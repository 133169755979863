import { FC, useEffect, useMemo, useRef, useState } from "react";
import DelegationListingItem from "../listing/common/types/delegation-listing-item";
import MapRoute from "../../../common/components/map/types/map-route";
import delegationTranslationsHelper from "../../../languages/delegation-translations.helper";
import Row from "../../../common/components/grid/row";
import Column from "../../../common/components/grid/column";
import MapComponent from "../../../common/components/map/map.component";
import LoaderComponent from "../../../common/components/loader/loader.component";
import DelegationDetailsRouteWaypoint from "./common/types/delegation-details-route-waypoint";
import delegationDetailsMapMarkersFactory from "./common/factory/delegation-details-map-markers.factory";
import useDelegationDetails from "../../../common/services/delegation/details/use-delegation-details";
import useAbort from "../../../common/hooks/use-abort";
import DelegationDetailsLoadParams from "../../../common/services/delegation/details/delegation-details-load-params";
import DelegationDetailsPassengerComponent from "./passenger/delegation-details-passenger.component";
import delegationDetailsPassengerFactory from "./common/factory/delegation-details-passenger.factory";
import DelegationDetailsRouteComponent from "./route/delegation-details-route.component";
import delegationDetailsWaypointFactory from "./common/factory/delegation-details-waypoint.factory";
import delegationDetailsRouteFactory from "./common/factory/delegation-details-route.factory";
import MessengerComponent from "../../../common/components/messenger/messenger.component";
import delegationDetailsHelper from "./common/delegation-details.helper";
import { useAppContext } from "../../../context/app.context";
import useDelegationRoadRoutes from "../../../common/services/delegation/road-route/use-delegation-road-routes";
import DelegationListingDelegationType from "../listing/common/types/delegation-listing-delegation-type";

type DelegationDetailsProps = {
  row: DelegationListingItem;
  isVisible: boolean;
};

const DelegationDetailsComponent: FC<DelegationDetailsProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const [waypoints, setWaypoints] = useState<DelegationDetailsRouteWaypoint[]>(
    []
  );
  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);
  const leftColumnContentRef = useRef<HTMLDivElement>(null);

  const delegationDetails = useDelegationDetails();
  const delegationDetailsAbort = useAbort();

  const roadRoutes = useDelegationRoadRoutes();

  const translations =
    delegationTranslationsHelper.getDelegationDetailsTranslations();

  const mapMarkers = useMemo(() => {
    return delegationDetailsMapMarkersFactory.create(waypoints);
  }, [waypoints]);

  const passengerDetails = useMemo(() => {
    if (delegationDetails.data)
      return delegationDetailsPassengerFactory.create(delegationDetails.data);
  }, [delegationDetails.data]);

  const rightColumnContentHeight = useMemo(() => {
    return leftColumnContentRef.current?.offsetHeight || 500;
  }, [leftColumnContentRef.current?.offsetHeight]);

  const loadDelegationDetails = (uuid: string) => {
    const params: DelegationDetailsLoadParams = {
      delegationUuid: uuid,
    };

    delegationDetails.load(params, delegationDetailsAbort.signal);
  };

  const loadRoadRoutes = (waypoints: DelegationDetailsRouteWaypoint[][]) => {
    const loadParams =
      delegationDetailsWaypointFactory.createSearchRoutingRequests(waypoints);

    roadRoutes.load(loadParams);
  };

  useEffect(() => {
    if (!props.isVisible) {
      return;
    }

    loadDelegationDetails(props.row.uuid);

    return delegationDetailsAbort.revoke;
  }, [props.row, props.isVisible]);

  useEffect(() => {
    if (!delegationDetails.data) {
      return;
    }

    const totalWaypoints: DelegationDetailsRouteWaypoint[][] =
      delegationDetailsWaypointFactory.createWaypoints(
        delegationDetails.data.addressSequence,
        delegationDetails.data.mileageData
      );

    loadRoadRoutes(totalWaypoints);

    setWaypoints(totalWaypoints.flat());
  }, [delegationDetails.data]);

  useEffect(() => {
    if (roadRoutes.data.length === 0) {
      return;
    }

    const totalRoutes = delegationDetailsRouteFactory.createMapRoutes(
      roadRoutes.data
    );

    setMapRoutes(totalRoutes);
  }, [roadRoutes.data]);

  const delegationChannelsAvailability =
    delegationDetailsHelper.getMileageDetailsMessengerChannelAvailability();

  const DetailsContent = useMemo(
    () => (
      <Row>
        <Column lg={7}>
          <div ref={leftColumnContentRef}>
            <Row>
              <Column>
                <div className="delegation_details_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    routes={mapRoutes}
                  />
                </div>
              </Column>
            </Row>
            <Row>
              <Column>
                <div>
                  {translations.delegationNumberLabel}
                  {props.row.delegationNumber}
                </div>
              </Column>
              <Column lg={2}>
                <DelegationDetailsPassengerComponent
                  passenger={passengerDetails}
                />
              </Column>
              <Column lg={10}>
                <DelegationDetailsRouteComponent
                  waypoints={waypoints}
                  delegationUuid={props.row.uuid}
                  isManualDelegation={
                    props.row.type === DelegationListingDelegationType.MANUAL
                  }
                />
              </Column>
            </Row>
          </div>
        </Column>
        <Column lg={5}>
          <div style={{ height: rightColumnContentHeight }}>
            <MessengerComponent
              channelsAvailability={delegationChannelsAvailability}
              orderUuid={props.row.uuid}
            />
          </div>
        </Column>
      </Row>
    ),
    [selectedAppLanguage, waypoints, mapMarkers, mapRoutes]
  );

  const LoaderContent = <LoaderComponent type="primary" />;

  return (
    <div className="delegation_details">
      {!delegationDetails.data ? LoaderContent : DetailsContent}
    </div>
  );
};

export default DelegationDetailsComponent;
