import useAsyncData from "../../../hooks/use-async-data";
import taxiFleetPartnerService from "../taxi-fleet-partner.service";
import TaxiFleetPartnerDetails from "./taxi-fleet-partner-details";
import TaxiFleetPartnerDetailsLoadParams from "./taxi-fleet-partner-details-load-params";

const useTaxiFleetPartnerDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<TaxiFleetPartnerDetails | null>(null);

  const load = async (
    params: TaxiFleetPartnerDetailsLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const taxiFleetPartnerDetails = await taxiFleetPartnerService.getDetails(
        params,
        signal
      );

      setData(taxiFleetPartnerDetails);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useTaxiFleetPartnerDetails;
