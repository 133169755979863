import HttpResponse from "../../../../utils/http/http.response";

export enum TaxiDriverContractDetailsResponseItemBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type TaxiDriverContractDetailsResponseItem = {
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  id: string;
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: TaxiDriverContractDetailsResponseItemBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  taxiDriverAssociationId: string;
  description: string | null;
};

type TaxiDriverContractDetailsResponse =
  HttpResponse<TaxiDriverContractDetailsResponseItem>;

export default TaxiDriverContractDetailsResponse;
