import BillingsOrderForwardTaxiTaxiRequest from "../../../../api/raily/billings/order-forward/taxi-taxi/billings-order-forward-taxi-taxi.request";
import BillingsOrderForwardTaxiTaxiLoadParams from "./billings-order-forward-taxi-taxi-load-params";

const create = (
  params: BillingsOrderForwardTaxiTaxiLoadParams
): BillingsOrderForwardTaxiTaxiRequest => {
  return {
    forwardingId: params.forwardingUuid,
  };
};

const billingsOrderForwardTaxiTaxiRequestFactory = {
  create,
};

export default billingsOrderForwardTaxiTaxiRequestFactory;
