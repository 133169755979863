import { FC, useEffect, useState } from "react";
import { useAppContext } from "../../../../../context/app.context";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import cargoBreadcrumbsHelper from "../../../common/breadcrumbs/cargo-breadcrumbs.helper";
import cargoCompanyAddFormHelper from "./common/cargo-company-add-form.helper";
import useAbort from "../../../../../common/hooks/use-abort";
import useForm from "../../../../../common/components/form/use-form";
import CargoCompanyAddParams from "../../../../../common/services/cargo-company/cargo-company/add/cargo-company-add-params";
import cargoCompanyAddParamsFactory from "./common/cargo-company-add-params.factory";
import cargoCompanyService from "../../../../../common/services/cargo-company/cargo-company/cargo-company.service";
import CargoCompanyContractMaintenancePolicySelectComponent from "./common/select/cargo-company-contract-maintenance-policy-select.component";
import cargoRoutesHelper from "../../../common/routes/cargo-routes.helper";

type CargoCompanyAddProps = {};

const CargoCompanyAddComponent: FC<CargoCompanyAddProps> = () => {
  const [isCargoCompanyAddPending, setIsCargoCompanyAddPending] =
    useState(false);

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const cargoCompanyAddAbort = useAbort();
  const navigate = useNavigate();

  const translations = cargoTranslationsHelper.getCompanyAddTranslations();

  const form = useForm({
    emptyValues: cargoCompanyAddFormHelper.getDefaultFormData(),
    validationDefinition: cargoCompanyAddFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    const breadcrumbs = cargoBreadcrumbsHelper.getCompanyAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const navigateToListing = () => {
    navigate(cargoRoutesHelper.getCompanyListingRoute());
  };

  const onCargoCompanyAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigateToListing();
  };

  const onCargoCompanyAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const cargoCompany = async () => {
    setIsCargoCompanyAddPending(true);

    const params: CargoCompanyAddParams = cargoCompanyAddParamsFactory.create(
      form.values
    );

    try {
      await cargoCompanyService.add(params, cargoCompanyAddAbort.signal);

      onCargoCompanyAddSuccess();
    } catch {
      onCargoCompanyAddFailure();
    } finally {
      setIsCargoCompanyAddPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid: boolean = await form.validateAll();

    if (!isFormValid) return;

    cargoCompany();
  };

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.companyNameLabel}
                  isRequired
                  errorMessage={form.validationResults.name.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.companyNameInputPlaceholder}
                    value={form.values.name}
                    onChange={(value) => form.setValue("name", value)}
                    onBlur={() => form.validate("name")}
                    hasError={!!form.validationResults.name.errorMessage}
                    idForTesting="cargo-company-add-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.displayNameLabel}
                  isRequired
                  errorMessage={form.validationResults.displayName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.displayNameInputPlaceholder}
                    value={form.values.displayName}
                    onChange={(value) => form.setValue("displayName", value)}
                    onBlur={() => form.validate("displayName")}
                    hasError={!!form.validationResults.displayName.errorMessage}
                    idForTesting="cargo-company-add-display-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.taxNumberLabel}
                  isRequired
                  errorMessage={form.validationResults.taxNumber.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.taxNumberInputPlaceholder}
                    value={form.values.taxNumber}
                    onChange={(value) => form.setValue("taxNumber", value)}
                    onBlur={() => form.validate("taxNumber")}
                    hasError={!!form.validationResults.taxNumber.errorMessage}
                    idForTesting="cargo-company-add-tax-number"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.companyIdLabel}
                  errorMessage={form.validationResults.companyId.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.companyIdInputPlaceholder}
                    value={form.values.companyId}
                    onChange={(value) => form.setValue("companyId", value)}
                    onBlur={() => form.validate("companyId")}
                    hasError={!!form.validationResults.companyId.errorMessage}
                    idForTesting="cargo-company-add-company-id"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.nationalCourtRegisterLabel}
                  errorMessage={
                    form.validationResults.nationalCourtRegister.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={translations.form.nationalCourtRegisterLabel}
                    value={form.values.nationalCourtRegister}
                    onChange={(value) =>
                      form.setValue("nationalCourtRegister", value)
                    }
                    onBlur={() => form.validate("nationalCourtRegister")}
                    hasError={
                      !!form.validationResults.nationalCourtRegister
                        .errorMessage
                    }
                    idForTesting="cargo-company-add-national-court-register"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.contractMaintenancePolicyLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.contractMaintenancePolicy
                      .errorMessage
                  }
                >
                  <CargoCompanyContractMaintenancePolicySelectComponent
                    onChange={(value) => {
                      form.setValue("contractMaintenancePolicy", value ?? "");
                    }}
                    onBlur={() => form.validate("contractMaintenancePolicy")}
                    placeholder={
                      translations.form
                        .contractMaintenancePolicySelectPlaceholder
                    }
                    selectedContractMaintenancePolicy={
                      form.values.contractMaintenancePolicy
                    }
                    isError={
                      !!form.validationResults.contractMaintenancePolicy
                        .errorMessage
                    }
                    idForTesting="cargo-company-add-contract-maintenance-policy"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.addressLabel}
                  isRequired
                  errorMessage={form.validationResults.address.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.addressLabel}
                    value={form.values.address}
                    onChange={(value) => form.setValue("address", value)}
                    onBlur={() => form.validate("address")}
                    hasError={!!form.validationResults.address.errorMessage}
                    idForTesting="cargo-company-add-address"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.notesLabel}
                  errorMessage={form.validationResults.notes.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.notesInputPlaceholder}
                    value={form.values.notes}
                    onChange={(value) => form.setValue("notes", value)}
                    onBlur={() => form.validate("notes")}
                    hasError={!!form.validationResults.notes.errorMessage}
                    idForTesting="cargo-company-add-notes"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <ButtonComponent
              onClick={submitForm}
              type="primary"
              title={translations.form.submitButtonTitle}
              idForTesting="cargo-company-add-submit-button"
              classNames={{ root: "mt-2" }}
              isDisabled={isCargoCompanyAddPending}
            >
              {translations.form.submitButtonLabel}
            </ButtonComponent>
          </CardComponent>
        </Column>
      </Row>
    </>
  );
};

export default CargoCompanyAddComponent;
