import taxiTranslationsHelper from "../../../../../../languages/taxi-translations.helper";
import TaxiCargoContractListingSortKey from "../types/taxi-cargo-contract-listing-sort-key";
import TaxiCargoContractListingSortSelectOption from "../types/taxi-cargo-contract-listing-sort-select-option";

const getSelectOptions = (): TaxiCargoContractListingSortSelectOption[] => {
  const translations =
    taxiTranslationsHelper.getCargoContractListingTranslations().sort;

  return [
    {
      label: translations.billingModelAscOptionLabel,
      value: TaxiCargoContractListingSortKey.BILLING_MODEL_ASC,
    },
    {
      label: translations.billingModelDescOptionLabel,
      value: TaxiCargoContractListingSortKey.BILLING_MODEL_DESC,
    },
    {
      label: translations.cargoCompanyAscOptionLabel,
      value: TaxiCargoContractListingSortKey.CARGO_COMPANY_ASC,
    },
    {
      label: translations.cargoCompanyDescOptionLabel,
      value: TaxiCargoContractListingSortKey.CARGO_COMPANY_DESC,
    },
    {
      label: translations.distanceRateAscOptionLabel,
      value: TaxiCargoContractListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.distanceRateDescOptionLabel,
      value: TaxiCargoContractListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      label: translations.discountPercentLimitAscOptionLabel,
      value: TaxiCargoContractListingSortKey.DISCOUNT_PERCENT_LIMIT_ASC,
    },
    {
      label: translations.discountPercentLimitDescOptionLabel,
      value: TaxiCargoContractListingSortKey.DISCOUNT_PERCENT_LIMIT_DESC,
    },
    {
      label: translations.freeStoppingPeriodAscOptionLabel,
      value: TaxiCargoContractListingSortKey.FREE_STOPPING_PERIOD_ASC,
    },
    {
      label: translations.freeStoppingPeriodDescOptionLabel,
      value: TaxiCargoContractListingSortKey.FREE_STOPPING_PERIOD_DESC,
    },
    {
      label: translations.stopRateAscOptionLabel,
      value: TaxiCargoContractListingSortKey.STOPPING_RATE_ASC,
    },
    {
      label: translations.stopRateDescOptionLabel,
      value: TaxiCargoContractListingSortKey.STOPPING_RATE_DESC,
    },
    {
      label: translations.periodOfValidityFromAscOptionLabel,
      value: TaxiCargoContractListingSortKey.PERIOD_OF_VALIDITY_FROM_ASC,
    },
    {
      label: translations.periodOfValidityFromDescOptionLabel,
      value: TaxiCargoContractListingSortKey.PERIOD_OF_VALIDITY_FROM_DESC,
    },
    {
      label: translations.periodOfValidityToAscOptionLabel,
      value: TaxiCargoContractListingSortKey.PERIOD_OF_VALIDITY_TO_ASC,
    },
    {
      label: translations.periodOfValidityToDescOptionLabel,
      value: TaxiCargoContractListingSortKey.PERIOD_OF_VALIDITY_TO_DESC,
    },
  ];
};

const taxiCargoContractListingSortHelper = { getSelectOptions };

export default taxiCargoContractListingSortHelper;
