import SearchRoadRoutingRequest from "../../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../../utils/search-road-route/search-road-routing.response";
import searchRoadRoutingService from "../../../utils/search-road-route/search-road-routing.service";
import railyApiService from "../raily-api.service";
import RouteActiveListRequest, {
  RouteActiveListRequestQueryParams,
} from "./active-list/route-active-list.request";
import routeActiveListRequestFactory from "./active-list/route-active-list-request.factory";
import RouteActiveListResponse from "./active-list/route-active-list.response";
import RouteCompletedRouteRequest from "./completed-route/route-completed-route.request";
import RouteCompletedRouteResponse from "./completed-route/route-completed-route.response";
import RouteDetailsRequest from "./details/route-details.request";
import RouteDetailsResponse from "./details/route-details.response";
import RouteFinishedListRequest, {
  RouteFinishedListRequestQueryParams,
} from "./finished-list/route-finished-list.request";
import routeFinishedListRequestFactory from "./finished-list/route-finished-list-request.factory";
import RouteFinishedListResponse from "./finished-list/route-finished-list.response";

const routeApiService = () => {
  const getActiveList = async (
    request: RouteActiveListRequest,
    abortSignal: AbortSignal
  ): Promise<RouteActiveListResponse> => {
    const url = `/plan-entries/active`;

    const queryParams: RouteActiveListRequestQueryParams =
      routeActiveListRequestFactory.createQueryParams(request);

    return railyApiService().get<RouteActiveListResponse>({
      url,
      queryParams,
      abortSignal,
    });
  };

  const getFinishedList = async (
    request: RouteFinishedListRequest,
    abortSignal: AbortSignal
  ): Promise<RouteFinishedListResponse> => {
    const url = `/plan-entries/finished`;

    const queryParams: RouteFinishedListRequestQueryParams =
      routeFinishedListRequestFactory.createQueryParams(request);

    return railyApiService().get<RouteFinishedListResponse>({
      url,
      queryParams,
      abortSignal,
    });
  };

  const getDetails = (
    request: RouteDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<RouteDetailsResponse> => {
    const url = `/plan-entries/${request.planEntryId}`;

    return railyApiService().get<RouteDetailsResponse>({
      url,
      abortSignal,
    });
  };

  const getRoute = (
    request: SearchRoadRoutingRequest
  ): Promise<SearchRoadRoutingResponse> => {
    return searchRoadRoutingService.search(request);
  };

  const getCompletedRoute = (
    request: RouteCompletedRouteRequest,
    abortSignal: AbortSignal
  ): Promise<RouteCompletedRouteResponse> => {
    const url = `/gps-gateway/records/plan-entry/${request.planEntryId}/gps-time-nodes`;

    return railyApiService().get<RouteCompletedRouteResponse>({
      url,
      abortSignal,
    });
  };

  return {
    getActiveList,
    getFinishedList,
    getDetails,
    getRoute,
    getCompletedRoute,
  };
};

export default routeApiService;
