import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import UserStatus from "../../../../../common/types/user-status";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import DispatcherListingFilter, {
  DispatcherListingCargoCompanyFilter,
  DispatcherListingDispatchFilter,
  DispatcherListingEmailFilter,
  DispatcherListingFirstNameFilter,
  DispatcherListingLastNameFilter,
  DispatcherListingStatusFilter,
  DispatcherListingUsernameFilter,
} from "../common/types/dispatcher-listing-filter";
import DispatcherListingFilterType from "../common/types/dispatcher-listing-filter-type";
import DispatcherListingSortKey from "../common/types/dispatcher-listing-sort-key";

const dispatcherListingFilterHelper = () => {
  const translations =
    userTranslationsHelper.getDispatcherListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getSelectDataByType = (
    filterType: DispatcherListingFilterType,
    query: string
  ) => {
    const options: Record<
      DispatcherListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [DispatcherListingFilterType.FIRST_NAME]: getSelectDataForFirstName,
      [DispatcherListingFilterType.LAST_NAME]: getSelectDataForLastName,
      [DispatcherListingFilterType.ACTIVITY_STATUS]: getSelectDataForStatus,
      [DispatcherListingFilterType.USERNAME]: getSelectDataForUserName,
      [DispatcherListingFilterType.EMAIL]: getSelectDataForEmailAddress,
      [DispatcherListingFilterType.DISPATCH]: getSelectDataForDispatch,
      [DispatcherListingFilterType.CARGO_COMPANY]: getSelectDataForCargoCompany,
    };

    const result = options[filterType](query);

    return result;
  };

  const getSelectDataForCargoCompany = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.cargoCompanyTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForDispatch = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.dispatchTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForFirstName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.firstNameTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForLastName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.lastNameTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForStatus = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
      options: [
        {
          label:
            translations.filters.search.activityStatusTemplateLabel.replace(
              "#{query}",
              userHelper.getActivityStatusText(UserStatus.ACTIVE)
            ),
          value: UserStatus.ACTIVE,
        },
        {
          label:
            translations.filters.search.activityStatusTemplateLabel.replace(
              "#{query}",
              userHelper.getActivityStatusText(UserStatus.DELETED)
            ),
          value: UserStatus.DELETED,
        },
      ],
    };
  };

  const getSelectDataForUserName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.usernameTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForEmailAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.emailTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getBadgeDefinitionByType = (
    filterType: DispatcherListingFilterType,
    filterValue: DispatcherListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      DispatcherListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [DispatcherListingFilterType.FIRST_NAME]: () =>
        getBadgeForFirstName(
          filterValue as DispatcherListingFirstNameFilter["value"]
        ),
      [DispatcherListingFilterType.LAST_NAME]: () =>
        getBadgeForLastName(
          filterValue as DispatcherListingLastNameFilter["value"]
        ),
      [DispatcherListingFilterType.EMAIL]: () =>
        getBadgeForEmailAddress(
          filterValue as DispatcherListingEmailFilter["value"]
        ),

      [DispatcherListingFilterType.ACTIVITY_STATUS]: () =>
        getBadgeForActivityStatus(
          filterValue as DispatcherListingStatusFilter["value"]
        ),
      [DispatcherListingFilterType.USERNAME]: () =>
        getBadgeForUsername(
          filterValue as DispatcherListingUsernameFilter["value"]
        ),
      [DispatcherListingFilterType.CARGO_COMPANY]: () =>
        getBadgeForCargoCompany(
          filterValue as DispatcherListingCargoCompanyFilter["value"]
        ),
      [DispatcherListingFilterType.DISPATCH]: () =>
        getBadgeForDispatch(
          filterValue as DispatcherListingDispatchFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getBadgeForDispatch = (
    filterValue: DispatcherListingDispatchFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.dispatchLabel,
      translations.filters.dispatchTitle
    );
  };

  const getBadgeForCargoCompany = (
    filterValue: DispatcherListingCargoCompanyFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.cargoCompanyLabel,
      translations.filters.cargoCompanyTitle
    );
  };

  const getBadgeForUsername = (
    filterValue: DispatcherListingUsernameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.usernameLabel,
      translations.filters.usernameTitle
    );
  };

  const getBadgeForActivityStatus = (
    filterValue: DispatcherListingStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText = userHelper.getActivityStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.activityStatusLabel,
      translations.filters.activityStatusTitle
    );
  };

  const getBadgeForFirstName = (
    filterValue: DispatcherListingFirstNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.firstNameLabel,
      translations.filters.firstNameTitle
    );
  };

  const getBadgeForLastName = (
    filterValue: DispatcherListingLastNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.lastNameLabel,
      translations.filters.lastNameTitle
    );
  };

  const getBadgeForEmailAddress = (
    filterValue: DispatcherListingEmailFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.emailLabel,
      translations.filters.emailTitle
    );
  };

  const getFilterDefinitionByType = (
    filterType: DispatcherListingFilterType
  ): ListingFilterDefinitionOption<DispatcherListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<DispatcherListingFilter>[] => {
      return Object.values(DispatcherListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<DispatcherListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.firstNameAscLabel,
        value: DispatcherListingSortKey.FIRST_NAME_ASC,
      },
      {
        label: translations.sort.firstNameDescLabel,
        value: DispatcherListingSortKey.FIRST_NAME_DESC,
      },
      {
        label: translations.sort.lastNameAscLabel,
        value: DispatcherListingSortKey.LAST_NAME_ASC,
      },
      {
        label: translations.sort.lastNameDescLabel,
        value: DispatcherListingSortKey.LAST_NAME_DESC,
      },
      {
        label: translations.sort.usernameAscLabel,
        value: DispatcherListingSortKey.USERNAME_ASC,
      },
      {
        label: translations.sort.usernameDescLabel,
        value: DispatcherListingSortKey.USERNAME_DESC,
      },
    ];
  };

  return { getFilterDefinition, getSortDefinition };
};

export default dispatcherListingFilterHelper;
