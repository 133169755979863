import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import DelegationAddVehicleType from "../types/delegation-add-vehicle-type";
import delegationAddFormValidationService from "./delegation-add-form-validation.service";
import DelegationAddFormData from "./types/delegation-add-form-data";

const getDefaultFormData = (): DelegationAddFormData => {
  return {
    workerUuid: "",
    workerCompanyUuid: "",
    mileagesCost: null,
    totalDistance: null,
    dateFrom: null,
    dateTo: null,
    osrmDistance: null,
    addressSequence: [],
    prepay: null,
    localTravelCost: null,
    oneDayDietCost: null,
    wholeDayDietCost: null,
    startedDayDietCost: null,
    accommodationBillsCost: null,
    accommodationCost: null,
    otherExpenses: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<DelegationAddFormData> => {
    return {
      workerUuid: (values) =>
        delegationAddFormValidationService.validateWorkerUuid(
          values.workerCompanyUuid,
          values.workerUuid
        ),
      workerCompanyUuid: (values) =>
        delegationAddFormValidationService.validateWorkerCompanyUuid(
          values.workerCompanyUuid
        ),
      mileagesCost: (values) =>
        delegationAddFormValidationService.validateMileageCost(
          values.mileagesCost
        ),
      totalDistance: (values) =>
        delegationAddFormValidationService.validateTotalDistance(
          values.totalDistance
        ),
      dateFrom: (values) =>
        delegationAddFormValidationService.validateDelegationDateFrom(
          values.dateFrom,
          values.dateTo
        ),
      dateTo: (values) =>
        delegationAddFormValidationService.validateDelegationDateTo(
          values.dateTo,
          values.dateFrom
        ),
      addressSequence: (values) =>
        delegationAddFormValidationService.validateAddressSequence(
          values.addressSequence
        ),
      osrmDistance: (values) =>
        delegationAddFormValidationService.validateOSRMDistance(
          values.osrmDistance
        ),
      accommodationBillsCost: (values) =>
        delegationAddFormValidationService.validateAccommodationBillsCost(
          values.accommodationBillsCost
        ),
      accommodationCost: (values) =>
        delegationAddFormValidationService.validateAccommodationCost(
          values.accommodationCost
        ),
      prepay: (values) =>
        delegationAddFormValidationService.validatePrepay(values.prepay),
      startedDayDietCost: (values) =>
        delegationAddFormValidationService.validateStartedDayDietCost(
          values.startedDayDietCost
        ),
      wholeDayDietCost: (values) =>
        delegationAddFormValidationService.validateWholeDayDietCost(
          values.wholeDayDietCost
        ),
      localTravelCost: (values) =>
        delegationAddFormValidationService.validateLocalTravelCost(
          values.localTravelCost
        ),
      oneDayDietCost: (values) =>
        delegationAddFormValidationService.validateOneDayDietCost(
          values.oneDayDietCost
        ),
      otherExpenses: (values) =>
        delegationAddFormValidationService.validateOtherExpenses(
          values.otherExpenses
        ),
    };
  };

const delegationAddFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default delegationAddFormHelper;
