import { FC, useState } from "react";
import TabsData from "./common/types/tabs-data";
import tabsHelper from "./common/tabs.helper";
import ComponentClassnames from "../../types/component-classnames";
import classNames from "classnames";

export type TabsProps = {
  data: TabsData;
  classNames?: ComponentClassnames & {
    content?: string;
  };
  defaultIndex?: number;
  onActiveTabChange?: (activeTab: number) => void;
};

const TabsComponent: FC<TabsProps> = (props) => {
  const [activeTab, setActiveTab] = useState(props.defaultIndex ?? 0);

  const rootClassnames = classNames("tabs", props.classNames?.root);

  const contentClassnames = classNames(
    "tabs_content",
    props.classNames?.content
  );

  const handleActiveTabChange = (tabIndex: number) => {
    props.onActiveTabChange && props.onActiveTabChange(tabIndex);

    setActiveTab(tabIndex);
  };

  return (
    <div className={rootClassnames}>
      <div className="tabs_header">
        {props.data.map((tab, index) => {
          const isTabActive = index === activeTab;

          return (
            <div
              className="tabs_header_tab"
              key={index}
              title={tab.label}
              onClick={() => handleActiveTabChange(index)}
            >
              <div className="tabs_header_tab__label">{tab.label}</div>
              {tab.counter && (
                <div className="tabs_header_tab__counter">{tab.counter}</div>
              )}
              {isTabActive && (
                <div className="tabs_header_tab__active_underline"></div>
              )}
            </div>
          );
        })}
      </div>
      <div className={contentClassnames}>
        {tabsHelper.getContent(props.data, activeTab)}
      </div>
    </div>
  );
};

export default TabsComponent;
