import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import classNames from "classnames";
import OrderJoinOrderDetailsDriver, {
  OrderJoinOrderDetailsDriverRole,
} from "../common/types/order-join-order-details-driver";

type OrderJoinOrderDetailsDriversProps = {
  drivers: OrderJoinOrderDetailsDriver[];
};

const OrderJoinOrderDetailsDriversComponent: FC<
  OrderJoinOrderDetailsDriversProps
> = (props) => {
  const translations = orderTranslationsHelper.getDetailsTranslations().drivers;

  return (
    <div className="order_join_drivers">
      {!!props.drivers.length && (
        <>
          <div>{translations.headingText}</div>
          <ul className="order_join_drivers_list">
            {props.drivers.map((driver) => {
              return (
                <li
                  className={"order_join_drivers_list_item"}
                  key={`order-driver-${driver.uuid}`}
                >
                  <div className="order_join_drivers_list_item__content">
                    <FontAwesomeIcon
                      icon={faUser}
                      className={classNames(
                        "order_details_join_list_item__content__icon",
                        driver.role ===
                          OrderJoinOrderDetailsDriverRole.CANDIDATE &&
                          "candidate"
                      )}
                    />
                    <div
                      className={classNames(
                        "order_details_join_list_item__content__name",
                        driver.role ===
                          OrderJoinOrderDetailsDriverRole.CANDIDATE &&
                          "candidate"
                      )}
                    >
                      {driver.displayName}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default OrderJoinOrderDetailsDriversComponent;
