import MileageDetailsResponse, {
  MileageDetailsResponseStatusType,
  MileageDetailsResponseVehicleType,
} from "../../../api/raily/mileage/details/mileage-details.response";
import MileageDetails, {
  MileageDetailsStatus,
  MileageDetailsVehicleType,
} from "./mileage-details";

const createStatusType = (
  type: MileageDetailsResponseStatusType | undefined
): MileageDetailsStatus | undefined => {
  switch (type) {
    case MileageDetailsResponseStatusType.ACCEPTED:
      return MileageDetailsStatus.ACCEPTED;
    case MileageDetailsResponseStatusType.DELEGATION_CREATED:
      return MileageDetailsStatus.DELEGATION_CREATED;
    case MileageDetailsResponseStatusType.PENDING:
      return MileageDetailsStatus.PENDING;
    case MileageDetailsResponseStatusType.REJECTED:
      return MileageDetailsStatus.REJECTED;
    default:
      return undefined;
  }
};

const createVehicleType = (
  type: MileageDetailsResponseVehicleType | undefined
): MileageDetailsVehicleType | undefined => {
  switch (type) {
    case MileageDetailsResponseVehicleType.CAR_GT_900CM3:
      return MileageDetailsVehicleType.CAR_GT_900CM3;
    case MileageDetailsResponseVehicleType.CAR_LE_900CM3:
      return MileageDetailsVehicleType.CAR_LE_900CM3;
    case MileageDetailsResponseVehicleType.COMPANY_OWNED:
      return MileageDetailsVehicleType.COMPANY_OWNED;
    case MileageDetailsResponseVehicleType.MOPED:
      return MileageDetailsVehicleType.MOPED;
    case MileageDetailsResponseVehicleType.MOTORCYCLE:
      return MileageDetailsVehicleType.MOTORCYCLE;
    default:
      return undefined;
  }
};

const create = (response: MileageDetailsResponse): MileageDetails => {
  return {
    addressSeq: response.data.addressSeq,
    createdAt: response.data.createdAt,
    createdBy: response.data.createdBy,
    distance: response.data.distance,
    endAddressLabel: response.data.endAddressLabel,
    hasDelegation: response.data.hasDelegation,
    humanId: response.data.humanId,
    id: response.data.id,
    midAddressesLabels: response.data.midAddressesLabels,
    mileageCost: response.data.mileageCost,
    mileageDate: response.data.mileageDate,
    startAddressLabel: response.data.startAddressLabel,
    status: createStatusType(response.data.state),
    vehicleType: createVehicleType(response.data.vehicleType),
    workerCompanyId: response.data.workerCompanyId,
    workerId: response.data.workerId,
    workerName: response.data.workerName,
    workerPhone: response.data.workerPhone,
    cardNumber: response.data.cardNumber,
    commissionNumber: response.data.commissionNumber,
    delegationId: response.data.delegationId,
    mileageNumber: response.data.mileageNumber,
    modifiedAt: response.data.modifiedAt,
    modifiedBy: response.data.modifiedBy,
    notes: response.data.notes,
    acceptedDistance: response.data.acceptedDistance,
  };
};

const mileageDetailsFactory = {
  create,
};

export default mileageDetailsFactory;
