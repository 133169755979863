import { FC } from "react";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import ModalComponent from "../../../../../../common/components/modal/modal.component";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";

type OrderAddChangeContractorConfirmationModalProps = {
  isModalOpen: boolean;
  onCloseClick: () => void;
  routesValidationErrors: string[];
};

const OrderAddRouteValidationModalComponent: FC<
  OrderAddChangeContractorConfirmationModalProps
> = (props) => {
  const translations = orderTranslationsHelper.getAddTranslations().validation;

  return (
    <ModalComponent
      classNames={{ root: "order_add_route_validation_modal" }}
      header={{ title: translations.headingText }}
      isOpen={props.isModalOpen}
      onClose={props.onCloseClick}
      actions={[
        <ButtonComponent
          onClick={props.onCloseClick}
          type="primary"
          title={translations.closeButtonTitle}
          idForTesting="route-validation-close-button"
        >
          {translations.closeButtonText}
        </ButtonComponent>,
      ]}
    >
      <ul className="order_add_route_validation_modal__error_list">
        {props.routesValidationErrors.map((error, index) => {
          return <li key={index}>{error}</li>;
        })}
      </ul>
    </ModalComponent>
  );
};

export default OrderAddRouteValidationModalComponent;
