import DealerListLoadParams, {
  DealerListLoadParamsOrder,
} from "../../../../../common/services/dealer/list/dealer-list-load-params";
import { DriverListingTaxiCorporationFilter } from "../../../driver/listing/common/types/driver-listing-filter";
import DealerListingFilter, {
  DealerListingActivityStatusFilter,
  DealerListingEmailAddressFilter,
  DealerListingFirstNameFilter,
  DealerListingLastNameFilter,
  DealerListingUserNameFilter,
} from "./types/dealer-listing-filter";
import DealerListingFilterType from "./types/dealer-listing-filter-type";
import DealerListingSortKey from "./types/dealer-listing-sort-key";

const createOrder = (
  sortKey: DealerListingSortKey | null
): DealerListLoadParamsOrder | undefined => {
  const definition: Record<DealerListingSortKey, DealerListLoadParamsOrder> = {
    [DealerListingSortKey.FIRST_NAME_ASC]:
      DealerListLoadParamsOrder.FIRST_NAME_ASC,
    [DealerListingSortKey.FIRST_NAME_DESC]:
      DealerListLoadParamsOrder.FIRST_NAME_DESC,
    [DealerListingSortKey.LAST_NAME_ASC]:
      DealerListLoadParamsOrder.LAST_NAME_ASC,
    [DealerListingSortKey.LAST_NAME_DESC]:
      DealerListLoadParamsOrder.LAST_NAME_DESC,
    [DealerListingSortKey.USERNAME_ASC]: DealerListLoadParamsOrder.USERNAME_ASC,
    [DealerListingSortKey.USERNAME_DESC]:
      DealerListLoadParamsOrder.USERNAME_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: DealerListingFilter[],
  sortKey: DealerListingSortKey | null
): DealerListLoadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === DealerListingFilterType.ACTIVITY_STATUS
  )?.value as DealerListingActivityStatusFilter["value"] | undefined;

  return {
    page,
    pageSize,
    order: createOrder(sortKey),
    taxiCorporation: filters.find(
      (filter) => filter.type === DealerListingFilterType.TAXI_CORPORATION
    )?.value as DriverListingTaxiCorporationFilter["value"] | undefined,
    firstName: filters.find(
      (filter) => filter.type === DealerListingFilterType.FIRST_NAME
    )?.value as DealerListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === DealerListingFilterType.LAST_NAME
    )?.value as DealerListingLastNameFilter["value"] | undefined,
    username: filters.find(
      (filter) => filter.type === DealerListingFilterType.USERNAME
    )?.value as DealerListingUserNameFilter["value"] | undefined,
    email: filters.find(
      (filter) => filter.type === DealerListingFilterType.EMAIL_ADDRESS
    )?.value as DealerListingEmailAddressFilter["value"] | undefined,
    status: statusFilter,
  };
};

const dealerListingLoadParamsFactory = {
  create,
};

export default dealerListingLoadParamsFactory;
