import RailyOfficerListRequest, {
  RailyOfficerListRequestStatusCriteria,
  RailyOfficerListRequestOrder,
} from "../../../api/raily/raily-officer/list/raily-officer-list.request";
import UserStatus from "../../../types/user-status";
import paginationService from "../../../utils/pagination/pagination.service";
import RailyOfficerListLoadParams, {
  RailyOfficerListLoadParamsOrder,
} from "./raily-officer-list-load-params";

const createStatusCriteria = (
  status: UserStatus | undefined
): RailyOfficerListRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return RailyOfficerListRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return RailyOfficerListRequestStatusCriteria.DELETED;
    default:
      return RailyOfficerListRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: RailyOfficerListLoadParamsOrder | undefined
): RailyOfficerListRequestOrder | undefined => {
  switch (status) {
    case RailyOfficerListLoadParamsOrder.FIRST_NAME_ASC:
      return RailyOfficerListRequestOrder.FIRST_NAME_ASC;
    case RailyOfficerListLoadParamsOrder.FIRST_NAME_DESC:
      return RailyOfficerListRequestOrder.FIRST_NAME_DESC;
    case RailyOfficerListLoadParamsOrder.LAST_NAME_ASC:
      return RailyOfficerListRequestOrder.LAST_NAME_ASC;
    case RailyOfficerListLoadParamsOrder.LAST_NAME_DESC:
      return RailyOfficerListRequestOrder.LAST_NAME_DESC;
    case RailyOfficerListLoadParamsOrder.USERNAME_ASC:
      return RailyOfficerListRequestOrder.USERNAME_ASC;
    case RailyOfficerListLoadParamsOrder.USERNAME_DESC:
      return RailyOfficerListRequestOrder.USERNAME_DESC;
    default:
      return undefined;
  }
};

const create = (
  params: RailyOfficerListLoadParams
): RailyOfficerListRequest => {
  return {
    creationStart: params.creationDateFrom,
    creationEnd: params.creationDateTo,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    limit: params.pageSize,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    statusCriteria: createStatusCriteria(params.status),
    username: params.username,
  };
};

const railyOfficerListRequestFactory = { create };

export default railyOfficerListRequestFactory;
