import useAsyncData from "../../../hooks/use-async-data";
import routeService from "../route.service";
import RouteActiveList from "./route-active-list";
import RouteActiveListLoadParams from "./route-active-list-load-params";

const useRouteActiveList = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<RouteActiveList>({
      data: [],
    });

  const load = async (
    params: RouteActiveListLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoOrderActiveList = await routeService.getActiveList(
        params,
        signal
      );

      setData(cargoOrderActiveList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useRouteActiveList;
