import classNames from "classnames";
import { FC, Fragment, useMemo } from "react";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import LoaderComponent from "../loader/loader.component";
import TableColumn from "./types/table-column";
import TableRow from "./types/table-row";
import { useAppContext } from "../../../context/app.context";

export type TableProps = {
  columns: TableColumn[];
  rows: TableRow[];
  isLoading?: boolean;
  isError?: boolean;
  noDataMessage?: string;
  errorDataLoadingMessage?: string;
};

const TableComponent: FC<TableProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const tableTranslations = useMemo(() => {
    return appTranslationsHelper.getComponentTranslations().table;
  }, [selectedAppLanguage]);

  const getTotalColumnsSpan = () => {
    let totalSpan = 0;

    for (const column of props.columns) {
      totalSpan += column.colSpan ?? 1;
    }

    return totalSpan;
  };

  const totalColumnsSpan = getTotalColumnsSpan();

  return (
    <table className={classNames("table", props.isLoading && "loading")}>
      <thead className="table__head">
        <tr className="table__tr">
          {props.columns.map((column) => {
            return (
              <th
                key={`column-${column.accessor as string}`}
                colSpan={column.colSpan}
                className="table__th"
              >
                <div className="table__th__content">
                  <div className="table__th__title" title={column.title}>
                    {column.header}
                  </div>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody
        className={classNames("table__body", props.isLoading && "loading")}
      >
        {props.isLoading && !props.rows.length ? (
          <tr>
            <td colSpan={totalColumnsSpan}>
              <div className="table__loader_wrapper">
                <LoaderComponent type="primary" />
              </div>
            </td>
          </tr>
        ) : props.isError ? (
          <tr>
            <td colSpan={totalColumnsSpan} className="table__error_wrapper">
              {props.errorDataLoadingMessage ??
                tableTranslations.errorDataLoadingText}
            </td>
          </tr>
        ) : props.rows.length === 0 ? (
          <tr>
            <td colSpan={totalColumnsSpan}>
              <div className="table__no_data_wrapper">
                {props.noDataMessage ?? tableTranslations.noDataMessageText}
              </div>
            </td>
          </tr>
        ) : (
          props.rows.map((row) => {
            return (
              <Fragment key={`row-${row.id}`}>
                <tr
                  className={classNames(
                    "table__tr",
                    row.onClick && "clickable"
                  )}
                  data-id={row.idForTesting}
                  onClick={row.onClick}
                >
                  {props.columns.map((column) => (
                    <td
                      key={column.accessor as string}
                      className="table__td"
                      data-label={`${column.header}:`}
                      colSpan={column.colSpan}
                    >
                      <div className="table__td__content">
                        {(row.value as any)[column.accessor]}
                      </div>
                    </td>
                  ))}
                </tr>
                {row.hiddenComponent && (
                  <tr
                    className={classNames(
                      "table__tr",
                      !row.isHiddenComponentVisible && "hidden"
                    )}
                  >
                    <td className="table__td" colSpan={totalColumnsSpan}>
                      {row.hiddenComponent}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })
        )}
        {props.isLoading && !!props.rows.length && (
          <>
            <tr className="table__overlay_loader_wrapper"></tr>
            <tr className="table__overlay_loader">
              <td colSpan={totalColumnsSpan}>
                <div className="table__loader_wrapper">
                  <LoaderComponent type="primary" />
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default TableComponent;
