import { FC, useEffect } from "react";
import {
  faUser,
  faUserGear,
  faBuilding,
  faCar,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import SignUpDriverFormStep from "./common/types/sign-up-driver-form-step";
import { useSignUpDriverContext } from "./common/context/sign-up-driver.context";
import SignUpDriverAccountComponent from "./account/sign-up-driver-account.component";
import SignUpDriverUserComponent from "./user/sign-up-driver-user.component";
import SignUpDriverVehicleComponent from "./vehicle/sign-up-driver-vehicle.component";
import SignUpDriverCompanyComponent from "./company/sign-up-driver-company.component";
import SignUpDriverWizardFormComponent from "./common/components/wizard-form/sign-up-driver-wizard-form.component";
import SignUpDriverWizardFormStep from "./common/components/wizard-form/sign-up-driver-wizard-form-step";
import SignUpDriverSummaryComponent from "./summary/sign-up-driver-summary.component";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useQueryParams from "../../../../common/hooks/use-query-params";
import signUpTokenService from "../common/sign-up-token.service";
import SignUpDriverTokenPayload from "../common/types/sign-up-driver-token-payload";
import AppLogoHorizontalComponent from "../../../../common/components/app-logo/app-logo-horizontal.component";

type SignUpProps = {};

const SignUpDriverComponent: FC<SignUpProps> = () => {
  const driverSignUpTranslations =
    appTranslationsHelper.getDriverSignUpTranslations();

  const driverSignUpFormStepsTranslations = driverSignUpTranslations.formSteps;

  const {
    currentFormStep,
    formSteps,
    passedFormSteps,
    setTaxiCorporationId,
    setAccountDataEmail,
    setAccountDataUsername,
    userData,
    setUserDataFirstName,
    setUserDataLastName,
    setUserDataMobile,
    setUserDataAlternativeMobile,
    disableAccountDataEmail,
    disableAccountDataUsername,
    disableUserDataFirstName,
    disableUserDataLastName,
    disableUserDataMobileNumber,
    disableUserDataAlternativeMobileNumber,
    goToStep,
  } = useSignUpDriverContext();

  const { token } = useQueryParams<{ token: string | undefined }>();

  const onTokenDecodeSuccess = (tokenPayload: SignUpDriverTokenPayload) => {
    setTaxiCorporationId(tokenPayload.taxi_corporation_id);

    if (tokenPayload.user_data.email) {
      setAccountDataEmail(tokenPayload.user_data.email);

      disableAccountDataEmail();
    }

    if (tokenPayload.user_data.username) {
      setAccountDataUsername(tokenPayload.user_data.username);

      disableAccountDataUsername();
    }

    if (tokenPayload.user_data.first_name) {
      setUserDataFirstName(tokenPayload.user_data.first_name);

      disableUserDataFirstName();
    }

    if (tokenPayload.user_data.last_name) {
      setUserDataLastName(tokenPayload.user_data.last_name);

      disableUserDataLastName();
    }

    if (
      tokenPayload.user_data.mobile_primary ||
      tokenPayload.user_data.mobile_primary_prefix
    ) {
      setUserDataMobile({
        dialingCode:
          tokenPayload.user_data.mobile_primary_prefix ??
          userData.mobile.dialingCode,
        number: tokenPayload.user_data.mobile_primary ?? userData.mobile.number,
      });
    }

    if (
      tokenPayload.user_data.mobile_primary &&
      tokenPayload.user_data.mobile_primary_prefix
    ) {
      disableUserDataMobileNumber();
    }

    if (
      tokenPayload.user_data.mobile_secondary ||
      tokenPayload.user_data.mobile_secondary_prefix
    ) {
      setUserDataAlternativeMobile({
        dialingCode:
          tokenPayload.user_data.mobile_secondary_prefix ??
          userData.alternativeMobile.dialingCode,
        number:
          tokenPayload.user_data.mobile_secondary ??
          userData.alternativeMobile.number,
      });
    }

    if (
      tokenPayload.user_data.mobile_secondary &&
      tokenPayload.user_data.mobile_secondary_prefix
    ) {
      disableUserDataAlternativeMobileNumber();
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    try {
      const tokenPayload = signUpTokenService.decode(token);

      if (!signUpTokenService.checkIsDriver(tokenPayload)) {
        console.error(`Invalid token type. It is not driver token.`);
        return;
      }

      onTokenDecodeSuccess(tokenPayload);
    } catch (e) {
      console.error(`Invalid token`);
    }
  }, [token]);

  const wizardFormStepsDefinition: SignUpDriverWizardFormStep[] = [
    {
      key: SignUpDriverFormStep.accountData,
      component: <SignUpDriverAccountComponent />,
      description: driverSignUpFormStepsTranslations.accountData,
      icon: faUserGear,
      onSelect: () => goToStep(SignUpDriverFormStep.accountData),
    },
    {
      key: SignUpDriverFormStep.userData,
      component: <SignUpDriverUserComponent />,
      description: driverSignUpFormStepsTranslations.userData,
      icon: faUser,
      onSelect: () => goToStep(SignUpDriverFormStep.userData),
    },
    {
      key: SignUpDriverFormStep.companyData,
      component: <SignUpDriverCompanyComponent />,
      description: driverSignUpFormStepsTranslations.companyData,
      icon: faBuilding,
      onSelect: () => goToStep(SignUpDriverFormStep.companyData),
    },
    {
      key: SignUpDriverFormStep.vehicleData,
      component: <SignUpDriverVehicleComponent />,
      description: driverSignUpFormStepsTranslations.vehicleData,
      icon: faCar,
      onSelect: () => goToStep(SignUpDriverFormStep.vehicleData),
    },
    {
      key: SignUpDriverFormStep.summary,
      component: <SignUpDriverSummaryComponent />,
      description: driverSignUpFormStepsTranslations.summary,
      icon: faReceipt,
      onSelect: () => goToStep(SignUpDriverFormStep.summary),
    },
  ];

  const wizardFormSteps = formSteps.map(
    (formStep) =>
      wizardFormStepsDefinition.find(
        (wizardFormStep) => wizardFormStep.key === formStep
      )!
  );

  return (
    <div className="sign_up_driver">
      <div className="sign_up_driver__content">
        <div className="sign_up_driver__heading">
          <AppLogoHorizontalComponent className="sign_up_driver__logo" />
          <h1 className="sign_up_driver__title">
            {driverSignUpTranslations.headerTitle}
          </h1>
        </div>
        <SignUpDriverWizardFormComponent
          steps={wizardFormSteps}
          selectedStepKey={currentFormStep}
          passedStepsKeys={passedFormSteps}
        />
      </div>
    </div>
  );
};

export default SignUpDriverComponent;
