import { FC, useState } from "react";
import { PlanningOrderSelectedItemData } from "../common/types/planning-order-selected-data";
import { PlanningOrderDriverPlanListingSelectedItemToSent } from "./types/planner-order-driver-plan-listing-selected-item";
import { DriverPlanCandidaturePreviewCandidatureEntriesRequestData } from "../../../../common/services/driver-plan/preview/driver-plan-candidature-preview";

import PlanningOrderDriverPlanListingSelectedItemComponent from "./item/planning-order-driver-plan-listing-selected-item.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import planningTranslationsHelper from "../../../../languages/planning-translations.helper";
import useAbort from "../../../../common/hooks/use-abort";
import DriverPlanCandidatureAddError from "../../../../common/services/driver-plan/candidature-add/driver-plan-candidatue-add-error";
import driverPlanService from "../../../../common/services/driver-plan/driver-plan.service";

type PlanningOrderDriverPlanListingSelectedProps = {
  selectedItemsData: PlanningOrderSelectedItemData[];
  orderUuid: string | undefined;
  onClick: (value: number) => void;
};

const PlanningOrderDriverPlanListingSelectedComponent: FC<
  PlanningOrderDriverPlanListingSelectedProps
> = (props) => {
  const [itemsToSent, setItemsToSent] = useState<
    PlanningOrderDriverPlanListingSelectedItemToSent[]
  >([]);

  const [candidatureEntriesRequestData, setCandidatureEntriesRequestData] =
    useState<DriverPlanCandidaturePreviewCandidatureEntriesRequestData | null>(
      null
    );

  const candidatureAddAbort = useAbort();

  const addItemId = (value: number) => {
    setItemsToSent([...itemsToSent, { id: value }]);
  };

  const removeItemId = (value: number) => {
    const updatedList = itemsToSent.filter((item) => item.id !== value);
    setItemsToSent(updatedList);
  };

  const addCandidature = async (item: PlanningOrderSelectedItemData) => {
    const itemToSent = itemsToSent.find((i) => i.id === item.id);

    if (!itemToSent) return;

    setItemsToSent((prevItems) => {
      const updatedItems = prevItems.map((itemToSent) =>
        itemToSent.id === item.id ? { ...item, isPending: true } : itemToSent
      );
      return updatedItems;
    });

    try {
      await driverPlanService.candidatureAdd(
        {
          solvedOrderUuid: candidatureEntriesRequestData?.token!,
          driverUuid: item.driver.uuid,
          transportingOrderUuid:
            candidatureEntriesRequestData?.transportingOrderUuid!,
          taxiCorporationUuid:
            candidatureEntriesRequestData?.taxiCorporationUuid,
          givenPlanEntryUuid: candidatureEntriesRequestData?.givenPlanEntryUuid,
        },
        candidatureAddAbort.signal
      );

      onCandidatureAddSuccess(itemToSent);
    } catch (error) {
      onCandidatureAddFailure(
        itemToSent,
        error as DriverPlanCandidatureAddError
      );
    }
  };

  const onCandidatureAddFailure = (
    item: PlanningOrderDriverPlanListingSelectedItemToSent,
    error: DriverPlanCandidatureAddError
  ) => {
    if (!item) return;

    setItemsToSent((prevItems) => {
      const updatedItems = prevItems.map((itemToSent) =>
        itemToSent.id === item.id
          ? {
              ...item,
              isSuccess: false,
              errorMessage: error?.message,
              isPending: false,
            }
          : itemToSent
      );
      return updatedItems;
    });
  };

  const onCandidatureAddSuccess = (
    item: PlanningOrderDriverPlanListingSelectedItemToSent
  ) => {
    if (!item) return;

    setItemsToSent((prevItems) => {
      const updatedItems = prevItems.map((itemToSent) =>
        itemToSent.id === item.id
          ? { ...item, isSuccess: true, isPending: false }
          : itemToSent
      );
      return updatedItems;
    });
  };

  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing.staging;

  const ListingItemsContent = props.selectedItemsData.map((item, index) => {
    const itemToSent = itemsToSent.find((i) => i.id === item.id);

    return (
      <PlanningOrderDriverPlanListingSelectedItemComponent
        item={item}
        key={index}
        onClick={props.onClick}
        orderUuid={props.orderUuid}
        onMark={addItemId}
        onUnMark={removeItemId}
        itemToSent={itemToSent!}
        onSuccess={setCandidatureEntriesRequestData}
      />
    );
  });

  const handleOnClick = () => {
    props.selectedItemsData.forEach((item) => {
      itemsToSent.forEach((i) => {
        if (i.id === item.id && !i.isSuccess) addCandidature(item);
      });
    });
  };

  return (
    <>
      <div className="planning_order_driver_plan_items">
        {ListingItemsContent}
      </div>
      <ButtonComponent
        onClick={handleOnClick}
        type="primary"
        classNames={{
          root: "planning-order-driver-plan-listing-selected__action_button mt-4",
        }}
        title={translations.submitButtonTitle}
      >
        {translations.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default PlanningOrderDriverPlanListingSelectedComponent;
