import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../common/hooks/use-is-component-mounted";
import useTaxiListingUserPermissions from "./common/user-permissions/use-taxi-listing-user-permissions";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import { useAppContext } from "../../../context/app.context";
import taxiBreadcrumbsHelper from "../common/breadcrumbs/taxi-breadcrumbs.helper";
import useAbort from "../../../common/hooks/use-abort";
import TaxiCorporationListLoadParams from "../../../common/services/taxi-corporation/list/taxi-corporation-list-load-params";
import taxiListingLoadParamsFactory from "./common/taxi-listing-load-params.factory";
import useTaxiCorporationList from "../../../common/services/taxi-corporation/list/use-taxi-corporation-list";
import TaxiListingItem from "./common/types/taxi-listing-item";
import taxiListingItemFactory from "./common/taxi-listing-item.factory";
import useTaxiListingFiltersDao from "./filter/use-taxi-listing-filters.dao";
import TaxiListingSortKey from "./common/types/taxi-listing-sort-key";
import TaxiListingFilter from "./common/types/taxi-listing-filter";
import usePagination from "../../../common/hooks/use-pagination";
import taxiTranslationsHelper from "../../../languages/taxi-translations.helper";
import HeadingComponent from "../../../common/components/heading/heading.component";
import TaxiListingFilterComponent from "./filter/taxi-listing-filter.component";
import CardComponent from "../../../common/components/card/card.component";
import TaxiListingTableComponent from "./table/taxi-listing-table.component";
import ListingPaginationComponent from "../../../common/components/listing/pagination/listing-pagination.component";

type TaxiListingProps = {};

const TaxiListingComponent: FC<TaxiListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.taxiListing);

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs = taxiBreadcrumbsHelper.getListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const taxiList = useTaxiCorporationList();

  const listingItemsAbort = useAbort();

  const loadListingItems = async () => {
    const params: TaxiCorporationListLoadParams =
      taxiListingLoadParamsFactory.create(page, pageSize, filters, sortKey);

    taxiList.load(params, listingItemsAbort.signal);
  };

  const listingItems: TaxiListingItem[] = useMemo(() => {
    return taxiListingItemFactory.createListingItems(taxiList.data.data);
  }, [taxiList.data.data]);

  const filtersDao = useTaxiListingFiltersDao();

  const [sortKey, setSortKey] = useState<TaxiListingSortKey>(
    () => filtersDao.getSortKey() ?? TaxiListingSortKey.TAXI_COMPANY_NAME_ASC
  );

  const [filters, setFilters] = useState<TaxiListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: taxiList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const translations = taxiTranslationsHelper.getTaxiListingTranslations();

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <TaxiListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TaxiListingTableComponent
          listingItems={listingItems}
          isError={taxiList.isError}
          isLoading={taxiList.isLoading}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={taxiList.data.totalCount}
        />
      </CardComponent>
    </>
  );
};

export default TaxiListingComponent;
