import TaxiTaxiContractListLoadParams from "./taxi-taxi-contract-list-load-params";
import useAsyncData from "../../../hooks/use-async-data";
import TaxiTaxiContractList from "./taxi-taxi-contract-list";
import taxiTaxiContractService from "../taxi-taxi-contract.service";

const useTaxiTaxiContractList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<TaxiTaxiContractList>({ data: [], totalCount: 0 });

  const load = async (
    params: TaxiTaxiContractListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const taxiContractList = await taxiTaxiContractService.getList(
        params,
        signal
      );

      setData(taxiContractList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useTaxiTaxiContractList;
