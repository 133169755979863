import { FC, useEffect } from "react";
import PlanningOrderOrderDetailsRouteComponent from "./route/planning-order-order-details-route.component";
import useAbort from "../../../../common/hooks/use-abort";
import useCargoOrderDetailsComplex from "../../../../common/services/cargo-order/details-complex/use-cargo-order-details-complex";
import LoaderComponent from "../../../../common/components/loader/loader.component";
import NoticeBoxComponent from "../../../../common/components/notice-box/notice-box.component";
import NoticeBoxType from "../../../../common/components/notice-box/notice-box-type";

type PlanningOrderOrderDetailsProps = { orderUuid: string | undefined };

const PlanningOrderOrderDetailsComponent: FC<PlanningOrderOrderDetailsProps> = (
  props
) => {
  const cargoOrderDetailsAbort = useAbort();
  const cargoOrderDetails = useCargoOrderDetailsComplex();

  useEffect(() => {
    if (!props.orderUuid) {
      return;
    }

    cargoOrderDetails.load(
      {
        orderUuid: props.orderUuid,
      },
      cargoOrderDetailsAbort.signal
    );
  }, [props.orderUuid]);

  const LoaderContent = (
    <div className="planning_order_order_details__loader">
      <LoaderComponent type="primary" />
    </div>
  );

  const ErrorContent = (
    <NoticeBoxComponent type={NoticeBoxType.DANGER}>Error</NoticeBoxComponent>
  );

  const DetailsContent = (
    <PlanningOrderOrderDetailsRouteComponent
      orderDetails={cargoOrderDetails.data!}
    />
  );

  return (
    <div className="planning_order_order_details">
      {cargoOrderDetails.isLoading
        ? LoaderContent
        : cargoOrderDetails.isError
        ? ErrorContent
        : DetailsContent}
    </div>
  );
};

export default PlanningOrderOrderDetailsComponent;
