import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import cargoOfficerAddByRailyFormValidationService from "./cargo-officer-add-by-raily-form-validation.service";
import CargoOfficerAddByRailyFormData from "./types/cargo-officer-add-by-raily-form-data";

const getDefaultFormData = (): CargoOfficerAddByRailyFormData => {
  return {
    firstName: "",
    lastName: "",
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
    cargoCompanyUuid: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<CargoOfficerAddByRailyFormData> => {
    return {
      alternativePhoneNumber: (values) =>
        cargoOfficerAddByRailyFormValidationService.validateAlternativePhoneNumber(
          values.alternativePhoneNumber
        ),
      cargoCompanyUuid: (values) =>
        cargoOfficerAddByRailyFormValidationService.validateCargoCompany(
          values.cargoCompanyUuid
        ),
      email: (values) =>
        cargoOfficerAddByRailyFormValidationService.validateEmail(values.email),
      firstName: (values) =>
        cargoOfficerAddByRailyFormValidationService.validateFirstName(
          values.firstName
        ),
      lastName: (values) =>
        cargoOfficerAddByRailyFormValidationService.validateLastName(
          values.lastName
        ),
      password: (values) =>
        cargoOfficerAddByRailyFormValidationService.validatePassword(
          values.password
        ),
      passwordRepeat: (values) =>
        cargoOfficerAddByRailyFormValidationService.validatePasswordRepeat(
          values.password,
          values.passwordRepeat
        ),
      phoneNumber: (values) =>
        cargoOfficerAddByRailyFormValidationService.validatePhoneNumber(
          values.phoneNumber
        ),
      username: async (values) =>
        await cargoOfficerAddByRailyFormValidationService.validateUsername(
          values.username
        ),
    };
  };

const cargoOfficerAddByRailyFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default cargoOfficerAddByRailyFormHelper;
