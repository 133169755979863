import CargoCompanyDispatchAddRequest from "../../../../api/raily/cargo-company/dispatch/add/cargo-company-dispatch-add-request";
import CargoCompanyDispatchAddParams from "./cargo-company-dispatch-add-params";

const create = (
  params: CargoCompanyDispatchAddParams
): CargoCompanyDispatchAddRequest => {
  return {
    addressString: params.address,
    companyId: params.companyUuid,
    dispatchName: params.name,
    displayName: params.displayName,
  };
};

const cargoCompanyDispatchAddRequestFactory = {
  create,
};

export default cargoCompanyDispatchAddRequestFactory;
