import ListingSortSelectComponent from "./sort/select/listing-sort-select.component";
import ListingFilterSelectComponent from "./select/listing-filter-select.component";
import ListingFilterBadgeListComponent from "./badge-list/listing-filter-badge-list.component";
import ListingFilterSelectOption from "../../../types/listing-filter-select-option";
import ListingFilter from "../../../types/listing-filter";
import ListingFilterBadge from "./badge/listing-filter-badge";
import ListingFilterDefinition from "./types/listing-filter-definition";
import ListingSortDefinition from "./types/listing-sort-definition";
import listingFilterFactory from "./listing-filter.factory";
import ListingDateFilterComponent from "./date/listing-date-filter.component";
import ListingDateRangeFilterComponent from "./date-range/listing-date-range-filter.component";
import DateRange from "../../../types/date-range";
import { useState } from "react";
import ListingSortSelectOption from "../../../types/listing-sort-select-option";
import SelectOption from "../../form/select/common/option/select-option";
import ListingNumericRangeFilterComponent from "./numeric-range/listing-numeric-range-filter.component";
import NumericRange from "../../../types/numeric-range";

type ListingFilterProps<T extends ListingFilter> = {
  filterDefinition: ListingFilterDefinition<T>;
  appliedFilters: ListingFilter[];
  onFiltersChange: (appliedFilters: ListingFilter[]) => void;
  sortDefinition: ListingSortDefinition;
  appliedSortKey: string;
  onSortKeyChange: (appliedSortKey: string) => void;
  idForTestingFilter?: string;
  idForTestingSort?: string;
};

const ListingFilterComponent = <T extends ListingFilter>(
  props: ListingFilterProps<T>
) => {
  const [filterSearchQuery, setFilterSearchQuery] = useState("");
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [dateFilterData, setDateFilterData] = useState<{
    filterType: T["type"];
  } | null>(null);

  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [dateRangeFilterData, setDateRangeFilterData] = useState<{
    filterType: T["type"];
  } | null>(null);

  const [isNumericRangeModalOpen, setIsNumericRangeModalOpen] = useState(false);
  const [numericRangeFilterData, setNumericRangeFilterData] = useState<{
    filterType: T["type"];
  } | null>(null);

  const openDateSelectModal = (filterType: T["type"]) => {
    setIsDateModalOpen(true);
    setDateFilterData({ filterType });
  };

  const closeDateSelectModal = () => {
    setIsDateModalOpen(false);
  };

  const openDateRangeSelectModal = (filterType: T["type"]) => {
    setIsDateRangeModalOpen(true);
    setDateRangeFilterData({ filterType });
  };

  const closeDateRangeSelectModal = () => {
    setIsDateRangeModalOpen(false);
  };

  const openNumericRangeSelectModal = (filterType: T["type"]) => {
    setIsNumericRangeModalOpen(true);
    setNumericRangeFilterData({ filterType });
  };

  const closeNumericRangeSelectModal = () => {
    setIsNumericRangeModalOpen(false);
  };

  const sortSelectOptions: ListingSortSelectOption[] =
    listingFilterFactory.createSortSelectOptions(props.sortDefinition);

  const selectedSortSelectOption =
    sortSelectOptions.find((option) => option.value === props.appliedSortKey) ??
    null;

  const onDateFilterClick = (filterType: T["type"]) => {
    openDateSelectModal(filterType);
  };

  const onDateRangeFilterClick = (filterType: T["type"]) => {
    openDateRangeSelectModal(filterType);
  };

  const onNumericRangeFilterClick = (filterType: T["type"]) => {
    openNumericRangeSelectModal(filterType);
  };

  const filterSelectOptions: ListingFilterSelectOption[] =
    listingFilterFactory.createFilterSelectOptions(
      props.filterDefinition,
      props.appliedFilters,
      filterSearchQuery,
      onDateFilterClick,
      onDateRangeFilterClick,
      onNumericRangeFilterClick
    );

  const onFilterSelectChange = (
    filterSelectOption: ListingFilterSelectOption
  ) => {
    addFilter(filterSelectOption.value);
  };

  const addFilter = (filter: ListingFilter) => {
    const newAppliedFilters: ListingFilter[] = [
      ...props.appliedFilters,
      filter,
    ];

    props.onFiltersChange(newAppliedFilters);
  };

  const onDeleteFilter = (index: number) => {
    const newAppliedFilters: ListingFilter[] = props.appliedFilters.filter(
      (_filter, filterIndex) => index !== filterIndex
    );

    props.onFiltersChange(newAppliedFilters);
  };

  const onDeleteAllFilters = () => {
    props.onFiltersChange([]);
  };

  const onSortSelectOptionChange = (option: SelectOption) => {
    props.onSortKeyChange(option.value);
  };

  const filterBadges: ListingFilterBadge[] =
    listingFilterFactory.createFilterBadges(
      props.filterDefinition,
      props.appliedFilters
    );

  const onDateFilterApply = (date: Date) => {
    const newFilter: ListingFilter = {
      type: dateFilterData!.filterType,
      value: date,
    };

    addFilter(newFilter);
    closeDateSelectModal();
  };

  const onDateRangeFilterApply = (dateRange: DateRange) => {
    const newFilter: ListingFilter = {
      type: dateRangeFilterData!.filterType,
      value: dateRange,
    };

    addFilter(newFilter);
    closeDateRangeSelectModal();
  };

  const onNumericRangeFilterApply = (numericRange: NumericRange) => {
    const newFilter: ListingFilter = {
      type: numericRangeFilterData!.filterType,
      value: numericRange,
    };

    addFilter(newFilter);
    closeNumericRangeSelectModal();
  };

  return (
    <>
      <div className="listing_filter">
        <ListingFilterSelectComponent
          onChange={(option) =>
            onFilterSelectChange(option as ListingFilterSelectOption)
          }
          options={filterSelectOptions}
          inputValue={filterSearchQuery}
          onInputChange={setFilterSearchQuery}
          idForTesting={props.idForTestingFilter}
        />
        <ListingSortSelectComponent
          onChange={(option) =>
            onSortSelectOptionChange(option as SelectOption)
          }
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting={props.idForTestingSort}
        />
      </div>
      <ListingFilterBadgeListComponent
        badges={filterBadges}
        onDeleteAll={onDeleteAllFilters}
        onDelete={onDeleteFilter}
      />
      <ListingDateFilterComponent
        isOpen={isDateModalOpen}
        onApply={onDateFilterApply}
        onClose={closeDateSelectModal}
      />
      <ListingDateRangeFilterComponent
        isOpen={isDateRangeModalOpen}
        onClose={closeDateRangeSelectModal}
        onApply={onDateRangeFilterApply}
      />
      <ListingNumericRangeFilterComponent
        isOpen={isNumericRangeModalOpen}
        onClose={closeNumericRangeSelectModal}
        onApply={onNumericRangeFilterApply}
      />
    </>
  );
};

export default ListingFilterComponent;
