import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import OrderDetailsManageDriverAssignmentFormData from "./types/order-details-manage-driver-assignment-form-data";
import OrderDetailsManageDriverAssignmentFormValidationResults from "./types/order-details-manage-driver-assignment-form-validation-results";

const getDefaultFormData = (): OrderDetailsManageDriverAssignmentFormData => {
  return {
    assignmentOption: null,
    driver: null,
    taxiCorporation: null,
    acceptImmediately: false,
  };
};

const getDefaultFormValidationResults =
  (): OrderDetailsManageDriverAssignmentFormValidationResults => {
    return {
      assignmentOption: formValidationService.defaultValidationResult,
      driver: formValidationService.defaultValidationResult,
      taxiCorporation: formValidationService.defaultValidationResult,
      acceptImmediately: formValidationService.defaultValidationResult,
    };
  };

const orderDetailsManageDriverAssignmentHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default orderDetailsManageDriverAssignmentHelper;
