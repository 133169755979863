import RailyOfficerAddRequest from "../../../api/raily/raily-officer/add/raily-officer-add.request";
import RailyOfficerAddLoadParams from "./raily-officer-add-load-params";

const create = (params: RailyOfficerAddLoadParams): RailyOfficerAddRequest => {
  return {
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.phoneNumber.number,
    mobilePrimaryPrefix: params.phoneNumber.dialingCode,
    mobileSecondary: params.alternativePhoneNumber.number || null,
    mobileSecondaryPrefix: params.alternativePhoneNumber.dialingCode || null,
    password: params.password,
    username: params.username,
  };
};

const railyOfficerAddRequestFactory = {
  create,
};

export default railyOfficerAddRequestFactory;
