import useAsyncData from "../../../hooks/use-async-data";
import messengerService from "../messenger.service";
import MessengerPostsMap from "./messenger-post";
import MessengerPostLoadParams from "./messenger-post-load-params";

const useMessengerPost = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MessengerPostsMap | null>(null);

  const load = async (params: MessengerPostLoadParams, signal: AbortSignal) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const messengerPostsMap = await messengerService.getPosts(params, signal);

      setData(messengerPostsMap);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const reload = async (
    params: MessengerPostLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const channelName = params.availableChannels[0];

      const channelPostsMap = await messengerService.getPosts(params, signal);

      const newData = data;
      newData?.set(channelName, channelPostsMap.get(channelName)!);

      setData(new Map(newData));
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
    reload,
  };
};

export default useMessengerPost;
