enum OrderActiveListingSortKey {
  INTERNAL_ORDER_ID_ASC = "INTERNAL_ORDER_ID_ASC",
  INTERNAL_ORDER_ID_DESC = "INTERNAL_ORDER_ID_DESC",
  IS_MOBILE_APP_USER_ASC = "IS_MOBILE_APP_USER_ASC",
  IS_MOBILE_APP_USER_DESC = "IS_MOBILE_APP_USER_DESC",
  EXECUTION_STATUS_ASC = "EXECUTION_STATUS_ASC",
  EXECUTION_STATUS_DESC = "EXECUTION_STATUS_DESC",
  TIME_TO_START_ASC = "TIME_TO_START_ASC",
  TIME_TO_START_DESC = "TIME_TO_START_DESC",
  URGENT_ASC = "URGENT_ASC",
  URGENT_DESC = "URGENT_DESC",
}

export default OrderActiveListingSortKey;
