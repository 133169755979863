import HttpResponse from "../../../../utils/http/http.response";

export enum MileageListResponseState {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DELEGATION_CREATED = "DELEGATION_CREATED",
}

export enum MileageListResponseVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type MileageListResponseAddressSequenceNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
};

export type MileageListResponseAddressSequence = {
  seq: MileageListResponseAddressSequenceNode[];
};

export type MileageListResponseItem = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  workerId: string;
  workerCompanyId: string;
  mileageDate: Date;
  cardNumber?: string | null;
  commissionNumber?: string | null;
  cargoCompanyName: string;
  mileageNumber?: string | null;
  notes?: string | null;
  state: MileageListResponseState;
  vehicleType: MileageListResponseVehicleType;
  distance: number;
  mileageCost: number;
  delegationId?: string | null;
  hasDelegation: boolean;
  humanId: number;
  workerName: string;
  workerPhone: string;
  addressSeq: MileageListResponseAddressSequence;
  startAddressLabel: string;
  midAddressesLabels: string[];
  endAddressLabel: string;
  osrmDistance: number;
  acceptedDistance?: number;
};

type MileageListResponse = HttpResponse<{
  data: MileageListResponseItem[];
  totalCount: number;
}>;

export default MileageListResponse;
