import TaxiDriverContractAddRequest, {
  TaxiDriverContractAddRequestBillingModel,
} from "../../../api/raily/taxi-driver-contract/add/taxi-driver-contract-add.request";
import BillingModel from "../../../types/billing-model";
import TaxiDriverContractAddParams from "./taxi-driver-contract-add-params";

const createModel = (
  billingModel: BillingModel
): TaxiDriverContractAddRequestBillingModel => {
  switch (billingModel) {
    case BillingModel.AB:
      return TaxiDriverContractAddRequestBillingModel.AB;
    case BillingModel.ABA:
      return TaxiDriverContractAddRequestBillingModel.ABA;
    case BillingModel.SABS:
      return TaxiDriverContractAddRequestBillingModel.SABS;
    case BillingModel.OTHER:
      return TaxiDriverContractAddRequestBillingModel.OTHER;
  }
};

const create = (
  params: TaxiDriverContractAddParams
): TaxiDriverContractAddRequest => {
  return {
    disabled: !params.isActive,
    distanceRate: params.distanceRate,
    haltingTimeAfterMinutes: params.freeStoppingPeriodMinutes,
    haltingTimeRate: params.stoppingRate,
    model: createModel(params.billingModel),
    taxiDriverAssociationId: params.taxiDriverAssociationUuid,
    validSince: params.periodOfValidity.from,
    validTo: params.periodOfValidity.to,
  };
};

const taxiDriverContractAddRequestFactory = {
  create,
};

export default taxiDriverContractAddRequestFactory;
