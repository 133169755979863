import MileageContractTemplateResponse, {
  MileageContractTemplateResponseData,
} from "../../../api/raily/mileage-contract/template/mileage-contract-template.response";
import MileageContractTemplate from "./mileage-contract-template";

const createTemplate = (
  item: MileageContractTemplateResponseData
): MileageContractTemplate => {
  return {
    dietFullAfterHours: item.dietFullAfterHours,
    dietFullRate: item.dietFullRate,
    dietHalfAfterHours: item.dietHalfAfterHours,
    dietHalfRate: item.dietHalfRate,
    distanceRateCarGt900cm3: item.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: item.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: item.distanceRateCompanyVehicle,
    distanceRateMoped: item.distanceRateMoped,
    distanceRateMotorcycle: item.distanceRateMotorcycle,
  };
};

const create = (
  response: MileageContractTemplateResponse
): MileageContractTemplate => {
  return createTemplate(response.data);
};

const mileageContractTemplateFactory = {
  create,
};

export default mileageContractTemplateFactory;
