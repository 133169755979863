import { CargoOrderSettlementListingStatusCountersResponseData } from "./api/cargo-order-settlement-listing-status-counters.response";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import StatusLegendStatuses from "../../../../../common/components/status-legend/types/status-legend-statuses";
import StatusLegendData from "../../../../../common/components/status-legend/types/status-legend-data";
import CargoOrderSettlementListingItemBillingStatus from "./types/cargo-order-settlement-listing-item-billing-status";

const getStatusText = (
  status: CargoOrderSettlementListingItemBillingStatus
): string => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .status;

  switch (status) {
    case CargoOrderSettlementListingItemBillingStatus.CREATED:
      return translations.CREATED;
    case CargoOrderSettlementListingItemBillingStatus.ACCEPTED:
      return translations.ACCEPTED;
    case CargoOrderSettlementListingItemBillingStatus.REJECTED:
      return translations.REJECTED;
    case CargoOrderSettlementListingItemBillingStatus.REOPENED:
      return translations.REOPENED;
    case CargoOrderSettlementListingItemBillingStatus.REOPEN_REQUEST_SENT:
      return translations.REOPEN_REQUEST_SENT;
    default:
      return "";
  }
};

const getStatusOptions = (
  data: CargoOrderSettlementListingStatusCountersResponseData
): StatusLegendData[] => {
  type ObjKey = keyof typeof data;
  const result = [];

  for (const key in data) {
    const status = key.toUpperCase();

    if (
      data.hasOwnProperty(key) &&
      status !== StatusLegendStatuses.ACCEPTED &&
      status !== StatusLegendStatuses.CREATED
    ) {
      result.push({
        status: status,
        totalCount: data[key as ObjKey],
      });
    }
  }
  return result;
};

const cargoOrderSettlementListingHelper = {
  getStatusText,
  getStatusOptions,
};

export default cargoOrderSettlementListingHelper;
