import classNames from "classnames";
import { FC, useMemo } from "react";
import { SteppedLineTo } from "react-lineto";
import mileageTranslationsHelper from "../../../../../../../languages/mileage-translations.helper";
import mileageAddSummaryRouteHelper from "./factory/mileage-add-summary-route.helper";
import MileageAddSummaryRouteWaypoint from "./types/mileage-add-summary-route-waypoint";

type MileageAddSummaryRouteProps = {
  waypoints: MileageAddSummaryRouteWaypoint[];
};

const MileageAddSummaryRouteComponent: FC<MileageAddSummaryRouteProps> = (
  props
) => {
  const filteredWaypoints = useMemo(() => {
    return props.waypoints.filter((worker) => !!worker.uuid);
  }, [props.waypoints]);

  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().summary.routes;

  const lineConnections =
    mileageAddSummaryRouteHelper.getDefinitionOfLineConnectionsBetweenWaypoints(
      filteredWaypoints
    );

  return (
    <div
      className="mileage_add_summary_route"
      data-test-id="mileage-add-summary-route"
    >
      <div className="mileage_add_summary_route_waypoint_list">
        <div className="mileage_add_summary_route_waypoint_list__header">
          <div
            className="mileage_add_summary_route_waypoint_list__heading place"
            title={translations.placeHeaderTitle}
          >
            {translations.placeHeaderLabel}
          </div>
          <div
            className="mileage_add_summary_route_waypoint_list__heading distance"
            title={translations.distanceHeaderTitle}
          >
            {translations.distanceHeaderLabel}
          </div>
          <div
            className="mileage_add_summary_route_waypoint_list__heading distance"
            title={translations.timeHeaderTitle}
          >
            {translations.timeHeaderLabel}
          </div>
        </div>
        {filteredWaypoints.map((waypoint, index) => {
          const isFirstWaypoint = index === 0;
          const isLastWaypoint = index === filteredWaypoints.length - 1;

          const isFaded = !isFirstWaypoint && !isLastWaypoint;

          return (
            <div
              className={classNames(
                "mileage_add_summary_route_waypoint_list__row",
                isFirstWaypoint && "first_highlight",
                isLastWaypoint && "last_highlight",
                isFaded && "faded"
              )}
              key={`mileage-add-summary-route-waypoint-index-${index}`}
              data-test-id={`mileage-add-summary-route-waypoint-index-${index}`}
            >
              <div
                className="mileage_add_summary_route_waypoint_list__row_cell place"
                title={mileageAddSummaryRouteHelper.getPlaceTitle(
                  waypoint.place
                )}
              >
                <div className="index">{index + 1}</div>
                <div
                  className={classNames(
                    "dot",
                    mileageAddSummaryRouteHelper.generateWaypointDotClassname(
                      waypoint
                    )
                  )}
                ></div>
                <div className="mileage_add_summary_route_waypoint_list__row_cell place">
                  {mileageAddSummaryRouteHelper.getPlaceLabel(waypoint.place)}
                </div>
              </div>
              <div className="mileage_add_summary_route_waypoint_list__row_cell distance">
                {mileageAddSummaryRouteHelper.getDistanceLabel(
                  waypoint.distance
                )}
              </div>
              <div className="mileage_add_summary_route_waypoint_list__row_cell time">
                {mileageAddSummaryRouteHelper.getTimeLabel(waypoint.time)}
              </div>
            </div>
          );
        })}
      </div>
      {lineConnections.map((item, index) => {
        return (
          <SteppedLineTo
            key={`waypoint-list-connection-line-index-${index}-type-${item.lineType}`}
            from={item.itemFromClassname}
            to={item.itemToClassname}
            delay={50}
            borderStyle={item.lineType}
            borderWidth={1}
            borderColor={"#000"}
            within={`mileage_add_summary_route`}
            className="mileage_add_summary__connection_line"
          />
        );
      })}
    </div>
  );
};

export default MileageAddSummaryRouteComponent;
