import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCircleInfo, faEdit } from "@fortawesome/free-solid-svg-icons";
import { BillingsOrderForwardTaxiDriverItem } from "../../../../../../common/services/billings/order-forward/taxi-driver/billings-order-forward-taxi-driver";
import LinkButtonComponent from "../../../../../../common/components/button/link/link-button.component";
import dateService from "../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import billingRoutesHelper from "../../../../common/routes/billing-routes.helper";
import RelatedBillingsTaxiDriverTableRow from "../../../../common/related-billings/taxi-driver/types/related-billings-taxi-driver-table-row";

const getTableRow = (
  billingData: BillingsOrderForwardTaxiDriverItem,
  planEntryId: string
): RelatedBillingsTaxiDriverTableRow => {
  const translations =
    billingsTranslationsHelper.getBillingsTranslations().relatedBillings
      .taxi_driver.table;

  return {
    id: billingData.billingUuid.toString(),
    value: {
      billingStatus: <></>,
      date: (
        <div title={dateService.format(billingData.date, "dd/mm/yyyy")}>
          {dateService.format(billingData.date, "dd/mm/yyyy")}
        </div>
      ),
      internalOrderId: (
        <div title={billingData.internalOrderId.toString()}>
          {billingData.internalOrderId}
        </div>
      ),
      companyName: (
        <div title={billingData.contractDetails?.companyName}>
          {billingData.contractDetails?.companyName}
        </div>
      ),
      model: (
        <div title={billingData.contractDetails?.model}>
          {billingData.contractDetails?.model}
        </div>
      ),
      rate: (
        <div title={billingData.contractDetails?.distanceRate.toString()}>
          {billingData.contractDetails?.distanceRate.toFixed(2)} PLN
        </div>
      ),
      distance: (
        <div title={billingData.distance.toString()}>
          {billingData.distance}
        </div>
      ),
      amountForDistance: (
        <div title={billingData.amountForDistance?.toString()}>
          {billingData.amountForDistance?.toFixed(2)} PLN
        </div>
      ),
      sumOfBonuses: (
        <div title={billingData.sumOfBonuses.toString()}>
          {billingData.sumOfBonuses.toFixed(2)} PLN
        </div>
      ),
      sumOfPenalties: (
        <div title={billingData.sumOfPenalties.toString()}>
          {billingData.sumOfPenalties.toFixed(2)} PLN
        </div>
      ),
      amountForChargeHaltings: (
        <div title={billingData.amountForChargeHaltings?.toString()}>
          {billingData.amountForChargeHaltings?.toFixed(2)} PLN
        </div>
      ),
      amountForChargeHighways: (
        <div title={billingData.amountForChargeHighways?.toString()}>
          {billingData.amountForChargeHighways?.toFixed(2)} PLN
        </div>
      ),
      allContributionsAmount: (
        <div title={billingData?.allContributionsAmount?.toString()}>
          {billingData.allContributionsAmount?.toFixed(2)} PLN
        </div>
      ),
      actions: billingData.isDraft ? (
        <LinkButtonComponent
          type="primary"
          openInNewTab
          title={translations.addActionLabel}
          to={billingRoutesHelper.getTaxiWithDriverAddRoute({
            planEntryUuid: planEntryId,
          })}
          classNames={{
            root: "related_billings_table_icon",
          }}
        >
          <FontAwesomeIcon icon={faAdd} />
        </LinkButtonComponent>
      ) : (
        <div className={"related_billings_actions"}>
          <LinkButtonComponent
            type="primary"
            openInNewTab
            title={translations.editActionLabel}
            to={billingRoutesHelper.getTaxiWithDriverEditRoute({
              billingUuid: billingData.billingUuid,
            })}
            classNames={{
              root: "related_billings_table_icon",
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </LinkButtonComponent>
          <LinkButtonComponent
            type="primary"
            openInNewTab
            title={translations.viewActionLabel}
            to={billingRoutesHelper.getTaxiWithDriverDetailsRoute({
              billingUuid: billingData.billingUuid,
            })}
            classNames={{
              root: "related_billings_table_icon",
            }}
          >
            <FontAwesomeIcon icon={faCircleInfo} />
          </LinkButtonComponent>
        </div>
      ),
    },
  };
};

const createTableRows = (
  listingItems: BillingsOrderForwardTaxiDriverItem[],
  planEntryId: string
): RelatedBillingsTaxiDriverTableRow[] => {
  return listingItems.map((listingItem) =>
    getTableRow(listingItem, planEntryId)
  );
};

const realtedBillingsTaxiDriverFactory = {
  createTableRows,
};

export default realtedBillingsTaxiDriverFactory;
