import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-sort-definition";
import { DelegationListItemType } from "../../../../common/services/delegation/list/delegation-list";
import dateService from "../../../../common/utils/date/date.service";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import DelegationListingHelper from "../common/delegation-listing.helper";
import DelegationListingFilter, {
  DelegationListingCostFilter,
  DelegationListingCreationDateFilter,
  DelegationListingDelegationNumberFilter,
  DelegationListingEndDateFilter,
  DelegationListingFirstCsvDownloadDateFilter,
  DelegationListingFirstPdfDownloadDateFilter,
  DelegationListingStartDateFilter,
  DelegationListingDelegationTypeFilter,
  DelegationListingWorkerNameFilter,
  DelegationListingCargoCompanyNameFilter,
  DelegationListingTotalDistanceFilter,
} from "../common/types/delegation-listing-filter";
import DelegationListingFilterType from "../common/types/delegation-listing-filter-type";
import DelegationListingSortKey from "../common/types/delegation-listing-sort-key";

const delegationListingFilterHelper = () => {
  const translations =
    delegationTranslationsHelper.getDelegationListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.delegationNumberAscLabel,
        value: DelegationListingSortKey.DELEGATION_NUMBER_ASC,
      },
      {
        label: translations.sort.delegationNumberDescLabel,
        value: DelegationListingSortKey.DELEGATION_NUMBER_DESC,
      },
      {
        label: translations.sort.cargoCompanyNameAscLabel,
        value: DelegationListingSortKey.CARGO_COMPANY_NAME_ASC,
      },
      {
        label: translations.sort.cargoCompanyNameDescLabel,
        value: DelegationListingSortKey.CARGO_COMPANY_NAME_DESC,
      },
      {
        label: translations.sort.workerNameAscLabel,
        value: DelegationListingSortKey.WORKER_NAME_ASC,
      },
      {
        label: translations.sort.workerNameDescLabel,
        value: DelegationListingSortKey.WORKER_NAME_DESC,
      },
      {
        label: translations.sort.costAscLabel,
        value: DelegationListingSortKey.COST_ASC,
      },
      {
        label: translations.sort.costDescLabel,
        value: DelegationListingSortKey.COST_DESC,
      },
      {
        label: translations.sort.totalDistanceAscLabel,
        value: DelegationListingSortKey.TOTAL_DISTANCE_ASC,
      },
      {
        label: translations.sort.totalDistanceDescLabel,
        value: DelegationListingSortKey.TOTAL_DISTANCE_DESC,
      },
      {
        label: translations.sort.startDateAscLabel,
        value: DelegationListingSortKey.START_DATE_ASC,
      },
      {
        label: translations.sort.startDateDescLabel,
        value: DelegationListingSortKey.START_DATE_DESC,
      },
      {
        label: translations.sort.endDateAscLabel,
        value: DelegationListingSortKey.END_DATE_ASC,
      },
      {
        label: translations.sort.endDateDescLabel,
        value: DelegationListingSortKey.END_DATE_DESC,
      },
      {
        label: translations.sort.creationDateAscLabel,
        value: DelegationListingSortKey.CREATION_DATE_ASC,
      },
      {
        label: translations.sort.creationDateDescLabel,
        value: DelegationListingSortKey.CREATION_DATE_DESC,
      },
      {
        label: translations.sort.firstCsvDownloadDateAscLabel,
        value: DelegationListingSortKey.FIRST_CSV_DOWNLOAD_ASC,
      },
      {
        label: translations.sort.firstCsvDownloadDateDescLabel,
        value: DelegationListingSortKey.FIRST_CSV_DOWNLOAD_DESC,
      },
      {
        label: translations.sort.firstPdfDownloadDateAscLabel,
        value: DelegationListingSortKey.FIRST_PDF_DOWNLOAD_ASC,
      },
      {
        label: translations.sort.firstPdfDownloadDateDescLabel,
        value: DelegationListingSortKey.FIRST_PDF_DOWNLOAD_DESC,
      },
    ];
  };

  const getSelectDataForDelegationStartDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.startDateLabel,
        },
      };
    };

  const getSelectDataForDelegationEndDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.endDateLabel,
        },
      };
    };

  const getSelectDataForDelegationCreationDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.creationDateLabel,
        },
      };
    };

  const getSelectDataForDelegationFirstCsvDownloadDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.firstCsvDownloadDateLabel,
        },
      };
    };

  const getSelectDataForDelegationFirstPdfDownloadDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.firstPdfDownloadDateLabel,
        },
      };
    };

  const getSelectDataForCargoCompanyName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.cargoCompanyNameLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForDelegationNumber = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.delegationNumberLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForDelegationWorkerName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.workerNameLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForExecutionStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.typeLabel.replace(
              "#{query}",
              translations.type.automatic
            ),
            value: DelegationListItemType.AUTO,
          },
          {
            label: translations.filters.search.typeLabel.replace(
              "#{query}",
              translations.type.manual
            ),
            value: DelegationListItemType.MANUAL,
          },
        ],
      };
    };

  const getSelectDataForDelegationCost = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_RANGE,
      option: {
        label: translations.filters.search.costLabel,
      },
    };
  };

  const getSelectDataForTotalDistance = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_RANGE,
      option: {
        label: translations.filters.search.totalDistaneLabel,
      },
    };
  };

  const getSelectDataByType = (
    filterType: DelegationListingFilterType,
    query: string
  ) => {
    const options: Record<
      DelegationListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [DelegationListingFilterType.DELEGATION_NUMBER]:
        getSelectDataForDelegationNumber,
      [DelegationListingFilterType.START_DATE]:
        getSelectDataForDelegationStartDate,
      [DelegationListingFilterType.END_DATE]: getSelectDataForDelegationEndDate,
      [DelegationListingFilterType.CARGO_COMPANY_NAME]:
        getSelectDataForCargoCompanyName,
      [DelegationListingFilterType.CREATION_DATE]:
        getSelectDataForDelegationCreationDate,
      [DelegationListingFilterType.FIRST_CSV_DOWNLOAD]:
        getSelectDataForDelegationFirstCsvDownloadDate,
      [DelegationListingFilterType.FIRST_PDF_DOWNLOAD]:
        getSelectDataForDelegationFirstPdfDownloadDate,
      [DelegationListingFilterType.WORKER_NAME]:
        getSelectDataForDelegationWorkerName,
      [DelegationListingFilterType.COST]: getSelectDataForDelegationCost,
      [DelegationListingFilterType.TOTAL_DISTANCE]:
        getSelectDataForTotalDistance,
      [DelegationListingFilterType.DELEGATION_TYPE]:
        getSelectDefinitionForExecutionStatus,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeForDelegationStartDate = (
    filterValue: DelegationListingStartDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.startDateLabel,
      translations.filters.startDateTitle
    );
  };

  const getBadgeForDelegationEndDate = (
    filterValue: DelegationListingEndDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.endDateLabel,
      translations.filters.endDateTitle
    );
  };

  const getBadgeForDelegationCreationDate = (
    filterValue: DelegationListingCreationDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.creationDateLabel,
      translations.filters.creationDateTitle
    );
  };

  const getBadgeForDelegationFirstCsvDownloadDate = (
    filterValue: DelegationListingFirstCsvDownloadDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.firstCsvDateLabel,
      translations.filters.firstCsvDateTitle
    );
  };

  const getBadgeForDelegationFirstPdfDownloadDate = (
    filterValue: DelegationListingFirstPdfDownloadDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.firstPdfDateLabel,
      translations.filters.firstPdfDateTitle
    );
  };

  const getBadgeForCargoCompanyName = (
    filterValue: DelegationListingCargoCompanyNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      String(filterValue),
      translations.filters.cargoCompanyNameLabel,
      translations.filters.cargoCompanyNameTitle
    );
  };

  const getBadgeForDelegationNumber = (
    filterValue: DelegationListingDelegationNumberFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      String(filterValue),
      translations.filters.delegationNumberLabel,
      translations.filters.delegationNumberTitle
    );
  };

  const getBadgeForDelegationWorkerName = (
    filterValue: DelegationListingWorkerNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.workerLabel,
      translations.filters.workerTitle
    );
  };

  const getBadgeForDelegationCost = (
    filterValue: DelegationListingCostFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${filterValue.from}-${filterValue.to}`,
      translations.filters.costLabel,
      translations.filters.costTitle
    );
  };

  const getBadgeForTotalDistance = (
    filterValue: DelegationListingCostFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${filterValue.from}-${filterValue.to}`,
      translations.filters.totalDistanceLabel,
      translations.filters.totalDistanceTitle
    );
  };

  const getBadgeForDelegationType = (
    filterValue: DelegationListingDelegationTypeFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      DelegationListingHelper.getTypeText(filterValue),
      translations.filters.typeLabel,
      translations.filters.typeTitle
    );
  };

  const getBadgeDefinitionByType = (
    filterType: DelegationListingFilterType,
    filterValue: DelegationListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      DelegationListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [DelegationListingFilterType.START_DATE]: () =>
        getBadgeForDelegationStartDate(
          filterValue as DelegationListingStartDateFilter["value"]
        ),
      [DelegationListingFilterType.END_DATE]: () =>
        getBadgeForDelegationEndDate(
          filterValue as DelegationListingEndDateFilter["value"]
        ),
      [DelegationListingFilterType.CREATION_DATE]: () =>
        getBadgeForDelegationCreationDate(
          filterValue as DelegationListingCreationDateFilter["value"]
        ),
      [DelegationListingFilterType.CARGO_COMPANY_NAME]: () =>
        getBadgeForCargoCompanyName(
          filterValue as DelegationListingCargoCompanyNameFilter["value"]
        ),
      [DelegationListingFilterType.FIRST_CSV_DOWNLOAD]: () =>
        getBadgeForDelegationFirstCsvDownloadDate(
          filterValue as DelegationListingFirstCsvDownloadDateFilter["value"]
        ),
      [DelegationListingFilterType.FIRST_PDF_DOWNLOAD]: () =>
        getBadgeForDelegationFirstPdfDownloadDate(
          filterValue as DelegationListingFirstPdfDownloadDateFilter["value"]
        ),
      [DelegationListingFilterType.DELEGATION_NUMBER]: () =>
        getBadgeForDelegationNumber(
          filterValue as DelegationListingDelegationNumberFilter["value"]
        ),
      [DelegationListingFilterType.WORKER_NAME]: () =>
        getBadgeForDelegationWorkerName(
          filterValue as DelegationListingWorkerNameFilter["value"]
        ),
      [DelegationListingFilterType.COST]: () =>
        getBadgeForDelegationCost(
          filterValue as DelegationListingCostFilter["value"]
        ),
      [DelegationListingFilterType.TOTAL_DISTANCE]: () =>
        getBadgeForTotalDistance(
          filterValue as DelegationListingTotalDistanceFilter["value"]
        ),
      [DelegationListingFilterType.DELEGATION_TYPE]: () =>
        getBadgeForDelegationType(
          filterValue as DelegationListingDelegationTypeFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: DelegationListingFilterType
  ): ListingFilterDefinitionOption<DelegationListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<DelegationListingFilter>[] => {
      return Object.values(DelegationListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<DelegationListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default delegationListingFilterHelper;
