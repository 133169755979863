import { FC, ReactElement, useMemo } from "react";
import DriverDetailsPlanByTaxiComponent from "./by-taxi/driver-details-active-route-plans-by-taxi.component";
import DriverDetailsPlanByRailyComponent from "./by-raily/driver-details-active-route-plans-by-raily.component";
import driverDetailsPlanEntryRoutePlansUserPermissionsHelper from "./common/user-permissions/driver-details-plan-entry-route-plans-user-permission.helper";
import { useAppContext } from "../../../../../context/app.context";

enum Option {
  RAILY = "RAILY",
  TAXI = "TAXI",
}

type DriverDetailsActiveRoutePlansProps = {
  driverUuid: string;
  isVisible: boolean;
  asCardComponent?: boolean;
};

const DriverDetailsActiveRoutePlansComponent: FC<
  DriverDetailsActiveRoutePlansProps
> = (props) => {
  const { user } = useAppContext();

  const userPermissions = useMemo(() => {
    return driverDetailsPlanEntryRoutePlansUserPermissionsHelper.getPermissions(
      user?.roles!
    );
  }, [user]);

  const selectedView: Option | null = userPermissions.hasAccessToRailyView
    ? Option.RAILY
    : userPermissions.hasAccessToTaxiView
    ? Option.TAXI
    : null;

  const Component: Record<Option, () => ReactElement | null> = {
    [Option.RAILY]: () => DriverDetailsPlanByRailyComponent(props),
    [Option.TAXI]: () => DriverDetailsPlanByTaxiComponent(props),
  };

  return selectedView ? Component[selectedView]() : null;
};

export default DriverDetailsActiveRoutePlansComponent;
