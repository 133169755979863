import Joi from "joi";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import MileageUpdateAddress from "../types/mileage-update-address";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import MileageUpdateVehicleType from "../types/mileage-update-vehicle-type";

const validateMileageDate = (value: Date | null) => {
  const validationSchema = Joi.date().required();

  return formValidationService.validate(value, validationSchema);
};

const validateVehicleType = (value: MileageUpdateVehicleType) => {
  const validationSchema = Joi.string()
    .valid(...Object.values(MileageUpdateVehicleType))
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistance = (value: number | null) => {
  const validationSchema = Joi.number()
    .integer()
    .min(0)
    .max(9999999999)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateOSRMDistance = (value: number | null) => {
  const validationSchema = Joi.number()
    .integer()
    .min(0)
    .max(9999999999)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateAddressSequence = (
  value: MileageUpdateAddress[]
): FormValidationResult => {
  const validationSchema = Joi.array().min(2).required();

  return formValidationService.validate(value, validationSchema);
};

const mileageUpdateFormValidationService = {
  validateAddressSequence,
  validateDistance,
  validateMileageDate,
  validateVehicleType,
  validateOSRMDistance,
};

export default mileageUpdateFormValidationService;
