import useAsyncData from "../../../hooks/use-async-data";
import routeService from "../route.service";
import RouteCompletedRoute from "./route-completed-route";
import RouteCompletedRouteLoadParams from "./route-completed-route-load-params";

const useRouteCompletedRoute = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<RouteCompletedRoute>(
      {
        data: [],
      },
      false
    );

  const load = async (
    params: RouteCompletedRouteLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const routeCompletedRoute = await routeService.getCompletedRoute(
        params,
        signal
      );

      setData(routeCompletedRoute);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useRouteCompletedRoute;
