import UserRole from "../../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../../common/utils/user/permissions/user-permissions.service";
import driverContractListingUserPermissionDefinition, {
  DriverContractListingUserPermissions,
} from "./driver-contract-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): DriverContractListingUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    driverContractListingUserPermissionDefinition
  );

  return permissions;
};

const driverContractListingUserPermissionsHelper = {
  getPermissions,
};

export default driverContractListingUserPermissionsHelper;
