export type MileageAddParamsNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
  distance?: number;
};

export enum MileageAddParamsVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

type MileageAddParams = {
  workerUuid: string;
  workerCompanyUuid: string;
  mileageDate: Date;
  nodes: MileageAddParamsNode[];
  cardNumber?: string | null;
  commissionNumber?: string | null;
  mileageNumber?: string | null;
  notes?: string | null;
  vehicleType?: MileageAddParamsVehicleType;
  distance?: number;
  osrmDistance?: number;
};

export default MileageAddParams;
