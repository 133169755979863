import useAsyncData from "../../../hooks/use-async-data";
import routeService from "../route.service";
import RouteDetails from "./route-details";
import RouteDetailsLoadParams from "./route-details-load-params";

const useRouteDetails = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<RouteDetails | null>(null);

  const load = async (params: RouteDetailsLoadParams, signal: AbortSignal) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const routeDetails = await routeService.getDetails(params, signal);

      setData(routeDetails);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useRouteDetails;
