import railyApiService from "../../raily-api.service";
import cargoCompanyPassengerListRequestFactory from "./list/cargo-company-passenger-list-request.factory";
import CargoCompanyPassengerListRequest from "./list/cargo-company-passenger-list.request";
import CargoCompanyPassengerListResponse from "./list/cargo-company-passenger-list.response";

const cargoCompanyPassengerApiService = () => {
  const getPassengerList = async (
    request: CargoCompanyPassengerListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyPassengerListResponse> => {
    const url = `/cargo-companies/${request.companyUuid}/passengers`;

    const queryParams =
      cargoCompanyPassengerListRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    getPassengerList,
  };
};

export default cargoCompanyPassengerApiService;
