import CargoCompanyDispatchSelectOption from "./cargo-company-dispatch-select-option";
import CargoCompanyDispatchSelectValue from "./cargo-company-dispatch-select-value";
const createOptionSubText = (
  selectValue: CargoCompanyDispatchSelectValue
): string => {
  const subTexts: string[] = [];

  if (selectValue.name) {
    subTexts.push(selectValue.name);
  }

  return subTexts.join(" | ");
};
const createOption = (
  selectValue: CargoCompanyDispatchSelectValue
): CargoCompanyDispatchSelectOption => {
  return {
    label: selectValue.displayName,
    subText: createOptionSubText(selectValue),
    value: selectValue,
  };
};

const createOptions = (
  selectValues: CargoCompanyDispatchSelectValue[]
): CargoCompanyDispatchSelectOption[] => {
  return selectValues.map(createOption);
};

const cargoCompanyDispatchSelectOptionFactory = { createOptions };

export default cargoCompanyDispatchSelectOptionFactory;
