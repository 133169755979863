export enum DelegationDownloadRequestFileFormat {
  PDF = "PDF",
  CSV = "CSV",
}

export enum DelegationDownloadRequestTimezone {
  EUROPE_WARSAW = "Europe/Warsaw",
  UTC = "UTC",
}

export type DelegationDownloadRequestSeparatorTypes = ";" | ",";

export type DelegationDownloadRequestQueryParams = {
  separator?: DelegationDownloadRequestSeparatorTypes;
  timezone?: DelegationDownloadRequestTimezone;
  delegations: string;
};

type DelegationDownloadRequest = {
  delegationUuids: string[];
  fileFormat: DelegationDownloadRequestFileFormat;
  separator?: DelegationDownloadRequestSeparatorTypes;
  timezone?: DelegationDownloadRequestTimezone;
};

export default DelegationDownloadRequest;
