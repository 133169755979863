import useAsyncData from "../../../hooks/use-async-data";
import taxiCorporationService from "../taxi-corporation.service";
import TaxiCorporationDetails from "./taxi-corporation-details";
import TaxiCorporationDetailsLoadParams from "./taxi-corporation-details-load-params";

const useTaxiCorporationDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<TaxiCorporationDetails | null>(null);

  const load = async (
    params: TaxiCorporationDetailsLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const taxiFleetPartnerList = await taxiCorporationService.getDetails(
        params,
        signal
      );

      setData(taxiFleetPartnerList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useTaxiCorporationDetails;
