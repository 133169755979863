import TaxiTaxiContractAddRequest, {
  TaxiTaxiContractAddRequestBillingModel,
} from "../../../api/raily/taxi-taxi-contract/add/taxi-taxi-contract-add.request";
import BillingModel from "../../../types/billing-model";
import TaxiTaxiContractAddParams from "./taxi-taxi-contract-add-params";

const createBillingModel = (
  requestBillingModel: BillingModel
): TaxiTaxiContractAddRequestBillingModel => {
  switch (requestBillingModel) {
    case BillingModel.AB:
      return TaxiTaxiContractAddRequestBillingModel.AB;
    case BillingModel.ABA:
      return TaxiTaxiContractAddRequestBillingModel.ABA;
    case BillingModel.OTHER:
      return TaxiTaxiContractAddRequestBillingModel.OTHER;
    case BillingModel.SABS:
      return TaxiTaxiContractAddRequestBillingModel.SABS;
  }
};

const create = (
  params: TaxiTaxiContractAddParams
): TaxiTaxiContractAddRequest => {
  return {
    consumerTaxiId: params.consumerTaxiUuid,
    disabled: !params.isActive,
    distanceRate: params.distanceRate,
    haltingTimeAfterMinutes: params.freeStoppingPeriodMinutes,
    haltingTimeRate: params.stoppingRate,
    model: createBillingModel(params.billingModel),
    producerTaxiId: params.producerTaxiUuid,
    validSince: params.periodOfValidation.from,
    validTo: params.periodOfValidation.to,
    displayName: params.displayName,
    isPartner: params.isPartner,
    priority: params.priority,
  };
};

const taxiTaxiContractAddRequestFactory = {
  create,
};

export default taxiTaxiContractAddRequestFactory;
