enum DispatcherListingFilterType {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  USERNAME = "USERNAME",
  EMAIL = "EMAIL",
  ACTIVITY_STATUS = "ACTIVITY_STATUS",
  CARGO_COMPANY = "CARGO_COMPANY",
  DISPATCH = "DISPATCH",
}

export default DispatcherListingFilterType;
