import RouteDetailsRequest from "../../../api/raily/route/details/route-details.request";
import RouteDetailsLoadParams from "./route-details-load-params";

const create = (params: RouteDetailsLoadParams): RouteDetailsRequest => {
  return {
    planEntryId: params.planEntryUuid,
  };
};

const routeDetailsRequestFactory = {
  create,
};

export default routeDetailsRequestFactory;
