import BillingsOrderAddressesResponse, {
  BillingsOrderAddressesResponseItem,
} from "../../../../api/raily/billings/order/addresses/billings-order-addresses.response";
import BillingsOrderAddresses, {
  BillingsOrderAddress,
} from "./billings-order-addresses";

const createAddress = (
  responseItem: BillingsOrderAddressesResponseItem
): BillingsOrderAddress => {
  return {
    lat: responseItem.lat,
    lon: responseItem.lon,
    displayName: responseItem.displayName,
    apartment: responseItem.apartment,
    country: responseItem.country,
    description: responseItem.description,
    houseNo: responseItem.houseNo,
    street: responseItem.street,
    town: responseItem.town,
    zipCode: responseItem.zipCode,
  };
};

const create = (
  response: BillingsOrderAddressesResponse
): BillingsOrderAddresses => {
  return response.data.map(createAddress);
};

const billingsOrderAddressesFactory = {
  create,
};

export default billingsOrderAddressesFactory;
