import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import orderRouteEditRoutesHelper from "../components/routes/order-route-edit-routes.helper";
import orderRouteEditHelper from "../helper/order-route-edit.helper";
import OrderRouteEditPassengerListItem from "../types/order-route-edit-passenger-list-item";
import OrderRouteEditRouteItem, {
  OrderRouteEditRouteWaypoint,
  OrderRouteEditRouteWaypointGroup,
  OrderRouteEditRouteWaypointGroupWaypoint,
} from "../types/order-route-edit-route-waypoint";
import OrderRouteEditValidationError from "./order-route-edit-validation-error";

const validationRules = {
  maxPassengersInCarPerWaypoint: 4,
};

const validateNumberOfWaypoints = (
  waypoints: OrderRouteEditRouteItem[]
): boolean => {
  return waypoints.length > 1;
};

const validateEachWaypointGroupHasFilledAddresses = (
  waypointsGroup: OrderRouteEditRouteWaypointGroup
): boolean => {
  return waypointsGroup.waypoints.every((waypoint) => waypoint.address);
};

const validateEachWaypointHasFilledAddress = (
  waypoint: OrderRouteEditRouteWaypoint
): boolean => {
  return !!waypoint.address;
};

const validateEachRouteItemHasFilledAddress = (
  routeItems: OrderRouteEditRouteItem[]
): boolean => {
  return !!routeItems.every((routeItem) => {
    const isWaypoint =
      orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      return validateEachWaypointHasFilledAddress(routeItem);
    }

    return validateEachWaypointGroupHasFilledAddresses(routeItem);
  });
};

const validateEachWaypointHasRightAmountOfPassengersInCar = (
  passengerList: OrderRouteEditPassengerListItem[],
  routeItems: OrderRouteEditRouteItem[]
): boolean => {
  return !!routeItems.every((waypoint) => {
    const amountOfPassengersInCar =
      orderRouteEditRoutesHelper.getAmountOfPassengersInCar(
        passengerList,
        waypoint,
        routeItems
      );

    return (
      amountOfPassengersInCar <= validationRules.maxPassengersInCarPerWaypoint
    );
  });
};

const validateAllPassengersAreOnboarded = (
  passengerList: OrderRouteEditPassengerListItem[],
  routeItems: OrderRouteEditRouteItem[]
): boolean => {
  return passengerList.every((passengerListItem) => {
    const passengerOnboardingWaypoint = routeItems.find((routeItem) => {
      const isWaypoint =
        orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

      if (isWaypoint) {
        return !!routeItem.onboardingPassengerListItems.find(
          (onboardingPassengerListItem) =>
            onboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      }

      return routeItem.waypoints.some(
        (waypoint) =>
          !!waypoint.onboardingPassengerListItems.find(
            (onboardingPassengerListItem) =>
              onboardingPassengerListItem.uuid === passengerListItem.uuid
          )
      );
    });
    const passengerOutboardingWaypoint = routeItems.find((routeItem) => {
      const isWaypoint =
        orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

      if (isWaypoint) {
        return !!routeItem.outboardingPassengerListItems.find(
          (outboardingPassengerListItem) =>
            outboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      }

      return routeItem.waypoints.some(
        (waypoint) =>
          !!waypoint.outboardingPassengerListItems.find(
            (outboardingPassengerListItem) =>
              outboardingPassengerListItem.uuid === passengerListItem.uuid
          )
      );
    });

    return passengerOnboardingWaypoint || passengerOutboardingWaypoint;
  });
};

const validateAllPassengersAreOnboardedRight = (
  passengerList: OrderRouteEditPassengerListItem[],
  routeItems: OrderRouteEditRouteItem[]
): boolean => {
  return passengerList.every((passengerListItem) => {
    const passengerOnboardingRouteItems = routeItems.filter((routeItem) => {
      const isWaypoint =
        orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

      if (isWaypoint) {
        return !!routeItem.onboardingPassengerListItems.find(
          (onboardingPassengerListItem) =>
            onboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      }

      return !!routeItem.waypoints.some((waypoint) =>
        waypoint.onboardingPassengerListItems.find(
          (onboardingPassengerListItem) =>
            onboardingPassengerListItem.uuid === passengerListItem.uuid
        )
      );
    });

    const passengerOutboardingRouteItems = routeItems.filter((routeItem) => {
      const isWaypoint =
        orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

      if (isWaypoint) {
        return !!routeItem.outboardingPassengerListItems.find(
          (outboardingPassengerListItem) =>
            outboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      }

      return routeItem.waypoints.some(
        (waypoint) =>
          !!waypoint.outboardingPassengerListItems.find(
            (outboardingPassengerListItem) =>
              outboardingPassengerListItem.uuid === passengerListItem.uuid
          )
      );
    });

    const areAllPassengersOnboardedRight = passengerOnboardingRouteItems.length
      ? passengerOnboardingRouteItems.every((onboardingWaypoint) => {
          const passengersInCar =
            orderRouteEditRoutesHelper.getListOfPassengersInCar(
              passengerList,
              onboardingWaypoint,
              routeItems
            );

          const isPassengerInCar = passengersInCar.find(
            (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
          );

          return !isPassengerInCar;
        })
      : false;

    const areAllPassengersOutboardedRight =
      passengerOutboardingRouteItems.length
        ? passengerOutboardingRouteItems.every((outboardingWaypoint) => {
            const passengersInCar =
              orderRouteEditRoutesHelper.getListOfPassengersInCar(
                passengerList,
                outboardingWaypoint,
                routeItems
              );

            const isPassengerInCar = passengersInCar.find(
              (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
            );

            return isPassengerInCar;
          })
        : false;

    return areAllPassengersOnboardedRight && areAllPassengersOutboardedRight;
  });
};

const validateIsDateAtLeastInOneWaypoint = (
  routeItems: OrderRouteEditRouteItem[]
): boolean => {
  return routeItems.some((routeItem) => {
    const isWaypoint =
      orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      return !!routeItem.date;
    }

    return false;
  });
};

const validateDatesAreValid = (
  routeItems: OrderRouteEditRouteItem[]
): boolean => {
  let lastDate: Date | null = null;
  let isOk = true;

  for (const routeItem of routeItems) {
    const isWaypoint =
      orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

    if (!isWaypoint) {
      continue;
    }

    if (!routeItem.date) {
      continue;
    }

    if (!lastDate) {
      lastDate = routeItem.date;
      continue;
    }

    if (lastDate.getTime() > routeItem.date.getTime()) {
      isOk = false;
      break;
    }

    lastDate = routeItem.date;
  }

  return isOk;
};

const validateWaypointHasPassengerAction = (
  waypoint: OrderRouteEditRouteWaypoint
): boolean => {
  return (
    !!waypoint.onboardingPassengerListItems.length ||
    !!waypoint.outboardingPassengerListItems.length
  );
};

const validateWaypointGroupWaypointHasPassengerAction = (
  waypoint: OrderRouteEditRouteWaypointGroupWaypoint
): boolean => {
  return (
    !!waypoint.onboardingPassengerListItems.length ||
    !!waypoint.outboardingPassengerListItems.length
  );
};

const validateWaypointGroupHasPassengerAction = (
  waypointGroup: OrderRouteEditRouteWaypointGroup
): boolean => {
  return waypointGroup.waypoints.every(
    validateWaypointGroupWaypointHasPassengerAction
  );
};

const validateRouteItemHasPassengerAction = (
  routeItem: OrderRouteEditRouteItem
) => {
  const isWaypoint = orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

  if (isWaypoint) {
    return validateWaypointHasPassengerAction(routeItem);
  }

  return validateWaypointGroupHasPassengerAction(routeItem);
};

const validateEachRouteItemHasPassengerAction = (
  routeItems: OrderRouteEditRouteItem[]
): boolean => {
  return routeItems.every(validateRouteItemHasPassengerAction);
};

const validateRoutesForSolvedOrderFetch = (
  passengerList: OrderRouteEditPassengerListItem[],
  routeItems: OrderRouteEditRouteItem[]
) => {
  const errors: OrderRouteEditValidationError[] = [];

  const isValidNumberOfWaypoints = validateNumberOfWaypoints(routeItems);

  if (!isValidNumberOfWaypoints) {
    errors.push(OrderRouteEditValidationError.TOO_LESS_WAYPOINTS);
  }

  const isValidPlaceIntoEachWaypoint =
    validateEachRouteItemHasFilledAddress(routeItems);

  if (!isValidPlaceIntoEachWaypoint) {
    errors.push(OrderRouteEditValidationError.NO_PLACE_IN_ONE_OF_WAYPOINTS);
  }

  const isValidAmountOfPassengersInCarIntoEachWaypoint =
    validateEachWaypointHasRightAmountOfPassengersInCar(
      passengerList,
      routeItems
    );

  if (!isValidAmountOfPassengersInCarIntoEachWaypoint) {
    errors.push(
      OrderRouteEditValidationError.TOO_MANY_PASSENGERS_IN_CAR_IN_ONE_OF_WAYPOINTS
    );
  }

  const isDateGivenAtLeastInOneWaypoint =
    validateIsDateAtLeastInOneWaypoint(routeItems);

  if (!isDateGivenAtLeastInOneWaypoint) {
    errors.push(OrderRouteEditValidationError.NO_ONE_WAYPOINT_HAS_DATE);
  }

  const areDatesValid = validateDatesAreValid(routeItems);

  if (!areDatesValid) {
    errors.push(OrderRouteEditValidationError.NOT_VALID_DATES_IN_WAYPOINTS);
  }

  return errors;
};

const validateRoutes = (
  passengerList: OrderRouteEditPassengerListItem[],
  routeItems: OrderRouteEditRouteItem[]
): OrderRouteEditValidationError[] => {
  const errors: OrderRouteEditValidationError[] = [];

  const isValidNumberOfWaypoints = validateNumberOfWaypoints(routeItems);

  if (!isValidNumberOfWaypoints) {
    errors.push(OrderRouteEditValidationError.TOO_LESS_WAYPOINTS);
  }

  const isAnyDateFromRouteItemsPastDate = routeItems
    .filter((x) => (x as OrderRouteEditRouteWaypoint).date != null)
    .some((x) => {
      const date = new Date((x as OrderRouteEditRouteWaypoint).date!);
      const year = date.getFullYear();
      return year < 1970;
    });

  if (isAnyDateFromRouteItemsPastDate) {
    errors.push(OrderRouteEditValidationError.PAST_DATE_IN_WAYPOINTS);
  }

  const isValidPlaceIntoEachWaypoint =
    validateEachRouteItemHasFilledAddress(routeItems);

  if (!isValidPlaceIntoEachWaypoint) {
    errors.push(OrderRouteEditValidationError.NO_PLACE_IN_ONE_OF_WAYPOINTS);
  }

  const isValidAmountOfPassengersInCarIntoEachWaypoint =
    validateEachWaypointHasRightAmountOfPassengersInCar(
      passengerList,
      routeItems
    );

  if (!isValidAmountOfPassengersInCarIntoEachWaypoint) {
    errors.push(
      OrderRouteEditValidationError.TOO_MANY_PASSENGERS_IN_CAR_IN_ONE_OF_WAYPOINTS
    );
  }

  const areAllPassengersAreUsed = validateAllPassengersAreOnboarded(
    passengerList,
    routeItems
  );

  if (!areAllPassengersAreUsed) {
    errors.push(OrderRouteEditValidationError.NOT_EVERY_PASSENGER_IS_ONBOARDED);
  }

  const arePassengersOnboardedRight = validateAllPassengersAreOnboardedRight(
    passengerList,
    routeItems
  );

  if (!arePassengersOnboardedRight) {
    errors.push(
      OrderRouteEditValidationError.NOT_EVERY_PASSENGER_IS_ONBOARDED_RIGHT
    );
  }

  const isDateGivenAtLeastInOneWaypoint =
    validateIsDateAtLeastInOneWaypoint(routeItems);

  if (!isDateGivenAtLeastInOneWaypoint) {
    errors.push(OrderRouteEditValidationError.NO_ONE_WAYPOINT_HAS_DATE);
  }

  const areDatesValid = validateDatesAreValid(routeItems);

  if (!areDatesValid) {
    errors.push(OrderRouteEditValidationError.NOT_VALID_DATES_IN_WAYPOINTS);
  }

  const arePassengersActionsValid =
    validateEachRouteItemHasPassengerAction(routeItems);

  if (!arePassengersActionsValid) {
    errors.push(
      OrderRouteEditValidationError.NOT_EVERY_WAYPOINT_HAS_PASSENGER_ACTION
    );
  }

  return errors;
};

const resolveValidationErrorLabels = (
  errors: OrderRouteEditValidationError[]
): string[] => {
  const translations =
    orderTranslationsHelper.getEditTranslations().validation.errors;

  const errorOptions: {
    error: OrderRouteEditValidationError;
    label: string;
  }[] = [
    {
      error: OrderRouteEditValidationError.TOO_LESS_WAYPOINTS,
      label: translations.toLessWaypoints,
    },
    {
      error: OrderRouteEditValidationError.NO_PLACE_IN_ONE_OF_WAYPOINTS,
      label: translations.noPlaceInOneOfWaypoints,
    },
    {
      error:
        OrderRouteEditValidationError.TOO_MANY_PASSENGERS_IN_CAR_IN_ONE_OF_WAYPOINTS,
      label: translations.tooManyPassengersInCarInOneOfWaypoints,
    },
    {
      error: OrderRouteEditValidationError.NOT_EVERY_PASSENGER_IS_ONBOARDED,
      label: translations.notEveryPassengerIsOnboarded,
    },
    {
      error:
        OrderRouteEditValidationError.NOT_EVERY_PASSENGER_IS_ONBOARDED_RIGHT,
      label: translations.notEveryPassengerIsOnboardedRight,
    },
    {
      error: OrderRouteEditValidationError.NO_ONE_WAYPOINT_HAS_DATE,
      label: translations.noOneWaypointHasDate,
    },
    {
      error: OrderRouteEditValidationError.NOT_VALID_DATES_IN_WAYPOINTS,
      label: translations.notValidDatesInWaypoints,
    },
    {
      error:
        OrderRouteEditValidationError.NOT_EVERY_WAYPOINT_HAS_PASSENGER_ACTION,
      label: translations.notEveryWaypointHasPassengerAction,
    },
    {
      error: OrderRouteEditValidationError.PAST_DATE_IN_WAYPOINTS,
      label: translations.pastDateInWaypoints,
    },
  ];

  const resolvedErrors: string[] = [];

  errors.forEach((error) => {
    const errorLabel = errorOptions.find(
      (errorOption) => errorOption.error === error
    )?.label;

    if (!errorLabel) {
      return;
    }

    resolvedErrors.push(errorLabel);
  });

  return resolvedErrors;
};

const orderRouteEditValidationService = {
  validateRoutes,
  validateEachWaypointHasRightAmountOfPassengersInCar,
  resolveValidationErrorLabels,
  validateRoutesForSolvedOrderFetch,
};

export default orderRouteEditValidationService;
