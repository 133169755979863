import OperatorListRequest, {
  OperatorListRequestStatusCriteria,
  OperatorListRequestOrder,
} from "../../../api/raily/operator/list/operator-list.request";
import UserStatus from "../../../types/user-status";
import paginationService from "../../../utils/pagination/pagination.service";
import OperatorListLoadParams, {
  OperatorListLoadParamsOrder,
} from "./operator-list-load-params";

const createStatusCriteria = (
  status: UserStatus | undefined
): OperatorListRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return OperatorListRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return OperatorListRequestStatusCriteria.DELETED;
    default:
      return OperatorListRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: OperatorListLoadParamsOrder | undefined
): OperatorListRequestOrder | undefined => {
  switch (status) {
    case OperatorListLoadParamsOrder.FIRST_NAME_ASC:
      return OperatorListRequestOrder.FIRST_NAME_ASC;
    case OperatorListLoadParamsOrder.FIRST_NAME_DESC:
      return OperatorListRequestOrder.FIRST_NAME_DESC;
    case OperatorListLoadParamsOrder.LAST_NAME_ASC:
      return OperatorListRequestOrder.LAST_NAME_ASC;
    case OperatorListLoadParamsOrder.LAST_NAME_DESC:
      return OperatorListRequestOrder.LAST_NAME_DESC;
    case OperatorListLoadParamsOrder.USERNAME_ASC:
      return OperatorListRequestOrder.USERNAME_ASC;
    case OperatorListLoadParamsOrder.USERNAME_DESC:
      return OperatorListRequestOrder.USERNAME_DESC;
    default:
      return undefined;
  }
};

const create = (params: OperatorListLoadParams): OperatorListRequest => {
  return {
    creationStart: params.creationDateFrom,
    creationEnd: params.creationDateTo,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    limit: params.pageSize,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    statusCriteria: createStatusCriteria(params.status),
    username: params.username,
  };
};

const operatorListRequestFactory = { create };

export default operatorListRequestFactory;
