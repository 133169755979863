import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import signUpPassengerAccountDataFormValidationService from "../../account/sign-up-passenger-account-data-form-validation.service";
import signUpPassengerUserDataFormValidationService from "../../user/sign-up-passenger-user-data-form-validation.service";
import signUpPassengerFormHelper from "../sign-up-passenger-form.helper";
import SignUpPassengerFormAccountData from "../types/sign-up-passenger-form-account-data";
import SignUpPassengerFormAccountDataValidationResults from "../types/sign-up-passenger-form-account-data-validation-results";
import SignUpPassengerFormStep from "../types/sign-up-passenger-form-step";
import SignUpPassengerAddress from "../types/sign-up-passenger-address";
import SignUpPassengerFormUserData from "../types/sign-up-passenger-form-user-data";
import SignUpPassengerFormUserDataValidationResults from "../types/sign-up-passenger-form-user-data-validation-results";
import SignUpPassengerContextProps from "./sign-up-passenger-context.props";
import SignUpPassengerAccountDataDisabledFormFields from "../types/sign-up-passenger-account-data-disabled-form-fields";
import SignUpPassengerUserDataDisabledFormFields from "../types/sign-up-passenger-user-data-disabled-form-fields";

const SignUpPassengerContext =
  createContext<SignUpPassengerContextProps | null>(null);

export const useSignUpPassengerContext = () => {
  const ctx = useContext(SignUpPassengerContext);

  if (ctx === undefined || ctx === null) {
    throw new Error(
      "useSignUpPassengerContext must be used within a SignUpPassengerContextProvider"
    );
  }
  return ctx;
};

const SignUpPassengerContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const formSteps = useMemo<SignUpPassengerFormStep[]>(
    () => signUpPassengerFormHelper.getFormSteps(),
    []
  );
  const [currentFormStep, setCurrentFormStep] =
    useState<SignUpPassengerFormStep>(SignUpPassengerFormStep.accountData);
  const [passedFormSteps, setPassedFormSteps] = useState<
    SignUpPassengerFormStep[]
  >([]);

  const [dispatchId, setDispatchId] = useState("");
  const [accountData, setAccountData] =
    useState<SignUpPassengerFormAccountData>({
      email: "",
      username: "",
      password: "",
      repeatPassword: "",
    });
  const [accountDataValidationResults, setAccountDataValidationResults] =
    useState<SignUpPassengerFormAccountDataValidationResults>({
      email: formValidationService.defaultValidationResult,
      username: formValidationService.defaultValidationResult,
      password: formValidationService.defaultValidationResult,
      repeatPassword: formValidationService.defaultValidationResult,
    });
  const [accountDataDisabledFormFields, setAccountDataDisabledFormFields] =
    useState<SignUpPassengerAccountDataDisabledFormFields>({
      email: false,
      username: false,
    });

  const [userData, setUserData] = useState<SignUpPassengerFormUserData>({
    firstName: "",
    lastName: "",
    mobile: {
      dialingCode: "",
      number: "",
    },
    alternativeMobile: {
      dialingCode: "",
      number: "",
    },
    addresses: [],
  });

  const [userDataDisabledFormFields, setUserDataDisabledFormFields] =
    useState<SignUpPassengerUserDataDisabledFormFields>({
      firstName: false,
      lastName: false,
      mobile: false,
      alternativeMobile: false,
    });

  const [userDataValidationResults, setUserDataValidationResults] =
    useState<SignUpPassengerFormUserDataValidationResults>({
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
      alternativeMobile: formValidationService.defaultValidationResult,
      languages: formValidationService.defaultValidationResult,
      addresses: formValidationService.defaultValidationResult,
    });

  const checkIsCurrentStepFormValid = async (): Promise<boolean> => {
    switch (currentFormStep) {
      case SignUpPassengerFormStep.accountData:
        return await checkIsAccountDataFormValid();
      case SignUpPassengerFormStep.userData:
        return checkIsUserDataFormValid();
      default:
        return false;
    }
  };

  const goToStep = (step: SignUpPassengerFormStep) => {
    if (currentFormStep === step) {
      return;
    }

    if (!passedFormSteps.includes(currentFormStep)) {
      setCurrentFormStep(step);
      return;
    }

    const isCurrentFormValid = checkIsCurrentStepFormValid();

    if (!isCurrentFormValid) {
      return;
    }

    setCurrentFormStep(step);
  };

  const setAccountDataEmail = (
    value: SignUpPassengerFormAccountData["email"]
  ) => {
    setAccountData((curr) => ({
      ...curr,
      email: value,
    }));
  };

  const setAccountDataUsername = (
    value: SignUpPassengerFormAccountData["username"]
  ) => {
    setAccountData((curr) => ({
      ...curr,
      username: value,
    }));
  };

  const setAccountDataPassword = (
    value: SignUpPassengerFormAccountData["password"]
  ) => {
    setAccountData((curr) => ({
      ...curr,
      password: value,
    }));
  };

  const setAccountDataRepeatPassword = (
    value: SignUpPassengerFormAccountData["repeatPassword"]
  ) => {
    setAccountData((curr) => ({
      ...curr,
      repeatPassword: value,
    }));
  };

  const validateAccountDataEmail = () => {
    const validationResult =
      signUpPassengerAccountDataFormValidationService.validateEmail(
        accountData.email
      );

    setAccountDataValidationResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAccountDataUsername = async () => {
    const validationResult =
      await signUpPassengerAccountDataFormValidationService.validateUsername(
        accountData.username
      );

    setAccountDataValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAccountDataPassword = () => {
    const validationResult =
      signUpPassengerAccountDataFormValidationService.validatePassword(
        accountData.password
      );

    setAccountDataValidationResults((curr) => ({
      ...curr,
      password: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAccountDataRepeatPassword = () => {
    const validationResult =
      signUpPassengerAccountDataFormValidationService.validatePasswordRepeat(
        accountData.password,
        accountData.repeatPassword
      );

    setAccountDataValidationResults((curr) => ({
      ...curr,
      repeatPassword: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsAccountDataFormValid = async () => {
    const isEmailValid = validateAccountDataEmail();
    const isUsernameValid = await validateAccountDataUsername();
    const isPasswordValid = validateAccountDataPassword();
    const isPasswordRepeatValid = validateAccountDataRepeatPassword();

    const isFormValid =
      isEmailValid &&
      isUsernameValid &&
      isPasswordValid &&
      isPasswordRepeatValid;

    return isFormValid;
  };

  const submitAccountStep = () => {
    const isFormValid = checkIsAccountDataFormValid();

    if (!isFormValid) {
      return;
    }

    setPassedFormSteps((curr) => [
      ...curr,
      SignUpPassengerFormStep.accountData,
    ]);
    setCurrentFormStep(SignUpPassengerFormStep.userData);
  };

  const setUserDataFirstName = (
    value: SignUpPassengerFormUserData["firstName"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      firstName: value,
    }));
  };

  const setUserDataLastName = (
    value: SignUpPassengerFormUserData["lastName"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      lastName: value,
    }));
  };

  const setUserDataMobile = (value: SignUpPassengerFormUserData["mobile"]) => {
    setUserData((curr) => ({
      ...curr,
      mobile: value,
    }));
  };

  const setUserDataAlternativeMobile = (
    value: SignUpPassengerFormUserData["alternativeMobile"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      alternativeMobile: value,
    }));
  };

  const addUserDataAddress = (newAddress: SignUpPassengerAddress) => {
    setUserData((curr) => ({
      ...curr,
      addresses: [...curr.addresses, newAddress],
    }));
  };

  const removeUserDataAddress = (addressIndex: number) => {
    const filteredAddresses = [
      ...userData.addresses.slice(0, addressIndex),
      ...userData.addresses.slice(addressIndex + 1),
    ];
    setUserData((curr) => ({
      ...curr,
      addresses: filteredAddresses,
    }));
  };

  const validateUserDataFirstName = () => {
    const validationResult =
      signUpPassengerUserDataFormValidationService.validateFirstname(
        userData.firstName
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataLastName = () => {
    const validationResult =
      signUpPassengerUserDataFormValidationService.validateLastname(
        userData.lastName
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataMobile = () => {
    const validationResult =
      signUpPassengerUserDataFormValidationService.validateMobile(
        userData.mobile
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataAlternativeMobile = () => {
    const validationResult =
      signUpPassengerUserDataFormValidationService.validateAlternativeMobile(
        userData.alternativeMobile
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataAddresses = () => {
    const validationResult =
      signUpPassengerUserDataFormValidationService.validateAddresses(
        userData.addresses
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      addresses: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsUserDataFormValid = () => {
    const isFirstNameValid = validateUserDataFirstName();
    const isLastNameValid = validateUserDataLastName();
    const isMobileValid = validateUserDataMobile();
    const isAlternativeMobileValid = validateUserDataAlternativeMobile();

    const areAddressesValid = validateUserDataAddresses();

    const isFormValid =
      isFirstNameValid &&
      isLastNameValid &&
      isMobileValid &&
      isAlternativeMobileValid &&
      areAddressesValid;

    return isFormValid;
  };

  const submitUserStep = () => {
    const isFormValid = checkIsUserDataFormValid();

    if (!isFormValid) {
      return;
    }

    setPassedFormSteps((curr) => [...curr, SignUpPassengerFormStep.userData]);
    setCurrentFormStep(SignUpPassengerFormStep.summary);
  };

  return (
    <SignUpPassengerContext.Provider
      value={{
        dispatchId,
        setDispatchId,
        setAccountDataEmail,
        setAccountDataUsername,
        setAccountDataPassword,
        setAccountDataRepeatPassword,
        accountDataValidationResults,
        validateAccountDataEmail,
        validateAccountDataUsername,
        validateAccountDataPassword,
        validateAccountDataRepeatPassword,
        setAccountDataValidationResults,
        setAccountDataDisabledFormFields,
        accountDataDisabledFormFields,
        setAccountData,
        accountData,
        submitAccountStep,
        formSteps,
        currentFormStep,
        setCurrentFormStep,
        passedFormSteps,
        setPassedFormSteps,
        goToStep,
        setUserData,
        userData,
        setUserDataDisabledFormFields,
        userDataDisabledFormFields,
        setUserDataFirstName,
        setUserDataLastName,
        setUserDataMobile,
        setUserDataAlternativeMobile,
        addUserDataAddress,
        removeUserDataAddress,
        userDataValidationResults,
        validateUserDataFirstName,
        validateUserDataLastName,
        validateUserDataMobile,
        validateUserDataAlternativeMobile,
        validateUserDataAddresses,
        setUserDataValidationResults,
        submitUserStep,
      }}
    >
      {children}
    </SignUpPassengerContext.Provider>
  );
};

export default SignUpPassengerContextProvider;
