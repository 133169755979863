import TaxiOfficerAddRequest from "../../api/raily/taxi-officer/add/taxi-officer-add.request";
import TaxiOfficerListRequest from "../../api/raily/taxi-officer/list/taxi-officer-list.request";
import taxiOfficerApiService from "../../api/raily/taxi-officer/taxi-officer-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import TaxiOfficerAddError from "./add/taxi-officer-add-error";
import TaxiOfficerAddParams from "./add/taxi-officer-add-params";
import taxiOfficerAddRequestFactory from "./add/taxi-officer-add-request.factory";
import TaxiOfficerList from "./list/taxi-officer-list";
import TaxiOfficerListError from "./list/taxi-officer-list-error";
import TaxiOfficerListLoadParams from "./list/taxi-officer-list-load-params";
import taxiOfficerListRequestFactory from "./list/taxi-officer-list-request.factory";
import taxiOfficerListFactory from "./list/taxi-officer-list.factory";
import TaxiOfficerEditError from "./edit/taxi-officer-edit-error";
import TaxiOfficerEditParams from "./edit/taxi-officer-edit-params";
import { TaxiOfficerEditRequest } from "../../api/raily/taxi-officer/edit/taxi-officer-edit.request";
import taxiOfficerEditRequestFactory from "./edit/taxi-officer-edit-request.factory";
import TaxiOfficerDetailsLoadParams from "./details/taxi-officer-details-load-params";
import TaxiOfficerDetails from "./details/taxi-officer-details";
import TaxiOfficerDetailsRequest from "../../api/raily/taxi-officer/details/taxi-officer-details.request";
import taxiOfficerDetailsRequestFactory from "./details/taxi-officer-details-request.factory";
import taxiOfficerDetailsFactory from "./details/taxi-officer-details.factory";
import TaxiOfficerDetailsError from "./details/taxi-officer-details-error";

const handleAddError = (
  error: HttpError | TaxiOfficerAddError
): TaxiOfficerAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleEditError = (
  error: HttpError | TaxiOfficerEditError
): TaxiOfficerEditError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleTaxiOfficerListError = (
  error: HttpError | TaxiOfficerListError
): TaxiOfficerListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDetailsError = (
  error: HttpError | TaxiOfficerDetailsError
): TaxiOfficerDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: TaxiOfficerAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TaxiOfficerAddRequest =
    taxiOfficerAddRequestFactory.create(params);

  try {
    const response = await taxiOfficerApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TaxiOfficerAddError;

    throw handleAddError(error);
  }
};

const edit = async (
  taxiOfficerUuid: string,
  params: TaxiOfficerEditParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TaxiOfficerEditRequest =
    taxiOfficerEditRequestFactory.create(params);

  try {
    const response = await taxiOfficerApiService().edit(
      taxiOfficerUuid,
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TaxiOfficerEditError;

    throw handleEditError(error);
  }
};

const getList = async (
  params: TaxiOfficerListLoadParams,
  abortSignal: AbortSignal
): Promise<TaxiOfficerList> => {
  const request: TaxiOfficerListRequest =
    taxiOfficerListRequestFactory.create(params);

  try {
    const response = await taxiOfficerApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return taxiOfficerListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | TaxiOfficerListError;

    throw handleTaxiOfficerListError(error);
  }
};

const getDetails = async (
  params: TaxiOfficerDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<TaxiOfficerDetails> => {
  const request: TaxiOfficerDetailsRequest =
    taxiOfficerDetailsRequestFactory.create(params);

  try {
    const response = await taxiOfficerApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiOfficerDetails = taxiOfficerDetailsFactory.create(response.data);

    return taxiOfficerDetails;
  } catch (_error) {
    const error = _error as HttpError | TaxiOfficerDetailsError;

    throw handleDetailsError(error);
  }
};

const taxiOfficerService = {
  add,
  edit,
  getList,
  getDetails,
};

export default taxiOfficerService;
