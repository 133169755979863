import { RouteActiveListItem } from "../../../../common/services/route/active-list/route-active-list";
import RouteActiveListingSortKey from "./types/route-active-listing-sort-key";

const sortByDriverNameAsc = (
  listingItems: RouteActiveListItem[]
): RouteActiveListItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemA.driverName >= itemB.driverName ? 1 : -1
  );
};

const sortByDriverNameDesc = (
  listingItems: RouteActiveListItem[]
): RouteActiveListItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemB.driverName >= itemA.driverName ? 1 : -1
  );
};

const sortByRouteIdAsc = (
  listingItems: RouteActiveListItem[]
): RouteActiveListItem[] => {
  return listingItems.sort((itemA, itemB) => itemA.humanId - itemB.humanId);
};

const sortByRouteIdDesc = (
  listingItems: RouteActiveListItem[]
): RouteActiveListItem[] => {
  return listingItems.sort((itemA, itemB) => itemB.humanId - itemA.humanId);
};

const sortByTaxiCorporationAsc = (
  listingItems: RouteActiveListItem[]
): RouteActiveListItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemA.taxiCorporationName >= itemB.taxiCorporationName ? 1 : -1
  );
};

const sortByTaxiCorporationDesc = (
  listingItems: RouteActiveListItem[]
): RouteActiveListItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemB.driverName >= itemA.driverName ? 1 : -1
  );
};

const sortListingItems = (
  listingItems: RouteActiveListItem[],
  sortKey: RouteActiveListingSortKey | null
): RouteActiveListItem[] => {
  switch (sortKey) {
    case RouteActiveListingSortKey.DRIVER_NAME_ASC:
      return sortByDriverNameAsc(listingItems);
    case RouteActiveListingSortKey.DRIVER_NAME_DESC:
      return sortByDriverNameDesc(listingItems);
    case RouteActiveListingSortKey.HUMAN_ID_ASC:
      return sortByRouteIdAsc(listingItems);
    case RouteActiveListingSortKey.HUMAN_ID_DESC:
      return sortByRouteIdDesc(listingItems);
    case RouteActiveListingSortKey.TAXI_CORPORATION_ASC:
      return sortByTaxiCorporationAsc(listingItems);
    case RouteActiveListingSortKey.TAXI_CORPORATION_DESC:
      return sortByTaxiCorporationDesc(listingItems);
    default:
      return listingItems;
  }
};

const routeActiveListingSortingService = {
  sortListingItems,
};

export default routeActiveListingSortingService;
