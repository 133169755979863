import TaxiOfficerEditParams from "./taxi-officer-edit-params";
import { TaxiOfficerEditRequest } from "../../../api/raily/taxi-officer/edit/taxi-officer-edit.request";

const create = (params: TaxiOfficerEditParams): TaxiOfficerEditRequest => {
  return {
    taxiCorporationId: params.taxiCorporationUuid,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.phoneNumber.number,
    mobilePrimaryPrefix: params.phoneNumber.dialingCode,
    mobileSecondary: params.alternativePhoneNumber.number || null,
    mobileSecondaryPrefix: params.alternativePhoneNumber.dialingCode || null,
  };
};

const taxiOfficerEditRequestFactory = {
  create,
};

export default taxiOfficerEditRequestFactory;
