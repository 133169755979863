import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import authValidationService from "../../../common/validation/auth-validation.service";
import SignUpDriverFormUserAddress from "../common/types/sign-up-passenger-address";

const validateAddresses = (
  addresses: SignUpDriverFormUserAddress[]
): FormValidationResult => {
  const lengthValidationSchema = Joi.array().min(1).required();

  const lengthValidationResult = formValidationService.validate(
    addresses,
    lengthValidationSchema
  );

  if (!lengthValidationResult.isValid) {
    return lengthValidationResult;
  }

  return lengthValidationResult;
};

const signUpPassengerUserDataFormValidationService = {
  validateFirstname: authValidationService.validateFirstName,
  validateLastname: authValidationService.validateLastName,
  validateMobile: authValidationService.validateMobile,
  validateAlternativeMobile: authValidationService.validateAlternativeMobile,
  validateAddresses,
};

export default signUpPassengerUserDataFormValidationService;
