import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import MileageUpdateVehicleType from "../types/mileage-update-vehicle-type";
import MileageUpdateVehicleTypeSelectOption from "../types/mileage-update-vehicle-type-select-option";

const vehicleTypeLabel = (type: MileageUpdateVehicleType): string => {
  const translations =
    mileageTranslationsHelper.getMileageUpdateTranslations().vehicleType;
  switch (type) {
    case MileageUpdateVehicleType.COMPANY_OWNED:
      return translations.typeCompanyLabel;
    case MileageUpdateVehicleType.CAR_GT_900CM3:
      return translations.typeOwnGT900;
    case MileageUpdateVehicleType.CAR_LE_900CM3:
      return translations.typeOwnLE900;
    case MileageUpdateVehicleType.MOPED:
      return translations.typeMoped;
    case MileageUpdateVehicleType.MOTORCYCLE:
      return translations.typeMotorcycle;
  }
};

const createSelectOption = (
  type: MileageUpdateVehicleType
): MileageUpdateVehicleTypeSelectOption => {
  return {
    label: vehicleTypeLabel(type),
    value: type,
    idForTesting: `mileage-update-vehicle-type-${String(type)}-select-option`,
  };
};

const createSelectOptions = (): MileageUpdateVehicleTypeSelectOption[] => {
  return Object.keys(MileageUpdateVehicleType).map((value) =>
    createSelectOption(value as MileageUpdateVehicleType)
  );
};

const mileageUpdateVehicleTypeSelectOptionFactory = {
  createSelectOptions,
  createSelectOption,
};

export default mileageUpdateVehicleTypeSelectOptionFactory;
