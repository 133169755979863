import { FC, useEffect, useMemo, useState } from "react";
import useMileageDictionaryList from "../../../../../../common/services/mileage-dictionary/list/use-mileage-dictionary-list";
import useAbort from "../../../../../../common/hooks/use-abort";
import MileageDictionaryListLoadParams from "../../../../../../common/services/mileage-dictionary/list/mileage-dictionary-list-load-params";
import MileageAddDictionaryRecordSelectOption from "../../types/mileage-add-doctionary-record-select-option";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";
import mileageAddDictionaryRecordFactory from "./mileage-add-dictionary-record.factory";

type MileageAddMileageNumberDictionaryInputProps = {
  cargoCompanyUuid: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  hasError?: boolean;
  onBlur?: () => void;
  idForTesting?: string;
  isClearable?: boolean;
};

const MileageAddMileageNumberDictionaryInputComponent: FC<
  MileageAddMileageNumberDictionaryInputProps
> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().mileageNumber;

  const [searchQuery, setSearchQuery] = useState("");

  const mileageDictionaryList = useMileageDictionaryList();
  const mileageDictionaryListAbort = useAbort();

  const loadMileageDictionaryList = (cargoCompanyUuid: string) => {
    const params: MileageDictionaryListLoadParams = {
      cargoCompanyUuid: cargoCompanyUuid,
      active: true,
    };

    mileageDictionaryList.load(params, mileageDictionaryListAbort.signal);
  };

  useEffect(() => {
    if (!props.cargoCompanyUuid) {
      return;
    }

    loadMileageDictionaryList(props.cargoCompanyUuid);

    return mileageDictionaryListAbort.revoke;
  }, [props.cargoCompanyUuid]);

  const options = useMemo(() => {
    return mileageAddDictionaryRecordFactory.createSelectOptions(
      mileageDictionaryList.data
    );
  }, [mileageDictionaryList.data]);

  const filteredOptions = useMemo(() => {
    return options.filter((value) =>
      value.value.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [options, searchQuery]);

  const selectedDictionaryRecordSelectOption = useMemo(() => {
    if (!props.value) {
      return null;
    }

    return mileageAddDictionaryRecordFactory.createSelectOption(props.value);
  }, [props.value]);

  const isLoading = mileageDictionaryList.isLoading;
  const hasError = mileageDictionaryList.isError;
  const isDisabled = hasError;

  return (
    <SingleSelectComponent
      placeholder={props.placeholder}
      value={selectedDictionaryRecordSelectOption}
      options={filteredOptions}
      isSearchable
      onChange={(option: MileageAddDictionaryRecordSelectOption | null) => {
        props.onChange(option?.value ?? "");
      }}
      noOptionsMessage={() => translations.dictionarySearchNoOptionsMessage}
      hasError={hasError}
      isLoading={isLoading}
      isDisabled={isDisabled}
      inputValue={searchQuery}
      onInputChange={setSearchQuery}
      onBlur={props.onBlur}
      idForTesting={props.idForTesting}
      isClearable={props.isClearable}
    />
  );
};

export default MileageAddMileageNumberDictionaryInputComponent;
