import useAsyncData from "../../../../hooks/use-async-data";
import billingService from "../../billings.service";
import BillingsOrderTaxiDriver from "./billings-order-taxi-driver";
import BillingsOrderTaxiDriverLoadParams from "./billings-order-taxi-driver-load-params";

const useBillingsOrderTaxiDriver = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<BillingsOrderTaxiDriver>({
      data: [],
    });

  const load = async (
    params: BillingsOrderTaxiDriverLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const taxiDriver = await billingService.getOrderTaxiDriver(
        params,
        signal
      );

      setData(taxiDriver);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useBillingsOrderTaxiDriver;
