import { FC } from "react";
import orderListingTableRouteFactory from "../../../../common/table/route/order-listing-table-route.factory";

type OrderAbandonedListingTableRouteProps = {
  addresses: (string | string[])[];
};

const OrderAbandonedListingTableRouteComponent: FC<
  OrderAbandonedListingTableRouteProps
> = (props) => {
  const addressesString = orderListingTableRouteFactory.createRouteString(
    props.addresses
  );
  return (
    <div
      title={props.addresses.join("\r")}
      className="order_abandoned_listing_route"
    >
      {addressesString}
    </div>
  );
};

export default OrderAbandonedListingTableRouteComponent;
