import appConfig from "../../../../../../app.config";
import urlService from "../../../../../../common/utils/url/url.service";
import authService from "../../../../../auth/common/auth.service";
import TaxiTransferedOrderSettlementListingReportRequest from "./taxi-transfered-order-settlement-listing-report.request";
import TaxiTransferedOrderSettlementListingReportResponse from "./taxi-transfered-order-settlement-listing-report.response";
import TaxiTransferedOrderSettlementListingRequest from "./taxi-transfered-order-settlement-listing.request";
import TaxiTransferedOrderSettlementListingResponse from "./taxi-transfered-order-settlement-listing.response";

const fetchListing = async (
  request: TaxiTransferedOrderSettlementListingRequest
): Promise<TaxiTransferedOrderSettlementListingResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/order-forwarding-billings`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchReport = async (
  request: TaxiTransferedOrderSettlementListingReportRequest
): Promise<TaxiTransferedOrderSettlementListingReportResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/order-forwarding-billings/csv-dump`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "attachment",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    mode: "cors",
    keepalive: true,
  };

  const response = await fetch(url, init);

  const filename = response.headers
    ?.get("content-disposition")
    ?.split(";")
    .find((n) => n.includes("filename="))
    ?.replace("filename=", "")
    .replace(/"/g, "")
    .trim()!;

  const responseData = await response.blob();

  return {
    status: response.status,
    data: {
      data: responseData,
      filename,
    },
  };
};

const taxiTransferedOrderSettlementListingApiService = {
  fetchListing,
  fetchReport,
};

export default taxiTransferedOrderSettlementListingApiService;
