import paginationService from "../../../utils/pagination/pagination.service";
import TaxiTaxiContractListLoadParams, {
  TaxiTaxiContractListLoadParamsOrder,
} from "./taxi-taxi-contract-list-load-params";
import TaxiTaxiContractListRequest, {
  TaxiTaxiContractListRequestBillingModel,
  TaxiTaxiContractListRequestOrder,
} from "../../../api/raily/taxi-taxi-contract/list/taxi-taxi-contract-list.request";
import BillingModel from "../../../types/billing-model";

const createOrder = (
  sortKey: TaxiTaxiContractListLoadParamsOrder | undefined
): TaxiTaxiContractListRequestOrder | undefined => {
  switch (sortKey) {
    case TaxiTaxiContractListLoadParamsOrder.PRIORITY_ASC:
      return TaxiTaxiContractListRequestOrder.PRIORITY_ASC;
    case TaxiTaxiContractListLoadParamsOrder.PRIORITY_DESC:
      return TaxiTaxiContractListRequestOrder.PRIORITY_DESC;
    case TaxiTaxiContractListLoadParamsOrder.DISPLAY_NAME_ASC:
      return TaxiTaxiContractListRequestOrder.DISPLAY_NAME_ASC;
    case TaxiTaxiContractListLoadParamsOrder.DISPLAY_NAME_DESC:
      return TaxiTaxiContractListRequestOrder.DISPLAY_NAME_DESC;
    case TaxiTaxiContractListLoadParamsOrder.PRODUCER_DISPLAY_NAME_ASC:
      return TaxiTaxiContractListRequestOrder.PRODUCER_DISPLAY_NAME_ASC;
    case TaxiTaxiContractListLoadParamsOrder.PRODUCER_DISPLAY_NAME_DESC:
      return TaxiTaxiContractListRequestOrder.PRODUCER_DISPLAY_NAME_DESC;
    case TaxiTaxiContractListLoadParamsOrder.CONSUMER_DISPLAY_NAME_ASC:
      return TaxiTaxiContractListRequestOrder.CONSUMER_DISPLAY_NAME_ASC;
    case TaxiTaxiContractListLoadParamsOrder.CONSUMER_DISPLAY_NAME_DESC:
      return TaxiTaxiContractListRequestOrder.CONSUMER_DISPLAY_NAME_DESC;
    case TaxiTaxiContractListLoadParamsOrder.HALTING_TIME_RATE_ASC:
      return TaxiTaxiContractListRequestOrder.HALTING_TIME_RATE_ASC;
    case TaxiTaxiContractListLoadParamsOrder.HALTING_TIME_RATE_DESC:
      return TaxiTaxiContractListRequestOrder.HALTING_TIME_RATE_DESC;
    case TaxiTaxiContractListLoadParamsOrder.DISTANCE_RATE_ASC:
      return TaxiTaxiContractListRequestOrder.DISTANCE_RATE_ASC;
    case TaxiTaxiContractListLoadParamsOrder.DISTANCE_RATE_DESC:
      return TaxiTaxiContractListRequestOrder.DISTANCE_RATE_DESC;
    case TaxiTaxiContractListLoadParamsOrder.HALTING_TIME_AFTER_MINUTES_ASC:
      return TaxiTaxiContractListRequestOrder.HALTING_TIME_AFTER_MINUTES_ASC;
    case TaxiTaxiContractListLoadParamsOrder.HALTING_TIME_AFTER_MINUTES_DESC:
      return TaxiTaxiContractListRequestOrder.HALTING_TIME_AFTER_MINUTES_DESC;
    default:
      return undefined;
  }
};

const createModel = (
  billingModelFilterKey: BillingModel | undefined
): TaxiTaxiContractListRequestBillingModel | undefined => {
  switch (billingModelFilterKey) {
    case BillingModel.AB:
      return TaxiTaxiContractListRequestBillingModel.AB;
    case BillingModel.ABA:
      return TaxiTaxiContractListRequestBillingModel.ABA;
    case BillingModel.SABS:
      return TaxiTaxiContractListRequestBillingModel.SABS;
    case BillingModel.OTHER:
      return TaxiTaxiContractListRequestBillingModel.OTHER;
    default:
      return undefined;
  }
};

const create = (
  params: TaxiTaxiContractListLoadParams
): TaxiTaxiContractListRequest => {
  return {
    creationStart: params.creationDate?.from,
    creationEnd: params.creationDate?.to,
    limit: params.pageSize,
    offset:
      params.page &&
      params.pageSize &&
      paginationService.calculateOffset(params.page, params.pageSize),
    resultsOrdering: createOrder(params.order),
    producerDisplayName: params.producerDisplayName,
    consumerDisplayName: params.consumerDisplayName,
    producerTaxiId: params.producerTaxiUuid,
    startValidSince: params.periodOfValidation?.from,
    endValidTo: params.periodOfValidation?.to,
    startDistanceRate: params.distanceRate?.from,
    endDistanceRate: params.distanceRate?.to,
    startHaltingTimeAfterMinutes: params.freeStoppingPeriod?.from,
    endHaltingTimeAfterMinutes: params.freeStoppingPeriod?.to,
    startHaltingTimeRate: params.stopRate?.from,
    endHaltingTimeRate: params.stopRate?.to,
    isPartner: params.isPartner,
    isValid: params.isValid,
    disabled: params.isActive === undefined ? undefined : !params.isActive,
    priority: params.priority,
    model: createModel(params.billingModel),
    displayName: params.displayName,
    engagedTaxiId: params.engagedTaxiUuid,
  };
};

const taxiTaxiContractListRequestFactory = { create };

export default taxiTaxiContractListRequestFactory;
