import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import CardComponent from "../../../../common/components/card/card.component";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import DriverListingTableComponent from "./table/driver-listing-table.component";
import DriverListingFilterComponent from "./filter/driver-listing-filter.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import useDriverListingUserPermissions from "./common/user-permissions/use-driver-listing-user-permissions";
import DriverListingItem from "./common/types/driver-listing-item";
import DriverListingFilter from "./common/types/driver-listing-filter";
import DriverListingSortKey from "./common/types/driver-listing-sort-key";
import driverListingLoadParamsFactory from "./common/driver-listing-load-params.factory";
import driverListingItemFactory from "./common/driver-listing-item.factory";
import useDriverListingFiltersDao from "./filter/use-driver-listing-filters.dao";
import usePagination from "../../../../common/hooks/use-pagination";
import useAbort from "../../../../common/hooks/use-abort";
import UserDeleteUserData from "../../common/delete/common/types/user-delete-user-data";
import UserDeleteComponent from "../../common/delete/user-delete.component";
import useDriverList from "../../../../common/services/driver/list/use-driver-list";
import DriverListLoadParams from "../../../../common/services/driver/list/driver-list-load-params";
import UserRestoreComponent from "../../common/restore/user-restore.component";
import UserRestoreUserData from "../../common/restore/types/user-restore-user-data";

type DriverListingProps = {};

const DriverListingComponent: FC<DriverListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();

  const userPermissions = useDriverListingUserPermissions();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userDriverListing);

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDriverListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const driverList = useDriverList();

  const listingItemsAbort = useAbort();

  const loadListingItems = async () => {
    const params: DriverListLoadParams = driverListingLoadParamsFactory.create(
      page,
      pageSize,
      filters,
      sortKey
    );

    driverList.load(params, listingItemsAbort.signal);
  };

  const listingItems: DriverListingItem[] = useMemo(() => {
    return driverListingItemFactory.createListingItems(driverList.data.data);
  }, [driverList.data.data]);

  const filtersDao = useDriverListingFiltersDao();

  const [sortKey, setSortKey] = useState<DriverListingSortKey>(
    () => filtersDao.getSortKey() ?? DriverListingSortKey.FIRST_NAME_ASC
  );

  const [filters, setFilters] = useState<DriverListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: driverList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const translations = userTranslationsHelper.getDriverListingTranslations();

  const [selectedDriverUuids, setSelectedDriverUuids] = useState<
    DriverListingItem["uuid"][]
  >([]);
  const [userSelectedToDelete, setUserSelectedToDelete] =
    useState<UserDeleteUserData | null>(null);
  const [userSelectedToRestore, setUserSelectedToRestore] =
    useState<UserRestoreUserData | null>(null);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isRestoreUserModalOpen, setIsRestoreUserModalOpen] = useState(false);

  const openDeleteUserModal = () => {
    setIsDeleteUserModalOpen(true);
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  const closeRestoreUserModal = () => {
    setIsRestoreUserModalOpen(false);
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    setSelectedDriverUuids([]);
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onSelectUserToDelete = (userData: UserDeleteUserData) => {
    setUserSelectedToDelete(userData);
    openDeleteUserModal();
  };

  const onSelectUserToRestore = (userData: UserRestoreUserData) => {
    setUserSelectedToRestore(userData);
    setIsRestoreUserModalOpen(true);
  };

  const onDeleteUserSuccess = () => {
    closeDeleteUserModal();
    loadListingItems();
  };

  const onRestoreUserSuccess = () => {
    closeRestoreUserModal();
    loadListingItems();
  };

  const DriverAddLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={userRoutesHelper.getDriverAddRoute()}
        title={translations.header.addNewDriverLinkTitle}
        idForTesting="driver-listing-add-button"
      >
        {translations.header.addNewDriverLinkLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasVisibleAddDriverButton
        ? DriverAddLinkButton
        : undefined,
    ];
  };

  const headingActions = createHeadingActions();

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <DriverListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <DriverListingTableComponent
          listingItems={listingItems}
          isError={driverList.isError}
          isLoading={driverList.isLoading}
          selectedDriverUuids={selectedDriverUuids}
          onSelectedDriverUuidsChange={setSelectedDriverUuids}
          onSelectUserToDelete={onSelectUserToDelete}
          onSelectUserToRestore={onSelectUserToRestore}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={driverList.data.totalCount}
        />
      </CardComponent>
      <UserDeleteComponent
        isOpen={isDeleteUserModalOpen}
        onClose={closeDeleteUserModal}
        userData={userSelectedToDelete}
        onSuccess={onDeleteUserSuccess}
      />
      <UserRestoreComponent
        isOpen={isRestoreUserModalOpen}
        onClose={closeRestoreUserModal}
        userData={userSelectedToRestore}
        onSuccess={onRestoreUserSuccess}
      />
    </>
  );
};

export default DriverListingComponent;
