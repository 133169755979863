import TaxiOfficerAddRequest, {
  TaxiOfficerAddRequestBody,
} from "./taxi-officer-add.request";

const createBody = (data: TaxiOfficerAddRequest): TaxiOfficerAddRequestBody => {
  return {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    mobilePrimary: data.mobilePrimary,
    mobilePrimaryPrefix: data.mobilePrimaryPrefix,
    mobileSecondary: data.mobileSecondary,
    mobileSecondaryPrefix: data.mobileSecondaryPrefix,
    password: data.password,
    username: data.username,
    taxiOfficer: {
      taxiCorporationId: data.taxiCorporationId,
    },
  };
};

const taxiOfficerAddRequestFactory = {
  createBody,
};

export default taxiOfficerAddRequestFactory;
