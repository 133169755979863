import Joi from "joi";
import CargoTaxiContractListingRouteQueryParams from "./types/cargo-taxi-contract-listing-route-query-params";
import CargoTaxiContractListingRouteQueryFilterParams from "./types/cargo-taxi-contract-listing-route-query-filter-params";
import CargoTaxiContractListingFilter, {
  CargoTaxiContractListingBillingModelFilter,
  CargoTaxiContractListingTaxiCorporationFilter,
} from "./types/cargo-taxi-contract-listing-filter";
import CargoTaxiContractListingFilterType from "./types/cargo-taxi-contract-listing-filter-type";
import CargoTaxiContractListingBillingModel from "./types/cargo-taxi-contract-listing-billing-model";
import CargoTaxiContractListingSortKey from "./types/cargo-taxi-contract-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: CargoTaxiContractListingRouteQueryFilterParams
): CargoTaxiContractListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: CargoTaxiContractListingFilter[] = [];

  if (routeQueryFilterParams.billingModel) {
    const billingModelFilter: CargoTaxiContractListingBillingModelFilter = {
      type: CargoTaxiContractListingFilterType.BILLING_MODEL,
      value:
        routeQueryFilterParams.billingModel as CargoTaxiContractListingBillingModelFilter["value"],
    };

    filters.push(billingModelFilter);
  }

  if (routeQueryFilterParams.taxiCorporation) {
    const cargoCompanyFilter: CargoTaxiContractListingTaxiCorporationFilter = {
      type: CargoTaxiContractListingFilterType.TAXI_CORPORATION,
      value:
        routeQueryFilterParams.taxiCorporation as CargoTaxiContractListingTaxiCorporationFilter["value"],
    };

    filters.push(cargoCompanyFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: CargoTaxiContractListingRouteQueryParams
): CargoTaxiContractListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const getPage = (
  routeQueryParams: CargoTaxiContractListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryParams.page)) {
    return undefined;
  }

  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: CargoTaxiContractListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryParams.pageSize)) {
    return undefined;
  }

  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: CargoTaxiContractListingFilter[],
  sortKey: CargoTaxiContractListingSortKey | null,
  page: number,
  pageSize: number
): CargoTaxiContractListingRouteQueryParams => {
  const routeQueryParams: CargoTaxiContractListingRouteQueryParams = {
    billingModel: filters.find(
      (filter) =>
        filter.type === CargoTaxiContractListingFilterType.BILLING_MODEL
    )?.value as CargoTaxiContractListingBillingModelFilter["value"],
    taxiCorporation: filters.find(
      (filter) =>
        filter.type === CargoTaxiContractListingFilterType.TAXI_CORPORATION
    )?.value as CargoTaxiContractListingTaxiCorporationFilter["value"],
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const validateFilters = (
  routeQueryFilterParams: CargoTaxiContractListingRouteQueryFilterParams
) => {
  const filterParams: CargoTaxiContractListingRouteQueryFilterParams = {
    billingModel: routeQueryFilterParams.billingModel,
    taxiCorporation: routeQueryFilterParams.taxiCorporation,
  };

  const validationSchema =
    Joi.object<CargoTaxiContractListingRouteQueryFilterParams>({
      billingModel: Joi.valid(
        ...Object.values(CargoTaxiContractListingBillingModel)
      ),
      taxiCorporation: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: CargoTaxiContractListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(CargoTaxiContractListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: CargoTaxiContractListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: CargoTaxiContractListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const cargoTaxiContractListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default cargoTaxiContractListingRouteQueryParamsService;
