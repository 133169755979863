import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useAbort from "../../../../../common/hooks/use-abort";
import { useAppContext } from "../../../../../context/app.context";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import cargoBreadcrumbsHelper from "../../../common/breadcrumbs/cargo-breadcrumbs.helper";
import cargoCompanyListingLoadParamsFactory from "./common/cargo-company-listing-load-params.factory";
import CargoCompanyListingItem from "./common/types/cargo-company-listing-item";
import CargoCompanyListingSortKey from "./common/types/cargo-company-listing-sort-key";
import CargoCompanyListingFilter from "./common/types/cargo-company-listing-filter";
import useClientSidePagination from "../../../../../common/hooks/use-clientside-pagination";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import CargoCompanyListingFilterComponent from "./filter/cargo-company-listing-filter.component";
import CardComponent from "../../../../../common/components/card/card.component";
import CargoCompanyListingTableComponent from "./table/cargo-company-listing-table.component";
import ListingPaginationComponent from "../../../../../common/components/listing/pagination/listing-pagination.component";
import cargoCompanyListingItemFactory from "./common/cargo-company-listing-item.factory";
import useCargoCompanyListingFiltersDao from "./filter/use-cargo-company-listing-filters.dao";
import CargoCompanyListLoadParams from "../../../../../common/services/cargo-company/cargo-company/list/cargo-company-list-load-params";
import useCargoCompanyList from "../../../../../common/services/cargo-company/cargo-company/list/use-cargo-company-list";

type CargoCompanyListingProps = {};

const CargoCompanyListingComponent: FC<CargoCompanyListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();

  const listingItemsAbort = useAbort();

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.cargoCompanyListing);

  useEffect(() => {
    const breadcrumbs = cargoBreadcrumbsHelper.getCompanyListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const cargoCompanyList = useCargoCompanyList();

  const loadListingItems = async () => {
    const params: CargoCompanyListLoadParams =
      cargoCompanyListingLoadParamsFactory.create(filters, sortKey);

    cargoCompanyList.load(params, listingItemsAbort.signal);
  };

  const listingItems: CargoCompanyListingItem[] = useMemo(() => {
    return cargoCompanyListingItemFactory.createListingItems(
      cargoCompanyList.data.data
    );
  }, [cargoCompanyList.data.data]);

  const filtersDao = useCargoCompanyListingFiltersDao();

  const [sortKey, setSortKey] = useState<CargoCompanyListingSortKey>(
    () => filtersDao.getSortKey() ?? CargoCompanyListingSortKey.COMPANY_NAME_ASC
  );

  const [filters, setFilters] = useState<CargoCompanyListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: listingItems,
      totalResults: listingItems.length,
    });

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const translations = cargoTranslationsHelper.getCompanyListingTranslations();

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    setPage(1);
  }, [pageSize, filters]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <CargoCompanyListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={setFilters}
        onSortKeyChange={setSortKey}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <CargoCompanyListingTableComponent
          listingItems={pageData}
          isError={cargoCompanyList.isError}
          isLoading={cargoCompanyList.isLoading}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={totalResults}
        />
      </CardComponent>
    </>
  );
};

export default CargoCompanyListingComponent;
