import paginationService from "../../../../../common/utils/pagination/pagination.service";
import TaxiOrderSettlementListingFilterType from "./types/taxi-order-settlement-listing-filter-type";
import TaxiOrderSettlementListingSortKey from "./types/taxi-order-settlement-listing-sort-key";
import TaxiOrderSettlementListingFilter, {
  TaxiOrderSettlementListingInternalOrderIdFilter,
  TaxiOrderSettlementListingBillingStatusFilter,
  TaxiOrderSettlementListingOrderStartDateFilter,
  TaxiOrderSettlementListingBillingModelFilter,
  TaxiOrderSettlementListingBillingTypeFilter,
  TaxiOrderSettlementListingCargoCompanyFilter,
  TaxiOrderSettlementListingDestinationTaxiFilter,
  TaxiOrderSettlementListingPlanEntryHumanIdsFilter,
  TaxiOrderSettlementListingPassengerFilter,
  TaxiOrderSettlementListingExcludeCargoCompanyFilter,
} from "./types/taxi-order-settlement-listing-filter";
import TaxiOrderSettlementListingRequest, {
  TaxiOrderSettlementListingRequestOrder,
} from "./api/taxi-order-settlement-listing.request";

const createRequestOrder = (
  sortKey: TaxiOrderSettlementListingSortKey | null
): TaxiOrderSettlementListingRequest["order"] => {
  const options: {
    sortKey: TaxiOrderSettlementListingSortKey;
    requestOrder: TaxiOrderSettlementListingRequestOrder;
  }[] = [
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.DISTANCE_DESC,
      sortKey: TaxiOrderSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.DISTANCE_ASC,
      sortKey: TaxiOrderSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: TaxiOrderSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: TaxiOrderSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.HALTING_AMOUNT_ASC,
      sortKey: TaxiOrderSettlementListingSortKey.STOPOVER_COST_ASC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.HALTING_AMOUNT_DESC,
      sortKey: TaxiOrderSettlementListingSortKey.STOPOVER_COST_DESC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.HUMAN_ID_ASC,
      sortKey: TaxiOrderSettlementListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.HUMAN_ID_DESC,
      sortKey: TaxiOrderSettlementListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.BILLING_MODEL_ASC,
      sortKey: TaxiOrderSettlementListingSortKey.BILLING_MODEL_ASC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.BILLING_MODEL_DESC,
      sortKey: TaxiOrderSettlementListingSortKey.BILLING_MODEL_DESC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.DISTANCE_RATE_ASC,
      sortKey: TaxiOrderSettlementListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.DISTANCE_RATE_DESC,
      sortKey: TaxiOrderSettlementListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: TaxiOrderSettlementListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: TaxiOrderSettlementListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: TaxiOrderSettlementListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder: TaxiOrderSettlementListingRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: TaxiOrderSettlementListingSortKey.TOTAL_COST_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: TaxiOrderSettlementListingFilter[],
  sortKey: TaxiOrderSettlementListingSortKey | null
): TaxiOrderSettlementListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    limit: pageSize,
    offset,
    order: createRequestOrder(sortKey),
    human_id: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | TaxiOrderSettlementListingInternalOrderIdFilter["value"]
      | undefined,
    plan_entry_human_ids: filters.find(
      (filter) =>
        filter.type ===
        TaxiOrderSettlementListingFilterType.PLAN_ENTRY_HUMAN_IDS
    )?.value as
      | TaxiOrderSettlementListingPlanEntryHumanIdsFilter["value"]
      | undefined,
    cargo_company: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.CARGO_COMPANY
    )?.value as
      | TaxiOrderSettlementListingCargoCompanyFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) => filter.type === TaxiOrderSettlementListingFilterType.PASSENGER
    )?.value as TaxiOrderSettlementListingPassengerFilter["value"] | undefined,
    exclude_cargo_company: filters.find(
      (filter) =>
        filter.type ===
        TaxiOrderSettlementListingFilterType.EXCLUDE_CARGO_COMPANY
    )?.value as
      | TaxiOrderSettlementListingExcludeCargoCompanyFilter["value"]
      | undefined,
    status: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.BILLING_STATUS
    )?.value as
      | TaxiOrderSettlementListingBillingStatusFilter["value"]
      | undefined,
    date_since: (
      filters.find(
        (filter) =>
          filter.type === TaxiOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    date_to: (
      filters.find(
        (filter) =>
          filter.type === TaxiOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    billing_model: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.BILLING_MODEL
    )?.value as
      | TaxiOrderSettlementListingBillingModelFilter["value"]
      | undefined,
    billing_type: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.BILLING_TYPE
    )?.value as
      | TaxiOrderSettlementListingBillingTypeFilter["value"]
      | undefined,
    responsible_taxi: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.DESTINATION_TAXI
    )?.value as
      | TaxiOrderSettlementListingDestinationTaxiFilter["value"]
      | undefined,
  };
};

const taxiOrderSettlementListingRequestFactory = {
  createRequest,
};

export default taxiOrderSettlementListingRequestFactory;
