import { FC } from "react";
import TableComponent from "../../../../../../../common/components/table/table.component";
import driverDetailsCandidatureManagementRouteListingHelper from "./driver-details-candidature-management-route-listing.helper";
import DriverDetailsCandidatureManagementRouteListingTableColumn from "./common/types/driver-details-candidature-management-route-listing-table-column";
import DriverDetailsCandidatureManagementRouteListingTableRow from "./common/types/driver-details-candidature-management-route-listing-table-row";
import { DriverDetailsCandidatureManagementPlanEntryWaypoint } from "../common/types/driver-details-candidature-management-plan-entry";
import { DriverDetailsDriverPlanEntryWaypoint } from "../../../active-route-plans/common/types/driver-details-plan-entry";
import classNames from "classnames";

type DriverDetailsCandidatureManagementRouteListingProps = {
  routeWaypoints: DriverDetailsCandidatureManagementPlanEntryWaypoint[];
  currentWaypoints: DriverDetailsDriverPlanEntryWaypoint[];
};

const DriverDetailsCandidatureManagementRouteListingComponent: FC<
  DriverDetailsCandidatureManagementRouteListingProps
> = (props) => {
  const tableColumns: DriverDetailsCandidatureManagementRouteListingTableColumn[] =
    driverDetailsCandidatureManagementRouteListingHelper.getColumns();

  const routeWaypointsUuids: string[] = props.routeWaypoints.map(
    (routeWaypoint) => routeWaypoint.uuid
  );
  const currentWaypointsUuids: string[] = props.currentWaypoints.map(
    (currentWaypoint) => currentWaypoint.uuid
  );

  const newWaypointsUuids: string[] = routeWaypointsUuids.filter(
    (routeWaypoint) => !currentWaypointsUuids.includes(routeWaypoint)
  );
  const removedWaypointsUuids: string[] = currentWaypointsUuids.filter(
    (currentWaypointUuid) => !routeWaypointsUuids.includes(currentWaypointUuid)
  );

  const tableRows: DriverDetailsCandidatureManagementRouteListingTableRow[] =
    props.routeWaypoints.map((waypoint) => {
      const isNewWaypoint: boolean = !!newWaypointsUuids.find(
        (newWaypoint) => newWaypoint === waypoint.uuid
      );
      const isRemovedWaypoint: boolean = !!removedWaypointsUuids.find(
        (removedWaypoint) => removedWaypoint === waypoint.uuid
      );

      const clientLabel =
        driverDetailsCandidatureManagementRouteListingHelper.getClientLabel(
          waypoint.clientName
        );

      const clientTitle =
        driverDetailsCandidatureManagementRouteListingHelper.getClientTitle(
          waypoint.clientName
        );

      const orderIdLabel =
        driverDetailsCandidatureManagementRouteListingHelper.getOrderIdLabel(
          waypoint.orderNo
        );

      const orderIdTitle =
        driverDetailsCandidatureManagementRouteListingHelper.getOrderIdTitle(
          waypoint.orderNo
        );

      const plannedDateLabel =
        driverDetailsCandidatureManagementRouteListingHelper.getPlannedDateLabel(
          waypoint.plannedDate
        );

      const plannedDateTitle =
        driverDetailsCandidatureManagementRouteListingHelper.getPlannedDateTitle(
          waypoint.plannedDate
        );

      const estimatedDateLabel =
        driverDetailsCandidatureManagementRouteListingHelper.getEstimatedDateLabel(
          waypoint.estimatedDate
        );

      const estimatedDateTitle =
        driverDetailsCandidatureManagementRouteListingHelper.getEstimatedDateTitle(
          waypoint.estimatedDate
        );

      const plannedHaltingTimeLabel =
        driverDetailsCandidatureManagementRouteListingHelper.getPlannedHaltingTimeLabel(
          waypoint.plannedHaltingTime
        );

      const plannedHaltingTimeTitle =
        driverDetailsCandidatureManagementRouteListingHelper.getPlannedHaltingTimeTitle(
          waypoint.plannedHaltingTime
        );

      return {
        id: waypoint.uuid,
        value: {
          difference: (
            <div
              className={classNames(
                "driver_details_candidature_route_listing_difference_marker",
                isNewWaypoint && "added",
                isRemovedWaypoint && "removed"
              )}
            ></div>
          ),
          client: <div title={clientTitle}>{clientLabel}</div>,
          orderNo: <div title={orderIdTitle}>{orderIdLabel}</div>,
          address: (
            <div title={waypoint.addressDisplayName}>
              {waypoint.addressDisplayName}
            </div>
          ),
          plannedDate: <div title={plannedDateTitle}>{plannedDateLabel}</div>,
          estimatedDate: (
            <div title={estimatedDateTitle}>{estimatedDateLabel}</div>
          ),
          plannedHaltingTime: (
            <div title={plannedHaltingTimeTitle}>{plannedHaltingTimeLabel}</div>
          ),
        },
      };
    });

  return <TableComponent columns={tableColumns} rows={tableRows} />;
};

export default DriverDetailsCandidatureManagementRouteListingComponent;
