import CargoCompanyAddRequest from "../../../api/raily/cargo-company/cargo-company/add/cargo-company-add-request";
import cargoCompanyApiService from "../../../api/raily/cargo-company/cargo-company/cargo-company-api.service";
import CargoCompanyListRequest from "../../../api/raily/cargo-company/cargo-company/list/cargo-company-list.request";
import CargoCompanyDetailsRequest from "../../../api/raily/cargo-company/cargo-company/details/cargo-company-details.request";
import dataLoadErrorFactory from "../../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../../utils/http/http.error";
import httpHelper from "../../../utils/http/http.helper";
import CargoCompanyAddError from "./add/cargo-company-add-error";
import CargoCompanyAddParams from "./add/cargo-company-add-params";
import cargoCompanyAddRequestFactory from "./add/cargo-company-add-request.factory";
import CargoCompanyList from "./list/cargo-company-list";
import CargoCompanyListError from "./list/cargo-company-list-error";
import CargoCompanyListLoadParams from "./list/cargo-company-list-load-params";
import cargoCompanyListRequestFactory from "./list/cargo-company-list-request.factory";
import cargoCompanyListFactory from "./list/cargo-company-list.factory";
import CargoCompanyDetails from "./details/cargo-company-details";
import CargoCompanyDetailsError from "./details/cargo-company-details-error";
import CargoCompanyDetailsParams from "./details/cargo-company-details-params";
import cargoCompanyDetailsRequestFactory from "./details/cargo-company-details-request.factory";
import cargoCompanyDetailsFactory from "./details/cargo-company-details.factory";

const handleAddError = (
  error: HttpError | CargoCompanyAddError
): CargoCompanyAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: CargoCompanyAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: CargoCompanyAddRequest =
    cargoCompanyAddRequestFactory.create(params);

  try {
    const response = await cargoCompanyApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyAddError;

    throw handleAddError(error);
  }
};

const handleListError = (
  error: HttpError | CargoCompanyListError
): CargoCompanyListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: CargoCompanyListLoadParams,
  abortSignal: AbortSignal
): Promise<CargoCompanyList> => {
  const request: CargoCompanyListRequest =
    cargoCompanyListRequestFactory.create(params);

  try {
    const response = await cargoCompanyApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoCompanyList = cargoCompanyListFactory.create(response);

    return cargoCompanyList;
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyListError;

    throw handleListError(error);
  }
};

const handleDetailsError = (
  error: HttpError | CargoCompanyDetailsError
): CargoCompanyDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: CargoCompanyDetailsParams,
  abortSignal: AbortSignal
): Promise<CargoCompanyDetails> => {
  const request: CargoCompanyDetailsRequest =
    cargoCompanyDetailsRequestFactory.create(params);

  try {
    const response = await cargoCompanyApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoCompany = cargoCompanyDetailsFactory.create(response.data);

    return cargoCompany;
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyDetailsError;

    throw handleDetailsError(error);
  }
};

const cargoCompanyService = {
  add,
  getList,
  getDetails,
};

export default cargoCompanyService;
