import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import UserRole from "../../../../common/types/user-role";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import DelegationDetailsPassenger from "./types/delegation-details-passenger";

const getPassengerTitle = (passenger: DelegationDetailsPassenger): string => {
  const translations =
    delegationTranslationsHelper.getDelegationDetailsTranslations().worker;

  return translations.workerTitleTemplate
    .replace("#{worker}", passenger.displayName)
    .replace("#{mobileNumber}", passenger.phoneNumber);
};

const getMileageDetailsMessengerChannelAvailability =
  (): MessengerChannelAvailability => {
    return {
      [MessengerChannel.DELEGATION_NOTES]: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.CARGO_OFFICER,
      ],
    };
  };

const delegationDetailsHelper = {
  getPassengerTitle,
  getMileageDetailsMessengerChannelAvailability,
};

export default delegationDetailsHelper;
