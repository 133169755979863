import { FC } from "react";
import ModalComponent from "../../../../../../common/components/modal/modal.component";
import LinkButtonComponent from "../../../../../../common/components/button/link/link-button.component";
import mileageRoutesHelper from "../../../../common/routes/mileage-routes.helper";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";

type MileageAddSuccessModalProps = {
  isOpen: boolean;
  onCloseClick: () => void;
};

const MileageAddSuccessModalComponent: FC<MileageAddSuccessModalProps> = (
  props
) => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().addSuccessModal;

  return (
    <ModalComponent
      header={{ title: translations.headingLabel }}
      isOpen={props.isOpen}
      onClose={props.onCloseClick}
      actions={[
        <LinkButtonComponent
          type="primary"
          title={translations.goToMileageListingTitle}
          to={mileageRoutesHelper.getListingRoute()}
          idForTesting={`mileage-add-success-modal-mileage-listing-link-button`}
        >
          {translations.goToMileageListingLabel}
        </LinkButtonComponent>,
        <ButtonComponent
          onClick={props.onCloseClick}
          type="primary"
          title={translations.addNextMileageTitle}
          idForTesting={`mileage-add-success-modal-next-mileage-button`}
        >
          {translations.addNextMileageLabel}
        </ButtonComponent>,
      ]}
    >
      {translations.messageLabel}
    </ModalComponent>
  );
};

export default MileageAddSuccessModalComponent;
