import classNames from "classnames";
import { FC, ReactNode } from "react";
import ComponentClassnames from "../../types/component-classnames";
import badgeHelper from "./badge.helper";
import BadgeType from "./badge.type";

type BadgeProps = {
  children: ReactNode;
  title?: string;
  classNames?: ComponentClassnames;
  type?: BadgeType;
  idForTesting?: string;
};

const BadgeComponent: FC<BadgeProps> = (props) => {
  const rootClassnamesByType = badgeHelper.getRootClassnameByType(props.type);
  const rootClassnames = classNames(
    "badge",
    props.classNames?.root,
    rootClassnamesByType
  );

  return (
    <div
      className={rootClassnames}
      title={props.title}
      data-test-id={props.idForTesting}
    >
      {props.children}
    </div>
  );
};

export default BadgeComponent;
