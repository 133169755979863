import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import settlementTranslationsHelper from "../../../../../../../languages/settlement-translations.helper";
import TaxiUnsettledTransferedOrderListingFilter, {
  TaxiUnsettledTransferedOrderListingProducerTaxiFilter,
  TaxiUnsettledTransferedOrderListingDriverFilter,
  TaxiUnsettledTransferedOrderListingExternalOrderIdFilter,
  TaxiUnsettledTransferedOrderListingInternalOrderIdFilter,
  TaxiUnsettledTransferedOrderListingPassengerFilter,
  TaxiUnsettledTransferedOrderListingRouteAddressFilter,
  TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter,
  TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter,
  TaxiUnsettledTransferedOrderListingStartDateFilter,
  TaxiUnsettledTransferedOrderListingConsumerTaxiFilter,
} from "../../types/taxi-unsettled-transfered-order-listing-filter";
import TaxiUnsettledTransferedOrderListingFilterType from "../../types/taxi-unsettled-transfered-order-listing-filter-type";

const getStartDateBadgeOption = (
  filter: TaxiUnsettledTransferedOrderListingStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: TaxiUnsettledTransferedOrderListingStartDateFilter;
} => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledTransferedOrderListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.startDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.startDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: TaxiUnsettledTransferedOrderListingFilterType.START_DATE,
      value: filter.value,
    },
  };
};

const getBadgeOptions = (filter: TaxiUnsettledTransferedOrderListingFilter) => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledTransferedOrderListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: TaxiUnsettledTransferedOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.producerTaxiFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingProducerTaxiFilter["value"]
        ),
        title: translations.producerTaxiFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingProducerTaxiFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.PRODUCER_TAXI,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingProducerTaxiFilter["value"],
      },
    },
    {
      badge: {
        text: translations.consumerTaxiFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingConsumerTaxiFilter["value"]
        ),
        title: translations.consumerTaxiFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingConsumerTaxiFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.CONSUMER_TAXI,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingConsumerTaxiFilter["value"],
      },
    },
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingDriverFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.DRIVER,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledTransferedOrderListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledTransferedOrderListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingExternalOrderIdFilter["value"]
        ),
        title: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingExternalOrderIdFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.EXTERNAL_ORDER_ID,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingExternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.PASSENGER,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingRouteAddressFilter["value"]
        ),
        title: translations.routeAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingRouteAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingRouteAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeDestinationAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter["value"]
        ),
        title: translations.routeDestinationAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as TaxiUnsettledTransferedOrderListingStartDateFilter
    ),
  ];

  return options;
};

const getBadge = (filter: TaxiUnsettledTransferedOrderListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: TaxiUnsettledTransferedOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const taxiUnsettledTransferedOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default taxiUnsettledTransferedOrderListingFiltersBadgeListHelper;
