import { FC } from "react";
import BillingsTaxiDriverContributionsBonusTableRow from "../types/billings-taxi-driver-contributions-bonus-table-row";
import BillingsTaxiDriverContributionsBonusTableColumn from "../types/billings-taxi-driver-contributions-bonus-table-column";
import TableComponent from "../../../../../../../common/components/table/table.component";
import BillingsTaxiDriverContributionsBonusTableHelper from "./billings-taxi-driver-contributions-bonus-table.helper";

type BillingsTaxiDriverContributionsBonusTableProps = {
  rows: BillingsTaxiDriverContributionsBonusTableRow[];
  isLoading: boolean;
};

const BillingsTaxiDriverContributionsBonusTableComponent: FC<
  BillingsTaxiDriverContributionsBonusTableProps
> = (props) => {
  const columns: BillingsTaxiDriverContributionsBonusTableColumn[] =
    BillingsTaxiDriverContributionsBonusTableHelper.getColumns();

  return (
    <TableComponent
      columns={columns}
      rows={props.rows}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiDriverContributionsBonusTableComponent;
