import { FC, useEffect, useState } from "react";
import cargoAddressListingFiltersHelper from "./cargo-address-listing-filters.helper";
import CargoAddressListingFiltersBadgeComponent from "./badge/cargo-address-listing-filters-badge.components";
import CargoAddressListingFilter from "../common/types/cargo-address-listing-filter";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import CargoAddressListingFilterSelectOption from "../common/types/cargo-address-listing-filter-select-options";
import ButtonComponent from "../../../../../common/components/button/button.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";

type CargoAddressListingFiltersProps = {
  filters: CargoAddressListingFilter[];
  onAddNewFilter: (filter: CargoAddressListingFilter) => void;
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const CargoAddressListingFiltersComponent: FC<
  CargoAddressListingFiltersProps
> = (props) => {
  const filterTranslations =
    cargoTranslationsHelper.getAddressListingTranslations().filters;
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchSelectOptions, setSearchSelectOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    const allFilterOptions =
      cargoAddressListingFiltersHelper.getSelectOptionsByQuery(
        searchInputValue
      );

    const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
      return !props.filters.find(
        (item) => JSON.stringify(item) === JSON.stringify(option.value)
      );
    });
    setSearchSelectOptions(filterOptionsWithoutSelected);
  }, [searchInputValue, props.filters]);

  const onFilterSelect = (filter: CargoAddressListingFilterSelectOption) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <div className="cargo_address_listing_filters">
      <SingleSelectComponent
        onChange={(option) => onFilterSelect(option!)}
        options={searchSelectOptions}
        value={null}
        classNames={{ root: "cargo_address_listing_filters__select" }}
        placeholder={filterTranslations.search.inputPlaceholder}
        inputValue={searchInputValue}
        onInputChange={setSearchInputValue}
        isSearchable
        filterFunction={() => true}
        menuPlacement="bottom"
        noOptionsMessage={() => {
          return filterTranslations.search.searchTipMessage;
        }}
        maxMenuHeight={400}
        idForTesting="cargo-address-listing-filter"
      />
      <div>
        <div className="cargo_address_listing_filters__list">
          {props.filters.map((filter, index) => (
            <CargoAddressListingFiltersBadgeComponent
              key={`${filter.type}-${filter.value}`}
              filter={filter}
              onDeleteButtonClick={() => props.onDeleteFilterClick(index)}
            />
          ))}
          {!!props.filters.length && (
            <ButtonComponent
              onClick={props.onDeleteAllFiltersButtonClick}
              idForTesting="cargo-address-listing-delete-fitlers-button"
            >
              {filterTranslations.search.removeAllTemplateText}
            </ButtonComponent>
          )}
        </div>
      </div>
    </div>
  );
};

export default CargoAddressListingFiltersComponent;
