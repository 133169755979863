import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import CardComponent from "../../../../../common/components/card/card.component";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import ListingPaginationComponent from "../../../../../common/components/listing/pagination/listing-pagination.component";
import useAbort from "../../../../../common/hooks/use-abort";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import usePagination from "../../../../../common/hooks/use-pagination";
import MileageDictionaryListLoadParams from "../../../../../common/services/mileage-dictionary/list/mileage-dictionary-list-load-params";
import useMileageDictionaryList from "../../../../../common/services/mileage-dictionary/list/use-mileage-dictionary-list";
import { useAppContext } from "../../../../../context/app.context";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import mileageBreadcrumbsHelper from "../../../common/breadcrumbs/mileage-breadcrumbs.helper";
import mileageDictionaryListingItemFactory from "../common/factory/mileage-dictionary-listing-item.factory";
import mileageDictionaryListingLoadParamsFactory from "../common/factory/mileage-dictionary-listing-load-params.factory";
import MileageDictionaryListingTableComponent from "../table/mileage-dictionary-listing-table.component";
import MileageDictionaryListingItem from "../common/types/mileage-dictionary-listing-item";
import MileageDictionaryListingBasicProps from "../common/types/mileage-dictionary-listing-basic-props";
import MileageDictionaryListingCompanyComponent from "../common/components/cargo-company/mileage-dictionary-listing-company.component";
import MileageDictionaryListingCompany from "../common/types/mileage-dictionary-listing-company";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import useMileageDictionaryFiltersDao from "../use-mileage-dictionary-listing.dao";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";
import mileageRoutesHelper from "../../../common/routes/mileage-routes.helper";
import { useNavigate, useParams } from "react-router-dom";
import MileageDictionaryListingRouteParams from "../../../common/routes/types/mileage-dictionary-listing.route.params";
import useCargoCompanyDetails from "../../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";
import CargoCompanyDetailsParams from "../../../../../common/services/cargo-company/cargo-company/details/cargo-company-details-params";
import mileageDictionaryListingCompanyFactory from "../common/factory/mileage-dictionary-listing-company.factory";

type MileageDictionaryAdminListingProps = MileageDictionaryListingBasicProps;

const MileageDictionaryAdminListingComponent: FC<
  MileageDictionaryAdminListingProps
> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageDictionaryTranslations();
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { cargoCompanyUuid } = useParams<MileageDictionaryListingRouteParams>();
  const navigate = useNavigate();

  const [selectedCargoCompany, setSelectedCargoCompany] =
    useState<MileageDictionaryListingCompany | null>(null);

  useEffect(() => {
    const breadcrumbs =
      mileageBreadcrumbsHelper.getMileageDictionaryBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageDictionaryListing);

  const cargoCompanyDetails = useCargoCompanyDetails();
  const cargoCompanyDetailsAbort = useAbort();

  const mileageDictionaryList = useMileageDictionaryList();
  const mileageDictionaryListAbort = useAbort();

  const filtersDao = useMileageDictionaryFiltersDao();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: mileageDictionaryList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const listingItems: MileageDictionaryListingItem[] = useMemo(() => {
    return mileageDictionaryListingItemFactory.create(
      mileageDictionaryList.data.data
    );
  }, [mileageDictionaryList.data.data]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    filtersDao.saveParams(page, pageSize);
  }, [page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    if (
      !selectedCargoCompany ||
      selectedCargoCompany.uuid !== cargoCompanyUuid
    ) {
      return;
    }

    const params: MileageDictionaryListLoadParams =
      mileageDictionaryListingLoadParamsFactory.create(
        page,
        pageSize,
        selectedCargoCompany.uuid
      );

    mileageDictionaryList.load(params, mileageDictionaryListAbort.signal);

    return () => mileageDictionaryListAbort.revoke();
  }, [page, pageSize, selectedCargoCompany]);

  useEffect(() => {
    if (!cargoCompanyUuid || cargoCompanyUuid === "listing") {
      return;
    }

    const detailsLoadParams: CargoCompanyDetailsParams = {
      cargoCompanyUuid: cargoCompanyUuid,
    };

    cargoCompanyDetails.load(
      detailsLoadParams,
      cargoCompanyDetailsAbort.signal
    );
  }, [cargoCompanyUuid]);

  useEffect(() => {
    if (!cargoCompanyDetails.data) {
      return;
    }

    const detailsCompany = mileageDictionaryListingCompanyFactory.createCompany(
      cargoCompanyDetails.data
    );

    setSelectedCargoCompany(detailsCompany);
  }, [cargoCompanyDetails.data]);

  useEffect(() => {
    if (
      !selectedCargoCompany ||
      selectedCargoCompany.uuid === cargoCompanyUuid
    ) {
      return;
    }

    navigate(
      mileageRoutesHelper.getDictionaryRoute({
        cargoCompanyUuid: selectedCargoCompany.uuid,
      }),
      { replace: true }
    );
  }, [selectedCargoCompany]);

  const AddNewDictionaryRecordLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        title={translations.header.addNewDictionaryRecordLinkTitle}
        to={mileageRoutesHelper.getDictionaryRecordAddRoute({
          cargoCompanyUuid: selectedCargoCompany?.uuid!,
        })}
        idForTesting="admin-mileage-dictionary-listing-add-button"
      >
        {translations.header.addNewDictionaryRecordLinkLabel}
      </LinkButtonComponent>
    ),
    [translations, selectedCargoCompany]
  );

  const createHeadingActions = (): ReactNode[] => {
    if (!!selectedCargoCompany) {
      return [AddNewDictionaryRecordLinkButton, ...props.changeViewButtons];
    }

    return [...props.changeViewButtons];
  };

  const headingActions = createHeadingActions();

  const isListingLoading = useMemo(() => {
    return !!selectedCargoCompany && mileageDictionaryList.isLoading;
  }, [selectedCargoCompany, mileageDictionaryList.isLoading]);

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <FormFieldComponent label={translations.company.headingLabel}>
          <MileageDictionaryListingCompanyComponent
            company={selectedCargoCompany}
            onCompanyChange={setSelectedCargoCompany}
          />
        </FormFieldComponent>
        <MileageDictionaryListingTableComponent
          cargoCompanyUuid={selectedCargoCompany?.uuid}
          listingItems={listingItems}
          isError={mileageDictionaryList.isError}
          isLoading={isListingLoading}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={mileageDictionaryList.data.totalCount}
        />
      </CardComponent>
    </>
  );
};

export default MileageDictionaryAdminListingComponent;
