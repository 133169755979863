import CargoCompanyDispatchMultiListResponse, {
  CargoCompanyDispatchMultiListResponseItem,
} from "../../../../api/raily/cargo-company/dispatch/multi-list/cargo-company-dispatch-multi-list.response";
import CargoCompanyDispatchMultiList, {
  CargoCompanyDispatchMultiListItem,
} from "./cargo-company-dispatch-multi-list";

const createDataItem = (
  responseDataItem: CargoCompanyDispatchMultiListResponseItem
): CargoCompanyDispatchMultiListItem => {
  return {
    uuid: responseDataItem.id,
    address: responseDataItem.addressString,
    creationDate: responseDataItem.createdAt,
    displayName: responseDataItem.displayName,
    name: responseDataItem.dispatchName,
  };
};

const createData = (
  responseData: CargoCompanyDispatchMultiListResponseItem[]
): CargoCompanyDispatchMultiListItem[] => responseData.map(createDataItem);

const create = (
  response: CargoCompanyDispatchMultiListResponse
): CargoCompanyDispatchMultiList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const cargoCompanyDispatchMultiListFactory = { create };

export default cargoCompanyDispatchMultiListFactory;
