import UserRole from "../../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../../common/utils/user/permissions/user-permissions.service";
import driverContractAddUserPermissionDefinition, {
  DriverContractAddUserPermissions,
} from "./driver-contract-add-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): DriverContractAddUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    driverContractAddUserPermissionDefinition
  );
};

const driverContractAddUserPermissionsHelper = {
  getPermissions,
};

export default driverContractAddUserPermissionsHelper;
