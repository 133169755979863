import TaxiUnsettledOrderListingItemStatus from "./taxi-unsettled-order-listing-item-status";

export enum TaxiUnsettledOrderListingItemMissingSettlementType {
  CARGO_WITH_RAILY = "CARGO_WITH_RAILY",
  CARGO_WITH_TAXI = "CARGO_WITH_TAXI",
  TAXI_WITH_RAILY = "TAXI_WITH_RAILY",
  RAILY_WITH_TAXI = "RAILY_WITH_TAXI",
}

type TaxiUnsettledOrderListingItem = {
  uuid: string;
  startDate: Date;
  internalOrderId: number;
  externalOrderId: string | null;
  routeAddresses: (string | string[])[];
  passengers: string[];
  dispatchName: string;
  clientName: string;
  taxiCorporations: string[];
  driversDisplayNames: string[];
  status: TaxiUnsettledOrderListingItemStatus;
  missingSettlementTypes: TaxiUnsettledOrderListingItemMissingSettlementType[];
};

export default TaxiUnsettledOrderListingItem;
