import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ButtonComponent from "../../../common/components/button/button.component";
import { useAppContext } from "../../../context/app.context";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import CargoOrderAddComponent from "./cargo/cargo-order-add.component";
import OrderAddViewMode from "./common/types/order-add-view-mode";
import RailyOrderAddComponent from "./raily/raily-order-add.component";
import orderAddUserPermissionsHelper from "./common/user-permissions/order-add-user-permission.helper";

const OrderAddComponent: FC = () => {
  const { user, selectedAppLanguage } = useAppContext();

  const userPermissions = useMemo(
    () => orderAddUserPermissionsHelper.getPermissions(user?.roles!),
    []
  );

  const translations = useMemo(
    () => orderTranslationsHelper.getAddTranslations(),
    [selectedAppLanguage]
  );

  const [selectedViewMode, setSelectedViewMode] =
    useState<OrderAddViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(OrderAddViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToCargoButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(OrderAddViewMode.CARGO)}
        title={translations.header.changeViewToCargoButtonTitle}
      >
        {translations.header.changeViewToCargoButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: OrderAddViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToCargoButton,
          hasPermission: userPermissions.hasAccessToCargoView,
        },
      },
      {
        viewMode: OrderAddViewMode.CARGO,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToCargoButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: OrderAddViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: OrderAddViewMode.RAILY,
        component: (
          <RailyOrderAddComponent
            changeViewButtons={getViewChangeButtons(OrderAddViewMode.RAILY)}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: OrderAddViewMode.CARGO,
        component: (
          <CargoOrderAddComponent
            changeViewButtons={getViewChangeButtons(OrderAddViewMode.CARGO)}
          />
        ),
        hasPermission: userPermissions.hasAccessToCargoView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default OrderAddComponent;
