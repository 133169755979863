const taxiRoutesUrls = {
  add: `/taxi/add`,
  listing: `/taxi/listing`,
  cargoContractAdd: `/taxi/:taxiCorporationUuid/cargo-contract/add`,
  taxiContractAdd: `/taxi/:taxiCorporationUuid/taxi-contract/add`,
  taxiContractEdit: `/taxi/:taxiCorporationUuid/taxi-contract/:contractUuid/edit`,
  cargoContractListing: `/taxi/:taxiCorporationUuid/cargo-contract/listing`,
  cargoContractEdit: `/taxi/:taxiCorporationUuid/cargo-contract/:contractUuid/edit`,
  taxiContractListing: `/taxi/:taxiCorporationUuid/taxi-contract/listing`,
  taxiFleetPartnerListing: `/taxi/:taxiCorporationUuid/fleet-partner/listing`,
  taxiFleetPartnerAdd: `/taxi/:taxiCorporationUuid/fleet-partner/add`,
  taxiFleetPartnerEdit: `/taxi/:taxiCorporationUuid/fleet-partner/:fleetPartnerUuid/edit`,
};

export default taxiRoutesUrls;
