import { MessengerSendPostRequestBody } from "./messenger-send-post.request";

const createBody = (message: string): MessengerSendPostRequestBody => {
  return {
    content: message,
  };
};

const messengerSendPostRequestFactory = { createBody };

export default messengerSendPostRequestFactory;
