import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import orderDetailsHistoryEntryForwardContentHelper from "./order-details-history-entry-forward-content.helper";
import { OrderDetailsTHandlingLogEntryForwardEvent } from "../../../common/types/order-details-handling-log-entry-event";

type OrderDetailsHistoryEntryForwardContentProps = {
  event: OrderDetailsTHandlingLogEntryForwardEvent;
};

const OrderDetailsHistoryEntryForwardContentComponent: FC<
  OrderDetailsHistoryEntryForwardContentProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().forwardOrderEntry;

  const contractDisplayNameLabel =
    orderDetailsHistoryEntryForwardContentHelper.getContractDisplayNameLabel(
      props.event.contractDisplayName
    );

  const contractDisplayNameTitle =
    orderDetailsHistoryEntryForwardContentHelper.getContractDisplayNameTitle(
      props.event.contractDisplayName
    );

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingText}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={contractDisplayNameTitle}
      >
        {contractDisplayNameLabel}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryForwardContentComponent;
