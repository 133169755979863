export enum MileageAddressListLoadParamsOrder {
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  MODIFIED_AT_DESC = "MODIFIED_AT_DESC",
  MODIFIED_AT_ASC = "MODIFIED_AT_ASC",
  STREET_ASC = "STREET_ASC",
  STREET_DESC = "STREET_DESC",
  TOWN_ASC = "TOWN_ASC",
  TOWN_DESC = "TOWN_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
}

type MileageAddressListLoadParams = {
  creationStart?: Date;
  creationEnd?: Date;
  order?: MileageAddressListLoadParamsOrder;
  searchQuery?: string;
};

export default MileageAddressListLoadParams;
