import { FC, useState } from "react";
import Calendar, { Detail } from "react-calendar";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../../../../context/app.context";
import classNames from "classnames";
import ComponentClassnames from "../../../../types/component-classnames";
import DateRange from "../../../../types/date-range";
import datePickerHelper from "../date-picker.helper";

type DateRangePickerProps = {
  classNames?: ComponentClassnames;
  date: DateRange | null;
  onChange: (date: DateRange | null) => void;
  minDate?: Date;
  maxDate?: Date;
};

const DateRangePickerComponent: FC<DateRangePickerProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const dateLocale = datePickerHelper.getDatePickerLocale(selectedAppLanguage);

  const [viewMode, setViewMode] = useState<Detail>("month");

  const Icon = ({ icon }: { icon: IconProp }) => (
    <FontAwesomeIcon size="sm" icon={icon} />
  );

  const onChange = (date: Date[]) => {
    props.onChange({
      from: date[0],
      to: date[1],
    });
  };

  return (
    <div className={classNames("date_range_picker", props.classNames?.root)}>
      <Calendar
        prev2Label={<Icon icon={faAngleDoubleLeft} />}
        prevLabel={<Icon icon={faAngleLeft} />}
        nextLabel={<Icon icon={faAngleRight} />}
        next2Label={<Icon icon={faAngleDoubleRight} />}
        className="date_range_picker_calendar"
        showDoubleView
        selectRange
        view={viewMode}
        onViewChange={(value) => setViewMode(value.view)}
        minDate={props.minDate}
        maxDate={props.maxDate}
        value={[props.date?.from ?? null, props.date?.to ?? null]}
        onChange={onChange}
        locale={dateLocale}
      />
    </div>
  );
};

export default DateRangePickerComponent;
