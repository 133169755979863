import {
  MileageListResponseAddressSequenceNode,
  MileageListResponseAddressSequence,
} from "../../../../../common/api/raily/mileage/list/mileage-list.response";
import MileageRoadRouteLoadParams, {
  MileageRoadRouteWaypoints,
} from "../../../../../common/services/mileage/road-route/mileage-road-route-load-params";
import MileageDetailsRouteWaypoint from "../types/mileage-details-route-waypoint";

const createWaypoint = (
  address: MileageListResponseAddressSequenceNode,
  index: number
): MileageDetailsRouteWaypoint => {
  return {
    stageNo: index + 1,
    place: {
      displayName: address.displayName,
      latitude: address.lat,
      longitude: address.lon,
    },
    distance: null,
    completionDate: null,
  };
};

const createWaypoints = (
  responseTransportingMileages: MileageListResponseAddressSequence
): MileageDetailsRouteWaypoint[] => {
  const waypoints = responseTransportingMileages.seq.map(createWaypoint);

  return waypoints;
};

const createWaypointDate = (startDate: Date, duration: number): Date => {
  return new Date(startDate.getTime()! + 1000 * duration);
};

const createSearchRoutingCoordinate = (
  waypoint: MileageDetailsRouteWaypoint
): MileageRoadRouteWaypoints => {
  return {
    lat: waypoint.place?.latitude!,
    lon: waypoint.place?.longitude!,
  };
};

const createSearchRoutingRequest = (
  waypoints: MileageDetailsRouteWaypoint[]
): MileageRoadRouteLoadParams => {
  const request: MileageRoadRouteLoadParams = {
    waypoints: waypoints.map(createSearchRoutingCoordinate),
  };

  return request;
};

const mileageDetailsWaypointFactory = {
  createWaypoints,
  createSearchRoutingRequest,
  createWaypointDate,
};

export default mileageDetailsWaypointFactory;
