import { TaxiUnsettledPrivateOrderListingTaxiCoporationsResponseDataItem } from "./api/taxi-unsettled-private-order-listing-taxi-corporations.response";
import {
  TaxiUnsettledPrivateOrderListingResponseDataItem,
  TaxiUnsettledPrivateOrderListingResponseDataItemMissingBillings,
} from "./api/taxi-unsettled-private-order-listing.response";
import TaxiUnsettledPrivateOrderListingItem, {
  TaxiUnsettledPrivateOrderListingItemMissingSettlementType,
} from "./types/taxi-unsettled-private-order-listing-item";
import TaxiUnsettledPrivateOrderListingItemStatus from "./types/taxi-unsettled-private-order-listing-item-status";
import TaxiUnsettledPrivateOrderListingTaxiCorporationSelectOption from "./types/taxi-unsettled-private-order-listing-taxi-corporation-select-option";

const createListingItemStatus = (
  responseDataItem: TaxiUnsettledPrivateOrderListingResponseDataItem
): TaxiUnsettledPrivateOrderListingItemStatus => {
  if (responseDataItem.is_cancelled) {
    return TaxiUnsettledPrivateOrderListingItemStatus.CANCELLED;
  }

  return TaxiUnsettledPrivateOrderListingItemStatus.FINISHED;
};

const createListingItemMissingSettlementTypes = (
  responseItemMissingBillings: TaxiUnsettledPrivateOrderListingResponseDataItemMissingBillings[]
): TaxiUnsettledPrivateOrderListingItemMissingSettlementType[] => {
  const missingSettlementTypes: TaxiUnsettledPrivateOrderListingItemMissingSettlementType[] =
    [];

  if (
    responseItemMissingBillings.includes(
      TaxiUnsettledPrivateOrderListingResponseDataItemMissingBillings.CARGO_TAXI
    )
  ) {
    missingSettlementTypes.push(
      TaxiUnsettledPrivateOrderListingItemMissingSettlementType.CARGO_WITH_TAXI
    );
  }

  return missingSettlementTypes;
};

const createListingItem = (
  responseDataItem: TaxiUnsettledPrivateOrderListingResponseDataItem
): TaxiUnsettledPrivateOrderListingItem => {
  return {
    clientName: responseDataItem.client.name,
    dispatchName: responseDataItem.dispatch,
    driversDisplayNames: responseDataItem.drivers,
    externalOrderId: responseDataItem.external_id,
    internalOrderId: responseDataItem.human_id,
    passengers: responseDataItem.passengers,
    routeAddresses: responseDataItem.ride_addresses,
    startDate: new Date(responseDataItem.start_time),
    status: createListingItemStatus(responseDataItem),
    taxiCorporations: responseDataItem.taxi_corporations,
    uuid: responseDataItem.id,
    missingSettlementTypes: createListingItemMissingSettlementTypes(
      responseDataItem.missing_taxi_billings
    ),
  };
};

const createListingItems = (
  responseDataItems: TaxiUnsettledPrivateOrderListingResponseDataItem[]
): TaxiUnsettledPrivateOrderListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: TaxiUnsettledPrivateOrderListingTaxiCoporationsResponseDataItem
): TaxiUnsettledPrivateOrderListingTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      displayName: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: TaxiUnsettledPrivateOrderListingTaxiCoporationsResponseDataItem[]
): TaxiUnsettledPrivateOrderListingTaxiCorporationSelectOption[] => {
  return responseTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const taxiUnsettledPrivateOrderListingFactory = {
  createListingItems,
  createTaxiCorporationSelectOptions,
};

export default taxiUnsettledPrivateOrderListingFactory;
