import Joi from "joi";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import MileageSettingsMileageNumberCreationModel from "../types/mileage-settings-mileage-number-creation-model";
import MileageSettingsFormData from "./types/mileage-settings-form-data";

const validateIsCardNumberRequired = (
  value: boolean,
  defaultValue: boolean
) => {
  const validationSchema = Joi.boolean().allow(defaultValue).only().required();
  return formValidationService.validate(value, validationSchema);
};

const validateIsMileageNumberRequired = (
  value: boolean,
  defaultValue: boolean
) => {
  const validationSchema = Joi.boolean().allow(defaultValue).only().required();
  return formValidationService.validate(value, validationSchema);
};

const validateMileageNumberCreationModel = (
  value: MileageSettingsMileageNumberCreationModel,
  defaultValue: MileageSettingsMileageNumberCreationModel
) => {
  const validationSchema = Joi.string().allow(defaultValue).only().required();
  return formValidationService.validate(value, validationSchema);
};

const validateDefaultValue = (values: MileageSettingsFormData) => {
  return formValidationService.ok();
};

const mileageSettingsFormValidationService = {
  validateIsCardNumberRequired,
  validateIsMileageNumberRequired,
  validateMileageNumberCreationModel,
  validateDefaultValue,
};

export default mileageSettingsFormValidationService;
