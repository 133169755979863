import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import taxiUnsettledRouteListingUserPermissionDefinition, {
  TaxiUnsettledRouteListingUserPermissions,
} from "./taxi-unsettled-route-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): TaxiUnsettledRouteListingUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    taxiUnsettledRouteListingUserPermissionDefinition
  );
};

const taxiUnsettledRouteListingUserPermissionsHelper = {
  getPermissions,
};

export default taxiUnsettledRouteListingUserPermissionsHelper;
