import TaxiTaxiContractDetailsResponse, {
  TaxiTaxiContractDetailsResponseItemBillingModel,
  TaxiTaxiContractDetailsResponseItemTaxiCorporation,
  TaxiTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy,
  TaxiTaxiContractDetailsResponseItemTaxiCorporationFleetPartner,
} from "../../../api/raily/taxi-taxi-contract/details/taxi-taxi-contract-details.response";
import BillingModel from "../../../types/billing-model";
import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import TaxiTaxiContractDetails, {
  TaxiTaxiContractDetailsTaxiCorporation,
  TaxiTaxiContractDetailsTaxiCorporationFleetPartner,
} from "./taxi-taxi-contract-details";

const createBillingModel = (
  responseModel: TaxiTaxiContractDetailsResponseItemBillingModel
) => {
  switch (responseModel) {
    case TaxiTaxiContractDetailsResponseItemBillingModel.AB:
      return BillingModel.AB;
    case TaxiTaxiContractDetailsResponseItemBillingModel.ABA:
      return BillingModel.ABA;
    case TaxiTaxiContractDetailsResponseItemBillingModel.OTHER:
      return BillingModel.OTHER;
    case TaxiTaxiContractDetailsResponseItemBillingModel.SABS:
      return BillingModel.SABS;
  }
};

const createTaxiCorporationContractMaintenancePolicy = (
  responseContractMaintenancePolicy: TaxiTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy
): ContractMaintenancePolicy => {
  switch (responseContractMaintenancePolicy) {
    case TaxiTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy.AUCTION:
      return ContractMaintenancePolicy.AUCTION;
    case TaxiTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy.BASIC:
      return ContractMaintenancePolicy.BASIC;
  }
};

const createTaxiCorporationFleetPartner = (
  responseFleetPartner: TaxiTaxiContractDetailsResponseItemTaxiCorporationFleetPartner
): TaxiTaxiContractDetailsTaxiCorporationFleetPartner => {
  return {
    createdBy: responseFleetPartner.createdBy,
    creationDate: responseFleetPartner.createdAt,
    modifiedBy: responseFleetPartner.modifiedBy,
    modifitationDate: responseFleetPartner.modifiedAt,
    name: responseFleetPartner.name,
    taxiCorporationUuid: responseFleetPartner.taxiCorporationId,
    uuid: responseFleetPartner.id,
  };
};

const createTaxiCorporationFleetPartners = (
  responseFleetPartners: TaxiTaxiContractDetailsResponseItemTaxiCorporationFleetPartner[]
): TaxiTaxiContractDetailsTaxiCorporationFleetPartner[] => {
  return responseFleetPartners.map(createTaxiCorporationFleetPartner);
};

const createTaxiCorporation = (
  responseTaxiCorporation: TaxiTaxiContractDetailsResponseItemTaxiCorporation
): TaxiTaxiContractDetailsTaxiCorporation => {
  return {
    address: responseTaxiCorporation.headquartersAddressString,
    companyId: responseTaxiCorporation.regon,
    contractMaintenancePolicy: createTaxiCorporationContractMaintenancePolicy(
      responseTaxiCorporation.contractMaintenancePolicy
    ),
    createdBy: responseTaxiCorporation.createdBy,
    creationDate: responseTaxiCorporation.createdAt,
    displayName: responseTaxiCorporation.displayName,
    fleetPartners: createTaxiCorporationFleetPartners(
      responseTaxiCorporation.fleetPartners
    ),
    modifiedBy: responseTaxiCorporation.modifiedBy,
    modifitationDate: responseTaxiCorporation.modifiedAt,
    name: responseTaxiCorporation.companyName,
    nationalCourtRegister: responseTaxiCorporation.krsNo,
    notes: responseTaxiCorporation.notes,
    taxNumber: responseTaxiCorporation.taxId,
    uuid: responseTaxiCorporation.id,
  };
};

const create = (
  response: TaxiTaxiContractDetailsResponse
): TaxiTaxiContractDetails => {
  return {
    periodOfValidity: {
      from: response.data.validSince,
      to: response.data.validTo,
    },
    isActive: !response.data.disabled,
    billingModel: createBillingModel(response.data.model),
    distanceRate: response.data.distanceRate,
    stoppingRate: response.data.haltingTimeRate,
    freeStoppingPeriodMinutes: response.data.haltingTimeAfterMinutes,
    uuid: response.data.id,
    producerTaxiUuid: response.data.producerTaxiId,
    producerTaxi: createTaxiCorporation(response.data.producerTaxi),
    consumerTaxiUuid: response.data.consumerTaxiId,
    consumerTaxi: createTaxiCorporation(response.data.consumerTaxi),
    displayName: response.data.displayName,
    priority: response.data.priority,
    isValid: response.data.isValid,
  };
};

const taxiTaxiContractDetailsFactory = {
  create,
};

export default taxiTaxiContractDetailsFactory;
