import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-sort-definition";
import { CargoOrderActiveListItemExecutionStatus } from "../../../../common/services/cargo-order/active-list/cargo-order-active-list";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderActiveListingHelper from "../common/order-active-listing.helper";
import OrderActiveListingFilter, {
  OrderActiveListingClientFilter,
  OrderActiveListingDispatchFilter,
  OrderActiveListingDriverFilter,
  OrderActiveListingExecutionStatusFilter,
  OrderActiveListingExternalOrderIdFilter,
  OrderActiveListingInternalOrderIdFilter,
  OrderActiveListingMobileAppUserFilter,
  OrderActiveListingPassengerFilter,
  OrderActiveListingRouteAddressFilter,
  OrderActiveListingRouteDestinationAddressFilter,
  OrderActiveListingRouteIntermediateAddressFilter,
  OrderActiveListingRoutePickupAddressFilter,
  OrderActiveListingTaxiCorporationFilter,
  OrderActiveListingUrgentOnlyFilter,
} from "../common/types/order-active-listing-filter";
import OrderActiveListingFilterType from "../common/types/order-active-listing-filter-type";
import OrderActiveListingSortKey from "../common/types/order-active-listing-sort-key";

const orderActiveListingFilterHelper = () => {
  const translations = orderTranslationsHelper.getActiveListingTranslations();

  const getSelectDefinitionForClient = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.clientTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForExcludeClient = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.excludeClientTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForDispatch = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.dispatchTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForExcludeDispatch = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.excludeDispatchTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForDriver = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.driverTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForExecutionStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.executionStatusTemplateLabel.replace(
                "#{query}",
                translations.executionStatus.candidatureLabel
              ),
            value: CargoOrderActiveListItemExecutionStatus.CANDIDATURE,
          },
          {
            label:
              translations.filters.search.executionStatusTemplateLabel.replace(
                "#{query}",
                translations.executionStatus.inProgressLabel
              ),
            value: CargoOrderActiveListItemExecutionStatus.IN_PROGRESS,
          },
          {
            label:
              translations.filters.search.executionStatusTemplateLabel.replace(
                "#{query}",
                translations.executionStatus.pendingLabel
              ),
            value: CargoOrderActiveListItemExecutionStatus.PENDING,
          },
          {
            label:
              translations.filters.search.executionStatusTemplateLabel.replace(
                "#{query}",
                translations.executionStatus.plannedLabel
              ),
            value: CargoOrderActiveListItemExecutionStatus.PLANNED,
          },
        ],
      };
    };

  const getSelectDefinitionForExternalOrderId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.externalOrderIdTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForInternalOrderId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label:
            translations.filters.search.internalOrderIdTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDefinitionForMobileAppUser =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.mobileAppUserLabel,
            value: true,
          },
          {
            label: translations.filters.search.noMobileAppUserLabel,
            value: false,
          },
        ],
      };
    };

  const getSelectDefinitionForPassengers = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.passengerTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForRouteAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.routeAddressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForRouteDestinationAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.routeDestinationAddressTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForRouteIntermediateAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.routeIntermediateAddressTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForRoutePickupAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.routePickupAddressTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForTaxi = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.taxiTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForExcludeTaxi = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.excludeTaxiTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForUrgent =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.urgentOnlyLabel,
            value: true,
          },
        ],
      };
    };

  const getSelectDataByType = (
    filterType: OrderActiveListingFilterType,
    query: string
  ) => {
    const options: Record<
      OrderActiveListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [OrderActiveListingFilterType.CLIENT]: getSelectDefinitionForClient,
      [OrderActiveListingFilterType.EXCLUDE_CLIENT]:
        getSelectDefinitionForExcludeClient,
      [OrderActiveListingFilterType.DISPATCH]: getSelectDefinitionForDispatch,
      [OrderActiveListingFilterType.EXCLUDE_DISPATCH]:
        getSelectDefinitionForExcludeDispatch,
      [OrderActiveListingFilterType.DRIVER]: getSelectDefinitionForDriver,
      [OrderActiveListingFilterType.EXECUTION_STATUS]:
        getSelectDefinitionForExecutionStatus,
      [OrderActiveListingFilterType.EXTERNAL_ORDER_ID]:
        getSelectDefinitionForExternalOrderId,
      [OrderActiveListingFilterType.INTERNAL_ORDER_ID]:
        getSelectDefinitionForInternalOrderId,
      [OrderActiveListingFilterType.MOBILE_APP_USER]:
        getSelectDefinitionForMobileAppUser,
      [OrderActiveListingFilterType.PASSENGER]:
        getSelectDefinitionForPassengers,
      [OrderActiveListingFilterType.ROUTE_ADDRESS]:
        getSelectDefinitionForRouteAddress,
      [OrderActiveListingFilterType.ROUTE_DESTINATION_ADDRESS]:
        getSelectDefinitionForRouteDestinationAddress,
      [OrderActiveListingFilterType.ROUTE_INTERMEDIATE_ADDRESS]:
        getSelectDefinitionForRouteIntermediateAddress,
      [OrderActiveListingFilterType.ROUTE_PICKUP_ADDRESS]:
        getSelectDefinitionForRoutePickupAddress,
      [OrderActiveListingFilterType.TAXI_CORPORATION]:
        getSelectDefinitionForTaxi,
      [OrderActiveListingFilterType.EXCLUDE_TAXI_CORPORATION]:
        getSelectDefinitionForExcludeTaxi,
      [OrderActiveListingFilterType.URGENT_ONLY]: getSelectDefinitionForUrgent,
    };

    const result = options[filterType](query);

    return result;
  };

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForClient = (
    filterValue: OrderActiveListingClientFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.clientLabel,
      translations.filters.clientTitle
    );
  };

  const getBadgeForExcludeClient = (
    filterValue: OrderActiveListingClientFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.excludeClientLabel,
      translations.filters.excludeClientTitle
    );
  };

  const getBadgeForDispatch = (
    filterValue: OrderActiveListingDispatchFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.dispatchLabel,
      translations.filters.dispatchTitle
    );
  };

  const getBadgeForExcludeDispatch = (
    filterValue: OrderActiveListingDispatchFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.excludeDispatchLabel,
      translations.filters.excludeDispatchTitle
    );
  };

  const getBadgeForDriver = (
    filterValue: OrderActiveListingDriverFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.driverLabel,
      translations.filters.driverTitle
    );
  };

  const getBadgeForExecutionStatus = (
    filterValue: OrderActiveListingExecutionStatusFilter["value"]
  ) => {
    return createBadgeData(
      orderActiveListingHelper.getExecutionStatusLabel(filterValue),
      translations.filters.executionStatusLabel,
      translations.filters.executionStatusTitle
    );
  };

  const getBadgeForExternalOrderId = (
    filterValue: OrderActiveListingExternalOrderIdFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.externalOrderIdLabel,
      translations.filters.externalOrderIdTitle
    );
  };

  const getBadgeForInternalOrderId = (
    filterValue: OrderActiveListingInternalOrderIdFilter["value"]
  ) => {
    return createBadgeData(
      String(filterValue),
      translations.filters.internalOrderIdLabel,
      translations.filters.internalOrderIdTitle
    );
  };

  const getBadgeForMobileAppUser = (
    filterValue: OrderActiveListingMobileAppUserFilter["value"]
  ) => {
    return createBadgeData(
      orderActiveListingHelper.getMobileAppUsingLabel(filterValue),
      translations.filters.mobileAppUserLabel,
      translations.filters.mobileAppUserTitle
    );
  };

  const getBadgeForPassenger = (
    filterValue: OrderActiveListingPassengerFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.passengerLabel,
      translations.filters.passengerTitle
    );
  };

  const getBadgeForRouteAddress = (
    filterValue: OrderActiveListingRouteAddressFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.routeAddressLabel,
      translations.filters.routeAddressTitle
    );
  };

  const getBadgeForRouteDestinationAddress = (
    filterValue: OrderActiveListingRouteDestinationAddressFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.routeDestinationAddressLabel,
      translations.filters.routeDestinationAddressTitle
    );
  };

  const getBadgeForRouteIntermediateAddress = (
    filterValue: OrderActiveListingRouteIntermediateAddressFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.routeIntermediateAddressLabel,
      translations.filters.routeIntermediateAddressTitle
    );
  };

  const getBadgeForRoutePickupAddress = (
    filterValue: OrderActiveListingRoutePickupAddressFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.routePickupAddressLabel,
      translations.filters.routePickupAddressTitle
    );
  };

  const getBadgeForTaxiCorporation = (
    filterValue: OrderActiveListingTaxiCorporationFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.taxiLabel,
      translations.filters.taxiTitle
    );
  };

  const getBadgeForExcludeTaxiCorporation = (
    filterValue: OrderActiveListingTaxiCorporationFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.excludeTaxiLabel,
      translations.filters.excludeTaxiTitle
    );
  };

  const getBadgeForUrgentOnly = (
    filterValue: OrderActiveListingUrgentOnlyFilter["value"]
  ) => {
    return {
      text: translations.filters.urgentOnlyLabel,
      title: translations.filters.urgentOnlyTitle,
    };
  };

  const getBadgeDefinitionByType = (
    filterType: OrderActiveListingFilterType,
    filterValue: OrderActiveListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      OrderActiveListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [OrderActiveListingFilterType.CLIENT]: () =>
        getBadgeForClient(
          filterValue as OrderActiveListingClientFilter["value"]
        ),
      [OrderActiveListingFilterType.EXCLUDE_CLIENT]: () =>
        getBadgeForExcludeClient(
          filterValue as OrderActiveListingClientFilter["value"]
        ),
      [OrderActiveListingFilterType.DISPATCH]: () =>
        getBadgeForDispatch(
          filterValue as OrderActiveListingDispatchFilter["value"]
        ),
      [OrderActiveListingFilterType.EXCLUDE_DISPATCH]: () =>
        getBadgeForExcludeDispatch(
          filterValue as OrderActiveListingDispatchFilter["value"]
        ),
      [OrderActiveListingFilterType.DRIVER]: () =>
        getBadgeForDriver(
          filterValue as OrderActiveListingDriverFilter["value"]
        ),
      [OrderActiveListingFilterType.EXECUTION_STATUS]: () =>
        getBadgeForExecutionStatus(
          filterValue as OrderActiveListingExecutionStatusFilter["value"]
        ),
      [OrderActiveListingFilterType.EXTERNAL_ORDER_ID]: () =>
        getBadgeForExternalOrderId(
          filterValue as OrderActiveListingExternalOrderIdFilter["value"]
        ),
      [OrderActiveListingFilterType.INTERNAL_ORDER_ID]: () =>
        getBadgeForInternalOrderId(
          filterValue as OrderActiveListingInternalOrderIdFilter["value"]
        ),
      [OrderActiveListingFilterType.MOBILE_APP_USER]: () =>
        getBadgeForMobileAppUser(
          filterValue as OrderActiveListingMobileAppUserFilter["value"]
        ),
      [OrderActiveListingFilterType.PASSENGER]: () =>
        getBadgeForPassenger(
          filterValue as OrderActiveListingPassengerFilter["value"]
        ),
      [OrderActiveListingFilterType.ROUTE_ADDRESS]: () =>
        getBadgeForRouteAddress(
          filterValue as OrderActiveListingRouteAddressFilter["value"]
        ),
      [OrderActiveListingFilterType.ROUTE_DESTINATION_ADDRESS]: () =>
        getBadgeForRouteDestinationAddress(
          filterValue as OrderActiveListingRouteDestinationAddressFilter["value"]
        ),
      [OrderActiveListingFilterType.ROUTE_INTERMEDIATE_ADDRESS]: () =>
        getBadgeForRouteIntermediateAddress(
          filterValue as OrderActiveListingRouteIntermediateAddressFilter["value"]
        ),
      [OrderActiveListingFilterType.ROUTE_PICKUP_ADDRESS]: () =>
        getBadgeForRoutePickupAddress(
          filterValue as OrderActiveListingRoutePickupAddressFilter["value"]
        ),
      [OrderActiveListingFilterType.TAXI_CORPORATION]: () =>
        getBadgeForTaxiCorporation(
          filterValue as OrderActiveListingTaxiCorporationFilter["value"]
        ),
      [OrderActiveListingFilterType.EXCLUDE_TAXI_CORPORATION]: () =>
        getBadgeForExcludeTaxiCorporation(
          filterValue as OrderActiveListingTaxiCorporationFilter["value"]
        ),
      [OrderActiveListingFilterType.URGENT_ONLY]: () =>
        getBadgeForUrgentOnly(
          filterValue as OrderActiveListingUrgentOnlyFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: OrderActiveListingFilterType
  ): ListingFilterDefinitionOption<OrderActiveListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<OrderActiveListingFilter>[] => {
      return Object.values(OrderActiveListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<OrderActiveListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.internalOrderIdAscLabel,
        value: OrderActiveListingSortKey.INTERNAL_ORDER_ID_ASC,
      },
      {
        label: translations.sort.internalOrderIdDescLabel,
        value: OrderActiveListingSortKey.INTERNAL_ORDER_ID_DESC,
      },
      {
        label: translations.sort.executionStatusAscLabel,
        value: OrderActiveListingSortKey.EXECUTION_STATUS_ASC,
      },
      {
        label: translations.sort.executionStatusDescLabel,
        value: OrderActiveListingSortKey.EXECUTION_STATUS_DESC,
      },
      {
        label: translations.sort.isMobileAppUserAscLabel,
        value: OrderActiveListingSortKey.IS_MOBILE_APP_USER_ASC,
      },
      {
        label: translations.sort.isMobileAppUserDescLabel,
        value: OrderActiveListingSortKey.IS_MOBILE_APP_USER_DESC,
      },
      {
        label: translations.sort.timeToStartAscLabel,
        value: OrderActiveListingSortKey.TIME_TO_START_ASC,
      },
      {
        label: translations.sort.timeToStartDescLabel,
        value: OrderActiveListingSortKey.TIME_TO_START_DESC,
      },
      {
        label: translations.sort.attentionRequiredAscLabel,
        value: OrderActiveListingSortKey.URGENT_ASC,
      },
      {
        label: translations.sort.attentionRequiredDescLabel,
        value: OrderActiveListingSortKey.URGENT_DESC,
      },
    ];
  };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default orderActiveListingFilterHelper;
