export enum MileageSettingsAddRequestMileageNumberCreationModel {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
  DICT = "DICT",
}

export type MileageSettingsAddRequestBody = {
  companyId: string;
  isCardNoRequired: boolean | undefined;
  isMileageNoRequired: boolean | undefined;
  mileageNoCreationType:
    | MileageSettingsAddRequestMileageNumberCreationModel
    | undefined;
};

type MileageSettingsAddRequest = {
  companyUuid: string;
  isCardNumberRequired?: boolean;
  isMileageNumberRequired?: boolean;
  mileageNumberCreationType?: MileageSettingsAddRequestMileageNumberCreationModel;
};

export default MileageSettingsAddRequest;
