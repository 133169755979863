import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";

const validateAddress = (address: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(100).trim().required();

  return formValidationService.validate(address, validationSchema);
};

const validateDate = (date: Date | null): FormValidationResult => {
  const validationSchema = Joi.date().allow(null);

  return formValidationService.validate(date, validationSchema);
};

const planningOrderDriverPlanListingAddressFormValidationService = {
  validateAddress,
  validateDate,
};

export default planningOrderDriverPlanListingAddressFormValidationService;
