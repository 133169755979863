export enum BillingsTaxiDriverContributionContractCorrectionType {
  BASE_AMOUNT_EXTERNAL_TAXI = "BASE_AMOUNT_EXTERNAL_TAXI",
  CONTRACT_CORRECTION_RATE = "CONTRACT_CORRECTION_RATE",
}

export type BillingsTaxiDriverContributionContractCorrectionRate = {
  type: BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE | null;
  rate: number | null;
};

export type BillingsTaxiDriverContributionBaseAmountExternalTaxi = {
  type: BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI | null;
  distance: number | null;
  amount: number | null;
};

type BillingsTaxiDriverContributionContractCorrection =
  | BillingsTaxiDriverContributionContractCorrectionRate
  | BillingsTaxiDriverContributionBaseAmountExternalTaxi;

export default BillingsTaxiDriverContributionContractCorrection;
