import { FC } from "react";
import ListingSortDefinition from "../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterDefinition from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingFilterComponent from "../../../../common/components/listing/filter/listing-filter.component";
import RouteActiveListingFilter from "../common/types/route-active-listing-filter";
import RouteActiveListingSortKey from "../common/types/route-active-listing-sort-key";
import routeActiveListingFilterHelper from "./route-active-listing-filter.helper";

type RouteActiveListingFilterProps = {
  filters: RouteActiveListingFilter[];
  onFiltersChange: (filters: RouteActiveListingFilter[]) => void;
  sortKey: RouteActiveListingSortKey;
  onSortKeyChange: (sortKey: RouteActiveListingSortKey) => void;
  hasAccessToTaxiCorporation: boolean;
};

const RouteActiveListingFilterComponent: FC<RouteActiveListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<RouteActiveListingFilter> =
    routeActiveListingFilterHelper().getFilterDefinition(
      props.hasAccessToTaxiCorporation
    );

  const sortDefinition: ListingSortDefinition =
    routeActiveListingFilterHelper().getSortDefinition(
      props.hasAccessToTaxiCorporation
    );

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as RouteActiveListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as RouteActiveListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="route-active-listing-filter"
      idForTestingSort="route-active-listing-sort"
    />
  );
};

export default RouteActiveListingFilterComponent;
