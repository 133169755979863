import UserStatus from "../../../types/user-status";

export enum OperatorListLoadParamsOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

type OperatorListLoadParams = {
  firstName?: string;
  lastName?: string;
  page: number;
  pageSize: number;
  username?: string;
  order?: OperatorListLoadParamsOrder;
  status?: UserStatus;
  email?: string;
  creationDateFrom?: Date;
  creationDateTo?: Date;
};

export default OperatorListLoadParams;
