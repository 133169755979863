import useAsyncData from "../../../hooks/use-async-data";
import cargoOrderService from "../cargo-order.service";
import CargoOrderActiveList from "./cargo-order-active-list";
import CargoOrderActiveListLoadParams from "./cargo-order-active-list-load-params";

const useCargoOrderActiveList = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<CargoOrderActiveList>({
      data: [],
    });

  const load = async (
    params: CargoOrderActiveListLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoOrderActiveList = await cargoOrderService.getActiveList(
        params,
        signal
      );

      setData(cargoOrderActiveList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useCargoOrderActiveList;
