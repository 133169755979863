import { useRef } from "react";

// TODO: do usunięcia po refactorze widoków
const useApiCall = <T extends any[]>(
  callback: (...parameters: T) => void,
  interval: number
) => {
  const apiCall = useRef<NodeJS.Timeout>();

  return (...parameters: T) => {
    if (apiCall.current) {
      clearTimeout(apiCall.current);
    }
    apiCall.current = setTimeout(() => {
      callback.apply(this, parameters);
    }, interval);
  };
};

export default useApiCall;
