import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { BillingsOrderTaxiTaxiItem } from "../../../../../../common/services/billings/order/taxi-taxi/billings-order-taxi-taxi";
import LinkButtonComponent from "../../../../../../common/components/button/link/link-button.component";
import dateService from "../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import billingRoutesHelper from "../../../../common/routes/billing-routes.helper";
import BillingsTableStatusComponent from "../../../../common/table/billings-table-status.component";
import RelatedBillingsTaxiTaxiTableRow from "../../../../common/related-billings/taxi-taxi/types/related-billings-taxi-taxi-table-row";

const getTableRow = (
  billingData: BillingsOrderTaxiTaxiItem
): RelatedBillingsTaxiTaxiTableRow => {
  const translations =
    billingsTranslationsHelper.getBillingsTranslations().relatedBillings
      .taxi_taxi.table;

  return {
    id: billingData.billingUuid.toString(),
    value: {
      billingStatus: (
        <BillingsTableStatusComponent status={billingData.status} />
      ),
      date: (
        <div title={dateService.format(billingData.date, "dd/mm/yyyy")}>
          {dateService.format(billingData.date, "dd/mm/yyyy")}
        </div>
      ),
      internalOrderId: (
        <div title={billingData.internalOrderId.toString()}>
          {billingData.internalOrderId}
        </div>
      ),
      companyName: (
        <div title={billingData.contractDetails?.companyName}>
          {billingData.contractDetails?.companyName}
        </div>
      ),
      model: (
        <div title={billingData.contractDetails?.model}>
          {billingData.contractDetails?.model}
        </div>
      ),
      rate: (
        <div title={billingData.contractDetails?.distanceRate.toString()}>
          {billingData.contractDetails?.distanceRate.toFixed(2)} PLN
        </div>
      ),
      distance: (
        <div title={billingData.distance.toString()}>
          {billingData.distance}
        </div>
      ),
      amountForDistance: (
        <div title={billingData.amountForDistance?.toString()}>
          {billingData.amountForDistance?.toFixed(2)} PLN
        </div>
      ),
      sumOfBonuses: (
        <div title={billingData.sumOfBonuses.toString()}>
          {billingData.sumOfBonuses.toFixed(2)} PLN
        </div>
      ),
      sumOfPenalties: (
        <div title={billingData.sumOfPenalties.toString()}>
          {billingData.sumOfPenalties.toFixed(2)} PLN
        </div>
      ),
      amountForChargeHaltings: (
        <div title={billingData.amountForChargeHaltings?.toString()}>
          {billingData.amountForChargeHaltings?.toFixed(2)} PLN
        </div>
      ),
      amountForChargeHighways: (
        <div title={billingData.amountForChargeHighways?.toString()}>
          {billingData.amountForChargeHighways?.toFixed(2)} PLN
        </div>
      ),
      allContributionsAmount: (
        <div title={billingData?.allContributionsAmount?.toString()}>
          {billingData.allContributionsAmount?.toFixed(2)} PLN
        </div>
      ),
      actions: (
        <LinkButtonComponent
          type="primary"
          openInNewTab
          title={
            billingData.readOnly
              ? translations.viewActionLabel
              : billingData.isDraft
              ? translations.addActionLabel
              : translations.viewActionLabel
          }
          to={
            billingData.readOnly
              ? billingRoutesHelper.getTaxiWithTaxiDetailsRoute({
                  billingUuid: billingData.billingUuid,
                })
              : billingData.isDraft
              ? billingRoutesHelper.getTaxiWithTaxiAddRoute({
                  forwardingUuid: billingData.forwardingUuid,
                })
              : billingRoutesHelper.getTaxiWithTaxiDetailsRoute({
                  billingUuid: billingData.billingUuid,
                })
          }
          classNames={{
            root: "related_billings_table_icon",
          }}
        >
          <FontAwesomeIcon icon={billingData.isDraft ? faAdd : faCircleInfo} />
        </LinkButtonComponent>
      ),
    },
  };
};

const createTableRows = (
  listingItems: BillingsOrderTaxiTaxiItem[]
): RelatedBillingsTaxiTaxiTableRow[] => {
  return listingItems.map((listingItem) => getTableRow(listingItem));
};

const realtedBillingsTaxiTaxiFactory = {
  createTableRows,
};

export default realtedBillingsTaxiTaxiFactory;
