import TaxiDriverAssociationContractType from "../../../../../../common/types/taxi-driver-association-contract-type";
import planningTranslationsHelper from "../../../../../../languages/planning-translations.helper";

const getFormOfEmploymentLabel = (
  contractType: TaxiDriverAssociationContractType
): string => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing.details;

  switch (contractType) {
    case TaxiDriverAssociationContractType.B2B:
      return translations.driverContractLabel;
    case TaxiDriverAssociationContractType.CONTRACT_OF_MANDATE:
      return translations.driverContractOfMandateLabel;
    case TaxiDriverAssociationContractType.EMPLOYMENT:
      return translations.driverEmploymentLabel;
  }
};

const planningOrderDriverListingItemHelper = {
  getFormOfEmploymentLabel,
};

export default planningOrderDriverListingItemHelper;
