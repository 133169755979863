import railyApiService from "../raily-api.service";
import DriverDetailsRequest from "./details/driver-details.request";
import DriverDetailsResponse from "./details/driver-details.response";
import driverListRequestFactory from "./list/driver-list-request.factory";
import DriverListRequest, {
  DriverListRequestQueryParams,
} from "./list/driver-list.request";
import DriverListResponse from "./list/driver-list.response";

const driverApiService = () => {
  const getList = async (
    request: DriverListRequest,
    abortSignal: AbortSignal
  ): Promise<DriverListResponse> => {
    const url = `/users/all/drivers`;

    const queryParams: DriverListRequestQueryParams =
      driverListRequestFactory.createQueryParams(request);

    return railyApiService().get<DriverListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getDetails = async (
    request: DriverDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<DriverDetailsResponse> => {
    const url = `drivers/${request.driverId}`;

    return railyApiService().get<DriverDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return { getList, getDetails };
};

export default driverApiService;
