import useAsyncData from "../../../hooks/use-async-data";
import taxiTaxiContractService from "../taxi-taxi-contract.service";
import TaxiTaxiContractDetails from "./taxi-taxi-contract-details";
import TaxiTaxiContractDetailsLoadParams from "./taxi-taxi-contract-details-load-params";

const useTaxiTaxiContractDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<TaxiTaxiContractDetails | null>(null);

  const load = async (
    params: TaxiTaxiContractDetailsLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const taxiContractDetails = await taxiTaxiContractService.getDetails(
        params,
        signal
      );

      setData(taxiContractDetails);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useTaxiTaxiContractDetails;
