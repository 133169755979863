import RouteActiveListResponse, {
  RouteActiveListResponseDataItem,
} from "../../../api/raily/route/active-list/route-active-list.response";
import RouteActiveList, { RouteActiveListItem } from "./route-active-list";

const createDataItem = (
  responseItem: RouteActiveListResponseDataItem
): RouteActiveListItem => {
  return {
    uuid: responseItem.id,
    driverName: responseItem.driverName,
    taxiCorporationName: responseItem.taxiName,
    routeAddresses: responseItem.planEntryNodes,
    humanId: responseItem.humanId,
    startTime: responseItem.startTime,
    passengers: responseItem.involvedCargoLabels,
    cargoOrderHumanIds: responseItem.cargoOrderHumanIds,
  };
};

const createData = (
  responseData: RouteActiveListResponseDataItem[]
): RouteActiveListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: RouteActiveListResponse): RouteActiveList => {
  return {
    data: createData(response.data),
  };
};

const routeActiveListFactory = {
  create,
};

export default routeActiveListFactory;
