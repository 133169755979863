import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsLeftRight,
  faCarSide,
} from "@fortawesome/free-solid-svg-icons";
import { faHourglassHalf } from "@fortawesome/free-regular-svg-icons";
import { OrderOptimizerListingItemEmptyRide } from "../../../../common/types/order-optimizer-listing-item";
import orderOptimizerListingHelper from "../../../../common/order-optimizer-listing.helper";

type OrderOptimizerListingTableRowRouteEmptyRideProps = {
  emptyRide: OrderOptimizerListingItemEmptyRide;
};

const OrderOptimizerListingTableRowRouteEmptyRideComponent: FC<
  OrderOptimizerListingTableRowRouteEmptyRideProps
> = (props) => {
  return (
    <div className="order_optimizer_table_row_route__empty_ride">
      <div className="order_optimizer_table_row_route__empty_ride__top">
        <div>
          <FontAwesomeIcon
            icon={faCarSide}
            className="order_optimizer_table_row_route__empty_ride__icon"
          />
          {orderOptimizerListingHelper.getTimeDeltaLabel(
            props.emptyRide.osrmTime
          )}
        </div>
        <div>
          <FontAwesomeIcon
            icon={faHourglassHalf}
            className="order_optimizer_table_row_route__empty_ride__icon"
          />
          {orderOptimizerListingHelper.getTimeDeltaLabel(
            props.emptyRide.haltingTime
          )}
        </div>
      </div>
      <div className="order_optimizer_table_row_route__empty_ride__bottom">
        <FontAwesomeIcon
          icon={faArrowsLeftRight}
          className="order_optimizer_table_row_route__empty_ride__icon"
        />
        {orderOptimizerListingHelper.getDistanceLabel(
          props.emptyRide.osrmDistance
        )}
      </div>
    </div>
  );
};

export default OrderOptimizerListingTableRowRouteEmptyRideComponent;
