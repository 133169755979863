import orderTranslationsHelper from "../../../../languages/order-translations.helper";

const getContentMessageText = (cargoCompanyOrderId: number) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrder
      .confirmationModal;

  return translations.contentMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const orderDetailsOrderCancelConfirmationHelper = {
  getContentMessageText,
};

export default orderDetailsOrderCancelConfirmationHelper;
