import { FC } from "react";
import { faTaxi } from "@fortawesome/free-solid-svg-icons";
import useOpen from "../../../../../common/hooks/use-open";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsChangeTaxiModal from "../../change-taxi/order-details-change-taxi.component";
import OrderDetailsDriver from "../../common/types/order-details-driver";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsEditTaxiComponentProps = {
  cargoCompanyUuid: string;
  details: OrderDetailsCargoOrder | null;
  drivers: OrderDetailsDriver[];
  tools: OrderDetailsToolsTranslations;
  hasAccess: boolean;
  onSuccess: (value: string) => void;
};

const OrderDetialsEditTaxiComponent: FC<OrderDetialsEditTaxiComponentProps> = ({
  cargoCompanyUuid,
  details,
  drivers,
  tools,
  hasAccess,
  onSuccess,
}) => {
  const { isOpen, open, close } = useOpen();

  const {
    isFinished,
    isCancelled,
    taxiCorporationUuid,
    cargoOrderId,
    cargoCompanyOrderId,
    dispatcherUuid,
  } = details!;

  if (
    !(!isFinished && !isCancelled && !drivers.length && hasAccess) ||
    taxiCorporationUuid === null
  )
    return null;

  const { editTaxiButtonTitle } = tools;

  return (
    <>
      <OrderDetailsToolButtonComponent
        onClick={open}
        icon={faTaxi}
        title={editTaxiButtonTitle}
      />
      {!!isOpen && (
        <OrderDetailsChangeTaxiModal
          isOpen={isOpen}
          onClose={close}
          taxiCorporationUuid={taxiCorporationUuid}
          orderUuid={cargoOrderId}
          cargoCompanyOrderId={cargoCompanyOrderId}
          cargoCompanyUuid={cargoCompanyUuid}
          dispatcherUuid={dispatcherUuid}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default OrderDetialsEditTaxiComponent;
