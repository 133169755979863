import { SearchAddressResponseItem } from "../../../../../common/utils/search-address/search-address.response";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import CargoAddressType from "../../common/types/cargo-address-type";
import CargoAddressTypeSelectOption from "../../common/types/cargo-address-type-select-option";
import { CargoAddressAddRequestAddressType } from "./api/cargo-address-add-add-address.request";
import CargoAddressAddAddress from "./types/cargo-address-add-address";
import CargoAddressAddAddressSelectOption from "./types/cargo-address-add-address-select-option";

const createAddress = (
  searchAddressResponseItem: SearchAddressResponseItem
): CargoAddressAddAddress => {
  const address: CargoAddressAddAddress = {
    displayName: searchAddressResponseItem.display_name,
    apartmentNumber: "",
    country: searchAddressResponseItem.address.country,
    countryCode: searchAddressResponseItem.address.country_code,
    houseNumber: searchAddressResponseItem.address.house_number ?? null,
    latitude: Number(searchAddressResponseItem.lat),
    longitude: Number(searchAddressResponseItem.lon),
    street: searchAddressResponseItem.address.road ?? null,
    town:
      searchAddressResponseItem.address.city ??
      searchAddressResponseItem.address.town ??
      searchAddressResponseItem.address.village ??
      null,
    zipCode: searchAddressResponseItem.address.postcode ?? null,
  };

  return address;
};

const createAddressSelectOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): CargoAddressAddAddressSelectOption => {
  const address = createAddress(searchAddressResponseItem);

  return {
    label: searchAddressResponseItem.display_name,
    value: address,
  };
};

const createAddRequestAddressType = (
  addressType: CargoAddressType
): CargoAddressAddRequestAddressType => {
  switch (addressType) {
    case CargoAddressType.HOTEL:
      return CargoAddressAddRequestAddressType.HOTEL;
    case CargoAddressType.MEETING_POINT:
      return CargoAddressAddRequestAddressType.MEETING_POINT;
    case CargoAddressType.STATION:
      return CargoAddressAddRequestAddressType.STATION;
  }
};

const createAddressSelectOptions = (
  searchAddressResponseItems: SearchAddressResponseItem[]
): CargoAddressAddAddressSelectOption[] => {
  return searchAddressResponseItems.map(createAddressSelectOption);
};

const createEditCargoAddressResponseAddressType = (
  addressType: CargoAddressAddRequestAddressType
): CargoAddressType => {
  switch (addressType) {
    case CargoAddressAddRequestAddressType.HOTEL:
      return CargoAddressType.HOTEL;
    case CargoAddressAddRequestAddressType.MEETING_POINT:
      return CargoAddressType.MEETING_POINT;
    case CargoAddressAddRequestAddressType.STATION:
      return CargoAddressType.STATION;
  }
};

const createAddressTypeSelectOption = (
  item: CargoAddressAddRequestAddressType
): CargoAddressTypeSelectOption => {
  const translations =
    cargoTranslationsHelper.getAddressEditTranslations().address.addressType;
  const addressType = createEditCargoAddressResponseAddressType(item);

  return {
    label: translations[addressType],
    value: addressType,
  };
};

const recreateAddressTypeSelectOption = (
  item: CargoAddressType
): CargoAddressTypeSelectOption => {
  const translations =
    cargoTranslationsHelper.getAddressEditTranslations().address.addressType;

  return {
    label: translations[item],
    value: item,
  };
};

const cargoAddressAddFactory = {
  createAddress,
  createAddressSelectOptions,
  createAddRequestAddressType,
  createAddressTypeSelectOption,
  recreateAddressTypeSelectOption,
};

export default cargoAddressAddFactory;
