import { FC } from "react";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import ModalComponent from "../../../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import orderRouteEditConfirmationModalHelper from "./order-route-edit-confirmation-modal.helper";

type OrderRouteEditConfirmationModalProps = {
  isModalOpen: boolean;
  isEditOrderFetching: boolean;
  isAnyDateFromRouteItemsPastDate: boolean;
  // TODO: Feature #948 postponed to next release
  // isTaxiContractPermitsTollRoads: boolean;
  onCloseClick: () => void;
  onSubmitClick: () => void;
};

const OrderRouteEditConfirmationModalComponent: FC<
  OrderRouteEditConfirmationModalProps
> = (props) => {
  const translations = orderTranslationsHelper.getEditTranslations();
  const { headingText, confirmButtonText, cancelButtonText } =
    translations.summary.submitEditOrderModal;

  const modalContent = orderRouteEditConfirmationModalHelper.getContent(
    props.isAnyDateFromRouteItemsPastDate
    // TODO: Feature #948 postponed to next release
    // props.isTaxiContractPermitsTollRoads
  );

  return (
    <ModalComponent
      classNames={{ root: "order_edit_route_confirmation_modal" }}
      header={{ title: headingText }}
      isOpen={props.isModalOpen}
      onClose={props.onCloseClick}
      actions={[
        <ButtonComponent
          onClick={props.onSubmitClick}
          type="primary"
          title={confirmButtonText}
          idForTesting="order-route-edit-route-confirmation-submit-button"
          isLoading={props.isEditOrderFetching}
        >
          {confirmButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={props.onCloseClick}
          type="danger"
          classNames={{ root: "w-100" }}
          title={cancelButtonText}
          idForTesting="order-route-edit-route-confirmation-close-button"
        >
          {cancelButtonText}
        </ButtonComponent>,
      ]}
    >
      <ul className="order_edit_route_confirmation_modal__content">
        {modalContent.map((item, index) => {
          return <li key={`${index}-${item}`}>{item}</li>;
        })}
      </ul>
    </ModalComponent>
  );
};

export default OrderRouteEditConfirmationModalComponent;
