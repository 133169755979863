import { FC } from "react";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../common/components/form/input/input.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../../common/context/sign-up-driver.context";

type SignUpDriverCompanyAddressProps = {};

const SignUpDriverCompanyAddressComponent: FC<
  SignUpDriverCompanyAddressProps
> = () => {
  const {
    companyData: { address },
    companyDataValidationResults,
    setCompanyDataAddressApartmentNumber,
    setCompanyDataAddressCountry,
    setCompanyDataAddressHouseNumber,
    setCompanyDataAddressStreet,
    setCompanyDataAddressTown,
    setCompanyDataAddressZipcode,
    setCompanyDataAddressDescription,
    validateCompanyDataAddressApartmentNumber,
    validateCompanyDataAddressCountry,
    validateCompanyDataAddressDescription,
    validateCompanyDataAddressHouseNumber,
    validateCompanyDataAddressStreet,
    validateCompanyDataAddressTown,
    validateCompanyDataAddressZipCode,
  } = useSignUpDriverContext();

  const companyDataAddressFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().companyDataForm
      .addressForm;

  return (
    <CardComponent
      classNames={{ root: "sign_up_driver_company_add_address h-100" }}
      header={{ title: companyDataAddressFormTranslations.title }}
    >
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={companyDataAddressFormTranslations.streetLabel}
            isRequired
            classNames={{ root: "mt-0" }}
            errorMessage={
              companyDataValidationResults.addressStreet.errorMessage
            }
          >
            <InputComponent
              value={address.street}
              onChange={setCompanyDataAddressStreet}
              placeholder={companyDataAddressFormTranslations.streetPlaceholder}
              hasError={
                !!companyDataValidationResults.addressStreet.errorMessage
              }
              onBlur={validateCompanyDataAddressStreet}
              idForTesting="company-data-add-new-address-street-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={3}>
          <FormFieldComponent
            label={companyDataAddressFormTranslations.houseNumberLabel}
            isRequired
            classNames={{ root: "mt-0" }}
            errorMessage={
              companyDataValidationResults.addressHouseNumber.errorMessage
            }
          >
            <InputComponent
              value={address.houseNumber}
              onChange={setCompanyDataAddressHouseNumber}
              placeholder={
                companyDataAddressFormTranslations.houseNumberPlaceholder
              }
              hasError={
                !!companyDataValidationResults.addressHouseNumber.errorMessage
              }
              onBlur={validateCompanyDataAddressHouseNumber}
              idForTesting="company-data-add-new-address-house-number-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={3}>
          <FormFieldComponent
            label={companyDataAddressFormTranslations.apartmentNumberLabel}
            errorMessage={
              companyDataValidationResults.addressApartmentNumber.errorMessage
            }
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              value={address.apartmentNumber}
              onChange={setCompanyDataAddressApartmentNumber}
              placeholder={
                companyDataAddressFormTranslations.apartmentNumberPlaceholder
              }
              hasError={
                !!companyDataValidationResults.addressApartmentNumber
                  .errorMessage
              }
              onBlur={validateCompanyDataAddressApartmentNumber}
              idForTesting="company-data-add-new-address-apartment-number-input"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={companyDataAddressFormTranslations.townLabel}
            classNames={{ root: "mt-0" }}
            isRequired
            errorMessage={companyDataValidationResults.addressTown.errorMessage}
          >
            <InputComponent
              value={address.town}
              onChange={setCompanyDataAddressTown}
              placeholder={companyDataAddressFormTranslations.townPlaceholder}
              hasError={!!companyDataValidationResults.addressTown.errorMessage}
              onBlur={validateCompanyDataAddressTown}
              idForTesting="company-data-add-new-address-town-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={6}>
          <FormFieldComponent
            label={companyDataAddressFormTranslations.zipCodeLabel}
            classNames={{ root: "mt-0" }}
            isRequired
            errorMessage={
              companyDataValidationResults.addressZipCode.errorMessage
            }
          >
            <InputComponent
              value={address.zipCode}
              onChange={setCompanyDataAddressZipcode}
              placeholder={
                companyDataAddressFormTranslations.zipCodePlaceholder
              }
              hasError={
                !!companyDataValidationResults.addressZipCode.errorMessage
              }
              onBlur={validateCompanyDataAddressZipCode}
              idForTesting="company-data-add-new-address-zip-code-input"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <FormFieldComponent
        label={companyDataAddressFormTranslations.countryLabel}
        classNames={{ root: "mt-0" }}
        isRequired
        errorMessage={companyDataValidationResults.addressCountry.errorMessage}
      >
        <InputComponent
          value={address.country}
          onChange={setCompanyDataAddressCountry}
          placeholder={companyDataAddressFormTranslations.countryPlaceholder}
          hasError={!!companyDataValidationResults.addressCountry.errorMessage}
          onBlur={validateCompanyDataAddressCountry}
          idForTesting="company-data-add-new-address-country-input"
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={companyDataAddressFormTranslations.descriptionLabel}
        classNames={{ root: "mt-0" }}
        errorMessage={
          companyDataValidationResults.addressDescription.errorMessage
        }
      >
        <InputComponent
          value={address.description}
          onChange={setCompanyDataAddressDescription}
          placeholder={
            companyDataAddressFormTranslations.descriptionPlaceholder
          }
          hasError={
            !!companyDataValidationResults.addressDescription.errorMessage
          }
          onBlur={validateCompanyDataAddressDescription}
          idForTesting="company-data-add-new-address-description-input"
        />
      </FormFieldComponent>
    </CardComponent>
  );
};

export default SignUpDriverCompanyAddressComponent;
