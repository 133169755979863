import { FC } from "react";
import delegationTranslationsHelper from "../../../../../../languages/delegation-translations.helper";
import ModalComponent from "../../../../../../common/components/modal/modal.component";
import LinkButtonComponent from "../../../../../../common/components/button/link/link-button.component";
import delegationRoutesHelper from "../../../../common/routes/delegation-routes.helper";
import ButtonComponent from "../../../../../../common/components/button/button.component";

type DelegationAddSuccessModalProps = {
  isOpen: boolean;
  onCloseClick: () => void;
};

const DelegationAddSuccessModalComponent: FC<DelegationAddSuccessModalProps> = (
  props
) => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().addSuccessModal;

  return (
    <ModalComponent
      header={{ title: translations.headingLabel }}
      isOpen={props.isOpen}
      onClose={props.onCloseClick}
      actions={[
        <LinkButtonComponent
          type="primary"
          title={translations.goToDelegationListingTitle}
          to={delegationRoutesHelper.getListingRoute()}
          idForTesting={`delegation-add-success-modal-delegation-listing-link-button`}
        >
          {translations.goToDelegationListingLabel}
        </LinkButtonComponent>,
        <ButtonComponent
          onClick={props.onCloseClick}
          type="primary"
          title={translations.addNextDelegationTitle}
          idForTesting={`delegation-add-success-modal-next-delegation-button`}
        >
          {translations.addNextDelegationLabel}
        </ButtonComponent>,
      ]}
    >
      {translations.messageLabel}
    </ModalComponent>
  );
};

export default DelegationAddSuccessModalComponent;
