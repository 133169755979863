import classNames from "classnames";
import { FC } from "react";
import TaxiOrderSettlementListingItemBillingStatus from "../../types/taxi-order-settlement-listing-item-billing-status";

type TaxiOrderSettlementTableStatusProps = {
  status: TaxiOrderSettlementListingItemBillingStatus | undefined;
};

const TaxiOrderSettlementListingTableStatusComponent: FC<
  TaxiOrderSettlementTableStatusProps
> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case TaxiOrderSettlementListingItemBillingStatus.CREATED:
        return "created";
      case TaxiOrderSettlementListingItemBillingStatus.ACCEPTED:
        return "accepted";
      case TaxiOrderSettlementListingItemBillingStatus.REJECTED:
        return "rejected";
      case TaxiOrderSettlementListingItemBillingStatus.REOPENED:
        return "reopened";
      case TaxiOrderSettlementListingItemBillingStatus.REOPEN_REQUEST_SENT:
        return "reopen_request_sent";
      default:
        return "";
    }
  };

  const statusClassname = getClassnameByStatus();

  const containerClassNames = classNames(
    "taxi_order_settlements_listing_table_status",
    statusClassname
  );

  return <div className={containerClassNames}></div>;
};

export default TaxiOrderSettlementListingTableStatusComponent;
