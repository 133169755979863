import { FC, RefObject } from "react";
import {
  GroupBase,
  OptionProps,
  SelectInstance,
  components,
} from "react-select";
import SelectOption from "./select-option";
import classNames from "classnames";

type SelectOptionProps = OptionProps<
  SelectOption,
  boolean,
  GroupBase<SelectOption>
> & {
  selectRef: RefObject<
    SelectInstance<SelectOption, boolean, GroupBase<SelectOption>>
  >;
};

const SelectOptionComponent: FC<SelectOptionProps> = (props) => {
  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!props.data.onClick) return;

    props.data.onClick(event);
    props.selectRef.current?.blur();
  };

  return (
    <components.Option
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        onClick: props.data.onClick ? handleOnClick : props.innerProps.onClick,
        "data-test-id": props.data.idForTesting,
      })}
      className={classNames(props.className, "select__option")}
    >
      <div className="label" title={props.data.label}>
        {props.data.label}
      </div>
      {props.data.subText && (
        <div className="subtext">{props.data.subText}</div>
      )}
    </components.Option>
  );
};

export default SelectOptionComponent;
