import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useRailyOfficerListingUserPermissions from "./common/user-permissions/use-raily-officer-listing-user-permissions";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import { useAppContext } from "../../../../context/app.context";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import useAbort from "../../../../common/hooks/use-abort";
import railyOfficerListingLoadParamsFactory from "./common/raily-officer-listing-load-params.factory";
import railyOfficerListingItemFactory from "./common/raily-officer-listing-item.factory";
import RailyOfficerListingItem from "./common/types/raily-officer-listing-item";
import useRailyOfficerListingFiltersDao from "./filters/use-raily-officer-listing-filters.dao";
import RailyOfficerListingSortKey from "./common/types/raily-officer-listing-sort-key";
import RailyOfficerListingFilter from "./common/types/raily-officer-listing-filter";
import usePagination from "../../../../common/hooks/use-pagination";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import UserDeleteUserData from "../../common/delete/common/types/user-delete-user-data";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import RailyOfficerListingFiltersComponent from "./filters/raily-officer-listing-filters.component";
import CardComponent from "../../../../common/components/card/card.component";
import RailyOfficerListingTableComponent from "./table/raily-officer-listing-table.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import UserDeleteComponent from "../../common/delete/user-delete.component";
import useRailyOfficerList from "../../../../common/services/raily-officer/list/use-raily-officer-list";
import RailyOfficerListLoadParams from "../../../../common/services/raily-officer/list/raily-officer-list-load-params";

type RailyOfficerListingProps = {};

const RailyOfficerListingComponent: FC<RailyOfficerListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();
  const userPermissions = useRailyOfficerListingUserPermissions();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userRailyOfficerListing);

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs =
      userBreadcrumbsHelper.getRailyOfficerListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const railyOfficerList = useRailyOfficerList();
  const listingItemsAbort = useAbort();

  const loadListingItems = async () => {
    const params: RailyOfficerListLoadParams =
      railyOfficerListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey
      );

    railyOfficerList.load(params, listingItemsAbort.signal);
  };

  const listingItems: RailyOfficerListingItem[] = useMemo(() => {
    return railyOfficerListingItemFactory.createListingItems(
      railyOfficerList.data.data
    );
  }, [railyOfficerList.data.data]);

  const filtersDao = useRailyOfficerListingFiltersDao();

  const [sortKey, setSortKey] = useState<RailyOfficerListingSortKey>(
    () => filtersDao.getSortKey() ?? RailyOfficerListingSortKey.FIRST_NAME_ASC
  );

  const [filters, setFilters] = useState<RailyOfficerListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: railyOfficerList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const translations =
    userTranslationsHelper.getRailyOfficerListingTranslations();

  const [userSelectedToDelete, setUserSelectedToDelete] =
    useState<UserDeleteUserData | null>(null);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const openDeleteUserModal = () => {
    setIsDeleteUserModalOpen(true);
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onSelectUserToDelete = (userData: UserDeleteUserData) => {
    setUserSelectedToDelete(userData);
    openDeleteUserModal();
  };

  const onDeleteUserSuccess = () => {
    closeDeleteUserModal();
    loadListingItems();
  };

  const RailyOfficerAddLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={userRoutesHelper.getRailyOfficerAddRoute()}
        title={translations.header.addNewRailyOfficerLinkTitle}
        idForTesting="raily-officer-listing-add-button"
      >
        {translations.header.addNewRailyOfficerLinkLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasVisibleAddRailyOfficerButton
        ? RailyOfficerAddLinkButton
        : undefined,
    ];
  };

  const headingActions = createHeadingActions();

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <RailyOfficerListingFiltersComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <RailyOfficerListingTableComponent
          listingItems={listingItems}
          isError={railyOfficerList.isError}
          isLoading={railyOfficerList.isLoading}
          onSelectUserToDelete={onSelectUserToDelete}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={railyOfficerList.data.totalCount}
        />
      </CardComponent>
      <UserDeleteComponent
        isOpen={isDeleteUserModalOpen}
        onClose={closeDeleteUserModal}
        userData={userSelectedToDelete}
        onSuccess={onDeleteUserSuccess}
      />
    </>
  );
};

export default RailyOfficerListingComponent;
