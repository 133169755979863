import Joi from "joi";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";

const validateActivity = (value: boolean | null): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const driverContractEditFormValidationService = {
  validateActivity,
};

export default driverContractEditFormValidationService;
