import railyApiService from "../raily-api.service";
import passengerListRequestFactory from "./list/passenger-list-request.factory";
import PassengerListRequest, {
  PassengerListRequestQueryParams,
} from "./list/passenger-list.request";
import PassengerListResponse from "./list/passenger-list.response";

const passengerApiService = () => {
  const getList = async (
    request: PassengerListRequest,
    abortSignal: AbortSignal
  ): Promise<PassengerListResponse> => {
    const url = `/users/all/passengers`;

    const queryParams: PassengerListRequestQueryParams =
      passengerListRequestFactory.createQueryParams(request);

    return railyApiService().get<PassengerListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  return { getList };
};

export default passengerApiService;
