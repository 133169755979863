import { OperatorListItem } from "../../../../../common/services/operator/list/operator-list";
import UserStatus from "../../../../../common/types/user-status";
import phoneNumberService from "../../../../../common/utils/phone-number/phone-number.service";
import OperatorListingItem from "./types/operator-listing-item";

const createMobilePhones = (
  listItem: OperatorListItem
): OperatorListingItem["phoneNumbers"] => {
  const phoneNumbers: OperatorListingItem["phoneNumbers"] = [
    phoneNumberService.getLabel(listItem.phoneNumber),
  ];

  if (listItem.alternativePhoneNumber) {
    phoneNumbers.push(
      phoneNumberService.getLabel(listItem.alternativePhoneNumber)
    );
  }

  return phoneNumbers;
};

const createListingItem = (listItem: OperatorListItem): OperatorListingItem => {
  const phoneNumbers = createMobilePhones(listItem);

  return {
    uuid: listItem.uuid,
    firstName: listItem.firstName,
    lastName: listItem.lastName,
    username: listItem.username,
    email: listItem.email,
    phoneNumbers,
    status: !listItem.removalDate ? UserStatus.ACTIVE : UserStatus.DELETED,
  };
};

const createListingItems = (
  listItems: OperatorListItem[]
): OperatorListingItem[] => {
  return listItems.map(createListingItem);
};

const operatorListingItemFactory = {
  createListingItems,
};

export default operatorListingItemFactory;
