import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiUnsettledPrivateOrderListingSortKey from "./types/taxi-unsettled-private-order-listing-sort-key";
import TaxiUnsettledPrivateOrderListingSortSelectOption from "./types/taxi-unsettled-private-order-listing-sort-select-option";

const getSelectOptions =
  (): TaxiUnsettledPrivateOrderListingSortSelectOption[] => {
    const translations =
      settlementTranslationsHelper.getTaxiUnsettledPrivateOrderListingTranslations();
    return [
      {
        label: translations.sort.startDateAscOptionLabel,
        value: TaxiUnsettledPrivateOrderListingSortKey.START_DATE_ASC,
      },
      {
        label: translations.sort.startDateDescOptionLabel,
        value: TaxiUnsettledPrivateOrderListingSortKey.START_DATE_DESC,
      },
      {
        label: translations.sort.internalOrderIdAscOptionLabel,
        value: TaxiUnsettledPrivateOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
      },
      {
        label: translations.sort.internalOrderIdDescOptionLabel,
        value: TaxiUnsettledPrivateOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
      },
    ];
  };

const taxiUnsettledPrivateOrderListingSortHelper = {
  getSelectOptions,
};

export default taxiUnsettledPrivateOrderListingSortHelper;
