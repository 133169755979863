export enum DriverPlanCandidatureAddRequestCandidatureEntryType {
  CREATE_SOLVED_ORDER = "CREATE_SOLVED_ORDER",
  DELETE_SOLVED_ORDER = "DELETE_SOLVED_ORDER",
  CREATE_PLAN_ENTRY = "CREATE_PLAN_ENTRY",
}

export type DriverPlanCandidatureAddRequestCandidatureCreatePlanEntry = {
  candidateEntryType: DriverPlanCandidatureAddRequestCandidatureEntryType.CREATE_PLAN_ENTRY;
  transportingOrderId: string | undefined;
  taxiCorporationId: string | undefined;
  givenPlanEntryId: string | undefined;
  candidatingSolve: string;
};

export type DriverPlanCandidatureAddRequestCandidatureCreateSolvedOrderEntry = {
  candidateEntryType: DriverPlanCandidatureAddRequestCandidatureEntryType.CREATE_SOLVED_ORDER;
  planEntryId: string | undefined;
  createPlanEntryId: string | undefined;
  transportingOrderId: string;
  candidatingSolve: string;
};

export type DriverPlanCandidatureAddRequestCandidatureDeleteSolvedOrderEntry = {
  candidateEntryType: DriverPlanCandidatureAddRequestCandidatureEntryType.DELETE_SOLVED_ORDER;
  deleteTargetId: string;
};

export type DriverPlanCandidatureAddRequestCandidatureEntry =
  | DriverPlanCandidatureAddRequestCandidatureCreatePlanEntry
  | DriverPlanCandidatureAddRequestCandidatureCreateSolvedOrderEntry
  | DriverPlanCandidatureAddRequestCandidatureDeleteSolvedOrderEntry;

export type DriverPlanCandidatureAddRequestBody = {
  candidateEntries: DriverPlanCandidatureAddRequestCandidatureEntry[];
  driverId: string;
  acceptImmediately?: boolean;
};

type DriverPlanCandidatureAddRequest = {
  candidatureEntries: DriverPlanCandidatureAddRequestCandidatureEntry[];
  driverId: string;
  acceptImmediately?: boolean;
};

export default DriverPlanCandidatureAddRequest;
