import UserRole from "../../../../../common/types/user-role";
import UserPermissions from "../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../common/utils/user/permissions/user-permissions-definition";

export type MileageAddUserPermissionOption =
  | "hasAccessToCargoView"
  | "hasAccessToRailyView";

export type MileageAddUserPermissionsDefinition =
  UserPermissionsDefinition<MileageAddUserPermissionOption>;

export type MileageAddUserPermissions =
  UserPermissions<MileageAddUserPermissionOption>;

const mileageAddUserPermissionsDefinition: MileageAddUserPermissionsDefinition =
  {
    hasAccessToCargoView: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default mileageAddUserPermissionsDefinition;
