import AppAsideExpandedNavigationGroup from "./types/app-aside-expanded-navigation-group";
import AppAsideExpandedNavigationItem from "./types/app-aside-expanded-navigation-item";
import AppAsideExpandedNavigationLink from "./types/app-aside-expanded-navigation-link";

const checkIsGroup = (
  item: AppAsideExpandedNavigationItem
): item is AppAsideExpandedNavigationGroup => {
  return !(item as AppAsideExpandedNavigationLink).path;
};

const getCounterValueLabel = (value: number): string => {
  if (value > 999) {
    return "999+";
  }

  return String(value);
};

const appAsideExpandedNavigationHelper = {
  checkIsGroup,
  getCounterValueLabel,
};

export default appAsideExpandedNavigationHelper;
