export enum MileageSettingsAddParamsMileageNumberCreationModel {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
  DICT = "DICT",
}

type MileageSettingsAddParams = {
  companyUuid: string;
  isCardNumberRequired?: boolean;
  isMileageNumberRequired?: boolean;
  mileageNumberCreationModel?: MileageSettingsAddParamsMileageNumberCreationModel;
};

export default MileageSettingsAddParams;
