import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryChangeTimeChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";
import orderDetailsHistoryEntryChangeRideContentChangeTimeHelper from "./order-details-history-entry-change-ride-content-change-time.helper";

type OrderDetailsHistoryEntryChangeRideContentChangeTimeProps = {
  event: OrderDetailsHistoryEntryChangeTimeChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentChangeTimeComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentChangeTimeProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .changeTime;

  const newPlannedDateLabel =
    orderDetailsHistoryEntryChangeRideContentChangeTimeHelper.getNewPlannedDateLabel(
      props.event.details.newPlannedDate
    );
  const newPlannedDateTitle =
    orderDetailsHistoryEntryChangeRideContentChangeTimeHelper.getNewPlannedDateTitle(
      props.event.details.newPlannedDate
    );

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={newPlannedDateTitle}
      >
        {newPlannedDateLabel}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentChangeTimeComponent;
