import { CargoOrderSettlementListingResponseDataItem } from "./api/cargo-order-settlement-listing.response";
import CargoOrderSettlementListingItem from "./types/cargo-order-settlement-listing-item";
import CargoOrderSettlementListingItemBillingStatus from "./types/cargo-order-settlement-listing-item-billing-status";

const createListingItemStatus = (
  responseDataItem: CargoOrderSettlementListingResponseDataItem
): CargoOrderSettlementListingItemBillingStatus | undefined => {
  switch (responseDataItem.status) {
    case "CREATED":
      return CargoOrderSettlementListingItemBillingStatus.CREATED;
    case "ACCEPTED":
      return CargoOrderSettlementListingItemBillingStatus.ACCEPTED;
    case "REJECTED":
      return CargoOrderSettlementListingItemBillingStatus.REJECTED;
    case "REOPENED":
      return CargoOrderSettlementListingItemBillingStatus.REOPENED;
    case "REOPEN_REQUEST":
      return CargoOrderSettlementListingItemBillingStatus.REOPEN_REQUEST_SENT;
    default:
      return undefined;
  }
};

const createRouteAddresses = (
  responseDataItem: CargoOrderSettlementListingResponseDataItem
): (string | string[])[] => {
  return responseDataItem.billing_nodes.map((node) => {
    return node.description;
  });
};

const createListingItem = (
  responseDataItem: CargoOrderSettlementListingResponseDataItem
): CargoOrderSettlementListingItem => {
  return {
    uuid: responseDataItem.id,
    internalOrderId: responseDataItem.human_id,
    orderStartDate: new Date(responseDataItem.date),
    routeAddresses: createRouteAddresses(responseDataItem),
    passengers: responseDataItem.passengers,
    taxiCorporationName: responseDataItem.taxi_corporation,
    externalOrderId: responseDataItem.external_id,
    dispatchName: responseDataItem.dispatch,
    status: createListingItemStatus(responseDataItem),
    distance: responseDataItem.distance,
    amountForDistance: responseDataItem.amount_for_distance,
    baseDistanceRate: responseDataItem.base_distance_rate,
    stoppingTime: responseDataItem.hours_of_haltings,
    stopoverCost: responseDataItem.amount_for_charge_haltings,
    tollRoadsCost: responseDataItem.amount_for_charge_highways,
    discountAmountPercent: responseDataItem.contract_correction_percentage,
    sumOfDiscounts: responseDataItem.sum_of_discounts,
    totalCost: responseDataItem.all_contributions_amount,
    billingType: responseDataItem.billing_type,
  };
};

const createListingItems = (
  responseDataItems: CargoOrderSettlementListingResponseDataItem[]
): CargoOrderSettlementListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const cargoOrderSettlementListingFactory = {
  createListingItems,
};

export default cargoOrderSettlementListingFactory;
