import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import DelegationAddAddress from "../types/delegation-add-address";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import DelegationAddFormData from "./types/delegation-add-form-data";
import DelegationAddVehicleType from "../types/delegation-add-vehicle-type";

const validateWorkerUuid = (companyId: string | null, value: string | null) => {
  if (!companyId) {
    return formValidationService.ok();
  }

  const validationSchema = Joi.string()
    .pattern(
      /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
    )
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateWorkerCompanyUuid = (value: string | null) => {
  const validationSchema = Joi.string()
    .pattern(
      /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
    )
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDelegationDateFrom = (
  value: Date | null,
  limit: DelegationAddFormData["dateTo"]
) => {
  if (!limit) {
    const validationSchema = Joi.date().required();

    return formValidationService.validate(value, validationSchema);
  } else {
    const validationSchema = Joi.date().max(limit).required();

    return formValidationService.validate(value, validationSchema);
  }
};

const validateDelegationDateTo = (
  value: Date | null,
  limit: DelegationAddFormData["dateFrom"]
) => {
  if (!limit) {
    const validationSchema = Joi.date().required();

    return formValidationService.validate(value, validationSchema);
  } else {
    const validationSchema = Joi.date().min(limit).required();

    return formValidationService.validate(value, validationSchema);
  }
};

const validateDelegationNumber = (value: string) => {
  const validationSchema = Joi.string().min(1).max(40).required();

  return formValidationService.validate(value, validationSchema);
};

const validateTotalDistance = (value: number | null) => {
  const validationSchema = Joi.number()
    .min(0)
    .max(99999999)
    .integer()
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateOSRMDistance = (value: number | null) => {
  const validationSchema = Joi.number()
    .min(0)
    .max(99999999)
    .integer()
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietCost = (value: number | null) => {
  const validationSchema = Joi.number()
    .min(0)
    .max(99999999)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateVehicleType = (value: DelegationAddVehicleType) => {
  const validationSchema = Joi.string()
    .valid(...Object.values(DelegationAddVehicleType))
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateMileageCost = (value: number | null) => {
  const validationSchema = Joi.number()
    .min(0)
    .max(99999999)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateAddressSequence = (
  value: DelegationAddAddress[][]
): FormValidationResult => {
  const validationSchema = Joi.array().min(1).required();

  return formValidationService.validate(value, validationSchema);
};

const validateAddressGroupRoutes = (
  value: DelegationAddAddress[]
): FormValidationResult => {
  const validationSchema = Joi.array().min(2);

  return formValidationService.validate(value, validationSchema);
};

const validateAddressGroupStartDate = (
  value: Date | null,
  minDate: Date | null,
  maxDate: Date | null
) => {
  let validationSchema = Joi.date().required();

  if (minDate) {
    validationSchema = validationSchema.min(minDate);
  }
  if (maxDate) {
    validationSchema = validationSchema.max(maxDate);
  }

  return formValidationService.validate(value, validationSchema);
};

const validateAddressGroupEndDate = (
  value: Date | null,
  minDate: Date | null,
  maxDate: Date | null
) => {
  let validationSchema = Joi.date().required();

  if (minDate) {
    validationSchema = validationSchema.min(minDate);
  }
  if (maxDate) {
    validationSchema = validationSchema.max(maxDate);
  }

  return formValidationService.validate(value, validationSchema);
};

const validateAccommodationBillsCost = (value: number | null) => {
  const validationSchema = Joi.number()
    .allow(null)
    .min(0)
    .max(99999999)
    .precision(2);

  return formValidationService.validate(value, validationSchema);
};

const validateAccommodationCost = (value: number | null) => {
  const validationSchema = Joi.number()
    .allow(null)
    .min(0)
    .max(99999999)
    .precision(2);

  return formValidationService.validate(value, validationSchema);
};

const validatePrepay = (value: number | null) => {
  const validationSchema = Joi.number()
    .allow(null)
    .min(0)
    .max(99999999)
    .precision(2);

  return formValidationService.validate(value, validationSchema);
};

const validateStartedDayDietCost = (value: number | null) => {
  const validationSchema = Joi.number()
    .allow(null)
    .min(0)
    .max(99999999)
    .precision(2);

  return formValidationService.validate(value, validationSchema);
};

const validateWholeDayDietCost = (value: number | null) => {
  const validationSchema = Joi.number()
    .allow(null)
    .min(0)
    .max(99999999)
    .precision(2);

  return formValidationService.validate(value, validationSchema);
};

const validateLocalTravelCost = (value: number | null) => {
  const validationSchema = Joi.number()
    .allow(null)
    .min(0)
    .max(99999999)
    .precision(2);

  return formValidationService.validate(value, validationSchema);
};

const validateOneDayDietCost = (value: number | null) => {
  const validationSchema = Joi.number()
    .allow(null)
    .min(0)
    .max(99999999)
    .precision(2);

  return formValidationService.validate(value, validationSchema);
};

const validateOtherExpenses = (value: number | null) => {
  const validationSchema = Joi.number()
    .allow(null)
    .min(0)
    .max(99999999)
    .precision(2);

  return formValidationService.validate(value, validationSchema);
};

const delegationAddFormValidationService = {
  validateTotalDistance,
  validateDelegationDateFrom,
  validateDelegationDateTo,
  validateDelegationNumber,
  validateWorkerUuid,
  validateWorkerCompanyUuid,
  validateDietCost,
  validateMileageCost,
  validateAddressGroupRoutes,
  validateAddressSequence,
  validateVehicleType,
  validateAddressGroupStartDate,
  validateAddressGroupEndDate,
  validateOSRMDistance,
  validateAccommodationBillsCost,
  validateAccommodationCost,
  validatePrepay,
  validateStartedDayDietCost,
  validateWholeDayDietCost,
  validateLocalTravelCost,
  validateOneDayDietCost,
  validateOtherExpenses,
};

export default delegationAddFormValidationService;
