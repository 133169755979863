import OrderOptimizerListRequest, {
  OrderOptimizerListRequestQueryParams,
} from "./order-optimizer-list.request";

const createQueryParams = (
  data: OrderOptimizerListRequest
): OrderOptimizerListRequestQueryParams => {
  return {
    limit: data.limit,
    orderHumanId: data.orderHumanId,
    mergePlanEntries: data.mergePlanEntries,
  };
};

const orderOptimizerListRequestFactory = { createQueryParams };

export default orderOptimizerListRequestFactory;
