import TaxiUnsettledOrderListingItem, {
  TaxiUnsettledOrderListingItemMissingSettlementType,
} from "../../common/types/taxi-unsettled-order-listing-item";
import TaxiUnsettledOrderListingItemStatus from "../../common/types/taxi-unsettled-order-listing-item-status";
import {
  TaxiUnsettledOrderListingByRailyResponseDataItem,
  TaxiUnsettledOrderListingByRailyResponseDataItemLackingRailyBillings as TaxiUnsettledOrderListingByRailyResponseDataItemLackingBillings,
} from "./api/taxi-unsettled-order-listing-by-raily.response";

const createListingItemStatus = (
  responseDataItem: TaxiUnsettledOrderListingByRailyResponseDataItem
): TaxiUnsettledOrderListingItemStatus => {
  if (responseDataItem.is_cancelled) {
    return TaxiUnsettledOrderListingItemStatus.CANCELLED;
  }

  return TaxiUnsettledOrderListingItemStatus.FINISHED;
};

const createListingItemMissingSettlementTypes = (
  responseItemLackingRailyBillings: TaxiUnsettledOrderListingByRailyResponseDataItemLackingBillings[]
): TaxiUnsettledOrderListingItemMissingSettlementType[] => {
  const missingSettlementTypes: TaxiUnsettledOrderListingItemMissingSettlementType[] =
    [];

  if (
    responseItemLackingRailyBillings.includes(
      TaxiUnsettledOrderListingByRailyResponseDataItemLackingBillings.CARGO_RAILY
    )
  ) {
    missingSettlementTypes.push(
      TaxiUnsettledOrderListingItemMissingSettlementType.CARGO_WITH_RAILY
    );
  }

  if (
    responseItemLackingRailyBillings.includes(
      TaxiUnsettledOrderListingByRailyResponseDataItemLackingBillings.RAILY_TAXI
    )
  ) {
    missingSettlementTypes.push(
      TaxiUnsettledOrderListingItemMissingSettlementType.RAILY_WITH_TAXI
    );
  }

  if (
    responseItemLackingRailyBillings.includes(
      TaxiUnsettledOrderListingByRailyResponseDataItemLackingBillings.TAXI_RAILY
    )
  ) {
    missingSettlementTypes.push(
      TaxiUnsettledOrderListingItemMissingSettlementType.TAXI_WITH_RAILY
    );
  }

  return missingSettlementTypes;
};

const createListingItem = (
  responseDataItem: TaxiUnsettledOrderListingByRailyResponseDataItem
): TaxiUnsettledOrderListingItem => {
  return {
    clientName: responseDataItem.client.name,
    dispatchName: responseDataItem.dispatch,
    driversDisplayNames: responseDataItem.drivers,
    externalOrderId: responseDataItem.external_id,
    internalOrderId: responseDataItem.human_id,
    passengers: responseDataItem.passengers,
    routeAddresses: responseDataItem.ride_addresses,
    startDate: new Date(responseDataItem.start_time),
    status: createListingItemStatus(responseDataItem),
    taxiCorporations: responseDataItem.taxi_corporations,
    uuid: responseDataItem.id,
    missingSettlementTypes: createListingItemMissingSettlementTypes(
      responseDataItem.lacking_raily_billings
    ),
  };
};

const createListingItems = (
  responseDataItems: TaxiUnsettledOrderListingByRailyResponseDataItem[]
): TaxiUnsettledOrderListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const taxiUnsettledOrderListingByRailyFactory = {
  createListingItems,
};

export default taxiUnsettledOrderListingByRailyFactory;
