import { FC, useRef } from "react";
import ModalComponent from "../../../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import PlanningOrderDriverPlanListingAddressFilterFormData from "./common/planning-order-driver-plan-listing-address-filter-form-data";
import { PlanningOrderDriverPlanListingAddressFilter } from "../../common/planning-order-driver-plan-listing-filter";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import DateInputComponent from "../../../../../../common/components/form/input/date/date-input.component";
import InputComponent from "../../../../../../common/components/form/input/input.component";
import planningTranslationsHelper from "../../../../../../languages/planning-translations.helper";
import planningOrderDriverPlanListingAddressFilterService from "./planning-order-driver-plan-listing-address-filter-form.helper";
import useForm from "../../../../../../common/components/form/use-form";
import dateService from "../../../../../../common/utils/date/date.service";

type PlanningOrderDriverPlanListingAddressFilterProps = {
  isOpen: boolean;
  onClose: () => void;
  onApply: (
    filterValue: PlanningOrderDriverPlanListingAddressFilter["value"]
  ) => void;
};

const PlanningOrderDriverPlanListingAddressFilterComponent: FC<
  PlanningOrderDriverPlanListingAddressFilterProps
> = (props) => {
  const renderRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing.filters
      .addressForm;

  const form = useForm<PlanningOrderDriverPlanListingAddressFilterFormData>({
    emptyValues:
      planningOrderDriverPlanListingAddressFilterService.getDefaultData(),
    validationDefinition:
      planningOrderDriverPlanListingAddressFilterService.getValidationDefinition(),
  });

  const onApplyButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    const filterValue: PlanningOrderDriverPlanListingAddressFilter["value"] = {
      address: form.values.address,
      startDate: form.values.startDate ?? undefined,
      endDate: form.values.endDate ?? undefined,
    };

    props.onApply(filterValue);
  };

  const onRejectButtonClick = () => {
    props.onClose();
  };

  const ApplyButton = (
    <ButtonComponent
      type="primary"
      onClick={onApplyButtonClick}
      title={translations.applyButtonTitle}
    >
      {translations.applyButtonLabel}
    </ButtonComponent>
  );

  const RejectButton = (
    <ButtonComponent
      type="danger"
      onClick={onRejectButtonClick}
      title={translations.rejectButtonTitle}
    >
      {translations.rejectButtonLabel}
    </ButtonComponent>
  );

  return (
    <ModalComponent
      renderRef={renderRef}
      containerRef={containerRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={{ title: translations.headingLabel }}
      actions={[ApplyButton, RejectButton]}
    >
      <FormFieldComponent
        label={translations.addressLabel}
        errorMessage={form.validationResults.address.errorMessage}
        isRequired
      >
        <InputComponent
          placeholder={translations.addressPlaceholder}
          value={form.values.address}
          onChange={(value) => {
            form.setValue("address", value);
          }}
          onBlur={() => form.validate("address")}
          hasError={!!form.validationResults.address.errorMessage}
          idForTesting="address"
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.startDateLabel}
        errorMessage={form.validationResults.startDate.errorMessage}
      >
        <DateInputComponent
          date={form.values.startDate}
          onChange={(value) => {
            form.setValue("startDate", value);
            form.validate("startDate");
          }}
          placeholder={translations.startDatePlaceholder}
          idForTesting="startDate"
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.endDateLabel}
        errorMessage={form.validationResults.endDate.errorMessage}
      >
        <DateInputComponent
          date={form.values.endDate}
          onChange={(value) => {
            const endOfDayDate = value && dateService.endOfDate(value);

            form.setValue("endDate", endOfDayDate);
            form.validate("endDate");
          }}
          placeholder={translations.endDatePlaceholder}
          idForTesting="endDate"
        />
      </FormFieldComponent>
    </ModalComponent>
  );
};

export default PlanningOrderDriverPlanListingAddressFilterComponent;
