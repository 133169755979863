import { FC, useMemo } from "react";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderAbandonedListingSortHelper from "../common/helper/order-abandoned-listing-sort.helper";
import OrderAbandonedListingSortKey from "../common/types/order-abandoned-listing-sort-key";
import OrderAbandonedListingSortSelectOption from "../common/types/order-abandoned-listing-sort-select-option";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import { useAppContext } from "../../../../context/app.context";

type OrderAbandonedListingSortProps = {
  sortKey: OrderAbandonedListingSortKey | null;
  onSortKeyChange: (key: OrderAbandonedListingSortKey | null) => void;
};

const OrderAbandonedListingSortComponent: FC<OrderAbandonedListingSortProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const translations =
    orderTranslationsHelper.getAbandonedListingTranslations().sort;

  const sortSelectOptions: OrderAbandonedListingSortSelectOption[] = useMemo(
    () => orderAbandonedListingSortHelper.getSelectOptions(),
    [selectedAppLanguage]
  );

  const selectedOption =
    sortSelectOptions.find((item) => item.value === props.sortKey) ?? null;

  return (
    <div className="order_abandoned_listing_sort">
      <SingleSelectComponent
        onChange={(option) => props.onSortKeyChange(option?.value!)}
        options={sortSelectOptions}
        value={selectedOption}
        classNames={{ root: "order_active_listing_sort__select" }}
        placeholder={translations.searchInputPlaceholder}
        isSearchable
        isClearable
        menuPlacement="bottom"
        maxMenuHeight={400}
        idForTesting="order-abandonded-listing-sort"
      />
    </div>
  );
};

export default OrderAbandonedListingSortComponent;
