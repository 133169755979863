import { FC, useMemo } from "react";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderActiveListingJoinTargetOrderData from "../common/types/order-active-listing-join-target-order-data";
import OrderActiveListingJoinSourceOrderData from "../common/types/order-active-listing-join-source-order-data";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import orderActiveListingOrderJoinHelper from "./order-active-listing-order-join.helper";
import ButtonComponent from "../../../../common/components/button/button.component";

type OrderActiveListingOrderJoinProps = {
  targetOrderData: OrderActiveListingJoinTargetOrderData;
  sourceOrdersData: OrderActiveListingJoinSourceOrderData[];
  onCancel: () => void;
};

const OrderActiveListingOrderJoinComponent: FC<
  OrderActiveListingOrderJoinProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getActiveListingTranslations().orderJoin;

  const orderJoinRoute: string = useMemo(() => {
    if (!props.sourceOrdersData.length) {
      return "";
    }

    return orderActiveListingOrderJoinHelper.getOrdersJoinRoute(
      props.targetOrderData,
      props.sourceOrdersData
    );
  }, [props.targetOrderData, props.sourceOrdersData]);

  const targetLabel = orderActiveListingOrderJoinHelper.getTargetLabel(
    props.targetOrderData
  );
  const sourcesLabel = orderActiveListingOrderJoinHelper.getSourcesLabel(
    props.sourceOrdersData
  );

  const isSubmitButtonEnabled = !!props.sourceOrdersData.length;

  return (
    <div className="order_active_listing_order_join">
      <div className="order_active_listing_order_join__heading">
        {translations.headingLabel}
      </div>
      <div className="order_active_listing_order_join__content">
        <div>
          <div>{targetLabel}</div>
          <div>{sourcesLabel}</div>
        </div>
        <div className="d-flex mt-2">
          <LinkButtonComponent
            type="success"
            to={orderJoinRoute}
            isDisabled={!isSubmitButtonEnabled}
            title={translations.submitButtonTitle}
          >
            {translations.submitButtonLabel}
          </LinkButtonComponent>
          <ButtonComponent
            type="brand"
            onClick={props.onCancel}
            classNames={{ root: "ml-1" }}
            title={translations.cancelButtonTitle}
          >
            {translations.cancelButtonLabel}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default OrderActiveListingOrderJoinComponent;
