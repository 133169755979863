import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryShuffleMergeEvent } from "../../../common/types/order-details-history-entry-event";
import orderDetailsHistoryEntryShuffleMergeContentHelper from "./order-details-history-entry-shuffle-merge-content.helper";

type OrderDetailsHistoryEntryShuffleMergeContentProps = {
  event: OrderDetailsHistoryEntryShuffleMergeEvent;
};

const OrderDetailsHistoryEntryShuffleMergeContentComponent: FC<
  OrderDetailsHistoryEntryShuffleMergeContentProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().shuffleMergeEntry;

  const sourceOrdersLabel =
    orderDetailsHistoryEntryShuffleMergeContentHelper.getSourceOrdersLabel(
      props.event.sourceInternalOrderIds
    );

  const targetOrderLabel =
    orderDetailsHistoryEntryShuffleMergeContentHelper.getTargetOrderLabel(
      props.event.targetInternalOrderId
    );

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingText}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={sourceOrdersLabel}
      >
        {sourceOrdersLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={targetOrderLabel}
      >
        {targetOrderLabel}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryShuffleMergeContentComponent;
