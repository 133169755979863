export enum MileageUpdateRequestStatusType {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DELEGATION_CREATED = "DELEGATION_CREATED",
}

export enum MileageUpdateRequestVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type MileageUpdateRequestAddressSequenceNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
  distance?: number;
};

export type MileageUpdateRequestAddressSequence = {
  seq: MileageUpdateRequestAddressSequenceNode[];
};

export type MileageUpdateRequestBody = {
  mileageDate?: Date;
  distance?: number;
  state?: MileageUpdateRequestStatusType;
  vehicleType?: MileageUpdateRequestVehicleType;
  updateReason?: string;
  addressSequence?: MileageUpdateRequestAddressSequence;
  acceptedDistance?: number;
  osrmDistance?: number;
};

type MileageUpdateRequest = {
  mileageUuid: string;
  mileageDate?: Date;
  distance?: number;
  state?: MileageUpdateRequestStatusType;
  vehicleType?: MileageUpdateRequestVehicleType;
  updateReason?: string;
  addressSequence?: MileageUpdateRequestAddressSequenceNode[];
  acceptedDistance?: number;
  osrmDistance?: number;
};

export default MileageUpdateRequest;
