import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import { UserRailyOfficerListingRouteQueryParams } from "../../../common/routes/types/user-raily-officer-listing-route-params";
import RailyOfficerListingFilter from "../common/types/raily-officer-listing-filter";
import RailyOfficerListingSortKey from "../common/types/raily-officer-listing-sort-key";
import railyOfficerListingFiltersService from "./raily-officer-listing-filters.service";

const useRailyOfficerListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<UserRailyOfficerListingRouteQueryParams>();

  const getFilters = (): RailyOfficerListingFilter[] => {
    return railyOfficerListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return railyOfficerListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return railyOfficerListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): RailyOfficerListingSortKey | undefined => {
    return railyOfficerListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: RailyOfficerListingFilter[],
    sortKey: RailyOfficerListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      railyOfficerListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useRailyOfficerListingFiltersDao;
