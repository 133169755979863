import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import settlementTranslationsHelper from "../../../../../../../languages/settlement-translations.helper";
import TaxiRouteSettlementListingFilter, {
  TaxiRouteSettlementListingBillingModelFilter,
  TaxiRouteSettlementListingCargoOrderInternalIdFilter,
  TaxiRouteSettlementListingDriverFilter,
  TaxiRouteSettlementListingFleetPartnerFilter,
  TaxiRouteSettlementListingRouteIdFilter,
  TaxiRouteSettlementListingRouteStartDateFilter,
} from "../../types/taxi-route-settlement-listing-filter";
import TaxiRouteSettlementListingFilterType from "../../types/taxi-route-settlement-listing-filter-type";
import dateService from "../../../../../../../common/utils/date/date.service";

const getRouteStartDateBadgeOption = (
  filter: TaxiRouteSettlementListingRouteStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: TaxiRouteSettlementListingRouteStartDateFilter;
} => {
  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.routeStartDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.routeStartDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: TaxiRouteSettlementListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getBadgeOptions = (filter: TaxiRouteSettlementListingFilter) => {
  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: TaxiRouteSettlementListingFilter;
  }[] = [
    {
      badge: {
        text: translations.billingModelFilterText.replace(
          "#{option}",
          filter.value as TaxiRouteSettlementListingBillingModelFilter["value"]
        ),
        title: translations.billingModelFilterTitle.replace(
          "#{option}",
          filter.value as TaxiRouteSettlementListingBillingModelFilter["value"]
        ),
      },
      filter: {
        type: TaxiRouteSettlementListingFilterType.BILLING_MODEL,
        value:
          filter.value as TaxiRouteSettlementListingBillingModelFilter["value"],
      },
    },
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as TaxiRouteSettlementListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as TaxiRouteSettlementListingDriverFilter["value"]
        ),
      },
      filter: {
        type: TaxiRouteSettlementListingFilterType.DRIVER,
        value: filter.value as TaxiRouteSettlementListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIdFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiRouteSettlementListingRouteIdFilter["value"]
          )
        ),
        title: translations.routeIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiRouteSettlementListingRouteIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiRouteSettlementListingFilterType.ROUTE_ID,
        value: filter.value as TaxiRouteSettlementListingRouteIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.fleetPartnerFilterText.replace(
          "#{query}",
          filter.value as TaxiRouteSettlementListingFleetPartnerFilter["value"]
        ),
        title: translations.fleetPartnerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiRouteSettlementListingFleetPartnerFilter["value"]
        ),
      },
      filter: {
        type: TaxiRouteSettlementListingFilterType.FLEET_PARTNER,
        value:
          filter.value as TaxiRouteSettlementListingFleetPartnerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.cargoOrderInternalIdFilterLabel.replace(
          "#{query}",
          String(
            filter.value as TaxiRouteSettlementListingCargoOrderInternalIdFilter["value"]
          )
        ),
        title: translations.cargoOrderInternalIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiRouteSettlementListingCargoOrderInternalIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiRouteSettlementListingFilterType.CARGO_ORDER_INTERNAL_ID,
        value:
          filter.value as TaxiRouteSettlementListingCargoOrderInternalIdFilter["value"],
      },
    },
    getRouteStartDateBadgeOption(
      filter as TaxiRouteSettlementListingRouteStartDateFilter
    ),
  ];

  return options;
};

const getBadge = (filter: TaxiRouteSettlementListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: TaxiRouteSettlementListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const taxiRouteSettlementListingFiltersBadgeListHelper = {
  getBadges,
};

export default taxiRouteSettlementListingFiltersBadgeListHelper;
