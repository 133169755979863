import HttpResponse from "../../../../utils/http/http.response";

export enum OrderCancelRequestNotAcceptableResponseErrorType {
  ORDER_CANCELLED = "ORDER_CANCELLED",
  ORDER_CANCEL_REQUESTED = "ORDER_CANCEL_REQUESTED",
  ORDER_FINISHED = "ORDER_FINISHED",
}

type OrderCancelRequestNotAcceptableResponseOrderCancelledError = {
  errorType: OrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCELLED;
};

type OrderCancelRequestNotAcceptableResponseOrderCancelRequestedError = {
  errorType: OrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCEL_REQUESTED;
};

type OrderCancelRequestNotAcceptableResponseOrderFinishedError = {
  errorType: OrderCancelRequestNotAcceptableResponseErrorType.ORDER_FINISHED;
};

export type OrderCancelRequestNotAcceptableResponseError =
  | OrderCancelRequestNotAcceptableResponseOrderCancelledError
  | OrderCancelRequestNotAcceptableResponseOrderCancelRequestedError
  | OrderCancelRequestNotAcceptableResponseOrderFinishedError;

type OrderCancelRequestSuccessResponse = HttpResponse<string>;

export type OrderCancelRequestNotAcceptableResponse =
  HttpResponse<OrderCancelRequestNotAcceptableResponseError>;

type OrderCancelRequestResponse =
  | OrderCancelRequestSuccessResponse
  | OrderCancelRequestNotAcceptableResponse;

export default OrderCancelRequestResponse;
