import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../common/hooks/use-is-component-mounted";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import orderBreadcrumbsHelper from "../common/breadcrumbs/order-breadcrumbs.helper";
import useCargoOrderFinishedList from "../../../common/services/cargo-order/finished-list/use-cargo-order-finished-list";
import useAbort from "../../../common/hooks/use-abort";
import CargoOrderFinishedListLoadParams from "../../../common/services/cargo-order/finished-list/cargo-order-finished-list-load-params";
import orderFinishedListingLoadParamsFactory from "./common/order-finished-listing-load-params.factory";
import usePagination from "../../../common/hooks/use-pagination";
import useOrderFinishedListingFiltersDao from "./filter/use-order-finished-listing-filters.dao";
import OrderFinishedListingFilter from "./common/types/order-finished-listing-filter";
import OrderFinishedListingSortKey from "./common/types/order-finished-listing-sort-key";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import OrderFinishedListingItem from "./common/types/order-finished-listing-item";
import HeadingComponent from "../../../common/components/heading/heading.component";
import OrderFinishedListingFilterComponent from "./filter/order-finished-listing-filters.component";
import CardComponent from "../../../common/components/card/card.component";
import OrderFinishedListingTableComponent from "./table/order-finished-listing-table.component";
import ListingPaginationComponent from "../../../common/components/listing/pagination/listing-pagination.component";
import orderFinishedListingItemFactory from "./common/order-finished-listing-item.factory";
import ButtonComponent from "../../../common/components/button/button.component";
import cargoOrderService from "../../../common/services/cargo-order/cargo-order.service";
import CargoOrderFinishedListReport from "../../../common/services/cargo-order/finished-list/report/cargo-order-finished-list-report";
import CargoOrderFinishedListReportLoadParams from "../../../common/services/cargo-order/finished-list/report/cargo-order-finished-list-report-load-params";
import fileDownloadService from "../../../common/utils/file-download/file-download.service";
import notificationService from "../../../common/utils/notification/notification.service";
import CargoOrderFinishedListReportError from "../../../common/services/cargo-order/finished-list/report/cargo-order-finished-list-report-error";
import orderFinishedListingReportLoadParamsFactory from "./common/order-finished-listing-report-load-params.factory";
import orderFinishedListingHelper from "./common/order-finished-listing.helper";
import StatusLegendComponent from "../../../common/components/status-legend/status-legend.component";

type OrderFinishedListingProps = {};

const OrderFinishedListingComponent: FC<OrderFinishedListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderFinishedListing);

  useEffect(() => {
    const breadcrumbs = orderBreadcrumbsHelper.getFinishedListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const finishedOrderList = useCargoOrderFinishedList();
  const listingItemsAbort = useAbort();
  const listingReportAbort = useAbort();

  const filtersDao = useOrderFinishedListingFiltersDao();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: finishedOrderList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const [expandedRowsOrderUuids, setExpandedRowsOrderUuids] = useState<
    OrderFinishedListingItem["uuid"][]
  >([]);

  const [filters, setFilters] = useState<OrderFinishedListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const [sortKey, setSortKey] = useState<OrderFinishedListingSortKey>(
    () =>
      filtersDao.getSortKey() ??
      OrderFinishedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC
  );

  const [isReportFetching, setIsReportFetching] = useState(false);

  const loadListingItems = async () => {
    const params: CargoOrderFinishedListLoadParams =
      orderFinishedListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey
      );

    finishedOrderList.load(params, listingItemsAbort.signal);
  };

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };
  const listingItems: OrderFinishedListingItem[] = useMemo(() => {
    return orderFinishedListingItemFactory.createListingItems(
      finishedOrderList.data.data
    );
  }, [finishedOrderList.data.data]);

  const translations = orderTranslationsHelper.getFinishedListingTranslations();

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    setExpandedRowsOrderUuids([]);
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onTableRowClick = (
    listingItemUuid: OrderFinishedListingItem["uuid"]
  ) => {
    const expandRow = () => {
      setExpandedRowsOrderUuids((curr) => [...curr, listingItemUuid]);
    };

    const collapseRow = () => {
      const filteredExpandedRows = expandedRowsOrderUuids.filter(
        (expandedOrderUuid) => expandedOrderUuid !== listingItemUuid
      );

      setExpandedRowsOrderUuids(filteredExpandedRows);
    };

    const isRowExpanded = expandedRowsOrderUuids.includes(listingItemUuid);

    if (isRowExpanded) {
      collapseRow();
      return;
    }

    expandRow();
  };

  const downloadReportFile = (report: CargoOrderFinishedListReport) => {
    fileDownloadService.downloadFromBlob(report.data, report.filename);
  };

  const onReportFetchSuccess = async (report: CargoOrderFinishedListReport) => {
    downloadReportFile(report);
  };

  const onReportFetchFailure = (error: CargoOrderFinishedListReportError) => {
    notificationService.error(error.message);
  };

  const fetchReport = async () => {
    setIsReportFetching(true);

    const params: CargoOrderFinishedListReportLoadParams =
      orderFinishedListingReportLoadParamsFactory.create(filters, sortKey);

    try {
      const cargoOrderFinishedListReport: CargoOrderFinishedListReport =
        await cargoOrderService.getFinishedListReport(
          params,
          listingReportAbort.signal
        );

      onReportFetchSuccess(cargoOrderFinishedListReport);
    } catch (error) {
      onReportFetchFailure(error as CargoOrderFinishedListReportError);
    } finally {
      setIsReportFetching(false);
    }
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={fetchReport}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting="order-finished-listing-download-report-button"
    >
      {translations.report.downloadButtonLabel}
    </ButtonComponent>
  );

  const statusOptions = orderFinishedListingHelper.getStatusOptions();

  return (
    <>
      <HeadingComponent
        actions={[ReportDownloadButton]}
        title={translations.header.headingLabel}
      />
      <StatusLegendComponent statusData={statusOptions} />
      <OrderFinishedListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <OrderFinishedListingTableComponent
          listingItems={listingItems}
          isError={finishedOrderList.isError}
          isLoading={finishedOrderList.isLoading}
          expandedRowsOrderUuids={expandedRowsOrderUuids}
          onRowClick={onTableRowClick}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={finishedOrderList.data.totalCount}
        />
      </CardComponent>
    </>
  );
};

export default OrderFinishedListingComponent;
