import appConfig from "../../../../../../../app.config";
import urlService from "../../../../../../../common/utils/url/url.service";
import authService from "../../../../../../auth/common/auth.service";
import TaxiUnsettledOrderListingByTaxiRequest from "./taxi-unsettled-order-listing-by-taxi.request";
import TaxiUnsettledOrderListingByTaxiResponse from "./taxi-unsettled-order-listing-by-taxi.response";

const fetchListing = async (
  taxiCorporationUuid: string,
  request: TaxiUnsettledOrderListingByTaxiRequest
): Promise<TaxiUnsettledOrderListingByTaxiResponse> => {
  const path = `${appConfig.baseApiUrl}/taxi-corporations/${taxiCorporationUuid}/orders/not-yet-billed`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const taxiUnsettledOrderListingByTaxiApiService = {
  fetchListing,
};

export default taxiUnsettledOrderListingByTaxiApiService;
