import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsTHandlingLogEntryForwardEvent } from "../../../common/types/order-details-handling-log-entry-event";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations().forwardOrderEntry;

const getContractDisplayNameLabel = (
  contractDisplayName: OrderDetailsTHandlingLogEntryForwardEvent["contractDisplayName"]
) => {
  return translations.contractDisplayNameTemplateText.replace(
    "#{contractDisplayName}",
    contractDisplayName ?? "-----"
  );
};

const getContractDisplayNameTitle = (
  contractDisplayName: OrderDetailsTHandlingLogEntryForwardEvent["contractDisplayName"]
) => {
  return translations.contractDisplayNameTemplateText.replace(
    "#{contractDisplayName}",
    contractDisplayName ?? "-----"
  );
};

const orderDetailsHistoryEntryForwardContentHelper = {
  getContractDisplayNameLabel,
  getContractDisplayNameTitle,
};

export default orderDetailsHistoryEntryForwardContentHelper;
