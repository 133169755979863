enum TaxiTaxiContractListingFilterType {
  BILLING_MODEL = "BILLING_MODEL",
  ACTIVE_STATUS = "ACTIVE_STATUS",
  DISPLAY_NAME = "DISPLAY_NAME",
  PRODUCER_DISPLAY_NAME = "PRODUCER_DISPLAY_NAME",
  CONSUMER_DISPLAY_NAME = "CONSUMER_DISPLAY_NAME",
  PRIORITY = "PRIORITY",
  DISTANCE_RATE_RANGE = "DISTANCE_RATE_RANGE",
  STOP_RATE_RANGE = "STOP_RATE_RANGE",
  FREE_STOPPING_PERIOD = "FREE_STOPPING_PERIOD",
  PERIOD_OF_VALIDATION = "PERIOD_OF_VALIDATION",
  PARTNER = "PARTNER",
}

export default TaxiTaxiContractListingFilterType;
