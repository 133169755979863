import {
  DriverListItem,
  DriverListItemTaxiAssociation,
} from "../../../../../common/services/driver/list/driver-list";
import AddressType from "../../../../../common/types/address-type";
import UserStatus from "../../../../../common/types/user-status";
import phoneNumberService from "../../../../../common/utils/phone-number/phone-number.service";
import DriverListingItem from "./types/driver-listing-item";

const createPhoneNumbers = (
  listItem: DriverListItem
): DriverListingItem["phoneNumbers"] => {
  const phoneNumbers: DriverListingItem["phoneNumbers"] = [
    phoneNumberService.getLabel(listItem.phoneNumber),
  ];

  if (listItem.alternativePhoneNumber) {
    phoneNumbers.push(
      phoneNumberService.getLabel(listItem.alternativePhoneNumber)
    );
  }

  return phoneNumbers;
};

const createFleetPartners = (
  listItemTaxiAssociations: DriverListItemTaxiAssociation[]
): string[] => {
  const fleetPartners: string[] = [];

  listItemTaxiAssociations.forEach((assocation) => {
    if (!assocation.fleetPartner) {
      return;
    }

    fleetPartners.push(assocation.fleetPartner.name);
  });

  return fleetPartners;
};

const createListingItem = (listItem: DriverListItem): DriverListingItem => {
  const startingAddress = listItem.addresses.find(
    (address) => address.type === AddressType.STARTING
  );

  const fleetPartners: string[] = createFleetPartners(
    listItem.taxiAssociations
  );

  const taxiCorporations = listItem.taxiAssociations.map(
    (taxiAssociation) => taxiAssociation.taxiCorporation.displayName
  );

  const createdDate = listItem.taxiAssociations.map(
    (taxiAssociation) => taxiAssociation.creationDate
  );

  const phoneNumbers = createPhoneNumbers(listItem);

  return {
    uuid: listItem.uuid,
    firstName: listItem.firstName,
    lastName: listItem.lastName,
    startingAddress:
      startingAddress?.town && startingAddress.zipCode
        ? `${startingAddress.town} ${startingAddress.zipCode}`
        : "",
    taxiCorporations,
    phoneNumbers,
    vehicle: {
      make: listItem.vehicle.make,
      model: listItem.vehicle.model,
      age: listItem.vehicle.productionYear
        ? new Date().getFullYear() - listItem.vehicle.productionYear
        : null,
      ownership: listItem.vehicle.ownership,
    },
    fleetPartners,
    createdByName: listItem.createdByName,
    creationDate: listItem.creationDate,
    modificationDate: listItem.modificationDate,
    modifiedByName: listItem.modifiedByName,
    isCandidatureActive: listItem.isCandidaturePending,
    status: !listItem.removalDate ? UserStatus.ACTIVE : UserStatus.DELETED,
  };
};

const createListingItems = (
  listItems: DriverListItem[]
): DriverListingItem[] => {
  return listItems.map(createListingItem);
};

const driverListingItemFactory = {
  createListingItems,
};

export default driverListingItemFactory;
