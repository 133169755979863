import { FC } from "react";
import DelegationListingFilter from "../common/types/delegation-listing-filter";
import ListingFilterDefinition from "../../../../common/components/listing/filter/types/listing-filter-definition";
import delegationListingFilterHelper from "./delegation-listing-filters.helper";
import ListingSortDefinition from "../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../common/components/listing/filter/listing-filter.component";
import DelegationListingSortKey from "../common/types/delegation-listing-sort-key";

type DelegationListingFilterProps = {
  filters: DelegationListingFilter[];
  onFiltersChange: (filters: DelegationListingFilter[]) => void;
  sortKey: DelegationListingSortKey;
  onSortKeyChange: (sortKey: DelegationListingSortKey) => void;
};

const DelegationListingFilterComponent: FC<DelegationListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<DelegationListingFilter> =
    delegationListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    delegationListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as DelegationListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as DelegationListingSortKey)
      }
      sortDefinition={sortDefinition}
    />
  );
};

export default DelegationListingFilterComponent;
