import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiUnsettledRouteListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type TaxiUnsettledRouteListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiUnsettledRouteListingUserPermissionOption>;

export type TaxiUnsettledRouteListingUserPermissions =
  UserPermissions<TaxiUnsettledRouteListingUserPermissionOption>;

const taxiUnsettledRouteListingUserPermissionDefinition: TaxiUnsettledRouteListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default taxiUnsettledRouteListingUserPermissionDefinition;
