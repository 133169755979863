import { ReactNode } from "react";
import mapMarkerIconFactory from "../../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import MileageDetailsRouteWaypoint from "../types/mileage-details-route-waypoint";
import isEqual from "lodash/isEqual";

const createMapMarker = (
  route: MileageDetailsRouteWaypoint,
  content?: ReactNode
): MapMarker => {
  const icon = mapMarkerIconFactory.createIcon({
    className: "map_marker default",
    content: content,
  });

  const marker: MapMarker = {
    coordinate: {
      latitude: route.place.latitude,
      longitude: route.place.longitude,
    },
    title: route.place.displayName,
    tooltip: route.place.displayName,
    icon,
  };

  return marker;
};

const createMapMarkers = (
  waypoints: MileageDetailsRouteWaypoint[]
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  for (const waypoint of waypoints) {
    const allWaypointsOnThisPlace = waypoints.filter((w) =>
      isEqual(w.place, waypoint.place)
    );

    const signature = allWaypointsOnThisPlace.map((w) => w.stageNo).join("/");

    const newMarker: MapMarker = createMapMarker(waypoint, signature);

    mapMarkers = [...mapMarkers, newMarker];
  }

  return mapMarkers;
};

const mileageDetailsMapMarkersFactory = {
  createMapMarkers,
};

export default mileageDetailsMapMarkersFactory;
