import {
  faChevronDown,
  faChevronUp,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import ButtonComponent from "../../../button/button.component";
import InputComponent from "../../input/input.component";

type DateTimePickerClockProps = {
  selectedDate: Date | null;
  onChange: (date: Date) => void;
};

const DateTimePickerClockComponent: FC<DateTimePickerClockProps> = (props) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setHours(props.selectedDate?.getHours() ?? 0);
    setMinutes(props.selectedDate?.getMinutes() ?? 0);
    setSeconds(props.selectedDate?.getSeconds() ?? 0);
  }, [props.selectedDate]);

  useEffect(() => {
    if (!props.selectedDate) return;

    props.selectedDate.setHours(hours);
    props.onChange(props.selectedDate);
  }, [hours]);

  useEffect(() => {
    if (!props.selectedDate) return;

    props.selectedDate.setMinutes(minutes);
    props.onChange(props.selectedDate);
  }, [minutes]);

  useEffect(() => {
    if (!props.selectedDate) return;

    props.selectedDate.setSeconds(seconds);
    props.onChange(props.selectedDate);
  }, [seconds]);

  const isHoursEditingEnabled = !!props.selectedDate;
  const isMinutesEditingEnabled = !!props.selectedDate;
  const isSecondsEditingEnabled = !!props.selectedDate;

  const onInputValueChange = (
    value: string,
    maxNumber: number,
    callback: (value: number) => void
  ) => {
    const newValue = Number(value);

    if (
      !checkIsStringContainsOnlyDigits(value) ||
      newValue < 0 ||
      newValue > maxNumber
    )
      return;

    callback(newValue);
  };

  const increaseHours = () => {
    if (!props.selectedDate) return;

    setHours((hours: number) => (hours + 1) % 24);
  };

  const decreaseHours = () => {
    if (!props.selectedDate) return;

    if (hours === 0) {
      return setHours(23);
    }

    setHours((hours: number) => (hours - 1) % 24);
  };

  const increaseMinutes = () => {
    if (!props.selectedDate) return;

    if (minutes === 59) {
      return setMinutes(0);
    }

    setMinutes((minutes: number) => (minutes + 1) % 60);
  };

  const decreaseMinutes = () => {
    if (!props.selectedDate) return;

    if (minutes === 0) {
      return setMinutes(59);
    }

    setMinutes((minutes: number) => minutes - 1);
  };

  const increaseSeconds = () => {
    if (!props.selectedDate) return;

    if (seconds === 59) {
      return setSeconds(0);
    }

    setSeconds((seconds: number) => (seconds + 1) % 60);
  };

  const decreaseSeconds = () => {
    if (!props.selectedDate) return;

    if (seconds === 0) {
      return setSeconds(59);
    }

    setSeconds((minutes: number) => minutes - 1);
  };

  const formatNumberTo2Digit = (number: number): string =>
    number.toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    });

  const checkIsStringContainsOnlyDigits = (candidate: string): boolean => {
    const validationPattern = /^\d+$/;

    return validationPattern.test(candidate);
  };

  return (
    <div className="date_time_picker_time_selector">
      <FontAwesomeIcon
        size="lg"
        icon={faClock}
        className="date_time_picker_time_selector__icon"
      />

      <div className="date_time_picker_time_selector__section">
        <ButtonComponent
          onClick={increaseHours}
          isDisabled={!isHoursEditingEnabled}
          classNames={{
            root: "date_time_picker_time_selector__action_button",
          }}
        >
          <FontAwesomeIcon size="sm" icon={faChevronUp} />
        </ButtonComponent>
        <InputComponent
          value={formatNumberTo2Digit(hours)}
          placeholder="Hours"
          onChange={(value) => onInputValueChange(value, 23, setHours)}
          isDisabled={!isHoursEditingEnabled}
        />
        <ButtonComponent
          onClick={decreaseHours}
          isDisabled={!isHoursEditingEnabled}
          classNames={{
            root: "date_time_picker_time_selector__action_button",
          }}
        >
          <FontAwesomeIcon size="sm" icon={faChevronDown} />
        </ButtonComponent>
      </div>
      <div className="date_time_picker_time_selector__section_separator">:</div>
      <div className="date_time_picker_time_selector__section">
        <ButtonComponent
          onClick={increaseMinutes}
          isDisabled={!isMinutesEditingEnabled}
          classNames={{
            root: "date_time_picker_time_selector__action_button",
          }}
        >
          <FontAwesomeIcon size="sm" icon={faChevronUp} />
        </ButtonComponent>
        <InputComponent
          value={formatNumberTo2Digit(minutes)}
          placeholder="Minutes"
          onChange={(value) => {
            onInputValueChange(value, 59, setMinutes);
          }}
          isDisabled={!isMinutesEditingEnabled}
        />
        <ButtonComponent
          onClick={decreaseMinutes}
          isDisabled={!isMinutesEditingEnabled}
          classNames={{
            root: "date_time_picker_time_selector__action_button",
          }}
        >
          <FontAwesomeIcon size="sm" icon={faChevronDown} />
        </ButtonComponent>
      </div>
      <div className="date_time_picker_time_selector__section_separator">:</div>
      <div className="date_time_picker_time_selector__section">
        <ButtonComponent
          onClick={increaseSeconds}
          isDisabled={!isSecondsEditingEnabled}
          classNames={{
            root: "date_time_picker_time_selector__action_button",
          }}
        >
          <FontAwesomeIcon size="sm" icon={faChevronUp} />
        </ButtonComponent>
        <InputComponent
          value={formatNumberTo2Digit(seconds)}
          placeholder="Seconds"
          onChange={(value) => onInputValueChange(value, 59, setSeconds)}
          isDisabled={!isSecondsEditingEnabled}
        />
        <ButtonComponent
          onClick={decreaseSeconds}
          isDisabled={!isHoursEditingEnabled}
          classNames={{
            root: "date_time_picker_time_selector__action_button",
          }}
        >
          <FontAwesomeIcon size="sm" icon={faChevronDown} />
        </ButtonComponent>
      </div>
    </div>
  );
};

export default DateTimePickerClockComponent;
