enum DriverContractListingByRailySortKey {
  BILLING_MODEL_ASC = "BILLING_MODEL_ASC",
  BILLING_MODEL_DESC = "BILLING_MODEL_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  FREE_STOPPING_PERIOD_ASC = "FREE_STOPPING_PERIOD_ASC",
  FREE_STOPPING_PERIOD_DESC = "FREE_STOPPING_PERIOD_DESC",
  STOPPING_RATE_ASC = "STOPPING_RATE_ASC",
  STOPPING_RATE_DESC = "STOPPING_RATE_DESC",
  PERIOD_OF_VALIDITY_FROM_ASC = "PERIOD_OF_VALIDITY_FROM_ASC",
  PERIOD_OF_VALIDITY_FROM_DESC = "PERIOD_OF_VALIDITY_FROM_DESC",
  PERIOD_OF_VALIDITY_TO_ASC = "PERIOD_OF_VALIDITY_TO_ASC",
  PERIOD_OF_VALIDITY_TO_DESC = "PERIOD_OF_VALIDITY_TO_DESC",
  TAXI_CORPORATION_ASC = "TAXI_CORPORATION_ASC",
  TAXI_CORPORATION_DESC = "TAXI_CORPORATION_DESC",
}

export default DriverContractListingByRailySortKey;
