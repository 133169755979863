import useRouteQueryParams from "../../../../../../common/hooks/use-route-query-params";
import { CargoCompanyListingRouteQueryParams } from "../../../../common/routes/types/cargo-company-listing-route-params";
import CargoCompanyListingFilter from "../common/types/cargo-company-listing-filter";
import CargoCompanyListingSortKey from "../common/types/cargo-company-listing-sort-key";
import cargoCompanyListingFiltersService from "./cargo-company-listing-filter.service";

const useCargoCompanyListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<CargoCompanyListingRouteQueryParams>();

  const getFilters = (): CargoCompanyListingFilter[] => {
    return cargoCompanyListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return cargoCompanyListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return cargoCompanyListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): CargoCompanyListingSortKey | undefined => {
    return cargoCompanyListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: CargoCompanyListingFilter[],
    sortKey: CargoCompanyListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      cargoCompanyListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useCargoCompanyListingFiltersDao;
