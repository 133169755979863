import { FC } from "react";
import TableComponent from "../../../../../common/components/table/table.component";
import relatedBillingsCargoTaxiHelper from "./related-billings-cargo-taxi.helper";
import RelatedBillingsCargoTaxiTableRow from "./types/related-billings-cargo-taxi-table-row";

type RelatedBillingsCargoTaxiTableProps = {
  tableRows: RelatedBillingsCargoTaxiTableRow[];
  isLoading: boolean;
};

const RelatedBillingsCargoTaxiTableComponent: FC<
  RelatedBillingsCargoTaxiTableProps
> = (props) => {
  const tableColumns = relatedBillingsCargoTaxiHelper.createTableColumns();

  return (
    <TableComponent
      isLoading={props.isLoading}
      columns={tableColumns}
      rows={props.tableRows}
    />
  );
};

export default RelatedBillingsCargoTaxiTableComponent;
