import { FC, useState, useEffect } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import OrderDetailsSelectTaxiContractsComponent from "../common/select/order-details-select-taxi-contracts.component";
import orderDetailsForwardingApiService from "../api/forwarding/order-details-forwarding.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import useOrderDetialsForwardingForm from "../hooks/use-order-details-forwarding-form";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import useOrderDetialsForwarding from "../use-order-details-forwarding";
import OrderDetailsTaxiCorporationSelectComponent from "./types/order-details-taxi-corporation-select-component";

type OrderDetailsEditForwardingModalComponentProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  transportingOrderId: string | undefined;
  sumbitDisabledText: string;
};

const OrderDetailsEditForwardingModalComponent: FC<
  OrderDetailsEditForwardingModalComponentProps
> = ({ isOpen, transportingOrderId, sumbitDisabledText, onClose }) => {
  const [contractId, setContractId] = useState("");
  const [selectedTaxiContract, setSelectedTaxiContract] =
    useState<OrderDetailsTaxiCorporationSelectComponent | null>(null);

  useEffect(() => {
    getForwardings();
  }, []);

  const { formValidationResults, setTaxiContract, checkIsFormValid } =
    useOrderDetialsForwardingForm({ onlyContract: true });

  const translations =
    orderTranslationsHelper.getDetailsTranslations().editForwarding;
  const {
    form,
    headingText,
    successEditForwardingNotificationText,
    failureEditForwardingNotificationText,
  } = translations;
  const {
    submitButtonTitle,
    submitButtonText,
    rejectButtonTitle,
    rejectButtonText,
    taxiContractLabel,
  } = form;

  const { producerTaxiId, forwardingId, consumerTaxiId, getForwardings } =
    useOrderDetialsForwarding({
      transportingOrderId,
    });

  const isEditEnable = !consumerTaxiId || !forwardingId;

  const sumbitText = !consumerTaxiId ? sumbitDisabledText : submitButtonTitle;

  const handleOnChange = (value: SelectOption) => {
    setSelectedTaxiContract(value);
    setContractId(value.value.id);
    getForwardings();
    setTaxiContract(value);
  };

  const handleEditForwardingSumbit = (response: any) => {
    if (response?.status === 200) {
      notificationService.success(successEditForwardingNotificationText);
      onClose();
      return;
    }
    notificationService.error(failureEditForwardingNotificationText);
    onClose();
  };

  const handleOnSubmit = () => {
    const isFormValid = checkIsFormValid();
    if (!isFormValid) return;

    orderDetailsForwardingApiService
      .fetchEditForwarding(forwardingId!, {
        contract_id: contractId,
      })
      .then(handleEditForwardingSumbit);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      classNames={{
        content: `modal_content`,
      }}
      header={{
        title: headingText,
      }}
      actions={[
        <ButtonComponent
          onClick={handleOnSubmit}
          type="primary"
          title={sumbitText}
          isDisabled={isEditEnable}
        >
          {submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={onClose}
          title={rejectButtonTitle}
        >
          {rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <FormFieldComponent
        label={taxiContractLabel}
        classNames={{ root: "mt-0" }}
        isRequired
        errorMessage={formValidationResults.taxiContract.errorMessage}
      >
        <OrderDetailsSelectTaxiContractsComponent
          onChange={handleOnChange}
          taxiCorporationUuid={consumerTaxiId}
          selectedTaxiContract={selectedTaxiContract}
          isEditEnable={isEditEnable}
          producerTaxiId={producerTaxiId}
        />
      </FormFieldComponent>
    </ModalComponent>
  );
};

export default OrderDetailsEditForwardingModalComponent;
