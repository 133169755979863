import { faIcons } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import delegationRoutesHelper from "../routes/delegation-routes.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";

const getAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: delegationRoutesHelper.getAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDelegationAdd,
    },
  ];
};
const getListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: delegationRoutesHelper.getListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDelegationListing,
    },
  ];
};

const delegationBreadcrumbsHelper = {
  getAddBreadcrumbs,
  getListingBreadcrumbs,
};

export default delegationBreadcrumbsHelper;
