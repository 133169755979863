import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import CardButtonComponent, { CardButtonProps } from "../card-button.component";

type CardEditButtonProps = Pick<
  CardButtonProps,
  "isDisabled" | "isLoading" | "onClick" | "title"
>;

const CardEditButtonComponent: FC<CardEditButtonProps> = (props) => {
  return <CardButtonComponent icon={faEdit} {...props} />;
};

CardEditButtonComponent.defaultProps = {
  title: "Edit",
};

export default CardEditButtonComponent;
