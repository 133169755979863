import { FC } from "react";
import { OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent } from "../../../common/types/order-details-handling-log-entry-event";
import orderDetailsHistoryEntryAssignmentOrderNewPlanContentHelper from "./order-details-history-entry-assignment-order-new-plan-content.helper";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";

type OrderDetailsHistoryEntryAssignmentOrderNewPlanContentProps = {
  event: OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent;
};

const OrderDetailsHistoryEntryAssignmentOrderNewPlanContentComponent: FC<
  OrderDetailsHistoryEntryAssignmentOrderNewPlanContentProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .assignmentOrderNewPlan;

  const taxiCorporationLabel =
    orderDetailsHistoryEntryAssignmentOrderNewPlanContentHelper.getTaxiCorporationName(
      props.event.taxiCorporationName
    );

  const driverName =
    orderDetailsHistoryEntryAssignmentOrderNewPlanContentHelper.getDriverName(
      props.event.driverName
    );

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={taxiCorporationLabel}
      >
        {taxiCorporationLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={driverName}
      >
        {driverName}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryAssignmentOrderNewPlanContentComponent;
