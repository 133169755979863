import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import OrderDetailsChangeTaxiRequest from "./order-details-change-taxi.request";
import OrderDetailsChangeTaxiTaxiContractsResponse from "./order-details-change-taxi-taxi-contracts.response";
import OrderDetailsChangeTaxiResponse from "./order-details-change-taxi.response";

const fetchTaxiContracts = (
  cargoCompanyUuid: string
): Promise<OrderDetailsChangeTaxiTaxiContractsResponse> => {
  const path = `/contracts/cargo-companies/${cargoCompanyUuid}/valid-collection`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const updateTaxiCorporation = (
  orderUuid: string,
  request: OrderDetailsChangeTaxiRequest
): Promise<OrderDetailsChangeTaxiResponse> => {
  const path = `/orders/${orderUuid}/change-target-taxi`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(request),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const orderDetailsChangeTaxiApiService = {
  fetchTaxiContracts,
  updateTaxiCorporation,
};

export default orderDetailsChangeTaxiApiService;
