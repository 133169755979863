import Joi, { AnySchema } from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import OrderDetailsManageDriverAssignmentFormData from "./types/order-details-manage-driver-assignment-form-data";

const validateDriver = (
  driver: OrderDetailsManageDriverAssignmentFormData["driver"]
) => {
  const validationSchema = Joi.object().required();

  return formValidationService.validate(driver, validationSchema);
};

const validateAssignmentOption = (
  assignmentOption: OrderDetailsManageDriverAssignmentFormData["assignmentOption"]
) => {
  const validationSchema = Joi.object().required();

  return formValidationService.validate(assignmentOption, validationSchema);
};

const validateTaxiCorporation = (
  taxiCorporation: OrderDetailsManageDriverAssignmentFormData["taxiCorporation"],
  assignmentOption: OrderDetailsManageDriverAssignmentFormData["assignmentOption"]
) => {
  const validationSchema = getTaxiCorporationValidationSchema(assignmentOption);

  return formValidationService.validate(taxiCorporation, validationSchema);
};

const validateAcceptImmediately = (
  acceptImmediately: OrderDetailsManageDriverAssignmentFormData["acceptImmediately"]
) => {
  const validationSchema = Joi.boolean();

  return formValidationService.validate(acceptImmediately, validationSchema);
};

const getTaxiCorporationValidationSchema = (
  assignmentOption: OrderDetailsManageDriverAssignmentFormData["assignmentOption"]
): AnySchema => {
  if (assignmentOption?.value === null) {
    return Joi.object().required();
  }

  return Joi.object().valid(null);
};

const orderDetailsManageDriverAssignmentFormValidationService = {
  validateDriver,
  validateAssignmentOption,
  validateTaxiCorporation,
  validateAcceptImmediately,
};

export default orderDetailsManageDriverAssignmentFormValidationService;
