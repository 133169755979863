import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import DriverContractListingByRailySortKey from "../types/driver-contract-listing-by-raily-sort-key";
import DriverContractListingByRailySortSelectOption from "../types/driver-contract-listing-by-raily-sort-select-option";

const getSelectOptions = (): DriverContractListingByRailySortSelectOption[] => {
  const translations =
    userTranslationsHelper.getDriverContractListingTranslations().sort;

  return [
    {
      label: translations.billingModelAscOptionLabel,
      value: DriverContractListingByRailySortKey.BILLING_MODEL_ASC,
    },
    {
      label: translations.billingModelDescOptionLabel,
      value: DriverContractListingByRailySortKey.BILLING_MODEL_DESC,
    },
    {
      label: translations.distanceRateAscOptionLabel,
      value: DriverContractListingByRailySortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.distanceRateDescOptionLabel,
      value: DriverContractListingByRailySortKey.DISTANCE_RATE_DESC,
    },
    {
      label: translations.freeStoppingPeriodAscOptionLabel,
      value: DriverContractListingByRailySortKey.FREE_STOPPING_PERIOD_ASC,
    },
    {
      label: translations.freeStoppingPeriodDescOptionLabel,
      value: DriverContractListingByRailySortKey.FREE_STOPPING_PERIOD_DESC,
    },
    {
      label: translations.stopRateAscOptionLabel,
      value: DriverContractListingByRailySortKey.STOPPING_RATE_ASC,
    },
    {
      label: translations.stopRateDescOptionLabel,
      value: DriverContractListingByRailySortKey.STOPPING_RATE_DESC,
    },
    {
      label: translations.periodOfValidityFromAscOptionLabel,
      value: DriverContractListingByRailySortKey.PERIOD_OF_VALIDITY_FROM_ASC,
    },
    {
      label: translations.periodOfValidityFromDescOptionLabel,
      value: DriverContractListingByRailySortKey.PERIOD_OF_VALIDITY_FROM_DESC,
    },
    {
      label: translations.periodOfValidityToAscOptionLabel,
      value: DriverContractListingByRailySortKey.PERIOD_OF_VALIDITY_TO_ASC,
    },
    {
      label: translations.periodOfValidityToDescOptionLabel,
      value: DriverContractListingByRailySortKey.PERIOD_OF_VALIDITY_TO_DESC,
    },
    {
      label: translations.taxiCorporationAscOptionLabel,
      value: DriverContractListingByRailySortKey.TAXI_CORPORATION_ASC,
    },
    {
      label: translations.taxiCorporationDescOptionLabel,
      value: DriverContractListingByRailySortKey.TAXI_CORPORATION_DESC,
    },
  ];
};

const driverContractListingByRailySortHelper = {
  getSelectOptions,
};

export default driverContractListingByRailySortHelper;
