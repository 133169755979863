import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import MileageDictionaryListingTableColumn from "./types/mileage-dictionary-listing-table-column";

const getColumns = (): MileageDictionaryListingTableColumn[] => {
  const translations =
    mileageTranslationsHelper.getMileageDictionaryTranslations().table.headers;

  const tableColumns: MileageDictionaryListingTableColumn[] = [
    {
      header: translations.dictionaryNameLabel,
      title: translations.dictionaryNameTitle,
      accessor: "name",
      colSpan: 8,
    },
    {
      header: translations.activityStatusLabel,
      title: translations.activityStatusTitle,
      accessor: "active",
      colSpan: 4,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 2,
    },
  ];

  return tableColumns;
};

const mileageDictionaryListingTableHelper = {
  getColumns,
};

export default mileageDictionaryListingTableHelper;
