import CargoOfficerAddRequest, {
  CargoOfficerAddRequestBody,
} from "./cargo-officer-add.request";

const createBody = (
  data: CargoOfficerAddRequest
): CargoOfficerAddRequestBody => {
  return {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    mobilePrimary: data.mobilePrimary,
    mobilePrimaryPrefix: data.mobilePrimaryPrefix,
    mobileSecondary: data.mobileSecondary,
    mobileSecondaryPrefix: data.mobileSecondaryPrefix,
    password: data.password,
    username: data.username,
    cargoOfficer: {
      cargoCompanyId: data.cargoCompanyId,
    },
  };
};

const cargoOfficerAddRequestFactory = {
  createBody,
};

export default cargoOfficerAddRequestFactory;
