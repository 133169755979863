import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useAppContext } from "../../../../../context/app.context";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import searchAddressApiService from "../../../../../common/utils/search-address/search-address.service";
import SearchAddressResponse from "../../../../../common/utils/search-address/search-address.response";
import ModalComponent from "../../../../../common/components/modal/modal.component";
import DriverAddressFormSearchSelectOption from "./types/driver-address-form-search-select-option";
import DriverAddressFormTypeSelectOption from "./types/driver-address-form-type-select-option";
import ButtonComponent from "../../../../../common/components/button/button.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import MapAddressSelectorComponent from "../../../../../common/components/map-address-selector/map-address-selector-component";
import useForm from "../../../../../common/components/form/use-form";
import driverAddressFormHelper from "./driver-address-form.helper";
import driverAddressFormFactory from "./driver-address-form.factory";
import DriverAddressFormData from "./types/driver-address-form-data";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import DriverAddressFormAddressSelected from "./types/driver-address-form-address-selected";
import DriverAddressFormAddress from "./types/driver-address-form-address";

type DriverAddressFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddNewAddress: (address: DriverAddressFormAddress) => void;
};

const DriverAddressFormComponent: FC<DriverAddressFormProps> = (props) => {
  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [isAddressFetching, setIsAddressFetching] = useState(false);
  const [addressSelectOptions, setAddressSelectOptions] = useState<
    DriverAddressFormSearchSelectOption[]
  >([]);

  const { selectedAppLanguage } = useAppContext();

  const addressTypeSelectOptions: DriverAddressFormTypeSelectOption[] = useMemo(
    () => driverAddressFormHelper.getAddressTypeSelectOptions(),
    [selectedAppLanguage]
  );

  const form = useForm<DriverAddressFormData>({
    emptyValues: driverAddressFormHelper.getDefaultFormData(),
    validationDefinition: driverAddressFormHelper.getValidationDefinition(),
  });

  const isAddressFormFieldEnabled = !!form.values.foundAddress;

  const translation =
    appTranslationsHelper.getDriverSignUpTranslations().userDataForm
      .addNewAddressForm;

  useEffect(() => {
    if (!addressSearchQuery) return;

    getDebounceAddressSelectOptions(addressSearchQuery);
  }, [addressSearchQuery]);

  useEffect(() => {
    if (props.isOpen) return;

    setAddressSearchQuery("");
    form.restore();
  }, [props.isOpen]);

  const getDebounceAddressSelectOptions = useCallback(
    debounce((addressSearchQuery: string) => {
      getAddressSelectOptions(addressSearchQuery);
    }, 300),
    []
  );

  useEffect(() => {
    form.setValue("type", null);
  }, [selectedAppLanguage]);

  const fetchResolvedAddress = (
    addressSearchQuery: string
  ): Promise<SearchAddressResponse> =>
    searchAddressApiService.searchByQuery(addressSearchQuery);

  const getAddressSelectOptions = (addressSearchQuery: string) => {
    setIsAddressFetching(true);

    fetchResolvedAddress(addressSearchQuery)
      .then((response) => {
        const selectOptions =
          driverAddressFormFactory.createAddressSelectOptions(response);

        setAddressSelectOptions(selectOptions);
      })
      .finally(() => {
        setIsAddressFetching(false);
      });
  };

  const addNewAddress = () => {
    if (!form.values.type) return;

    const newAddress = driverAddressFormFactory.createAddress(form.values);

    props.onAddNewAddress(newAddress);
  };

  const acceptSelectedAddress = (value: DriverAddressFormAddressSelected) => {
    const foundAddress = {
      label: value.name,
      value: value,
    };

    const address = {
      foundAddress: foundAddress,
      type: null,
      street: value.street ?? "",
      houseNumber: value.houseNumber ?? "",
      apartmentNumber: value.apartmentNumber ?? "",
      zipCode: value.zipCode ?? "",
      country: value.country ?? "",
      town: value.town ?? "",
      description: "",
    };

    form.setValues(address);
  };

  const onAddressSelectChange = (value: SelectOption | null) => {
    if (!value) {
      form.setValues(driverAddressFormHelper.getDefaultFormData());
      return;
    }

    form.setValues(value.value);
    form.setValue("foundAddress", value);
  };

  const checkIsFormModified = (): boolean =>
    !!(
      addressSearchQuery !== "" ||
      form.values.street !== "" ||
      form.values.houseNumber !== "" ||
      form.values.apartmentNumber !== "" ||
      form.values.zipCode !== "" ||
      form.values.country !== "" ||
      form.values.town !== "" ||
      form.values.description !== ""
    );

  const onSubmitButtonClick = async () => {
    const isFormValid: boolean = await form.validateAll();
    if (!isFormValid) return;

    props.onClose();
    addNewAddress();
  };

  return (
    <ModalComponent
      header={{ title: translation.title }}
      classNames={{ root: "driver_address_form" }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          title={translation.submitButtonTitle}
          idForTesting="user-data-add-new-address-submit-button"
        >
          {translation.submitButtonText}
        </ButtonComponent>,
      ]}
      closeConfirmation={{
        title: translation.closeConfirmation.title,
        messageText: translation.closeConfirmation.textMessage,
        shouldBeVisibleOnCloseClick: checkIsFormModified(),
      }}
    >
      <div style={{ fontSize: 14, padding: `0 0 20px 0` }}>
        {translation.tipText}
      </div>
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={translation.searchLabel}
            classNames={{ root: "mt-0" }}
          >
            <SingleSelectComponent
              placeholder={translation.searchPlaceholder}
              options={addressSelectOptions}
              value={form.values.foundAddress}
              isLoading={isAddressFetching}
              inputValue={addressSearchQuery}
              onInputChange={setAddressSearchQuery}
              isClearable
              onChange={onAddressSelectChange}
              isSearchable
              menuPlacement="bottom"
              filterFunction={() => true}
              idForTesting="user-data-add-new-address-search-select"
              menuPosition="fixed"
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={translation.addressTypeLabel}
            isRequired
            errorMessage={form.validationResults.type.errorMessage}
          >
            <SingleSelectComponent
              placeholder={translation.addressTypePlaceholder}
              options={addressTypeSelectOptions}
              value={form.values.type}
              onChange={(value) => {
                form.setValue("type", value);
              }}
              menuPlacement="bottom"
              isSearchable
              hasError={!!form.validationResults.type.errorMessage}
              idForTesting="user-data-add-new-address-address-type-select"
              menuPosition="fixed"
            />
          </FormFieldComponent>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translation.streetLabel}
                isRequired
                errorMessage={form.validationResults.street.errorMessage}
              >
                <InputComponent
                  value={form.values.street}
                  onChange={(value) => {
                    form.setValue("street", value);
                  }}
                  placeholder={translation.streetPlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={!!form.validationResults.street.errorMessage}
                  onBlur={() => form.validate("street")}
                  idForTesting="user-data-add-new-address-street-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={3}>
              <FormFieldComponent
                label={translation.houseNumberLabel}
                isRequired
                errorMessage={form.validationResults.houseNumber.errorMessage}
              >
                <InputComponent
                  value={form.values.houseNumber}
                  onChange={(value) => {
                    form.setValue("houseNumber", value);
                  }}
                  placeholder={translation.houseNumberPlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={!!form.validationResults.houseNumber.errorMessage}
                  onBlur={() => form.validate("houseNumber")}
                  idForTesting="user-data-add-new-address-house-number-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={3}>
              <FormFieldComponent
                label={translation.apartmentNumberLabel}
                errorMessage={
                  form.validationResults.apartmentNumber.errorMessage
                }
              >
                <InputComponent
                  value={form.values.apartmentNumber}
                  onChange={(value) => {
                    form.setValue("apartmentNumber", value);
                  }}
                  placeholder={translation.apartmentNumberPlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={
                    !!form.validationResults.apartmentNumber.errorMessage
                  }
                  onBlur={() => form.validate("apartmentNumber")}
                  idForTesting="user-data-add-new-address-apartment-number-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translation.townLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={form.validationResults.town.errorMessage}
              >
                <InputComponent
                  value={form.values.town}
                  onChange={(value) => {
                    form.setValue("town", value);
                  }}
                  placeholder={translation.townPlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={!!form.validationResults.town.errorMessage}
                  onBlur={() => form.validate("town")}
                  idForTesting="user-data-add-new-address-town-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={6}>
              <FormFieldComponent
                label={translation.zipCodeLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={form.validationResults.zipCode.errorMessage}
              >
                <InputComponent
                  value={form.values.zipCode}
                  onChange={(value) => {
                    form.setValue("zipCode", value);
                  }}
                  placeholder={translation.zipCodePlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={!!form.validationResults.zipCode.errorMessage}
                  onBlur={() => form.validate("zipCode")}
                  idForTesting="user-data-add-new-address-zip-code-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <FormFieldComponent
            label={translation.countryLabel}
            classNames={{ root: "mt-0" }}
            isRequired
            errorMessage={form.validationResults.country.errorMessage}
          >
            <InputComponent
              value={form.values.country}
              onChange={(value) => {
                form.setValue("country", value);
              }}
              placeholder={translation.countryPlaceholder}
              isDisabled={!isAddressFormFieldEnabled}
              hasError={!!form.validationResults.country.errorMessage}
              onBlur={() => form.validate("country")}
              idForTesting="user-data-add-new-address-country-input"
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={translation.descriptionLabel}
            classNames={{ root: "mt-0" }}
            errorMessage={form.validationResults.description.errorMessage}
          >
            <InputComponent
              value={form.values.description}
              onChange={(value) => {
                form.setValue("description", value);
              }}
              placeholder={translation.descriptionPlaceholder}
              isDisabled={!isAddressFormFieldEnabled}
              hasError={!!form.validationResults.description.errorMessage}
              onBlur={() => form.validate("description")}
              idForTesting="user-data-add-new-address-description-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={6}>
          <FormFieldComponent
            label={translation.mapLabel}
            classNames={{
              root: "mt-0 driver_address_form_field",
            }}
          >
            <div className="driver_address_form_map">
              <MapAddressSelectorComponent
                address={form.values.foundAddress?.value}
                onAddressChange={acceptSelectedAddress}
              />
            </div>
          </FormFieldComponent>
        </Column>
      </Row>
    </ModalComponent>
  );
};

export default DriverAddressFormComponent;
