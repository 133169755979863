import useAsyncData from "../../../hooks/use-async-data";
import dispatcherService from "../dispatcher.service";
import DispatcherList from "./dispatcher-list";
import DispatcherListError from "./dispatcher-list-error";
import DispatcherListLoadParams from "./dispatcher-list-load-params";

const useDispatcherList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<DispatcherList>({ data: [], totalCount: 0 });

  const load = async (
    params: DispatcherListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await dispatcherService.getList(params, signal);

      setData(data);
      setIsLoading(false);
    } catch (_error) {
      const error = _error as DispatcherListError;

      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useDispatcherList;
