import appConfig from "../../../../../app.config";
import authService from "../../../../auth/common/auth.service";
import OrderRouteEditPassengersResponse from "./order-route-edit-passengers.response";
import OrderRouteEditTaxiContractsResponse from "./order-route-edit-taxi-contracts.response";
import searchRoadRoutingService from "../../../../../common/utils/search-road-route/search-road-routing.service";
import OrderRouteEditRouteRequest from "./order-route-edit-route.request";
import OrderRouteEditRouteResponse from "./order-route-edit-route.response";
import OrderRouteEditRequestBody from "./order-route-edit.request";
import urlService from "../../../../../common/utils/url/url.service";
import OrderRouteEditSolvedRidesRequest from "./order-route-edit-solved-rides.request";
import OrderRouteEditSolvedRidesResponse from "./order-route-edit-solved-rides.response";
import OrderRouteEditResponse, {
  OrderRouteEditErrorType,
  OrderRouteEditNotAcceptableResponseError,
  OrderRouteEditUnprocessableEntityErrorType,
  OrderRouteEditUnprocessableEntityTimeError,
} from "./order-route-edit.response";
import OrderRouteEditPassengersRequest from "./order-route-edit-passengers.request";
import OrderRouteEditCargoAddressesRequest from "./order-route-edit-cargo-addresses-request";
import OrderRouteEditCargoAddressesResponse from "./order-route-edit-cargo-addresses.response";
import OrderRouteEditOrderDetailsResponse from "./order-route-edit-order-details.response";

import orderTranslationsHelper from "../../../../../languages/order-translations.helper";

const fetchTaxiContracts = (
  dispatcherId: string
): Promise<OrderRouteEditTaxiContractsResponse> => {
  const path = `/contracts/dispatchers/${dispatcherId}/valid-collection`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchPassengers = (
  contractorCompanyUuid: string,
  request: OrderRouteEditPassengersRequest
): Promise<OrderRouteEditPassengersResponse> => {
  const path = `/cargo-companies/${contractorCompanyUuid}/passengers`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchCargoAddresses = (
  companyUuid: string,
  request: OrderRouteEditCargoAddressesRequest
): Promise<OrderRouteEditCargoAddressesResponse> => {
  const path = `/cargo-companies/${companyUuid}/addresses`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchRoute = (
  request: OrderRouteEditRouteRequest
): Promise<OrderRouteEditRouteResponse> => {
  return searchRoadRoutingService.search(request);
};

const editOrder = (
  orderId: string,
  requestBody: OrderRouteEditRequestBody
): Promise<OrderRouteEditResponse> => {
  const path = `/orders/${orderId}/log-debug-echo`;

  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(requestBody),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchSolvedRides = (
  request: OrderRouteEditSolvedRidesRequest
): Promise<OrderRouteEditSolvedRidesResponse> => {
  const path = `/rides/solved`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(request),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchOrderDetails = (
  orderId: string
): Promise<OrderRouteEditOrderDetailsResponse> => {
  const path = `/orders/cargo-orders/${orderId}`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const resolveApiErrorLabel = (
  error: OrderRouteEditNotAcceptableResponseError
): string => {
  const errorType = error.error_type;

  const translations =
    orderTranslationsHelper.getEditTranslations().validation.errors.api;

  switch (errorType) {
    case OrderRouteEditErrorType.PASSENGERS_DO_OVERLAP:
      return translations.passengersDoOverlap;
    case OrderRouteEditErrorType.NODE_ALREADY_CHECKED_OUT:
      return translations.nodeAleadyCheckOut;
    case OrderRouteEditErrorType.RESULT_TIMING_NOT_POSSIBLE:
      return translations.resultTimingNotPossible;
    default:
      return translations.default;
  }
};
const resolveUnprocessableEntityError = (
  error: OrderRouteEditUnprocessableEntityTimeError
): string => {
  const translations = orderTranslationsHelper.getEditTranslations().validation;
  const [item] = error.detail;

  switch (item.error_code) {
    case OrderRouteEditUnprocessableEntityErrorType.TOO_LONG_RIDE:
      return translations.errors.unprocessableEntity.givenTimeError;
    default:
      return translations.defaultError;
  }
};
const orderRouteEditApiService = {
  fetchPassengers,
  fetchCargoAddresses,
  fetchTaxiContracts,
  fetchRoute,
  editOrder,
  fetchSolvedRides,
  fetchOrderDetails,
  resolveApiErrorLabel,
  resolveUnprocessableEntityError,
};

export default orderRouteEditApiService;
