import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { CargoCompanyDispatchListItem } from "../../../../../common/services/cargo-company/dispatch/list/cargo-company-dispatch-list";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";
import dateService from "../../../../../common/utils/date/date.service";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import CargoDispatchListingTableColumn from "./types/cargo-dispatch-listing-table-column";
import CargoDispatchListingTableRow from "./types/cargo-dispatch-listing-table-row";
import cargoRoutesHelper from "../../../common/routes/cargo-routes.helper";

const getTableColumns = (): CargoDispatchListingTableColumn[] => {
  const translations = cargoTranslationsHelper.getDispatchTranslations().table;

  const tableColumns: CargoDispatchListingTableColumn[] = [
    {
      header: translations.creationDateLabel,
      title: translations.creationDateTitle,
      accessor: "creationDate",
      colSpan: 8,
    },
    {
      header: translations.nameLabel,
      title: translations.nameTitle,
      accessor: "name",
      colSpan: 10,
    },
    {
      header: translations.displayNameLabel,
      title: translations.displayNameTitle,
      accessor: "displayName",
      colSpan: 10,
    },
    {
      header: translations.addressLabel,
      title: translations.addressTitle,
      accessor: "address",
      colSpan: 28,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 4,
    },
  ];

  return tableColumns;
};

const getTableRow = (
  item: CargoCompanyDispatchListItem,
  cargoCompanyUuid: string
): CargoDispatchListingTableRow => {
  const translations = cargoTranslationsHelper.getDispatchTranslations().table;

  const creationDate = dateService.formatDateTime(item.creationDate);
  const dispatchUuid = item.uuid;

  return {
    id: item.uuid,
    value: {
      creationDate: (
        <div title={translations.creationDateTitle}>{creationDate}</div>
      ),
      name: <div title={translations.nameLabel}>{item.name}</div>,
      displayName: (
        <div title={translations.displayNameLabel}>{item.displayName}</div>
      ),
      address: <div title={translations.addressLabel}>{item.address}</div>,
      actions: (
        <TableLinkButtonComponent
          title={translations.editButtonTitle}
          icon={faEdit}
          to={cargoRoutesHelper.getDispatchEditRoute({
            cargoCompanyUuid,
            dispatchUuid,
          })}
          idForTesting={`cargo-dispatch-table-item-${item.uuid}-edit-button`}
        />
      ),
    },
  };
};

const cargoDispatchListingHelper = {
  getTableColumns,
  getTableRow,
};

export default cargoDispatchListingHelper;
