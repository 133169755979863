import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../../../context/app.context";
import ButtonComponent from "../../../../../common/components/button/button.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import DriverEditUpdateRequest from "../common/api/driver-edit-update.request";
import DriverEditUpdateResponse from "../common/api/driver-edit-update.response";
import DriverEditAccountDataComponent from "../common/account/driver-edit-account-data.component";
import DriverEditCompanyDataComponent from "../common/company/driver-edit-company-data.component";
import DriverEditVehicleDataComponent from "../common/vehicle/driver-edit-vehicle-data.component";
import driverEditAccountDataFormValidationService from "../common/account/driver-edit-account-data-form-validation.service";
import driverEditCompanyDataFormValidationService from "../common/company/driver-edit-company-data-form-validation.service";
import driverEditByRailyUserDataFormValidationService from "./user-data/driver-edit-by-raily-user-data-form-validation.service";
import driverEditVehicleDataFormValidationService from "../common/vehicle/driver-edit-vehicle-data-form-validation.service";
import driverEditFormHelper from "../common/driver-edit-form.helper";
import driverEditFactory from "../common/driver-edit.factory";
import DriverEditAccountFormData from "../common/types/driver-edit-account-form-data";
import DriverEditAccountFormValidationResults from "../common/types/driver-edit-account-form-validation-results";
import DriverEditCompanyFormData from "../common/types/driver-edit-company-form-data";
import DriverEditCompanyFormValidationResults from "../common/types/driver-edit-company-form-validation-results";
import DriverEditVehicleFormData from "../common/types/driver-edit-vehicle-form-data";
import DriverEditVehicleFormValidationResults from "../common/types/driver-edit-vehicle-form-validation-results";
import DriverEditViewBasicProps from "../common/types/driver-edit-view-basic-props";
import DriverEditByRailyUserDataComponent from "./user-data/driver-edit-by-raily-user-data.component";
import DriverEditByRailyUserFormData from "./common/types/driver-edit-by-raily-user-form-data";
import DriverEditByRailyUserFormValidationResults from "./common/types/driver-edit-by-raily-user-form-validation-results";
import driverEditByRailyFormHelper from "./common/driver-edit-by-raily-form.helper";
import driverEditByRailyApiService from "./common/api/driver-edit-by-raily-api.service";
import UserFormOfEmployment from "../../../../../common/types/user-form-of-employment";
import UserDriverEditRouteParams from "../../../common/routes/types/user-driver-edit-route-params";

type DriverEditByRailyProps = DriverEditViewBasicProps;

const DriverEditByRailyComponent: FC<DriverEditByRailyProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const { driverUuid } = useParams<UserDriverEditRouteParams>();

  const translations = userTranslationsHelper.getDriverEditTranslations();

  const navigate = useNavigate();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [accountFormData, setAccountFormData] =
    useState<DriverEditAccountFormData>(() => props.accountFormData);

  const [accountFormValidationResults, setAccountFormValidationResults] =
    useState<DriverEditAccountFormValidationResults>(() =>
      driverEditFormHelper.getDefaultAccountFormValidationResults()
    );

  const [userFormData, setUserFormData] =
    useState<DriverEditByRailyUserFormData>(() => props.userFormData);

  const [userFormValidationResults, setUserFormValidationResults] =
    useState<DriverEditByRailyUserFormValidationResults>(() =>
      driverEditByRailyFormHelper.getDefaultUserFormValidationResults()
    );

  const [companyFormData, setCompanyFormData] =
    useState<DriverEditCompanyFormData>(() => props.companyFormData);

  const [companyFormValidationResults, setCompanyFormValidationResults] =
    useState<DriverEditCompanyFormValidationResults>(() =>
      driverEditFormHelper.getDefaultCompanyFormValidationResults()
    );

  const [vehicleFormData, setVehicleFormData] =
    useState<DriverEditVehicleFormData>(() => props.vehicleFormData);

  const [vehicleFormValidationResults, setVehicleFormValidationResults] =
    useState<DriverEditVehicleFormValidationResults>(() =>
      driverEditFormHelper.getDefaultVehicleFormValidationResults()
    );

  // temporary solution - remove after refactor
  const revalidateAccountField = async (field: string) => {
    switch (field) {
      case "email":
        return validateAccountDataEmail();
    }
  };

  const revalidateUserField = (field: string) => {
    switch (field) {
      case "firstName":
        return validateUserDataFirstName();
      case "lastName":
        return validateUserDataLastName();
      case "mobile":
        return validateUserDataMobile();
      case "alternativeMobile":
        return validateUserDataAlternativeMobile();
      case "birthDate":
        return validateUserDataBirthDate();
      case "birthPlace":
        return validateUserDataBirthPlace();
      case "personalIdNumber":
        return validateUserDataPersonalIdNumber();
      case "citizenship":
        return validateUserDataCitizenship();
      case "languages":
        return validateUserDataLanguages();
      case "yearsOfExperience":
        return validateUserDataYearsOfExperience();
      case "formOfEmployment":
        return validateUserDataFormOfEmployment();
      case "fleetPartner":
        return validateUserDataFleetPartner();
      case "addresses":
        return validateUserDataAddresses();
    }
  };

  const revalidateVehicleField = (field: string) => {
    switch (field) {
      case "make":
        return validateVehicleDataMake();
      case "model":
        return validateVehicleDataModel();
      case "productionYear":
        return validateVehicleDataProductionYear();
      case "numberOfSeats":
        return validateVehicleDataNumberOfSeats();
      case "registrationNumber":
        return validateVehicleDataRegistrationNumber();
      case "ownership":
        return validateVehicleDataOwnership();
    }
  };

  const revalidateCompanyField = (field: string) => {
    switch (field) {
      case "name":
        return validateCompanyDataCompanyName();
      case "companyId":
        return validateCompanyDataCompanyId();
      case "taxNumber":
        return validateCompanyDataTaxNumber();
      case "nationalCourtRegisterNumber":
        return validateCompanyDataNationalCourtRegisterNumber();
      case "addressCountry":
        return validateCompanyDataAddressCountry();
      case "addressZipCode":
        return validateCompanyDataAddressZipCode();
      case "addressTown":
        return validateCompanyDataAddressTown();
      case "addressStreet":
        return validateCompanyDataAddressStreet();
      case "addressHouseNumber":
        return validateCompanyDataAddressHouseNumber();
      case "addressApartmentNumber":
        return validateCompanyDataAddressApartmentNumber();
      case "addressDescription":
        return validateCompanyDataAddressDescription();
      case "addressMap":
        return validateVehicleDataOwnership();
    }
  };

  useEffect(() => {
    const accountRevalidationFields = Object.keys(
      accountFormValidationResults
    ).filter(
      (formKey) =>
        accountFormValidationResults[
          formKey as keyof DriverEditAccountFormValidationResults
        ].isValid === false
    );
    const userRevalidationFields = Object.keys(
      userFormValidationResults
    ).filter(
      (formKey) =>
        userFormValidationResults[
          formKey as keyof DriverEditByRailyUserFormValidationResults
        ].isValid === false
    );
    const vehicleRevalidationFields = Object.keys(
      vehicleFormValidationResults
    ).filter(
      (formKey) =>
        vehicleFormValidationResults[
          formKey as keyof DriverEditVehicleFormValidationResults
        ].isValid === false
    );

    accountRevalidationFields.forEach(
      async (field) => await revalidateAccountField(field)
    );

    userRevalidationFields.forEach((field) => revalidateUserField(field));

    vehicleRevalidationFields.forEach((field) => revalidateVehicleField(field));

    if (userFormData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
      const companyRevalidationFields = Object.keys(
        companyFormValidationResults
      ).filter(
        (formKey) =>
          companyFormValidationResults[
            formKey as keyof DriverEditCompanyFormValidationResults
          ].isValid === false
      );

      companyRevalidationFields.forEach((field) =>
        revalidateCompanyField(field)
      );
    }
  }, [selectedAppLanguage]);

  const validateAccountDataEmail = () => {
    const validationResult =
      driverEditAccountDataFormValidationService.validateEmail(
        accountFormData.email
      );

    setAccountFormValidationResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsAccountFormValid = () => {
    const isEmailValid = validateAccountDataEmail();

    return isEmailValid;
  };

  const validateUserDataFirstName = () => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateFirstName(
        userFormData.firstName
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataLastName = () => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateLastName(
        userFormData.lastName
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataMobile = () => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateMobile(
        userFormData.mobile
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataAlternativeMobile = () => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateAlternativeMobile(
        userFormData.alternativeMobile
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataBirthDate = (
    value: DriverEditByRailyUserFormData["birthDate"] = userFormData.birthDate
  ) => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateBirthDate(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      birthDate: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataBirthPlace = () => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateBirthPlace(
        userFormData.birthPlace
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      birthPlace: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataPersonalIdNumber = () => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validatePersonalIdNumber(
        userFormData.personalIdNumber
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      personalIdNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataCitizenship = (
    value: DriverEditByRailyUserFormData["citizenship"] = userFormData.citizenship
  ) => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateCitizenship(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      citizenship: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataLanguages = (
    value: DriverEditByRailyUserFormData["languages"] = userFormData.languages
  ) => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateLanguages(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      languages: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataYearsOfExperience = () => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateYearsOfExperience(
        userFormData.yearsOfExperience
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      yearsOfExperience: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataFormOfEmployment = (
    value: DriverEditByRailyUserFormData["formOfEmployment"] = userFormData.formOfEmployment
  ) => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateFormOfEmployment(
        value
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      formOfEmployment: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataFleetPartner = (
    value: DriverEditByRailyUserFormData["fleetPartner"] = userFormData.fleetPartner
  ) => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateFleetPartner(
        value
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      fleetPartner: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataAddresses = (
    value: DriverEditByRailyUserFormData["addresses"] = userFormData.addresses
  ) => {
    const validationResult =
      driverEditByRailyUserDataFormValidationService.validateAddresses(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      addresses: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsUserFormValid = () => {
    const isFirstNameValid = validateUserDataFirstName();
    const isLastNameValid = validateUserDataLastName();
    const isMobileValid = validateUserDataMobile();
    const isAlternativeMobileValid = validateUserDataAlternativeMobile();
    const isBirthDateValid = validateUserDataBirthDate();
    const isBirthPlaceValid = validateUserDataBirthPlace();
    const isPersonalIdNumberValid = validateUserDataPersonalIdNumber();
    const isCitizenshipValid = validateUserDataCitizenship();
    const isLanguagesValid = validateUserDataLanguages();
    const isYearsOfExperienceValid = validateUserDataYearsOfExperience();
    const isFormOfEmploymentValid = validateUserDataFormOfEmployment();
    const isFleetPartnerValid = validateUserDataFleetPartner();
    const isAddressesValid = validateUserDataAddresses();

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isMobileValid &&
      isAlternativeMobileValid &&
      isBirthDateValid &&
      isBirthPlaceValid &&
      isPersonalIdNumberValid &&
      isCitizenshipValid &&
      isLanguagesValid &&
      isYearsOfExperienceValid &&
      isFormOfEmploymentValid &&
      isFleetPartnerValid &&
      isAddressesValid
    );
  };

  const validateCompanyDataCompanyName = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateName(
        companyFormData.name
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      name: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataCompanyId = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateCompanyId(
        companyFormData.companyId
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      companyId: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataTaxNumber = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateTaxNumber(
        companyFormData.taxNumber
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      taxNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataNationalCourtRegisterNumber = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateNationalCourtRegister(
        companyFormData.nationalCourtRegisterNumber
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      nationalCourtRegisterNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressApartmentNumber = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateAddressApartmentNumber(
        companyFormData.address.apartmentNumber
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressApartmentNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressCountry = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateAddressCountry(
        companyFormData.address.country
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressCountry: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressDescription = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateAddressDescription(
        companyFormData.address.description
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressDescription: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressHouseNumber = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateAddressHouseNumber(
        companyFormData.address.houseNumber
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressHouseNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressStreet = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateAddressStreet(
        companyFormData.address.street
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressStreet: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressTown = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateAddressTown(
        companyFormData.address.town
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressTown: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressZipCode = () => {
    const validationResult =
      driverEditCompanyDataFormValidationService.validateAddressZipCode(
        companyFormData.address.zipCode
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressZipCode: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsCompanyDataAddressFormValid = () => {
    const isApartmentNumberValid = validateCompanyDataAddressApartmentNumber();
    const isCountryValid = validateCompanyDataAddressCountry();
    const isDescriptionValid = validateCompanyDataAddressDescription();
    const isHouseNumberNumberValid = validateCompanyDataAddressHouseNumber();
    const isStreetValid = validateCompanyDataAddressStreet();
    const isTownValid = validateCompanyDataAddressTown();
    const isZipCodeValid = validateCompanyDataAddressZipCode();

    return (
      isApartmentNumberValid &&
      isCountryValid &&
      isDescriptionValid &&
      isHouseNumberNumberValid &&
      isStreetValid &&
      isTownValid &&
      isZipCodeValid
    );
  };

  const checkIsCompanyDataFormValid = () => {
    const isCompanyNameValid = validateCompanyDataCompanyName();
    const isCompanyIdValid = validateCompanyDataCompanyId();
    const isTaxNumberValid = validateCompanyDataTaxNumber();
    const isNationalCourtRegisterNumberValid =
      validateCompanyDataNationalCourtRegisterNumber();

    const isAddressValid = checkIsCompanyDataAddressFormValid();

    return (
      isCompanyNameValid &&
      isCompanyIdValid &&
      isTaxNumberValid &&
      isNationalCourtRegisterNumberValid &&
      isAddressValid
    );
  };

  const validateVehicleDataMake = () => {
    const validationResult =
      driverEditVehicleDataFormValidationService.validateMake(
        vehicleFormData.make
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      make: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataModel = () => {
    const validationResult =
      driverEditVehicleDataFormValidationService.validateModel(
        vehicleFormData.model
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      model: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataNumberOfSeats = () => {
    const validationResult =
      driverEditVehicleDataFormValidationService.validateNumberOfSeats(
        vehicleFormData.numberOfSeats
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      numberOfSeats: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataOwnership = (
    value: DriverEditVehicleFormData["ownership"] = vehicleFormData.ownership
  ) => {
    const validationResult =
      driverEditVehicleDataFormValidationService.validateOwnership(value);

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      ownership: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataProductionYear = () => {
    const validationResult =
      driverEditVehicleDataFormValidationService.validateProductionYear(
        vehicleFormData.productionYear
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      productionYear: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataRegistrationNumber = () => {
    const validationResult =
      driverEditVehicleDataFormValidationService.validateRegistrationNumber(
        vehicleFormData.registrationNumber
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      registrationNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsVehicleDataFormValid = () => {
    const isMakeValid = validateVehicleDataMake();
    const isModelValid = validateVehicleDataModel();
    const isNumberOfSeatsValid = validateVehicleDataNumberOfSeats();
    const isOwnershipValid = validateVehicleDataOwnership();
    const isProductionYearValid = validateVehicleDataProductionYear();
    const isRegistrationNumberValid = validateVehicleDataRegistrationNumber();

    return (
      isMakeValid &&
      isModelValid &&
      isNumberOfSeatsValid &&
      isOwnershipValid &&
      isProductionYearValid &&
      isRegistrationNumberValid
    );
  };

  const checkIsFormValidForEmployment = () => {
    const isAccountFormValid = checkIsAccountFormValid();
    const isUserFormValid = checkIsUserFormValid();

    const isVehicleFormValid = checkIsVehicleDataFormValid();

    return isAccountFormValid && isUserFormValid && isVehicleFormValid;
  };

  const checkIsFormValidForB2B = () => {
    const isAccountFormValid = checkIsAccountFormValid();
    const isUserFormValid = checkIsUserFormValid();
    const isCompanyFormValid = checkIsCompanyDataFormValid();
    const isVehicleFormValid = checkIsVehicleDataFormValid();

    return (
      isAccountFormValid &&
      isUserFormValid &&
      isCompanyFormValid &&
      isVehicleFormValid
    );
  };

  const checkIsFormValid = () => {
    if (userFormData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
      return checkIsFormValidForB2B();
    }

    return checkIsFormValidForEmployment();
  };

  const navigateToListing = () => {
    const driverListingRoute = userRoutesHelper.getDriverListingRoute();
    navigate(driverListingRoute);
  };

  const handleDriverEditResponse = (response: DriverEditUpdateResponse) => {
    if (response.status === 200) {
      notificationService.success(translations.successEditNotificationText);
      navigateToListing();
      return;
    }
    notificationService.error(translations.failureEditNotificationText);
  };

  const submitForm = () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid || !driverUuid) return;

    setIsFormSubmitting(true);

    const request: DriverEditUpdateRequest =
      driverEditFactory.createEditDriverRequest(
        accountFormData,
        userFormData,
        companyFormData,
        vehicleFormData,
        props.taxiDriverAssociationUuid
      );

    driverEditByRailyApiService
      .updateDriver(request, driverUuid)
      .then(handleDriverEditResponse)
      .finally(() => setIsFormSubmitting(false));
  };

  const isCargoDataSectionVisible =
    userFormData.formOfEmployment?.value === UserFormOfEmployment.B2B;

  if (!props.driverName) {
    return null;
  }

  return (
    <div className="user_driver_add">
      <HeadingComponent
        title={translations.header.headingText.replace(
          "#{driverName}",
          props.driverName
        )}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column withPaddings>
          <DriverEditAccountDataComponent
            formData={accountFormData}
            formValidationResults={accountFormValidationResults}
            onFormDataChange={setAccountFormData}
            validateEmail={validateAccountDataEmail}
          />
        </Column>
      </Row>
      <Row>
        <Column withPaddings>
          <DriverEditByRailyUserDataComponent
            formData={userFormData}
            fleetPartnerSelectOptions={props.fleetPartnerSelectOptions}
            formValidationResults={userFormValidationResults}
            onFormDataChange={setUserFormData}
            validateFirstName={validateUserDataFirstName}
            validateLastName={validateUserDataLastName}
            validateMobile={validateUserDataMobile}
            validateAlternativeMobile={validateUserDataAlternativeMobile}
            validateBirthDate={validateUserDataBirthDate}
            validateBirthPlace={validateUserDataBirthPlace}
            validatePersonalIdNumber={validateUserDataPersonalIdNumber}
            validateCitizenship={validateUserDataCitizenship}
            validateLanguages={validateUserDataLanguages}
            validateYearsOfExperience={validateUserDataYearsOfExperience}
            validateFormOfEmployment={validateUserDataFormOfEmployment}
            validateFleetPartner={validateUserDataFleetPartner}
            validateAddresses={validateUserDataAddresses}
          />
        </Column>
      </Row>
      {isCargoDataSectionVisible && (
        <Row>
          <Column withPaddings>
            <DriverEditCompanyDataComponent
              formData={companyFormData}
              formValidationResults={companyFormValidationResults}
              onFormDataChange={setCompanyFormData}
              validateCompanyName={validateCompanyDataCompanyName}
              validateCompanyId={validateCompanyDataCompanyId}
              validateTaxNumber={validateCompanyDataTaxNumber}
              validateNationalCourtRegisterNumber={
                validateCompanyDataNationalCourtRegisterNumber
              }
              validateAddressApartmentNumber={
                validateCompanyDataAddressApartmentNumber
              }
              validateAddressCountry={validateCompanyDataAddressCountry}
              validateAddressDescription={validateCompanyDataAddressDescription}
              validateAddressHouseNumber={validateCompanyDataAddressHouseNumber}
              validateAddressTown={validateCompanyDataAddressTown}
              validateAddressStreet={validateCompanyDataAddressStreet}
              validateAddressZipCode={validateCompanyDataAddressZipCode}
            />
          </Column>
        </Row>
      )}
      <Row>
        <Column withPaddings>
          <DriverEditVehicleDataComponent
            formData={vehicleFormData}
            formValidationResults={vehicleFormValidationResults}
            onFormDataChange={setVehicleFormData}
            validateMake={validateVehicleDataMake}
            validateModel={validateVehicleDataModel}
            validateNumberOfSeats={validateVehicleDataNumberOfSeats}
            validateOwnership={validateVehicleDataOwnership}
            validateProductionYear={validateVehicleDataProductionYear}
            validateRegistrationNumber={validateVehicleDataRegistrationNumber}
          />
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isDisabled={isFormSubmitting}
        classNames={{ root: "mt-2" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};

export default DriverEditByRailyComponent;
