import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import { UserPassengerListingRouteQueryParams } from "../../../common/routes/types/user-passenger-listing-route-params";
import PassengerListingFilter from "../common/types/passenger-listing-filter";
import PassengerListingSortKey from "../common/types/passenger-listing-sort-key";
import passengerListingFiltersService from "./passenger-listing-filters.service";

const usePassengerListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<UserPassengerListingRouteQueryParams>();

  const getFilters = (): PassengerListingFilter[] => {
    return passengerListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return passengerListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return passengerListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): PassengerListingSortKey | undefined => {
    return passengerListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: PassengerListingFilter[],
    sortKey: PassengerListingSortKey | null,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      passengerListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default usePassengerListingFiltersDao;
