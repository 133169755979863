import RailyOfficerAddLoadParams from "../../../../../../common/services/raily-officer/add/raily-officer-add-load-params";
import RailyOfficerAddFormData from "./raily-officer-add-form-data";

const create = (
  formData: RailyOfficerAddFormData
): RailyOfficerAddLoadParams => {
  return {
    alternativePhoneNumber: formData.alternativePhoneNumber,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    password: formData.password,
    phoneNumber: formData.phoneNumber,
    username: formData.username,
  };
};

const railyOfficerAddLoadParamsFactory = {
  create,
};

export default railyOfficerAddLoadParamsFactory;
