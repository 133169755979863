import delegationTranslationsHelper from "../../../../../../languages/delegation-translations.helper";

const getContentText = (delegationNumber: string) => {
  const translations =
    delegationTranslationsHelper.getDelegationDeleteTranslations()
      .delegationDelete;

  return translations.contentMessageTemplateLabel.replace(
    "#{delegation_number}",
    String(delegationNumber)
  );
};

const delegationDeleteHelper = {
  getContentText,
};

export default delegationDeleteHelper;
