import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import taxiTaxiContractAddFormValidationService from "../taxi-taxi-contract-add-form-validation.service";
import TaxiTaxiContractAddFormData from "./types/taxi-taxi-contract-add-form-data";

const getEmptyFormData = (): TaxiTaxiContractAddFormData => {
  return {
    billingModel: null,
    distanceRate: null,
    freeStoppingPeriodMinutes: null,
    periodOfValidity: null,
    stoppingRate: null,
    targetTaxiCorporationUuid: "",
    displayName: "",
    isActive: null,
    priority: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<TaxiTaxiContractAddFormData> => {
    return {
      billingModel: (values) =>
        taxiTaxiContractAddFormValidationService.validateBillingModel(
          values.billingModel
        ),
      displayName: (values) =>
        taxiTaxiContractAddFormValidationService.validateDisplayName(
          values.displayName
        ),
      distanceRate: (values) =>
        taxiTaxiContractAddFormValidationService.validateDistanceRate(
          values.distanceRate
        ),
      freeStoppingPeriodMinutes: (values) =>
        taxiTaxiContractAddFormValidationService.validateFreeStoppingPeriodMinutes(
          values.freeStoppingPeriodMinutes
        ),
      isActive: (values) =>
        taxiTaxiContractAddFormValidationService.validateActivity(
          values.isActive
        ),
      periodOfValidity: (values) =>
        taxiTaxiContractAddFormValidationService.validatePeriodOfValidity(
          values.periodOfValidity
        ),
      priority: (values) =>
        taxiTaxiContractAddFormValidationService.validatePriority(
          values.priority
        ),
      stoppingRate: (values) =>
        taxiTaxiContractAddFormValidationService.validateStoppingRate(
          values.stoppingRate
        ),
      targetTaxiCorporationUuid: (values) =>
        taxiTaxiContractAddFormValidationService.validateTargetTaxiCorporation(
          values.targetTaxiCorporationUuid
        ),
    };
  };

const taxiTaxiContractAddFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
};

export default taxiTaxiContractAddFormHelper;
