import TaxiDriverContractAddRequest from "../../api/raily/taxi-driver-contract/add/taxi-driver-contract-add.request";
import TaxiDriverContractDetailsRequest from "../../api/raily/taxi-driver-contract/details/taxi-driver-contract-details.request";
import taxiDriverContractApiService from "../../api/raily/taxi-driver-contract/taxi-driver-contract-api.service";
import TaxiDriverContractUpdateRequest from "../../api/raily/taxi-driver-contract/update/taxi-driver-contract-update.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import TaxiDriverContractAddError from "./add/taxi-driver-contract-add-error";
import TaxiDriverContractAddParams from "./add/taxi-driver-contract-add-params";
import taxiDriverContractAddRequestFactory from "./add/taxi-driver-contract-add-request.factory";
import TaxiDriverContractDetails from "./details/taxi-driver-contract-details";
import TaxiDriverContractDetailsError from "./details/taxi-driver-contract-details-error";
import TaxiDriverContractDetailsLoadParams from "./details/taxi-driver-contract-details-load-params";
import taxiDriverContractDetailsFactory from "./details/taxi-driver-contract-details.factory";
import TaxiDriverContractUpdateError from "./update/taxi-driver-contract-update-error";
import TaxiDriverContractUpdateParams from "./update/taxi-driver-contract-update-params";
import taxiDriverContractUpdateRequestFactory from "./update/taxi-driver-contract-update-request.factory";

const handleAddError = (
  error: HttpError | TaxiDriverContractAddError
): TaxiDriverContractAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: TaxiDriverContractAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TaxiDriverContractAddRequest =
    taxiDriverContractAddRequestFactory.create(params);

  try {
    const response = await taxiDriverContractApiService().add(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TaxiDriverContractAddError;

    throw handleAddError(error);
  }
};

const handleUpdateError = (
  error: HttpError | TaxiDriverContractUpdateError
): TaxiDriverContractUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const update = async (
  params: TaxiDriverContractUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TaxiDriverContractUpdateRequest =
    taxiDriverContractUpdateRequestFactory.create(params);

  try {
    const response = await taxiDriverContractApiService().update(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TaxiDriverContractUpdateError;

    throw handleUpdateError(error);
  }
};

const handleDetailsError = (
  error: HttpError | TaxiDriverContractDetailsError
): TaxiDriverContractDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: TaxiDriverContractDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<TaxiDriverContractDetails> => {
  const request: TaxiDriverContractDetailsRequest = {
    contractId: params.contractUuid,
  };

  try {
    const response = await taxiDriverContractApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiDriverContractDetails =
      taxiDriverContractDetailsFactory.create(response);

    return taxiDriverContractDetails;
  } catch (_error) {
    const error = _error as HttpError | TaxiDriverContractDetailsError;

    throw handleDetailsError(error);
  }
};

const taxiDriverContractService = {
  add,
  update,
  getDetails,
};

export default taxiDriverContractService;
