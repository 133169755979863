import DataLoadError, {
  DataLoadDefaultErrors,
} from "../../../utils/data-load-error/data-load-error";

export enum DriverPlanDeleteOrderErrorType {
  TAXI_DRIVER_ASSOCIATION_NOT_EXISTS = "TAXI_DRIVER_ASSOCIATION_NOT_EXISTS",
  TAXI_DRIVER_CONTRACT_NOT_VALID = "TAXI_DRIVER_CONTRACT_NOT_VALID",
  TAXI_RAILY_CONTRACT_NOT_VALID = "TAXI_RAILY_CONTRACT_NOT_VALID",
  ORDER_NOT_EXISTS = "ORDER_NOT_EXISTS",
  ORDER_IN_PROGRESS = "ORDER_IN_PROGRESS",
}

export type DriverPlanTaxiDriverAssociationNotExistsError = DataLoadError<
  DriverPlanDeleteOrderErrorType.TAXI_DRIVER_ASSOCIATION_NOT_EXISTS,
  {}
>;

export type DriverPlanTaxiDriverContractNotValidError = DataLoadError<
  DriverPlanDeleteOrderErrorType.TAXI_DRIVER_CONTRACT_NOT_VALID,
  {}
>;

export type DriverPlanTaxiRailyContractNotExistsError = DataLoadError<
  DriverPlanDeleteOrderErrorType.TAXI_RAILY_CONTRACT_NOT_VALID,
  {}
>;

export type DriverPlanOrderNotExistsError = DataLoadError<
  DriverPlanDeleteOrderErrorType.ORDER_NOT_EXISTS,
  {}
>;

export type DriverPlanOrderInProgressError = DataLoadError<
  DriverPlanDeleteOrderErrorType.ORDER_IN_PROGRESS,
  {}
>;

type DriverPlanDeleteOrderError =
  | DataLoadDefaultErrors
  | DriverPlanTaxiDriverAssociationNotExistsError
  | DriverPlanTaxiDriverContractNotValidError
  | DriverPlanTaxiRailyContractNotExistsError
  | DriverPlanOrderNotExistsError
  | DriverPlanOrderInProgressError;

export default DriverPlanDeleteOrderError;
