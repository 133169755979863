import TaxiRouteSettlementListingByRailyTaxiCorporationSelectOption from "./types/taxi-route-settlement-listing-by-raily-taxi-corporation-select-option";
import taxiRouteSettlementListingFactory from "../../common/taxi-route-settlement-listing.factory";
import { TaxiRouteSettlementListingByRailyTaxiCorporationsResponseDataItem } from "./api/taxi-route-settlement-listing-by-raily-taxi-corporations.response";

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: TaxiRouteSettlementListingByRailyTaxiCorporationsResponseDataItem
): TaxiRouteSettlementListingByRailyTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      displayName: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: TaxiRouteSettlementListingByRailyTaxiCorporationsResponseDataItem[]
): TaxiRouteSettlementListingByRailyTaxiCorporationSelectOption[] => {
  return responseTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const taxiRouteSettlementListingByRailyFactory = {
  createTaxiCorporationSelectOptions,
  createListingItems: taxiRouteSettlementListingFactory.createListingItems,
  createStatsSummary: taxiRouteSettlementListingFactory.createStatsSummary,
};

export default taxiRouteSettlementListingByRailyFactory;
