import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import DriverEditByRailyComponent from "./by-raily/driver-edit-by-raily.component";
import DriverEditByTaxiComponent from "./by-taxi/driver-edit-by-taxi.component";
import driverEditUserPermissionsHelper from "./common/user-permissions/driver-edit-user-permission.helper";
import DriverEditViewMode from "./common/types/driver-edit-view-mode";
import { useParams } from "react-router-dom";
import UserDriverEditRouteParams from "../../common/routes/types/user-driver-edit-route-params";
import driverEditByRailyFactory from "./by-raily/common/factory/driver-edit-by-raily.factory";
import driverEditApiService from "./common/api/driver-edit-api.service";
import DriverEditInitFormDataRequest from "./common/api/driver-edit-init-form-data.request";
import notificationService from "../../../../common/utils/notification/notification.service";
import DriverEditInitFormDataResponse from "./common/api/driver-edit-init-form-data.response";
import DriverEditAccountFormData from "./common/types/driver-edit-account-form-data";
import driverEditFormHelper from "./common/driver-edit-form.helper";
import DriverEditByRailyUserFormData from "./by-raily/common/types/driver-edit-by-raily-user-form-data";
import driverEditByRailyFormHelper from "./by-raily/common/driver-edit-by-raily-form.helper";
import DriverEditCompanyFormData from "./common/types/driver-edit-company-form-data";
import DriverEditVehicleFormData from "./common/types/driver-edit-vehicle-form-data";
import DriverEditUserDataFleetPartnerSelectOption from "./common/types/driver-edit-user-data-fleet-partner-select-option";
import DriverEditInitFormDataResponseDataItem from "./common/types/driver-edit-init-form-data-item";

type DriverEditProps = {};

const DriverEditComponent: FC<DriverEditProps> = () => {
  const { driverUuid } = useParams<UserDriverEditRouteParams>();
  const { user, selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const [driverName, setDriverName] = useState("");

  useDocumentTitle(
    documentTitleTranslations.userDriverEdit.replace(
      "#{driverName}",
      driverName
    )
  );

  const [isDriverFetching, setIsDriverFetching] = useState(false);

  const [accountFormData, setAccountFormData] =
    useState<DriverEditAccountFormData>(() =>
      driverEditFormHelper.getDefaultAccountFormData()
    );

  const [userFormData, setUserFormData] =
    useState<DriverEditByRailyUserFormData>(() =>
      driverEditByRailyFormHelper.getDefaultUserFormData()
    );

  const [taxiDriverAssociationUuid, setTaxiDriverAssociationUuid] =
    useState("");

  const [companyFormData, setCompanyFormData] =
    useState<DriverEditCompanyFormData>(() =>
      driverEditFormHelper.getDefaultCompanyFormData()
    );

  const [vehicleFormData, setVehicleFormData] =
    useState<DriverEditVehicleFormData>(() =>
      driverEditFormHelper.getDefaultVehicleFormData()
    );

  const [fleetPartnerSelectOptions, setFleetPartnerSelectOptions] = useState<
    DriverEditUserDataFleetPartnerSelectOption[]
  >([]);

  const userPermissions = useMemo(
    () => driverEditUserPermissionsHelper.getPermissions(user?.roles!),
    []
  );

  const translations = useMemo(
    () => userTranslationsHelper.getDriverEditTranslations(),
    [selectedAppLanguage]
  );

  useEffect(() => {
    if (!driverUuid) {
      return;
    }

    const breadcrumbs = userBreadcrumbsHelper.getDriverEditBreadcrumbs({
      driverUuid,
      driverName,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, driverUuid]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<DriverEditViewMode | null>(null);

  useEffect(() => {
    if (!driverUuid) {
      return;
    }

    const breadcrumbs = userBreadcrumbsHelper.getDriverEditBreadcrumbs({
      driverName,
      driverUuid,
    });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, driverUuid, driverName]);

  const onFetchFormInitDataSuccess = (
    responseDriver: DriverEditInitFormDataResponseDataItem
  ) => {
    const userFormData =
      driverEditByRailyFactory.createFormData(responseDriver);
    const accountFormData =
      driverEditByRailyFactory.createAccountFormData(responseDriver);
    const companyFormData =
      driverEditByRailyFactory.createCompanyFormData(responseDriver);
    const vehicleFormData = driverEditByRailyFactory.createVehicleFormData(
      responseDriver.driver
    );
    const fleetPartnerSelectOptions =
      driverEditByRailyFactory.createFleetPartnerSelectOptions(
        responseDriver.associations[0].taxi_corporation.fleet_partners
      );

    const driverName = `${responseDriver.user.first_name} ${responseDriver.user.last_name}`;
    const driverUsername = responseDriver.user.username;

    setUserFormData(userFormData);
    setAccountFormData(accountFormData);
    setCompanyFormData(companyFormData);
    setVehicleFormData(vehicleFormData);
    setTaxiDriverAssociationUuid(responseDriver.associations[0].id);
    setFleetPartnerSelectOptions(fleetPartnerSelectOptions);
    setDriverName(driverName);
  };

  const onFetchFormInitDataFailure = () => {
    notificationService.error(
      translations.failureFetchingDriverNotificationMessageText
    );
  };

  const handleFetchFormInitDatasResponse = (
    response: DriverEditInitFormDataResponse
  ) => {
    if (response.status === 200) {
      onFetchFormInitDataSuccess(response.data);
      return;
    }

    onFetchFormInitDataFailure();
  };

  const fetchFormInitData = () => {
    if (!driverUuid) {
      return;
    }

    setIsDriverFetching(true);

    const request: DriverEditInitFormDataRequest = {
      id: driverUuid,
    };

    driverEditApiService
      .fetchFormInitData(request)
      .then(handleFetchFormInitDatasResponse)
      .catch(onFetchFormInitDataFailure)
      .finally(() => {
        setIsDriverFetching(false);
      });
  };

  useEffect(() => {
    fetchFormInitData();
  }, [driverUuid]);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DriverEditViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DriverEditViewMode.TAXI)}
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: DriverEditViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: DriverEditViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToTaxiButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: DriverEditViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: DriverEditViewMode.RAILY,
        component: (
          <DriverEditByRailyComponent
            changeViewButtons={getViewChangeButtons(DriverEditViewMode.RAILY)}
            driverUuid={driverUuid}
            driverName={driverName}
            userFormData={userFormData}
            accountFormData={accountFormData}
            companyFormData={companyFormData}
            vehicleFormData={vehicleFormData}
            fleetPartnerSelectOptions={fleetPartnerSelectOptions}
            taxiDriverAssociationUuid={taxiDriverAssociationUuid}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: DriverEditViewMode.TAXI,
        component: (
          <DriverEditByTaxiComponent
            changeViewButtons={getViewChangeButtons(DriverEditViewMode.TAXI)}
            driverUuid={driverUuid}
            driverName={driverName}
            userFormData={userFormData}
            accountFormData={accountFormData}
            companyFormData={companyFormData}
            vehicleFormData={vehicleFormData}
            fleetPartnerSelectOptions={fleetPartnerSelectOptions}
            taxiDriverAssociationUuid={taxiDriverAssociationUuid}
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons, driverUuid, driverName]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent || isDriverFetching) {
    return null;
  }

  return SelectedViewComponent;
};

export default DriverEditComponent;
