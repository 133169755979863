import { DriverAddFleetPartnersResponseItem } from "./api/driver-add-fleet-partners.response";
import DriverAddUserDataFleetPartnerSelectOption from "./types/driver-add-user-data-fleet-partner-select-option";

const createSelectOption = (
  responseItem: DriverAddFleetPartnersResponseItem
): DriverAddUserDataFleetPartnerSelectOption => {
  return {
    label: responseItem.name,
    value: {
      uuid: responseItem.id,
      name: responseItem.name,
    },
  };
};

const createSelectOptions = (
  responseItems: DriverAddFleetPartnersResponseItem[]
): DriverAddUserDataFleetPartnerSelectOption[] => {
  return responseItems.map(createSelectOption);
};

const driverAddFleetPartnersFactory = {
  createSelectOptions,
};

export default driverAddFleetPartnersFactory;
