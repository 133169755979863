import CargoOfficerAddRequest from "../../../api/raily/cargo-officer/add/cargo-officer-add.request";
import CargoOfficerAddParams from "./cargo-officer-add-params";

const create = (params: CargoOfficerAddParams): CargoOfficerAddRequest => {
  return {
    cargoCompanyId: params.cargoCompanyUuid,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.phoneNumber.number,
    mobilePrimaryPrefix: params.phoneNumber.dialingCode,
    mobileSecondary: params.alternativePhoneNumber.number || null,
    mobileSecondaryPrefix: params.alternativePhoneNumber.dialingCode || null,
    password: params.password,
    username: params.username,
  };
};

const cargoOfficerAddRequestFactory = {
  create,
};

export default cargoOfficerAddRequestFactory;
