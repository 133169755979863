enum OrderFinishedListingFilterType {
  CARGO_COMPANY_INTERNAL_ORDER_ID = "CARGO_COMPANY_INTERNAL_ORDER_ID",
  CARGO_COMPANY_EXTERNAL_ORDER_ID = "CARGO_EXTERNAL_ORDER_ID",
  CLIENT = "CLIENT",
  DISPATCH = "DISPATCH",
  DRIVER = "DRIVER",
  PASSENGER = "PASSENGER",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  ROUTE_DESTINATION_ADDRESS = "ROUTE_DESTINATION_ADDRESS",
  ROUTE_INTERMEDIATE_ADDRESS = "ROUTE_INTERMEDIATE_ADDRESS",
  ROUTE_PICKUP_ADDRESS = "ROUTE_PICKUP_ADDRESS",
  EXECUTION_STATUS = "EXECUTION_STATUS",
  PUBLIC_STATUS = "PUBLIC_STATUS",
  TAXI = "TAXI",
  ORDER_START_DATE = "ORDER_START_DATE",
  URGENT_ONLY = "URGENT_ONLY",
}

export default OrderFinishedListingFilterType;
