import PlannerDriverPlanListRequest, {
  PlannerDriverPlanListRequestBody,
  PlannerDriverPlanListRequestQueryParams,
} from "./planner-driver-plan-list.request";

const createBody = (
  data: PlannerDriverPlanListRequest
): PlannerDriverPlanListRequestBody => {
  return {
    matchTo: data.matchTo,
    filter: data.filter,
    resultOrder: data.resultOrder,
  };
};

const createQueryParams = (
  data: PlannerDriverPlanListRequest
): PlannerDriverPlanListRequestQueryParams => {
  return {
    orderId: data.orderId,
    taxiCorporationId: data.taxiCorporationId,
    offset: data.offset,
    limit: data.limit,
  };
};

const plannerDriverPlanListRequestFactory = {
  createBody,
  createQueryParams,
};

export default plannerDriverPlanListRequestFactory;
