import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useMemo, useState } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import LoaderComponent from "../../../../common/components/loader/loader.component";
import NoticeBoxType from "../../../../common/components/notice-box/notice-box-type";
import NoticeBoxComponent from "../../../../common/components/notice-box/notice-box.component";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderDetailsHistoryApiService from "./common/api/order-details-history-api.service";
import OrderDetailsHistoryResponse, {
  OrderDetailsHistoryResponseData,
} from "./common/api/order-details-history.response";
import orderDetailsHistoryEntryFactory from "./common/order-details-history-entry.factory";
import OrderDetailsHistoryEntry from "./common/types/order-details-history-entry";
import OrderDetailsHistoryEntryComponent from "./entry/order-details-history-entry.component";
import useOrderDetailsHistoryUserPermissions from "./common/user-permissions/use-order-details-history-user-permissions";
import useCargoOrderDetailsHandlingLog from "../../../../common/services/cargo-order/handling-log/use-cargo-order-details-handling-log";
import useAbort from "../../../../common/hooks/use-abort";
import CargoOrderDetailsHandlingLogLoadParams from "../../../../common/services/cargo-order/handling-log/cargo-order-details-handling-log-load-params";
import orderDetailsHandlingLogLoadParamsFactory from "./common/types/order-details-handling-log-load-params.factory";

type OrderDetailsHistoryProps = {
  orderUuid: string;
  refetchFlag: boolean;
};

const OrderDetailsHistoryComponent: FC<OrderDetailsHistoryProps> = (props) => {
  const [isOrderHistoryFetching, setIsOrderHistoryFetching] = useState(false);
  const [historyEntryData, setHistoryEntryData] =
    useState<OrderDetailsHistoryResponseData>([]);
  const [isOrderHistoryFetchingError, setIsOrderHistoryFetchingError] =
    useState(false);
  const [historyEntries, setHistoryEntries] = useState<
    OrderDetailsHistoryEntry[]
  >([]);

  const handlingLogAbort = useAbort();
  const orderHandlingLog = useCargoOrderDetailsHandlingLog();
  const userPermissions = useOrderDetailsHistoryUserPermissions();
  const translations = orderTranslationsHelper.getDetailsHistoryTranslations();

  useEffect(() => {
    if (!userPermissions.hasAccessToHandlingLog) return;

    const params: CargoOrderDetailsHandlingLogLoadParams =
      orderDetailsHandlingLogLoadParamsFactory.create(props.orderUuid);

    orderHandlingLog.load(params, handlingLogAbort.signal);
  }, []);

  const onOrderHistoryFetchSuccess = (
    historyResponseData: OrderDetailsHistoryResponseData
  ) => {
    setHistoryEntryData(historyResponseData);
    setIsOrderHistoryFetchingError(false);
  };

  useEffect(() => {
    const historyEntries = orderDetailsHistoryEntryFactory.createHistoryEntries(
      historyEntryData,
      orderHandlingLog.data.data
    );

    setHistoryEntries(historyEntries);
  }, [orderHandlingLog.data.data, historyEntryData]);

  const onOrderHistoryFetchFailure = () => {
    setIsOrderHistoryFetchingError(true);
  };

  const handleOrderHistoryResponse = (
    historyResponse: OrderDetailsHistoryResponse
  ) => {
    const isError = !(historyResponse.status === 200);

    if (!isError) {
      onOrderHistoryFetchSuccess(historyResponse.data);
      return;
    }

    onOrderHistoryFetchFailure();
  };

  const fetchOrderHistory = () => {
    setIsOrderHistoryFetching(true);

    const orderDetailsHistoryFetchPromise =
      orderDetailsHistoryApiService.fetchOrderHistory(props.orderUuid);

    Promise.all([orderDetailsHistoryFetchPromise]).then(
      ([orderDetailsHistoryResponse]) => {
        handleOrderHistoryResponse(orderDetailsHistoryResponse);
        setIsOrderHistoryFetching(false);
      }
    );
  };

  useEffect(() => {
    fetchOrderHistory();
  }, [props.orderUuid, props.refetchFlag]);

  const onRefreshButtonClick = () => {
    fetchOrderHistory();
  };

  const LoaderContent = (
    <div className="order_details_history_loader_wrapper">
      <LoaderComponent type="primary" />
    </div>
  );

  const ErrorContent = (
    <NoticeBoxComponent
      type={NoticeBoxType.DANGER}
      classNames={{ root: "w-100 mt-2" }}
    >
      {translations.failureFetchHistoryNotificationMessageText}
    </NoticeBoxComponent>
  );

  return (
    <div className="order_details_history">
      <div className="order_details_history__heading">
        <div className="order_details_history__heading_text">
          {translations.headingText}
        </div>
        <ButtonComponent
          type="primary"
          onClick={onRefreshButtonClick}
          classNames={{ root: "p-2 order_details_history_refresh_button" }}
          title={translations.refreshButtonTitle}
        >
          <FontAwesomeIcon
            icon={faArrowsRotate}
            size="lg"
            className="order_details_history_refresh_button__icon"
          />
        </ButtonComponent>
      </div>
      {isOrderHistoryFetching ? (
        LoaderContent
      ) : isOrderHistoryFetchingError ? (
        ErrorContent
      ) : (
        <ul className="order_details_history_entry_list">
          {historyEntries.map((entry, index) => {
            return (
              <OrderDetailsHistoryEntryComponent key={index} entry={entry} />
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default OrderDetailsHistoryComponent;
