import paginationService from "../../../../utils/pagination/pagination.service";
import MileageContractListRequest, {
  MileageContractListQueryParams,
} from "./mileage-contract-list.request";

const createQueryParams = (
  request: MileageContractListRequest
): MileageContractListQueryParams => {
  return {
    offset:
      !!request.page && !!request.pageSize
        ? paginationService.calculateOffset(request.page, request.pageSize)
        : undefined,
    limit: request.pageSize,
    isValid: request.isValid,
    cargoCompanyId: request.cargoCompanyUuid,
    cargoCompanyName: request.cargoCompanyName,
    order: request.order,
  };
};

const mileageContractListRequestFactory = {
  createQueryParams,
};

export default mileageContractListRequestFactory;
