import SearchRoadRoutingRequest from "../../../../../common/utils/search-road-route/search-road-routing.request";
import BillingsNode from "../../../common/types/billings-node";

const createBillingDataRouteRequests = (
  nodes: BillingsNode[]
): SearchRoadRoutingRequest[] => {
  const result: SearchRoadRoutingRequest[] = [];

  const lastNodePosition = Math.max(...nodes.map((node) => node.position));

  nodes.forEach((node, index) => {
    const nextNode = nodes[index + 1];

    const shouldProcessNextNodes = node.position === lastNodePosition;

    if (shouldProcessNextNodes) {
      return;
    }

    result.push({
      waypointCoordinates: [
        { latitude: node.lat, longitude: node.lon },
        { latitude: nextNode.lat, longitude: nextNode.lon },
      ],
      excludeHighway: !nextNode.isHighwayAllowed,
    });
  });

  return result;
};

const billingDataRouteRequestsFactory = {
  createBillingDataRouteRequests,
};

export default billingDataRouteRequestsFactory;
