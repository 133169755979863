import RailyOfficerAddRequest from "../../api/raily/raily-officer/add/raily-officer-add.request";
import RailyOfficerListRequest from "../../api/raily/raily-officer/list/raily-officer-list.request";
import railyOfficerApiService from "../../api/raily/raily-officer/raily-officer-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import RailyOfficerAddError from "./add/raily-officer-add-error";
import RailyOfficerAddLoadParams from "./add/raily-officer-add-load-params";
import railyOfficerAddRequestFactory from "./add/raily-officer-add-request.factory";
import RailyOfficerList from "./list/raily-officer-list";
import RailyOfficerListError from "./list/raily-officer-list-error";
import RailyOfficerListLoadParams from "./list/raily-officer-list-load-params";
import railyOfficerListRequestFactory from "./list/raily-officer-list-request.factory";
import railyOfficerListFactory from "./list/raily-officer-list.factory";

const handleAddError = (
  error: HttpError | RailyOfficerAddError
): RailyOfficerAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleListError = (
  error: HttpError | RailyOfficerListError
): RailyOfficerListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: RailyOfficerListLoadParams,
  abortSignal: AbortSignal
): Promise<RailyOfficerList> => {
  const request: RailyOfficerListRequest =
    railyOfficerListRequestFactory.create(params);

  try {
    const response = await railyOfficerApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return railyOfficerListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | RailyOfficerListError;

    throw handleListError(error);
  }
};

const add = async (
  params: RailyOfficerAddLoadParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: RailyOfficerAddRequest =
    railyOfficerAddRequestFactory.create(params);

  try {
    const response = await railyOfficerApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | RailyOfficerAddError;

    throw handleAddError(error);
  }
};

const railyOfficerService = {
  add,
  getList,
};

export default railyOfficerService;
