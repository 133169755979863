import { FC } from "react";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetailsGoogleMapsRouteComponentProps = {
  isRouteSequenceSolved: boolean;
  tools: OrderDetailsToolsTranslations;
  onSuccess: () => void;
};

const OrderDetailsGoogleMapsRouteComponent: FC<
  OrderDetailsGoogleMapsRouteComponentProps
> = ({ isRouteSequenceSolved, tools, onSuccess }) => {
  if (!isRouteSequenceSolved) return null;

  const { googleMapsRouteButtonTitle } = tools;

  return (
    <OrderDetailsToolButtonComponent
      onClick={onSuccess}
      icon={faLocationDot}
      title={googleMapsRouteButtonTitle}
    />
  );
};

export default OrderDetailsGoogleMapsRouteComponent;
