import MileageDictionaryListingRouteQueryParams from "./table/types/mileage-dictionary-listing-route-params";

const getPage = (
  routeQueryParams: MileageDictionaryListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: MileageDictionaryListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  page: number,
  pageSize: number
): MileageDictionaryListingRouteQueryParams => {
  return {
    page: String(page),
    pageSize: String(pageSize),
  };
};

const mileageDictionaryListingService = {
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default mileageDictionaryListingService;
