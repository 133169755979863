import { DriverContractListingResponseItem } from "../../common/api/driver-contract-listing.response";
import DriverContractListingByTaxiListingItem from "./types/driver-contract-listing-by-taxi-listing-item";

const createListingItem = (
  responseItem: DriverContractListingResponseItem
): DriverContractListingByTaxiListingItem => {
  return {
    uuid: responseItem.contract_id,
    billingModel: responseItem.model,
    distanceRate: responseItem.distance_rate,
    freeStoppingPeriodMinutes: responseItem.halting_time_after_minutes,
    stoppingRate: responseItem.halting_time_rate,
    periodOfValidity: {
      from: new Date(responseItem.valid_since),
      to: new Date(responseItem.valid_to),
    },
    isActive: responseItem.active,
  };
};

const createListingItems = (
  responseData: DriverContractListingResponseItem[]
): DriverContractListingByTaxiListingItem[] => {
  return responseData.map(createListingItem);
};

const driverContractListingByTaxiFactory = {
  createListingItems,
};

export default driverContractListingByTaxiFactory;
