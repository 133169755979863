import appConfig from "../../../../../../../app.config";
import authService from "../../../../../../auth/common/auth.service";
import passengerAddApiService from "../../../common/api/passenger-add-api.service";
import PassengerAddByRailyCargoCompaniesResponse from "./passenger-add-by-raily-cargo-companies-response";

const fetchCargoCompanies =
  async (): Promise<PassengerAddByRailyCargoCompaniesResponse> => {
    const path = `/cargo-companies`;
    const url = `${appConfig.baseApiUrl}${path}`;
    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };

    const r = await fetch(url, init);
    const responseData = await r.json();

    return {
      status: r.status,
      data: responseData,
    };
  };

const passengerAddByRailyApiService = {
  addPassenger: passengerAddApiService.addPassenger,
  fetchCargoCompanies,
};

export default passengerAddByRailyApiService;
