import MileageListRequest, {
  MileageListRequestVehicleType,
  MileageResultsOrder,
} from "../../../api/raily/mileage/list/mileage-list.request";
import paginationService from "../../../utils/pagination/pagination.service";
import MileageListLoadParams, {
  MileageListLoadParamsOrder,
  MileageListLoadParamsVehicleType,
} from "./mileage-list-load-params";

const createOrder = (
  order?: MileageListLoadParamsOrder | undefined
): MileageResultsOrder | undefined => {
  switch (order) {
    case MileageListLoadParamsOrder.CARD_NO_ASC:
      return MileageResultsOrder.CARD_NO_ASC;
    case MileageListLoadParamsOrder.CARD_NO_DESC:
      return MileageResultsOrder.CARD_NO_DESC;
    case MileageListLoadParamsOrder.DATE_ASC:
      return MileageResultsOrder.DATE_ASC;
    case MileageListLoadParamsOrder.DATE_DESC:
      return MileageResultsOrder.DATE_DESC;
    case MileageListLoadParamsOrder.DISTANCE_ASC:
      return MileageResultsOrder.DISTANCE_ASC;
    case MileageListLoadParamsOrder.DISTANCE_DESC:
      return MileageResultsOrder.DISTANCE_DESC;
    case MileageListLoadParamsOrder.HUMAN_ID_ASC:
      return MileageResultsOrder.HUMAN_ID_ASC;
    case MileageListLoadParamsOrder.HUMAN_ID_DESC:
      return MileageResultsOrder.HUMAN_ID_DESC;
    case MileageListLoadParamsOrder.WORKER_NAME_ASC:
      return MileageResultsOrder.WORKER_NAME_ASC;
    case MileageListLoadParamsOrder.WORKER_NAME_DESC:
      return MileageResultsOrder.WORKER_NAME_DESC;
    case MileageListLoadParamsOrder.CARGO_COMPANY_NAME_ASC:
      return MileageResultsOrder.CARGO_COMPANY_NAME_ASC;
    case MileageListLoadParamsOrder.CARGO_COMPANY_NAME_DESC:
      return MileageResultsOrder.CARGO_COMPANY_NAME_DESC;
    default:
      return undefined;
  }
};

const createVehicleType = (
  type: MileageListLoadParamsVehicleType | undefined
): MileageListRequestVehicleType | undefined => {
  switch (type) {
    case MileageListLoadParamsVehicleType.CAR_GT_900CM3:
      return MileageListRequestVehicleType.CAR_GT_900CM3;
    case MileageListLoadParamsVehicleType.CAR_LE_900CM3:
      return MileageListRequestVehicleType.CAR_LE_900CM3;
    case MileageListLoadParamsVehicleType.COMPANY_OWNED:
      return MileageListRequestVehicleType.COMPANY_OWNED;
    case MileageListLoadParamsVehicleType.MOPED:
      return MileageListRequestVehicleType.MOPED;
    case MileageListLoadParamsVehicleType.MOTORCYCLE:
      return MileageListRequestVehicleType.MOTORCYCLE;
    default:
      return undefined;
  }
};

const create = (params: MileageListLoadParams): MileageListRequest => {
  return {
    address: params.address,
    cardNumber: params.cardNumber,
    companyId: params.companyId,
    dateSince: params.dateSince,
    dateTo: params.dateTo,
    distanceMax: params.distanceMax,
    distanceMin: params.distanceMin,
    endAddress: params.endAddress,
    cargoCompanyName: params.cargoCompanyName,
    humanId: params.humanId,
    limit: params.pageSize,
    midAddress: params.midAddress,
    mileageNumber: params.mileageNumber,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    startAddress: params.startAddress,
    workerName: params.workerName,
    vehicleType: createVehicleType(params.vehicleType),
  };
};

const mileageListRequestFactory = {
  create,
};

export default mileageListRequestFactory;
