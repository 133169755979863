import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionContractCorrectionType,
} from "./types/billings-taxi-driver-contributions-contract-correction";

const getNewContractCorrectionContribution = (
  distance: number
): BillingsTaxiDriverContributionContractCorrection => {
  return {
    type: null,
    distance: distance,
    rate: 0,
    amount: 0,
  };
};

const getContributionTypeTranslation = (
  contributionType: BillingsTaxiDriverContributionContractCorrectionType | null
) => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations()
      .contractCorrection.type;

  switch (contributionType) {
    case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return translations.baseAmountExternalTaxi;
    case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
      return translations.otherRate;
  }
};

const billingsTaxiDriverContributionsContractContributionHelper = {
  getNewContractCorrectionContribution,
  getContributionTypeTranslation,
};

export default billingsTaxiDriverContributionsContractContributionHelper;
