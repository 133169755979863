import formValidationService from "../../../../../../../../common/utils/validation/form-validation.service";
import DriverDetailsPlanEditCheckoutFormData from "./types/driver-details-plan-edit-checkout-form-data";
import DriverDetailsPlanEditCheckoutFormValidationResults from "./types/driver-details-plan-edit-checkout-form-validation-results";

const getDefaultFormData = (): DriverDetailsPlanEditCheckoutFormData => {
  return {
    haltingTimeMinutes: null,
    highwayCost: null,
  };
};

const getDefaultFormValidationResults =
  (): DriverDetailsPlanEditCheckoutFormValidationResults => {
    return {
      haltingTime: formValidationService.defaultValidationResult,
      highwayCost: formValidationService.defaultValidationResult,
    };
  };

const driverDetailsPlanEditCheckoutFormHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default driverDetailsPlanEditCheckoutFormHelper;
