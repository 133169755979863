export enum RailyOfficerListRequestOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

export enum RailyOfficerListRequestStatusCriteria {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  ALL = "ALL",
}

export type RailyOfficerListRequestQueryParams = {
  firstName: string | undefined;
  lastName: string | undefined;
  username: string | undefined;
  email: string | undefined;
  statusCriteria: RailyOfficerListRequestStatusCriteria | undefined;
  order: RailyOfficerListRequestOrder | undefined;
  offset: number | undefined;
  limit: number | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
};

type RailyOfficerListRequest = {
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  statusCriteria?: RailyOfficerListRequestStatusCriteria;
  order?: RailyOfficerListRequestOrder;
};

export default RailyOfficerListRequest;
