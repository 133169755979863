export enum OrderAbandonedListingFilterRequest {
  START_TIME_SINCE = "start_time_since",
  START_TIME_TO = "start_time_to",
  HUMAN_ID = "human_id",
  ADDRESS = "address",
  START_ADDRESS = "start_address",
  MID_ADDRESS = "mid_address",
  END_ADDRESS = "end_address",
  PASSENGER = "passenger",
  CLIENT = "client",
  DISPATCH = "dispatch",
  EXTERNAL_ID = "external_id",
  PUBLIC_STATUS = "public_status",
}

export type OrderAbandonedListingFilterRequestType = Partial<
  Record<OrderAbandonedListingFilterRequest, string>
>;
