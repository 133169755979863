import OrderCancelRequest from "../../../api/raily/order/order-cancel/order-cancel.request";
import OrderCancelParams from "./order-cancel-params";

const create = (params: OrderCancelParams): OrderCancelRequest => {
  return {
    orderId: params.orderUuid,
  };
};

const orderCancelRequestFactory = {
  create,
};

export default orderCancelRequestFactory;
