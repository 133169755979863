import classNames from "classnames";
import { FC } from "react";
import DelegationListingDelegationStatus from "../../types/delegation-listing-delegation-status";

type DelegationListingTableStatusProps = {
  status: DelegationListingDelegationStatus;
};

const DelegationListingTableStatusComponent: FC<
  DelegationListingTableStatusProps
> = (props) => {
  const getClassNameByStatus = (): string => {
    switch (props.status) {
      case DelegationListingDelegationStatus.DOWNLOADED:
        return "downloaded";
      case DelegationListingDelegationStatus.UNDOWNLOADED:
        return "undownloaded";
      case DelegationListingDelegationStatus.DELETED:
        return "deleted";
      default:
        return "";
    }
  };

  const statusClassName = getClassNameByStatus();

  const containerClassNames = classNames(
    "delegation-listing_table_status",
    statusClassName
  );

  return <div className={containerClassNames}></div>;
};

export default DelegationListingTableStatusComponent;
