import BillingsOrderTaxiDriverRequest from "../../../../api/raily/billings/order/taxi-driver/billings-order-taxi-driver.request";
import BillingsOrderTaxiDriverLoadParams from "./billings-order-taxi-driver-load-params";

const create = (
  params: BillingsOrderTaxiDriverLoadParams
): BillingsOrderTaxiDriverRequest => {
  return {
    orderId: params.orderUuid,
  };
};

const billingsOrderTaxiDriverRequestFactory = {
  create,
};

export default billingsOrderTaxiDriverRequestFactory;
