import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiTaxiContributionPenalty, {
  BillingsTaxiTaxiContributionPenaltyType,
} from "./types/billings-taxi-taxi-contributions-penalty";

const getNewPenaltyContribution = (): BillingsTaxiTaxiContributionPenalty => {
  return {
    type: null,
    amount: null,
    comment: "",
  };
};

const getContributionTypeTranslation = (
  contributionType: BillingsTaxiTaxiContributionPenaltyType | null
) => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiContributionsTranslations().penalty
      .type;

  switch (contributionType) {
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_BEING_LATE:
      return translations.beingLate;
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
      return translations.incompatibleCar;
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return translations.wrongRoute;
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_OTHER:
      return translations.other;
  }
};

const billingsTaxiTaxiContributionsPenaltyHelper = {
  getNewPenaltyContribution,
  getContributionTypeTranslation,
};

export default billingsTaxiTaxiContributionsPenaltyHelper;
