export enum PlanTypeEntry {
  PLAN_ENTRY = "PlanEntry",
  CANDIDATURE_ENTRY = "CandidatureEntry",
  ORDER = "OrderEntry",
}

export enum PlanXAxisValue {
  FIRST_ROW = 10,
  SECOND_ROW = 20,
  THIRD_ROW = 30,
}

export enum PlanColors {
  PLAN_ENRTRY = "#008BF7",
  CANDIDATURE_ENTRY = "#7AB6FF",
  ORDER_ENTRY = "#00C9B7",
  POTENCIAL_PLAN_ENTRY = "#87E7A4",
}

export type PlanningOrderDriverPlanListingItemChartPlanOrderPoint = {
  address: string;
  date: Date;
};

export type PlanningOrderDriverPlanListingItemChartPlanOrderPoints = {
  uuid?: number;
  waypoints: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[];
};

export type PlanningOrderDriverPlanListingItemChartPlan = {
  uuid?: number;
  start?: PlanningOrderDriverPlanListingItemChartPlanOrderPoint;
  end?: PlanningOrderDriverPlanListingItemChartPlanOrderPoint;
  orders: PlanningOrderDriverPlanListingItemChartPlanOrderPoints[];
  type: PlanTypeEntry;
};

type PlanningOrderDriverPlanListingItemChart = {
  entries: PlanningOrderDriverPlanListingItemChartPlan[];
};

export default PlanningOrderDriverPlanListingItemChart;
