import HttpResponse from "../../../../utils/http/http.response";

export enum OrderCancelNotAcceptableResponseErrorType {
  ORDER_CANCELLED = "ORDER_CANCELLED",
  PASSENGER_ON_BOARD = "PASSENGER_ON_BOARD",
}

type OrderCancelNotAcceptableResponseOrderCancelledError = {
  errorType: OrderCancelNotAcceptableResponseErrorType.ORDER_CANCELLED;
};

type OrderCancelNotAcceptableResponsePassengerOnboardError = {
  errorType: OrderCancelNotAcceptableResponseErrorType.PASSENGER_ON_BOARD;
};

export type OrderCancelNotAcceptableResponseError =
  | OrderCancelNotAcceptableResponseOrderCancelledError
  | OrderCancelNotAcceptableResponsePassengerOnboardError;

type OrderCancelSuccessResponse = HttpResponse<string>;

export type OrderCancelNotAcceptableResponse =
  HttpResponse<OrderCancelNotAcceptableResponseError>;

type OrderCancelResponse =
  | OrderCancelSuccessResponse
  | OrderCancelNotAcceptableResponse;

export default OrderCancelResponse;
