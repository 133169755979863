import PlannerDriverPlanListResponse, {
  PlannerDriverPlanListResponseItem,
  PlannerDriverPlanListResponseItemCandidatingPlanEntry,
  PlannerDriverPlanListResponseItemCandidatingPlanEntryEndDate,
  PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPoints,
  PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPointsNodeData,
  PlannerDriverPlanListResponseItemCandidatingPlanEntryStartData,
  PlannerDriverPlanListResponseItemPlanEntry,
  PlannerDriverPlanListResponseItemPlanEntryEndData,
  PlannerDriverPlanListResponseItemPlanEntryOrderPoint,
  PlannerDriverPlanListResponseItemPlanEntryOrderPointsNodeData,
  PlannerDriverPlanListResponseItemPlanEntryStartData,
  PlannerDriverPlanListResponseItemTargerPlanEntryData,
  PlannerDriverPlanListResponseItemUser,
  PlannerDriverPlanListResponseItemUserBillingModel,
  PlannerDriverPlanListResponseItemUserTaxiDriverContractType,
} from "../../../api/raily/planner/list/planner-driver-plan-list.response";
import BillingModel from "../../../types/billing-model";
import TaxiDriverAssociationContractType from "../../../types/taxi-driver-association-contract-type";
import PlannerDriverPlanList, {
  PlannerDriverPlanListItem,
  PlannerDriverPlanListItemCandidatingPlanEntry,
  PlannerDriverPlanListItemCandidatingPlanEntryEndData,
  PlannerDriverPlanListItemCandidatingPlanEntryOrderPoint,
  PlannerDriverPlanListItemCandidatingPlanEntryStartData,
  PlannerDriverPlanListItemCandidatingPlanEntryWaypoint,
  PlannerDriverPlanListItemPlanEntry,
  PlannerDriverPlanListItemPlanEntryEndData,
  PlannerDriverPlanListItemPlanEntryOrderPoint,
  PlannerDriverPlanListItemPlanEntryOrderWaypoint,
  PlannerDriverPlanListItemPlanEntryStartData,
  PlannerDriverPlanListItemTargerPlanEntryData,
  PlannerDriverPlanListItemUser,
} from "./planner-driver-plan-list";

const createBillingModel = (
  type: PlannerDriverPlanListResponseItemUserBillingModel
): BillingModel => {
  switch (type) {
    case PlannerDriverPlanListResponseItemUserBillingModel.AB:
      return BillingModel.AB;
    case PlannerDriverPlanListResponseItemUserBillingModel.ABA:
      return BillingModel.ABA;
    case PlannerDriverPlanListResponseItemUserBillingModel.OTHER:
      return BillingModel.OTHER;
    case PlannerDriverPlanListResponseItemUserBillingModel.SABS:
      return BillingModel.SABS;
  }
};

const createContractType = (
  type: PlannerDriverPlanListResponseItemUserTaxiDriverContractType
): TaxiDriverAssociationContractType => {
  switch (type) {
    case PlannerDriverPlanListResponseItemUserTaxiDriverContractType.B2B:
      return TaxiDriverAssociationContractType.B2B;
    case PlannerDriverPlanListResponseItemUserTaxiDriverContractType.CONTRACT_OF_MANDATE:
      return TaxiDriverAssociationContractType.CONTRACT_OF_MANDATE;
    case PlannerDriverPlanListResponseItemUserTaxiDriverContractType.EMPLOYMENT:
      return TaxiDriverAssociationContractType.EMPLOYMENT;
  }
};

const createAlternativePhoneNumber = (
  data: PlannerDriverPlanListResponseItemUser
): PlannerDriverPlanListItemUser["alternativePhoneNumber"] => {
  if (!data.mobileSecondary || !data.mobileSecondaryPrefix) {
    return null;
  }

  return {
    dialingCode: data.mobileSecondaryPrefix,
    number: data.mobileSecondary,
  };
};

const createUser = (
  user: PlannerDriverPlanListResponseItemUser
): PlannerDriverPlanListItemUser => {
  return {
    uuid: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: {
      dialingCode: user.mobilePrimaryPrefix,
      number: user.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(user),
    taxiName: user.taxiName,
    taxiDriverContractType: createContractType(user.taxiDriverContractType),
    startingAddress: user.startingAddress,
    carMark: user.carMake,
    carModel: user.carModel,
    carOwnership: user.carOwnership,
    billingModel: createBillingModel(user.billingModel),
    distanceRate: user.distanceRate,
  };
};

const createTargetPlanEntryData = (
  data: PlannerDriverPlanListResponseItemTargerPlanEntryData | null
): PlannerDriverPlanListItemTargerPlanEntryData => {
  return {
    uuid: data?.id,
    planEntryUuid: data?.humanId,
  };
};

const createPlanEntriesStart = (
  data: PlannerDriverPlanListResponseItemPlanEntryStartData
): PlannerDriverPlanListItemPlanEntryStartData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createPlanEntriesEnd = (
  data: PlannerDriverPlanListResponseItemPlanEntryEndData
): PlannerDriverPlanListItemPlanEntryEndData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createWaypoint = (
  data: PlannerDriverPlanListResponseItemPlanEntryOrderPointsNodeData
): PlannerDriverPlanListItemPlanEntryOrderWaypoint => {
  return { address: data.address, date: data.date };
};

const createWaypoints = (
  data: PlannerDriverPlanListResponseItemPlanEntryOrderPointsNodeData[]
): PlannerDriverPlanListItemPlanEntryOrderWaypoint[] => {
  return data.map(createWaypoint);
};

const createPlanEntriesOrder = (
  data: PlannerDriverPlanListResponseItemPlanEntryOrderPoint
): PlannerDriverPlanListItemPlanEntryOrderPoint => {
  return {
    orderId: data.humanId,
    waypoints: createWaypoints(data.waypoints),
  };
};

const createPlanEntriesOrders = (
  data: PlannerDriverPlanListResponseItemPlanEntryOrderPoint[]
): PlannerDriverPlanListItemPlanEntryOrderPoint[] => {
  return data.map(createPlanEntriesOrder);
};

const createPlanEntry = (
  data: PlannerDriverPlanListResponseItemPlanEntry
): PlannerDriverPlanListItemPlanEntry => {
  return {
    id: data.id,
    planEntryId: data.humanId,
    start: createPlanEntriesStart(data.start),
    end: createPlanEntriesEnd(data.end),
    orders: createPlanEntriesOrders(data.orders),
  };
};

const createPlanEntries = (
  data: PlannerDriverPlanListResponseItemPlanEntry[]
): PlannerDriverPlanListItemPlanEntry[] => {
  return data.map(createPlanEntry);
};

const createCandidatureEntryStart = (
  data: PlannerDriverPlanListResponseItemCandidatingPlanEntryStartData
): PlannerDriverPlanListItemCandidatingPlanEntryStartData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createCandidatureEntryEnd = (
  data: PlannerDriverPlanListResponseItemCandidatingPlanEntryEndDate
): PlannerDriverPlanListItemCandidatingPlanEntryEndData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createCandidatureEntryWaypoint = (
  data: PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPointsNodeData
): PlannerDriverPlanListItemCandidatingPlanEntryWaypoint => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createCandidatureEntryWaypoints = (
  data: PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPointsNodeData[]
): PlannerDriverPlanListItemCandidatingPlanEntryWaypoint[] => {
  return data.map(createCandidatureEntryWaypoint);
};

const createCandidatureEntryOrder = (
  data: PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPoints
): PlannerDriverPlanListItemCandidatingPlanEntryOrderPoint => {
  return {
    planEntryUuid: data.humanId,
    waypoints: createCandidatureEntryWaypoints(data.waypoints),
  };
};

const createCandidatureEntryOrders = (
  data: PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPoints[]
): PlannerDriverPlanListItemCandidatingPlanEntryOrderPoint[] => {
  return data.map(createCandidatureEntryOrder);
};

const createCandidatureEntry = (
  data: PlannerDriverPlanListResponseItemCandidatingPlanEntry
): PlannerDriverPlanListItemCandidatingPlanEntry => {
  return {
    uuid: data.id,
    candidatureUuid: data.humanId,
    start: createCandidatureEntryStart(data.start),
    end: createCandidatureEntryEnd(data.end),
    orders: createCandidatureEntryOrders(data.orders),
  };
};

const createCandidatureEntries = (
  data: PlannerDriverPlanListResponseItemCandidatingPlanEntry[]
): PlannerDriverPlanListItemCandidatingPlanEntry[] => {
  return data.map(createCandidatureEntry);
};

const createDataItem = (
  responseItem: PlannerDriverPlanListResponseItem
): PlannerDriverPlanListItem => {
  return {
    targetPlanEntryData: createTargetPlanEntryData(
      responseItem.targetPlanEntryData
    ),
    taxiDriverAssociationUuid: responseItem.taxiDriverAssociationId,
    planEntries: createPlanEntries(responseItem.planEntries),
    candidatureEntries: createCandidatureEntries(
      responseItem.candidatureEntries
    ),
    user: createUser(responseItem.user),
  };
};

const createData = (
  responseData: PlannerDriverPlanListResponseItem[]
): PlannerDriverPlanListItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: PlannerDriverPlanListResponse
): PlannerDriverPlanList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const plannerDriverPlanListFactory = {
  create,
};

export default plannerDriverPlanListFactory;
