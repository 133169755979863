import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import CargoAddressListingFilter from "../../common/types/cargo-address-listing-filter";
import CargoAddressListingFilterType from "../../common/types/cargo-address-listing-filter-type";

const getBadgeFilterNameTranslationByType = (
  filterType: CargoAddressListingFilterType
): string => {
  const filterTranslations =
    cargoTranslationsHelper.getAddressListingTranslations().filters;

  const options: {
    translation: string;
    type: CargoAddressListingFilterType;
  }[] = [
    {
      translation: filterTranslations.descriptionFilterName,
      type: CargoAddressListingFilterType.DESCRIPTION,
    },
    {
      translation: filterTranslations.streetFilterName,
      type: CargoAddressListingFilterType.STREET,
    },

    {
      translation: filterTranslations.townFilterName,
      type: CargoAddressListingFilterType.TOWN,
    },
    {
      translation: filterTranslations.countryFilterName,
      type: CargoAddressListingFilterType.COUNTRY,
    },
    {
      translation: filterTranslations.zipCodeFilterName,
      type: CargoAddressListingFilterType.ZIP_CODE,
    },
  ];

  return (
    options.find((option) => option.type === filterType)?.translation ?? ""
  );
};

const getBadgeFilterTitleTranslationByType = (
  filterType: CargoAddressListingFilterType
): string => {
  const filterTranslations =
    cargoTranslationsHelper.getAddressListingTranslations().filters;

  const options: {
    translation: string;
    type: CargoAddressListingFilterType;
  }[] = [
    {
      translation: filterTranslations.descriptionFilterTitle,
      type: CargoAddressListingFilterType.DESCRIPTION,
    },
    {
      translation: filterTranslations.streetFilterTitle,
      type: CargoAddressListingFilterType.STREET,
    },
    {
      translation: filterTranslations.townFilterTitle,
      type: CargoAddressListingFilterType.TOWN,
    },
    {
      translation: filterTranslations.countryFilterTitle,
      type: CargoAddressListingFilterType.COUNTRY,
    },
    {
      translation: filterTranslations.zipCodeFilterTitle,
      type: CargoAddressListingFilterType.ZIP_CODE,
    },
  ];

  return (
    options.find((option) => option.type === filterType)?.translation ?? ""
  );
};

const getBadgeName = (filter: CargoAddressListingFilter): string => {
  const filterName = getBadgeFilterNameTranslationByType(filter.type);

  return `${filterName}: ${filter.value}`;
};

const getBadgeTitle = (filter: CargoAddressListingFilter): string => {
  const filterTitle = getBadgeFilterTitleTranslationByType(filter.type);

  return `${filterTitle}: ${filter.value}`;
};

const cargoAddressListingFiltersBadgeHelper = {
  getBadgeName,
  getBadgeTitle,
};

export default cargoAddressListingFiltersBadgeHelper;
