const appAsidePinValueLocalStorageKey = "IS_ASIDE_PANEL_PINNED";

const setPinValue = (value: boolean) => {
  localStorage.setItem(appAsidePinValueLocalStorageKey, String(value));
};

const getPinValue = (): boolean | null => {
  const storedValue = localStorage.getItem(appAsidePinValueLocalStorageKey);

  if (storedValue === null) {
    return null;
  }

  return storedValue === "true" ? true : storedValue === "false" ? false : null;
};

const appAsideDao = { setPinValue, getPinValue };

export default appAsideDao;
