import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiRouteSettlementListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type TaxiRouteSettlementListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiRouteSettlementListingUserPermissionOption>;

export type TaxiRouteSettlementListingUserPermissions =
  UserPermissions<TaxiRouteSettlementListingUserPermissionOption>;

const taxiRouteSettlementListingUserPermissionDefinition: TaxiRouteSettlementListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default taxiRouteSettlementListingUserPermissionDefinition;
