import railyApiService from "../raily-api.service";
import orderOptimizerListRequestFactory from "./list/order-optimizer-list-request.factory";
import OrderOptimizerListRequest, {
  OrderOptimizerListRequestQueryParams,
} from "./list/order-optimizer-list.request";
import OrderOptimizerListResponse from "./list/order-optimizer-list.response";

const orderOptimizerApiService = () => {
  const getList = async (
    request: OrderOptimizerListRequest,
    abortSignal: AbortSignal
  ): Promise<OrderOptimizerListResponse> => {
    const url = `/optimize-all`;

    const queryParams: OrderOptimizerListRequestQueryParams =
      orderOptimizerListRequestFactory.createQueryParams(request);

    return railyApiService(
      process.env.OPTIMIZER_URL
    ).get<OrderOptimizerListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    getList,
  };
};

export default orderOptimizerApiService;
