export enum TaxiTaxiContractAddRequestBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type TaxiTaxiContractAddRequestBody = {
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: TaxiTaxiContractAddRequestBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  producerTaxiId: string;
  consumerTaxiId: string;
  displayName: string | undefined;
  priority: number | undefined;
  isPartner: boolean | undefined;
};

type TaxiTaxiContractAddRequest = {
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: TaxiTaxiContractAddRequestBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  producerTaxiId: string;
  consumerTaxiId: string;
  displayName?: string;
  priority?: number;
  isPartner?: boolean;
};

export default TaxiTaxiContractAddRequest;
