import passengerAddFormValidationService from "../../common/passenger-add-form-validation.service";

const passengerAddByCargoFormValidationService = {
  validateFirstName: passengerAddFormValidationService.validateFirstName,
  validateLastName: passengerAddFormValidationService.validateLastName,
  validateMobile: passengerAddFormValidationService.validateMobile,
  validateAlternativeMobile:
    passengerAddFormValidationService.validateAlternativeMobile,
  validateEmail: passengerAddFormValidationService.validateEmail,
  validateUsername: passengerAddFormValidationService.validateUsername,
  validatePassword: passengerAddFormValidationService.validatePassword,
  validatePasswordRepeat:
    passengerAddFormValidationService.validatePasswordRepeat,
  validateAddresses: passengerAddFormValidationService.validateAddresses,
  validateDispatches: passengerAddFormValidationService.validateDispatches,
};

export default passengerAddByCargoFormValidationService;
