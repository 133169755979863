import { FC, useMemo, useState } from "react";
import DriverContractListingByTaxiFilter from "../../common/types/driver-contract-listing-by-taxi-filter";
import { useAppContext } from "../../../../../../../../context/app.context";
import driverContractListingByTaxiFilterHelper from "../../common/filter/driver-contract-listing-by-taxi-filter.helper";
import DriverContractListingByTaxiFilterSelectOption from "../../common/types/driver-contract-listing-by-taxi-filter-select-option";
import ListingFilterSelectComponent from "../../../../../../../../common/components/listing/filter/select/listing-filter-select.component";

type DriverContractListingByTaxiFiltersSelectProps = {
  filters: DriverContractListingByTaxiFilter[];
  onAddNewFilter: (filter: DriverContractListingByTaxiFilter) => void;
};

const DriverContractListingByTaxiFiltersSelectComponent: FC<
  DriverContractListingByTaxiFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions = useMemo(() => {
    return driverContractListingByTaxiFilterHelper.getSelectOptions(
      searchInputValue,
      props.filters
    );
  }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: DriverContractListingByTaxiFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as DriverContractListingByTaxiFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
    />
  );
};

export default DriverContractListingByTaxiFiltersSelectComponent;
