import { FC, useEffect, useState } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import ModalComponent, {
  ModalProps,
} from "../../../../common/components/modal/modal.component";
import notificationService from "../../../../common/utils/notification/notification.service";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderDetailsChangeTaxiApiService from "./common/api/order-details-change-taxi-api.service";
import OrderDetailsChangeTaxiTaxiContractsResponse, {
  OrderDetailsChangeTaxiTaxiContractsResponseData,
} from "./common/api/order-details-change-taxi-taxi-contracts.response";
import orderDetailsChangeTaxiResponse from "./common/api/order-details-change-taxi.response";
import orderDetailsChangeTaxiFormValidationService from "./common/order-details-change-taxi-form-validation.service";
import orderDetailsChangeTaxiFactory from "./common/order-details-change-taxi.factory";
import orderDetailsChangeTaxiHelper from "./common/order-details-change-taxi.helper";
import OrderDetailsChangeTaxiFormData from "./common/types/order-details-change-taxi-form-data";
import OrderDetailsChangeTaxiFormValidationResults from "./common/types/order-details-change-taxi-form-validation-results";
import OrderDetailsChangeTaxiTaxiCorporationSelectOption from "./common/types/order-details-change-taxi-taxi-corporation-select-option";
import OrderDetailsChangeTaxiRequest from "./common/api/order-details-change-taxi.request";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";

type OrderDetailsChangeTaxiModalProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  taxiCorporationUuid: string | null;
  dispatcherUuid: string;
  orderUuid: string;
  cargoCompanyOrderId: number;
  cargoCompanyUuid: string;
  onSuccess: (taxiCorporationUuid: string) => void;
};

const OrderDetailsChangeTaxiModal: FC<OrderDetailsChangeTaxiModalProps> = (
  props
) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().changeTaxi;

  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<OrderDetailsChangeTaxiTaxiCorporationSelectOption[]>([]);

  const [areTaxiContractsFetching, setAreTaxiContractsFetching] =
    useState(false);

  const [isTaxiContractsFetchingError, setIsTaxiContractsFetchingError] =
    useState(false);

  const [isUpdateTaxiCorporationFetching, setIsUpdateTaxiCorporationFetching] =
    useState(false);

  const [formData, setFormData] = useState<OrderDetailsChangeTaxiFormData>(() =>
    orderDetailsChangeTaxiHelper.getDefaultFormData()
  );
  const [formValidationResults, setFormValidationResults] =
    useState<OrderDetailsChangeTaxiFormValidationResults>(() =>
      orderDetailsChangeTaxiHelper.getDefaultFormValidationResults()
    );

  const clearFormData = () => {
    setFormData(orderDetailsChangeTaxiHelper.getDefaultFormData());
  };

  const clearFormValidationResults = () => {
    setFormValidationResults(
      orderDetailsChangeTaxiHelper.getDefaultFormValidationResults()
    );
  };

  useEffect(() => {
    if (props.isOpen) {
      return;
    }

    clearFormData();
    clearFormValidationResults();
    setTaxiCorporationSelectOptions([]);
  }, [props.isOpen]);

  const setTaxiCorporation = (
    value: OrderDetailsChangeTaxiFormData["taxiCorporation"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      taxiCorporation: value,
    }));
  };

  const setCurrentTaxiCorporationSelectOption = (
    taxiCorporationUuid: string | null
  ) => {
    const currentTaxi = taxiCorporationSelectOptions.find(
      (option) => option.value.uuid === taxiCorporationUuid
    );

    if (currentTaxi) {
      setTaxiCorporation(currentTaxi);
    }
  };

  const onTaxiContractsFetchSuccess = (
    responseTaxiContractsData: OrderDetailsChangeTaxiTaxiContractsResponseData
  ) => {
    const taxiCorporationSelectOptions =
      orderDetailsChangeTaxiFactory.createTaxiCorporationsSelectOptions(
        responseTaxiContractsData
      );

    setTaxiCorporationSelectOptions(taxiCorporationSelectOptions);
  };

  useEffect(() => {
    if (taxiCorporationSelectOptions.length) {
      setCurrentTaxiCorporationSelectOption(props.taxiCorporationUuid);
      setAreTaxiContractsFetching(false);
    }
  }, [taxiCorporationSelectOptions.length]);

  const handleTaxiContractsResponse = (
    response: OrderDetailsChangeTaxiTaxiContractsResponse
  ) => {
    if (response.status === 200) {
      onTaxiContractsFetchSuccess(response.data);
      return;
    }

    setIsTaxiContractsFetchingError(true);
  };

  useEffect(() => {
    if (props.isOpen) {
      const cargoCompanyUuid = props.cargoCompanyUuid;
      setAreTaxiContractsFetching(true);

      orderDetailsChangeTaxiApiService
        .fetchTaxiContracts(cargoCompanyUuid)
        .then(handleTaxiContractsResponse);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (isTaxiContractsFetchingError) {
      props.onClose();
      notificationService.error(translations.fetchingTaxiCorporationsError);
    }
  }, [isTaxiContractsFetchingError]);

  const validateTaxiCorporation = (
    taxiCorporation: OrderDetailsChangeTaxiFormData["taxiCorporation"] = formData.taxiCorporation
  ) => {
    const validationResult =
      orderDetailsChangeTaxiFormValidationService.validateTaxiCorporation(
        taxiCorporation
      );

    setFormValidationResults((curr) => ({
      ...curr,
      taxiCorporation: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsFormValid = () => {
    const isTaxiCorporationValid = validateTaxiCorporation();

    return isTaxiCorporationValid;
  };

  const submitForm = () => {
    const prevTaxiCorporationUuid = props.taxiCorporationUuid;
    const newTaxiCorportaionUuid = formData.taxiCorporation!.value.uuid;

    if (newTaxiCorportaionUuid === prevTaxiCorporationUuid) {
      props.onClose();
      return;
    }

    const isFormValid = checkIsFormValid();

    if (!isFormValid) {
      return;
    }

    changeTaxiCorporation(props.orderUuid, newTaxiCorportaionUuid);
  };

  const changeTaxiCorporation = (
    orderUuid: string,
    newTaxiCorporationUuid: string
  ) => {
    setIsUpdateTaxiCorporationFetching(true);

    const request: OrderDetailsChangeTaxiRequest = {
      new_target_taxi_id: newTaxiCorporationUuid,
    };

    orderDetailsChangeTaxiApiService
      .updateTaxiCorporation(orderUuid, request)
      .then(handleChangeTaxiResponse)
      .catch(onChangeTaxiFailure)
      .finally(() => {
        setIsUpdateTaxiCorporationFetching(false);
        props.onClose();
      });
  };

  const onChangeTaxiSuccess = () => {
    notificationService.success(
      translations.successUpdateTaxiNotificationMessageText
    );
    props.onSuccess(formData.taxiCorporation?.value.uuid!);
  };

  const onChangeTaxiFailure = () => {
    notificationService.error(
      translations.failureUpdateTaxiNotificationMessageText
    );
  };

  const handleChangeTaxiResponse = (
    response: orderDetailsChangeTaxiResponse
  ) => {
    if (response.status === 201) {
      onChangeTaxiSuccess();
      return;
    }

    onChangeTaxiFailure();
  };

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      classNames={{
        root: `order_details_change_taxi`,
        content: `order_details_change_taxi_content`,
      }}
      header={{
        title: translations.headingText.replace(
          `#{cargoCompanyOrderId}`,
          `${props.cargoCompanyOrderId}`
        ),
      }}
      actions={[
        <ButtonComponent
          onClick={submitForm}
          type="primary"
          title={translations.form.submitButtonTitle}
          isLoading={isUpdateTaxiCorporationFetching}
        >
          {translations.form.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.form.rejectButtonTitle}
        >
          {translations.form.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <FormFieldComponent
        label={translations.form.taxiLabel}
        classNames={{ root: "mt-0" }}
        isRequired
        errorMessage={formValidationResults.taxiCorporation.errorMessage}
      >
        <SingleSelectComponent
          placeholder={translations.form.taxiPlaceholder}
          options={taxiCorporationSelectOptions}
          value={formData.taxiCorporation}
          isLoading={areTaxiContractsFetching}
          onChange={(option) => {
            setTaxiCorporation(option);
            validateTaxiCorporation(option);
          }}
          menuPlacement="bottom"
          idForTesting="taxi-corporation-select"
          hasError={!!formValidationResults.taxiCorporation.errorMessage}
          menuPosition="fixed"
        />
      </FormFieldComponent>
    </ModalComponent>
  );
};

export default OrderDetailsChangeTaxiModal;
