import Joi from "joi";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";

const validateDiscount = (
  discountValue: number,
  maxValue: number
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(maxValue).required();

  return formValidationService.validate(discountValue, validationSchema);
};

const validateHaltingTime = (
  haltingTimeValue: number | number
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(599).required();

  return formValidationService.validate(haltingTimeValue, validationSchema);
};

const billingDataAddValidationService = {
  validateDiscount,
  validateHaltingTime,
};

export default billingDataAddValidationService;
