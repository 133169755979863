import DelegationDetails from "../../../../../common/services/delegation/details/delegation-details";
import DelegationDetailsPassenger from "../types/delegation-details-passenger";

const create = (details: DelegationDetails): DelegationDetailsPassenger => {
  return {
    displayName: details.workerName,
    phoneNumber: details.workerPhone,
    uuid: details.workerUuid,
  };
};

const delegationDetailsPassengerFactory = {
  create,
};

export default delegationDetailsPassengerFactory;
