import isEqual from "lodash/isEqual";
import TaxiTransferedOrderSettlementListingFilterSelectOption, {
  TaxiTransferedOrderSettlementListingInternalOrderIdFilterSelectOption,
} from "../types/taxi-transfered-order-settlement-listing-filter-select-option";
import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiTransferedOrderSettlementListingFilterType from "../types/taxi-transfered-order-settlement-listing-filter-type";
import TaxiTransferedOrderSettlementListingFilter, {
  TaxiTransferedOrderSettlementListingOrderStartDateFilter,
} from "../types/taxi-transfered-order-settlement-listing-filter";
import TaxiTransferedOrderSettlementListingItemBillingStatus from "../types/taxi-transfered-order-settlement-listing-item-billing-status";
import taxiTransferedOrderSettlementListingHelper from "./taxi-transfered-order-settlement-listing.helper";

const getSearchQueryOptions = (
  query: string
): TaxiTransferedOrderSettlementListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByProducerTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByProducerTaxiTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByConsumerTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByConsumerTaxiTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByProducerTaxiLabel(query),
      value: {
        type: TaxiTransferedOrderSettlementListingFilterType.PRODUCER_TAXI,
        value: query,
      },
    },
    {
      label: getSearchByConsumerTaxiLabel(query),
      value: {
        type: TaxiTransferedOrderSettlementListingFilterType.CONSUMER_TAXI,
        value: query,
      },
    },
  ];

  const options: TaxiTransferedOrderSettlementListingFilterSelectOption[] = [
    ...stringOptions,
  ] as TaxiTransferedOrderSettlementListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const routeIdFilterOption: TaxiTransferedOrderSettlementListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: TaxiTransferedOrderSettlementListingFilterType.INTERNAL_ORDER_ID,
          value: Number(query),
        },
      };

    options.push(routeIdFilterOption);
  }

  return options;
};

const getSearchStatusOptions = (
  query: string
): TaxiTransferedOrderSettlementListingFilterSelectOption[] => {
  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .filters.search;

  const allSelectOptions: TaxiTransferedOrderSettlementListingFilterSelectOption[] =
    Object.values(TaxiTransferedOrderSettlementListingItemBillingStatus).map(
      (status) => {
        const label =
          searchFilterTranslations.searchByBillingStatusTemplateText.replace(
            "#{option}",
            taxiTransferedOrderSettlementListingHelper.getBillingStatusText(
              status
            )
          );

        return {
          label,
          value: {
            type: TaxiTransferedOrderSettlementListingFilterType.BILLING_STATUS,
            value: status,
          },
        };
      }
    );

  if (query) {
    return allSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allSelectOptions;
};

const getSelectOptionsByQuery = (
  query: string
): TaxiTransferedOrderSettlementListingFilterSelectOption[] => {
  const queryOptions = getSearchQueryOptions(query);
  const statusOptions = getSearchStatusOptions(query);

  return [...statusOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: TaxiTransferedOrderSettlementListingFilter[]
): TaxiTransferedOrderSettlementListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  return allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });
};

const getDefaultFilters = (
  filters: TaxiTransferedOrderSettlementListingFilter[]
): TaxiTransferedOrderSettlementListingFilter[] => {
  const currentDate = new Date();
  const timezoneOffset = currentDate.getTimezoneOffset();

  const defaultFilters = [
    {
      type: TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        to: new Date(
          new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
          ).getTime() -
            timezoneOffset * 60 * 1000
        ),
      },
    } as TaxiTransferedOrderSettlementListingOrderStartDateFilter,
  ];

  defaultFilters.forEach((defaultFilter) => {
    const existingFilter = filters.find(
      (filter) => filter.type === defaultFilter.type
    );

    if (existingFilter) {
      return;
    }

    filters.push(defaultFilter);
  });

  return filters;
};

const taxiTransferedOrderSettlementListingFilterHelper = {
  getDefaultFilters,
  getSelectOptions,
};

export default taxiTransferedOrderSettlementListingFilterHelper;
