import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiUnsettledOrderListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type TaxiUnsettledOrderListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiUnsettledOrderListingUserPermissionOption>;

export type TaxiUnsettledOrderListingUserPermissions =
  UserPermissions<TaxiUnsettledOrderListingUserPermissionOption>;

const taxiUnsettledOrderListingUserPermissionDefinition: TaxiUnsettledOrderListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default taxiUnsettledOrderListingUserPermissionDefinition;
