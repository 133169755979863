import RailyOfficerAddFormData from "./types/raily-officer-add-form-data";
import phoneNumberInputHelper from "../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import railyOfficerAddFormValidationService from "../raily-officer-add-form-validation.service";

const getDefaultFormData = (): RailyOfficerAddFormData => {
  return {
    firstName: "",
    lastName: "",
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<RailyOfficerAddFormData> => {
    return {
      alternativePhoneNumber: (values) =>
        railyOfficerAddFormValidationService.validateAlternativePhoneNumber(
          values.alternativePhoneNumber
        ),

      email: (values) =>
        railyOfficerAddFormValidationService.validateEmail(values.email),
      firstName: (values) =>
        railyOfficerAddFormValidationService.validateFirstName(
          values.firstName
        ),
      lastName: (values) =>
        railyOfficerAddFormValidationService.validateLastName(values.lastName),
      password: (values) =>
        railyOfficerAddFormValidationService.validatePassword(values.password),
      passwordRepeat: (values) =>
        railyOfficerAddFormValidationService.validatePasswordRepeat(
          values.password,
          values.passwordRepeat
        ),
      phoneNumber: (values) =>
        railyOfficerAddFormValidationService.validatePhoneNumber(
          values.phoneNumber
        ),
      username: async (values) =>
        await railyOfficerAddFormValidationService.validateUsername(
          values.username
        ),
    };
  };

const railyOfficerAddFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default railyOfficerAddFormHelper;
