import orderRouteEditPassengerValidationService from "../order-route-edit-passenger-validation.service";

const orderRouteEditExternalPassengerAddFormValidationService = {
  validateFirstName: orderRouteEditPassengerValidationService.validateFirstName,
  validateLastName: orderRouteEditPassengerValidationService.validateLastName,
  validateMobile: orderRouteEditPassengerValidationService.validateMobile,
  validateDispatchName:
    orderRouteEditPassengerValidationService.validateDispatchName,
  validateExternalId:
    orderRouteEditPassengerValidationService.validateExternalId,
};

export default orderRouteEditExternalPassengerAddFormValidationService;
