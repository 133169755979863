import TaxiOfficerAddParams from "../../../../../../common/services/taxi-officer/add/taxi-officer-add-params";
import TaxiOfficerAddByTaxiFormData from "./types/taxi-officer-add-by-taxi-form-data";
import User from "../../../../../../common/types/user";

const create = (
  formData: TaxiOfficerAddByTaxiFormData,
  user: User
): TaxiOfficerAddParams => {
  const taxiCorporationUuid = user.aspects.taxiOfficer?.taxiCorporationUuid!;

  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    username: formData.username,
    password: formData.password,
    phoneNumber: formData.phoneNumber,
    alternativePhoneNumber: formData.alternativePhoneNumber,
    taxiCorporationUuid,
  };
};

const taxiOfficerAddByTaxiParamsFactory = {
  create,
};

export default taxiOfficerAddByTaxiParamsFactory;
