import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import PhoneNumber from "../../../types/phone-number";

export enum RouteDetailsSolvedOrderRideSequenceOrder {
  STRICT = "STRICT",
}

export type RouteDetailsSolvedOrderRide = {
  seq: RouteDetailsAddressNode[];
  sequenceOrder: RouteDetailsSolvedOrderRideSequenceOrder;
};

export type RouteDetailsSolvedOrderTransportingOrder = {
  uuid: string;
};

export type RouteDetailsSolvedOrderCargoCompany = {
  taxNumber: string;
  companyId: string | null;
  nationalCourtRegister: string | null;
  companyName: string;
  displayName: string;
  address: string;
  contractMaintenancePolicy: ContractMaintenancePolicy;
  notes: string | null;
  uuid: string;
};

export type RouteDetailsSolvedOrder = {
  involvedPassengerUuids: string[];
  ride: RouteDetailsSolvedOrderRide;
  performanceRegion: string;
  uuid: string;
  transportingOrder: RouteDetailsSolvedOrderTransportingOrder;
  cargoCompany: RouteDetailsSolvedOrderCargoCompany;
  excludeHighway: boolean;
};

export type RouteDetailsNodeExclusion = {
  nodeUuids: string[];
  excludeApproaching: boolean;
  excludeReturning: boolean;
  excludeInside: boolean;
};

export type RouteDetailsAddressNode = {
  uuid: string;
  latitude: number;
  longitude: number;
  plannedDate: Date | null;
  haltingTime: number | null;
  displayName: string | null;
  onboardingPassengersUuids: string[];
  outboardingPassengersUuids: string[];
  estimatedTime: Date;
};

export type RouteDetailsCheckoutEvent = {
  uuid: string;
  nodeUuid: string;
  arrivalDate: Date;
  stoppingRate: number | null;
  highwayCharge: number | null;
};

export type RouteDetailsTaxiCorporation = {
  uuid: string;
  displayName: string;
};

export type RouteDetailsPassenger = {
  uuid: string;
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
};

export type RouteDetailsDriver = {
  uuid: string;
  firstName: string;
  lastName: string;
};

type RouteDetails = {
  modificationDate: Date | null;
  modifiedBy: string | null;
  creationDate: Date;
  createdBy: string;
  uuid: string;
  solvedOrders: RouteDetailsSolvedOrder[];
  nodeExclusions: RouteDetailsNodeExclusion[];
  startNode: RouteDetailsAddressNode;
  endNode: RouteDetailsAddressNode;
  checkoutsCount: number;
  checkoutEvents: RouteDetailsCheckoutEvent[];
  taxiCorporation: RouteDetailsTaxiCorporation;
  humanId: number | null;
  passengers: RouteDetailsPassenger[];
  driver: RouteDetailsDriver;
};

export default RouteDetails;
