import CargoCompanyPassengerListResponse, {
  CargoCompanyPassengerListResponseItem,
  CargoCompanyPassengerListResponseItemAddress,
} from "../../../../api/raily/cargo-company/passenger/list/cargo-company-passenger-list.response";
import {
  CargoCompanyPassengerListItem,
  CargoCompanyPassengerListItemAddress,
} from "./cargo-company-passenger-list";

const createAddress = (
  address: CargoCompanyPassengerListResponseItemAddress
): CargoCompanyPassengerListItemAddress => {
  return {
    displayName: address.displayName,
    lat: address.lat,
    lon: address.lon,
    apartmentNumber: address.apartment,
    houseNumber: address.houseNo,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createAddresses = (
  addresses: CargoCompanyPassengerListResponseItemAddress[]
): CargoCompanyPassengerListItemAddress[] => {
  return addresses.map(createAddress);
};

const createPassengerListItem = (
  item: CargoCompanyPassengerListResponseItem
): CargoCompanyPassengerListItem => {
  return {
    addresses: createAddresses(item.addresses),
    displayName: `${item.user.firstName} ${item.user.lastName}`,
    uuid: item.user.id,
  };
};

const createPassengerList = (
  response: CargoCompanyPassengerListResponse
): CargoCompanyPassengerListItem[] => {
  return response.data.map(createPassengerListItem);
};

const cargoCompanyPassengerListFactory = {
  createPassengerList,
};

export default cargoCompanyPassengerListFactory;
