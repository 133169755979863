import BillingsOrderAddresses from "../../../../../common/services/billings/order/addresses/billings-order-addresses";
import BillingsNode from "../../types/billings-node";
import BillingsRouteDetailsAddress from "../types/billings-route-details-address";
import BillingsRouteDetailsAddressSelectOption from "../types/billings-route-details-address-select-option";

const createBillingAddress = (
  responseCargoAddress: BillingsNode
): BillingsRouteDetailsAddress => {
  return {
    displayName: responseCargoAddress.description,
    latitude: responseCargoAddress.lat,
    longitude: responseCargoAddress.lon,
  };
};

const createBillingAddressesSelectOptions = (
  billingOrderAddresses: BillingsOrderAddresses
): BillingsRouteDetailsAddressSelectOption[] => {
  return billingOrderAddresses.map((address) => ({
    label: address.displayName,
    value: {
      displayName: address.description ?? "",
      latitude: address.lat,
      longitude: address.lon,
    },
  }));
};

const createAddressSelectOption = (
  address: BillingsRouteDetailsAddress
): BillingsRouteDetailsAddressSelectOption => {
  const selectOption: BillingsRouteDetailsAddressSelectOption = {
    label: address.displayName,
    value: {
      latitude: address.latitude,
      longitude: address.longitude,
      displayName: address.displayName,
    },
  };
  return selectOption;
};

const billingsRouteDetailsFactory = {
  createAddressSelectOption,
  createBillingAddress,
  createBillingAddressesSelectOptions,
};

export default billingsRouteDetailsFactory;
