import BillingModel from "../../../types/billing-model";
import { BillingData } from "../api/billing-data.response";
import BillingFormData from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date
        ? new Date(billingNode.checkout_date)
        : null,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date
        ? new Date(billingNode.planned_date)
        : null,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    contractDetails: {
      companyName: data.contract.cargo_company.company_name,
      discountLimit: data.contract.discount_limit,
      haltingTimeRate: data.contract.halting_time_rate,
      distanceRate: data.contract.distance_rate,
      isHighwayAllowedDuringRide: data.contract.allow_charge_during_ride,
      isHighwayAllowedWhenApproaching:
        data.contract.allow_charge_while_approaching,
      isHighwayAllowedWhileReturning:
        data.contract.allow_charge_while_returning,
      model: data.contract.model as BillingModel,
      haltingTimeAppliedAfter: data.contract.halting_time_after_minutes,
    },
    baseAmount: data.base_amount,
    discountAmount: data.discount_amount,
    haltingCost: data.halting_amount,
    highwayCharge: data.highway_charge,
    distance: data.distance,
    routeDistanceCost: data.distance_amount,
    internalOrderId: data.cargo_order.human_id,
    discount: data.discount,
    total: data.total_amount,
    planEntryId: data.plan_entry_id,
    cargoOrderId: data.cargo_order.id,
  };

  return billingDraft;
};

const billingDataFactory = {
  createBillingData,
};

export default billingDataFactory;
