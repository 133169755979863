import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";

const validateName = (name: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).required();

  return formValidationService.validate(name, validationSchema);
};

const taxiFleetPartnerEditFormValidationService = {
  validateName,
};

export default taxiFleetPartnerEditFormValidationService;
