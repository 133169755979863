import SignInRequest from "../../../api/raily/auth/sign-in/sign-in.request";
import SignInParams from "./sign-in-params";

const create = (params: SignInParams): SignInRequest => {
  return {
    password: params.password,
    username: params.username.trim(),
  };
};

const signInRequestFactory = { create };

export default signInRequestFactory;
