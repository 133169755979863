import CargoOfficerAddParams from "../../../../../../common/services/cargo-officer/add/cargo-officer-add-params";
import CargoOfficerAddByRailyFormData from "./types/cargo-officer-add-by-raily-form-data";

const create = (
  formData: CargoOfficerAddByRailyFormData
): CargoOfficerAddParams => {
  return {
    alternativePhoneNumber: formData.alternativePhoneNumber,
    cargoCompanyUuid: formData.cargoCompanyUuid,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    password: formData.password,
    phoneNumber: formData.phoneNumber,
    username: formData.username,
  };
};

const cargoOfficerAddByRailyParamsFactory = {
  create,
};

export default cargoOfficerAddByRailyParamsFactory;
