import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import MileageAddVehicleType from "../types/mileage-add-vehicle-type";
import MileageAddVehicleTypeSelectOption from "../types/mileage-add-vehicle-type-select-option";

const vehicleTypeLabel = (type: MileageAddVehicleType): string => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().vehicleType;
  switch (type) {
    case MileageAddVehicleType.COMPANY_OWNED:
      return translations.typeCompanyLabel;
    case MileageAddVehicleType.CAR_GT_900CM3:
      return translations.typeOwnGT900;
    case MileageAddVehicleType.CAR_LE_900CM3:
      return translations.typeOwnLE900;
    case MileageAddVehicleType.MOPED:
      return translations.typeMoped;
    case MileageAddVehicleType.MOTORCYCLE:
      return translations.typeMotorcycle;
  }
};

const createSelectOption = (
  type: MileageAddVehicleType
): MileageAddVehicleTypeSelectOption => {
  return {
    label: vehicleTypeLabel(type),
    value: type,
    idForTesting: `mileage-add-vehicle-type-${String(type)}-select-option`,
  };
};

const createSelectOptions = (): MileageAddVehicleTypeSelectOption[] => {
  return Object.keys(MileageAddVehicleType).map((value) =>
    createSelectOption(value as MileageAddVehicleType)
  );
};

const mileageAddVehicleTypeFactory = {
  createSelectOptions,
  createSelectOption,
};

export default mileageAddVehicleTypeFactory;
