export enum MileageContractListLoadParamsOrder {
  COMPANY_NAME_ASC = "COMPANY_NAME_ASC",
  COMPANY_NAME_DESC = "COMPANY_NAME_DESC",
  VALID_SINCE_ASC = "VALID_SINCE_ASC",
  VALID_SINCE_DESC = "VALID_SINCE_DESC",
  VALID_TO_ASC = "VALID_TO_ASC",
  VALID_TO_DESC = "VALID_TO_DESC",
}

type MileageContractListLoadParams = {
  page?: number;
  pageSize?: number;
  isValid?: boolean;
  cargoCompanyUuid?: string;
  cargoCompanyName?: string;
  order?: MileageContractListLoadParamsOrder;
};

export default MileageContractListLoadParams;
