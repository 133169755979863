import React, {
  useEffect,
  useRef,
  useCallback,
  FC,
  ReactNode,
  useState,
  RefObject,
} from "react";
import classNames from "classnames";
import ComponentClassnames from "../../types/component-classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "../button/button.component";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import appTranslationsHelper from "../../../languages/app-translations.helper";

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  header: {
    title: string;
  };
  actions?: ReactNode[];
  classNames?: ComponentClassnames & {
    content?: string;
  };
  closeConfirmation?: {
    title: string;
    messageText: string;
    shouldBeVisibleOnCloseClick: boolean;
  };
  renderRef?: RefObject<HTMLDivElement>;
  containerRef?: RefObject<HTMLDivElement>;
};

const ModalComponent: FC<ModalProps> = (props) => {
  const modalComponentTranslations =
    appTranslationsHelper.getComponentTranslations().modal;

  const modalRef = props.renderRef ?? useRef<HTMLDivElement>(null);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalWrapperRef = useRef<HTMLDivElement>(null);

  const [isCloseConfirmationVisible, setIsCloseConfirmationVisible] =
    useState(false);

  const onCloseClick = () => {
    if (props.closeConfirmation?.shouldBeVisibleOnCloseClick) {
      setIsCloseConfirmationVisible(true);
      return;
    }

    props.onClose();
  };

  const onCloseConfirm = () => {
    props.onClose();
    setIsCloseConfirmationVisible(false);
  };

  const onCloseReject = () => {
    setIsCloseConfirmationVisible(false);
  };

  const onEscapeKeyClick = () => {
    if (isCloseConfirmationVisible) {
      onCloseReject();
      return;
    }

    onCloseClick();
  };

  const escapeKeyHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onEscapeKeyClick();
      }
    },
    [props, isCloseConfirmationVisible]
  );

  useEffect(() => {
    if (props.isOpen) {
      modalRef?.current?.focus();
      document.addEventListener("keydown", escapeKeyHandler, false);

      return () => {
        document.removeEventListener("keydown", escapeKeyHandler, false);
      };
    }
  }, [props.isOpen, escapeKeyHandler]);

  const modalBackgroundClassname = classNames(
    "modal__background",
    props.isOpen && "is-open"
  );
  const modalClassname = classNames("modal", props.classNames?.root);

  const onModalClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const actions = props.actions?.filter((actionItem) => actionItem);

  return (
    <div
      className={modalBackgroundClassname}
      onMouseDown={() => onCloseClick()}
    >
      <div
        className={modalClassname}
        ref={modalRef}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className="modal__wrapper" ref={modalWrapperRef}>
          <div className="modal__header" ref={modalHeaderRef}>
            {props.header.title && (
              <h4 className="modal__title">{props.header.title}</h4>
            )}
            <div className="modal__close">
              <ButtonComponent
                onClick={onCloseClick}
                classNames={{ root: "modal__close__button" }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  className="modal__close__icon"
                />
              </ButtonComponent>
            </div>
          </div>
          <div className="modal__container" onClick={onModalClick}>
            <div
              className={classNames(
                "modal__content",
                props.classNames?.content
              )}
              ref={props.containerRef}
            >
              {props.children}
            </div>
            {actions?.length && (
              <div className="modal__cta">
                {actions.map((action, index) => (
                  <span key={index} className="modal__cta__item">
                    {action}
                  </span>
                ))}
              </div>
            )}
          </div>
          {isCloseConfirmationVisible && props.closeConfirmation && (
            <div className="modal_overlay">
              <div className="modal_close_confirmation">
                <div>
                  <h1 className="modal_close_confirmation__title">
                    {props.closeConfirmation.title}
                  </h1>
                  <div className="modal_close_confirmation__message">
                    {props.closeConfirmation.messageText}
                  </div>
                </div>

                <div className="modal_close_confirmation__buttons_wrapper">
                  <ButtonComponent
                    onClick={onCloseConfirm}
                    type="success"
                    classNames={{ root: "modal_close_confirmation__button" }}
                    title={
                      modalComponentTranslations.confirmationAcceptButtonTitle
                    }
                  >
                    {modalComponentTranslations.confirmationAcceptButtonText}
                  </ButtonComponent>
                  <ButtonComponent
                    onClick={onCloseReject}
                    type="danger"
                    classNames={{ root: "modal_close_confirmation__button" }}
                    title={
                      modalComponentTranslations.confirmationRejectButtonTitle
                    }
                  >
                    {modalComponentTranslations.confirmationRejectButtonText}
                  </ButtonComponent>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
