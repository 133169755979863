import { FC, useMemo } from "react";
import BooleanSelectOption from "./boolean-select-option";
import booleanSelectHelper from "./boolean-select.helper";
import { useAppContext } from "../../../../../context/app.context";
import SingleSelectComponent from "../single-select/single-select.component";

type BooleanSelectProps = {
  value: boolean | null;
  onChange: (value: boolean | null) => void;
  placeholder: string;
  onBlur?: () => void;
  isError?: boolean;
  isDisabled?: boolean;
  idForTesting?: string;
};

const BooleanSelectComponent: FC<BooleanSelectProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const options: BooleanSelectOption[] = useMemo(() => {
    return booleanSelectHelper.getOptions();
  }, [selectedAppLanguage]);

  const selectedOption: BooleanSelectOption | null = useMemo(() => {
    return options.find((option) => option.value === props.value) ?? null;
  }, [options, props.value]);

  return (
    <SingleSelectComponent
      value={selectedOption}
      options={options}
      onChange={(option) => props.onChange(option?.value ?? null)}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      hasError={props.isError}
      isDisabled={props.isDisabled}
      idForTesting={props.idForTesting}
      isSearchable
    />
  );
};

export default BooleanSelectComponent;
