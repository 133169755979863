import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import ButtonComponent from "../../../../../../../common/components/button/button.component";
import Column from "../../../../../../../common/components/grid/column";
import Row from "../../../../../../../common/components/grid/row";
import MapComponent from "../../../../../../../common/components/map/map.component";
import MapMarker from "../../../../../../../common/components/map/types/map-marker";
import TableDeleteButtonComponent from "../../../../../../../common/components/table/button/delete/table-delete-button.component";
import TableComponent from "../../../../../../../common/components/table/table.component";
import TableRow from "../../../../../../../common/components/table/types/table-row";
import appTranslationsHelper from "../../../../../../../languages/app-translations.helper";
import DriverAddAddress from "../../types/driver-add-address";
import driverAddUserDataAddressFactory from "./common/driver-add-user-data-address.factory";
import driverAddUserDataAddressHelper from "./common/driver-add-user-data-address.helper";
import DriverAddUserAddAddressListingTableColumn from "./common/types/driver-add-user-data-address-listing-table-column";
import DriverAddUserAddAddressListingTableRow from "./common/types/driver-add-user-data-address-listing-table-row";
import DriverAddressFormComponent from "../../../../common/address-form/driver-address-form.component";
import AddressType from "../../../../../../../common/types/address-type";

type DriverAddUserDataAddressProps = {
  addresses: DriverAddAddress[];
  onRemoveAddressButtonClick: (index: number) => void;
  onAddNewAddress: (address: DriverAddAddress) => void;
};

const DriverAddUserDataAddressComponent: FC<DriverAddUserDataAddressProps> = (
  props
) => {
  const userDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().userDataForm;

  const [isAddNewAddressModalOpen, setIsAddNewAddressModalOpen] =
    useState(false);

  const mapMarkers: MapMarker[] =
    driverAddUserDataAddressFactory.createMapMarkers(props.addresses);

  const openAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(true);
  };

  const closeAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(false);
  };

  const addressTypeIconDefinitions: {
    type: AddressType;
    icon: IconProp;
  }[] = [
    {
      icon: faEnvelope,
      type: AddressType.MAILING,
    },
    {
      icon: faHouse,
      type: AddressType.STARTING,
    },
  ];

  const tableColumns: DriverAddUserAddAddressListingTableColumn[] = [
    {
      accessor: "address",
      header: userDataFormTranslations.addressTableHeader.addressName,
      title: userDataFormTranslations.addressTableHeader.addressName,
      colSpan: 4,
    },
    {
      accessor: "actions",
      header: "Actions",
      title: "",
    },
  ];

  const createTableRow = (
    address: DriverAddAddress,
    index: number
  ): DriverAddUserAddAddressListingTableRow => {
    const icon = addressTypeIconDefinitions.find(
      (definition) => definition.type === address.type
    )?.icon;

    return {
      id: String(index),
      value: {
        address: (
          <div className="driver_add_user_add_address_table_row address">
            {icon && <FontAwesomeIcon icon={icon} className="icon" />}
            <div className="text_ellipsis">
              {driverAddUserDataAddressHelper.getAddressLabel(address)}
            </div>
          </div>
        ),
        actions: (
          <TableDeleteButtonComponent
            onClick={() => props.onRemoveAddressButtonClick(index)}
          />
        ),
      },
    };
  };

  const tableRows: TableRow[] = props.addresses.map(createTableRow);

  return (
    <div className="driver_add_user_add_address">
      <Row>
        <Column xl={4}>
          <TableComponent columns={tableColumns} rows={tableRows} />
          <div className="driver_add_user_add_address__add_button_wrapper">
            <ButtonComponent
              onClick={openAddNewAddressModal}
              title={userDataFormTranslations.addNewAddressButtonTitle}
              type="primary"
              idForTesting="user-data-open-add-new-address-modal-button"
            >
              {userDataFormTranslations.addNewAddressButtonText}
            </ButtonComponent>
          </div>
        </Column>
        <Column xl={8}>
          <div className="driver_add_user_add_address__map_wrapper">
            <MapComponent markers={mapMarkers} autoFit autoFitOnUpdate />
          </div>
        </Column>
      </Row>
      <DriverAddressFormComponent
        isOpen={isAddNewAddressModalOpen}
        onClose={closeAddNewAddressModal}
        onAddNewAddress={props.onAddNewAddress}
      />
    </div>
  );
};

export default DriverAddUserDataAddressComponent;
