import MileageDetailsFormData from "./types/mileage-details-form-data";

const create = (distance: number | null): MileageDetailsFormData => {
  return {
    acceptedDistance: distance,
  };
};

const mileageDetailsFormFactory = {
  create,
};

export default mileageDetailsFormFactory;
