export enum MileageUpdateParamsStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DELEGATION_CREATED = "DELEGATION_CREATED",
}

export enum MileageUpdateParamsVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type MileageUpdateParamsNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
  distance?: number;
};

type MileageUpdateParams = {
  mileageUuid: string;
  mileageDate?: Date;
  distance?: number;
  state?: MileageUpdateParamsStatus;
  vehicleType?: MileageUpdateParamsVehicleType;
  updateReason?: string;
  addressSequence?: MileageUpdateParamsNode[];
  acceptedDistance?: number;
  osrmDistance?: number;
};

export default MileageUpdateParams;
