import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import orderAddRoutesHelper from "../components/routes/order-add-routes.helper";
import orderAddHelper from "../helper/order-add.helper";
import OrderAddCargoCompanySelectOption from "../types/order-add-cargo-company-select-option";
import OrderAddDispatcherSelectOption from "../types/order-add-dispatcher-select-option";
import OrderAddPassengerListItem from "../types/order-add-passenger-list-item";
import OrderAddRouteItem, {
  OrderAddRouteWaypoint,
  OrderAddRouteWaypointGroup,
  OrderAddRouteWaypointGroupWaypoint,
} from "../types/order-add-route-waypoint";
import OrderAddTaxiCorporationSelectOption from "../types/order-add-taxi-corporation-select-option";
import OrderAddValidationError from "./order-add-validation-error";

const validationRules = {
  maxPassengersInCarPerWaypoint: 4,
};

const validateContractor = (
  cargoCompany: OrderAddCargoCompanySelectOption | null
) => {
  const validationSchema = Joi.any().not(null).required();

  return formValidationService.validate(cargoCompany, validationSchema);
};

const validateDispatcher = (
  dispatcher: OrderAddDispatcherSelectOption | null
) => {
  const validationSchema = Joi.any().not(null).required();

  return formValidationService.validate(dispatcher, validationSchema);
};

const validateOrderNumber = (orderNumber: string) => {
  const validationSchema = Joi.string().max(50).allow("").required();

  return formValidationService.validate(orderNumber, validationSchema);
};

const validateTaxiCorporation = (
  taxiCorporation: OrderAddTaxiCorporationSelectOption | null
) => {
  const validationSchema = Joi.any().not(null).required();

  return formValidationService.validate(taxiCorporation, validationSchema);
};

const validateNumberOfWaypoints = (waypoints: OrderAddRouteItem[]): boolean => {
  return waypoints.length > 1;
};

const validateEachWaypointGroupHasFilledAddresses = (
  waypointsGroup: OrderAddRouteWaypointGroup
): boolean => {
  return waypointsGroup.waypoints.every((waypoint) => waypoint.address);
};

const validateEachWaypointHasFilledAddress = (
  waypoint: OrderAddRouteWaypoint
): boolean => {
  return !!waypoint.address;
};

const validateEachRouteItemHasFilledAddress = (
  routeItems: OrderAddRouteItem[]
): boolean => {
  return !!routeItems.every((routeItem) => {
    const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      return validateEachWaypointHasFilledAddress(routeItem);
    }

    return validateEachWaypointGroupHasFilledAddresses(routeItem);
  });
};

const validateEachWaypointHasRightAmountOfPassengersInCar = (
  passengerList: OrderAddPassengerListItem[],
  routeItems: OrderAddRouteItem[]
): boolean => {
  return !!routeItems.every((waypoint) => {
    const amountOfPassengersInCar =
      orderAddRoutesHelper.getAmountOfPassengersInCar(
        passengerList,
        waypoint,
        routeItems
      );

    return (
      amountOfPassengersInCar <= validationRules.maxPassengersInCarPerWaypoint
    );
  });
};

const validateAllPassengersAreOnboarded = (
  passengerList: OrderAddPassengerListItem[],
  routeItems: OrderAddRouteItem[]
): boolean => {
  return passengerList.every((passengerListItem) => {
    const passengerOnboardingWaypoint = routeItems.find((routeItem) => {
      const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

      if (isWaypoint) {
        return !!routeItem.onboardingPassengerListItems.find(
          (onboardingPassengerListItem) =>
            onboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      }

      return routeItem.waypoints.some(
        (waypoint) =>
          !!waypoint.onboardingPassengerListItems.find(
            (onboardingPassengerListItem) =>
              onboardingPassengerListItem.uuid === passengerListItem.uuid
          )
      );
    });
    const passengerOutboardingWaypoint = routeItems.find((routeItem) => {
      const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

      if (isWaypoint) {
        return !!routeItem.outboardingPassengerListItems.find(
          (outboardingPassengerListItem) =>
            outboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      }

      return routeItem.waypoints.some(
        (waypoint) =>
          !!waypoint.outboardingPassengerListItems.find(
            (outboardingPassengerListItem) =>
              outboardingPassengerListItem.uuid === passengerListItem.uuid
          )
      );
    });

    return passengerOnboardingWaypoint || passengerOutboardingWaypoint;
  });
};

const validateAllPassengersAreOnboardedRight = (
  passengerList: OrderAddPassengerListItem[],
  routeItems: OrderAddRouteItem[]
): boolean => {
  return passengerList.every((passengerListItem) => {
    const passengerOnboardingRouteItems = routeItems.filter((routeItem) => {
      const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

      if (isWaypoint) {
        return !!routeItem.onboardingPassengerListItems.find(
          (onboardingPassengerListItem) =>
            onboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      }

      return !!routeItem.waypoints.some((waypoint) =>
        waypoint.onboardingPassengerListItems.find(
          (onboardingPassengerListItem) =>
            onboardingPassengerListItem.uuid === passengerListItem.uuid
        )
      );
    });

    const passengerOutboardingRouteItems = routeItems.filter((routeItem) => {
      const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

      if (isWaypoint) {
        return !!routeItem.outboardingPassengerListItems.find(
          (outboardingPassengerListItem) =>
            outboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      }

      return routeItem.waypoints.some(
        (waypoint) =>
          !!waypoint.outboardingPassengerListItems.find(
            (outboardingPassengerListItem) =>
              outboardingPassengerListItem.uuid === passengerListItem.uuid
          )
      );
    });

    const areAllPassengersOnboardedRight = passengerOnboardingRouteItems.length
      ? passengerOnboardingRouteItems.every((onboardingWaypoint) => {
          const passengersInCar = orderAddRoutesHelper.getListOfPassengersInCar(
            passengerList,
            onboardingWaypoint,
            routeItems
          );

          const isPassengerInCar = passengersInCar.find(
            (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
          );

          return !isPassengerInCar;
        })
      : false;

    const areAllPassengersOutboardedRight =
      passengerOutboardingRouteItems.length
        ? passengerOutboardingRouteItems.every((outboardingWaypoint) => {
            const passengersInCar =
              orderAddRoutesHelper.getListOfPassengersInCar(
                passengerList,
                outboardingWaypoint,
                routeItems
              );

            const isPassengerInCar = passengersInCar.find(
              (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
            );

            return isPassengerInCar;
          })
        : false;

    return areAllPassengersOnboardedRight && areAllPassengersOutboardedRight;
  });
};

const validateIsDateAtLeastInOneWaypoint = (
  routeItems: OrderAddRouteItem[]
): boolean => {
  return routeItems.some((routeItem) => {
    const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      return !!routeItem.date;
    }

    return false;
  });
};

const validateDatesAreValid = (routeItems: OrderAddRouteItem[]): boolean => {
  let lastDate: Date | null = null;
  let isOk = true;

  for (const routeItem of routeItems) {
    const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

    if (!isWaypoint) {
      continue;
    }

    if (!routeItem.date) {
      continue;
    }

    if (!lastDate) {
      lastDate = routeItem.date;
      continue;
    }

    if (lastDate.getTime() >= routeItem.date.getTime()) {
      isOk = false;
      break;
    }

    lastDate = routeItem.date;
  }

  return isOk;
};

const validateWaypointHasPassengerAction = (
  waypoint: OrderAddRouteWaypoint
): boolean => {
  return (
    !!waypoint.onboardingPassengerListItems.length ||
    !!waypoint.outboardingPassengerListItems.length
  );
};

const validateWaypointGroupWaypointHasPassengerAction = (
  waypoint: OrderAddRouteWaypointGroupWaypoint
): boolean => {
  return (
    !!waypoint.onboardingPassengerListItems.length ||
    !!waypoint.outboardingPassengerListItems.length
  );
};

const validateWaypointGroupHasPassengerAction = (
  waypointGroup: OrderAddRouteWaypointGroup
): boolean => {
  return waypointGroup.waypoints.every(
    validateWaypointGroupWaypointHasPassengerAction
  );
};

const validateRouteItemHasPassengerAction = (routeItem: OrderAddRouteItem) => {
  const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

  if (isWaypoint) {
    return validateWaypointHasPassengerAction(routeItem);
  }

  return validateWaypointGroupHasPassengerAction(routeItem);
};

const validateEachRouteItemHasPassengerAction = (
  routeItems: OrderAddRouteItem[]
): boolean => {
  return routeItems.every(validateRouteItemHasPassengerAction);
};

const validateRoutesForSolvedOrderFetch = (
  passengerList: OrderAddPassengerListItem[],
  routeItems: OrderAddRouteItem[]
) => {
  const errors: OrderAddValidationError[] = [];

  const isValidNumberOfWaypoints = validateNumberOfWaypoints(routeItems);

  if (!isValidNumberOfWaypoints) {
    errors.push(OrderAddValidationError.TOO_LESS_WAYPOINTS);
  }

  const isValidPlaceIntoEachWaypoint =
    validateEachRouteItemHasFilledAddress(routeItems);

  if (!isValidPlaceIntoEachWaypoint) {
    errors.push(OrderAddValidationError.NO_PLACE_IN_ONE_OF_WAYPOINTS);
  }

  const isValidAmountOfPassengersInCarIntoEachWaypoint =
    validateEachWaypointHasRightAmountOfPassengersInCar(
      passengerList,
      routeItems
    );

  if (!isValidAmountOfPassengersInCarIntoEachWaypoint) {
    errors.push(
      OrderAddValidationError.TOO_MANY_PASSENGERS_IN_CAR_IN_ONE_OF_WAYPOINTS
    );
  }

  const isDateGivenAtLeastInOneWaypoint =
    validateIsDateAtLeastInOneWaypoint(routeItems);

  if (!isDateGivenAtLeastInOneWaypoint) {
    errors.push(OrderAddValidationError.NO_ONE_WAYPOINT_HAS_DATE);
  }

  const areDatesValid = validateDatesAreValid(routeItems);

  if (!areDatesValid) {
    errors.push(OrderAddValidationError.NOT_VALID_DATES_IN_WAYPOINTS);
  }

  return errors;
};

const validateRoutes = (
  passengerList: OrderAddPassengerListItem[],
  routeItems: OrderAddRouteItem[]
): OrderAddValidationError[] => {
  const errors: OrderAddValidationError[] = [];

  const isValidNumberOfWaypoints = validateNumberOfWaypoints(routeItems);

  if (!isValidNumberOfWaypoints) {
    errors.push(OrderAddValidationError.TOO_LESS_WAYPOINTS);
  }

  let currentDate = new Date();

  const isAnyDateFromRouteItemsPastDate = routeItems
    .filter((x) => (x as OrderAddRouteWaypoint).date != null)
    .some((x) => (x as OrderAddRouteWaypoint).date! < currentDate);

  if (isAnyDateFromRouteItemsPastDate) {
    errors.push(OrderAddValidationError.PAST_DATE_IN_WAYPOINTS);
  }

  const isValidPlaceIntoEachWaypoint =
    validateEachRouteItemHasFilledAddress(routeItems);

  if (!isValidPlaceIntoEachWaypoint) {
    errors.push(OrderAddValidationError.NO_PLACE_IN_ONE_OF_WAYPOINTS);
  }

  const isValidAmountOfPassengersInCarIntoEachWaypoint =
    validateEachWaypointHasRightAmountOfPassengersInCar(
      passengerList,
      routeItems
    );

  if (!isValidAmountOfPassengersInCarIntoEachWaypoint) {
    errors.push(
      OrderAddValidationError.TOO_MANY_PASSENGERS_IN_CAR_IN_ONE_OF_WAYPOINTS
    );
  }

  const areAllPassengersAreUsed = validateAllPassengersAreOnboarded(
    passengerList,
    routeItems
  );

  if (!areAllPassengersAreUsed) {
    errors.push(OrderAddValidationError.NOT_EVERY_PASSENGER_IS_ONBOARDED);
  }

  const arePassengersOnboardedRight = validateAllPassengersAreOnboardedRight(
    passengerList,
    routeItems
  );

  if (!arePassengersOnboardedRight) {
    errors.push(OrderAddValidationError.NOT_EVERY_PASSENGER_IS_ONBOARDED_RIGHT);
  }

  const isDateGivenAtLeastInOneWaypoint =
    validateIsDateAtLeastInOneWaypoint(routeItems);

  if (!isDateGivenAtLeastInOneWaypoint) {
    errors.push(OrderAddValidationError.NO_ONE_WAYPOINT_HAS_DATE);
  }

  const areDatesValid = validateDatesAreValid(routeItems);

  if (!areDatesValid) {
    errors.push(OrderAddValidationError.NOT_VALID_DATES_IN_WAYPOINTS);
  }

  const arePassengersActionsValid =
    validateEachRouteItemHasPassengerAction(routeItems);

  if (!arePassengersActionsValid) {
    errors.push(
      OrderAddValidationError.NOT_EVERY_WAYPOINT_HAS_PASSENGER_ACTION
    );
  }

  return errors;
};

const resolveValidationErrorLabels = (
  errors: OrderAddValidationError[]
): string[] => {
  const translations =
    orderTranslationsHelper.getAddTranslations().validation.errors;

  const errorOptions: { error: OrderAddValidationError; label: string }[] = [
    {
      error: OrderAddValidationError.TOO_LESS_WAYPOINTS,
      label: translations.toLessWaypoints,
    },
    {
      error: OrderAddValidationError.NO_PLACE_IN_ONE_OF_WAYPOINTS,
      label: translations.noPlaceInOneOfWaypoints,
    },
    {
      error:
        OrderAddValidationError.TOO_MANY_PASSENGERS_IN_CAR_IN_ONE_OF_WAYPOINTS,
      label: translations.tooManyPassengersInCarInOneOfWaypoints,
    },
    {
      error: OrderAddValidationError.NOT_EVERY_PASSENGER_IS_ONBOARDED,
      label: translations.notEveryPassengerIsOnboarded,
    },
    {
      error: OrderAddValidationError.NOT_EVERY_PASSENGER_IS_ONBOARDED_RIGHT,
      label: translations.notEveryPassengerIsOnboardedRight,
    },
    {
      error: OrderAddValidationError.NO_ONE_WAYPOINT_HAS_DATE,
      label: translations.noOneWaypointHasDate,
    },
    {
      error: OrderAddValidationError.NOT_VALID_DATES_IN_WAYPOINTS,
      label: translations.notValidDatesInWaypoints,
    },
    {
      error: OrderAddValidationError.NOT_EVERY_WAYPOINT_HAS_PASSENGER_ACTION,
      label: translations.notEveryWaypointHasPassengerAction,
    },
    {
      error: OrderAddValidationError.PAST_DATE_IN_WAYPOINTS,
      label: translations.pastDateInWaypoints,
    },
  ];

  const resolvedErrors: string[] = [];

  errors.forEach((error) => {
    const errorLabel = errorOptions.find(
      (errorOption) => errorOption.error === error
    )?.label;

    if (!errorLabel) {
      return;
    }

    resolvedErrors.push(errorLabel);
  });

  return resolvedErrors;
};

const orderAddValidationService = {
  validateContractor,
  validateDispatcher,
  validateTaxiCorporation,
  validateRoutes,
  validateEachWaypointHasRightAmountOfPassengersInCar,
  resolveValidationErrorLabels,
  validateRoutesForSolvedOrderFetch,
  validateOrderNumber,
};

export default orderAddValidationService;
