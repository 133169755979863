import RouteActiveListRequest, {
  RouteActiveListRequestQueryParams,
} from "./route-active-list.request";

const createQueryParams = (
  request: RouteActiveListRequest
): RouteActiveListRequestQueryParams => {
  return {
    taxiCorporationId: request.taxiCorporationId,
    cargoOrderId: request.cargoOrderId,
    cargoOrderHumanId: request.cargoOrderHumanId,
  };
};

const routeActiveListRequestFactory = { createQueryParams };

export default routeActiveListRequestFactory;
