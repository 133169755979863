import { FC } from "react";
import PaginationComponent from "../../pagination/pagination.component";

type ListingPaginationProps = {
  page: number;
  pageSize: number;
  totalResults: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  pageSizeOptions?: number[];
};

const ListingPaginationComponent: FC<ListingPaginationProps> = (props) => {
  return (
    <div className="listing_pagination">
      <PaginationComponent
        onPageChange={props.onPageChange}
        onPageSizeChange={props.onPageSizeChange}
        page={props.page}
        pageSize={props.pageSize}
        totalResults={props.totalResults}
        pageSizeOptions={props.pageSizeOptions}
      />
    </div>
  );
};

export default ListingPaginationComponent;
