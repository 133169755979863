import { round } from "lodash";
import dateService from "../../../../../../../../common/utils/date/date.service";
import uuidService from "../../../../../../../../common/utils/uuid/uuid.service";
import MileageUpdateSummaryRouteConnection from "../types/mileage-update-summary-route-connection";
import MileageUpdateSummaryRouteConnectionLineType from "../types/mileage-update-summary-route-connection-line-type";
import MileageUpdateSummaryRouteWaypoint from "../types/mileage-update-summary-route-waypoint";

const getPlaceLabel = (place: MileageUpdateSummaryRouteWaypoint["place"]) => {
  if (place === null) {
    return `----`;
  }

  return place.displayName;
};

const getPlaceTitle = (place: MileageUpdateSummaryRouteWaypoint["place"]) => {
  if (place === null) {
    return undefined;
  }

  return place.displayName;
};

const getDistanceLabel = (
  distance: MileageUpdateSummaryRouteWaypoint["distance"]
): string => {
  if (distance === null) {
    return `0 KM`;
  }

  const distanceAsKm = round(distance / 1000);

  return `${distanceAsKm} KM`;
};

const getTimeLabel = (time: MileageUpdateSummaryRouteWaypoint["time"]) => {
  if (time === null || !dateService.checkIsValidDate(time)) {
    return `---`;
  }

  return dateService.formatDateTime(time);
};

const generateWaypointDotClassname = (
  waypoint: MileageUpdateSummaryRouteWaypoint
) => {
  return `dot-uuid-${waypoint.uuid ?? uuidService.generate()}`;
};

const getDefinitionOfLineConnectionsBetweenWaypoints = (
  waypoints: MileageUpdateSummaryRouteWaypoint[]
): MileageUpdateSummaryRouteConnection[] => {
  let connectionLineDefinitions: MileageUpdateSummaryRouteConnection[] = [];

  waypoints.forEach((currentWaypoint, index) => {
    const nextWaypoint = waypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const lineType = MileageUpdateSummaryRouteConnectionLineType.SOLID;

    const newConnectionLineDefinition: MileageUpdateSummaryRouteConnection = {
      itemFromClassname: generateWaypointDotClassname(currentWaypoint),
      itemToClassname: generateWaypointDotClassname(nextWaypoint),
      lineType,
    };

    connectionLineDefinitions = [
      ...connectionLineDefinitions,
      newConnectionLineDefinition,
    ];
  });

  return connectionLineDefinitions;
};

const mileageUpdateSummaryRouteHelper = {
  getPlaceLabel,
  getPlaceTitle,
  getDistanceLabel,
  getTimeLabel,
  getDefinitionOfLineConnectionsBetweenWaypoints,
  generateWaypointDotClassname,
};

export default mileageUpdateSummaryRouteHelper;
