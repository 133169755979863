import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import mileageDetailsHelper from "../common/mileage-details.helper";
import MileageDetailsPassenger from "../common/types/mileage-details-passenger";
import MileageDetailsRouteWaypoint from "../common/types/mileage-details-route-waypoint";

type MileageDetailsPassengersProps = {
  passengers: MileageDetailsPassenger[];
  selectedPassenger: MileageDetailsPassenger | null;
  waypoints: MileageDetailsRouteWaypoint[];
};

const MileageDetailsPassengersComponent: FC<MileageDetailsPassengersProps> = (
  props
) => {
  const translations =
    mileageTranslationsHelper.getMileageDetailsTranslations().passengers;

  return (
    <div className="mileage_details_passengers">
      <div>{translations.headingText}</div>
      <ul className="mileage_details_passengers_list">
        {props.passengers.map((passenger) => {
          const isSelected = props.selectedPassenger?.uuid === passenger.uuid;

          return (
            <li
              className={classNames(
                "mileage_details_passengers_item",
                isSelected && "selected"
              )}
              key={`passenger-${passenger.uuid}`}
              title={mileageDetailsHelper.getPassengerTitle(passenger)}
            >
              <FontAwesomeIcon
                icon={faUser}
                className="mileage_details_passengers_item__icon"
              />
              <div className="mileage_details_passengers_item__name">
                {passenger.displayName}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MileageDetailsPassengersComponent;
