import PassengerListRequest, {
  PassengerListRequestStatusCriteria,
  PassengerListRequestOrder,
} from "../../../api/raily/passenger/list/passenger-list.request";
import UserStatus from "../../../types/user-status";
import paginationService from "../../../utils/pagination/pagination.service";
import PassengerListLoadParams, {
  PassengerListLoadParamsOrder,
} from "./passenger-list-load-params";

const createStatusCriteria = (
  status: UserStatus | undefined
): PassengerListRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return PassengerListRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return PassengerListRequestStatusCriteria.DELETED;
    default:
      return PassengerListRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: PassengerListLoadParamsOrder | undefined
): PassengerListRequestOrder | undefined => {
  switch (status) {
    case PassengerListLoadParamsOrder.FIRST_NAME_ASC:
      return PassengerListRequestOrder.FIRST_NAME_ASC;
    case PassengerListLoadParamsOrder.FIRST_NAME_DESC:
      return PassengerListRequestOrder.FIRST_NAME_DESC;
    case PassengerListLoadParamsOrder.LAST_NAME_ASC:
      return PassengerListRequestOrder.LAST_NAME_ASC;
    case PassengerListLoadParamsOrder.LAST_NAME_DESC:
      return PassengerListRequestOrder.LAST_NAME_DESC;
    case PassengerListLoadParamsOrder.USERNAME_ASC:
      return PassengerListRequestOrder.USERNAME_ASC;
    case PassengerListLoadParamsOrder.USERNAME_DESC:
      return PassengerListRequestOrder.USERNAME_DESC;
    default:
      return undefined;
  }
};

const create = (params: PassengerListLoadParams): PassengerListRequest => {
  return {
    cargoCompany: params.cargoCompany,
    cargoCompanyId: params.cargoCompanyId,
    dispatch: params.dispatch,
    dispatchId: params.dispatchId,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    statusCriteria: createStatusCriteria(params.status),
    username: params.username,
    creationEnd: params.creationEnd,
    creationStart: params.creationStart,
    limit: params.pageSize,
    address: params.address,
  };
};

const passengerListRequestFactory = { create };

export default passengerListRequestFactory;
