import cargoCompanyPassengerApiService from "../../../api/raily/cargo-company/passenger/cargo-company-passenger-api.service";
import dataLoadErrorFactory from "../../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../../utils/http/http.error";
import httpHelper from "../../../utils/http/http.helper";
import CargoCompanyPassengerListError from "./list/cargo-company-passenger-list-error";
import CargoCompanyPassengerListLoadParams from "./list/cargo-company-passenger-list-load-params";
import cargoCompanyPassengerListRequestFactory from "./list/cargo-company-passenger-list-request.factory";
import CargoCompanyPassengerList from "./list/cargo-company-passenger-list";
import cargoCompanyPassengerListFactory from "./list/cargo-company-passenger-list.factory";

const handleCargoCompanyPassengerListError = (
  error: HttpError | CargoCompanyPassengerListError
): CargoCompanyPassengerListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getPassengerList = async (
  params: CargoCompanyPassengerListLoadParams,
  abortSignal: AbortSignal
): Promise<CargoCompanyPassengerList> => {
  const request =
    cargoCompanyPassengerListRequestFactory.createPassengerListRequest(params);

  try {
    const response = await cargoCompanyPassengerApiService().getPassengerList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoCompanyPassengerListFactory.createPassengerList(response);
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyPassengerListError;
    throw handleCargoCompanyPassengerListError(error);
  }
};

const cargoCompanyPassengerService = {
  getPassengerList,
};

export default cargoCompanyPassengerService;
