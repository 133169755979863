import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";

const getContent = (
  isAnyDateFromRouteItemsPastDate: boolean
  // TODO: Feature #948 postponed to next release
  // isTaxiContractPermitsTollRoads: boolean
): string[] => {
  const translations =
    orderTranslationsHelper.getEditTranslations().summary.submitEditOrderModal;
  const content: string[] = [];

  if (isAnyDateFromRouteItemsPastDate) {
    content.push(translations.itemsPastDateLabel);
  }

  // TODO: Feature #948 postponed to next release
  // if (!!!isTaxiContractPermitsTollRoads) {
  //   content.push(translations.taxiContractPermitsTollRoadsLabel);
  // }

  return content;
};

const orderRouteEditConfirmationModalHelper = { getContent };

export default orderRouteEditConfirmationModalHelper;
