import useAsyncData from "../../../hooks/use-async-data";
import mileageContractService from "../mileage-contract.service";
import MileageContractList from "./mileage-contract-list";
import MileageContractListLoadParams from "./mileage-contract-list-load-params";

const useMileageContractsList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MileageContractList>({ data: [], totalCount: 0 });

  const load = async (
    params: MileageContractListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const mileageContractsList: MileageContractList =
        await mileageContractService.getList(params, signal);

      setData(mileageContractsList);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useMileageContractsList;
