import StatusLegendStatuses from "../../../../common/components/status-legend/types/status-legend-statuses";
import { CargoOrderActiveListItemExecutionStatus } from "../../../../common/services/cargo-order/active-list/cargo-order-active-list";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";

const getStatusOptions = () => [
  {
    status: StatusLegendStatuses.PENDING,
  },
  {
    status: StatusLegendStatuses.PLANNED,
  },
  {
    status: StatusLegendStatuses.IN_PROGRESS,
  },
  {
    status: StatusLegendStatuses.CANDIDATURE,
  },
];

const getExecutionStatusOptions = () => {
  const translations =
    orderTranslationsHelper.getActiveListingTranslations().executionStatus;

  return [
    {
      label: translations.pendingLabel,
      status: CargoOrderActiveListItemExecutionStatus.PENDING,
    },
    {
      label: translations.plannedLabel,
      status: CargoOrderActiveListItemExecutionStatus.PLANNED,
    },
    {
      label: translations.inProgressLabel,
      status: CargoOrderActiveListItemExecutionStatus.IN_PROGRESS,
    },
    {
      label: translations.candidatureLabel,
      status: CargoOrderActiveListItemExecutionStatus.CANDIDATURE,
    },
  ];
};

const getExecutionStatusLabel = (
  status: CargoOrderActiveListItemExecutionStatus
): string => {
  const options = getExecutionStatusOptions();

  return options.find((option) => option.status === status)?.label ?? "";
};

const getMobileAppUsingLabel = (isUsing: boolean) => {
  const translations = orderTranslationsHelper.getActiveListingTranslations();

  return isUsing
    ? translations.mobileAppUserTitle
    : translations.noMobileAppUserTitle;
};

const orderActiveListingHelper = {
  getExecutionStatusOptions,
  getExecutionStatusLabel,
  getMobileAppUsingLabel,
  getStatusOptions,
};

export default orderActiveListingHelper;
