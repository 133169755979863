import { FC } from "react";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../common/components/form/input/input.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverAddCompanyFormAddress from "../types/driver-add-company-form-address";
import DriverAddCompanyFormData from "../types/driver-add-company-form-data";
import DriverAddCompanyFormValidationResults from "../types/driver-add-company-form-validation-results";

type DriverAddCompanyDataProps = {
  formData: DriverAddCompanyFormData;
  formValidationResults: DriverAddCompanyFormValidationResults;
  onFormDataChange: (formData: DriverAddCompanyFormData) => void;
  validateCompanyName: () => void;
  validateCompanyId: () => void;
  validateTaxNumber: () => void;
  validateNationalCourtRegisterNumber: () => void;
  validateAddressStreet: () => void;
  validateAddressHouseNumber: () => void;
  validateAddressApartmentNumber: () => void;
  validateAddressTown: () => void;
  validateAddressCountry: () => void;
  validateAddressZipCode: () => void;
  validateAddressDescription: () => void;
};

const DriverAddCompanyDataComponent: FC<DriverAddCompanyDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.companyData;

  const setCompanyName = (value: DriverAddCompanyFormData["name"]) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      name: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setCompanyId = (value: DriverAddCompanyFormData["companyId"]) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      companyId: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setTaxNumber = (value: DriverAddCompanyFormData["taxNumber"]) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      taxNumber: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setNationalCourtRegisterNumber = (
    value: DriverAddCompanyFormData["nationalCourtRegisterNumber"]
  ) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      nationalCourtRegisterNumber: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setAddressStreet = (value: DriverAddCompanyFormAddress["street"]) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      address: {
        ...props.formData.address,
        street: value,
      },
    };

    props.onFormDataChange(newFormData);
  };

  const setAddressHouseNumber = (
    value: DriverAddCompanyFormAddress["houseNumber"]
  ) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      address: {
        ...props.formData.address,
        houseNumber: value,
      },
    };

    props.onFormDataChange(newFormData);
  };

  const setAddressApartmentNumber = (
    value: DriverAddCompanyFormAddress["apartmentNumber"]
  ) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      address: {
        ...props.formData.address,
        apartmentNumber: value,
      },
    };

    props.onFormDataChange(newFormData);
  };

  const setAddressTown = (value: DriverAddCompanyFormAddress["town"]) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      address: {
        ...props.formData.address,
        town: value,
      },
    };

    props.onFormDataChange(newFormData);
  };

  const setAddressZipCode = (value: DriverAddCompanyFormAddress["zipCode"]) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      address: {
        ...props.formData.address,
        zipCode: value,
      },
    };

    props.onFormDataChange(newFormData);
  };

  const setAddressCountry = (value: DriverAddCompanyFormAddress["country"]) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      address: {
        ...props.formData.address,
        country: value,
      },
    };

    props.onFormDataChange(newFormData);
  };

  const setAddressDescription = (
    value: DriverAddCompanyFormAddress["description"]
  ) => {
    const newFormData: DriverAddCompanyFormData = {
      ...props.formData,
      address: {
        ...props.formData.address,
        description: value,
      },
    };

    props.onFormDataChange(newFormData);
  };

  return (
    <Row>
      <Column lg={6}>
        <CardComponent
          header={{ title: translations.headingText }}
          classNames={{ root: "h-100" }}
        >
          <Row>
            <Column>
              <FormFieldComponent
                label={translations.nameLabel}
                isRequired
                classNames={{ root: "mt-0" }}
                errorMessage={props.formValidationResults.name.errorMessage}
              >
                <InputComponent
                  placeholder={translations.namePlaceholder}
                  value={props.formData.name}
                  onChange={setCompanyName}
                  onBlur={props.validateCompanyName}
                  hasError={!!props.formValidationResults.name.errorMessage}
                  idForTesting="company-data-company-name-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.taxNumberLabel}
                isRequired
                errorMessage={
                  props.formValidationResults.taxNumber.errorMessage
                }
              >
                <InputComponent
                  placeholder={translations.taxNumberPlaceholder}
                  value={props.formData.taxNumber}
                  onChange={setTaxNumber}
                  onBlur={props.validateTaxNumber}
                  hasError={
                    !!props.formValidationResults.taxNumber.errorMessage
                  }
                  idForTesting="company-data-tax-number-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.companyIdLabel}
                errorMessage={
                  props.formValidationResults.companyId.errorMessage
                }
              >
                <InputComponent
                  placeholder={translations.companyIdPlaceholder}
                  value={props.formData.companyId}
                  onChange={setCompanyId}
                  onBlur={props.validateCompanyId}
                  hasError={
                    !!props.formValidationResults.companyId.errorMessage
                  }
                  idForTesting="company-data-company-id-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.nationalCourtRegisterLabel}
                errorMessage={
                  props.formValidationResults.nationalCourtRegisterNumber
                    .errorMessage
                }
              >
                <InputComponent
                  placeholder={translations.nationalCourtRegisterPlaceholder}
                  value={props.formData.nationalCourtRegisterNumber}
                  onChange={setNationalCourtRegisterNumber}
                  onBlur={props.validateNationalCourtRegisterNumber}
                  hasError={
                    !!props.formValidationResults.nationalCourtRegisterNumber
                      .errorMessage
                  }
                  idForTesting="company-data-national-court-register-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
        </CardComponent>
      </Column>
      <Column lg={6}>
        <CardComponent
          header={{ title: translations.address.headingText }}
          classNames={{ root: "h-100" }}
        >
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.address.streetLabel}
                isRequired
                classNames={{ root: "mt-0" }}
                errorMessage={
                  props.formValidationResults.addressStreet.errorMessage
                }
              >
                <InputComponent
                  value={props.formData.address.street}
                  onChange={setAddressStreet}
                  placeholder={translations.address.streetPlaceholder}
                  hasError={
                    !!props.formValidationResults.addressStreet.errorMessage
                  }
                  onBlur={props.validateAddressStreet}
                  idForTesting="company-data-address-street-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.address.houseNumberLabel}
                isRequired
                classNames={{ root: "mt-0" }}
                errorMessage={
                  props.formValidationResults.addressHouseNumber.errorMessage
                }
              >
                <InputComponent
                  value={props.formData.address.houseNumber}
                  onChange={setAddressHouseNumber}
                  placeholder={translations.address.houseNumberPlaceholder}
                  hasError={
                    !!props.formValidationResults.addressHouseNumber
                      .errorMessage
                  }
                  onBlur={props.validateAddressHouseNumber}
                  idForTesting="company-data-address-house-number-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.address.apartmentNumberLabel}
                errorMessage={
                  props.formValidationResults.addressApartmentNumber
                    .errorMessage
                }
                classNames={{ root: "mt-0" }}
              >
                <InputComponent
                  value={props.formData.address.apartmentNumber}
                  onChange={setAddressApartmentNumber}
                  placeholder={translations.address.apartmentNumberPlaceholder}
                  hasError={
                    !!props.formValidationResults.addressApartmentNumber
                      .errorMessage
                  }
                  onBlur={props.validateAddressApartmentNumber}
                  idForTesting="company-data-address-apartment-number-input"
                />
              </FormFieldComponent>
            </Column>

            <Column xl={6}>
              <FormFieldComponent
                label={translations.address.townLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={
                  props.formValidationResults.addressTown.errorMessage
                }
              >
                <InputComponent
                  value={props.formData.address.town}
                  onChange={setAddressTown}
                  placeholder={translations.address.townPlaceholder}
                  hasError={
                    !!props.formValidationResults.addressTown.errorMessage
                  }
                  onBlur={props.validateAddressTown}
                  idForTesting="company-data-address-town-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.address.zipCodeLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={
                  props.formValidationResults.addressZipCode.errorMessage
                }
              >
                <InputComponent
                  value={props.formData.address.zipCode}
                  onChange={setAddressZipCode}
                  placeholder={translations.address.zipCodePlaceholder}
                  hasError={
                    !!props.formValidationResults.addressZipCode.errorMessage
                  }
                  onBlur={props.validateAddressZipCode}
                  idForTesting="company-data-address-zip-code-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.address.countryLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={
                  props.formValidationResults.addressCountry.errorMessage
                }
              >
                <InputComponent
                  value={props.formData.address.country}
                  onChange={setAddressCountry}
                  placeholder={translations.address.countryPlaceholder}
                  hasError={
                    !!props.formValidationResults.addressCountry.errorMessage
                  }
                  onBlur={props.validateAddressCountry}
                  idForTesting="company-data-address-country-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column>
              <FormFieldComponent
                label={translations.address.descriptionLabel}
                classNames={{ root: "mt-0" }}
                errorMessage={
                  props.formValidationResults.addressDescription.errorMessage
                }
              >
                <InputComponent
                  value={props.formData.address.description}
                  onChange={setAddressDescription}
                  placeholder={translations.address.descriptionPlaceholder}
                  hasError={
                    !!props.formValidationResults.addressDescription
                      .errorMessage
                  }
                  onBlur={props.validateAddressDescription}
                  idForTesting="company-data-address-description-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
        </CardComponent>
      </Column>
    </Row>
  );
};

export default DriverAddCompanyDataComponent;
