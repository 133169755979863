import { GroupBase, MenuListProps, components } from "react-select";
import SelectOption from "../option/select-option";
import { FC } from "react";

type SelectMenuListProps = MenuListProps<
  SelectOption,
  boolean,
  GroupBase<SelectOption>
> & {
  idForTesting: string | undefined;
};

const SelectMenuListComponent: FC<SelectMenuListProps> = (props) => {
  const { idForTesting, ...propsRest } = props;

  return (
    <components.MenuList
      {...propsRest}
      innerProps={Object.assign({}, props.innerProps, {
        "data-test-id": idForTesting,
      })}
    />
  );
};

export default SelectMenuListComponent;
