import CargoCompanyMileageContractDetailsRequest, {
  CargoCompanyMileageContractDetailsQueryParams,
} from "./cargo-company-mileage-contract-details.request";

const createQueryParams = (
  request: CargoCompanyMileageContractDetailsRequest
): CargoCompanyMileageContractDetailsQueryParams => {
  return {
    validAt: request.validAt,
  };
};

const cargoCompanyMileageContractRequestFactory = {
  createQueryParams,
};

export default cargoCompanyMileageContractRequestFactory;
