import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import { useAppContext } from "../../../../context/app.context";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import DispatcherAddByRailyComponent from "./by-raily/dispatcher-add-by-raily.component";
import DispatcherAddByCargoComponent from "./by-cargo/dispatcher-add-by-cargo.component";
import DispatcherAddViewMode from "./common/dispatcher-add-view-mode";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import useDispatcherAddUserPermissions from "./common/user-permissions/use-dispatcher-add-user-permissions";

type DispatcherAddProps = {};

const DispatcherAddComponent: FC<DispatcherAddProps> = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userDispatcherAdd);

  const userPermissions = useDispatcherAddUserPermissions();
  const translations = userTranslationsHelper.getDispatcherAddTranslations();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDispatcherAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<DispatcherAddViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DispatcherAddViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DispatcherAddViewMode.CARGO)}
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: DispatcherAddViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToCargoView,
        },
      },
      {
        viewMode: DispatcherAddViewMode.CARGO,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToTaxiButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: DispatcherAddViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: DispatcherAddViewMode.RAILY,
        component: (
          <DispatcherAddByRailyComponent
            changeViewButtons={getViewChangeButtons(
              DispatcherAddViewMode.RAILY
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: DispatcherAddViewMode.CARGO,
        component: (
          <DispatcherAddByCargoComponent
            changeViewButtons={getViewChangeButtons(
              DispatcherAddViewMode.CARGO
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToCargoView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default DispatcherAddComponent;
