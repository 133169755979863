import { FC, useEffect } from "react";
import useDocumentTitle from "../../common/hooks/use-document-title";
import { useAppContext } from "../../context/app.context";
import appTranslationsHelper from "../../languages/app-translations.helper";

type DashboardProps = {};

const DashboardComponent: FC<DashboardProps> = () => {
  const { setBreadcrumbs } = useAppContext();
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mainDashboard);

  useEffect(() => {
    setBreadcrumbs([]);
  }, []);

  return <div>Dashboard</div>;
};

export default DashboardComponent;
