import { FC } from "react";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsHistoryComponentProps = {
  tools: OrderDetailsToolsTranslations;
  isActive: boolean;
  onSuccess: () => void;
};

const OrderDetialsHistoryComponent: FC<OrderDetialsHistoryComponentProps> = ({
  tools,
  isActive,
  onSuccess,
}) => {
  const { showOrderHistoryButtonTitle } = tools;

  return (
    <OrderDetailsToolButtonComponent
      onClick={onSuccess}
      icon={faClockRotateLeft}
      title={showOrderHistoryButtonTitle}
      isActive={isActive}
    />
  );
};

export default OrderDetialsHistoryComponent;
