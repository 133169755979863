import DelegationAddSummaryRouteConnection from "../types/delegation-add-summary-route-connection";
import DelegationAddSummaryRouteConnectionLineType from "../types/delegation-add-summary-route-connection-line-type";
import DelegationAddRouteWaypoint from "../../../../types/delegation-add-route-waypoint";

const getPlaceLabel = (place: DelegationAddRouteWaypoint["place"]) => {
  if (place === null) {
    return `----`;
  }

  return place.displayName;
};

const getPlaceTitle = (place: DelegationAddRouteWaypoint["place"]) => {
  if (place === null) {
    return undefined;
  }

  return place.displayName;
};

const getGroupLabel = (group: DelegationAddRouteWaypoint["group"]) => {
  if (group === null) {
    return `----`;
  }

  return group;
};

const getGroupTitle = (group: DelegationAddRouteWaypoint["group"]) => {
  if (group === null) {
    return undefined;
  }

  return group;
};

const generateWaypointDotClassname = (waypoint: DelegationAddRouteWaypoint) => {
  return `dot-uuid-${waypoint.uuid}`;
};

const getDefinitionOfLineConnectionsBetweenWaypoints = (
  waypoints: DelegationAddRouteWaypoint[]
): DelegationAddSummaryRouteConnection[] => {
  let connectionLineDefinitions: DelegationAddSummaryRouteConnection[] = [];

  waypoints.forEach((currentWaypoint, index) => {
    const nextWaypoint = waypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const lineType = DelegationAddSummaryRouteConnectionLineType.SOLID;

    const newConnectionLineDefinition: DelegationAddSummaryRouteConnection = {
      itemFromClassname: generateWaypointDotClassname(currentWaypoint),
      itemToClassname: generateWaypointDotClassname(nextWaypoint),
      lineType,
    };

    connectionLineDefinitions = [
      ...connectionLineDefinitions,
      newConnectionLineDefinition,
    ];
  });

  return connectionLineDefinitions;
};

const delegationAddSummaryRouteHelper = {
  getPlaceLabel,
  getPlaceTitle,
  getGroupLabel,
  getGroupTitle,
  getDefinitionOfLineConnectionsBetweenWaypoints,
  generateWaypointDotClassname,
};

export default delegationAddSummaryRouteHelper;
