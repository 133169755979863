import Joi from "joi";
import TaxiCargoContractListingRouteQueryFilterParams from "./types/taxi-cargo-contract-listing-route-query-filter-params";
import TaxiCargoContractListingFilter, {
  TaxiCargoContractListingBillingModelFilter,
  TaxiCargoContractListingCargoCompanyFilter,
} from "./types/taxi-cargo-contract-listing-filter";
import TaxiCargoContractListingFilterType from "./types/taxi-cargo-contract-listing-filter-type";
import TaxiCargoContractListingRouteQueryParams from "./types/taxi-cargo-contract-listing-route-query-params";
import TaxiCargoContractListingSortKey from "./types/taxi-cargo-contract-listing-sort-key";
import TaxiCargoContractListingBillingModel from "./types/taxi-cargo-contract-listing-billing-model";

const getFilters = (
  routeQueryFilterParams: TaxiCargoContractListingRouteQueryFilterParams
): TaxiCargoContractListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: TaxiCargoContractListingFilter[] = [];

  if (routeQueryFilterParams.billingModel) {
    const billingModelFilter: TaxiCargoContractListingBillingModelFilter = {
      type: TaxiCargoContractListingFilterType.BILLING_MODEL,
      value:
        routeQueryFilterParams.billingModel as TaxiCargoContractListingBillingModelFilter["value"],
    };

    filters.push(billingModelFilter);
  }

  if (routeQueryFilterParams.cargoCompany) {
    const cargoCompanyFilter: TaxiCargoContractListingCargoCompanyFilter = {
      type: TaxiCargoContractListingFilterType.CARGO_COMPANY,
      value:
        routeQueryFilterParams.cargoCompany as TaxiCargoContractListingCargoCompanyFilter["value"],
    };

    filters.push(cargoCompanyFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: TaxiCargoContractListingRouteQueryParams
): TaxiCargoContractListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const getPage = (
  routeQueryParams: TaxiCargoContractListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryParams.page)) {
    return undefined;
  }

  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: TaxiCargoContractListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryParams.pageSize)) {
    return undefined;
  }

  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: TaxiCargoContractListingFilter[],
  sortKey: TaxiCargoContractListingSortKey | null,
  page: number,
  pageSize: number
): TaxiCargoContractListingRouteQueryParams => {
  const routeQueryParams: TaxiCargoContractListingRouteQueryParams = {
    billingModel: filters.find(
      (filter) =>
        filter.type === TaxiCargoContractListingFilterType.BILLING_MODEL
    )?.value as TaxiCargoContractListingBillingModelFilter["value"],
    cargoCompany: filters.find(
      (filter) =>
        filter.type === TaxiCargoContractListingFilterType.CARGO_COMPANY
    )?.value as TaxiCargoContractListingCargoCompanyFilter["value"],
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const validateFilters = (
  routeQueryFilterParams: TaxiCargoContractListingRouteQueryFilterParams
) => {
  const filterParams: TaxiCargoContractListingRouteQueryFilterParams = {
    billingModel: routeQueryFilterParams.billingModel,
    cargoCompany: routeQueryFilterParams.cargoCompany,
  };

  const validationSchema =
    Joi.object<TaxiCargoContractListingRouteQueryFilterParams>({
      billingModel: Joi.valid(
        ...Object.values(TaxiCargoContractListingBillingModel)
      ),
      cargoCompany: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: TaxiCargoContractListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(TaxiCargoContractListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: TaxiCargoContractListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: TaxiCargoContractListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const taxiCargoContractListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiCargoContractListingRouteQueryParamsService;
