import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type CargoOrderSettlementListingUserPermissionOption =
  | "hasAccessToCargoView"
  | "hasAccessToRailyView"
  | "hasAccessToEditCargoWithTaxiSettlement"
  | "hasAccessToDetailsCargoWithTaxiSettlement";

export type CargoOrderSettlementListingUserPermissionsDefinition =
  UserPermissionsDefinition<CargoOrderSettlementListingUserPermissionOption>;

export type CargoOrderSettlementListingUserPermissions =
  UserPermissions<CargoOrderSettlementListingUserPermissionOption>;

const cargoOrderSettlementListingUserPermissionDefinition: CargoOrderSettlementListingUserPermissionsDefinition =
  {
    hasAccessToCargoView: [UserRole.CARGO_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToEditCargoWithTaxiSettlement: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
    ],
    hasAccessToDetailsCargoWithTaxiSettlement: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  };

export default cargoOrderSettlementListingUserPermissionDefinition;
