import MileageDictionaryDetailsResponse, {
  MileageDictionaryDetailsResponseItem,
} from "../../../api/raily/mileage-dictionary/details/mileage-dictionary-details.response";
import MileageDictionaryDetails from "./mileage-dictionary-details";

const createDataItem = (
  item: MileageDictionaryDetailsResponseItem
): MileageDictionaryDetails => {
  return {
    active: item.active,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    modificationDate: item.modifiedAt,
    modifiedBy: item.modifiedBy,
    name: item.name,
    uuid: item.id,
  };
};

const create = (
  data: MileageDictionaryDetailsResponse
): MileageDictionaryDetails => {
  return createDataItem(data.data);
};

const mileageDictionaryDetailsFactory = {
  create,
};

export default mileageDictionaryDetailsFactory;
