import MileageSettingsAddRequest, {
  MileageSettingsAddRequestBody,
} from "./mileage-settings-add.request";

const createBody = (
  request: MileageSettingsAddRequest
): MileageSettingsAddRequestBody => {
  return {
    companyId: request.companyUuid,
    isCardNoRequired: request.isCardNumberRequired,
    isMileageNoRequired: request.isMileageNumberRequired,
    mileageNoCreationType: request.mileageNumberCreationType,
  };
};

const mileageSettingsAddRequestFactory = {
  createBody,
};

export default mileageSettingsAddRequestFactory;
