import TaxiTaxiContractListingTableColumn from "../common/types/taxi-taxi-contract-listing-table-column";
import taxiTranslationsHelper from "../../../../../languages/taxi-translations.helper";

const getColumns = (): TaxiTaxiContractListingTableColumn[] => {
  const translations =
    taxiTranslationsHelper.getTaxiContractListingTranslations().table.headers;

  const columns: TaxiTaxiContractListingTableColumn[] = [
    {
      header: translations.sourceTaxiCorporationLabel,
      title: translations.sourceTaxiCorporationTitle,
      accessor: "sourceTaxiCorporation",
      colSpan: 10,
    },
    {
      header: translations.targetTaxiCorporationLabel,
      title: translations.targetTaxiCorporationTitle,
      accessor: "targetTaxiCorporation",
      colSpan: 10,
    },
    {
      header: translations.displayNameLabel,
      title: translations.displayNameTitle,
      accessor: "displayName",
      colSpan: 10,
    },
    {
      header: translations.periodOfValidationLabel,
      title: translations.periodOfValidationTitle,
      accessor: "periodOfValidity",
      colSpan: 12,
    },
    {
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      accessor: "billingModel",
      colSpan: 10,
    },
    {
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      accessor: "distanceRate",
      colSpan: 10,
    },
    {
      header: translations.stoppingRateLabel,
      title: translations.stoppingRateTitle,
      accessor: "stoppingRate",
      colSpan: 10,
    },
    {
      header: translations.freeStoppingPeriodLabel,
      title: translations.freeStoppingPeriodTitle,
      accessor: "freeStoppingPeriodMinutes",
      colSpan: 14,
    },
    {
      header: translations.priorityLabel,
      title: translations.priorityTitle,
      accessor: "priority",
      colSpan: 10,
    },
    {
      header: translations.isActiveStatusLabel,
      title: translations.isActiveStatusTitle,
      accessor: "activityStatus",
      colSpan: 5,
    },
    {
      header: translations.isPartnerLabel,
      title: translations.isPartnerTitle,
      accessor: "partner",
      colSpan: 5,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 10,
    },
  ];

  return columns;
};

const getActivityStatusLabel = (isContractActive: boolean | null): string => {
  const translations =
    taxiTranslationsHelper.getTaxiContractListingTranslations();

  return isContractActive
    ? translations.table.activityStatus.activeLabel
    : translations.table.activityStatus.inactiveLabel;
};

const taxiTaxiContractListingTableHelper = {
  getColumns,
  getActivityStatusLabel,
};

export default taxiTaxiContractListingTableHelper;
