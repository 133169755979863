import { faFilePen } from "@fortawesome/free-solid-svg-icons";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import mileageRoutesHelper from "../../../common/routes/mileage-routes.helper";
import MileageDetailsToolButtonComponent from "../../common/tool-button/mileage-details-tool-button.component";
import MileageListingStatus from "../../../listing/common/types/mileage-listing-status";
import MileageDetailsProps from "../types/mileage-details-props";

const MileageDetailsUpdateComponent: FC<MileageDetailsProps> = (props) => {
  const isDisabled = useMemo(() => {
    return props.details.status === MileageListingStatus.DELEGATION_CREATED;
  }, [props.details.status]);

  const navigate = useNavigate();

  return (
    <MileageDetailsToolButtonComponent
      onClick={() => {
        if (isDisabled) {
          return;
        }

        navigate(
          mileageRoutesHelper.getUpdateRoute({
            mileageUuid: props.details?.mileageUuid!,
          })
        );
      }}
      icon={faFilePen}
      title={props.translations.editMileageButtonTitle}
      isDisabled={isDisabled}
    />
  );
};

export default MileageDetailsUpdateComponent;
