import { UserCargoOfficerListingRouteQueryParams } from "../../../common/routes/types/user-cargo-officer-listing-route-params";
import CargoOfficerListingFilter, {
  CargoOfficerListingEmailFilter,
  CargoOfficerListingFirstNameFilter,
  CargoOfficerListingLastNameFilter,
  CargoOfficerListingActivityStatusFilter,
  CargoOfficerListingUsernameFilter,
  CargoOfficerListingCargoCompanyFilter,
} from "../common/types/cargo-officer-listing-filter";
import UserStatus from "../../../../../common/types/user-status";
import CargoOfficerListingSortKey from "../common/types/cargo-officer-listing-sort-key";
import CargoOfficerListingFilterType from "../common/types/cargo-officer-listing-filter-type";

const getFilters = (
  routeQueryFilterParams: UserCargoOfficerListingRouteQueryParams
): CargoOfficerListingFilter[] => {
  const filters: CargoOfficerListingFilter[] = [];

  if (routeQueryFilterParams.firstName) {
    const firstNameFilter: CargoOfficerListingFirstNameFilter = {
      type: CargoOfficerListingFilterType.FIRST_NAME,
      value: routeQueryFilterParams.firstName,
    };

    filters.push(firstNameFilter);
  }

  if (routeQueryFilterParams.lastName) {
    const lastNameFilter: CargoOfficerListingLastNameFilter = {
      type: CargoOfficerListingFilterType.LAST_NAME,
      value: routeQueryFilterParams.lastName,
    };

    filters.push(lastNameFilter);
  }

  if (routeQueryFilterParams.email) {
    const emailAddressFilter: CargoOfficerListingEmailFilter = {
      type: CargoOfficerListingFilterType.EMAIL,
      value: routeQueryFilterParams.email,
    };

    filters.push(emailAddressFilter);
  }

  if (routeQueryFilterParams.username) {
    const usernameFilter: CargoOfficerListingUsernameFilter = {
      type: CargoOfficerListingFilterType.USERNAME,
      value: routeQueryFilterParams.username,
    };

    filters.push(usernameFilter);
  }

  if (routeQueryFilterParams.cargoCompany) {
    const cargoCompanyFilter: CargoOfficerListingCargoCompanyFilter = {
      type: CargoOfficerListingFilterType.CARGO_COMPANY,
      value: routeQueryFilterParams.cargoCompany,
    };

    filters.push(cargoCompanyFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: CargoOfficerListingActivityStatusFilter = {
      type: CargoOfficerListingFilterType.ACTIVITY_STATUS,
      value:
        routeQueryFilterParams.status === "DELETED"
          ? UserStatus.DELETED
          : UserStatus.ACTIVE,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: UserCargoOfficerListingRouteQueryParams
): CargoOfficerListingSortKey | undefined => {
  return routeQueryParams.sort as CargoOfficerListingSortKey;
};

const getPage = (
  routeQueryParams: UserCargoOfficerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: UserCargoOfficerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: CargoOfficerListingFilter[],
  sortKey: CargoOfficerListingSortKey | null,
  page: number,
  pageSize: number
): UserCargoOfficerListingRouteQueryParams => {
  const userActivityFilterValue = filters.find(
    (filter) => filter.type === CargoOfficerListingFilterType.ACTIVITY_STATUS
  )?.value as CargoOfficerListingActivityStatusFilter["value"] | undefined;

  const routeQueryParams: UserCargoOfficerListingRouteQueryParams = {
    firstName: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.FIRST_NAME
    )?.value as CargoOfficerListingFirstNameFilter["value"],
    lastName: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.LAST_NAME
    )?.value as CargoOfficerListingLastNameFilter["value"],
    username: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.USERNAME
    )?.value as CargoOfficerListingUsernameFilter["value"],
    email: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.EMAIL
    )?.value as CargoOfficerListingEmailFilter["value"],
    cargoCompany: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.CARGO_COMPANY
    )?.value as CargoOfficerListingCargoCompanyFilter["value"],
    status:
      userActivityFilterValue === undefined
        ? undefined
        : String(userActivityFilterValue),
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const cargoOfficerListingFilterService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default cargoOfficerListingFilterService;
