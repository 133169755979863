import dateService from "../../../../../../../common/utils/date/date.service";
import durationService from "../../../../../../../common/utils/duration/duration.service";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import DriverDetailsCandidatureManagementRouteListingTableColumn from "./common/types/driver-details-candidature-management-route-listing-table-column";

const getColumns =
  (): DriverDetailsCandidatureManagementRouteListingTableColumn[] => {
    const translations =
      userTranslationsHelper.getDriverDetailsCandidatureTranslations()
        .management.routeListing.table.headers;

    return [
      {
        header: "",
        title: "",
        accessor: "difference",
        colSpan: 3,
      },
      {
        header: translations.clientLabel,
        title: translations.clientTitle,
        accessor: "client",
        colSpan: 10,
      },
      {
        header: translations.orderNoLabel,
        title: translations.orderNoTitle,
        accessor: "orderNo",
        colSpan: 10,
      },
      {
        header: translations.addressLabel,
        title: translations.addressTitle,
        accessor: "address",
        colSpan: 10,
      },
      {
        header: translations.plannedDateLabel,
        title: translations.plannedDateTitle,
        accessor: "plannedDate",
        colSpan: 10,
      },
      {
        header: translations.estimatedDateLabel,
        title: translations.estimatedDateTitle,
        accessor: "estimatedDate",
        colSpan: 10,
      },
      {
        header: translations.plannedHaltingTimeLabel,
        title: translations.plannedHaltingTimeTitle,
        accessor: "plannedHaltingTime",
        colSpan: 10,
      },
    ];
  };

const getEmptyLabel = (): string => {
  return `----`;
};

const getEmptyTitle = (): string => {
  return ``;
};

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return getEmptyLabel();
  }

  return dateService.formatTime(date);
};

const getDateTitle = (date: Date | null): string => {
  if (!date) {
    return getEmptyTitle();
  }

  return dateService.formatDateTime(date);
};

const getClientLabel = (clientDisplayName: string | null): string => {
  if (!clientDisplayName) {
    return getEmptyLabel();
  }

  return clientDisplayName;
};

const getClientTitle = (clientDisplayName: string | null): string => {
  if (!clientDisplayName) {
    return getEmptyTitle();
  }

  return clientDisplayName;
};

const getOrderIdLabel = (orderId: number | null): string => {
  if (!orderId) {
    return getEmptyLabel();
  }

  return `${orderId}`;
};

const getOrderIdTitle = (orderId: number | null): string => {
  if (!orderId) {
    return getEmptyTitle();
  }

  return `${orderId}`;
};

const getPlannedDateLabel = (plannedDate: Date | null) => {
  return getDateLabel(plannedDate);
};

const getPlannedDateTitle = (plannedDate: Date | null) => {
  return getDateTitle(plannedDate);
};

const getEstimatedDateLabel = (estimatedDate: Date | null) => {
  return getDateLabel(estimatedDate);
};

const getEstimatedDateTitle = (estimatedDate: Date | null) => {
  return getDateTitle(estimatedDate);
};

const getPlannedHaltingTimeLabel = (haltingTime: number | null) => {
  if (haltingTime === null) {
    return getEmptyLabel();
  }

  return `${durationService.format(haltingTime)}h`;
};

const getPlannedHaltingTimeTitle = (haltingTime: number | null) => {
  if (haltingTime === null) {
    return getEmptyTitle();
  }

  return `${durationService.format(haltingTime)}h`;
};

const driverDetailsCandidatureManagementRouteListingHelper = {
  getColumns,
  getClientLabel,
  getClientTitle,
  getOrderIdLabel,
  getOrderIdTitle,
  getPlannedDateLabel,
  getPlannedDateTitle,
  getEstimatedDateLabel,
  getEstimatedDateTitle,
  getPlannedHaltingTimeLabel,
  getPlannedHaltingTimeTitle,
};

export default driverDetailsCandidatureManagementRouteListingHelper;
