import Joi from "joi";
import TaxiRouteSettlementListingRouteQueryFilterParams from "./types/taxi-route-settlement-listing-route-query-filter-params";
import TaxiRouteSettlementListingRouteQueryParams from "./types/taxi-route-settlement-listing-route-query-params";
import TaxiRouteSettlementListingSortKey from "./types/taxi-route-settlement-listing-sort-key";
import TaxiRouteSettlementListingFilter, {
  TaxiRouteSettlementListingRouteStartDateFilter,
  TaxiRouteSettlementListingBillingModelFilter,
  TaxiRouteSettlementListingDriverFilter,
  TaxiRouteSettlementListingRouteIdFilter,
  TaxiRouteSettlementListingFleetPartnerFilter,
  TaxiRouteSettlementListingCargoOrderInternalIdFilter,
} from "./types/taxi-route-settlement-listing-filter";
import TaxiRouteSettlementListingFilterType from "./types/taxi-route-settlement-listing-filter-type";
import BillingModel from "../../../common/types/billing-model";
import taxiRouteSettlementListingFilterHelper from "./taxi-route-settlement-listing-filter.helper";

const getFilters = (
  routeQueryFilterParams: TaxiRouteSettlementListingRouteQueryFilterParams
): TaxiRouteSettlementListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: TaxiRouteSettlementListingFilter[] = [];

  if (
    routeQueryFilterParams.routeStartDateFrom &&
    routeQueryFilterParams.routeStartDateTo
  ) {
    const routeStartDateFilter: TaxiRouteSettlementListingRouteStartDateFilter =
      {
        type: TaxiRouteSettlementListingFilterType.ORDER_START_DATE,
        value: {
          from: new Date(routeQueryFilterParams.routeStartDateFrom),
          to: new Date(routeQueryFilterParams.routeStartDateTo),
        },
      };

    filters.push(routeStartDateFilter);
  }

  if (routeQueryFilterParams.routeId) {
    const routeIdFilter: TaxiRouteSettlementListingRouteIdFilter = {
      type: TaxiRouteSettlementListingFilterType.ROUTE_ID,
      value: Number(routeQueryFilterParams.routeId),
    };
    filters.push(routeIdFilter);
  }

  if (routeQueryFilterParams.cargoOrderInternalId) {
    const cargoOrderInternalIdFilter: TaxiRouteSettlementListingCargoOrderInternalIdFilter =
      {
        type: TaxiRouteSettlementListingFilterType.CARGO_ORDER_INTERNAL_ID,
        value: Number(routeQueryFilterParams.cargoOrderInternalId),
      };
    filters.push(cargoOrderInternalIdFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: TaxiRouteSettlementListingDriverFilter = {
      type: TaxiRouteSettlementListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.billingModel) {
    const billingModelFilter: TaxiRouteSettlementListingBillingModelFilter = {
      type: TaxiRouteSettlementListingFilterType.BILLING_MODEL,
      value: routeQueryFilterParams.billingModel as BillingModel,
    };

    filters.push(billingModelFilter);
  }

  if (routeQueryFilterParams.fleetPartner) {
    const fleetPartnerFilter: TaxiRouteSettlementListingFleetPartnerFilter = {
      type: TaxiRouteSettlementListingFilterType.FLEET_PARTNER,
      value: routeQueryFilterParams.fleetPartner,
    };

    filters.push(fleetPartnerFilter);
  }

  const updatedFilters =
    taxiRouteSettlementListingFilterHelper.getDefaultFilters(filters);

  return updatedFilters;
};

const getSortKey = (
  routeQueryParams: TaxiRouteSettlementListingRouteQueryParams
): TaxiRouteSettlementListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: TaxiRouteSettlementListingFilter[],
  sortKey: TaxiRouteSettlementListingSortKey | null,
  page: number,
  pageSize: number
): TaxiRouteSettlementListingRouteQueryParams => {
  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    routeStartDateFrom: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiRouteSettlementListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    routeStartDateTo: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiRouteSettlementListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    routeId: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ROUTE_ID
      )?.value as TaxiRouteSettlementListingRouteIdFilter["value"] | undefined
    )?.toString(),
    cargoOrderInternalId: filters.find(
      (filter) =>
        filter.type ===
        TaxiRouteSettlementListingFilterType.CARGO_ORDER_INTERNAL_ID
    )?.value as
      | TaxiRouteSettlementListingCargoOrderInternalIdFilter["value"]
      | undefined,
    billingModel: filters.find(
      (filter) =>
        filter.type === TaxiRouteSettlementListingFilterType.BILLING_MODEL
    )?.value as
      | TaxiRouteSettlementListingBillingModelFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) => filter.type === TaxiRouteSettlementListingFilterType.DRIVER
    )?.value as TaxiRouteSettlementListingDriverFilter["value"] | undefined,
    fleetPartner: filters.find(
      (filter) =>
        filter.type === TaxiRouteSettlementListingFilterType.FLEET_PARTNER
    )?.value as
      | TaxiRouteSettlementListingFleetPartnerFilter["value"]
      | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: TaxiRouteSettlementListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: TaxiRouteSettlementListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: TaxiRouteSettlementListingRouteQueryFilterParams
) => {
  const filterParams: TaxiRouteSettlementListingRouteQueryFilterParams = {
    routeStartDateFrom: routeQueryFilterParams.routeStartDateFrom,
    routeStartDateTo: routeQueryFilterParams.routeStartDateTo,
    routeId: routeQueryFilterParams.routeId,
    driver: routeQueryFilterParams.driver,
    billingModel: routeQueryFilterParams.billingModel,
  };

  const validationSchema =
    Joi.object<TaxiRouteSettlementListingRouteQueryFilterParams>({
      routeStartDateFrom: Joi.string(),
      routeStartDateTo: Joi.string(),
      routeId: Joi.number(),
      billingModel: Joi.valid(...Object.values(BillingModel)),
      driver: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: TaxiRouteSettlementListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(TaxiRouteSettlementListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: TaxiRouteSettlementListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: TaxiRouteSettlementListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const taxiRouteSettlementListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiRouteSettlementListingRouteQueryParamsService;
