import HttpResponse from "../../../../utils/http/http.response";

export enum CargoOrderDetailsResponseItemStatus {
  PENDING = "PENDING",
  PLANNED = "PLANNED",
  CANDIDATURE = "CANDIDATURE",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export enum CargoOrderDetailsResponseItemCargoOrderRideSequenceOrder {
  STRICT = "STRICT",
}

export enum CargoOrderDetailsResponseItemCargoOrderCargoSourceType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export type CargoOrderDetailsResponseItemUser = {
  id: string;
  username: string;
  lastName: string;
  firstName: string;
};

export type CargoOrderDetailsResponseItemPassengerAddress = {
  id: string;
  lat: number;
  lon: number;
  country?: string;
  town?: string;
  zipCode?: string;
  street?: string;
  houseNo?: string;
  apartment?: string;
  description?: string;
  displayName: string;
};

export type CargoOrderDetailsResponseItemPassengerAspect = {
  addresses: CargoOrderDetailsResponseItemPassengerAddress[];
};

export type CargoOrderDetailsResponseItemPassenger =
  CargoOrderDetailsResponseItemUser & {
    passenger: CargoOrderDetailsResponseItemPassengerAspect;
  };

export type CargoOrderDetailsResponseItemNodeExclusions = {
  nodeIds: string[];
  excludeApproaching: boolean;
  excludeReturning: boolean;
  excludeInside: boolean;
};

export type CargoOrderDetailsResponseItemCargoOrderMeta = {
  id: string;
};

export type CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubject = {
  id: string;
};

export type CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubjectList = {
  subjects: CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubject[];
};

export type CargoOrderDetailsResponseItemCargoOrderRideMeta =
  CargoOrderDetailsResponseItemCargoOrderMeta & {
    cargoEnter: CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubjectList;
    cargoExit: CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubjectList;
  };

export type CargoOrderDetailsResponseItemCargoOrderRideSeqNode = {
  lat: number;
  lon: number;
  time: Date | null;
  haltingTime: number;
  meta: CargoOrderDetailsResponseItemCargoOrderRideMeta;
  displayName: string;
};

export type CargoOrderDetailsResponseItemCargoOrderRideSeqNodeSet = {
  seq: CargoOrderDetailsResponseItemCargoOrderRideSeqNode[];
  sequenceOrder: CargoOrderDetailsResponseItemCargoOrderRideSequenceOrder;
  meta: CargoOrderDetailsResponseItemCargoOrderMeta;
};
export type CargoOrderDetailsResponseItemCargoOrderRideSeq = (
  | CargoOrderDetailsResponseItemCargoOrderRideSeqNode
  | CargoOrderDetailsResponseItemCargoOrderRideSeqNodeSet
)[];

export type CargoOrderDetailsResponseItemCargoOrderRide = {
  seq: CargoOrderDetailsResponseItemCargoOrderRideSeq;
  sequenceOrder: CargoOrderDetailsResponseItemCargoOrderRideSequenceOrder;
  meta: CargoOrderDetailsResponseItemCargoOrderMeta;
};

export type CargoOrderDetailsResponseItemCargoOrderCargoSourceInternal = {
  sourceType: CargoOrderDetailsResponseItemCargoOrderCargoSourceType.INTERNAL;
  meta?: string;
  externalId?: string;
  passengerId: string;
};

export type CargoOrderDetailsResponseItemCargoOrderCargoSourceExternal = {
  sourceType: CargoOrderDetailsResponseItemCargoOrderCargoSourceType.EXTERNAL;
  meta?: string;
  externalId?: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
  dispatchName?: string;
};

export type CargoOrderDetailsResponseItemCargoOrderCargoSource =
  | CargoOrderDetailsResponseItemCargoOrderCargoSourceInternal
  | CargoOrderDetailsResponseItemCargoOrderCargoSourceExternal;

export type CargoOrderDetailsResponseItemCargoOrderCargoSourceElement = {
  cargoSubjectId: string;
  cargoItemSource: CargoOrderDetailsResponseItemCargoOrderCargoSource;
};

export type CargoOrderDetailsResponseItemCargoOrder = {
  id: string;
  ride: CargoOrderDetailsResponseItemCargoOrderRide;
  cargoItemsSourceList: CargoOrderDetailsResponseItemCargoOrderCargoSourceElement[];
};

export type CargoOrderDetailsResponseItem = {
  id: string;
  passengers: CargoOrderDetailsResponseItemPassenger[];
  cargoOrder: CargoOrderDetailsResponseItemCargoOrder;
  status: CargoOrderDetailsResponseItemStatus;
  nodeExclusions: CargoOrderDetailsResponseItemNodeExclusions;
  forceAllowToll: boolean;
};

type CargoOrderDetailsResponse = HttpResponse<CargoOrderDetailsResponseItem>;

export default CargoOrderDetailsResponse;
