import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import ButtonComponent, {
  ButtonProps,
} from "../../../../common/components/button/button.component";

type BillingsCargoTaxiToolButtonProps = Pick<
  ButtonProps,
  "onClick" | "title" | "isDisabled" | "type" | "isLoading"
> & {
  icon: IconProp;
  isActive?: boolean;
};

const BillingsCargoTaxiToolButtonComponent: FC<
  BillingsCargoTaxiToolButtonProps
> = (props) => {
  return (
    <ButtonComponent
      onClick={props.onClick}
      title={props.title}
      classNames={{
        root: classNames("billings_tools_button", props.isActive && "active"),
      }}
      type={props.type}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
    >
      <FontAwesomeIcon
        icon={props.icon}
        className="billings_tools_button__icon"
      />
    </ButtonComponent>
  );
};

BillingsCargoTaxiToolButtonComponent.defaultProps = {
  type: "primary",
};

export default BillingsCargoTaxiToolButtonComponent;
