import CargoCompanyListLoadParams, {
  CargoCompanyListLoadParamsOrder,
} from "../../../../../../common/services/cargo-company/cargo-company/list/cargo-company-list-load-params";
import CargoCompanyListingFilter, {
  CargoCompanyListingAddressFilter,
  CargoCompanyListingCompanyIdFilter,
  CargoCompanyListingCompanyNameFilter,
  CargoCompanyListingCompanyDisplayNameFilter,
  CargoCompanyListingNotesFilter,
  CargoCompanyListingTaxNumberFilter,
} from "./types/cargo-company-listing-filter";
import CargoCompanyListingFilterType from "./types/cargo-company-listing-filter-type";
import CargoCompanyListingSortKey from "./types/cargo-company-listing-sort-key";

const createOrder = (
  sortKey: CargoCompanyListingSortKey | null
): CargoCompanyListLoadParamsOrder | undefined => {
  const definition: Record<
    CargoCompanyListingSortKey,
    CargoCompanyListLoadParamsOrder
  > = {
    [CargoCompanyListingSortKey.COMPANY_NAME_ASC]:
      CargoCompanyListLoadParamsOrder.COMPANY_NAME_ASC,
    [CargoCompanyListingSortKey.COMPANY_NAME_DESC]:
      CargoCompanyListLoadParamsOrder.COMPANY_NAME_DESC,
    [CargoCompanyListingSortKey.COMPANY_DISPLAY_NAME_ASC]:
      CargoCompanyListLoadParamsOrder.COMPANY_DISPLAY_NAME_ASC,
    [CargoCompanyListLoadParamsOrder.COMPANY_DISPLAY_NAME_DESC]:
      CargoCompanyListLoadParamsOrder.COMPANY_DISPLAY_NAME_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  filters: CargoCompanyListingFilter[],
  sortKey: CargoCompanyListingSortKey | null
): CargoCompanyListLoadParams => {
  return {
    order: createOrder(sortKey),
    taxNumber: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.TAX_NUMBER
    )?.value as CargoCompanyListingTaxNumberFilter["value"] | undefined,
    companyId: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.COMPANY_ID
    )?.value as CargoCompanyListingCompanyIdFilter["value"] | undefined,
    nationalCourtRegister: filters.find(
      (filter) =>
        filter.type === CargoCompanyListingFilterType.NATIONAL_COURT_REGISTER
    )?.value as CargoCompanyListingTaxNumberFilter["value"] | undefined,
    companyName: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.COMPANY_NAME
    )?.value as CargoCompanyListingCompanyNameFilter["value"] | undefined,
    companyDisplayName: filters.find(
      (filter) =>
        filter.type === CargoCompanyListingFilterType.COMPANY_DISPLAY_NAME
    )?.value as
      | CargoCompanyListingCompanyDisplayNameFilter["value"]
      | undefined,
    address: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.ADDRESS
    )?.value as CargoCompanyListingAddressFilter["value"] | undefined,
    notes: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.NOTES
    )?.value as CargoCompanyListingNotesFilter["value"] | undefined,
  };
};

const cargoCompanyListingLoadParamsFactory = {
  create,
};

export default cargoCompanyListingLoadParamsFactory;
