enum OrderAbandonedListingSortApiKey {
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  MODIFIED_AT_ASC = "MODIFIED_AT_ASC",
  MODIFIED_AT_DESC = "MODIFIED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
}

export default OrderAbandonedListingSortApiKey;
