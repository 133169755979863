import SignInResponse from "../../../api/raily/auth/sign-in/sign-in.response";
import SignInResult from "./sign-in-result";

const create = (response: SignInResponse): SignInResult => {
  return {
    accessToken: response.data.accessToken,
  };
};

const signInResultFactory = {
  create,
};

export default signInResultFactory;
