import { FC, useEffect, useState } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../../../common/components/modal/modal.component";
import Row from "../../../../../../common/components/grid/row";
import Column from "../../../../../../common/components/grid/column";
import DriverDetailsCandidatureManagementRouteListingComponent from "./route-listing/driver-details-candidature-management-route-listing.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import driverDetailsCandidatureApiService from "../common/api/driver-details-candidature-api.service";
import DriverDetailsAcceptCandidatureResponse from "../common/api/driver-details-accept-candidature.response";
import notificationService from "../../../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverDetailsCandidatureManagementPlanEntry from "./common/types/driver-details-candidature-management-plan-entry";
import DriverDetailsRoutePlansAfterCandidatureApprovalResponse, {
  DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry,
} from "../common/api/driver-details-route-plans-after-candidature-approval.response";
import driverDetailsCandidatureManagementPlanEntriesAfterCandidatureApproveFactory from "./common/driver-details-candidature-management-plan-entries-after-candidature-approve.factory";
import { useAppContext } from "../../../../../../context/app.context";
import driverDetailsUserPermissionsHelper from "../../common/user-permissions/driver-details-user-permission.helper";
import LoaderComponent from "../../../../../../common/components/loader/loader.component";
import DriverDetailsDriverPlanEntry from "../../active-route-plans/common/types/driver-details-plan-entry";
import NoticeBoxComponent from "../../../../../../common/components/notice-box/notice-box.component";
import NoticeBoxType from "../../../../../../common/components/notice-box/notice-box-type";
import DriverDetailsAcceptPlanCandidatureErrorResponse, {
  DriverDetailsAcceptPlanCandidatureNotAcceptableResponseError,
} from "../common/api/driver-details-accept-plan-candidature-error.response";

type DriverDetailsCandidatureManagementProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  driverUuid: string;
  onAcceptCandidatureSuccess: () => void;
  onDeleteCandidatureSuccess: () => void;
  currentPlanEntries: DriverDetailsDriverPlanEntry[];
};

const DriverDetailsCandidatureManagementComponent: FC<
  DriverDetailsCandidatureManagementProps
> = (props) => {
  const translations =
    userTranslationsHelper.getDriverDetailsCandidatureTranslations().management;

  const { user } = useAppContext();

  const userPermissions = driverDetailsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const [isAcceptCandidatureFetching, setIsAcceptCandidatureFetching] =
    useState(false);
  const [isDeleteCandidatureFetching, setIsDeleteCandidatureFetching] =
    useState(false);

  const [isCandidaturePlanFetching, setIsCandidaturePlanFetching] =
    useState(false);
  const [isCandidatePlanFetchingError, setIsCandidatePlanFetchingError] =
    useState(true);

  const [
    planEntriesAfterCandidatureAccept,
    setPlanEntriesAfterCandidatureAccept,
  ] = useState<DriverDetailsCandidatureManagementPlanEntry[]>([]);

  const onAcceptCandidatureSuccess = () => {
    notificationService.success(
      translations.successCandidatureAcceptanceNotificationText
    );
    props.onAcceptCandidatureSuccess();
  };

  const onAcceptCandidatureFailure = (
    error: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseError
  ) => {
    const errorMessage =
      driverDetailsCandidatureApiService.acceptPlanCandidatureResolveApiErrorLabel(
        error
      );
    notificationService.error(errorMessage);
  };

  const handleAcceptCandidatureResponse = (
    response: DriverDetailsAcceptCandidatureResponse
  ) => {
    if (response.status === 200) {
      onAcceptCandidatureSuccess();
      return;
    }

    const errorResponse =
      response as DriverDetailsAcceptPlanCandidatureErrorResponse;
    onAcceptCandidatureFailure(
      errorResponse.data as DriverDetailsAcceptPlanCandidatureNotAcceptableResponseError
    );
  };

  const acceptCandidature = () => {
    setIsAcceptCandidatureFetching(true);

    driverDetailsCandidatureApiService
      .acceptPlanCandidature(props.driverUuid)
      .then(handleAcceptCandidatureResponse)
      .catch(onAcceptCandidatureFailure)
      .finally(() => setIsAcceptCandidatureFetching(false));
  };

  const onAcceptCandidatureButtonClick = () => {
    acceptCandidature();
  };

  const onDeleteCandidatureSuccess = () => {
    notificationService.success(
      translations.successCandidatureDeletionNotificationText
    );
    props.onDeleteCandidatureSuccess();
  };

  const onDeleteCandidatureFailure = () => {
    notificationService.error(
      translations.failureCandidatureDeletionNotificationText
    );
  };

  const handleDeleteCandidatureResponse = (
    response: DriverDetailsAcceptCandidatureResponse
  ) => {
    if (response.status === 200) {
      onDeleteCandidatureSuccess();
      return;
    }

    onDeleteCandidatureFailure();
  };

  const deleteCandidature = () => {
    setIsDeleteCandidatureFetching(true);

    driverDetailsCandidatureApiService
      .deletePlanCandidature(props.driverUuid)
      .then(handleDeleteCandidatureResponse)
      .catch(onDeleteCandidatureFailure)
      .finally(() => setIsDeleteCandidatureFetching(false));
  };

  const onDeleteCandidatureButtonClick = () => {
    deleteCandidature();
  };

  const onRejectButtonClick = () => {
    props.onClose();
  };

  const onDriverRoutePlansAfterCandidatureFetchSuccess = (
    responsePlanEntries: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry[]
  ) => {
    const planEntriesAfterCandidatureAccept =
      driverDetailsCandidatureManagementPlanEntriesAfterCandidatureApproveFactory.createPlanEntries(
        responsePlanEntries
      );
    setPlanEntriesAfterCandidatureAccept(planEntriesAfterCandidatureAccept);
  };

  const onDriverRoutePlansAfterCandidatureFetchFailure = () => {
    setIsCandidatePlanFetchingError(true);
  };

  const handleDriverRoutePlansAfterCandidatureResponse = (
    response: DriverDetailsRoutePlansAfterCandidatureApprovalResponse
  ) => {
    if (response.status === 200) {
      onDriverRoutePlansAfterCandidatureFetchSuccess(response.data.entries);
      return;
    }

    onDriverRoutePlansAfterCandidatureFetchFailure();
  };

  const fetchDriverRoutePlansAfterCandidatureApproval = () => {
    setIsCandidaturePlanFetching(true);
    setIsCandidatePlanFetchingError(false);

    driverDetailsCandidatureApiService
      .fetchDriverRoutePlansAfterCandidatureApproval(props.driverUuid)
      .then(handleDriverRoutePlansAfterCandidatureResponse)
      .catch(onDriverRoutePlansAfterCandidatureFetchFailure)
      .finally(() => setIsCandidaturePlanFetching(false));
  };

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    fetchDriverRoutePlansAfterCandidatureApproval();
  }, [props.isOpen]);

  const ErrorContent = (
    <NoticeBoxComponent
      type={NoticeBoxType.DANGER}
      classNames={{ root: "w-100" }}
    >
      {translations.failureCandidatureFetchNotificationText}
    </NoticeBoxComponent>
  );

  const Content = (
    <>
      <div className="mb-4">
        {translations.planAfterApprovalOfCandidatureHeadingText}
      </div>
      {planEntriesAfterCandidatureAccept.map((route) => {
        const currentPlanEntryWaypoints =
          props.currentPlanEntries.find((entry) => entry.uuid === route.uuid)
            ?.waypoints ?? [];
        return (
          <div key={route.uuid} className="mb-4">
            <div>
              {route.id
                ? translations.planAfterApprovalOfCandidatureRouteIdTemplateText.replace(
                    "#{routeId}",
                    String(route.id)
                  )
                : translations.planAfterApprovalOfCandidatureNewRouteText}
            </div>
            <DriverDetailsCandidatureManagementRouteListingComponent
              routeWaypoints={route.waypoints}
              currentWaypoints={currentPlanEntryWaypoints}
            />
          </div>
        );
      })}
    </>
  );

  const isAcceptButtonVisible =
    userPermissions.hasAccessToAcceptCandidature &&
    !isCandidatePlanFetchingError &&
    !isCandidaturePlanFetching;

  const isDeleteButtonVisible =
    userPermissions.hasAccessToDeleteCandidature &&
    !isCandidatePlanFetchingError &&
    !isCandidaturePlanFetching;

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={{ title: translations.headingText }}
      classNames={{
        content: "driver_details_candidature_route_listing w-100",
      }}
      actions={[
        isAcceptButtonVisible && (
          <ButtonComponent
            onClick={onAcceptCandidatureButtonClick}
            title={translations.acceptCandidatureButtonTitle}
            type="success"
            isLoading={isAcceptCandidatureFetching}
          >
            {translations.acceptCandidatureButtonText}
          </ButtonComponent>
        ),
        isDeleteButtonVisible && (
          <ButtonComponent
            onClick={onDeleteCandidatureButtonClick}
            title={translations.deleteCandidatureButtonTitle}
            isLoading={isDeleteCandidatureFetching}
            type="danger"
          >
            {translations.deleteCandidatureButtonText}
          </ButtonComponent>
        ),
        <ButtonComponent
          onClick={onRejectButtonClick}
          title={translations.rejectButtonTitle}
          type="brand"
        >
          {translations.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <Row>
        <Column>
          {isCandidaturePlanFetching ? (
            <div className="w-100">
              <LoaderComponent type="primary" />
            </div>
          ) : isCandidatePlanFetchingError ? (
            ErrorContent
          ) : (
            Content
          )}
        </Column>
      </Row>
    </ModalComponent>
  );
};

export default DriverDetailsCandidatureManagementComponent;
