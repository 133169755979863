import railyApiService from "../raily-api.service";
import taxiFleetPartnerAddRequestFactory from "./add/taxi-fleet-partner-add-request.factory";
import TaxiFleetPartnerAddRequest, {
  TaxiFleetPartnerAddRequestBody,
} from "./add/taxi-fleet-partner-add.request";
import taxiFleetPartnerUpdateRequestFactory from "./update/taxi-fleet-partner-update-request.factory";
import TaxiFleetPartnerUpdateRequest, {
  TaxiFleetPartnerUpdateRequestBody,
} from "./update/taxi-fleet-partner-update.request";
import TaxiFleetPartnerUpdateResponse from "./update/taxi-fleet-partner-update.response";
import TaxiFleetPartnerListRequest from "./list/taxi-fleet-partner-list.request";
import TaxiFleetPartnerListResponse from "./list/taxi-fleet-partner-list.response";
import TaxiFleetPartnerDetailsResponse from "./details/taxi-fleet-partner-details.response";
import TaxiFleetPartnerDetailsRequest from "./details/taxi-fleet-partner-details.request";

const taxiFleetPartnerApiService = () => {
  const add = async (
    request: TaxiFleetPartnerAddRequest,
    abortSignal: AbortSignal
  ) => {
    const url = `/taxi-corporations/${request.taxiCorporationUuid}/fleet-partners`;

    const body: TaxiFleetPartnerAddRequestBody =
      taxiFleetPartnerAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const getList = async (
    request: TaxiFleetPartnerListRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiFleetPartnerListResponse> => {
    const url = `/taxi-corporations/${request.taxiCorporationUuid}/fleet-partners`;

    return railyApiService().get<TaxiFleetPartnerListResponse>({
      url,
      abortSignal,
    });
  };

  const update = async (
    request: TaxiFleetPartnerUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiFleetPartnerUpdateResponse> => {
    const url = `/taxi-corporations/${request.taxiCorporationId}/fleet-partners`;

    const body: TaxiFleetPartnerUpdateRequestBody =
      taxiFleetPartnerUpdateRequestFactory.createBody(request);

    return railyApiService().put<TaxiFleetPartnerUpdateResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const getDetails = async (
    request: TaxiFleetPartnerDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiFleetPartnerDetailsResponse> => {
    const url = `/partners/${request.fleetPartnerId}`;

    return railyApiService().get<TaxiFleetPartnerDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return {
    add,
    getList,
    update,
    getDetails,
  };
};

export default taxiFleetPartnerApiService;
