import SignUpDriverFormUserAddress from "../../common/types/sign-up-driver-form-user-address";

const getAddressLabel = (address: SignUpDriverFormUserAddress) => {
  return `${address.street} ${address.houseNumber}${
    address.apartmentNumber && `/${address.apartmentNumber}`
  } ${address.town}, ${address.zipCode}`;
};

const signUpDriverUserAddressHelper = {
  getAddressLabel,
};

export default signUpDriverUserAddressHelper;
