import OperatorListRequest, {
  OperatorListRequestQueryParams,
} from "./operator-list.request";

const createQueryParams = (
  data: OperatorListRequest
): OperatorListRequestQueryParams => {
  return {
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
    limit: data.limit,
    offset: data.offset,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    username: data.username,
    order: data.order,
    statusCriteria: data.statusCriteria,
  };
};

const operatorListRequestFactory = {
  createQueryParams,
};

export default operatorListRequestFactory;
