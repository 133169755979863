import PhoneNumber from "../../../types/phone-number";

export enum CargoContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type CargoOfficerListItemTaxiCorporation = {
  uuid: string;
  taxId: string;
  companyId: string | null;
  nationalCourtRegister: string | null;
  name: string;
  displayName: string;
  address: string;
  contractMaintenancePolicy: CargoContractMaintenancePolicy;
  notes: string | null;
};

export type CargoOfficerListItem = {
  uuid: string;
  firstName: string;
  lastName: string;
  username: string;
  removalDate: Date | null;
  email: string;
  phoneNumber: PhoneNumber;
  alternativePhoneNumber: PhoneNumber | null;
  cargoCompany: CargoOfficerListItemTaxiCorporation;
};

type CargoOfficerList = {
  data: CargoOfficerListItem[];
  totalCount: number;
};

export default CargoOfficerList;
