import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode } from "react";
import ButtonComponent from "../../common/components/button/button.component";

type AppAsidePinButtonProps = {
  isPinned: boolean;
  onClick: () => void;
  idForTesting?: string;
};

const AppAsidePinButtonComponent: FC<AppAsidePinButtonProps> = (props) => {
  const Button: FC<{ children: ReactNode; onClick: () => void }> = (
    buttonProps
  ) => {
    return (
      <ButtonComponent
        onClick={buttonProps.onClick}
        classNames={{ root: "app_aside_pin_button" }}
        idForTesting={props.idForTesting}
      >
        {buttonProps.children}
      </ButtonComponent>
    );
  };

  const Icon: FC<{ icon: IconProp }> = (props) => {
    return <FontAwesomeIcon icon={props.icon} size="sm" />;
  };

  if (props.isPinned) {
    return (
      <Button onClick={props.onClick}>
        <Icon icon={faChevronLeft} />
      </Button>
    );
  }

  return (
    <Button onClick={props.onClick}>
      <Icon icon={faChevronRight} />
    </Button>
  );
};

export default AppAsidePinButtonComponent;
