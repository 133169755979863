import CargoTaxiContractDetailsRequest from "../../../api/raily/cargo-taxi-contract/details/cargo-taxi-contract-details.request";
import CargoTaxiContractDetailsLoadParams from "./cargo-taxi-contract-details-load-params";

const create = (
  params: CargoTaxiContractDetailsLoadParams
): CargoTaxiContractDetailsRequest => {
  return {
    contractId: params.contractUuid,
  };
};

const cargoTaxiContractDetailsRequestFactory = {
  create,
};

export default cargoTaxiContractDetailsRequestFactory;
