import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import { UserDriverListingRouteQueryParams } from "../../../common/routes/types/user-driver-listing-route-params";
import DriverListingFilter from "../common/types/driver-listing-filter";
import DriverListingSortKey from "../common/types/driver-listing-sort-key";
import driverListingFiltersService from "./driver-listing-filters.service";

const useDriverListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<UserDriverListingRouteQueryParams>();

  const getFilters = (): DriverListingFilter[] => {
    return driverListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return driverListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return driverListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): DriverListingSortKey | undefined => {
    return driverListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: DriverListingFilter[],
    sortKey: DriverListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams = driverListingFiltersService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useDriverListingFiltersDao;
