enum TaxiTransferedOrderSettlementListingSortKey {
  ORDER_START_DATE_ASC = "ORDER_START_DATE_ASC",
  ORDER_START_DATE_DESC = "ORDER_START_DATE_DESC",
  INTERNAL_ORDER_ID_ASC = "INTERNAL_ORDER_ID_ASC",
  INTERNAL_ORDER_ID_DESC = "INTERNAL_ORDER_ID_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  DISTANCE_COST_ASC = "DISTANCE_COST_ASC",
  DISTANCE_COST_DESC = "DISTANCE_COST_DESC",
  STOPOVER_COST_ASC = "STOPOVER_COST_ASC",
  STOPOVER_COST_DESC = "STOPOVER_COST_DESC",
  TOLL_ROADS_COST_ASC = "TOLL_ROADS_COST_ASC",
  TOLL_ROADS_COST_DESC = "TOLL_ROADS_COST_DESC",
  TOTAL_COST_ASC = "TOTAL_COST_ASC",
  TOTAL_COST_DESC = "TOTAL_COST_DESC",
  PRODUCER_ASC = "PRODUCER_ASC",
  PRODUCER_DESC = "PRODUCER_DESC",
  CONSUMER_ASC = "CONSUMER_ASC",
  CONSUMER_DESC = "CONSUMER_DESC",
  BILLING_MODEL_ASC = "BILLING_MODEL_ASC",
  BILLING_MODEL_DESC = "BILLING_MODEL_DESC",
}

export default TaxiTransferedOrderSettlementListingSortKey;
