import TaxiFleetPartnerUpdateRequest, {
  TaxiFleetPartnerUpdateRequestBody,
} from "./taxi-fleet-partner-update.request";

const createBody = (
  request: TaxiFleetPartnerUpdateRequest
): TaxiFleetPartnerUpdateRequestBody => {
  return {
    fleetPartnerId: request.fleetPartnerId,
    name: request.name,
  };
};

const taxiFleetPartnerUpdateRequestFactory = {
  createBody,
};

export default taxiFleetPartnerUpdateRequestFactory;
