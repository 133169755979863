import SignUpPassengerAddress from "../../common/types/sign-up-passenger-address";

const getAddressLabel = (address: SignUpPassengerAddress) => {
  return `${address.street} ${address.houseNumber}${
    address.apartmentNumber && `/${address.apartmentNumber}`
  } ${address.town}, ${address.zipCode}`;
};

const signUpPassengerUserAddressHelper = {
  getAddressLabel,
};

export default signUpPassengerUserAddressHelper;
