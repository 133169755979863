export enum CargoOfficerListRequestOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

export enum CargoOfficerListRequestStatusCriteria {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  ALL = "ALL",
}

export type CargoOfficerListRequestQueryParams = {
  cargoCompany: string | undefined;
  cargoCompanyId: string | undefined;
  order: CargoOfficerListRequestOrder | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  username: string | undefined;
  email: string | undefined;
  statusCriteria: CargoOfficerListRequestStatusCriteria | undefined;
  offset: number | undefined;
  limit: number | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
};

type CargoOfficerListRequest = {
  cargoCompany?: string;
  cargoCompanyId?: string;
  order?: CargoOfficerListRequestOrder;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  statusCriteria?: CargoOfficerListRequestStatusCriteria;
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
};

export default CargoOfficerListRequest;
