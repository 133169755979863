import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiOfficerListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView"
  | "hasAccessToEditUser"
  | "hasAccessToDeleteUser"
  | "hasVisibleAddTaxiOfficerButton";

export type TaxiOfficerListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiOfficerListingUserPermissionOption>;

export type TaxiOfficerListingUserPermissions =
  UserPermissions<TaxiOfficerListingUserPermissionOption>;

const taxiOfficerListingUserPermissionDefinition: TaxiOfficerListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToEditUser: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToDeleteUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasVisibleAddTaxiOfficerButton: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  };

export default taxiOfficerListingUserPermissionDefinition;
