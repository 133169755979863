import appConfig from "../../../../../app.config";
import urlService from "../../../../../common/utils/url/url.service";
import authService from "../../../../auth/common/auth.service";
import BillingsAddressesRequest from "./billing-addresses.request";
import BillingAddressesResponse from "./billing-addresses.response";
import BillingGpsResponse from "./billing-gps-data.response";

const fetchAddresses = (
  orderId: string,
  request: BillingsAddressesRequest
): Promise<BillingAddressesResponse> => {
  const path = `/billings/orders/${orderId}/addresses`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchGpsData = (orderId: string): Promise<BillingGpsResponse> => {
  const path = `/gps-gateway/records/cargo-order/${orderId}/gps-time-nodes`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const billingsApiService = {
  fetchAddresses,
  fetchGpsData,
};

export default billingsApiService;
