import OperatorListLoadParams from "./operator-list-load-params";
import operatorService from "../operator.service";
import OperatorList from "./operator-list";
import OperatorListError from "./operator-list-error";
import useAsyncData from "../../../hooks/use-async-data";

const useOperatorList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<OperatorList>({ data: [], totalCount: 0 });

  const load = async (
    params: OperatorListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const operatorList = await operatorService.getList(params, signal);

      setData(operatorList);
    } catch (_error) {
      const error = _error as OperatorListError;

      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useOperatorList;
