import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionCustomSelectData,
  ListingFilterDefinitionOptionDefaultSelectData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import dateService from "../../../../../common/utils/date/date.service";
import planningTranslationsHelper from "../../../../../languages/planning-translations.helper";
import PlanningOrderDriverPlanListingFilter, {
  PlanningOrderDriverPlanListingAddressFilter,
  PlanningOrderDriverPlanListingCargoOrderFilter,
  PlanningOrderDriverPlanListingDriverNameFilter,
  PlanningOrderDriverPlanListingResultFilter,
  PlanningOrderDriverPlanListingResultFilterValue,
} from "../common/planning-order-driver-plan-listing-filter";
import PlanningOrderDriverPlanListingFilterType from "../common/planning-order-driver-plan-listing-filter-type";
import PlanningOrderDriverPlanListingSortKey from "../common/planning-order-driver-plan-listing-sort-key";

const planningOrderDriverPlanListingFilterHelper = () => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing;

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForAddress = (
    filterValue: PlanningOrderDriverPlanListingAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    if (filterValue.startDate) {
      if (filterValue.endDate) {
        return {
          text: translations.filters.addressWithRangeDateLabel
            .replace("#{address}", filterValue.address)
            .replace(
              "#{address}",
              dateService.formatDateTime(filterValue.endDate)
            )
            .replace(
              "#{address}",
              dateService.formatDateTime(filterValue.startDate)
            ),
          title: translations.filters.addressWithRangeDateTitle
            .replace("#{address}", filterValue.address)
            .replace(
              "#{endDate}",
              dateService.formatDateTime(filterValue.endDate)
            )
            .replace(
              "#{startDate}",
              dateService.formatDateTime(filterValue.startDate)
            ),
        };
      }

      return {
        text: translations.filters.addressWithStartDateLabel
          .replace("#{address}", filterValue.address)
          .replace(
            "#{address}",
            dateService.formatDateTime(filterValue.startDate)
          ),
        title: translations.filters.addressWithStartDateTitle
          .replace("#{address}", filterValue.address)
          .replace(
            "#{startDate}",
            dateService.formatDateTime(filterValue.startDate)
          ),
      };
    }

    if (filterValue.endDate) {
      return {
        text: translations.filters.addressWithEndDateLabel
          .replace("#{address}", filterValue.address)
          .replace(
            "#{address}",
            dateService.formatDateTime(filterValue.endDate)
          ),
        title: translations.filters.addressWithEndDateTitle
          .replace("#{address}", filterValue.address)
          .replace(
            "#{endDate}",
            dateService.formatDateTime(filterValue.endDate)
          ),
      };
    }

    return {
      text: translations.filters.addressLabel.replace(
        "#{address}",
        filterValue.address
      ),
      title: translations.filters.addressTitle.replace(
        "#{address}",
        filterValue.address
      ),
    };
  };

  const getBadgeForDriverName = (
    filterValue: PlanningOrderDriverPlanListingDriverNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.driverNameWithDateLabel,
      translations.filters.driverNameWithDateTitle
    );
  };

  const getBadgeForCargoOrder = (
    filterValue: PlanningOrderDriverPlanListingCargoOrderFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.cargoOrderWithDateLabel,
      translations.filters.cargoOrderWithDateTitle
    );
  };

  const getBadgeForResult = (
    filterValue: PlanningOrderDriverPlanListingResultFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options = [
      {
        label: translations.filters.newPlanResultWithDateLabel,
        title: translations.filters.newPlanResultWithDateTitle,
        value: PlanningOrderDriverPlanListingResultFilterValue.NEW_PLAN,
      },
      {
        label: translations.filters.existingPlanResultWithDateLabel,
        title: translations.filters.existingPlanResultWithDateTitle,
        value: PlanningOrderDriverPlanListingResultFilterValue.EXISTING_PLAN,
      },
    ];

    const option = options.find((option) => option.value === filterValue);
    return { text: option!.label, title: option!.title };
  };

  const getSelectDefinitionForAddress = (
    onAddressSelect: () => void
  ): ListingFilterDefinitionOptionCustomSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.CUSTOM,
      option: {
        label: translations.filters.search.addressLabel,
        onClick: onAddressSelect,
      },
    };
  };

  const getSelectDefinitionForDriverName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.driverNameLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForCargoOrder = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.cargoOrderLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForResult =
    (): ListingFilterDefinitionOptionDefaultSelectData => {
      const options: ListingFilterDefinitionOptionDefaultSelectData["options"] =
        [
          {
            label: translations.filters.search.newPlanResultLabel,
            value: PlanningOrderDriverPlanListingResultFilterValue.NEW_PLAN,
          },
          {
            label: translations.filters.search.existingPlanResultLabel,
            value:
              PlanningOrderDriverPlanListingResultFilterValue.EXISTING_PLAN,
          },
        ];

      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: options,
      };
    };

  const getSelectDataByType = (
    filterType: PlanningOrderDriverPlanListingFilterType,
    query: string,
    onAddressSelect: () => void
  ) => {
    const options: Record<
      PlanningOrderDriverPlanListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [PlanningOrderDriverPlanListingFilterType.ADDRESS]: () =>
        getSelectDefinitionForAddress(onAddressSelect),
      [PlanningOrderDriverPlanListingFilterType.DRIVER_NAME]:
        getSelectDefinitionForDriverName,
      [PlanningOrderDriverPlanListingFilterType.CARGO_ORDER]:
        getSelectDefinitionForCargoOrder,
      [PlanningOrderDriverPlanListingFilterType.RESULT]:
        getSelectDefinitionForResult,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeDefinitionByType = (
    filterType: PlanningOrderDriverPlanListingFilterType,
    filterValue: PlanningOrderDriverPlanListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      PlanningOrderDriverPlanListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [PlanningOrderDriverPlanListingFilterType.ADDRESS]: () =>
        getBadgeForAddress(
          filterValue as PlanningOrderDriverPlanListingAddressFilter["value"]
        ),
      [PlanningOrderDriverPlanListingFilterType.DRIVER_NAME]: () =>
        getBadgeForDriverName(
          filterValue as PlanningOrderDriverPlanListingDriverNameFilter["value"]
        ),
      [PlanningOrderDriverPlanListingFilterType.CARGO_ORDER]: () =>
        getBadgeForCargoOrder(
          filterValue as PlanningOrderDriverPlanListingCargoOrderFilter["value"]
        ),
      [PlanningOrderDriverPlanListingFilterType.RESULT]: () =>
        getBadgeForResult(
          filterValue as PlanningOrderDriverPlanListingResultFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: PlanningOrderDriverPlanListingFilterType,
    onAddressSelect: () => void
  ): ListingFilterDefinitionOption<PlanningOrderDriverPlanListingFilter> => {
    return {
      getSelectData: (query: string) =>
        getSelectDataByType(filterType, query, onAddressSelect),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions = (
    onAddressSelect: () => void
  ): ListingFilterDefinitionOption<PlanningOrderDriverPlanListingFilter>[] => {
    return Object.values(PlanningOrderDriverPlanListingFilterType).map(
      (filterType) => getFilterDefinitionByType(filterType, onAddressSelect)
    );
  };

  const getFilterDefinition = (
    onAddressSelect: () => void
  ): ListingFilterDefinition<PlanningOrderDriverPlanListingFilter> => {
    return {
      options: getFilterDefinitionOptions(onAddressSelect),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.driverNameAscLabel,
        value: PlanningOrderDriverPlanListingSortKey.DRIVER_NAME_ASC,
      },
      {
        label: translations.sort.driverNameDescLabel,
        value: PlanningOrderDriverPlanListingSortKey.DRIVER_NAME_DESC,
      },
      {
        label: translations.sort.routeStartDateAscLabel,
        value: PlanningOrderDriverPlanListingSortKey.ROUTE_START_DATE_ASC,
      },
      {
        label: translations.sort.routeStartDateDescLabel,
        value: PlanningOrderDriverPlanListingSortKey.ROUTE_START_DATE_DESC,
      },
      {
        label: translations.sort.routeEndDateAscLabel,
        value: PlanningOrderDriverPlanListingSortKey.ROUTE_END_DATE_ASC,
      },
      {
        label: translations.sort.routeEndDateDescLabel,
        value: PlanningOrderDriverPlanListingSortKey.ROUTE_END_DATE_DESC,
      },
    ];
  };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default planningOrderDriverPlanListingFilterHelper;
