import TaxiDriverContractDetailsResponse, {
  TaxiDriverContractDetailsResponseItemBillingModel,
} from "../../../api/raily/taxi-driver-contract/details/taxi-driver-contract-details.response";
import BillingModel from "../../../types/billing-model";
import TaxiDriverContractDetails from "./taxi-driver-contract-details";

const createBillingModel = (
  responseBillingModel: TaxiDriverContractDetailsResponseItemBillingModel
): BillingModel => {
  switch (responseBillingModel) {
    case TaxiDriverContractDetailsResponseItemBillingModel.AB:
      return BillingModel.AB;
    case TaxiDriverContractDetailsResponseItemBillingModel.ABA:
      return BillingModel.ABA;
    case TaxiDriverContractDetailsResponseItemBillingModel.SABS:
      return BillingModel.SABS;
    case TaxiDriverContractDetailsResponseItemBillingModel.OTHER:
      return BillingModel.OTHER;
  }
};

const create = (
  response: TaxiDriverContractDetailsResponse
): TaxiDriverContractDetails => {
  return {
    billingModel: createBillingModel(response.data.model),
    createdBy: response.data.createdBy,
    creationDate: response.data.createdAt,
    distanceRate: response.data.distanceRate,
    freeStoppingPeriodMinutes: response.data.haltingTimeAfterMinutes,
    isActive: !response.data.disabled,
    modificationDate: response.data.modifiedAt,
    modifiedBy: response.data.modifiedBy,
    periodOfValidity: {
      from: response.data.validSince,
      to: response.data.validTo,
    },
    stoppingRate: response.data.haltingTimeRate,
    taxiDriverAssociationUuid: response.data.taxiDriverAssociationId,
    uuid: response.data.id,
    notes: response.data.description,
  };
};

const taxiDriverContractDetailsFactory = {
  create,
};

export default taxiDriverContractDetailsFactory;
