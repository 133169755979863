import CargoOrderFinishedListReportRequest, {
  CargoOrderFinishedListReportRequestQueryParams,
} from "./cargo-order-finished-list-report-request";

const createQueryParams = (
  data: CargoOrderFinishedListReportRequest
): CargoOrderFinishedListReportRequestQueryParams => {
  return {
    client: data.client,
    dispatch: data.dispatch,
    driver: data.driver,
    passenger: data.passenger,
    taxi: data.taxi,
    externalId: data.externalId,
    isApproved: data.isApproved,
    isCancelled: data.isCancelled,
    address: data.address,
    startAddress: data.startAddress,
    midAddress: data.midAddress,
    endAddress: data.endAddress,
    publicStatus: data.publicStatus,
    order: data.order,
    startTimeSince: data.startTimeSince,
    startTimeTo: data.startTimeTo,
    humanId: data.internalOrderId,
  };
};

const cargoOrderFinishedListReportRequestFactory = {
  createQueryParams,
};

export default cargoOrderFinishedListReportRequestFactory;
