export enum DelegationAddFromMileagesRequestTimezone {
  EUROPE_WARSAW = "Europe/Warsaw",
  UTC = "UTC",
}

export type DelegationAddFromMileagesRequestParams = {
  timezone?: DelegationAddFromMileagesRequestTimezone;
};

export type DelegationAddFromMileagesRequestBody = {
  cargoCompanyId: string;
  workerId: string;
  mileageIds: string[];
};

type DelegationAddFromMileagesRequest = {
  cargoCompanyUuid: string;
  workerUuid: string;
  mileageUuids: string[];
  timezone?: DelegationAddFromMileagesRequestTimezone;
};

export default DelegationAddFromMileagesRequest;
