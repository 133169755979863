import { OrderRouteEditRouteWaypointGroup } from "./../types/order-route-edit-route-waypoint";
import {
  CargoEditExternalCargoItemSourceData,
  CargoEditSourceType,
  OrderRouteEditNodeMetaSubject,
  OrderRouteEditOrderDetailsData,
  OrderRouteEditSeqGroupNode,
  OrderRouteEditSeqNode,
} from "./../api/order-route-edit-order-details.response";
import { OrderRouteEditCargoAddressesResponseDataAddress } from "../api/order-route-edit-cargo-addresses.response";

import {
  OrderRouteEditPassengersResponsePassenger,
  OrderRouteEditPassengersResponsePassengerAddress,
} from "../api/order-route-edit-passengers.response";
import OrderRouteEditSolvedRidesRequest from "../api/order-route-edit-solved-rides.request";
import { OrderRouteEditTaxiContractsResponseData } from "../api/order-route-edit-taxi-contracts.response";
import OrderRouteEditRequestBody, {
  OrderRouteEditRequestCargoOrderRide,
  OrderRouteEditRequestExternalCargoItemSource,
  OrderRouteEditRequestInternalCargoItemSource,
  OrderRouteEditRequestRideSeqItem,
  OrderRouteEditRequestRideWaypoint,
  OrderRouteEditSeqGroupNodeRequest,
  OrderRouteEditSeqNodeRequest,
} from "../api/order-route-edit.request";
import orderAddHelper from "../helper/order-route-edit.helper";
import OrderRouteEditPassenger, {
  OrderRouteEditExternalPassenger,
  OrderRouteEditInternalPassenger,
  OrderRouteEditPassengerAddress,
  OrderRouteEditPassengerType,
} from "../types/order-route-edit-passenger";
import OrderRouteEditPassengerSelectOption from "../types/order-route-edit-passenger-select-option";
import OrderRouteEditRouteAddress from "../types/order-route-edit-route-address";
import OrderRouteEditRouteAddressSelectOption from "../types/order-route-edit-route-address-select-option";
import OrderRouteEditRouteItem, {
  OrderRouteEditRouteWaypoint,
  OrderRouteEditRouteWaypointGroupWaypoint,
} from "../types/order-route-edit-route-waypoint";

import OrderRouteEditOrderDetails from "../types/order-route-edit-order-details";
import OrderRouteEditTaxiContract from "../types/order-route-edit-taxi-contract";
import OrderRouteEditPassengerListItem from "../types/order-route-edit-passenger-list-item";
import orderRouteEditHelper from "../helper/order-route-edit.helper";
import OrderRouteEditCargoAddress from "../types/order-route-edit-cargo-address";

const createOrderDetails = (
  prevOrderDetails: OrderRouteEditOrderDetailsData
): OrderRouteEditOrderDetails => {
  return {
    cargoCompanyId:
      prevOrderDetails.cargo_order.dispatcher.dispatch.cargo_company.id,
    dispatcherId: prevOrderDetails.cargo_order.dispatcher.id,
    taxiCorporationId: prevOrderDetails.target_taxi_id,
    humanId: prevOrderDetails.cargo_order.human_id,
    forceAllowToll: prevOrderDetails.force_allow_toll,
  };
};

const createExternalPassengersList = (
  prevOrderDetails: OrderRouteEditOrderDetailsData
): OrderRouteEditPassengerListItem[] => {
  const externalPassengersList: OrderRouteEditPassengerListItem[] = [];

  const externalSubjects =
    prevOrderDetails.cargo_order.cargo_items_source_list.filter(
      (s) => s.cargo_item_source.source_type === CargoEditSourceType.EXTERNAL
    );

  if (externalSubjects.length) {
    externalSubjects.forEach((s) => {
      const subjectId = s.cargo_subject_id;
      const subjectData =
        s.cargo_item_source as CargoEditExternalCargoItemSourceData;

      const externalPassenger: OrderRouteEditPassengerListItem = {
        uuid: subjectId,
        passenger: {
          firstName: subjectData.first_name,
          lastName: subjectData.last_name,
          uuid: subjectId,
          dispatchName: subjectData.dispatch_name ?? "",
          externalId: subjectData.external_id ?? "",
          mobile: subjectData.phone_no,
          type: OrderRouteEditPassengerType.EXTERNAL,
        },
      };

      externalPassengersList.push(externalPassenger);
    });
  }

  return externalPassengersList;
};

const createInternalPassengersList = (
  prevOrderDetails: OrderRouteEditOrderDetailsData
): OrderRouteEditPassengerListItem[] => {
  return prevOrderDetails.passengers.map((p) => {
    const externalId =
      prevOrderDetails.cargo_order.cargo_items_source_list.find(
        (item) => item.cargo_subject_id === p.id
      )?.cargo_item_source.external_id ?? "";

    return {
      uuid: p.id,
      passenger: {
        uuid: p.id,
        firstName: p.first_name,
        lastName: p.last_name,
        type: OrderRouteEditPassengerType.INTERNAL,
        externalId,
        addresses: p.passenger.addresses.map((a) => {
          return {
            uuid: a.id,
            displayName: a.display_name,
            latitude: a.lat,
            longitude: a.lon,
            apartmentNumber: a.apartment ?? "",
            houseNumber: a.house_no ?? "",
            street: a.street ?? "",
            town: a.town ?? "",
            zipCode: a.zip_code ?? "",
          };
        }),
      },
    };
  });
};

const createPassengersList = (
  prevOrderDetails: OrderRouteEditOrderDetailsData
): OrderRouteEditPassengerListItem[] => {
  const externalPassengersList = createExternalPassengersList(prevOrderDetails);
  const internalPassengersList = createInternalPassengersList(prevOrderDetails);

  return [...internalPassengersList, ...externalPassengersList];
};

const matchPassengerFromList = (
  passengerList: OrderRouteEditPassengerListItem[],
  subject: OrderRouteEditNodeMetaSubject
): OrderRouteEditPassengerListItem => {
  return passengerList.find((passenger) => passenger.uuid === subject.id)!;
};

const createSingleWaypoint = (
  node: OrderRouteEditSeqNode,
  passengerList: OrderRouteEditPassengerListItem[]
): OrderRouteEditRouteWaypoint => {
  return {
    uuid: node.meta.id,
    address: {
      latitude: node.lat,
      longitude: node.lon,
      displayName: node.display_name,
      uuid: node.meta.id,
      apartmentNumber: node.apartment ?? "",
      houseNumber: node.house_no ?? "",
      street: node.street ?? "",
      town: node.town ?? "",
      zipCode: node.zip_code ?? "",
    },
    date: node.time !== null ? new Date(node.time) : null,
    haltingTimeMinutes: node.halting_time ? node.halting_time / 60 : null,
    onboardingPassengerListItems: node.meta.cargo_enter.subjects.map(
      (subject) => matchPassengerFromList(passengerList, subject)
    ),
    outboardingPassengerListItems: node.meta.cargo_exit.subjects.map(
      (subject) => matchPassengerFromList(passengerList, subject)
    ),
  };
};

const createWaypointGroup = (
  node: OrderRouteEditSeqGroupNode,
  passengerList: OrderRouteEditPassengerListItem[]
): OrderRouteEditRouteWaypointGroup => {
  return {
    uuid: node.meta.id,
    waypoints: node.seq.map((waypoint) =>
      createSingleWaypoint(waypoint, passengerList)
    ),
  };
};

const createRouteItems = (
  prevOrderDetails: OrderRouteEditOrderDetailsData,
  passengerList: OrderRouteEditPassengerListItem[]
): OrderRouteEditRouteItem[] => {
  const routeItems: OrderRouteEditRouteItem[] = [];
  const prevRide = prevOrderDetails.cargo_order.ride;

  prevRide.seq.forEach((node) => {
    if (orderRouteEditHelper.checkIsSingleNode(node)) {
      routeItems.push(createSingleWaypoint(node, passengerList));
    } else {
      routeItems.push(createWaypointGroup(node, passengerList));
    }
  });

  return routeItems;
};

const createTaxiContract = (
  taxiContractsResponseData: OrderRouteEditTaxiContractsResponseData,
  taxiCorporationId: string | null
): OrderRouteEditTaxiContract => {
  if (taxiCorporationId) {
    const taxiContract = taxiContractsResponseData.taxi_contracts.find(
      (contract) => contract.taxi_corporation.id === taxiCorporationId
    );

    return {
      contractPermitsTollRoads: taxiContract?.allow_charge_during_ride!,
    };
  }

  return {
    contractPermitsTollRoads:
      taxiContractsResponseData.raily_contract?.allow_charge_during_ride!,
  };
};

const createPassengerAddress = (
  responseItem: OrderRouteEditPassengersResponsePassengerAddress
): OrderRouteEditPassengerAddress => {
  return {
    latitude: responseItem.lat,
    longitude: responseItem.lon,
    displayName: responseItem.display_name,
    uuid: responseItem.id,
    apartmentNumber: responseItem.apartment ?? "",
    houseNumber: responseItem.house_no ?? "",
    street: responseItem.street ?? "",
    town: responseItem.town ?? "",
    zipCode: responseItem.zip_code ?? "",
  };
};

const createPassenger = (
  responseItem: OrderRouteEditPassengersResponsePassenger
): OrderRouteEditPassenger => {
  return {
    uuid: responseItem.user.id,
    firstName: responseItem.user.first_name,
    lastName: responseItem.user.last_name,
    externalId: "",
    addresses: createPassengerAddresses(responseItem.addresses),
    type: OrderRouteEditPassengerType.INTERNAL,
  };
};

const createPassengerAddresses = (
  responsePassengerAddresses: OrderRouteEditPassengersResponsePassengerAddress[]
): OrderRouteEditPassengerAddress[] => {
  return responsePassengerAddresses.map(createPassengerAddress);
};

const createPassengers = (
  responsePassengers: OrderRouteEditPassengersResponsePassenger[]
): OrderRouteEditPassenger[] => {
  return responsePassengers.map(createPassenger);
};

const createPassengerSelectOption = (
  passenger: OrderRouteEditPassenger
): OrderRouteEditPassengerSelectOption => {
  return {
    label: `${passenger.firstName} ${passenger.lastName}`,
    value: passenger,
  };
};

const createPassengerSelectOptions = (
  responsePassengers: OrderRouteEditPassengersResponsePassenger[]
): OrderRouteEditPassengerSelectOption[] => {
  const passengers = createPassengers(responsePassengers);

  return passengers.map(createPassengerSelectOption);
};

const createCargoAddress = (
  responseCargoAddress: OrderRouteEditCargoAddressesResponseDataAddress
): OrderRouteEditCargoAddress => {
  return {
    uuid: responseCargoAddress.id,
    displayName: responseCargoAddress.display_name,
    latitude: responseCargoAddress.lat,
    longitude: responseCargoAddress.lon,
    apartmentNumber: responseCargoAddress.apartment ?? "",
    houseNumber: responseCargoAddress.house_no ?? "",
    street: responseCargoAddress.street ?? "",
    town: responseCargoAddress.town ?? "",
    zipCode: responseCargoAddress.zip_code ?? "",
    type: responseCargoAddress.cargo_address_type,
  };
};

const createCargoAddresses = (
  responseCargoAddresses: OrderRouteEditCargoAddressesResponseDataAddress[]
): OrderRouteEditCargoAddress[] => {
  return responseCargoAddresses.map(createCargoAddress);
};

const createRouteAddressSelectOption = (
  routeAddress: OrderRouteEditRouteAddress
): OrderRouteEditRouteAddressSelectOption => {
  return {
    label: routeAddress.subText || routeAddress.displayName,
    value: routeAddress,
  };
};

const createRouteAddressesSelectOptions = (
  routeAddresses: OrderRouteEditRouteAddress[]
): OrderRouteEditRouteAddressSelectOption[] => {
  return routeAddresses.map(createRouteAddressSelectOption);
};

const createRailyRideWaypointFromRouteWaypoint = (
  routeWaypoint: OrderRouteEditRouteWaypoint
) => {
  const newWaypoint: OrderRouteEditRequestRideWaypoint = {
    lat: routeWaypoint.address!.latitude,
    lon: routeWaypoint.address!.longitude,
    display_name: routeWaypoint.address!.displayName,
    halting_time: routeWaypoint.haltingTimeMinutes
      ? routeWaypoint.haltingTimeMinutes * 60
      : 0,
    meta: {
      id: routeWaypoint.uuid,
      cargo_enter: {
        subjects: routeWaypoint.onboardingPassengerListItems.map(
          (onboardingPassengerListItem) => {
            const passengerUuid = onboardingPassengerListItem.passenger.uuid;

            return {
              id: passengerUuid,
            };
          }
        ),
      },
      cargo_exit: {
        subjects: routeWaypoint.outboardingPassengerListItems.map(
          (outboardingPassengerListItem) => {
            const passengerUuid = outboardingPassengerListItem.passenger.uuid;

            return {
              id: passengerUuid,
            };
          }
        ),
      },
    },
    time: routeWaypoint.date ? routeWaypoint.date?.toJSON() : null,
  };

  return newWaypoint;
};

const createRailyRideWaypointFromRouteGroupWaypoint = (
  routeGroupWaypoint: OrderRouteEditRouteWaypointGroupWaypoint
) => {
  const newWaypoint: OrderRouteEditRequestRideWaypoint = {
    lat: routeGroupWaypoint.address!.latitude,
    lon: routeGroupWaypoint.address!.longitude,
    display_name: routeGroupWaypoint.address!.displayName,
    halting_time: routeGroupWaypoint.haltingTimeMinutes
      ? routeGroupWaypoint.haltingTimeMinutes * 60
      : 0,
    meta: {
      id: routeGroupWaypoint.uuid,
      cargo_enter: {
        subjects: routeGroupWaypoint.onboardingPassengerListItems.map(
          (onboardingPassengerListItem) => {
            return {
              id: onboardingPassengerListItem.passenger.uuid,
            };
          }
        ),
      },
      cargo_exit: {
        subjects: routeGroupWaypoint.outboardingPassengerListItems.map(
          (outboardingPassengerListItem) => {
            return {
              id: outboardingPassengerListItem.passenger.uuid,
            };
          }
        ),
      },
    },
    time: null,
  };

  return newWaypoint;
};

const createRailyRidePatch = (
  routeItems: OrderRouteEditRouteItem[]
): (OrderRouteEditSeqNodeRequest | OrderRouteEditSeqGroupNodeRequest)[] => {
  const patchSequence: (
    | OrderRouteEditSeqNodeRequest
    | OrderRouteEditSeqGroupNodeRequest
  )[] = [];

  routeItems.forEach((routeItem) => {
    const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      patchSequence.push({
        display_name: routeItem.address?.displayName!,
        halting_time: routeItem.haltingTimeMinutes
          ? routeItem.haltingTimeMinutes * 60
          : 0,
        lat: routeItem.address?.latitude!,
        lon: routeItem.address?.longitude!,
        time: routeItem.date!,
        meta: {
          cargo_enter: {
            subjects: routeItem.onboardingPassengerListItems.map(
              (passenger) => {
                return {
                  id: passenger.passenger.uuid,
                };
              }
            ),
          },
          cargo_exit: {
            subjects: routeItem.outboardingPassengerListItems.map(
              (passenger) => {
                return {
                  id: passenger.passenger.uuid,
                };
              }
            ),
          },
          id: routeItem.uuid,
        },
      });
    } else {
      patchSequence.push({
        seq: routeItem.waypoints.map((waypoint) => {
          return {
            display_name: waypoint.address?.displayName!,
            halting_time: waypoint.haltingTimeMinutes
              ? waypoint.haltingTimeMinutes * 60
              : 0,
            time: null,
            lat: waypoint.address?.latitude!,
            lon: waypoint.address?.longitude!,
            meta: {
              cargo_enter: {
                subjects: waypoint.onboardingPassengerListItems.map(
                  (passenger) => {
                    return {
                      id: passenger.passenger.uuid,
                    };
                  }
                ),
              },
              cargo_exit: {
                subjects: waypoint.outboardingPassengerListItems.map(
                  (passenger) => {
                    return {
                      id: passenger.passenger.uuid,
                    };
                  }
                ),
              },
              id: waypoint.uuid,
            },
          };
        }),
        meta: {
          id: routeItem.uuid,
        },
        sequence_order: "ANY",
      });
    }
  });

  return patchSequence;
};

const createEditRequest = (
  prevOrderDetails: OrderRouteEditOrderDetailsData,
  routeItems: OrderRouteEditRouteItem[],
  passengerList: OrderRouteEditPassengerListItem[]
): OrderRouteEditRequestBody => {
  const rv_2: Record<
    string,
    | OrderRouteEditRequestInternalCargoItemSource
    | OrderRouteEditRequestExternalCargoItemSource
  > = {};

  passengerList.forEach((listItem) => {
    const isInternalPassenger = orderRouteEditHelper.checkIsInternalPassenger(
      listItem.passenger
    );

    if (isInternalPassenger) {
      const listItemPassenger =
        listItem.passenger as OrderRouteEditInternalPassenger;

      const internal: OrderRouteEditRequestInternalCargoItemSource = {
        source_type: "INTERNAL",
        external_id: listItemPassenger.externalId || null,
        passenger_id: listItemPassenger.uuid,
      };

      rv_2[listItem.passenger.uuid] = internal;

      return;
    }
    const listItemPassenger =
      listItem.passenger as OrderRouteEditExternalPassenger;

    const external: OrderRouteEditRequestExternalCargoItemSource = {
      source_type: "EXTERNAL",
      external_id: listItemPassenger.externalId || null,
      dispatch_name: listItemPassenger.dispatchName || null,
      first_name: listItemPassenger.firstName,
      last_name: listItemPassenger.lastName,
      phone_no: listItemPassenger.mobile,
    };

    rv_2[listItem.uuid] = external;
  });

  return {
    rv_1: {
      seq: createRailyRidePatch(routeItems),
      sequence_order: "STRICT",
      meta: {
        id: prevOrderDetails.cargo_order.ride.meta.id,
      },
    },
    rv_2,
    force_allow_toll: prevOrderDetails.force_allow_toll,
  };
};

const createRailyRide = (
  routeItems: OrderRouteEditRouteItem[]
): OrderRouteEditRequestCargoOrderRide => {
  const mainSequence: OrderRouteEditRequestRideSeqItem[] = [];

  routeItems.forEach((routeItem) => {
    const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      const newWaypoint: OrderRouteEditRequestRideWaypoint =
        createRailyRideWaypointFromRouteWaypoint(routeItem);

      mainSequence.push(newWaypoint);

      return;
    }

    const sequence: OrderRouteEditRequestRideSeqItem[] = [];

    routeItem.waypoints.forEach((waypoint) => {
      const newWaypoint: OrderRouteEditRequestRideWaypoint =
        createRailyRideWaypointFromRouteGroupWaypoint(waypoint);

      sequence.push(newWaypoint);
    });

    const newCargoOrderRide: OrderRouteEditRequestCargoOrderRide = {
      sequence_order: "ANY",
      seq: sequence,
    };

    mainSequence.push(newCargoOrderRide);
  });

  return {
    seq: mainSequence,
    sequence_order: "STRICT",
  };
};

const createSolvedRidesRequest = (
  routeItems: OrderRouteEditRouteItem[],
  taxiContract: OrderRouteEditTaxiContract
): OrderRouteEditSolvedRidesRequest => {
  const railyRide = createRailyRide(routeItems);

  return {
    raily_ride: railyRide,
    node_exclusion_collection: {
      exclusions: [
        {
          exclude_approaching: false,
          exclude_inside: taxiContract.contractPermitsTollRoads ?? false,
          exclude_returning: false,
          node_ids: [],
        },
      ],
    },
  };
};

const orderRouteEditFactory = {
  createTaxiContract,
  createPassengersList,
  createPassengerSelectOptions,
  createCargoAddresses,
  createRouteAddressSelectOption,
  createRouteAddressesSelectOptions,
  createEditRequest,
  createSolvedRidesRequest,
  createOrderDetails,
  createRouteItems,
};

export default orderRouteEditFactory;
