import useAsyncData from "../../../hooks/use-async-data";
import taxiDriverContractService from "../taxi-driver-contract.service";
import TaxiDriverContractDetails from "./taxi-driver-contract-details";
import TaxiDriverContractDetailsLoadParams from "./taxi-driver-contract-details-load-params";

const useTaxiDriverContractDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<TaxiDriverContractDetails | null>(null);

  const load = async (
    params: TaxiDriverContractDetailsLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await taxiDriverContractService.getDetails(params, signal);

      setData(data);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useTaxiDriverContractDetails;
