import { faEllipsisVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { FC, useState, useCallback, useEffect, useMemo } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import SelectOption from "../../../../../../common/components/form/select/common/option/select-option";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import useAbort from "../../../../../../common/hooks/use-abort";
import useOpen from "../../../../../../common/hooks/use-open";
import MileageAddressListLoadParams from "../../../../../../common/services/mileage-address/list/mileage-address-list-load-params";
import useMileageAddressList from "../../../../../../common/services/mileage-address/list/use-mileage-address-list";
import { useAppContext } from "../../../../../../context/app.context";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";
import mileageAddAddressFactory from "../../factory/mileage-add-address.factory";
import MileageAddAddress from "../../types/mileage-add-address";
import MileageAddAddressSelectOption from "../../types/mileage-add-address-select-option";
import { MileageAddRouteItem } from "./mileage-add-routes.types";
import MileageAddressAddModalComponent from "../../../../common/routes/address-add-modal/address-add-modal.component";
import MileageAddressAddModalAddress from "../../../../common/routes/address-add-modal/types/address-add-modal-address";

type MileageAddRouteItemProps = {
  routeIndex: number;
  routeItem: MileageAddRouteItem;
  updateRouteItem: (updatedRouteItem: MileageAddRouteItem) => void;
  deleteRouteItem: () => void;
  additionalAddresses: MileageAddAddress[];
  cargoCompanyAddresses: MileageAddAddress[];
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onBlur?: () => void;
  idForTesting?: string;
};

const MileageAddRouteItemComponent: FC<MileageAddRouteItemProps> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().routes;

  const mileageAddressList = useMileageAddressList();
  const mileageAddressListAbort = useAbort();

  const [addressSearchQuery, setAddressSearchQuery] = useState("");

  const { selectedAppLanguage } = useAppContext();
  const { isOpen, open, close } = useOpen();

  const loadAddressesDebounced = useCallback(
    debounce((addressSearchQuery: string) => {
      const loadParams: MileageAddressListLoadParams = {
        searchQuery: addressSearchQuery,
      };

      mileageAddressList.load(loadParams, mileageAddressListAbort.signal);
    }, 500),
    []
  );

  useEffect(() => {
    if (!addressSearchQuery) {
      return;
    }

    loadAddressesDebounced(addressSearchQuery);

    return mileageAddressListAbort.revoke;
  }, [addressSearchQuery]);

  const addressSelectOptions = useMemo<MileageAddAddressSelectOption[]>(() => {
    if (!addressSearchQuery) {
      const additionalAddress: SelectOption = {
        label: translations.addressAddLabel,
        value: null,
        onClick: open,
      };
      return [additionalAddress];
    }

    const cargoCompanyAddressListOptions: MileageAddAddressSelectOption[] =
      props.cargoCompanyAddresses
        .filter((address) =>
          address.displayName
            .toLowerCase()
            .includes(addressSearchQuery.toLowerCase())
        )
        .map(mileageAddAddressFactory.createSelectOption);

    const passengerOptions = props.additionalAddresses
      .filter((address: MileageAddAddress) => {
        return (
          !address.subText ||
          address.subText
            .toLocaleLowerCase()
            .includes(addressSearchQuery.toLocaleLowerCase())
        );
      })
      .map((value) => mileageAddAddressFactory.createSelectOption(value));

    const mileageAddressListOptions: MileageAddAddressSelectOption[] =
      mileageAddressList.data.map(
        mileageAddAddressFactory.createSelectOptionFromListingItem
      );

    return [
      ...passengerOptions,
      ...cargoCompanyAddressListOptions,
      ...mileageAddressListOptions,
    ];
  }, [
    props.additionalAddresses,
    props.cargoCompanyAddresses,
    mileageAddressList.data,
    addressSearchQuery,
    selectedAppLanguage,
  ]);

  const addressSelectOption = props.routeItem.address
    ? mileageAddAddressFactory.createSelectOption(props.routeItem.address)
    : null;

  const isAddressListLoading =
    !!addressSearchQuery && mileageAddressList.isLoading;

  const onAddressChange = (address: MileageAddAddress | null) => {
    const item: MileageAddRouteItem = {
      uuid: props.routeItem.uuid,
      address: address,
    };

    props.updateRouteItem(item);
  };

  const onAddressAdd = (address: MileageAddressAddModalAddress) => {
    const addAddress: MileageAddAddress = {
      displayName: `${address.zipCode}, ${address.town}, ${address.street} ${address.houseNumber}`,
      latitude: address.latitude,
      longitude: address.longitude,
      apartmentNumber: address.apartmentNumber,
      houseNumber: address.houseNumber,
      street: address.street,
      town: address.town,
      zipCode: address.zipCode,
    };

    const item: MileageAddRouteItem = {
      uuid: props.routeItem.uuid,
      address: addAddress,
    };

    props.updateRouteItem(item);
  };

  return (
    <div className="mileage_add_route_list_item">
      <div className="mileage_add_route_list_item__top_row">
        <button
          className="mileage_add_route_list_item__drag_button"
          title={translations.dragButtonTitle}
          {...props.dragHandleProps}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </button>
        <div className="mileage_add_route_list_item__index">
          {props.routeIndex + 1}
        </div>
        <SingleSelectComponent
          onChange={(value) => onAddressChange(value?.value)}
          options={addressSelectOptions}
          defaultValue={addressSelectOption}
          value={addressSelectOption}
          placeholder={translations.selectPlaceholder}
          isLoading={isAddressListLoading}
          isDisabled={mileageAddressList.isError}
          maxMenuHeight={500}
          isSearchable
          filterFunction={() => true}
          inputValue={addressSearchQuery}
          onInputChange={setAddressSearchQuery}
          isClearable
          onBlur={props.onBlur}
          noOptionsMessage={(inputValue) => {
            if (inputValue) {
              return translations.searchNoOptionsMessage;
            }
            return translations.searchTipMessage;
          }}
          idForTesting={props.idForTesting}
          classNames={{ root: "mileage_add_route_list_item__address" }}
        />
        <ButtonComponent
          onClick={props.deleteRouteItem}
          classNames={{
            root: "mileage_add_route_list_item__action_button",
          }}
          idForTesting={`milleage-add-route-list-item-${props.routeItem.uuid}-delete-button`}
        >
          <FontAwesomeIcon icon={faTrash} />
        </ButtonComponent>
      </div>
      <MileageAddressAddModalComponent
        isOpen={isOpen}
        onClose={close}
        onAddNewAddress={onAddressAdd}
      />
    </div>
  );
};

export default MileageAddRouteItemComponent;
