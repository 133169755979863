import { FC } from "react";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import CargoOfficerListingFilter from "../common/types/cargo-officer-listing-filter";
import CargoOfficerListingSortKey from "../common/types/cargo-officer-listing-sort-key";
import cargoOfficerListingFilterHelper from "./cargo-officer-listing-filter.helper";

type CargoOfficerListingFilterProps = {
  filters: CargoOfficerListingFilter[];
  onFiltersChange: (filters: CargoOfficerListingFilter[]) => void;
  sortKey: CargoOfficerListingSortKey;
  onSortKeyChange: (sortKey: CargoOfficerListingSortKey) => void;
};

const CargoOfficerListingFilterComponent: FC<CargoOfficerListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<CargoOfficerListingFilter> =
    cargoOfficerListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    cargoOfficerListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as CargoOfficerListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as CargoOfficerListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="cargo-officer-listing-filter"
      idForTestingSort="cargo-officer-listing-sort"
    />
  );
};

export default CargoOfficerListingFilterComponent;
