import MileageAddressListResponse, {
  MileageAddressListResponseItem,
} from "../../../api/raily/mileage-address/list/mileage-address-list.response";
import MileageAddressListItem from "./mileage-address-list";

const createDataItem = (
  item: MileageAddressListResponseItem
): MileageAddressListItem => {
  return {
    apartment: item.apartment,
    country: item.country,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    description: item.description,
    displayName: item.displayName,
    houseNo: item.houseNo,
    id: item.id,
    lat: item.lat,
    lon: item.lon,
    street: item.street,
    town: item.town,
    zipCode: item.zipCode,
    modificationDate: item.modifiedAt,
    modifiedBy: item.modifiedBy,
  };
};

const create = (
  response: MileageAddressListResponse
): MileageAddressListItem[] => {
  const listItems: MileageAddressListItem[] = response.data.map(createDataItem);

  return listItems;
};

const mileageAddressListFactory = {
  create,
};

export default mileageAddressListFactory;
