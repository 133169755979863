import TaxiCorporationListLoadParams, {
  TaxiCorporationListLoadParamsOrder,
} from "../../../../common/services/taxi-corporation/list/taxi-corporation-list-load-params";
import TaxiListingFilter, {
  TaxiListingAddressFilter,
  TaxiListingCompanyIdFilter,
  TaxiListingDisplayNameFilter,
  TaxiListingNameFilter,
  TaxiListingNationalCourtRegisterFilter,
  TaxiListingNotesFilter,
  TaxiListingTaxNumberFilter,
} from "./types/taxi-listing-filter";
import TaxiListingFilterType from "./types/taxi-listing-filter-type";
import TaxiListingSortKey from "./types/taxi-listing-sort-key";

const createOrder = (
  sortKey: TaxiListingSortKey | null
): TaxiCorporationListLoadParamsOrder | undefined => {
  const definition: Record<
    TaxiListingSortKey,
    TaxiCorporationListLoadParamsOrder
  > = {
    [TaxiListingSortKey.TAXI_COMPANY_NAME_ASC]:
      TaxiCorporationListLoadParamsOrder.COMPANY_NAME_ASC,
    [TaxiListingSortKey.TAXI_COMPANY_NAME_DESC]:
      TaxiCorporationListLoadParamsOrder.COMPANY_NAME_DESC,
    [TaxiListingSortKey.TAXI_COMPANY_DISPLAY_NAME_ASC]:
      TaxiCorporationListLoadParamsOrder.DISPLAY_NAME_ASC,
    [TaxiListingSortKey.TAXI_COMPANY_DISPLAY_NAME_DESC]:
      TaxiCorporationListLoadParamsOrder.DISPLAY_NAME_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: TaxiListingFilter[],
  sortKey: TaxiListingSortKey | null
): TaxiCorporationListLoadParams => {
  return {
    page,
    pageSize,
    name: filters.find((filter) => filter.type === TaxiListingFilterType.NAME)
      ?.value as TaxiListingNameFilter["value"] | undefined,
    displayName: filters.find(
      (filter) => filter.type === TaxiListingFilterType.DISPLAY_NAME
    )?.value as TaxiListingDisplayNameFilter["value"] | undefined,
    address: filters.find(
      (filter) => filter.type === TaxiListingFilterType.ADDRESS
    )?.value as TaxiListingAddressFilter["value"] | undefined,
    notes: filters.find((filter) => filter.type === TaxiListingFilterType.NOTES)
      ?.value as TaxiListingNotesFilter["value"] | undefined,
    companyId: filters.find(
      (filter) => filter.type === TaxiListingFilterType.COMPANY_ID
    )?.value as TaxiListingCompanyIdFilter["value"] | undefined,
    nationalCourtRegister: filters.find(
      (filter) => filter.type === TaxiListingFilterType.NATIONAL_COURT_REGISTER
    )?.value as TaxiListingNationalCourtRegisterFilter["value"] | undefined,
    taxNumber: filters.find(
      (filter) => filter.type === TaxiListingFilterType.TAX_NUMBER
    )?.value as TaxiListingTaxNumberFilter["value"] | undefined,
    order: createOrder(sortKey),
  };
};

const taxiListingLoadParamsFactory = {
  create,
};

export default taxiListingLoadParamsFactory;
