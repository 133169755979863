import Joi from "joi";
import formValidationService from "../../../../common/utils/validation/form-validation.service";

const validateAcceptedDistance = (value: number | null) => {
  const validationSchema = Joi.number()
    .min(0)
    .max(9999999999)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const mileageDetailsFormValidationService = {
  validateAcceptedDistance,
};

export default mileageDetailsFormValidationService;
