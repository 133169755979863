import useAsyncData from "../../../hooks/use-async-data";
import railyOfficerService from "../raily-officer.service";
import RailyOfficerList from "./raily-officer-list";
import RailyOfficerListLoadParams from "./raily-officer-list-load-params";

const useRailyOfficerList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<RailyOfficerList>({ data: [], totalCount: 0 });

  const load = async (
    params: RailyOfficerListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const railyOfficerList = await railyOfficerService.getList(
        params,
        signal
      );

      setData(railyOfficerList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useRailyOfficerList;
