import { FC } from "react";
import { faDriversLicense } from "@fortawesome/free-solid-svg-icons";
import useOpen from "../../../../../common/hooks/use-open";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsManageDriverAssignmentModal from "../../manage-driver-assignment/order-details-manage-driver-assignment.component";
import OrderDetailsDriver from "../../common/types/order-details-driver";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetailsManageDriverAssignmentComponentProps = {
  details: OrderDetailsCargoOrder | null;
  drivers: OrderDetailsDriver[];
  tools: OrderDetailsToolsTranslations;
  hasAccess: boolean;
  onSuccess: () => void;
};

const OrderDetailsManageDriverAssignmentComponent: FC<
  OrderDetailsManageDriverAssignmentComponentProps
> = (props) => {
  const { isOpen, open, close } = useOpen();
  const { isFinished, isCancelled, cargoOrderId, cargoCompanyOrderId } =
    props.details!;

  const isManageDriverAssignmentButtonVisible =
    !isFinished && !isCancelled && props.hasAccess;

  if (!isManageDriverAssignmentButtonVisible) return null;

  const { manageDriverAssignmentButtonTitle } = props.tools;

  const onSuccess = () => {
    close();
    props.onSuccess();
  };

  return (
    <>
      <OrderDetailsToolButtonComponent
        onClick={open}
        icon={faDriversLicense}
        title={manageDriverAssignmentButtonTitle}
      />
      {!!isOpen && (
        <OrderDetailsManageDriverAssignmentModal
          isOpen={isOpen}
          onClose={close}
          assignmentDrivers={props.drivers}
          cargoCompanyOrderId={cargoCompanyOrderId}
          orderUuid={cargoOrderId}
          onSuccess={onSuccess}
          transportingOrderUuid={props.details?.transportingOrderUuid!}
          estimatedStartTime={props.details?.estimatedStartTime}
        />
      )}
    </>
  );
};

export default OrderDetailsManageDriverAssignmentComponent;
