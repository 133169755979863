import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import { UserDispatcherListingRouteQueryParams } from "../../../common/routes/types/user-dispatcher-listing-route-params";
import DispatcherListingFilter from "../common/types/dispatcher-listing-filter";
import DispatcherListingSortKey from "../common/types/dispatcher-listing-sort-key";
import dispatcherListingFiltersService from "./dispatcher-listing-filters.service";

const useDispatcherListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<UserDispatcherListingRouteQueryParams>();

  const getFilters = (): DispatcherListingFilter[] => {
    return dispatcherListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return dispatcherListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return dispatcherListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): DispatcherListingSortKey | undefined => {
    return dispatcherListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: DispatcherListingFilter[],
    sortKey: DispatcherListingSortKey | null,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      dispatcherListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useDispatcherListingFiltersDao;
