import TaxiOfficerDetails from "../../../../../../common/services/taxi-officer/details/taxi-officer-details";
import TaxiOfficerEditByTaxiFormData from "./types/taxi-officer-edit-by-taxi-form-data";

const create = (data: TaxiOfficerDetails): TaxiOfficerEditByTaxiFormData => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: {
      dialingCode: data.phoneNumber?.dialingCode ?? "",
      number: data.phoneNumber?.number ?? "",
    },
    alternativePhoneNumber: {
      dialingCode: data.alternativePhoneNumber?.dialingCode ?? "",
      number: data.alternativePhoneNumber?.number ?? "",
    },
  };
};

const taxiOfficerEditByTaxiFormDataFactory = {
  create,
};

export default taxiOfficerEditByTaxiFormDataFactory;
