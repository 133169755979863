import dateService from "../../../../../../../common/utils/date/date.service";
import durationService from "../../../../../../../common/utils/duration/duration.service";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import {
  OrderDetailsHistoryEntryInitializeEvent,
  OrderDetailsHistoryEntryInitializeEventRouteWaypoint,
} from "../../../common/types/order-details-history-entry-event";

const getCargoExternalOrderIdLabel = (
  cargoExternalOrderId: OrderDetailsHistoryEntryInitializeEvent["cargoExternalOrderId"]
) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  return translations.cargoExternalOrderIdTemplateText.replace(
    "#{cargoExternalOrderId}",
    cargoExternalOrderId ?? "-----"
  );
};

const getCargoExternalOrderIdTitle = (
  cargoExternalOrderId: OrderDetailsHistoryEntryInitializeEvent["cargoExternalOrderId"]
) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  return translations.cargoExternalOrderIdTemplateText.replace(
    "#{cargoExternalOrderId}",
    cargoExternalOrderId ?? "-----"
  );
};

const getPlannedDateLabel = (
  plannedDate: OrderDetailsHistoryEntryInitializeEventRouteWaypoint["plannedDate"]
) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  const formattedDate = plannedDate
    ? dateService.formatDateTime(plannedDate)
    : `-----`;

  return translations.route.dateTemplateText.replace(
    "#{plannedDateString}",
    formattedDate
  );
};

const getPlannedDateTitle = (
  plannedDate: OrderDetailsHistoryEntryInitializeEventRouteWaypoint["plannedDate"]
) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  const formattedDate = plannedDate
    ? dateService.formatDateTime(plannedDate)
    : `-----`;

  return translations.route.dateTemplateText.replace(
    "#{plannedDateString}",
    formattedDate
  );
};

const getHaltingTimeLabel = (
  haltingTime: OrderDetailsHistoryEntryInitializeEventRouteWaypoint["haltingTime"]
) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  const formattedHaltingTime = durationService.format(haltingTime);

  return translations.route.haltingTimeTemplateText.replace(
    "#{haltingTime}",
    formattedHaltingTime
  );
};

const getHaltingTimeTitle = (
  haltingTime: OrderDetailsHistoryEntryInitializeEventRouteWaypoint["haltingTime"]
) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  const formattedHaltingTime = durationService.format(haltingTime);

  return translations.route.haltingTimeTemplateText.replace(
    "#{haltingTime}",
    formattedHaltingTime
  );
};

const getContractModelLabel = (
  contractModel: OrderDetailsHistoryEntryInitializeEvent["contractModel"]
) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  return translations.contractModelTemplateLabel.replace(
    "#{contractModel}",
    contractModel ?? "-----"
  );
};

const getTaxiCorporationLabel = (
  taxiCorporationName: OrderDetailsHistoryEntryInitializeEvent["taxiCorporationName"]
) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  return translations.taxiCorporationTemplateLabel.replace(
    "#{taxiCorporationDisplayName}",
    taxiCorporationName ?? "-----"
  );
};

const orderDetailsHistoryEntryInitializeContentHelper = {
  getCargoExternalOrderIdLabel,
  getCargoExternalOrderIdTitle,
  getPlannedDateLabel,
  getPlannedDateTitle,
  getHaltingTimeLabel,
  getHaltingTimeTitle,
  getContractModelLabel,
  getTaxiCorporationLabel,
};

export default orderDetailsHistoryEntryInitializeContentHelper;
