import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import UserStatus from "../../../../../common/types/user-status";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import DealerListingFilter, {
  DealerListingActivityStatusFilter,
  DealerListingEmailAddressFilter,
  DealerListingFirstNameFilter,
  DealerListingLastNameFilter,
  DealerListingTaxiCorporationFilter,
  DealerListingUserNameFilter,
} from "../common/types/dealer-listing-filter";
import DealerListingFilterType from "../common/types/dealer-listing-filter-type";
import DealerListingSortKey from "../common/types/dealer-listing-sort-key";

const dealerListingFilterHelper = () => {
  const translations = userTranslationsHelper.getDealerListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForTaxiCorporation = (
    filterValue: DealerListingTaxiCorporationFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.taxiCorporationLabel,
      translations.filters.taxiCorporationTitle
    );
  };

  const getBadgeForFirstName = (
    filterValue: DealerListingFirstNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.firstNameLabel,
      translations.filters.firstNameTitle
    );
  };

  const getBadgeForLastName = (
    filterValue: DealerListingLastNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.lastNameLabel,
      translations.filters.lastNameTitle
    );
  };

  const getBadgeForUserName = (
    filterValue: DealerListingUserNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.usernameLabel,
      translations.filters.usernameTitle
    );
  };

  const getBadgeForEmailAddress = (
    filterValue: DealerListingEmailAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.emailLabel,
      translations.filters.emailTitle
    );
  };

  const getBadgeForActivityStatus = (
    filterValue: DealerListingActivityStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText = userHelper.getActivityStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.activityStatusLabel,
      translations.filters.activityStatusTitle
    );
  };

  const getSelectDefinitionForActivityStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.ACTIVE)
              ),
            value: UserStatus.ACTIVE,
          },
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.DELETED)
              ),
            value: UserStatus.DELETED,
          },
        ],
      };
    };

  const getSelectDataForTaxiCorporation = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.taxiCorporationTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForFirstName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.firstNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForLastName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.lastNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForUserName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.usernameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForEmailAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.emailTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: DealerListingFilterType,
    query: string
  ) => {
    const options: Record<
      DealerListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [DealerListingFilterType.TAXI_CORPORATION]:
        getSelectDataForTaxiCorporation,
      [DealerListingFilterType.ACTIVITY_STATUS]:
        getSelectDefinitionForActivityStatus,
      [DealerListingFilterType.FIRST_NAME]: getSelectDataForFirstName,
      [DealerListingFilterType.LAST_NAME]: getSelectDefinitionForLastName,
      [DealerListingFilterType.USERNAME]: getSelectDataForUserName,
      [DealerListingFilterType.EMAIL_ADDRESS]:
        getSelectDefinitionForEmailAddress,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeDefinitionByType = (
    filterType: DealerListingFilterType,
    filterValue: DealerListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      DealerListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [DealerListingFilterType.TAXI_CORPORATION]: () =>
        getBadgeForTaxiCorporation(
          filterValue as DealerListingTaxiCorporationFilter["value"]
        ),
      [DealerListingFilterType.ACTIVITY_STATUS]: () =>
        getBadgeForActivityStatus(
          filterValue as DealerListingActivityStatusFilter["value"]
        ),
      [DealerListingFilterType.FIRST_NAME]: () =>
        getBadgeForFirstName(
          filterValue as DealerListingFirstNameFilter["value"]
        ),
      [DealerListingFilterType.LAST_NAME]: () =>
        getBadgeForLastName(
          filterValue as DealerListingLastNameFilter["value"]
        ),
      [DealerListingFilterType.USERNAME]: () =>
        getBadgeForUserName(
          filterValue as DealerListingUserNameFilter["value"]
        ),
      [DealerListingFilterType.EMAIL_ADDRESS]: () =>
        getBadgeForEmailAddress(
          filterValue as DealerListingEmailAddressFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinition =
    (): ListingFilterDefinition<DealerListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getFilterDefinitionByType = (
    filterType: DealerListingFilterType
  ): ListingFilterDefinitionOption<DealerListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<DealerListingFilter>[] => {
      return Object.values(DealerListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.firstNameAscLabel,
        value: DealerListingSortKey.FIRST_NAME_ASC,
      },
      {
        label: translations.sort.firstNameDescLabel,
        value: DealerListingSortKey.FIRST_NAME_DESC,
      },
      {
        label: translations.sort.lastNameAscLabel,
        value: DealerListingSortKey.LAST_NAME_ASC,
      },
      {
        label: translations.sort.lastNameDescLabel,
        value: DealerListingSortKey.LAST_NAME_DESC,
      },
      {
        label: translations.sort.usernameAscLabel,
        value: DealerListingSortKey.USERNAME_ASC,
      },
      {
        label: translations.sort.usernameDescLabel,
        value: DealerListingSortKey.USERNAME_DESC,
      },
    ];
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default dealerListingFilterHelper;
