import CargoCompanyPassengerListRequest, {
  CargoCompanyPassengerListRequestQueryParams,
} from "./cargo-company-passenger-list.request";

const createQueryParams = (
  request: CargoCompanyPassengerListRequest
): CargoCompanyPassengerListRequestQueryParams => {
  return {
    searchQuery: !!request.searchQuery ? request.searchQuery : undefined,
  };
};

const cargoCompanyPassengerListRequestFactory = {
  createQueryParams,
};

export default cargoCompanyPassengerListRequestFactory;
