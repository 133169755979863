import {
  faEdit,
  faEllipsisVertical,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import orderRouteEditPassengerHelper from "./order-route-edit-passenger.helper";
import debounce from "lodash/debounce";
import classNames from "classnames";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import orderRouteEditApiService from "../../api/order-route-edit-api.service";
import OrderRouteEditPassengersRequest from "../../api/order-route-edit-passengers.request";
import OrderRouteEditPassengersResponse, {
  OrderRouteEditPassengersResponsePassenger,
} from "../../api/order-route-edit-passengers.response";
import orderRouteEditFactory from "../../factory/order-route-edit.factory";
import OrderRouteEditContractor from "../../types/order-route-edit-contractor";
import OrderRouteEditPassengerListItem from "../../types/order-route-edit-passenger-list-item";
import OrderRouteEditPassengerSelectOption from "../../types/order-route-edit-passenger-select-option";
import OrderRouteEditRoutesPassengerIconComponent from "../passenger-icon/order-route-edit-routes-passenger-icon.component";
import OrderRouteEditPassenger, {
  OrderRouteEditExternalPassenger,
} from "../../types/order-route-edit-passenger";
import OrderRouteEditPassengerEditComponent from "./edit/order-route-edit-passenger-edit.component";
import OrderRouteEditExternalPassengerAddComponent from "./add-external/order-route-edit-external-passenger-add.component";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";

type OrderRouteEditPassengersProps = {
  passengerList: OrderRouteEditPassengerListItem[];
  onPassengerListChange: (
    passengerList: OrderRouteEditPassengerListItem[]
  ) => void;
  selectedPassengerUuid: string | null;
  onSelectedPassengerUuidChange: (selectedPassengerUuid: string | null) => void;
  contractorUuid: OrderRouteEditContractor["uuid"] | null;
};

const OrderRouteEditPassengersComponent: FC<OrderRouteEditPassengersProps> = (
  props
) => {
  const translations = orderTranslationsHelper.getEditTranslations().passengers;

  const [arePassengersFetching, setArePassengersFetching] = useState(false);

  const [isPassengersFetchingError, setIsPassengersFetchingError] =
    useState(false);

  const [allPassengersSelectOptions, setAllPassengersSelectOptions] = useState<
    OrderRouteEditPassengerSelectOption[]
  >([]);

  const [passengerSearchQuery, setPassengerSearchQuery] = useState("");

  const [isEditPassengerModalOpen, setIsEditPassengerModalOpen] =
    useState(false);

  const [isAddExternalPassengerModalOpen, setIsAddExternalPassengerModalOpen] =
    useState(false);

  const [
    passengerSelectedToEditListingItemUuid,
    setPassengerSelectedToEditListingItemUuid,
  ] = useState<OrderRouteEditPassengerListItem["uuid"]>("");

  const openEditPassengerModal = () => {
    setIsEditPassengerModalOpen(true);
  };

  const closeEditPassengerModal = () => {
    setIsEditPassengerModalOpen(false);
  };

  const openAddExternalPassengerModal = () => {
    setIsAddExternalPassengerModalOpen(true);
  };

  const closeAddExternalPassengerModal = () => {
    setIsAddExternalPassengerModalOpen(false);
  };

  const onPassengersFetchSuccess = (
    responsePassengers: OrderRouteEditPassengersResponsePassenger[]
  ) => {
    const passengersSelectOptions =
      orderRouteEditFactory.createPassengerSelectOptions(responsePassengers);

    setAllPassengersSelectOptions(passengersSelectOptions);
  };

  const handlePassengersResponse = (
    response: OrderRouteEditPassengersResponse
  ) => {
    if (response.status === 200) {
      onPassengersFetchSuccess(response.data);
      return;
    }

    setIsPassengersFetchingError(true);
  };

  const fetchPassengersDebounced = useCallback(
    debounce((selectedContractorUuid: string, passengerSearchQuery: string) => {
      const request: OrderRouteEditPassengersRequest = {
        search_query: passengerSearchQuery,
      };

      orderRouteEditApiService
        .fetchPassengers(selectedContractorUuid, request)
        .then(handlePassengersResponse)
        .finally(() => {
          setArePassengersFetching(false);
        });
    }, 500),
    []
  );

  useEffect(() => {
    if (!props.contractorUuid || !passengerSearchQuery) {
      setAllPassengersSelectOptions([]);
      return;
    }

    setArePassengersFetching(true);

    fetchPassengersDebounced(props.contractorUuid, passengerSearchQuery);
  }, [props.contractorUuid, passengerSearchQuery, fetchPassengersDebounced]);

  const filteredPassengerSelectOptions = allPassengersSelectOptions.filter(
    (option) =>
      !props.passengerList.find(
        (passengerListItem) =>
          passengerListItem.passenger.uuid === option.value?.uuid
      )
  );

  const passengerSelectOptions: OrderRouteEditPassengerSelectOption[] = [
    {
      label: translations.addNewExternalPassengerSelectOptionLabel,
      value: null,
      onClick: openAddExternalPassengerModal,
    },
    ...filteredPassengerSelectOptions,
  ];

  const addPassenger = (passenger: OrderRouteEditPassenger) => {
    const newPassengerListItem: OrderRouteEditPassengerListItem = {
      uuid: uuidService.generate(),
      passenger,
    };

    const newPassengerList: OrderRouteEditPassengerListItem[] = [
      ...props.passengerList,
      newPassengerListItem,
    ];

    props.onPassengerListChange(newPassengerList);
  };

  const onEditPassengerButtonClick = (
    passengerListItemUuid: OrderRouteEditPassengerListItem["uuid"]
  ) => {
    setPassengerSelectedToEditListingItemUuid(passengerListItemUuid);
    openEditPassengerModal();
  };

  const onDeletePassengerButtonClick = (
    passengerListItemUuid: OrderRouteEditPassengerListItem["uuid"]
  ) => {
    const newPassengerList: OrderRouteEditPassengerListItem[] =
      props.passengerList.filter(
        (passengerListItem) => passengerListItem.uuid !== passengerListItemUuid
      );

    props.onPassengerListChange(newPassengerList);
  };

  const getReorderedPassengerList = (
    startIndex: number,
    endIndex: number
  ): OrderRouteEditPassengerListItem[] => {
    const result = [...props.passengerList];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragPassengerListItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedPassengerList = getReorderedPassengerList(
      result.source.index,
      result.destination.index
    );

    props.onPassengerListChange(reorderedPassengerList);
  };

  const isDraggingEnabled = props.passengerList.length > 1;

  const isSearchSelectDisabled =
    isPassengersFetchingError || !props.contractorUuid;

  const onPassengerIconClick = (
    passengerListItem: OrderRouteEditPassengerListItem
  ) => {
    if (props.selectedPassengerUuid === passengerListItem.passenger.uuid) {
      props.onSelectedPassengerUuidChange(null);
      return;
    }
    props.onSelectedPassengerUuidChange(passengerListItem.passenger.uuid);
  };

  const passengerSelectedToEdit: OrderRouteEditPassenger | undefined =
    props.passengerList.find(
      (listItem) => listItem.uuid === passengerSelectedToEditListingItemUuid
    )?.passenger;

  const onExternalPassengerAddSuccess = (
    passenger: OrderRouteEditExternalPassenger
  ) => {
    const newPassengerList: OrderRouteEditPassengerListItem[] = [
      ...props.passengerList,
      {
        passenger,
        uuid: passenger.uuid,
      },
    ];

    props.onPassengerListChange(newPassengerList);
    closeAddExternalPassengerModal();
  };

  const onPassengerEditSuccess = (passenger: OrderRouteEditPassenger) => {
    const passengerSelectedListItem = props.passengerList.find(
      (item) => item.uuid === passengerSelectedToEditListingItemUuid
    );
    if (!passengerSelectedToEditListingItemUuid || !passengerSelectedListItem) {
      return;
    }

    const indexOfEditingPassenger = props.passengerList.indexOf(
      passengerSelectedListItem
    );

    const newPassengerListItem: OrderRouteEditPassengerListItem = {
      uuid: passengerSelectedToEditListingItemUuid,
      passenger,
    };

    const newPassengerList: OrderRouteEditPassengerListItem[] = [
      ...props.passengerList.slice(0, indexOfEditingPassenger),
      newPassengerListItem,
      ...props.passengerList.slice(indexOfEditingPassenger + 1),
    ];

    props.onPassengerListChange(newPassengerList);

    setPassengerSelectedToEditListingItemUuid("");
    closeEditPassengerModal();
  };

  return (
    <>
      <FormFieldComponent label={translations.headingText} isRequired>
        <DragDropContext onDragEnd={onDragPassengerListItemEnd}>
          <Droppable droppableId="order_edit_passenger_list_drop_area">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="order_edit_passenger_list">
                  {props.passengerList.map((passengerListItem, index) => {
                    const isSelected =
                      props.selectedPassengerUuid ===
                      passengerListItem.passenger.uuid;

                    return (
                      <Draggable
                        key={passengerListItem.uuid}
                        draggableId={passengerListItem.uuid}
                        index={index}
                        isDragDisabled={!isDraggingEnabled}
                      >
                        {(provided) => (
                          <div
                            className={classNames(
                              "order_edit_passenger_list_item",
                              isSelected && `selected`
                            )}
                            key={`passenger-list-item-${passengerListItem.uuid}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <button
                              className="order_edit_passenger_list_item__drag_button"
                              {...provided.dragHandleProps}
                              title={translations.dragButtonTitle}
                            >
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                            </button>

                            <div className="order_edit_passenger_list_item__index">
                              <ButtonComponent
                                onClick={() =>
                                  onPassengerIconClick(passengerListItem)
                                }
                                classNames={{ root: "p-0" }}
                              >
                                <OrderRouteEditRoutesPassengerIconComponent
                                  number={index + 1}
                                />
                              </ButtonComponent>
                            </div>
                            <div
                              className="order_edit_passenger_list_item__passenger_name"
                              title={orderRouteEditPassengerHelper.getPassengerTitle(
                                passengerListItem.passenger
                              )}
                            >
                              {orderRouteEditPassengerHelper.getPassengerLabel(
                                passengerListItem.passenger
                              )}
                            </div>
                            <div className="d-flex">
                              <ButtonComponent
                                onClick={() =>
                                  onEditPassengerButtonClick(
                                    passengerListItem.uuid
                                  )
                                }
                                classNames={{
                                  root: "order_edit_passenger_list_item__action_button",
                                }}
                                title={translations.editPassengerButtonTitle}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </ButtonComponent>

                              <ButtonComponent
                                onClick={() =>
                                  onDeletePassengerButtonClick(
                                    passengerListItem.uuid
                                  )
                                }
                                classNames={{
                                  root: "order_edit_passenger_list_item__action_button",
                                }}
                                title={translations.deletePassengerButtonTitle}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </ButtonComponent>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="order_edit_passenger_list_item m-0">
          <SingleSelectComponent
            classNames={{ root: "order_edit_passenger_list_item__select" }}
            placeholder={translations.selectPlaceholder}
            value={null}
            onChange={(value: OrderRouteEditPassengerSelectOption | null) => {
              if (!value?.value) return;

              addPassenger(value.value);
            }}
            options={passengerSelectOptions}
            isLoading={arePassengersFetching}
            isDisabled={isSearchSelectDisabled}
            isSearchable
            filterFunction={() => true}
            inputValue={passengerSearchQuery}
            onInputChange={setPassengerSearchQuery}
            noOptionsMessage={(inputValue) => {
              if (inputValue) {
                return translations.searchNoOptionsMessage;
              }
              return translations.searchTipMessage;
            }}
          />
        </div>
      </FormFieldComponent>
      <OrderRouteEditPassengerEditComponent
        isOpen={isEditPassengerModalOpen}
        onClose={closeEditPassengerModal}
        onSubmit={onPassengerEditSuccess}
        passenger={passengerSelectedToEdit}
      />
      <OrderRouteEditExternalPassengerAddComponent
        isOpen={isAddExternalPassengerModalOpen}
        onClose={closeAddExternalPassengerModal}
        onSubmit={onExternalPassengerAddSuccess}
      />
    </>
  );
};

export default OrderRouteEditPassengersComponent;
