import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import DateRange from "../../../../../../common/types/date-range";
import dateService from "../../../../../../common/utils/date/date.service";
import driverContractEditFormValidationService from "./driver-contract-edit-form-validation.service";
import DriverContractEditFormData from "./types/driver-contract-edit-form-data";

const getEmptyFormData = (): DriverContractEditFormData => {
  return {
    isActive: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<DriverContractEditFormData> => {
    return {
      isActive: (values) =>
        driverContractEditFormValidationService.validateActivity(
          values.isActive
        ),
    };
  };

const getPeriodOfValidityLabel = (
  periodOfValidity: DateRange | undefined
): string => {
  if (!periodOfValidity) {
    return "";
  }

  return `${dateService.formatDate(
    periodOfValidity.from
  )} - ${dateService.formatDate(periodOfValidity.to)}`;
};

const driverContractEditFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
  getPeriodOfValidityLabel,
};

export default driverContractEditFormHelper;
