import MileageDictionaryAddRequest from "../../api/raily/mileage-dictionary/add/mileage-dictionary-add.request";
import MileageDictionaryListRequest from "../../api/raily/mileage-dictionary/list/mileage-dictionary-list.request";
import mileageDictionaryApiService from "../../api/raily/mileage-dictionary/mileage-dictionary-api.service";
import MileageDictionaryUpdateRequest from "../../api/raily/mileage-dictionary/update/mileage-dictionary-update.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import MileageDictionaryAddError from "./add/mileage-dictionary-add-error";
import MileageDictionaryAddParams from "./add/mileage-dictionary-add-params";
import mileageDictionaryAddRequestFactory from "./add/mileage-dictionary-add-request.factory";
import MileageDictionaryDetails from "./details/mileage-dictionary-details";
import MileageDictionaryDetailsError from "./details/mileage-dictionary-details-error";
import MileageDictionaryDetailsLoadParams from "./details/mileage-dictionary-details-load-params";
import mileageDictionaryDetailsFactory from "./details/mileage-dictionary-details.factory";
import mileageDictionaryDetailsRequestFactory from "./details/mileage-dictionary-details-request.factory";
import MileageDictionaryList from "./list/mileage-dictionary-list";
import MileageDictionaryListError from "./list/mileage-dictionary-list-error";
import MileageDictionaryListLoadParams from "./list/mileage-dictionary-list-load-params";
import MileageDictionaryListRequestFactory from "./list/mileage-dictionary-list-request.factory";
import mileageDictionaryListFactory from "./list/mileage-dictionary-list.factory";
import MileageDictionaryUpdateError from "./update/mileage-dictionary-update-error";
import MileageDictionaryUpdateParams from "./update/mileage-dictionary-update-params";
import mileageDictionaryUpdateRequestFactory from "./update/mileage-dictionary-update-request.factory";

const handleListError = (
  error: HttpError | MileageDictionaryListError
): MileageDictionaryListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleAddError = (
  error: HttpError | MileageDictionaryAddError
): MileageDictionaryAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleUpdateError = (
  error: HttpError | MileageDictionaryUpdateError
): MileageDictionaryUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDetailsError = (
  error: HttpError | MileageDictionaryDetailsError
): MileageDictionaryDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  loadParams: MileageDictionaryListLoadParams,
  abortSignal: AbortSignal
): Promise<MileageDictionaryList> => {
  const request: MileageDictionaryListRequest =
    MileageDictionaryListRequestFactory.create(loadParams);

  try {
    const response = await mileageDictionaryApiService().list(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return mileageDictionaryListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | MileageDictionaryListError;

    throw handleListError(error);
  }
};

const add = async (
  params: MileageDictionaryAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: MileageDictionaryAddRequest =
    mileageDictionaryAddRequestFactory.create(params);

  try {
    const response = await mileageDictionaryApiService().add(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | MileageDictionaryAddError;

    throw handleAddError(error);
  }
};

const update = async (
  params: MileageDictionaryUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: MileageDictionaryUpdateRequest =
    mileageDictionaryUpdateRequestFactory.create(params);

  try {
    const response = await mileageDictionaryApiService().update(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | MileageDictionaryUpdateError;

    throw handleUpdateError(error);
  }
};

const details = async (
  params: MileageDictionaryDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<MileageDictionaryDetails> => {
  const request = mileageDictionaryDetailsRequestFactory.create(params);

  try {
    const response = await mileageDictionaryApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return mileageDictionaryDetailsFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | MileageDictionaryDetailsError;

    throw handleDetailsError(error);
  }
};

const mileageDictionaryService = {
  getList,
  add,
  update,
  details,
};

export default mileageDictionaryService;
