import CargoCompanyDispatchListRequest from "../../../../api/raily/cargo-company/dispatch/list/cargo-company-dispatch-list.request";
import paginationService from "../../../../utils/pagination/pagination.service";
import CargoCompanyDispatchListLoadParams from "./cargo-company-dispatch-list-load-params";

const create = (
  params: CargoCompanyDispatchListLoadParams
): CargoCompanyDispatchListRequest => {
  return {
    cargoCompanyId: params.cargoCompanyUuid,
    limit: params.pageSize,
    offset:
      params.page && params.pageSize
        ? paginationService.calculateOffset(params.page, params.pageSize)
        : undefined,
  };
};

const cargoCompanyDispatchListRequestFactory = {
  create,
};

export default cargoCompanyDispatchListRequestFactory;
