import useAsyncData from "../../../../hooks/use-async-data";
import billingService from "../../billings.service";
import BillingsOrderForwardCargoTaxi from "./billings-order-forward-cargo-taxi";
import BillingsOrderForwardCargoTaxiLoadParams from "./billings-order-forward-cargo-taxi-load-params";

const useBillingsOrderForwardCargoTaxi = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<BillingsOrderForwardCargoTaxi>({
      data: [],
    });

  const load = async (
    params: BillingsOrderForwardCargoTaxiLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoTaxi = await billingService.getOrderForwardCargoTaxi(
        params,
        signal
      );

      setData(cargoTaxi);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useBillingsOrderForwardCargoTaxi;
