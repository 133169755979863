import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import UserStatus from "../../../../../common/types/user-status";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import TaxiOfficerListingFilter, {
  TaxiOfficerListingEmailAddressFilter,
  TaxiOfficerListingFirstNameFilter,
  TaxiOfficerListingLastNameFilter,
  TaxiOfficerListingStatusFilter,
  TaxiOfficerListingUserNameFilter,
} from "../common/types/taxi-officer-listing-filter";
import TaxiOfficerListingFilterType from "../common/types/taxi-officer-listing-filter-type";
import TaxiOfficerListingSortKey from "../common/types/taxi-officer-listing-sort-key";

const taxiOfficerListingFilterHelper = () => {
  const translations =
    userTranslationsHelper.getTaxiOfficerListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getSelectDataByType = (
    filterType: TaxiOfficerListingFilterType,
    query: string
  ) => {
    const options: Record<
      TaxiOfficerListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [TaxiOfficerListingFilterType.FIRST_NAME]: getSelectDataForFirstName,
      [TaxiOfficerListingFilterType.LAST_NAME]: getSelectDataForLastName,
      [TaxiOfficerListingFilterType.ACTIVITY_STATUS]: getSelectDataForStatus,
      [TaxiOfficerListingFilterType.USERNAME]: getSelectDataForUserName,
      [TaxiOfficerListingFilterType.EMAIL]: getSelectDataForEmailAddress,
    };

    const result = options[filterType](query);

    return result;
  };

  const getSelectDataForFirstName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.firstNameTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForLastName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.lastNameTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.ACTIVE)
              ),
            value: UserStatus.ACTIVE,
          },
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.DELETED)
              ),
            value: UserStatus.DELETED,
          },
        ],
      };
    };

  const getSelectDataForUserName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.usernameTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForEmailAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.emailTemplateLabel.replace(
            `#{query}`,
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getBadgeDefinitionByType = (
    filterType: TaxiOfficerListingFilterType,
    filterValue: TaxiOfficerListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      TaxiOfficerListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [TaxiOfficerListingFilterType.FIRST_NAME]: () =>
        getBadgeForFirstName(
          filterValue as TaxiOfficerListingFirstNameFilter["value"]
        ),
      [TaxiOfficerListingFilterType.LAST_NAME]: () =>
        getBadgeForLastName(
          filterValue as TaxiOfficerListingLastNameFilter["value"]
        ),
      [TaxiOfficerListingFilterType.EMAIL]: () =>
        getBadgeForEmailAddress(
          filterValue as TaxiOfficerListingEmailAddressFilter["value"]
        ),

      [TaxiOfficerListingFilterType.ACTIVITY_STATUS]: () =>
        getBadgeForActivityStatus(
          filterValue as TaxiOfficerListingStatusFilter["value"]
        ),
      [TaxiOfficerListingFilterType.USERNAME]: () =>
        getBadgeForUserName(
          filterValue as TaxiOfficerListingUserNameFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getBadgeForUserName = (
    filterValue: TaxiOfficerListingUserNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.usernameLabel,
      translations.filters.usernameTitle
    );
  };

  const getBadgeForActivityStatus = (
    filterValue: TaxiOfficerListingStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText = userHelper.getActivityStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.activityStatusLabel,
      translations.filters.activityStatusTitle
    );
  };

  const getBadgeForFirstName = (
    filterValue: TaxiOfficerListingFirstNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.firstNameLabel,
      translations.filters.firstNameTitle
    );
  };

  const getBadgeForLastName = (
    filterValue: TaxiOfficerListingLastNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.lastNameLabel,
      translations.filters.lastNameTitle
    );
  };

  const getBadgeForEmailAddress = (
    filterValue: TaxiOfficerListingEmailAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.emailLabel,
      translations.filters.emailTitle
    );
  };

  const getFilterDefinitionByType = (
    filterType: TaxiOfficerListingFilterType
  ): ListingFilterDefinitionOption<TaxiOfficerListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<TaxiOfficerListingFilter>[] => {
      return Object.values(TaxiOfficerListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<TaxiOfficerListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.firstNameAscLabel,
        value: TaxiOfficerListingSortKey.FIRST_NAME_ASC,
      },
      {
        label: translations.sort.firstNameDescLabel,
        value: TaxiOfficerListingSortKey.FIRST_NAME_DESC,
      },
      {
        label: translations.sort.lastNameAscLabel,
        value: TaxiOfficerListingSortKey.LAST_NAME_ASC,
      },
      {
        label: translations.sort.lastNameDescLabel,
        value: TaxiOfficerListingSortKey.LAST_NAME_DESC,
      },
      {
        label: translations.sort.usernameAscLabel,
        value: TaxiOfficerListingSortKey.USERNAME_ASC,
      },
      {
        label: translations.sort.usernameDescLabel,
        value: TaxiOfficerListingSortKey.USERNAME_DESC,
      },
    ];
  };

  return { getFilterDefinition, getSortDefinition };
};

export default taxiOfficerListingFilterHelper;
