import { useState } from "react";

const useAsyncData = <T>(
  defaultData: T,
  defaultLoadingState: boolean = true
) => {
  const [isLoading, setIsLoading] = useState(defaultLoadingState);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<T>(defaultData);

  return {
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    data,
    setData,
  };
};

export default useAsyncData;
