import HttpResponse from "../../../../utils/http/http.response";

export enum MessengerPostResponsePostType {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REOPEN_REQUEST = "REOPEN_REQUEST",
  REOPENED = "REOPENED",
  BILLED = "BILLED",
  UPDATED = "UPDATED",
  USER_ENTRY = "USER_ENTRY",
}

export type MessengerPostResponsePost = {
  id: string;
  content: string;
  createdAt: string;
  createdBy: string;
  authorFirstName: string;
  authorLastName: string;
  authorCompany: string;
  postType: MessengerPostResponsePostType;
  distance: number | null;
  amount: number | null;
};

export type MessengerPostResponseData = MessengerPostResponsePost[];

type MessengerPostResponse = HttpResponse<MessengerPostResponseData>;

export default MessengerPostResponse;
