import Joi from "joi";
import DriverContractListingByRailyRouteQueryParams from "./types/driver-contract-listing-by-raily-route-query-params";
import DriverContractListingByRailyRouteQueryFilterParams from "./types/driver-contract-listing-by-raily-route-query-filter-params";
import DriverContractListingByRailyFilter, {
  DriverContractListingByRailyBillingModelFilter,
  DriverContractListingByRailyTaxiCorporationFilter,
} from "./types/driver-contract-listing-by-raily-filter";
import DriverContractListingByRailyFilterType from "./types/driver-contract-listing-by-raily-filter-type";
import DriverContractListingBillingModel from "../../common/types/driver-contract-listing-billing-model";
import DriverContractListingByRailySortKey from "./types/driver-contract-listing-by-raily-sort-key";

const getFilters = (
  routeQueryFilterParams: DriverContractListingByRailyRouteQueryFilterParams
): DriverContractListingByRailyFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: DriverContractListingByRailyFilter[] = [];

  if (routeQueryFilterParams.billingModel) {
    const billingModelFilter: DriverContractListingByRailyBillingModelFilter = {
      type: DriverContractListingByRailyFilterType.BILLING_MODEL,
      value:
        routeQueryFilterParams.billingModel as DriverContractListingByRailyBillingModelFilter["value"],
    };

    filters.push(billingModelFilter);
  }

  if (routeQueryFilterParams.taxiCorporation) {
    const taxiCorporationFilter: DriverContractListingByRailyTaxiCorporationFilter =
      {
        type: DriverContractListingByRailyFilterType.TAXI_CORPORATION,
        value:
          routeQueryFilterParams.taxiCorporation as DriverContractListingByRailyTaxiCorporationFilter["value"],
      };

    filters.push(taxiCorporationFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: DriverContractListingByRailyRouteQueryParams
): DriverContractListingByRailySortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const getPage = (
  routeQueryParams: DriverContractListingByRailyRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryParams.page)) {
    return undefined;
  }

  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: DriverContractListingByRailyRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryParams.pageSize)) {
    return undefined;
  }

  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: DriverContractListingByRailyFilter[],
  sortKey: DriverContractListingByRailySortKey | null,
  page: number,
  pageSize: number
): DriverContractListingByRailyRouteQueryParams => {
  const routeQueryParams: DriverContractListingByRailyRouteQueryParams = {
    billingModel: filters.find(
      (filter) =>
        filter.type === DriverContractListingByRailyFilterType.BILLING_MODEL
    )?.value,
    taxiCorporation: filters.find(
      (filter) =>
        filter.type === DriverContractListingByRailyFilterType.TAXI_CORPORATION
    )?.value,
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const validateFilters = (
  routeQueryFilterParams: DriverContractListingByRailyRouteQueryFilterParams
) => {
  const filterParams: DriverContractListingByRailyRouteQueryFilterParams = {
    billingModel: routeQueryFilterParams.billingModel,
    taxiCorporation: routeQueryFilterParams.taxiCorporation,
  };

  const validationSchema =
    Joi.object<DriverContractListingByRailyRouteQueryFilterParams>({
      billingModel: Joi.valid(
        ...Object.values(DriverContractListingBillingModel)
      ),
      taxiCorporation: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: DriverContractListingByRailyRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(DriverContractListingByRailySortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: DriverContractListingByRailyRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: DriverContractListingByRailyRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const driverContractListingByRailyRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default driverContractListingByRailyRouteQueryParamsService;
