import HttpResponse from "../../../../../common/utils/http/http.response";

export type OrderAddResponseSuccessResponse = HttpResponse<string>;

export enum OrderAddNotAcceptableResponseErrorType {
  INCONSISTENT_RIDE_TIMING = "INCONSISTENT_RIDE_TIMING",
  TIME_NOT_AVAILABLE = "TIME_NOT_AVAILABLE",
  TIMING_NOT_POSSIBLE = "TIMING_NOT_POSSIBLE",
  NO_VALID_CARGO_TAXI_CONTRACT = "NO_VALID_CARGO_TAXI_CONTRACT",
  PASSENGERS_DO_OVERLAP = "PASSENGERS_DO_OVERLAP",
}

export enum OrderAddUnprocessableEntityErrorType {
  TOO_LONG_RIDE = "TOO_LONG_RIDE",
}

export type OrderAddUnprocessableEntityTimeError = {
  detail: {
    error_code: OrderAddUnprocessableEntityErrorType.TOO_LONG_RIDE;
  }[];
};

export type OrderAddNotAcceptableResponseInconsistentRideTimingError = {
  error_type: OrderAddNotAcceptableResponseErrorType.INCONSISTENT_RIDE_TIMING;
};

export type OrderAddNotAcceptableResponseTimingNotAvailableError = {
  error_type: OrderAddNotAcceptableResponseErrorType.TIME_NOT_AVAILABLE;
};

export type OrderAddNotAcceptableResponseTimingNotPossibleError = {
  error_type: OrderAddNotAcceptableResponseErrorType.TIMING_NOT_POSSIBLE;
};

export type OrderAddNotAcceptableResponseNoValidCargoTaxiContractError = {
  error_type: OrderAddNotAcceptableResponseErrorType.NO_VALID_CARGO_TAXI_CONTRACT;
};

export type OrderAddNotAcceptableResponsePassengerDoOverlapError = {
  error_type: OrderAddNotAcceptableResponseErrorType.PASSENGERS_DO_OVERLAP;
  subjectOrderId: string;
  overlappingMap: string[];
};

export type OrderAddNotAcceptableResponseError =
  | OrderAddNotAcceptableResponseInconsistentRideTimingError
  | OrderAddNotAcceptableResponseTimingNotAvailableError
  | OrderAddNotAcceptableResponseTimingNotPossibleError
  | OrderAddNotAcceptableResponseNoValidCargoTaxiContractError
  | OrderAddNotAcceptableResponsePassengerDoOverlapError;

export type OrderAddNotAcceptableResponse =
  HttpResponse<OrderAddNotAcceptableResponseError>;

export type OrderAddUnprocessableEntityResponse =
  HttpResponse<OrderAddUnprocessableEntityTimeError>;

type OrderAddResponse =
  | OrderAddResponseSuccessResponse
  | OrderAddNotAcceptableResponse
  | OrderAddUnprocessableEntityResponse;

export default OrderAddResponse;
