import { FC, useMemo } from "react";
import { useAppContext } from "../../../../context/app.context";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import TaxiContractMaintenancePolicy from "../../common/types/taxi-contract-maintenance-policy";
import TaxiCompanyContractSourceSelectOption from "./taxi-company-contract-maintenance-policy-select-option";
import taxiCompanyContractMaintenancePolicySelectOptionFactory from "./taxi-company-contract-maintenance-policy-select-option.factory";

type TaxiCompanyContractMaintenancePolicySelectProps = {
  placeholder: string;
  selectedContractMaintenancePolicy: TaxiContractMaintenancePolicy | null;
  onChange: (value: TaxiContractMaintenancePolicy | null) => void;
  onBlur: () => void;
  isError: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  idForTesting?: string;
};

const TaxiCompanyContractMaintenancePolicySelectComponent: FC<
  TaxiCompanyContractMaintenancePolicySelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const selectOptions: TaxiCompanyContractSourceSelectOption[] = useMemo(
    () =>
      taxiCompanyContractMaintenancePolicySelectOptionFactory.createOptions(),
    [selectedAppLanguage]
  );

  const selectedOption: TaxiCompanyContractSourceSelectOption | null =
    useMemo(() => {
      return !props.selectedContractMaintenancePolicy
        ? null
        : selectOptions.find(
            (option) => option.value === props.selectedContractMaintenancePolicy
          ) ?? null;
    }, [props.selectedContractMaintenancePolicy, selectOptions]);

  return (
    <SingleSelectComponent
      placeholder={props.placeholder}
      value={selectedOption}
      onChange={(value) => props.onChange(value?.value ?? null)}
      onBlur={props.onBlur}
      options={selectOptions}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      hasError={props.isError}
      idForTesting={props.idForTesting}
      isSearchable
    />
  );
};

export default TaxiCompanyContractMaintenancePolicySelectComponent;
