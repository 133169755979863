import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import TaxiUnsettledOrderListingByRailyRouteQueryParams from "./common/types/taxi-unsettled-order-listing-by-raily-route-query-params";
import TaxiUnsettledOrderListingItem from "../common/types/taxi-unsettled-order-listing-item";
import TaxiUnsettledOrderListingFilter, {
  TaxiUnsettledOrderListingStartDateFilter,
} from "../common/types/taxi-unsettled-order-listing-filter";
import TaxiUnsettledOrderListingSortKey from "../common/types/taxi-unsettled-order-listing-sort-key";
import TaxiUnsettledOrderListingSortSelectOption from "../common/types/taxi-unsettled-order-listing-sort-select-option";
import taxiUnsettledOrderListingSortHelper from "../common/taxi-unsettled-order-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import taxiUnsettledOrderListingByRailyRouteQueryParamsService from "./common/taxi-unsettled-order-listing-by-raily-route-query-params.service";
import taxiUnsettledOrderListingByRailyRequestFactory from "./common/taxi-unsettled-order-listing-by-raily-request.factory";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import TaxiUnsettledOrderListingFiltersSelectComponent from "../common/filters/select/taxi-unsettled-order-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import TaxiUnsettledOrderListingFiltersBadgeListComponent from "../common/filters/list/taxi-unsettled-order-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import TaxiUnsettledOrderListingTableComponent from "../common/table/taxi-unsettled-order-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import TaxiUnsettledOrderListingViewBasicProps from "../common/types/taxi-unsettled-order-listing-view-basic-props";
import taxiUnsettledOrderListingByRailyApiService from "./common/api/taxi-unsettled-order-listing-by-raily-api.service";
import taxiUnsettledOrderListingByRailyFactory from "./common/taxi-unsettled-order-listing-by-raily.factory";
import TaxiUnsettledOrderListingFilterType from "../common/types/taxi-unsettled-order-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import TaxiUnsettledOrderListingByRailyResponse, {
  TaxiUnsettledOrderListingByRailyResponseData,
} from "./common/api/taxi-unsettled-order-listing-by-raily.response";
import { useAppContext } from "../../../../../context/app.context";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import StatusLegendComponent from "../../../../../common/components/status-legend/status-legend.component";
import taxiUnsettledOrderListingHelper from "../common/taxi-unsettled-order-listing.helper";

type TaxiUnsettledOrderListingByRailyProps =
  TaxiUnsettledOrderListingViewBasicProps;

const TaxiUnsettledOrderListingByRailyComponent: FC<
  TaxiUnsettledOrderListingByRailyProps
> = () => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledOrderListingTranslations();

  const { selectedAppLanguage } = useAppContext();

  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiUnsettledOrderListingByRailyRouteQueryParams>();

  const [areOrderListingItemsFetching, setAreOrderListingItemsFetching] =
    useState(false);

  const [
    isOrderListingItemsFetchingError,
    setIsOrderListingItemsFetchingError,
  ] = useState(false);

  const [orderListingItems, setOrderListingItems] = useState<
    TaxiUnsettledOrderListingItem[]
  >([]);
  const [totalListingItems, setTotalListingItems] = useState(0);

  const [filters, setFilters] = useState<TaxiUnsettledOrderListingFilter[]>(
    () =>
      taxiUnsettledOrderListingByRailyRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiUnsettledOrderListingSortKey | null>(
      () =>
        taxiUnsettledOrderListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: TaxiUnsettledOrderListingSortSelectOption[] =
    useMemo(
      () => taxiUnsettledOrderListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalListingItems,
    defaultPageSize:
      taxiUnsettledOrderListingByRailyRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiUnsettledOrderListingByRailyRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiUnsettledOrderListingByRailyRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const onListingFetchSuccess = (
    responseData: TaxiUnsettledOrderListingByRailyResponseData
  ) => {
    setTotalListingItems(responseData.total_count);

    const listingItems =
      taxiUnsettledOrderListingByRailyFactory.createListingItems(
        responseData.data
      );

    setOrderListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsOrderListingItemsFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiUnsettledOrderListingByRailyResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setAreOrderListingItemsFetching(true);
    setIsOrderListingItemsFetchingError(false);

    const request =
      taxiUnsettledOrderListingByRailyRequestFactory.createRequest(
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    taxiUnsettledOrderListingByRailyApiService
      .fetchListing(request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setAreOrderListingItemsFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [page, pageSize, filters, selectedSortKey]);

  const addNewFilter = (newFilter: TaxiUnsettledOrderListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey, selectedAppLanguage]);

  const startDateFilterValue = filters.find(
    (filter) => filter.type === TaxiUnsettledOrderListingFilterType.START_DATE
  )?.value as TaxiUnsettledOrderListingStartDateFilter["value"] | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== TaxiUnsettledOrderListingFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.START_DATE
    );

    const newFilter: TaxiUnsettledOrderListingStartDateFilter = {
      type: TaxiUnsettledOrderListingFilterType.START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== TaxiUnsettledOrderListingFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const statusOptions =
    taxiUnsettledOrderListingHelper.getStatusLegendOptions();

  return (
    <div className="taxi_unsettled_order_listing">
      <HeadingComponent title={translations.header.headingText} />
      <StatusLegendComponent statusData={statusOptions} />
      <div className="taxi_unsettled_order_listing_tools">
        <div className="d-flex">
          <TaxiUnsettledOrderListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="taxi-unsettled-order-listing-by-raily-date-range"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="taxi-unsettled-order-listing-by-raily-sort"
        />
      </div>
      <TaxiUnsettledOrderListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TaxiUnsettledOrderListingTableComponent
          listingItems={orderListingItems}
          isError={isOrderListingItemsFetchingError}
          isLoading={areOrderListingItemsFetching}
        />
        <div className="taxi_unsettled_order_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalListingItems}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default TaxiUnsettledOrderListingByRailyComponent;