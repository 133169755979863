export enum CargoCompanyListLoadParamsOrder {
  COMPANY_NAME_ASC = "COMPANY_NAME_ASC",
  COMPANY_NAME_DESC = "COMPANY_NAME_DESC",
  COMPANY_DISPLAY_NAME_ASC = "COMPANY_DISPLAY_NAME_ASC",
  COMPANY_DISPLAY_NAME_DESC = "COMPANY_DISPLAY_NAME_DESC",
}

type CargoCompanyListLoadParams = {
  taxNumber?: string;
  companyId?: string;
  nationalCourtRegister?: string;
  companyName?: string;
  companyDisplayName?: string;
  address?: string;
  notes?: string;
  order?: CargoCompanyListLoadParamsOrder;
  creationStart?: Date;
  creationEnd?: Date;
};

export default CargoCompanyListLoadParams;
