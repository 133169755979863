import HttpResponse from "../../../../utils/http/http.response";

export enum TaxiCorporationListResponseItemContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type TaxiCorporationListResponseItemFleetPartner = {
  id: string;
  taxiCorporationId: string;
  name: string;
  modifiedAt: string | null;
  modifiedBy: string | null;
  createdAt: string;
  createdBy: string;
};

export type TaxiCorporationListResponseItem = {
  id: string;
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: TaxiCorporationListResponseItemContractMaintenancePolicy;
  notes: string | null;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date | null;
  createdBy: string | null;
  fleetPartners: TaxiCorporationListResponseItemFleetPartner[];
};

type TaxiCorporationListResponse = HttpResponse<{
  data: TaxiCorporationListResponseItem[];
  totalCount: number;
}>;

export default TaxiCorporationListResponse;
