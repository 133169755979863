import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import SelectOption from "./option/select-option";
import ComponentClassnames from "../../../../types/component-classnames";
import classNames from "classnames";

const getNoOptionsMessage = (
  inputValue: string,
  noOptionsMessage?: (inputValue: string) => string
) => {
  const selectComponentTranslations =
    appTranslationsHelper.getComponentTranslations().select;

  return noOptionsMessage
    ? noOptionsMessage(inputValue)
    : inputValue
    ? selectComponentTranslations.noOptionsMessageForQuery
    : selectComponentTranslations.noOptionsMessage;
};

const filterByDefault = (
  option: FilterOptionOption<SelectOption>,
  inputValue: string
): boolean =>
  option.data.label.toLowerCase().includes(inputValue.toLowerCase()) ||
  !!option.data.subText?.toLowerCase().includes(inputValue.toLowerCase());

const getSelectClasses = (
  selectClasses?: ComponentClassnames,
  hasError?: boolean
) => classNames("select", selectClasses?.root, hasError && "error");

const selectHelper = {
  getNoOptionsMessage,
  filterByDefault,
  getSelectClasses,
};

export default selectHelper;
