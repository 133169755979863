import CargoOrderActiveListRequest, {
  CargoOrderActiveListRequestQueryParams,
} from "./cargo-order-active-list.request";

const createQueryParams = (
  data: CargoOrderActiveListRequest
): CargoOrderActiveListRequestQueryParams => {
  return {
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
  };
};

const cargoOrderActiveListRequestFactory = {
  createQueryParams,
};

export default cargoOrderActiveListRequestFactory;
