import RouteFinishedListRequest, {
  RouteFinishedListingRequestOrder,
} from "../../../api/raily/route/finished-list/route-finished-list.request";
import paginationService from "../../../utils/pagination/pagination.service";
import RouteFinishedListLoadParams, {
  RouteFinishedListLoadParamsOrder,
} from "./route-finished-list-load-params";

const createOrder = (
  status: RouteFinishedListLoadParamsOrder | undefined
): RouteFinishedListingRequestOrder | undefined => {
  switch (status) {
    case RouteFinishedListLoadParamsOrder.DRIVER_ASC:
      return RouteFinishedListingRequestOrder.DRIVER_ASC;
    case RouteFinishedListLoadParamsOrder.DRIVER_DESC:
      return RouteFinishedListingRequestOrder.DRIVER_ASC;
    case RouteFinishedListLoadParamsOrder.HUMAN_ID_ASC:
      return RouteFinishedListingRequestOrder.HUMAN_ID_ASC;
    case RouteFinishedListLoadParamsOrder.HUMAN_ID_DESC:
      return RouteFinishedListingRequestOrder.HUMAN_ID_DESC;
    case RouteFinishedListLoadParamsOrder.TAXI_CORPORATION_ASC:
      return RouteFinishedListingRequestOrder.TAXI_CORPORATION_ASC;
    case RouteFinishedListLoadParamsOrder.TAXI_CORPORATION_DESC:
      return RouteFinishedListingRequestOrder.TAXI_CORPORATION_DESC;
    default:
      return undefined;
  }
};

const create = (
  params: RouteFinishedListLoadParams
): RouteFinishedListRequest => {
  return {
    order: createOrder(params.order),
    taxiCorporationId: params.taxiCorporationUuid,
    driverId: params.driverUuid,
    driverName: params.driverName ? [params.driverName] : [],
    taxiName: params.taxiName ? [params.taxiName] : [],
    planEntryNodes: params.planEntryNodes ? [params.planEntryNodes] : [],
    startingNodeLabel: params.startingNodeLabel
      ? [params.startingNodeLabel]
      : [],
    endingNodeLabel: params.endingNodeLabel ? [params.endingNodeLabel] : [],
    startTimeSince: params.startTimeSince,
    startTimeTo: params.startTimeTo,
    endTimeSince: params.endTimeSince,
    endTimeTo: params.endTimeTo,
    humanId: params.humanId ? [params.humanId] : [],
    involvedCargoLabels: params.involvedCargoLabels
      ? [params.involvedCargoLabels]
      : [],
    isBilled: params.isBilled,
    cargoOrderId: params.cargoOrderUuid,
    cargoOrderHumanId: params.cargoOrderHumanId,
    creationStart: params.creationStart,
    creationEnd: params.creationEnd,
    limit: params.pageSize,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
  };
};

const routeFinishedListRequestFactory = {
  create,
};

export default routeFinishedListRequestFactory;
