import MileageUpdateRequest, {
  MileageUpdateRequestAddressSequenceNode,
  MileageUpdateRequestStatusType,
  MileageUpdateRequestVehicleType,
} from "../../../api/raily/mileage/update/mileage-update-request";
import MileageUpdateParams, {
  MileageUpdateParamsNode,
  MileageUpdateParamsStatus,
  MileageUpdateParamsVehicleType,
} from "./mileage-update-params";

const createAddressNode = (
  node: MileageUpdateParamsNode
): MileageUpdateRequestAddressSequenceNode => {
  return {
    displayName: node.displayName,
    lat: node.lat,
    lon: node.lon,
    time: node.time,
    apartmentNumber: node.apartmentNumber,
    houseNumber: node.houseNumber,
    street: node.street,
    town: node.town,
    zipCode: node.zipCode,
    distance: node.distance,
  };
};

const createAddressNodes = (
  nodes: MileageUpdateParamsNode[] | undefined
): MileageUpdateRequestAddressSequenceNode[] | undefined => {
  if (!nodes) {
    return undefined;
  }
  const requestNodes: MileageUpdateRequestAddressSequenceNode[] =
    nodes.map(createAddressNode);

  return requestNodes;
};

const createVehicleType = (
  type: MileageUpdateParamsVehicleType | undefined
): MileageUpdateRequestVehicleType | undefined => {
  switch (type) {
    case MileageUpdateParamsVehicleType.CAR_GT_900CM3:
      return MileageUpdateRequestVehicleType.CAR_GT_900CM3;
    case MileageUpdateParamsVehicleType.CAR_LE_900CM3:
      return MileageUpdateRequestVehicleType.CAR_LE_900CM3;
    case MileageUpdateParamsVehicleType.COMPANY_OWNED:
      return MileageUpdateRequestVehicleType.COMPANY_OWNED;
    case MileageUpdateParamsVehicleType.MOPED:
      return MileageUpdateRequestVehicleType.MOPED;
    case MileageUpdateParamsVehicleType.MOTORCYCLE:
      return MileageUpdateRequestVehicleType.MOTORCYCLE;
    default:
      return undefined;
  }
};

const createStatusType = (
  type: MileageUpdateParamsStatus | undefined
): MileageUpdateRequestStatusType | undefined => {
  switch (type) {
    case MileageUpdateParamsStatus.ACCEPTED:
      return MileageUpdateRequestStatusType.ACCEPTED;
    case MileageUpdateParamsStatus.DELEGATION_CREATED:
      return MileageUpdateRequestStatusType.DELEGATION_CREATED;
    case MileageUpdateParamsStatus.PENDING:
      return MileageUpdateRequestStatusType.PENDING;
    case MileageUpdateParamsStatus.REJECTED:
      return MileageUpdateRequestStatusType.REJECTED;
    default:
      return undefined;
  }
};

const create = (params: MileageUpdateParams): MileageUpdateRequest => {
  return {
    mileageUuid: params.mileageUuid,
    mileageDate: params.mileageDate,
    distance: params.distance,
    state: createStatusType(params.state),
    vehicleType: createVehicleType(params.vehicleType),
    updateReason: params.updateReason,
    addressSequence: createAddressNodes(params.addressSequence),
    acceptedDistance: params.acceptedDistance,
    osrmDistance: params.osrmDistance,
  };
};

const mileageUpdateRequestfactory = {
  create,
};

export default mileageUpdateRequestfactory;
