import { CargoOrderActiveListItemExecutionStatus } from "../../../../common/services/cargo-order/active-list/cargo-order-active-list";
import OrderActiveListingRouteQueryParams from "../../common/routes/types/order-active-listing-route-params";
import OrderActiveListingFilter, {
  OrderActiveListingClientFilter,
  OrderActiveListingDispatchFilter,
  OrderActiveListingDriverFilter,
  OrderActiveListingExcludeClientFilter,
  OrderActiveListingExcludeDispatchFilter,
  OrderActiveListingExcludeTaxiCorporationFilter,
  OrderActiveListingExecutionStatusFilter,
  OrderActiveListingExternalOrderIdFilter,
  OrderActiveListingInternalOrderIdFilter,
  OrderActiveListingMobileAppUserFilter,
  OrderActiveListingPassengerFilter,
  OrderActiveListingRouteAddressFilter,
  OrderActiveListingRouteDestinationAddressFilter,
  OrderActiveListingRouteIntermediateAddressFilter,
  OrderActiveListingRoutePickupAddressFilter,
  OrderActiveListingTaxiCorporationFilter,
  OrderActiveListingUrgentOnlyFilter,
} from "../common/types/order-active-listing-filter";
import OrderActiveListingFilterType from "../common/types/order-active-listing-filter-type";
import OrderActiveListingSortKey from "../common/types/order-active-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: OrderActiveListingRouteQueryParams
): OrderActiveListingFilter[] => {
  const filters: OrderActiveListingFilter[] = [];

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: OrderActiveListingInternalOrderIdFilter = {
      type: OrderActiveListingFilterType.INTERNAL_ORDER_ID,
      value: Number(routeQueryFilterParams.internalOrderId),
    };

    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalOrderIdFilter: OrderActiveListingExternalOrderIdFilter = {
      type: OrderActiveListingFilterType.EXTERNAL_ORDER_ID,
      value: routeQueryFilterParams.externalOrderId,
    };

    filters.push(externalOrderIdFilter);
  }

  if (routeQueryFilterParams.client) {
    const clientFilter: OrderActiveListingClientFilter = {
      type: OrderActiveListingFilterType.CLIENT,
      value: routeQueryFilterParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.excludedClient) {
    const clientFilter: OrderActiveListingExcludeClientFilter = {
      type: OrderActiveListingFilterType.EXCLUDE_CLIENT,
      value: routeQueryFilterParams.excludedClient,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: OrderActiveListingDispatchFilter = {
      type: OrderActiveListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.excludedDispatch) {
    const dispatchFilter: OrderActiveListingExcludeDispatchFilter = {
      type: OrderActiveListingFilterType.EXCLUDE_DISPATCH,
      value: routeQueryFilterParams.excludedDispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: OrderActiveListingDriverFilter = {
      type: OrderActiveListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.isMobileAppUser) {
    const mobileApplicationUserFilter: OrderActiveListingMobileAppUserFilter = {
      type: OrderActiveListingFilterType.MOBILE_APP_USER,
      value: routeQueryFilterParams.isMobileAppUser === "true" ? true : false,
    };

    filters.push(mobileApplicationUserFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: OrderActiveListingPassengerFilter = {
      type: OrderActiveListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: OrderActiveListingRouteAddressFilter = {
      type: OrderActiveListingFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationAddressFilter: OrderActiveListingRouteDestinationAddressFilter =
      {
        type: OrderActiveListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationAddressFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: OrderActiveListingRouteIntermediateAddressFilter =
      {
        type: OrderActiveListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: OrderActiveListingRoutePickupAddressFilter =
      {
        type: OrderActiveListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: OrderActiveListingExecutionStatusFilter = {
      type: OrderActiveListingFilterType.EXECUTION_STATUS,
      value:
        routeQueryFilterParams.status === "CANDIDATURE"
          ? CargoOrderActiveListItemExecutionStatus.CANDIDATURE
          : routeQueryFilterParams.status === "IN_PROGRESS"
          ? CargoOrderActiveListItemExecutionStatus.IN_PROGRESS
          : routeQueryFilterParams.status === "PENDING"
          ? CargoOrderActiveListItemExecutionStatus.PENDING
          : CargoOrderActiveListItemExecutionStatus.PLANNED,
    };

    filters.push(statusFilter);
  }

  if (routeQueryFilterParams.taxi) {
    const taxiFilter: OrderActiveListingTaxiCorporationFilter = {
      type: OrderActiveListingFilterType.TAXI_CORPORATION,
      value: routeQueryFilterParams.taxi,
    };

    filters.push(taxiFilter);
  }

  if (routeQueryFilterParams.excludedTaxi) {
    const taxiFilter: OrderActiveListingExcludeTaxiCorporationFilter = {
      type: OrderActiveListingFilterType.EXCLUDE_TAXI_CORPORATION,
      value: routeQueryFilterParams.excludedTaxi,
    };

    filters.push(taxiFilter);
  }

  if (routeQueryFilterParams.isUrgentOnly) {
    const urgentOnlyFilter: OrderActiveListingUrgentOnlyFilter = {
      type: OrderActiveListingFilterType.URGENT_ONLY,
      value: routeQueryFilterParams.isUrgentOnly === "true" ? true : false,
    };

    filters.push(urgentOnlyFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: OrderActiveListingRouteQueryParams
): OrderActiveListingSortKey | undefined => {
  return routeQueryParams.sort as OrderActiveListingSortKey | undefined;
};

const createRouteQueryParams = (
  filters: OrderActiveListingFilter[],
  sortKey: OrderActiveListingSortKey | null,
  page: number,
  pageSize: number,
  defaultSelectedOrderUuid: string | undefined
): OrderActiveListingRouteQueryParams => {
  const internalOrderIdFilterValue = filters.find(
    (filter) => filter.type === OrderActiveListingFilterType.INTERNAL_ORDER_ID
  )?.value as OrderActiveListingInternalOrderIdFilter["value"] | undefined;

  return {
    page: String(page),
    pageSize: String(pageSize),
    sort: sortKey ?? undefined,
    internalOrderId: internalOrderIdFilterValue
      ? String(internalOrderIdFilterValue)
      : undefined,
    externalOrderId: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.EXTERNAL_ORDER_ID
    )?.value as OrderActiveListingExternalOrderIdFilter["value"] | undefined,
    client: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.CLIENT
    )?.value as OrderActiveListingClientFilter["value"] | undefined,
    excludedClient: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.EXCLUDE_CLIENT
    )?.value as OrderActiveListingExcludeClientFilter["value"] | undefined,
    dispatch: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.DISPATCH
    )?.value as OrderActiveListingDispatchFilter["value"] | undefined,
    excludedDispatch: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.EXCLUDE_DISPATCH
    )?.value as OrderActiveListingExcludeDispatchFilter["value"] | undefined,
    driver: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.DRIVER
    )?.value as OrderActiveListingDriverFilter["value"] | undefined,
    isMobileAppUser: (
      filters.find(
        (filter) => filter.type === OrderActiveListingFilterType.MOBILE_APP_USER
      )?.value as OrderActiveListingMobileAppUserFilter["value"] | undefined
    )?.toString(),
    passenger: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.PASSENGER
    )?.value as OrderActiveListingPassengerFilter["value"] | undefined,
    routeAddress: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.ROUTE_ADDRESS
    )?.value as OrderActiveListingRouteAddressFilter["value"] | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type === OrderActiveListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | OrderActiveListingRouteDestinationAddressFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type === OrderActiveListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | OrderActiveListingRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type === OrderActiveListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as OrderActiveListingRoutePickupAddressFilter["value"] | undefined,
    status: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.EXECUTION_STATUS
    )?.value as OrderActiveListingExecutionStatusFilter["value"] | undefined,
    taxi: filters.find(
      (filter) => filter.type === OrderActiveListingFilterType.TAXI_CORPORATION
    )?.value as OrderActiveListingTaxiCorporationFilter["value"] | undefined,
    excludedTaxi: filters.find(
      (filter) =>
        filter.type === OrderActiveListingFilterType.EXCLUDE_TAXI_CORPORATION
    )?.value as
      | OrderActiveListingExcludeTaxiCorporationFilter["value"]
      | undefined,
    isUrgentOnly: (
      filters.find(
        (filter) => filter.type === OrderActiveListingFilterType.URGENT_ONLY
      )?.value as OrderActiveListingUrgentOnlyFilter["value"] | undefined
    )?.toString(),
    defaultSelectedOrderUuid: defaultSelectedOrderUuid,
  };
};

const getPage = (
  routeQueryFilterParams: OrderActiveListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: OrderActiveListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const orderActiveListingFilterService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default orderActiveListingFilterService;
