import { FC } from "react";
import classNames from "classnames";
import BillingStatus from "../../types/billing-status";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";

type BillingsTableStatusProps = {
  status: BillingStatus | null;
};

const BillingsTableStatusComponent: FC<BillingsTableStatusProps> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case BillingStatus.CREATED:
        return "created";
      case BillingStatus.ACCEPTED:
        return "accepted";
      case BillingStatus.REJECTED:
        return "rejected";
      case BillingStatus.REOPENED:
        return "reopened";
      case BillingStatus.REOPEN_REQUEST:
        return "reopen_request";
      default:
        return "";
    }
  };

  const createStatusTitle = () => {
    const translations =
      billingsTranslationsHelper.getBillingsTranslations().statuses;

    switch (props.status) {
      case BillingStatus.CREATED:
        return translations.createdStatusLabel;
      case BillingStatus.ACCEPTED:
        return translations.acceptedStatusLabel;
      case BillingStatus.REJECTED:
        return translations.rejectedStatusLabel;
      case BillingStatus.REOPENED:
        return translations.reopenedStatusLabel;
      case BillingStatus.REOPEN_REQUEST:
        return translations.reopenRequestStatusLabel;
      default:
        return "";
    }
  };

  const statusClassname = getClassnameByStatus();
  const statusTitle = createStatusTitle();

  const containerClassNames = classNames(
    "billings_item_table_status",
    statusClassname
  );

  return <div title={statusTitle} className={containerClassNames}></div>;
};

export default BillingsTableStatusComponent;
