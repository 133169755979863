import ListingFilter from "../../../types/listing-filter";
import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
} from "./types/listing-filter-definition";

const checkIsFilterApplied = (
  filterDefinitionOption: ListingFilterDefinitionOption<any>,
  appliedFilters: ListingFilter[]
): boolean => {
  return !!appliedFilters.find(
    (appliedFilter) => appliedFilter.type === filterDefinitionOption.filterType
  );
};

const getUnappliedFilterOptions = (
  filterDefinition: ListingFilterDefinition<any>,
  appliedFilters: ListingFilter[]
): ListingFilterDefinitionOption<any>[] => {
  return filterDefinition.options.filter(
    (option) => !checkIsFilterApplied(option, appliedFilters)
  );
};

const listingFilterHelper = {
  getUnappliedFilterOptions,
};

export default listingFilterHelper;
