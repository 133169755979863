import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import userFormValidationService from "../../../../../shared/user/user.validation.service";
import PassengerAddress from "../../common/types/passenger-address";
import PassengerDispatchSelectOption from "../../common/types/passenger-dispatch-select-option";

const validateAddresses = (
  addresses: PassengerAddress[]
): FormValidationResult => {
  const validationSchema = Joi.array().min(1).required();

  return formValidationService.validate(addresses, validationSchema);
};

const validateDispatches = (
  dispatches: PassengerDispatchSelectOption[]
): FormValidationResult => {
  const validationSchema = Joi.array().min(1).required();

  return formValidationService.validate(dispatches, validationSchema);
};

const passengerEditFormValidationService = {
  validateEmail: userFormValidationService.validateEmail,
  validateFirstName: userFormValidationService.validateFirstName,
  validateLastName: userFormValidationService.validateLastName,
  validateMobile: userFormValidationService.validateMobile,
  validateAlternativeMobile:
    userFormValidationService.validateAlternativeMobile,
  validateAddresses,
  validateDispatches,
};

export default passengerEditFormValidationService;
