import CargoCompanyDispatchAddRequest, {
  CargoCompanyDispatchAddRequestBody,
} from "./cargo-company-dispatch-add-request";

const createBody = (
  data: CargoCompanyDispatchAddRequest
): CargoCompanyDispatchAddRequestBody => {
  return {
    addressString: data.addressString,
    dispatchName: data.dispatchName,
    displayName: data.displayName,
  };
};

const cargoCompanyDispatchAddRequestFactory = {
  createBody,
};

export default cargoCompanyDispatchAddRequestFactory;
