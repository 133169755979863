import { FC } from "react";
import { PlannerDriverPlanListItem } from "../../../../../common/services/planner/list/planner-driver-plan-list";
import { PlannerDriverPlanOrderItem } from "../../../../../common/services/planner/order/planner-driver-plan-order";
import { PlanningOrderSelectedItemData } from "../../common/types/planning-order-selected-data";
import PlanningOrderDriverPlanListingItemComponent from "./item/planning-order-driver-plan-listing-item.component";
import planningTranslationsHelper from "../../../../../languages/planning-translations.helper";

type PlanningOrderDriverPlanListingItemsProps = {
  items: PlannerDriverPlanListItem[];
  order: PlannerDriverPlanOrderItem;
  onButtonClick: (item: PlanningOrderSelectedItemData) => void;
};

const PlanningOrderDriverPlanListingItemsComponent: FC<
  PlanningOrderDriverPlanListingItemsProps
> = (props) => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing;

  const DriverPlanListingContent = props.items.map((item, index) => (
    <PlanningOrderDriverPlanListingItemComponent
      item={item}
      key={index}
      order={props.order}
      onClick={props.onButtonClick}
    />
  ));

  const NoDataContent = (
    <div className="table__no_data_wrapper">
      {translations.noContentMessage}
    </div>
  );

  return (
    <div className="planning_order_driver_plan_items mt-4">
      {props.items.length ? DriverPlanListingContent : NoDataContent}
    </div>
  );
};

export default PlanningOrderDriverPlanListingItemsComponent;
