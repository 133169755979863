import { SearchAddressResponseItem } from "../../../../../common/utils/search-address/search-address.response";
import MileageAddressAddModalAddress from "./types/address-add-modal-address";
import AddressAddModalData from "./types/address-add-modal-data";
import MileageAddressAddModalOption from "./types/address-add-modal-option";
import MileageAddressAddModalSearchSelectOption from "./types/address-add-modal-search-select-option";

const createSearchAddressOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): MileageAddressAddModalOption => {
  return {
    displayName: searchAddressResponseItem.display_name,
    apartmentNumber: "",
    country: searchAddressResponseItem.address.country,
    countryCode: searchAddressResponseItem.address.country_code,
    houseNumber: searchAddressResponseItem.address.house_number ?? null,
    latitude: Number(searchAddressResponseItem.lat),
    longitude: Number(searchAddressResponseItem.lon),
    street: searchAddressResponseItem.address.road ?? null,
    town:
      searchAddressResponseItem.address.city ??
      searchAddressResponseItem.address.town ??
      searchAddressResponseItem.address.village ??
      null,
    zipCode: searchAddressResponseItem.address.postcode ?? null,
  };
};

const createSearchAddressSelectOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): MileageAddressAddModalSearchSelectOption => {
  const address = createSearchAddressOption(searchAddressResponseItem);

  return {
    label: searchAddressResponseItem.display_name,
    value: address,
  };
};

const createAddressSelectOptions = (
  searchAddressResponseItems: SearchAddressResponseItem[]
): MileageAddressAddModalSearchSelectOption[] => {
  return searchAddressResponseItems.map(createSearchAddressSelectOption);
};

const createAddress = (
  formData: AddressAddModalData
): MileageAddressAddModalAddress => {
  return {
    apartmentNumber: formData.apartmentNumber,
    houseNumber: formData.houseNumber,
    latitude: formData.foundAddress?.value.latitude!,
    longitude: formData.foundAddress?.value.longitude!,
    street: formData.street,
    town: formData.town,
    zipCode: formData.zipCode,
  };
};

const addressAddModalFactory = {
  createSearchAddressOption,
  createAddressSelectOptions,
  createAddress,
};

export default addressAddModalFactory;
