import authValidationService from "../../../common/validation/auth-validation.service";

const signUpDriverAccountDataFormValidationService = {
  validateEmail: authValidationService.validateEmail,
  validateUsername: authValidationService.validateUsername,
  validatePassword: authValidationService.validatePassword,
  validatePasswordRepeat: authValidationService.validatePasswordRepeat,
};

export default signUpDriverAccountDataFormValidationService;
