import MileageDictionaryListLoadParams from "../../../../../../common/services/mileage-dictionary/list/mileage-dictionary-list-load-params";
import paginationService from "../../../../../../common/utils/pagination/pagination.service";

const create = (
  page: number,
  pageSize: number,
  cargoCompanyUuid: string
): MileageDictionaryListLoadParams => {
  return {
    cargoCompanyUuid: cargoCompanyUuid,
    limit: pageSize,
    offset: paginationService.calculateOffset(page, pageSize),
  };
};

const mileageDictionaryListingLoadParamsFactory = {
  create,
};

export default mileageDictionaryListingLoadParamsFactory;
