import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import dispatcherAddByRailyFormValidationService from "./dispatcher-add-by-raily-form-validation.service";
import DispatcherAddByRailyFormData from "./types/dispatcher-add-by-raily-form-data";

const getDefaultFormData = (): DispatcherAddByRailyFormData => {
  return {
    firstName: "",
    lastName: "",
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
    dispatchUuid: "",
    cargoCompanyUuid: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<DispatcherAddByRailyFormData> => {
    return {
      alternativePhoneNumber: (values) =>
        dispatcherAddByRailyFormValidationService.validateAlternativePhoneNumber(
          values.alternativePhoneNumber
        ),
      dispatchUuid: (values) =>
        dispatcherAddByRailyFormValidationService.validateDispatch(
          values.dispatchUuid
        ),
      email: (values) =>
        dispatcherAddByRailyFormValidationService.validateEmail(values.email),
      firstName: (values) =>
        dispatcherAddByRailyFormValidationService.validateFirstName(
          values.firstName
        ),
      lastName: (values) =>
        dispatcherAddByRailyFormValidationService.validateLastName(
          values.lastName
        ),
      password: (values) =>
        dispatcherAddByRailyFormValidationService.validatePassword(
          values.password
        ),
      passwordRepeat: (values) =>
        dispatcherAddByRailyFormValidationService.validatePasswordRepeat(
          values.password,
          values.passwordRepeat
        ),
      phoneNumber: (values) =>
        dispatcherAddByRailyFormValidationService.validatePhoneNumber(
          values.phoneNumber
        ),
      username: async (values) =>
        await dispatcherAddByRailyFormValidationService.validateUsername(
          values.username
        ),
      cargoCompanyUuid: (values) =>
        dispatcherAddByRailyFormValidationService.validateCargoCompany(
          values.cargoCompanyUuid
        ),
    };
  };

const dispatcherAddByRailyFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default dispatcherAddByRailyFormHelper;
