import MileageSettingsDetails, {
  MileageSettingsDetailsMileageNumberCreationModel,
} from "../../../../common/services/mileage-settings/details/mileage-settings-details";
import MileageSettingsMileageNumberCreationModel from "../types/mileage-settings-mileage-number-creation-model";
import MileageSettingsFormData from "./types/mileage-settings-form-data";

const createFormCreationModel = (
  model: MileageSettingsDetailsMileageNumberCreationModel
): MileageSettingsMileageNumberCreationModel => {
  switch (model) {
    case MileageSettingsDetailsMileageNumberCreationModel.AUTO:
      return MileageSettingsMileageNumberCreationModel.AUTO;
    case MileageSettingsDetailsMileageNumberCreationModel.DICT:
      return MileageSettingsMileageNumberCreationModel.DICT;
    case MileageSettingsDetailsMileageNumberCreationModel.MANUAL:
      return MileageSettingsMileageNumberCreationModel.MANUAL;
  }
};

const create = (details: MileageSettingsDetails): MileageSettingsFormData => {
  return {
    defaultIsCardNumberRequired: details.isCardNumberRequired,
    defaultIsMileageNumberRequired: details.isMileageNumberRequired,
    defaultMileageNumberCreationModel: createFormCreationModel(
      details.mileageNumberCreationModel
    ),
    isCardNumberRequired: details.isCardNumberRequired,
    isMileageNumberRequired: details.isMileageNumberRequired,
    mileageNumberCreationModel: createFormCreationModel(
      details.mileageNumberCreationModel
    ),
  };
};

const createRestoreData = (
  formData: MileageSettingsFormData
): MileageSettingsFormData => {
  return {
    defaultIsCardNumberRequired: formData.defaultIsCardNumberRequired,
    defaultIsMileageNumberRequired: formData.defaultIsMileageNumberRequired,
    defaultMileageNumberCreationModel:
      formData.defaultMileageNumberCreationModel,
    isCardNumberRequired: formData.defaultIsCardNumberRequired,
    isMileageNumberRequired: formData.defaultIsMileageNumberRequired,
    mileageNumberCreationModel: formData.defaultMileageNumberCreationModel,
  };
};

const mileageSettingsFormFactory = {
  create,
  createRestoreData,
};

export default mileageSettingsFormFactory;
