import userTranslationsHelper from "../../../../../languages/user-translations.helper";

const translations =
  userTranslationsHelper.getPassengerListingTranslations().filters.search;

const validateAddress = (address: string): string | undefined => {
  const regex = /^[a-zA-Z0-9\/-]*$/;
  return regex.test(address)
    ? undefined
    : translations.invalidCharactersErrorMessage;
};

const passengerListingFilterValidationService = {
  validateAddress,
};

export default passengerListingFilterValidationService;
