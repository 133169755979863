import MileageAddAddress from "../types/mileage-add-address";
import MileageAddParams, {
  MileageAddParamsNode,
  MileageAddParamsVehicleType,
} from "../../../../../common/services/mileage/add/mileage-add-params";
import MileageAddVehicleType from "../types/mileage-add-vehicle-type";
import MileageAddFormData from "../form/types/mileage-add-form-data";

const createNode = (address: MileageAddAddress): MileageAddParamsNode => {
  return {
    displayName: address.displayName,
    lat: address.latitude,
    lon: address.longitude,
    time: address.time,
    apartmentNumber: address.apartmentNumber,
    houseNumber: address.houseNumber,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createNodes = (
  addresses: MileageAddAddress[],
  startDate: Date,
  pointToPointDurations: number[],
  distances: number[]
) => {
  const nodes = addresses.map(createNode);

  nodes[0].time = startDate;
  nodes[0].distance = 0;
  pointToPointDurations.forEach((duration, index) => {
    nodes[index + 1].time = new Date(
      nodes[index].time?.getTime()! + 1000 * duration
    );
  });
  distances.forEach((distance, index) => {
    nodes[index + 1].distance = distance;
  });

  return nodes;
};

const createVehicleType = (
  type: MileageAddVehicleType
): MileageAddParamsVehicleType => {
  switch (type) {
    case MileageAddVehicleType.CAR_GT_900CM3:
      return MileageAddParamsVehicleType.CAR_GT_900CM3;
    case MileageAddVehicleType.CAR_LE_900CM3:
      return MileageAddParamsVehicleType.CAR_LE_900CM3;
    case MileageAddVehicleType.COMPANY_OWNED:
      return MileageAddParamsVehicleType.COMPANY_OWNED;
    case MileageAddVehicleType.MOPED:
      return MileageAddParamsVehicleType.MOPED;
    case MileageAddVehicleType.MOTORCYCLE:
      return MileageAddParamsVehicleType.MOTORCYCLE;
  }
};

const create = (
  formData: MileageAddFormData,
  pointToPointDurations: number[],
  distances: number[]
): MileageAddParams => {
  return {
    mileageDate: formData.mileageDate!,
    workerCompanyUuid: formData.companyUuid!,
    workerUuid: formData.workerUuid!,
    cardNumber: formData.cardNumber?.trim(),
    commissionNumber: formData.commissionNumber,
    distance: formData.distance ?? undefined,
    mileageNumber: formData.mileageNumber?.trim(),
    notes: formData.notes,
    vehicleType: createVehicleType(formData.vehicleType),
    nodes: createNodes(
      formData.addressSequence,
      formData.mileageDate!,
      pointToPointDurations,
      distances
    ),
    osrmDistance: formData.osrmDistance ?? formData.distance,
  };
};

const mileageAddParamsFactory = {
  create,
};

export default mileageAddParamsFactory;
