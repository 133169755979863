export enum CargoAddressAddRequestAddressType {
  HOTEL = "HOTEL",
  STATION = "STATION",
  MEETING_POINT = "MEETING_POINT",
}

type CargoAddressAddAddressRequest = {
  lat: number;
  lon: number;
  country: string;
  town: string;
  zip_code: string;
  street: string;
  house_no: string;
  apartment: string | null;
  description: string | null;
  type: CargoAddressAddRequestAddressType;
};

export default CargoAddressAddAddressRequest;
