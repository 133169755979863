import { FC } from "react";
import BillingsCargoTaxiContributionsDiscountTableRow from "../types/billings-cargo-taxi-contributions-discount-table-row";
import BillingsCargoTaxiContributionsDiscountTableColumn from "../types/billings-cargo-taxi-contributions-discount-table-column";
import BillingsCargoTaxiContributionsDiscountTableHelper from "./billings-cargo-taxi-contributions-discount-table.helper";
import TableComponent from "../../../../../../../common/components/table/table.component";

type BillingsCargoTaxiContributionsDiscountTableProps = {
  rows: BillingsCargoTaxiContributionsDiscountTableRow[];
  isLoading: boolean;
};

const BillingsCargoTaxiContributionsDiscountTableComponent: FC<
  BillingsCargoTaxiContributionsDiscountTableProps
> = (props) => {
  const columns: BillingsCargoTaxiContributionsDiscountTableColumn[] =
    BillingsCargoTaxiContributionsDiscountTableHelper.getColumns();

  return (
    <TableComponent
      columns={columns}
      rows={props.rows}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsCargoTaxiContributionsDiscountTableComponent;
