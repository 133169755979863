import CargoCompanyDispatcherListResponse, {
  CargoCompanyDispatcherListResponseItem,
} from "../../../../api/raily/cargo-company/dispatcher/list/cargo-company-dispatcher-list.response";
import { CargoCompanyDispatcherListItem } from "./cargo-company-dispatcher-list";

const createDispatcherListItem = (
  item: CargoCompanyDispatcherListResponseItem
): CargoCompanyDispatcherListItem => {
  return {
    addresses: [],
    displayName: `${item.user.firstName} ${item.user.lastName}`,
    uuid: item.user.id,
  };
};

const createDispatcherList = (
  response: CargoCompanyDispatcherListResponse
): CargoCompanyDispatcherListItem[] => {
  return response.data.map(createDispatcherListItem);
};

const cargoCompanyDispatcherListFactory = {
  createDispatcherList,
};

export default cargoCompanyDispatcherListFactory;
