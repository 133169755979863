import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import cargoOrderSettlementListingUserPermissionsHelper from "./common/user-permissions/cargo-order-settlement-listing-user-permission.helper";
import settlementTranslationsHelper from "../../../../languages/settlement-translations.helper";
import settlementBreadcrumbsHelper from "../../common/breadcrumbs/settlement-breadcrumbs.helper";
import CargoOrderSettlementListingViewMode from "./common/types/cargo-order-settlement-listing-view-mode";
import ButtonComponent from "../../../../common/components/button/button.component";
import CargoOrderSettlementListingByRailyComponent from "./by-raily/cargo-order-settlement-listing-by-raily.component";
import CargoOrderSettlementListingByCargoComponent from "./by-cargo/cargo-order-settlement-listing-by-cargo.component";

type CargoOrderSettlementListingProps = {};

const CargoOrderSettlementListingComponent: FC<
  CargoOrderSettlementListingProps
> = () => {
  const { user, selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.settlementsCargoSettledOrderListing
  );

  const userPermissions = useMemo(
    () =>
      cargoOrderSettlementListingUserPermissionsHelper.getPermissions(
        user?.roles!
      ),
    []
  );

  const translations = useMemo(
    () =>
      settlementTranslationsHelper.getCargoOrderSettlementListingTranslations(),
    [selectedAppLanguage]
  );

  useEffect(() => {
    const breadcrumbs =
      settlementBreadcrumbsHelper.getCargoOrderSettlementListingBreadcrumbs();

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<CargoOrderSettlementListingViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() =>
          setSelectedViewMode(CargoOrderSettlementListingViewMode.RAILY)
        }
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToCargoButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() =>
          setSelectedViewMode(CargoOrderSettlementListingViewMode.CARGO)
        }
        title={translations.header.changeViewToCargoButtonTitle}
      >
        {translations.header.changeViewToCargoButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: CargoOrderSettlementListingViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToCargoButton,
          hasPermission: userPermissions.hasAccessToCargoView,
        },
      },
      {
        viewMode: CargoOrderSettlementListingViewMode.CARGO,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToCargoButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: CargoOrderSettlementListingViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: CargoOrderSettlementListingViewMode.RAILY,
        component: (
          <CargoOrderSettlementListingByRailyComponent
            actionButtons={getViewChangeButtons(
              CargoOrderSettlementListingViewMode.RAILY
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: CargoOrderSettlementListingViewMode.CARGO,
        component: (
          <CargoOrderSettlementListingByCargoComponent
            actionButtons={getViewChangeButtons(
              CargoOrderSettlementListingViewMode.CARGO
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToCargoView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default CargoOrderSettlementListingComponent;
