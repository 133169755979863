import DispatcherUpdateParams from "../../../../../common/services/dispatcher/update/dispatcher-update-params";
import DispatcherEditFormData from "./types/dispatcher-edit-form-data";

const create = (
  formData: DispatcherEditFormData,
  dispatcherUuid: string
): DispatcherUpdateParams => {
  return {
    alternativePhoneNumber: formData.alternativePhoneNumber,
    dispatcherUuid,
    dispatchUuid: formData.dispatchUuid,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    phoneNumber: formData.phoneNumber,
    username: formData.username,
  };
};

const dispatcherUpdateParamsFactory = {
  create,
};

export default dispatcherUpdateParamsFactory;
