import delegationTranslationsHelper from "../../../../../languages/delegation-translations.helper";
import DelegationAddVehicleType from "../types/delegation-add-vehicle-type";

const getVehicleTypeLabel = (type: DelegationAddVehicleType): string => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().vehicleType;
  switch (type) {
    case DelegationAddVehicleType.COMPANY_OWNED:
      return translations.typeCompanyLabel;
    case DelegationAddVehicleType.CAR_GT_900CM3:
      return translations.typeOwnGT900;
    case DelegationAddVehicleType.CAR_LE_900CM3:
      return translations.typeOwnLE900;
    case DelegationAddVehicleType.MOPED:
      return translations.typeMoped;
    case DelegationAddVehicleType.MOTORCYCLE:
      return translations.typeMotorcycle;
  }
};

const calculateDate = (date: Date, delay: number) => {
  return new Date(date.getTime() + 1000 * delay);
};

const delegationAddHelper = {
  getVehicleTypeLabel,
  calculateDate,
};

export default delegationAddHelper;
