import useAsyncData from "../../../hooks/use-async-data";
import mileageDictionaryService from "../mileage-dictionary.service";
import MileageDictionaryDetails from "./mileage-dictionary-details";
import MileageDictionaryDetailsLoadParams from "./mileage-dictionary-details-load-params";

const useMileageDictionaryDetails = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<MileageDictionaryDetails | null>(null);

  const load = async (
    loadParams: MileageDictionaryDetailsLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const mileageDictionaryDetails = await mileageDictionaryService.details(
        loadParams,
        signal
      );

      setData(mileageDictionaryDetails);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useMileageDictionaryDetails;
