import listingFilterService from "../../../../../../../../common/utils/listing/filters/listing-filter.service";
import DriverContractListingBillingModel from "../../../common/types/driver-contract-listing-billing-model";
import DriverContractListingByTaxiFilter from "../types/driver-contract-listing-by-taxi-filter";
import DriverContractListingByTaxiFilterType from "../types/driver-contract-listing-by-taxi-filter-type";
import DriverContractListingByTaxiListingItem from "../types/driver-contract-listing-by-taxi-listing-item";

const filterListingItemsByBillingModel = (
  listingItems: DriverContractListingByTaxiListingItem[],
  options: DriverContractListingBillingModel[]
) => {
  return listingFilterService.filterByEnumProperty(
    listingItems,
    "billingModel",
    options
  );
};

const filterListingItems = (
  listingItems: DriverContractListingByTaxiListingItem[],
  filters: DriverContractListingByTaxiFilter[]
): DriverContractListingByTaxiListingItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: DriverContractListingByTaxiListingItem[] = [
    ...listingItems,
  ];

  const billingModelFilterQueries =
    listingFilterService.getValuesOfGivenFilterType(
      filters,
      DriverContractListingByTaxiFilterType.BILLING_MODEL
    );

  if (billingModelFilterQueries.length) {
    filteredListingItems = filterListingItemsByBillingModel(
      filteredListingItems,
      billingModelFilterQueries
    );
  }

  return filteredListingItems;
};

const driverContractListingByTaxiFilterService = {
  filterListingItems,
};

export default driverContractListingByTaxiFilterService;
