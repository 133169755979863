import { CargoCompanyListingRouteQueryParams } from "../../../../common/routes/types/cargo-company-listing-route-params";
import CargoCompanyListingFilter, {
  CargoCompanyListingAddressFilter,
  CargoCompanyListingCompanyIdFilter,
  CargoCompanyListingCompanyNameFilter,
  CargoCompanyListingCompanyDisplayNameFilter,
  CargoCompanyListingNationalCourtRegisterFilter,
  CargoCompanyListingNotesFilter,
  CargoCompanyListingTaxNumberFilter,
} from "../common/types/cargo-company-listing-filter";
import CargoCompanyListingFilterType from "../common/types/cargo-company-listing-filter-type";
import CargoCompanyListingSortKey from "../common/types/cargo-company-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: CargoCompanyListingRouteQueryParams
): CargoCompanyListingFilter[] => {
  const filters: CargoCompanyListingFilter[] = [];

  if (routeQueryFilterParams.taxNumber) {
    const taxNumberFilter: CargoCompanyListingTaxNumberFilter = {
      type: CargoCompanyListingFilterType.TAX_NUMBER,
      value: routeQueryFilterParams.taxNumber,
    };

    filters.push(taxNumberFilter);
  }

  if (routeQueryFilterParams.companyId) {
    const companyIdFilter: CargoCompanyListingCompanyIdFilter = {
      type: CargoCompanyListingFilterType.COMPANY_ID,
      value: routeQueryFilterParams.companyId,
    };

    filters.push(companyIdFilter);
  }

  if (routeQueryFilterParams.nationalCourtRegister) {
    const nationalCourtRegisterFilter: CargoCompanyListingNationalCourtRegisterFilter =
      {
        type: CargoCompanyListingFilterType.NATIONAL_COURT_REGISTER,
        value: routeQueryFilterParams.nationalCourtRegister,
      };

    filters.push(nationalCourtRegisterFilter);
  }

  if (routeQueryFilterParams.companyName) {
    const companyNameFilter: CargoCompanyListingCompanyNameFilter = {
      type: CargoCompanyListingFilterType.COMPANY_NAME,
      value: routeQueryFilterParams.companyName,
    };

    filters.push(companyNameFilter);
  }

  if (routeQueryFilterParams.displayName) {
    const displayNameFilter: CargoCompanyListingCompanyDisplayNameFilter = {
      type: CargoCompanyListingFilterType.COMPANY_DISPLAY_NAME,
      value: routeQueryFilterParams.displayName,
    };

    filters.push(displayNameFilter);
  }

  if (routeQueryFilterParams.address) {
    const addressFilter: CargoCompanyListingAddressFilter = {
      type: CargoCompanyListingFilterType.ADDRESS,
      value: routeQueryFilterParams.address,
    };

    filters.push(addressFilter);
  }

  if (routeQueryFilterParams.notes) {
    const notesFilter: CargoCompanyListingNotesFilter = {
      type: CargoCompanyListingFilterType.NOTES,
      value: routeQueryFilterParams.notes,
    };

    filters.push(notesFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: CargoCompanyListingRouteQueryParams
): CargoCompanyListingSortKey | undefined => {
  return routeQueryParams.sort as CargoCompanyListingSortKey;
};

const getPage = (
  routeQueryFilterParams: CargoCompanyListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: CargoCompanyListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: CargoCompanyListingFilter[],
  sortKey: CargoCompanyListingSortKey | null,
  page: number,
  pageSize: number
): CargoCompanyListingRouteQueryParams => {
  const routeQueryParams: CargoCompanyListingRouteQueryParams = {
    taxNumber: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.TAX_NUMBER
    )?.value as CargoCompanyListingTaxNumberFilter["value"],
    companyId: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.COMPANY_ID
    )?.value as CargoCompanyListingCompanyIdFilter["value"],
    nationalCourtRegister: filters.find(
      (filter) =>
        filter.type === CargoCompanyListingFilterType.NATIONAL_COURT_REGISTER
    )?.value as CargoCompanyListingNationalCourtRegisterFilter["value"],
    companyName: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.COMPANY_NAME
    )?.value as CargoCompanyListingCompanyNameFilter["value"],
    displayName: filters.find(
      (filter) =>
        filter.type === CargoCompanyListingFilterType.COMPANY_DISPLAY_NAME
    )?.value as CargoCompanyListingCompanyDisplayNameFilter["value"],
    address: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.ADDRESS
    )?.value as CargoCompanyListingAddressFilter["value"],
    notes: filters.find(
      (filter) => filter.type === CargoCompanyListingFilterType.NOTES
    )?.value as CargoCompanyListingNotesFilter["value"],
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const cargoCompanyListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default cargoCompanyListingFiltersService;
