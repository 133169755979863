import HttpResponse from "../../../../../common/utils/http/http.response";
import BillingNode from "./types/billing-node";
import { OrderForwardingAction } from "./types/order-forwarding-action";
import PlanEntry from "./types/plan-entry";

export enum BillingDataResponseContributionType {
  BASE_AMOUNT = "BASE_AMOUNT",
  CHARGE_HALTINGS = "CHARGE_HALTING",
  CHARGE_HIGHWAYS = "CHARGE_HIGHWAY",
  BONUS_FAVORABLE_DISTANCE = "BONUS_FAVORABLE_DISTANCE",
  BONUS_OTHER = "BONUS_OTHER",
  PENALTY_WRONG_ROUTE = "PENALTY_WRONG_ROUTE",
  PENALTY_BEING_LATE = "PENALTY_BEING_LATE",
  PENALTY_INCOMPATIBLE_CAR = "PENALTY_INCOMPATIBLE_CAR",
  PENALTY_OTHER = "PENALTY_OTHER",
}

export type BillingDataResponseBonusContributionFavorableDistance = {
  type: BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE;
  comment: string | null;
  distance: number;
  rate: number;
};

export type BillingDataResponseBonusContributionOther = {
  type: BillingDataResponseContributionType.BONUS_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingDataResponsePenaltyContributionWrongRoute = {
  type: BillingDataResponseContributionType.PENALTY_WRONG_ROUTE;
  comment: string | null;
  amount: number;
};

export type BillingDataResponsePenaltyContributionBeingLate = {
  type: BillingDataResponseContributionType.PENALTY_BEING_LATE;
  comment: string | null;
  amount: number;
};

export type BillingDataResponsePenaltyContributionIncompatibleCar = {
  type: BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR;
  comment: string | null;
  amount: number;
};

export type BillingDataResponsePenaltyContributionOther = {
  type: BillingDataResponseContributionType.PENALTY_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingDataResponseBonusContribution =
  | BillingDataResponseBonusContributionFavorableDistance
  | BillingDataResponseBonusContributionOther;

export type BillingDataResponsePenaltyContribution =
  | BillingDataResponsePenaltyContributionWrongRoute
  | BillingDataResponsePenaltyContributionBeingLate
  | BillingDataResponsePenaltyContributionIncompatibleCar
  | BillingDataResponsePenaltyContributionOther;

export type BillingDataResponseContribution =
  | BillingDataResponseBonusContribution
  | BillingDataResponsePenaltyContribution;

export type BillingData = {
  order_forwarding_action: OrderForwardingAction;
  billing_nodes: BillingNode[];
  billing_contributions: BillingDataResponseContribution[];
  all_contributions_amount: number;
  base_amount_value: number;
  amount_for_charge_haltings: number;
  amount_for_charge_highways: number;
  distance: number;
  amount_for_distance: number;
  plan_entry: PlanEntry;
  plan_entry_id: string;
  sum_of_bonuses: number;
  sum_of_penalties: number;
  date: Date;
  passengers: string[];
  rate_for_distance: number;
  favorable_distance: number;
};

export type BillingDataResponse = HttpResponse<BillingData>;

export default BillingDataResponse;
