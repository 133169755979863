import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type MileageContractListingUserPermissionOption =
  | "hasVisibleAddContractButton"
  | "hasAccessToEditMileageContracts";

export type MileageContractListingUserPermissionsDefinition =
  UserPermissionsDefinition<MileageContractListingUserPermissionOption>;

export type MileageContractListingUserPermissions =
  UserPermissions<MileageContractListingUserPermissionOption>;

const mileageContractListingUserPermissionDefinition: MileageContractListingUserPermissionsDefinition =
  {
    hasVisibleAddContractButton: [UserRole.ADMIN],
    hasAccessToEditMileageContracts: [UserRole.ADMIN],
  };

export default mileageContractListingUserPermissionDefinition;
