import Joi from "joi";
import TaxiTransferedOrderSettlementListingRouteQueryFilterParams from "./types/taxi-transfered-order-settlement-listing-route-query-filter-params";
import TaxiTransferedOrderSettlementListingFilter, {
  TaxiTransferedOrderSettlementListingConsumerTaxiFilter,
  TaxiTransferedOrderSettlementListingInternalOrderIdFilter,
  TaxiTransferedOrderSettlementListingTaxiCorporationIdFilter,
  TaxiTransferedOrderSettlementListingOrderStartDateFilter,
  TaxiTransferedOrderSettlementListingProducerTaxiFilter,
} from "./types/taxi-transfered-order-settlement-listing-filter";
import TaxiTransferedOrderSettlementListingFilterType from "./types/taxi-transfered-order-settlement-listing-filter-type";
import TaxiTransferedOrderSettlementListingRouteQueryParams from "./types/taxi-transfered-order-settlement-listing-route-query-params";
import TaxiTransferedOrderSettlementListingSortKey from "./types/taxi-transfered-order-settlement-listing-sort-key";
import taxiTransferedOrderSettlementListingFilterHelper from "./helper/taxi-transfered-order-settlement-listing-filter.helper";

const getFilters = (
  routeQueryFilterParams: TaxiTransferedOrderSettlementListingRouteQueryFilterParams
): TaxiTransferedOrderSettlementListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: TaxiTransferedOrderSettlementListingFilter[] = [];

  if (
    routeQueryFilterParams.orderStartDateFrom &&
    routeQueryFilterParams.orderStartDateTo
  ) {
    const startDateFilter: TaxiTransferedOrderSettlementListingOrderStartDateFilter =
      {
        type: TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE,
        value: {
          from: new Date(routeQueryFilterParams.orderStartDateFrom),
          to: new Date(routeQueryFilterParams.orderStartDateTo),
        },
      };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.taxiCorporationId) {
    const taxiCorporationFilter: TaxiTransferedOrderSettlementListingTaxiCorporationIdFilter =
      {
        type: TaxiTransferedOrderSettlementListingFilterType.TAXI_CORPORATION_ID,
        value: routeQueryFilterParams.taxiCorporationId,
      };
    filters.push(taxiCorporationFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: TaxiTransferedOrderSettlementListingInternalOrderIdFilter =
      {
        type: TaxiTransferedOrderSettlementListingFilterType.INTERNAL_ORDER_ID,
        value: Number(routeQueryFilterParams.internalOrderId),
      };
    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.producerTaxi) {
    const producerTaxiFilter: TaxiTransferedOrderSettlementListingProducerTaxiFilter =
      {
        type: TaxiTransferedOrderSettlementListingFilterType.PRODUCER_TAXI,
        value: routeQueryFilterParams.producerTaxi,
      };
    filters.push(producerTaxiFilter);
  }

  if (routeQueryFilterParams.consumerTaxi) {
    const consumerTaxiFilter: TaxiTransferedOrderSettlementListingConsumerTaxiFilter =
      {
        type: TaxiTransferedOrderSettlementListingFilterType.CONSUMER_TAXI,
        value: routeQueryFilterParams.consumerTaxi,
      };
    filters.push(consumerTaxiFilter);
  }

  const updatedFilters =
    taxiTransferedOrderSettlementListingFilterHelper.getDefaultFilters(filters);

  return updatedFilters;
};

const getSortKey = (
  routeQueryParams: TaxiTransferedOrderSettlementListingRouteQueryParams
): TaxiTransferedOrderSettlementListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: TaxiTransferedOrderSettlementListingFilter[],
  sortKey: TaxiTransferedOrderSettlementListingSortKey | null,
  page: number,
  pageSize: number
): TaxiTransferedOrderSettlementListingRouteQueryParams => {
  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    orderStartDateFrom: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiTransferedOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    orderStartDateTo: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiTransferedOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    internalOrderId: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiTransferedOrderSettlementListingFilterType.INTERNAL_ORDER_ID
      )?.value as
        | TaxiTransferedOrderSettlementListingInternalOrderIdFilter["value"]
        | undefined
    )?.toString(),
    consumerTaxi: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.CONSUMER_TAXI
    )?.value as
      | TaxiTransferedOrderSettlementListingConsumerTaxiFilter["value"]
      | undefined,
    producerTaxi: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.PRODUCER_TAXI
    )?.value as
      | TaxiTransferedOrderSettlementListingProducerTaxiFilter["value"]
      | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: TaxiTransferedOrderSettlementListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: TaxiTransferedOrderSettlementListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: TaxiTransferedOrderSettlementListingRouteQueryFilterParams
) => {
  const filterParams: TaxiTransferedOrderSettlementListingRouteQueryFilterParams =
    {
      orderStartDateFrom: routeQueryFilterParams.orderStartDateFrom,
      orderStartDateTo: routeQueryFilterParams.orderStartDateTo,
      taxiCorporationId: routeQueryFilterParams.taxiCorporationId,
      internalOrderId: routeQueryFilterParams.internalOrderId,
      consumerTaxi: routeQueryFilterParams.consumerTaxi,
      producerTaxi: routeQueryFilterParams.producerTaxi,
    };

  const validationSchema =
    Joi.object<TaxiTransferedOrderSettlementListingRouteQueryFilterParams>({
      orderStartDateFrom: Joi.string(),
      orderStartDateTo: Joi.string(),
      taxiCorporationId: Joi.string(),
      internalOrderId: Joi.number(),
      producerTaxi: Joi.string(),
      consumerTaxi: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: TaxiTransferedOrderSettlementListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(TaxiTransferedOrderSettlementListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: TaxiTransferedOrderSettlementListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: TaxiTransferedOrderSettlementListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const taxiTransferedOrderSettlementListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiTransferedOrderSettlementListingRouteQueryParamsService;
