import { FC } from "react";
import taxiUnsettledRouteListingTableHelper from "./taxi-unsettled-route-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import TaxiUnsettledRouteListingItem from "../types/taxi-unsettled-route-listing-item";
import TaxiUnsettledRouteListingTableColumn from "../types/taxi-unsettled-route-listing-table-column";
import TaxiUnsettledRouteListingTableRow from "../types/taxi-unsettled-route-listing-table-row";

type TaxiUnsettledRouteListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: TaxiUnsettledRouteListingItem[];
};

const TaxiUnsettledRouteListingTableComponent: FC<
  TaxiUnsettledRouteListingTableProps
> = (props) => {
  const columns: TaxiUnsettledRouteListingTableColumn[] =
    taxiUnsettledRouteListingTableHelper.getColumns();

  const rows: TaxiUnsettledRouteListingTableRow[] =
    taxiUnsettledRouteListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiUnsettledRouteListingTableComponent;
