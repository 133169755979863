import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingDetailsTableColumn from "./types/billing-details-table-column";

const getTableColumns = (): BillingDetailsTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiViewBillingsTranslations()
      .billingSummary.table;

  const tableColumns: BillingDetailsTableColumn[] = [
    {
      header: translations.headers.dateLabel,
      title: translations.headers.dateTitle,
      accessor: "date",
      colSpan: 5,
    },
    {
      header: translations.headers.internalOrderIdLabel,
      title: translations.headers.internalOrderIdTitle,
      accessor: "internalOrderId",
      colSpan: 5,
    },
    {
      header: translations.headers.companyNameLabel,
      title: translations.headers.companyNameTitle,
      accessor: "companyName",
      colSpan: 10,
    },
    {
      header: translations.headers.passengersLabel,
      title: translations.headers.passengersTitle,
      accessor: "passengers",
      colSpan: 10,
    },
    {
      header: translations.headers.modelLabel,
      title: translations.headers.modelTitle,
      accessor: "model",
      colSpan: 5,
    },
    {
      header: translations.headers.rateLabel,
      title: translations.headers.rateTitle,
      accessor: "rate",
      colSpan: 5,
    },
    {
      header: translations.headers.distanceLabel,
      title: translations.headers.distanceTitle,
      accessor: "distance",
      colSpan: 5,
    },
    {
      header: translations.headers.baseAmountValueLabel,
      title: translations.headers.baseAmountValueTitle,
      accessor: "baseAmountValue",
      colSpan: 5,
    },
    {
      header: translations.headers.amountForDistanceLabel,
      title: translations.headers.amountForDistanceTitle,
      accessor: "amountForDistance",
      colSpan: 5,
    },
    {
      header: translations.headers.contractCorrectionPercentageLabel,
      title: translations.headers.contractCorrectionPercentageTitle,
      accessor: "contractCorrectionPercentage",
      colSpan: 5,
    },
    {
      header: translations.headers.sumOfDiscountsLabel,
      title: translations.headers.sumOfDiscountsTitle,
      accessor: "sumOfDiscounts",
      colSpan: 5,
    },
    {
      header: translations.headers.amountForChargeHaltingsLabel,
      title: translations.headers.amountForChargeHaltingsTitle,
      accessor: "amountForChargeHaltings",
      colSpan: 5,
    },
    {
      header: translations.headers.amountForChargeHighwaysLabel,
      title: translations.headers.amountForChargeHighwaysTitle,
      accessor: "amountForChargeHighways",
      colSpan: 5,
    },
    {
      header: translations.headers.allContributionsAmountLabel,
      title: translations.headers.allContributionsAmountTitle,
      accessor: "allContributionsAmount",
      colSpan: 5,
    },
  ];

  return tableColumns;
};

const billingDetailsHelper = {
  getTableColumns,
};

export default billingDetailsHelper;
