import BadgeType from "./badge.type";

const getRootClassnameByType = (type?: BadgeType): string | undefined => {
  switch (type) {
    case BadgeType.BRAND:
      return "brand";
    case BadgeType.DANGER:
      return "danger";
    case BadgeType.INFO:
      return "info";
    case BadgeType.PRIMARY:
      return "primary";
    case BadgeType.SUCCESS:
      return "success";
    case BadgeType.WARNING:
      return "warning";
    default:
      return undefined;
  }
};

const badgeHelper = { getRootClassnameByType };

export default badgeHelper;
