import MileageSettingsDetailsRequest from "../../../api/raily/mileage-settings/details/mileage-settings-details.request";
import MileageSettingsDetailsLoadParams from "./mileage-settings-details-load-params";

const create = (
  params: MileageSettingsDetailsLoadParams
): MileageSettingsDetailsRequest => {
  return {
    cargoCompanyUuid: params.cargoCompanyUuid,
    active: params.active,
  };
};

const mileageSettingsDetailsRequestFactory = {
  create,
};

export default mileageSettingsDetailsRequestFactory;
