import useAsyncData from "../../../hooks/use-async-data";
import taxiFleetPartnerService from "../taxi-fleet-partner.service";
import TaxiFleetPartnerList from "./taxi-fleet-partner-list";
import TaxiFleetPartnerListLoadParams from "./taxi-fleet-partner-list-load-params";

const useTaxiFleetPartnerList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<TaxiFleetPartnerList>([]);

  const load = async (
    params: TaxiFleetPartnerListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const taxiFleetPartnerList = await taxiFleetPartnerService.getList(
        params,
        signal
      );

      setData(taxiFleetPartnerList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useTaxiFleetPartnerList;
