import paginationService from "../../../../../../common/utils/pagination/pagination.service";
import TaxiUnsettledOrderListingByRailyRequest, {
  TaxiUnsettledOrderListingByRailyRequestOrder,
} from "./api/taxi-unsettled-order-listing-by-raily.request";
import TaxiUnsettledOrderListingFilter, {
  TaxiUnsettledOrderListingClientFilter,
  TaxiUnsettledOrderListingDispatchFilter,
  TaxiUnsettledOrderListingDriverFilter,
  TaxiUnsettledOrderListingExternalOrderIdFilter,
  TaxiUnsettledOrderListingInternalOrderIdFilter,
  TaxiUnsettledOrderListingPassengerFilter,
  TaxiUnsettledOrderListingRouteAddressFilter,
  TaxiUnsettledOrderListingRouteDestinationAddressFilter,
  TaxiUnsettledOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledOrderListingRoutePickupAddressFilter,
  TaxiUnsettledOrderListingStartDateFilter,
  TaxiUnsettledOrderListingStatusFilter,
  TaxiUnsettledOrderListingTaxiFilter,
} from "../../common/types/taxi-unsettled-order-listing-filter";
import TaxiUnsettledOrderListingFilterType from "../../common/types/taxi-unsettled-order-listing-filter-type";
import TaxiUnsettledOrderListingItemStatus from "../../common/types/taxi-unsettled-order-listing-item-status";
import TaxiUnsettledOrderListingSortKey from "../../common/types/taxi-unsettled-order-listing-sort-key";

const createRequestIsCancelled = (
  filters: TaxiUnsettledOrderListingFilter[]
): TaxiUnsettledOrderListingByRailyRequest["is_cancelled"] => {
  const filterValue = filters.find(
    (filter) => filter.type === TaxiUnsettledOrderListingFilterType.STATUS
  )?.value as TaxiUnsettledOrderListingStatusFilter["value"] | undefined;

  if (filterValue === undefined) {
    return undefined;
  }

  if (filterValue === TaxiUnsettledOrderListingItemStatus.CANCELLED) {
    return true;
  }

  return false;
};

const createRequestOrder = (
  sortKey: TaxiUnsettledOrderListingSortKey | null
): TaxiUnsettledOrderListingByRailyRequest["order"] => {
  const options: {
    sortKey: TaxiUnsettledOrderListingSortKey;
    requestOrder: TaxiUnsettledOrderListingByRailyRequestOrder;
  }[] = [
    {
      requestOrder: TaxiUnsettledOrderListingByRailyRequestOrder.HUMAN_ID_ASC,
      sortKey: TaxiUnsettledOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder: TaxiUnsettledOrderListingByRailyRequestOrder.HUMAN_ID_DESC,
      sortKey: TaxiUnsettledOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder: TaxiUnsettledOrderListingByRailyRequestOrder.START_TIME_ASC,
      sortKey: TaxiUnsettledOrderListingSortKey.START_DATE_ASC,
    },
    {
      requestOrder:
        TaxiUnsettledOrderListingByRailyRequestOrder.START_TIME_DESC,
      sortKey: TaxiUnsettledOrderListingSortKey.START_DATE_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: TaxiUnsettledOrderListingFilter[],
  sortKey: TaxiUnsettledOrderListingSortKey | null
): TaxiUnsettledOrderListingByRailyRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    order: createRequestOrder(sortKey),
    address: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | TaxiUnsettledOrderListingRouteAddressFilter["value"]
      | undefined,
    limit: pageSize,
    client: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.CLIENT
    )?.value as TaxiUnsettledOrderListingClientFilter["value"] | undefined,
    dispatch: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.DISPATCH
    )?.value as TaxiUnsettledOrderListingDispatchFilter["value"] | undefined,
    driver: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.DRIVER
    )?.value as TaxiUnsettledOrderListingDriverFilter["value"] | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | TaxiUnsettledOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledOrderListingExternalOrderIdFilter["value"]
      | undefined,
    human_id: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledOrderListingInternalOrderIdFilter["value"]
      | undefined,
    is_cancelled: createRequestIsCancelled(filters),
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | TaxiUnsettledOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    offset,
    passenger: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.PASSENGER
    )?.value as TaxiUnsettledOrderListingPassengerFilter["value"] | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | TaxiUnsettledOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledOrderListingFilterType.START_DATE
      )?.value as TaxiUnsettledOrderListingStartDateFilter["value"] | undefined
    )?.from?.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledOrderListingFilterType.START_DATE
      )?.value as TaxiUnsettledOrderListingStartDateFilter["value"] | undefined
    )?.to?.toJSON(),
    taxi: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.TAXI
    )?.value as TaxiUnsettledOrderListingTaxiFilter["value"] | undefined,
  };
};

const taxiUnsettledOrderListingByRailyRequestFactory = {
  createRequest,
};

export default taxiUnsettledOrderListingByRailyRequestFactory;
