import { FC, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import DispatcherEditDispatchSelectOption from "./common/types/dispatcher-edit-dispatch-select-option";
import dispatcherEditHelper from "./common/dispatcher-edit.helper";
import UserDispatcherEditRouteParams from "../../common/routes/types/user-dispatcher-edit-route-params";
import notificationService from "../../../../common/utils/notification/notification.service";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import Row from "../../../../common/components/grid/row";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import Column from "../../../../common/components/grid/column";
import PhoneNumberInputComponent from "../../../../common/components/form/input/phone-number/phone-number-input.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import useForm from "../../../../common/components/form/use-form";
import dispatcherEditFormHelper from "./common/dispatcher-edit-form.helper";
import useDispatcherEditForm from "../../../../common/services/dispatcher/edit-form/use-dispatcher-edit-form";
import useAbort from "../../../../common/hooks/use-abort";
import DispatcherUpdateParams from "../../../../common/services/dispatcher/update/dispatcher-update-params";
import dispatcherEditFormDataFactory from "./common/dispatcher-edit-form-data.factory";
import DispatcherEditFormData from "./common/types/dispatcher-edit-form-data";
import dispatcherService from "../../../../common/services/dispatcher/dispatcher.service";
import dispatcherUpdateParamsFactory from "./common/dispatcher-update-params.factory";
import NoticeBoxComponent from "../../../../common/components/notice-box/notice-box.component";
import NoticeBoxType from "../../../../common/components/notice-box/notice-box-type";

type DispatcherEditProps = {};

const DispatcherEditComponent: FC<DispatcherEditProps> = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { dispatcherUuid } = useParams<UserDispatcherEditRouteParams>();

  const [isDispatcherUpdatePending, setIsDispatcherUpdatePending] =
    useState(false);

  const form = useForm({
    emptyValues: dispatcherEditFormHelper.getDefaultFormData(),
    validationDefinition: dispatcherEditFormHelper.getValidationDefinition(),
  });

  const editForm = useDispatcherEditForm();
  const editFormAbort = useAbort();

  const dispatcherUpdateAbort = useAbort();

  const dispatcherName: string = useMemo(() => {
    if (!editForm.data) {
      return "";
    }

    return `${editForm.data.firstName} ${editForm.data.lastName}`;
  }, [editForm.data]);

  useEffect(() => {
    if (!dispatcherUuid) {
      return;
    }

    editForm.load(
      {
        dispatcherUuid,
      },
      editFormAbort.signal
    );

    return () => editFormAbort.revoke();
  }, [dispatcherUuid]);

  useEffect(() => {
    if (!editForm.data) {
      return;
    }

    const formData: DispatcherEditFormData =
      dispatcherEditFormDataFactory.create(editForm.data);

    form.setValues(formData);
  }, [editForm.data]);

  const dispatchSelectOptions: DispatcherEditDispatchSelectOption[] =
    useMemo(() => {
      if (!editForm.data) {
        return [];
      }

      return editForm.data.availableDispatches.map((dispatch) => {
        const option: DispatcherEditDispatchSelectOption = {
          label: dispatch.displayName,
          value: {
            uuid: dispatch.uuid,
          },
          subText: `${dispatch.name} | ${dispatch.address}`,
        };

        return option;
      });
    }, [editForm.data?.availableDispatches]);

  const selectedDispatchSelectOption: DispatcherEditDispatchSelectOption | null =
    useMemo(() => {
      return (
        dispatchSelectOptions.find(
          (option) => option.value.uuid === form.values.dispatchUuid
        ) ?? null
      );
    }, [dispatchSelectOptions, form.values.dispatchUuid]);

  const translations = userTranslationsHelper.getDispatcherEditTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.userDispatcherEdit.replace(
      "#{dispatcherName}",
      dispatcherName
    )
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!dispatcherUuid) {
      return;
    }

    const breadcrumbs = userBreadcrumbsHelper.getDispatcherEditBreadcrumbs({
      dispatcherUuid,
      dispatcherName,
    });
    setBreadcrumbs(breadcrumbs);
  }, [dispatcherUuid, selectedAppLanguage, dispatcherName]);

  const navigateToListing = () => {
    const dispatcherListingRoute = userRoutesHelper.getDispatcherListingRoute();
    navigate(dispatcherListingRoute);
  };

  const onDispatcherUpdateSuccess = () => {
    notificationService.success(translations.successUpdateNotificationLabel);
    navigateToListing();
  };
  const onDispatcherUpdateFailure = () => {
    notificationService.error(translations.failureUpdateNotificationLabel);
  };

  const updateDispatcher = async () => {
    setIsDispatcherUpdatePending(true);

    const params: DispatcherUpdateParams = dispatcherUpdateParamsFactory.create(
      form.values,
      dispatcherUuid!
    );

    try {
      await dispatcherService.update(params, dispatcherUpdateAbort.signal);

      onDispatcherUpdateSuccess();
    } catch {
      onDispatcherUpdateFailure();
    } finally {
      setIsDispatcherUpdatePending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid || !dispatcherUuid) {
      return;
    }

    updateDispatcher();
  };

  if (editForm.isError) {
    return (
      <>
        <HeadingComponent
          title={dispatcherEditHelper.getHeadingLabel(dispatcherName)}
        />
        <NoticeBoxComponent type={NoticeBoxType.DANGER}>
          {translations.failureEditFormDataLoadNotificationLabel}
        </NoticeBoxComponent>
      </>
    );
  }

  return (
    <>
      <HeadingComponent
        title={dispatcherEditHelper.getHeadingLabel(dispatcherName)}
      />
      <CardComponent isLoading={editForm.isLoading}>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.emailLabel}
              isRequired
              errorMessage={form.validationResults.email.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.emailPlaceholder}
                value={form.values.email}
                onChange={(value) => form.setValue("email", value)}
                onBlur={() => form.validate("email")}
                hasError={!!form.validationResults.email.errorMessage}
                idForTesting={`email`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent label={translations.form.usernameLabel}>
              <InputComponent
                placeholder={translations.form.usernamePlaceholder}
                value={form.values.username}
                onChange={(value) => form.setValue("username", value)}
                idForTesting={`username`}
                isDisabled
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.firstNameLabel}
              isRequired
              errorMessage={form.validationResults.firstName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.firstNamePlaceholder}
                value={form.values.firstName}
                onChange={(value) => form.setValue("firstName", value)}
                onBlur={() => form.validate("firstName")}
                hasError={!!form.validationResults.firstName.errorMessage}
                idForTesting={`first-name`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.lastNameLabel}
              isRequired
              errorMessage={form.validationResults.lastName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.lastNamePlaceholder}
                value={form.values.lastName}
                onChange={(value) => form.setValue("lastName", value)}
                onBlur={() => form.validate("lastName")}
                hasError={!!form.validationResults.lastName.errorMessage}
                idForTesting={`last-name`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.phoneNumberLabel}
              isRequired
              errorMessage={form.validationResults.phoneNumber.errorMessage}
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.phoneNumberLabel}
                phoneNumber={form.values.phoneNumber}
                onChange={(value) => form.setValue("phoneNumber", value)}
                onBlur={() => form.validate("phoneNumber")}
                hasError={!!form.validationResults.phoneNumber.errorMessage}
                idForTesting={`phone-number`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.alternativePhoneNumberLabel}
              errorMessage={
                form.validationResults.alternativePhoneNumber.errorMessage
              }
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.alternativePhoneNumberLabel}
                phoneNumber={form.values.alternativePhoneNumber}
                onChange={(value) =>
                  form.setValue("alternativePhoneNumber", value)
                }
                onBlur={() => form.validate("alternativePhoneNumber")}
                hasError={
                  !!form.validationResults.alternativePhoneNumber.errorMessage
                }
                idForTesting={`alternative-phone`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.dispatchLabel}
              isRequired
              errorMessage={form.validationResults.dispatchUuid.errorMessage}
            >
              <SingleSelectComponent
                isLoading={isDispatcherUpdatePending}
                placeholder={translations.form.dispatchPlaceholder}
                onChange={(option) =>
                  form.setValue(
                    "dispatchUuid",
                    (option as DispatcherEditDispatchSelectOption | null)?.value
                      .uuid ?? ""
                  )
                }
                options={dispatchSelectOptions}
                value={selectedDispatchSelectOption}
                hasError={!!form.validationResults.dispatchUuid.errorMessage}
                idForTesting={`dispatches-select`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        title={translations.form.submitButtonTitle}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default DispatcherEditComponent;
