import UserRole from "../../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../../common/utils/user/permissions/user-permissions-definition";

export type CargoCompanyListingUserPermissionDefinitionOption =
  | "hasAccessToTaxiContractListing"
  | "hasAccessToAddressListing"
  | "hasAccessToDispatchListing";

export type CargoCompanyListingUserPermissionsDefinition =
  UserPermissionsDefinition<CargoCompanyListingUserPermissionDefinitionOption>;

export type CargoCompanyListingUserPermissions =
  UserPermissions<CargoCompanyListingUserPermissionDefinitionOption>;

const cargoCompanyListingUserPermissionDefinition: CargoCompanyListingUserPermissionsDefinition =
  {
    hasAccessToTaxiContractListing: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToAddressListing: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
    ],
    hasAccessToDispatchListing: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default cargoCompanyListingUserPermissionDefinition;
