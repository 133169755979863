import Joi from "joi";
import PhoneNumber from "../../common/types/phone-number";
import formValidationService from "../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../common/utils/validation/types/form-validation-result";
import userTranslationsHelper from "../../languages/user-translations.helper";
import userService from "../../common/services/user/user.service";

const validateUsername = async (
  username: string
): Promise<FormValidationResult> => {
  const abortController = new AbortController();

  const pattern = /^[A-Za-z0-9]+([-_.]?[A-Za-z0-9]+)*$/;

  const validationSchema = Joi.string()
    .min(3)
    .max(40)
    .regex(pattern)
    .required();

  const result = formValidationService.validate(username, validationSchema);

  if (!result.isValid) {
    return result;
  }

  try {
    const isUserExists = (
      await userService.checkUserExists({ username }, abortController.signal)
    ).isExists;

    if (isUserExists) {
      const errorMessage =
        userTranslationsHelper.getUserAddTranslations()
          .userExistsValidationMessage;

      return formValidationService.bad(errorMessage);
    }
  } catch {}

  return formValidationService.ok();
};

const validatePassword = (password: string): FormValidationResult => {
  const validationSchema = Joi.string().min(12).max(50).required();

  return formValidationService.validate(password, validationSchema);
};

const validatePasswordRepeat = (
  password: string,
  passwordRepeat: string
): FormValidationResult => {
  const validateAsPasswordResult = validatePassword(passwordRepeat);

  if (!validateAsPasswordResult.isValid) {
    return validateAsPasswordResult;
  }

  const passwordRepeatSchema = Joi.string().valid(password).required();

  const validationResult = formValidationService.validate(
    passwordRepeat,
    passwordRepeatSchema
  );

  if (validationResult.isValid) {
    return validationResult;
  }

  validationResult.errorMessage =
    formValidationService.getValidationCustomMessages().passwordRepeatValidationError;

  return validationResult;
};

const validateFirstName = (firstName: string): FormValidationResult => {
  const pattern = /^[^\s].*[^\s]$/;

  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .regex(pattern)
    .required();

  return formValidationService.validate(firstName, validationSchema);
};

const validateLastName = (lastName: string): FormValidationResult => {
  const pattern = /^[^\s].*[^\s]$/;

  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .regex(pattern)
    .required();

  return formValidationService.validate(lastName, validationSchema);
};

const mobileDialingCodeValidationSchema = Joi.string()
  .min(2)
  .max(4)
  .trim()
  .required();

const mobileNumberPattern = new RegExp(`^[0-9]*$`);
const mobileNumberValidationSchema = Joi.string()
  .min(9)
  .max(14)
  .regex(mobileNumberPattern)
  .required();

const requiredMobileValidationSchema = Joi.object({
  dialingCode: mobileDialingCodeValidationSchema,
  number: mobileNumberValidationSchema,
});

const validateMobile = (phoneNumber: PhoneNumber): FormValidationResult => {
  const validationSchema = requiredMobileValidationSchema;

  return formValidationService.validate(phoneNumber, validationSchema);
};

const validateAlternativeMobile = (
  alternativePhoneNumber: PhoneNumber | null
): FormValidationResult => {
  const emptyValidationSchema = Joi.object({
    dialingCode: Joi.string().equal(""),
    number: Joi.string().equal(""),
  });

  const validationSchema = Joi.alternatives().try(
    requiredMobileValidationSchema,
    emptyValidationSchema
  );

  return formValidationService.validate(
    alternativePhoneNumber,
    validationSchema
  );
};

const validateEmail = (email: string): FormValidationResult => {
  const validationSchema = Joi.string()
    .email({ tlds: { allow: false } })
    .required();

  return formValidationService.validate(email, validationSchema);
};

const userFormValidationService = {
  validateUsername,
  validatePassword,
  validatePasswordRepeat,
  validateFirstName,
  validateLastName,
  validateMobile,
  validateAlternativeMobile,
  validateEmail,
};

export default userFormValidationService;
