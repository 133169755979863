import { isEqual } from "lodash";
import { useEffect } from "react";
import appConfig from "../../app.config";
import userFactory from "../../common/utils/user/user.factory";
import { useAppContext } from "../../context/app.context";
import authService from "./common/auth.service";
import useAbort from "../../common/hooks/use-abort";
import auth from "../../common/services/auth/auth.service";

const useAuthSessionFlow = () => {
  const {
    isUserLoggedIn,
    setIsUserLoggedIn,
    setIsTokenRenewPending,
    user,
    setUser,
  } = useAppContext();

  const refreshTokenAbort = useAbort();

  const checkShouldRenewToken = (): boolean => {
    const accessTokenExpirationTime =
      authService.getAccessTokenExpirationTime();

    if (!accessTokenExpirationTime) {
      return true;
    }

    const currentTime = new Date().getTime();

    const shouldRenewToken =
      accessTokenExpirationTime <
      currentTime + appConfig.silentRenewBeforeExpirationTime;

    return shouldRenewToken;
  };

  const renewAccessToken = async (abortSignal: AbortSignal) => {
    setIsTokenRenewPending(true);

    try {
      const data = await auth.refreshToken(abortSignal);

      onRenewTokenSuccess(data.accessToken);
    } catch {
      setIsUserLoggedIn(false);
    } finally {
      setIsTokenRenewPending(false);
    }
  };

  const accessTokenChecker = () => {
    const shouldRenewToken = checkShouldRenewToken();

    if (shouldRenewToken) {
      renewAccessToken(refreshTokenAbort.signal);
      return;
    }

    setIsTokenRenewPending(false);
  };

  useEffect(() => {
    accessTokenChecker();

    if (!isUserLoggedIn) {
      return;
    }

    const checkAccessTokenExpirationInterval = setInterval(
      accessTokenChecker,
      appConfig.checkTokenExpirationIntervalTime
    );

    return () => {
      clearInterval(checkAccessTokenExpirationInterval);
    };
  }, [isUserLoggedIn]);

  const onRenewTokenSuccess = (accessToken: string) => {
    authService.setAccessCredentials(accessToken);
    setIsUserLoggedIn(true);

    const accessTokenDecodedContent =
      authService.getAccessTokenDecodedContent(accessToken);

    if (!accessTokenDecodedContent) {
      return;
    }

    const newUserData = userFactory.createUser(
      accessTokenDecodedContent.profile
    );

    if (isEqual(user, newUserData)) {
      return;
    }

    setUser(newUserData);
  };
};

export default useAuthSessionFlow;
