import appConfig from "../../../../../../app.config";
import urlService from "../../../../../../common/utils/url/url.service";
import authService from "../../../../../auth/common/auth.service";

import RelatedRailyTaxiBillingsResponse from "./related-raily-taxi-billings.response";

const fetchRailyTaxiRelatedBillings = (
  orderId: string
): Promise<RelatedRailyTaxiBillingsResponse> => {
  const path = `/billings/orders/${orderId}/related/raily-taxi`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const relatedBillingsApiService = {
  fetchRailyTaxiRelatedBillings,
};

export default relatedBillingsApiService;
