import { FC, useEffect, useMemo } from "react";
import MileageUpdateAddress from "../../types/mileage-update-address";
import { MileageUpdateAddressRouteItem } from "./mileage-update-address-routes.types";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";
import mileageUpdateAddressRoutesHelper from "./mileage-update-address-routes.helper";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import MileageUpdateAddressRouteItemComponent from "./mileage-update-address-route-item.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import mileageUpdateAddressFactory from "../../factory/mileage-update-address.factory";
import useCargoCompanyAddressList from "../../../../../../common/services/cargo-company/address/list/use-cargo-company-address-list";
import useAbort from "../../../../../../common/hooks/use-abort";
import CargoCompanyAddressListLoadParams from "../../../../../../common/services/cargo-company/address/list/cargo-company-address-list-load-params";

type MileageUpdateAddressRoutesProps = {
  cargoCompanyUuid: string | undefined;
  routes: MileageUpdateAddressRouteItem[];
  onRoutesChange: (addresses: MileageUpdateAddressRouteItem[]) => void;
  additionalAddresses: MileageUpdateAddress[];
  isAddButtonDisabled: boolean;
  onBlur?: () => void;
  idForTesting?: string;
};

const MileageUpdateAddressRoutesComponent: FC<
  MileageUpdateAddressRoutesProps
> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageUpdateTranslations().routes;

  const cargoCompanyAddressList = useCargoCompanyAddressList();
  const cargoCompanyAddressListAbort = useAbort();

  const loadCargoCompanyAddresses = (cargoCompanyUuid: string) => {
    const loadParams: CargoCompanyAddressListLoadParams = {
      companyUuid: cargoCompanyUuid,
    };

    cargoCompanyAddressList.load(
      loadParams,
      cargoCompanyAddressListAbort.signal
    );
  };

  useEffect(() => {
    if (!props.cargoCompanyUuid) {
      return;
    }

    loadCargoCompanyAddresses(props.cargoCompanyUuid);

    return cargoCompanyAddressListAbort.revoke;
  }, [props.cargoCompanyUuid]);

  const cargoCompanyAddresses = useMemo(
    () =>
      cargoCompanyAddressList.data.map(
        mileageUpdateAddressFactory.createFromCargoAddress
      ),
    [cargoCompanyAddressList.data]
  );

  const onAddRouteButtonClick = () => {
    const newEmptyRoute = mileageUpdateAddressRoutesHelper.getNewEmptyRoute();

    const newRoutes: MileageUpdateAddressRouteItem[] = [
      ...props.routes,
      newEmptyRoute,
    ];

    props.onRoutesChange(newRoutes);
  };

  const updateRouteItem = (updatedRouteItem: MileageUpdateAddressRouteItem) => {
    const routeToUpdate = props.routes.find(
      (route) => route.uuid === updatedRouteItem.uuid
    );

    if (!routeToUpdate) {
      return;
    }

    const newRoutes: MileageUpdateAddressRouteItem[] = [...props.routes];

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);
    newRoutes[indexOfRouteToUpdate] = updatedRouteItem;

    props.onRoutesChange(newRoutes);
  };

  const deleteRouteItem = (
    routeItemUuid: MileageUpdateAddressRouteItem["uuid"]
  ) => {
    const newRoutes: MileageUpdateAddressRouteItem[] = props.routes.filter(
      (route) => route.uuid !== routeItemUuid
    );

    props.onRoutesChange(newRoutes);
  };

  const getReorderedRouteItems = (
    startIndex: number,
    endIndex: number
  ): MileageUpdateAddressRouteItem[] => {
    const result = [...props.routes];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragRouteListItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedRoutes = getReorderedRouteItems(
      result.source.index,
      result.destination.index
    );

    props.onRoutesChange(reorderedRoutes);
  };

  const isDraggingEnabled = props.routes.length > 1;

  return (
    <>
      <DragDropContext onDragEnd={onDragRouteListItemEnd}>
        <Droppable droppableId="mileage_update_address_routes_list_drop_area">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div>
                {props.routes.map((route, routeIndex) => {
                  return (
                    <Draggable
                      key={route.uuid}
                      draggableId={route.uuid}
                      index={routeIndex}
                      isDragDisabled={!isDraggingEnabled}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <MileageUpdateAddressRouteItemComponent
                            routeIndex={routeIndex}
                            routeItem={route}
                            updateRouteItem={updateRouteItem}
                            deleteRouteItem={() => deleteRouteItem(route.uuid)}
                            additionalAddresses={props.additionalAddresses}
                            cargoCompanyAddresses={cargoCompanyAddresses}
                            dragHandleProps={provided.dragHandleProps}
                            onBlur={props.onBlur}
                            idForTesting={props.idForTesting}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ButtonComponent
        onClick={onAddRouteButtonClick}
        type="primary"
        title={translations.addNewButtonTitle}
        isDisabled={props.isAddButtonDisabled}
        idForTesting={"mileage-update-address-routes-new-button"}
      >
        {translations.addNewButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default MileageUpdateAddressRoutesComponent;
