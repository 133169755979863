import { FC } from "react";
import CardEditButtonComponent from "../../../../../../common/components/card/button/add/card-edit-button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../../common/context/sign-up-driver.context";
import SignUpDriverFormStep from "../../common/types/sign-up-driver-form-step";
import signUpDriverSummaryHelper from "../sign-up-driver-summary.helper";

type SignUpDriverSummaryCompanyProps = {};

const SignUpDriverSummaryCompanyComponent: FC<
  SignUpDriverSummaryCompanyProps
> = () => {
  const companyDataSummaryDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().summary
      .companyData;

  const { companyData, setCurrentFormStep } = useSignUpDriverContext();

  const onEditButtonClick = () => {
    setCurrentFormStep(SignUpDriverFormStep.companyData);
  };

  return (
    <CardComponent
      header={{
        title: companyDataSummaryDataFormTranslations.title,
        actions: [
          <CardEditButtonComponent
            onClick={onEditButtonClick}
            title={companyDataSummaryDataFormTranslations.editButtonText}
          />,
        ],
      }}
    >
      <Row>
        <Column md={4} lg={3}>
          <FormFieldComponent
            label={companyDataSummaryDataFormTranslations.nameLabel}
            classNames={{ root: "mt-0" }}
          >
            {companyData.name}
          </FormFieldComponent>
        </Column>
        <Column md={4} lg={3}>
          <FormFieldComponent
            label={companyDataSummaryDataFormTranslations.companyIdLabel}
            classNames={{ root: "mt-0" }}
          >
            {companyData.companyId}
          </FormFieldComponent>
        </Column>
        <Column md={4} lg={3}>
          <FormFieldComponent
            label={companyDataSummaryDataFormTranslations.taxNumberLabel}
            classNames={{ root: "mt-0" }}
          >
            {companyData.taxNumber}
          </FormFieldComponent>
        </Column>
        <Column md={4} lg={3}>
          <FormFieldComponent
            label={
              companyDataSummaryDataFormTranslations.nationalCourtRegisterNumberLabel
            }
            classNames={{ root: "mt-0" }}
          >
            {companyData.nationalCourtRegisterNumber}
          </FormFieldComponent>
        </Column>
        <Column>
          <FormFieldComponent
            label={companyDataSummaryDataFormTranslations.addressLabel}
            classNames={{ root: "mt-0" }}
          >
            {signUpDriverSummaryHelper.getAddressString(
              companyData.address
            )}
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default SignUpDriverSummaryCompanyComponent;
