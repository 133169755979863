import UserStatus from "../../../types/user-status";

export enum CargoOfficerListLoadParamsOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

type CargoOfficerListLoadParams = {
  cargoCompany?: string;
  cargoCompanyId?: string;
  order?: CargoOfficerListLoadParamsOrder;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  status?: UserStatus;
  creationStart?: Date;
  creationEnd?: Date;
  page: number;
  pageSize: number;
};

export default CargoOfficerListLoadParams;
