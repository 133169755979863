import BillingsOrderTaxiTaxiRequest from "../../../../api/raily/billings/order/taxi-taxi/billings-order-taxi-taxi.request";
import BillingsOrderTaxiTaxiLoadParams from "./billings-order-taxi-taxi-load-params";

const create = (
  params: BillingsOrderTaxiTaxiLoadParams
): BillingsOrderTaxiTaxiRequest => {
  return {
    orderId: params.orderUuid,
  };
};

const billingsOrderTaxiTaxiRequestFactory = {
  create,
};

export default billingsOrderTaxiTaxiRequestFactory;
