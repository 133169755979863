import appConfig from "../../../../../../../../../app.config";
import authService from "../../../../../../../../auth/common/auth.service";
import DriverDetailsPlanDeleteCheckoutResponse from "./driver-details-plan-delete-checkout.response";

const deleteCheckout = (
  driverUuid: string,
  planEntryUuid: string,
  checkoutEventUuid: string
): Promise<DriverDetailsPlanDeleteCheckoutResponse> => {
  const path = `/drivers/${driverUuid}/plan-entries/${planEntryUuid}/checkout-events/${checkoutEventUuid}`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const driverDetailsPlanDeleteCheckoutApiService = {
  deleteCheckout,
};

export default driverDetailsPlanDeleteCheckoutApiService;
