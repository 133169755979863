import taxiUnsettledRouteListingRouteQueryParamsService from "../../common/taxi-unsettled-route-listing-route-query-params.service";

const taxiUnsettledRouteListingByTaxiRouteQueryParamsService = {
  getFilters: taxiUnsettledRouteListingRouteQueryParamsService.getFilters,
  getSortKey: taxiUnsettledRouteListingRouteQueryParamsService.getSortKey,
  getPage: taxiUnsettledRouteListingRouteQueryParamsService.getPage,
  getPageSize: taxiUnsettledRouteListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams:
    taxiUnsettledRouteListingRouteQueryParamsService.createRouteQueryParams,
};

export default taxiUnsettledRouteListingByTaxiRouteQueryParamsService;
