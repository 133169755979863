import isEqual from "lodash/isEqual";
import DriverContractListingByRailyFilterSelectOption, {
  DriverContractListingByRailyBillingModelFilterSelectOption,
} from "../types/driver-contract-listing-by-raily-filter-select-option";
import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import DriverContractListingByRailyFilter from "../types/driver-contract-listing-by-raily-filter";
import DriverContractListingByRailyFilterType from "../types/driver-contract-listing-by-raily-filter-type";
import DriverContractListingBillingModel from "../../../common/types/driver-contract-listing-billing-model";

const getSearchBillingModelOptions = (
  query: string
): DriverContractListingByRailyBillingModelFilterSelectOption[] => {
  const searchFilterTranslations =
    userTranslationsHelper.getDriverContractListingTranslations().filters
      .search;

  const allBillingModelSelectOptions: DriverContractListingByRailyBillingModelFilterSelectOption[] =
    Object.values(DriverContractListingBillingModel).map((billingModel) => {
      const billingModelLabel =
        searchFilterTranslations.searchByBillingModelTemplateText.replace(
          "#{option}",
          billingModel
        );

      return {
        label: billingModelLabel,
        value: {
          type: DriverContractListingByRailyFilterType.BILLING_MODEL,
          value: billingModel,
        },
      };
    });

  if (query) {
    return allBillingModelSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allBillingModelSelectOptions;
};

const getSearchQueryOptions = (
  query: string
): DriverContractListingByRailyFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const translations =
    userTranslationsHelper.getDriverContractListingTranslations().filters
      .search;

  const getSearchByTaxiCorporationQueryLabel = (query: string) => {
    return translations.searchByTaxiCorporationTemplateText.replace(
      `#{query}`,
      query
    );
  };

  return [
    {
      label: getSearchByTaxiCorporationQueryLabel(query),
      value: {
        type: DriverContractListingByRailyFilterType.TAXI_CORPORATION,
        value: query,
      },
    },
  ];
};

const getFilterOptions = (
  query: string
): DriverContractListingByRailyFilterSelectOption[] => {
  const billingModelOptions = getSearchBillingModelOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...billingModelOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: DriverContractListingByRailyFilter[]
): DriverContractListingByRailyFilterSelectOption[] => {
  const allFilterOptions = getFilterOptions(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const driverContractListingByRailyFilterHelper = {
  getSelectOptions,
};

export default driverContractListingByRailyFilterHelper;
