import classNames from "classnames";
import { FC } from "react";
import BreadcrumbsComponent from "../../common/components/breadcrumbs/breadcrumbs.component";
import notificationService from "../../common/utils/notification/notification.service";
import { useAppContext } from "../../context/app.context";
import appTranslationsHelper from "../../languages/app-translations.helper";
import AppHeaderLanguageComponent from "./language/app-header-language.component";
import AppHeaderUserComponent from "./user/app-header-user.component";
import useAbort from "../../common/hooks/use-abort";
import auth from "../../common/services/auth/auth.service";
import authService from "../auth/common/auth.service";

type AppHeaderProps = {};

const AppHeaderComponent: FC<AppHeaderProps> = () => {
  const {
    breadcrumbs,
    areBreadcrumbsLoading,
    isAsidePanelPinned,
    setIsUserLoggedIn,
  } = useAppContext();

  const translations = appTranslationsHelper.getAuthTranslations();

  const logoutAbort = useAbort();

  const onLogoutSuccess = () => {
    authService.removeAccessToken();
    authService.removeAccessTokenExpirationTime();

    setIsUserLoggedIn(false);

    notificationService.success(translations.logout.successNotificationLabel);
  };

  const onLogoutFailure = () => {
    notificationService.error(translations.logout.failureNotificationLabel);
  };

  const logout = () => {
    auth
      .logout(logoutAbort.signal)
      .then(onLogoutSuccess)
      .catch(onLogoutFailure);
  };

  return (
    <div className="app_header">
      <div
        className={classNames(
          "app_header_content",
          isAsidePanelPinned && "app_aside_expanded"
        )}
      >
        <div>
          <BreadcrumbsComponent
            breadcrumbs={breadcrumbs}
            isLoading={areBreadcrumbsLoading}
          />
        </div>
        <div className="app_header_content">
          <div className="app_header_language">
            <AppHeaderLanguageComponent />
          </div>
          <div className="app_header_user_data">
            <AppHeaderUserComponent onLogout={logout} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeaderComponent;
