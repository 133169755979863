import DelegationAddVehicleType from "../types/delegation-add-vehicle-type";
import DelegationAddVehicleTypeSelectOption from "../types/delegation-add-vehicle-type-select-option";
import delegationAddHelper from "./delegation-add-helper";

const createSelectOption = (
  type: DelegationAddVehicleType
): DelegationAddVehicleTypeSelectOption => {
  return {
    label: delegationAddHelper.getVehicleTypeLabel(type),
    value: type,
    idForTesting: `delegation-add-vehicle-type-${String(type)}-select-option`,
  };
};

const createSelectOptions = (): DelegationAddVehicleTypeSelectOption[] => {
  return Object.keys(DelegationAddVehicleType).map((value) =>
    createSelectOption(value as DelegationAddVehicleType)
  );
};

const delegationAddVehicleTypeSelectOptionFactory = {
  createSelectOptions,
  createSelectOption,
};

export default delegationAddVehicleTypeSelectOptionFactory;
