import listingFilterService from "../../../../../../../../common/utils/listing/filters/listing-filter.service";
import DriverContractListingBillingModel from "../../../common/types/driver-contract-listing-billing-model";
import DriverContractListingByRailyFilter, {
  DriverContractListingByRailyBillingModelFilter,
} from "../types/driver-contract-listing-by-raily-filter";
import DriverContractListingByRailyFilterType from "../types/driver-contract-listing-by-raily-filter-type";
import DriverContractListingByRailyListingItem from "../types/driver-contract-listing-by-raily-listing-item";

const filterListingItemsByBillingModel = (
  listingItems: DriverContractListingByRailyListingItem[],
  options: DriverContractListingBillingModel[]
) => {
  return listingFilterService.filterByEnumProperty(
    listingItems,
    "billingModel",
    options
  );
};

const filterListingItemsByTaxiCorporation = (
  listingItems: DriverContractListingByRailyListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByStringProperty(
    listingItems,
    "taxiCorporation",
    queries
  );
};

const filterListingItems = (
  listingItems: DriverContractListingByRailyListingItem[],
  filters: DriverContractListingByRailyFilter[]
): DriverContractListingByRailyListingItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: DriverContractListingByRailyListingItem[] = [
    ...listingItems,
  ];

  const billingModelFilterQueries =
    listingFilterService.getValuesOfGivenFilterType(
      filters,
      DriverContractListingByRailyFilterType.BILLING_MODEL
    ) as DriverContractListingByRailyBillingModelFilter["value"][];

  if (billingModelFilterQueries.length) {
    filteredListingItems = filterListingItemsByBillingModel(
      filteredListingItems,
      billingModelFilterQueries
    );
  }

  const taxiCorporationFilterQueries =
    listingFilterService.getValuesOfGivenFilterType(
      filters,
      DriverContractListingByRailyFilterType.TAXI_CORPORATION
    );

  if (taxiCorporationFilterQueries.length) {
    filteredListingItems = filterListingItemsByTaxiCorporation(
      filteredListingItems,
      taxiCorporationFilterQueries
    );
  }

  return filteredListingItems;
};

const driverContractListingByRailyFilterService = {
  filterListingItems,
};

export default driverContractListingByRailyFilterService;
