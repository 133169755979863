import Joi from "joi";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import MileageAddAddress from "../types/mileage-add-address";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import MileageAddVehicleType from "../types/mileage-add-vehicle-type";
import MileageAddFormSettings, {
  MileageAddFormSettingsMileageNumberCreationModel,
} from "./types/mileage-add-form-settings";

const validateWorkerUuid = (value: string | null) => {
  const validationSchema = Joi.string()
    .pattern(
      /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
    )
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateCompanyUuid = (value: string | null) => {
  const validationSchema = Joi.string()
    .pattern(
      /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
    )
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateMileageDate = (value: Date | null) => {
  const validationSchema = Joi.date().required();

  return formValidationService.validate(value, validationSchema);
};

const validateCardNumber = (
  value: string | undefined,
  settings: MileageAddFormSettings
) => {
  const validationSchemaCommon = Joi.string().trim().min(1).max(40);
  let validationSchema;

  if (settings.isCardNumberRequired) {
    validationSchema = validationSchemaCommon.required();
  } else {
    validationSchema = validationSchemaCommon.allow("");
  }

  return formValidationService.validate(value, validationSchema);
};

const validateCommissionNumber = (value: string) => {
  const validationSchema = Joi.string().trim().allow("").min(1).max(40);

  return formValidationService.validate(value, validationSchema);
};

const validateMileageNumber = (
  value: string | undefined,
  settings: MileageAddFormSettings
) => {
  const validationSchemaCommon = Joi.string().trim().min(1).max(40);
  let validationSchema;

  if (
    settings.isMileageNumberRequired &&
    settings.mileageNumberCreationModel !==
      MileageAddFormSettingsMileageNumberCreationModel.AUTO
  ) {
    validationSchema = validationSchemaCommon.required();
  } else {
    validationSchema = validationSchemaCommon.allow("");
  }

  return formValidationService.validate(value, validationSchema);
};

const validateNotes = (value: string) => {
  const validationSchema = Joi.string().trim().allow("").max(255);

  return formValidationService.validate(value, validationSchema);
};

const validateVehicleType = (value: MileageAddVehicleType) => {
  const validationSchema = Joi.string()
    .valid(...Object.values(MileageAddVehicleType))
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistance = (value: number) => {
  const validationSchema = Joi.number()
    .min(0)
    .max(9999999999)
    .integer()
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateOSRMDistance = (value: number | null) => {
  const validationSchema = Joi.number()
    .min(0)
    .max(9999999999)
    .integer()
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateAddressSequence = (
  value: MileageAddAddress[]
): FormValidationResult => {
  const validationSchema = Joi.array().min(2).required();

  return formValidationService.validate(value, validationSchema);
};

const mileageAddFormValidationService = {
  validateAddressSequence,
  validateCardNumber,
  validateCommissionNumber,
  validateCompanyUuid,
  validateDistance,
  validateMileageDate,
  validateMileageNumber,
  validateNotes,
  validateVehicleType,
  validateWorkerUuid,
  validateOSRMDistance,
};

export default mileageAddFormValidationService;
