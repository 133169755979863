import durationService from "../../../../../common/utils/duration/duration.service";
import planningTranslationsHelper from "../../../../../languages/planning-translations.helper";

const getDateLabel = (date: Date | null): string => {
  if (!date) return `----`;

  return date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getDateTitle = (date: Date | null): string => {
  if (!date) return ``;

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

const getHaltingTimeLabel = (haltingTime: number): string => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().details.routes;

  const formattedHaltingTime = durationService.format(haltingTime);

  return translations.haltingDateTemplateLabel.replace(
    "#{duration}",
    formattedHaltingTime
  );
};

const getDistanceLabel = (distance: number | null): string => {
  if (distance === null) return `----`;

  const distanceAsKm = distance / 1000;
  return `${distanceAsKm.toFixed(1)} KM`;
};

const planningOrderOrderDetailsRouteHelper = {
  getDateLabel,
  getDateTitle,
  getDistanceLabel,
  getHaltingTimeLabel,
};

export default planningOrderOrderDetailsRouteHelper;
