import UserRole from "../../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../../common/utils/user/permissions/user-permissions.service";
import mileageDictionaryListingUserPermissionsDefinition, {
  MileageDictionaryListingUserPermissions,
} from "./mileage-dictionary-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): MileageDictionaryListingUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    mileageDictionaryListingUserPermissionsDefinition
  );

  return permissions;
};

const mileeageDictionaryListingUserPermissionsHelper = {
  getPermissions,
};

export default mileeageDictionaryListingUserPermissionsHelper;
