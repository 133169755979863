import railyApiService from "../raily-api.service";
import BillingsOrderForwardCargoTaxiRequest from "./order-forward/cargo-taxi/billings-order-forward-cargo-taxi.request";
import BillingsOrderForwardCargoTaxiResponse from "./order-forward/cargo-taxi/billings-order-forward-cargo-taxi.response";
import BillingsOrderForwardTaxiDriverRequest from "./order-forward/taxi-driver/billings-order-forward-taxi-driver.request";
import BillingsOrderForwardTaxiDriverResponse from "./order-forward/taxi-driver/billings-order-forward-taxi-driver.response";
import BillingsOrderForwardTaxiTaxiRequest from "./order-forward/taxi-taxi/billings-order-forward-taxi-taxi.request";
import BillingsOrderForwardTaxiTaxiResponse from "./order-forward/taxi-taxi/billings-order-forward-taxi-taxi.response";
import billingsOrderAddressesRequestFactory from "./order/addresses/billings-order-addresses-request.factory";
import BillingsOrderAddressesRequest, {
  BillingsOrderAddressesRequestQueryParams,
} from "./order/addresses/billings-order-addresses.request";
import BillingsOrderAddressesResponse from "./order/addresses/billings-order-addresses.response";
import BillingsOrderTaxiDriverRequest from "./order/taxi-driver/billings-order-taxi-driver.request";
import BillingsOrderTaxiDriverResponse from "./order/taxi-driver/billings-order-taxi-driver.response";
import BillingsOrderTaxiTaxiRequest from "./order/taxi-taxi/billings-order-taxi-taxi.request";
import BillingsOrderTaxiTaxiResponse from "./order/taxi-taxi/billings-order-taxi-taxi.response";
import { TransferredOrderStatusCountResponse } from "./order/transferred-order-status-count/transferred-order-status-count.response";
import BillingsPlanEntryCargoTaxiRequest from "./plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi.request";
import BillingsPlanEntryCargoTaxiResponse from "./plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi.response";
import BillingsPlanEntryTaxiTaxiRequest from "./plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi.request";
import BillingsPlanEntryTaxiTaxiResponse from "./plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi.response";

const billingsApiService = () => {
  const getOrderForwardCargoTaxi = async (
    request: BillingsOrderForwardCargoTaxiRequest,
    abortSignal: AbortSignal
  ): Promise<BillingsOrderForwardCargoTaxiResponse> => {
    const url = `/billings/orders-forwarding/${request.forwardingId}/related/cargo-taxi`;

    return railyApiService().get<BillingsOrderForwardCargoTaxiResponse>({
      url,
      abortSignal,
    });
  };

  const getOrderForwardTaxiDriver = async (
    request: BillingsOrderForwardTaxiDriverRequest,
    abortSignal: AbortSignal
  ): Promise<BillingsOrderForwardTaxiDriverResponse> => {
    const url = `/billings/orders-forwarding/${request.forwardingId}/related/taxi-driver`;

    return railyApiService().get<BillingsOrderForwardTaxiDriverResponse>({
      url,
      abortSignal,
    });
  };

  const getOrderForwardTaxiTaxi = async (
    request: BillingsOrderForwardTaxiTaxiRequest,
    abortSignal: AbortSignal
  ): Promise<BillingsOrderForwardTaxiTaxiResponse> => {
    const url = `/billings/orders-forwarding/${request.forwardingId}/related/taxi-taxi`;

    return railyApiService().get<BillingsOrderForwardTaxiTaxiResponse>({
      url,
      abortSignal,
    });
  };

  const getPlanEntryCargoTaxi = async (
    request: BillingsPlanEntryCargoTaxiRequest,
    abortSignal: AbortSignal
  ): Promise<BillingsPlanEntryCargoTaxiResponse> => {
    const url = `/billings/plan-entry/${request.planEntryId}/related/cargo-taxi`;

    return railyApiService().get<BillingsPlanEntryCargoTaxiResponse>({
      url,
      abortSignal,
    });
  };

  const getPlanEntryTaxiTaxi = async (
    request: BillingsPlanEntryTaxiTaxiRequest,
    abortSignal: AbortSignal
  ): Promise<BillingsPlanEntryTaxiTaxiResponse> => {
    const url = `/billings/plan-entry/${request.planEntryId}/related/taxi-taxi`;

    return railyApiService().get<BillingsPlanEntryTaxiTaxiResponse>({
      url,
      abortSignal,
    });
  };

  const getOrderTaxiDriver = async (
    request: BillingsOrderTaxiDriverRequest,
    abortSignal: AbortSignal
  ): Promise<BillingsOrderTaxiDriverResponse> => {
    const url = `/billings/orders/${request.orderId}/related/taxi-driver`;

    return railyApiService().get<BillingsOrderTaxiDriverResponse>({
      url,
      abortSignal,
    });
  };

  const getOrderTaxiTaxi = async (
    request: BillingsOrderTaxiTaxiRequest,
    abortSignal: AbortSignal
  ): Promise<BillingsOrderTaxiTaxiResponse> => {
    const url = `/billings/orders/${request.orderId}/related/taxi-taxi`;

    return railyApiService().get<BillingsOrderTaxiTaxiResponse>({
      url,
      abortSignal,
    });
  };

  const getTransferredOrderStatusCount = async (
    abortSignal: AbortSignal
  ): Promise<TransferredOrderStatusCountResponse> => {
    const url = `/meta/billing-taxi-taxi-status-counters`;

    return railyApiService().get<TransferredOrderStatusCountResponse>({
      url,
      abortSignal,
    });
  };

  const getOrderAddresses = async (
    request: BillingsOrderAddressesRequest,
    abortSignal: AbortSignal
  ): Promise<BillingsOrderAddressesResponse> => {
    const url = `/billings/orders/${request.orderId}/addresses`;

    const queryParams: BillingsOrderAddressesRequestQueryParams =
      billingsOrderAddressesRequestFactory.createQueryParams(request);

    return railyApiService().get<BillingsOrderAddressesResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    getOrderForwardCargoTaxi,
    getOrderForwardTaxiDriver,
    getOrderForwardTaxiTaxi,
    getPlanEntryCargoTaxi,
    getPlanEntryTaxiTaxi,
    getOrderTaxiDriver,
    getOrderTaxiTaxi,
    getTransferredOrderStatusCount,
    getOrderAddresses,
  };
};

export default billingsApiService;
