import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiFleetPartnerListingUserPermissionDefinitionOption =
  | "hasAccessToAddTaxiFleetPartner"
  | "hasAccessToEditTaxiFleetPartner";

export type TaxiFleetPartnerListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiFleetPartnerListingUserPermissionDefinitionOption>;

export type TaxiFleetPartnerListingUserPermissions =
  UserPermissions<TaxiFleetPartnerListingUserPermissionDefinitionOption>;

const taxiFleetPartnerListingUserPermissionDefinition: TaxiFleetPartnerListingUserPermissionsDefinition =
  {
    hasAccessToAddTaxiFleetPartner: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
    hasAccessToEditTaxiFleetPartner: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  };

export default taxiFleetPartnerListingUserPermissionDefinition;
