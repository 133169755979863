import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryExternalIdUpdateEvent } from "../../../common/types/order-details-history-entry-event";
import orderDetailsHistoryEntryExternalIdUpdateContentHelper from "./order-details-history-entry-external-id-update-content.helper";

type OrderDetailsHistoryEntryExternalIdUpdateContentProps = {
  event: OrderDetailsHistoryEntryExternalIdUpdateEvent;
};

const OrderDetailsHistoryEntryExternalIdUpdateContentComponent: FC<
  OrderDetailsHistoryEntryExternalIdUpdateContentProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .externalIdUpdateEntry;

  const newCargoExternalOrderIdLabel =
    orderDetailsHistoryEntryExternalIdUpdateContentHelper.getCargoExternalOrderIdLabel(
      props.event.newCargoExternalOrderId
    );

  const newCargoExternalOrderIdTitle =
    orderDetailsHistoryEntryExternalIdUpdateContentHelper.getCargoExternalOrderIdTitle(
      props.event.newCargoExternalOrderId
    );

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingText}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={newCargoExternalOrderIdTitle}
      >
        {newCargoExternalOrderIdLabel}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryExternalIdUpdateContentComponent;
