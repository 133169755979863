import { FC, ReactNode, useEffect, useRef, useState, useMemo } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import temporaryMarkerIcon from "../../../assets/icons/temporary-marker-icon.png";
import {
  Icon,
  LatLngBounds,
  LatLngTuple,
  Map,
  Point,
  LeafletMouseEvent,
  LatLngExpression,
} from "leaflet";
import MapCoordinate from "./types/map-coordinate";
import MapMarker from "./types/map-marker";
import mapHelper from "./map.helper";
import MapMarkerComponent from "./marker/map-marker.component";
import useIsComponentMounted from "../../hooks/use-is-component-mounted";
import MapRoute from "./types/map-route";

type MapProps = {
  onMapLongClick?: (coordinate: MapCoordinate) => void;
  markers?: MapMarker[];
  onMarkerCoordinateChange?: (updatedMarker: MapMarker) => void;
  temporaryMarkers?: MapMarker[];
  onTemporaryMarkerCoordinateChange?: (
    updatedTemporaryMarker: MapMarker
  ) => void;
  centerLocation?: MapCoordinate;
  zoom?: number;
  autoFit?: boolean;
  autoFitOnUpdate?: boolean;
  isMessageBoxVisible?: boolean;
  messageBox?: ReactNode;
  refreshFitFlag?: boolean;
  routes?: MapRoute[];
};

const MapComponent: FC<MapProps> = (props) => {
  const mapRef = useRef<Map>(null);
  const mapConfig = mapHelper.getDefaultConfig();
  const isComponentMounted = useIsComponentMounted();
  const [isMapReady, setIsMapReady] = useState(false);

  const refreshZoom = () => {
    const markerLatLngTuples: LatLngTuple[] =
      props.markers?.map((marker) => [
        marker.coordinate.latitude,
        marker.coordinate.longitude,
      ]) ?? [];

    const bounds = new LatLngBounds(markerLatLngTuples);
    mapRef.current?.fitBounds(bounds, { padding: new Point(25, 25) });
  };

  const checkElementIsMapContainer = (
    element: HTMLDivElement | null
  ): boolean => {
    return element?.className?.includes(`leaflet-container`) ?? false;
  };

  useEffect(() => {
    if (
      !mapRef.current ||
      !isMapReady ||
      !props.autoFit ||
      !props.markers?.length
    ) {
      return;
    }

    refreshZoom();
  }, [mapRef.current, isMapReady]);

  useEffect(() => {
    if (!isComponentMounted || !isMapReady) return;

    if (props.markers?.length) {
      refreshZoom();
    } else {
      const defaultLatLongTuple: LatLngTuple = [
        mapConfig.defaultCenterLocation.lat,
        mapConfig.defaultCenterLocation.lng,
      ];

      const bounds = new LatLngBounds([defaultLatLongTuple]);
      mapRef.current
        ?.fitBounds(bounds, { padding: new Point(25, 25) })
        .setZoom(mapConfig.defaultZoom);
    }
  }, [props.refreshFitFlag]);

  useEffect(() => {
    if (!mapRef.current || !props.onMapLongClick || !isMapReady) {
      return;
    }

    const longClickTime = 500;
    let longClickTimeout: NodeJS.Timeout | null = null;
    let isLongClickActive = false;

    const handleLongClickEvent = (event: LeafletMouseEvent) => {
      const coordinate: MapCoordinate = {
        latitude: event.latlng.lat,
        longitude: event.latlng.lng,
      };

      props.onMapLongClick!(coordinate);
    };

    const handleMouseDown = (event: LeafletMouseEvent) => {
      if (
        !checkElementIsMapContainer(
          event.originalEvent.currentTarget as HTMLDivElement
        )
      ) {
        return;
      }

      longClickTimeout = setTimeout(() => {
        isLongClickActive = true;
      }, longClickTime);
    };

    const handleMoveStart = () => {
      clearInterval(longClickTimeout!);
      isLongClickActive = false;
    };

    const handleMouseUp = (event: LeafletMouseEvent) => {
      if (isLongClickActive) {
        handleLongClickEvent(event);

        isLongClickActive = false;
      }
      clearInterval(longClickTimeout!);
      isLongClickActive = false;
    };

    mapRef.current?.on("mousedown", handleMouseDown);
    mapRef.current?.on("mouseup", handleMouseUp);
    mapRef.current?.on("movestart", handleMoveStart);

    return () => {
      mapRef.current?.off("mousedown", handleMouseDown);
      mapRef.current?.off("mouseup", handleMouseUp);
      mapRef.current?.off("movestart", handleMoveStart);
    };
  }, [mapRef.current, props.markers, isMapReady]);

  useEffect(() => {
    if (props.autoFitOnUpdate && mapRef.current && props.markers?.length) {
      refreshZoom();
    }
  }, [props.autoFitOnUpdate, mapRef.current, props.markers]);

  const mapMarkersWithoutDuplicates = useMemo(
    () => mapHelper.getMarkersWithoutDuplicates(props.markers),
    [props.markers]
  );

  const zoom = props.zoom ?? mapConfig.defaultZoom;

  const center: LatLngExpression = props.centerLocation
    ? {
        lat: props.centerLocation.latitude,
        lng: props.centerLocation.longitude,
      }
    : mapConfig.defaultCenterLocation;

  return (
    <div className="map">
      <MapContainer
        className="map_container"
        center={center}
        zoom={zoom}
        ref={mapRef}
        scrollWheelZoom={true}
        whenReady={() => setIsMapReady(true)}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {props.isMessageBoxVisible && (
          <div className="map__message_box">{props.messageBox}</div>
        )}
        {props.routes?.map((route, index) => {
          if (!route.waypoints.length) {
            return null;
          }
          const firstWaypoint = route.waypoints[0];
          const lastWaypoint = route.waypoints[route.waypoints.length - 1];
          return (
            <Polyline
              key={`mapPolyLine-${index}-from-${firstWaypoint.latitude}${firstWaypoint.longitude}-to-${lastWaypoint.latitude}${lastWaypoint.longitude}`}
              pathOptions={{
                color: route.options?.color ?? mapConfig.defaultRouteColor,
              }}
              positions={route.waypoints.map((waypoint) => ({
                lat: waypoint.latitude,
                lng: waypoint.longitude,
              }))}
            />
          );
        })}

        {mapMarkersWithoutDuplicates.map((marker) => {
          const markerKey = `mapMarker-${marker.coordinate.latitude};${marker.coordinate.longitude}`;

          return (
            <MapMarkerComponent
              key={markerKey}
              coordinate={marker.coordinate}
              icon={marker.icon}
              title={marker.title}
              tooltip={marker.tooltip}
              isDraggable={marker.isDraggable}
              onCoordinateChange={(coordinate) => {
                props.onMarkerCoordinateChange &&
                  props.onMarkerCoordinateChange({ ...marker, coordinate });
              }}
            />
          );
        })}
        {props.temporaryMarkers?.map((marker, index) => {
          const icon = new Icon({
            iconUrl: temporaryMarkerIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          });

          return (
            <MapMarkerComponent
              key={`temporaryMapMarker-${index}`}
              coordinate={marker.coordinate}
              icon={icon}
              title={marker.title}
              tooltip={marker.tooltip}
              isDraggable={marker.isDraggable}
              onCoordinateChange={(coordinate) => {
                props.onTemporaryMarkerCoordinateChange &&
                  props.onTemporaryMarkerCoordinateChange({
                    ...marker,
                    coordinate,
                  });
              }}
            />
          );
        })}
      </MapContainer>
    </div>
  );
};

MapComponent.defaultProps = {};

export default MapComponent;
