import { FC, useEffect, useRef } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import formValidationHelper from "../../../../../common/utils/validation/form-validation.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import { useSignUpPassengerContext } from "../common/context/sign-up-passenger.context";
import SignUpPassengerUserAddressComponent from "./address/sign-up-passenger-user-address.component";

const SignUpPassengerUserComponent: FC = () => {
  const isComponentMounted = useIsComponentMounted();

  const translations =
    appTranslationsHelper.getPassengerSignUpTranslations().userDataForm;

  const {
    userData,
    setUserDataFirstName,
    setUserDataLastName,
    setUserDataMobile,
    setUserDataAlternativeMobile,
    userDataValidationResults,
    validateUserDataFirstName,
    validateUserDataLastName,
    validateUserDataMobile,
    validateUserDataAlternativeMobile,
    validateUserDataAddresses,
    submitUserStep,
    userDataDisabledFormFields,
  } = useSignUpPassengerContext();

  const firstNameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstNameInputRef.current && !userData.firstName) {
      firstNameInputRef.current.focus();
    }
  }, [firstNameInputRef.current]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }
    validateUserDataAddresses();
  }, [userData.addresses]);

  const isSubmitButtonEnabled = formValidationHelper.checkThatFormHasErrors(
    userDataValidationResults
  );

  return (
    <>
      <h1 className="sign_up_passenger__step_title">{translations.title}</h1>
      <CardComponent>
        <Row>
          <Column md={6}>
            <FormFieldComponent
              label={translations.firstNameLabel}
              isRequired
              classNames={{ root: "mt-0" }}
              errorMessage={userDataValidationResults.firstName.errorMessage}
            >
              <InputComponent
                inputRef={firstNameInputRef}
                placeholder={translations.firstNamePlaceholder}
                value={userData.firstName}
                onChange={setUserDataFirstName}
                onBlur={validateUserDataFirstName}
                hasError={!!userDataValidationResults.firstName.errorMessage}
                isDisabled={userDataDisabledFormFields.firstName}
                idForTesting={`user-data-first-name-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column md={6}>
            <FormFieldComponent
              label={translations.lastNameLabel}
              isRequired
              classNames={{ root: "mt-0" }}
              errorMessage={userDataValidationResults.lastName.errorMessage}
            >
              <InputComponent
                placeholder={translations.lastNamePlaceholder}
                value={userData.lastName}
                onChange={setUserDataLastName}
                onBlur={validateUserDataLastName}
                hasError={!!userDataValidationResults.lastName.errorMessage}
                isDisabled={userDataDisabledFormFields.lastName}
                idForTesting={`user-data-last-name-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormFieldComponent
              label={translations.mobileLabel}
              isRequired
              errorMessage={userDataValidationResults.mobile.errorMessage}
            >
              <PhoneNumberInputComponent
                placeholder={translations.mobilePlaceholder}
                phoneNumber={userData.mobile}
                onChange={setUserDataMobile}
                onBlur={validateUserDataMobile}
                hasError={!!userDataValidationResults.mobile.errorMessage}
                isDisabled={userDataDisabledFormFields.mobile}
                idForTesting={`mobile`}
              />
            </FormFieldComponent>
          </Column>
          <Column md={6}>
            <FormFieldComponent
              label={translations.alternativeMobileLabel}
              errorMessage={
                userDataValidationResults.alternativeMobile.errorMessage
              }
            >
              <PhoneNumberInputComponent
                placeholder={translations.alternativeMobilePlaceholder}
                phoneNumber={userData.alternativeMobile}
                onChange={setUserDataAlternativeMobile}
                onBlur={validateUserDataAlternativeMobile}
                hasError={
                  !!userDataValidationResults.alternativeMobile.errorMessage
                }
                isDisabled={userDataDisabledFormFields.alternativeMobile}
                idForTesting={`alternative-mobile`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <FormFieldComponent
          label={translations.addressesLabel}
          isRequired
          errorMessage={userDataValidationResults.addresses.errorMessage}
        >
          <SignUpPassengerUserAddressComponent />
        </FormFieldComponent>
      </CardComponent>
      <div className="sign_up_passenger__next_button_wrapper">
        <ButtonComponent
          onClick={submitUserStep}
          type="primary"
          isDisabled={!isSubmitButtonEnabled}
          idForTesting={`user-data-submit-button`}
        >
          {translations.submitButtonText}
        </ButtonComponent>
      </div>
    </>
  );
};

export default SignUpPassengerUserComponent;
