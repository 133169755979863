import UserStatus from "../../../../../common/types/user-status";
import { UserDispatcherListingRouteQueryParams } from "../../../common/routes/types/user-dispatcher-listing-route-params";
import DispatcherListingFilter, {
  DispatcherListingCargoCompanyFilter,
  DispatcherListingDispatchFilter,
  DispatcherListingEmailFilter,
  DispatcherListingFirstNameFilter,
  DispatcherListingLastNameFilter,
  DispatcherListingStatusFilter,
  DispatcherListingUsernameFilter,
} from "../common/types/dispatcher-listing-filter";
import DispatcherListingFilterType from "../common/types/dispatcher-listing-filter-type";
import DispatcherListingSortKey from "../common/types/dispatcher-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: UserDispatcherListingRouteQueryParams
): DispatcherListingFilter[] => {
  const filters: DispatcherListingFilter[] = [];

  if (routeQueryFilterParams.firstName) {
    const firstNameFilter: DispatcherListingFirstNameFilter = {
      type: DispatcherListingFilterType.FIRST_NAME,
      value: routeQueryFilterParams.firstName,
    };

    filters.push(firstNameFilter);
  }

  if (routeQueryFilterParams.lastName) {
    const lastNameFilter: DispatcherListingLastNameFilter = {
      type: DispatcherListingFilterType.LAST_NAME,
      value: routeQueryFilterParams.lastName,
    };

    filters.push(lastNameFilter);
  }

  if (routeQueryFilterParams.email) {
    const emailAddressFilter: DispatcherListingEmailFilter = {
      type: DispatcherListingFilterType.EMAIL,
      value: routeQueryFilterParams.email,
    };

    filters.push(emailAddressFilter);
  }

  if (routeQueryFilterParams.userName) {
    const userNameFilter: DispatcherListingUsernameFilter = {
      type: DispatcherListingFilterType.USERNAME,
      value: routeQueryFilterParams.userName,
    };

    filters.push(userNameFilter);
  }

  if (routeQueryFilterParams.cargoCompany) {
    const cargoCompanyFilter: DispatcherListingCargoCompanyFilter = {
      type: DispatcherListingFilterType.CARGO_COMPANY,
      value: routeQueryFilterParams.cargoCompany,
    };

    filters.push(cargoCompanyFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: DispatcherListingDispatchFilter = {
      type: DispatcherListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: DispatcherListingStatusFilter = {
      type: DispatcherListingFilterType.ACTIVITY_STATUS,
      value:
        routeQueryFilterParams.status === "DELETED"
          ? UserStatus.DELETED
          : UserStatus.ACTIVE,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: UserDispatcherListingRouteQueryParams
): DispatcherListingSortKey | undefined => {
  return routeQueryParams.sort as DispatcherListingSortKey;
};

const getPage = (
  routeQueryParams: UserDispatcherListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: UserDispatcherListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: DispatcherListingFilter[],
  sortKey: DispatcherListingSortKey | null,
  page: number,
  pageSize: number
): UserDispatcherListingRouteQueryParams => {
  const userActivityFilterValue = filters.find(
    (filter) => filter.type === DispatcherListingFilterType.ACTIVITY_STATUS
  )?.value as DispatcherListingStatusFilter["value"] | undefined;

  const routeQueryParams: UserDispatcherListingRouteQueryParams = {
    firstName: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.FIRST_NAME
    )?.value as DispatcherListingFirstNameFilter["value"],
    lastName: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.LAST_NAME
    )?.value as DispatcherListingLastNameFilter["value"],
    userName: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.USERNAME
    )?.value as DispatcherListingUsernameFilter["value"],
    email: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.EMAIL
    )?.value as DispatcherListingEmailFilter["value"],
    status:
      userActivityFilterValue === undefined
        ? undefined
        : String(userActivityFilterValue),
    cargoCompany: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.CARGO_COMPANY
    )?.value as DispatcherListingCargoCompanyFilter["value"],
    dispatch: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.DISPATCH
    )?.value as DispatcherListingDispatchFilter["value"],
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const dispatcherListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default dispatcherListingFiltersService;
