import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import OrderActiveListingRouteQueryParams from "../../common/routes/types/order-active-listing-route-params";
import OrderActiveListingFilter from "../common/types/order-active-listing-filter";
import OrderActiveListingSortKey from "../common/types/order-active-listing-sort-key";
import orderActiveListingFilterService from "./order-active-listing-filter.service";

const useOrderActiveListingFilterDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderActiveListingRouteQueryParams>();

  const getFilters = (): OrderActiveListingFilter[] => {
    return orderActiveListingFilterService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return orderActiveListingFilterService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return orderActiveListingFilterService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): OrderActiveListingSortKey | undefined => {
    return orderActiveListingFilterService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: OrderActiveListingFilter[],
    sortKey: OrderActiveListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const newRouteQueryParams =
      orderActiveListingFilterService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize,
        routeQueryParams.defaultSelectedOrderUuid
      );

    setRouteQueryParams(newRouteQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useOrderActiveListingFilterDao;
