import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "./types/billings-taxi-driver-contributions-penalty";

const getNewPenaltyContribution = (): BillingsTaxiDriverContributionPenalty => {
  return {
    amount: null,
    comment: "",
    type: null,
  };
};

const getContributionTypeTranslation = (
  contributionType: BillingsTaxiDriverContributionPenaltyType | null
) => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations().penalty
      .type;

  switch (contributionType) {
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
      return translations.beingLate;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
      return translations.incompatibleCar;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return translations.wrongRoute;
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
      return translations.other;
  }
};

const billingsTaxiDriverContributionsPenaltyHelper = {
  getNewPenaltyContribution,
  getContributionTypeTranslation,
};

export default billingsTaxiDriverContributionsPenaltyHelper;
