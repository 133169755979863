import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiCargoContractListingUserPermissionOption =
  | "hasAccessToEditCargoContract";

export type TaxiCargoContractListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiCargoContractListingUserPermissionOption>;

export type TaxiCargoContractListingUserPermissions =
  UserPermissions<TaxiCargoContractListingUserPermissionOption>;

const taxiCargoContractListingUserPermissionDefinition: TaxiCargoContractListingUserPermissionsDefinition =
  {
    hasAccessToEditCargoContract: [UserRole.ADMIN],
  };

export default taxiCargoContractListingUserPermissionDefinition;
