import RouteActiveListRequest from "../../api/raily/route/active-list/route-active-list.request";
import RouteCompletedRouteRequest from "../../api/raily/route/completed-route/route-completed-route.request";
import RouteDetailsRequest from "../../api/raily/route/details/route-details.request";
import RouteFinishedListRequest from "../../api/raily/route/finished-list/route-finished-list.request";
import routeApiService from "../../api/raily/route/route-api.service";
import { DataLoadDefaultErrors } from "../../utils/data-load-error/data-load-error";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import RouteActiveList from "./active-list/route-active-list";
import RouteActiveListError from "./active-list/route-active-list-error";
import RouteActiveListLoadParams from "./active-list/route-active-list-load-params";
import routeActiveListRequestFactory from "./active-list/route-active-list-request.factory";
import routeActiveListFactory from "./active-list/route-active-list.factory";
import RouteCompletedRoute from "./completed-route/route-completed-route";
import RouteCompletedRouteError from "./completed-route/route-completed-route-error";
import RouteCompletedRouteLoadParams from "./completed-route/route-completed-route-load-params";
import routeCompletedRouteRequestFactory from "./completed-route/route-completed-route-request.factory";
import routeCompletedRouteFactory from "./completed-route/route-completed-route.factory";
import RouteDetails from "./details/route-details";
import RouteDetailsError from "./details/route-details-error";
import RouteDetailsLoadParams from "./details/route-details-load-params";
import routeDetailsRequestFactory from "./details/route-details-request.factory";
import routeDetailsFactory from "./details/route-details.factory";
import RouteFinishedList from "./finished-list/route-finished-list";
import RouteFinishedListLoadParams from "./finished-list/route-finished-list-load-params";
import routeFinishedListRequestFactory from "./finished-list/route-finished-list-request.factory";
import routeFinishedListFactory from "./finished-list/route-finished-list.factory";
import RouteRoadRoute from "./road-route/route-road-route";
import RouteRoadRouteLoadParams from "./road-route/route-road-route-load-params";
import routeRoadRouteRequestFactory from "./road-route/route-road-route-request.factory";
import routeRoadRouteFactory from "./road-route/route-road-route.factory";

const handleActiveListError = (
  error: HttpError | RouteActiveListError
): RouteActiveListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getActiveList = async (
  params: RouteActiveListLoadParams,
  abortSignal: AbortSignal
): Promise<RouteActiveList> => {
  const request: RouteActiveListRequest =
    routeActiveListRequestFactory.create(params);

  try {
    const response = await routeApiService().getActiveList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return routeActiveListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | RouteActiveListError;

    throw handleActiveListError(error);
  }
};

const handleFinishedListError = (
  error: HttpError | RouteActiveListError
): RouteActiveListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getFinishedList = async (
  params: RouteFinishedListLoadParams,
  abortSignal: AbortSignal
): Promise<RouteFinishedList> => {
  const request: RouteFinishedListRequest =
    routeFinishedListRequestFactory.create(params);

  try {
    const response = await routeApiService().getFinishedList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return routeFinishedListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | RouteActiveListError;

    throw handleFinishedListError(error);
  }
};

const handleDetailsError = (
  error: HttpError | RouteDetailsError
): RouteDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: RouteDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<RouteDetails> => {
  const request: RouteDetailsRequest =
    routeDetailsRequestFactory.create(params);

  try {
    const response = await routeApiService().getDetails(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const routeDetails = routeDetailsFactory.create(response.data);

    return routeDetails;
  } catch (_error) {
    const error = _error as HttpError | RouteDetailsError;

    throw handleDetailsError(error);
  }
};

const handleRoadRouteError = (error: HttpError): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getRoute = async (
  params: RouteRoadRouteLoadParams
): Promise<RouteRoadRoute[]> => {
  const request = routeRoadRouteRequestFactory.create(params);

  try {
    const response = await routeApiService().getRoute(request);

    return routeRoadRouteFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError;

    throw handleRoadRouteError(error);
  }
};

const handleCompletedRouteError = (
  error: HttpError | RouteCompletedRouteError
): RouteCompletedRouteError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getCompletedRoute = async (
  params: RouteCompletedRouteLoadParams,
  abortSignal: AbortSignal
): Promise<RouteCompletedRoute> => {
  const request: RouteCompletedRouteRequest =
    routeCompletedRouteRequestFactory.create(params);

  try {
    const response = await routeApiService().getCompletedRoute(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const routeCompletedRoute = routeCompletedRouteFactory.create(response);

    return routeCompletedRoute;
  } catch (_error) {
    const error = _error as HttpError | RouteCompletedRouteError;

    throw handleCompletedRouteError(error);
  }
};

const routeService = {
  getActiveList,
  getFinishedList,
  getDetails,
  getRoute,
  getCompletedRoute,
};

export default routeService;
