import TaxiTaxiContractUpdateRequest from "../../../api/raily/taxi-taxi-contract/update/taxi-taxi-contract-update.request";
import TaxiTaxiContractUpdateParams from "./taxi-taxi-contract-update-params";

const create = (
  params: TaxiTaxiContractUpdateParams
): TaxiTaxiContractUpdateRequest => {
  return {
    contractUuid: params.contractUuid,
    disabled: !params.isActive,
    displayName: params.displayName,
    priority: params.priority,
  };
};

const taxiTaxiContractUpdateRequestFactory = {
  create,
};

export default taxiTaxiContractUpdateRequestFactory;
