import { DivIcon } from "leaflet";

import isEqual from "lodash/isEqual";
import OrderJoinOrderDetailsRouteWaypoint from "../types/order-join-order-details-route-waypoint";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import mapMarkerIconFactory from "../../../../../common/components/map/marker/map-marker-icon.factory";
import OrderJoinOrderDetailsMapMarkerType from "../types/order-join-order-details-map-marker-type";

const createMapMarker = (
  route: OrderJoinOrderDetailsRouteWaypoint,
  type: OrderJoinOrderDetailsMapMarkerType,
  signature?: string
): MapMarker => {
  const onboardingMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker onboarding",
    content: signature,
  });

  const outboardingMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker outboarding",
    content: signature,
  });

  const standardMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: signature,
  });

  const weatheredMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker weathered",
    content: signature,
  });

  const iconOptions: {
    type: OrderJoinOrderDetailsMapMarkerType;
    icon: DivIcon;
  }[] = [
    {
      type: OrderJoinOrderDetailsMapMarkerType.ONBOARDING,
      icon: onboardingMarkerIcon,
    },
    {
      type: OrderJoinOrderDetailsMapMarkerType.OUTBOARDING,
      icon: outboardingMarkerIcon,
    },
    {
      type: OrderJoinOrderDetailsMapMarkerType.STANDARD,
      icon: standardMarkerIcon,
    },
    {
      type: OrderJoinOrderDetailsMapMarkerType.WEATHERED,
      icon: weatheredMarkerIcon,
    },
  ];

  const icon: MapMarker["icon"] = iconOptions.find((icon) => icon.type === type)
    ?.icon!;

  const marker: MapMarker = {
    coordinate: {
      latitude: route.place.latitude,
      longitude: route.place.longitude,
    },
    title: route.place.displayName,
    tooltip: route.place.displayName,
    icon,
  };

  return marker;
};

const createMapMarkersForNotSelectedPassenger = (
  routeWaypoints: OrderJoinOrderDetailsRouteWaypoint[]
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  const flattenedWaypoints = routeWaypoints.flat();

  for (const waypoint of flattenedWaypoints) {
    const allWaypointsOnThisPlace = flattenedWaypoints.filter((w) =>
      isEqual(w.place, waypoint.place)
    );

    const signature = allWaypointsOnThisPlace.map((w) => w.stageNo).join("/");

    const newMapMarker = createMapMarker(
      waypoint,
      OrderJoinOrderDetailsMapMarkerType.STANDARD,
      signature
    );

    mapMarkers = [...mapMarkers, newMapMarker];
  }

  return mapMarkers;
};

const createOnboardingMapMarker = (
  waypoint: OrderJoinOrderDetailsRouteWaypoint
) => {
  const signature = `${waypoint.stageNo}`;

  const newMarker: MapMarker = createMapMarker(
    waypoint,
    OrderJoinOrderDetailsMapMarkerType.ONBOARDING,
    signature
  );

  return newMarker;
};

const createOutboardingMapMarker = (
  waypoint: OrderJoinOrderDetailsRouteWaypoint
) => {
  const signature = `${waypoint.stageNo}`;

  const newMarker: MapMarker = createMapMarker(
    waypoint,
    OrderJoinOrderDetailsMapMarkerType.OUTBOARDING,
    signature
  );

  return newMarker;
};

const createMapMarkers = (
  routes: OrderJoinOrderDetailsRouteWaypoint[]
): MapMarker[] => {
  return createMapMarkersForNotSelectedPassenger(routes);
};

const orderJoinOrderDetailsPlannedRouteMapMarkersFactory = {
  createMapMarkers,
};

export default orderJoinOrderDetailsPlannedRouteMapMarkersFactory;
