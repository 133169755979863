import { filter } from "lodash";
import MileageListLoadParams, {
  MileageListLoadParamsOrder,
  MileageListLoadParamsVehicleType,
} from "../../../../common/services/mileage/list/mileage-list-load-params";
import MileageListingFilter, {
  MileageListingCardNumberFilter,
  MileageListingMileageDateFilter,
  MileageListingDistanceFilter,
  MileageListingEndAddressFilter,
  MileageListingHumanIdFilter,
  MileageListingMileageNumberFilter,
  MileageListingStartAddressFilter,
  MileageListingWorkerNameFilter,
  MileageListingCargoCompanyNameFilter,
  MileageListingVehicleTypeFilter,
} from "./types/mileage-listing-filter";
import MileageListingFilterType from "./types/mileage-listing-filter-type";
import MileageListingSortKey from "./types/mileage-listing-sort-key";
import MileageListingVehicleType from "./types/mileage-listing-vehicle-type";

const createVehicleType = (
  type: MileageListingVehicleType | undefined
): MileageListLoadParamsVehicleType | undefined => {
  switch (type) {
    case MileageListingVehicleType.CAR_GT_900CM3:
      return MileageListLoadParamsVehicleType.CAR_GT_900CM3;
    case MileageListingVehicleType.CAR_LE_900CM3:
      return MileageListLoadParamsVehicleType.CAR_LE_900CM3;
    case MileageListingVehicleType.COMPANY_OWNED:
      return MileageListLoadParamsVehicleType.COMPANY_OWNED;
    case MileageListingVehicleType.MOPED:
      return MileageListLoadParamsVehicleType.MOPED;
    case MileageListingVehicleType.MOTORCYCLE:
      return MileageListLoadParamsVehicleType.MOTORCYCLE;
    default:
      return undefined;
  }
};

const createOrder = (
  sortKey: MileageListingSortKey | null
): MileageListLoadParamsOrder | undefined => {
  switch (sortKey) {
    case MileageListingSortKey.CARD_NO_ASC:
      return MileageListLoadParamsOrder.CARD_NO_ASC;
    case MileageListingSortKey.CARD_NO_DESC:
      return MileageListLoadParamsOrder.CARD_NO_DESC;
    case MileageListingSortKey.DATE_ASC:
      return MileageListLoadParamsOrder.DATE_ASC;
    case MileageListingSortKey.DATE_DESC:
      return MileageListLoadParamsOrder.DATE_DESC;
    case MileageListingSortKey.DISTANCE_ASC:
      return MileageListLoadParamsOrder.DISTANCE_ASC;
    case MileageListingSortKey.DISTANCE_DESC:
      return MileageListLoadParamsOrder.DISTANCE_DESC;
    case MileageListingSortKey.HUMAN_ID_ASC:
      return MileageListLoadParamsOrder.HUMAN_ID_ASC;
    case MileageListingSortKey.HUMAN_ID_DESC:
      return MileageListLoadParamsOrder.HUMAN_ID_DESC;
    case MileageListingSortKey.WORKER_NAME_ASC:
      return MileageListLoadParamsOrder.WORKER_NAME_ASC;
    case MileageListingSortKey.WORKER_NAME_DESC:
      return MileageListLoadParamsOrder.WORKER_NAME_DESC;
    case MileageListingSortKey.CARGO_COMPANY_NAME_ASC:
      return MileageListLoadParamsOrder.CARGO_COMPANY_NAME_ASC;
    case MileageListingSortKey.CARGO_COMPANY_NAME_DESC:
      return MileageListLoadParamsOrder.CARGO_COMPANY_NAME_DESC;
    default:
      return undefined;
  }
};

const create = (
  page: number,
  pageSize: number,
  filters: MileageListingFilter[],
  sortKey: MileageListingSortKey | null
): MileageListLoadParams => {
  const dateFilter = filters.find(
    (filter) => filter.type == MileageListingFilterType.MILEAGE_DATE
  )?.value as MileageListingMileageDateFilter["value"] | undefined;

  const distanceFilter = filters.find(
    (filter) => filter.type == MileageListingFilterType.DISTANCE
  )?.value as MileageListingDistanceFilter["value"] | undefined;

  const startAddress = filters.find(
    (filter) => filter.type == MileageListingFilterType.START_ADDRESS
  )?.value as MileageListingStartAddressFilter["value"] | undefined;

  const endAddress = filters.find(
    (filter) => filter.type == MileageListingFilterType.END_ADDRESS
  )?.value as MileageListingEndAddressFilter["value"] | undefined;

  const vehicleType = filters.find(
    (filter) => filter.type == MileageListingFilterType.VEHICLE_TYPE
  )?.value as MileageListingVehicleTypeFilter["value"] | undefined;

  return {
    page: page,
    pageSize: pageSize,
    order: createOrder(sortKey),
    dateSince: dateFilter?.from,
    dateTo: dateFilter?.to,
    humanId: filters.find(
      (filter) => filter.type == MileageListingFilterType.HUMAN_ID
    )?.value as MileageListingHumanIdFilter["value"] | undefined,
    mileageNumber: filters.find(
      (filter) => filter.type == MileageListingFilterType.MILEAGE_NUMBER
    )?.value as MileageListingMileageNumberFilter["value"] | undefined,
    workerName: filters.find(
      (filter) => filter.type == MileageListingFilterType.WORKER_NAME
    )?.value as MileageListingWorkerNameFilter["value"] | undefined,
    cardNumber: filters.find(
      (filter) => filter.type == MileageListingFilterType.CARD_NUMBER
    )?.value as MileageListingCardNumberFilter["value"] | undefined,
    cargoCompanyName: filters.find(
      (filter) => filter.type == MileageListingFilterType.CARGO_COMPANY_NAME
    )?.value as MileageListingCargoCompanyNameFilter["value"] | undefined,
    vehicleType: createVehicleType(vehicleType),
    startAddress: startAddress ? [startAddress] : undefined,
    endAddress: endAddress ? [endAddress] : undefined,
    distanceMin: distanceFilter?.from,
    distanceMax: distanceFilter?.to,
  };
};

const mileageListingLoadParamsFactory = {
  create,
};

export default mileageListingLoadParamsFactory;
