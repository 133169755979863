import { Marker, Popup } from "react-leaflet";
import { FC, useEffect, useRef } from "react";
import MapMarker from "../types/map-marker";
import { LatLngExpression, Marker as LeafletMarker } from "leaflet";
import MapCoordinate from "../types/map-coordinate";
import mapMarkerIconFactory from "./map-marker-icon.factory";

export type MapMarkerProps = MapMarker;

const MapMarkerComponent: FC<MapMarkerProps> = (props) => {
  const markerRef = useRef<LeafletMarker>(null);

  const position: LatLngExpression = {
    lat: props.coordinate.latitude,
    lng: props.coordinate.longitude,
  };

  useEffect(() => {
    if (!markerRef.current || !props.isDraggable) {
      return;
    }

    const handleDragEnd = () => {
      if (!markerRef.current || !props.onCoordinateChange) {
        return;
      }

      const newPosition = markerRef.current.getLatLng();

      const coordinate: MapCoordinate = {
        latitude: newPosition.lat,
        longitude: newPosition.lng,
      };

      props.onCoordinateChange(coordinate);
    };

    markerRef.current.on("dragend", handleDragEnd);

    return () => {
      markerRef.current?.off("dragend", handleDragEnd);
    };
  }, [markerRef.current]);

  return (
    <Marker
      ref={markerRef}
      position={position}
      icon={props.icon}
      title={props.title}
      draggable={props.isDraggable}
      zIndexOffset={props.zIndex}
    >
      {props.tooltip && <Popup maxWidth={undefined}>{props.tooltip}</Popup>}
    </Marker>
  );
};

MapMarkerComponent.defaultProps = {
  icon: mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
  }),
};

export default MapMarkerComponent;
