import HttpResponse from "../../../utils/http/http.response";
import ApiHeaders from "../../types/api-headers";
import railyApiService from "../raily-api.service";
import { RefreshTokenRequestBody } from "./refresh-token/refresh-token.request";
import RefreshTokenResponse from "./refresh-token/refresh-token.response";
import signInRequestFactory from "./sign-in/sign-in-request.factory";
import SignInRequest, { SignInRequestBody } from "./sign-in/sign-in.request";
import SignInResponse from "./sign-in/sign-in.response";

const authApiService = () => {
  const signIn = (
    request: SignInRequest,
    abortSignal: AbortSignal
  ): Promise<SignInResponse> => {
    const url = `/auth/token`;

    const body: SignInRequestBody = signInRequestFactory.createBody(request);

    const headers: ApiHeaders = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    return railyApiService().post({
      url,
      abortSignal,
      body,
      headers,
    });
  };

  const signOut = (abortSignal: AbortSignal): Promise<HttpResponse> => {
    const url = `/auth/logout`;

    return railyApiService().post({
      url,
      abortSignal,
    });
  };

  const refreshToken = (
    abortSignal: AbortSignal
  ): Promise<RefreshTokenResponse> => {
    const url = `/auth/token`;

    const body: RefreshTokenRequestBody = {
      grantType: "refresh",
    };

    const headers: ApiHeaders = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    return railyApiService().post({
      url,
      abortSignal,
      body,
      headers,
    });
  };

  return {
    signIn,
    signOut,
    refreshToken,
  };
};

export default authApiService;
