export enum CargoOrderFinishedListReportLoadParamsSortKey {
  ORDER_START_DATE_ASC = "ORDER_START_DATE_ASC",
  ORDER_START_DATE_DESC = "ORDER_START_DATE_DESC",
  INTERNAL_ORDER_ID_ASC = "INTERNAL_ORDER_ID_ASC",
  INTERNAL_ORDER_ID_DESC = "INTERNAL_ORDER_ID_DESC",
}

export enum CargoOrderFinishedListReportLoadParamsPublicStatus {
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
  PUBLIC = "PUBLIC",
}

export enum CargoOrderFinishedListReportLoadParamsExecutionStatus {
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
}

type CargoOrderFinishedListReportLoadParams = {
  internalOrderId?: number;
  isUrgent?: boolean;
  client?: string;
  dispatch?: string;
  driver?: string;
  externalOrderId?: string;
  address?: string;
  pickupAddress?: string;
  intermediateAddress?: string;
  destinationAddress?: string;
  taxi?: string;
  passenger?: string;
  executionStatus?: CargoOrderFinishedListReportLoadParamsExecutionStatus;
  publicStatus?: CargoOrderFinishedListReportLoadParamsPublicStatus;
  sortKey?: CargoOrderFinishedListReportLoadParamsSortKey;
  orderStartDateTo?: Date;
  orderStartDateFrom?: Date;
};

export default CargoOrderFinishedListReportLoadParams;
