import useAsyncData from "../../../hooks/use-async-data";
import mileageContractService from "../mileage-contract.service";
import MileageContractDetails from "./mileage-contract-details";
import MileageContractDetailsLoadParams from "./mileage-contract-details-load-params";

const useMileageContractDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MileageContractDetails | null>(null);

  const load = async (
    params: MileageContractDetailsLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const mileageContractDetails = await mileageContractService.getDetails(
        params,
        signal
      );

      setData(mileageContractDetails);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useMileageContractDetails;
