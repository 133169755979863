import TaxiOfficerEditByRailyFormData from "./types/taxi-officer-edit-by-raily-form-data";
import TaxiOfficerEditParams from "../../../../../../common/services/taxi-officer/edit/taxi-officer-edit-params";

const create = (
  formData: TaxiOfficerEditByRailyFormData
): TaxiOfficerEditParams => {
  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    phoneNumber: formData.phoneNumber,
    alternativePhoneNumber: formData.alternativePhoneNumber,
    taxiCorporationUuid: formData.taxiCorporationUuid,
  };
};

const taxiOfficerEditByRailyParamsFactory = {
  create,
};

export default taxiOfficerEditByRailyParamsFactory;
