enum OrderAddValidationError {
  TOO_LESS_WAYPOINTS = "TOO_LESS_WAYPOINTS",
  TOO_MANY_PASSENGERS_IN_CAR_IN_ONE_OF_WAYPOINTS = "TOO_MANY_PASSENGERS_IN_CAR_IN_ONE_OF_WAYPOINTS",
  NO_PLACE_IN_ONE_OF_WAYPOINTS = "NO_PLACE_IN_ONE_OF_WAYPOINTS",
  NO_ONE_WAYPOINT_HAS_DATE = "NO_ONE_WAYPOINT_HAS_DATE",
  NOT_EVERY_PASSENGER_IS_ONBOARDED = "NOT_EVERY_PASSENGER_IS_ONBOARDED",
  NOT_EVERY_PASSENGER_IS_ONBOARDED_RIGHT = "NOT_EVERY_PASSENGER_IS_ONBOARDED_RIGHT",
  NOT_VALID_DATES_IN_WAYPOINTS = "NOT_VALID_DATES_IN_WAYPOINTS",
  NOT_EVERY_WAYPOINT_HAS_PASSENGER_ACTION = "NOT_EVERY_WAYPOINT_HAS_PASSENGER_ACTION",
  PAST_DATE_IN_WAYPOINTS = "PAST_DATE_IN_WAYPOINTS",
}

export default OrderAddValidationError;
