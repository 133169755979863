import BillingsOrderAddressesRequest from "../../../../api/raily/billings/order/addresses/billings-order-addresses.request";
import BillingsOrderAddressesLoadParams from "./billings-order-addresses-load-params";

const create = (
  params: BillingsOrderAddressesLoadParams
): BillingsOrderAddressesRequest => {
  return {
    orderId: params.orderUuid,
    searchQuery: params.searchQuery,
  };
};

const billingsOrderAddressesRequestFactory = {
  create,
};

export default billingsOrderAddressesRequestFactory;
