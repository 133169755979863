import TaxiCorporationListRequest, {
  TaxiCorporationListRequestOrder,
} from "../../../api/raily/taxi-corporation/list/taxi-corporation-list.request";
import paginationService from "../../../utils/pagination/pagination.service";
import TaxiCorporationListLoadParams, {
  TaxiCorporationListLoadParamsOrder,
} from "./taxi-corporation-list-load-params";

const createOrder = (
  status: TaxiCorporationListLoadParamsOrder | undefined
): TaxiCorporationListRequestOrder | undefined => {
  switch (status) {
    case TaxiCorporationListLoadParamsOrder.COMPANY_NAME_ASC:
      return TaxiCorporationListRequestOrder.COMPANY_NAME_ASC;
    case TaxiCorporationListLoadParamsOrder.COMPANY_NAME_DESC:
      return TaxiCorporationListRequestOrder.COMPANY_NAME_DESC;
    case TaxiCorporationListLoadParamsOrder.DISPLAY_NAME_ASC:
      return TaxiCorporationListRequestOrder.DISPLAY_NAME_ASC;
    case TaxiCorporationListLoadParamsOrder.DISPLAY_NAME_DESC:
      return TaxiCorporationListRequestOrder.DISPLAY_NAME_DESC;

    default:
      return undefined;
  }
};

const create = (
  params: TaxiCorporationListLoadParams
): TaxiCorporationListRequest => {
  return {
    headquartersAddressString: params.address,
    companyName: params.name,
    displayName: params.displayName,
    krsNo: params.nationalCourtRegister,
    notes: params.notes,
    regon: params.companyId,
    taxId: params.taxNumber,
    creationStart: params.creationDateFrom,
    creationEnd: params.creationDateTo,
    limit: params.pageSize,
    offset:
      params.page && params.pageSize
        ? paginationService.calculateOffset(params.page, params.pageSize)
        : undefined,
    order: createOrder(params.order),
  };
};

const taxiCorporationListRequestFactory = { create };

export default taxiCorporationListRequestFactory;
