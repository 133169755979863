import railyApiService from "../raily-api.service";
import TransportingOrderDeleteForwardingRequest from "./delete-forwarding/transporting-order-delete-forwarding.request";
import TransportingOrderDeleteForwardingResponse from "./delete-forwarding/transporting-order-delete-forwarding.response";

const transportingOrderApiService = () => {
  const deleteForwarding = async (
    request: TransportingOrderDeleteForwardingRequest,
    abortSignal: AbortSignal
  ): Promise<TransportingOrderDeleteForwardingResponse> => {
    const url = `/orders/transporting-orders/${request.orderId}/forwards/${request.forwardActionId}`;

    return railyApiService().delete({
      url,
      abortSignal,
    });
  };

  return { deleteForwarding };
};

export default transportingOrderApiService;
