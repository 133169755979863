import CargoCompanyAddRequest, {
  CargoCompanyAddRequestBody,
} from "./cargo-company-add-request";

const createBody = (
  data: CargoCompanyAddRequest
): CargoCompanyAddRequestBody => {
  return {
    taxId: data.taxId,
    regon: data.regon || null,
    krsNo: data.krsNo || null,
    companyName: data.companyName,
    displayName: data.displayName,
    headquartersAddressString: data.headquartersAddressString,
    contractMaintenancePolicy: data.contractMaintenancePolicy,
    notes: data.notes,
  };
};

const cargoCompanyAddRequestFactory = {
  createBody,
};

export default cargoCompanyAddRequestFactory;
