export enum OrderDetailsDriverRole {
  ASSIGNED = "ASSIGNED",
  CANDIDATE = "CANDIDATE",
}

type OrderDetailsDriver = {
  uuid: string;
  displayName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: OrderDetailsDriverRole;
};

export default OrderDetailsDriver;
