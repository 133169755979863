import appConfig from "../../../../../../app.config";
import SignUpCargoOfficerRequest from "./sign-up-cargo-officer.request";
import SignUpCargoOfficerResponse from "./sign-up-cargo-officer.response";


const signUpCargoOfficer = async (
  request: SignUpCargoOfficerRequest,
  token: string
): Promise<SignUpCargoOfficerResponse> => {
  const path = `/cargo-officers`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
    const responseData = await r.json();
    return {
        status: r.status,
        data: responseData,
    };
};

const signUpCargoOfficerApiService = {
    signUpCargoOfficer,
};

export default signUpCargoOfficerApiService;
