import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import OrderDetailsChangeCargoExternalIdFormData from "./types/order-details-change-cargo-external-id-form-data";

const validateCargoExternalId = (
  cargoExternalId: OrderDetailsChangeCargoExternalIdFormData["cargoExternalId"]
) => {
  const validationSchema = Joi.string().allow("").max(50).required();

  return formValidationService.validate(cargoExternalId, validationSchema);
};

const orderDetailsChangeCargoExternalIdValidationService = {
  validateCargoExternalId,
};

export default orderDetailsChangeCargoExternalIdValidationService;
