import HttpResponse from "../../../../utils/http/http.response";

type TransportingOrderDeleteForwardingSuccessResponse = HttpResponse<string>;

export enum TransportingOrderDeleteForwardingNotAcceptableResponseErrorType {
  ABANDONED_ORDER = "ABANDONED_ORDER",
  DRIVER_ASSIGNED = "DRIVER_ASSIGNED",
}

export type TransportingOrderDeleteForwardingNotAcceptableResponseAbandonedOrder =
  {
    errorType: TransportingOrderDeleteForwardingNotAcceptableResponseErrorType.ABANDONED_ORDER;
  };

export type TransportingOrderDeleteForwardingNotAcceptableResponseDriverAssigned =
  {
    errorType: TransportingOrderDeleteForwardingNotAcceptableResponseErrorType.DRIVER_ASSIGNED;
  };

type TransportingOrderDeleteForwardingNotAcceptableResponseError =
  | TransportingOrderDeleteForwardingNotAcceptableResponseAbandonedOrder
  | TransportingOrderDeleteForwardingNotAcceptableResponseDriverAssigned;

export type TransportingOrderDeleteForwardingNotAcceptableResponse =
  HttpResponse<TransportingOrderDeleteForwardingNotAcceptableResponseError>;

type TransportingOrderDeleteForwardingResponse =
  | TransportingOrderDeleteForwardingSuccessResponse
  | TransportingOrderDeleteForwardingNotAcceptableResponse;

export default TransportingOrderDeleteForwardingResponse;
