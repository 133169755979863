import { FC } from "react";
import ModalComponent from "../../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import htmlParser from "../../../../../common/utils/html-parser/html-parser.service";
import delegationTranslationsHelper from "../../../../../languages/delegation-translations.helper";
import delegationDeleteHelper from "./types/delegation-delete.helper";
import DelegationDeleteDelegationData from "./types/delegation-delete-delegation-data";

type DelegationDeleteProps = {
  isOpen: boolean;
  onClose: () => void;
  delegationData: DelegationDeleteDelegationData | null;
  onSuccess: () => void;
  isDeletePending: boolean;
};

const DelegationDeleteComponent: FC<DelegationDeleteProps> = (props) => {
  const translations =
    delegationTranslationsHelper.getDelegationDeleteTranslations();

  if (!props.delegationData) {
    return null;
  }

  const onSubmitButtonClick = () => {
    props.onSuccess();
  };

  const contentText = delegationDeleteHelper.getContentText(
    props.delegationData.delegationNumber
  );

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={{ title: translations.delegationDelete.headingLabel }}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="danger"
          title={translations.delegationDelete.submitButtonTitle}
          idForTesting="submit-button"
          isLoading={props.isDeletePending}
        >
          {translations.delegationDelete.submitButtonLabel}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.delegationDelete.rejectButtonTitle}
          idForTesting="reject-button"
        >
          {translations.delegationDelete.rejectButtonLabel}
        </ButtonComponent>,
      ]}
    >
      {htmlParser.parse(contentText)}
    </ModalComponent>
  );
};

export default DelegationDeleteComponent;
