import MileageDetailsPassenger from "./types/mileage-details-passenger";
import MileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import UserRole from "../../../../common/types/user-role";

const getPassengerTitle = (passenger: MileageDetailsPassenger): string => {
  const translations =
    MileageTranslationsHelper.getMileageDetailsTranslations();

  return translations.passengers.passengerTitleTemplateText
    .replace("#{passenger}", passenger.displayName)
    .replace("#{mobileNumber}", passenger.phoneNumber);
};

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.MILEAGE_PASSENGER]: [UserRole.ADMIN],
    [MessengerChannel.MILEAGE_NOTES]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  };
};

const mileageDetailsHelper = {
  getPassengerTitle,
  getMessengerChannelAvailability,
};

export default mileageDetailsHelper;
