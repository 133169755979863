import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import mileageRoutesUrls from "./mileage-routes-urls";
import MileageListingComponent from "../../listing/mileage-listing.component";
import MileageAddComponent from "../../add/mileage-add.component";
import MileageContractsListingComponent from "../../contract/listing/mileage-contract-listing.component";
import MileageContractAddComponent from "../../contract/add/mileage-contract-add.component";
import MileageSettingsComponent from "../../settings/mileage-settings.component";
import MileageDictionaryListingComponent from "../../dictionary/listing/mileage-dictionary-listing.component";
import MileageDictionaryAddComponent from "../../dictionary/add/mileage-dictionary-add.component";
import MileageDictionaryUpdateComponent from "../../dictionary/update/mileage-dictionary-update.component";
import MileageContractUpdateComponent from "../../contract/update/mileage-contract-update.component";
import MileageUpdateComponent from "../../update/mileage-update.component";

const mileageRoutesDefinition: RouteItemDefinition[] = [
  {
    path: mileageRoutesUrls.listing,
    component: <MileageListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
  {
    path: mileageRoutesUrls.add,
    component: <MileageAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
  {
    path: mileageRoutesUrls.contractListing,
    component: <MileageContractsListingComponent />,
    userRolesWhitelist: [UserRole.ADMIN],
  },
  {
    path: mileageRoutesUrls.contractAdd,
    component: <MileageContractAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN],
  },
  {
    path: mileageRoutesUrls.contractUpdate,
    component: <MileageContractUpdateComponent />,
    userRolesWhitelist: [UserRole.ADMIN],
  },
  {
    path: mileageRoutesUrls.settings,
    component: <MileageSettingsComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
  {
    path: mileageRoutesUrls.dictionary,
    component: <MileageDictionaryListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
  {
    path: mileageRoutesUrls.dictionaryRecordAdd,
    component: <MileageDictionaryAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
  {
    path: mileageRoutesUrls.dictionaryRecordUpdate,
    component: <MileageDictionaryUpdateComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
  {
    path: mileageRoutesUrls.update,
    component: <MileageUpdateComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
];

export default mileageRoutesDefinition;
