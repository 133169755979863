import SelectOption from "../../../../../../../common/components/form/select/common/option/select-option";
import ContractMaintenancePolicy from "../../../../../../../common/types/contract-maintenance-policy";
import cargoTranslationsHelper from "../../../../../../../languages/cargo-translations.helper";

const createOptions = (): SelectOption<ContractMaintenancePolicy>[] => {
  const translations = cargoTranslationsHelper.getCompanyAddTranslations();

  return [
    {
      label: translations.contractMaintenancePolicy.basic,
      value: ContractMaintenancePolicy.BASIC,
    },
    {
      label: translations.contractMaintenancePolicy.auction,
      value: ContractMaintenancePolicy.AUCTION,
    },
  ];
};

const cargoCompanyContractMaintenancePolicySelectOptionFactory = {
  createOptions,
};

export default cargoCompanyContractMaintenancePolicySelectOptionFactory;
