import { FC } from "react";
import CardEditButtonComponent from "../../../../../../common/components/card/button/add/card-edit-button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../../common/context/sign-up-driver.context";
import SignUpDriverFormStep from "../../common/types/sign-up-driver-form-step";

type SignUpDriverSummaryAccountProps = {};

const SignUpDriverSummaryAccountComponent: FC<
  SignUpDriverSummaryAccountProps
> = () => {
  const accountDataSummaryDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().summary.accountData;

  const { accountData, setCurrentFormStep } = useSignUpDriverContext();

  const onEditButtonClick = () => {
    setCurrentFormStep(SignUpDriverFormStep.accountData);
  };

  return (
    <CardComponent
      header={{
        title: accountDataSummaryDataFormTranslations.title,
        actions: [
          <CardEditButtonComponent
            onClick={onEditButtonClick}
            title={accountDataSummaryDataFormTranslations.editButtonText}
          />,
        ],
      }}
    >
      <Row>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={accountDataSummaryDataFormTranslations.emailLabel}
            classNames={{ root: "mt-0" }}
          >
            {accountData.email}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={accountDataSummaryDataFormTranslations.usernameLabel}
            classNames={{ root: "mt-0" }}
          >
            {accountData.username}
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default SignUpDriverSummaryAccountComponent;
