import { FC } from "react";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsOriginalOrderPlanComponentProps = {
  tools: OrderDetailsToolsTranslations;
  isOrderSolved: boolean;
  isActive: boolean;
  onSuccess: () => void;
};

const OrderDetialsOriginalOrderPlanComponent: FC<
  OrderDetialsOriginalOrderPlanComponentProps
> = ({ tools, isOrderSolved, isActive, onSuccess }) => {
  if (!isOrderSolved) return null;

  const { hideOriginalOrderPlanButtonTitle, showOriginalOrderPlanButtonTitle } =
    tools;

  const title = isActive
    ? hideOriginalOrderPlanButtonTitle
    : showOriginalOrderPlanButtonTitle;

  return (
    <OrderDetailsToolButtonComponent
      icon={faFile}
      title={title}
      onClick={onSuccess}
      isActive={isActive}
    />
  );
};

export default OrderDetialsOriginalOrderPlanComponent;
