import GoogleMapsRouteWaypoint from "./google-maps-route-waypoint";

const createGoogleMapsRouteUrl = (mapWaypoints: GoogleMapsRouteWaypoint[]) => {
  const baseUrl = `https://www.google.com/maps/dir/?api=1`;
  const origin = `origin=${mapWaypoints[0].latitude},${mapWaypoints[0].longitude}`;
  const destination = `destination=${
    mapWaypoints[mapWaypoints.length - 1].latitude
  },${mapWaypoints[mapWaypoints.length - 1].longitude}`;
  const waypoints = mapWaypoints
    .slice(1, -1)
    .map((waypoint) => `${waypoint.latitude},${waypoint.longitude}`)
    .join("|");
  const travelMode = `travelmode=driving`;

  return `${baseUrl}&${origin}&${destination}&waypoints=${waypoints}&${travelMode}`;
};

const googleMapsRouteService = { createGoogleMapsRouteUrl };

export default googleMapsRouteService;
