import HttpResponse from "../../../../../../../common/utils/http/http.response";

export enum CargoAddressEditResponseItemAddressType {
  HOTEL = "HOTEL",
  STATION = "STATION",
  MEETING_POINT = "MEETING_POINT",
}

export type CargoAddressEditResponseItem = {
  modified_at: string | null;
  modified_by: string | null;
  created_at: string;
  created_by: string;
  lat: number;
  lon: number;
  country: string | null;
  town: string | null;
  zip_code: string | null;
  street: string | null;
  house_no: string | null;
  apartment: string | null;
  description: string | null;
  display_name: string;
  type: CargoAddressEditResponseItemAddressType;
};

type CargoAddressEditResponse = HttpResponse<CargoAddressEditResponseItem>;

export default CargoAddressEditResponse;
