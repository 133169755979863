import { FC, useEffect, useMemo, useState } from "react";
import { BillingsPlanEntryCargoTaxiItem } from "../../../../../../common/services/billings/plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi";
import { useAppContext } from "../../../../../../context/app.context";
import useBillingsPlanEntryCargoTaxi from "../../../../../../common/services/billings/plan-entry/cargo-taxi/use-billings-plan-entry-cargo-taxi";
import useAbort from "../../../../../../common/hooks/use-abort";
import CardComponent from "../../../../../../common/components/card/card.component";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import RelatedBillingsCargoTaxiTableRow from "../../../../common/related-billings/cargo-taxi/types/related-billings-cargo-taxi-table-row";
import realtedBillingsCargoTaxiFactory from "./related-billings-cargo-taxi.factory";
import RelatedBillingsCargoTaxiTableComponent from "../../../../common/related-billings/cargo-taxi/related-billings-cargo-taxi-table.component";

type RelatedBillingsCargoTaxiProps = {
  planEntryId: string | undefined;
};

const RelatedBillingsCargoTaxiComponent: FC<RelatedBillingsCargoTaxiProps> = (
  props
) => {
  const translations =
    billingsTranslationsHelper.getBillingsTranslations().relatedBillings
      .cargo_taxi;

  const [relatedBillingsCargoTaxiData, setRelatedBillingsCargoTaxiData] =
    useState<BillingsPlanEntryCargoTaxiItem[]>([]);

  const { selectedAppLanguage } = useAppContext();
  const cargoTaxi = useBillingsPlanEntryCargoTaxi();
  const cargoTaxiAbort = useAbort();

  const cargoTaxiData = async () => {
    cargoTaxi.load(
      { planEntryUuid: props.planEntryId! },
      cargoTaxiAbort.signal
    );
  };

  useEffect(() => {
    if (!props.planEntryId) return;

    cargoTaxiData();
  }, [props.planEntryId]);

  useEffect(() => {
    if (!cargoTaxi.data.data) return;

    setRelatedBillingsCargoTaxiData(cargoTaxi.data.data);
  }, [cargoTaxi.data.data]);

  const tableRows: RelatedBillingsCargoTaxiTableRow[] = useMemo(() => {
    if (!relatedBillingsCargoTaxiData) return [];

    return realtedBillingsCargoTaxiFactory.createTableRows(
      relatedBillingsCargoTaxiData
    );
  }, [relatedBillingsCargoTaxiData, selectedAppLanguage]);

  return (
    <CardComponent
      classNames={{ root: "related_order_billings" }}
      header={{ title: translations.summaryLabel }}
    >
      <RelatedBillingsCargoTaxiTableComponent
        tableRows={tableRows}
        isLoading={cargoTaxi.isLoading && !!props.planEntryId}
      />
    </CardComponent>
  );
};

export default RelatedBillingsCargoTaxiComponent;
