export enum MileageListLoadParamsOrder {
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  WORKER_NAME_ASC = "WORKER_NAME_ASC",
  WORKER_NAME_DESC = "WORKER_NAME_DESC",
  CARD_NO_ASC = "CARD_NO_ASC",
  CARD_NO_DESC = "CARD_NO_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  CARGO_COMPANY_NAME_ASC = "CARGO_COMPANY_NAME_ASC",
  CARGO_COMPANY_NAME_DESC = "CARGO_COMPANY_NAME_DESC",
}

export enum MileageListLoadParamsVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

type MileageListLoadParams = {
  page: number;
  pageSize: number;
  order?: MileageListLoadParamsOrder;
  companyId?: string;
  dateSince?: Date;
  cargoCompanyName?: string;
  dateTo?: Date;
  humanId?: number;
  mileageNumber?: string;
  workerName?: string;
  cardNumber?: string;
  address?: string[];
  startAddress?: string[];
  midAddress?: string[];
  endAddress?: string[];
  distanceMax?: number;
  distanceMin?: number;
  vehicleType?: MileageListLoadParamsVehicleType;
};

export default MileageListLoadParams;
