import { FC, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import mileageBreadcrumbsHelper from "../../common/breadcrumbs/mileage-breadcrumbs.helper";
import { useNavigate, useParams } from "react-router-dom";
import MileageDictionaryRecordAddRouteParams from "../../common/routes/types/mileage-dictionary-record-add-route-params";
import useCargoCompanyDetails from "../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";
import useAbort from "../../../../common/hooks/use-abort";
import useForm from "../../../../common/components/form/use-form";
import MileageDictionaryAddFormData from "./form/types/mileage-dictionary-add-form-data";
import mileageDictionaryAddFormHelper from "./form/mileage-dictionary-add-form.helper";
import notificationService from "../../../../common/utils/notification/notification.service";
import mileageRoutesHelper from "../../common/routes/mileage-routes.helper";
import MileageDictionaryAddParams from "../../../../common/services/mileage-dictionary/add/mileage-dictionary-add-params";
import mileageDictionaryService from "../../../../common/services/mileage-dictionary/mileage-dictionary.service";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import ButtonComponent from "../../../../common/components/button/button.component";

const MileageDictionaryAddComponent: FC = () => {
  const { cargoCompanyUuid } =
    useParams<MileageDictionaryRecordAddRouteParams>();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [isDictionaryRecordAddPending, setIsDictionaryRecordAddPending] =
    useState(false);

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();

  const mileageDictionaryRecordAddAbort = useAbort();
  const navigate = useNavigate();

  const form = useForm<MileageDictionaryAddFormData>({
    emptyValues: mileageDictionaryAddFormHelper.getDefaultFormData(),
    validationDefinition:
      mileageDictionaryAddFormHelper.getValidationDefinition(),
  });

  const translations =
    mileageTranslationsHelper.getMileageDictionaryTranslations().add;

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => cargoCompanyAbort.revoke();
  }, [cargoCompanyUuid]);

  const cargoCompanyName: string | undefined = useMemo(() => {
    if (!cargoCompany.data) return;

    return cargoCompany.data.displayName;
  }, [cargoCompany.data]);

  useEffect(() => {
    const breadcrumbs =
      mileageBreadcrumbsHelper.getMileageDictionaryAddRecordBreadcrumbs({
        cargoCompanyName: cargoCompanyName ?? "",
        cargoCompanyUuid: cargoCompanyUuid!,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoCompanyName]);

  const onMileageDictionaryAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigate(
      mileageRoutesHelper.getDictionaryRoute({
        cargoCompanyUuid: cargoCompanyUuid!,
      })
    );
  };

  const onMileageDictionaryAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const addDictionaryRecord = async () => {
    if (!cargoCompanyUuid) {
      return;
    }

    setIsDictionaryRecordAddPending(true);

    const params: MileageDictionaryAddParams = {
      cargoCompanyUuid: cargoCompanyUuid,
      name: form.values.name!,
    };

    try {
      await mileageDictionaryService.add(
        params,
        mileageDictionaryRecordAddAbort.signal
      );

      onMileageDictionaryAddSuccess();
    } catch {
      onMileageDictionaryAddFailure();
    } finally {
      setIsDictionaryRecordAddPending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) return;

    addDictionaryRecord();
  };

  return (
    <>
      <HeadingComponent title={translations.headingLabel} />
      <Row>
        <Column>
          <CardComponent>
            <Row>
              <Column>
                <FormFieldComponent
                  label={translations.form.nameLabel}
                  errorMessage={form.validationResults.name.errorMessage}
                >
                  <InputComponent
                    value={form.values.name}
                    onChange={(value) => {
                      form.setValue("name", value);
                    }}
                    placeholder={translations.form.namePlaceholder}
                    hasError={!!form.validationResults.name.errorMessage}
                    onBlur={() => {
                      form.validate("name");
                    }}
                    idForTesting="mileage-dictionary-add-name"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <ButtonComponent
              onClick={onSubmitButtonClick}
              type="primary"
              isDisabled={isDictionaryRecordAddPending}
              idForTesting="mileage-dictionary-add-sumbit-button"
              classNames={{ root: "mt-2" }}
              title={translations.submitButtonTitle}
            >
              {translations.submitButtonLabel}
            </ButtonComponent>
          </CardComponent>
        </Column>
      </Row>
    </>
  );
};

export default MileageDictionaryAddComponent;
