import { FC } from "react";
import DelegationDetailsRouteWaypoint from "../common/types/delegation-details-route-waypoint";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import delegationDetailsRouteHelper from "./delegation-details-route.helper";
import classNames from "classnames";
import { SteppedLineTo } from "react-lineto";

type DelegationDetailsRouteProps = {
  waypoints: DelegationDetailsRouteWaypoint[];
  delegationUuid: string;
  isManualDelegation: boolean;
};

const DelegationDetailsRouteComponent: FC<DelegationDetailsRouteProps> = (
  props
) => {
  const translations =
    delegationTranslationsHelper.getDelegationDetailsTranslations().routes;

  const lineConnections =
    delegationDetailsRouteHelper.getDefinitionOfLineConnectionsBetweenWaypointsInList(
      props.waypoints,
      props.delegationUuid
    );

  const placeClassNames = classNames(
    "delegation_details_route__place",
    props.isManualDelegation
      ? "delegation_details_route__place_manual"
      : "delegation_details_route__place_auto"
  );

  return (
    <div className="delegation_details_routes">
      <div>{translations.headingLabel}</div>
      <ul className="delegation_details_routes_list">
        <li className="delegation_details_route">
          <div className={placeClassNames}>{translations.placeHeaderLabel}</div>
          <div
            className="delegation_details_route__mileage_id"
            title={
              props.isManualDelegation
                ? translations.routeIdHeaderTitle
                : translations.mileageIdHeaderTitle
            }
          >
            {props.isManualDelegation
              ? translations.routeIdHeaderLabel
              : translations.mileageIdHeaderLabel}
          </div>
          {!props.isManualDelegation && (
            <div
              className="delegation_details_route__time"
              title={translations.timeHeaderTitle}
            >
              {translations.timeHeaderLabel}
            </div>
          )}
        </li>
      </ul>
      {props.waypoints.map((waypoint, index) => {
        return (
          <li
            key={`waypoint-${waypoint.place.lat}${waypoint.place.lon}-index-${index}`}
            className={classNames(
              "delegation_details_route",
              `delegation-uuid-${props.delegationUuid}`
            )}
          >
            <div className={placeClassNames}>
              <div className="delegation_details_route__place_index">
                {index + 1}
              </div>
              <div
                className={classNames(
                  "delegation_details_route__place_dot",
                  delegationDetailsRouteHelper.generateWaypointDotClassname(
                    waypoint,
                    index,
                    props.delegationUuid
                  )
                )}
              />
              <div title={waypoint.place.displayName}>
                {waypoint.place.displayName}
              </div>
            </div>
            <div className="delegation_details_route__mileage_id">
              {waypoint.stageNo}
            </div>
            {!props.isManualDelegation && (
              <div
                className="delegation_details_route__time"
                title={delegationDetailsRouteHelper.getDateLabel(waypoint.time)}
              >
                {delegationDetailsRouteHelper.getDateLabel(waypoint.time)}
              </div>
            )}
          </li>
        );
      })}
      {lineConnections.map((item, index) => {
        return (
          <SteppedLineTo
            key={`waypoint-list-connection-line-index-${index}`}
            from={item.itemFromClassname}
            to={item.itemToClassname}
            delay={50}
            borderStyle={item.lineType}
            borderWidth={1}
            borderColor={"#000"}
            within={`delegation_details_route delegation-uuid-${props.delegationUuid}`}
            className="delegation_details_route__connection_line"
          />
        );
      })}
    </div>
  );
};

export default DelegationDetailsRouteComponent;
