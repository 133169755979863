import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import TaxiUnsettledRouteListingByTaxiRouteQueryParams from "./common/types/taxi-unsettled-route-listing-by-taxi-route-query-params";
import TaxiUnsettledRouteListingItem from "../common/types/taxi-unsettled-route-listing-item";
import TaxiUnsettledRouteListingFilter, {
  TaxiUnsettledRouteListingRouteStartDateFilter,
} from "../common/types/taxi-unsettled-route-listing-filter";
import TaxiUnsettledRouteListingSortKey from "../common/types/taxi-unsettled-route-listing-sort-key";
import TaxiUnsettledRouteListingSortSelectOption from "../common/types/taxi-unsettled-route-listing-sort-select-option";
import taxiUnsettledRouteListingSortHelper from "../common/taxi-unsettled-route-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import taxiUnsettledRouteListingByTaxiRouteQueryParamsService from "./common/taxi-unsettled-route-listing-by-taxi-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import TaxiUnsettledRouteListingFiltersSelectComponent from "../common/filters/select/taxi-unsettled-route-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import TaxiUnsettledRouteListingFiltersBadgeListComponent from "../common/filters/list/taxi-unsettled-route-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import TaxiUnsettledRouteListingTableComponent from "../common/table/taxi-unsettled-route-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import TaxiUnsettledRouteListingViewBasicProps from "../common/types/taxi-unsettled-route-listing-view-basic-props";
import taxiUnsettledRouteListingByTaxiApiService from "./common/api/taxi-unsettled-route-listing-by-taxi-api.service";
import taxiUnsettledRouteListingByTaxiFactory from "./common/taxi-unsettled-route-listing-by-taxi.factory";
import TaxiUnsettledRouteListingFilterType from "../common/types/taxi-unsettled-route-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import TaxiUnsettledRouteListingResponse from "../common/api/taxi-unsettled-route-listing.response";
import taxiUnsettledRouteListingRequestFactory from "../common/taxi-unsettled-route-listing-request.factory";
import { useAppContext } from "../../../../../context/app.context";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";

type TaxiUnsettledRouteListingByTaxiProps =
  TaxiUnsettledRouteListingViewBasicProps;

const TaxiUnsettledRouteListingByTaxiComponent: FC<
  TaxiUnsettledRouteListingByTaxiProps
> = () => {
  const { user, selectedAppLanguage } = useAppContext();
  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiUnsettledRouteListingByTaxiRouteQueryParams>();

  const translations =
    settlementTranslationsHelper.getTaxiUnsettledRouteListingTranslations();

  const [filters, setFilters] = useState<TaxiUnsettledRouteListingFilter[]>(
    () =>
      taxiUnsettledRouteListingByTaxiRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    TaxiUnsettledRouteListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiUnsettledRouteListingSortKey | null>(
      () =>
        taxiUnsettledRouteListingByTaxiRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: TaxiUnsettledRouteListingSortSelectOption[] =
    useMemo(
      () => taxiUnsettledRouteListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      taxiUnsettledRouteListingByTaxiRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiUnsettledRouteListingByTaxiRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onListingFetchSuccess = (
    response: TaxiUnsettledRouteListingResponse
  ) => {
    const listingItems =
      taxiUnsettledRouteListingByTaxiFactory.createListingItems(
        response.data.data
      );

    setListingItems(listingItems);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiUnsettledRouteListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const taxiCorporationUuid = user?.aspects.taxiOfficer?.taxiCorporationUuid!;

    const request = taxiUnsettledRouteListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    taxiUnsettledRouteListingByTaxiApiService
      .fetchListing(taxiCorporationUuid, request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [filters, selectedSortKey, pageSize, page]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiUnsettledRouteListingByTaxiRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: TaxiUnsettledRouteListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey, selectedAppLanguage]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
  )?.value as
    | TaxiUnsettledRouteListingRouteStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
    );

    const newFilter: TaxiUnsettledRouteListingRouteStartDateFilter = {
      type: TaxiUnsettledRouteListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  return (
    <div className="taxi_unsettled_route_listing">
      <HeadingComponent title={translations.header.headingText} />
      <div className="taxi_unsettled_route_listing_tools">
        <div className="d-flex">
          <TaxiUnsettledRouteListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onOrderStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="taxi-unsettled-route-listing-by-taxi-date-range"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="taxi-unsettled-route-listing-by-taxi-sort"
        />
      </div>
      <TaxiUnsettledRouteListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TaxiUnsettledRouteListingTableComponent
          listingItems={listingItems}
          isError={isListingFetchingError}
          isLoading={isListingFetching}
        />
        <div className="taxi_unsettled_route_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default TaxiUnsettledRouteListingByTaxiComponent;