import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import MileageContractTemplate from "../../../../../common/services/mileage-contract/template/mileage-contract-template";
import mileageContractsAddFormValidationService from "../mileage-contract-add-form-validation.service";
import MileageContractAddFormData from "./types/mileage-contract-add-form-data";

const getEmptyFormData = (): MileageContractAddFormData => {
  return {
    distanceRateCompanyVehicle: 0.0,
    distanceRateCarGt900cm3: 1.15,
    distanceRateCarLe900cm3: 0.89,
    distanceRateMotorcycle: 0.69,
    distanceRateMoped: 0.42,
    dietFullRate: 45.0,
    dietFullAfterHours: 12,
    dietHalfRate: 22.5,
    dietHalfAfterHours: 8,
    periodOfValidity: null,
    disabled: false,
    companyUuid: "",
  };
};

const getValuesFromTemplate = (
  template: MileageContractTemplate | null
): MileageContractAddFormData => {
  if (!template) return getEmptyFormData();

  return {
    distanceRateCompanyVehicle: template.distanceRateCompanyVehicle,
    distanceRateCarGt900cm3: template.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: template.distanceRateCarLe900cm3,
    distanceRateMotorcycle: template.distanceRateMotorcycle,
    distanceRateMoped: template.distanceRateMoped,
    dietFullRate: template.dietFullRate,
    dietFullAfterHours: template.dietFullAfterHours,
    dietHalfRate: template.dietHalfRate,
    dietHalfAfterHours: template.dietHalfAfterHours,
    periodOfValidity: null,
    disabled: false,
    companyUuid: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<MileageContractAddFormData> => {
    return {
      distanceRateCompanyVehicle: (values) =>
        mileageContractsAddFormValidationService.validateDistanceRateCompanyVehicle(
          values.distanceRateCompanyVehicle
        ),
      distanceRateCarGt900cm3: (values) =>
        mileageContractsAddFormValidationService.validateDistanceRateCarGt900cm3(
          values.distanceRateCarGt900cm3
        ),
      distanceRateCarLe900cm3: (values) =>
        mileageContractsAddFormValidationService.validateDistanceRateCarLe900cm3(
          values.distanceRateCarLe900cm3
        ),
      distanceRateMotorcycle: (values) =>
        mileageContractsAddFormValidationService.validateDistanceRateMotorcycle(
          values.distanceRateMotorcycle
        ),
      distanceRateMoped: (values) =>
        mileageContractsAddFormValidationService.validateDistanceRateMoped(
          values.distanceRateMoped
        ),
      dietFullRate: (values) =>
        mileageContractsAddFormValidationService.validateDietFullRate(
          values.dietFullRate
        ),
      dietFullAfterHours: (values) =>
        mileageContractsAddFormValidationService.validateDietFullAfterHours(
          values.dietFullAfterHours,
          values.dietHalfAfterHours
        ),
      dietHalfRate: (values) =>
        mileageContractsAddFormValidationService.validateDietHalfRate(
          values.dietHalfRate
        ),
      dietHalfAfterHours: (values) =>
        mileageContractsAddFormValidationService.validateDietHalfAfterHours(
          values.dietHalfAfterHours,
          values.dietFullAfterHours
        ),
      disabled: (values) =>
        mileageContractsAddFormValidationService.validateDisabled(
          values.disabled
        ),
      companyUuid: (values) =>
        mileageContractsAddFormValidationService.validateCompanyUuid(
          values.companyUuid
        ),
      periodOfValidity: (values) =>
        mileageContractsAddFormValidationService.validatePeriodOfValidity(
          values.periodOfValidity
        ),
    };
  };

const mileageContractAddFormHelper = {
  getEmptyFormData,
  getValuesFromTemplate,
  getValidationDefinition,
};

export default mileageContractAddFormHelper;
