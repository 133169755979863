import { isEqual, round } from "lodash";
import mapMarkerIconFactory from "../../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../common/components/map/types/map-route";
import DelegationRoadRouteItem from "../../../../../common/services/delegation/road-route/delegation-road-route-item";
import DelegationRoadRouteLoadParams, {
  DelegationRoadRouteWaypoint,
} from "../../../../../common/services/delegation/road-route/delegation-road-route-load-params";
import uuidService from "../../../../../common/utils/uuid/uuid.service";
import DelegationAddAddress from "../types/delegation-add-address";
import DelegationAddRouteWaypoint from "../types/delegation-add-route-waypoint";
import delegationAddHelper from "./delegation-add-helper";
import DelegationAddRouteItem from "../types/delegation-add-route-item";
import delegationAddAddressFactory from "./delegation-add-address.factory";

const createMapMarker = (
  waypoint: DelegationAddRouteWaypoint,
  content?: string
) => {
  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: content,
  });

  const newMapMarker: MapMarker = {
    coordinate: {
      latitude: waypoint.place.latitude,
      longitude: waypoint.place.longitude,
    },
    title: waypoint.place.displayName,
    tooltip: waypoint.place.displayName,
    icon: markerIcon,
  };

  return newMapMarker;
};

const createMapMarkers = (
  waypoints: DelegationAddRouteWaypoint[]
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  for (const waypoint of waypoints) {
    const allWaypointsOnThisPlace = waypoints
      .map((waypoint, waypointIndex) => ({
        place: waypoint.place,
        index: waypointIndex,
      }))
      .filter((w) => isEqual(w.place, waypoint.place));

    const signature = allWaypointsOnThisPlace.map((w) => w.index + 1).join("/");

    const newMarker: MapMarker = createMapMarker(waypoint, signature);

    mapMarkers = [...mapMarkers, newMarker];
  }

  return mapMarkers;
};

const createMapRoute = (data: DelegationRoadRouteItem): MapRoute => {
  return {
    waypoints: data.coordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
  };
};

const createWaypoint = (
  address: DelegationAddAddress,
  groupId: number
): DelegationAddRouteWaypoint => {
  return {
    group: groupId + 1,
    distance: address.distance ?? 0,
    time: address.time,
    place: {
      displayName: address.displayName,
      latitude: address.latitude,
      longitude: address.longitude,
    },
    uuid: uuidService.generate(),
  };
};

const createWaypoints = (
  addresses: DelegationAddAddress[],
  groupId: number
): DelegationAddRouteWaypoint[] => {
  return addresses.map((address) => createWaypoint(address, groupId));
};

const createSearchRoutingCoordinate = (
  waypoint: DelegationAddRouteWaypoint
): DelegationRoadRouteWaypoint => {
  return {
    lat: waypoint.place.latitude,
    lon: waypoint.place.longitude,
  };
};

const createSearchRoutingRequest = (
  waypoints: DelegationAddRouteWaypoint[]
): DelegationRoadRouteLoadParams | null => {
  if (waypoints.length < 2) return null;

  const request: DelegationRoadRouteLoadParams = {
    waypoints: waypoints.map(createSearchRoutingCoordinate),
  };

  return request;
};

const createNewRoutes = (
  routes: DelegationAddRouteItem[],
  startDate: Date | null,
  roadRouteData: DelegationRoadRouteItem | null
) => {
  if (!roadRouteData) return routes;

  const newRoutes = [...routes];

  const numberOfLegs = roadRouteData.legs.length;
  let legIndex = 0;
  let previousDate = startDate ?? undefined;

  newRoutes.forEach((route, routeIndex) => {
    if (routeIndex == 0) {
      if (route.address) {
        route.address.time = previousDate;
        route.address.distance = 0;
      }
    } else {
      if (legIndex < numberOfLegs && route.address) {
        if (previousDate) {
          const currentDate = delegationAddHelper.calculateDate(
            previousDate,
            roadRouteData.legs[legIndex].duration
          );

          route.address.time = currentDate;
          previousDate = currentDate;
        }

        route.address.distance = round(
          roadRouteData.legs[legIndex].distance / 1000,
          2
        );

        legIndex += 1;
      }
    }
  });

  return newRoutes;
};

const createNewWaypoint = (
  value: DelegationAddRouteItem[],
  groupIndex: number
) => {
  const addresses =
    delegationAddAddressFactory.createAddressesFromRouteItems(value);

  return createWaypoints(addresses, groupIndex);
};

const delegationAddRouteFactory = {
  createMapMarkers,
  createMapRoute,
  createWaypoints,
  createSearchRoutingRequest,
  createNewRoutes,
  createNewWaypoint,
};

export default delegationAddRouteFactory;
