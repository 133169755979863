import { CargoCompanyAddressListItem } from "../../../../../common/services/cargo-company/address/list/cargo-company-address-list";
import MileageAddressListItem from "../../../../../common/services/mileage-address/list/mileage-address-list";
import delegationTranslationsHelper from "../../../../../languages/delegation-translations.helper";
import DelegationAddAddress from "../types/delegation-add-address";
import DelegationAddAddressSelectOption from "../types/delegation-add-address-select-option";
import DelegationAddRouteGroup from "../types/delegation-add-route-group";
import DelegationAddRouteItem from "../types/delegation-add-route-item";
import DelegationAddWorker from "../types/delegation-add-worker";

const createAdressesFromRouteGroups = (
  groups: DelegationAddRouteGroup[]
): DelegationAddAddress[][] => {
  const seq: DelegationAddAddress[][] = [];

  groups.forEach((groups) => {
    if (groups.routes.length >= 2) {
      seq.push(createAddressesFromRouteItems(groups.routes));
    }
  });

  return seq;
};

const createAddressesFromRouteItems = (
  routeItems: DelegationAddRouteItem[]
): DelegationAddAddress[] => {
  const seq: DelegationAddAddress[] = [];

  routeItems.forEach((routeItem) => {
    if (routeItem.address) {
      seq.push(routeItem.address);
    }
  });

  return seq;
};

const createAddress = (item: MileageAddressListItem): DelegationAddAddress => {
  return {
    displayName: item.displayName,
    latitude: item.lat,
    longitude: item.lon,
    apartmentNumber: item.apartment,
    houseNumber: item.houseNo,
    street: item.street,
    town: item.town,
    zipCode: item.zipCode,
  };
};

const createSelectOption = (
  address: DelegationAddAddress
): DelegationAddAddressSelectOption => {
  return {
    label: address.displayName,
    value: address,
    subText: address.subText,
  };
};

const createSelectOptions = (
  item: MileageAddressListItem
): DelegationAddAddressSelectOption => {
  const address: DelegationAddAddress = createAddress(item);
  return createSelectOption(address);
};

const createPassengerAddresses = (
  worker: DelegationAddWorker | null
): DelegationAddAddress[] => {
  if (!worker) {
    return [];
  }

  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().workers;

  const passengerSubText = `${translations.passengerAddressLabel}: ${worker.displayName}`;

  const addresses = [...worker.addresses];

  addresses.forEach((address) => {
    address.subText = passengerSubText;
  });

  return addresses;
};
const createAddressFromCargoAddress = (
  item: CargoCompanyAddressListItem
): DelegationAddAddress => {
  return {
    displayName: item.displayName,
    latitude: item.lat,
    longitude: item.lon,
    town: item.town,
    apartmentNumber: item.apartment,
    houseNumber: item.houseNumber,
    street: item.street,
    zipCode: item.zipCode,
  };
};

const delegationAddAddressFactory = {
  createSelectOptions,
  createSelectOption,
  createAddressesFromRouteItems,
  createAdressesFromRouteGroups,
  createPassengerAddresses,
  createAddressFromCargoAddress,
};

export default delegationAddAddressFactory;
