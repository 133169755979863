import useAsyncData from "../../../hooks/use-async-data";
import CargoOrderDetailsComplex from "./cargo-order-details-complex";
import CargoOrderDetailsComplexLoadParams from "./cargo-order-details-complex-load-params";
import cargoOrderService from "../cargo-order.service";

const useCargoOrderDetailsComplex = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<CargoOrderDetailsComplex | null>(null);

  const load = async (
    params: CargoOrderDetailsComplexLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoOrderDetails = await cargoOrderService.getDetailsComplex(
        params,
        signal
      );

      setData(cargoOrderDetails);
      setIsLoading(false);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useCargoOrderDetailsComplex;
