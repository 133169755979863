import authValidationService from "../../../common/validation/auth-validation.service";

const signUpPassengerAccountDataFormValidationService = {
  validateEmail: authValidationService.validateEmail,
  validateUsername: authValidationService.validateUsername,
  validatePassword: authValidationService.validatePassword,
  validatePasswordRepeat: authValidationService.validatePasswordRepeat,
};

export default signUpPassengerAccountDataFormValidationService;
