import useAsyncData from "../../../hooks/use-async-data";
import mileageSettingsService from "../mileage-settings.service";
import MileageSettingsDetails from "./mileage-settings-details";
import MileageSettingsDetailsLoadParams from "./mileage-settings-details-load-params";

const useMileageSettingsDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MileageSettingsDetails | null>(null);

  const load = async (
    params: MileageSettingsDetailsLoadParams,
    abortSignal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const details = await mileageSettingsService.details(params, abortSignal);
      setData(details);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useMileageSettingsDetails;
