import PassengerListRequest, {
  PassengerListRequestQueryParams,
} from "./passenger-list.request";

const createQueryParams = (
  data: PassengerListRequest
): PassengerListRequestQueryParams => {
  return {
    cargoCompany: data.cargoCompany,
    cargoCompanyId: data.cargoCompanyId,
    dispatch: data.dispatch,
    dispatchId: data.dispatchId,
    firstName: data.firstName,
    lastName: data.lastName,
    limit: data.limit,
    offset: data.offset,
    order: data.order,
    statusCriteria: data.statusCriteria,
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
    email: data.email,
    username: data.username,
    address: data.address,
  };
};

const passengerListRequestFactory = {
  createQueryParams,
};

export default passengerListRequestFactory;
