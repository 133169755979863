import appTranslationsHelper from "./app-translations.helper";
import MileageListingTranslations from "./types/mileage/mileage-lisitng.translations";
import MileageAddTranslations from "./types/mileage/mileage-add.translations";
import MileageContractListingTranslations from "./types/mileage/mileage-contract-listing.translations";
import MileageContractAddTranslations from "./types/mileage/mileage-contract-add.translations";
import MileageDictionaryTranslations from "./types/mileage/mileage-dictionary.translations";
import MileageOptionsTranslations from "./types/mileage/mileage-settings.translations";
import MileageContractUpdateTranslations from "./types/mileage/mileage-contract-update.translations";
import MileageDetailsTranslations from "./types/mileage/mileage-details.translations";
import MileageUpdateTranslations from "./types/mileage/mileage-update.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `mileage`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getMileageListingTranslations = (): MileageListingTranslations => {
  const translations = getTranslations<MileageListingTranslations>(
    `mileage-listing.translations.json`
  );

  return translations;
};

const getMileageAddTranslations = (): MileageAddTranslations => {
  const translations = getTranslations<MileageAddTranslations>(
    `mileage-add.translations.json`
  );

  return translations;
};

const getMileageContractListingTranslations =
  (): MileageContractListingTranslations => {
    const translations = getTranslations<MileageContractListingTranslations>(
      `mileage-contract-listing.translations.json`
    );

    return translations;
  };

const getMileageContractAddTranslations =
  (): MileageContractAddTranslations => {
    const translations = getTranslations<MileageContractAddTranslations>(
      `mileage-contract-add.translations.json`
    );

    return translations;
  };

const getMileageContractUpdateTranslations =
  (): MileageContractUpdateTranslations => {
    const translations = getTranslations<MileageContractUpdateTranslations>(
      `mileage-contract-update.translations.json`
    );

    return translations;
  };

const getMileageDictionaryTranslations = (): MileageDictionaryTranslations => {
  const translations = getTranslations<MileageDictionaryTranslations>(
    `mileage-dictionary.translations.json`
  );

  return translations;
};

const getMileageSettingsTranslations = (): MileageOptionsTranslations => {
  const translations = getTranslations<MileageOptionsTranslations>(
    `mileage-settings.translations.json`
  );

  return translations;
};

const getMileageDetailsTranslations = (): MileageDetailsTranslations => {
  const translations = getTranslations<MileageDetailsTranslations>(
    `mileage-details.translations.json`
  );

  return translations;
};

const getMileageUpdateTranslations = (): MileageUpdateTranslations => {
  const translations = getTranslations<MileageUpdateTranslations>(
    `mileage-update.translations.json`
  );

  return translations;
};

const mileageTranslationsHelper = {
  getMileageContractListingTranslations,
  getMileageContractAddTranslations,
  getMileageContractUpdateTranslations,
  getMileageListingTranslations,
  getMileageAddTranslations,
  getMileageDictionaryTranslations,
  getMileageSettingsTranslations,
  getMileageDetailsTranslations,
  getMileageUpdateTranslations,
};

export default mileageTranslationsHelper;
