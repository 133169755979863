enum TaxiUnsettledRouteListingFilterType {
  ORDER_START_DATE = "ROUTE_START_DATE",
  ROUTE_ID = "ROUTE_ID",
  DRIVER = "DRIVER",
  CLIENT = "CLIENT",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  ROUTE_DESTINATION_ADDRESS = "ROUTE_DESTINATION_ADDRESS",
  ROUTE_INTERMEDIATE_ADDRESS = "ROUTE_INTERMEDIATE_ADDRESS",
  ROUTE_PICKUP_ADDRESS = "ROUTE_PICKUP_ADDRESS",
  ORDER_INTERNAL_ID = "ORDER_INTERNAL_ID",
  FLEET_PARTNER = "FLEET_PARTNER",
}

export default TaxiUnsettledRouteListingFilterType;
