export enum DelegationListItemStatus {
  UNDOWNLOADED = "UNDOWNLOADED",
  DOWNLOADED = "DOWNLOADED",
  DELETED = "DELETED",
}

export enum DelegationListItemType {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
}

export type DelegationListItem = {
  uuid: string;
  createdAt: Date;
  createdBy: string;
  cargoCompanyName: string;
  downloadedCsvAt?: Date | null;
  downloadedCsvBy?: string | null;
  downloadedPdfAt?: Date | null;
  downloadedPdfBy?: string | null;
  status: DelegationListItemStatus;
  delegationNumber: string;
  type: DelegationListItemType;
  workerName: string;
  dateFrom: Date;
  dateTo: Date;
  creationDate: Date;
  totalCost: number;
  totalDistance: number;
  humanId: number;
};

type DelegationList = {
  data: DelegationListItem[];
  totalCount: number;
};

export default DelegationList;
