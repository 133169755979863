import { SearchAddressResponseItem } from "../../../../../../../../../common/utils/search-address/search-address.response";
import SignUpDriverUserTemporaryAddress from "../../../../../../common/types/sign-up-address-proposition";
import SignUpAddressSelectOption from "../../../../../../common/types/sign-up-address-select-option";

const createAddress = (
  searchAddressResponseItem: SearchAddressResponseItem
): SignUpDriverUserTemporaryAddress => {
  const address: SignUpDriverUserTemporaryAddress = {
    displayName: searchAddressResponseItem.display_name,
    apartmentNumber: "",
    country: searchAddressResponseItem.address.country,
    countryCode: searchAddressResponseItem.address.country_code,
    houseNumber: searchAddressResponseItem.address.house_number ?? null,
    latitude: Number(searchAddressResponseItem.lat),
    longitude: Number(searchAddressResponseItem.lon),
    street: searchAddressResponseItem.address.road ?? null,
    town:
      searchAddressResponseItem.address.city ??
      searchAddressResponseItem.address.town ??
      searchAddressResponseItem.address.village ??
      null,
    zipCode: searchAddressResponseItem.address.postcode ?? null,
  };

  return address;
};

const createAddressSelectOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): SignUpAddressSelectOption => {
  const address = createAddress(searchAddressResponseItem);

  return {
    label: searchAddressResponseItem.display_name,
    value: address,
  };
};

const createAddressSelectOptions = (
  searchAddressResponseItems: SearchAddressResponseItem[]
): SignUpAddressSelectOption[] => {
  return searchAddressResponseItems.map(createAddressSelectOption);
};

const signUpDriverUserAddressAddFactory = {
  createAddress,
  createAddressSelectOptions,
};

export default signUpDriverUserAddressAddFactory;
