import userTranslationsHelper from "../../../../../languages/user-translations.helper";

const getHeadingLabel = (dispatcherName: string): string => {
  const translations = userTranslationsHelper.getDispatcherEditTranslations();

  if (!dispatcherName) {
    return translations.header.headingLabel;
  }
  return translations.header.headingLabelWithParams.replace(
    "#{dispatcherName}",
    dispatcherName
  );
};

const dispatcherEditHelper = {
  getHeadingLabel,
};

export default dispatcherEditHelper;
