import classNames from "classnames";
import { FC, useMemo } from "react";
import { SteppedLineTo } from "react-lineto";
import mileageTranslationsHelper from "../../../../../../../languages/mileage-translations.helper";
import mileageUpdateSummaryRouteHelper from "./factory/mileage-update-summary-route.helper";
import MileageUpdateSummaryRouteWaypoint from "./types/mileage-update-summary-route-waypoint";

type MileageUpdateSummaryRouteProps = {
  waypoints: MileageUpdateSummaryRouteWaypoint[];
};

const MileageUpdateSummaryRouteComponent: FC<MileageUpdateSummaryRouteProps> = (
  props
) => {
  const filteredWaypoints = useMemo(() => {
    return props.waypoints.filter((worker) => worker.uuid !== null);
  }, [props.waypoints]);

  const translations =
    mileageTranslationsHelper.getMileageUpdateTranslations().summary.routes;

  const lineConnections =
    mileageUpdateSummaryRouteHelper.getDefinitionOfLineConnectionsBetweenWaypoints(
      filteredWaypoints
    );

  return (
    <div
      className="mileage_update_summary_route"
      data-test-id="mileage-update-summary-route"
    >
      <div className="mileage_update_summary_route_waypoint_list">
        <div className="mileage_update_summary_route_waypoint_list__header">
          <div
            className="mileage_update_summary_route_waypoint_list__heading place"
            title={translations.placeHeaderTitle}
          >
            {translations.placeHeaderLabel}
          </div>
          <div
            className="mileage_update_summary_route_waypoint_list__heading distance"
            title={translations.distanceHeaderTitle}
          >
            {translations.distanceHeaderLabel}
          </div>
          <div
            className="mileage_update_summary_route_waypoint_list__heading time"
            title={translations.timeHeaderTitle}
          >
            {translations.timeHeaderLabel}
          </div>
        </div>
        {filteredWaypoints.map((waypoint, index) => {
          const isFirstWaypoint = index === 0;
          const isLastWaypoint = index === filteredWaypoints.length - 1;

          const isFaded = !isFirstWaypoint && !isLastWaypoint;

          return (
            <div
              className={classNames(
                "mileage_update_summary_route_waypoint_list__row",
                isFirstWaypoint && "first_highlight",
                isLastWaypoint && "last_highlight",
                isFaded && "faded"
              )}
              key={`mileage-update-summary-route-waypoint-index-${index}`}
              data-test-id={`mileage-update-summary-route-waypoint-index-${index}`}
            >
              <div
                className="mileage_update_summary_route_waypoint_list__row_cell place"
                title={mileageUpdateSummaryRouteHelper.getPlaceTitle(
                  waypoint.place
                )}
              >
                <div className="index">{index + 1}</div>
                <div
                  className={classNames(
                    "dot",
                    mileageUpdateSummaryRouteHelper.generateWaypointDotClassname(
                      waypoint
                    )
                  )}
                ></div>
                <div className="place_name">
                  {mileageUpdateSummaryRouteHelper.getPlaceLabel(
                    waypoint.place
                  )}
                </div>
              </div>
              <div className="mileage_update_summary_route_waypoint_list__row_cell distance">
                {mileageUpdateSummaryRouteHelper.getDistanceLabel(
                  waypoint.distance
                )}
              </div>
              <div className="mileage_update_summary_route_waypoint_list__row_cell time">
                {mileageUpdateSummaryRouteHelper.getTimeLabel(waypoint.time)}
              </div>
            </div>
          );
        })}
      </div>
      {lineConnections.map((item, index) => {
        return (
          <SteppedLineTo
            key={`waypoint-list-connection-line-index-${index}-type-${item.lineType}`}
            from={item.itemFromClassname}
            to={item.itemToClassname}
            delay={50}
            borderStyle={item.lineType}
            borderWidth={1}
            borderColor={"#000"}
            within={`mileage_update_summary_route`}
            className="mileage_update_summary__connection_line"
          />
        );
      })}
    </div>
  );
};

export default MileageUpdateSummaryRouteComponent;
