import { FC } from "react";
import CargoCompanyListingFilter from "../common/types/cargo-company-listing-filter";
import CargoCompanyListingSortKey from "../common/types/cargo-company-listing-sort-key";
import ListingFilterDefinition from "../../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../../../common/components/listing/filter/listing-filter.component";
import cargoCompanyListingFilterHelper from "./cargo-company-listing-filter.helper";

type CargoCompanyListingFilterProps = {
  filters: CargoCompanyListingFilter[];
  onFiltersChange: (filters: CargoCompanyListingFilter[]) => void;
  sortKey: CargoCompanyListingSortKey;
  onSortKeyChange: (sortKey: CargoCompanyListingSortKey) => void;
};

const CargoCompanyListingFilterComponent: FC<CargoCompanyListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<CargoCompanyListingFilter> =
    cargoCompanyListingFilterHelper().getFilterDefinition();
  const sortDefinition: ListingSortDefinition =
    cargoCompanyListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as CargoCompanyListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as CargoCompanyListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="cargo-company-listing-filter"
      idForTestingSort="cargo-company-listing-sort"
    />
  );
};

export default CargoCompanyListingFilterComponent;
