import dateService from "../../../../../../../../common/utils/date/date.service";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
    .changeTime;

const getNewPlannedDateLabel = (newPlannedDate: Date | null) => {
  const formattedDate = newPlannedDate
    ? dateService.formatDateTime(newPlannedDate)
    : `-----`;

  return translations.newDateTemplateText.replace(
    "#{plannedDateString}",
    formattedDate
  );
};

const getNewPlannedDateTitle = (newPlannedDate: Date | null) => {
  const formattedDate = newPlannedDate
    ? dateService.formatDateTime(newPlannedDate)
    : `-----`;

  return translations.newDateTemplateText.replace(
    "#{plannedDateString}",
    formattedDate
  );
};

const orderDetailsHistoryEntryChangeRideContentChangeTimeHelper = {
  getNewPlannedDateLabel,
  getNewPlannedDateTitle,
};

export default orderDetailsHistoryEntryChangeRideContentChangeTimeHelper;
