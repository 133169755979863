import UserRole from "../../../../../common/types/user-role";
import userPermissionsService from "../../../../../common/utils/user/permissions/user-permissions.service";
import orderDetailsUserPermissionDefinition, {
  OrderDetailsUserPermissions as OrderDetailsUserPermissions,
} from "./order-details-user-permission";

const getPermissions = (userRoles: UserRole[]): OrderDetailsUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    orderDetailsUserPermissionDefinition
  );

  return permissions;
};

const orderDetailsUserPermissionsHelper = {
  getPermissions,
};

export default orderDetailsUserPermissionsHelper;
