import railyApiService from "../../raily-api.service";
import CargoCompanyDispatcherListRequest from "./list/cargo-company-dispatcher-list.request";
import CargoCompanyDispatcherListResponse from "./list/cargo-company-dispatcher-list.response";

const cargoCompanyDispatcherApiService = () => {
  const getDisaptcherList = async (
    request: CargoCompanyDispatcherListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyDispatcherListResponse> => {
    const url = `/cargo-companies/${request.companyUuid}/dispatchers`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  return {
    getDisaptcherList,
  };
};

export default cargoCompanyDispatcherApiService;
