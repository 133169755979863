import CargoCompanyAddressListRequest, {
  CargoCompanyAddressListQueryParams,
} from "./cargo-company-address-list.request";

const createQueryParams = (
  request: CargoCompanyAddressListRequest
): CargoCompanyAddressListQueryParams => {
  return {
    searchQuery: request.searchQuery,
  };
};

const cargoCompanyAddressListRequestFactory = {
  createQueryParams,
};

export default cargoCompanyAddressListRequestFactory;
