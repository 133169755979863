import { FC, useEffect, useRef, useState } from "react";
import { PlanningOrderSelectedItemData } from "../../common/types/planning-order-selected-data";
import { PlanningOrderDriverPlanListingSelectedItemToSent } from "../types/planner-order-driver-plan-listing-selected-item";
import { DriverPlanCandidaturePreviewCandidatureEntriesRequestData } from "../../../../../common/services/driver-plan/preview/driver-plan-candidature-preview";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import PlanningOrderDriverPlanListingSelectedItemHeaderComponent from "./planning-order-driver-plan-listing-selected-item-header";
import planningOrderDriverPlanListingSelectedItemFactory from "./planning-order-driver-plan-listing-selected-item.factory";
import DriverPlanCandidaturePreviewLoadParams from "../../../../../common/services/driver-plan/preview/driver-plan-candidature-preview-load-params";
import useAbort from "../../../../../common/hooks/use-abort";
import useDriverPlanCandidaturePreview from "../../../../../common/services/driver-plan/preview/use-driver-plan-candidature-preview";
import PlanningOrderDriverPlanListingSelectedItemChartComponent from "./planning-order-driver-plan-listing-selected-item-chart.component";
import PlanningOrderDriverPlanListingSelectedItemChartData from "../types/planner-order-driver-plan-listing-selected-item-chart-data";
import {
  CandidatureAddFailure,
  CandidatureAddSucess,
  ErrorContent,
  LoaderContent,
  OverfloatedLoaderContent,
} from "./planning-order-driver-plan-listing-selected-item-status.component";

type PlanningOrderDriverPlanListingSelectedItemProps = {
  item: PlanningOrderSelectedItemData;
  onClick: (value: number) => void;
  orderUuid: string | undefined;
  onMark: (value: number) => void;
  onUnMark: (value: number) => void;
  itemToSent: PlanningOrderDriverPlanListingSelectedItemToSent;
  onSuccess: (
    value: DriverPlanCandidaturePreviewCandidatureEntriesRequestData
  ) => void;
};

const PlanningOrderDriverPlanListingSelectedItemComponent: FC<
  PlanningOrderDriverPlanListingSelectedItemProps
> = (props) => {
  const [shouldItemBeSent, setShouldItemBeSent] = useState(false);

  const isCandidatureSentSuccess = props.itemToSent?.isSuccess;
  const isCandidatureSentErrorMessage = props.itemToSent?.errorMessage;
  const isCandidatureSentPending = props.itemToSent?.isPending;
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const id = props.item.id;
    if (shouldItemBeSent) {
      props.onMark(id);
      return;
    }
    props.onUnMark(id);
  }, [shouldItemBeSent]);

  const driverPlanCandidaturePreviewAbort = useAbort();
  const driverPlanCandidaturePreview = useDriverPlanCandidaturePreview();

  const isPlanEntryUuid = props.item.planEntry?.uuid;

  useEffect(() => {
    if (!props.orderUuid) return;

    const params: DriverPlanCandidaturePreviewLoadParams =
      planningOrderDriverPlanListingSelectedItemFactory.createParams(
        props.orderUuid!,
        isPlanEntryUuid ? undefined : props.item.taxiDriverAssociationUuid,
        isPlanEntryUuid ? props.item.planEntry! : undefined
      );

    driverPlanCandidaturePreview.load(
      params,
      driverPlanCandidaturePreviewAbort.signal
    );
  }, [props.orderUuid, props.item]);

  useEffect(() => {
    if (!driverPlanCandidaturePreview.data || !props.onSuccess) return;

    props.onSuccess(
      driverPlanCandidaturePreview.data.candidatureEntriesRequestData
    );
  }, [driverPlanCandidaturePreview]);

  useEffect(() => {
    if (!driverPlanCandidaturePreview.isError) return;

    props.onClick(listingItemData.user.id);
  }, [driverPlanCandidaturePreview.isError]);

  const listingItemData: PlanningOrderDriverPlanListingSelectedItemChartData =
    planningOrderDriverPlanListingSelectedItemFactory.createListingItem(
      props.item,
      driverPlanCandidaturePreview.data!
    );

  let candidatureStatusComponent = null;
  if (isCandidatureSentPending) {
    candidatureStatusComponent = <OverfloatedLoaderContent />;
  } else if (isCandidatureSentSuccess) {
    candidatureStatusComponent = <CandidatureAddSucess />;
  } else if (!isCandidatureSentSuccess && isCandidatureSentErrorMessage) {
    candidatureStatusComponent = (
      <CandidatureAddFailure message={isCandidatureSentErrorMessage} />
    );
  }

  const ChartContent = (
    <>
      {candidatureStatusComponent}
      <PlanningOrderDriverPlanListingSelectedItemChartComponent
        item={listingItemData}
        parentRef={chartRef}
      />
    </>
  );

  return (
    <div className="planning-order-driver-plan-listing-selected">
      <Row>
        <Column>
          <PlanningOrderDriverPlanListingSelectedItemHeaderComponent
            user={listingItemData.user}
            onClick={props.onClick}
            onMark={setShouldItemBeSent}
            shouldItemBeSent={shouldItemBeSent}
            isCandidatureAdded={isCandidatureSentSuccess!}
          />

          <div
            ref={chartRef}
            className="planning-order-driver-plan-listing-selected__chart"
          >
            {driverPlanCandidaturePreview.isLoading ? (
              <LoaderContent />
            ) : driverPlanCandidaturePreview.isError ? (
              <ErrorContent />
            ) : (
              ChartContent
            )}
          </div>
        </Column>
      </Row>
    </div>
  );
};

export default PlanningOrderDriverPlanListingSelectedItemComponent;
