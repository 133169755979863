import isEqual from "lodash/isEqual";
import mapMarkerIconFactory from "../../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import DelegationDetailsRouteWaypoint from "../types/delegation-details-route-waypoint";

const createMapMarker = (
  waypoint: DelegationDetailsRouteWaypoint,
  content?: string
): MapMarker => {
  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker default",
    content: content,
  });

  return {
    coordinate: {
      latitude: waypoint.place.lat,
      longitude: waypoint.place.lon,
    },
    title: waypoint.place.displayName,
    tooltip: waypoint.place.displayName,
    icon: markerIcon,
  };
};

const create = (waypoints: DelegationDetailsRouteWaypoint[]): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  for (const waypoint of waypoints) {
    const allWaypointsOnThisPlace = waypoints
      .map((waypoint, waypointIndex) => ({
        place: waypoint.place,
        index: waypointIndex,
      }))
      .filter((w) => isEqual(w.place, waypoint.place));

    const signature = allWaypointsOnThisPlace.map((w) => w.index + 1).join("/");

    const newMarker: MapMarker = createMapMarker(waypoint, signature);

    mapMarkers = [...mapMarkers, newMarker];
  }

  return mapMarkers;
};

const delegationDetailsMapMarkersFactory = {
  create,
};

export default delegationDetailsMapMarkersFactory;
