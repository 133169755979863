import BillingNode from "./types/billing-node";

export enum BillingRecalculateRequestContributionType {
  BASE_AMOUNT = "BASE_AMOUNT",
  CHARGE_HALTINGS = "CHARGE_HALTING",
  CHARGE_HIGHWAYS = "CHARGE_HIGHWAY",
  DISCOUNT_OPTIMIZATION = "DISCOUNT_OPTIMIZATION",
  DISCOUNT_BEING_LATE = "DISCOUNT_BEING_LATE",
  DISCOUNT_INCOMPATIBLE_CAR = "DISCOUNT_INCOMPATIBLE_CAR",
  DISCOUNT_OTHER = "DISCOUNT_OTHER",
  CONTRACT_CORRECTION_PERCENTAGE = "CONTRACT_CORRECTION_PERCENTAGE",
}

export type BillingRecalculateRequestDiscountContributionContractCorrectionPercentage =
  {
    type: BillingRecalculateRequestContributionType.CONTRACT_CORRECTION_PERCENTAGE;
    percentage: number;
    amount: number;
  };

export type BillingRecalculateRequestDiscountContributionOptimization = {
  type: BillingRecalculateRequestContributionType.DISCOUNT_OPTIMIZATION;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestDiscountContributionBeingLate = {
  type: BillingRecalculateRequestContributionType.DISCOUNT_BEING_LATE;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestDiscountContributionIncompatibleCar = {
  type: BillingRecalculateRequestContributionType.DISCOUNT_INCOMPATIBLE_CAR;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestDiscountContributionOther = {
  type: BillingRecalculateRequestContributionType.DISCOUNT_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestContribution =
  | BillingRecalculateRequestDiscountContributionContractCorrectionPercentage
  | BillingRecalculateRequestDiscountContributionOptimization
  | BillingRecalculateRequestDiscountContributionBeingLate
  | BillingRecalculateRequestDiscountContributionIncompatibleCar
  | BillingRecalculateRequestDiscountContributionOther;

type BillingRecalculateRequest = {
  billing_nodes: BillingNode[];
  billing_contributions: BillingRecalculateRequestContribution[];
  contract_correction_percentage: number;
};

export default BillingRecalculateRequest;
