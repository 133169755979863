import { useEffect, useRef } from "react";

const useHorizontalScrollOnWheel = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.addEventListener("wheel", onWheelChartScroll);
    return () => ref.current?.removeEventListener("wheel", onWheelChartScroll);
  }, []);

  const onWheelChartScroll = (event: WheelEvent) => {
    event.preventDefault();
    ref.current!.scrollLeft += event.deltaY;
  };

  return ref;
};

export default useHorizontalScrollOnWheel;
