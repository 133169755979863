import dateService from "../../../../utils/date/date.service";

const getInputValue = (date: Date | null): string => {
  if (!date) {
    return "";
  }

  return dateService.formatDate(date);
};

const dateInputHelper = {
  getInputValue,
};

export default dateInputHelper;
