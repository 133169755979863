import CargoCompanyMileageContractDetailsResponse from "../../../../api/raily/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details.response";
import CargoCompanyMileageContractDetails from "./cargo-company-mileage-contract-details";

const create = (
  response: CargoCompanyMileageContractDetailsResponse
): CargoCompanyMileageContractDetails => {
  return {
    dietFullAfterHours: response.data.dietFullAfterHours,
    dietFullRate: response.data.dietFullRate,
    dietHalfAfterHours: response.data.dietHalfAfterHours,
    dietHalfRate: response.data.dietHalfRate,
    disabled: response.data.disabled,
    distanceRateCarGt900cm3: response.data.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: response.data.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: response.data.distanceRateCompanyVehicle,
    distanceRateMoped: response.data.distanceRateMoped,
    distanceRateMotorcycle: response.data.distanceRateMotorcycle,
    uuid: response.data.id,
    validSince: response.data.validSince,
    validTo: response.data.validTo,
  };
};

const cargoCompanyMileageContractDetailsFactory = {
  create,
};

export default cargoCompanyMileageContractDetailsFactory;
