import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../../context/app.context";
import AppAsideNavigationDefinitionItem, {
  AppAsideNavigationDefinitionGroup,
} from "../common/types/app-aside-navigation-definition";
import { AppAsideAttentionCountersResponseData } from "../../common/api/app-aside-attention-counters.response";
import appAsideNavigationHelper from "../common/app-aside-navigation.helper";
import AppAsideExpandedNavigationGroupListItemComponent from "./app-aside-expanded-navigation-group-list-item.component";
import AppAsideExpandedNavigationLinkListItemComponent from "./app-aside-expanded-navigation-link-list-item.component";
import appAsideExpandedNavigationFactory from "./common/app-aside-expanded-navigation.factory";
import appAsideExpandedNavigationHelper from "./common/app-aside-expanded-navigation.helper";
import AppAsideExpandedNavigationItem from "./common/types/app-aside-expanded-navigation-item";

type AppAsideExpandedNavigationProps = {
  attentionCountersData: AppAsideAttentionCountersResponseData | null;
  expandedGroupsKeys: string[];
  navigationItems: AppAsideExpandedNavigationItem[];
  isExpanded: boolean;
  navigationDefinition: AppAsideNavigationDefinitionItem[];
  setExpandedGroupsKeys: Dispatch<SetStateAction<string[]>>;
  setNavigationItems: Dispatch<
    SetStateAction<AppAsideExpandedNavigationItem[]>
  >;
};

const AppAsideExpandedNavigationComponent: FC<
  AppAsideExpandedNavigationProps
> = (props) => {
  const { pathname } = useLocation();

  const navigationItems =
    appAsideExpandedNavigationFactory.createNavigationItems(
      props.navigationDefinition,
      props.expandedGroupsKeys,
      pathname
    );

  useEffect(() => {
    props.setNavigationItems(navigationItems);
  }, [props.navigationDefinition, props.expandedGroupsKeys, pathname]);

  const toggleGroup = (
    groupKey: AppAsideNavigationDefinitionGroup["key"],
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      props.setExpandedGroupsKeys((keys) =>
        keys.filter((key) => key !== groupKey)
      );
      return;
    }

    props.setExpandedGroupsKeys((keys) => [...keys, groupKey]);
  };

  return (
    <nav className="app_aside_navigation">
      <div className="app_aside_navigation_list">
        <ul>
          {props.navigationItems.map((item) => {
            const isGroup = appAsideExpandedNavigationHelper.checkIsGroup(item);

            if (isGroup) {
              return (
                <AppAsideExpandedNavigationGroupListItemComponent
                  key={`navigation-list-item-key-${item.key}`}
                  group={item}
                  onToggleClick={toggleGroup}
                  idForTesting={`app-aside-navigation-list-item-${item.key}-group`}
                />
              );
            }

            return (
              <AppAsideExpandedNavigationLinkListItemComponent
                key={`navigation-list-item-key-${item.key}`}
                link={item}
                idForTesting={`app-aside-navigation-list-item-${item.key}-link`}
              />
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default AppAsideExpandedNavigationComponent;
