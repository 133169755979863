enum DelegationListingFilterType {
  DELEGATION_NUMBER = "DELEGATION_NUMBER",
  DELEGATION_TYPE = "DELEGATION_TYPE",
  CARGO_COMPANY_NAME = "CARGO_COMPANY_NAME",
  WORKER_NAME = "WORKER_NAME",
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  CREATION_DATE = "CREATION_DATE",
  FIRST_CSV_DOWNLOAD = "FIRST_CSV_DOWNLOAD",
  FIRST_PDF_DOWNLOAD = "FIRST_PDF_DOWNLOAD",
  COST = "COST",
  TOTAL_DISTANCE = "TOTAL_DISTANCE",
}

export default DelegationListingFilterType;
