import { FC } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderDetailsErrorHelper from "./order-details-error.helper";

type OrderDetailsErrorProps = {
  onTryAgainButtonClick: () => void;
  isFetching: boolean;
  cargoCompanyOrderId: number;
};

const OrderDetailsErrorComponent: FC<OrderDetailsErrorProps> = (props) => {
  const translations = orderTranslationsHelper.getDetailsTranslations();

  return (
    <div className="order_details_error">
      <div className="order_details_error__heading">
        {translations.fetchingError.headingText}
      </div>
      <div className="order_details_error__description">
        {orderDetailsErrorHelper.getFetchingErrorDescriptionText(
          props.cargoCompanyOrderId
        )}
      </div>

      <ButtonComponent
        onClick={props.onTryAgainButtonClick}
        isLoading={props.isFetching}
        type="primary"
        classNames={{
          root: "order_details_error__retry_button",
        }}
      >
        {translations.fetchingError.retryButtonText}
      </ButtonComponent>
    </div>
  );
};

export default OrderDetailsErrorComponent;
