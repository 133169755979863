import { useState, useEffect } from "react";
import orderDetailsForwardingApiService from "./api/forwarding/order-details-forwarding.service";
import OrderDetailsForwardingResponse from "./forwarding/types/order-details-forwarding.response";

type UseOrderDetialsForwardingProps = {
  transportingOrderId: string | undefined;
};

const useOrderDetialsForwarding = (params: UseOrderDetialsForwardingProps) => {
  const [forwardingId, setForwardingId] = useState("");
  const [consumerTaxiId, setConsumerTaxiId] = useState("");
  const [producerTaxiId, setProducerTaxiId] = useState("");

  useEffect(() => {
    if (!params.transportingOrderId) return;

    getForwardings();
  }, [params.transportingOrderId]);

  const getForwardings = () => {
    orderDetailsForwardingApiService
      .fetchForwarding(params.transportingOrderId!)
      .then(handleForwardingResponse);
  };

  const handleForwardingResponse = (
    response: OrderDetailsForwardingResponse
  ) => {
    const lastForwardingIndex = response.data.length - 1;

    setForwardingId(response.data[lastForwardingIndex]?.id);
    setConsumerTaxiId(
      response.data[lastForwardingIndex]?.contract.consumer_taxi_id
    );
    setProducerTaxiId(
      response.data[lastForwardingIndex]?.contract.producer_taxi_id
    );
  };

  return {
    producerTaxiId,
    forwardingId,
    consumerTaxiId,
    setForwardingId,
    getForwardings,
  };
};

export default useOrderDetialsForwarding;
