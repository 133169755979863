import useAsyncData from "../../../hooks/use-async-data";
import PlannerDriverPlanOrderLoadParams from "./planner-driver-plan-order-load-params";
import plannerDriverPlanService from "../planner-driver-plan.service";
import PlannerDriverPlanOrder from "./planner-driver-plan-order";

const usePlannerDriverPlanOrder = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<PlannerDriverPlanOrder>({
      data: { orders: [], orderUuid: 0 },
    });

  const load = async (
    params: PlannerDriverPlanOrderLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const plannerDriverPlanListOrderData =
        await plannerDriverPlanService.getOrder(params, signal);

      setData(plannerDriverPlanListOrderData);
      setIsLoading(false);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default usePlannerDriverPlanOrder;
