import appConfig from "../../../../../../../app.config";
import authService from "../../../../../../auth/common/auth.service";
import driverAddApiService from "../../../common/api/driver-add-api.service";
import DriverAddByRailyTaxiCorporationsResponse from "./driver-add-by-raily-taxi-corporations.response";

const fetchTaxiCorporations =
  (): Promise<DriverAddByRailyTaxiCorporationsResponse> => {
    const path = `/taxi-corporations`;
    const url = `${appConfig.baseApiUrl}${path}`;

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
    };

    return fetch(url, init).then(async (r) => {
      const responseData = await r.json();
      return {
        status: r.status,
        data: responseData,
      };
    });
  };

const driverAddByRailyApiService = {
  addDriver: driverAddApiService.addDriver,
  fetchFleetPartners: driverAddApiService.fetchFleetPartners,
  fetchTaxiCorporations,
};

export default driverAddByRailyApiService;
