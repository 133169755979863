import axios, { AxiosError, AxiosResponse } from "axios";
import HttpResponse from "../utils/http/http.response";
import ApiConfig from "./types/api-config";
import HttpError from "../utils/http/http.error";
import ApiPostParams from "./types/api-post-params";
import ApiGetParams from "./types/api-get-params";
import ApiPutParams from "./types/api-put-params";
import ApiDeleteParams from "./types/api-delete-params";

const apiService = (config: ApiConfig) => {
  const prepareHttpResponse = <T extends HttpResponse>(
    axiosResponse: AxiosResponse
  ): T => {
    return <T>{
      data: axiosResponse.data,
      status: axiosResponse.status,
    };
  };

  const handleRequest = async <T extends HttpResponse>(
    requestFunction: Promise<AxiosResponse<any, any>>
  ): Promise<T> => {
    return new Promise(async (resolve, reject) => {
      try {
        const axiosResponse = await requestFunction;

        return resolve(prepareHttpResponse<T>(axiosResponse));
      } catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.code) {
          reject(HttpError.OTHER);
        }

        switch (axiosError.code) {
          case "ERR_CANCELED":
            return;
          case "ERR_NETWORK":
            reject(HttpError.OFFLINE);
            return;
          default:
            reject(HttpError.OTHER);
        }
      }
    });
  };

  const apiClient = axios.create({
    baseURL: config.baseUrl,
    headers: config.defaultHeaders,
    paramsSerializer: { indexes: config.paramSerializerConfig?.indexes },
    validateStatus: () => {
      return true;
    },
    withCredentials: config.withCredentials,
  });

  if (config.interceptors?.request) {
    apiClient.interceptors.request.use(config.interceptors.request);
  }

  if (config.interceptors?.response) {
    apiClient.interceptors.response.use(config.interceptors.response);
  }

  const get = <T extends HttpResponse>(params: ApiGetParams): Promise<T> => {
    const request = apiClient.get(params.url, {
      params: params.queryParams,
      signal: params.abortSignal,
      headers: {
        ...config.defaultHeaders,
        ...params.headers,
      },
    });

    return handleRequest(request);
  };

  const post = async <T extends HttpResponse>(
    params: ApiPostParams
  ): Promise<T> => {
    const request = apiClient.post(params.url, params.body, {
      params: params.queryParams,
      signal: params.abortSignal,
      headers: {
        ...config.defaultHeaders,
        ...params.headers,
      },
    });

    return handleRequest(request);
  };

  const put = async <T extends HttpResponse>(
    params: ApiPutParams
  ): Promise<T> => {
    const request = apiClient.put(params.url, params.body, {
      params: params.queryParams,
      signal: params.abortSignal,
      headers: {
        ...config.defaultHeaders,
        ...params.headers,
      },
    });

    return handleRequest(request);
  };

  const httpDelete = async <T extends HttpResponse>(
    params: ApiDeleteParams
  ): Promise<T> => {
    const request = apiClient.delete(params.url, {
      params: params.queryParams,
      signal: params.abortSignal,
      headers: {
        ...config.defaultHeaders,
        ...params.headers,
      },
    });

    return handleRequest(request);
  };

  return {
    get,
    post,
    put,
    delete: httpDelete,
  };
};

export default apiService;
