import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiRouteSettlementListingSortKey from "./types/taxi-route-settlement-listing-sort-key";
import TaxiRouteSettlementListingSortSelectOption from "./types/taxi-route-settlement-listing-sort-select-option";

const getSelectOptions = (): TaxiRouteSettlementListingSortSelectOption[] => {
  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations();

  return [
    {
      label: translations.sort.distanceAscOptionLabel,
      value: TaxiRouteSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      label: translations.sort.distanceDescOptionLabel,
      value: TaxiRouteSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      label: translations.sort.tollRoadsCostAscOptionLabel,
      value: TaxiRouteSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      label: translations.sort.tollRoadsCostDescOptionLabel,
      value: TaxiRouteSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      label: translations.sort.routeIdAscOptionLabel,
      value: TaxiRouteSettlementListingSortKey.ROUTE_ID_ASC,
    },
    {
      label: translations.sort.routeIdDescOptionLabel,
      value: TaxiRouteSettlementListingSortKey.ROUTE_ID_DESC,
    },
    {
      label: translations.sort.driverAscOptionLabel,
      value: TaxiRouteSettlementListingSortKey.DRIVER_ASC,
    },
    {
      label: translations.sort.driverDescOptionLabel,
      value: TaxiRouteSettlementListingSortKey.DRIVER_DESC,
    },
    {
      label: translations.sort.billingModelAscOptionLabel,
      value: TaxiRouteSettlementListingSortKey.BILLING_MODEL_ASC,
    },
    {
      label: translations.sort.billingModelDescOptionLabel,
      value: TaxiRouteSettlementListingSortKey.BILLING_MODEL_DESC,
    },
    {
      label: translations.sort.baseDistanceRateAscOptionLabel,
      value: TaxiRouteSettlementListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.sort.baseDistanceRateDescOptionLabel,
      value: TaxiRouteSettlementListingSortKey.DISTANCE_RATE_DESC,
    },
    // {
    //   label: translations.sort.distanceCostAscOptionLabel,
    //   value: TaxiRouteSettlementListingSortKey.DISTANCE_COST_ASC,
    // },
    // {
    //   label: translations.sort.distanceCostDescOptionLabel,
    //   value: TaxiRouteSettlementListingSortKey.DISTANCE_COST_DESC,
    // },
    // {
    //   label: translations.sort.totalCostAscOptionLabel,
    //   value: TaxiRouteSettlementListingSortKey.TOTAL_COST_ASC,
    // },
    // {
    //   label: translations.sort.totalCostDescOptionLabel,
    //   value: TaxiRouteSettlementListingSortKey.TOTAL_COST_DESC,
    // },
  ];
};

const taxiRouteSettlementListingSortHelper = {
  getSelectOptions,
};

export default taxiRouteSettlementListingSortHelper;
