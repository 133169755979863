import CargoCompanyDispatchUpdateRequest from "../../../../api/raily/cargo-company/dispatch/update/cargo-company-dispatch-update-request";
import CargoCompanyDispatchUpdateParams from "./cargo-company-dispatch-update-params";

const create = (
  params: CargoCompanyDispatchUpdateParams
): CargoCompanyDispatchUpdateRequest => {
  return {
    addressString: params.address,
    companyId: params.companyUuid,
    dispatchId: params.dispatchUuid,
    dispatchName: params.name,
    displayName: params.displayName,
  };
};

const cargoCompanyDispatchUpdateRequestFactory = {
  create,
};

export default cargoCompanyDispatchUpdateRequestFactory;
