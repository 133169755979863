import TaxiTaxiContractListingFilter, {
  TaxiTaxiContractListingActivityStatusFilter,
  TaxiTaxiContractListingBillingModelFilter,
  TaxiTaxiContractListingConsumerDisplayNameFilter,
  TaxiTaxiContractListingDisplayNameFilter,
  TaxiTaxiContractListingDistanceRateFilter,
  TaxiTaxiContractListingFreeStoppingPeriodFilter,
  TaxiTaxiContractListingPartnerFilter,
  TaxiTaxiContractListingPeriodOfValidationFilter,
  TaxiTaxiContractListingPriorityFilter,
  TaxiTaxiContractListingProducerDisplayNameFilter,
  TaxiTaxiContractListingStopRateFilter,
} from "./types/taxi-taxi-contract-listing-filter";
import TaxiTaxiContractListingFilterType from "./types/taxi-taxi-contract-listing-filter-type";
import TaxiTaxiContractListingSortKey from "./types/taxi-taxi-contract-listing-sort-key";
import TaxiTaxiContractListLoadParams, {
  TaxiTaxiContractListLoadParamsOrder,
} from "../../../../../common/services/taxi-taxi-contract/list/taxi-taxi-contract-list-load-params";

const createOrder = (
  sortKey: TaxiTaxiContractListingSortKey | null
): TaxiTaxiContractListLoadParamsOrder | undefined => {
  const definition: Record<
    TaxiTaxiContractListingSortKey,
    TaxiTaxiContractListLoadParamsOrder
  > = {
    [TaxiTaxiContractListingSortKey.PRIORITY_ASC]:
      TaxiTaxiContractListLoadParamsOrder.PRIORITY_ASC,
    [TaxiTaxiContractListingSortKey.PRIORITY_DESC]:
      TaxiTaxiContractListLoadParamsOrder.PRIORITY_DESC,
    [TaxiTaxiContractListingSortKey.DISPLAY_NAME_ASC]:
      TaxiTaxiContractListLoadParamsOrder.DISPLAY_NAME_ASC,
    [TaxiTaxiContractListingSortKey.DISPLAY_NAME_DESC]:
      TaxiTaxiContractListLoadParamsOrder.DISPLAY_NAME_DESC,
    [TaxiTaxiContractListingSortKey.PRODUCER_DISPLAY_NAME_ASC]:
      TaxiTaxiContractListLoadParamsOrder.PRODUCER_DISPLAY_NAME_ASC,
    [TaxiTaxiContractListingSortKey.PRODUCER_DISPLAY_NAME_DESC]:
      TaxiTaxiContractListLoadParamsOrder.PRODUCER_DISPLAY_NAME_DESC,
    [TaxiTaxiContractListingSortKey.CONSUMER_DISPLAY_NAME_ASC]:
      TaxiTaxiContractListLoadParamsOrder.CONSUMER_DISPLAY_NAME_ASC,
    [TaxiTaxiContractListingSortKey.CONSUMER_DISPLAY_NAME_DESC]:
      TaxiTaxiContractListLoadParamsOrder.CONSUMER_DISPLAY_NAME_DESC,
    [TaxiTaxiContractListingSortKey.HALTING_TIME_RATE_ASC]:
      TaxiTaxiContractListLoadParamsOrder.HALTING_TIME_RATE_ASC,
    [TaxiTaxiContractListingSortKey.HALTING_TIME_RATE_DESC]:
      TaxiTaxiContractListLoadParamsOrder.HALTING_TIME_RATE_DESC,
    [TaxiTaxiContractListingSortKey.DISTANCE_RATE_ASC]:
      TaxiTaxiContractListLoadParamsOrder.DISTANCE_RATE_ASC,
    [TaxiTaxiContractListingSortKey.DISTANCE_RATE_DESC]:
      TaxiTaxiContractListLoadParamsOrder.DISTANCE_RATE_DESC,
    [TaxiTaxiContractListingSortKey.HALTING_TIME_AFTER_MINUTES_ASC]:
      TaxiTaxiContractListLoadParamsOrder.HALTING_TIME_AFTER_MINUTES_ASC,
    [TaxiTaxiContractListingSortKey.HALTING_TIME_AFTER_MINUTES_DESC]:
      TaxiTaxiContractListLoadParamsOrder.HALTING_TIME_AFTER_MINUTES_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: TaxiTaxiContractListingFilter[],
  sortKey: TaxiTaxiContractListingSortKey | null,
  taxiCorporationUuid: string
): TaxiTaxiContractListLoadParams => {
  const periodOfValidation = filters.find(
    (filter) =>
      filter.type === TaxiTaxiContractListingFilterType.PERIOD_OF_VALIDATION
  )?.value as TaxiTaxiContractListingPeriodOfValidationFilter["value"];

  const distanceRateRange = filters.find(
    (filter) =>
      filter.type === TaxiTaxiContractListingFilterType.DISTANCE_RATE_RANGE
  )?.value as TaxiTaxiContractListingDistanceRateFilter["value"];

  const freeStoppingPeriod = filters.find(
    (filter) =>
      filter.type === TaxiTaxiContractListingFilterType.FREE_STOPPING_PERIOD
  )?.value as TaxiTaxiContractListingFreeStoppingPeriodFilter["value"];

  const stopRateRange = filters.find(
    (filter) =>
      filter.type === TaxiTaxiContractListingFilterType.STOP_RATE_RANGE
  )?.value as TaxiTaxiContractListingStopRateFilter["value"];

  const billingModel = filters.find(
    (filter) => filter.type === TaxiTaxiContractListingFilterType.BILLING_MODEL
  )?.value as TaxiTaxiContractListingBillingModelFilter["value"];

  const activeStatus = filters.find(
    (filter) => filter.type === TaxiTaxiContractListingFilterType.ACTIVE_STATUS
  )?.value as TaxiTaxiContractListingActivityStatusFilter["value"];

  return {
    page,
    pageSize,
    displayName: filters.find(
      (filter) => filter.type === TaxiTaxiContractListingFilterType.DISPLAY_NAME
    )?.value as TaxiTaxiContractListingDisplayNameFilter["value"] | undefined,
    producerDisplayName: filters.find(
      (filter) =>
        filter.type === TaxiTaxiContractListingFilterType.PRODUCER_DISPLAY_NAME
    )?.value as
      | TaxiTaxiContractListingProducerDisplayNameFilter["value"]
      | undefined,
    consumerDisplayName: filters.find(
      (filter) =>
        filter.type === TaxiTaxiContractListingFilterType.CONSUMER_DISPLAY_NAME
    )?.value as
      | TaxiTaxiContractListingConsumerDisplayNameFilter["value"]
      | undefined,
    periodOfValidation: periodOfValidation,
    distanceRate: distanceRateRange,
    freeStoppingPeriod: freeStoppingPeriod,
    stopRate: stopRateRange,
    priority: filters.find(
      (filter) => filter.type === TaxiTaxiContractListingFilterType.PRIORITY
    )?.value as TaxiTaxiContractListingPriorityFilter["value"],
    isPartner: filters.find(
      (filter) => filter.type === TaxiTaxiContractListingFilterType.PARTNER
    )?.value as TaxiTaxiContractListingPartnerFilter["value"],
    isActive: activeStatus,
    billingModel,
    engagedTaxiUuid: taxiCorporationUuid,
    order: createOrder(sortKey),
  };
};

const taxiTaxiContractListingLoadParamsFactory = {
  create,
};

export default taxiTaxiContractListingLoadParamsFactory;
