import FormValidationResult from "./types/form-validation-result";

const checkThatEveryFieldHasNoErrorMessage = (
  validationResults: FormValidationResult[]
) => {
  return validationResults.every((result) => result.errorMessage === "");
};

const checkThatFormHasErrors = (validationResults: {
  [key: string]: FormValidationResult;
}) => {
  return checkThatEveryFieldHasNoErrorMessage(Object.values(validationResults));
};

const formValidationHelper = {
  checkThatFormHasErrors,
};

export default formValidationHelper;
