export enum MessengerPostType {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REOPEN_REQUEST = "REOPEN_REQUEST",
  REOPENED = "REOPENED",
  BILLED = "BILLED",
  UPDATED = "UPDATED",
  USER_ENTRY = "USER_ENTRY",
}

type MessengerPost = {
  authorUuid: string;
  authorFullName: string;
  authorCompany: string;
  content: string;
  date: Date;
  postType: MessengerPostType;
};

export default MessengerPost;
