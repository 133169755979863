import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import settlementTranslationsHelper from "../../../../../../../languages/settlement-translations.helper";
import cargoOrderSettlementListingHelper from "../../cargo-order-settlement-listing.helper";
import CargoOrderSettlementListingFilter, {
  CargoOrderSettlementListingInternalOrderIdFilter,
  CargoOrderSettlementListingPassengerFilter,
  CargoOrderSettlementListingTaxiFilter,
  CargoOrderSettlementListingDispatchFilter,
  CargoOrderSettlementListingBillingStatusFilter,
  CargoOrderSettlementListingExternalOrderIdFilter,
  CargoOrderSettlementListingOrderStartDateFilter,
  CargoOrderSettlementListingRouteIntermediateAddressFilter,
  CargoOrderSettlementListingRoutePickupAddressFilter,
  CargoOrderSettlementListingRouteDestinationAddressFilter,
  CargoOrderSettlementListingRouteAddressFilter,
} from "../../types/cargo-order-settlement-listing-filter";
import CargoOrderSettlementListingFilterType from "../../types/cargo-order-settlement-listing-filter-type";
import dateService from "../../../../../../../common/utils/date/date.service";
import StatusLegendStatuses from "../../../../../../../common/components/status-legend/types/status-legend-statuses";
import CargoOrderSettlementListingItemBillingStatus from "../../types/cargo-order-settlement-listing-item-billing-status";

const getStartDateBadgeOption = (
  filter: CargoOrderSettlementListingOrderStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: CargoOrderSettlementListingOrderStartDateFilter;
} => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.orderStartDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.orderStartDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: CargoOrderSettlementListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: CargoOrderSettlementListingBillingStatusFilter;
}[] => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .filters;

  return Object.values(CargoOrderSettlementListingItemBillingStatus).map(
    (statusFilterValue) => {
      return {
        badge: {
          text: translations.statusFilterText.replace(
            "#{option}",
            cargoOrderSettlementListingHelper.getStatusText(statusFilterValue!)
          ),
          title: translations.statusFilterTitle.replace(
            "#{option}",
            cargoOrderSettlementListingHelper.getStatusText(statusFilterValue!)
          ),
        },
        filter: {
          type: CargoOrderSettlementListingFilterType.BILLING_STATUS,
          value: statusFilterValue,
        },
      };
    }
  );
};

const getBadgeOptions = (filter: CargoOrderSettlementListingFilter) => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: CargoOrderSettlementListingFilter;
  }[] = [
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingInternalOrderIdFilter["value"]
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingInternalOrderIdFilter["value"]
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as CargoOrderSettlementListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeAddressFilterText.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingRouteAddressFilter["value"]
        ),
        title: translations.routeAddressFilterTitle.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingRouteAddressFilter["value"]
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as CargoOrderSettlementListingRouteAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as CargoOrderSettlementListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeDestinationAddressFilterText.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingRouteDestinationAddressFilter["value"]
        ),
        title: translations.routeDestinationAddressFilterTitle.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingRouteDestinationAddressFilter["value"]
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as CargoOrderSettlementListingRouteDestinationAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as CargoOrderSettlementListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as CargoOrderSettlementListingRoutePickupAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          String(
            filter.value as CargoOrderSettlementListingPassengerFilter["value"]
          )
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          String(
            filter.value as CargoOrderSettlementListingPassengerFilter["value"]
          )
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.PASSENGER,
        value:
          filter.value as CargoOrderSettlementListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.externalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as CargoOrderSettlementListingExternalOrderIdFilter["value"]
          )
        ),
        title: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as CargoOrderSettlementListingExternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.EXTERNAL_ORDER_ID,
        value:
          filter.value as CargoOrderSettlementListingExternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.taxiFilterText.replace(
          "#{query}",
          String(filter.value as CargoOrderSettlementListingTaxiFilter["value"])
        ),
        title: translations.taxiFilterTitle.replace(
          "#{query}",
          String(filter.value as CargoOrderSettlementListingTaxiFilter["value"])
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.TAXI,
        value: filter.value as CargoOrderSettlementListingTaxiFilter["value"],
      },
    },
    {
      badge: {
        text: translations.dispatchFilterText.replace(
          "#{query}",
          String(
            filter.value as CargoOrderSettlementListingDispatchFilter["value"]
          )
        ),
        title: translations.dispatchFilterTitle.replace(
          "#{query}",
          String(
            filter.value as CargoOrderSettlementListingDispatchFilter["value"]
          )
        ),
      },
      filter: {
        type: CargoOrderSettlementListingFilterType.DISPATCH,
        value:
          filter.value as CargoOrderSettlementListingDispatchFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as CargoOrderSettlementListingOrderStartDateFilter
    ),
    ...getStatusBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: CargoOrderSettlementListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: CargoOrderSettlementListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const cargoOrderSettlementListingFiltersBadgeListHelper = {
  getBadges,
};

export default cargoOrderSettlementListingFiltersBadgeListHelper;
