import OperatorListResponse, {
  OperatorListResponseItem,
} from "../../../api/raily/operator/list/operator-list.response";
import OperatorList, { OperatorListItem } from "./operator-list";

const createAlternativePhoneNumber = (
  responseDataItem: OperatorListResponseItem
): OperatorListItem["alternativePhoneNumber"] => {
  if (
    !responseDataItem.mobileSecondary ||
    !responseDataItem.mobileSecondaryPrefix
  ) {
    return null;
  }

  return {
    dialingCode: responseDataItem.mobileSecondaryPrefix,
    number: responseDataItem.mobileSecondary,
  };
};

const createDataItem = (
  responseDataItem: OperatorListResponseItem
): OperatorListItem => {
  return {
    creationDate: responseDataItem.createdAt,
    email: responseDataItem.email,
    firstName: responseDataItem.firstName,
    lastName: responseDataItem.lastName,
    phoneNumber: {
      dialingCode: responseDataItem.mobilePrimaryPrefix,
      number: responseDataItem.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(responseDataItem),
    removalDate: responseDataItem.removedAt,
    username: responseDataItem.username,
    uuid: responseDataItem.id,
  };
};

const createData = (
  responseData: OperatorListResponseItem[]
): OperatorListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: OperatorListResponse): OperatorList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const operatorListFactory = {
  create,
};

export default operatorListFactory;
