import RouteActiveListRequest from "../../../api/raily/route/active-list/route-active-list.request";
import RouteActiveListLoadParams from "./route-active-list-load-params";

const create = (params: RouteActiveListLoadParams): RouteActiveListRequest => {
  return {
    taxiCorporationId: params.taxiCorporationUuid,
    cargoOrderId: params.cargoOrderUuid,
    cargoOrderHumanId: params.cargoOrderHumanId,
  };
};

const routeActiveListRequestFactory = {
  create,
};

export default routeActiveListRequestFactory;
