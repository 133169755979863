import UserStatus from "../../../../../common/types/user-status";
import { UserPassengerListingRouteQueryParams } from "../../../common/routes/types/user-passenger-listing-route-params";
import PassengerListingFilter, {
  PassengerListingAddressFilter,
  PassengerListingCargoCompanyFilter,
  PassengerListingDispatchFilter,
  PassengerListingEmailFilter,
  PassengerListingFirstNameFilter,
  PassengerListingLastNameFilter,
  PassengerListingStatusFilter,
  PassengerListingUsernameFilter,
} from "../common/types/passenger-listing-filter";
import PassengerListingFilterType from "../common/types/passenger-listing-filter-type";
import PassengerListingSortKey from "../common/types/passenger-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: UserPassengerListingRouteQueryParams
): PassengerListingFilter[] => {
  const filters: PassengerListingFilter[] = [];

  if (routeQueryFilterParams.firstName) {
    const firstNameFilter: PassengerListingFilter = {
      type: PassengerListingFilterType.FIRST_NAME,
      value: routeQueryFilterParams.firstName,
    };

    filters.push(firstNameFilter);
  }

  if (routeQueryFilterParams.lastName) {
    const lastNameFilter: PassengerListingLastNameFilter = {
      type: PassengerListingFilterType.LAST_NAME,
      value: routeQueryFilterParams.lastName,
    };

    filters.push(lastNameFilter);
  }

  if (routeQueryFilterParams.email) {
    const emailAddressFilter: PassengerListingEmailFilter = {
      type: PassengerListingFilterType.EMAIL,
      value: routeQueryFilterParams.email,
    };

    filters.push(emailAddressFilter);
  }

  if (routeQueryFilterParams.username) {
    const userNameFilter: PassengerListingUsernameFilter = {
      type: PassengerListingFilterType.USERNAME,
      value: routeQueryFilterParams.username,
    };

    filters.push(userNameFilter);
  }

  if (routeQueryFilterParams.cargoCompany) {
    const cargoCompanyFilter: PassengerListingCargoCompanyFilter = {
      type: PassengerListingFilterType.CARGO_COMPANY,
      value: routeQueryFilterParams.cargoCompany,
    };

    filters.push(cargoCompanyFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: PassengerListingDispatchFilter = {
      type: PassengerListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: PassengerListingStatusFilter = {
      type: PassengerListingFilterType.ACTIVITY_STATUS,
      value:
        routeQueryFilterParams.status === "DELETED"
          ? UserStatus.DELETED
          : UserStatus.ACTIVE,
    };

    filters.push(statusFilter);
  }

  if (routeQueryFilterParams.address) {
    const addressFilter: PassengerListingFilter = {
      type: PassengerListingFilterType.ADDRESS,
      value: routeQueryFilterParams.address,
    };

    filters.push(addressFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: UserPassengerListingRouteQueryParams
): PassengerListingSortKey | undefined => {
  return routeQueryParams.sort as PassengerListingSortKey;
};

const getPage = (
  routeQueryParams: UserPassengerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: UserPassengerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: PassengerListingFilter[],
  sortKey: PassengerListingSortKey | null,
  page: number,
  pageSize: number
): UserPassengerListingRouteQueryParams => {
  const userActivityFilterValue = filters.find(
    (filter) => filter.type === PassengerListingFilterType.ACTIVITY_STATUS
  )?.value as PassengerListingStatusFilter["value"] | undefined;

  const routeQueryParams: UserPassengerListingRouteQueryParams = {
    firstName: filters.find(
      (filter) => filter.type === PassengerListingFilterType.FIRST_NAME
    )?.value as PassengerListingFirstNameFilter["value"],
    lastName: filters.find(
      (filter) => filter.type === PassengerListingFilterType.LAST_NAME
    )?.value as PassengerListingLastNameFilter["value"],
    username: filters.find(
      (filter) => filter.type === PassengerListingFilterType.USERNAME
    )?.value as PassengerListingUsernameFilter["value"],
    email: filters.find(
      (filter) => filter.type === PassengerListingFilterType.EMAIL
    )?.value as PassengerListingEmailFilter["value"],
    status:
      userActivityFilterValue === undefined
        ? undefined
        : String(userActivityFilterValue),
    cargoCompany: filters.find(
      (filter) => filter.type === PassengerListingFilterType.CARGO_COMPANY
    )?.value as PassengerListingCargoCompanyFilter["value"],
    dispatch: filters.find(
      (filter) => filter.type === PassengerListingFilterType.DISPATCH
    )?.value as PassengerListingDispatchFilter["value"],
    address: filters.find(
      (filter) => filter.type === PassengerListingFilterType.ADDRESS
    )?.value as PassengerListingAddressFilter["value"],
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const passengerListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default passengerListingFiltersService;
