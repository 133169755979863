import PhoneNumber from "../../../../../common/types/phone-number";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import SignUpDriverFormCompanyDataAddress from "../common/types/sign-up-driver-form-company-data-address";

const getMobileString = (phone: PhoneNumber): string => {
  if (!phone.dialingCode || !phone.number) {
    return appTranslationsHelper.getDriverSignUpTranslations().summary
      .noFillDataText;
  }

  return `${phone.dialingCode} ${phone.number}`;
};

const getDateString = (date: Date): string => {
  return date.toLocaleDateString();
};

const getAddressString = (
  address: SignUpDriverFormCompanyDataAddress
): string => {
  const addressString = `${address.street} ${
    address.houseNumber && address.apartmentNumber
      ? `${address.houseNumber}/${address.apartmentNumber}`
      : `${address.houseNumber}`
  }, ${address.zipCode} ${address.town}`;

  return addressString;
};

const signUpDriverSummaryHelper = {
  getMobileString,
  getDateString,
  getAddressString,
};

export default signUpDriverSummaryHelper;
