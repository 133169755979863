import useAsyncData from "../../../hooks/use-async-data";
import mileageAddressService from "../mileage-address.service";
import MileageAddressListItem from "./mileage-address-list";
import MileageAddressListLoadParams from "./mileage-address-list-load-params";

const useMileageAddressList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MileageAddressListItem[]>([]);

  const load = async (
    params: MileageAddressListLoadParams,
    abortSignal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await mileageAddressService.getList(params, abortSignal);

      setData(data);
      setIsLoading(false);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useMileageAddressList;
