import formValidationService from "../../../../common/utils/validation/form-validation.service";
import SignInFormData from "./sign-in-form-data";
import SignInFormValidationResults from "./sign-in-form-validation-results";

const getDefaultFormData = (): SignInFormData => {
  return {
    username: "",
    password: "",
  };
};

const getDefaultFormValidationResults = (): SignInFormValidationResults => {
  return {
    username: formValidationService.defaultValidationResult,
    password: formValidationService.defaultValidationResult,
  };
};

const signInFormHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default signInFormHelper;
