enum DelegationListingSortKey {
  DELEGATION_NUMBER_ASC = "DELEGATION_NUMBER_ASC",
  DELEGATION_NUMBER_DESC = "DELEGATION_NUMBER_DESC",
  CARGO_COMPANY_NAME_ASC = "CARGO_COMPANY_NAME_ASC",
  CARGO_COMPANY_NAME_DESC = "CARGO_COMPANY_NAME_DESC",
  WORKER_NAME_ASC = "WORKER_NAME_ASC",
  WORKER_NAME_DESC = "WORKER_NAME_DESC",
  COST_ASC = "COST_ASC",
  COST_DESC = "COST_DESC",
  TOTAL_DISTANCE_ASC = "TOTAL_DISTANCE_ASC",
  TOTAL_DISTANCE_DESC = "TOTAL_DISTANCE_DESC",
  START_DATE_ASC = "START_DATE_ASC",
  START_DATE_DESC = "START_DATE_DESC",
  END_DATE_ASC = "END_DATE_ASC",
  END_DATE_DESC = "END_DATE_DESC",
  CREATION_DATE_ASC = "CREATION_DATE_ASC",
  CREATION_DATE_DESC = "CREATION_DATE_DESC",
  FIRST_CSV_DOWNLOAD_ASC = "FIRST_CSV_DOWNLOAD_ASC",
  FIRST_CSV_DOWNLOAD_DESC = "FIRST_CSV_DOWNLOAD_DESC",
  FIRST_PDF_DOWNLOAD_ASC = "FIRST_PDF_DOWNLOAD_ASC",
  FIRST_PDF_DOWNLOAD_DESC = "FIRST_PDF_DOWNLOAD_DESC",
}
export default DelegationListingSortKey;
