const downloadFromBlob = (content: Blob, filename: string) => {
  const href = window.URL.createObjectURL(content);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

const fileDownloadService = {
  downloadFromBlob,
};

export default fileDownloadService;
