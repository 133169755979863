import { FC, MouseEvent, useRef, RefObject, useEffect, useState } from "react";
import { faCalendarAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { createPortal } from "react-dom";
import classNames from "classnames";
import ComponentClassnames from "../../../../types/component-classnames";
import OutsideClickHandler from "react-outside-click-handler";
import InputComponent from "../../input/input.component";
import FormIcon from "../../icon/form-icon";
import DateRange from "../../../../types/date-range";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import dateRangeInputHelper from "./date-range-input.helper";
import DateRangePickerComponent from "../../date-picker/date-range-picker/date-range-picker.component";
import useOpen from "../../../../hooks/use-open";

type DateRangeInputProps = {
  classNames?: ComponentClassnames;
  date: DateRange | null;
  onChange: (date: DateRange | null) => void;
  minDate?: Date;
  maxDate?: Date;
  onBlur?: () => void;
  hasError?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  idForTesting?: string;
  parentRef?: RefObject<HTMLElement>;
  parentContainerRef?: RefObject<HTMLElement>;
};

const DateRangeInputComponent: FC<DateRangeInputProps> = (props) => {
  const [calendarHeight, setCalendarHeight] = useState(0);
  const { isOpen, open, close } = useOpen();
  const datePickerRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calendarHeight =
      calendarRef?.current?.getBoundingClientRect().top ?? 0;
    setCalendarHeight(calendarHeight);
  }, [isOpen]);

  const createCalendarClasses = () => {
    if (!datePickerRef) return "";
    const datePickerTop =
      datePickerRef.current?.getBoundingClientRect().top ?? 0;
    const windowHeight = window.innerHeight;

    return calendarHeight
      ? datePickerTop + calendarHeight >= windowHeight
        ? "date_range_input_selector_above"
        : "date_range_input_selector_below"
      : "";
  };

  const calendarPositionClass = createCalendarClasses();

  const translations =
    appTranslationsHelper.getComponentTranslations().dateRangePicker;
  const inputValue = dateRangeInputHelper.getDateRangeInputValue(props.date);
  const dataPickerRect = datePickerRef.current?.getBoundingClientRect();
  const renderRef = props.parentRef?.current;

  const onDateRangeChange = (dateRange: DateRange | null) => {
    props.onChange(dateRange);
    close();
  };

  const onInputFocus = () => open();

  const onClearDateRangeButtonClick = (
    event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    props.onChange(null);
  };

  const onOutsideClick = (event: any) => {
    if (
      !isOpen ||
      (calendarRef.current && calendarRef.current.contains(event.target))
    ) {
      return;
    }

    close();
  };

  const dateRangePicker = createPortal(
    <div
      style={{
        position: renderRef ? "fixed" : "absolute",
        top: dataPickerRect
          ? renderRef
            ? dataPickerRect.top
            : dataPickerRect.top + window.scrollY
          : "",
        left: dataPickerRect ? (renderRef ? "" : dataPickerRect.left) : "",
      }}
    >
      <div
        className={classNames(
          calendarPositionClass,
          "date_range_input_selector"
        )}
        ref={calendarRef}
      >
        <DateRangePickerComponent
          date={props.date}
          maxDate={props.maxDate}
          minDate={props.minDate}
          onChange={onDateRangeChange}
        />
      </div>
    </div>,
    renderRef ?? document.body
  );

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div
        ref={datePickerRef}
        className={props.classNames?.root}
        data-test-id={props.idForTesting}
      >
        {isOpen && dateRangePicker}
        <InputComponent
          onFocus={onInputFocus}
          onBlur={props.onBlur}
          value={inputValue}
          placeholder={props.placeholder ?? translations.inputPlaceholder}
          isReadOnly
          iconLeft={<FormIcon icon={faCalendarAlt} />}
          iconRight={
            props.date?.from &&
            props.date?.to && (
              <FormIcon
                icon={faTimes}
                title={translations.clearDateButtonTitle}
                onClick={onClearDateRangeButtonClick}
              />
            )
          }
          idForTesting={
            props.idForTesting ? `${props.idForTesting}-input` : undefined
          }
          hasError={props.hasError}
          isDisabled={props.isDisabled}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default DateRangeInputComponent;
