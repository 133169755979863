import PassengeAddress from "../../types/passenger-address";

const getAddressLabel = (address: PassengeAddress) => {
  return `${address.street} ${address.houseNumber}${
    address.apartmentNumber && `/${address.apartmentNumber}`
  } ${address.town}, ${address.zipCode}`;
};

const passengerAddressHelper = {
  getAddressLabel,
};

export default passengerAddressHelper;
