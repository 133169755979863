import MileageAddWorker from "../../types/mileage-add-worker";

const getCargoCompanyLabel = (companyName: string) => {
  if (!companyName) {
    return `-----`;
  }

  return companyName;
};

const getWorkerLabel = (workerName: string | undefined) => {
  if (!workerName) {
    return `-----`;
  }

  return workerName;
};

const getMileageNumberLabel = (mileageNumber: string | undefined) => {
  if (!mileageNumber) {
    return `-----`;
  }

  return mileageNumber;
};

const getCardNumberLabel = (cardNo: string | undefined) => {
  if (!cardNo) {
    return `-----`;
  }

  return cardNo;
};
const getCommissionNumberLabel = (commissionNumber: string) => {
  if (!commissionNumber) {
    return `-----`;
  }

  return commissionNumber;
};

const mileageAddSummaryHelper = {
  getCommissionNumberLabel,
  getCargoCompanyLabel,
  getWorkerLabel,
  getMileageNumberLabel,
  getCardNumberLabel,
};

export default mileageAddSummaryHelper;
