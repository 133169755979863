import { DriverAddByRailyTaxiCorporationsResponseDataItem } from "../api/driver-add-by-raily-taxi-corporations.response";
import DriverAddByRailyTaxiCorporation from "../types/driver-add-by-raily-taxi-corporation";
import DriverAddByRailyTaxiCorporationSelectOption from "../types/driver-add-by-raily-taxi-corporation-select-option";

const createTaxiCorporation = (
  responseCargoCompany: DriverAddByRailyTaxiCorporationsResponseDataItem
): DriverAddByRailyTaxiCorporation => {
  return {
    uuid: responseCargoCompany.id,
    displayName: responseCargoCompany.display_name,
    name: responseCargoCompany.company_name,
  };
};

const createTaxiCorporationSelectOption = (
  taxiCorporation: DriverAddByRailyTaxiCorporation
): DriverAddByRailyTaxiCorporationSelectOption => {
  return {
    label: taxiCorporation.displayName,
    value: taxiCorporation,
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: DriverAddByRailyTaxiCorporationsResponseDataItem[]
): DriverAddByRailyTaxiCorporationSelectOption[] => {
  const taxiCorporations = responseTaxiCorporations.map(createTaxiCorporation);

  return taxiCorporations.map(createTaxiCorporationSelectOption);
};

const driverAddByRailyFactory = {
  createTaxiCorporationSelectOptions,
};

export default driverAddByRailyFactory;
