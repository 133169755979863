import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import TaxiListingTableColumn from "../common/types/taxi-listing-table-column";

const getColumns = (): TaxiListingTableColumn[] => {
  const translations =
    taxiTranslationsHelper.getTaxiListingTranslations().table.headers;

  const tableColumns: TaxiListingTableColumn[] = [
    {
      header: translations.companyNameLabel,
      title: translations.companyNameTitle,
      accessor: "companyName",
      colSpan: 10,
    },
    {
      header: translations.companyDisplayNameLabel,
      title: translations.companyDisplayNameTitle,
      accessor: "companyDisplayName",
      colSpan: 10,
    },
    {
      header: translations.taxNumberLabel,
      title: translations.taxNumberTitle,
      accessor: "taxId",
      colSpan: 10,
    },
    {
      header: translations.companyIdLabel,
      title: translations.companyIdTitle,
      accessor: "companyId",
      colSpan: 10,
    },
    {
      header: translations.nationalCourtRegisterLabel,
      title: translations.nationalCourtRegisterTitle,
      accessor: "nationalCourtRegister",
      colSpan: 10,
    },
    {
      header: translations.addressLabel,
      title: translations.addressTitle,
      accessor: "address",
      colSpan: 10,
    },
    {
      header: translations.notesLabel,
      title: translations.notesTitle,
      accessor: "notes",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];

  return tableColumns;
};

const taxiListingTableHelper = {
  getColumns,
};

export default taxiListingTableHelper;
