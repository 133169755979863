import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import taxiUnsettledOrderListingFilterHelper from "../../taxi-unsettled-order-listing-filter.helper";
import TaxiUnsettledOrderListingFilter from "../../types/taxi-unsettled-order-listing-filter";
import TaxiUnsettledOrderListingFilterSelectOption from "../../types/taxi-unsettled-order-listing-filter-select-option";

type TaxiUnsettledOrderListingFiltersSelectProps = {
  filters: TaxiUnsettledOrderListingFilter[];
  onAddNewFilter: (filter: TaxiUnsettledOrderListingFilter) => void;
};

const TaxiUnsettledOrderListingFiltersSelectComponent: FC<
  TaxiUnsettledOrderListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: TaxiUnsettledOrderListingFilterSelectOption[] =
    useMemo(() => {
      return taxiUnsettledOrderListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: TaxiUnsettledOrderListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as TaxiUnsettledOrderListingFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="taxi-unsettled-order-listing-filter"
    />
  );
};

export default TaxiUnsettledOrderListingFiltersSelectComponent;
