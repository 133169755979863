import { FC, useEffect, useState } from "react";

type TimeCountdownProps = {
  dateTo: Date;
};

const TimeCountdownComponent: FC<TimeCountdownProps> = (props) => {
  const [timeString, setTimeString] = useState("");

  const formatNumber = (number: number): string => {
    return `${number}`.padStart(2, "0");
  };

  const createTimeString = (timeLeft: number): string => {
    const format = "hh:mm:ss";

    if (timeLeft < 0) {
      const dateAsString = format
        .replace(`hh`, "00")
        .replace(`mm`, "00")
        .replace(`ss`, "00");

      return dateAsString;
    }

    const seconds = (timeLeft / 1000) % 60;
    const minutes = (timeLeft / 1000 / 60) % 60;
    const hours = timeLeft / 1000 / 60 / 60;

    const dateAsString = format
      .replace(`hh`, formatNumber(Math.floor(hours)))
      .replace(`mm`, formatNumber(Math.floor(minutes)))
      .replace(`ss`, formatNumber(Math.floor(seconds)));

    return dateAsString;
  };

  const computeAndSetTimeString = () => {
    const timeLeft = new Date(props.dateTo).getTime() - new Date().getTime();

    const timeString = createTimeString(timeLeft);

    setTimeString(timeString);
  };

  useEffect(() => {
    computeAndSetTimeString();

    const timeCountdownInterval = setInterval(computeAndSetTimeString, 1000);

    return () => {
      clearInterval(timeCountdownInterval);
    };
  }, []);

  return <div>{timeString}</div>;
};

export default TimeCountdownComponent;

TimeCountdownComponent.defaultProps = {};
