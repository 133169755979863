import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import DriverDetailsActiveRoutePlansComponent from "../details/active-route-plans/driver-details-active-route-plans.component";
import driverPlanApiService from "./common/api/driver-plan-api.service";
import DriverPlanDataResponse from "./common/api/driver-plan-data.response";
import UserDriverPlanRouteParams from "../../common/routes/types/user-driver-plan-route-params";
import Driver from "./common/types/driver";

type DriverPlanProps = {};

const DriverPlanComponent: FC<DriverPlanProps> = () => {
  const { driverUuid } = useParams<UserDriverPlanRouteParams>();
  const [driverData, setDriverData] = useState<Driver>({
    last_name: "",
    first_name: "",
  });

  const handleDataResponse = (response: DriverPlanDataResponse) => {
    if (response?.status !== 200) return;
    setDriverData(response?.data);
  };

  useEffect(() => {
    if (!driverUuid) return;

    driverPlanApiService
      .fetchDriverPlanData(driverUuid)
      .then(handleDataResponse);
  }, [driverUuid]);

  if (!driverUuid) return null;

  const { first_name, last_name } = driverData;

  return (
    <>
      <HeadingComponent title={`${first_name} ${last_name}`} />
      <DriverDetailsActiveRoutePlansComponent
        driverUuid={driverUuid}
        isVisible={true}
        asCardComponent={true}
      />
    </>
  );
};

export default DriverPlanComponent;
