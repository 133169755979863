import ListingFilterBadge from "../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import CargoTaxiContractListingFilter, {
  CargoTaxiContractListingBillingModelFilter,
  CargoTaxiContractListingTaxiCorporationFilter,
} from "../../common/types/cargo-taxi-contract-listing-filter";
import CargoTaxiContractListingFilterType from "../../common/types/cargo-taxi-contract-listing-filter-type";

const getBadgeOptions = (filter: CargoTaxiContractListingFilter) => {
  const translations =
    cargoTranslationsHelper.getTaxiContractListingTranslations().filters;

  const options: {
    badge: ListingFilterBadge;
    filter: CargoTaxiContractListingFilter;
  }[] = [
    {
      badge: {
        text: translations.billingModelFilterText.replace(
          "#{option}",
          String(filter.value)
        ),
        title: translations.billingModelFilterText.replace(
          "#{option}",
          String(filter.value)
        ),
      },
      filter: {
        type: CargoTaxiContractListingFilterType.BILLING_MODEL,
        value:
          filter.value as CargoTaxiContractListingBillingModelFilter["value"],
      },
    },
    {
      badge: {
        text: translations.taxiCorporationFilterText.replace(
          "#{query}",
          String(filter.value)
        ),
        title: translations.taxiCorporationFilterTitle.replace(
          "#{query}",
          String(filter.value)
        ),
      },
      filter: {
        type: CargoTaxiContractListingFilterType.TAXI_CORPORATION,
        value:
          filter.value as CargoTaxiContractListingTaxiCorporationFilter["value"],
      },
    },
  ];

  return options;
};

const getBadge = (
  filter: CargoTaxiContractListingFilter
): ListingFilterBadge | undefined => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: CargoTaxiContractListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const cargoTaxiContractListingFiltersBadgeListHelper = {
  getBadges,
};

export default cargoTaxiContractListingFiltersBadgeListHelper;
