import {
  TaxiOfficerEditRequest,
  TaxiOfficerEditRequestBody,
} from "./taxi-officer-edit.request";

const createBody = (
  data: TaxiOfficerEditRequest
): TaxiOfficerEditRequestBody => {
  return {
    user: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobilePrimary: data.mobilePrimary,
      mobilePrimaryPrefix: data.mobilePrimaryPrefix,
      mobileSecondary: data.mobileSecondary,
      mobileSecondaryPrefix: data.mobileSecondaryPrefix,
    },
    taxiCorporationId: data.taxiCorporationId,
  };
};

const taxiOfficerEditRequestFactory = {
  createBody,
};

export default taxiOfficerEditRequestFactory;
