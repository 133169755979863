import { FC, useEffect, useState } from "react";
import DriverDetailsDriverPlanEntry from "../common/types/driver-details-plan-entry";
import driverDetailsActiveRoutePlansByTaxiApiService from "./common/api/driver-details-active-route-plans-by-taxi-api.service";
import driverDetailsPlanByTaxiPlanEntryFactory from "./common/driver-details-plan-by-taxi-plan-entry.factory";
import LoaderComponent from "../../../../../../common/components/loader/loader.component";
import DriverDetailsActiveRoutePlansContentComponent from "../driver-details-active-route-plans-content.component";
import { useAppContext } from "../../../../../../context/app.context";
import DriverDetailsCandidatureComponent from "../../candidature/driver-details-candidature.component";
import DriverDetailsActiveRoutePlansByTaxiPlanResponse, {
  DriverDetailsActiveRoutePlansByTaxiPlanResponseData,
} from "./common/api/driver-details-active-route-plans-by-taxi-plan.response";

type DriverDetailsPlanByTaxiProps = {
  driverUuid: string;
  isVisible: boolean;
};

const DriverDetailsPlanByTaxiComponent: FC<DriverDetailsPlanByTaxiProps> = (
  props
) => {
  const { user } = useAppContext();

  const [isPlanFetching, setIsPlanFetching] = useState(true);

  const [planEntries, setPlanEntries] = useState<
    DriverDetailsDriverPlanEntry[]
  >([]);

  const onPlanFetchSuccess = (
    responseData: DriverDetailsActiveRoutePlansByTaxiPlanResponseData
  ) => {
    const planEntries =
      driverDetailsPlanByTaxiPlanEntryFactory.createPlanEntries(
        responseData.entries
      );

    setPlanEntries(planEntries);
  };

  const onPlanFetchFailure = () => {};

  const handlePlanResponse = (
    response: DriverDetailsActiveRoutePlansByTaxiPlanResponse
  ) => {
    if (response.status === 200) {
      onPlanFetchSuccess(response.data);
      return;
    }

    onPlanFetchFailure();
  };

  const fetchPlan = () => {
    setIsPlanFetching(true);

    const taxiCorporationUuid =
      user?.aspects.taxiOfficer?.taxiCorporationUuid ??
      user?.aspects.dealer?.taxiCorporationUuid!;

    driverDetailsActiveRoutePlansByTaxiApiService
      .fetchPlan(props.driverUuid, taxiCorporationUuid)
      .then(handlePlanResponse)
      .catch(onPlanFetchFailure)
      .finally(() => setIsPlanFetching(false));
  };

  useEffect(() => {
    if (!props.isVisible || planEntries.length) {
      return;
    }

    fetchPlan();
  }, [props.isVisible, props.driverUuid]);

  if (isPlanFetching && !planEntries.length) {
    return (
      <div className="driver_details_active_route_plans_loader_wrapper">
        <LoaderComponent type="primary" />
      </div>
    );
  }

  return (
    <>
      <DriverDetailsCandidatureComponent
        driverUuid={props.driverUuid}
        isVisible={props.isVisible}
        onAcceptCandidatureSuccess={fetchPlan}
        currentPlanEntries={planEntries}
      />
      <DriverDetailsActiveRoutePlansContentComponent
        driverUuid={props.driverUuid}
        planEntries={planEntries}
        refetchPlan={fetchPlan}
      />
    </>
  );
};

export default DriverDetailsPlanByTaxiComponent;
