import { useMemo } from "react";
import usePagination from "./use-pagination";

type ClientSidePaginationParams<T> = {
  data: T[];
  totalResults: number;
  defaultPageSize?: number;
  defaultPage?: number;
};

const useClientSidePagination = <T extends {}>(
  params: ClientSidePaginationParams<T>
) => {
  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: params.totalResults,
    defaultPageSize: params.defaultPageSize,
    defaultPage: params.defaultPage,
  });

  const pageData = useMemo(() => {
    const minimumItemIndex = (page - 1) * pageSize;
    const maximumItemIndex = (page - 1) * pageSize + pageSize;

    return params.data.slice(minimumItemIndex, maximumItemIndex);
  }, [params.data, page, pageSize]);

  return {
    page,
    pageSize,
    setPage,
    setPageSize,
    pageData,
    totalResults: params.totalResults,
  };
};

export default useClientSidePagination;
