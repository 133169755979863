import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import OrderDetailsForwardingFormData from "./types/order-details-forwarding-form-data";
import OrderDetailsForwardingFormValidationResults from "./types/order-details-forwarding-form-validation-results";

const getDefaultFormData = (): OrderDetailsForwardingFormData => {
  return {
    taxiCorporation: null,
    taxiContract: null,
  };
};

const getDefaultFormValidationResults =
  (): OrderDetailsForwardingFormValidationResults => {
    return {
      taxiCorporation: formValidationService.defaultValidationResult,
      taxiContract: formValidationService.defaultValidationResult,
    };
  };

const orderDetailsForwardingFormHelper = {
  getDefaultFormValidationResults,
  getDefaultFormData,
};

export default orderDetailsForwardingFormHelper;
