import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/app.context";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import ButtonComponent from "../../../../common/components/button/button.component";
import notificationService from "../../../../common/utils/notification/notification.service";
import PhoneNumberInputComponent from "../../../../common/components/form/input/phone-number/phone-number-input.component";
import operatorAddFormHelper from "./common/operator-add-form.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import useForm from "../../../../common/components/form/use-form";
import useAbort from "../../../../common/hooks/use-abort";
import OperatorAddParams from "../../../../common/services/operator/add/operator-add-params";
import operatorAddParamsFactory from "./common/types/operator-add-params.factory";
import operatorService from "../../../../common/services/operator/operator.service";

type OperatorAddProps = {};

const OperatorAddComponent: FC<OperatorAddProps> = () => {
  const [isOperatorAddPending, setIsOperatorAddPending] = useState(false);

  const operatorAddAbort = useAbort();
  const navigate = useNavigate();
  const translations = userTranslationsHelper.getOperatorAddTranslations();

  const form = useForm({
    emptyValues: operatorAddFormHelper.getDefaultFormData(),
    validationDefinition: operatorAddFormHelper.getValidationDefinition(),
  });

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getOperatorAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const navigateToListing = () => {
    navigate(userRoutesHelper.getOperatorListingRoute());
  };

  const onOperatorAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigateToListing();
  };

  const onOperatorAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const addOperator = async () => {
    setIsOperatorAddPending(true);

    const params: OperatorAddParams = operatorAddParamsFactory.create(
      form.values
    );

    try {
      await operatorService.add(params, operatorAddAbort.signal);

      onOperatorAddSuccess();
    } catch {
      onOperatorAddFailure();
    } finally {
      setIsOperatorAddPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid: boolean = await form.validateAll();

    if (!isFormValid) return;

    addOperator();
  };

  return (
    <div className="operator_add">
      <HeadingComponent title={translations.header.headingLabel} />
      <CardComponent>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.emailLabel}
              isRequired
              errorMessage={form.validationResults.email.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.emailPlaceholder}
                value={form.values.email}
                onChange={(value) => form.setValue("email", value)}
                onBlur={() => form.validate("email")}
                hasError={!!form.validationResults.email.errorMessage}
                idForTesting={`email`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.usernameLabel}
              isRequired
              errorMessage={form.validationResults.username.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.usernamePlaceholder}
                value={form.values.username}
                onChange={(value) => form.setValue("username", value)}
                onBlur={() => form.validate("username")}
                hasError={!!form.validationResults.username.errorMessage}
                idForTesting={`username`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.firstNameLabel}
              isRequired
              errorMessage={form.validationResults.firstName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.firstNamePlaceholder}
                value={form.values.firstName}
                onChange={(value) => form.setValue("firstName", value)}
                onBlur={() => form.validate("firstName")}
                hasError={!!form.validationResults.firstName.errorMessage}
                idForTesting={`first-name`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.lastNameLabel}
              isRequired
              errorMessage={form.validationResults.lastName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.lastNamePlaceholder}
                value={form.values.lastName}
                onChange={(value) => form.setValue("lastName", value)}
                onBlur={() => form.validate("lastName")}
                hasError={!!form.validationResults.lastName.errorMessage}
                idForTesting={`last-name`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.phoneNumberLabel}
              isRequired
              errorMessage={form.validationResults.phoneNumber.errorMessage}
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.phoneNumberPlaceholder}
                phoneNumber={form.values.phoneNumber}
                onChange={(value) => {
                  form.setValue("phoneNumber", value);
                }}
                onBlur={() => form.validate("phoneNumber")}
                hasError={!!form.validationResults.phoneNumber.errorMessage}
                idForTesting={`phone-number`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.alternativePhoneNumberLabel}
              errorMessage={
                form.validationResults.alternativePhoneNumber.errorMessage
              }
            >
              <PhoneNumberInputComponent
                placeholder={
                  translations.form.alternativePhoneNumberPlaceholder
                }
                phoneNumber={form.values.alternativePhoneNumber}
                onChange={(value) => {
                  form.setValue("alternativePhoneNumber", value);
                }}
                onBlur={() => form.validate("alternativePhoneNumber")}
                hasError={
                  !!form.validationResults.alternativePhoneNumber.errorMessage
                }
                idForTesting={`alternative-phone-number`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.passwordLabel}
              isRequired
              errorMessage={form.validationResults.password.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.passwordPlaceholder}
                value={form.values.password}
                type="password"
                onChange={(value) => form.setValue("password", value)}
                onBlur={() => form.validate("password")}
                hasError={!!form.validationResults.password.errorMessage}
                idForTesting={`password`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.passwordRepeatLabel}
              isRequired
              errorMessage={form.validationResults.passwordRepeat.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.passwordRepeatPlaceholder}
                value={form.values.passwordRepeat}
                type="password"
                onChange={(value) => form.setValue("passwordRepeat", value)}
                onBlur={() => form.validate("passwordRepeat")}
                hasError={!!form.validationResults.passwordRepeat.errorMessage}
                idForTesting={`password-repeat`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        title={translations.form.submitButtonTitle}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
        isDisabled={isOperatorAddPending}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </div>
  );
};
export default OperatorAddComponent;
