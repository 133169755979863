import ChartData, {
  ChartDataGroup,
  ChartDataGroupItem,
  ChartDataGroupItems,
  ChartDataItemGroupPoint,
} from "./types/chart-data";
import { ChartDataResults } from "./types/chart-data-results";
import chartHelper from "./chart.helper";
import ChartLabelComponent, {
  ChartLabelTextAnchor,
} from "./chart-label.component";
import { useMemo } from "react";

type ChartProps = {
  data: ChartData;
  barLabelsLeftPadding?: number;
  barLabelsTopPadding?: number;
  barLabelsAngle?: number;
  topLabelFontSize?: number;
  referenceGroupValue?: number;
};

const ALL_POINTS_KEY = "allPoints";

const useChart = (props: ChartProps) => {
  const result: ChartDataResults = {
    labels: [],
    data: [],
    groupedPoints: {},
    uniqueTracks: [],
  };

  const createBarLabels = (
    index: number,
    point: ChartDataItemGroupPoint,
    i: ChartDataGroupItems
  ) => {
    result.labels.push(
      <ChartLabelComponent
        dy={props.barLabelsLeftPadding}
        dx={props.barLabelsTopPadding}
        key={index}
        text={point.label!}
        x={i.xAxisValue}
        y={new Date(point.date)}
        barLabelsAngle={props.barLabelsAngle}
        fontSize={props.topLabelFontSize}
        textAnchor={ChartLabelTextAnchor.START}
      />
    );
  };

  const createInlineBarLabels = (
    groupItem: ChartDataGroupItem,
    i: ChartDataGroupItems
  ) => {
    const dataFrom = groupItem.points[0].date.getTime();

    const yAxisValue = chartHelper.calculateMiddlePoint(
      dataFrom,
      groupItem.points[groupItem.points.length - 1].date.getTime()
    );

    result.labels.push(
      <ChartLabelComponent
        key={yAxisValue}
        text={groupItem.barLabel!}
        x={i.xAxisValue}
        y={new Date(yAxisValue)}
        fontSize={props.topLabelFontSize}
        textAnchor={ChartLabelTextAnchor.MIDDLE}
      />
    );
  };

  const mapData = (
    point: ChartDataItemGroupPoint,
    groupItem: ChartDataGroupItem,
    i: ChartDataGroupItems
  ) => {
    result.data.push({
      x: i.xAxisValue,
      y: point.date,
      y0: groupItem.points[groupItem.points.length - 1].date,
      label: point.label,
      barLabel: groupItem.barLabel,
      fill: groupItem.color,
      opacity: groupItem.opacity,
      stroke: groupItem.borderColor,
      strokeWidth: groupItem.borderWidth,
      tooltipLabel: point.tooltipLabel,
    });
  };

  const pushPointsToGroupedPoints = (
    groupItem: ChartDataGroupItem,
    groupName: string | number
  ) => {
    if (!result.groupedPoints[groupName]) result.groupedPoints[groupName] = [];
    result.groupedPoints[groupName!].push(...groupItem.points);
  };

  const createChartData = () => {
    props.data.group.forEach((item: ChartDataGroup) => {
      if (item.topLabel && !result.uniqueTracks.includes(item.topLabel))
        result.uniqueTracks.push(item.topLabel);

      item.items.forEach((i) => {
        i.items.forEach((groupItem, index) => {
          createInlineBarLabels(groupItem, i);

          pushPointsToGroupedPoints(groupItem, item.topLabel!);
          pushPointsToGroupedPoints(groupItem, ALL_POINTS_KEY);
          pushPointsToGroupedPoints(groupItem, i.xAxisValue);

          groupItem.points.forEach((point) => {
            mapData(point, groupItem, i);
            createBarLabels(index, point, i);
          });
        });
      });
    });
  };

  createChartData();
  const tracks = result.uniqueTracks;
  const ticksAndDays = useMemo(
    () =>
      chartHelper.calculateTicksAndDays(
        props.referenceGroupValue!,
        result.groupedPoints[ALL_POINTS_KEY],
        result.groupedPoints
      ),
    [props.data]
  );
  const days = ticksAndDays.days;
  const ticks = ticksAndDays.result;

  return {
    result,
    tracks,
    days,
    ticks,
  };
};

export default useChart;
