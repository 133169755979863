export enum OrderJoinOrderDetailsDriverRole {
  ASSIGNED = "ASSIGNED",
  CANDIDATE = "CANDIDATE",
}

type OrderJoinOrderDetailsDriver = {
  uuid: string;
  displayName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: OrderJoinOrderDetailsDriverRole;
};

export default OrderJoinOrderDetailsDriver;
