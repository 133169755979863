import { faEnvelope, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mapMarkerIconFactory from "../../../../../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../../../../../common/components/map/types/map-marker";
import DriverEditAddress from "../../../types/driver-edit-address";

import driverEditUserDataAddressHelper from "./driver-edit-user-data-address.helper";
import AddressType from "../../../../../../../../common/types/address-type";

const createMapMarker = (address: DriverEditAddress): MapMarker => {
  const homeIcon = (
    <FontAwesomeIcon
      icon={faHome}
      className="driver_add_user_add_address__map_marker_signature_icon"
    />
  );

  const startingMarkerIcon = mapMarkerIconFactory.createIcon({
    content: homeIcon,
    className: "map_marker standard",
  });

  const envelopeIcon = (
    <FontAwesomeIcon
      icon={faEnvelope}
      className="driver_add_user_add_address__map_marker_signature_icon"
    />
  );

  const mailingMarkerIcon = mapMarkerIconFactory.createIcon({
    content: envelopeIcon,
    className: "map_marker standard",
  });

  return {
    coordinate: {
      latitude: address.latitude,
      longitude: address.longitude,
    },
    title: driverEditUserDataAddressHelper.getAddressLabel(address),
    tooltip: driverEditUserDataAddressHelper.getAddressLabel(address),
    icon:
      address.type === AddressType.MAILING
        ? mailingMarkerIcon
        : address.type === AddressType.STARTING
        ? startingMarkerIcon
        : undefined,
  };
};

const createMapMarkers = (addresses: DriverEditAddress[]): MapMarker[] => {
  return addresses.map(createMapMarker);
};

const driverEditUserDataAddressFactory = {
  createMapMarkers,
};

export default driverEditUserDataAddressFactory;
