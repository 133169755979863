import ListingFilterBadge from "../../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import DriverContractListingByRailyFilter, {
  DriverContractListingByRailyBillingModelFilter,
  DriverContractListingByRailyTaxiCorporationFilter,
} from "../../common/types/driver-contract-listing-by-raily-filter";
import DriverContractListingByRailyFilterType from "../../common/types/driver-contract-listing-by-raily-filter-type";

const getBadgeOptions = (filter: DriverContractListingByRailyFilter) => {
  const translations =
    userTranslationsHelper.getDriverContractListingTranslations().filters;

  const options: {
    badge: ListingFilterBadge;
    filter: DriverContractListingByRailyFilter;
  }[] = [
    {
      badge: {
        text: translations.billingModelFilterText.replace(
          "#{option}",
          filter.value
        ),
        title: translations.billingModelFilterText.replace(
          "#{option}",
          filter.value
        ),
      },
      filter: {
        type: DriverContractListingByRailyFilterType.BILLING_MODEL,
        value:
          filter.value as DriverContractListingByRailyBillingModelFilter["value"],
      },
    },
    {
      badge: {
        text: translations.taxiCorporationFilterText.replace(
          "#{query}",
          filter.value
        ),
        title: translations.taxiCorporationFilterTitle.replace(
          "#{query}",
          filter.value
        ),
      },
      filter: {
        type: DriverContractListingByRailyFilterType.TAXI_CORPORATION,
        value:
          filter.value as DriverContractListingByRailyTaxiCorporationFilter["value"],
      },
    },
  ];

  return options;
};

const getBadge = (
  filter: DriverContractListingByRailyFilter
): ListingFilterBadge | undefined => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: DriverContractListingByRailyFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const driverListingByRailyFiltersBadgeListHelper = {
  getBadges,
};

export default driverListingByRailyFiltersBadgeListHelper;
