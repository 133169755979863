import RouteActiveListingTableColumn from "../common/types/route-active-listing-table-column";
import routeTranslationsHelper from "../../../../languages/route-translations.helper";
import { RouteActiveListingUserPermissions } from "../common/user-permissions/route-active-listing-user-permission";

const getColumns = (
  userPermissions: RouteActiveListingUserPermissions
): RouteActiveListingTableColumn[] => {
  const translations =
    routeTranslationsHelper.getActiveListingTranslations().table.headers;

  const taxiCorporationColumn: RouteActiveListingTableColumn = {
    header: translations.taxiCorporationLabel,
    title: translations.taxiCorporationTitle,
    accessor: "taxiCorporation",
    colSpan: 10,
  };

  const tableColumns: RouteActiveListingTableColumn[] = [
    {
      header: translations.startDateLabel,
      title: translations.startDateTitle,
      accessor: "startDate",
      colSpan: 10,
    },
    {
      header: translations.humanIdLabel,
      title: translations.humanIdTitle,
      accessor: "humanId",
      colSpan: 5,
    },
    {
      header: translations.cargoOrderHumanIdsLabel,
      title: translations.cargoOrderHumanIdsTitle,
      accessor: "cargoOrderHumanIds",
      colSpan: 5,
    },
    {
      header: translations.routeLabel,
      title: translations.routeTitle,
      accessor: "route",
      colSpan: 15,
    },
    {
      header: translations.passengersLabel,
      title: translations.passengersLabel,
      accessor: "passengers",
      colSpan: 10,
    },
    ...(userPermissions.hasAccessToTaxiCorporation
      ? [taxiCorporationColumn]
      : []),
    {
      header: translations.driverLabel,
      title: translations.driverTitle,
      accessor: "driver",
      colSpan: 10,
    },
  ];

  return tableColumns;
};

const routeActiveListingTableHelper = {
  getColumns,
};

export default routeActiveListingTableHelper;
