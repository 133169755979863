import HttpResponse from "../../../../utils/http/http.response";

export enum TaxiOfficerListResponseItemContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type TaxiOfficerListResponseItemFleetPartner = {
  id: string;
  modifiedAt: string | null;
  modifiedBy: string | null;
  createdAt: string;
  createdBy: string;
  taxiCorporationId: string;
  name: string;
};

export type TaxiOfficerListResponseItemTaxiOfficer = {
  taxiCorporationId: string;
  taxiCorporation: TaxiOfficerListResponseItemTaxiCorporation | null;
};

export type TaxiOfficerListResponseItemTaxiCorporation = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: TaxiOfficerListResponseItemContractMaintenancePolicy;
  notes: string | null;
  id: string;
  modifiedAt: string | null;
  modifiedBy: string | null;
  createdAt: string;
  createdBy: string;
  fleetPartners: TaxiOfficerListResponseItemFleetPartner[];
};

export type TaxiOfficerListResponseItem = {
  removedBy: string | null;
  removedAt: Date | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
  taxiOfficer: TaxiOfficerListResponseItemTaxiOfficer;
};

type TaxiOfficerListResponse = HttpResponse<{
  data: TaxiOfficerListResponseItem[];
  totalCount: number;
}>;

export default TaxiOfficerListResponse;
