import { CargoCompanyListItem } from "../../../../../../common/services/cargo-company/cargo-company/list/cargo-company-list";
import CargoCompanyListingItem from "./types/cargo-company-listing-item";

const createListingItem = (
  listItem: CargoCompanyListItem
): CargoCompanyListingItem => {
  return {
    uuid: listItem.uuid,
    companyName: listItem.name,
    displayName: listItem.displayName,
    taxNumber: listItem.taxNumber,
    companyId: listItem.companyId ?? "",
    nationalCourtRegister: listItem.nationalCourtRegister ?? "",
    address: listItem.address,
    notes: listItem.notes ?? "",
  };
};

const createListingItems = (
  listItems: CargoCompanyListItem[]
): CargoCompanyListingItem[] => {
  return listItems.map(createListingItem);
};

const cargoCompanyListingItemFactory = {
  createListingItems,
};

export default cargoCompanyListingItemFactory;
