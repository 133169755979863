import CargoOrderSettlementListingFilterType from "./types/cargo-order-settlement-listing-filter-type";
import CargoOrderSettlementListingSortKey from "./types/cargo-order-settlement-listing-sort-key";
import CargoOrderSettlementListingFilter, {
  CargoOrderSettlementListingDispatchFilter,
  CargoOrderSettlementListingExternalOrderIdFilter,
  CargoOrderSettlementListingInternalOrderIdFilter,
  CargoOrderSettlementListingPassengerFilter,
  CargoOrderSettlementListingBillingStatusFilter,
  CargoOrderSettlementListingTaxiFilter,
  CargoOrderSettlementListingRouteAddressFilter,
  CargoOrderSettlementListingRoutePickupAddressFilter,
  CargoOrderSettlementListingOrderStartDateFilter,
  CargoOrderSettlementListingRouteDestinationAddressFilter,
  CargoOrderSettlementListingRouteIntermediateAddressFilter,
} from "./types/cargo-order-settlement-listing-filter";
import CargoOrderSettlementListingReportRequest, {
  CargoOrderSettlementListingReportRequestOrder,
} from "./api/cargo-order-settlement-listing-report.request";

const createRequestOrder = (
  sortKey: CargoOrderSettlementListingSortKey | null
): CargoOrderSettlementListingReportRequest["order"] => {
  const options: {
    sortKey: CargoOrderSettlementListingSortKey;
    requestOrder: CargoOrderSettlementListingReportRequestOrder;
  }[] = [
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.DATE_DESC,
      sortKey: CargoOrderSettlementListingSortKey.ORDER_START_DATE_DESC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.DATE_ASC,
      sortKey: CargoOrderSettlementListingSortKey.ORDER_START_DATE_ASC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.DISTANCE_DESC,
      sortKey: CargoOrderSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.DISTANCE_ASC,
      sortKey: CargoOrderSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: CargoOrderSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: CargoOrderSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.HUMAN_ID_ASC,
      sortKey: CargoOrderSettlementListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.HUMAN_ID_DESC,
      sortKey: CargoOrderSettlementListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.TAXI_CORPORATION_ASC,
      sortKey: CargoOrderSettlementListingSortKey.TAXI_CORPORATION_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.TAXI_CORPORATION_DESC,
      sortKey: CargoOrderSettlementListingSortKey.TAXI_CORPORATION_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.EXTERNAL_ORDER_ID_ASC,
      sortKey: CargoOrderSettlementListingSortKey.EXTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.EXTERNAL_ORDER_ID_DESC,
      sortKey: CargoOrderSettlementListingSortKey.EXTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.DISPATCH_ASC,
      sortKey: CargoOrderSettlementListingSortKey.DISPATCH_ASC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.DISPATCH_DESC,
      sortKey: CargoOrderSettlementListingSortKey.DISPATCH_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: CargoOrderSettlementListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: CargoOrderSettlementListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.HALTING_HOURS_ASC,
      sortKey: CargoOrderSettlementListingSortKey.STOPOVER_TIME_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.HALTING_HOURS_DESC,
      sortKey: CargoOrderSettlementListingSortKey.STOPOVER_TIME_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.HALTING_AMOUNT_ASC,
      sortKey: CargoOrderSettlementListingSortKey.STOPOVER_COST_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.HALTING_AMOUNT_DESC,
      sortKey: CargoOrderSettlementListingSortKey.STOPOVER_COST_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: CargoOrderSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: CargoOrderSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.DISCOUNT_ASC,
      sortKey: CargoOrderSettlementListingSortKey.DISCOUNT_SAVINGS_ASC,
    },
    {
      requestOrder: CargoOrderSettlementListingReportRequestOrder.DISCOUNT_DESC,
      sortKey: CargoOrderSettlementListingSortKey.DISCOUNT_SAVINGS_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.DISCOUNT_AMOUNT_ASC,
      sortKey: CargoOrderSettlementListingSortKey.DISCOUNT_AMOUNT_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.DISCOUNT_AMOUNT_DESC,
      sortKey: CargoOrderSettlementListingSortKey.DISCOUNT_AMOUNT_DESC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: CargoOrderSettlementListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder:
        CargoOrderSettlementListingReportRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: CargoOrderSettlementListingSortKey.TOTAL_COST_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  filters: CargoOrderSettlementListingFilter[],
  sortKey: CargoOrderSettlementListingSortKey | null
): CargoOrderSettlementListingReportRequest => {
  return {
    order: createRequestOrder(sortKey),
    human_id: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | CargoOrderSettlementListingInternalOrderIdFilter["value"]
      | undefined,
    address: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.ROUTE_ADDRESS
    )?.value as
      | CargoOrderSettlementListingRouteAddressFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type ===
        CargoOrderSettlementListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | CargoOrderSettlementListingRoutePickupAddressFilter["value"]
      | undefined,
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        CargoOrderSettlementListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | CargoOrderSettlementListingRouteIntermediateAddressFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        CargoOrderSettlementListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | CargoOrderSettlementListingRouteDestinationAddressFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.PASSENGER
    )?.value as CargoOrderSettlementListingPassengerFilter["value"] | undefined,
    taxi: filters.find(
      (filter) => filter.type === CargoOrderSettlementListingFilterType.TAXI
    )?.value as CargoOrderSettlementListingTaxiFilter["value"] | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | CargoOrderSettlementListingExternalOrderIdFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) => filter.type === CargoOrderSettlementListingFilterType.DISPATCH
    )?.value as CargoOrderSettlementListingDispatchFilter["value"] | undefined,
    status: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.BILLING_STATUS
    )?.value as
      | CargoOrderSettlementListingBillingStatusFilter["value"]
      | undefined,
    date_since: (
      filters.find(
        (filter) =>
          filter.type === CargoOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | CargoOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    date_to: (
      filters.find(
        (filter) =>
          filter.type === CargoOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | CargoOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
  };
};

const cargoOrderSettlementListingReportRequestFactory = {
  createRequest,
};

export default cargoOrderSettlementListingReportRequestFactory;
