import {
  useRef,
  RefObject,
  ReactNode,
  FC,
  ChangeEvent,
  MouseEvent,
} from "react";
import classNames from "classnames";
import ComponentClassnames from "../../../types/component-classnames";

type CheckboxProps = {
  children?: ReactNode;
  isChecked: boolean;
  onChange: (value: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  classNames?: ComponentClassnames;
  hasError?: boolean;
  isDisabled?: boolean;
  title?: string;
};

const CheckboxComponent: FC<CheckboxProps> = (props) => {
  const checkboxClassnames = classNames(
    "checkbox",
    props.classNames?.root,
    props.isDisabled && "disabled"
  );
  const checkboxMarkClassnames = classNames(
    "checkbox__checkmark",
    props.hasError && "error"
  );

  const checkboxCheckmarkRef: RefObject<HTMLSpanElement> | null = useRef(null);

  const onInputFocus = () => {
    checkboxCheckmarkRef?.current?.classList.add("active");
  };

  const onInputBlur = () => {
    checkboxCheckmarkRef?.current?.classList.remove("active");
  };

  const onClick = (event: MouseEvent<HTMLLabelElement>) => {
    event.stopPropagation();
  };

  return (
    <label
      key={`checkbox-value-${String(props.isChecked)}`}
      className={checkboxClassnames}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      title={props.title}
      onClick={onClick}
    >
      <input
        type="checkbox"
        className="checkbox__input"
        checked={props.isChecked}
        onFocus={onInputFocus}
        onChange={(event) => props.onChange(event.target.checked, event)}
      />
      <span
        className={checkboxMarkClassnames}
        ref={checkboxCheckmarkRef}
      ></span>
      <span className="checkbox__content">{props.children}</span>
    </label>
  );
};

export default CheckboxComponent;
