import { FC } from "react";
import orderListingTableRouteFactory from "../../../common/table/route/order-listing-table-route.factory";

type OrderActiveListingTableRouteProps = {
  addresses: (string | string[])[];
};

const OrderActiveListingTableRouteComponent: FC<
  OrderActiveListingTableRouteProps
> = (props) => {
  const addressesString = orderListingTableRouteFactory.createRouteString(
    props.addresses
  );

  return (
    <div
      title={props.addresses.join("\r")}
      className="order_active_listing_table_route"
    >
      {addressesString}
    </div>
  );
};

export default OrderActiveListingTableRouteComponent;
