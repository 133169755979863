
import appConfig from "../../../../../../app.config";
import urlService from "../../../../../../common/utils/url/url.service";
import authService from "../../../../../auth/common/auth.service";
import RelatedCargoRailyBillingsResponse from "./related-cargo-raily-billings.response";
import RelatedTaxiRailyBillingsResponse from "./related-taxi-raily-billings.response";

const fetchTaxiRailyRelatedBillings = (
  orderId: string
): Promise<RelatedTaxiRailyBillingsResponse> => {
  const path = `/billings/orders/${orderId}/related/taxi-raily`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchCargoRailyRelatedBillings = (
  orderId: string
): Promise<RelatedCargoRailyBillingsResponse> => {
  const path = `/billings/orders/${orderId}/related/cargo-raily`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const relatedBillingsApiService = {
  fetchTaxiRailyRelatedBillings,
  fetchCargoRailyRelatedBillings,
};

export default relatedBillingsApiService;
