import isEqual from "lodash/isEqual";
import TaxiUnsettledPrivateOrderListingFilterSelectOption, {
  TaxiUnsettledPrivateOrderListingInternalOrderIdFilterSelectOption,
  TaxiUnsettledPrivateOrderListingStatusFilterSelectOption,
} from "../types/taxi-unsettled-private-order-listing-filter-select-option";
import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiUnsettledPrivateOrderListingFilterType from "../types/taxi-unsettled-private-order-listing-filter-type";
import TaxiUnsettledPrivateOrderListingItemStatus from "../types/taxi-unsettled-private-order-listing-item-status";
import taxiUnsettledPrivateOrderListingHelper from "../taxi-unsettled-private-order-listing.helper";
import TaxiUnsettledPrivateOrderListingFilter from "../types/taxi-unsettled-private-order-listing-filter";

const getSearchQueryOptions = (
  query: string
): TaxiUnsettledPrivateOrderListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiUnsettledPrivateOrderListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByClientLabel = (query: string) => {
    return searchFilterTranslations.searchByClientTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExcludeClientLabel = (query: string) => {
    return searchFilterTranslations.searchByExcludeClientTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDispatchLabel = (query: string) => {
    return searchFilterTranslations.searchByDispatchTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDriverLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByExternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengerLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByClientLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.CLIENT,
        value: query,
      },
    },
    {
      label: getSearchByExcludeClientLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.EXCLUDE_CARGO_COMPANY,
        value: query,
      },
    },
    {
      label: getSearchByDispatchLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.DISPATCH,
        value: query,
      },
    },
    {
      label: getSearchByDriverLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByExternalOrderIdLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: query,
      },
    },
    {
      label: getSearchByPassengerLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.PASSENGER,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressLabel(query),
      value: {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
  ];

  const options: TaxiUnsettledPrivateOrderListingFilterSelectOption[] = [
    ...stringOptions,
  ] as TaxiUnsettledPrivateOrderListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const internalOrderIdFilterOption: TaxiUnsettledPrivateOrderListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: TaxiUnsettledPrivateOrderListingFilterType.INTERNAL_ORDER_ID,
          value: parseStringToNumber(query)!,
        },
      };

    options.push(internalOrderIdFilterOption);
  }

  return options;
};

const getSearchStatusOptions = (
  query: string
): TaxiUnsettledPrivateOrderListingFilterSelectOption[] => {
  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiUnsettledPrivateOrderListingTranslations()
      .filters.search;

  const allStatusSelectOptions: TaxiUnsettledPrivateOrderListingStatusFilterSelectOption[] =
    Object.values(TaxiUnsettledPrivateOrderListingItemStatus).map((status) => {
      const statusLabel =
        searchFilterTranslations.searchByStatusTemplateText.replace(
          "#{option}",
          taxiUnsettledPrivateOrderListingHelper.getStatusText(status)
        );

      return {
        label: statusLabel,
        value: {
          type: TaxiUnsettledPrivateOrderListingFilterType.STATUS,
          value: status,
        },
      };
    });

  if (query) {
    return allStatusSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allStatusSelectOptions;
};

const getSelectOptionsByQuery = (
  query: string
): TaxiUnsettledPrivateOrderListingFilterSelectOption[] => {
  const statusOptions = getSearchStatusOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...statusOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: TaxiUnsettledPrivateOrderListingFilter[]
): TaxiUnsettledPrivateOrderListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const taxiUnsettledPrivateOrderListingFilterHelper = {
  getSelectOptions,
};

export default taxiUnsettledPrivateOrderListingFilterHelper;
