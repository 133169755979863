import TaxiTransferedOrderSettlementListingReportRequest, {
  TaxiTransferedOrderSettlementListingReportRequestOrder,
} from "../api/taxi-transfered-order-settlement-listing-report.request";
import TaxiTransferedOrderSettlementListingFilter, {
  TaxiTransferedOrderSettlementListingConsumerTaxiFilter,
  TaxiTransferedOrderSettlementListingInternalOrderIdFilter,
  TaxiTransferedOrderSettlementListingOrderStartDateFilter,
  TaxiTransferedOrderSettlementListingProducerTaxiFilter,
  TaxiTransferedOrderSettlementListingStatusFilter,
} from "../types/taxi-transfered-order-settlement-listing-filter";
import TaxiTransferedOrderSettlementListingFilterType from "../types/taxi-transfered-order-settlement-listing-filter-type";
import TaxiTransferedOrderSettlementListingSortKey from "../types/taxi-transfered-order-settlement-listing-sort-key";

const createRequestOrder = (
  sortKey: TaxiTransferedOrderSettlementListingSortKey | null
): TaxiTransferedOrderSettlementListingReportRequest["order"] => {
  const options: {
    sortKey: TaxiTransferedOrderSettlementListingSortKey;
    requestOrder: TaxiTransferedOrderSettlementListingReportRequestOrder;
  }[] = [
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.DATE_AT_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.ORDER_START_DATE_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.DATE_AT_DESC,
      sortKey:
        TaxiTransferedOrderSettlementListingSortKey.ORDER_START_DATE_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.HUMAN_ID_ASC,
      sortKey:
        TaxiTransferedOrderSettlementListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.HUMAN_ID_DESC,
      sortKey:
        TaxiTransferedOrderSettlementListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.DISTANCE_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.DISTANCE_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.DISTANCE_RATE_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.DISTANCE_RATE_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.HALTING_AMOUNT_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.STOPOVER_COST_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.HALTING_AMOUNT_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.STOPOVER_COST_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.TOTAL_COST_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.PRODUCER_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.PRODUCER_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.PRODUCER_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.PRODUCER_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.CONSUMER_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.CONSUMER_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.CONSUMER_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.CONSUMER_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.BILLING_MODEL_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.BILLING_MODEL_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingReportRequestOrder.BILLING_MODEL_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.BILLING_MODEL_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  taxiCorporationId: string | undefined,
  filters: TaxiTransferedOrderSettlementListingFilter[],
  sortKey: TaxiTransferedOrderSettlementListingSortKey | null
): TaxiTransferedOrderSettlementListingReportRequest => {
  return {
    order: createRequestOrder(sortKey),
    taxi_corporation_id: taxiCorporationId,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | TaxiTransferedOrderSettlementListingInternalOrderIdFilter["value"]
      | undefined,
    producer: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.PRODUCER_TAXI
    )?.value as
      | TaxiTransferedOrderSettlementListingProducerTaxiFilter["value"]
      | undefined,
    consumer: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.CONSUMER_TAXI
    )?.value as
      | TaxiTransferedOrderSettlementListingConsumerTaxiFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiTransferedOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiTransferedOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    status: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.BILLING_STATUS
    )?.value as
      | TaxiTransferedOrderSettlementListingStatusFilter["value"]
      | undefined,
  };
};

const taxiTransferedOrderSettlementListingReportRequestFactory = {
  createRequest,
};

export default taxiTransferedOrderSettlementListingReportRequestFactory;
