import {
  DecodedAccessTokenProfile,
  DecodedAccessTokenProfileAdmin,
  DecodedAccessTokenProfileCargoOfficer,
  DecodedAccessTokenProfileDealer,
  DecodedAccessTokenProfileDispatcher,
  DecodedAccessTokenProfileDriver,
  DecodedAccessTokenProfileDriverTaxiDriverAssociation,
  DecodedAccessTokenProfileOperator,
  DecodedAccessTokenProfilePassenger,
  DecodedAccessTokenProfilePassengerDispatch,
  DecodedAccessTokenProfileRailyOfficer,
  DecodedAccessTokenProfileTaxiOfficer,
} from "../../../modules/auth/common/types/decoded-access-token";
import User from "../../types/user";
import UserAspects, {
  UserAdminAspect,
  UserCargoOfficerAspect,
  UserDealerAspect,
  UserDispatcherAspect,
  UserDriverAspect,
  UserDriverAspectAssociationToTaxi,
  UserOperatorAspect,
  UserPassengerAspect,
  UserPassengerAspectDispatch,
  UserRailyOfficerAspect,
  UserTaxiOfficerAspect,
} from "../../types/user-aspects";
import UserRole from "../../types/user-role";

const createUserRoles = (
  accessTokenDecodedContentProfile: DecodedAccessTokenProfile
): UserRole[] => {
  const userRoles: UserRole[] = [];

  if (accessTokenDecodedContentProfile.admin) {
    userRoles.push(UserRole.ADMIN);
  }

  if (accessTokenDecodedContentProfile.cargo_officer) {
    userRoles.push(UserRole.CARGO_OFFICER);
  }

  if (accessTokenDecodedContentProfile.dealer) {
    userRoles.push(UserRole.DEALER);
  }

  if (accessTokenDecodedContentProfile.dispatcher) {
    userRoles.push(UserRole.DISPATCHER);
  }

  if (accessTokenDecodedContentProfile.driver) {
    userRoles.push(UserRole.DRIVER);
  }

  if (accessTokenDecodedContentProfile.operator) {
    userRoles.push(UserRole.OPERATOR);
  }

  if (accessTokenDecodedContentProfile.passenger) {
    userRoles.push(UserRole.PASSENGER);
  }

  if (accessTokenDecodedContentProfile.raily_officer) {
    userRoles.push(UserRole.RAILY_OFFICER);
  }

  if (accessTokenDecodedContentProfile.taxi_officer) {
    userRoles.push(UserRole.TAXI_OFFICER);
  }

  return userRoles;
};

const createAdminUserAspect = (
  tokenProfileAdmin: DecodedAccessTokenProfileAdmin | null
): UserAdminAspect | null => {
  if (!tokenProfileAdmin) {
    return null;
  }

  return {};
};

const createCargoOfficerUserAspect = (
  tokenProfileCargoOfficer: DecodedAccessTokenProfileCargoOfficer | null
): UserCargoOfficerAspect | null => {
  if (!tokenProfileCargoOfficer) {
    return null;
  }

  return {
    cargoCompanyUuid: tokenProfileCargoOfficer.cargo_company_id,
  };
};

const createDealerUserAspect = (
  tokenProfileDealer: DecodedAccessTokenProfileDealer | null
): UserDealerAspect | null => {
  if (!tokenProfileDealer) {
    return null;
  }

  return {
    taxiCorporationUuid: tokenProfileDealer.taxi_corporation_id,
  };
};

const createDispatcherUserAspect = (
  tokenProfileDispatcher: DecodedAccessTokenProfileDispatcher | null
): UserDispatcherAspect | null => {
  if (!tokenProfileDispatcher) {
    return null;
  }

  return {
    dispatchUuid: tokenProfileDispatcher.dispatch.id,
    cargoCompanyUuid: tokenProfileDispatcher.dispatch.cargo_company_id,
  };
};

const createDriverUserAspectAssociationToTaxi = (
  tokenProfileDriverTaxiAssociation: DecodedAccessTokenProfileDriverTaxiDriverAssociation
): UserDriverAspectAssociationToTaxi => {
  return {
    uuid: tokenProfileDriverTaxiAssociation.id,
    taxiCorporationUuid: tokenProfileDriverTaxiAssociation.taxi_corporation_id,
  };
};

const createDriverUserAspectAssociationsToTaxi = (
  tokenProfileDriverTaxiAssociations: DecodedAccessTokenProfileDriverTaxiDriverAssociation[]
): UserDriverAspectAssociationToTaxi[] => {
  return tokenProfileDriverTaxiAssociations.map(
    createDriverUserAspectAssociationToTaxi
  );
};

const createDriverUserAspect = (
  tokenProfileDriver: DecodedAccessTokenProfileDriver | null
): UserDriverAspect | null => {
  if (!tokenProfileDriver) {
    return null;
  }

  const associationsToTaxi = createDriverUserAspectAssociationsToTaxi(
    tokenProfileDriver.taxi_driver_associations
  );

  return {
    associationsToTaxi,
  };
};

const createOperatorUserAspect = (
  tokenProfileOperator: DecodedAccessTokenProfileOperator | null
): UserOperatorAspect | null => {
  if (!tokenProfileOperator) {
    return null;
  }

  return {};
};

const createPassengerUserAspectDispatch = (
  tokenProfilePassengerDispatch: DecodedAccessTokenProfilePassengerDispatch
): UserPassengerAspectDispatch => {
  return {
    uuid: tokenProfilePassengerDispatch.id,
    cargoCompanyUuid: tokenProfilePassengerDispatch.cargo_company_id,
  };
};

const createPassengerUserAspectDispatches = (
  tokenProfilePassengerDispatches: DecodedAccessTokenProfilePassengerDispatch[]
): UserPassengerAspectDispatch[] => {
  return tokenProfilePassengerDispatches.map(createPassengerUserAspectDispatch);
};

const createPassengerUserAspect = (
  tokenProfilePassenger: DecodedAccessTokenProfilePassenger | null
): UserPassengerAspect | null => {
  if (!tokenProfilePassenger) {
    return null;
  }

  const dispatches = createPassengerUserAspectDispatches(
    tokenProfilePassenger.dispatches
  );

  return {
    dispatches,
  };
};

const createRailyOfficerUserAspect = (
  tokenProfileRailyOfficer: DecodedAccessTokenProfileRailyOfficer | null
): UserRailyOfficerAspect | null => {
  if (!tokenProfileRailyOfficer) {
    return null;
  }

  return {};
};

const createTaxiOfficerUserAspect = (
  tokenProfileTaxiOfficer: DecodedAccessTokenProfileTaxiOfficer | null
): UserTaxiOfficerAspect | null => {
  if (!tokenProfileTaxiOfficer) {
    return null;
  }

  return {
    taxiCorporationUuid: tokenProfileTaxiOfficer.taxi_corporation_id,
  };
};

const createUserAspects = (
  accessTokenDecodedContentProfile: DecodedAccessTokenProfile
): UserAspects => {
  const adminUserAspect: UserAdminAspect | null = createAdminUserAspect(
    accessTokenDecodedContentProfile.admin
  );
  const cargoOfficerUserAspect: UserCargoOfficerAspect | null =
    createCargoOfficerUserAspect(
      accessTokenDecodedContentProfile.cargo_officer
    );

  const dealerUserAspect: UserDealerAspect | null = createDealerUserAspect(
    accessTokenDecodedContentProfile.dealer
  );

  const dispatcherUserAspect: UserDispatcherAspect | null =
    createDispatcherUserAspect(accessTokenDecodedContentProfile.dispatcher);

  const driverUserAspect: UserDriverAspect | null = createDriverUserAspect(
    accessTokenDecodedContentProfile.driver
  );

  const operatorUserAspect: UserOperatorAspect | null =
    createOperatorUserAspect(accessTokenDecodedContentProfile.operator);

  const passengerUserAspect: UserPassengerAspect | null =
    createPassengerUserAspect(accessTokenDecodedContentProfile.passenger);

  const railyOfficerUserAspect: UserRailyOfficerAspect | null =
    createRailyOfficerUserAspect(
      accessTokenDecodedContentProfile.raily_officer
    );

  const taxiOfficerUserAspect: UserTaxiOfficerAspect | null =
    createTaxiOfficerUserAspect(accessTokenDecodedContentProfile.taxi_officer);

  const userAspects: UserAspects = {
    admin: adminUserAspect,
    cargoOfficer: cargoOfficerUserAspect,
    dealer: dealerUserAspect,
    dispatcher: dispatcherUserAspect,
    driver: driverUserAspect,
    operator: operatorUserAspect,
    passenger: passengerUserAspect,
    railyOfficer: railyOfficerUserAspect,
    taxiOfficer: taxiOfficerUserAspect,
  };

  return userAspects;
};

const createUser = (
  accessTokenDecodedContentProfile: DecodedAccessTokenProfile
): User => {
  const roles = createUserRoles(accessTokenDecodedContentProfile);

  const aspects = createUserAspects(accessTokenDecodedContentProfile);

  return {
    uuid: accessTokenDecodedContentProfile.id,
    firstName: accessTokenDecodedContentProfile.first_name,
    lastName: accessTokenDecodedContentProfile.last_name,
    email: accessTokenDecodedContentProfile.email,
    username: accessTokenDecodedContentProfile.username,
    roles,
    aspects,
  };
};

const userFactory = {
  createUser,
};

export default userFactory;
