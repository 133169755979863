import { faAdd, faEdit, faList } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import userRoutesHelper from "../routes/user-routes.helper";
import UserBreadcrumbsDriverContractListingParams from "./types/user-breadcrumbs-driver-contract-listing-params";
import UserBreadcrumbsDriverContractAddParams from "./types/user-breadcrumbs-driver-contract-add-params";
import UserBreadcrumbsDispatcherEditParams from "./types/user-breadcrumbs-dispatcher-edit-params";
import UserBreadcrumbsDriverEditParams from "./types/user-breadcrumbs-driver-edit-params";
import UserBreadcrumbsPassengerEditParams from "./types/user-breadcrumbs-passenger-edit-params";
import UserBreadcrumbsDriverContractEditParams from "./types/user-breadcrumbs-driver-contract-edit.params";
import UserBreadcrumbsTaxiOfficerEditParams from "./types/user-breadcrumbs-taxi-officer-edit-params";

const getCargoOfficerAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faAdd,
      linkTo: userRoutesHelper.getCargoOfficerAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userCargoOfficerAdd,
    },
  ];
};

const getCargoOfficerListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getCargoOfficerListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userCargoOfficerListing,
    },
  ];
};

const getDealerAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faAdd,
      linkTo: userRoutesHelper.getDealerAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().userDealerAdd,
    },
  ];
};

const getDispatcherListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getDispatcherListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userDispatcherListing,
    },
  ];
};

const getDriverAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faAdd,
      linkTo: userRoutesHelper.getDriverAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().userDriverAdd,
    },
  ];
};

const getDriverEditBreadcrumbs = (
  params: UserBreadcrumbsDriverEditParams
): Breadcrumb[] => {
  return [
    ...getDriverListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: userRoutesHelper.getDriverEditRoute(params),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .userDriverEdit.replace("#{driverName}", params.driverName),
    },
  ];
};

const getDispatcherAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faAdd,
      linkTo: userRoutesHelper.getDispatcherAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userDispatcherAdd,
    },
  ];
};

const getDispatcherEditBreadcrumbText = (dispatcherName: string): string => {
  if (!dispatcherName) {
    return appTranslationsHelper.getBreadcrumbsTranslations()
      .userDispatcherEdit;
  }

  return appTranslationsHelper
    .getBreadcrumbsTranslations()
    .userDispatcherEditWithParams.replace("#{dispatcherName}", dispatcherName);
};

const getDispatcherEditBreadcrumbs = (
  params: UserBreadcrumbsDispatcherEditParams
): Breadcrumb[] => {
  return [
    {
      icon: faEdit,
      linkTo: userRoutesHelper.getDispatcherEditRoute({
        dispatcherUuid: params.dispatcherUuid,
      }),
      text: getDispatcherEditBreadcrumbText(params.dispatcherName),
    },
  ];
};

const getTaxiOfficerEditBreadcrumbs = (
  params: UserBreadcrumbsTaxiOfficerEditParams
): Breadcrumb[] => {
  return [
    {
      icon: faEdit,
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userTaxiOfficerEdit,
      linkTo: userRoutesHelper.getTaxiOfficerEditRoute({
        taxiOfficerUuid: params.taxiOfficerUuid,
      }),
    },
  ];
};

const getPassengerAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faAdd,
      linkTo: userRoutesHelper.getPassengerAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().userPassengerAdd,
    },
  ];
};

const getPassengerEditBreadcrumbs = (
  params: UserBreadcrumbsPassengerEditParams
): Breadcrumb[] => {
  return [
    ...getPassengerListingBreadcrumbs(),
    {
      icon: faEdit,
      linkTo: userRoutesHelper.getPassengerEditRoute({
        passengerUuid: params.passengerUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .userPassengerEdit.replace("#{passengerName}", params.passengerName),
    },
  ];
};

const getPassengerListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getPassengerListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userPassengerListing,
    },
  ];
};

const getTaxiOfficerListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getTaxiOfficerListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userTaxiOfficerListing,
    },
  ];
};
const getDealerListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getDealerListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userDealerListing,
    },
  ];
};

const getRailyOfficerAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getRailyOfficerAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userRailyOfficerAdd,
    },
  ];
};

const getRailyOfficerListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getRailyOfficerListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userRailyOfficerListing,
    },
  ];
};

const getTaxiOfficerAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faAdd,
      linkTo: userRoutesHelper.getTaxiOfficerAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userTaxiOfficerAdd,
    },
  ];
};

const getDriverListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getDriverListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userDriverListing,
    },
  ];
};

const getDriverContractAddBreadcrumbs = (
  params: UserBreadcrumbsDriverContractAddParams
): Breadcrumb[] => {
  return [
    ...getDriverContractListingBreadcrumbs({
      driverName: params.driverName,
      driverUuid: params.driverUuid,
    }),
    {
      icon: faList,
      linkTo: userRoutesHelper.getDriverContractAddRoute({
        driverUuid: params.driverUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .userDriverContractAdd.replace("#{driverName}", params.driverName),
    },
  ];
};

const getDriverContractListingBreadcrumbs = (
  params: UserBreadcrumbsDriverContractListingParams
): Breadcrumb[] => {
  return [
    ...getDriverListingBreadcrumbs(),
    {
      icon: faList,
      linkTo: userRoutesHelper.getDriverContractListingRoute({
        driverUuid: params.driverUuid,
      }),
      text:
        params.driverName &&
        appTranslationsHelper
          .getBreadcrumbsTranslations()
          .userDriverContractListing.replace(
            "#{driverName}",
            params.driverName
          ),
    },
  ];
};

const getDriverContractEditBreadcrumbs = (
  params: UserBreadcrumbsDriverContractEditParams
): Breadcrumb[] => {
  return [
    ...getDriverContractListingBreadcrumbs({
      driverName: params.driverName,
      driverUuid: params.driverUuid,
    }),
    {
      icon: faEdit,
      linkTo: userRoutesHelper.getDriverContractEditRoute(params),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userDriverContractEdit,
    },
  ];
};

const getOperatorAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faAdd,
      linkTo: userRoutesHelper.getOperatorAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().userOperatorAdd,
    },
  ];
};

const getOperatorListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: userRoutesHelper.getOperatorListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .userOperatorListing,
    },
  ];
};

const userBreadcrumbsHelper = {
  getCargoOfficerAddBreadcrumbs,
  getCargoOfficerListingBreadcrumbs,
  getDealerAddBreadcrumbs,
  getDealerListingBreadcrumbs,
  getDispatcherAddBreadcrumbs,
  getDispatcherEditBreadcrumbs,
  getDispatcherListingBreadcrumbs,
  getDriverAddBreadcrumbs,
  getDriverEditBreadcrumbs,
  getDriverContractAddBreadcrumbs,
  getDriverContractListingBreadcrumbs,
  getDriverContractEditBreadcrumbs,
  getDriverListingBreadcrumbs,
  getOperatorAddBreadcrumbs,
  getOperatorListingBreadcrumbs,
  getPassengerAddBreadcrumbs,
  getPassengerEditBreadcrumbs,
  getPassengerListingBreadcrumbs,
  getRailyOfficerAddBreadcrumbs,
  getRailyOfficerListingBreadcrumbs,
  getTaxiOfficerAddBreadcrumbs,
  getTaxiOfficerListingBreadcrumbs,
  getTaxiOfficerEditBreadcrumbs,
};

export default userBreadcrumbsHelper;
