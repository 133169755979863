import { faAdd } from "@fortawesome/free-solid-svg-icons";
import TableButtonComponent from "../../../../common/components/table/button/table-button.component";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageListingTableColumn from "../common/types/mileage-listing-table-column";

const getColumns = (
  isActionSelectAllButtonVisible: boolean,
  areAllRowsSelected: boolean,
  onActionSelectAllButtonClick: () => void
): MileageListingTableColumn[] => {
  const translations =
    mileageTranslationsHelper.getMileageListingTranslations().table.headers;

  const tableColumns: MileageListingTableColumn[] = [
    {
      header: "",
      title: "",
      accessor: "status",
      colSpan: 1,
    },
    {
      header: translations.mileageDateLabel,
      title: translations.mileageDateTitle,
      accessor: "mileageDate",
      colSpan: 3,
    },
    {
      header: translations.humanIdLabel,
      title: translations.humanIdTitle,
      accessor: "humanId",
      colSpan: 1,
    },
    {
      header: translations.cargoCompanyNameLabel,
      title: translations.cargoCompanyNameTitle,
      accessor: "cargoCompany",
      colSpan: 3,
    },
    {
      header: translations.vehicleTypeLabel,
      title: translations.vehicleTypeTitle,
      accessor: "vehicleType",
      colSpan: 3,
    },
    {
      header: translations.mileageNumberLabel,
      title: translations.mileageNumberTitle,
      accessor: "mileageNumber",
      colSpan: 3,
    },
    {
      header: translations.workerLabel,
      title: translations.workerTitle,
      accessor: "workerName",
      colSpan: 3,
    },
    {
      header: translations.cardNumberLabel,
      title: translations.cardNumberTitle,
      accessor: "cardNumber",
      colSpan: 3,
    },
    {
      header: translations.routeLabel,
      title: translations.routeTitle,
      accessor: "route",
      colSpan: 8,
    },
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 4,
    },
    {
      header: isActionSelectAllButtonVisible && (
        <TableButtonComponent
          icon={faAdd}
          onClick={onActionSelectAllButtonClick}
          idForTesting="mileage-listing-table-column-action-button"
          type={areAllRowsSelected ? "success" : "brand"}
        />
      ),
      title: "",
      accessor: "action",
      colSpan: 2,
    },
  ];

  return tableColumns;
};

const mileageListingTableHelper = {
  getColumns,
};

export default mileageListingTableHelper;
