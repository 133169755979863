import HttpResponse from "../../../../../common/utils/http/http.response";

export type OrderRouteEditResponseSuccessResponse = HttpResponse<string>;

export enum OrderRouteEditErrorType {
  PASSENGERS_DO_OVERLAP = "PASSENGERS_DO_OVERLAP",
  NODE_ALREADY_CHECKED_OUT = "NODE_ALREADY_CHECKED_OUT",
  RESULT_TIMING_NOT_POSSIBLE = "RESULT_TIMING_NOT_POSSIBLE",
}

export enum OrderRouteEditUnprocessableEntityErrorType {
  TOO_LONG_RIDE = "TOO_LONG_RIDE",
}

export type OrderRouteEditUnprocessableEntityTimeError = {
  detail: {
    error_code: OrderRouteEditUnprocessableEntityErrorType.TOO_LONG_RIDE;
  }[];
};

export type OrderRouteEditNotAcceptableResponsePassengerDoOverlapError = {
  error_type: OrderRouteEditErrorType.PASSENGERS_DO_OVERLAP;
  subject_order_id: string;
};

export type OrderRouteEditNotAcceptableResponseResultTimingNotPossible = {
  error_type: OrderRouteEditErrorType.RESULT_TIMING_NOT_POSSIBLE;
};

export type OrderRouteEditNotAcceptableResponseNodeAleardyCheckedOutError = {
  error_type: OrderRouteEditErrorType.NODE_ALREADY_CHECKED_OUT;
};

export type OrderRouteEditNotAcceptableResponseError =
  | OrderRouteEditNotAcceptableResponsePassengerDoOverlapError
  | OrderRouteEditNotAcceptableResponseNodeAleardyCheckedOutError
  | OrderRouteEditNotAcceptableResponseResultTimingNotPossible;

export type OrderRouteEditNotAcceptableResponse =
  HttpResponse<OrderRouteEditNotAcceptableResponseError>;

export type OrderRouteEditUnprocessableEntityResponse =
  HttpResponse<OrderRouteEditUnprocessableEntityTimeError>;

type OrderRouteEditResponse =
  | OrderRouteEditResponseSuccessResponse
  | OrderRouteEditNotAcceptableResponse
  | OrderRouteEditUnprocessableEntityResponse;

export default OrderRouteEditResponse;
