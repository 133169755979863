import planningTranslationsHelper from "../../../../languages/planning-translations.helper";
import {
  DriverPlanCandidaturePreviewErrorType,
  DriverPlanEntryCandidateCollisionError,
  DriverPlanOrderInProgressError,
  DriverPlanOrderNotExistsError,
  DriverPlanTaxiDriverAssociationNotExistsError,
  DriverPlanTaxiDriverContractNotValidError,
  DriverPlanTaxiRailyContractNotExistsError,
} from "./driver-plan-candidature-preview-error";

const createPlanEntryCandidateCollisionError =
  (): DriverPlanEntryCandidateCollisionError => {
    const translations =
      planningTranslationsHelper.getOrderTranslations().driverPlanListing
        .staging;

    return {
      message: translations.planEntryCandidateCollisionErrorLabel,
      params: {},
      type: DriverPlanCandidaturePreviewErrorType.PLAN_ENTRY_CANDIDATE_COLLISION,
    };
  };

const createTaxiDriverAssocationNotExistsError =
  (): DriverPlanTaxiDriverAssociationNotExistsError => {
    const translations =
      planningTranslationsHelper.getOrderTranslations().driverPlanListing
        .staging;

    return {
      message: translations.taxiDriverAssocationNotExistsErrorLabel,
      params: {},
      type: DriverPlanCandidaturePreviewErrorType.TAXI_DRIVER_ASSOCIATION_NOT_EXISTS,
    };
  };

const createOrderNotExistsError = (): DriverPlanOrderNotExistsError => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing.staging;

  return {
    message: translations.orderNotExistsErrorLabel,
    params: {},
    type: DriverPlanCandidaturePreviewErrorType.ORDER_NOT_EXISTS,
  };
};

const createOrderInProgressError = (): DriverPlanOrderInProgressError => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing.staging;

  return {
    message: translations.orderInProgressErrorLabel,
    params: {},
    type: DriverPlanCandidaturePreviewErrorType.ORDER_IN_PROGRESS,
  };
};

const createTaxiDriverContractNotValidError =
  (): DriverPlanTaxiDriverContractNotValidError => {
    const translations =
      planningTranslationsHelper.getOrderTranslations().driverPlanListing
        .staging;

    return {
      message: translations.taxiDriverContractNotValidErrorLabel,
      params: {},
      type: DriverPlanCandidaturePreviewErrorType.TAXI_DRIVER_CONTRACT_NOT_VALID,
    };
  };

const createTaxiRailyContractNotValidError =
  (): DriverPlanTaxiRailyContractNotExistsError => {
    const translations =
      planningTranslationsHelper.getOrderTranslations().driverPlanListing
        .staging;

    return {
      message: translations.taxiRailyContractNotValidErrorLabel,
      params: {},
      type: DriverPlanCandidaturePreviewErrorType.TAXI_RAILY_CONTRACT_NOT_VALID,
    };
  };

const driverPlanCandidaturePreviewErrorFactory = {
  createPlanEntryCandidateCollisionError,
  createTaxiDriverAssocationNotExistsError,
  createOrderNotExistsError,
  createOrderInProgressError,
  createTaxiDriverContractNotValidError,
  createTaxiRailyContractNotValidError,
};

export default driverPlanCandidaturePreviewErrorFactory;
