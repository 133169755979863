import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryDeleteNodeChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";

type OrderDetailsHistoryEntryChangeRideContentDeleteNodeProps = {
  event: OrderDetailsHistoryEntryDeleteNodeChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentDeleteNodeComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentDeleteNodeProps
> = () => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .deleteNode;

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentDeleteNodeComponent;
