import {
  RouteDetailsResponseData,
  RouteDetailsResponseDataCheckoutEvent,
  RouteDetailsResponseDataDriver,
  RouteDetailsResponseDataNode,
  RouteDetailsResponseDataNodeExclusion,
  RouteDetailsResponseDataNodeExclusions,
  RouteDetailsResponseDataPassenger,
  RouteDetailsResponseDataSolvedOrder,
  RouteDetailsResponseDataSolvedOrderCargoCompany,
  RouteDetailsResponseDataSolvedOrderCargoCompanyContractMaintenancePolicy,
  RouteDetailsResponseDataSolvedOrderRide,
  RouteDetailsResponseDataSolvedOrderRideSequenceOrder,
  RouteDetailsResponseDataSolvedOrderTransportingOrder,
  RouteDetailsResponseDataTaxi,
} from "../../../api/raily/route/details/route-details.response";
import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import uuidService from "../../../utils/uuid/uuid.service";
import RouteDetails, {
  RouteDetailsAddressNode,
  RouteDetailsCheckoutEvent,
  RouteDetailsDriver,
  RouteDetailsNodeExclusion,
  RouteDetailsPassenger,
  RouteDetailsSolvedOrder,
  RouteDetailsSolvedOrderCargoCompany,
  RouteDetailsSolvedOrderRide,
  RouteDetailsSolvedOrderRideSequenceOrder,
  RouteDetailsSolvedOrderTransportingOrder,
  RouteDetailsTaxiCorporation,
} from "./route-details";

const createSolvedOrderRideSequenceOrder = (
  responseSolvedOrderRideSequenceOrder: RouteDetailsResponseDataSolvedOrderRideSequenceOrder
): RouteDetailsSolvedOrderRideSequenceOrder => {
  switch (responseSolvedOrderRideSequenceOrder) {
    case RouteDetailsResponseDataSolvedOrderRideSequenceOrder.STRICT:
      return RouteDetailsSolvedOrderRideSequenceOrder.STRICT;
  }
};

const createSolvedOrderRide = (
  responseSolvedOrderRide: RouteDetailsResponseDataSolvedOrderRide
): RouteDetailsSolvedOrderRide => {
  return {
    seq: createAddressNodes(responseSolvedOrderRide.seq),
    sequenceOrder: createSolvedOrderRideSequenceOrder(
      responseSolvedOrderRide.sequenceOrder
    ),
  };
};

const createSolvedOrderTransportingOrder = (
  responseSolvedOrderTransportingOrder: RouteDetailsResponseDataSolvedOrderTransportingOrder
): RouteDetailsSolvedOrderTransportingOrder => {
  return {
    uuid: responseSolvedOrderTransportingOrder.id,
  };
};

const createSolvedOrderCargoCompanyContractMaintenancePolicy = (
  responseSolvedOrderCargoCompanyContractMaintenancePolicy: RouteDetailsResponseDataSolvedOrderCargoCompanyContractMaintenancePolicy
): ContractMaintenancePolicy => {
  switch (responseSolvedOrderCargoCompanyContractMaintenancePolicy) {
    case RouteDetailsResponseDataSolvedOrderCargoCompanyContractMaintenancePolicy.AUCTION:
      return ContractMaintenancePolicy.AUCTION;
    case RouteDetailsResponseDataSolvedOrderCargoCompanyContractMaintenancePolicy.BASIC:
      return ContractMaintenancePolicy.BASIC;
  }
};

const createSolvedOrderCargoCompany = (
  responseSolvedOrderCargoCompany: RouteDetailsResponseDataSolvedOrderCargoCompany
): RouteDetailsSolvedOrderCargoCompany => {
  return {
    taxNumber: responseSolvedOrderCargoCompany.taxId,
    companyId: responseSolvedOrderCargoCompany.regon,
    nationalCourtRegister: responseSolvedOrderCargoCompany.krsNo,
    companyName: responseSolvedOrderCargoCompany.companyName,
    displayName: responseSolvedOrderCargoCompany.displayName,
    address: responseSolvedOrderCargoCompany.headquartersAddressString,
    contractMaintenancePolicy:
      createSolvedOrderCargoCompanyContractMaintenancePolicy(
        responseSolvedOrderCargoCompany.contractMaintenancePolicy
      ),
    notes: responseSolvedOrderCargoCompany.notes,
    uuid: responseSolvedOrderCargoCompany.id,
  };
};

const createSolvedOrder = (
  responseSolvedOrder: RouteDetailsResponseDataSolvedOrder
): RouteDetailsSolvedOrder => {
  return {
    involvedPassengerUuids: responseSolvedOrder.involvedPassengerIds,
    ride: createSolvedOrderRide(responseSolvedOrder.ride),
    performanceRegion: responseSolvedOrder.performanceRegion,
    uuid: responseSolvedOrder.id,
    transportingOrder: createSolvedOrderTransportingOrder(
      responseSolvedOrder.transportingOrder
    ),
    cargoCompany: createSolvedOrderCargoCompany(
      responseSolvedOrder.cargoCompany
    ),
    excludeHighway: !responseSolvedOrder.forceAllowToll,
  };
};

const createSolvedOrders = (
  responseSolvedOrders: RouteDetailsResponseDataSolvedOrder[]
): RouteDetailsSolvedOrder[] => {
  return responseSolvedOrders.map(createSolvedOrder);
};

const createNodeExclusion = (
  responseNodeExclusion: RouteDetailsResponseDataNodeExclusion
): RouteDetailsNodeExclusion => {
  return {
    nodeUuids: responseNodeExclusion.nodeIds,
    excludeApproaching: responseNodeExclusion.excludeApproaching,
    excludeReturning: responseNodeExclusion.excludeReturning,
    excludeInside: responseNodeExclusion.excludeInside,
  };
};

const createNodeExclusions = (
  responseNodeExclusions: RouteDetailsResponseDataNodeExclusions
): RouteDetailsNodeExclusion[] => {
  return responseNodeExclusions.exclusions.map((nodeExclusion) =>
    createNodeExclusion(nodeExclusion)
  );
};

const createAddressNode = (
  responseAddressNode: RouteDetailsResponseDataNode
): RouteDetailsAddressNode => {
  return {
    uuid: responseAddressNode.meta.id,
    latitude: responseAddressNode.lat,
    longitude: responseAddressNode.lon,
    plannedDate: responseAddressNode.time,
    haltingTime: responseAddressNode.haltingTime,
    displayName: responseAddressNode.displayName,
    onboardingPassengersUuids: responseAddressNode.meta.cargoEnter.subjects.map(
      (subject) => subject.id
    ),
    outboardingPassengersUuids: responseAddressNode.meta.cargoExit.subjects.map(
      (subject) => subject.id
    ),
    estimatedTime: responseAddressNode.meta.estimatedTime,
  };
};

const createAddressNodes = (
  responseAddressNodes: RouteDetailsResponseDataNode[]
) => {
  return responseAddressNodes.map(createAddressNode);
};

const createCheckoutEvent = (
  responseCheckoutEvent: RouteDetailsResponseDataCheckoutEvent
): RouteDetailsCheckoutEvent => {
  return {
    uuid: responseCheckoutEvent.id,
    nodeUuid: responseCheckoutEvent.nodeId,
    arrivalDate: responseCheckoutEvent.achievedAt,
    stoppingRate: responseCheckoutEvent.haltingTime,
    highwayCharge: responseCheckoutEvent.highwayCharge,
  };
};

const createCheckoutEvents = (
  responseCheckoutEvents: RouteDetailsResponseDataCheckoutEvent[]
): RouteDetailsCheckoutEvent[] => {
  return responseCheckoutEvents.map(createCheckoutEvent);
};

const createTaxiCorporation = (
  responseTaxi: RouteDetailsResponseDataTaxi
): RouteDetailsTaxiCorporation => {
  return {
    uuid: responseTaxi.id,
    displayName: responseTaxi.displayName,
  };
};

const createPassenger = (
  responsePassenger: RouteDetailsResponseDataPassenger
): RouteDetailsPassenger => {
  return {
    uuid: responsePassenger.passengerId ?? uuidService.generate(),
    firstName: responsePassenger.firstName,
    lastName: responsePassenger.lastName,
    phoneNumber: {
      dialingCode: responsePassenger.phonePrefix,
      number: responsePassenger.phoneNo,
    },
  };
};

const createPassengers = (
  responsePassengers: RouteDetailsResponseDataPassenger[]
): RouteDetailsPassenger[] => {
  return responsePassengers.map(createPassenger);
};

const createDriver = (
  responseDriver: RouteDetailsResponseDataDriver
): RouteDetailsDriver => {
  return {
    uuid: responseDriver.id,
    firstName: responseDriver.firstName,
    lastName: responseDriver.lastName,
  };
};

const create = (responseItem: RouteDetailsResponseData): RouteDetails => {
  return {
    modificationDate: responseItem.modifiedAt,
    modifiedBy: responseItem.modifiedBy,
    creationDate: responseItem.createdAt,
    createdBy: responseItem.createdBy,
    uuid: responseItem.id,
    solvedOrders: createSolvedOrders(responseItem.solvedOrders),
    nodeExclusions: createNodeExclusions(responseItem.nodeExclusions),
    startNode: createAddressNode(responseItem.startNode),
    endNode: createAddressNode(responseItem.endNode),
    checkoutsCount: responseItem.checkoutsCount,
    checkoutEvents: createCheckoutEvents(responseItem.checkoutEvents),
    taxiCorporation: createTaxiCorporation(responseItem.taxi),
    humanId: responseItem.humanId,
    passengers: createPassengers(responseItem.passengers),
    driver: createDriver(responseItem.driver),
  };
};

const routeDetailsFactory = { create };

export default routeDetailsFactory;
