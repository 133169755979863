import { faAdd } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import settlementBreadcrumbsHelper from "../../../settlement/common/breadcrumbs/settlement-breadcrumbs.helper";
import billingRoutesHelper from "../routes/billing-routes.helper";
import BillingCargoRailyAddBreadcrumbsParams from "./types/billings-cargo-raily-add-breadcrumbs-params";
import BillingCargoTaxiAddBreadcrumbsParams from "./types/billings-cargo-taxi-add-breadcrumbs-params";
import BillingCargoTaxiDetailsBreadcrumbsParams from "./types/billings-cargo-taxi-details-breadcrumbs-params";
import BillingCargoTaxiEditBreadcrumbsParams from "./types/billings-cargo-taxi-edit-breadcrumbs-params";
import BillingTaxiDriverAddBreadcrumbsParams from "./types/billings-taxi-driver-add-breadcrumbs-params";
import BillingTaxiDriverEditBreadcrumbsParams from "./types/billings-taxi-driver-edit-breadcrumbs-params";
import BillingTaxiRailyAddBreadcrumbsParams from "./types/billings-taxi-raily-add-breadcrumbs-params";
import BillingRailyTaxiAddBreadcrumbsParams from "./types/billings-raily-taxi-add-breadcrumbs-params";
import BillingTaxiTaxiAddBreadcrumbsParams from "./types/billings-taxi-taxi-add-breadcrumbs-params";
import BillingTaxiTaxiEditBreadcrumbsParams from "./types/billings-taxi-taxi-edit-breadcrumbs-params";
import BillingTaxiTaxiViewBreadcrumbsParams from "./types/billings-taxi-taxi-view-breadcrumbs-params";
import BillingTaxiDriverViewBreadcrumbsParams from "./types/billings-taxi-driver-view-breadcrumbs-params";

const getCargoWithRailyAddBreadcrumbs = (
  params: BillingCargoRailyAddBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiUnsettledOrderListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getCargoWithRailyAddRoute({
        orderUuid: params.orderUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsCargoWithRailyAdd.replace(
          "#{orderInternalId}",
          params.orderInternalId
        ),
    },
  ];
};

const getCargoWithTaxiAddBreadcrumbs = (
  params: BillingCargoTaxiAddBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiUnsettledPrivateOrderListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getCargoWithTaxiAddRoute({
        orderUuid: params.orderUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsCargoWithTaxiAdd.replace(
          "#{orderInternalId}",
          params.orderInternalId
        ),
    },
  ];
};

const getCargoWithTaxiDetailsBreadcrumbs = (
  params: BillingCargoTaxiDetailsBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiOrderSettlementListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getCargoWithTaxiDetailsRoute({
        billingUuid: params.billingUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsCargoWithTaxiDetails.replace(
          "#{orderInternalId}",
          params.orderInternalId
        ),
    },
  ];
};

const getCargoWithTaxiEditBreadcrumbs = (
  params: BillingCargoTaxiEditBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiOrderSettlementListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getCargoWithTaxiEditRoute({
        billingUuid: params.billingUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsCargoWithTaxiEdit.replace(
          "#{orderInternalId}",
          params.orderInternalId
        ),
    },
  ];
};

const getTaxiWithDriverAddBreadcrumbs = (
  params: BillingTaxiDriverAddBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiUnsettledRouteListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getTaxiWithDriverAddRoute({
        planEntryUuid: params.planEntryUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsTaxiWithDriverAdd.replace("#{routeId}", params.routeId),
    },
  ];
};

const getTaxiWithDriverEditBreadcrumbs = (
  params: BillingTaxiDriverEditBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiSettledRouteListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getTaxiWithDriverEditRoute({
        billingUuid: params.billingUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsTaxiWithDriverEdit.replace("#{routeId}", params.routeId),
    },
  ];
};

const getTaxiWithDriverViewBreadcrumbs = (
  params: BillingTaxiDriverViewBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiSettledRouteListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getTaxiWithDriverDetailsRoute({
        billingUuid: params.billingUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsTaxiWithDriverView.replace("#{routeId}", params.routeId),
    },
  ];
};

const getTaxiWithTaxiAddBreadcrumbs = (
  params: BillingTaxiTaxiAddBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiUnsettledTransferedOrderListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getTaxiWithTaxiAddRoute({
        forwardingUuid: params.forwardingUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsTaxiWithTaxiAdd.replace("#{routeId}", params.routeId),
    },
  ];
};

const getTaxiWithTaxiEditBreadcrumbs = (
  params: BillingTaxiTaxiEditBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiSettledTransferedOrderListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getTaxiWithTaxiEditRoute({
        billingUuid: params.billingUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsTaxiWithTaxiEdit.replace("#{routeId}", params.routeId),
    },
  ];
};

const getTaxiWithTaxiViewBreadcrumbs = (
  params: BillingTaxiTaxiViewBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiSettledTransferedOrderListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getTaxiWithTaxiDetailsRoute({
        billingUuid: params.billingUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsTaxiWithTaxiView.replace("#{routeId}", params.routeId),
    },
  ];
};

const getTaxiWithRailyAddBreadcrumbs = (
  params: BillingTaxiRailyAddBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiUnsettledOrderListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getTaxiWithRailyAddRoute({
        orderUuid: params.orderUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsTaxiWithRailyAdd.replace(
          "#{orderInternalId}",
          params.orderInternalId
        ),
    },
  ];
};

const getRailyWithTaxiAddBreadcrumbs = (
  params: BillingRailyTaxiAddBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...settlementBreadcrumbsHelper.getTaxiUnsettledOrderListingBreadcrumbs(),
    {
      icon: faAdd,
      linkTo: billingRoutesHelper.getRailyWithTaxiAddRoute({
        orderUuid: params.orderUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .billingsRailyWithTaxiAdd.replace(
          "#{orderInternalId}",
          params.orderInternalId
        ),
    },
  ];
};

const billingBreadcrumbsHelper = {
  getCargoWithRailyAddBreadcrumbs,
  getCargoWithTaxiAddBreadcrumbs,
  getCargoWithTaxiDetailsBreadcrumbs,
  getCargoWithTaxiEditBreadcrumbs,
  getTaxiWithDriverAddBreadcrumbs,
  getTaxiWithDriverEditBreadcrumbs,
  getTaxiWithRailyAddBreadcrumbs,
  getRailyWithTaxiAddBreadcrumbs,
  getTaxiWithTaxiAddBreadcrumbs,
  getTaxiWithTaxiEditBreadcrumbs,
  getTaxiWithTaxiViewBreadcrumbs,
  getTaxiWithDriverViewBreadcrumbs,
};

export default billingBreadcrumbsHelper;
