import useAsyncData from "../../../../hooks/use-async-data";
import cargoCompanyPassengerService from "../cargo-company-passenger.service";
import CargoCompanyPassengerListLoadParams from "./cargo-company-passenger-list-load-params";
import CargoCompanyPassengerList from "./cargo-company-passenger-list";

const useCargoCompanyPassengerList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<CargoCompanyPassengerList>([]);

  const load = async (
    params: CargoCompanyPassengerListLoadParams,
    abortSignal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await cargoCompanyPassengerService.getPassengerList(
        params,
        abortSignal
      );

      setData(data);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    isError,
    load,
  };
};

export default useCargoCompanyPassengerList;
