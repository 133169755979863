import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import PassengerEditInitDataResponse from "./types/passenger-edit-init-data.response";
import PassengerEditUpdateRequest from "./types/passenger-edit-update.request";
import PassengerEditUpdateResponse from "./types/passenger-edit-update.response";

const update = async (
  passengerUuid: string,
  request: PassengerEditUpdateRequest
): Promise<PassengerEditUpdateResponse> => {
  const path = `/passengers/${passengerUuid}/edit-form`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchInitData = async (
  passengerUuid: string
): Promise<PassengerEditInitDataResponse> => {
  const path = `/passengers/${passengerUuid}/edit-form`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const passengerEditApiService = {
  update,
  fetchInitData,
};

export default passengerEditApiService;
