import CargoCompanyListRequest, {
  CargoCompanyListRequestQueryParams,
} from "./cargo-company-list.request";

const createQueryParams = (
  data: CargoCompanyListRequest
): CargoCompanyListRequestQueryParams => {
  return {
    companyName: data.companyName,
    companyNameCaseSensitive: data.companyNameCaseSensitive,
    contractMaintenancePolicy: data.contractMaintenancePolicy,
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
    displayName: data.displayName,
    displayNameCaseSensitive: data.displayNameCaseSensitive,
    headquartersAddressString: data.headquartersAddressString,
    headquartersAddressStringCaseSensitive:
      data.headquartersAddressStringCaseSensitive,
    krsNo: data.krsNo,
    notes: data.notes,
    notesCaseSensitive: data.notesCaseSensitive,
    order: data.order,
    regon: data.regon,
    taxId: data.taxId,
  };
};

const cargoCompanyListRequestFactory = {
  createQueryParams,
};

export default cargoCompanyListRequestFactory;
