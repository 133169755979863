import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { AppAsideNavigationDefinitionGroup } from "../common/types/app-aside-navigation-definition";
import AppAsideExpandedNavigationListItemToggleButtonComponent from "./app-aside-expanded-navigation-list-item-toggle-button.component";
import classNames from "classnames";
import AppAsideExpandedNavigationLinkListItemComponent from "./app-aside-expanded-navigation-link-list-item.component";
import AppAsideExpandedNavigationGroup from "./common/types/app-aside-expanded-navigation-group";
import appAsideExpandedNavigationHelper from "./common/app-aside-expanded-navigation.helper";

type AppAsideExpandedNavigationGroupListItemProps = {
  group: AppAsideExpandedNavigationGroup;
  onToggleClick: (
    groupKey: AppAsideNavigationDefinitionGroup["key"],
    isExpanded: boolean
  ) => void;
  depth?: number;
  idForTesting?: string;
};

const AppAsideExpandedNavigationGroupListItemComponent: FC<
  AppAsideExpandedNavigationGroupListItemProps
> = (props) => {
  const depth = props.depth ?? 0;

  return (
    <li className="app_aside_navigation_list_item">
      <div
        className={classNames(
          "app_aside_navigation_list_item__content",
          props.group.isHighlighted && "highlighted"
        )}
        data-test-id={props.idForTesting}
        onClick={() =>
          props.onToggleClick(props.group.key, props.group.isExpanded)
        }
      >
        <div
          className="app_aside_navigation_list_item__content_data"
          style={{ paddingLeft: depth * 10 }}
        >
          <div className="app_aside_navigation_list_item__icon_wrapper">
            <FontAwesomeIcon
              icon={props.group.icon}
              className="app_aside_navigation_list_item__icon"
            />
          </div>
          <div className="app_aside_navigation_list_item__text">
            {props.group.text}
          </div>
          {!!props.group.attentionCounter && (
            <div className="app_aside_navigation_list_item__attention_counter">
              {appAsideExpandedNavigationHelper.getCounterValueLabel(
                props.group.attentionCounter
              )}
            </div>
          )}
        </div>
        <div className="app_aside_navigation_list_item__toggle">
          <AppAsideExpandedNavigationListItemToggleButtonComponent
            isExpanded={props.group.isExpanded}
            onClick={() =>
              props.onToggleClick(props.group.key, props.group.isExpanded)
            }
          />
        </div>
      </div>
      <ul>
        {props.group.items.map((item) => {
          const isGroup = appAsideExpandedNavigationHelper.checkIsGroup(item);

          if (isGroup) {
            return (
              <AppAsideExpandedNavigationGroupListItemComponent
                key={`navigation-list-item-key-${item.key}`}
                group={item}
                onToggleClick={() =>
                  props.onToggleClick(item.key, item.isExpanded)
                }
                depth={depth + 1}
                idForTesting={`app-aside-navigation-list-item-${item.key}-group`}
              />
            );
          }

          return (
            <AppAsideExpandedNavigationLinkListItemComponent
              link={item}
              key={`navigation-list-item-key-${item.key}`}
              depth={depth + 1}
              idForTesting={`app-aside-navigation-list-item-${item.key}-link`}
            />
          );
        })}
      </ul>
    </li>
  );
};

export default AppAsideExpandedNavigationGroupListItemComponent;
