import isEqual from "lodash/isEqual";
import DriverContractListingByTaxiFilterSelectOption, {
  DriverContractListingByTaxiBillingModelFilterSelectOption,
} from "../types/driver-contract-listing-by-taxi-filter-select-option";
import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import DriverContractListingByTaxiFilter from "../types/driver-contract-listing-by-taxi-filter";
import DriverContractListingByTaxiFilterType from "../types/driver-contract-listing-by-taxi-filter-type";
import DriverContractListingBillingModel from "../../../common/types/driver-contract-listing-billing-model";

const getSearchBillingModelOptions = (
  query: string
): DriverContractListingByTaxiFilterSelectOption[] => {
  const searchFilterTranslations =
    userTranslationsHelper.getDriverContractListingTranslations().filters
      .search;

  const allBillingModelSelectOptions: DriverContractListingByTaxiBillingModelFilterSelectOption[] =
    Object.values(DriverContractListingBillingModel).map((billingModel) => {
      const billingModelLabel =
        searchFilterTranslations.searchByBillingModelTemplateText.replace(
          "#{option}",
          billingModel
        );

      return {
        label: billingModelLabel,
        value: {
          type: DriverContractListingByTaxiFilterType.BILLING_MODEL,
          value: billingModel,
        },
      };
    });

  if (query) {
    return allBillingModelSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allBillingModelSelectOptions;
};

const getFilterOptions = (
  query: string
): DriverContractListingByTaxiFilterSelectOption[] => {
  const billingModelOptions = getSearchBillingModelOptions(query);

  return [...billingModelOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: DriverContractListingByTaxiFilter[]
): DriverContractListingByTaxiFilterSelectOption[] => {
  const allFilterOptions = getFilterOptions(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const driverContractListingByTaxiFilterHelper = {
  getSelectOptions,
};

export default driverContractListingByTaxiFilterHelper;
