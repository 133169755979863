import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import BillingsTaxiDriverContributionsPenaltyTableColumn from "../types/billings-taxi-driver-contributions-penalty-table-column";

const getRequiredColumnHeading = (heading: string) => (
  <>
    {heading} <span className="header_asterisk">*</span>
  </>
);

const getColumns = (): BillingsTaxiDriverContributionsPenaltyTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations().penalty
      .table.headers;

  const tableColumns: BillingsTaxiDriverContributionsPenaltyTableColumn[] = [
    {
      header: translations.typeLabel,
      title: translations.typeTitle,
      accessor: "type",
      colSpan: 10,
    },
    {
      header: getRequiredColumnHeading(translations.amountLabel),
      title: translations.amountTitle,
      accessor: "amount",
      colSpan: 10,
    },
    {
      header: getRequiredColumnHeading(translations.commentLabel),
      title: translations.commentTitle,
      accessor: "comment",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];

  return tableColumns;
};

const BillingsTaxiDriverContributionsPenaltyTableHelper = { getColumns };

export default BillingsTaxiDriverContributionsPenaltyTableHelper;
