import { FC, useMemo } from "react";
import SingleSelectComponent from "../single-select/single-select.component";
import BillingModel from "../../../../types/billing-model";
import BillingModelSelectOption from "../../../../types/billing-model-select-option";
import billingModelSelectHelper from "./billing-model-select.helper";

type BillingModelSingleSelectProps = {
  value: BillingModel | null;
  onChange: (value: BillingModel | null) => void;
  placeholder: string;
  onBlur: () => void;
  isError?: boolean;
  isDisabled?: boolean;
  idForTesting?: string;
};

const BillingModelSingleSelectComponent: FC<BillingModelSingleSelectProps> = (
  props
) => {
  const options: BillingModelSelectOption[] = useMemo(() => {
    return billingModelSelectHelper.getSelectOptions();
  }, []);

  const selectedOption: BillingModelSelectOption | null = useMemo(() => {
    return options.find((option) => option.value === props.value) ?? null;
  }, [options, props.value]);

  return (
    <SingleSelectComponent
      value={selectedOption}
      onChange={(option) => props.onChange(option?.value ?? null)}
      options={options}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      hasError={props.isError}
      isDisabled={props.isDisabled}
      idForTesting={props.idForTesting}
      isSearchable
    />
  );
};

export default BillingModelSingleSelectComponent;
