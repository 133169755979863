import MileageDictionaryDetailsRequest from "../../../api/raily/mileage-dictionary/details/mileage-dictionary-details.request";
import MileageDictionaryDetailsLoadParams from "./mileage-dictionary-details-load-params";

const create = (
  params: MileageDictionaryDetailsLoadParams
): MileageDictionaryDetailsRequest => {
  return {
    cargoCompanyUuid: params.cargoCompanyUuid,
    recordUuid: params.recordUuid,
  };
};

const mileageDictionaryDetailsRequestFactory = {
  create,
};

export default mileageDictionaryDetailsRequestFactory;
