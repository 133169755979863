import { FC } from "react";
import TaxiOfficerListingFilter from "../common/types/taxi-officer-listing-filter";
import TaxiOfficerListingSortKey from "../common/types/taxi-officer-listing-sort-key";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import taxiOfficerListingFilterHelper from "./taxi-officer-listing-filter.helper";

type TaxiOfficerListingFilterProps = {
  filters: TaxiOfficerListingFilter[];
  onFiltersChange: (filters: TaxiOfficerListingFilter[]) => void;
  sortKey: TaxiOfficerListingSortKey;
  onSortKeyChange: (sortKey: TaxiOfficerListingSortKey) => void;
};

const TaxiOfficerListingFilterComponent: FC<TaxiOfficerListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<TaxiOfficerListingFilter> =
    taxiOfficerListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    taxiOfficerListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as TaxiOfficerListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as TaxiOfficerListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="taxi-officer-listing-filter"
      idForTestingSort="taxi-officer-listing-sort"
    />
  );
};

export default TaxiOfficerListingFilterComponent;
