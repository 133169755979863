import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { FC, useRef, useState } from "react";
import ComponentClassnames from "../../../../types/component-classnames";
import phoneNumberInputHelper from "./phone-number-input.helper";
import PhoneNumberInputDialingCodeOption from "./phone-number-input-dialing-code-option";
import OutsideClickHandler from "react-outside-click-handler";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import PhoneNumber from "../../../../types/phone-number";
import useKeyboardPress from "../../../../hooks/use-keyboard-press";

type PhoneNumberInputProps = {
  classNames?: ComponentClassnames & {
    input?: string;
  };
  phoneNumber: PhoneNumber;
  onChange?: (phoneNumber: PhoneNumber) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder: string;
  type?: "email" | "password" | "number" | "text";
  isDisabled?: boolean;
  isReadOnly?: boolean;
  hasError?: boolean;
  idForTesting?: string;
};

const PhoneNumberInputComponent: FC<PhoneNumberInputProps> = (props) => {
  const translations =
    appTranslationsHelper.getComponentTranslations().phoneInput;

  const inputWrapperClassnames = classNames(
    "phone_number_input",
    props.classNames?.root,
    props.hasError && "error",
    props.isDisabled && "disabled"
  );
  const inputClassnames = classNames(
    "phone_number_input__input",
    props.classNames?.input
  );

  const [isDialingCodeListOpen, setIsDialingCodeListOpen] = useState(false);
  const [dialingCodeFocusedIndex, setDialingCodeFocusedIndex] = useState(0);

  const inputWrapperRef = useRef<HTMLLabelElement>(null);
  const numberInputRef = useRef<HTMLInputElement>(null);
  const dialingCodeInputRef = useRef<HTMLInputElement>(null);
  const dialingCodeSelectRef = useRef<HTMLUListElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  useKeyboardPress({
    ArrowDown: (e) => {
      e.preventDefault();

      if (dialingCodeFocusedIndex < selectCountryOptions.length - 1) {
        setDialingCodeFocusedIndex((prevIndex) => prevIndex + 1);

        const focusedItem = dialingCodeSelectRef.current!.children[
          dialingCodeFocusedIndex + 1
        ] as HTMLElement;
        const scrollOffset =
          focusedItem.offsetTop - dialingCodeSelectRef.current!.scrollTop;

        dialingCodeSelectRef.current!.scrollTop += scrollOffset;
      }
    },
    ArrowUp: (e) => {
      e.preventDefault();

      if (dialingCodeFocusedIndex > 0) {
        if (dialingCodeFocusedIndex < dialingCodeSelectRef.current!.scrollTop) {
          const focusedItem = dialingCodeSelectRef.current!.children[
            dialingCodeFocusedIndex - 1
          ] as HTMLElement;
          const scrollOffset =
            dialingCodeSelectRef.current!.scrollTop - focusedItem.offsetTop;

          dialingCodeSelectRef.current!.scrollTop -= scrollOffset;
        }

        setDialingCodeFocusedIndex((prevIndex) => prevIndex - 1);
      }
    },
    Enter: () => {
      const focusedOption = selectCountryOptions[dialingCodeFocusedIndex];

      onDialingCodeOptionClick(focusedOption);
    },
    Space: (e) => {
      e.preventDefault();

      setIsDialingCodeListOpen(true);
    },
    Tab: () => {
      closeDialingCodeList();
    },
    ref: dialingCodeInputRef,
  });

  const onFocus = () => {
    setIsFocused(true);
    inputWrapperRef?.current?.classList.add("active");
    if (props.onFocus) props.onFocus();
  };

  const onBlur = () => {
    inputWrapperRef?.current?.classList.remove("active");
    if (props.onBlur) props.onBlur();
  };

  const onPhoneNumberChange = (value: string) => {
    const isInputValid = phoneNumberInputHelper.validatePhoneInput(value);

    if (props.onChange && isInputValid)
      props.onChange({
        dialingCode: props.phoneNumber.dialingCode,
        number: value,
      });
  };

  const onDialingCodeChange = (value: PhoneNumberInputDialingCodeOption) => {
    if (props.onChange)
      props.onChange({
        dialingCode: value.dialingCode,
        number: props.phoneNumber.number,
      });
  };

  const selectCountryOptions = phoneNumberInputHelper.getCountryOptions();

  const selectedOption = selectCountryOptions.find(
    (item) => item.dialingCode === props.phoneNumber.dialingCode
  );

  const toggleDialingCodeList = () => {
    setIsDialingCodeListOpen((curr) => !curr);
  };

  const closeDialingCodeList = () => {
    setIsDialingCodeListOpen(false);
  };

  const onOutsideClick = () => {
    if (!isFocused) {
      return;
    }
    setIsFocused(false);
    closeDialingCodeList();
    onBlur();
  };

  const dialingCodeInputValue = selectedOption
    ? `${selectedOption.countryCode} ${selectedOption.dialingCode}`
    : "";

  const onDialingCodeOptionClick = (
    countryOption: PhoneNumberInputDialingCodeOption
  ) => {
    onDialingCodeChange(countryOption);
    closeDialingCodeList();
    setTimeout(() => {
      numberInputRef.current?.focus();
    });
  };

  const clearInput = () => {
    if (props.onChange) {
      const emptyValue = phoneNumberInputHelper.getEmptyValue();
      props.onChange(emptyValue);
    }
  };

  const showClearButton =
    props.phoneNumber.dialingCode || props.phoneNumber.number;

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <label
        ref={inputWrapperRef}
        className={inputWrapperClassnames}
        onFocus={onFocus}
      >
        <div className="phone_number_input_dialing_code_info">
          <input
            type={props.type || "text"}
            data-test-id={
              props.idForTesting
                ? `${props.idForTesting}-dialing-code-input`
                : undefined
            }
            value={dialingCodeInputValue}
            placeholder={translations.dialingCodePlaceholder}
            className="phone_number_input_dialing_code_info__input"
            autoCorrect="false"
            ref={dialingCodeInputRef}
            readOnly
          />
          <FontAwesomeIcon
            className="phone_number_input_dialing_code_info__icon"
            icon={faChevronDown}
            size="sm"
            onClick={toggleDialingCodeList}
          />
        </div>
        <div className="phone_number_input_number_info">
          <input
            ref={numberInputRef}
            type={props.type || "text"}
            data-test-id={
              props.idForTesting
                ? `${props.idForTesting}-number-input`
                : undefined
            }
            value={props.phoneNumber?.number ?? ""}
            placeholder={props.placeholder}
            className={inputClassnames}
            autoComplete="off"
            onChange={(e) => onPhoneNumberChange(e.target.value)}
            autoCorrect="false"
            readOnly={props.isReadOnly || props.isDisabled}
            disabled={props.isDisabled}
            step="any"
            onBlur={onBlur}
          />
          {showClearButton && (
            <FontAwesomeIcon
              className="phone_number_input_number_info__icon"
              icon={faXmark}
              size="sm"
              onClick={clearInput}
            />
          )}
        </div>

        {isDialingCodeListOpen && (
          <ul
            className="phone_number_input_dialing_code_list"
            ref={dialingCodeSelectRef}
          >
            {selectCountryOptions.map((option, index) => {
              return (
                <li
                  className={classNames(
                    "phone_number_input_dialing_code_list_item",
                    dialingCodeFocusedIndex === index && "focused"
                  )}
                  key={option.countryCode}
                  onClick={() => onDialingCodeOptionClick(option)}
                >
                  <div className="phone_number_input_dialing_code_list_item__code">
                    {option.countryCode}
                  </div>
                  <div className="phone_number_input_dialing_code_list_item__dialing_code">
                    {option.dialingCode}
                  </div>
                  <div className="phone_number_input_dialing_code_list_item__country">
                    {option.country}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </label>
    </OutsideClickHandler>
  );
};

export default PhoneNumberInputComponent;
