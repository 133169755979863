import TaxiUnsettledPrivateOrderListingItemStatus from "./taxi-unsettled-private-order-listing-item-status";

export enum TaxiUnsettledPrivateOrderListingItemMissingSettlementType {
  CARGO_WITH_RAILY = "CARGO_WITH_RAILY",
  CARGO_WITH_TAXI = "CARGO_WITH_TAXI",
  TAXI_WITH_RAILY = "TAXI_WITH_RAILY",
  RAILY_WITH_TAXI = "RAILY_WITH_TAXI",
}

type TaxiUnsettledPrivateOrderListingItem = {
  uuid: string;
  startDate: Date;
  internalOrderId: number;
  externalOrderId: string | null;
  routeAddresses: (string | string[])[];
  passengers: string[];
  dispatchName: string;
  clientName: string;
  taxiCorporations: string[];
  driversDisplayNames: string[];
  status: TaxiUnsettledPrivateOrderListingItemStatus;
  missingSettlementTypes: TaxiUnsettledPrivateOrderListingItemMissingSettlementType[];
};

export default TaxiUnsettledPrivateOrderListingItem;
