import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import {
  OrderDetailsManageDriverAssignmentDriversResponseDriver,
  OrderDetailsManageDriverAssignmentDriversResponseDriverAssociationToTaxi,
  OrderDetailsManageDriverAssignmentDriversResponseDriverPlanEntry,
} from "./api/order-details-manage-driver-assignment-drivers.response";
import {
  OrderDetailsManageDriverAssignmentDriverPlanEntry,
  OrderDetailsManageDriverAssignmentDriverTaxiCorporation,
} from "./types/order-details-manage-driver-assignment-driver";
import OrderDetailsManageDriverAssignmentDriverSelectOption from "./types/order-details-manage-driver-assignment-driver-select-option";
import OrderDetailsManageDriverAssignmentSelectOption from "./types/order-details-manage-driver-assignment-select-option";
import OrderDetailsManageDriverAssignmentTaxiCorporationSelectOption from "./types/order-details-manage-driver-assignment-taxi-corporation-select-option";

const createPlanEntry = (
  responsePlanEntry: OrderDetailsManageDriverAssignmentDriversResponseDriverPlanEntry
): OrderDetailsManageDriverAssignmentDriverPlanEntry => {
  return {
    uuid: responsePlanEntry.id,
    ordersCargoCompanyInternalIds: responsePlanEntry.solved_orders
      .map((solvedOrder) => solvedOrder.transporting_order.cargo_order.human_id)
      .flat(),
  };
};

const createPlanEntries = (
  responsePlanEntries: OrderDetailsManageDriverAssignmentDriversResponseDriverPlanEntry[]
): OrderDetailsManageDriverAssignmentDriverPlanEntry[] => {
  return responsePlanEntries.map(createPlanEntry);
};

const createDriverTaxiCorporation = (
  responseTaxiAssociation: OrderDetailsManageDriverAssignmentDriversResponseDriverAssociationToTaxi
): OrderDetailsManageDriverAssignmentDriverTaxiCorporation => {
  return {
    name: responseTaxiAssociation.taxi_corporation.company_name,
    displayName: responseTaxiAssociation.taxi_corporation.display_name,
    uuid: responseTaxiAssociation.taxi_corporation.id,
  };
};

const createDriverTaxiCorporations = (
  responseTaxiAssociations: OrderDetailsManageDriverAssignmentDriversResponseDriverAssociationToTaxi[]
): OrderDetailsManageDriverAssignmentDriverTaxiCorporation[] => {
  return responseTaxiAssociations.map(createDriverTaxiCorporation);
};

const createDriverSelectOption = (
  responseDriver: OrderDetailsManageDriverAssignmentDriversResponseDriver
): OrderDetailsManageDriverAssignmentDriverSelectOption => {
  const planEntries = createPlanEntries(responseDriver.entries);
  const taxiCorporations = createDriverTaxiCorporations(
    responseDriver.taxi_driver_associations
  );

  return {
    label: `${responseDriver.user.first_name} ${responseDriver.user.last_name}`,
    value: {
      uuid: responseDriver.user.id,
      firstName: responseDriver.user.first_name,
      lastName: responseDriver.user.last_name,
      planEntries,
      taxiCorporations,
    },
  };
};

const createDriverSelectOptions = (
  responseDrivers: OrderDetailsManageDriverAssignmentDriversResponseDriver[]
): OrderDetailsManageDriverAssignmentDriverSelectOption[] => {
  return responseDrivers.map(createDriverSelectOption);
};

const createNewPlanEntryOption =
  (): OrderDetailsManageDriverAssignmentSelectOption => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment;

    return {
      label: translations.form.assignmentToNewPlanEntrySelectOptionLabel,
      value: null,
    };
  };

const createAssignmentOptionFromPlanEntry = (
  planEntry: OrderDetailsManageDriverAssignmentDriverPlanEntry
): OrderDetailsManageDriverAssignmentSelectOption => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment;

  const label =
    translations.form.assignmentToExistingPlanEntrySelectOptionLabelTemplate.replace(
      "#{ordersCargoCompanyInternalIds}",
      planEntry.ordersCargoCompanyInternalIds.join()
    );
  return {
    label,
    value: {
      planEntry: {
        uuid: planEntry.uuid,
        orderCargoCompanyInternalIds: planEntry.ordersCargoCompanyInternalIds,
      },
    },
  };
};

const createAssignmentOptionsFromPlanEntries = (
  planEntries: OrderDetailsManageDriverAssignmentDriverPlanEntry[]
): OrderDetailsManageDriverAssignmentSelectOption[] => {
  return planEntries.map(createAssignmentOptionFromPlanEntry);
};

const createAssignmentSelectOptions = (
  planEntries: OrderDetailsManageDriverAssignmentDriverPlanEntry[]
): OrderDetailsManageDriverAssignmentSelectOption[] => {
  const newPlanEntryOption = createNewPlanEntryOption();
  const optionsFromPlanEntries =
    createAssignmentOptionsFromPlanEntries(planEntries);

  return [newPlanEntryOption, ...optionsFromPlanEntries];
};

const createTaxiCorporationSelectOptions = (
  driverTaxiAssocations: OrderDetailsManageDriverAssignmentDriverTaxiCorporation[]
): OrderDetailsManageDriverAssignmentTaxiCorporationSelectOption[] => {
  return driverTaxiAssocations.map((taxiCorporation) => {
    return {
      label: taxiCorporation.displayName,
      subText: taxiCorporation.name,
      value: {
        name: taxiCorporation.name,
        uuid: taxiCorporation.uuid,
      },
    };
  });
};

const orderDetailsManageDriverAssignmentFactory = {
  createDriverSelectOptions,
  createAssignmentSelectOptions,
  createTaxiCorporationSelectOptions,
};

export default orderDetailsManageDriverAssignmentFactory;
