import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import BooleanSelectOption from "./boolean-select-option";

const getOptions = (): BooleanSelectOption[] => {
  const translations =
    appTranslationsHelper.getComponentTranslations().booleanSelect;

  return [
    {
      label: translations.trueOptionLabel,
      value: true,
    },
    {
      label: translations.falseOptionLabel,
      value: false,
    },
  ];
};

const booleanSelectHelper = {
  getOptions,
};

export default booleanSelectHelper;
