import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import mileageDictionaryUpdateFormValildationService from "./mileage-dictionary-update-form-validation.service";
import MileageDictionaryUpdateFormData from "./types/mileage-dictionary-update-form-data";

const getDefaultFormData = (): MileageDictionaryUpdateFormData => {
  return {
    name: undefined,
    active: true,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<MileageDictionaryUpdateFormData> => {
    return {
      name: (values) =>
        mileageDictionaryUpdateFormValildationService.validateName(values.name),
      active: (values) =>
        mileageDictionaryUpdateFormValildationService.validateActive(
          values.active
        ),
    };
  };

const mileageDictionaryUpdateFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default mileageDictionaryUpdateFormHelper;
