import HttpResponse from "../../../../utils/http/http.response";

export enum CargoOrderFinishedListResponseItemPublicStatus {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
}

type CargoOrderFinishedListResponseItemProducer = {
  id: string | null;
  name: string;
};

type CargoOrderFinishedListResponseItemConsumer = {
  id: string | null;
  name: string;
};

type CargoOrderFinishedListResponseItemClient = {
  id: string | null;
  name: string;
};

type CargoOrderFinishedListResponseItemAttentions = {
  newSharedPost: boolean;
  newPlanEntryPost: boolean;
  cargoOrderEdit: boolean;
  cargoOrderCancel: boolean;
};

export type CargoOrderFinishedListResponseDataItem = {
  id: string;
  startTime: Date;
  humanId: number;
  isCancelled: boolean;
  publicStatus: CargoOrderFinishedListResponseItemPublicStatus;
  rideAddresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  consumer: CargoOrderFinishedListResponseItemConsumer | null;
  producer: CargoOrderFinishedListResponseItemProducer;
  client: CargoOrderFinishedListResponseItemClient;
  push: boolean;
  drivers: string[];
  externalId: string | null;
  isCancelRequested: boolean;
  taxiCorporations: string[];
  attentions: CargoOrderFinishedListResponseItemAttentions;
  slkStatus: boolean | null;
};

type CargoOrderFinishedListResponse = HttpResponse<{
  data: CargoOrderFinishedListResponseDataItem[];
  totalCount: number;
}>;

export default CargoOrderFinishedListResponse;
