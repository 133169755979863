export enum DelegationDetailsStatus {
  NEW = "NEW",
  DOWNLOADED = "DOWNLOADED",
  DELETED = "DELETED",
}

export enum DelegationDetailsType {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export type DelegationDetailsAddressNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
};

export type DelegationDetailsMileageData = {
  mileageDate: Date;
  cardNumber?: string;
  commissionNumber?: string;
  mileageNumber?: string;
  humanId: number;
};

type DelegationDetails = {
  uuid: string;
  delegationNumber: string;
  status: DelegationDetailsStatus;
  type: DelegationDetailsType;
  workerName: string;
  workerPhone: string;
  workerUuid: string;
  dateFrom: Date;
  dateTo: Date;
  totalCost: number;
  humanId: number;
  addressSequence: DelegationDetailsAddressNode[][];
  mileageData: DelegationDetailsMileageData[];
};

export default DelegationDetails;
