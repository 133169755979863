import { FC } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsOrderRouteComponentProps = {
  details: OrderDetailsCargoOrder | null;
  tools: OrderDetailsToolsTranslations;
  hasAccess: boolean;
  onSuccess: () => void;
};

const OrderDetialsOrderRouteComponent: FC<
  OrderDetialsOrderRouteComponentProps
> = ({ details, tools, hasAccess, onSuccess }) => {
  const { isFinished, isCancelled } = details!;

  const isEditOrderRouteButtonVisible =
    !isFinished && !isCancelled && hasAccess;

  if (!isEditOrderRouteButtonVisible) return null;

  const { editOrderRouteButtonTitle } = tools;

  return (
    <OrderDetailsToolButtonComponent
      onClick={onSuccess}
      icon={faPencil}
      title={editOrderRouteButtonTitle}
    />
  );
};

export default OrderDetialsOrderRouteComponent;
