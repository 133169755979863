import BillingStatus from "../../../types/billing-status";
import BillingStatusUpdateRequest from "../api/billing-status-update.request";
import { ActionOption } from "../types/action-option";

const createUpdateBillingStatusRequest = (
  actionOption: ActionOption,
  comment: string
): BillingStatusUpdateRequest => {
  const result: BillingStatusUpdateRequest = {
    comment: comment,
    status: setBillingStatus(actionOption),
  };

  return result;
};

const setBillingStatus = (action: ActionOption): BillingStatus => {
  switch (action) {
    case ActionOption.ACCEPT:
      return BillingStatus.ACCEPTED;
    case ActionOption.REJECT:
      return BillingStatus.REJECTED;
    case ActionOption.REOPEN:
      return BillingStatus.REOPENED;
    case ActionOption.SEND_REQUEST_TO_REOPEN:
      return BillingStatus.REOPEN_REQUEST;
  }
};

const billingStatusUpdateRequestFactory = {
  createUpdateBillingStatusRequest,
};

export default billingStatusUpdateRequestFactory;
