import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import taxiUnsettledRouteListingFilterHelper from "../../taxi-unsettled-route-listing-filter.helper";
import TaxiUnsettledRouteListingFilter from "../../types/taxi-unsettled-route-listing-filter";
import TaxiUnsettledRouteListingFilterSelectOption from "../../types/taxi-unsettled-route-listing-filter-select-option";

type TaxiUnsettledRouteListingFiltersSelectProps = {
  filters: TaxiUnsettledRouteListingFilter[];
  onAddNewFilter: (filter: TaxiUnsettledRouteListingFilter) => void;
};

const TaxiUnsettledRouteListingFiltersSelectComponent: FC<
  TaxiUnsettledRouteListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: TaxiUnsettledRouteListingFilterSelectOption[] =
    useMemo(() => {
      return taxiUnsettledRouteListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: TaxiUnsettledRouteListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as TaxiUnsettledRouteListingFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="taxi-unsettled-route-listing-filter"
    />
  );
};

export default TaxiUnsettledRouteListingFiltersSelectComponent;
