import { FC } from "react";
import ListingSortDefinition from "../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterDefinition from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingFilterComponent from "../../../../common/components/listing/filter/listing-filter.component";
import RouteFinishedListingFilter from "../common/types/route-finished-listing-filter";
import RouteFinishedListingSortKey from "../common/types/route-finished-listing-sort-key";
import routeFinishedListingFilterHelper from "./route-finished-listing-filter.helper";

type RouteFinishedListingFilterProps = {
  filters: RouteFinishedListingFilter[];
  onFiltersChange: (filters: RouteFinishedListingFilter[]) => void;
  sortKey: RouteFinishedListingSortKey;
  onSortKeyChange: (sortKey: RouteFinishedListingSortKey) => void;
  hasAccessToTaxiCorporation: boolean;
};

const RouteFinishedListingFilterComponent: FC<
  RouteFinishedListingFilterProps
> = (props) => {
  const filterDefinition: ListingFilterDefinition<RouteFinishedListingFilter> =
    routeFinishedListingFilterHelper().getFilterDefinition(
      props.hasAccessToTaxiCorporation
    );

  const sortDefinition: ListingSortDefinition =
    routeFinishedListingFilterHelper().getSortDefinition(
      props.hasAccessToTaxiCorporation
    );

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as RouteFinishedListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as RouteFinishedListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="route-finished-listing-filter"
      idForTestingSort="route-finished-listing-sort"
    />
  );
};

export default RouteFinishedListingFilterComponent;
