import routeTranslationsHelper from "../../../../languages/route-translations.helper";
import RouteFinishedListingTableColumn from "../common/types/route-finished-listing-table-column";
import { RouteFinishedListingUserPermissions } from "../common/user-permissions/route-finished-listing-user-permission";

const getColumns = (
  userPermissions: RouteFinishedListingUserPermissions
): RouteFinishedListingTableColumn[] => {
  const translations =
    routeTranslationsHelper.getFinishedListingTranslations().table.headers;

  const taxiCorporationColumn: RouteFinishedListingTableColumn = {
    header: translations.taxiCorporationLabel,
    title: translations.taxiCorporationTitle,
    accessor: "taxiCorporation",
    colSpan: 10,
  };

  const tableColumns: RouteFinishedListingTableColumn[] = [
    {
      header: translations.startDateLabel,
      title: translations.startDateTitle,
      accessor: "startDate",
      colSpan: 10,
    },
    {
      header: translations.humanIdLabel,
      title: translations.humanIdTitle,
      accessor: "humanId",
      colSpan: 5,
    },
    {
      header: translations.cargoOrderHumanIdsLabel,
      title: translations.cargoOrderHumanIdsTitle,
      accessor: "cargoOrderHumanIds",
      colSpan: 5,
    },
    {
      header: translations.routeLabel,
      title: translations.routeTitle,
      accessor: "route",
      colSpan: 15,
    },
    {
      header: translations.passengersLabel,
      title: translations.passengersLabel,
      accessor: "passengers",
      colSpan: 10,
    },
    ...(userPermissions.hasAccessToTaxiCorporation
      ? [taxiCorporationColumn]
      : []),
    {
      header: translations.driverLabel,
      title: translations.driverTitle,
      accessor: "driver",
      colSpan: 10,
    },
  ];

  return tableColumns;
};

const routeFinishedListingTableHelper = {
  getColumns,
};

export default routeFinishedListingTableHelper;
