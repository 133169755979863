import MileageContractDetails from "../../../../../common/services/mileage-contract/details/mileage-contract-details";
import mileageContractUpdateFormHelper from "../form/mileage-contract-update-form.helper";
import MileageContractUpdateFormData from "../form/types/mileage-contract-update-form-data";

const create = (
  details: MileageContractDetails | null
): MileageContractUpdateFormData => {
  if (!details) return mileageContractUpdateFormHelper.getEmptyFormData();

  return {
    distanceRateCompanyVehicle: details.distanceRateCompanyVehicle,
    distanceRateCarGt900cm3: details.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: details.distanceRateCarLe900cm3,
    distanceRateMotorcycle: details.distanceRateMotorcycle,
    distanceRateMoped: details.distanceRateMoped,
    dietFullRate: details.dietFullRate,
    dietFullAfterHours: details.dietFullAfterHours,
    dietHalfRate: details.dietHalfRate,
    dietHalfAfterHours: details.dietHalfAfterHours,
    periodOfValidity: null,
    isActive: details.isActive,
  };
};

const mileageContractUpdateFormFactory = {
  create,
};

export default mileageContractUpdateFormFactory;
