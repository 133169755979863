import appAsideNavigationHelper from "../../common/app-aside-navigation.helper";
import AppAsideNavigationDefinitionItem, {
  AppAsideNavigationDefinitionGroup,
  AppAsideNavigationDefinitionLink,
} from "../../common/types/app-aside-navigation-definition";
import AppAsideExpandedNavigationGroup from "./types/app-aside-expanded-navigation-group";
import AppAsideExpandedNavigationItem from "./types/app-aside-expanded-navigation-item";
import AppAsideExpandedNavigationLink from "./types/app-aside-expanded-navigation-link";

const createLinkNavigationItem = (
  navigationDefinitionLink: AppAsideNavigationDefinitionLink,
  pathname: string
) => {
  const isActive = navigationDefinitionLink.path === pathname;

  const link: AppAsideExpandedNavigationLink = {
    ...navigationDefinitionLink,
    isActive,
  };

  return link;
};

const createGroupNavigationItem = (
  navigationDefinitionGroup: AppAsideNavigationDefinitionGroup,
  expandedNavigationItemKeys: string[],
  pathname: string
) => {
  const isExpanded = expandedNavigationItemKeys.includes(
    navigationDefinitionGroup.key
  );
  const isHighlighted = expandedNavigationItemKeys.includes(
    navigationDefinitionGroup.key
  );

  const subItems: AppAsideExpandedNavigationItem[] = isExpanded
    ? createNavigationItems(
        navigationDefinitionGroup.items,
        expandedNavigationItemKeys,
        pathname
      )
    : [];

  const group: AppAsideExpandedNavigationGroup = {
    ...navigationDefinitionGroup,
    isExpanded,
    isHighlighted,
    items: subItems,
  };

  return group;
};

const createNavigationItems = (
  navigationDefinitionItems: AppAsideNavigationDefinitionItem[],
  expandedNavigationItemKeys: string[],
  pathname: string
): AppAsideExpandedNavigationItem[] => {
  return navigationDefinitionItems.map((navigationDefinitionItem) => {
    const isLink = appAsideNavigationHelper.checkIsDefinitionLink(
      navigationDefinitionItem
    );
    if (isLink) {
      return createLinkNavigationItem(navigationDefinitionItem, pathname);
    }

    return createGroupNavigationItem(
      navigationDefinitionItem,
      expandedNavigationItemKeys,
      pathname
    );
  });
};

const appAsideExpandedNavigationFactory = {
  createNavigationItems,
};

export default appAsideExpandedNavigationFactory;
