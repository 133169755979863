import { FC } from "react";
import { ModalProps } from "../../../../../../../common/components/modal/modal.component";
import OrderRouteEditPassenger from "../../../types/order-route-edit-passenger";
import OrderRouteEditExternalPassengerEditComponent from "./external/order-route-edit-external-passenger-edit.component";
import OrderRouteEditInternalPassengerEditComponent from "./internal/order-route-edit-internal-passenger-edit.component";
import orderRouteEditHelper from "../../../helper/order-route-edit.helper";

type OrderRouteEditPassengerEditProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  passenger: OrderRouteEditPassenger | undefined;
  onSubmit: (passenger: OrderRouteEditPassenger) => void;
};

const OrderRouteEditPassengerEditComponent: FC<
  OrderRouteEditPassengerEditProps
> = (props) => {
  if (!props.passenger) {
    return null;
  }

  if (orderRouteEditHelper.checkIsInternalPassenger(props.passenger)) {
    return (
      <OrderRouteEditInternalPassengerEditComponent
        isOpen={props.isOpen}
        onClose={props.onClose}
        onSubmit={props.onSubmit}
        passenger={props.passenger}
      />
    );
  }

  return (
    <OrderRouteEditExternalPassengerEditComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={props.onSubmit}
      passenger={props.passenger}
    />
  );
};

export default OrderRouteEditPassengerEditComponent;
