import TaxiTaxiContractAddRequest from "../../api/raily/taxi-taxi-contract/add/taxi-taxi-contract-add.request";
import TaxiTaxiContractDetailsRequest from "../../api/raily/taxi-taxi-contract/details/taxi-taxi-contract-details.request";
import TaxiTaxiContractListRequest from "../../api/raily/taxi-taxi-contract/list/taxi-taxi-contract-list.request";
import taxiTaxiContractApiService from "../../api/raily/taxi-taxi-contract/taxi-taxi-contract-api.service";
import TaxiTaxiContractUpdateRequest from "../../api/raily/taxi-taxi-contract/update/taxi-taxi-contract-update.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import TaxiTaxiContractAddError from "./add/taxi-taxi-contract-add-error";
import TaxiTaxiContractAddParams from "./add/taxi-taxi-contract-add-params";
import taxiTaxiContractAddRequestFactory from "./add/taxi-taxi-contract-add-request.factory";
import TaxiTaxiContractDetails from "./details/taxi-taxi-contract-details";
import TaxiTaxiContractDetailsError from "./details/taxi-taxi-contract-details-error";
import TaxiTaxiContractDetailsLoadParams from "./details/taxi-taxi-contract-details-load-params";
import taxiTaxiContractDetailsFactory from "./details/taxi-taxi-contract-details.factory";
import TaxiTaxiContractList from "./list/taxi-taxi-contract-list";
import TaxiTaxiContractListError from "./list/taxi-taxi-contract-list-error";
import TaxiTaxiContractListLoadParams from "./list/taxi-taxi-contract-list-load-params";
import taxiTaxiContractListRequestFactory from "./list/taxi-taxi-contract-list-request.factory";
import taxiTaxiContractListFactory from "./list/taxi-taxi-contract-list.factory";
import TaxiTaxiContractUpdateError from "./update/taxi-taxi-contract-update-error";
import TaxiTaxiContractUpdateParams from "./update/taxi-taxi-contract-update-params";
import taxiTaxiContractUpdateRequestFactory from "./update/taxi-taxi-contract-update-request.factory";

const handleAddError = (
  error: HttpError | TaxiTaxiContractAddError
): TaxiTaxiContractAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: TaxiTaxiContractAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TaxiTaxiContractAddRequest =
    taxiTaxiContractAddRequestFactory.create(params);

  try {
    const response = await taxiTaxiContractApiService().add(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TaxiTaxiContractAddError;

    throw handleAddError(error);
  }
};

const handleListError = (
  error: HttpError | TaxiTaxiContractListError
): TaxiTaxiContractListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: TaxiTaxiContractListLoadParams,
  abortSignal: AbortSignal
): Promise<TaxiTaxiContractList> => {
  const request: TaxiTaxiContractListRequest =
    taxiTaxiContractListRequestFactory.create(params);

  try {
    const response = await taxiTaxiContractApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return taxiTaxiContractListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | TaxiTaxiContractListError;

    throw handleListError(error);
  }
};

const handleUpdateError = (
  error: HttpError | TaxiTaxiContractUpdateError
): TaxiTaxiContractUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const update = async (
  params: TaxiTaxiContractUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TaxiTaxiContractUpdateRequest =
    taxiTaxiContractUpdateRequestFactory.create(params);

  try {
    const response = await taxiTaxiContractApiService().update(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TaxiTaxiContractUpdateError;

    throw handleUpdateError(error);
  }
};

const handleDetailsError = (
  error: HttpError | TaxiTaxiContractDetailsError
): TaxiTaxiContractListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: TaxiTaxiContractDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<TaxiTaxiContractDetails> => {
  const request: TaxiTaxiContractDetailsRequest = {
    contractUuid: params.contractUuid,
  };

  try {
    const response = await taxiTaxiContractApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return taxiTaxiContractDetailsFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | TaxiTaxiContractDetailsError;

    throw handleDetailsError(error);
  }
};

const taxiTaxiContractService = {
  add,
  getList,
  update,
  getDetails,
};

export default taxiTaxiContractService;
