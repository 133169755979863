export enum TaxiOfficerListRequestOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
}

export enum TaxiOfficerListRequestStatusCriteria {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  ALL = "ALL",
}

export type TaxiOfficerListRequestQueryParams = {
  taxiCorporation: string | undefined;
  taxiCorporationId: string | undefined;
  order: TaxiOfficerListRequestOrder | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  username: string | undefined;
  email: string | undefined;
  statusCriteria: TaxiOfficerListRequestStatusCriteria | undefined;
  offset: number | undefined;
  limit: number | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
};

type TaxiOfficerListRequest = {
  taxiCorporation?: string;
  taxiCorporationId?: string;
  order?: TaxiOfficerListRequestOrder;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  statusCriteria?: TaxiOfficerListRequestStatusCriteria;
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
};

export default TaxiOfficerListRequest;
