import CargoOrderFinishedListRequest, {
  CargoOrderFinishedListRequestQueryParams,
} from "./cargo-order-finished-list.request";

const createQueryParams = (
  data: CargoOrderFinishedListRequest
): CargoOrderFinishedListRequestQueryParams => {
  return {
    client: data.client,
    dispatch: data.dispatch,
    driver: data.driver,
    passenger: data.passenger,
    taxi: data.taxi,
    externalId: data.externalId,
    isApproved: data.isApproved,
    isCancelled: data.isCancelled,
    limit: data.limit,
    address: data.address,
    startAddress: data.startAddress,
    midAddress: data.midAddress,
    endAddress: data.endAddress,
    publicStatus: data.publicStatus,
    offset: data.offset,
    order: data.order,
    startTimeSince: data.startTimeSince,
    startTimeTo: data.startTimeTo,
    humanId: data.internalOrderId,
  };
};

const cargoOrderFinishedListRequestFactory = {
  createQueryParams,
};

export default cargoOrderFinishedListRequestFactory;
