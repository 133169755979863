import { FC, useMemo, useState } from "react";
import CargoTaxiContractListingFilter from "../../common/types/cargo-taxi-contract-listing-filter";
import { useAppContext } from "../../../../../../context/app.context";
import cargoTaxiContractListingFilterHelper from "../../common/filter/cargo-taxi-contract-listing-filter.helper";
import CargoTaxiContractListingFilterSelectOption from "../../common/types/cargo-taxi-contract-listing-filter-select-option";
import ListingFilterSelectComponent from "../../../../../../common/components/listing/filter/select/listing-filter-select.component";

type CargoTaxiContractListingFiltersSelectProps = {
  filters: CargoTaxiContractListingFilter[];
  onAddNewFilter: (filter: CargoTaxiContractListingFilter) => void;
};

const CargoTaxiContractListingFiltersSelectComponent: FC<
  CargoTaxiContractListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions = useMemo(() => {
    return cargoTaxiContractListingFilterHelper.getSelectOptions(
      searchInputValue,
      props.filters
    );
  }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: CargoTaxiContractListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as CargoTaxiContractListingFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="cargo-taxi-contract-listing-filter"
    />
  );
};

export default CargoTaxiContractListingFiltersSelectComponent;
