import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import OrderDetailsChangeCargoExternalIdRequest from "./order-details-change-cargo-external-id.request";
import OrderDetailsChangeCargoExternalidResponse from "./order-details-change-cargo-external-id.response";

const updateCargoExternalId = async (
  orderUuid: string,
  request: OrderDetailsChangeCargoExternalIdRequest
): Promise<OrderDetailsChangeCargoExternalidResponse> => {
  const path = `/orders/${orderUuid}/external-id-update`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();

    return {
      status: r.status,
      data: responseData,
    };
  });
};

const orderDetailsChangeCargoExternalIdApiService = {
  updateCargoExternalId,
};

export default orderDetailsChangeCargoExternalIdApiService;
