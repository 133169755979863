import mapMarkerIconFactory from "../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../common/components/map/types/map-marker";
import BillingsNode from "../types/billings-node";

type DuplicatedNode = {
  positions: number[];
  lat: number;
  lon: number;
};

const createMapMarkers = (nodes: BillingsNode[]): MapMarker[] => {
  const duplicatedNodes: BillingsNode[] = [];
  const uniqueNodes: BillingsNode[] = [];

  nodes.forEach((node, index) => {
    let isDuplicate = false;

    for (let i = 0; i < nodes.length; i++) {
      if (i !== index) {
        const selectedNode = nodes[i];

        if (selectedNode.lat === node.lat && selectedNode.lon === node.lon) {
          isDuplicate = true;
          break;
        }
      }
    }

    if (isDuplicate) {
      duplicatedNodes.push(node);
    } else {
      uniqueNodes.push(node);
    }
  });

  const duplicatedMarkersResult: MapMarker[] =
    retrieveDuplicatedMarkers(duplicatedNodes);

  const uniqueMarkersResult = retrieveUniqueMarkers(uniqueNodes);

  return [...duplicatedMarkersResult, ...uniqueMarkersResult];
};

const retrieveUniqueMarkers = (uniqueNodes: BillingsNode[]): MapMarker[] => {
  return uniqueNodes.map((x) => {
    const signature = `${x.position}`;
    const icon = mapMarkerIconFactory.createIcon({
      className: "map_marker standard",
      content: signature,
    });

    return {
      coordinate: { latitude: x.lat, longitude: x.lon },
      title: x.description,
      tooltip: x.description,
      icon: icon,
    };
  });
};

const retrieveDuplicatedMarkers = (
  duplicatedNodes: BillingsNode[]
): MapMarker[] => {
  const duplicatedMarkersResult: MapMarker[] = [];
  const duplicatesMap = new Map<string, DuplicatedNode>();

  duplicatedNodes.forEach((duplicatedNode) => {
    const key = `${duplicatedNode.lat}_${duplicatedNode.lon}`;

    if (duplicatesMap.has(key)) {
      duplicatesMap.get(key)?.positions.push(duplicatedNode.position);
    } else {
      duplicatesMap.set(key, {
        positions: [duplicatedNode.position],
        lat: duplicatedNode.lat,
        lon: duplicatedNode.lon,
      });
    }
  });

  duplicatesMap.forEach((item) => {
    const signature = item.positions.join("/");
    const icon = mapMarkerIconFactory.createIcon({
      className: "map_marker standard",
      content: signature,
    });
    duplicatedMarkersResult.push({
      coordinate: {
        latitude: item.lat,
        longitude: item.lon,
      },
      icon: icon,
    });
  });

  return duplicatedMarkersResult;
};

const billingsMapMarkersFactory = {
  createMapMarkers,
};

export default billingsMapMarkersFactory;
