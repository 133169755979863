import CargoOrderDetailsComplexRequest from "../../../api/raily/cargo-order/details-complex/cargo-order-details-complex.request";
import CargoOrderDetailsComplexLoadParams from "./cargo-order-details-complex-load-params";

const create = (
  params: CargoOrderDetailsComplexLoadParams
): CargoOrderDetailsComplexRequest => {
  return { orderId: params.orderUuid };
};

const cargoOrderDetailsComplexRequestFactory = {
  create,
};

export default cargoOrderDetailsComplexRequestFactory;
