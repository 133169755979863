import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import PassengerAddRequest from "./passenger-add-request";
import PassengerAddResponse from "./passenger-add-response";

const addPassenger = async (
  request: PassengerAddRequest
): Promise<PassengerAddResponse> => {
  const path = `/passengers`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const passengerAddApiService = {
  addPassenger,
};

export default passengerAddApiService;
