import { FC, useMemo } from "react";
import billingDetailsHelper from "./billing-details.helper";
import BillingFormData from "../types/billing-form.data";
import BillingDetailsTableRow from "./types/billing-details-table-row";
import dateService from "../../../../../common/utils/date/date.service";
import CardComponent from "../../../../../common/components/card/card.component";
import TableComponent from "../../../../../common/components/table/table.component";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import { BillingsTaxiTaxiContributionBonusType } from "../../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus";

type OrderBillingDetailsProps = {
  billingData: BillingFormData | undefined;
};

const BillingDetailsComponent: FC<OrderBillingDetailsProps> = (props) => {
  const createTableRow = (
    billingData: BillingFormData
  ): BillingDetailsTableRow => {
    const favorableDistance = billingData.billingContributions.bonus.find(
      (contribution) =>
        contribution.type ===
        BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE
    )?.distance;

    return {
      id: billingData.internalOrderId.toString(),
      value: {
        date: (
          <div title={dateService.format(billingData.date, "dd/mm/yyyy")}>
            {dateService.format(billingData.date, "dd/mm/yyyy")}
          </div>
        ),
        internalOrderId: (
          <div title={billingData.internalOrderId.toString()}>
            {billingData.internalOrderId}
          </div>
        ),
        companyName: (
          <div title={billingData.contractDetails?.companyName}>
            {billingData.contractDetails?.companyName}
          </div>
        ),
        passengers: (
          <div>
            {billingData.passengers.map((passenger, index) => (
              <div key={index} title={passenger}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        model: (
          <div title={billingData.contractDetails?.model}>
            {billingData.contractDetails?.model}
          </div>
        ),
        rate: (
          <div title={billingData.contractDetails?.distanceRate.toString()}>
            {billingData.contractDetails?.distanceRate.toFixed(2)} PLN
          </div>
        ),
        distance: (
          <div title={billingData.distance.toString()}>
            {billingData.distance}
          </div>
        ),
        additionalDistance: (
          <div title={favorableDistance ? favorableDistance.toString() : "0"}>
            {favorableDistance ?? 0}
          </div>
        ),
        amountForDistance: (
          <div title={billingData.amountForDistance.toString()}>
            {billingData.amountForDistance.toFixed(2)} PLN
          </div>
        ),
        amountForChargeHaltings: (
          <div title={billingData.amountForChargeHaltings.toString()}>
            {billingData.amountForChargeHaltings.toFixed(2)} PLN
          </div>
        ),
        amountForChargeHighways: (
          <div title={billingData.amountForChargeHighways.toString()}>
            {billingData.amountForChargeHighways.toFixed(2)} PLN
          </div>
        ),
        sumOfBonuses: (
          <div title={billingData.sumOfBonuses.toString()}>
            {billingData.sumOfBonuses.toFixed(2)} PLN
          </div>
        ),
        sumOfPenalties: (
          <div title={billingData.sumOfPenalties.toString()}>
            {billingData.sumOfPenalties.toFixed(2)} PLN
          </div>
        ),
        allContributionsAmount: (
          <div title={billingData.allContributionsAmount?.toString()}>
            {billingData.allContributionsAmount.toFixed(2)} PLN
          </div>
        ),
      },
    };
  };

  const tableColumns = billingDetailsHelper.getTableColumns();

  const tableRows: BillingDetailsTableRow[] = useMemo(() => {
    if (props.billingData) {
      const tableRow = createTableRow(props.billingData);
      return [tableRow];
    }

    return [];
  }, [props.billingData]);

  const translations = billingsTranslationsHelper.getRouteSummaryTranslations();

  return (
    <CardComponent
      classNames={{ root: "billing_details" }}
      header={{ title: translations.summaryLabel }}
    >
      <TableComponent columns={tableColumns} rows={tableRows} />
    </CardComponent>
  );
};

export default BillingDetailsComponent;
