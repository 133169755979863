import MileageSettingsAddRequest, {
  MileageSettingsAddRequestMileageNumberCreationModel,
} from "../../../api/raily/mileage-settings/add/mileage-settings-add.request";
import MileageSettingsAddParams, {
  MileageSettingsAddParamsMileageNumberCreationModel,
} from "./mileage-settings-add-params";

const createCreationModel = (
  model: MileageSettingsAddParamsMileageNumberCreationModel | undefined
): MileageSettingsAddRequestMileageNumberCreationModel | undefined => {
  switch (model) {
    case MileageSettingsAddParamsMileageNumberCreationModel.AUTO:
      return MileageSettingsAddRequestMileageNumberCreationModel.AUTO;
    case MileageSettingsAddParamsMileageNumberCreationModel.DICT:
      return MileageSettingsAddRequestMileageNumberCreationModel.DICT;
    case MileageSettingsAddParamsMileageNumberCreationModel.MANUAL:
      return MileageSettingsAddRequestMileageNumberCreationModel.MANUAL;
    default:
      return undefined;
  }
};

const create = (
  params: MileageSettingsAddParams
): MileageSettingsAddRequest => {
  return {
    companyUuid: params.companyUuid,
    isCardNumberRequired: params.isCardNumberRequired,
    isMileageNumberRequired: params.isMileageNumberRequired,
    mileageNumberCreationType: createCreationModel(
      params.mileageNumberCreationModel
    ),
  };
};

const mileageSettingsAddRequestfactory = {
  create,
};

export default mileageSettingsAddRequestfactory;
