import mapMarkerIconFactory from "../../../../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../../../common/components/map/types/map-route";
import {
  SearchRoadRoutingResponseRoute,
  SearchRoadRoutingResponseRouteGeometryCoordinate,
} from "../../../../../../../common/utils/search-road-route/search-road-routing.response";
import OrderAddSummaryRouteWaypoint from "./types/order-add-summary-route-waypoint";

const createMapMarker = (waypoint: OrderAddSummaryRouteWaypoint) => {
  const signature = `${waypoint.stageNo}`;

  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: signature,
  });

  const newMapMarker: MapMarker = {
    coordinate: {
      latitude: waypoint.place!.latitude,
      longitude: waypoint.place!.longitude,
    },
    icon: markerIcon,
    title: waypoint.place!.displayName,
    tooltip: waypoint.place!.displayName,
  };

  return newMapMarker;
};

const createMapMarkers = (
  waypoints: OrderAddSummaryRouteWaypoint[]
): MapMarker[] => {
  const mapMarkers: MapMarker[] = [];

  waypoints.forEach((waypoint) => {
    if (!waypoint.place) {
      return;
    }

    const newMapMarker = createMapMarker(waypoint);

    mapMarkers.push(newMapMarker);
  });

  return mapMarkers;
};

const createMapRoute = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute => {
  return {
    waypoints: routesResponseGeometryCoordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
  };
};

const createMapRoutes = (
  responseRoutes: SearchRoadRoutingResponseRoute[]
): MapRoute[] => {
  return responseRoutes.map((responseRoute) =>
    createMapRoute(responseRoute.geometry.coordinates)
  );
};

const orderAddSummaryMapFactory = {
  createMapMarkers,
  createMapRoute,
  createMapRoutes,
};

export default orderAddSummaryMapFactory;
