import { FC, useEffect, useState } from "react";
import ButtonComponent from "../../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import NumericInputComponent from "../../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import ModalComponent, {
  ModalProps,
} from "../../../../../../../common/components/modal/modal.component";
import driverDetailsPlanEditCheckoutApiService from "./common/api/driver-details-plan-edit-checkout-api.service";
import DriverDetailsPlanEditCheckoutRequest from "./common/api/driver-details-plan-edit-checkout.request";
import DriverDetailsPlanEditCheckoutResponse from "./common/api/driver-details-plan-edit-checkout.response";
import driverDetailsPlanEditCheckoutFormValidationService from "./common/driver-details-plan-edit-checkout-form-validation.service";
import driverDetailsPlanEditCheckoutFormHelper from "./common/driver-details-plan-edit-checkout-form.helper";
import DriverDetailsPlanEditCheckoutFormData from "./common/types/driver-details-plan-edit-checkout-form-data";
import DriverDetailsPlanEditCheckoutFormValidationResults from "./common/types/driver-details-plan-edit-checkout-form-validation-results";
import DriverDetailsDriverPlanEntry, {
  DriverDetailsDriverPlanEntryWaypoint,
} from "../../common/types/driver-details-plan-entry";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import notificationService from "../../../../../../../common/utils/notification/notification.service";

type DriverDetailsPlanEditCheckoutProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  onEditCheckoutSuccess: () => void;
  driverUuid: string;
  planEntry: DriverDetailsDriverPlanEntry | undefined;
  waypoint: DriverDetailsDriverPlanEntryWaypoint | undefined;
  checkoutEventUuid: string;
};

const DriverDetailsPlanEditCheckoutComponent: FC<
  DriverDetailsPlanEditCheckoutProps
> = (props) => {
  const translations =
    userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations()
      .editCheckout;

  const [formData, setFormData] =
    useState<DriverDetailsPlanEditCheckoutFormData>(() =>
      driverDetailsPlanEditCheckoutFormHelper.getDefaultFormData()
    );
  const [formValidationResults, setFormValidationResults] =
    useState<DriverDetailsPlanEditCheckoutFormValidationResults>(() =>
      driverDetailsPlanEditCheckoutFormHelper.getDefaultFormValidationResults()
    );

  const [isCheckoutEditFetching, setIsCheckoutEditFetching] = useState(false);

  const setHaltingTime = (
    haltingTimeMinutes: DriverDetailsPlanEditCheckoutFormData["haltingTimeMinutes"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      haltingTimeMinutes,
    }));
  };

  const setHighwayCost = (
    highwayCost: DriverDetailsPlanEditCheckoutFormData["highwayCost"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      highwayCost,
    }));
  };

  const validateHaltingTime = () => {
    const validationResult =
      driverDetailsPlanEditCheckoutFormValidationService.validateHaltingTime(
        formData.haltingTimeMinutes
      );

    setFormValidationResults((curr) => ({
      ...curr,
      haltingTime: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateHighwayCost = () => {
    const validationResult =
      driverDetailsPlanEditCheckoutFormValidationService.validateHighwayCost(
        formData.highwayCost
      );

    setFormValidationResults((curr) => ({
      ...curr,
      highwayCost: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateForm = (): boolean => {
    const isHaltingTimeValid = validateHaltingTime();
    const isHighwayCostValid = validateHighwayCost();

    return isHaltingTimeValid && isHighwayCostValid;
  };

  const onEditCheckoutSuccess = () => {
    notificationService.success(translations.successNotificationText);
    props.onEditCheckoutSuccess();
  };

  const onEditCheckoutFailure = () => {
    notificationService.error(translations.failureNotificationText);
  };

  const handleEditCheckoutResponse = (
    response: DriverDetailsPlanEditCheckoutResponse
  ) => {
    if (response.status === 200) {
      onEditCheckoutSuccess();
      return;
    }

    onEditCheckoutFailure();
  };

  const editCheckout = () => {
    setIsCheckoutEditFetching(true);

    const request: DriverDetailsPlanEditCheckoutRequest = {
      new_halting_time: formData.haltingTimeMinutes
        ? formData.haltingTimeMinutes * 60
        : null,
      new_highway_charge: formData.highwayCost,
    };

    driverDetailsPlanEditCheckoutApiService
      .editCheckout(
        props.driverUuid,
        props.planEntry?.uuid!,
        props.checkoutEventUuid,
        request
      )
      .then(handleEditCheckoutResponse)
      .catch(onEditCheckoutFailure)
      .finally(() => setIsCheckoutEditFetching(false));
  };

  const onSubmitButtonClick = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    editCheckout();
  };

  const clearFormData = () => {
    setFormData(driverDetailsPlanEditCheckoutFormHelper.getDefaultFormData());
  };

  const clearFormValidationResults = () => {
    setFormValidationResults(
      driverDetailsPlanEditCheckoutFormHelper.getDefaultFormValidationResults()
    );
  };

  useEffect(() => {
    if (props.isOpen) {
      return;
    }

    clearFormData();
    clearFormValidationResults();
  }, [props.isOpen]);

  useEffect(() => {
    if (!props.isOpen || !props.waypoint) {
      return;
    }

    setFormData({
      haltingTimeMinutes: props.waypoint.forcedHaltingTime
        ? props.waypoint.forcedHaltingTime / 60
        : null,
      highwayCost: props.waypoint.highwayCost,
    });
  }, [props.isOpen]);

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          isLoading={isCheckoutEditFetching}
          title={translations.form.submitButtonTitle}
        >
          {translations.form.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={props.onClose}
          type="brand"
          title={translations.form.rejectButtonTitle}
        >
          {translations.form.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <FormFieldComponent
        label={translations.form.haltingTimeLabel}
        errorMessage={formValidationResults.haltingTime.errorMessage}
        classNames={{ root: "mt-0" }}
      >
        <NumericInputComponent
          placeholder={translations.form.haltingTimePlaceholder}
          value={formData.haltingTimeMinutes}
          onChange={setHaltingTime}
          onBlur={validateHaltingTime}
          hasError={!!formValidationResults.haltingTime.errorMessage}
          isIntegerOnly
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.form.highwayCostLabel}
        errorMessage={formValidationResults.highwayCost.errorMessage}
      >
        <NumericInputComponent
          placeholder={translations.form.highwayCostPlaceholder}
          value={formData.highwayCost}
          onChange={setHighwayCost}
          onBlur={validateHighwayCost}
          hasError={!!formValidationResults.highwayCost.errorMessage}
        />
      </FormFieldComponent>
    </ModalComponent>
  );
};

export default DriverDetailsPlanEditCheckoutComponent;
