import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import {
  OrderCancelErrorType,
  OrderCancelOrderCancelledError,
  OrderCancelPassengerOnboardError,
} from "./order-cancel-error";

const createOrderCancelError = (): OrderCancelOrderCancelledError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrder.errors;

  return {
    message: translations.orderCancelledError,
    params: {},
    type: OrderCancelErrorType.ORDER_CANCELLED,
  };
};

const createPassengerOnBoardError = (): OrderCancelPassengerOnboardError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrder.errors;

  return {
    message: translations.passengerOnBoardError,
    params: {},
    type: OrderCancelErrorType.PASSENGER_ON_BOARD,
  };
};

const OrderCancelErrorFactory = {
  createOrderCancelError,
  createPassengerOnBoardError,
};

export default OrderCancelErrorFactory;
