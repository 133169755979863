import { FC } from "react";
import TableComponent from "../../../../../../../common/components/table/table.component";
import BillingsTaxiTaxiContributionsPenaltyTableRow from "../types/billings-taxi-taxi-contributions-penalty-table-row";
import BillingsTaxiTaxiContributionsPenaltyTableColumn from "../types/billings-taxi-taxi-contributions-penalty-table-column";
import BillingsTaxiTaxiContributionsPenaltyTableHelper from "./billings-taxi-taxi-contributions-penalty-table.helper";

type BillingsTaxiTaxiContributionsPenaltyTableProps = {
  rows: BillingsTaxiTaxiContributionsPenaltyTableRow[];
  isLoading: boolean;
};

const BillingsTaxiTaxiContributionsPenaltyTableComponent: FC<
  BillingsTaxiTaxiContributionsPenaltyTableProps
> = (props) => {
  const columns: BillingsTaxiTaxiContributionsPenaltyTableColumn[] =
    BillingsTaxiTaxiContributionsPenaltyTableHelper.getColumns();

  return (
    <TableComponent
      columns={columns}
      rows={props.rows}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiTaxiContributionsPenaltyTableComponent;
