import appConfig from "../../../../../app.config";
import SignUpVerifyTokenResponse from "./sign-up-verify-token.response";

const verifySignUpToken = (
  token: string
): Promise<SignUpVerifyTokenResponse> => {
  const path = `/auth/my-access-token`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const signUpApiService = {
  verifySignUpToken,
};

export default signUpApiService;
