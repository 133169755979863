import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useAbort from "../../../../common/hooks/use-abort";
import useDispatcherListingUserPermissions from "./common/user-permissions/use-dispatcher-listing-user-permissions";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import dispatcherListingLoadParamsFactory from "./common/dispatcher-listing-load-params.factory";
import usePagination from "../../../../common/hooks/use-pagination";
import DispatcherListingFilter from "./common/types/dispatcher-listing-filter";
import DispatcherListingSortKey from "./common/types/dispatcher-listing-sort-key";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import DispatcherListingFilterComponent from "./filter/dispatcher-listing-filter.component";
import CardComponent from "../../../../common/components/card/card.component";
import DispatcherListingTableComponent from "./table/dispatcher-listing-table.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import DispatcherListingItem from "./common/types/dispatcher-listing-item";
import dispatcherListingItemFactory from "./common/dispatcher-listing-item.factory";
import useDispatcherListingFiltersDao from "./filter/use-dispatcher-listing-filters.dao";
import UserDeleteUserData from "../../common/delete/common/types/user-delete-user-data";
import UserDeleteComponent from "../../common/delete/user-delete.component";
import useDispatcherList from "../../../../common/services/dispatcher/list/use-dispatcher-list";
import DispatcherListLoadParams from "../../../../common/services/dispatcher/list/dispatcher-list-load-params";

type DispatcherListingProps = {};

const DispatcherListingComponent: FC<DispatcherListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();
  const listingItemsAbort = useAbort();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();
  const userPermissions = useDispatcherListingUserPermissions();
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();
  useDocumentTitle(documentTitleTranslations.userDispatcherListing);

  const dispatcherList = useDispatcherList();
  const filtersDao = useDispatcherListingFiltersDao();

  const [filters, setFilters] = useState<DispatcherListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const [sortKey, setSortKey] = useState<DispatcherListingSortKey>(
    () => filtersDao.getSortKey() ?? DispatcherListingSortKey.FIRST_NAME_ASC
  );

  const listingItems: DispatcherListingItem[] = useMemo(() => {
    return dispatcherListingItemFactory.createListingItems(
      dispatcherList.data.data
    );
  }, [dispatcherList.data.data]);

  const [userSelectedToDelete, setUserSelectedToDelete] =
    useState<UserDeleteUserData | null>(null);

  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDispatcherListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const translations =
    userTranslationsHelper.getDispatcherListingTranslations();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: dispatcherList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const openDeleteUserModal = () => {
    setIsDeleteUserModalOpen(true);
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onSelectUserToDelete = (userData: UserDeleteUserData) => {
    setUserSelectedToDelete(userData);
    openDeleteUserModal();
  };

  const onDeleteUserSuccess = () => {
    closeDeleteUserModal();
    loadListingItems();
  };

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasVisibleAddDispatcherButton
        ? DispatcherAddLinkButton
        : undefined,
    ];
  };

  const DispatcherAddLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        to={userRoutesHelper.getDispatcherAddRoute()}
        type="primary"
        title={translations.header.addNewDispatcherLinkTitle}
        idForTesting="dispatcher-listing-add-button"
      >
        {translations.header.addNewDispatcherLinkLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const headingActions = createHeadingActions();

  const loadListingItems = async () => {
    const params: DispatcherListLoadParams =
      dispatcherListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey
      );

    dispatcherList.load(params, listingItemsAbort.signal);
  };

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <DispatcherListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <DispatcherListingTableComponent
          listingItems={listingItems}
          isError={dispatcherList.isError}
          isLoading={dispatcherList.isLoading}
          onSelectUserToDelete={onSelectUserToDelete}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={dispatcherList.data.totalCount}
        />
      </CardComponent>
      <UserDeleteComponent
        isOpen={isDeleteUserModalOpen}
        onClose={closeDeleteUserModal}
        userData={userSelectedToDelete}
        onSuccess={onDeleteUserSuccess}
      />
    </>
  );
};

export default DispatcherListingComponent;
