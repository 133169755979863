import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import MileageAddVehicleType from "../types/mileage-add-vehicle-type";
import mileageAddFormValidationService from "./mileage-add-form-validation.service";
import MileageAddFormData from "./types/mileage-add-form-data";
import MileageAddFormSettings, {
  MileageAddFormSettingsMileageNumberCreationModel,
} from "./types/mileage-add-form-settings";

const getDefaultSettings = (): MileageAddFormSettings => {
  return {
    isCardNumberRequired: true,
    isMileageNumberRequired: true,
    mileageNumberCreationModel:
      MileageAddFormSettingsMileageNumberCreationModel.MANUAL,
  };
};

const getDefaultFormData = (): MileageAddFormData => {
  return {
    addressSequence: [],
    cardNumber: "",
    commissionNumber: "",
    companyUuid: "",
    distance: 0,
    mileageDate: null,
    mileageNumber: "",
    notes: "",
    vehicleType: MileageAddVehicleType.CAR_GT_900CM3,
    workerUuid: null,
    settings: getDefaultSettings(),
    osrmDistance: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<MileageAddFormData> => {
    return {
      addressSequence: (values) =>
        mileageAddFormValidationService.validateAddressSequence(
          values.addressSequence
        ),
      cardNumber: (values) =>
        mileageAddFormValidationService.validateCardNumber(
          values.cardNumber,
          values.settings
        ),
      commissionNumber: (values) =>
        mileageAddFormValidationService.validateCommissionNumber(
          values.commissionNumber
        ),
      companyUuid: (values) =>
        mileageAddFormValidationService.validateCompanyUuid(values.companyUuid),
      distance: (values) =>
        mileageAddFormValidationService.validateDistance(values.distance),
      mileageDate: (values) =>
        mileageAddFormValidationService.validateMileageDate(values.mileageDate),
      mileageNumber: (values) =>
        mileageAddFormValidationService.validateMileageNumber(
          values.mileageNumber,
          values.settings
        ),
      notes: (values) =>
        mileageAddFormValidationService.validateNotes(values.notes),
      vehicleType: (values) =>
        mileageAddFormValidationService.validateVehicleType(values.vehicleType),
      workerUuid: (values) =>
        mileageAddFormValidationService.validateWorkerUuid(values.workerUuid),
      settings: formValidationService.ok,
      osrmDistance: (values) =>
        mileageAddFormValidationService.validateOSRMDistance(
          values.osrmDistance
        ),
    };
  };

const mileageAddFormHelper = {
  getDefaultSettings,
  getDefaultFormData,
  getValidationDefinition,
};

export default mileageAddFormHelper;
