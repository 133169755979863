import railyApiService from "../raily-api.service";
import driverPlanCandidatureAddByOrderIdRequestFactory from "./add-by-order-id/driver-plan-candidature-add-by-order-id-request.factory";
import DriverPlanCandidatureAddByOrderIdRequest, {
  DriverPlanCandidatureAddByOrderIdRequestBody,
} from "./add-by-order-id/driver-plan-candidature-add-by-order-id.request";
import DriverPlanCandidatureAddByOrderIdResponse from "./add-by-order-id/driver-plan-candidature-add-by-order-id.response";
import driverPlanCandidatureAddRequestFactory from "./add/driver-plan-candidature-add-request.factory";
import DriverPlanCandidatureAddRequest, {
  DriverPlanCandidatureAddRequestBody,
} from "./add/driver-plan-candidature-add.request";
import DriverPlanCandidatureAddResponse from "./add/driver-plan-candidature-add.response";
import driverPlanCandidaturePreviewRequestFactory from "./preview/driver-plan-candidature-preview-request.factory";
import DriverPlanCandidaturePreviewRequest, {
  DriverPlanCandidaturePreviewRequestQueryParams,
} from "./preview/driver-plan-candidature-preview.request";
import DriverPlanCandidaturePreviewResponse from "./preview/driver-plan-candidature-preview.response";

const driverPlanCandidatureApiService = () => {
  const add = (
    request: DriverPlanCandidatureAddRequest,
    abortSignal: AbortSignal
  ): Promise<DriverPlanCandidatureAddResponse> => {
    const url = `/candidatures`;

    const body: DriverPlanCandidatureAddRequestBody =
      driverPlanCandidatureAddRequestFactory.createBody(request);

    return railyApiService().post<DriverPlanCandidatureAddResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const addByOrderId = (
    request: DriverPlanCandidatureAddByOrderIdRequest,
    abortSignal: AbortSignal
  ): Promise<DriverPlanCandidatureAddByOrderIdResponse> => {
    const url = `/candidatures/for-cargo-order`;

    const body: DriverPlanCandidatureAddByOrderIdRequestBody =
      driverPlanCandidatureAddByOrderIdRequestFactory.createBody(request);

    return railyApiService().post<DriverPlanCandidatureAddByOrderIdResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const getPreview = (
    request: DriverPlanCandidaturePreviewRequest,
    abortSignal: AbortSignal
  ): Promise<DriverPlanCandidaturePreviewResponse> => {
    const url = `/candidatures/staging/${request.orderId}`;

    const queryParams: DriverPlanCandidaturePreviewRequestQueryParams =
      driverPlanCandidaturePreviewRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    add,
    addByOrderId,
    getPreview,
  };
};

export default driverPlanCandidatureApiService;
