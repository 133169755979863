import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import driverEditFormHelper from "../../common/driver-edit-form.helper";
import DriverEditByTaxiUserFormData from "./types/driver-edit-by-taxi-user-form-data";
import DriverEditByTaxiUserFormValidationResults from "./types/driver-edit-by-taxi-user-form-validation-results";

const getDefaultUserFormData = (): DriverEditByTaxiUserFormData => {
  return {
    firstName: "",
    lastName: "",
    mobile: phoneNumberInputHelper.getEmptyValue(),
    alternativeMobile: phoneNumberInputHelper.getEmptyValue(),
    birthDate: null,
    birthPlace: "",
    personalIdNumber: "",
    citizenship: null,
    languages: [],
    yearsOfExperience: null,
    formOfEmployment: null,
    fleetPartner: null,
    addresses: [],
  };
};

const getDefaultUserFormValidationResults =
  (): DriverEditByTaxiUserFormValidationResults => {
    return {
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
      alternativeMobile: formValidationService.defaultValidationResult,
      birthDate: formValidationService.defaultValidationResult,
      birthPlace: formValidationService.defaultValidationResult,
      personalIdNumber: formValidationService.defaultValidationResult,
      citizenship: formValidationService.defaultValidationResult,
      languages: formValidationService.defaultValidationResult,
      yearsOfExperience: formValidationService.defaultValidationResult,
      formOfEmployment: formValidationService.defaultValidationResult,
      fleetPartner: formValidationService.defaultValidationResult,
      addresses: formValidationService.defaultValidationResult,
    };
  };

const driverEditByRailyFormHelper = {
  getCitizenshipSelectOptions: driverEditFormHelper.getCitizenshipSelectOptions,
  getFormOfEmploymentSelectOptions:
    driverEditFormHelper.getFormOfEmploymentSelectOptions,
  getLanguageSelectOptions: driverEditFormHelper.getLanguageSelectOptions,
  getVehicleOwnershipSelectOptions:
    driverEditFormHelper.getVehicleOwnershipSelectOptions,
  getDefaultAccountFormData: driverEditFormHelper.getDefaultAccountFormData,
  getDefaultAccountFormValidationResults:
    driverEditFormHelper.getDefaultAccountFormValidationResults,
  getDefaultUserFormData,
  getDefaultUserFormValidationResults,
  getDefaultCompanyFormData: driverEditFormHelper.getDefaultCompanyFormData,
  getDefaultCompanyFormValidationResults:
    driverEditFormHelper.getDefaultCompanyFormValidationResults,
  getDefaultVehicleFormData: driverEditFormHelper.getDefaultVehicleFormData,
  getDefaultVehicleFormValidationResults:
    driverEditFormHelper.getDefaultVehicleFormValidationResults,
};

export default driverEditByRailyFormHelper;
