import { FC } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import formValidationHelper from "../../../../../common/utils/validation/form-validation.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../common/context/sign-up-driver.context";
import SignUpDriverCompanyAddressComponent from "./address/sign-up-driver-company-address.component";

type SignUpDriverCompanyProps = {};

const SignUpDriverCompanyComponent: FC<SignUpDriverCompanyProps> = () => {
  const companyDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().companyDataForm;

  const {
    companyData,
    setCompanyDataName,
    setCompanyDataCompanyId,
    setCompanyDataTaxNumber,
    setCompanyDataNationalCourtRegisterNumber,
    onCompanyStepSubmitButtonClick,
    companyDataValidationResults,
    validateCompanyDataName,
    validateCompanyDataCompanyId,
    validateCompanyDataTaxNumber,
    validateCompanyDataNationalCourtRegisterNumber,
  } = useSignUpDriverContext();

  const isSubmitButtonEnabled = formValidationHelper.checkThatFormHasErrors(
    companyDataValidationResults
  );

  return (
    <>
      <h1 className="sign_up_driver__step_title">
        {companyDataFormTranslations.title}
      </h1>
      <Row>
        <Column lg={6} withPaddings>
          <CardComponent classNames={{ root: "h-100" }}>
            <Row>
              <Column>
                <FormFieldComponent
                  label={companyDataFormTranslations.nameLabel}
                  isRequired
                  classNames={{ root: "mt-0" }}
                  errorMessage={companyDataValidationResults.name.errorMessage}
                >
                  <InputComponent
                    placeholder={companyDataFormTranslations.namePlaceholder}
                    value={companyData.name}
                    onChange={setCompanyDataName}
                    onBlur={validateCompanyDataName}
                    hasError={!!companyDataValidationResults.name.errorMessage}
                    idForTesting="company-data-company-name-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column>
                <FormFieldComponent
                  label={companyDataFormTranslations.taxNumberLabel}
                  isRequired
                  errorMessage={
                    companyDataValidationResults.taxNumber.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={
                      companyDataFormTranslations.taxNumberPlaceholder
                    }
                    value={companyData.taxNumber}
                    onChange={setCompanyDataTaxNumber}
                    onBlur={validateCompanyDataTaxNumber}
                    hasError={
                      !!companyDataValidationResults.taxNumber.errorMessage
                    }
                    idForTesting="company-data-tax-number-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column>
                <FormFieldComponent
                  label={companyDataFormTranslations.companyIdLabel}
                  errorMessage={
                    companyDataValidationResults.companyId.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={
                      companyDataFormTranslations.companyIdPlaceholder
                    }
                    value={companyData.companyId}
                    onChange={setCompanyDataCompanyId}
                    onBlur={validateCompanyDataCompanyId}
                    hasError={
                      !!companyDataValidationResults.companyId.errorMessage
                    }
                    idForTesting="company-data-company-id-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column>
                <FormFieldComponent
                  label={companyDataFormTranslations.nationalCourtRegisterLabel}
                  errorMessage={
                    companyDataValidationResults.nationalCourtRegisterNumber
                      .errorMessage
                  }
                >
                  <InputComponent
                    placeholder={
                      companyDataFormTranslations.nationalCourtRegisterPlaceholder
                    }
                    value={companyData.nationalCourtRegisterNumber}
                    onChange={setCompanyDataNationalCourtRegisterNumber}
                    onBlur={validateCompanyDataNationalCourtRegisterNumber}
                    hasError={
                      !!companyDataValidationResults.nationalCourtRegisterNumber
                        .errorMessage
                    }
                    idForTesting="company-data-national-court-register-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
        </Column>
        <Column lg={6} withPaddings>
          <SignUpDriverCompanyAddressComponent />
        </Column>
      </Row>
      <div className="sign_up_driver__next_button_wrapper">
        <ButtonComponent
          onClick={onCompanyStepSubmitButtonClick}
          type="primary"
          isDisabled={!isSubmitButtonEnabled}
          idForTesting="company-data-submit-button"
        >
          {companyDataFormTranslations.submitButtonText}
        </ButtonComponent>
      </div>
    </>
  );
};

export default SignUpDriverCompanyComponent;
