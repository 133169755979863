import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type OperatorListingUserPermissionOption =
  | "hasAccessToDeleteUser"
  | "hasVisibleAddOperatorButton";

export type OperatorListingUserPermissionsDefinition =
  UserPermissionsDefinition<OperatorListingUserPermissionOption>;

export type OperatorListingUserPermissions =
  UserPermissions<OperatorListingUserPermissionOption>;

const operatorListingUserPermissionDefinition: OperatorListingUserPermissionsDefinition =
  {
    hasAccessToDeleteUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasVisibleAddOperatorButton: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default operatorListingUserPermissionDefinition;
