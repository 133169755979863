import { FC } from "react";
import TaxiUnsettledTransferedOrderListingFilter from "../../types/taxi-unsettled-transfered-order-listing-filter";
import taxiUnsettledTransferedOrderListingFiltersBadgeListHelper from "./taxi-unsettled-transfered-order-listing-filters-badge-list.helper";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type TaxiUnsettledTransferedOrderListingFiltersListProps = {
  filters: TaxiUnsettledTransferedOrderListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const TaxiUnsettledTransferedOrderListingFiltersBadgeListComponent: FC<
  TaxiUnsettledTransferedOrderListingFiltersListProps
> = (props) => {
  const badges =
    taxiUnsettledTransferedOrderListingFiltersBadgeListHelper.getBadges(
      props.filters
    );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default TaxiUnsettledTransferedOrderListingFiltersBadgeListComponent;
