import { SearchAddressResponseItem } from "../../../../common/utils/search-address/search-address.response";
import { CargoAddressAddRequestAddressType } from "../../../cargo/address/add/common/api/cargo-address-add-add-address.request";
import CargoAddressType from "../../../cargo/address/common/types/cargo-address-type";
import BillingAddressAddAddress from "./types/billing-address-add-address";
import BillingAddressAddAddressSelectOption from "./types/billing-address-add-address-select-option";

const createAddress = (
  searchAddressResponseItem: SearchAddressResponseItem
): BillingAddressAddAddress => {
  const address: BillingAddressAddAddress = {
    displayName: searchAddressResponseItem.display_name,
    apartmentNumber: "",
    country: searchAddressResponseItem.address.country,
    countryCode: searchAddressResponseItem.address.country_code,
    houseNumber: searchAddressResponseItem.address.house_number ?? null,
    latitude: Number(searchAddressResponseItem.lat),
    longitude: Number(searchAddressResponseItem.lon),
    street: searchAddressResponseItem.address.road ?? null,
    town:
      searchAddressResponseItem.address.city ??
      searchAddressResponseItem.address.town ??
      searchAddressResponseItem.address.village ??
      null,
    zipCode: searchAddressResponseItem.address.postcode ?? null,
  };

  return address;
};

const createAddressSelectOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): BillingAddressAddAddressSelectOption => {
  const address = createAddress(searchAddressResponseItem);

  return {
    label: searchAddressResponseItem.display_name,
    value: address,
  };
};

const createAddRequestAddressType = (
  addressType: CargoAddressType
): CargoAddressAddRequestAddressType => {
  switch (addressType) {
    case CargoAddressType.HOTEL:
      return CargoAddressAddRequestAddressType.HOTEL;
    case CargoAddressType.MEETING_POINT:
      return CargoAddressAddRequestAddressType.MEETING_POINT;
    case CargoAddressType.STATION:
      return CargoAddressAddRequestAddressType.STATION;
  }
};

const createAddressSelectOptions = (
  searchAddressResponseItems: SearchAddressResponseItem[]
): BillingAddressAddAddressSelectOption[] => {
  return searchAddressResponseItems.map(createAddressSelectOption);
};

const billingAddressAddFactory = {
  createAddress,
  createAddressSelectOptions,
  createAddRequestAddressType,
};

export default billingAddressAddFactory;
