import Joi from "joi";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";

const validatePlannedStartDate = (
  value: Date | null,
  minDate: Date
): FormValidationResult => {
  let validationSchema = Joi.date().not(null).min(minDate).required();

  return formValidationService.validate(value, validationSchema);
};

const orderDetailsManageDriverAssignmentCollisionFormValidationService = {
  validatePlannedStartDate,
};

export default orderDetailsManageDriverAssignmentCollisionFormValidationService;
