import { FC } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import ModalComponent from "../../../../common/components/modal/modal.component";
import htmlParser from "../../../../common/utils/html-parser/html-parser.service";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderDetailsOrderCancelConfirmationHelper from "./order-details-order-cancel-confirmation.helper";

type OrderDetailsOrderCancelConfirmationModalComponentProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cargoCompanyOrderId: number | undefined;
};

const OrderDetailsOrderCancelConfirmationModalComponent: FC<
  OrderDetailsOrderCancelConfirmationModalComponentProps
> = (props) => {
  if (props.cargoCompanyOrderId === undefined) {
    return null;
  }

  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrder
      .confirmationModal;

  const contentText =
    orderDetailsOrderCancelConfirmationHelper.getContentMessageText(
      props.cargoCompanyOrderId
    );

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          type="danger"
          onClick={props.onConfirm}
          title={translations.confirmationButtonTitle}
        >
          {translations.confirmationButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.rejectButtonTitle}
        >
          {translations.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      {htmlParser.parse(contentText)}
    </ModalComponent>
  );
};

export default OrderDetailsOrderCancelConfirmationModalComponent;
