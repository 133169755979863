import railyApiService from "../../raily-api.service";
import CargoCompanyDispatchAddRequest, {
  CargoCompanyDispatchAddRequestBody,
} from "./add/cargo-company-dispatch-add-request";
import cargoCompanyDispatchAddRequestFactory from "./add/cargo-company-dispatch-add-request.factory";
import CargoCompanyDispatchAddResponse from "./add/cargo-company-dispatch-add-response";
import CargoCompanyDispatchUpdateRequest, {
  CargoCompanyDispatchUpdateRequestBody,
} from "./update/cargo-company-dispatch-update-request";
import cargoCompanyDispatchUpadteRequestFactory from "./update/cargo-company-dispatch-update-request.factory";
import CargoCompanyDispatchUpdateResponse from "./update/cargo-company-dispatch-update-response";
import cargoCompanyDispatchListRequestFactory from "./list/cargo-company-dispatch-list-request.factory";
import CargoCompanyDispatchListRequest, {
  CargoCompanyDispatchListRequestQueryParams,
} from "./list/cargo-company-dispatch-list.request";
import CargoCompanyDispatchListResponse from "./list/cargo-company-dispatch-list.response";
import CargoCompanyDispatchDetailsRequest from "./details/cargo-company-dispatch-details.request";
import CargoCompanyDispatchDetailsResponse from "./details/cargo-company-dispatch-details.response";
import CargoCompanyDispatchMultiListRequest from "./multi-list/cargo-company-dispatch-multi-list.request";
import CargoCompanyDispatchMultiListResponse from "./multi-list/cargo-company-dispatch-multi-list.response";
import cargoCompanyDispatchMultiListRequestFactory from "./multi-list/cargo-company-dispatch-multi-list-request.factory";

const cargoCompanyDispatchApiService = () => {
  const getList = async (
    request: CargoCompanyDispatchListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyDispatchListResponse> => {
    const url = `/cargo-companies/${request.cargoCompanyId}/dispatches`;

    const queryParams: CargoCompanyDispatchListRequestQueryParams =
      cargoCompanyDispatchListRequestFactory.createQueryParams(request);

    return railyApiService().get<CargoCompanyDispatchListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getMultiList = async (
    request: CargoCompanyDispatchMultiListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyDispatchMultiListResponse> => {
    const url = `/cargo-companies/dispatches`;

    const queryParams: CargoCompanyDispatchListRequestQueryParams =
      cargoCompanyDispatchMultiListRequestFactory.createQueryParams(request);

    return railyApiService().get<CargoCompanyDispatchMultiListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getDetails = async (
    request: CargoCompanyDispatchDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyDispatchDetailsResponse> => {
    const url = `/cargo-companies/${request.cargoCompanyId}/dispatches/${request.dispatchId}`;

    return railyApiService().get<CargoCompanyDispatchDetailsResponse>({
      url,
      abortSignal,
    });
  };

  const add = (
    request: CargoCompanyDispatchAddRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyDispatchAddResponse> => {
    const url = `/cargo-companies/${request.companyId}/dispatches`;

    const body: CargoCompanyDispatchAddRequestBody =
      cargoCompanyDispatchAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const update = (
    request: CargoCompanyDispatchUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyDispatchUpdateResponse> => {
    const url = `/cargo-companies/${request.companyId}/dispatches/${request.dispatchId}`;

    const body: CargoCompanyDispatchUpdateRequestBody =
      cargoCompanyDispatchUpadteRequestFactory.createBody(request);

    return railyApiService().put({
      url,
      abortSignal,
      body,
    });
  };

  return {
    getList,
    getMultiList,
    add,
    update,
    getDetails,
  };
};

export default cargoCompanyDispatchApiService;
