import DriverPlanCandidatureAddByOrderIdRequest from "../../api/raily/driver-plan-candidature/add-by-order-id/driver-plan-candidature-add-by-order-id.request";
import {
  DriverPlanCandidatureAddByOrderIdNotAcceptableResponse,
  DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateFitsWithNotEnoughTimeError,
  DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType,
} from "../../api/raily/driver-plan-candidature/add-by-order-id/driver-plan-candidature-add-by-order-id.response";
import DriverPlanCandidatureAddRequest from "../../api/raily/driver-plan-candidature/add/driver-plan-candidature-add.request";
import DriverPlanCandidatureAddResponse, {
  DriverPlanCandidatureAddNotAcceptableResponse,
  DriverPlanCandidatureAddNotAcceptableResponseErrorType,
} from "../../api/raily/driver-plan-candidature/add/driver-plan-candidature-add.response";
import driverPlanCandidatureApiService from "../../api/raily/driver-plan-candidature/driver-plan-candidature-api.service";
import DriverPlanCandidaturePreviewRequest from "../../api/raily/driver-plan-candidature/preview/driver-plan-candidature-preview.request";
import {
  DriverPlanCandidaturePreviewNotAcceptableResponse,
  DriverPlanCandidaturePreviewNotAcceptableResponseErrorType,
  DriverPlanCandidaturePreviewResponseItem,
} from "../../api/raily/driver-plan-candidature/preview/driver-plan-candidature-preview.response";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import notificationService from "../../utils/notification/notification.service";
import DriverPlanCandidatureAddByOrderIdError from "./candidature-add-by-order-id/driver-plan-candidature-add-by-order-id-error";
import driverPlanCandidatureAddByOrderIdErrorFactory from "./candidature-add-by-order-id/driver-plan-candidature-add-by-order-id-error.factory";
import DriverPlanCandidatureAddByOrderIdParams from "./candidature-add-by-order-id/driver-plan-candidature-add-by-order-id-params";
import driverPlanCandidatureAddByOrderIdRequestFactory from "./candidature-add-by-order-id/driver-plan-candidature-add-by-order-id-request.factory";
import DriverPlanCandidatureAddError from "./candidature-add/driver-plan-candidatue-add-error";
import driverPlanCandidatureAddErrorFactory from "./candidature-add/driver-plan-candidature-add-error.factory";
import DriverPlanCandidatureAddParams from "./candidature-add/driver-plan-candidature-add-params";
import driverPlanCandidatureAddRequestFactory from "./candidature-add/driver-plan-candidature-add-request.factory";
import DriverPlanDeleteOrderError from "./delete-order/driver-plan-delete-order-error";
import driverPlanDeleteOrderErrorFactory from "./delete-order/driver-plan-delete-order-error.factory";
import DriverPlanDeleteOrderParams from "./delete-order/driver-plan-delete-order-params";
import driverPlanDeleteOrderRequestFactory from "./delete-order/driver-plan-delete-order-request.factory";
import DriverPlanCandidaturePreview from "./preview/driver-plan-candidature-preview";
import DriverPlanCandidaturePreviewError from "./preview/driver-plan-candidature-preview-error";
import driverPlanCandidaturePreviewErrorFactory from "./preview/driver-plan-candidature-preview-error.factory";
import DriverPlanCandidaturePreviewLoadParams from "./preview/driver-plan-candidature-preview-load-params";
import driverPlanCandidaturePreviewRequestFactory from "./preview/driver-plan-candidature-preview-request.factory";
import driverPlanCandidaturePreviewFactory from "./preview/driver-plan-candidature-preview.factory";

const handleDeleteOrderError = (
  error: HttpError | DriverPlanDeleteOrderError
) => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleCandidatureAddError = (
  error: HttpError | DriverPlanCandidatureAddError
) => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createErrorsFromNotAcceptableResponse = (
  response: DriverPlanCandidatureAddNotAcceptableResponse
): DriverPlanDeleteOrderError => {
  switch (response.data.errorType) {
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION:
      return driverPlanDeleteOrderErrorFactory.createTaxiDriverAssocationNotExistsError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND:
      return driverPlanDeleteOrderErrorFactory.createOrderNotExistsError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS:
      return driverPlanDeleteOrderErrorFactory.createOrderInProgressError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT:
      return driverPlanDeleteOrderErrorFactory.createTaxiDriverContractNotValidError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT:
      return driverPlanDeleteOrderErrorFactory.createTaxiRailyContractNotValidError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createPreviewErrorsFromNotAcceptableResponse = (
  response: DriverPlanCandidaturePreviewNotAcceptableResponse
): DriverPlanCandidaturePreviewError => {
  switch (response.data.errorType) {
    case DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_COLLISION:
      return driverPlanCandidaturePreviewErrorFactory.createPlanEntryCandidateCollisionError();
    case DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION:
      return driverPlanCandidaturePreviewErrorFactory.createTaxiDriverAssocationNotExistsError();
    case DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND:
      return driverPlanCandidaturePreviewErrorFactory.createOrderNotExistsError();
    case DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS:
      return driverPlanCandidaturePreviewErrorFactory.createOrderInProgressError();
    case DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT:
      return driverPlanCandidaturePreviewErrorFactory.createTaxiDriverContractNotValidError();
    case DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT:
      return driverPlanCandidaturePreviewErrorFactory.createTaxiRailyContractNotValidError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createCandidatureAddErrorsFromNotAcceptableResponse = (
  response: DriverPlanCandidatureAddNotAcceptableResponse
): any => {
  switch (response.data.errorType) {
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_COLLISION:
      return driverPlanCandidatureAddErrorFactory.createPlanEntryCandidateCollisionError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION:
      return driverPlanCandidatureAddErrorFactory.createTaxiDriverAssocationNotExistsError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND:
      return driverPlanCandidatureAddErrorFactory.createOrderNotExistsError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS:
      return driverPlanCandidatureAddErrorFactory.createOrderInProgressError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT:
      return driverPlanCandidatureAddErrorFactory.createTaxiDriverContractNotValidError();
    case DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT:
      return driverPlanCandidatureAddErrorFactory.createTaxiRailyContractNotValidError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const deleteOrder = async (
  params: DriverPlanDeleteOrderParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DriverPlanCandidatureAddRequest =
    driverPlanDeleteOrderRequestFactory.create(params);
  try {
    const response = await driverPlanCandidatureApiService().add(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createErrorsFromNotAcceptableResponse(
        response as DriverPlanCandidatureAddNotAcceptableResponse
      );
    }

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DriverPlanDeleteOrderError;

    throw handleDeleteOrderError(error);
  }
};

const handlePreviewError = (
  error: HttpError | DriverPlanCandidaturePreviewError
) => {
  if (!httpHelper.checkIsHttpError(error)) {
    notificationService.error(error.message);
    return;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getPreview = async (
  params: DriverPlanCandidaturePreviewLoadParams,
  abortSignal: AbortSignal
): Promise<DriverPlanCandidaturePreview> => {
  const request: DriverPlanCandidaturePreviewRequest =
    driverPlanCandidaturePreviewRequestFactory.create(params);
  try {
    const response = await driverPlanCandidatureApiService().getPreview(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createPreviewErrorsFromNotAcceptableResponse(
        response as DriverPlanCandidaturePreviewNotAcceptableResponse
      );
    }

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return driverPlanCandidaturePreviewFactory.create(
      response.data as DriverPlanCandidaturePreviewResponseItem
    );
  } catch (_error) {
    const error = _error as HttpError | DriverPlanCandidaturePreviewError;
    throw handlePreviewError(error);
  }
};

const candidatureAdd = async (
  params: DriverPlanCandidatureAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DriverPlanCandidatureAddRequest =
    driverPlanCandidatureAddRequestFactory.create(params);

  try {
    const response = await driverPlanCandidatureApiService().add(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createCandidatureAddErrorsFromNotAcceptableResponse(
        response as DriverPlanCandidatureAddNotAcceptableResponse
      );
    }

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DriverPlanCandidatureAddError;

    throw handleCandidatureAddError(error);
  }
};

const handleCandidatureAddByOrderIdError = (
  error: HttpError | DriverPlanCandidatureAddByOrderIdError
) => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createCandidatureAddByOrderIdErrorsFromNotAcceptableResponse = (
  response: DriverPlanCandidatureAddByOrderIdNotAcceptableResponse
): any => {
  switch (response.data.errorType) {
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.DRIVER_HAS_CANDIDATURE:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createDriverHasCandidatureError();
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_COLLISION:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createPlanEntryCandidateCollisionError();
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createTaxiDriverAssocationNotExistsError();
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createOrderNotExistsError();
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createOrderInProgressError();
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createTaxiDriverContractNotValidError();
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createTaxiRailyContractNotValidError();
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createPlanEntryCandidateStartTimeExpiredError();
    case DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME:
      return driverPlanCandidatureAddByOrderIdErrorFactory.createPlanCandidateFitsWithNotEnoughTimeError(
        response.data
      );

    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const candidatureAddByOrderId = async (
  params: DriverPlanCandidatureAddByOrderIdParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DriverPlanCandidatureAddByOrderIdRequest =
    driverPlanCandidatureAddByOrderIdRequestFactory.create(params);

  try {
    const response = await driverPlanCandidatureApiService().addByOrderId(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createCandidatureAddByOrderIdErrorsFromNotAcceptableResponse(
        response as DriverPlanCandidatureAddByOrderIdNotAcceptableResponse
      );
    }

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DriverPlanCandidatureAddByOrderIdError;

    throw handleCandidatureAddByOrderIdError(error);
  }
};

const driverPlanService = {
  deleteOrder,
  getPreview,
  candidatureAdd,
  candidatureAddByOrderId,
};

export default driverPlanService;
