import { FC, useState } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import ModalComponent from "../../../../common/components/modal/modal.component";
import htmlParser from "../../../../common/utils/html-parser/html-parser.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userService from "../../../../common/services/user/user.service";
import useAbort from "../../../../common/hooks/use-abort";
import userRestoreHelper from "./types/user-restore.helper";
import UserRestoreUserData from "./types/user-restore-user-data";

type UserRestoreProps = {
  isOpen: boolean;
  userData: UserRestoreUserData | null;
  onClose: () => void;
  onSuccess: () => void;
};

const UserRestoreComponent: FC<UserRestoreProps> = (props) => {
  const [isRestorePending, setIsRestorePending] = useState(false);
  const restoreUserAbort = useAbort();

  if (!props.userData) return null;

  const translations = userTranslationsHelper.getUserRestoreTranslations();
  const contentText = userRestoreHelper.getContentText(props.userData);

  const onRestoreUserSuccess = () => {
    const notificationText = userRestoreHelper.getSuccessNotificationLabel(
      props.userData!
    );
    notificationService.success(notificationText);

    props.onSuccess();
  };

  const onRestoreUserFailure = () => {
    const notificationText = userRestoreHelper.getFailureNotificationLabel(
      props.userData!
    );
    notificationService.error(notificationText);
  };

  const restoreUser = async () => {
    setIsRestorePending(true);

    try {
      await userService.restore(
        { userUuid: props.userData!.uuid },
        restoreUserAbort.signal
      );

      onRestoreUserSuccess();
    } catch {
      onRestoreUserFailure();
    } finally {
      setIsRestorePending(false);
    }
  };

  const onSubmitButtonClick = () => {
    setIsRestorePending(true);
    restoreUser();
  };

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={{ title: translations.headingLabel }}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="danger"
          title={translations.submitButtonTitle}
          idForTesting="submit-button"
          isLoading={isRestorePending}
        >
          {translations.submitButtonLabel}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.rejectButtonTitle}
          idForTesting="reject-button"
        >
          {translations.rejectButtonLabel}
        </ButtonComponent>,
      ]}
    >
      {htmlParser.parse(contentText)}
    </ModalComponent>
  );
};

export default UserRestoreComponent;
