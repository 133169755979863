import StatusLegendStatuses from "../../../../common/components/status-legend/types/status-legend-statuses";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import DelegationListingDelegationType from "./types/delegation-listing-delegation-type";

const getStatusOptions = () => [
  {
    status: StatusLegendStatuses.UNDOWNLOADED,
  },
  {
    status: StatusLegendStatuses.DOWNLOADED,
  },
  {
    status: StatusLegendStatuses.DELETED,
  },
];

const getTypeText = (type: DelegationListingDelegationType): string => {
  const translations =
    delegationTranslationsHelper.getDelegationListingTranslations().type;

  const options: {
    text: string;
    type: DelegationListingDelegationType;
  }[] = [
    {
      text: translations.manual,
      type: DelegationListingDelegationType.MANUAL,
    },
    {
      text: translations.automatic,
      type: DelegationListingDelegationType.AUTO,
    },
  ];

  return options.find((option) => option.type == type)?.text ?? "";
};

const DelegationListingHelper = {
  getStatusOptions,
  getTypeText,
};

export default DelegationListingHelper;
