import OperatorListingFilter, {
  OperatorListingEmailFilter,
  OperatorListingFirstNameFilter,
  OperatorListingLastNameFilter,
  OperatorListingStatusFilter,
  OperatorListingUsernameFilter,
} from "./types/operator-listing-filter";
import OperatorListingFilterType from "./types/operator-listing-filter-type";
import OperatorListingSortKey from "./types/operator-listing-sort-key";
import OperatorListLoadParams, {
  OperatorListLoadParamsOrder,
} from "../../../../../common/services/operator/list/operator-list-load-params";

const createOrder = (
  sortKey: OperatorListingSortKey | null
): OperatorListLoadParamsOrder | undefined => {
  const definition: Record<
    OperatorListingSortKey,
    OperatorListLoadParamsOrder
  > = {
    [OperatorListingSortKey.FIRST_NAME_ASC]:
      OperatorListLoadParamsOrder.FIRST_NAME_ASC,
    [OperatorListingSortKey.FIRST_NAME_DESC]:
      OperatorListLoadParamsOrder.FIRST_NAME_DESC,
    [OperatorListingSortKey.LAST_NAME_ASC]:
      OperatorListLoadParamsOrder.LAST_NAME_ASC,
    [OperatorListingSortKey.LAST_NAME_DESC]:
      OperatorListLoadParamsOrder.LAST_NAME_DESC,
    [OperatorListingSortKey.USERNAME_ASC]:
      OperatorListLoadParamsOrder.USERNAME_ASC,
    [OperatorListingSortKey.USERNAME_DESC]:
      OperatorListLoadParamsOrder.USERNAME_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: OperatorListingFilter[],
  sortKey: OperatorListingSortKey | null
): OperatorListLoadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === OperatorListingFilterType.STATUS
  )?.value as OperatorListingStatusFilter["value"] | undefined;

  return {
    page,
    pageSize,
    firstName: filters.find(
      (filter) => filter.type === OperatorListingFilterType.FIRST_NAME
    )?.value as OperatorListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === OperatorListingFilterType.LAST_NAME
    )?.value as OperatorListingLastNameFilter["value"] | undefined,
    email: filters.find(
      (filter) => filter.type === OperatorListingFilterType.EMAIL
    )?.value as OperatorListingEmailFilter["value"] | undefined,
    username: filters.find(
      (filter) => filter.type === OperatorListingFilterType.USERNAME
    )?.value as OperatorListingUsernameFilter["value"] | undefined,
    order: createOrder(sortKey),
    status: statusFilter,
  };
};

const OperatorListingLoadParamsFactory = {
  create,
};

export default OperatorListingLoadParamsFactory;
