import OrderAddPassenger from "../../types/order-add-passenger";

const getPassengerTitle = (passenger: OrderAddPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const getPassengerLabel = (passenger: OrderAddPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const orderAddPassengerHelper = {
  getPassengerTitle,
  getPassengerLabel,
};

export default orderAddPassengerHelper;
