import appConfig from "../../../app.config";
import httpService from "../http/http-service";
import SearchRoadRoutingRequest from "./search-road-routing.request";
import SearchRoadRoutingResponse, {
  SearchRoadRoutingResponseRoute,
} from "./search-road-routing.response";

const searchRoadRoutingHttpService = () => {
  const baseUrl = appConfig.routingServiceBaseApiUrl;
  const basicHeaders: HeadersInit = {
    "Content-Type": "application/json",
  };

  return httpService(baseUrl, basicHeaders);
};

const search = (
  request: SearchRoadRoutingRequest
): Promise<SearchRoadRoutingResponse> => {
  const baseRequestPath = `/driving`;
  const serializedWaypointsCoordinates = request.waypointCoordinates
    .map((coordinate) => `${coordinate.longitude},${coordinate.latitude}`)
    .join(";");

  const requestPath = `${baseRequestPath}/${serializedWaypointsCoordinates}`;

  const params: any = {
    steps: true,
    geometries: `geojson`,
    overview: `full`,
    continue_straight: "true",
  };

  if (request.excludeHighway) {
    params.exclude = ["motorway"];
  }

  return searchRoadRoutingHttpService()
    .get(requestPath, params)
    .then((_response) => {
      const response: SearchRoadRoutingResponse =
        _response as SearchRoadRoutingResponse;

      const modifiedRoutes: SearchRoadRoutingResponseRoute[] = (
        _response as SearchRoadRoutingResponse
      ).routes.map((route) => {
        return {
          ...route,
          legs: route.legs.map((leg, index) => {
            return {
              ...leg,
              start: request.waypointCoordinates[index],
              end: request.waypointCoordinates[index + 1],
            };
          }),
        };
      });

      response.routes = modifiedRoutes;
      return response;
    });
};

const searchRoadRoutingService = {
  search,
};

export default searchRoadRoutingService;
