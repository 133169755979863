enum UserLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export default UserLanguage;
