import appConfig from "../../../../../../app.config";
import SignUpDriverRequest from "./sign-up-driver.request";
import SignUpDriverResponse from "./sign-up-driver.response";

const signUp = (
  request: SignUpDriverRequest,
  token: string
): Promise<SignUpDriverResponse> => {
  const path = `/drivers`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const signUpDriverApiService = {
  signUp,
};

export default signUpDriverApiService;
