enum OrderAbandonedListingFilterType {
  CARGO_COMPANY_INTERNAL_ORDER_ID = "CARGO_COMPANY_INTERNAL_ORDER_ID",
  CARGO_COMPANY_EXTERNAL_ORDER_ID = "CARGO_EXTERNAL_ORDER_ID",
  ORDER_START_FROM = "ORDER_START_FROM",
  ORDER_START_TO = "ORDER_START_TO",
  CLIENT = "CLIENT",
  DISPATCH = "DISPATCH",
  PASSENGER = "PASSENGER",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  ROUTE_DESTINATION_ADDRESS = "ROUTE_DESTINATION_ADDRESS",
  ROUTE_INTERMEDIATE_ADDRESS = "ROUTE_INTERMEDIATE_ADDRESS",
  ROUTE_PICKUP_ADDRESS = "ROUTE_PICKUP_ADDRESS",
}

export default OrderAbandonedListingFilterType;
