import DispatcherListRequest, {
  DispatcherListRequestStatusCriteria,
  DispatcherListRequestOrder,
} from "../../../api/raily/dispatcher/list/dispatcher-list.request";
import UserStatus from "../../../types/user-status";
import paginationService from "../../../utils/pagination/pagination.service";
import DispatcherListLoadParams, {
  DispatcherListLoadParamsOrder,
} from "./dispatcher-list-load-params";

const createStatusCriteria = (
  status: UserStatus | undefined
): DispatcherListRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return DispatcherListRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return DispatcherListRequestStatusCriteria.DELETED;
    default:
      return DispatcherListRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: DispatcherListLoadParamsOrder | undefined
): DispatcherListRequestOrder | undefined => {
  switch (status) {
    case DispatcherListLoadParamsOrder.FIRST_NAME_ASC:
      return DispatcherListRequestOrder.FIRST_NAME_ASC;
    case DispatcherListLoadParamsOrder.FIRST_NAME_DESC:
      return DispatcherListRequestOrder.FIRST_NAME_DESC;
    case DispatcherListLoadParamsOrder.LAST_NAME_ASC:
      return DispatcherListRequestOrder.LAST_NAME_ASC;
    case DispatcherListLoadParamsOrder.LAST_NAME_DESC:
      return DispatcherListRequestOrder.LAST_NAME_DESC;
    case DispatcherListLoadParamsOrder.USERNAME_ASC:
      return DispatcherListRequestOrder.USERNAME_ASC;
    case DispatcherListLoadParamsOrder.USERNAME_DESC:
      return DispatcherListRequestOrder.USERNAME_DESC;
    default:
      return undefined;
  }
};

const create = (params: DispatcherListLoadParams): DispatcherListRequest => {
  return {
    cargoCompany: params.cargoCompany,
    cargoCompanyId: params.cargoCompanyId,
    dispatch: params.dispatch,
    dispatchId: params.dispatchId,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    offset:
      params.page && params.pageSize
        ? paginationService.calculateOffset(params.page, params.pageSize)
        : undefined,
    order: createOrder(params.order),
    statusCriteria: createStatusCriteria(params.status),
    username: params.username,
    creationEnd: params.creationEnd,
    creationStart: params.creationStart,
    limit: params.pageSize,
  };
};

const dispatcherListRequestFactory = { create };

export default dispatcherListRequestFactory;
