import useAsyncData from "../../../hooks/use-async-data";
import routeService from "../route.service";
import RouteRoadRoute from "./route-road-route";
import RouteRoadRouteLoadParams from "./route-road-route-load-params";

const useRouteRoadRoutes = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<RouteRoadRoute[][]>([]);

  const load = async (params: RouteRoadRouteLoadParams[]): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const routeRoadRoutes = await Promise.all(
        params.map(async (param) => {
          return await routeService.getRoute(param);
        })
      );

      setData(routeRoadRoutes);
      setIsLoading(false);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useRouteRoadRoutes;
