import isEqual from "lodash/isEqual";
import mapMarkerIconFactory from "../../../../../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../../../../common/components/map/types/map-route";
import { SearchRoadRoutingResponseRouteGeometryCoordinate } from "../../../../../../../../common/utils/search-road-route/search-road-routing.response";
import MileageUpdateSummaryRouteWaypoint from "../types/mileage-update-summary-route-waypoint";

const createMapMarker = (
  waypoint: MileageUpdateSummaryRouteWaypoint,
  content?: string
) => {
  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: content,
  });

  const newMapMarker: MapMarker = {
    coordinate: {
      latitude: waypoint.place.latitude,
      longitude: waypoint.place.longitude,
    },
    title: waypoint.place.displayName,
    tooltip: waypoint.place.displayName,
    icon: markerIcon,
  };

  return newMapMarker;
};

const createMapMarkers = (
  waypoints: MileageUpdateSummaryRouteWaypoint[]
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  for (const waypoint of waypoints) {
    const allWaypointsOnThisPlace = waypoints.filter((w) =>
      isEqual(w.place, waypoint.place)
    );

    const signature = allWaypointsOnThisPlace
      .map((w) => w.markerPosition)
      .join("/");

    const newMarker: MapMarker = createMapMarker(waypoint, signature);

    mapMarkers = [...mapMarkers, newMarker];
  }

  return mapMarkers;
};

const createMapRoute = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute => {
  return {
    waypoints: routesResponseGeometryCoordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
  };
};

const mileageUpdateSummaryRouteFactory = {
  createMapMarkers,
  createMapRoute,
};

export default mileageUpdateSummaryRouteFactory;
