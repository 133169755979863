import OrderFinishedListingRouteQueryParams from "../../common/routes/types/order-finished-listing-route-params";
import OrderFinishedListingFilter, {
  OrderFinishedListingCargoCompanyExternalOrderIdFilter,
  OrderFinishedListingCargoCompanyInternalOrderIdFilter,
  OrderFinishedListingClientFilter,
  OrderFinishedListingDispatchFilter,
  OrderFinishedListingDriverFilter,
  OrderFinishedListingExecutionStatusFilter,
  OrderFinishedListingOrderStartDateFilter,
  OrderFinishedListingPassengerFilter,
  OrderFinishedListingPublicStatusFilter,
  OrderFinishedListingRouteAddressFilter,
  OrderFinishedListingRouteDestinationAddressFilter,
  OrderFinishedListingRouteIntermediateAddressFilter,
  OrderFinishedListingRoutePickupAddressFilter,
  OrderFinishedListingTaxiFilter,
  OrderFinishedListingUrgentOnlyFilter,
} from "../common/types/order-finished-listing-filter";
import OrderFinishedListingFilterType from "../common/types/order-finished-listing-filter-type";
import OrderFinishedListingSortKey from "../common/types/order-finished-listing-sort-key";
import OrderFinishedListingPublicStatus from "../common/types/order-finished-listing-public-status";
import OrderFinishedListingExecutionStatus from "../common/types/order-finished-listing-execution-status";

const getSortKey = (
  routeQueryParams: OrderFinishedListingRouteQueryParams
): OrderFinishedListingSortKey | undefined => {
  return routeQueryParams.sort as OrderFinishedListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: OrderFinishedListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: OrderFinishedListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const getFilters = (
  routeQueryFilterParams: OrderFinishedListingRouteQueryParams
): OrderFinishedListingFilter[] => {
  const filters: OrderFinishedListingFilter[] = [];

  if (routeQueryFilterParams.client) {
    const clientFilter: OrderFinishedListingClientFilter = {
      type: OrderFinishedListingFilterType.CLIENT,
      value: routeQueryFilterParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: OrderFinishedListingDispatchFilter = {
      type: OrderFinishedListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: OrderFinishedListingDriverFilter = {
      type: OrderFinishedListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalOrderIdFilter: OrderFinishedListingCargoCompanyExternalOrderIdFilter =
      {
        type: OrderFinishedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID,
        value: routeQueryFilterParams.externalOrderId,
      };

    filters.push(externalOrderIdFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderId: OrderFinishedListingCargoCompanyInternalOrderIdFilter =
      {
        type: OrderFinishedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID,
        value: routeQueryFilterParams.internalOrderId as unknown as number,
      };

    filters.push(internalOrderId);
  }

  if (routeQueryFilterParams.isUrgentOnly) {
    const urgentOnlyFilter: OrderFinishedListingUrgentOnlyFilter = {
      type: OrderFinishedListingFilterType.URGENT_ONLY,
      value: routeQueryFilterParams.isUrgentOnly === "true" ? true : false,
    };

    filters.push(urgentOnlyFilter);
  }

  if (
    routeQueryFilterParams.orderStartDateFrom &&
    routeQueryFilterParams.orderStartDateTo
  ) {
    const orderStartDateFilter: OrderFinishedListingOrderStartDateFilter = {
      type: OrderFinishedListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.orderStartDateFrom),
        to: new Date(routeQueryFilterParams.orderStartDateTo),
      },
    };

    filters.push(orderStartDateFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: OrderFinishedListingPassengerFilter = {
      type: OrderFinishedListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.publicStatus) {
    const publicStatusFilter: OrderFinishedListingPublicStatusFilter = {
      type: OrderFinishedListingFilterType.PUBLIC_STATUS,
      value:
        routeQueryFilterParams.publicStatus as unknown as OrderFinishedListingPublicStatus,
    };

    filters.push(publicStatusFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: OrderFinishedListingRouteAddressFilter = {
      type: OrderFinishedListingFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.taxi) {
    const taxiFilter: OrderFinishedListingTaxiFilter = {
      type: OrderFinishedListingFilterType.TAXI,
      value: routeQueryFilterParams.taxi,
    };

    filters.push(taxiFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationAddressFilter: OrderFinishedListingRouteDestinationAddressFilter =
      {
        type: OrderFinishedListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationAddressFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: OrderFinishedListingRouteIntermediateAddressFilter =
      {
        type: OrderFinishedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: OrderFinishedListingRoutePickupAddressFilter =
      {
        type: OrderFinishedListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (routeQueryFilterParams.executionStatus) {
    const executionStatusFilter: OrderFinishedListingExecutionStatusFilter = {
      type: OrderFinishedListingFilterType.EXECUTION_STATUS,
      value:
        routeQueryFilterParams.executionStatus as unknown as OrderFinishedListingExecutionStatus,
    };

    filters.push(executionStatusFilter);
  }

  return filters;
};

const createRouteQueryParams = (
  filters: OrderFinishedListingFilter[],
  sortKey: OrderFinishedListingSortKey | null,
  page: number,
  pageSize: number
): OrderFinishedListingRouteQueryParams => {
  const orderStartDateFilterValue = filters.find(
    (filter) => filter.type === OrderFinishedListingFilterType.ORDER_START_DATE
  )?.value as OrderFinishedListingOrderStartDateFilter["value"] | undefined;

  const routeQueryParams: OrderFinishedListingRouteQueryParams = {
    client: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.CLIENT
    )?.value as OrderFinishedListingFilter["value"] as string,
    dispatch: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.DISPATCH
    )?.value as OrderFinishedListingFilter["value"] as string,
    driver: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.DRIVER
    )?.value as OrderFinishedListingFilter["value"] as string,
    externalOrderId: filters.find(
      (filter) =>
        filter.type ===
        OrderFinishedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID
    )?.value as OrderFinishedListingFilter["value"] as string,
    internalOrderId: filters.find(
      (filter) =>
        filter.type ===
        OrderFinishedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID
    )?.value as OrderFinishedListingFilter["value"] as string,
    isUrgentOnly: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.URGENT_ONLY
    )?.value as OrderFinishedListingFilter["value"] as string,
    orderStartDateTo: orderStartDateFilterValue?.to.toJSON(),
    orderStartDateFrom: orderStartDateFilterValue?.from.toJSON(),
    passenger: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.PASSENGER
    )?.value as OrderFinishedListingFilter["value"] as string,
    publicStatus: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.PUBLIC_STATUS
    )?.value as OrderFinishedListingFilter["value"] as string,
    routeAddress: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.ROUTE_ADDRESS
    )?.value as OrderFinishedListingFilter["value"] as string,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type === OrderFinishedListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as OrderFinishedListingFilter["value"] as string,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        OrderFinishedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as OrderFinishedListingFilter["value"] as string,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type === OrderFinishedListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as OrderFinishedListingFilter["value"] as string,
    executionStatus: filters.find(
      (filter) =>
        filter.type === OrderFinishedListingFilterType.EXECUTION_STATUS
    )?.value as OrderFinishedListingFilter["value"] as string,
    taxi: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.TAXI
    )?.value as OrderFinishedListingFilter["value"] as string,
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const orderFinishedListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default orderFinishedListingFiltersService;
