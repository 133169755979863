import { TextAnchorType, ValueOrAccessor, VictoryLabel } from "victory";
import chartHelper from "./chart.helper";
import { FC } from "react";

export enum ChartLabelTextAnchor {
  START = "START",
  MIDDLE = "MIDDLE",
  END = "END",
}

export type ChartLabelProps = {
  x: number;
  y: Date;
  dy?: number;
  dx?: number;
  text: string;
  fontSize?: number;
  textAnchor?: ChartLabelTextAnchor;
  barLabelsAngle?: number;
  scale?: { x: (item: number) => void; y: (item: Date) => void };
  charactersLimit?: number;
};

const ChartLabelComponent: FC<ChartLabelProps> = (props) => {
  const MAX_CHARACTERS = props.charactersLimit || 9;

  const x = props.scale?.x(props.x) ?? 0;
  const y = props.scale?.y(props.y) ?? 0;

  const getTextAnchor = (
    textAnchor: ChartLabelTextAnchor | undefined
  ): ValueOrAccessor<TextAnchorType> => {
    switch (textAnchor) {
      case ChartLabelTextAnchor.START:
        return "start";
      case ChartLabelTextAnchor.MIDDLE:
        return "middle";
      case ChartLabelTextAnchor.END:
        return "end";
      default:
        return "inherit";
    }
  };

  return (
    <VictoryLabel
      y={x}
      x={y}
      dy={props.dy}
      dx={props.dx}
      text={chartHelper.truncateText(props.text, MAX_CHARACTERS)}
      textAnchor={getTextAnchor(props.textAnchor)}
      angle={props.barLabelsAngle}
      style={{
        fontSize: props.fontSize,
      }}
    />
  );
};

export default ChartLabelComponent;
