import HttpResponse from "../../../../../common/utils/http/http.response";

export type OrderRouteEditPassengerData = {
  id: string;
  first_name: string;
  last_name: string;
  username: string;
  passenger: {
    addresses: OrderRouteEditPassengerAddressData[];
  };
};

export type OrderRouteEditPassengerAddressData = {
  id: string;
  display_name: string;
  lat: number;
  lon: number;
  apartment: string | null;
  house_no: string | null;
  street: string | null;
  town: string | null;
  zip_code: string | null;
};

export enum CargoEditSourceType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export interface CargoEditCargoItemSourceData {
  source_type: CargoEditSourceType;
  meta: string;
}

export interface CargoEditInternalCargoItemSourceData
  extends CargoEditCargoItemSourceData {
  passenger_id: string;
  external_id: string | null;
}

export interface CargoEditExternalCargoItemSourceData
  extends CargoEditCargoItemSourceData {
  first_name: string;
  last_name: string;
  phone_no: string;
  external_id: string | null;
  dispatch_name: string | null;
}

export type CargoEditCargoItemsSourceElement = {
  cargo_subject_id: string;
  cargo_item_source:
    | CargoEditInternalCargoItemSourceData
    | CargoEditExternalCargoItemSourceData;
};

export type OrderRouteEditNodeMetaSubject = {
  id: string;
};

export type OrderRouteEditNodeMeta = {
  id: string;
  cargo_enter: {
    subjects: OrderRouteEditNodeMetaSubject[];
  };
  cargo_exit: {
    subjects: OrderRouteEditNodeMetaSubject[];
  };
};

export type OrderRouteEditSeqNode = {
  lat: number;
  lon: number;
  time: Date | null;
  halting_time: number | null;
  meta: OrderRouteEditNodeMeta;
  display_name: string;
  apartment: string | null;
  house_no: string | null;
  street: string | null;
  town: string | null;
  zip_code: string | null;
  seq?: any;
};

export type OrderRouteEditSeqGroupNode = {
  seq: OrderRouteEditSeqNode[];
  sequence_order: "STRICT" | "ANY";
  meta: {
    id: string;
  };
};

export type OrderRouteEditRide = {
  seq: (OrderRouteEditSeqNode | OrderRouteEditSeqGroupNode)[];
  sequence_order: "STRICT" | "ANY";
  meta: {
    id: string;
  };
};

export type OrderRouteEditDispatcher = {
  id: string;
  dispatch: {
    id: string;
    cargo_company: {
      id: string;
    };
  };
};

export type OrderRouteEditCargoOrderData = {
  id: string;
  cargo_items_source_lookup: any;
  cargo_items_source_list: CargoEditCargoItemsSourceElement[];
  ride: OrderRouteEditRide;
  dispatcher: OrderRouteEditDispatcher;
  human_id: number;
};

export type OrderRouteEditOrderDetailsData = {
  id: string;
  passengers: OrderRouteEditPassengerData[];
  cargo_order: OrderRouteEditCargoOrderData;
  target_taxi_id: string | null;
  force_allow_toll: boolean;
};

export type OrderRouteEditOrderDetailsResponse =
  HttpResponse<OrderRouteEditOrderDetailsData>;

export default OrderRouteEditOrderDetailsResponse;
