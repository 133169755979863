import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import { RouteFinishedListingRouteQueryParams } from "../../common/routes/types/route-finished-listing-route-params";
import RouteFinishedListingFilter from "../common/types/route-finished-listing-filter";
import RouteFinishedListingSortKey from "../common/types/route-finished-listing-sort-key";
import routeFinishedListingFilterService from "./route-finished-listing-filter.service";

const useRouteFinishedListingFilterDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<RouteFinishedListingRouteQueryParams>();

  const getFilters = (): RouteFinishedListingFilter[] => {
    return routeFinishedListingFilterService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return routeFinishedListingFilterService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return routeFinishedListingFilterService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): RouteFinishedListingSortKey | undefined => {
    return routeFinishedListingFilterService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: RouteFinishedListingFilter[],
    sortKey: RouteFinishedListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const newRouteQueryParams =
      routeFinishedListingFilterService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(newRouteQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useRouteFinishedListingFilterDao;
