import appTranslationsHelper from "../../../../languages/app-translations.helper";
import UserRole from "../../../types/user-role";
import userPermissionsService from "../../../utils/user/permissions/user-permissions.service";
import MessengerChannel from "../types/messenger-channel";
import MessengerChannelAvailability from "../types/messenger-channel-availability";
import MessengerChannelOption from "../types/messenger-channel-option";
import { MessengerPostType } from "../types/messenger-post";

const getChannelOptionDefinitions = (): MessengerChannelOption[] => {
  const translations =
    appTranslationsHelper.getComponentTranslations().messenger;

  return [
    {
      label: translations.channels.billingLabel,
      title: translations.channels.billingTitle,
      channel: MessengerChannel.BILLING,
    },
    {
      label: translations.channels.cargoLabel,
      title: translations.channels.cargoTitle,
      channel: MessengerChannel.CARGO,
    },
    {
      label: translations.channels.driverLabel,
      title: translations.channels.driverTitle,
      channel: MessengerChannel.DRIVER,
    },
    {
      label: translations.channels.railyLabel,
      title: translations.channels.railyTitle,
      channel: MessengerChannel.RAILY,
    },
    {
      label: translations.channels.sharedLabel,
      title: translations.channels.sharedTitle,
      channel: MessengerChannel.SHARED,
    },
    {
      label: translations.channels.taxiLabel,
      title: translations.channels.taxiTitle,
      channel: MessengerChannel.TAXI,
    },
    {
      label: translations.channels.mileageWorkerLabel,
      title: translations.channels.mileageWorkerTitle,
      channel: MessengerChannel.MILEAGE_PASSENGER,
    },
    {
      label: translations.channels.mileageNotesLabel,
      title: translations.channels.mileageNotesTitle,
      channel: MessengerChannel.MILEAGE_NOTES,
    },
    {
      label: translations.channels.delegationNotesLabel,
      title: translations.channels.delegationNotesTitle,
      channel: MessengerChannel.DELEGATION_NOTES,
    },
  ];
};

const getChannelOptions = (
  userRoles: UserRole[],
  channelsAvalibility: MessengerChannelAvailability
): MessengerChannelOption[] => {
  const channelOptionDefinitions = getChannelOptionDefinitions();

  const channelOptions = channelOptionDefinitions
    .filter((item) => {
      const availableRoles = channelsAvalibility[item.channel];

      return (
        availableRoles &&
        userPermissionsService.solvePermission(userRoles, availableRoles)
      );
    })
    .map((item) => item);

  return channelOptions;
};

const getFormattedMessageSentDateTime = (date: Date) => {
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

const getPostColor = (type: MessengerPostType) => {
  switch (type) {
    case MessengerPostType.ACCEPTED:
      return "accepted";
    case MessengerPostType.REJECTED:
      return "rejected";
    case MessengerPostType.REOPENED:
      return "reopened";
    case MessengerPostType.REOPEN_REQUEST:
      return "reopen_request";
    case MessengerPostType.BILLED:
      return "billed";
    case MessengerPostType.UPDATED:
      return "updated";
    case MessengerPostType.USER_ENTRY:
      return "user_entry";
  }
};

const getContentTranslation = (content: string) => {
  const translations =
    appTranslationsHelper.getComponentTranslations().messenger.statuses;

  switch (content) {
    case MessengerPostType.ACCEPTED:
      return translations.acceptedStatusLabel;
    case MessengerPostType.REJECTED:
      return translations.rejectedStatusLabel;
    case MessengerPostType.REOPENED:
      return translations.reopenedStatusLabel;
    case MessengerPostType.REOPEN_REQUEST:
      return translations.reopenRequestStatusLabel;
    default:
      return content;
  }
};

const messengerHelper = {
  getChannelOptions,
  getFormattedMessageSentDateTime,
  getPostColor,
  getContentTranslation,
};

export default messengerHelper;
