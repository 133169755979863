import BillingNode from "./types/billing-node";

export enum BillingSaveRequestContributionType {
  BASE_AMOUNT = "BASE_AMOUNT",
  CHARGE_HALTINGS = "CHARGE_HALTING",
  CHARGE_HIGHWAYS = "CHARGE_HIGHWAY",
  DISCOUNT_OPTIMIZATION = "DISCOUNT_OPTIMIZATION",
  DISCOUNT_BEING_LATE = "DISCOUNT_BEING_LATE",
  DISCOUNT_INCOMPATIBLE_CAR = "DISCOUNT_INCOMPATIBLE_CAR",
  DISCOUNT_OTHER = "DISCOUNT_OTHER",
  CONTRACT_CORRECTION_PERCENTAGE = "CONTRACT_CORRECTION_PERCENTAGE",
}

export type BillingSaveRequestDiscountContributionContractCorrectionPercentage =
  {
    type: BillingSaveRequestContributionType.CONTRACT_CORRECTION_PERCENTAGE;
    percentage: number;
    amount: number;
  };

export type BillingSaveRequestDiscountContributionOptimization = {
  type: BillingSaveRequestContributionType.DISCOUNT_OPTIMIZATION;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestDiscountContributionBeingLate = {
  type: BillingSaveRequestContributionType.DISCOUNT_BEING_LATE;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestDiscountContributionIncompatibleCar = {
  type: BillingSaveRequestContributionType.DISCOUNT_INCOMPATIBLE_CAR;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestDiscountContributionOther = {
  type: BillingSaveRequestContributionType.DISCOUNT_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestDiscountContribution =
  | BillingSaveRequestDiscountContributionContractCorrectionPercentage
  | BillingSaveRequestDiscountContributionOptimization
  | BillingSaveRequestDiscountContributionBeingLate
  | BillingSaveRequestDiscountContributionIncompatibleCar
  | BillingSaveRequestDiscountContributionOther;

type BillingSaveRequest = {
  billing_nodes: BillingNode[];
  contract_correction_percentage: number;
  billing_contributions: BillingSaveRequestDiscountContribution[];
};

export default BillingSaveRequest;
