import appTranslationsHelper from "./app-translations.helper";
import OrderAbandonedListingTranslations from "./types/order/order-abandoned-listing.translation";
import OrderActiveListingTranslations from "./types/order/order-active-listing.translations";
import OrderAddTranslations from "./types/order/order-add.translations";
import OrderRouteEditTranslations from "./types/order/order-route-edit.translations";
import OrderDetailsTranslations from "./types/order/details/order-details.translations";
import OrderFinishedListingTranslations from "./types/order/order-finished-listing.translations";
import OrderDetailsHistoryTranslations from "./types/order/details/order-details-history.translations";
import OrderDetailsChangesApprovingTranslations from "./types/order/details/order-details-changes-approving.translations";
import OrderJoinTranslations from "./types/order/order-join.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `order`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getActiveListingTranslations = (): OrderActiveListingTranslations => {
  const translations = getTranslations<OrderActiveListingTranslations>(
    `order-active-listing.translations.json`
  );

  return translations;
};

const getAbandonedListingTranslations =
  (): OrderAbandonedListingTranslations => {
    const translations = getTranslations<OrderAbandonedListingTranslations>(
      `order-abandoned-listing.translation`
    );

    return translations;
  };

const getAddTranslations = (): OrderAddTranslations => {
  const translations = getTranslations<OrderAddTranslations>(
    `order-add.translations.json`
  );

  return translations;
};

const getEditTranslations = (): OrderRouteEditTranslations => {
  const translations = getTranslations<OrderRouteEditTranslations>(
    `order-route-edit.translations.json`
  );

  return translations;
};

const getFinishedListingTranslations = (): OrderFinishedListingTranslations => {
  const translations = getTranslations<OrderFinishedListingTranslations>(
    `order-finished-listing.translations.json`
  );

  return translations;
};

const getDetailsTranslations = (): OrderDetailsTranslations => {
  const translations = getTranslations<OrderDetailsTranslations>(
    `details/order-details.translations.json`
  );

  return translations;
};

const getOrderJoinTranslations = (): OrderJoinTranslations => {
  const translations = getTranslations<OrderJoinTranslations>(
    `order-join.translations.json`
  );

  return translations;
};

const getDetailsHistoryTranslations = (): OrderDetailsHistoryTranslations => {
  const translations = getTranslations<OrderDetailsHistoryTranslations>(
    `details/order-details-history.translations.json`
  );

  return translations;
};

const getDetailsChangesApprovingTranslations =
  (): OrderDetailsChangesApprovingTranslations => {
    const translations =
      getTranslations<OrderDetailsChangesApprovingTranslations>(
        `details/order-details-changes-approving.translations.json`
      );

    return translations;
  };

const orderTranslationsHelper = {
  getActiveListingTranslations,
  getAbandonedListingTranslations,
  getAddTranslations,
  getFinishedListingTranslations,
  getEditTranslations,
  getDetailsTranslations,
  getDetailsHistoryTranslations,
  getDetailsChangesApprovingTranslations,
  getOrderJoinTranslations,
};

export default orderTranslationsHelper;
