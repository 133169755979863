import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type CargoOfficerListingUserPermissionOption =
  | "hasAccessToDeleteUser"
  | "hasVisibleAddCargoOfficerButton";

export type CargoOfficerListingUserPermissionsDefinition =
  UserPermissionsDefinition<CargoOfficerListingUserPermissionOption>;

export type CargoOfficerListingUserPermissions =
  UserPermissions<CargoOfficerListingUserPermissionOption>;

const cargoOfficerListingUserPermissionDefinition: CargoOfficerListingUserPermissionsDefinition =
  {
    hasAccessToDeleteUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasVisibleAddCargoOfficerButton: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
    ],
  };

export default cargoOfficerListingUserPermissionDefinition;
