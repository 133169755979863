import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import TaxiCargoContractListingResponse from "./types/taxi-cargo-contract-listing-response";

const fetchTaxiCorporation = async (taxiCorporationUuid: string) => {
  const path = `${appConfig.baseApiUrl}/taxi-corporations/${taxiCorporationUuid}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchListing = async (
  taxiCorporationUuid: string
): Promise<TaxiCargoContractListingResponse> => {
  const path = `${appConfig.baseApiUrl}/taxi-corporations/${taxiCorporationUuid}/cargo-taxi/contracts`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const taxiCargoContractListingApiService = {
  fetchListing,
  fetchTaxiCorporation,
};

export default taxiCargoContractListingApiService;
