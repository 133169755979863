import OrderEditRouteParams from "./types/order-edit-route-params";
import orderRoutesUrls from "./order-routes-urls";
import urlService from "../../../../common/utils/url/url.service";
import { OrderJoinRouteParams } from "./types/order-join-route-params";
import OrderActiveListingRouteQueryParams from "./types/order-active-listing-route-params";

const getAddRoute = (): string => {
  return orderRoutesUrls.add;
};

const getEditRoute = (params: OrderEditRouteParams): string => {
  return orderRoutesUrls.routeEdit.replace(":orderId", params.orderId);
};

const getListingOfActiveOrdersRoute = (
  params?: OrderActiveListingRouteQueryParams
): string => {
  return urlService.buildWithoutEmptyParams(
    orderRoutesUrls.listingOfActiveOrders,
    params
  );
};

const getListingOfFinishedOrdersRoute = (): string => {
  return orderRoutesUrls.listingOfFinishedOrders;
};

const getListingOfAbandonedOrdersRoute = (): string => {
  return orderRoutesUrls.listingOfAbandonedOrders;
};

const getJoinRoute = (params: OrderJoinRouteParams): string => {
  return urlService.buildWithoutEmptyParams(orderRoutesUrls.join, params);
};

const orderRoutesHelper = {
  getAddRoute,
  getListingOfActiveOrdersRoute,
  getListingOfFinishedOrdersRoute,
  getListingOfAbandonedOrdersRoute,
  getEditRoute,
  getJoinRoute,
};

export default orderRoutesHelper;
