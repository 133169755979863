import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import TaxiRouteSettlementListingByRailyRouteQueryParams from "./common/types/taxi-route-settlement-listing-by-raily-route-query-params";
import TaxiRouteSettlementListingItem from "../common/types/taxi-route-settlement-listing-item";
import TaxiRouteSettlementListingFilter, {
  TaxiRouteSettlementListingRouteStartDateFilter,
} from "../common/types/taxi-route-settlement-listing-filter";
import TaxiRouteSettlementListingSortKey from "../common/types/taxi-route-settlement-listing-sort-key";
import TaxiRouteSettlementListingSortSelectOption from "../common/types/taxi-route-settlement-listing-sort-select-option";
import taxiRouteSettlementListingSortHelper from "../common/taxi-route-settlement-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import taxiRouteSettlementListingByRailyRouteQueryParamsService from "./common/taxi-route-settlement-listing-by-raily-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import TaxiRouteSettlementListingFiltersSelectComponent from "../common/filters/select/taxi-route-settlement-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import TaxiRouteSettlementListingFiltersBadgeListComponent from "../common/filters/list/taxi-route-settlement-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import TaxiRouteSettlementListingTableComponent from "../common/table/taxi-route-settlement-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import TaxiRouteSettlementListingViewBasicProps from "../common/types/taxi-route-settlement-listing-view-basic-props";
import taxiRouteSettlementListingByRailyApiService from "./common/api/taxi-route-settlement-listing-by-raily-api.service";
import taxiRouteSettlementListingByRailyFactory from "./common/taxi-route-settlement-listing-by-raily.factory";
import TaxiRouteSettlementListingFilterType from "../common/types/taxi-route-settlement-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import TaxiRouteSettlementListingByRailyTaxiCorporationSelectOption from "./common/types/taxi-route-settlement-listing-by-raily-taxi-corporation-select-option";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import TaxiRouteSettlementListingResponse from "../common/api/taxi-route-settlement-listing.response";
import taxiRouteSettlementListingRequestFactory from "../common/taxi-route-settlement-listing-request.factory";
import TaxiRouteSettlementListingByRailyTaxiCorporationsResponse from "./common/api/taxi-route-settlement-listing-by-raily-taxi-corporations.response";
import { TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem } from "../../order-settlement-listing/by-raily/common/api/taxi-order-settlement-listing-by-raily-taxi-corporations.response";
import { useAppContext } from "../../../../../context/app.context";
import TaxiRouteSettlementListingReportResponse, {
  TaxiRouteSettlementListingReportResponseData,
} from "../common/api/taxi-route-settlement-listing-report.response";
import fileDownloadService from "../../../../../common/utils/file-download/file-download.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import ButtonComponent from "../../../../../common/components/button/button.component";
import taxiRouteSettlementListingReportRequestFactory from "../common/taxi-route-settlement-listing-report-request.factory";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import TaxiRouteSettlementListingStatsSummary from "../common/types/taxi-route-settlement-listing-stats-summary";
import TaxiRouteSettlementListingStatsSummaryComponent from "../common/stats-summary/taxi-route-settlement-listing-stats-summary.component";

type TaxiRouteSettlementListingByRailyProps =
  TaxiRouteSettlementListingViewBasicProps;

const TaxiRouteSettlementListingByRailyComponent: FC<
  TaxiRouteSettlementListingByRailyProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage } = useAppContext();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiRouteSettlementListingByRailyRouteQueryParams>();

  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations();

  const [filters, setFilters] = useState<TaxiRouteSettlementListingFilter[]>(
    () =>
      taxiRouteSettlementListingByRailyRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    TaxiRouteSettlementListingItem[]
  >([]);
  const [statsSummary, setStatsSummary] =
    useState<TaxiRouteSettlementListingStatsSummary | null>(null);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiRouteSettlementListingSortKey | null>(
      () =>
        taxiRouteSettlementListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [selectedTaxiCorporationUuid, setSelectedTaxiCorporationUuid] =
    useState<string | null>(
      () =>
        taxiRouteSettlementListingByRailyRouteQueryParamsService.getTaxiCorporationUuid(
          routeQueryParams
        ) ?? null
    );

  const onTaxiCorporationChange = (
    selectedOption: TaxiRouteSettlementListingByRailyTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationUuid(selectedOption.value.uuid);
    setPage(1);
  };

  const [isTaxiCorporationFetching, setIsTaxiCorporationFetching] =
    useState(false);
  const [isTaxiCorporationFetchingError, setIsTaxiCorporationFetchingError] =
    useState(false);

  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<TaxiRouteSettlementListingByRailyTaxiCorporationSelectOption[]>(
      []
    );

  const selectedTaxiCorporationSelectOption = taxiCorporationSelectOptions.find(
    (option) => option.value.uuid === selectedTaxiCorporationUuid
  );

  const sortSelectOptions: TaxiRouteSettlementListingSortSelectOption[] =
    useMemo(
      () => taxiRouteSettlementListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      taxiRouteSettlementListingByRailyRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiRouteSettlementListingByRailyRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onTaxiCorporationFetchSuccess = (
    responseDataItems: TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem[]
  ) => {
    const taxiCorporationsSelectOptions =
      taxiRouteSettlementListingByRailyFactory.createTaxiCorporationSelectOptions(
        responseDataItems
      );

    setTaxiCorporationSelectOptions(taxiCorporationsSelectOptions);
  };

  const onTaxiCorporationFetchFailure = () => {
    setIsTaxiCorporationFetchingError(true);
  };

  const handleTaxiCorporationResponse = (
    response: TaxiRouteSettlementListingByRailyTaxiCorporationsResponse
  ) => {
    if (response.status === 200) {
      onTaxiCorporationFetchSuccess(response.data.data);
      return;
    }

    onTaxiCorporationFetchFailure();
  };

  useEffect(() => {
    setIsTaxiCorporationFetching(true);
    setIsTaxiCorporationFetchingError(false);

    taxiRouteSettlementListingByRailyApiService
      .fetchTaxiCorporations()
      .then(handleTaxiCorporationResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsTaxiCorporationFetching(false));
  }, []);

  const onListingFetchSuccess = (
    response: TaxiRouteSettlementListingResponse
  ) => {
    const listingItems =
      taxiRouteSettlementListingByRailyFactory.createListingItems(
        response.data.data
      );

    const statsSummary =
      taxiRouteSettlementListingByRailyFactory.createStatsSummary(
        response.data.stats
      );

    setListingItems(listingItems);
    setStatsSummary(statsSummary);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiRouteSettlementListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request = taxiRouteSettlementListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    taxiRouteSettlementListingByRailyApiService
      .fetchListing(selectedTaxiCorporationUuid!, request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize || !selectedTaxiCorporationUuid) {
      return;
    }

    fetchListing();
  }, [filters, selectedTaxiCorporationUuid, selectedSortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiRouteSettlementListingByRailyRouteQueryParamsService.createRouteQueryParams(
        selectedTaxiCorporationUuid,
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [selectedTaxiCorporationUuid, filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: TaxiRouteSettlementListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey, selectedAppLanguage]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === TaxiRouteSettlementListingFilterType.ORDER_START_DATE
  )?.value as
    | TaxiRouteSettlementListingRouteStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== TaxiRouteSettlementListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === TaxiRouteSettlementListingFilterType.ORDER_START_DATE
    );

    const newFilter: TaxiRouteSettlementListingRouteStartDateFilter = {
      type: TaxiRouteSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            TaxiRouteSettlementListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: TaxiRouteSettlementListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: TaxiRouteSettlementListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const request =
      taxiRouteSettlementListingReportRequestFactory.createRequest(
        filters,
        selectedSortKey
      );

    taxiRouteSettlementListingByRailyApiService
      .fetchReport(selectedTaxiCorporationUuid!, request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting="taxi-route-settlement-listing-by-raily-report-download-button"
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  const isReportDownloadButtonVisible = !!selectedTaxiCorporationUuid;
  const isListingContentVisible = !!selectedTaxiCorporationUuid;

  return (
    <div className="taxi_route_settlements_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          ...props.actionButtons,
          isReportDownloadButtonVisible && ReportDownloadButton,
        ]}
      />
      <FormFieldComponent label={translations.taxiCorporationLabel} isRequired>
        <SingleSelectComponent
          placeholder={translations.taxiCorporationPlaceholder}
          value={selectedTaxiCorporationSelectOption ?? null}
          options={taxiCorporationSelectOptions}
          onChange={(selectedOption) =>
            onTaxiCorporationChange(selectedOption!)
          }
          classNames={{
            root: "taxi_route_settlements_listing_taxi_corporation_select",
          }}
          idForTesting={`taxi-corporation-select`}
          isLoading={isTaxiCorporationFetching}
          isDisabled={isTaxiCorporationFetchingError}
          isSearchable
        />
      </FormFieldComponent>
      {isListingContentVisible && (
        <>
          <div className="taxi_route_settlements_listing_tools">
            <div className="d-flex">
              <TaxiRouteSettlementListingFiltersSelectComponent
                filters={filters}
                onAddNewFilter={addNewFilter}
              />
              <DateRangeInputComponent
                date={startDateFilterValue ?? null}
                onChange={onOrderStartDateFilterValueChange}
                classNames={{ root: "ml-2" }}
                placeholder={
                  translations.filters.searchByStartDateSelectInputPlaceholder
                }
                idForTesting="taxi-route-settlement-listing-by-raily-date-range"
              />
            </div>
            <ListingSortSelectComponent
              onChange={(option) => setSelectedSortKey(option?.value!)}
              options={sortSelectOptions}
              value={selectedSortSelectOption}
              idForTesting="taxi-route-settlement-listing-by-raily-sort"
            />
          </div>
          <TaxiRouteSettlementListingFiltersBadgeListComponent
            filters={filters}
            onDeleteFilterClick={deleteFilter}
            onDeleteAllFiltersButtonClick={deleteAllFilters}
          />
          {!!listingItems.length && (
            <TaxiRouteSettlementListingStatsSummaryComponent
              isError={isListingFetchingError}
              isLoading={isListingFetching}
              statsSummary={statsSummary}
            />
          )}
          <CardComponent classNames={{ root: "mt-4" }}>
            <TaxiRouteSettlementListingTableComponent
              listingItems={listingItems}
              isError={isListingFetchingError}
              isLoading={isListingFetching}
            />
            <div className="taxi_route_settlements_listing__pagination_wrapper">
              <PaginationComponent
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                page={page}
                pageSize={pageSize}
                totalResults={totalResults}
              />
            </div>
          </CardComponent>
        </>
      )}
    </div>
  );
};

export default TaxiRouteSettlementListingByRailyComponent;