import DispatcherAddParams from "../../../../../../common/services/dispatcher/add/dispatcher-add-params";
import DispatcherAddByCargoFormData from "./types/dispatcher-add-by-cargo-form-data";

const create = (
  formData: DispatcherAddByCargoFormData
): DispatcherAddParams => {
  return {
    alternativePhoneNumber: formData.alternativePhoneNumber,
    dispatchUuid: formData.dispatchUuid,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    password: formData.password,
    phoneNumber: formData.phoneNumber,
    username: formData.username,
  };
};

const dispatcherAddByCargoParamsFactory = {
  create,
};

export default dispatcherAddByCargoParamsFactory;
