import { FC, useMemo, useState } from "react";
import MileageAddVehicleType from "../../types/mileage-add-vehicle-type";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";
import { useAppContext } from "../../../../../../context/app.context";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import MileageAddVehicleTypeSelectOption from "../../types/mileage-add-vehicle-type-select-option";
import mileageAddVehicleTypeFactory from "../../factory/mileage-add-vehicle-type.factory";

type MileageAddVehicleTypeSelectProps = {
  vehicleType: MileageAddVehicleType;
  onVehicleTypeChange: (type: MileageAddVehicleType) => void;
  onBlur?: () => void;
};

const MileageAddVehicleTypeSelectComponent: FC<
  MileageAddVehicleTypeSelectProps
> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().vehicleType;

  const { selectedAppLanguage } = useAppContext();

  const [searchQuery, setSearchQuery] = useState("");

  const vehicleTypeSelectOptions = useMemo(
    mileageAddVehicleTypeFactory.createSelectOptions,
    [selectedAppLanguage]
  );

  const vehicleTypeSelectOption =
    mileageAddVehicleTypeFactory.createSelectOption(props.vehicleType);

  return (
    <SingleSelectComponent
      placeholder={translations.selectPlaceholder}
      value={vehicleTypeSelectOption}
      options={vehicleTypeSelectOptions}
      onChange={(value: MileageAddVehicleTypeSelectOption | null) => {
        if (!value) {
          return;
        }

        props.onVehicleTypeChange(value.value);
      }}
      idForTesting="mileage-add-vehicle-type-select-component"
      isSearchable
      inputValue={searchQuery}
      onInputChange={setSearchQuery}
      onBlur={props.onBlur}
    />
  );
};

export default MileageAddVehicleTypeSelectComponent;
