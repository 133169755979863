import HttpResponse from "../../../../utils/http/http.response";

export enum TaxiTaxiContractDetailsResponseItemBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export enum TaxiTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type TaxiTaxiContractDetailsResponseItemTaxiCorporationFleetPartner = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  taxiCorporationId: string;
  name: string;
};

export type TaxiTaxiContractDetailsResponseItemTaxiCorporation = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: TaxiTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy;
  notes: string;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  fleetPartners: TaxiTaxiContractDetailsResponseItemTaxiCorporationFleetPartner[];
};

type TaxiTaxiContractDetailsResponseItem = {
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: TaxiTaxiContractDetailsResponseItemBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  id: string;
  producerTaxiId: string;
  producerTaxi: TaxiTaxiContractDetailsResponseItemTaxiCorporation;
  consumerTaxiId: string;
  consumerTaxi: TaxiTaxiContractDetailsResponseItemTaxiCorporation;
  isPartner: boolean;
  displayName: string;
  priority: number;
  isValid: boolean;
};

type TaxiTaxiContractDetailsResponse =
  HttpResponse<TaxiTaxiContractDetailsResponseItem>;

export default TaxiTaxiContractDetailsResponse;
