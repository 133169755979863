import { FC } from "react";
import TableComponent from "../../../../../../../common/components/table/table.component";
import BillingsTaxiDriverContributionsContractCorrectionTableColumn from "../types/billings-taxi-driver-contributions-contract-correction-table-column";
import BillingsTaxiDriverContributionsContractCorrectionTableRow from "../types/billings-taxi-driver-contributions-contract-correction-table-row";
import BillingsTaxiDriverContributionsContractCorrectionTableHelper from "./billings-taxi-driver-contributions-contract-correction-table.helper";

type BillingsTaxiDriverContributionsContractCorrectionTableProps = {
  rows: BillingsTaxiDriverContributionsContractCorrectionTableRow[];
  isLoading: boolean;
};

const BillingsTaxiDriverContributionsContractCorrectionTableComponent: FC<
  BillingsTaxiDriverContributionsContractCorrectionTableProps
> = (props) => {
  const columns: BillingsTaxiDriverContributionsContractCorrectionTableColumn[] =
    BillingsTaxiDriverContributionsContractCorrectionTableHelper.getColumns();

  return (
    <TableComponent
      columns={columns}
      rows={props.rows}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiDriverContributionsContractCorrectionTableComponent;
