import debounce from "lodash/debounce";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import notificationService from "../../../../common/utils/notification/notification.service";
import SearchAddressResponse from "../../../../common/utils/search-address/search-address.response";
import searchAddressApiService from "../../../../common/utils/search-address/search-address.service";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import cargoTranslationsHelper from "../../../../languages/cargo-translations.helper";
import cargoBreadcrumbsHelper from "../../common/breadcrumbs/cargo-breadcrumbs.helper";
import cargoRoutesHelper from "../../common/routes/cargo-routes.helper";
import CargoAddressAddAddressRequest from "./common/api/cargo-address-add-add-address.request";
import CargoAddressAddAddAddressResponse from "./common/api/cargo-address-add-add-address.response";
import cargoAddressAddApiService from "./common/api/cargo-address-add-api.service";
import cargoAddressFormHelper from "../common/cargo-address-form.helper";
import CargoAddressAddAddressSelectOption from "./common/types/cargo-address-add-address-select-option";
import cargoAddressAddFactory from "./common/cargo-address-add.factory";
import CargoAddressListingRouteParams from "../../common/routes/types/cargo-address-listing-route-params";
import CargoAddressTypeSelectOption from "../common/types/cargo-address-type-select-option";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import MapAddressSelectorComponent from "../../../../common/components/map-address-selector/map-address-selector-component";
import CargoAddressAddFormCoordinates from "./common/types/cargo-address-add-form-coordinates";
import CargoAddressAddFormAddressSelected from "./common/types/cargo-address-add-form-address-selected";
import useForm from "../../../../common/components/form/use-form";
import CargoAddressFormData from "../common/types/cargo-address-form-data";
import useAbort from "../../../../common/hooks/use-abort";
import useCargoCompanyDetails from "../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";

type CargoAddressAddProps = {};

const CargoAddressAddComponent: FC<CargoAddressAddProps> = () => {
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const { cargoCompanyUuid } = useParams<CargoAddressListingRouteParams>();
  const [cargoCompanyName, setCargoCompanyName] = useState("");

  const navigate = useNavigate();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.cargoAddressAdd);

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => cargoCompanyAbort.revoke();
  }, [cargoCompanyUuid]);

  useEffect(() => {
    if (!cargoCompany.data) return;

    setCargoCompanyName(cargoCompany.data.displayName);
  }, [cargoCompany.data]);

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    const breadcrumbs = cargoBreadcrumbsHelper.getAddressAddBreadcrumbs({
      cargoCompanyUuid,
      cargoCompanyName,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoCompanyUuid, cargoCompanyName]);

  const translations = cargoTranslationsHelper.getAddressAddTranslations();

  const form = useForm<CargoAddressFormData>({
    emptyValues: cargoAddressFormHelper.getEmptyFormData(),
    validationDefinition: cargoAddressFormHelper.getValidationDefinition(),
  });

  const [addressSelectOptions, setAddressSelectOptions] = useState<
    CargoAddressAddAddressSelectOption[]
  >([]);
  const [selectedAddressOption, setSelectedAddressOption] =
    useState<CargoAddressAddAddressSelectOption | null>(null);
  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [isAddressFetching, setIsAddressFetching] = useState(false);

  const addressTypeSelectOptions: CargoAddressTypeSelectOption[] = useMemo(
    () => cargoAddressFormHelper.getAddressTypeSelectOptions(),
    [selectedAppLanguage]
  );

  const [addressCoordinates, setAddressCoordinates] =
    useState<CargoAddressAddFormCoordinates | null>(null);

  const [isAddressAddFetching, setIsAddressAddFetching] = useState(false);

  const acceptAddressCandidature = (
    value: CargoAddressAddFormAddressSelected
  ) => {
    if (!value) return;

    const streetValue = value.street ?? "";
    const houseNumberValue = value.houseNumber ?? "";
    const zipCodeValue = value.zipCode ?? "";
    const countryValue = value.country ?? "";
    const townValue = value.town ?? "";

    const address: CargoAddressAddAddressSelectOption | null = {
      label: value.name,
      value: value,
    };

    setSelectedAddressOption(address);

    setAddressCoordinates({
      latitude: value.latitude,
      longitude: value.longitude,
    });

    form.setValues({
      addressType: null,
      street: streetValue,
      houseNumber: houseNumberValue,
      apartmentNumber: "",
      zipCode: zipCodeValue,
      country: countryValue,
      town: townValue,
      description: "",
    });
  };

  useEffect(() => {
    const streetValue = selectedAddressOption?.value.street ?? "";
    const houseNumberValue = selectedAddressOption?.value.houseNumber ?? "";
    const zipCodeValue = selectedAddressOption?.value.zipCode ?? "";
    const countryValue = selectedAddressOption?.value.country ?? "";
    const townValue = selectedAddressOption?.value.town ?? "";
    const coordinate = selectedAddressOption
      ? {
          latitude: selectedAddressOption.value.latitude,
          longitude: selectedAddressOption.value.longitude,
        }
      : null;

    form.setValues({
      addressType: null,
      street: streetValue,
      houseNumber: houseNumberValue,
      apartmentNumber: "",
      zipCode: zipCodeValue,
      country: countryValue,
      town: townValue,
      description: "",
    });

    setAddressCoordinates(coordinate);
  }, [selectedAddressOption]);

  useEffect(() => {
    if (!form.values.addressType) return;

    const addressTypeValue =
      cargoAddressAddFactory.recreateAddressTypeSelectOption(
        form.values.addressType.value
      );

    form.setValue("addressType", addressTypeValue);
  }, [selectedAppLanguage]);

  const fetchAddressSelectOptions = (addressSearchQuery: string) => {
    setIsAddressFetching(true);

    fetchResolvedAddress(addressSearchQuery)
      .then((response) => {
        const selectOptions =
          cargoAddressAddFactory.createAddressSelectOptions(response);

        setAddressSelectOptions(selectOptions);
      })
      .finally(() => {
        setIsAddressFetching(false);
      });
  };

  const debounceFetchAddressSelectOptions = useCallback(
    debounce((addressSearchQuery: string) => {
      fetchAddressSelectOptions(addressSearchQuery);
    }, 300),
    []
  );

  useEffect(() => {
    if (!addressSearchQuery) {
      return;
    }

    debounceFetchAddressSelectOptions(addressSearchQuery);
  }, [addressSearchQuery]);

  const fetchResolvedAddress = (
    addressSearchQuery: string
  ): Promise<SearchAddressResponse> => {
    return searchAddressApiService.searchByQuery(addressSearchQuery);
  };

  const onAddAddressSuccess = () => {
    notificationService.success(translations.successAddNotificationText);
    navigate(
      cargoRoutesHelper.getAddressListingRoute({
        cargoCompanyUuid: cargoCompanyUuid!,
      })
    );
  };

  const onAddAddressFailure = () => {
    notificationService.error(translations.failureAddNotificationText);
  };

  const handleAddAddressResponse = (
    response: CargoAddressAddAddAddressResponse
  ) => {
    if (response.status === 201) {
      onAddAddressSuccess();
      return;
    }

    onAddAddressFailure();
  };

  const addNewAddress = () => {
    if (!cargoCompanyUuid) return;

    setIsAddressAddFetching(true);

    const request: CargoAddressAddAddressRequest = {
      apartment: form.values.apartmentNumber,
      country: form.values.country,
      description: form.values.description.trim() || null,
      house_no: form.values.houseNumber,
      lat: addressCoordinates?.latitude!,
      lon: addressCoordinates?.longitude!,
      street: form.values.street,
      town: form.values.town,
      type: cargoAddressAddFactory.createAddRequestAddressType(
        form.values.addressType?.value!
      ),
      zip_code: form.values.zipCode,
    };

    cargoAddressAddApiService
      .addAddress(cargoCompanyUuid, request)
      .then(handleAddAddressResponse)
      .finally(() => setIsAddressAddFetching(false));
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) return;

    addNewAddress();
  };

  const isAddressDetailsFormFieldEnabled =
    !!selectedAddressOption || !!addressCoordinates;

  return (
    <div className="cargo_address_add">
      <HeadingComponent title={translations.header.headingText} />
      <CardComponent>
        <Row>
          <Column xl={6}>
            <small>{translations.address.searchAddressTip}</small>
            <FormFieldComponent label={translations.address.searchAddressLabel}>
              <SingleSelectComponent
                placeholder={
                  translations.address.searchAddressSelectPlaceholder
                }
                options={addressSelectOptions}
                value={selectedAddressOption}
                isLoading={isAddressFetching}
                inputValue={addressSearchQuery}
                onInputChange={setAddressSearchQuery}
                isClearable
                onChange={(option) => setSelectedAddressOption(option)}
                isSearchable
                menuPlacement="bottom"
                filterFunction={() => true}
                idForTesting="cargo-address-add-address-search-select"
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.address.addressTypeLabel}
              isRequired
              errorMessage={form.validationResults.addressType.errorMessage}
            >
              <SingleSelectComponent
                placeholder={translations.address.addressTypeSelectPlaceholder}
                options={addressTypeSelectOptions}
                value={form.values.addressType}
                onChange={(value) => form.setValue("addressType", value)}
                menuPlacement="bottom"
                hasError={!!form.validationResults.addressType.errorMessage}
                idForTesting="cargo-address-add-address-type-select"
              />
            </FormFieldComponent>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.address.streetLabel}
                  isRequired
                  errorMessage={form.validationResults.street.errorMessage}
                >
                  <InputComponent
                    value={form.values.street}
                    onChange={(value) => form.setValue("street", value)}
                    placeholder={translations.address.streetInputPlaceholder}
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!form.validationResults.street.errorMessage}
                    onBlur={() => form.validate("street")}
                    idForTesting="cargo-address-add-street-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={3}>
                <FormFieldComponent
                  label={translations.address.houseNumberLabel}
                  isRequired
                  errorMessage={form.validationResults.houseNumber.errorMessage}
                >
                  <InputComponent
                    value={form.values.houseNumber}
                    onChange={(value) => form.setValue("houseNumber", value)}
                    placeholder={
                      translations.address.houseNumberInputPlaceholder
                    }
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!form.validationResults.houseNumber.errorMessage}
                    onBlur={() => form.validate("houseNumber")}
                    idForTesting="cargo-address-add-house-number-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={3}>
                <FormFieldComponent
                  label={translations.address.apartmentNumberLabel}
                  errorMessage={
                    form.validationResults.apartmentNumber.errorMessage
                  }
                >
                  <InputComponent
                    value={form.values.apartmentNumber}
                    onChange={(value) =>
                      form.setValue("apartmentNumber", value)
                    }
                    placeholder={
                      translations.address.apartmentNumberInputPlaceholder
                    }
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={
                      !!form.validationResults.apartmentNumber.errorMessage
                    }
                    onBlur={() => form.validate("apartmentNumber")}
                    idForTesting="cargo-address-add-apartment-number-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.address.townLabel}
                  classNames={{ root: "mt-0" }}
                  isRequired
                  errorMessage={form.validationResults.town.errorMessage}
                >
                  <InputComponent
                    value={form.values.town}
                    onChange={(value) => form.setValue("town", value)}
                    placeholder={translations.address.townInputPlaceholder}
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!form.validationResults.town.errorMessage}
                    onBlur={() => form.validate("town")}
                    idForTesting="cargo-address-add-town-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.address.zipCodeLabel}
                  classNames={{ root: "mt-0" }}
                  isRequired
                  errorMessage={form.validationResults.zipCode.errorMessage}
                >
                  <InputComponent
                    value={form.values.zipCode}
                    onChange={(value) => form.setValue("zipCode", value)}
                    placeholder={translations.address.zipCodeInputPlaceholder}
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!form.validationResults.zipCode.errorMessage}
                    onBlur={() => form.validate("zipCode")}
                    idForTesting="cargo-address-add-zip-code-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <FormFieldComponent
              label={translations.address.countryLabel}
              classNames={{ root: "mt-0" }}
              isRequired
              errorMessage={form.validationResults.country.errorMessage}
            >
              <InputComponent
                value={form.values.country}
                onChange={(value) => form.setValue("country", value)}
                placeholder={translations.address.countryInputPlaceholder}
                isDisabled={!isAddressDetailsFormFieldEnabled}
                hasError={!!form.validationResults.country.errorMessage}
                onBlur={() => form.validate("country")}
                idForTesting="cargo-address-add-country-input"
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.address.descriptionLabel}
              classNames={{ root: "mt-0" }}
              errorMessage={form.validationResults.description.errorMessage}
              isRequired
            >
              <InputComponent
                value={form.values.description}
                onChange={(value) => form.setValue("description", value)}
                placeholder={translations.address.descriptionInputPlaceholder}
                isDisabled={!isAddressDetailsFormFieldEnabled}
                hasError={!!form.validationResults.description.errorMessage}
                onBlur={() => form.validate("description")}
                idForTesting="cargo-address-add-description-input"
              />
            </FormFieldComponent>
          </Column>
          <Column xl={6}>
            <FormFieldComponent
              label={translations.address.mapLabel}
              classNames={{
                root: "mt-0",
              }}
            >
              <div className="cargo_address_add_map_wrapper">
                <MapAddressSelectorComponent
                  onAddressChange={acceptAddressCandidature}
                  address={addressCoordinates!}
                />
              </div>
            </FormFieldComponent>
          </Column>
        </Row>
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          isDisabled={isAddressAddFetching}
          idForTesting="cargo-address-add-submit-button"
          classNames={{ root: "mt-2" }}
          title={translations.address.submitButtonTitle}
        >
          {translations.address.submitButtonText}
        </ButtonComponent>
      </CardComponent>
    </div>
  );
};

export default CargoAddressAddComponent;
