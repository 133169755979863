import RouteFinishedListResponse, {
  RouteFinishedListResponseDataItem,
} from "../../../api/raily/route/finished-list/route-finished-list.response";
import RouteFinishedList, {
  RouteFinishedListItem,
} from "./route-finished-list";

const createDataItem = (
  responseItem: RouteFinishedListResponseDataItem
): RouteFinishedListItem => {
  return {
    uuid: responseItem.id,
    driverName: responseItem.driverName,
    taxiCorporationName: responseItem.taxiName,
    routeAddresses: responseItem.planEntryNodes,
    humanId: responseItem.humanId,
    startTime: responseItem.startTime,
    passengers: responseItem.involvedCargoLabels,
    cargoOrderHumanIds: responseItem.cargoOrderHumanIds,
  };
};

const createData = (
  responseData: RouteFinishedListResponseDataItem[]
): RouteFinishedListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: RouteFinishedListResponse): RouteFinishedList => {
  return {
    data: createData(response.data.data),
  };
};

const routeFinishedListFactory = {
  create,
};

export default routeFinishedListFactory;
