import AppLanguage from "./types/app-language";

const languageLocalStorageKey = "LANGUAGE";

const setLanguage = (language: AppLanguage): void => {
  localStorage.setItem(languageLocalStorageKey, language);
};

const getLanguage = (): AppLanguage | null => {
  return localStorage.getItem(languageLocalStorageKey) as AppLanguage | null;
};

const appLanguageDao = {
  setLanguage,
  getLanguage,
};

export default appLanguageDao;
