import { FC, useMemo, useState, useCallback, useEffect } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import { useAppContext } from "../../../../context/app.context";
import MileageDictionaryListingViewMode from "./common/types/mileage-dictionary-listing-view-mode";
import MileageDictionaryCargoListingComponent from "./cargo/cargo-mileage-dictionary-listing.component";
import MileageDictionaryAdminListingComponent from "./admin/admin-mileage-dictionary-listing.component";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import mileageDictionaryListingUserPermissionsHelper from "./common/types/user-permissions/mileage-dictionary-listing-user-permissions.helper";

const MileageDictionaryListingComponent: FC = () => {
  const { user, selectedAppLanguage } = useAppContext();

  const userPermissions = useMemo(
    () =>
      mileageDictionaryListingUserPermissionsHelper.getPermissions(
        user?.roles!
      ),
    []
  );

  const translations = useMemo(
    () => mileageTranslationsHelper.getMileageDictionaryTranslations(),
    [selectedAppLanguage]
  );

  const [selectedViewMode, setSelectedViewMode] =
    useState<MileageDictionaryListingViewMode | null>(null);

  const ChangeViewToAdminButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() =>
          setSelectedViewMode(MileageDictionaryListingViewMode.ADMIN)
        }
        title={translations.header.changeViewToAdminButtonTitle}
      >
        {translations.header.changeViewToAdminButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToCargoButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() =>
          setSelectedViewMode(MileageDictionaryListingViewMode.CARGO)
        }
        title={translations.header.changeViewToCargoButtonTitle}
      >
        {translations.header.changeViewToCargoButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: MileageDictionaryListingViewMode.ADMIN,
        buttonOptions: {
          button: ChangeViewToCargoButton,
          hasPermission: userPermissions.hasAccessToCargoView,
        },
      },
      {
        viewMode: MileageDictionaryListingViewMode.CARGO,
        buttonOptions: {
          button: ChangeViewToAdminButton,
          hasPermission: userPermissions.hasAccessToAdminView,
        },
      },
    ],
    [userPermissions, ChangeViewToCargoButton, ChangeViewToAdminButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: MileageDictionaryListingViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: MileageDictionaryListingViewMode.ADMIN,
        component: (
          <MileageDictionaryAdminListingComponent
            changeViewButtons={getViewChangeButtons(
              MileageDictionaryListingViewMode.ADMIN
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToAdminView,
      },
      {
        mode: MileageDictionaryListingViewMode.CARGO,
        component: (
          <MileageDictionaryCargoListingComponent
            changeViewButtons={getViewChangeButtons(
              MileageDictionaryListingViewMode.CARGO
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToCargoView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default MileageDictionaryListingComponent;
