import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderJoinRouteParamsService, {
  OrderJoinOrderData,
} from "../../common/routes/order-join-route-params.service";
import orderRoutesHelper from "../../common/routes/order-routes.helper";
import { OrderJoinRouteParams } from "../../common/routes/types/order-join-route-params";
import OrderActiveListingJoinSourceOrderData from "../common/types/order-active-listing-join-source-order-data";
import OrderActiveListingJoinTargetOrderData from "../common/types/order-active-listing-join-target-order-data";

const getTargetLabel = (
  targetOrderData: OrderActiveListingJoinTargetOrderData
): string => {
  const translations =
    orderTranslationsHelper.getActiveListingTranslations().orderJoin;

  return translations.targetOrderTemplateLabel.replace(
    "#{internalOrderId}",
    String(targetOrderData.internalOrderId)
  );
};

const getSourcesLabel = (
  sourceOrdersData: OrderActiveListingJoinSourceOrderData[]
): string => {
  const translations =
    orderTranslationsHelper.getActiveListingTranslations().orderJoin;

  return translations.joinedOrderTemplateLabel.replace(
    "#{internalOrderIds}",
    sourceOrdersData.length
      ? sourceOrdersData.map((item) => item.internalOrderId).join(", ")
      : translations.orderNotSelectedLabel
  );
};

const getJoinOrdersRouteParams = (
  listingTargetOrderData: OrderActiveListingJoinTargetOrderData,
  listingSourceOrdersData: OrderActiveListingJoinSourceOrderData[]
): OrderJoinRouteParams => {
  const targetOrderData: OrderJoinOrderData = {
    uuid: listingTargetOrderData.uuid,
    internalOrderId: listingTargetOrderData.internalOrderId,
  };

  const sourceOrdersData: OrderJoinOrderData[] = listingSourceOrdersData;

  return orderJoinRouteParamsService.build(
    targetOrderData.uuid,
    sourceOrdersData
  );
};

const getOrdersJoinRoute = (
  targetOrderData: OrderActiveListingJoinTargetOrderData,
  sourceOrdersData: OrderActiveListingJoinSourceOrderData[]
): string => {
  const routeParams: OrderJoinRouteParams = getJoinOrdersRouteParams(
    targetOrderData,
    sourceOrdersData
  );

  return orderRoutesHelper.getJoinRoute(routeParams);
};

const orderActiveListingOrderJoinHelper = {
  getTargetLabel,
  getSourcesLabel,
  getOrdersJoinRoute,
};

export default orderActiveListingOrderJoinHelper;
