import CargoTaxiContractAddRequest from "../../api/raily/cargo-taxi-contract/add/cargo-taxi-contract-add.request";
import cargoTaxiContractApiService from "../../api/raily/cargo-taxi-contract/cargo-taxi-contract-api.service";
import CargoTaxiContractDetailsRequest from "../../api/raily/cargo-taxi-contract/details/cargo-taxi-contract-details.request";
import CargoTaxiContractUpdateRequest from "../../api/raily/cargo-taxi-contract/update/cargo-taxi-contract-update.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import CargoTaxiContractAddError from "./add/cargo-taxi-contract-add-error";
import CargoTaxiContractAddParams from "./add/cargo-taxi-contract-add-params";
import cargoTaxiContractAddRequestFactory from "./add/cargo-taxi-contract-add-request.factory";
import CargoTaxiContractDetails from "./details/cargo-taxi-contract-details";
import CargoTaxiContractDetailsError from "./details/cargo-taxi-contract-details-error";
import CargoTaxiContractDetailsLoadParams from "./details/cargo-taxi-contract-details-load-params";
import cargoTaxiContractDetailsRequestFactory from "./details/cargo-taxi-contract-details-request.factory";
import cargoTaxiContractDetailsFactory from "./details/cargo-taxi-contract-details.factory";
import CargoTaxiContractUpdateError from "./update/cargo-taxi-contract-update-error";
import CargoTaxiContractUpdateParams from "./update/cargo-taxi-contract-update-params";
import cargoTaxiContractUpdateRequestFactory from "./update/cargo-taxi-contract-update-request.factory";

const handleAddError = (
  error: HttpError | CargoTaxiContractAddError
): CargoTaxiContractAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: CargoTaxiContractAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: CargoTaxiContractAddRequest =
    cargoTaxiContractAddRequestFactory.create(params);

  try {
    const response = await cargoTaxiContractApiService().add(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | CargoTaxiContractAddError;

    throw handleAddError(error);
  }
};

const handleUpdateError = (
  error: HttpError | CargoTaxiContractUpdateError
): CargoTaxiContractUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const update = async (
  params: CargoTaxiContractUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: CargoTaxiContractUpdateRequest =
    cargoTaxiContractUpdateRequestFactory.create(params);

  try {
    const response = await cargoTaxiContractApiService().update(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | CargoTaxiContractUpdateError;

    throw handleUpdateError(error);
  }
};

const handleDetailsError = (
  error: HttpError | CargoTaxiContractDetailsError
): CargoTaxiContractDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: CargoTaxiContractDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<CargoTaxiContractDetails> => {
  const request: CargoTaxiContractDetailsRequest =
    cargoTaxiContractDetailsRequestFactory.create(params);

  try {
    const response = await cargoTaxiContractApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoTaxiContractDetailsFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | CargoTaxiContractDetailsError;

    throw handleDetailsError(error);
  }
};

const cargoTaxiContractService = {
  add,
  update,
  getDetails,
};

export default cargoTaxiContractService;
