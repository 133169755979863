import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import TaxiUnsettledPrivateOrderListingFilter from "../../types/taxi-unsettled-private-order-listing-filter";
import TaxiUnsettledPrivateOrderListingFilterSelectOption from "../../types/taxi-unsettled-private-order-listing-filter-select-option";
import taxiUnsettledPrivateOrderListingFilterHelper from "../taxi-unsettled-private-order-listing-filter.helper";

type TaxiUnsettledPrivateOrderListingFiltersSelectProps = {
  filters: TaxiUnsettledPrivateOrderListingFilter[];
  onAddNewFilter: (filter: TaxiUnsettledPrivateOrderListingFilter) => void;
};

const TaxiUnsettledPrivateOrderListingFiltersSelectComponent: FC<
  TaxiUnsettledPrivateOrderListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: TaxiUnsettledPrivateOrderListingFilterSelectOption[] =
    useMemo(() => {
      return taxiUnsettledPrivateOrderListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: TaxiUnsettledPrivateOrderListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(
          option as TaxiUnsettledPrivateOrderListingFilterSelectOption
        )
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="taxi-unsettled-private-order-listing-filter"
    />
  );
};

export default TaxiUnsettledPrivateOrderListingFiltersSelectComponent;
