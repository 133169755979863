import { faTaxi, faTruck } from "@fortawesome/free-solid-svg-icons";
import TaxiUnsettledPrivateOrderListingTableColumn from "../common/types/taxi-unsettled-private-order-listing-table-column";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiUnsettledPrivateOrderListingItem, {
  TaxiUnsettledPrivateOrderListingItemMissingSettlementType,
} from "../common/types/taxi-unsettled-private-order-listing-item";
import TaxiUnsettledPrivateOrderListingTableRow from "../common/types/taxi-unsettled-private-order-listing-table-row";
import dateService from "../../../../../common/utils/date/date.service";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";
import billingRoutesHelper from "../../../../billings/common/routes/billing-routes.helper";
import TaxiUnsettledPrivateOrderListingTableStatusComponent from "./status/taxi-unsettled-private-order-listing-table-status.component";

const getColumns = (): TaxiUnsettledPrivateOrderListingTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledPrivateOrderListingTranslations()
      .table.headers;

  const columns: TaxiUnsettledPrivateOrderListingTableColumn[] = [
    {
      accessor: "status",
      header: "",
      title: "",
      colSpan: 2,
    },
    {
      accessor: "startDate",
      header: translations.startDateLabel,
      title: translations.startDateTitle,
      colSpan: 10,
    },
    {
      accessor: "internalOrderId",
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      colSpan: 8,
    },
    {
      accessor: "externalOrderId",
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      colSpan: 8,
    },
    {
      accessor: "route",
      header: translations.routeLabel,
      title: translations.routeTitle,
      colSpan: 35,
    },
    {
      accessor: "passengers",
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      colSpan: 10,
    },
    {
      accessor: "dispatch",
      header: translations.dispatchLabel,
      title: translations.dispatchTitle,
      colSpan: 8,
    },
    {
      accessor: "client",
      header: translations.clientLabel,
      title: translations.clientTitle,
      colSpan: 8,
    },
    {
      accessor: "taxiCorporations",
      header: translations.taxiCorporationsLabel,
      title: translations.taxiCorporationsTitle,
      colSpan: 8,
    },
    {
      accessor: "drivers",
      header: translations.driversLabel,
      title: translations.driversTitle,
      colSpan: 10,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 10,
    },
  ];

  return columns;
};

const checkIsAddressGroup = (
  address: string | string[]
): address is string[] => {
  if (!Array.isArray(address)) {
    return false;
  }

  return true;
};

const getRouteLabel = (
  addresses: TaxiUnsettledPrivateOrderListingItem["routeAddresses"]
): string => {
  const addressesWithFlattenedGroups = addresses.map((address) => {
    const isAddressGroup = checkIsAddressGroup(address);

    if (!isAddressGroup) {
      return address;
    }

    const connectedAddresses = address.join(" | ");
    return `[ ${connectedAddresses} ]`;
  });

  return addressesWithFlattenedGroups.join(" > ");
};

const getRouteTitle = (
  addresses: TaxiUnsettledPrivateOrderListingItem["routeAddresses"]
): string => {
  return addresses.join(`\r`);
};

const getRow = (
  listingItem: TaxiUnsettledPrivateOrderListingItem
): TaxiUnsettledPrivateOrderListingTableRow => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledOrderListingTranslations()
      .table;

  const routeAddressesLabel = getRouteLabel(listingItem.routeAddresses);
  const routeAddressesTitle = getRouteTitle(listingItem.routeAddresses);

  const startDateLabel = dateService.format(
    listingItem.startDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.startDate,
    "dd.mm.yyyy"
  );

  const isCargoWithTaxiSettlementAddButtonVisible =
    listingItem.missingSettlementTypes.includes(
      TaxiUnsettledPrivateOrderListingItemMissingSettlementType.CARGO_WITH_TAXI
    );

  const isCargoWithRailySettlementAddButtonVisible =
    listingItem.missingSettlementTypes.includes(
      TaxiUnsettledPrivateOrderListingItemMissingSettlementType.CARGO_WITH_RAILY
    );

  const isRailyWithTaxiSettlementAddButtonVisible =
    listingItem.missingSettlementTypes.includes(
      TaxiUnsettledPrivateOrderListingItemMissingSettlementType.RAILY_WITH_TAXI
    );

  return {
    id: listingItem.uuid,
    value: {
      client: (
        <div title={listingItem.clientName}>{listingItem.clientName}</div>
      ),
      dispatch: (
        <div title={listingItem.dispatchName}>{listingItem.dispatchName}</div>
      ),
      drivers: (
        <div>
          {listingItem.driversDisplayNames.map((driverDisplayName, index) => (
            <div key={index} title={driverDisplayName}>
              {driverDisplayName}
            </div>
          ))}
        </div>
      ),
      externalOrderId: (
        <div title={listingItem.externalOrderId ?? undefined}>
          {listingItem.externalOrderId ?? undefined}
        </div>
      ),
      internalOrderId: (
        <div title={String(listingItem.internalOrderId)}>
          {String(listingItem.internalOrderId)}
        </div>
      ),
      passengers: (
        <div>
          {listingItem.passengers.map((passenger, index) => (
            <div key={index} title={passenger}>
              {passenger}
            </div>
          ))}
        </div>
      ),
      route: <div title={routeAddressesTitle}>{routeAddressesLabel}</div>,
      startDate: <div title={startDateTitle}>{startDateLabel}</div>,
      status: (
        <TaxiUnsettledPrivateOrderListingTableStatusComponent
          status={listingItem.status}
        />
      ),
      taxiCorporations: (
        <div>
          {listingItem.taxiCorporations.map((taxiCorporation, index) => (
            <div key={index} title={taxiCorporation}>
              {taxiCorporation}
            </div>
          ))}
        </div>
      ),
      actions: (
        <div className="d-flex">
          {isCargoWithTaxiSettlementAddButtonVisible && (
            <TableLinkButtonComponent
              icon={faTaxi}
              to={billingRoutesHelper.getCargoWithTaxiAddRoute({
                orderUuid: listingItem.uuid,
              })}
              title={translations.addCargoWithTaxiSettlementButtonTitle}
              idForTesting={`taxi-unsettled-private-order-listing-table-item-${listingItem.uuid}-add-cargo-taxi-button`}
            />
          )}
          {isCargoWithRailySettlementAddButtonVisible && (
            <TableLinkButtonComponent
              icon={faTruck}
              to={billingRoutesHelper.getCargoWithRailyAddRoute({
                orderUuid: listingItem.uuid,
              })}
              title={translations.addCargoWithRailySettlementButtonTitle}
              idForTesting={`taxi-unsettled-private-order-listing-table-item-${listingItem.uuid}-add-cargo-raily-button`}
            />
          )}
          {isRailyWithTaxiSettlementAddButtonVisible && (
            <TableLinkButtonComponent
              icon={faTaxi}
              to={billingRoutesHelper.getRailyWithTaxiAddRoute({
                orderUuid: listingItem.uuid,
              })}
              title={translations.addRailyWithTaxiSettlementButtonTitle}
              idForTesting={`taxi-unsettled-private-order-listing-table-item-${listingItem.uuid}-add-raily-taxi-button`}
            />
          )}
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: TaxiUnsettledPrivateOrderListingItem[]
): TaxiUnsettledPrivateOrderListingTableRow[] => {
  return listingItems.map(getRow);
};

const taxiUnsettledPrivateOrderListingTableHelper = {
  getColumns,
  getRows,
};

export default taxiUnsettledPrivateOrderListingTableHelper;
