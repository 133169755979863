import MileageDictionaryAddRequest from "../../../api/raily/mileage-dictionary/add/mileage-dictionary-add.request";
import MileageDictionaryAddParams from "./mileage-dictionary-add-params";

const create = (
  params: MileageDictionaryAddParams
): MileageDictionaryAddRequest => {
  return {
    cargoCompanyUuid: params.cargoCompanyUuid,
    name: params.name,
  };
};

const mileageDictionaryAddRequestFactory = {
  create,
};

export default mileageDictionaryAddRequestFactory;
