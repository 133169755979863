import appConfig from "../../../../../../../../../app.config";
import userTranslationsHelper from "../../../../../../../../../languages/user-translations.helper";
import authService from "../../../../../../../../auth/common/auth.service";
import {
  DriverDetailsPlanAddCheckoutErrorType,
  DriverDetailsPlanAddCheckoutDriverNotAcceptableResponseError,
} from "./driver-details-plan-add-checkout-error.response";
import DriverDetailsPlanAddCheckoutRequest from "./driver-details-plan-add-checkout.request";
import DriverDetailsPlanAddCheckoutResponse from "./driver-details-plan-add-checkout.response";

const addCheckout = (
  driverUuid: string,
  planEntryUuid: string,
  request: DriverDetailsPlanAddCheckoutRequest
): Promise<DriverDetailsPlanAddCheckoutResponse> => {
  const path = `/drivers/${driverUuid}/plan-entries/${planEntryUuid}/checkout-events`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const resolveApiErrorLabel = (
  error: DriverDetailsPlanAddCheckoutDriverNotAcceptableResponseError
): string => {
  const errorType = error.error_type;

  const translations =
    userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations()
      .addCheckout;

  switch (errorType) {
    case DriverDetailsPlanAddCheckoutErrorType.DRIVER_HAS_CANDIDATURE:
      return translations.apiErrors.driverHasCandidatureErrorLabel;
    default:
      return translations.failureNotificationText;
  }
};

const driverDetailsPlanAddCheckoutApiService = {
  addCheckout,
  resolveApiErrorLabel,
};

export default driverDetailsPlanAddCheckoutApiService;
