import UserRole from "../common/types/user-role";
import appRoutesDefinition from "./app-routes";
import appRoutesUrls from "./app-routes-urls";
import RouteItem from "./types/route-item";
import RouteItemDefinition from "./types/route-item-definition";

const getMainRoute = (): string => {
  return appRoutesUrls.main;
};

const checkUserAccessToRoute = (
  userRoles: UserRole[],
  routeDefinitionItem: RouteItemDefinition
) => {
  return (
    userRoles.some((userRole) =>
      routeDefinitionItem.userRolesWhitelist?.includes(userRole)
    ) || !routeDefinitionItem.userRolesWhitelist
  );
};

const getRouteItems = (userRoles: UserRole[]): RouteItem[] => {
  const routes: RouteItem[] = appRoutesDefinition.map((routeDefinitionItem) => {
    const hasAccess = checkUserAccessToRoute(userRoles, routeDefinitionItem);

    return {
      path: routeDefinitionItem.path,
      component: routeDefinitionItem.component,
      hasPermission: hasAccess,
    };
  });

  return routes;
};

const appRoutesHelper = {
  getMainRoute,
  getRouteItems,
};

export default appRoutesHelper;
