import { MessengerPostChannel } from "../post/messenger-post";

export enum MessengerSendPostLoadParamsBillingType {
  CARGO = "CARGO",
  TAXI = "TAXI",
}

type MessengerSendPostLoadParams = {
  channel: MessengerPostChannel;
  content: string;
  billingType?: MessengerSendPostLoadParamsBillingType;
  billingUuid?: string;
  orderUuid?: string;
  planEntryUuid?: string;
  solvedOrderUuid?: string;
  taxiCorporationUuid?: string;
};

export default MessengerSendPostLoadParams;
