import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../button/button.component";

type MapAddressSelectorAddressTooltipProps = {
  text: string;
  onConfirm: () => void;
};

const MapAddressSelectorMessageBoxComponent: FC<
  MapAddressSelectorAddressTooltipProps
> = (props) => {
  return (
    <div className={"map-address-selector-message-box"}>
      <p className={"map-address-selector-message-box-text"} title={props.text}>
        {props.text}
      </p>
      <ButtonComponent onClick={props.onConfirm} type="success">
        <FontAwesomeIcon icon={faCheck} />
      </ButtonComponent>
    </div>
  );
};

export default MapAddressSelectorMessageBoxComponent;
