import ListingFilterBadge from "../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import taxiTranslationsHelper from "../../../../../../languages/taxi-translations.helper";
import TaxiCargoContractListingFilter, {
  TaxiCargoContractListingBillingModelFilter,
  TaxiCargoContractListingCargoCompanyFilter,
} from "../../common/types/taxi-cargo-contract-listing-filter";
import TaxiCargoContractListingFilterType from "../../common/types/taxi-cargo-contract-listing-filter-type";

const getBadgeOptions = (filter: TaxiCargoContractListingFilter) => {
  const translations =
    taxiTranslationsHelper.getCargoContractListingTranslations().filters;

  const options: {
    badge: ListingFilterBadge;
    filter: TaxiCargoContractListingFilter;
  }[] = [
    {
      badge: {
        text: translations.billingModelFilterText.replace(
          "#{option}",
          String(filter.value)
        ),
        title: translations.billingModelFilterText.replace(
          "#{option}",
          String(filter.value)
        ),
      },
      filter: {
        type: TaxiCargoContractListingFilterType.BILLING_MODEL,
        value:
          filter.value as TaxiCargoContractListingBillingModelFilter["value"],
      },
    },
    {
      badge: {
        text: translations.cargoCompanyFilterText.replace(
          "#{query}",
          String(filter.value)
        ),
        title: translations.cargoCompanyFilterTitle.replace(
          "#{query}",
          String(filter.value)
        ),
      },
      filter: {
        type: TaxiCargoContractListingFilterType.CARGO_COMPANY,
        value:
          filter.value as TaxiCargoContractListingCargoCompanyFilter["value"],
      },
    },
  ];

  return options;
};

const getBadge = (
  filter: TaxiCargoContractListingFilter
): ListingFilterBadge | undefined => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: TaxiCargoContractListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const taxiCargoContractListingFiltersBadgeListHelper = {
  getBadges,
};

export default taxiCargoContractListingFiltersBadgeListHelper;
