import taxiRoutesUrls from "./taxi-routes-urls";
import TaxiCargoContractAddRouteParams from "./types/taxi-cargo-contract-add-route-params";
import TaxiCargoContractEditRouteParams from "./types/taxi-cargo-contract-edit-route-params";
import TaxiCargoContractListingRouteParams from "./types/taxi-cargo-contract-listing-route-params";
import TaxiFleetPartnerAddRouteParams from "./types/taxi-fleet-partner-add-route-params";
import TaxiFleetPartnerEditRouteParams from "./types/taxi-fleet-partner-edit-route-params";
import TaxiFleetPartnerListingRouteParams from "./types/taxi-fleet-partner-listing-route-params";
import TaxiTaxiContractEditRouteParams from "./types/taxi-taxi-contract-edit-route-params";
import TaxiTaxiContractListingRouteParams from "./types/taxi-taxi-contract-listing-route-params";

const getAddRoute = (): string => {
  return taxiRoutesUrls.add;
};

const getListingRoute = (): string => {
  return taxiRoutesUrls.listing;
};

const getCargoContractAddRoute = (
  params: TaxiCargoContractAddRouteParams
): string => {
  return taxiRoutesUrls.cargoContractAdd.replace(
    ":taxiCorporationUuid",
    params.taxiCorporationUuid
  );
};

const getCargoContractListingRoute = (
  params: TaxiCargoContractListingRouteParams
): string => {
  return taxiRoutesUrls.cargoContractListing.replace(
    ":taxiCorporationUuid",
    params.taxiCorporationUuid
  );
};

const getCargoContractEditRoute = (
  params: TaxiCargoContractEditRouteParams
): string => {
  return taxiRoutesUrls.cargoContractEdit
    .replace(":taxiCorporationUuid", params.taxiCorporationUuid)
    .replace(":contractUuid", params.contractUuid);
};

const getTaxiContractAddRoute = (
  params: TaxiCargoContractAddRouteParams
): string => {
  return taxiRoutesUrls.taxiContractAdd.replace(
    ":taxiCorporationUuid",
    params.taxiCorporationUuid
  );
};

const getTaxiContractEditRoute = (
  params: TaxiTaxiContractEditRouteParams
): string => {
  return taxiRoutesUrls.taxiContractEdit
    .replace(":taxiCorporationUuid", params.taxiCorporationUuid)
    .replace(":contractUuid", params.contractUuid);
};

const getTaxiContractListingRoute = (
  params: TaxiTaxiContractListingRouteParams
): string => {
  return taxiRoutesUrls.taxiContractListing.replace(
    ":taxiCorporationUuid",
    params.taxiCorporationUuid
  );
};

const getFleetPartnerAddRoute = (
  params: TaxiFleetPartnerAddRouteParams
): string => {
  return taxiRoutesUrls.taxiFleetPartnerAdd.replace(
    ":taxiCorporationUuid",
    params.taxiCorporationUuid
  );
};

const getFleetPartnerListingRoute = (
  params: TaxiFleetPartnerListingRouteParams
): string => {
  return taxiRoutesUrls.taxiFleetPartnerListing.replace(
    ":taxiCorporationUuid",
    params.taxiCorporationUuid
  );
};

const getTaxiFleetPartnerEditRoute = (
  params: TaxiFleetPartnerEditRouteParams
): string => {
  return taxiRoutesUrls.taxiFleetPartnerEdit
    .replace(":taxiCorporationUuid", params.taxiCorporationUuid)
    .replace(":fleetPartnerUuid", params.fleetPartnerUuid);
};

const taxiRoutesHelper = {
  getAddRoute,
  getListingRoute,
  getCargoContractAddRoute,
  getCargoContractEditRoute,
  getCargoContractListingRoute,
  getTaxiContractAddRoute,
  getTaxiContractEditRoute,
  getTaxiContractListingRoute,
  getFleetPartnerListingRoute,
  getFleetPartnerAddRoute,
  getTaxiFleetPartnerEditRoute,
};

export default taxiRoutesHelper;
