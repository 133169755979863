import HttpResponse from "../../../../utils/http/http.response";

export enum DispatcherListCargoContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type DispatcherListResponseItemCargoCompany = {
  id: string;
  taxId: string;
  regon: string;
  krsNo: string;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: DispatcherListCargoContractMaintenancePolicy;
  notes: string | null;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
};

export type DispatcherListResponseItemDispatch = {
  dispatchId: string;
  dispatch: DispatcherListResponseItemDispatcher | null;
};

export type DispatcherListResponseItemDispatcher = {
  id: string;
  dispatchName: string;
  displayName: string;
  addressString: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  cargoCompany: DispatcherListResponseItemCargoCompany;
};

export type DispatcherListResponseItem = {
  removedBy: string | null;
  removedAt: Date | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
  dispatcher: DispatcherListResponseItemDispatch;
};

type DispatcherListResponse = HttpResponse<{
  data: DispatcherListResponseItem[];
  totalCount: number;
}>;

export default DispatcherListResponse;
