import MapRoute from "../../../../../common/components/map/types/map-route";
import { SearchRoadRoutingResponseRouteGeometryCoordinate } from "../../../../../common/utils/search-road-route/search-road-routing.response";

const createMapRoute = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute => {
  return {
    waypoints: routesResponseGeometryCoordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
  };
};

const billingMapRoutesFactory = {
  createMapRoute,
};

export default billingMapRoutesFactory;
