import BillingsOrderTaxiTaxiResponse, {
  BillingsOrderTaxiTaxiResponseItem,
} from "../../../../api/raily/billings/order/taxi-taxi/billings-order-taxi-taxi.response";
import BillingModel from "../../../../types/billing-model";
import BillingStatus from "../../../../types/billing-status";
import BillingsOrderTaxiTaxi, {
  BillingsOrderTaxiTaxiItem,
} from "./billings-order-taxi-taxi";

const createDataItem = (
  data: BillingsOrderTaxiTaxiResponseItem
): BillingsOrderTaxiTaxiItem => {
  return {
    billingUuid: data.id,
    forwardingUuid: data.orderForwardingAction.id,
    contractDetails: {
      companyName: data.orderForwardingAction.contract.consumerTaxi.displayName,

      distanceRate: data.orderForwardingAction.contract.distanceRate,
      model: data.orderForwardingAction.contract.model as BillingModel,
    },
    baseAmountValue: data.baseAmountValue,
    amountForChargeHaltings: data.amountForChargeHaltings,
    amountForChargeHighways: data.amountForChargeHighways,
    distance: data.distance,
    amountForDistance: data.amountForDistance,
    internalOrderId:
      data.orderForwardingAction.transportingOrder.cargoOrder.humanId,
    allContributionsAmount: data.allContributionsAmount,
    isDraft: data.draft,
    date: data.date,
    sumOfBonuses: data.sumOfBonuses,
    sumOfPenalties: data.sumOfPenalties,
    status: data.status as BillingStatus,
    readOnly: data.readOnly,
  };
};

const createData = (
  responseData: BillingsOrderTaxiTaxiResponseItem[]
): BillingsOrderTaxiTaxiItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: BillingsOrderTaxiTaxiResponse
): BillingsOrderTaxiTaxi => {
  return {
    data: createData(response.data),
  };
};

const billingsOrderTaxiTaxiFactory = {
  create,
};

export default billingsOrderTaxiTaxiFactory;
