import MileageDictionaryUpdateRequest from "../../../api/raily/mileage-dictionary/update/mileage-dictionary-update.request";
import MileageDictionaryUpdateParams from "./mileage-dictionary-update-params";

const create = (
  params: MileageDictionaryUpdateParams
): MileageDictionaryUpdateRequest => {
  return {
    cargoCompanyId: params.cargoCompanyUuid,
    recordId: params.recordUuid,
    active: params.active,
    name: params.name,
  };
};

const mileageDictionaryUpdateRequestFactory = {
  create,
};

export default mileageDictionaryUpdateRequestFactory;
