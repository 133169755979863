import UserRole from "../../../../common/types/user-role";
import { OrderDetailsCargoOrderChangesApprovals } from "../common/types/order-details-cargo-order";
import orderDetailsUserPermissionsHelper from "../common/user-permissions/order-details-user-permission.helper";
import OrderDetailsChangesApprovalOptionType from "./common/types/order-details-changes-approval-option";

const getApproveOptions = (
  userRoles: UserRole[],
  changeApprovals: OrderDetailsCargoOrderChangesApprovals
) => {
  const userPermissions =
    orderDetailsUserPermissionsHelper.getPermissions(userRoles);

  const options: {
    userHasPermission: boolean;
    isApproved: boolean;
    type: OrderDetailsChangesApprovalOptionType;
  }[] = [
    {
      userHasPermission: userPermissions.hasAccessToApproveChangesAsDealer,
      isApproved: !changeApprovals.isDealerApproveNeeded,
      type: OrderDetailsChangesApprovalOptionType.BY_DEALER,
    },
    {
      userHasPermission: userPermissions.hasAccessToApproveChangesAsDispatcher,
      isApproved: !changeApprovals.isDispatcherApproveNeeded,
      type: OrderDetailsChangesApprovalOptionType.BY_DISPATCHER,
    },
    {
      userHasPermission: userPermissions.hasAccessToApproveChangesAsOperator,
      isApproved: !changeApprovals.isOperatorApproveNeeded,
      type: OrderDetailsChangesApprovalOptionType.BY_OPERATOR,
    },
  ];

  return options;
};

const checkHasUserPermissionToApproveMany = (
  userRoles: UserRole[],
  changeApprovals: OrderDetailsCargoOrderChangesApprovals
): boolean => {
  const options = getApproveOptions(userRoles, changeApprovals);

  return options.filter((item) => item.userHasPermission === true).length >= 2;
};

const checkAreAllPermittedOptionsApproved = (
  userRoles: UserRole[],
  changeApprovals: OrderDetailsCargoOrderChangesApprovals
): boolean => {
  const options = getApproveOptions(userRoles, changeApprovals);

  return options
    .filter((item) => item.userHasPermission)
    .every((item) => item.isApproved);
};

const getPermittedOperationTypes = (
  userRoles: UserRole[],
  changeApprovals: OrderDetailsCargoOrderChangesApprovals
): OrderDetailsChangesApprovalOptionType[] => {
  const options = getApproveOptions(userRoles, changeApprovals);

  return options
    .filter((option) => option.userHasPermission)
    .map((option) => option.type);
};

const orderDetailsChangesApprovalHelper = {
  checkHasUserPermissionToApproveMany,
  checkAreAllPermittedOptionsApproved,
  getPermittedOperationTypes,
};

export default orderDetailsChangesApprovalHelper;
