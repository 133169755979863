import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import settlementTranslationsHelper from "../../../../../../../languages/settlement-translations.helper";
import taxiUnsettledOrderListingHelper from "../../taxi-unsettled-order-listing.helper";
import TaxiUnsettledOrderListingFilter, {
  TaxiUnsettledOrderListingStatusFilter,
  TaxiUnsettledOrderListingClientFilter,
  TaxiUnsettledOrderListingDispatchFilter,
  TaxiUnsettledOrderListingDriverFilter,
  TaxiUnsettledOrderListingInternalOrderIdFilter,
  TaxiUnsettledOrderListingExternalOrderIdFilter,
  TaxiUnsettledOrderListingPassengerFilter,
  TaxiUnsettledOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledOrderListingRoutePickupAddressFilter,
  TaxiUnsettledOrderListingStartDateFilter,
  TaxiUnsettledOrderListingTaxiFilter,
} from "../../types/taxi-unsettled-order-listing-filter";
import TaxiUnsettledOrderListingFilterType from "../../types/taxi-unsettled-order-listing-filter-type";
import TaxiUnsettledOrderListingItemStatus from "../../types/taxi-unsettled-order-listing-item-status";

const getStartDateBadgeOption = (
  filter: TaxiUnsettledOrderListingStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: TaxiUnsettledOrderListingStartDateFilter;
} => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledOrderListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.startDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.startDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: TaxiUnsettledOrderListingFilterType.START_DATE,
      value: filter.value,
    },
  };
};

const getStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: TaxiUnsettledOrderListingStatusFilter;
}[] => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledOrderListingTranslations()
      .filters;

  return Object.values(TaxiUnsettledOrderListingItemStatus).map(
    (statusFilterValue) => {
      return {
        badge: {
          text: translations.statusFilterText.replace(
            "#{option}",
            taxiUnsettledOrderListingHelper.getStatusText(statusFilterValue)
          ),
          title: translations.statusFilterTitle.replace(
            "#{option}",
            taxiUnsettledOrderListingHelper.getStatusText(statusFilterValue)
          ),
        },
        filter: {
          type: TaxiUnsettledOrderListingFilterType.STATUS,
          value: statusFilterValue,
        },
      };
    }
  );
};

const getBadgeOptions = (filter: TaxiUnsettledOrderListingFilter) => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledOrderListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: TaxiUnsettledOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.clientNameFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingClientFilter["value"]
        ),
        title: translations.clientNameFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingClientFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.CLIENT,
        value: filter.value as TaxiUnsettledOrderListingClientFilter["value"],
      },
    },
    {
      badge: {
        text: translations.dispatchNameFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingDispatchFilter["value"]
        ),
        title: translations.dispatchNameFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingDispatchFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.DISPATCH,
        value: filter.value as TaxiUnsettledOrderListingDispatchFilter["value"],
      },
    },
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingDriverFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.DRIVER,
        value: filter.value as TaxiUnsettledOrderListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledOrderListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledOrderListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as TaxiUnsettledOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.externalOrderIdFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingExternalOrderIdFilter["value"]
        ),
        title: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingExternalOrderIdFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.EXTERNAL_ORDER_ID,
        value:
          filter.value as TaxiUnsettledOrderListingExternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.PASSENGER,
        value:
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as TaxiUnsettledOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as TaxiUnsettledOrderListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as TaxiUnsettledOrderListingRoutePickupAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.taxiFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingTaxiFilter["value"]
        ),
        title: translations.taxiFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledOrderListingTaxiFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledOrderListingFilterType.TAXI,
        value: filter.value as TaxiUnsettledOrderListingTaxiFilter["value"],
      },
    },
    getStartDateBadgeOption(filter as TaxiUnsettledOrderListingStartDateFilter),
    ...getStatusBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: TaxiUnsettledOrderListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: TaxiUnsettledOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const taxiUnsettledOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default taxiUnsettledOrderListingFiltersBadgeListHelper;
