import MileageDictionaryListRequest, {
  MileageDictionaryListRequestQueryParams,
} from "./mileage-dictionary-list.request";

const createQueryParams = (
  request: MileageDictionaryListRequest
): MileageDictionaryListRequestQueryParams => {
  return {
    active: request.active,
    limit: request.limit,
    offset: request.offset,
  };
};

const mileageDictioanryListRequestFactory = {
  createQueryParams,
};

export default mileageDictioanryListRequestFactory;
