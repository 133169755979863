enum CargoAddressListingSortKey {
  ADDRESS_DESCRIPTION_ASC = "ADDRESS_DESCRIPTION_ASC",
  ADDRESS_DESCRIPTION_DESC = "ADDRESS_DESCRIPTION_DESC",
  ADDRESS_COUNTRY_ASC = "ADDRESS_COUNTRY_ASC",
  ADDRESS_COUNTRY_DESC = "ADDRESS_COUNTRY_DESC",
  ADDRESS_TOWN_ASC = "ADDRESS_TOWN_ASC",
  ADDRESS_TOWN_DESC = "ADDRESS_TOWN_DESC",
  ADDRESS_STREET_ASC = "ADDRESS_STREET_ASC",
  ADDRESS_STREET_DESC = "ADDRESS_STREET_DESC",
}

export default CargoAddressListingSortKey;
