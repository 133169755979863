import HttpResponse from "../../../../utils/http/http.response";

export enum DelegationDetailsResponseItemState {
  NEW = "NEW",
  DOWNLOADED = "DOWNLOADED",
  DELETED = "DELETED",
}

export enum DelegationDetailsResponseItemType {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum DelegationDetailsResponseItemVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type DelegationDetailsResponseItemAddressSequnenceNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
};

export type DelegationDetailsResponseItemAddressSequenceNodeGroup = {
  nodes: DelegationDetailsResponseItemAddressSequnenceNode[];
  vehicleType: DelegationDetailsResponseItemVehicleType;
};

export type DelegationDetailsResponseItemAddressSequnence = {
  seq: DelegationDetailsResponseItemAddressSequenceNodeGroup[];
};

export type DelegationDetailsResponseItemMileageData = {
  mileageDate: Date;
  cardNumber?: string;
  commissionNumber?: string;
  mileageNumber?: string;
  humanId: number;
};

export type DelegationDetailsResponseItem = {
  downloadedCsvAt?: Date;
  downloadedCsvBy?: string;
  downloadedPdfAt?: Date;
  downloadedPdfBy?: string;
  createdAt: Date;
  createdBy: string;
  id: string;
  delegationNumber: string;
  state: DelegationDetailsResponseItemState;
  type: DelegationDetailsResponseItemType;
  workerName: string;
  workerPhone: string;
  workerId: string;
  dateFrom: Date;
  dateTo: Date;
  totalCost: number;
  humanId: number;
  addressSeq: DelegationDetailsResponseItemAddressSequnence;
  mileageData: DelegationDetailsResponseItemMileageData[];
};

type DelegationDetailsResponse = HttpResponse<DelegationDetailsResponseItem>;

export default DelegationDetailsResponse;
