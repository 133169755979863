import { faEllipsisVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import orderAddPassengerHelper from "./order-add-passenger.helper";
import debounce from "lodash/debounce";
import classNames from "classnames";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import orderAddApiService from "../../api/order-add-api.service";
import OrderAddPassengersRequest from "../../api/order-add-passengers.request";
import OrderAddPassengersResponse, {
  OrderAddPassengersResponseItem,
} from "../../api/order-add-passengers.response";
import orderAddFactory from "../../factory/order-add.factory";
import OrderAddCargoCompany from "../../types/order-add-cargo-company";
import OrderAddPassengerListItem from "../../types/order-add-passenger-list-item";
import OrderAddPassengerSelectOption from "../../types/order-add-passenger-select-option";
import OrderAddRoutesPassengerIconComponent from "../passenger-icon/order-add-routes-passenger-icon.component";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import OrderAddPassenger from "../../types/order-add-passenger";

type OrderAddPassengersProps = {
  passengerList: OrderAddPassengerListItem[];
  onPassengerListChange: (passengerList: OrderAddPassengerListItem[]) => void;
  selectedPassengerUuid: string | null;
  onSelectedPassengerUuidChange: (selectedPassengerUuid: string | null) => void;
  contractorUuid: OrderAddCargoCompany["uuid"] | null;
};

const OrderAddPassengersComponent: FC<OrderAddPassengersProps> = (props) => {
  const translations = orderTranslationsHelper.getAddTranslations().passengers;

  const [arePassengersFetching, setArePassengersFetching] = useState(false);

  const [isPassengersFetchingError, setIsPassengersFetchingError] =
    useState(false);

  const [passengersSelectOptions, setPassengersSelectOptions] = useState<
    OrderAddPassengerSelectOption[]
  >([]);

  const [passengerSearchQuery, setPassengerSearchQuery] = useState("");

  const onPassengersFetchSuccess = (
    responsePassengers: OrderAddPassengersResponseItem[]
  ) => {
    const passengersSelectOptions =
      orderAddFactory.createPassengerSelectOptions(responsePassengers);

    setPassengersSelectOptions(passengersSelectOptions);
  };

  const handlePassengersResponse = (response: OrderAddPassengersResponse) => {
    if (response.status === 200) {
      onPassengersFetchSuccess(response.data);
      return;
    }

    setIsPassengersFetchingError(true);
  };

  const fetchPassengersDebounced = useCallback(
    debounce(
      (selectedCargoCompanyUuid: string, passengerSearchQuery: string) => {
        const request: OrderAddPassengersRequest = {
          search_query: passengerSearchQuery,
        };

        orderAddApiService
          .fetchPassengers(selectedCargoCompanyUuid, request)
          .then(handlePassengersResponse)
          .finally(() => {
            setArePassengersFetching(false);
          });
      },
      500
    ),
    []
  );

  useEffect(() => {
    if (!props.contractorUuid || !passengerSearchQuery) {
      setPassengersSelectOptions([]);
      return;
    }

    setArePassengersFetching(true);
    fetchPassengersDebounced(props.contractorUuid, passengerSearchQuery);

    return () => {
      fetchPassengersDebounced.cancel();
      setArePassengersFetching(false);
    };
  }, [props.contractorUuid, passengerSearchQuery, fetchPassengersDebounced]);

  const filteredPassengerSelectOptions = passengersSelectOptions.filter(
    (option) =>
      !props.passengerList.find(
        (passengerListItem) =>
          passengerListItem.passenger.uuid === option.value.uuid
      )
  );

  const addPassenger = (passenger: OrderAddPassenger) => {
    const newPassengerListItem: OrderAddPassengerListItem = {
      uuid: uuidService.generate(),
      passenger,
    };

    const newPassengerList: OrderAddPassengerListItem[] = [
      ...props.passengerList,
      newPassengerListItem,
    ];

    props.onPassengerListChange(newPassengerList);
  };

  const onDeletePassengerButtonClick = (
    passengerListItemUuid: OrderAddPassengerListItem["uuid"]
  ) => {
    const newPassengerList: OrderAddPassengerListItem[] =
      props.passengerList.filter(
        (passengerListItem) => passengerListItem.uuid !== passengerListItemUuid
      );

    props.onPassengerListChange(newPassengerList);
  };

  const getReorderedPassengerList = (
    startIndex: number,
    endIndex: number
  ): OrderAddPassengerListItem[] => {
    const result = [...props.passengerList];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragPassengerListItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedPassengerList = getReorderedPassengerList(
      result.source.index,
      result.destination.index
    );

    props.onPassengerListChange(reorderedPassengerList);
  };

  const isDraggingEnabled = props.passengerList.length > 1;

  const isSearchSelectDisabled =
    isPassengersFetchingError || !props.contractorUuid;

  const onPassengerIconClick = (
    passengerListItem: OrderAddPassengerListItem
  ) => {
    if (props.selectedPassengerUuid === passengerListItem.passenger.uuid) {
      props.onSelectedPassengerUuidChange(null);
      return;
    }
    props.onSelectedPassengerUuidChange(passengerListItem.passenger.uuid);
  };

  return (
    <FormFieldComponent label={translations.headingText} isRequired>
      <DragDropContext onDragEnd={onDragPassengerListItemEnd}>
        <Droppable droppableId="order_add_passenger_list_drop_area">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div
                className="order_add_passenger_list"
                data-test-id="passenger-list"
              >
                {props.passengerList.map((passengerListItem, index) => {
                  const isSelected =
                    props.selectedPassengerUuid ===
                    passengerListItem.passenger.uuid;

                  return (
                    <Draggable
                      key={passengerListItem.uuid}
                      draggableId={passengerListItem.uuid}
                      index={index}
                      isDragDisabled={!isDraggingEnabled}
                    >
                      {(provided) => (
                        <div
                          className={classNames(
                            "order_add_passenger_list_item",
                            isSelected && `selected`
                          )}
                          key={`passenger-list-item-${passengerListItem.uuid}`}
                          ref={provided.innerRef}
                          data-test-id={`passenger-list-item-${passengerListItem.uuid}`}
                          {...provided.draggableProps}
                        >
                          <button
                            className="order_add_passenger_list_item__drag_button"
                            {...provided.dragHandleProps}
                            title={translations.dragButtonTitle}
                            data-test-id={`passenger-list-item-${passengerListItem.uuid}-drag-button`}
                          >
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </button>

                          <div className="order_add_passenger_list_item__index">
                            <ButtonComponent
                              onClick={() =>
                                onPassengerIconClick(passengerListItem)
                              }
                              classNames={{ root: "p-0" }}
                              idForTesting={`passenger-list-item-${passengerListItem.uuid}-select-button`}
                            >
                              <OrderAddRoutesPassengerIconComponent
                                number={index + 1}
                              />
                            </ButtonComponent>
                          </div>
                          <div
                            className="order_add_passenger_list_item__passenger_name"
                            title={orderAddPassengerHelper.getPassengerTitle(
                              passengerListItem.passenger
                            )}
                          >
                            {orderAddPassengerHelper.getPassengerLabel(
                              passengerListItem.passenger
                            )}
                          </div>
                          <ButtonComponent
                            onClick={() =>
                              onDeletePassengerButtonClick(
                                passengerListItem.uuid
                              )
                            }
                            classNames={{
                              root: "order_add_passenger_list_item__action_button",
                            }}
                            idForTesting={`passenger-list-item-${passengerListItem.uuid}-delete-button`}
                            title={translations.deletePassengerButtonTitle}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </ButtonComponent>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="order_add_passenger_list_item m-0">
        <SingleSelectComponent
          classNames={{ root: "order_add_passenger_list_item__select" }}
          placeholder={translations.selectPlaceholder}
          value={null}
          onChange={(value: OrderAddPassengerSelectOption | null) => {
            if (!value) {
              return;
            }

            addPassenger(value.value);
          }}
          idForTesting="passenger-select"
          options={filteredPassengerSelectOptions}
          isLoading={arePassengersFetching}
          isDisabled={isSearchSelectDisabled}
          isSearchable
          filterFunction={() => true}
          inputValue={passengerSearchQuery}
          onInputChange={setPassengerSearchQuery}
          noOptionsMessage={(inputValue) => {
            if (inputValue) {
              return translations.searchNoOptionsMessage;
            }
            return translations.searchTipMessage;
          }}
        />
      </div>
    </FormFieldComponent>
  );
};

export default OrderAddPassengersComponent;
