export enum DelegationAddParamsTimezone {
  EUROPE_WARSAW = "Europe/Warsaw",
  UTC = "UTC",
}

export enum DelegationAddParamsVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type DelegationAddParamsAddress = {
  latitude: number;
  longitude: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
  distance?: number;
};

type DelegationAddParams = {
  workerUuid: string;
  workerCompanyUuid: string;
  mileagesCost: number;
  totalDistance: number;
  dateFrom: Date;
  dateTo: Date;
  addressSequence: DelegationAddParamsAddress[][];
  groupVehicleTypes: DelegationAddParamsVehicleType[];
  osrmDistance?: number;
  prepay?: number;
  localTravelCost?: number;
  oneDayDietCost?: number;
  wholeDayDietCost?: number;
  startedDayDietCost?: number;
  accommodationBillsCost?: number;
  accommodationCost?: number;
  otherExpenses?: number;
  timezone?: DelegationAddParamsTimezone;
};

export default DelegationAddParams;
