import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import DriverAddFleetPartnersResponse from "./driver-add-fleet-partners.response";
import DriverAddRequest from "./driver-add.request";
import DriverAddResponse from "./driver-add.response";

const addDriver = async (
  request: DriverAddRequest
): Promise<DriverAddResponse> => {
  const path = `/drivers`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchFleetPartners = async (
  taxiCorporationUuid: string
): Promise<DriverAddFleetPartnersResponse> => {
  const path = `/taxi-corporations/${taxiCorporationUuid}/fleet-partners`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const driverAddApiService = {
  addDriver,
  fetchFleetPartners,
};

export default driverAddApiService;
