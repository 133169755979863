import useAsyncData from "../../../../hooks/use-async-data";
import cargoCompanyMileageContractService from "../cargo-company-mileage-contract.service";
import CargoCompanyMileageContractDetails from "./cargo-company-mileage-contract-details";
import CargoCompanyMileageContractDetailsLoadParams from "./cargo-company-mileage-contract-details-load-params";

const useCargoCompanyMileageContractDetails = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<CargoCompanyMileageContractDetails | null>(null);

  const load = async (
    loadParams: CargoCompanyMileageContractDetailsLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await cargoCompanyMileageContractService.getDetails(
        loadParams,
        signal
      );

      setData(data);
    } catch {
      setData(null);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useCargoCompanyMileageContractDetails;
