import { SearchAddressResponseItem } from "../../../../../common/utils/search-address/search-address.response";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import CargoAddressFormData from "../../common/types/cargo-address-form-data";
import CargoAddressType from "../../common/types/cargo-address-type";
import CargoAddressTypeSelectOption from "../../common/types/cargo-address-type-select-option";
import CargoAddressEditUpdateRequest, {
  CargoAddressEditRequestAddressType,
} from "./api/types/cargo-address-edit-update.request";
import {
  CargoAddressEditResponseItem,
  CargoAddressEditResponseItemAddressType,
} from "./api/types/cargo-address-edit.response";
import CargoAddressEditAddress from "./types/cargo-address-edit-address";
import CargoAddressEditAddressFormData from "./types/cargo-address-edit-address-form-data";
import CargoAddressEditAddressSelectOption from "./types/cargo-address-edit-address-select-option";
import CargoAddressEditFormCoordinates from "./types/cargo-address-edit-form-coordinates";

const createAddress = (
  searchAddressResponseItem: SearchAddressResponseItem
): CargoAddressEditAddress => {
  const address: CargoAddressEditAddress = {
    displayName: searchAddressResponseItem.display_name,
    apartmentNumber: "",
    country: searchAddressResponseItem.address.country,
    houseNumber: searchAddressResponseItem.address.house_number ?? "",
    latitude: Number(searchAddressResponseItem.lat),
    longitude: Number(searchAddressResponseItem.lon),
    street: searchAddressResponseItem.address.road ?? "",
    town:
      searchAddressResponseItem.address.city ??
      searchAddressResponseItem.address.town ??
      searchAddressResponseItem.address.village ??
      "",
    zipCode: searchAddressResponseItem.address.postcode ?? "",
  };

  return address;
};

const createAddressSelectOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): CargoAddressEditAddressSelectOption => {
  const address = createAddress(searchAddressResponseItem);

  return {
    label: searchAddressResponseItem.display_name,
    value: address,
  };
};

const createAddressSelectOptions = (
  searchAddressResponseItems: SearchAddressResponseItem[]
): CargoAddressEditAddressSelectOption[] => {
  return searchAddressResponseItems.map(createAddressSelectOption);
};

const createEditCargoAddressUpdateRequestAddressType = (
  addressType: CargoAddressType
): CargoAddressEditRequestAddressType => {
  switch (addressType) {
    case CargoAddressType.HOTEL:
      return CargoAddressEditRequestAddressType.HOTEL;
    case CargoAddressType.MEETING_POINT:
      return CargoAddressEditRequestAddressType.MEETING_POINT;
    case CargoAddressType.STATION:
      return CargoAddressEditRequestAddressType.STATION;
  }
};

const createEditCargoAddressUpdateRequest = (
  formData: CargoAddressFormData,
  addressCoordinates: CargoAddressEditFormCoordinates
): CargoAddressEditUpdateRequest => {
  return {
    lat: addressCoordinates.latitude,
    lon: addressCoordinates.longitude,
    country: formData.country,
    town: formData.town,
    zip_code: formData.zipCode,
    street: formData.street,
    house_no: formData.houseNumber,
    apartment: formData.apartmentNumber,
    description: formData.description,
    type: createEditCargoAddressUpdateRequestAddressType(
      formData.addressType?.value!
    ),
  };
};

const createEditCargoAddressResponseAddressType = (
  addressType: CargoAddressEditResponseItemAddressType
): CargoAddressType => {
  switch (addressType) {
    case CargoAddressEditResponseItemAddressType.HOTEL:
      return CargoAddressType.HOTEL;
    case CargoAddressEditResponseItemAddressType.MEETING_POINT:
      return CargoAddressType.MEETING_POINT;
    case CargoAddressEditResponseItemAddressType.STATION:
      return CargoAddressType.STATION;
  }
};

const createAddressTypeSelectOption = (
  responseAddressType: CargoAddressEditResponseItemAddressType
): CargoAddressTypeSelectOption => {
  const translations =
    cargoTranslationsHelper.getAddressEditTranslations().address.addressType;

  const addressType =
    createEditCargoAddressResponseAddressType(responseAddressType);
  return {
    label: translations[addressType],
    value: addressType,
  };
};

const recreateAddressTypeSelectOption = (
  item: CargoAddressType
): CargoAddressTypeSelectOption => {
  const translations =
    cargoTranslationsHelper.getAddressEditTranslations().address.addressType;

  return {
    label: translations[item],
    value: item,
  };
};

const createFormData = (
  responseItem: CargoAddressEditResponseItem
): CargoAddressEditAddressFormData => {
  return {
    latitude: responseItem.lat,
    longitude: responseItem.lon,
    country: responseItem.country ?? "",
    town: responseItem.town ?? "",
    zipCode: responseItem.zip_code ?? "",
    street: responseItem.street ?? "",
    houseNumber: responseItem.house_no ?? "",
    apartmentNumber: responseItem.apartment ?? "",
    displayName: responseItem.display_name,
    description: responseItem.description ?? "",
    addressType: createAddressTypeSelectOption(responseItem.type),
  };
};

const cargoAddressEditFactory = {
  createAddress,
  createAddressSelectOptions,
  createEditCargoAddressUpdateRequest,
  createFormData,
  createAddressTypeSelectOption,
  recreateAddressTypeSelectOption,
};

export default cargoAddressEditFactory;
