import TaxiCorporationListRequest from "../../api/raily/taxi-corporation/list/taxi-corporation-list.request";
import taxiCorporationApiService from "../../api/raily/taxi-corporation/taxi-corporation-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import TaxiCorporationDetailsError from "./details/taxi-corporation-details-error";
import TaxiCorporationDetailsLoadParams from "./details/taxi-corporation-details-load-params";
import taxiCorporationDetailsFactory from "./details/taxi-corporation-details.factory";
import TaxiCorporationListError from "./list/taxi-corporation-list-error";
import TaxiCorporationList from "./list/taxi-corporation-list";
import TaxiCorporationListLoadParams from "./list/taxi-corporation-list-load-params";
import taxiCorporationListRequestFactory from "./list/taxi-corporation-list-request.factory";
import taxiCorporationListFactory from "./list/taxi-corporation-list.factory";

const handleDetailsError = (error: HttpError | TaxiCorporationDetailsError) => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: TaxiCorporationDetailsLoadParams,
  abortSignal: AbortSignal
) => {
  try {
    const response = await taxiCorporationApiService().getDetails(
      params,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiDetails = taxiCorporationDetailsFactory.create(response);
    return taxiDetails;
  } catch (_error) {
    const error = _error as HttpError | TaxiCorporationDetailsError;

    throw handleDetailsError(error);
  }
};

const handleListError = (
  error: HttpError | TaxiCorporationListError
): TaxiCorporationListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: TaxiCorporationListLoadParams,
  abortSignal: AbortSignal
): Promise<TaxiCorporationList> => {
  const request: TaxiCorporationListRequest =
    taxiCorporationListRequestFactory.create(params);

  try {
    const response = await taxiCorporationApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return taxiCorporationListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | TaxiCorporationListError;

    throw handleListError(error);
  }
};

const taxiCorporationService = {
  getList,
  getDetails,
};

export default taxiCorporationService;
