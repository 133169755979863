import dateService from "../../../../common/utils/date/date.service";
import DelegationDetailsRouteConnection from "../common/types/delegation-details-route-connection";
import DelegationDetailsRouteConnectionLineType from "../common/types/delegation-details-route-connection-line-type";
import DelegationDetailsRouteWaypoint from "../common/types/delegation-details-route-waypoint";

const getDateLabel = (date: Date | undefined): string => {
  if (!date) {
    return ``;
  }

  return dateService.formatDateTime(date);
};

const generateWaypointDotClassname = (
  waypoint: DelegationDetailsRouteWaypoint,
  index: number,
  delegationUuid: string
) => {
  return `dot-mileage-${delegationUuid}-coordinates-${waypoint.place.lat};${waypoint.place.lon}-stageNo-${waypoint.stageNo}-index-${index}`;
};

const getDefinitionOfLineConnectionsBetweenWaypointsInList = (
  waypoints: DelegationDetailsRouteWaypoint[],
  delegationUuid: string
): DelegationDetailsRouteConnection[] => {
  let connectionLineDefinitions: DelegationDetailsRouteConnection[] = [];

  waypoints.forEach((currentWaypoint, index) => {
    if (index == 0) return;

    const previousWaypoint = waypoints[index - 1];

    const areBothWaypointsFromTheSameStage =
      currentWaypoint.stageNo === previousWaypoint.stageNo;

    const lineType = areBothWaypointsFromTheSameStage
      ? DelegationDetailsRouteConnectionLineType.SOLID
      : DelegationDetailsRouteConnectionLineType.DOTTED;

    const newConnectionLineDefinition: DelegationDetailsRouteConnection = {
      itemFromClassname: generateWaypointDotClassname(
        currentWaypoint,
        index,
        delegationUuid
      ),
      itemToClassname: generateWaypointDotClassname(
        previousWaypoint,
        index - 1,
        delegationUuid
      ),
      lineType,
    };

    connectionLineDefinitions.push(newConnectionLineDefinition);
  });

  return connectionLineDefinitions;
};

const delegationDetailsRouteHelper = {
  getDateLabel,
  getDefinitionOfLineConnectionsBetweenWaypointsInList,
  generateWaypointDotClassname,
};

export default delegationDetailsRouteHelper;
