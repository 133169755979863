import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import CargoTaxiContractListingResponse from "./types/cargo-taxi-contract-listing.response";

const fetchListing = async (
  cargoCompanyUuid: string
): Promise<CargoTaxiContractListingResponse> => {
  const path = `${appConfig.baseApiUrl}/cargo-companies/${cargoCompanyUuid}/cargo-taxi/contracts`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const cargoTaxiContractListingApiService = { fetchListing };

export default cargoTaxiContractListingApiService;
