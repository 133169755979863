import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import TaxiOfficerEditByRailyFormData from "./types/taxi-officer-edit-by-raily-form-data";
import taxiOfficerEditByRailyFormValidationService from "./taxi-officer-edit-by-raily-form-validation.service";

const getDefaultFormData = (): TaxiOfficerEditByRailyFormData => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
    taxiCorporationUuid: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<TaxiOfficerEditByRailyFormData> => {
    return {
      firstName: (values) =>
        taxiOfficerEditByRailyFormValidationService.validateFirstName(
          values.firstName
        ),
      lastName: (values) =>
        taxiOfficerEditByRailyFormValidationService.validateLastName(
          values.lastName
        ),
      email: (values) =>
        taxiOfficerEditByRailyFormValidationService.validateEmail(values.email),
      phoneNumber: (values) =>
        taxiOfficerEditByRailyFormValidationService.validatePhoneNumber(
          values.phoneNumber
        ),
      alternativePhoneNumber: (values) =>
        taxiOfficerEditByRailyFormValidationService.validateAlternativePhoneNumber(
          values.alternativePhoneNumber
        ),
      taxiCorporationUuid: (values) =>
        taxiOfficerEditByRailyFormValidationService.validateTaxiCorporation(
          values.taxiCorporationUuid
        ),
    };
  };

const taxiOfficerEditByRailyFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default taxiOfficerEditByRailyFormHelper;
