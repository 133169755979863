import MileageAddAddress from "../types/mileage-add-address";
import MileageAddWorker from "../types/mileage-add-worker";
import {
  MileageAddWorkerListItemAddress,
  MileageAddWorkerListItem,
} from "../types/mileage-add-worker-list";
import MileageAddWorkerSelectOption from "../types/mileage-add-worker-select-option";

const createAddress = (
  address: MileageAddWorkerListItemAddress
): MileageAddAddress => {
  return {
    displayName: address.displayName,
    latitude: address.lat,
    longitude: address.lon,
    apartmentNumber: address.apartmentNumber,
    houseNumber: address.houseNumber,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createAddresses = (
  addresses: MileageAddWorkerListItemAddress[]
): MileageAddAddress[] => {
  const mileageAddresses: MileageAddAddress[] = addresses.map(createAddress);

  return mileageAddresses;
};

const createWorker = (worker: MileageAddWorkerListItem): MileageAddWorker => {
  return {
    displayName: worker.displayName,
    subText: worker.subText,
    uuid: worker.uuid,
    addresses: createAddresses(worker.addresses),
  };
};

const createWorkerSelectOption = (
  worker: MileageAddWorker
): MileageAddWorkerSelectOption => {
  return {
    label: worker.displayName,
    subText: worker.subText,
    value: worker,
  };
};

const createWorkerSelectOptions = (
  data: MileageAddWorkerListItem[]
): MileageAddWorkerSelectOption[] => {
  const workers = data.map(createWorker);
  return workers.map(createWorkerSelectOption);
};

const mileageAddWorkerFactory = {
  createWorkerSelectOption,
  createWorkerSelectOptions,
};

export default mileageAddWorkerFactory;
