import appConfig from "../../../../../../app.config";
import SignUpTaxiOfficerRequest from "./sign-up-taxi-officer.request";
import SignUpTaxiOfficerResponse from "./sign-up-taxi-officer.response";

const signUpTaxiOfficer = async (
  request: SignUpTaxiOfficerRequest,
  token: string
): Promise<SignUpTaxiOfficerResponse> => {
  const path = `/taxi-officers`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
    const responseData = await r.json();
    return {
        status: r.status,
        data: responseData,
    };
};

const signUpTaxiOfficerApiService = {
  signUpTaxiOfficer,
};

export default signUpTaxiOfficerApiService;
