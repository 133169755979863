import ListingFilter from "../../../types/listing-filter";
import ListingFilterSelectOption from "../../../types/listing-filter-select-option";
import SelectOption from "../../form/select/common/option/select-option";
import ListingFilterBadge from "./badge/listing-filter-badge";
import listingFilterHelper from "./listing-filter.helper";
import ListingFilterDefinition, {
  ListingFilterDefinitionOptionCustomSelectData,
  ListingFilterDefinitionOptionDateRangeSelectData,
  ListingFilterDefinitionOptionDateSelectData,
  ListingFilterDefinitionOptionDefaultSelectData,
  ListingFilterDefinitionOptionNumericQuerySelectData,
  ListingFilterDefinitionOptionNumericRangeSelectData,
  ListingFilterDefinitionOptionQuerySelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "./types/listing-filter-definition";
import ListingSortDefinition from "./types/listing-sort-definition";

const checkFilterQueryRequirementOk = (searchQuery: string) => {
  return searchQuery !== "";
};

const checkFilterNumericRequirementOk = (searchQuery: string) => {
  return (
    checkFilterQueryRequirementOk(searchQuery) &&
    !Number.isNaN(Number(searchQuery))
  );
};

const createFilterSelectOptionsFromDefault = (
  selectData: ListingFilterDefinitionOptionDefaultSelectData,
  filterType: ListingFilter["type"]
): ListingFilterSelectOption[] => {
  return selectData.options.map((option) => {
    const selectOption: ListingFilterSelectOption = {
      label: option.label,
      value: {
        type: filterType,
        value: option.value,
      },
    };

    return selectOption;
  });
};

const createFilterSelectOptionsFromQuery = (
  selectData: ListingFilterDefinitionOptionQuerySelectData,
  searchQuery: string,
  filterType: ListingFilter["type"]
): ListingFilterSelectOption[] => {
  if (!checkFilterQueryRequirementOk(searchQuery)) {
    return [];
  }

  return selectData.options.map((option) => {
    const selectOption: ListingFilterSelectOption = {
      label: option.label,
      value: {
        type: filterType,
        value: option.value,
      },
    };

    return selectOption;
  });
};

const createFilterSelectOptionsFromNumericQuery = (
  selectData: ListingFilterDefinitionOptionNumericQuerySelectData,
  searchQuery: string,
  filterType: ListingFilter["type"]
): ListingFilterSelectOption[] => {
  if (!checkFilterNumericRequirementOk(searchQuery)) {
    return [];
  }

  return selectData.options.map((option) => {
    const selectOption: ListingFilterSelectOption = {
      label: option.label,
      value: {
        type: filterType,
        value: option.value,
      },
    };

    return selectOption;
  });
};

const createFilterSelectOptionsFromDate = (
  selectData: ListingFilterDefinitionOptionDateSelectData,
  filterType: ListingFilter["type"],
  onClick: (filterType: ListingFilter["type"]) => void
): ListingFilterSelectOption[] => {
  return [
    {
      label: selectData.option.label,
      value: {
        type: filterType,
        value: undefined,
      },
      onClick: () => onClick(filterType),
    },
  ];
};

const createFilterSelectOptionsFromDateRange = (
  selectData: ListingFilterDefinitionOptionDateRangeSelectData,
  filterType: ListingFilter["type"],
  onClick: (filterType: ListingFilter["type"]) => void
): ListingFilterSelectOption[] => {
  return [
    {
      label: selectData.option.label,
      value: {
        type: filterType,
        value: undefined,
      },
      onClick: () => onClick(filterType),
    },
  ];
};

const createFilterSelectOptionsFromCustom = (
  selectData: ListingFilterDefinitionOptionCustomSelectData,
  filterType: ListingFilter["type"]
): ListingFilterSelectOption[] => {
  return [
    {
      label: selectData.option.label,
      value: {
        type: filterType,
        value: undefined,
      },
      onClick: () => selectData.option.onClick && selectData.option.onClick(),
    },
  ];
};

const createFilterSelectOptionsFromNumericRange = (
  selectData: ListingFilterDefinitionOptionNumericRangeSelectData,
  filterType: ListingFilter["type"],
  onClick: (filterType: ListingFilter["type"]) => void
): ListingFilterSelectOption[] => {
  return [
    {
      label: selectData.option.label,
      value: {
        type: filterType,
        value: undefined,
      },
      onClick: () => onClick(filterType),
    },
  ];
};

const createFilterSelectOptions = (
  filterDefinition: ListingFilterDefinition<any>,
  appliedFilters: ListingFilter[],
  searchQuery: string,
  onDateSelectClick: (filterType: ListingFilter["type"]) => void,
  onDateRangeSelectClick: (filterType: ListingFilter["type"]) => void,
  onNumericRangeSelectClick: (filterType: ListingFilter["type"]) => void
): ListingFilterSelectOption[] => {
  const unappliedFiltersDefinitionOptions =
    listingFilterHelper.getUnappliedFilterOptions(
      filterDefinition,
      appliedFilters
    );

  const selectOptions: ListingFilterSelectOption[] = [];

  unappliedFiltersDefinitionOptions.forEach((option) => {
    const selectData = option.getSelectData(searchQuery);

    const createSelectOptionByType: Record<
      ListingFilterDefinitionOptionSelectDataType,
      () => ListingFilterSelectOption[]
    > = {
      [ListingFilterDefinitionOptionSelectDataType.DEFAULT]: () =>
        createFilterSelectOptionsFromDefault(
          selectData as ListingFilterDefinitionOptionDefaultSelectData,
          option.filterType
        ),
      [ListingFilterDefinitionOptionSelectDataType.QUERY]: () =>
        createFilterSelectOptionsFromQuery(
          selectData as ListingFilterDefinitionOptionQuerySelectData,
          searchQuery,
          option.filterType
        ),
      [ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY]: () =>
        createFilterSelectOptionsFromNumericQuery(
          selectData as ListingFilterDefinitionOptionNumericQuerySelectData,
          searchQuery,
          option.filterType
        ),
      [ListingFilterDefinitionOptionSelectDataType.DATE]: () =>
        createFilterSelectOptionsFromDate(
          selectData as ListingFilterDefinitionOptionDateSelectData,
          option.filterType,
          onDateSelectClick
        ),
      [ListingFilterDefinitionOptionSelectDataType.DATE_RANGE]: () =>
        createFilterSelectOptionsFromDateRange(
          selectData as ListingFilterDefinitionOptionDateRangeSelectData,
          option.filterType,
          onDateRangeSelectClick
        ),
      [ListingFilterDefinitionOptionSelectDataType.CUSTOM]: () =>
        createFilterSelectOptionsFromCustom(
          selectData as ListingFilterDefinitionOptionCustomSelectData,
          option.filterType
        ),
      [ListingFilterDefinitionOptionSelectDataType.NUMERIC_RANGE]: () =>
        createFilterSelectOptionsFromNumericRange(
          selectData as ListingFilterDefinitionOptionNumericRangeSelectData,
          option.filterType,
          onNumericRangeSelectClick
        ),
    };

    const newSelectOptions: ListingFilterSelectOption[] =
      createSelectOptionByType[selectData.type]();

    selectOptions.push(...newSelectOptions);
  });

  return selectOptions;
};

const createSortSelectOptions = (
  sortDefinition: ListingSortDefinition
): SelectOption[] => {
  return sortDefinition.options.map((item) => ({
    label: item.label,
    value: item.value,
  }));
};

const createFilterBadges = (
  filterDefinition: ListingFilterDefinition<any>,
  appliedFilters: ListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  appliedFilters.forEach((filter) => {
    const filterDefinitionOption = filterDefinition.options.find(
      (option) => option.filterType === filter.type
    );

    if (!filterDefinitionOption) {
      return;
    }

    const data = filterDefinitionOption.getBadgeData(filter.value);

    const badge: ListingFilterBadge = {
      text: data.text,
      title: data.title,
    };

    badges.push(badge);
  });

  return badges;
};

const listingFilterFactory = {
  createFilterSelectOptions,
  createFilterBadges,
  createSortSelectOptions,
};

export default listingFilterFactory;
