import TaxiOfficerListRequest, {
  TaxiOfficerListRequestQueryParams,
} from "./taxi-officer-list.request";

const createQueryParams = (
  data: TaxiOfficerListRequest
): TaxiOfficerListRequestQueryParams => {
  return {
    taxiCorporation: data.taxiCorporation,
    taxiCorporationId: data.taxiCorporationId,
    firstName: data.firstName,
    lastName: data.lastName,
    limit: data.limit,
    offset: data.offset,
    order: data.order,
    statusCriteria: data.statusCriteria,
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
    email: data.email,
    username: data.username,
  };
};

const taxiOfficerListRequestFactory = {
  createQueryParams,
};

export default taxiOfficerListRequestFactory;
