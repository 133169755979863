export enum CargoCompanyAddressListItemAddressType {
  HOTEL = "HOTEL",
  STATION = "STATION",
  MEETING_POINT = "MEETING_POINT",
}

export type CargoCompanyAddressListItem = {
  uuid: string;
  creationDate: Date;
  createdBy: string;
  lat: number;
  lon: number;
  displayName: string;
  type: CargoCompanyAddressListItemAddressType;
  modificationDate?: Date;
  modifiedBy?: string;
  country?: string;
  town?: string;
  zipCode?: string;
  street?: string;
  houseNumber?: string;
  apartment?: string;
  description?: string;
};

type CargoCompanyAddressList = CargoCompanyAddressListItem[];

export default CargoCompanyAddressList;
