import ChartData, {
  ChartDataGroup,
  ChartDataGroupItem,
  ChartDataGroupItems,
  ChartDataItemGroupPoint,
} from "../../../../../../common/components/chart/types/chart-data";
import {
  PlannerDriverPlanListItem,
  PlannerDriverPlanListItemCandidatingPlanEntry,
  PlannerDriverPlanListItemCandidatingPlanEntryOrderPoint,
  PlannerDriverPlanListItemCandidatingPlanEntryWaypoint,
  PlannerDriverPlanListItemPlanEntry,
  PlannerDriverPlanListItemPlanEntryOrderPoint,
  PlannerDriverPlanListItemPlanEntryOrderWaypoint,
} from "../../../../../../common/services/planner/list/planner-driver-plan-list";
import {
  PlannerDriverPlanOrderItem,
  PlannerDriverPlanOrderItemOrderPoint,
} from "../../../../../../common/services/planner/order/planner-driver-plan-order";
import PlanningOrderDriverPlanListingItemChart, {
  PlanColors,
  PlanTypeEntry,
  PlanXAxisValue,
  PlanningOrderDriverPlanListingItemChartPlan,
  PlanningOrderDriverPlanListingItemChartPlanOrderPoint,
  PlanningOrderDriverPlanListingItemChartPlanOrderPoints,
} from "../../common/planner-order-driver-listing-item-chart";

const createChartDatItemGroupPoint = (
  item: PlanningOrderDriverPlanListingItemChartPlanOrderPoint,
  index: number,
  isEndPoint?: boolean
): ChartDataItemGroupPoint => {
  const isLabelVisible = index === 0 || isEndPoint;
  return {
    date: new Date(item.date),
    label: isLabelVisible ? item.address : "",
    tooltipLabel: item.address,
  };
};

const createChartDataItemGroupPoints = (
  item: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[],
  isEndPoint?: boolean
): ChartDataItemGroupPoint[] => {
  return item.map((i, index) =>
    createChartDatItemGroupPoint(i, index, isEndPoint)
  );
};

const createChartDataGroupItem = (
  item: PlanningOrderDriverPlanListingItemChartPlanOrderPoints,
  color: string,
  isLabelHidden?: boolean,
  isEndpoint?: boolean
): ChartDataGroupItem => {
  return {
    color: color,
    opacity: 1,
    barLabel: isLabelHidden ? "" : item.uuid?.toString(),
    points: createChartDataItemGroupPoints(item.waypoints, isEndpoint),
    borderColor: color,
  };
};

const createChartDataGroupItemFromSinglePoint = (
  list: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[],
  color: string,
  isEndPoint?: boolean
): ChartDataGroupItem => {
  return {
    color: color,
    opacity: 0.2,
    points: createChartDataItemGroupPoints(list, isEndPoint),
    borderColor: color,
    borderWidth: 2,
  };
};

const createChartDataGroupItemOrderItem = (
  previousOrder: PlanningOrderDriverPlanListingItemChartPlanOrderPoints,
  order: PlanningOrderDriverPlanListingItemChartPlanOrderPoints,
  color: string
) => {
  const orderPoints: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] = [
    previousOrder.waypoints[previousOrder.waypoints.length - 1],
    order.waypoints[0],
  ];
  return createChartDataGroupItemFromSinglePoint(orderPoints, color);
};

const createChartDataGroupItemStartItem = (
  plan: PlanningOrderDriverPlanListingItemChartPlan,
  groupItem: ChartDataGroupItems,
  color: string
) => {
  const startList: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] = [
    plan.start!,
    plan.orders[0].waypoints[0],
  ];
  groupItem.items.push(
    createChartDataGroupItemFromSinglePoint(startList, color)
  );
};

const createChartDataGroupItemEndItem = (
  plan: PlanningOrderDriverPlanListingItemChartPlan,
  groupItem: ChartDataGroupItems,
  color: string,
  isEndPoint?: boolean
) => {
  const lastOrder = plan.orders[plan.orders.length - 1];
  const endList: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] = [
    lastOrder.waypoints[lastOrder.waypoints.length - 1],
    plan.end!,
  ];
  groupItem.items.push(
    createChartDataGroupItemFromSinglePoint(endList, color, isEndPoint)
  );
};

const getChartDataGroupEntries = (
  list: PlanningOrderDriverPlanListingItemChartPlan[],
  groups: ChartDataGroup[],
  xAxisValue: number,
  color: string,
  isLabelHidden?: boolean
) => {
  list.forEach((plan) => {
    const isOrder = plan.type === PlanTypeEntry.ORDER;
    let group = groups.find((group) => group.topLabel === plan.uuid);

    if (!group) {
      group = { topLabel: plan.uuid, items: [] };
      groups.push(group);
    }

    let previousOrder: PlanningOrderDriverPlanListingItemChartPlanOrderPoints;
    const groupItem: ChartDataGroupItems = {
      xAxisValue: xAxisValue,
      items: [],
    };

    if (!isOrder) {
      createChartDataGroupItemStartItem(plan, groupItem, color);
      createChartDataGroupItemEndItem(plan, groupItem, color, true);
    }

    plan.orders.forEach(
      (order: PlanningOrderDriverPlanListingItemChartPlanOrderPoints) => {
        if (previousOrder) {
          const x: ChartDataGroupItem = createChartDataGroupItemOrderItem(
            previousOrder,
            order,
            color
          );
          groupItem?.items.push(x);
        }

        previousOrder = order;

        groupItem.items.push(
          createChartDataGroupItem(order, color, isLabelHidden, isOrder)
        );
      }
    );
    group?.items.push(groupItem);
  });
};

const createChartItems = (
  item: PlanningOrderDriverPlanListingItemChart
): ChartData => {
  let groups: ChartDataGroup[] = [];

  const entries = item.entries;
  const isCandidatureEntriesExist = !!entries.find(
    (i) => i.type === PlanTypeEntry.CANDIDATURE_ENTRY
  );

  const planEntryXAxisValue = isCandidatureEntriesExist
    ? PlanXAxisValue.THIRD_ROW
    : PlanXAxisValue.SECOND_ROW;

  getChartDataGroupEntries(
    entries.filter((i) => i.type === PlanTypeEntry.PLAN_ENTRY),
    groups,
    planEntryXAxisValue,
    PlanColors.PLAN_ENRTRY
  );

  if (isCandidatureEntriesExist) {
    getChartDataGroupEntries(
      entries.filter((i) => i.type === PlanTypeEntry.CANDIDATURE_ENTRY),
      groups,
      PlanXAxisValue.SECOND_ROW,
      PlanColors.CANDIDATURE_ENTRY,
      true
    );
  }

  getChartDataGroupEntries(
    entries.filter((i) => i.type === PlanTypeEntry.ORDER),
    groups,
    PlanXAxisValue.FIRST_ROW,
    PlanColors.ORDER_ENTRY
  );

  return { group: groups };
};

const createCandidatureEntryOrderPoint = (
  item: PlannerDriverPlanListItemCandidatingPlanEntryWaypoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint => {
  return {
    address: item.address,
    date: item.date,
  };
};

const createCandidatureEntryOrderPoins = (
  item: PlannerDriverPlanListItemCandidatingPlanEntryWaypoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] => {
  return item.map(createCandidatureEntryOrderPoint);
};

const createCandidatureEntryOrder = (
  item: PlannerDriverPlanListItemCandidatingPlanEntryOrderPoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints => {
  return {
    uuid: 0,
    waypoints: createCandidatureEntryOrderPoins(item.waypoints),
  };
};

const createCandidatureEntryOrders = (
  item: PlannerDriverPlanListItemCandidatingPlanEntryOrderPoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints[] => {
  return item.map(createCandidatureEntryOrder);
};

const createCandidatureEntries = (
  item: PlannerDriverPlanListItemCandidatingPlanEntry[]
): PlanningOrderDriverPlanListingItemChartPlan[] => {
  return item.map(createCandidatureEntry);
};

const createCandidatureEntry = (
  item: PlannerDriverPlanListItemCandidatingPlanEntry
): PlanningOrderDriverPlanListingItemChartPlan => {
  return {
    uuid: item.candidatureUuid,
    start: item.start,
    end: item.end,
    orders: createCandidatureEntryOrders(item.orders),
    type: PlanTypeEntry.CANDIDATURE_ENTRY,
  };
};

const createPlanEntries = (
  item: PlannerDriverPlanListItemPlanEntry[]
): PlanningOrderDriverPlanListingItemChartPlan[] => {
  return item.map(createPlanEntry);
};

const createPlanEntryOrderPoint = (
  item: PlannerDriverPlanListItemPlanEntryOrderWaypoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint => {
  return {
    address: item.address,
    date: item.date,
  };
};

const createPlanEntryOrderPoints = (
  item: PlannerDriverPlanListItemPlanEntryOrderWaypoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] => {
  return item.map(createPlanEntryOrderPoint);
};

const createPlanEntryOrder = (
  item: PlannerDriverPlanListItemPlanEntryOrderPoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints => {
  return {
    uuid: item.orderId,
    waypoints: createPlanEntryOrderPoints(item.waypoints),
  };
};

const createPlanEntryOrders = (
  item: PlannerDriverPlanListItemPlanEntryOrderPoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints[] => {
  return item.map(createPlanEntryOrder);
};

const createPlanEntry = (
  item: PlannerDriverPlanListItemPlanEntry
): PlanningOrderDriverPlanListingItemChartPlan => {
  return {
    uuid: item.planEntryId,
    start: item.start,
    end: item.end,
    orders: createPlanEntryOrders(item.orders),
    type: PlanTypeEntry.PLAN_ENTRY,
  };
};

const createOrderPoint = (
  item: PlannerDriverPlanOrderItemOrderPoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint => {
  return {
    address: item.address,
    date: item.date,
  };
};

const createOrderOrderPoints = (
  item: PlannerDriverPlanOrderItemOrderPoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] => {
  return item.map(createOrderPoint);
};

const createOrderOrder = (
  item: PlannerDriverPlanOrderItem
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints => {
  return {
    uuid: item.orderUuid,
    waypoints: createOrderOrderPoints(item.orders),
  };
};

const createOrderEntry = (
  item: PlannerDriverPlanOrderItem
): PlanningOrderDriverPlanListingItemChartPlan => {
  return {
    orders: [createOrderOrder(item)],
    end: item.orders[1],
    type: PlanTypeEntry.ORDER,
  };
};

const createChartItemData = (
  item: PlannerDriverPlanListItem,
  order: PlannerDriverPlanOrderItem
): PlanningOrderDriverPlanListingItemChart => {
  const entries = [];
  const planEntries = createPlanEntries(item.planEntries);
  const candidatureEntries = createCandidatureEntries(item.candidatureEntries);
  const orderEntries = createOrderEntry(order);
  entries.push(...planEntries, ...candidatureEntries, orderEntries);

  return {
    entries: entries,
  };
};

const planningOrderDriverListingChartDataFactory = {
  createChartItems,
  createChartItemData,
};

export default planningOrderDriverListingChartDataFactory;
