import MileageContractUpdateRequest, {
  MileageContractUpdateRequestBody,
} from "./mileage-contract-update.request";

const createBody = (
  request: MileageContractUpdateRequest
): MileageContractUpdateRequestBody => {
  return {
    dietFullAfterHours: request.dietFullAfterHours,
    dietFullRate: request.dietFullRate,
    dietHalfAfterHours: request.dietHalfAfterHours,
    dietHalfRate: request.dietHalfRate,
    disabled: !request.isActive,
    distanceRateCarGt900cm3: request.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: request.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: request.distanceRateCompanyVehicle,
    distanceRateMoped: request.distanceRateMoped,
    distanceRateMotorcycle: request.distanceRateMotorcycle,
    validSince: request.validSince,
    validTo: request.validTo,
  };
};

const mileageContractsUpdateRequestFactory = {
  createBody,
};

export default mileageContractsUpdateRequestFactory;
