import { FC } from "react";
import mileageListingTableRouteFactory from "../../../common/table/route/mileage-listing-table-route.factory";

type MileageListingTableRouteComponentProps = { addresses: string[] };

const MileageListingTableRouteComponent: FC<
  MileageListingTableRouteComponentProps
> = (props) => {
  const addressesString = mileageListingTableRouteFactory.createRouteString(
    props.addresses
  );

  return (
    <div
      title={props.addresses.join("\r")}
      className="mileage_listing_table_route"
    >
      {addressesString}
    </div>
  );
};

export default MileageListingTableRouteComponent;
