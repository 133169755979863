import { FC, useEffect, useState } from "react";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import TaxiCargoContractAddFormData from "./common/types/taxi-cargo-contract-add-form-data";
import taxiCargoContractAddFormHelper from "./common/taxi-cargo-contract-add-form.helper";
import { useNavigate, useParams } from "react-router-dom";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import BooleanSelectComponent from "../../../../common/components/form/select/boolean/boolean-select.component";
import notificationService from "../../../../common/utils/notification/notification.service";
import TaxiCargoContractAddRouteParams from "../../common/routes/types/taxi-cargo-contract-add-route-params";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";
import useForm from "../../../../common/components/form/use-form";
import useCargoCompanyList from "../../../../common/services/cargo-company/cargo-company/list/use-cargo-company-list";
import useAbort from "../../../../common/hooks/use-abort";
import CargoTaxiContractAddParams from "../../../../common/services/cargo-taxi-contract/add/cargo-taxi-contract-add-params";
import taxiCargoContractAddParamsFactory from "./common/taxi-cargo-contract-add-params.factory";
import cargoTaxiContractService from "../../../../common/services/cargo-taxi-contract/cargo-taxi-contract.service";
import CargoCompanySingleSelectComponent from "../../../../common/components/form/select/cargo-company/cargo-company-single-select.component";
import BillingModelSingleSelectComponent from "../../../../common/components/form/select/billing-model/billing-model-single-select.component";
import useTaxiCorporationDetails from "../../../../common/services/taxi-corporation/details/use-taxi-corporation-details";

type TaxiCargoContractAddProps = {};

const TaxiCargoContractAddComponent: FC<TaxiCargoContractAddProps> = () => {
  const { taxiCorporationUuid } = useParams<TaxiCargoContractAddRouteParams>();
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const navigate = useNavigate();

  useDocumentTitle(documentTitleTranslations.cargoTaxiContractAdd);

  const taxiDetails = useTaxiCorporationDetails();
  const taxiDetailsAbort = useAbort();

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    taxiDetails.load(
      {
        taxiCorporationUuid,
      },
      taxiDetailsAbort.signal
    );
  }, [taxiCorporationUuid]);

  useEffect(() => {
    const breadcrumbs = taxiBreadcrumbsHelper.getCargoContractAddBreadcrumbs({
      taxiCorporationUuid: taxiCorporationUuid!,
      taxiCorporationDisplayName: taxiDetails.data?.displayName,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, taxiDetails.data]);

  const translations = taxiTranslationsHelper.getCargoContractAddTranslations();

  const form = useForm<TaxiCargoContractAddFormData>({
    emptyValues: taxiCargoContractAddFormHelper.getEmptyFormData(),
    validationDefinition:
      taxiCargoContractAddFormHelper.getValidationDefinition(),
  });

  const cargoCompanyList = useCargoCompanyList();
  const cargoCompanyListAbort = useAbort();

  const cargoTaxiContractAddAbort = useAbort();

  useEffect(() => {
    cargoCompanyList.load({}, cargoCompanyListAbort.signal);
  }, []);

  const [isContractAddPending, setIsContractAddPending] = useState(false);

  const onContractAddSuccess = () => {
    notificationService.success(translations.successAddNotificationText);

    navigate(
      taxiRoutesHelper.getCargoContractListingRoute({
        taxiCorporationUuid: taxiCorporationUuid!,
      })
    );
  };

  const onContractAddFailure = () => {
    notificationService.error(translations.failureAddNotificationText);
  };

  const addContract = async () => {
    setIsContractAddPending(true);

    const params: CargoTaxiContractAddParams =
      taxiCargoContractAddParamsFactory.create(
        form.values,
        taxiCorporationUuid!
      );

    try {
      await cargoTaxiContractService.add(
        params,
        cargoTaxiContractAddAbort.signal
      );

      onContractAddSuccess();
    } catch {
      onContractAddFailure();
    } finally {
      setIsContractAddPending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    addContract();
  };

  return (
    <>
      <HeadingComponent title={translations.header.headingText} />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.cargoCompanyLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.cargoCompanyUuid.errorMessage
                  }
                >
                  <CargoCompanySingleSelectComponent
                    placeholder={translations.form.cargoCompanyPlaceholder}
                    cargoCompanyList={cargoCompanyList.data.data}
                    selectedCargoCompanyUuid={form.values.cargoCompanyUuid}
                    onChange={(value) =>
                      form.setValue("cargoCompanyUuid", value?.uuid ?? "")
                    }
                    isError={
                      !!form.validationResults.cargoCompanyUuid.errorMessage
                    }
                    isLoading={cargoCompanyList.isLoading}
                    isDisabled={cargoCompanyList.isError}
                    onBlur={() => form.validate("cargoCompanyUuid")}
                    idForTesting="taxi-cargo-contract-add-cargo-company"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.periodOfValidityLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.periodOfValidity.errorMessage
                  }
                >
                  <DateRangeInputComponent
                    placeholder={translations.form.periodOfValidityPlaceholder}
                    date={form.values.periodOfValidity}
                    onChange={(value) => {
                      form.setValue("periodOfValidity", value);
                      form.validate("periodOfValidity", value);
                    }}
                    hasError={
                      !!form.validationResults.periodOfValidity.errorMessage
                    }
                    idForTesting="taxi-cargo-contract-add-period-validity"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.billingModelLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.billingModel.errorMessage
                  }
                >
                  <BillingModelSingleSelectComponent
                    placeholder={translations.form.billingModelPlaceholder}
                    value={form.values.billingModel}
                    onChange={(value) => form.setValue("billingModel", value)}
                    isError={!!form.validationResults.billingModel.errorMessage}
                    onBlur={() => form.validate("billingModel")}
                    idForTesting="taxi-cargo-contract-add-billing-model"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.distanceRateLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.distanceRate.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={translations.form.distanceRatePlaceholder}
                    value={form.values.distanceRate}
                    onChange={(value) => form.setValue("distanceRate", value)}
                    hasError={
                      !!form.validationResults.distanceRate.errorMessage
                    }
                    onBlur={() => form.validate("distanceRate")}
                    decimalPrecision={2}
                    idForTesting="taxi-cargo-contract-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.discountPercentLimitLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.discountPercentLimit.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.discountPercentLimitPlaceholder
                    }
                    value={form.values.discountPercentLimit}
                    onChange={(value) =>
                      form.setValue("discountPercentLimit", value)
                    }
                    hasError={
                      !!form.validationResults.discountPercentLimit.errorMessage
                    }
                    onBlur={() => form.validate("discountPercentLimit")}
                    idForTesting="taxi-cargo-contract-add-discount"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.freeStoppingPeriodLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.freeStoppingPeriodMinutes
                      .errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.freeStoppingPeriodPlaceholder
                    }
                    value={form.values.freeStoppingPeriodMinutes}
                    onChange={(value) =>
                      form.setValue("freeStoppingPeriodMinutes", value)
                    }
                    hasError={
                      !!form.validationResults.freeStoppingPeriodMinutes
                        .errorMessage
                    }
                    onBlur={() => form.validate("freeStoppingPeriodMinutes")}
                    idForTesting="taxi-cargo-contract-add-free-stoping-period"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.stoppingRateLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.stoppingRate.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={translations.form.stoppingRatePlaceholder}
                    value={form.values.stoppingRate}
                    onChange={(value) => form.setValue("stoppingRate", value)}
                    hasError={
                      !!form.validationResults.stoppingRate.errorMessage
                    }
                    onBlur={() => form.validate("stoppingRate")}
                    decimalPrecision={2}
                    idForTesting="taxi-cargo-contract-add-stopping-rate"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.tollRoadsDuringOrderAllowedLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.isTollRoadsDuringOrderAllowed
                      .errorMessage
                  }
                >
                  <BooleanSelectComponent
                    placeholder={
                      translations.form.tollRoadsDuringOrderAllowedPlaceholder
                    }
                    value={form.values.isTollRoadsDuringOrderAllowed}
                    onChange={(value) =>
                      form.setValue("isTollRoadsDuringOrderAllowed", value)
                    }
                    onBlur={() =>
                      form.validate("isTollRoadsDuringOrderAllowed")
                    }
                    isError={
                      !!form.validationResults.isTollRoadsDuringOrderAllowed
                        .errorMessage
                    }
                    idForTesting="taxi-cargo-contract-add-tool-roads-during-order"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={
                    translations.form.tollRoadsWhileApproachingAllowedLabel
                  }
                  isRequired
                  errorMessage={
                    form.validationResults.isTollRoadsWhileArrivalAllowed
                      .errorMessage
                  }
                >
                  <BooleanSelectComponent
                    placeholder={
                      translations.form
                        .tollRoadsWhileApproachingAllowedPlaceholder
                    }
                    value={form.values.isTollRoadsWhileArrivalAllowed}
                    onChange={(value) =>
                      form.setValue("isTollRoadsWhileArrivalAllowed", value)
                    }
                    onBlur={() =>
                      form.validate("isTollRoadsWhileArrivalAllowed")
                    }
                    isError={
                      !!form.validationResults.isTollRoadsWhileArrivalAllowed
                        .errorMessage
                    }
                    idForTesting="taxi-cargo-contract-add-tool-roads-while-arrival"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.tollRoadsWhileReturningAllowedLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.isTollRoadsWhileReturningAllowed
                      .errorMessage
                  }
                >
                  <BooleanSelectComponent
                    placeholder={
                      translations.form
                        .tollRoadsWhileReturningAllowedPlaceholder
                    }
                    value={form.values.isTollRoadsWhileReturningAllowed}
                    onChange={(value) =>
                      form.setValue("isTollRoadsWhileReturningAllowed", value)
                    }
                    onBlur={() =>
                      form.validate("isTollRoadsWhileReturningAllowed")
                    }
                    isError={
                      !!form.validationResults.isTollRoadsWhileReturningAllowed
                        .errorMessage
                    }
                    idForTesting="taxi-cargo-contract-add-tool-roads-while-returning"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.orderPublishingAllowedLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.isOrderPublishingAllowed.errorMessage
                  }
                >
                  <BooleanSelectComponent
                    placeholder={
                      translations.form.orderPublishingAllowedPlaceholder
                    }
                    value={form.values.isOrderPublishingAllowed}
                    onChange={(value) =>
                      form.setValue("isOrderPublishingAllowed", value)
                    }
                    onBlur={() => form.validate("isOrderPublishingAllowed")}
                    isError={
                      !!form.validationResults.isOrderPublishingAllowed
                        .errorMessage
                    }
                    idForTesting="taxi-cargo-contract-add-is-public"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.isActiveLabel}
                  isRequired
                  errorMessage={form.validationResults.isActive.errorMessage}
                >
                  <BooleanSelectComponent
                    placeholder={translations.form.isActivePlaceholder}
                    value={form.values.isActive}
                    onChange={(value) => form.setValue("isActive", value)}
                    isError={!!form.validationResults.isActive.errorMessage}
                    onBlur={() => form.validate("isActive")}
                    idForTesting="taxi-cargo-contract-add-is-active"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
          <ButtonComponent
            onClick={onSubmitButtonClick}
            type="primary"
            isLoading={isContractAddPending}
            idForTesting="taxi-cargo-contract-add-submit-button"
            classNames={{ root: "mt-4" }}
            title={translations.form.submitButtonTitle}
          >
            {translations.form.submitButtonText}
          </ButtonComponent>
        </Column>
      </Row>
    </>
  );
};

export default TaxiCargoContractAddComponent;
