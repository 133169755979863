import UserRole from "../../../../common/types/user-role";
import UserPermissions from "../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../common/utils/user/permissions/user-permissions-definition";

export type BillingsUserPermissionOption =
  | "hasAccessToAccept"
  | "hasAccessToReject"
  | "hasAccessToReopen"
  | "hasAccessToRequestToReopen"
  | "hasAccessToSearchAddress";

export type BillingsUserPermissionsDefinition =
  UserPermissionsDefinition<BillingsUserPermissionOption>;

export type BillingsUserPermissions =
  UserPermissions<BillingsUserPermissionOption>;

const billingsUserPermissionDefinition: BillingsUserPermissionsDefinition = {
  hasAccessToAccept: [UserRole.CARGO_OFFICER, UserRole.ADMIN],
  hasAccessToReopen: [UserRole.CARGO_OFFICER, UserRole.ADMIN],
  hasAccessToReject: [UserRole.CARGO_OFFICER, UserRole.ADMIN],
  hasAccessToRequestToReopen: [UserRole.TAXI_OFFICER, UserRole.ADMIN],
  hasAccessToSearchAddress: [UserRole.CARGO_OFFICER, UserRole.ADMIN],
};

export default billingsUserPermissionDefinition;
