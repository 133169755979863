import TaxiUnsettledRouteListingByRailyTaxiCorporationSelectOption from "./types/taxi-unsettled-route-listing-by-raily-taxi-corporation-select-option";
import taxiUnsettledRouteListingFactory from "../../common/taxi-unsettled-route-listing.factory";
import { TaxiUnsettledRouteListingByRailyTaxiCoporationsResponseDataItem } from "./api/taxi-unsettled-route-listing-by-raily-taxi-corporations.response";

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: TaxiUnsettledRouteListingByRailyTaxiCoporationsResponseDataItem
): TaxiUnsettledRouteListingByRailyTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      displayName: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: TaxiUnsettledRouteListingByRailyTaxiCoporationsResponseDataItem[]
): TaxiUnsettledRouteListingByRailyTaxiCorporationSelectOption[] => {
  return responseTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const taxiUnsettledRouteListingByRailyFactory = {
  createTaxiCorporationSelectOptions,
  createListingItems: taxiUnsettledRouteListingFactory.createListingItems,
};

export default taxiUnsettledRouteListingByRailyFactory;
