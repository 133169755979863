import MileageContractListRequest, {
  MileageContractListQueryParamsOrder,
} from "../../../api/raily/mileage-contract/list/mileage-contract-list.request";
import MileageContractListLoadParams, {
  MileageContractListLoadParamsOrder,
} from "./mileage-contract-list-load-params";

const createOrder = (
  order: MileageContractListLoadParamsOrder | undefined
): MileageContractListQueryParamsOrder | undefined => {
  switch (order) {
    case MileageContractListLoadParamsOrder.COMPANY_NAME_ASC:
      return MileageContractListQueryParamsOrder.COMPANY_NAME_ASC;
    case MileageContractListLoadParamsOrder.COMPANY_NAME_DESC:
      return MileageContractListQueryParamsOrder.COMPANY_NAME_DESC;
    case MileageContractListLoadParamsOrder.VALID_SINCE_ASC:
      return MileageContractListQueryParamsOrder.VALID_SINCE_ASC;
    case MileageContractListLoadParamsOrder.VALID_SINCE_DESC:
      return MileageContractListQueryParamsOrder.VALID_SINCE_DESC;
    case MileageContractListLoadParamsOrder.VALID_TO_ASC:
      return MileageContractListQueryParamsOrder.VALID_TO_ASC;
    case MileageContractListLoadParamsOrder.VALID_TO_DESC:
      return MileageContractListQueryParamsOrder.VALID_TO_DESC;
    default:
      return undefined;
  }
};

const create = (
  params: MileageContractListLoadParams
): MileageContractListRequest => {
  return {
    cargoCompanyUuid: params.cargoCompanyUuid,
    isValid: params.isValid,
    page: params.page,
    pageSize: params.pageSize,
    cargoCompanyName: params.cargoCompanyName,
    order: createOrder(params.order),
  };
};

const mileageContractsListRequestFactory = {
  create,
};

export default mileageContractsListRequestFactory;
