import SearchRoadRoutingResponse, {
  SearchRoadRoutingResponseRoute,
} from "../../../utils/search-road-route/search-road-routing.response";
import DelegationRoadRouteItem from "./delegation-road-route-item";

const createRoute = (
  route: SearchRoadRoutingResponseRoute
): DelegationRoadRouteItem => {
  return {
    coordinates: route.geometry.coordinates,
    legs: route.legs,
  };
};

const create = (
  response: SearchRoadRoutingResponse
): DelegationRoadRouteItem => {
  const listItem: DelegationRoadRouteItem = createRoute(response.routes[0]);

  return listItem;
};

const delegationRoadRouteFactory = {
  create,
};

export default delegationRoadRouteFactory;
