import { FC, useState } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import ModalComponent from "../../../../common/components/modal/modal.component";
import notificationService from "../../../../common/utils/notification/notification.service";
import { useAppContext } from "../../../../context/app.context";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import { OrderDetailsCargoOrderChangesApprovals } from "../common/types/order-details-cargo-order";
import orderDetailsChangesApprovalApiService from "./common/api/order-details-changes-approval-api.service";
import OrderDetailsChangesApprovalOptionType from "./common/types/order-details-changes-approval-option";
import orderDetailsChangesApprovalHelper from "./order-details-changes-approval.helper";
import OrderDetailsChangesApprovalResponse from "./common/api/order-details-changes-approval.response";

type OrderDetailsChangesApprovalProps = {
  orderUuid: string;
  changeApprovals: OrderDetailsCargoOrderChangesApprovals;
  onSuccessApproveByDealer: () => void;
  onSuccessApproveByDispatcher: () => void;
  onSuccessApproveByOperator: () => void;
  isOrderCancelled: boolean;
  isApproveButtonDisabled: boolean;
};

const OrderDetailsChangesApprovalComponent: FC<
  OrderDetailsChangesApprovalProps
> = (props) => {
  const { user } = useAppContext();
  const translations =
    orderTranslationsHelper.getDetailsChangesApprovingTranslations();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDealerApproveFetching, setIsDealerApproveFetching] = useState(false);
  const [isDispatcherApproveFetching, setIsDispatcherApproveFetching] =
    useState(false);
  const [isOperatorApproveFetching, setIsOperatorApproveFetching] =
    useState(false);

  const onApproveAsDealerFetchingSuccess = () => {
    showApproveChangesSuccessNotification();
    props.onSuccessApproveByDealer();
  };

  const onApproveAsDealerFetchingFailure = () => {
    showApproveChangesFailureNotification();
  };

  const handleApproveAsDealerResponse = (
    response: OrderDetailsChangesApprovalResponse
  ) => {
    if (response.status === 201) {
      onApproveAsDealerFetchingSuccess();
      return;
    }

    onApproveAsDealerFetchingFailure();
  };

  const approveChangesAsDealer = () => {
    setIsDealerApproveFetching(true);

    orderDetailsChangesApprovalApiService
      .fetchApproveChanges(props.orderUuid)
      .then(handleApproveAsDealerResponse)
      .catch(onApproveAsDealerFetchingFailure)
      .finally(() => setIsDealerApproveFetching(false));
  };

  const showApproveChangesFailureNotification = () => {
    notificationService.error(
      translations.failureApproveNotificationMessageText
    );
  };

  const showApproveChangesSuccessNotification = () => {
    notificationService.success(
      translations.successApproveNotificationMessageText
    );
  };

  const onApproveAsDispatcherFetchingSuccess = () => {
    showApproveChangesSuccessNotification();
    props.onSuccessApproveByDispatcher();
  };

  const onApproveAsDispatcherFetchingFailure = () => {
    showApproveChangesFailureNotification();
  };

  const handleApproveAsDispatcherResponse = (
    response: OrderDetailsChangesApprovalResponse
  ) => {
    if (response.status === 201) {
      onApproveAsDispatcherFetchingSuccess();
      return;
    }

    onApproveAsDispatcherFetchingFailure();
  };

  const approveChangesAsDispatcher = () => {
    setIsDispatcherApproveFetching(true);

    orderDetailsChangesApprovalApiService
      .fetchApproveChanges(props.orderUuid)
      .then(handleApproveAsDispatcherResponse)
      .catch(onApproveAsDispatcherFetchingFailure)
      .finally(() => setIsDispatcherApproveFetching(false));
  };

  const onApproveAsOperatorFetchingSuccess = () => {
    showApproveChangesSuccessNotification();
    props.onSuccessApproveByOperator();
  };

  const onApproveAsOperatorFetchingFailure = () => {
    showApproveChangesFailureNotification();
  };

  const handleApproveAsOperatorResponse = (
    response: OrderDetailsChangesApprovalResponse
  ) => {
    if (response.status === 201) {
      onApproveAsOperatorFetchingSuccess();
      return;
    }

    onApproveAsOperatorFetchingFailure();
  };

  const approveChangesAsOperator = () => {
    setIsOperatorApproveFetching(true);

    orderDetailsChangesApprovalApiService
      .fetchApproveChanges(props.orderUuid)
      .then(handleApproveAsOperatorResponse)
      .catch(onApproveAsOperatorFetchingFailure)
      .finally(() => setIsOperatorApproveFetching(false));
  };

  const approveChanges = () => {
    const permittedOperationTypes =
      orderDetailsChangesApprovalHelper.getPermittedOperationTypes(
        user?.roles!,
        props.changeApprovals
      );

    const selectedOperationType = permittedOperationTypes[0];

    switch (selectedOperationType) {
      case OrderDetailsChangesApprovalOptionType.BY_DEALER:
        approveChangesAsDealer();
        break;
      case OrderDetailsChangesApprovalOptionType.BY_DISPATCHER:
        approveChangesAsDispatcher();
        break;
      case OrderDetailsChangesApprovalOptionType.BY_OPERATOR:
        approveChangesAsOperator();
        break;
      default:
        break;
    }
  };

  const onApproveChangesButtonClick = () => {
    const hasUserPermissionToManyApproveOptions =
      orderDetailsChangesApprovalHelper.checkHasUserPermissionToApproveMany(
        user?.roles!,
        props.changeApprovals
      );

    if (hasUserPermissionToManyApproveOptions) {
      openModal();
      return;
    }

    approveChanges();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isApproveButtonVisible = !props.isOrderCancelled;

  const isApproveButtonEnabled =
    !orderDetailsChangesApprovalHelper.checkAreAllPermittedOptionsApproved(
      user?.roles!,
      props.changeApprovals
    ) && !props.isApproveButtonDisabled;

  const isApproveButtonLoading =
    (isDealerApproveFetching ||
      isDispatcherApproveFetching ||
      isOperatorApproveFetching) &&
    !isModalOpen;

  const onApproveChangesAsDealerButtonClick = () => {
    approveChangesAsDealer();
  };

  const onApproveChangesAsDispatcherButtonClick = () => {
    approveChangesAsDispatcher();
  };

  const onApproveChangesAsOperatorButtonClick = () => {
    approveChangesAsOperator();
  };

  const permittedOperationTypes =
    orderDetailsChangesApprovalHelper.getPermittedOperationTypes(
      user?.roles!,
      props.changeApprovals
    );

  const isDealerApproveButtonEnabled =
    props.changeApprovals.isDealerApproveNeeded;

  const isDealerApproveButtonVisible =
    permittedOperationTypes.includes(
      OrderDetailsChangesApprovalOptionType.BY_DEALER
    ) && !props.isOrderCancelled;

  const isDispatcherApproveButtonEnabled =
    props.changeApprovals.isDispatcherApproveNeeded;

  const isDispatcherApproveButtonVisible =
    permittedOperationTypes.includes(
      OrderDetailsChangesApprovalOptionType.BY_DISPATCHER
    ) && !props.isOrderCancelled;

  const isOperatorApproveButtonEnabled =
    props.changeApprovals.isOperatorApproveNeeded;

  const isOperatorApproveButtonVisible =
    permittedOperationTypes.includes(
      OrderDetailsChangesApprovalOptionType.BY_OPERATOR
    ) && !props.isOrderCancelled;

  if (!permittedOperationTypes.length || !isApproveButtonVisible) {
    return null;
  }

  return (
    <>
      {isApproveButtonVisible && (
        <ButtonComponent
          onClick={onApproveChangesButtonClick}
          type="success"
          classNames={{ root: "w-100" }}
          isLoading={isApproveButtonLoading}
          isDisabled={!isApproveButtonEnabled}
          title={
            isApproveButtonEnabled
              ? translations.approveButtonTitleForNotApproved
              : translations.approveButtonTitleForApproved
          }
        >
          {isApproveButtonEnabled
            ? translations.approveButtonTextForNotApproved
            : translations.approveButtonTextForApproved}
        </ButtonComponent>
      )}

      <ModalComponent
        isOpen={isModalOpen}
        onClose={closeModal}
        header={{ title: translations.manyOptionsModalHeadingText }}
        actions={[
          isDealerApproveButtonVisible && (
            <ButtonComponent
              onClick={onApproveChangesAsDispatcherButtonClick}
              type="success"
              isLoading={isDispatcherApproveFetching}
              isDisabled={!isDispatcherApproveButtonEnabled}
              title={
                isDispatcherApproveButtonEnabled
                  ? translations.approveAsDispatcherButtonTitleForNotApproved
                  : translations.approveAsDispatcherButtonTitleForApproved
              }
            >
              {isDispatcherApproveButtonEnabled
                ? translations.approveAsDispatcherButtonTextForNotApproved
                : translations.approveAsDispatcherButtonTextForApproved}
            </ButtonComponent>
          ),
          isDispatcherApproveButtonVisible && (
            <ButtonComponent
              onClick={onApproveChangesAsOperatorButtonClick}
              type="success"
              isLoading={isOperatorApproveFetching}
              isDisabled={!isOperatorApproveButtonEnabled}
              title={
                isDispatcherApproveButtonEnabled
                  ? translations.approveAsDispatcherButtonTitleForNotApproved
                  : translations.approveAsDispatcherButtonTitleForApproved
              }
            >
              {isOperatorApproveButtonEnabled
                ? translations.approveAsOperatorButtonTextForNotApproved
                : translations.approveAsOperatorButtonTextForApproved}
            </ButtonComponent>
          ),
          isOperatorApproveButtonVisible && (
            <ButtonComponent
              onClick={onApproveChangesAsDealerButtonClick}
              type="success"
              isLoading={isDealerApproveFetching}
              isDisabled={!isDealerApproveButtonEnabled}
              title={
                isDispatcherApproveButtonEnabled
                  ? translations.approveAsDealerButtonTitleForNotApproved
                  : translations.approveAsDealerButtonTitleForApproved
              }
            >
              {isDispatcherApproveButtonEnabled
                ? translations.approveAsDealerButtonTextForNotApproved
                : translations.approveAsDealerButtonTextForApproved}
            </ButtonComponent>
          ),
        ]}
      >
        {translations.manyOptionsModalText}
      </ModalComponent>
    </>
  );
};

export default OrderDetailsChangesApprovalComponent;
