import appConfig from "../../../../../app.config";
import authService from "../../../../auth/common/auth.service";
import TaxiAddRequest from "./taxi-add-request";
import TaxiAddResponse from "./taxi-add-response";

const addTaxi = async (request: TaxiAddRequest): Promise<TaxiAddResponse> => {
  const path = `/taxi-corporations`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const taxiAddApiService = {
  addTaxi,
};

export default taxiAddApiService;
