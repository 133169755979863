import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiOrderSettlementListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView"
  | "hasAccessToEditCargoWithTaxiSettlement"
  | "hasAccessToDetailsCargoWithTaxiSettlement";

export type TaxiOrderSettlementListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiOrderSettlementListingUserPermissionOption>;

export type TaxiOrderSettlementListingUserPermissions =
  UserPermissions<TaxiOrderSettlementListingUserPermissionOption>;

const taxiOrderSettlementListingUserPermissionDefinition: TaxiOrderSettlementListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToEditCargoWithTaxiSettlement: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
    hasAccessToDetailsCargoWithTaxiSettlement: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  };

export default taxiOrderSettlementListingUserPermissionDefinition;
