import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { PlanningOrderDriverPlanListingSelectedItemUser } from "../types/planner-order-driver-plan-listing-selected-item";
import planningTranslationsHelper from "../../../../../languages/planning-translations.helper";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CheckboxComponent from "../../../../../common/components/form/checkbox/checkbox.component";

type PlanningOrderDriverPlanListingItemDetailsProps = {
  user: PlanningOrderDriverPlanListingSelectedItemUser;
  onClick: (value: number) => void;
  onMark: (value: boolean) => void;
  shouldItemBeSent: boolean;
  isCandidatureAdded: boolean;
};

const PlanningOrderDriverPlanListingSelectedItemHeaderComponent: FC<
  PlanningOrderDriverPlanListingItemDetailsProps
> = (props) => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing.staging;

  const handleRemoveButtonClick = () => {
    props.onClick(props.user.id);
    props.onMark(false);
  };

  const handleOnCheckboxChange = () => {
    props.onMark(!props.shouldItemBeSent);
  };

  return (
    <div className="planning-order-driver-plan-listing-selected__item">
      <div className="planning-order-driver-plan-listing-selected__item_name">{`${props.user.driver.firstName} ${props.user.driver.lastName}`}</div>
      <div className="planning-order-driver-plan-listing-selected__item_employment">
        <span>{`${props.user.taxiName} `}</span>
      </div>
      <div>
        {!!props.user.planEntry?.internalOrderId ? (
          <span>
            {translations.routeTemplateLabel.replace(
              "#{routeId}",
              String(props.user.planEntry.internalOrderId)
            )}
          </span>
        ) : (
          <span>{translations.newRouteLabel}</span>
        )}
      </div>
      <div className="planning-order-driver-plan-listing-selected__buttons">
        <CheckboxComponent
          isChecked={props.shouldItemBeSent}
          onChange={handleOnCheckboxChange}
          title={translations.candidatureSentCheckboxLabel}
          isDisabled={props.isCandidatureAdded}
        />
        <ButtonComponent
          onClick={handleRemoveButtonClick}
          title={translations.removePlanButtonTitle}
          classNames={{
            root: "planning-order-driver-plan-listing-selected__close_button",
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </ButtonComponent>
      </div>
    </div>
  );
};

export default PlanningOrderDriverPlanListingSelectedItemHeaderComponent;
