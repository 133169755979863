import { FC, useEffect, useState } from "react";
import { useAppContext } from "../../../context/app.context";
import { useParams } from "react-router-dom";
import { PlanningOrderSelectedItemData } from "./common/types/planning-order-selected-data";
import Row from "../../../common/components/grid/row";
import Column from "../../../common/components/grid/column";
import CardComponent from "../../../common/components/card/card.component";
import HeadingComponent from "../../../common/components/heading/heading.component";
import planningTranslationsHelper from "../../../languages/planning-translations.helper";
import PlanningOrderDriverPlanListingComponent from "./driver-plan-listing/planning-order-driver-plan-listing.component";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import planningBreadcrumbsHelper from "../common/breadcrumbs/planning-breadcrumbs.helper";
import PlanningOrderDriverPlanListingSelectedComponent from "./driver-plan-listing-selected/planning-order-driver-plan-listing-selected.component";
import PlanningOrderOrderDetailsComponent from "./order-details/planning-order-order-details.component";
import PlannerOrderDriverListingRouteParams from "./driver-plan-listing/types/planner-order-driver-listing-route-params";
import PlanningOrderOrderMapComponent from "./order-map/planning-order-order-map.component";
import useAbort from "../../../common/hooks/use-abort";
import useCargoOrderDetailsComplex from "../../../common/services/cargo-order/details-complex/use-cargo-order-details-complex";
import StatusLegendComponent from "../../../common/components/status-legend/status-legend.component";
import planningOrderHelper from "./planning-order.helper";

type PlanningOrderProps = {};

const PlanningOrderComponent: FC<PlanningOrderProps> = () => {
  const [selectedItemsData, setSelectedItemsData] = useState<
    PlanningOrderSelectedItemData[]
  >([]);

  const translations = planningTranslationsHelper.getOrderTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.planningOrder);

  const { orderUuid } = useParams<PlannerOrderDriverListingRouteParams>();

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const cargoOrderDetailsAbort = useAbort();
  const cargoOrderDetails = useCargoOrderDetailsComplex();
  const internalOrderId = cargoOrderDetails.data?.internalOrderId;

  useEffect(() => {
    if (!orderUuid) return;

    cargoOrderDetails.load(
      {
        orderUuid: orderUuid,
      },
      cargoOrderDetailsAbort.signal
    );
  }, [orderUuid]);

  useEffect(() => {
    const breadcrumbs = planningBreadcrumbsHelper.getOrderBreadcrumbs({
      internalOrderId: internalOrderId,
      orderUuid: orderUuid!,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoOrderDetails.data]);

  const addItemToSelected = (item: PlanningOrderSelectedItemData) => {
    setSelectedItemsData([...selectedItemsData, item]);
  };

  const removeItemFromSelectedItemsData = (id: number) => {
    const selectedItems = selectedItemsData.filter(
      (item: PlanningOrderSelectedItemData) => item.id !== id
    );
    setSelectedItemsData(selectedItems);
  };

  const headerLabel = `${translations.header.headingLabel} ${
    internalOrderId ? internalOrderId : ""
  }`;

  const statusOptions = planningOrderHelper.getStatusOptions();

  return (
    <>
      <HeadingComponent title={headerLabel} />
      <StatusLegendComponent statusData={statusOptions} />
      <Row>
        <Column lg={7}>
          <CardComponent
            header={{ title: translations.details.headingLabel }}
            classNames={{ root: "h-100" }}
          >
            <PlanningOrderOrderDetailsComponent orderUuid={orderUuid} />
          </CardComponent>
        </Column>
        <Column lg={5}>
          <CardComponent
            header={{ title: translations.map.headingLabel }}
            classNames={{ root: "h-100" }}
          >
            <PlanningOrderOrderMapComponent orderUuid={orderUuid} />
          </CardComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={7} withPaddings>
          <CardComponent
            header={{ title: translations.driverPlanListing.headingLabel }}
          >
            <PlanningOrderDriverPlanListingComponent
              orderUuid={orderUuid}
              onButtonClick={addItemToSelected}
              selectedItemsData={selectedItemsData}
            />
          </CardComponent>
        </Column>
        <Column lg={5} withPaddings>
          <CardComponent
            header={{
              title: translations.selectedDriverPlanListing.headingLabel,
            }}
          >
            <PlanningOrderDriverPlanListingSelectedComponent
              selectedItemsData={selectedItemsData}
              orderUuid={orderUuid}
              onClick={removeItemFromSelectedItemsData}
            />
          </CardComponent>
        </Column>
      </Row>
    </>
  );
};

export default PlanningOrderComponent;
