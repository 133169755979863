import HttpResponse from "../../../../utils/http/http.response";

export enum MileageDetailsResponseStatusType {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DELEGATION_CREATED = "DELEGATION_CREATED",
}

export enum MileageDetailsResponseVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type MileageDetailsResponseAddressSequenceNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
  distance?: number;
};

export type MileageDetailsResponseAddressSequence = {
  seq: MileageDetailsResponseAddressSequenceNode[];
};

export type MileageDetailsResponseItem = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  workerId: string;
  workerCompanyId: string;
  mileageDate: Date;
  cardNumber?: string | null;
  commissionNumber?: string | null;
  mileageNumber?: string | null;
  notes?: string | null;
  state: MileageDetailsResponseStatusType;
  vehicleType: MileageDetailsResponseVehicleType;
  distance: number;
  mileageCost: number;
  delegationId?: string | null;
  hasDelegation: boolean;
  humanId: number;
  workerName: string;
  workerPhone: string;
  addressSeq: MileageDetailsResponseAddressSequence;
  startAddressLabel: string;
  midAddressesLabels: string[];
  endAddressLabel: string;
  osrmDistance?: number;
  acceptedDistance?: number;
};

type MileageDetailsResponse = HttpResponse<MileageDetailsResponseItem>;

export default MileageDetailsResponse;
