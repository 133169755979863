import useAsyncData from "../../../hooks/use-async-data";
import mileageService from "../mileage.service";
import MileageRoadRouteItem from "./mileage-road-route-item";
import MileageRoadRouteLoadParams from "./mileage-road-route-load-params";

const useMileageRoadRoute = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MileageRoadRouteItem | null>(null);

  const load = async (params: MileageRoadRouteLoadParams): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await mileageService.getRoute(params);

      setData(data);
      setIsLoading(false);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useMileageRoadRoute;
