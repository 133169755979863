import OrderOptimizerListLoadParams from "../../../../common/services/order-optimizer/list/order-optimizer-list-load-paramts";
import OrderOptimizerListingFilter, {
  OrderOptimizerListingOrderHumanIdFilter,
} from "./types/order-optimizer-listing-filter";
import OrderOptimizerListingFilterType from "./types/order-optimizer-listing-filter-type";

const create = (
  filters: OrderOptimizerListingFilter[]
): OrderOptimizerListLoadParams => {
  return {
    orderHumanId: filters.find(
      (filter) => filter.type === OrderOptimizerListingFilterType.ORDER_HUMAN_ID
    )?.value as OrderOptimizerListingOrderHumanIdFilter["value"] | undefined,
    pageSize: 10,
  };
};

const orderOptimizerListingLoadParamsFactory = {
  create,
};

export default orderOptimizerListingLoadParamsFactory;
