import appAsideNavigationHelper from "../../common/app-aside-navigation.helper";
import AppAsideNavigationDefinitionItem, {
  AppAsideNavigationDefinitionGroup,
  AppAsideNavigationDefinitionLink,
} from "../../common/types/app-aside-navigation-definition";
import appAsideMinifiedNavigationHelper from "./app-aside-minified-navigation.helper";
import AppAsideMinifiedNavigationItem from "./types/app-aside-minified-navigation-item";

const createNavigationItemFromLink = (
  navigationDefinitionLink: AppAsideNavigationDefinitionLink,
  pathname: string
): AppAsideMinifiedNavigationItem => {
  const isActive = navigationDefinitionLink.path === pathname;

  const item: AppAsideMinifiedNavigationItem = {
    icon: navigationDefinitionLink.icon,
    isActive,
    key: navigationDefinitionLink.key,
  };

  return item;
};

const createNavigationItemFromGroup = (
  navigationDefinitionGroup: AppAsideNavigationDefinitionGroup,
  pathname: string
): AppAsideMinifiedNavigationItem => {
  const isActive =
    appAsideMinifiedNavigationHelper.checkIsSomeNestedNavigationItemActive(
      navigationDefinitionGroup,
      pathname
    );

  const item: AppAsideMinifiedNavigationItem = {
    icon: navigationDefinitionGroup.icon,
    isActive,
    key: navigationDefinitionGroup.key,
  };

  return item;
};

const createNavigationItems = (
  navigationDefinitionItems: AppAsideNavigationDefinitionItem[],
  pathname: string
): AppAsideMinifiedNavigationItem[] => {
  return navigationDefinitionItems.map((navigationDefinitionItem) => {
    const isLink = appAsideNavigationHelper.checkIsDefinitionLink(
      navigationDefinitionItem
    );
    if (isLink) {
      return createNavigationItemFromLink(navigationDefinitionItem, pathname);
    }

    return createNavigationItemFromGroup(navigationDefinitionItem, pathname);
  });
};

const appAsideMinifiedNavigationFactory = {
  createNavigationItems,
};

export default appAsideMinifiedNavigationFactory;
