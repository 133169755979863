import orderOptimizerRoutesUrls from "./order-optimizer-routes-urls";

const getListingRoute = () => orderOptimizerRoutesUrls.listing;

const getListingRouteWithOrderHumanId = (orderHumanId: number) => {
  return `${orderOptimizerRoutesUrls.listing}?orderHumanId=${orderHumanId}`;
};

const orderOptimizerRoutesHelper = {
  getListingRoute,
  getListingRouteWithOrderHumanId,
};

export default orderOptimizerRoutesHelper;
