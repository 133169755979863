import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryChangePositionChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";

type OrderDetailsHistoryEntryChangeRideContentChangePositionProps = {
  event: OrderDetailsHistoryEntryChangePositionChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentChangePositionComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentChangePositionProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .changePosition;

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
      <div className="order_details_history_entry_list_item_content__value">
        {props.event.details.newPlaceAddressDisplayName}
        {translations.newAddressTemplateText.replace(
          "#{addressDisplayName}",
          props.event.details.newPlaceAddressDisplayName
        )}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentChangePositionComponent;
