enum RouteFinishedListingFilterType {
  CARGO_ORDER_HUMAN_ID = "CARGO_ORDER_HUMAN_ID",
  DRIVER = "DRIVER",
  PASSENGER = "PASSENGER",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  HUMAN_ID = "HUMAN_ID",
  TAXI_CORPORATION = "TAXI_CORPORATION",
}

export default RouteFinishedListingFilterType;
