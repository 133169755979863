import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import CargoTaxiContractListingTableColumn from "./types/cargo-taxi-contract-listing-table-column";

const getActivityStatusText = (isContractActive: boolean): string => {
  const translations =
    cargoTranslationsHelper.getTaxiContractListingTranslations();

  return isContractActive
    ? translations.table.activityStatus.activeText
    : translations.table.activityStatus.inactiveText;
};

const getTableColumns = (): CargoTaxiContractListingTableColumn[] => {
  const translations =
    cargoTranslationsHelper.getTaxiContractListingTranslations().table.headers;

  const tableColumns: CargoTaxiContractListingTableColumn[] = [
    {
      header: translations.taxiCorporationLabel,
      title: translations.taxiCorporationTitle,
      accessor: "taxiCorporation",
      colSpan: 10,
    },
    {
      header: translations.validSinceLabel,
      title: translations.validSinceTitle,
      accessor: "validSince",
      colSpan: 10,
    },
    {
      header: translations.validToLabel,
      title: translations.validToTitle,
      accessor: "validTo",
      colSpan: 10,
    },
    {
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      accessor: "billingModel",
      colSpan: 10,
    },
    {
      header: translations.tollRoadsWhileApproachingAllowedLabel,
      title: translations.tollRoadsWhileApproachingAllowedTitle,
      accessor: "isTollRoadsWhileApproachingAllowed",
      colSpan: 10,
    },
    {
      header: translations.tollRoadsWhileReturningAllowedLabel,
      title: translations.tollRoadsWhileReturningAllowedTitle,
      accessor: "isTollRoadsWhileReturningAllowed",
      colSpan: 10,
    },
    {
      header: translations.tollRoadsDuringOrderAllowedLabel,
      title: translations.tollRoadsDuringOrderAllowedTitle,
      accessor: "isTollRoadsDuringOrderAllowed",
      colSpan: 10,
    },
    {
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      accessor: "distanceRate",
      colSpan: 10,
    },
    {
      header: translations.discountPercentLimitLabel,
      title: translations.discountPercentLimitTitle,
      accessor: "discountPercentLimit",
      colSpan: 10,
    },
    {
      header: translations.stoppingRateLabel,
      title: translations.stoppingRateTitle,
      accessor: "stoppingRate",
      colSpan: 10,
    },
    {
      header: translations.freeStoppingPeriodLabel,
      title: translations.freeStoppingPeriodTitle,
      accessor: "freeStoppingPeriodMinutes",
      colSpan: 10,
    },
    {
      header: translations.orderPublishingAllowedLabel,
      title: translations.orderPublishingAllowedTitle,
      accessor: "isOrderPublishingAllowed",
      colSpan: 10,
    },
    {
      header: translations.activityStatusLabel,
      title: translations.activityStatusTitle,
      accessor: "activityStatus",
      colSpan: 10,
    },
  ];

  return tableColumns;
};

const getDistanceRateLabel = (distanceRate: number) => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getDistanceRateTitle = (distanceRate: number) => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getDiscountPercentLimitLabel = (discountLimit: number) => {
  return `${discountLimit}%`;
};

const getDiscountPercentLimitTitle = (discountLimit: number) => {
  return `${discountLimit}%`;
};

const getStoppingRateLabel = (stoppingRate: number) => {
  return `${String(stoppingRate.toFixed(2))} PLN/h`;
};

const getStoppingRateTitle = (stoppingRate: number) => {
  return `${String(stoppingRate.toFixed(2))} PLN/h`;
};

const cargoTaxiContractListingHelper = {
  getActivityStatusText,
  getTableColumns,
  getDistanceRateLabel,
  getDistanceRateTitle,
  getDiscountPercentLimitLabel,
  getDiscountPercentLimitTitle,
  getStoppingRateLabel,
  getStoppingRateTitle,
};

export default cargoTaxiContractListingHelper;
