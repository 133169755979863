import TaxiCargoContractListingItem from "../types/taxi-cargo-contract-listing-item";
import TaxiCargoContractListingSortKey from "../types/taxi-cargo-contract-listing-sort-key";

const sortListingItemsByBillingModelAsc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemA.billingModel.localeCompare(itemB.billingModel)
  );
};

const sortListingItemsByBillingModelDesc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemB.billingModel.localeCompare(itemA.billingModel)
  );
};

const sortListingItemsByCargoCompanyAsc = (
  listingItems: TaxiCargoContractListingItem[]
) => {
  return listingItems.sort((itemA, itemB) =>
    itemA.cargoCompany.localeCompare(itemB.cargoCompany)
  );
};

const sortListingItemsByCargoCompanyDesc = (
  listingItems: TaxiCargoContractListingItem[]
) => {
  return listingItems.sort((itemA, itemB) =>
    itemB.cargoCompany.localeCompare(itemA.cargoCompany)
  );
};

const sortListingItemsByDiscountPercentLimitAsc = (
  listingItems: TaxiCargoContractListingItem[]
) => {
  return listingItems.sort(
    (itemA, itemB) => itemA.discountPercentLimit - itemB.discountPercentLimit
  );
};

const sortListingItemsByDiscountPercentLimitDesc = (
  listingItems: TaxiCargoContractListingItem[]
) => {
  return listingItems.sort(
    (itemA, itemB) => itemB.discountPercentLimit - itemA.discountPercentLimit
  );
};

const sortListingItemsByDistanceRateAsc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.distanceRate - itemB.distanceRate
  );
};

const sortListingItemsByDistanceRateDesc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.distanceRate - itemA.distanceRate
  );
};

const sortListingItemsByHaltingTimeGracePeriodAsc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemA.freeStoppingPeriodMinutes - itemB.freeStoppingPeriodMinutes
  );
};

const sortListingItemsByHaltingTimeGracePeriodDesc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemB.freeStoppingPeriodMinutes - itemA.freeStoppingPeriodMinutes
  );
};

const sortListingItemsByHaltingTimeRateAsc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.stoppingRate - itemB.stoppingRate
  );
};

const sortListingItemsByHaltingTimeRateDesc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.stoppingRate - itemA.stoppingRate
  );
};

const sortListingItemsByPeriodOfValidityFromAsc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.validFrom.getTime() - itemA.validFrom.getTime()
  );
};

const sortListingItemsByPeriodOfValidityFromDesc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.validFrom.getTime() - itemB.validFrom.getTime()
  );
};

const sortListingItemsByPeriodOfValidityToAsc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.validTo.getTime() - itemA.validTo.getTime()
  );
};

const sortListingItemsByPeriodOfValidityToDesc = (
  listingItems: TaxiCargoContractListingItem[]
): TaxiCargoContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.validTo.getTime() - itemB.validTo.getTime()
  );
};

const sortListingItems = (
  listingItems: TaxiCargoContractListingItem[],
  sortKey: TaxiCargoContractListingSortKey | null
): TaxiCargoContractListingItem[] => {
  switch (sortKey) {
    case TaxiCargoContractListingSortKey.BILLING_MODEL_ASC:
      return sortListingItemsByBillingModelAsc(listingItems);
    case TaxiCargoContractListingSortKey.BILLING_MODEL_DESC:
      return sortListingItemsByBillingModelDesc(listingItems);
    case TaxiCargoContractListingSortKey.CARGO_COMPANY_ASC:
      return sortListingItemsByCargoCompanyAsc(listingItems);
    case TaxiCargoContractListingSortKey.CARGO_COMPANY_DESC:
      return sortListingItemsByCargoCompanyDesc(listingItems);
    case TaxiCargoContractListingSortKey.DISCOUNT_PERCENT_LIMIT_ASC:
      return sortListingItemsByDiscountPercentLimitAsc(listingItems);
    case TaxiCargoContractListingSortKey.DISCOUNT_PERCENT_LIMIT_DESC:
      return sortListingItemsByDiscountPercentLimitDesc(listingItems);
    case TaxiCargoContractListingSortKey.DISTANCE_RATE_ASC:
      return sortListingItemsByDistanceRateAsc(listingItems);
    case TaxiCargoContractListingSortKey.DISTANCE_RATE_DESC:
      return sortListingItemsByDistanceRateDesc(listingItems);
    case TaxiCargoContractListingSortKey.FREE_STOPPING_PERIOD_ASC:
      return sortListingItemsByHaltingTimeGracePeriodAsc(listingItems);
    case TaxiCargoContractListingSortKey.FREE_STOPPING_PERIOD_DESC:
      return sortListingItemsByHaltingTimeGracePeriodDesc(listingItems);
    case TaxiCargoContractListingSortKey.STOPPING_RATE_ASC:
      return sortListingItemsByHaltingTimeRateAsc(listingItems);
    case TaxiCargoContractListingSortKey.STOPPING_RATE_DESC:
      return sortListingItemsByHaltingTimeRateDesc(listingItems);
    case TaxiCargoContractListingSortKey.PERIOD_OF_VALIDITY_FROM_ASC:
      return sortListingItemsByPeriodOfValidityFromAsc(listingItems);
    case TaxiCargoContractListingSortKey.PERIOD_OF_VALIDITY_FROM_DESC:
      return sortListingItemsByPeriodOfValidityFromDesc(listingItems);
    case TaxiCargoContractListingSortKey.PERIOD_OF_VALIDITY_TO_ASC:
      return sortListingItemsByPeriodOfValidityToAsc(listingItems);
    case TaxiCargoContractListingSortKey.PERIOD_OF_VALIDITY_TO_DESC:
      return sortListingItemsByPeriodOfValidityToDesc(listingItems);
    default:
      return listingItems;
  }
};

const taxiCargoContractListingSortService = { sortListingItems };

export default taxiCargoContractListingSortService;
