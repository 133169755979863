import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import driverAddFormHelper from "../../common/driver-add-form.helper";
import DriverAddByRailyUserFormData from "./types/driver-add-by-raily-user-form-data";
import DriverAddByRailyUserFormValidationResults from "./types/driver-add-by-raily-user-form-validation-results";

const getDefaultUserFormData = (): DriverAddByRailyUserFormData => {
  return {
    firstName: "",
    lastName: "",
    mobile: phoneNumberInputHelper.getEmptyValue(),
    alternativeMobile: phoneNumberInputHelper.getEmptyValue(),
    birthDate: null,
    birthPlace: "",
    personalIdNumber: "",
    citizenship: null,
    languages: [],
    yearsOfExperience: null,
    formOfEmployment: null,
    taxiCorporation: null,
    fleetPartner: null,
    addresses: [],
  };
};

const getDefaultUserFormValidationResults =
  (): DriverAddByRailyUserFormValidationResults => {
    return {
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
      alternativeMobile: formValidationService.defaultValidationResult,
      birthDate: formValidationService.defaultValidationResult,
      birthPlace: formValidationService.defaultValidationResult,
      personalIdNumber: formValidationService.defaultValidationResult,
      citizenship: formValidationService.defaultValidationResult,
      languages: formValidationService.defaultValidationResult,
      yearsOfExperience: formValidationService.defaultValidationResult,
      formOfEmployment: formValidationService.defaultValidationResult,
      taxiCorporation: formValidationService.defaultValidationResult,
      fleetPartner: formValidationService.defaultValidationResult,
      addresses: formValidationService.defaultValidationResult,
    };
  };

const driverAddByRailyFormHelper = {
  getCitizenshipSelectOptions: driverAddFormHelper.getCitizenshipSelectOptions,
  getFormOfEmploymentSelectOptions:
    driverAddFormHelper.getFormOfEmploymentSelectOptions,
  getLanguageSelectOptions: driverAddFormHelper.getLanguageSelectOptions,
  getVehicleOwnershipSelectOptions:
    driverAddFormHelper.getVehicleOwnershipSelectOptions,
  getDefaultAccountFormData: driverAddFormHelper.getDefaultAccountFormData,
  getDefaultAccountFormValidationResults:
    driverAddFormHelper.getDefaultAccountFormValidationResults,
  getDefaultUserFormData,
  getDefaultUserFormValidationResults,
  getDefaultCompanyFormData: driverAddFormHelper.getDefaultCompanyFormData,
  getDefaultCompanyFormValidationResults:
    driverAddFormHelper.getDefaultCompanyFormValidationResults,
  getDefaultVehicleFormData: driverAddFormHelper.getDefaultVehicleFormData,
  getDefaultVehicleFormValidationResults:
    driverAddFormHelper.getDefaultVehicleFormValidationResults,
};

export default driverAddByRailyFormHelper;
