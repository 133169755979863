import settlementRoutesUrls from "./settlement-routes-urls";

const getCargoListingOfOrderSettlementRoute = (): string => {
  return settlementRoutesUrls.cargoListingOfOrderSettlements;
};

const getTaxiListingOfSettledOrdersRoute = (): string => {
  return settlementRoutesUrls.taxiListingOfSettledOrders;
};

const getTaxiListingOfSettledRoutesRoute = (): string => {
  return settlementRoutesUrls.taxiListingOfSettledRoutes;
};

const getTaxiListingOfUnsettledOrdersRoute = (): string => {
  return settlementRoutesUrls.taxiListingOfUnsettledOrders;
};

const getTaxiListingOfUnsettledPrivateOrdersRoute = (): string => {
  return settlementRoutesUrls.taxiListingOfUnsettledPrivateOrders;
};

const getTaxiListingOfUnsettledRoutesRoute = (): string => {
  return settlementRoutesUrls.taxiListingOfUnsettledRoutes;
};

const getTaxiListingOfUnsettledTransferedOrdersRoute = (): string => {
  return settlementRoutesUrls.taxiListingOfUnsettledTransferedOrders;
};

const getRailyListingOfUnsettledOrdersRoute = (): string => {
  return settlementRoutesUrls.railyListingOfUnsettledOrders;
};

const getRailyListingOfSettledOrdersRoute = (): string => {
  return settlementRoutesUrls.railyListingOfSettledOrders;
};

const getTaxiListingOfSettledTransferedOrdersRoute = (): string => {
  return settlementRoutesUrls.taxiListingOfSettledTransferedOrders;
};

const settlementRoutesHelper = {
  getCargoListingOfOrderSettlementRoute,
  getTaxiListingOfSettledOrdersRoute,
  getTaxiListingOfSettledRoutesRoute,
  getTaxiListingOfUnsettledOrdersRoute,
  getTaxiListingOfUnsettledPrivateOrdersRoute,
  getTaxiListingOfUnsettledRoutesRoute,
  getTaxiListingOfUnsettledTransferedOrdersRoute,
  getRailyListingOfUnsettledOrdersRoute,
  getRailyListingOfSettledOrdersRoute,
  getTaxiListingOfSettledTransferedOrdersRoute,
};

export default settlementRoutesHelper;
