import listingFilterService from "../../../../../../common/utils/listing/filters/listing-filter.service";
import CargoTaxiContractListingBillingModel from "../types/cargo-taxi-contract-listing-billing-model";
import CargoTaxiContractListingFilter, {
  CargoTaxiContractListingBillingModelFilter,
} from "../types/cargo-taxi-contract-listing-filter";
import CargoTaxiContractListingFilterType from "../types/cargo-taxi-contract-listing-filter-type";
import CargoTaxiContractListingItem from "../types/cargo-taxi-contract-listing-item";

const filterListingItemsByBillingModel = (
  listingItems: CargoTaxiContractListingItem[],
  options: CargoTaxiContractListingBillingModel[]
) => {
  return listingFilterService.filterByEnumProperty(
    listingItems,
    "billingModel",
    options
  );
};

const filterListingItemsByTaxiCorporation = (
  listingItems: CargoTaxiContractListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByStringProperty(
    listingItems,
    "taxiCorporation",
    queries
  );
};

const filterListingItems = (
  listingItems: CargoTaxiContractListingItem[],
  filters: CargoTaxiContractListingFilter[]
): CargoTaxiContractListingItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: CargoTaxiContractListingItem[] = [...listingItems];

  const billingModelFilterQueries =
    listingFilterService.getValuesOfGivenFilterType(
      filters,
      CargoTaxiContractListingFilterType.BILLING_MODEL
    ) as CargoTaxiContractListingBillingModelFilter["value"][];

  if (billingModelFilterQueries.length) {
    filteredListingItems = filterListingItemsByBillingModel(
      filteredListingItems,
      billingModelFilterQueries
    );
  }

  const taxiCorporationFilterQueries =
    listingFilterService.getValuesOfGivenFilterType(
      filters,
      CargoTaxiContractListingFilterType.TAXI_CORPORATION
    );

  if (taxiCorporationFilterQueries.length) {
    filteredListingItems = filterListingItemsByTaxiCorporation(
      filteredListingItems,
      taxiCorporationFilterQueries
    );
  }

  return filteredListingItems;
};

const cargoTaxiContractListingFilterService = { filterListingItems };

export default cargoTaxiContractListingFilterService;
