import Joi from "joi";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";

const validateFirstName = (firstName: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).trim().required();

  return formValidationService.validate(firstName, validationSchema);
};

const validateLastName = (lastName: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).trim().required();

  return formValidationService.validate(lastName, validationSchema);
};

const validateMobile = (mobile: string): FormValidationResult => {
  const pattern = /^(\+\d{1,3}\d{8,}|\d{7,})$/;

  const validationSchema = Joi.string().pattern(pattern).required();

  return formValidationService.validate(mobile, validationSchema);
};

const validateDispatchName = (dispatchName: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").required();

  return formValidationService.validate(dispatchName, validationSchema);
};

const validateExternalId = (externalId: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").required();

  return formValidationService.validate(externalId, validationSchema);
};

const orderRouteEditPassengerValidationService = {
  validateFirstName,
  validateLastName,
  validateMobile,
  validateDispatchName,
  validateExternalId,
};

export default orderRouteEditPassengerValidationService;
