enum OrderDetailsHistoryChangeRideEventType {
  ADD_CARGO_ENTER = "ADD_CARGO_ENTER",
  ADD_CARGO_EXIT = "ADD_CARGO_EXIT",
  CHANGE_HALTING_TIME = "CHANGE_HALTING_TIME",
  CHANGE_POSITION = "CHANGE_POSITION",
  CHANGE_TIME = "CHANGE_TIME",
  CREATE_INNER_RIDE = "CREATE_INNER_RIDE",
  DELETE_NODE = "DELETE_NODE",
  DO_NOTHING = "DO_NOTHING",
  INSERT_NODE_BEFORE = "INSERT_NODE_BEFORE",
  INSERT_NODE_AFTER = "INSERT_NODE_AFTER",
  REMOVE_CARGO_ENTER = "REMOVE_CARGO_ENTER",
  REMOVE_CARGO_EXIT = "REMOVE_CARGO_EXIT",
  SQUASH_INNER_RIDE = "SQUASH_INNER_RIDE",
}

export default OrderDetailsHistoryChangeRideEventType;
