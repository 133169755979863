import CargoCompanyMileageContractDetailsRequest from "../../../../api/raily/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details.request";
import CargoCompanyMileageContractDetailsLoadParams from "./cargo-company-mileage-contract-details-load-params";

const create = (
  params: CargoCompanyMileageContractDetailsLoadParams
): CargoCompanyMileageContractDetailsRequest => {
  return {
    cargoCompanyUuid: params.cargoCompanyUuid,
    validAt: params.validAt,
  };
};

const cargoCompanyMileageContractDetailsRequestFactory = {
  create,
};

export default cargoCompanyMileageContractDetailsRequestFactory;
