import { FC, useMemo } from "react";
import OrderOptimizerListingItem from "../common/types/order-optimizer-listing-item";
import { useAppContext } from "../../../../context/app.context";
import OrderOptimizerListingTableRow from "../common/types/order-optimizer-listing-table-row";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import OrderOptimizerListingTableColumn from "../common/types/order-optimizer-listing-table-column";
import orderOptimizerListingTableHelper from "./order-optimizer-listing-table.helpers";
import OrderOptimizerListingTableRowComponent from "./row/order-optimizer-listing-table-row.component";

type OrderOptimizerListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: OrderOptimizerListingItem[];
  selectedHumanId: number | undefined;
};

const OrderOptimizerListingTableComponent: FC<
  OrderOptimizerListingTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(
    (): OrderOptimizerListingTableColumn[] =>
      orderOptimizerListingTableHelper.getColumns(),
    []
  );

  const createTableRow = (
    listingItem: OrderOptimizerListingItem
  ): OrderOptimizerListingTableRow => {
    return {
      id: listingItem.uuid,
      value: {
        row: (
          <OrderOptimizerListingTableRowComponent
            listingItem={listingItem}
            selectedHumanId={props.selectedHumanId}
          />
        ),
      },
    };
  };

  const rows: OrderOptimizerListingTableRow[] = useMemo(() => {
    return props.listingItems.map((list) => createTableRow(list));
  }, [props.listingItems, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderOptimizerListingTableComponent;
