import { FC, useEffect, useMemo, useState, KeyboardEvent, useRef } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCircleExclamation,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import MessengerChannel from "./types/messenger-channel";
import MessengerChannelData from "./types/messenger-channel-data";
import MessengerChannelOption from "./types/messenger-channel-option";
import messengerHelper from "./common/messenger.helper";
import MessengerChannelAvailability from "./types/messenger-channel-availability";
import BadgeComponent from "../badge/badge.component";
import BadgeType from "../badge/badge.type";
import ButtonComponent from "../button/button.component";
import LoaderComponent from "../loader/loader.component";
import InputComponent from "../form/input/input.component";
import User from "../../types/user";
import useMessengerPost from "../../services/messenger/post/use-messenger-post";
import useAbort from "../../hooks/use-abort";
import messengerPostLoadParamsFactory from "./common/messenger-post-load-params.factory";
import messengerFactory from "./common/messenger.factory";
import messengerSendPostLoadParamsFactory from "./common/messenger-send-post-load-params.factory";
import messengerService from "../../services/messenger/messenger.service";
import MessengerBillingType from "./types/messenger-billing-type";
import ComponentClassnames from "../../types/component-classnames";

type MessengerProps = {
  channelsAvailability: MessengerChannelAvailability;
  billingType?: MessengerBillingType;
  billingUuid?: string;
  classNames?: ComponentClassnames;
  orderUuid?: string;
  postsHeadingText?: string;
  planEntryUuid?: string;
  solvedOrderUuid?: string;
  withoutChannelList?: boolean;
};

const MessengerComponent: FC<MessengerProps> = (props) => {
  const translations =
    appTranslationsHelper.getComponentTranslations().messenger;

  const { user, selectedAppLanguage } = useAppContext();

  const messengerPost = useMessengerPost();
  const messengerPostAbort = useAbort();
  const messengerReloadPostAbort = useAbort();
  const messengerSendPostAbort = useAbort();

  const [channelPostsMap, setChannelPostsMap] = useState<
    Map<MessengerChannel, MessengerChannelData>
  >(new Map());

  const [newMessage, setNewMessage] = useState("");

  const [isPostSending, setIsPostSending] = useState(false);

  const [selectedChannelOption, setSelectedChannelOption] =
    useState<MessengerChannelOption | null>(null);

  const messageInputRef = useRef<HTMLInputElement>(null);

  const channelOptions: MessengerChannelOption[] = useMemo(() => {
    if (!user?.roles) {
      return [];
    }

    return messengerHelper.getChannelOptions(
      user.roles,
      props.channelsAvailability
    );
  }, [user?.roles, selectedAppLanguage]);

  useEffect(() => {
    if (!channelOptions.length) {
      return;
    }

    fetchPosts();
  }, [
    channelOptions,
    props.billingUuid,
    props.orderUuid,
    props.planEntryUuid,
    props.solvedOrderUuid,
  ]);

  useEffect(() => {
    if (!channelOptions.length) {
      setSelectedChannelOption(null);

      return;
    }

    const sharedChannel = channelOptions.find(
      (channel) => channel.channel === MessengerChannel.SHARED
    );

    setSelectedChannelOption(sharedChannel ?? channelOptions[0]);
  }, [channelOptions]);

  useEffect(() => {
    if (!messengerPost.data?.size) {
      return;
    }

    const channelsPostsMap = messengerFactory.createPosts(messengerPost.data);

    setChannelPostsMap(channelsPostsMap);
  }, [messengerPost.data]);

  const fetchPosts = async () => {
    if (!user) {
      return;
    }

    const taxiCorporationUuid = getTaxiCorporationUuidFromUser(user);

    const params = messengerPostLoadParamsFactory.create(
      channelOptions,
      props.billingType,
      props.billingUuid,
      props.orderUuid,
      props.planEntryUuid,
      props.solvedOrderUuid,
      taxiCorporationUuid
    );

    await messengerPost.load(params, messengerPostAbort.signal);
  };

  const fetchChannelsPosts = async (channel: MessengerChannel) => {
    if (!user) {
      return;
    }

    const taxiCorporationUuid = getTaxiCorporationUuidFromUser(user);

    const params = messengerPostLoadParamsFactory.createReloadParams(
      channel,
      props.billingType,
      props.billingUuid,
      props.orderUuid,
      props.planEntryUuid,
      props.solvedOrderUuid,
      taxiCorporationUuid
    );

    await messengerPost.reload(params, messengerReloadPostAbort.signal);
  };

  const sendPost = async () => {
    if (!user || !newMessage || !selectedChannelOption) {
      return;
    }

    setIsPostSending(true);

    const taxiCorporationUuid = getTaxiCorporationUuidFromUser(user);

    const params = messengerSendPostLoadParamsFactory.create(
      selectedChannelOption.channel,
      newMessage,
      props.billingType,
      props.billingUuid,
      props.orderUuid,
      props.planEntryUuid,
      props.solvedOrderUuid,
      taxiCorporationUuid
    );

    try {
      await messengerService.sendPost(params, messengerSendPostAbort.signal);

      onPostSendSuccess();
    } catch {
      onPostSendFailure();
    } finally {
      setIsPostSending(false);
    }
  };

  const onPostSendSuccess = () => {
    setNewMessage("");

    const selectedChannel = selectedChannelOption?.channel;

    if (selectedChannel) {
      fetchChannelsPosts(selectedChannel);
    }

    messageInputRef.current?.focus();
  };

  const onPostSendFailure = () => {
    messageInputRef.current?.focus();
  };

  const getTaxiCorporationUuidFromUser = (user: User): string | undefined => {
    const taxiCorporationUuid =
      user.aspects.dealer?.taxiCorporationUuid ??
      user.aspects.taxiOfficer?.taxiCorporationUuid;

    return taxiCorporationUuid;
  };

  const onChannelListItemClick = (channel: MessengerChannelOption) =>
    setSelectedChannelOption(channel);

  const onNewMessageInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      sendPost();
    }
  };

  const rootClassnames = classNames("messenger", props.classNames?.root);

  const selectedChannelData =
    !!selectedChannelOption &&
    channelPostsMap.get(selectedChannelOption.channel);

  return (
    <div className={rootClassnames}>
      {!props.withoutChannelList && (
        <div className="messenger_channels">
          <div className="messenger_channels__heading">
            {translations.headingText}
          </div>
          <ul className="messenger_channels__list">
            {channelOptions.map((channelOption) => {
              const isSelected =
                selectedChannelOption?.channel === channelOption.channel;

              return (
                <li
                  className="messenger_channels__item"
                  onClick={() => onChannelListItemClick(channelOption)}
                  key={channelOption.channel}
                >
                  <div
                    className={classNames(
                      "messenger_channels__item__status",
                      isSelected && "selected"
                    )}
                  ></div>
                  <div
                    className="messenger_channels__item__label"
                    title={channelOption.title}
                  >
                    <div className="messenger_channels__item__label__label">
                      {channelOption.label}
                    </div>
                    <div className="messenger_channels__item__label__count">
                      {channelPostsMap.get(channelOption.channel) && (
                        <BadgeComponent
                          type={BadgeType.BRAND}
                          classNames={{
                            root: "messenger_channels__item__label__count__badge",
                          }}
                        >
                          {
                            channelPostsMap.get(channelOption.channel)?.posts
                              .length
                          }
                        </BadgeComponent>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <div className="messenger_posts">
        <div className="messenger_posts__heading">
          <div
            className="messenger_posts__heading__text"
            title={props.postsHeadingText}
          >
            {props.postsHeadingText}
          </div>
          <ButtonComponent
            type="primary"
            onClick={fetchPosts}
            isDisabled={messengerPost.isLoading}
            title={translations.refreshMessageListButtonTitle}
            classNames={{ root: "p-2" }}
          >
            <FontAwesomeIcon
              icon={faArrowsRotate}
              size="lg"
              className="messenger_posts__sending__icon"
            />
          </ButtonComponent>
        </div>
        {messengerPost.isLoading ? (
          <div className="messenger_posts__loader_wrapper">
            <LoaderComponent type="primary" />
          </div>
        ) : (
          selectedChannelData && (
            <ul className="messenger_posts__list">
              {selectedChannelData.isError ? (
                <li className="messenger_posts__error_wrapper">
                  <div className="messenger_posts__error_content">
                    <FontAwesomeIcon icon={faCircleExclamation} size="5x" />
                    <div className="messenger_posts__error_content__text">
                      {translations.fetchingPostsErrorText}
                    </div>
                  </div>
                </li>
              ) : selectedChannelData.posts.length === 0 ? (
                <li className="messenger_posts__no_messages_wrapper">
                  <div className="messenger_posts__no_messages_text">
                    {translations.noPostsText}
                  </div>
                </li>
              ) : (
                selectedChannelData.posts.map((post, index) => {
                  const isOwnPost = user?.uuid === post.authorUuid;
                  const isRailyPost = post.authorCompany === "Raily";

                  const content = messengerHelper.getContentTranslation(
                    post.content
                  );

                  return (
                    <li
                      key={`post-index-${index}`}
                      className={classNames(
                        "messenger_posts__item",
                        isOwnPost && "own",
                        isRailyPost && "raily",
                        props.billingType === MessengerBillingType.CARGO &&
                          messengerHelper.getPostColor(post.postType)
                      )}
                    >
                      <div className="messenger_posts__item__details_wrapper">
                        <div className="messenger_posts__item__details">
                          {`${
                            post.authorFullName
                          } ${messengerHelper.getFormattedMessageSentDateTime(
                            post.date
                          )}`}
                        </div>
                        <div className="messenger_posts__item__details_company">
                          {post.authorCompany}
                        </div>
                      </div>
                      <div className="messenger_posts__item__content">
                        {content}
                      </div>
                    </li>
                  );
                })
              )}
            </ul>
          )
        )}
        <div className="messenger_posts__sending">
          <InputComponent
            inputRef={messageInputRef}
            value={newMessage}
            onChange={setNewMessage}
            isReadOnly={isPostSending}
            isDisabled={!selectedChannelOption}
            placeholder={translations.newMessageInputPlaceholder}
            classNames={{
              root: "messenger_posts__sending__input",
            }}
            onKeyDown={onNewMessageInputKeyDown}
          />
          <ButtonComponent
            type="success"
            onClick={sendPost}
            isLoading={isPostSending}
            isDisabled={isPostSending}
            title={translations.newMessageSubmitButtonTitle}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              className="messenger_posts__sending__icon"
            />
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default MessengerComponent;
