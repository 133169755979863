import { faIcons } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import orderOptimizerRouteHelper from "../routes/order-optimizer-routes.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";

const getListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: orderOptimizerRouteHelper.getListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .orderOptimizerListing,
    },
  ];
};

const orderOptimizerBreadcrumbsHelper = { getListingBreadcrumbs };

export default orderOptimizerBreadcrumbsHelper;
