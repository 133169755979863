import HttpResponse from "../../../../../../../common/utils/http/http.response";

export enum DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType {
  NO_TAXI_DRIVER_ASSOCIATION = "NO_TAXI_DRIVER_ASSOCIATION",
  NO_VALID_TAXI_DRIVER_CONTRACT = "NO_VALID_TAXI_DRIVER_CONTRACT",
  NO_VALID_TAXI_RAILY_CONTRACT = "NO_VALID_TAXI_RAILY_CONTRACT",
  NO_VALID_CARGO_RAILY_CONTRACT = "NO_VALID_CARGO_RAILY_CONTRACT",
  TARGET_PLAN_ENTRY_NOT_FOUND = "TARGET_PLAN_ENTRY_NOT_FOUND",
  CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME = "CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME",
  CANDIDATE_DOES_NOT_FIT = "CANDIDATE_DOES_NOT_FIT",
  TOO_MANY_CHECKOUT_EVENTS = "TOO_MANY_CHECKOUT_EVENTS",
  CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION = "CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION",
  DELETE_SOLVED_ORDER_TARGET_NOT_FOUND = "DELETE_SOLVED_ORDER_TARGET_NOT_FOUND",
  PLAN_ENTRY_CANDIDATE_COLLISION = "PLAN_ENTRY_CANDIDATE_COLLISION",
  FORBIDDEN_TAXI = "FORBIDDEN_TAXI",
  FORBIDDEN_PLAN_ENTRY = "FORBIDDEN_PLAN_ENTRY",
  PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR = "PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR",
}

export type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseNoTaxiDriverAssociationError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION;
    driver_id: string;
    taxi_id: string;
  };

export type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseNoValidTaxiDriverContractError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT;
    driver_id: string;
    taxi_id: string;
  };

export type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseNoValidTaxiRailyContractError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT;
  };

export type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseNoValidCargoRailyContractError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.NO_VALID_CARGO_RAILY_CONTRACT;
    driver_id: string;
    taxi_id: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseTargetPlanEntryNotFoundError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.TARGET_PLAN_ENTRY_NOT_FOUND;
    candidature_entry_id: string;
    provided_plan_entry_id: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseCandidateFitsWithNotEnoughTimeError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME;
    candidature_id: string;
    candidature_entry_id: string;
    colliding_solved_order_id: string;
    candidature_solved_order_id: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseTooManyCheckoutEventsError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseCandidateDoesNotFitError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.CANDIDATE_DOES_NOT_FIT;
    candidature_id: string;
    candidature_entry_id: string;
    candidature_solved_order_id: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseCandidatePlanEntryCollidesError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION;
    candidature_id: string;
    candidature_entry_id: string;
    provided_plan_entry_id: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseDeleteSolvedOrderTargetNotFoundError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND;
    candidature_id: string;
    candidature_entry_id: string;
    targetSolvedOrderId: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponsePlanEntryCandidateCollisionError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_COLLISION;
    candidature_id: string;
    candidature_entry_id: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseForbiddenTaxiError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.FORBIDDEN_TAXI;
    provided_taxi_id: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseForbiddenPlanEntryError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.FORBIDDEN_PLAN_ENTRY;
    provided_plan_entry_id: string;
  };

type DriverDetailsAcceptPlanCandidatureNotAcceptableResponsePlanEntryCandidateStartTimeExpiredError =
  {
    error_type: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR;
    candidature_id: string;
    candidature_entry_id: string;
  };

export type DriverDetailsAcceptPlanCandidatureNotAcceptableResponseError =
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseNoTaxiDriverAssociationError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseNoValidTaxiDriverContractError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseNoValidTaxiRailyContractError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseNoValidCargoRailyContractError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseTargetPlanEntryNotFoundError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseCandidateFitsWithNotEnoughTimeError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseCandidateDoesNotFitError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseCandidatePlanEntryCollidesError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseDeleteSolvedOrderTargetNotFoundError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponsePlanEntryCandidateCollisionError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseForbiddenTaxiError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseForbiddenPlanEntryError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponseTooManyCheckoutEventsError
  | DriverDetailsAcceptPlanCandidatureNotAcceptableResponsePlanEntryCandidateStartTimeExpiredError;

export type DriverDetailsAcceptPlanCandidatureErrorResponse =
  HttpResponse<DriverDetailsAcceptPlanCandidatureNotAcceptableResponseError>;

export default DriverDetailsAcceptPlanCandidatureErrorResponse;
