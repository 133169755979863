import { FC, useEffect, useState } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../../../../../common/components/modal/modal.component";
import FormFieldComponent from "../../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../../common/components/form/input/input.component";
import ButtonComponent from "../../../../../../../../common/components/button/button.component";
import { OrderRouteEditInternalPassenger } from "../../../../types/order-route-edit-passenger";
import OrderRouteEditInternalPassengerEditFormData from "./common/types/order-route-edit-internal-passenger-edit-form-data";
import orderRouteEditInternalPassengerEditHelper from "./common/order-route-edit-internal-passenger-edit-form.helper";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import OrderRouteEditInternalPassengerEditFormValidationResults from "./common/types/order-route-edit-internal-passenger-edit-form-validation-results";
import Row from "../../../../../../../../common/components/grid/row";
import Column from "../../../../../../../../common/components/grid/column";
import orderRouteEditInternalPassengerEditFormValidationService from "./order-route-edit-internal-passenger-edit-form-validation.service";

type OrderRouteEditInternalPassengerEditProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  passenger: OrderRouteEditInternalPassenger;
  onSubmit: (passenger: OrderRouteEditInternalPassenger) => void;
};

const OrderRouteEditInternalPassengerEditComponent: FC<
  OrderRouteEditInternalPassengerEditProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getEditTranslations().passengers.edit;

  const [formData, setFormData] =
    useState<OrderRouteEditInternalPassengerEditFormData>(() =>
      orderRouteEditInternalPassengerEditHelper.getDefaultFormData(
        props.passenger
      )
    );

  const [formValidationResults, setFormValidationResults] =
    useState<OrderRouteEditInternalPassengerEditFormValidationResults>(() =>
      orderRouteEditInternalPassengerEditHelper.getDefaultFormValidationResults()
    );

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    setFormValidationResults(
      orderRouteEditInternalPassengerEditHelper.getDefaultFormValidationResults()
    );
    setFormData(
      orderRouteEditInternalPassengerEditHelper.getDefaultFormData(
        props.passenger
      )
    );
  }, [props.isOpen]);

  const setExternalId = (
    value: OrderRouteEditInternalPassengerEditFormData["externalId"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      externalId: value,
    }));
  };

  const validateExternalId = (
    value: OrderRouteEditInternalPassengerEditFormData["externalId"] = formData.externalId
  ) => {
    const validationResult =
      orderRouteEditInternalPassengerEditFormValidationService.validateExternalId(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      externalId: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateForm = () => {
    const isExternalIdValid = validateExternalId();

    return isExternalIdValid;
  };

  const onSubmitButtonClick = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    const newPassenger: OrderRouteEditInternalPassenger = {
      ...props.passenger,
      externalId: formData.externalId.trim(),
    };

    props.onSubmit(newPassenger);
  };

  const onCloseButtonClick = () => {
    props.onClose();
  };

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={{
        title: translations.headingText.replace(
          "#{passengerFullName}",
          `${props.passenger.firstName} ${props.passenger.lastName}`
        ),
      }}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          title={translations.submitButtonTitle}
          idForTesting="order-route-edit-internal-passenger-edit-submit-button"
        >
          {translations.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={onCloseButtonClick}
          type="brand"
          title={translations.cancelButtonTitle}
          idForTesting="order-route-edit-internal-passenger-edit-cancel-button"
        >
          {translations.cancelButtonText}
        </ButtonComponent>,
      ]}
    >
      <Row>
        <Column>
          <FormFieldComponent
            label={translations.form.externalIdLabel}
            errorMessage={formValidationResults.externalId.errorMessage}
          >
            <InputComponent
              value={formData.externalId}
              onChange={setExternalId}
              placeholder={translations.form.externalIdPlaceholder}
              hasError={!!formValidationResults.externalId.errorMessage}
              onBlur={validateExternalId}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </ModalComponent>
  );
};

export default OrderRouteEditInternalPassengerEditComponent;
