import MileageAddRequest, {
  MileageAddRequestBody,
} from "./mileage-add.request";

const createBody = (request: MileageAddRequest): MileageAddRequestBody => {
  return {
    addressSequence: {
      seq: request.addressSequenceNodes,
    },
    cardNumber: request.cardNumber,
    commissionNumber: request.commissionNumber,
    distance: request.distance,
    mileageDate: request.mileageDate,
    mileageNumber: request.mileageNumber,
    notes: request.notes,
    vehicleType: request.vehicleType,
    workerCompanyId: request.workerCompanyId,
    workerId: request.workerId,
    osrmDistance: request.osrmDistance,
  };
};

const mileageAddRequestFactory = {
  createBody,
};

export default mileageAddRequestFactory;
