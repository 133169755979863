import taxiOrderSettlementListingRouteQueryParamsService from "../../common/taxi-order-settlement-listing-route-query-params.service";
import TaxiOrderSettlementListingFilter from "../../common/types/taxi-order-settlement-listing-filter";
import TaxiOrderSettlementListingSortKey from "../../common/types/taxi-order-settlement-listing-sort-key";
import TaxiOrderSettlementListingByRailyRouteQueryParams from "./types/taxi-order-settlement-listing-by-raily-route-query-params";
import TaxiOrderSettlementListingByRailyTaxiCorporation from "./types/taxi-order-settlement-listing-by-raily-taxi-corporation";
import Joi from "joi";

const createRouteQueryParams = (
  taxiCorporationUuid:
    | TaxiOrderSettlementListingByRailyTaxiCorporation["uuid"]
    | null,
  filters: TaxiOrderSettlementListingFilter[],
  sortKey: TaxiOrderSettlementListingSortKey | null,
  page: number,
  pageSize: number
): TaxiOrderSettlementListingByRailyRouteQueryParams => {
  const commonParams =
    taxiOrderSettlementListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return {
    ...commonParams,
    taxiCorporation: taxiCorporationUuid ?? undefined,
  };
};

const getTaxiCorporationCompanyUuid = (
  routeQueryParams: TaxiOrderSettlementListingByRailyRouteQueryParams
): string | undefined => {
  if (!validateTaxiCorporationUuid(routeQueryParams.taxiCorporation)) {
    return undefined;
  }

  return routeQueryParams.taxiCorporation;
};

const validateTaxiCorporationUuid = (
  routeQueryTaxiCorporationParam: TaxiOrderSettlementListingByRailyRouteQueryParams["taxiCorporation"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryTaxiCorporationParam).error
    ?.message;
};

const taxiOrderSettlementListingByRailyRouteQueryParamsService = {
  getFilters: taxiOrderSettlementListingRouteQueryParamsService.getFilters,
  getSortKey: taxiOrderSettlementListingRouteQueryParamsService.getSortKey,
  getPage: taxiOrderSettlementListingRouteQueryParamsService.getPage,
  getPageSize: taxiOrderSettlementListingRouteQueryParamsService.getPageSize,
  getTaxiCorporationCompanyUuid,
  createRouteQueryParams,
};

export default taxiOrderSettlementListingByRailyRouteQueryParamsService;
