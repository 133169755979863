export enum PlannerDriverPlanListRequestMatchType {
  DRIVER = "DRIVER",
  PLAN_ENTRY = "PLAN_ENTRY",
}

export enum PlannerDriverPlanListRequestFilterType {
  ADDRESS = "ADDRESS",
  DRIVER_NAME = "DRIVER_NAME",
  CARGO_ORDER = "CARGO_ORDER",
  NONE = "NONE",
}

export enum PlannerDriverPlanListRequestSorterType {
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  DISTANCE_TO_ADDRESS = "DISTANCE_TO_ADDRESS",
  DISTANCE_TO_POINT = "DISTANCE_TO_POINT",
  DRIVER_NAME = "DRIVER_NAME",
}

export enum PlannerDriverPlanListRequestSorterOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export type PlannerDriverPlanListRequestResultOrder =
  | PlannerDriverPlanListRequestDriverNameSorter
  | PlannerDriverPlanListRequestStartDateSorter
  | PlannerDriverPlanListRequestFinishDateSorter
  | PlannerDriverPlanListRequestDistanceToAddressSorter
  | PlannerDriverPlanListRequestDistanceToPointSorter;

export type PlannerDriverPlanListRequestDriverNameSorter = {
  sorterType: PlannerDriverPlanListRequestSorterType.DRIVER_NAME;
  order: PlannerDriverPlanListRequestSorterOrder;
};

export type PlannerDriverPlanListRequestStartDateSorter = {
  sorterType: PlannerDriverPlanListRequestSorterType.START_DATE;
  order: PlannerDriverPlanListRequestSorterOrder;
};

export type PlannerDriverPlanListRequestFinishDateSorter = {
  sorterType: PlannerDriverPlanListRequestSorterType.END_DATE;
  order: PlannerDriverPlanListRequestSorterOrder;
};

export type PlannerDriverPlanListRequestDistanceToAddressSorter = {
  sorterType: PlannerDriverPlanListRequestSorterType.DISTANCE_TO_ADDRESS;
  order: PlannerDriverPlanListRequestSorterOrder;
  relativeTo: string;
  rangeFrom: Date | null;
  rangeTo: Date | null;
};

export type PlannerDriverPlanListRequestDistanceToPointSorter = {
  sorterType: PlannerDriverPlanListRequestSorterType.DISTANCE_TO_POINT;
  order: PlannerDriverPlanListRequestSorterOrder;
  lat: number;
  lon: number;
  rangeFrom: Date | null;
  rangeTo: Date | null;
};

export type PlannerDriverPlanListRequestAddressFilter = {
  filterType: PlannerDriverPlanListRequestFilterType.ADDRESS;
  address: string;
  timeStart: Date | null;
  timeEnd: Date | null;
};

export type PlannerDriverPlanListRequestDriverNameFilter = {
  filterType: PlannerDriverPlanListRequestFilterType.DRIVER_NAME;
  driverNameQuery: string;
};

export type PlannerDriverPlanListRequestCargoOrderFilter = {
  filterType: PlannerDriverPlanListRequestFilterType.CARGO_ORDER;
  orderIdQuery: string;
};

export type PlannerDriverPlanListRequestNoneFilter = {
  filterType: PlannerDriverPlanListRequestFilterType.NONE;
};

export type PlannerDriverPlanListRequestFilter =
  | PlannerDriverPlanListRequestAddressFilter
  | PlannerDriverPlanListRequestDriverNameFilter
  | PlannerDriverPlanListRequestCargoOrderFilter
  | PlannerDriverPlanListRequestNoneFilter;

export type PlannerDriverPlanListRequestBody = {
  matchTo: PlannerDriverPlanListRequestMatchType[] | undefined;
  filter: PlannerDriverPlanListRequestFilter;
  resultOrder: PlannerDriverPlanListRequestResultOrder | undefined;
};

export type PlannerDriverPlanListRequestQueryParams = {
  orderId: string | undefined;
  taxiCorporationId: string | undefined;
  offset: number | undefined;
  limit: number | undefined;
};

type PlannerDriverPlanListRequest = {
  orderId?: string;
  taxiCorporationId?: string;
  offset?: number;
  limit?: number;
  matchTo?: PlannerDriverPlanListRequestMatchType[];
  filter: PlannerDriverPlanListRequestFilter;
  resultOrder?: PlannerDriverPlanListRequestResultOrder;
};

export default PlannerDriverPlanListRequest;
