import ListingFilterBadge from "../../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import DriverContractListingByTaxiFilter from "../../common/types/driver-contract-listing-by-taxi-filter";
import DriverContractListingByTaxiFilterType from "../../common/types/driver-contract-listing-by-taxi-filter-type";

const getBadgeOptions = (filter: DriverContractListingByTaxiFilter) => {
  const translations =
    userTranslationsHelper.getDriverContractListingTranslations().filters;

  const options: {
    badge: ListingFilterBadge;
    filter: DriverContractListingByTaxiFilter;
  }[] = [
    {
      badge: {
        text: translations.billingModelFilterText.replace(
          "#{option}",
          filter.value
        ),
        title: translations.billingModelFilterText.replace(
          "#{option}",
          filter.value
        ),
      },
      filter: {
        type: DriverContractListingByTaxiFilterType.BILLING_MODEL,
        value: filter.value,
      },
    },
  ];

  return options;
};

const getBadge = (
  filter: DriverContractListingByTaxiFilter
): ListingFilterBadge | undefined => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: DriverContractListingByTaxiFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const driverListingByTaxiFiltersBadgeListHelper = {
  getBadges,
};

export default driverListingByTaxiFiltersBadgeListHelper;
