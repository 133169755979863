import DriverContractListingByRailyFilter from "../../common/types/driver-contract-listing-by-raily-filter";
import ListingFilterBadgeListComponent from "../../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";
import driverContractListingByRailyFiltersBadgeListHelper from "./driver-contract-listing-by-raily-filters-badge-list.helper";
import { FC } from "react";

type DriverContractListingByRailyFiltersBadgeListProps = {
  filters: DriverContractListingByRailyFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const DriverContractListingByRailyFiltersBadgeListComponent: FC<
  DriverContractListingByRailyFiltersBadgeListProps
> = (props) => {
  const badges = driverContractListingByRailyFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default DriverContractListingByRailyFiltersBadgeListComponent;
