import HttpError from "../http/http.error";
import {
  DataLoadErrorType,
  DataLoadOfflineError,
  DataLoadAuthError,
  DataLoadUnexpectedError,
} from "./data-load-error";

const createUnexpectedError = (): DataLoadUnexpectedError => {
  return {
    type: DataLoadErrorType.UNEXPECTED,
    params: {},
    message: "Wystąpił nieoczekiwany błąd",
  };
};

const createOfflineError = (): DataLoadOfflineError => {
  return {
    type: DataLoadErrorType.OFFLINE,
    params: {},
    message:
      "Wystąpił błąd z połączeniem internetowym. Sprawdź swoje połączenie i spróbuj ponownie.",
  };
};

const createAuthError = (): DataLoadAuthError => {
  return {
    type: DataLoadErrorType.UNAUTHORIZED,
    params: {},
    message: "Wystąpił błąd autoryzacji",
  };
};

const handleHttpError = (httpError: HttpError) => {};

const dataLoadErrorFactory = {
  createUnexpectedError,
  createOfflineError,
  createAuthError,
};

export default dataLoadErrorFactory;
