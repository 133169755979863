import MileageListingActionData from "./types/mileage-listing-action-data";
import MileageListingItem from "./types/mileage-listing-item";

const create = (listingItem: MileageListingItem): MileageListingActionData => {
  return {
    humanId: listingItem.humanId,
    mileageUuid: listingItem.uuid,
    workerUuid: listingItem.workerUuid,
    workerCompanyUuid: listingItem.workerCompanyUuid,
  };
};

const mileageListingActionDataFactory = {
  create,
};

export default mileageListingActionDataFactory;
