import userFormValidationService from "../../../../../../shared/user/user.validation.service";

const cargoOfficerAddByCargoFormValidationService = {
  validateUsername: userFormValidationService.validateUsername,
  validatePassword: userFormValidationService.validatePassword,
  validatePasswordRepeat: userFormValidationService.validatePasswordRepeat,
  validateFirstName: userFormValidationService.validateFirstName,
  validateLastName: userFormValidationService.validateLastName,
  validatePhoneNumber: userFormValidationService.validateMobile,
  validateAlternativePhoneNumber:
    userFormValidationService.validateAlternativeMobile,
  validateEmail: userFormValidationService.validateEmail,
};

export default cargoOfficerAddByCargoFormValidationService;
