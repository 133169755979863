import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import DelegationDetailsPassenger from "../common/types/delegation-details-passenger";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import delegationDetailsHelper from "../common/delegation-details.helper";

type DelegationDetailsPassengerProps = {
  passenger: DelegationDetailsPassenger | undefined;
};

const DelegationDetailsPassengerComponent: FC<
  DelegationDetailsPassengerProps
> = (props) => {
  const translations =
    delegationTranslationsHelper.getDelegationDetailsTranslations().worker;

  if (!props.passenger) {
    return null;
  }

  return (
    <div className="delegation_details_passengers">
      <div>{translations.headingLabel}</div>
      <ul className="delegation_details_passengers_list">
        <li
          className={"delegation_details_passengers_item"}
          key={`passenger-${props.passenger.uuid}`}
          title={delegationDetailsHelper.getPassengerTitle(props.passenger)}
        >
          <FontAwesomeIcon
            icon={faUser}
            className="delegation_details_passengers_item__icon"
          />
          <div className="delegation_details_passengers_item__name">
            {props.passenger.displayName}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DelegationDetailsPassengerComponent;
