import AppLanguage from "../../../../languages/types/app-language";
import DatePickerLocale from "./date-picker-locale";

const getDatePickerLocale = (language: AppLanguage): string => {
  switch (language) {
    case AppLanguage.PL:
      return DatePickerLocale.PL;
    case AppLanguage.EN:
      return DatePickerLocale.EN;
    case AppLanguage.DE:
      return DatePickerLocale.DE;
    default:
      return DatePickerLocale.PL;
  }
};

const datePickerHelper = {
  getDatePickerLocale,
};

export default datePickerHelper;
