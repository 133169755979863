import { FC, useMemo } from "react";
import { useAppContext } from "../../../../../context/app.context";
import { CargoCompanyDispatchListItem } from "../../../../services/cargo-company/dispatch/list/cargo-company-dispatch-list";
import CargoCompanyDispatchSelectOption from "./cargo-company-dispatch-select-option";
import SingleSelectComponent from "../single-select/single-select.component";
import cargoCompanyDispatchSelectOptionFactory from "./cargo-company-dispatch-select-option.factory";

type CargoCompanyDispatchSelectProps = {
  placeholder: string;
  selectedDispatcherUuid: string;
  dispatchList: CargoCompanyDispatchListItem[];
  onChange: (value: CargoCompanyDispatchListItem | null) => void;
  onBlur: () => void;
  isError: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  idForTesting?: string;
};

const CargoCompanyDispatchSelectComponent: FC<
  CargoCompanyDispatchSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const selectOptions: CargoCompanyDispatchSelectOption[] = useMemo(
    () =>
      cargoCompanyDispatchSelectOptionFactory.createOptions(props.dispatchList),
    [props.dispatchList, selectedAppLanguage]
  );

  const selectedOption: CargoCompanyDispatchSelectOption | null =
    useMemo(() => {
      return !props.selectedDispatcherUuid
        ? null
        : selectOptions.find(
            (option) => option.value.uuid === props.selectedDispatcherUuid
          ) ?? null;
    }, [props.selectedDispatcherUuid, selectOptions]);

  return (
    <SingleSelectComponent
      placeholder={props.placeholder}
      value={selectedOption}
      onChange={(value) => props.onChange(value?.value ?? null)}
      onBlur={props.onBlur}
      options={selectOptions}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      hasError={props.isError}
      idForTesting={props.idForTesting}
      isSearchable
    />
  );
};

export default CargoCompanyDispatchSelectComponent;
