import { FC } from "react";
import { InputProps, GroupBase, components } from "react-select";
import SelectOption from "../option/select-option";

type SelectInputProps = InputProps<
  SelectOption,
  boolean,
  GroupBase<SelectOption>
> & {
  idForTesting: string | undefined;
};

const SelectInputComponent: FC<SelectInputProps> = (props) => {
  const { idForTesting, ...propsRest } = props;

  return (
    <components.Input
      {...Object.assign({}, propsRest, {
        "data-test-id": idForTesting,
      })}
    />
  );
};

export default SelectInputComponent;
