import HttpResponse from "../../../../utils/http/http.response";

export enum CargoOfficerListResponseItemContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type CargoOfficerListResponseItemTaxiOfficer = {
  cargoCompanyId: string;
  cargoCompany: CargoOfficerListResponseItemTaxiCorporation | null;
};

export type CargoOfficerListResponseItemTaxiCorporation = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: CargoOfficerListResponseItemContractMaintenancePolicy;
  notes: string | null;
  id: string;
  modifiedAt: string | null;
  modifiedBy: string | null;
  createdAt: string;
  createdBy: string;
};

export type CargoOfficerListResponseItem = {
  removedBy: string | null;
  removedAt: Date | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
  cargoOfficer: CargoOfficerListResponseItemTaxiOfficer;
};

type CargoOfficerListResponse = HttpResponse<{
  data: CargoOfficerListResponseItem[];
  totalCount: number;
}>;

export default CargoOfficerListResponse;
