import orderRouteEditHelper from "../../../helper/order-route-edit.helper";
import OrderRouteEditPassenger, {
  OrderRouteEditInternalPassenger,
} from "../../../types/order-route-edit-passenger";
import OrderRouteEditPassengerListItem from "../../../types/order-route-edit-passenger-list-item";
import orderRouteEditRoutesFactory from "../order-route-edit-routes.factory";

const getPassengerButtonLabel = (
  passenger: OrderRouteEditPassenger
): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const getPassengerButtonTitle = (
  passenger: OrderRouteEditPassenger
): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const getPassengerIconTitle = (passenger: OrderRouteEditPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const getPassengerAddressesSelectOptions = (
  passengerList: OrderRouteEditPassengerListItem[]
) => {
  const internalPassengers = passengerList
    .map((listItem) => listItem.passenger)
    .filter((passenger) =>
      orderRouteEditHelper.checkIsInternalPassenger(passenger)
    ) as OrderRouteEditInternalPassenger[];

  return internalPassengers
    .map((passenger) => {
      return passenger.addresses.map((address) => {
        return orderRouteEditRoutesFactory.createRouteAddressSelectOptionFromPassengerAddress(
          passenger,
          address
        );
      });
    })
    .flat();
};

const orderRouteEditRouteWaypointHelper = {
  getPassengerButtonLabel,
  getPassengerButtonTitle,
  getPassengerIconTitle,
  getPassengerAddressesSelectOptions,
};

export default orderRouteEditRouteWaypointHelper;
