import { FC, useEffect, useMemo, useState } from "react";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import DateTimeInputComponent from "../../../../common/components/form/input/date-time/date-time-input.component";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import useAbort from "../../../../common/hooks/use-abort";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import delegationBreadcrumbsHelper from "../../common/breadcrumps/delegation-breadcrumbs.helper";
import DelegationAddWorkersComponent from "../common/components/worker/delegation-add-worker.component";
import DelegationAddBasicProps from "../common/types/delegation-add-basic-props";
import useForm from "../../../../common/components/form/use-form";
import delegationAddFormHelper from "../common/form/delegation-add-form.helper";
import DelegationAddWorker from "../common/types/delegation-add-worker";
import useCargoCompanyDetails from "../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";
import DelegationAddRoutesComponent from "../common/components/address/delegation-add-routes.component";
import delegationAddAddressFactory from "../common/factory/delegation-add-address.factory";
import DelegationAddAddress from "../common/types/delegation-add-address";
import DelegationAddRouteGroup from "../common/types/delegation-add-route-group";
import DelegationAddSummaryComponent from "../common/components/summary/delegation-add-summary.component";
import CargoCompanyDetailsParams from "../../../../common/services/cargo-company/cargo-company/details/cargo-company-details-params";
import MapRoute from "../../../../common/components/map/types/map-route";
import useCargoCompanyMileageContractDetails from "../../../../common/services/cargo-company/mileage-contract/details/use-cargo-company-mileage-contract-details";
import CargoCompanyMileageContractDetailsLoadParams from "../../../../common/services/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details-load-params";
import { round } from "lodash";
import DelegationAddVehicleType from "../common/types/delegation-add-vehicle-type";

type CargoDelegationAddProps = DelegationAddBasicProps;

const CargoDelegationAddComponent: FC<CargoDelegationAddProps> = (props) => {
  const { user, setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations();

  useEffect(() => {
    const breadcrumbs = delegationBreadcrumbsHelper.getAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const cargoCompanyMileageContractDetails =
    useCargoCompanyMileageContractDetails();
  const cargoCompanyMileageContractDetailsAbort = useAbort();

  const [worker, setWorker] = useState<DelegationAddWorker | null>(null);

  const [delegationAddGroups, setDelegationAddGroups] = useState<
    DelegationAddRouteGroup[]
  >([]);

  const [groupVehicleTypes, setGroupVehicleTypes] = useState<
    DelegationAddVehicleType[]
  >([]);

  const mapRoutes = useMemo(() => {
    const routes: MapRoute[] = [];

    delegationAddGroups.forEach((group) => {
      if (group.mapRoute) {
        routes.push(group.mapRoute);
      }
    });

    return routes;
  }, [delegationAddGroups]);

  const mapWaypoints = useMemo(() => {
    return delegationAddGroups.map((group) => group.mapWaypoints);
  }, [delegationAddGroups]);

  const cargoCompanyDetails = useCargoCompanyDetails();
  const cargoCompanyDetailsAbort = useAbort();

  useDocumentTitle(documentTitleTranslations.mileageDelegationAdd);

  const form = useForm({
    emptyValues: delegationAddFormHelper.getDefaultFormData(),
    validationDefinition: delegationAddFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    if (!user?.aspects.cargoOfficer?.cargoCompanyUuid) {
      return;
    }

    const params: CargoCompanyDetailsParams = {
      cargoCompanyUuid: user.aspects.cargoOfficer.cargoCompanyUuid,
    };

    cargoCompanyDetails.load(params, cargoCompanyDetailsAbort.signal);

    return cargoCompanyDetailsAbort.revoke;
  }, []);

  useEffect(() => {
    form.validate("addressSequence");
  }, [delegationAddGroups]);

  useEffect(() => {
    form.validate("totalDistance");
    form.validate("osrmDistance");
  }, [form.values.totalDistance]);

  useEffect(() => {
    loadCargoMileageContract(
      form.values.workerCompanyUuid,
      form.values.dateTo ?? undefined
    );
  }, [form.values.workerCompanyUuid, form.values.dateTo]);

  useEffect(() => {
    if (!cargoCompanyDetails.data) return;

    form.setValue("workerCompanyUuid", cargoCompanyDetails.data.uuid);
  }, [cargoCompanyDetails.data]);

  const loadCargoMileageContract = (
    cargoCompanyUuid: string,
    validityDate?: Date
  ) => {
    const loadParams: CargoCompanyMileageContractDetailsLoadParams = {
      cargoCompanyUuid: cargoCompanyUuid,
      validAt: validityDate,
    };

    cargoCompanyMileageContractDetails.load(
      loadParams,
      cargoCompanyMileageContractDetailsAbort.signal
    );
  };

  const onRoutesChange = (groups: DelegationAddRouteGroup[]) => {
    setDelegationAddGroups(groups);

    const seq: DelegationAddAddress[][] =
      delegationAddAddressFactory.createAdressesFromRouteGroups(groups);

    form.setValue("addressSequence", seq);

    const distance = round(
      groups
        .map((group) => group.distance)
        .reduce((previous, current) => {
          return (previous ?? 0) + (current ?? 0);
        }) ?? 0
    );

    const mileagesCost = round(
      groups
        .map((groups) => groups.groupCost)
        .reduce((previous, current) => previous + current),
      2
    );

    setGroupVehicleTypes(groups.map((group) => group.vehicleType));

    form.setValue("totalDistance", distance);
    form.setValue("osrmDistance", distance);
    form.setValue("mileagesCost", mileagesCost);

    if (groups.length > 0) {
      const dateTo = groups[groups.length - 1].endDate;
      form.setValue("dateFrom", groups[0].startDate);
      form.setValue("dateTo", dateTo);
    }
  };

  const onWorkerChange = (worker: DelegationAddWorker | null) => {
    setWorker(worker);
    setDelegationAddGroups([]);

    form.setValue("workerUuid", worker?.uuid ?? "");
    form.setValue("addressSequence", []);
  };

  const passengerAddresses = useMemo(() => {
    return delegationAddAddressFactory.createPassengerAddresses(worker);
  }, [worker, selectedAppLanguage]);

  const restoreForm = () => {
    setWorker(null);
    setDelegationAddGroups([]);
    setGroupVehicleTypes([]);
    cargoCompanyDetails.reset();
    form.restore();
  };

  return (
    <>
      <div className="delegation_add">
        <HeadingComponent
          title={translations.header.headingLabel}
          actions={props.changeViewButtons}
        />
        <Row>
          <Column xl={7}>
            <CardComponent header={{ title: translations.headingLabel }}>
              <Row>
                <Column lg={12}>
                  <FormFieldComponent
                    label={translations.workers.headingLabel}
                    isRequired
                    errorMessage={
                      form.validationResults.workerCompanyUuid.errorMessage
                    }
                  >
                    <DelegationAddWorkersComponent
                      worker={worker}
                      onWorkerChange={onWorkerChange}
                      onBlur={() => {
                        form.validate("workerUuid");
                      }}
                      idForTesting="cargo-delegation-add-worker"
                      workerCompanyUuid={form.values.workerCompanyUuid}
                      hasError={
                        !!form.validationResults.workerUuid.errorMessage &&
                        form.values.workerCompanyUuid !== null
                      }
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.startDate.headingLabel}
                    isRequired
                    errorMessage={form.validationResults.dateFrom.errorMessage}
                  >
                    <DateTimeInputComponent
                      date={form.values.dateFrom}
                      onChange={() => {}}
                      isDisabled
                      idForTesting="cargo-delegation-add-date-from"
                      placeholder={translations.startDate.selectPlaceholder}
                      hasError={!!form.validationResults.dateFrom.errorMessage}
                      maxDate={form.values.dateTo ?? undefined}
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.endDate.headingLabel}
                    isRequired
                    errorMessage={form.validationResults.dateTo.errorMessage}
                  >
                    <DateTimeInputComponent
                      date={form.values.dateTo}
                      onChange={() => {}}
                      isDisabled
                      idForTesting="cargo-delegation-add-date-to"
                      placeholder={translations.endDate.selectPlaceholder}
                      hasError={!!form.validationResults.dateTo.errorMessage}
                      minDate={form.values.dateFrom ?? undefined}
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={12}>
                  <FormFieldComponent
                    label={translations.routes.headingLabel}
                    isRequired
                    errorMessage={
                      form.validationResults.addressSequence.errorMessage
                    }
                  >
                    <DelegationAddRoutesComponent
                      cargoCompanyUuid={form.values.workerCompanyUuid}
                      isAddButtonDisabled={
                        !form.values.workerCompanyUuid ||
                        !form.values.workerUuid
                      }
                      additionalAddresses={passengerAddresses}
                      groups={delegationAddGroups}
                      onGroupsChange={onRoutesChange}
                      mileageContract={cargoCompanyMileageContractDetails.data}
                      idForTesting="cargo-delegation-add-routes"
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
            </CardComponent>
            <CardComponent
              header={{ title: translations.additionalDataForm.headingLabel }}
            >
              <Row>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.additionalDataForm.prepay.headingLabel}
                    errorMessage={form.validationResults.prepay.errorMessage}
                  >
                    <NumericInputComponent
                      value={form.values.prepay}
                      onChange={(value) => {
                        form.setValue("prepay", value);
                      }}
                      onBlur={() => {
                        form.validate("prepay");
                      }}
                      idForTesting="cargo-delegation-add-prepay"
                      placeholder={
                        translations.additionalDataForm.prepay.selectPlaceholder
                      }
                      hasError={!!form.validationResults.prepay.errorMessage}
                      decimalPrecision={2}
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={6}>
                  <FormFieldComponent
                    label={
                      translations.additionalDataForm.oneDayDelegation
                        .headingLabel
                    }
                    errorMessage={
                      form.validationResults.oneDayDietCost.errorMessage
                    }
                  >
                    <NumericInputComponent
                      value={form.values.oneDayDietCost}
                      onChange={(value) => {
                        form.setValue("oneDayDietCost", value);
                      }}
                      onBlur={() => {
                        form.validate("oneDayDietCost");
                      }}
                      idForTesting="cargo-delegation-add-one-day-diet-cost"
                      placeholder={
                        translations.additionalDataForm.oneDayDelegation
                          .selectPlaceholder
                      }
                      hasError={
                        !!form.validationResults.oneDayDietCost.errorMessage
                      }
                      decimalPrecision={2}
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={6}>
                  <FormFieldComponent
                    label={
                      translations.additionalDataForm.longerDelegation
                        .startedDaysHeadingLabel
                    }
                    errorMessage={
                      form.validationResults.startedDayDietCost.errorMessage
                    }
                  >
                    <NumericInputComponent
                      value={form.values.startedDayDietCost}
                      onChange={(value) => {
                        form.setValue("startedDayDietCost", value);
                      }}
                      onBlur={() => {
                        form.validate("startedDayDietCost");
                      }}
                      idForTesting="cargo-delegation-add-started-day-diet-cost"
                      placeholder={
                        translations.additionalDataForm.longerDelegation
                          .startedDaysSelectPlaceholder
                      }
                      hasError={
                        !!form.validationResults.startedDayDietCost.errorMessage
                      }
                      decimalPrecision={2}
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={6}>
                  <FormFieldComponent
                    label={
                      translations.additionalDataForm.longerDelegation
                        .wholeDaysHeadingLabel
                    }
                    errorMessage={
                      form.validationResults.wholeDayDietCost.errorMessage
                    }
                  >
                    <NumericInputComponent
                      value={form.values.wholeDayDietCost}
                      onChange={(value) => {
                        form.setValue("wholeDayDietCost", value);
                      }}
                      onBlur={() => {
                        form.validate("wholeDayDietCost");
                      }}
                      idForTesting="cargo-delegation-add-whole-day-diet-cost"
                      placeholder={
                        translations.additionalDataForm.longerDelegation
                          .wholeDaysSelectPlaceholder
                      }
                      hasError={
                        !!form.validationResults.wholeDayDietCost.errorMessage
                      }
                      decimalPrecision={2}
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={6}>
                  <FormFieldComponent
                    label={
                      translations.additionalDataForm.accommodation
                        .lumpSumHeadingLabel
                    }
                    errorMessage={
                      form.validationResults.accommodationCost.errorMessage
                    }
                  >
                    <NumericInputComponent
                      value={form.values.accommodationCost}
                      onChange={(value) => {
                        form.setValue("accommodationCost", value);
                      }}
                      onBlur={() => {
                        form.validate("accommodationCost");
                      }}
                      idForTesting="cargo-delegation-add-accommodation-cost"
                      placeholder={
                        translations.additionalDataForm.accommodation
                          .lumpSumSelectPlaceholder
                      }
                      hasError={
                        !!form.validationResults.accommodationCost.errorMessage
                      }
                      decimalPrecision={2}
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={6}>
                  <FormFieldComponent
                    label={
                      translations.additionalDataForm.accommodation
                        .billsHeadingLabel
                    }
                    errorMessage={
                      form.validationResults.accommodationBillsCost.errorMessage
                    }
                  >
                    <NumericInputComponent
                      value={form.values.accommodationBillsCost}
                      onChange={(value) => {
                        form.setValue("accommodationBillsCost", value);
                      }}
                      onBlur={() => {
                        form.validate("accommodationBillsCost");
                      }}
                      idForTesting="cargo-delegation-add-accommodation-bills-cost"
                      placeholder={
                        translations.additionalDataForm.accommodation
                          .sumSelectPlaceholder
                      }
                      hasError={
                        !!form.validationResults.accommodationBillsCost
                          .errorMessage
                      }
                      decimalPrecision={2}
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.additionalDataForm.lumpSum.headingLabel}
                    errorMessage={
                      form.validationResults.localTravelCost.errorMessage
                    }
                  >
                    <NumericInputComponent
                      value={form.values.localTravelCost}
                      onChange={(value) => {
                        form.setValue("localTravelCost", value);
                      }}
                      onBlur={() => {
                        form.validate("localTravelCost");
                      }}
                      idForTesting="cargo-delegation-add-local-travel-cost"
                      placeholder={
                        translations.additionalDataForm.lumpSum
                          .selectPlaceholder
                      }
                      hasError={
                        !!form.validationResults.localTravelCost.errorMessage
                      }
                      decimalPrecision={2}
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={6}>
                  <FormFieldComponent
                    label={
                      translations.additionalDataForm.otherExpenses.headingLabel
                    }
                    errorMessage={
                      form.validationResults.otherExpenses.errorMessage
                    }
                  >
                    <NumericInputComponent
                      value={form.values.otherExpenses}
                      onChange={(value) => {
                        form.setValue("otherExpenses", value);
                      }}
                      onBlur={() => {
                        form.validate("otherExpenses");
                      }}
                      idForTesting="cargo-delegation-add-other-expenses"
                      placeholder={
                        translations.additionalDataForm.otherExpenses
                          .selectPlaceholder
                      }
                      hasError={
                        !!form.validationResults.otherExpenses.errorMessage
                      }
                      decimalPrecision={2}
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
            </CardComponent>
          </Column>
          <Column xl={5}>
            <DelegationAddSummaryComponent
              groupVehicleTypes={groupVehicleTypes}
              workerName={worker?.displayName}
              companyName={cargoCompanyDetails.data?.displayName ?? ""}
              formData={form.values}
              validateForm={form.validateAll}
              restoreForm={restoreForm}
              mapRoutes={mapRoutes}
              mapWaypoints={mapWaypoints.flat()}
              mileageContract={cargoCompanyMileageContractDetails.data}
            />
          </Column>
        </Row>
      </div>
    </>
  );
};

export default CargoDelegationAddComponent;
