import { FC, useState, useRef, useMemo, useEffect } from "react";
import Column from "../../../common/components/grid/column";
import Row from "../../../common/components/grid/row";
import LoaderComponent from "../../../common/components/loader/loader.component";
import MapComponent from "../../../common/components/map/map.component";
import MapMarker from "../../../common/components/map/types/map-marker";
import MapRoute from "../../../common/components/map/types/map-route";
import MessengerComponent from "../../../common/components/messenger/messenger.component";
import useMileageRoadRoute from "../../../common/services/mileage/road-route/use-mileage-road-route";
import { useAppContext } from "../../../context/app.context";
import mileageTranslationsHelper from "../../../languages/mileage-translations.helper";
import MileageListingItem from "../listing/common/types/mileage-listing-item";
import mileageDetailsInternalFactory from "./common/factory/mileage-details-internal.factory";
import mileageDetailsMapMarkersFactory from "./common/factory/mileage-details-map-markers.factory";
import mileageDetailsPassengerFactory from "./common/factory/mileage-details-passenger.factory";
import mileageDetailsRouteFactory from "./common/factory/mileage-details-route.factory";
import mileageDetailsWaypointFactory from "./common/factory/mileage-details-waypoint.factory";
import mileageDetailsHelper from "./common/mileage-details.helper";
import MileageDetailsRouteWaypoint from "./common/types/mileage-details-route-waypoint";
import MileageDetailsPassengersComponent from "./passenger/mileage-details-passengers.component";
import MileageDetailsRouteComponent from "./route/mileage-details-route.component";
import MileageDetailsToolsComponent from "./tools/mileage-details-tools.component";
import MileageDetailsToolsProps from "./tools/types/mileage-details-tools-props";
import mileageDetailsUserPermissionsHelper from "./user-permissions/mileage-details-user-permission.helper";

type MileageDetailsProps = {
  row: MileageListingItem;
  isVisible: boolean;
  reloadRows: () => void;
};

const MileageDetailsComponent: FC<MileageDetailsProps> = (props) => {
  const { user } = useAppContext();
  const [waypoints, setWaypoints] = useState<MileageDetailsRouteWaypoint[]>([]);
  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);
  const leftColumnContentRef = useRef<HTMLDivElement>(null);

  const roadRoute = useMileageRoadRoute();

  const translations =
    mileageTranslationsHelper.getMileageDetailsTranslations();

  const mileageDetails = useMemo(
    () => mileageDetailsInternalFactory.create(props.row),
    [props.row]
  );

  const passengerDetails = useMemo(
    () => [mileageDetailsPassengerFactory.create(props.row)],
    [props.row]
  );

  const userPermissions = mileageDetailsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const mapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers =
      mileageDetailsMapMarkersFactory.createMapMarkers(waypoints);

    return mapMarkers;
  }, [waypoints]);

  useEffect(() => {
    if (!props.isVisible) {
      return;
    }
    const preparedWaypoints = mileageDetailsWaypointFactory.createWaypoints(
      props.row.addressSeq
    );

    setWaypoints(preparedWaypoints);

    if (preparedWaypoints.length > 1) {
      const request =
        mileageDetailsWaypointFactory.createSearchRoutingRequest(
          preparedWaypoints
        );
      roadRoute.load(request);
    } else {
      setMapRoutes([]);
    }
  }, [props.row.addressSeq, props.isVisible]);

  useEffect(() => {
    if (!roadRoute.data) {
      return;
    }

    const route = mileageDetailsRouteFactory.createMapRoute(
      roadRoute.data.coordinates
    );
    setMapRoutes([route]);

    const fixedWaypoints = [...waypoints];

    fixedWaypoints[0].distance = 0;
    fixedWaypoints[0].completionDate = props.row.mileageDate;

    roadRoute.data.legs.forEach((leg, index) => {
      fixedWaypoints[index + 1].distance = leg.distance;
      fixedWaypoints[index + 1].completionDate =
        mileageDetailsWaypointFactory.createWaypointDate(
          fixedWaypoints[index].completionDate!,
          leg.duration
        );
    });

    setWaypoints(fixedWaypoints);
  }, [roadRoute.data]);

  const rightColumnContentHeight = useMemo(() => {
    return leftColumnContentRef.current?.offsetHeight || 500;
  }, [leftColumnContentRef.current?.offsetHeight]);

  const mileageDetailsToolsProps: MileageDetailsToolsProps = {
    mileageDetails: mileageDetails,
    userPermissions: userPermissions,
    onSuccess: props.reloadRows,
  };

  const mileageDetailsMessengerChannelsAvailability =
    mileageDetailsHelper.getMessengerChannelAvailability();

  const DetailsContent = (
    <Row>
      <Column lg={7}>
        <div ref={leftColumnContentRef}>
          <Row>
            <Column>
              <div className="mileage_details_map_wrapper">
                <MapComponent markers={mapMarkers} autoFit routes={mapRoutes} />
                <MileageDetailsToolsComponent {...mileageDetailsToolsProps} />
              </div>
            </Column>
          </Row>
          <Row>
            <Column>
              <div>
                {translations.mileageNumber}
                {props.row.mileageNumber ?? "----"}
              </div>
            </Column>
            <Column md={4} lg={3}>
              <MileageDetailsPassengersComponent
                passengers={passengerDetails}
                selectedPassenger={null}
                waypoints={waypoints}
              />
            </Column>
            <Column md={8} lg={9}>
              <MileageDetailsRouteComponent
                waypoints={waypoints}
                mileageUuid={props.row.uuid}
                selectedPassenger={null}
              />
            </Column>
          </Row>
        </div>
      </Column>
      <Column lg={5}>
        <div style={{ height: rightColumnContentHeight }}>
          <MessengerComponent
            orderUuid={props.row.uuid}
            channelsAvailability={mileageDetailsMessengerChannelsAvailability}
          />
        </div>
      </Column>
    </Row>
  );

  const LoaderContent = <LoaderComponent type="primary" />;

  return (
    <div className="mileage_details">
      {props.isVisible ? DetailsContent : LoaderContent}
    </div>
  );
};

export default MileageDetailsComponent;
