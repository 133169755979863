import taxiTranslationsHelper from "../../../../../languages/taxi-translations.helper";

const getHeadingLabel = (taxiCorporationName: string | undefined) => {
  const translations =
    taxiTranslationsHelper.getTaxiFleetPartnerListingTranslations();

  return `${translations.header.headingLabel}${
    taxiCorporationName ? `: ${taxiCorporationName}` : ""
  }`;
};

const taxiFleetPartnerListingHelper = { getHeadingLabel };

export default taxiFleetPartnerListingHelper;
