import { FC, useEffect, useState } from "react";
import ModalComponent from "../../../common/components/modal/modal.component";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import FormComponent from "../../../common/components/form/form.component";
import FormFieldComponent from "../../../common/components/form/field/form-field.component";
import InputComponent from "../../../common/components/form/input/input.component";
import ButtonComponent from "../../../common/components/button/button.component";
import AppHeaderUserChangePasswordFormValidationResults from "./common/types/app-header-user-change-password-data-validation.results";
import AppHeaderUserChangePasswordFormData from "./common/types/app-header-user-change-password-form-data";
import appHeaderUserChangePasswordValidationService from "./common/app-header-user-change-password-form-validation.service";
import appHeaderUserChangePasswordApiService from "./common/api/app-header-user-change-password-api.service";
import HttpResponse from "../../../common/utils/http/http.response";
import notificationService from "../../../common/utils/notification/notification.service";
import appHeaderUserChangePasswordFactory from "./common/factory/app-header-user-change-password.factory";
import appHeaderUserChangePasswordFormHelper from "./common/app-header-user-change-password-form.helper";

type AppHeaderUserChangePasswordProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AppHeaderUserChangePasswordComponent: FC<
  AppHeaderUserChangePasswordProps
> = (props) => {
  const translations =
    appTranslationsHelper.getAuthTranslations().changePassword;

  const [formData, setFormData] = useState<AppHeaderUserChangePasswordFormData>(
    appHeaderUserChangePasswordFormHelper.getDefaultFormData()
  );
  const [formValidationResults, setFormValidationResults] =
    useState<AppHeaderUserChangePasswordFormValidationResults>(
      appHeaderUserChangePasswordFormHelper.getDefaultFormValidationResults()
    );
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    setFormData(appHeaderUserChangePasswordFormHelper.getDefaultFormData());
    setFormValidationResults(
      appHeaderUserChangePasswordFormHelper.getDefaultFormValidationResults()
    );
  }, [props.isOpen]);

  const close = () => {
    props.onClose();
    setFormData(appHeaderUserChangePasswordFormHelper.getDefaultFormData());
    setFormValidationResults(
      appHeaderUserChangePasswordFormHelper.getDefaultFormValidationResults()
    );
  };

  const setCurrentPassword = (
    value: AppHeaderUserChangePasswordFormData["currentPassword"]
  ) => setFormData({ ...formData, currentPassword: value });

  const setNewPassword = (
    value: AppHeaderUserChangePasswordFormData["newPassword"]
  ) => setFormData({ ...formData, newPassword: value });

  const setRepeatNewPassword = (
    value: AppHeaderUserChangePasswordFormData["repeatNewPassword"]
  ) => setFormData({ ...formData, repeatNewPassword: value });

  const validateCurrentPassword = () => {
    const validationResult =
      appHeaderUserChangePasswordValidationService.validatePassword(
        formData.currentPassword
      );

    setFormValidationResults((curr) => ({
      ...curr,
      currentPassword: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateNewPassword = () => {
    const validationResult =
      appHeaderUserChangePasswordValidationService.validatePasswordsNotEqual(
        formData.currentPassword,
        formData.newPassword
      );

    setFormValidationResults((curr) => ({
      ...curr,
      newPassword: validationResult,
    }));

    if (!!formData.repeatNewPassword) {
      validatePasswordRepeat();
    }

    return validationResult.isValid;
  };

  const validatePasswordRepeat = () => {
    const validationResult =
      appHeaderUserChangePasswordValidationService.validatePasswordRepeat(
        formData.newPassword,
        formData.repeatNewPassword
      );

    setFormValidationResults((curr) => ({
      ...curr,
      repeatNewPassword: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsFormValid = () => {
    const isCurrentPasswordValid = validateCurrentPassword();
    const isNewPasswordValid = validateNewPassword();
    const isRepeatNewPasswordValid = validatePasswordRepeat();

    return (
      isCurrentPasswordValid && isNewPasswordValid && isRepeatNewPasswordValid
    );
  };

  const handleSubmitResponse = (response: HttpResponse) => {
    if (response.status === 202) {
      notificationService.success(translations.successNotificationLabel);
      props.onClose();

      return;
    }

    notificationService.error(translations.failureNotificationLabel);
  };

  const submitForm = () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid) {
      return;
    }

    setIsFormSubmitting(true);

    const request =
      appHeaderUserChangePasswordFactory.createChangePasswordRequest(formData);

    appHeaderUserChangePasswordApiService
      .changePassword(request)
      .then(handleSubmitResponse)
      .finally(() => setIsFormSubmitting(false));
  };

  return (
    <ModalComponent
      header={{ title: translations.headingLabel }}
      isOpen={props.isOpen}
      onClose={close}
      actions={[
        <ButtonComponent
          onClick={submitForm}
          isDisabled={isFormSubmitting}
          type="primary"
          idForTesting="user-data-change-password-submit-button"
          title={translations.form.submitButtonTitle}
        >
          {translations.form.submitButtonLabel}
        </ButtonComponent>,
        <ButtonComponent
          onClick={close}
          type="brand"
          title={translations.form.rejectButtonTitle}
        >
          {translations.form.rejectButtonLabel}
        </ButtonComponent>,
      ]}
    >
      <FormComponent onSubmit={submitForm}>
        <FormFieldComponent
          label={translations.form.currentPasswordLabel}
          isRequired
          errorMessage={formValidationResults.currentPassword.errorMessage}
        >
          <InputComponent
            placeholder={translations.form.currentPasswordPlaceholder}
            value={formData.currentPassword}
            onChange={setCurrentPassword}
            onBlur={validateCurrentPassword}
            type="password"
            hasError={!!formValidationResults.currentPassword.errorMessage}
            idForTesting="current-password"
          />
        </FormFieldComponent>
        <FormFieldComponent
          label={translations.form.newPasswordLabel}
          isRequired
          errorMessage={formValidationResults.newPassword.errorMessage}
        >
          <InputComponent
            placeholder={translations.form.newPasswordPlaceholder}
            value={formData.newPassword}
            onChange={setNewPassword}
            onBlur={validateNewPassword}
            type="password"
            hasError={!!formValidationResults.newPassword.errorMessage}
            idForTesting="new-password"
          />
        </FormFieldComponent>
        <FormFieldComponent
          label={translations.form.repeatNewPasswordLabel}
          isRequired
          errorMessage={formValidationResults.repeatNewPassword.errorMessage}
        >
          <InputComponent
            placeholder={translations.form.repeatNewPasswordPlaceholder}
            value={formData.repeatNewPassword}
            onChange={setRepeatNewPassword}
            onBlur={validatePasswordRepeat}
            type="password"
            hasError={!!formValidationResults.repeatNewPassword.errorMessage}
            idForTesting="repeat-new-password"
          />
        </FormFieldComponent>
      </FormComponent>
    </ModalComponent>
  );
};

export default AppHeaderUserChangePasswordComponent;
