import { DriverDetailsResponseItem } from "../../../api/raily/driver/details/driver-details.response";
import DriverDetails from "./driver-details";

const createAlternativePhoneNumber = (
  item: DriverDetailsResponseItem
): DriverDetails["alternativePhoneNumber"] => {
  if (!item.mobileSecondary || !item.mobileSecondaryPrefix) {
    return null;
  }

  return {
    dialingCode: item.mobileSecondaryPrefix,
    number: item.mobileSecondary,
  };
};
const create = (item: DriverDetailsResponseItem): DriverDetails => {
  return {
    uuid: item.id,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
    phoneNumber: {
      dialingCode: item.mobilePrimaryPrefix,
      number: item.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(item),
  };
};

const driverDetailsFactory = { create };

export default driverDetailsFactory;
