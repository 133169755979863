import isEqual from "lodash/isEqual";
import TaxiUnsettledTransferedOrderListingFilterSelectOption, {
  TaxiUnsettledTransferedOrderListingInternalOrderIdFilterSelectOption,
} from "../types/taxi-unsettled-transfered-order-listing-filter-select-option";
import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiUnsettledTransferedOrderListingFilterType from "../types/taxi-unsettled-transfered-order-listing-filter-type";
import TaxiUnsettledTransferedOrderListingFilter from "../types/taxi-unsettled-transfered-order-listing-filter";

const getSearchQueryOptions = (
  query: string
): TaxiUnsettledTransferedOrderListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiUnsettledTransferedOrderListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByProducerTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByProducerTaxiTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByConsumerTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByConsumerTaxiTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDriverLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByExternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengerLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByProducerTaxiLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.PRODUCER_TAXI,
        value: query,
      },
    },
    {
      label: getSearchByConsumerTaxiLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.CONSUMER_TAXI,
        value: query,
      },
    },
    {
      label: getSearchByDriverLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByExternalOrderIdLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: query,
      },
    },
    {
      label: getSearchByPassengerLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.PASSENGER,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressLabel(query),
      value: {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
  ];

  const options: TaxiUnsettledTransferedOrderListingFilterSelectOption[] = [
    ...stringOptions,
  ] as TaxiUnsettledTransferedOrderListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const internalOrderIdFilterOption: TaxiUnsettledTransferedOrderListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: TaxiUnsettledTransferedOrderListingFilterType.INTERNAL_ORDER_ID,
          value: parseStringToNumber(query)!,
        },
      };

    options.push(internalOrderIdFilterOption);
  }

  return options;
};

const getSelectOptionsByQuery = (
  query: string
): TaxiUnsettledTransferedOrderListingFilterSelectOption[] => {
  const queryOptions = getSearchQueryOptions(query);

  return queryOptions;
};

const getSelectOptions = (
  query: string,
  selectedFilters: TaxiUnsettledTransferedOrderListingFilter[]
): TaxiUnsettledTransferedOrderListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const taxiUnsettledTransferedOrderListingFilterHelper = {
  getSelectOptions,
};

export default taxiUnsettledTransferedOrderListingFilterHelper;
