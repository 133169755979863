import orderTranslationsHelper from "../../../../../languages/order-translations.helper";

const createHeader = (orderHumanId: string) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
      .collisionModal;

  return {
    title: translations.headingTemplateText.replace(
      "#{orderHumanId}",
      orderHumanId
    ),
  };
};

const orderDetailsManageDriverAssignmentCollisionHelper = {
  createHeader,
};

export default orderDetailsManageDriverAssignmentCollisionHelper;
