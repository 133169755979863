import { FC } from "react";
import LoaderComponent from "../../../../../../common/components/loader/loader.component";

const RouteDetailsLoaderComponent: FC = () => {
  return (
    <div className="route_details_loader_wrapper">
      <LoaderComponent type="primary" />
    </div>
  );
};

export default RouteDetailsLoaderComponent;
