import { FC } from "react";
import TimeCountdownComponent from "../../../../../common/components/time-countdown/time-countdown.component";
import dateService from "../../../../../common/utils/date/date.service";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";

type OrderActiveListingTableTimeToStartProps = {
  timeToStart: Date;
};

const OrderActiveListingTableTimeToStartComponent: FC<
  OrderActiveListingTableTimeToStartProps
> = (props) => {
  const translations = orderTranslationsHelper.getActiveListingTranslations();

  return (
    <>
      <span>{dateService.formatDateTime(props.timeToStart)}</span>
      <div className="order_active_listing_time_to_start">
        {translations.table.timeToStartPrefix}:
        <TimeCountdownComponent dateTo={props.timeToStart} />
      </div>
    </>
  );
};

export default OrderActiveListingTableTimeToStartComponent;
