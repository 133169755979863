import {
  DriverPlanDeleteOrderErrorType,
  DriverPlanOrderInProgressError,
  DriverPlanOrderNotExistsError,
  DriverPlanTaxiDriverAssociationNotExistsError,
  DriverPlanTaxiDriverContractNotValidError,
  DriverPlanTaxiRailyContractNotExistsError,
} from "./driver-plan-delete-order-error";

const createTaxiDriverAssocationNotExistsError =
  (): DriverPlanTaxiDriverAssociationNotExistsError => {
    return {
      message: "Brak dowiązania firmy taxi z kierowcą",
      params: {},
      type: DriverPlanDeleteOrderErrorType.TAXI_DRIVER_ASSOCIATION_NOT_EXISTS,
    };
  };

const createOrderNotExistsError = (): DriverPlanOrderNotExistsError => {
  return {
    message: "Zlecenie nie istnieje",
    params: {},
    type: DriverPlanDeleteOrderErrorType.ORDER_NOT_EXISTS,
  };
};

const createOrderInProgressError = (): DriverPlanOrderInProgressError => {
  return {
    message: "Zlecenie w trakcie realizacji",
    params: {},
    type: DriverPlanDeleteOrderErrorType.ORDER_IN_PROGRESS,
  };
};

const createTaxiDriverContractNotValidError =
  (): DriverPlanTaxiDriverContractNotValidError => {
    return {
      message: "Brak ważnego kontraktu firmy taxi z kierowcą",
      params: {},
      type: DriverPlanDeleteOrderErrorType.TAXI_DRIVER_CONTRACT_NOT_VALID,
    };
  };

const createTaxiRailyContractNotValidError =
  (): DriverPlanTaxiRailyContractNotExistsError => {
    return {
      message: "Brak ważnego kontraktu Raily z firmą taxi",
      params: {},
      type: DriverPlanDeleteOrderErrorType.TAXI_RAILY_CONTRACT_NOT_VALID,
    };
  };

const driverPlanDeleteOrderErrorFactory = {
  createTaxiDriverAssocationNotExistsError,
  createOrderNotExistsError,
  createOrderInProgressError,
  createTaxiDriverContractNotValidError,
  createTaxiRailyContractNotValidError,
};

export default driverPlanDeleteOrderErrorFactory;
