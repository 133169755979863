import { FC } from "react";
import driverDetailsActiveRoutePlanDeleteOrderListingHelper from "./driver-details-active-route-plans-order-delete-listing.helper";
import DriverDetailsActiveRoutePlanOrderDeleteTableRow from "./types/driver-details-active-route-plans-order-delete-table-row";
import TableComponent from "../../../../../../common/components/table/table.component";
import TableDeleteButtonComponent from "../../../../../../common/components/table/button/delete/table-delete-button.component";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import { DriverDetailsActiveRoutePlanDeleteOrderData } from "./types/driver-details-active-route-plans-delete-order-data";

type DriverDetailsPlanDeleteOrderListingProps = {
  orders: DriverDetailsActiveRoutePlanDeleteOrderData[];
  onDeleteOrderClick: (orderUuid: string, solvedOrderUuid: string) => void;
};

const DriverDetailsActiveRoutePlanOrderDeleteListingComponent: FC<
  DriverDetailsPlanDeleteOrderListingProps
> = (props) => {
  const createTableRow = (
    orderData: DriverDetailsActiveRoutePlanDeleteOrderData
  ): DriverDetailsActiveRoutePlanOrderDeleteTableRow => {
    const translations =
      userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations();

    return {
      id: orderData.uuid,
      value: {
        cargoOrderInternalId: (
          <div title={String(orderData.cargoInternalOrderId)}>
            {orderData.cargoInternalOrderId}
          </div>
        ),
        actions: (
          <div>
            <TableDeleteButtonComponent
              title={
                orderData.isPossibleToDelete
                  ? translations.deleteOrder.table.deleteButtonTitle
                  : translations.deleteOrder.table.deleteButtonDisabledTitle
              }
              onClick={() =>
                props.onDeleteOrderClick(
                  orderData.uuid,
                  orderData.solvedOrderUuid
                )
              }
              isDisabled={!orderData.isPossibleToDelete}
              isLoading={orderData.isDeletePending}
            />
          </div>
        ),
      },
    };
  };

  const columns =
    driverDetailsActiveRoutePlanDeleteOrderListingHelper.getColumns();
  const rows = props.orders.map(createTableRow);

  return <TableComponent columns={columns} rows={rows} />;
};

export default DriverDetailsActiveRoutePlanOrderDeleteListingComponent;
