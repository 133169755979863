import { FC } from "react";
import TableComponent from "../../../../../../../common/components/table/table.component";
import BillingsTaxiTaxiContributionsBonusTableRow from "../types/billings-taxi-taxi-contributions-bonus-table-row";
import BillingsTaxiTaxiContributionsBonusTableColumn from "../types/billings-taxi-taxi-contributions-bonus-table-column";
import BillingsTaxiTaxiContributionsBonusTableHelper from "./billings-taxi-taxi-contributions-bonus-table.helper";

type BillingsTaxiTaxiContributionsBonusTableProps = {
  rows: BillingsTaxiTaxiContributionsBonusTableRow[];
  isLoading: boolean;
};

const BillingsTaxiTaxiContributionsBonusTableComponent: FC<
  BillingsTaxiTaxiContributionsBonusTableProps
> = (props) => {
  const columns: BillingsTaxiTaxiContributionsBonusTableColumn[] =
    BillingsTaxiTaxiContributionsBonusTableHelper.getColumns();

  return (
    <TableComponent
      columns={columns}
      rows={props.rows}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiTaxiContributionsBonusTableComponent;
