import SignUpPassengerFormStep from "./types/sign-up-passenger-form-step";

const getFormSteps = () => {
  return [
    SignUpPassengerFormStep.accountData,
    SignUpPassengerFormStep.userData,
    SignUpPassengerFormStep.summary,
  ];
};

const signUpPassengerFormHelper = {
  getFormSteps,
};

export default signUpPassengerFormHelper;
