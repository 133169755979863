import Joi from "joi";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";

const validateComment = (comment: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").max(20).trim();

  return formValidationService.validate(comment, validationSchema);
};

const billingsStatusUpdateValidationService = {
  validateComment,
};

export default billingsStatusUpdateValidationService;
