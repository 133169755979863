import {
  CargoOrderFinishedListItem,
  CargoOrderFinishedListItemPublicStatus,
} from "../../../../common/services/cargo-order/finished-list/cargo-order-finished-list";
import OrderFinishedListingExecutionStatus from "./types/order-finished-listing-execution-status";
import OrderFinishedListingItem from "./types/order-finished-listing-item";
import OrderFinishedListingPublicStatus from "./types/order-finished-listing-public-status";

const createPublicStatus = (
  status: CargoOrderFinishedListItemPublicStatus
): OrderFinishedListingPublicStatus => {
  switch (status) {
    case CargoOrderFinishedListItemPublicStatus.PRIVATE:
      return OrderFinishedListingPublicStatus.PRIVATE;
    case CargoOrderFinishedListItemPublicStatus.PUBLIC:
      return OrderFinishedListingPublicStatus.PUBLIC;
    case CargoOrderFinishedListItemPublicStatus.PUBLISHED:
      return OrderFinishedListingPublicStatus.PUBLISHED;
  }
};

const createListingItem = (
  listItem: CargoOrderFinishedListItem
): OrderFinishedListingItem => {
  return {
    uuid: listItem.uuid,
    cargoCompanyExternalOrderId: listItem.externalOrderId,
    cargoCompanyOrderId: listItem.internalOrderId,
    consumerName: listItem.consumer?.name ?? "",
    producerName: listItem.producer?.name ?? "",
    orderDate: new Date(listItem.estimatedStartDate),
    isMobileAppUser: listItem.isMobileAppUser,
    dispatchName: listItem.dispatch,
    drivers: listItem.drivers,
    passengers: listItem.passengers,
    routeAddresses: listItem.routeAddresses,
    isCancelled: listItem.isCancelled,
    publicStatus: createPublicStatus(listItem.publicStatus),
    status: listItem.isCancelled
      ? OrderFinishedListingExecutionStatus.CANCELLED
      : OrderFinishedListingExecutionStatus.FINISHED,
    attentions: {
      newSharedPost: listItem.attentions?.newSharedPost,
      newPlanEntryPost: listItem.attentions?.newPlanEntryPost,
      cargoOrderEdit: listItem.attentions?.cargoOrderEdit,
      cargoOrderCancel: listItem.attentions?.cargoOrderCancel,
    },
    slkStatus: listItem.slkStatus ?? null,
  };
};

const createListingItems = (
  listItems: CargoOrderFinishedListItem[]
): OrderFinishedListingItem[] => {
  return listItems.map(createListingItem);
};

const driverListingItemFactory = {
  createListingItems,
};

export default driverListingItemFactory;
