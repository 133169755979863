import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import planningRoutesUrls from "../../../planning/common/routes/planning-routes-urls";
import OrderActiveListingOrderRouteParams from "../common/types/order-active-listing-order-route-params";
import OrderActiveListingTableColumn from "../common/types/order-active-listing-table-column";
import {
  faCancel,
  faCar,
  faEnvelope,
  faMessage,
  faMobileAlt,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";

const getColumns = (): OrderActiveListingTableColumn[] => {
  const translations =
    orderTranslationsHelper.getActiveListingTranslations().table.headers;

  const tableColumns: OrderActiveListingTableColumn[] = [
    {
      header: "",
      title: "",
      accessor: "executionStatus",
      colSpan: 2,
    },
    {
      header: translations.timeToStartLabel,
      title: translations.timeToStartTitle,
      accessor: "timeToStart",
      colSpan: 10,
    },
    {
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      accessor: "internalOrderId",
      colSpan: 5,
    },
    {
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      accessor: "externalOrderId",
      colSpan: 7,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faMessage}
          className="order_active_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.newSharedPostTitle,
      accessor: "newSharedPost",
      colSpan: 2,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faEnvelope}
          className="order_active_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.newPlanEntryPostTitle,
      accessor: "newPlanEntryPost",
      colSpan: 2,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faPencil}
          className="order_active_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.cargoOrderEditTitle,
      accessor: "cargoOrderEdit",
      colSpan: 2,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faCancel}
          className="order_active_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.cargoOrderCancelTitle,
      accessor: "cargoOrderCancel",
      colSpan: 2,
    },

    {
      header: (
        <FontAwesomeIcon
          icon={faCar}
          className="order_active_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.slkStatusTitle,
      accessor: "slkStatus",
      colSpan: 2,
    },
    {
      header: translations.routeLabel,
      title: translations.routeTitle,
      accessor: "route",
      colSpan: 24,
    },
    {
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      accessor: "passenger",
      colSpan: 8,
    },
    {
      header: translations.dispatchLabel,
      title: translations.dispatchTitle,
      accessor: "dispatch",
      colSpan: 8,
    },
    {
      header: translations.clientLabel,
      title: translations.clientTitle,
      accessor: "client",
      colSpan: 8,
    },
    {
      header: translations.taxiLabel,
      title: translations.taxiTitle,
      accessor: "taxi",
      colSpan: 8,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faMobileAlt}
          className="order_active_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.isMobileAppUserTitle,
      accessor: "isMobileAppUser",
      colSpan: 2,
    },
    {
      header: translations.driversLabel,
      title: translations.driversTitle,
      accessor: "driver",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 4,
    },
  ];

  return tableColumns;
};

const getOrderRoute = (params: OrderActiveListingOrderRouteParams): string => {
  return planningRoutesUrls.order.replace(":orderUuid", params.orderUuid);
};

const orderActiveListingTableHelper = {
  getColumns,
  getOrderRoute,
};

export default orderActiveListingTableHelper;
