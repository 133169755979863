import TaxiOfficerDetails from "./taxi-officer-details";
import { TaxiOfficerDetailsResponseItem } from "../../../api/raily/taxi-officer/details/taxi-officer-details.response";

const createAlternativePhoneNumber = (
  item: TaxiOfficerDetailsResponseItem
): TaxiOfficerDetails["alternativePhoneNumber"] => {
  if (!item.mobileSecondary || !item.mobileSecondaryPrefix) {
    return null;
  }

  return {
    dialingCode: item.mobileSecondaryPrefix,
    number: item.mobileSecondary,
  };
};
const create = (item: TaxiOfficerDetailsResponseItem): TaxiOfficerDetails => {
  return {
    uuid: item.id,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
    phoneNumber: {
      dialingCode: item.mobilePrimaryPrefix,
      number: item.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(item),
    taxiCorporationUuid: item.taxiOfficer.taxiCorporationId,
  };
};

const taxiOfficerDetailsFactory = { create };

export default taxiOfficerDetailsFactory;
