import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAbort from "../../../../common/hooks/use-abort";
import { useAppContext } from "../../../../context/app.context";
import MileageDictionaryRecordUpdateRouteParams from "../../common/routes/types/mileage-dictionary-record-update-route-params";
import useMileageDictionaryDetails from "../../../../common/services/mileage-dictionary/details/use-mileage-dictionary-details";
import useForm from "../../../../common/components/form/use-form";
import mileageDictionaryUpdateFormHelper from "./form/mileage-dictionary-update-form.helper";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageDictionaryDetailsLoadParams from "../../../../common/services/mileage-dictionary/details/mileage-dictionary-details-load-params";
import mileageBreadcrumbsHelper from "../../common/breadcrumbs/mileage-breadcrumbs.helper";
import useCargoCompanyDetails from "../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";
import notificationService from "../../../../common/utils/notification/notification.service";
import mileageRoutesHelper from "../../common/routes/mileage-routes.helper";
import MileageDictionaryUpdateParams from "../../../../common/services/mileage-dictionary/update/mileage-dictionary-update-params";
import mileageDictionaryService from "../../../../common/services/mileage-dictionary/mileage-dictionary.service";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import SwitchButton from "../../../../common/components/switch-button/switch-button.component";
import mileageDictionaryUpdateFormFactory from "./form/mileage-dictionary-update-form.factory";

const MileageDictionaryUpdateComponent: FC = () => {
  const { cargoCompanyUuid, recordUuid } =
    useParams<MileageDictionaryRecordUpdateRouteParams>();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [isDictionaryRecordUpdatePending, setIsDictionaryRecordUpdatePending] =
    useState(false);

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();
  const mileageDictionaryDetails = useMileageDictionaryDetails();
  const mileageDictionaryDetailsAbort = useAbort();

  const mileageDictionaryRecordUpdateAbort = useAbort();
  const navigate = useNavigate();

  const form = useForm({
    emptyValues: mileageDictionaryUpdateFormHelper.getDefaultFormData(),
    validationDefinition:
      mileageDictionaryUpdateFormHelper.getValidationDefinition(),
  });

  const translations =
    mileageTranslationsHelper.getMileageDictionaryTranslations().edit;

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => cargoCompanyAbort.revoke();
  }, [cargoCompanyUuid]);

  const cargoCompanyName: string | undefined = useMemo(() => {
    if (!cargoCompany.data) return;

    return cargoCompany.data.displayName;
  }, [cargoCompany.data]);

  useEffect(() => {
    if (!cargoCompanyUuid || !recordUuid) return;

    const params: MileageDictionaryDetailsLoadParams = {
      cargoCompanyUuid: cargoCompanyUuid,
      recordUuid: recordUuid,
    };

    mileageDictionaryDetails.load(params, mileageDictionaryDetailsAbort.signal);

    return () => mileageDictionaryDetailsAbort.revoke();
  }, [cargoCompanyUuid, recordUuid]);

  useEffect(() => {
    if (!mileageDictionaryDetails.data) {
      return;
    }

    form.setValues(
      mileageDictionaryUpdateFormFactory.createFormData(
        mileageDictionaryDetails.data
      )
    );
  }, [mileageDictionaryDetails.data]);

  useEffect(() => {
    const breadcrumbs =
      mileageBreadcrumbsHelper.getMileageDictionaryUpdateRecordBreadcrumbs({
        cargoCompanyName: cargoCompanyName ?? "",
        cargoCompanyUuid: cargoCompanyUuid!,
        dictionaryRecordUuid: recordUuid!,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoCompanyName]);

  const onMileageDictionaryUpdateSuccess = () => {
    notificationService.success(translations.successUpdateNotificationLabel);
    navigate(
      mileageRoutesHelper.getDictionaryRoute({
        cargoCompanyUuid: cargoCompanyUuid!,
      })
    );
  };

  const onMileageDictionaryUpdateFailure = () => {
    notificationService.error(translations.failureUpdateNotificationLabel);
  };
  const updateDictionaryReord = async () => {
    if (!cargoCompanyUuid || !recordUuid) {
      return;
    }

    setIsDictionaryRecordUpdatePending(true);

    const params: MileageDictionaryUpdateParams = {
      cargoCompanyUuid: cargoCompanyUuid,
      recordUuid: recordUuid,
      name: form.values.name!,
      active: form.values.active,
    };

    try {
      await mileageDictionaryService.update(
        params,
        mileageDictionaryRecordUpdateAbort.signal
      );

      onMileageDictionaryUpdateSuccess();
    } catch {
      onMileageDictionaryUpdateFailure();
    } finally {
      setIsDictionaryRecordUpdatePending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) return;

    updateDictionaryReord();
  };

  return (
    <>
      <HeadingComponent title={translations.headingLabel} />
      <Row>
        <Column>
          <CardComponent>
            <Row>
              <Column>
                <FormFieldComponent
                  label={translations.form.nameLabel}
                  errorMessage={form.validationResults.name.errorMessage}
                >
                  <InputComponent
                    value={form.values.name}
                    onChange={(value) => {
                      form.setValue("name", value);
                    }}
                    placeholder={
                      mileageDictionaryDetails.data?.name ??
                      translations.form.namePlaceholder
                    }
                    hasError={!!form.validationResults.name.errorMessage}
                    onBlur={() => {
                      form.validate("name");
                    }}
                    idForTesting="mileage-dictionary-update-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column>
                <FormFieldComponent label={translations.form.activeLabel}>
                  <SwitchButton
                    checked={form.values.active}
                    onChange={(value) => {
                      form.setValue("active", value);
                    }}
                    onBlur={() => {
                      form.validate("active");
                    }}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <ButtonComponent
              onClick={onSubmitButtonClick}
              type="primary"
              isDisabled={isDictionaryRecordUpdatePending}
              idForTesting="mileage-dictionary-update-sumbit-button"
              classNames={{ root: "mt-2" }}
              title={translations.submitButtonTitle}
            >
              {translations.submitButtonLabel}
            </ButtonComponent>
          </CardComponent>
        </Column>
      </Row>
    </>
  );
};

export default MileageDictionaryUpdateComponent;
