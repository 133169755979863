import DriverDetailsDriverPlanEntry, {
  DriverDetailsDriverPlanEntryNodeExclusions,
  DriverDetailsDriverPlanEntryWaypoint,
  DriverDetailsDriverPlanEntryWaypointType,
} from "../../common/types/driver-details-plan-entry";
import {
  DriverDetailsActiveRoutePlansByTaxiResponseFullPlanEntry,
  DriverDetailsActiveRoutePlansByTaxiResponsePlanEntry,
  DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryCheckoutEvent,
  DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryEndNode,
  DriverDetailsActiveRoutePlansByTaxiResponsePlanEntrySolvedOrder,
  DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryStartNode,
  DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType,
  DriverDetailsActiveRoutePlansByTaxiResponseRestrictedPlanEntry,
} from "./api/driver-details-active-route-plans-by-taxi-plan.response";

const checkIsRestrictedResponsePlanEntry = (
  responsePlanEntry: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntry
): responsePlanEntry is DriverDetailsActiveRoutePlansByTaxiResponseRestrictedPlanEntry => {
  return (
    responsePlanEntry.view_type ===
    DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType.RESTRICTED
  );
};

const createWaypointForStartNode = (
  responseStartNode: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryStartNode,
  responseCheckoutEvents: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryCheckoutEvent[],
  planEntryType: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType,
  stageCounter: number
): DriverDetailsDriverPlanEntryWaypoint => {
  const responseCheckoutEvent = responseCheckoutEvents.find(
    (item) => item.node_id === responseStartNode.meta.id
  );

  const waypoint: DriverDetailsDriverPlanEntryWaypoint = {
    type: DriverDetailsDriverPlanEntryWaypointType.DRIVER_HOME,
    uuid: responseStartNode.meta.id,
    address: {
      displayName: responseStartNode.display_name,
      latitude: responseStartNode.lat,
      longitude: responseStartNode.lon,
    },
    checkoutDate: responseCheckoutEvent?.achieved_at
      ? new Date(responseCheckoutEvent.achieved_at)
      : null,
    clientDisplayName: "",
    estimatedDate: new Date(responseStartNode.meta.estimated_time),
    plannedHaltingTime: responseStartNode.halting_time,
    forcedHaltingTime: responseCheckoutEvent?.halting_time ?? null,
    highwayCost: responseCheckoutEvent?.highway_charge ?? null,
    order: null,
    plannedDate: responseStartNode.time
      ? new Date(responseStartNode.time)
      : null,
    isCheckouted: !!responseCheckoutEvent,
    checkoutEventUuid: responseCheckoutEvent?.id ?? null,
    isCheckoutManagementEnabled:
      planEntryType ===
      DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType.FULL,
    solvedOrderUuid: null,
    stageNo: stageCounter,
  };

  return waypoint;
};

const createWaypointForEndNode = (
  responseEndNode: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryEndNode,
  responseCheckoutEvents: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryCheckoutEvent[],
  planEntryType: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType,
  stageCounter: number
): DriverDetailsDriverPlanEntryWaypoint => {
  const responseCheckoutEvent = responseCheckoutEvents.find(
    (item) => item.node_id === responseEndNode.meta.id
  );

  const waypoint: DriverDetailsDriverPlanEntryWaypoint = {
    type: DriverDetailsDriverPlanEntryWaypointType.DRIVER_HOME,
    uuid: responseEndNode.meta.id,
    address: {
      displayName: responseEndNode.display_name,
      latitude: responseEndNode.lat,
      longitude: responseEndNode.lon,
    },
    checkoutDate: responseCheckoutEvent?.achieved_at
      ? new Date(responseCheckoutEvent.achieved_at)
      : null,
    clientDisplayName: "",
    estimatedDate: new Date(responseEndNode.meta.estimated_time),
    plannedHaltingTime: responseEndNode.halting_time,
    forcedHaltingTime: responseCheckoutEvent?.halting_time ?? null,
    highwayCost: responseCheckoutEvent?.highway_charge ?? null,
    order: null,
    plannedDate: responseEndNode.time ? new Date(responseEndNode.time) : null,
    isCheckouted: !!responseCheckoutEvent,
    checkoutEventUuid: responseCheckoutEvent?.id ?? null,
    isCheckoutManagementEnabled:
      planEntryType ===
      DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType.FULL,
    solvedOrderUuid: null,
    stageNo: stageCounter,
  };

  return waypoint;
};

const createWaypointsForSolvedOrder = (
  responseSolvedOrder: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntrySolvedOrder,
  responseCheckoutEvents: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryCheckoutEvent[],
  stageCounter: number,
  onComplete: (stageCounterValue: number) => void
): DriverDetailsDriverPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  let internalStageCounter = stageCounter;

  responseSolvedOrder.ride.seq.forEach((seqItem) => {
    const responseCheckoutEvent = responseCheckoutEvents.find(
      (item) => item.node_id === seqItem.meta.id
    );

    const waypoint: DriverDetailsDriverPlanEntryWaypoint = {
      type: DriverDetailsDriverPlanEntryWaypointType.ORDER_WAYPOINT,
      uuid: seqItem.meta.id,
      clientDisplayName: "",
      order: {
        uuid: responseSolvedOrder.transporting_order.cargo_order.id,
        cargoInternalOrderId:
          responseSolvedOrder.transporting_order.cargo_order.human_id,
      },
      address: {
        displayName: seqItem.display_name,
        latitude: seqItem.lat,
        longitude: seqItem.lon,
      },
      checkoutDate: responseCheckoutEvent?.achieved_at
        ? new Date(responseCheckoutEvent.achieved_at)
        : null,
      estimatedDate: new Date(seqItem.meta.estimated_time),
      plannedHaltingTime: seqItem.halting_time,
      forcedHaltingTime: responseCheckoutEvent?.halting_time ?? null,
      highwayCost: responseCheckoutEvent?.highway_charge ?? null,
      plannedDate: seqItem.time ? new Date(seqItem.time) : null,
      isCheckouted: !!responseCheckoutEvent,
      checkoutEventUuid: responseCheckoutEvent?.id ?? null,
      isCheckoutManagementEnabled: true,
      solvedOrderUuid: responseSolvedOrder.id,
      stageNo: internalStageCounter,
    };

    waypoints.push(waypoint);
    internalStageCounter++;
  });

  onComplete(internalStageCounter);

  return waypoints;
};

const createWaypointsForSolvedOrders = (
  responseSolvedOrders: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntrySolvedOrder[],
  responseCheckoutEvents: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryCheckoutEvent[],
  initialStageCounter: number
): DriverDetailsDriverPlanEntryWaypoint[] => {
  let stageCounter = initialStageCounter;
  const allWaypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  responseSolvedOrders.forEach((responseSolvedOrder) => {
    const orderWaypoints = createWaypointsForSolvedOrder(
      responseSolvedOrder,
      responseCheckoutEvents,
      stageCounter,
      (updatedStageCounter) => {
        stageCounter = updatedStageCounter;
      }
    );

    allWaypoints.push(...orderWaypoints);
  });

  return allWaypoints;
};

const createPlanEntryWaypointsForRestrictedPlanEntry = (
  responseRestrictedPlanEntry: DriverDetailsActiveRoutePlansByTaxiResponseRestrictedPlanEntry
): DriverDetailsDriverPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  let stageCounter: number = 1;

  const startWaypoint = createWaypointForStartNode(
    responseRestrictedPlanEntry.start_node,
    [],
    responseRestrictedPlanEntry.view_type,
    stageCounter
  );

  waypoints.push(startWaypoint);

  const endWaypoint = createWaypointForEndNode(
    responseRestrictedPlanEntry.end_node,
    [],
    responseRestrictedPlanEntry.view_type,
    stageCounter
  );

  waypoints.push(endWaypoint);

  return waypoints;
};

const createPlanEntryWaypointsForFullPlanEntry = (
  responseFullPlanEntry: DriverDetailsActiveRoutePlansByTaxiResponseFullPlanEntry
): DriverDetailsDriverPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  let stageCounter: number = 1;

  const startWaypoint = createWaypointForStartNode(
    responseFullPlanEntry.start_node,
    responseFullPlanEntry.checkout_events,
    responseFullPlanEntry.view_type,
    stageCounter
  );

  stageCounter++;

  waypoints.push(startWaypoint);

  const orderWaypoints = createWaypointsForSolvedOrders(
    responseFullPlanEntry.solved_orders,
    responseFullPlanEntry.checkout_events,
    stageCounter
  );

  waypoints.push(...orderWaypoints);

  stageCounter = stageCounter = waypoints[waypoints.length - 1].stageNo + 1;

  const endWaypoint = createWaypointForEndNode(
    responseFullPlanEntry.end_node,
    responseFullPlanEntry.checkout_events,
    responseFullPlanEntry.view_type,
    stageCounter
  );

  waypoints.push(endWaypoint);

  return waypoints;
};

const createPlanEntryWaypoints = (
  responsePlanEntry: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntry
): DriverDetailsDriverPlanEntryWaypoint[] => {
  const isResponsePlanEntryRestricted =
    checkIsRestrictedResponsePlanEntry(responsePlanEntry);

  if (isResponsePlanEntryRestricted) {
    return createPlanEntryWaypointsForRestrictedPlanEntry(responsePlanEntry);
  }

  return createPlanEntryWaypointsForFullPlanEntry(responsePlanEntry);
};

const createNodeExclusions = (
  responsePlanEntry: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntry
): DriverDetailsDriverPlanEntryNodeExclusions[] => {
  const exclusionList: DriverDetailsDriverPlanEntryNodeExclusions[] = [];

  responsePlanEntry.node_exclusions.exclusions.forEach((nodeExclusions) => {
    exclusionList.push({
      approaching: nodeExclusions.exclude_approaching,
      inside: nodeExclusions.exclude_inside,
      returning: nodeExclusions.exclude_returning,
      nodeIds: nodeExclusions.node_ids,
    });
  });

  return exclusionList;
};

const createPlanEntry = (
  responsePlanEntry: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntry
): DriverDetailsDriverPlanEntry => {
  const waypoints = createPlanEntryWaypoints(responsePlanEntry);
  const nodeExclusions = createNodeExclusions(responsePlanEntry);

  return {
    isAnonymized:
      responsePlanEntry.view_type ===
      DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType.RESTRICTED,
    uuid: responsePlanEntry.id,
    id: responsePlanEntry.human_id,
    waypoints,
    nodeExclusions: nodeExclusions,
  };
};

const createPlanEntries = (
  responsePlanEntries: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntry[]
): DriverDetailsDriverPlanEntry[] => {
  return responsePlanEntries.map(createPlanEntry);
};

const driverDetailsPlanByTaxiPlanEntryFactory = {
  createPlanEntries,
};

export default driverDetailsPlanByTaxiPlanEntryFactory;
