import { FC } from "react";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../common/components/form/input/input.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverAddAccountFormData from "../types/driver-add-account-form-data";
import DriverAddAccountFormValidationResults from "../types/driver-add-account-form-validation-results";

type DriverAddAccountDataProps = {
  formData: DriverAddAccountFormData;
  formValidationResults: DriverAddAccountFormValidationResults;
  onFormDataChange: (formData: DriverAddAccountFormData) => void;
  validateEmail: () => void;
  validateUsername: () => void;
  validatePassword: () => void;
  validatePasswordRepeat: () => void;
  isUsernameFetching: boolean;
};

const DriverAddAccountDataComponent: FC<DriverAddAccountDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.accountData;

  const setUsername = (value: DriverAddAccountFormData["username"]) => {
    const newFormData: DriverAddAccountFormData = {
      ...props.formData,
      username: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setEmail = (value: DriverAddAccountFormData["email"]) => {
    const newFormData: DriverAddAccountFormData = {
      ...props.formData,
      email: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setPassword = (value: DriverAddAccountFormData["password"]) => {
    const newFormData: DriverAddAccountFormData = {
      ...props.formData,
      password: value,
    };

    props.onFormDataChange(newFormData);
  };

  const setPasswordRepeat = (
    value: DriverAddAccountFormData["passwordRepeat"]
  ) => {
    const newFormData: DriverAddAccountFormData = {
      ...props.formData,
      passwordRepeat: value,
    };

    props.onFormDataChange(newFormData);
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.emailLabel}
            isRequired
            errorMessage={props.formValidationResults.email.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.emailPlaceholder}
              value={props.formData.email}
              onChange={setEmail}
              onBlur={props.validateEmail}
              hasError={!!props.formValidationResults.email.errorMessage}
              idForTesting={`account-data-email`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.usernameLabel}
            isRequired
            errorMessage={props.formValidationResults.username.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              isDisabled={props.isUsernameFetching}
              placeholder={translations.usernamePlaceholder}
              value={props.formData.username}
              onChange={setUsername}
              onBlur={props.validateUsername}
              hasError={!!props.formValidationResults.username.errorMessage}
              idForTesting={`account-data-user-name`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.passwordLabel}
            isRequired
            errorMessage={props.formValidationResults.password.errorMessage}
          >
            <InputComponent
              placeholder={translations.passwordPlaceholder}
              value={props.formData.password}
              type="password"
              onChange={setPassword}
              onBlur={props.validatePassword}
              hasError={!!props.formValidationResults.password.errorMessage}
              idForTesting={`account-data-password`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.passwordRepeatLabel}
            isRequired
            errorMessage={
              props.formValidationResults.passwordRepeat.errorMessage
            }
          >
            <InputComponent
              placeholder={translations.passwordRepeatPlaceholder}
              value={props.formData.passwordRepeat}
              type="password"
              onChange={setPasswordRepeat}
              onBlur={props.validatePasswordRepeat}
              hasError={
                !!props.formValidationResults.passwordRepeat.errorMessage
              }
              idForTesting={`account-data-password-repeat`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default DriverAddAccountDataComponent;
