import { FC, useEffect, useRef, useState } from "react";
import ModalComponent from "../../../modal/modal.component";
import ButtonComponent from "../../../button/button.component";
import DateRange from "../../../../types/date-range";
import DateRangePickerComponent from "../../../form/date-picker/date-range-picker/date-range-picker.component";
import dateService from "../../../../utils/date/date.service";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";

type ListingDateFilterProps = {
  isOpen: boolean;
  onClose: () => void;
  onApply: (date: DateRange) => void;
};

const ListingDateRangeFilterComponent: FC<ListingDateFilterProps> = (props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(
    null
  );

  const translations =
    appTranslationsHelper.getComponentTranslations().listing.filter.dateRange;

  useEffect(() => {
    if (props.isOpen && modalRef.current) {
      modalRef.current.focus();
    }

    setSelectedDateRange(null);
  }, [props.isOpen]);

  const onAcceptButtonClick = () => {
    if (!selectedDateRange) {
      return;
    }

    props.onApply(selectedDateRange);
  };

  const onRejectButtonClick = () => {
    props.onClose();
  };

  const ApplyButton = (
    <ButtonComponent
      type="primary"
      onClick={onAcceptButtonClick}
      title={translations.applyButtonTitle}
    >
      {translations.applyButtonText}
    </ButtonComponent>
  );

  const RejectButton = (
    <ButtonComponent
      type="danger"
      onClick={onRejectButtonClick}
      title={translations.rejectButtonTitle}
    >
      {translations.rejectButtonText}
    </ButtonComponent>
  );

  return (
    <div ref={modalRef} tabIndex={-1}>
      <ModalComponent
        header={{
          title: translations.headingText,
        }}
        classNames={{
          root: "listing_date_range_filter",
          content: "listing_date_range_filter_content",
        }}
        isOpen={props.isOpen}
        onClose={props.onClose}
        actions={[ApplyButton, RejectButton]}
      >
        <DateRangePickerComponent
          date={selectedDateRange}
          onChange={setSelectedDateRange}
        />
        {selectedDateRange && (
          <div className="mt-2">
            {dateService.formatDate(selectedDateRange.from)} -
            {dateService.formatDate(selectedDateRange.to)}
          </div>
        )}
      </ModalComponent>
    </div>
  );
};

export default ListingDateRangeFilterComponent;
