import { SearchRoadRoutingRequestWaypointCoordinate } from "../../../../../../../common/utils/search-road-route/search-road-routing.request";
import OrderAddPassenger from "../../../types/order-add-passenger";
import OrderAddTaxiCorporation from "../../../types/order-add-taxi-corporation";
import OrderAddSummaryRouteWaypoint from "../route/types/order-add-summary-route-waypoint";
import OrderAddSummaryConnectedWaypoints from "./types/order-add-summary-connected-waypoints";

const getTaxiCorporationLabel = (
  selectedTaxiCorporation: OrderAddTaxiCorporation | null
) => {
  if (!selectedTaxiCorporation) {
    return `-----`;
  }

  return selectedTaxiCorporation.displayName;
};

const getPassengersListLabel = (selectedPassengers: OrderAddPassenger[]) => {
  if (!selectedPassengers.length) {
    return `-----`;
  }

  const selectedPassengersDisplayNames = selectedPassengers.map(
    (passenger) => `${passenger.firstName} ${passenger.lastName}`
  );

  return selectedPassengersDisplayNames.join(`, `);
};

const getOrderNumberLabel = (orderNumber: string) => {
  if (!orderNumber) {
    return `-----`;
  }

  return orderNumber;
};

const getNotesForTaxiLabel = (notesForTaxi: string) => {
  if (!notesForTaxi) {
    return `-----`;
  }

  return notesForTaxi;
};

const getInternalRemarksLabel = (internalRemarks: string) => {
  if (!internalRemarks) {
    return `-----`;
  }

  return internalRemarks;
};

const getWaypointGroupsCouldBeConnected = (
  waypoints: OrderAddSummaryRouteWaypoint[]
) => {
  let waypointGroups: OrderAddSummaryConnectedWaypoints[] = [];

  waypoints.forEach((waypoint, index) => {
    const nextWaypoint = waypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const isWaypointAndNextWaypointSameStageNo =
      waypoint.stageNo === nextWaypoint.stageNo;
    const isWaypointAlone =
      waypoints.filter((w) => w.stageNo === waypoint.stageNo).length === 1;
    const isNextWaypointAlone =
      waypoints.filter((w) => w.stageNo === nextWaypoint.stageNo).length === 1;

    const isPlaceInWaypoint = !!waypoint.place;
    const isPlaceInNextWaypoint = !!nextWaypoint.place;

    if (
      !isWaypointAndNextWaypointSameStageNo &&
      isWaypointAlone &&
      isNextWaypointAlone &&
      isPlaceInWaypoint &&
      isPlaceInNextWaypoint
    ) {
      waypointGroups.push({ start: waypoint, end: nextWaypoint });
    }
  });

  return waypointGroups;
};

const getCoordinatesOfWaypointsCouldBeConnected = (
  connectedWaypoints: OrderAddSummaryConnectedWaypoints[]
): SearchRoadRoutingRequestWaypointCoordinate[][] => {
  let coordinatesOfWaypointsCouldBeConnected: SearchRoadRoutingRequestWaypointCoordinate[][] =
    [];

  connectedWaypoints.forEach((connection) => {
    const start = {
      latitude: connection.start.place!.latitude,
      longitude: connection.start.place!.longitude,
    };

    const end = {
      latitude: connection.end.place!.latitude,
      longitude: connection.end.place!.longitude,
    };

    const newGroup: SearchRoadRoutingRequestWaypointCoordinate[] = [start, end];

    coordinatesOfWaypointsCouldBeConnected.push(newGroup);
  });

  return coordinatesOfWaypointsCouldBeConnected;
};

const orderAddSummaryHelper = {
  getTaxiCorporationLabel,
  getPassengersListLabel,
  getOrderNumberLabel,
  getNotesForTaxiLabel,
  getInternalRemarksLabel,
  getWaypointGroupsCouldBeConnected,
  getCoordinatesOfWaypointsCouldBeConnected,
};

export default orderAddSummaryHelper;
