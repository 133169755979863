import DispatcherUpdateRequest from "../../../api/raily/dispatcher/update/dispatcher-update.request";
import DispatcherUpdateParams from "./dispatcher-update-params";

const create = (params: DispatcherUpdateParams): DispatcherUpdateRequest => {
  return {
    dispatcherId: params.dispatcherUuid,
    dispatchId: params.dispatchUuid,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.phoneNumber.number,
    mobilePrimaryPrefix: params.phoneNumber.dialingCode,
    mobileSecondary: params.alternativePhoneNumber.number || null,
    mobileSecondaryPrefix: params.alternativePhoneNumber.dialingCode || null,
  };
};

const dispatcherUpdateRequestFactory = {
  create,
};

export default dispatcherUpdateRequestFactory;
