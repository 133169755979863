import appConfig from "../../../../../../../app.config";
import authService from "../../../../../../auth/common/auth.service";
import DriverContractListingDriverResponse from "./driver-contract-listing-driver.response";
import DriverContractListingResponse from "./driver-contract-listing.response";

const fetchDriverDetails = async (
  driverUuid: string
): Promise<DriverContractListingDriverResponse> => {
  const path = `${appConfig.baseApiUrl}/drivers/${driverUuid}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchListing = async (
  driverUuid: string
): Promise<DriverContractListingResponse> => {
  const path = `${appConfig.baseApiUrl}/drivers/${driverUuid}/contracts/taxi-driver`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const driverContractListingApiService = {
  fetchDriverDetails,
  fetchListing,
};

export default driverContractListingApiService;
