import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../context/app.context";
import mileageAddUserPermissionsHelper from "./common/user-permissions/mileage-add-user-permissions.helper";
import MileageAddViewMode from "./common/types/mileage-add-view-mode";
import ButtonComponent from "../../../common/components/button/button.component";
import CargoMileageAddComponent from "./cargo/cargo-mileage-add.component";
import RailyMileageAddComponent from "./raily/raily-mileage-add.component";
import mileageTranslationsHelper from "../../../languages/mileage-translations.helper";

const MileageAddComponent: FC = () => {
  const { user, selectedAppLanguage } = useAppContext();

  const userPermissions = useMemo(
    () => mileageAddUserPermissionsHelper.getPermissions(user?.roles!),
    []
  );

  const translations = useMemo(
    () => mileageTranslationsHelper.getMileageAddTranslations(),
    [selectedAppLanguage]
  );

  const [selectedViewMode, setSelectedViewMode] =
    useState<MileageAddViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(MileageAddViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToCargoButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(MileageAddViewMode.CARGO)}
        title={translations.header.changeViewToCargoButtonTitle}
      >
        {translations.header.changeViewToCargoButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: MileageAddViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToCargoButton,
          hasPermission: userPermissions.hasAccessToCargoView,
        },
      },
      {
        viewMode: MileageAddViewMode.CARGO,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToCargoButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: MileageAddViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: MileageAddViewMode.RAILY,
        component: (
          <RailyMileageAddComponent
            changeViewButtons={getViewChangeButtons(MileageAddViewMode.RAILY)}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: MileageAddViewMode.CARGO,
        component: (
          <CargoMileageAddComponent
            changeViewButtons={getViewChangeButtons(MileageAddViewMode.CARGO)}
          />
        ),
        hasPermission: userPermissions.hasAccessToCargoView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default MileageAddComponent;
