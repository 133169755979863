import DealerAddRequest, { DealerAddRequestBody } from "./dealer-add.request";

const createBody = (data: DealerAddRequest): DealerAddRequestBody => {
  return {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    mobilePrimary: data.mobilePrimary,
    mobilePrimaryPrefix: data.mobilePrimaryPrefix,
    mobileSecondary: data.mobileSecondary,
    mobileSecondaryPrefix: data.mobileSecondaryPrefix,
    password: data.password,
    username: data.username,
    dealer: {
      taxiCorporationId: data.taxiCorporationId,
    },
  };
};

const dealerAddRequestFactory = {
  createBody,
};

export default dealerAddRequestFactory;
