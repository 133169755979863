import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import routesWaypointAddressAddFormValidationService from "./routes-waypoint-address-add-form-validation.service";
import RoutesWaypointAddressAddFormData from "./types/routes-waypoint-address-add-form-data";

const getDefaultFormData = (): RoutesWaypointAddressAddFormData => {
  return {
    foundAddress: null,
    street: "",
    houseNumber: "",
    apartmentNumber: "",
    zipCode: "",
    town: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<RoutesWaypointAddressAddFormData> => {
    return {
      foundAddress: () => formValidationService.defaultValidationResult,
      street: (values) =>
        routesWaypointAddressAddFormValidationService.validateStreet(
          values.street
        ),
      houseNumber: (values) =>
        routesWaypointAddressAddFormValidationService.validateHouseNumber(
          values.houseNumber
        ),
      apartmentNumber: (values) =>
        routesWaypointAddressAddFormValidationService.validateApartmentNumber(
          values.apartmentNumber
        ),
      zipCode: (values) =>
        routesWaypointAddressAddFormValidationService.validateZipCode(
          values.zipCode
        ),
      town: (values) =>
        routesWaypointAddressAddFormValidationService.validateTown(values.town),
    };
  };

const addressAddModalHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default addressAddModalHelper;
