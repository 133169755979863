import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import settlementTranslationsHelper from "../../../../../../../languages/settlement-translations.helper";
import BillingModel from "../../../../../common/types/billing-model";
import taxiTransferedOrderSettlementListingHelper from "../../helper/taxi-transfered-order-settlement-listing.helper";
import TaxiTransferedOrderSettlementListingFilter, {
  TaxiTransferedOrderSettlementListingConsumerTaxiFilter,
  TaxiTransferedOrderSettlementListingInternalOrderIdFilter,
  TaxiTransferedOrderSettlementListingOrderStartDateFilter,
  TaxiTransferedOrderSettlementListingProducerTaxiFilter,
  TaxiTransferedOrderSettlementListingStatusFilter,
} from "../../types/taxi-transfered-order-settlement-listing-filter";
import TaxiTransferedOrderSettlementListingFilterType from "../../types/taxi-transfered-order-settlement-listing-filter-type";
import TaxiTransferedOrderSettlementListingItemBillingStatus from "../../types/taxi-transfered-order-settlement-listing-item-billing-status";

const getStartDateBadgeOption = (
  filter: TaxiTransferedOrderSettlementListingOrderStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: TaxiTransferedOrderSettlementListingOrderStartDateFilter;
} => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.orderStartDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.orderStartDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getBillingStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: TaxiTransferedOrderSettlementListingStatusFilter;
}[] => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .filters;

  return Object.values(
    TaxiTransferedOrderSettlementListingItemBillingStatus
  ).map((statusFilterValue) => {
    return {
      badge: {
        text: translations.billingStatusFilterText.replace(
          "#{option}",
          taxiTransferedOrderSettlementListingHelper.getBillingStatusText(
            statusFilterValue
          )
        ),
        title: translations.billingStatusFilterTitle.replace(
          "#{option}",
          taxiTransferedOrderSettlementListingHelper.getBillingStatusText(
            statusFilterValue
          )
        ),
      },
      filter: {
        type: TaxiTransferedOrderSettlementListingFilterType.BILLING_STATUS,
        value: statusFilterValue,
      },
    };
  });
};

const getBadgeOptions = (
  filter: TaxiTransferedOrderSettlementListingFilter
) => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: TaxiTransferedOrderSettlementListingFilter;
  }[] = [
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiTransferedOrderSettlementListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiTransferedOrderSettlementListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiTransferedOrderSettlementListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as TaxiTransferedOrderSettlementListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.producerTaxiFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiTransferedOrderSettlementListingProducerTaxiFilter["value"]
          )
        ),
        title: translations.producerTaxiFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiTransferedOrderSettlementListingProducerTaxiFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiTransferedOrderSettlementListingFilterType.PRODUCER_TAXI,
        value:
          filter.value as TaxiTransferedOrderSettlementListingProducerTaxiFilter["value"],
      },
    },
    {
      badge: {
        text: translations.consumerTaxiFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiTransferedOrderSettlementListingConsumerTaxiFilter["value"]
          )
        ),
        title: translations.consumerTaxiFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiTransferedOrderSettlementListingConsumerTaxiFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiTransferedOrderSettlementListingFilterType.CONSUMER_TAXI,
        value:
          filter.value as TaxiTransferedOrderSettlementListingConsumerTaxiFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as TaxiTransferedOrderSettlementListingOrderStartDateFilter
    ),
    ...getBillingStatusBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: TaxiTransferedOrderSettlementListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: TaxiTransferedOrderSettlementListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const taxiTransferedOrderSettlementListingFiltersBadgeListHelper = {
  getBadges,
};

export default taxiTransferedOrderSettlementListingFiltersBadgeListHelper;
