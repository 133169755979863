import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../../common/components/table/table.component";
import driverContractListingByRailyTableHelper from "./driver-contract-listing-by-raily-table.helper";
import DriverContractListingByRailyListingItem from "../common/types/driver-contract-listing-by-raily-listing-item";
import DriverContractListingByRailyTableColumn from "../common/types/driver-contract-listing-by-raily-table-column";
import DriverContractListingByRailyTableRow from "../common/types/driver-contract-listing-by-raily-table-row";
import { useAppContext } from "../../../../../../../context/app.context";

type DriverContractListingByRailyTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: DriverContractListingByRailyListingItem[];
  driverUuid: string;
};

const DriverContractListingByRailyTableComponent: FC<
  DriverContractListingByRailyTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns: DriverContractListingByRailyTableColumn[] = useMemo(() => {
    return driverContractListingByRailyTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const rows: DriverContractListingByRailyTableRow[] = useMemo(() => {
    return driverContractListingByRailyTableHelper.getRows(
      props.listingItems,
      props.driverUuid
    );
  }, [props.listingItems, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default DriverContractListingByRailyTableComponent;
