import { FC } from "react";
import TableComponent from "../../../../../common/components/table/table.component";
import OperatorListingTableColumn from "../common/types/operator-listing-table-column";
import OperatorListingTableRow from "../common/types/operator-listing-table-row";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import UserStatus from "../../../../../common/types/user-status";
import TableDeleteButtonComponent from "../../../../../common/components/table/button/delete/table-delete-button.component";
import OperatorListingItem from "../common/types/operator-listing-item";
import operatorListingTableHelper from "./operator-listing-table.helper";
import useOperatorListingUserPermissions from "../common/user-permissions/use-operator-listing-user-permissions";
import UserDeleteUserData from "../../../common/delete/common/types/user-delete-user-data";

type OperatorListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: OperatorListingItem[];
  onSelectUserToDelete: (userData: UserDeleteUserData) => void;
};

const OperatorListingTableComponent: FC<OperatorListingTableProps> = (
  props
) => {
  const userPermissions = useOperatorListingUserPermissions();

  const columns: OperatorListingTableColumn[] =
    operatorListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: OperatorListingItem
  ): OperatorListingTableRow => {
    const translations =
      userTranslationsHelper.getOperatorListingTranslations().table;

    const activityStatus = operatorListingTableHelper.getStatusText(
      listingItem.status
    );

    const isDeleted = listingItem.status === UserStatus.DELETED;

    const isDeleteButtonVisible =
      !isDeleted && userPermissions.hasAccessToDeleteUser;

    return {
      id: listingItem.uuid,
      idForTesting: listingItem.uuid,
      value: {
        firstName: (
          <div title={listingItem.firstName}>{listingItem.firstName}</div>
        ),
        lastName: (
          <div title={listingItem.lastName}>{listingItem.lastName}</div>
        ),
        username: <div>{listingItem.username}</div>,
        phoneNumbers: (
          <div>
            {listingItem.phoneNumbers.map((phoneNumber, index) => (
              <div key={index} title={phoneNumber}>
                {phoneNumber}
              </div>
            ))}
          </div>
        ),
        email: <div title={listingItem.email}>{listingItem.email}</div>,
        status: <div title={activityStatus}>{activityStatus}</div>,
        actions: (
          <div className="d-flex" onClick={(event) => event.stopPropagation()}>
            {isDeleteButtonVisible && (
              <TableDeleteButtonComponent
                onClick={() =>
                  props.onSelectUserToDelete({
                    uuid: listingItem.uuid,
                    firstName: listingItem.firstName,
                    lastName: listingItem.lastName,
                  })
                }
                title={translations.operatorDeleteButtonTitle}
                idForTesting={`operator-listing-table-item-${listingItem.uuid}-delete-button`}
              />
            )}
          </div>
        ),
      },
    };
  };

  const rows: OperatorListingTableRow[] = props.listingItems.map(
    (listingItem) => createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default OperatorListingTableComponent;
