import useAsyncData from "../../../hooks/use-async-data";
import mileageContractService from "../mileage-contract.service";
import MileageContractTemplate from "./mileage-contract-template";

const useMileageContractTemplate = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MileageContractTemplate | null>(null);

  const load = async (signal: AbortSignal): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const mileageList = await mileageContractService.getTemplate(signal);

      setData(mileageList);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useMileageContractTemplate;
