import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import OrderOptimizerListingRouteQueryParams from "../../common/routes/types/order-optimizer-listing-route-params";
import OrderOptimizerListingFilter from "../common/types/order-optimizer-listing-filter";
import orderOptimizerListingFiltersService from "./order-optimizer-listing-filters.service";

const useOrderOptimizerListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderOptimizerListingRouteQueryParams>();

  const getFilters = (): OrderOptimizerListingFilter[] => {
    return orderOptimizerListingFiltersService.getFilters(routeQueryParams);
  };

  const saveFilters = (filters: OrderOptimizerListingFilter[]): void => {
    const routeQueryParams =
      orderOptimizerListingFiltersService.createRouteQueryParams(filters);

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    saveFilters,
  };
};

export default useOrderOptimizerListingFiltersDao;
