import BillingsPlanEntryCargoTaxiResponse, {
  BillingsPlanEntryCargoTaxiResponseItem,
} from "../../../../api/raily/billings/plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi.response";
import BillingModel from "../../../../types/billing-model";
import BillingStatus from "../../../../types/billing-status";
import BillingsPlanEntryCargoTaxi, {
  BillingsPlanEntryCargoTaxiItem,
} from "./billings-plan-entry-cargo-taxi";

const createDataItem = (
  data: BillingsPlanEntryCargoTaxiResponseItem
): BillingsPlanEntryCargoTaxiItem => {
  return {
    billingUuid: data.id,
    cargoOrderUuid: data.cargoOrder?.id!,
    contractDetails: {
      companyName: data.contract.cargoCompany.displayName,
      distanceRate: data.contract.distanceRate,
      model: data.contract.model as BillingModel,
    },
    amountForDistance: data.amountForDistance,
    amountForChargeHaltings: data.amountForChargeHaltings,
    amountForChargeHighways: data.amountForChargeHighways,
    distance: data.distance,
    internalOrderId: data.cargoOrder?.humanId!,
    contractCorrectionPercentage: data.contractCorrectionPercentage,
    allContributionsAmount: data.allContributionsAmount,
    sumOfDiscounts: data.sumOfDiscounts,
    isDraft: data.draft,
    date: data.date,
    status: data.status as BillingStatus,
  };
};

const createData = (
  responseData: BillingsPlanEntryCargoTaxiResponseItem[]
): BillingsPlanEntryCargoTaxiItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: BillingsPlanEntryCargoTaxiResponse
): BillingsPlanEntryCargoTaxi => {
  return {
    data: createData(response.data),
  };
};

const billingsPlanEntryCargoTaxiFactory = {
  create,
};

export default billingsPlanEntryCargoTaxiFactory;
