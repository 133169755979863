import formValidationService from "../../../../../../../common/utils/validation/form-validation.service";
import DriverContractAddByRailyFormData from "./types/driver-contract-add-by-raily-form-data";
import DriverContractAddByRailyFormValidationResults from "./types/driver-contract-add-by-raily-form-validation-results";

const getDefaultFormValidationResults =
  (): DriverContractAddByRailyFormValidationResults => {
    return {
      periodOfValidity: formValidationService.defaultValidationResult,
      billingModel: formValidationService.defaultValidationResult,
      distanceRate: formValidationService.defaultValidationResult,
      haltingTimeRate: formValidationService.defaultValidationResult,
      haltingTimeGracePeriod: formValidationService.defaultValidationResult,
      taxiAssociation: formValidationService.defaultValidationResult,
      isActive: formValidationService.defaultValidationResult,
      notes: formValidationService.defaultValidationResult,
    };
  };

const getDefaultFormData = (): DriverContractAddByRailyFormData => {
  return {
    periodOfValidity: null,
    billingModel: null,
    distanceRate: null,
    haltingTimeRate: null,
    haltingTimeGracePeriodMinutes: null,
    taxiAssociation: null,
    isActive: null,
    notes: null,
  };
};

const driverContractAddByRailyFormHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default driverContractAddByRailyFormHelper;
