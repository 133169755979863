import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../languages/settlement-translations.helper";
import { useAppContext } from "../../../../context/app.context";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import TaxiUnsettledTransferedOrderListingItem from "./common/types/taxi-unsettled-transfered-order-listing-item";
import TaxiUnsettledTransferedOrderListingFilter, {
  TaxiUnsettledTransferedOrderListingStartDateFilter,
} from "./common/types/taxi-unsettled-transfered-order-listing-filter";
import taxiUnsettledTransferedOrderListingRouteQueryParamsService from "./common/taxi-unsettled-transfered-order-listing-route-query-params.service";
import TaxiUnsettledTransferedOrderListingSortKey from "./common/types/taxi-unsettled-transfered-order-listing-sort-key";
import TaxiUnsettledTransferedOrderListingSortSelectOption from "./common/types/taxi-unsettled-transfered-order-listing-sort-select-option";
import TaxiUnsettledTransferedOrderListingSortHelper from "./common/helper/taxi-unsettled-transfered-order-listing-sort.helper";
import usePagination from "../../../../common/hooks/use-pagination";
import TaxiUnsettledTransferedOrderListingResponse, {
  TaxiUnsettledTransferedOrderListingResponseData,
} from "./common/api/taxi-unsettled-transfered-order-listing-response";
import taxiUnsettledTransferedOrderListingRequestFactory from "./common/factory/taxi-unsettled-transfered-order-listing-request.factory";
import taxiUnsettledTransferedOrderListingApiService from "./common/api/taxi-unsettled-transfered-order-listing-api.service";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import TaxiUnsettledTransferedOrderListingRouteQueryParams from "./common/types/taxi-unsettled-transfered-order-listing-route-query-params";
import TaxiUnsettledTransferedOrderListingFactory from "./common/factory/taxi-unsettled-transfered-order-listing.factory";
import TaxiUnsettledTransferedOrderListingFilterType from "./common/types/taxi-unsettled-transfered-order-listing-filter-type";
import DateRange from "../../../../common/types/date-range";
import TaxiUnsettledTransferedOrderListingFiltersSelectComponent from "./common/filters/select/taxi-unsettled-transfered-order-listing-filters-select.component";
import TaxiUnsettledTransferedOrderListingFiltersBadgeListComponent from "./common/filters/list/taxi-unsettled-transfered-order-listing-filters-badge-list.component";
import CardComponent from "../../../../common/components/card/card.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import TaxiUnsettledTransferedOrderListingTableComponent from "./common/table/taxi-unsettled-transfered-order-listing-table.component";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import settlementBreadcrumbsHelper from "../../common/breadcrumbs/settlement-breadcrumbs.helper";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";

const TaxiUnsettledTransferedOrderListingComponent: FC = () => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledTransferedOrderListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.settlementsTaxiUnsettledTransferedOrderListing
  );

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiUnsettledTransferedOrderListingRouteQueryParams>();

  const [isListingFetching, setIsListingFetching] = useState(false);

  const [isListingFetchingError, setIsListingFetchingError] = useState(false);

  const [listingItems, setListingItems] = useState<
    TaxiUnsettledTransferedOrderListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [filters, setFilters] = useState<
    TaxiUnsettledTransferedOrderListingFilter[]
  >(() =>
    taxiUnsettledTransferedOrderListingRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiUnsettledTransferedOrderListingSortKey | null>(
      () =>
        taxiUnsettledTransferedOrderListingRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: TaxiUnsettledTransferedOrderListingSortSelectOption[] =
    useMemo(
      () => TaxiUnsettledTransferedOrderListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      taxiUnsettledTransferedOrderListingRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiUnsettledTransferedOrderListingRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiUnsettledTransferedOrderListingRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  useEffect(() => {
    const breadcrumbs =
      settlementBreadcrumbsHelper.getTaxiUnsettledTransferedOrderListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const onListingFetchSuccess = (
    responseData: TaxiUnsettledTransferedOrderListingResponseData
  ) => {
    setTotalResults(responseData.total_count);

    const listingItems =
      TaxiUnsettledTransferedOrderListingFactory.createListingItems(
        responseData.data
      );

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiUnsettledTransferedOrderListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request =
      taxiUnsettledTransferedOrderListingRequestFactory.createRequest(
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    taxiUnsettledTransferedOrderListingApiService
      .fetchListing(request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [page, pageSize, filters, selectedSortKey]);

  const addNewFilter = (
    newFilter: TaxiUnsettledTransferedOrderListingFilter
  ) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey, selectedAppLanguage]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === TaxiUnsettledTransferedOrderListingFilterType.START_DATE
  )?.value as
    | TaxiUnsettledTransferedOrderListingStartDateFilter["value"]
    | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !==
          TaxiUnsettledTransferedOrderListingFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === TaxiUnsettledTransferedOrderListingFilterType.START_DATE
    );

    const newFilter: TaxiUnsettledTransferedOrderListingStartDateFilter = {
      type: TaxiUnsettledTransferedOrderListingFilterType.START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            TaxiUnsettledTransferedOrderListingFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  return (
    <div className="taxi_unsettled_transfered_order_listing">
      <HeadingComponent title={translations.header.headingText} />
      <div className="taxi_unsettled_transfered_order_listing_tools">
        <div className="d-flex">
          <TaxiUnsettledTransferedOrderListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="taxi-unsettled-transfered-order-listing-date-range"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="taxi-unsettled-transfered-order-listing-sort"
        />
      </div>
      <TaxiUnsettledTransferedOrderListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TaxiUnsettledTransferedOrderListingTableComponent
          listingItems={listingItems}
          isError={isListingFetchingError}
          isLoading={isListingFetching}
        />
        <div className="taxi_unsettled_transfered_order_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default TaxiUnsettledTransferedOrderListingComponent;