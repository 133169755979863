import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import { useAppContext } from "../../../../../context/app.context";
import MileageDictionaryListingItem from "../common/types/mileage-dictionary-listing-item";
import mileageDictionaryListingTableHelper from "./mileage-dictionary-listing-table.helper";
import MileageDictionaryListingTableColumn from "./types/mileage-dictionary-listing-table-column";
import MileageDictionaryListingTableRow from "./types/mileage-dictionary-listing-table-row";
import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import { faCheck, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";
import mileageRoutesHelper from "../../../common/routes/mileage-routes.helper";

type MileageDictionaryListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  cargoCompanyUuid: string | undefined;
  listingItems: MileageDictionaryListingItem[];
};

const MileageDictionaryListingTableComponent: FC<
  MileageDictionaryListingTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const createTableRow = (
    cargoCompanyUuid: string,
    item: MileageDictionaryListingItem
  ): MileageDictionaryListingTableRow => {
    const translations =
      mileageTranslationsHelper.getMileageDictionaryTranslations().table;

    return {
      id: item.uuid,
      value: {
        active: (
          <div title={translations.activityStatusTitle}>
            {item.active ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        name: <div title={translations.dictionaryNameTitle}>{item.name}</div>,
        actions: (
          <TableLinkButtonComponent
            title={translations.editButtonTitle}
            icon={faEdit}
            to={mileageRoutesHelper.getDictionaryRecordUpdateRoute({
              cargoCompanyUuid: cargoCompanyUuid,
              recordUuid: item.uuid,
            })}
            idForTesting="mileage-dictionary-listing-table-edit-link-button"
          />
        ),
      },
    };
  };

  const columns = useMemo(
    (): MileageDictionaryListingTableColumn[] =>
      mileageDictionaryListingTableHelper.getColumns(),
    [selectedAppLanguage]
  );

  const rows: MileageDictionaryListingTableRow[] = useMemo(() => {
    if (!props.cargoCompanyUuid) {
      return [];
    }

    return props.listingItems.map((item) =>
      createTableRow(props.cargoCompanyUuid!, item)
    );
  }, [JSON.stringify(props.listingItems)]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default MileageDictionaryListingTableComponent;
