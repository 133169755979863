import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsTHandlingLogEntryForwardCancelEvent } from "../../../common/types/order-details-handling-log-entry-event";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations()
    .forwardCancelOrderEntry;

const getHeadingText = (
  producerDisplayName: OrderDetailsTHandlingLogEntryForwardCancelEvent["producerDisplayName"],
  consumerDisplayName: OrderDetailsTHandlingLogEntryForwardCancelEvent["consumerDisplayName"]
) => {
  return translations.headingText
    .replace("#{producerDisplayName}", producerDisplayName ?? "-----")
    .replace("#{consumerDisplayName}", consumerDisplayName ?? "-----");
};

const orderDetailsHistoryEntryForwardCancelContentHelper = { getHeadingText };

export default orderDetailsHistoryEntryForwardCancelContentHelper;
