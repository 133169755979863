import { FC } from "react";
import DelegationListingActionData from "../common/types/delegation-listing-action-data";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import delegationListingActionsModalHelper from "./delegation-listing-actions-modal.helper";
import ButtonComponent from "../../../../common/components/button/button.component";

type DelegationListingActionsModalProps = {
  actionDataList: DelegationListingActionData[];
  onSuccess: () => void;
  onCancel: () => void;
};

const DelegationListingActionsModalComponent: FC<
  DelegationListingActionsModalProps
> = (props) => {
  const translations =
    delegationTranslationsHelper.getDelegationListingTranslations().download;

  const sourcesLabel = delegationListingActionsModalHelper.getDataLabel(
    props.actionDataList
  );

  const isSubmitButtonEnabled = !!props.actionDataList.length;

  return (
    <div className="delegation_listing_action_modal">
      <div className="delegation_listing_action_modal__heading">
        {translations.headingLabel}
      </div>
      <div className="delegation_listing_action_modal__content">
        <div>
          <div>{sourcesLabel}</div>
        </div>
        <div className="d-flex mt-2">
          <ButtonComponent
            type="success"
            isDisabled={!isSubmitButtonEnabled}
            title={translations.submitButtonTitle}
            onClick={props.onSuccess}
          >
            {translations.submitButtonLabel}
          </ButtonComponent>
          <ButtonComponent
            type="brand"
            onClick={props.onCancel}
            classNames={{ root: "ml-1" }}
            title={translations.cancelButtonTitle}
          >
            {translations.cancelButtonLabel}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default DelegationListingActionsModalComponent;
