import PaginationSelectOption from "./pagination-select-option";

const getDefaultPageSizeOptions = (): number[] => {
  return [50, 100, 200, 500];
};

const getDefaultPageSize = (): number => {
  return 100;
};

const getDefaultSelectOptions = (): PaginationSelectOption[] => {
  const defaultPageSizeOptions = getDefaultPageSizeOptions();

  return defaultPageSizeOptions.map(createSelectOption);
};

const createSelectOption = (pageSize: number): PaginationSelectOption => {
  return {
    label: pageSize.toString(),
    value: pageSize,
  };
};

const getDefaultSelectedSelectOption = (): PaginationSelectOption => {
  const defaultPageSize = getDefaultPageSize();

  const option = getDefaultSelectOptions().find(
    (x) => x.value === defaultPageSize
  );

  return option ?? createSelectOption(defaultPageSize);
};

const createSelectOptions = (
  pageSizeOptions?: number[]
): PaginationSelectOption[] => {
  if (!pageSizeOptions?.length) {
    return getDefaultSelectOptions();
  }

  return pageSizeOptions.map(createSelectOption);
};

const getSelectedOption = (
  defaultPageSize?: number
): PaginationSelectOption => {
  if (!defaultPageSize) {
    return getDefaultSelectedSelectOption();
  }

  return createSelectOption(defaultPageSize);
};

const paginationHelper = {
  createSelectOptions,
  getSelectedOption,
};

export default paginationHelper;
