export enum DelegationAddFromMileagesParamsTimezone {
  EUROPE_WARSAW = "Europe/Warsaw",
  UTC = "UTC",
}

type DelegationAddFromMileagesParams = {
  cargoCompanyUuid: string;
  workerUuid: string;
  mileageUuids: string[];
  timezone?: DelegationAddFromMileagesParamsTimezone;
};

export default DelegationAddFromMileagesParams;
