export enum CargoAddressEditRequestAddressType {
  HOTEL = "HOTEL",
  STATION = "STATION",
  MEETING_POINT = "MEETING_POINT",
}

type CargoAddressEditUpdateRequest = {
  lat: number;
  lon: number;
  country: string | null;
  town: string | null;
  zip_code: string | null;
  street: string | null;
  house_no: string | null;
  apartment: string | null;
  description: string | null;
  type: CargoAddressEditRequestAddressType;
};

export default CargoAddressEditUpdateRequest;
