import { FC, ReactNode } from "react";

type RowProps = {
  children: ReactNode;
};

const Row: FC<RowProps> = (props) => {
  return <div className="row">{props.children}</div>;
};

export default Row;
