export enum RouteFinishedListingRequestOrder {
  DRIVER_ASC = "DRIVER_ASC",
  DRIVER_DESC = "DRIVER_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  TAXI_CORPORATION_ASC = "TAXI_CORPORATION_ASC",
  TAXI_CORPORATION_DESC = "TAXI_CORPORATION_DESC",
}

export type RouteFinishedListRequestQueryParams = {
  order: RouteFinishedListingRequestOrder | undefined;
  taxiCorporationId: string | undefined;
  driverId: string | undefined;
  driverName: string[] | undefined;
  taxiName: string[] | undefined;
  planEntryNodes: string[] | undefined;
  startingNodeLabel: string[] | undefined;
  endingNodeLabel: string[] | undefined;
  startTimeSince: Date | undefined;
  startTimeTo: Date | undefined;
  endTimeSince: Date | undefined;
  endTimeTo: Date | undefined;
  humanId: number[] | undefined;
  involvedCargoLabels: string[] | undefined;
  isBilled: boolean | undefined;
  cargoOrderId: string | undefined;
  cargoOrderHumanId: number | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
  limit: number | undefined;
  offset: number | undefined;
};

type RouteFinishedListRequest = {
  order?: RouteFinishedListingRequestOrder;
  taxiCorporationId?: string;
  driverId?: string;
  driverName?: string[];
  taxiName?: string[];
  planEntryNodes?: string[];
  startingNodeLabel?: string[];
  endingNodeLabel?: string[];
  startTimeSince?: Date;
  startTimeTo?: Date;
  endTimeSince?: Date;
  endTimeTo?: Date;
  humanId?: number[];
  involvedCargoLabels?: string[];
  isBilled?: boolean;
  cargoOrderId?: string;
  cargoOrderHumanId?: number;
  creationStart?: Date;
  creationEnd?: Date;
  limit?: number;
  offset?: number;
};

export default RouteFinishedListRequest;
