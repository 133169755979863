import { FC, useEffect, useMemo, useState } from "react";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import ModalComponent from "../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import htmlParser from "../../../../common/utils/html-parser/html-parser.service";
import mileageDetailsMileageAcceptHelper from "./mileage-accept.helper";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import MileageDetailsInternal from "../common/types/mileage-details-internal";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import useForm from "../../../../common/components/form/use-form";
import MileageDetailsFormData from "../form/types/mileage-details-form-data";
import mileageDetailsFormHelper from "../form/mileage-details-form.helper";
import mileageDetailsFormFactory from "../form/mileage-details-form.factory";
import FormErrorComponent from "../../../../common/components/form/error/form-error.component";
import FormComponent from "../../../../common/components/form/form.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import classNames from "classnames";

type MileageDetailsMileageAcceptModalComponentProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (acceptedDistance: number) => void;
  onReject: () => void;
  details: MileageDetailsInternal;
};

const MileageDetailsMileageAcceptModalComponent: FC<
  MileageDetailsMileageAcceptModalComponentProps
> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageDetailsTranslations().acceptMileage
      .confirmationModal;

  const form = useForm<MileageDetailsFormData>({
    emptyValues: mileageDetailsFormFactory.create(
      props.details.acceptedDistance
    ),
    validationDefinition: mileageDetailsFormHelper.getValidationDefinition(),
  });

  const contentText = mileageDetailsMileageAcceptHelper.getContentMessageText(
    String(props.details.humanId)
  );

  const selectDistance = () => {
    form.setValue("acceptedDistance", props.details.distance);
  };

  const selectOsrmDistance = () => {
    form.setValue("acceptedDistance", props.details.osrmDistance);
  };

  const onConfirm = async () => {
    const validationResult = await form.validateAll();

    if (!validationResult) {
      return;
    }

    props.onConfirm(form.values.acceptedDistance!);
  };

  useEffect(() => {
    form.validate("acceptedDistance");
  }, [form.values.acceptedDistance]);

  const differenceResult: number = useMemo(() => {
    return props.details.distance - props.details.osrmDistance;
  }, [props.details.distance, props.details.osrmDistance]);

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          type="success"
          onClick={onConfirm}
          title={translations.confirmationButtonTitle}
          idForTesting="mileage-details-modal-confirm-button"
        >
          {translations.confirmationButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="danger"
          onClick={props.onReject}
          title={translations.rejectButtonTitle}
          idForTesting="mileage-details-modal-reject-button"
        >
          {translations.rejectButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.cancelButtonTitle}
          idForTesting="mileage-details-modal-cancel-button"
        >
          {translations.cancelButtonText}
        </ButtonComponent>,
      ]}
    >
      {htmlParser.parse(contentText)}
      <div className="mileage_details_modal">
        <FormFieldComponent
          label={translations.osrmDistanceTitle}
          classNames={{ root: "mileage_details_modal_field" }}
        >
          <ButtonComponent
            classNames={{
              content: "mileage_details_modal_value_button_content",
              root: "mileage_details_modal_value_button_label",
            }}
            type="primary"
            onClick={selectOsrmDistance}
            title={translations.osrmDistanceTitle}
            idForTesting="mileage-details-modal-osrm-distance-button"
          >
            {props.details.osrmDistance}
          </ButtonComponent>
        </FormFieldComponent>
        <FormFieldComponent
          label={translations.implementedDistanceTitle}
          classNames={{ root: "mileage_details_modal_field" }}
        >
          <ButtonComponent
            classNames={{
              content: "mileage_details_modal_value_button_content",
              root: "mileage_details_modal_value_button_label",
            }}
            type="primary"
            onClick={selectDistance}
            title={translations.implementedDistanceTitle}
            idForTesting="mileage-details-modal-implemented-distance-button"
          >
            {props.details.distance}
          </ButtonComponent>
        </FormFieldComponent>
        <FormFieldComponent
          label={translations.differenceTitle}
          classNames={{ root: "mileage_details_modal_field" }}
        >
          <NumericInputComponent
            classNames={{
              input: classNames(
                "mileage_details_modal_difference_input",
                `mileage_details_modal_difference_input__${
                  differenceResult >= 0 ? "positive" : "negative"
                }`
              ),
              root: "mileage_details_modal_diferrence_label",
            }}
            isReadOnly
            value={Number(differenceResult.toFixed(2))}
          />
        </FormFieldComponent>
        <FormFieldComponent
          label={translations.acceptedDistanceTitle}
          classNames={{ root: "mileage_details_modal_field" }}
        >
          <NumericInputComponent
            classNames={{ root: "mileage_details_modal_numeric_input" }}
            value={form.values.acceptedDistance}
            onChange={(value) => {
              form.setValue("acceptedDistance", value);
            }}
            decimalPrecision={2}
            idForTesting="mileage-details-modal-accepted-distance-input"
          />
          <FormErrorComponent
            message={form.validationResults.acceptedDistance.errorMessage}
          />
        </FormFieldComponent>
      </div>
    </ModalComponent>
  );
};

export default MileageDetailsMileageAcceptModalComponent;
