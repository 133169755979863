import DelegationAddFromMileagesRequest from "../../api/raily/delegation/add-from-mileages/delegation-add-from-mileages.request";
import DelegationAddRequest from "../../api/raily/delegation/add/delegation-add.request";
import delegationApiService from "../../api/raily/delegation/delegation-api.service";
import DelegationDeleteRequest from "../../api/raily/delegation/delete/delegation-delete.request";
import DelegationDownloadRequest from "../../api/raily/delegation/download/delegation-download.request";
import DelegationListRequest from "../../api/raily/delegation/list/delegation-list.request";
import { DataLoadDefaultErrors } from "../../utils/data-load-error/data-load-error";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import fileDownloadService from "../../utils/file-download/file-download.service";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import SearchRoadRoutingRequest from "../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../utils/search-road-route/search-road-routing.response";
import DelegationAddFromMileagesError from "./add-from-mileages/delegation-add-from-mileages-error";
import DelegationAddFromMileagesParams from "./add-from-mileages/delegation-add-from-mileages-params";
import delegationAddFromMileagesRequestFactory from "./add-from-mileages/delegation-add-from-mileages-request.factory";
import DelegationAddError from "./add/delegation-add-error";
import DelegationAddParams from "./add/delegation-add-params";
import delegationAddRequestFactory from "./add/delegation-add-request.factory";
import DelegationDeleteError from "./delete/delegation-delete-error";
import DelegationDeleteParams from "./delete/delegation-delete-params";
import DelegationDownloadError from "./download/delegation-download-error";
import DelegationDownloadParams from "./download/delegation-download-params";
import delegationDownloadRequestFactory from "./download/delegation-download-request.factory";
import DelegationDetails from "./details/delegation-details";
import DelegationDetailsError from "./details/delegation-details-error";
import DelegationDetailsLoadParams from "./details/delegation-details-load-params";
import delegationDetailsRequestFactory from "./details/delegation-details-request.factory";
import delegationDetailsFactory from "./details/delegation-details.factory";
import DelegationListError from "./list/delegation-list-error";
import DelegationListLoadParams from "./list/delegation-list-load-params";
import delegationListRequestFactory from "./list/delegation-list-request.factory";
import delegationListFactory from "./list/delegation-list.factory";
import DelegationRoadRouteItem from "./road-route/delegation-road-route-item";
import DelegationRoadRouteLoadParams from "./road-route/delegation-road-route-load-params";
import delegationRoadRouteRequestFactory from "./road-route/delegation-road-route-request.factory";
import delegationRoadRouteFactory from "./road-route/delegation-road-route.factory";

const handleListError = (
  error: HttpError | DelegationListError
): DelegationListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleAddError = (
  error: HttpError | DelegationAddError
): DelegationAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleAddFromMileagesError = (
  error: HttpError | DelegationAddFromMileagesError
): DelegationAddFromMileagesError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDetailsError = (
  error: HttpError | DelegationDetailsError
): DelegationDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDeleteError = (error: HttpError | DelegationDeleteError) => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDownloadError = (
  error: HttpError | DelegationDownloadError
): DelegationDownloadError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleRoadRouteError = (error: HttpError): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  loadParams: DelegationListLoadParams,
  abortSignal: AbortSignal
) => {
  const request: DelegationListRequest =
    delegationListRequestFactory.create(loadParams);

  try {
    const response = await delegationApiService().list(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const delegationList = delegationListFactory.create(response);
    return delegationList;
  } catch (_error) {
    const error = _error as HttpError | DelegationListError;

    throw handleListError(error);
  }
};

const add = async (
  params: DelegationAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DelegationAddRequest =
    delegationAddRequestFactory.create(params);

  try {
    const response = await delegationApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DelegationAddError;

    throw handleAddError(error);
  }
};

const addFromMileages = async (
  params: DelegationAddFromMileagesParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DelegationAddFromMileagesRequest =
    delegationAddFromMileagesRequestFactory.create(params);

  try {
    const response = await delegationApiService().addFromMileages(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DelegationAddFromMileagesError;

    throw handleAddFromMileagesError(error);
  }
};

const getDetails = async (
  params: DelegationDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<DelegationDetails> => {
  const request = delegationDetailsRequestFactory.create(params);

  try {
    const response = await delegationApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return delegationDetailsFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | DelegationDetailsError;

    throw handleDetailsError(error);
  }
};

const deleteDelegation = async (
  params: DelegationDeleteParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DelegationDeleteRequest = {
    delegationUuid: params.delegationUuid,
  };

  try {
    const response = await delegationApiService().deleteDelegation(
      request,
      abortSignal
    );

    if (response.status !== 204) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DelegationDeleteError;

    throw handleDeleteError(error);
  }
};

const download = async (params: DelegationDownloadParams): Promise<void> => {
  const request: DelegationDownloadRequest =
    delegationDownloadRequestFactory.create(params);

  try {
    const response = await delegationApiService().download(request);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    fileDownloadService.downloadFromBlob(
      response.data.data,
      response.data.filename
    );
  } catch (_error) {
    const error = _error as HttpError | DelegationDownloadError;

    throw handleDownloadError(error);
  }
};
const getRoute = async (
  params: DelegationRoadRouteLoadParams
): Promise<DelegationRoadRouteItem> => {
  const request = delegationRoadRouteRequestFactory.create(params);

  try {
    const response = await delegationApiService().getRoute(request);

    return delegationRoadRouteFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError;

    throw handleRoadRouteError(error);
  }
};

const delegationService = {
  getList,
  add,
  addFromMileages,
  getDetails,
  deleteDelegation,
  download,
  getRoute,
};

export default delegationService;
