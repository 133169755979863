import dateService from "../../../../../../../../common/utils/date/date.service";
import durationService from "../../../../../../../../common/utils/duration/duration.service";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
    .insertNodeBefore;

const getAddressLabel = (addressDisplayName: string) => {
  return translations.addressTemplateText.replace(
    "#{addressDisplayName}",
    addressDisplayName
  );
};

const getAddressTitle = (addressDisplayName: string) => {
  return translations.addressTemplateText.replace(
    "#{addressDisplayName}",
    addressDisplayName
  );
};

const getPlannedDateLabel = (plannedDate: Date | null) => {
  const formattedDate = plannedDate
    ? dateService.formatDateTime(plannedDate)
    : `-----`;

  return translations.dateTemplateText.replace(
    "#{plannedDateString}",
    formattedDate
  );
};

const getPlannedDateTitle = (plannedDate: Date | null) => {
  const formattedDate = plannedDate
    ? dateService.formatDateTime(plannedDate)
    : `-----`;

  return translations.dateTemplateText.replace(
    "#{plannedDateString}",
    formattedDate
  );
};

const getHaltingTimeLabel = (haltingTime: number) => {
  const formattedHaltingTime = durationService.format(haltingTime);

  return translations.haltingTimeTemplateText.replace(
    "#{haltingTime}",
    formattedHaltingTime
  );
};

const getHaltingTimeTitle = (haltingTime: number) => {
  const formattedHaltingTime = durationService.format(haltingTime);

  return translations.haltingTimeTemplateText.replace(
    "#{haltingTime}",
    formattedHaltingTime
  );
};

const orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper = {
  getAddressLabel,
  getAddressTitle,
  getPlannedDateLabel,
  getPlannedDateTitle,
  getHaltingTimeLabel,
  getHaltingTimeTitle,
};

export default orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper;
