import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type RailyOfficerListingUserPermissionOption =
  | "hasAccessToDeleteUser"
  | "hasVisibleAddRailyOfficerButton";

export type RailyOfficerListingUserPermissionsDefinition =
  UserPermissionsDefinition<RailyOfficerListingUserPermissionOption>;

export type RailyOfficerListingUserPermissions =
  UserPermissions<RailyOfficerListingUserPermissionOption>;

const railyOfficerListingUserPermissionDefinition: RailyOfficerListingUserPermissionsDefinition =
  {
    hasAccessToDeleteUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasVisibleAddRailyOfficerButton: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  };

export default railyOfficerListingUserPermissionDefinition;
