import {
  DelegationListItem,
  DelegationListItemStatus,
  DelegationListItemType,
} from "../../../../common/services/delegation/list/delegation-list";
import DelegationListingDelegationStatus from "./types/delegation-listing-delegation-status";
import DelegationListingDelegationType from "./types/delegation-listing-delegation-type";
import DelegationListingItem from "./types/delegation-listing-item";

const createStatus = (
  status: DelegationListItemStatus
): DelegationListingDelegationStatus => {
  switch (status) {
    case DelegationListItemStatus.DELETED:
      return DelegationListingDelegationStatus.DELETED;
    case DelegationListItemStatus.DOWNLOADED:
      return DelegationListingDelegationStatus.DOWNLOADED;
    case DelegationListItemStatus.UNDOWNLOADED:
      return DelegationListingDelegationStatus.UNDOWNLOADED;
  }
};

const createType = (
  type: DelegationListItemType
): DelegationListingDelegationType => {
  switch (type) {
    case DelegationListItemType.AUTO:
      return DelegationListingDelegationType.AUTO;
    case DelegationListItemType.MANUAL:
      return DelegationListingDelegationType.MANUAL;
  }
};

const createListingItem = (item: DelegationListItem): DelegationListingItem => {
  return {
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    dateFrom: item.dateFrom,
    dateTo: item.dateTo,
    creationDate: item.creationDate,
    delegationNumber: item.delegationNumber,
    cargoCompanyName: item.cargoCompanyName,
    humanId: item.humanId,
    status: createStatus(item.status),
    totalCost: item.totalCost,
    type: createType(item.type),
    uuid: item.uuid,
    totalDistance: item.totalDistance,
    workerName: item.workerName,
    downloadedCsvAt: item.downloadedCsvAt,
    downloadedCsvBy: item.downloadedCsvBy,
    downloadedPdfAt: item.downloadedPdfAt,
    downloadedPdfBy: item.downloadedPdfBy,
  };
};

const create = (data: DelegationListItem[]): DelegationListingItem[] => {
  const items: DelegationListingItem[] = data.map(createListingItem);

  return items;
};

const delegatonListingItemFactory = {
  create,
};

export default delegatonListingItemFactory;
