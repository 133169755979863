import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import OrderCancelParams from "./cancel/order-cancel-params";
import OrderCancelRequest from "../../api/raily/order/order-cancel/order-cancel.request";
import orderCancelRequestFactory from "./cancel/order-cancel-request.factory";
import orderApiService from "../../api/raily/order/order-api.service";
import {
  OrderCancelNotAcceptableResponse,
  OrderCancelNotAcceptableResponseErrorType,
} from "../../api/raily/order/order-cancel/order-cancel.response";
import OrderCancelError from "./cancel/order-cancel-error";
import {
  OrderCancelRequestNotAcceptableResponse,
  OrderCancelRequestNotAcceptableResponseErrorType,
} from "../../api/raily/order/order-cancel-request/order-cancel-request.response";
import OrderCancelErrorFactory from "./cancel/order-cancel-error.factory";
import OrderCancelRequestParams from "./cancel-request/order-cancel-request-params";
import OrderCancelRequestRequest from "../../api/raily/order/order-cancel-request/order-cancel-request.request";
import orderCancelRequestRequestFactory from "./cancel-request/order-cancel-request-request.factory";
import OrderCancelRequestError from "./cancel-request/order-cancel-request-error";
import OrderCancelRequestErrorFactory from "./cancel-request/order-cancel-request-error.factory";

const handleCancelError = (
  error: HttpError | OrderCancelError
): OrderCancelError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createOrderCancelNotAcceptableError = (
  response: OrderCancelNotAcceptableResponse
): OrderCancelError => {
  switch (response.data.errorType) {
    case OrderCancelNotAcceptableResponseErrorType.ORDER_CANCELLED:
      return OrderCancelErrorFactory.createOrderCancelError();
    case OrderCancelNotAcceptableResponseErrorType.PASSENGER_ON_BOARD:
      return OrderCancelErrorFactory.createPassengerOnBoardError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const cancel = async (
  params: OrderCancelParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: OrderCancelRequest = orderCancelRequestFactory.create(params);

  try {
    const response = await orderApiService().cancel(request, abortSignal);

    if (response.status === 406) {
      throw createOrderCancelNotAcceptableError(
        response as OrderCancelNotAcceptableResponse
      );
    }

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | OrderCancelError;

    throw handleCancelError(error);
  }
};

const handleCancelRequestError = (
  error: HttpError | OrderCancelRequestError
): OrderCancelRequestError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createOrderCancelRequestNotAcceptableError = (
  response: OrderCancelRequestNotAcceptableResponse
): OrderCancelRequestError => {
  switch (response.data.errorType) {
    case OrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCELLED:
      return OrderCancelRequestErrorFactory.createOrderCancelError();
    case OrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCEL_REQUESTED:
      return OrderCancelRequestErrorFactory.createOrderCancelRequestError();
    case OrderCancelRequestNotAcceptableResponseErrorType.ORDER_FINISHED:
      return OrderCancelRequestErrorFactory.createOrderFinishedError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const sendCancelRequest = async (
  params: OrderCancelRequestParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: OrderCancelRequestRequest =
    orderCancelRequestRequestFactory.create(params);

  try {
    const response = await orderApiService().sendCancelRequest(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createOrderCancelRequestNotAcceptableError(
        response as OrderCancelRequestNotAcceptableResponse
      );
    }

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | OrderCancelRequestError;

    throw handleCancelRequestError(error);
  }
};

const orderService = {
  cancel,
  sendCancelRequest,
};

export default orderService;
