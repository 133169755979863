import CargoOfficerAddRequest from "../../api/raily/cargo-officer/add/cargo-officer-add.request";
import cargoOfficerApiService from "../../api/raily/cargo-officer/cargo-officer-api.service";
import CargoOfficerListRequest from "../../api/raily/cargo-officer/list/cargo-officer-list.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import CargoOfficerAddError from "./add/cargo-officer-add-error";
import CargoOfficerAddParams from "./add/cargo-officer-add-params";
import cargoOfficerAddRequestFactory from "./add/cargo-officer-add-request.factory";
import CargoOfficerList from "./list/cargo-officer-list";
import CargoOfficerListError from "./list/cargo-officer-list-error";
import CargoOfficerListLoadParams from "./list/cargo-officer-list-load-params";
import cargoOfficerListRequestFactory from "./list/cargo-officer-list-request.factory";
import cargoOfficerListFactory from "./list/cargo-officer-list.factory";

const handleAddError = (
  error: HttpError | CargoOfficerAddError
): CargoOfficerAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleListError = (
  error: HttpError | CargoOfficerListError
): CargoOfficerListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: CargoOfficerAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: CargoOfficerAddRequest =
    cargoOfficerAddRequestFactory.create(params);

  try {
    const response = await cargoOfficerApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | CargoOfficerAddError;

    throw handleAddError(error);
  }
};

const list = async (
  params: CargoOfficerListLoadParams,
  abortSignal: AbortSignal
): Promise<CargoOfficerList> => {
  const request: CargoOfficerListRequest =
    cargoOfficerListRequestFactory.create(params);

  try {
    const response = await cargoOfficerApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoOfficerListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | CargoOfficerListError;

    throw handleListError(error);
  }
};

const cargoOfficerService = {
  add,
  list,
};

export default cargoOfficerService;
