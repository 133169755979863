import { FC, useMemo, useState } from "react";
import DriverContractListingByRailyFilter from "../../common/types/driver-contract-listing-by-raily-filter";
import { useAppContext } from "../../../../../../../../context/app.context";
import driverContractListingByRailyFilterHelper from "../../common/filter/driver-contract-listing-by-raily-filter.helper";
import DriverContractListingByRailyFilterSelectOption from "../../common/types/driver-contract-listing-by-raily-filter-select-option";
import ListingFilterSelectComponent from "../../../../../../../../common/components/listing/filter/select/listing-filter-select.component";

type DriverContractListingByRailyFiltersSelectProps = {
  filters: DriverContractListingByRailyFilter[];
  onAddNewFilter: (filter: DriverContractListingByRailyFilter) => void;
};

const DriverContractListingByRailyFiltersSelectComponent: FC<
  DriverContractListingByRailyFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions = useMemo(() => {
    return driverContractListingByRailyFilterHelper.getSelectOptions(
      searchInputValue,
      props.filters
    );
  }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: DriverContractListingByRailyFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as DriverContractListingByRailyFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
    />
  );
};

export default DriverContractListingByRailyFiltersSelectComponent;
