import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";

const validateName = (name: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(40).required();

  return formValidationService.validate(name, validationSchema);
};

const validateCompanyId = (companyId: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").min(2).max(20);

  return formValidationService.validate(companyId, validationSchema);
};

const validateTaxNumber = (taxNumber: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(20).required();

  return formValidationService.validate(taxNumber, validationSchema);
};

const validateNationalCourtRegister = (
  nationalCourtRegister: string
): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(20);

  return formValidationService.validate(
    nationalCourtRegister,
    validationSchema
  );
};

const validateAddressStreet = (street: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).required();

  return formValidationService.validate(street, validationSchema);
};

const validateAddressHouseNumber = (
  houseNumber: string
): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(50).required();

  return formValidationService.validate(houseNumber, validationSchema);
};

const validateAddressApartmentNumber = (
  apartmentNumber: string
): FormValidationResult => {
  const validationSchema = Joi.string().allow("").max(50).required();

  return formValidationService.validate(apartmentNumber, validationSchema);
};

const validateAddressTown = (town: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).required();

  return formValidationService.validate(town, validationSchema);
};

const validateAddressCountry = (country: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).required();

  return formValidationService.validate(country, validationSchema);
};

const validateAddressZipCode = (zipCode: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(10).required();

  return formValidationService.validate(zipCode, validationSchema);
};

const validateAddressDescription = (
  description: string
): FormValidationResult => {
  const validationSchema = Joi.string().allow("").min(2).max(100).required();

  return formValidationService.validate(description, validationSchema);
};

const signUpDriverCompanyDataFormValidationService = {
  validateName,
  validateCompanyId,
  validateTaxNumber,
  validateNationalCourtRegister,
  validateAddressStreet,
  validateAddressHouseNumber,
  validateAddressApartmentNumber,
  validateAddressTown,
  validateAddressCountry,
  validateAddressZipCode,
  validateAddressDescription,
};

export default signUpDriverCompanyDataFormValidationService;
