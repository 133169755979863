import { useRef, RefObject, FC, ReactNode, KeyboardEvent } from "react";
import classNames from "classnames";
import ComponentClassnames from "../../../types/component-classnames";

export type InputProps = {
  classNames?: ComponentClassnames & {
    input?: string;
  };
  value: string | undefined;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  type?: "email" | "password" | "number" | "text";
  isDisabled?: boolean;
  isReadOnly?: boolean;
  inputRef?: RefObject<HTMLInputElement> | undefined;
  hasError?: boolean;
  idForTesting?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

const InputComponent: FC<InputProps> = (props) => {
  const inputWrapperClassnames = classNames(
    "form_input",
    props.classNames?.root,
    props.hasError && "error",
    props.isDisabled && "disabled"
  );
  const inputClassnames = classNames(
    "form_input__input",
    props.classNames?.input
  );

  const inputWrapperRef: RefObject<HTMLLabelElement> = useRef(null);

  const onInputFocus = () => {
    inputWrapperRef?.current?.classList.add("active");
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const onInputBlur = () => {
    inputWrapperRef?.current?.classList.remove("active");

    if (props.onBlur) props.onBlur();
  };

  const onChange = (value: string) => {
    if (props.onChange) props.onChange(value);
  };

  return (
    <label ref={inputWrapperRef} className={inputWrapperClassnames}>
      {props.iconLeft && (
        <i className="form_input__icon_left">{props.iconLeft}</i>
      )}
      <input
        ref={props.inputRef}
        type={props.type || "text"}
        value={props.value ?? ""}
        placeholder={props.placeholder}
        className={inputClassnames}
        autoComplete="off"
        onChange={(e) => onChange(e.target.value)}
        autoCorrect="false"
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        readOnly={props.isReadOnly || props.isDisabled}
        disabled={props.isDisabled}
        data-test-id={props.idForTesting}
        onKeyDown={props.onKeyDown}
      />
      {props.iconRight && (
        <i className="form_input__icon_right">{props.iconRight}</i>
      )}
    </label>
  );
};

export default InputComponent;
