import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import dateService from "../../../../../common/utils/date/date.service";
import mileageContractUpdateFormValidationService from "../form/mileage-contract-update-form-validation.service";
import MileageContractUpdateFormData from "../form/types/mileage-contract-update-form-data";

const getEmptyFormData = (): MileageContractUpdateFormData => {
  return {
    distanceRateCompanyVehicle: 0.0,
    distanceRateCarGt900cm3: 1.15,
    distanceRateCarLe900cm3: 0.89,
    distanceRateMotorcycle: 0.69,
    distanceRateMoped: 0.42,
    dietFullRate: 45.0,
    dietFullAfterHours: 8,
    dietHalfRate: 22.5,
    dietHalfAfterHours: 12,
    periodOfValidity: null,
    isActive: true,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<MileageContractUpdateFormData> => {
    return {
      distanceRateCompanyVehicle: (values) =>
        mileageContractUpdateFormValidationService.validateDistanceRateCompanyVehicle(
          values.distanceRateCompanyVehicle
        ),
      distanceRateCarGt900cm3: (values) =>
        mileageContractUpdateFormValidationService.validateDistanceRateCarGt900cm3(
          values.distanceRateCarGt900cm3
        ),
      distanceRateCarLe900cm3: (values) =>
        mileageContractUpdateFormValidationService.validateDistanceRateCarLe900cm3(
          values.distanceRateCarLe900cm3
        ),
      distanceRateMotorcycle: (values) =>
        mileageContractUpdateFormValidationService.validateDistanceRateMotorcycle(
          values.distanceRateMotorcycle
        ),
      distanceRateMoped: (values) =>
        mileageContractUpdateFormValidationService.validateDistanceRateMoped(
          values.distanceRateMoped
        ),
      dietFullRate: (values) =>
        mileageContractUpdateFormValidationService.validateDietFullRate(
          values.dietFullRate
        ),
      dietFullAfterHours: (values) =>
        mileageContractUpdateFormValidationService.validateDietFullAfterHours(
          values.dietFullAfterHours
        ),
      dietHalfRate: (values) =>
        mileageContractUpdateFormValidationService.validateDietHalfRate(
          values.dietHalfRate
        ),
      dietHalfAfterHours: (values) =>
        mileageContractUpdateFormValidationService.validateDietHalfAfterHours(
          values.dietHalfAfterHours
        ),
      isActive: (values) =>
        mileageContractUpdateFormValidationService.validateIsActive(
          values.isActive
        ),
      periodOfValidity: (values) =>
        mileageContractUpdateFormValidationService.validatePeriodOfValidity(
          values.periodOfValidity
        ),
    };
  };

const getPeriodOfValidityLabel = (
  validSince: Date | undefined,
  validTo: Date | undefined
): string => {
  if (!validSince || !validTo) {
    return "";
  }

  return `${dateService.formatDate(validSince)} - ${dateService.formatDate(
    validTo
  )}`;
};

const mileageContractUpdateFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
  getPeriodOfValidityLabel,
};

export default mileageContractUpdateFormHelper;
