import OperatorAddFormData from "./types/operator-add-form-data";
import phoneNumberInputHelper from "../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import operatorAddFormValidationService from "./operator-add-form-validation.service";
import { FormValidationDefinition } from "../../../../../common/components/form/use-form";

const getDefaultFormData = (): OperatorAddFormData => {
  return {
    firstName: "",
    lastName: "",
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<OperatorAddFormData> => {
    return {
      alternativePhoneNumber: (values) =>
        operatorAddFormValidationService.validateAlternativePhoneNumber(
          values.alternativePhoneNumber
        ),
      email: (values) =>
        operatorAddFormValidationService.validateEmail(values.email),
      firstName: (values) =>
        operatorAddFormValidationService.validateFirstName(values.firstName),
      lastName: (values) =>
        operatorAddFormValidationService.validateLastName(values.lastName),
      password: (values) =>
        operatorAddFormValidationService.validatePassword(values.password),
      passwordRepeat: (values) =>
        operatorAddFormValidationService.validatePasswordRepeat(
          values.password,
          values.passwordRepeat
        ),
      phoneNumber: (values) =>
        operatorAddFormValidationService.validatePhoneNumber(
          values.phoneNumber
        ),
      username: async (values) =>
        await operatorAddFormValidationService.validateUsername(
          values.username
        ),
    };
  };

const operatorAddFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default operatorAddFormHelper;
