import OrderOptimizerListRequest from "../../api/raily/order-optimizer/list/order-optimizer-list.request";
import orderOptimizerApiService from "../../api/raily/order-optimizer/order-optimizer-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import OrderOptimizerList from "./list/order-optimizer-list";
import OrderOptimizerListError from "./list/order-optimizer-list-error";
import OrderOptimizerListLoadParams from "./list/order-optimizer-list-load-paramts";
import orderOptimizerListRequestFactory from "./list/order-optimizer-list-request.factory";
import orderOptimizerListFactory from "./list/order-optimizer-list.factory";

const handleListError = (
  error: HttpError | OrderOptimizerListError
): OrderOptimizerListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: OrderOptimizerListLoadParams,
  abortSignal: AbortSignal
): Promise<OrderOptimizerList> => {
  const request: OrderOptimizerListRequest =
    orderOptimizerListRequestFactory.create(params);

  try {
    const response = await orderOptimizerApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return orderOptimizerListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | OrderOptimizerListError;

    throw handleListError(error);
  }
};

const orderOptimizerService = {
  getList,
};

export default orderOptimizerService;
