import { FC, useEffect, useState } from "react";
import MapMarker from "../map/types/map-marker";
import SearchAddressResponse from "../../utils/search-address/search-address.response";
import searchAddressApiService from "../../utils/search-address/search-address.service";
import MapCoordinate from "../map/types/map-coordinate";
import MapComponent from "../map/map.component";
import MapAddressSelectorAdress from "./types/map-address-selector-address";
import MapAddressSelectorAddressSelected from "./types/map-address-selector-address-selected";
import MapAddressSelectorMessageBoxComponent from "./map-address-selector-message-box.component";
import mapAddressSelectorFactory from "./map-address-selector.factory";

type MapAddressSelectorProps = {
  address: MapAddressSelectorAdress | undefined;
  onAddressChange?: (value: MapAddressSelectorAddressSelected) => void;
};

const MapAddressSelectorComponent: FC<MapAddressSelectorProps> = (props) => {
  const [mapMarker, setMapMarker] = useState<MapMarker | null>(null);
  const [refreshMapFitFlag, setRefreshMapFitFlag] = useState(false);
  const [selectedMapMarker, setSelectedMapMarker] = useState<MapMarker | null>(
    null
  );
  const [selectedAddress, setSelectedAddress] =
    useState<MapAddressSelectorAddressSelected | null>(null);

  useEffect(() => {
    updateSelectedAddress();
  }, [selectedMapMarker]);

  useEffect(() => {
    if (!props.address) {
      setMapMarker(null);
      setSelectedMapMarker(null);
      setRefreshMapFitFlag((curr) => !curr);
      return;
    }

    const coordinate = {
      latitude: props.address.latitude,
      longitude: props.address.longitude,
    };

    setMapMarker({ coordinate, isDraggable: true });
    setRefreshMapFitFlag((curr) => !curr);
  }, [props.address]);

  const searchAddress = (
    searchAddressQuery: string
  ): Promise<SearchAddressResponse> => {
    return searchAddressApiService.searchByQuery(searchAddressQuery);
  };

  const updateSelectedAddress = () => {
    if (!selectedMapMarker) {
      setSelectedAddress(null);
      return;
    }

    const searchQuery = `${selectedMapMarker.coordinate.latitude}, ${selectedMapMarker.coordinate.longitude}`;

    searchAddress(searchQuery).then((response) => {
      const [item] = response;
      if (!item) return;

      const address = mapAddressSelectorFactory.createSearchAddressOption(item);
      setSelectedAddress(address);
    });
  };

  const handleLongMapClick = (coordinate: MapCoordinate) => {
    setSelectedMapMarker({
      coordinate,
      isDraggable: true,
    });
  };

  const acceptSelectedAddress = () => {
    if (!selectedAddress || !selectedMapMarker) return;
    props.onAddressChange && props.onAddressChange(selectedAddress);

    setMapMarker({ ...selectedMapMarker });
    setRefreshMapFitFlag((curr) => !curr);
    setSelectedMapMarker(null);
    setSelectedAddress(null);
  };

  const onMarkerCoordinateChange = (newMarker: MapMarker) => {
    setMapMarker(newMarker);
  };

  const onTemporaryMarkerCoordinateChange = (newMarker: MapMarker) => {
    setSelectedMapMarker({
      ...newMarker,
    });
  };

  const mapMessageBox = (
    <MapAddressSelectorMessageBoxComponent
      text={selectedAddress?.name ?? ""}
      onConfirm={acceptSelectedAddress}
    />
  );

  return (
    <MapComponent
      markers={mapMarker ? [mapMarker] : []}
      onMarkerCoordinateChange={onMarkerCoordinateChange}
      temporaryMarkers={selectedMapMarker ? [selectedMapMarker] : []}
      onTemporaryMarkerCoordinateChange={onTemporaryMarkerCoordinateChange}
      onMapLongClick={handleLongMapClick}
      messageBox={mapMessageBox ?? null}
      isMessageBoxVisible={!!selectedAddress}
      autoFit
      refreshFitFlag={refreshMapFitFlag}
    />
  );
};

export default MapAddressSelectorComponent;
