import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import UserRestoreUserData from "./user-restore-user-data";

const getUserFullName = (user: UserRestoreUserData) => {
  return `${user.firstName} ${user.lastName}`;
};

const getContentText = (user: UserRestoreUserData): string => {
  const translations = userTranslationsHelper.getUserRestoreTranslations();

  const fullName = getUserFullName(user);

  return translations.contentMessageTemplateLabel.replace(
    "#{userFullName}",
    fullName
  );
};

const getSuccessNotificationLabel = (user: UserRestoreUserData): string => {
  const translations = userTranslationsHelper.getUserRestoreTranslations();

  const fullName = getUserFullName(user);

  return translations.successNotificationTemplateLabel.replace(
    "#{userFullName}",
    fullName
  );
};

const getFailureNotificationLabel = (user: UserRestoreUserData): string => {
  const translations = userTranslationsHelper.getUserRestoreTranslations();

  const fullName = getUserFullName(user);

  return translations.failureNotificationTemplateLabel.replace(
    "#{userFullName}",
    fullName
  );
};

const userRestoreHelper = {
  getContentText,
  getSuccessNotificationLabel,
  getFailureNotificationLabel,
};

export default userRestoreHelper;
