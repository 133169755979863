import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import taxiRouteSettlementListingUserPermissionDefinition, {
  TaxiRouteSettlementListingUserPermissions,
} from "./taxi-route-settlement-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): TaxiRouteSettlementListingUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    taxiRouteSettlementListingUserPermissionDefinition
  );
};

const taxiRouteSettlementListingUserPermissionsHelper = {
  getPermissions,
};

export default taxiRouteSettlementListingUserPermissionsHelper;
