import { FC, useMemo } from "react";
import { useAppContext } from "../../../../../../../context/app.context";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";
import CargoCompanyContractSourceSelectOption from "./cargo-company-contract-maintenance-policy-select-option";
import SelectOption from "../../../../../../../common/components/form/select/common/option/select-option";
import ContractMaintenancePolicy from "../../../../../../../common/types/contract-maintenance-policy";
import cargoCompanyContractMaintenancePolicySelectOptionFactory from "./cargo-company-contract-maintenance-policy-select-option.factory";

type CargoCompanyContractMaintenancePolicySelectProps = {
  placeholder: string;
  selectedContractMaintenancePolicy: ContractMaintenancePolicy | null;
  onChange: (value: ContractMaintenancePolicy | null) => void;
  onBlur: () => void;
  isError: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  idForTesting?: string;
};

const CargoCompanyContractMaintenancePolicySelectComponent: FC<
  CargoCompanyContractMaintenancePolicySelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const selectOptions: CargoCompanyContractSourceSelectOption[] = useMemo(
    () =>
      cargoCompanyContractMaintenancePolicySelectOptionFactory.createOptions(),
    [selectedAppLanguage]
  );

  const selectedOption: CargoCompanyContractSourceSelectOption | null =
    useMemo(() => {
      return !props.selectedContractMaintenancePolicy
        ? null
        : selectOptions.find(
            (option) => option.value === props.selectedContractMaintenancePolicy
          ) ?? null;
    }, [props.selectedContractMaintenancePolicy, selectOptions]);

  return (
    <SingleSelectComponent
      placeholder={props.placeholder}
      value={selectedOption}
      onChange={(value) => props.onChange(value?.value ?? null)}
      onBlur={props.onBlur}
      options={selectOptions}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      hasError={props.isError}
      idForTesting={props.idForTesting}
      isSearchable
    />
  );
};

export default CargoCompanyContractMaintenancePolicySelectComponent;
