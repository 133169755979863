import DelegationDownloadRequest, {
  DelegationDownloadRequestQueryParams,
} from "./delegation-download.request";

const createQueryParams = (
  request: DelegationDownloadRequest
): DelegationDownloadRequestQueryParams => {
  return {
    separator: request.separator,
    timezone: request.timezone,
    delegations: request.delegationUuids.join(","),
  };
};

const delegationDownloadRequestFactory = {
  createQueryParams,
};

export default delegationDownloadRequestFactory;
