import { FC, useEffect, useMemo, useState } from "react";
import BillingFormData from "./types/billing-form.data";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingApiService from "./api/billings-taxi-raily-api.service";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingsNode from "../../common/types/billings-node";
import billingRecalculateRequestFactory from "./factory/billing-recalculate-request.factory";
import BillingDataResponse from "./api/billing-data.response";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import AdditionalOptionsComponent from "./additional-options/additional-options.component";
import billingsApiService from "./api/billings-api.service";
import BillingGpsResponse from "./api/billing-gps-data.response";
import billingSaveRequestFactory from "./factory/billing-save-request.factory";
import BillingSaveResponse from "./api/billing-save.response";
import RelatedRailyTaxiBillingsComponent from "./related-billings/raily-taxi/related-raily-taxi-billings.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import MapComponent from "../../../../common/components/map/map.component";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import { useNavigate, useParams } from "react-router-dom";
import BillingTaxiRailyAddRouteParams from "../../common/routes/types/billing-taxi-raily-add-route-params";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingDataAddValidationService from "./billing-data-add-validation.service";
import BillingFormDataValidationResult, {
  NodeValidationResult,
} from "./types/billing-form-data-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import settlementRoutesHelper from "../../../settlement/common/routes/settlement-routes.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import BillingsSummaryConfirmationComponent from "./billings-summary-confirmation.component";
import BillingsRouteDetailsFormComponent from "../../common/route-details/billings-route-details-form.component";
import billingsRouteDetailsHelper from "../../common/route-details/common/billings-route-details.helper";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import billingsTaxiRailyHelper from "./billings-taxi-raily.helper";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";

type BillingsTaxiRailyAddProps = {};

const BillingsTaxiRailyAddComponent: FC<BillingsTaxiRailyAddProps> = () => {
  const { orderUuid } = useParams<BillingTaxiRailyAddRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [isBillingSavePending, setIsBillingSavePending] = useState(false);

  const [orderInternalId, setOrderInternalId] = useState("");

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [billingFormData, setBillingFormData] = useState<BillingFormData>();

  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );
  const [isSummaryConfirmationVisible, setIsSummaryConfirmationVisible] =
    useState(false);
  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);
  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);
  const [formValidationResults, setFormValidationResults] =
    useState<BillingFormDataValidationResult>({
      discountValue: formValidationService.defaultValidationResult,
      nodeValidations: [],
    });

  const navigate = useNavigate();

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsTaxiWithRailyAdd.replace("#{orderInternalId}", orderInternalId);

  useDocumentTitle(documentTitle);

  useEffect(() => {
    const breadcrumbs = billingBreadcrumbsHelper.getTaxiWithRailyAddBreadcrumbs(
      {
        orderUuid: orderUuid!,
        orderInternalId,
      }
    );

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, orderInternalId]);

  useEffect(() => {
    setIsBillingDataLoading(true);
    billingApiService
      .fetchBillingData(orderUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  }, []);

  useEffect(() => {
    billingsApiService.fetchGpsData(orderUuid!).then(handleGpsDataResponse);
  }, []);

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
      setShouldRetrieveRouteData(false);
    });
  }, [shouldRetrieveRouteData]);

  const recalculateBilling = (formData: BillingFormData) => {
    const recalculateRequest =
      billingRecalculateRequestFactory.createRecalculateRequest(formData!);

    billingApiService
      .recalculateBilling(orderUuid!, recalculateRequest)
      .then(handleRecalculateResponse);
  };

  const onCopyFromPlannedDistance = () => {
    const newBillingNodes = billingFormData?.billingNodes.map((node) => {
      node.distance = node.plannedDistance;

      return node;
    });

    const newBillingFormData: BillingFormData = {
      ...billingFormData!,
      billingNodes: newBillingNodes!,
    };

    setBillingFormData(newBillingFormData);

    const result = billingsRouteDetailsHelper.validateAllFieldsByType(
      formValidationResults,
      newBillingFormData.billingNodes,
      "distance"
    );

    nodeValidationResults(result.nodeResult!);

    if (result.isAllFormValid) recalculateBilling(newBillingFormData);
  };

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const handleRecalculateResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onRecalculateSuccess(response);
    }
  };

  const handleBillingSaveResponse = (response: BillingSaveResponse) => {
    if (response.status === 201) {
      notificationService.success(translations.successNotificationText);
      navigateToListing();

      return;
    }
    notificationService.error(translations.failureNotificationText);
  };

  const onModalClose = () => {
    setIsSummaryConfirmationVisible(false);
  };

  const openModal = () => {
    setIsSummaryConfirmationVisible(true);
  };

  const navigateToListing = () => {
    navigate(settlementRoutesHelper.getRailyListingOfUnsettledOrdersRoute());
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);

    setBillingData(billingData);
    setBillingFormData(billingData);
    setShouldRetrieveRouteData(true);
    setOrderInternalId(String(response.data.cargo_order.human_id));

    setFormValidationResults((current) => ({
      discountValue: current.discountValue,
      nodeValidations: billingData.billingNodes.map((x) => {
        return {
          distance: formValidationService.defaultValidationResult,
          haltingTime: formValidationService.defaultValidationResult,
          highwayCharge: formValidationService.defaultValidationResult,
          position: x.position,
        };
      }),
    }));
  };

  const validationOnBlur = (position: number, fieldName: string) => {
    type Node = keyof typeof node;

    const node = billingFormData?.billingNodes.find(
      (x) => x.position === position
    );

    const fieldToValidate = node && node[fieldName as Node];

    const results = billingsRouteDetailsHelper.validationOnBlur(
      fieldToValidate,
      formValidationResults,
      position,
      fieldName
    );

    nodeValidationResults(results.nodeResult!);

    if (results.isAllFormValid) recalculateBilling(billingFormData!);
  };

  const nodeValidationResults = (node: NodeValidationResult[]) => {
    setFormValidationResults((curr) => ({
      ...curr,
      nodeValidations: node,
    }));
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const onDiscountBlur = () => {
    const isDiscountValid = validateDiscount();

    if (isDiscountValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const validateDiscount = () => {
    const maximumDiscountValue = billingData?.contractDetails?.discountLimit
      ? billingData?.contractDetails?.discountLimit
      : 0;

    const validationResult = billingDataAddValidationService.validateDiscount(
      billingFormData?.discount ?? 0,
      maximumDiscountValue
    );

    setFormValidationResults((curr) => ({
      ...curr,
      discountValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const onBillingDataSave = () => {
    const areNodesValid =
      billingFormData?.billingNodes
        .map((node) => {
          return billingsRouteDetailsHelper.validateHaltingTime(
            formValidationResults,
            node.haltingTime,
            node.position
          );
        })
        .every((x) => x) ?? false;

    const isDiscountValid = validateDiscount();
    const isFormValid = isDiscountValid && areNodesValid;

    if (!isFormValid) return;

    if (billingFormData) {
      setIsBillingSavePending(true);

      const data =
        billingSaveRequestFactory.createSaveBillingRequest(billingFormData);

      billingApiService
        .saveBillingData(orderUuid!, data)
        .then(handleBillingSaveResponse)
        .finally(() => setIsBillingSavePending(false));
    }
  };

  const onBillingNodeChanged = (node: BillingsNode) => {
    const nodes = billingFormData?.billingNodes.filter(
      (x) => x.position !== node.position
    )!;

    nodes.push(node);

    const sortedArray = nodes.sort((a, b) =>
      a.position < b.position ? -1 : 1
    );

    setBillingFormData((current) => ({
      ...current!,
      billingNodes: sortedArray,
    }));

    setShouldRetrieveRouteData(true);
  };

  const onDiscountChanged = (discountValue: number) => {
    setBillingFormData((current) => ({ ...current!, discount: discountValue }));
  };

  const onInputBlur = () => {
    recalculateBilling(billingFormData!);
  };

  const onRecalculateSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);
    setBillingData(billingData);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const translations =
    billingsTranslationsHelper.getTaxiRailyAddBillingsTranslations();

  const messengerChannelsAvailability =
    billingsTaxiRailyHelper.getMessengerChannelAvailability();

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace(
            "#{orderInternalId}",
            orderInternalId
          )}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={9}>
                    <BillingsRouteDetailsFormComponent
                      billingNodes={billingFormData?.billingNodes}
                      formValidationResults={
                        formValidationResults.nodeValidations
                      }
                      orderId={orderUuid!}
                      onBillingNodeChanged={onBillingNodeChanged}
                      onInputBlur={onInputBlur}
                      onCopyFromPlannedDistance={onCopyFromPlannedDistance}
                      validationOnBlur={validationOnBlur}
                    />
                  </Column>
                  <Column lg={3}>
                    <AdditionalOptionsComponent
                      billingData={billingFormData}
                      onDiscountChanged={onDiscountChanged}
                      onDiscountBlur={onDiscountBlur}
                      formValidationResults={formValidationResults}
                    />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <CardComponent
                  classNames={{ root: "billing_details" }}
                  header={{ title: translations.billingSummary.summaryLabel }}
                >
                  <BillingDetailsComponent billingData={billingData} />
                </CardComponent>
              </Column>
              <Column>
                <ButtonComponent type="primary" onClick={openModal}>
                  {translations.submitLabel}
                </ButtonComponent>
              </Column>
              <Column withPaddings>
                <RelatedRailyTaxiBillingsComponent
                  orderId={billingData?.cargoOrderId}
                />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            <CardComponent classNames={{ root: "h-50", content: "h-100" }}>
              {billingData?.planEntryId && (
                <MessengerComponent
                  channelsAvailability={messengerChannelsAvailability}
                  planEntryUuid={billingData.planEntryId}
                />
              )}
            </CardComponent>
          </Column>
        </Row>
        <BillingsSummaryConfirmationComponent
          isVisible={isSummaryConfirmationVisible}
          onSubmit={onBillingDataSave}
          onClose={onModalClose}
          billingData={billingData!}
          isLoading={isBillingSavePending}
        />
      </div>
    </>
  );
};

export default BillingsTaxiRailyAddComponent;
