import HttpResponse from "../../../../../common/utils/http/http.response";

export enum RouteDetailsResponseDataSolvedOrderRideSequenceOrder {
  STRICT = "STRICT",
}

export type RouteDetailsResponseDataSolvedOrderRideMeta = {
  id: string;
};

export type RouteDetailsResponseDataSolvedOrderRide = {
  seq: RouteDetailsResponseDataNode[];
  sequenceOrder: RouteDetailsResponseDataSolvedOrderRideSequenceOrder;
  meta: RouteDetailsResponseDataSolvedOrderRideMeta;
};

export type RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMetaCargoSubject =
  {
    id: string;
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMetaCargoEnter =
  {
    subjects: RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMetaCargoSubject[];
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMetaCargoExit =
  {
    subjects: RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMetaCargoSubject[];
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMeta =
  {
    id: string;
    cargoEnter: RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMetaCargoEnter;
    cargoExit: RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMetaCargoExit;
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNode = {
  lat: number;
  lon: number;
  time: Date | null;
  haltingTime: number | null;
  meta: RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNodeMeta;
  displayName: string | null;
};

export enum RouteDetailsResponseDataSolvedOrderTransportingOrderRideSequenceOrder {
  STRICT = "STRICT",
  ANY = "ANY",
}

export type RouteDetailsResponseDataSolvedOrderTransportingOrderRideMeta = {
  id: string;
};

export type RouteDetailsResponseDataSolvedOrderTransportingOrderRide = {
  seq: RouteDetailsResponseDataSolvedOrderTransportingOrderRideSeqNode[];
  sequenceOrder: RouteDetailsResponseDataSolvedOrderTransportingOrderRideSequenceOrder;
  meta: RouteDetailsResponseDataSolvedOrderTransportingOrderRideMeta;
};

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMetaCargoSubject =
  {
    id: string;
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMetaCargoEnter =
  {
    subjects: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMetaCargoSubject[];
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMetaCargoExit =
  {
    subjects: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMetaCargoSubject[];
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMeta =
  {
    id: string;
    cargoEnter: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMetaCargoEnter;
    cargoExit: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMetaCargoExit;
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNode =
  {
    lat: number;
    lon: number;
    time: Date | null;
    haltingTime: number | null;
    meta: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNodeMeta;
    displayName: string | null;
  };

export enum RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSequenceOrder {
  STRICT = "STRICT",
  ANY = "ANY",
}

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideMeta =
  {
    id: string;
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRide =
  {
    seq: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSeqNode[];
    sequenceOrder: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideSequenceOrder;
    meta: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRideMeta;
  };

export enum RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderType {
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
  PUBLIC = "PUBLIC",
}

export enum RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderInternalCargoItemSourceType {
  INTERNAL = "INTERNAL",
}

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderInternalCargoItemSource =
  {
    sourceType: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderInternalCargoItemSourceType;
    meta: string | number | boolean | null;
    externalId: string | null;
    passengerId: string;
  };

export enum RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderExternalCargoItemSourceType {
  EXTERNAL = "EXTERNAL",
}

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderExternalCargoItemSource =
  {
    sourceType: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderExternalCargoItemSourceType;
    meta: string | number | boolean | null;
    externalId: string | null;
    firstName: string;
    lastName: string;
    phoneNo: string;
    dispatchName: string | null;
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderDispatcherUser =
  {
    firstName: string;
    mobilePrimaryPrefix: string;
    mobilePrimary: string;
    mobileSecondaryPrefix: string | null;
    mobileSecondary: string | null;
    email: string;
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderDispatcher =
  {
    id: string;
    user: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderDispatcherUser;
  };

export type RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrder = {
  involvedPassengerIds: string;
  ride: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderRide;
  orderType: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderType;
  cargoItemsSourceLookup:
    | RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderInternalCargoItemSource
    | RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderExternalCargoItemSource;
  id: string;
  humanId: number;
  dispatcher: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrderDispatcher;
};

export type RouteDetailsResponseDataSolvedOrderTransportingOrder = {
  involvedPassengersIds: string[];
  ride: RouteDetailsResponseDataSolvedOrderTransportingOrderRide;
  isFinished: boolean;
  id: string;
  cargoOrder: RouteDetailsResponseDataSolvedOrderTransportingOrderCargoOrder;
};

export enum RouteDetailsResponseDataSolvedOrderCargoCompanyContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type RouteDetailsResponseDataSolvedOrderCargoCompany = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: RouteDetailsResponseDataSolvedOrderCargoCompanyContractMaintenancePolicy;
  notes: string | null;
  id: string;
};

export type RouteDetailsResponseDataSolvedOrder = {
  involvedPassengerIds: string[];
  ride: RouteDetailsResponseDataSolvedOrderRide;
  performanceRegion: string;
  id: string;
  transportingOrder: RouteDetailsResponseDataSolvedOrderTransportingOrder;
  cargoCompany: RouteDetailsResponseDataSolvedOrderCargoCompany;
  forceAllowToll: boolean;
};

export type RouteDetailsResponseDataNodeExclusion = {
  nodeIds: string[];
  excludeApproaching: boolean;
  excludeReturning: boolean;
  excludeInside: boolean;
};

export type RouteDetailsResponseDataNodeExclusions = {
  exclusions: RouteDetailsResponseDataNodeExclusion[];
};

export type RouteDetailsResponseDataNodeMetaCargoSubject = {
  id: string;
};

export type RouteDetailsResponseDataNodeMetaCargoEnter = {
  subjects: RouteDetailsResponseDataNodeMetaCargoSubject[];
};

export type RouteDetailsResponseDataNodeMetaCargoExit = {
  subjects: RouteDetailsResponseDataNodeMetaCargoSubject[];
};

export type RouteDetailsResponseDataNodeMeta = {
  id: string;
  cargoEnter: RouteDetailsResponseDataNodeMetaCargoEnter;
  cargoExit: RouteDetailsResponseDataNodeMetaCargoExit;
  estimatedTime: Date;
};

export enum RouteDetailsResponseDataNodeTimingPolicy {
  REQUESTED = "REQUESTED",
  OPTIMIZED = "OPTIMIZED",
}

export type RouteDetailsResponseDataNode = {
  lat: number;
  lon: number;
  time: Date | null;
  haltingTime: number | null;
  meta: RouteDetailsResponseDataNodeMeta;
  displayName: string | null;
  timingPolicy: RouteDetailsResponseDataNodeTimingPolicy;
};

export type RouteDetailsResponseDataCheckoutEvent = {
  id: string;
  nodeId: string;
  achievedAt: Date;
  haltingTime: number | null;
  highwayCharge: number | null;
};

export type RouteDetailsResponseDataTaxi = {
  id: string;
  displayName: string;
};

export enum RouteDetailsResponseDataPassengerItemType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export type RouteDetailsResponseDataPassenger = {
  itemType: RouteDetailsResponseDataPassengerItemType | null;
  firstName: string;
  lastName: string;
  dispatch: string | null;
  phonePrefix: string;
  phoneNo: string;
  cargoItemId: string;
  passengerId: string | null;
};

export type RouteDetailsResponseDataDriver = {
  id: string;
  firstName: string;
  lastName: string;
};

export type RouteDetailsResponseData = {
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  id: string;
  solvedOrders: RouteDetailsResponseDataSolvedOrder[];
  nodeExclusions: RouteDetailsResponseDataNodeExclusions;
  startNode: RouteDetailsResponseDataNode;
  endNode: RouteDetailsResponseDataNode;
  checkoutsCount: number;
  checkoutEvents: RouteDetailsResponseDataCheckoutEvent[];
  taxi: RouteDetailsResponseDataTaxi;
  humanId: number | null;
  passengers: RouteDetailsResponseDataPassenger[];
  driver: RouteDetailsResponseDataDriver;
};

type RouteDetailsResponse = HttpResponse<RouteDetailsResponseData>;

export default RouteDetailsResponse;
