import appConfig from "../../../../../../app.config";
import SignUpPassengerRequest from "./sign-up-passenger.request";
import SignUpPassengerResponse from "./sign-up-passenger.response";

const signUp = async (
  request: SignUpPassengerRequest,
  token: string
): Promise<SignUpPassengerResponse> => {
  const path = `/passengers`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();
  return {
    status: r.status,
    data: responseData,
  };
};
const signUpPassengerApiService = {
  signUp,
};

export default signUpPassengerApiService;
