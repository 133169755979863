import { faDriversLicense, faIcons } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import planningRoutesHelper from "../routes/planning-routes.helper";
import PlanningBreadcrumbsOrderParams from "./types/planning-breadcrumbs-order-params";
import PlanningBreadcrumbsDriverParams from "./types/planning-breadcrumbs-driver-params";
import orderRoutesHelper from "../../../order/common/routes/order-routes.helper";

const getOverviewBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: planningRoutesHelper.getOverviewRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().planningOverview,
    },
  ];
};

const getDriverBreadcrumbs = (
  params: PlanningBreadcrumbsDriverParams
): Breadcrumb[] => {
  return [
    ...getOverviewBreadcrumbs(),
    {
      icon: faIcons,
      linkTo: planningRoutesHelper.getDriverRoute({
        driverUuid: params.driverUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations().planningDriver,
    },
  ];
};

const getOrderBreadcrumbs = (
  params: PlanningBreadcrumbsOrderParams
): Breadcrumb[] => {
  return [
    ...getOverviewBreadcrumbs(),
    {
      icon: faIcons,
      linkTo: `${orderRoutesHelper.getListingOfActiveOrdersRoute({
        defaultSelectedOrderUuid: params.orderUuid,
      })}`,
      text: params.internalOrderId?.toString(),
    },
    {
      icon: faDriversLicense,
      linkTo: planningRoutesHelper.getOrderRoute({
        orderUuid: params.orderUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations().planningOrder,
    },
  ];
};

const planningBreadcrumbsHelper = {
  getOrderBreadcrumbs,
  getDriverBreadcrumbs,
  getOverviewBreadcrumbs,
};

export default planningBreadcrumbsHelper;
