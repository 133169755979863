import OrderAddRouteItem, {
  OrderAddRouteWaypoint,
  OrderAddRouteWaypointGroup,
} from "../types/order-add-route-waypoint";

const checkIsRouteItemAWaypoint = (
  routeItem: OrderAddRouteItem
): routeItem is OrderAddRouteWaypoint => {
  if ((routeItem as OrderAddRouteWaypoint).date !== undefined) {
    return true;
  }

  return false;
};

const checkIsRouteItemWaypointGroup = (
  routeItem: OrderAddRouteItem
): routeItem is OrderAddRouteWaypointGroup => {
  if ((routeItem as OrderAddRouteWaypointGroup).waypoints) {
    return true;
  }

  return false;
};

const orderAddHelper = {
  checkIsRouteItemAWaypoint,
  checkIsRouteItemWaypointGroup,
};

export default orderAddHelper;
