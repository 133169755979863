import DelegationAddAddress from "../../types/delegation-add-address";
import DelegationAddWorker from "../../types/delegation-add-worker";
import {
  DelegationAddWorkerListItem,
  DelegationAddWorkerListItemAddress,
} from "../../types/delegation-add-worker-list";
import DelegationAddWorkerSelectOption from "../../types/delegation-add-worker-select-option";

const createAddress = (
  address: DelegationAddWorkerListItemAddress
): DelegationAddAddress => {
  return {
    displayName: address.displayName,
    latitude: address.lat,
    longitude: address.lon,
    apartmentNumber: address.apartmentNumber,
    houseNumber: address.houseNumber,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createAddresses = (
  addresses: DelegationAddWorkerListItemAddress[]
): DelegationAddAddress[] => {
  const DelegationAddresses: DelegationAddAddress[] =
    addresses.map(createAddress);

  return DelegationAddresses;
};

const createWorker = (
  worker: DelegationAddWorkerListItem
): DelegationAddWorker => {
  return {
    displayName: worker.displayName,
    subText: worker.subText,
    uuid: worker.uuid,
    addresses: createAddresses(worker.addresses),
  };
};

const createWorkerSelectOption = (
  worker: DelegationAddWorker
): DelegationAddWorkerSelectOption => {
  return {
    label: worker.displayName,
    subText: worker.subText,
    value: worker,
  };
};

const createWorkerSelectOptions = (
  data: DelegationAddWorkerListItem[]
): DelegationAddWorkerSelectOption[] => {
  const workers = data.map(createWorker);
  return workers.map(createWorkerSelectOption);
};

const delegationAddWorkerFactory = {
  createWorkerSelectOption,
  createWorkerSelectOptions,
};

export default delegationAddWorkerFactory;
