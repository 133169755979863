import { FC } from "react";
import taxiUnsettledOrderListingTableHelper from "./taxi-unsettled-order-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import TaxiUnsettledOrderListingItem from "../types/taxi-unsettled-order-listing-item";
import TaxiUnsettledOrderListingTableColumn from "../types/taxi-unsettled-order-listing-table-column";
import TaxiUnsettledOrderListingTableRow from "../types/taxi-unsettled-order-listing-table-row";

type TaxiUnsettledOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: TaxiUnsettledOrderListingItem[];
};

const TaxiUnsettledOrderListingTableComponent: FC<
  TaxiUnsettledOrderListingTableProps
> = (props) => {
  const columns: TaxiUnsettledOrderListingTableColumn[] =
    taxiUnsettledOrderListingTableHelper.getColumns();

  const rows: TaxiUnsettledOrderListingTableRow[] =
    taxiUnsettledOrderListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiUnsettledOrderListingTableComponent;
