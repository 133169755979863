import DispatcherAddParams from "../../../../../../common/services/dispatcher/add/dispatcher-add-params";
import DispatcherAddByRailyFormData from "./types/dispatcher-add-by-raily-form-data";

const create = (
  formData: DispatcherAddByRailyFormData
): DispatcherAddParams => {
  return {
    alternativePhoneNumber: formData.alternativePhoneNumber,
    dispatchUuid: formData.dispatchUuid,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    password: formData.password,
    phoneNumber: formData.phoneNumber,
    username: formData.username,
    cargoCompanyUuid: formData.cargoCompanyUuid,
  };
};

const dispatcherAddByRailyParamsFactory = {
  create,
};

export default dispatcherAddByRailyParamsFactory;
