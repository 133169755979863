import railyApiService from "../raily-api.service";
import mileageDictionaryAddRequestFactory from "./add/mileage-dictionary-add-request.factory";
import MileageDictionaryAddRequest from "./add/mileage-dictionary-add.request";
import MileageDictionaryAddResponse from "./add/mileage-dictionary-add.response";
import MileageDictionaryDetailsRequest from "./details/mileage-dictionary-details.request";
import MileageDictionaryDetailsResponse from "./details/mileage-dictionary-details.response";
import mileageDictionaryListRequestFactory from "./list/mileage-dictionary-list-request.factory";
import MileageDictionaryListRequest, {
  MileageDictionaryListRequestQueryParams,
} from "./list/mileage-dictionary-list.request";
import MileageDictionaryListResponse from "./list/mileage-dictionary-list.response";
import mileageDictionaryUpdateRequestFactory from "./update/mileage-dictionary-update-request.factory";
import MileageDictionaryUpdateRequest, {
  MileageDictionaryUpdateRequestBody,
} from "./update/mileage-dictionary-update.request";
import MileageDictionaryUpdateResponse from "./update/mileage-dictionary-update.response";

const mileageDictionaryApiService = () => {
  const add = async (
    request: MileageDictionaryAddRequest,
    abortSignal: AbortSignal
  ): Promise<MileageDictionaryAddResponse> => {
    const url = `/mileage/dictionary/${request.cargoCompanyUuid}`;
    const body = mileageDictionaryAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const list = async (
    request: MileageDictionaryListRequest,
    abortSignal: AbortSignal
  ): Promise<MileageDictionaryListResponse> => {
    const url = `/mileage/dictionary/${request.cargoCompanyUuid}`;
    const queryParams: MileageDictionaryListRequestQueryParams =
      mileageDictionaryListRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  const update = async (
    request: MileageDictionaryUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<MileageDictionaryUpdateResponse> => {
    const url = `/mileage/dictionary/${request.cargoCompanyId}/record/${request.recordId}`;
    const body: MileageDictionaryUpdateRequestBody =
      mileageDictionaryUpdateRequestFactory.createBody(request);

    return railyApiService().put({
      url,
      abortSignal,
      body,
    });
  };

  const getDetails = async (
    request: MileageDictionaryDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<MileageDictionaryDetailsResponse> => {
    const url = `/mileage/dictionary/${request.cargoCompanyUuid}/record/${request.recordUuid}`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  return {
    add,
    list,
    update,
    getDetails,
  };
};

export default mileageDictionaryApiService;
