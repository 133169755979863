import HttpResponse from "../../../../utils/http/http.response";

type MileageUpdateSuccessResponse = HttpResponse<string>;

export enum MileageUpdateNotAcceptableResponseErrorType {
  MILEAGE_HAS_DELEGATION = "MILEAGE_HAS_DELEGATION",
  NO_VALID_MILEAGE_CONTRACT_FOUND = "NO_VALID_MILEAGE_CONTRACT_FOUND",
}

export type MileageUpdateNotAcceptableResponseMileageHasDelegationError = {
  errorType: MileageUpdateNotAcceptableResponseErrorType.MILEAGE_HAS_DELEGATION;
};

export type MileageUpdateNotAcceptableResponseNoValidMileageContractFoundError =
  {
    errorType: MileageUpdateNotAcceptableResponseErrorType.NO_VALID_MILEAGE_CONTRACT_FOUND;
  };

export type MileageUpdateNotAcceptableResponseError =
  | MileageUpdateNotAcceptableResponseMileageHasDelegationError
  | MileageUpdateNotAcceptableResponseNoValidMileageContractFoundError;

export type MileageUpdateNotAcceptableResponse =
  HttpResponse<MileageUpdateNotAcceptableResponseError>;

type MileageUpdateResponse =
  | MileageUpdateSuccessResponse
  | MileageUpdateNotAcceptableResponse;

export default MileageUpdateResponse;
