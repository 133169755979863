import { DealerListItem } from "../../../../../common/services/dealer/list/dealer-list";
import UserStatus from "../../../../../common/types/user-status";
import phoneNumberService from "../../../../../common/utils/phone-number/phone-number.service";
import DealerListingItem from "./types/dealer-listing-item";

const createMobilePhones = (
  dealerListItem: DealerListItem
): DealerListingItem["phoneNumbers"] => {
  const phoneNumbers: DealerListingItem["phoneNumbers"] = [
    phoneNumberService.getLabel(dealerListItem.phoneNumber),
  ];

  if (dealerListItem.alternativePhoneNumber) {
    phoneNumbers.push(
      phoneNumberService.getLabel(dealerListItem.alternativePhoneNumber)
    );
  }

  return phoneNumbers;
};

const createListingItem = (
  dealerListItem: DealerListItem
): DealerListingItem => {
  const phoneNumbers = createMobilePhones(dealerListItem);

  return {
    uuid: dealerListItem.uuid,
    firstName: dealerListItem.firstName,
    lastName: dealerListItem.lastName,
    userName: dealerListItem.username,
    emailAddress: dealerListItem.email,
    phoneNumbers: phoneNumbers,
    taxiCorporationName: dealerListItem.taxiCorporationName,
    status: !dealerListItem.removalDate
      ? UserStatus.ACTIVE
      : UserStatus.DELETED,
  };
};

const createListingItems = (
  responseDataItems: DealerListItem[]
): DealerListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const dealerListingItemFactory = {
  createListingItems,
};

export default dealerListingItemFactory;
