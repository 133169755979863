import "./app.scss";
import { useNavigate, Route, Routes, useLocation } from "react-router-dom";
import { useAppContext } from "./context/app.context";
import { FC, useEffect, useMemo } from "react";
import PageLoaderComponent from "./common/components/page/loader/page-loader.component";
import authRoutes from "./modules/auth/common/routes/auth-routes";
import authRoutesHelper from "./modules/auth/common/routes/auth-routes.helper";
import authRoutesUrls from "./modules/auth/common/routes/auth-routes-urls";
import useAuthSessionFlow from "./modules/auth/use-auth-session-flow";
import AppHeaderComponent from "./modules/app-header/app-header.component";
import classNames from "classnames";
import AppAsideComponent from "./modules/app-aside/app-aside.component";
import RouteNotFoundComponent from "./routes/not-found/route-not-found.component";
import RouteNoAccessComponent from "./routes/no-access/route-no-access.component";
import appRoutesHelper from "./routes/app-routes.helper";
import orderRoutesDefinition from "./modules/order/common/routes/order-routes";
import orderRoutesUrls from "./modules/order/common/routes/order-routes-urls";
import userPermissionsService from "./common/utils/user/permissions/user-permissions.service";
import UserRole from "./common/types/user-role";
import orderRoutesHelper from "./modules/order/common/routes/order-routes.helper";

const App: FC = () => {
  const { isUserLoggedIn, isTokenRenewPending, isAsidePanelPinned, user } =
    useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  useAuthSessionFlow();

  useEffect(() => {
    const isCurrentRouteOneOfAuthRoutes = Object.values(
      authRoutesUrls
    ).includes(location.pathname);

    if (
      !isUserLoggedIn &&
      !isTokenRenewPending &&
      !isCurrentRouteOneOfAuthRoutes
    ) {
      const signInRoute = authRoutesHelper.getSignInRoute();
      navigate(signInRoute);
      return;
    }

    if (
      isUserLoggedIn &&
      !isTokenRenewPending &&
      isCurrentRouteOneOfAuthRoutes
    ) {
      const activeOrdersRouteWhitelist = orderRoutesDefinition.find(
        (route) => route.path === orderRoutesUrls.listingOfActiveOrders
      )?.userRolesWhitelist!;

      const hasPermissionToActiveOrders =
        userPermissionsService.solvePermission(
          user?.roles!,
          activeOrdersRouteWhitelist
        );

      if (
        hasPermissionToActiveOrders &&
        !user?.roles.includes(UserRole.ADMIN)
      ) {
        navigate(orderRoutesHelper.getListingOfActiveOrdersRoute());
        return;
      }

      navigate(appRoutesHelper.getMainRoute());
      return;
    }
  }, [isUserLoggedIn, isTokenRenewPending]);

  const UnauthorizedAppContent = useMemo(() => {
    return (
      <Routes>
        {authRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          );
        })}
      </Routes>
    );
  }, []);

  const appRoutes = useMemo(() => {
    if (!user?.roles) {
      return [];
    }
    return appRoutesHelper.getRouteItems(user.roles);
  }, [user?.roles]);

  const AuthorizedAppContent = useMemo(() => {
    return (
      <>
        <AppAsideComponent />
        <AppHeaderComponent />
        <div
          className={classNames(
            "page_content",
            isAsidePanelPinned && "app_aside_pinned"
          )}
        >
          <Routes>
            {appRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    route.hasPermission ? (
                      route.component
                    ) : (
                      <RouteNoAccessComponent />
                    )
                  }
                />
              );
            })}
            <Route path="*" element={<RouteNotFoundComponent />} />
          </Routes>
        </div>
      </>
    );
  }, [isAsidePanelPinned, appRoutes]);

  if (!isUserLoggedIn && isTokenRenewPending) {
    return (
      <div className="app loading">
        <PageLoaderComponent />
      </div>
    );
  }

  return (
    <div className="app">
      {isUserLoggedIn && user ? AuthorizedAppContent : UnauthorizedAppContent}
    </div>
  );
};

export default App;
