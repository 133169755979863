import CargoCompanyDetailsRequest from "../../../../api/raily/cargo-company/cargo-company/details/cargo-company-details.request";
import CargoCompanyDetailsParams from "./cargo-company-details-params";

const create = (
  params: CargoCompanyDetailsParams
): CargoCompanyDetailsRequest => {
  return {
    cargoCompanyId: params.cargoCompanyUuid,
  };
};

const cargoCompanyDetailsRequestFactory = {
  create,
};

export default cargoCompanyDetailsRequestFactory;
