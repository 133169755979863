import CargoCompanyPassengerListRequest from "../../../../api/raily/cargo-company/passenger/list/cargo-company-passenger-list.request";
import CargoCompanyPassengerListLoadParams from "./cargo-company-passenger-list-load-params";

const createPassengerListRequest = (
  params: CargoCompanyPassengerListLoadParams
): CargoCompanyPassengerListRequest => {
  return {
    companyUuid: params.companyUuid,
    searchQuery: params.searchQuery,
  };
};

const cargoCompanyPassengerListRequestFactory = {
  createPassengerListRequest,
};

export default cargoCompanyPassengerListRequestFactory;
