const getDateTimeInputValue = (date: Date | null): string => {
  if (!date) return "";

  return `${date.toLocaleDateString(undefined)} ${date.toLocaleTimeString(
    undefined
  )}`;
};

const dateTimeInputHelper = {
  getDateTimeInputValue,
};

export default dateTimeInputHelper;
