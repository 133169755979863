import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DriverContractAddViewMode from "./common/types/driver-contract-add-view-mode";
import driverContractAddUserPermissionsHelper from "./common/user-permissions/driver-contract-add-user-permission.helper";
import DriverContractAddByRailyComponent from "./by-raily/driver-contract-add-by-raily.component";
import DriverContractAddByTaxiComponent from "./by-taxi/driver-contract-add-by-taxi.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import { useAppContext } from "../../../../../context/app.context";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../../common/breadcrumbs/user-breadcrumbs.helper";
import UserDriverContractAddRouteParams from "../../../common/routes/types/user-driver-contract-add-route-params";
import { useParams } from "react-router-dom";
import useDriverDetails from "../../../../../common/services/driver/details/use-driver-details";
import useAbort from "../../../../../common/hooks/use-abort";

type DriverContractAddProps = {};

const DriverContractAddComponent: FC<DriverContractAddProps> = () => {
  const { driverUuid } = useParams<UserDriverContractAddRouteParams>();
  const { user, selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userDriverContractAdd);

  const driverDetails = useDriverDetails();
  const driverDetailsAbort = useAbort();

  useEffect(() => {
    if (!driverUuid) {
      return;
    }

    driverDetails.load({ driverUuid }, driverDetailsAbort.signal);
  }, [driverUuid]);

  const driverName = useMemo(() => {
    if (!driverDetails.data) {
      return "";
    }

    return `${driverDetails.data.firstName} ${driverDetails.data.lastName}`;
  }, [driverDetails.data]);

  useEffect(() => {
    if (!driverUuid || !driverDetails.data) {
      return;
    }

    const breadcrumbs = userBreadcrumbsHelper.getDriverContractAddBreadcrumbs({
      driverName,
      driverUuid,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, driverName]);

  const userPermissions = useMemo(
    () => driverContractAddUserPermissionsHelper.getPermissions(user?.roles!),
    []
  );

  const translations = useMemo(
    () => userTranslationsHelper.getDriverContractAddTranslations(),
    [selectedAppLanguage]
  );

  const [selectedViewMode, setSelectedViewMode] =
    useState<DriverContractAddViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DriverContractAddViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DriverContractAddViewMode.TAXI)}
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: DriverContractAddViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: DriverContractAddViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToTaxiButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: DriverContractAddViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: DriverContractAddViewMode.RAILY,
        component: (
          <DriverContractAddByRailyComponent
            changeViewButtons={getViewChangeButtons(
              DriverContractAddViewMode.RAILY
            )}
            driverName={driverName}
            driverUuid={driverUuid}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: DriverContractAddViewMode.TAXI,
        component: (
          <DriverContractAddByTaxiComponent
            changeViewButtons={getViewChangeButtons(
              DriverContractAddViewMode.TAXI
            )}
            driverName={driverName}
            driverUuid={driverUuid}
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons, driverUuid, driverName]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, [viewOptions]);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }

    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default DriverContractAddComponent;
