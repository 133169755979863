import UserRole from "../../../../common/types/user-role";
import userPermissionsService from "../../../../common/utils/user/permissions/user-permissions.service";
import billingsUserPermissionDefinition, {
  BillingsUserPermissions,
} from "./billings-user-permission";

const getPermissions = (userRoles: UserRole[]): BillingsUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    billingsUserPermissionDefinition
  );
};

const getSelectSearchablePermissions = (
  userRoles: UserRole[]
): BillingsUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    billingsUserPermissionDefinition
  );
};

const billingsUserPermissionsHelper = {
  getPermissions,
  getSelectSearchablePermissions,
};

export default billingsUserPermissionsHelper;
