import MileageDictionaryUpdateRequest, {
  MileageDictionaryUpdateRequestBody,
} from "./mileage-dictionary-update.request";

const createBody = (
  request: MileageDictionaryUpdateRequest
): MileageDictionaryUpdateRequestBody => {
  return {
    active: request.active,
    name: request.name,
  };
};

const mileageDictionaryUpdateRequestFactory = {
  createBody,
};

export default mileageDictionaryUpdateRequestFactory;
