import SignUpTaxiOfficerFormData from "./types/sign-up-taxi-officer-form-data";
import SignUpTaxiOfficerRequest from "./api/sign-up-taxi-officer.request";
import SignUpTaxiOfficerTokenPayload from "../../common/types/sign-up-taxi-officer-token-payload";
import SignUpTaxiOfficerDisabledFormFields from "./types/sign-up-taxi-officer-disabled-form-fields";

const createSignUpRequest = (
  userData: SignUpTaxiOfficerFormData,
  taxiCorporationId: string
): SignUpTaxiOfficerRequest => ({
  first_name: userData.firstName.trim(),
  last_name: userData.lastName.trim(),
  mobile_primary: userData.mobile.number!,
  mobile_primary_prefix: userData.mobile.dialingCode!,
  mobile_secondary: userData.alternativeMobile.number,
  mobile_secondary_prefix: userData.alternativeMobile.dialingCode,
  email: userData.email.trim(),
  username: userData.username.trim(),
  password: userData.password,
  taxi_officer: {
    taxi_corporation_id: taxiCorporationId,
  },
});

const createDisabledFormFieldsConfig = (
  tokenPayload: SignUpTaxiOfficerTokenPayload
): SignUpTaxiOfficerDisabledFormFields => {
  return {
    email: !!tokenPayload.user_data.email,
    firstName: !!tokenPayload.user_data.first_name,
    lastName: !!tokenPayload.user_data.last_name,
    mobile:
      !!tokenPayload.user_data.mobile_primary &&
      !!tokenPayload.user_data.mobile_primary_prefix,
    alternativeMobile:
      !!tokenPayload.user_data.mobile_secondary &&
      !!tokenPayload.user_data.mobile_secondary_prefix,
    username: !!tokenPayload.user_data.username,
  };
};

const createFormData = (
  tokenPayload: SignUpTaxiOfficerTokenPayload
): SignUpTaxiOfficerFormData => {
  return {
    email: tokenPayload.user_data.email ?? "",
    password: "",
    passwordRepeat: "",
    username: tokenPayload.user_data.username ?? "",
    firstName: tokenPayload.user_data.first_name ?? "",
    lastName: tokenPayload.user_data.last_name ?? "",
    mobile: {
      dialingCode: tokenPayload.user_data.mobile_primary_prefix ?? "",
      number: tokenPayload.user_data.mobile_primary ?? "",
    },
    alternativeMobile: {
      dialingCode: tokenPayload.user_data.mobile_secondary_prefix ?? "",
      number: tokenPayload.user_data.mobile_secondary ?? "",
    },
  };
};

const signUpTaxiOfficerFactory = {
  createSignUpRequest,
  createFormData,
  createDisabledFormFieldsConfig,
};

export default signUpTaxiOfficerFactory;
