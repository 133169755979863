import DriverEditUpdateRequest, {
  DriverEditAddressUpdateRequest,
} from "../../common/api/driver-edit-update.request";
import DriverEditAccountFormData from "../../common/types/driver-edit-account-form-data";
import DriverEditAddress from "../../common/types/driver-edit-address";
import DriverEditCompanyFormData from "../../common/types/driver-edit-company-form-data";
import DriverEditVehicleFormData from "../../common/types/driver-edit-vehicle-form-data";
import DriverEditUserFormData from "../../common/types/driver-edit-user-form-data";
import UserLanguageSelectOption from "../../../../../../common/types/user-language-select-option";
import UserLanguage from "../../../../../../common/types/user-language";
import dateService from "../../../../../../common/utils/date/date.service";

const createEditDriverLanguages = (
  languagesSelectOptions: UserLanguageSelectOption[]
): UserLanguage[] => {
  return languagesSelectOptions.map((languagesSelectOption) => {
    return languagesSelectOption.value;
  });
};

const createEditDriverAddresses = (
  addresses: DriverEditAddress[]
): DriverEditAddressUpdateRequest[] => {
  return addresses.map((address) => {
    return {
      country: address.country,
      zip_code: address.zipCode,
      town: address.town,
      street: address.street,
      house_no: address.houseNumber,
      apartment: address.apartmentNumber,
      description: address.description,
      lat: address.latitude,
      lon: address.longitude,
      type: address.type,
    };
  });
};

const createEditDriverRequest = (
  accountFormData: DriverEditAccountFormData,
  userFormData: DriverEditUserFormData,
  companyFormData: DriverEditCompanyFormData,
  vehicleFormData: DriverEditVehicleFormData
): DriverEditUpdateRequest => {
  return {
    user: {
      first_name: userFormData.firstName,
      last_name: userFormData.lastName,
      mobile_primary_prefix: userFormData.mobile.dialingCode ?? "",
      mobile_primary: userFormData.mobile.number ?? "",
      mobile_secondary_prefix: userFormData.alternativeMobile.dialingCode,
      mobile_secondary: userFormData.alternativeMobile.number,
      email: accountFormData.email,
    },
    driver: {
      birth_date: dateService.format(userFormData.birthDate!, "yyyy-mm-dd"),
      birth_place: userFormData.birthPlace,
      id_number: userFormData.personalIdNumber,
      citizenship: userFormData.citizenship?.value!,
      languages: createEditDriverLanguages(userFormData.languages),
      experience: userFormData.yearsOfExperience!,
      car_ownership: vehicleFormData.ownership?.value!,
      car_make: vehicleFormData.make,
      car_model: vehicleFormData.model,
      car_reg_no: vehicleFormData.registrationNumber,
      car_prod_year: vehicleFormData.productionYear!,
      car_seats_no: vehicleFormData.numberOfSeats!,
    },
    associations: [],
    addresses: createEditDriverAddresses(userFormData.addresses),
  };
};

const driverEditByTaxiFactory = {
  createEditDriverRequest,
};

export default driverEditByTaxiFactory;
