import phoneNumberInputHelper from "../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";

const getDefaultFormData = () => {
  return {
    email: "",
    firstName: "",
    lastName: "",
    mobile: phoneNumberInputHelper.getEmptyValue(),
    alternativeMobile: phoneNumberInputHelper.getEmptyValue(),
    addresses: [],
    dispatches: [],
  };
};

const getDefaultFormValidationResults = () => {
  return {
    email: formValidationService.defaultValidationResult,
    firstName: formValidationService.defaultValidationResult,
    lastName: formValidationService.defaultValidationResult,
    mobile: formValidationService.defaultValidationResult,
    alternativeMobile: formValidationService.defaultValidationResult,
    addresses: formValidationService.defaultValidationResult,
    dispatches: formValidationService.defaultValidationResult,
  };
};

const getHeadingText = (passengerName: string) => {
  const translations = userTranslationsHelper.getPassengerEditTranslations();

  if (!passengerName) {
    return translations.header.headingText;
  }
  return translations.header.headingTextWithName.replace(
    "#{passengerName}",
    passengerName
  );
};

const passengerEditHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
  getHeadingText,
};

export default passengerEditHelper;
