import HttpResponse from "../../../../../common/utils/http/http.response";

export type OrderJoinPreviewResponsePassenger = {
  id: string;
  first_name: string;
  last_name: string;
  mobile_primary_prefix: string;
  mobile_primary: string;
  mobile_secondary_prefix: string | null;
  mobile_secondary: string | null;
};

export type OrderJoinPreviewResponseDriver = {
  id: string;
  first_name: string;
  last_name: string;
  mobile_primary_prefix: string;
  mobile_primary: string;
  mobile_secondary_prefix: string | null;
  mobile_secondary: string | null;
};

export type OrderJoinPreviewResponseRideWaypoint = {
  lat: number;
  lon: number;
  time: string | null;
  halting_time: number;
  display_name: string;
  meta: {
    id: string;
    cargo_enter: {
      subjects: { id: string }[];
    };
    cargo_exit: {
      subjects: { id: string }[];
    };
  };
};

export type OrderJoinPreviewResponseDataRideSeqItem =
  | OrderJoinPreviewResponseRideWaypoint
  | OrderJoinOrderDetailsResponseDataCargoOrderRide;

export type OrderJoinOrderDetailsResponseDataCargoOrderRide = {
  seq: OrderJoinPreviewResponseDataRideSeqItem[];
  sequence_order: "STRICT" | "ANY";
};

export type OrderJoinOrderDetailsResponseSolvedOrderRideSeqItem = {
  lat: number;
  lon: number;
  time: string | null;
  halting_time: number;
  display_name: string;
  meta: {
    id: string;
    cargo_enter: {
      subjects: { id: string }[];
    };
    cargo_exit: {
      subjects: { id: string }[];
    };
    estimated_time: string;
  };
};

export type OrderJoinOrderDetailsResponseSolvedOrderRide = {
  seq: OrderJoinOrderDetailsResponseSolvedOrderRideSeqItem[];
  sequence_order: "STRICT";
};
export type OrderJoinOrderDetailsResponseSolvedOrderCheckoutEvent = {
  node_id: string;
  achieved_at: string;
};

export type OrderJoinOrderDetailsResponseSolvedOrder = {
  id: string;
  ride: OrderJoinOrderDetailsResponseSolvedOrderRide;
  checkout_events:
    | OrderJoinOrderDetailsResponseSolvedOrderCheckoutEvent[]
    | null;
};

export type OrderJoinOrderDetailsResponseTransportingOrderCreatePlanEntry = {
  driver: {
    id: string;
    first_name: string;
    last_name: string;
    mobile_primary_prefix: string;
    mobile_primary: string;
    mobile_secondary_prefix: string | null;
    mobile_secondary: string | null;
  };
};

export type OrderJoinOrderDetailsResponseTransportingOrder = {
  id: string;
  solved_order: OrderJoinOrderDetailsResponseSolvedOrder | null;
  create_plan_entry_entries: OrderJoinOrderDetailsResponseTransportingOrderCreatePlanEntry[];
};

export type OrderJoinOrderDetailsResponseCargoOrder = {
  id: string;
  human_id: number;
  approving_dispatcher_id: string | null;
  approving_operator_id: string | null;
  approving_dealer_id: string | null;
  external_id: string | null;
  is_cancel_requested: boolean;
  is_cancelled: boolean;
  is_finished: boolean;
  is_published: boolean | null;
  ride: OrderJoinOrderDetailsResponseDataCargoOrderRide;
  transporting_orders: OrderJoinOrderDetailsResponseTransportingOrder[];
  target_taxi_id: string | null;
  dispatcher: {
    id: string;
  };
  cargo_items_source_list: OrderJoinCargoItemsSourceElement[];
};

export type OrderJoinOrderDetailsResponseData = {
  id: string;
  passengers: OrderJoinPreviewResponsePassenger[];
  drivers: OrderJoinPreviewResponseDriver[];
  cargo_order: OrderJoinOrderDetailsResponseCargoOrder;
  node_exclusion: OrderJoinOrderDetailsResponseNodeExclusion;
};

export type OrderJoinOrderDetailsResponseNodeExclusion = {
  exclude_approaching: boolean;
  exclude_inside: boolean;
  exclude_returning: boolean;
};

export enum OrderJoinSourceType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export interface OrderJoinCargoItemSourceData {
  source_type: OrderJoinSourceType;
  meta: string;
}

export interface OrderJoinInternalCargoItemSourceData
  extends OrderJoinCargoItemSourceData {
  passenger_id: string;
  external_id: string | null;
}

export interface OrderJoinExternalCargoItemSourceData
  extends OrderJoinCargoItemSourceData {
  first_name: string;
  last_name: string;
  phone_no: string;
  external_id: string | null;
  dispatch_name: string | null;
}

export type OrderJoinCargoItemsSourceElement = {
  cargo_subject_id: string;
  cargo_item_source:
    | OrderJoinInternalCargoItemSourceData
    | OrderJoinExternalCargoItemSourceData;
};

type OrderJoinOrderPreviewResponse =
  HttpResponse<OrderJoinOrderDetailsResponseData>;

export default OrderJoinOrderPreviewResponse;
