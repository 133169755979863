import { FC, useEffect } from "react";
import CardComponent from "../../../common/components/card/card.component";
import Column from "../../../common/components/grid/column";
import Row from "../../../common/components/grid/row";
import HeadingComponent from "../../../common/components/heading/heading.component";
import planningTranslationsHelper from "../../../languages/planning-translations.helper";
import { useAppContext } from "../../../context/app.context";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import planningBreadcrumbsHelper from "../common/breadcrumbs/planning-breadcrumbs.helper";

type PlanningOverviewProps = {};

const PlanningOverviewComponent: FC<PlanningOverviewProps> = () => {
  const translations = planningTranslationsHelper.getOverviewTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.planningOverview);

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs = planningBreadcrumbsHelper.getOverviewBreadcrumbs();

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column lg={8}>
          <CardComponent
            header={{ title: translations.activeOrderListing.headingLabel }}
          >
            Lista aktywnych zleceń
          </CardComponent>
        </Column>
        <Column lg={4}>
          <CardComponent
            header={{ title: translations.driverPlanListing.headingLabel }}
          >
            Lista kierowców
          </CardComponent>
        </Column>
      </Row>
    </>
  );
};

export default PlanningOverviewComponent;
