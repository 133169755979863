enum PlanningOrderDriverPlanListingSortKey {
  DRIVER_NAME_ASC = "DRIVER_NAME_ASC",
  DRIVER_NAME_DESC = "DRIVER_NAME_DESC",
  ROUTE_START_DATE_ASC = "ROUTE_START_DATE_ASC",
  ROUTE_START_DATE_DESC = "ROUTE_START_DATE_DESC",
  ROUTE_END_DATE_ASC = "ROUTE_END_DATE_ASC",
  ROUTE_END_DATE_DESC = "ROUTE_END_DATE_DESC",
}

export default PlanningOrderDriverPlanListingSortKey;
