import dateformat from "dateformat";
import dayjs from "dayjs";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const format = (date: Date, mask: string) => {
  return dateformat(date, mask);
};

const createDate = (dateString: string): Date => {
  return dayjs(dateString, "DD.MM.YYYY", true).toDate();
};

const checkIsValidDate = (date: any): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

const formatDate = (date: Date) => format(date, "dd.mm.yyyy");

const formatDateTime = (date: Date) => format(date, "dd.mm.yyyy HH:MM");

const formatTime = (date: Date) => format(date, "HH:MM");

const endOfDate = (date: Date): Date => dayjs(date).endOf("day").toDate();

const isValidDateTime = (value: string): boolean => {
  return (
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(value) ||
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{2}:\d{2}/.test(value)
  );
};

const dateService = {
  format,
  createDate,
  checkIsValidDate,
  formatDate,
  formatDateTime,
  formatTime,
  isValidDateTime,
  endOfDate,
};

export default dateService;
