import { round } from "lodash";
import dateService from "../../../../common/utils/date/date.service";
import MileageDetailsRouteConnection from "../common/types/mileage-details-route-connection";
import MileageDetailsRouteConnectionLineType from "../common/types/mileage-details-route-connection-line-type";
import MileageDetailsRouteWaypoint from "../common/types/mileage-details-route-waypoint";

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return `----`;
  }

  return dateService.formatDateTime(date);
};

const getDateTitle = (date: Date | null): string => {
  if (!date) {
    return ``;
  }

  return dateService.formatDateTime(date);
};

const getCompletionDateLabel = (completionDate: Date | null): string => {
  return getDateLabel(completionDate);
};

const getCompletionDateTitle = (completionDate: Date | null): string => {
  return getDateTitle(completionDate);
};

const getDistanceLabel = (distance: number | null): string => {
  if (distance === null) {
    return `----`;
  }

  const distanceAsKm = round(distance / 1000);

  return `${distanceAsKm} KM`;
};

const generateWaypointDotClassname = (
  waypoint: MileageDetailsRouteWaypoint,
  mileageUuid: string
) => {
  return `dot-mileage-${mileageUuid}-coordinates-${waypoint.place.latitude};${waypoint.place.longitude}-stageNo-${waypoint.stageNo}`;
};

const getDefinitionOfLineConnectionsBetweenWaypointsInList = (
  waypoints: MileageDetailsRouteWaypoint[],
  mileageUuid: string
): MileageDetailsRouteConnection[] => {
  let connectionLineDefinitions: MileageDetailsRouteConnection[] = [];

  const flattenedWaypoints = waypoints.flat();

  flattenedWaypoints.forEach((currentWaypoint, index) => {
    const nextWaypoint = flattenedWaypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const areBothWaypointsFromTheSameStage =
      currentWaypoint.stageNo === nextWaypoint.stageNo;

    const lineType = areBothWaypointsFromTheSameStage
      ? MileageDetailsRouteConnectionLineType.DOTTED
      : MileageDetailsRouteConnectionLineType.SOLID;

    const newConnectionLineDefinition: MileageDetailsRouteConnection = {
      itemFromClassname: generateWaypointDotClassname(
        currentWaypoint,
        mileageUuid
      ),
      itemToClassname: generateWaypointDotClassname(nextWaypoint, mileageUuid),
      lineType,
    };

    connectionLineDefinitions = [
      ...connectionLineDefinitions,
      newConnectionLineDefinition,
    ];
  });

  return connectionLineDefinitions;
};

const MileageDetailsRouteHelper = {
  getCompletionDateLabel,
  getCompletionDateTitle,
  getDistanceLabel,
  getDefinitionOfLineConnectionsBetweenWaypointsInList,
  generateWaypointDotClassname,
};

export default MileageDetailsRouteHelper;
