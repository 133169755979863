import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import mileageDictionaryListingService from "./mileage-dictionary-listing.service";
import MileageDictionaryListingRouteQueryParams from "./table/types/mileage-dictionary-listing-route-params";

const useMileageDictionaryFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<MileageDictionaryListingRouteQueryParams>();

  const getPage = (): number | undefined => {
    return mileageDictionaryListingService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return mileageDictionaryListingService.getPageSize(routeQueryParams);
  };

  const saveParams = (page: number, pageSize: number) => {
    const routeQueryParams =
      mileageDictionaryListingService.createRouteQueryParams(page, pageSize);

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getPage,
    getPageSize,
    saveParams,
  };
};

export default useMileageDictionaryFiltersDao;
