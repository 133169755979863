import DispatcherListResponse, {
  DispatcherListResponseItem,
  DispatcherListCargoContractMaintenancePolicy,
  DispatcherListResponseItemCargoCompany,
  DispatcherListResponseItemDispatcher,
} from "../../../api/raily/dispatcher/list/dispatcher-list.response";
import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import DispatcherList, { DispatcherListItem } from "./dispatcher-list";

const createAlternativePhoneNumber = (
  responseDataItem: DispatcherListResponseItem
): DispatcherListItem["alternativePhoneNumber"] => {
  if (
    !responseDataItem.mobileSecondary ||
    !responseDataItem.mobileSecondaryPrefix
  ) {
    return null;
  }

  return {
    dialingCode: responseDataItem.mobileSecondaryPrefix,
    number: responseDataItem.mobileSecondary,
  };
};

const mapContractMaintenancePolicy = (
  contractMaintenancePolicy: DispatcherListCargoContractMaintenancePolicy
): ContractMaintenancePolicy => {
  switch (contractMaintenancePolicy) {
    case DispatcherListCargoContractMaintenancePolicy.AUCTION:
      return ContractMaintenancePolicy.AUCTION;
    case DispatcherListCargoContractMaintenancePolicy.BASIC:
      return ContractMaintenancePolicy.BASIC;
  }
};

const createCargoCompany = (
  cargoCompany: DispatcherListResponseItemCargoCompany
): DispatcherListItem["cargoCompany"] => {
  return {
    uuid: cargoCompany.id,
    address: cargoCompany.headquartersAddressString,
    companyId: cargoCompany.taxId,
    contractMaintenancePolicy: mapContractMaintenancePolicy(
      cargoCompany.contractMaintenancePolicy
    ),
    displayName: cargoCompany.displayName,
    name: cargoCompany.companyName,
    nationalCourtRegister: cargoCompany.krsNo,
    notes: cargoCompany.notes,
    taxId: cargoCompany.taxId,
  };
};

const createDispatch = (
  dispatch: DispatcherListResponseItemDispatcher
): DispatcherListItem["dispatch"] => {
  return {
    uuid: dispatch.id,
    address: dispatch.addressString,
    creationDate: dispatch.createdAt,
    displayName: dispatch.displayName,
    name: dispatch.dispatchName,
  };
};

const createDataItem = (
  responseDataItem: DispatcherListResponseItem
): DispatcherListItem => {
  return {
    uuid: responseDataItem.id,
    creationDate: responseDataItem.createdAt,
    removalDate: responseDataItem.removedAt,
    alternativePhoneNumber: createAlternativePhoneNumber(responseDataItem),
    cargoCompany: createCargoCompany(
      responseDataItem.dispatcher.dispatch!.cargoCompany
    ),
    dispatch: createDispatch(responseDataItem.dispatcher.dispatch!),
    email: responseDataItem.email,
    firstName: responseDataItem.firstName,
    lastName: responseDataItem.lastName,
    username: responseDataItem.username,
    phoneNumber: {
      dialingCode: responseDataItem.mobilePrimaryPrefix,
      number: responseDataItem.mobilePrimary,
    },
  };
};

const createData = (
  responseData: DispatcherListResponseItem[]
): DispatcherListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: DispatcherListResponse): DispatcherList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const dispatcherListFactory = {
  create,
};

export default dispatcherListFactory;
