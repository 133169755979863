import { FC, useEffect, useState } from "react";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import OrderDetailsSelectTaxiContractsComponent from "../common/select/order-details-select-taxi-contracts.component";
import OrderDetailsForwardingFormValidationResults from "../common/validation/types/order-details-forwarding-form-validation-results";
import SelectOptionTaxiCorporationSelectOption from "./types/order-details-forwarding-available-consumer-select-option";
import OrderDetailsSelectAvailableConsumerComponent from "./order-details-select-available-consumer.component";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";

type OrderDetailsForwardingFormComponentProps = {
  forwardingId: string | undefined;
  formValidationResults: OrderDetailsForwardingFormValidationResults;
  selectedTaxiCorporationId: string | undefined;
  producerTaxiId: string | null;
  transportingOrderId: string | null;
  handleTaxiContractChange: (value: SelectOption | null) => void;
  getForwardings: () => void;
  setSelectedTaxiCorporationId: (value: string) => void;
  setTaxiCorporation: (value: SelectOption) => void;
};
const OrderDetailsForwardingFormComponent: FC<
  OrderDetailsForwardingFormComponentProps
> = ({
  forwardingId,
  formValidationResults,
  selectedTaxiCorporationId,
  producerTaxiId,
  transportingOrderId,
  setTaxiCorporation,
  handleTaxiContractChange,
  getForwardings,
  setSelectedTaxiCorporationId,
}) => {
  const [selectedTaxiCorporation, setSelectedTaxiCorporation] =
    useState<SelectOptionTaxiCorporationSelectOption | null>(null);
  const [selectedTaxiContractId, setSelectedTaxiContractId] =
    useState<SelectOptionTaxiCorporationSelectOption | null>(null);
  const { taxiCorporation, taxiContract } = formValidationResults;

  useEffect(() => {
    setSelectedTaxiContractId(null);
    handleTaxiContractChange(null);
  }, [selectedTaxiCorporationId]);

  const translations =
    orderTranslationsHelper.getDetailsTranslations().forwarding;
  const { taxiCorporationLabel, taxiContractLabel } = translations.form;

  const handleTaxiCorporationChange = (
    value: SelectOptionTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporation(value);
    setTaxiCorporation(value);
    setSelectedTaxiCorporationId(value.value.id);
  };

  const handleOnTaxiContractChange = (value: SelectOption) => {
    handleTaxiContractChange(value);
    setSelectedTaxiContractId(value?.value?.uuid);
    getForwardings();
  };

  return (
    <>
      <FormFieldComponent
        label={taxiCorporationLabel}
        classNames={{ root: "mt-0" }}
        isRequired
        errorMessage={taxiCorporation.errorMessage}
      >
        <OrderDetailsSelectAvailableConsumerComponent
          forwardingId={forwardingId}
          onChange={handleTaxiCorporationChange}
          selectedTaxiCorporationValue={selectedTaxiCorporation}
          transportingOrderId={transportingOrderId}
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={taxiContractLabel}
        classNames={{ root: "mt-0" }}
        isRequired
        errorMessage={taxiContract.errorMessage}
      >
        <OrderDetailsSelectTaxiContractsComponent
          onChange={handleOnTaxiContractChange}
          taxiCorporationUuid={selectedTaxiCorporationId!}
          producerTaxiId={producerTaxiId}
          selectedTaxiContract={selectedTaxiContractId}
        />
      </FormFieldComponent>
    </>
  );
};

export default OrderDetailsForwardingFormComponent;
