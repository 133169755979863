import { IconProp } from "@fortawesome/fontawesome-svg-core";
import LinkButtonComponent, {
  LinkButtonProps,
} from "../../../button/link/link-button.component";
import { FC } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TableLinkButtonProps = Omit<LinkButtonProps, "children"> & {
  icon: IconProp;
};

const TableLinkButtonComponent: FC<TableLinkButtonProps> = (props) => {
  return (
    <LinkButtonComponent
      {...props}
      classNames={{
        root: classNames("table_button", props.classNames?.root),
        content: "table_button__content",
      }}
    >
      <FontAwesomeIcon
        icon={props.icon}
        className="table_button__icon"
        size={"sm"}
      />
    </LinkButtonComponent>
  );
};

TableLinkButtonComponent.defaultProps = {
  type: "brand",
};

export default TableLinkButtonComponent;
