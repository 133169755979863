import Joi from "joi";
import CargoOrderSettlementListingRouteQueryFilterParams from "./types/cargo-order-settlement-listing-route-query-filter-params";
import CargoOrderSettlementListingRouteQueryParams from "./types/cargo-order-settlement-listing-route-query-params";
import CargoOrderSettlementListingSortKey from "./types/cargo-order-settlement-listing-sort-key";
import CargoOrderSettlementListingFilter, {
  CargoOrderSettlementListingDispatchFilter,
  CargoOrderSettlementListingRouteAddressFilter,
  CargoOrderSettlementListingRouteDestinationAddressFilter,
  CargoOrderSettlementListingRouteIntermediateAddressFilter,
  CargoOrderSettlementListingRoutePickupAddressFilter,
  CargoOrderSettlementListingExternalOrderIdFilter,
  CargoOrderSettlementListingInternalOrderIdFilter,
  CargoOrderSettlementListingPassengerFilter,
  CargoOrderSettlementListingOrderStartDateFilter,
  CargoOrderSettlementListingBillingStatusFilter,
  CargoOrderSettlementListingTaxiFilter,
} from "./types/cargo-order-settlement-listing-filter";
import CargoOrderSettlementListingFilterType from "./types/cargo-order-settlement-listing-filter-type";
import cargoOrderSettlementListingFilterHelper from "./cargo-order-settlement-listing-filter.helper";

const getFilters = (
  routeQueryFilterParams: CargoOrderSettlementListingRouteQueryFilterParams
): CargoOrderSettlementListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: CargoOrderSettlementListingFilter[] = [];

  if (
    routeQueryFilterParams.orderStartDateFrom &&
    routeQueryFilterParams.orderStartDateTo
  ) {
    const startDateFilter: CargoOrderSettlementListingOrderStartDateFilter = {
      type: CargoOrderSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.orderStartDateFrom),
        to: new Date(routeQueryFilterParams.orderStartDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const idFilter: CargoOrderSettlementListingInternalOrderIdFilter = {
      type: CargoOrderSettlementListingFilterType.INTERNAL_ORDER_ID,
      value: routeQueryFilterParams.internalOrderId,
    };
    filters.push(idFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeIntermediateAddressFilter: CargoOrderSettlementListingRouteAddressFilter =
      {
        type: CargoOrderSettlementListingFilterType.ROUTE_ADDRESS,
        value: routeQueryFilterParams.routeAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: CargoOrderSettlementListingRouteIntermediateAddressFilter =
      {
        type: CargoOrderSettlementListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationAddressFilter: CargoOrderSettlementListingRouteDestinationAddressFilter =
      {
        type: CargoOrderSettlementListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: CargoOrderSettlementListingRoutePickupAddressFilter =
      {
        type: CargoOrderSettlementListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengersFilter: CargoOrderSettlementListingPassengerFilter = {
      type: CargoOrderSettlementListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };
    filters.push(passengersFilter);
  }

  if (routeQueryFilterParams.taxi) {
    const taxiFilter: CargoOrderSettlementListingTaxiFilter = {
      type: CargoOrderSettlementListingFilterType.TAXI,
      value: routeQueryFilterParams.taxi,
    };
    filters.push(taxiFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalIdFilter: CargoOrderSettlementListingExternalOrderIdFilter = {
      type: CargoOrderSettlementListingFilterType.EXTERNAL_ORDER_ID,
      value: routeQueryFilterParams.externalOrderId,
    };
    filters.push(externalIdFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: CargoOrderSettlementListingDispatchFilter = {
      type: CargoOrderSettlementListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };
    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.billingStatus) {
    const statusFilter: CargoOrderSettlementListingBillingStatusFilter = {
      type: CargoOrderSettlementListingFilterType.BILLING_STATUS,
      value: routeQueryFilterParams.billingStatus,
    };
    filters.push(statusFilter);
  }

  const updatedFilters =
    cargoOrderSettlementListingFilterHelper.getDefaultFilters(filters);

  return updatedFilters;
};

const getSortKey = (
  routeQueryParams: CargoOrderSettlementListingRouteQueryParams
): CargoOrderSettlementListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: CargoOrderSettlementListingFilter[],
  sortKey: CargoOrderSettlementListingSortKey | null,
  page: number,
  pageSize: number
): CargoOrderSettlementListingRouteQueryParams => {
  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    orderStartDateFrom: (
      filters.find(
        (filter) =>
          filter.type === CargoOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | CargoOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    orderStartDateTo: (
      filters.find(
        (filter) =>
          filter.type === CargoOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | CargoOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    internalOrderId: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | CargoOrderSettlementListingInternalOrderIdFilter["value"]
      | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.ROUTE_ADDRESS
    )?.value as
      | CargoOrderSettlementListingRouteAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        CargoOrderSettlementListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | CargoOrderSettlementListingRouteDestinationAddressFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        CargoOrderSettlementListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | CargoOrderSettlementListingRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type ===
        CargoOrderSettlementListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | CargoOrderSettlementListingRoutePickupAddressFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.PASSENGER
    )?.value as CargoOrderSettlementListingPassengerFilter["value"] | undefined,
    taxi: filters.find(
      (filter) => filter.type === CargoOrderSettlementListingFilterType.TAXI
    )?.value as CargoOrderSettlementListingTaxiFilter["value"] | undefined,
    externalOrderId: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | CargoOrderSettlementListingExternalOrderIdFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) => filter.type === CargoOrderSettlementListingFilterType.DISPATCH
    )?.value as CargoOrderSettlementListingDispatchFilter["value"] | undefined,
    billingStatus: filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.BILLING_STATUS
    )?.value as
      | CargoOrderSettlementListingBillingStatusFilter["value"]
      | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: CargoOrderSettlementListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: CargoOrderSettlementListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: CargoOrderSettlementListingRouteQueryFilterParams
) => {
  const filterParams: CargoOrderSettlementListingRouteQueryFilterParams = {
    orderStartDateFrom: routeQueryFilterParams.orderStartDateFrom,
    orderStartDateTo: routeQueryFilterParams.orderStartDateTo,
    internalOrderId: routeQueryFilterParams.internalOrderId,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    passenger: routeQueryFilterParams.passenger,
    taxi: routeQueryFilterParams.taxi,
    externalOrderId: routeQueryFilterParams.externalOrderId,
    dispatch: routeQueryFilterParams.dispatch,
    billingStatus: routeQueryFilterParams.billingStatus,
  };

  const validationSchema =
    Joi.object<CargoOrderSettlementListingRouteQueryFilterParams>({
      orderStartDateFrom: Joi.string(),
      orderStartDateTo: Joi.string(),
      internalOrderId: Joi.number(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      passenger: Joi.string(),
      taxi: Joi.string(),
      externalOrderId: Joi.string(),
      dispatch: Joi.string(),
      billingStatus: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: CargoOrderSettlementListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(CargoOrderSettlementListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: CargoOrderSettlementListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: CargoOrderSettlementListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const cargoOrderSettlementListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default cargoOrderSettlementListingRouteQueryParamsService;
