import phoneNumberInputHelper from "../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import dispatcherEditFormValidationService from "./dispatcher-edit-form-validation.service";
import DispatcherEditFormData from "./types/dispatcher-edit-form-data";

const getDefaultFormData = (): DispatcherEditFormData => {
  return {
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
    dispatchUuid: "",
    email: "",
    firstName: "",
    lastName: "",
    username: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<DispatcherEditFormData> => {
    return {
      alternativePhoneNumber: (values) =>
        dispatcherEditFormValidationService.validateAlternativeMobile(
          values.alternativePhoneNumber
        ),
      dispatchUuid: (values) =>
        dispatcherEditFormValidationService.validateDispatch(
          values.dispatchUuid
        ),
      email: (values) =>
        dispatcherEditFormValidationService.validateEmail(values.email),
      firstName: (values) =>
        dispatcherEditFormValidationService.validateFirstName(values.firstName),
      lastName: (values) =>
        dispatcherEditFormValidationService.validateFirstName(values.lastName),
      phoneNumber: (values) =>
        dispatcherEditFormValidationService.validateMobile(values.phoneNumber),
      username: () => formValidationService.ok(),
    };
  };

const dispatcherEditFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default dispatcherEditFormHelper;
