import Joi from "joi";
import driverDetailsPlanCheckoutFormValidationService from "../../common/driver-details-plan-checkout-form-validation.service";
import formValidationService from "../../../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../../../common/utils/validation/types/form-validation-result";

const getDateValidationSchema = (date: Date | null) => {
  const minDate = date ? date : "1970-01-01";

  return Joi.date().min(minDate).max(new Date()).required();
};

const validateDate = (
  date: Date | null,
  minDate: Date | null
): FormValidationResult => {
  const validationSchema = getDateValidationSchema(minDate);

  return formValidationService.validate(date, validationSchema);
};

const driverDetailsPlanAddCheckoutFormValidationService = {
  validateDate,
  validateHaltingTime:
    driverDetailsPlanCheckoutFormValidationService.validateHaltingTime,
  validateHighwayCost:
    driverDetailsPlanCheckoutFormValidationService.validateHighwayCost,
};

export default driverDetailsPlanAddCheckoutFormValidationService;
