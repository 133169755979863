import OrderOptimizerListResponse, {
  OrderOptimizerListResponseDataItem,
  OrderOptimizerListResponseDataItemEmptyRide,
  OrderOptimizerListResponseDataItemEmptyRideStartPoint,
  OrderOptimizerListResponseDataItemOrder,
  OrderOptimizerListResponseDataItemOrderBusinessData,
  OrderOptimizerListResponseDataItemOrderDriversReturnPoint,
  OrderOptimizerListResponseDataItemOrderEndPoint,
  OrderOptimizerListResponseDataItemOrderStartPoint,
} from "../../../api/raily/order-optimizer/list/order-optimizer-list.response";
import OrderOptimizerList, {
  OrderOptimizerListItem,
  OrderOptimizerListItemEmptyRide,
  OrderOptimizerListItemEmptyRideStartPoint,
  OrderOptimizerListItemOrder,
  OrderOptimizerListItemOrderBusinessData,
  OrderOptimizerListItemOrderDriversReturnPoint,
  OrderOptimizerListItemOrderEndPoint,
  OrderOptimizerListItemOrderStartPoint,
} from "./order-optimizer-list";

const createOrderStartPoint = (
  responseDataItemOrderStartPoint: OrderOptimizerListResponseDataItemOrderStartPoint
): OrderOptimizerListItemOrderStartPoint => ({
  latitude: responseDataItemOrderStartPoint.latitude,
  longitude: responseDataItemOrderStartPoint.longitude,
});

const createOrderEndPoint = (
  responseDataItemOrderEndPoint: OrderOptimizerListResponseDataItemOrderEndPoint
): OrderOptimizerListItemOrderEndPoint => ({
  latitude: responseDataItemOrderEndPoint.latitude,
  longitude: responseDataItemOrderEndPoint.longitude,
});

const createOrderDriversReturnPoint = (
  responseDataItemOrderDriversReturnPoint: OrderOptimizerListResponseDataItemOrderDriversReturnPoint | null
): OrderOptimizerListItemOrderDriversReturnPoint | null => {
  if (!responseDataItemOrderDriversReturnPoint) {
    return null;
  }

  return {
    latitude: responseDataItemOrderDriversReturnPoint.latitude,
    longitude: responseDataItemOrderDriversReturnPoint.longitude,
  };
};

const createOrderBusinessData = (
  responseDataItemOrderBusinessData: OrderOptimizerListResponseDataItemOrderBusinessData
): OrderOptimizerListItemOrderBusinessData => ({
  cargoCompany: responseDataItemOrderBusinessData.cargoCompany,
  taxiCompany: responseDataItemOrderBusinessData.taxiCompany,
  planEntryHumanId: responseDataItemOrderBusinessData.planEntryHumanId,
  startPointDisplayName:
    responseDataItemOrderBusinessData.startPointDisplayName,
  endPointDisplayName: responseDataItemOrderBusinessData.endPointDisplayName,
});

const createOrder = (
  responseDataItemOrder: OrderOptimizerListResponseDataItemOrder
): OrderOptimizerListItemOrder => ({
  humanId: responseDataItemOrder.humanId,
  startPoint: createOrderStartPoint(responseDataItemOrder.startPoint),
  endPoint: createOrderEndPoint(responseDataItemOrder.endPoint),
  /**TODO: Remove this and thif of a proper solution */
  startTime: new Date(
    new Date(responseDataItemOrder.startTime).getTime() + 7200000
  ),
  /**TODO: Remove this and thif of a proper solution */
  endTime: new Date(
    new Date(responseDataItemOrder.endTime).getTime() + 7200000
  ),
  forwardDistance: responseDataItemOrder.forwardDistance,
  driversReturnPoint: createOrderDriversReturnPoint(
    responseDataItemOrder.driversReturnPoint
  ),
  businessData: createOrderBusinessData(responseDataItemOrder.businessData),
});

const createOrders = (
  responseDataItemOrders: OrderOptimizerListResponseDataItemOrder[]
): OrderOptimizerListItemOrder[] => {
  return responseDataItemOrders.map(createOrder);
};

const createEmptyRideStartPoint = (
  responseDataItemEmptyRideStartPoint: OrderOptimizerListResponseDataItemEmptyRideStartPoint
): OrderOptimizerListItemEmptyRideStartPoint => ({
  latitude: responseDataItemEmptyRideStartPoint.latitude,
  longitude: responseDataItemEmptyRideStartPoint.longitude,
});

const createEmptyRideEndPoint = (
  responseDataItemEmptyRideEndPoint: OrderOptimizerListResponseDataItemEmptyRideStartPoint
): OrderOptimizerListItemEmptyRideStartPoint => ({
  latitude: responseDataItemEmptyRideEndPoint.latitude,
  longitude: responseDataItemEmptyRideEndPoint.longitude,
});

const createEmptyRide = (
  responseDataItemEmptyRide: OrderOptimizerListResponseDataItemEmptyRide
): OrderOptimizerListItemEmptyRide => ({
  startPoint: createEmptyRideStartPoint(responseDataItemEmptyRide.startPoint),
  endPoint: createEmptyRideEndPoint(responseDataItemEmptyRide.endPoint),
  osrmDistance: responseDataItemEmptyRide.osrmDistance,
  osrmTime: responseDataItemEmptyRide.osrmTime,
  haltingTime: responseDataItemEmptyRide.haltingTime,
});

const createEmptyRides = (
  responseDataItemEmptyRides: OrderOptimizerListResponseDataItemEmptyRide[]
): OrderOptimizerListItemEmptyRide[] => {
  return responseDataItemEmptyRides.map(createEmptyRide);
};

const createDataItem = (
  responseDataItem: OrderOptimizerListResponseDataItem
): OrderOptimizerListItem => {
  return {
    orders: createOrders(responseDataItem.orders),
    score: responseDataItem.score,
    relativeScore: responseDataItem.relativeScore,
    emptyRides: createEmptyRides(responseDataItem.emptyRides),
  };
};

const createData = (
  responseData: OrderOptimizerListResponseDataItem[]
): OrderOptimizerListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: OrderOptimizerListResponse): OrderOptimizerList => {
  return {
    data: createData(response.data),
    totalCount: response.data.length,
  };
};

const orderOptimizerListFactory = {
  create,
};

export default orderOptimizerListFactory;
