import appLanguageHelper from "./app-language.helper";
import AppLanguage from "./types/app-language";
import AuthTranslations from "./types/auth/auth-translations";
import CargoOfficerSignUpTranslations from "./types/auth/cargo-officer-sign-up.translations";
import DealerSignUpTranslations from "./types/auth/dealer-sign-up.translations";
import DispatcherSignUpTranslations from "./types/auth/dispatcher-sign-up.translations";
import DriverSignUpTranslations from "./types/auth/driver-sign-up.translations";
import PassengerSignUpTranslations from "./types/auth/passenger-sign-up.translation";
import SignInTranslations from "./types/auth/sign-in.translations";
import TaxiOfficerSignUpTranslations from "./types/auth/taxi-officer-sign-up.translations";
import BreadcrumbsTranslations from "./types/breadcrumbs.translations";
import CommonTranslations from "./types/common.translations";
import ComponentsTranslations from "./types/components.translations";
import DocumentTitleTranslations from "./types/document-title.translations";
import NavigationTranslations from "./types/navigation.translations";
import RouteNoAccessTranslations from "./types/route-no-access.translations";
import RouteNotFoundTranslations from "./types/route-not-found.translations";
import UserCommonTranslations from "./types/user-common.translations";
import ValidationErrorMessageTranslations from "./types/validation-error-message.translations";

const getFolderName = (selectedLanguage: AppLanguage): string => {
  const definition: Record<AppLanguage, string> = {
    [AppLanguage.DE]: "de",
    [AppLanguage.EN]: "en",
    [AppLanguage.PL]: "pl",
  };

  return definition[selectedLanguage];
};

const getBaseFilePath = (selectedLanguage: AppLanguage) => {
  const folderName = getFolderName(selectedLanguage);
  const baseFilePath = `./${folderName}`;

  return baseFilePath;
};

const getResolvedFilePath = (internalFilePath: string) => {
  const selectedLanguage = appLanguageHelper.getSelectedLanguage();

  const baseFilePath = getBaseFilePath(selectedLanguage);

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return filePath;
};

const getTranslations = <T>(internalFilePath: string): T => {
  const filePath = getResolvedFilePath(internalFilePath);

  const fileContent = require(`${filePath}`);

  return fileContent as T;
};

const getCommonTranslations = (): CommonTranslations => {
  const translations = getTranslations<CommonTranslations>(
    `common.translations.json`
  );

  return translations;
};

const getDocumentTitleTranslations = (): DocumentTitleTranslations => {
  const translations = getTranslations<DocumentTitleTranslations>(
    `document-titles.translations.json`
  );

  return translations;
};

const getBreadcrumbsTranslations = (): BreadcrumbsTranslations => {
  const translations = getTranslations<BreadcrumbsTranslations>(
    `breadcrumbs.translations.json`
  );

  return translations;
};

const getNavigationTranslations = (): NavigationTranslations => {
  const translations = getTranslations<NavigationTranslations>(
    `navigation.translations.json`
  );

  return translations;
};

const getFormValidationMessageTemplates =
  (): ValidationErrorMessageTranslations => {
    const translations = getTranslations<ValidationErrorMessageTranslations>(
      "validation-error-messages.translations.json"
    );

    return translations;
  };

const getAuthTranslations = (): AuthTranslations => {
  const translations = getTranslations<AuthTranslations>(
    `auth/auth.translations.json`
  );

  return translations;
};

const getSignInTranslations = (): SignInTranslations => {
  const translations = getTranslations<SignInTranslations>(
    `auth/sign-in.translations.json`
  );

  return translations;
};

const getDriverSignUpTranslations = (): DriverSignUpTranslations => {
  const translations = getTranslations<DriverSignUpTranslations>(
    "auth/driver-sign-up.translations.json"
  );

  return translations;
};

const getCargoOfficerSignUpTranslations = () => {
  const translations = getTranslations<CargoOfficerSignUpTranslations>(
    "auth/cargo-officer-sign-up.translations.json"
  );

  return translations;
};

const getDealerSignUpTranslations = () => {
  const translations = getTranslations<DealerSignUpTranslations>(
    "auth/dealer-sign-up.translations.json"
  );

  return translations;
};

const getTaxiOfficerSignUpTranslations = () => {
  const translations = getTranslations<TaxiOfficerSignUpTranslations>(
    "auth/taxi-officer-sign-up.translations.json"
  );

  return translations;
};

const getDispatcherSignUpTranslations = () => {
  const translations = getTranslations<DispatcherSignUpTranslations>(
    `auth/dispatcher-sign-up.translations.json`
  );

  return translations;
};

const getPassengerSignUpTranslations = () => {
  const translations = getTranslations<PassengerSignUpTranslations>(
    `auth/passenger-sign-up.translations.json`
  );

  return translations;
};

const getUserCommonTranslations = (): UserCommonTranslations => {
  const translations = getTranslations<UserCommonTranslations>(
    `user-common.translations.json`
  );

  return translations;
};

const getComponentTranslations = (): ComponentsTranslations => {
  const translations = getTranslations<ComponentsTranslations>(
    `components.translations.json`
  );

  return translations;
};

const getRouteNotFoundTranslations = () => {
  const translations = getTranslations<RouteNotFoundTranslations>(
    `route-not-found.translations.json`
  );

  return translations;
};

const getRouteNoAccessTranslations = () => {
  const translations = getTranslations<RouteNoAccessTranslations>(
    `route-no-access.translations.json`
  );

  return translations;
};

const appTranslationsHelper = {
  getBreadcrumbsTranslations,
  getCommonTranslations,
  getDocumentTitleTranslations,
  getNavigationTranslations,
  getFormValidationMessageTemplates,
  getAuthTranslations,
  getSignInTranslations,
  getDealerSignUpTranslations,
  getDispatcherSignUpTranslations,
  getCargoOfficerSignUpTranslations,
  getDriverSignUpTranslations,
  getTaxiOfficerSignUpTranslations,
  getPassengerSignUpTranslations,
  getUserCommonTranslations,
  getComponentTranslations,
  getRouteNotFoundTranslations,
  getRouteNoAccessTranslations,
  getTranslations,
};

export default appTranslationsHelper;
