import { useAppContext } from "../../../../context/app.context";
import UserPermissionsDefinition from "./user-permissions-definition";
import userPermissionsService from "./user-permissions.service";

const useUserPermissions = <T extends string>(
  definition: UserPermissionsDefinition<T>
) => {
  const { user } = useAppContext();

  const permissions = userPermissionsService.solvePermissions(
    user?.roles!,
    definition
  );

  return permissions;
};

export default useUserPermissions;
