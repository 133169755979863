import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import mileageListingTableHelper from "./mileage-listing-table.helper";
import MileageListingTableRow from "../common/types/mileage-listing-table-row";
import dateService from "../../../../common/utils/date/date.service";
import MileageListingTableStatusComponent from "./status/mileage-listing-table-status.component";
import MileageListingTableColumn from "../common/types/mileage-listing-table-column";
import { useAppContext } from "../../../../context/app.context";
import MileageListingItem from "../common/types/mileage-listing-item";
import MileageListingTableRouteComponent from "./route/mileage-listing-table-route.component";
import MileageListingActionData from "../common/types/mileage-listing-action-data";
import TableButtonComponent from "../../../../common/components/table/button/table-button.component";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import MileageListingStatus from "../common/types/mileage-listing-status";
import MileageDetailsComponent from "../../details/mileage-details.component";
import mileageListingHelper from "../common/mileage-listing.helper";
import { round } from "lodash";

type MileageListingTableProps = Pick<TableProps, "isError" | "isLoading"> & {
  listingItems: MileageListingItem[];
  expandedRowsMileageUuids: string[];
  onRowClick: (mileageUuid: string) => void;
  isGenerateDelegationEnabled: boolean;
  isDownloadCSVEnabled: boolean;
  mileageListingActionData: MileageListingActionData[];
  onActionButtonClick: (listingItem: MileageListingItem) => void;
  onActionSelectAllButtonClick: () => void;
  reloadRows: () => void;
};

const MileageListingTableComponent: FC<MileageListingTableProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(
    (): MileageListingTableColumn[] =>
      mileageListingTableHelper.getColumns(
        props.isDownloadCSVEnabled,
        props.mileageListingActionData.length === props.listingItems.length,
        props.onActionSelectAllButtonClick
      ),
    [
      selectedAppLanguage,
      props.isDownloadCSVEnabled,
      props.onActionSelectAllButtonClick,
    ]
  );

  const createTableRow = (
    listingItem: MileageListingItem
  ): MileageListingTableRow => {
    const mileageDateTitle = dateService.format(
      listingItem.mileageDate,
      "dd.mm.yyyy"
    );
    const mileageDateLabel = dateService.format(
      listingItem.mileageDate,
      "dd.mm.yyyy"
    );

    const isHiddenComponentVisible = props.expandedRowsMileageUuids.includes(
      listingItem.uuid
    );

    const distanceLabel = `${round(
      listingItem.acceptedDistance ?? listingItem.distance
    )} KM`;

    const isItemSelected = !!props.mileageListingActionData.find(
      (source) => source.mileageUuid === listingItem.uuid
    );

    const isGenerateDelegationButtonVisible =
      props.isGenerateDelegationEnabled &&
      listingItem.status === MileageListingStatus.ACCEPTED &&
      (props.mileageListingActionData.length === 0 ||
        (props.mileageListingActionData[0].workerCompanyUuid ===
          listingItem.workerCompanyUuid &&
          props.mileageListingActionData[0].workerUuid ===
            listingItem.workerUuid));

    const isDownloadCSVButtonVisible = props.isDownloadCSVEnabled;

    const isActionButtonVisible =
      isGenerateDelegationButtonVisible || isDownloadCSVButtonVisible;

    return {
      id: listingItem.uuid,
      onClick: () => props.onRowClick(listingItem.uuid),
      value: {
        status: (
          <MileageListingTableStatusComponent status={listingItem.status} />
        ),
        mileageDate: <div title={mileageDateTitle}>{mileageDateLabel}</div>,
        humanId: (
          <div title={String(listingItem.humanId)}>{listingItem.humanId}</div>
        ),
        cargoCompany: (
          <div title={listingItem.cargoCompanyName}>
            {listingItem.cargoCompanyName}
          </div>
        ),
        mileageNumber: (
          <div title={listingItem.mileageNumber ?? ""}>
            {listingItem.mileageNumber ?? "---"}
          </div>
        ),
        vehicleType: (
          <div
            title={mileageListingHelper.getVehicleTypeLabel(
              listingItem.vehicleType
            )}
          >
            {mileageListingHelper.getVehicleTypeLabel(listingItem.vehicleType)}
          </div>
        ),
        workerName: (
          <div title={listingItem.workerName}>{listingItem.workerName}</div>
        ),
        cardNumber: (
          <div title={listingItem.cardNumber ?? ""}>
            {listingItem.cardNumber ?? "---"}
          </div>
        ),
        route: (
          <MileageListingTableRouteComponent
            addresses={listingItem.addressSeq.seq.map(
              (value) => value.displayName
            )}
          />
        ),
        distance: <div title={distanceLabel}>{distanceLabel}</div>,
        action: isActionButtonVisible && (
          <TableButtonComponent
            icon={faCrosshairs}
            type={isItemSelected ? "success" : "brand"}
            onClick={() => props.onActionButtonClick(listingItem)}
          />
        ),
      },
      hiddenComponent: (
        <MileageDetailsComponent
          isVisible={isHiddenComponentVisible}
          row={listingItem}
          reloadRows={props.reloadRows}
        />
      ),
      isHiddenComponentVisible,
    };
  };

  const rows: MileageListingTableRow[] = useMemo(() => {
    return props.listingItems.map((list) => createTableRow(list));
  }, [
    JSON.stringify(props.listingItems),
    props.expandedRowsMileageUuids,
    props.isGenerateDelegationEnabled,
    props.isDownloadCSVEnabled,
    props.mileageListingActionData,
  ]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default MileageListingTableComponent;
