import CargoCompanyDispatchUpdateRequest, {
  CargoCompanyDispatchUpdateRequestBody,
} from "./cargo-company-dispatch-update-request";

const createBody = (
  data: CargoCompanyDispatchUpdateRequest
): CargoCompanyDispatchUpdateRequestBody => {
  return {
    addressString: data.addressString,
    dispatchName: data.dispatchName,
    displayName: data.displayName,
  };
};

const cargoCompanyDispatchUpadteRequestFactory = {
  createBody,
};

export default cargoCompanyDispatchUpadteRequestFactory;
