import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import UserFormOfEmployment from "../../../../../../common/types/user-form-of-employment";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import signUpDriverAccountDataFormValidationService from "../../account/sign-up-driver-account-data-form-validation.service";
import signUpDriverCompanyDataFormValidationService from "../../company/sign-up-driver-company-data-form-validation.service";
import signUpDriverUserDataFormValidationService from "../../user/sign-up-driver-user-data-form-validation.service";
import signUpDriverVehicleFormValidationService from "../../vehicle/sign-up-driver-vehicle-form-validation.service";
import signUpDriverFormHelper from "../sign-up-driver-form.helper";
import SignUpDriverFormAccountData from "../types/sign-up-driver-form-account-data";
import SignUpDriverFormAccountDataValidationResults from "../types/sign-up-driver-form-account-data-validation-results";
import SignUpDriverFormCompanyData from "../types/sign-up-driver-form-company-data";
import SignUpDriverFormCompanyDataValidationResults from "../types/sign-up-driver-form-company-data-validation-results";
import SignUpDriverFormStep from "../types/sign-up-driver-form-step";
import SignUpDriverFormUserAddress from "../types/sign-up-driver-form-user-address";
import SignUpDriverFormUserData from "../types/sign-up-driver-form-user-data";
import SignUpDriverFormUserDataValidationResults from "../types/sign-up-driver-form-user-data-validation-results";
import SignUpDriverFormVehicleData from "../types/sign-up-driver-form-vehicle-data";
import SignUpDriverForVehicleValidationResults from "../types/sign-up-driver-form-vehicle-validation-results";
import SignUpDriverContextProps from "./sign-up-driver-context.props";

const SignUpDriverContext = createContext<SignUpDriverContextProps | null>(
  null
);

export const useSignUpDriverContext = () => {
  const ctx = useContext(SignUpDriverContext);

  if (ctx === undefined || ctx === null) {
    throw new Error(
      "useSignUpDriverContext must be used within a SignUpDriverContextProvider"
    );
  }
  return ctx;
};

const SignUpDriverContextProvider = ({ children }: { children: ReactNode }) => {
  const [formSteps, setFormSteps] = useState<SignUpDriverFormStep[]>(
    signUpDriverFormHelper.getDefaultFormSteps()
  );
  const [currentFormStep, setCurrentFormStep] = useState<SignUpDriverFormStep>(
    SignUpDriverFormStep.accountData
  );
  const [passedFormSteps, setPassedFormSteps] = useState<
    SignUpDriverFormStep[]
  >([]);

  const [taxiCorporationId, setTaxiCorporationId] = useState("");
  const [accountData, setAccountData] = useState<SignUpDriverFormAccountData>({
    email: "",
    username: "",
    password: "",
    repeatPassword: "",
  });
  const [accountDataValidationResults, setAccountDataValidationResults] =
    useState<SignUpDriverFormAccountDataValidationResults>({
      email: formValidationService.defaultValidationResult,
      username: formValidationService.defaultValidationResult,
      password: formValidationService.defaultValidationResult,
      repeatPassword: formValidationService.defaultValidationResult,
    });

  const [userData, setUserData] = useState<SignUpDriverFormUserData>({
    firstName: "",
    lastName: "",
    mobile: {
      dialingCode: "",
      number: "",
    },
    alternativeMobile: {
      dialingCode: "",
      number: "",
    },
    birthDate: null,
    birthPlace: "",
    personalIdNumber: "",
    citizenship: null,
    languages: [],
    yearsOfExperience: null,
    formOfEmployment: null,
    addresses: [],
  });

  const [isAccountDataEmailDisabled, setIsAccountDataEmailDisabled] =
    useState(false);
  const [isAccountDataUsernameDisabled, setIsAccountDataUsernameDisabled] =
    useState(false);
  const [isUserDataFirstNameDisabled, setIsUserDataFirstNameDisabled] =
    useState(false);
  const [isUserDataLastNameDisabled, setIsUserDataLastNameDisabled] =
    useState(false);
  const [isUserDataMobileNumberDisabled, setIsUserDataMobileNumberDisabled] =
    useState(false);
  const [
    isUserDataAlternativeMobileNumberDisabled,
    setIsUserDataAlternativeMobileNumberDisabled,
  ] = useState(false);

  const [userDataValidationResults, setUserDataValidationResults] =
    useState<SignUpDriverFormUserDataValidationResults>({
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
      alternativeMobile: formValidationService.defaultValidationResult,
      birthDate: formValidationService.defaultValidationResult,
      birthPlace: formValidationService.defaultValidationResult,
      personalIdNumber: formValidationService.defaultValidationResult,
      citizenship: formValidationService.defaultValidationResult,
      languages: formValidationService.defaultValidationResult,
      yearsOfExperience: formValidationService.defaultValidationResult,
      formOfEmployment: formValidationService.defaultValidationResult,
      addresses: formValidationService.defaultValidationResult,
    });

  const [companyData, setCompanyData] = useState<SignUpDriverFormCompanyData>({
    name: "",
    companyId: "",
    taxNumber: "",
    nationalCourtRegisterNumber: "",
    address: {
      apartmentNumber: "",
      country: "",
      description: "",
      houseNumber: "",
      street: "",
      town: "",
      zipCode: "",
    },
  });

  const [companyDataValidationResults, setCompanyDataValidationResults] =
    useState<SignUpDriverFormCompanyDataValidationResults>({
      name: formValidationService.defaultValidationResult,
      companyId: formValidationService.defaultValidationResult,
      taxNumber: formValidationService.defaultValidationResult,
      nationalCourtRegisterNumber:
        formValidationService.defaultValidationResult,
      addressCountry: formValidationService.defaultValidationResult,
      addressZipCode: formValidationService.defaultValidationResult,
      addressTown: formValidationService.defaultValidationResult,
      addressStreet: formValidationService.defaultValidationResult,
      addressHouseNumber: formValidationService.defaultValidationResult,
      addressApartmentNumber: formValidationService.defaultValidationResult,
      addressDescription: formValidationService.defaultValidationResult,
      addressMap: formValidationService.defaultValidationResult,
    });

  const [vehicleData, setVehicleData] = useState<SignUpDriverFormVehicleData>({
    make: "",
    model: "",
    numberOfSeats: null,
    registrationNumber: "",
    ownership: null,
    productionYear: null,
  });

  const [vehicleValidationResults, setVehicleValidationResults] =
    useState<SignUpDriverForVehicleValidationResults>({
      make: formValidationService.defaultValidationResult,
      model: formValidationService.defaultValidationResult,
      numberOfSeats: formValidationService.defaultValidationResult,
      registrationNumber: formValidationService.defaultValidationResult,
      ownership: formValidationService.defaultValidationResult,
      productionYear: formValidationService.defaultValidationResult,
    });

  useEffect(() => {
    if (userData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
      const steps = signUpDriverFormHelper.getFormStepsForB2B();
      setFormSteps(steps);
      return;
    }

    const steps = signUpDriverFormHelper.getDefaultFormSteps();
    setFormSteps(steps);
  }, [userData.formOfEmployment]);

  // ACCOUNT DATA

  const checkIsCurrentStepFormValid = async (): Promise<boolean> => {
    switch (currentFormStep) {
      case SignUpDriverFormStep.accountData:
        return await checkIsAccountDataFormValid();
      case SignUpDriverFormStep.userData:
        return checkIsUserDataFormValid();
      case SignUpDriverFormStep.companyData:
        return checkIsCompanyDataFormValid();
      case SignUpDriverFormStep.vehicleData:
        return checkIsVehicleDataFormValid();
      default:
        return false;
    }
  };

  const goToStep = (step: SignUpDriverFormStep) => {
    if (currentFormStep === step) {
      return;
    }

    if (!passedFormSteps.includes(currentFormStep)) {
      setCurrentFormStep(step);
      return;
    }

    const isCurrentFormValid = checkIsCurrentStepFormValid();

    if (!isCurrentFormValid) {
      return;
    }

    setCurrentFormStep(step);
  };

  const setAccountDataEmail = (value: SignUpDriverFormAccountData["email"]) => {
    setAccountData((curr) => ({
      ...curr,
      email: value,
    }));
  };

  const setAccountDataUsername = (
    value: SignUpDriverFormAccountData["username"]
  ) => {
    setAccountData((curr) => ({
      ...curr,
      username: value,
    }));
  };

  const setAccountDataPassword = (
    value: SignUpDriverFormAccountData["password"]
  ) => {
    setAccountData((curr) => ({
      ...curr,
      password: value,
    }));
  };

  const setAccountDataRepeatPassword = (
    value: SignUpDriverFormAccountData["repeatPassword"]
  ) => {
    setAccountData((curr) => ({
      ...curr,
      repeatPassword: value,
    }));
  };

  const disableAccountDataEmail = () => {
    setIsAccountDataEmailDisabled(true);
  };

  const disableAccountDataUsername = () => {
    setIsAccountDataUsernameDisabled(true);
  };

  const disableUserDataFirstName = () => {
    setIsUserDataFirstNameDisabled(true);
  };

  const disableUserDataLastName = () => {
    setIsUserDataLastNameDisabled(true);
  };

  const disableUserDataMobileNumber = () => {
    setIsUserDataMobileNumberDisabled(true);
  };

  const disableUserDataAlternativeMobileNumber = () => {
    setIsUserDataAlternativeMobileNumberDisabled(true);
  };

  const validateAccountDataEmail = () => {
    const validationResult =
      signUpDriverAccountDataFormValidationService.validateEmail(
        accountData.email
      );

    setAccountDataValidationResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAccountDataUsername = async () => {
    const validationResult =
      await signUpDriverAccountDataFormValidationService.validateUsername(
        accountData.username
      );

    setAccountDataValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAccountDataPassword = () => {
    const validationResult =
      signUpDriverAccountDataFormValidationService.validatePassword(
        accountData.password
      );

    setAccountDataValidationResults((curr) => ({
      ...curr,
      password: validationResult,
    }));

    if (!!accountData.repeatPassword) {
      validateAccountDataPasswordRepeat();
    }

    return validationResult.isValid;
  };

  const validateAccountDataPasswordRepeat = () => {
    const validationResult =
      signUpDriverAccountDataFormValidationService.validatePasswordRepeat(
        accountData.password,
        accountData.repeatPassword
      );

    setAccountDataValidationResults((curr) => ({
      ...curr,
      repeatPassword: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsAccountDataFormValid = async () => {
    const isEmailValid = validateAccountDataEmail();
    const isUsernameValid = await validateAccountDataUsername();
    const isPasswordValid = validateAccountDataPassword();
    const isPasswordRepeatValid = validateAccountDataPasswordRepeat();

    const isFormValid =
      isEmailValid &&
      isUsernameValid &&
      isPasswordValid &&
      isPasswordRepeatValid;

    return isFormValid;
  };

  const submitAccountStep = () => {
    const isFormValid = checkIsAccountDataFormValid();

    if (!isFormValid) {
      return;
    }

    setPassedFormSteps((curr) => [...curr, SignUpDriverFormStep.accountData]);
    setCurrentFormStep(SignUpDriverFormStep.userData);
  };

  // USER DATA

  const setUserDataFirstName = (
    value: SignUpDriverFormUserData["firstName"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      firstName: value,
    }));
  };

  const setUserDataLastName = (value: SignUpDriverFormUserData["lastName"]) => {
    setUserData((curr) => ({
      ...curr,
      lastName: value,
    }));
  };

  const setUserDataMobile = (value: SignUpDriverFormUserData["mobile"]) => {
    setUserData((curr) => ({
      ...curr,
      mobile: value,
    }));
  };

  const setUserDataAlternativeMobile = (
    value: SignUpDriverFormUserData["alternativeMobile"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      alternativeMobile: value,
    }));
  };

  const setUserDataBirthDate = (
    value: SignUpDriverFormUserData["birthDate"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      birthDate: value,
    }));
  };

  const setUserDataBirthPlace = (
    value: SignUpDriverFormUserData["birthPlace"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      birthPlace: value,
    }));
  };

  const setUserDataPersonalIdNumber = (
    value: SignUpDriverFormUserData["personalIdNumber"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      personalIdNumber: value,
    }));
  };

  const setUserDataCitizenship = (
    value: SignUpDriverFormUserData["citizenship"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      citizenship: value,
    }));
  };

  const setUserDataLanguages = (
    value: SignUpDriverFormUserData["languages"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      languages: value,
    }));
  };

  const setUserDataExperience = (
    value: SignUpDriverFormUserData["yearsOfExperience"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      yearsOfExperience: value,
    }));
  };

  const setUserDataFormOfEmployment = (
    value: SignUpDriverFormUserData["formOfEmployment"]
  ) => {
    setUserData((curr) => ({
      ...curr,
      formOfEmployment: value,
    }));
  };

  const addUserDataAddress = (newAddress: SignUpDriverFormUserAddress) => {
    setUserData((curr) => ({
      ...curr,
      addresses: [...curr.addresses, newAddress],
    }));
  };

  const removeUserDataAddress = (addressIndex: number) => {
    const filteredAddresses = [
      ...userData.addresses.slice(0, addressIndex),
      ...userData.addresses.slice(addressIndex + 1),
    ];
    setUserData((curr) => ({
      ...curr,
      addresses: filteredAddresses,
    }));
  };

  const validateUserDataFirstname = () => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateFirstname(
        userData.firstName
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataLastname = () => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateLastname(
        userData.lastName
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataMobile = () => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateMobile(userData.mobile);

    setUserDataValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataAlternativeMobile = () => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateAlternativeMobile(
        userData.alternativeMobile
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataBirthDate = (
    value: SignUpDriverFormUserData["birthDate"] = userData.birthDate
  ) => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateBirthDate(value);

    setUserDataValidationResults((curr) => ({
      ...curr,
      birthDate: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataBirthPlace = () => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateBirthPlace(
        userData.birthPlace
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      birthPlace: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataPersonalIdNumber = () => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validatePersonalIdNumber(
        userData.personalIdNumber
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      personalIdNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataCitizenship = (
    value: SignUpDriverFormUserData["citizenship"] = userData.citizenship
  ) => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateCitizenship(value);

    setUserDataValidationResults((curr) => ({
      ...curr,
      citizenship: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataLanguages = (
    value: SignUpDriverFormUserData["languages"] = userData.languages
  ) => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateLanguages(value);

    setUserDataValidationResults((curr) => ({
      ...curr,
      languages: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataYearsOfExperience = () => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateYearsOfExperience(
        userData.yearsOfExperience
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      yearsOfExperience: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataFormOfEmployment = (
    value: SignUpDriverFormUserData["formOfEmployment"] = userData.formOfEmployment
  ) => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateFormOfEmployment(value);

    setUserDataValidationResults((curr) => ({
      ...curr,
      formOfEmployment: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataAddresses = () => {
    const validationResult =
      signUpDriverUserDataFormValidationService.validateAddresses(
        userData.addresses
      );

    setUserDataValidationResults((curr) => ({
      ...curr,
      addresses: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsUserDataFormValid = () => {
    const isFirstnameValid = validateUserDataFirstname();
    const isLastnameValid = validateUserDataLastname();
    const isMobileValid = validateUserDataMobile();
    const isAlternativeMobileValid = validateUserDataAlternativeMobile();
    const isBirthDateValid = validateUserDataBirthDate();
    const isBirthPlaceValid = validateUserDataBirthPlace();
    const isPersonalIdNumberValid = validateUserDataPersonalIdNumber();
    const isCitizenshipValid = validateUserDataCitizenship();
    const areLanguagesValid = validateUserDataLanguages();
    const isYearsOfExperienceValid = validateUserDataYearsOfExperience();
    const isFormOfEmploymentValid = validateUserDataFormOfEmployment();
    const areAddressesValid = validateUserDataAddresses();

    const isFormValid =
      isFirstnameValid &&
      isLastnameValid &&
      isMobileValid &&
      isAlternativeMobileValid &&
      isBirthDateValid &&
      isBirthPlaceValid &&
      isPersonalIdNumberValid &&
      isCitizenshipValid &&
      areLanguagesValid &&
      isYearsOfExperienceValid &&
      isFormOfEmploymentValid &&
      areAddressesValid;

    return isFormValid;
  };

  const submitUserStep = () => {
    const isFormValid = checkIsUserDataFormValid();

    if (!isFormValid) {
      return;
    }

    setPassedFormSteps((curr) => [...curr, SignUpDriverFormStep.userData]);

    if (userData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
      setCurrentFormStep(SignUpDriverFormStep.companyData);
      return;
    }

    setCurrentFormStep(SignUpDriverFormStep.vehicleData);
  };

  // COMPANY DATA

  const setCompanyDataName = (value: SignUpDriverFormCompanyData["name"]) => {
    setCompanyData((curr) => ({
      ...curr,
      name: value,
    }));
  };

  const setCompanyDataCompanyId = (
    value: SignUpDriverFormCompanyData["companyId"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      companyId: value.toUpperCase(),
    }));
  };

  const setCompanyDataTaxNumber = (
    value: SignUpDriverFormCompanyData["taxNumber"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      taxNumber: value.toUpperCase(),
    }));
  };

  const setCompanyDataNationalCourtRegisterNumber = (
    value: SignUpDriverFormCompanyData["nationalCourtRegisterNumber"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      nationalCourtRegisterNumber: value.toUpperCase(),
    }));
  };

  const setCompanyDataAddressStreet = (
    value: SignUpDriverFormCompanyData["address"]["street"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      address: {
        ...curr.address,
        street: value,
      },
    }));
  };

  const setCompanyDataAddressHouseNumber = (
    value: SignUpDriverFormCompanyData["address"]["houseNumber"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      address: {
        ...curr.address,
        houseNumber: value,
      },
    }));
  };

  const setCompanyDataAddressApartmentNumber = (
    value: SignUpDriverFormCompanyData["address"]["apartmentNumber"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      address: {
        ...curr.address,
        apartmentNumber: value,
      },
    }));
  };

  const setCompanyDataAddressTown = (
    value: SignUpDriverFormCompanyData["address"]["town"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      address: {
        ...curr.address,
        town: value,
      },
    }));
  };

  const setCompanyDataAddressCountry = (
    value: SignUpDriverFormCompanyData["address"]["country"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      address: {
        ...curr.address,
        country: value,
      },
    }));
  };

  const setCompanyDataAddressZipcode = (
    value: SignUpDriverFormCompanyData["address"]["zipCode"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      address: {
        ...curr.address,
        zipCode: value,
      },
    }));
  };

  const setCompanyDataAddressDescription = (
    value: SignUpDriverFormCompanyData["address"]["description"]
  ) => {
    setCompanyData((curr) => ({
      ...curr,
      address: {
        ...curr.address,
        description: value,
      },
    }));
  };

  const validateCompanyDataName = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateName(
        companyData.name
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      name: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataCompanyId = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateCompanyId(
        companyData.companyId
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      companyId: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataNationalCourtRegisterNumber = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateNationalCourtRegister(
        companyData.nationalCourtRegisterNumber
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      nationalCourtRegisterNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressStreet = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateAddressStreet(
        companyData.address.street
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      addressStreet: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressHouseNumber = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateAddressHouseNumber(
        companyData.address.houseNumber
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      addressHouseNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressApartmentNumber = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateAddressApartmentNumber(
        companyData.address.apartmentNumber
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      addressApartmentNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressTown = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateAddressTown(
        companyData.address.town
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      addressTown: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressCountry = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateAddressCountry(
        companyData.address.country
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      addressCountry: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressZipCode = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateAddressZipCode(
        companyData.address.zipCode
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      addressZipCode: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressDescription = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateAddressDescription(
        companyData.address.description
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      addressDescription: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataTaxNumber = () => {
    const validationResult =
      signUpDriverCompanyDataFormValidationService.validateTaxNumber(
        companyData.taxNumber
      );

    setCompanyDataValidationResults((curr) => ({
      ...curr,
      taxNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsCompanyDataAddressFormValid = () => {
    const isApartmentNumberValid = validateCompanyDataAddressApartmentNumber();
    const isCountryValid = validateCompanyDataAddressCountry();
    const isDescriptionValid = validateCompanyDataAddressDescription();
    const isHouseNumberValid = validateCompanyDataAddressHouseNumber();
    const isStreetValid = validateCompanyDataAddressStreet();
    const isTownValid = validateCompanyDataAddressTown();
    const isZipCodeValid = validateCompanyDataAddressZipCode();

    const isAddressFormValid =
      isApartmentNumberValid &&
      isCountryValid &&
      isDescriptionValid &&
      isHouseNumberValid &&
      isStreetValid &&
      isTownValid &&
      isZipCodeValid;

    return isAddressFormValid;
  };

  const checkIsCompanyDataFormValid = () => {
    const isCompanyNameValid = validateCompanyDataName();
    const isCompanyIdValid = validateCompanyDataCompanyId();
    const isTaxNumberValid = validateCompanyDataTaxNumber();
    const isNationalCourtRegisterNumberValid =
      validateCompanyDataNationalCourtRegisterNumber();

    const isAddressFormValid = checkIsCompanyDataAddressFormValid();

    const isFormValid =
      isCompanyNameValid &&
      isCompanyIdValid &&
      isTaxNumberValid &&
      isNationalCourtRegisterNumberValid &&
      isAddressFormValid;

    return isFormValid;
  };

  const onCompanyStepSubmitButtonClick = () => {
    const isFormValid = checkIsCompanyDataFormValid();

    if (!isFormValid) {
      return;
    }

    setPassedFormSteps((curr) => [...curr, SignUpDriverFormStep.companyData]);

    setCurrentFormStep(SignUpDriverFormStep.vehicleData);
  };

  // VEHICLE

  const setVehicleDataMake = (value: SignUpDriverFormVehicleData["make"]) => {
    setVehicleData((curr) => ({
      ...curr,
      make: value,
    }));
  };

  const setVehicleDataModel = (value: SignUpDriverFormVehicleData["model"]) => {
    setVehicleData((curr) => ({
      ...curr,
      model: value,
    }));
  };

  const setVehicleDataProductionYear = (
    value: SignUpDriverFormVehicleData["productionYear"]
  ) => {
    setVehicleData((curr) => ({
      ...curr,
      productionYear: value,
    }));
  };

  const setVehicleDataNumberOfSeats = (
    value: SignUpDriverFormVehicleData["numberOfSeats"]
  ) => {
    setVehicleData((curr) => ({
      ...curr,
      numberOfSeats: value,
    }));
  };

  const setVehicleDataRegistrationNumber = (
    value: SignUpDriverFormVehicleData["registrationNumber"]
  ) => {
    setVehicleData((curr) => ({
      ...curr,
      registrationNumber: value.toUpperCase(),
    }));
  };

  const setVehicleDataOwnerShip = (
    value: SignUpDriverFormVehicleData["ownership"]
  ) => {
    setVehicleData((curr) => ({
      ...curr,
      ownership: value,
    }));
  };

  // VEHICLE VALIDATION

  const validateVehicleDataMake = () => {
    const validationResult =
      signUpDriverVehicleFormValidationService.validateMake(vehicleData.make);

    setVehicleValidationResults((curr) => ({
      ...curr,
      make: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataModel = () => {
    const validationResult =
      signUpDriverVehicleFormValidationService.validateModel(vehicleData.model);

    setVehicleValidationResults((curr) => ({
      ...curr,
      model: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataProductionYear = () => {
    const validationResult =
      signUpDriverVehicleFormValidationService.validateProductionYear(
        vehicleData.productionYear
      );

    setVehicleValidationResults((curr) => ({
      ...curr,
      productionYear: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataNumberOfSeats = () => {
    const validationResult =
      signUpDriverVehicleFormValidationService.validateNumberOfSeats(
        vehicleData.numberOfSeats
      );

    setVehicleValidationResults((curr) => ({
      ...curr,
      numberOfSeats: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataRegistrationNumber = () => {
    const validationResult =
      signUpDriverVehicleFormValidationService.validateRegistrationNumber(
        vehicleData.registrationNumber
      );

    setVehicleValidationResults((curr) => ({
      ...curr,
      registrationNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataOwnerShip = (
    value: SignUpDriverFormVehicleData["ownership"] = vehicleData.ownership
  ) => {
    const validationResult =
      signUpDriverVehicleFormValidationService.validateOwnership(value);

    setVehicleValidationResults((curr) => ({
      ...curr,
      ownership: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsVehicleDataFormValid = () => {
    const isMakeValid = validateVehicleDataMake();
    const isModelValid = validateVehicleDataModel();
    const isProductionYearValid = validateVehicleDataProductionYear();
    const isNumberOfSeatsValid = validateVehicleDataNumberOfSeats();
    const isRegistrationNumberValid = validateVehicleDataRegistrationNumber();
    const isOwnershipValid = validateVehicleDataOwnerShip();

    const isFormValid =
      isMakeValid &&
      isModelValid &&
      isProductionYearValid &&
      isNumberOfSeatsValid &&
      isRegistrationNumberValid &&
      isOwnershipValid;

    return isFormValid;
  };

  const onVehicleStepSubmitButtonClick = () => {
    const isFormValid = checkIsVehicleDataFormValid();

    if (!isFormValid) {
      return;
    }

    setPassedFormSteps((curr) => [...curr, SignUpDriverFormStep.vehicleData]);
    setCurrentFormStep(SignUpDriverFormStep.summary);
  };

  return (
    <SignUpDriverContext.Provider
      value={{
        taxiCorporationId,
        setTaxiCorporationId,
        formSteps,
        currentFormStep,
        setCurrentFormStep,
        passedFormSteps,
        setPassedFormSteps,
        goToStep,
        accountData,
        setAccountDataEmail,
        isAccountDataEmailDisabled,
        disableAccountDataEmail,
        setAccountDataUsername,
        isAccountDataUsernameDisabled,
        disableAccountDataUsername,
        setAccountDataPassword,
        setAccountDataRepeatPassword,
        accountDataValidationResults,
        validateAccountDataEmail,
        validateAccountDataUsername,
        validateAccountDataPassword,
        validateAccountDataPasswordRepeat,
        submitAccountStep,
        setAccountDataValidationResults,
        userData,
        setUserDataFirstName,
        isUserDataFirstNameDisabled,
        disableUserDataFirstName,
        setUserDataLastName,
        isUserDataLastNameDisabled,
        disableUserDataLastName,
        setUserDataMobile,
        isUserDataMobileNumberDisabled,
        disableUserDataMobileNumber,
        setUserDataAlternativeMobile,
        isUserDataAlternativeMobileNumberDisabled,
        disableUserDataAlternativeMobileNumber,
        setUserDataBirthDate,
        setUserDataBirthPlace,
        setUserDataPersonalIdNumber,
        setUserDataCitizenship,
        setUserDataLanguages,
        setUserDataExperience,
        setUserDataFormOfEmployment,
        addUserDataAddress,
        removeUserDataAddress,
        userDataValidationResults,
        validateUserDataFirstName: validateUserDataFirstname,
        validateUserDataLastname,
        validateUserDataMobile,
        validateUserDataAlternativeMobile,
        validateUserDataBirthDate,
        validateUserDataBirthPlace,
        validateUserDataPersonalIdNumber,
        validateUserDataCitizenship,
        validateUserDataLanguages,
        validateUserDataYearsOfExperience,
        validateUserDataFormOfEmployment,
        validateUserDataAddresses,
        setUserDataValidationResults,
        submitUserStep,
        companyData,
        setCompanyDataName,
        setCompanyDataCompanyId,
        setCompanyDataTaxNumber,
        setCompanyDataNationalCourtRegisterNumber,
        setCompanyDataAddressStreet,
        setCompanyDataAddressHouseNumber,
        setCompanyDataAddressApartmentNumber,
        setCompanyDataAddressTown,
        setCompanyDataAddressCountry,
        setCompanyDataAddressZipcode,
        setCompanyDataAddressDescription,
        companyDataValidationResults,
        validateCompanyDataName,
        validateCompanyDataNationalCourtRegisterNumber,
        validateCompanyDataCompanyId,
        validateCompanyDataTaxNumber,
        validateCompanyDataAddressStreet,
        validateCompanyDataAddressHouseNumber,
        validateCompanyDataAddressApartmentNumber,
        validateCompanyDataAddressTown,
        validateCompanyDataAddressCountry,
        validateCompanyDataAddressZipCode,
        validateCompanyDataAddressDescription,
        onCompanyStepSubmitButtonClick,
        vehicleData,
        setVehicleDataMake,
        setVehicleDataModel,
        setVehicleDataProductionYear,
        setVehicleDataNumberOfSeats,
        setVehicleDataRegistrationNumber,
        setVehicleDataOwnerShip,
        vehicleValidationResults,
        validateVehicleDataMake,
        validateVehicleDataModel,
        validateVehicleDataProductionYear,
        validateVehicleDataNumberOfSeats,
        validateVehicleDataRegistrationNumber,
        validateVehicleDataOwnerShip,
        onVehicleStepSubmitButtonClick,
      }}
    >
      {children}
    </SignUpDriverContext.Provider>
  );
};

export default SignUpDriverContextProvider;
