import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-sort-definition";
import routeTranslationsHelper from "../../../../languages/route-translations.helper";
import RouteActiveListingFilter, {
  RouteActiveListingCargoOrderHumanIdFilter,
  RouteActiveListingDriverFilter,
  RouteActiveListingPassengerFilter,
  RouteActiveListingRouteAddressFilter,
  RouteActiveListingHumanIdFilter,
  RouteActiveListingTaxiCorporationFilter,
} from "../common/types/route-active-listing-filter";
import RouteActiveListingFilterType from "../common/types/route-active-listing-filter-type";
import RouteActiveListingSortKey from "../common/types/route-active-listing-sort-key";

const routeActiveListingFilterHelper = () => {
  const translations = routeTranslationsHelper.getActiveListingTranslations();

  const getSelectDefinitionForCargoOrderHumanId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label:
            translations.filters.search.cargoOrderHumanIdTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDefinitionForDriver = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.driverTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForPassenger = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.passengerTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForRouteAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.routeAddressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForHumanId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label: translations.filters.search.humanIdTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDefinitionForTaxiCorporation = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.taxiCorporationTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: RouteActiveListingFilterType,
    query: string
  ) => {
    const options: Record<
      RouteActiveListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [RouteActiveListingFilterType.CARGO_ORDER_HUMAN_ID]:
        getSelectDefinitionForCargoOrderHumanId,
      [RouteActiveListingFilterType.DRIVER]: getSelectDefinitionForDriver,
      [RouteActiveListingFilterType.PASSENGER]: getSelectDefinitionForPassenger,
      [RouteActiveListingFilterType.ROUTE_ADDRESS]:
        getSelectDefinitionForRouteAddress,
      [RouteActiveListingFilterType.HUMAN_ID]: getSelectDefinitionForHumanId,
      [RouteActiveListingFilterType.TAXI_CORPORATION]:
        getSelectDefinitionForTaxiCorporation,
    };

    const result = options[filterType](query);

    return result;
  };

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForCargoOrderHumanId = (
    filterValue: RouteActiveListingCargoOrderHumanIdFilter["value"]
  ) => {
    return createBadgeData(
      String(filterValue),
      translations.filters.cargoOrderHumanIdLabel,
      translations.filters.cargoOrderHumanIdTitle
    );
  };

  const getBadgeForDriver = (
    filterValue: RouteActiveListingDriverFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.driverLabel,
      translations.filters.driverTitle
    );
  };

  const getBadgeForPassenger = (
    filterValue: RouteActiveListingPassengerFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.passengerLabel,
      translations.filters.passengerTitle
    );
  };

  const getBadgeForRouteAddress = (
    filterValue: RouteActiveListingRouteAddressFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.routeAddressLabel,
      translations.filters.routeAddressTitle
    );
  };

  const getBadgeForHumanId = (
    filterValue: RouteActiveListingHumanIdFilter["value"]
  ) => {
    return createBadgeData(
      String(filterValue),
      translations.filters.humanIdLabel,
      translations.filters.humanIdTitle
    );
  };

  const getBadgeForTaxiCorporation = (
    filterValue: RouteActiveListingTaxiCorporationFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.taxiCorporationLabel,
      translations.filters.taxiCorporationTitle
    );
  };

  const getBadgeDefinitionByType = (
    filterType: RouteActiveListingFilterType,
    filterValue: RouteActiveListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      RouteActiveListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [RouteActiveListingFilterType.CARGO_ORDER_HUMAN_ID]: () =>
        getBadgeForCargoOrderHumanId(
          filterValue as RouteActiveListingCargoOrderHumanIdFilter["value"]
        ),
      [RouteActiveListingFilterType.DRIVER]: () =>
        getBadgeForDriver(
          filterValue as RouteActiveListingDriverFilter["value"]
        ),
      [RouteActiveListingFilterType.PASSENGER]: () =>
        getBadgeForPassenger(
          filterValue as RouteActiveListingPassengerFilter["value"]
        ),
      [RouteActiveListingFilterType.ROUTE_ADDRESS]: () =>
        getBadgeForRouteAddress(
          filterValue as RouteActiveListingRouteAddressFilter["value"]
        ),
      [RouteActiveListingFilterType.HUMAN_ID]: () =>
        getBadgeForHumanId(
          filterValue as RouteActiveListingHumanIdFilter["value"]
        ),
      [RouteActiveListingFilterType.TAXI_CORPORATION]: () =>
        getBadgeForTaxiCorporation(
          filterValue as RouteActiveListingTaxiCorporationFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: RouteActiveListingFilterType
  ): ListingFilterDefinitionOption<RouteActiveListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<RouteActiveListingFilter>[] => {
      return Object.values(RouteActiveListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition = (
    hasAccessToTaxiCorporation: boolean
  ): ListingFilterDefinition<RouteActiveListingFilter> => {
    return {
      options: getFilterDefinitionOptions().filter((option) =>
        !hasAccessToTaxiCorporation
          ? option.filterType !== RouteActiveListingFilterType.TAXI_CORPORATION
          : true
      ),
    };
  };

  const getSortDefinitionOptions = (
    hasAccessToTaxiCorporation: boolean
  ): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.driverAscLabel,
        value: RouteActiveListingSortKey.DRIVER_NAME_ASC,
      },
      {
        label: translations.sort.driverDescLabel,
        value: RouteActiveListingSortKey.DRIVER_NAME_DESC,
      },
      {
        label: translations.sort.humanIdAscLabel,
        value: RouteActiveListingSortKey.HUMAN_ID_ASC,
      },
      {
        label: translations.sort.humanIdDescLabel,
        value: RouteActiveListingSortKey.HUMAN_ID_DESC,
      },
      ...(hasAccessToTaxiCorporation
        ? [
            {
              label: translations.sort.taxiCorporationAscLabel,
              value: RouteActiveListingSortKey.TAXI_CORPORATION_ASC,
            },
            {
              label: translations.sort.taxiCorporationDescLabel,
              value: RouteActiveListingSortKey.TAXI_CORPORATION_DESC,
            },
          ]
        : []),
    ];
  };

  const getSortDefinition = (
    hasAccessToTaxiCorporation: boolean
  ): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(hasAccessToTaxiCorporation),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default routeActiveListingFilterHelper;
