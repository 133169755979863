import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import {
  TransportingOrderDeleteForwardingErrorType,
  TransportingOrderDeleteForwardingAbandonedOrderError,
  TransportingOrderDeleteForwardingDriverAssignedError,
} from "./transporting-order-delete-forwarding-error";

const createAbandonedOrderError =
  (): TransportingOrderDeleteForwardingAbandonedOrderError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().cancelForwarding;

    return {
      message: translations.cancelForwardingAbandonedOrderError,
      params: {},
      type: TransportingOrderDeleteForwardingErrorType.ABANDONED_ORDER,
    };
  };

const createDriverAssignedError =
  (): TransportingOrderDeleteForwardingDriverAssignedError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().cancelForwarding;

    return {
      message: translations.cancelForwardingDriverAssignedError,
      params: {},
      type: TransportingOrderDeleteForwardingErrorType.DRIVER_ASSIGNED,
    };
  };

const transportingOrderDeleteForwardingErrorFactory = {
  createAbandonedOrderError,
  createDriverAssignedError,
};

export default transportingOrderDeleteForwardingErrorFactory;
