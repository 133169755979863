import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { BillingsPlanEntryCargoTaxiItem } from "../../../../../../common/services/billings/plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi";
import LinkButtonComponent from "../../../../../../common/components/button/link/link-button.component";
import dateService from "../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import RelatedBillingsCargoTaxiTableRow from "../../../../common/related-billings/cargo-taxi/types/related-billings-cargo-taxi-table-row";
import billingRoutesHelper from "../../../../common/routes/billing-routes.helper";
import BillingsTableStatusComponent from "../../../../common/table/billings-table-status.component";

const getTableRow = (
  billingData: BillingsPlanEntryCargoTaxiItem
): RelatedBillingsCargoTaxiTableRow => {
  const translations =
    billingsTranslationsHelper.getBillingsTranslations().relatedBillings
      .cargo_taxi.table;

  return {
    id: billingData.internalOrderId.toString(),
    value: {
      billingStatus: (
        <BillingsTableStatusComponent status={billingData.status} />
      ),
      date: (
        <div title={dateService.format(billingData.date, "dd/mm/yyyy")}>
          {dateService.format(billingData.date, "dd/mm/yyyy")}
        </div>
      ),
      internalOrderId: (
        <div title={billingData.internalOrderId.toString()}>
          {billingData.internalOrderId}
        </div>
      ),
      companyName: (
        <div title={billingData.contractDetails?.companyName}>
          {billingData.contractDetails?.companyName}
        </div>
      ),
      model: (
        <div title={billingData.contractDetails?.model}>
          {billingData.contractDetails?.model}
        </div>
      ),
      rate: (
        <div title={billingData.contractDetails?.distanceRate.toString()}>
          {billingData.contractDetails?.distanceRate.toFixed(2)} PLN
        </div>
      ),
      distance: (
        <div title={billingData.distance.toString()}>
          {billingData.distance}
        </div>
      ),
      amountForDistance: (
        <div title={billingData.amountForDistance?.toString()}>
          {billingData.amountForDistance?.toFixed(2)} PLN
        </div>
      ),
      contractCorrectionPercentage: (
        <div title={billingData.contractCorrectionPercentage?.toString()}>
          {billingData.contractCorrectionPercentage?.toFixed(2)}%
        </div>
      ),
      sumOfDiscounts: (
        <div title={billingData.sumOfDiscounts?.toString()}>
          {billingData.sumOfDiscounts?.toFixed(2)} PLN
        </div>
      ),
      amountForChargeHaltings: (
        <div title={billingData.amountForChargeHaltings?.toString()}>
          {billingData.amountForChargeHaltings?.toFixed(2)} PLN
        </div>
      ),
      amountForChargeHighways: (
        <div title={billingData.amountForChargeHighways?.toString()}>
          {billingData.amountForChargeHighways?.toFixed(2)} PLN
        </div>
      ),
      allContributionsAmount: (
        <div title={billingData.allContributionsAmount?.toString()}>
          {billingData.allContributionsAmount?.toFixed(2)} PLN
        </div>
      ),
      actions: (
        <LinkButtonComponent
          type="primary"
          openInNewTab
          title={
            billingData.isDraft
              ? translations.addActionLabel
              : translations.viewActionLabel
          }
          to={
            billingData.isDraft
              ? billingRoutesHelper.getCargoWithTaxiAddRoute({
                  orderUuid: billingData.cargoOrderUuid,
                })
              : billingRoutesHelper.getCargoWithTaxiDetailsRoute({
                  billingUuid: billingData.billingUuid,
                })
          }
          classNames={{
            root: "related_billings_table_icon",
          }}
        >
          <FontAwesomeIcon icon={billingData.isDraft ? faAdd : faCircleInfo} />
        </LinkButtonComponent>
      ),
    },
  };
};

const createTableRows = (
  listingItems: BillingsPlanEntryCargoTaxiItem[]
): RelatedBillingsCargoTaxiTableRow[] => {
  return listingItems.map((listingItem) => getTableRow(listingItem));
};

const realtedBillingsCargoTaxiFactory = {
  createTableRows,
};

export default realtedBillingsCargoTaxiFactory;
