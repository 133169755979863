import MileageListingItem from "../../../listing/common/types/mileage-listing-item";
import MileageDetailsInternal from "../types/mileage-details-internal";

const create = (details: MileageListingItem): MileageDetailsInternal => {
  return {
    humanId: details.humanId,
    mileageUuid: details.uuid,
    status: details.status,
    distance: details.distance,
    osrmDistance: details.osrmDistance,
    acceptedDistance: details.acceptedDistance ?? null,
  };
};

const mileageDetailsInternalFactory = {
  create,
};

export default mileageDetailsInternalFactory;
