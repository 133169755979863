import RouteCompletedRouteRequest from "../../../api/raily/route/completed-route/route-completed-route.request";
import RouteCompletedRouteLoadParams from "./route-completed-route-load-params";

const create = (
  params: RouteCompletedRouteLoadParams
): RouteCompletedRouteRequest => {
  return {
    planEntryId: params.planEntryUuid,
  };
};

const routeCompletedRouteRequestFactory = {
  create,
};

export default routeCompletedRouteRequestFactory;
