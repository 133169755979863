import isEqual from "lodash/isEqual";
import TaxiCargoContractListingFilterSelectOption, {
  TaxiCargoContractListingBillingModelFilterSelectOption,
} from "../types/taxi-cargo-contract-listing-filter-select-option";
import taxiTranslationsHelper from "../../../../../../languages/taxi-translations.helper";
import TaxiCargoContractListingBillingModel from "../types/taxi-cargo-contract-listing-billing-model";
import TaxiCargoContractListingFilterType from "../types/taxi-cargo-contract-listing-filter-type";
import TaxiCargoContractListingFilter from "../types/taxi-cargo-contract-listing-filter";

const getSearchBillingModelOptions = (
  query: string
): TaxiCargoContractListingBillingModelFilterSelectOption[] => {
  const searchFilterTranslations =
    taxiTranslationsHelper.getCargoContractListingTranslations().filters
      .search;

  const allBillingModelSelectOptions: TaxiCargoContractListingBillingModelFilterSelectOption[] =
    Object.values(TaxiCargoContractListingBillingModel).map((billingModel) => {
      const billingModelLabel =
        searchFilterTranslations.searchByBillingModelTemplateText.replace(
          "#{option}",
          billingModel
        );

      return {
        label: billingModelLabel,
        value: {
          type: TaxiCargoContractListingFilterType.BILLING_MODEL,
          value: billingModel,
        },
      };
    });

  if (query) {
    return allBillingModelSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allBillingModelSelectOptions;
};

const getSearchQueryOptions = (
  query: string
): TaxiCargoContractListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const translations =
    taxiTranslationsHelper.getCargoContractListingTranslations().filters
      .search;

  const getSearchByCargoCompanyQueryLabel = (query: string) => {
    return translations.searchByCargoCompanyTemplateText.replace(
      `#{query}`,
      query
    );
  };

  return [
    {
      label: getSearchByCargoCompanyQueryLabel(query),
      value: {
        type: TaxiCargoContractListingFilterType.CARGO_COMPANY,
        value: query,
      },
    },
  ];
};

const getFilterOptions = (
  query: string
): TaxiCargoContractListingFilterSelectOption[] => {
  const billingModelOptions = getSearchBillingModelOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...billingModelOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: TaxiCargoContractListingFilter[]
): TaxiCargoContractListingFilterSelectOption[] => {
  const allFilterOptions = getFilterOptions(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const taxiCargoContractListingFilterHelper = { getSelectOptions };

export default taxiCargoContractListingFilterHelper;
