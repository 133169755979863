export enum MileageContractListQueryParamsOrder {
  COMPANY_NAME_ASC = "COMPANY_NAME_ASC",
  COMPANY_NAME_DESC = "COMPANY_NAME_DESC",
  VALID_SINCE_ASC = "VALID_SINCE_ASC",
  VALID_SINCE_DESC = "VALID_SINCE_DESC",
  VALID_TO_ASC = "VALID_TO_ASC",
  VALID_TO_DESC = "VALID_TO_DESC",
}

export type MileageContractListQueryParams = {
  offset: number | undefined;
  limit: number | undefined;
  isValid: boolean | undefined;
  cargoCompanyId: string | undefined;
  cargoCompanyName: string | undefined;
  order: MileageContractListQueryParamsOrder | undefined;
};

type MileageContractListRequest = {
  page?: number;
  pageSize?: number;
  isValid?: boolean;
  cargoCompanyUuid?: string;
  cargoCompanyName?: string;
  order?: MileageContractListQueryParamsOrder;
};

export default MileageContractListRequest;
