import { FC, useEffect, useState } from "react";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import useAbort from "../../../../common/hooks/use-abort";
import { useAppContext } from "../../../../context/app.context";
import TaxiFleetPartnerEditRouteParams from "../../common/routes/types/taxi-fleet-partner-edit-route-params";
import { useNavigate, useParams } from "react-router-dom";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import useForm from "../../../../common/components/form/use-form";
import TaxiFleetPartnerEditFormData from "./common/types/taxi-fleet-partner-edit-form-data";
import taxiFleetPartnerEditFormHelper from "./common/taxi-fleet-partner-edit-form.helper";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import taxiFleetPartnerUpdateParamsFactory from "./common/taxi-fleet-partner-update-params.factory";
import notificationService from "../../../../common/utils/notification/notification.service";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import ButtonComponent from "../../../../common/components/button/button.component";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import TaxiFleetPartnerUpdateParams from "../../../../common/services/taxi-fleet-partner/update/taxi-fleet-partner-update-params";
import taxiFleetPartnerService from "../../../../common/services/taxi-fleet-partner/taxi-fleet-partner.service";
import useTaxiFleetPartnerDetails from "../../../../common/services/taxi-fleet-partner/details/use-taxi-fleet-partner-details";

type TaxiFleetPartnerEditProps = {};

const TaxiFleetPartnerEditComponent: FC<TaxiFleetPartnerEditProps> = () => {
  const translations =
    taxiTranslationsHelper.getTaxiFleetPartnerEditTranslations();

  const taxiFleetPartnerEditAbort = useAbort();

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { taxiCorporationUuid, fleetPartnerUuid } =
    useParams<TaxiFleetPartnerEditRouteParams>();

  const navigate = useNavigate();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const taxiFleetPartnerDetailsAbort = useAbort();
  const taxiFleetPartnerDetails = useTaxiFleetPartnerDetails();

  useEffect(() => {
    if (!fleetPartnerUuid) {
      return;
    }

    taxiFleetPartnerDetails.load(
      {
        fleetPartnerUuid,
      },
      taxiFleetPartnerDetailsAbort.signal
    );
  }, []);

  const [isFleetPartnerUpdatePending, setIsFleetPartnerUpdatePending] =
    useState(false);

  useEffect(() => {
    if (
      !taxiCorporationUuid ||
      !fleetPartnerUuid ||
      !taxiFleetPartnerDetails.data
    ) {
      return;
    }

    const breadcrumbs =
      taxiBreadcrumbsHelper.getTaxiFleetPartnerEditBreadcrumbs({
        taxiCorporationUuid,
        taxiFleetPartnerUuid: fleetPartnerUuid,
        taxiFleetPartnerName: taxiFleetPartnerDetails.data.name,
      });

    setBreadcrumbs(breadcrumbs);
  }, [
    fleetPartnerUuid,
    taxiCorporationUuid,
    selectedAppLanguage,
    taxiFleetPartnerDetails.data,
  ]);

  useDocumentTitle(
    documentTitleTranslations.taxiFleetPartnerEdit.replace(
      "#{taxiFleetPartnerName}",
      taxiFleetPartnerDetails.data?.name ?? ""
    )
  );

  const form = useForm<TaxiFleetPartnerEditFormData>({
    emptyValues: taxiFleetPartnerEditFormHelper.getEmptyFormData(),
    validationDefinition:
      taxiFleetPartnerEditFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    if (!taxiFleetPartnerDetails.data) {
      return;
    }

    form.setValues({
      name: taxiFleetPartnerDetails.data.name,
    });
  }, [taxiFleetPartnerDetails.data]);

  const navigateToListing = () => {
    const taxiFleetPartnerListingRoute =
      taxiRoutesHelper.getFleetPartnerListingRoute({
        taxiCorporationUuid: taxiCorporationUuid!,
      });

    navigate(taxiFleetPartnerListingRoute);
  };

  const onFleetPartnerEditSuccess = () => {
    notificationService.success(translations.successEditNotificationLabel);
    navigateToListing();
  };

  const onFleetPartnerEditFailure = () => {
    notificationService.error(translations.failureEditNotificationLabel);
  };

  const editTaxiFleetPartner = async () => {
    setIsFleetPartnerUpdatePending(true);

    const params: TaxiFleetPartnerUpdateParams =
      taxiFleetPartnerUpdateParamsFactory.create(
        form.values!,
        fleetPartnerUuid!,
        taxiCorporationUuid!
      );

    try {
      await taxiFleetPartnerService.update(
        params,
        taxiFleetPartnerEditAbort.signal
      );

      onFleetPartnerEditSuccess();
    } catch {
      onFleetPartnerEditFailure();
    } finally {
      setIsFleetPartnerUpdatePending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    editTaxiFleetPartner();
  };

  return (
    <>
      <HeadingComponent
        title={taxiFleetPartnerEditFormHelper.getHeadingLabel(
          taxiFleetPartnerDetails.data?.name
        )}
      />
      <CardComponent isLoading={taxiFleetPartnerDetails.isLoading}>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.nameLabel}
              isRequired
              errorMessage={form.validationResults.name.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.namePlaceholder}
                value={form.values.name}
                onChange={(value) => form.setValue("name", value)}
                onBlur={() => form.validate("name")}
                hasError={!!form.validationResults.name.errorMessage}
                idForTesting="taxi-fleet-partner-edit-name"
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isDisabled={isFleetPartnerUpdatePending}
        classNames={{ root: "mt-4" }}
        idForTesting="taxi-fleet-partner-edit-submit-button"
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default TaxiFleetPartnerEditComponent;
