import Joi from "joi";
import formValidationService from "../../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";

const validateHaltingTime = (
  haltingTimeMinutes: number | null
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .max(720)
    .allow(null)
    .integer()
    .required();

  return formValidationService.validate(haltingTimeMinutes, validationSchema);
};

const validateHighwayCost = (
  highwayCost: number | null
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(9999).allow(null).required();

  return formValidationService.validate(highwayCost, validationSchema);
};

const driverDetailsPlanCheckoutFormValidationService = {
  validateHaltingTime,
  validateHighwayCost,
};

export default driverDetailsPlanCheckoutFormValidationService;
