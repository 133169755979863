import UserRole from "../../../../common/types/user-role";
import UserPermissions from "../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../common/utils/user/permissions/user-permissions-definition";

export type MileageDetailsUserPermissionOption =
  | "hasAccessToEditMileage"
  | "hasAccessToRejectMileage"
  | "hasAccessToAcceptMileage";

export type MileageDetailsUserPermissionsDefinition =
  UserPermissionsDefinition<MileageDetailsUserPermissionOption>;

export type MileageDetailsUserPermissions =
  UserPermissions<MileageDetailsUserPermissionOption>;

const mileageDetailsUserPermissionDefinition: MileageDetailsUserPermissionsDefinition =
  {
    hasAccessToEditMileage: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
    hasAccessToRejectMileage: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
    hasAccessToAcceptMileage: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  };

export default mileageDetailsUserPermissionDefinition;
