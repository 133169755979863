import appConfig from "../../../../../../../app.config";
import urlService from "../../../../../../../common/utils/url/url.service";
import authService from "../../../../../../auth/common/auth.service";
import TaxiUnsettledOrderListingByRailyRequest from "./taxi-unsettled-order-listing-by-raily.request";
import TaxiUnsettledOrderListingByRailyResponse from "./taxi-unsettled-order-listing-by-raily.response";

const fetchListing = async (
  request: TaxiUnsettledOrderListingByRailyRequest
): Promise<TaxiUnsettledOrderListingByRailyResponse> => {
  const path = `${appConfig.baseApiUrl}/orders/finished-cargo-orders/not-yet-billed`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const taxiUnsettledOrderListingByRailyApiService = {
  fetchListing,
};

export default taxiUnsettledOrderListingByRailyApiService;
