import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import {
  OrderCancelRequestErrorType,
  OrderCancelRequestedError,
  OrderCancelledError,
  OrderFinishedError,
} from "./order-cancel-request-error";

const createOrderCancelError = (): OrderCancelledError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrderRequest.errors;

  return {
    message: translations.orderCancelledError,
    params: {},
    type: OrderCancelRequestErrorType.ORDER_CANCELLED,
  };
};

const createOrderCancelRequestError = (): OrderCancelRequestedError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrderRequest.errors;

  return {
    message: translations.orderCancelRequestedError,
    params: {},
    type: OrderCancelRequestErrorType.ORDER_CANCEL_REQUESTED,
  };
};

const createOrderFinishedError = (): OrderFinishedError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrderRequest.errors;

  return {
    message: translations.orderFinishedError,
    params: {},
    type: OrderCancelRequestErrorType.ORDER_FINISHED,
  };
};

const OrderCancelRequestErrorFactory = {
  createOrderCancelError,
  createOrderCancelRequestError,
  createOrderFinishedError,
};

export default OrderCancelRequestErrorFactory;
