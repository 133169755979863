import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryRemoveCargoExitChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";

type OrderDetailsHistoryEntryChangeRideContentRemoveCargoExitProps = {
  event: OrderDetailsHistoryEntryRemoveCargoExitChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentRemoveCargoExitComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentRemoveCargoExitProps
> = () => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .removeCargoExit;

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentRemoveCargoExitComponent;
