import TaxiAddFormData from "../../common/types/taxi-add-form-data";

const getDefaultFormData = (): TaxiAddFormData => {
  return {
    taxId: "",
    companyId: "",
    nationalCourtRegister: "",
    companyName: "",
    displayName: "",
    address: "",
    contractMaintenancePolicy: undefined,
    notes: "",
  };
};

const taxiAddFormHelper = {
  getDefaultFormData,
};

export default taxiAddFormHelper;
