import UserRole from "../../../../common/types/user-role";
import userPermissionsService from "../../../../common/utils/user/permissions/user-permissions.service";
import mileageDetailsUserPermissionDefinition, {
  MileageDetailsUserPermissions,
} from "./mileage-details-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): MileageDetailsUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    mileageDetailsUserPermissionDefinition
  );

  return permissions;
};

const mileageDetailsUserPermissionsHelper = {
  getPermissions,
};

export default mileageDetailsUserPermissionsHelper;
