import appTranslationsHelper from "../../../languages/app-translations.helper";
import StatusLegendStatuses from "./types/status-legend-statuses";

const getStatusLegendLabel = (status: string): string | undefined => {
  const translations =
    appTranslationsHelper.getComponentTranslations().statusLegend.statuses;

  switch (status) {
    case StatusLegendStatuses.PLANNED:
      return translations.plannedLabel;
    case StatusLegendStatuses.CANDIDATURE:
      return translations.candidatureLabel;
    case StatusLegendStatuses.IN_PROGRESS:
      return translations.inProgressLabel;
    case StatusLegendStatuses.PENDING:
      return translations.pendingLabel;
    case StatusLegendStatuses.ACCEPTED:
      return translations.acceptedLabel;
    case StatusLegendStatuses.CANCELLED:
      return translations.cancelledLabel;
    case StatusLegendStatuses.CREATED:
      return translations.createdLabel;
    case StatusLegendStatuses.FINISHED:
      return translations.finishedLabel;
    case StatusLegendStatuses.REJECTED:
      return translations.rejectedLabel;
    case StatusLegendStatuses.REOPENED:
      return translations.reopenedLabel;
    case StatusLegendStatuses.REOPEN_REQUEST:
      return translations.reopenRequestLabel;
    case StatusLegendStatuses.REOPEN_REQUEST_SENT:
      return translations.reopenRequestLabel;
    case StatusLegendStatuses.CURRENT_PLAN:
      return translations.currentPlanLabel;
    case StatusLegendStatuses.ORDER:
      return translations.orderLabel;
    case StatusLegendStatuses.POTENTIAL_PLAN:
      return translations.potentialPlanLabel;
    case StatusLegendStatuses.PROPOSED_POTENTIAL_PLAN:
      return translations.proposedPotentialPlanLabel;
    case StatusLegendStatuses.CREATED_TOTAL:
      return translations.createdLabel;
    case StatusLegendStatuses.DELEGATION_CREATED:
      return translations.delegationCreatedLabel;
    case StatusLegendStatuses.DOWNLOADED:
      return translations.downloadedLabel;
    case StatusLegendStatuses.UNDOWNLOADED:
      return translations.undownloadedLabel;
    case StatusLegendStatuses.DELETED:
      return translations.deletedLabel;
  }
};

const getStatusLegendClassName = (status: string): string | undefined => {
  switch (status) {
    case StatusLegendStatuses.PLANNED:
      return "status_legend_item_planned";
    case StatusLegendStatuses.CANDIDATURE:
      return "status_legend_item_canidature";
    case StatusLegendStatuses.IN_PROGRESS:
      return "status_legend_item_in_progress";
    case StatusLegendStatuses.PENDING:
      return "status_legend_item_pending";
    case StatusLegendStatuses.ACCEPTED:
      return "status_legend_item_accepted";
    case StatusLegendStatuses.CANCELLED:
      return "status_legend_item_cancelled";
    case StatusLegendStatuses.CREATED:
      return "status_legend_item_created";
    case StatusLegendStatuses.FINISHED:
      return "status_legend_item_finished";
    case StatusLegendStatuses.REJECTED:
      return "status_legend_item_rejected";
    case StatusLegendStatuses.REOPENED:
      return "status_legend_item_reopened";
    case StatusLegendStatuses.REOPEN_REQUEST:
      return "status_legend_item_reopen_request";
    case StatusLegendStatuses.REOPEN_REQUEST_SENT:
      return "status_legend_item_reopen_request";
    case StatusLegendStatuses.CURRENT_PLAN:
      return "status_legend_item_current_plan";
    case StatusLegendStatuses.ORDER:
      return "status_legend_item_order";
    case StatusLegendStatuses.POTENTIAL_PLAN:
      return "status_legend_item_potential_plan";
    case StatusLegendStatuses.PROPOSED_POTENTIAL_PLAN:
      return "status_legend_item_proposed_potential_plan";
    case StatusLegendStatuses.CREATED_TOTAL:
      return "status_legend_item_created";
    case StatusLegendStatuses.DELEGATION_CREATED:
      return "status_legend_item_delegation_created";
    case StatusLegendStatuses.UNDOWNLOADED:
      return "status_legend_item_undownloaded";
    case StatusLegendStatuses.DOWNLOADED:
      return "status_legend_item_downloaded";
    case StatusLegendStatuses.DELETED:
      return "status_legend_item_deleted";
  }
};

const statusLegendHelper = {
  getStatusLegendLabel,
  getStatusLegendClassName,
};

export default statusLegendHelper;
