import { FC, useState, useEffect, useMemo } from "react";
import cargoOrderSettlementListingHelper from "../cargo-order-settlement-listing.helper";
import cargoOrderSettlementListingApiService from "../api/cargo-order-settlement-listing-api.service";
import CargoOrderSettlementListingStatusCountersResponse, {
  CargoOrderSettlementListingStatusCountersResponseData,
} from "../api/cargo-order-settlement-listing-status-counters.response";
import StatusLegendComponent from "../../../../../../common/components/status-legend/status-legend.component";

type CargoOrderSettlementListingStatusLegendProps = {
  cargoCompanyUuid: string | null;
};

const CargoOrderSettlementListingStatusLegendComponent: FC<
  CargoOrderSettlementListingStatusLegendProps
> = (props) => {
  const [statusCount, setStatusCount] = useState<
    CargoOrderSettlementListingStatusCountersResponseData | undefined
  >();

  useEffect(() => {
    cargoOrderSettlementListingApiService
      .fetchStatusCounters({
        cargo_company_id: props.cargoCompanyUuid ?? undefined,
      })
      .then(handleStatusCountersResponse);
  }, [props.cargoCompanyUuid]);

  const handleStatusCountersResponse = (
    response: CargoOrderSettlementListingStatusCountersResponse
  ) => {
    setStatusCount(
      response.data as CargoOrderSettlementListingStatusCountersResponseData
    );
  };

  const statusOptions = useMemo(
    () => cargoOrderSettlementListingHelper.getStatusOptions(statusCount!),
    [statusCount]
  );

  return <StatusLegendComponent statusData={statusOptions} />;
};

export default CargoOrderSettlementListingStatusLegendComponent;
