import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import OrderAbandonedListingComponent from "../../abandoned-listing/order-abandoned-listing.component";
import OrderActiveListingComponent from "../../active-listing/order-active-listing.component";
import OrderAddComponent from "../../add-new/order-add.component";
import OrderFinishedListingComponent from "../../finished-listing/order-finished-listing.component";
import OrderJoinComponent from "../../join/order-join.component";
import OrderRouteEditComponent from "../../route-edit/order-route-edit.component";
import orderRoutesUrls from "./order-routes-urls";

const orderRoutesDefinition: RouteItemDefinition[] = [
  {
    path: orderRoutesUrls.add,
    component: <OrderAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: orderRoutesUrls.listingOfAbandonedOrders,
    component: <OrderAbandonedListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.OPERATOR,
      UserRole.DEALER,
      UserRole.DISPATCHER,
      UserRole.CARGO_OFFICER,
    ],
  },
  {
    path: orderRoutesUrls.listingOfActiveOrders,
    component: <OrderActiveListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: orderRoutesUrls.listingOfFinishedOrders,
    component: <OrderFinishedListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: orderRoutesUrls.routeEdit,
    component: <OrderRouteEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: orderRoutesUrls.join,
    component: <OrderJoinComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.OPERATOR],
  },
];

export default orderRoutesDefinition;
