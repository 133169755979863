import TaxiTaxiContractListRequest, {
  TaxiContractListRequestQueryParams,
} from "./list/taxi-taxi-contract-list.request";
import TaxiTaxiContractListResponse from "./list/taxi-taxi-contract-list.response";
import taxiTaxiContractListRequestFactory from "./list/taxi-taxi-contract-list-request.factory";
import railyApiService from "../raily-api.service";
import TaxiTaxiContractAddRequest, {
  TaxiTaxiContractAddRequestBody,
} from "./add/taxi-taxi-contract-add.request";
import taxiTaxiContractAddRequestFactory from "./add/taxi-taxi-contract-add-request.factory";
import TaxiTaxiContractAddResponse from "./add/taxi-taxi-contract-add.response";
import TaxiTaxiContractUpdateRequest, {
  TaxiTaxiContractUpdateRequestBody,
} from "./update/taxi-taxi-contract-update.request";
import TaxiTaxiContractUpdateResponse from "./update/taxi-taxi-contract-update.response";
import taxiTaxiContractUpdateRequestFactory from "./update/taxi-taxi-contract-update-request.factory";
import TaxiTaxiContractDetailsRequest from "./details/taxi-taxi-contract-details.request";
import TaxiTaxiContractDetailsResponse from "./details/taxi-taxi-contract-details.response";

const taxiTaxiContractApiService = () => {
  const add = (
    request: TaxiTaxiContractAddRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiTaxiContractAddResponse> => {
    const url = `/contracts/taxi-forwarding`;

    const body: TaxiTaxiContractAddRequestBody =
      taxiTaxiContractAddRequestFactory.createBody(request);

    return railyApiService().post<TaxiTaxiContractAddResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const getList = async (
    request: TaxiTaxiContractListRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiTaxiContractListResponse> => {
    const url = `/contracts/taxi-forwarding`;

    const queryParams: TaxiContractListRequestQueryParams =
      taxiTaxiContractListRequestFactory.createQueryParams(request);

    return railyApiService().get<TaxiTaxiContractListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const update = (
    request: TaxiTaxiContractUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiTaxiContractUpdateResponse> => {
    const url = `/contracts/taxi-forwarding/${request.contractUuid}`;

    const body: TaxiTaxiContractUpdateRequestBody =
      taxiTaxiContractUpdateRequestFactory.createBody(request);

    return railyApiService().put<TaxiTaxiContractUpdateResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const getDetails = (
    request: TaxiTaxiContractDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiTaxiContractDetailsResponse> => {
    const url = `/contracts/taxi-forwarding/${request.contractUuid}`;

    return railyApiService().get<TaxiTaxiContractDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return {
    add,
    getList,
    update,
    getDetails,
  };
};

export default taxiTaxiContractApiService;
