import { FC, useEffect } from "react";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import { useAppContext } from "../../../context/app.context";
import planningBreadcrumbsHelper from "../common/breadcrumbs/planning-breadcrumbs.helper";

type PlanningDriverProps = {};

const PlanningDriverComponent: FC<PlanningDriverProps> = () => {
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.planningDriver);

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs = planningBreadcrumbsHelper.getDriverBreadcrumbs({
      driverName: "Test driver name",
      driverUuid: "123",
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  return <div>Planning driver</div>;
};

export default PlanningDriverComponent;
