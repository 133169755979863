import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import ButtonComponent from "../../../common/components/button/button.component";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import { useAppContext } from "../../../context/app.context";
import AppHeaderUserChangePasswordComponent from "./app-header-user-change-password.component";
import OutsideClickHandler from "react-outside-click-handler";
import TermsAndConditionsPDF from "../../../assets/documents/raily_taxi_terms_and_conditions.pdf";
import LinkButtonComponent from "../../../common/components/button/link/link-button.component";

type AppHeaderUserProps = {
  onLogout: () => void;
};

const AppHeaderUserComponent: FC<AppHeaderUserProps> = (props) => {
  const user = useAppContext().user!;

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isUserDropdownExpanded, setIsUserDropdownExpanded] = useState(false);

  const translations = appTranslationsHelper.getAuthTranslations();

  const openChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
    setIsUserDropdownExpanded(false);
  };

  const closeChangePasswordModal = () => setIsChangePasswordModalOpen(false);

  return (
    <div className="app_header_user">
      <OutsideClickHandler
        onOutsideClick={() => setIsUserDropdownExpanded(false)}
      >
        <ButtonComponent
          onClick={() => setIsUserDropdownExpanded(!isUserDropdownExpanded)}
          title={`${user.firstName} ${user.lastName}`}
        >
          <FontAwesomeIcon icon={faUserCircle} size="2x" />
        </ButtonComponent>
        {isUserDropdownExpanded && (
          <div className="app_header_user_dropdown_content">
            <div className="app_header_user_dropdown_content_info_wrapper">
              <FontAwesomeIcon icon={faUserCircle} size="3x" />
              <div className="app_header_user_dropdown_content_info">
                {user.firstName && user.lastName && (
                  <div className="app_header_user_dropdown_content_info__text">
                    {user.firstName} {user.lastName}
                  </div>
                )}
                {user.username && (
                  <div className="app_header_user_dropdown_content_info__text">
                    {user.username}
                  </div>
                )}
                {user.email && (
                  <div className="app_header_user_dropdown_content_info__text">
                    {user.email}
                  </div>
                )}
              </div>
            </div>
            <div className="app_header_user_dropdown_content_actions">
              <LinkButtonComponent
                type="brand"
                classNames={{
                  root: `app_header_user_dropdown_content_actions__link_button`,
                  content: `app_header_user_dropdown_content_actions__link_button_content`,
                }}
                openInNewTab
                to={TermsAndConditionsPDF}
              >
                {translations.termsAndConditions}
              </LinkButtonComponent>
              <ButtonComponent
                onClick={openChangePasswordModal}
                classNames={{
                  root: `app_header_user_dropdown_content_actions__button`,
                }}
                type="info"
                title={translations.changePassword.buttonTitle}
              >
                {translations.changePassword.buttonLabel}
              </ButtonComponent>
              <ButtonComponent
                onClick={props.onLogout}
                classNames={{
                  root: `app_header_user_dropdown_content_actions__button`,
                }}
                type="danger"
                title={translations.logout.buttonTitle}
              >
                {translations.logout.buttonLabel}
              </ButtonComponent>
            </div>
          </div>
        )}
      </OutsideClickHandler>
      <AppHeaderUserChangePasswordComponent
        isOpen={isChangePasswordModalOpen}
        onClose={closeChangePasswordModal}
      />
    </div>
  );
};

export default AppHeaderUserComponent;
