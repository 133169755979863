import { FC } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import ModalComponent from "../../../../../common/components/modal/modal.component";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";

type OrderAddChangeContractorConfirmationModalProps = {
  isModalOpen: boolean;
  onCloseClick: () => void;
  onConfirmClick: () => void;
};

const OrderAddChangeContractorConfirmationModalComponent: FC<
  OrderAddChangeContractorConfirmationModalProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getAddTranslations().changeContractorModal;

  const onSubmitButtonClick = () => {
    props.onConfirmClick();
  };

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isModalOpen}
      onClose={props.onCloseClick}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          title={translations.confirmationButtonTitle}
          idForTesting="change-contractor-confirmation-button"
        >
          {translations.confirmationButtonText}
        </ButtonComponent>,
      ]}
    >
      {translations.messageText}
    </ModalComponent>
  );
};

export default OrderAddChangeContractorConfirmationModalComponent;
