import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent } from "../../../common/types/order-details-handling-log-entry-event";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations()
    .assignmentOrderExistingPlan;

const getRouteLabel = (
  planEntryUuid: OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent["planEntryUuid"]
) => {
  return translations.routeLabel.replace(
    "#{planEntryUuid}",
    planEntryUuid.toString() ?? "-----"
  );
};

const getTaxiCorporationName = (
  taxiCorporationName: OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent["taxiCorporationName"]
) => {
  return translations.taxiCorporationNameLabel.replace(
    "#{taxiCorporationName}",
    taxiCorporationName ?? "-----"
  );
};

const getDriverName = (
  driverName: OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent["driverName"]
) => {
  return translations.driverNameLabel.replace(
    "#{driverName}",
    driverName ?? "-----"
  );
};

const orderDetailsHistoryEntryAssignmentOrderExistingPlanContentHelper = {
  getRouteLabel,
  getTaxiCorporationName,
  getDriverName,
};

export default orderDetailsHistoryEntryAssignmentOrderExistingPlanContentHelper;
