import appConfig from "../../../../../../app.config";
import searchAddressApiService from "../../../../../../common/utils/search-address/search-address.service";
import authService from "../../../../../auth/common/auth.service";
import CargoAddressAddAddressRequest from "./cargo-address-add-add-address.request";
import CargoAddressAddAddAddressResponse from "./cargo-address-add-add-address.response";

const addAddress = async (
  cargoCompanyUuid: string,
  request: CargoAddressAddAddressRequest
): Promise<CargoAddressAddAddAddressResponse> => {
  const path = `/cargo-companies/${cargoCompanyUuid}/addresses`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const searchAddress = searchAddressApiService.searchByQuery;

const cargoAddressAddApiService = {
  addAddress,
  searchAddress,
};

export default cargoAddressAddApiService;
