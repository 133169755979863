import HttpResponse from "../../../../../../../common/utils/http/http.response";

export enum TaxiUnsettledOrderListingByRailyResponseDataItemLackingRailyBillings {
  CARGO_RAILY = "CARGO_RAILY",
  TAXI_RAILY = "TAXI_RAILY",
  RAILY_TAXI = "RAILY_TAXI",
}

export type TaxiUnsettledOrderListingByRailyResponseDataClient = {
  id: string;
  name: string;
};

export type TaxiUnsettledOrderListingByRailyResponseDataItem = {
  id: string;
  start_time: string;
  human_id: number;
  is_cancelled: boolean;
  ride_addresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  client: TaxiUnsettledOrderListingByRailyResponseDataClient;
  taxi_corporations: string[];
  drivers: string[];
  external_id: string | null;
  lacking_raily_billings: TaxiUnsettledOrderListingByRailyResponseDataItemLackingRailyBillings[];
};

export type TaxiUnsettledOrderListingByRailyResponseData = {
  data: TaxiUnsettledOrderListingByRailyResponseDataItem[];
  total_count: number;
};

type TaxiUnsettledOrderListingByRailyResponse =
  HttpResponse<TaxiUnsettledOrderListingByRailyResponseData>;

export default TaxiUnsettledOrderListingByRailyResponse;
