import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import ButtonComponent, {
  ButtonProps,
} from "../../../../../../common/components/button/button.component";

type RouteDetailsToolButtonProps = Pick<
  ButtonProps,
  "onClick" | "title" | "isDisabled" | "type" | "isLoading"
> & {
  icon: IconProp;
  isActive?: boolean;
};

const RouteDetailsToolButtonComponent: FC<RouteDetailsToolButtonProps> = (
  props
) => {
  return (
    <ButtonComponent
      onClick={props.onClick}
      title={props.title}
      classNames={{
        root: classNames(
          "route_details_tools_button",
          props.isActive && "active"
        ),
      }}
      type={props.type}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
    >
      <FontAwesomeIcon
        icon={props.icon}
        className="route_details_tools_button__icon"
      />
    </ButtonComponent>
  );
};

RouteDetailsToolButtonComponent.defaultProps = {
  type: "primary",
};

export default RouteDetailsToolButtonComponent;
