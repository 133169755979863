import appConfig from "../../../../../../../app.config";
import authService from "../../../../../../auth/common/auth.service";
import taxiUnsettledRouteListingApiService from "../../../common/api/taxi-unsettled-route-listing-api.service";
import TaxiUnsettledRouteListingByRailyTaxiCoporationsResponse from "./taxi-unsettled-route-listing-by-raily-taxi-corporations.response";

const fetchTaxiCorporations =
  (): Promise<TaxiUnsettledRouteListingByRailyTaxiCoporationsResponse> => {
    const path = `/taxi-corporations`;
    const url = `${appConfig.baseApiUrl}${path}`;

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
    };

    return fetch(url, init).then(async (r) => {
      const responseData = await r.json();
      return {
        status: r.status,
        data: responseData,
      };
    });
  };

const taxiUnsettledRouteListingByRailyApiService = {
  fetchTaxiCorporations,
  fetchListing: taxiUnsettledRouteListingApiService.fetchListing,
};

export default taxiUnsettledRouteListingByRailyApiService;
