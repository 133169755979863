import userFormValidationService from "../../../../../../shared/user/user.validation.service";

const taxiOfficerEditByTaxiFormValidationService = {
  validateFirstName: userFormValidationService.validateFirstName,
  validateLastName: userFormValidationService.validateLastName,
  validatePhoneNumber: userFormValidationService.validateMobile,
  validateAlternativePhoneNumber:
    userFormValidationService.validateAlternativeMobile,
  validateEmail: userFormValidationService.validateEmail,
};

export default taxiOfficerEditByTaxiFormValidationService;
