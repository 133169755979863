import MileageSettingsDetailsResponse, {
  MileageSettingsDetailsResponseItem,
  MileageSettingsDetailsResponseMileageNumberCreationModel,
} from "../../../api/raily/mileage-settings/details/mileage-settings-details.response";
import MileageSettingsDetails, {
  MileageSettingsDetailsMileageNumberCreationModel,
} from "./mileage-settings-details";

const createCreationModel = (
  model: MileageSettingsDetailsResponseMileageNumberCreationModel
): MileageSettingsDetailsMileageNumberCreationModel => {
  switch (model) {
    case MileageSettingsDetailsResponseMileageNumberCreationModel.AUTO:
      return MileageSettingsDetailsMileageNumberCreationModel.AUTO;
    case MileageSettingsDetailsResponseMileageNumberCreationModel.DICT:
      return MileageSettingsDetailsMileageNumberCreationModel.DICT;
    case MileageSettingsDetailsResponseMileageNumberCreationModel.MANUAL:
      return MileageSettingsDetailsMileageNumberCreationModel.MANUAL;
  }
};

const createItem = (
  item: MileageSettingsDetailsResponseItem
): MileageSettingsDetails => {
  return {
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    isCardNumberRequired: item.isCardNoRequired,
    isMileageNumberRequired: item.isMileageNoRequired,
    mileageNumberCreationModel: createCreationModel(item.mileageNoCreationType),
    uuid: item.id,
    modificationDate: item.modifiedAt,
    modifiedBy: item.modifiedBy,
  };
};

const create = (
  response: MileageSettingsDetailsResponse
): MileageSettingsDetails => {
  return createItem(response.data);
};

const mileageSettingsDetailsFactory = {
  create,
};

export default mileageSettingsDetailsFactory;
