import HttpResponse from "../../../../utils/http/http.response";

export enum DriverPlanCandidaturePreviewCandidateEntryType {
  CREATE_SOLVED_ORDER = "CREATE_SOLVED_ORDER",
  CREATE_PLAN_ENTRY = "CREATE_PLAN_ENTRY",
}

export enum DriverPlanCandidaturePreviewCandidateRideType {
  STRICT = "STRICT",
}

export enum DriverPlanCandidaturePreviewCandidateTimingPolicy {
  REQUESTED = "REQUESTED",
  OPTIMIZED = "OPTIMIZED",
}

export enum DriverPlanCandidaturePreviewNotAcceptableResponseErrorType {
  NO_TAXI_DRIVER_ASSOCIATION = "NO_TAXI_DRIVER_ASSOCIATION",
  NO_VALID_TAXI_DRIVER_CONTRACT = "NO_VALID_TAXI_DRIVER_CONTRACT",
  NO_VALID_TAXI_RAILY_CONTRACT = "NO_VALID_TAXI_RAILY_CONTRACT",
  NO_VALID_CARGO_RAILY_CONTRACT = "NO_VALID_CARGO_RAILY_CONTRACT",
  TARGET_PLAN_ENTRY_NOT_FOUND = "TARGET_PLAN_ENTRY_NOT_FOUND",
  CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME = "CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME",
  CANDIDATE_DOES_NOT_FIT = "CANDIDATE_DOES_NOT_FIT",
  TOO_MANY_CHECKOUT_EVENTS = "TOO_MANY_CHECKOUT_EVENTS",
  CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION = "CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION",
  DELETE_SOLVED_ORDER_TARGET_NOT_FOUND = "DELETE_SOLVED_ORDER_TARGET_NOT_FOUND",
  TARGET_ORDER_CANCELLED = "TARGET_ORDER_CANCELLED",
  PLAN_ENTRY_CANDIDATE_COLLISION = "PLAN_ENTRY_CANDIDATE_COLLISION",
  FORBIDDEN_TAXI = "FORBIDDEN_TAXI",
  FORBIDDEN_PLAN_ENTRY = "FORBIDDEN_PLAN_ENTRY",
}

export type DriverPlanCandidaturePreviewNotAcceptableResponseNoTaxiDriverAssociationError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION;
    driverId: string;
    taxiId: string;
  };

export type DriverPlanCandidaturePreviewNotAcceptableResponseNoValidTaxiDriverContractError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT;
    driverId: string;
    taxiId: string;
  };

export type DriverPlanCandidaturePreviewNotAcceptableResponseNoValidTaxiRailyContractError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT;
  };

export type DriverPlanCandidaturePreviewNotAcceptableResponseNoValidCargoRailyContractError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.NO_VALID_CARGO_RAILY_CONTRACT;
    driverId: string;
    taxiId: string;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponseTargetPlanEntryNotFoundError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.TARGET_PLAN_ENTRY_NOT_FOUND;
    candidateEntryId: string;
    targetPlanEntryId: string;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponseTooManyCheckoutEventsError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponseCandidateFitsWithNotEnoughTimeError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME;
    candidatureId: string;
    candidatureEntryId: string;
    collidingSolvedOrderId: string;
    candidatureSolvedOrderId: string;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponseCandidateDoesNotFitError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.CANDIDATE_DOES_NOT_FIT;
    candidatureId: string;
    candidatureEntryId: string;
    candidatureSolvedOrderId: string;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponseCandidatePlanEntryCollidesError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION;
    candidatureId: string;
    candidatureEntryId: string;
    candidaturePlanEntryId: string;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponseDeleteSolvedOrderTargetNotFoundError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND;
    candidatureId: string;
    candidateEntryId: string;
    targetSolvedOrderId: string;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponseTargetTransportingOrderCancelledError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.TARGET_ORDER_CANCELLED;
    candidatureId: string;
    candidateEntryId: string;
    targetSolvedOrderId: string;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponsePlanEntryCandidateCollisionError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_COLLISION;
    candidatureId: string;
    candidateEntryId: string;
  };

type DriverPlanCandidaturePreviewNotAcceptableResponseForbiddenTaxiError = {
  errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.FORBIDDEN_TAXI;
  providedTaxiId: string;
};

type DriverPlanCandidaturePreviewNotAcceptableResponseForbiddenPlanEntryError =
  {
    errorType: DriverPlanCandidaturePreviewNotAcceptableResponseErrorType.FORBIDDEN_PLAN_ENTRY;
    providedPlanEntryId: string;
  };

export type DriverPlanCandidaturePreviewNotAcceptableResponseError =
  | DriverPlanCandidaturePreviewNotAcceptableResponseNoTaxiDriverAssociationError
  | DriverPlanCandidaturePreviewNotAcceptableResponseNoValidTaxiDriverContractError
  | DriverPlanCandidaturePreviewNotAcceptableResponseNoValidTaxiRailyContractError
  | DriverPlanCandidaturePreviewNotAcceptableResponseNoValidCargoRailyContractError
  | DriverPlanCandidaturePreviewNotAcceptableResponseDeleteSolvedOrderTargetNotFoundError
  | DriverPlanCandidaturePreviewNotAcceptableResponseTargetPlanEntryNotFoundError
  | DriverPlanCandidaturePreviewNotAcceptableResponseTooManyCheckoutEventsError
  | DriverPlanCandidaturePreviewNotAcceptableResponseCandidateFitsWithNotEnoughTimeError
  | DriverPlanCandidaturePreviewNotAcceptableResponseCandidateDoesNotFitError
  | DriverPlanCandidaturePreviewNotAcceptableResponseCandidatePlanEntryCollidesError
  | DriverPlanCandidaturePreviewNotAcceptableResponseTargetTransportingOrderCancelledError
  | DriverPlanCandidaturePreviewNotAcceptableResponsePlanEntryCandidateCollisionError
  | DriverPlanCandidaturePreviewNotAcceptableResponseForbiddenTaxiError
  | DriverPlanCandidaturePreviewNotAcceptableResponseForbiddenPlanEntryError;

export type DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPointsNodeData =
  {
    address: string;
    date: Date;
  };

export type DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPoint =
  {
    humanId: number;
    waypoints: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPointsNodeData[];
  };

export type DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryStartData =
  {
    address: string;
    date: Date;
  };

export type DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryEndData =
  {
    address: string;
    date: Date;
  };

export type DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntry =
  {
    id: string;
    humanId: number;
    start: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryStartData;
    end: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryEndData;
    orders: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPoint[];
  };

export type DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPointsNodeData =
  {
    address: string;
    date: Date;
  };

export type DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPoint =
  {
    humanId: number;
    waypoints: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPointsNodeData[];
  };

export type DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryStartData =
  {
    address: string;
    date: Date;
  };

export type DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryEndData =
  {
    address: string;
    date: Date;
  };

export type DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntry = {
  id: string;
  humanId: number;
  start: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryStartData;
  end: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryEndData;
  orders: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPoint[];
};

export type DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPointsNodeData =
  {
    address: string;
    date: Date;
  };

export type DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPoint = {
  humanId: number;
  waypoints: DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPointsNodeData[];
};

export type DriverPlanCandidaturePreviewResponseItemPlanEntryStartData = {
  address: string;
  date: Date;
};

export type DriverPlanCandidaturePreviewResponseItemPlanEntryEndData = {
  address: string;
  date: Date;
};

export type DriverPlanCandidaturePreviewResponseItemPlanEntry = {
  id: string;
  humanId: number;
  start: DriverPlanCandidaturePreviewResponseItemPlanEntryStartData;
  end: DriverPlanCandidaturePreviewResponseItemPlanEntryEndData;
  orders: DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPoint[];
};

export type DriverPlanCandidaturePreviewResponseItemCreateSolvedOrderCandidating =
  {
    candidateEntryType: DriverPlanCandidaturePreviewCandidateEntryType.CREATE_SOLVED_ORDER;
    planEntryId: string | null;
    createPlanEntryId: string | null;
    transportingOrderId: string;
    id: string;
    candidatingSolve: string;
  };

export type DriverPlanCandidaturePreviewResponseItemCreatePlanEntryCandidating =
  {
    candidateEntryType: DriverPlanCandidaturePreviewCandidateEntryType.CREATE_PLAN_ENTRY;
    createPlanEntryId: string | null;
    transportingOrderId: string;
    id: string;
    givenPlanEntryId: string;
    candidatingSolve: string;
    taxiCorporationId: string;
  };

export type DriverPlanCandidaturePreviewResponseItemCandidatingRequest =
  | DriverPlanCandidaturePreviewResponseItemCreateSolvedOrderCandidating
  | DriverPlanCandidaturePreviewResponseItemCreatePlanEntryCandidating;

export type DriverPlanCandidaturePreviewResponseItem = {
  planEntries: DriverPlanCandidaturePreviewResponseItemPlanEntry[];
  candidatureEntries: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntry[];
  draftCandidatureEntries: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntry[];
  candidatingRequest: DriverPlanCandidaturePreviewResponseItemCandidatingRequest;
};

type DriverPlanCandidaturePreviewSuccessResponse =
  HttpResponse<DriverPlanCandidaturePreviewResponseItem>;

export type DriverPlanCandidaturePreviewNotAcceptableResponse =
  HttpResponse<DriverPlanCandidaturePreviewNotAcceptableResponseError>;

type DriverPlanCandidaturePreviewResponse =
  | DriverPlanCandidaturePreviewSuccessResponse
  | DriverPlanCandidaturePreviewNotAcceptableResponse;

export default DriverPlanCandidaturePreviewResponse;
