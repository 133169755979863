import { FC } from "react";
import OrderJoinOrderDetailsPassenger from "../common/types/order-join-order-details-passenger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";

type OrderJoinOrderDetailsPassengers = {
  passengers: OrderJoinOrderDetailsPassenger[];
};

const translations = orderTranslationsHelper.getOrderJoinTranslations();
const OrderJoinOrderDetailsPassengersComponent: FC<
  OrderJoinOrderDetailsPassengers
> = (props) => {
  return (
    <>
      <div className="mt-2">{translations.passengersListLabel}</div>
      <div className="order_join_passengers">
        {props.passengers?.map((passenger) => (
          <li
            className={classNames("order_join_passengers_item")}
            key={`passenger-${passenger.uuid}`}
          >
            <FontAwesomeIcon
              icon={faUser}
              className="order_join_passengers_item__icon"
            />
            <div className="order_join_passengers_item__name">
              {passenger.name}
            </div>
          </li>
        ))}
      </div>
    </>
  );
};

export default OrderJoinOrderDetailsPassengersComponent;
