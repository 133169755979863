import CargoCompanyAddRequest from "../../../../api/raily/cargo-company/cargo-company/add/cargo-company-add-request";
import CargoCompanyAddParams from "./cargo-company-add-params";

const create = (params: CargoCompanyAddParams): CargoCompanyAddRequest => {
  return {
    taxId: params.taxNumber,
    regon: params.companyId || null,
    krsNo: params.nationalCourtRegister,
    companyName: params.name,
    displayName: params.displayName,
    headquartersAddressString: params.address,
    contractMaintenancePolicy: params.contractMaintenancePolicy,
    notes: params.notes,
  };
};

const cargoCompanyAddRequestFactory = {
  create,
};

export default cargoCompanyAddRequestFactory;
