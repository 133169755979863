import {
  CargoOrderDetailsResponseItem,
  CargoOrderDetailsResponseItemCargoOrder,
  CargoOrderDetailsResponseItemCargoOrderCargoSource,
  CargoOrderDetailsResponseItemCargoOrderCargoSourceElement,
  CargoOrderDetailsResponseItemCargoOrderCargoSourceType,
  CargoOrderDetailsResponseItemCargoOrderRide,
  CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubject,
  CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubjectList,
  CargoOrderDetailsResponseItemCargoOrderRideSeq,
  CargoOrderDetailsResponseItemCargoOrderRideSeqNode,
  CargoOrderDetailsResponseItemCargoOrderRideSeqNodeSet,
  CargoOrderDetailsResponseItemCargoOrderRideSequenceOrder,
  CargoOrderDetailsResponseItemPassenger,
  CargoOrderDetailsResponseItemPassengerAddress,
  CargoOrderDetailsResponseItemPassengerAspect,
  CargoOrderDetailsResponseItemStatus,
} from "../../../api/raily/cargo-order/details/cargo-order-details.response";
import CargoOrderDetails, {
  CargoOrderDetailsItemCargoOrder,
  CargoOrderDetailsItemCargoOrderCargoSource,
  CargoOrderDetailsItemCargoOrderCargoSourceElement,
  CargoOrderDetailsItemCargoOrderCargoSourceType,
  CargoOrderDetailsItemCargoOrderRide,
  CargoOrderDetailsItemCargoOrderRideMetaCargoSubject,
  CargoOrderDetailsItemCargoOrderRideMetaCargoSubjectList,
  CargoOrderDetailsItemCargoOrderRideSeq,
  CargoOrderDetailsItemCargoOrderRideSeqNode,
  CargoOrderDetailsItemCargoOrderRideSeqNodeSet,
  CargoOrderDetailsItemCargoOrderRideSequenceOrder,
  CargoOrderDetailsItemPassenger,
  CargoOrderDetailsItemPassengerAddress,
  CargoOrderDetailsItemPassengerAspect,
  CargoOrderDetailsItemStatus,
} from "./cargo-order-details";

const createCargoSubject = (
  subject: CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubject
): CargoOrderDetailsItemCargoOrderRideMetaCargoSubject => {
  return {
    uuid: subject.id,
  };
};

const createCargoSubjectList = (
  subjectList: CargoOrderDetailsResponseItemCargoOrderRideMetaCargoSubjectList
): CargoOrderDetailsItemCargoOrderRideMetaCargoSubjectList => {
  return {
    subjects: subjectList.subjects.map(createCargoSubject),
  };
};

const createRideSeqNode = (
  node: CargoOrderDetailsResponseItemCargoOrderRideSeqNode
): CargoOrderDetailsItemCargoOrderRideSeqNode => {
  return {
    displayName: node.displayName,
    haltingTime: node.haltingTime,
    lat: node.lat,
    lon: node.lon,
    meta: {
      uuid: node.meta.id,
      cargoEnter: createCargoSubjectList(node.meta.cargoEnter),
      cargoExit: createCargoSubjectList(node.meta.cargoExit),
    },
    time: node.time,
  };
};

const createRideSeq = (
  seq: CargoOrderDetailsResponseItemCargoOrderRideSeq
): CargoOrderDetailsItemCargoOrderRideSeq => {
  const sequence: CargoOrderDetailsItemCargoOrderRideSeq = [];

  seq.forEach((group) => {
    const nodeSetCast =
      group as CargoOrderDetailsResponseItemCargoOrderRideSeqNodeSet;

    if (!!nodeSetCast.seq) {
      const nodes: CargoOrderDetailsItemCargoOrderRideSeqNode[] = [];

      nodeSetCast.seq.forEach((node) => {
        nodes.push(createRideSeqNode(node));
      });

      const nodeSet: CargoOrderDetailsItemCargoOrderRideSeqNodeSet = {
        seq: nodes,
        meta: {
          uuid: nodeSetCast.meta.id,
        },
        sequenceOrder: createRideSequenceOrder(nodeSetCast.sequenceOrder),
      };

      sequence.push(nodeSet);
    } else {
      const nodeCast =
        group as CargoOrderDetailsResponseItemCargoOrderRideSeqNode;

      sequence.push(createRideSeqNode(nodeCast));
    }
  });

  return sequence;
};

const createRideSequenceOrder = (
  order: CargoOrderDetailsResponseItemCargoOrderRideSequenceOrder
): CargoOrderDetailsItemCargoOrderRideSequenceOrder => {
  switch (order) {
    case CargoOrderDetailsResponseItemCargoOrderRideSequenceOrder.STRICT:
      return CargoOrderDetailsItemCargoOrderRideSequenceOrder.STRICT;
  }
};

const createRide = (
  ride: CargoOrderDetailsResponseItemCargoOrderRide
): CargoOrderDetailsItemCargoOrderRide => {
  return {
    meta: {
      uuid: ride.meta.id,
    },
    sequenceOrder: createRideSequenceOrder(ride.sequenceOrder),
    seq: createRideSeq(ride.seq),
  };
};

const createItemSourceListElementSource = (
  cargoItemSource: CargoOrderDetailsResponseItemCargoOrderCargoSource
): CargoOrderDetailsItemCargoOrderCargoSource => {
  if (
    cargoItemSource.sourceType ==
    CargoOrderDetailsResponseItemCargoOrderCargoSourceType.EXTERNAL
  ) {
    return {
      sourceType: CargoOrderDetailsItemCargoOrderCargoSourceType.EXTERNAL,
      firstName: cargoItemSource.firstName,
      lastName: cargoItemSource.lastName,
      phoneNo: cargoItemSource.phoneNo,
      dispatchName: cargoItemSource.dispatchName,
      externalId: cargoItemSource.externalId,
      meta: cargoItemSource.meta,
    };
  } else {
    return {
      sourceType: CargoOrderDetailsItemCargoOrderCargoSourceType.INTERNAL,
      passengerUuid: cargoItemSource.passengerId,
      externalId: cargoItemSource.externalId,
      meta: cargoItemSource.meta,
    };
  }
};

const createItemSourceListElement = (
  element: CargoOrderDetailsResponseItemCargoOrderCargoSourceElement
): CargoOrderDetailsItemCargoOrderCargoSourceElement => {
  return {
    cargoSubjectUuid: element.cargoSubjectId,
    cargoItemSource: createItemSourceListElementSource(element.cargoItemSource),
  };
};

const createItemSourceList = (
  cargoItemsSourceList: CargoOrderDetailsResponseItemCargoOrderCargoSourceElement[]
): CargoOrderDetailsItemCargoOrderCargoSourceElement[] => {
  return cargoItemsSourceList.map(createItemSourceListElement);
};

const createCargoOrder = (
  cargoOrder: CargoOrderDetailsResponseItemCargoOrder
): CargoOrderDetailsItemCargoOrder => {
  return {
    uuid: cargoOrder.id,
    ride: createRide(cargoOrder.ride),
    cargoItemsSourceList: createItemSourceList(cargoOrder.cargoItemsSourceList),
  };
};

const createStatus = (
  status: CargoOrderDetailsResponseItemStatus
): CargoOrderDetailsItemStatus => {
  switch (status) {
    case CargoOrderDetailsResponseItemStatus.CANDIDATURE:
      return CargoOrderDetailsItemStatus.CANDIDATURE;
    case CargoOrderDetailsResponseItemStatus.FINISHED:
      return CargoOrderDetailsItemStatus.FINISHED;
    case CargoOrderDetailsResponseItemStatus.IN_PROGRESS:
      return CargoOrderDetailsItemStatus.IN_PROGRESS;
    case CargoOrderDetailsResponseItemStatus.PENDING:
      return CargoOrderDetailsItemStatus.PENDING;
    case CargoOrderDetailsResponseItemStatus.PLANNED:
      return CargoOrderDetailsItemStatus.PLANNED;
  }
};

const createPassengerAddress = (
  address: CargoOrderDetailsResponseItemPassengerAddress
): CargoOrderDetailsItemPassengerAddress => {
  return {
    displayName: address.displayName,
    lat: address.lat,
    lon: address.lon,
    uuid: address.id,
    apartment: address.apartment,
    country: address.country,
    description: address.description,
    houseNo: address.houseNo,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createPassengerAddresses = (
  addresses: CargoOrderDetailsResponseItemPassengerAddress[]
): CargoOrderDetailsItemPassengerAddress[] => {
  return addresses.map(createPassengerAddress);
};

const createPassengerAspect = (
  passenger: CargoOrderDetailsResponseItemPassengerAspect
): CargoOrderDetailsItemPassengerAspect => {
  return {
    addresses: createPassengerAddresses(passenger.addresses),
  };
};

const createPassenger = (
  passenger: CargoOrderDetailsResponseItemPassenger
): CargoOrderDetailsItemPassenger => {
  return {
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    passenger: createPassengerAspect(passenger.passenger),
    username: passenger.username,
    uuid: passenger.id,
  };
};

const createPassengers = (
  passengers: CargoOrderDetailsResponseItemPassenger[]
): CargoOrderDetailsItemPassenger[] => {
  return passengers.map(createPassenger);
};

const create = (
  responseItem: CargoOrderDetailsResponseItem
): CargoOrderDetails => {
  return {
    uuid: responseItem.id,
    passengers: createPassengers(responseItem.passengers),
    cargoOrder: createCargoOrder(responseItem.cargoOrder),
    forceAllowToll: responseItem.forceAllowToll,
    nodeExclusions: responseItem.nodeExclusions,
    status: createStatus(responseItem.status),
  };
};

const cargoOrderDetailsFactory = { create };

export default cargoOrderDetailsFactory;
