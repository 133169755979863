import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import { useAppContext } from "../../../../context/app.context";
import mileageBreadcrumbsHelper from "../../common/breadcrumbs/mileage-breadcrumbs.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import useAbort from "../../../../common/hooks/use-abort";
import useMileageContractListingFiltersDao from "./filter/use-mileage-contract-listing-filters.dao";
import usePagination from "../../../../common/hooks/use-pagination";
import MileageContractListingFilter from "./common/types/mileage-contract-listing-filter";
import MileageContractListingSortKey from "./common/types/mileage-contract-listing-sort-key";
import mileageContractListingLoadParamsFactory from "./common/mileage-contract-listing-load-params.factory";
import CardComponent from "../../../../common/components/card/card.component";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import MileageContractListingItem from "./common/types/mileage-contract-listing-item";
import MileageContractsListingTableComponent from "./table/mileage-contract-listing-table.component";
import MileageContractListingFilterComponent from "./filter/mileage-contract-listing-filters.components";
import mileageContractsListingItemFactory from "./common/mileage-contract-listing-item.factory";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import useMileageContractListingUserPermissions from "./common/user-permissions/use-mileage-contract-listing-user-permissions";
import useMileageContractsList from "../../../../common/services/mileage-contract/list/use-mileage-contracts";
import MileageContractListLoadParams from "../../../../common/services/mileage-contract/list/mileage-contract-list-load-params";
import mileageRoutesHelper from "../../common/routes/mileage-routes.helper";

const MileageContractsListingComponent: FC = () => {
  const translations =
    mileageTranslationsHelper.getMileageContractListingTranslations();
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs =
      mileageBreadcrumbsHelper.getMileageContractListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageContractListing);

  const userPermissions = useMileageContractListingUserPermissions();

  const mileageContractsList = useMileageContractsList();
  const listingItemsAbort = useAbort();

  const filtersDao = useMileageContractListingFiltersDao();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: mileageContractsList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const [expandedRowsMileageUuids, setExpandedRowsMileageUuids] = useState<
    string[]
  >([]);

  const [filters, setFilters] = useState<MileageContractListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const [sortKey, setSortKey] = useState<MileageContractListingSortKey>(
    () =>
      filtersDao.getSortKey() ?? MileageContractListingSortKey.COMPANY_NAME_DESC
  );

  const loadListingItems = async () => {
    const params: MileageContractListLoadParams =
      mileageContractListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey
      );
    mileageContractsList.load(params, listingItemsAbort.signal);
  };

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const listingItems: MileageContractListingItem[] = useMemo(() => {
    return mileageContractsListingItemFactory.createListingItems(
      mileageContractsList.data.data
    );
  }, [mileageContractsList.data.data]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    setExpandedRowsMileageUuids([]);
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onTableRowClick = (listingItemUuid: string) => {
    const expandRow = () => {
      setExpandedRowsMileageUuids((curr) => [...curr, listingItemUuid]);
    };

    const collapseRow = () => {
      const filteredExpandedRows = expandedRowsMileageUuids.filter(
        (expandedMileageUuid) => expandedMileageUuid !== listingItemUuid
      );

      setExpandedRowsMileageUuids(filteredExpandedRows);
    };

    const isRowExpanded = expandedRowsMileageUuids.includes(listingItemUuid);

    if (isRowExpanded) {
      collapseRow();
      return;
    }

    expandRow();
  };

  const createHeadingActions = (): ReactNode[] => {
    const MileageContractAddLink = (
      <LinkButtonComponent
        to={mileageRoutesHelper.getContractAddRoute()}
        type="primary"
        title={translations.header.addButtonTitle}
        idForTesting="mileage-contracts-listing-mileage-contract-add-button"
      >
        {translations.header.addButtonLabel}
      </LinkButtonComponent>
    );

    const actions: ReactNode[] = [
      userPermissions.hasVisibleAddContractButton
        ? MileageContractAddLink
        : undefined,
    ];

    return actions;
  };

  const headingActions = createHeadingActions();

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <MileageContractListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <MileageContractsListingTableComponent
          listingItems={listingItems}
          onRowClick={onTableRowClick}
          expandedRowsMileageUuids={expandedRowsMileageUuids}
          isError={false}
          isLoading={false}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={mileageContractsList.data.totalCount}
        />
      </CardComponent>
    </>
  );
};

export default MileageContractsListingComponent;
