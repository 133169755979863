import HttpError from "./http.error";

const checkIsHttpError = (error: any): error is HttpError => {
  return Object.values(HttpError).includes(error as HttpError);
};

const httpHelper = {
  checkIsHttpError,
};

export default httpHelper;
