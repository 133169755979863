import HttpResponse from "../../../../../../common/utils/http/http.response";
import OrderDetailsHistoryChangeRideEventType from "../types/order-details-history-change-ride-event-type";
import OrderDetailsHistoryEventType from "../types/order-details-history-event-type";

export enum OrderDetailsHistoryResponseBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type OrderDetailsHistoryResponseItemAddCargoEnterChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.ADD_CARGO_ENTER;
};

export type OrderDetailsHistoryResponseItemAddCargoExitChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.ADD_CARGO_EXIT;
};

export type OrderDetailsHistoryResponseItemChangeHaltingTimeChangeRideCommand =
  {
    command_type: OrderDetailsHistoryChangeRideEventType.CHANGE_HALTING_TIME;
    halting_time: number;
  };

export type OrderDetailsHistoryResponseItemChangePositionChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.CHANGE_POSITION;
  display_name: string;
};

export type OrderDetailsHistoryResponseItemChangeTimeChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.CHANGE_TIME;
  time: string | null;
};

export type OrderDetailsHistoryResponseItemCreateInnerRideChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.CREATE_INNER_RIDE;
};

export type OrderDetailsHistoryResponseItemDeleteNodeChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.DELETE_NODE;
};

export type OrderDetailsHistoryResponseItemDoNothingChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.DO_NOTHING;
};

export type OrderDetailsHistoryResponseItemInsertNodeAfterChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.INSERT_NODE_AFTER;
  subject: {
    time: string | null;
    halting_time: number;
    display_name: string;
  };
};

export type OrderDetailsHistoryResponseItemInsertNodeBeforeChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.INSERT_NODE_BEFORE;
  subject: {
    time: string | null;
    halting_time: number;
    display_name: string;
  };
};

export type OrderDetailsHistoryResponseItemRemoveCargoEnterChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_ENTER;
};

export type OrderDetailsHistoryResponseItemRemoveCargoExitChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_EXIT;
};

export type OrderDetailsHistoryResponseItemSquashInnerRideChangeRideCommand = {
  command_type: OrderDetailsHistoryChangeRideEventType.SQUASH_INNER_RIDE;
};

export type OrderDetailsHistoryResponseItemChangeRideCommandUpdateRideCommand =
  | OrderDetailsHistoryResponseItemAddCargoEnterChangeRideCommand
  | OrderDetailsHistoryResponseItemAddCargoExitChangeRideCommand
  | OrderDetailsHistoryResponseItemChangeHaltingTimeChangeRideCommand
  | OrderDetailsHistoryResponseItemChangePositionChangeRideCommand
  | OrderDetailsHistoryResponseItemChangeTimeChangeRideCommand
  | OrderDetailsHistoryResponseItemCreateInnerRideChangeRideCommand
  | OrderDetailsHistoryResponseItemDeleteNodeChangeRideCommand
  | OrderDetailsHistoryResponseItemDoNothingChangeRideCommand
  | OrderDetailsHistoryResponseItemInsertNodeAfterChangeRideCommand
  | OrderDetailsHistoryResponseItemInsertNodeBeforeChangeRideCommand
  | OrderDetailsHistoryResponseItemRemoveCargoEnterChangeRideCommand
  | OrderDetailsHistoryResponseItemRemoveCargoExitChangeRideCommand
  | OrderDetailsHistoryResponseItemSquashInnerRideChangeRideCommand;

export type OrderDetailsHistoryResponseItemInitializeCommandRideWaypoint = {
  time: string | null;
  halting_time: number;
  display_name: string;
};

export type OrderDetailsHistoryResponseDataRideSeqItem =
  | OrderDetailsHistoryResponseItemInitializeCommandRideWaypoint
  | OrderDetailsHistoryResponseItemInitializeCommandRide;

export type OrderDetailsHistoryResponseItemInitializeCommandRide = {
  seq: OrderDetailsHistoryResponseDataRideSeqItem[];
  sequence_order: "STRICT" | "ANY";
};

export type OrderDetailsHistoryResponseItemBindPassengerCommand = {
  command_type: OrderDetailsHistoryEventType.BIND_PASSENGER;
  new_external_id: string | null;
  cargo_item_id: string;
};

export type OrderDetailsHistoryResponseItemCancelOrderCommand = {
  command_type: OrderDetailsHistoryEventType.CANCEL_ORDER;
};

export type OrderDetailsHistoryResponseItemChangeRideCommand = {
  command_type: OrderDetailsHistoryEventType.CHANGE_RIDE;
  update_ride_command_sequence: OrderDetailsHistoryResponseItemChangeRideCommandUpdateRideCommand[];
};

export type OrderDetailsHistoryResponseItemChangeTargetTaxiCommand = {
  command_type: OrderDetailsHistoryEventType.CHANGE_TARGET_TAXI;
  new_target_taxi_id: string;
};

export type OrderDetailsHistoryResponseItemDealerApprovalCommand = {
  command_type: OrderDetailsHistoryEventType.DEALER_APPROVAL;
  approving_dealer_id: string;
};

export type OrderDetailsHistoryResponseItemDispatchApprovalCommand = {
  command_type: OrderDetailsHistoryEventType.DISPATCH_APPROVAL;
  approving_dispatcher_id: string;
};

export type OrderDetailsHistoryResponseItemExternalIdUpdateCommand = {
  command_type: OrderDetailsHistoryEventType.EXTERNAL_ID_UPDATE;
  new_external_id: string;
};

export type OrderDetailsHistoryResponseItemInitializeCommand = {
  command_type: OrderDetailsHistoryEventType.INITIALIZE;
  dispatcher_id: string;
  external_id: string | null;
  ride: OrderDetailsHistoryResponseItemInitializeCommandRide;
};

export type OrderDetailsHistoryResponseItemOperatorApprovalCommand = {
  command_type: OrderDetailsHistoryEventType.OPERATIONAL_APPROVAL;
  approving_operator_id: string;
};

export type OrderDetailsHistoryResponseItemMergeOrderCommand = {
  command_type: OrderDetailsHistoryEventType.MERGE_ORDERS;
};

export type OrderDetailsHistoryResponseItemRequestCancelOrderCommand = {
  command_type: OrderDetailsHistoryEventType.REQUEST_CANCEL_ORDER;
};

export type OrderDetailsHistoryResponseItemShuffleMergeCommand = {
  command_type: OrderDetailsHistoryEventType.SHUFFLE_MERGE;
  target_order_id: string;
  source_order_ids: string[];
  representation: {
    target: {
      human_id: string;
    };
    sources: {
      human_id: string;
    }[];
  };
};

export type OrderDetailsHistoryResponseItemPerformedCommand =
  | OrderDetailsHistoryResponseItemBindPassengerCommand
  | OrderDetailsHistoryResponseItemCancelOrderCommand
  | OrderDetailsHistoryResponseItemChangeRideCommand
  | OrderDetailsHistoryResponseItemChangeTargetTaxiCommand
  | OrderDetailsHistoryResponseItemDealerApprovalCommand
  | OrderDetailsHistoryResponseItemDispatchApprovalCommand
  | OrderDetailsHistoryResponseItemExternalIdUpdateCommand
  | OrderDetailsHistoryResponseItemInitializeCommand
  | OrderDetailsHistoryResponseItemMergeOrderCommand
  | OrderDetailsHistoryResponseItemOperatorApprovalCommand
  | OrderDetailsHistoryResponseItemRequestCancelOrderCommand
  | OrderDetailsHistoryResponseItemShuffleMergeCommand;

export type OrderDetailsHistoryResponseItem = {
  id: string;
  created_at: string;
  author: {
    first_name: string;
    last_name: string;
  };
  performed_command: OrderDetailsHistoryResponseItemPerformedCommand;
  cargo_taxi_billing_model: OrderDetailsHistoryResponseBillingModel | null;
  taxi_corporation_display_name: string | null;
};

export type OrderDetailsHistoryResponseData = OrderDetailsHistoryResponseItem[];

type OrderDetailsHistoryResponse =
  HttpResponse<OrderDetailsHistoryResponseData>;

export default OrderDetailsHistoryResponse;
