import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

const getHaltingText = (value: number | undefined): string => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiViewBillingsTranslations();

  return translations.contractDetails.haltingAppliedAfter.replace(
    "#{minutesValue}",
    String(value)
  );
};

const contractDetailsHelper = {
  getHaltingText,
};

export default contractDetailsHelper;
