import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import RouteActiveListingComponent from "../../active-listing/route-active-listing.component";
import RouteFinishedListingComponent from "../../finished-listing/route-finished-listing.component";
import routeRoutesUrls from "./route-routes-urls";

const routeRoutesDefinition: RouteItemDefinition[] = [
  {
    component: <RouteActiveListingComponent />,
    path: routeRoutesUrls.activeListing,
    userRolesWhitelist: [UserRole.DEALER, UserRole.OPERATOR, UserRole.ADMIN],
  },
  {
    component: <RouteFinishedListingComponent />,
    path: routeRoutesUrls.finishedListing,
    userRolesWhitelist: [UserRole.DEALER, UserRole.OPERATOR, UserRole.ADMIN],
  },
];

export default routeRoutesDefinition;
