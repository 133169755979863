import MileageContractAddParams from "../../../../../common/services/mileage-contract/add/mileage-contract-add-params";
import MileageContractAddFormData from "./types/mileage-contract-add-form-data";

const create = (
  formData: MileageContractAddFormData
): MileageContractAddParams => {
  return {
    companyUuid: formData.companyUuid,
    dietFullAfterHours: formData.dietFullAfterHours,
    dietFullRate: formData.dietFullRate,
    dietHalfAfterHours: formData.dietHalfAfterHours,
    dietHalfRate: formData.dietHalfRate,
    disabled: formData.disabled,
    distanceRateCarGt900cm3: formData.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: formData.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: formData.distanceRateCompanyVehicle,
    distanceRateMoped: formData.distanceRateMoped,
    distanceRateMotorcycle: formData.distanceRateMotorcycle,
    validSince: formData.periodOfValidity?.from,
    validTo: formData.periodOfValidity?.to,
  };
};

const mileageContractAddParamsFactory = {
  create,
};

export default mileageContractAddParamsFactory;
