import DriverAddAddress from "../../../types/driver-add-address";

const getAddressLabel = (address: DriverAddAddress) => {
  return `${address.street} ${address.houseNumber}${
    address.apartmentNumber && `/${address.apartmentNumber}`
  } ${address.town}, ${address.zipCode}`;
};

const driverAddUserDataAddressHelper = {
  getAddressLabel,
};

export default driverAddUserDataAddressHelper;
