import { DriverContractAddTaxiAssociationsResponseDataItem } from "../../../common/api/driver-contract-add-taxi-assocations.response";
import DriverContractAddByRailyTaxiAssocationSelectOption from "../types/driver-contract-add-by-raily-taxi-assocation-select-option";

const createTaxiAssocationSelectOption = (
  responseTaxiAssociation: DriverContractAddTaxiAssociationsResponseDataItem
): DriverContractAddByRailyTaxiAssocationSelectOption => {
  return {
    label: responseTaxiAssociation.taxi_corporation.display_name,
    value: {
      uuid: responseTaxiAssociation.id,
    },
  };
};

const createTaxiAssocationSelectOptions = (
  responseTaxiAssociations: DriverContractAddTaxiAssociationsResponseDataItem[]
): DriverContractAddByRailyTaxiAssocationSelectOption[] => {
  return responseTaxiAssociations.map(createTaxiAssocationSelectOption);
};

const driverContractAddByRailyFactory = {
  createTaxiAssocationSelectOptions,
};

export default driverContractAddByRailyFactory;
