import appConfig from "../../../app.config";
import appLanguageDao from "../../../languages/app-language.dao";
import AppLanguage from "../../../languages/types/app-language";
import httpService from "../http/http-service";
import SearchAddressResponse from "./search-address.response";

const searchAddressHttpService = () => {
  const baseUrl = appConfig.searchAddressBaseApiUrl;
  const basicHeaders: HeadersInit = {
    "Content-Type": "application/json",
  };

  return httpService(baseUrl, basicHeaders);
};

const getRequestAcceptLanguage = (): string => {
  const acceptLanguageOptions = [
    { appLanguage: AppLanguage.DE, acceptLanguage: "de" },
    { appLanguage: AppLanguage.EN, acceptLanguage: "en" },
    { appLanguage: AppLanguage.PL, acceptLanguage: "pl" },
  ];

  const appLanguage =
    appLanguageDao.getLanguage() ?? appConfig.defaultAppLanguage;

  return acceptLanguageOptions.find(
    (option) => option.appLanguage === appLanguage
  )!.acceptLanguage;
};

const searchByQuery = (query: string): Promise<SearchAddressResponse> => {
  const requestPath = `/search`;

  const acceptLanguage = getRequestAcceptLanguage();

  const params = {
    addressdetails: 1,
    "accept-language": acceptLanguage,
    format: "json",
    q: query,
  };

  return searchAddressHttpService().get(requestPath, params);
};

const searchAddressApiService = {
  searchByQuery,
};

export default searchAddressApiService;
