import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingRouteDetailsTableColumn from "./types/billing-route-details-table-column";

const getTableColumns = (): BillingRouteDetailsTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getRouteSummaryTranslations().table.headers;

  const tableColumns: BillingRouteDetailsTableColumn[] = [
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 10,
    },
    {
      header: translations.rateForDistanceLabel,
      title: translations.rateForDistanceTitle,
      accessor: "rateForDistance",
      colSpan: 10,
    },
    {
      header: translations.amountForDistanceLabel,
      title: translations.amountForDistanceTitle,
      accessor: "amountForDistance",
      colSpan: 10,
    },
    {
      header: translations.amountForChargeHaltingsLabel,
      title: translations.amountForChargeHaltingsTitle,
      accessor: "amountForChargeHaltings",
      colSpan: 10,
    },
    {
      header: translations.amountForChargeHighwaysLabel,
      title: translations.amountForChargeHighwaysTitle,
      accessor: "amountForChargeHighways",
      colSpan: 10,
    },
  ];

  return tableColumns;
};

const billingRouteDetailsHelper = {
  getTableColumns,
};

export default billingRouteDetailsHelper;
