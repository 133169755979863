import HttpResponse from "../../../utils/http/http.response";
import railyApiService from "../raily-api.service";
import railyOfficerAddRequestFactory from "./add/raily-officer-add-request.factory";
import RailyOfficerAddRequest, {
  RailyOfficerAddRequestBody,
} from "./add/raily-officer-add.request";
import railyOfficerListRequestFactory from "./list/raily-officer-list-request.factory";
import RailyOfficerListRequest, {
  RailyOfficerListRequestQueryParams,
} from "./list/raily-officer-list.request";
import RailyOfficerListResponse from "./list/raily-officer-list.response";

const railyOfficerApiService = () => {
  const add = (
    request: RailyOfficerAddRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/raily-officers`;

    const body: RailyOfficerAddRequestBody =
      railyOfficerAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const getList = async (
    request: RailyOfficerListRequest,
    abortSignal: AbortSignal
  ): Promise<RailyOfficerListResponse> => {
    const url = `/users/all/raily-officers`;

    const queryParams: RailyOfficerListRequestQueryParams =
      railyOfficerListRequestFactory.createQueryParams(request);

    return railyApiService().get<RailyOfficerListResponse>({
      url,
      queryParams,
      abortSignal,
    });
  };

  return { add, getList };
};

export default railyOfficerApiService;
