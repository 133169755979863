import classNames from "classnames";
import { FC } from "react";
import MileageListingStatus from "../../common/types/mileage-listing-status";

type MileageListingTableStatusProps = {
  status: MileageListingStatus;
};

const MileageListingTableStatusComponent: FC<MileageListingTableStatusProps> = (
  props
) => {
  const getClassNameByStatus = (): string => {
    switch (props.status) {
      case MileageListingStatus.ACCEPTED:
        return "accepted";
      case MileageListingStatus.DELEGATION_CREATED:
        return "delegation_created";
      case MileageListingStatus.PENDING:
        return "pending";
      case MileageListingStatus.REJECTED:
        return "rejected";
      default:
        return "";
    }
  };

  const statusClassName = getClassNameByStatus();

  const containerClassNames = classNames(
    "mileage_listing_table_status",
    statusClassName
  );

  return <div className={containerClassNames}></div>;
};

export default MileageListingTableStatusComponent;
