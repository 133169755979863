import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import TaxiOfficerAddByTaxiFormData from "./types/taxi-officer-add-by-taxi-form-data";
import taxiOfficerAddByTaxiFormValidationService from "./taxi-officer-add-by-taxi-form-validation.service";

const getDefaultFormData = (): TaxiOfficerAddByTaxiFormData => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<TaxiOfficerAddByTaxiFormData> => {
    return {
      firstName: (values) =>
        taxiOfficerAddByTaxiFormValidationService.validateFirstName(
          values.firstName
        ),
      lastName: (values) =>
        taxiOfficerAddByTaxiFormValidationService.validateLastName(
          values.lastName
        ),
      email: (values) =>
        taxiOfficerAddByTaxiFormValidationService.validateEmail(values.email),
      username: async (values) =>
        await taxiOfficerAddByTaxiFormValidationService.validateUsername(
          values.username
        ),
      password: (values) =>
        taxiOfficerAddByTaxiFormValidationService.validatePassword(
          values.password
        ),
      passwordRepeat: (values) =>
        taxiOfficerAddByTaxiFormValidationService.validatePasswordRepeat(
          values.password,
          values.passwordRepeat
        ),
      phoneNumber: (values) =>
        taxiOfficerAddByTaxiFormValidationService.validatePhoneNumber(
          values.phoneNumber
        ),
      alternativePhoneNumber: (values) =>
        taxiOfficerAddByTaxiFormValidationService.validateAlternativePhoneNumber(
          values.alternativePhoneNumber
        ),
    };
  };

const taxiOfficerAddByTaxiFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default taxiOfficerAddByTaxiFormHelper;
