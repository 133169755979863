import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import passengerAddUserPermissionDefinition, {
  PassengerAddUserPermissions,
} from "./passenger-add-user-permission";

const getPermissions = (userRoles: UserRole[]): PassengerAddUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    passengerAddUserPermissionDefinition
  );

  return permissions;
};

const passengerAddUserPermissionsHelper = {
  getPermissions,
};

export default passengerAddUserPermissionsHelper;
