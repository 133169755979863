export enum CargoOrderDetailsItemStatus {
  PENDING = "PENDING",
  PLANNED = "PLANNED",
  CANDIDATURE = "CANDIDATURE",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export enum CargoOrderDetailsItemCargoOrderRideSequenceOrder {
  STRICT = "STRICT",
}

export enum CargoOrderDetailsItemCargoOrderCargoSourceType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export type CargoOrderDetailsItemUser = {
  uuid: string;
  username: string;
  lastName: string;
  firstName: string;
};

export type CargoOrderDetailsItemPassengerAddress = {
  uuid: string;
  lat: number;
  lon: number;
  country?: string;
  town?: string;
  zipCode?: string;
  street?: string;
  houseNo?: string;
  apartment?: string;
  description?: string;
  displayName: string;
};

export type CargoOrderDetailsItemPassengerAspect = {
  addresses: CargoOrderDetailsItemPassengerAddress[];
};

export type CargoOrderDetailsItemPassenger = CargoOrderDetailsItemUser & {
  passenger: CargoOrderDetailsItemPassengerAspect;
};

export type CargoOrderDetailsItemPassengerItemPassengerInternal = {
  uuid: string;
  type: CargoOrderDetailsItemCargoOrderCargoSourceType.INTERNAL;
  firstName: string;
  lastName: string;
  addresses: CargoOrderDetailsItemPassengerAddress[];
  externalId: string;
};

export type CargoOrderDetailsItemPassengerItemPassengerExternal = {
  uuid: string;
  type: CargoOrderDetailsItemCargoOrderCargoSourceType.EXTERNAL;
  firstName: string;
  lastName: string;
  dispatchName: string;
  externalId: string;
  mobile: string;
};

export type CargoOrderDetailsItemPassengerItemPassenger =
  | CargoOrderDetailsItemPassengerItemPassengerInternal
  | CargoOrderDetailsItemPassengerItemPassengerExternal;

export type CargoOrderDetailsItemPassengerItem = {
  uuid: string;
  passenger: CargoOrderDetailsItemPassengerItemPassenger;
};

export type CargoOrderDetailsItemNodeExclusions = {
  nodeIds: string[];
  excludeApproaching: boolean;
  excludeReturning: boolean;
  excludeInside: boolean;
};

export type CargoOrderDetailsItemCargoOrderMeta = {
  uuid: string;
};

export type CargoOrderDetailsItemCargoOrderRideMetaCargoSubject = {
  uuid: string;
};

export type CargoOrderDetailsItemCargoOrderRideMetaCargoSubjectList = {
  subjects: CargoOrderDetailsItemCargoOrderRideMetaCargoSubject[];
};

export type CargoOrderDetailsItemCargoOrderRideMeta =
  CargoOrderDetailsItemCargoOrderMeta & {
    cargoEnter: CargoOrderDetailsItemCargoOrderRideMetaCargoSubjectList;
    cargoExit: CargoOrderDetailsItemCargoOrderRideMetaCargoSubjectList;
  };

export type CargoOrderDetailsItemCargoOrderRideSeqNode = {
  lat: number;
  lon: number;
  time: Date | null;
  haltingTime: number;
  meta: CargoOrderDetailsItemCargoOrderRideMeta;
  displayName: string;
};

export type CargoOrderDetailsItemCargoOrderRideSeqNodeSet = {
  seq: CargoOrderDetailsItemCargoOrderRideSeqNode[];
  sequenceOrder: CargoOrderDetailsItemCargoOrderRideSequenceOrder;
  meta: CargoOrderDetailsItemCargoOrderMeta;
};
export type CargoOrderDetailsItemCargoOrderRideSeq = (
  | CargoOrderDetailsItemCargoOrderRideSeqNode
  | CargoOrderDetailsItemCargoOrderRideSeqNodeSet
)[];

export type CargoOrderDetailsItemCargoOrderRide = {
  seq: CargoOrderDetailsItemCargoOrderRideSeq;
  sequenceOrder: CargoOrderDetailsItemCargoOrderRideSequenceOrder;
  meta: CargoOrderDetailsItemCargoOrderMeta;
};

export type CargoOrderDetailsItemCargoOrderCargoSourceInternal = {
  sourceType: CargoOrderDetailsItemCargoOrderCargoSourceType.INTERNAL;
  meta?: string;
  externalId?: string;
  passengerUuid: string;
};

export type CargoOrderDetailsItemCargoOrderCargoSourceExternal = {
  sourceType: CargoOrderDetailsItemCargoOrderCargoSourceType.EXTERNAL;
  meta?: string;
  externalId?: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
  dispatchName?: string;
};

export type CargoOrderDetailsItemCargoOrderCargoSource =
  | CargoOrderDetailsItemCargoOrderCargoSourceInternal
  | CargoOrderDetailsItemCargoOrderCargoSourceExternal;

export type CargoOrderDetailsItemCargoOrderCargoSourceElement = {
  cargoSubjectUuid: string;
  cargoItemSource: CargoOrderDetailsItemCargoOrderCargoSource;
};

export type CargoOrderDetailsItemCargoOrder = {
  uuid: string;
  ride: CargoOrderDetailsItemCargoOrderRide;
  cargoItemsSourceList: CargoOrderDetailsItemCargoOrderCargoSourceElement[];
};

type CargoOrderDetails = {
  uuid: string;
  passengers: CargoOrderDetailsItemPassenger[];
  cargoOrder: CargoOrderDetailsItemCargoOrder;
  status: CargoOrderDetailsItemStatus;
  nodeExclusions: CargoOrderDetailsItemNodeExclusions;
  forceAllowToll: boolean;
};

export default CargoOrderDetails;
