import { FC, useMemo } from "react";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../common/components/form/input/input.component";
import NumericInputComponent from "../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import driverEditFormHelper from "../driver-edit-form.helper";
import DriverEditVehicleFormData from "../types/driver-edit-vehicle-form-data";
import DriverEditVehicleFormValidationResults from "../types/driver-edit-vehicle-form-validation-results";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";

type DriverEditVehicleDataProps = {
  formData: DriverEditVehicleFormData;
  formValidationResults: DriverEditVehicleFormValidationResults;
  onFormDataChange: (formData: DriverEditVehicleFormData) => void;
  validateMake: () => void;
  validateModel: () => void;
  validateProductionYear: () => void;
  validateNumberOfSeats: () => void;
  validateRegistrationNumber: () => void;
  validateOwnership: (value: DriverEditVehicleFormData["ownership"]) => void;
};

const DriverEditVehicleDataComponent: FC<DriverEditVehicleDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.vehicleData;

  const ownershipSelectOptions = useMemo(() => {
    return driverEditFormHelper.getVehicleOwnershipSelectOptions();
  }, []);

  const setMake = (value: DriverEditVehicleFormData["make"]) => {
    const newFormData: DriverEditVehicleFormData = {
      ...props.formData,
      make: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setModel = (value: DriverEditVehicleFormData["model"]) => {
    const newFormData: DriverEditVehicleFormData = {
      ...props.formData,
      model: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setProductionYear = (
    value: DriverEditVehicleFormData["productionYear"]
  ) => {
    const newFormData: DriverEditVehicleFormData = {
      ...props.formData,
      productionYear: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setNumberOfSeats = (
    value: DriverEditVehicleFormData["numberOfSeats"]
  ) => {
    const newFormData: DriverEditVehicleFormData = {
      ...props.formData,
      numberOfSeats: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setRegistrationNumber = (
    value: DriverEditVehicleFormData["registrationNumber"]
  ) => {
    const newFormData: DriverEditVehicleFormData = {
      ...props.formData,
      registrationNumber: value.toUpperCase(),
    };
    props.onFormDataChange(newFormData);
  };

  const setOwnership = (value: DriverEditVehicleFormData["ownership"]) => {
    const newFormData: DriverEditVehicleFormData = {
      ...props.formData,
      ownership: value,
    };
    props.onFormDataChange(newFormData);
  };

  const onOwnershipChange = (value: DriverEditVehicleFormData["ownership"]) => {
    setOwnership(value);
    props.validateOwnership(value);
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.makeLabel}
            errorMessage={props.formValidationResults.make.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.makePlaceholder}
              value={props.formData.make}
              onChange={setMake}
              onBlur={props.validateMake}
              hasError={!!props.formValidationResults.make.errorMessage}
              idForTesting="vehicle-make"
            />
          </FormFieldComponent>
        </Column>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.modelLabel}
            errorMessage={props.formValidationResults.model.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.modelPlaceholder}
              value={props.formData.model}
              onChange={setModel}
              onBlur={props.validateModel}
              hasError={!!props.formValidationResults.model.errorMessage}
              idForTesting="vehicle-model"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.productionYearLabel}
            errorMessage={
              props.formValidationResults.productionYear.errorMessage
            }
          >
            <NumericInputComponent
              placeholder={translations.productionYearPlaceholder}
              value={props.formData.productionYear}
              isIntegerOnly
              onChange={setProductionYear}
              onBlur={props.validateProductionYear}
              hasError={
                !!props.formValidationResults.productionYear.errorMessage
              }
              idForTesting="vehicle-production-year"
            />
          </FormFieldComponent>
        </Column>

        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.numberOfSeatsLabel}
            errorMessage={
              props.formValidationResults.numberOfSeats.errorMessage
            }
          >
            <NumericInputComponent
              placeholder={translations.numberOfSeatsPlaceholder}
              value={props.formData.numberOfSeats}
              isIntegerOnly
              onChange={setNumberOfSeats}
              onBlur={props.validateNumberOfSeats}
              hasError={
                !!props.formValidationResults.numberOfSeats.errorMessage
              }
              idForTesting="vehicle-number-of-seats"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.registrationNumberLabel}
            errorMessage={
              props.formValidationResults.registrationNumber.errorMessage
            }
          >
            <InputComponent
              placeholder={translations.registrationNumberPlaceholder}
              value={props.formData.registrationNumber}
              onChange={setRegistrationNumber}
              onBlur={props.validateRegistrationNumber}
              hasError={
                !!props.formValidationResults.registrationNumber.errorMessage
              }
              idForTesting="vehicle-registration-number"
            />
          </FormFieldComponent>
        </Column>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.ownershipLabel}
            errorMessage={props.formValidationResults.ownership.errorMessage}
          >
            <SingleSelectComponent
              placeholder={translations.ownershipPlaceholder}
              value={props.formData.ownership}
              options={ownershipSelectOptions}
              onChange={(selectedOptions) => onOwnershipChange(selectedOptions)}
              hasError={!!props.formValidationResults.ownership.errorMessage}
              idForTesting="vehicle-ownership"
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default DriverEditVehicleDataComponent;
