import { FC } from "react";
import taxiOrderSettlementListingFiltersBadgeListHelper from "./taxi-order-settlement-listing-filters-badge-list.helper";
import TaxiOrderSettlementListingFilter from "../../types/taxi-order-settlement-listing-filter";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type TaxiOrderSettlementListingFiltersListProps = {
  filters: TaxiOrderSettlementListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const TaxiOrderSettlementListingFiltersBadgeListComponent: FC<
  TaxiOrderSettlementListingFiltersListProps
> = (props) => {
  const badges = taxiOrderSettlementListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default TaxiOrderSettlementListingFiltersBadgeListComponent;
