import { FC, useEffect, useMemo, useState } from "react";
import RelatedRailyTaxiBillingTableRow from "./types/related-raily-taxi-billing-table-row";
import relatedBillingsApiService from "../api/related-billings-api.service";
import relatedRailyTaxiBillingsDataFactory from "./factory/related-raily-taxi-billings-data.factory";
import RelatedRailyTaxiBillingData from "./types/related-raily-taxi-billing data";
import relatedOrdersBillingsHelper from "./related-raily-taxi-billings.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RelatedRailyTaxiBillingsResponse from "../api/related-raily-taxi-billings.response";
import dateService from "../../../../../../common/utils/date/date.service";
import LinkButtonComponent from "../../../../../../common/components/button/link/link-button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import TableComponent from "../../../../../../common/components/table/table.component";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

type RelatedRailyTaxiBillingsProps = {
  orderId: string | undefined;
};

const RelatedRailyTaxiBillingsComponent: FC<RelatedRailyTaxiBillingsProps> = (
  props
) => {
  const [billingsData, setBillingsData] =
    useState<RelatedRailyTaxiBillingData[]>();
  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);

  useEffect(() => {
    if (props.orderId) {
      setIsBillingDataLoading(true);
      relatedBillingsApiService
        .fetchRailyTaxiRelatedBillings(props.orderId)
        .then(handleBillingDataResponse)
        .finally(() => {
          setIsBillingDataLoading(false);
        });
    }
  }, [props.orderId]);

  const handleBillingDataResponse = (
    response: RelatedRailyTaxiBillingsResponse
  ) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const onBillingDataFetchSuccess = (
    response: RelatedRailyTaxiBillingsResponse
  ) => {
    const billingsData = relatedRailyTaxiBillingsDataFactory.createBillingsData(
      response.data
    );

    setBillingsData(billingsData);
  };

  const onTableRowClick = () => {};

  const createTableRow = (
    billingData: RelatedRailyTaxiBillingData
  ): RelatedRailyTaxiBillingTableRow => {
    return {
      id: billingData.internalOrderId.toString(),
      onClick: onTableRowClick,
      value: {
        date: (
          <div title={dateService.format(billingData.date, "dd/mm/yyyy")}>
            {dateService.format(billingData.date, "dd/mm/yyyy")}
          </div>
        ),
        internalOrderId: (
          <div title={billingData.internalOrderId.toString()}>
            {billingData.internalOrderId}
          </div>
        ),
        companyName: (
          <div title={billingData.contractDetails?.companyName}>
            {billingData.contractDetails?.companyName}
          </div>
        ),
        model: (
          <div title={billingData.contractDetails?.model}>
            {billingData.contractDetails?.model}
          </div>
        ),
        rate: (
          <div title={billingData.contractDetails?.distanceRate.toString()}>
            {billingData.contractDetails?.distanceRate.toFixed(2)}
          </div>
        ),
        distance: (
          <div title={billingData.distance.toString()}>
            {billingData.distance}
          </div>
        ),
        routeDistanceCost: (
          <div title={billingData.routeDistanceCost.toString()}>
            {billingData.routeDistanceCost.toFixed(2)}
          </div>
        ),
        bonus: (
          <div title={billingData.bonus.toString()}>
            {billingData.bonus.toFixed(2)}
          </div>
        ),
        penalty: (
          <div title={billingData.penalty.toString()}>
            {billingData.penalty.toFixed(2)}
          </div>
        ),
        haltingCost: (
          <div title={billingData.haltingCost.toString()}>
            {billingData.haltingCost.toFixed(2)}
          </div>
        ),
        highwayCost: (
          <div title={billingData.highwayCharge.toString()}>
            {billingData.highwayCharge.toFixed(2)}
          </div>
        ),
        total: (
          <div title={billingData?.total.toString()}>
            {billingData.total.toFixed(2)}
          </div>
        ),
        actions: (
          <LinkButtonComponent
            type="primary"
            to={""}
            classNames={{
              root: "related_order_billings_table_edit_billing_icon",
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </LinkButtonComponent>
        ),
      },
    };
  };

  const tableColumns = relatedOrdersBillingsHelper.getTableColumns();

  const tableRows: RelatedRailyTaxiBillingTableRow[] = useMemo(() => {
    if (billingsData) {
      return billingsData.map((listingItem) => createTableRow(listingItem));
    }

    return [];
  }, [billingsData]);

  const translations =
    billingsTranslationsHelper.getTaxiRailyAddBillingsTranslations()
      .relatedBillings.raily_taxi;

  return (
    <CardComponent
      classNames={{ root: "related_order_billings" }}
      header={{ title: translations.summaryLabel }}
    >
      <TableComponent
        isLoading={isBillingDataLoading}
        columns={tableColumns}
        rows={tableRows}
      />
    </CardComponent>
  );
};

export default RelatedRailyTaxiBillingsComponent;
