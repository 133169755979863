import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DealerAddUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type DealerAddUserPermissionsDefinition =
  UserPermissionsDefinition<DealerAddUserPermissionOption>;

export type CargoOfficerAddUserPermissions =
  UserPermissions<DealerAddUserPermissionOption>;

const dealerAddUserPermissionDefinition: DealerAddUserPermissionsDefinition = {
  hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
  hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
};

export default dealerAddUserPermissionDefinition;
