import taxiUnsettledOrderListingRouteQueryParamsService from "../../common/taxi-unsettled-order-listing-route-query-params.service";
import TaxiUnsettledOrderListingFilter from "../../common/types/taxi-unsettled-order-listing-filter";
import TaxiUnsettledOrderListingSortKey from "../../common/types/taxi-unsettled-order-listing-sort-key";
import TaxiUnsettledOrderListingByTaxiRouteQueryParams from "./types/taxi-unsettled-order-listing-by-taxi-route-query-params";

const createRouteQueryParams = (
  filters: TaxiUnsettledOrderListingFilter[],
  sortKey: TaxiUnsettledOrderListingSortKey | null,
  page: number,
  pageSize: number
): TaxiUnsettledOrderListingByTaxiRouteQueryParams => {
  const routeQueryParams: TaxiUnsettledOrderListingByTaxiRouteQueryParams =
    taxiUnsettledOrderListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return routeQueryParams;
};

const taxiUnsettledOrderListingByTaxiRouteQueryParamsService = {
  getFilters: taxiUnsettledOrderListingRouteQueryParamsService.getFilters,
  getSortKey: taxiUnsettledOrderListingRouteQueryParamsService.getSortKey,
  getPage: taxiUnsettledOrderListingRouteQueryParamsService.getPage,
  getPageSize: taxiUnsettledOrderListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams,
};

export default taxiUnsettledOrderListingByTaxiRouteQueryParamsService;
