import { FC, useEffect, useState } from "react";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import TaxiTaxiContractAddFormData from "./common/types/taxi-taxi-contract-add-form-data";
import taxiTaxiContractAddFormHelper from "./common/taxi-taxi-contract-add-form.helper";
import { useNavigate, useParams } from "react-router-dom";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import notificationService from "../../../../common/utils/notification/notification.service";
import TaxiTaxiContractAddRouteParams from "../../common/routes/types/taxi-taxi-contract-add-route-params";
import BooleanSelectComponent from "../../../../common/components/form/select/boolean/boolean-select.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";
import useForm from "../../../../common/components/form/use-form";
import useTaxiCorporationList from "../../../../common/services/taxi-corporation/list/use-taxi-corporation-list";
import useTaxiCorporationDetails from "../../../../common/services/taxi-corporation/details/use-taxi-corporation-details";
import useAbort from "../../../../common/hooks/use-abort";
import TaxiCorporationSingleSelectComponent from "../../../../common/components/form/select/taxi-corporation/taxi-corporation-single-select.component";
import BillingModelSingleSelectComponent from "../../../../common/components/form/select/billing-model/billing-model-single-select.component";
import taxiTaxiContractService from "../../../../common/services/taxi-taxi-contract/taxi-taxi-contract.service";
import taxiTaxiContractAddParamsFactory from "./common/taxi-taxi-contract-add-params.factory";
import TaxiTaxiContractAddParams from "../../../../common/services/taxi-taxi-contract/add/taxi-taxi-contract-add-params";

type TaxiTaxiContractAddProps = {};

const TaxiTaxiContractAddComponent: FC<TaxiTaxiContractAddProps> = () => {
  const translations = taxiTranslationsHelper.getTaxiContractAddTranslations();

  const { taxiCorporationUuid } = useParams<TaxiTaxiContractAddRouteParams>();
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const navigate = useNavigate();

  useDocumentTitle(documentTitleTranslations.taxiTaxiContractAdd);

  const taxiDetails = useTaxiCorporationDetails();
  const taxiDetailsAbort = useAbort();

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    taxiDetails.load(
      {
        taxiCorporationUuid,
      },
      taxiDetailsAbort.signal
    );
  }, [taxiCorporationUuid]);

  useEffect(() => {
    const breadcrumbs = taxiBreadcrumbsHelper.getTaxiContractAddBreadcrumbs({
      taxiCorporationUuid: taxiCorporationUuid!,
      taxiCorporationDisplayName: taxiDetails.data?.displayName,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, taxiDetails.data]);

  const taxiCorporationDetails = useTaxiCorporationDetails();
  const taxiCorporationDetailsAbort = useAbort();

  useEffect(() => {
    taxiCorporationDetails.load(
      { taxiCorporationUuid: taxiCorporationUuid! },
      taxiCorporationDetailsAbort.signal
    );
  }, []);

  const taxiCorporationList = useTaxiCorporationList();
  const taxiCorporationListAbort = useAbort();

  const taxiTaxiContractAddAbort = useAbort();

  useEffect(() => {
    taxiCorporationList.load({}, taxiCorporationListAbort.signal);
  }, []);

  const form = useForm<TaxiTaxiContractAddFormData>({
    emptyValues: taxiTaxiContractAddFormHelper.getEmptyFormData(),
    validationDefinition:
      taxiTaxiContractAddFormHelper.getValidationDefinition(),
  });

  const [isContractAddPending, setIsContractAddPending] = useState(false);

  const onContractAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);

    navigate(
      taxiRoutesHelper.getTaxiContractListingRoute({
        taxiCorporationUuid: taxiCorporationUuid!,
      })
    );
  };

  const onContractAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const addContract = async () => {
    setIsContractAddPending(true);

    const params: TaxiTaxiContractAddParams =
      taxiTaxiContractAddParamsFactory.create(
        form.values,
        taxiCorporationDetails.data!
      );

    try {
      await taxiTaxiContractService.add(
        params,
        taxiTaxiContractAddAbort.signal
      );

      onContractAddSuccess();
    } catch {
      onContractAddFailure();
    } finally {
      setIsContractAddPending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    addContract();
  };

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.sourceTaxiCorporationLabel}
                >
                  {taxiCorporationDetails.data?.displayName}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.targetTaxiCorporationLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.targetTaxiCorporationUuid
                      .errorMessage
                  }
                >
                  <TaxiCorporationSingleSelectComponent
                    placeholder={
                      translations.form.targetTaxiCorporationPlaceholder
                    }
                    selectedTaxiCorporationUuid={
                      form.values.targetTaxiCorporationUuid
                    }
                    onChange={(option) =>
                      form.setValue(
                        "targetTaxiCorporationUuid",
                        option?.uuid ?? null
                      )
                    }
                    onBlur={() => form.validate("targetTaxiCorporationUuid")}
                    isError={
                      !!form.validationResults.targetTaxiCorporationUuid
                        .errorMessage
                    }
                    taxiCorporationList={taxiCorporationList.data.data.filter(
                      (item) => item.uuid !== taxiCorporationUuid
                    )}
                    isLoading={taxiCorporationList.isLoading}
                    isDisabled={taxiCorporationList.isError}
                    idForTesting="taxi-taxi-contract-add-taxi-corporation"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.periodOfValidityLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.periodOfValidity.errorMessage
                  }
                >
                  <DateRangeInputComponent
                    placeholder={translations.form.periodOfValidityPlaceholder}
                    date={form.values.periodOfValidity}
                    onChange={(dateRange) => {
                      form.setValue("periodOfValidity", dateRange);
                      form.validate("periodOfValidity", dateRange);
                    }}
                    hasError={
                      !!form.validationResults.periodOfValidity.errorMessage
                    }
                    idForTesting="taxi-taxi-contract-add-period-validity"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.displayNameLabel}
                  isRequired
                  errorMessage={form.validationResults.displayName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.displayNamePlaceholder}
                    value={form.values.displayName ?? undefined}
                    onChange={(value) => form.setValue("displayName", value)}
                    onBlur={() => form.validate("displayName")}
                    hasError={!!form.validationResults.displayName.errorMessage}
                    idForTesting="taxi-taxi-contract-add-display-name"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.distanceRateLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.distanceRate.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={translations.form.distanceRatePlaceholder}
                    value={form.values.distanceRate}
                    onChange={(value) => form.setValue("distanceRate", value)}
                    decimalPrecision={2}
                    hasError={
                      !!form.validationResults.distanceRate.errorMessage
                    }
                    onBlur={() => form.validate("distanceRate")}
                    idForTesting="taxi-taxi-contract-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.billingModelLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.billingModel.errorMessage
                  }
                >
                  <BillingModelSingleSelectComponent
                    placeholder={translations.form.billingModelPlaceholder}
                    value={form.values.billingModel}
                    onChange={(value) => form.setValue("billingModel", value)}
                    onBlur={() => form.validate("billingModel")}
                    isError={!!form.validationResults.billingModel.errorMessage}
                    idForTesting="taxi-taxi-contract-add-billing-model"
                  />
                </FormFieldComponent>
              </Column>
            </Row>

            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.freeStoppingPeriodLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.freeStoppingPeriodMinutes
                      .errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.freeStoppingPeriodPlaceholder
                    }
                    value={form.values.freeStoppingPeriodMinutes}
                    onChange={(value) =>
                      form.setValue("freeStoppingPeriodMinutes", value)
                    }
                    isIntegerOnly
                    hasError={
                      !!form.validationResults.freeStoppingPeriodMinutes
                        .errorMessage
                    }
                    onBlur={() => form.validate("freeStoppingPeriodMinutes")}
                    idForTesting="taxi-taxi-contract-add-free-stopping-period"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.stoppingRateLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.stoppingRate.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={translations.form.stoppingRatePlaceholder}
                    value={form.values.stoppingRate}
                    decimalPrecision={2}
                    onChange={(value) => form.setValue("stoppingRate", value)}
                    hasError={
                      !!form.validationResults.stoppingRate.errorMessage
                    }
                    onBlur={() => form.validate("stoppingRate")}
                    idForTesting="taxi-taxi-contract-add-stopping-rate"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.isActiveLabel}
                  isRequired
                  errorMessage={form.validationResults.isActive.errorMessage}
                >
                  <BooleanSelectComponent
                    placeholder={translations.form.isActivePlaceholder}
                    value={form.values.isActive}
                    onChange={(value) => form.setValue("isActive", value)}
                    isError={!!form.validationResults.isActive.errorMessage}
                    onBlur={() => form.validate("isActive")}
                    idForTesting="taxi-taxi-contract-add-is-active"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.priorityLabel}
                  errorMessage={form.validationResults.priority.errorMessage}
                  isRequired
                >
                  <NumericInputComponent
                    placeholder={translations.form.priorityPlaceholder}
                    value={form.values.priority}
                    isIntegerOnly
                    onChange={(value) => form.setValue("priority", value)}
                    onBlur={() => form.validate("priority")}
                    hasError={!!form.validationResults.priority.errorMessage}
                    idForTesting="taxi-taxi-contract-add-priority"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
          <ButtonComponent
            onClick={onSubmitButtonClick}
            type="primary"
            isLoading={isContractAddPending}
            idForTesting="taxi-taxi-contract-add-submit-button"
            classNames={{ root: "mt-4" }}
            title={translations.form.submitButtonTitle}
          >
            {translations.form.submitButtonLabel}
          </ButtonComponent>
        </Column>
      </Row>
    </>
  );
};

export default TaxiTaxiContractAddComponent;
