import MileageUpdateRequest, {
  MileageUpdateRequestBody,
} from "./mileage-update-request";

const createBody = (
  request: MileageUpdateRequest
): MileageUpdateRequestBody => {
  return {
    mileageDate: request.mileageDate,
    distance: request.distance,
    state: request.state,
    vehicleType: request.vehicleType,
    updateReason: request.updateReason,
    addressSequence: request.addressSequence
      ? {
          seq: request.addressSequence,
        }
      : undefined,
    acceptedDistance: request.acceptedDistance,
    osrmDistance: request.osrmDistance,
  };
};

const mileageUpdateRequestFactory = {
  createBody,
};

export default mileageUpdateRequestFactory;
