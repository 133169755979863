import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import CargoAddressListingFilter from "../../common/types/cargo-address-listing-filter";
import cargoAddressListingFiltersBadgeHelper from "./cargo-address-listing-filters-badge.helper";

type CargoAddressListingFiltersBadgeProps = {
  filter: CargoAddressListingFilter;
  onDeleteButtonClick: () => void;
};

const CargoAddressListingFiltersBadgeComponent: FC<
  CargoAddressListingFiltersBadgeProps
> = (props) => {
  const badgeName = cargoAddressListingFiltersBadgeHelper.getBadgeName(
    props.filter
  );
  const badgeTitle = cargoAddressListingFiltersBadgeHelper.getBadgeTitle(
    props.filter
  );

  return (
    <div className="cargo_address_listing_filters_badge" title={badgeTitle}>
      <div className="cargo_address_listing_filters_badge__text">
        {badgeName}
      </div>
      <ButtonComponent
        onClick={props.onDeleteButtonClick}
        classNames={{
          root: `cargo_address_listing_filters_badge__button`,
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </ButtonComponent>
    </div>
  );
};

export default CargoAddressListingFiltersBadgeComponent;
