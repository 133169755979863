import { MessengerPostChannel } from "./messenger-post";

export enum MessengerPostLoadParamsBillingType {
  CARGO = "CARGO",
  TAXI = "TAXI",
}

type MessengerPostLoadParams = {
  availableChannels: MessengerPostChannel[];
  billingType?: MessengerPostLoadParamsBillingType;
  billingUuid?: string;
  orderUuid?: string;
  planEntryUuid?: string;
  solvedOrderUuid?: string;
  taxiCorporationUuid?: string;
};

export default MessengerPostLoadParams;
