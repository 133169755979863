import { FC } from "react";
import CardEditButtonComponent from "../../../../../../common/components/card/button/add/card-edit-button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import { useSignUpPassengerContext } from "../../common/context/sign-up-passenger.context";
import SignUpPassengerFormStep from "../../common/types/sign-up-passenger-form-step";

const SignUpPassengerSummaryAccountComponent: FC = () => {
  const translations =
    appTranslationsHelper.getPassengerSignUpTranslations().summary.accountData;

  const { accountData, setCurrentFormStep } = useSignUpPassengerContext();

  const onEditButtonClick = () => {
    setCurrentFormStep(SignUpPassengerFormStep.accountData);
  };

  return (
    <CardComponent
      header={{
        title: translations.title,
        actions: [
          <CardEditButtonComponent
            onClick={onEditButtonClick}
            title={translations.editButtonText}
          />,
        ],
      }}
    >
      <Row>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={translations.emailLabel}
            classNames={{ root: "mt-0" }}
          >
            {accountData.email}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={translations.usernameLabel}
            classNames={{ root: "mt-0" }}
          >
            {accountData.username}
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default SignUpPassengerSummaryAccountComponent;
