export enum DelegationDownloadParamsFileFormat {
  PDF = "PDF",
  CSV = "CSV",
}

export enum DelegationDownloadParamsTimezone {
  EUROPE_WARSAW = "Europe/Warsaw",
  UTC = "UTC",
}

export type DelegationDownloadParamsSeparatorTypes = ";" | ",";

type DelegationDownloadParams = {
  delegationUuids: string[];
  fileFormat: DelegationDownloadParamsFileFormat;
  separator?: DelegationDownloadParamsSeparatorTypes;
  timezone?: DelegationDownloadParamsTimezone;
};

export default DelegationDownloadParams;
