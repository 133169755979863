import { FC, useEffect, useState } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../../../../../common/components/modal/modal.component";
import FormFieldComponent from "../../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../../common/components/form/input/input.component";
import ButtonComponent from "../../../../../../../../common/components/button/button.component";
import OrderRouteEditExternalPassengerEditFormData from "./common/types/order-route-edit-external-passenger-edit-form-data";
import orderRouteEditExternalPassengerEditHelper from "./common/order-route-edit-external-passenger-edit-form.helper";
import { OrderRouteEditExternalPassenger } from "../../../../types/order-route-edit-passenger";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import OrderRouteEditExternalPassengerEditFormValidationResults from "./common/types/order-route-edit-external-passenger-edit-form-validation-results";
import Row from "../../../../../../../../common/components/grid/row";
import Column from "../../../../../../../../common/components/grid/column";
import orderRouteEditExternalPassengerEditFormValidationService from "./order-route-edit-external-passenger-edit-form-validation.service";

type OrderRouteEditExternalPassengerEditProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  passenger: OrderRouteEditExternalPassenger;
  onSubmit: (passenger: OrderRouteEditExternalPassenger) => void;
};

const OrderRouteEditExternalPassengerEditComponent: FC<
  OrderRouteEditExternalPassengerEditProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getEditTranslations().passengers.edit;

  const [formData, setFormData] =
    useState<OrderRouteEditExternalPassengerEditFormData>(() =>
      orderRouteEditExternalPassengerEditHelper.getDefaultFormData(
        props.passenger
      )
    );

  const [formValidationResults, setFormValidationResults] =
    useState<OrderRouteEditExternalPassengerEditFormValidationResults>(() =>
      orderRouteEditExternalPassengerEditHelper.getDefaultFormValidationResults()
    );

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    setFormValidationResults(
      orderRouteEditExternalPassengerEditHelper.getDefaultFormValidationResults()
    );
    setFormData(
      orderRouteEditExternalPassengerEditHelper.getDefaultFormData(
        props.passenger
      )
    );
  }, [props.isOpen]);

  const setExternalId = (
    value: OrderRouteEditExternalPassengerEditFormData["externalId"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      externalId: value,
    }));
  };

  const setDispatchName = (
    value: OrderRouteEditExternalPassengerEditFormData["dispatchName"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      dispatchName: value,
    }));
  };

  const setFirstName = (
    value: OrderRouteEditExternalPassengerEditFormData["firstName"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      firstName: value,
    }));
  };

  const setLastName = (
    value: OrderRouteEditExternalPassengerEditFormData["lastName"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      lastName: value,
    }));
  };

  const setMobile = (
    value: OrderRouteEditExternalPassengerEditFormData["mobile"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      mobile: value,
    }));
  };

  const validateExternalId = (
    value: OrderRouteEditExternalPassengerEditFormData["externalId"] = formData.externalId
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerEditFormValidationService.validateExternalId(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      externalId: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateFirstName = (
    value: OrderRouteEditExternalPassengerEditFormData["firstName"] = formData.firstName
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerEditFormValidationService.validateFirstName(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateLastName = (
    value: OrderRouteEditExternalPassengerEditFormData["lastName"] = formData.lastName
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerEditFormValidationService.validateLastName(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDispatchName = (
    value: OrderRouteEditExternalPassengerEditFormData["dispatchName"] = formData.dispatchName
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerEditFormValidationService.validateDispatchName(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      dispatchName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateMobile = (
    value: OrderRouteEditExternalPassengerEditFormData["mobile"] = formData.mobile
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerEditFormValidationService.validateMobile(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateForm = () => {
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const isMobileNameValid = validateMobile();
    const isExternalIdValid = validateExternalId();
    const isDispatchNameValid = validateDispatchName();

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isMobileNameValid &&
      isExternalIdValid &&
      isDispatchNameValid
    );
  };

  const onSubmitButtonClick = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    const newPassenger: OrderRouteEditExternalPassenger = {
      ...props.passenger,
      dispatchName: formData.dispatchName.trim(),
      externalId: formData.externalId.trim(),
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      mobile: formData.mobile.trim(),
    };

    props.onSubmit(newPassenger);
  };

  const onCloseButtonClick = () => {
    props.onClose();
  };

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={{
        title: translations.headingText.replace(
          "#{passengerFullName}",
          `${props.passenger.firstName} ${props.passenger.lastName}`
        ),
      }}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          title={translations.submitButtonTitle}
          idForTesting="order-route-edit-external-passenger-edit-submit-button"
        >
          {translations.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={onCloseButtonClick}
          type="brand"
          title={translations.cancelButtonTitle}
          idForTesting="order-route-edit-external-passenger-edit-cancel-button"
        >
          {translations.cancelButtonText}
        </ButtonComponent>,
      ]}
    >
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.firstNameLabel}
            errorMessage={formValidationResults.firstName.errorMessage}
            isRequired
          >
            <InputComponent
              value={formData.firstName}
              onChange={setFirstName}
              placeholder={translations.form.firstNamePlaceholder}
              hasError={!!formValidationResults.firstName.errorMessage}
              onBlur={validateFirstName}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.lastNameLabel}
            errorMessage={formValidationResults.lastName.errorMessage}
            isRequired
          >
            <InputComponent
              value={formData.lastName}
              onChange={setLastName}
              placeholder={translations.form.lastNamePlaceholder}
              hasError={!!formValidationResults.lastName.errorMessage}
              onBlur={validateLastName}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.mobileLabel}
            errorMessage={formValidationResults.mobile.errorMessage}
            isRequired
          >
            <InputComponent
              value={formData.mobile}
              onChange={setMobile}
              placeholder={translations.form.mobilePlaceholder}
              hasError={!!formValidationResults.mobile.errorMessage}
              onBlur={validateMobile}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.externalIdLabel}
            errorMessage={formValidationResults.externalId.errorMessage}
          >
            <InputComponent
              value={formData.externalId}
              onChange={setExternalId}
              placeholder={translations.form.externalIdPlaceholder}
              hasError={!!formValidationResults.externalId.errorMessage}
              onBlur={validateExternalId}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.dispatchLabel}
            errorMessage={formValidationResults.dispatchName.errorMessage}
          >
            <InputComponent
              value={formData.dispatchName}
              onChange={setDispatchName}
              placeholder={translations.form.dispatchPlaceholder}
              hasError={!!formValidationResults.dispatchName.errorMessage}
              onBlur={validateDispatchName}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </ModalComponent>
  );
};

export default OrderRouteEditExternalPassengerEditComponent;
