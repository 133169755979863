import CargoOrderDetailsHandlingLogLoadParams from "../../../../../../common/services/cargo-order/handling-log/cargo-order-details-handling-log-load-params";

const create = (
  orderId: string,
  creationEnd?: Date,
  creationStart?: Date
): CargoOrderDetailsHandlingLogLoadParams => {
  return {
    orderId: orderId,
    creationEnd: creationEnd || undefined,
    creationStart: creationStart || undefined,
  };
};

const orderDetailsHandlingLogLoadParamsFactory = {
  create,
};

export default orderDetailsHandlingLogLoadParamsFactory;
