import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateService from "../../../../../../../common/utils/date/date.service";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import DriverContractListingByRailyListingItem from "../common/types/driver-contract-listing-by-raily-listing-item";
import DriverContractListingByRailyTableColumn from "../common/types/driver-contract-listing-by-raily-table-column";
import DriverContractListingByRailyTableRow from "../common/types/driver-contract-listing-by-raily-table-row";
import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import TableLinkButtonComponent from "../../../../../../../common/components/table/button/link/table-link-button.component";
import userRoutesHelper from "../../../../../common/routes/user-routes.helper";

const getColumns = (): DriverContractListingByRailyTableColumn[] => {
  const translations =
    userTranslationsHelper.getDriverContractListingTranslations().table.headers;

  const columns: DriverContractListingByRailyTableColumn[] = [
    {
      header: translations.taxiCorporationLabel,
      title: translations.taxiCorporationTitle,
      accessor: "taxiCorporation",
      colSpan: 10,
    },
    {
      header: translations.periodOfValidityFromDateLabel,
      title: translations.periodOfValidityFromDateTitle,
      accessor: "periodOfValidityFromDate",
      colSpan: 10,
    },
    {
      header: translations.periodOfValidityToDateLabel,
      title: translations.periodOfValidityToDateTitle,
      accessor: "periodOfValidityToDate",
      colSpan: 10,
    },
    {
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      accessor: "billingModel",
      colSpan: 10,
    },
    {
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      accessor: "distanceRate",
      colSpan: 10,
    },
    {
      header: translations.stoppingRateLabel,
      title: translations.stoppingRateTitle,
      accessor: "stoppingRate",
      colSpan: 10,
    },
    {
      header: translations.freeStoppingPeriodLabel,
      title: translations.freeStoppingPeriodTitle,
      accessor: "freeStoppingPeriodMinutes",
      colSpan: 10,
    },
    {
      header: translations.isActiveLabel,
      title: translations.isActiveTitle,
      accessor: "isActive",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];

  return columns;
};

const getRow = (
  listingItem: DriverContractListingByRailyListingItem,
  driverUuid: string
): DriverContractListingByRailyTableRow => {
  const translations =
    userTranslationsHelper.getDriverContractListingTranslations().table;

  const periodOfValidityFromDateLabel = dateService.formatDate(
    listingItem.periodOfValidity.from
  );

  const periodOfValidityFromDateTitle = dateService.formatDate(
    listingItem.periodOfValidity.from
  );

  const periodOfValidityToDateLabel = dateService.formatDate(
    listingItem.periodOfValidity.to
  );

  const periodOfValidityToDateTitle = dateService.formatDate(
    listingItem.periodOfValidity.to
  );

  const distanceRateLabel = translations.distanceRateTemplateText.replace(
    "#{distanceRate}",
    String(listingItem.distanceRate)
  );

  const distanceRateTitle = translations.distanceRateTemplateText.replace(
    "#{distanceRate}",
    String(listingItem.distanceRate)
  );

  const haltingTimeRateLabel = translations.stopRateTemplateText.replace(
    "#{stoppingRate}",
    String(listingItem.stoppingRate)
  );

  const haltingTimeRateTitle = translations.stopRateTemplateText.replace(
    "#{stoppingRate}",
    String(listingItem.stoppingRate)
  );

  const haltingTimeGracePeriodLabel =
    translations.freeStoppingPeriodTemplateText.replace(
      "#{freeStoppingPeriod}",
      String(listingItem.freeStoppingPeriodMinutes)
    );

  const haltingTimeGracePeriodTitle =
    translations.freeStoppingPeriodTemplateText.replace(
      "#{freeStoppingPeriod}",
      String(listingItem.freeStoppingPeriodMinutes)
    );

  return {
    id: listingItem.uuid,
    value: {
      taxiCorporation: (
        <div title={listingItem.taxiCorporation}>
          {listingItem.taxiCorporation}
        </div>
      ),
      periodOfValidityFromDate: (
        <div title={periodOfValidityFromDateTitle}>
          {periodOfValidityFromDateLabel}
        </div>
      ),
      periodOfValidityToDate: (
        <div title={periodOfValidityToDateTitle}>
          {periodOfValidityToDateLabel}
        </div>
      ),
      billingModel: (
        <div title={listingItem.billingModel}>{listingItem.billingModel}</div>
      ),
      distanceRate: <div title={distanceRateTitle}>{distanceRateLabel}</div>,
      stoppingRate: (
        <div title={haltingTimeRateTitle}>{haltingTimeRateLabel}</div>
      ),
      freeStoppingPeriodMinutes: (
        <div title={haltingTimeGracePeriodTitle}>
          {haltingTimeGracePeriodLabel}
        </div>
      ),
      isActive: (
        <div>
          {listingItem.isActive ? (
            <FontAwesomeIcon icon={faCheck} size="sm" />
          ) : (
            <FontAwesomeIcon icon={faTimes} size="sm" />
          )}
        </div>
      ),
      actions: (
        <div className="d-flex">
          <TableLinkButtonComponent
            icon={faEdit}
            to={userRoutesHelper.getDriverContractEditRoute({
              driverUuid,
              contractUuid: listingItem.uuid,
            })}
            idForTesting={"taxi-driver-contract-edit-button"}
          />
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: DriverContractListingByRailyListingItem[],
  driverUuid: string
): DriverContractListingByRailyTableRow[] => {
  return listingItems.map((listingItem) => getRow(listingItem, driverUuid));
};

const driverContractListingByRailyTableHelper = {
  getColumns,
  getRows,
};

export default driverContractListingByRailyTableHelper;
