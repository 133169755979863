import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import taxiOrderSettlementListingUserPermissionsHelper from "./common/user-permissions/taxi-order-settlement-listing-user-permission.helper";
import settlementTranslationsHelper from "../../../../languages/settlement-translations.helper";
import settlementBreadcrumbsHelper from "../../common/breadcrumbs/settlement-breadcrumbs.helper";
import TaxiOrderSettlementListingViewMode from "./common/types/taxi-order-settlement-listing-view-mode";
import ButtonComponent from "../../../../common/components/button/button.component";
import TaxiOrderSettlementListingByRailyComponent from "./by-raily/taxi-order-settlement-listing-by-raily.component";
import TaxiOrderSettlementListingByTaxiComponent from "./by-taxi/taxi-order-settlement-listing-by-taxi.component";

type TaxiOrderSettlementListingProps = {};

const TaxiOrderSettlementListingComponent: FC<
  TaxiOrderSettlementListingProps
> = () => {
  const { user, selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.settlementsTaxiSettledOrderListing
  );

  const userPermissions = useMemo(
    () =>
      taxiOrderSettlementListingUserPermissionsHelper.getPermissions(
        user?.roles!
      ),
    []
  );

  const translations = useMemo(
    () =>
      settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations(),
    [selectedAppLanguage]
  );

  useEffect(() => {
    const breadcrumbs =
      settlementBreadcrumbsHelper.getTaxiOrderSettlementListingBreadcrumbs();

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<TaxiOrderSettlementListingViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() =>
          setSelectedViewMode(TaxiOrderSettlementListingViewMode.RAILY)
        }
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() =>
          setSelectedViewMode(TaxiOrderSettlementListingViewMode.TAXI)
        }
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: TaxiOrderSettlementListingViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: TaxiOrderSettlementListingViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToTaxiButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: TaxiOrderSettlementListingViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: TaxiOrderSettlementListingViewMode.RAILY,
        component: (
          <TaxiOrderSettlementListingByRailyComponent
            actionButtons={getViewChangeButtons(
              TaxiOrderSettlementListingViewMode.RAILY
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: TaxiOrderSettlementListingViewMode.TAXI,
        component: (
          <TaxiOrderSettlementListingByTaxiComponent
            actionButtons={getViewChangeButtons(
              TaxiOrderSettlementListingViewMode.TAXI
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default TaxiOrderSettlementListingComponent;
