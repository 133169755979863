import TaxiOfficerAddParams from "../../../../../../common/services/taxi-officer/add/taxi-officer-add-params";
import TaxiOfficerAddByRailyFormData from "./types/taxi-officer-add-by-raily-form-data";

const create = (
  formData: TaxiOfficerAddByRailyFormData
): TaxiOfficerAddParams => {
  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    username: formData.username,
    password: formData.password,
    phoneNumber: formData.phoneNumber,
    alternativePhoneNumber: formData.alternativePhoneNumber,
    taxiCorporationUuid: formData.taxiCorporationUuid,
  };
};

const taxiOfficerAddByRailyParamsFactory = {
  create,
};

export default taxiOfficerAddByRailyParamsFactory;
