import SelectOption from "../../../../common/components/form/select/common/option/select-option";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import TaxiContractMaintenancePolicy from "../../common/types/taxi-contract-maintenance-policy";

const createOptions = (): SelectOption<TaxiContractMaintenancePolicy>[] => {
  const translations = taxiTranslationsHelper.getAddTranslations();

  return [
    {
      label: translations.contractMaintenancePolicy.basic,
      value: TaxiContractMaintenancePolicy.BASIC,
    },
    {
      label: translations.contractMaintenancePolicy.auction,
      value: TaxiContractMaintenancePolicy.AUCTION,
    },
  ];
};

const taxiCompanyContractMaintenancePolicySelectOptionFactory = {
  createOptions,
};

export default taxiCompanyContractMaintenancePolicySelectOptionFactory;
