import CargoCompanyDispatchMultiListRequest, {
  CargoCompanyDispatchMultiListRequestQueryParams,
} from "./cargo-company-dispatch-multi-list.request";

const createQueryParams = (
  data: CargoCompanyDispatchMultiListRequest
): CargoCompanyDispatchMultiListRequestQueryParams => {
  return {
    companyIds: data.companyIds,
    offset: data.offset,
    limit: data.limit,
  };
};

const cargoCompanyDispatchMultiListRequestFactory = {
  createQueryParams,
};

export default cargoCompanyDispatchMultiListRequestFactory;
