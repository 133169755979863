import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderDetailsHelper from "../common/order-details.helper";
import OrderDetailsPassenger from "../common/types/order-details-passenger";
import OrderDetailsRouteWaypoint from "../common/types/order-details-route-waypoint";

type OrderDetailsPassengersProps = {
  passengers: OrderDetailsPassenger[];
  selectedPassenger: OrderDetailsPassenger | null;
  onPassengerRowClick: (selectedPassenger: OrderDetailsPassenger) => void;
  waypoints: OrderDetailsRouteWaypoint[];
};

const OrderDetailsPassengersComponent: FC<OrderDetailsPassengersProps> = (
  props
) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().passengers;

  return (
    <div className="order_details_passengers">
      <div>{translations.headingText}</div>
      <ul className="order_details_passengers_list">
        {props.passengers.map((passenger) => {
          const isSelected = props.selectedPassenger?.uuid === passenger.uuid;

          return (
            <li
              className={classNames(
                "order_details_passengers_item",
                isSelected && "selected"
              )}
              key={`passenger-${passenger.uuid}`}
              onClick={() => props.onPassengerRowClick(passenger)}
              title={orderDetailsHelper.getPassengerTitle(
                passenger,
                props.waypoints
              )}
            >
              <FontAwesomeIcon
                icon={faUser}
                className="order_details_passengers_item__icon"
              />
              <div className="order_details_passengers_item__name">
                {passenger.displayName}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OrderDetailsPassengersComponent;
