import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import AppAsideExpandedNavigationLink from "./common/types/app-aside-expanded-navigation-link";
import appAsideExpandedNavigationHelper from "./common/app-aside-expanded-navigation.helper";

type AppAsideExpandedNavigationLinkListItemProps = {
  link: AppAsideExpandedNavigationLink;
  depth?: number;
  idForTesting?: string;
};

const AppAsideExpandedNavigationLinkListItemComponent: FC<
  AppAsideExpandedNavigationLinkListItemProps
> = (props) => {
  const depth = props.depth ?? 0;

  return (
    <li className="app_aside_navigation_list_item">
      <LinkButtonComponent
        to={props.link.path}
        classNames={{
          root: "app_aside_navigation_list_item_link_button",
          content: "app_aside_navigation_list_item_link_button__content",
        }}
      >
        <div
          className={classNames(
            "app_aside_navigation_list_item__content",
            props.link.isActive && "active"
          )}
          data-test-id={props.idForTesting}
        >
          <div
            className="app_aside_navigation_list_item__content_data"
            style={{ paddingLeft: depth * 10 }}
          >
            <div className="app_aside_navigation_list_item__icon_wrapper">
              <FontAwesomeIcon
                icon={props.link.icon}
                className="app_aside_navigation_list_item__icon"
              />
            </div>
            <div className="app_aside_navigation_list_item__text">
              {props.link.text}
            </div>
            {!!props.link.attentionCounter && (
              <div className="app_aside_navigation_list_item__attention_counter">
                {appAsideExpandedNavigationHelper.getCounterValueLabel(
                  props.link.attentionCounter
                )}
              </div>
            )}
          </div>
        </div>
      </LinkButtonComponent>
    </li>
  );
};

export default AppAsideExpandedNavigationLinkListItemComponent;
