import driverDetailsPlanCheckoutFormValidationService from "../../common/driver-details-plan-checkout-form-validation.service";

const driverDetailsPlanEditCheckoutFormValidationService = {
  validateHaltingTime:
    driverDetailsPlanCheckoutFormValidationService.validateHaltingTime,
  validateHighwayCost:
    driverDetailsPlanCheckoutFormValidationService.validateHighwayCost,
};

export default driverDetailsPlanEditCheckoutFormValidationService;
