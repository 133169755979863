import { FC } from "react";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import ModalComponent from "../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import BillingFormData from "./types/billing-form.data";
import BillingDetailsComponent from "./billing-details/billing-details.component";

type BillingsSummaryConfirmationProps = {
  onClose: () => void;
  onSubmit: () => void;
  isVisible: boolean;
  billingData: BillingFormData;
  isLoading: boolean;
};

const BillingsSummaryConfirmationComponent: FC<
  BillingsSummaryConfirmationProps
> = (props) => {
  const translations =
    billingsTranslationsHelper.getRailyTaxiAddBillingsTranslations();

  return (
    <ModalComponent
      header={{ title: translations.billingSummary.summaryConfirmation }}
      isOpen={props.isVisible}
      classNames={{ root: "billing_summary_confirmation" }}
      onClose={() => props.onClose()}
      actions={[
        <ButtonComponent
          type="primary"
          onClick={props.onSubmit}
          isLoading={props.isLoading}
        >
          {translations.submitLabel}
        </ButtonComponent>,
      ]}
    >
      <BillingDetailsComponent billingData={props.billingData} />
    </ModalComponent>
  );
};
export default BillingsSummaryConfirmationComponent;
