import MileageListResponse, {
  MileageListResponseAddressSequence,
  MileageListResponseAddressSequenceNode,
  MileageListResponseItem,
  MileageListResponseState,
  MileageListResponseVehicleType,
} from "../../../api/raily/mileage/list/mileage-list.response";
import MileageList, {
  MileageListAddressSequence,
  MileageListAddressSequenceNode,
  MileageListItem,
  MileageListStatus,
  MileageListVehicleType,
} from "./mileage-list";

const createAddressSequenceNode = (
  node: MileageListResponseAddressSequenceNode
): MileageListAddressSequenceNode => {
  return {
    displayName: node.displayName,
    lat: node.lat,
    lon: node.lon,
    apartmentNumber: node.apartmentNumber,
    houseNumber: node.houseNumber,
    street: node.street,
    time: node.time,
    town: node.town,
    zipCode: node.zipCode,
  };
};

const createAddressSequence = (
  responseRequence: MileageListResponseAddressSequence
): MileageListAddressSequence => {
  const sequence: MileageListAddressSequenceNode[] = responseRequence.seq.map(
    createAddressSequenceNode
  );

  return {
    seq: sequence,
  };
};

const createStatus = (state: MileageListResponseState): MileageListStatus => {
  switch (state) {
    case MileageListResponseState.ACCEPTED:
      return MileageListStatus.ACCEPTED;
    case MileageListResponseState.DELEGATION_CREATED:
      return MileageListStatus.DELEGATION_CREATED;
    case MileageListResponseState.PENDING:
      return MileageListStatus.PENDING;
    case MileageListResponseState.REJECTED:
      return MileageListStatus.REJECTED;
  }
};

const createVehicleType = (
  type: MileageListResponseVehicleType
): MileageListVehicleType => {
  switch (type) {
    case MileageListResponseVehicleType.CAR_GT_900CM3:
      return MileageListVehicleType.CAR_GT_900CM3;
    case MileageListResponseVehicleType.CAR_LE_900CM3:
      return MileageListVehicleType.CAR_LE_900CM3;
    case MileageListResponseVehicleType.COMPANY_OWNED:
      return MileageListVehicleType.COMPANY_OWNED;
    case MileageListResponseVehicleType.MOPED:
      return MileageListVehicleType.MOPED;
    case MileageListResponseVehicleType.MOTORCYCLE:
      return MileageListVehicleType.MOTORCYCLE;
  }
};

const createDataItem = (item: MileageListResponseItem): MileageListItem => {
  return {
    addressSeq: createAddressSequence(item.addressSeq),
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    distance: item.distance,
    endAddressLabel: item.endAddressLabel,
    hasDelegation: item.hasDelegation,
    humanId: item.humanId,
    uuid: item.id,
    midAddressesLabels: item.midAddressesLabels,
    mileageCost: item.mileageCost,
    mileageDate: item.mileageDate,
    startAddressLabel: item.startAddressLabel,
    status: createStatus(item.state),
    vehicleType: createVehicleType(item.vehicleType),
    workerCompanyUuid: item.workerCompanyId,
    workerUuid: item.workerId,
    workerName: item.workerName,
    workerPhone: item.workerPhone,
    cardNumber: item.cardNumber,
    commissionNumber: item.commissionNumber,
    delegationUuid: item.delegationId,
    cargoCompanyName: item.cargoCompanyName,
    mileageNumber: item.mileageNumber,
    modificationDate: item.modifiedAt,
    modifiedBy: item.modifiedBy,
    notes: item.notes,
    osrmDistance: item.osrmDistance,
    acceptedDistance: item.acceptedDistance,
  };
};

const createData = (
  responseData: MileageListResponseItem[]
): MileageListItem[] => {
  const items: MileageListItem[] = responseData.map(createDataItem);

  return items;
};

const create = (response: MileageListResponse): MileageList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const mileageListFactory = {
  create,
};

export default mileageListFactory;
