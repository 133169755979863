import PhoneNumber from "../../../types/phone-number";

export enum TaxiContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type TaxiOfficerListItemTaxiCorporation = {
  uuid: string;
  taxId: string;
  companyId: string | null;
  nationalCourtRegister: string | null;
  name: string;
  displayName: string;
  address: string;
  contractMaintenancePolicy: TaxiContractMaintenancePolicy;
  notes: string | null;
};

export type TaxiOfficerListItem = {
  uuid: string;
  firstName: string;
  lastName: string;
  username: string;
  removalDate: Date | null;
  email: string;
  phoneNumber: PhoneNumber;
  alternativePhoneNumber: PhoneNumber | null;
  taxiCorporation: TaxiOfficerListItemTaxiCorporation;
};

type TaxiOfficerList = {
  data: TaxiOfficerListItem[];
  totalCount: number;
};

export default TaxiOfficerList;
