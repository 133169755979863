export enum MileageSettingsUpdateRequestMileageNumberCreationModel {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
  DICT = "DICT",
}

export type MileageSettingsUpdateRequestBody = {
  isCardNoRequired: boolean | undefined;
  isMileageNoRequired: boolean | undefined;
  mileageNoCreationType:
    | MileageSettingsUpdateRequestMileageNumberCreationModel
    | undefined;
};

type MileageSettingsUpdateRequest = {
  cargoCompanyUuid: string;
  isCardNumberRequired?: boolean;
  isMileageNumberRequired?: boolean;
  mileageNumberCreationType?: MileageSettingsUpdateRequestMileageNumberCreationModel;
};

export default MileageSettingsUpdateRequest;
