import MileageContractUpdateParams from "../../../../../common/services/mileage-contract/update/mileage-contract-update-params";
import MileageContractUpdateFormData from "../form/types/mileage-contract-update-form-data";

const create = (
  formData: MileageContractUpdateFormData,
  contractUuid: string
): MileageContractUpdateParams => {
  return {
    contractUuid: contractUuid,
    isActive: formData.isActive ?? undefined,
  };
};

const mileageContractUpdateParamsFactory = {
  create,
};

export default mileageContractUpdateParamsFactory;
