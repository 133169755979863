import { faCalendarAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FC, useRef, useEffect, RefObject, useState } from "react";
import { createPortal } from "react-dom";
import InputComponent, { InputProps } from "../../input/input.component";
import OutsideClickHandler from "react-outside-click-handler";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import useKeyboardPress from "../../../../hooks/use-keyboard-press";
import ComponentClassnames from "../../../../types/component-classnames";
import FormIcon from "../../icon/form-icon";
import classNames from "classnames";
import useOpen from "../../../../hooks/use-open";
import DateTimePickerComponent from "../../date-picker/date-time-picker/date-time-picker.component";
import dateTimeInputHelper from "./data-time-input.helper";

type DateTimeInputProps = {
  classNames?: ComponentClassnames & {
    input?: InputProps["classNames"];
  };
  date: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  onBlur?: () => void;
  hasError?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  idForTesting?: string;
  parentRef?: RefObject<HTMLElement>;
  parentContainerRef?: RefObject<HTMLElement>;
};

const DateTimeInputComponent: FC<DateTimeInputProps> = (props) => {
  const [calendarHeight, setCalendarHeight] = useState(0);
  const { isOpen, open, close } = useOpen();
  const datePickerRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calendarHeight =
      calendarRef?.current?.getBoundingClientRect().top ?? 0;
    setCalendarHeight(calendarHeight);
  }, [isOpen]);

  const createCalendarClasses = () => {
    if (!datePickerRef) return "";
    const datePickerTop =
      datePickerRef.current?.getBoundingClientRect().top ?? 0;
    const windowHeight = window.innerHeight;

    return calendarHeight
      ? datePickerTop + calendarHeight >= windowHeight
        ? "date_time_input_selector_above"
        : "date_time_input_selector_below"
      : "";
  };

  useKeyboardPress({
    Escape: () => close(),
    Tab: () => close(),
    ref: datePickerRef,
  });

  useEffect(() => {
    if (!props.parentContainerRef) return;
    props.parentContainerRef.current?.addEventListener("scroll", onScroll);

    return () => {
      props.parentContainerRef?.current?.removeEventListener(
        "scroll",
        onScroll
      );
    };
  }, []);

  const calendarPositionClass = createCalendarClasses();

  const translations =
    appTranslationsHelper.getComponentTranslations().dateTimePicker;

  const dataPickerRect = datePickerRef.current?.getBoundingClientRect();
  const renderRef = props.parentRef?.current;

  const onScroll = () => close();

  const onOutsideClick = (event?: any) => {
    if (
      !isOpen ||
      (calendarRef.current && calendarRef.current.contains(event?.target))
    )
      return;

    close();

    if (!props.date) props.onChange(null);
  };

  const dateTimePicker = createPortal(
    <div
      className={
        props.parentRef ? `date_time_input_wrapper date_time_input` : ""
      }
      style={{
        position: renderRef ? "fixed" : "absolute",
        top: dataPickerRect
          ? renderRef
            ? dataPickerRect.top
            : dataPickerRect.top + window.scrollY
          : "",
        left: dataPickerRect ? (renderRef ? "" : dataPickerRect.left) : "",
      }}
    >
      <div
        className={classNames(
          "date_time_input_selector",
          calendarPositionClass
        )}
        ref={calendarRef}
      >
        <DateTimePickerComponent
          value={props.date}
          onChange={props.onChange}
          minDate={props.minDate}
          maxDate={props.maxDate}
        />
      </div>
    </div>,
    renderRef ?? document.body
  );

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div
        ref={datePickerRef}
        className={props.classNames?.root}
        data-test-id={props.idForTesting}
      >
        {isOpen && dateTimePicker}
        <InputComponent
          onFocus={() => open()}
          onBlur={props.onBlur}
          value={dateTimeInputHelper.getDateTimeInputValue(props.date)}
          placeholder={props.placeholder ?? translations.inputPlaceholder}
          isReadOnly={true}
          iconLeft={<FormIcon icon={faCalendarAlt} />}
          iconRight={
            props.date && (
              <FormIcon
                icon={faTimes}
                title={translations.clearDateButtonTitle}
                onClick={(e) => {
                  e.preventDefault();
                  props.onChange(null);
                }}
              />
            )
          }
          classNames={props.classNames?.input}
          idForTesting={
            props.idForTesting ? `${props.idForTesting}-input` : undefined
          }
          hasError={props.hasError}
          isDisabled={props.isDisabled}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default DateTimeInputComponent;
