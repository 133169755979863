import { CargoCompanyDispatcherListItem } from "../../../../../common/services/cargo-company/dispatcher/list/cargo-company-dispatcher-list";
import CargoCompanyPassengerList, {
  CargoCompanyPassengerListItem,
  CargoCompanyPassengerListItemAddress,
} from "../../../../../common/services/cargo-company/passenger/list/cargo-company-passenger-list";
import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import {
  MileageAddWorkerListItemAddress,
  MileageAddWorkerListItem,
} from "../types/mileage-add-worker-list";

const createPassengerAddress = (
  address: CargoCompanyPassengerListItemAddress
): MileageAddWorkerListItemAddress => {
  return {
    displayName: address.displayName,
    lat: address.lat,
    lon: address.lon,
    apartmentNumber: address.apartmentNumber,
    houseNumber: address.houseNumber,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createPassengerListItem = (
  item: CargoCompanyPassengerListItem
): MileageAddWorkerListItem => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().workers;

  return {
    addresses: item.addresses.map(createPassengerAddress),
    displayName: item.displayName,
    subText: translations.passengerWorkerLabel,
    uuid: item.uuid,
  };
};

const createPassengerList = (
  response: CargoCompanyPassengerList
): MileageAddWorkerListItem[] => {
  return response.map(createPassengerListItem);
};

const createDispatcherListItem = (
  item: CargoCompanyDispatcherListItem
): MileageAddWorkerListItem => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().workers;

  return {
    addresses: [],
    displayName: item.displayName,
    subText: translations.dispatcherWorkerLabel,
    uuid: item.uuid,
  };
};

const createDispatcherList = (
  response: CargoCompanyDispatcherListItem[]
): MileageAddWorkerListItem[] => {
  return response.map(createDispatcherListItem);
};

const combineWorkerLists = (
  list1: MileageAddWorkerListItem[],
  list2: MileageAddWorkerListItem[]
): MileageAddWorkerListItem[] => {
  return [...list1, ...list2].sort((item1, item2) => {
    if (item1.displayName < item2.displayName) return -1;
    if (item1.displayName > item2.displayName) return 1;
    return 0;
  });
};

const mileageAddWorkerListFactory = {
  createPassengerList,
  createDispatcherList,
  combineWorkerLists,
};

export default mileageAddWorkerListFactory;
