import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import orderRouteEditHelper from "../../helper/order-route-edit.helper";
import { OrderRouteEditCargoAddressType } from "../../types/order-route-edit-cargo-address";
import OrderRouteEditPassengerListItem from "../../types/order-route-edit-passenger-list-item";
import OrderRouteEditRouteItem, {
  OrderRouteEditRouteWaypoint,
  OrderRouteEditRouteWaypointGroup,
  OrderRouteEditRouteWaypointGroupWaypoint,
} from "../../types/order-route-edit-route-waypoint";

const getNewEmptyRoute = (): OrderRouteEditRouteItem => {
  const newRoute: OrderRouteEditRouteItem = {
    uuid: uuidService.generate(),
    address: null,
    date: null,
    haltingTimeMinutes: null,
    onboardingPassengerListItems: [],
    outboardingPassengerListItems: [],
  };

  return newRoute;
};

const getListOfPassengersInCar = (
  passengerList: OrderRouteEditPassengerListItem[],
  routeItem: OrderRouteEditRouteItem,
  allRoutes: OrderRouteEditRouteItem[]
): OrderRouteEditPassengerListItem[] => {
  const indexOfCurrentRoute = allRoutes.indexOf(routeItem);

  let passengersInCar: OrderRouteEditPassengerListItem[] = [];

  const routeItemsToCheck = allRoutes.slice(0, indexOfCurrentRoute);

  routeItemsToCheck.forEach((routeItem) => {
    const isWaypoint =
      orderRouteEditHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      passengersInCar.push(...routeItem.onboardingPassengerListItems);

      passengersInCar = passengersInCar.filter(
        (passengerInCar) =>
          !routeItem.outboardingPassengerListItems.find(
            (outboardingPassenger) =>
              outboardingPassenger.uuid === passengerInCar.uuid
          )
      );

      return;
    }

    const waypointGroupOnboardingPassengerUuidList = routeItem.waypoints
      .map((waypoint) => waypoint.onboardingPassengerListItems)
      .flat();

    passengersInCar.push(...waypointGroupOnboardingPassengerUuidList);

    passengersInCar = passengersInCar.filter(
      (uuidOfPassengersInCar) =>
        !routeItem.waypoints
          .map((waypoint) => waypoint.outboardingPassengerListItems)
          .flat()
          .find(
            (outboardingPassengerUuid) =>
              outboardingPassengerUuid === uuidOfPassengersInCar
          )
    );
  });

  return passengerList.filter((passengerListItem) =>
    passengersInCar.find(
      (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
    )
  );
};

const getAmountOfPassengersInCar = (
  passengerList: OrderRouteEditPassengerListItem[],
  route: OrderRouteEditRouteItem,
  allRoutes: OrderRouteEditRouteItem[]
): number => {
  const passengersInCar = getListOfPassengersInCar(
    passengerList,
    route,
    allRoutes
  );

  return passengersInCar.length;
};

const getOnboardingPassengerCandidateListForWaypoint = (
  passengerList: OrderRouteEditPassengerListItem[],
  passengersInCarBeforeCurrentWaypoint: OrderRouteEditPassengerListItem[],
  waypoint: OrderRouteEditRouteWaypoint
): OrderRouteEditPassengerListItem[] => {
  return passengerList.filter(
    (passengerListItem) =>
      !passengersInCarBeforeCurrentWaypoint.find(
        (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
      ) &&
      !waypoint.onboardingPassengerListItems.find(
        (onboardingPassengerListItem) =>
          onboardingPassengerListItem.uuid === passengerListItem.uuid
      )
  );
};

const getOnboardingPassengerCandidateListForWaypointGroup = (
  passengerList: OrderRouteEditPassengerListItem[],
  passengersInCarBeforeCurrentWaypoint: OrderRouteEditPassengerListItem[],
  waypoint: OrderRouteEditRouteWaypointGroup
): OrderRouteEditPassengerListItem[] => {
  return passengerList.filter((passengerListItem) => {
    return (
      !passengersInCarBeforeCurrentWaypoint.find(
        (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
      ) &&
      !waypoint.waypoints.some((waypoint) => {
        return waypoint.onboardingPassengerListItems.find(
          (onboardingPassengerListItem) =>
            onboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      })
    );
  });
};

const getOnboardingPassengerCandidateList = (
  passengerList: OrderRouteEditPassengerListItem[],
  currentRouteItem: OrderRouteEditRouteItem,
  allRoutes: OrderRouteEditRouteItem[]
): OrderRouteEditPassengerListItem[] => {
  const passengersInCarBeforeCurrentRoute = getListOfPassengersInCar(
    passengerList,
    currentRouteItem,
    allRoutes
  );

  const isWaypoint =
    orderRouteEditHelper.checkIsRouteItemAWaypoint(currentRouteItem);

  if (isWaypoint) {
    return getOnboardingPassengerCandidateListForWaypoint(
      passengerList,
      passengersInCarBeforeCurrentRoute,
      currentRouteItem
    );
  }

  return getOnboardingPassengerCandidateListForWaypointGroup(
    passengerList,
    passengersInCarBeforeCurrentRoute,
    currentRouteItem
  );
};

const getOutboardingPassengerCandidateListForWaypoint = (
  passengerList: OrderRouteEditPassengerListItem[],
  passengersInCarBeforeCurrentWaypoint: OrderRouteEditPassengerListItem[],
  waypoint: OrderRouteEditRouteWaypoint
) => {
  return passengerList.filter(
    (passengerListItem) =>
      passengersInCarBeforeCurrentWaypoint.find(
        (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
      ) &&
      !waypoint.outboardingPassengerListItems.find(
        (outboardingPassengerListItem) =>
          outboardingPassengerListItem.uuid === passengerListItem.uuid
      )
  );
};

const getOutboardingPassengerCandidateListForWaypointGroup = (
  passengerList: OrderRouteEditPassengerListItem[],
  passengersInCarBeforeCurrentWaypoint: OrderRouteEditPassengerListItem[],
  waypointGroup: OrderRouteEditRouteWaypointGroup
) => {
  return passengerList.filter(
    (passengerListItem) =>
      passengersInCarBeforeCurrentWaypoint.find(
        (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
      ) &&
      !waypointGroup.waypoints.some(
        (waypoint) =>
          !!waypoint.outboardingPassengerListItems.find(
            (outboardingPassengerListItem) =>
              outboardingPassengerListItem.uuid === passengerListItem.uuid
          )
      )
  );
};

const getOutboardingPassengerCandidateList = (
  passengerList: OrderRouteEditPassengerListItem[],
  currentRouteItem: OrderRouteEditRouteItem,
  allRoutes: OrderRouteEditRouteItem[]
): OrderRouteEditPassengerListItem[] => {
  const passengersInCarBeforeCurrentRoute = getListOfPassengersInCar(
    passengerList,
    currentRouteItem,
    allRoutes
  );

  const isWaypoint =
    orderRouteEditHelper.checkIsRouteItemAWaypoint(currentRouteItem);

  if (isWaypoint) {
    return getOutboardingPassengerCandidateListForWaypoint(
      passengerList,
      passengersInCarBeforeCurrentRoute,
      currentRouteItem
    );
  }

  return getOutboardingPassengerCandidateListForWaypointGroup(
    passengerList,
    passengersInCarBeforeCurrentRoute,
    currentRouteItem
  );
};

const getIndexOfPassenger = (
  passengerList: OrderRouteEditPassengerListItem[],
  passengerListItemUuid: OrderRouteEditPassengerListItem["uuid"]
): number | undefined => {
  const passengerListItem = passengerList.find(
    (listItem) => listItem.uuid === passengerListItemUuid
  );

  if (!passengerListItem) {
    return undefined;
  }

  const indexOfPassengerListItem = passengerList.indexOf(passengerListItem);

  return indexOfPassengerListItem;
};

const getUpdatedRouteItemsOnPassengerListChange = (
  routes: OrderRouteEditRouteItem[],
  passengerList: OrderRouteEditPassengerListItem[]
) => {
  const newRoutes: OrderRouteEditRouteItem[] = [];

  routes.forEach((route) => {
    const isWaypoint = orderRouteEditHelper.checkIsRouteItemAWaypoint(route);

    if (isWaypoint) {
      const newOnboardingPassengerListItems: OrderRouteEditRouteWaypoint["onboardingPassengerListItems"] =
        [];
      const newOutboardingPassengerListItems: OrderRouteEditRouteWaypoint["outboardingPassengerListItems"] =
        [];

      route.onboardingPassengerListItems.forEach(
        (onboardingPassengerListItem) => {
          const passengerOnList = passengerList.find(
            (passengerListItem) =>
              passengerListItem.uuid === onboardingPassengerListItem.uuid
          );

          if (!passengerOnList) {
            return;
          }

          newOnboardingPassengerListItems.push(passengerOnList);
        }
      );

      route.outboardingPassengerListItems.forEach(
        (outboardingPassengerListItem) => {
          const passengerOnList = passengerList.find(
            (passengerListItem) =>
              passengerListItem.uuid === outboardingPassengerListItem.uuid
          );

          if (!passengerOnList) {
            return;
          }

          newOutboardingPassengerListItems.push(passengerOnList);
        }
      );

      const newRoute: OrderRouteEditRouteWaypoint = {
        ...route,
        onboardingPassengerListItems: newOnboardingPassengerListItems,
        outboardingPassengerListItems: newOutboardingPassengerListItems,
      };

      newRoutes.push(newRoute);

      return;
    }

    const newRoute: OrderRouteEditRouteWaypointGroup = {
      ...route,
      waypoints: [],
    };

    route.waypoints.forEach((waypoint) => {
      const newOnboardingPassengerListItems: OrderRouteEditRouteWaypoint["onboardingPassengerListItems"] =
        [];
      const newOutboardingPassengerListItems: OrderRouteEditRouteWaypoint["outboardingPassengerListItems"] =
        [];

      waypoint.onboardingPassengerListItems.forEach(
        (onboardingPassengerListItem) => {
          const passengerOnList = passengerList.find(
            (passengerListItem) =>
              passengerListItem.uuid === onboardingPassengerListItem.uuid
          );

          if (!passengerOnList) {
            return;
          }
          newOnboardingPassengerListItems.push(passengerOnList);
        }
      );

      waypoint.outboardingPassengerListItems.forEach(
        (outboardingPassengerListItem) => {
          const passengerOnList = passengerList.find(
            (passengerListItem) =>
              passengerListItem.uuid === outboardingPassengerListItem.uuid
          );

          if (!passengerOnList) {
            return;
          }
          newOutboardingPassengerListItems.push(passengerOnList);
        }
      );

      const newWaypoint: OrderRouteEditRouteWaypointGroupWaypoint = {
        ...waypoint,
        onboardingPassengerListItems: newOnboardingPassengerListItems,
        outboardingPassengerListItems: newOutboardingPassengerListItems,
      };

      newRoute.waypoints.push(newWaypoint);
    });
    newRoutes.push(newRoute);
  });

  return newRoutes;
};

const getRouteAddressSelectOptionSubTextForCargoAddress = (
  cargoAddressType: OrderRouteEditCargoAddressType
): string => {
  const translations =
    orderTranslationsHelper.getEditTranslations().routes.waypoint
      .cargoAddressType;

  switch (cargoAddressType) {
    case OrderRouteEditCargoAddressType.HOTEL:
      return translations.HOTEL;
    case OrderRouteEditCargoAddressType.MEETING_POINT:
      return translations.MEETING_POINT;
    case OrderRouteEditCargoAddressType.STATION:
      return translations.STATION;
    default:
      return ``;
  }
};

const orderRouteEditRoutesHelper = {
  getNewEmptyRoute,
  getOnboardingPassengerCandidateList,
  getOutboardingPassengerCandidateList,
  getIndexOfPassenger,
  getListOfPassengersInCar,
  getAmountOfPassengersInCar,
  getUpdatedRouteItemsOnPassengerListChange,
  getRouteAddressSelectOptionSubTextForCargoAddress,
};

export default orderRouteEditRoutesHelper;
