import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";

const getContentMessageText = (humanId: string) => {
  const translations =
    mileageTranslationsHelper.getMileageDetailsTranslations().acceptMileage
      .confirmationModal;

  return translations.contentMessageTemplateText.replace("#{humanId}", humanId);
};

const mileageDetailsMileageAcceptHelper = {
  getContentMessageText,
};

export default mileageDetailsMileageAcceptHelper;
