import BillingRouteRequest from "./billing-route.request";
import BillingRouteResponse from "./billing-route.response";
import BillingDataResponse from "./billing-data.response";
import BillingRecalculateRequest from "./billing-recalculate.request";
import BillingSaveRequest from "./billing-save.request";
import BillingSaveResponse from "./billing-save.response";
import appConfig from "../../../../../app.config";
import searchRoadRoutingService from "../../../../../common/utils/search-road-route/search-road-routing.service";
import authService from "../../../../auth/common/auth.service";

const fetchBillingData = async (
  billingUuid: string
): Promise<BillingDataResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/${billingUuid}/taxi-taxi`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const recalculateBilling = async (
  forwardingId: string,
  requestBody: BillingRecalculateRequest
): Promise<BillingDataResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/orders-forwarding/${forwardingId}/taxi-taxi/recalculate`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const saveBillingData = async (
  billingUuid: string,
  requestBody: BillingSaveRequest
): Promise<BillingSaveResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/${billingUuid}/taxi-taxi`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "PUT",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchRoute = (
  request: BillingRouteRequest
): Promise<BillingRouteResponse> => {
  return searchRoadRoutingService.search(request);
};

const billingTaxiTaxiApiService = {
  fetchBillingData,
  fetchRoute,
  recalculateBilling,
  saveBillingData,
};

export default billingTaxiTaxiApiService;
