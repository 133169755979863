import appConfig from "../../../../app.config";
import authService from "../../../../modules/auth/common/auth.service";
import urlService from "../../../utils/url/url.service";
import SearchRoadRoutingRequest from "../../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../../utils/search-road-route/search-road-routing.response";
import searchRoadRoutingService from "../../../utils/search-road-route/search-road-routing.service";
import railyApiService from "../raily-api.service";
import delegationAddFromMileagesRequestFactory from "./add-from-mileages/delegation-add-from-mileages-request.factory";
import DelegationAddFromMileagesRequest from "./add-from-mileages/delegation-add-from-mileages.request";
import DelegationAddFromMileagesResponse from "./add-from-mileages/delegation-add-from-mileages.response";
import delegationAddRequestFactory from "./add/delegation-add-request.factory";
import DelegationAddRequest from "./add/delegation-add.request";
import DelegationAddResponse from "./add/delegation-add.response";
import DelegationDeleteRequest from "./delete/delegation-delete.request";
import DelegationDeleteResponse from "./delete/delegation-delete.response";
import delegationDownloadRequestFactory from "./download/delegation-download-request.factory";
import DelegationDownloadRequest, {
  DelegationDownloadRequestQueryParams,
} from "./download/delegation-download.request";
import DelegationDownloadResponse from "./download/delegation-download.response";
import delegationListRequstFactory from "./list/delegation-list-request.factory";
import DelegationListRequest from "./list/delegation-list.request";
import DelegationListResponse from "./list/delegation-list.response";
import DelegationDetailsResponse from "./details/delegation-details.response";
import DelegationDetailsRequest from "./details/delegation-details.request";

const delegationApiService = () => {
  const list = async (
    request: DelegationListRequest,
    abortSignal: AbortSignal
  ): Promise<DelegationListResponse> => {
    const url = "/delegation";

    const queryParams = delegationListRequstFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      queryParams,
      abortSignal,
    });
  };

  const add = async (
    request: DelegationAddRequest,
    abortSignal: AbortSignal
  ): Promise<DelegationAddResponse> => {
    const url = "/delegation";

    const body = delegationAddRequestFactory.createRequestBody(request);
    const queryParams = delegationAddRequestFactory.createQueryParams(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
      queryParams,
    });
  };

  const addFromMileages = async (
    request: DelegationAddFromMileagesRequest,
    abortSignal: AbortSignal
  ): Promise<DelegationAddFromMileagesResponse> => {
    const url = `/delegation/from-mileages`;

    const body = delegationAddFromMileagesRequestFactory.createBody(request);
    const queryParams =
      delegationAddFromMileagesRequestFactory.createQueryParams(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
      queryParams,
    });
  };

  const getDetails = async (
    request: DelegationDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<DelegationDetailsResponse> => {
    const url = `delegation/${request.delegationUuid}`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  const deleteDelegation = async (
    request: DelegationDeleteRequest,
    abortSignal: AbortSignal
  ): Promise<DelegationDeleteResponse> => {
    const url = `/delegation/${request.delegationUuid}`;

    return railyApiService().delete({
      url,
      abortSignal,
    });
  };

  const download = async (
    request: DelegationDownloadRequest
  ): Promise<DelegationDownloadResponse> => {
    const path = `${
      appConfig.baseApiUrl
    }/delegation/download/${request.fileFormat.toLowerCase()}`;

    const queryParams: DelegationDownloadRequestQueryParams =
      delegationDownloadRequestFactory.createQueryParams(request);

    const url = urlService.buildWithoutEmptyParams(path, queryParams);

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "attachment",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
      mode: "cors",
      keepalive: true,
    };

    const response = await fetch(url, init);

    const filename = response.headers
      ?.get("content-disposition")
      ?.split(";")
      .find((n) => n.includes("filename="))
      ?.replace("filename=", "")
      .replace(/"/g, "")
      .trim()!;

    const responseData = await response.blob();

    return {
      status: response.status,
      data: {
        data: responseData,
        filename,
      },
    };
  };

  const getRoute = (
    request: SearchRoadRoutingRequest
  ): Promise<SearchRoadRoutingResponse> => {
    return searchRoadRoutingService.search(request);
  };

  return {
    list,
    add,
    addFromMileages,
    getDetails,
    deleteDelegation,
    download,
    getRoute,
  };
};

export default delegationApiService;
