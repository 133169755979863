import { FC } from "react";
import { ReactComponent as PassengerMarkIcon } from "../../../../../../assets/icons/passenger-mark-icon.svg";

type OrderRouteEditRoutesPassengerIconProps = {
  number: number;
};

const OrderRouteEditRoutesPassengerIconComponent: FC<
  OrderRouteEditRoutesPassengerIconProps
> = (props) => {
  return (
    <div className="order_edit_passenger_icon">
      <PassengerMarkIcon />
      <span className="order_edit_passenger_icon__content">{props.number}</span>
    </div>
  );
};

export default OrderRouteEditRoutesPassengerIconComponent;
