import UserRole from "../../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DriverDetailsPlanEntryRoutePlansUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type DriverDetailsPlanEntryRoutePlansUserPermissionDefinition =
  UserPermissionsDefinition<DriverDetailsPlanEntryRoutePlansUserPermissionOption>;

export type DriverDetailsPlanEntryRoutePlansUserPermissions =
  UserPermissions<DriverDetailsPlanEntryRoutePlansUserPermissionOption>;

const driverDetailsPlanEntryRoutePlansUserPermissionDefinition: DriverDetailsPlanEntryRoutePlansUserPermissionDefinition =
  {
    hasAccessToRailyView: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
    ],
    hasAccessToTaxiView: [UserRole.DEALER],
  };

export default driverDetailsPlanEntryRoutePlansUserPermissionDefinition;
