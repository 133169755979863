import BillingRecalculateRequest from "../api/billing-recalculate.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData from "../types/billing-form.data";

const createRecalculateRequest = (
  data: BillingFormData
): BillingRecalculateRequest => {
  const result: BillingRecalculateRequest = {
    billing_nodes: createBilligNodes(data),
    discount: data.discount,
  };
  return result;
};

const createBilligNodes = (data: BillingFormData): BillingNodeApi[] => {
  return data.billingNodes.map((specificNode) => {
    const node: BillingNodeApi = {
      id: specificNode.id,
      node_id: specificNode.nodeId,
      order_id: specificNode.orderId,
      lat: specificNode.lat,
      lon: specificNode.lon,
      distance: specificNode.distance,
      description: specificNode.description,
      checkout_date: specificNode.checkoutDate?.toJSON() ?? null,
      checkout_event_id: specificNode.checkoutEventUuid,
      planned_date: specificNode.plannedDate?.toJSON() ?? null,
      halting_amount: specificNode.haltingAmount,
      halting_time: specificNode.haltingTime,
      allow_charge: specificNode.isHighwayAllowed,
      highway_charge: specificNode.highwayCharge,
      planned_distance: specificNode.plannedDistance,
      editable_coordinates: specificNode.isEditable,
    };

    return node;
  });
};

const billingRecalculateRequestFactory = {
  createRecalculateRequest,
};

export default billingRecalculateRequestFactory;
