import { FC } from "react";
import BillingsTaxiDriverContributionsPenaltyTableRow from "../types/billings-taxi-driver-contributions-penalty-table-row";
import BillingsTaxiDriverContributionsPenaltyTableColumn from "../types/billings-taxi-driver-contributions-penalty-table-column";
import BillingsTaxiDriverContributionsPenaltyTableHelper from "./billings-taxi-driver-contributions-penalty-table.helper";
import TableComponent from "../../../../../../../common/components/table/table.component";

type BillingsTaxiDriverContributionsPenaltyTableProps = {
  rows: BillingsTaxiDriverContributionsPenaltyTableRow[];
  isLoading: boolean;
};

const BillingsTaxiDriverContributionsPenaltyTableComponent: FC<
  BillingsTaxiDriverContributionsPenaltyTableProps
> = (props) => {
  const columns: BillingsTaxiDriverContributionsPenaltyTableColumn[] =
    BillingsTaxiDriverContributionsPenaltyTableHelper.getColumns();

  return (
    <TableComponent
      columns={columns}
      rows={props.rows}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiDriverContributionsPenaltyTableComponent;
