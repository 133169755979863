import { FC, useEffect, useState } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../../../common/components/modal/modal.component";
import { DriverDetailsDriverPlanEntryWaypoint } from "../common/types/driver-details-plan-entry";
import DriverDetailsActiveRoutePlanOrderDeleteListingComponent from "./driver-details-active-route-plans-order-delete-listing.component";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import notificationService from "../../../../../../common/utils/notification/notification.service";
import DriverDetailsActiveRoutePlanDeleteOrderDictionary, {
  DriverDetailsActiveRoutePlanDeleteOrderData,
} from "./types/driver-details-active-route-plans-delete-order-data";
import driverPlanService from "../../../../../../common/services/driver-plan/driver-plan.service";
import useAbort from "../../../../../../common/hooks/use-abort";
import DriverPlanDeleteOrderError from "../../../../../../common/services/driver-plan/delete-order/driver-plan-delete-order-error";

type DriverDetailsPlanDeleteOrderProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  onDeleteOrderSuccess: () => void;
  planEntryWaypoints: DriverDetailsDriverPlanEntryWaypoint[];
  driverUuid: string;
};

const DriverDetailsPlanDeleteOrderComponent: FC<
  DriverDetailsPlanDeleteOrderProps
> = (props) => {
  const [ordersDataDict, setOrdersDataDict] =
    useState<DriverDetailsActiveRoutePlanDeleteOrderDictionary>({});

  useEffect(() => {
    const ordersData: DriverDetailsActiveRoutePlanDeleteOrderDictionary = {};

    const ordersWaypoints = props.planEntryWaypoints.filter(
      (waypoint) => waypoint.order !== null && waypoint.solvedOrderUuid !== null
    );

    ordersWaypoints.forEach((waypoint) => {
      const isOrderExists = !!ordersData[waypoint.order!.uuid];

      if (isOrderExists) {
        return;
      }

      const newOrderData: DriverDetailsActiveRoutePlanDeleteOrderData = {
        uuid: waypoint.order!.uuid,
        cargoInternalOrderId: waypoint.order!.cargoInternalOrderId,
        solvedOrderUuid: waypoint.solvedOrderUuid!,
        isPossibleToDelete: !ordersWaypoints.some(
          (_waypoint) =>
            _waypoint.order!.uuid === waypoint.order?.uuid &&
            _waypoint.checkoutDate
        ),
        isDeletePending: false,
      };

      ordersData[waypoint.order!.uuid] = newOrderData;
    });

    setOrdersDataDict(ordersData);
  }, [props.planEntryWaypoints]);

  const translations =
    userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations();

  const setDeletePending = (orderUuid: string, isPending: boolean) => {
    const newOrdersData = { ...ordersDataDict };

    newOrdersData[orderUuid] = {
      ...newOrdersData[orderUuid],
      isDeletePending: isPending,
    };

    setOrdersDataDict(newOrdersData);
  };

  const deleteOrderAbort = useAbort();

  const onDeleteOrderSuccess = () => {
    notificationService.success(
      translations.deleteOrder.successNotificationText
    );
    props.onDeleteOrderSuccess();
  };

  const onDeleteOrderFailure = (error: DriverPlanDeleteOrderError) => {
    notificationService.error(
      `${translations.deleteOrder.failureNotificationText}. ${error.message}`
    );
  };

  const deleteOrder = async (orderUuid: string, solvedOrderUuid: string) => {
    setDeletePending(orderUuid, true);
    try {
      await driverPlanService.deleteOrder(
        {
          driverUuid: props.driverUuid,
          solvedOrderUuid,
        },
        deleteOrderAbort.signal
      );

      onDeleteOrderSuccess();
    } catch (error) {
      onDeleteOrderFailure(error as DriverPlanDeleteOrderError);
    } finally {
      setDeletePending(orderUuid, false);
    }
  };

  const onDeleteOrderClick = (orderUuid: string, solvedOrderUuid: string) => {
    deleteOrder(orderUuid, solvedOrderUuid);
  };

  const ordersDataSet = Object.keys(ordersDataDict).map(
    (key) => ordersDataDict[key]
  );

  return (
    <ModalComponent
      header={{ title: translations.deleteOrder.headingText }}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <DriverDetailsActiveRoutePlanOrderDeleteListingComponent
        orders={ordersDataSet}
        onDeleteOrderClick={onDeleteOrderClick}
      />
    </ModalComponent>
  );
};

export default DriverDetailsPlanDeleteOrderComponent;
