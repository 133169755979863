import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import MessengerPostsMap, {
  MessengerPostChannel,
  MessengerPostChannelData,
} from "./post/messenger-post";
import MessengerPostError from "./post/messenger-post-error";
import MessengerPostLoadParams from "./post/messenger-post-load-params";
import messengerPostRequestFactory from "./post/messenger-post-request.factory";
import messengerPostFactory from "./post/messenger-post.factory";
import MessengerSendPostError from "./send-post/messenger-send-post-error";
import MessengerSendPostLoadParams from "./send-post/messenger-send-post-load-params";
import messengerSendPostRequestFactory from "./send-post/messenger-send-post-request.factory";

const handlePostsError = (
  error: HttpError | MessengerPostError
): MessengerPostError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getPosts = async (
  params: MessengerPostLoadParams,
  abortSignal: AbortSignal
): Promise<MessengerPostsMap> => {
  const promises = messengerPostRequestFactory.create(params, abortSignal);

  const result = new Map<MessengerPostChannel, MessengerPostChannelData>();

  try {
    const responses = await Promise.all(promises);

    responses.forEach((response, index) => {
      const channel = params.availableChannels[index];

      if (response.status !== 200) {
        result.set(channel, { isError: true, posts: [] });
        return;
      }

      const posts = messengerPostFactory.create(response);

      const sortedPosts = posts.sort(
        (postA, postB) => postA.date.getTime() - postB.date.getTime()
      );

      result.set(channel, { isError: false, posts: sortedPosts });
    });
  } catch (_error) {
    const error = _error as HttpError | MessengerPostError;

    throw handlePostsError(error);
  }

  return result;
};

const handleSendPostError = (
  error: HttpError | MessengerSendPostError
): MessengerPostError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const sendPost = async (
  params: MessengerSendPostLoadParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const promise = messengerSendPostRequestFactory.create(params, abortSignal);

  try {
    const response = await Promise.resolve(promise);

    if (response!.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | MessengerSendPostError;

    throw handleSendPostError(error);
  }
};

const messengerService = {
  getPosts,
  sendPost,
};

export default messengerService;
