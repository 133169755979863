import useAsyncData from "../../../hooks/use-async-data";
import delegationService from "../delegation.service";
import DelegationRoadRouteItem from "./delegation-road-route-item";
import DelegationRoadRouteLoadParams from "./delegation-road-route-load-params";

const useDelegationRoadRoutes = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<DelegationRoadRouteItem[]>([]);

  const load = async (
    params: DelegationRoadRouteLoadParams[]
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const responses = await Promise.all(
        params.map(async (param) => {
          return await delegationService.getRoute(param);
        })
      );

      setData(responses);
      setIsLoading(false);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useDelegationRoadRoutes;
