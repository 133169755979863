import appTranslationsHelper from "../../../languages/app-translations.helper";

const getLabel = (value: boolean) => {
  const translations = appTranslationsHelper.getCommonTranslations().boolean;

  if (value) {
    return translations.trueLabel;
  }

  return translations.falseLabel;
};

const booleanHelper = {
  getLabel,
};

export default booleanHelper;
