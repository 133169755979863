import DelegationAddFromMileagesRequest, {
  DelegationAddFromMileagesRequestTimezone,
} from "../../../api/raily/delegation/add-from-mileages/delegation-add-from-mileages.request";
import DelegationAddFromMileagesParams, {
  DelegationAddFromMileagesParamsTimezone,
} from "./delegation-add-from-mileages-params";

const createTimezone = (
  timezone: DelegationAddFromMileagesParamsTimezone | undefined
): DelegationAddFromMileagesRequestTimezone | undefined => {
  switch (timezone) {
    case DelegationAddFromMileagesParamsTimezone.EUROPE_WARSAW:
      return DelegationAddFromMileagesRequestTimezone.EUROPE_WARSAW;
    case DelegationAddFromMileagesParamsTimezone.UTC:
      return DelegationAddFromMileagesRequestTimezone.UTC;
    default:
      return DelegationAddFromMileagesRequestTimezone.EUROPE_WARSAW;
  }
};

const create = (
  params: DelegationAddFromMileagesParams
): DelegationAddFromMileagesRequest => {
  return {
    cargoCompanyUuid: params.cargoCompanyUuid,
    mileageUuids: params.mileageUuids,
    workerUuid: params.workerUuid,
    timezone: createTimezone(params.timezone),
  };
};

const delegationAddFromMileagesRequestFactory = {
  create,
};

export default delegationAddFromMileagesRequestFactory;
