import authValidationService from "../../../common/validation/auth-validation.service";

const signUpTaxiOfficerValidationService = {
  validateEmail: authValidationService.validateEmail,
  validateMobile: authValidationService.validateMobile,
  validateAlternativeMobile: authValidationService.validateAlternativeMobile,
  validateUsername: authValidationService.validateUsername,
  validateFirstName: authValidationService.validateFirstName,
  validateLastName: authValidationService.validateLastName,
  validatePassword: authValidationService.validatePassword,
  validatePasswordRepeat: authValidationService.validatePasswordRepeat,
};

export default signUpTaxiOfficerValidationService;
