import TaxiOfficerDetailsLoadParams from "./taxi-officer-details-load-params";
import TaxiOfficerDetailsRequest from "../../../api/raily/taxi-officer/details/taxi-officer-details.request";

const create = (
  params: TaxiOfficerDetailsLoadParams
): TaxiOfficerDetailsRequest => {
  return {
    taxiOfficerUuid: params.taxiOfficerUuid,
  };
};

const taxiOfficerDetailsRequestFactory = {
  create,
};

export default taxiOfficerDetailsRequestFactory;
