import DriverPlanCandidatureAddRequest, {
  DriverPlanCandidatureAddRequestCandidatureCreatePlanEntry,
  DriverPlanCandidatureAddRequestCandidatureCreateSolvedOrderEntry,
  DriverPlanCandidatureAddRequestCandidatureEntryType,
} from "../../../api/raily/driver-plan-candidature/add/driver-plan-candidature-add.request";
import DriverPlanCandidatureAddParams from "./driver-plan-candidature-add-params";

const createPlanEntryCandidating = (
  params: DriverPlanCandidatureAddParams
): DriverPlanCandidatureAddRequestCandidatureCreatePlanEntry => {
  return {
    candidateEntryType:
      DriverPlanCandidatureAddRequestCandidatureEntryType.CREATE_PLAN_ENTRY,
    transportingOrderId: params.transportingOrderUuid,
    taxiCorporationId: params.taxiCorporationUuid,
    givenPlanEntryId: params.givenPlanEntryUuid,
    candidatingSolve: params.solvedOrderUuid,
  };
};

const createSolvedOrderCandidating = (
  params: DriverPlanCandidatureAddParams
): DriverPlanCandidatureAddRequestCandidatureCreateSolvedOrderEntry => {
  return {
    candidateEntryType:
      DriverPlanCandidatureAddRequestCandidatureEntryType.CREATE_SOLVED_ORDER,
    planEntryId: params.planEntryUuid,
    createPlanEntryId: params.createPlanEntryId,
    transportingOrderId: params.transportingOrderUuid,
    candidatingSolve: params.solvedOrderUuid,
  };
};

const createCandidatureEntries = (
  params: DriverPlanCandidatureAddParams
): any => {
  if (params.givenPlanEntryUuid) return createPlanEntryCandidating(params);

  return createSolvedOrderCandidating(params);
};

const create = (
  params: DriverPlanCandidatureAddParams
): DriverPlanCandidatureAddRequest => {
  return {
    driverId: params.driverUuid,
    candidatureEntries: [createCandidatureEntries(params)],
  };
};

const driverPlanCandidatureAddRequestFactory = {
  create,
};

export default driverPlanCandidatureAddRequestFactory;
