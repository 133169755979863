import { FC, useCallback, useEffect, useState } from "react";
import MileageAddressAddModalAddress from "./types/address-add-modal-address";
import MileageAddressAddModalSearchSelectOption from "./types/address-add-modal-search-select-option";
import useForm from "../../../../../common/components/form/use-form";
import AddressAddModalData from "./types/address-add-modal-data";
import addressAddModalHelper from "./address-add-modal.helper";
import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import { debounce } from "lodash";
import SearchAddressResponse from "../../../../../common/utils/search-address/search-address.response";
import searchAddressApiService from "../../../../../common/utils/search-address/search-address.service";
import addressAddModalFactory from "./address-add-modal.factory";
import MileageAddressAddModalAddressSelected from "./types/address-add-modal-address-selected";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import ModalComponent from "../../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import MapAddressSelectorComponent from "../../../../../common/components/map-address-selector/map-address-selector-component";

type MileageAddressAddModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddNewAddress: (address: MileageAddressAddModalAddress) => void;
};

const MileageAddressAddModalComponent: FC<MileageAddressAddModalProps> = (
  props
) => {
  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [isAddressFetching, setIsAddressFetching] = useState(false);
  const [addressSelectOptions, setAddressSelectOptions] = useState<
    MileageAddressAddModalSearchSelectOption[]
  >([]);

  const form = useForm<AddressAddModalData>({
    emptyValues: addressAddModalHelper.getDefaultFormData(),
    validationDefinition: addressAddModalHelper.getValidationDefinition(),
  });

  const isAddressFormFieldEnabled = !!form.values.foundAddress;

  const translation =
    mileageTranslationsHelper.getMileageAddTranslations().newAddress;

  useEffect(() => {
    if (!addressSearchQuery) return;

    getDebounceAddressSelectOptions(addressSearchQuery);
  }, [addressSearchQuery]);

  useEffect(() => {
    if (props.isOpen) return;

    setAddressSearchQuery("");
    form.restore();
  }, [props.isOpen]);

  const getDebounceAddressSelectOptions = useCallback(
    debounce((addressSearchQuery: string) => {
      getAddressSelectOptions(addressSearchQuery);
    }, 300),
    []
  );

  const fetchResolvedAddress = (
    addressSearchQuery: string
  ): Promise<SearchAddressResponse> =>
    searchAddressApiService.searchByQuery(addressSearchQuery);

  const getAddressSelectOptions = (addressSearchQuery: string) => {
    setIsAddressFetching(true);

    fetchResolvedAddress(addressSearchQuery)
      .then((response) => {
        const selectOptions =
          addressAddModalFactory.createAddressSelectOptions(response);

        setAddressSelectOptions(selectOptions);
      })
      .finally(() => {
        setIsAddressFetching(false);
      });
  };

  const addNewAddress = () => {
    const newAddress = addressAddModalFactory.createAddress(form.values);
    props.onAddNewAddress(newAddress);
  };

  const acceptSelectedAddress = (
    value: MileageAddressAddModalAddressSelected
  ) => {
    const foundAddress = {
      label: value.name,
      value: value,
    };

    const address = {
      foundAddress: foundAddress,
      type: null,
      street: value.street ?? "",
      houseNumber: value.houseNumber ?? "",
      apartmentNumber: value.apartmentNumber ?? "",
      zipCode: value.zipCode ?? "",
      country: value.country ?? "",
      town: value.town ?? "",
      description: "",
    };

    form.setValues(address);
  };

  const onAddressSelectChange = (value: SelectOption | null) => {
    if (!value) {
      form.setValues(addressAddModalHelper.getDefaultFormData());
      return;
    }

    form.setValues(value.value);
    form.setValue("foundAddress", value);
  };

  const checkIsFormModified = (): boolean =>
    !!(
      addressSearchQuery !== "" ||
      form.values.street !== "" ||
      form.values.houseNumber !== "" ||
      form.values.apartmentNumber !== "" ||
      form.values.zipCode !== "" ||
      form.values.town !== ""
    );

  const onSubmitButtonClick = async () => {
    const isFormValid: boolean = await form.validateAll();
    if (!isFormValid) return;

    props.onClose();
    addNewAddress();
  };

  return (
    <ModalComponent
      header={{ title: translation.titleLabel }}
      classNames={{ root: "address_add_modal" }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          title={translation.submitButtonTitle}
          idForTesting="add-new-address-submit-button"
        >
          {translation.submitButtonText}
        </ButtonComponent>,
      ]}
      closeConfirmation={{
        title: translation.closeConfirmation.title,
        messageText: translation.closeConfirmation.textMessage,
        shouldBeVisibleOnCloseClick: checkIsFormModified(),
      }}
    >
      <div style={{ fontSize: 14, padding: `0 0 20px 0` }}>
        {translation.tipLabel}
      </div>
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={translation.searchLabel}
            classNames={{ root: "mt-0" }}
          >
            <SingleSelectComponent
              placeholder={translation.searchPlaceholder}
              options={addressSelectOptions}
              value={form.values.foundAddress}
              isLoading={isAddressFetching}
              inputValue={addressSearchQuery}
              onInputChange={setAddressSearchQuery}
              isClearable
              onChange={onAddressSelectChange}
              isSearchable
              menuPlacement="bottom"
              filterFunction={() => true}
              idForTesting="add-new-address-search-select"
              menuPosition="fixed"
            />
          </FormFieldComponent>
          <Row>
            <Column xl={12}>
              <FormFieldComponent
                label={translation.streetLabel}
                isRequired
                errorMessage={form.validationResults.street.errorMessage}
              >
                <InputComponent
                  value={form.values.street}
                  onChange={(value) => {
                    form.setValue("street", value);
                  }}
                  placeholder={translation.streetPlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={!!form.validationResults.street.errorMessage}
                  onBlur={() => form.validate("street")}
                  idForTesting="add-new-address-street-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translation.houseNumberLabel}
                isRequired
                errorMessage={form.validationResults.houseNumber.errorMessage}
              >
                <InputComponent
                  value={form.values.houseNumber}
                  onChange={(value) => {
                    form.setValue("houseNumber", value);
                  }}
                  placeholder={translation.houseNumberPlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={!!form.validationResults.houseNumber.errorMessage}
                  onBlur={() => form.validate("houseNumber")}
                  idForTesting="add-new-address-house-number-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={6}>
              <FormFieldComponent
                label={translation.apartmentNumberLabel}
                errorMessage={
                  form.validationResults.apartmentNumber.errorMessage
                }
              >
                <InputComponent
                  value={form.values.apartmentNumber}
                  onChange={(value) => {
                    form.setValue("apartmentNumber", value);
                  }}
                  placeholder={translation.apartmentNumberPlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={
                    !!form.validationResults.apartmentNumber.errorMessage
                  }
                  onBlur={() => form.validate("apartmentNumber")}
                  idForTesting="add-new-address-apartment-number-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translation.townLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={form.validationResults.town.errorMessage}
              >
                <InputComponent
                  value={form.values.town}
                  onChange={(value) => {
                    form.setValue("town", value);
                  }}
                  placeholder={translation.townPlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={!!form.validationResults.town.errorMessage}
                  onBlur={() => form.validate("town")}
                  idForTesting="add-new-address-town-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={6}>
              <FormFieldComponent
                label={translation.zipCodeLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={form.validationResults.zipCode.errorMessage}
              >
                <InputComponent
                  value={form.values.zipCode}
                  onChange={(value) => {
                    form.setValue("zipCode", value);
                  }}
                  placeholder={translation.zipCodePlaceholder}
                  isDisabled={!isAddressFormFieldEnabled}
                  hasError={!!form.validationResults.zipCode.errorMessage}
                  onBlur={() => form.validate("zipCode")}
                  idForTesting="add-new-address-zip-code-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
        </Column>
        <Column xl={6}>
          <FormFieldComponent
            label={translation.mapLabel}
            classNames={{
              root: "mt-0 address_add_modal_field",
            }}
          >
            <div className="address_add_modal_map">
              <MapAddressSelectorComponent
                address={form.values.foundAddress?.value}
                onAddressChange={acceptSelectedAddress}
              />
            </div>
          </FormFieldComponent>
        </Column>
      </Row>
    </ModalComponent>
  );
};

export default MileageAddressAddModalComponent;
