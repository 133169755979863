import Joi from "joi";
import UserCitizenship from "../../../../../../common/types/user-citizenship";
import UserCitizenshipSelectOption from "../../../../../../common/types/user-citizenship-select-option";
import UserFormOfEmployment from "../../../../../../common/types/user-form-of-employment";
import UserFormOfEmploymentSelectOption from "../../../../../../common/types/user-form-of-employment-select-option";
import UserLanguage from "../../../../../../common/types/user-language";
import UserLanguageSelectOption from "../../../../../../common/types/user-language-select-option";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import userFormValidationService from "../../../../../../shared/user/user.validation.service";
import DriverEditAddress from "../types/driver-edit-address";
import DriverEditUserDataFleetPartnerSelectOption from "../types/driver-edit-user-data-fleet-partner-select-option";
import AddressType from "../../../../../../common/types/address-type";

const validateBirthDate = (birthDate: Date | null): FormValidationResult => {
  const currentDate = new Date();
  const pastDate = new Date().setDate(currentDate.getDate() - 1);

  const validationSchema = Joi.date()
    .min("1940-01-01")
    .max(pastDate)
    .required();

  return formValidationService.validate(birthDate, validationSchema);
};

const validateBirthPlace = (birthPlace: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).trim().required();

  return formValidationService.validate(birthPlace, validationSchema);
};

const validatePersonalIdNumber = (
  personalIdNumber: string
): FormValidationResult => {
  const pattern = new RegExp(`^[0-9]*$`);
  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .trim()
    .regex(pattern)
    .required();

  return formValidationService.validate(personalIdNumber, validationSchema);
};

const validateCitizenship = (
  citizenshipSelectOption: UserCitizenshipSelectOption | null
): FormValidationResult => {
  const validValues = Object.values(UserCitizenship);

  const validationSchema = Joi.string()
    .valid(...validValues)
    .required();

  const citizenship = citizenshipSelectOption?.value;

  return formValidationService.validate(citizenship, validationSchema);
};

const validateLanguages = (
  languageSelectOptions: UserLanguageSelectOption[]
): FormValidationResult => {
  const validValues = Object.values(UserLanguage);

  const validationSchema = Joi.array()
    .min(1)
    .max(10)
    .items(Joi.string().valid(...validValues))
    .required();

  const languages = languageSelectOptions.map((item) => item.value);

  return formValidationService.validate(languages, validationSchema);
};

const validateYearsOfExperience = (
  yearsOfExperience: number | null
): FormValidationResult => {
  const validationSchema = Joi.number().integer().min(0).max(50).required();

  return formValidationService.validate(yearsOfExperience, validationSchema);
};

const validateFormOfEmployment = (
  formOfEmploymentOption: UserFormOfEmploymentSelectOption | null
): FormValidationResult => {
  const validValues = Object.values(UserFormOfEmployment);

  const validationSchema = Joi.array()
    .valid(...validValues)
    .required();

  const formOfEmployment = formOfEmploymentOption?.value;

  return formValidationService.validate(formOfEmployment, validationSchema);
};

const validateFleetPartner = (
  fleetPartner: DriverEditUserDataFleetPartnerSelectOption | null
): FormValidationResult => {
  const validationSchema = Joi.object().allow(null).required();

  return formValidationService.validate(fleetPartner, validationSchema);
};

const validateAddresses = (
  addresses: DriverEditAddress[]
): FormValidationResult => {
  const lengthValidationSchema = Joi.array().length(2).required();

  const lengthValidationResult = formValidationService.validate(
    addresses,
    lengthValidationSchema
  );

  if (!lengthValidationResult.isValid) {
    return lengthValidationResult;
  }

  const mailingAddress = addresses.find(
    (item) => item.type === AddressType.MAILING
  );
  const startingAddress = addresses.find(
    (item) => item.type === AddressType.STARTING
  );

  if (!mailingAddress) {
    return formValidationService.bad(
      formValidationService.getValidationCustomMessages()
        .driverSignUpMailingAddressValidationError
    );
  }

  if (!startingAddress) {
    return formValidationService.bad(
      formValidationService.getValidationCustomMessages()
        .driverSignUpStartingAddressValidationError
    );
  }

  return lengthValidationResult;
};

const driverEditUserDataFormValidationService = {
  validateFirstName: userFormValidationService.validateFirstName,
  validateLastName: userFormValidationService.validateLastName,
  validateMobile: userFormValidationService.validateMobile,
  validateAlternativeMobile:
    userFormValidationService.validateAlternativeMobile,
  validateBirthDate,
  validateBirthPlace,
  validatePersonalIdNumber,
  validateCitizenship,
  validateLanguages,
  validateYearsOfExperience,
  validateFormOfEmployment,
  validateFleetPartner,
  validateAddresses,
};

export default driverEditUserDataFormValidationService;
