import TaxiDriverContractUpdateRequest from "../../../api/raily/taxi-driver-contract/update/taxi-driver-contract-update.request";
import TaxiDriverContractUpdateParams from "./taxi-driver-contract-update-params";

const create = (
  params: TaxiDriverContractUpdateParams
): TaxiDriverContractUpdateRequest => {
  return {
    contractId: params.contractUuid,
    disabled: !params.isActive,
  };
};

const taxiDriverContractUpdateRequestFactory = {
  create,
};

export default taxiDriverContractUpdateRequestFactory;
