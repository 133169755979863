import railyApiService from "../raily-api.service";
import mileageAddressListRequestFactory from "./list/mileage-address-list-request.factory";
import MileageAddressListRequest, {
  MileageAddressListQueryParams,
} from "./list/mileage-address-list.request";
import MileageAddressListResponse from "./list/mileage-address-list.response";

const mileageAddressApiService = () => {
  const getList = async (
    request: MileageAddressListRequest,
    abortSignal: AbortSignal
  ): Promise<MileageAddressListResponse> => {
    const url = `/mileage/address`;

    const queryParams: MileageAddressListQueryParams =
      mileageAddressListRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    getList,
  };
};

export default mileageAddressApiService;
