import { FC } from "react";
import ContractDetails from "../types/contract-details";
import CardComponent from "../../../../../common/components/card/card.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import contractDetailsHelper from "./contract-details.helper";

type ContractDetailsProps = {
  contractDetails: ContractDetails | null;
  isLoading: boolean;
};

const ContractDetailsComponent: FC<ContractDetailsProps> = (props) => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiAddBillingsTranslations();
  const Content = (
    <Row>
      <Column lg={6}>
        <div className="contract_details_item">
          <div>{translations.contractDetails.billingModelText}</div>
          <div>{props.contractDetails?.model}</div>
        </div>
        <div className="contract_details_item">
          <div>{translations.contractDetails.rateText}</div>
          <div>{props.contractDetails?.distanceRate.toFixed(2)}</div>
        </div>
      </Column>
      <Column lg={6}>
        <div className="contract_details_item">
          <div>{translations.contractDetails.haltingRate}</div>
          <div>{props.contractDetails?.haltingTimeRate.toFixed(2)}</div>
        </div>
        <div className="contract_details_item">
          <div>
            {contractDetailsHelper.getHaltingText(
              props.contractDetails?.haltingTimeAppliedAfter
            )}
          </div>
        </div>
      </Column>
    </Row>
  );

  const shouldRenderContent = !!props.contractDetails && !props.isLoading;

  return (
    <CardComponent
      isLoading={props.isLoading}
      classNames={{ root: "contract_details" }}
      header={{ title: props.contractDetails?.companyName ?? "" }}
    >
      {shouldRenderContent && Content}
    </CardComponent>
  );
};

export default ContractDetailsComponent;
