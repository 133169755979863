import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import UserRole from "../../../../common/types/user-role";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingsTaxiTaxiContributionsBonusHelper from "../common/contributions/bonus/billings-taxi-taxi-contributions-bonus.helper";
import BillingsTaxiTaxiContributionBonus, {
  BillingsTaxiTaxiContributionBonusType,
} from "../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus";
import billingsTaxiTaxiContributionsPenaltyHelper from "../common/contributions/penalty/billings-taxi-taxi-contributions-penalty.helper";
import BillingsTaxiTaxiContributionPenalty, {
  BillingsTaxiTaxiContributionPenaltyType,
} from "../common/contributions/penalty/types/billings-taxi-taxi-contributions-penalty";
import BillingContributionType from "./types/billing-contribution-type";
import BillingContributionTypeSelectOption from "./types/billing-contribution-type-select-option";
import { BillingFormDataContributions } from "./types/billing-form.data";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
      UserRole.TAXI_OFFICER,
      UserRole.DEALER,
    ],
    [MessengerChannel.DRIVER]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    [MessengerChannel.SHARED]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
      UserRole.TAXI_OFFICER,
      UserRole.DEALER,
    ],
    [MessengerChannel.TAXI]: [UserRole.DEALER, UserRole.TAXI_OFFICER],
  };
};

const getContributionsBonusTypeSelectOptions =
  (): BillingContributionTypeSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiTaxiContributionsTranslations();

    return [
      {
        label: translations.bonus.type.favorableDistance,
        value: BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE,
      },
      {
        label: translations.bonus.type.other,
        value: BillingsTaxiTaxiContributionBonusType.BONUS_OTHER,
      },
    ];
  };

const getContributionsPenaltyTypeSelectOptions =
  (): BillingContributionTypeSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiTaxiContributionsTranslations();

    return [
      {
        label: translations.penalty.type.beingLate,
        value: BillingsTaxiTaxiContributionPenaltyType.PENALTY_BEING_LATE,
      },
      {
        label: translations.penalty.type.incompatibleCar,
        value: BillingsTaxiTaxiContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR,
      },
      {
        label: translations.penalty.type.other,
        value: BillingsTaxiTaxiContributionPenaltyType.PENALTY_OTHER,
      },
      {
        label: translations.penalty.type.wrongRoute,
        value: BillingsTaxiTaxiContributionPenaltyType.PENALTY_WRONG_ROUTE,
      },
    ];
  };

const getContributionTypeFromTabIndex = (
  tabIndex: number
): BillingContributionType | undefined => {
  switch (tabIndex) {
    case 0:
      return BillingContributionType.BONUS;
    case 1:
      return BillingContributionType.PENALTY;
    default:
      return undefined;
  }
};

const getMaxContributionsFromTabIndex = (tabIndex: number): number => {
  const contributionType: BillingContributionType | undefined =
    getContributionTypeFromTabIndex(tabIndex);

  switch (contributionType) {
    case BillingContributionType.BONUS:
      return Object.keys(BillingsTaxiTaxiContributionBonusType).length;
    case BillingContributionType.PENALTY:
      return Object.keys(BillingsTaxiTaxiContributionPenaltyType).length;
    default:
      return 0;
  }
};

const getNewContribution = (
  type: BillingContributionType
): BillingsTaxiTaxiContributionBonus | BillingsTaxiTaxiContributionPenalty => {
  switch (type) {
    case BillingContributionType.BONUS:
      return billingsTaxiTaxiContributionsBonusHelper.getBonusNewContribution();
    case BillingContributionType.PENALTY:
      return billingsTaxiTaxiContributionsPenaltyHelper.getNewPenaltyContribution();
  }
};

const getContributionsByType = (
  contributions: BillingFormDataContributions,
  type: BillingContributionType
):
  | BillingsTaxiTaxiContributionBonus[]
  | BillingsTaxiTaxiContributionPenalty[] => {
  switch (type) {
    case BillingContributionType.BONUS:
      return contributions.bonus;
    case BillingContributionType.PENALTY:
      return contributions.penalty;
  }
};

const billingsTaxiTaxiHelper = {
  getMessengerChannelAvailability,
  getContributionsBonusTypeSelectOptions,
  getContributionsPenaltyTypeSelectOptions,
  getContributionTypeFromTabIndex,
  getNewContribution,
  getContributionsByType,
  getMaxContributionsFromTabIndex,
};

export default billingsTaxiTaxiHelper;
