import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import taxiTaxiContractListingHelper from "../../common/taxi-taxi-contract-listing.helper";

type TaxiTaxiContractListingTablePartnerProps = {
  isPartner: boolean;
};

const TaxiTaxiContractListingTablePartnerComponent: FC<
  TaxiTaxiContractListingTablePartnerProps
> = (props) => {
  const UsingIcon = (
    <FontAwesomeIcon
      icon={faCheck}
      title={taxiTaxiContractListingHelper.getPartnerStatusText(true)}
    />
  );

  const NotUsingIcon = (
    <FontAwesomeIcon
      icon={faXmark}
      title={taxiTaxiContractListingHelper.getPartnerStatusText(false)}
    />
  );

  return <div>{props.isPartner ? UsingIcon : NotUsingIcon}</div>;
};

export default TaxiTaxiContractListingTablePartnerComponent;
