import { faIcons } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import settlementRoutesHelper from "../routes/settlement-routes.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";

const getTaxiUnsettledOrderListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: settlementRoutesHelper.getTaxiListingOfUnsettledOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .settlementsTaxiUnsettledOrderListing,
    },
  ];
};

const getTaxiUnsettledPrivateOrderListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo:
        settlementRoutesHelper.getTaxiListingOfUnsettledPrivateOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .settlementsTaxiUnsettledPrivateOrderListing,
    },
  ];
};

const getTaxiUnsettledRouteListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: settlementRoutesHelper.getTaxiListingOfUnsettledRoutesRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .settlementsTaxiUnsettledRouteListing,
    },
  ];
};

const getTaxiSettledRouteListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: settlementRoutesHelper.getTaxiListingOfSettledRoutesRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .settlementsTaxiSettledRouteListing,
    },
  ];
};

const getCargoOrderSettlementListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: settlementRoutesHelper.getCargoListingOfOrderSettlementRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .settlementsCargoSettledOrderListing,
    },
  ];
};

const getTaxiOrderSettlementListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: settlementRoutesHelper.getTaxiListingOfSettledOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .settlementsTaxiSettledOrderListing,
    },
  ];
};

const getTaxiUnsettledTransferedOrderListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo:
        settlementRoutesHelper.getTaxiListingOfUnsettledTransferedOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .settlementsTaxiUnsettledTransferedOrderListing,
    },
  ];
};

const getTaxiSettledTransferedOrderListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo:
        settlementRoutesHelper.getTaxiListingOfSettledTransferedOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .settlementsTaxiSettledTransferedOrderListing,
    },
  ];
};

const settlementBreadcrumbsHelper = {
  getTaxiUnsettledOrderListingBreadcrumbs,
  getTaxiUnsettledPrivateOrderListingBreadcrumbs,
  getTaxiSettledRouteListingBreadcrumbs,
  getCargoOrderSettlementListingBreadcrumbs,
  getTaxiOrderSettlementListingBreadcrumbs,
  getTaxiUnsettledRouteListingBreadcrumbs,
  getTaxiUnsettledTransferedOrderListingBreadcrumbs,
  getTaxiSettledTransferedOrderListingBreadcrumbs,
};

export default settlementBreadcrumbsHelper;
