import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import PlanningOrderDriverPlanListingAddressFilterFormData from "./common/planning-order-driver-plan-listing-address-filter-form-data";
import planningOrderDriverPlanListingAddressFormValidationService from "./planning-order-driver-plan-listing-addres-form-validation.service";

const getDefaultData =
  (): PlanningOrderDriverPlanListingAddressFilterFormData => {
    return {
      address: "",
      startDate: null,
      endDate: null,
    };
  };

const getValidationDefinition =
  (): FormValidationDefinition<PlanningOrderDriverPlanListingAddressFilterFormData> => {
    return {
      address: (values) =>
        planningOrderDriverPlanListingAddressFormValidationService.validateAddress(
          values.address
        ),
      startDate: (values) =>
        planningOrderDriverPlanListingAddressFormValidationService.validateDate(
          values.startDate
        ),
      endDate: (values) =>
        planningOrderDriverPlanListingAddressFormValidationService.validateDate(
          values.endDate
        ),
    };
  };

const planningOrderDriverPlanListingAddressFilterService = {
  getDefaultData,
  getValidationDefinition,
};

export default planningOrderDriverPlanListingAddressFilterService;
