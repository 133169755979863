import { FormValidationDefinition } from "../../../../common/components/form/use-form";
import mileageDetailsFormValidationService from "./mileage-details-form-validation.service";
import MileageDetailsFormData from "./types/mileage-details-form-data";

const getDefaultFormData = (): MileageDetailsFormData => {
  return {
    acceptedDistance: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<MileageDetailsFormData> => {
    return {
      acceptedDistance: (values) =>
        mileageDetailsFormValidationService.validateAcceptedDistance(
          values.acceptedDistance
        ),
    };
  };

const mileageDetailsFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default mileageDetailsFormHelper;
