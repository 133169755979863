import { FC } from "react";
import ButtonComponent from "../../../button/button.component";
import ListingFilterBadge from "../badge/listing-filter-badge";
import ListingFilterBadgeComponent from "../badge/listing-filter-badge.component";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";

export type ListingFiltersBadgeListProps = {
  badges: ListingFilterBadge[];
  onDelete: (index: number) => void;
  onDeleteAll: () => void;
};

const ListingFilterBadgeListComponent: FC<ListingFiltersBadgeListProps> = (
  props
) => {
  const translations =
    appTranslationsHelper.getComponentTranslations().listing.filter.badgeList;

  const isClearAllButtonVisible = !!props.badges.length;

  return (
    <div className="listing_filter_badge_list">
      {props.badges.map((badge, index) => {
        return (
          <ListingFilterBadgeComponent
            key={index}
            title={badge.title}
            text={badge.text}
            onDeleteButtonClick={() => props.onDelete(index)}
          />
        );
      })}
      {isClearAllButtonVisible && (
        <ButtonComponent
          classNames={{
            root: "listing_filter_badge_list__clear_button",
          }}
          onClick={props.onDeleteAll}
          title={translations.deleteAllFiltersTitle}
        >
          {translations.deleteAllFiltersText}
        </ButtonComponent>
      )}
    </div>
  );
};

export default ListingFilterBadgeListComponent;
