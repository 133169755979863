import { FC, useEffect, useMemo, useState } from "react";
import { BillingsPlanEntryTaxiTaxiItem } from "../../../../../../common/services/billings/plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi";
import { useAppContext } from "../../../../../../context/app.context";
import useAbort from "../../../../../../common/hooks/use-abort";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import CardComponent from "../../../../../../common/components/card/card.component";
import useBillingsPlanEntryTaxiTaxi from "../../../../../../common/services/billings/plan-entry/taxi-taxi/use-billings-plan-entry-taxi-taxi";
import RelatedBillingsTaxiTaxiTableRow from "../../../../common/related-billings/taxi-taxi/types/related-billings-taxi-taxi-table-row";
import RelatedBillingsTaxiTaxiTableComponent from "../../../../common/related-billings/taxi-taxi/related-billings-taxi-taxi-table.component";
import realtedBillingsTaxiTaxiFactory from "./related-billings-taxi-taxi.factory";

type RelatedBillingsTaxiTaxiProps = {
  planEntryId: string | undefined;
};

const RelatedBillingsTaxiTaxiComponent: FC<RelatedBillingsTaxiTaxiProps> = (
  props
) => {
  const translations =
    billingsTranslationsHelper.getBillingsTranslations().relatedBillings
      .taxi_taxi;

  const [relatedBillingsTaxiTaxiData, setRelatedBillingsTaxiTaxiData] =
    useState<BillingsPlanEntryTaxiTaxiItem[]>([]);

  const { selectedAppLanguage } = useAppContext();
  const taxiTaxi = useBillingsPlanEntryTaxiTaxi();
  const taxiTaxiAbort = useAbort();

  const taxiTaxiData = async () => {
    taxiTaxi.load({ planEntryUuid: props.planEntryId! }, taxiTaxiAbort.signal);
  };

  useEffect(() => {
    if (!props.planEntryId) return;

    taxiTaxiData();
  }, [props.planEntryId]);

  useEffect(() => {
    if (!taxiTaxi.data.data) return;

    setRelatedBillingsTaxiTaxiData(taxiTaxi.data.data);
  }, [taxiTaxi.data.data]);

  const tableRows: RelatedBillingsTaxiTaxiTableRow[] = useMemo(() => {
    if (!relatedBillingsTaxiTaxiData) return [];

    return realtedBillingsTaxiTaxiFactory.createTableRows(
      relatedBillingsTaxiTaxiData
    );
  }, [relatedBillingsTaxiTaxiData, selectedAppLanguage]);

  return (
    <CardComponent
      classNames={{ root: "related_billings" }}
      header={{ title: translations.summaryLabel }}
    >
      <RelatedBillingsTaxiTaxiTableComponent
        tableRows={tableRows}
        isLoading={taxiTaxi.isLoading && !!props.planEntryId}
      />
    </CardComponent>
  );
};

export default RelatedBillingsTaxiTaxiComponent;
