import BillingModel from "../../../types/billing-model";
import {
  BillingData,
  BillingDataResponseBonusContribution,
  BillingDataResponseContribution,
  BillingDataResponseContributionType,
  BillingDataResponsePenaltyContribution,
} from "../api/billing-data.response";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import BillingsTaxiTaxiContributionBonus, {
  BillingsTaxiTaxiContributionBonusType,
} from "../../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus";
import BillingsTaxiTaxiContributionPenalty, {
  BillingsTaxiTaxiContributionPenaltyType,
} from "../../common/contributions/penalty/types/billings-taxi-taxi-contributions-penalty";
import TabsData from "../../../../../common/components/tabs/common/types/tabs-data";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiTaxiContributionsBonusTableRow from "../../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus-table-row";
import BillingsTaxiTaxiContributionsPenaltyTableRow from "../../common/contributions/penalty/types/billings-taxi-taxi-contributions-penalty-table-row";
import BillingsTaxiTaxiContributionsBonusTableComponent from "../../common/contributions/bonus/table/billings-taxi-taxi-contributions-bonus-table.component";
import BillingsTaxiTaxiContributionsPenaltyTableComponent from "../../common/contributions/penalty/table/billings-taxi-taxi-contributions-penalty-table.component";
import billingsTaxiTaxiContributionsBonusHelper from "../../common/contributions/bonus/billings-taxi-taxi-contributions-bonus.helper";
import billingsTaxiTaxiContributionsPenaltyHelper from "../../common/contributions/penalty/billings-taxi-taxi-contributions-penalty.helper";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingPenaltyContributionType = (
  contributionType: BillingDataResponseContributionType
): BillingsTaxiTaxiContributionPenaltyType | undefined => {
  switch (contributionType) {
    case BillingDataResponseContributionType.PENALTY_WRONG_ROUTE:
      return BillingsTaxiTaxiContributionPenaltyType.PENALTY_WRONG_ROUTE;
    case BillingDataResponseContributionType.PENALTY_BEING_LATE:
      return BillingsTaxiTaxiContributionPenaltyType.PENALTY_BEING_LATE;
    case BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR:
      return BillingsTaxiTaxiContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR;
    case BillingDataResponseContributionType.PENALTY_OTHER:
      return BillingsTaxiTaxiContributionPenaltyType.PENALTY_OTHER;
    default:
      return undefined;
  }
};

const createBillingBonusContribution = (
  contribution: BillingDataResponseBonusContribution
): BillingsTaxiTaxiContributionBonus => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE:
      return {
        type: BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE,
        comment: contribution.comment ?? "",
        distance: contribution.distance,
        rate: contribution.rate,
      };
    case BillingDataResponseContributionType.BONUS_OTHER:
      return {
        type: BillingsTaxiTaxiContributionBonusType.BONUS_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount,
        distance: null,
      };
  }
};

const createBillingPenaltyContribution = (
  contribution: BillingDataResponsePenaltyContribution
): BillingsTaxiTaxiContributionPenalty => {
  return {
    type: createBillingPenaltyContributionType(contribution.type) ?? null,
    comment: contribution.comment ?? "",
    amount: contribution.amount,
  };
};

const createBillingContributions = (
  contributions: BillingDataResponseContribution[]
): BillingFormDataContributions => {
  const bonusContributions: BillingsTaxiTaxiContributionBonus[] = contributions
    .filter((contribution) =>
      [
        BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE,
        BillingDataResponseContributionType.BONUS_OTHER,
      ].includes(contribution.type)
    )
    .map((contribution) =>
      createBillingBonusContribution(
        contribution as BillingDataResponseBonusContribution
      )
    );

  const penaltyContributions: BillingsTaxiTaxiContributionPenalty[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.PENALTY_BEING_LATE,
          BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR,
          BillingDataResponseContributionType.PENALTY_OTHER,
          BillingDataResponseContributionType.PENALTY_WRONG_ROUTE,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingPenaltyContribution(
          contribution as BillingDataResponsePenaltyContribution
        )
      );

  return {
    bonus: bonusContributions,
    penalty: penaltyContributions,
  };
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    orderId: data.order_forwarding_action.transporting_order.cargo_order.id,
    forwardingId: data.order_forwarding_action.id,
    billingNodes: createBillingNodes(data),
    billingContributions: createBillingContributions(
      data.billing_contributions
    ),
    contractDetails: {
      companyName:
        data.order_forwarding_action.contract.producer_taxi.display_name,
      haltingTimeRate: data.order_forwarding_action.contract.halting_time_rate,
      distanceRate: data.order_forwarding_action.contract.distance_rate,
      model: data.order_forwarding_action.contract.model as BillingModel,
      haltingTimeAppliedAfter:
        data.order_forwarding_action.contract.halting_time_after_minutes,
    },
    baseAmountValue: data.base_amount_value,
    amountForChargeHaltings: data.amount_for_charge_haltings,
    amountForChargeHighways: data.amount_for_charge_highways,
    distance: data.distance,
    amountForDistance: data.amount_for_distance,
    internalOrderId:
      data.order_forwarding_action.transporting_order.cargo_order.human_id,
    allContributionsAmount: data.all_contributions_amount,
    planEntryId: data.plan_entry_id,
    sumOfBonuses: data.sum_of_bonuses,
    sumOfPenalties: data.sum_of_penalties,
    date: data.date,
    status: data.status,
    producerTaxiUuid: data.order_forwarding_action.contract.producer_taxi.id,
    consumerTaxiUuid: data.order_forwarding_action.contract.consumer_taxi.id,
    passengers: data.passengers,
    rateForDistance: data.rate_for_distance,
    favorableDistance: data.favorable_distance,
  };

  return billingDraft;
};

const createContributionsBonusTableRow = (
  contribution: BillingsTaxiTaxiContributionBonus,
  contributionIndex: number
): BillingsTaxiTaxiContributionsBonusTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiContributionsTranslations();

  const contributionType =
    billingsTaxiTaxiContributionsBonusHelper.getContributionTypeTranslation(
      contribution.type
    );

  switch (contribution.type) {
    case BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: <div title={contributionType}>{contributionType}</div>,
          distance: (
            <div title={String(contribution.distance)}>
              {String(contribution.distance)}
            </div>
          ),
          rate: (
            <div title={String(contribution.rate)}>
              {String(contribution.rate)}
            </div>
          ),
          amount: (
            <div title={translations.attributeNotApplicable}>
              {translations.attributeNotApplicable}
            </div>
          ),
          comment: (
            <div title={contribution.comment}>{contribution.comment}</div>
          ),
          actions: <div className="d-flex"></div>,
        },
      };
    case BillingsTaxiTaxiContributionBonusType.BONUS_OTHER:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: <div title={contributionType}>{contributionType}</div>,
          distance: (
            <div title={translations.attributeNotApplicable}>
              {translations.attributeNotApplicable}
            </div>
          ),
          rate: (
            <div title={translations.attributeNotApplicable}>
              {translations.attributeNotApplicable}
            </div>
          ),
          amount: (
            <div title={String(contribution.amount)}>
              {String(contribution.amount)}
            </div>
          ),
          comment: (
            <div title={contribution.comment}>{contribution.comment}</div>
          ),
          actions: <div className="d-flex"></div>,
        },
      };
    default:
      return {
        id: String(contributionIndex),
        value: {
          type: <div></div>,
          distance: <div></div>,
          rate: <div></div>,
          amount: <div></div>,
          comment: <div></div>,
          actions: <div className="d-flex"></div>,
        },
      };
  }
};

const createContributionsPenaltyTableRow = (
  contribution: BillingsTaxiTaxiContributionPenalty,
  contributionIndex: number
): BillingsTaxiTaxiContributionsPenaltyTableRow => {
  const contributionType =
    billingsTaxiTaxiContributionsPenaltyHelper.getContributionTypeTranslation(
      contribution.type
    );

  return {
    id: `${contribution.type}-${contributionIndex}`,
    value: {
      type: <div title={contributionType}>{contributionType}</div>,
      amount: (
        <div title={String(contribution.amount)}>
          {String(contribution.amount)}
        </div>
      ),
      comment: <div title={contribution.comment}>{contribution.comment}</div>,
      actions: <div className="d-flex"></div>,
    },
  };
};

const createTabsData = (
  contributions: BillingFormDataContributions,
  isDataLoading: boolean
): TabsData => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiContributionsTranslations();

  const bonusTableRows: BillingsTaxiTaxiContributionsBonusTableRow[] =
    contributions.bonus.map((contribution, index) =>
      createContributionsBonusTableRow(contribution, index)
    );

  const penaltyTableRows: BillingsTaxiTaxiContributionsPenaltyTableRow[] =
    contributions.penalty.map((contribution, index) =>
      createContributionsPenaltyTableRow(contribution, index)
    );

  const BonusTableContent = (
    <BillingsTaxiTaxiContributionsBonusTableComponent
      isLoading={isDataLoading}
      rows={bonusTableRows}
    />
  );

  const PenaltyTableContent = (
    <BillingsTaxiTaxiContributionsPenaltyTableComponent
      isLoading={isDataLoading}
      rows={penaltyTableRows}
    />
  );

  const tabsData: TabsData = [
    {
      label: translations.bonus.title,
      content: BonusTableContent,
      counter: contributions.bonus.filter((contribution) => contribution.type)
        .length,
      totalTableRows: bonusTableRows.length,
    },
    {
      label: translations.penalty.title,
      content: PenaltyTableContent,
      counter: contributions.penalty.filter((contribution) => contribution.type)
        .length,
      totalTableRows: penaltyTableRows.length,
    },
  ];

  return tabsData;
};

const billingDataFactory = {
  createBillingData,
  createTabsData,
};

export default billingDataFactory;
