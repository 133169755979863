import mileageRoutesUrls from "./mileage-routes-urls";
import MileageContractUpdateRouteParams from "./types/mileage-contract-update-route-params";
import MileageDictionaryListingRouteParams from "./types/mileage-dictionary-listing.route.params";
import MileageDictionaryRecordAddRouteParams from "./types/mileage-dictionary-record-add-route-params";
import MileageDictionaryRecordUpdateRouteParams from "./types/mileage-dictionary-record-update-route-params";
import MileageUpdateRouteParams from "./types/mileage-update-route-params";

const getAddRoute = (): string => {
  return mileageRoutesUrls.add;
};

const getUpdateRoute = (params: MileageUpdateRouteParams): string => {
  return mileageRoutesUrls.update.replace(":mileageUuid", params.mileageUuid);
};

const getListingRoute = (): string => {
  return mileageRoutesUrls.listing;
};

const getContractListingRoute = (): string => {
  return mileageRoutesUrls.contractListing;
};

const getContractAddRoute = (): string => {
  return mileageRoutesUrls.contractAdd;
};

const getContractUpdateRoute = (
  params: MileageContractUpdateRouteParams
): string => {
  return mileageRoutesUrls.contractUpdate.replace(
    ":contractUuid",
    params.contractUuid
  );
};

const getSettingsRoute = (): string => {
  return mileageRoutesUrls.settings;
};

const getDictionaryRoute = (
  params?: MileageDictionaryListingRouteParams
): string => {
  return mileageRoutesUrls.dictionary.replace(
    ":cargoCompanyUuid",
    params?.cargoCompanyUuid ?? "listing"
  );
};

const getDictionaryRecordUpdateRoute = (
  params: MileageDictionaryRecordUpdateRouteParams
): string => {
  return mileageRoutesUrls.dictionaryRecordUpdate
    .replace(":cargoCompanyUuid", params.cargoCompanyUuid)
    .replace(":recordUuid", params.recordUuid);
};

const getDictionaryRecordAddRoute = (
  params: MileageDictionaryRecordAddRouteParams
): string => {
  return mileageRoutesUrls.dictionaryRecordAdd.replace(
    ":cargoCompanyUuid",
    params.cargoCompanyUuid
  );
};

const mileageRoutesHelper = {
  getAddRoute,
  getUpdateRoute,
  getListingRoute,
  getContractListingRoute,
  getContractAddRoute,
  getSettingsRoute,
  getDictionaryRoute,
  getDictionaryRecordUpdateRoute,
  getDictionaryRecordAddRoute,
  getContractUpdateRoute,
};

export default mileageRoutesHelper;
