import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import OrderDetailsChangeTaxiFormData from "./types/order-details-change-taxi-form-data";
import OrderDetailsChangeTaxiFormValidationResults from "./types/order-details-change-taxi-form-validation-results";

const getDefaultFormData = (): OrderDetailsChangeTaxiFormData => {
  return {
    taxiCorporation: null,
  };
};

const getDefaultFormValidationResults =
  (): OrderDetailsChangeTaxiFormValidationResults => {
    return {
      taxiCorporation: formValidationService.defaultValidationResult,
    };
  };

const orderDetailsChangeTaxiHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default orderDetailsChangeTaxiHelper;
