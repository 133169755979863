import Joi from "joi";
import formValidationService from "../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../common/utils/validation/types/form-validation-result";
import TaxiContractMaintenancePolicy from "../common/types/taxi-contract-maintenance-policy";

const validateCompanyName = (companyName: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(40).required();

  return formValidationService.validate(companyName, validationSchema);
};

const validateCompanyDisplayName = (
  displayName: string
): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(40).required();

  return formValidationService.validate(displayName, validationSchema);
};

const validateTaxId = (taxId: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(10).required();

  return formValidationService.validate(taxId, validationSchema);
};

const validateCompanyId = (companyId: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").min(1).max(14);

  return formValidationService.validate(companyId, validationSchema);
};

const validateNationalCourtRegister = (
  nationalCourtRegister: string
): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(10);

  return formValidationService.validate(
    nationalCourtRegister,
    validationSchema
  );
};

const validateContractMaintenancePolicy = (
  contractMaintenancePolicy: TaxiContractMaintenancePolicy
): FormValidationResult => {
  const validationSchema = Joi.any().not(null).required();

  return formValidationService.validate(
    contractMaintenancePolicy,
    validationSchema
  );
};

const validateAddress = (address: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(100).required();

  return formValidationService.validate(address, validationSchema);
};

const validateNotes = (notes: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").max(255);

  return formValidationService.validate(notes, validationSchema);
};

const taxiAddFormValidationService = {
  validateNationalCourtRegister,
  validateCompanyId,
  validateTaxId,
  validateAddress,
  validateCompanyName,
  validateCompanyDisplayName,
  validateContractMaintenancePolicy,
  validateNotes,
};

export default taxiAddFormValidationService;
