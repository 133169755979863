export enum TaxiUnsettledOrderListingByTaxiRequestOrder {
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
}

type TaxiUnsettledOrderListingByTaxiRequest = {
  order?: TaxiUnsettledOrderListingByTaxiRequestOrder;
  limit: number;
  offset?: number;
  start_time_since?: string;
  start_time_to?: string;
  human_id?: number;
  address?: string;
  start_address?: string;
  mid_address?: string;
  end_address?: string;
  passenger?: string;
  dispatch?: string;
  client?: string;
  external_id?: string;
  taxi?: string;
  driver?: string;
  is_cancelled?: boolean;
};

export default TaxiUnsettledOrderListingByTaxiRequest;
