import { FC, useEffect, useState } from "react";
import {
  OrderRouteEditExternalPassenger,
  OrderRouteEditPassengerType,
} from "../../../types/order-route-edit-passenger";
import ModalComponent, {
  ModalProps,
} from "../../../../../../../common/components/modal/modal.component";
import orderRouteEditExternalPassengerAddHelper from "./common/order-route-edit-external-passenger-add-form.helper";
import OrderRouteEditExternalPassengerAddFormData from "./common/types/order-route-edit-external-passenger-add-form-data";
import uuidService from "../../../../../../../common/utils/uuid/uuid.service";
import ButtonComponent from "../../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../common/components/form/input/input.component";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import OrderRouteEditExternalPassengerAddFormValidationResults from "./common/types/order-route-edit-external-passenger-add-form-validation-results";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import orderRouteEditExternalPassengerAddFormValidationService from "./order-route-edit-external-passenger-add-form-validation.service";

type OrderRouteEditExternalPassengerAddProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  onSubmit: (passenger: OrderRouteEditExternalPassenger) => void;
};

const OrderRouteEditExternalPassengerAddComponent: FC<
  OrderRouteEditExternalPassengerAddProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getEditTranslations().passengers.add;

  const [formData, setFormData] =
    useState<OrderRouteEditExternalPassengerAddFormData>(() =>
      orderRouteEditExternalPassengerAddHelper.getDefaultFormData()
    );

  const [formValidationResults, setFormValidationResults] =
    useState<OrderRouteEditExternalPassengerAddFormValidationResults>(() =>
      orderRouteEditExternalPassengerAddHelper.getDefaultFormValidationResults()
    );

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    setFormValidationResults(
      orderRouteEditExternalPassengerAddHelper.getDefaultFormValidationResults()
    );
    setFormData(orderRouteEditExternalPassengerAddHelper.getDefaultFormData());
  }, [props.isOpen]);

  const setExternalId = (
    value: OrderRouteEditExternalPassengerAddFormData["externalId"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      externalId: value,
    }));
  };

  const setDispatchName = (
    value: OrderRouteEditExternalPassengerAddFormData["dispatchName"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      dispatchName: value,
    }));
  };

  const setFirstName = (
    value: OrderRouteEditExternalPassengerAddFormData["firstName"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      firstName: value,
    }));
  };

  const setLastName = (
    value: OrderRouteEditExternalPassengerAddFormData["lastName"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      lastName: value,
    }));
  };

  const setMobile = (
    value: OrderRouteEditExternalPassengerAddFormData["mobile"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      mobile: value,
    }));
  };

  const validateExternalId = (
    value: OrderRouteEditExternalPassengerAddFormData["externalId"] = formData.externalId
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerAddFormValidationService.validateExternalId(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      externalId: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateFirstName = (
    value: OrderRouteEditExternalPassengerAddFormData["firstName"] = formData.firstName
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerAddFormValidationService.validateFirstName(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateLastName = (
    value: OrderRouteEditExternalPassengerAddFormData["lastName"] = formData.lastName
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerAddFormValidationService.validateLastName(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDispatchName = (
    value: OrderRouteEditExternalPassengerAddFormData["dispatchName"] = formData.dispatchName
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerAddFormValidationService.validateDispatchName(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      dispatchName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateMobile = (
    value: OrderRouteEditExternalPassengerAddFormData["mobile"] = formData.mobile
  ) => {
    const validationResult =
      orderRouteEditExternalPassengerAddFormValidationService.validateMobile(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateForm = () => {
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const isMobileNameValid = validateMobile();
    const isExternalIdValid = validateExternalId();
    const isDispatchNameValid = validateDispatchName();

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isMobileNameValid &&
      isExternalIdValid &&
      isDispatchNameValid
    );
  };

  const onSubmitButtonClick = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    const newPassenger: OrderRouteEditExternalPassenger = {
      type: OrderRouteEditPassengerType.EXTERNAL,
      uuid: uuidService.generate(),
      dispatchName: formData.dispatchName.trim(),
      externalId: formData.externalId.trim(),
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      mobile: formData.mobile.trim(),
    };

    props.onSubmit(newPassenger);
  };

  const onCloseButtonClick = () => {
    props.onClose();
  };

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={{ title: translations.headingText }}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          title={translations.submitButtonTitle}
          idForTesting="order-route-edit-external-passenger-add-submit-button"
        >
          {translations.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={onCloseButtonClick}
          type="brand"
          title={translations.cancelButtonTitle}
          idForTesting="order-route-edit-external-passenger-add-cancel-button"
        >
          {translations.cancelButtonText}
        </ButtonComponent>,
      ]}
    >
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.firstNameLabel}
            errorMessage={formValidationResults.firstName.errorMessage}
            isRequired
          >
            <InputComponent
              value={formData.firstName}
              onChange={setFirstName}
              placeholder={translations.form.firstNamePlaceholder}
              hasError={!!formValidationResults.firstName.errorMessage}
              onBlur={validateFirstName}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.lastNameLabel}
            errorMessage={formValidationResults.lastName.errorMessage}
            isRequired
          >
            <InputComponent
              value={formData.lastName}
              onChange={setLastName}
              placeholder={translations.form.lastNamePlaceholder}
              hasError={!!formValidationResults.lastName.errorMessage}
              onBlur={validateLastName}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.externalIdLabel}
            errorMessage={formValidationResults.externalId.errorMessage}
          >
            <InputComponent
              value={formData.externalId}
              onChange={setExternalId}
              placeholder={translations.form.externalIdPlaceholder}
              hasError={!!formValidationResults.externalId.errorMessage}
              onBlur={validateExternalId}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.dispatchLabel}
            errorMessage={formValidationResults.dispatchName.errorMessage}
          >
            <InputComponent
              value={formData.dispatchName}
              onChange={setDispatchName}
              placeholder={translations.form.dispatchPlaceholder}
              hasError={!!formValidationResults.dispatchName.errorMessage}
              onBlur={validateDispatchName}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.form.mobileLabel}
            errorMessage={formValidationResults.mobile.errorMessage}
            isRequired
          >
            <InputComponent
              value={formData.mobile}
              onChange={setMobile}
              placeholder={translations.form.mobilePlaceholder}
              hasError={!!formValidationResults.mobile.errorMessage}
              onBlur={validateMobile}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </ModalComponent>
  );
};

export default OrderRouteEditExternalPassengerAddComponent;
