import PlannerDriverPlanListRequest from "../../../api/raily/planner/list/planner-driver-plan-list.request";
import {
  PlannerDriverPlanListRequestAddressFilter,
  PlannerDriverPlanListRequestCargoOrderFilter,
  PlannerDriverPlanListRequestDistanceToAddressSorter,
  PlannerDriverPlanListRequestDistanceToPointSorter,
  PlannerDriverPlanListRequestDriverNameFilter,
  PlannerDriverPlanListRequestDriverNameSorter,
  PlannerDriverPlanListRequestFilter,
  PlannerDriverPlanListRequestFilterType,
  PlannerDriverPlanListRequestFinishDateSorter,
  PlannerDriverPlanListRequestMatchType,
  PlannerDriverPlanListRequestSorterOrder,
  PlannerDriverPlanListRequestResultOrder,
  PlannerDriverPlanListRequestSorterType,
  PlannerDriverPlanListRequestStartDateSorter,
} from "../../../api/raily/planner/list/planner-driver-plan-list.request";
import paginationService from "../../../utils/pagination/pagination.service";
import PlannerDriverPlanListLoadParams, {
  PlannerDriverPlanListLoadParamsSorterType,
  PlannerDriverPlanListLoadParamsAddress,
  PlannerDriverPlanListLoadParamsDistanceToAddressAscSorter,
  PlannerDriverPlanListLoadParamsDistanceToAddressDescSorter,
  PlannerDriverPlanListLoadParamsDistanceToPointAscSorter,
  PlannerDriverPlanListLoadParamsDistanceToPointDescSorter,
  PlannerDriverPlanListLoadParamsPlanType,
  PlannerDriverPlanListLoadParamsSorter,
} from "./planner-driver-plan-list-load-params";

const createMatchToItem = (
  planType: PlannerDriverPlanListLoadParamsPlanType
): PlannerDriverPlanListRequestMatchType => {
  switch (planType) {
    case PlannerDriverPlanListLoadParamsPlanType.EXISTING_PLAN:
      return PlannerDriverPlanListRequestMatchType.PLAN_ENTRY;
    case PlannerDriverPlanListLoadParamsPlanType.NEW_PLAN:
      return PlannerDriverPlanListRequestMatchType.DRIVER;
  }
};

const createMatchTo = (
  data: PlannerDriverPlanListLoadParamsPlanType[] | undefined
): PlannerDriverPlanListRequestMatchType[] | undefined => {
  return data?.map(createMatchToItem);
};

const createAddressFilter = (
  params: PlannerDriverPlanListLoadParamsAddress
): PlannerDriverPlanListRequestAddressFilter => {
  return {
    address: params.query,
    timeStart: params.dateFrom ?? null,
    timeEnd: params.dateTo ?? null,
    filterType: PlannerDriverPlanListRequestFilterType.ADDRESS,
  };
};

const createDriverNameFilter = (
  params: PlannerDriverPlanListLoadParams
): PlannerDriverPlanListRequestDriverNameFilter => {
  return {
    filterType: PlannerDriverPlanListRequestFilterType.DRIVER_NAME,
    driverNameQuery: params.driverName!,
  };
};

const createOrderQueryFilter = (
  params: PlannerDriverPlanListLoadParams
): PlannerDriverPlanListRequestCargoOrderFilter => {
  return {
    filterType: PlannerDriverPlanListRequestFilterType.CARGO_ORDER,
    orderIdQuery: params.orderQuery!,
  };
};

const createFilter = (
  params: PlannerDriverPlanListLoadParams
): PlannerDriverPlanListRequestFilter => {
  if (params.address) return createAddressFilter(params.address);
  if (params.driverName) return createDriverNameFilter(params);
  if (params.orderQuery) return createOrderQueryFilter(params);

  return { filterType: PlannerDriverPlanListRequestFilterType.NONE };
};

const createDriverNameSorter = (
  order: PlannerDriverPlanListRequestSorterOrder
): PlannerDriverPlanListRequestDriverNameSorter => {
  return {
    sorterType: PlannerDriverPlanListRequestSorterType.DRIVER_NAME,
    order: order,
  };
};

const createStartDateSorter = (
  order: PlannerDriverPlanListRequestSorterOrder
): PlannerDriverPlanListRequestStartDateSorter => {
  return {
    sorterType: PlannerDriverPlanListRequestSorterType.START_DATE,
    order: order,
  };
};

const createEndDateSorter = (
  order: PlannerDriverPlanListRequestSorterOrder
): PlannerDriverPlanListRequestFinishDateSorter => {
  return {
    sorterType: PlannerDriverPlanListRequestSorterType.END_DATE,
    order: order,
  };
};

const createDistanceToAddressAscSorter = (
  order: PlannerDriverPlanListRequestSorterOrder,
  sort: PlannerDriverPlanListLoadParamsDistanceToAddressAscSorter
): PlannerDriverPlanListRequestDistanceToAddressSorter => {
  return {
    sorterType: PlannerDriverPlanListRequestSorterType.DISTANCE_TO_ADDRESS,
    order: order,
    relativeTo: sort.relativeTo,
    rangeFrom: sort.dateFrom ?? null,
    rangeTo: sort.dateFrom ?? null,
  };
};

const createDistanceToAddressDescSorter = (
  order: PlannerDriverPlanListRequestSorterOrder,
  sort: PlannerDriverPlanListLoadParamsDistanceToAddressDescSorter
): PlannerDriverPlanListRequestDistanceToAddressSorter => {
  return {
    sorterType: PlannerDriverPlanListRequestSorterType.DISTANCE_TO_ADDRESS,
    order: order,
    relativeTo: sort.relativeTo,
    rangeFrom: sort.dateFrom ?? null,
    rangeTo: sort.dateFrom ?? null,
  };
};

const createDistanceToPointAscSorter = (
  order: PlannerDriverPlanListRequestSorterOrder,
  sort: PlannerDriverPlanListLoadParamsDistanceToPointAscSorter
): PlannerDriverPlanListRequestDistanceToPointSorter => {
  return {
    sorterType: PlannerDriverPlanListRequestSorterType.DISTANCE_TO_POINT,
    order: order,
    lat: sort.latitude,
    lon: sort.longitude,
    rangeFrom: sort.dateFrom ?? null,
    rangeTo: sort.dateFrom ?? null,
  };
};

const createDistanceToPointDescSorter = (
  order: PlannerDriverPlanListRequestSorterOrder,
  sort: PlannerDriverPlanListLoadParamsDistanceToPointDescSorter
): PlannerDriverPlanListRequestDistanceToPointSorter => {
  return {
    sorterType: PlannerDriverPlanListRequestSorterType.DISTANCE_TO_POINT,
    order: order,
    lat: sort.latitude,
    lon: sort.longitude,
    rangeFrom: sort.dateFrom ?? null,
    rangeTo: sort.dateFrom ?? null,
  };
};

const createSorter = (
  sort: PlannerDriverPlanListLoadParamsSorter | undefined
): PlannerDriverPlanListRequestResultOrder | undefined => {
  switch (sort?.sorterType) {
    case PlannerDriverPlanListLoadParamsSorterType.DRIVER_NAME_ASC:
      return createDriverNameSorter(
        PlannerDriverPlanListRequestSorterOrder.ASC
      );
    case PlannerDriverPlanListLoadParamsSorterType.DRIVER_NAME_DESC:
      return createDriverNameSorter(
        PlannerDriverPlanListRequestSorterOrder.DESC
      );
    case PlannerDriverPlanListLoadParamsSorterType.START_DATE_ASC:
      return createStartDateSorter(PlannerDriverPlanListRequestSorterOrder.ASC);
    case PlannerDriverPlanListLoadParamsSorterType.START_DATE_DESC:
      return createStartDateSorter(
        PlannerDriverPlanListRequestSorterOrder.DESC
      );
    case PlannerDriverPlanListLoadParamsSorterType.END_DATE_ASC:
      return createEndDateSorter(PlannerDriverPlanListRequestSorterOrder.ASC);
    case PlannerDriverPlanListLoadParamsSorterType.END_DATE_DESC:
      return createEndDateSorter(PlannerDriverPlanListRequestSorterOrder.DESC);
    case PlannerDriverPlanListLoadParamsSorterType.DISTANCE_TO_ADDRESS_ASC:
      return createDistanceToAddressAscSorter(
        PlannerDriverPlanListRequestSorterOrder.ASC,
        sort
      );
    case PlannerDriverPlanListLoadParamsSorterType.DISTANCE_TO_ADDRESS_DESC:
      return createDistanceToAddressDescSorter(
        PlannerDriverPlanListRequestSorterOrder.DESC,
        sort
      );
    case PlannerDriverPlanListLoadParamsSorterType.DISTANCE_TO_POINT_ASC:
      return createDistanceToPointAscSorter(
        PlannerDriverPlanListRequestSorterOrder.ASC,
        sort
      );
    case PlannerDriverPlanListLoadParamsSorterType.DISTANCE_TO_POINT_DESC:
      return createDistanceToPointDescSorter(
        PlannerDriverPlanListRequestSorterOrder.DESC,
        sort
      );
    default:
      return undefined;
  }
};

const create = (
  params: PlannerDriverPlanListLoadParams
): PlannerDriverPlanListRequest => {
  return {
    orderId: params.orderUuid,
    taxiCorporationId: params.taxiCorporationUuid,
    offset:
      params.page && params.pageSize
        ? paginationService.calculateOffset(params.page, params.pageSize)
        : undefined,
    limit: params.pageSize,
    matchTo: createMatchTo(params.planType),
    filter: createFilter(params),
    resultOrder: createSorter(params.sort),
  };
};

const plannerDriverPlanListRequestFactory = {
  create,
};

export default plannerDriverPlanListRequestFactory;
