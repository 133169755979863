import { OrderAbandonedListingResponseDataItem } from "../api/order-abandoned-listing.response";
import OrderAbandonedListingItem from "../types/order-abandoned-listing-item";

const createOrderListingItem = (
  responseDataItem: OrderAbandonedListingResponseDataItem
): OrderAbandonedListingItem => {
  return {
    uuid: responseDataItem.id,
    orderDate: new Date(responseDataItem.start_time),
    routeAddresses: responseDataItem.ride_addresses,
    passengers: responseDataItem.passengers,
    dispatchName: responseDataItem.dispatch,
    producerName: responseDataItem.producer.name,
    cargoCompanyOrderId: responseDataItem.human_id,
    cargoCompanyExternalOrderId: responseDataItem.external_id,
  };
};

const createOrderListingItems = (
  responseDataItems: OrderAbandonedListingResponseDataItem[]
): OrderAbandonedListingItem[] => {
  return responseDataItems.map(createOrderListingItem);
};

const orderAbandonedListingFactory = {
  createOrderListingItems,
};

export default orderAbandonedListingFactory;
