import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import CargoOrderSettlementListingBillingsOrderData from "../types/cargo-order-settlement-listing-billings-accept-data";

const getBillingLabel = (
  billingsAcceptData: CargoOrderSettlementListingBillingsOrderData[]
): string => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .billingsAccept;

  return translations.selectedBillingsTemplateLabel.replace(
    "#{internalOrderIds}",
    billingsAcceptData.length
      ? billingsAcceptData.map((item) => item.internalOrderId).join(", ")
      : translations.billingsNotSelectedLabel
  );
};

const cargoOrderSettlementListingBillingsAcceptHelper = {
  getBillingLabel,
};

export default cargoOrderSettlementListingBillingsAcceptHelper;
