import dealerApiService from "../../api/raily/dealer/dealer-api.service";
import DealerListRequest from "../../api/raily/dealer/list/dealer-list.request";
import DealerAddError from "./add/dealer-add-error";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import DealerList from "./list/dealer-list";
import DealerListLoadParams from "./list/dealer-list-load-params";
import dealerListRequestFactory from "./list/dealer-list-request.factory";
import dealerListFactory from "./list/dealer-list.factory";
import DealerAddParams from "./add/dealer-add-params";
import DealerAddRequest from "../../api/raily/dealer/add/dealer-add.request";
import dealerAddRequestFactory from "./add/dealer-add-request.factory";

const handleListError = (error: HttpError) => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: DealerListLoadParams,
  abortSignal: AbortSignal
): Promise<DealerList> => {
  const request: DealerListRequest = dealerListRequestFactory.create(params);

  try {
    const response = await dealerApiService().getList(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const dealerList = dealerListFactory.create(response);

    return dealerList;
  } catch (_error) {
    const error = _error as HttpError;

    throw handleListError(error);
  }
};

const handleAddError = (error: HttpError | DealerAddError): DealerAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: DealerAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DealerAddRequest = dealerAddRequestFactory.create(params);

  try {
    const response = await dealerApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DealerAddError;

    throw handleAddError(error);
  }
};

const dealerService = {
  getList,
  add,
};

export default dealerService;
