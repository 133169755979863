import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import { UserOperatorListingRouteQueryParams } from "../../../common/routes/types/user-operator-listing-route-params";
import OperatorListingFilter from "../common/types/operator-listing-filter";
import OperatorListingSortKey from "../common/types/operator-listing-sort-key";
import operatorListingFiltersService from "./operator-listing-filters.service";

const useOperatorListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<UserOperatorListingRouteQueryParams>();

  const getFilters = (): OperatorListingFilter[] => {
    return operatorListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return operatorListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return operatorListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): OperatorListingSortKey | undefined => {
    return operatorListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: OperatorListingFilter[],
    sortKey: OperatorListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      operatorListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useOperatorListingFiltersDao;
