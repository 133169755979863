import HttpResponse from "../../../utils/http/http.response";
import railyApiService from "../raily-api.service";
import CheckUserExistsRequest from "./check-user-exists/check-user-exists.request";
import CheckUserExistsResponse from "./check-user-exists/check-user-exists.response";
import UserDeleteRequest from "./delete/user-delete.request";
import UserRestoreRequest from "./restore/user-restore.request";
import UserRestoreResponse from "./restore/user-restore.response";

const userApiService = () => {
  const checkUserExists = (
    request: CheckUserExistsRequest,
    abortSignal: AbortSignal
  ): Promise<CheckUserExistsResponse> => {
    const url = `/users/exists/${request.username}`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  const deleteUser = (
    request: UserDeleteRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/users/${request.userUuid}`;

    return railyApiService().delete({
      url,
      abortSignal,
    });
  };

  const restore = (
    request: UserRestoreRequest,
    abortSignal: AbortSignal
  ): Promise<UserRestoreResponse> => {
    const url = `/users/${request.userUuid}/restore`;

    return railyApiService().put<UserRestoreResponse>({
      url,
      abortSignal,
    });
  };

  return {
    checkUserExists,
    delete: deleteUser,
    restore,
  };
};

export default userApiService;
