import { TaxiListingRouteQueryParams } from "../../../user/common/routes/types/taxi-listing-route-params";
import TaxiListingFilter, {
  TaxiListingAddressFilter,
  TaxiListingCompanyIdFilter,
  TaxiListingDisplayNameFilter,
  TaxiListingNameFilter,
  TaxiListingNationalCourtRegisterFilter,
  TaxiListingNotesFilter,
  TaxiListingTaxNumberFilter,
} from "../common/types/taxi-listing-filter";
import TaxiListingFilterType from "../common/types/taxi-listing-filter-type";
import TaxiListingSortKey from "../common/types/taxi-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: TaxiListingRouteQueryParams
): TaxiListingFilter[] => {
  const filters: TaxiListingFilter[] = [];

  if (routeQueryFilterParams.name) {
    const nameFilter: TaxiListingNameFilter = {
      type: TaxiListingFilterType.NAME,
      value: routeQueryFilterParams.name,
    };

    filters.push(nameFilter);
  }

  if (routeQueryFilterParams.address) {
    const address: TaxiListingAddressFilter = {
      type: TaxiListingFilterType.ADDRESS,
      value: routeQueryFilterParams.address,
    };

    filters.push(address);
  }

  if (routeQueryFilterParams.companyId) {
    const companyIdFilter: TaxiListingCompanyIdFilter = {
      type: TaxiListingFilterType.COMPANY_ID,
      value: routeQueryFilterParams.companyId,
    };

    filters.push(companyIdFilter);
  }

  if (routeQueryFilterParams.displayName) {
    const displayNameFilter: TaxiListingDisplayNameFilter = {
      type: TaxiListingFilterType.DISPLAY_NAME,
      value: routeQueryFilterParams.displayName,
    };

    filters.push(displayNameFilter);
  }

  if (routeQueryFilterParams.nationalCourtRegister) {
    const nationalCourtRegisterFilter: TaxiListingNationalCourtRegisterFilter =
      {
        type: TaxiListingFilterType.NATIONAL_COURT_REGISTER,
        value: routeQueryFilterParams.nationalCourtRegister,
      };

    filters.push(nationalCourtRegisterFilter);
  }

  if (routeQueryFilterParams.notes) {
    const notesFilter: TaxiListingNotesFilter = {
      type: TaxiListingFilterType.NOTES,
      value: routeQueryFilterParams.notes,
    };

    filters.push(notesFilter);
  }

  if (routeQueryFilterParams.taxNumber) {
    const taxNumberFilter: TaxiListingTaxNumberFilter = {
      type: TaxiListingFilterType.TAX_NUMBER,
      value: routeQueryFilterParams.taxNumber,
    };

    filters.push(taxNumberFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: TaxiListingRouteQueryParams
): TaxiListingSortKey | undefined => {
  return routeQueryParams.sort as TaxiListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: TaxiListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: TaxiListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: TaxiListingFilter[],
  sortKey: TaxiListingSortKey | null,
  page: number,
  pageSize: number
): TaxiListingRouteQueryParams => {
  const routeQueryParams: TaxiListingRouteQueryParams = {
    name: filters.find((filter) => filter.type === TaxiListingFilterType.NAME)
      ?.value as TaxiListingNameFilter["value"],
    address: filters.find(
      (filter) => filter.type === TaxiListingFilterType.ADDRESS
    )?.value as TaxiListingAddressFilter["value"],
    companyId: filters.find(
      (filter) => filter.type === TaxiListingFilterType.COMPANY_ID
    )?.value as TaxiListingCompanyIdFilter["value"],
    displayName: filters.find(
      (filter) => filter.type === TaxiListingFilterType.DISPLAY_NAME
    )?.value as TaxiListingDisplayNameFilter["value"],
    nationalCourtRegister: filters.find(
      (filter) => filter.type === TaxiListingFilterType.NATIONAL_COURT_REGISTER
    )?.value as TaxiListingNationalCourtRegisterFilter["value"],
    notes: filters.find((filter) => filter.type === TaxiListingFilterType.NOTES)
      ?.value as TaxiListingNotesFilter["value"],
    taxNumber: filters.find(
      (filter) => filter.type === TaxiListingFilterType.TAX_NUMBER
    )?.value as TaxiListingTaxNumberFilter["value"],
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const taxiListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiListingFiltersService;
