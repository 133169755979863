import authApiService from "../../api/raily/auth/auth-api.service";
import SignInRequest from "../../api/raily/auth/sign-in/sign-in.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import LogoutError from "./logout/logout-error";
import RefreshTokenError from "./refresh-token/refresh-token-error";
import RefreshTokenResult from "./refresh-token/refresh-token-result";
import refreshTokenResultFactory from "./refresh-token/refresh-token-result.factory";
import SignInError from "./sign-in/sign-in-error";
import SignInParams from "./sign-in/sign-in-params";
import signInRequestFactory from "./sign-in/sign-in-request.factory";
import SignInResult from "./sign-in/sign-in-result";
import signInResultFactory from "./sign-in/sign-in-result.factory";

const handleSignInError = (error: HttpError | SignInError): SignInError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const signIn = async (
  params: SignInParams,
  abortSignal: AbortSignal
): Promise<SignInResult> => {
  const request: SignInRequest = signInRequestFactory.create(params);

  try {
    const response = await authApiService().signIn(request, abortSignal);

    if (response.status === 401) {
      throw dataLoadErrorFactory.createAuthError();
    }

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return signInResultFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | SignInError;

    throw handleSignInError(error);
  }
};

const handleRefreshTokenError = (
  error: HttpError | RefreshTokenError
): RefreshTokenError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const refreshToken = async (
  abortSignal: AbortSignal
): Promise<RefreshTokenResult> => {
  try {
    const response = await authApiService().refreshToken(abortSignal);

    if (response.status === 401) {
      throw dataLoadErrorFactory.createAuthError();
    }

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return refreshTokenResultFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | RefreshTokenError;

    throw handleRefreshTokenError(error);
  }
};

const handleLogoutError = (error: HttpError | LogoutError): LogoutError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const logout = async (abortSignal: AbortSignal): Promise<void> => {
  try {
    const response = await authApiService().signOut(abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | LogoutError;

    throw handleLogoutError(error);
  }
};

const authService = { signIn, refreshToken, logout };

export default authService;
