import {
  UserDeleteDriverHasActivePlanError,
  UserDeleteDriverHasCandidatureError,
  UserDeleteErrorType,
} from "./user-delete-error";
import userDeleteHelper from "../../../../modules/user/common/delete/common/types/user-delete.helper";
import UserDeleteUserData from "../../../../modules/user/common/delete/common/types/user-delete-user-data";

const createCandidatureCollisionError = (
  user: UserDeleteUserData
): UserDeleteDriverHasCandidatureError => {
  return {
    message:
      userDeleteHelper.getDriverHasCandidatureFailureNotificationLabel(user),
    params: {},
    type: UserDeleteErrorType.DRIVER_HAS_CANDIDATURE,
  };
};

const createActivePlanCollisionError = (
  user: UserDeleteUserData
): UserDeleteDriverHasActivePlanError => {
  return {
    message:
      userDeleteHelper.getDriverHasActivePlanFailureNotificationLabel(user),
    params: {},
    type: UserDeleteErrorType.DRIVER_HAS_ACTIVE_PLAN,
  };
};

const userDeleteErrorFactory = {
  createCandidatureCollisionError,
  createActivePlanCollisionError,
};

export default userDeleteErrorFactory;
