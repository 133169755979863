import MileageSettingsUpdateRequest, {
  MileageSettingsUpdateRequestMileageNumberCreationModel,
} from "../../../api/raily/mileage-settings/update/mileage-settings-update.request";
import MileageSettingsUpdateParams, {
  MileageSettingsUpdateParamsMileageNumberCreationModel,
} from "./mileage-settings-update-params";

const createCreationModel = (
  model: MileageSettingsUpdateParamsMileageNumberCreationModel | undefined
): MileageSettingsUpdateRequestMileageNumberCreationModel | undefined => {
  switch (model) {
    case MileageSettingsUpdateParamsMileageNumberCreationModel.AUTO:
      return MileageSettingsUpdateRequestMileageNumberCreationModel.AUTO;
    case MileageSettingsUpdateParamsMileageNumberCreationModel.DICT:
      return MileageSettingsUpdateRequestMileageNumberCreationModel.DICT;
    case MileageSettingsUpdateParamsMileageNumberCreationModel.MANUAL:
      return MileageSettingsUpdateRequestMileageNumberCreationModel.MANUAL;
    default:
      return undefined;
  }
};

const create = (
  params: MileageSettingsUpdateParams
): MileageSettingsUpdateRequest => {
  return {
    cargoCompanyUuid: params.cargoCompanyUuid,
    isCardNumberRequired: params.isCardNumberRequired,
    isMileageNumberRequired: params.isMileageNumberRequired,
    mileageNumberCreationType: createCreationModel(
      params.mileageNumberCreationModel
    ),
  };
};

const mileageSettingsUpdateRequestFactory = {
  create,
};

export default mileageSettingsUpdateRequestFactory;
