import { FC, useMemo } from "react";
import { useAppContext } from "../../../context/app.context";
import appLanguageHelper from "../../../languages/app-language.helper";
import appHeaderLanguageFactory from "./app-header-language.factory";
import AppHeaderLanguageSelectOption from "./types/app-header-language-select-option";
import SingleSelectComponent from "../../../common/components/form/select/single-select/single-select.component";

const AppHeaderLanguageComponent: FC = () => {
  const { selectedAppLanguage, setAppLanguage } = useAppContext();

  const languageOptions = useMemo(
    () => appLanguageHelper.getLanguageOptions(),
    []
  );

  const selectOptions = useMemo(
    () => appHeaderLanguageFactory.createSelectOptions(languageOptions),
    [languageOptions]
  );

  const onChange = (selectOption: AppHeaderLanguageSelectOption) => {
    setAppLanguage(selectOption.value);
  };

  const selectedOption: AppHeaderLanguageSelectOption | null =
    selectOptions.find((option) => option.value === selectedAppLanguage) ??
    null;

  return (
    <SingleSelectComponent
      onChange={(option) => onChange(option!)}
      options={selectOptions}
      value={selectedOption}
    />
  );
};

export default AppHeaderLanguageComponent;
