import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-sort-definition";
import routeTranslationsHelper from "../../../../languages/route-translations.helper";
import RouteFinishedListingFilter, {
  RouteFinishedListingCargoOrderHumanIdFilter,
  RouteFinishedListingDriverFilter,
  RouteFinishedListingPassengerFilter,
  RouteFinishedListingRouteAddressFilter,
  RouteFinishedListingHumanIdFilter,
  RouteFinishedListingTaxiCorporationFilter,
} from "../common/types/route-finished-listing-filter";
import RouteFinishedListingFilterType from "../common/types/route-finished-listing-filter-type";
import RouteFinishedListingSortKey from "../common/types/route-finished-listing-sort-key";

const routeFinishedListingFilterHelper = () => {
  const translations = routeTranslationsHelper.getFinishedListingTranslations();

  const getSelectDefinitionForCargoOrderHumanId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label:
            translations.filters.search.cargoOrderHumanIdTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDefinitionForDriver = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.driverTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForPassenger = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.passengerTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForRouteAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.routeAddressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForHumanId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label: translations.filters.search.humanIdTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDefinitionForTaxiCorporation = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.taxiCorporationTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: RouteFinishedListingFilterType,
    query: string
  ) => {
    const options: Record<
      RouteFinishedListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [RouteFinishedListingFilterType.CARGO_ORDER_HUMAN_ID]:
        getSelectDefinitionForCargoOrderHumanId,
      [RouteFinishedListingFilterType.DRIVER]: getSelectDefinitionForDriver,
      [RouteFinishedListingFilterType.PASSENGER]:
        getSelectDefinitionForPassenger,
      [RouteFinishedListingFilterType.ROUTE_ADDRESS]:
        getSelectDefinitionForRouteAddress,
      [RouteFinishedListingFilterType.HUMAN_ID]: getSelectDefinitionForHumanId,
      [RouteFinishedListingFilterType.TAXI_CORPORATION]:
        getSelectDefinitionForTaxiCorporation,
    };

    const result = options[filterType](query);

    return result;
  };

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForCargoOrderHumanId = (
    filterValue: RouteFinishedListingCargoOrderHumanIdFilter["value"]
  ) => {
    return createBadgeData(
      String(filterValue),
      translations.filters.cargoOrderHumanIdLabel,
      translations.filters.cargoOrderHumanIdTitle
    );
  };

  const getBadgeForDriver = (
    filterValue: RouteFinishedListingDriverFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.driverLabel,
      translations.filters.driverTitle
    );
  };

  const getBadgeForPassenger = (
    filterValue: RouteFinishedListingPassengerFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.passengerLabel,
      translations.filters.passengerTitle
    );
  };

  const getBadgeForRouteAddress = (
    filterValue: RouteFinishedListingRouteAddressFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.routeAddressLabel,
      translations.filters.routeAddressTitle
    );
  };

  const getBadgeForHumanId = (
    filterValue: RouteFinishedListingHumanIdFilter["value"]
  ) => {
    return createBadgeData(
      String(filterValue),
      translations.filters.humanIdLabel,
      translations.filters.humanIdTitle
    );
  };

  const getBadgeForTaxiCorporation = (
    filterValue: RouteFinishedListingTaxiCorporationFilter["value"]
  ) => {
    return createBadgeData(
      filterValue,
      translations.filters.taxiCorporationLabel,
      translations.filters.taxiCorporationTitle
    );
  };

  const getBadgeDefinitionByType = (
    filterType: RouteFinishedListingFilterType,
    filterValue: RouteFinishedListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      RouteFinishedListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [RouteFinishedListingFilterType.CARGO_ORDER_HUMAN_ID]: () =>
        getBadgeForCargoOrderHumanId(
          filterValue as RouteFinishedListingCargoOrderHumanIdFilter["value"]
        ),
      [RouteFinishedListingFilterType.DRIVER]: () =>
        getBadgeForDriver(
          filterValue as RouteFinishedListingDriverFilter["value"]
        ),
      [RouteFinishedListingFilterType.PASSENGER]: () =>
        getBadgeForPassenger(
          filterValue as RouteFinishedListingPassengerFilter["value"]
        ),
      [RouteFinishedListingFilterType.ROUTE_ADDRESS]: () =>
        getBadgeForRouteAddress(
          filterValue as RouteFinishedListingRouteAddressFilter["value"]
        ),
      [RouteFinishedListingFilterType.HUMAN_ID]: () =>
        getBadgeForHumanId(
          filterValue as RouteFinishedListingHumanIdFilter["value"]
        ),
      [RouteFinishedListingFilterType.TAXI_CORPORATION]: () =>
        getBadgeForTaxiCorporation(
          filterValue as RouteFinishedListingTaxiCorporationFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: RouteFinishedListingFilterType
  ): ListingFilterDefinitionOption<RouteFinishedListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<RouteFinishedListingFilter>[] => {
      return Object.values(RouteFinishedListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition = (
    hasAccessToTaxiCorporation: boolean
  ): ListingFilterDefinition<RouteFinishedListingFilter> => {
    return {
      options: getFilterDefinitionOptions().filter((option) =>
        !hasAccessToTaxiCorporation
          ? option.filterType !==
            RouteFinishedListingFilterType.TAXI_CORPORATION
          : true
      ),
    };
  };

  const getSortDefinitionOptions = (
    hasAccessToTaxiCorporation: boolean
  ): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.driverAscLabel,
        value: RouteFinishedListingSortKey.DRIVER_NAME_ASC,
      },
      {
        label: translations.sort.driverDescLabel,
        value: RouteFinishedListingSortKey.DRIVER_NAME_DESC,
      },
      {
        label: translations.sort.humanIdAscLabel,
        value: RouteFinishedListingSortKey.HUMAN_ID_ASC,
      },
      {
        label: translations.sort.humanIdDescLabel,
        value: RouteFinishedListingSortKey.HUMAN_ID_DESC,
      },
      ...(hasAccessToTaxiCorporation
        ? [
            {
              label: translations.sort.taxiCorporationAscLabel,
              value: RouteFinishedListingSortKey.TAXI_CORPORATION_ASC,
            },
            {
              label: translations.sort.taxiCorporationDescLabel,
              value: RouteFinishedListingSortKey.TAXI_CORPORATION_DESC,
            },
          ]
        : []),
    ];
  };

  const getSortDefinition = (
    hasAccessToTaxiCorporation: boolean
  ): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(hasAccessToTaxiCorporation),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default routeFinishedListingFilterHelper;
