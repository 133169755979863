import CargoCompanyDispatchListRequest, {
  CargoCompanyDispatchListRequestQueryParams,
} from "./cargo-company-dispatch-list.request";

const createQueryParams = (
  data: CargoCompanyDispatchListRequest
): CargoCompanyDispatchListRequestQueryParams => {
  return {
    offset: data.offset,
    limit: data.limit,
  };
};

const cargoCompanyDispatchListRequestFactory = {
  createQueryParams,
};

export default cargoCompanyDispatchListRequestFactory;
