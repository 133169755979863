import appAsideNavigationHelper from "../../common/app-aside-navigation.helper";
import { AppAsideNavigationDefinitionGroup } from "../../common/types/app-aside-navigation-definition";

const checkIsSomeNestedNavigationItemActive = (
  navigationDefinitionGroup: AppAsideNavigationDefinitionGroup,
  pathname: string
): boolean => {
  return navigationDefinitionGroup.items.some((item) => {
    const isLink = appAsideNavigationHelper.checkIsDefinitionLink(item);

    if (isLink) {
      return item.path === pathname;
    }

    return checkIsSomeNestedNavigationItemActive(item, pathname);
  });
};

const appAsideMinifiedNavigationHelper = {
  checkIsSomeNestedNavigationItemActive,
};

export default appAsideMinifiedNavigationHelper;
