import { TaxiOfficerAddRequest } from "../../../api/raily/taxi-officer/add/taxi-officer-add.request";
import TaxiOfficerAddParams from "./taxi-officer-add-params";

const create = (params: TaxiOfficerAddParams): TaxiOfficerAddRequest => {
  return {
    taxiCorporationId: params.taxiCorporationUuid,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.phoneNumber.number,
    mobilePrimaryPrefix: params.phoneNumber.dialingCode,
    mobileSecondary: params.alternativePhoneNumber.number || null,
    mobileSecondaryPrefix: params.alternativePhoneNumber.dialingCode || null,
    password: params.password,
    username: params.username,
  };
};

const taxiOfficerAddRequestFactory = {
  create,
};

export default taxiOfficerAddRequestFactory;
