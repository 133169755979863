import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import orderActiveListingHelper from "../../common/order-active-listing.helper";

type OrderActiveListingTableDriverMobileAppUserProps = {
  isMobileAppUser: boolean;
};

const OrderActiveListingTableDriverMobileAppUserComponent: FC<
  OrderActiveListingTableDriverMobileAppUserProps
> = (props) => {
  const UsingIcon = (
    <FontAwesomeIcon
      icon={faMobileAlt}
      className="order_active_listing_table_driver_mobile_app_user__icon is_user"
      title={orderActiveListingHelper.getMobileAppUsingLabel(true)}
    />
  );

  const NotUsingIcon = (
    <FontAwesomeIcon
      icon={faMobileAlt}
      className="order_active_listing_table_driver_mobile_app_user__icon is_not_user"
      title={orderActiveListingHelper.getMobileAppUsingLabel(false)}
    />
  );

  return (
    <div className="order_active_listing_table_driver_mobile_app_user">
      {props.isMobileAppUser ? UsingIcon : NotUsingIcon}
    </div>
  );
};

export default OrderActiveListingTableDriverMobileAppUserComponent;
