import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import BooleanSelectComponent from "../../../../common/components/form/select/boolean/boolean-select.component";
import useForm from "../../../../common/components/form/use-form";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import useAbort from "../../../../common/hooks/use-abort";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import useMileageContractDetails from "../../../../common/services/mileage-contract/details/use-mileage-contract-details";
import mileageContractService from "../../../../common/services/mileage-contract/mileage-contract.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import mileageBreadcrumbsHelper from "../../common/breadcrumbs/mileage-breadcrumbs.helper";
import mileageRoutesHelper from "../../common/routes/mileage-routes.helper";
import mileageContractUpdateParamsFactory from "./factory/mileage-contract-update-params.factory";
import MileageContractUpdateFormData from "./form/types/mileage-contract-update-form-data";
import MileageContractUpdateRouteParams from "../../common/routes/types/mileage-contract-update-route-params";
import mileageContractUpdateFormHelper from "./form/mileage-contract-update-form.helper";
import mileageContractUpdateFormFactory from "./factory/mileage-contract-update-form.factory";

const MileageContractUpdateComponent: FC = () => {
  const navigate = useNavigate();

  const { contractUuid } = useParams<MileageContractUpdateRouteParams>();
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageContractUpdate);

  const mileageContractDetails = useMileageContractDetails();
  const mileageContractDetailsAbort = useAbort();

  const [isContractUpdatePending, setIsContractUpdatePending] = useState(false);
  const mileageContractUpdateAbort = useAbort();

  const companyName = useMemo(() => {
    return mileageContractDetails.data?.companyName;
  }, [mileageContractDetails.data]);

  useEffect(() => {
    const breadcrumbs =
      mileageBreadcrumbsHelper.getMileageContractUpdateBreadcrumbs({
        contractUuid: contractUuid!,
        companyName: companyName ?? "",
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, companyName]);

  const translations =
    mileageTranslationsHelper.getMileageContractUpdateTranslations();

  const form = useForm<MileageContractUpdateFormData>({
    emptyValues: mileageContractUpdateFormHelper.getEmptyFormData(),
    validationDefinition:
      mileageContractUpdateFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    if (!contractUuid) {
      return;
    }

    mileageContractDetails.load(
      {
        contractUuid,
      },
      mileageContractDetailsAbort.signal
    );
  }, [contractUuid]);

  useEffect(() => {
    if (!mileageContractDetails.data) {
      return;
    }

    form.setValues(
      mileageContractUpdateFormFactory.create(mileageContractDetails.data)
    );
  }, [mileageContractDetails.data]);

  const onContractUpdateSuccess = () => {
    notificationService.success(translations.successUpdateNotificationLabel);

    navigate(mileageRoutesHelper.getContractListingRoute());
  };

  const onContractUpdateFailure = () => {
    notificationService.error(translations.failureUpdateNotificationLabel);
  };

  const updateContract = async () => {
    if (!contractUuid) {
      return;
    }

    setIsContractUpdatePending(true);

    const loadParams = mileageContractUpdateParamsFactory.create(
      form.values,
      contractUuid
    );

    try {
      await mileageContractService.update(
        loadParams,
        mileageContractUpdateAbort.signal
      );

      onContractUpdateSuccess();
    } catch {
      onContractUpdateFailure();
    } finally {
      setIsContractUpdatePending(false);
    }
  };

  const onSubmitButtonClick = () => {
    updateContract();
  };

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={4}>
                <FormFieldComponent label={translations.form.cargoCompanyLabel}>
                  {mileageContractDetails.data?.companyName}
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.periodOfValidityLabel}
                >
                  {mileageContractUpdateFormHelper.getPeriodOfValidityLabel(
                    mileageContractDetails.data?.validSince,
                    mileageContractDetails.data?.validTo
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateCompanyVehicleLabel}
                >
                  {mileageContractDetails.data?.distanceRateCompanyVehicle}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateCarGt900cm3Label}
                >
                  {mileageContractDetails.data?.distanceRateCarGt900cm3}
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateCarLe900cm3Label}
                >
                  {mileageContractDetails.data?.distanceRateCarLe900cm3}
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateMotorcycleLabel}
                >
                  {mileageContractDetails.data?.distanceRateMotorcycle}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateMopedLabel}
                >
                  {mileageContractDetails.data?.distanceRateMoped}
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent label={translations.form.dietFullRateLabel}>
                  {mileageContractDetails.data?.dietFullRate}
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent label={translations.form.dietHalfRateLabel}>
                  {mileageContractDetails.data?.dietHalfRate}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.dietFullAfterHoursLabel}
                >
                  {mileageContractDetails.data?.dietFullAfterHours}
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.dietHalfAfterHoursLabel}
                >
                  {mileageContractDetails.data?.dietHalfAfterHours}
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.isActiveLabel}
                  isRequired
                  errorMessage={form.validationResults.isActive.errorMessage}
                >
                  <BooleanSelectComponent
                    placeholder={translations.form.isActivePlaceholder}
                    value={form.values.isActive}
                    onChange={(value) => form.setValue("isActive", value)}
                    isError={!!form.validationResults.isActive.errorMessage}
                    onBlur={() => form.validate("isActive")}
                    idForTesting="mileage-contracts-update-is-active"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
          <ButtonComponent
            onClick={onSubmitButtonClick}
            type="primary"
            isLoading={isContractUpdatePending}
            idForTesting="mileage-contracts-update-submit-button"
            classNames={{ root: "mt-4" }}
            title={translations.form.submitButtonTitle}
          >
            {translations.form.submitButtonLabel}
          </ButtonComponent>
        </Column>
      </Row>
    </>
  );
};

export default MileageContractUpdateComponent;
