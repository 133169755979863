import appTranslationsHelper from "../../../../languages/app-translations.helper";
import MessengerPostResponse, {
  MessengerPostResponsePost,
  MessengerPostResponsePostType,
} from "../../../api/raily/messenger/post/messenger-post.response";
import { MessengerPost, MessengerPostType } from "./messenger-post";

const createPostType = (
  responsePostType: MessengerPostResponsePostType
): MessengerPostType => {
  switch (responsePostType) {
    case MessengerPostResponsePostType.ACCEPTED:
      return MessengerPostType.ACCEPTED;
    case MessengerPostResponsePostType.REJECTED:
      return MessengerPostType.REJECTED;
    case MessengerPostResponsePostType.REOPEN_REQUEST:
      return MessengerPostType.REOPEN_REQUEST;
    case MessengerPostResponsePostType.REOPENED:
      return MessengerPostType.REOPENED;
    case MessengerPostResponsePostType.BILLED:
      return MessengerPostType.BILLED;
    case MessengerPostResponsePostType.UPDATED:
      return MessengerPostType.UPDATED;
    case MessengerPostResponsePostType.USER_ENTRY:
      return MessengerPostType.USER_ENTRY;
  }
};

const createPostContent = (
  content: string,
  postType: MessengerPostType,
  distance: number,
  amount: number
): string => {
  const translations =
    appTranslationsHelper.getComponentTranslations().messenger;

  const distanceString = distance.toFixed();
  const amountString = amount.toFixed(2);

  switch (postType) {
    case MessengerPostType.BILLED:
      return translations.postBilledContentTemplate
        .replace("#{distance}", distanceString)
        .replace("#{amount}", amountString);
    case MessengerPostType.UPDATED:
      return translations.postUpdatedContentTemplate
        .replace("#{distance}", distanceString)
        .replace("#{amount}", amountString);
    default:
      return content;
  }
};

const createItem = (responseItem: MessengerPostResponsePost): MessengerPost => {
  const postType = createPostType(responseItem.postType);

  return {
    authorUuid: responseItem.id,
    authorFullName: `${responseItem.authorFirstName} ${responseItem.authorLastName}`,
    authorCompany: responseItem.authorCompany,
    content: createPostContent(
      responseItem.content,
      postType,
      responseItem.distance ?? 0,
      responseItem.amount ?? 0
    ),
    date: new Date(responseItem.createdAt),
    postType: postType,
  };
};

const create = (response: MessengerPostResponse) => {
  return response.data.map((item) => createItem(item));
};

const messengerPostFactory = {
  create,
};

export default messengerPostFactory;
