import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../../common/components/table/table.component";
import DriverContractListingByTaxiListingItem from "../common/types/driver-contract-listing-by-taxi-listing-item";
import DriverContractListingByTaxiTableColumn from "../common/types/driver-contract-listing-by-taxi-table-column";
import DriverContractListingByTaxiTableRow from "../common/types/driver-contract-listing-by-taxi-table-row";
import driverContractListingByTaxiTableHelper from "./driver-contract-listing-by-taxi-table.helper";
import { useAppContext } from "../../../../../../../context/app.context";

type DriverContractListingByTaxiTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: DriverContractListingByTaxiListingItem[];
  driverUuid: string;
};

const DriverContractListingByTaxiTableComponent: FC<
  DriverContractListingByTaxiTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns: DriverContractListingByTaxiTableColumn[] = useMemo(() => {
    return driverContractListingByTaxiTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const rows: DriverContractListingByTaxiTableRow[] = useMemo(() => {
    return driverContractListingByTaxiTableHelper.getRows(
      props.listingItems,
      props.driverUuid
    );
  }, [props.listingItems, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default DriverContractListingByTaxiTableComponent;
