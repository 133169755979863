const settlementRoutesUrls = {
  cargoListingOfOrderSettlements: `/settlements/cargo/order-settlements-listing`,
  railyListingOfUnsettledOrders: `/settlements/raily/unsettled-orders-listing`,
  railyListingOfSettledOrders: `/settlements/raily/settled-orders-listing`,
  taxiListingOfSettledOrders: `/settlements/taxi/settled-orders-listing`,
  taxiListingOfSettledRoutes: `/settlements/taxi/settled-routes-listing`,
  taxiListingOfUnsettledOrders: `/settlements/taxi/unsettled-orders-listing`,
  taxiListingOfUnsettledPrivateOrders: `/settlements/taxi/unsettled-private-orders-listing`,
  taxiListingOfUnsettledRoutes: `/settlements/taxi/unsettled-routes-listing`,
  taxiListingOfUnsettledTransferedOrders: `/settlements/taxi/unsettled-transfered-orders-listing`,
  taxiListingOfSettledTransferedOrders: `/settlements/taxi/settled-transfered-orders-listing`,
};

export default settlementRoutesUrls;
