import Joi from "joi";
import formValidationService from "../../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";

const validateStreet = (street: string): FormValidationResult => {
  // const pattern = /^(?!.*(?:\.{2,}|-{2,}))[a-zA-Z\u0080-\u024F\s.-]+$/u;
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(street, validationSchema);
};

const validateHouseNumber = (houseNumber: string): FormValidationResult => {
  // const pattern = /^\d+[a-zA-Z]?$/;
  const validationSchema = Joi.string().trim().max(10).required();

  return formValidationService.validate(houseNumber, validationSchema);
};

const validateApartmentNumber = (
  apartmentNumber: string
): FormValidationResult => {
  const validationSchema = Joi.string().allow("").trim().max(10).required();

  return formValidationService.validate(apartmentNumber, validationSchema);
};

const validateTown = (town: string): FormValidationResult => {
  // const pattern = /^(?!.*(?:\.{2,}|-{2,}))[a-zA-Z\u0080-\u024F\s.-]+$/u;
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(town, validationSchema);
};

const validateCountry = (country: string): FormValidationResult => {
  // const pattern = /^(?!.*(?:\.{2,}|-{2,}))[a-zA-Z\u0080-\u024F\s.-]+$/u;
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(country, validationSchema);
};

const validateZipCode = (zipCode: string): FormValidationResult => {
  const validationSchema = Joi.string().trim().min(2).max(10).required();

  return formValidationService.validate(zipCode, validationSchema);
};

const validateDescription = (description: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").trim().max(100).required();

  return formValidationService.validate(description, validationSchema);
};

const passengerAddressAddFormValidationService = {
  validateStreet,
  validateHouseNumber,
  validateApartmentNumber,
  validateTown,
  validateCountry,
  validateZipCode,
  validateDescription,
};

export default passengerAddressAddFormValidationService;
