import DriverListRequest, {
  DriverListRequestQueryParams,
} from "./driver-list.request";

const createQueryParams = (
  data: DriverListRequest
): DriverListRequestQueryParams => {
  return {
    firstName: data.firstName,
    fleetPartner: data.fleetPartner,
    fleetPartnerId: data.fleetPartnerId,
    lastName: data.lastName,
    limit: data.limit,
    offset: data.offset,
    order: data.order,
    statusCriteria: data.statusCriteria,
    taxiCorporation: data.taxiCorporation,
    taxiCorporationId: data.taxiCorporationId,
    address: data.address,
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
    email: data.email,
    pendingCandidature: data.pendingCandidature,
    username: data.username,
  };
};

const driverListRequestFactory = {
  createQueryParams,
};

export default driverListRequestFactory;
