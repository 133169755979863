import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-sort-definition";
import { CargoOrderFinishedListItemPublicStatus } from "../../../../common/services/cargo-order/finished-list/cargo-order-finished-list";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderFinishedListingFilter, {
  OrderFinishedListingCargoCompanyExternalOrderIdFilter,
  OrderFinishedListingCargoCompanyInternalOrderIdFilter,
  OrderFinishedListingClientFilter,
  OrderFinishedListingDispatchFilter,
  OrderFinishedListingDriverFilter,
  OrderFinishedListingOrderStartDateFilter,
  OrderFinishedListingPassengerFilter,
  OrderFinishedListingPublicStatusFilter,
  OrderFinishedListingRouteAddressFilter,
  OrderFinishedListingRouteDestinationAddressFilter,
  OrderFinishedListingRouteIntermediateAddressFilter,
  OrderFinishedListingRoutePickupAddressFilter,
  OrderFinishedListingExecutionStatusFilter,
  OrderFinishedListingTaxiFilter,
  OrderFinishedListingUrgentOnlyFilter,
} from "../common/types/order-finished-listing-filter";
import OrderFinishedListingFilterType from "../common/types/order-finished-listing-filter-type";
import OrderFinishedListingSortKey from "../common/types/order-finished-listing-sort-key";
import OrderFinishedListingExecutionStatus from "../common/types/order-finished-listing-execution-status";
import dateService from "../../../../common/utils/date/date.service";
import orderFinishedListingHelper from "../common/order-finished-listing.helper";
import OrderFinishedListingPublicStatus from "../common/types/order-finished-listing-public-status";
const orderFinishedListingFilterHelper = () => {
  const translations = orderTranslationsHelper.getFinishedListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.cargoCompanyInternalOrderIdAscLabel,
        value: OrderFinishedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC,
      },
      {
        label: translations.sort.cargoCompanyInternalOrderIdDescLabel,
        value: OrderFinishedListingSortKey.CARGO_COMPANY_INTERNAL_ID_DESC,
      },
      {
        label: translations.sort.startTimeAscLabel,
        value: OrderFinishedListingSortKey.START_TIME_ASC,
      },
      {
        label: translations.sort.startTimeDescLabel,
        value: OrderFinishedListingSortKey.START_TIME_DESC,
      },
    ];
  };

  const getSelectDataForCargoCompanyExternalOrderId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.externalOrderIdTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForCargoCompanyInternalOrderId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label:
            translations.filters.search.internalOrderIdTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDataForClient = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.clientTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForDispatch = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.dispatchTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForDriver = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.driverTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForPassenger = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.passengersTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForRouteAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.routeAddressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForRouteIntermediateAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.routeIntermediateAddressTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForRouteDestinationAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.routeDestinationAddressTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForRoutePickupAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.routePickupAddressTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForTaxi = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.taxiTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForPublicStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.publicStatusTemplateLabel.replace(
                "#{query}",
                orderFinishedListingHelper.getPublicStatusText(
                  OrderFinishedListingPublicStatus.PRIVATE
                )
              ),
            value: OrderFinishedListingPublicStatus.PRIVATE,
          },
          {
            label:
              translations.filters.search.publicStatusTemplateLabel.replace(
                "#{query}",
                orderFinishedListingHelper.getPublicStatusText(
                  OrderFinishedListingPublicStatus.PUBLIC
                )
              ),
            value: OrderFinishedListingPublicStatus.PUBLIC,
          },
          {
            label:
              translations.filters.search.publicStatusTemplateLabel.replace(
                "#{query}",
                orderFinishedListingHelper.getPublicStatusText(
                  OrderFinishedListingPublicStatus.PUBLISHED
                )
              ),
            value: OrderFinishedListingPublicStatus.PUBLISHED,
          },
        ],
      };
    };

  const getSelectDataForStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.executionStatusLabel.replace(
              "#{query}",
              orderFinishedListingHelper.getExecutionStatusText(
                OrderFinishedListingExecutionStatus.FINISHED
              )
            ),
            value: OrderFinishedListingExecutionStatus.FINISHED,
          },
          {
            label: translations.filters.search.executionStatusLabel.replace(
              "#{query}",
              orderFinishedListingHelper.getExecutionStatusText(
                OrderFinishedListingExecutionStatus.CANCELLED
              )
            ),
            value: OrderFinishedListingExecutionStatus.CANCELLED,
          },
        ],
      };
    };

  const getSelectDataForUrgentOnly =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.urgentOnlyLabel,
            value: true,
          },
        ],
      };
    };

  const getSelectDataForOrderStartDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.orderStartDateLabel,
        },
      };
    };

  const getSelectDataByType = (
    filterType: OrderFinishedListingFilterType,
    query: string
  ) => {
    const options: Record<
      OrderFinishedListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [OrderFinishedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID]:
        getSelectDataForCargoCompanyExternalOrderId,
      [OrderFinishedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID]:
        getSelectDataForCargoCompanyInternalOrderId,
      [OrderFinishedListingFilterType.CLIENT]: getSelectDataForClient,
      [OrderFinishedListingFilterType.DISPATCH]: getSelectDataForDispatch,
      [OrderFinishedListingFilterType.DRIVER]: getSelectDataForDriver,
      [OrderFinishedListingFilterType.ORDER_START_DATE]:
        getSelectDataForOrderStartDate,
      [OrderFinishedListingFilterType.PASSENGER]: getSelectDataForPassenger,
      [OrderFinishedListingFilterType.PUBLIC_STATUS]:
        getSelectDataForPublicStatus,
      [OrderFinishedListingFilterType.ROUTE_ADDRESS]:
        getSelectDataForRouteAddress,
      [OrderFinishedListingFilterType.ROUTE_DESTINATION_ADDRESS]:
        getSelectDataForRouteDestinationAddress,
      [OrderFinishedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS]:
        getSelectDataForRouteIntermediateAddress,
      [OrderFinishedListingFilterType.ROUTE_PICKUP_ADDRESS]:
        getSelectDataForRoutePickupAddress,
      [OrderFinishedListingFilterType.EXECUTION_STATUS]: getSelectDataForStatus,
      [OrderFinishedListingFilterType.TAXI]: getSelectDataForTaxi,
      [OrderFinishedListingFilterType.URGENT_ONLY]: getSelectDataForUrgentOnly,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeForCargoCompanyExternalOrderId = (
    filterValue: OrderFinishedListingCargoCompanyExternalOrderIdFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.cargoCompanyExternalOrderIdLabel,
      translations.filters.cargoCompanyExternalOrderIdTitle
    );
  };

  const getBadgeForCargoCompanyInternalOrderId = (
    filterValue: OrderFinishedListingCargoCompanyInternalOrderIdFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue.toString(),
      translations.filters.cargoCompanyInternalOrderIdLabel,
      translations.filters.cargoCompanyInternalOrderIdTitle
    );
  };

  const getBadgeForClient = (
    filterValue: OrderFinishedListingClientFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.clientLabel,
      translations.filters.clientTitle
    );
  };

  const getBadgeForDispatch = (
    filterValue: OrderFinishedListingDispatchFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.dispatchLabel,
      translations.filters.dispatchTitle
    );
  };

  const getBadgeForDriver = (
    filterValue: OrderFinishedListingDriverFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.driverLabel,
      translations.filters.driverTitle
    );
  };

  const getBadgeForPassenger = (
    filterValue: OrderFinishedListingPassengerFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.passengerLabel,
      translations.filters.passengerTitle
    );
  };

  const getBadgeForPublicStatus = (
    filterValue: OrderFinishedListingPublicStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText =
      orderFinishedListingHelper.getPublicStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.publicStatusLabel,
      translations.filters.publicStatusTitle
    );
  };

  const getBadgeForRouteAddress = (
    filterValue: OrderFinishedListingRouteAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.routeAddressLabel,
      translations.filters.routeAddressTitle
    );
  };

  const getBadgeForRouteIntermediateAddress = (
    filterValue: OrderFinishedListingRouteIntermediateAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.routeIntermediateAddressLabel,
      translations.filters.routeIntermediateAddressTitle
    );
  };

  const getBadgeForRouteDestinationAddress = (
    filterValue: OrderFinishedListingRouteDestinationAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.routeDestinationAddressLabel,
      translations.filters.routeDestinationAddressTitle
    );
  };

  const getBadgeForRoutePickupAddress = (
    filterValue: OrderFinishedListingRoutePickupAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.routePickupAddressLabel,
      translations.filters.routePickupAddressTitle
    );
  };

  const getBadgeForExecutionStatus = (
    filterValue: OrderFinishedListingExecutionStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText =
      orderFinishedListingHelper.getExecutionStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.statusLabel,
      translations.filters.statusTitle
    );
  };

  const getBadgeForTaxi = (
    filterValue: OrderFinishedListingTaxiFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.taxiLabel,
      translations.filters.taxiTitle
    );
  };

  const getBadgeForUrgentOnly = (
    filterValue: OrderFinishedListingUrgentOnlyFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      "",
      translations.filters.urgentOnlyLabel,
      translations.filters.urgentOnlyTitle
    );
  };

  const getBadgeForOrderStartDate = (
    filterValue: OrderFinishedListingOrderStartDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.orderStartDateLabel,
      translations.filters.orderStartDateTitle
    );
  };

  const getBadgeDefinitionByType = (
    filterType: OrderFinishedListingFilterType,
    filterValue: OrderFinishedListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      OrderFinishedListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [OrderFinishedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID]: () =>
        getBadgeForCargoCompanyExternalOrderId(
          filterValue as OrderFinishedListingCargoCompanyExternalOrderIdFilter["value"]
        ),
      [OrderFinishedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID]: () =>
        getBadgeForCargoCompanyInternalOrderId(
          filterValue as OrderFinishedListingCargoCompanyInternalOrderIdFilter["value"]
        ),
      [OrderFinishedListingFilterType.CLIENT]: () =>
        getBadgeForClient(
          filterValue as OrderFinishedListingClientFilter["value"]
        ),
      [OrderFinishedListingFilterType.DISPATCH]: () =>
        getBadgeForDispatch(
          filterValue as OrderFinishedListingDispatchFilter["value"]
        ),
      [OrderFinishedListingFilterType.DRIVER]: () =>
        getBadgeForDriver(
          filterValue as OrderFinishedListingDriverFilter["value"]
        ),
      [OrderFinishedListingFilterType.PASSENGER]: () =>
        getBadgeForPassenger(
          filterValue as OrderFinishedListingPassengerFilter["value"]
        ),
      [OrderFinishedListingFilterType.PUBLIC_STATUS]: () =>
        getBadgeForPublicStatus(
          filterValue as OrderFinishedListingPublicStatusFilter["value"]
        ),
      [OrderFinishedListingFilterType.ROUTE_ADDRESS]: () =>
        getBadgeForRouteAddress(
          filterValue as OrderFinishedListingRouteAddressFilter["value"]
        ),
      [OrderFinishedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS]: () =>
        getBadgeForRouteIntermediateAddress(
          filterValue as OrderFinishedListingRouteIntermediateAddressFilter["value"]
        ),
      [OrderFinishedListingFilterType.ROUTE_DESTINATION_ADDRESS]: () =>
        getBadgeForRouteDestinationAddress(
          filterValue as OrderFinishedListingRouteDestinationAddressFilter["value"]
        ),
      [OrderFinishedListingFilterType.ROUTE_PICKUP_ADDRESS]: () =>
        getBadgeForRoutePickupAddress(
          filterValue as OrderFinishedListingRoutePickupAddressFilter["value"]
        ),
      [OrderFinishedListingFilterType.EXECUTION_STATUS]: () =>
        getBadgeForExecutionStatus(
          filterValue as OrderFinishedListingExecutionStatusFilter["value"]
        ),
      [OrderFinishedListingFilterType.TAXI]: () =>
        getBadgeForTaxi(filterValue as OrderFinishedListingTaxiFilter["value"]),
      [OrderFinishedListingFilterType.URGENT_ONLY]: () =>
        getBadgeForUrgentOnly(
          filterValue as OrderFinishedListingUrgentOnlyFilter["value"]
        ),
      [OrderFinishedListingFilterType.ORDER_START_DATE]: () =>
        getBadgeForOrderStartDate(
          filterValue as OrderFinishedListingOrderStartDateFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: OrderFinishedListingFilterType
  ): ListingFilterDefinitionOption<OrderFinishedListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<OrderFinishedListingFilter>[] => {
      return Object.values(OrderFinishedListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<OrderFinishedListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default orderFinishedListingFilterHelper;
