import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import planningTranslationsHelper from "../../../../../languages/planning-translations.helper";

const PlanningOrderDriverPlanListingSelectedSuccessNotificationComponent: FC =
  () => {
    const translations =
      planningTranslationsHelper.getOrderTranslations().driverPlanListing
        .staging;

    return (
      <div className="planning-order-driver-plan-listing-selected__notification">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="planning-order-driver-plan-listing-selected__notification-success"
          size="4x"
        />
        <p>{translations.candidatureAddSuccessLabel}</p>
      </div>
    );
  };

export default PlanningOrderDriverPlanListingSelectedSuccessNotificationComponent;
