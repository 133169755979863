import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderAbandonedListingFilterSelectOption from "../common/types/order-abandoned-listing-filter-select-option";
import OrderAbandonedListingFilterType from "../common/types/order-abandoned-listing-filter-type";

const getSearchByClientQueryLabel = (
  searchByClientTemplateText: string,
  query: string
) => {
  return searchByClientTemplateText.replace(`#{query}`, query);
};

const getSearchByDispatchQueryLabel = (
  searchByDispatchTemplateText: string,
  query: string
) => {
  return searchByDispatchTemplateText.replace(`#{query}`, query);
};

const getSearchByCargoCompanyInternalOrderIdQueryLabel = (
  searchByCargoCompanyInternalOrderIdTemplateText: string,
  query: string
) => {
  return searchByCargoCompanyInternalOrderIdTemplateText.replace(
    `#{query}`,
    query
  );
};

const getSearchByCargoCompanyExternalOrderIdQueryLabel = (
  searchByCargoCompanyExternalOrderIdTemplateText: string,
  query: string
) => {
  return searchByCargoCompanyExternalOrderIdTemplateText.replace(
    `#{query}`,
    query
  );
};

const getSearchByRouteAddressQueryLabel = (
  searchByRouteAddressTemplateText: string,
  query: string
) => {
  return searchByRouteAddressTemplateText.replace(`#{query}`, query);
};

const getSearchByRouteIntermediateAddressQueryLabel = (
  searchByRouteIntermediateAddressTemplateText: string,
  query: string
) => {
  return searchByRouteIntermediateAddressTemplateText.replace(
    `#{query}`,
    query
  );
};

const getSearchByRouteDestinationAddressQueryLabel = (
  searchByRouteDestinationAddressTemplateText: string,
  query: string
) => {
  return searchByRouteDestinationAddressTemplateText.replace(`#{query}`, query);
};

const getSearchByRoutePickupAddressQueryLabel = (
  searchByRoutePickupAddressTemplateText: string,
  query: string
) => {
  return searchByRoutePickupAddressTemplateText.replace(`#{query}`, query);
};

const getSearchByWorkersQueryLabel = (
  searchByWorkersTemplateText: string,
  query: string
) => {
  return searchByWorkersTemplateText.replace(`#{query}`, query);
};

const getSearchQueryOptions = (
  query: string
): OrderAbandonedListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    orderTranslationsHelper.getAbandonedListingTranslations().filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const stringOptions: OrderAbandonedListingFilterSelectOption[] = [
    {
      label: getSearchByClientQueryLabel(
        searchFilterTranslations.searchByClientTemplateText,
        query
      ),
      value: {
        type: OrderAbandonedListingFilterType.CLIENT,
        value: query,
      },
    },
    {
      label: getSearchByDispatchQueryLabel(
        searchFilterTranslations.searchByDispatchTemplateText,
        query
      ),
      value: {
        type: OrderAbandonedListingFilterType.DISPATCH,
        value: query,
      },
    },
    {
      label: getSearchByCargoCompanyExternalOrderIdQueryLabel(
        searchFilterTranslations.searchByCargoCompanyExternalOrderIdTemplateText,
        query
      ),
      value: {
        type: OrderAbandonedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressQueryLabel(
        searchFilterTranslations.searchByRouteAddressTemplateText,
        query
      ),
      value: {
        type: OrderAbandonedListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressQueryLabel(
        searchFilterTranslations.searchByRouteDestinationAddressTemplateText,
        query
      ),
      value: {
        type: OrderAbandonedListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressQueryLabel(
        searchFilterTranslations.searchByRouteIntermediateAddressTemplateText,
        query
      ),
      value: {
        type: OrderAbandonedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressQueryLabel(
        searchFilterTranslations.searchByRoutePickupAddressTemplateText,
        query
      ),
      value: {
        type: OrderAbandonedListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },

    {
      label: getSearchByWorkersQueryLabel(
        searchFilterTranslations.searchByWorkersTemplateText,
        query
      ),
      value: {
        type: OrderAbandonedListingFilterType.PASSENGER,
        value: query,
      },
    },
  ];

  const options: OrderAbandonedListingFilterSelectOption[] = [...stringOptions];

  if (parseStringToNumber(query) !== undefined) {
    const orderInternalIdFilterOption: OrderAbandonedListingFilterSelectOption =
      {
        label: getSearchByCargoCompanyInternalOrderIdQueryLabel(
          searchFilterTranslations.searchByCargoCompanyInternalOrderIdTemplateText,
          query
        ),
        value: {
          type: OrderAbandonedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID,
          value: Number(query),
        },
      };

    options.push(orderInternalIdFilterOption);
  }

  return options;
};

const getSelectOptionsByQuery = (
  query: string
): OrderAbandonedListingFilterSelectOption[] => {
  const standardOptions = getSearchQueryOptions(query);

  return [...standardOptions];
};

const OrderAbandonedListingFiltersHelper = {
  getSelectOptionsByQuery,
};

export default OrderAbandonedListingFiltersHelper;
