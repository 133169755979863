import OperatorAddRequest from "../../../api/raily/operator/add/operator-add-request";
import OperatorAddParams from "./operator-add-params";

const create = (params: OperatorAddParams): OperatorAddRequest => {
  return {
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.phoneNumber.number,
    mobilePrimaryPrefix: params.phoneNumber.dialingCode,
    mobileSecondary: params.alternativePhoneNumber.number || null,
    mobileSecondaryPrefix: params.alternativePhoneNumber.dialingCode || null,
    password: params.password,
    username: params.username,
  };
};

const operatorAddRequestFactory = {
  create,
};

export default operatorAddRequestFactory;
