import PassengerAddByRailyCargoCompanySelectOption from "./types/passenger-add-by-raily-cargo-company-select-option";
import passengerAddFormValidationService from "../../common/passenger-add-form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import Joi from "joi";

const validateCargoCompanies = (
  cargoCompanies: PassengerAddByRailyCargoCompanySelectOption[]
): FormValidationResult => {
  const validationSchema = Joi.array().min(1).required();

  return formValidationService.validate(cargoCompanies, validationSchema);
};

const passengerAddByRailyFormValidationService = {
  validateFirstName: passengerAddFormValidationService.validateFirstName,
  validateLastName: passengerAddFormValidationService.validateLastName,
  validateMobile: passengerAddFormValidationService.validateMobile,
  validateAlternativeMobile:
    passengerAddFormValidationService.validateAlternativeMobile,
  validateEmail: passengerAddFormValidationService.validateEmail,
  validateUsername: passengerAddFormValidationService.validateUsername,
  validatePassword: passengerAddFormValidationService.validatePassword,
  validatePasswordRepeat:
    passengerAddFormValidationService.validatePasswordRepeat,
  validateAddresses: passengerAddFormValidationService.validateAddresses,
  validateDispatches: passengerAddFormValidationService.validateDispatches,
  validateCargoCompanies,
};

export default passengerAddByRailyFormValidationService;
