import SelectOptionTaxiContractsComponent from "./types/select-taxi-taxi-contracts";
import { TaxiTaxiContractResponseDataItem } from "./types/taxi-taxi-contracts.response";

const createTaxiContractSelectOption = (
  taxiCorporation: TaxiTaxiContractResponseDataItem
): SelectOptionTaxiContractsComponent => {
  return {
    label: taxiCorporation.display_name,
    value: taxiCorporation,
  };
};

const createTaxiContractsSelectOptions = (
  responseTaxiCorporations: TaxiTaxiContractResponseDataItem[]
): SelectOptionTaxiContractsComponent[] => {
  return responseTaxiCorporations.map(createTaxiContractSelectOption);
};

const taxiContractFactory = {
  createTaxiContractsSelectOptions,
};

export default taxiContractFactory;
