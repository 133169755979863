import TaxiOfficerEditByTaxiFormData from "./types/taxi-officer-edit-by-taxi-form-data";
import User from "../../../../../../common/types/user";
import TaxiOfficerEditParams from "../../../../../../common/services/taxi-officer/edit/taxi-officer-edit-params";

const create = (
  formData: TaxiOfficerEditByTaxiFormData,
  user: User
): TaxiOfficerEditParams => {
  const taxiCorporationUuid = user.aspects.taxiOfficer?.taxiCorporationUuid!;

  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    phoneNumber: formData.phoneNumber,
    alternativePhoneNumber: formData.alternativePhoneNumber,
    taxiCorporationUuid,
  };
};

const taxiOfficerEditByTaxiParamsFactory = {
  create,
};

export default taxiOfficerEditByTaxiParamsFactory;
