import PlannerDriverPlanOrderRequest from "../../../api/raily/planner/order/planning-driver-plan-order.request";
import PlannerDriverPlanOrderLoadParams from "./planner-driver-plan-order-load-params";

const create = (
  params: PlannerDriverPlanOrderLoadParams
): PlannerDriverPlanOrderRequest => {
  return {
    orderId: params.orderUuid,
  };
};

const plannerDriverPlanOrderRequestFactory = {
  create,
};

export default plannerDriverPlanOrderRequestFactory;
