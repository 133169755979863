import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import MileageUpdateVehicleType from "../types/mileage-update-vehicle-type";
import mileageUpdateFormValidationService from "./mileage-update-form-validation.service";
import MileageUpdateFormData from "./types/mileage-update-form-data";

const getDefaultFormData = (): MileageUpdateFormData => {
  return {
    addressSequence: [],
    distance: null,
    mileageDate: null,
    vehicleType: MileageUpdateVehicleType.COMPANY_OWNED,
    osrmDistance: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<MileageUpdateFormData> => {
    return {
      addressSequence: (values) =>
        mileageUpdateFormValidationService.validateAddressSequence(
          values.addressSequence
        ),
      distance: (values) =>
        mileageUpdateFormValidationService.validateDistance(values.distance),
      mileageDate: (values) =>
        mileageUpdateFormValidationService.validateMileageDate(
          values.mileageDate
        ),
      vehicleType: (values) =>
        mileageUpdateFormValidationService.validateVehicleType(
          values.vehicleType
        ),
      osrmDistance: (values) =>
        mileageUpdateFormValidationService.validateOSRMDistance(
          values.osrmDistance
        ),
    };
  };

const mileageUpdateFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default mileageUpdateFormHelper;
