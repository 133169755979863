import SearchRoadRoutingRequest, {
  SearchRoadRoutingRequestWaypointCoordinate,
} from "../../../utils/search-road-route/search-road-routing.request";
import DelegationRoadRouteLoadParams, {
  DelegationRoadRouteWaypoint,
} from "./delegation-road-route-load-params";

const changeWaypointType = (
  type: DelegationRoadRouteWaypoint
): SearchRoadRoutingRequestWaypointCoordinate => {
  return {
    latitude: type.lat,
    longitude: type.lon,
  };
};

const create = (
  loadParams: DelegationRoadRouteLoadParams
): SearchRoadRoutingRequest => {
  return {
    waypointCoordinates: loadParams.waypoints.map(changeWaypointType),
  };
};

const delegationRoadRouteRequestFactory = {
  create,
};

export default delegationRoadRouteRequestFactory;
