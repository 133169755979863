import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import useAbort from "../../../../common/hooks/use-abort";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import usePagination from "../../../../common/hooks/use-pagination";
import UserDeleteUserData from "../../common/delete/common/types/user-delete-user-data";
import UserDeleteComponent from "../../common/delete/user-delete.component";
import useCargoOfficerListingUserPermissions from "./common/user-permissions/use-cargo-officer-listing-user-permission";
import useCargoOfficerListingFilterDao from "./filters/use-cargo-officer-listing-filter.dao";
import CargoOfficerListingFilter from "./common/types/cargo-officer-listing-filter";
import CargoOfficerListingSortKey from "./common/types/cargo-officer-listing-sort-key";
import CargoOfficerListingItem from "./common/types/cargo-officer-listing-item";
import cargoOfficerListingLoadParamsFactory from "./common/cargo-officer-listing-load-params.factory";
import cargoOfficerListingFactory from "./common/cargo-officer-listing-item.factory";
import CargoOfficerListingFilterComponent from "./filters/cargo-officer-listing-filter.component";
import CargoOfficerListingTableComponent from "./table/cargo-officer-listing-table.component";
import CargoOfficerListLoadParams from "../../../../common/services/cargo-officer/list/cargo-officer-list-load-params";
import useCargoOfficerList from "../../../../common/services/cargo-officer/list/use-cargo-officer-list";

type CargoOfficerListingProps = {};

const CargoOfficerListingComponent: FC<CargoOfficerListingProps> = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();
  const userPermissions = useCargoOfficerListingUserPermissions();
  const listingItemsAbort = useAbort();
  const cargoOfficerList = useCargoOfficerList();
  const isComponentMounted = useIsComponentMounted();
  const filtersDao = useCargoOfficerListingFilterDao();
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();
  useDocumentTitle(documentTitleTranslations.userCargoOfficerListing);

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: cargoOfficerList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const [filters, setFilters] = useState<CargoOfficerListingFilter[]>(() =>
    filtersDao.getFilters()
  );
  const [sortKey, setSortKey] = useState<CargoOfficerListingSortKey>(
    () => filtersDao.getSortKey() ?? CargoOfficerListingSortKey.FIRST_NAME_ASC
  );
  const [userSelectedToDelete, setUserSelectedToDelete] =
    useState<UserDeleteUserData | null>(null);

  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const openDeleteUserModal = () => {
    setIsDeleteUserModalOpen(true);
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  useEffect(() => {
    const breadcrumbs =
      userBreadcrumbsHelper.getCargoOfficerListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  useEffect(() => {
    if (!isComponentMounted) return;

    saveFilters();
  }, [filters, page, pageSize, sortKey]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  const listingItems: CargoOfficerListingItem[] = useMemo(() => {
    return cargoOfficerListingFactory.createListingItems(
      cargoOfficerList.data.data
    );
  }, [cargoOfficerList.data.data]);

  const saveFilters = () =>
    filtersDao.saveFilters(filters, sortKey, page, pageSize);

  const translations =
    userTranslationsHelper.getCargoOfficerListingTranslations();

  const onSelectUserToDelete = (userData: UserDeleteUserData) => {
    setUserSelectedToDelete(userData);
    openDeleteUserModal();
  };

  const onDeleteUserSuccess = () => {
    closeDeleteUserModal();
    loadListingItems();
  };

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasVisibleAddCargoOfficerButton
        ? CargoOfficerAddLinkButton
        : undefined,
    ];
  };

  const CargoOfficerAddLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        to={userRoutesHelper.getCargoOfficerAddRoute()}
        type="primary"
        title={translations.header.addNewCargoOfficerLinkTitle}
        data-test-id="cargo-officer-listing-add-button"
      >
        {translations.header.addNewCargoOfficerLinkLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const headingActions = createHeadingActions();

  const loadListingItems = async () => {
    const params: CargoOfficerListLoadParams =
      cargoOfficerListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey
      );

    cargoOfficerList.load(params, listingItemsAbort.signal);
  };

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <CargoOfficerListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <CargoOfficerListingTableComponent
          listingItems={listingItems}
          isError={cargoOfficerList.isError}
          isLoading={cargoOfficerList.isLoading}
          onSelectUserToDelete={onSelectUserToDelete}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={cargoOfficerList.data.totalCount}
        />
      </CardComponent>
      <UserDeleteComponent
        isOpen={isDeleteUserModalOpen}
        onClose={closeDeleteUserModal}
        userData={userSelectedToDelete}
        onSuccess={onDeleteUserSuccess}
      />
    </>
  );
};

export default CargoOfficerListingComponent;
