import { FC } from "react";
import { faTrashRestore } from "@fortawesome/free-solid-svg-icons";
import TableButtonComponent, {
  TableButtonProps,
} from "../table-button.component";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";

type TableRestoreButtonProps = Pick<
  TableButtonProps,
  "onClick" | "title" | "isDisabled" | "isLoading" | "idForTesting"
>;

const TableRestoreButtonComponent: FC<TableRestoreButtonProps> = (props) => {
  const tableTranslations =
    appTranslationsHelper.getComponentTranslations().table;

  return (
    <TableButtonComponent
      onClick={props.onClick}
      icon={faTrashRestore}
      title={props.title ?? tableTranslations.restoreButtonTitle}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      idForTesting={props.idForTesting}
    />
  );
};

export default TableRestoreButtonComponent;
