import { FC } from "react";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import StatusLegendItemComponent from "./status-legend-item.component";
import StatusLegendData from "./types/status-legend-data";

type StatusLegendProps = {
  statusData: StatusLegendData[];
};

const StatusLegendComponent: FC<StatusLegendProps> = ({ statusData }) => {
  const translations =
    appTranslationsHelper.getComponentTranslations().statusLegend;

  const content = statusData.map(({ status, totalCount }) => (
    <StatusLegendItemComponent
      key={status}
      status={status}
      totalCount={totalCount}
    />
  ));

  return (
    <section className="status_legend">
      <h5>{translations.headingLabel}:</h5>
      {content}
    </section>
  );
};

export default StatusLegendComponent;
