enum TaxiRouteSettlementListingSortKey {
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  TOLL_ROADS_COST_ASC = "TOLL_ROADS_COST_ASC",
  TOLL_ROADS_COST_DESC = "TOLL_ROADS_COST_DESC",
  ROUTE_ID_ASC = "ROUTE_ID_ASC",
  ROUTE_ID_DESC = "ROUTE_ID_DESC",
  DRIVER_ASC = "DRIVER_ASC",
  DRIVER_DESC = "DRIVER_DESC",
  BILLING_MODEL_ASC = "BILLING_MODEL_ASC",
  BILLING_MODEL_DESC = "BILLING_MODEL_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  DISTANCE_COST_ASC = "DISTANCE_COST_ASC",
  DISTANCE_COST_DESC = "DISTANCE_COST_DESC",
  TOTAL_COST_ASC = "TOTAL_COST_ASC",
  TOTAL_COST_DESC = "TOTAL_COST_DESC",
}

export default TaxiRouteSettlementListingSortKey;
