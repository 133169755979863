import useAsyncData from "../../../hooks/use-async-data";
import delegationService from "../delegation.service";
import DelegationList from "./delegation-list";
import DelegationListLoadParams from "./delegation-list-load-params";

const useDelegationList = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<DelegationList>({
      data: [],
      totalCount: 0,
    });

  const load = async (
    params: DelegationListLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const delegationList = await delegationService.getList(params, signal);

      setData(delegationList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useDelegationList;
