export enum TaxiTransferedOrderSettlementListingRequestOrder {
  DATE_AT_ASC = "DATE_AT_ASC",
  DATE_AT_DESC = "DATE_AT_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  DISTANCE_AMOUNT_ASC = "DISTANCE_AMOUNT_ASC",
  DISTANCE_AMOUNT_DESC = "DISTANCE_AMOUNT_DESC",
  HALTING_AMOUNT_ASC = "HALTING_AMOUNT_ASC",
  HALTING_AMOUNT_DESC = "HALTING_AMOUNT_DESC",
  HIGHWAY_CHARGE_ASC = "HIGHWAY_CHARGE_ASC",
  HIGHWAY_CHARGE_DESC = "HIGHWAY_CHARGE_DESC",
  TOTAL_AMOUNT_ASC = "TOTAL_AMOUNT_ASC",
  TOTAL_AMOUNT_DESC = "TOTAL_AMOUNT_DESC",
  PRODUCER_ASC = "PRODUCER_ASC",
  PRODUCER_DESC = "PRODUCER_DESC",
  CONSUMER_ASC = "CONSUMER_ASC",
  CONSUMER_DESC = "CONSUMER_DESC",
  BILLING_MODEL_ASC = "BILLING_MODEL_ASC",
  BILLING_MODEL_DESC = "BILLING_MODEL_DESC",
}

type TaxiTransferedOrderSettlementListingRequest = {
  limit: number;
  offset: number;
  order: TaxiTransferedOrderSettlementListingRequestOrder | undefined;
  taxi_corporation_id: string | undefined;
  start_time_since: string | undefined;
  start_time_to: string | undefined;
  human_id: number | undefined;
  producer: string | undefined;
  consumer: string | undefined;
  status: string | undefined;
};

export default TaxiTransferedOrderSettlementListingRequest;
