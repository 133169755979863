import cargoCompanyDispatcherApiService from "../../../api/raily/cargo-company/dispatcher/cargo-company-dispatcher-api.service";
import dataLoadErrorFactory from "../../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../../utils/http/http.error";
import httpHelper from "../../../utils/http/http.helper";
import CargoCompanyDispatcherList from "./list/cargo-company-dispatcher-list";
import CargoCompanyDispatcherListError from "./list/cargo-company-dispatcher-list-error";
import CargoCompanyDispatcherListLoadParams from "./list/cargo-company-dispatcher-list-load-params";
import cargoCompanyDispatcherListRequestFactory from "./list/cargo-company-dispatcher-list-request.factory";
import cargoCompanyDispatcherListFactory from "./list/cargo-company-dispatcher-list.factory";

const handleCargoCompanyDispatcherListError = (
  error: HttpError | CargoCompanyDispatcherListError
): CargoCompanyDispatcherListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDispatcherList = async (
  params: CargoCompanyDispatcherListLoadParams,
  abortSignal: AbortSignal
): Promise<CargoCompanyDispatcherList> => {
  const request =
    cargoCompanyDispatcherListRequestFactory.createDispatcherListRequest(
      params
    );

  try {
    const response = await cargoCompanyDispatcherApiService().getDisaptcherList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoCompanyDispatcherListFactory.createDispatcherList(response);
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyDispatcherListError;
    throw handleCargoCompanyDispatcherListError(error);
  }
};

const cargoCompanyDispatcherService = {
  getDispatcherList,
};

export default cargoCompanyDispatcherService;
