import MileageContractListingFilter, {
  MileageContractListingCargoCompanyFilter,
} from "../common/types/mileage-contract-listing-filter";
import MileageContractListingRouteQueryParams from "../common/types/mileage-contract-listing-filter-route-params";
import MileageContractListingFilterType from "../common/types/mileage-contract-listing-filter-type";
import MileageContractListingSortKey from "../common/types/mileage-contract-listing-sort-key";

const getSortKey = (
  routeQueryParams: MileageContractListingRouteQueryParams
): MileageContractListingSortKey | undefined => {
  return routeQueryParams.sort as MileageContractListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: MileageContractListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: MileageContractListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const getFilters = (
  routeQueryParams: MileageContractListingRouteQueryParams
): MileageContractListingFilter[] => {
  const filters: MileageContractListingFilter[] = [];

  if (routeQueryParams.cargoCompany) {
    const cargoCompanyFilter: MileageContractListingCargoCompanyFilter = {
      type: MileageContractListingFilterType.CARGO_COMPANY,
      value:
        routeQueryParams.cargoCompany as MileageContractListingCargoCompanyFilter["value"],
    };

    filters.push(cargoCompanyFilter);
  }

  return filters;
};

const createRouteQueryParams = (
  filters: MileageContractListingFilter[],
  sortKey: MileageContractListingSortKey,
  page: number,
  pageSize: number
): MileageContractListingRouteQueryParams => {
  const routeQueryParams: MileageContractListingRouteQueryParams = {
    cargoCompany: filters.find(
      (filter) => filter.type === MileageContractListingFilterType.CARGO_COMPANY
    )?.value as MileageContractListingCargoCompanyFilter["value"],
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const mileageContractListingFiltersService = {
  getSortKey,
  getPage,
  getPageSize,
  getFilters,
  createRouteQueryParams,
};

export default mileageContractListingFiltersService;
