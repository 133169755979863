import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import taxiUnsettledOrderListingUserPermissionDefinition, {
  TaxiUnsettledOrderListingUserPermissions,
} from "./taxi-unsettled-order-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): TaxiUnsettledOrderListingUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    taxiUnsettledOrderListingUserPermissionDefinition
  );

  return permissions;
};

const taxiUnsettledOrderListingUserPermissionsHelper = {
  getPermissions,
};

export default taxiUnsettledOrderListingUserPermissionsHelper;
