import CargoTaxiContractAddRequest, {
  CargoTaxiContractAddRequestBillingModel,
} from "../../../api/raily/cargo-taxi-contract/add/cargo-taxi-contract-add.request";
import BillingModel from "../../../types/billing-model";
import CargoTaxiContractAddParams from "./cargo-taxi-contract-add-params";

const createBillingModel = (
  billingModel: BillingModel
): CargoTaxiContractAddRequestBillingModel => {
  switch (billingModel) {
    case BillingModel.AB:
      return CargoTaxiContractAddRequestBillingModel.AB;
    case BillingModel.ABA:
      return CargoTaxiContractAddRequestBillingModel.ABA;
    case BillingModel.OTHER:
      return CargoTaxiContractAddRequestBillingModel.OTHER;
    case BillingModel.SABS:
      return CargoTaxiContractAddRequestBillingModel.SABS;
  }
};

const create = (
  params: CargoTaxiContractAddParams
): CargoTaxiContractAddRequest => {
  return {
    allowChargeDuringRide: params.isTollRoadsDuringOrderAllowed,
    allowChargeWhileApproaching: params.isTollRoadsWhileArrivalAllowed,
    allowChargeWhileReturning: params.isTollRoadsWhileReturningAllowed,
    cargoCompanyId: params.cargoCompanyUuid,
    disabled: !params.isActive,
    discountLimit: params.discountPercentLimit,
    distanceRate: params.distanceRate,
    haltingTimeAfterMinutes: params.freeStoppingPeriodMinutes,
    haltingTimeRate: params.stoppingRate,
    model: createBillingModel(params.billingModel),
    publishingAllowed: params.isOrderPublishingAllowed,
    taxiCorporationId: params.taxiCorporationUuid,
    validSince: params.periodOfValidity.from,
    validTo: params.periodOfValidity.to,
  };
};

const cargoTaxiContractAddRequestFactory = {
  create,
};

export default cargoTaxiContractAddRequestFactory;
