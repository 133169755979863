export enum MileageAddFormSettingsMileageNumberCreationModel {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
  DICT = "DICT",
}

type MileageAddFormSettings = {
  isCardNumberRequired: boolean;
  isMileageNumberRequired: boolean;
  mileageNumberCreationModel: MileageAddFormSettingsMileageNumberCreationModel;
};

export default MileageAddFormSettings;
