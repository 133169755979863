import MileageDictionaryAddRequest, {
  MileageDictionaryAddRequestBody,
} from "./mileage-dictionary-add.request";

const createBody = (
  request: MileageDictionaryAddRequest
): MileageDictionaryAddRequestBody => {
  return {
    name: request.name,
  };
};

const mileageDictionaryAddRequestFactory = {
  createBody,
};

export default mileageDictionaryAddRequestFactory;
