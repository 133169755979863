import RouteFinishedListRequest, {
  RouteFinishedListRequestQueryParams,
} from "./route-finished-list.request";

const createQueryParams = (
  request: RouteFinishedListRequest
): RouteFinishedListRequestQueryParams => {
  return {
    order: request.order,
    taxiCorporationId: request.taxiCorporationId,
    driverId: request.driverId,
    driverName: request.driverName,
    taxiName: request.taxiName,
    planEntryNodes: request.planEntryNodes,
    startingNodeLabel: request.startingNodeLabel,
    endingNodeLabel: request.endingNodeLabel,
    startTimeSince: request.startTimeSince,
    startTimeTo: request.startTimeTo,
    endTimeSince: request.endTimeSince,
    endTimeTo: request.endTimeTo,
    humanId: request.humanId,
    involvedCargoLabels: request.involvedCargoLabels,
    isBilled: request.isBilled,
    cargoOrderId: request.cargoOrderId,
    cargoOrderHumanId: request.cargoOrderHumanId,
    creationStart: request.creationStart,
    creationEnd: request.creationEnd,
    limit: request.limit,
    offset: request.offset,
  };
};

const routeFinishedListRequestFactory = { createQueryParams };

export default routeFinishedListRequestFactory;
