export enum MileageSettingsDetailsMileageNumberCreationModel {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
  DICT = "DICT",
}

type MileageSettingsDetails = {
  uuid: string;
  createdAt: Date;
  createdBy: string;
  modificationDate?: Date | null;
  modifiedBy?: string | null;
  isCardNumberRequired: boolean;
  isMileageNumberRequired: boolean;
  mileageNumberCreationModel: MileageSettingsDetailsMileageNumberCreationModel;
};

export default MileageSettingsDetails;
