import UserStatus from "../../../types/user-status";

export enum DispatcherListLoadParamsOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

type DispatcherListLoadParams = {
  cargoCompany?: string;
  cargoCompanyId?: string;
  dispatch?: string;
  dispatchId?: string;
  order?: DispatcherListLoadParamsOrder;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  status?: UserStatus;
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
  page?: number;
  pageSize?: number;
};

export default DispatcherListLoadParams;
