enum MileageListingSortKey {
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  WORKER_NAME_ASC = "WORKER_NAME_ASC",
  WORKER_NAME_DESC = "WORKER_NAME_DESC",
  CARD_NO_ASC = "CARD_NO_ASC",
  CARD_NO_DESC = "CARD_NO_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  CARGO_COMPANY_NAME_ASC = "CARGO_COMPANY_NAME_ASC",
  CARGO_COMPANY_NAME_DESC = "CARGO_COMPANY_NAME_DESC",
}

export default MileageListingSortKey;
