import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import OrderDetailsForwardingFormData from "./types/order-details-forwarding-form-data";
import { PropertyName } from "lodash";

const validateTaxiCorporation = (
  taxiCorporation: OrderDetailsForwardingFormData["taxiCorporation"]
) => {
  const validationSchema = Joi.object().required();

  return formValidationService.validate(taxiCorporation, validationSchema);
};

const validateTaxiContract = (
  taxiContract: OrderDetailsForwardingFormData["taxiContract"]
) => {
  const validationSchema = Joi.object().required();

  return formValidationService.validate(taxiContract, validationSchema);
};

const orderDetailsForwardingFormValidationService = {
  validateTaxiCorporation,
  validateTaxiContract,
};

export default orderDetailsForwardingFormValidationService;
