import CargoTaxiContractListingItem from "../types/cargo-taxi-contract-listing-item";
import CargoTaxiContractListingSortKey from "../types/cargo-taxi-contract-listing-sort-key";

const sortListingItemsByBillingModelAsc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemA.billingModel.localeCompare(itemB.billingModel)
  );
};

const sortListingItemsByBillingModelDesc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemB.billingModel.localeCompare(itemA.billingModel)
  );
};

const sortListingItemsByDiscountPercentLimitAsc = (
  listingItems: CargoTaxiContractListingItem[]
) => {
  return listingItems.sort(
    (itemA, itemB) => itemA.discountPercentLimit - itemB.discountPercentLimit
  );
};

const sortListingItemsByDiscountPercentLimitDesc = (
  listingItems: CargoTaxiContractListingItem[]
) => {
  return listingItems.sort(
    (itemA, itemB) => itemB.discountPercentLimit - itemA.discountPercentLimit
  );
};

const sortListingItemsByDistanceRateAsc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.distanceRate - itemB.distanceRate
  );
};

const sortListingItemsByDistanceRateDesc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.distanceRate - itemA.distanceRate
  );
};

const sortListingItemsByHaltingTimeGracePeriodAsc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemA.freeStoppingPeriodMinutes - itemB.freeStoppingPeriodMinutes
  );
};

const sortListingItemsByHaltingTimeGracePeriodDesc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemB.freeStoppingPeriodMinutes - itemA.freeStoppingPeriodMinutes
  );
};

const sortListingItemsByHaltingTimeRateAsc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.stoppingRate - itemB.stoppingRate
  );
};

const sortListingItemsByHaltingTimeRateDesc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.stoppingRate - itemA.stoppingRate
  );
};

const sortListingItemsByTaxiCorporationAsc = (
  listingItems: CargoTaxiContractListingItem[]
) => {
  return listingItems.sort((itemA, itemB) =>
    itemA.taxiCorporation.localeCompare(itemB.taxiCorporation)
  );
};

const sortListingItemsByTaxiCorporationDesc = (
  listingItems: CargoTaxiContractListingItem[]
) => {
  return listingItems.sort((itemA, itemB) =>
    itemB.taxiCorporation.localeCompare(itemA.taxiCorporation)
  );
};

const sortListingItemsByPeriodOfValidityFromAsc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.validFrom.getTime() - itemA.validFrom.getTime()
  );
};

const sortListingItemsByPeriodOfValidityFromDesc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.validFrom.getTime() - itemB.validFrom.getTime()
  );
};

const sortListingItemsByPeriodOfValidityToAsc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.validTo.getTime() - itemA.validTo.getTime()
  );
};

const sortListingItemsByPeriodOfValidityToDesc = (
  listingItems: CargoTaxiContractListingItem[]
): CargoTaxiContractListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.validTo.getTime() - itemB.validTo.getTime()
  );
};

const sortListingItems = (
  listingItems: CargoTaxiContractListingItem[],
  sortKey: CargoTaxiContractListingSortKey | null
): CargoTaxiContractListingItem[] => {
  switch (sortKey) {
    case CargoTaxiContractListingSortKey.BILLING_MODEL_ASC:
      return sortListingItemsByBillingModelAsc(listingItems);
    case CargoTaxiContractListingSortKey.BILLING_MODEL_DESC:
      return sortListingItemsByBillingModelDesc(listingItems);
    case CargoTaxiContractListingSortKey.DISCOUNT_PERCENT_LIMIT_ASC:
      return sortListingItemsByDiscountPercentLimitAsc(listingItems);
    case CargoTaxiContractListingSortKey.DISCOUNT_PERCENT_LIMIT_DESC:
      return sortListingItemsByDiscountPercentLimitDesc(listingItems);
    case CargoTaxiContractListingSortKey.DISTANCE_RATE_ASC:
      return sortListingItemsByDistanceRateAsc(listingItems);
    case CargoTaxiContractListingSortKey.DISTANCE_RATE_DESC:
      return sortListingItemsByDistanceRateDesc(listingItems);
    case CargoTaxiContractListingSortKey.FREE_STOPPING_PERIOD_ASC:
      return sortListingItemsByHaltingTimeGracePeriodAsc(listingItems);
    case CargoTaxiContractListingSortKey.FREE_STOPPING_PERIOD_DESC:
      return sortListingItemsByHaltingTimeGracePeriodDesc(listingItems);
    case CargoTaxiContractListingSortKey.STOPPING_RATE_ASC:
      return sortListingItemsByHaltingTimeRateAsc(listingItems);
    case CargoTaxiContractListingSortKey.STOPPING_RATE_DESC:
      return sortListingItemsByHaltingTimeRateDesc(listingItems);
    case CargoTaxiContractListingSortKey.PERIOD_OF_VALIDITY_FROM_ASC:
      return sortListingItemsByPeriodOfValidityFromAsc(listingItems);
    case CargoTaxiContractListingSortKey.PERIOD_OF_VALIDITY_FROM_DESC:
      return sortListingItemsByPeriodOfValidityFromDesc(listingItems);
    case CargoTaxiContractListingSortKey.PERIOD_OF_VALIDITY_TO_ASC:
      return sortListingItemsByPeriodOfValidityToAsc(listingItems);
    case CargoTaxiContractListingSortKey.PERIOD_OF_VALIDITY_TO_DESC:
      return sortListingItemsByPeriodOfValidityToDesc(listingItems);
    case CargoTaxiContractListingSortKey.TAXI_CORPORATION_ASC:
      return sortListingItemsByTaxiCorporationAsc(listingItems);
    case CargoTaxiContractListingSortKey.TAXI_CORPORATION_DESC:
      return sortListingItemsByTaxiCorporationDesc(listingItems);
    default:
      return listingItems;
  }
};

const cargoTaxiContractListingSortService = { sortListingItems };

export default cargoTaxiContractListingSortService;
