import StatusLegendStatuses from "../../../../../common/components/status-legend/types/status-legend-statuses";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import BillingModel from "../../../common/types/billing-model";
import { TaxiOrderSettlementListingStatusCountersResponseData } from "./api/taxi-order-settlement-listing-status-counters.response";
import TaxiOrderSettlementListingItemBillingStatus from "./types/taxi-order-settlement-listing-item-billing-status";
import TaxiOrderSettlementListingItemBillingType from "./types/taxi-order-settlement-listing-item-billing-type";
import TaxiOrderSettlementListingStatusLegendOption from "./types/taxi-order-settlement-listing-status-legend-option";

const getBillingStatusOptions = (
  responseData?: TaxiOrderSettlementListingStatusCountersResponseData
): TaxiOrderSettlementListingStatusLegendOption[] => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .status;

  const options: TaxiOrderSettlementListingStatusLegendOption[] = [
    {
      text: translations.CREATED,
      status: TaxiOrderSettlementListingItemBillingStatus.CREATED,
      totalCount: responseData?.created,
    },
    {
      text: translations.ACCEPTED,
      status: TaxiOrderSettlementListingItemBillingStatus.ACCEPTED,
      totalCount: responseData?.accepted,
    },
    {
      text: translations.REJECTED,
      status: TaxiOrderSettlementListingItemBillingStatus.REJECTED,
      totalCount: responseData?.rejected,
    },
    {
      text: translations.REOPENED,
      status: TaxiOrderSettlementListingItemBillingStatus.REOPENED,
      totalCount: responseData?.reopened,
    },
    {
      text: translations.REOPEN_REQUEST_SENT,
      status: TaxiOrderSettlementListingItemBillingStatus.REOPEN_REQUEST_SENT,
      totalCount: responseData?.reopen_request,
    },
  ];

  return options;
};

const getBillingStatusText = (
  status: TaxiOrderSettlementListingItemBillingStatus
): string => {
  const options = getBillingStatusOptions();

  return options.find((option) => option.status === status)?.text ?? "";
};

const getBillingTypeOptions = () => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .type;

  const options: {
    text: string;
    billingType: TaxiOrderSettlementListingItemBillingType;
  }[] = [
    {
      text: translations.PUB_RAILY_2_TAXI,
      billingType: TaxiOrderSettlementListingItemBillingType.PUB_RAILY_2_TAXI,
    },
    {
      text: translations.PRIV_CARGO_2_TAXI,
      billingType: TaxiOrderSettlementListingItemBillingType.PRIV_CARGO_2_TAXI,
    },
    {
      text: translations.PRIV_TAXI_2_RAILY,
      billingType: TaxiOrderSettlementListingItemBillingType.PRIV_TAXI_2_RAILY,
    },
  ];

  return options;
};

const getBillingTypeText = (
  type: TaxiOrderSettlementListingItemBillingType
): string => {
  const options = getBillingTypeOptions();

  return options.find((option) => option.billingType === type)?.text ?? "";
};

const getBillingModelOptions = () => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .model;

  const options: {
    text: string;
    billingModel: BillingModel;
  }[] = [
    {
      text: translations.AB,
      billingModel: BillingModel.AB,
    },
    {
      text: translations.ABA,
      billingModel: BillingModel.ABA,
    },
    {
      text: translations.OTHER,
      billingModel: BillingModel.OTHER,
    },
    {
      text: translations.SABS,
      billingModel: BillingModel.SABS,
    },
  ];

  return options;
};

const getBillingModelText = (type: BillingModel): string => {
  const options = getBillingModelOptions();

  return options.find((option) => option.billingModel === type)?.text ?? "";
};

const taxiOrderSettlementListingHelper = {
  getBillingStatusOptions,
  getBillingStatusText,
  getBillingTypeOptions,
  getBillingTypeText,
  getBillingModelOptions,
  getBillingModelText,
};

export default taxiOrderSettlementListingHelper;
