import useAsyncData from "../../../../hooks/use-async-data";
import billingService from "../../billings.service";
import TransferredOrderStatusCount from "./transferred-order-status-count.model";

const useTransferredOrderStatusCount = () => {
  const { data, setData, setIsError, setIsLoading, isError, isLoading } =
    useAsyncData<TransferredOrderStatusCount | undefined>(undefined);

  const load = async (signal: AbortSignal) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const transferredOrderStatusCount =
        await billingService.getTransferredOrderStatusCount(signal);

      if (!signal.aborted) {
        setData(transferredOrderStatusCount);
      }
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useTransferredOrderStatusCount;
