import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import TaxiUnsettledTransferedOrderListingFilter from "../../types/taxi-unsettled-transfered-order-listing-filter";
import TaxiUnsettledTransferedOrderListingFilterSelectOption from "../../types/taxi-unsettled-transfered-order-listing-filter-select-option";
import taxiUnsettledTransferedOrderListingFilterHelper from "../../helper/taxi-unsettled-transfered-order-listing-filter.helper";

type TaxiUnsettledTransferedOrderListingFiltersSelectProps = {
  filters: TaxiUnsettledTransferedOrderListingFilter[];
  onAddNewFilter: (filter: TaxiUnsettledTransferedOrderListingFilter) => void;
};

const TaxiUnsettledTransferedOrderListingFiltersSelectComponent: FC<
  TaxiUnsettledTransferedOrderListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: TaxiUnsettledTransferedOrderListingFilterSelectOption[] =
    useMemo(() => {
      return taxiUnsettledTransferedOrderListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: TaxiUnsettledTransferedOrderListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(
          option as TaxiUnsettledTransferedOrderListingFilterSelectOption
        )
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="taxi-unsettled-transfered-order-listing-filter"
    />
  );
};

export default TaxiUnsettledTransferedOrderListingFiltersSelectComponent;
