import cargoOrderSettlementListingRouteQueryParamsService from "../../common/cargo-order-settlement-listing-route-query-params.service";

const cargoOrderSettlementListingByCargoRouteQueryParamsService = {
  getFilters: cargoOrderSettlementListingRouteQueryParamsService.getFilters,
  getSortKey: cargoOrderSettlementListingRouteQueryParamsService.getSortKey,
  getPage: cargoOrderSettlementListingRouteQueryParamsService.getPage,
  getPageSize: cargoOrderSettlementListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams:
    cargoOrderSettlementListingRouteQueryParamsService.createRouteQueryParams,
};

export default cargoOrderSettlementListingByCargoRouteQueryParamsService;
