import TaxiFleetPartnerDetailsResponse from "../../../api/raily/taxi-fleet-partner/details/taxi-fleet-partner-details.response";
import TaxiFleetPartnerDetails from "./taxi-fleet-partner-details";

const create = (
  response: TaxiFleetPartnerDetailsResponse
): TaxiFleetPartnerDetails => {
  return {
    createdBy: response.data.createdBy,
    creationDate: response.data.createdAt,
    modificationDate: response.data.modifiedAt,
    modifiedBy: response.data.modifiedBy,
    name: response.data.name,
    taxiCorporationUuid: response.data.taxiCorporationId,
    uuid: response.data.id,
  };
};

const taxiFleetPartnerDetailsFactory = {
  create,
};

export default taxiFleetPartnerDetailsFactory;
