import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import DriverPlanDataResponse from "./driver-plan-data.response";

const fetchDriverPlanData = async (
  driverUuid: string
): Promise<DriverPlanDataResponse> => {
  const path = `/drivers/${driverUuid}`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const driverPlanApiService = {
  fetchDriverPlanData,
};

export default driverPlanApiService;
