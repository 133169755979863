import appConfig from "../../../../../../../../app.config";
import SearchRoadRoutingRequest from "../../../../../../../../common/utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../../../../../../../common/utils/search-road-route/search-road-routing.response";
import searchRoadRoutingService from "../../../../../../../../common/utils/search-road-route/search-road-routing.service";
import authService from "../../../../../../../auth/common/auth.service";
import DriverDetailsPlanByTaxiPlanResponse from "./driver-details-active-route-plans-by-taxi-plan.response";

const fetchPlan = async (
  driverUuid: string,
  taxiCorporationUuid: string
): Promise<DriverDetailsPlanByTaxiPlanResponse> => {
  const path = `/taxi-corporations/${taxiCorporationUuid}/drivers/${driverUuid}/plan`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchRoute = (
  request: SearchRoadRoutingRequest
): Promise<SearchRoadRoutingResponse> => {
  return searchRoadRoutingService.search(request);
};

const driverDetailsActiveRoutePlansByTaxiApiService = {
  fetchPlan,
  fetchRoute,
};

export default driverDetailsActiveRoutePlansByTaxiApiService;
