import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import CargoCompanyAddFormData from "./types/cargo-company-add-form-data";
import cargoCompanyAddFormValidationService from "./cargo-company-add-form-validation.service";

const getDefaultFormData = (): CargoCompanyAddFormData => {
  return {
    taxNumber: "",
    companyId: "",
    nationalCourtRegister: "",
    name: "",
    displayName: "",
    address: "",
    contractMaintenancePolicy: null,
    notes: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<CargoCompanyAddFormData> => {
    return {
      taxNumber: (values) =>
        cargoCompanyAddFormValidationService.validateTaxNumber(
          values.taxNumber
        ),
      companyId: (values) =>
        cargoCompanyAddFormValidationService.validateCompanyId(
          values.companyId
        ),
      nationalCourtRegister: (values) =>
        cargoCompanyAddFormValidationService.validateNationalCourtRegister(
          values.nationalCourtRegister
        ),
      name: (values) =>
        cargoCompanyAddFormValidationService.validateName(values.name),
      displayName: (values) =>
        cargoCompanyAddFormValidationService.validateDisplayName(
          values.displayName
        ),
      address: (values) =>
        cargoCompanyAddFormValidationService.validateAddress(values.address),
      contractMaintenancePolicy: (values) =>
        cargoCompanyAddFormValidationService.validateContractMaintenancePolicy(
          values.contractMaintenancePolicy!
        ),
      notes: (values) =>
        cargoCompanyAddFormValidationService.validateNotes(values.notes),
    };
  };

const cargoCompanyAddFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default cargoCompanyAddFormHelper;
