import { FC, useEffect, useMemo, useRef, useState } from "react";
import routeTranslationsHelper from "../../../languages/route-translations.helper";
import Row from "../../../common/components/grid/row";
import Column from "../../../common/components/grid/column";
import MapComponent from "../../../common/components/map/map.component";
import MessengerComponent from "../../../common/components/messenger/messenger.component";
import useRouteDetails from "../../../common/services/route/details/use-route-details";
import MapRoute from "../../../common/components/map/types/map-route";
import RouteDetailsLoaderComponent from "./common/components/loader/route-details-loader.component";
import RouteDetailsErrorComponent from "./common/components/error/route-details-error.component";
import RouteDetailsToolsComponent from "./common/components/tools/route-details-tools.component";
import RouteDetailsPassengersComponent from "./common/components/passengers/route-details-passengers.component";
import routeDetailsHelper from "./common/route-details.helper";
import { RouteDetailsPassenger } from "../../../common/services/route/details/route-details";
import RouteDetailsDriverComponent from "./common/components/driver/route-details-driver.component";
import routeDetailsFactory from "./common/route-details.factory";
import RouteDetailsRouteWaypoint from "./common/types/route-details-route-waypoint";
import useAbort from "../../../common/hooks/use-abort";
import MapMarker from "../../../common/components/map/types/map-marker";
import mapMarkerIconFactory from "../../../common/components/map/marker/map-marker-icon.factory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import useRouteRoadRoutes from "../../../common/services/route/road-route/use-road-route-road";
import RouteRoadRouteLoadParams from "../../../common/services/route/road-route/route-road-route-load-params";
import routeRoadRouteLoadParamsFactory from "./common/route-road-route-load-params.factory";
import RouteDetailsRouteComponent from "./common/components/route/route-details-route.component";
import useRouteCompletedRoute from "../../../common/services/route/completed-route/use-route-completed-route";
import RouteDetailsRouteWaypointGroup from "./common/types/route-details-route-waypoint-group";

type RouteDetailsProps = {
  routeUuid: string;
  routeHumanId: number;
  isVisible: boolean;
};

const RouteDetailsComponent: FC<RouteDetailsProps> = (props) => {
  const leftColumnContentRef = useRef<HTMLDivElement>(null);
  const rightColumnContentHeight = useMemo(() => {
    return leftColumnContentRef.current?.offsetHeight || 500;
  }, [leftColumnContentRef.current?.offsetHeight]);

  const routeDetails = useRouteDetails();
  const routeDetailsAbort = useAbort();

  const routeCompletedRoute = useRouteCompletedRoute();
  const routeCompletedRouteAbort = useAbort();

  const roadRoutes = useRouteRoadRoutes();

  const [shouldShowCompletedMapRoutes, setShouldShowCompletedMapRoutes] =
    useState(false);

  const [selectedPassenger, setSelectedPassenger] =
    useState<RouteDetailsPassenger | null>(null);

  const waypointGroups: RouteDetailsRouteWaypointGroup[] = useMemo(() => {
    if (!routeDetails.data) {
      return [];
    }

    return routeDetailsFactory.createWaypointGroups(
      routeDetails.data,
      roadRoutes.data
    );
  }, [routeDetails.data, roadRoutes.data]);

  const totalWaypoints: RouteDetailsRouteWaypoint[] = useMemo(() => {
    return waypointGroups.map((group) => group.waypoints).flat();
  }, [waypointGroups]);

  const completedMapRoute: MapRoute | undefined = useMemo(() => {
    if (
      !shouldShowCompletedMapRoutes ||
      !routeCompletedRoute.data.data.length
    ) {
      return;
    }

    return routeDetailsFactory.createCompletedMapRoute(
      routeCompletedRoute.data.data
    );
  }, [routeCompletedRoute.data.data]);

  useEffect(() => {
    if (!props.isVisible) {
      return;
    }

    loadDetails();
  }, [props.isVisible]);

  useEffect(() => {
    if (
      !props.isVisible ||
      !totalWaypoints.length ||
      !shouldShowCompletedMapRoutes
    ) {
      return;
    }

    loadCompletedRoute();
  }, [shouldShowCompletedMapRoutes]);

  useEffect(() => {
    if (!props.isVisible || !totalWaypoints.length) {
      return;
    }

    loadRoadRoutes();
  }, [props.isVisible, totalWaypoints.length]);

  const loadDetails = () => {
    routeDetails.load(
      { planEntryUuid: props.routeUuid },
      routeDetailsAbort.signal
    );
  };

  const loadCompletedRoute = () => {
    routeCompletedRoute.load(
      { planEntryUuid: props.routeUuid },
      routeCompletedRouteAbort.signal
    );
  };

  const loadRoadRoutes = () => {
    const params: RouteRoadRouteLoadParams[] = waypointGroups.map((group) =>
      routeRoadRouteLoadParamsFactory.create(group)
    );

    roadRoutes.load(params);
  };

  const driverPositionMapMarker: MapMarker | undefined = useMemo(() => {
    if (shouldShowCompletedMapRoutes) {
      const lastWaypoint =
        completedMapRoute?.waypoints[completedMapRoute.waypoints.length - 1];

      return {
        coordinate: {
          latitude: lastWaypoint?.latitude ?? 0,
          longitude: lastWaypoint?.longitude ?? 0,
        },
        icon: mapMarkerIconFactory.createStandardIcon({
          content: (
            <div>
              <FontAwesomeIcon icon={faCar} />
            </div>
          ),
        }),
      };
    }
  }, [completedMapRoute]);

  const locationsMapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers = routeDetailsFactory.createMapMarkers(
      totalWaypoints,
      selectedPassenger?.uuid
    );

    return mapMarkers;
  }, [selectedPassenger?.uuid, totalWaypoints]);

  const mapMarkers: MapMarker[] = useMemo(() => {
    if (driverPositionMapMarker) {
      return [...locationsMapMarkers, driverPositionMapMarker];
    }

    return locationsMapMarkers;
  }, [locationsMapMarkers, driverPositionMapMarker]);

  const onPassengerRowClick = (passenger: RouteDetailsPassenger) => {
    if (passenger.uuid === selectedPassenger?.uuid) {
      setSelectedPassenger(null);
      return;
    }

    setSelectedPassenger(passenger);
  };

  const toggleCompletedMapRoutesActive = () => {
    setShouldShowCompletedMapRoutes((curr) => !curr);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const routes: MapRoute[] = routeDetailsFactory.createMapRoutes(
      roadRoutes.data.flat()
    );

    if (shouldShowCompletedMapRoutes && completedMapRoute) {
      routes.push(completedMapRoute);
    }

    return routes;
  }, [roadRoutes.data, completedMapRoute, shouldShowCompletedMapRoutes]);

  const isCompletedRoutesFetching = routeCompletedRoute.isLoading;

  const isCompletedRouteButtonActive = shouldShowCompletedMapRoutes;

  const routeDetailsToolsProps = {
    isCompletedRoutesFetching,
    isCompletedRouteButtonActive,
    toggleCompletedMapRoutesActive,
  };

  const messengerChannelsAvailability =
    routeDetailsHelper.getMessengerChannelAvailability();

  const DetailsContent = (
    <Row>
      <Column lg={7}>
        <div ref={leftColumnContentRef}>
          <Row>
            <Column>
              <div className="route_details_map_wrapper">
                <MapComponent markers={mapMarkers} autoFit routes={mapRoutes} />
                <RouteDetailsToolsComponent {...routeDetailsToolsProps} />
              </div>
            </Column>
          </Row>
          <Row>
            <Column md={4} lg={3}>
              {!!routeDetails.data?.passengers.length && (
                <RouteDetailsPassengersComponent
                  onPassengerRowClick={onPassengerRowClick}
                  passengers={routeDetails.data.passengers}
                  selectedPassenger={selectedPassenger}
                  waypoints={totalWaypoints}
                />
              )}
              {!!routeDetails.data?.driver && (
                <RouteDetailsDriverComponent
                  driver={routeDetails.data.driver}
                />
              )}
            </Column>
            <Column md={8} lg={9}>
              {!!totalWaypoints.length && (
                <RouteDetailsRouteComponent
                  waypoints={totalWaypoints}
                  routeUuid={props.routeUuid}
                  selectedPassenger={selectedPassenger}
                />
              )}
            </Column>
          </Row>
        </div>
      </Column>
      <Column lg={5}>
        <div style={{ height: rightColumnContentHeight }}>
          <MessengerComponent
            channelsAvailability={messengerChannelsAvailability}
            planEntryUuid={props.routeUuid}
          />
        </div>
      </Column>
    </Row>
  );

  const ErrorContent = (
    <RouteDetailsErrorComponent
      routeHumanId={props.routeHumanId}
      isFetching={routeDetails.isLoading}
      onTryAgainButtonClick={loadDetails}
    />
  );

  const LoaderContent = <RouteDetailsLoaderComponent />;

  return (
    <div className="route_details">
      {routeDetails.isError
        ? ErrorContent
        : routeDetails.isLoading
        ? LoaderContent
        : DetailsContent}
    </div>
  );
};

export default RouteDetailsComponent;
