import useAsyncData from "../../../hooks/use-async-data";
import mileageDictionaryService from "../mileage-dictionary.service";
import MileageDictionaryList from "./mileage-dictionary-list";
import MileageDictionaryListLoadParams from "./mileage-dictionary-list-load-params";

const useMileageDictionaryList = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<MileageDictionaryList>({
      data: [],
      totalCount: 0,
    });

  const load = async (
    loadParams: MileageDictionaryListLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const mileageDictionaryList = await mileageDictionaryService.getList(
        loadParams,
        signal
      );

      setData(mileageDictionaryList);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useMileageDictionaryList;
