import { PassengerAddRequestPassengerAddress } from "./api/passenger-add-request";
import PassengerAddress from "../../common/types/passenger-address";
import PassengerDispatchSelectOption from "../../common/types/passenger-dispatch-select-option";
import { CargoCompanyDispatchListItem } from "../../../../../common/services/cargo-company/dispatch/list/cargo-company-dispatch-list";

const createAddPassengerRequestAddress = (
  formDataAddress: PassengerAddress
): PassengerAddRequestPassengerAddress => {
  return {
    lat: formDataAddress.latitude,
    lon: formDataAddress.longitude,
    country: formDataAddress.country.trim(),
    town: formDataAddress.town.trim(),
    street: formDataAddress.street.trim(),
    zip_code: formDataAddress.zipCode.trim(),
    house_no: formDataAddress.houseNumber.trim(),
    apartment: formDataAddress.apartmentNumber.trim(),
    description: formDataAddress.description.trim() || null,
  };
};

const createAddPassengerRequestAddresses = (
  formDataAddresses: PassengerAddress[]
): PassengerAddRequestPassengerAddress[] => {
  return formDataAddresses.map((address: PassengerAddress) => {
    return createAddPassengerRequestAddress(address);
  });
};

const createAddPassengerRequestDispatchIds = (
  formDataDispatches: PassengerDispatchSelectOption[]
): string[] => {
  return formDataDispatches.map((dispatch) => {
    return dispatch.value.uuid;
  });
};

const createDispatchSelectOption = (
  responseDispatch: CargoCompanyDispatchListItem
): PassengerDispatchSelectOption => {
  return {
    label: responseDispatch.displayName,
    value: {
      uuid: responseDispatch.uuid,
      displayName: responseDispatch.displayName,
      addressString: responseDispatch.address,
    },
  };
};

const createDispatchSelectOptions = (
  responseDispatches: CargoCompanyDispatchListItem[]
): PassengerDispatchSelectOption[] =>
  responseDispatches.map(createDispatchSelectOption);

const passengerAddFactory = {
  createAddPassengerRequestDispatchIds,
  createDispatchSelectOptions,
  createAddPassengerRequestAddresses,
};

export default passengerAddFactory;
