import { FC, useEffect, useState } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import BooleanSelectComponent from "../../../../common/components/form/select/boolean/boolean-select.component";
import CargoCompanySingleSelectComponent from "../../../../common/components/form/select/cargo-company/cargo-company-single-select.component";
import useForm from "../../../../common/components/form/use-form";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import useAbort from "../../../../common/hooks/use-abort";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import useCargoCompanyList from "../../../../common/services/cargo-company/cargo-company/list/use-cargo-company-list";
import notificationService from "../../../../common/utils/notification/notification.service";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import MileageContractAddFormData from "./common/types/mileage-contract-add-form-data";
import mileageContractAddFormHelper from "./common/mileage-contract-add-form.helper";
import mileageContractAddParamsFactory from "./common/mileage-contract-add-params.factory";
import mileageBreadcrumbsHelper from "../../common/breadcrumbs/mileage-breadcrumbs.helper";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import mileageContractService from "../../../../common/services/mileage-contract/mileage-contract.service";
import MileageContractAddParams from "../../../../common/services/mileage-contract/add/mileage-contract-add-params";
import useMileageContractTemplate from "../../../../common/services/mileage-contract/template/use-mileage-contract-template";
import { useNavigate } from "react-router-dom";
import mileageRoutesHelper from "../../common/routes/mileage-routes.helper";

const MileageContractAddComponent: FC = () => {
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const navigate = useNavigate();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageContractAdd);

  useEffect(() => {
    const breadcrumbs =
      mileageBreadcrumbsHelper.getMileageContractAddBreadcrumbs();

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const translations =
    mileageTranslationsHelper.getMileageContractAddTranslations();

  const form = useForm<MileageContractAddFormData>({
    emptyValues: mileageContractAddFormHelper.getEmptyFormData(),
    validationDefinition:
      mileageContractAddFormHelper.getValidationDefinition(),
  });

  const cargoCompanyList = useCargoCompanyList();
  const cargoCompanyListAbort = useAbort();

  const mileageContractsAddAbort = useAbort();

  useEffect(() => {
    cargoCompanyList.load({}, cargoCompanyListAbort.signal);
  }, []);

  const [isContractAddPending, setIsContractAddPending] = useState(false);

  const onContractAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigate(mileageRoutesHelper.getContractListingRoute());
  };

  const onContractAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const addContract = async () => {
    setIsContractAddPending(true);

    const params: MileageContractAddParams =
      mileageContractAddParamsFactory.create(form.values);

    try {
      await mileageContractService.add(params, mileageContractsAddAbort.signal);

      onContractAddSuccess();
    } catch {
      onContractAddFailure();
    } finally {
      setIsContractAddPending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    addContract();
  };

  const mileageContractTemplate = useMileageContractTemplate();
  const mileageContractTemplateAbort = useAbort();

  useEffect(() => {
    mileageContractTemplate.load(mileageContractTemplateAbort.signal);
    return () => mileageContractTemplateAbort.revoke();
  }, []);

  useEffect(() => {
    form.setValues(
      mileageContractAddFormHelper.getValuesFromTemplate(
        mileageContractTemplate.data
      )
    );
  }, [mileageContractTemplate.data]);

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.cargoCompanyLabel}
                  isRequired
                  errorMessage={form.validationResults.companyUuid.errorMessage}
                >
                  <CargoCompanySingleSelectComponent
                    placeholder={translations.form.cargoCompanyPlaceholder}
                    cargoCompanyList={cargoCompanyList.data.data}
                    selectedCargoCompanyUuid={form.values.companyUuid}
                    onChange={(value) =>
                      form.setValue("companyUuid", value?.uuid ?? "")
                    }
                    isError={!!form.validationResults.companyUuid.errorMessage}
                    isLoading={cargoCompanyList.isLoading}
                    isDisabled={cargoCompanyList.isError}
                    onBlur={() => form.validate("companyUuid")}
                    idForTesting="mileage-contracts-add-cargo-company"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.periodOfValidityLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.periodOfValidity.errorMessage
                  }
                >
                  <DateRangeInputComponent
                    placeholder={translations.form.periodOfValidityPlaceholder}
                    date={form.values.periodOfValidity}
                    onChange={(value) => {
                      form.setValue("periodOfValidity", value);
                      form.validate("periodOfValidity", value);
                    }}
                    hasError={
                      !!form.validationResults.periodOfValidity.errorMessage
                    }
                    idForTesting="mileage-contracts-add-period-validity"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateCompanyVehicleLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.distanceRateCompanyVehicle
                      .errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.distanceRateCompanyVehiclePlaceholder
                    }
                    value={form.values.distanceRateCompanyVehicle}
                    onChange={(value) =>
                      form.setValue("distanceRateCompanyVehicle", value)
                    }
                    hasError={
                      !!form.validationResults.distanceRateCompanyVehicle
                        .errorMessage
                    }
                    onBlur={() => form.validate("distanceRateCompanyVehicle")}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateCarGt900cm3Label}
                  isRequired
                  errorMessage={
                    form.validationResults.distanceRateCarGt900cm3.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.distanceRateCarGt900cm3Placeholder
                    }
                    value={form.values.distanceRateCarGt900cm3}
                    onChange={(value) =>
                      form.setValue("distanceRateCarGt900cm3", value)
                    }
                    hasError={
                      !!form.validationResults.distanceRateCarGt900cm3
                        .errorMessage
                    }
                    onBlur={() => form.validate("distanceRateCarGt900cm3")}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateCarLe900cm3Label}
                  isRequired
                  errorMessage={
                    form.validationResults.distanceRateCarLe900cm3.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.distanceRateCarLe900cm3Placeholder
                    }
                    value={form.values.distanceRateCarLe900cm3}
                    onChange={(value) =>
                      form.setValue("distanceRateCarLe900cm3", value)
                    }
                    hasError={
                      !!form.validationResults.distanceRateCarLe900cm3
                        .errorMessage
                    }
                    onBlur={() => form.validate("distanceRateCarLe900cm3")}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateMotorcycleLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.distanceRateMotorcycle.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.distanceRateMotorcyclePlaceholder
                    }
                    value={form.values.distanceRateMotorcycle}
                    onChange={(value) =>
                      form.setValue("distanceRateMotorcycle", value)
                    }
                    hasError={
                      !!form.validationResults.distanceRateMotorcycle
                        .errorMessage
                    }
                    onBlur={() => form.validate("distanceRateMotorcycle")}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.distanceRateMopedLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.distanceRateMoped.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={translations.form.distanceRateMopedPlaceholder}
                    value={form.values.distanceRateMoped}
                    onChange={(value) =>
                      form.setValue("distanceRateMoped", value)
                    }
                    hasError={
                      !!form.validationResults.distanceRateMoped.errorMessage
                    }
                    onBlur={() => form.validate("distanceRateMoped")}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.dietFullRateLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.dietFullRate.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={translations.form.dietFullRatePlaceholder}
                    value={form.values.dietFullRate}
                    onChange={(value) => form.setValue("dietFullRate", value)}
                    hasError={
                      !!form.validationResults.dietFullRate.errorMessage
                    }
                    onBlur={() => form.validate("dietFullRate")}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.dietHalfRateLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.dietHalfRate.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={translations.form.dietHalfRatePlaceholder}
                    value={form.values.dietHalfRate}
                    onChange={(value) => form.setValue("dietHalfRate", value)}
                    hasError={
                      !!form.validationResults.dietHalfRate.errorMessage
                    }
                    onBlur={() => form.validate("dietHalfRate")}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.dietFullAfterHoursLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.dietFullAfterHours.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.dietFullAfterHoursPlaceholder
                    }
                    value={form.values.dietFullAfterHours}
                    onChange={(value) =>
                      form.setValue("dietFullAfterHours", value)
                    }
                    hasError={
                      !!form.validationResults.dietFullAfterHours.errorMessage
                    }
                    onBlur={() => {
                      form.validate("dietHalfAfterHours");
                      form.validate("dietFullAfterHours");
                    }}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.dietHalfAfterHoursLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.dietHalfAfterHours.errorMessage
                  }
                >
                  <NumericInputComponent
                    placeholder={
                      translations.form.dietHalfAfterHoursPlaceholder
                    }
                    value={form.values.dietHalfAfterHours}
                    onChange={(value) =>
                      form.setValue("dietHalfAfterHours", value)
                    }
                    hasError={
                      !!form.validationResults.dietHalfAfterHours.errorMessage
                    }
                    onBlur={() => {
                      form.validate("dietHalfAfterHours");
                      form.validate("dietFullAfterHours");
                    }}
                    decimalPrecision={2}
                    idForTesting="mileage-contracts-add-distance-rate"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.form.disabledLabel}
                  isRequired
                  errorMessage={form.validationResults.disabled.errorMessage}
                >
                  <BooleanSelectComponent
                    placeholder={translations.form.disabledPlaceholder}
                    value={!form.values.disabled}
                    onChange={(value) => form.setValue("disabled", !value)}
                    isError={!!form.validationResults.disabled.errorMessage}
                    onBlur={() => form.validate("disabled")}
                    idForTesting="mileage-contracts-add-is-active"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
          <ButtonComponent
            onClick={onSubmitButtonClick}
            type="primary"
            isLoading={isContractAddPending}
            idForTesting="mileage-contracts-add-submit-button"
            classNames={{ root: "mt-4" }}
            title={translations.form.submitButtonTitle}
          >
            {translations.form.submitButtonLabel}
          </ButtonComponent>
        </Column>
      </Row>
    </>
  );
};

export default MileageContractAddComponent;
