import RouteCompletedRouteResponse, {
  RouteCompletedRouteResponseData,
  RouteCompletedRouteResponseItem,
} from "../../../api/raily/route/completed-route/route-completed-route.response";
import RouteCompletedRoute, {
  RouteCompletedRouteItem,
} from "./route-completed-route";

const createDataItem = (
  responseItem: RouteCompletedRouteResponseItem
): RouteCompletedRouteItem => {
  return {
    latitude: responseItem.lat,
    longitude: responseItem.lon,
    recordTime: new Date(responseItem.recordTime),
  };
};

const createData = (
  responseData: RouteCompletedRouteResponseData
): RouteCompletedRouteItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: RouteCompletedRouteResponse): RouteCompletedRoute => {
  return {
    data: createData(response.data),
  };
};

const routeCompletedRouteFactory = { create };

export default routeCompletedRouteFactory;
