import {
  OrderDetailsResponseData,
  OrderDetailsResponseTransportingOrder,
  OrderDetailsResponseTransportingOrderCandidatureEntry,
  OrderDetailsResponseTransportingOrderDriver,
  OrderDetailsResponseTransportingOrderInternalPassenger,
  OrderDetailsResponseTransportingOrderPassenger,
  OrderDetailsResponseTransportingOrderPassengerType,
} from "./api/order-details.response";
import OrderDetailsCargoOrder from "./types/order-details-cargo-order";
import OrderDetailsDriver, {
  OrderDetailsDriverRole,
} from "./types/order-details-driver";
import OrderDetailsPassenger from "./types/order-details-passenger";

const createAssignedDriver = (
  responseDriver: OrderDetailsResponseTransportingOrderDriver
): OrderDetailsDriver => {
  return {
    uuid: responseDriver.id,
    firstName: responseDriver.first_name,
    lastName: responseDriver.last_name,
    displayName: `${responseDriver.first_name} ${responseDriver.last_name}`,
    phoneNumber: `${responseDriver.mobile_primary_prefix} ${responseDriver.mobile_primary}`,
    role: OrderDetailsDriverRole.ASSIGNED,
  };
};

const createCandidateDriver = (
  responseCanidateDriver: OrderDetailsResponseTransportingOrderCandidatureEntry["driver"]
): OrderDetailsDriver => {
  return {
    uuid: responseCanidateDriver.id,
    firstName: responseCanidateDriver.first_name,
    lastName: responseCanidateDriver.last_name,
    displayName: `${responseCanidateDriver.first_name} ${responseCanidateDriver.last_name}`,
    phoneNumber: `${responseCanidateDriver.mobile_primary_prefix} ${responseCanidateDriver.mobile_primary}`,
    role: OrderDetailsDriverRole.CANDIDATE,
  };
};

const createDrivers = (
  responseTransportingOrders: OrderDetailsResponseTransportingOrder[],
  responseCandidateDrivers: OrderDetailsResponseTransportingOrderCandidatureEntry["driver"][]
) => {
  const responseDrivers: OrderDetailsResponseTransportingOrderDriver[] =
    responseTransportingOrders
      .map((order) => order.driver)
      .filter(
        (driver) => driver
      ) as OrderDetailsResponseTransportingOrderDriver[];

  const assignedDrivers = responseDrivers
    ? responseDrivers.map(createAssignedDriver)
    : [];
  const candidateDrivers = responseCandidateDrivers.map(createCandidateDriver);

  const drivers: OrderDetailsDriver[] = [
    ...assignedDrivers,
    ...candidateDrivers,
  ];

  return drivers;
};

const createPassenger = (
  responsePassenger: OrderDetailsResponseTransportingOrderPassenger
): OrderDetailsPassenger => {
  return {
    uuid:
      responsePassenger.item_type ===
      OrderDetailsResponseTransportingOrderPassengerType.EXTERNAL
        ? responsePassenger.cargo_item_id
        : (
            responsePassenger as OrderDetailsResponseTransportingOrderInternalPassenger
          ).passenger_id,
    displayName: `${responsePassenger.first_name} ${responsePassenger.last_name}`,
    phoneNumber: `${responsePassenger.phone_prefix} ${responsePassenger.phone_no}`,
  };
};

const createPassengers = (
  transportingOrders: OrderDetailsResponseTransportingOrder[]
): OrderDetailsPassenger[] => {
  const responsePassengers = transportingOrders
    .map((order) => order.passengers)
    .flat();

  const passengers = responsePassengers.map(createPassenger);

  return passengers;
};

const createBasicInfo = (
  responseCargoOrder: OrderDetailsResponseData
): OrderDetailsCargoOrder => {
  const transportingOrderWithDriver =
    responseCargoOrder.transporting_orders.find(
      (order: OrderDetailsResponseTransportingOrder) => !!order.driver
    );

  const solvedOrderId = transportingOrderWithDriver?.id;
  const routeNumber = transportingOrderWithDriver?.plan_entry_human_id;

  let estimatedStartTime: Date | undefined;

  const firstSolve = responseCargoOrder.transporting_orders[0].solve[0];
  if (Array.isArray(firstSolve)) {
    estimatedStartTime = new Date(firstSolve[0].estimated_time);
  } else if (firstSolve) {
    estimatedStartTime = new Date(firstSolve.estimated_time);
  }

  return {
    cargoOrderId: responseCargoOrder.id,
    cargoCompanyOrderId: responseCargoOrder.human_id,
    cargoCompanyExternalOrderId: responseCargoOrder.external_id,
    changesApprovals: {
      isDispatcherApproveNeeded: !responseCargoOrder.approving_dispatcher_id,
      isOperatorApproveNeeded: !responseCargoOrder.approving_operator_id,
      isDealerApproveNeeded: !responseCargoOrder.approving_dealer_id,
    },
    isCancelRequestSent: responseCargoOrder.is_cancel_requested,
    isCancelled: responseCargoOrder.is_cancelled,
    isFinished: responseCargoOrder.is_finished,
    taxiCorporationUuid: responseCargoOrder.target_taxi_id,
    dispatcherUuid: responseCargoOrder.dispatcher.id,
    isPublished: responseCargoOrder.is_published ?? true,
    solvedOrderId: solvedOrderId,
    transportingOrderUuid: responseCargoOrder.transporting_orders[0].id,
    routeNumber: routeNumber ?? null,
    estimatedStartTime: estimatedStartTime,
  };
};

const orderDetailsFactory = {
  createPassengers,
  createDrivers,
  createBasicInfo,
};

export default orderDetailsFactory;
