import MileageAddressListRequest from "../../api/raily/mileage-address/list/mileage-address-list.request";
import MileageAddressListResponse from "../../api/raily/mileage-address/list/mileage-address-list.response";
import mileageAddressApiService from "../../api/raily/mileage-address/mileage-address-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import MileageAddressListItem from "./list/mileage-address-list";
import MileageAddressListError from "./list/mileage-address-list-error";
import MileageAddressListLoadParams from "./list/mileage-address-list-load-params";
import mileageAddressListRequestFactory from "./list/mileage-address-list-request.factory";
import mileageAddressListFactory from "./list/mileage-address-list.factory";

const handleListError = (
  error: HttpError | MileageAddressListError
): MileageAddressListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: MileageAddressListLoadParams,
  abortSignal: AbortSignal
): Promise<MileageAddressListItem[]> => {
  const request: MileageAddressListRequest =
    mileageAddressListRequestFactory.create(params);

  try {
    const response: MileageAddressListResponse =
      await mileageAddressApiService().getList(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const addressListItems: MileageAddressListItem[] =
      mileageAddressListFactory.create(response);

    return addressListItems;
  } catch (_error) {
    const error = _error as HttpError | MileageAddressListError;

    throw handleListError(error);
  }
};

const mileageAddressService = {
  getList,
};

export default mileageAddressService;
