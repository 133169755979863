import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import FormComponent from "../../../../common/components/form/form.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import SignUpDispatcherFormData from "./common/types/sign-up-dispatcher-form-data";
import { FC, useEffect, useState } from "react";
import SignUpDispatcherDataValidationResults from "./common/types/sign-up-dispatcher-data-validation.results";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import signUpDispatcherValidationService from "./common/sign-up-dispatcher-validation.service";
import PhoneNumberInputComponent from "../../../../common/components/form/input/phone-number/phone-number-input.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import Column from "../../../../common/components/grid/column";
import useQueryParams from "../../../../common/hooks/use-query-params";
import signUpTokenService from "../common/sign-up-token.service";
import SignUpDispatcherResponse from "./common/api/sign-up-dispatcher.response";
import notificationService from "../../../../common/utils/notification/notification.service";
import { useNavigate } from "react-router-dom";
import authRoutesHelper from "../../common/routes/auth-routes.helper";
import SignUpDispatcherDisabledFormFields from "./common/types/sign-up-dispatcher-disabled-form-fields";
import signUpDispatcherFactory from "./common/sign-up-dispatcher.factory";
import signUpDispatcherApiService from "./common/api/sign-up-dispatcher-api.service";
import SignUpDispatcherTokenPayload from "../common/types/sign-up-dispatcher-token-payload";
import phoneNumberInputHelper from "../../../../common/components/form/input/phone-number/phone-number-input.helper";
import Row from "../../../../common/components/grid/row";
import AppLogoHorizontalComponent from "../../../../common/components/app-logo/app-logo-horizontal.component";

const SignUpDispatcherComponent: FC = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [disabledFormFields, setDisabledFormFields] =
    useState<SignUpDispatcherDisabledFormFields>({
      email: false,
      username: false,
      firstName: false,
      lastName: false,
      mobile: false,
      alternativeMobile: false,
    });

  const [formData, setFormData] = useState<SignUpDispatcherFormData>({
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
    mobile: phoneNumberInputHelper.getEmptyValue(),
    alternativeMobile: phoneNumberInputHelper.getEmptyValue(),
    firstName: "",
    lastName: "",
  });
  const { token } = useQueryParams<{ token: string | undefined }>();

  const navigate = useNavigate();

  const [dispatchId, setDispatchId] = useState("");

  const [formValidationResults, setFormValidationResults] =
    useState<SignUpDispatcherDataValidationResults>({
      email: formValidationService.defaultValidationResult,
      username: formValidationService.defaultValidationResult,
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      password: formValidationService.defaultValidationResult,
      passwordRepeat: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
      alternativeMobile: formValidationService.defaultValidationResult,
    });

  const translations = appTranslationsHelper.getDispatcherSignUpTranslations();

  const setEmail = (value: SignUpDispatcherFormData["email"]) =>
    setFormData((curr) => ({ ...curr, email: value }));

  const setPassword = (value: SignUpDispatcherFormData["password"]) =>
    setFormData((curr) => ({ ...curr, password: value }));

  const setPasswordRepeat = (
    value: SignUpDispatcherFormData["passwordRepeat"]
  ) => setFormData((curr) => ({ ...curr, passwordRepeat: value }));

  const setUsername = (value: SignUpDispatcherFormData["username"]) =>
    setFormData((curr) => ({ ...curr, username: value }));

  const setFirstName = (value: SignUpDispatcherFormData["firstName"]) =>
    setFormData((curr) => ({ ...curr, firstName: value }));

  const setLastName = (value: SignUpDispatcherFormData["lastName"]) =>
    setFormData((curr) => ({ ...curr, lastName: value }));

  const setMobile = (value: SignUpDispatcherFormData["mobile"]) =>
    setFormData((curr) => ({ ...curr, mobile: value }));

  const setAlternativeMobile = (
    value: SignUpDispatcherFormData["alternativeMobile"]
  ) => setFormData((curr) => ({ ...curr, alternativeMobile: value }));

  const validateEmail = () => {
    const validationResult = signUpDispatcherValidationService.validateEmail(
      formData.email
    );

    setFormValidationResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const validatePassword = () => {
    const validationResult = signUpDispatcherValidationService.validatePassword(
      formData.password
    );

    setFormValidationResults((curr) => ({
      ...curr,
      password: validationResult,
    }));

    if (!!formData.passwordRepeat) {
      validatePasswordRepeat();
    }

    return validationResult.isValid;
  };

  const validatePasswordRepeat = () => {
    const validationResult =
      signUpDispatcherValidationService.validatePasswordRepeat(
        formData.password,
        formData.passwordRepeat
      );

    setFormValidationResults((curr) => ({
      ...curr,
      passwordRepeat: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUsername = async () => {
    const validationResult =
      await signUpDispatcherValidationService.validateUsername(
        formData.username
      );

    setFormValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateFirstName = () => {
    const validationResult =
      signUpDispatcherValidationService.validateFirstName(formData.firstName);

    setFormValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateLastName = () => {
    const validationResult = signUpDispatcherValidationService.validateLastName(
      formData.lastName
    );

    setFormValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateMobile = () => {
    const validationResult = signUpDispatcherValidationService.validateMobile(
      formData.mobile
    );

    setFormValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAlternativeMobile = () => {
    const validationResult =
      signUpDispatcherValidationService.validateAlternativeMobile(
        formData.alternativeMobile
      );

    setFormValidationResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const onTokenDecodeSuccess = (tokenPayload: SignUpDispatcherTokenPayload) => {
    const defaultFormData =
      signUpDispatcherFactory.createFormData(tokenPayload);
    const defaultFormDataDisabledFields =
      signUpDispatcherFactory.createDisabledFormFields(tokenPayload);

    setFormData(defaultFormData);
    setDisabledFormFields(defaultFormDataDisabledFields);
    setDispatchId(tokenPayload.dispatch_id);
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    try {
      const tokenPayload = signUpTokenService.decode(token);

      if (!signUpTokenService.checkIsDispatcher(tokenPayload)) {
        console.error(`Invalid token type. It is not dispatcher token.`);
        return;
      }

      onTokenDecodeSuccess(tokenPayload);
    } catch (e) {
      console.error(`Invalid token`);
    }
  }, [token]);

  const checkIsUserDataFormValid = async () => {
    const isEmailValid = validateEmail();
    const isUsernameValid = await validateUsername();
    const isPasswordValid = validatePassword();
    const isPasswordRepeatValid = validatePasswordRepeat();
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const isMobileValid = validateMobile();
    const IsAlternativeMobileValid = validateAlternativeMobile();

    const isFormValid =
      isEmailValid &&
      isUsernameValid &&
      isPasswordValid &&
      isPasswordRepeatValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isMobileValid &&
      IsAlternativeMobileValid;

    return isFormValid;
  };

  const navigateToSignInRoute = (username: string) => {
    const signInRoute = authRoutesHelper.getSignInRoute();

    navigate(signInRoute, {
      state: {
        username,
      },
    });
  };

  const onSuccessSignUp = (username: string) => {
    notificationService.success(translations.successSignUpNotificationText);

    navigateToSignInRoute(username);
  };

  const handleSubmitResponse = (response: SignUpDispatcherResponse) => {
    if (response.status === 201) {
      onSuccessSignUp(response.data.username);
    }
  };

  const submitForm = async () => {
    if (!token) {
      console.error(`No token available`);
      return;
    }

    const isFormValid = checkIsUserDataFormValid();

    if (!isFormValid) return;
    setIsFormSubmitting(true);

    const request = signUpDispatcherFactory.createSignUpRequest(
      formData,
      dispatchId
    );

    signUpDispatcherApiService
      .signUpDispatcher(request, token)
      .then(handleSubmitResponse);
  };

  return (
    <div className="sign_up_dispatcher">
      <div className="sign_up_dispatcher__content">
        <div className="sign_up_dispatcher__heading">
          <AppLogoHorizontalComponent className="sign_up_dispatcher__logo" />
          <h1 className="sign_up_dispatcher__title">
            {translations.headerTitle}
          </h1>
        </div>
        <div className="sign_up_dispatcher__form">
          <Row>
            <Column withPaddings>
              <CardComponent>
                <FormComponent onSubmit={submitForm}>
                  <Row>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.emailLabel}
                        isRequired
                        errorMessage={formValidationResults.email.errorMessage}
                      >
                        <InputComponent
                          placeholder={translations.form.emailPlaceholder}
                          value={formData.email}
                          isDisabled={disabledFormFields.email}
                          onChange={setEmail}
                          onBlur={validateEmail}
                          hasError={!!formValidationResults.email.errorMessage}
                          idForTesting="email-input"
                        />
                      </FormFieldComponent>
                    </Column>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.usernameLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.username.errorMessage
                        }
                      >
                        <InputComponent
                          placeholder={translations.form.usernamePlaceholder}
                          isDisabled={disabledFormFields.username}
                          value={formData.username}
                          onChange={setUsername}
                          onBlur={validateUsername}
                          hasError={
                            !!formValidationResults.username.errorMessage
                          }
                          idForTesting="username-input"
                        />
                      </FormFieldComponent>
                    </Column>
                  </Row>
                  <Row>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.firstNameLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.firstName.errorMessage
                        }
                      >
                        <InputComponent
                          placeholder={translations.form.firstNamePlaceholder}
                          value={formData.firstName}
                          isDisabled={disabledFormFields.firstName}
                          onChange={setFirstName}
                          onBlur={validateFirstName}
                          hasError={
                            !!formValidationResults.firstName.errorMessage
                          }
                          idForTesting="first-name-input"
                        />
                      </FormFieldComponent>
                    </Column>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.lastNameLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.lastName.errorMessage
                        }
                      >
                        <InputComponent
                          placeholder={translations.form.lastNamePlaceholder}
                          isDisabled={disabledFormFields.lastName}
                          value={formData.lastName}
                          onChange={setLastName}
                          onBlur={validateLastName}
                          hasError={
                            !!formValidationResults.lastName.errorMessage
                          }
                          idForTesting="last-name-input"
                        />
                      </FormFieldComponent>
                    </Column>
                  </Row>
                  <Row>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.mobileLabel}
                        isRequired
                        errorMessage={formValidationResults.mobile.errorMessage}
                      >
                        <PhoneNumberInputComponent
                          placeholder={translations.form.mobilePlaceholder}
                          phoneNumber={formData.mobile}
                          isDisabled={disabledFormFields.mobile}
                          onChange={setMobile}
                          onBlur={validateMobile}
                          hasError={!!formValidationResults.mobile.errorMessage}
                          idForTesting="mobile"
                        />
                      </FormFieldComponent>
                    </Column>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.alternativeMobileLabel}
                        errorMessage={
                          formValidationResults.alternativeMobile.errorMessage
                        }
                      >
                        <PhoneNumberInputComponent
                          placeholder={
                            translations.form.alternativeMobilePlaceholder
                          }
                          isDisabled={disabledFormFields.alternativeMobile}
                          phoneNumber={formData.alternativeMobile}
                          onChange={setAlternativeMobile}
                          onBlur={validateAlternativeMobile}
                          hasError={
                            !!formValidationResults.alternativeMobile
                              .errorMessage
                          }
                          idForTesting="alternative-mobile"
                        />
                      </FormFieldComponent>
                    </Column>
                  </Row>
                  <Row>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.passwordLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.password.errorMessage
                        }
                      >
                        <InputComponent
                          type="password"
                          placeholder={translations.form.passwordPlaceholder}
                          value={formData.password}
                          onChange={setPassword}
                          onBlur={validatePassword}
                          hasError={
                            !!formValidationResults.password.errorMessage
                          }
                          idForTesting="password-input"
                        />
                      </FormFieldComponent>
                    </Column>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.passwordRepeatLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.passwordRepeat.errorMessage
                        }
                      >
                        <InputComponent
                          type="password"
                          placeholder={
                            translations.form.passwordRepeatPlaceholder
                          }
                          value={formData.passwordRepeat}
                          onChange={setPasswordRepeat}
                          onBlur={validatePasswordRepeat}
                          hasError={
                            !!formValidationResults.passwordRepeat.errorMessage
                          }
                          idForTesting="password-repeat-input"
                        />
                      </FormFieldComponent>
                    </Column>
                  </Row>
                </FormComponent>
              </CardComponent>
              <div className="sign_up_cargo_officer__submit_button_wrapper">
                <ButtonComponent
                  onClick={submitForm}
                  type="primary"
                  isDisabled={isFormSubmitting}
                  idForTesting="submit-button"
                >
                  {translations.formSubmitButton}
                </ButtonComponent>
              </div>
            </Column>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SignUpDispatcherComponent;
