import UserStatus from "../../../types/user-status";

export enum DealerListLoadParamsOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

type DealerListLoadParams = {
  page: number;
  pageSize: number;
  order?: DealerListLoadParamsOrder;
  taxiCorporation?: string;
  taxiCorporationUuid?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  status?: UserStatus;
  creationDateFrom?: Date;
  creationDateTo?: Date;
};

export default DealerListLoadParams;
