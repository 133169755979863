import MileageDictionaryListRequest from "../../../api/raily/mileage-dictionary/list/mileage-dictionary-list.request";
import MileageDictionaryListLoadParams from "./mileage-dictionary-list-load-params";

const create = (
  params: MileageDictionaryListLoadParams
): MileageDictionaryListRequest => {
  return {
    limit: params.limit,
    offset: params.offset,
    cargoCompanyUuid: params.cargoCompanyUuid,
    active: params.active,
  };
};

const MileageDictionaryListRequestFactory = {
  create,
};

export default MileageDictionaryListRequestFactory;
