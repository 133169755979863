import { MileageDictionaryListItem } from "../../../../../../common/services/mileage-dictionary/list/mileage-dictionary-list";
import MileageDictionaryListingItem from "../types/mileage-dictionary-listing-item";

const createItem = (
  item: MileageDictionaryListItem
): MileageDictionaryListingItem => {
  return {
    active: item.active,
    name: item.name,
    uuid: item.uuid,
  };
};

const create = (
  data: MileageDictionaryListItem[]
): MileageDictionaryListingItem[] => {
  return data.map(createItem);
};

const mileageDictionaryListingItemFactory = {
  create,
};

export default mileageDictionaryListingItemFactory;
