import { useEffect, useMemo, useState } from "react";
import CardComponent from "../../../common/components/card/card.component";
import HeadingComponent from "../../../common/components/heading/heading.component";
import useOrderOptimizerList from "../../../common/services/order-optimizer/list/use-order-optimizer-list";
import orderOptimizerListingItemFactory from "./common/order-optimizer-listing-item.factory";
import OrderOptimizerListingItem from "./common/types/order-optimizer-listing-item";
import OrderOptimizerListingTableComponent from "./table/order-optimizer-listing-table.component";
import orderOptimizerBreadcrumbsHelper from "../common/breadcrumbs/order-optimizer-breadcrumbs.helper";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import orderOptimizerTranslationsHelper from "../../../languages/order-optimizer-translations.helper";
import useAbort from "../../../common/hooks/use-abort";
import OrderOptimizerListLoadParams from "../../../common/services/order-optimizer/list/order-optimizer-list-load-paramts";
import OrderOptimizerListingFiltersComponent from "./filters/order-optimizer-listing-filters.component";
import useOrderOptimizerListingFiltersDao from "./filters/use-order-optimizer-listing-filters.dao";
import OrderOptimizerListingFilter, {
  OrderOptimizerListingOrderHumanIdFilter,
} from "./common/types/order-optimizer-listing-filter";
import orderOptimizerListingLoadParamsFactory from "./common/order-optimizer-listing-load-params.factory";
import useIsComponentMounted from "../../../common/hooks/use-is-component-mounted";
import OrderOptimizerListingFilterType from "./common/types/order-optimizer-listing-filter-type";

const OrderOptimizerListingComponent = () => {
  const orderOptimizerList = useOrderOptimizerList();
  const orderOptimizerListAbort = useAbort();
  const isComponentMounted = useIsComponentMounted();

  const filtersDao = useOrderOptimizerListingFiltersDao();

  const [filters, setFilters] = useState<OrderOptimizerListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const selectedHumanId = useMemo(() => {
    const filterValue = filters.find(
      (filter) => filter.type === OrderOptimizerListingFilterType.ORDER_HUMAN_ID
    )?.value as string | undefined;

    return Number.parseInt(filterValue ?? "");
  }, [filters]);

  const loadListingItems = () => {
    const params: OrderOptimizerListLoadParams =
      orderOptimizerListingLoadParamsFactory.create(filters);

    orderOptimizerList.load(params, orderOptimizerListAbort.signal);
  };

  const saveFilters = () => {
    filtersDao.saveFilters(filters);
  };

  useEffect(() => {
    if (!isComponentMounted && !filters.length) {
      return;
    }

    saveFilters();
    loadListingItems();

    return orderOptimizerListAbort.revoke;
  }, [filters]);

  const translations =
    orderOptimizerTranslationsHelper.getListingTranslations();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs = orderOptimizerBreadcrumbsHelper.getListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderOptimizerListing);

  const listingItems: OrderOptimizerListingItem[] = useMemo(() => {
    return orderOptimizerListingItemFactory.createListingItems(
      orderOptimizerList.data.data
    );
  }, [orderOptimizerList.data.data]);

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <OrderOptimizerListingFiltersComponent
        filters={filters}
        isListingLoading={orderOptimizerList.isLoading}
        onFiltersChange={(filters) => {
          setFilters(filters);
        }}
      />
      <CardComponent>
        <OrderOptimizerListingTableComponent
          listingItems={listingItems}
          selectedHumanId={selectedHumanId}
          isError={orderOptimizerList.isError}
          isLoading={orderOptimizerList.isLoading}
        />
      </CardComponent>
    </>
  );
};

export default OrderOptimizerListingComponent;
