import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import OperatorListingFilter from "./common/types/operator-listing-filter";
import OperatorListingItem from "./common/types/operator-listing-item";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import OperatorListingFiltersComponent from "./filters/operator-listing-filters.component";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import OperatorListingTableComponent from "./table/operator-listing-table.component";
import useAbort from "../../../../common/hooks/use-abort";
import operatorListingLoadParamsFactory from "../../operator/listing/common/operator-listing-load-params.factory";
import usePagination from "../../../../common/hooks/use-pagination";
import operatorListingItemFactory from "./common/operator-listing-item.factory";
import useOperatorListingFiltersDao from "./filters/use-operator-listing-filters.dao";
import OperatorListingSortKey from "./common/types/operator-listing-sort-key";
import useOperatorListingUserPermissions from "./common/user-permissions/use-operator-listing-user-permissions";
import UserDeleteUserData from "../../common/delete/common/types/user-delete-user-data";
import UserDeleteComponent from "../../common/delete/user-delete.component";
import OperatorListLoadParams from "../../../../common/services/operator/list/operator-list-load-params";
import useOperatorList from "../../../../common/services/operator/list/use-operator-list";

type OperatorListingProps = {};

const OperatorListingComponent: FC<OperatorListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();

  const userPermissions = useOperatorListingUserPermissions();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userOperatorListing);

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getOperatorListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const operatorList = useOperatorList();

  const listingItemsAbort = useAbort();

  const loadListingItems = async () => {
    const params: OperatorListLoadParams =
      operatorListingLoadParamsFactory.create(page, pageSize, filters, sortKey);

    operatorList.load(params, listingItemsAbort.signal);
  };

  const listingItems: OperatorListingItem[] = useMemo(() => {
    return operatorListingItemFactory.createListingItems(
      operatorList.data.data
    );
  }, [operatorList.data.data]);

  const filtersDao = useOperatorListingFiltersDao();

  const [sortKey, setSortKey] = useState<OperatorListingSortKey>(
    () => filtersDao.getSortKey() ?? OperatorListingSortKey.FIRST_NAME_ASC
  );

  const [filters, setFilters] = useState<OperatorListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: operatorList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const translations = userTranslationsHelper.getOperatorListingTranslations();

  const [userSelectedToDelete, setUserSelectedToDelete] =
    useState<UserDeleteUserData | null>(null);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const openDeleteUserModal = () => {
    setIsDeleteUserModalOpen(true);
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onSelectUserToDelete = (userData: UserDeleteUserData) => {
    setUserSelectedToDelete(userData);
    openDeleteUserModal();
  };

  const onDeleteUserSuccess = () => {
    closeDeleteUserModal();
    loadListingItems();
  };

  const OperatorAddLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={userRoutesHelper.getOperatorAddRoute()}
        title={translations.header.addNewOperatorLinkTitle}
        idForTesting="operator-listing-add-button"
      >
        {translations.header.addNewOperatorLinkLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasVisibleAddOperatorButton
        ? OperatorAddLinkButton
        : undefined,
    ];
  };

  const headingActions = createHeadingActions();

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <OperatorListingFiltersComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <OperatorListingTableComponent
          listingItems={listingItems}
          isError={operatorList.isError}
          isLoading={operatorList.isLoading}
          onSelectUserToDelete={onSelectUserToDelete}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={operatorList.data.totalCount}
        />
      </CardComponent>
      <UserDeleteComponent
        isOpen={isDeleteUserModalOpen}
        onClose={closeDeleteUserModal}
        userData={userSelectedToDelete}
        onSuccess={onDeleteUserSuccess}
      />
    </>
  );
};

export default OperatorListingComponent;
