import appTranslationsHelper from "./app-translations.helper";
import DelegationAddTranslations from "./types/delegation/delegation-add.translations";
import DelegationDeleteTranslations from "./types/delegation/delegation-delete.translations";
import DelegationDetailsTranslations from "./types/delegation/delegation-details.translations";
import DelegationListingTranslations from "./types/delegation/delegation-listing.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `delegation`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getDelegationListingTranslations = (): DelegationListingTranslations => {
  const translations = getTranslations<DelegationListingTranslations>(
    `delegation-listing.translations.json`
  );

  return translations;
};

const getDelegationAddTranslations = (): DelegationAddTranslations => {
  const translations = getTranslations<DelegationAddTranslations>(
    `delegation-add.translations.json`
  );

  return translations;
};

const getDelegationDeleteTranslations = (): DelegationDeleteTranslations => {
  const translations = getTranslations<DelegationDeleteTranslations>(
    `delegation-delete.translations.json`
  );

  return translations;
};

const getDelegationDetailsTranslations = (): DelegationDetailsTranslations => {
  const translations = getTranslations<DelegationDetailsTranslations>(
    `delegation-details.translations.json`
  );

  return translations;
};

const delegationTranslationsHelper = {
  getDelegationListingTranslations,
  getDelegationAddTranslations,
  getDelegationDeleteTranslations,
  getDelegationDetailsTranslations,
};

export default delegationTranslationsHelper;
