import MileageContractListLoadParams, {
  MileageContractListLoadParamsOrder,
} from "../../../../../common/services/mileage-contract/list/mileage-contract-list-load-params";
import MileageContractListingFilter from "./types/mileage-contract-listing-filter";
import MileageContractListingFilterType from "./types/mileage-contract-listing-filter-type";
import MileageContractListingSortKey from "./types/mileage-contract-listing-sort-key";

const createOrder = (
  sortKey: MileageContractListingSortKey | null
): MileageContractListLoadParamsOrder | undefined => {
  switch (sortKey) {
    case MileageContractListingSortKey.COMPANY_NAME_ASC:
      return MileageContractListLoadParamsOrder.COMPANY_NAME_ASC;
    case MileageContractListingSortKey.COMPANY_NAME_DESC:
      return MileageContractListLoadParamsOrder.COMPANY_NAME_DESC;
    case MileageContractListingSortKey.VALID_SINCE_ASC:
      return MileageContractListLoadParamsOrder.VALID_SINCE_ASC;
    case MileageContractListingSortKey.VALID_SINCE_DESC:
      return MileageContractListLoadParamsOrder.VALID_SINCE_DESC;
    case MileageContractListingSortKey.VALID_TO_ASC:
      return MileageContractListLoadParamsOrder.VALID_TO_ASC;
    case MileageContractListingSortKey.VALID_TO_DESC:
      return MileageContractListLoadParamsOrder.VALID_TO_DESC;
    default:
      return undefined;
  }
};

const create = (
  page: number,
  pageSize: number,
  filters: MileageContractListingFilter[],
  sortKey: MileageContractListingSortKey | null
): MileageContractListLoadParams => {
  return {
    page: page,
    pageSize: pageSize,
    order: createOrder(sortKey),
    cargoCompanyName:
      filters.find(
        (filter) =>
          filter.type == MileageContractListingFilterType.CARGO_COMPANY
      )?.value ?? undefined,
  };
};

const mileageContractListingLoadParamsFactory = {
  create,
};

export default mileageContractListingLoadParamsFactory;
