import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import SignUpDriverWizardFormStep from "./sign-up-driver-wizard-form-step";
import SignUpDriverFormStep from "../../types/sign-up-driver-form-step";

type SignUpDriverWizardFormProps = {
  steps: SignUpDriverWizardFormStep[];
  selectedStepKey: SignUpDriverFormStep;
  passedStepsKeys: SignUpDriverFormStep[];
};

const SignUpDriverWizardFormComponent: FC<SignUpDriverWizardFormProps> = (
  props
) => {
  const Component = props.steps.find(
    (step) => step.key === props.selectedStepKey
  )?.component ?? <></>;

  return (
    <>
      <div className="sign_up_driver_wizard_form">
        <div className="sign_up_driver_wizard_form__line"></div>
        <div className="sign_up_driver_wizard_form__options">
          {props.steps.map((step, index) => {
            const isPassed = props.passedStepsKeys.includes(step.key);
            const isCurrentStep = props.selectedStepKey.includes(step.key);
            const previousStep: SignUpDriverWizardFormStep | undefined =
              props.steps[index - 1];
            const isPreviousStepPassed = previousStep
              ? props.passedStepsKeys.includes(previousStep.key)
              : false;

            const isDisabled =
              !isCurrentStep && !isPassed && !isPreviousStepPassed;

            const isActive = isCurrentStep;

            return (
              <div
                className="sign_up_driver_wizard_form__option"
                key={step.key}
              >
                <button
                  className={classNames(
                    "sign_up_driver_wizard_form__button",
                    isPassed && "passed",
                    isActive && "active"
                  )}
                  disabled={isDisabled}
                  onClick={step.onSelect}
                >
                  <FontAwesomeIcon
                    icon={step.icon}
                    size="lg"
                    className={classNames(
                      "sign_up_driver_wizard_form__button--icon",
                      isPassed && "passed"
                    )}
                  />
                </button>
                <div className="sign_up_driver_wizard_form__option__description">
                  {step.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {Component}
    </>
  );
};

export default SignUpDriverWizardFormComponent;
