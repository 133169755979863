import { CargoCompanyListItem } from "../../../../common/services/cargo-company/cargo-company/list/cargo-company-list";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageSettingsCargoCompanySelectOption from "../types/mileage-settings-cargo-company-select-option";
import MileageSettingsMileageNumberCreationModel from "../types/mileage-settings-mileage-number-creation-model";
import MileageSettingsMileageNumberCreationModelSelectOption from "../types/mileage-settings-mileage-number-creation-model-select-option";

const createCreationModelLabel = (
  model: MileageSettingsMileageNumberCreationModel
): string => {
  const translations =
    mileageTranslationsHelper.getMileageSettingsTranslations().creationModel;

  switch (model) {
    case MileageSettingsMileageNumberCreationModel.AUTO:
      return translations.modelAutoLabel;
    case MileageSettingsMileageNumberCreationModel.DICT:
      return translations.modelDictionaryLabel;
    case MileageSettingsMileageNumberCreationModel.MANUAL:
      return translations.modelManualLabel;
  }
};

const createMileageNumberCreationModelSelectOption = (
  model: MileageSettingsMileageNumberCreationModel
): MileageSettingsMileageNumberCreationModelSelectOption => {
  return {
    label: createCreationModelLabel(model),
    value: model,
    idForTesting: `mileage-settings-mileage-number-creation-model-${String(
      model
    )}-select-option`,
  };
};

const createMileageNumberCreationModelSelectOptions =
  (): MileageSettingsMileageNumberCreationModelSelectOption[] => {
    return Object.keys(MileageSettingsMileageNumberCreationModel).map((value) =>
      createMileageNumberCreationModelSelectOption(
        value as MileageSettingsMileageNumberCreationModel
      )
    );
  };

const createCargoCompanySelectOption = (
  responseCargoCompany: CargoCompanyListItem
): MileageSettingsCargoCompanySelectOption => {
  return {
    label: responseCargoCompany.displayName,
    value: {
      uuid: responseCargoCompany.uuid,
      displayName: responseCargoCompany.displayName,
    },
  };
};

const createCargoCompanySelectOptions = (
  responseCargoCompanies: CargoCompanyListItem[]
): MileageSettingsCargoCompanySelectOption[] => {
  return responseCargoCompanies.map(createCargoCompanySelectOption);
};

const mileageSettingsFactory = {
  createMileageNumberCreationModelSelectOptions,
  createMileageNumberCreationModelSelectOption,
  createCargoCompanySelectOptions,
};

export default mileageSettingsFactory;
