import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import addressAddModalValidationService from "./address-add-modal-validation.service";
import AddressAddModalData from "./types/address-add-modal-data";

const getDefaultFormData = (): AddressAddModalData => {
  return {
    foundAddress: null,
    street: "",
    houseNumber: "",
    apartmentNumber: "",
    zipCode: "",
    town: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<AddressAddModalData> => {
    return {
      foundAddress: () => formValidationService.defaultValidationResult,
      street: (values) =>
        addressAddModalValidationService.validateStreet(values.street),
      houseNumber: (values) =>
        addressAddModalValidationService.validateHouseNumber(
          values.houseNumber
        ),
      apartmentNumber: (values) =>
        addressAddModalValidationService.validateApartmentNumber(
          values.apartmentNumber
        ),
      zipCode: (values) =>
        addressAddModalValidationService.validateZipCode(values.zipCode),
      town: (values) =>
        addressAddModalValidationService.validateTown(values.town),
    };
  };

const addressAddModalHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default addressAddModalHelper;
