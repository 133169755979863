import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type OrderDetailsHistoryUserPermissionDefinitionOption =
  "hasAccessToHandlingLog";

export type OrderDetailsHistoryUserPermissionsDefinition =
  UserPermissionsDefinition<OrderDetailsHistoryUserPermissionDefinitionOption>;

export type OrderDetailsHistoryUserPermissions =
  UserPermissions<OrderDetailsHistoryUserPermissionDefinitionOption>;

const orderDetailsHistoryUserPermissionDefinition: OrderDetailsHistoryUserPermissionsDefinition =
  {
    hasAccessToHandlingLog: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.OPERATOR,
      UserRole.TAXI_OFFICER,
    ],
  };

export default orderDetailsHistoryUserPermissionDefinition;
