import Joi from "joi";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import MileageContractAddFormData from "./common/types/mileage-contract-add-form-data";
import formValidationService from "../../../../common/utils/validation/form-validation.service";

const validateDistanceRateCompanyVehicle = (
  value: MileageContractAddFormData["distanceRateCompanyVehicle"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRateCarGt900cm3 = (
  value: MileageContractAddFormData["distanceRateCarGt900cm3"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRateCarLe900cm3 = (
  value: MileageContractAddFormData["distanceRateCarLe900cm3"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRateMotorcycle = (
  value: MileageContractAddFormData["distanceRateMotorcycle"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRateMoped = (
  value: MileageContractAddFormData["distanceRateMoped"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietFullRate = (
  value: MileageContractAddFormData["dietFullRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietFullAfterHours = (
  value: MileageContractAddFormData["dietFullAfterHours"],
  limit: MileageContractAddFormData["dietHalfAfterHours"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(limit).less(24).required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietHalfRate = (
  value: MileageContractAddFormData["dietHalfRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietHalfAfterHours = (
  value: MileageContractAddFormData["dietHalfAfterHours"],
  limit: MileageContractAddFormData["dietFullAfterHours"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(limit).required();

  return formValidationService.validate(value, validationSchema);
};

const validatePeriodOfValidity = (
  value: MileageContractAddFormData["periodOfValidity"]
): FormValidationResult => {
  const validationSchema = Joi.object<
    MileageContractAddFormData["periodOfValidity"]
  >()
    .keys({
      from: Joi.date().min("1970-01-01").required(),
      to: Joi.date().required(),
    })
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateCompanyUuid = (
  value: MileageContractAddFormData["companyUuid"]
): FormValidationResult => {
  const validationSchema = Joi.string().required();

  return formValidationService.validate(value, validationSchema);
};

const validateDisabled = (
  value: MileageContractAddFormData["disabled"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const MileageContractAddFormValidationService = {
  validateDistanceRateCompanyVehicle,
  validateDistanceRateCarGt900cm3,
  validateDistanceRateCarLe900cm3,
  validateDistanceRateMotorcycle,
  validateDistanceRateMoped,
  validateDietFullRate,
  validateDietFullAfterHours,
  validateDietHalfRate,
  validateDietHalfAfterHours,
  validateCompanyUuid,
  validatePeriodOfValidity,
  validateDisabled,
};

export default MileageContractAddFormValidationService;
