import queryString from "query-string";

const build = (
  url: string,
  queryParams: { [key: string]: string | string[] } = {}
): string => queryString.stringifyUrl({ url, query: queryParams });

const buildWithoutEmptyParams = (
  baseUrl: string,
  queryParams: { [key: string]: any } = {}
): string => {
  const newParams: { [key: string]: any } = {};

  const queryParamsKeys = Object.keys(queryParams);

  for (const key of queryParamsKeys) {
    const parametrValue = queryParams[key];
    if (
      parametrValue === null ||
      parametrValue === undefined ||
      parametrValue === "" ||
      (Array.isArray(parametrValue) && parametrValue.length === 0)
    ) {
      continue;
    }

    newParams[key] = parametrValue;
  }

  const url = build(baseUrl, newParams);

  return url;
};

const parse = (query: string) => {
  return queryString.parse(query);
};

const urlService = {
  build,
  buildWithoutEmptyParams,
  parse,
};

export default urlService;
