import jwtService from "../../../../common/utils/jwt/jwt.service";
import SignUpCargoOfficerTokenPayload from "./types/sign-up-cargo-officer-token-payload";
import SignUpDealerTokenPayload from "./types/sign-up-dealer-token-payload";
import SignUpDispatcherTokenPayload from "./types/sign-up-dispatcher-token-payload";
import SignUpDriverTokenPayload from "./types/sign-up-driver-token-payload";
import SignUpPassengerTokenPayload from "./types/sign-up-passenger-token-payload";
import SignUpRailyOfficerTokenPayload from "./types/sign-up-raily-officer-token-payload";
import SignUpTaxiOfficerTokenPayload from "./types/sign-up-taxi-officer-token-payload";
import SignUpTokenPayload from "./types/sign-up-token-payload";
import SignUpTokenUserRole from "./types/sign-up-token-user-role";

const decode = (token: string): SignUpTokenPayload | null => {
  try {
    return jwtService.decode<SignUpTokenPayload>(token);
  } catch (e) {
    return null;
  }
};

const checkIsCargoOfficer = (
  tokenPayload: SignUpTokenPayload | null
): tokenPayload is SignUpCargoOfficerTokenPayload => {
  return tokenPayload?.user_role === SignUpTokenUserRole.CARGO_OFFICER;
};

const checkIsDealer = (
  tokenPayload: SignUpTokenPayload | null
): tokenPayload is SignUpDealerTokenPayload => {
  return tokenPayload?.user_role === SignUpTokenUserRole.DEALER;
};

const checkIsDispatcher = (
  tokenPayload: SignUpTokenPayload | null
): tokenPayload is SignUpDispatcherTokenPayload => {
  return tokenPayload?.user_role === SignUpTokenUserRole.DISPATCHER;
};

const checkIsDriver = (
  tokenPayload: SignUpTokenPayload | null
): tokenPayload is SignUpDriverTokenPayload => {
  return tokenPayload?.user_role === SignUpTokenUserRole.DRIVER;
};

const checkIsPassenger = (
  tokenPayload: SignUpTokenPayload | null
): tokenPayload is SignUpPassengerTokenPayload => {
  return tokenPayload?.user_role === SignUpTokenUserRole.PASSENGER;
};

const checkIsRailyOfficer = (
  tokenPayload: SignUpTokenPayload | null
): tokenPayload is SignUpRailyOfficerTokenPayload => {
  return tokenPayload?.user_role === SignUpTokenUserRole.RAILY_OFFICER;
};

const checkIsTaxiOfficer = (
  tokenPayload: SignUpTokenPayload | null
): tokenPayload is SignUpTaxiOfficerTokenPayload => {
  return tokenPayload?.user_role === SignUpTokenUserRole.TAXI_OFFICER;
};

const signUpTokenService = {
  decode,
  checkIsCargoOfficer,
  checkIsDealer,
  checkIsDispatcher,
  checkIsDriver,
  checkIsPassenger,
  checkIsRailyOfficer,
  checkIsTaxiOfficer,
};

export default signUpTokenService;
