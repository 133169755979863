import { FC } from "react";
import TableComponent from "../../../../../common/components/table/table.component";
import RelatedBillingsTaxiDriverTableRow from "./types/related-billings-taxi-driver-table-row";
import relatedBillingsTaxiDriverHelper from "./related-billings-taxi-driver.helper";

type RelatedBillingsTaxiDriverTableProps = {
  tableRows: RelatedBillingsTaxiDriverTableRow[];
  isLoading: boolean;
};

const RelatedBillingsTaxiDriverTableComponent: FC<
  RelatedBillingsTaxiDriverTableProps
> = (props) => {
  const tableColumns = relatedBillingsTaxiDriverHelper.createTableColumns();

  return (
    <TableComponent
      isLoading={props.isLoading}
      columns={tableColumns}
      rows={props.tableRows}
    />
  );
};

export default RelatedBillingsTaxiDriverTableComponent;
