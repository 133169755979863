import DriverDetailsRequest from "../../../api/raily/driver/details/driver-details.request";
import DriverDetailsLoadParams from "./driver-details-load-params";

const create = (params: DriverDetailsLoadParams): DriverDetailsRequest => {
  return {
    driverId: params.driverUuid,
  };
};

const driverDetailsRequestFactory = {
  create,
};

export default driverDetailsRequestFactory;
