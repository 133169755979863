import { FC } from "react";
import taxiUnsettledOrderListingFiltersBadgeListHelper from "./taxi-unsettled-order-listing-filters-badge-list.helper";
import TaxiUnsettledOrderListingFilter from "../../types/taxi-unsettled-order-listing-filter";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type TaxiUnsettledOrderListingFiltersListProps = {
  filters: TaxiUnsettledOrderListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const TaxiUnsettledOrderListingFiltersBadgeListComponent: FC<
  TaxiUnsettledOrderListingFiltersListProps
> = (props) => {
  const badges = taxiUnsettledOrderListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default TaxiUnsettledOrderListingFiltersBadgeListComponent;
