import { FC } from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsCopyComponentProps = {
  details: OrderDetailsCargoOrder | null;
  tools: OrderDetailsToolsTranslations;
  onSuccess: () => void;
};

const OrderDetialsCopyComponent: FC<OrderDetialsCopyComponentProps> = ({
  details,
  tools,
  onSuccess,
}) => {
  const { isFinished, isCancelled } = details!;

  if (!(!isFinished && !isCancelled)) return null;

  const { copyDetailsButtonTitle } = tools;

  return (
    <OrderDetailsToolButtonComponent
      onClick={onSuccess}
      icon={faCopy}
      title={copyDetailsButtonTitle}
    />
  );
};

export default OrderDetialsCopyComponent;
