import HttpResponse from "../../../../utils/http/http.response";

export enum TaxiContractListResponseItemBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export enum TaxiContractListResponseItemTaxiCorporationContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type TaxiContractListResponseItemTaxiCorporationFleetPartner = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  taxiCorporationId: string;
  name: string;
};

export type TaxiContractListResponseItemTaxiCorporation = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: TaxiContractListResponseItemTaxiCorporationContractMaintenancePolicy;
  notes: string;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  fleetPartners: TaxiContractListResponseItemTaxiCorporationFleetPartner[];
};

export type TaxiContractListResponseItem = {
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: TaxiContractListResponseItemBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  id: string;
  producerTaxiId: string;
  producerTaxi: TaxiContractListResponseItemTaxiCorporation;
  consumerTaxiId: string;
  consumerTaxi: TaxiContractListResponseItemTaxiCorporation;
  displayName: string | null;
  isPartner: boolean;
  priority: number;
  isValid: boolean;
};

type TaxiTaxiContractListResponse = HttpResponse<{
  data: TaxiContractListResponseItem[];
  totalCount: number;
}>;

export default TaxiTaxiContractListResponse;
