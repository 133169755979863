import { FC, useState } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import notificationService from "../../../../../common/utils/notification/notification.service";
import useOrderDetialsForwarding from "../../modals/use-order-details-forwarding";
import transportingOrderService from "../../../../../common/services/transporting-order/transporting-order.service";
import useAbort from "../../../../../common/hooks/use-abort";
import TransportingOrderDeleteForwardingError from "../../../../../common/services/transporting-order/delete-forwarding/transporting-order-delete-forwarding-error";

type OrderDetailsCancelForwardingModalComponentProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  transportingOrderId: string | undefined;
  sumbitDisabledText: string;
};

const OrderDetailsCancelForwardingModalComponent: FC<
  OrderDetailsCancelForwardingModalComponentProps
> = ({ isOpen, transportingOrderId, sumbitDisabledText, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { forwardingId } = useOrderDetialsForwarding({
    transportingOrderId,
  });
  const deleteForwardingOrderAbort = useAbort();

  const translations = orderTranslationsHelper.getDetailsTranslations();
  const { cancelForwarding } = translations;

  const { confirmationModal, successCancelForwardingNotificationText } =
    cancelForwarding;
  const {
    headingText,
    confirmationButtonTitle,
    confirmationButtonText,
    contentMessageTemplateText,
    rejectButtonTitle,
    rejectButtonText,
  } = confirmationModal;

  const handleCancelForwardingResponse = () => {
    notificationService.success(successCancelForwardingNotificationText);
    onClose();
  };

  const handleCancelForwardingFailure = (
    error: TransportingOrderDeleteForwardingError
  ) => {
    notificationService.error(error.message);
    onClose();
  };

  const confirmHandler = async () => {
    setIsLoading(true);

    try {
      await transportingOrderService.deleteForwarding(
        {
          orderUuid: transportingOrderId!,
          forwardingUuid: forwardingId,
        },
        deleteForwardingOrderAbort.signal
      );

      handleCancelForwardingResponse();
    } catch (error) {
      handleCancelForwardingFailure(
        error as TransportingOrderDeleteForwardingError
      );
    } finally {
      setIsLoading(false);
    }
  };

  const sumbitButtonTitle = forwardingId
    ? confirmationButtonTitle
    : sumbitDisabledText;

  return (
    <ModalComponent
      header={{ title: headingText }}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <ButtonComponent
          type="danger"
          onClick={confirmHandler}
          title={sumbitButtonTitle}
          isLoading={isLoading}
          isDisabled={!forwardingId}
        >
          {confirmationButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={onClose}
          title={rejectButtonTitle}
        >
          {rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      {contentMessageTemplateText}
    </ModalComponent>
  );
};

export default OrderDetailsCancelForwardingModalComponent;
