import DecodedAccessToken from "./types/decoded-access-token";
import jwt_decode from "jwt-decode";

const accessTokenKey = "ACCESS_TOKEN";
const accessTokenExpirationTimeKey = "ACCESS_TOKEN_EXP_TIME";

const setAccessToken = (token: string): void => {
  localStorage.setItem(accessTokenKey, token);
};

const removeAccessToken = () => {
  localStorage.removeItem(accessTokenKey);
};

const getAccessToken = () => {
  return localStorage.getItem(accessTokenKey);
};

const getAccessTokenExpirationTime = (): number | undefined => {
  const value = localStorage.getItem(accessTokenExpirationTimeKey);

  if (!value) {
    return undefined;
  }

  return Number(value);
};

const setAccessTokenExpirationTime = (value: number): void => {
  localStorage.setItem(accessTokenExpirationTimeKey, String(value));
};

const removeAccessTokenExpirationTime = (): void => {
  localStorage.removeItem(accessTokenExpirationTimeKey);
};

const checkIsAccessTokenValid = (): boolean => {
  const accessToken = getAccessToken();

  const accessTokenExpirationTime = getAccessTokenExpirationTime();

  const isTokenExpired =
    !!accessTokenExpirationTime &&
    accessTokenExpirationTime < new Date().getTime();

  return !!accessToken && !!accessTokenExpirationTime && !isTokenExpired;
};

const getAccessTokenDecodedContent = (
  accessToken?: string
): DecodedAccessToken | null => {
  const token = accessToken ?? getAccessToken();

  if (!token) {
    return null;
  }

  const decodedToken = jwt_decode(token) as DecodedAccessToken;

  return decodedToken;
};

const setAccessCredentials = (accessToken: string) => {
  const decodedToken = jwt_decode(accessToken) as DecodedAccessToken;

  const tokenExpirationTime = decodedToken.exp * 1000;

  setAccessToken(accessToken);
  setAccessTokenExpirationTime(tokenExpirationTime);
};

const authService = {
  setAccessToken,
  getAccessToken,
  getAccessTokenDecodedContent,
  removeAccessToken,
  getAccessTokenExpirationTime,
  setAccessTokenExpirationTime,
  removeAccessTokenExpirationTime,
  checkIsAccessTokenValid,
  setAccessCredentials,
};

export default authService;
