import { FC, useEffect, useState } from "react";
import taxiOrderSettlementListingHelper from "../taxi-order-settlement-listing.helper";

import taxiOrderSettlementListingApiService from "../api/taxi-order-settlement-listing-api.service";
import TaxiOrderSettlementListingStatusCountersResponse, {
  TaxiOrderSettlementListingStatusCountersResponseData,
} from "../api/taxi-order-settlement-listing-status-counters.response";
import { useAppContext } from "../../../../../../context/app.context";
import StatusLegendComponent from "../../../../../../common/components/status-legend/status-legend.component";

type TaxiOrderSettlementListingStatusLegendProps = {
  taxiCorporationUuid: string | null;
};

const TaxiOrderSettlementListingStatusLegendComponent: FC<
  TaxiOrderSettlementListingStatusLegendProps
> = (props) => {
  const [countersResponse, setCountersResponse] = useState<
    TaxiOrderSettlementListingStatusCountersResponseData | undefined
  >();

  useAppContext();

  useEffect(() => {
    taxiOrderSettlementListingApiService
      .fetchStatusCounters({
        taxi_corporation_id: props.taxiCorporationUuid ?? undefined,
      })
      .then(handleStatusCountersResponse);
  }, [props.taxiCorporationUuid]);

  const handleStatusCountersResponse = (
    response: TaxiOrderSettlementListingStatusCountersResponse
  ) => {
    setCountersResponse(response.data);
  };

  const statusOptions =
    taxiOrderSettlementListingHelper.getBillingStatusOptions(countersResponse);

  return <StatusLegendComponent statusData={statusOptions} />;
};

export default TaxiOrderSettlementListingStatusLegendComponent;
