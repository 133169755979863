import { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PassengerEditFormData from "./common/types/passenger-edit-form-data";
import PassengerEditFormValidationResults from "./common/types/passenger-edit-form-validation.results";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import PassengerEditHelper from "./common/passenger-edit.helper";
import ButtonComponent from "../../../../common/components/button/button.component";
import PhoneNumberInputComponent from "../../../../common/components/form/input/phone-number/phone-number-input.component";
import PassengerFormDataAddressComponent from "../common/components/address/passenger-address.component";
import PassengerAddress from "../common/types/passenger-address";
import passengerEditFormValidationService from "./common/passenger-edit-form-validation.servicce";
import UserPassengerEditRouteParams from "../../common/routes/types/user-passenger-edit-route-params";
import notificationService from "../../../../common/utils/notification/notification.service";
import passengerEditFactory from "./common/factory/passenger-edit.factory";
import passengerEditApiService from "./common/api/passenger-edit-api.service";
import PassengerEditUpdateResponse from "./common/api/types/passenger-edit-update.response";
import PassengerEditInitDataResponse from "./common/api/types/passenger-edit-init-data.response";
import PassengerDispatchSelectOption from "../common/types/passenger-dispatch-select-option";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import MultiSelectComponent from "../../../../common/components/form/select/multi-select/multi-select.component";

type PassengerEditProps = {};

const PassengerEditComponent: FC<PassengerEditProps> = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { passengerUuid } = useParams<UserPassengerEditRouteParams>();

  const navigate = useNavigate();

  const translations = userTranslationsHelper.getPassengerEditTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const [passengerName, setPassengerName] = useState("");

  useDocumentTitle(
    documentTitleTranslations.userPassengerEdit.replace(
      "#{passengerName}",
      passengerName
    )
  );

  const [isPassengerFetching, setIsPassengerFetching] = useState(false);

  const [dispatchSelectOptions, sestDispatchSelectOptions] = useState<
    PassengerDispatchSelectOption[]
  >([]);

  const [formData, setFormData] = useState<PassengerEditFormData>(
    PassengerEditHelper.getDefaultFormData()
  );
  const [formValidationResults, setFormValidationsResults] =
    useState<PassengerEditFormValidationResults>(
      PassengerEditHelper.getDefaultFormValidationResults()
    );

  // temporary solution - remove after refactor
  useEffect(() => {
    const revalidationFields = Object.keys(formValidationResults).filter(
      (formKey) =>
        formValidationResults[
          formKey as keyof PassengerEditFormValidationResults
        ].isValid === false
    );

    revalidationFields.forEach((field) => {
      switch (field) {
        case "firstName":
          return validateFirstName();
        case "lastName":
          return validateLastName();
        case "username":
        case "email":
          return validateEmail();
        case "mobile":
          return validateMobile();
        case "alternativeMobile":
          return validateAlternativeMobile();
        case "addresses":
          return validateAddresses();
        case "dispatches":
          return validateDispatches();
      }
    });
  }, [selectedAppLanguage]);

  useEffect(() => {
    if (!passengerUuid) {
      return;
    }

    const breadcrumbs = userBreadcrumbsHelper.getPassengerEditBreadcrumbs({
      passengerUuid,
      passengerName,
    });
    setBreadcrumbs(breadcrumbs);
  }, [passengerUuid, selectedAppLanguage, passengerName]);

  useEffect(() => {
    fetchPassengerData();
  }, [passengerUuid]);

  const fetchPassengerData = () => {
    if (!passengerUuid) {
      return;
    }

    setIsPassengerFetching(true);

    passengerEditApiService
      .fetchInitData(passengerUuid)
      .then(handlePassengerDataResponse)
      .catch(onPassengerDataFetchFailure)
      .finally(() => setIsPassengerFetching(false));
  };

  const handlePassengerDataResponse = (
    response: PassengerEditInitDataResponse
  ) => {
    if (response.status === 200) {
      onPassengerDataFetchSuccess(response);
      return;
    }

    onPassengerDataFetchFailure();
  };

  const onPassengerDataFetchSuccess = (
    response: PassengerEditInitDataResponse
  ) => {
    const formData = passengerEditFactory.createFormData(response.data);
    const dispatchSelectOptions =
      passengerEditFactory.createDispatchSelectOptions(
        response.data.passenger.available_dispatches
      );

    setFormData(formData);
    setPassengerName(`${response.data.first_name} ${response.data.last_name}`);
    sestDispatchSelectOptions(dispatchSelectOptions);
  };

  const onPassengerDataFetchFailure = () => {
    notificationService.error(
      translations.failureFetchPassengerDataNotificationText
    );
  };

  const setFirstName = (firstName: PassengerEditFormData["firstName"]) => {
    setFormData((curr) => ({
      ...curr,
      firstName,
    }));
  };

  const setLastName = (lastName: PassengerEditFormData["lastName"]) => {
    setFormData((curr) => ({
      ...curr,
      lastName,
    }));
  };

  const setEmail = (email: PassengerEditFormData["email"]) => {
    setFormData((curr) => ({ ...curr, email }));
  };

  const setMobile = (mobile: PassengerEditFormData["mobile"]) => {
    setFormData((curr) => ({
      ...curr,
      mobile,
    }));
  };

  const setAlternativeMobile = (
    alternativeMobile: PassengerEditFormData["alternativeMobile"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      alternativeMobile,
    }));
  };

  const addNewAddress = (newAddress: PassengerAddress) => {
    const newAddresses: PassengerEditFormData["addresses"] = [
      ...formData.addresses,
      newAddress,
    ];

    setFormData((curr) => ({
      ...curr,
      addresses: newAddresses,
    }));

    validateAddresses(newAddresses);
  };

  const removeAddress = (index: number) => {
    const filteredAddresses: PassengerAddress[] = formData.addresses.filter(
      (_address, addressIndex: number) => {
        return index !== addressIndex;
      }
    );

    setFormData((curr) => ({
      ...curr,
      addresses: filteredAddresses,
    }));

    validateAddresses(filteredAddresses);
  };

  const setDispatches = (value: PassengerEditFormData["dispatches"]) => {
    setFormData((curr) => ({
      ...curr,
      dispatches: value,
    }));
  };

  const onDispatchesChange = (value: PassengerEditFormData["dispatches"]) => {
    setDispatches(value);
    validateDispatches(value);
  };

  const validateFirstName = () => {
    const validationResult =
      passengerEditFormValidationService.validateFirstName(formData.firstName);

    setFormValidationsResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateLastName = () => {
    const validationResult =
      passengerEditFormValidationService.validateLastName(formData.lastName);

    setFormValidationsResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateMobile = () => {
    const validationResult = passengerEditFormValidationService.validateMobile(
      formData.mobile
    );

    setFormValidationsResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAlternativeMobile = () => {
    const validationResult =
      passengerEditFormValidationService.validateAlternativeMobile(
        formData.alternativeMobile
      );

    setFormValidationsResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateEmail = () => {
    const validationResult = passengerEditFormValidationService.validateEmail(
      formData.email
    );

    setFormValidationsResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDispatches = (
    value: PassengerEditFormData["dispatches"] = formData.dispatches
  ) => {
    const validationResult =
      passengerEditFormValidationService.validateDispatches(value);

    setFormValidationsResults((curr) => ({
      ...curr,
      dispatches: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAddresses = (
    value: PassengerEditFormData["addresses"] = formData.addresses
  ) => {
    const validationResult =
      passengerEditFormValidationService.validateAddresses(value);

    setFormValidationsResults((curr) => ({
      ...curr,
      addresses: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsFormValid = () => {
    const isDispatchIdsValid = validateDispatches();
    const isEmailValid = validateEmail();
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const isMobileValid = validateMobile();
    const isMobileAlternativeValid = validateAlternativeMobile();
    const isAddressesValid = validateAddresses();

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isMobileValid &&
      isMobileAlternativeValid &&
      isDispatchIdsValid &&
      isAddressesValid
    );
  };

  const navigateToListing = () => {
    const passengerListingRoute = userRoutesHelper.getPassengerListingRoute();
    navigate(passengerListingRoute);
  };

  const handleSubmitResponse = (response: PassengerEditUpdateResponse) => {
    if (response.status === 200) {
      notificationService.success(translations.successEditNotificationText);
      navigateToListing();

      return;
    }

    notificationService.error(translations.failureEditNotificationText);
  };

  const submitForm = async () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid || !passengerUuid) {
      return;
    }

    const request = passengerEditFactory.createEditPassengerRequest(formData);

    passengerEditApiService
      .update(passengerUuid, request)
      .then(handleSubmitResponse);
  };

  return (
    <div className="user_passenger_edit">
      <HeadingComponent
        title={PassengerEditHelper.getHeadingText(passengerName)}
      />
      <CardComponent isLoading={isPassengerFetching}>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.dispatchesLabel}
              isRequired
              errorMessage={formValidationResults.dispatches.errorMessage}
            >
              <MultiSelectComponent
                isLoading={isPassengerFetching}
                placeholder={translations.form.dispatchesPlaceholder}
                onChange={(option) => onDispatchesChange(option)}
                options={dispatchSelectOptions}
                value={formData.dispatches}
                hasError={!!formValidationResults.dispatches.errorMessage}
                idForTesting={`dispatches-select`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.emailLabel}
              isRequired
              errorMessage={formValidationResults.email.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.emailPlaceholder}
                value={formData.email}
                onChange={setEmail}
                onBlur={validateEmail}
                hasError={!!formValidationResults.email.errorMessage}
                idForTesting={`email-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.firstNameLabel}
              isRequired
              errorMessage={formValidationResults.firstName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.firstNamePlaceholder}
                value={formData.firstName}
                onChange={setFirstName}
                onBlur={validateFirstName}
                hasError={!!formValidationResults.firstName.errorMessage}
                idForTesting={`first-name-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.lastNameLabel}
              isRequired
              errorMessage={formValidationResults.lastName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.lastNamePlaceholder}
                value={formData.lastName}
                onChange={setLastName}
                onBlur={validateLastName}
                hasError={!!formValidationResults.lastName.errorMessage}
                idForTesting={`last-name-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.mobilePlaceholder}
              isRequired
              errorMessage={formValidationResults.mobile.errorMessage}
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.mobilePlaceholder}
                phoneNumber={formData.mobile}
                onChange={setMobile}
                onBlur={validateMobile}
                hasError={!!formValidationResults.mobile.errorMessage}
                idForTesting={`mobile`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.alternativeMobileLabel}
              errorMessage={
                formValidationResults.alternativeMobile.errorMessage
              }
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.alternativeMobileLabel}
                phoneNumber={formData.alternativeMobile}
                onChange={setAlternativeMobile}
                onBlur={validateAlternativeMobile}
                hasError={
                  !!formValidationResults.alternativeMobile.errorMessage
                }
                idForTesting={`alternative-mobile`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column>
            <FormFieldComponent
              label={translations.form.addressLabel}
              isRequired
              errorMessage={formValidationResults.addresses.errorMessage}
            >
              <PassengerFormDataAddressComponent
                onAddressAdd={addNewAddress}
                onAddressRemove={removeAddress}
                addresses={formData.addresses}
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        title={translations.form.submitButtonTitle}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};

export default PassengerEditComponent;
