import MileageListingItem from "../../../listing/common/types/mileage-listing-item";
import MileageDetailsPassenger from "../types/mileage-details-passenger";

const create = (item: MileageListingItem): MileageDetailsPassenger => {
  return {
    displayName: item.workerName,
    phoneNumber: item.workerPhone,
    uuid: item.workerUuid,
  };
};

const mileageDetailsPassengerFactory = {
  create,
};

export default mileageDetailsPassengerFactory;
