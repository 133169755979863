import MileageSettingsAddParams, {
  MileageSettingsAddParamsMileageNumberCreationModel,
} from "../../../../common/services/mileage-settings/add/mileage-settings-add-params";
import MileageSettingsUpdateParams, {
  MileageSettingsUpdateParamsMileageNumberCreationModel,
} from "../../../../common/services/mileage-settings/update/mileage-settings-update-params";
import MileageSettingsFormData from "../form/types/mileage-settings-form-data";
import MileageSettingsMileageNumberCreationModel from "../types/mileage-settings-mileage-number-creation-model";

const createAddCreationModel = (
  model: MileageSettingsMileageNumberCreationModel
): MileageSettingsAddParamsMileageNumberCreationModel => {
  switch (model) {
    case MileageSettingsMileageNumberCreationModel.AUTO:
      return MileageSettingsAddParamsMileageNumberCreationModel.AUTO;
    case MileageSettingsMileageNumberCreationModel.DICT:
      return MileageSettingsAddParamsMileageNumberCreationModel.DICT;
    case MileageSettingsMileageNumberCreationModel.MANUAL:
      return MileageSettingsAddParamsMileageNumberCreationModel.MANUAL;
  }
};

const createAddParams = (
  companyUuid: string,
  formData: MileageSettingsFormData
): MileageSettingsAddParams => {
  return {
    companyUuid: companyUuid,
    isCardNumberRequired: formData.isCardNumberRequired,
    isMileageNumberRequired: formData.isMileageNumberRequired,
    mileageNumberCreationModel: createAddCreationModel(
      formData.mileageNumberCreationModel
    ),
  };
};

const createUpdateCreationModel = (
  model: MileageSettingsMileageNumberCreationModel
): MileageSettingsUpdateParamsMileageNumberCreationModel => {
  switch (model) {
    case MileageSettingsMileageNumberCreationModel.AUTO:
      return MileageSettingsUpdateParamsMileageNumberCreationModel.AUTO;
    case MileageSettingsMileageNumberCreationModel.DICT:
      return MileageSettingsUpdateParamsMileageNumberCreationModel.DICT;
    case MileageSettingsMileageNumberCreationModel.MANUAL:
      return MileageSettingsUpdateParamsMileageNumberCreationModel.MANUAL;
  }
};

const createUpdateParams = (
  companyUuid: string,
  formData: MileageSettingsFormData
): MileageSettingsUpdateParams => {
  return {
    cargoCompanyUuid: companyUuid,
    isCardNumberRequired: formData.isCardNumberRequired,
    isMileageNumberRequired: formData.isMileageNumberRequired,
    mileageNumberCreationModel: createUpdateCreationModel(
      formData.mileageNumberCreationModel
    ),
  };
};

const mileageSettingsParamsFactory = {
  createAddParams,
  createUpdateParams,
};

export default mileageSettingsParamsFactory;
