import { FC } from "react";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { IsPublicOrder } from "../../../../../common/utils/order/is-public-order";
import useOpen from "../../../../../common/hooks/use-open";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsCancelForwardingModal from "../../modals/cancel-forwading/order-details-cancel-forwarding.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsForwardingCancelComponentProps = {
  tools: OrderDetailsToolsTranslations;
  details: OrderDetailsCargoOrder | null;
  forwardingId: string;
  hasAccess: boolean;
};

const OrderDetialsForwardingCancelComponent: FC<
  OrderDetialsForwardingCancelComponentProps
> = ({ tools, details, forwardingId, hasAccess }) => {
  const { isOpen, open, close } = useOpen();
  const { isFinished, isCancelled, transportingOrderUuid } = details!;

  const isForwardingCancelButtonVisible =
    !isFinished && !isCancelled && hasAccess;

  if (!isForwardingCancelButtonVisible) return null;

  const {
    cancelForwardingButtonTitle,
    cancelForwardingButtonDisabledTitle,
    cancelNonActiveForwardingButtonDisabledTitle,
  } = tools;

  const asPublicOrder = IsPublicOrder(details!);

  const actionButtonText = asPublicOrder
    ? cancelForwardingButtonDisabledTitle
    : cancelForwardingButtonTitle;

  return (
    <>
      <OrderDetailsToolButtonComponent
        onClick={open}
        icon={faSquareXmark}
        title={actionButtonText}
        type="danger"
        isDisabled={asPublicOrder || !forwardingId}
      />
      {!!isOpen && (
        <OrderDetailsCancelForwardingModal
          isOpen={isOpen}
          onClose={close}
          transportingOrderId={transportingOrderUuid}
          sumbitDisabledText={cancelNonActiveForwardingButtonDisabledTitle}
        />
      )}
    </>
  );
};

export default OrderDetialsForwardingCancelComponent;
