import MileageSettingsUpdateRequest, {
  MileageSettingsUpdateRequestBody,
} from "./mileage-settings-update.request";

const createbody = (
  request: MileageSettingsUpdateRequest
): MileageSettingsUpdateRequestBody => {
  return {
    isCardNoRequired: request.isCardNumberRequired,
    isMileageNoRequired: request.isMileageNumberRequired,
    mileageNoCreationType: request.mileageNumberCreationType,
  };
};

const mileageSettingsupdateRequestFactory = {
  createbody,
};

export default mileageSettingsupdateRequestFactory;
