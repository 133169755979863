import { FC } from "react";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { IsPublicOrder } from "../../../../../common/utils/order/is-public-order";
import useOpen from "../../../../../common/hooks/use-open";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsForwardingModal from "../../modals/forwarding/order-details-forwarding-modal.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsForwardingAddComponentProps = {
  tools: OrderDetailsToolsTranslations;
  details: OrderDetailsCargoOrder | null;
  hasAccess: boolean;
};

const OrderDetialsForwardingAddComponent: FC<
  OrderDetialsForwardingAddComponentProps
> = ({ tools, details, hasAccess }) => {
  const { isOpen, open, close } = useOpen();
  const { forwardingButtonTitle, forwardingButtonDisabledTitle } = tools;
  const { isFinished, isCancelled } = details!;

  const isForwardingAddButtonVisible = !isFinished && !isCancelled && hasAccess;

  if (!isForwardingAddButtonVisible) return null;

  const asPublicOrder = IsPublicOrder(details!);
  const actionButtonText = asPublicOrder
    ? forwardingButtonDisabledTitle
    : forwardingButtonTitle;

  return (
    <>
      <OrderDetailsToolButtonComponent
        onClick={open}
        icon={faShareSquare}
        title={actionButtonText}
        isDisabled={asPublicOrder}
      />
      {!!isOpen && (
        <OrderDetailsForwardingModal
          isOpen={isOpen}
          onClose={close}
          details={details}
        />
      )}
    </>
  );
};

export default OrderDetialsForwardingAddComponent;
