import apiService from "../api.service";
import ApiConfig from "../types/api-config";

import railyApiHelper from "./raily-api.helper";

const railyApiService = (customBaseUrl?: string) => {
  const config: ApiConfig = railyApiHelper.getConfig(customBaseUrl);

  return apiService(config);
};

export default railyApiService;
