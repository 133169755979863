import { FC, useEffect, useState } from "react";
import { PlanningOrderSelectedItemData } from "../common/types/planning-order-selected-data";
import { PlannerDriverPlanListItem } from "../../../../common/services/planner/list/planner-driver-plan-list";
import useAbort from "../../../../common/hooks/use-abort";
import usePlannerDriverPlanList from "../../../../common/services/planner/list/use-planner-driver-plan-list";
import PlannerDriverPlanListLoadParams from "../../../../common/services/planner/list/planner-driver-plan-list-load-params";
import PlanningOrderDriverPlanListingFilterComponent from "./filter/planning-order-driver-plan-listing-filter.component";
import PlanningOrderDriverPlanListingSortKey from "./common/planning-order-driver-plan-listing-sort-key";
import PlanningOrderDriverPlanListingFilter from "./common/planning-order-driver-plan-listing-filter";
import PlanningOrderDriverPlanListingItemsComponent from "./items/planning-order-driver-plan-listing-items.component";
import usePlannerDriverPlanOrder from "../../../../common/services/planner/order/use-planner-driver-plan-order";
import planningOrderDriverPlanListingDriverPlanListLoadParamsFactory from "./common/planning-order-driver-plan-listing-driver-plan-list-params.factory";
import LoaderComponent from "../../../../common/components/loader/loader.component";
import usePagination from "../../../../common/hooks/use-pagination";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";

type PlanningOrderDriverPlanListingProps = {
  orderUuid: string | undefined;
  selectedItemsData: PlanningOrderSelectedItemData[];
  onButtonClick: (item: PlanningOrderSelectedItemData) => void;
};

const PlanningOrderDriverPlanListingComponent: FC<
  PlanningOrderDriverPlanListingProps
> = (props) => {
  const [sortKey, setSortKey] = useState<PlanningOrderDriverPlanListingSortKey>(
    () => PlanningOrderDriverPlanListingSortKey.DRIVER_NAME_ASC
  );

  const [filters, setFilters] = useState<
    PlanningOrderDriverPlanListingFilter[]
  >([]);

  const [listingItems, setListingItems] = useState<PlannerDriverPlanListItem[]>(
    []
  );

  const plannerDriverPlanListAbort = useAbort();
  const plannerDriverPlanList = usePlannerDriverPlanList();

  const plannerDriverPlanOrderAbort = useAbort();
  const plannerDriverPlanOrder = usePlannerDriverPlanOrder();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: plannerDriverPlanList.data.totalCount,
    defaultPageSize: 5,
  });

  useEffect(() => {
    if (!props.orderUuid) return;

    const params: PlannerDriverPlanListLoadParams =
      planningOrderDriverPlanListingDriverPlanListLoadParamsFactory.create(
        filters,
        sortKey,
        page,
        pageSize,
        props.orderUuid
      );

    plannerDriverPlanList.load(params, plannerDriverPlanListAbort.signal);
  }, [filters, sortKey, page, pageSize, props.orderUuid]);

  useEffect(() => {
    if (!props.orderUuid) return;

    plannerDriverPlanOrder.load(
      {
        orderUuid: props.orderUuid,
      },
      plannerDriverPlanOrderAbort.signal
    );
  }, [props.orderUuid]);

  useEffect(() => {
    const plannerDriverPlanData = plannerDriverPlanList.data.data.map(
      (data, index) => {
        return { ...data, id: index };
      }
    );

    const items = plannerDriverPlanData.filter(
      (item: PlannerDriverPlanListItem) => {
        const isSameItem = props.selectedItemsData.find(
          (i: PlanningOrderSelectedItemData) => i.id === item.id
        );
        return !isSameItem;
      }
    );

    setListingItems(items);
  }, [props.selectedItemsData, plannerDriverPlanList.data.data]);

  const isLoading =
    plannerDriverPlanList.isLoading || plannerDriverPlanOrder.isLoading;

  const LoaderContent = (
    <div className="planning_order_order_details__loader">
      <LoaderComponent type="primary" />
    </div>
  );

  const ListingContent = (
    <>
      <PlanningOrderDriverPlanListingItemsComponent
        items={listingItems}
        order={plannerDriverPlanOrder.data.data}
        onButtonClick={props.onButtonClick}
      />
      <ListingPaginationComponent
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        page={page}
        pageSize={pageSize}
        totalResults={plannerDriverPlanList.data.totalCount}
        pageSizeOptions={[5, 10, 25]}
      />
    </>
  );

  return (
    <div>
      <PlanningOrderDriverPlanListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={setFilters}
        onSortKeyChange={setSortKey}
      />
      {isLoading ? LoaderContent : ListingContent}
    </div>
  );
};

export default PlanningOrderDriverPlanListingComponent;
