import classNames from "classnames";
import { FC, ReactNode, RefObject, useState, useRef, useEffect } from "react";
import ComponentClassnames from "../../../../types/component-classnames";

type NumericInputProps = {
  classNames?: ComponentClassnames & {
    input?: string;
  };
  value: number | null;
  onChange?: (value: number | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  inputRef?: RefObject<HTMLInputElement> | undefined;
  hasError?: boolean;
  isIntegerOnly?: boolean;
  idForTesting?: string;
  decimalPrecision?: number;
};

const NumericInputComponent: FC<NumericInputProps> = (props) => {
  const [inputValue, setInputValue] = useState("");

  const inputWrapperRef: RefObject<HTMLLabelElement> = useRef(null);

  useEffect(() => {
    setInputValue(props.value?.toString() ?? "");
  }, [props.value]);

  const onInputFocus = () => {
    inputWrapperRef?.current?.classList.add("active");
    if (props.onFocus) props.onFocus();
  };

  const onInputBlur = () => {
    inputWrapperRef?.current?.classList.remove("active");
    if (props.onBlur) props.onBlur();
  };

  const getIntegerOnlyValidationPattern = () => {
    return /^-?\d{0,7}$/;
  };

  const getNumberWithPrecisionValidationPattern = (precisionInput: number) => {
    const maxPrecision = 7;

    const precision =
      precisionInput > maxPrecision ? maxPrecision : precisionInput;
    return new RegExp(
      `^-?(\\d{0,7}(?![.])|\\d{0,7}[.]{1}\\d{0,${precision}})$`
    );
  };

  const getValidationPattern = () => {
    if (props.isIntegerOnly) {
      return getIntegerOnlyValidationPattern();
    }

    if (props.decimalPrecision) {
      return getNumberWithPrecisionValidationPattern(props.decimalPrecision);
    }

    return /^-?\d{0,7}[.]?\d{0,7}$/;
  };

  const onChange = (value: string) => {
    if (value === "") {
      setInputValue(value);
      props.onChange && props.onChange(null);
      return;
    }

    const validationPattern = getValidationPattern();
    const newValue = value.replace(",", ".");

    const isValid = validationPattern.test(newValue);

    if (!isValid) {
      return;
    }

    setInputValue(newValue);

    if (props.onChange && !Number.isNaN(Number(value)) && !value.endsWith("."))
      props.onChange(Number(value));
  };

  const inputWrapperClassnames = classNames(
    "form_input",
    props.classNames?.root,
    props.hasError && "error",
    props.isDisabled && "disabled"
  );

  const inputClassnames = classNames(
    "form_input__input",
    props.classNames?.input
  );

  return (
    <label ref={inputWrapperRef} className={inputWrapperClassnames}>
      {props.iconLeft && (
        <i className="form_input__icon_left">{props.iconLeft}</i>
      )}
      <input
        ref={props.inputRef}
        value={inputValue}
        placeholder={props.placeholder}
        className={inputClassnames}
        autoComplete="off"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        autoCorrect="false"
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        readOnly={props.isReadOnly || props.isDisabled}
        disabled={props.isDisabled}
        data-test-id={props.idForTesting}
      />
      {props.iconRight && (
        <i className="form_input__icon_right">{props.iconRight}</i>
      )}
    </label>
  );
};

export default NumericInputComponent;
