import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import BadgeComponent from "../../../badge/badge.component";
import ButtonComponent from "../../../button/button.component";
import ListingFilterBadge from "./listing-filter-badge";

export type ListingFiltersBadgeProps = ListingFilterBadge & {
  onDeleteButtonClick: () => void;
};

const ListingFilterBadgeComponent: FC<ListingFiltersBadgeProps> = (props) => {
  return (
    <BadgeComponent
      title={props.title}
      classNames={{ root: "listing_filter_badge" }}
    >
      <div className="listing_filter_badge__text">{props.text}</div>
      <ButtonComponent
        onClick={props.onDeleteButtonClick}
        classNames={{
          root: `listing_filter_badge__button`,
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </ButtonComponent>
    </BadgeComponent>
  );
};

export default ListingFilterBadgeComponent;
