import classNames from "classnames";
import { FC } from "react";
import { CargoOrderActiveListItemExecutionStatus } from "../../../../../common/services/cargo-order/active-list/cargo-order-active-list";

type OrderActiveListingTableStatusProps = {
  status: CargoOrderActiveListItemExecutionStatus;
};

const OrderActiveListingTableStatusComponent: FC<
  OrderActiveListingTableStatusProps
> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case CargoOrderActiveListItemExecutionStatus.CANDIDATURE:
        return "candidature";
      case CargoOrderActiveListItemExecutionStatus.IN_PROGRESS:
        return "in_progress";
      case CargoOrderActiveListItemExecutionStatus.PENDING:
        return "pending";
      case CargoOrderActiveListItemExecutionStatus.PLANNED:
        return "planned";
      default:
        return "";
    }
  };

  const statusClassname = getClassnameByStatus();

  const containerClassNames = classNames(
    "order_active_listing_table_status",
    statusClassname
  );

  return <div className={containerClassNames}></div>;
};

export default OrderActiveListingTableStatusComponent;
