import UserRole from "../../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DriverContractAddUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type DriverContractAddUserPermissionsDefinition =
  UserPermissionsDefinition<DriverContractAddUserPermissionOption>;

export type DriverContractAddUserPermissions =
  UserPermissions<DriverContractAddUserPermissionOption>;

const driverContractAddUserPermissionDefinition: DriverContractAddUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
    ],
  };

export default driverContractAddUserPermissionDefinition;
