import { MutableRefObject, useCallback, useEffect } from "react";

type KeyboardPressParams = {
  ArrowDown?: (event: KeyboardEvent) => void;
  ArrowUp?: (event: KeyboardEvent) => void;
  Enter?: (event: KeyboardEvent) => void;
  Escape?: (event: KeyboardEvent) => void;
  Space?: (event: KeyboardEvent) => void;
  Tab?: (event: KeyboardEvent) => void;
  ref: MutableRefObject<HTMLElement | null>;
};

const useKeyboardPress = (params: KeyboardPressParams) => {
  const keyboardKeyClickHandler = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowDown":
          return params.ArrowDown ? params.ArrowDown(event) : undefined;
        case "ArrowUp":
          return params.ArrowUp ? params.ArrowUp(event) : undefined;
        case "Escape":
          return params.Escape ? params.Escape(event) : undefined;
        case "Enter":
          return params.Enter ? params.Enter(event) : undefined;
        case " ":
          return params.Space ? params.Space(event) : undefined;
        case "Tab":
          return params.Tab ? params.Tab(event) : undefined;
        default:
          break;
      }
    },
    [params]
  );

  useEffect(() => {
    params.ref.current?.addEventListener(
      "keydown",
      keyboardKeyClickHandler,
      false
    );

    return () => {
      params.ref.current?.removeEventListener(
        "keydown",
        keyboardKeyClickHandler,
        false
      );
    };
  }, [keyboardKeyClickHandler]);
};

export default useKeyboardPress;
