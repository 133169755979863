import isEqual from "lodash/isEqual";
import CargoTaxiContractListingFilterSelectOption, {
  CargoTaxiContractListingBillingModelFilterSelectOption,
} from "../types/cargo-taxi-contract-listing-filter-select-option";
import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import CargoTaxiContractListingFilterType from "../types/cargo-taxi-contract-listing-filter-type";
import CargoTaxiContractListingBillingModel from "../types/cargo-taxi-contract-listing-billing-model";
import CargoTaxiContractListingFilter from "../types/cargo-taxi-contract-listing-filter";

const getSearchBillingModelOptions = (
  query: string
): CargoTaxiContractListingBillingModelFilterSelectOption[] => {
  const searchFilterTranslations =
    cargoTranslationsHelper.getTaxiContractListingTranslations().filters.search;

  const allBillingModelSelectOptions: CargoTaxiContractListingBillingModelFilterSelectOption[] =
    Object.values(CargoTaxiContractListingBillingModel).map((billingModel) => {
      const billingModelLabel =
        searchFilterTranslations.searchByBillingModelTemplateText.replace(
          "#{option}",
          billingModel
        );

      return {
        label: billingModelLabel,
        value: {
          type: CargoTaxiContractListingFilterType.BILLING_MODEL,
          value: billingModel,
        },
      };
    });

  if (query) {
    return allBillingModelSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allBillingModelSelectOptions;
};

const getSearchQueryOptions = (
  query: string
): CargoTaxiContractListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const translations =
    cargoTranslationsHelper.getTaxiContractListingTranslations().filters.search;

  const getSearchByTaxiCorporationQueryLabel = (query: string) => {
    return translations.searchByTaxiCorporationTemplateText.replace(
      `#{query}`,
      query
    );
  };

  return [
    {
      label: getSearchByTaxiCorporationQueryLabel(query),
      value: {
        type: CargoTaxiContractListingFilterType.TAXI_CORPORATION,
        value: query,
      },
    },
  ];
};

const getFilterOptions = (
  query: string
): CargoTaxiContractListingFilterSelectOption[] => {
  const billingModelOptions = getSearchBillingModelOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...billingModelOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: CargoTaxiContractListingFilter[]
): CargoTaxiContractListingFilterSelectOption[] => {
  const allFilterOptions = getFilterOptions(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const cargoTaxiContractListingFilterHelper = { getSelectOptions };

export default cargoTaxiContractListingFilterHelper;
