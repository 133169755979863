import { MessengerPostChannel } from "../../../services/messenger/post/messenger-post";
import MessengerPostLoadParams, {
  MessengerPostLoadParamsBillingType,
} from "../../../services/messenger/post/messenger-post-load-params";
import MessengerBillingType from "../types/messenger-billing-type";
import MessengerChannel from "../types/messenger-channel";
import MessengerChannelOption from "../types/messenger-channel-option";

const createPostChannel = (channel: MessengerChannel): MessengerPostChannel => {
  switch (channel) {
    case MessengerChannel.BILLING:
      return MessengerPostChannel.BILLING;
    case MessengerChannel.CARGO:
      return MessengerPostChannel.CARGO;
    case MessengerChannel.DRIVER:
      return MessengerPostChannel.DRIVER;
    case MessengerChannel.RAILY:
      return MessengerPostChannel.RAILY;
    case MessengerChannel.SHARED:
      return MessengerPostChannel.SHARED;
    case MessengerChannel.TAXI:
      return MessengerPostChannel.TAXI;
    case MessengerChannel.MILEAGE_NOTES:
      return MessengerPostChannel.MILEAGE_NOTES;
    case MessengerChannel.MILEAGE_PASSENGER:
      return MessengerPostChannel.MILEAGE_PASSENGER;
    case MessengerChannel.DELEGATION_NOTES:
      return MessengerPostChannel.DELEGATION_NOTES;
  }
};

const createBillingType = (
  billingType: MessengerBillingType | undefined
): MessengerPostLoadParamsBillingType | undefined => {
  switch (billingType) {
    case MessengerBillingType.CARGO:
      return MessengerPostLoadParamsBillingType.CARGO;
    case MessengerBillingType.TAXI:
      return MessengerPostLoadParamsBillingType.TAXI;
    default:
      return undefined;
  }
};

const createReloadParams = (
  channel: MessengerChannel,
  billingType: MessengerBillingType | undefined,
  billingUuid: string | undefined,
  orderUuid: string | undefined,
  planEntryUuid: string | undefined,
  solvedOrderUuid: string | undefined,
  taxiCorporationUuid: string | undefined
): MessengerPostLoadParams => {
  return {
    availableChannels: [createPostChannel(channel)],
    billingType: createBillingType(billingType),
    billingUuid: billingUuid,
    orderUuid: orderUuid,
    planEntryUuid: planEntryUuid,
    solvedOrderUuid: solvedOrderUuid,
    taxiCorporationUuid: taxiCorporationUuid,
  };
};

const create = (
  channelOptions: MessengerChannelOption[],
  billingType: MessengerBillingType | undefined,
  billingUuid: string | undefined,
  orderUuid: string | undefined,
  planEntryUuid: string | undefined,
  solvedOrderUuid: string | undefined,
  taxiCorporationUuid: string | undefined
): MessengerPostLoadParams => {
  return {
    availableChannels: channelOptions.map((option) =>
      createPostChannel(option.channel)
    ),
    billingType: createBillingType(billingType),
    billingUuid: billingUuid,
    orderUuid: orderUuid,
    planEntryUuid: planEntryUuid,
    solvedOrderUuid: solvedOrderUuid,
    taxiCorporationUuid: taxiCorporationUuid,
  };
};

const messengerPostLoadParamsFactory = {
  create,
  createReloadParams,
};

export default messengerPostLoadParamsFactory;
