import MileageContractDetailsResponse from "../../../api/raily/mileage-contract/details/mileage-contract-details.response";
import MileageContractDetails from "./mileage-contract-details";

const create = (
  response: MileageContractDetailsResponse
): MileageContractDetails => {
  return {
    distanceRateCompanyVehicle: response.data.distanceRateCompanyVehicle,
    distanceRateCarGt900cm3: response.data.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: response.data.distanceRateCarLe900cm3,
    distanceRateMotorcycle: response.data.distanceRateMotorcycle,
    distanceRateMoped: response.data.distanceRateMoped,
    dietFullRate: response.data.dietFullRate,
    dietFullAfterHours: response.data.dietFullAfterHours,
    dietHalfRate: response.data.dietHalfRate,
    dietHalfAfterHours: response.data.dietHalfAfterHours,
    validSince: response.data.validSince,
    validTo: response.data.validTo,
    isActive: !response.data.disabled,
    companyUuid: response.data.companyId,
    companyName: response.data.companyName,
  };
};

const mileageContractDetailsFactory = {
  create,
};

export default mileageContractDetailsFactory;
