import { FC } from "react";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import BillingsToolButtonComponent from "../../tool-button/billings-tool-button.component";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

type BillingsHistoryComponentProps = {
  isActive: boolean;
  onSuccess: () => void;
};

const BillingsHistoryComponent: FC<BillingsHistoryComponentProps> = ({
  isActive,
  onSuccess,
}) => {
  const translations =
    billingsTranslationsHelper.getBillingsTranslations().tools;

  return (
    <BillingsToolButtonComponent
      onClick={onSuccess}
      icon={faClockRotateLeft}
      title={
        isActive
          ? translations.hideOrderHistoryButtonTitle
          : translations.showOrderHistoryButtonTitle
      }
      isActive={isActive}
    />
  );
};

export default BillingsHistoryComponent;
