import Joi from "joi";
import TaxiUnsettledOrderListingRouteQueryFilterParams from "./types/taxi-unsettled-order-listing-route-query-filter-params";
import TaxiUnsettledOrderListingRouteQueryParams from "./types/taxi-unsettled-order-listing-route-query-params";
import TaxiUnsettledOrderListingSortKey from "./types/taxi-unsettled-order-listing-sort-key";
import TaxiUnsettledOrderListingFilter, {
  TaxiUnsettledOrderListingClientFilter,
  TaxiUnsettledOrderListingDispatchFilter,
  TaxiUnsettledOrderListingDriverFilter,
  TaxiUnsettledOrderListingExternalOrderIdFilter,
  TaxiUnsettledOrderListingInternalOrderIdFilter,
  TaxiUnsettledOrderListingPassengerFilter,
  TaxiUnsettledOrderListingRouteAddressFilter,
  TaxiUnsettledOrderListingRouteDestinationAddressFilter,
  TaxiUnsettledOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledOrderListingRoutePickupAddressFilter,
  TaxiUnsettledOrderListingStartDateFilter,
  TaxiUnsettledOrderListingStatusFilter,
  TaxiUnsettledOrderListingTaxiFilter,
} from "./types/taxi-unsettled-order-listing-filter";
import TaxiUnsettledOrderListingFilterType from "./types/taxi-unsettled-order-listing-filter-type";

const getFilters = (
  routeQueryFilterParams: TaxiUnsettledOrderListingRouteQueryFilterParams
): TaxiUnsettledOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: TaxiUnsettledOrderListingFilter[] = [];

  if (routeQueryFilterParams.client) {
    const clientFilter: TaxiUnsettledOrderListingClientFilter = {
      type: TaxiUnsettledOrderListingFilterType.CLIENT,
      value: routeQueryFilterParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: TaxiUnsettledOrderListingDispatchFilter = {
      type: TaxiUnsettledOrderListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: TaxiUnsettledOrderListingDriverFilter = {
      type: TaxiUnsettledOrderListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalOrderIdFilter: TaxiUnsettledOrderListingExternalOrderIdFilter =
      {
        type: TaxiUnsettledOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: routeQueryFilterParams.externalOrderId,
      };

    filters.push(externalOrderIdFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: TaxiUnsettledOrderListingInternalOrderIdFilter =
      {
        type: TaxiUnsettledOrderListingFilterType.INTERNAL_ORDER_ID,
        value: routeQueryFilterParams.internalOrderId,
      };

    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: TaxiUnsettledOrderListingPassengerFilter = {
      type: TaxiUnsettledOrderListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.taxi) {
    const taxiFilter: TaxiUnsettledOrderListingTaxiFilter = {
      type: TaxiUnsettledOrderListingFilterType.TAXI,
      value: routeQueryFilterParams.taxi,
    };

    filters.push(taxiFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: TaxiUnsettledOrderListingRouteAddressFilter = {
      type: TaxiUnsettledOrderListingFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationFilter: TaxiUnsettledOrderListingRouteDestinationAddressFilter =
      {
        type: TaxiUnsettledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: TaxiUnsettledOrderListingRouteIntermediateAddressFilter =
      {
        type: TaxiUnsettledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: TaxiUnsettledOrderListingRoutePickupAddressFilter =
      {
        type: TaxiUnsettledOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: TaxiUnsettledOrderListingStartDateFilter = {
      type: TaxiUnsettledOrderListingFilterType.START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.startDateFrom),
        to: new Date(routeQueryFilterParams.startDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: TaxiUnsettledOrderListingStatusFilter = {
      type: TaxiUnsettledOrderListingFilterType.STATUS,
      value: routeQueryFilterParams.status,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: TaxiUnsettledOrderListingRouteQueryParams
): TaxiUnsettledOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: TaxiUnsettledOrderListingFilter[],
  sortKey: TaxiUnsettledOrderListingSortKey | null,
  page: number,
  pageSize: number
): TaxiUnsettledOrderListingRouteQueryParams => {
  const routeQueryParams: TaxiUnsettledOrderListingRouteQueryParams = {
    client: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.CLIENT
    )?.value as TaxiUnsettledOrderListingClientFilter["value"] | undefined,
    dispatch: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.DISPATCH
    )?.value as TaxiUnsettledOrderListingDispatchFilter["value"] | undefined,
    driver: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.DRIVER
    )?.value as TaxiUnsettledOrderListingDriverFilter["value"] | undefined,
    externalOrderId: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledOrderListingExternalOrderIdFilter["value"]
      | undefined,
    internalOrderId: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledOrderListingInternalOrderIdFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.PASSENGER
    )?.value as TaxiUnsettledOrderListingPassengerFilter["value"] | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | TaxiUnsettledOrderListingRouteAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | TaxiUnsettledOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | TaxiUnsettledOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | TaxiUnsettledOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledOrderListingFilterType.START_DATE
      )?.value as TaxiUnsettledOrderListingStartDateFilter["value"] | undefined
    )?.from?.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledOrderListingFilterType.START_DATE
      )?.value as TaxiUnsettledOrderListingStartDateFilter["value"] | undefined
    )?.to?.toJSON(),
    status: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.STATUS
    )?.value as TaxiUnsettledOrderListingStatusFilter["value"] | undefined,
    taxi: filters.find(
      (filter) => filter.type === TaxiUnsettledOrderListingFilterType.TAXI
    )?.value as TaxiUnsettledOrderListingTaxiFilter["value"] | undefined,
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const getPage = (
  routeQueryFilterParams: TaxiUnsettledOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: TaxiUnsettledOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: TaxiUnsettledOrderListingRouteQueryFilterParams
) => {
  const filterParams: TaxiUnsettledOrderListingRouteQueryFilterParams = {
    client: routeQueryFilterParams.client,
    dispatch: routeQueryFilterParams.dispatch,
    driver: routeQueryFilterParams.driver,
    externalOrderId: routeQueryFilterParams.externalOrderId,
    internalOrderId: routeQueryFilterParams.internalOrderId,
    passenger: routeQueryFilterParams.passenger,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
    taxi: routeQueryFilterParams.taxi,
  };

  const validationSchema =
    Joi.object<TaxiUnsettledOrderListingRouteQueryFilterParams>({
      client: Joi.string(),
      dispatch: Joi.string(),
      driver: Joi.string(),
      externalOrderId: Joi.string(),
      internalOrderId: Joi.string(),
      passenger: Joi.string(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      startDateFrom: Joi.date(),
      startDateTo: Joi.date(),
      taxi: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: TaxiUnsettledOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(TaxiUnsettledOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: TaxiUnsettledOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: TaxiUnsettledOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const taxiUnsettledOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiUnsettledOrderListingRouteQueryParamsService;
