import jwt_decode from "jwt-decode";
import JwtPayload from "./jwt-payload";

const decode = <T>(token: string): JwtPayload<T> | null => {
  const data = jwt_decode(token);

  if (!data) {
    return null;
  }

  return jwt_decode(token) as JwtPayload<T> | null;
};

const jwtService = {
  decode,
};

export default jwtService;
