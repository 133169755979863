import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import { MileageAddRouteItem } from "./mileage-add-routes.types";

const getNewEmptyRoute = (): MileageAddRouteItem => {
  return {
    uuid: uuidService.generate(),
    address: null,
  };
};

const mileageAddRoutesHelper = {
  getNewEmptyRoute,
};

export default mileageAddRoutesHelper;
