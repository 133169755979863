import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import SignInComponent from "../../sign-in/sign-in.component";
import SignUpComponent from "../../sign-up/sign-up.component";
import authRoutesUrls from "./auth-routes-urls";

const authRoutes: RouteItemDefinition[] = [
  {
    path: authRoutesUrls.signIn,
    component: <SignInComponent />,
  },
  {
    path: authRoutesUrls.signUp,
    component: <SignUpComponent />,
  },
];

export default authRoutes;
