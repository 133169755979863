import authRoutesUrls from "./auth-routes-urls";

const getSignInRoute = (): string => {
  return authRoutesUrls.signIn;
};

const getSignUpRoute = (): string => {
  return authRoutesUrls.signUp;
};

const authRoutesHelper = {
  getSignInRoute,
  getSignUpRoute,
};

export default authRoutesHelper;
