import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import CargoAddressType from "../../../common/types/cargo-address-type";
import CargoAddressListingTableColumn from "../types/cargo-address-listing-table-column";

const getTableColumns = (): CargoAddressListingTableColumn[] => {
  const translations =
    cargoTranslationsHelper.getAddressListingTranslations().table.headers;

  const tableColumns: CargoAddressListingTableColumn[] = [
    {
      header: translations.descriptionLabel,
      title: translations.descriptionTitle,
      accessor: "description",
      colSpan: 10,
    },
    {
      header: translations.countryLabel,
      title: translations.countryTitle,
      accessor: "country",
      colSpan: 10,
    },
    {
      header: translations.townLabel,
      title: translations.townTitle,
      accessor: "town",
      colSpan: 10,
    },
    {
      header: translations.zipCodeLabel,
      title: translations.zipCodeTitle,
      accessor: "zipCode",
      colSpan: 10,
    },
    {
      header: translations.streetLabel,
      title: translations.streetTitle,
      accessor: "street",
      colSpan: 10,
    },
    {
      header: translations.houseApartmentNumberLabel,
      title: translations.houseApartmentNumberTitle,
      accessor: "houseApartmentNumber",
      colSpan: 10,
    },
    {
      header: translations.typeLabel,
      title: translations.typeTitle,
      accessor: "type",
      colSpan: 10,
    },
    {
      header: translations.geoCoordinatesLabel,
      title: translations.geoCoordinatesTitle,
      accessor: "geoCoordinates",
      colSpan: 10,
    },
    {
      header: translations.actionsLabel,
      title: translations.actionsTitle,
      accessor: "actions",
      colSpan: 10,
    },
  ];

  return tableColumns;
};

const getAddressTypeLabel = (addressType: CargoAddressType): string => {
  const translations =
    cargoTranslationsHelper.getAddressListingTranslations().table.addressType;

  switch (addressType) {
    case CargoAddressType.HOTEL:
      return translations.hotel;
    case CargoAddressType.MEETING_POINT:
      return translations.meetingPoint;
    case CargoAddressType.STATION:
      return translations.station;
  }
};

const cargoAddressListingHelper = {
  getTableColumns,
  getAddressTypeLabel,
};

export default cargoAddressListingHelper;
