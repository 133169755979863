import {
  faChain,
  faChevronDown,
  faChevronUp,
  faEdit,
  faEllipsisVertical,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../../../../../../context/app.context";
import { OrderRouteEditInternalPassenger } from "../../../types/order-route-edit-passenger";
import { OrderRouteEditRouteWaypoint } from "../../../types/order-route-edit-route-waypoint";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import OrderRouteEditCargoAddressesResponse, {
  OrderRouteEditCargoAddressesResponseDataAddress,
} from "../../../api/order-route-edit-cargo-addresses.response";
import debounce from "lodash/debounce";
import ButtonComponent from "../../../../../../../common/components/button/button.component";
import NumericInputComponent from "../../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import orderRouteEditApiService from "../../../api/order-route-edit-api.service";
import OrderRouteEditCargoAddressesRequest from "../../../api/order-route-edit-cargo-addresses-request";
import orderRouteEditFactory from "../../../factory/order-route-edit.factory";
import OrderRouteEditCargoAddress from "../../../types/order-route-edit-cargo-address";
import OrderRouteEditContractor from "../../../types/order-route-edit-contractor";
import OrderRouteEditPassengerListItem from "../../../types/order-route-edit-passenger-list-item";
import OrderRouteEditRouteAddress from "../../../types/order-route-edit-route-address";
import OrderRouteEditRouteAddressSelectOption from "../../../types/order-route-edit-route-address-select-option";
import OrderRouteEditRoutesPassengerIconComponent from "../../passenger-icon/order-route-edit-routes-passenger-icon.component";
import orderRouteEditRoutesFactory from "../order-route-edit-routes.factory";
import orderRouteEditRoutesHelper from "../order-route-edit-routes.helper";
import orderRouteEditRouteWaypointHelper from "./order-route-edit-route-waypoint.helper";
import orderRouteEditHelper from "../../../helper/order-route-edit.helper";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";
import DateTimeInputComponent from "../../../../../../../common/components/form/input/date-time/date-time-input.component";
import SelectOption from "../../../../../../../common/components/form/select/common/option/select-option";
import RoutesWaypointAddressAddFormComponent from "../../../../../common/routes/waypoint-address-add-form/routes-waypoint-address-add-form.component";
import useOpen from "../../../../../../../common/hooks/use-open";
import RoutesWaypointAddressAddFormAddressData from "../../../../../common/routes/waypoint-address-add-form/types/routes-waypoint-address-add-form-adress-data";

type OrderRouteEditRouteWaypointProps = {
  number: number;
  waypoint: OrderRouteEditRouteWaypoint;
  updateWaypoint: (updatedWaypoint: OrderRouteEditRouteWaypoint) => void;
  deleteWaypoint: () => void;
  onboardingPassengerCandidateList: OrderRouteEditPassengerListItem[];
  outboardingPassengerCandidateList: OrderRouteEditPassengerListItem[];
  isJoinWaypointIntoGroupButtonVisible: boolean;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onJoinWaypointIntoGroupButtonClick: () => void;
  passengerList: OrderRouteEditPassengerListItem[];
  selectedContractorUuid: OrderRouteEditContractor["uuid"] | null;
};

const OrderRouteEditRouteWaypointComponent: FC<
  OrderRouteEditRouteWaypointProps
> = (props) => {
  const translations = orderTranslationsHelper.getEditTranslations().routes;

  const [isCargoAddressListFetching, setIsCargoAddressListFetching] =
    useState(false);

  const [isCargoAddressListFetchingError, setIsCargoAddressListFetchingError] =
    useState(false);

  const [address, setAddress] = useState<OrderRouteEditRouteAddress | null>(
    null
  );

  const [cargoAddressList, setCargoAddressList] = useState<
    OrderRouteEditCargoAddress[]
  >([]);

  const [addressSearchQuery, setAddressSearchQuery] = useState("");

  const [
    isOnboardingPassengerListExpanded,
    setIsOnboardingPassengerListExpanded,
  ] = useState(false);

  const [
    isOutboardingPassengerListExpanded,
    setIsOutboardingPassengerListExpanded,
  ] = useState(false);

  const { selectedAppLanguage } = useAppContext();
  const { isOpen, open, close } = useOpen();

  const onAddressesFetchSuccess = (
    responseAddresses: OrderRouteEditCargoAddressesResponseDataAddress[]
  ) => {
    const addressList =
      orderRouteEditFactory.createCargoAddresses(responseAddresses);

    setCargoAddressList(addressList);
  };

  const handleAddressesResponse = (
    response: OrderRouteEditCargoAddressesResponse
  ) => {
    if (response.status === 200) {
      onAddressesFetchSuccess(response.data);
      return;
    }

    setIsCargoAddressListFetchingError(true);
  };

  const fetchAddressesDebounced = useCallback(
    debounce((selectedContractorUuid: string, addressSearchQuery: string) => {
      const request: OrderRouteEditCargoAddressesRequest = {
        search_query: addressSearchQuery,
      };

      orderRouteEditApiService
        .fetchCargoAddresses(selectedContractorUuid, request)
        .then(handleAddressesResponse)
        .finally(() => {
          setIsCargoAddressListFetching(false);
        });
    }, 500),
    []
  );

  useEffect(() => {
    if (!props.selectedContractorUuid || !addressSearchQuery) {
      return;
    }

    setIsCargoAddressListFetching(true);
    setIsCargoAddressListFetchingError(false);

    fetchAddressesDebounced(props.selectedContractorUuid, addressSearchQuery);
  }, [props.selectedContractorUuid, addressSearchQuery]);

  const changeAddress = (address: RoutesWaypointAddressAddFormAddressData) => {
    const updatedWaypointAddress: OrderRouteEditRouteAddress = orderRouteEditRoutesFactory.createUpdatedWaypointAddress(address);

    const updatedWaypoint: OrderRouteEditRouteWaypoint = {
      ...props.waypoint,
      address: updatedWaypointAddress
    };

    props.updateWaypoint(updatedWaypoint);
  };

  const changeDate = (date: Date | null) => {
    const updatedWaypoint: OrderRouteEditRouteWaypoint = {
      ...props.waypoint,
      date,
    };

    props.updateWaypoint(updatedWaypoint);
  };

  const changeHaltingTimeMinutes = (haltingTimeMinutes: number | null) => {
    const updatedWaypoint: OrderRouteEditRouteWaypoint = {
      ...props.waypoint,
      haltingTimeMinutes,
    };

    props.updateWaypoint(updatedWaypoint);
  };

  const addPassengerToOnboarding = (
    passengerCandidate: OrderRouteEditPassengerListItem
  ) => {
    const newOnboardingPassengerListItems: OrderRouteEditRouteWaypoint["onboardingPassengerListItems"] =
      [...props.waypoint.onboardingPassengerListItems, passengerCandidate];

    const updatedWaypoint: OrderRouteEditRouteWaypoint = {
      ...props.waypoint,
      onboardingPassengerListItems: newOnboardingPassengerListItems,
    };

    props.updateWaypoint(updatedWaypoint);
  };

  const removePassengerFromOnboarding = (passengerListItemUuid: string) => {
    const newOnboardingPassengerListItems: OrderRouteEditRouteWaypoint["onboardingPassengerListItems"] =
      props.waypoint.onboardingPassengerListItems.filter(
        (passengerListItem) => passengerListItem.uuid !== passengerListItemUuid
      );

    const updatedWaypoint: OrderRouteEditRouteWaypoint = {
      ...props.waypoint,
      onboardingPassengerListItems: newOnboardingPassengerListItems,
    };

    props.updateWaypoint(updatedWaypoint);
  };

  const addPassengerToOutboarding = (
    passengerCandidate: OrderRouteEditPassengerListItem
  ) => {
    const newOutboardingPassengerListItems: OrderRouteEditRouteWaypoint["outboardingPassengerListItems"] =
      [...props.waypoint.outboardingPassengerListItems, passengerCandidate];

    const updatedWaypoint: OrderRouteEditRouteWaypoint = {
      ...props.waypoint,
      outboardingPassengerListItems: newOutboardingPassengerListItems,
    };

    props.updateWaypoint(updatedWaypoint);
  };

  const removePassengerFromOutboarding = (passengerListItemUuid: string) => {
    const newOutboardingPassengerListItems: OrderRouteEditRouteWaypoint["outboardingPassengerListItems"] =
      props.waypoint.outboardingPassengerListItems.filter(
        (passengerListItem) => passengerListItem.uuid !== passengerListItemUuid
      );

    const updatedWaypoint: OrderRouteEditRouteWaypoint = {
      ...props.waypoint,
      outboardingPassengerListItems: newOutboardingPassengerListItems,
    };

    props.updateWaypoint(updatedWaypoint);
  };

  const addressSelectOptions = useMemo<
    OrderRouteEditRouteAddressSelectOption[]
  >(() => {
    if (isCargoAddressListFetching) return [];

    if (!addressSearchQuery) {
      const additionalAddress: SelectOption[] = [
        {
          label: translations.waypointAddressAddLabel,
          value: null,
          onClick: () => {
            open();
            setAddress(null);
          },
        },
      ];

      return additionalAddress;
    }

    const getAddressSelectOptionsFromPassengerList =
      (): OrderRouteEditRouteAddressSelectOption[] => {
        const internalPassengers = props.passengerList
          .map((listItem) => listItem.passenger)
          .filter((passenger) =>
            orderRouteEditHelper.checkIsInternalPassenger(passenger)
          ) as OrderRouteEditInternalPassenger[];

        return internalPassengers
          .map((passenger) => {
            return passenger.addresses.map((address) => {
              return orderRouteEditRoutesFactory.createRouteAddressSelectOptionFromPassengerAddress(
                passenger,
                address
              );
            });
          })
          .flat();
      };

    const addressSelectOptionsFromPassengerList: OrderRouteEditRouteAddressSelectOption[] =
      getAddressSelectOptionsFromPassengerList();

    const filteredAddressSelectOptionsFromPassengerList =
      addressSelectOptionsFromPassengerList.filter((option) => {
        return (
          option.label
            .toLowerCase()
            .includes(addressSearchQuery.toLowerCase()) ||
          option.subText
            ?.toLowerCase()
            .includes(addressSearchQuery.toLowerCase())
        );
      });

    const addressSelectOptionsFromCargoAddresses: OrderRouteEditRouteAddressSelectOption[] =
      cargoAddressList.map((cargoAddress) => {
        return orderRouteEditRoutesFactory.createRouteAddressSelectOptionFromCargoAddress(
          cargoAddress
        );
      });

    const selectOptions: OrderRouteEditRouteAddressSelectOption[] = [
      ...filteredAddressSelectOptionsFromPassengerList,
      ...addressSelectOptionsFromCargoAddresses,
    ];

    return selectOptions;
  }, [
    props.passengerList,
    cargoAddressList,
    addressSearchQuery,
    isCargoAddressListFetching,
    selectedAppLanguage,
  ]);

  const addressSelectOption = props.waypoint.address
    ? orderRouteEditFactory.createRouteAddressSelectOption(
        props.waypoint.address
      )
    : null;

  const ExpandOnboardingPassengerListButton = (
    <ButtonComponent
      onClick={() => setIsOnboardingPassengerListExpanded(true)}
      title={translations.waypoint.onboardingPassengersExpandButtonTitle}
      classNames={{
        root: "order_edit_route_list_item_onboarding__collapse_button",
      }}
    >
      <FontAwesomeIcon icon={faChevronDown} />
    </ButtonComponent>
  );

  const CollapseOnboardingPassengerListButton = (
    <ButtonComponent
      onClick={() => setIsOnboardingPassengerListExpanded(false)}
      title={translations.waypoint.onboardingPassengersCollapseButtonTitle}
      classNames={{
        root: "order_edit_route_list_item_onboarding__collapse_button",
      }}
    >
      <FontAwesomeIcon icon={faChevronUp} />
    </ButtonComponent>
  );

  const ExpandOutboardingPassengerListButton = (
    <ButtonComponent
      onClick={() => setIsOutboardingPassengerListExpanded(true)}
      title={translations.waypoint.outboardingPassengersExpandButtonTitle}
      classNames={{
        root: "order_edit_route_list_item_outboarding__collapse_button",
      }}
    >
      <FontAwesomeIcon icon={faChevronDown} />
    </ButtonComponent>
  );

  const CollapseOutboardingPassengerListButton = (
    <ButtonComponent
      onClick={() => setIsOutboardingPassengerListExpanded(false)}
      title={translations.waypoint.outboardingPassengersCollapseButtonTitle}
      classNames={{
        root: "order_edit_route_list_item_outboarding__collapse_button",
      }}
    >
      <FontAwesomeIcon icon={faChevronUp} />
    </ButtonComponent>
  );

  return (
    <div className="order_edit_route_list_item">
      <div className="order_edit_route_list_item__top_row">
        <button
          className="order_edit_route_list_item__drag_button"
          title={translations.waypoint.dragButtonTitle}
          {...props.dragHandleProps}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </button>
        <div className="order_edit_route_list_item__index">{props.number}</div>
        <ButtonComponent
          onClick={() => {
            open();
            setAddress(addressSelectOption?.value ?? null);
          }}
          title={translations.waypoint.waypointAddressEdiitLabel}
          classNames={{
            root: "order_edit_route_list_item__action_button",
          }}
          isDisabled={!props.waypoint.address}
        >
          <FontAwesomeIcon icon={faEdit} />
        </ButtonComponent>
        <SingleSelectComponent
          onChange={(value) => changeAddress(value?.value)}
          options={addressSelectOptions}
          defaultValue={addressSelectOption}
          value={addressSelectOption}
          placeholder={translations.waypoint.addressSelectText}
          classNames={{
            root: `order_edit_route_list_item__address`,
          }}
          isLoading={isCargoAddressListFetching}
          isDisabled={isCargoAddressListFetchingError}
          maxMenuHeight={500}
          isSearchable
          filterFunction={() => true}
          inputValue={addressSearchQuery}
          onInputChange={setAddressSearchQuery}
          isClearable
          noOptionsMessage={(inputValue) => {
            if (inputValue) {
              return translations.waypoint.addressSelectSearchNoOptionsMessage;
            }
            return translations.waypoint.addressSelectSearchTipMessage;
          }}
        />
        <div className="order_edit_route_list_item__date">
          <DateTimeInputComponent
            date={props.waypoint.date}
            onChange={changeDate}
          />
        </div>
        <NumericInputComponent
          placeholder={translations.waypoint.haltingTimeInput}
          value={props.waypoint.haltingTimeMinutes}
          isIntegerOnly
          onChange={changeHaltingTimeMinutes}
          classNames={{
            root: "order_edit_route_list_item__halting_time",
          }}
        />
        <ButtonComponent
          onClick={props.deleteWaypoint}
          classNames={{
            root: "order_edit_route_list_item__action_button",
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </ButtonComponent>
      </div>
      <div className="order_edit_route_list_item__bottom_row">
        <div className="order_edit_route_list_item_onboarding">
          <div className="order_edit_route_list_item_onboarding__collapsed">
            <ul className="order_edit_route_list_item_onboarding__collapsed_passenger_list">
              {props.waypoint.onboardingPassengerListItems.map(
                (onboardingPassengerListItem) => {
                  const passengerIndex =
                    orderRouteEditRoutesHelper.getIndexOfPassenger(
                      props.passengerList,
                      onboardingPassengerListItem.uuid
                    );

                  if (passengerIndex === undefined) {
                    return null;
                  }

                  const iconTitle =
                    orderRouteEditRouteWaypointHelper.getPassengerIconTitle(
                      onboardingPassengerListItem.passenger
                    );

                  return (
                    <li
                      key={`onboarded-passenger-${onboardingPassengerListItem.uuid}`}
                      className="order_edit_route_list_item_onboarding__collapsed_passenger_list_item"
                      title={iconTitle}
                    >
                      <OrderRouteEditRoutesPassengerIconComponent
                        number={passengerIndex + 1}
                      />
                    </li>
                  );
                }
              )}
            </ul>
            {isOnboardingPassengerListExpanded
              ? CollapseOnboardingPassengerListButton
              : ExpandOnboardingPassengerListButton}
          </div>
          {isOnboardingPassengerListExpanded && (
            <>
              <div>
                <ul className="order_edit_route_list_item_onboarding__expanded_passenger_list">
                  {props.waypoint.onboardingPassengerListItems.map(
                    (onboardingPassengerListItem) => {
                      const { passenger } = onboardingPassengerListItem;

                      const passengerIndex =
                        orderRouteEditRoutesHelper.getIndexOfPassenger(
                          props.passengerList,
                          onboardingPassengerListItem.uuid
                        );

                      if (passengerIndex === undefined) {
                        return null;
                      }

                      const buttonLabel =
                        orderRouteEditRouteWaypointHelper.getPassengerButtonLabel(
                          passenger
                        );

                      const buttonTitle =
                        orderRouteEditRouteWaypointHelper.getPassengerButtonTitle(
                          passenger
                        );

                      return (
                        <li
                          key={`onboarded-passenger-${onboardingPassengerListItem.uuid}-${passenger.uuid}`}
                          className="order_edit_route_list_item_onboarding__expanded_passenger_list_item"
                          title={buttonTitle}
                        >
                          <OrderRouteEditRoutesPassengerIconComponent
                            number={passengerIndex + 1}
                          />
                          <ButtonComponent
                            onClick={() =>
                              removePassengerFromOnboarding(
                                onboardingPassengerListItem.uuid
                              )
                            }
                            classNames={{
                              root: "order_edit_route_list_item_onboarding__passenger_button",
                            }}
                          >
                            {buttonLabel}
                          </ButtonComponent>
                        </li>
                      );
                    }
                  )}
                </ul>
                <ul className="order_edit_route_list_item_onboarding__passenger_candidate_list">
                  {props.onboardingPassengerCandidateList.map(
                    (onboardingPassengerCandidateListItem) => {
                      const { passenger } =
                        onboardingPassengerCandidateListItem;

                      const passengerIndex =
                        orderRouteEditRoutesHelper.getIndexOfPassenger(
                          props.passengerList,
                          onboardingPassengerCandidateListItem.uuid
                        );

                      if (passengerIndex === undefined) {
                        return null;
                      }

                      const buttonLabel =
                        orderRouteEditRouteWaypointHelper.getPassengerButtonLabel(
                          passenger
                        );

                      const buttonTitle =
                        orderRouteEditRouteWaypointHelper.getPassengerButtonTitle(
                          passenger
                        );

                      return (
                        <li
                          key={`onboarded-passenger-candidate-${passenger.uuid}`}
                          className="order_edit_route_list_item_onboarding__expanded_passenger_list_item"
                          title={buttonTitle}
                        >
                          <OrderRouteEditRoutesPassengerIconComponent
                            number={passengerIndex + 1}
                          />
                          <ButtonComponent
                            onClick={() =>
                              addPassengerToOnboarding(
                                onboardingPassengerCandidateListItem
                              )
                            }
                            classNames={{
                              root: "order_edit_route_list_item_onboarding__passenger_candidate_button",
                            }}
                          >
                            {buttonLabel}
                          </ButtonComponent>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
        <div>
          {props.isJoinWaypointIntoGroupButtonVisible && (
            <ButtonComponent
              onClick={props.onJoinWaypointIntoGroupButtonClick}
              title={translations.waypoint.joinWaypointIntoGroupButtonTitle}
            >
              <FontAwesomeIcon icon={faChain} size="sm" />
            </ButtonComponent>
          )}
        </div>
        <div className="order_edit_route_list_item_outboarding">
          <div className="order_edit_route_list_item_outboarding__collapsed">
            <ul className="order_edit_route_list_item_outboarding__collapsed_passenger_list">
              {props.waypoint.outboardingPassengerListItems.map(
                (outboardingPassengerListItem) => {
                  const passengerIndex =
                    orderRouteEditRoutesHelper.getIndexOfPassenger(
                      props.passengerList,
                      outboardingPassengerListItem.uuid
                    );

                  if (passengerIndex === undefined) {
                    return null;
                  }

                  const iconTitle =
                    orderRouteEditRouteWaypointHelper.getPassengerIconTitle(
                      outboardingPassengerListItem.passenger
                    );

                  return (
                    <li
                      key={`order_edit_route_passenger_icon_${outboardingPassengerListItem.uuid}`}
                      className="order_edit_route_list_item_outboarding__collapsed_passenger_list_item"
                      title={iconTitle}
                    >
                      <OrderRouteEditRoutesPassengerIconComponent
                        number={passengerIndex + 1}
                      />
                    </li>
                  );
                }
              )}
            </ul>
            {isOutboardingPassengerListExpanded
              ? CollapseOutboardingPassengerListButton
              : ExpandOutboardingPassengerListButton}
          </div>
          {isOutboardingPassengerListExpanded && (
            <>
              <ul className="order_edit_route_list_item_outboarding__expanded_passenger_list">
                {props.waypoint.outboardingPassengerListItems.map(
                  (outboardingPassengerListItem) => {
                    const { passenger } = outboardingPassengerListItem;

                    const passengerIndex =
                      orderRouteEditRoutesHelper.getIndexOfPassenger(
                        props.passengerList,
                        outboardingPassengerListItem.uuid
                      );

                    if (passengerIndex === undefined) {
                      return null;
                    }

                    const buttonLabel =
                      orderRouteEditRouteWaypointHelper.getPassengerButtonLabel(
                        passenger
                      );

                    const buttonTitle =
                      orderRouteEditRouteWaypointHelper.getPassengerButtonTitle(
                        passenger
                      );

                    return (
                      <li
                        key={`outboarding-passenger-${passenger.uuid}`}
                        className="order_edit_route_list_item_outboarding__expanded_passenger_list_item"
                        title={buttonTitle}
                      >
                        <OrderRouteEditRoutesPassengerIconComponent
                          number={passengerIndex + 1}
                        />
                        <ButtonComponent
                          onClick={() =>
                            removePassengerFromOutboarding(
                              outboardingPassengerListItem.uuid
                            )
                          }
                          classNames={{
                            root: "order_edit_route_list_item_outboarding__passenger_button",
                          }}
                        >
                          {buttonLabel}
                        </ButtonComponent>
                      </li>
                    );
                  }
                )}
              </ul>
              <ul className="order_edit_route_list_item_outboarding__passenger_candidate_list">
                {props.outboardingPassengerCandidateList.map(
                  (outboardingPassengerCandidateListItem) => {
                    const { passenger } = outboardingPassengerCandidateListItem;

                    const passengerIndex =
                      orderRouteEditRoutesHelper.getIndexOfPassenger(
                        props.passengerList,
                        outboardingPassengerCandidateListItem.uuid
                      );

                    if (passengerIndex === undefined) {
                      return null;
                    }

                    const buttonLabel =
                      orderRouteEditRouteWaypointHelper.getPassengerButtonLabel(
                        passenger
                      );

                    const buttonTitle =
                      orderRouteEditRouteWaypointHelper.getPassengerButtonTitle(
                        passenger
                      );

                    return (
                      <li
                        key={`outboarding-passenger-candidate-${passenger.uuid}`}
                        className="order_edit_route_list_item_outboarding__expanded_passenger_list_item"
                        title={buttonTitle}
                      >
                        <OrderRouteEditRoutesPassengerIconComponent
                          number={passengerIndex + 1}
                        />
                        <ButtonComponent
                          onClick={() =>
                            addPassengerToOutboarding(
                              outboardingPassengerCandidateListItem
                            )
                          }
                          classNames={{
                            root: "order_edit_route_list_item_outboarding__passenger_candidate_button",
                          }}
                        >
                          {buttonLabel}
                        </ButtonComponent>
                      </li>
                    );
                  }
                )}
              </ul>
            </>
          )}
        </div>
      </div>
      <RoutesWaypointAddressAddFormComponent
        isOpen={isOpen}
        onClose={close}
        onAddNewAddress={changeAddress}
      />
    </div>
  );
};

export default OrderRouteEditRouteWaypointComponent;
