enum RouteFinishedListingSortKey {
  DRIVER_NAME_ASC = "DRIVER_NAME_ASC",
  DRIVER_NAME_DESC = "DRIVER_NAME_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  TAXI_CORPORATION_ASC = "TAXI_CORPORATION_ASC",
  TAXI_CORPORATION_DESC = "TAXI_CORPORATION_DESC",
}

export default RouteFinishedListingSortKey;
