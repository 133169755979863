import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent } from "../../../common/types/order-details-handling-log-entry-event";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations()
    .assignmentOrderNewPlan;

const getTaxiCorporationName = (
  taxiCorporationName: OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent["taxiCorporationName"]
) => {
  return translations.taxiCorporationNameLabel.replace(
    "#{taxiCorporationName}",
    taxiCorporationName ?? "-----"
  );
};

const getDriverName = (
  driverName: OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent["driverName"]
) => {
  return translations.driverNameLabel.replace(
    "#{driverName}",
    driverName ?? "-----"
  );
};

const orderDetailsHistoryEntryAssignmentOrderNewPlanContentHelper = {
  getTaxiCorporationName,
  getDriverName,
};

export default orderDetailsHistoryEntryAssignmentOrderNewPlanContentHelper;
