import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import TaxiTaxiContractListingFilter, {
  TaxiTaxiContractListingBillingModelFilter,
  TaxiTaxiContractListingConsumerDisplayNameFilter,
  TaxiTaxiContractListingDisplayNameFilter,
  TaxiTaxiContractListingDistanceRateFilter,
  TaxiTaxiContractListingFreeStoppingPeriodFilter,
  TaxiTaxiContractListingPeriodOfValidationFilter,
  TaxiTaxiContractListingProducerDisplayNameFilter,
  TaxiTaxiContractListingStopRateFilter,
  TaxiTaxiContractListingPriorityFilter,
  TaxiTaxiContractListingActivityStatusFilter,
  TaxiTaxiContractListingPartnerFilter,
} from "../common/types/taxi-taxi-contract-listing-filter";
import TaxiTaxiContractListingFilterType from "../common/types/taxi-taxi-contract-listing-filter-type";
import TaxiTaxiContractListingSortKey from "../common/types/taxi-taxi-contract-listing-sort-key";
import taxiTranslationsHelper from "../../../../../languages/taxi-translations.helper";
import dateService from "../../../../../common/utils/date/date.service";
import taxiTaxiContractListingHelper from "../common/taxi-taxi-contract-listing.helper";
import BillingModel from "../../../../../common/types/billing-model";

const taxiTaxiContractListingFilterHelper = () => {
  const translations =
    taxiTranslationsHelper.getTaxiContractListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForDisplayName = (
    filterValue: TaxiTaxiContractListingDisplayNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.displayNameLabel,
      translations.filters.displayNameTitle
    );
  };

  const getBadgeForProducerDisplayName = (
    filterValue: TaxiTaxiContractListingProducerDisplayNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.producerDisplayNameLabel,
      translations.filters.producerDisplayNameTitle
    );
  };

  const getBadgeForConsumerDisplayName = (
    filterValue: TaxiTaxiContractListingConsumerDisplayNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.consumerDisplayNameLabel,
      translations.filters.consumerDisplayNameTitle
    );
  };

  const getBadgeForBillingModel = (
    filterValue: TaxiTaxiContractListingBillingModelFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.billingModelLabel,
      translations.filters.billingModelTitle
    );
  };

  const getBadgeForPeriodOfValidation = (
    filterValue: TaxiTaxiContractListingPeriodOfValidationFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const validFromLabel = dateService.formatDate(filterValue.from);
    const validToLabel = dateService.formatDate(filterValue.to);

    return createBadgeData(
      `${validFromLabel} -
        ${validToLabel}`,
      translations.filters.periodOfValidationLabel,
      translations.filters.periodOfValidationTitle
    );
  };

  const getBadgeForDistanceRate = (
    filterValue: TaxiTaxiContractListingDistanceRateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const createValueLabel = (value: number) =>
      translations.filters.distanceRateValueLabel.replace(
        "#{rate}",
        String(value)
      );
    return createBadgeData(
      `${createValueLabel(filterValue.from)} - ${createValueLabel(
        filterValue.to
      )}`,
      translations.filters.distanceRateLabel,
      translations.filters.distanceRateTitle
    );
  };

  const getBadgeForFreeStoppingPeriod = (
    filterValue: TaxiTaxiContractListingFreeStoppingPeriodFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const createValueLabel = (value: number) =>
      translations.filters.freeStoppingPeriodValueLabel.replace(
        "#{time}",
        String(value)
      );
    return createBadgeData(
      `${createValueLabel(filterValue.from)} - ${createValueLabel(
        filterValue.to
      )}`,
      translations.filters.freeStoppingPeriodLabel,
      translations.filters.freeStoppingPeriodTitle
    );
  };

  const getBadgeForStopRateRange = (
    filterValue: TaxiTaxiContractListingStopRateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const createValueLabel = (value: number) =>
      translations.filters.stoppingRateValueLabel.replace(
        "#{rate}",
        String(value)
      );
    return createBadgeData(
      `${createValueLabel(filterValue.from)} -
        ${createValueLabel(filterValue.to)}`,
      translations.filters.stopRateRangeLabel,
      translations.filters.stopRateRangeTitle
    );
  };

  const getBadgeForActivityStatus = (
    filterValue: TaxiTaxiContractListingActivityStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText =
      taxiTaxiContractListingHelper.getActivityStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.activityStatusLabel,
      translations.filters.activityStatusTitle
    );
  };

  const getBadgeForPartner = (
    filterValue: TaxiTaxiContractListingPartnerFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText =
      taxiTaxiContractListingHelper.getPartnerStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.partnerLabel,
      translations.filters.partnerTitle
    );
  };

  const getBadgeForPriority = (
    filterValue: TaxiTaxiContractListingPriorityFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${filterValue}`,
      translations.filters.priorityLabel,
      translations.filters.priorityTitle
    );
  };

  const getSelectDataForDisplayName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.displayNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForProducerDisplayName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.producerDisplayNameTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForConsumerDisplayName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.consumerDisplayNameTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForBillingModel = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    const searchFilterTranslations =
      taxiTranslationsHelper.getTaxiContractListingTranslations().filters
        .search;

    const allBillingModelSelectOptions = Object.values(BillingModel).map(
      (billingModel) => {
        const billingModelLabel =
          searchFilterTranslations.billingModelTemplateLabel.replace(
            "#{query}",
            billingModel
          );

        return {
          label: billingModelLabel,
          value: billingModel,
        };
      }
    );

    if (query) {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: allBillingModelSelectOptions.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        ),
      };
    }

    return {
      type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
      options: allBillingModelSelectOptions,
    };
  };

  const getSelectDataForPeriodOfValidation =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.periodOfValidationTemplateLabel,
        },
      };
    };

  const getSelectDataForDistanceRate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_RANGE,
        option: {
          label: translations.filters.search.distanceRateTemplateLabel,
        },
      };
    };

  const getSelectDataForFreeStoppingPeriod =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_RANGE,
        option: {
          label: translations.filters.search.freeStoppingPeriodTemplateLabel,
        },
      };
    };

  const getSelectDataForStopRateRange =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_RANGE,
        option: {
          label: translations.filters.search.stopRateRangeTemplateLabel,
        },
      };
    };

  const getSelectDataForActivityStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                taxiTaxiContractListingHelper.getActivityStatusText(true)
              ),
            value: true,
          },
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                taxiTaxiContractListingHelper.getActivityStatusText(false)
              ),
            value: false,
          },
        ],
      };
    };

  const getSelectDataForPartner =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.partnerLabel,
            value: true,
          },
          {
            label: translations.filters.search.noPartnerLabel,
            value: false,
          },
        ],
      };
    };

  const getSelectDataForPriority = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    const searchFilterTranslations =
      taxiTranslationsHelper.getTaxiContractListingTranslations().filters
        .search;
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label: searchFilterTranslations.priorityStatusTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: TaxiTaxiContractListingFilterType,
    query: string
  ) => {
    const options: Record<
      TaxiTaxiContractListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [TaxiTaxiContractListingFilterType.PRIORITY]: getSelectDataForPriority,
      [TaxiTaxiContractListingFilterType.PERIOD_OF_VALIDATION]:
        getSelectDataForPeriodOfValidation,
      [TaxiTaxiContractListingFilterType.DISTANCE_RATE_RANGE]:
        getSelectDataForDistanceRate,
      [TaxiTaxiContractListingFilterType.FREE_STOPPING_PERIOD]:
        getSelectDataForFreeStoppingPeriod,
      [TaxiTaxiContractListingFilterType.STOP_RATE_RANGE]:
        getSelectDataForStopRateRange,
      [TaxiTaxiContractListingFilterType.DISPLAY_NAME]:
        getSelectDataForDisplayName,
      [TaxiTaxiContractListingFilterType.PRODUCER_DISPLAY_NAME]:
        getSelectDataForProducerDisplayName,
      [TaxiTaxiContractListingFilterType.CONSUMER_DISPLAY_NAME]:
        getSelectDataForConsumerDisplayName,
      [TaxiTaxiContractListingFilterType.BILLING_MODEL]:
        getSelectDataForBillingModel,
      [TaxiTaxiContractListingFilterType.ACTIVE_STATUS]:
        getSelectDataForActivityStatus,
      [TaxiTaxiContractListingFilterType.PARTNER]: getSelectDataForPartner,
    };

    return options[filterType](query);
  };

  const getBadgeDefinitionByType = (
    filterType: TaxiTaxiContractListingFilterType,
    filterValue: TaxiTaxiContractListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      TaxiTaxiContractListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [TaxiTaxiContractListingFilterType.DISPLAY_NAME]: () =>
        getBadgeForDisplayName(
          filterValue as TaxiTaxiContractListingDisplayNameFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.PRODUCER_DISPLAY_NAME]: () =>
        getBadgeForProducerDisplayName(
          filterValue as TaxiTaxiContractListingProducerDisplayNameFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.CONSUMER_DISPLAY_NAME]: () =>
        getBadgeForConsumerDisplayName(
          filterValue as TaxiTaxiContractListingConsumerDisplayNameFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.BILLING_MODEL]: () =>
        getBadgeForBillingModel(
          filterValue as TaxiTaxiContractListingBillingModelFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.PERIOD_OF_VALIDATION]: () =>
        getBadgeForPeriodOfValidation(
          filterValue as TaxiTaxiContractListingPeriodOfValidationFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.DISTANCE_RATE_RANGE]: () =>
        getBadgeForDistanceRate(
          filterValue as TaxiTaxiContractListingDistanceRateFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.FREE_STOPPING_PERIOD]: () =>
        getBadgeForFreeStoppingPeriod(
          filterValue as TaxiTaxiContractListingFreeStoppingPeriodFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.STOP_RATE_RANGE]: () =>
        getBadgeForStopRateRange(
          filterValue as TaxiTaxiContractListingStopRateFilter["value"]
        ),

      [TaxiTaxiContractListingFilterType.ACTIVE_STATUS]: () =>
        getBadgeForActivityStatus(
          filterValue as TaxiTaxiContractListingActivityStatusFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.PRIORITY]: () =>
        getBadgeForPriority(
          filterValue as TaxiTaxiContractListingPriorityFilter["value"]
        ),
      [TaxiTaxiContractListingFilterType.PARTNER]: () =>
        getBadgeForPartner(
          filterValue as TaxiTaxiContractListingPartnerFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: TaxiTaxiContractListingFilterType
  ): ListingFilterDefinitionOption<TaxiTaxiContractListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<TaxiTaxiContractListingFilter>[] => {
      return Object.values(TaxiTaxiContractListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<TaxiTaxiContractListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.displayNameAscLabel,
        value: TaxiTaxiContractListingSortKey.DISPLAY_NAME_ASC,
      },
      {
        label: translations.sort.displayNameDescLabel,
        value: TaxiTaxiContractListingSortKey.DISPLAY_NAME_DESC,
      },
      {
        label: translations.sort.producerDisplayNameAscLabel,
        value: TaxiTaxiContractListingSortKey.PRODUCER_DISPLAY_NAME_ASC,
      },
      {
        label: translations.sort.producerDisplayNameDescLabel,
        value: TaxiTaxiContractListingSortKey.PRODUCER_DISPLAY_NAME_DESC,
      },
      {
        label: translations.sort.consumerDisplayNameAscLabel,
        value: TaxiTaxiContractListingSortKey.CONSUMER_DISPLAY_NAME_ASC,
      },
      {
        label: translations.sort.consumerDisplayNameDescLabel,
        value: TaxiTaxiContractListingSortKey.CONSUMER_DISPLAY_NAME_DESC,
      },
      {
        label: translations.sort.priorityAscLabel,
        value: TaxiTaxiContractListingSortKey.PRIORITY_ASC,
      },
      {
        label: translations.sort.priorityDescLabel,
        value: TaxiTaxiContractListingSortKey.PRIORITY_DESC,
      },
      {
        label: translations.sort.stoppingRateAscLabel,
        value: TaxiTaxiContractListingSortKey.HALTING_TIME_RATE_ASC,
      },
      {
        label: translations.sort.stoppingRateDescLabel,
        value: TaxiTaxiContractListingSortKey.HALTING_TIME_RATE_DESC,
      },
      {
        label: translations.sort.distanceRateAscLabel,
        value: TaxiTaxiContractListingSortKey.DISTANCE_RATE_ASC,
      },
      {
        label: translations.sort.distanceRateDescLabel,
        value: TaxiTaxiContractListingSortKey.DISTANCE_RATE_DESC,
      },
      {
        label: translations.sort.freeStoppingPeriodMinutesAscLabel,
        value: TaxiTaxiContractListingSortKey.HALTING_TIME_AFTER_MINUTES_ASC,
      },
      {
        label: translations.sort.freeStoppingPeriodMinutesAscLabel,
        value: TaxiTaxiContractListingSortKey.HALTING_TIME_AFTER_MINUTES_DESC,
      },
    ];
  };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default taxiTaxiContractListingFilterHelper;
