import { CargoOfficerListItem } from "../../../../../common/services/cargo-officer/list/cargo-officer-list";
import UserStatus from "../../../../../common/types/user-status";
import phoneNumberService from "../../../../../common/utils/phone-number/phone-number.service";
import CargoOfficerListingItem from "./types/cargo-officer-listing-item";

const createPhoneNumbers = (
  listItem: CargoOfficerListItem
): CargoOfficerListingItem["phoneNumbers"] => {
  const phoneNumbers: CargoOfficerListingItem["phoneNumbers"] = [
    phoneNumberService.getLabel(listItem.phoneNumber),
  ];

  if (listItem.alternativePhoneNumber) {
    phoneNumbers.push(
      phoneNumberService.getLabel(listItem.alternativePhoneNumber)
    );
  }

  return phoneNumbers;
};

const createListingItem = (
  listItem: CargoOfficerListItem
): CargoOfficerListingItem => {
  const phoneNumbers = createPhoneNumbers(listItem);

  return {
    uuid: listItem.uuid,
    firstName: listItem.firstName,
    lastName: listItem.lastName,
    username: listItem.username,
    email: listItem.email,
    phoneNumbers: phoneNumbers,
    cargoCompanyName: listItem.cargoCompany.name,
    status: !listItem.removalDate ? UserStatus.ACTIVE : UserStatus.DELETED,
  };
};

const createListingItems = (
  listItems: CargoOfficerListItem[]
): CargoOfficerListingItem[] => {
  return listItems.map(createListingItem);
};

const cargoOfficerListingFactory = {
  createListingItems,
};

export default cargoOfficerListingFactory;
