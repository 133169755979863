import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import useAbort from "../../../../common/hooks/use-abort";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import usePagination from "../../../../common/hooks/use-pagination";
import useTaxiOfficerListingUserPermissions from "./common/user-permissions/use-taxi-officer-listing-user-permission";
import TaxiOfficerListingSortKey from "./common/types/taxi-officer-listing-sort-key";
import TaxiOfficerListingItem from "./common/types/taxi-officer-listing-item";
import taxiOfficerListingLoadParamsFactory from "./common/taxi-officer-listing-load-params.factory";
import TaxiOfficerListingFilter from "./common/types/taxi-officer-listing-filter";
import useTaxiOfficerListingFiltersDao from "./filters/use-taxi-officer-listing-filter.dao";
import TaxiOfficerListingTableComponent from "./table/taxi-officer-listing-table.component";
import TaxiOfficerListingFilterComponent from "./filters/taxi-officer-listing-filter.component";
import taxiOfficerListingItemFactory from "./common/taxi-officer-listing-item.factory";
import UserDeleteUserData from "../../common/delete/common/types/user-delete-user-data";
import UserDeleteComponent from "../../common/delete/user-delete.component";
import TaxiOfficerListLoadParams from "../../../../common/services/taxi-officer/list/taxi-officer-list-load-params";
import useTaxiOfficerList from "../../../../common/services/taxi-officer/list/use-taxi-officer-list";

type TaxiOfficerListingProps = {};

const TaxiOfficerListingComponent: FC<TaxiOfficerListingProps> = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();
  const userPermissions = useTaxiOfficerListingUserPermissions();
  const listingItemsAbort = useAbort();
  const taxiOfficerList = useTaxiOfficerList();
  const isComponentMounted = useIsComponentMounted();
  const filtersDao = useTaxiOfficerListingFiltersDao();
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();
  useDocumentTitle(documentTitleTranslations.userTaxiOfficerListing);

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: taxiOfficerList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const [filters, setFilters] = useState<TaxiOfficerListingFilter[]>(() =>
    filtersDao.getFilters()
  );
  const [sortKey, setSortKey] = useState<TaxiOfficerListingSortKey>(
    () => filtersDao.getSortKey() ?? TaxiOfficerListingSortKey.FIRST_NAME_ASC
  );
  const [userSelectedToDelete, setUserSelectedToDelete] =
    useState<UserDeleteUserData | null>(null);

  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const openDeleteUserModal = () => {
    setIsDeleteUserModalOpen(true);
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  useEffect(() => {
    const breadcrumbs =
      userBreadcrumbsHelper.getTaxiOfficerListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  useEffect(() => {
    if (!isComponentMounted) return;

    saveFilters();
  }, [filters, page, pageSize, sortKey]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  const listingItems: TaxiOfficerListingItem[] = useMemo(() => {
    return taxiOfficerListingItemFactory.createListingItems(
      taxiOfficerList.data.data
    );
  }, [taxiOfficerList.data.data]);

  const saveFilters = () =>
    filtersDao.saveFilters(filters, sortKey, page, pageSize);

  const translations =
    userTranslationsHelper.getTaxiOfficerListingTranslations();

  const onSelectUserToDelete = (userData: UserDeleteUserData) => {
    setUserSelectedToDelete(userData);
    openDeleteUserModal();
  };

  const onDeleteUserSuccess = () => {
    closeDeleteUserModal();
    loadListingItems();
  };

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasVisibleAddTaxiOfficerButton
        ? TaxiOfficerAddLinkButton
        : undefined,
    ];
  };

  const TaxiOfficerAddLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        to={userRoutesHelper.getTaxiOfficerAddRoute()}
        type="primary"
        title={translations.header.addNewTaxiOfficerLinkTitle}
        idForTesting="taxi-officer-listing-add-button"
      >
        {translations.header.addNewTaxiOfficerLinkLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const headingActions = createHeadingActions();

  const loadListingItems = async () => {
    const params: TaxiOfficerListLoadParams =
      taxiOfficerListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey
      );

    taxiOfficerList.load(params, listingItemsAbort.signal);
  };

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <TaxiOfficerListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TaxiOfficerListingTableComponent
          listingItems={listingItems}
          isError={taxiOfficerList.isError}
          isLoading={taxiOfficerList.isLoading}
          onSelectUserToDelete={onSelectUserToDelete}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={taxiOfficerList.data.totalCount}
        />
      </CardComponent>
      <UserDeleteComponent
        isOpen={isDeleteUserModalOpen}
        onClose={closeDeleteUserModal}
        userData={userSelectedToDelete}
        onSuccess={onDeleteUserSuccess}
      />
    </>
  );
};

export default TaxiOfficerListingComponent;
