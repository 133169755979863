import { FC } from "react";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsCompletedRouteComponentProps = {
  details: OrderDetailsCargoOrder | null;
  tools: OrderDetailsToolsTranslations;
  isRouteStarted: boolean;
  isLoading: boolean;
  isActive: boolean;
  onSuccess: () => void;
};

const OrderDetialsCompletedRouteComponent: FC<
  OrderDetialsCompletedRouteComponentProps
> = ({ details, tools, isRouteStarted, isLoading, isActive, onSuccess }) => {
  const { hideCompletionRouteButtonTitle, showCompletionRouteButtonTitle } =
    tools;

  const title = isActive
    ? hideCompletionRouteButtonTitle
    : showCompletionRouteButtonTitle;

  return (
    <OrderDetailsToolButtonComponent
      onClick={onSuccess}
      icon={faMapLocation}
      title={title}
      isDisabled={!isRouteStarted}
      isActive={isActive}
      isLoading={isLoading}
    />
  );
};

export default OrderDetialsCompletedRouteComponent;
