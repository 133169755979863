import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import dealerAddByRailyFormValidationService from "./dealer-add-by-raily-form-validation.service";
import DealerAddByRailyFormData from "./types/dealer-add-by-raily-form-data";

const getDefaultFormData = (): DealerAddByRailyFormData => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
    taxiCorporationUuid: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<DealerAddByRailyFormData> => {
    return {
      firstName: (values) =>
        dealerAddByRailyFormValidationService.validateFirstName(
          values.firstName
        ),
      lastName: (values) =>
        dealerAddByRailyFormValidationService.validateLastName(values.lastName),
      email: (values) =>
        dealerAddByRailyFormValidationService.validateEmail(values.email),
      username: async (values) =>
        await dealerAddByRailyFormValidationService.validateUsername(
          values.username
        ),
      password: (values) =>
        dealerAddByRailyFormValidationService.validatePassword(values.password),
      passwordRepeat: (values) =>
        dealerAddByRailyFormValidationService.validatePasswordRepeat(
          values.password,
          values.passwordRepeat
        ),
      phoneNumber: (values) =>
        dealerAddByRailyFormValidationService.validatePhoneNumber(
          values.phoneNumber
        ),
      alternativePhoneNumber: (values) =>
        dealerAddByRailyFormValidationService.validateAlternativePhoneNumber(
          values.alternativePhoneNumber
        ),
      taxiCorporationUuid: (values) =>
        dealerAddByRailyFormValidationService.validateTaxiCorporation(
          values.taxiCorporationUuid
        ),
    };
  };

const dealerAddByRailyFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default dealerAddByRailyFormHelper;
