import taxiTranslationsHelper from "../../../../../languages/taxi-translations.helper";

const getActivityStatusText = (isStatusActive: boolean): string => {
  const translations =
    taxiTranslationsHelper.getTaxiContractListingTranslations();

  return isStatusActive
    ? translations.filters.activityStatus.activeLabel
    : translations.filters.activityStatus.inactiveLabel;
};

const getPartnerStatusText = (isActive: boolean): string => {
  const translations =
    taxiTranslationsHelper.getTaxiContractListingTranslations();

  return isActive
    ? translations.filters.partnerStatus.partnerLabel
    : translations.filters.partnerStatus.noPartnerLabel;
};

const getHeadingLabel = (taxiCorporationName: string | undefined) => {
  const translations =
    taxiTranslationsHelper.getTaxiContractListingTranslations();

  return taxiCorporationName
    ? translations.header.headingLabelWithParams.replace(
        "#{taxiCorporationName}",
        taxiCorporationName
      )
    : translations.header.headingLabel;
};

const taxiTaxiContractListingHelper = {
  getActivityStatusText,
  getPartnerStatusText,
  getHeadingLabel,
};

export default taxiTaxiContractListingHelper;
