import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import TaxiCorporationSelectOption from "./taxi-corporation-select-option";
import TaxiCorporationSelectValue from "./taxi-corporation-select-value";

const createOptionSubText = (
  selectValue: TaxiCorporationSelectValue
): string => {
  const translations =
    appTranslationsHelper.getComponentTranslations().select.taxiCorporation
      .subText;

  const subTexts: string[] = [];

  if (selectValue.name) {
    subTexts.push(selectValue.name);
  }

  if (selectValue.taxNumber) {
    subTexts.push(
      `${translations.taxNumberTemplateLabel.replace(
        "#{value}",
        selectValue.taxNumber
      )}`
    );
  }

  if (selectValue.companyId) {
    subTexts.push(
      `${translations.companyIdTemplateLabel.replace(
        "#{value}",
        selectValue.companyId
      )}`
    );
  }

  if (selectValue.nationalCourtRegister) {
    subTexts.push(
      `${translations.nationalCourtRegisterTemplateLabel.replace(
        "#{value}",
        selectValue.nationalCourtRegister
      )}`
    );
  }

  return subTexts.join(" | ");
};

const createOption = (
  selectValue: TaxiCorporationSelectValue
): TaxiCorporationSelectOption => {
  return {
    label: selectValue.displayName,
    subText: createOptionSubText(selectValue),
    value: selectValue,
  };
};

const createOptions = (
  selectValues: TaxiCorporationSelectValue[]
): TaxiCorporationSelectOption[] => {
  return selectValues.map(createOption);
};

const taxiCorporationSelectOptionFactory = { createOptions };

export default taxiCorporationSelectOptionFactory;
