import copyToClipboard from "copy-to-clipboard";

const copy = (text: string): void => {
  copyToClipboard(text);
};

const clipboardService = {
  copy,
};

export default clipboardService;
