import DealerListResponse, {
  DealerListResponseItem,
} from "../../../api/raily/dealer/list/dealer-list.response";
import DealerList, { DealerListItem } from "./dealer-list";

const createAlternativePhoneNumber = (
  responseDataItem: DealerListResponseItem
): DealerListItem["alternativePhoneNumber"] => {
  if (
    !responseDataItem.mobileSecondary ||
    !responseDataItem.mobileSecondaryPrefix
  ) {
    return null;
  }

  return {
    dialingCode: responseDataItem.mobileSecondaryPrefix,
    number: responseDataItem.mobileSecondary,
  };
};

const createDataItem = (
  responseDataItem: DealerListResponseItem
): DealerListItem => {
  return {
    removalDate: responseDataItem.removedAt,
    creationDate: responseDataItem.createdAt,
    uuid: responseDataItem.id,
    firstName: responseDataItem.firstName,
    lastName: responseDataItem.lastName,
    email: responseDataItem.email,
    username: responseDataItem.username,
    phoneNumber: {
      dialingCode: responseDataItem.mobilePrimaryPrefix,
      number: responseDataItem.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(responseDataItem),
    taxiCorporationName: responseDataItem.dealer.taxiCorporation.companyName,
  };
};

const createData = (
  responseData: DealerListResponseItem[]
): DealerListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: DealerListResponse): DealerList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const dealerListFactory = {
  create,
};

export default dealerListFactory;
