import { FormValidationDefinition } from "../../../../common/components/form/use-form";
import MileageSettingsMileageNumberCreationModel from "../types/mileage-settings-mileage-number-creation-model";
import mileageSettingsFormValidationService from "./mileage-settings.form-validation.service";
import MileageSettingsFormData from "./types/mileage-settings-form-data";

const getDefaultFormData = (): MileageSettingsFormData => {
  return {
    isCardNumberRequired: true,
    isMileageNumberRequired: true,
    mileageNumberCreationModel:
      MileageSettingsMileageNumberCreationModel.MANUAL,
    defaultIsCardNumberRequired: true,
    defaultIsMileageNumberRequired: true,
    defaultMileageNumberCreationModel:
      MileageSettingsMileageNumberCreationModel.MANUAL,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<MileageSettingsFormData> => {
    return {
      defaultIsCardNumberRequired:
        mileageSettingsFormValidationService.validateDefaultValue,
      defaultIsMileageNumberRequired:
        mileageSettingsFormValidationService.validateDefaultValue,
      defaultMileageNumberCreationModel:
        mileageSettingsFormValidationService.validateDefaultValue,
      isCardNumberRequired: (values) =>
        mileageSettingsFormValidationService.validateIsCardNumberRequired(
          values.isCardNumberRequired,
          values.defaultIsCardNumberRequired
        ),
      isMileageNumberRequired: (values) =>
        mileageSettingsFormValidationService.validateIsMileageNumberRequired(
          values.isMileageNumberRequired,
          values.defaultIsMileageNumberRequired
        ),
      mileageNumberCreationModel: (values) =>
        mileageSettingsFormValidationService.validateMileageNumberCreationModel(
          values.mileageNumberCreationModel,
          values.defaultMileageNumberCreationModel
        ),
    };
  };

const mileageSettingsFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default mileageSettingsFormHelper;
