import BillingModel from "../../../types/billing-model";
import DateRange from "../../../types/date-range";
import NumericRange from "../../../types/numeric-range";

export enum TaxiTaxiContractListLoadParamsOrder {
  PRIORITY_ASC = "PRIORITY_ASC",
  PRIORITY_DESC = "PRIORITY_DESC",
  DISPLAY_NAME_ASC = "DISPLAY_NAME_ASC",
  DISPLAY_NAME_DESC = "DISPLAY_NAME_DESC",
  PRODUCER_DISPLAY_NAME_ASC = "PRODUCER_DISPLAY_NAME_ASC",
  PRODUCER_DISPLAY_NAME_DESC = "PRODUCER_DISPLAY_NAME_DESC",
  CONSUMER_DISPLAY_NAME_ASC = "CONSUMER_DISPLAY_NAME_ASC",
  CONSUMER_DISPLAY_NAME_DESC = "CONSUMER_DISPLAY_NAME_DESC",
  HALTING_TIME_RATE_ASC = "HALTING_TIME_RATE_ASC",
  HALTING_TIME_RATE_DESC = "HALTING_TIME_RATE_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  HALTING_TIME_AFTER_MINUTES_ASC = "HALTING_TIME_AFTER_MINUTES_ASC",
  HALTING_TIME_AFTER_MINUTES_DESC = "HALTING_TIME_AFTER_MINUTES_DESC",
}

type TaxiTaxiContractListLoadParams = {
  displayName?: string;
  producerDisplayName?: string;
  consumerDisplayName?: string;
  producerTaxiUuid?: string;
  engagedTaxiUuid?: string;
  billingModel?: BillingModel;
  page?: number;
  pageSize?: number;
  order?: TaxiTaxiContractListLoadParamsOrder;
  periodOfValidation?: DateRange;
  distanceRate?: NumericRange;
  freeStoppingPeriod?: NumericRange;
  stopRate?: NumericRange;
  isPartner?: boolean;
  isValid?: boolean;
  creationDate?: DateRange;
  priority?: number;
  isActive?: boolean;
};

export default TaxiTaxiContractListLoadParams;
