import { FC, ReactNode } from "react";
import CardComponent from "../card/card.component";
import ComponentClassnames from "../../types/component-classnames";

type ContentWrapperProps = {
  asCardComponent?: boolean;
  children: ReactNode;
  classNames?: ComponentClassnames & {
    content?: string;
  };
};

const ContentWrapper: FC<ContentWrapperProps> = ({
  asCardComponent,
  children,
  classNames,
}) =>
  asCardComponent ? (
    <CardComponent classNames={classNames}>{children}</CardComponent>
  ) : (
    <>{children}</>
  );

export default ContentWrapper;
