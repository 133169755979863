import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import CargoOrderFinishedListReportResponse from "../../../../api/raily/cargo-order/finished-list/report/cargo-order-finished-list-report.response";
import CargoOrderFinishedListReport, {
  CargoOrderFinishedListReportPublicStatus,
} from "./cargo-order-finished-list-report";

const createPublicStatus = (
  publicStatus: CargoOrderFinishedListReportPublicStatus,
  publicStatusesTranslations: Record<string, string>
): string => {
  switch (publicStatus) {
    case CargoOrderFinishedListReportPublicStatus.PRIVATE:
      return publicStatusesTranslations.private;
    case CargoOrderFinishedListReportPublicStatus.PUBLIC:
      return publicStatusesTranslations.public;
    case CargoOrderFinishedListReportPublicStatus.PUBLISHED:
      return publicStatusesTranslations.published;
    default:
      return "undefined";
  }
};

const create = (
  response: CargoOrderFinishedListReportResponse
): CargoOrderFinishedListReport => {
  const translations =
    orderTranslationsHelper.getFinishedListingTranslations().publicStatuses;

  let reportContent = response.data;

  Object.values(CargoOrderFinishedListReportPublicStatus).forEach(
    (publicStatus) => {
      reportContent = reportContent.replace(
        new RegExp(`CargoOrderType.${publicStatus}`, "g"),
        createPublicStatus(publicStatus, translations)
      );
    }
  );

  return {
    data: new Blob([reportContent], { type: "text/csv;charset=utf8" }),
    filename: "",
  };
};

const cargoOrderFinishedListReportFactory = {
  create,
};

export default cargoOrderFinishedListReportFactory;
