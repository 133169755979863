import taxiTranslationsHelper from "../../../../../languages/taxi-translations.helper";
import TaxiFleetPartnerListingTableColumn from "../common/types/taxi-fleet-partner-listing-table-column";

const getColumns = (): TaxiFleetPartnerListingTableColumn[] => {
  const translations =
    taxiTranslationsHelper.getTaxiFleetPartnerListingTranslations().table
      .headers;

  const tableColumns: TaxiFleetPartnerListingTableColumn[] = [
    {
      header: translations.nameLabel,
      title: translations.nameTitle,
      accessor: "name",
      colSpan: 10,
    },
    {
      header: translations.creationDateLabel,
      title: translations.creationDateTitle,
      accessor: "creationDate",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];

  return tableColumns;
};

const taxiFleetPartnerListingTableHelper = { getColumns };

export default taxiFleetPartnerListingTableHelper;
