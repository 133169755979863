import HttpResponse from "../../../../utils/http/http.response";

type DriverPlanCandidatureAddSuccessResponse = HttpResponse<string>;

export enum DriverPlanCandidatureAddNotAcceptableResponseErrorType {
  NO_TAXI_DRIVER_ASSOCIATION = "NO_TAXI_DRIVER_ASSOCIATION",
  NO_VALID_TAXI_DRIVER_CONTRACT = "NO_VALID_TAXI_DRIVER_CONTRACT",
  NO_VALID_TAXI_RAILY_CONTRACT = "NO_VALID_TAXI_RAILY_CONTRACT",
  NO_VALID_CARGO_RAILY_CONTRACT = "NO_VALID_CARGO_RAILY_CONTRACT",
  TARGET_PLAN_ENTRY_NOT_FOUND = "TARGET_PLAN_ENTRY_NOT_FOUND",
  CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME = "CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME",
  CANDIDATE_DOES_NOT_FIT = "CANDIDATE_DOES_NOT_FIT",
  TOO_MANY_CHECKOUT_EVENTS = "TOO_MANY_CHECKOUT_EVENTS",
  CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION = "CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION",
  DELETE_SOLVED_ORDER_TARGET_NOT_FOUND = "DELETE_SOLVED_ORDER_TARGET_NOT_FOUND",
  PLAN_ENTRY_CANDIDATE_COLLISION = "PLAN_ENTRY_CANDIDATE_COLLISION",
  FORBIDDEN_TAXI = "FORBIDDEN_TAXI",
  FORBIDDEN_PLAN_ENTRY = "FORBIDDEN_PLAN_ENTRY",
}

export type DriverPlanCandidatureAddNotAcceptableResponseNoTaxiDriverAssociationError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION;
    driverId: string;
    taxiId: string;
  };

export type DriverPlanCandidatureAddNotAcceptableResponseNoValidTaxiDriverContractError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT;
    driverId: string;
    taxiId: string;
  };

export type DriverPlanCandidatureAddNotAcceptableResponseNoValidTaxiRailyContractError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT;
  };

export type DriverPlanCandidatureAddNotAcceptableResponseNoValidCargoRailyContractError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.NO_VALID_CARGO_RAILY_CONTRACT;
    driverId: string;
    taxiId: string;
  };

type DriverPlanCandidatureAddNotAcceptableResponseTargetPlanEntryNotFoundError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.TARGET_PLAN_ENTRY_NOT_FOUND;
    candidateEntryId: string;
    targetPlanEntryId: string;
  };

type DriverPlanCandidatureAddNotAcceptableResponseCandidateFitsWithNotEnoughTimeError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME;
    candidatureId: string;
    candidatureEntryId: string;
    collidingSolvedOrderId: string;
    candidatureSolvedOrderId: string;
  };

type DriverPlanCandidatureAddNotAcceptableResponseTooManyCheckoutEventsError = {
  errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS;
};

type DriverPlanCandidatureAddNotAcceptableResponseCandidateDoesNotFitError = {
  errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.CANDIDATE_DOES_NOT_FIT;
  candidatureId: string;
  candidatureEntryId: string;
  candidatureSolvedOrderId: string;
};

type DriverPlanCandidatureAddNotAcceptableResponseCandidatePlanEntryCollidesError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION;
    candidatureId: string;
    candidatureEntryId: string;
    candidaturePlanEntryId: string;
  };

type DriverPlanCandidatureAddNotAcceptableResponseDeleteSolvedOrderTargetNotFoundError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND;
    candidatureId: string;
    candidateEntryId: string;
    targetSolvedOrderId: string;
  };

type DriverPlanCandidatureAddNotAcceptableResponsePlanEntryCandidateCollisionError =
  {
    errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_COLLISION;
    candidatureId: string;
    candidateEntryId: string;
  };

type DriverPlanCandidatureAddNotAcceptableResponseForbiddenTaxiError = {
  errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.FORBIDDEN_TAXI;
  providedTaxiId: string;
};

type DriverPlanCandidatureAddNotAcceptableResponseForbiddenPlanEntryError = {
  errorType: DriverPlanCandidatureAddNotAcceptableResponseErrorType.FORBIDDEN_PLAN_ENTRY;
  providedPlanEntryId: string;
};

type DriverPlanCandidatureAddNotAcceptableResponseError =
  | DriverPlanCandidatureAddNotAcceptableResponseNoTaxiDriverAssociationError
  | DriverPlanCandidatureAddNotAcceptableResponseNoValidTaxiDriverContractError
  | DriverPlanCandidatureAddNotAcceptableResponseNoValidTaxiRailyContractError
  | DriverPlanCandidatureAddNotAcceptableResponseNoValidCargoRailyContractError
  | DriverPlanCandidatureAddNotAcceptableResponseTargetPlanEntryNotFoundError
  | DriverPlanCandidatureAddNotAcceptableResponseCandidateFitsWithNotEnoughTimeError
  | DriverPlanCandidatureAddNotAcceptableResponseCandidateDoesNotFitError
  | DriverPlanCandidatureAddNotAcceptableResponseCandidatePlanEntryCollidesError
  | DriverPlanCandidatureAddNotAcceptableResponseDeleteSolvedOrderTargetNotFoundError
  | DriverPlanCandidatureAddNotAcceptableResponsePlanEntryCandidateCollisionError
  | DriverPlanCandidatureAddNotAcceptableResponseForbiddenTaxiError
  | DriverPlanCandidatureAddNotAcceptableResponseForbiddenPlanEntryError
  | DriverPlanCandidatureAddNotAcceptableResponseTooManyCheckoutEventsError;

export type DriverPlanCandidatureAddNotAcceptableResponse =
  HttpResponse<DriverPlanCandidatureAddNotAcceptableResponseError>;

type DriverPlanCandidatureAddResponse =
  | DriverPlanCandidatureAddSuccessResponse
  | DriverPlanCandidatureAddNotAcceptableResponse;

export default DriverPlanCandidatureAddResponse;
