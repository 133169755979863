import Joi from "joi";
import authValidationService from "../../../auth/common/validation/auth-validation.service";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";

const validatePasswordsNotEqual = (
  currentPassword: string,
  newPassword: string
) => {
  const validateNewPasswordResult =
    authValidationService.validatePassword(newPassword);

  if (!validateNewPasswordResult.isValid) {
    return validateNewPasswordResult;
  }

  const passwordsNotEqualSchema = Joi.string()
    .allow("")
    .invalid(currentPassword)
    .required();

  const validationResult = formValidationService.validate(
    newPassword,
    passwordsNotEqualSchema
  );

  if (validationResult.isValid) {
    return validationResult;
  }

  validationResult.errorMessage =
    formValidationService.getValidationCustomMessages().newPasswordValidationError;

  return validationResult;
};

const validatePassword = (password: string): FormValidationResult => {
  const validationSchema = Joi.string().max(50).required();

  return formValidationService.validate(password, validationSchema);
};

const appHeaderUserChangePasswordValidationService = {
  validatePassword,
  validatePasswordsNotEqual,
  validatePasswordRepeat: authValidationService.validatePasswordRepeat,
};

export default appHeaderUserChangePasswordValidationService;
