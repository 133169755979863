import railyApiService from "../raily-api.service";
import cargoOrderActiveListRequestFactory from "./active-list/cargo-order-active-list-request.factory";
import CargoOrderActiveListRequest, {
  CargoOrderActiveListRequestQueryParams,
} from "./active-list/cargo-order-active-list.request";
import CargoOrderActiveListResponse from "./active-list/cargo-order-active-list.response";
import cargoOrderFinishedListRequestFactory from "./finished-list/cargo-order-finished-list-request.factory";
import CargoOrderFinishedListRequest, {
  CargoOrderFinishedListRequestQueryParams,
} from "./finished-list/cargo-order-finished-list.request";
import CargoOrderFinishedListResponse from "./finished-list/cargo-order-finished-list.response";
import CargoOrderDetailsComplexRequest from "./details-complex/cargo-order-details-complex.request";
import CargoOrderDetailsComplexResponse from "./details-complex/cargo-order-details-complex.response";
import cargoOrderDetailsHandlingLogRequestFactory from "./details-complex/handling-log/cargo-order-details-handling-log-request.factory";
import CargoOrderDetailsHandlingLogRequest, {
  CargoOrderDetailsHandlingLogRequestQueryParams,
} from "./details-complex/handling-log/cargo-order-details-handling-log.request";
import CargoOrderDetailsHandlingLogResponse from "./details-complex/handling-log/cargo-order-details-handling-log.response";
import CargoOrderFinishedListReportRequest, {
  CargoOrderFinishedListReportRequestQueryParams,
} from "./finished-list/report/cargo-order-finished-list-report-request";
import CargoOrderFinishedListReportResponse from "./finished-list/report/cargo-order-finished-list-report.response";
import cargoOrderFinishedListReportRequestFactory from "./finished-list/report/cargo-order-finished-list-report-request.factory";
import CargoOrderDetailsResponse from "./details/cargo-order-details.response";
import CargoOrderDetailsRequest from "./details/cargo-order-details.request";

const cargoOrderApiService = () => {
  const getActiveList = async (
    request: CargoOrderActiveListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoOrderActiveListResponse> => {
    const url = `/orders/active-cargo-orders`;

    const queryParams: CargoOrderActiveListRequestQueryParams =
      cargoOrderActiveListRequestFactory.createQueryParams(request);

    return railyApiService().get<CargoOrderActiveListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getFinishedList = async (
    request: CargoOrderFinishedListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoOrderFinishedListResponse> => {
    const url = `/orders/finished-cargo-orders`;

    const queryParams: CargoOrderFinishedListRequestQueryParams =
      cargoOrderFinishedListRequestFactory.createQueryParams(request);

    return railyApiService().get<CargoOrderFinishedListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getFinishedListReport = async (
    request: CargoOrderFinishedListReportRequest,
    abortSignal: AbortSignal
  ): Promise<CargoOrderFinishedListReportResponse> => {
    const url = `/orders/finished-cargo-orders/csv-dump`;

    const queryParams: CargoOrderFinishedListReportRequestQueryParams =
      cargoOrderFinishedListReportRequestFactory.createQueryParams(request);

    return railyApiService().get<CargoOrderFinishedListReportResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getDetailsComplex = async (
    request: CargoOrderDetailsComplexRequest,
    abortSignal: AbortSignal
  ): Promise<CargoOrderDetailsComplexResponse> => {
    const url = `/orders/cargo-orders/${request.orderId}/complex`;

    return railyApiService().get<CargoOrderDetailsComplexResponse>({
      url,
      abortSignal,
    });
  };

  const getDetails = async (
    request: CargoOrderDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<CargoOrderDetailsResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}`;

    return railyApiService().get<CargoOrderDetailsResponse>({
      url,
      abortSignal,
    });
  };

  const getHandlingLog = async (
    request: CargoOrderDetailsHandlingLogRequest,
    abortSignal: AbortSignal
  ): Promise<CargoOrderDetailsHandlingLogResponse> => {
    const url = `/orders/cargo-orders/${request.orderId}/handling-log`;

    const queryParams: CargoOrderDetailsHandlingLogRequestQueryParams =
      cargoOrderDetailsHandlingLogRequestFactory.createQueryParams(request);

    return railyApiService().get<CargoOrderDetailsHandlingLogResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    getActiveList,
    getFinishedList,
    getFinishedListReport,
    getHandlingLog,
    getDetailsComplex,
    getDetails,
  };
};

export default cargoOrderApiService;
