const format = (duration: number): string => {
  const formatNumber = (number: number): string => {
    return `${number}`.padStart(2, "0");
  };

  const minutes = formatNumber(Math.floor((duration / 60) % 60));
  const hours = formatNumber(Math.floor(duration / 60 / 60));

  return `${hours}:${minutes}`;
};

const durationService = {
  format,
};

export default durationService;
