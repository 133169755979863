import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

const getBooleanMappedValue = (value: boolean | undefined): string => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiEditBillingsTranslations();

  return value
    ? translations.contractDetails.yesText
    : translations.contractDetails.noText;
};

const getDiscountText = (value: number | undefined): string => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiEditBillingsTranslations();
  if (value && value > 0) {
    return translations.contractDetails.discountTextValue.replace(
      "#{discountValue}",
      String(value)
    );
  }

  return translations.contractDetails.noText;
};

const getHaltingText = (value: number | undefined): string => {
  const translations =
    billingsTranslationsHelper.getRailyTaxiAddBillingsTranslations();

  return translations.contractDetails.haltingAppliedAfter.replace(
    "#{minutesValue}",
    String(value)
  );
};

const contractDetailsHelper = {
  getBooleanMappedValue,
  getDiscountText,
  getHaltingText,
};

export default contractDetailsHelper;
