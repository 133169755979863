import cargoCompanyDispatchApiService from "../../../api/raily/cargo-company/dispatch/cargo-company-dispatch-api.service";
import CargoCompanyDispatchListRequest from "../../../api/raily/cargo-company/dispatch/list/cargo-company-dispatch-list.request";
import dataLoadErrorFactory from "../../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../../utils/http/http.error";
import httpHelper from "../../../utils/http/http.helper";
import CargoCompanyDispatchListError from "./list/cargo-company-dispatch-list-error";
import CargoCompanyDispatchList from "./list/cargo-company-dispatch-list";
import CargoCompanyDispatchListLoadParams from "./list/cargo-company-dispatch-list-load-params";
import cargoCompanyDispatchListFactory from "./list/cargo-company-dispatch-list.factory";
import cargoCompanyDispatchListRequestFactory from "./list/cargo-company-dispatch-list-request.factory";
import CargoCompanyDispatchDetailsError from "./details/cargo-company-dispatch-details-error";
import CargoCompanyDispatchDetailsLoadParams from "./details/cargo-company-dispatch-details-load-params";
import CargoCompanyDispatchDetails from "./details/cargo-company-dispatch-details";
import CargoCompanyDispatchDetailsRequest from "../../../api/raily/cargo-company/dispatch/details/cargo-company-dispatch-details.request";
import cargoCompanyDispatchDetailsRequestFactory from "./details/cargo-company-dispatch-details-request.factory";
import cargoCompanyDispatchSingleFactory from "./details/cargo-company-dispatch-details.factory";
import CargoCompanyDispatchAddError from "./add/cargo-company-dispatch-add-error";
import CargoCompanyDispatchAddParams from "./add/cargo-company-dispatch-add-params";
import CargoCompanyDispatchAddRequest from "../../../api/raily/cargo-company/dispatch/add/cargo-company-dispatch-add-request";
import cargoCompanyDispatchAddRequestFactory from "./add/cargo-company-dispatch-add-request.factory";
import CargoCompanyDispatchUpdateError from "./update/cargo-company-dispatch-update-error";
import CargoCompanyDispatchUpdateParams from "./update/cargo-company-dispatch-update-params";
import CargoCompanyDispatchUpdateRequest from "../../../api/raily/cargo-company/dispatch/update/cargo-company-dispatch-update-request";
import cargoCompanyDispatchUpdateRequestFactory from "./update/cargo-company-dispatch-update-request.factory";
import CargoCompanyDispatchMultiListError from "./multi-list/cargo-company-dispatch-multi-list-error";
import CargoCompanyDispatchMultiListLoadParams from "./multi-list/cargo-company-dispatch-multi-list-load-params";
import CargoCompanyDispatchMultiList from "./multi-list/cargo-company-dispatch-multi-list";
import cargoCompanyDispatchMultiListRequestFactory from "./multi-list/cargo-company-dispatch-multi-list-request.factory";
import CargoCompanyDispatchMultiListRequest from "../../../api/raily/cargo-company/dispatch/multi-list/cargo-company-dispatch-multi-list.request";
import cargoCompanyDispatchMultiListFactory from "./multi-list/cargo-company-dispatch-multi-list.factory";

const handleListError = (
  error: HttpError | CargoCompanyDispatchListError
): CargoCompanyDispatchListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: CargoCompanyDispatchListLoadParams,
  abortSignal: AbortSignal
): Promise<CargoCompanyDispatchList> => {
  const request: CargoCompanyDispatchListRequest =
    cargoCompanyDispatchListRequestFactory.create(params);

  try {
    const response = await cargoCompanyDispatchApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoCompanyDispatchListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyDispatchListError;

    throw handleListError(error);
  }
};

const handleAddError = (
  error: HttpError | CargoCompanyDispatchAddError
): CargoCompanyDispatchAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: CargoCompanyDispatchAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: CargoCompanyDispatchAddRequest =
    cargoCompanyDispatchAddRequestFactory.create(params);

  try {
    const response = await cargoCompanyDispatchApiService().add(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyDispatchAddError;

    throw handleAddError(error);
  }
};

const handleUpdateError = (
  error: HttpError | CargoCompanyDispatchUpdateError
): CargoCompanyDispatchUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const update = async (
  params: CargoCompanyDispatchUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: CargoCompanyDispatchUpdateRequest =
    cargoCompanyDispatchUpdateRequestFactory.create(params);

  try {
    const response = await cargoCompanyDispatchApiService().update(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyDispatchUpdateError;

    throw handleUpdateError(error);
  }
};

const handleDetailsError = (
  error: HttpError | CargoCompanyDispatchDetailsError
): CargoCompanyDispatchDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: CargoCompanyDispatchDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<CargoCompanyDispatchDetails> => {
  const request: CargoCompanyDispatchDetailsRequest =
    cargoCompanyDispatchDetailsRequestFactory.create(params);

  try {
    const response = await cargoCompanyDispatchApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoCompanyDispatchSingleFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyDispatchDetailsError;

    throw handleDetailsError(error);
  }
};

const handleMultiListError = (
  error: HttpError | CargoCompanyDispatchMultiListError
): CargoCompanyDispatchMultiListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getMultiList = async (
  params: CargoCompanyDispatchMultiListLoadParams,
  abortSignal: AbortSignal
): Promise<CargoCompanyDispatchMultiList> => {
  const request: CargoCompanyDispatchMultiListRequest =
    cargoCompanyDispatchMultiListRequestFactory.create(params);

  try {
    const response = await cargoCompanyDispatchApiService().getMultiList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoCompanyDispatchMultiListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyDispatchListError;

    throw handleMultiListError(error);
  }
};

const cargoCompanyDispatchService = {
  getList,
  add,
  update,
  getDetails,
  getMultiList,
};

export default cargoCompanyDispatchService;
