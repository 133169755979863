export enum TaxiUnsettledTransferedOrderListingRequestOrder {
  CONSUMER_ASC = "CONSUMER_ASC",
  CONSUMER_DESC = "CONSUMER_DESC",
  PRODUCER_ASC = "PRODUCER_ASC",
  PRODUCER_DESC = "PRODUCER_DESC",
}

type TaxiUnsettledTransferedOrderListingRequest = {
  order: TaxiUnsettledTransferedOrderListingRequestOrder | undefined;
  limit: number | undefined;
  offset: number | undefined;
  start_time_since: string | undefined;
  start_time_to: string | undefined;
  human_id: number | undefined;
  address: string | undefined;
  start_address: string | undefined;
  mid_address: string | undefined;
  end_address: string | undefined;
  passenger: string | undefined;
  producer: string | undefined;
  consumer: string | undefined;
  external_id: string | undefined;
  driver: string | undefined;
};

export default TaxiUnsettledTransferedOrderListingRequest;
