import UserFormOfEmployment from "../../../../../common/types/user-form-of-employment";
import dateService from "../../../../../common/utils/date/date.service";
import SignUpDriverRequest, {
  SignUpDriverRequestAddress,
  SignUpDriverRequestTaxiDriverAssociation,
  SignUpDriverRequestTaxiDriverAssociationForB2B,
  SignUpDriverRequestTaxiDriverAssociationForEmployment,
} from "./api/sign-up-driver.request";
import SignUpDriverFormAccountData from "./types/sign-up-driver-form-account-data";
import SignUpDriverFormCompanyData from "./types/sign-up-driver-form-company-data";
import SignUpDriverFormUserAddress from "./types/sign-up-driver-form-user-address";
import SignUpDriverFormUserData from "./types/sign-up-driver-form-user-data";
import SignUpDriverFormVehicleData from "./types/sign-up-driver-form-vehicle-data";

const createSignUpRequestAddress = (
  userDataAddress: SignUpDriverFormUserAddress
): SignUpDriverRequestAddress => {
  const requestAddress: SignUpDriverRequestAddress = {
    apartment: userDataAddress.apartmentNumber.trim(),
    country: userDataAddress.country.trim(),
    description: userDataAddress.description.trim(),
    house_no: userDataAddress.houseNumber.trim(),
    lat: userDataAddress.latitude,
    lon: userDataAddress.longitude,
    street: userDataAddress.street.trim(),
    town: userDataAddress.town.trim(),
    type: userDataAddress.type,
    zip_code: userDataAddress.zipCode.trim(),
  };

  return requestAddress;
};

const createSignUpRequestAddresses = (
  userDataAddresses: SignUpDriverFormUserAddress[]
): SignUpDriverRequestAddress[] => {
  return userDataAddresses.map(createSignUpRequestAddress);
};

const createSignUpRequestTaxiDriverAssociationForEmployment = (
  userData: SignUpDriverFormUserData,
  taxiCorporationId: string
): SignUpDriverRequestTaxiDriverAssociationForEmployment => {
  return {
    contract_type: userData.formOfEmployment!.value,
    taxi_corporation_id: taxiCorporationId,
    display_name: `${userData.firstName.trim()} ${userData.lastName.trim()}`,
  };
};

const createSignUpRequestTaxiDriverAssociationForB2B = (
  userData: SignUpDriverFormUserData,
  companyData: SignUpDriverFormCompanyData,
  taxiCorporationId: string
): SignUpDriverRequestTaxiDriverAssociationForB2B => {
  return {
    apartment: companyData.address.apartmentNumber.trim(),
    company_name: companyData.name,
    contract_type: userData.formOfEmployment!.value,
    country: companyData.address.country.trim(),
    description: companyData.address.description.trim(),
    house_no: companyData.address.houseNumber.trim(),
    regon: companyData.companyId.trim() || null,
    tax_id: companyData.taxNumber.trim(),
    zip_code: companyData.address.zipCode.trim(),
    town: companyData.address.town.trim(),
    krs_no: companyData.nationalCourtRegisterNumber.trim(),
    street: companyData.address.street.trim(),
    taxi_corporation_id: taxiCorporationId,
    display_name: `${userData.firstName.trim()} ${userData.lastName.trim()}`,
  };
};

const createSignUpRequestTaxiDriverAssociation = (
  userData: SignUpDriverFormUserData,
  companyData: SignUpDriverFormCompanyData,
  taxiCorporationId: string
): SignUpDriverRequestTaxiDriverAssociation => {
  if (userData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
    return createSignUpRequestTaxiDriverAssociationForB2B(
      userData,
      companyData,
      taxiCorporationId
    );
  }

  return createSignUpRequestTaxiDriverAssociationForEmployment(
    userData,
    taxiCorporationId
  );
};

const createSignUpRequest = ({
  accountData,
  userData,
  companyData,
  vehicleData,
  taxiCorporationId,
}: {
  accountData: SignUpDriverFormAccountData;
  userData: SignUpDriverFormUserData;
  companyData: SignUpDriverFormCompanyData;
  vehicleData: SignUpDriverFormVehicleData;
  taxiCorporationId: string;
}): SignUpDriverRequest => {
  const request: SignUpDriverRequest = {
    email: accountData.email.trim(),
    username: accountData.username.trim(),
    password: accountData.password,
    first_name: userData.firstName.trim(),
    last_name: userData.lastName.trim(),
    mobile_primary_prefix: userData.mobile.dialingCode!,
    mobile_primary: userData.mobile.number!,
    mobile_secondary_prefix: userData.alternativeMobile.dialingCode,
    mobile_secondary: userData.alternativeMobile.number,
    driver: {
      birth_date: dateService.format(userData.birthDate!, "yyyy-mm-dd"),
      birth_place: userData.birthPlace,
      citizenship: userData.citizenship!.value,
      experience: userData.yearsOfExperience!,
      id_number: userData.personalIdNumber,
      languages: userData.languages.map((lang) => lang.value),
      car_make: vehicleData.make,
      car_model: vehicleData.model,
      car_ownership: vehicleData.ownership!.value,
      car_prod_year: vehicleData.productionYear!,
      car_reg_no: vehicleData.registrationNumber,
      car_seats_no: vehicleData.numberOfSeats!,
      addresses: createSignUpRequestAddresses(userData.addresses),
      taxi_driver_association: createSignUpRequestTaxiDriverAssociation(
        userData,
        companyData,
        taxiCorporationId
      ),
    },
  };

  return request;
};

const signUpDriverFormFactory = {
  createSignUpRequest,
};

export default signUpDriverFormFactory;
