import { MileageContractListItem } from "../../../../../common/services/mileage-contract/list/mileage-contract-list";
import MileageContractListingItem from "./types/mileage-contract-listing-item";

const createListingItem = (
  item: MileageContractListItem
): MileageContractListingItem => {
  return {
    uuid: item.uuid,
    validSince: item.validSince,
    validTo: item.validTo,
    dietFullAfterHours: item.dietFullAfterHours,
    dietFullRate: item.dietFullRate,
    dietHalfAfterHours: item.dietHalfAfterHours,
    dietHalfRate: item.dietHalfRate,
    disabled: item.disabled,
    distanceRateCarGt900cm3: item.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: item.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: item.distanceRateCompanyVehicle,
    distanceRateMoped: item.distanceRateMoped,
    distanceRateMotorcycle: item.distanceRateMotorcycle,
    companyName: item.companyName,
  };
};

const createListingItems = (
  listItems: MileageContractListItem[]
): MileageContractListingItem[] => {
  const listingItems: MileageContractListingItem[] =
    listItems.map(createListingItem);

  return listingItems;
};

const mileageContractsListingItemFactory = {
  createListingItems,
};

export default mileageContractsListingItemFactory;
