import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TaxiCargoContractEditRouteParams from "../../common/routes/types/taxi-cargo-contract-edit-route-params";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import useForm from "../../../../common/components/form/use-form";
import TaxiCargoContractEditFormData from "./common/types/taxi-cargo-contract-edit-form-data";
import taxiCargoContractEditFormHelper from "./common/taxi-cargo-contract-edit-form.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import BooleanSelectComponent from "../../../../common/components/form/select/boolean/boolean-select.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import cargoTaxiContractService from "../../../../common/services/cargo-taxi-contract/cargo-taxi-contract.service";
import useCargoTaxiContractDetails from "../../../../common/services/cargo-taxi-contract/details/use-cargo-taxi-contract-details";
import useAbort from "../../../../common/hooks/use-abort";
import notificationService from "../../../../common/utils/notification/notification.service";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import booleanHelper from "../../../../common/utils/boolean/boolean.helper";
import useTaxiCorporationDetails from "../../../../common/services/taxi-corporation/details/use-taxi-corporation-details";

type TaxiCargoContractEditProps = {};

const TaxiCargoContractEditComponent: FC<TaxiCargoContractEditProps> = () => {
  const navigate = useNavigate();

  const { taxiCorporationUuid, contractUuid } =
    useParams<TaxiCargoContractEditRouteParams>();
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.taxiCargoContractEdit);

  const taxiDetails = useTaxiCorporationDetails();
  const taxiDetailsAbort = useAbort();

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    taxiDetails.load(
      {
        taxiCorporationUuid,
      },
      taxiDetailsAbort.signal
    );
  }, [taxiCorporationUuid]);

  useEffect(() => {
    const breadcrumbs = taxiBreadcrumbsHelper.getCargoContractEditBreadcrumbs({
      taxiCorporationUuid: taxiCorporationUuid!,
      contractUuid: contractUuid!,
      taxiCorporationDisplayName: taxiDetails.data?.displayName,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, taxiDetails.data]);

  const translations =
    taxiTranslationsHelper.getCargoContractEditTranslations();

  const form = useForm<TaxiCargoContractEditFormData>({
    emptyValues: taxiCargoContractEditFormHelper.getEmptyFormData(),
    validationDefinition:
      taxiCargoContractEditFormHelper.getValidationDefinition(),
  });

  const cargoTaxiContractDetails = useCargoTaxiContractDetails();
  const cargoTaxiContractDetailsAbort = useAbort();

  useEffect(() => {
    if (!contractUuid) {
      return;
    }

    cargoTaxiContractDetails.load(
      {
        contractUuid,
      },
      cargoTaxiContractDetailsAbort.signal
    );
  }, [contractUuid]);

  useEffect(() => {
    if (!cargoTaxiContractDetails.data) {
      return;
    }

    form.setValues({
      isActive: cargoTaxiContractDetails.data.isActive,
    });
  }, [cargoTaxiContractDetails.data]);

  const cargoTaxiContractUpdateAbort = useAbort();

  const [isContractUpdatePending, setIsContractUpdatePending] = useState(false);

  const onContractUpdateSuccess = () => {
    notificationService.success(translations.successUpdateNotificationLabel);

    navigate(
      taxiRoutesHelper.getCargoContractListingRoute({
        taxiCorporationUuid: taxiCorporationUuid!,
      })
    );
  };

  const onContractUpdateFailure = () => {
    notificationService.error(translations.failureUpdateNotificationLabel);
  };

  const updateContract = async () => {
    setIsContractUpdatePending(true);

    try {
      await cargoTaxiContractService.update(
        {
          contractUuid: contractUuid!,
          isActive: form.values.isActive!,
        },
        cargoTaxiContractUpdateAbort.signal
      );

      onContractUpdateSuccess();
    } catch {
      onContractUpdateFailure();
    } finally {
      setIsContractUpdatePending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    updateContract();
  };

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column xl={8}>
          <CardComponent isLoading={cargoTaxiContractDetails.isLoading}>
            <Row>
              <Column lg={6}>
                <FormFieldComponent label={translations.form.cargoCompanyLabel}>
                  {cargoTaxiContractDetails.data?.cargoCompany.displayName}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.periodOfValidityLabel}
                >
                  {taxiCargoContractEditFormHelper.getPeriodOfValidityLabel(
                    cargoTaxiContractDetails.data?.periodOfValidity
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent label={translations.form.billingModelLabel}>
                  {cargoTaxiContractDetails.data?.billingModel}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent label={translations.form.distanceRateLabel}>
                  {translations.form.distanceRateContentLabel.replace(
                    "#{distanceRate}",
                    cargoTaxiContractDetails.data?.distanceRate.toString() ?? ""
                  )}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.discountPercentLimitLabel}
                >
                  {translations.form.discountPercentLimitContentLabel.replace(
                    "#{discountPercentLimit}",
                    cargoTaxiContractDetails.data?.discountPercentLimit.toString() ??
                      ""
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.freeStoppingPeriodLabel}
                >
                  {translations.form.freeStoppingPeriodContentLabel.replace(
                    "#{freeStoppingPeriod}",
                    cargoTaxiContractDetails.data?.freeStoppingPeriodMinutes.toString() ??
                      ""
                  )}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent label={translations.form.stoppingRateLabel}>
                  {translations.form.stoppingRateContentLabel.replace(
                    "#{stoppingRate}",
                    cargoTaxiContractDetails.data?.stoppingRate.toString() ?? ""
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.tollRoadsDuringOrderAllowedLabel}
                >
                  {booleanHelper.getLabel(
                    cargoTaxiContractDetails.data
                      ?.isTollRoadsDuringOrderAllowed!
                  )}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={
                    translations.form.tollRoadsWhileApproachingAllowedLabel
                  }
                >
                  {booleanHelper.getLabel(
                    cargoTaxiContractDetails.data
                      ?.isTollRoadsWhileArrivalAllowed!
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.tollRoadsWhileReturningAllowedLabel}
                >
                  {booleanHelper.getLabel(
                    cargoTaxiContractDetails.data
                      ?.isTollRoadsWhileReturningAllowed!
                  )}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.orderPublishingAllowedLabel}
                >
                  {booleanHelper.getLabel(
                    cargoTaxiContractDetails.data?.isOrderPublishingAllowed!
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.isActiveLabel}
                  isRequired
                  errorMessage={form.validationResults.isActive.errorMessage}
                >
                  <BooleanSelectComponent
                    placeholder={translations.form.isActivePlaceholder}
                    value={form.values.isActive}
                    onChange={(value) => form.setValue("isActive", value)}
                    isError={!!form.validationResults.isActive.errorMessage}
                    onBlur={() => form.validate("isActive")}
                    idForTesting="taxi-cargo-contract-edit-is-public"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
          <ButtonComponent
            onClick={onSubmitButtonClick}
            type="primary"
            isLoading={isContractUpdatePending}
            idForTesting="taxi-cargo-contract-edit-submit-button"
            classNames={{ root: "mt-4" }}
            title={translations.form.submitButtonTitle}
          >
            {translations.form.submitButtonLabel}
          </ButtonComponent>
        </Column>
      </Row>
    </>
  );
};

export default TaxiCargoContractEditComponent;
