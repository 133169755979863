import DriverPlanCandidaturePreview, {
  DriverPlanCandidaturePreviewCandidatingPlanEntry,
  DriverPlanCandidaturePreviewCandidatingPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewCandidatingPlanEntryWaypoint,
  DriverPlanCandidaturePreviewDraftCandidatingPlanEntry,
  DriverPlanCandidaturePreviewDraftCandidatingPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewDraftCandidatingPlanEntryWaypoint,
  DriverPlanCandidaturePreviewPlanEntry,
  DriverPlanCandidaturePreviewPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewPlanEntryOrderWaypoint,
} from "../../../../../common/services/driver-plan/preview/driver-plan-candidature-preview";
import DriverPlanCandidaturePreviewLoadParams from "../../../../../common/services/driver-plan/preview/driver-plan-candidature-preview-load-params";
import {
  PlanningOrderSelectedItemData,
  PlanningOrderSelectedItemDataDriver,
  PlanningOrderSelectedItemDataPlanEntry,
} from "../../common/types/planning-order-selected-data";
import {
  PlanningOrderDriverPlanListingSelectedItemUser,
  PlanningOrderDriverPlanListingSelectedItemUserDriver,
  PlanningOrderDriverPlanListingSelectedItemUserPlanEntry,
} from "../types/planner-order-driver-plan-listing-selected-item";
import ChartData, {
  ChartDataGroup,
  ChartDataGroupItem,
  ChartDataGroupItems,
  ChartDataItemGroupPoint,
} from "../../../../../common/components/chart/types/chart-data";
import PlanningOrderDriverPlanListingItemChart, {
  PlanColors,
  PlanTypeEntry,
  PlanXAxisValue,
  PlanningOrderDriverPlanListingItemChartPlan,
  PlanningOrderDriverPlanListingItemChartPlanOrderPoint,
  PlanningOrderDriverPlanListingItemChartPlanOrderPoints,
} from "../../driver-plan-listing/common/planner-order-driver-listing-item-chart";
import PlanningOrderDriverPlanListingSelectedItemChartData from "../types/planner-order-driver-plan-listing-selected-item-chart-data";

const createChartDatItemGroupPoint = (
  item: PlanningOrderDriverPlanListingItemChartPlanOrderPoint,
  index: number,
  isEndPoint?: boolean
): ChartDataItemGroupPoint => {
  const isLabelVisible = index === 0 || isEndPoint;
  return {
    date: new Date(item.date),
    label: isLabelVisible ? item.address : "",
    tooltipLabel: item.address,
  };
};

const createChartDataItemGroupPoints = (
  item: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[],
  isEndPoint?: boolean
): ChartDataItemGroupPoint[] => {
  return item.map((i, index) =>
    createChartDatItemGroupPoint(i, index, isEndPoint)
  );
};

const createChartDataGroupItem = (
  item: PlanningOrderDriverPlanListingItemChartPlanOrderPoints,
  color: string,
  isLabelHidden?: boolean,
  isEndpoint?: boolean
): ChartDataGroupItem => {
  return {
    color: color,
    opacity: 1,
    barLabel: isLabelHidden ? "" : item.uuid?.toString(),
    points: createChartDataItemGroupPoints(item.waypoints, isEndpoint),
    borderColor: color,
  };
};

const createChartDataGroupItemFromSinglePoint = (
  list: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[],
  color: string,
  isEndPoint?: boolean
): ChartDataGroupItem => {
  return {
    color: color,
    opacity: 0.2,
    points: createChartDataItemGroupPoints(list, isEndPoint),
    borderColor: color,
    borderWidth: 2,
  };
};

const createChartDataGroupItemOrderItem = (
  previousOrder: PlanningOrderDriverPlanListingItemChartPlanOrderPoints,
  order: PlanningOrderDriverPlanListingItemChartPlanOrderPoints,
  color: string
) => {
  const orderPoints: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] = [
    previousOrder.waypoints[previousOrder.waypoints.length - 1],
    order.waypoints[0],
  ];
  return createChartDataGroupItemFromSinglePoint(orderPoints, color);
};

const createChartDataGroupItemStartItem = (
  plan: PlanningOrderDriverPlanListingItemChartPlan,
  groupItem: ChartDataGroupItems,
  color: string
) => {
  const startList: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] = [
    plan.start!,
    plan.orders[0].waypoints[0],
  ];
  groupItem.items.push(
    createChartDataGroupItemFromSinglePoint(startList, color)
  );
};

const createChartDataGroupItemEndItem = (
  plan: PlanningOrderDriverPlanListingItemChartPlan,
  groupItem: ChartDataGroupItems,
  color: string,
  isEndPoint?: boolean
) => {
  const lastOrder = plan.orders[plan.orders.length - 1];
  const endList: PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] = [
    lastOrder.waypoints[lastOrder.waypoints.length - 1],
    plan.end!,
  ];
  groupItem.items.push(
    createChartDataGroupItemFromSinglePoint(endList, color, isEndPoint)
  );
};

const getChartDataGroupEntries = (
  list: PlanningOrderDriverPlanListingItemChartPlan[],
  groups: ChartDataGroup[],
  xAxisValue: number,
  color: string,
  isLabelHidden?: boolean
) => {
  list.forEach((plan) => {
    let group = groups.find((group) => group.topLabel === plan.uuid);

    if (!group) {
      group = { topLabel: plan.uuid, items: [] };
      groups.push(group);
    }

    let previousOrder: PlanningOrderDriverPlanListingItemChartPlanOrderPoints;
    const groupItem: ChartDataGroupItems = {
      xAxisValue: xAxisValue,
      items: [],
    };

    createChartDataGroupItemStartItem(plan, groupItem, color);
    createChartDataGroupItemEndItem(plan, groupItem, color, true);

    plan.orders.forEach(
      (order: PlanningOrderDriverPlanListingItemChartPlanOrderPoints) => {
        if (previousOrder) {
          const x: ChartDataGroupItem = createChartDataGroupItemOrderItem(
            previousOrder,
            order,
            color
          );
          groupItem?.items.push(x);
        }

        previousOrder = order;

        groupItem.items.push(
          createChartDataGroupItem(order, color, isLabelHidden)
        );
      }
    );
    group?.items.push(groupItem);
  });
};

const createChartItems = (
  item: PlanningOrderDriverPlanListingItemChart
): ChartData => {
  let groups: ChartDataGroup[] = [];

  const entries = item.entries;
  const isCandidatureEntriesExist = !!entries.find(
    (i) => i.type === PlanTypeEntry.CANDIDATURE_ENTRY
  );

  const planEntryXAxisValue = isCandidatureEntriesExist
    ? PlanXAxisValue.THIRD_ROW
    : PlanXAxisValue.SECOND_ROW;

  getChartDataGroupEntries(
    entries.filter((i) => i.type === PlanTypeEntry.PLAN_ENTRY),
    groups,
    planEntryXAxisValue,
    PlanColors.PLAN_ENRTRY
  );

  if (isCandidatureEntriesExist) {
    getChartDataGroupEntries(
      entries.filter((i) => i.type === PlanTypeEntry.CANDIDATURE_ENTRY),
      groups,
      PlanXAxisValue.SECOND_ROW,
      PlanColors.CANDIDATURE_ENTRY,
      true
    );
  }

  getChartDataGroupEntries(
    entries.filter((i) => i.type === PlanTypeEntry.ORDER),
    groups,
    PlanXAxisValue.FIRST_ROW,
    PlanColors.POTENCIAL_PLAN_ENTRY
  );

  return { group: groups };
};

const createCandidatureEntryOrderPoint = (
  item: DriverPlanCandidaturePreviewCandidatingPlanEntryWaypoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint => {
  return {
    address: item.address,
    date: item.date,
  };
};

const createCandidatureEntryOrderPoins = (
  item: DriverPlanCandidaturePreviewCandidatingPlanEntryWaypoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] => {
  return item.map(createCandidatureEntryOrderPoint);
};

const createCandidatureEntryOrder = (
  item: DriverPlanCandidaturePreviewCandidatingPlanEntryOrderPoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints => {
  return {
    waypoints: createCandidatureEntryOrderPoins(item.waypoints),
  };
};

const createCandidatureEntryOrders = (
  item: DriverPlanCandidaturePreviewCandidatingPlanEntryOrderPoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints[] => {
  return item.map(createCandidatureEntryOrder);
};

const createCandidatureEntries = (
  item: DriverPlanCandidaturePreviewCandidatingPlanEntry[]
): PlanningOrderDriverPlanListingItemChartPlan[] => {
  return item.map(createCandidatureEntry);
};

const createCandidatureEntry = (
  item: DriverPlanCandidaturePreviewCandidatingPlanEntry
): PlanningOrderDriverPlanListingItemChartPlan => {
  return {
    uuid: item.candidatureUuid,
    start: item.start,
    end: item.end,
    orders: createCandidatureEntryOrders(item.orders),
    type: PlanTypeEntry.CANDIDATURE_ENTRY,
  };
};

const createPlanEntryOrderPoint = (
  item: DriverPlanCandidaturePreviewPlanEntryOrderWaypoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint => {
  return {
    address: item.address,
    date: item.date,
  };
};

const createPlanEntryOrderPoints = (
  item: DriverPlanCandidaturePreviewPlanEntryOrderWaypoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] => {
  return item.map(createPlanEntryOrderPoint);
};

const createPlanEntryOrder = (
  item: DriverPlanCandidaturePreviewPlanEntryOrderPoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints => {
  return {
    uuid: item.orderId,
    waypoints: createPlanEntryOrderPoints(item.waypoints),
  };
};

const createPlanEntryOrders = (
  item: DriverPlanCandidaturePreviewPlanEntryOrderPoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints[] => {
  return item.map(createPlanEntryOrder);
};

const createPlanEntry = (
  item: DriverPlanCandidaturePreviewPlanEntry
): PlanningOrderDriverPlanListingItemChartPlan => {
  return {
    uuid: item.planEntryId,
    start: item.start,
    end: item.end,
    orders: createPlanEntryOrders(item.orders),
    type: PlanTypeEntry.PLAN_ENTRY,
  };
};

const createPlanEntries = (
  item: DriverPlanCandidaturePreviewPlanEntry[]
): PlanningOrderDriverPlanListingItemChartPlan[] => {
  return item.map(createPlanEntry);
};

const createDraftCandidatureOrderPoint = (
  item: DriverPlanCandidaturePreviewDraftCandidatingPlanEntryWaypoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint => {
  return {
    address: item.address,
    date: item.date,
  };
};

const createDraftCandidatureOrderPoints = (
  item: DriverPlanCandidaturePreviewDraftCandidatingPlanEntryWaypoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoint[] => {
  return item.map(createDraftCandidatureOrderPoint);
};

const createDraftCandidatureOrder = (
  item: DriverPlanCandidaturePreviewDraftCandidatingPlanEntryOrderPoint
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints => {
  return {
    uuid: item.planEntryUuid,
    waypoints: createDraftCandidatureOrderPoints(item.waypoints),
  };
};

const createDraftCandidatureOrders = (
  item: DriverPlanCandidaturePreviewDraftCandidatingPlanEntryOrderPoint[]
): PlanningOrderDriverPlanListingItemChartPlanOrderPoints[] => {
  return item.map(createDraftCandidatureOrder);
};

const createDraftCandidatureEntry = (
  item: DriverPlanCandidaturePreviewDraftCandidatingPlanEntry
): PlanningOrderDriverPlanListingItemChartPlan => {
  return {
    uuid: item.candidatureUuid,
    start: item.start,
    end: item.end,
    orders: createDraftCandidatureOrders(item.orders),
    type: PlanTypeEntry.ORDER,
  };
};

const createDraftCandidatureEntries = (
  item: DriverPlanCandidaturePreviewDraftCandidatingPlanEntry[]
): PlanningOrderDriverPlanListingItemChartPlan[] => {
  return item.map(createDraftCandidatureEntry);
};

const createChartItemData = (
  item: PlanningOrderDriverPlanListingSelectedItemChartData
): PlanningOrderDriverPlanListingItemChart => {
  const entries = [];
  const planEntries = createPlanEntries(item.data.planEntries);
  const candidatureEntries = createCandidatureEntries(
    item.data.candidatureEntries
  );
  const drafCandidatureEntries = createDraftCandidatureEntries(
    item.data.draftCandidatureEntries
  );

  entries.push(
    ...planEntries,
    ...candidatureEntries,
    ...drafCandidatureEntries
  );

  return {
    entries: entries,
  };
};

const createParams = (
  orderUuid: string,
  taxiDriverAssociationUuid?: string | undefined,
  planEntry?: PlanningOrderSelectedItemDataPlanEntry | undefined
): DriverPlanCandidaturePreviewLoadParams => {
  return {
    orderUuid: orderUuid,
    taxiDriverAssociationUuid: taxiDriverAssociationUuid,
    planEntryUuid: planEntry?.uuid,
  };
};

const createListingItemUserDriver = (
  item: PlanningOrderSelectedItemDataDriver
): PlanningOrderDriverPlanListingSelectedItemUserDriver => {
  return {
    firstName: item.firstName,
    lastName: item.lastName,
    uuid: item.uuid,
  };
};

const createListingItemUserPlanEntry = (
  item: PlanningOrderSelectedItemDataPlanEntry
): PlanningOrderDriverPlanListingSelectedItemUserPlanEntry => {
  return {
    uuid: item.uuid,
    internalOrderId: item.id,
  };
};

const createListingItemUser = (
  item: PlanningOrderSelectedItemData
): PlanningOrderDriverPlanListingSelectedItemUser => {
  return {
    id: item.id,
    driver: createListingItemUserDriver(item.driver),
    planEntry: createListingItemUserPlanEntry(item.planEntry!),
    taxiName: item.taxiName,
    taxiDriverAssociationUuid: item.taxiDriverAssociationUuid,
  };
};

const createListingItem = (
  item: PlanningOrderSelectedItemData,
  data: DriverPlanCandidaturePreview
): PlanningOrderDriverPlanListingSelectedItemChartData => {
  return {
    user: createListingItemUser(item),
    data: data,
  };
};

const planningOrderDriverPlanListingSelectedItemFactory = {
  createParams,
  createListingItem,
  createChartItems,
  createChartItemData,
};

export default planningOrderDriverPlanListingSelectedItemFactory;
