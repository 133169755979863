import DelegationDetailsRequest from "../../../api/raily/delegation/details/delegation-details.request";
import DelegationDetailsLoadParams from "./delegation-details-load-params";

const create = (
  params: DelegationDetailsLoadParams
): DelegationDetailsRequest => {
  return {
    delegationUuid: params.delegationUuid,
  };
};

const delegationDetailsRequestFactory = {
  create,
};

export default delegationDetailsRequestFactory;
