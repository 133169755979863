import { FC } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import formValidationHelper from "../../../../../common/utils/validation/form-validation.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../common/context/sign-up-driver.context";
import signUpDriverFormHelper from "../common/sign-up-driver-form.helper";
import SignUpDriverFormVehicleData from "../common/types/sign-up-driver-form-vehicle-data";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";

type SignUpDriverVehicleProps = {};

const SignUpDriverVehicleComponent: FC<SignUpDriverVehicleProps> = () => {
  const {
    vehicleData,
    setVehicleDataMake,
    setVehicleDataModel,
    setVehicleDataNumberOfSeats,
    setVehicleDataOwnerShip,
    setVehicleDataProductionYear,
    setVehicleDataRegistrationNumber,
    vehicleValidationResults,
    validateVehicleDataMake,
    validateVehicleDataModel,
    validateVehicleDataNumberOfSeats,
    validateVehicleDataOwnerShip,
    validateVehicleDataProductionYear,
    validateVehicleDataRegistrationNumber,
    onVehicleStepSubmitButtonClick,
  } = useSignUpDriverContext();

  const vehicleDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().vehicleDataForm;

  const ownershipSelectOptions =
    signUpDriverFormHelper.getVehicleOwnershipSelectOptions();

  const isSubmitButtonEnabled = formValidationHelper.checkThatFormHasErrors(
    vehicleValidationResults
  );

  const onVehicleOwnershipChange = (
    value: SignUpDriverFormVehicleData["ownership"]
  ) => {
    setVehicleDataOwnerShip(value);
    validateVehicleDataOwnerShip(value);
  };

  return (
    <>
      <h1 className="sign_up_driver__step_title">
        {vehicleDataFormTranslations.title}
      </h1>
      <CardComponent>
        <Row>
          <Column md={6}>
            <FormFieldComponent
              isRequired
              label={vehicleDataFormTranslations.makeLabel}
              errorMessage={vehicleValidationResults.make.errorMessage}
            >
              <InputComponent
                placeholder={vehicleDataFormTranslations.makePlaceholder}
                value={vehicleData.make}
                onChange={setVehicleDataMake}
                onBlur={validateVehicleDataMake}
                hasError={!!vehicleValidationResults.make.errorMessage}
                idForTesting="vehicle-make"
              />
            </FormFieldComponent>
          </Column>
          <Column md={6}>
            <FormFieldComponent
              isRequired
              label={vehicleDataFormTranslations.modelLabel}
              errorMessage={vehicleValidationResults.model.errorMessage}
            >
              <InputComponent
                placeholder={vehicleDataFormTranslations.modelPlaceholder}
                value={vehicleData.model}
                onChange={setVehicleDataModel}
                onBlur={validateVehicleDataModel}
                hasError={!!vehicleValidationResults.model.errorMessage}
                idForTesting="vehicle-model"
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormFieldComponent
              isRequired
              label={vehicleDataFormTranslations.productionYearLabel}
              errorMessage={
                vehicleValidationResults.productionYear.errorMessage
              }
            >
              <InputComponent
                placeholder={
                  vehicleDataFormTranslations.productionYearPlaceholder
                }
                value={String(vehicleData.productionYear)}
                type="number"
                onChange={(productionYear) => {
                  setVehicleDataProductionYear(
                    productionYear !== "" ? Number(productionYear) : null
                  );
                }}
                onBlur={validateVehicleDataProductionYear}
                hasError={
                  !!vehicleValidationResults.productionYear.errorMessage
                }
                idForTesting="vehicle-production-year"
              />
            </FormFieldComponent>
          </Column>

          <Column md={6}>
            <FormFieldComponent
              isRequired
              label={vehicleDataFormTranslations.numberOfSeatsLabel}
              errorMessage={vehicleValidationResults.numberOfSeats.errorMessage}
            >
              <InputComponent
                type="number"
                placeholder={
                  vehicleDataFormTranslations.numberOfSeatsPlaceholder
                }
                value={String(vehicleData.numberOfSeats)}
                onChange={(numberOfSeats) => {
                  setVehicleDataNumberOfSeats(
                    numberOfSeats !== "" ? Number(numberOfSeats) : null
                  );
                }}
                onBlur={validateVehicleDataNumberOfSeats}
                hasError={!!vehicleValidationResults.numberOfSeats.errorMessage}
                idForTesting="vehicle-number-of-seats"
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormFieldComponent
              isRequired
              label={vehicleDataFormTranslations.registrationNumberLabel}
              errorMessage={
                vehicleValidationResults.registrationNumber.errorMessage
              }
            >
              <InputComponent
                placeholder={
                  vehicleDataFormTranslations.registrationNumberPlaceholder
                }
                value={vehicleData.registrationNumber}
                onChange={setVehicleDataRegistrationNumber}
                onBlur={validateVehicleDataRegistrationNumber}
                hasError={
                  !!vehicleValidationResults.registrationNumber.errorMessage
                }
                idForTesting="vehicle-registration-number"
              />
            </FormFieldComponent>
          </Column>
          <Column md={6}>
            <FormFieldComponent
              isRequired
              label={vehicleDataFormTranslations.ownershipLabel}
              errorMessage={vehicleValidationResults.ownership.errorMessage}
            >
              <SingleSelectComponent
                placeholder={vehicleDataFormTranslations.ownershipPlaceholder}
                value={vehicleData.ownership}
                options={ownershipSelectOptions}
                onChange={(selectedOptions) =>
                  onVehicleOwnershipChange(selectedOptions)
                }
                hasError={!!vehicleValidationResults.ownership.errorMessage}
                idForTesting="vehicle-ownership"
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <div className="sign_up_driver__next_button_wrapper">
        <ButtonComponent
          onClick={onVehicleStepSubmitButtonClick}
          type="primary"
          isDisabled={!isSubmitButtonEnabled}
          idForTesting="vehicle-submit-button"
        >
          {vehicleDataFormTranslations.submitButtonText}
        </ButtonComponent>
      </div>
    </>
  );
};

export default SignUpDriverVehicleComponent;
