import UserRole from "../../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../../common/utils/user/permissions/user-permissions.service";
import DriverDetailsPlanEntryRoutePlansUserPermissionDefinition, {
  DriverDetailsPlanEntryRoutePlansUserPermissions,
} from "./driver-details-plan-entry-route-plans-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): DriverDetailsPlanEntryRoutePlansUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    DriverDetailsPlanEntryRoutePlansUserPermissionDefinition
  );

  return permissions;
};

const driverDetailsPlanEntryRoutePlansUserPermissionsHelper = {
  getPermissions,
};

export default driverDetailsPlanEntryRoutePlansUserPermissionsHelper;
