import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import taxiOrderSettlementListingFilterHelper from "../../taxi-order-settlement-listing-filter.helper";
import TaxiOrderSettlementListingFilter from "../../types/taxi-order-settlement-listing-filter";
import TaxiOrderSettlementListingFilterSelectOption from "../../types/taxi-order-settlement-listing-filter-select-option";

type TaxiOrderSettlementListingFiltersSelectProps = {
  filters: TaxiOrderSettlementListingFilter[];
  onAddNewFilter: (filter: TaxiOrderSettlementListingFilter) => void;
};

const TaxiOrderSettlementListingFiltersSelectComponent: FC<
  TaxiOrderSettlementListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: TaxiOrderSettlementListingFilterSelectOption[] =
    useMemo(() => {
      return taxiOrderSettlementListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: TaxiOrderSettlementListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as TaxiOrderSettlementListingFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="taxi-order-settlement-listing-filter"
    />
  );
};

export default TaxiOrderSettlementListingFiltersSelectComponent;
