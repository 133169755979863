import { FC, useMemo } from "react";
import { useAppContext } from "../../../../../../context/app.context";
import CardComponent from "../../../../../../common/components/card/card.component";
import DateInputComponent from "../../../../../../common/components/form/input/date/date-input.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../common/components/form/input/input.component";
import NumericInputComponent from "../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import PhoneNumberInputComponent from "../../../../../../common/components/form/input/phone-number/phone-number-input.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import UserCitizenshipSelectOption from "../../../../../../common/types/user-citizenship-select-option";
import UserFormOfEmploymentSelectOption from "../../../../../../common/types/user-form-of-employment-select-option";
import UserLanguageSelectOption from "../../../../../../common/types/user-language-select-option";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import driverEditFormHelper from "../../common/driver-edit-form.helper";
import DriverEditAddress from "../../common/types/driver-edit-address";
import DriverEditUserDataFleetPartnerSelectOption from "../../common/types/driver-edit-user-data-fleet-partner-select-option";
import DriverEditUserDataAddressComponent from "../../common/user/address/driver-edit-user-data-address.component";
import DriverEditByRailyUserFormData from "../common/types/driver-edit-by-raily-user-form-data";
import DriverEditByRailyUserFormValidationResults from "../common/types/driver-edit-by-raily-user-form-validation-results";
import MultiSelectComponent from "../../../../../../common/components/form/select/multi-select/multi-select.component";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";

type DriverEditUserDataProps = {
  formData: DriverEditByRailyUserFormData;
  formValidationResults: DriverEditByRailyUserFormValidationResults;
  fleetPartnerSelectOptions: DriverEditUserDataFleetPartnerSelectOption[];
  onFormDataChange: (formData: DriverEditByRailyUserFormData) => void;
  validateFirstName: () => void;
  validateLastName: () => void;
  validateMobile: () => void;
  validateAlternativeMobile: () => void;
  validateBirthDate: (
    value: DriverEditByRailyUserFormData["birthDate"]
  ) => void;
  validateBirthPlace: () => void;
  validatePersonalIdNumber: () => void;
  validateCitizenship: (
    value: DriverEditByRailyUserFormData["citizenship"]
  ) => void;
  validateLanguages: (
    value: DriverEditByRailyUserFormData["languages"]
  ) => void;
  validateYearsOfExperience: () => void;
  validateFleetPartner: (
    value: DriverEditByRailyUserFormData["fleetPartner"]
  ) => void;
  validateFormOfEmployment: (
    value: DriverEditByRailyUserFormData["formOfEmployment"]
  ) => void;
  validateAddresses: (
    value: DriverEditByRailyUserFormData["addresses"]
  ) => void;
};

const DriverEditByRailyUserDataComponent: FC<DriverEditUserDataProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.userData;

  const citizenshipSelectOptions: UserCitizenshipSelectOption[] = useMemo(
    () => driverEditFormHelper.getCitizenshipSelectOptions(),
    [selectedAppLanguage]
  );

  const languageSelectOptions: UserLanguageSelectOption[] = useMemo(
    () => driverEditFormHelper.getLanguageSelectOptions(),
    [selectedAppLanguage]
  );

  const formOfEmploymentSelectOptions: UserFormOfEmploymentSelectOption[] =
    useMemo(
      () => driverEditFormHelper.getFormOfEmploymentSelectOptions(),
      [selectedAppLanguage]
    );

  const setFirstName = (value: DriverEditByRailyUserFormData["firstName"]) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      firstName: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setLastName = (value: DriverEditByRailyUserFormData["lastName"]) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      lastName: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setMobile = (value: DriverEditByRailyUserFormData["mobile"]) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      mobile: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setAlternativeMobile = (
    value: DriverEditByRailyUserFormData["alternativeMobile"]
  ) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      alternativeMobile: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setBirthDate = (value: DriverEditByRailyUserFormData["birthDate"]) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      birthDate: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setBirthPlace = (
    value: DriverEditByRailyUserFormData["birthPlace"]
  ) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      birthPlace: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setPersonalIdNumber = (
    value: DriverEditByRailyUserFormData["personalIdNumber"]
  ) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      personalIdNumber: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setCitizenship = (
    value: DriverEditByRailyUserFormData["citizenship"]
  ) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      citizenship: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setLanguages = (value: DriverEditByRailyUserFormData["languages"]) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      languages: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setYearsOfExperience = (
    value: DriverEditByRailyUserFormData["yearsOfExperience"]
  ) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      yearsOfExperience: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setFormOfEmployment = (
    value: DriverEditByRailyUserFormData["formOfEmployment"]
  ) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      formOfEmployment: value,
    };
    props.onFormDataChange(newFormData);
  };

  const setFleetPartner = (
    value: DriverEditByRailyUserFormData["fleetPartner"]
  ) => {
    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      fleetPartner: value,
    };
    props.onFormDataChange(newFormData);
  };

  const addAddress = (value: DriverEditAddress) => {
    const newAddresses: DriverEditByRailyUserFormData["addresses"] = [
      ...props.formData.addresses,
      value,
    ];

    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      addresses: newAddresses,
    };
    props.onFormDataChange(newFormData);
    props.validateAddresses(newAddresses);
  };

  const removeAddress = (indexOfAddressToRemove: number) => {
    const newAddresses: DriverEditByRailyUserFormData["addresses"] =
      props.formData.addresses.filter(
        (_address, index) => indexOfAddressToRemove !== index
      );

    const newFormData: DriverEditByRailyUserFormData = {
      ...props.formData,
      addresses: newAddresses,
    };
    props.onFormDataChange(newFormData);
    props.validateAddresses(newAddresses);
  };

  const onCitizenshipChange = (
    value: DriverEditByRailyUserFormData["citizenship"]
  ) => {
    setCitizenship(value);
    props.validateCitizenship(value);
  };

  const onLanguagesChange = (
    value: DriverEditByRailyUserFormData["languages"]
  ) => {
    setLanguages(value);
    props.validateLanguages(value);
  };

  const onFormOfEmploymentChange = (
    value: DriverEditByRailyUserFormData["formOfEmployment"]
  ) => {
    setFormOfEmployment(value);
    props.validateFormOfEmployment(value);
  };

  const onBirthDateChange = (
    value: DriverEditByRailyUserFormData["birthDate"]
  ) => {
    setBirthDate(value);
    props.validateBirthDate(value);
  };

  const onFleetPartnerChange = (
    value: DriverEditByRailyUserFormData["fleetPartner"]
  ) => {
    setFleetPartner(value);
    props.validateFleetPartner(value);
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.firstNameLabel}
            isRequired
            errorMessage={props.formValidationResults.firstName.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.firstNamePlaceholder}
              value={props.formData.firstName}
              onChange={setFirstName}
              onBlur={props.validateFirstName}
              hasError={!!props.formValidationResults.firstName.errorMessage}
              idForTesting={`user-data-first-name-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.lastNameLabel}
            isRequired
            errorMessage={props.formValidationResults.lastName.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.lastNamePlaceholder}
              value={props.formData.lastName}
              onChange={setLastName}
              onBlur={props.validateLastName}
              hasError={!!props.formValidationResults.lastName.errorMessage}
              idForTesting={`user-data-last-name-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.mobileLabel}
            isRequired
            errorMessage={props.formValidationResults.mobile.errorMessage}
          >
            <PhoneNumberInputComponent
              placeholder={translations.mobilePlaceholder}
              phoneNumber={props.formData.mobile}
              onChange={setMobile}
              onBlur={props.validateMobile}
              hasError={!!props.formValidationResults.mobile.errorMessage}
              idForTesting={`user-data-mobile-number`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.alternativeMobileLabel}
            errorMessage={
              props.formValidationResults.alternativeMobile.errorMessage
            }
          >
            <PhoneNumberInputComponent
              placeholder={translations.alternativeMobilePlaceholder}
              phoneNumber={props.formData.alternativeMobile}
              onChange={setAlternativeMobile}
              onBlur={props.validateAlternativeMobile}
              hasError={
                !!props.formValidationResults.alternativeMobile.errorMessage
              }
              idForTesting={`user-data-alternative-mobile`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.birthDateLabel}
            isRequired
            errorMessage={props.formValidationResults.birthDate.errorMessage}
          >
            <DateInputComponent
              placeholder={translations.birthDatePlaceholder}
              date={props.formData.birthDate}
              onChange={onBirthDateChange}
              hasError={!!props.formValidationResults.birthDate.errorMessage}
              maxDate={new Date()}
              idForTesting={`user-data-birth-date-picker-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.birthPlaceLabel}
            isRequired
            errorMessage={props.formValidationResults.birthPlace.errorMessage}
          >
            <InputComponent
              placeholder={translations.birthPlacePlaceholder}
              value={props.formData.birthPlace}
              onChange={setBirthPlace}
              onBlur={props.validateBirthPlace}
              hasError={!!props.formValidationResults.birthPlace.errorMessage}
              idForTesting={`user-data-birth-place-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.personalIdNumberLabel}
            isRequired
            errorMessage={
              props.formValidationResults.personalIdNumber.errorMessage
            }
          >
            <InputComponent
              placeholder={translations.personalIdNumberPlaceholder}
              value={props.formData.personalIdNumber}
              onChange={setPersonalIdNumber}
              onBlur={props.validatePersonalIdNumber}
              hasError={
                !!props.formValidationResults.personalIdNumber.errorMessage
              }
              idForTesting={`user-data-personal-id-number-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.citizenshipLabel}
            isRequired
            errorMessage={props.formValidationResults.citizenship.errorMessage}
          >
            <SingleSelectComponent
              placeholder={translations.citizenshipPlaceholder}
              value={props.formData.citizenship}
              options={citizenshipSelectOptions}
              onChange={(selectedOptions) =>
                onCitizenshipChange(selectedOptions)
              }
              hasError={!!props.formValidationResults.citizenship.errorMessage}
              idForTesting={`user-data-citizenship-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.languagesLabel}
            isRequired
            errorMessage={props.formValidationResults.languages.errorMessage}
          >
            <MultiSelectComponent
              placeholder={translations.languagesPlaceholder}
              value={props.formData.languages}
              options={languageSelectOptions}
              onChange={(selectedOptions) => onLanguagesChange(selectedOptions)}
              hasError={!!props.formValidationResults.languages.errorMessage}
              idForTesting={`user-data-languages-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.experienceLabel}
            isRequired
            errorMessage={
              props.formValidationResults.yearsOfExperience.errorMessage
            }
          >
            <NumericInputComponent
              placeholder={translations.experiencePlaceholder}
              value={props.formData.yearsOfExperience}
              isIntegerOnly
              onChange={setYearsOfExperience}
              onBlur={props.validateYearsOfExperience}
              hasError={
                !!props.formValidationResults.yearsOfExperience.errorMessage
              }
              idForTesting={`user-data-experience-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.formOfEmploymentLabel}
            isRequired
            errorMessage={
              props.formValidationResults.formOfEmployment.errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={translations.formOfEmploymentPlaceholder}
              value={props.formData.formOfEmployment}
              options={formOfEmploymentSelectOptions}
              onChange={(formOfEmployment) =>
                onFormOfEmploymentChange(formOfEmployment)
              }
              hasError={
                !!props.formValidationResults.formOfEmployment.errorMessage
              }
              idForTesting={`user-data-form-of-employment-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.fleetPartnerLabel}
            errorMessage={props.formValidationResults.fleetPartner.errorMessage}
          >
            <SingleSelectComponent
              placeholder={translations.fleetPartnerPlaceholder}
              value={props.formData.fleetPartner}
              options={props.fleetPartnerSelectOptions}
              onChange={(fleetPartner) => onFleetPartnerChange(fleetPartner)}
              isClearable
              hasError={!!props.formValidationResults.fleetPartner.errorMessage}
              idForTesting={`user-data-fleet-partner-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column>
          <FormFieldComponent
            label={translations.addressesLabel}
            isRequired
            errorMessage={props.formValidationResults.addresses.errorMessage}
          >
            <DriverEditUserDataAddressComponent
              addresses={props.formData.addresses}
              onRemoveAddressButtonClick={removeAddress}
              onAddNewAddress={addAddress}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default DriverEditByRailyUserDataComponent;
