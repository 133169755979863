import taxiRouteSettlementListingRouteQueryParamsService from "../../common/taxi-route-settlement-listing-route-query-params.service";

const taxiRouteSettlementListingByTaxiRouteQueryParamsService = {
  getFilters: taxiRouteSettlementListingRouteQueryParamsService.getFilters,
  getSortKey: taxiRouteSettlementListingRouteQueryParamsService.getSortKey,
  getPage: taxiRouteSettlementListingRouteQueryParamsService.getPage,
  getPageSize: taxiRouteSettlementListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams:
    taxiRouteSettlementListingRouteQueryParamsService.createRouteQueryParams,
};

export default taxiRouteSettlementListingByTaxiRouteQueryParamsService;
