import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import ContractValidCollectionLoadParams from "./valid-collection/contract-valid-collection-load-params";
import ContractValidCollectionRequest from "../../api/raily/contracts/valid-collection/contract-valid-collection.request";
import contractValidCollectionRequestFactory from "./valid-collection/contract-valid-collection-request.factory";
import contractApiService from "../../api/raily/contracts/contract-api.service";
import ContractValidCollectionError from "./valid-collection/contract-valid-collection-error";

const handleListError = (
  error: HttpError | ContractValidCollectionError
): ContractValidCollectionError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getValidCollection = async (
  params: ContractValidCollectionLoadParams,
  abortSignal: AbortSignal
): Promise<any> => {
  const request: ContractValidCollectionRequest =
    contractValidCollectionRequestFactory.create(params);

  try {
    const response = await contractApiService().getValidCollection(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return response;
  } catch (_error) {
    const error = _error as HttpError | ContractValidCollectionError;

    throw handleListError(error);
  }
};

const contractService = {
  getValidCollection,
};

export default contractService;
