import UserRole from "../../../../../common/types/user-role";
import userPermissionsService from "../../../../../common/utils/user/permissions/user-permissions.service";
import mileageAddUserPermissionsDefinition, {
  MileageAddUserPermissions,
} from "./mileage-add-user-permissions";

const getPermissions = (userRoles: UserRole[]): MileageAddUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    mileageAddUserPermissionsDefinition
  );

  return permissions;
};

const mileageAddUserPermissionsHelper = {
  getPermissions,
};

export default mileageAddUserPermissionsHelper;
