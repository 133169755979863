import TaxiFleetPartnerAddRequest from "../../../api/raily/taxi-fleet-partner/add/taxi-fleet-partner-add.request";
import TaxiFleetPartnerAddParams from "./taxi-fleet-partner-add-params";

const create = (
  params: TaxiFleetPartnerAddParams
): TaxiFleetPartnerAddRequest => {
  return {
    taxiCorporationUuid: params.taxiCorporationUuid,
    name: params.name,
  };
};

const taxiFleetPartnerAddRequestFactory = { create };

export default taxiFleetPartnerAddRequestFactory;
