import { FC } from "react";
import taxiRouteSettlementListingFiltersBadgeListHelper from "./taxi-route-settlement-listing-filters-badge-list.helper";
import TaxiRouteSettlementListingFilter from "../../types/taxi-route-settlement-listing-filter";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type TaxiRouteSettlementListingFiltersListProps = {
  filters: TaxiRouteSettlementListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const TaxiRouteSettlementListingFiltersBadgeListComponent: FC<
  TaxiRouteSettlementListingFiltersListProps
> = (props) => {
  const badges = taxiRouteSettlementListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default TaxiRouteSettlementListingFiltersBadgeListComponent;
