import mileageContractApiService from "../../api/raily/mileage-contract/mileage-contract-api.service";
import { DataLoadDefaultErrors } from "../../utils/data-load-error/data-load-error";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import MileageContractAddError from "./add/mileage-contract-add-error";
import MileageContractAddParams from "./add/mileage-contract-add-params";
import mileageContractsAddRequestFactory from "./add/mileage-contract-add-request.factory";
import MileageContractTemplate from "./template/mileage-contract-template";
import mileageContractTemplateFactory from "./template/mileage-contract-template.factory";
import MileageContractList from "./list/mileage-contract-list";
import MileageContractListError from "./list/mileage-contract-list-error";
import MileageContractListLoadParams from "./list/mileage-contract-list-load-params";
import mileageContractsListRequestFactory from "./list/mileage-contract-list-request.factory";
import mileageContractsListFactory from "./list/mileage-contract-list.factory";
import MileageContractDetailsLoadParams from "./details/mileage-contract-details-load-params";
import MileageContractDetails from "./details/mileage-contract-details";
import MileageContractDetailsError from "./details/mileage-contract-details-error";
import mileageContractDetailsRequestFactory from "./details/mileage-contract-details-request.factory";
import MileageContractDetailsRequest from "../../api/raily/mileage-contract/details/mileage-contract-details.request";
import mileageContractDetailsFactory from "./details/mileage-contract-details.factory";
import MileageContractUpdateError from "./update/mileage-contract-update-error";
import mileageContractsUpdateRequestFactory from "./update/mileage-contract-update-request.factory";
import MileageContractUpdateParams from "./update/mileage-contract-update-params";

const handleListError = (
  error: MileageContractListError | HttpError
): MileageContractListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleAddError = (
  error: MileageContractAddError | HttpError
): MileageContractAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleUpdateError = (
  error: MileageContractUpdateError | HttpError
): MileageContractUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleTemplateError = (
  error: DataLoadDefaultErrors | HttpError
): DataLoadDefaultErrors => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDetailsError = (
  error: DataLoadDefaultErrors | HttpError
): DataLoadDefaultErrors => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: MileageContractListLoadParams,
  abortSignal: AbortSignal
): Promise<MileageContractList> => {
  const request = mileageContractsListRequestFactory.create(params);

  try {
    const response = await mileageContractApiService().list(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const data = mileageContractsListFactory.create(response);
    return data;
  } catch (_error) {
    const error = _error as MileageContractListError | HttpError;

    throw handleListError(error);
  }
};

const add = async (
  params: MileageContractAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request = mileageContractsAddRequestFactory.create(params);

  try {
    const response = await mileageContractApiService().add(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as MileageContractAddError | HttpError;

    throw handleAddError(error);
  }
};

const update = async (
  params: MileageContractUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request = mileageContractsUpdateRequestFactory.create(params);

  try {
    const response = await mileageContractApiService().update(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as MileageContractUpdateError | HttpError;

    throw handleUpdateError(error);
  }
};

const getTemplate = async (
  abortSignal: AbortSignal
): Promise<MileageContractTemplate> => {
  try {
    const response = await mileageContractApiService().getTemplate(abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return mileageContractTemplateFactory.create(response);
  } catch (_error) {
    const error = _error as DataLoadDefaultErrors | HttpError;

    throw handleTemplateError(error);
  }
};

const getDetails = async (
  params: MileageContractDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<MileageContractDetails> => {
  const request: MileageContractDetailsRequest =
    mileageContractDetailsRequestFactory.create(params);

  try {
    const response = await mileageContractApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return mileageContractDetailsFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | MileageContractDetailsError;

    throw handleDetailsError(error);
  }
};

const mileageContractService = {
  getList,
  add,
  update,
  getTemplate,
  getDetails,
};

export default mileageContractService;
