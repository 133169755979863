import BillingsPlanEntryTaxiTaxi, {
  BillingsPlanEntryTaxiTaxiItem,
} from "./billings-plan-entry-taxi-taxi";
import BillingModel from "../../../../types/billing-model";
import BillingStatus from "../../../../types/billing-status";
import BillingsPlanEntryTaxiTaxiResponse, {
  BillingsPlanEntryTaxiTaxiResponseItem,
} from "../../../../api/raily/billings/plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi.response";

const createDataItem = (
  data: BillingsPlanEntryTaxiTaxiResponseItem
): BillingsPlanEntryTaxiTaxiItem => {
  return {
    billingUuid: data.id,
    forwardingUuid: data.orderForwardingAction.id,
    contractDetails: {
      companyName: data.orderForwardingAction.contract.consumerTaxi.displayName,

      distanceRate: data.orderForwardingAction.contract.distanceRate,
      model: data.orderForwardingAction.contract.model as BillingModel,
    },
    baseAmountValue: data.baseAmountValue,
    amountForChargeHaltings: data.amountForChargeHaltings,
    amountForChargeHighways: data.amountForChargeHighways,
    distance: data.distance,
    amountForDistance: data.amountForDistance,
    internalOrderId:
      data.orderForwardingAction.transportingOrder.cargoOrder.humanId,
    allContributionsAmount: data.allContributionsAmount,
    isDraft: data.draft,
    date: data.date,
    sumOfBonuses: data.sumOfBonuses,
    sumOfPenalties: data.sumOfPenalties,
    status: data.status as BillingStatus,
    readOnly: data.readOnly,
  };
};

const createData = (
  responseData: BillingsPlanEntryTaxiTaxiResponseItem[]
): BillingsPlanEntryTaxiTaxiItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: BillingsPlanEntryTaxiTaxiResponse
): BillingsPlanEntryTaxiTaxi => {
  return {
    data: createData(response.data),
  };
};

const billingsPlanEntryTaxiTaxiFactory = {
  create,
};

export default billingsPlanEntryTaxiTaxiFactory;
