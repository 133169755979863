enum CargoOfficerListingFilterType {
  CARGO_COMPANY = "CARGO_COMPANY",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  USERNAME = "USERNAME",
  EMAIL = "EMAIL",
  ACTIVITY_STATUS = "STATUS",
}

export default CargoOfficerListingFilterType;
