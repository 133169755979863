const billingRoutesUrls = {
  cargoWithRailyAdd: `/billings/cargo-raily/order/:orderUuid/add`,
  railyWithTaxiAdd: `/billings/raily-taxi/order/:orderUuid/add`,
  cargoWithTaxiAdd: `/billings/cargo-taxi/order/:orderUuid/add`,
  taxiWithDriverAdd: `/billings/taxi-driver/plan-entry/:planEntryUuid/add`,
  taxiWithRailyAdd: `/billings/taxi-raily/order/:orderUuid/add`,
  cargoWithTaxiEdit: `/billings/cargo-taxi/:billingUuid/edit`,
  cargoWithTaxiDetails: `/billings/cargo-taxi/:billingUuid/details`,
  taxiWithDriverEdit: `/billings/taxi-driver/:billingUuid/edit`,
  taxiWithTaxiAdd: `/billings/taxi-taxi/:forwardingUuid/add`,
  taxiWithTaxiEdit: `/billings/taxi-taxi/:billingUuid/edit`,
  taxiWithTaxiDetails: `/billings/taxi-taxi/:billingUuid/details`,
  taxiWithDriverDetails: `/billings/taxi-driver/:billingUuid/details`,
};

export default billingRoutesUrls;
