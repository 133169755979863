import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import OrderFinishedListingRouteQueryParams from "../../common/routes/types/order-finished-listing-route-params";
import OrderFinishedListingFilter from "../common/types/order-finished-listing-filter";
import OrderFinishedListingSortKey from "../common/types/order-finished-listing-sort-key";
import orderFinishedListingFiltersService from "./order-finished-listing-filters.service";

const useOrderFinishedListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderFinishedListingRouteQueryParams>();

  const getFilters = (): OrderFinishedListingFilter[] => {
    return orderFinishedListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return orderFinishedListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return orderFinishedListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): OrderFinishedListingSortKey | undefined => {
    return orderFinishedListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: OrderFinishedListingFilter[],
    sortKey: OrderFinishedListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      orderFinishedListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useOrderFinishedListingFiltersDao;
