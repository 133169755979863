import taxiOrderSettlementListingRouteQueryParamsService from "../../common/taxi-order-settlement-listing-route-query-params.service";

const taxiOrderSettlementListingByTaxiRouteQueryParamsService = {
  getFilters: taxiOrderSettlementListingRouteQueryParamsService.getFilters,
  getSortKey: taxiOrderSettlementListingRouteQueryParamsService.getSortKey,
  getPage: taxiOrderSettlementListingRouteQueryParamsService.getPage,
  getPageSize: taxiOrderSettlementListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams:
    taxiOrderSettlementListingRouteQueryParamsService.createRouteQueryParams,
};

export default taxiOrderSettlementListingByTaxiRouteQueryParamsService;
