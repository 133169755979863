import RefreshTokenResponse from "../../../api/raily/auth/refresh-token/refresh-token.response";
import RefreshTokenResult from "./refresh-token-result";

const create = (response: RefreshTokenResponse): RefreshTokenResult => {
  return {
    accessToken: response.data.accessToken,
  };
};

const refreshTokenResultFactory = {
  create,
};

export default refreshTokenResultFactory;
