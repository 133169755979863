import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import ButtonComponent, { ButtonProps } from "../../button/button.component";
import classNames from "classnames";

export type TableButtonProps = Omit<ButtonProps, "children"> & {
  icon: IconProp;
};

const TableButtonComponent: FC<TableButtonProps> = (props) => {
  return (
    <ButtonComponent
      {...props}
      classNames={{
        root: classNames("table_button", props.classNames?.root),
        content: "table_button__content",
      }}
    >
      <FontAwesomeIcon
        icon={props.icon}
        className="table_button__icon"
        size={"sm"}
      />
    </ButtonComponent>
  );
};

TableButtonComponent.defaultProps = {
  type: "brand",
};

export default TableButtonComponent;
