import urlService from "../../../../common/utils/url/url.service";
import delegationRoutesUrls from "./delegation-routes-urls";
import { DelegationJoinRouteParams } from "./types/delegation-join-route-params";

const getAddRoute = (): string => {
  return delegationRoutesUrls.add;
};

const getListingRoute = (): string => {
  return delegationRoutesUrls.listing;
};

const getJoinRoute = (params: DelegationJoinRouteParams): string => {
  return urlService.buildWithoutEmptyParams(delegationRoutesUrls.join, params);
};

const delegationRoutesHelper = {
  getAddRoute,
  getListingRoute,
  getJoinRoute,
};

export default delegationRoutesHelper;
