import CargoCompanyAddressListRequest from "../../../../api/raily/cargo-company/address/list/cargo-company-address-list.request";
import CargoCompanyAddressListLoadParams from "./cargo-company-address-list-load-params";

const create = (
  params: CargoCompanyAddressListLoadParams
): CargoCompanyAddressListRequest => {
  return {
    companyUuid: params.companyUuid,
    searchQuery: params.searchQuery,
  };
};

const cargoCompanyAddressListRequestFactory = {
  create,
};

export default cargoCompanyAddressListRequestFactory;
