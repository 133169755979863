import { FC } from "react";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../common/components/form/input/input.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverEditAccountFormData from "../types/driver-edit-account-form-data";
import DriverEditAccountFormValidationResults from "../types/driver-edit-account-form-validation-results";

type DriverEditAccountDataProps = {
  formData: DriverEditAccountFormData;
  formValidationResults: DriverEditAccountFormValidationResults;
  onFormDataChange: (formData: DriverEditAccountFormData) => void;
  validateEmail: () => void;
};

const DriverEditAccountDataComponent: FC<DriverEditAccountDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.accountData;

  const setEmail = (value: DriverEditAccountFormData["email"]) => {
    const newFormData: DriverEditAccountFormData = {
      ...props.formData,
      email: value,
    };
    props.onFormDataChange(newFormData);
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.emailLabel}
            isRequired
            errorMessage={props.formValidationResults.email.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.emailPlaceholder}
              value={props.formData.email}
              onChange={setEmail}
              onBlur={props.validateEmail}
              hasError={!!props.formValidationResults.email.errorMessage}
              idForTesting={`account-data-email`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.usernameLabel}
            isRequired
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.usernamePlaceholder}
              value={props.formData.username}
              idForTesting={`account-data-user-name`}
              isDisabled={true}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default DriverEditAccountDataComponent;
