import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import userHelper from "../../../../../common/utils/user/user.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import SignUpDriverAddressType from "./types/sign-up-driver-address-type";
import SignUpDriverFormStep from "./types/sign-up-driver-form-step";

const getDefaultFormSteps = () => {
  return [
    SignUpDriverFormStep.accountData,
    SignUpDriverFormStep.userData,
    SignUpDriverFormStep.vehicleData,
    SignUpDriverFormStep.summary,
  ];
};

const getFormStepsForB2B = () => {
  return [
    SignUpDriverFormStep.accountData,
    SignUpDriverFormStep.userData,
    SignUpDriverFormStep.companyData,
    SignUpDriverFormStep.vehicleData,
    SignUpDriverFormStep.summary,
  ];
};

const getAddressTypeSelectOptions =
  (): SelectOption<SignUpDriverAddressType>[] => {
    const addressTypeTranslations =
      appTranslationsHelper.getDriverSignUpTranslations().userDataForm
        .addNewAddressForm.addressType;

    return [
      {
        label: addressTypeTranslations.MAILING,
        value: SignUpDriverAddressType.MAILING,
      },
      {
        label: addressTypeTranslations.STARTING,
        value: SignUpDriverAddressType.STARTING,
      },
    ];
  };

const signUpDriverFormHelper = {
  getCitizenshipSelectOptions: userHelper.getCitizenshipSelectOptions,
  getLanguageSelectOptions: userHelper.getLanguageSelectOptions,
  getFormOfEmploymentSelectOptions: userHelper.getFormOfEmploymentSelectOptions,
  getVehicleOwnershipSelectOptions: userHelper.getVehicleOwnershipSelectOptions,
  getDefaultFormSteps,
  getFormStepsForB2B,
  getAddressTypeSelectOptions,
};

export default signUpDriverFormHelper;
