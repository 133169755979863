import DelegationDetailsResponse, {
  DelegationDetailsResponseItem,
  DelegationDetailsResponseItemAddressSequenceNodeGroup,
  DelegationDetailsResponseItemAddressSequnence,
  DelegationDetailsResponseItemAddressSequnenceNode,
  DelegationDetailsResponseItemMileageData,
  DelegationDetailsResponseItemState,
  DelegationDetailsResponseItemType,
} from "../../../api/raily/delegation/details/delegation-details.response";
import DelegationDetails, {
  DelegationDetailsAddressNode,
  DelegationDetailsMileageData,
  DelegationDetailsStatus,
  DelegationDetailsType,
} from "./delegation-details";

const createAddress = (
  address: DelegationDetailsResponseItemAddressSequnenceNode
): DelegationDetailsAddressNode => {
  return {
    displayName: address.displayName,
    lat: address.lat,
    lon: address.lon,
    time: address.time,
    apartmentNumber: address.apartmentNumber,
    houseNumber: address.houseNumber,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createAddressGroup = (
  group: DelegationDetailsResponseItemAddressSequenceNodeGroup
): DelegationDetailsAddressNode[] => {
  return group.nodes.map(createAddress);
};

const createAddressSequence = (
  seq: DelegationDetailsResponseItemAddressSequnence
): DelegationDetailsAddressNode[][] => {
  return seq.seq.map(createAddressGroup);
};

const createMileageDataItem = (
  item: DelegationDetailsResponseItemMileageData
): DelegationDetailsMileageData => {
  return {
    humanId: item.humanId,
    mileageDate: item.mileageDate,
    cardNumber: item.cardNumber,
    commissionNumber: item.commissionNumber,
    mileageNumber: item.mileageNumber,
  };
};

const createMileageData = (
  data: DelegationDetailsResponseItemMileageData[]
): DelegationDetailsMileageData[] => {
  return data.map(createMileageDataItem);
};

const createStatus = (
  state: DelegationDetailsResponseItemState
): DelegationDetailsStatus => {
  switch (state) {
    case DelegationDetailsResponseItemState.DELETED:
      return DelegationDetailsStatus.DELETED;
    case DelegationDetailsResponseItemState.DOWNLOADED:
      return DelegationDetailsStatus.DOWNLOADED;
    case DelegationDetailsResponseItemState.NEW:
      return DelegationDetailsStatus.NEW;
  }
};

const createType = (
  type: DelegationDetailsResponseItemType
): DelegationDetailsType => {
  switch (type) {
    case DelegationDetailsResponseItemType.AUTO:
      return DelegationDetailsType.AUTO;
    case DelegationDetailsResponseItemType.MANUAL:
      return DelegationDetailsType.MANUAL;
  }
};

const createDetails = (
  item: DelegationDetailsResponseItem
): DelegationDetails => {
  return {
    addressSequence: createAddressSequence(item.addressSeq),
    dateFrom: item.dateFrom,
    dateTo: item.dateTo,
    delegationNumber: item.delegationNumber,
    humanId: item.humanId,
    mileageData: createMileageData(item.mileageData),
    status: createStatus(item.state),
    totalCost: item.totalCost,
    type: createType(item.type),
    uuid: item.id,
    workerName: item.workerName,
    workerPhone: item.workerPhone,
    workerUuid: item.workerId,
  };
};

const create = (response: DelegationDetailsResponse): DelegationDetails => {
  return createDetails(response.data);
};

const delegationDetailsFactory = {
  create,
};

export default delegationDetailsFactory;
