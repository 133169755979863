import { FC } from "react";
import TableComponent from "../../../../../common/components/table/table.component";
import RelatedBillingsTaxiTaxiTableRow from "./types/related-billings-taxi-taxi-table-row";
import relatedBillingsTaxiTaxiHelper from "./related-billings-taxi-taxi.helper";

type RelatedBillingsTaxiTaxiTableProps = {
  tableRows: RelatedBillingsTaxiTaxiTableRow[];
  isLoading: boolean;
};

const RelatedBillingsTaxiTaxiTableComponent: FC<
  RelatedBillingsTaxiTaxiTableProps
> = (props) => {
  const tableColumns = relatedBillingsTaxiTaxiHelper.createTableColumns();

  return (
    <TableComponent
      isLoading={props.isLoading}
      columns={tableColumns}
      rows={props.tableRows}
    />
  );
};

export default RelatedBillingsTaxiTaxiTableComponent;
