import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import DelegationListingRouteQueryParams from "../../common/routes/types/delegation-listing-route-params";
import DelegationListingFilter from "../common/types/delegation-listing-filter";
import DelegationListingSortKey from "../common/types/delegation-listing-sort-key";
import delegationListingFiltersService from "./delegation-listing-filters.service";

const useDelegationListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<DelegationListingRouteQueryParams>();

  const getFilters = (): DelegationListingFilter[] => {
    return delegationListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return delegationListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return delegationListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): DelegationListingSortKey | undefined => {
    return delegationListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: DelegationListingFilter[],
    sortKey: DelegationListingSortKey,
    page: number,
    pageSize: number
  ) => {
    const routeQueryParams =
      delegationListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    saveFilters,
    getSortKey,
    getPage,
    getPageSize,
  };
};

export default useDelegationListingFiltersDao;
