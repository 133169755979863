import TaxiDriverContractUpdateRequest, {
  TaxiDriverContractUpdateRequestBody,
} from "./taxi-driver-contract-update.request";

const createBody = (
  request: TaxiDriverContractUpdateRequest
): TaxiDriverContractUpdateRequestBody => {
  return {
    disabled: request.disabled,
  };
};

const taxiDriverContractUpdateRequestFactory = {
  createBody,
};

export default taxiDriverContractUpdateRequestFactory;
