import { FC, useEffect, useMemo, useState } from "react";
import useClientSidePagination from "../../../../../../common/hooks/use-clientside-pagination";
import driverContractListingApiService from "../common/api/driver-contract-listing-api.service";
import DriverContractListingResponse, {
  DriverContractListingResponseData,
} from "../common/api/driver-contract-listing.response";
import DriverContractListingByRailyListingItem from "./common/types/driver-contract-listing-by-raily-listing-item";
import HeadingComponent from "../../../../../../common/components/heading/heading.component";
import driverContractListingHelper from "../common/driver-contract-listing.helper";
import CardComponent from "../../../../../../common/components/card/card.component";
import DriverContractListingByRailyTableComponent from "./table/driver-contract-listing-by-raily-table.component";
import PaginationComponent from "../../../../../../common/components/pagination/pagination.component";
import DriverContractListingViewBasicProps from "../common/types/driver-contract-listing-view-basic-props";
import driverContractListingByRailyFactory from "./common/driver-contract-listing-by-raily.factory";
import useIsComponentMounted from "../../../../../../common/hooks/use-is-component-mounted";
import DriverContractListingByRailyFilter from "./common/types/driver-contract-listing-by-raily-filter";
import useRouteQueryParams from "../../../../../../common/hooks/use-route-query-params";
import DriverContractListingByRailyRouteQueryParams from "./common/types/driver-contract-listing-by-raily-route-query-params";
import DriverContractListingByRailySortKey from "./common/types/driver-contract-listing-by-raily-sort-key";
import driverContractListingByRailyRouteQueryParamsService from "./common/driver-contract-listing-by-raily-route-query-params.service";
import driverContractListingByRailyFilterService from "./common/filter/driver-contract-listing-by-raily-filter.service";
import driverContractListingByRailySortService from "./common/sort/driver-contract-listing-by-raily-sort.service";
import driverContractListingByRailySortHelper from "./common/sort/driver-contract-listing-by-raily-sort.helper";
import DriverContractListingByRailySortSelectOption from "./common/types/driver-contract-listing-by-raily-sort-select-option";
import DriverContractListingByRailyFiltersSelectComponent from "./filter/select/driver-contract-listing-by-raily-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import DriverContractListingByRailyFiltersBadgeListComponent from "./filter/list/driver-contract-listing-by-raily-filters-badge-list.component";
import { useAppContext } from "../../../../../../context/app.context";

type DriverContractListingByRailyProps = DriverContractListingViewBasicProps;

const DriverContractListingByRailyComponent: FC<
  DriverContractListingByRailyProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<DriverContractListingByRailyRouteQueryParams>();

  const [filters, setFilters] = useState<DriverContractListingByRailyFilter[]>(
    () =>
      driverContractListingByRailyRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );
  const [selectedSortKey, setSelectedSortKey] =
    useState<DriverContractListingByRailySortKey | null>(
      () =>
        driverContractListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [listingItems, setListingItems] = useState<
    DriverContractListingByRailyListingItem[]
  >([]);
  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [filteredListingItems, setFilteredListingItems] = useState<
    DriverContractListingByRailyListingItem[]
  >([]);

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: filteredListingItems,
      totalResults: filteredListingItems.length,
    });

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const filteredListingItems =
      driverContractListingByRailyFilterService.filterListingItems(
        listingItems,
        filters
      );

    const sortedListingItems =
      driverContractListingByRailySortService.sortListingItems(
        filteredListingItems,
        selectedSortKey
      );

    setFilteredListingItems(sortedListingItems);
  }, [selectedSortKey, JSON.stringify(filters), JSON.stringify(listingItems)]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    setPage(1);
  }, [pageSize, filters]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      driverContractListingByRailyRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const onListingFetchSuccess = (
    responseData: DriverContractListingResponseData
  ) => {
    const listingItems: DriverContractListingByRailyListingItem[] =
      driverContractListingByRailyFactory.createListingItems(responseData);

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (response: DriverContractListingResponse) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    driverContractListingApiService
      .fetchListing(props.driverUuid!)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!props.driverUuid) {
      return;
    }

    fetchListing();
  }, [props.driverUuid]);

  const addNewFilter = (newFilter: DriverContractListingByRailyFilter) => {
    setFilters((curr) => [...curr, newFilter]);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filterItem) => {
      return filters.indexOf(filterItem) !== index;
    });

    setFilters(newFilters);
  };

  const deleteAllFilters = () => {
    setFilters([]);
  };

  const sortSelectOptions: DriverContractListingByRailySortSelectOption[] =
    useMemo(
      () => driverContractListingByRailySortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  return (
    <div className="driver_contract_listing">
      <HeadingComponent
        title={driverContractListingHelper.getHeadingText(props.driverName)}
        actions={[...props.changeViewButtons, props.addContractButton]}
      />
      <div className="driver_contract_listing_tools">
        <DriverContractListingByRailyFiltersSelectComponent
          filters={filters}
          onAddNewFilter={addNewFilter}
        />
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
        />
      </div>
      <DriverContractListingByRailyFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <DriverContractListingByRailyTableComponent
          listingItems={pageData}
          isError={isListingFetchingError}
          isLoading={isListingFetching}
          driverUuid={props.driverUuid}
        />
        <div className="driver_contract_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={setPageSize}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default DriverContractListingByRailyComponent;
