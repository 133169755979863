import { FC, useEffect, useRef } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import DateInputComponent from "../../../../../common/components/form/input/date/date-input.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import FormComponent from "../../../../../common/components/form/form.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import formValidationHelper from "../../../../../common/utils/validation/form-validation.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../common/context/sign-up-driver.context";
import signUpDriverFormHelper from "../common/sign-up-driver-form.helper";
import SignUpDriverUserAddressComponent from "./address/sign-up-driver-user-address.component";
import SignUpDriverFormUserData from "../common/types/sign-up-driver-form-user-data";
import MultiSelectComponent from "../../../../../common/components/form/select/multi-select/multi-select.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";

type SignUpDriverUserProps = {};

const SignUpDriverUserComponent: FC<SignUpDriverUserProps> = () => {
  const isComponentMounted = useIsComponentMounted();

  const userDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().userDataForm;

  const citizenshipSelectOptions =
    signUpDriverFormHelper.getCitizenshipSelectOptions();

  const languageSelectOptions =
    signUpDriverFormHelper.getLanguageSelectOptions();

  const formOfEmploymentSelectOptions =
    signUpDriverFormHelper.getFormOfEmploymentSelectOptions();

  const {
    userData,
    setUserDataFirstName,
    setUserDataLastName,
    setUserDataMobile,
    setUserDataAlternativeMobile,
    setUserDataBirthDate,
    setUserDataBirthPlace,
    setUserDataPersonalIdNumber,
    setUserDataCitizenship,
    setUserDataLanguages,
    setUserDataExperience,
    setUserDataFormOfEmployment,
    userDataValidationResults,
    validateUserDataFirstName,
    validateUserDataLastname,
    validateUserDataMobile,
    validateUserDataAlternativeMobile,
    validateUserDataBirthDate,
    validateUserDataBirthPlace,
    validateUserDataPersonalIdNumber,
    validateUserDataCitizenship,
    validateUserDataLanguages,
    validateUserDataYearsOfExperience,
    validateUserDataFormOfEmployment,
    validateUserDataAddresses,
    submitUserStep,
    isUserDataFirstNameDisabled,
    isUserDataLastNameDisabled,
    isUserDataMobileNumberDisabled,
    isUserDataAlternativeMobileNumberDisabled,
  } = useSignUpDriverContext();

  const firstNameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstNameInputRef.current && !userData.firstName) {
      firstNameInputRef.current.focus();
    }
  }, [firstNameInputRef.current]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }
    validateUserDataAddresses();
  }, [userData.addresses]);

  const isSubmitButtonEnabled = formValidationHelper.checkThatFormHasErrors(
    userDataValidationResults
  );

  const onCitizenshipChange = (
    value: SignUpDriverFormUserData["citizenship"]
  ) => {
    setUserDataCitizenship(value);
    validateUserDataCitizenship(value);
  };

  const onLanguageChange = (value: SignUpDriverFormUserData["languages"]) => {
    setUserDataLanguages(value);
    validateUserDataLanguages(value);
  };

  const onBirthDateChange = (value: SignUpDriverFormUserData["birthDate"]) => {
    setUserDataBirthDate(value);
    validateUserDataBirthDate(value);
  };

  const onFormOfEmploymentChange = (
    value: SignUpDriverFormUserData["formOfEmployment"]
  ) => {
    setUserDataFormOfEmployment(value);
    validateUserDataFormOfEmployment(value);
  };

  return (
    <>
      <h1 className="sign_up_driver__step_title">
        {userDataFormTranslations.title}
      </h1>
      <CardComponent>
        <FormComponent onSubmit={submitUserStep}>
          <Row>
            <Column md={6}>
              <FormFieldComponent
                label={userDataFormTranslations.firstNameLabel}
                isRequired
                classNames={{ root: "mt-0" }}
                errorMessage={userDataValidationResults.firstName.errorMessage}
              >
                <InputComponent
                  inputRef={firstNameInputRef}
                  placeholder={userDataFormTranslations.firstNamePlaceholder}
                  value={userData.firstName}
                  onChange={setUserDataFirstName}
                  onBlur={validateUserDataFirstName}
                  hasError={!!userDataValidationResults.firstName.errorMessage}
                  isDisabled={isUserDataFirstNameDisabled}
                  idForTesting={`user-data-first-name-input`}
                />
              </FormFieldComponent>
            </Column>
            <Column md={6}>
              <FormFieldComponent
                label={userDataFormTranslations.lastNameLabel}
                isRequired
                classNames={{ root: "mt-0" }}
                errorMessage={userDataValidationResults.lastName.errorMessage}
              >
                <InputComponent
                  placeholder={userDataFormTranslations.lastNamePlaceholder}
                  value={userData.lastName}
                  onChange={setUserDataLastName}
                  onBlur={validateUserDataLastname}
                  hasError={!!userDataValidationResults.lastName.errorMessage}
                  isDisabled={isUserDataLastNameDisabled}
                  idForTesting={`user-data-last-name-input`}
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column md={6}>
              <FormFieldComponent
                label={userDataFormTranslations.mobileLabel}
                isRequired
                errorMessage={userDataValidationResults.mobile.errorMessage}
              >
                <PhoneNumberInputComponent
                  placeholder={userDataFormTranslations.mobilePlaceholder}
                  phoneNumber={userData.mobile}
                  onChange={setUserDataMobile}
                  onBlur={validateUserDataMobile}
                  hasError={!!userDataValidationResults.mobile.errorMessage}
                  isDisabled={isUserDataMobileNumberDisabled}
                  idForTesting={`mobile`}
                />
              </FormFieldComponent>
            </Column>
            <Column md={6}>
              <FormFieldComponent
                label={userDataFormTranslations.alternativeMobileLabel}
                errorMessage={
                  userDataValidationResults.alternativeMobile.errorMessage
                }
              >
                <PhoneNumberInputComponent
                  placeholder={
                    userDataFormTranslations.alternativeMobilePlaceholder
                  }
                  phoneNumber={userData.alternativeMobile}
                  onChange={setUserDataAlternativeMobile}
                  onBlur={validateUserDataAlternativeMobile}
                  hasError={
                    !!userDataValidationResults.alternativeMobile.errorMessage
                  }
                  isDisabled={isUserDataAlternativeMobileNumberDisabled}
                  idForTesting={`alternative-mobile`}
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <FormFieldComponent
            label={userDataFormTranslations.birthDateLabel}
            isRequired
            errorMessage={userDataValidationResults.birthDate.errorMessage}
          >
            <DateInputComponent
              placeholder={userDataFormTranslations.birthDatePlaceholder}
              date={userData.birthDate}
              onChange={onBirthDateChange}
              hasError={!!userDataValidationResults.birthDate.errorMessage}
              maxDate={new Date()}
              idForTesting={`user-data-birth-date-picker-input`}
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={userDataFormTranslations.birthPlaceLabel}
            isRequired
            errorMessage={userDataValidationResults.birthPlace.errorMessage}
          >
            <InputComponent
              placeholder={userDataFormTranslations.birthPlacePlaceholder}
              value={userData.birthPlace}
              onChange={setUserDataBirthPlace}
              onBlur={validateUserDataBirthPlace}
              hasError={!!userDataValidationResults.birthPlace.errorMessage}
              idForTesting={`user-data-birth-place-input`}
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={userDataFormTranslations.personalIdNumberLabel}
            isRequired
            errorMessage={
              userDataValidationResults.personalIdNumber.errorMessage
            }
          >
            <InputComponent
              placeholder={userDataFormTranslations.personalIdNumberPlaceholder}
              value={userData.personalIdNumber}
              onChange={setUserDataPersonalIdNumber}
              onBlur={validateUserDataPersonalIdNumber}
              hasError={
                !!userDataValidationResults.personalIdNumber.errorMessage
              }
              idForTesting={`user-data-personal-id-number-input`}
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={userDataFormTranslations.citizenshipLabel}
            isRequired
            errorMessage={userDataValidationResults.citizenship.errorMessage}
          >
            <SingleSelectComponent
              placeholder={userDataFormTranslations.citizenshipPlaceholder}
              value={userData.citizenship}
              options={citizenshipSelectOptions}
              onChange={(value) => onCitizenshipChange(value)}
              hasError={!!userDataValidationResults.citizenship.errorMessage}
              idForTesting={`user-data-citizenship-select`}
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={userDataFormTranslations.languagesLabel}
            isRequired
            errorMessage={userDataValidationResults.languages.errorMessage}
          >
            <MultiSelectComponent
              placeholder={userDataFormTranslations.languagesPlaceholder}
              value={userData.languages}
              options={languageSelectOptions}
              onChange={(selectedOptions) => onLanguageChange(selectedOptions)}
              hasError={!!userDataValidationResults.languages.errorMessage}
              idForTesting={`user-data-languages-select`}
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={userDataFormTranslations.experienceLabel}
            isRequired
            errorMessage={
              userDataValidationResults.yearsOfExperience.errorMessage
            }
          >
            <InputComponent
              placeholder={userDataFormTranslations.experiencePlaceholder}
              value={String(userData.yearsOfExperience)}
              type="number"
              onChange={(yearsOfExperience) => {
                setUserDataExperience(
                  yearsOfExperience !== "" ? Number(yearsOfExperience) : null
                );
              }}
              onBlur={validateUserDataYearsOfExperience}
              hasError={
                !!userDataValidationResults.yearsOfExperience.errorMessage
              }
              idForTesting={`user-data-experience-input`}
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={userDataFormTranslations.formOfEmploymentLabel}
            isRequired
            errorMessage={
              userDataValidationResults.formOfEmployment.errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={userDataFormTranslations.formOfEmploymentPlaceholder}
              value={userData.formOfEmployment}
              options={formOfEmploymentSelectOptions}
              onChange={(formOfEmployment) =>
                onFormOfEmploymentChange(formOfEmployment)
              }
              hasError={
                !!userDataValidationResults.formOfEmployment.errorMessage
              }
              idForTesting={`user-data-form-of-employment-select`}
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={userDataFormTranslations.addressesLabel}
            isRequired
            errorMessage={userDataValidationResults.addresses.errorMessage}
          >
            <SignUpDriverUserAddressComponent />
          </FormFieldComponent>
        </FormComponent>
      </CardComponent>
      <div className="sign_up_driver__next_button_wrapper">
        <ButtonComponent
          onClick={submitUserStep}
          type="primary"
          isDisabled={!isSubmitButtonEnabled}
          idForTesting={`user-data-submit-button`}
        >
          {userDataFormTranslations.submitButtonText}
        </ButtonComponent>
      </div>
    </>
  );
};

export default SignUpDriverUserComponent;
