import UserRole from "../../../../../common/types/user-role";
import userPermissionsService from "../../../../../common/utils/user/permissions/user-permissions.service";
import orderAddUserPermissionDefinition, {
  OrderAddUserPermissions,
} from "./order-add-user-permission";

const getPermissions = (userRoles: UserRole[]): OrderAddUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    orderAddUserPermissionDefinition
  );

  return permissions;
};

const orderAddUserPermissionsHelper = {
  getPermissions,
};

export default orderAddUserPermissionsHelper;
