import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import PassengerAddByRailyFormData from "./types/passenger-add-by-raily-form-data";
import PassengerAddByRailyFormValidationResults from "./types/passenger-add-by-raily-form-validation.results";

const getDefaultFormData = (): PassengerAddByRailyFormData => {
  return {
    firstName: "",
    lastName: "",
    mobile: phoneNumberInputHelper.getEmptyValue(),
    alternativeMobile: phoneNumberInputHelper.getEmptyValue(),
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
    addresses: [],
    cargoCompanies: [],
    dispatches: [],
  };
};

const getFormValidationResults =
  (): PassengerAddByRailyFormValidationResults => {
    return {
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      username: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
      alternativeMobile: formValidationService.defaultValidationResult,
      email: formValidationService.defaultValidationResult,
      password: formValidationService.defaultValidationResult,
      passwordRepeat: formValidationService.defaultValidationResult,
      addresses: formValidationService.defaultValidationResult,
      cargoCompanies: formValidationService.defaultValidationResult,
      dispatches: formValidationService.defaultValidationResult,
    };
  };

const passengerAddByRailyFormHelper = {
  getDefaultFormData,
  getFormValidationResults,
};

export default passengerAddByRailyFormHelper;
