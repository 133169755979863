import DriverListingFilter, {
  DriverListingFirstNameFilter,
  DriverListingActivityStatusFilter,
  DriverListingCandidatureActivityFilter,
  DriverListingFleetPartnerFilter,
  DriverListingLastNameFilter,
  DriverListingStartingAddressFilter,
  DriverListingTaxiCorporationFilter,
} from "../common/types/driver-listing-filter";
import DriverListingFilterType from "../common/types/driver-listing-filter-type";
import DriverListingSortKey from "../common/types/driver-listing-sort-key";
import { UserDriverListingRouteQueryParams } from "../../../common/routes/types/user-driver-listing-route-params";
import UserStatus from "../../../../../common/types/user-status";

const getFilters = (
  routeQueryFilterParams: UserDriverListingRouteQueryParams
): DriverListingFilter[] => {
  const filters: DriverListingFilter[] = [];

  if (routeQueryFilterParams.firstName) {
    const firstNameFilter: DriverListingFirstNameFilter = {
      type: DriverListingFilterType.FIRST_NAME,
      value: routeQueryFilterParams.firstName,
    };

    filters.push(firstNameFilter);
  }

  if (routeQueryFilterParams.lastName) {
    const lastNameFilter: DriverListingLastNameFilter = {
      type: DriverListingFilterType.LAST_NAME,
      value: routeQueryFilterParams.lastName,
    };

    filters.push(lastNameFilter);
  }

  if (routeQueryFilterParams.fleetPartner) {
    const fleetPartnerFilter: DriverListingFleetPartnerFilter = {
      type: DriverListingFilterType.FLEET_PARTNER,
      value: routeQueryFilterParams.fleetPartner,
    };

    filters.push(fleetPartnerFilter);
  }

  if (routeQueryFilterParams.startingAddress) {
    const startingAddressFilter: DriverListingStartingAddressFilter = {
      type: DriverListingFilterType.STARTING_ADDRESS,
      value: routeQueryFilterParams.startingAddress,
    };

    filters.push(startingAddressFilter);
  }

  if (routeQueryFilterParams.taxiCorporation) {
    const startingAddressFilter: DriverListingTaxiCorporationFilter = {
      type: DriverListingFilterType.TAXI_CORPORATION,
      value: routeQueryFilterParams.taxiCorporation,
    };

    filters.push(startingAddressFilter);
  }

  if (routeQueryFilterParams.isCandidatureActive) {
    const candidatureActivityFilter: DriverListingCandidatureActivityFilter = {
      type: DriverListingFilterType.CANDIDATURE_ACTIVITY,
      value:
        routeQueryFilterParams.isCandidatureActive === "true" ? true : false,
    };

    filters.push(candidatureActivityFilter);
  }

  if (routeQueryFilterParams.status) {
    const candidatureActivityFilter: DriverListingActivityStatusFilter = {
      type: DriverListingFilterType.ACTIVITY_STATUS,
      value:
        routeQueryFilterParams.status === "DELETED"
          ? UserStatus.DELETED
          : UserStatus.ACTIVE,
    };

    filters.push(candidatureActivityFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: UserDriverListingRouteQueryParams
): DriverListingSortKey | undefined => {
  return routeQueryParams.sort as DriverListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: UserDriverListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: UserDriverListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: DriverListingFilter[],
  sortKey: DriverListingSortKey | null,
  page: number,
  pageSize: number
): UserDriverListingRouteQueryParams => {
  const candidatureActivityFilterValue = filters.find(
    (filter) => filter.type === DriverListingFilterType.CANDIDATURE_ACTIVITY
  )?.value as DriverListingCandidatureActivityFilter["value"] | undefined;

  const userActivityFilterValue = filters.find(
    (filter) => filter.type === DriverListingFilterType.ACTIVITY_STATUS
  )?.value as DriverListingActivityStatusFilter["value"] | undefined;

  const routeQueryParams: UserDriverListingRouteQueryParams = {
    firstName: filters.find(
      (filter) => filter.type === DriverListingFilterType.FIRST_NAME
    )?.value as DriverListingFirstNameFilter["value"],
    lastName: filters.find(
      (filter) => filter.type === DriverListingFilterType.LAST_NAME
    )?.value as DriverListingLastNameFilter["value"],
    fleetPartner: filters.find(
      (filter) => filter.type === DriverListingFilterType.FLEET_PARTNER
    )?.value as DriverListingFleetPartnerFilter["value"],
    startingAddress: filters.find(
      (filter) => filter.type === DriverListingFilterType.STARTING_ADDRESS
    )?.value as DriverListingStartingAddressFilter["value"],
    taxiCorporation: filters.find(
      (filter) => filter.type === DriverListingFilterType.TAXI_CORPORATION
    )?.value as DriverListingTaxiCorporationFilter["value"],
    isCandidatureActive:
      candidatureActivityFilterValue === undefined
        ? undefined
        : String(candidatureActivityFilterValue),
    status:
      userActivityFilterValue === undefined
        ? undefined
        : String(userActivityFilterValue),
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const driverListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default driverListingFiltersService;
