import SearchRoadRoutingResponse, {
  SearchRoadRoutingResponseRoute,
} from "../../../utils/search-road-route/search-road-routing.response";
import RouteRoadRoute from "./route-road-route";

const createRoute = (route: SearchRoadRoutingResponseRoute): RouteRoadRoute => {
  return {
    coordinates: route.geometry.coordinates,
    legs: route.legs,
  };
};

const create = (response: SearchRoadRoutingResponse): RouteRoadRoute[] => {
  return response.routes.map(createRoute);
};

const routeRoadRouteFactory = {
  create,
};

export default routeRoadRouteFactory;
