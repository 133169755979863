import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import AddressType from "../../../../../common/types/address-type";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import DriverAddressFormTypeSelectOption from "./types/driver-address-form-type-select-option";
import DriverAddressFormData from "./types/driver-address-form-data";
import driverAddressFormValidationService from "./driver-address-form-validation.service";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";

const getDefaultFormData = (): DriverAddressFormData => {
  return {
    foundAddress: null,
    type: null,
    street: "",
    houseNumber: "",
    apartmentNumber: "",
    zipCode: "",
    country: "",
    town: "",
    description: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<DriverAddressFormData> => {
    return {
      foundAddress: () => formValidationService.defaultValidationResult,
      type: (values) =>
        driverAddressFormValidationService.validateAddressType(values.type),
      street: (values) =>
        driverAddressFormValidationService.validateStreet(values.street),
      houseNumber: (values) =>
        driverAddressFormValidationService.validateHouseNumber(
          values.houseNumber
        ),
      apartmentNumber: (values) =>
        driverAddressFormValidationService.validateApartmentNumber(
          values.apartmentNumber
        ),
      zipCode: (values) =>
        driverAddressFormValidationService.validateZipCode(values.zipCode),
      country: (values) =>
        driverAddressFormValidationService.validateCountry(values.country),
      town: (values) =>
        driverAddressFormValidationService.validateTown(values.town),
      description: (values) =>
        driverAddressFormValidationService.validateDescription(
          values.description
        ),
    };
  };

const getAddressTypeSelectOptions = (): DriverAddressFormTypeSelectOption[] => {
  const addressTypeTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().userDataForm
      .addNewAddressForm.addressType;

  return [
    {
      label: addressTypeTranslations.MAILING,
      value: AddressType.MAILING,
    },
    {
      label: addressTypeTranslations.STARTING,
      value: AddressType.STARTING,
    },
  ];
};

const driverAddressFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
  getAddressTypeSelectOptions,
};

export default driverAddressFormHelper;
