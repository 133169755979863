import { FormValidationDefinition } from "../../../../form/use-form";
import numericRangeFormValidationService from "./numeric-range-form-validation.service";
import NumericRangeFormData from "./types/numeric-range-form-data";

const getDefaultFormData = (): NumericRangeFormData => {
  return {
    from: 0,
    to: 1,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<NumericRangeFormData> => {
    return {
      from: (values) =>
        numericRangeFormValidationService.validateFrom(values.from, values.to),
      to: (values) =>
        numericRangeFormValidationService.validateTo(values.to, values.from),
    };
  };

const numericRangeFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default numericRangeFormHelper;
