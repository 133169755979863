import { useEffect } from "react";

export const useAbort = (): { revoke: () => void; signal: AbortSignal } => {
  const abortController = new AbortController();

  const revoke = () => {
    abortController.abort();
  };

  useEffect(() => {
    return () => revoke();
  }, []);

  return {
    signal: abortController.signal,
    revoke,
  };
};

export default useAbort;
