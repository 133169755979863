import BillingModel from "../../../types/billing-model";
import {
  BillingData,
  BillingDataResponseBonusContribution,
  BillingDataResponseContribution,
  BillingDataResponseContributionType,
  BillingDataResponsePenaltyContribution,
} from "../api/billing-data.response";
import BillingFormData, {
  BillingFormDataContributions,
  BillingSummaryData,
} from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import BillingsTaxiTaxiContributionBonus, {
  BillingsTaxiTaxiContributionBonusType,
} from "../../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus";
import BillingsTaxiTaxiContributionPenalty, {
  BillingsTaxiTaxiContributionPenaltyType,
} from "../../common/contributions/penalty/types/billings-taxi-taxi-contributions-penalty";
import BillingsTaxiTaxiContributionsBonusTableRow from "../../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus-table-row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import billingsTaxiTaxiContributionsBonusHelper from "../../common/contributions/bonus/billings-taxi-taxi-contributions-bonus.helper";
import BillingsTaxiTaxiContributionsPenaltyTableRow from "../../common/contributions/penalty/types/billings-taxi-taxi-contributions-penalty-table-row";
import billingsTaxiTaxiContributionsPenaltyHelper from "../../common/contributions/penalty/billings-taxi-taxi-contributions-penalty.helper";
import TabsData from "../../../../../common/components/tabs/common/types/tabs-data";
import BillingsTaxiTaxiContributionsBonusTableComponent from "../../common/contributions/bonus/table/billings-taxi-taxi-contributions-bonus-table.component";
import BillingsTaxiTaxiContributionsPenaltyTableComponent from "../../common/contributions/penalty/table/billings-taxi-taxi-contributions-penalty-table.component";
import BillingContributionsValidationResult from "../types/billing-contributions-validation-result";
import BillingContributionType from "../types/billing-contribution-type";
import billingsTaxiTaxiHelper from "../billings-taxi-taxi.helper";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import FormErrorComponent from "../../../../../common/components/form/error/form-error.component";
import NumericInputComponent from "../../../../../common/components/form/input/numeric-input/numeric-input.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import TableButtonComponent from "../../../../../common/components/table/button/table-button.component";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingPenaltyContributionType = (
  contributionType: BillingDataResponseContributionType
): BillingsTaxiTaxiContributionPenaltyType | undefined => {
  switch (contributionType) {
    case BillingDataResponseContributionType.PENALTY_WRONG_ROUTE:
      return BillingsTaxiTaxiContributionPenaltyType.PENALTY_WRONG_ROUTE;
    case BillingDataResponseContributionType.PENALTY_BEING_LATE:
      return BillingsTaxiTaxiContributionPenaltyType.PENALTY_BEING_LATE;
    case BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR:
      return BillingsTaxiTaxiContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR;
    case BillingDataResponseContributionType.PENALTY_OTHER:
      return BillingsTaxiTaxiContributionPenaltyType.PENALTY_OTHER;
    default:
      return undefined;
  }
};

const createBillingBonusContribution = (
  contribution: BillingDataResponseBonusContribution
): BillingsTaxiTaxiContributionBonus => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE:
      return {
        type: BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE,
        comment: contribution.comment ?? "",
        distance: contribution.distance,
        rate: contribution.rate,
      };
    case BillingDataResponseContributionType.BONUS_OTHER:
      return {
        type: BillingsTaxiTaxiContributionBonusType.BONUS_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount,
        distance: null,
      };
  }
};

const createBillingPenaltyContribution = (
  contribution: BillingDataResponsePenaltyContribution
): BillingsTaxiTaxiContributionPenalty => {
  return {
    type: createBillingPenaltyContributionType(contribution.type) ?? null,
    comment: contribution.comment ?? "",
    amount: contribution.amount,
  };
};

const createBillingContributions = (
  contributions: BillingDataResponseContribution[]
): BillingFormDataContributions => {
  const bonusContributions: BillingsTaxiTaxiContributionBonus[] = contributions
    .filter((contribution) =>
      [
        BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE,
        BillingDataResponseContributionType.BONUS_OTHER,
      ].includes(contribution.type)
    )
    .map((contribution) =>
      createBillingBonusContribution(
        contribution as BillingDataResponseBonusContribution
      )
    );

  const penaltyContributions: BillingsTaxiTaxiContributionPenalty[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.PENALTY_BEING_LATE,
          BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR,
          BillingDataResponseContributionType.PENALTY_OTHER,
          BillingDataResponseContributionType.PENALTY_WRONG_ROUTE,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingPenaltyContribution(
          contribution as BillingDataResponsePenaltyContribution
        )
      );

  return {
    bonus: bonusContributions,
    penalty: penaltyContributions,
  };
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    billingContributions: createBillingContributions(
      data.billing_contributions
    ),
  };

  return billingDraft;
};

const createBillingSummaryData = (data: BillingData) => {
  const billingDraft: BillingSummaryData = {
    orderId: data.order_forwarding_action.transporting_order.cargo_order.id,
    forwardingId: data.order_forwarding_action.id,
    contractDetails: {
      companyName:
        data.order_forwarding_action.contract.producer_taxi.display_name,
      haltingTimeRate: data.order_forwarding_action.contract.halting_time_rate,
      distanceRate: data.order_forwarding_action.contract.distance_rate,
      model: data.order_forwarding_action.contract.model as BillingModel,
      haltingTimeAppliedAfter:
        data.order_forwarding_action.contract.halting_time_after_minutes,
    },
    baseAmountValue: data.base_amount_value,
    amountForChargeHaltings: data.amount_for_charge_haltings,
    amountForChargeHighways: data.amount_for_charge_highways,
    distance: data.distance,
    amountForDistance: data.amount_for_distance,
    internalOrderId:
      data.order_forwarding_action.transporting_order.cargo_order.human_id,
    allContributionsAmount: data.all_contributions_amount,
    planEntryId: data.plan_entry_id,
    sumOfBonuses: data.sum_of_bonuses,
    sumOfPenalties: data.sum_of_penalties,
    date: data.date,
    status: data.status,
    passengers: data.passengers,
    rateForDistance: data.rate_for_distance,
    favorableDistance: data.favorable_distance,
  };

  return billingDraft;
};

const createContributionsBonusTableRow = (
  contribution: BillingsTaxiTaxiContributionBonus,
  contributions: BillingsTaxiTaxiContributionBonus[],
  position: number,
  deleteContribution: (position: number, type: BillingContributionType) => void,
  contributionsValidationResults: BillingContributionsValidationResult,
  onContributionTypeChange: (
    position: number,
    type:
      | BillingsTaxiTaxiContributionBonusType
      | BillingsTaxiTaxiContributionPenaltyType,
    contributionType: BillingContributionType
  ) => void,
  onContributionDistanceChange: (
    position: number,
    distance: number | null
  ) => void,
  onContributionRateChange: (position: number, rate: number | null) => void,
  onContributionAmountChange: (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentChange: (
    position: number,
    comment: string,
    contributionType: BillingContributionType
  ) => void,
  onContributionTypeBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionDistanceBlur: (position: number) => void,
  onContributionRateBlur: (position: number) => void,
  onContributionAmountBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void
): BillingsTaxiTaxiContributionsBonusTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiContributionsTranslations();

  const typeSelectOptions =
    billingsTaxiTaxiHelper.getContributionsBonusTypeSelectOptions();

  const typeSelectedOption =
    typeSelectOptions.find((option) => option.value === contribution.type) ??
    null;

  const validationResult = contributionsValidationResults.bonus.find(
    (validation) => validation.position === position
  );

  switch (contribution.type) {
    case BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return {
        id: `${contribution.type}-${position}`,
        value: {
          type: (
            <div className="billings_contributions_row">
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                options={typeSelectOptions}
                placeholder={translations.bonus.form.typeSelectPlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-bonus-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                autoFocus
                hasError={!!validationResult?.type.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          distance: (
            <div
              className="billings_contributions_row"
              title={String(contribution.distance)}
            >
              <NumericInputComponent
                value={contribution.distance}
                onChange={(value) =>
                  onContributionDistanceChange(position, value)
                }
                onBlur={() => onContributionDistanceBlur(position)}
                placeholder={translations.bonus.form.distancePlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-bonus-distance-${position}`}
                isIntegerOnly
                hasError={!!validationResult?.distance.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.distance.errorMessage}
              />
            </div>
          ),
          rate: (
            <div
              className="billings_contributions_row"
              title={String(contribution.rate)}
            >
              <NumericInputComponent
                value={contribution.rate}
                onChange={(value) => onContributionRateChange(position, value)}
                onBlur={() => onContributionRateBlur(position)}
                placeholder={translations.bonus.form.ratePlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-bonus-rate-${position}`}
                decimalPrecision={2}
                hasError={!!validationResult?.rate.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.rate.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="billings_contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          comment: (
            <div
              className="billings_contributions_row"
              title={contribution.comment}
            >
              <InputComponent
                value={contribution.comment}
                onChange={(value) =>
                  onContributionCommentChange(
                    position,
                    value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionCommentBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                placeholder={translations.bonus.form.commentPlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-bonus-comment-${position}`}
                hasError={!!validationResult?.comment.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.comment.errorMessage}
              />
            </div>
          ),
          actions: (
            <div className="billings_contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  deleteContribution(position, BillingContributionType.BONUS);
                }}
                type="danger"
                title={
                  translations.bonus.table.actions.deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    case BillingsTaxiTaxiContributionBonusType.BONUS_OTHER:
      return {
        id: `${contribution.type}-${position}`,
        value: {
          type: (
            <div className="billings_contributions_row">
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                options={typeSelectOptions}
                placeholder={translations.bonus.form.typeSelectPlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-bonus-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                autoFocus
                hasError={!!validationResult?.type.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          distance: (
            <div
              className="billings_contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          rate: (
            <div
              className="billings_contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          amount: (
            <div
              className="billings_contributions_row"
              title={String(contribution.amount)}
            >
              <NumericInputComponent
                value={contribution.amount}
                onChange={(value) =>
                  onContributionAmountChange(
                    position,
                    value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionAmountBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                placeholder={translations.bonus.form.amountPlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-bonus-amount-${position}`}
                decimalPrecision={2}
                hasError={!!validationResult?.amount.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.amount.errorMessage}
              />
            </div>
          ),
          comment: (
            <div
              className="billings_contributions_row"
              title={contribution.comment}
            >
              <InputComponent
                value={contribution.comment}
                onChange={(value) =>
                  onContributionCommentChange(
                    position,
                    value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionCommentBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                placeholder={translations.bonus.form.commentPlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-bonus-comment-${position}`}
                hasError={!!validationResult?.comment.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.comment.errorMessage}
              />
            </div>
          ),
          actions: (
            <div className="billings_contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  deleteContribution(position, BillingContributionType.BONUS);
                }}
                type="danger"
                title={
                  translations.bonus.table.actions.deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    default:
      return {
        id: String(position),
        value: {
          type: (
            <div>
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                options={typeSelectOptions}
                placeholder={translations.bonus.form.typeSelectPlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-bonus-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                autoFocus
                hasError={!!validationResult?.type.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          distance: <div></div>,
          rate: <div></div>,
          amount: <div></div>,
          comment: <div></div>,
          actions: <div className="d-flex"></div>,
        },
      };
  }
};

const createContributionsPenaltyTableRow = (
  contribution: BillingsTaxiTaxiContributionPenalty,
  contributions: BillingsTaxiTaxiContributionPenalty[],
  position: number,
  deleteContribution: (position: number, type: BillingContributionType) => void,
  contributionsValidationResults: BillingContributionsValidationResult,
  onContributionTypeChange: (
    position: number,
    type:
      | BillingsTaxiTaxiContributionBonusType
      | BillingsTaxiTaxiContributionPenaltyType,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountChange: (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentChange: (
    position: number,
    comment: string,
    contributionType: BillingContributionType
  ) => void,
  onContributionTypeBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void
): BillingsTaxiTaxiContributionsPenaltyTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiContributionsTranslations();

  const typeSelectOptions =
    billingsTaxiTaxiHelper.getContributionsPenaltyTypeSelectOptions();

  const typeSelectedOption =
    typeSelectOptions.find((option) => option.value === contribution.type) ??
    null;

  const validationResult = contributionsValidationResults.penalty.find(
    (validation) => validation.position === position
  );

  return {
    id: `${contribution.type}-${position}`,
    value: {
      type: (
        <div className="billings_contributions_row">
          <SingleSelectComponent
            value={typeSelectedOption}
            onChange={(value) =>
              onContributionTypeChange(
                position,
                value?.value,
                BillingContributionType.PENALTY
              )
            }
            onBlur={() =>
              onContributionTypeBlur(position, BillingContributionType.PENALTY)
            }
            options={typeSelectOptions}
            placeholder={translations.penalty.form.typeSelectPlaceholder}
            idForTesting={`billings-taxi-taxi-edit-contribution-penalty-type-select-${position}`}
            filterFunction={(option) =>
              !contributions.find(
                (contribution) => option.value == contribution.type
              )
            }
            autoFocus
            hasError={!!validationResult?.type.errorMessage}
          />
          <FormErrorComponent message={validationResult?.type.errorMessage} />
        </div>
      ),
      amount: (
        <div
          className="billings_contributions_row"
          title={String(contribution.amount)}
        >
          <NumericInputComponent
            value={contribution.amount}
            onChange={(value) =>
              onContributionAmountChange(
                position,
                value,
                BillingContributionType.PENALTY
              )
            }
            onBlur={() =>
              onContributionAmountBlur(
                position,
                BillingContributionType.PENALTY
              )
            }
            placeholder={translations.penalty.form.amountPlaceholder}
            idForTesting={`billings-taxi-taxi-edit-contribution-penalty-amount-${position}`}
            decimalPrecision={2}
            hasError={!!validationResult?.amount.errorMessage}
          />
          <FormErrorComponent message={validationResult?.amount.errorMessage} />
        </div>
      ),
      comment: (
        <div
          className="billings_contributions_row"
          title={contribution.comment}
        >
          <InputComponent
            value={contribution.comment}
            onChange={(value) =>
              onContributionCommentChange(
                position,
                value,
                BillingContributionType.PENALTY
              )
            }
            onBlur={() =>
              onContributionCommentBlur(
                position,
                BillingContributionType.PENALTY
              )
            }
            placeholder={translations.penalty.form.commentPlaceholder}
            idForTesting={`billings-taxi-taxi-edit-contribution-penalty-comment-${position}`}
            hasError={!!validationResult?.comment.errorMessage}
          />
          <FormErrorComponent
            message={validationResult?.comment.errorMessage}
          />
        </div>
      ),
      actions: (
        <div className="billings_contributions_delete_button">
          <TableButtonComponent
            icon={faTrash}
            onClick={() => {
              deleteContribution(position, BillingContributionType.PENALTY);
            }}
            type="danger"
            title={
              translations.penalty.table.actions.deleteContributionButtonTitle
            }
          />
        </div>
      ),
    },
  };
};

const createTabsData = (
  contributions: BillingFormDataContributions,
  isDataLoading: boolean,
  deleteContribution: (position: number, type: BillingContributionType) => void,
  contributionsValidationResults: BillingContributionsValidationResult,
  onContributionTypeChange: (
    position: number,
    type:
      | BillingsTaxiTaxiContributionBonusType
      | BillingsTaxiTaxiContributionPenaltyType,
    contributionType: BillingContributionType
  ) => void,
  onContributionDistanceChange: (
    position: number,
    distance: number | null
  ) => void,
  onContributionRateChange: (position: number, rate: number | null) => void,
  onContributionAmountChange: (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentChange: (
    position: number,
    comment: string,
    contributionType: BillingContributionType
  ) => void,
  onContributionTypeBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionDistanceBlur: (position: number) => void,
  onContributionRateBlur: (position: number) => void,
  onContributionAmountBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void
): TabsData => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiContributionsTranslations();

  const bonusTableRows: BillingsTaxiTaxiContributionsBonusTableRow[] =
    contributions.bonus.map((contribution, index) =>
      createContributionsBonusTableRow(
        contribution,
        contributions.bonus,
        index,
        deleteContribution,
        contributionsValidationResults,
        onContributionTypeChange,
        onContributionDistanceChange,
        onContributionRateChange,
        onContributionAmountChange,
        onContributionCommentChange,
        onContributionTypeBlur,
        onContributionDistanceBlur,
        onContributionRateBlur,
        onContributionAmountBlur,
        onContributionCommentBlur
      )
    );

  const penaltyTableRows: BillingsTaxiTaxiContributionsPenaltyTableRow[] =
    contributions.penalty.map((contribution, index) =>
      createContributionsPenaltyTableRow(
        contribution,
        contributions.penalty,
        index,
        deleteContribution,
        contributionsValidationResults,
        onContributionTypeChange,
        onContributionAmountChange,
        onContributionCommentChange,
        onContributionTypeBlur,
        onContributionAmountBlur,
        onContributionCommentBlur
      )
    );

  const BonusTableContent = (
    <BillingsTaxiTaxiContributionsBonusTableComponent
      isLoading={isDataLoading}
      rows={bonusTableRows}
    />
  );

  const PenaltyTableContent = (
    <BillingsTaxiTaxiContributionsPenaltyTableComponent
      isLoading={isDataLoading}
      rows={penaltyTableRows}
    />
  );

  const tabsData: TabsData = [
    {
      label: translations.bonus.title,
      content: BonusTableContent,
      counter: contributions.bonus.filter((contribution) => contribution.type)
        .length,
      totalTableRows: bonusTableRows.length,
    },
    {
      label: translations.penalty.title,
      content: PenaltyTableContent,
      counter: contributions.penalty.filter((contribution) => contribution.type)
        .length,
      totalTableRows: penaltyTableRows.length,
    },
  ];

  return tabsData;
};

const billingDataFactory = {
  createBillingData,
  createBillingSummaryData,
  createTabsData,
};

export default billingDataFactory;
