import HttpResponse from "../../../../utils/http/http.response";

type UserDeleteSuccessResponse = HttpResponse<string>;

export enum UserDeleteNotAcceptableResponseErrorType {
  DRIVER_HAS_CANDIDATURE = "DRIVER_HAS_CANDIDATURE",
  DRIVER_HAS_ACTIVE_PLAN = "DRIVER_HAS_ACTIVE_PLAN",
}

export type UserDeleteNotAcceptableResponseDriverHasCandidatureError = {
  errorType: UserDeleteNotAcceptableResponseErrorType.DRIVER_HAS_CANDIDATURE;
};

export type UserDeleteNotAcceptableResponseDriverHasActivePlanError = {
  errorType: UserDeleteNotAcceptableResponseErrorType.DRIVER_HAS_ACTIVE_PLAN;
};

export type UserDeleteNotAcceptableResponseError =
  | UserDeleteNotAcceptableResponseDriverHasCandidatureError
  | UserDeleteNotAcceptableResponseDriverHasActivePlanError;

export type UserDeleteNotAcceptableResponse =
  HttpResponse<UserDeleteNotAcceptableResponseError>;

type UserDeleteResponse =
  | UserDeleteSuccessResponse
  | UserDeleteNotAcceptableResponse;

export default UserDeleteResponse;
