import { FC, useEffect, useRef, useState } from "react";
import ButtonComponent from "../../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import NumericInputComponent from "../../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import ModalComponent, {
  ModalProps,
} from "../../../../../../../common/components/modal/modal.component";
import driverDetailsPlanAddCheckoutApiService from "./common/api/driver-details-plan-add-checkout-api.service";
import DriverDetailsPlanAddCheckoutRequest from "./common/api/driver-details-plan-add-checkout.request";
import DriverDetailsPlanAddCheckoutResponse from "./common/api/driver-details-plan-add-checkout.response";
import driverDetailsPlanAddCheckoutFormValidationService from "./common/driver-details-plan-add-checkout-form-validation.service";
import driverDetailsPlanAddCheckoutFormHelper from "./common/driver-details-plan-add-checkout-form.helper";
import DriverDetailsPlanAddCheckoutFormData from "./common/types/driver-details-plan-add-checkout-form-data";
import DriverDetailsPlanAddCheckoutFormValidationResults from "./common/types/driver-details-plan-add-checkout-form-validation-results";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import notificationService from "../../../../../../../common/utils/notification/notification.service";
import DateTimeInputComponent from "../../../../../../../common/components/form/input/date-time/date-time-input.component";
import DriverDetailsPlanAddCheckoutErrorResponse, {
  DriverDetailsPlanAddCheckoutDriverNotAcceptableResponseError,
} from "./common/api/driver-details-plan-add-checkout-error.response";

type DriverDetailsPlanAddCheckoutProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  onAddCheckoutSuccess: () => void;
  driverUuid: string;
  planEntryUuid: string;
  waypointUuid: string;
  previousWaypointCheckoutDate: Date | null;
  currentWaypointEstimatedDate: Date | null;
};

const DriverDetailsPlanAddCheckoutComponent: FC<
  DriverDetailsPlanAddCheckoutProps
> = (props) => {
  const renderRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const translations =
    userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations()
      .addCheckout;

  const [formData, setFormData] =
    useState<DriverDetailsPlanAddCheckoutFormData>(() =>
      driverDetailsPlanAddCheckoutFormHelper.getDefaultFormData()
    );
  const [formValidationResults, setFormValidationResults] =
    useState<DriverDetailsPlanAddCheckoutFormValidationResults>(() =>
      driverDetailsPlanAddCheckoutFormHelper.getDefaultFormValidationResults()
    );

  const [isCheckoutAddFetching, setIsCheckoutAddFetching] = useState(false);

  const setDate = (date: DriverDetailsPlanAddCheckoutFormData["date"]) => {
    setFormData((curr) => ({
      ...curr,
      date,
    }));
  };

  const onDateChange = (date: DriverDetailsPlanAddCheckoutFormData["date"]) => {
    setDate(date);
    validateDate(date);
  };

  const setHaltingTime = (
    haltingTimeMinutes: DriverDetailsPlanAddCheckoutFormData["haltingTimeMinutes"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      haltingTimeMinutes,
    }));
  };

  const setHighwayCost = (
    highwayCost: DriverDetailsPlanAddCheckoutFormData["highwayCost"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      highwayCost,
    }));
  };

  const validateDate = (
    date: DriverDetailsPlanAddCheckoutFormData["date"] = formData.date
  ) => {
    const validationResult =
      driverDetailsPlanAddCheckoutFormValidationService.validateDate(
        date,
        props.previousWaypointCheckoutDate
      );

    setFormValidationResults((curr) => ({
      ...curr,
      date: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateHaltingTime = () => {
    const validationResult =
      driverDetailsPlanAddCheckoutFormValidationService.validateHaltingTime(
        formData.haltingTimeMinutes
      );

    setFormValidationResults((curr) => ({
      ...curr,
      haltingTime: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateHighwayCost = () => {
    const validationResult =
      driverDetailsPlanAddCheckoutFormValidationService.validateHighwayCost(
        formData.highwayCost
      );

    setFormValidationResults((curr) => ({
      ...curr,
      highwayCost: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateForm = (): boolean => {
    const isDateValid = validateDate();
    const isHaltingTimeValid = validateHaltingTime();
    const isHighwayCostValid = validateHighwayCost();

    return isDateValid && isHaltingTimeValid && isHighwayCostValid;
  };

  const onAddCheckoutSuccess = () => {
    notificationService.success(translations.successNotificationText);
    props.onAddCheckoutSuccess();
  };

  const onAddCheckoutFailure = (
    error: DriverDetailsPlanAddCheckoutDriverNotAcceptableResponseError
  ) => {
    const errorMessage =
      driverDetailsPlanAddCheckoutApiService.resolveApiErrorLabel(error);
    notificationService.error(errorMessage);
  };

  const handleAddCheckoutResponse = (
    response: DriverDetailsPlanAddCheckoutResponse
  ) => {
    if (response.status === 201) {
      onAddCheckoutSuccess();
      return;
    }

    const errorResponse = response as DriverDetailsPlanAddCheckoutErrorResponse;
    onAddCheckoutFailure(
      errorResponse.data as DriverDetailsPlanAddCheckoutDriverNotAcceptableResponseError
    );
  };

  const addCheckout = () => {
    setIsCheckoutAddFetching(true);

    const request: DriverDetailsPlanAddCheckoutRequest = {
      achieved_at: formData.date!.toJSON(),
      halting_time: formData.haltingTimeMinutes
        ? formData.haltingTimeMinutes * 60
        : null,
      highway_charge: formData.highwayCost,
      node_id: props.waypointUuid,
    };

    driverDetailsPlanAddCheckoutApiService
      .addCheckout(props.driverUuid, props.planEntryUuid, request)
      .then(handleAddCheckoutResponse)
      .catch(onAddCheckoutFailure)
      .finally(() => setIsCheckoutAddFetching(false));
  };

  const onSubmitButtonClick = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    addCheckout();
  };

  const clearFormData = () => {
    setFormData(driverDetailsPlanAddCheckoutFormHelper.getDefaultFormData());
  };

  const clearFormValidationResults = () => {
    setFormValidationResults(
      driverDetailsPlanAddCheckoutFormHelper.getDefaultFormValidationResults()
    );
  };

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    clearFormData();
    clearFormValidationResults();

    setDate(props.currentWaypointEstimatedDate);
  }, [props.isOpen]);

  return (
    <ModalComponent
      renderRef={renderRef}
      containerRef={containerRef}
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      classNames={{
        root: "driver_details_plan_add_checkout",
        content: "driver_details_plan_add_checkout_content",
      }}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          isLoading={isCheckoutAddFetching}
          title={translations.form.submitButtonTitle}
        >
          {translations.form.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={props.onClose}
          type="brand"
          title={translations.form.rejectButtonTitle}
        >
          {translations.form.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <FormFieldComponent
        label={translations.form.dateLabel}
        isRequired
        errorMessage={formValidationResults.date.errorMessage}
        classNames={{ root: "mt-0" }}
      >
        <DateTimeInputComponent
          date={formData.date}
          onChange={onDateChange}
          minDate={props.previousWaypointCheckoutDate ?? undefined}
          maxDate={new Date()}
          placeholder={translations.form.datePlaceholder}
          hasError={!!formValidationResults.date.errorMessage}
          parentRef={renderRef}
          parentContainerRef={containerRef}
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.form.haltingTimeLabel}
        errorMessage={formValidationResults.haltingTime.errorMessage}
      >
        <NumericInputComponent
          placeholder={translations.form.haltingTimePlaceholder}
          value={formData.haltingTimeMinutes}
          onChange={setHaltingTime}
          onBlur={validateHaltingTime}
          hasError={!!formValidationResults.haltingTime.errorMessage}
          isIntegerOnly
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.form.highwayCostLabel}
        errorMessage={formValidationResults.highwayCost.errorMessage}
      >
        <NumericInputComponent
          placeholder={translations.form.highwayCostPlaceholder}
          value={formData.highwayCost}
          onChange={setHighwayCost}
          onBlur={validateHighwayCost}
          hasError={!!formValidationResults.highwayCost.errorMessage}
        />
      </FormFieldComponent>
    </ModalComponent>
  );
};

export default DriverDetailsPlanAddCheckoutComponent;
