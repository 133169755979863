import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import appConfig from "../app.config";
import Breadcrumb from "../common/components/breadcrumbs/common/types/breadcrumb";
import User from "../common/types/user";
import appLanguageDao from "../languages/app-language.dao";
import AppLanguage from "../languages/types/app-language";
import appAsideDao from "../modules/app-aside/app-aside.dao";
import authService from "../modules/auth/common/auth.service";
import userFactory from "../common/utils/user/user.factory";

type AppContextProps = {
  isUserLoggedIn: boolean;
  setIsUserLoggedIn: (isUserLoggedIn: boolean) => void;
  user: User | null;
  setUser: (user: User | null) => void;
  isTokenRenewPending: boolean;
  setIsTokenRenewPending: (isTokenRenewPending: boolean) => void;
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  areBreadcrumbsLoading: boolean;
  setAreBreadcrumbsLoading: (areBreadcrumbsLoading: boolean) => void;
  isAsidePanelPinned: boolean;
  pinAsidePanel: () => void;
  unpinAsidePanel: () => void;
  selectedAppLanguage: AppLanguage;
  setAppLanguage: (selectedAppLanguage: AppLanguage) => void;
};

const AppContext = createContext<AppContextProps | null>(null);

export const useAppContext = () => {
  const ctx = useContext(AppContext);

  if (ctx === undefined || ctx === null) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return ctx;
};

const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(() =>
    authService.checkIsAccessTokenValid()
  );
  const [user, setUser] = useState<User | null>(() => {
    const accessTokenDecodedContent =
      authService.getAccessTokenDecodedContent();

    if (!accessTokenDecodedContent) {
      return null;
    }

    const userData = userFactory.createUser(accessTokenDecodedContent.profile);

    return userData;
  });
  const [isTokenRenewPending, setIsTokenRenewPending] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [areBreadcrumbsLoading, setAreBreadcrumbsLoading] = useState(false);
  const [isAsidePanelPinned, setIsAsidePanelPinned] = useState(
    appAsideDao.getPinValue() ?? false
  );
  const [selectedAppLanguage, setSelectedAppLanguage] = useState<AppLanguage>(
    appLanguageDao.getLanguage() ?? appConfig.defaultAppLanguage
  );

  const pinAsidePanel = () => {
    setIsAsidePanelPinned(true);
    appAsideDao.setPinValue(true);
  };

  const unpinAsidePanel = () => {
    setIsAsidePanelPinned(false);
    appAsideDao.setPinValue(false);
  };

  const setAppLanguage = (appLanguage: AppLanguage) => {
    appLanguageDao.setLanguage(appLanguage);
    setSelectedAppLanguage(appLanguage);
  };

  useEffect(() => {
    document.documentElement.lang = selectedAppLanguage.toLowerCase();
  }, [selectedAppLanguage]);

  return (
    <AppContext.Provider
      value={{
        isUserLoggedIn,
        setIsUserLoggedIn,
        user,
        setUser,
        isTokenRenewPending,
        setIsTokenRenewPending,
        breadcrumbs,
        setBreadcrumbs,
        areBreadcrumbsLoading,
        setAreBreadcrumbsLoading,
        isAsidePanelPinned,
        pinAsidePanel,
        unpinAsidePanel,
        selectedAppLanguage,
        setAppLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
