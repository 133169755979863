import MileageContractAddRequest, {
  MileageContractAddRequestBody,
} from "./mileage-contract-add.request";

const createBody = (
  request: MileageContractAddRequest
): MileageContractAddRequestBody => {
  return {
    companyId: request.companyUuid,
    dietFullAfterHours: request.dietFullAfterHours,
    dietFullRate: request.dietFullRate,
    dietHalfAfterHours: request.dietHalfAfterHours,
    dietHalfRate: request.dietHalfRate,
    disabled: request.disabled,
    distanceRateCarGt900cm3: request.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: request.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: request.distanceRateCompanyVehicle,
    distanceRateMoped: request.distanceRateMoped,
    distanceRateMotorcycle: request.distanceRateMotorcycle,
    validSince: request.validSince,
    validTo: request.validTo,
  };
};

const mileageContractsAddRequestFactory = {
  createBody,
};

export default mileageContractsAddRequestFactory;
