import ListingFilter from "../../../../types/listing-filter";

export enum ListingFilterDefinitionOptionSelectDataType {
  DATE_RANGE = "DATE_RANGE",
  NUMERIC_RANGE = "NUMERIC_RANGE",
  DATE = "DATE",
  QUERY = "QUERY",
  NUMERIC_QUERY = "NUMERIC_QUERY",
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}

export type ListingFilterDefinitionOptionDefaultSelectData = {
  options: {
    label: string;
    value: unknown;
  }[];
  type: ListingFilterDefinitionOptionSelectDataType.DEFAULT;
};

export type ListingFilterDefinitionOptionQuerySelectData = {
  options: {
    label: string;
    value: string;
  }[];
  type: ListingFilterDefinitionOptionSelectDataType.QUERY;
};

export type ListingFilterDefinitionOptionNumericQuerySelectData = {
  options: {
    label: string;
    value: number;
  }[];
  type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY;
};

export type ListingFilterDefinitionOptionDateSelectData = {
  option: {
    label: string;
  };
  type: ListingFilterDefinitionOptionSelectDataType.DATE;
};

export type ListingFilterDefinitionOptionDateRangeSelectData = {
  option: {
    label: string;
  };
  type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE;
};

export type ListingFilterDefinitionOptionCustomSelectData = {
  option: {
    label: string;
    onClick: () => void;
  };
  type: ListingFilterDefinitionOptionSelectDataType.CUSTOM;
};

export type ListingFilterDefinitionOptionNumericRangeSelectData = {
  option: {
    label: string;
  };
  type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_RANGE;
};

export type ListingFilterDefinitionOptionSelectData =
  | ListingFilterDefinitionOptionDefaultSelectData
  | ListingFilterDefinitionOptionQuerySelectData
  | ListingFilterDefinitionOptionNumericQuerySelectData
  | ListingFilterDefinitionOptionDateSelectData
  | ListingFilterDefinitionOptionDateRangeSelectData
  | ListingFilterDefinitionOptionCustomSelectData
  | ListingFilterDefinitionOptionNumericRangeSelectData;

export type ListingFilterDefinitionOptionBadgeData = {
  text: string;
  title: string;
};

export type ListingFilterDefinitionOption<T extends ListingFilter> = {
  filterType: T["type"];
  getSelectData: (query: string) => ListingFilterDefinitionOptionSelectData;
  getBadgeData: (value: T["value"]) => ListingFilterDefinitionOptionBadgeData;
};

type ListingFilterDefinition<T extends ListingFilter> = {
  options: ListingFilterDefinitionOption<T>[];
};

export default ListingFilterDefinition;
