import { CargoCompanyAddressListItem } from "../../../../../common/services/cargo-company/address/list/cargo-company-address-list";
import MileageAddressListItem from "../../../../../common/services/mileage-address/list/mileage-address-list";
import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import { MileageAddRouteItem } from "../components/address/mileage-add-routes.types";
import MileageAddAddress from "../types/mileage-add-address";
import MileageAddAddressSelectOption from "../types/mileage-add-address-select-option";
import MileageAddWorker from "../types/mileage-add-worker";

const createFromRouteItems = (
  routeItems: MileageAddRouteItem[]
): MileageAddAddress[] => {
  const seq: MileageAddAddress[] = [];

  routeItems.forEach((routeItem) => {
    if (routeItem.address) {
      seq.push(routeItem.address);
    }
  });

  return seq;
};

const create = (item: MileageAddressListItem): MileageAddAddress => {
  return {
    displayName: item.displayName,
    latitude: item.lat,
    longitude: item.lon,
    apartmentNumber: item.apartment,
    houseNumber: item.houseNo,
    street: item.street,
    town: item.town,
    zipCode: item.zipCode,
  };
};

const createFromCargoAddress = (
  item: CargoCompanyAddressListItem
): MileageAddAddress => {
  return {
    displayName: item.displayName,
    latitude: item.lat,
    longitude: item.lon,
    apartmentNumber: item.apartment,
    houseNumber: item.houseNumber,
    street: item.street,
    town: item.town,
    zipCode: item.zipCode,
  };
};

const createSelectOption = (
  address: MileageAddAddress
): MileageAddAddressSelectOption => {
  return {
    label: address.displayName,
    subText: address.subText,
    value: address,
  };
};

const createSelectOptionFromListingItem = (
  item: MileageAddressListItem
): MileageAddAddressSelectOption => {
  const address: MileageAddAddress = create(item);
  return createSelectOption(address);
};

const createPassengerAddresses = (
  worker: MileageAddWorker | null
): MileageAddAddress[] => {
  if (!worker) {
    return [];
  }

  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().workers;

  const passengerSubText = `${translations.passengerAddressLabel}: ${worker.displayName}`;

  const addresses = [...worker.addresses];

  addresses.forEach((address) => {
    address.subText = passengerSubText;
  });

  return addresses;
};

const mileageAddAddressFactory = {
  createSelectOptionFromListingItem,
  createSelectOption,
  createFromRouteItems,
  createFromCargoAddress,
  createPassengerAddresses,
};

export default mileageAddAddressFactory;
