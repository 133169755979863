import MapRoute from "../../../../../common/components/map/types/map-route";
import {
  SearchRoadRoutingResponseRoute,
  SearchRoadRoutingResponseRouteGeometryCoordinate,
} from "../../../../../common/utils/search-road-route/search-road-routing.response";

const createMapRouteWaypoints = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute["waypoints"] => {
  return routesResponseGeometryCoordinates.map((coordinate) => {
    return {
      latitude: coordinate[1],
      longitude: coordinate[0],
    };
  });
};

const createMapRoute = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute => {
  const waypoints = createMapRouteWaypoints(routesResponseGeometryCoordinates);

  return {
    waypoints,
  };
};

const createMapRoutes = (
  responseRoutes: SearchRoadRoutingResponseRoute[]
): MapRoute[] => {
  return responseRoutes.map((responseRoute) =>
    createMapRoute(responseRoute.geometry.coordinates)
  );
};

const orderJoinOrderDetailsMapRouteFactory = {
  createMapRoutes,
  createMapRoute,
};

export default orderJoinOrderDetailsMapRouteFactory;
