import { FC, useMemo, useState } from "react";
import DelegationAddVehicleType from "../../types/delegation-add-vehicle-type";
import delegationTranslationsHelper from "../../../../../../languages/delegation-translations.helper";
import { useAppContext } from "../../../../../../context/app.context";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import DelegationAddVehicleTypeSelectOption from "../../types/delegation-add-vehicle-type-select-option";
import delegationAddVehicleTypeSelectOptionFactory from "../../factory/delegation-add-vehicle-type.factory";

type DelegationAddVehicleTypeSelectProps = {
  vehicleType: DelegationAddVehicleType;
  onVehicleTypeChange: (type: DelegationAddVehicleType) => void;
  onBlur?: () => void;
};

const DelegationAddVehicleTypeSelectComponent: FC<
  DelegationAddVehicleTypeSelectProps
> = (props) => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().vehicleType;

  const { selectedAppLanguage } = useAppContext();

  const [searchQuery, setSearchQuery] = useState("");

  const vehicleTypeSelectOptions = useMemo(
    delegationAddVehicleTypeSelectOptionFactory.createSelectOptions,
    [selectedAppLanguage]
  );

  const vehicleTypeSelectOption =
    delegationAddVehicleTypeSelectOptionFactory.createSelectOption(
      props.vehicleType
    );

  return (
    <SingleSelectComponent
      placeholder={translations.selectPlaceholder}
      value={vehicleTypeSelectOption}
      options={vehicleTypeSelectOptions}
      onChange={(value: DelegationAddVehicleTypeSelectOption | null) => {
        if (!value) {
          return;
        }

        props.onVehicleTypeChange(value.value);
      }}
      idForTesting="delegation-add-vehicle-type-select-component"
      isSearchable
      inputValue={searchQuery}
      onInputChange={setSearchQuery}
      onBlur={props.onBlur}
    />
  );
};

export default DelegationAddVehicleTypeSelectComponent;
