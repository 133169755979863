import { FC } from "react";
import MileageDetailsToolsProps from "./types/mileage-details-tools-props";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageDetailsUpdateComponent from "./elements/mileage-details-update-mileage.component";
import MileageDetailsAcceptComponent from "./elements/mileage-details-accept-mileage.component";
import MileageDetailsProps from "./types/mileage-details-props";

const MileageDetailsToolsComponent: FC<MileageDetailsToolsProps> = (props) => {
  if (!props.mileageDetails) return null;

  const translations =
    mileageTranslationsHelper.getMileageDetailsTranslations().tools;

  const { hasAccessToEditMileage, hasAccessToAcceptMileage } =
    props.userPermissions;

  const toolsProps: MileageDetailsProps = {
    details: props.mileageDetails,
    onSuccess: props.onSuccess,
    translations: translations,
  };

  return (
    <div className="mileage_details_tools">
      {hasAccessToEditMileage && (
        <MileageDetailsUpdateComponent {...toolsProps} />
      )}
      {hasAccessToAcceptMileage && (
        <MileageDetailsAcceptComponent {...toolsProps} />
      )}
    </div>
  );
};

export default MileageDetailsToolsComponent;
