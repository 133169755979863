import UserStatus from "../../../../../common/types/user-status";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import PassengerListingTableColumn from "../common/types/passenger-listing-table-column";

const getColumns = (): PassengerListingTableColumn[] => {
  const translations =
    userTranslationsHelper.getPassengerListingTranslations().table.headers;

  return [
    {
      header: translations.dispatchesLabel,
      title: translations.dispatchesTitle,
      accessor: "dispatches",
      colSpan: 10,
    },
    {
      header: translations.firstNameLabel,
      title: translations.firstNameTitle,
      accessor: "firstName",
      colSpan: 10,
    },
    {
      header: translations.lastNameLabel,
      title: translations.lastNameTitle,
      accessor: "lastName",
      colSpan: 10,
    },
    {
      header: translations.usernameLabel,
      title: translations.usernameTitle,
      accessor: "username",
      colSpan: 10,
    },
    {
      header: translations.emailLabel,
      title: translations.emailTitle,
      accessor: "email",
      colSpan: 10,
    },
    {
      header: translations.phoneNumbersLabel,
      title: translations.phoneNumbersTitle,
      accessor: "phoneNumbers",
      colSpan: 10,
    },
    {
      header: translations.addressesLabel,
      title: translations.addressesTitle,
      accessor: "addresses",
      colSpan: 10,
    },
    {
      header: translations.activityStatusLabel,
      title: translations.activityStatusTitle,
      accessor: "status",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];
};

const getStatusText = (status: UserStatus): string | undefined => {
  return userHelper.getActivityStatusText(status);
};

const passengerListingTableHelper = {
  getColumns,
  getStatusText,
};

export default passengerListingTableHelper;
