import UserStatus from "../../../../../common/types/user-status";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import DealerListingTableColumn from "../common/types/dealer-listing-table-column";

const getColumns = (): DealerListingTableColumn[] => {
  const translations =
    userTranslationsHelper.getDealerListingTranslations().table.headers;

  const columns: DealerListingTableColumn[] = [
    {
      header: translations.firstNameLabel,
      title: translations.firstNameTitle,
      accessor: "firstName",
      colSpan: 10,
    },
    {
      header: translations.lastNameLabel,
      title: translations.lastNameTitle,
      accessor: "lastName",
      colSpan: 10,
    },
    {
      header: translations.usernameLabel,
      title: translations.usernameTitle,
      accessor: "userName",
      colSpan: 10,
    },
    {
      header: translations.emailLabel,
      title: translations.emailTitle,
      accessor: "emailAddress",
      colSpan: 10,
    },
    {
      header: translations.phoneNumbersLabel,
      title: translations.phoneNumbersTitle,
      accessor: "phoneNumbers",
      colSpan: 10,
    },
    {
      header: translations.taxiCorporationLabel,
      title: translations.taxiCorporationTitle,
      accessor: "taxiCorporation",
      colSpan: 10,
    },
    {
      header: translations.activityStatusLabel,
      title: translations.activityStatusTitle,
      accessor: "status",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 10,
    },
  ];

  return columns;
};

const getStatusText = (status: UserStatus): string | undefined => {
  return userHelper.getActivityStatusText(status);
};

const dealerListingTableHelper = {
  getColumns,
  getStatusText,
};

export default dealerListingTableHelper;
