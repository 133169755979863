import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DispatcherListingUserPermissionOption =
  | "hasAccessToEditUser"
  | "hasAccessToDeleteUser"
  | "hasVisibleAddDispatcherButton";

export type DispatcherListingUserPermissionsDefinition =
  UserPermissionsDefinition<DispatcherListingUserPermissionOption>;

export type DispatcherListingUserPermissions =
  UserPermissions<DispatcherListingUserPermissionOption>;

const dispatcherListingUserPermissionDefinition: DispatcherListingUserPermissionsDefinition =
  {
    hasAccessToEditUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToDeleteUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasVisibleAddDispatcherButton: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  };

export default dispatcherListingUserPermissionDefinition;
