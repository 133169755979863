import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import CargoAddressEditUpdateRequest from "./types/cargo-address-edit-update.request";
import CargoAddressEditResponse from "./types/cargo-address-edit.response";

const fetchInitData = async (
  cargoCompanyUuid: string,
  addressUuid: string
): Promise<CargoAddressEditResponse> => {
  const path = `${appConfig.baseApiUrl}/cargo-companies/${cargoCompanyUuid}/addresses/${addressUuid}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const update = async (
  cargoCompanyUuid: string,
  addressUuid: string,
  request: CargoAddressEditUpdateRequest
) => {
  const path = `${appConfig.baseApiUrl}/cargo-companies/${cargoCompanyUuid}/addresses/${addressUuid}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const cargoAddressEditApiService = { fetchInitData, update };

export default cargoAddressEditApiService;
