import { FC, useEffect, useRef, useState } from "react";
import ModalComponent from "../../../modal/modal.component";
import ButtonComponent from "../../../button/button.component";
import DatePickerComponent from "../../../form/date-picker/date-picker/date-picker.component";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";

type ListingDateFilterProps = {
  isOpen: boolean;
  onClose: () => void;
  onApply: (date: Date) => void;
};

const ListingDateFilterComponent: FC<ListingDateFilterProps> = (props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const translations =
    appTranslationsHelper.getComponentTranslations().listing.filter.date;

  useEffect(() => {
    if (props.isOpen) {
      return;
    }

    setSelectedDate(null);
  }, [props.isOpen]);

  const onAcceptButtonClick = () => {
    if (!selectedDate) {
      return;
    }

    props.onApply(selectedDate);
  };

  const onRejectButtonClick = () => {
    props.onClose();
  };

  const ApplyButton = (
    <ButtonComponent
      type="primary"
      onClick={onAcceptButtonClick}
      title={translations.applyButtonTitle}
    >
      {translations.applyButtonText}
    </ButtonComponent>
  );

  const RejectButton = (
    <ButtonComponent
      type="danger"
      onClick={onRejectButtonClick}
      title={translations.rejectButtonTitle}
    >
      {translations.rejectButtonText}
    </ButtonComponent>
  );

  return (
    <ModalComponent
      header={{
        title: translations.headingText,
      }}
      classNames={{
        root: "listing_date_filter",
        content: "listing_date_filter_content",
      }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[ApplyButton, RejectButton]}
    >
      <DatePickerComponent date={selectedDate} onChange={setSelectedDate} />
    </ModalComponent>
  );
};

export default ListingDateFilterComponent;
