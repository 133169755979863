import MileageAddRequest, {
  MileageAddRequestAddressSequenceNode,
  MileageAddRequestVehicleType,
} from "../../../api/raily/mileage/add/mileage-add.request";
import MileageAddParams, {
  MileageAddParamsNode,
  MileageAddParamsVehicleType,
} from "./mileage-add-params";

const createAddressNode = (
  node: MileageAddParamsNode
): MileageAddRequestAddressSequenceNode => {
  return {
    displayName: node.displayName,
    lat: node.lat,
    lon: node.lon,
    time: node.time,
    apartmentNumber: node.apartmentNumber,
    houseNumber: node.houseNumber,
    street: node.street,
    town: node.town,
    zipCode: node.zipCode,
    distance: node.distance,
  };
};

const createAddressNodes = (
  nodes: MileageAddParamsNode[]
): MileageAddRequestAddressSequenceNode[] => {
  const requestNodes: MileageAddRequestAddressSequenceNode[] =
    nodes.map(createAddressNode);

  return requestNodes;
};

const createVehicleType = (
  type: MileageAddParamsVehicleType | undefined
): MileageAddRequestVehicleType | undefined => {
  switch (type) {
    case MileageAddParamsVehicleType.CAR_GT_900CM3:
      return MileageAddRequestVehicleType.CAR_GT_900CM3;
    case MileageAddParamsVehicleType.CAR_LE_900CM3:
      return MileageAddRequestVehicleType.CAR_LE_900CM3;
    case MileageAddParamsVehicleType.COMPANY_OWNED:
      return MileageAddRequestVehicleType.COMPANY_OWNED;
    case MileageAddParamsVehicleType.MOPED:
      return MileageAddRequestVehicleType.MOPED;
    case MileageAddParamsVehicleType.MOTORCYCLE:
      return MileageAddRequestVehicleType.MOTORCYCLE;
    default:
      return undefined;
  }
};

const create = (params: MileageAddParams): MileageAddRequest => {
  return {
    addressSequenceNodes: createAddressNodes(params.nodes),
    mileageDate: params.mileageDate,
    workerCompanyId: params.workerCompanyUuid,
    workerId: params.workerUuid,
    cardNumber: params.cardNumber,
    commissionNumber: params.commissionNumber,
    distance: params.distance,
    mileageNumber: params.mileageNumber,
    notes: params.notes,
    vehicleType: createVehicleType(params.vehicleType),
    osrmDistance: params.osrmDistance,
  };
};

const mileageAddRequestfactory = {
  create,
};

export default mileageAddRequestfactory;
