import { FC } from "react";
import CardEditButtonComponent from "../../../../../../common/components/card/button/add/card-edit-button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../../common/context/sign-up-driver.context";
import SignUpDriverFormStep from "../../common/types/sign-up-driver-form-step";

type SignUpDriverSummaryVehicleProps = {};

const SignUpDriverSummaryVehicleComponent: FC<
  SignUpDriverSummaryVehicleProps
> = () => {
  const vehicleDataSummaryDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().summary
      .vehicleData;

  const { vehicleData, setCurrentFormStep } = useSignUpDriverContext();

  const onEditButtonClick = () => {
    setCurrentFormStep(SignUpDriverFormStep.vehicleData);
  };

  return (
    <CardComponent
      header={{
        title: vehicleDataSummaryDataFormTranslations.title,
        actions: [
          <CardEditButtonComponent
            onClick={onEditButtonClick}
            title={vehicleDataSummaryDataFormTranslations.editButtonText}
          />,
        ],
      }}
    >
      <Row>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={vehicleDataSummaryDataFormTranslations.makeLabel}
            classNames={{ root: "mt-0" }}
          >
            {vehicleData.make}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={vehicleDataSummaryDataFormTranslations.modelLabel}
            classNames={{ root: "mt-0" }}
          >
            {vehicleData.model}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={vehicleDataSummaryDataFormTranslations.productionYearLabel}
            classNames={{ root: "mt-0" }}
          >
            {vehicleData.productionYear}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={vehicleDataSummaryDataFormTranslations.numberOfSeatsLabel}
            classNames={{ root: "mt-0" }}
          >
            {vehicleData.numberOfSeats}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={
              vehicleDataSummaryDataFormTranslations.registrationNumberLabel
            }
            classNames={{ root: "mt-0" }}
          >
            {vehicleData.registrationNumber}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={vehicleDataSummaryDataFormTranslations.ownershipLabel}
            classNames={{ root: "mt-0" }}
          >
            {vehicleData.ownership!.label}
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default SignUpDriverSummaryVehicleComponent;
