import { FC } from "react";
import CargoTaxiContractListingFilter from "../../common/types/cargo-taxi-contract-listing-filter";
import ListingFilterBadgeListComponent from "../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";
import cargoTaxiContractListingFiltersBadgeListHelper from "./cargo-taxi-contract-listing-filters-badge-list.helper";

type CargoTaxiContractListingFiltersBadgeListProps = {
  filters: CargoTaxiContractListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const CargoTaxiContractListingFiltersBadgeListComponent: FC<
  CargoTaxiContractListingFiltersBadgeListProps
> = (props) => {
  const badges = cargoTaxiContractListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default CargoTaxiContractListingFiltersBadgeListComponent;
