import { FC } from "react";
import taxiUnsettledRouteListingFiltersBadgeListHelper from "./taxi-unsettled-route-listing-filters-badge-list.helper";
import TaxiUnsettledRouteListingFilter from "../../types/taxi-unsettled-route-listing-filter";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type TaxiUnsettledRouteListingFiltersListProps = {
  filters: TaxiUnsettledRouteListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const TaxiUnsettledRouteListingFiltersBadgeListComponent: FC<
  TaxiUnsettledRouteListingFiltersListProps
> = (props) => {
  const badges = taxiUnsettledRouteListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default TaxiUnsettledRouteListingFiltersBadgeListComponent;
