import { FC } from "react";
import {
  faCodeMerge,
  faCrosshairs,
  faDriversLicense,
  faPencil,
  faMessage,
  faCancel,
  faCar,
  faEnvelope,
  faCodePullRequest,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CargoOrderActiveListItem } from "../../../../common/services/cargo-order/active-list/cargo-order-active-list";
import TableComponent from "../../../../common/components/table/table.component";
import orderActiveListingTableHelper from "./order-active-listing-table.helper";
import OrderActiveListingTableRow from "../common/types/order-active-listing-table-row";
import OrderActiveListingJoinTargetOrderData from "../common/types/order-active-listing-join-target-order-data";
import OrderActiveListingJoinSourceOrderData from "../common/types/order-active-listing-join-source-order-data";
import OrderActiveListingTableTimeToStartComponent from "./time-to-start/order-active-listing-table-time-to-start.component";
import OrderActiveListingTableRouteComponent from "./route/order-active-listing-table-route.component";
import OrderActiveListingTableDriverMobileAppUserComponent from "./driver-mobile-application-user/order-active-listing-table-driver-mobile-app-user.component";
import TableButtonComponent from "../../../../common/components/table/button/table-button.component";
import OrderDetailsComponent from "../../details/order-details.component";
import OrderActiveListingTableStatusComponent from "./status/order-active-listing-table-status.component";
import TableLinkButtonComponent from "../../../../common/components/table/button/link/table-link-button.component";
import useOrderActiveListingUserPermissions from "../common/user-permissions/use-order-active-listing-user-permission";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import OrderActiveListingRouteQueryParams from "../../common/routes/types/order-active-listing-route-params";
import orderOptimizerRoutesHelper from "../../../order-optimizer/common/routes/order-optimizer-routes.helper";

type OrderActiveListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: CargoOrderActiveListItem[];
  expandedRowsUuids: string[];
  onExpandedRowsUuidsChange: (expandedRowsUuids: string[]) => void;
  selectedJoinTargetOrderData: OrderActiveListingJoinTargetOrderData | null;
  onSelectedJoinTargetOrderDataChange: (
    selectedJoinTargetOrderData: OrderActiveListingJoinTargetOrderData | null
  ) => void;
  selectedJoinSourceOrdersData: OrderActiveListingJoinSourceOrderData[];
  onSelectedJoinSourceOrdersDataChange: (
    selectedJoinSourceOrdersData: OrderActiveListingJoinSourceOrderData[]
  ) => void;
  isOrderJoinEnabled: boolean;
  onCancelOrderSuccess: () => void;
  onAddCandidatureSuccess: () => void;
};
const OrderActiveListingTableComponent: FC<OrderActiveListingTableProps> = (
  props
) => {
  const columns = orderActiveListingTableHelper.getColumns();
  const userPermissions = useOrderActiveListingUserPermissions();

  const translations =
    orderTranslationsHelper.getActiveListingTranslations().table;

  const expandRow = (orderUuid: string) => {
    props.onExpandedRowsUuidsChange([...props.expandedRowsUuids, orderUuid]);
  };

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderActiveListingRouteQueryParams>();

  const handlePushRouteWithSelectedOrderId = (id: string) => {
    const newSearchParams = { ...routeQueryParams };
    newSearchParams.defaultSelectedOrderUuid = id;
    setRouteQueryParams(newSearchParams);
  };

  const collapseRow = (orderUuid: string) => {
    const filteredExpandedRows = props.expandedRowsUuids.filter(
      (expandedRowUuid) => expandedRowUuid !== orderUuid
    );

    props.onExpandedRowsUuidsChange(filteredExpandedRows);
  };

  const checkDateWithinTwoHours = (date: Date) => {
    const data = new Date(date);
    const currentDate = new Date();

    currentDate.setHours(currentDate.getHours() + 2);

    return !!(data <= currentDate);
  };

  const createTableRow = (
    listingItem: CargoOrderActiveListItem
  ): OrderActiveListingTableRow => {
    const isRowExpanded = props.expandedRowsUuids.includes(listingItem.uuid);

    const onRowClick = () => {
      if (isRowExpanded) {
        collapseRow(listingItem.uuid);
        return;
      }

      expandRow(listingItem.uuid);
    };

    const isSelectTargetOrderForOrderJoinButtonVisible =
      !props.selectedJoinTargetOrderData ||
      props.selectedJoinTargetOrderData.uuid === listingItem.uuid;

    const isSelectSourceOrderForOrderJoinButtonVisible =
      props.selectedJoinTargetOrderData &&
      props.selectedJoinTargetOrderData.uuid !== listingItem.uuid;

    const isSelectSourceOrderForOrderJoinButtonEnabled =
      props.selectedJoinTargetOrderData?.clientUuid ===
        listingItem.producer?.id && !listingItem.drivers.length;

    const onSelectSourceOrderForOrderJoinButtonClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation();

      if (
        props.selectedJoinSourceOrdersData.find(
          (item) => item.uuid === listingItem.uuid
        )
      ) {
        props.onSelectedJoinSourceOrdersDataChange(
          props.selectedJoinSourceOrdersData.filter(
            (item) => item.uuid !== listingItem.uuid
          )
        );

        return;
      }

      props.onSelectedJoinSourceOrdersDataChange([
        ...props.selectedJoinSourceOrdersData,
        {
          internalOrderId: listingItem.internalOrderId,
          uuid: listingItem.uuid,
        },
      ]);
    };

    const isLessThenTwoHoures = checkDateWithinTwoHours(
      listingItem.estimatedStartDate
    );

    return {
      id: listingItem.uuid,
      idForTesting: listingItem.uuid,
      onClick: onRowClick,
      value: {
        executionStatus: (
          <OrderActiveListingTableStatusComponent
            status={listingItem.executionStatus}
          />
        ),
        timeToStart: (
          <OrderActiveListingTableTimeToStartComponent
            timeToStart={listingItem.estimatedStartDate}
          />
        ),
        internalOrderId: (
          <div title={String(listingItem.internalOrderId)}>
            {listingItem.internalOrderId}
          </div>
        ),
        externalOrderId: (
          <div title={String(listingItem.externalOrderId)}>
            {listingItem.externalOrderId}
          </div>
        ),
        newSharedPost: listingItem.attentions.newSharedPost && (
          <div title={translations.headers.newSharedPostTitle}>
            <FontAwesomeIcon
              icon={faMessage}
              className="order_active_listing_table_message"
              size={"sm"}
            />
          </div>
        ),
        newPlanEntryPost: listingItem.attentions.newPlanEntryPost && (
          <div title={translations.headers.newPlanEntryPostTitle}>
            <FontAwesomeIcon
              icon={faEnvelope}
              className="order_active_listing_table_driver_message"
              size={"sm"}
            />
          </div>
        ),
        cargoOrderEdit: listingItem.attentions.cargoOrderEdit && (
          <div title={translations.headers.cargoOrderEditTitle}>
            <FontAwesomeIcon
              icon={faPencil}
              className="order_active_listing_table_edit"
              size={"sm"}
            />
          </div>
        ),
        cargoOrderCancel: listingItem.attentions.cargoOrderCancel && (
          <div title={translations.headers.cargoOrderCancelTitle}>
            <FontAwesomeIcon
              icon={faCancel}
              className="order_active_listing_table_cancel"
              size={"sm"}
            />
          </div>
        ),
        slkStatus: listingItem.slkStatus !== null && (
          <div
            title={
              listingItem.slkStatus
                ? translations.headers.slkStatusActiveTitle
                : translations.headers.slkStatusNotActiveTitle
            }
          >
            <FontAwesomeIcon
              fade={isLessThenTwoHoures}
              icon={faCar}
              className={`${
                listingItem.slkStatus
                  ? "order_active_listing_table_active"
                  : "order_active_listing_table_cancel"
              }`}
              size={"sm"}
            />
          </div>
        ),
        route: (
          <OrderActiveListingTableRouteComponent
            addresses={listingItem.routeAddresses}
          />
        ),
        passenger: (
          <div>
            {listingItem.passengers.map((passenger, index) => (
              <div key={index} title={passenger}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        dispatch: (
          <div title={listingItem.dispatch}>{listingItem.dispatch}</div>
        ),
        client: (
          <div title={listingItem.producer?.name}>
            {listingItem.producer?.name}
          </div>
        ),
        taxi: (
          <div title={listingItem.consumer?.name}>
            {listingItem.consumer?.name}
          </div>
        ),
        isMobileAppUser: (
          <OrderActiveListingTableDriverMobileAppUserComponent
            isMobileAppUser={listingItem.isMobileAppUser}
          />
        ),
        driver: (
          <div>
            {listingItem.drivers.map((driver, index) => (
              <div key={index} title={driver}>
                {driver}
              </div>
            ))}
          </div>
        ),
        actions: (
          <div className="order_active_listing_table_actions d-flex">
            {props.isOrderJoinEnabled && (
              <>
                {isSelectTargetOrderForOrderJoinButtonVisible && (
                  <TableButtonComponent
                    icon={faCrosshairs}
                    type={
                      props.selectedJoinTargetOrderData?.uuid ===
                      listingItem.uuid
                        ? "success"
                        : "brand"
                    }
                    onClick={() => {
                      props.onSelectedJoinTargetOrderDataChange({
                        clientUuid: listingItem.producer?.id ?? "",
                        uuid: listingItem.uuid,
                        internalOrderId: listingItem.internalOrderId,
                      });
                    }}
                    idForTesting={`order-active-liting-table-item-${listingItem.uuid}-select-target-join-order-button`}
                  />
                )}
                {isSelectSourceOrderForOrderJoinButtonVisible && (
                  <TableButtonComponent
                    icon={faCodeMerge}
                    type={
                      props.selectedJoinSourceOrdersData.find(
                        (item) => item.uuid === listingItem.uuid
                      )
                        ? "success"
                        : "brand"
                    }
                    isDisabled={!isSelectSourceOrderForOrderJoinButtonEnabled}
                    onClick={onSelectSourceOrderForOrderJoinButtonClick}
                    idForTesting={`order-active-liting-table-item-${listingItem.uuid}-select-source-join-order-button`}
                  />
                )}
              </>
            )}
            {!!userPermissions.hasVisibileToOrderPlanningButton && (
              <TableLinkButtonComponent
                icon={faDriversLicense}
                to={orderActiveListingTableHelper.getOrderRoute({
                  orderUuid: listingItem.uuid,
                })}
                title={translations.orderPlanningLinkButtonTitle}
                idForTesting={`order-active-liting-table-item-${listingItem.uuid}-planner-button`}
                onClick={() =>
                  handlePushRouteWithSelectedOrderId(listingItem.uuid)
                }
              />
            )}
            {!!userPermissions.hasVisibleOptitoolButton && (
              <TableLinkButtonComponent
                icon={faCodePullRequest}
                to={orderOptimizerRoutesHelper.getListingRouteWithOrderHumanId(
                  listingItem.internalOrderId
                )}
                title={translations.optitoolLinkButtonTitle}
                idForTesting={`order-active-liting-table-item-${listingItem.uuid}-optitool-button`}
              />
            )}
          </div>
        ),
      },
      hiddenComponent: (
        <OrderDetailsComponent
          orderUuid={listingItem.uuid}
          cargoCompanyOrderId={listingItem.internalOrderId}
          cargoCompanyUuid={listingItem.producer?.id}
          attentions={listingItem.attentions}
          isVisible={isRowExpanded}
          onCancelOrderSuccess={props.onCancelOrderSuccess}
          onAddCandidatureSuccess={props.onAddCandidatureSuccess}
        />
      ),
      isHiddenComponentVisible: isRowExpanded,
    };
  };

  const tableRows: OrderActiveListingTableRow[] = props.listingItems.map(
    (listingItem) => createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={tableRows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderActiveListingTableComponent;
