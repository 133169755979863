import TaxiContractListResponse, {
  TaxiContractListResponseItem,
  TaxiContractListResponseItemBillingModel,
  TaxiContractListResponseItemTaxiCorporation,
  TaxiContractListResponseItemTaxiCorporationContractMaintenancePolicy,
  TaxiContractListResponseItemTaxiCorporationFleetPartner,
} from "../../../api/raily/taxi-taxi-contract/list/taxi-taxi-contract-list.response";
import BillingModel from "../../../types/billing-model";
import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import TaxiTaxiContractList, {
  TaxiTaxiContractListItem,
  TaxiTaxiContractListItemTaxiCorporation,
  TaxiTaxiContractListItemTaxiCorporationFleetPartner,
} from "./taxi-taxi-contract-list";

const createBillingModel = (
  responseModel: TaxiContractListResponseItemBillingModel
) => {
  switch (responseModel) {
    case TaxiContractListResponseItemBillingModel.AB:
      return BillingModel.AB;
    case TaxiContractListResponseItemBillingModel.ABA:
      return BillingModel.ABA;
    case TaxiContractListResponseItemBillingModel.OTHER:
      return BillingModel.OTHER;
    case TaxiContractListResponseItemBillingModel.SABS:
      return BillingModel.SABS;
  }
};

const createTaxiCorporationContractMaintenancePolicy = (
  responseContractMaintenancePolicy: TaxiContractListResponseItemTaxiCorporationContractMaintenancePolicy
): ContractMaintenancePolicy => {
  switch (responseContractMaintenancePolicy) {
    case TaxiContractListResponseItemTaxiCorporationContractMaintenancePolicy.AUCTION:
      return ContractMaintenancePolicy.AUCTION;
    case TaxiContractListResponseItemTaxiCorporationContractMaintenancePolicy.BASIC:
      return ContractMaintenancePolicy.BASIC;
  }
};

const createTaxiCorporationFleetPartner = (
  responseFleetPartner: TaxiContractListResponseItemTaxiCorporationFleetPartner
): TaxiTaxiContractListItemTaxiCorporationFleetPartner => {
  return {
    createdBy: responseFleetPartner.createdBy,
    creationDate: responseFleetPartner.createdAt,
    modifiedBy: responseFleetPartner.modifiedBy,
    modifitationDate: responseFleetPartner.modifiedAt,
    name: responseFleetPartner.name,
    taxiCorporationUuid: responseFleetPartner.taxiCorporationId,
    uuid: responseFleetPartner.id,
  };
};

const createTaxiCorporationFleetPartners = (
  responseFleetPartners: TaxiContractListResponseItemTaxiCorporationFleetPartner[]
): TaxiTaxiContractListItemTaxiCorporationFleetPartner[] => {
  return responseFleetPartners.map(createTaxiCorporationFleetPartner);
};

const createTaxiCorporation = (
  responseTaxiCorporation: TaxiContractListResponseItemTaxiCorporation
): TaxiTaxiContractListItemTaxiCorporation => {
  return {
    address: responseTaxiCorporation.headquartersAddressString,
    companyId: responseTaxiCorporation.regon,
    contractMaintenancePolicy: createTaxiCorporationContractMaintenancePolicy(
      responseTaxiCorporation.contractMaintenancePolicy
    ),
    createdBy: responseTaxiCorporation.createdBy,
    creationDate: responseTaxiCorporation.createdAt,
    displayName: responseTaxiCorporation.displayName,
    fleetPartners: createTaxiCorporationFleetPartners(
      responseTaxiCorporation.fleetPartners
    ),
    modifiedBy: responseTaxiCorporation.modifiedBy,
    modifitationDate: responseTaxiCorporation.modifiedAt,
    name: responseTaxiCorporation.companyName,
    nationalCourtRegister: responseTaxiCorporation.krsNo,
    notes: responseTaxiCorporation.notes,
    taxNumber: responseTaxiCorporation.taxId,
    uuid: responseTaxiCorporation.id,
  };
};

const createDataItem = (
  responseDataItem: TaxiContractListResponseItem
): TaxiTaxiContractListItem => {
  return {
    periodOfValidity: {
      from: responseDataItem.validSince,
      to: responseDataItem.validTo,
    },
    isActive: !responseDataItem.disabled,
    billingModel: createBillingModel(responseDataItem.model),
    distanceRate: responseDataItem.distanceRate,
    stoppingRate: responseDataItem.haltingTimeRate,
    freeStoppingPeriodMinutes: responseDataItem.haltingTimeAfterMinutes,
    uuid: responseDataItem.id,
    producerTaxiUuid: responseDataItem.producerTaxiId,
    producerTaxi: createTaxiCorporation(responseDataItem.producerTaxi),
    consumerTaxiUuid: responseDataItem.consumerTaxiId,
    consumerTaxi: createTaxiCorporation(responseDataItem.consumerTaxi),
    displayName: responseDataItem.displayName,
    priority: responseDataItem.priority,
    isValid: responseDataItem.isValid,
    isPartner: responseDataItem.isPartner,
  };
};

const createData = (
  responseData: TaxiContractListResponseItem[]
): TaxiTaxiContractListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: TaxiContractListResponse): TaxiTaxiContractList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const taxiTaxiContractListFactory = {
  create,
};

export default taxiTaxiContractListFactory;
