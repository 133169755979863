import { FC, useEffect } from "react";
import {
  faUser,
  faUserGear,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import SignUpPassengerFormStep from "./common/types/sign-up-passenger-form-step";
import { useSignUpPassengerContext } from "./common/context/sign-up-passenger.context";
import SignUpPassengerUserComponent from "./user/sign-up-passenger-user.component";
import SignUpPassengerWizardFormComponent from "./common/components/wizard-form/sign-up-passenger-wizard-form.component";
import SignUpPassengerWizardFormStep from "./common/components/wizard-form/sign-up-passenger-wizard-form-step";
import SignUpPassengerSummaryComponent from "./summary/sign-up-passenger-summary.component";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useQueryParams from "../../../../common/hooks/use-query-params";
import signUpTokenService from "../common/sign-up-token.service";
import SignUpPassengerTokenPayload from "../../sign-up/common/types/sign-up-passenger-token-payload";
import SignUpPassengerAccountComponent from "./account/sign-up-passenger-account.component";
import signUpPassengerFormFactory from "./common/sign-up-passenger-form.factory";
import AppLogoHorizontalComponent from "../../../../common/components/app-logo/app-logo-horizontal.component";

const SignUpPassengerComponent: FC = () => {
  const translations = appTranslationsHelper.getPassengerSignUpTranslations();

  const {
    currentFormStep,
    formSteps,
    passedFormSteps,
    goToStep,
    setDispatchId,
    setUserData,
    setAccountData,
    setUserDataDisabledFormFields: setUserFieldsDisabled,
    setAccountDataDisabledFormFields: setAccountDataDisabledFields,
  } = useSignUpPassengerContext();

  const { token } = useQueryParams<{ token: string | undefined }>();

  const onTokenDecodeSuccess = (tokenPayload: SignUpPassengerTokenPayload) => {
    const userFormData =
      signUpPassengerFormFactory.createUserFormData(tokenPayload);
    const accountFormData =
      signUpPassengerFormFactory.createAccountFormData(tokenPayload);
    setDispatchId(tokenPayload.dispatch_id);

    setUserData(userFormData);
    setAccountData(accountFormData);

    const userDisabledFields =
      signUpPassengerFormFactory.createUserDisabledFormFields(tokenPayload);
    const accountDisabledFields =
      signUpPassengerFormFactory.createAccountDisabledFormFields(tokenPayload);

    setUserFieldsDisabled(userDisabledFields);
    setAccountDataDisabledFields(accountDisabledFields);
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    try {
      const tokenPayload = signUpTokenService.decode(token);

      if (!signUpTokenService.checkIsPassenger(tokenPayload)) {
        console.error(`Invalid token type. It is not driver token.`);
        return;
      }

      onTokenDecodeSuccess(tokenPayload);
    } catch (e) {
      console.error(`Invalid token`);
    }
  }, [token]);

  const wizardFormStepsDefinition: SignUpPassengerWizardFormStep[] = [
    {
      key: SignUpPassengerFormStep.accountData,
      component: <SignUpPassengerAccountComponent />,
      description: translations.formSteps.accountData,
      icon: faUserGear,
      onSelect: () => goToStep(SignUpPassengerFormStep.accountData),
    },
    {
      key: SignUpPassengerFormStep.userData,
      component: <SignUpPassengerUserComponent />,
      description: translations.formSteps.userData,
      icon: faUser,
      onSelect: () => goToStep(SignUpPassengerFormStep.userData),
    },
    {
      key: SignUpPassengerFormStep.summary,
      component: <SignUpPassengerSummaryComponent />,
      description: translations.formSteps.summary,
      icon: faReceipt,
      onSelect: () => goToStep(SignUpPassengerFormStep.summary),
    },
  ];

  const wizardFormSteps = formSteps.map(
    (formStep) =>
      wizardFormStepsDefinition.find(
        (wizardFormStep) => wizardFormStep.key === formStep
      )!
  );

  return (
    <div className="sign_up_passenger">
      <div className="sign_up_passenger__content">
        <div className="sign_up_passenger__heading">
          <AppLogoHorizontalComponent className="sign_up_passenger__logo" />
          <h1 className="sign_up_passenger__title">
            {translations.headerTitle}
          </h1>
        </div>
        <SignUpPassengerWizardFormComponent
          steps={wizardFormSteps}
          selectedStepKey={currentFormStep}
          passedStepsKeys={passedFormSteps}
        />
      </div>
    </div>
  );
};

export default SignUpPassengerComponent;
