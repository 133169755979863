export enum TaxiRouteSettlementListingReportRequestOrder {
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  HIGHWAY_CHARGE_ASC = "HIGHWAY_CHARGE_ASC",
  HIGHWAY_CHARGE_DESC = "HIGHWAY_CHARGE_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  DRIVER_ASC = "DRIVER_ASC",
  DRIVER_DESC = "DRIVER_DESC",
  BILLING_MODEL_ASC = "BILLING_MODEL_ASC",
  BILLING_MODEL_DESC = "BILLING_MODEL_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  DISTANCE_AMOUNT_ASC = "DISTANCE_AMOUNT_ASC",
  DISTANCE_AMOUNT_DESC = "DISTANCE_AMOUNT_DESC",
  TOTAL_AMOUNT_ASC = "TOTAL_AMOUNT_ASC",
  TOTAL_AMOUNT_DESC = "TOTAL_AMOUNT_DESC",
}

type TaxiRouteSettlementListingReportRequest = {
  order: TaxiRouteSettlementListingReportRequestOrder | undefined;
  human_id: string | undefined;
  date_since: string | undefined;
  date_to: string | undefined;
  driver: string | undefined;
  billing_model: string | undefined;
  fleet_partner: string | undefined;
  cargo_order_human_id: number | undefined;
};

export default TaxiRouteSettlementListingReportRequest;
