import { useLocation } from "react-router-dom";
import urlService from "../utils/url/url.service";

const useQueryParams = <T = any>() => {
  const { search } = useLocation();

  const newParamObj = urlService.parse(search) as any;
  return newParamObj as T;
};

export default useQueryParams;
