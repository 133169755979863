import MileageContractListResponse, {
  MileageContractListResponseItem,
} from "../../../api/raily/mileage-contract/list/mileage-contract-list.response";
import MileageContractList, {
  MileageContractListItem,
} from "./mileage-contract-list";

const createDataItem = (
  item: MileageContractListResponseItem
): MileageContractListItem => {
  return {
    uuid: item.id,
    companyUuid: item.companyId,
    validSince: item.validSince,
    validTo: item.validTo,
    modificationDate: item.modifiedAt,
    modifiedBy: item.modifiedBy,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    dietFullAfterHours: item.dietFullAfterHours,
    dietFullRate: item.dietFullRate,
    dietHalfAfterHours: item.dietHalfAfterHours,
    dietHalfRate: item.dietHalfRate,
    disabled: item.disabled,
    distanceRateCarGt900cm3: item.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: item.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: item.distanceRateCompanyVehicle,
    distanceRateMoped: item.distanceRateMoped,
    distanceRateMotorcycle: item.distanceRateMotorcycle,
    companyName: item.companyName,
  };
};

const createData = (
  responseData: MileageContractListResponseItem[]
): MileageContractListItem[] => {
  const items: MileageContractListItem[] = responseData.map(createDataItem);

  return items;
};

const create = (response: MileageContractListResponse): MileageContractList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const mileageContractsListFactory = {
  create,
};

export default mileageContractsListFactory;
