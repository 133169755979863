import railyApiService from "../raily-api.service";
import mileageContractsAddRequestFactory from "./add/mileage-contract-add-request.factory";
import MileageContractAddRequest from "./add/mileage-contract-add.request";
import MileageContractAddResponse from "./add/mileage-contract-add.response";
import MileageContractTemplateResponse from "./template/mileage-contract-template.response";
import mileageContractListRequestFactory from "./list/mileage-contract-list-request.factory";
import MileageContractListRequest from "./list/mileage-contract-list.request";
import MileageContractListResponse from "./list/mileage-contract-list.response";
import MileageContractDetailsRequest from "./details/mileage-contract-details.request";
import MileageContractDetailsResponse from "./details/mileage-contract-details.response";
import MileageContractUpdateRequest from "./update/mileage-contract-update.request";
import MileageContractUpdateResponse from "./update/mileage-contract-update.response";
import mileageContractsUpdateRequestFactory from "./update/mileage-contract-update-request.factory";

const mileageContractApiService = () => {
  const list = async (
    request: MileageContractListRequest,
    abortSignal: AbortSignal
  ): Promise<MileageContractListResponse> => {
    const url = `contracts/mileage`;

    const queryParams =
      mileageContractListRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      queryParams,
      abortSignal,
    });
  };

  const add = async (
    request: MileageContractAddRequest,
    abortSignal: AbortSignal
  ): Promise<MileageContractAddResponse> => {
    const url = `/contracts/mileage`;

    const body = mileageContractsAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const update = async (
    request: MileageContractUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<MileageContractUpdateResponse> => {
    const url = `/contracts/mileage/${request.contractUuid}`;

    const body = mileageContractsUpdateRequestFactory.createBody(request);

    return railyApiService().put({
      url,
      abortSignal,
      body,
    });
  };

  const getTemplate = async (
    abortSignal: AbortSignal
  ): Promise<MileageContractTemplateResponse> => {
    const url = `/contracts/mileage/template`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  const getDetails = (
    request: MileageContractDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<MileageContractDetailsResponse> => {
    const url = `/contracts/mileage/${request.contractUuid}`;

    return railyApiService().get<MileageContractDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return {
    add,
    list,
    update,
    getTemplate,
    getDetails,
  };
};

export default mileageContractApiService;
