import { FC } from "react";
import OrderActiveListingFilter from "../common/types/order-active-listing-filter";
import OrderActiveListingSortKey from "../common/types/order-active-listing-sort-key";
import orderActiveListingFilterHelper from "./order-active-listing-filter.helper";
import ListingSortDefinition from "../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterDefinition from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingFilterComponent from "../../../../common/components/listing/filter/listing-filter.component";

type OrderActiveListingFilterProps = {
  filters: OrderActiveListingFilter[];
  onFiltersChange: (filters: OrderActiveListingFilter[]) => void;
  sortKey: OrderActiveListingSortKey;
  onSortKeyChange: (sortKey: OrderActiveListingSortKey) => void;
};

const OrderActiveListingFilterComponent: FC<OrderActiveListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<OrderActiveListingFilter> =
    orderActiveListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    orderActiveListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as OrderActiveListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as OrderActiveListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="order-active-listing-filter"
      idForTestingSort="order-active-listing-sort"
    />
  );
};

export default OrderActiveListingFilterComponent;
