import DriverPlanCandidatureAddByOrderIdRequest from "../../../api/raily/driver-plan-candidature/add-by-order-id/driver-plan-candidature-add-by-order-id.request";
import DriverPlanCandidatureAddByOrderIdParams from "./driver-plan-candidature-add-by-order-id-params";

const create = (
  params: DriverPlanCandidatureAddByOrderIdParams
): DriverPlanCandidatureAddByOrderIdRequest => {
  return {
    driverId: params.driverUuid,
    orderId: params.orderUuid,
    acceptImmediately: params.acceptImmediately,
    planEntryId: params.planEntryUuid,
    taxiCorporationId: params.taxiCorporationUuid,
  };
};

const driverPlanCandidatureAddRequestFactory = {
  create,
};

export default driverPlanCandidatureAddRequestFactory;
