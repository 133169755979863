import BillingModel from "../../../../types/billing-model";
import TaxiDriverAssociationContractType from "../../../../types/taxi-driver-association-contract-type";
import UserVehicleOwnership from "../../../../types/user-vehicle-ownership-form";

import HttpResponse from "../../../../utils/http/http.response";

export enum PlannerDriverPlanListResponseItemUserTaxiDriverContractType {
  B2B = "B2B",
  EMPLOYMENT = "EMPLOYMENT",
  CONTRACT_OF_MANDATE = "CONTRACT_OF_MANDATE",
}

export enum PlannerDriverPlanListResponseItemUserBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type PlannerDriverPlanListResponseItemUser = {
  id: string;
  firstName: string;
  lastName: string;
  mobilePrimary: string;
  mobilePrimaryPrefix: string;
  mobileSecondary: string | null;
  mobileSecondaryPrefix: string | null;
  taxiName: string;
  taxiDriverContractType: PlannerDriverPlanListResponseItemUserTaxiDriverContractType;
  startingAddress: string;
  carMake: string | null;
  carModel: string | null;
  carOwnership: UserVehicleOwnership;
  billingModel: PlannerDriverPlanListResponseItemUserBillingModel;
  distanceRate: number;
};

export type PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPointsNodeData =
  {
    address: string;
    date: Date;
  };

export type PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPoints = {
  humanId: number;
  waypoints: PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPointsNodeData[];
};

export type PlannerDriverPlanListResponseItemCandidatingPlanEntryEndDate = {
  address: string;
  date: Date;
};

export type PlannerDriverPlanListResponseItemCandidatingPlanEntryStartData = {
  address: string;
  date: Date;
};

export type PlannerDriverPlanListResponseItemCandidatingPlanEntry = {
  id: number;
  humanId: number;
  start: PlannerDriverPlanListResponseItemCandidatingPlanEntryStartData;
  end: PlannerDriverPlanListResponseItemCandidatingPlanEntryEndDate;
  orders: PlannerDriverPlanListResponseItemCandidatingPlanEntryOrderPoints[];
};

export type PlannerDriverPlanListResponseItemPlanEntryOrderPointsNodeData = {
  address: string;
  date: Date;
};

export type PlannerDriverPlanListResponseItemPlanEntryOrderPoint = {
  humanId: number;
  waypoints: PlannerDriverPlanListResponseItemPlanEntryOrderPointsNodeData[];
};

export type PlannerDriverPlanListResponseItemPlanEntryStartData = {
  address: string;
  date: Date;
};

export type PlannerDriverPlanListResponseItemPlanEntryEndData = {
  address: string;
  date: Date;
};

export type PlannerDriverPlanListResponseItemPlanEntry = {
  id: string;
  humanId: number;
  start: PlannerDriverPlanListResponseItemPlanEntryStartData;
  end: PlannerDriverPlanListResponseItemPlanEntryEndData;
  orders: PlannerDriverPlanListResponseItemPlanEntryOrderPoint[];
};

export type PlannerDriverPlanListResponseItemTargerPlanEntryData = {
  id: string;
  humanId: number;
};

export type PlannerDriverPlanListResponseItem = {
  targetPlanEntryData: PlannerDriverPlanListResponseItemTargerPlanEntryData | null;
  taxiDriverAssociationId: string | null;
  planEntries: PlannerDriverPlanListResponseItemPlanEntry[];
  candidatureEntries: PlannerDriverPlanListResponseItemCandidatingPlanEntry[];
  user: PlannerDriverPlanListResponseItemUser;
};

type PlannerDriverPlanListResponse = HttpResponse<{
  data: PlannerDriverPlanListResponseItem[];
  totalCount: number;
}>;

export default PlannerDriverPlanListResponse;
