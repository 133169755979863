import MileageDetailsRequest from "../../../api/raily/mileage/details/mileage-details.request";
import MileageDetailsLoadParams from "./mileage-details-load-params";

const create = (params: MileageDetailsLoadParams): MileageDetailsRequest => {
  return {
    mileageUuid: params.mileageUuid,
  };
};

const mileageDetailsRequestFactory = {
  create,
};

export default mileageDetailsRequestFactory;
