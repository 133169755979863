import { FC } from "react";
import taxiOrderSettlementListingTableHelper from "./taxi-order-settlement-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import TaxiOrderSettlementListingItem from "../types/taxi-order-settlement-listing-item";
import TaxiOrderSettlementListingTableColumn from "../types/taxi-order-settlement-listing-table-column";
import TaxiOrderSettlementListingTableRow from "../types/taxi-order-settlement-listing-table-row";
import taxiOrderSettlementListingUserPermissionsHelper from "../user-permissions/taxi-order-settlement-listing-user-permission.helper";
import { useAppContext } from "../../../../../../context/app.context";

type TaxiOrderSettlementListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: TaxiOrderSettlementListingItem[];
};

const TaxiOrderSettlementListingTableComponent: FC<
  TaxiOrderSettlementListingTableProps
> = (props) => {
  const { user } = useAppContext();
  const userPermissions =
    taxiOrderSettlementListingUserPermissionsHelper.getPermissions(
      user?.roles!
    );

  const columns: TaxiOrderSettlementListingTableColumn[] =
    taxiOrderSettlementListingTableHelper.getColumns();

  const rows: TaxiOrderSettlementListingTableRow[] =
    taxiOrderSettlementListingTableHelper.getRows(
      props.listingItems,
      userPermissions
    );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiOrderSettlementListingTableComponent;
