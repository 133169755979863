import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import driverDetailsUserPermissionDefinition, {
  DriverDetailsUserPermissions,
} from "./driver-details-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): DriverDetailsUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    driverDetailsUserPermissionDefinition
  );

  return permissions;
};

const driverDetailsUserPermissionsHelper = {
  getPermissions,
};

export default driverDetailsUserPermissionsHelper;
