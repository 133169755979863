import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import UserRole from "../../../../common/types/user-role";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.CARGO]: [UserRole.CARGO_OFFICER, UserRole.DISPATCHER],
    [MessengerChannel.DRIVER]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    [MessengerChannel.RAILY]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
    ],
    [MessengerChannel.SHARED]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
      UserRole.TAXI_OFFICER,
      UserRole.DEALER,
    ],
    [MessengerChannel.TAXI]: [UserRole.DEALER, UserRole.TAXI_OFFICER],
  };
};

const billingsTaxiRailyHelper = { getMessengerChannelAvailability };

export default billingsTaxiRailyHelper;
