export enum DataLoadErrorType {
  UNEXPECTED = "UNEXPECTED",
  OFFLINE = "OFFLINE",
  UNAUTHORIZED = "UNAUTHORIZED",
}

export type DataLoadError<Type, Params> = {
  type: Type;
  params: Params;
  message: string;
};

export type DataLoadOfflineError = DataLoadError<DataLoadErrorType.OFFLINE, {}>;

export type DataLoadAuthError = DataLoadError<
  DataLoadErrorType.UNAUTHORIZED,
  {}
>;

export type DataLoadUnexpectedError = DataLoadError<
  DataLoadErrorType.UNEXPECTED,
  {}
>;

export type DataLoadDefaultErrors =
  | DataLoadUnexpectedError
  | DataLoadOfflineError
  | DataLoadAuthError;

export default DataLoadError;
