import { FC, useCallback, useEffect, useState } from "react";
import ModalComponent from "../../../../common/components/modal/modal.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import BillingAddressAddAddressSelectOption from "./types/billing-address-add-address-select-option";
import BillingAddressAddAddress from "./types/billing-address-add-address";
import MapComponent from "../../../../common/components/map/map.component";
import billingAddressAddFormHelper from "./billing-address-add-form.helper";
import MapMarker from "../../../../common/components/map/types/map-marker";
import billingAddressAddFormValidationService from "./billing-address-add-form-validation.service";
import MapCoordinate from "../../../../common/components/map/types/map-coordinate";
import debounce from "lodash/debounce";
import SearchAddressResponse from "../../../../common/utils/search-address/search-address.response";
import searchAddressApiService from "../../../../common/utils/search-address/search-address.service";
import ButtonComponent from "../../../../common/components/button/button.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import FocusTrap from "focus-trap-react";
import BillingAddress from "../../cargo-taxi/edit/types/billing-address";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import BillingAddressAddFormValidationResults from "./types/billing-address-add-form-validation-results";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import billingAddressAddFactory from "./billing-address-add.factory";

export type BillingAddressAddModalProps = {
  addAddressIsOpen: boolean;
  closeModal: () => void;
  onSave: (address: BillingAddress) => void;
};

const BillingAddressAddComponent: FC<BillingAddressAddModalProps> = (props) => {
  const translations =
    billingsTranslationsHelper.getBillingsAddressAddTranslations();
  const [refreshMapFitFlag, setRefreshMapFitFlag] = useState(false);
  const [addressSelectOptions, setAddressSelectOptions] = useState<
    BillingAddressAddAddressSelectOption[]
  >([]);
  const [selectedAddressOption, setSelectedAddressOption] =
    useState<BillingAddressAddAddressSelectOption | null>(null);
  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [isAddressFetching, setIsAddressFetching] = useState(false);

  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [town, setTown] = useState("");
  const [description, setDescription] = useState("");

  const [validationResults, setValidationResults] =
    useState<BillingAddressAddFormValidationResults>(() =>
      billingAddressAddFormHelper.getDefaultValidationResults()
    );

  const [mapMarker, setMapMarker] = useState<MapMarker | null>(null);
  const [selectedCandidatureMapMarker, setSelectedCandidatureMapMarker] =
    useState<MapMarker | null>(null);
  const [selectedAddressCandidature, setSelectedAddressCandidature] =
    useState<BillingAddressAddAddress | null>(null);

  const validateStreet = (value: string = street) => {
    const validationResult =
      billingAddressAddFormValidationService.validateStreet(value);

    setValidationResults((curr) => ({
      ...curr,
      street: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateHouseNumber = (value: string = houseNumber) => {
    const validationResult =
      billingAddressAddFormValidationService.validateHouseNumber(value);

    setValidationResults((curr) => ({
      ...curr,
      houseNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateApartmentNumber = (value: string = apartmentNumber) => {
    const validationResult =
      billingAddressAddFormValidationService.validateApartmentNumber(value);

    setValidationResults((curr) => ({
      ...curr,
      apartmentNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateZipCode = (value: string = zipCode) => {
    const validationResult =
      billingAddressAddFormValidationService.validateZipCode(value);

    setValidationResults((curr) => ({
      ...curr,
      zipCode: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCountry = (value: string = country) => {
    const validationResult =
      billingAddressAddFormValidationService.validateCountry(value);

    setValidationResults((curr) => ({
      ...curr,
      country: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateTown = (value: string = town) => {
    const validationResult =
      billingAddressAddFormValidationService.validateTown(value);

    setValidationResults((curr) => ({
      ...curr,
      town: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDescription = (value: string = description) => {
    const validationResult =
      billingAddressAddFormValidationService.validateDescription(value);

    setValidationResults((curr) => ({
      ...curr,
      description: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateForm = () => {
    const isStreetValid = validateStreet();
    const isHouseNumberValid = validateHouseNumber();
    const isApartmentNumberValid = validateApartmentNumber();
    const isTownValid = validateTown();
    const isZipCodeValid = validateZipCode();
    const isCountryValid = validateCountry();
    const isDescriptionValid = validateDescription();

    return (
      isStreetValid &&
      isHouseNumberValid &&
      isApartmentNumberValid &&
      isTownValid &&
      isZipCodeValid &&
      isCountryValid &&
      isDescriptionValid
    );
  };

  const onMarkerCoordinateChange = (newMarker: MapMarker) => {
    setMapMarker(newMarker);
  };

  const onTemporaryMarkerCoordinateChange = (newMarker: MapMarker) => {
    setSelectedCandidatureMapMarker(newMarker);
  };

  const refreshMapFit = () => {
    setRefreshMapFitFlag((curr) => !curr);
  };

  const acceptAddressCandidature = () => {
    if (!selectedAddressCandidature || !selectedCandidatureMapMarker) {
      return;
    }

    const streetValue = selectedAddressCandidature.street ?? "";
    const houseNumberValue = selectedAddressCandidature.houseNumber ?? "";
    const zipCodeValue = selectedAddressCandidature.zipCode ?? "";
    const countryValue = selectedAddressCandidature.country;
    const townValue = selectedAddressCandidature.town ?? "";

    setStreet(streetValue);
    setHouseNumber(houseNumberValue);
    setApartmentNumber("");
    setZipCode(zipCodeValue);
    setCountry(countryValue);
    setTown(townValue);
    setSelectedAddressCandidature(null);
    setMapMarker({ ...selectedCandidatureMapMarker });
    setSelectedCandidatureMapMarker(null);
    refreshMapFit();

    validateStreet(streetValue);
    validateHouseNumber(houseNumberValue);
    validateZipCode(zipCodeValue);
    validateCountry(countryValue);
    validateTown(townValue);
  };

  const handleLongMapClick = (coordinate: MapCoordinate) => {
    setSelectedCandidatureMapMarker({
      coordinate,
      isDraggable: true,
    });
  };

  useEffect(() => {
    if (!selectedAddressOption) {
      return;
    }

    const streetValue = selectedAddressOption.value.street ?? "";
    const houseNumberValue = selectedAddressOption.value.houseNumber ?? "";
    const zipCodeValue = selectedAddressOption.value.zipCode ?? "";
    const countryValue = selectedAddressOption.value.country;
    const townValue = selectedAddressOption.value.town ?? "";

    setStreet(streetValue);
    setHouseNumber(houseNumberValue);
    setApartmentNumber("");
    setZipCode(zipCodeValue);
    setCountry(countryValue);
    setTown(townValue);
    setMapMarker({
      coordinate: {
        latitude: selectedAddressOption.value.latitude,
        longitude: selectedAddressOption.value.longitude,
      },
      isDraggable: true,
    });

    validateStreet(streetValue);
    validateHouseNumber(houseNumberValue);
    validateZipCode(zipCodeValue);
    validateCountry(countryValue);
    validateTown(townValue);

    refreshMapFit();
  }, [selectedAddressOption]);

  const fetchAddressSelectOptions = (addressSearchQuery: string) => {
    setIsAddressFetching(true);

    fetchResolvedAddress(addressSearchQuery)
      .then((response) => {
        const selectOptions =
          billingAddressAddFactory.createAddressSelectOptions(response);

        setAddressSelectOptions(selectOptions);
      })
      .finally(() => {
        setIsAddressFetching(false);
      });
  };

  const debounceFetchAddressSelectOptions = useCallback(
    debounce((addressSearchQuery: string) => {
      fetchAddressSelectOptions(addressSearchQuery);
    }, 300),
    []
  );

  useEffect(() => {
    if (!addressSearchQuery) {
      return;
    }

    debounceFetchAddressSelectOptions(addressSearchQuery);
  }, [addressSearchQuery]);

  const fetchResolvedAddress = (
    addressSearchQuery: string
  ): Promise<SearchAddressResponse> => {
    return searchAddressApiService.searchByQuery(addressSearchQuery);
  };

  const updateSelectedAddressCandidature = () => {
    if (!selectedCandidatureMapMarker) {
      setSelectedAddressCandidature(null);
      return;
    }

    const searchQuery = `${selectedCandidatureMapMarker.coordinate.latitude}, ${selectedCandidatureMapMarker.coordinate.longitude}`;
    fetchResolvedAddress(searchQuery).then((response) => {
      if (!response.length) {
        console.error(`No location found`);
        return;
      }

      const foundLocation = response[0];

      const address = billingAddressAddFactory.createAddress(foundLocation);

      setSelectedAddressCandidature(address);
    });
  };

  useEffect(() => {
    updateSelectedAddressCandidature();
  }, [selectedCandidatureMapMarker]);

  const isMessageBoxVisible = !!selectedAddressCandidature;

  const mapMessageBox = selectedAddressCandidature ? (
    <div style={{ display: `flex`, maxHeight: 40 }}>
      <div
        style={{
          fontSize: 12,
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: `-webkit-box`,
          WebkitLineClamp: 2,
          WebkitBoxOrient: `vertical`,
        }}
        title={selectedAddressCandidature.displayName}
      >
        {selectedAddressCandidature.displayName}
      </div>
      <div>
        <ButtonComponent onClick={acceptAddressCandidature} type="success">
          <FontAwesomeIcon icon={faCheck} />
        </ButtonComponent>
      </div>
    </div>
  ) : null;

  const concatAddress = (): string => {
    const descriptionFormatted = description !== "" ? `${description}, ` : "";
    let buildingWithApartment = houseNumber;
    if (apartmentNumber) {
      buildingWithApartment = `${houseNumber}/${apartmentNumber}`;
    }
    return `${descriptionFormatted}${town} ${zipCode}, ${street} ${buildingWithApartment}, ${country}`;
  };

  const addNewAddress = () => {
    if (!mapMarker) {
      return;
    }

    const newAddress: BillingAddress = {
      displayName: concatAddress(),
      latitude: mapMarker.coordinate.latitude,
      longitude: mapMarker.coordinate.longitude,
    };

    props.onSave(newAddress);
  };

  const onSubmitButtonClick = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    addNewAddress();
  };

  const isAddressDetailsFormFieldEnabled = !!selectedAddressOption || mapMarker;

  return (
    <ModalComponent
      isOpen={props.addAddressIsOpen}
      onClose={props.closeModal}
      header={{ title: translations.headingTitle }}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          idForTesting="user-data-change-password-submit-button"
        >
          {translations.form.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={props.closeModal}
          type="brand"
          title={translations.form.rejectButtonText}
        >
          {translations.form.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <FocusTrap
        active={props.addAddressIsOpen}
        focusTrapOptions={{
          onActivate: () => {
            alert("yay!");
          },
          preventScroll: true,
        }}
      >
        <Row>
          <Column xl={6}>
            <small>{translations.form.address.searchAddressTip}</small>
            <FormFieldComponent
              label={translations.form.address.searchAddressLabel}
            >
              <SingleSelectComponent
                placeholder={
                  translations.form.address.searchAddressSelectPlaceholder
                }
                options={addressSelectOptions}
                value={selectedAddressOption}
                isLoading={isAddressFetching}
                inputValue={addressSearchQuery}
                onInputChange={setAddressSearchQuery}
                isClearable
                onChange={(option) => setSelectedAddressOption(option)}
                isSearchable
                menuPlacement="bottom"
                filterFunction={() => true}
                idForTesting="address-search-select"
                autoFocus
                menuPosition="fixed"
              />
            </FormFieldComponent>

            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.address.streetLabel}
                  isRequired
                  errorMessage={validationResults.street.errorMessage}
                >
                  <InputComponent
                    value={street}
                    onChange={setStreet}
                    placeholder={
                      translations.form.address.streetInputPlaceholder
                    }
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!validationResults.street.errorMessage}
                    onBlur={validateStreet}
                    idForTesting="street-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={3}>
                <FormFieldComponent
                  label={translations.form.address.houseNumberLabel}
                  isRequired
                  errorMessage={validationResults.houseNumber.errorMessage}
                >
                  <InputComponent
                    value={houseNumber}
                    onChange={setHouseNumber}
                    placeholder={
                      translations.form.address.houseNumberInputPlaceholder
                    }
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!validationResults.houseNumber.errorMessage}
                    onBlur={validateHouseNumber}
                    idForTesting="house-number-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={3}>
                <FormFieldComponent
                  label={translations.form.address.apartmentNumberLabel}
                  errorMessage={validationResults.apartmentNumber.errorMessage}
                >
                  <InputComponent
                    value={apartmentNumber}
                    onChange={setApartmentNumber}
                    placeholder={
                      translations.form.address.apartmentNumberInputPlaceholder
                    }
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!validationResults.apartmentNumber.errorMessage}
                    onBlur={validateApartmentNumber}
                    idForTesting="apartment-number-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.address.townLabel}
                  classNames={{ root: "mt-0" }}
                  isRequired
                  errorMessage={validationResults.town.errorMessage}
                >
                  <InputComponent
                    value={town}
                    onChange={setTown}
                    placeholder={translations.form.address.townInputPlaceholder}
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!validationResults.town.errorMessage}
                    onBlur={validateTown}
                    idForTesting="town-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.address.zipCodeLabel}
                  classNames={{ root: "mt-0" }}
                  isRequired
                  errorMessage={validationResults.zipCode.errorMessage}
                >
                  <InputComponent
                    value={zipCode}
                    onChange={setZipCode}
                    placeholder={
                      translations.form.address.zipCodeInputPlaceholder
                    }
                    isDisabled={!isAddressDetailsFormFieldEnabled}
                    hasError={!!validationResults.zipCode.errorMessage}
                    onBlur={validateZipCode}
                    idForTesting="zip-code-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <FormFieldComponent
              label={translations.form.address.countryLabel}
              classNames={{ root: "mt-0" }}
              isRequired
              errorMessage={validationResults.country.errorMessage}
            >
              <InputComponent
                value={country}
                onChange={setCountry}
                placeholder={translations.form.address.countryInputPlaceholder}
                isDisabled={!isAddressDetailsFormFieldEnabled}
                hasError={!!validationResults.country.errorMessage}
                onBlur={validateCountry}
                idForTesting="country-input"
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.form.address.descriptionLabel}
              classNames={{ root: "mt-0" }}
              errorMessage={validationResults.description.errorMessage}
            >
              <InputComponent
                value={description}
                onChange={setDescription}
                placeholder={
                  translations.form.address.descriptionInputPlaceholder
                }
                isDisabled={!isAddressDetailsFormFieldEnabled}
                hasError={!!validationResults.description.errorMessage}
                onBlur={validateDescription}
                idForTesting="description-input"
              />
            </FormFieldComponent>
          </Column>
          <Column xl={6}>
            <FormFieldComponent
              label={translations.form.address.mapLabel}
              classNames={{
                root: "mt-0",
              }}
              errorMessage={validationResults.map.errorMessage}
            >
              <div
                style={{
                  width: "100%",
                  height: 300,
                  position: "relative",
                }}
              >
                <MapComponent
                  markers={mapMarker ? [mapMarker] : []}
                  onMarkerCoordinateChange={onMarkerCoordinateChange}
                  temporaryMarkers={
                    selectedCandidatureMapMarker
                      ? [selectedCandidatureMapMarker]
                      : []
                  }
                  onTemporaryMarkerCoordinateChange={
                    onTemporaryMarkerCoordinateChange
                  }
                  onMapLongClick={handleLongMapClick}
                  autoFit
                  refreshFitFlag={refreshMapFitFlag}
                  messageBox={mapMessageBox}
                  isMessageBoxVisible={isMessageBoxVisible}
                />
              </div>
            </FormFieldComponent>
          </Column>
        </Row>
      </FocusTrap>
    </ModalComponent>
  );
};

export default BillingAddressAddComponent;
