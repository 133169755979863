import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import {
  MileageUpdateErrorType,
  MileageUpdateMileageHasDelegationError,
  MileageUpdateNoValidMileageContractFoundError,
} from "./mileage-update-error";

const createMileageHasDelegationError =
  (): MileageUpdateMileageHasDelegationError => {
    const translation =
      mileageTranslationsHelper.getMileageUpdateTranslations().error;

    return {
      message: translation.mileageHasDelegation,
      params: {},
      type: MileageUpdateErrorType.MILEAGE_HAS_DELEGATION,
    };
  };

const createNoValidMileageContractFoundError =
  (): MileageUpdateNoValidMileageContractFoundError => {
    const translation =
      mileageTranslationsHelper.getMileageUpdateTranslations().error;

    return {
      message: translation.mileageNoValidContractFound,
      params: {},
      type: MileageUpdateErrorType.NO_VALID_MILEAGE_CONTRACT_FOUND,
    };
  };

const mileageUpdateErrorFactory = {
  createMileageHasDelegationError,
  createNoValidMileageContractFoundError,
};

export default mileageUpdateErrorFactory;
