import SignInRequest, { SignInRequestBody } from "./sign-in.request";

const createBody = (request: SignInRequest): SignInRequestBody => {
  return {
    username: request.username,
    password: request.password,
    grant_type: "password",
  };
};

const signInRequestFactory = {
  createBody,
};

export default signInRequestFactory;
