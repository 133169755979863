import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import TaxiAddComponent from "../../add/taxi-add-component";
import TaxiCargoContractAddComponent from "../../cargo-contract/add/taxi-cargo-contract-add.component";
import TaxiCargoContractEditComponent from "../../cargo-contract/edit/taxi-cargo-contract-edit.component";
import TaxiCargoContractListingComponent from "../../cargo-contract/listing/taxi-cargo-contract-listing.component";
import TaxiFleetPartnerAddComponent from "../../fleet-partner/add/taxi-fleet-partner-add.component";
import TaxiFleetPartnerEditComponent from "../../fleet-partner/edit/taxi-fleet-partner-edit.component";
import TaxiFleetPartnerListingComponent from "../../fleet-partner/listing/taxi-fleet-partner-listing.component";
import TaxiListingComponent from "../../listing/taxi-listing.component";
import TaxiTaxiContractAddComponent from "../../taxi-contract/add/taxi-taxi-contract-add.component";
import TaxiTaxiContractEditComponent from "../../taxi-contract/edit/taxi-taxi-contract-edit.component";
import TaxiTaxiContractListingComponent from "../../taxi-contract/listing/taxi-taxi-contract-listing.component";
import taxiRoutesUrls from "./taxi-routes-urls";

const taxiRoutesDefinition: RouteItemDefinition[] = [
  {
    path: taxiRoutesUrls.add,
    component: <TaxiAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: taxiRoutesUrls.listing,
    component: <TaxiListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: taxiRoutesUrls.cargoContractAdd,
    component: <TaxiCargoContractAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: taxiRoutesUrls.cargoContractEdit,
    component: <TaxiCargoContractEditComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: taxiRoutesUrls.taxiContractAdd,
    component: <TaxiTaxiContractAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: taxiRoutesUrls.cargoContractListing,
    component: <TaxiCargoContractListingComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: taxiRoutesUrls.taxiContractListing,
    component: <TaxiTaxiContractListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: taxiRoutesUrls.taxiContractEdit,
    component: <TaxiTaxiContractEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: taxiRoutesUrls.taxiFleetPartnerListing,
    component: <TaxiFleetPartnerListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: taxiRoutesUrls.taxiFleetPartnerAdd,
    component: <TaxiFleetPartnerAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: taxiRoutesUrls.taxiFleetPartnerEdit,
    component: <TaxiFleetPartnerEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
];

export default taxiRoutesDefinition;
