export enum DealerListRequestOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

export enum DealerListRequestStatusCriteria {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  ALL = "ALL",
}

export type DealerListRequestQueryParams = {
  taxiCorporation: string | undefined;
  taxiCorporationId: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  username: string | undefined;
  email: string | undefined;
  statusCriteria: DealerListRequestStatusCriteria | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
  order: DealerListRequestOrder | undefined;
  offset: number | undefined;
  limit: number | undefined;
};

type DealerListRequest = {
  taxiCorporation?: string;
  taxiCorporationId?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  statusCriteria?: DealerListRequestStatusCriteria;
  order?: DealerListRequestOrder;
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
};

export default DealerListRequest;
