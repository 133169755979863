import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import { TaxiTaxiContractListingRouteQueryParams } from "../../../common/routes/types/taxi-taxi-contract-listing-route-params";
import TaxiTaxiContractListingFilter from "../common/types/taxi-taxi-contract-listing-filter";
import TaxiTaxiContractListingSortKey from "../common/types/taxi-taxi-contract-listing-sort-key";
import taxiTaxiContractListingFiltersService from "./taxi-taxi-contract-listing-filters.service";

const useTaxiTaxiContractListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiTaxiContractListingRouteQueryParams>();

  const getFilters = (): TaxiTaxiContractListingFilter[] => {
    return taxiTaxiContractListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return taxiTaxiContractListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return taxiTaxiContractListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): TaxiTaxiContractListingSortKey | undefined => {
    return taxiTaxiContractListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: TaxiTaxiContractListingFilter[],
    sortKey: TaxiTaxiContractListingSortKey,
    page: number,
    pageSize: number,
    taxiCorporationUuid: string
  ): void => {
    const routeQueryParams =
      taxiTaxiContractListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize,
        taxiCorporationUuid
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useTaxiTaxiContractListingFiltersDao;
