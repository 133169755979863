import useAsyncData from "../../../hooks/use-async-data";
import DriverPlanCandidaturePreviewLoadParams from "./driver-plan-candidature-preview-load-params";
import DriverPlanCandidaturePreview from "./driver-plan-candidature-preview";
import driverPlanService from "../driver-plan.service";

const useDriverPlanCandidaturePreview = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<DriverPlanCandidaturePreview | null>(null);

  const load = async (
    params: DriverPlanCandidaturePreviewLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const driverPlanCanidaturePreview = await driverPlanService.getPreview(
        params,
        signal
      );

      setData(driverPlanCanidaturePreview);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useDriverPlanCandidaturePreview;
