import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import DriverContractListingByTaxiSortKey from "../types/driver-contract-listing-by-taxi-sort-key";
import DriverContractListingByTaxiSortSelectOption from "../types/driver-contract-listing-by-taxi-sort-select-option";

const getSelectOptions = (): DriverContractListingByTaxiSortSelectOption[] => {
  const translations =
    userTranslationsHelper.getDriverContractListingTranslations().sort;

  return [
    {
      label: translations.billingModelAscOptionLabel,
      value: DriverContractListingByTaxiSortKey.BILLING_MODEL_ASC,
    },
    {
      label: translations.billingModelDescOptionLabel,
      value: DriverContractListingByTaxiSortKey.BILLING_MODEL_DESC,
    },
    {
      label: translations.distanceRateAscOptionLabel,
      value: DriverContractListingByTaxiSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.distanceRateDescOptionLabel,
      value: DriverContractListingByTaxiSortKey.DISTANCE_RATE_DESC,
    },
    {
      label: translations.freeStoppingPeriodAscOptionLabel,
      value: DriverContractListingByTaxiSortKey.FREE_STOPPING_PERIOD_ASC,
    },
    {
      label: translations.freeStoppingPeriodDescOptionLabel,
      value: DriverContractListingByTaxiSortKey.FREE_STOPPING_PERIOD_DESC,
    },
    {
      label: translations.stopRateAscOptionLabel,
      value: DriverContractListingByTaxiSortKey.STOPPING_RATE_ASC,
    },
    {
      label: translations.stopRateDescOptionLabel,
      value: DriverContractListingByTaxiSortKey.STOPPING_RATE_DESC,
    },
    {
      label: translations.periodOfValidityFromAscOptionLabel,
      value: DriverContractListingByTaxiSortKey.PERIOD_OF_VALIDITY_FROM_ASC,
    },
    {
      label: translations.periodOfValidityFromDescOptionLabel,
      value: DriverContractListingByTaxiSortKey.PERIOD_OF_VALIDITY_FROM_DESC,
    },
    {
      label: translations.periodOfValidityToAscOptionLabel,
      value: DriverContractListingByTaxiSortKey.PERIOD_OF_VALIDITY_TO_ASC,
    },
    {
      label: translations.periodOfValidityToDescOptionLabel,
      value: DriverContractListingByTaxiSortKey.PERIOD_OF_VALIDITY_TO_DESC,
    },
  ];
};

const driverContractListingByTaxiSortHelper = {
  getSelectOptions,
};

export default driverContractListingByTaxiSortHelper;
