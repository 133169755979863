import { FC, useMemo } from "react";
import CardComponent from "../card/card.component";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";

type ErrorProps = {
  message?: string;
};

const ErrorComponent: FC<ErrorProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const translations = useMemo(() => {
    return appTranslationsHelper.getComponentTranslations().error;
  }, [selectedAppLanguage]);

  return (
    <CardComponent classNames={{ root: "error", content: "error__content" }}>
      {props.message ?? translations.messageText}
    </CardComponent>
  );
};

export default ErrorComponent;
