import appTranslationsHelper from "../../../languages/app-translations.helper";
import UserCitizenship from "../../types/user-citizenship";
import UserCitizenshipSelectOption from "../../types/user-citizenship-select-option";
import UserFormOfEmployment from "../../types/user-form-of-employment";
import UserFormOfEmploymentSelectOption from "../../types/user-form-of-employment-select-option";
import UserLanguage from "../../types/user-language";
import UserLanguageSelectOption from "../../types/user-language-select-option";
import UserStatus from "../../types/user-status";
import UserVehicleOwnership from "../../types/user-vehicle-ownership-form";
import UserVehicleOwnershipSelectOption from "../../types/user-vehicle-ownership-form-select-option";

const getCitizenshipSelectOptions = (): UserCitizenshipSelectOption[] => {
  const translations =
    appTranslationsHelper.getUserCommonTranslations().citizenship;

  return [
    {
      label: translations.BY,
      value: UserCitizenship.BY,
    },
    {
      label: translations.CZ,
      value: UserCitizenship.CZ,
    },
    {
      label: translations.DE,
      value: UserCitizenship.DE,
    },
    {
      label: translations.LT,
      value: UserCitizenship.LT,
    },
    {
      label: translations.PL,
      value: UserCitizenship.PL,
    },
    {
      label: translations.RU,
      value: UserCitizenship.RU,
    },
    {
      label: translations.SK,
      value: UserCitizenship.SK,
    },
    {
      label: translations.UA,
      value: UserCitizenship.UA,
    },
  ];
};

const getLanguageSelectOptions = (): UserLanguageSelectOption[] => {
  const translations =
    appTranslationsHelper.getUserCommonTranslations().languages;

  return [
    {
      label: translations.BY,
      value: UserLanguage.BY,
    },
    {
      label: translations.CZ,
      value: UserLanguage.CZ,
    },
    {
      label: translations.DE,
      value: UserLanguage.DE,
    },
    {
      label: translations.FR,
      value: UserLanguage.FR,
    },
    {
      label: translations.ES,
      value: UserLanguage.ES,
    },
    {
      label: translations.EN,
      value: UserLanguage.EN,
    },
    {
      label: translations.LT,
      value: UserLanguage.LT,
    },
    {
      label: translations.PL,
      value: UserLanguage.PL,
    },
    {
      label: translations.RU,
      value: UserLanguage.RU,
    },
    {
      label: translations.SK,
      value: UserLanguage.SK,
    },
    {
      label: translations.UA,
      value: UserLanguage.UA,
    },
  ];
};

const getFormOfEmploymentSelectOptions =
  (): UserFormOfEmploymentSelectOption[] => {
    const translations =
      appTranslationsHelper.getUserCommonTranslations().formOfEmployment;

    return [
      {
        label: translations.B2B,
        value: UserFormOfEmployment.B2B,
      },
      {
        label: translations.EMPLOYMENT,
        value: UserFormOfEmployment.EMPLOYMENT,
      },
    ];
  };

const getVehicleOwnershipText = (ownership: UserVehicleOwnership): string => {
  const translations =
    appTranslationsHelper.getUserCommonTranslations().vehicleOwnershipForm;

  const options: { text: string; ownership: UserVehicleOwnership }[] = [
    {
      text: translations.BUSINESS,
      ownership: UserVehicleOwnership.BUSINESS,
    },
    {
      text: translations.OWNER,
      ownership: UserVehicleOwnership.OWNER,
    },
  ];

  return options.find((option) => option.ownership === ownership)?.text ?? "";
};

const getVehicleOwnershipSelectOptions =
  (): UserVehicleOwnershipSelectOption[] => {
    return [
      {
        label: getVehicleOwnershipText(UserVehicleOwnership.OWNER),
        value: UserVehicleOwnership.OWNER,
      },
      {
        label: getVehicleOwnershipText(UserVehicleOwnership.BUSINESS),
        value: UserVehicleOwnership.BUSINESS,
      },
    ];
  };

const getActivityStatusText = (status: UserStatus): string => {
  const translations =
    appTranslationsHelper.getUserCommonTranslations().activityStatus;

  const options: { text: string; status: UserStatus }[] = [
    {
      text: translations.ACTIVE,
      status: UserStatus.ACTIVE,
    },
    {
      text: translations.DELETED,
      status: UserStatus.DELETED,
    },
    {
      text: translations.INACTIVE,
      status: UserStatus.INACTIVE,
    },
  ];

  return options.find((option) => option.status === status)?.text ?? "";
};

const userHelper = {
  getCitizenshipSelectOptions,
  getLanguageSelectOptions,
  getFormOfEmploymentSelectOptions,
  getVehicleOwnershipSelectOptions,
  getVehicleOwnershipText,
  getActivityStatusText,
};

export default userHelper;
