import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../../context/app.context";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import DispatcherAddViewBasicProps from "../common/dispatcher-add-view-basic-props";
import dispatcherAddByCargoFormHelper from "./common/dispatcher-add-by-cargo-form.helper";
import DispatcherAddByCargoFormData from "./common/types/dispatcher-add-by-cargo-form-data";
import useAbort from "../../../../../common/hooks/use-abort";
import useForm from "../../../../../common/components/form/use-form";
import DispatcherAddParams from "../../../../../common/services/dispatcher/add/dispatcher-add-params";
import dispatcherService from "../../../../../common/services/dispatcher/dispatcher.service";
import dispatcherAddByCargoParamsFactory from "./common/dispatcher-add-by-cargo-params.factory";
import useCargoCompanyDispatchList from "../../../../../common/services/cargo-company/dispatch/list/use-cargo-company-dispatch-list";
import CargoCompanyDispatchSelectComponent from "../../../../../common/components/form/select/cargo-company-dispatch/cargo-company-dispatch-single-select.component";

type DispatcherAddByCargoProps = DispatcherAddViewBasicProps;

const DispatcherAddByCargoComponent: FC<DispatcherAddByCargoProps> = (
  props
) => {
  const [isDispatcherAddPending, setIsDispatcherAddPending] = useState(false);

  const { user } = useAppContext();
  const dispatcherAddAbort = useAbort();
  const cargoCompanyDispatchList = useCargoCompanyDispatchList();
  const cargoCompanyDispatchListAbort = useAbort();
  const navigate = useNavigate();

  const form = useForm<DispatcherAddByCargoFormData>({
    emptyValues: dispatcherAddByCargoFormHelper.getDefaultFormData(),
    validationDefinition:
      dispatcherAddByCargoFormHelper.getValidationDefinition(),
  });

  const translations = userTranslationsHelper.getDispatcherAddTranslations();
  const cargoCompanyUuid =
    user?.aspects.cargoOfficer?.cargoCompanyUuid ||
    user?.aspects.dispatcher?.cargoCompanyUuid!;

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompanyDispatchList.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyDispatchListAbort.signal
    );

    return () => cargoCompanyDispatchListAbort.revoke();
  }, [cargoCompanyUuid]);

  const onCargoOfficerAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigateToListing();
  };

  const onCargoOfficerAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const addDispatcher = async () => {
    setIsDispatcherAddPending(true);

    const params: DispatcherAddParams =
      dispatcherAddByCargoParamsFactory.create(form.values);

    try {
      await dispatcherService.add(params, dispatcherAddAbort.signal);

      onCargoOfficerAddSuccess();
    } catch {
      onCargoOfficerAddFailure();
    } finally {
      setIsDispatcherAddPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid: boolean = await form.validateAll();

    if (!isFormValid) return;

    addDispatcher();
  };

  const navigateToListing = () => {
    const dispatcherListingRoute = userRoutesHelper.getDispatcherListingRoute();

    navigate(dispatcherListingRoute);
  };

  const isDispatcherSelectDisabled = cargoCompanyDispatchList.isError;
  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.dispatchLabel}
                  classNames={{ root: `mt-0` }}
                  isRequired
                  errorMessage={
                    form.validationResults.dispatchUuid.errorMessage
                  }
                >
                  <CargoCompanyDispatchSelectComponent
                    idForTesting={`dispatch`}
                    onChange={(value) => {
                      form.setValue("dispatchUuid", value?.uuid ?? "");
                    }}
                    onBlur={() =>
                      cargoCompanyUuid && form.validate("dispatchUuid")
                    }
                    placeholder={translations.form.dispatchPlaceholder}
                    selectedDispatcherUuid={form.values.dispatchUuid}
                    dispatchList={cargoCompanyDispatchList?.data?.data}
                    isError={!!form.validationResults.dispatchUuid.errorMessage}
                    isLoading={cargoCompanyDispatchList.isLoading}
                    isDisabled={isDispatcherSelectDisabled}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <></>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.emailLabel}
                  isRequired
                  errorMessage={form.validationResults.email.errorMessage}
                  classNames={{ root: "mt-0" }}
                >
                  <InputComponent
                    placeholder={translations.form.emailPlaceholder}
                    value={form.values.email}
                    onChange={(value) => form.setValue("email", value)}
                    onBlur={() => form.validate("email")}
                    hasError={!!form.validationResults.email.errorMessage}
                    idForTesting={`email`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.usernameLabel}
                  isRequired
                  errorMessage={form.validationResults.username.errorMessage}
                  classNames={{ root: "mt-0" }}
                >
                  <InputComponent
                    placeholder={translations.form.usernamePlaceholder}
                    value={form.values.username}
                    onChange={(value) => form.setValue("username", value)}
                    onBlur={() => form.validate("username")}
                    hasError={!!form.validationResults.username.errorMessage}
                    idForTesting={`username`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.firstNameLabel}
                  isRequired
                  errorMessage={form.validationResults.firstName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.firstNamePlaceholder}
                    value={form.values.firstName}
                    onChange={(value) => form.setValue("firstName", value)}
                    onBlur={() => form.validate("firstName")}
                    hasError={!!form.validationResults.firstName.errorMessage}
                    idForTesting={`first-name`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.lastNameLabel}
                  isRequired
                  errorMessage={form.validationResults.lastName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.lastNamePlaceholder}
                    value={form.values.lastName}
                    onChange={(value) => form.setValue("lastName", value)}
                    onBlur={() => form.validate("lastName")}
                    hasError={!!form.validationResults.lastName.errorMessage}
                    idForTesting={`last-name`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.phoneNumberLabel}
                  isRequired
                  errorMessage={form.validationResults.phoneNumber.errorMessage}
                >
                  <PhoneNumberInputComponent
                    placeholder={translations.form.phoneNumberPlaceholder}
                    phoneNumber={form.values.phoneNumber}
                    onChange={(value) => {
                      form.setValue("phoneNumber", value);
                    }}
                    onBlur={() => form.validate("phoneNumber")}
                    hasError={!!form.validationResults.phoneNumber.errorMessage}
                    idForTesting={`phone-number`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.alternativePhoneNumberLabel}
                  errorMessage={
                    form.validationResults.alternativePhoneNumber.errorMessage
                  }
                >
                  <PhoneNumberInputComponent
                    placeholder={
                      translations.form.alternativePhoneNumberPlaceholder
                    }
                    phoneNumber={form.values.alternativePhoneNumber}
                    onChange={(value) => {
                      form.setValue("alternativePhoneNumber", value);
                    }}
                    onBlur={() => form.validate("alternativePhoneNumber")}
                    hasError={
                      !!form.validationResults.alternativePhoneNumber
                        .errorMessage
                    }
                    idForTesting={`alternative-phone-number`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.passwordLabel}
                  isRequired
                  errorMessage={form.validationResults.password.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.passwordPlaceholder}
                    value={form.values.password}
                    type="password"
                    onChange={(value) => form.setValue("password", value)}
                    onBlur={() => form.validate("password")}
                    hasError={!!form.validationResults.password.errorMessage}
                    idForTesting={`password`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.passwordRepeatLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.passwordRepeat.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={translations.form.passwordRepeatPlaceholder}
                    value={form.values.passwordRepeat}
                    type="password"
                    onChange={(value) => form.setValue("passwordRepeat", value)}
                    onBlur={() => form.validate("passwordRepeat")}
                    hasError={
                      !!form.validationResults.passwordRepeat.errorMessage
                    }
                    idForTesting={`password-repeat`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isDisabled={isDispatcherAddPending}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default DispatcherAddByCargoComponent;
