import DealerAddRequest from "../../../api/raily/dealer/add/dealer-add.request";
import DealerAddParams from "./dealer-add-params";

const create = (params: DealerAddParams): DealerAddRequest => {
  return {
    taxiCorporationId: params.taxiCorporationUuid,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.phoneNumber.number,
    mobilePrimaryPrefix: params.phoneNumber.dialingCode,
    mobileSecondary: params.alternativePhoneNumber.number || null,
    mobileSecondaryPrefix: params.alternativePhoneNumber.dialingCode || null,
    password: params.password,
    username: params.username,
  };
};

const dealerAddRequestFactory = {
  create,
};

export default dealerAddRequestFactory;
