import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useAbort from "../../../../common/hooks/use-abort";
import usePassengerListingUserPermissions from "./common/user-permissions/use-passenger-listing-user-permissions";
import usePassengerListingFiltersDao from "./filter/use-passenger-listing-filters.dao";
import PassengerListingFilter from "./common/types/passenger-listing-filter";
import PassengerListingSortKey from "./common/types/passenger-listing-sort-key";
import PassengerListingItem from "./common/types/passenger-listing-item";
import passengerListingItemFactory from "./common/passenger-listing-item.factory";
import usePagination from "../../../../common/hooks/use-pagination";
import passengerListingLoadParamsFactory from "./common/passenger-listing-load-params.factory";
import CardComponent from "../../../../common/components/card/card.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import PassengerListingFilterComponent from "./filter/passenger-listing-filter.component";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import PassengerListingTableComponent from "./table/passenger-listing-table.component";
import UserDeleteUserData from "../../common/delete/common/types/user-delete-user-data";
import UserDeleteComponent from "../../common/delete/user-delete.component";
import usePassengerList from "../../../../common/services/passenger/list/use-passenger-list";
import PassengerListLoadParams from "../../../../common/services/passenger/list/passenger-list-load-params";

type PassengerListingProps = {};

const PassengerListingComponent: FC<PassengerListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();
  const listingItemsAbort = useAbort();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();
  const userPermissions = usePassengerListingUserPermissions();
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();
  useDocumentTitle(documentTitleTranslations.userPassengerListing);

  const passengerList = usePassengerList();
  const filtersDao = usePassengerListingFiltersDao();

  const [filters, setFilters] = useState<PassengerListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const [sortKey, setSortKey] = useState<PassengerListingSortKey>(
    () => filtersDao.getSortKey() ?? PassengerListingSortKey.FIRST_NAME_ASC
  );

  const listingItems: PassengerListingItem[] = useMemo(() => {
    return passengerListingItemFactory.createListingItems(
      passengerList.data.data
    );
  }, [passengerList.data.data]);

  const [userSelectedToDelete, setUserSelectedToDelete] =
    useState<UserDeleteUserData | null>(null);

  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getPassengerListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const translations = userTranslationsHelper.getPassengerListingTranslations();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: passengerList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const openDeleteUserModal = () => {
    setIsDeleteUserModalOpen(true);
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onSelectUserToDelete = (userData: UserDeleteUserData) => {
    setUserSelectedToDelete(userData);
    openDeleteUserModal();
  };

  const onDeleteUserSuccess = () => {
    closeDeleteUserModal();
    loadListingItems();
  };

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasAccessToAddPassenger
        ? PassengerAddLinkButton
        : undefined,
    ];
  };

  const PassengerAddLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        to={userRoutesHelper.getPassengerAddRoute()}
        type="primary"
        title={translations.header.addNewPassengerLinkTitle}
        idForTesting="passenger-listing-add-button"
      >
        {translations.header.addNewPassengerLinkLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const headingActions = createHeadingActions();

  const loadListingItems = async () => {
    const params: PassengerListLoadParams =
      passengerListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey
      );

    passengerList.load(params, listingItemsAbort.signal);
  };

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <PassengerListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <PassengerListingTableComponent
          listingItems={listingItems}
          isError={passengerList.isError}
          isLoading={passengerList.isLoading}
          onSelectUserToDelete={onSelectUserToDelete}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={passengerList.data.totalCount}
        />
      </CardComponent>
      <UserDeleteComponent
        isOpen={isDeleteUserModalOpen}
        onClose={closeDeleteUserModal}
        userData={userSelectedToDelete}
        onSuccess={onDeleteUserSuccess}
      />
    </>
  );
};

export default PassengerListingComponent;
