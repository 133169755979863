import cargoCompanyMileageContractApiService from "../../../api/raily/cargo-company/mileage-contract/cargo-company-mileage-contract-api.service";
import dataLoadErrorFactory from "../../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../../utils/http/http.error";
import httpHelper from "../../../utils/http/http.helper";
import CargoCompanyMileageContractDetails from "./details/cargo-company-mileage-contract-details";
import CargoCompanyMileageContractDetailsError from "./details/cargo-company-mileage-contract-details-error";
import CargoCompanyMileageContractDetailsLoadParams from "./details/cargo-company-mileage-contract-details-load-params";
import cargoCompanyMileageContractDetailsRequestFactory from "./details/cargo-company-mileage-contract-details-request.factory";
import cargoCompanyMileageContractDetailsFactory from "./details/cargo-company-mileage-contract-details.factory";

const handleDetailsError = (
  error: HttpError | CargoCompanyMileageContractDetailsError
): CargoCompanyMileageContractDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  loadParams: CargoCompanyMileageContractDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<CargoCompanyMileageContractDetails> => {
  const request =
    cargoCompanyMileageContractDetailsRequestFactory.create(loadParams);

  try {
    const response = await cargoCompanyMileageContractApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoCompanyMileageContractDetailsFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | CargoCompanyMileageContractDetailsError;

    throw handleDetailsError(error);
  }
};

const cargoCompanyMileageContractService = {
  getDetails,
};

export default cargoCompanyMileageContractService;
