export enum MileageDownloadRequestTimezone {
  EUROPE_WARSAW = "Europe/Warsaw",
  UTC = "UTC",
}

export type MileageDownloadRequestSeparatorTypes = ";" | ",";

export type MileageDownloadRequestQueryParams = {
  separator?: MileageDownloadRequestSeparatorTypes;
  timezone?: MileageDownloadRequestTimezone;
  mileages: string;
};

type MileageDownloadRequest = {
  mileageUuids: string[];
  separator?: MileageDownloadRequestSeparatorTypes;
  timezone?: MileageDownloadRequestTimezone;
};

export default MileageDownloadRequest;
