import DataLoadError, {
  DataLoadDefaultErrors,
} from "../../../utils/data-load-error/data-load-error";

export enum UserDeleteErrorType {
  DRIVER_HAS_CANDIDATURE = "DRIVER_HAS_CANDIDATURE",
  DRIVER_HAS_ACTIVE_PLAN = "DRIVER_HAS_ACTIVE_PLAN",
}

export type UserDeleteDriverHasCandidatureError = DataLoadError<
  UserDeleteErrorType.DRIVER_HAS_CANDIDATURE,
  {}
>;

export type UserDeleteDriverHasActivePlanError = DataLoadError<
  UserDeleteErrorType.DRIVER_HAS_ACTIVE_PLAN,
  {}
>;

type UserDeleteError =
  | DataLoadDefaultErrors
  | UserDeleteDriverHasCandidatureError
  | UserDeleteDriverHasActivePlanError;

export default UserDeleteError;
