import { FC, useState } from "react";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import ModalComponent from "../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import InputComponent from "../../../../common/components/form/input/input.component";
import ComponentType from "../../../../common/types/component-type";
import { ActionOption } from "./types/action-option";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import BillingStatusUpdateValidationResult from "./types/billing-status-update-validation-result";
import billingsStatusUpdateValidationService from "./billings-status-update-validation.service";
import BillingStatusUpdateFormData from "./types/billing-status-update-form.data";

type BillingsCargoTaxiModalProps = {
  onClose: () => void;
  onSubmit: (comment: string) => void;
  actionOption: ActionOption | undefined;
  isModalVisible: boolean;
};

type ModalProps = {
  modalHeader: string;
  buttonStyle: string;
  buttonLabel: string;
};

const BillingsCargoTaxiModal: FC<BillingsCargoTaxiModalProps> = (props) => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiViewBillingsTranslations();

  const [formValidationResults, setFormValidationResults] =
    useState<BillingStatusUpdateValidationResult>({
      comment: formValidationService.defaultValidationResult,
    });

  const [formData, setFormData] = useState<BillingStatusUpdateFormData>({
    comment: "",
  });

  const setComment = (value: BillingStatusUpdateFormData["comment"]) => {
    setFormData((curr) => ({
      ...curr,
      comment: value,
    }));
  };

  const validateComment = (
    value: BillingStatusUpdateFormData["comment"] = formData.comment
  ) => {
    const validationResult =
      billingsStatusUpdateValidationService.validateComment(value);

    setFormValidationResults((curr) => ({
      ...curr,
      comment: validationResult,
    }));

    return validationResult.isValid;
  };

  const submitHandle = () => {
    const isValid = validateComment(formData.comment);

    if (!isValid) return;

    props.onSubmit(formData.comment);
  };

  const getButtonStyle = (): ModalProps | undefined => {
    switch (props.actionOption) {
      case ActionOption.ACCEPT: {
        return {
          buttonStyle: "success",
          buttonLabel: translations.acceptLabel,
          modalHeader: translations.acceptLabel,
        };
      }
      case ActionOption.REJECT: {
        return {
          buttonStyle: "danger",
          buttonLabel: translations.rejectLabel,
          modalHeader: translations.rejectLabel,
        };
      }
      case ActionOption.REOPEN:
        return {
          buttonStyle: "primary",
          buttonLabel: translations.reopenLabel,
          modalHeader: translations.reopenLabel,
        };
      case ActionOption.SEND_REQUEST_TO_REOPEN: {
        return {
          buttonStyle: "primary",
          buttonLabel: translations.sendRequestToReopenLabel,
          modalHeader: translations.sendRequestToReopenLabel,
        };
      }
    }
  };

  const style = getButtonStyle();

  return (
    <>
      <ModalComponent
        header={{ title: style?.modalHeader ?? "" }}
        isOpen={props.isModalVisible}
        onClose={() => props.onClose()}
        actions={[
          <ButtonComponent
            onClick={submitHandle}
            type={style?.buttonStyle as unknown as ComponentType}
          >
            {style?.buttonLabel}
          </ButtonComponent>,
          <ButtonComponent
            onClick={props.onClose}
            type="brand"
            title={translations.rejectButtonTitle}
          >
            {translations.rejectButtonText}
          </ButtonComponent>,
        ]}
      >
        <Row>
          <Column xl={5}>
            <FormFieldComponent
              label={translations.commentLabel}
              errorMessage={formValidationResults.comment.errorMessage}
            >
              <InputComponent
                value={formData.comment}
                onChange={setComment}
                placeholder={translations.commentPlaceholder}
                hasError={!!formValidationResults.comment.errorMessage}
                onBlur={validateComment}
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </ModalComponent>
    </>
  );
};
export default BillingsCargoTaxiModal;
