import { DivIcon } from "leaflet";
import OrderDetailsMapMarkerType from "./types/order-details-map-marker-type";
import isEqual from "lodash/isEqual";
import OrderDetailsRouteWaypoint from "./types/order-details-route-waypoint";
import MapMarker from "../../../../common/components/map/types/map-marker";
import mapMarkerIconFactory from "../../../../common/components/map/marker/map-marker-icon.factory";
import { ReactNode } from "react";

const createMapMarker = (
  route: OrderDetailsRouteWaypoint,
  type: OrderDetailsMapMarkerType,
  content?: ReactNode
): MapMarker => {
  const onboardingMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker onboarding",
    content: content,
  });

  const outboardingMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker outboarding",
    content: content,
  });

  const standardMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: content,
  });

  const weatheredMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker weathered",
    content: content,
  });

  const iconOptions: {
    type: OrderDetailsMapMarkerType;
    icon: DivIcon;
  }[] = [
    {
      type: OrderDetailsMapMarkerType.ONBOARDING,
      icon: onboardingMarkerIcon,
    },
    {
      type: OrderDetailsMapMarkerType.OUTBOARDING,
      icon: outboardingMarkerIcon,
    },
    {
      type: OrderDetailsMapMarkerType.STANDARD,
      icon: standardMarkerIcon,
    },
    {
      type: OrderDetailsMapMarkerType.WEATHERED,
      icon: weatheredMarkerIcon,
    },
  ];

  const icon: MapMarker["icon"] = iconOptions.find((icon) => icon.type === type)
    ?.icon!;

  const marker: MapMarker = {
    coordinate: {
      latitude: route.place.latitude,
      longitude: route.place.longitude,
    },
    title: route.place.displayName,
    tooltip: route.place.displayName,
    icon,
  };

  return marker;
};

const createMapMarkersForNotSelectedPassenger = (
  routeWaypoints: OrderDetailsRouteWaypoint[]
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  const flattenedWaypoints = routeWaypoints.flat();

  for (const waypoint of flattenedWaypoints) {
    const allWaypointsOnThisPlace = flattenedWaypoints.filter((w) =>
      isEqual(w.place, waypoint.place)
    );

    const signature = allWaypointsOnThisPlace
      .map((w) => w.markerPosition)
      .join("/");

    const newMapMarker = createMapMarker(
      waypoint,
      OrderDetailsMapMarkerType.STANDARD,
      signature
    );

    mapMarkers = [...mapMarkers, newMapMarker];
  }

  return mapMarkers;
};

const createOnboardingMapMarker = (waypoint: OrderDetailsRouteWaypoint) => {
  const signature = `${waypoint.markerPosition}`;

  const newMarker: MapMarker = createMapMarker(
    waypoint,
    OrderDetailsMapMarkerType.ONBOARDING,
    signature
  );

  return newMarker;
};

const createOutboardingMapMarker = (waypoint: OrderDetailsRouteWaypoint) => {
  const signature = `${waypoint.markerPosition}`;

  const newMarker: MapMarker = createMapMarker(
    waypoint,
    OrderDetailsMapMarkerType.OUTBOARDING,
    signature
  );

  return newMarker;
};

const createMapMarkersForSelectedPassenger = (
  routeWaypoints: OrderDetailsRouteWaypoint[],
  selectedPassengerUuid: string
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  const flattenedWaypoints = routeWaypoints.flat();

  for (const waypoint of flattenedWaypoints) {
    const isOnboardingRoute = waypoint.onboardingPassengersUuids.includes(
      selectedPassengerUuid
    );

    if (isOnboardingRoute) {
      const newMarker = createOnboardingMapMarker(waypoint);

      mapMarkers = [...mapMarkers, newMarker];

      continue;
    }

    const isOutboardingRoute = waypoint.outboardingPassengersUuids.includes(
      selectedPassengerUuid
    );

    if (isOutboardingRoute) {
      const newMarker = createOutboardingMapMarker(waypoint);

      mapMarkers = [...mapMarkers, newMarker];
      continue;
    }

    const allWaypointsOnThisPlace = flattenedWaypoints.filter((w) =>
      isEqual(w.place, waypoint.place)
    );

    const signature = allWaypointsOnThisPlace
      .map((w) => w.markerPosition)
      .join("/");

    const newMarker: MapMarker = createMapMarker(
      waypoint,
      OrderDetailsMapMarkerType.WEATHERED,
      signature
    );

    mapMarkers = [...mapMarkers, newMarker];
  }

  return mapMarkers;
};

const createMapMarkers = (
  routes: OrderDetailsRouteWaypoint[],
  selectedPassengerUuid?: string
): MapMarker[] => {
  if (!selectedPassengerUuid) {
    return createMapMarkersForNotSelectedPassenger(routes);
  }

  return createMapMarkersForSelectedPassenger(routes, selectedPassengerUuid);
};

const orderDetailsPlannedRouteMapMarkersFactory = {
  createMapMarkers,
};

export default orderDetailsPlannedRouteMapMarkersFactory;
