import DataLoadError, {
  DataLoadDefaultErrors,
} from "../../../utils/data-load-error/data-load-error";

export enum MileageUpdateErrorType {
  MILEAGE_HAS_DELEGATION = "MILEAGE_HAS_DELEGATION",
  NO_VALID_MILEAGE_CONTRACT_FOUND = "NO_VALID_MILEAGE_CONTRACT_FOUND",
}

export type MileageUpdateMileageHasDelegationError = DataLoadError<
  MileageUpdateErrorType.MILEAGE_HAS_DELEGATION,
  {}
>;

export type MileageUpdateNoValidMileageContractFoundError = DataLoadError<
  MileageUpdateErrorType.NO_VALID_MILEAGE_CONTRACT_FOUND,
  {}
>;

type MileageUpdateError =
  | DataLoadDefaultErrors
  | MileageUpdateMileageHasDelegationError
  | MileageUpdateNoValidMileageContractFoundError;

export default MileageUpdateError;
