import { FC, useEffect, useMemo, useState } from "react";
import RelatedCargoRailyBillingTableRow from "./types/related-cargo-raily-billing-table-row";
import relatedBillingsApiService from "../api/related-billings-api.service";
import relatedCargoRailyBillingsDataFactory from "./factory/related-cargo-raily-billings-data.factory";
import RelatedCargoRailyBillingData from "./types/related-cargo-raily-billing data";
import relatedCargoRailyBillingsHelper from "./related-cargo-raily-billings.helper";
import RelatedCargoRailyBillingsResponse from "../api/related-cargo-raily-billings.response";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CardComponent from "../../../../../../common/components/card/card.component";
import TableComponent from "../../../../../../common/components/table/table.component";
import dateService from "../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import billingRoutesHelper from "../../../../common/routes/billing-routes.helper";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";

type RelatedCargoRailyBillingsProps = {
  orderId: string | undefined;
};

const RelatedCargoRailyBillingsComponent: FC<RelatedCargoRailyBillingsProps> = (
  props
) => {
  const [billingsData, setBillingsData] =
    useState<RelatedCargoRailyBillingData[]>();
  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);

  useEffect(() => {
    if (props.orderId) {
      setIsBillingDataLoading(true);
      relatedBillingsApiService
        .fetchCargoRailyRelatedBillings(props.orderId)
        .then(handleBillingDataResponse)
        .finally(() => {
          setIsBillingDataLoading(false);
        });
    }
  }, [props.orderId]);

  const handleBillingDataResponse = (
    response: RelatedCargoRailyBillingsResponse
  ) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const onBillingDataFetchSuccess = (
    response: RelatedCargoRailyBillingsResponse
  ) => {
    const billingsData =
      relatedCargoRailyBillingsDataFactory.createBillingsData(response.data);

    setBillingsData(billingsData);
  };

  const onTableRowClick = () => {};

  const createTableRow = (
    billingData: RelatedCargoRailyBillingData
  ): RelatedCargoRailyBillingTableRow => {
    return {
      id: billingData.internalOrderId.toString(),
      onClick: onTableRowClick,
      value: {
        date: (
          <div title={dateService.format(billingData.date, "dd/mm/yyyy")}>
            {dateService.format(billingData.date, "dd/mm/yyyy")}
          </div>
        ),
        internalOrderId: (
          <div title={billingData.internalOrderId.toString()}>
            {billingData.internalOrderId}
          </div>
        ),
        companyName: (
          <div title={billingData.contractDetails?.companyName}>
            {billingData.contractDetails?.companyName}
          </div>
        ),
        model: (
          <div title={billingData.contractDetails?.model}>
            {billingData.contractDetails?.model}
          </div>
        ),
        rate: (
          <div title={billingData.contractDetails?.distanceRate.toString()}>
            {billingData.contractDetails?.distanceRate.toFixed(2)} PLN
          </div>
        ),
        distance: (
          <div title={billingData.distance.toString()}>
            {billingData.distance}
          </div>
        ),
        routeDistanceCost: (
          <div title={billingData.routeDistanceCost.toString()}>
            {billingData.routeDistanceCost.toFixed(2)} PLN
          </div>
        ),
        discount: (
          <div title={billingData?.discount.toString()}>
            {billingData.discount.toFixed(2)}%
          </div>
        ),
        discountTotal: (
          <div title={billingData.discountAmount.toString()}>
            {billingData.discountAmount.toFixed(2)} PLN
          </div>
        ),
        haltingCost: (
          <div title={billingData.haltingCost.toString()}>
            {billingData.haltingCost.toFixed(2)} PLN
          </div>
        ),
        highwayCost: (
          <div title={billingData.highwayCharge.toString()}>
            {billingData.highwayCharge.toFixed(2)} PLN
          </div>
        ),
        total: (
          <div title={billingData?.total.toString()}>
            {billingData.total.toFixed(2)} PLN
          </div>
        ),
        actions: (
          <TableLinkButtonComponent
            openInNewTab
            icon={faEdit}
            to={billingRoutesHelper.getCargoWithRailyAddRoute({
              orderUuid: billingData.uuid,
            })}
            type="primary"
            idForTesting={"cargo-with-raily-related-billing-button"}
            classNames={{
              root: "related_order_billings_table_edit_billing_icon",
            }}
          />
        ),
      },
    };
  };

  const tableColumns = relatedCargoRailyBillingsHelper.getTableColumns();

  const tableRows: RelatedCargoRailyBillingTableRow[] = useMemo(() => {
    if (billingsData) {
      return billingsData.map((listingItem) => createTableRow(listingItem));
    }

    return [];
  }, [billingsData]);

  const translations =
    billingsTranslationsHelper.getRailyTaxiAddBillingsTranslations()
      .relatedBillings.cargo_raily;

  return (
    <CardComponent
      classNames={{ root: "related_order_billings" }}
      header={{ title: translations.summaryLabel }}
    >
      <TableComponent
        isLoading={isBillingDataLoading}
        columns={tableColumns}
        rows={tableRows}
      />
    </CardComponent>
  );
};

export default RelatedCargoRailyBillingsComponent;
