import { UserTaxiOfficerListingRouteQueryParams } from "../../../common/routes/types/user-taxi-officer-listing-route-params";
import TaxiOfficerListingFilter, {
  TaxiOfficerListingEmailAddressFilter,
  TaxiOfficerListingFirstNameFilter,
  TaxiOfficerListingLastNameFilter,
  TaxiOfficerListingStatusFilter,
  TaxiOfficerListingUserNameFilter,
} from "../common/types/taxi-officer-listing-filter";
import UserStatus from "../../../../../common/types/user-status";
import TaxiOfficerListingFilterType from "../common/types/taxi-officer-listing-filter-type";
import TaxiOfficerListingSortKey from "../common/types/taxi-officer-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: UserTaxiOfficerListingRouteQueryParams
): TaxiOfficerListingFilter[] => {
  const filters: TaxiOfficerListingFilter[] = [];

  if (routeQueryFilterParams.firstName) {
    const firstNameFilter: TaxiOfficerListingFirstNameFilter = {
      type: TaxiOfficerListingFilterType.FIRST_NAME,
      value: routeQueryFilterParams.firstName,
    };

    filters.push(firstNameFilter);
  }

  if (routeQueryFilterParams.lastName) {
    const lastNameFilter: TaxiOfficerListingLastNameFilter = {
      type: TaxiOfficerListingFilterType.LAST_NAME,
      value: routeQueryFilterParams.lastName,
    };

    filters.push(lastNameFilter);
  }

  if (routeQueryFilterParams.email) {
    const emailAddressFilter: TaxiOfficerListingEmailAddressFilter = {
      type: TaxiOfficerListingFilterType.EMAIL,
      value: routeQueryFilterParams.email,
    };

    filters.push(emailAddressFilter);
  }

  if (routeQueryFilterParams.userName) {
    const userNameFilter: TaxiOfficerListingUserNameFilter = {
      type: TaxiOfficerListingFilterType.USERNAME,
      value: routeQueryFilterParams.userName,
    };

    filters.push(userNameFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: TaxiOfficerListingStatusFilter = {
      type: TaxiOfficerListingFilterType.ACTIVITY_STATUS,
      value:
        routeQueryFilterParams.status === "DELETED"
          ? UserStatus.DELETED
          : UserStatus.ACTIVE,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: UserTaxiOfficerListingRouteQueryParams
): TaxiOfficerListingSortKey | undefined => {
  return routeQueryParams.sort as TaxiOfficerListingSortKey;
};

const getPage = (
  routeQueryParams: UserTaxiOfficerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: UserTaxiOfficerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: TaxiOfficerListingFilter[],
  sortKey: TaxiOfficerListingSortKey | null,
  page: number,
  pageSize: number
): UserTaxiOfficerListingRouteQueryParams => {
  const userActivityFilterValue = filters.find(
    (filter) => filter.type === TaxiOfficerListingFilterType.ACTIVITY_STATUS
  )?.value as TaxiOfficerListingStatusFilter["value"] | undefined;

  const routeQueryParams: UserTaxiOfficerListingRouteQueryParams = {
    firstName: filters.find(
      (filter) => filter.type === TaxiOfficerListingFilterType.FIRST_NAME
    )?.value as TaxiOfficerListingFirstNameFilter["value"],
    lastName: filters.find(
      (filter) => filter.type === TaxiOfficerListingFilterType.LAST_NAME
    )?.value as TaxiOfficerListingLastNameFilter["value"],
    userName: filters.find(
      (filter) => filter.type === TaxiOfficerListingFilterType.USERNAME
    )?.value as TaxiOfficerListingUserNameFilter["value"],
    email: filters.find(
      (filter) => filter.type === TaxiOfficerListingFilterType.EMAIL
    )?.value as TaxiOfficerListingEmailAddressFilter["value"],
    status:
      userActivityFilterValue === undefined
        ? undefined
        : String(userActivityFilterValue),
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const taxiOfficerListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiOfficerListingFiltersService;
