import { FC, useEffect, useState } from "react";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import useQueryParams from "../../../common/hooks/use-query-params";
import SignUpTokenUserRole from "./common/types/sign-up-token-user-role";
import SignUpDriverComponent from "./driver/sign-up-driver.component";
import SignUpDriverContextProvider from "./driver/common/context/sign-up-driver.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import signUpTokenService from "./common/sign-up-token.service";
import HttpResponse from "../../../common/utils/http/http.response";
import signUpApiService from "./common/api/sign-up-api.service";
import SignUpCargoOfficerComponent from "./cargo-officer/sign-up-cargo-officer.component";
import SignUpDealerComponent from "./dealer/sign-up-dealer.component";
import SignUpTaxiOfficerComponent from "./taxi-officer/sing-up-taxi-officer.component";
import SignUpDispatcherComponent from "./dispatcher/sign-up-dispatcher.component";
import SignUpPassengerComponent from "./passenger/sign-up-passenger.component";
import SignUpPassengerContextProvider from "./passenger/common/context/sign-up-passenger.context";
import AppLogoHorizontalComponent from "../../../common/components/app-logo/app-logo-horizontal.component";

type SignUpProps = {};

const SignUpComponent: FC<SignUpProps> = () => {
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();
  const signUpTranslations = appTranslationsHelper.getAuthTranslations().signUp;

  useDocumentTitle(documentTitleTranslations.signUp);

  const { token } = useQueryParams<{ token: string | undefined }>();

  const [tokenUserRole, setTokenUserRole] =
    useState<SignUpTokenUserRole | null>(null);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const [isTokenVerifying, setIsTokenVerifying] = useState(false);

  const handleVerifySignUpTokenResponse = (response: HttpResponse) => {
    if (response.status === 200) {
      return;
    }
    if (response.status === 401) {
      setIsTokenInvalid(true);
    }
  };

  useEffect(() => {
    if (isTokenVerifying) {
      return;
    }
    if (!token) {
      setIsToken(false);
      return;
    }
    setIsTokenVerifying(true);
    setIsToken(true);

    signUpApiService
      .verifySignUpToken(token)
      .then(handleVerifySignUpTokenResponse)
      .finally(() => setIsTokenVerifying(false));

    try {
      const tokenPayload = signUpTokenService.decode(token);

      if (tokenPayload) {
        setTokenUserRole(tokenPayload.user_role);
      }
    } catch (e) {
      setIsTokenInvalid(true);
    }
  }, []);

  if (!isToken) {
    return (
      <div className="auth">
        <div className="auth__content">
          <AppLogoHorizontalComponent className="auth__logo" />
          <div className="auth__error_message">
            {signUpTranslations.noTokenLabel}
          </div>
        </div>
      </div>
    );
  }

  if (isTokenVerifying) {
    return (
      <div className="auth">
        <div className="auth__content">
          <AppLogoHorizontalComponent className="auth__logo" />
          <div className="auth__error_message">
            {signUpTranslations.verifyTokenPendingLabel}
          </div>
        </div>
      </div>
    );
  }

  if (isTokenInvalid) {
    return (
      <div className="auth">
        <div className="auth__content">
          <AppLogoHorizontalComponent className="auth__logo" />
          <div className="auth__error_message">
            {signUpTranslations.invalidTokenLabel}
          </div>
        </div>
      </div>
    );
  }

  if (tokenUserRole === SignUpTokenUserRole.DRIVER && !isTokenVerifying) {
    return (
      <SignUpDriverContextProvider>
        <SignUpDriverComponent />
      </SignUpDriverContextProvider>
    );
  }

  if (
    tokenUserRole === SignUpTokenUserRole.CARGO_OFFICER &&
    !isTokenVerifying
  ) {
    return <SignUpCargoOfficerComponent />;
  }

  if (tokenUserRole === SignUpTokenUserRole.DEALER && !isTokenVerifying) {
    return <SignUpDealerComponent />;
  }

  if (tokenUserRole === SignUpTokenUserRole.TAXI_OFFICER && !isTokenVerifying) {
    return <SignUpTaxiOfficerComponent />;
  }
  if (tokenUserRole === SignUpTokenUserRole.DISPATCHER && !isTokenVerifying) {
    return <SignUpDispatcherComponent />;
  }
  if (tokenUserRole === SignUpTokenUserRole.PASSENGER && !isTokenVerifying) {
    return (
      <SignUpPassengerContextProvider>
        <SignUpPassengerComponent />;
      </SignUpPassengerContextProvider>
    );
  }

  return (
    <div className="auth">
      <div className="auth__content">
        <AppLogoHorizontalComponent className="auth__logo" />
      </div>
    </div>
  );
};

export default SignUpComponent;
