import DriverDetailsDriverPlanEntry, {
  DriverDetailsDriverPlanEntryNodeExclusions,
  DriverDetailsDriverPlanEntryWaypoint,
  DriverDetailsDriverPlanEntryWaypointType,
} from "../../common/types/driver-details-plan-entry";
import {
  DriverDetailsPlanByRailyPlanResponsePlanEntry,
  DriverDetailsPlanByRailyPlanResponsePlanEntryCheckoutEvent,
  DriverDetailsPlanByRailyPlanResponsePlanEntryEndNode,
  DriverDetailsPlanByRailyPlanResponsePlanEntrySolvedOrder,
  DriverDetailsPlanByRailyPlanResponsePlanEntryStartNode,
} from "./api/driver-details-active-route-plans-by-raily-plan.response";

const createWaypointForStartNode = (
  responseStartNode: DriverDetailsPlanByRailyPlanResponsePlanEntryStartNode,
  responseCheckoutEvents: DriverDetailsPlanByRailyPlanResponsePlanEntryCheckoutEvent[],
  stageCounter: number
): DriverDetailsDriverPlanEntryWaypoint => {
  const responseCheckoutEvent = responseCheckoutEvents.find(
    (item) => item.node_id === responseStartNode.meta.id
  );

  const waypoint: DriverDetailsDriverPlanEntryWaypoint = {
    type: DriverDetailsDriverPlanEntryWaypointType.DRIVER_HOME,
    uuid: responseStartNode.meta.id,
    address: {
      displayName: responseStartNode.display_name,
      latitude: responseStartNode.lat,
      longitude: responseStartNode.lon,
    },
    checkoutDate: responseCheckoutEvent?.achieved_at
      ? new Date(responseCheckoutEvent.achieved_at)
      : null,
    clientDisplayName: "",
    estimatedDate: new Date(responseStartNode.meta.estimated_time),
    plannedHaltingTime: responseStartNode.halting_time,
    forcedHaltingTime: responseCheckoutEvent?.halting_time ?? null,
    highwayCost: responseCheckoutEvent?.highway_charge ?? null,
    order: null,
    plannedDate: responseStartNode.time
      ? new Date(responseStartNode.time)
      : null,
    isCheckouted: !!responseCheckoutEvent,
    checkoutEventUuid: responseCheckoutEvent?.id ?? null,
    isCheckoutManagementEnabled: true,
    solvedOrderUuid: null,
    stageNo: stageCounter,
  };

  return waypoint;
};

const createWaypointForEndNode = (
  responseEndNode: DriverDetailsPlanByRailyPlanResponsePlanEntryEndNode,
  responseCheckoutEvents: DriverDetailsPlanByRailyPlanResponsePlanEntryCheckoutEvent[],
  stageCounter: number
): DriverDetailsDriverPlanEntryWaypoint => {
  const responseCheckoutEvent = responseCheckoutEvents.find(
    (item) => item.node_id === responseEndNode.meta.id
  );

  const waypoint: DriverDetailsDriverPlanEntryWaypoint = {
    type: DriverDetailsDriverPlanEntryWaypointType.DRIVER_HOME,
    uuid: responseEndNode.meta.id,
    address: {
      displayName: responseEndNode.display_name,
      latitude: responseEndNode.lat,
      longitude: responseEndNode.lon,
    },
    checkoutDate: responseCheckoutEvent?.achieved_at
      ? new Date(responseCheckoutEvent.achieved_at)
      : null,
    clientDisplayName: "",
    estimatedDate: new Date(responseEndNode.meta.estimated_time),
    plannedHaltingTime: responseEndNode.halting_time,
    forcedHaltingTime: responseCheckoutEvent?.halting_time ?? null,
    highwayCost: responseCheckoutEvent?.highway_charge ?? null,
    order: null,
    plannedDate: responseEndNode.time ? new Date(responseEndNode.time) : null,
    isCheckouted: !!responseCheckoutEvent,
    checkoutEventUuid: responseCheckoutEvent?.id ?? null,
    isCheckoutManagementEnabled: true,
    solvedOrderUuid: null,
    stageNo: stageCounter,
  };

  return waypoint;
};

const createWaypointsForSolvedOrder = (
  responseSolvedOrder: DriverDetailsPlanByRailyPlanResponsePlanEntrySolvedOrder,
  responseCheckoutEvents: DriverDetailsPlanByRailyPlanResponsePlanEntryCheckoutEvent[],
  stageCounter: number,
  onComplete: (stageCounterValue: number) => void
): DriverDetailsDriverPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  let internalStageCounter = stageCounter;

  responseSolvedOrder.ride.seq.forEach((seqItem) => {
    const responseCheckoutEvent = responseCheckoutEvents.find(
      (item) => item.node_id === seqItem.meta.id
    );

    const waypoint: DriverDetailsDriverPlanEntryWaypoint = {
      type: DriverDetailsDriverPlanEntryWaypointType.ORDER_WAYPOINT,
      uuid: seqItem.meta.id,
      clientDisplayName: responseSolvedOrder.cargo_company.display_name,
      order: {
        uuid: responseSolvedOrder.transporting_order.cargo_order.id,
        cargoInternalOrderId:
          responseSolvedOrder.transporting_order.cargo_order.human_id,
      },
      address: {
        displayName: seqItem.display_name,
        latitude: seqItem.lat,
        longitude: seqItem.lon,
      },
      checkoutDate: responseCheckoutEvent?.achieved_at
        ? new Date(responseCheckoutEvent.achieved_at)
        : null,
      estimatedDate: new Date(seqItem.meta.estimated_time),
      plannedHaltingTime: seqItem.halting_time,
      forcedHaltingTime: responseCheckoutEvent?.halting_time ?? null,
      highwayCost: responseCheckoutEvent?.highway_charge ?? null,
      plannedDate: seqItem.time ? new Date(seqItem.time) : null,
      isCheckouted: !!responseCheckoutEvent,
      checkoutEventUuid: responseCheckoutEvent?.id ?? null,
      isCheckoutManagementEnabled: true,
      solvedOrderUuid: responseSolvedOrder.id,
      stageNo: internalStageCounter,
    };

    waypoints.push(waypoint);
    internalStageCounter++;
  });

  onComplete(internalStageCounter);

  return waypoints;
};

const createWaypointsForSolvedOrders = (
  responseSolvedOrders: DriverDetailsPlanByRailyPlanResponsePlanEntrySolvedOrder[],
  responseCheckoutEvents: DriverDetailsPlanByRailyPlanResponsePlanEntryCheckoutEvent[],
  initialStageCounter: number
): DriverDetailsDriverPlanEntryWaypoint[] => {
  let stageCounter = initialStageCounter;
  const allWaypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  responseSolvedOrders.forEach((responseSolvedOrder) => {
    const orderWaypoints = createWaypointsForSolvedOrder(
      responseSolvedOrder,
      responseCheckoutEvents,
      stageCounter,
      (updatedStageCounter) => {
        stageCounter = updatedStageCounter;
      }
    );

    allWaypoints.push(...orderWaypoints);
  });

  return allWaypoints;
};

const createPlanEntryWaypoints = (
  responsePlanEntry: DriverDetailsPlanByRailyPlanResponsePlanEntry
): DriverDetailsDriverPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  let stageCounter: number = 1;

  const startWaypoint = createWaypointForStartNode(
    responsePlanEntry.start_node,
    responsePlanEntry.checkout_events,
    stageCounter
  );

  stageCounter++;

  waypoints.push(startWaypoint);

  const orderWaypoints = createWaypointsForSolvedOrders(
    responsePlanEntry.solved_orders,
    responsePlanEntry.checkout_events,
    stageCounter
  );

  waypoints.push(...orderWaypoints);

  stageCounter = stageCounter = waypoints[waypoints.length - 1].stageNo + 1;

  const endWaypoint = createWaypointForEndNode(
    responsePlanEntry.end_node,
    responsePlanEntry.checkout_events,
    stageCounter
  );

  waypoints.push(endWaypoint);

  return waypoints;
};

const createNodeExclusions = (
  responsePlanEntry: DriverDetailsPlanByRailyPlanResponsePlanEntry
): DriverDetailsDriverPlanEntryNodeExclusions[] => {
  const exclusionList = responsePlanEntry.node_exclusions.exclusions.map(
    (nodeExclusions) => ({
      approaching: nodeExclusions.exclude_approaching,
      inside: nodeExclusions.exclude_inside,
      returning: nodeExclusions.exclude_returning,
      nodeIds: nodeExclusions.node_ids,
    })
  );

  return exclusionList;
};

const createPlanEntry = (
  responsePlanEntry: DriverDetailsPlanByRailyPlanResponsePlanEntry
): DriverDetailsDriverPlanEntry => {
  const waypoints = createPlanEntryWaypoints(responsePlanEntry);
  const nodeExclusions = createNodeExclusions(responsePlanEntry);

  return {
    isAnonymized: false,
    uuid: responsePlanEntry.id,
    id: responsePlanEntry.human_id,
    waypoints,
    nodeExclusions: nodeExclusions,
  };
};

const createPlanEntries = (
  responsePlanEntries: DriverDetailsPlanByRailyPlanResponsePlanEntry[]
): DriverDetailsDriverPlanEntry[] => {
  return responsePlanEntries.map(createPlanEntry);
};

const driverDetailsActiveRoutePlansByRailyPlanEntryFactory = {
  createPlanEntries,
};

export default driverDetailsActiveRoutePlansByRailyPlanEntryFactory;
