import React from "react";
import "./switch-button.scss";

interface SwitchButtonProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  onBlur?: () => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = (props) => {
  const handleToggle = () => {
    props.onChange(!props.checked);
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={handleToggle}
        onBlur={props.onBlur}
      />
      <span className="slider"></span>
    </label>
  );
};

export default SwitchButton;
