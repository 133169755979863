import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryInitializeEvent } from "../../../common/types/order-details-history-entry-event";
import orderDetailsHistoryEntryInitializeContentHelper from "./order-details-history-entry-initialize-content.helper";

type OrderDetailsHistoryEntryInitializeContentProps = {
  event: OrderDetailsHistoryEntryInitializeEvent;
};

const OrderDetailsHistoryEntryInitializeContentComponent: FC<
  OrderDetailsHistoryEntryInitializeContentProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .initializeOrderEntry;

  const cargoExternalOrderIdLabel =
    orderDetailsHistoryEntryInitializeContentHelper.getCargoExternalOrderIdLabel(
      props.event.cargoExternalOrderId
    );

  const cargoExternalOrderIdTitle =
    orderDetailsHistoryEntryInitializeContentHelper.getCargoExternalOrderIdTitle(
      props.event.cargoExternalOrderId
    );

  const contractModelLabel =
    orderDetailsHistoryEntryInitializeContentHelper.getContractModelLabel(
      props.event.contractModel
    );

  const taxiCorporationLabel =
    orderDetailsHistoryEntryInitializeContentHelper.getTaxiCorporationLabel(
      props.event.taxiCorporationName
    );

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingText}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={contractModelLabel}
      >
        {contractModelLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={taxiCorporationLabel}
      >
        {taxiCorporationLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={cargoExternalOrderIdTitle}
      >
        {cargoExternalOrderIdLabel}
      </div>
      <div className="order_details_history_entry_list_item_content__section">
        <div className="order_details_history_entry_list_item_content__label">
          {translations.route.headingText}
        </div>
        <div>
          {props.event.route.map((routeWaypoint, index) => {
            const plannedDateLabel =
              orderDetailsHistoryEntryInitializeContentHelper.getPlannedDateLabel(
                routeWaypoint.plannedDate
              );

            const plannedDateTitle =
              orderDetailsHistoryEntryInitializeContentHelper.getPlannedDateTitle(
                routeWaypoint.plannedDate
              );

            const haltingTimeLabel =
              orderDetailsHistoryEntryInitializeContentHelper.getHaltingTimeLabel(
                routeWaypoint.haltingTime
              );

            const haltingTimeTitle =
              orderDetailsHistoryEntryInitializeContentHelper.getHaltingTimeTitle(
                routeWaypoint.haltingTime
              );

            return (
              <div key={index}>
                <div
                  className="order_details_history_entry_list_item_content__value"
                  title={`${routeWaypoint.stageNo}. ${routeWaypoint.addressDisplayName}`}
                >
                  {`${routeWaypoint.stageNo}. ${routeWaypoint.addressDisplayName}`}
                </div>
                <div
                  className="order_details_history_entry_list_item_content__value"
                  title={plannedDateTitle}
                >
                  {plannedDateLabel}
                </div>
                <div
                  className="order_details_history_entry_list_item_content__value"
                  title={haltingTimeTitle}
                >
                  {haltingTimeLabel}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryInitializeContentComponent;
