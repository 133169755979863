import classNames from "classnames";
import { FC, useMemo } from "react";
import { SteppedLineTo } from "react-lineto";
import delegationTranslationsHelper from "../../../../../../../languages/delegation-translations.helper";
import delegationAddSummaryRouteHelper from "./factory/delegation-add-summary-route.helper";
import DelegationAddRouteWaypoint from "../../../types/delegation-add-route-waypoint";

type DelegationAddSummaryRouteProps = {
  waypoints: DelegationAddRouteWaypoint[];
};

const DelegationAddSummaryRouteComponent: FC<DelegationAddSummaryRouteProps> = (
  props
) => {
  const filteredWaypoints = useMemo(() => {
    return props.waypoints.filter((worker) => worker.uuid !== null);
  }, [props.waypoints]);

  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().summary.routes;

  const lineConnections =
    delegationAddSummaryRouteHelper.getDefinitionOfLineConnectionsBetweenWaypoints(
      filteredWaypoints
    );

  return (
    <div
      className="delegation_add_summary_route"
      data-test-id="delegation-add-summary-route"
    >
      <div className="delegation_add_summary_route_waypoint_list">
        <div className="delegation_add_summary_route_waypoint_list__header">
          <div
            className="delegation_add_summary_route_waypoint_list__heading place"
            title={translations.placeHeaderTitle}
          >
            {translations.placeHeaderLabel}
          </div>
          <div
            className="delegation_add_summary_route_waypoint_list__heading group"
            title={translations.groupHeaderTitle}
          >
            {translations.groupHeaderLabel}
          </div>
        </div>
        {filteredWaypoints.map((waypoint, index) => {
          const isFirstWaypoint = index === 0;
          const isLastWaypoint = index === filteredWaypoints.length - 1;

          const isFaded = !isFirstWaypoint && !isLastWaypoint;

          return (
            <div
              className={classNames(
                "delegation_add_summary_route_waypoint_list__row",
                isFirstWaypoint && "first_highlight",
                isLastWaypoint && "last_highlight",
                isFaded && "faded"
              )}
              key={`delegation-add-summary-route-waypoint-index-${index}`}
              data-test-id={`delegation-add-summary-route-waypoint-index-${index}`}
            >
              <div
                className="delegation_add_summary_route_waypoint_list__row_cell place"
                title={delegationAddSummaryRouteHelper.getPlaceTitle(
                  waypoint.place
                )}
              >
                <div className="index">{index + 1}</div>
                <div
                  className={classNames(
                    "dot",
                    delegationAddSummaryRouteHelper.generateWaypointDotClassname(
                      waypoint
                    )
                  )}
                ></div>
                <div className="delegation_add_summary_route_waypoint_list__row_cell place">
                  {delegationAddSummaryRouteHelper.getPlaceLabel(
                    waypoint.place
                  )}
                </div>
                <div className="delegation_add_summary_route_waypoint_list__row_cell group">
                  {delegationAddSummaryRouteHelper.getGroupLabel(
                    waypoint.group
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {lineConnections.map((item, index) => {
        return (
          <SteppedLineTo
            key={`waypoint-list-connection-line-index-${index}-type-${item.lineType}`}
            from={item.itemFromClassname}
            to={item.itemToClassname}
            delay={50}
            borderStyle={item.lineType}
            borderWidth={1}
            borderColor={"#000"}
            within={`delegation_add_summary_route`}
            className="delegation_add_summary__connection_line"
          />
        );
      })}
    </div>
  );
};

export default DelegationAddSummaryRouteComponent;
