import MileageContractDetailsRequest from "../../../api/raily/mileage-contract/details/mileage-contract-details.request";
import MileageContractDetailsLoadParams from "./mileage-contract-details-load-params";

const create = (
  params: MileageContractDetailsLoadParams
): MileageContractDetailsRequest => {
  return {
    contractUuid: params.contractUuid,
  };
};

const mileageContractDetailsRequestFactory = {
  create,
};

export default mileageContractDetailsRequestFactory;
