import orderTranslationsHelper from "../../../../../languages/order-translations.helper";

type OrderDetailsCopyToClipboardRouteWaypointLabelBuilder = {
  withEstimatedDate: (
    estimatedDateLabel: string
  ) => OrderDetailsCopyToClipboardRouteWaypointLabelBuilder;
  withOnboardingPassengersLabels: (
    onboardingPassengersLabels: string[]
  ) => OrderDetailsCopyToClipboardRouteWaypointLabelBuilder;
  withOutboardingPassengersLabels: (
    outboardingPassengersLabels: string[]
  ) => OrderDetailsCopyToClipboardRouteWaypointLabelBuilder;
  build: () => string;
};

const orderDetailsCopyToClipboardRouteWaypointLabelBuilder = (
  stageNo: number,
  placeDisplayName: string
): OrderDetailsCopyToClipboardRouteWaypointLabelBuilder => {
  const translations = orderTranslationsHelper.getDetailsTranslations();

  let _estimatedDateLabel: string = "";
  let _onboardingPassengersLabels: string[] = [];
  let _outboardingPassengersLabels: string[] = [];

  const withEstimatedDate = (estimatedDateLabel: string) => {
    _estimatedDateLabel = estimatedDateLabel;

    return builders;
  };

  const withOnboardingPassengersLabels = (
    onboardingPassengersLabels: string[]
  ) => {
    _onboardingPassengersLabels = onboardingPassengersLabels;

    return builders;
  };

  const withOutboardingPassengersLabels = (
    outboardingPassengersLabels: string[]
  ) => {
    _outboardingPassengersLabels = outboardingPassengersLabels;

    return builders;
  };

  const build = () => {
    let labelTemplate = `#{stageNo}. #{placeDisplayName}`;

    if (_estimatedDateLabel) {
      labelTemplate += ` #{estimatedDate}`;
    }

    if (_onboardingPassengersLabels.length) {
      labelTemplate += `\r${translations.copyToClipboard.onboardingPassengersLabel}`;
    }

    if (_outboardingPassengersLabels.length) {
      labelTemplate += `\r${translations.copyToClipboard.outboardingPassengersLabel}`;
    }

    return labelTemplate
      .replace(`#{stageNo}`, `${stageNo}`)
      .replace(`#{placeDisplayName}`, placeDisplayName)
      .replace(`#{estimatedDate}`, `(${_estimatedDateLabel})`)
      .replace(
        `#{onboardingPassengersLabels}`,
        _onboardingPassengersLabels.join(", ")
      )
      .replace(
        `#{outboardingPassengersLabels}`,
        _outboardingPassengersLabels.join(", ")
      );
  };

  const builders = {
    withEstimatedDate,
    withOnboardingPassengersLabels,
    withOutboardingPassengersLabels,
    build,
  };

  return builders;
};

export default orderDetailsCopyToClipboardRouteWaypointLabelBuilder;
