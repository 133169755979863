import { FC } from "react";
import IconProps from "./icon-props";

type SteeringWheelIconProps = IconProps;

const SteeringWheelIcon: FC<SteeringWheelIconProps> = (props) => {
  return (
    <svg
      width={props.width ?? 20}
      height={props.height ?? 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17.9381V14C9 12.9738 8.60557 11.9868 7.89828 11.2432C7.19099 10.4996 6.22496 10.0563 5.2 10.005L5 10H2C2 14.0796 5.05369 17.446 9 17.9381ZM2.25203 8C3.14012 4.54955 6.27232 2 10 2C13.7277 2 16.8599 4.54955 17.748 8H2.25203ZM18 10H14.999C13.9728 10 12.9858 10.3944 12.2422 11.1017C11.4986 11.809 11.0553 12.775 11.004 13.8L11 14V17.9381C14.9463 17.446 18 14.0796 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
        fill={props.color ?? "black"}
      />
    </svg>
  );
};

export default SteeringWheelIcon;
