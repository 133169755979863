import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import CargoCompanyListingTableColumn from "../common/types/cargo-company-listing-table-column";

const getColumns = (): CargoCompanyListingTableColumn[] => {
  const translations =
    cargoTranslationsHelper.getCompanyListingTranslations().table.headers;

  const columns: CargoCompanyListingTableColumn[] = [
    {
      header: translations.companyNameLabel,
      title: translations.companyNameTitle,
      accessor: "companyName",
      colSpan: 10,
    },
    {
      header: translations.companyDisplayNameLabel,
      title: translations.companyDisplayNameTitle,
      accessor: "companyDisplayName",
      colSpan: 10,
    },
    {
      header: translations.taxNumberLabel,
      title: translations.taxNumberTitle,
      accessor: "taxNumber",
      colSpan: 7,
    },
    {
      header: translations.companyIdLabel,
      title: translations.companyIdTitle,
      accessor: "companyId",
      colSpan: 7,
    },
    {
      header: translations.nationalCourtRegisterLabel,
      title: translations.nationalCourtRegisterTitle,
      accessor: "nationalCourtRegister",
      colSpan: 7,
    },
    {
      header: translations.addressLabel,
      title: translations.addressTitle,
      accessor: "address",
      colSpan: 10,
    },
    {
      header: translations.notesLabel,
      title: translations.notesTitle,
      accessor: "notes",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];

  return columns;
};

const cargoCompanyListingTableHelper = {
  getColumns,
};

export default cargoCompanyListingTableHelper;
