import {
  OrderDetailsHistoryResponseItemAddCargoEnterChangeRideCommand,
  OrderDetailsHistoryResponseItemAddCargoExitChangeRideCommand,
  OrderDetailsHistoryResponseItemChangeHaltingTimeChangeRideCommand,
  OrderDetailsHistoryResponseItemChangePositionChangeRideCommand,
  OrderDetailsHistoryResponseItemChangeRideCommandUpdateRideCommand,
  OrderDetailsHistoryResponseItemChangeTimeChangeRideCommand,
  OrderDetailsHistoryResponseItemCreateInnerRideChangeRideCommand,
  OrderDetailsHistoryResponseItemDeleteNodeChangeRideCommand,
  OrderDetailsHistoryResponseItemDoNothingChangeRideCommand,
  OrderDetailsHistoryResponseItemInsertNodeAfterChangeRideCommand,
  OrderDetailsHistoryResponseItemInsertNodeBeforeChangeRideCommand,
  OrderDetailsHistoryResponseItemRemoveCargoEnterChangeRideCommand,
  OrderDetailsHistoryResponseItemRemoveCargoExitChangeRideCommand,
  OrderDetailsHistoryResponseItemSquashInnerRideChangeRideCommand,
} from "./api/order-details-history.response";
import OrderDetailsHistoryEntryChangeRideInternalEvent, {
  OrderDetailsHistoryEntryAddCargoEnterChangeRideEvent,
  OrderDetailsHistoryEntryAddCargoExitChangeRideEvent,
  OrderDetailsHistoryEntryChangeHaltingTimeChangeRideEvent,
  OrderDetailsHistoryEntryChangePositionChangeRideEvent,
  OrderDetailsHistoryEntryChangeTimeChangeRideEvent,
  OrderDetailsHistoryEntryCreateInnerRideChangeRideEvent,
  OrderDetailsHistoryEntryDeleteNodeChangeRideEvent,
  OrderDetailsHistoryEntryDoNothingChangeRideEvent,
  OrderDetailsHistoryEntryInsertNodeAfterChangeRideEvent,
  OrderDetailsHistoryEntryInsertNodeBeforeChangeRideEvent,
  OrderDetailsHistoryEntryRemoveCargoEnterChangeRideEvent,
  OrderDetailsHistoryEntryRemoveCargoExitChangeRideEvent,
} from "./types/order-details-history-change-ride-event";
import OrderDetailsHistoryChangeRideEventType from "./types/order-details-history-change-ride-event-type";

const createAddCargoEnterEvent = (
  responseItemAddCargoEnterChangeRideCommand: OrderDetailsHistoryResponseItemAddCargoEnterChangeRideCommand
): OrderDetailsHistoryEntryAddCargoEnterChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.ADD_CARGO_ENTER,
  };
};

const createAddCargoExitEvent = (
  responseItemAddCargoExitChangeRideCommand: OrderDetailsHistoryResponseItemAddCargoExitChangeRideCommand
): OrderDetailsHistoryEntryAddCargoExitChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.ADD_CARGO_EXIT,
  };
};

const createChangeHaltingTimeEvent = (
  responseItemChangeHaltingTimeChangeRideCommand: OrderDetailsHistoryResponseItemChangeHaltingTimeChangeRideCommand
): OrderDetailsHistoryEntryChangeHaltingTimeChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.CHANGE_HALTING_TIME,
    details: {
      newHaltingTime:
        responseItemChangeHaltingTimeChangeRideCommand.halting_time,
    },
  };
};

const createChangePositionEvent = (
  responseItemChangePositionChangeRideCommand: OrderDetailsHistoryResponseItemChangePositionChangeRideCommand
): OrderDetailsHistoryEntryChangePositionChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.CHANGE_POSITION,
    details: {
      newPlaceAddressDisplayName:
        responseItemChangePositionChangeRideCommand.display_name,
    },
  };
};

const createChangeTimeEvent = (
  responseItemChangeTimeChangeRideCommand: OrderDetailsHistoryResponseItemChangeTimeChangeRideCommand
): OrderDetailsHistoryEntryChangeTimeChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.CHANGE_TIME,
    details: {
      newPlannedDate: responseItemChangeTimeChangeRideCommand.time
        ? new Date(responseItemChangeTimeChangeRideCommand.time)
        : null,
    },
  };
};

const createCreateInnerRideEvent = (
  responseItemCreateInnerRideChangeRideCommand: OrderDetailsHistoryResponseItemCreateInnerRideChangeRideCommand
): OrderDetailsHistoryEntryCreateInnerRideChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.CREATE_INNER_RIDE,
  };
};

const createDeleteNodeEvent = (
  responseItemDeleteNodeChangeRideCommand: OrderDetailsHistoryResponseItemDeleteNodeChangeRideCommand
): OrderDetailsHistoryEntryDeleteNodeChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.DELETE_NODE,
  };
};

const createDoNothingEvent = (
  responseItemDoNothingChangeRideCommand: OrderDetailsHistoryResponseItemDoNothingChangeRideCommand
): OrderDetailsHistoryEntryDoNothingChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.DO_NOTHING,
  };
};

const createInsertNodeAfterEvent = (
  responseItemInsertNodeAfterChangeRideCommand: OrderDetailsHistoryResponseItemInsertNodeAfterChangeRideCommand
): OrderDetailsHistoryEntryInsertNodeAfterChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.INSERT_NODE_AFTER,
    details: {
      haltingTime:
        responseItemInsertNodeAfterChangeRideCommand.subject.halting_time,
      placeAddressDisplayName:
        responseItemInsertNodeAfterChangeRideCommand.subject.display_name,
      date: responseItemInsertNodeAfterChangeRideCommand.subject.time
        ? new Date(responseItemInsertNodeAfterChangeRideCommand.subject.time)
        : null,
    },
  };
};

const createInsertNodeBeforeEvent = (
  responseItemInsertNodeBeforeChangeRideCommand: OrderDetailsHistoryResponseItemInsertNodeBeforeChangeRideCommand
): OrderDetailsHistoryEntryInsertNodeBeforeChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.INSERT_NODE_BEFORE,
    details: {
      haltingTime:
        responseItemInsertNodeBeforeChangeRideCommand.subject.halting_time,
      placeAddressDisplayName:
        responseItemInsertNodeBeforeChangeRideCommand.subject.display_name,
      date: responseItemInsertNodeBeforeChangeRideCommand.subject.time
        ? new Date(responseItemInsertNodeBeforeChangeRideCommand.subject.time)
        : null,
    },
  };
};

const createRemoveCargoEnterEvent = (
  responseItemRemoveCargoEnterChangeRideCommand: OrderDetailsHistoryResponseItemRemoveCargoEnterChangeRideCommand
): OrderDetailsHistoryEntryRemoveCargoEnterChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_ENTER,
  };
};

const createRemoveCargoExitEvent = (
  responseItemRemoveCargoExitChangeRideCommand: OrderDetailsHistoryResponseItemRemoveCargoExitChangeRideCommand
): OrderDetailsHistoryEntryRemoveCargoExitChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_EXIT,
  };
};

const createSquashInnerRideEvent = (
  responseItemSquashInnerRideChangeRideCommand: OrderDetailsHistoryResponseItemSquashInnerRideChangeRideCommand
): OrderDetailsHistoryEntryRemoveCargoExitChangeRideEvent => {
  return {
    type: OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_EXIT,
  };
};

const createEvent = (
  responseItemChangeRideCommandUpdateRideCommand: OrderDetailsHistoryResponseItemChangeRideCommandUpdateRideCommand
): OrderDetailsHistoryEntryChangeRideInternalEvent => {
  switch (responseItemChangeRideCommandUpdateRideCommand.command_type) {
    case OrderDetailsHistoryChangeRideEventType.ADD_CARGO_ENTER:
      return createAddCargoEnterEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemAddCargoEnterChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.ADD_CARGO_EXIT:
      return createAddCargoExitEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemAddCargoExitChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.CHANGE_HALTING_TIME:
      return createChangeHaltingTimeEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemChangeHaltingTimeChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.CHANGE_POSITION:
      return createChangePositionEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemChangePositionChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.CHANGE_TIME:
      return createChangeTimeEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemChangeTimeChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.CREATE_INNER_RIDE:
      return createCreateInnerRideEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemCreateInnerRideChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.DELETE_NODE:
      return createDeleteNodeEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemDeleteNodeChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.DO_NOTHING:
      return createDoNothingEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemDoNothingChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.INSERT_NODE_AFTER:
      return createInsertNodeAfterEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemInsertNodeAfterChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.INSERT_NODE_BEFORE:
      return createInsertNodeBeforeEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemInsertNodeBeforeChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_ENTER:
      return createRemoveCargoEnterEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemRemoveCargoEnterChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_EXIT:
      return createRemoveCargoExitEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemRemoveCargoExitChangeRideCommand
      );
    case OrderDetailsHistoryChangeRideEventType.SQUASH_INNER_RIDE:
      return createSquashInnerRideEvent(
        responseItemChangeRideCommandUpdateRideCommand as OrderDetailsHistoryResponseItemSquashInnerRideChangeRideCommand
      );
  }
};

const createEvents = (
  responseItemChangeRideCommandUpdateRideCommands: OrderDetailsHistoryResponseItemChangeRideCommandUpdateRideCommand[]
): OrderDetailsHistoryEntryChangeRideInternalEvent[] => {
  return responseItemChangeRideCommandUpdateRideCommands.map(createEvent);
};

const orderDetailsHistoryChangeRideEntryFactory = {
  createEvents,
};

export default orderDetailsHistoryChangeRideEntryFactory;
