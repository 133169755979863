import MileageDownloadRequest, {
  MileageDownloadRequestQueryParams,
} from "./mileage-download.request";

const createQueryParams = (
  request: MileageDownloadRequest
): MileageDownloadRequestQueryParams => {
  return {
    separator: request.separator,
    timezone: request.timezone,
    mileages: request.mileageUuids.join(","),
  };
};

const mileageDownloadRequestFactory = {
  createQueryParams,
};

export default mileageDownloadRequestFactory;
