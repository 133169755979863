import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import TaxiUnsettledPrivateOrderListingItem from "../common/types/taxi-unsettled-private-order-listing-item";
import TaxiUnsettledPrivateOrderListingTableColumn from "../common/types/taxi-unsettled-private-order-listing-table-column";
import TaxiUnsettledPrivateOrderListingTableRow from "../common/types/taxi-unsettled-private-order-listing-table-row";
import taxiUnsettledPrivateOrderListingTableHelper from "./taxi-unsettled-private-order-listing-table.helper";

type TaxiUnsettledPrivateOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: TaxiUnsettledPrivateOrderListingItem[];
};

const TaxiUnsettledPrivateOrderListingTableComponent: FC<
  TaxiUnsettledPrivateOrderListingTableProps
> = (props) => {
  const columns: TaxiUnsettledPrivateOrderListingTableColumn[] =
    taxiUnsettledPrivateOrderListingTableHelper.getColumns();

  const rows: TaxiUnsettledPrivateOrderListingTableRow[] =
    taxiUnsettledPrivateOrderListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiUnsettledPrivateOrderListingTableComponent;
