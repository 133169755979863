import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { FC, memo, useState, useEffect, useMemo, useCallback } from "react";
import { useAppContext } from "../../context/app.context";
import { debounce } from "lodash";
import { ReactComponent as RailyLogo } from "../../assets/images/raily_logo.svg";
import { ReactComponent as MarketplaceLogo } from "../../assets/images/marketplace_logo.svg";
import { ReactComponent as MarketplaceLogoFull } from "../../assets/images/marketplace_logo_full.svg";
import AppAsideMinifiedNavigationComponent from "./navigation/minified/app-aside-minified-navigation.component";
import AppAsidePinButtonComponent from "./app-aside-pin-button.component";
import AppAsideExpandedNavigationComponent from "./navigation/expanded/app-aside-expanded-navigation.component";
import appAsideAttentionCountersApiService from "./common/api/app-aside-attention-counters-api.service";
import AppAsideAttentionCountersResponse, {
  AppAsideAttentionCountersResponseData,
} from "./common/api/app-aside-attention-counters.response";
import AppAsideExpandedNavigationItem from "./navigation/expanded/common/types/app-aside-expanded-navigation-item";
import appAsideNavigationHelper from "./navigation/common/app-aside-navigation.helper";
import AppLogoHorizontalComponent from "../../common/components/app-logo/app-logo-horizontal.component";
import appConfig from "../../app.config";
import LinkButtonComponent from "../../common/components/button/link/link-button.component";

const AppAsideComponent: FC = memo(() => {
  const { isAsidePanelPinned, pinAsidePanel, unpinAsidePanel } =
    useAppContext();

  const marketplaceUrl = appConfig.marketplaceUrl;

  const { pathname } = useLocation();
  const { selectedAppLanguage, user } = useAppContext();

  const [attentionCountersData, setAttentionCountersData] =
    useState<AppAsideAttentionCountersResponseData | null>(null);
  const [isAsidePanelExpanded, setIsAsidePanelExpanded] = useState(false);
  const [shouldExpandAsidePanel, setShouldExpandAsidePanel] = useState(false);
  const [expandedGroupsKeys, setExpandedGroupsKeys] = useState<string[]>([]);
  const [navigationItems, setNavigationItems] = useState<
    AppAsideExpandedNavigationItem[]
  >([]);

  useEffect(() => {
    appAsideAttentionCountersApiService
      .fetchAttentionCounters()
      .then(handleAttentionCountersDataResponse);
  }, []);

  const navigationDefinition = useMemo(() => {
    return appAsideNavigationHelper.getNavigationDefinitionForUserRoles(
      user!.roles,
      attentionCountersData ?? undefined
    );
  }, [selectedAppLanguage, user?.roles, attentionCountersData]);

  useEffect(() => {
    const expandedGroupKeysByDefault =
      appAsideNavigationHelper.getListOfDefaultOpenedNavigationItemKeys(
        navigationDefinition,
        pathname
      );

    setExpandedGroupsKeys(expandedGroupKeysByDefault);
  }, [navigationDefinition]);

  const manageIsExpanded = useCallback(
    debounce((isExpanded: boolean) => {
      setIsAsidePanelExpanded(isExpanded);
    }, 200),
    []
  );

  useEffect(() => {
    manageIsExpanded(shouldExpandAsidePanel);
  }, [shouldExpandAsidePanel]);

  const isExpanded = isAsidePanelPinned || isAsidePanelExpanded;

  const handleAttentionCountersDataResponse = (
    response: AppAsideAttentionCountersResponse
  ) => {
    if (response.status === 200) {
      setAttentionCountersData(response.data);
      return;
    }
  };

  const toggleAsidePanelPin = () => {
    if (isAsidePanelPinned) {
      unpinAsidePanel();
      return;
    }

    pinAsidePanel();
  };

  const handleMouseEnter = () => {
    setShouldExpandAsidePanel(true);
  };

  const handleMouseLeave = () => {
    setShouldExpandAsidePanel(false);
  };

  const ExpandedContent = (
    <>
      <div className="app_aside_logo_wrapper">
        <AppLogoHorizontalComponent className="app_aside_logo" />
      </div>
      <AppAsideExpandedNavigationComponent
        attentionCountersData={attentionCountersData}
        expandedGroupsKeys={expandedGroupsKeys}
        navigationItems={navigationItems}
        setExpandedGroupsKeys={setExpandedGroupsKeys}
        setNavigationItems={setNavigationItems}
        isExpanded={isExpanded}
        navigationDefinition={navigationDefinition}
      />
      <LinkButtonComponent
        openInNewTab
        to={marketplaceUrl}
        classNames={{
          root: "app_aside_marketplace_link",
        }}
      >
        <MarketplaceLogoFull
          className="app_aside_marketplace_logo_full"
          data-test-id="app-aside-marketplace-link"
        />
      </LinkButtonComponent>
      <AppAsidePinButtonComponent
        isPinned={isAsidePanelPinned}
        onClick={toggleAsidePanelPin}
        idForTesting="app-aside-pin-button"
      />
    </>
  );

  const MinifiedContent = (
    <>
      <div className="app_aside_logo_wrapper">
        <RailyLogo className="app_aside_logo" />
      </div>
      <AppAsideMinifiedNavigationComponent />

      <div className="app_aside_marketplace_logo_wrapper">
        <MarketplaceLogo className="app_aside_marketplace_logo_small" />
      </div>

      <AppAsidePinButtonComponent
        isPinned={isAsidePanelPinned}
        onClick={toggleAsidePanelPin}
        idForTesting="app-aside-pin-button"
      />
    </>
  );

  const Content = isExpanded ? ExpandedContent : MinifiedContent;

  return (
    <aside
      className={classNames("app_aside", isExpanded && "expanded")}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      {Content}
    </aside>
  );
});

export default AppAsideComponent;
