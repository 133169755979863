import BillingModel from "../../../types/billing-model";
import {
  BillingDataResponseContributionType,
  BillingData,
  BillingDataResponseContribution,
} from "../api/billing-data.response";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import TabsData from "../../../../../common/components/tabs/common/types/tabs-data";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsCargoTaxiContributionsDiscountTableComponent from "../../common/contributions/discount/table/billings-cargo-taxi-contributions-discount-table.component";
import BillingsCargoTaxiContributionsDiscountTableRow from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount-table-row";
import BillingsCargoTaxiContribution, {
  BillingsCargoTaxiContributionDiscountType,
} from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";
import billingsCargoTaxiContributionsDiscountHelper from "../../common/contributions/discount/billings-cargo-taxi-contributions-discount.helper";
import BillingsCargoTaxiContributionDiscount from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingDiscountContributionType = (
  contributionType:
    | BillingDataResponseContributionType.DISCOUNT_BEING_LATE
    | BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR
    | BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION
    | BillingDataResponseContributionType.DISCOUNT_OTHER
):
  | BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE
  | BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR
  | BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION
  | BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER => {
  switch (contributionType) {
    case BillingDataResponseContributionType.DISCOUNT_BEING_LATE:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE;
    case BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR;
    case BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION;
    case BillingDataResponseContributionType.DISCOUNT_OTHER:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER;
  }
};

const createBillingDiscountContribution = (
  contribution: BillingDataResponseContribution
): BillingsCargoTaxiContributionDiscount => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.CONTRACT_CORRECTION_PERCENTAGE:
      return {
        type: BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE,
        percentage: contribution.percentage,
        amount: contribution.amount,
      };
    case BillingDataResponseContributionType.DISCOUNT_BEING_LATE:
    case BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR:
    case BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION:
    case BillingDataResponseContributionType.DISCOUNT_OTHER:
      return {
        type: createBillingDiscountContributionType(contribution.type),
        comment: contribution.comment ?? "",
        amount: contribution.amount,
      };
  }
};

const createBillingContributions = (
  contributions: BillingDataResponseContribution[]
): BillingFormDataContributions => {
  const discountContribution = contributions
    .filter((contribution) =>
      [
        BillingDataResponseContributionType.DISCOUNT_BEING_LATE,
        BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR,
        BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION,
        BillingDataResponseContributionType.DISCOUNT_OTHER,
        BillingDataResponseContributionType.CONTRACT_CORRECTION_PERCENTAGE,
      ].includes(contribution.type)
    )
    .map((contribution) => createBillingDiscountContribution(contribution));

  return {
    discount: discountContribution,
  };
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    billingContributions: createBillingContributions(
      data.billing_contributions
    ),
    contractDetails: {
      companyName: data.contract.cargo_company.display_name,
      discountLimit: data.contract.discount_limit,
      haltingTimeRate: data.contract.halting_time_rate,
      distanceRate: data.contract.distance_rate,
      isHighwayAllowedDuringRide: data.contract.allow_charge_during_ride,
      isHighwayAllowedWhenApproaching:
        data.contract.allow_charge_while_approaching,
      isHighwayAllowedWhileReturning:
        data.contract.allow_charge_while_returning,
      model: data.contract.model as BillingModel,
      haltingTimeAppliedAfter: data.contract.halting_time_after_minutes,
    },
    baseAmountValue: data.base_amount_value,
    sumOfDiscounts: data.sum_of_discounts,
    amountForChargeHaltings: data.amount_for_charge_haltings,
    amountForChargeHighways: data.amount_for_charge_highways,
    distance: data.distance,
    amountForDistance: data.amount_for_distance,
    internalOrderId: data.cargo_order.human_id,
    allContributionsAmount: data.all_contributions_amount,
    planEntryId: data.plan_entry_id,
    cargoOrderId: data.cargo_order.id,
    date: data.date,
    status: data.status,
    passengers: data.passengers,
    contractCorrectionPercentage: data.contract_correction_percentage,
  };

  return billingDraft;
};

const createContributionsDiscountTableRow = (
  contribution: BillingsCargoTaxiContribution,
  contributionIndex: number
): BillingsCargoTaxiContributionsDiscountTableRow => {
  const contributionType =
    billingsCargoTaxiContributionsDiscountHelper.getContributionTypeTranslation(
      contribution.type
    );

  const translations =
    billingsTranslationsHelper.getCargoTaxiContributionsTranslations();

  switch (contribution.type) {
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: <div title={contributionType}>{contributionType}</div>,
          amount: (
            <div title={String(contribution.amount)}>{contribution.amount}</div>
          ),
          comment: (
            <div title={translations.attributeNotApplicable}>
              {translations.attributeNotApplicable}
            </div>
          ),
          percentage: (
            <div title={String(contribution.percentage)}>
              {contribution.percentage}
            </div>
          ),
          actions: <div className="d-flex"></div>,
        },
      };
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return {
        id: `${contribution.type}-${contributionIndex}`,
        value: {
          type: <div title={contributionType}>{contributionType}</div>,
          amount: (
            <div title={String(contribution.amount)}>{contribution.amount}</div>
          ),
          comment: (
            <div title={String(contribution.comment)}>
              {contribution.comment}
            </div>
          ),
          percentage: (
            <div title={translations.attributeNotApplicable}>
              {translations.attributeNotApplicable}
            </div>
          ),
          actions: <div className="d-flex"></div>,
        },
      };
    default:
      return {
        id: String(contributionIndex),
        value: {
          type: <div></div>,
          amount: <div></div>,
          comment: <div></div>,
          percentage: <div></div>,
          actions: <div className="d-flex"></div>,
        },
      };
  }
};

const createTabsData = (
  contributions: BillingFormDataContributions,
  isDataLoading: boolean
): TabsData => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiContributionsTranslations();

  const discountTableRows: BillingsCargoTaxiContributionsDiscountTableRow[] =
    contributions.discount.map((contribution, index) =>
      createContributionsDiscountTableRow(contribution, index)
    );

  const DiscountTabContent = (
    <BillingsCargoTaxiContributionsDiscountTableComponent
      isLoading={isDataLoading}
      rows={discountTableRows}
    />
  );

  const tabsData: TabsData = [
    {
      label: translations.discount.title,
      content: DiscountTabContent,
      counter: contributions.discount.filter(
        (contribution) => contribution.type
      ).length,
      totalTableRows: discountTableRows.length,
    },
  ];

  return tabsData;
};

const billingDataFactory = {
  createBillingData,
  createTabsData,
};

export default billingDataFactory;
