import CargoOrderSettlementListingItemBillingStatus from "../types/cargo-order-settlement-listing-item-billing-status";

export enum CargoOrderSettlementListingReportRequestOrder {
  DATE_DESC = "DATE_DESC",
  DATE_ASC = "DATE_ASC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  TAXI_CORPORATION_ASC = "TAXI_ASC",
  TAXI_CORPORATION_DESC = "TAXI_DESC",
  EXTERNAL_ORDER_ID_ASC = "EXTERNAL_ID_ASC",
  EXTERNAL_ORDER_ID_DESC = "EXTERNAL_ID_DESC",
  DISPATCH_ASC = "DISPATCH_ASC",
  DISPATCH_DESC = "DISPATCH_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  DISTANCE_AMOUNT_ASC = "DISTANCE_AMOUNT_ASC",
  DISTANCE_AMOUNT_DESC = "DISTANCE_AMOUNT_DESC",
  HALTING_HOURS_ASC = "HALTING_HOURS_ASC",
  HALTING_HOURS_DESC = "HALTING_HOURS_DESC",
  HALTING_AMOUNT_ASC = "HALTING_AMOUNT_ASC",
  HALTING_AMOUNT_DESC = "HALTING_AMOUNT_DESC",
  HIGHWAY_CHARGE_ASC = "HIGHWAY_CHARGE_ASC",
  HIGHWAY_CHARGE_DESC = "HIGHWAY_CHARGE_DESC",
  DISCOUNT_ASC = "DISCOUNT_ASC",
  DISCOUNT_DESC = "DISCOUNT_DESC",
  DISCOUNT_AMOUNT_ASC = "DISCOUNT_AMOUNT_ASC",
  DISCOUNT_AMOUNT_DESC = "DISCOUNT_AMOUNT_DESC",
  TOTAL_AMOUNT_ASC = "TOTAL_AMOUNT_ASC",
  TOTAL_AMOUNT_DESC = "TOTAL_AMOUNT_DESC",
}

type CargoOrderSettlementListingReportRequest = {
  order: CargoOrderSettlementListingReportRequestOrder | undefined;
  human_id: string | undefined;
  passenger: string | undefined;
  taxi: string | undefined;
  date_since: string | undefined;
  date_to: string | undefined;
  external_id: string | undefined;
  dispatch: string | undefined;
  status: CargoOrderSettlementListingItemBillingStatus | undefined;
  address: string | undefined;
  start_address: string | undefined;
  mid_address: string | undefined;
  end_address: string | undefined;
};

export default CargoOrderSettlementListingReportRequest;
