import appConfig from "../../../../../../app.config";
import urlService from "../../../../../../common/utils/url/url.service";
import authService from "../../../../../auth/common/auth.service";
import TaxiUnsettledRouteListingRequest from "./taxi-unsettled-route-listing.request";
import TaxiUnsettledRouteListingResponse from "./taxi-unsettled-route-listing.response";

const fetchListing = async (
  taxiCorporationUuid: string,
  request: TaxiUnsettledRouteListingRequest
): Promise<TaxiUnsettledRouteListingResponse> => {
  const path = `${appConfig.baseApiUrl}/taxi-corporations/${taxiCorporationUuid}/plan-entries/not-yet-billed`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const taxiUnsettledRouteListingApiService = {
  fetchListing,
};

export default taxiUnsettledRouteListingApiService;
