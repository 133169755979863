import useAsyncData from "../../../hooks/use-async-data";
import mileageService from "../mileage.service";
import MileageDetails from "./mileage-details";
import MileageDetailsLoadParams from "./mileage-details-load-params";

const useMileageDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MileageDetails | null>(null);

  const load = async (
    params: MileageDetailsLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const mileageDetails: MileageDetails = await mileageService.getDetails(
        params,
        signal
      );

      setData(mileageDetails);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = () => {
    setIsError(false);
    setIsLoading(true);
    setData(null);
  };

  return {
    data,
    isError,
    isLoading,
    load,
    reset,
  };
};

export default useMileageDetails;
