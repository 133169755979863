import formValidationService from "../../../../../../../../common/utils/validation/form-validation.service";
import OrderRouteEditExternalPassengerAddFormData from "./types/order-route-edit-external-passenger-add-form-data";
import OrderRouteEditExternalPassengerAddFormValidationResults from "./types/order-route-edit-external-passenger-add-form-validation-results";

const getDefaultFormData = (): OrderRouteEditExternalPassengerAddFormData => {
  return {
    externalId: "",
    dispatchName: "",
    firstName: "",
    lastName: "",
    mobile: "",
  };
};

const getDefaultFormValidationResults =
  (): OrderRouteEditExternalPassengerAddFormValidationResults => {
    return {
      externalId: formValidationService.defaultValidationResult,
      dispatchName: formValidationService.defaultValidationResult,
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
    };
  };

const orderRouteEditExternalPassengerAddHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default orderRouteEditExternalPassengerAddHelper;
