import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useTaxiTaxiContractListingUserPermissions from "./common/user-permissions/use-taxi-taxi-contract-listing-user-permissions";
import useTaxiTaxiContractList from "../../../../common/services/taxi-taxi-contract/list/use-taxi-taxi-contract-list";
import useAbort from "../../../../common/hooks/use-abort";
import TaxiTaxiContractListLoadParams from "../../../../common/services/taxi-taxi-contract/list/taxi-taxi-contract-list-load-params";
import taxiTaxiContractListingLoadParamsFactory from "./common/taxi-taxi-contract-listing-load-params.factory";
import useTaxiTaxiContractListingFiltersDao from "./filters/use-taxi-taxi-contract-listing-filters.dao";
import TaxiTaxiContractListingSortKey from "./common/types/taxi-taxi-contract-listing-sort-key";
import TaxiTaxiContractListingFilter from "./common/types/taxi-taxi-contract-listing-filter";
import usePagination from "../../../../common/hooks/use-pagination";
import TaxiTaxiContractListingFiltersComponent from "./filters/taxi-taxi-contract-listing-filters.component";
import TaxiTaxiContractListingTableComponent from "./table/taxi-taxi-contract-listing-table.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import { useParams } from "react-router-dom";
import TaxiTaxiContractListingRouteParams from "../../common/routes/types/taxi-taxi-contract-listing-route-params";
import useTaxiCorporationDetails from "../../../../common/services/taxi-corporation/details/use-taxi-corporation-details";
import taxiTaxiContractListingHelper from "./common/taxi-taxi-contract-listing.helper";

type TaxiTaxiContractListingProps = {};

const TaxiTaxiContractListingComponent: FC<
  TaxiTaxiContractListingProps
> = () => {
  const isComponentMounted = useIsComponentMounted();

  const userPermissions = useTaxiTaxiContractListingUserPermissions();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const { taxiCorporationUuid } =
    useParams<TaxiTaxiContractListingRouteParams>();

  useDocumentTitle(documentTitleTranslations.taxiTaxiContractListing);

  const taxiCorporationDetailsAbort = useAbort();
  const taxiCorporationDetails = useTaxiCorporationDetails();

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    taxiCorporationDetails.load(
      {
        taxiCorporationUuid: taxiCorporationUuid!,
      },
      taxiCorporationDetailsAbort.signal
    );
  }, [taxiCorporationUuid]);

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs = taxiBreadcrumbsHelper.getTaxiContractListingBreadcrumbs(
      {
        taxiCorporationUuid: taxiCorporationUuid!,
        taxiCorporationDisplayName: taxiCorporationDetails.data?.displayName,
      }
    );
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, taxiCorporationDetails.data]);

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    taxiCorporationDetails.load(
      {
        taxiCorporationUuid,
      },
      taxiCorporationDetailsAbort.signal
    );
  }, []);

  const taxiTaxiContractList = useTaxiTaxiContractList();
  const taxiTaxiContractListAbort = useAbort();

  const loadListingItems = async () => {
    const params: TaxiTaxiContractListLoadParams =
      taxiTaxiContractListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey,
        taxiCorporationUuid!
      );

    taxiTaxiContractList.load(params, taxiTaxiContractListAbort.signal);
  };

  const filtersDao = useTaxiTaxiContractListingFiltersDao();

  const [sortKey, setSortKey] = useState<TaxiTaxiContractListingSortKey>(
    () =>
      filtersDao.getSortKey() ?? TaxiTaxiContractListingSortKey.DISPLAY_NAME_ASC
  );

  const [filters, setFilters] = useState<TaxiTaxiContractListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: taxiTaxiContractList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const saveFilters = () => {
    filtersDao.saveFilters(
      filters,
      sortKey,
      page,
      pageSize,
      taxiCorporationUuid!
    );
  };

  const translations =
    taxiTranslationsHelper.getTaxiContractListingTranslations();

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize, taxiCorporationUuid]);

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    setPage(1);
  }, [pageSize, filters]);

  useEffect(() => {
    loadListingItems();

    return () => taxiTaxiContractListAbort.revoke();
  }, [page, pageSize, filters, sortKey, taxiCorporationUuid]);

  const ContractAddLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={taxiRoutesHelper.getTaxiContractAddRoute({
          taxiCorporationUuid: taxiCorporationUuid!,
        })}
        title={translations.header.addContractLinkButtonTitle}
        idForTesting="taxi-taxi-contract-listing-add-button"
      >
        {translations.header.addContractLinkButtonLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasVisibleAddContractButton
        ? ContractAddLinkButton
        : undefined,
    ];
  };

  const headingActions = createHeadingActions();

  return (
    <>
      <HeadingComponent
        title={taxiTaxiContractListingHelper.getHeadingLabel(
          taxiCorporationDetails.data?.name
        )}
        actions={headingActions}
      />
      <TaxiTaxiContractListingFiltersComponent
        filters={filters}
        onFiltersChange={setFilters}
        sortKey={sortKey}
        onSortKeyChange={setSortKey}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TaxiTaxiContractListingTableComponent
          listingItems={taxiTaxiContractList.data.data}
          isError={taxiTaxiContractList.isError}
          isLoading={taxiTaxiContractList.isLoading}
          taxiCorporationUuid={taxiCorporationUuid}
        />
        <ListingPaginationComponent
          onPageChange={onPageChange}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={taxiTaxiContractList.data.totalCount}
        />
      </CardComponent>
    </>
  );
};

export default TaxiTaxiContractListingComponent;
