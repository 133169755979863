import SignUpPassengerFormAccountData from "./types/sign-up-passenger-form-account-data";
import SignUpPassengerFormUserData from "./types/sign-up-passenger-form-user-data";
import SignUpPassengerTokenPayload from "../../common/types/sign-up-passenger-token-payload";
import SignUpPassengerUserDataDisabledFormFields from "./types/sign-up-passenger-user-data-disabled-form-fields";
import SignUpPassengerAccountDataDisabledFormFields from "./types/sign-up-passenger-account-data-disabled-form-fields";

const createUserFormData = (
  tokenPayload: SignUpPassengerTokenPayload
): SignUpPassengerFormUserData => {
  return {
    firstName: tokenPayload.user_data.first_name ?? "",
    lastName: tokenPayload.user_data.last_name ?? "",
    mobile: {
      dialingCode: tokenPayload.user_data.mobile_primary_prefix ?? "",
      number: tokenPayload.user_data.mobile_primary ?? "",
    },
    alternativeMobile: {
      dialingCode: tokenPayload.user_data.mobile_secondary_prefix ?? "",
      number: tokenPayload.user_data.mobile_secondary ?? "",
    },
    addresses: [],
  };
};

const createAccountFormData = (
  tokenPayload: SignUpPassengerTokenPayload
): SignUpPassengerFormAccountData => {
  return {
    email: tokenPayload.user_data.email ?? "",
    username: tokenPayload.user_data.username ?? "",
    password: "",
    repeatPassword: "",
  };
};

const createUserDisabledFormFields = (
  tokenPayload: SignUpPassengerTokenPayload
): SignUpPassengerUserDataDisabledFormFields => {
  return {
    firstName: !!tokenPayload.user_data.first_name,
    lastName: !!tokenPayload.user_data.last_name,
    mobile: !!tokenPayload.user_data.mobile_primary,
    alternativeMobile: !!tokenPayload.user_data.mobile_secondary,
  };
};

const createAccountDisabledFormFields = (
  tokenPayload: SignUpPassengerTokenPayload
): SignUpPassengerAccountDataDisabledFormFields => {
  return {
    email: !!tokenPayload.user_data.email,
    username: !!tokenPayload.user_data.username,
  };
};

const signUpPassengerFormFactory = {
  createUserFormData,
  createAccountFormData,
  createUserDisabledFormFields,
  createAccountDisabledFormFields,
};

export default signUpPassengerFormFactory;
