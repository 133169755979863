import { FC } from "react";
import orderOptimizerTranslationsHelper from "../../../../../../languages/order-optimizer-translations.helper";
import orderOptimizerListingTableRowSummaryHelper from "./order-optimizer-listing-table-row-summary.helper";
import orderOptimizerListingHelper from "../../../common/order-optimizer-listing.helper";
import OrderOptimizerListingItem from "../../../common/types/order-optimizer-listing-item";

type OrderOptimizerListingTableRowSummaryProps = {
  listingItem: OrderOptimizerListingItem;
};

const OrderOptimizerListingTableRowSummaryComponent: FC<
  OrderOptimizerListingTableRowSummaryProps
> = (props) => {
  const translations =
    orderOptimizerTranslationsHelper.getListingTranslations().table.summary;

  const lastRide =
    props.listingItem.emptyRides[props.listingItem.emptyRides.length - 1];

  const orderStartsInSeconds = orderOptimizerListingHelper.getTimeDelta(
    props.listingItem.orders[0].startTime
  );

  return (
    <div className="order_optimizer_table_row_summary">
      <div>
        <div className="order_optimizer_table_row_summary__score_label">
          {translations.scoreLabel}
        </div>
        <div className="order_optimizer_table_row_summary__score_data">
          {orderOptimizerListingTableRowSummaryHelper.getScoreLabel(
            props.listingItem.relativeScore
          )}
        </div>
      </div>
      <div>
        <div className="order_optimizer_table_row_summary__label">
          {translations.orderStartsInSecondsLabel}
        </div>
        <div className="order_optimizer_table_row_summary__data">
          {orderStartsInSeconds <= 0
            ? translations.orderInProggressLabel
            : orderOptimizerListingHelper.getTimeDeltaLabel(
                orderStartsInSeconds
              )}
        </div>
      </div>
      <div>
        <div className="order_optimizer_table_row_summary__label">
          {translations.returnDistanceLabel}
        </div>
        <div className="order_optimizer_table_row_summary__data">
          {orderOptimizerListingHelper.getDistanceLabel(lastRide.osrmDistance)}
        </div>
      </div>
      <div>
        <div className="order_optimizer_table_row_summary__label">
          {translations.returnTimeLabel}
        </div>
        <div className="order_optimizer_table_row_summary__data">
          {orderOptimizerListingHelper.getTimeDeltaLabel(lastRide.osrmTime)}
        </div>
      </div>
    </div>
  );
};

export default OrderOptimizerListingTableRowSummaryComponent;
