import MileageAddressListRequest, {
  MileageAddressListQueryParams,
} from "./mileage-address-list.request";

const createQueryParams = (
  request: MileageAddressListRequest
): MileageAddressListQueryParams => {
  return {
    creationEnd: request.creationEnd,
    creationStart: request.creationStart,
    order: request.order,
    searchQuery: request.searchQuery,
  };
};

const mileageAddressListRequestFactory = {
  createQueryParams,
};

export default mileageAddressListRequestFactory;
