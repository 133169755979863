import { FC, useEffect, useMemo, useState } from "react";
import mileageAddRoutesHelper from "./mileage-add-routes.helper";
import { MileageAddRouteItem } from "./mileage-add-routes.types";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";
import MileageAddAddress from "../../types/mileage-add-address";
import MileageAddRouteItemComponent from "./mileage-add-route-item.component";
import useCargoCompanyAddressList from "../../../../../../common/services/cargo-company/address/list/use-cargo-company-address-list";
import useAbort from "../../../../../../common/hooks/use-abort";
import CargoCompanyAddressListLoadParams from "../../../../../../common/services/cargo-company/address/list/cargo-company-address-list-load-params";
import mileageAddAddressFactory from "../../factory/mileage-add-address.factory";

type MileageAddRoutesProps = {
  cargoCompanyUuid: string | undefined;
  routes: MileageAddRouteItem[];
  onRoutesChange: (addresses: MileageAddRouteItem[]) => void;
  additionalAddresses: MileageAddAddress[];
  isAddButtonDisabled: boolean;
  onBlur?: () => void;
  idForTesting?: string;
};

const MileageAddRoutesComponent: FC<MileageAddRoutesProps> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().routes;

  const [returnAddressRouteItemUuid, setReturnAddressRouteItemUuid] = useState<
    string | null
  >(null);

  const cargoCompanyAddressList = useCargoCompanyAddressList();
  const cargoCompanyAddressListAbort = useAbort();

  const loadCargoCompanyAddresses = (cargoCompanyUuid: string) => {
    const loadParams: CargoCompanyAddressListLoadParams = {
      companyUuid: cargoCompanyUuid,
    };

    cargoCompanyAddressList.load(
      loadParams,
      cargoCompanyAddressListAbort.signal
    );
  };

  useEffect(() => {
    if (!props.cargoCompanyUuid) {
      return;
    }

    loadCargoCompanyAddresses(props.cargoCompanyUuid);

    return cargoCompanyAddressListAbort.revoke;
  }, [props.cargoCompanyUuid]);

  const cargoCompanyAddresses = useMemo(
    () =>
      cargoCompanyAddressList.data.map(
        mileageAddAddressFactory.createFromCargoAddress
      ),
    [cargoCompanyAddressList.data]
  );

  const onAddRouteButtonClick = () => {
    const newEmptyRoute = mileageAddRoutesHelper.getNewEmptyRoute();

    const newRoutes: MileageAddRouteItem[] = [...props.routes, newEmptyRoute];

    props.onRoutesChange(newRoutes);
  };

  const onAddReturnAddressButtonClick = () => {
    if (props.routes.length === 0) {
      return;
    }

    const returnAddressRouteItem = mileageAddRoutesHelper.getNewEmptyRoute();

    returnAddressRouteItem.address = props.routes[0].address;

    const newRoutes: MileageAddRouteItem[] = [
      ...props.routes,
      returnAddressRouteItem,
    ];

    props.onRoutesChange(newRoutes);
    setReturnAddressRouteItemUuid(returnAddressRouteItem.uuid);
  };

  const updateRouteItem = (updatedRouteItem: MileageAddRouteItem) => {
    const routeToUpdate = props.routes.find(
      (route) => route.uuid === updatedRouteItem.uuid
    );

    if (!routeToUpdate) {
      return;
    }

    const newRoutes: MileageAddRouteItem[] = [...props.routes];

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);
    newRoutes[indexOfRouteToUpdate] = updatedRouteItem;

    props.onRoutesChange(newRoutes);
  };

  const deleteRouteItem = (routeItemUuid: MileageAddRouteItem["uuid"]) => {
    const newRoutes: MileageAddRouteItem[] = props.routes.filter(
      (route) => route.uuid !== routeItemUuid
    );

    if (routeItemUuid === returnAddressRouteItemUuid) {
      setReturnAddressRouteItemUuid(null);
    }

    props.onRoutesChange(newRoutes);
  };

  const getReorderedRouteItems = (
    startIndex: number,
    endIndex: number
  ): MileageAddRouteItem[] => {
    const result = [...props.routes];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragRouteListItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedRoutes = getReorderedRouteItems(
      result.source.index,
      result.destination.index
    );

    props.onRoutesChange(reorderedRoutes);
  };

  const isDraggingEnabled = props.routes.length > 1;

  return (
    <>
      <DragDropContext onDragEnd={onDragRouteListItemEnd}>
        <Droppable droppableId="mileage_add_route_list_drop_area">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div>
                {props.routes.map((route, routeIndex) => {
                  return (
                    <Draggable
                      key={route.uuid}
                      draggableId={route.uuid}
                      index={routeIndex}
                      isDragDisabled={!isDraggingEnabled}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <MileageAddRouteItemComponent
                            routeIndex={routeIndex}
                            routeItem={route}
                            updateRouteItem={updateRouteItem}
                            deleteRouteItem={() => deleteRouteItem(route.uuid)}
                            additionalAddresses={props.additionalAddresses}
                            cargoCompanyAddresses={cargoCompanyAddresses}
                            dragHandleProps={provided.dragHandleProps}
                            onBlur={props.onBlur}
                            idForTesting={props.idForTesting}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="mileage_add_route_list_item__row">
        <div className="mileage_add_route_list_item__space">
          <ButtonComponent
            onClick={onAddRouteButtonClick}
            type="primary"
            title={translations.addNewButtonTitle}
            isDisabled={props.isAddButtonDisabled}
            idForTesting={"mileage-add-route-new-button"}
          >
            {translations.addNewButtonLabel}
          </ButtonComponent>
        </div>
        <ButtonComponent
          onClick={onAddReturnAddressButtonClick}
          type="primary"
          title={translations.addReturnAddressButtonTitle}
          isDisabled={
            props.routes.length === 0 ||
            props.routes[0].address === null ||
            !!returnAddressRouteItemUuid
          }
          idForTesting={"mileage-add-route-return-address-button"}
        >
          {translations.addReturnAddressButtonLabel}
        </ButtonComponent>
      </div>
    </>
  );
};

export default MileageAddRoutesComponent;
