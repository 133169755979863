import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import CargoCompanySelectOption from "./cargo-company-select-option";
import CargoCompanySelectValue from "./cargo-company-select-value";

const createOptionSubText = (selectValue: CargoCompanySelectValue): string => {
  const translations =
    appTranslationsHelper.getComponentTranslations().select.cargoCompany
      .subText;

  const subTexts: string[] = [];

  if (selectValue.name) {
    subTexts.push(selectValue.name);
  }

  if (selectValue.taxNumber) {
    subTexts.push(
      `${translations.taxNumberTemplateLabel.replace(
        "#{value}",
        selectValue.taxNumber
      )}`
    );
  }

  if (selectValue.companyId) {
    subTexts.push(
      `${translations.companyIdTemplateLabel.replace(
        "#{value}",
        selectValue.companyId
      )}`
    );
  }

  if (selectValue.nationalCourtRegister) {
    subTexts.push(
      `${translations.nationalCourtRegisterTemplateLabel.replace(
        "#{value}",
        selectValue.nationalCourtRegister
      )}`
    );
  }

  return subTexts.join(" | ");
};

const createOption = (
  selectValue: CargoCompanySelectValue
): CargoCompanySelectOption => {
  return {
    label: selectValue.displayName,
    subText: createOptionSubText(selectValue),
    value: selectValue,
  };
};

const createOptions = (
  selectValues: CargoCompanySelectValue[]
): CargoCompanySelectOption[] => {
  return selectValues.map(createOption);
};

const cargoCompanySelectOptionFactory = { createOptions };

export default cargoCompanySelectOptionFactory;
