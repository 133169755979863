import ListingFilter from "../../../../../common/types/listing-filter";
import PlanningOrderDriverPlanListingFilterType from "./planning-order-driver-plan-listing-filter-type";

export type PlanningOrderDriverPlanListingAddressFilter = ListingFilter<
  PlanningOrderDriverPlanListingFilterType.ADDRESS,
  {
    address: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
  }
>;

export type PlanningOrderDriverPlanListingDriverNameFilter = ListingFilter<
  PlanningOrderDriverPlanListingFilterType.DRIVER_NAME,
  string
>;

export type PlanningOrderDriverPlanListingCargoOrderFilter = ListingFilter<
  PlanningOrderDriverPlanListingFilterType.CARGO_ORDER,
  string
>;

export enum PlanningOrderDriverPlanListingResultFilterValue {
  NEW_PLAN = "NEW_PLAN",
  EXISTING_PLAN = "EXISTING_PLAN",
}

export type PlanningOrderDriverPlanListingResultFilter = ListingFilter<
  PlanningOrderDriverPlanListingFilterType.RESULT,
  PlanningOrderDriverPlanListingResultFilterValue
>;

type PlanningOrderDriverPlanListingFilter =
  | PlanningOrderDriverPlanListingAddressFilter
  | PlanningOrderDriverPlanListingDriverNameFilter
  | PlanningOrderDriverPlanListingCargoOrderFilter
  | PlanningOrderDriverPlanListingResultFilter;

export default PlanningOrderDriverPlanListingFilter;
