import useAsyncData from "../../../hooks/use-async-data";
import dispatcherService from "../dispatcher.service";
import DispatcherEditForm from "./dispatcher-edit-form";
import DispatcherEditFormError from "./dispatcher-edit-form-error";
import DispatcherEditFormLoadParams from "./dispatcher-edit-form-load-params";

const useDispatcherEditForm = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<DispatcherEditForm | null>(null);

  const load = async (
    params: DispatcherEditFormLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await dispatcherService.getEditForm(params, signal);

      setData(data);
      setIsLoading(false);
    } catch (_error) {
      const error = _error as DispatcherEditFormError;

      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useDispatcherEditForm;
