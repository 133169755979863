import { ReactNode } from "react";
import MapRoute from "../../../../common/components/map/types/map-route";
import RouteDetails, {
  RouteDetailsAddressNode,
} from "../../../../common/services/route/details/route-details";
import RouteRoadRoute from "../../../../common/services/route/road-route/route-road-route";
import RouteDetailsRouteWaypoint from "./types/route-details-route-waypoint";
import MapMarker from "../../../../common/components/map/types/map-marker";
import mapMarkerIconFactory from "../../../../common/components/map/marker/map-marker-icon.factory";
import { DivIcon } from "leaflet";
import { isEqual } from "lodash";
import RouteDetailsMapMarkerType from "./types/route-details-map-marker-type";
import { RouteCompletedRouteData } from "../../../../common/services/route/completed-route/route-completed-route";
import RouteDetailsRouteWaypointGroup from "./types/route-details-route-waypoint-group";

const createWaypoint = (
  routeDetailsSequenceItem: RouteDetailsAddressNode,
  markerPosition: number,
  completionDate: Date | undefined,
  distance: number
): RouteDetailsRouteWaypoint => {
  return {
    haltingTime: routeDetailsSequenceItem.haltingTime ?? 0,
    onboardingPassengersUuids:
      routeDetailsSequenceItem.onboardingPassengersUuids,
    outboardingPassengersUuids:
      routeDetailsSequenceItem.outboardingPassengersUuids,
    orderedDate: routeDetailsSequenceItem.plannedDate,
    place: {
      displayName: routeDetailsSequenceItem.displayName ?? "",
      latitude: routeDetailsSequenceItem.latitude,
      longitude: routeDetailsSequenceItem.longitude,
    },
    markerPosition,
    completionDate: completionDate ?? null,
    distance: distance,
    estimatedDate: routeDetailsSequenceItem.estimatedTime,
  };
};

const createWaypointGroups = (
  routeDetails: RouteDetails,
  roadRoutes: RouteRoadRoute[][]
): RouteDetailsRouteWaypointGroup[] => {
  const waypointGroups: RouteDetailsRouteWaypointGroup[] = [];

  let markerPosition = 1;

  routeDetails.solvedOrders.forEach((order, orderIndex) => {
    const waypoints: RouteDetailsRouteWaypoint[] = [];
    order.ride.seq.forEach((sequenceItem, sequenceItemIndex) => {
      const completionDate = routeDetails.checkoutEvents.find(
        (event) => event.nodeUuid === sequenceItem.uuid
      )?.arrivalDate;

      const distance =
        !!roadRoutes.length && sequenceItemIndex !== 0
          ? roadRoutes[orderIndex][0].legs[sequenceItemIndex - 1].distance
          : 0;

      const newWaypoint = createWaypoint(
        sequenceItem,
        markerPosition,
        completionDate,
        distance
      );

      waypoints.push(newWaypoint);

      markerPosition++;
    });

    waypointGroups.push({
      waypoints,
      excludeHighway: order.excludeHighway,
    });
  });

  return waypointGroups;
};

const createMapRoute = (route: RouteRoadRoute): MapRoute => {
  return {
    waypoints: route.coordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
  };
};

const createMapRoutes = (routes: RouteRoadRoute[]): MapRoute[] => {
  return routes.map(createMapRoute);
};

const createMapMarker = (
  route: RouteDetailsRouteWaypoint,
  type: RouteDetailsMapMarkerType,
  content?: ReactNode
): MapMarker => {
  const onboardingMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker onboarding",
    content: content,
  });

  const outboardingMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker outboarding",
    content: content,
  });

  const standardMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: content,
  });

  const weatheredMarkerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker weathered",
    content: content,
  });

  const iconOptions: {
    type: RouteDetailsMapMarkerType;
    icon: DivIcon;
  }[] = [
    {
      type: RouteDetailsMapMarkerType.ONBOARDING,
      icon: onboardingMarkerIcon,
    },
    {
      type: RouteDetailsMapMarkerType.OUTBOARDING,
      icon: outboardingMarkerIcon,
    },
    {
      type: RouteDetailsMapMarkerType.STANDARD,
      icon: standardMarkerIcon,
    },
    {
      type: RouteDetailsMapMarkerType.WEATHERED,
      icon: weatheredMarkerIcon,
    },
  ];

  const icon: MapMarker["icon"] = iconOptions.find((icon) => icon.type === type)
    ?.icon!;

  const marker: MapMarker = {
    coordinate: {
      latitude: route.place.latitude,
      longitude: route.place.longitude,
    },
    title: route.place.displayName,
    tooltip: route.place.displayName,
    icon,
  };

  return marker;
};

const createMapMarkersForNotSelectedPassenger = (
  routeWaypoints: RouteDetailsRouteWaypoint[]
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  const flattenedWaypoints = routeWaypoints.flat();

  for (const waypoint of flattenedWaypoints) {
    const allWaypointsOnThisPlace = flattenedWaypoints.filter((w) =>
      isEqual(w.place, waypoint.place)
    );

    const signature = allWaypointsOnThisPlace
      .map((w) => w.markerPosition)
      .join("/");

    const newMapMarker = createMapMarker(
      waypoint,
      RouteDetailsMapMarkerType.STANDARD,
      signature
    );

    mapMarkers = [...mapMarkers, newMapMarker];
  }

  return mapMarkers;
};

const createOnboardingMapMarker = (waypoint: RouteDetailsRouteWaypoint) => {
  const signature = `${waypoint.markerPosition}`;

  const newMarker: MapMarker = createMapMarker(
    waypoint,
    RouteDetailsMapMarkerType.ONBOARDING,
    signature
  );

  return newMarker;
};

const createOutboardingMapMarker = (waypoint: RouteDetailsRouteWaypoint) => {
  const signature = `${waypoint.markerPosition}`;

  const newMarker: MapMarker = createMapMarker(
    waypoint,
    RouteDetailsMapMarkerType.OUTBOARDING,
    signature
  );

  return newMarker;
};

const createMapMarkersForSelectedPassenger = (
  routeWaypoints: RouteDetailsRouteWaypoint[],
  selectedPassengerUuid: string
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  const flattenedWaypoints = routeWaypoints.flat();

  for (const waypoint of flattenedWaypoints) {
    const isOnboardingRoute = waypoint.onboardingPassengersUuids.includes(
      selectedPassengerUuid
    );

    if (isOnboardingRoute) {
      const newMarker = createOnboardingMapMarker(waypoint);

      mapMarkers = [...mapMarkers, newMarker];

      continue;
    }

    const isOutboardingRoute = waypoint.outboardingPassengersUuids.includes(
      selectedPassengerUuid
    );

    if (isOutboardingRoute) {
      const newMarker = createOutboardingMapMarker(waypoint);

      mapMarkers = [...mapMarkers, newMarker];
      continue;
    }

    const allWaypointsOnThisPlace = flattenedWaypoints.filter((w) =>
      isEqual(w.place, waypoint.place)
    );

    const signature = allWaypointsOnThisPlace
      .map((w) => w.markerPosition)
      .join("/");

    const newMarker: MapMarker = createMapMarker(
      waypoint,
      RouteDetailsMapMarkerType.WEATHERED,
      signature
    );

    mapMarkers = [...mapMarkers, newMarker];
  }

  return mapMarkers;
};

const createMapMarkers = (
  routes: RouteDetailsRouteWaypoint[],
  selectedPassengerUuid?: string
): MapMarker[] => {
  if (!selectedPassengerUuid) {
    return createMapMarkersForNotSelectedPassenger(routes);
  }

  return createMapMarkersForSelectedPassenger(routes, selectedPassengerUuid);
};

const createCompletedMapRoute = (routes: RouteCompletedRouteData): MapRoute => {
  const sortedItems = routes.sort(
    (itemA, itemB) => itemA.recordTime.getTime() - itemB.recordTime.getTime()
  );

  const waypoints: MapRoute["waypoints"] = sortedItems.map((item) => ({
    latitude: item.latitude,
    longitude: item.longitude,
  }));

  const mapRoute: MapRoute = {
    waypoints,
    options: {
      color: "#3699ff",
    },
  };

  return mapRoute;
};

const routeDetailsFactory = {
  createWaypointGroups,
  createMapRoutes,
  createMapMarkers,
  createCompletedMapRoute,
};

export default routeDetailsFactory;
