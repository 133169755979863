import { FC, useEffect, useMemo, useState } from "react";
import CardComponent from "../../../common/components/card/card.component";
import HeadingComponent from "../../../common/components/heading/heading.component";
import TableComponent from "../../../common/components/table/table.component";
import { useAppContext } from "../../../context/app.context";
import orderFinishListingApiService from "./common/api/order-abandoned-listing-api.service";
import OrderAbandonedListingResponse from "./common/api/order-abandoned-listing.response";
import orderAbandonedListingFactory from "./common/factory/order-abandoned-listing.factory";
import orderAbandonedListingHelper from "./common/helper/order-abandoned-listing.helper";
import OrderAbandonedListingItem from "./common/types/order-abandoned-listing-item";
import OrderAbandonedListingTableRouteComponent from "./common/table/route/order-abandoned-listing-table-route.component";
import OrderAbandonedListingTableRow from "./common/types/order-abandoned-listing-table-row";
import PaginationComponent from "../../../common/components/pagination/pagination.component";
import OrderAbandonedListingFiltersComponent from "./filters/order-abandoned-listing-filters.component";
import OrderAbandonedListingFilter from "./common/types/order-abandoned-listing-filter";
import OrderAbandonedListingSortComponent from "./sort/order-abandoned-listing-sort.component";
import orderAbandonedListingSortHelper from "./common/helper/order-abandoned-listing-sort.helper";
import OrderAbandonedListingSortKey from "./common/types/order-abandoned-listing-sort-key";
import DateRange from "../../../common/types/date-range";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import orderBreadcrumbsHelper from "../common/breadcrumbs/order-breadcrumbs.helper";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import orderAbandonedListingRequestFactory from "./common/factory/order-abandonded-listing-request.factory";
import OrderDetailsComponent from "../details/order-details.component";
import dateService from "../../../common/utils/date/date.service";
import DateRangeInputComponent from "../../../common/components/form/input/date-range/date-range-input.component";
import OrderAbandonedListingFiltersBadgeComponent from "./filters/badge/order-abandoned-listing-filters-badge.component";
import ButtonComponent from "../../../common/components/button/button.component";

const OrderAbandonedListingComponent: FC = () => {
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filters, setFilters] = useState<OrderAbandonedListingFilter[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedSortKey, setSelectedSortKey] =
    useState<OrderAbandonedListingSortKey | null>(
      orderAbandonedListingSortHelper.getDefaultSelectOption().value
    );

  const [startOrderDateRange, setStartOrderDateRange] =
    useState<DateRange | null>(null);
  const [listingItems, setListingItems] = useState<OrderAbandonedListingItem[]>(
    []
  );

  const [expandedRowsOrderUuids, setExpandedRowsOrderUuids] = useState<
    OrderAbandonedListingItem["uuid"][]
  >([]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderAbandonedListing);

  const translations =
    orderTranslationsHelper.getAbandonedListingTranslations();

  const onListingFetchSuccess = (response: OrderAbandonedListingResponse) => {
    const listingItems = orderAbandonedListingFactory.createOrderListingItems(
      response.data.data
    );

    setTotalCount(response.data.total_count);
    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (response: OrderAbandonedListingResponse) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request =
      orderAbandonedListingRequestFactory.createFetchListingRequest(
        filters,
        selectedSortKey,
        startOrderDateRange,
        pageSize,
        currentPage
      );

    orderFinishListingApiService
      .fetchListing(request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    fetchListing();
  }, [filters, currentPage, pageSize, selectedSortKey, startOrderDateRange]);

  const tableColumns = orderAbandonedListingHelper.getTableColumns();

  const createTableRow = (
    listingItem: OrderAbandonedListingItem
  ): OrderAbandonedListingTableRow => {
    const onTableRowClick = () => {
      const expandRow = (orderUuid: OrderAbandonedListingItem["uuid"]) => {
        setExpandedRowsOrderUuids((curr) => [...curr, orderUuid]);
      };

      const collapseRow = (orderUuid: OrderAbandonedListingItem["uuid"]) => {
        const filteredExpandedRows = expandedRowsOrderUuids.filter(
          (expandedOrderUuid) => expandedOrderUuid !== orderUuid
        );

        setExpandedRowsOrderUuids(filteredExpandedRows);
      };

      const isRowExpanded = expandedRowsOrderUuids.includes(listingItem.uuid);

      if (isRowExpanded) {
        collapseRow(listingItem.uuid);
        return;
      }

      expandRow(listingItem.uuid);
    };

    const orderDate = dateService.format(listingItem.orderDate, "dd.mm.yyyy");

    const isHiddenComponentVisible = expandedRowsOrderUuids.includes(
      listingItem.uuid
    );

    return {
      id: listingItem.uuid,
      onClick: onTableRowClick,
      value: {
        date: <div title={orderDate}>{orderDate}</div>,
        cargoCompanyOrderId: (
          <div title={String(listingItem.cargoCompanyOrderId)}>
            {listingItem.cargoCompanyOrderId}
          </div>
        ),
        client: (
          <div title={listingItem.producerName}>{listingItem.producerName}</div>
        ),
        dispatch: (
          <div title={listingItem.dispatchName}>{listingItem.dispatchName}</div>
        ),
        cargoCompanyExternalOrderId: (
          <div title={listingItem.cargoCompanyExternalOrderId ?? ""}>
            {listingItem.cargoCompanyExternalOrderId}
          </div>
        ),
        passenger: (
          <div>
            {listingItem.passengers.map((passenger, index) => (
              <div key={index} title={passenger}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        route: (
          <OrderAbandonedListingTableRouteComponent
            addresses={listingItem.routeAddresses}
          />
        ),
      },
      hiddenComponent: (
        <OrderDetailsComponent
          orderUuid={listingItem.uuid}
          cargoCompanyOrderId={listingItem.cargoCompanyOrderId}
          isVisible={isHiddenComponentVisible}
        />
      ),
      isHiddenComponentVisible,
    };
  };

  const tableRows: OrderAbandonedListingTableRow[] = useMemo(() => {
    return listingItems.map((listingItem) => createTableRow(listingItem));
  }, [listingItems, expandedRowsOrderUuids]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
    setExpandedRowsOrderUuids([]);
  };

  useEffect(() => {
    const breadcrumbs = orderBreadcrumbsHelper.getAbandonedListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const addNewFilter = (newFilter: OrderAbandonedListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setCurrentPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filterItem) => {
      return filters.indexOf(filterItem) !== index;
    });

    setFilters(newFilters);
    setCurrentPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setCurrentPage(1);
  };

  const onSortKeyChange = (key: OrderAbandonedListingSortKey | null) => {
    setSelectedSortKey(key);
    setExpandedRowsOrderUuids([]);
  };

  const onStartOrderDateRangeChange = (date: DateRange | null) => {
    setStartOrderDateRange(date);
  };

  return (
    <div className="order_abandoned_listing">
      <HeadingComponent title={translations.header.headingText} />
      <div className="order_abandoned_listing_tools">
        <OrderAbandonedListingFiltersComponent
          filters={filters}
          onAddNewFilter={addNewFilter}
        />
        <DateRangeInputComponent
          onChange={onStartOrderDateRangeChange}
          date={startOrderDateRange}
          placeholder={translations.dataRangePlaceholderLabel}
          idForTesting="order-abandonded-listing-date-range-start"
        />
        <OrderAbandonedListingSortComponent
          sortKey={selectedSortKey}
          onSortKeyChange={onSortKeyChange}
        />
      </div>
      <div className="order_abandoned_listing_filters__list">
        {filters.map((filter, index) => (
          <OrderAbandonedListingFiltersBadgeComponent
            key={`${filter.type}-${filter.value}`}
            filter={filter}
            onDeleteButtonClick={() => deleteFilter(index)}
          />
        ))}
        {!!filters.length && (
          <ButtonComponent onClick={deleteAllFilters}>
            Usuń wszystkie
          </ButtonComponent>
        )}
      </div>
      <CardComponent classNames={{ root: "mt-4" }}>
        <TableComponent
          columns={tableColumns}
          rows={tableRows}
          isLoading={isListingFetching}
          isError={isListingFetchingError}
        />
        <div className="order_abandoned_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={setPageSize}
            page={currentPage}
            pageSize={pageSize}
            totalResults={totalCount}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default OrderAbandonedListingComponent;
