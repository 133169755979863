import CargoCompanyDispatchMultiListRequest from "../../../../api/raily/cargo-company/dispatch/multi-list/cargo-company-dispatch-multi-list.request";
import paginationService from "../../../../utils/pagination/pagination.service";
import CargoCompanyDispatchMultiListLoadParams from "./cargo-company-dispatch-multi-list-load-params";

const create = (
  params: CargoCompanyDispatchMultiListLoadParams
): CargoCompanyDispatchMultiListRequest => {
  return {
    companyIds: params.cargoCompanyUuids,
    limit: params.pageSize,
    offset:
      params.page && params.pageSize
        ? paginationService.calculateOffset(params.page, params.pageSize)
        : undefined,
  };
};

const cargoCompanyDispatchMultiListRequestFactory = {
  create,
};

export default cargoCompanyDispatchMultiListRequestFactory;
