import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import { TaxiListingRouteQueryParams } from "../../../user/common/routes/types/taxi-listing-route-params";
import TaxiListingFilter from "../common/types/taxi-listing-filter";
import TaxiListingSortKey from "../common/types/taxi-listing-sort-key";
import taxiListingFiltersService from "./taxi-listing-filters.service";

const useTaxiListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiListingRouteQueryParams>();

  const getFilters = (): TaxiListingFilter[] => {
    return taxiListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return taxiListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return taxiListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): TaxiListingSortKey | undefined => {
    return taxiListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: TaxiListingFilter[],
    sortKey: TaxiListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams = taxiListingFiltersService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useTaxiListingFiltersDao;
