import urlService from "../url/url.service";

const httpService = (baseUrl: string, headers?: HeadersInit) => {
  const httpGet = <T>(
    path: string,
    query: { [key: string]: any } = {}
  ): Promise<T> => {
    const requestParams: RequestInit = { method: "GET" };

    const requestUrl = urlService.buildWithoutEmptyParams(path, query);

    return sendRequest(requestUrl, requestParams);
  };

  const httpPost = <T>(
    path: string,
    body: { [key: string]: any } = {}
  ): Promise<T> => {
    const requestParams: RequestInit = {
      method: "POST",
      body: JSON.stringify(body),
    };

    return sendRequest<T>(path, requestParams);
  };

  const httpPut = <T>(
    path: string,
    body: { [key: string]: any } = {}
  ): Promise<T> => {
    const requestParams: RequestInit = {
      method: "PUT",
      body: JSON.stringify(body),
    };

    return sendRequest<T>(path, requestParams);
  };

  const httpDelete = <T>(
    path: string,
    body: { [key: string]: any } = {}
  ): Promise<T> => {
    const requestParams: RequestInit = {
      method: "DELETE",
      body: JSON.stringify(body),
    };

    return sendRequest<T>(path, requestParams);
  };

  const sendRequest = <T>(path: string, params?: RequestInit): Promise<T> => {
    const fullApiUrl = `${baseUrl}${path}`;

    const requestParams: RequestInit = {
      ...params,
      headers: {
        ...headers,
        ...params?.headers,
      },
    };

    return new Promise(async (resolve, reject) => {
      fetch(fullApiUrl, requestParams)
        .then(async (response) => {
          if (response.status === 204) {
            resolve(undefined as unknown as any);
            return;
          }

          if (response.status === 200) {
            resolve(response.json());
            return;
          }

          throw response;
        })
        .catch(async (error: Response | string) => {
          if (error.toString() === "TypeError: Failed to fetch") {
            reject(undefined);
            return;
          }

          const errorResponse = error as Response;
          try {
            const statusCode = errorResponse.status;
            const responseJson = await errorResponse.json();
            createErrorResponse(responseJson, statusCode);

            reject(responseJson);
            return;
          } catch (error) {
            reject();
          }
        });
    });
  };

  const createErrorResponse = (responseJson: any, statusCode: number) => {
    return {
      status: statusCode,
      response: responseJson,
    };
  };

  return {
    get: httpGet,
    post: httpPost,
    put: httpPut,
    delete: httpDelete,
  };
};

export default httpService;
