import { FC } from "react";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import LinkButtonComponent from "../../../../../../common/components/button/link/link-button.component";
import ModalComponent from "../../../../../../common/components/modal/modal.component";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import orderRoutesHelper from "../../../../common/routes/order-routes.helper";

type OrderAddSuccessModalProps = {
  isOpen: boolean;
  onCloseClick: () => void;
  addedOrderUuid: string;
  onAddNextOrderButtonClick: () => void;
};

const OrderAddSuccessModalComponent: FC<OrderAddSuccessModalProps> = (
  props
) => {
  const translations =
    orderTranslationsHelper.getAddTranslations().addSuccessModal;

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={props.onCloseClick}
      actions={[
        <LinkButtonComponent
          type="primary"
          title={translations.addNotesTitle}
          to={orderRoutesHelper.getListingOfActiveOrdersRoute({
            defaultSelectedOrderUuid: props.addedOrderUuid,
          })}
          idForTesting={`add-notes-link-button`}
        >
          {translations.addNotesText}
        </LinkButtonComponent>,
        <LinkButtonComponent
          type="primary"
          title={translations.goToListingOfActiveOrdersTitle}
          to={orderRoutesHelper.getListingOfActiveOrdersRoute()}
          idForTesting={`go-active-order-listing-link-button`}
        >
          {translations.goToListingOfActiveOrdersText}
        </LinkButtonComponent>,
        <ButtonComponent
          onClick={props.onAddNextOrderButtonClick}
          type="primary"
          title={translations.addNextOrderTitle}
          idForTesting={`add-next-order-button`}
        >
          {translations.addNextOrderText}
        </ButtonComponent>,
      ]}
    >
      {translations.messageText}
    </ModalComponent>
  );
};

export default OrderAddSuccessModalComponent;
