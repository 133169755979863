import SearchRoadRoutingResponse, {
  SearchRoadRoutingResponseRoute,
} from "../../../utils/search-road-route/search-road-routing.response";
import MileageRoadRouteItem from "./mileage-road-route-item";

const createRoute = (
  route: SearchRoadRoutingResponseRoute
): MileageRoadRouteItem => {
  return {
    coordinates: route.geometry.coordinates,
    legs: route.legs,
  };
};

const create = (response: SearchRoadRoutingResponse): MileageRoadRouteItem => {
  const listItem: MileageRoadRouteItem = createRoute(response.routes[0]);

  return listItem;
};

const mileageRoadRouteFactory = {
  create,
};

export default mileageRoadRouteFactory;
