import {
  CargoOrderActiveListItem,
  CargoOrderActiveListItemExecutionStatus,
} from "../../../../common/services/cargo-order/active-list/cargo-order-active-list";
import listingFilterService from "../../../../common/utils/listing/filters/listing-filter.service";
import OrderActiveListingFilter, {
  OrderActiveListingInternalOrderIdFilter,
  OrderActiveListingClientFilter,
  OrderActiveListingDispatchFilter,
  OrderActiveListingDriverFilter,
  OrderActiveListingPassengerFilter,
  OrderActiveListingRouteAddressFilter,
  OrderActiveListingRouteDestinationAddressFilter,
  OrderActiveListingRouteIntermediateAddressFilter,
  OrderActiveListingRoutePickupAddressFilter,
  OrderActiveListingMobileAppUserFilter,
  OrderActiveListingExecutionStatusFilter,
  OrderActiveListingTaxiCorporationFilter,
  OrderActiveListingUrgentOnlyFilter,
  OrderActiveListingExternalOrderIdFilter,
  OrderActiveListingExcludeDispatchFilter,
  OrderActiveListingExcludeTaxiCorporationFilter,
} from "./types/order-active-listing-filter";
import OrderActiveListingFilterType from "./types/order-active-listing-filter-type";

const checkIsAddressGroup = (
  address: string | string[]
): address is string[] => {
  if (!Array.isArray(address)) {
    return false;
  }

  return true;
};

const filterByUrgentOnly = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  const filteredListingItems = listingItems.filter(
    (item) =>
      item.executionStatus ===
        CargoOrderActiveListItemExecutionStatus.CANDIDATURE ||
      item.executionStatus ===
        CargoOrderActiveListItemExecutionStatus.PENDING ||
      item.attentions.newSharedPost ||
      item.attentions.newPlanEntryPost ||
      item.attentions.cargoOrderEdit ||
      item.attentions.cargoOrderCancel
  );

  return filteredListingItems;
};

const filterByClient = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingClientFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) =>
      item.client?.name
        .trim()
        .toLowerCase()
        .includes(query.trim().toLowerCase())
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByClientExclusions = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingClientFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter(
      (item) =>
        !item.client?.name
          .trim()
          .toLowerCase()
          .includes(query.trim().toLowerCase())
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByDispatch = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingDispatchFilter["value"][]
): CargoOrderActiveListItem[] => {
  return listingFilterService.filterByStringProperty(
    listingItems,
    "dispatch",
    queries
  );
};

const filterByDispatchExclusions = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingExcludeDispatchFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter(
      (item) =>
        !item.dispatch.trim().toLowerCase().includes(query.trim().toLowerCase())
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByDriver = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingDriverFilter["value"][]
): CargoOrderActiveListItem[] => {
  return listingFilterService.filterByArrayOfStringsProperty(
    listingItems,
    "drivers",
    queries
  );
};

const filterByInternalOrderId = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingInternalOrderIdFilter["value"][]
): CargoOrderActiveListItem[] => {
  return listingFilterService.filterByNumberProperty(
    listingItems,
    "internalOrderId",
    queries
  );
};

const filterByExternalOrderId = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingExternalOrderIdFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) => {
      if (item.externalOrderId) {
        return item.externalOrderId
          .trim()
          .toLowerCase()
          .includes(query.trim().toLowerCase());
      }
      return false;
    });

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByRoutePickupAddress = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingRoutePickupAddressFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) => {
      const pickupAddress = item.routeAddresses.length
        ? item.routeAddresses[0]
        : undefined;

      if (!pickupAddress) {
        return false;
      }

      const isPickupAddressGroup = checkIsAddressGroup(pickupAddress);

      if (isPickupAddressGroup) {
        return pickupAddress.some((address) =>
          address.trim().toLowerCase().includes(query.trim().toLowerCase())
        );
      }

      return pickupAddress
        .trim()
        .toLowerCase()
        .includes(query.trim().toLowerCase());
    });

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByRouteDestinationAddress = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingRouteDestinationAddressFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) => {
      const destinationAddress = item.routeAddresses.length
        ? item.routeAddresses[item.routeAddresses.length - 1]
        : undefined;

      if (!destinationAddress) {
        return false;
      }

      const isDestinationAddressGroup = checkIsAddressGroup(destinationAddress);

      if (isDestinationAddressGroup) {
        return destinationAddress.some((address) =>
          address.trim().toLowerCase().includes(query.trim().toLowerCase())
        );
      }

      return destinationAddress
        .trim()
        .toLowerCase()
        .includes(query.trim().toLowerCase());
    });

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByRouteAddress = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingRouteAddressFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) =>
      item.routeAddresses
        .flat()
        .some((address) =>
          address.trim().toLowerCase().includes(query.trim().toLowerCase())
        )
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByRouteIntermediateAddress = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingRouteIntermediateAddressFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) => {
      const routeAddressesWithoutStartAndEnd =
        item.routeAddresses.length >= 3
          ? item.routeAddresses.slice(1, item.routeAddresses.length - 1)
          : [];

      return routeAddressesWithoutStartAndEnd
        .flat()
        .some((address) =>
          address.trim().toLowerCase().includes(query.trim().toLowerCase())
        );
    });

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByMobileAppUser = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingMobileAppUserFilter["value"][]
): CargoOrderActiveListItem[] => {
  return listingFilterService.filterByBooleanProperty(
    listingItems,
    "isMobileAppUser",
    queries
  );
};

const filterByExecutionStatus = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingExecutionStatusFilter["value"][]
): CargoOrderActiveListItem[] => {
  return listingFilterService.filterByEnumProperty(
    listingItems,
    "executionStatus",
    queries
  );
};

const filterByTaxi = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingTaxiCorporationFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) =>
      item.consumer?.name
        .trim()
        .toLowerCase()
        .includes(query.trim().toLowerCase())
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByTaxiExclusions = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingExcludeTaxiCorporationFilter["value"][]
): CargoOrderActiveListItem[] => {
  let filteredListingItems: CargoOrderActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter(
      (item) =>
        !item.consumer?.name
          .trim()
          .toLowerCase()
          .includes(query.trim().toLowerCase())
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByPassengers = (
  listingItems: CargoOrderActiveListItem[],
  queries: OrderActiveListingPassengerFilter["value"][]
): CargoOrderActiveListItem[] => {
  return listingFilterService.filterByArrayOfStringsProperty(
    listingItems,
    "passengers",
    queries
  );
};

const filterListingItems = (
  listingItems: CargoOrderActiveListItem[],
  filters: OrderActiveListingFilter[]
): CargoOrderActiveListItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: CargoOrderActiveListItem[] = [...listingItems];

  const urgentOnlyFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingUrgentOnlyFilter["value"]
    >(filters, OrderActiveListingFilterType.URGENT_ONLY);

  if (urgentOnlyFilterValues.length) {
    filteredListingItems = filterByUrgentOnly(filteredListingItems);
  }

  const internalOrderIdFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingInternalOrderIdFilter["value"]
    >(filters, OrderActiveListingFilterType.INTERNAL_ORDER_ID);

  if (internalOrderIdFilterValues.length) {
    filteredListingItems = filterByInternalOrderId(
      filteredListingItems,
      internalOrderIdFilterValues
    );
  }

  const externalOrderIdFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingExternalOrderIdFilter["value"]
    >(filters, OrderActiveListingFilterType.EXTERNAL_ORDER_ID);

  if (externalOrderIdFilterValues.length) {
    filteredListingItems = filterByExternalOrderId(
      filteredListingItems,
      externalOrderIdFilterValues
    );
  }

  const clientFilterValues = listingFilterService.getValuesOfGivenFilterType<
    OrderActiveListingFilterType,
    OrderActiveListingClientFilter["value"]
  >(filters, OrderActiveListingFilterType.CLIENT);

  if (clientFilterValues.length) {
    filteredListingItems = filterByClient(
      filteredListingItems,
      clientFilterValues
    );
  }

  const excludeClientFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingClientFilter["value"]
    >(filters, OrderActiveListingFilterType.EXCLUDE_CLIENT);

  if (excludeClientFilterValues.length) {
    filteredListingItems = filterByClientExclusions(
      filteredListingItems,
      excludeClientFilterValues
    );
  }

  const routeAddressFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingRouteAddressFilter["value"]
    >(filters, OrderActiveListingFilterType.ROUTE_ADDRESS);

  if (routeAddressFilterValues.length) {
    filteredListingItems = filterByRouteAddress(
      filteredListingItems,
      routeAddressFilterValues
    );
  }

  const routeDestinationAddressFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingRouteDestinationAddressFilter["value"]
    >(filters, OrderActiveListingFilterType.ROUTE_DESTINATION_ADDRESS);

  if (routeDestinationAddressFilterValues.length) {
    filteredListingItems = filterByRouteDestinationAddress(
      filteredListingItems,
      routeDestinationAddressFilterValues
    );
  }

  const routeIntermediateAddressFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingRouteIntermediateAddressFilter["value"]
    >(filters, OrderActiveListingFilterType.ROUTE_INTERMEDIATE_ADDRESS);

  if (routeIntermediateAddressFilterValues.length) {
    filteredListingItems = filterByRouteIntermediateAddress(
      filteredListingItems,
      routeIntermediateAddressFilterValues
    );
  }

  const routePickupAddressFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingRoutePickupAddressFilter["value"]
    >(filters, OrderActiveListingFilterType.ROUTE_PICKUP_ADDRESS);

  if (routePickupAddressFilterValues.length) {
    filteredListingItems = filterByRoutePickupAddress(
      filteredListingItems,
      routePickupAddressFilterValues
    );
  }

  const dispatchFilterValues = listingFilterService.getValuesOfGivenFilterType<
    OrderActiveListingFilterType,
    OrderActiveListingDispatchFilter["value"]
  >(filters, OrderActiveListingFilterType.DISPATCH);

  if (dispatchFilterValues.length) {
    filteredListingItems = filterByDispatch(
      filteredListingItems,
      dispatchFilterValues
    );
  }

  const excludeDispatchFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingExcludeDispatchFilter["value"]
    >(filters, OrderActiveListingFilterType.EXCLUDE_DISPATCH);

  if (excludeDispatchFilterValues.length) {
    filteredListingItems = filterByDispatchExclusions(
      filteredListingItems,
      excludeDispatchFilterValues
    );
  }

  const driverFilterValues = listingFilterService.getValuesOfGivenFilterType<
    OrderActiveListingFilterType,
    OrderActiveListingDriverFilter["value"]
  >(filters, OrderActiveListingFilterType.DRIVER);

  if (driverFilterValues.length) {
    filteredListingItems = filterByDriver(
      filteredListingItems,
      driverFilterValues
    );
  }

  const mobileAppUserFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingMobileAppUserFilter["value"]
    >(filters, OrderActiveListingFilterType.MOBILE_APP_USER);

  if (mobileAppUserFilterValues.length) {
    filteredListingItems = filterByMobileAppUser(
      filteredListingItems,
      mobileAppUserFilterValues
    );
  }

  const executionStatusFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingExecutionStatusFilter["value"]
    >(filters, OrderActiveListingFilterType.EXECUTION_STATUS);

  if (executionStatusFilterValues.length) {
    filteredListingItems = filterByExecutionStatus(
      filteredListingItems,
      executionStatusFilterValues
    );
  }

  const taxiFilterValues = listingFilterService.getValuesOfGivenFilterType<
    OrderActiveListingFilterType,
    OrderActiveListingTaxiCorporationFilter["value"]
  >(filters, OrderActiveListingFilterType.TAXI_CORPORATION);

  if (taxiFilterValues.length) {
    filteredListingItems = filterByTaxi(filteredListingItems, taxiFilterValues);
  }

  const excludeTaxiFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      OrderActiveListingFilterType,
      OrderActiveListingExcludeTaxiCorporationFilter["value"]
    >(filters, OrderActiveListingFilterType.EXCLUDE_TAXI_CORPORATION);

  if (excludeTaxiFilterValues.length) {
    filteredListingItems = filterByTaxiExclusions(
      filteredListingItems,
      excludeTaxiFilterValues
    );
  }

  const passengerFilterValues = listingFilterService.getValuesOfGivenFilterType<
    OrderActiveListingFilterType,
    OrderActiveListingPassengerFilter["value"]
  >(filters, OrderActiveListingFilterType.PASSENGER);

  if (passengerFilterValues.length) {
    filteredListingItems = filterByPassengers(
      filteredListingItems,
      passengerFilterValues
    );
  }

  return filteredListingItems;
};

const orderActiveListingFilteringService = {
  filterListingItems,
};

export default orderActiveListingFilteringService;
