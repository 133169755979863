import BillingsOrderForwardTaxiDriverResponse, {
  BillingsOrderForwardTaxiDriverResponseItem,
} from "../../../../api/raily/billings/order-forward/taxi-driver/billings-order-forward-taxi-driver.response";
import BillingsOrderForwardTaxiDriver, {
  BillingsOrderForwardTaxiDriverItem,
} from "./billings-order-forward-taxi-driver";
import BillingModel from "../../../../types/billing-model";

const createDataItem = (
  data: BillingsOrderForwardTaxiDriverResponseItem
): BillingsOrderForwardTaxiDriverItem => {
  return {
    billingUuid: data.id,
    contractDetails: {
      companyName:
        data.contract.taxiDriverAssociation.taxiCorporation.displayName,
      distanceRate: data.contract.distanceRate,
      model: data.contract.model as BillingModel,
    },
    baseAmountValue: data.baseAmountValue,
    amountForChargeHaltings: data.amountForChargeHaltings,
    amountForChargeHighways: data.amountForChargeHighways,
    distance: data.distance,
    amountForDistance: data.amountForDistance,
    internalOrderId: data.planEntry.humanId,
    allContributionsAmount: data.allContributionsAmount,
    isDraft: data.draft,
    date: data.date,
    sumOfBonuses: data.sumOfBonuses,
    sumOfPenalties: data.sumOfPenalties,
  };
};

const createData = (
  responseData: BillingsOrderForwardTaxiDriverResponseItem[]
): BillingsOrderForwardTaxiDriverItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: BillingsOrderForwardTaxiDriverResponse
): BillingsOrderForwardTaxiDriver => {
  return {
    data: createData(response.data),
  };
};

const billingsOrderForwardTaxiDriverFactory = {
  create,
};

export default billingsOrderForwardTaxiDriverFactory;
