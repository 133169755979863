import railyApiService from "../raily-api.service";
import MileageAddRequest, {
  MileageAddRequestBody,
} from "./add/mileage-add.request";
import mileageAddRequestFactory from "./add/mileage-add-request.factory";
import MileageAddResponse from "./add/mileage-add.response";
import mileageListRequstFactory from "./list/mileage-list-request.factory";
import MileageListRequest from "./list/mileage-list.request";
import MileageListResponse from "./list/mileage-list.response";
import MileageDownloadRequest, {
  MileageDownloadRequestQueryParams,
} from "./download/mileage-download.request";
import mileageDownloadRequestFactory from "./download/mileage-download-request.factory";
import MileageDownloadResponse from "./download/mileage-download.response";
import authService from "../../../../modules/auth/common/auth.service";
import urlService from "../../../utils/url/url.service";
import appConfig from "../../../../app.config";
import SearchRoadRoutingRequest from "../../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../../utils/search-road-route/search-road-routing.response";
import searchRoadRoutingService from "../../../utils/search-road-route/search-road-routing.service";
import MileageDetailsRequest from "./details/mileage-details.request";
import MileageDetailsResponse from "./details/mileage-details.response";
import HttpResponse from "../../../utils/http/http.response";
import MileageUpdateRequest from "./update/mileage-update-request";
import mileageUpdateRequestFactory from "./update/mileage-update-request.factory";

const mileageApiService = () => {
  const getList = async (
    request: MileageListRequest,
    abortSignal: AbortSignal
  ): Promise<MileageListResponse> => {
    const url = `/mileage`;

    const queryParams = mileageListRequstFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  const add = async (
    request: MileageAddRequest,
    abortSignal: AbortSignal
  ): Promise<MileageAddResponse> => {
    const url = `/mileage`;

    const body: MileageAddRequestBody =
      mileageAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const download = async (
    request: MileageDownloadRequest
  ): Promise<MileageDownloadResponse> => {
    const path = `${appConfig.baseApiUrl}/mileage/download/csv`;

    const queryParams: MileageDownloadRequestQueryParams =
      mileageDownloadRequestFactory.createQueryParams(request);

    const url = urlService.buildWithoutEmptyParams(path, queryParams);

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "attachment",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
      mode: "cors",
      keepalive: true,
    };

    const response = await fetch(url, init);

    const filename = response.headers
      ?.get("content-disposition")
      ?.split(";")
      .find((n) => n.includes("filename="))
      ?.replace("filename=", "")
      .replace(/"/g, "")
      .trim()!;

    const responseData = await response.blob();

    return {
      status: response.status,
      data: {
        data: responseData,
        filename,
      },
    };
  };

  const getRoute = (
    request: SearchRoadRoutingRequest
  ): Promise<SearchRoadRoutingResponse> => {
    return searchRoadRoutingService.search(request);
  };

  const getDetails = async (
    request: MileageDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<MileageDetailsResponse> => {
    const url = `/mileage/${request.mileageUuid}`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  const updateMileage = async (
    request: MileageUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse<string>> => {
    const url = `/mileage/${request.mileageUuid}`;
    const body = mileageUpdateRequestFactory.createBody(request);

    return railyApiService().put({
      url,
      abortSignal,
      body,
    });
  };

  return {
    getList,
    add,
    download,
    getRoute,
    getDetails,
    updateMileage,
  };
};

export default mileageApiService;
