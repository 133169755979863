import appConfig from "../../../../../../../../../app.config";
import authService from "../../../../../../../../auth/common/auth.service";
import DriverDetailsPlanEditCheckoutRequest from "./driver-details-plan-edit-checkout.request";
import DriverDetailsPlanEditCheckoutResponse from "./driver-details-plan-edit-checkout.response";

const editCheckout = (
  driverUuid: string,
  planEntryUuid: string,
  checkoutEventUuid: string,
  request: DriverDetailsPlanEditCheckoutRequest
): Promise<DriverDetailsPlanEditCheckoutResponse> => {
  const path = `/drivers/${driverUuid}/plan-entries/${planEntryUuid}/checkout-events/${checkoutEventUuid}`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "PUT",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const driverDetailsPlanEditCheckoutApiService = {
  editCheckout,
};

export default driverDetailsPlanEditCheckoutApiService;
