import { useState, FC, useRef, MouseEvent, useEffect, RefObject } from "react";
import { faCalendarAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { createPortal } from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import ComponentClassnames from "../../../../types/component-classnames";
import useKeyboardPress from "../../../../hooks/use-keyboard-press";
import InputComponent from "../input.component";
import FormIcon from "../../icon/form-icon";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import dateService from "../../../../utils/date/date.service";
import DatePickerComponent from "../../date-picker/date-picker/date-picker.component";
import dateInputHelper from "./date-input.helper";
import useOpen from "../../../../hooks/use-open";

type DateInputProps = {
  classNames?: ComponentClassnames;
  date: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  onBlur?: () => void;
  hasError?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  idForTesting?: string;
  parentRef?: RefObject<HTMLElement>;
  parentContainerRef?: RefObject<HTMLElement>;
};

const DateInputComponent: FC<DateInputProps> = (props) => {
  const [calendarHeight, setCalendarHeight] = useState(0);
  const { isOpen, open, close } = useOpen();
  const datePickerRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calendarHeight =
      calendarRef?.current?.getBoundingClientRect().top ?? 0;
    setCalendarHeight(calendarHeight);
  }, [isOpen]);

  const createCalendarClasses = () => {
    if (!datePickerRef) return "";
    const datePickerTop =
      datePickerRef.current?.getBoundingClientRect().top ?? 0;
    const windowHeight = window.innerHeight;

    return calendarHeight
      ? datePickerTop + calendarHeight >= windowHeight
        ? "date_input_selector_above"
        : "date_input_selector_below"
      : "";
  };

  useKeyboardPress({
    Escape: () => close(),
    Tab: () => open(),
    ref: datePickerRef,
  });

  const [inputValue, setInputValue] = useState(() =>
    dateInputHelper.getInputValue(props.date)
  );

  useEffect(() => {
    const newInputValue = dateInputHelper.getInputValue(props.date);
    setInputValue(newInputValue);
  }, [props.date]);

  const translations =
    appTranslationsHelper.getComponentTranslations().datePicker;
  const calendarPositionClass = createCalendarClasses();
  const dataPickerRect = datePickerRef.current?.getBoundingClientRect();
  const renderRef = props.parentRef?.current;

  const onInputValueChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  const onInputBlur = () => {
    const newDateCandidate = dateService.createDate(inputValue);

    const isDateValid = dateService.checkIsValidDate(newDateCandidate);

    if (!isDateValid) {
      const newInputValue = dateInputHelper.getInputValue(props.date);
      setInputValue(newInputValue);
      return;
    }

    props.onChange(newDateCandidate);
  };

  const onClearDateButtonClick = (
    event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    props.onChange(null);
  };

  const onOutsideClick = (event?: any) => {
    if (
      !isOpen ||
      (calendarRef.current && calendarRef.current.contains(event.target))
    )
      return;

    close();
  };

  const datePicker = createPortal(
    <div
      style={{
        position: renderRef ? "fixed" : "absolute",
        top: dataPickerRect
          ? renderRef
            ? dataPickerRect.top
            : dataPickerRect.top + window.scrollY
          : "",
        left: dataPickerRect ? (renderRef ? "" : dataPickerRect.left) : "",
      }}
    >
      <div
        className={classNames("date_input_selector", calendarPositionClass)}
        ref={calendarRef}
      >
        <DatePickerComponent
          date={props.date}
          onChange={props.onChange}
          minDate={props.minDate}
          maxDate={props.maxDate}
        />
      </div>
    </div>,
    renderRef ?? document.body
  );

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div ref={datePickerRef} className={props.classNames?.root}>
        {isOpen && datePicker}
        <InputComponent
          onFocus={() => open()}
          value={inputValue}
          onChange={onInputValueChange}
          isReadOnly
          placeholder={props.placeholder ?? translations.inputPlaceholder}
          iconLeft={<FormIcon icon={faCalendarAlt} />}
          iconRight={
            props.date && (
              <FormIcon
                icon={faTimes}
                title={translations.clearDateButtonTitle}
                onClick={onClearDateButtonClick}
              />
            )
          }
          onBlur={onInputBlur}
          idForTesting={props.idForTesting}
          hasError={props.hasError}
          isDisabled={props.isDisabled}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default DateInputComponent;
