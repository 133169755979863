import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";

type AppAsideExpandedNavigationCollapseButtonProps = {
  isExpanded: boolean;
  onClick: () => void;
};

const AppAsideExpandedNavigationListItemToggleButtonComponent: FC<
  AppAsideExpandedNavigationCollapseButtonProps
> = (props) => {
  const Button: FC<{ children: ReactNode; onClick: () => void }> = (props) => {
    return (
      <ButtonComponent
        onClick={props.onClick}
        classNames={{ root: "app_aside_navigation_list_item__toggle_button" }}
      >
        {props.children}
      </ButtonComponent>
    );
  };
  const Icon: FC<{ icon: IconProp }> = (props) => {
    return (
      <FontAwesomeIcon
        icon={props.icon}
        size="sm"
        className="app_aside_navigation_list_item__toggle_icon"
      />
    );
  };

  if (props.isExpanded) {
    return (
      <Button onClick={props.onClick}>
        <Icon icon={faChevronDown} />
      </Button>
    );
  }

  return (
    <Button onClick={props.onClick}>
      <Icon icon={faChevronDown} />
    </Button>
  );
};

export default AppAsideExpandedNavigationListItemToggleButtonComponent;
