import { ActionOption } from "./../../../../billings/cargo-taxi/view/types/action-option";
import { TaxiUnsettledRouteListingResponseDataItem } from "./api/taxi-unsettled-route-listing.response";
import TaxiUnsettledRouteListingItem from "./types/taxi-unsettled-route-listing-item";

const createListingItem = (
  responseDataItem: TaxiUnsettledRouteListingResponseDataItem
): TaxiUnsettledRouteListingItem => {
  return {
    uuid: responseDataItem.id,
    routeId: responseDataItem.human_id,
    driverName: responseDataItem.driver,
    startDate: new Date(responseDataItem.start_time),
    addresses: responseDataItem.addresses.join(", "),
    orderIds: responseDataItem.cargo_order_human_ids,
    clients: responseDataItem.clients.join(", "),
    fleetPartner: responseDataItem.fleet_partner,
  };
};

const createListingItems = (
  responseDataItems: TaxiUnsettledRouteListingResponseDataItem[]
): TaxiUnsettledRouteListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const taxiUnsettledRouteListingFactory = {
  createListingItems,
};

export default taxiUnsettledRouteListingFactory;
