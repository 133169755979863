import DelegationListResponse, {
  DelegationListResponseItemDelegationType,
  DelegationListResponseItem,
  DelegationListResponseItemState,
} from "../../../api/raily/delegation/list/delegation-list.response";
import DelegationList, {
  DelegationListItem,
  DelegationListItemStatus,
  DelegationListItemType,
} from "./delegation-list";

const createStatus = (
  state: DelegationListResponseItemState
): DelegationListItemStatus => {
  switch (state) {
    case DelegationListResponseItemState.DELETED:
      return DelegationListItemStatus.DELETED;
    case DelegationListResponseItemState.DOWNLOADED:
      return DelegationListItemStatus.DOWNLOADED;
    case DelegationListResponseItemState.NEW:
      return DelegationListItemStatus.UNDOWNLOADED;
  }
};

const createType = (
  type: DelegationListResponseItemDelegationType
): DelegationListItemType => {
  switch (type) {
    case DelegationListResponseItemDelegationType.AUTO:
      return DelegationListItemType.AUTO;
    case DelegationListResponseItemDelegationType.MANUAL:
      return DelegationListItemType.MANUAL;
  }
};

const createDataItem = (
  item: DelegationListResponseItem
): DelegationListItem => {
  return {
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    dateFrom: item.dateFrom,
    dateTo: item.dateTo,
    creationDate: item.creationDate,
    delegationNumber: item.delegationNumber,
    cargoCompanyName: item.cargoCompanyName,
    humanId: item.humanId,
    status: createStatus(item.state),
    totalCost: item.totalCost,
    totalDistance: item.totalDistance,
    type: createType(item.type),
    uuid: item.id,
    workerName: item.workerName,
    downloadedCsvAt: item.downloadedCsvAt,
    downloadedCsvBy: item.downloadedCsvBy,
    downloadedPdfAt: item.downloadedPdfAt,
    downloadedPdfBy: item.downloadedPdfBy,
  };
};

const createData = (
  data: DelegationListResponseItem[]
): DelegationListItem[] => {
  const items: DelegationListItem[] = data.map(createDataItem);

  return items;
};

const create = (response: DelegationListResponse): DelegationList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const delegationListFactory = {
  create,
};

export default delegationListFactory;
