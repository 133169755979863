import routeRoutesUrls from "./route-routes-urls";

const getActiveRoutesRoute = () => {
  return routeRoutesUrls.activeListing;
};

const getFinishedRoutesRoute = () => {
  return routeRoutesUrls.finishedListing;
};

const routeRoutesHelper = {
  getActiveRoutesRoute,
  getFinishedRoutesRoute,
};

export default routeRoutesHelper;
