import OrderOptimizerListingTableColumn from "../common/types/order-optimizer-listing-table-column";

const getColumns = (): OrderOptimizerListingTableColumn[] => {
  const tableColumns: OrderOptimizerListingTableColumn[] = [
    {
      header: "",
      title: "",
      accessor: "row",
    },
  ];
  return tableColumns;
};

const orderOptimizerListingTableHelper = { getColumns };

export default orderOptimizerListingTableHelper;
