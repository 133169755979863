import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../context/app.context";
import delegationAddUserPermissionsHelper from "./common/user-permissions/delegation-add-user-permissions.helper";
import delegationTranslationsHelper from "../../../languages/delegation-translations.helper";
import DelegationAddViewMode from "./common/types/delegation-add-view-mode";
import ButtonComponent from "../../../common/components/button/button.component";
import CargoDelegationAddComponent from "./cargo/cargo-delegation-add.component";
import RailyDelegationAddComponent from "./raily/raily-delegation-add.component";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";

const DelegationAddComponent: FC = () => {
  const { user, selectedAppLanguage } = useAppContext();

  const userPermissions = useMemo(
    () => delegationAddUserPermissionsHelper.getPermissions(user?.roles!),
    []
  );

  const translations = useMemo(
    () => delegationTranslationsHelper.getDelegationAddTranslations(),
    [selectedAppLanguage]
  );

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageDelegationAdd);

  const [selectedViewMode, setSelectedViewMode] =
    useState<DelegationAddViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DelegationAddViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToCargoButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DelegationAddViewMode.CARGO)}
        title={translations.header.changeViewToCargoButtonTitle}
      >
        {translations.header.changeViewToCargoButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: DelegationAddViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToCargoButton,
          hasPermission: userPermissions.hasAccessToCargoView,
        },
      },
      {
        viewMode: DelegationAddViewMode.CARGO,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToCargoButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: DelegationAddViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: DelegationAddViewMode.RAILY,
        component: (
          <RailyDelegationAddComponent
            changeViewButtons={getViewChangeButtons(
              DelegationAddViewMode.RAILY
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: DelegationAddViewMode.CARGO,
        component: (
          <CargoDelegationAddComponent
            changeViewButtons={getViewChangeButtons(
              DelegationAddViewMode.CARGO
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToCargoView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default DelegationAddComponent;
