import BillingModel from "../../../types/billing-model";
import {
  BillingData,
  BillingDataResponseContribution,
  BillingDataResponseContributionType,
} from "../api/billing-data.response";
import BillingFormData, {
  BillingFormDataContributions,
  BillingSummaryData,
} from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import BillingsCargoTaxiContributionsDiscountTableRow from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount-table-row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import billingsCargoTaxiHelper from "../billings-cargo-taxi.helper";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import NumericInputComponent from "../../../../../common/components/form/input/numeric-input/numeric-input.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import TableButtonComponent from "../../../../../common/components/table/button/table-button.component";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TabsData from "../../../../../common/components/tabs/common/types/tabs-data";
import BillingsCargoTaxiContributionsDiscountTableComponent from "../../common/contributions/discount/table/billings-cargo-taxi-contributions-discount-table.component";
import BillingsCargoTaxiContribution, {
  BillingsCargoTaxiContributionDiscountType,
} from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import FormErrorComponent from "../../../../../common/components/form/error/form-error.component";
import BillingContributionValidationResult from "../types/billing-contributions-validation-result";
import BillingsCargoTaxiContributions from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date
        ? new Date(billingNode.checkout_date)
        : null,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date
        ? new Date(billingNode.planned_date)
        : null,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingDiscountContributionType = (
  contributionType:
    | BillingDataResponseContributionType.DISCOUNT_BEING_LATE
    | BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR
    | BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION
    | BillingDataResponseContributionType.DISCOUNT_OTHER
):
  | BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE
  | BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR
  | BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION
  | BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER => {
  switch (contributionType) {
    case BillingDataResponseContributionType.DISCOUNT_BEING_LATE:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE;
    case BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR;
    case BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION;
    case BillingDataResponseContributionType.DISCOUNT_OTHER:
      return BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER;
  }
};

const createBillingDiscountContribution = (
  contribution: BillingDataResponseContribution
): BillingsCargoTaxiContributions => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.CONTRACT_CORRECTION_PERCENTAGE:
      return {
        type: BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE,
        percentage: contribution.percentage,
        amount: contribution.amount,
      };
    case BillingDataResponseContributionType.DISCOUNT_BEING_LATE:
    case BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR:
    case BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION:
    case BillingDataResponseContributionType.DISCOUNT_OTHER:
      return {
        type: createBillingDiscountContributionType(contribution.type),
        comment: contribution.comment ?? "",
        amount: contribution.amount,
      };
  }
};

const createBillingContributions = (
  contributions: BillingDataResponseContribution[]
): BillingFormDataContributions => {
  const discountContribution = contributions
    .filter((contribution) =>
      [
        BillingDataResponseContributionType.DISCOUNT_BEING_LATE,
        BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR,
        BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION,
        BillingDataResponseContributionType.DISCOUNT_OTHER,
        BillingDataResponseContributionType.CONTRACT_CORRECTION_PERCENTAGE,
      ].includes(contribution.type)
    )
    .map((contribution) => createBillingDiscountContribution(contribution));

  return {
    discount: discountContribution,
  };
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    billingContributions: createBillingContributions(
      data.billing_contributions
    ),
  };

  return billingDraft;
};

const createBillingSummaryData = (data: BillingData) => {
  const billingDraft: BillingSummaryData = {
    contractDetails: {
      companyName: data.contract.cargo_company.display_name,
      discountLimit: data.contract.discount_limit,
      haltingTimeRate: data.contract.halting_time_rate,
      distanceRate: data.contract.distance_rate,
      isHighwayAllowedDuringRide: data.contract.allow_charge_during_ride,
      isHighwayAllowedWhenApproaching:
        data.contract.allow_charge_while_approaching,
      isHighwayAllowedWhileReturning:
        data.contract.allow_charge_while_returning,
      model: data.contract.model as BillingModel,
      haltingTimeAppliedAfter: data.contract.halting_time_after_minutes,
    },
    baseAmountValue: data.base_amount_value,
    sumOfDiscounts: data.sum_of_discounts,
    amountForChargeHaltings: data.amount_for_charge_haltings,
    amountForChargeHighways: data.amount_for_charge_highways,
    distance: data.distance,
    amountForDistance: data.amount_for_distance,
    internalOrderId: data.cargo_order.human_id,
    allContributionsAmount: data.all_contributions_amount,
    planEntryId: data.plan_entry_id,
    cargoOrderId: data.cargo_order.id,
    date: data.date,
    status: data.status,
    passengers: data.passengers,
    contractCorrectionPercentage: data.contract_correction_percentage,
  };

  return billingDraft;
};

const createContributionsDiscountTableRow = (
  contribution: BillingsCargoTaxiContribution,
  contributions: BillingsCargoTaxiContribution[],
  position: number,
  deleteContribution: (position: number) => void,
  contributionsValidationResults: BillingContributionValidationResult,
  onContributionTypeChange: (
    position: number,
    type: BillingsCargoTaxiContributionDiscountType
  ) => void,
  onContributionAmountChange: (position: number, amount: number | null) => void,
  onContributionCommentChange: (position: number, comment: string) => void,
  onContributionPercentageChange: (
    position: number,
    percentage: number | null
  ) => void,
  onContributionTypeBlur: (position: number) => void,
  onContributionPercentageBlur: (position: number) => void,
  onContributionAmountBlur: (position: number) => void,
  onContributionCommentBlur: (position: number) => void
): BillingsCargoTaxiContributionsDiscountTableRow => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiContributionsTranslations();

  const typeSelectOptions =
    billingsCargoTaxiHelper.getContributionDiscountTypeSelectOptions();

  const typeSelectedOption =
    typeSelectOptions.find((option) => option.value === contribution.type) ??
    null;

  const validationResult = contributionsValidationResults.discount.find(
    (validation) => validation.position === position
  );

  switch (contribution.type) {
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return {
        id: `${contribution.type}-${position}`,
        value: {
          type: (
            <div className="billings_contributions_row">
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(position, value?.value)
                }
                onBlur={() => onContributionTypeBlur(position)}
                options={typeSelectOptions}
                placeholder={translations.discount.form.typeSelectPlaceholder}
                idForTesting={`billings=cargo-taxi-edit-contribution-discount-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                autoFocus
                hasError={!!validationResult?.type.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="billings_contributions_not_required_row"
              title={String(contribution.amount)}
            >
              {contribution.amount}
            </div>
          ),
          comment: (
            <div
              className="billings_contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          percentage: (
            <div
              className="billings_contributions_row"
              title={String(contribution.percentage)}
            >
              <NumericInputComponent
                value={contribution.percentage}
                onChange={(value) => {
                  onContributionPercentageChange(position, value);
                }}
                onBlur={() => {
                  onContributionPercentageBlur(position);
                }}
                placeholder={translations.discount.form.percentagePlaceholder}
                idForTesting={`billings-taxi-taxi-edit-contribution-discount-percentage-${position}`}
                decimalPrecision={2}
                hasError={!!validationResult?.percentage.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.percentage.errorMessage}
              />
            </div>
          ),
          actions: (
            <div className="billings_contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  deleteContribution(position);
                }}
                type="danger"
                title={
                  translations.discount.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return {
        id: `${contribution.type}-${position}`,
        value: {
          type: (
            <div className="billings_contributions_row">
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(position, value?.value)
                }
                onBlur={() => onContributionTypeBlur(position)}
                options={typeSelectOptions}
                placeholder={translations.discount.form.typeSelectPlaceholder}
                idForTesting={`billings-cargo-taxi-edit-contribution-discount-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                autoFocus
                hasError={!!validationResult?.type.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="billings_contributions_row"
              title={String(contribution.amount)}
            >
              <NumericInputComponent
                value={contribution.amount}
                onChange={(value) =>
                  onContributionAmountChange(position, value)
                }
                onBlur={() => onContributionAmountBlur(position)}
                placeholder={translations.discount.form.amountPlaceholder}
                idForTesting={`billings-cargo-taxi-edit-contribution-discount-amount-${position}`}
                decimalPrecision={2}
                hasError={!!validationResult?.amount.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.amount.errorMessage}
              />
            </div>
          ),
          comment: (
            <div
              className="billings_contributions_row"
              title={contribution.comment}
            >
              <InputComponent
                value={contribution.comment}
                onChange={(value) =>
                  onContributionCommentChange(position, value)
                }
                onBlur={() => onContributionCommentBlur(position)}
                placeholder={translations.discount.form.commentPlaceholder}
                idForTesting={`billings-cargo-taxi-edit-contribution-discount-comment-${position}`}
                hasError={!!validationResult?.comment.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.comment.errorMessage}
              />
            </div>
          ),
          percentage: (
            <div
              className="billings_contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          actions: (
            <div className="billings_contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  deleteContribution(position);
                }}
                type="danger"
                title={
                  translations.discount.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    default:
      return {
        id: String(position),
        value: {
          type: (
            <div>
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(position, value?.value)
                }
                onBlur={() => onContributionTypeBlur(position)}
                options={typeSelectOptions}
                placeholder={translations.discount.form.typeSelectPlaceholder}
                idForTesting={`billings-cargo-taxi-edit-contribution-discount-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                autoFocus
                hasError={!!validationResult?.type.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          amount: <div></div>,
          comment: <div></div>,
          percentage: <div></div>,
          actions: <div className="d-flex"></div>,
        },
      };
  }
};

const createTabsData = (
  contributions: BillingFormDataContributions,
  isDataLoading: boolean,
  deleteContribution: (position: number) => void,
  contributionsValidationResults: BillingContributionValidationResult,
  onContributionTypeChange: (
    position: number,
    type: BillingsCargoTaxiContributionDiscountType
  ) => void,
  onContributionAmountChange: (position: number, amount: number | null) => void,
  onContributionCommentChange: (position: number, comment: string) => void,
  onContributionPercentageChange: (
    position: number,
    percentage: number | null
  ) => void,
  onContributionTypeBlur: (position: number) => void,
  onContributionPercentageBlur: (position: number) => void,
  onContributionAmountBlur: (position: number) => void,
  onContributionCommentBlur: (position: number) => void
): TabsData => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiContributionsTranslations();

  const discountTableRows: BillingsCargoTaxiContributionsDiscountTableRow[] =
    contributions.discount.map((contribution, index) =>
      createContributionsDiscountTableRow(
        contribution,
        contributions.discount,
        index,
        deleteContribution,
        contributionsValidationResults,
        onContributionTypeChange,
        onContributionAmountChange,
        onContributionCommentChange,
        onContributionPercentageChange,
        onContributionTypeBlur,
        onContributionPercentageBlur,
        onContributionAmountBlur,
        onContributionCommentBlur
      )
    );

  const DiscountTabContent = (
    <BillingsCargoTaxiContributionsDiscountTableComponent
      isLoading={isDataLoading}
      rows={discountTableRows}
    />
  );

  const tabsData: TabsData = [
    {
      label: translations.discount.title,
      content: DiscountTabContent,
      counter: contributions.discount.filter(
        (contribution) => contribution.type
      ).length,
      totalTableRows: discountTableRows.length,
    },
  ];

  return tabsData;
};

const billingDataFactory = {
  createBillingData,
  createBillingSummaryData,
  createTabsData,
};

export default billingDataFactory;
