import DriverPlanCandidaturePreviewRequest from "../../../api/raily/driver-plan-candidature/preview/driver-plan-candidature-preview.request";
import DriverPlanCandidaturePreviewLoadParams from "./driver-plan-candidature-preview-load-params";

const create = (
  params: DriverPlanCandidaturePreviewLoadParams
): DriverPlanCandidaturePreviewRequest => {
  return {
    orderId: params.orderUuid,
    taxiDriverAssociationId: params.taxiDriverAssociationUuid,
    planEntryId: params.planEntryUuid,
  };
};

const driverPlanCandidaturePreviewRequestFactory = {
  create,
};

export default driverPlanCandidaturePreviewRequestFactory;
