import orderTranslationsHelper from "../../../../languages/order-translations.helper";

const getFetchingErrorDescriptionText = (cargoCompanyOrderId: number) => {
  const translations = orderTranslationsHelper.getDetailsTranslations();

  return translations.fetchingError.descriptionText.replace(
    "#{id}",
    String(cargoCompanyOrderId)
  );
};

const orderDetailsErrorHelper = {
  getFetchingErrorDescriptionText,
};

export default orderDetailsErrorHelper;
