import DataLoadError, {
  DataLoadDefaultErrors,
} from "../../../utils/data-load-error/data-load-error";

export enum OrderCancelRequestErrorType {
  ORDER_CANCELLED = "ORDER_CANCELLED",
  ORDER_CANCEL_REQUESTED = "ORDER_CANCEL_REQUESTED",
  ORDER_FINISHED = "ORDER_FINISHED",
}

export type OrderCancelledError = DataLoadError<
  OrderCancelRequestErrorType.ORDER_CANCELLED,
  {}
>;

export type OrderCancelRequestedError = DataLoadError<
  OrderCancelRequestErrorType.ORDER_CANCEL_REQUESTED,
  {}
>;

export type OrderFinishedError = DataLoadError<
  OrderCancelRequestErrorType.ORDER_FINISHED,
  {}
>;

type OrderCancelRequestError =
  | DataLoadDefaultErrors
  | OrderCancelledError
  | OrderCancelRequestedError
  | OrderFinishedError;

export default OrderCancelRequestError;
