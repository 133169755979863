import DispatcherAddRequest from "../../api/raily/dispatcher/add/dispatcher-add.request";
import dispatcherApiService from "../../api/raily/dispatcher/dispatcher-api.service";
import DispatcherEditFormRequest from "../../api/raily/dispatcher/edit-form/dispatcher-edit-form.request";
import DispatcherListRequest from "../../api/raily/dispatcher/list/dispatcher-list.request";
import DispatcherUpdateRequest from "../../api/raily/dispatcher/update/dispatcher-update.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import DispatcherEditForm from "./edit-form/dispatcher-edit-form";
import DispatcherEditFormError from "./edit-form/dispatcher-edit-form-error";
import DispatcherEditFormLoadParams from "./edit-form/dispatcher-edit-form-load-params";
import dispatcherEditFormRequestFactory from "./edit-form/dispatcher-edit-form-request.factory";
import dispatcherEditFormFactory from "./edit-form/dispatcher-edit-form.factory";
import DispatcherAddError from "./add/dispatcher-add-error";
import DispatcherAddParams from "./add/dispatcher-add-params";
import dispatcherAddRequestFactory from "./add/dispatcher-add-request.factory";
import DispatcherList from "./list/dispatcher-list";
import DispatcherListError from "./list/dispatcher-list-error";
import DispatcherListLoadParams from "./list/dispatcher-list-load-params";
import dispatcherListRequestFactory from "./list/dispatcher-list-request.factory";
import dispatcherListFactory from "./list/dispatcher-list.factory";
import DispatcherUpdateError from "./update/dispatcher-update-error";
import DispatcherUpdateParams from "./update/dispatcher-update-params";
import dispatcherUpdateRequestFactory from "./update/dispatcher-update-request.factory";
const handleAddError = (
  error: HttpError | DispatcherAddError
): DispatcherAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleListError = (
  error: HttpError | DispatcherListError
): DispatcherListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: DispatcherAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DispatcherAddRequest =
    dispatcherAddRequestFactory.create(params);

  try {
    const response = await dispatcherApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DispatcherAddError;

    throw handleAddError(error);
  }
};

const getList = async (
  params: DispatcherListLoadParams,
  abortSignal: AbortSignal
): Promise<DispatcherList> => {
  const request: DispatcherListRequest =
    dispatcherListRequestFactory.create(params);

  try {
    const response = await dispatcherApiService().getList(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const dispatcherList = dispatcherListFactory.create(response);

    return dispatcherList;
  } catch (_error) {
    const error = _error as HttpError | DispatcherListError;

    throw handleListError(error);
  }
};

const handleUpdateError = (
  error: HttpError | DispatcherUpdateError
): DispatcherUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const update = async (
  params: DispatcherUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DispatcherUpdateRequest =
    dispatcherUpdateRequestFactory.create(params);

  try {
    const response = await dispatcherApiService().update(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DispatcherUpdateError;

    throw handleUpdateError(error);
  }
};

const handleEditFormError = (
  error: HttpError | DispatcherEditFormError
): DispatcherEditFormError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getEditForm = async (
  params: DispatcherEditFormLoadParams,
  abortSignal: AbortSignal
): Promise<DispatcherEditForm> => {
  const request: DispatcherEditFormRequest =
    dispatcherEditFormRequestFactory.create(params);

  try {
    const response = await dispatcherApiService().getEditForm(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return dispatcherEditFormFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | DispatcherEditFormError;

    throw handleEditFormError(error);
  }
};

const dispatcherService = {
  add,
  getList,
  update,
  getEditForm,
};

export default dispatcherService;
