import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import MileageContractListingFilter from "../common/types/mileage-contract-listing-filter";
import MileageContractListingRouteQueryParams from "../common/types/mileage-contract-listing-filter-route-params";
import MileageContractListingSortKey from "../common/types/mileage-contract-listing-sort-key";
import mileageContractListingFiltersService from "./mileage-contract-listing-filters.service";

const useMileageContractListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<MileageContractListingRouteQueryParams>();

  const getFilters = (): MileageContractListingFilter[] => {
    return mileageContractListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return mileageContractListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return mileageContractListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): MileageContractListingSortKey | undefined => {
    return mileageContractListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: MileageContractListingFilter[],
    sortKey: MileageContractListingSortKey,
    page: number,
    pageSize: number
  ) => {
    const routeQueryParams =
      mileageContractListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getPage,
    getPageSize,
    getFilters,
    saveFilters,
    getSortKey,
  };
};

export default useMileageContractListingFiltersDao;
