import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import dealerAddByTaxiFormValidationService from "./dealer-add-by-taxi-form-validation.service";
import DealerAddByTaxiFormData from "./types/dealer-add-by-taxi-form-data";

const getDefaultFormData = (): DealerAddByTaxiFormData => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
    phoneNumber: phoneNumberInputHelper.getEmptyValue(),
    alternativePhoneNumber: phoneNumberInputHelper.getEmptyValue(),
  };
};
const getValidationDefinition =
  (): FormValidationDefinition<DealerAddByTaxiFormData> => {
    return {
      firstName: (values) =>
        dealerAddByTaxiFormValidationService.validateFirstName(
          values.firstName
        ),
      lastName: (values) =>
        dealerAddByTaxiFormValidationService.validateLastName(values.lastName),
      email: (values) =>
        dealerAddByTaxiFormValidationService.validateEmail(values.email),
      username: async (values) =>
        await dealerAddByTaxiFormValidationService.validateUsername(
          values.username
        ),
      password: (values) =>
        dealerAddByTaxiFormValidationService.validatePassword(values.password),
      passwordRepeat: (values) =>
        dealerAddByTaxiFormValidationService.validatePasswordRepeat(
          values.password,
          values.passwordRepeat
        ),
      phoneNumber: (values) =>
        dealerAddByTaxiFormValidationService.validatePhoneNumber(
          values.phoneNumber
        ),
      alternativePhoneNumber: (values) =>
        dealerAddByTaxiFormValidationService.validateAlternativePhoneNumber(
          values.alternativePhoneNumber
        ),
    };
  };

const dealerAddByTaxiFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default dealerAddByTaxiFormHelper;
