import DispatcherAddRequest, {
  DispatcherAddRequestBody,
} from "./add/dispatcher-add.request";
import DispatcherEditFormRequest from "./edit-form/dispatcher-edit-form.request";
import DispatcherEditFormResponse from "./edit-form/dispatcher-edit-form.response";
import dispatcherListRequestFactory from "./list/dispatcher-list-request.factory";
import DispatcherListRequest, {
  DispatcherListRequestQueryParams,
} from "./list/dispatcher-list.request";
import DispatcherListResponse from "./list/dispatcher-list.response";
import dispatcherUpdateRequestFactory from "./update/dispatcher-update-request.factory";
import DispatcherUpdateRequest, {
  DispatcherUpdateRequestBody,
} from "./update/dispatcher-update.request";
import DispatcherUpdateResponse from "./update/dispatcher-update.response";
import HttpResponse from "../../../utils/http/http.response";
import railyApiService from "../raily-api.service";
import dispatcherAddRequestFactory from "./add/dispatcher-add-request.factory";

const dispatcherApiService = () => {
  const add = (
    request: DispatcherAddRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/dispatchers`;

    const body: DispatcherAddRequestBody =
      dispatcherAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const getList = async (
    request: DispatcherListRequest,
    abortSignal: AbortSignal
  ): Promise<DispatcherListResponse> => {
    const url = `/users/all/dispatchers`;

    const queryParams: DispatcherListRequestQueryParams =
      dispatcherListRequestFactory.createQueryParams(request);

    return railyApiService().get<DispatcherListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const update = (
    request: DispatcherUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<DispatcherUpdateResponse> => {
    const url = `/users/${request.dispatcherId}/dispatcher`;

    const body: DispatcherUpdateRequestBody =
      dispatcherUpdateRequestFactory.createBody(request);

    return railyApiService().put<DispatcherUpdateResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const getEditForm = (
    request: DispatcherEditFormRequest,
    abortSignal: AbortSignal
  ): Promise<DispatcherEditFormResponse> => {
    const url = `/users/${request.dispatcherId}/dispatcher/edit-form`;

    return railyApiService().get<DispatcherEditFormResponse>({
      url,
      abortSignal,
    });
  };

  return { add, getList, update, getEditForm };
};

export default dispatcherApiService;
