import { FC, useRef, useState } from "react";
import OrderOptimizerListingFilter from "../common/types/order-optimizer-listing-filter";
import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderOptimizerListingFilterType from "../common/types/order-optimizer-listing-filter-type";
import useKeyboardPress from "../../../../common/hooks/use-keyboard-press";

type OrderOptimizerListingFiltersProps = {
  filters: OrderOptimizerListingFilter[];
  isListingLoading: boolean;
  onFiltersChange: (filters: OrderOptimizerListingFilter[]) => void;
};

const OrderOptimizerListingFiltersComponent: FC<
  OrderOptimizerListingFiltersProps
> = (props) => {
  const translations =
    orderOptimizerTranslationsHelper.getListingTranslations().filters;

  const [filterSearchQuery, setFilterSearchQuery] = useState<number | null>(
    () =>
      props.filters.find(
        (filter) =>
          filter.type == OrderOptimizerListingFilterType.ORDER_HUMAN_ID
      )?.value ?? null
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useKeyboardPress({
    Enter: () => addFilter(),
    ref: inputRef,
  });

  const addFilter = () => {
    const newAppliedFilters: OrderOptimizerListingFilter[] = [];

    if (filterSearchQuery) {
      const newFilter: OrderOptimizerListingFilter = {
        type: OrderOptimizerListingFilterType.ORDER_HUMAN_ID,
        value: filterSearchQuery,
      };

      newAppliedFilters.push(newFilter);
    }

    props.onFiltersChange(newAppliedFilters);
  };

  return (
    <div className="order_optimizer_listing_filter">
      <ButtonComponent
        onClick={() => {
          setFilterSearchQuery(null);
          props.onFiltersChange([]);
        }}
        classNames={{
          root: "order_optimizer_listing_filter__button",
        }}
        isLoading={props.isListingLoading}
        title={translations.getAllOrdersButtonLabel}
        type="primary"
        idForTesting="order-optimizer-listing-filter-button"
      >
        {translations.getAllOrdersButtonLabel}
      </ButtonComponent>
      <div className="order_optimizer_listing_filter__input_wrapper">
        <FormFieldComponent label={translations.orderHumanIdPlaceholder}>
          <NumericInputComponent
            inputRef={inputRef}
            placeholder={translations.orderHumanIdPlaceholder}
            value={filterSearchQuery}
            onChange={setFilterSearchQuery}
            idForTesting="order-optimizer-listing-filter"
            isIntegerOnly
          />
        </FormFieldComponent>
        <ButtonComponent
          classNames={{
            root: "order_optimizer_listing_filter__input_wrapper__button",
          }}
          onClick={addFilter}
          title={translations.orderHumanIdPlaceholder}
          type="primary"
          idForTesting="order-optimizer-listing-filter-order-human-id-button"
        >
          <FontAwesomeIcon
            className="order_optimizer_listing_filter__input_wrapper__button__icon"
            icon={faMagnifyingGlass}
          />
        </ButtonComponent>
      </div>
    </div>
  );
};

export default OrderOptimizerListingFiltersComponent;
