import BillingRouteDetailsTableColumn from "../types/billings-route-details-table-column";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingRouteDetailsFormDataValidationResult, {
  NodeValidationResult,
} from "../types/billings-route-details-form-data-validation-result";
import BillingsRouteDetailsFormDataValidationResult from "../types/billings-route-details-form-data-validation-result";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import billingRouteDetailsFormDataValidationService from "./billing-route-details-form-data-validation.service";
import BillingsNode from "../../types/billings-node";
import BillingsRouteDetailsFormFieldValidationResult from "../types/billings-route-details-form-field-validation-result";

const validationOnBlur = (
  fieldToValidate: number | undefined,
  formValidationResults: BillingsRouteDetailsFormDataValidationResult,
  position: number,
  fieldName: string
): BillingsRouteDetailsFormFieldValidationResult => {
  const validationResults = validationField(
    formValidationResults,
    fieldToValidate,
    position,
    fieldName
  );

  const areOtherNodesValid = formValidationResults.nodeValidations
    .filter((node) => node.position !== position)
    .map((node) => {
      type Node = keyof Omit<typeof node, "position">;

      return node[fieldName as Node].isValid;
    });

  const isAllFormValid = !!(
    areOtherNodesValid.every((item) => item === true) &&
    validationResults?.isValid
  );

  return {
    areOtherNodesValid,
    isValid: validationResults?.isValid ?? false,
    nodeResult: validationResults?.nodeResult ?? [],
    isAllFormValid: isAllFormValid,
  };
};

const validateHaltingTime = (
  formValidationResults: BillingRouteDetailsFormDataValidationResult,
  haltingTime: number | undefined,
  position: number
) => {
  const haltingTimeInMinutes = haltingTime ? haltingTime / 60 : 0;

  const validationResult =
    billingRouteDetailsFormDataValidationService.validateHaltingTime(
      haltingTimeInMinutes
    );

  const unchangedItems: NodeValidationResult[] =
    formValidationResults.nodeValidations.filter(
      (x) => x.position !== position
    );

  const changedItem: NodeValidationResult | undefined =
    formValidationResults.nodeValidations.find((x) => x.position === position);

  const nodeResult: NodeValidationResult[] = [
    ...unchangedItems,
    {
      haltingTime: validationResult,
      distance:
        changedItem?.distance ?? formValidationService.defaultValidationResult,
      highwayCharge:
        changedItem?.highwayCharge ??
        formValidationService.defaultValidationResult,
      position,
    },
  ];

  return { nodeResult, isValid: validationResult.isValid };
};

const validatehHghwayCost = (
  formValidationResults: BillingRouteDetailsFormDataValidationResult,
  highwayCost: number | undefined,
  position: number
) => {
  const validationResult =
    billingRouteDetailsFormDataValidationService.validateHighwayCost(
      highwayCost
    );

  const unchangedItems: NodeValidationResult[] =
    formValidationResults.nodeValidations.filter(
      (x) => x.position !== position
    );

  const changedItem: NodeValidationResult | undefined =
    formValidationResults.nodeValidations.find((x) => x.position === position);

  const nodeResult: NodeValidationResult[] = [
    ...unchangedItems,
    {
      haltingTime:
        changedItem?.haltingTime ??
        formValidationService.defaultValidationResult,
      distance:
        changedItem?.distance ?? formValidationService.defaultValidationResult,
      highwayCharge: validationResult,
      position,
    },
  ];

  return { nodeResult, isValid: validationResult.isValid };
};

const validateDistance = (
  formValidationResults: BillingRouteDetailsFormDataValidationResult,
  distance: number | undefined,
  position: number
) => {
  const validationResult =
    billingRouteDetailsFormDataValidationService.validateDistance(distance);

  const unchangedItems: NodeValidationResult[] =
    formValidationResults.nodeValidations.filter(
      (x) => x.position !== position
    );

  const changedItem: NodeValidationResult | undefined =
    formValidationResults.nodeValidations.find((x) => x.position === position);

  const nodeResult: NodeValidationResult[] = [
    ...unchangedItems,
    {
      haltingTime:
        changedItem?.haltingTime ??
        formValidationService.defaultValidationResult,
      distance: validationResult,
      highwayCharge:
        changedItem?.highwayCharge ??
        formValidationService.defaultValidationResult,
      position,
    },
  ];

  return { nodeResult, isValid: validationResult.isValid };
};

const haltingTimeValidation = (
  formValidationResults: BillingRouteDetailsFormDataValidationResult,
  haltingTime: number | undefined,
  position: number
) =>
  billingsRouteDetailsHelper.validateHaltingTime(
    formValidationResults,
    haltingTime,
    position
  );

const validationField = (
  formValidationResults: BillingRouteDetailsFormDataValidationResult,
  fieldToValidate: number | undefined,
  position: number,
  fieldName: string
) => {
  switch (fieldName) {
    case "haltingTime":
      return haltingTimeValidation(
        formValidationResults,
        fieldToValidate,
        position
      );
    case "highwayCharge":
      return validatehHghwayCost(
        formValidationResults,
        fieldToValidate,
        position
      );
    case "distance":
      return validateDistance(formValidationResults, fieldToValidate, position);
  }
};

const validateAllFieldsByType = (
  formValidationResults: BillingRouteDetailsFormDataValidationResult,
  nodes: BillingsNode[],
  fieldName: string
) => {
  let param = formValidationResults;
  let result: BillingsRouteDetailsFormFieldValidationResult | null;

  nodes.forEach((node) => {
    result = validationOnBlur(node.distance, param, node.position, fieldName);

    param = {
      discountValue: formValidationResults.discountValue,
      nodeValidations: result.nodeResult!,
    };
  });

  return result!;
};

const getTableColumns = (): BillingRouteDetailsTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getRouteDetailsTranslations().table;

  const tableColumns: BillingRouteDetailsTableColumn[] = [
    {
      header: translations.headers.orderPointsLabel,
      title: translations.headers.orderPointsTitle,
      accessor: "routeNode",
      colSpan: 15,
    },
    {
      header: translations.headers.timeLabel,
      title: translations.headers.timeTitle,
      accessor: "time",
      colSpan: 5,
    },
    {
      header: translations.headers.plannedTimeLabel,
      title: translations.headers.plannedTimeTitle,
      accessor: "plannedTime",
      colSpan: 6,
    },
    {
      header: translations.headers.haltingTimeLabel,
      title: translations.headers.haltingTimeTitle,
      accessor: "haltingTime",
      colSpan: 6,
    },
    {
      header: translations.headers.distanceLabel,
      title: translations.headers.distanceTitle,
      accessor: "distance",
      colSpan: 5,
    },
    {
      header: translations.headers.plannedDistanceLabel,
      title: translations.headers.plannedDistanceTitle,
      accessor: "plannedDistance",
      colSpan: 5,
    },
    {
      header: translations.headers.highwayCostLabel,
      title: translations.headers.highwayCostTitle,
      accessor: "highwayCost",
      colSpan: 5,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 3,
    },
  ];

  return tableColumns;
};

const billingsRouteDetailsHelper = {
  validateHaltingTime,
  validatehHghwayCost,
  validateDistance,
  validationOnBlur,
  validationField,
  haltingTimeValidation,
  getTableColumns,
  validateAllFieldsByType,
};

export default billingsRouteDetailsHelper;
