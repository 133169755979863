import userFormValidationService from "../../../../../shared/user/user.validation.service";

const operatorAddFormValidationService = {
  validateFirstName: userFormValidationService.validateFirstName,
  validateLastName: userFormValidationService.validateLastName,
  validatePhoneNumber: userFormValidationService.validateMobile,
  validateAlternativePhoneNumber:
    userFormValidationService.validateAlternativeMobile,
  validateEmail: userFormValidationService.validateEmail,
  validateUsername: userFormValidationService.validateUsername,
  validatePassword: userFormValidationService.validatePassword,
  validatePasswordRepeat: userFormValidationService.validatePasswordRepeat,
};

export default operatorAddFormValidationService;
