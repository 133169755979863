import {
  TaxiOrderSettlementListingResponseDataItem,
  TaxiOrderSettlementListingResponseDataStats,
} from "./api/taxi-order-settlement-listing.response";
import TaxiOrderSettlementListingItem from "./types/taxi-order-settlement-listing-item";
import TaxiOrderSettlementListingItemBillingStatus from "./types/taxi-order-settlement-listing-item-billing-status";
import TaxiOrderSettlementListingStatsSummary from "./types/taxi-order-settlement-listing-stats-summary";

const createListingItemStatus = (
  responseDataItem: TaxiOrderSettlementListingResponseDataItem
): TaxiOrderSettlementListingItemBillingStatus | undefined => {
  switch (responseDataItem.status) {
    case "CREATED":
      return TaxiOrderSettlementListingItemBillingStatus.CREATED;
    case "ACCEPTED":
      return TaxiOrderSettlementListingItemBillingStatus.ACCEPTED;
    case "REJECTED":
      return TaxiOrderSettlementListingItemBillingStatus.REJECTED;
    case "REOPENED":
      return TaxiOrderSettlementListingItemBillingStatus.REOPENED;
    case "REOPEN_REQUEST":
      return TaxiOrderSettlementListingItemBillingStatus.REOPEN_REQUEST_SENT;
    default:
      return undefined;
  }
};

const createListingItem = (
  responseDataItem: TaxiOrderSettlementListingResponseDataItem
): TaxiOrderSettlementListingItem => {
  return {
    uuid: responseDataItem.id,
    internalOrderId: responseDataItem.human_id,
    planEntryHumanIds: responseDataItem.plan_entry_human_ids,
    externalOrderId: responseDataItem.external_id,
    orderStartDate: new Date(responseDataItem.date),
    cargoCompanyName: responseDataItem.cargo_company,
    billingModel: responseDataItem.billing_model,
    billingType: responseDataItem.billing_type,
    status: createListingItemStatus(responseDataItem),
    distance: responseDataItem.distance,
    amountForDistance: responseDataItem.amount_for_distance,
    baseDistanceRate: responseDataItem.base_distance_rate,
    stopoverCost: responseDataItem.amount_for_charge_haltings ?? 0,
    tollRoadsCost: responseDataItem.amount_for_charge_highways ?? 0,
    discountAmountPercent: responseDataItem.contract_correction_percentage ?? 0,
    totalCost: responseDataItem.all_contributions_amount,
    sumOfDiscounts: responseDataItem.sum_of_discounts ?? 0,
    passengers: responseDataItem.passengers,
    destinationTaxi: responseDataItem.responsible_taxi ?? "",
  };
};

const createListingItems = (
  responseDataItems: TaxiOrderSettlementListingResponseDataItem[]
): TaxiOrderSettlementListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const createStatsSummary = (
  responseStatsSummary: TaxiOrderSettlementListingResponseDataStats
): TaxiOrderSettlementListingStatsSummary => {
  return {
    distance: responseStatsSummary.distance,
    amountForChargeHaltings: responseStatsSummary.amount_for_charge_haltings,
    amountForChargeHighways: responseStatsSummary.amount_for_charge_highways,
    amountForDistance: responseStatsSummary.amount_for_distance,
    sumOfDiscounts: responseStatsSummary.sum_of_discounts,
    allContributionsAmount: responseStatsSummary.all_contributions_amount,
  };
};

const taxiOrderSettlementListingFactory = {
  createListingItems,
  createStatsSummary,
};

export default taxiOrderSettlementListingFactory;
