import BillingsPlanEntryCargoTaxiRequest from "../../../../api/raily/billings/plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi.request";
import BillingsPlanEntryCargoTaxiLoadParams from "./billings-plan-entry-cargo-taxi-load-params";

const create = (
  params: BillingsPlanEntryCargoTaxiLoadParams
): BillingsPlanEntryCargoTaxiRequest => {
  return {
    planEntryId: params.planEntryUuid,
  };
};

const billingsPlanEntryCargoTaxiRequestFactory = {
  create,
};

export default billingsPlanEntryCargoTaxiRequestFactory;
