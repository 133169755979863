import { FC } from "react";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteDetailsCompletedRouteComponent from "./elements/route-details-completed-route.component";

export type RouteDetailsToolsProps = {
  isCompletedRoutesFetching: boolean;
  isCompletedRouteButtonActive: boolean;
  toggleCompletedMapRoutesActive: () => void;
};

const RouteDetailsToolsComponent: FC<RouteDetailsToolsProps> = (props) => {
  const { tools } = routeTranslationsHelper.getDetailsTranslations();

  return (
    <div className="route_details_tools">
      <RouteDetailsCompletedRouteComponent
        tools={tools}
        isLoading={props.isCompletedRoutesFetching}
        isActive={props.isCompletedRouteButtonActive}
        onSuccess={props.toggleCompletedMapRoutesActive}
      />
    </div>
  );
};

export default RouteDetailsToolsComponent;
