import MileageDetails, {
  MileageDetailsAddressSequenceNode,
  MileageDetailsVehicleType,
} from "../../../../../common/services/mileage/details/mileage-details";
import MileageUpdateAddress from "../types/mileage-update-address";
import MileageUpdateVehicleType from "../types/mileage-update-vehicle-type";
import MileageUpdateFormData from "./types/mileage-update-form-data";

const createAddress = (
  node: MileageDetailsAddressSequenceNode
): MileageUpdateAddress => {
  return {
    displayName: node.displayName,
    zipCode: node.zipCode,
    town: node.town,
    street: node.street,
    houseNumber: node.houseNumber,
    apartmentNumber: node.apartmentNumber,
    latitude: node.lat,
    longitude: node.lon,
  };
};

const createVehicleType = (
  type: MileageDetailsVehicleType | undefined
): MileageUpdateVehicleType => {
  switch (type) {
    case MileageDetailsVehicleType.CAR_GT_900CM3:
      return MileageUpdateVehicleType.CAR_GT_900CM3;
    case MileageDetailsVehicleType.CAR_LE_900CM3:
      return MileageUpdateVehicleType.CAR_LE_900CM3;
    case MileageDetailsVehicleType.COMPANY_OWNED:
      return MileageUpdateVehicleType.COMPANY_OWNED;
    case MileageDetailsVehicleType.MOPED:
      return MileageUpdateVehicleType.MOPED;
    case MileageDetailsVehicleType.MOTORCYCLE:
      return MileageUpdateVehicleType.MOTORCYCLE;
    default:
      return MileageUpdateVehicleType.COMPANY_OWNED;
  }
};

const create = (details: MileageDetails): MileageUpdateFormData => {
  return {
    addressSequence: details.addressSeq.seq.map(createAddress),
    distance: details.acceptedDistance ?? details.distance,
    mileageDate: details.mileageDate,
    vehicleType: createVehicleType(details.vehicleType),
    osrmDistance: details.osrmDistance ?? details.distance,
  };
};

const mileageUpdateFormFactory = {
  create,
};

export default mileageUpdateFormFactory;
