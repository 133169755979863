import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FC, useMemo } from "react";
import useAbort from "../../../../../common/hooks/use-abort";
import useOpen from "../../../../../common/hooks/use-open";
import mileageService from "../../../../../common/services/mileage/mileage.service";
import MileageUpdateParams, {
  MileageUpdateParamsStatus,
} from "../../../../../common/services/mileage/update/mileage-update-params";
import MileageDetailsToolButtonComponent from "../../common/tool-button/mileage-details-tool-button.component";
import MileageDetailsMileageAcceptModalComponent from "../../mileage-accept/mileage-accept.component";
import MileageListingStatus from "../../../listing/common/types/mileage-listing-status";
import MileageDetailsProps from "../types/mileage-details-props";

const MileageDetailsAcceptComponent: FC<MileageDetailsProps> = (props) => {
  const isDisabled = useMemo(() => {
    return props.details.status === MileageListingStatus.DELEGATION_CREATED;
  }, [props.details.status]);

  const { isOpen, open, close } = useOpen();
  const abort = useAbort();

  const handleOnConfirm = (acceptedDistance: number) => {
    if (isDisabled) {
      return;
    }

    const params: MileageUpdateParams = {
      mileageUuid: props.details?.mileageUuid!,
      state: MileageUpdateParamsStatus.ACCEPTED,
      acceptedDistance: acceptedDistance,
    };
    mileageService
      .update(params, abort.signal)
      .then(close)
      .then(props.onSuccess);
  };

  const handleOnReject = () => {
    if (isDisabled) {
      return;
    }

    const params: MileageUpdateParams = {
      mileageUuid: props.details?.mileageUuid!,
      state: MileageUpdateParamsStatus.REJECTED,
    };
    mileageService
      .update(params, abort.signal)
      .then(close)
      .then(props.onSuccess);
  };

  return (
    <>
      <MileageDetailsToolButtonComponent
        onClick={open}
        icon={faCheck}
        title={props.translations.acceptMileageButtonTitle}
        type="success"
        isDisabled={isDisabled}
      />
      {!!isOpen && (
        <MileageDetailsMileageAcceptModalComponent
          isOpen={isOpen}
          onClose={close}
          onConfirm={handleOnConfirm}
          onReject={handleOnReject}
          details={props.details}
        />
      )}
    </>
  );
};

export default MileageDetailsAcceptComponent;
