import appConfig from "../../../../app.config";
import authService from "../../../auth/common/auth.service";
import AppAsideAttentionCountersResponse from "./app-aside-attention-counters.response";

const fetchAttentionCounters =
  async (): Promise<AppAsideAttentionCountersResponse> => {
    const path = `${appConfig.baseApiUrl}/meta/attention_counters`;

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
    };

    const r = await fetch(path, init);
    const responseData = await r.json();

    return {
      status: r.status,
      data: responseData,
    };
  };

const appAsideAttentionCountersApiService = {
  fetchAttentionCounters,
};

export default appAsideAttentionCountersApiService;
