import DispatcherAddRequest from "../../../api/raily/dispatcher/add/dispatcher-add.request";
import DispatcherAddParams from "./dispatcher-add-params";

const create = (params: DispatcherAddParams): DispatcherAddRequest => {
  return {
    dispatchId: params.dispatchUuid,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.phoneNumber.number,
    mobilePrimaryPrefix: params.phoneNumber.dialingCode,
    mobileSecondary: params.alternativePhoneNumber.number || null,
    mobileSecondaryPrefix: params.alternativePhoneNumber.dialingCode || null,
    password: params.password,
    username: params.username,
  };
};

const cargoOfficerAddRequestFactory = {
  create,
};

export default cargoOfficerAddRequestFactory;
