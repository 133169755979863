import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import UserStatus from "../../../../../common/types/user-status";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import OperatorListingFilter, {
  OperatorListingStatusFilter,
  OperatorListingFirstNameFilter,
  OperatorListingLastNameFilter,
  OperatorListingEmailFilter,
  OperatorListingUsernameFilter,
} from "../common/types/operator-listing-filter";
import OperatorListingFilterType from "../common/types/operator-listing-filter-type";
import OperatorListingSortKey from "../common/types/operator-listing-sort-key";

const operatorListingFilterHelper = () => {
  const translations = userTranslationsHelper.getOperatorListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForStatus = (
    filterValue: OperatorListingStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText = userHelper.getActivityStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.activityStatusLabel,
      translations.filters.activityStatusTitle
    );
  };

  const getBadgeForFirstName = (
    filterValue: OperatorListingFirstNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.firstNameLabel,
      translations.filters.firstNameTitle
    );
  };

  const getBadgeForLastName = (
    filterValue: OperatorListingLastNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.lastNameLabel,
      translations.filters.lastNameTitle
    );
  };

  const getBadgeForEmail = (
    filterValue: OperatorListingEmailFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.emailLabel,
      translations.filters.emailTitle
    );
  };

  const getBadgeForUsername = (
    filterValue: OperatorListingUsernameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.usernameLabel,
      translations.filters.usernameTitle
    );
  };

  const getSelectDefinitionForStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.ACTIVE)
              ),
            value: UserStatus.ACTIVE,
          },
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.DELETED)
              ),
            value: UserStatus.DELETED,
          },
        ],
      };
    };

  const getSelectDataForFirstName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.firstNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForLastName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.lastNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForUsername = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.usernameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForEmail = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.emailTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: OperatorListingFilterType,
    query: string
  ) => {
    const options: Record<
      OperatorListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [OperatorListingFilterType.STATUS]: getSelectDefinitionForStatus,
      [OperatorListingFilterType.FIRST_NAME]: getSelectDataForFirstName,
      [OperatorListingFilterType.LAST_NAME]: getSelectDefinitionForLastName,
      [OperatorListingFilterType.USERNAME]: getSelectDefinitionForUsername,
      [OperatorListingFilterType.EMAIL]: getSelectDefinitionForEmail,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeDefinitionByType = (
    filterType: OperatorListingFilterType,
    filterValue: OperatorListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      OperatorListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [OperatorListingFilterType.STATUS]: () =>
        getBadgeForStatus(filterValue as OperatorListingStatusFilter["value"]),
      [OperatorListingFilterType.FIRST_NAME]: () =>
        getBadgeForFirstName(
          filterValue as OperatorListingFirstNameFilter["value"]
        ),
      [OperatorListingFilterType.LAST_NAME]: () =>
        getBadgeForLastName(
          filterValue as OperatorListingLastNameFilter["value"]
        ),
      [OperatorListingFilterType.EMAIL]: () =>
        getBadgeForEmail(filterValue as OperatorListingEmailFilter["value"]),
      [OperatorListingFilterType.USERNAME]: () =>
        getBadgeForUsername(
          filterValue as OperatorListingUsernameFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: OperatorListingFilterType
  ): ListingFilterDefinitionOption<OperatorListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<OperatorListingFilter>[] => {
      return Object.values(OperatorListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<OperatorListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.firstNameAscLabel,
        value: OperatorListingSortKey.FIRST_NAME_ASC,
      },
      {
        label: translations.sort.firstNameDescLabel,
        value: OperatorListingSortKey.FIRST_NAME_DESC,
      },
      {
        label: translations.sort.lastNameAscLabel,
        value: OperatorListingSortKey.LAST_NAME_ASC,
      },
      {
        label: translations.sort.lastNameDescLabel,
        value: OperatorListingSortKey.LAST_NAME_DESC,
      },
      {
        label: translations.sort.usernameAscLabel,
        value: OperatorListingSortKey.USERNAME_ASC,
      },
      {
        label: translations.sort.usernameDescLabel,
        value: OperatorListingSortKey.USERNAME_DESC,
      },
    ];
  };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default operatorListingFilterHelper;
