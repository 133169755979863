import appTranslationsHelper from "./app-translations.helper";
import TaxiCargoContractAddTranslations from "./types/taxi/cargo-contract/taxi-cargo-contract-add.translations";
import TaxiAddTranslations from "./types/taxi/taxi-add.translations";
import TaxiCargoContractListingTranslations from "./types/taxi/cargo-contract/taxi-cargo-contract-listing.translations";
import TaxiListingTranslations from "./types/taxi/taxi-listing.translations";
import TaxiTaxiContractAddTranslations from "./types/taxi/taxi-contract/taxi-taxi-contract-add.translations";
import TaxiTaxiContractListingTranslations from "./types/taxi/taxi-contract/taxi-taxi-contract-listing.translations";
import TaxiTaxiContractEditTranslations from "./types/taxi/taxi-contract/taxi-taxi-contract-edit.translations";
import TaxiFleetPartnerListingTranslations from "./types/taxi/fleet-partner/taxi-fleet-partner-listing.translations";
import TaxiFleetPartnerAddTranslations from "./types/taxi/fleet-partner/taxi-fleet-partner-add.translations";
import TaxiFleetPartnerEditTranslations from "./types/taxi/fleet-partner/taxi-fleet-partner-edit.translations";
import TaxiCargoContractEditTranslations from "./types/taxi/cargo-contract/taxi-cargo-contract-edit.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `taxi`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getAddTranslations = (): TaxiAddTranslations => {
  const translations = getTranslations<TaxiAddTranslations>(
    `taxi-add.translations.json`
  );

  return translations;
};

const getTaxiListingTranslations = (): TaxiListingTranslations => {
  const translations = getTranslations<TaxiListingTranslations>(
    `taxi-listing.translations.json`
  );

  return translations;
};

const getCargoContractAddTranslations =
  (): TaxiCargoContractAddTranslations => {
    const translations = getTranslations<TaxiCargoContractAddTranslations>(
      `cargo-contract/taxi-cargo-contract-add.translations.json`
    );

    return translations;
  };

const getCargoContractEditTranslations =
  (): TaxiCargoContractEditTranslations => {
    const translations = getTranslations<TaxiCargoContractEditTranslations>(
      `cargo-contract/taxi-cargo-contract-edit.translations.json`
    );

    return translations;
  };

const getCargoContractListingTranslations =
  (): TaxiCargoContractListingTranslations => {
    const translations = getTranslations<TaxiCargoContractListingTranslations>(
      `cargo-contract/taxi-cargo-contract-listing.translations.json`
    );

    return translations;
  };

const getTaxiContractAddTranslations = (): TaxiTaxiContractAddTranslations => {
  const translations = getTranslations<TaxiTaxiContractAddTranslations>(
    `taxi-contract/taxi-taxi-contract-add.translations.json`
  );

  return translations;
};

const getTaxiContractEditTranslations =
  (): TaxiTaxiContractEditTranslations => {
    const translations = getTranslations<TaxiTaxiContractEditTranslations>(
      `taxi-contract/taxi-taxi-contract-edit.translations.json`
    );

    return translations;
  };

const getTaxiContractListingTranslations =
  (): TaxiTaxiContractListingTranslations => {
    const translations = getTranslations<TaxiTaxiContractListingTranslations>(
      `taxi-contract/taxi-taxi-contract-listing.translations.json`
    );

    return translations;
  };

const getTaxiFleetPartnerListingTranslations =
  (): TaxiFleetPartnerListingTranslations => {
    const translations = getTranslations<TaxiFleetPartnerListingTranslations>(
      `fleet-partner/taxi-fleet-partner-listing.translations.json`
    );

    return translations;
  };

const getTaxiFleetPartnerAddTranslations =
  (): TaxiFleetPartnerAddTranslations => {
    const translations = getTranslations<TaxiFleetPartnerAddTranslations>(
      `fleet-partner/taxi-fleet-partner-add.translations.json`
    );

    return translations;
  };

const getTaxiFleetPartnerEditTranslations =
  (): TaxiFleetPartnerEditTranslations => {
    const translations = getTranslations<TaxiFleetPartnerEditTranslations>(
      `fleet-partner/taxi-fleet-partner-edit.translations.json`
    );

    return translations;
  };

const taxiTranslationsHelper = {
  getAddTranslations,
  getTaxiContractAddTranslations,
  getTaxiContractListingTranslations,
  getTaxiListingTranslations,
  getCargoContractAddTranslations,
  getCargoContractEditTranslations,
  getCargoContractListingTranslations,
  getTaxiContractEditTranslations,
  getTaxiFleetPartnerListingTranslations,
  getTaxiFleetPartnerAddTranslations,
  getTaxiFleetPartnerEditTranslations,
};

export default taxiTranslationsHelper;
