import TaxiOfficerListResponse, {
  TaxiOfficerListResponseItem,
  TaxiOfficerListResponseItemContractMaintenancePolicy,
  TaxiOfficerListResponseItemTaxiCorporation,
} from "../../../api/raily/taxi-officer/list/taxi-officer-list.response";
import {
  TaxiContractMaintenancePolicy,
  TaxiOfficerListItem,
  TaxiOfficerListItemTaxiCorporation,
} from "./taxi-officer-list";
import TaxiOfficerList from "./taxi-officer-list";

const createAlternativePhoneNumber = (
  responseDataItem: TaxiOfficerListResponseItem
): TaxiOfficerListItem["alternativePhoneNumber"] => {
  if (
    !responseDataItem.mobileSecondary ||
    !responseDataItem.mobileSecondaryPrefix
  ) {
    return null;
  }

  return {
    dialingCode: responseDataItem.mobileSecondaryPrefix,
    number: responseDataItem.mobileSecondary,
  };
};

const createContractMaintenancePolicy = (
  responseContractMaintenancePolicy: TaxiOfficerListResponseItemContractMaintenancePolicy
): TaxiContractMaintenancePolicy => {
  switch (responseContractMaintenancePolicy) {
    case TaxiOfficerListResponseItemContractMaintenancePolicy.AUCTION:
      return TaxiContractMaintenancePolicy.AUCTION;
    case TaxiOfficerListResponseItemContractMaintenancePolicy.BASIC:
      return TaxiContractMaintenancePolicy.BASIC;
  }
};

const createTaxiCorporation = (
  responseTaxiCorporation: TaxiOfficerListResponseItemTaxiCorporation
): TaxiOfficerListItemTaxiCorporation => {
  return {
    uuid: responseTaxiCorporation.id,
    companyId: responseTaxiCorporation.regon,
    contractMaintenancePolicy: createContractMaintenancePolicy(
      responseTaxiCorporation.contractMaintenancePolicy
    ),
    address: responseTaxiCorporation.headquartersAddressString,
    displayName: responseTaxiCorporation.displayName,
    name: responseTaxiCorporation.companyName,
    nationalCourtRegister: responseTaxiCorporation.krsNo,
    notes: responseTaxiCorporation.notes,
    taxId: responseTaxiCorporation.taxId,
  };
};

const createDataItem = (
  responseDataItem: TaxiOfficerListResponseItem
): TaxiOfficerListItem => {
  return {
    uuid: responseDataItem.id,
    removalDate: responseDataItem.removedAt,
    alternativePhoneNumber: createAlternativePhoneNumber(responseDataItem),
    taxiCorporation: createTaxiCorporation(
      responseDataItem.taxiOfficer.taxiCorporation!
    ),
    email: responseDataItem.email,
    firstName: responseDataItem.firstName,
    lastName: responseDataItem.lastName,
    username: responseDataItem.username,
    phoneNumber: {
      dialingCode: responseDataItem.mobilePrimaryPrefix,
      number: responseDataItem.mobilePrimary,
    },
  };
};

const createData = (
  responseData: TaxiOfficerListResponseItem[]
): TaxiOfficerListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: TaxiOfficerListResponse): TaxiOfficerList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const taxiOfficerListFactory = {
  create,
};

export default taxiOfficerListFactory;
