import listingFilterService from "../../../../../../common/utils/listing/filters/listing-filter.service";
import TaxiCargoContractListingBillingModel from "../types/taxi-cargo-contract-listing-billing-model";
import TaxiCargoContractListingFilter, {
  TaxiCargoContractListingBillingModelFilter,
} from "../types/taxi-cargo-contract-listing-filter";
import TaxiCargoContractListingFilterType from "../types/taxi-cargo-contract-listing-filter-type";
import TaxiCargoContractListingItem from "../types/taxi-cargo-contract-listing-item";

const filterListingItemsByBillingModel = (
  listingItems: TaxiCargoContractListingItem[],
  options: TaxiCargoContractListingBillingModel[]
) => {
  return listingFilterService.filterByEnumProperty(
    listingItems,
    "billingModel",
    options
  );
};

const filterListingItemsByCargoCompany = (
  listingItems: TaxiCargoContractListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByStringProperty(
    listingItems,
    "cargoCompany",
    queries
  );
};

const filterListingItems = (
  listingItems: TaxiCargoContractListingItem[],
  filters: TaxiCargoContractListingFilter[]
): TaxiCargoContractListingItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: TaxiCargoContractListingItem[] = [...listingItems];

  const billingModelFilterQueries =
    listingFilterService.getValuesOfGivenFilterType(
      filters,
      TaxiCargoContractListingFilterType.BILLING_MODEL
    ) as TaxiCargoContractListingBillingModelFilter["value"][];

  if (billingModelFilterQueries.length) {
    filteredListingItems = filterListingItemsByBillingModel(
      filteredListingItems,
      billingModelFilterQueries
    );
  }

  const cargoCompanyFilterQueries =
    listingFilterService.getValuesOfGivenFilterType(
      filters,
      TaxiCargoContractListingFilterType.CARGO_COMPANY
    );

  if (cargoCompanyFilterQueries.length) {
    filteredListingItems = filterListingItemsByCargoCompany(
      filteredListingItems,
      cargoCompanyFilterQueries
    );
  }

  return filteredListingItems;
};

const taxiCargoContractListingFilterService = { filterListingItems };

export default taxiCargoContractListingFilterService;
