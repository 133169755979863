import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import {
  faAdd,
  faEdit,
  faHandshake,
  faIcons,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import taxiRoutesHelper from "../routes/taxi-routes.helper";
import TaxiFleetPartnerEditBreadcrumbsParams from "./types/taxi-fleet-partner-edit-breadcrumbs-params";
import TaxiCargoContractListingBreadcrumbsParams from "./types/taxi-cargo-contract-listing-breadcrumbs-params";
import TaxiTaxiContractListingBreadcrumbsParams from "./types/taxi-taxi-contract-listing-breadcrumbs-params";
import TaxiFleetPartnerListingBreadcrumbsParams from "./types/taxi-fleet-partner-listing-breadcrumbs-params";
import TaxiFleetPartnerAddBreadcrumbsParams from "./types/taxi-fleet-partner-add-breadcrumbs-params";
import TaxiCargoContractAddBreadcrumbsParams from "./types/taxi-cargo-contract-add-breadcrumbs-params";
import TaxiCargoContractEditBreadcrumbsParams from "./types/taxi-cargo-contract-edit-breadcrumbs-params";
import TaxiTaxiContractAddBreadcrumbsParams from "./types/taxi-taxi-contract-add-breadcrumbs-params";
import TaxiTaxiContractEditBreadcrumbsParams from "./types/taxi-taxi-contract-edit-breadcrumbs-params";

const getAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: taxiRoutesHelper.getAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().taxiAdd,
    },
  ];
};

const getListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: taxiRoutesHelper.getListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().taxiListing,
    },
  ];
};

const getCargoContractListingBreadcrumbs = (
  params: TaxiCargoContractListingBreadcrumbsParams
) => {
  return [
    ...getListingBreadcrumbs(),
    {
      icon: faList,
      linkTo: taxiRoutesHelper.getCargoContractListingRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .taxiCargoContractListing.replace(
          "#{taxiCorporationDisplayName}",
          params.taxiCorporationDisplayName ?? ""
        ),
    },
  ];
};

const getCargoContractAddBreadcrumbs = (
  params: TaxiCargoContractAddBreadcrumbsParams
) => {
  return [
    ...getCargoContractListingBreadcrumbs({
      taxiCorporationUuid: params.taxiCorporationUuid,
      taxiCorporationDisplayName: params.taxiCorporationDisplayName,
    }),
    {
      icon: faAdd,
      linkTo: taxiRoutesHelper.getCargoContractAddRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .taxiCargoContractAdd,
    },
  ];
};

const getCargoContractEditBreadcrumbs = (
  params: TaxiCargoContractEditBreadcrumbsParams
) => {
  return [
    ...getCargoContractListingBreadcrumbs({
      taxiCorporationUuid: params.taxiCorporationUuid,
      taxiCorporationDisplayName: params.taxiCorporationDisplayName,
    }),
    {
      icon: faEdit,
      linkTo: taxiRoutesHelper.getCargoContractEditRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
        contractUuid: params.contractUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .taxiCargoContractEdit,
    },
  ];
};

const getTaxiContractListingBreadcrumbs = (
  params: TaxiTaxiContractListingBreadcrumbsParams
) => {
  return [
    ...getListingBreadcrumbs(),
    {
      icon: faList,
      linkTo: taxiRoutesHelper.getTaxiContractListingRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .taxiTaxiContractListing.replace(
          "#{taxiCorporationDisplayName}",
          params.taxiCorporationDisplayName ?? ""
        ),
    },
  ];
};

const getTaxiContractAddBreadcrumbs = (
  params: TaxiTaxiContractAddBreadcrumbsParams
) => {
  return [
    ...getTaxiContractListingBreadcrumbs({
      taxiCorporationUuid: params.taxiCorporationUuid,
      taxiCorporationDisplayName: params.taxiCorporationDisplayName,
    }),
    {
      icon: faAdd,
      linkTo: taxiRoutesHelper.getTaxiContractAddRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .taxiTaxiContractAdd,
    },
  ];
};

const getTaxiContractEditBreadcrumbs = (
  params: TaxiTaxiContractEditBreadcrumbsParams
) => {
  return [
    ...getTaxiContractListingBreadcrumbs({
      taxiCorporationUuid: params.taxiCorporationUuid,
      taxiCorporationDisplayName: params.taxiCorporationDisplayName,
    }),
    {
      icon: faEdit,
      linkTo: taxiRoutesHelper.getTaxiContractEditRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
        contractUuid: params.contractUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .taxiTaxiContractEdit,
    },
  ];
};

const getFleetPartnerListingBreadcrumbs = (
  params: TaxiFleetPartnerListingBreadcrumbsParams
) => {
  return [
    ...getListingBreadcrumbs(),
    {
      icon: faHandshake,
      linkTo: taxiRoutesHelper.getFleetPartnerListingRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .taxiFleetPartnerListing,
    },
  ];
};

const getFleetPartnerAddBreadcrumbs = (
  params: TaxiFleetPartnerAddBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...getFleetPartnerListingBreadcrumbs({
      taxiCorporationUuid: params.taxiCorporationUuid,
    }),
    {
      icon: faAdd,
      linkTo: taxiRoutesHelper.getFleetPartnerAddRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .taxiFleetPartnerAdd,
    },
  ];
};

const getTaxiFleetPartnerEditBreadcrumbs = (
  params: TaxiFleetPartnerEditBreadcrumbsParams
): Breadcrumb[] => {
  return [
    ...getFleetPartnerListingBreadcrumbs({
      taxiCorporationUuid: params.taxiCorporationUuid,
    }),
    {
      icon: faEdit,
      linkTo: taxiRoutesHelper.getTaxiFleetPartnerEditRoute({
        taxiCorporationUuid: params.taxiCorporationUuid,
        fleetPartnerUuid: params.taxiFleetPartnerUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .taxiFleetPartnerEdit.replace(
          "#{fleetPartnerName}",
          params.taxiFleetPartnerName
        ),
    },
  ];
};

const taxiBreadcrumbsHelper = {
  getAddBreadcrumbs,
  getTaxiContractAddBreadcrumbs,
  getListingBreadcrumbs,
  getCargoContractAddBreadcrumbs,
  getCargoContractEditBreadcrumbs,
  getCargoContractListingBreadcrumbs,
  getTaxiContractListingBreadcrumbs,
  getTaxiContractEditBreadcrumbs,
  getFleetPartnerListingBreadcrumbs,
  getFleetPartnerAddBreadcrumbs,
  getTaxiFleetPartnerEditBreadcrumbs,
};

export default taxiBreadcrumbsHelper;
