import driverAddUserDataFormValidationService from "../../common/user/driver-add-user-data-form-validation.service";

const driverAddByRailyUserDataFormValidationService = {
  validateFirstName: driverAddUserDataFormValidationService.validateFirstName,
  validateLastName: driverAddUserDataFormValidationService.validateLastName,
  validateMobile: driverAddUserDataFormValidationService.validateMobile,
  validateAlternativeMobile:
    driverAddUserDataFormValidationService.validateAlternativeMobile,
  validateBirthDate: driverAddUserDataFormValidationService.validateBirthDate,
  validateBirthPlace: driverAddUserDataFormValidationService.validateBirthPlace,
  validatePersonalIdNumber:
    driverAddUserDataFormValidationService.validatePersonalIdNumber,
  validateCitizenship:
    driverAddUserDataFormValidationService.validateCitizenship,
  validateLanguages: driverAddUserDataFormValidationService.validateLanguages,
  validateYearsOfExperience:
    driverAddUserDataFormValidationService.validateYearsOfExperience,
  validateFormOfEmployment:
    driverAddUserDataFormValidationService.validateFormOfEmployment,
  validateFleetPartner:
    driverAddUserDataFormValidationService.validateFleetPartner,
  validateAddresses: driverAddUserDataFormValidationService.validateAddresses,
};

export default driverAddByRailyUserDataFormValidationService;
