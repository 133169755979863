import PassengerListResponse, {
  PassengerListResponseItem,
  PassengerListCargoContractMaintenancePolicy,
  PassengerListResponseItemCargoCompany,
  PassengerListResponseItemDispatch,
  PassengerListResponseItemAddress,
} from "../../../api/raily/passenger/list/passenger-list.response";
import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import PassengerList, {
  PassengerListItem,
  PassengerListItemAddress,
  PassengerListItemCargoCompany,
  PassengerListItemDispatch,
} from "./passenger-list";

const createAlternativePhoneNumber = (
  responseDataItem: PassengerListResponseItem
): PassengerListItem["alternativePhoneNumber"] => {
  if (
    !responseDataItem.mobileSecondary ||
    !responseDataItem.mobileSecondaryPrefix
  ) {
    return null;
  }

  return {
    dialingCode: responseDataItem.mobileSecondaryPrefix,
    number: responseDataItem.mobileSecondary,
  };
};

const createContractMaintenancePolicy = (
  contractMaintenancePolicy: PassengerListCargoContractMaintenancePolicy
): ContractMaintenancePolicy => {
  switch (contractMaintenancePolicy) {
    case PassengerListCargoContractMaintenancePolicy.AUCTION:
      return ContractMaintenancePolicy.AUCTION;
    case PassengerListCargoContractMaintenancePolicy.BASIC:
      return ContractMaintenancePolicy.BASIC;
  }
};

const createCargoCompany = (
  cargoCompany: PassengerListResponseItemCargoCompany
): PassengerListItemCargoCompany => {
  return {
    uuid: cargoCompany.id,
    address: cargoCompany.headquartersAddressString,
    companyId: cargoCompany.taxId,
    contractMaintenancePolicy: createContractMaintenancePolicy(
      cargoCompany.contractMaintenancePolicy
    ),
    displayName: cargoCompany.displayName,
    name: cargoCompany.companyName,
    nationalCourtRegister: cargoCompany.krsNo,
    notes: cargoCompany.notes,
    taxId: cargoCompany.taxId,
  };
};

const createDispatch = (
  dispatch: PassengerListResponseItemDispatch
): PassengerListItemDispatch => {
  return {
    uuid: dispatch.id,
    name: dispatch.dispatchName,
    displayName: dispatch.displayName,
    address: dispatch.addressString,
    creationDate: dispatch.createdAt,
    cargoCompany: createCargoCompany(dispatch.cargoCompany),
  };
};

const createAddress = (
  passenger: PassengerListResponseItemAddress
): PassengerListItemAddress => {
  return {
    uuid: passenger.id,
    apartmentNumber: passenger.apartment,
    country: passenger.country,
    description: passenger.description,
    displayName: passenger.displayName,
    houseNumber: passenger.houseNo,
    latitude: passenger.lat,
    longitude: passenger.lon,
    street: passenger.street,
    town: passenger.town,
    zipCode: passenger.zipCode,
  };
};

const createDataItem = (
  responseDataItem: PassengerListResponseItem
): PassengerListItem => {
  return {
    uuid: responseDataItem.id,
    creationDate: responseDataItem.createdAt,
    removalDate: responseDataItem.removedAt,
    addresses: responseDataItem.passenger.addresses.map((address) =>
      createAddress(address)
    ),
    dispatches: responseDataItem.passenger.dispatches.map((dispatch) =>
      createDispatch(dispatch)
    ),
    alternativePhoneNumber: createAlternativePhoneNumber(responseDataItem),
    email: responseDataItem.email,
    firstName: responseDataItem.firstName,
    lastName: responseDataItem.lastName,
    username: responseDataItem.username,
    phoneNumber: {
      dialingCode: responseDataItem.mobilePrimaryPrefix,
      number: responseDataItem.mobilePrimary,
    },
  };
};

const createData = (
  responseData: PassengerListResponseItem[]
): PassengerListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: PassengerListResponse): PassengerList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const passengerListFactory = {
  create,
};

export default passengerListFactory;
