import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import { RouteActiveListingRouteQueryParams } from "../../common/routes/types/route-active-listing-route-params";
import RouteActiveListingFilter from "../common/types/route-active-listing-filter";
import RouteActiveListingSortKey from "../common/types/route-active-listing-sort-key";
import routeActiveListingFilterService from "./route-active-listing-filter.service";

const useRouteActiveListingFilterDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<RouteActiveListingRouteQueryParams>();

  const getFilters = (): RouteActiveListingFilter[] => {
    return routeActiveListingFilterService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return routeActiveListingFilterService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return routeActiveListingFilterService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): RouteActiveListingSortKey | undefined => {
    return routeActiveListingFilterService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: RouteActiveListingFilter[],
    sortKey: RouteActiveListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const newRouteQueryParams =
      routeActiveListingFilterService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(newRouteQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useRouteActiveListingFilterDao;
