import MileageDictionaryListResponse, {
  MileageDictionaryListResponseItem,
} from "../../../api/raily/mileage-dictionary/list/mileage-dictionary-list.response";
import MileageDictionaryList, {
  MileageDictionaryListItem,
} from "./mileage-dictionary-list";

const createDataItem = (
  item: MileageDictionaryListResponseItem
): MileageDictionaryListItem => {
  return {
    active: item.active,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    modificationDate: item.modifiedAt,
    modifiedBy: item.modifiedBy,
    name: item.name,
    uuid: item.id,
  };
};

const createData = (
  data: MileageDictionaryListResponseItem[]
): MileageDictionaryListItem[] => {
  const items: MileageDictionaryListItem[] = data.map(createDataItem);

  return items;
};

const create = (
  response: MileageDictionaryListResponse
): MileageDictionaryList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const mileageDictionaryListFactory = { create };

export default mileageDictionaryListFactory;
