import formValidationService from "../../../../../../../../common/utils/validation/form-validation.service";
import DriverDetailsPlanAddCheckoutFormData from "./types/driver-details-plan-add-checkout-form-data";
import DriverDetailsPlanAddCheckoutFormValidationResults from "./types/driver-details-plan-add-checkout-form-validation-results";

const getDefaultFormData = (): DriverDetailsPlanAddCheckoutFormData => {
  return {
    date: null,
    haltingTimeMinutes: null,
    highwayCost: null,
  };
};

const getDefaultFormValidationResults =
  (): DriverDetailsPlanAddCheckoutFormValidationResults => {
    return {
      date: formValidationService.defaultValidationResult,
      haltingTime: formValidationService.defaultValidationResult,
      highwayCost: formValidationService.defaultValidationResult,
    };
  };

const driverDetailsPlanAddCheckoutFormHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default driverDetailsPlanAddCheckoutFormHelper;
