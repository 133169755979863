import { useEffect, useState } from "react";
import SelectOption from "../../../../../../common/components/form/select/common/option/select-option";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import orderDetailsForwardingApiService from "../../api/forwarding/order-details-forwarding.service";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import taxiContractFactory from "./taxi-corntract.factory";
import SelectOptionTaxiContractComponent from "./types/select-taxi-taxi-contracts";
import TaxiTaxiContractResponse from "./types/taxi-taxi-contracts.response";

type OrderDetailsSelectTaxiContractsComponentProps = {
  taxiCorporationUuid: string;
  isEditEnable?: boolean;
  selectedTaxiContract?: SelectOption | null;
  producerTaxiId: string | null;
  onChange: (value: SelectOption) => void;
};

const OrderDetailsSelectTaxiContractsComponent = (
  props: OrderDetailsSelectTaxiContractsComponentProps
) => {
  const [taxiContractSelectOptions, setTaxiContractSelectOptions] = useState<
    SelectOptionTaxiContractComponent[]
  >([]);
  const [isFetching, setIsFetching] = useState(false);
  const translations =
    userTranslationsHelper.getTaxiOfficerListingTranslations().select;

  useEffect(() => {
    if (!props.taxiCorporationUuid) return;

    fetchContractDetails(props.taxiCorporationUuid!, props.producerTaxiId);
  }, [props.taxiCorporationUuid]);

  const handleContractResponse = (response: TaxiTaxiContractResponse) => {
    if (response.status !== 200) return;

    const taxiCorporationsSelectOptions =
      taxiContractFactory.createTaxiContractsSelectOptions(response.data?.data);
    setTaxiContractSelectOptions(taxiCorporationsSelectOptions);
  };

  const fetchContractDetails = (
    selectedTaxiCorporationId: string,
    producerId: string | null
  ) => {
    setIsFetching(true);

    orderDetailsForwardingApiService
      .fetchTaxiContract(selectedTaxiCorporationId, producerId!)
      .then(handleContractResponse)
      .finally(() => setIsFetching(false));
  };

  return (
    <SingleSelectComponent
      placeholder={translations.selectTaxiCorporationPlaceholder}
      value={props.selectedTaxiContract!}
      onChange={(value) => props.onChange(value!)}
      options={taxiContractSelectOptions}
      isLoading={isFetching}
      isDisabled={props.isEditEnable}
      isSearchable
      menuPlacement="bottom"
      menuPosition="fixed"
    />
  );
};

export default OrderDetailsSelectTaxiContractsComponent;
