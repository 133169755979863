import DelegationAddParams, {
  DelegationAddParamsVehicleType,
} from "../../../../../common/services/delegation/add/delegation-add-params";
import DelegationAddFormData from "../form/types/delegation-add-form-data";
import DelegationAddVehicleType from "../types/delegation-add-vehicle-type";

const createVehicleType = (
  type: DelegationAddVehicleType
): DelegationAddParamsVehicleType => {
  switch (type) {
    case DelegationAddVehicleType.CAR_GT_900CM3:
      return DelegationAddParamsVehicleType.CAR_GT_900CM3;
    case DelegationAddVehicleType.CAR_LE_900CM3:
      return DelegationAddParamsVehicleType.CAR_LE_900CM3;
    case DelegationAddVehicleType.COMPANY_OWNED:
      return DelegationAddParamsVehicleType.COMPANY_OWNED;
    case DelegationAddVehicleType.MOPED:
      return DelegationAddParamsVehicleType.MOPED;
    case DelegationAddVehicleType.MOTORCYCLE:
      return DelegationAddParamsVehicleType.MOTORCYCLE;
  }
};

const createVehicleTypes = (
  types: DelegationAddVehicleType[]
): DelegationAddParamsVehicleType[] => {
  return types.map(createVehicleType);
};

const create = (
  formData: DelegationAddFormData,
  groupVehicleTypes: DelegationAddVehicleType[]
): DelegationAddParams => {
  return {
    workerCompanyUuid: formData.workerCompanyUuid,
    workerUuid: formData.workerUuid,
    dateFrom: formData.dateFrom!,
    dateTo: formData.dateTo!,
    mileagesCost: formData.mileagesCost!,
    totalDistance: formData.totalDistance!,
    addressSequence: formData.addressSequence,
    osrmDistance: formData.osrmDistance ?? formData.totalDistance!,
    accommodationBillsCost: formData.accommodationBillsCost ?? undefined,
    accommodationCost: formData.accommodationCost ?? undefined,
    prepay: formData.prepay ?? undefined,
    startedDayDietCost: formData.startedDayDietCost ?? undefined,
    wholeDayDietCost: formData.wholeDayDietCost ?? undefined,
    localTravelCost: formData.localTravelCost ?? undefined,
    oneDayDietCost: formData.oneDayDietCost ?? undefined,
    otherExpenses: formData.otherExpenses ?? undefined,
    groupVehicleTypes: createVehicleTypes(groupVehicleTypes),
  };
};

const delegationAddParamsFactory = {
  create,
};

export default delegationAddParamsFactory;
