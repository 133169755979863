import UserRole from "../../../types/user-role";
import UserPermissions from "./user-permissions";
import UserPermissionsDefinition from "./user-permissions-definition";

const solvePermission = (
  userRoles: UserRole[],
  requiredUserRoles: UserRole[]
): boolean => {
  return userRoles.some((userRole) => requiredUserRoles.includes(userRole));
};

const solvePermissions = <T extends string>(
  userRoles: UserRole[],
  userPermissionsDefinfition: UserPermissionsDefinition<T>
): UserPermissions<T> => {
  const permissions: Partial<UserPermissions<T>> = {};

  Object.keys(userPermissionsDefinfition).forEach((_key) => {
    const key = _key as T;

    permissions[key] = solvePermission(
      userRoles,
      userPermissionsDefinfition[key]
    );
  });

  return permissions as UserPermissions<T>;
};

const userPermissionsService = {
  solvePermission,
  solvePermissions,
};

export default userPermissionsService;
