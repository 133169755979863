import BillingRouteRequest from "./billing-route.request";
import BillingRouteResponse from "./billing-route.response";
import BillingDataResponse from "./billing-data.response";
import authService from "../../../../auth/common/auth.service";
import appConfig from "../../../../../app.config";
import searchRoadRoutingService from "../../../../../common/utils/search-road-route/search-road-routing.service";

const fetchBillingData = async (
  billingId: string
): Promise<BillingDataResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/${billingId}/taxi-driver`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchRoute = (
  request: BillingRouteRequest
): Promise<BillingRouteResponse> => {
  return searchRoadRoutingService.search(request);
};

const billingTaxiDriverApiService = {
  fetchBillingData,
  fetchRoute,
};

export default billingTaxiDriverApiService;
