import HttpResponse from "../../../../utils/http/http.response";
import railyApiService from "../../raily-api.service";
import CargoCompanyAddRequest, {
  CargoCompanyAddRequestBody,
} from "./add/cargo-company-add-request";
import cargoCompanyAddRequestFactory from "./add/cargo-company-add-request.factory";
import cargoCompanyListRequestFactory from "./list/cargo-company-list-request.factory";
import CargoCompanyListRequest, {
  CargoCompanyListRequestQueryParams,
} from "./list/cargo-company-list.request";
import CargoCompanyListResponse from "./list/cargo-company-list.response";
import CargoCompanyDetailsRequest from "./details/cargo-company-details.request";
import CargoCompanyDetailsResponse from "./details/cargo-company-details.response";

const cargoCompanyApiService = () => {
  const add = (
    request: CargoCompanyAddRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/cargo-companies`;

    const body: CargoCompanyAddRequestBody =
      cargoCompanyAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const getList = (
    request: CargoCompanyListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyListResponse> => {
    const url = `/cargo-companies`;

    const queryParams: CargoCompanyListRequestQueryParams =
      cargoCompanyListRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getDetails = (
    request: CargoCompanyDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyDetailsResponse> => {
    const url = `/cargo-companies/${request.cargoCompanyId}`;

    return railyApiService().get<CargoCompanyDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return {
    add,
    getList,
    getDetails,
  };
};

export default cargoCompanyApiService;
