import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import MileageContractListingTableColumn from "../common/types/mileage-contract-listing-table-column";

const getActivityStatusLabel = (isContractDisabled: boolean): string => {
  const translations =
    mileageTranslationsHelper.getMileageContractListingTranslations();

  return isContractDisabled
    ? translations.table.activityStatus.inactiveLabel
    : translations.table.activityStatus.activeLabel;
};

const getTableColumns = (): MileageContractListingTableColumn[] => {
  const translations =
    mileageTranslationsHelper.getMileageContractListingTranslations().table
      .headers;

  const tableColumns: MileageContractListingTableColumn[] = [
    {
      header: translations.companyNameLabel,
      title: translations.companyNameTitle,
      accessor: "companyName",
      colSpan: 10,
    },
    {
      header: translations.validSinceLabel,
      title: translations.validSinceTitle,
      accessor: "validSince",
      colSpan: 10,
    },
    {
      header: translations.validToLabel,
      title: translations.validToTitle,
      accessor: "validTo",
      colSpan: 10,
    },
    {
      header: translations.distanceRateCompanyVehicleLabel,
      title: translations.distanceRateCompanyVehicleTitle,
      accessor: "distanceRateCompanyVehicle",
      colSpan: 10,
    },
    {
      header: translations.distanceRateCarGt900cm3Label,
      title: translations.distanceRateCarGt900cm3Title,
      accessor: "distanceRateCarGt900cm3",
      colSpan: 10,
    },
    {
      header: translations.distanceRateCarLe900cm3Label,
      title: translations.distanceRateCarLe900cm3Title,
      accessor: "distanceRateCarLe900cm3",
      colSpan: 10,
    },
    {
      header: translations.distanceRateMotorcycleLabel,
      title: translations.distanceRateMotorcycleTitle,
      accessor: "distanceRateMotorcycle",
      colSpan: 10,
    },
    {
      header: translations.distanceRateMopedLabel,
      title: translations.distanceRateMopedTitle,
      accessor: "distanceRateMoped",
      colSpan: 10,
    },
    {
      header: translations.dietFullRateLabel,
      title: translations.dietFullRateTitle,
      accessor: "dietFullRate",
      colSpan: 10,
    },
    {
      header: translations.dietFullAfterHoursLabel,
      title: translations.dietFullAfterHoursTitle,
      accessor: "dietFullAfterHours",
      colSpan: 10,
    },
    {
      header: translations.dietHalfRateLabel,
      title: translations.dietHalfRateTitle,
      accessor: "dietHalfRate",
      colSpan: 10,
    },
    {
      header: translations.dietHalfAfterHoursLabel,
      title: translations.dietHalfAfterHoursTitle,
      accessor: "dietHalfAfterHours",
      colSpan: 10,
    },
    {
      header: translations.activityStatusLabel,
      title: translations.activityStatusTitle,
      accessor: "activityStatus",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];

  return tableColumns;
};

const getDistanceRateCompanyVehicleLabel = (
  distanceRateCompanyVehicle: number
) => {
  return `${String(distanceRateCompanyVehicle.toFixed(2))} PLN/km`;
};

const getDistanceRateCompanyVehicleTitle = (
  distanceRateCompanyVehicle: number
) => {
  return `${String(distanceRateCompanyVehicle.toFixed(2))} PLN/km`;
};

const getDistanceRateCarGt900cm3Label = (distanceRateCarGt900cm3: number) => {
  return `${String(distanceRateCarGt900cm3.toFixed(2))} PLN/km`;
};

const getDistanceRateCarGt900cm3Title = (distanceRateCarGt900cm3: number) => {
  return `${String(distanceRateCarGt900cm3.toFixed(2))} PLN/km`;
};

const getDistanceRateCarLe900cm3Label = (distanceRateCarLe900cm3: number) => {
  return `${String(distanceRateCarLe900cm3.toFixed(2))} PLN/km`;
};

const getDistanceRateCarLe900cm3Title = (distanceRateCarLe900cm3: number) => {
  return `${String(distanceRateCarLe900cm3.toFixed(2))} PLN/km`;
};

const getDistanceRateMotorcycleLabel = (distanceRateMotorcycle: number) => {
  return `${String(distanceRateMotorcycle.toFixed(2))} PLN/km`;
};

const getDistanceRateMotorcycleTitle = (distanceRateMotorcycle: number) => {
  return `${String(distanceRateMotorcycle.toFixed(2))} PLN/km`;
};

const getDistanceRateMopedLabel = (distanceRateMoped: number) => {
  return `${String(distanceRateMoped.toFixed(2))} PLN/km`;
};

const getDistanceRateMopedTitle = (distanceRateMoped: number) => {
  return `${String(distanceRateMoped.toFixed(2))} PLN/km`;
};

const getDietFullRateLabel = (dietFullRate: number) => {
  return `${String(dietFullRate.toFixed(2))} PLN`;
};

const getDietFullRateTitle = (dietFullRate: number) => {
  return `${String(dietFullRate.toFixed(2))} PLN`;
};

const getDietHalfRateLabel = (dietHalfRate: number) => {
  return `${String(dietHalfRate.toFixed(2))} PLN`;
};

const getDietHalfRateTitle = (dietHalfRate: number) => {
  return `${String(dietHalfRate.toFixed(2))} PLN`;
};

const getDietFullAfterHoursLabel = (dietFullAfterHours: number) => {
  return `${String(dietFullAfterHours)} h`;
};

const getDietFullAfterHoursTitle = (dietFullAfterHours: number) => {
  return `${String(dietFullAfterHours)} h`;
};

const getDietHalfAfterHoursLabel = (dietHalfAfterHours: number) => {
  return `${String(dietHalfAfterHours)} h`;
};

const getDietHalfAfterHoursTitle = (dietHalfAfterHours: number) => {
  return `${String(dietHalfAfterHours)} h`;
};

const mileageContractsListingHelper = {
  getActivityStatusLabel,
  getTableColumns,
  getDistanceRateCompanyVehicleLabel,
  getDistanceRateCompanyVehicleTitle,
  getDistanceRateCarGt900cm3Label,
  getDistanceRateCarGt900cm3Title,
  getDistanceRateCarLe900cm3Label,
  getDistanceRateCarLe900cm3Title,
  getDistanceRateMotorcycleLabel,
  getDistanceRateMotorcycleTitle,
  getDistanceRateMopedLabel,
  getDistanceRateMopedTitle,
  getDietFullRateLabel,
  getDietFullRateTitle,
  getDietHalfRateLabel,
  getDietHalfRateTitle,
  getDietFullAfterHoursLabel,
  getDietFullAfterHoursTitle,
  getDietHalfAfterHoursLabel,
  getDietHalfAfterHoursTitle,
};

export default mileageContractsListingHelper;
