import LoaderComponent from "../../../../../common/components/loader/loader.component";
import NoticeBoxType from "../../../../../common/components/notice-box/notice-box-type";
import NoticeBoxComponent from "../../../../../common/components/notice-box/notice-box.component";
import PlanningOrderDriverPlanListingSelectedSuccessNotificationComponent from "../notification/planning-order-driver-plan-listing-selected-success-notification.component";
import PlanningOrderDriverPlanListingSelectedFailureNotificationComponent from "../notification/planning-order-driver-plan-listing-slected-failure-notification.component";

export const LoaderContent = () => (
  <div className="planning-order-driver-plan-listing-selected__loader">
    <LoaderComponent type="primary" />
  </div>
);

export const OverfloatedLoaderContent = () => (
  <div className="planning-order-driver-plan-listing-selected__notification">
    <LoaderComponent type="primary" />
  </div>
);

export const ErrorContent = () => (
  <NoticeBoxComponent type={NoticeBoxType.DANGER}>Error</NoticeBoxComponent>
);

export const CandidatureAddSucess = () => (
  <PlanningOrderDriverPlanListingSelectedSuccessNotificationComponent />
);

export const CandidatureAddFailure = ({ message }: { message: string }) => (
  <PlanningOrderDriverPlanListingSelectedFailureNotificationComponent
    text={message}
  />
);
