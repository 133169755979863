import HttpResponse from "../../../../../../../../../common/utils/http/http.response";

export enum DriverDetailsPlanAddCheckoutErrorType {
  DRIVER_HAS_CANDIDATURE = "DRIVER_HAS_CANDIDATURE",
}

export type DriverDetailsPlanAddCheckoutDriverHasCandidatureError = {
  error_type: DriverDetailsPlanAddCheckoutErrorType.DRIVER_HAS_CANDIDATURE;
  candidature_id: string;
};

export type DriverDetailsPlanAddCheckoutDriverNotAcceptableResponseError =
  DriverDetailsPlanAddCheckoutDriverHasCandidatureError;

type DriverDetailsPlanAddCheckoutErrorResponse =
  HttpResponse<DriverDetailsPlanAddCheckoutDriverNotAcceptableResponseError>;

export default DriverDetailsPlanAddCheckoutErrorResponse;
