import SignUpPassengerRequest, {
  SignUpPassengerRequestAddress,
} from "./api/sign-up-passenger.request";
import SignUpPassengerFormAccountData from "./types/sign-up-passenger-form-account-data";
import SignUpPassengerAddress from "./types/sign-up-passenger-address";
import SignUpPassengerFormUserData from "./types/sign-up-passenger-form-user-data";

const createSignUpRequestAddresses = (
  userDataAddresses: SignUpPassengerAddress[]
): SignUpPassengerRequestAddress[] => {
  return userDataAddresses.map(createSignUpRequestAddress);
};

const createSignUpRequestAddress = (
  userDataAddress: SignUpPassengerAddress
): SignUpPassengerRequestAddress => {
  const requestAddress: SignUpPassengerRequestAddress = {
    apartment: userDataAddress.apartmentNumber.trim(),
    country: userDataAddress.country.trim(),
    description: userDataAddress.description.trim(),
    house_no: userDataAddress.houseNumber.trim(),
    lat: userDataAddress.latitude,
    lon: userDataAddress.longitude,
    street: userDataAddress.street.trim(),
    town: userDataAddress.town.trim(),
    zip_code: userDataAddress.zipCode.trim(),
  };

  return requestAddress;
};

const createSignUpRequest = ({
  accountData,
  userData,
  dispatchId,
}: {
  accountData: SignUpPassengerFormAccountData;
  userData: SignUpPassengerFormUserData;
  dispatchId: string;
}): SignUpPassengerRequest => {
  const request: SignUpPassengerRequest = {
    first_name: userData.firstName.trim(),
    last_name: userData.lastName.trim(),
    mobile_primary_prefix: userData.mobile.dialingCode!,
    mobile_primary: userData.mobile.number!,
    mobile_secondary_prefix: userData.alternativeMobile.dialingCode,
    mobile_secondary: userData.alternativeMobile.number,
    email: accountData.email.trim(),
    username: accountData.username.trim(),
    password: accountData.password,
    passenger: {
      addresses: createSignUpRequestAddresses(userData.addresses),
      dispatch_ids: [dispatchId],
    },
  };

  return request;
};

const signUpPassengerFormFactory = {
  createSignUpRequest,
  createSignUpRequestAddress,
};

export default signUpPassengerFormFactory;
