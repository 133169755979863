import appConfig from "../../../../../../app.config";
import urlService from "../../../../../../common/utils/url/url.service";
import authService from "../../../../../auth/common/auth.service";
import TaxiUnsettledPrivateOrderListingTaxiCoporationsResponse from "./taxi-unsettled-private-order-listing-taxi-corporations.response";
import TaxiUnsettledPrivateOrderListingRequest from "./taxi-unsettled-private-order-listing.request";
import TaxiUnsettledPrivateOrderListingResponse from "./taxi-unsettled-private-order-listing.response";

const fetchTaxiCorporations =
  (): Promise<TaxiUnsettledPrivateOrderListingTaxiCoporationsResponse> => {
    const path = `/taxi-corporations`;
    const url = `${appConfig.baseApiUrl}${path}`;

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
    };

    return fetch(url, init).then(async (r) => {
      const responseData = await r.json();
      return {
        status: r.status,
        data: responseData,
      };
    });
  };

const fetchListing = async (
  taxiCorporationUuid: string,
  request: TaxiUnsettledPrivateOrderListingRequest
): Promise<TaxiUnsettledPrivateOrderListingResponse> => {
  const path = `${appConfig.baseApiUrl}/taxi-corporations/${taxiCorporationUuid}/orders/not-yet-billed`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const taxiUnsettledPrivateOrderListingApiService = {
  fetchListing,
  fetchTaxiCorporations,
};

export default taxiUnsettledPrivateOrderListingApiService;
