import { FC } from "react";
import {
  PlannerDriverPlanListItemTargerPlanEntryData,
  PlannerDriverPlanListItemUser,
} from "../../../../../../common/services/planner/list/planner-driver-plan-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { PlanningOrderSelectedItemData } from "../../../common/types/planning-order-selected-data";
import planningTranslationsHelper from "../../../../../../languages/planning-translations.helper";
import phoneNumberService from "../../../../../../common/utils/phone-number/phone-number.service";
import userHelper from "../../../../../../common/utils/user/user.helper";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import planningOrderDriverPlanListingFactory from "../../common/planning-order-driver-plan-listing.factory";
import planningOrderDriverListingItemHelper from "./planning-order-driver-plan-listing-item.factory";

type PlanningOrderDriverPlanListingItemDetailsProps = {
  user: PlannerDriverPlanListItemUser;
  planEntries: PlannerDriverPlanListItemTargerPlanEntryData;
  taxiDriverAssociationUuid: string | null;
  onClick: (item: PlanningOrderSelectedItemData) => void;
  id: number;
};

const PlanningOrderDriverPlanListingItemDetailsComponent: FC<
  PlanningOrderDriverPlanListingItemDetailsProps
> = (props) => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing.details;

  const handleOnClick = () => {
    const data = planningOrderDriverPlanListingFactory.createSelectedItemData(
      props.id,
      props.user,
      props.planEntries,
      props.taxiDriverAssociationUuid
    );

    props.onClick(data);
  };

  const contractTypeLabel =
    planningOrderDriverListingItemHelper.getFormOfEmploymentLabel(
      props.user.taxiDriverContractType
    );

  return (
    <div className="planning_order_driver_plan_item_details">
      <div className="planning_order_driver_plan_item_details__user">
        <div className="planning_order_driver_plan_item_details__heading">
          <span>{`${props.user.firstName} ${props.user.lastName}`}</span>
          <ButtonComponent onClick={handleOnClick}>
            <FontAwesomeIcon
              className="planning_order_driver_plan_item_details__heading-icon"
              title={translations.addToDriverPlanButtonLabel}
              icon={faChevronRight}
              size="1x"
            />
          </ButtonComponent>
        </div>
        <div className="planning_order_driver_plan_item_details__row">
          <strong>{translations.phoneNumbersLabel}</strong>
          <div>{phoneNumberService.getLabel(props.user.phoneNumber)}</div>
          {props.user.alternativePhoneNumber && (
            <div>
              {phoneNumberService.getLabel(props.user.alternativePhoneNumber)}
            </div>
          )}
        </div>
        <div className="planning_order_driver_plan_item_details__row">
          <strong>{translations.employmentLabel}</strong>
          <div>{`${props.user.taxiName} (${contractTypeLabel})`}</div>
        </div>
        <div className="planning_order_driver_plan_item_details__row">
          <strong>{translations.startingAddressLabel}</strong>
          <div>{props.user.startingAddress}</div>
        </div>
        <div className="planning_order_driver_plan_item_details__row">
          <strong>{translations.vehicleLabel}</strong>
          <div>
            {userHelper.getVehicleOwnershipText(props.user.carOwnership)}
          </div>
        </div>
        <div className="planning_order_driver_plan_item_details__row">
          <strong>{translations.distanceRateLabel}</strong>
          <div>
            {translations.distanceRateValueLabel.replace(
              "#{rate}",
              String(props.user.distanceRate)
            )}
          </div>
        </div>
      </div>
      <div className="planning_order_driver_plan_item_details__route">
        {props.planEntries.planEntryUuid ? (
          <span>
            {translations.routeTemplateLabel.replace(
              "#{routeId}",
              String(props.planEntries.planEntryUuid)
            )}
          </span>
        ) : (
          <span>{translations.newRouteLabel}</span>
        )}
      </div>
    </div>
  );
};

export default PlanningOrderDriverPlanListingItemDetailsComponent;
