import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import OrderAbandonedListingTableColumn from "../types/order-abandoned-listing-table-column";
import OrderAbandonedListingFilterType from "../types/order-abandoned-listing-filter-type";
import OrderAbandonedListingFilter from "../types/order-abandoned-listing-filter";
import OrderAbandonedListingSortKey from "../types/order-abandoned-listing-sort-key";
import OrderAbandonedListingSortApiKey from "../types/order-abandoned-listing-sort-api-keys";
import OrderAbandonedListingPublicStatus from "../types/order-abandoned-listing-public-status";
import OrderAbandonedListingPublicStatusOption from "../types/order-abandoned-listing-public-status-option";
import {
  OrderAbandonedListingFilterRequest,
  OrderAbandonedListingFilterRequestType,
} from "../api/order-abandoned-listing-filters.request";

const getTableColumns = (): OrderAbandonedListingTableColumn[] => {
  const translations =
    orderTranslationsHelper.getAbandonedListingTranslations().table.headers;

  const tableColumns: OrderAbandonedListingTableColumn[] = [
    {
      header: translations.orderDateLabel,
      title: translations.orderDateTitle,
      accessor: "date",
      colSpan: 10,
    },
    {
      header: translations.cargoCompanyInternalOrderIdLabel,
      title: translations.cargoCompanyInternalOrderIdTitle,
      accessor: "cargoCompanyOrderId",
      colSpan: 8,
    },
    {
      header: translations.cargoCompanyExternalOrderIdLabel,
      title: translations.cargoCompanyExternalOrderIdTitle,
      accessor: "cargoCompanyExternalOrderId",
      colSpan: 8,
    },
    {
      header: translations.routeLabel,
      title: translations.routeTitle,
      accessor: "route",
      colSpan: 35,
    },
    {
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      accessor: "passenger",
      colSpan: 10,
    },
    {
      header: translations.dispatchLabel,
      title: translations.dispatchTitle,
      accessor: "dispatch",
      colSpan: 8,
    },
    {
      header: translations.clientLabel,
      title: translations.clientTitle,
      accessor: "client",
      colSpan: 8,
    },
  ];

  return tableColumns;
};

const getPublicStatusOptions =
  (): OrderAbandonedListingPublicStatusOption[] => {
    const translations =
      orderTranslationsHelper.getAbandonedListingTranslations().publicStatuses;

    return [
      {
        label: translations.private,
        status: OrderAbandonedListingPublicStatus.PRIVATE,
      },
      {
        label: translations.public,
        status: OrderAbandonedListingPublicStatus.PUBLIC,
      },
      {
        label: translations.published,
        status: OrderAbandonedListingPublicStatus.PUBLISHED,
      },
    ];
  };

const getApiFilterSelectKeys = (filterKey: OrderAbandonedListingFilterType) => {
  switch (filterKey) {
    case OrderAbandonedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID:
      return OrderAbandonedListingFilterRequest.EXTERNAL_ID;
    case OrderAbandonedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID:
      return OrderAbandonedListingFilterRequest.HUMAN_ID;
    case OrderAbandonedListingFilterType.ROUTE_ADDRESS:
      return OrderAbandonedListingFilterRequest.ADDRESS;
    case OrderAbandonedListingFilterType.ROUTE_PICKUP_ADDRESS:
      return OrderAbandonedListingFilterRequest.START_ADDRESS;
    case OrderAbandonedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS:
      return OrderAbandonedListingFilterRequest.MID_ADDRESS;
    case OrderAbandonedListingFilterType.ROUTE_DESTINATION_ADDRESS:
      return OrderAbandonedListingFilterRequest.END_ADDRESS;
    case OrderAbandonedListingFilterType.CLIENT:
      return OrderAbandonedListingFilterRequest.CLIENT;
    case OrderAbandonedListingFilterType.DISPATCH:
      return OrderAbandonedListingFilterRequest.DISPATCH;
    case OrderAbandonedListingFilterType.ORDER_START_FROM:
      return OrderAbandonedListingFilterRequest.START_TIME_SINCE;
    case OrderAbandonedListingFilterType.ORDER_START_TO:
      return OrderAbandonedListingFilterRequest.START_TIME_TO;
    default:
      return OrderAbandonedListingFilterRequest.EXTERNAL_ID;
  }
};

const getRequestOrderValue = (
  selectedSortKey: OrderAbandonedListingSortKey | null
) => {
  switch (selectedSortKey) {
    case OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC:
      return OrderAbandonedListingSortApiKey.HUMAN_ID_ASC;
    case OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_DESC:
      return OrderAbandonedListingSortApiKey.HUMAN_ID_DESC;
    case OrderAbandonedListingSortKey.CREATED_ASC:
      return OrderAbandonedListingSortApiKey.CREATED_AT_ASC;
    case OrderAbandonedListingSortKey.CREATED_DESC:
      return OrderAbandonedListingSortApiKey.CREATED_AT_DESC;
    case OrderAbandonedListingSortKey.MODIFIED_ASC:
      return OrderAbandonedListingSortApiKey.MODIFIED_AT_ASC;
    case OrderAbandonedListingSortKey.MODIFIED_DESC:
      return OrderAbandonedListingSortApiKey.MODIFIED_AT_DESC;
    default:
      return OrderAbandonedListingSortApiKey.CREATED_AT_DESC;
  }
};

const getRequestFiltersList = (
  orderAbandonedListingFilters: OrderAbandonedListingFilter[]
) =>
  orderAbandonedListingFilters.reduce<OrderAbandonedListingFilterRequestType>(
    (filtersList, filter) => {
      return {
        ...filtersList,
        [getApiFilterSelectKeys(filter.type)]: filter.value,
      };
    },
    {}
  );

const orderAbandonedListingHelper = {
  getTableColumns,
  getPublicStatusOptions,
  getRequestFiltersList,
  getRequestOrderValue,
};

export default orderAbandonedListingHelper;
