import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryDoNothingChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";

type OrderDetailsHistoryEntryChangeRideContentDoNothingProps = {
  event: OrderDetailsHistoryEntryDoNothingChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentDoNothingComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentDoNothingProps
> = () => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .doNothing;

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentDoNothingComponent;
