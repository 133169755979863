import { RouteFinishedListingRouteQueryParams } from "../../common/routes/types/route-finished-listing-route-params";
import RouteFinishedListingFilter, {
  RouteFinishedListingCargoOrderHumanIdFilter,
  RouteFinishedListingDriverFilter,
  RouteFinishedListingPassengerFilter,
  RouteFinishedListingRouteAddressFilter,
  RouteFinishedListingHumanIdFilter,
  RouteFinishedListingTaxiCorporationFilter,
} from "../common/types/route-finished-listing-filter";
import RouteFinishedListingFilterType from "../common/types/route-finished-listing-filter-type";
import RouteFinishedListingSortKey from "../common/types/route-finished-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: RouteFinishedListingRouteQueryParams
): RouteFinishedListingFilter[] => {
  const filters: RouteFinishedListingFilter[] = [];

  if (routeQueryFilterParams.cargoOrderHumanId) {
    const cargoOrderHumanIdFilter: RouteFinishedListingCargoOrderHumanIdFilter =
      {
        type: RouteFinishedListingFilterType.CARGO_ORDER_HUMAN_ID,
        value: Number(routeQueryFilterParams.cargoOrderHumanId),
      };

    filters.push(cargoOrderHumanIdFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: RouteFinishedListingDriverFilter = {
      type: RouteFinishedListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: RouteFinishedListingPassengerFilter = {
      type: RouteFinishedListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: RouteFinishedListingRouteAddressFilter = {
      type: RouteFinishedListingFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.humanId) {
    const humanIdFilter: RouteFinishedListingHumanIdFilter = {
      type: RouteFinishedListingFilterType.HUMAN_ID,
      value: Number(routeQueryFilterParams.humanId),
    };

    filters.push(humanIdFilter);
  }

  if (routeQueryFilterParams.taxiCorporation) {
    const taxiCorporationFilter: RouteFinishedListingTaxiCorporationFilter = {
      type: RouteFinishedListingFilterType.TAXI_CORPORATION,
      value: routeQueryFilterParams.taxiCorporation,
    };

    filters.push(taxiCorporationFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: RouteFinishedListingRouteQueryParams
): RouteFinishedListingSortKey | undefined => {
  return routeQueryParams.sort as RouteFinishedListingSortKey | undefined;
};

const createRouteQueryParams = (
  filters: RouteFinishedListingFilter[],
  sortKey: RouteFinishedListingSortKey | null,
  page: number,
  pageSize: number
): RouteFinishedListingRouteQueryParams => {
  const cargoOrderHumanIdFilterValue = filters.find(
    (filter) =>
      filter.type === RouteFinishedListingFilterType.CARGO_ORDER_HUMAN_ID
  )?.value as RouteFinishedListingCargoOrderHumanIdFilter["value"] | undefined;
  const humanIdFilterValue = filters.find(
    (filter) => filter.type === RouteFinishedListingFilterType.HUMAN_ID
  )?.value as RouteFinishedListingHumanIdFilter["value"] | undefined;

  return {
    page: String(page),
    pageSize: String(pageSize),
    sort: sortKey ?? undefined,
    cargoOrderHumanId: cargoOrderHumanIdFilterValue
      ? String(cargoOrderHumanIdFilterValue)
      : undefined,
    driver: filters.find(
      (filter) => filter.type === RouteFinishedListingFilterType.DRIVER
    )?.value as RouteFinishedListingDriverFilter["value"] | undefined,
    passenger: filters.find(
      (filter) => filter.type === RouteFinishedListingFilterType.PASSENGER
    )?.value as RouteFinishedListingPassengerFilter["value"] | undefined,
    routeAddress: filters.find(
      (filter) => filter.type === RouteFinishedListingFilterType.ROUTE_ADDRESS
    )?.value as RouteFinishedListingRouteAddressFilter["value"] | undefined,
    humanId: humanIdFilterValue ? String(humanIdFilterValue) : undefined,
    taxiCorporation: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingFilterType.TAXI_CORPORATION
    )?.value as RouteFinishedListingTaxiCorporationFilter["value"] | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: RouteFinishedListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: RouteFinishedListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const routeFinishedListingFilterService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default routeFinishedListingFilterService;
