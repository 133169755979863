import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryCancelOrderEvent } from "../../../common/types/order-details-history-entry-event";

type OrderDetailsHistoryEntryCancelOrderContentProps = {
  event: OrderDetailsHistoryEntryCancelOrderEvent;
};

const OrderDetailsHistoryEntryCancelOrderContentComponent: FC<
  OrderDetailsHistoryEntryCancelOrderContentProps
> = () => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().cancelOrderEntry;

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingText}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryCancelOrderContentComponent;
