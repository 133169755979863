enum TaxiOfficerListingSortKey {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
  EMAIL_ADDRESS_ASC = "EMAIL_ADDRESS_ASC",
  EMAIL_ADDRESS_DESC = "EMAIL_ADDRESS_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
}

export default TaxiOfficerListingSortKey;
