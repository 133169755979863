import { ChartDataItemGroupPoint } from "./types/chart-data";
import chartHelper from "./chart.helper";
import ChartLabelComponent from "./chart-label.component";
import { FC } from "react";

export type ChartTopLabelProps = {
  tracks: number[];
  groupedPoints: any;
  fontSize?: number;
  scale?: { x: () => void; y: () => void };
  topLabelGroupName?: string;
};

const ChartTopLabelComponent: FC<ChartTopLabelProps> = (props) => {
  const topLabels = props.tracks.map((item: number) => {
    const points: ChartDataItemGroupPoint[] = props.groupedPoints[item];

    const minDate = chartHelper.getMinValue(points);
    const maxDate = chartHelper.getMaxValue(points);
    const yAxisValue = chartHelper.calculateMiddlePoint(minDate, maxDate);

    return (
      <ChartLabelComponent
        key={yAxisValue}
        text={`${props.topLabelGroupName} ${item.toString()}`}
        x={39}
        y={new Date(yAxisValue)}
        fontSize={props.fontSize}
        scale={props.scale}
        charactersLimit={20}
      />
    );
  });

  return <>{topLabels}</>;
};

export default ChartTopLabelComponent;
