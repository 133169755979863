import OrderAddPassenger from "../../../types/order-add-passenger";

const getPassengerButtonLabel = (passenger: OrderAddPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const getPassengerButtonTitle = (passenger: OrderAddPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const getPassengerIconTitle = (passenger: OrderAddPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const orderAddRouteWaypointHelper = {
  getPassengerButtonLabel,
  getPassengerButtonTitle,
  getPassengerIconTitle,
};

export default orderAddRouteWaypointHelper;
