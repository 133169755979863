import BillingsPlanEntryTaxiTaxiRequest from "../../../../api/raily/billings/plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi.request";
import BillingsPlanEntryTaxiTaxiLoadParams from "./billings-plan-entry-taxi-taxi-load-params";

const create = (
  params: BillingsPlanEntryTaxiTaxiLoadParams
): BillingsPlanEntryTaxiTaxiRequest => {
  return {
    planEntryId: params.planEntryUuid,
  };
};

const billingsPlanEntryTaxiTaxiRequestFactory = {
  create,
};

export default billingsPlanEntryTaxiTaxiRequestFactory;
