import CargoOfficerListResponse, {
  CargoOfficerListResponseItem,
  CargoOfficerListResponseItemContractMaintenancePolicy,
  CargoOfficerListResponseItemTaxiCorporation,
} from "../../../api/raily/cargo-officer/list/cargo-officer-list.response";
import CargoOfficerList, {
  CargoContractMaintenancePolicy,
  CargoOfficerListItem,
  CargoOfficerListItemTaxiCorporation,
} from "./cargo-officer-list";

const createAlternativePhoneNumber = (
  responseDataItem: CargoOfficerListResponseItem
): CargoOfficerListItem["alternativePhoneNumber"] => {
  if (
    !responseDataItem.mobileSecondary ||
    !responseDataItem.mobileSecondaryPrefix
  ) {
    return null;
  }

  return {
    dialingCode: responseDataItem.mobileSecondaryPrefix,
    number: responseDataItem.mobileSecondary,
  };
};

const createContractMaintenancePolicy = (
  responseContractMaintenancePolicy: CargoOfficerListResponseItemContractMaintenancePolicy
): CargoContractMaintenancePolicy => {
  switch (responseContractMaintenancePolicy) {
    case CargoOfficerListResponseItemContractMaintenancePolicy.AUCTION:
      return CargoContractMaintenancePolicy.AUCTION;
    case CargoOfficerListResponseItemContractMaintenancePolicy.BASIC:
      return CargoContractMaintenancePolicy.BASIC;
  }
};

const createTaxiCorporation = (
  responseTaxiCorporation: CargoOfficerListResponseItemTaxiCorporation
): CargoOfficerListItemTaxiCorporation => {
  return {
    uuid: responseTaxiCorporation.id,
    companyId: responseTaxiCorporation.regon,
    contractMaintenancePolicy: createContractMaintenancePolicy(
      responseTaxiCorporation.contractMaintenancePolicy
    ),
    address: responseTaxiCorporation.headquartersAddressString,
    displayName: responseTaxiCorporation.displayName,
    name: responseTaxiCorporation.companyName,
    nationalCourtRegister: responseTaxiCorporation.krsNo,
    notes: responseTaxiCorporation.notes,
    taxId: responseTaxiCorporation.taxId,
  };
};

const createDataItem = (
  responseDataItem: CargoOfficerListResponseItem
): CargoOfficerListItem => {
  return {
    uuid: responseDataItem.id,
    removalDate: responseDataItem.removedAt,
    alternativePhoneNumber: createAlternativePhoneNumber(responseDataItem),
    cargoCompany: createTaxiCorporation(
      responseDataItem.cargoOfficer.cargoCompany!
    ),
    email: responseDataItem.email,
    firstName: responseDataItem.firstName,
    lastName: responseDataItem.lastName,
    username: responseDataItem.username,
    phoneNumber: {
      dialingCode: responseDataItem.mobilePrimaryPrefix,
      number: responseDataItem.mobilePrimary,
    },
  };
};

const createData = (
  responseData: CargoOfficerListResponseItem[]
): CargoOfficerListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: CargoOfficerListResponse): CargoOfficerList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const taxiOfficerListFactory = {
  create,
};

export default taxiOfficerListFactory;
