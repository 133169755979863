import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import MileageContractListingFilter, {
  MileageContractListingCargoCompanyFilter,
} from "../common/types/mileage-contract-listing-filter";
import MileageContractListingFilterType from "../common/types/mileage-contract-listing-filter-type";
import MileageContractListingSortKey from "../common/types/mileage-contract-listing-sort-key";

const mileageContractListingFilterHelper = () => {
  const translations =
    mileageTranslationsHelper.getMileageContractListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.cargoCompanyAscOptionLabel,
        value: MileageContractListingSortKey.COMPANY_NAME_ASC,
      },
      {
        label: translations.sort.cargoCompanyDescOptionLabel,
        value: MileageContractListingSortKey.COMPANY_NAME_DESC,
      },
      {
        label: translations.sort.validSinceAscOptionLabel,
        value: MileageContractListingSortKey.VALID_SINCE_ASC,
      },
      {
        label: translations.sort.validSinceDescOptionLabel,
        value: MileageContractListingSortKey.VALID_SINCE_DESC,
      },
      {
        label: translations.sort.validToAscOptionLabel,
        value: MileageContractListingSortKey.VALID_TO_ASC,
      },
      {
        label: translations.sort.validToDescOptionLabel,
        value: MileageContractListingSortKey.VALID_TO_DESC,
      },
    ];
  };

  const getSelectDataForCargoCompany = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.searchByCargoCompanyTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: MileageContractListingFilterType,
    query: string
  ) => {
    const options: Record<
      MileageContractListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [MileageContractListingFilterType.CARGO_COMPANY]:
        getSelectDataForCargoCompany,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeForCargoCompany = (
    filterValue: MileageContractListingCargoCompanyFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      String(filterValue),
      translations.filters.cargoCompanyFilterLabel,
      translations.filters.cargoCompanyFilterTitle
    );
  };

  const getBadgeDefinitionByType = (
    filterType: MileageContractListingFilterType,
    filterValue: MileageContractListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      MileageContractListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [MileageContractListingFilterType.CARGO_COMPANY]: () =>
        getBadgeForCargoCompany(
          filterValue as MileageContractListingCargoCompanyFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: MileageContractListingFilterType
  ): ListingFilterDefinitionOption<MileageContractListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<MileageContractListingFilter>[] => {
      return Object.values(MileageContractListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<MileageContractListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default mileageContractListingFilterHelper;
