import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../../../context/app.context";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import cargoBreadcrumbsHelper from "../../../common/breadcrumbs/cargo-breadcrumbs.helper";
import useCargoCompanyDispatchList from "../../../../../common/services/cargo-company/dispatch/list/use-cargo-company-dispatch-list";
import useAbort from "../../../../../common/hooks/use-abort";
import cargoDispatchListingHelper from "./cargo-dispatch-listing.helper";
import CargoDispatchListingTableRow from "./types/cargo-dispatch-listing-table-row";
import TableComponent from "../../../../../common/components/table/table.component";
import CardComponent from "../../../../../common/components/card/card.component";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";
import cargoRoutesHelper from "../../../common/routes/cargo-routes.helper";
import CargoDispatchRouteParams from "../../../common/routes/types/cargo-dispatch-route-params";
import ListingPaginationComponent from "../../../../../common/components/listing/pagination/listing-pagination.component";
import usePagination from "../../../../../common/hooks/use-pagination";
import useCargoDispatchListingDao from "./use-cargo-dispatch-listing.dao";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useCargoCompanyDetails from "../../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";

type CargoDispatchListingProps = {};

const CargoDispatchListingComponent: FC<CargoDispatchListingProps> = () => {
  const { cargoCompanyUuid } = useParams<CargoDispatchRouteParams>();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [cargoCompanyName, setCargoCompanyName] = useState("");

  const isComponentMounted = useIsComponentMounted();
  const cargoCompanyDispatchList = useCargoCompanyDispatchList();
  const cargoCompanyDispatchListAbort = useAbort();

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();

  const listingDao = useCargoDispatchListingDao();
  const dispatchList = useCargoCompanyDispatchList();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: dispatchList.data.totalCount,
    defaultPageSize: listingDao.getPageSize(),
    defaultPage: listingDao.getPage(),
  });

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    loadListingItems();
    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => {
      cargoCompanyAbort.revoke();
    };
  }, [cargoCompanyUuid]);

  useEffect(() => {
    if (!cargoCompany.data) return;

    setCargoCompanyName(cargoCompany.data.displayName);
  }, [cargoCompany.data]);

  useEffect(() => {
    const breadcrumbs = cargoBreadcrumbsHelper.getDispatchListingBreadcrumbs({
      cargoCompanyName,
      cargoCompanyUuid: cargoCompanyUuid!,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoCompanyName]);

  useEffect(() => {
    if (!isComponentMounted) return;

    listingDao.saveParams(page, pageSize);
  }, [page, pageSize]);

  const loadListingItems = async () => {
    if (!cargoCompanyUuid) return;

    const params = { page, pageSize, cargoCompanyUuid };

    cargoCompanyDispatchList.load(params, cargoCompanyDispatchListAbort.signal);
  };

  useEffect(() => {
    loadListingItems();

    return () => cargoCompanyDispatchListAbort.revoke();
  }, [page, pageSize, cargoCompanyUuid]);

  const translations = cargoTranslationsHelper.getDispatchTranslations().header;

  const tableColumns = cargoDispatchListingHelper.getTableColumns();
  const tableRows: CargoDispatchListingTableRow[] = useMemo(() => {
    return cargoCompanyDispatchList.data?.data?.map((item) =>
      cargoDispatchListingHelper.getTableRow(item, cargoCompanyUuid!)
    );
  }, [cargoCompanyDispatchList.data]);

  const AddNewDispatchLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={cargoRoutesHelper.getDispatchAddRoute({
          cargoCompanyUuid: cargoCompanyUuid!,
        })}
        title={translations.addDispatchLinkButtonTitle}
        idForTesting="carg-dispatch-listing-add-button"
      >
        {translations.addDispatchLinkButtonLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  return (
    <div className="cargo_taxi_contract_listing">
      <HeadingComponent
        title={translations.headingText}
        actions={[AddNewDispatchLinkButton]}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TableComponent
          columns={tableColumns}
          rows={tableRows}
          isLoading={cargoCompanyDispatchList.isLoading}
          isError={cargoCompanyDispatchList.isError}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={cargoCompanyDispatchList.data.totalCount}
        />
      </CardComponent>
    </div>
  );
};

export default CargoDispatchListingComponent;
