export enum MileageAddRequestVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type MileageAddRequestAddressSequenceNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
  distance?: number;
};

export type MileageAddRequestBody = {
  workerId: string;
  workerCompanyId: string;
  mileageDate: Date;
  addressSequence: {
    seq: MileageAddRequestAddressSequenceNode[];
  };
  cardNumber: string | null | undefined;
  commissionNumber: string | null | undefined;
  mileageNumber: string | null | undefined;
  notes: string | null | undefined;
  vehicleType: MileageAddRequestVehicleType | undefined;
  distance: number | undefined;
  osrmDistance?: number;
};

type MileageAddRequest = {
  workerId: string;
  workerCompanyId: string;
  mileageDate: Date;
  addressSequenceNodes: MileageAddRequestAddressSequenceNode[];
  cardNumber?: string | null;
  commissionNumber?: string | null;
  mileageNumber?: string | null;
  notes?: string | null;
  vehicleType?: MileageAddRequestVehicleType;
  distance?: number;
  osrmDistance?: number;
};

export default MileageAddRequest;
