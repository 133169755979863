import classNames from "classnames";
import { FC } from "react";
import OrderFinishedListingExecutionStatus from "../../types/order-finished-listing-execution-status";

type OrderFinishedListingTableStatusProps = {
  status: OrderFinishedListingExecutionStatus;
};

const OrderFinishedListingTableStatusComponent: FC<
  OrderFinishedListingTableStatusProps
> = (props) => {
  const getClassNameByStatus = (): string => {
    switch (props.status) {
      case OrderFinishedListingExecutionStatus.FINISHED:
        return "finished";
      case OrderFinishedListingExecutionStatus.CANCELLED:
        return "cancelled";
      default:
        return "";
    }
  };

  const statusClassName = getClassNameByStatus();

  const containerClassNames = classNames(
    "order_finished_listing_table_status",
    statusClassName
  );

  return <div className={containerClassNames}></div>;
};

export default OrderFinishedListingTableStatusComponent;
