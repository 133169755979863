import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import UserStatus from "../../../../../common/types/user-status";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import RailyOfficerListingFilter, {
  RailyOfficerListingEmailFilter,
  RailyOfficerListingFirstNameFilter,
  RailyOfficerListingLastNameFilter,
  RailyOfficerListingActivityStatusFilter,
  RailyOfficerListingUsernameFilter,
} from "../common/types/raily-officer-listing-filter";
import RailyOfficerListingFilterType from "../common/types/raily-officer-listing-filter-type";
import RailyOfficerListingSortKey from "../common/types/raily-officer-listing-sort-key";

const railyOfficerListingFilterHelper = () => {
  const translations =
    userTranslationsHelper.getRailyOfficerListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForStatus = (
    filterValue: RailyOfficerListingActivityStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText = userHelper.getActivityStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.activityStatusLabel,
      translations.filters.activityStatusTitle
    );
  };

  const getBadgeForFirstName = (
    filterValue: RailyOfficerListingFirstNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.firstNameLabel,
      translations.filters.firstNameTitle
    );
  };

  const getBadgeForLastName = (
    filterValue: RailyOfficerListingLastNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.lastNameLabel,
      translations.filters.lastNameTitle
    );
  };

  const getBadgeForEmail = (
    filterValue: RailyOfficerListingEmailFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.emailLabel,
      translations.filters.emailTitle
    );
  };

  const getBadgeForUsername = (
    filterValue: RailyOfficerListingUsernameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.usernameLabel,
      translations.filters.usernameTitle
    );
  };

  const getSelectDefinitionForStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.ACTIVE)
              ),
            value: UserStatus.ACTIVE,
          },
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.DELETED)
              ),
            value: UserStatus.DELETED,
          },
        ],
      };
    };

  const getSelectDataForFirstName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.firstNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForLastName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.lastNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForUsername = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.usernameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForEmail = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.emailTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: RailyOfficerListingFilterType,
    query: string
  ) => {
    const options: Record<
      RailyOfficerListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [RailyOfficerListingFilterType.ACTIVITY_STATUS]:
        getSelectDefinitionForStatus,
      [RailyOfficerListingFilterType.FIRST_NAME]: getSelectDataForFirstName,
      [RailyOfficerListingFilterType.LAST_NAME]: getSelectDefinitionForLastName,
      [RailyOfficerListingFilterType.USERNAME]: getSelectDefinitionForUsername,
      [RailyOfficerListingFilterType.EMAIL]: getSelectDefinitionForEmail,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeDefinitionByType = (
    filterType: RailyOfficerListingFilterType,
    filterValue: RailyOfficerListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      RailyOfficerListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [RailyOfficerListingFilterType.ACTIVITY_STATUS]: () =>
        getBadgeForStatus(
          filterValue as RailyOfficerListingActivityStatusFilter["value"]
        ),
      [RailyOfficerListingFilterType.FIRST_NAME]: () =>
        getBadgeForFirstName(
          filterValue as RailyOfficerListingFirstNameFilter["value"]
        ),
      [RailyOfficerListingFilterType.LAST_NAME]: () =>
        getBadgeForLastName(
          filterValue as RailyOfficerListingLastNameFilter["value"]
        ),
      [RailyOfficerListingFilterType.EMAIL]: () =>
        getBadgeForEmail(
          filterValue as RailyOfficerListingEmailFilter["value"]
        ),
      [RailyOfficerListingFilterType.USERNAME]: () =>
        getBadgeForUsername(
          filterValue as RailyOfficerListingUsernameFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: RailyOfficerListingFilterType
  ): ListingFilterDefinitionOption<RailyOfficerListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<RailyOfficerListingFilter>[] => {
      return Object.values(RailyOfficerListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<RailyOfficerListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.firstNameAscLabel,
        value: RailyOfficerListingSortKey.FIRST_NAME_ASC,
      },
      {
        label: translations.sort.firstNameDescLabel,
        value: RailyOfficerListingSortKey.FIRST_NAME_DESC,
      },
      {
        label: translations.sort.lastNameAscLabel,
        value: RailyOfficerListingSortKey.LAST_NAME_ASC,
      },
      {
        label: translations.sort.lastNameDescLabel,
        value: RailyOfficerListingSortKey.LAST_NAME_DESC,
      },
      {
        label: translations.sort.usernameAscLabel,
        value: RailyOfficerListingSortKey.USERNAME_ASC,
      },
      {
        label: translations.sort.usernameDescLabel,
        value: RailyOfficerListingSortKey.USERNAME_DESC,
      },
    ];
  };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default railyOfficerListingFilterHelper;
