import { MessengerPostChannel } from "../../../services/messenger/post/messenger-post";
import MessengerSendPostLoadParams, {
  MessengerSendPostLoadParamsBillingType,
} from "../../../services/messenger/send-post/messenger-send-post-load-params";
import MessengerBillingType from "../types/messenger-billing-type";
import MessengerChannel from "../types/messenger-channel";

const createPostChannel = (channel: MessengerChannel): MessengerPostChannel => {
  switch (channel) {
    case MessengerChannel.BILLING:
      return MessengerPostChannel.BILLING;
    case MessengerChannel.CARGO:
      return MessengerPostChannel.CARGO;
    case MessengerChannel.DRIVER:
      return MessengerPostChannel.DRIVER;
    case MessengerChannel.RAILY:
      return MessengerPostChannel.RAILY;
    case MessengerChannel.SHARED:
      return MessengerPostChannel.SHARED;
    case MessengerChannel.TAXI:
      return MessengerPostChannel.TAXI;
    case MessengerChannel.MILEAGE_NOTES:
      return MessengerPostChannel.MILEAGE_NOTES;
    case MessengerChannel.MILEAGE_PASSENGER:
      return MessengerPostChannel.MILEAGE_PASSENGER;
    case MessengerChannel.DELEGATION_NOTES:
      return MessengerPostChannel.DELEGATION_NOTES;
  }
};

const createBillingType = (
  billingType: MessengerBillingType | undefined
): MessengerSendPostLoadParamsBillingType | undefined => {
  switch (billingType) {
    case MessengerBillingType.CARGO:
      return MessengerSendPostLoadParamsBillingType.CARGO;
    case MessengerBillingType.TAXI:
      return MessengerSendPostLoadParamsBillingType.TAXI;
    default:
      return undefined;
  }
};

const create = (
  activeChannel: MessengerChannel,
  newMessage: string,
  billingType: MessengerBillingType | undefined,
  billingUuid: string | undefined,
  orderUuid: string | undefined,
  planEntryUuid: string | undefined,
  solvedOrderUuid: string | undefined,
  taxiCorporationUuid: string | undefined
): MessengerSendPostLoadParams => {
  return {
    channel: createPostChannel(activeChannel),
    content: newMessage,
    billingType: createBillingType(billingType),
    billingUuid: billingUuid,
    orderUuid: orderUuid,
    planEntryUuid: planEntryUuid,
    solvedOrderUuid: solvedOrderUuid,
    taxiCorporationUuid: taxiCorporationUuid,
  };
};

const messengerSendPostLoadParamsFactory = {
  create,
};

export default messengerSendPostLoadParamsFactory;
