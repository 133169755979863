import HttpResponse from "../../../../utils/http/http.response";

export enum MileageSettingsDetailsResponseMileageNumberCreationModel {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
  DICT = "DICT",
}

export type MileageSettingsDetailsResponseItem = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  isCardNoRequired: boolean;
  isMileageNoRequired: boolean;
  mileageNoCreationType: MileageSettingsDetailsResponseMileageNumberCreationModel;
};

type MileageSettingsDetailsResponse =
  HttpResponse<MileageSettingsDetailsResponseItem>;

export default MileageSettingsDetailsResponse;
