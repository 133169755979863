import { PlanningOrderStatus } from "../common/types/plannin-order-status";

const getStatusOptions = () => [
  {
    status: PlanningOrderStatus.CURRENT_PLAN,
  },
  {
    status: PlanningOrderStatus.POTENTIAL_PLAN,
  },
  {
    status: PlanningOrderStatus.ORDER,
  },
  {
    status: PlanningOrderStatus.PROPOSED_POTENTIAL_PLAN,
  },
];

const planningOrderHelper = {
  getStatusOptions,
};

export default planningOrderHelper;
