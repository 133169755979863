import { FC } from "react";
import MileageListingActionData from "../common/types/mileage-listing-action-data";
import ButtonComponent from "../../../../common/components/button/button.component";
import mileageListingActionsModalHelper from "./mileage-listing-actions-modal.helper";
import MileageListingActionsModalTranslations from "../../../../languages/types/mileage/mileage-listing-actions-modal.translations";

type MileageListingActionsModalProps = {
  mileageActionData: MileageListingActionData[];
  onSuccess: () => void;
  onCancel: () => void;
  translations: MileageListingActionsModalTranslations;
};

const MileageListingActionsModalComponent: FC<
  MileageListingActionsModalProps
> = (props) => {
  const sourcesLabel = mileageListingActionsModalHelper.getDatalabel(
    props.mileageActionData
  );

  const isSubmitButtonEnabled = !!props.mileageActionData.length;

  return (
    <div className="mileage_listing_action_modal">
      <div className="mileage_listing_action_modal__heading">
        {props.translations.headingLabel}
      </div>
      <div className="mileage_listing_action_modal__content">
        <div>
          <div>{sourcesLabel}</div>
        </div>
        <div className="d-flex mt-2">
          <ButtonComponent
            type="success"
            isDisabled={!isSubmitButtonEnabled}
            title={props.translations.submitButtonTitle}
            onClick={props.onSuccess}
          >
            {props.translations.submitButtonLabel}
          </ButtonComponent>
          <ButtonComponent
            type="brand"
            onClick={props.onCancel}
            classNames={{ root: "ml-1" }}
            title={props.translations.cancelButtonTitle}
          >
            {props.translations.cancelButtonLabel}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default MileageListingActionsModalComponent;
