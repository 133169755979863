import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import OrderDetailsChangeCargoExternalIdFormValidationResults from "./types/order-details-change-cargo-external-id-data-validation.results";

const getDefaultFormValidationResults =
  (): OrderDetailsChangeCargoExternalIdFormValidationResults => {
    return {
      cargoExternalId: formValidationService.defaultValidationResult,
    };
  };

const orderDetailsChangeCargoExternalIdHelper = {
  getDefaultFormValidationResults,
};

export default orderDetailsChangeCargoExternalIdHelper;
