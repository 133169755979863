import { FC } from "react";
import TaxiListingFilter from "../common/types/taxi-listing-filter";
import TaxiListingSortKey from "../common/types/taxi-listing-sort-key";
import ListingFilterDefinition from "../../../../common/components/listing/filter/types/listing-filter-definition";
import taxiListingFilterHelper from "./taxi-listing-filter.helper";
import ListingSortDefinition from "../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../common/components/listing/filter/listing-filter.component";

type TaxiListingFilterProps = {
  filters: TaxiListingFilter[];
  onFiltersChange: (filters: TaxiListingFilter[]) => void;
  sortKey: TaxiListingSortKey;
  onSortKeyChange: (sortKey: TaxiListingSortKey) => void;
};

const TaxiListingFilterComponent: FC<TaxiListingFilterProps> = (props) => {
  const filterDefinition: ListingFilterDefinition<TaxiListingFilter> =
    taxiListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    taxiListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as TaxiListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as TaxiListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="taxi-listing-filter"
      idForTestingSort="taxi-listing-sort"
    />
  );
};

export default TaxiListingFilterComponent;
