import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import OrderDetailsHistoryResponse from "./order-details-history.response";
import OrderDetailsTransferHistoryResponse from "./order-details-transfer-history.response";

const fetchOrderHistory = (
  orderUuid: string
): Promise<OrderDetailsHistoryResponse> => {
  const path = `/orders/cargo-orders/${orderUuid}/history`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchTransferHistory = (
  orderUuid: string
): Promise<OrderDetailsTransferHistoryResponse> => {
  const path = `/orders/cargo-orders/${orderUuid}/handling-log`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const orderDetailsHistoryApiService = {
  fetchOrderHistory,
  fetchTransferHistory,
};

export default orderDetailsHistoryApiService;
