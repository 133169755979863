import { ChartDataItemGroupPoint } from "./types/chart-data";

const HOUR_PER_DAY = 24;
const ONE_HOUR = 60 * 60 * 1000;

const getMinValue = (list: ChartDataItemGroupPoint[]) =>
  list
    ? Math.min(...list.map((p: ChartDataItemGroupPoint) => p.date.getTime()))
    : 0;

const getMaxValue = (list: ChartDataItemGroupPoint[]) =>
  list
    ? Math.max(...list.map((p: ChartDataItemGroupPoint) => p.date.getTime()))
    : 0;

const getFullHourTime = (time: number) => new Date(time).setMinutes(0, 0, 0);

const calculateMiddlePoint = (from: number, to: number) =>
  (to - from) / 2 + from;

const calculateTicksAndDays = (
  centerGroupValue: number,
  points: ChartDataItemGroupPoint[],
  groupedPoints: any
) => {
  const result = [];

  const minDateFromList = getMinValue(points);
  const maxDateFromList = getMaxValue(points);

  let minDate = getFullHourTime(minDateFromList);
  const maxDate = getFullHourTime(maxDateFromList) + ONE_HOUR;

  const days = Math.ceil((maxDate - minDate) / ONE_HOUR / HOUR_PER_DAY);

  result.push(minDate);

  while (minDate < maxDate) {
    minDate += ONE_HOUR;
    result.push(minDate);
  }

  adjustNumberOfTicks(centerGroupValue, days, result, groupedPoints);

  return { result, days };
};

const adjustNumberOfTicks = (
  centerGroupValue: number,
  days: number,
  result: number[],
  groupedPoints: any
) => {
  const hoursToDisplay = HOUR_PER_DAY * days;

  if (!groupedPoints[centerGroupValue]) return;

  const points: ChartDataItemGroupPoint[] = groupedPoints[centerGroupValue];

  const minDateFromCenterGroup = getMinValue(points);
  const maxDateFromCenterGroup = getMaxValue(points);
  const referenceGroupMinDate = getFullHourTime(minDateFromCenterGroup);
  const referenceGroupMaxDate = getFullHourTime(maxDateFromCenterGroup);

  let left = result.filter((r: number) => r < referenceGroupMinDate).length;
  let right = result.filter((r: number) => r > referenceGroupMaxDate).length;

  while (result.length < hoursToDisplay) {
    if (left <= right) {
      result.unshift(result[0] - ONE_HOUR);
      left++;
    } else {
      result.push(result[result.length - 1] + ONE_HOUR);
      right++;
    }
  }
};

const referenceGroupPositionRatio = (
  referenceGroupValue: number,
  ticks: number[],
  groupedPoints: any
) => {
  if (!groupedPoints[referenceGroupValue]) return 0;

  const points: ChartDataItemGroupPoint[] = groupedPoints[referenceGroupValue];

  const minValue = getMinValue(points);
  const maxValue = getMaxValue(points);
  const referenceGroupCenter = calculateMiddlePoint(minValue, maxValue);

  const labelNextToReferenceGroupCenter = getFullHourTime(referenceGroupCenter);
  return ticks.indexOf(labelNextToReferenceGroupCenter) / ticks.length;
};

const setHourFormat = (tick: string) => {
  const date = new Date(tick);
  const hour = date.getHours();
  const hourFormat = (hour < 10 ? "0" + hour : hour) + ":00";

  return hour === 0
    ? hourFormat + "\n" + date.toLocaleDateString()
    : hourFormat;
};

const truncateText = (text: string, maxLength: number) =>
  text?.length > maxLength ? text.substring(0, maxLength) + "..." : text;

const chartHelper = {
  calculateTicksAndDays,
  calculateMiddlePoint,
  getMinValue,
  getMaxValue,
  getFullHourTime,
  setHourFormat,
  referenceGroupPositionRatio,
  truncateText,
};

export default chartHelper;
