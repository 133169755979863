import { FC, useMemo, useState } from "react";
import { useAppContext } from "../../../../../../context/app.context";
import ListingFilterSelectComponent from "../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import taxiCargoContractListingFilterHelper from "../../common/filter/taxi-cargo-contract-listing-filter.helper";
import TaxiCargoContractListingFilterSelectOption from "../../common/types/taxi-cargo-contract-listing-filter-select-option";
import TaxiCargoContractListingFilter from "../../common/types/taxi-cargo-contract-listing-filter";

type TaxiCargoContractListingFiltersSelectProps = {
  filters: TaxiCargoContractListingFilter[];
  onAddNewFilter: (filter: TaxiCargoContractListingFilter) => void;
};

const TaxiCargoContractListingFiltersSelectComponent: FC<
  TaxiCargoContractListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions = useMemo(() => {
    return taxiCargoContractListingFilterHelper.getSelectOptions(
      searchInputValue,
      props.filters
    );
  }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: TaxiCargoContractListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as TaxiCargoContractListingFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="cargo-taxi-contract-listing-filter"
    />
  );
};

export default TaxiCargoContractListingFiltersSelectComponent;
