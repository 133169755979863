import { FC, useEffect } from "react";
import CardComponent from "../../common/components/card/card.component";
import HeadingComponent from "../../common/components/heading/heading.component";
import useDocumentTitle from "../../common/hooks/use-document-title";
import { useAppContext } from "../../context/app.context";
import appTranslationsHelper from "../../languages/app-translations.helper";

const RouteNoAccessComponent: FC = () => {
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.routeNoAccess);

  const { setBreadcrumbs } = useAppContext();

  useEffect(() => {
    setBreadcrumbs([]);
  }, []);

  const translations = appTranslationsHelper.getRouteNoAccessTranslations();

  return (
    <div className="no_access">
      <div className="no_access_message">
        <HeadingComponent title={translations.headingText} />
        <CardComponent>{translations.message}</CardComponent>
      </div>
    </div>
  );
};

export default RouteNoAccessComponent;
