import appConfig from "../../../../../app.config";
import searchRoadRoutingService from "../../../../../common/utils/search-road-route/search-road-routing.service";
import authService from "../../../../auth/common/auth.service";
import OrderDetailsRidesNodeExlusionsResponse from "../../../details/common/api/order-details-rides-node-exclusions.response";
import OrderJoinMergedOrderRequest from "./order-join-merged-order.request";
import OrderJoinOrderDetailsSolvedRidesRequest from "./order-join-order-details-solved-rides.request";
import OrderJoinOrderDetailsSolvedRidesResponse from "./order-join-order-details-solved-rides.response";
import OrderJoinOrderPreviewResponse from "./order-join-order-details.response";
import OrderJoinPreviewRequest from "./order-join-preview.request";
import OrderJoinRequest from "./order-join.request";
import OrderJoinResponse from "./order-join.response";

const fetchRoute = searchRoadRoutingService.search;

const fetchRidesNodeExclusions = (
  orderUuid: string
): Promise<OrderDetailsRidesNodeExlusionsResponse> => {
  const path = `/orders/cargo-orders/${orderUuid}/node-exclusion`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchOrderDetails = (
  orderUuid: string
): Promise<OrderJoinOrderPreviewResponse> => {
  const path = `/orders/cargo-orders/${orderUuid}`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchOrderJoinPreview = (
  targetOrderUuid: string,
  request: OrderJoinPreviewRequest
): Promise<OrderJoinOrderPreviewResponse> => {
  const path = `/orders/${targetOrderUuid}/shuffle-merge`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const requestBody: OrderJoinMergedOrderRequest = {
    ...request,
    dry_run: true,
  };

  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(requestBody),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const joinOrders = (
  targetOrderUuid: string,
  request: OrderJoinRequest
): Promise<OrderJoinResponse> => {
  const path = `/orders/${targetOrderUuid}/shuffle-merge`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const requestBody: OrderJoinMergedOrderRequest = {
    ...request,
    dry_run: false,
  };

  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(requestBody),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchSolvedRides = (
  request: OrderJoinOrderDetailsSolvedRidesRequest
): Promise<OrderJoinOrderDetailsSolvedRidesResponse> => {
  const path = `/rides/solved`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(request),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const orderJoinApiService = {
  fetchOrderDetails,
  fetchRoute,
  fetchSolvedRides,
  fetchRidesNodeExclusions,
  fetchOrderJoinPreview,
  joinOrders,
};

export default orderJoinApiService;
