import { FC, useMemo } from "react";
import SingleSelectComponent from "../single-select/single-select.component";
import { useAppContext } from "../../../../../context/app.context";
import TaxiCorporationSelectOption from "./taxi-corporation-select-option";
import taxiCorporationSelectOptionFactory from "./taxi-corporation-select-option.factory";
import { TaxiCorporationListItem } from "../../../../services/taxi-corporation/list/taxi-corporation-list";

type TaxiCorporationSingleSelectProps = {
  placeholder: string;
  selectedTaxiCorporationUuid: string;
  taxiCorporationList: TaxiCorporationListItem[];
  onChange: (value: TaxiCorporationListItem | null) => void;
  onBlur: () => void;
  isError: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  idForTesting?: string;
};

const TaxiCorporationSingleSelectComponent: FC<
  TaxiCorporationSingleSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const selectOptions: TaxiCorporationSelectOption[] = useMemo(
    () =>
      taxiCorporationSelectOptionFactory.createOptions(
        props.taxiCorporationList
      ),
    [props.taxiCorporationList, selectedAppLanguage]
  );

  const selectedOption: TaxiCorporationSelectOption | null = useMemo(() => {
    if (!props.selectedTaxiCorporationUuid) {
      return null;
    }

    return (
      selectOptions.find(
        (option) => option.value.uuid === props.selectedTaxiCorporationUuid
      ) ?? null
    );
  }, [props.selectedTaxiCorporationUuid, selectOptions]);

  return (
    <SingleSelectComponent
      placeholder={props.placeholder}
      value={selectedOption}
      onChange={(value) => props.onChange(value?.value ?? null)}
      onBlur={props.onBlur}
      options={selectOptions}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      hasError={props.isError}
      idForTesting={props.idForTesting}
      isSearchable
    />
  );
};

export default TaxiCorporationSingleSelectComponent;
