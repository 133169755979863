import paginationService from "../../../../../common/utils/pagination/pagination.service";
import TaxiUnsettledRouteListingFilterType from "./types/taxi-unsettled-route-listing-filter-type";
import TaxiUnsettledRouteListingSortKey from "./types/taxi-unsettled-route-listing-sort-key";
import TaxiUnsettledRouteListingFilter, {
  TaxiUnsettledRouteListingClientFilter,
  TaxiUnsettledRouteListingDriverFilter,
  TaxiUnsettledRouteListingFleetPartnerFilter,
  TaxiUnsettledRouteListingOrderInternalIdFilter,
  TaxiUnsettledRouteListingRouteAddressFilter,
  TaxiUnsettledRouteListingRouteDestinationAddressFilter,
  TaxiUnsettledRouteListingRouteIdFilter,
  TaxiUnsettledRouteListingRouteIntermediateAddressFilter,
  TaxiUnsettledRouteListingRoutePickupAddressFilter,
  TaxiUnsettledRouteListingRouteStartDateFilter,
} from "./types/taxi-unsettled-route-listing-filter";
import TaxiUnsettledRouteListingRequest, {
  TaxiUnsettledRouteListingRequestOrder,
} from "./api/taxi-unsettled-route-listing.request";

const createRequestOrder = (
  sortKey: TaxiUnsettledRouteListingSortKey | null
): TaxiUnsettledRouteListingRequest["order"] => {
  const options: {
    sortKey: TaxiUnsettledRouteListingSortKey;
    requestOrder: TaxiUnsettledRouteListingRequestOrder;
  }[] = [
    {
      requestOrder: TaxiUnsettledRouteListingRequestOrder.START_TIME_DESC,
      sortKey: TaxiUnsettledRouteListingSortKey.START_TIME_DESC,
    },
    {
      requestOrder: TaxiUnsettledRouteListingRequestOrder.START_TIME_ASC,
      sortKey: TaxiUnsettledRouteListingSortKey.START_TIME_ASC,
    },
    {
      requestOrder: TaxiUnsettledRouteListingRequestOrder.HUMAN_ID_ASC,
      sortKey: TaxiUnsettledRouteListingSortKey.ROUTE_ID_ASC,
    },
    {
      requestOrder: TaxiUnsettledRouteListingRequestOrder.HUMAN_ID_DESC,
      sortKey: TaxiUnsettledRouteListingSortKey.ROUTE_ID_DESC,
    },
    {
      requestOrder: TaxiUnsettledRouteListingRequestOrder.DRIVER_ASC,
      sortKey: TaxiUnsettledRouteListingSortKey.DRIVER_ASC,
    },
    {
      requestOrder: TaxiUnsettledRouteListingRequestOrder.DRIVER_DESC,
      sortKey: TaxiUnsettledRouteListingSortKey.DRIVER_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: TaxiUnsettledRouteListingFilter[],
  sortKey: TaxiUnsettledRouteListingSortKey | null
): TaxiUnsettledRouteListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    limit: pageSize,
    offset,
    order: createRequestOrder(sortKey),
    human_id: (
      filters.find(
        (filter) => filter.type === TaxiUnsettledRouteListingFilterType.ROUTE_ID
      )?.value as TaxiUnsettledRouteListingRouteIdFilter["value"] | undefined
    )?.toString(),
    driver: filters.find(
      (filter) => filter.type === TaxiUnsettledRouteListingFilterType.DRIVER
    )?.value as TaxiUnsettledRouteListingDriverFilter["value"] | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiUnsettledRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiUnsettledRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    client: filters.find(
      (filter) => filter.type === TaxiUnsettledRouteListingFilterType.CLIENT
    )?.value as TaxiUnsettledRouteListingClientFilter["value"] | undefined,

    external_id: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.ORDER_INTERNAL_ID
    )?.value as
      | TaxiUnsettledRouteListingRoutePickupAddressFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | TaxiUnsettledRouteListingRoutePickupAddressFilter["value"]
      | undefined,
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | TaxiUnsettledRouteListingRouteIntermediateAddressFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | TaxiUnsettledRouteListingRouteDestinationAddressFilter["value"]
      | undefined,
    address: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.ROUTE_ADDRESS
    )?.value as
      | TaxiUnsettledRouteListingRouteAddressFilter["value"]
      | undefined,
    cargo_order_human_id: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.ORDER_INTERNAL_ID
    )?.value as
      | TaxiUnsettledRouteListingOrderInternalIdFilter["value"]
      | undefined,
    fleet_partner: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.FLEET_PARTNER
    )?.value as
      | TaxiUnsettledRouteListingFleetPartnerFilter["value"]
      | undefined,
  };
};
const taxiUnsettledRouteListingRequestFactory = {
  createRequest,
};

export default taxiUnsettledRouteListingRequestFactory;
