import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiOfficerAddUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type TaxiOfficerAddUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiOfficerAddUserPermissionOption>;

export type TaxiOfficerAddUserPermissions =
  UserPermissions<TaxiOfficerAddUserPermissionOption>;

const taxiOfficerAddUserPermissionDefinition: TaxiOfficerAddUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default taxiOfficerAddUserPermissionDefinition;
