import {
  PassengerListItem,
  PassengerListItemDispatch,
} from "../../../../../common/services/passenger/list/passenger-list";
import UserStatus from "../../../../../common/types/user-status";
import phoneNumberService from "../../../../../common/utils/phone-number/phone-number.service";
import PassengerListingItem, {
  PassengerListingItemDispatch,
} from "./types/passenger-listing-item";

const createMobilePhones = (
  listItem: PassengerListItem
): PassengerListingItem["phoneNumbers"] => {
  const phoneNumbers: PassengerListingItem["phoneNumbers"] = [
    phoneNumberService.getLabel(listItem.phoneNumber),
  ];

  if (listItem.alternativePhoneNumber) {
    phoneNumbers.push(
      phoneNumberService.getLabel(listItem.alternativePhoneNumber)
    );
  }

  return phoneNumbers;
};

const createDispatch = (
  listItemDispatch: PassengerListItemDispatch
): PassengerListingItemDispatch => {
  return {
    name: listItemDispatch.displayName,
    cargoCompany: listItemDispatch.cargoCompany.name,
  };
};

const createListingItem = (
  listItem: PassengerListItem
): PassengerListingItem => {
  const phoneNumbers = createMobilePhones(listItem);

  return {
    uuid: listItem.uuid,
    firstName: listItem.firstName,
    lastName: listItem.lastName,
    dispatches: listItem.dispatches.map((listItemDispatch) =>
      createDispatch(listItemDispatch)
    ),
    addresses: listItem.addresses.map((address) => {
      return address.displayName;
    }),
    email: listItem.email,
    username: listItem.username,
    phoneNumbers,
    status: !listItem.removalDate ? UserStatus.ACTIVE : UserStatus.DELETED,
  };
};

const createListingItems = (
  listItems: PassengerListItem[]
): PassengerListingItem[] => {
  return listItems.map(createListingItem);
};

const passengerListingItemFactory = {
  createListingItems,
};

export default passengerListingItemFactory;
