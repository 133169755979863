import CargoOrderSettlementListingByRailyCargoCompanySelectOption from "./types/cargo-order-settlement-listing-by-raily-cargo-company-select-option";
import { CargoOrderSettlementListingByRailyCargoCompaniesResponseDataItem } from "./api/cargo-order-settlement-listing-by-raily-cargo-companies.response";
import cargoOrderSettlementListingFactory from "../../common/cargo-order-settlement-listing.factory";

const createCargoCompanySelectOption = (
  responseCargoCompany: CargoOrderSettlementListingByRailyCargoCompaniesResponseDataItem
): CargoOrderSettlementListingByRailyCargoCompanySelectOption => {
  return {
    label: responseCargoCompany.display_name,
    value: {
      uuid: responseCargoCompany.id,
      displayName: responseCargoCompany.display_name,
    },
  };
};

const createCargoCompanySelectOptions = (
  responseCargoCompanies: CargoOrderSettlementListingByRailyCargoCompaniesResponseDataItem[]
): CargoOrderSettlementListingByRailyCargoCompanySelectOption[] => {
  return responseCargoCompanies.map(createCargoCompanySelectOption);
};

const cargoOrderSettlementListingByRailyFactory = {
  createCargoCompanySelectOptions,
  createListingItems: cargoOrderSettlementListingFactory.createListingItems,
};

export default cargoOrderSettlementListingByRailyFactory;
