import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import PhoneNumber from "../../../../types/phone-number";
import PhoneNumberInputDialingCodeOption from "./phone-number-input-dialing-code-option";

const getCountryOptions = () => {
  const phoneInputComponentTranslations =
    appTranslationsHelper.getComponentTranslations().phoneInput;

  const options: PhoneNumberInputDialingCodeOption[] = [
    {
      country: phoneInputComponentTranslations.countryName.PL,
      countryCode: "PL",
      dialingCode: "+48",
    },
    {
      country: phoneInputComponentTranslations.countryName.DE,
      countryCode: "DE",
      dialingCode: "+49",
    },
    {
      country: phoneInputComponentTranslations.countryName.SK,
      countryCode: "SK",
      dialingCode: "+412",
    },
    {
      country: phoneInputComponentTranslations.countryName.CZ,
      countryCode: "CZ",
      dialingCode: "+420",
    },
    {
      country: phoneInputComponentTranslations.countryName.UA,
      countryCode: "UA",
      dialingCode: "+380",
    },
    {
      country: phoneInputComponentTranslations.countryName.RU,
      countryCode: "RU",
      dialingCode: "+7",
    },
    {
      country: phoneInputComponentTranslations.countryName.BY,
      countryCode: "BY",
      dialingCode: "+375",
    },
    {
      country: phoneInputComponentTranslations.countryName.LT,
      countryCode: "LT",
      dialingCode: "+370",
    },
  ];

  return options;
};

const validatePhoneInput = (value: string): boolean => {
  const pattern = new RegExp(`^[0-9]*$`);

  return pattern.test(value);
};

const getEmptyValue = (): PhoneNumber => {
  return {
    dialingCode: "",
    number: "",
  };
};

const phoneNumberInputHelper = {
  getCountryOptions,
  validatePhoneInput,
  getEmptyValue,
};

export default phoneNumberInputHelper;
