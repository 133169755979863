import DispatcherEditFormResponse, {
  DispatcherEditFormResponseAvailableDispatch,
  DispatcherEditFormResponseDispatch,
  DispatcherEditFormResponseUser,
} from "../../../api/raily/dispatcher/edit-form/dispatcher-edit-form.response";
import DispatcherEditForm, {
  DispatcherEditFormDispatch,
} from "./dispatcher-edit-form";

const createAlternativePhoneNumber = (
  responseUser: DispatcherEditFormResponseUser
): DispatcherEditForm["alternativePhoneNumber"] => {
  if (!responseUser.mobileSecondary || !responseUser.mobileSecondaryPrefix) {
    return null;
  }

  return {
    dialingCode: responseUser.mobileSecondaryPrefix,
    number: responseUser.mobileSecondary,
  };
};

const createDispatch = (
  responseDispatch: DispatcherEditFormResponseDispatch
): DispatcherEditFormDispatch => {
  return {
    address: responseDispatch.addressString,
    createdBy: responseDispatch.createdBy,
    creationDate: responseDispatch.createdAt,
    displayName: responseDispatch.displayName,
    modificationDate: responseDispatch.modifiedAt,
    modifiedBy: responseDispatch.modifiedBy,
    name: responseDispatch.dispatchName,
    uuid: responseDispatch.id,
  };
};

const createAvailableDispatch = (
  responseAvailableDispatch: DispatcherEditFormResponseAvailableDispatch
): DispatcherEditFormDispatch => {
  return {
    address: responseAvailableDispatch.addressString,
    createdBy: responseAvailableDispatch.createdBy,
    creationDate: responseAvailableDispatch.createdAt,
    displayName: responseAvailableDispatch.displayName,
    modificationDate: responseAvailableDispatch.modifiedAt,
    modifiedBy: responseAvailableDispatch.modifiedBy,
    name: responseAvailableDispatch.dispatchName,
    uuid: responseAvailableDispatch.id,
  };
};

const createAvailableDispatches = (
  responseAvailableDispatches: DispatcherEditFormResponseAvailableDispatch[]
): DispatcherEditFormDispatch[] => {
  return responseAvailableDispatches.map(createAvailableDispatch);
};

const create = (response: DispatcherEditFormResponse): DispatcherEditForm => {
  return {
    email: response.data.user.email,
    alternativePhoneNumber: createAlternativePhoneNumber(response.data.user),
    availableDispatches: createAvailableDispatches(
      response.data.availableDispatches
    ),
    dispatch: createDispatch(response.data.dispatch),
    firstName: response.data.user.firstName,
    lastName: response.data.user.lastName,
    phoneNumber: {
      dialingCode: response.data.user.mobilePrimaryPrefix,
      number: response.data.user.mobilePrimary,
    },
    username: response.data.user.username,
  };
};

const dispatcherEditFormFactory = {
  create,
};

export default dispatcherEditFormFactory;
