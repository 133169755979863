import MileageContractUpdateRequest from "../../../api/raily/mileage-contract/update/mileage-contract-update.request";
import MileageContractUpdateParams from "./mileage-contract-update-params";

const create = (
  params: MileageContractUpdateParams
): MileageContractUpdateRequest => {
  return {
    contractUuid: params.contractUuid,
    dietFullAfterHours: params.dietFullAfterHours,
    dietFullRate: params.dietFullRate,
    dietHalfAfterHours: params.dietHalfAfterHours,
    dietHalfRate: params.dietHalfRate,
    isActive: params.isActive,
    distanceRateCarGt900cm3: params.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: params.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: params.distanceRateCompanyVehicle,
    distanceRateMoped: params.distanceRateMoped,
    distanceRateMotorcycle: params.distanceRateMotorcycle,
    validSince: params.validSince,
    validTo: params.validTo,
  };
};

const mileageContractsUpdateRequestFactory = {
  create,
};

export default mileageContractsUpdateRequestFactory;
