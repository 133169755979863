import { FC, useState } from "react";
import UserDeleteUserData from "./common/types/user-delete-user-data";
import ButtonComponent from "../../../../common/components/button/button.component";
import ModalComponent from "../../../../common/components/modal/modal.component";
import htmlParser from "../../../../common/utils/html-parser/html-parser.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userDeleteHelper from "./common/types/user-delete.helper";
import userService from "../../../../common/services/user/user.service";
import useAbort from "../../../../common/hooks/use-abort";
import UserDeleteError from "../../../../common/services/user/delete/user-delete-error";

type UserDeleteProps = {
  isOpen: boolean;
  onClose: () => void;
  userData: UserDeleteUserData | null;
  onSuccess: () => void;
};

const UserDeleteComponent: FC<UserDeleteProps> = (props) => {
  const [isDeletePending, setIsDeletePending] = useState(false);

  const deleteUserAbort = useAbort();

  const translations = userTranslationsHelper.getUserDeleteTranslations();

  if (!props.userData) {
    return null;
  }

  const onDeleteUserSuccess = () => {
    const notificationText = userDeleteHelper.getSuccessNotificationLabel(
      props.userData!
    );
    notificationService.success(notificationText);

    props.onSuccess();
  };

  const onDeleteUserFailure = (error: UserDeleteError) => {
    notificationService.error(error.message);
  };

  const deleteUser = async () => {
    setIsDeletePending(true);

    try {
      await userService.delete(
        { userUuid: props.userData!.uuid },
        deleteUserAbort.signal,
        props.userData!
      );

      onDeleteUserSuccess();
    } catch (error) {
      onDeleteUserFailure(error as UserDeleteError);
    } finally {
      setIsDeletePending(false);
    }
  };

  const onSubmitButtonClick = () => {
    setIsDeletePending(true);

    deleteUser();
  };

  const contentText = userDeleteHelper.getContentText(props.userData);

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={{ title: translations.headingLabel }}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="danger"
          title={translations.submitButtonTitle}
          idForTesting="submit-button"
          isLoading={isDeletePending}
        >
          {translations.submitButtonLabel}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.rejectButtonTitle}
          idForTesting="reject-button"
        >
          {translations.rejectButtonLabel}
        </ButtonComponent>,
      ]}
    >
      {htmlParser.parse(contentText)}
    </ModalComponent>
  );
};

export default UserDeleteComponent;
