import DriverEditUpdateRequest, {
  DriverEditAddressUpdateRequest,
  DriverEditTaxiDriverAssociationForB2BRequest,
  DriverEditTaxiDriverAssociationForEmploymentRequest,
  DriverEditTaxiDriverAssociationUpdateRequest,
} from "./api/driver-edit-update.request";
import DriverEditAccountFormData from "./types/driver-edit-account-form-data";
import DriverEditAddress from "./types/driver-edit-address";
import DriverEditCompanyFormData from "./types/driver-edit-company-form-data";
import DriverEditVehicleFormData from "./types/driver-edit-vehicle-form-data";
import DriverEditUserFormData from "./types/driver-edit-user-form-data";
import UserLanguageSelectOption from "../../../../../common/types/user-language-select-option";
import UserLanguage from "../../../../../common/types/user-language";
import dateService from "../../../../../common/utils/date/date.service";
import UserFormOfEmployment from "../../../../../common/types/user-form-of-employment";

const createEditDriverLanguages = (
  languagesSelectOptions: UserLanguageSelectOption[]
): UserLanguage[] => {
  return languagesSelectOptions.map((languagesSelectOption) => {
    return languagesSelectOption.value;
  });
};

const createEditDriverAddresses = (
  addresses: DriverEditAddress[]
): DriverEditAddressUpdateRequest[] => {
  return addresses.map((address) => {
    return {
      country: address.country,
      zip_code: address.zipCode,
      town: address.town,
      street: address.street,
      house_no: address.houseNumber,
      apartment: address.apartmentNumber,
      description: address.description,
      lat: address.latitude,
      lon: address.longitude,
      type: address.type,
    };
  });
};

const createDriverEditTaxiDriverAssociationForEmploymentRequest = (
  userFormData: DriverEditUserFormData,
  taxiDriverAssociationUuid: string
): DriverEditTaxiDriverAssociationForEmploymentRequest => {
  return {
    contract_type: userFormData.formOfEmployment?.value!,
    fleet_partner_id: userFormData.fleetPartner?.value.uuid!,
    id: taxiDriverAssociationUuid,
  };
};

const createDriverEditTaxiDriverAssociationForB2BRequest = (
  userFormData: DriverEditUserFormData,
  companyFormData: DriverEditCompanyFormData,
  taxiDriverAssociationUuid: string
): DriverEditTaxiDriverAssociationForB2BRequest => {
  return {
    contract_type: userFormData.formOfEmployment?.value!,
    tax_id: companyFormData.taxNumber,
    regon: companyFormData.companyId || null,
    krs_no: companyFormData.nationalCourtRegisterNumber || null,
    fleet_partner_id: userFormData.fleetPartner?.value.uuid ?? null,
    company_name: companyFormData.name,
    country: companyFormData.address.country,
    town: companyFormData.address.town,
    zip_code: companyFormData.address.zipCode,
    street: companyFormData.address.street,
    house_no: companyFormData.address.houseNumber,
    apartment: companyFormData.address.apartmentNumber,
    description: companyFormData.address.description,
    id: taxiDriverAssociationUuid,
  };
};

const createDriverEditTaxiDriverAssociationRequest = (
  userFormData: DriverEditUserFormData,
  companyFormData: DriverEditCompanyFormData,
  taxiDriverAssociationUuid: string
): DriverEditTaxiDriverAssociationUpdateRequest => {
  if (userFormData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
    return createDriverEditTaxiDriverAssociationForB2BRequest(
      userFormData,
      companyFormData,
      taxiDriverAssociationUuid
    );
  }

  return createDriverEditTaxiDriverAssociationForEmploymentRequest(
    userFormData,
    taxiDriverAssociationUuid
  );
};

const createEditDriverRequest = (
  accountFormData: DriverEditAccountFormData,
  userFormData: DriverEditUserFormData,
  companyFormData: DriverEditCompanyFormData,
  vehicleFormData: DriverEditVehicleFormData,
  taxiDriverAssociationUuid: string
): DriverEditUpdateRequest => {
  return {
    user: {
      first_name: userFormData.firstName,
      last_name: userFormData.lastName,
      mobile_primary_prefix: userFormData.mobile.dialingCode ?? "",
      mobile_primary: userFormData.mobile.number ?? "",
      mobile_secondary_prefix: userFormData.alternativeMobile.dialingCode,
      mobile_secondary: userFormData.alternativeMobile.number,
      email: accountFormData.email,
    },
    driver: {
      birth_date: dateService.format(userFormData.birthDate!, "yyyy-mm-dd"),
      birth_place: userFormData.birthPlace,
      id_number: userFormData.personalIdNumber,
      citizenship: userFormData.citizenship?.value!,
      languages: createEditDriverLanguages(userFormData.languages),
      experience: userFormData.yearsOfExperience!,
      car_ownership: vehicleFormData.ownership?.value!,
      car_make: vehicleFormData.make,
      car_model: vehicleFormData.model,
      car_reg_no: vehicleFormData.registrationNumber,
      car_prod_year: vehicleFormData.productionYear!,
      car_seats_no: vehicleFormData.numberOfSeats!,
    },
    associations: [
      createDriverEditTaxiDriverAssociationRequest(
        userFormData,
        companyFormData,
        taxiDriverAssociationUuid
      ),
    ],
    addresses: createEditDriverAddresses(userFormData.addresses),
  };
};

const driverEditFactory = {
  createEditDriverRequest,
};

export default driverEditFactory;
