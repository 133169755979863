import DelegationAddFormData from "../../form/types/delegation-add-form-data";

const getCargoCompanyLabel = (companyName: string) => {
  if (!companyName) {
    return `-----`;
  }

  return companyName;
};

const getCargoCompanyTitle = (companyName: string) => {
  if (!companyName) {
    return undefined;
  }

  return companyName;
};

const getWorkerLabel = (workerName: string | undefined) => {
  if (!workerName) {
    return `-----`;
  }

  return workerName;
};

const getWorkerTitle = (workerName: string | undefined) => {
  if (!workerName) {
    return undefined;
  }

  return workerName;
};

const getStartDateLabel = (startDate: Date | null): string => {
  if (!startDate) {
    return `-----`;
  }

  return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString(
    undefined,
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  )}`;
};

const getStartDateTitle = (startDate: Date | null): string => {
  if (!startDate) {
    return "";
  }

  return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString(
    undefined,
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  )}`;
};

const getEndDateLabel = (endDate: Date | null): string => {
  if (!endDate) {
    return `-----`;
  }

  return `${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString(
    undefined,
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  )}`;
};

const getEndDateTitle = (endDate: Date | null): string => {
  if (!endDate) {
    return "";
  }

  return `${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString(
    undefined,
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  )}`;
};

const getDelegationCostLabel = (formData: DelegationAddFormData) => {
  let totalCost = 0;

  totalCost =
    (formData.mileagesCost ?? 0) +
    (formData.accommodationBillsCost ?? 0) +
    (formData.accommodationCost ?? 0) +
    (formData.localTravelCost ?? 0) +
    (formData.oneDayDietCost ?? 0) +
    (formData.otherExpenses ?? 0) +
    (formData.startedDayDietCost ?? 0) +
    (formData.wholeDayDietCost ?? 0);

  return totalCost.toFixed(2) + " PLN";
};

const getDelegationCostTitle = (formData: DelegationAddFormData) => {
  let totalCost = 0;

  totalCost =
    (formData.mileagesCost ?? 0) +
    (formData.accommodationBillsCost ?? 0) +
    (formData.accommodationCost ?? 0) +
    (formData.localTravelCost ?? 0) +
    (formData.oneDayDietCost ?? 0) +
    (formData.otherExpenses ?? 0) +
    (formData.startedDayDietCost ?? 0) +
    (formData.wholeDayDietCost ?? 0);

  return totalCost.toFixed(2) + " PLN";
};

const getDietCostLabel = (formData: DelegationAddFormData) => {
  let dietCost = 0;

  dietCost =
    (formData.oneDayDietCost ?? 0) +
    (formData.wholeDayDietCost ?? 0) +
    (formData.startedDayDietCost ?? 0);

  return dietCost.toFixed(2) + " PLN";
};

const getDietCostTitle = (formData: DelegationAddFormData) => {
  let dietCost = 0;

  dietCost =
    (formData.oneDayDietCost ?? 0) +
    (formData.wholeDayDietCost ?? 0) +
    (formData.startedDayDietCost ?? 0);

  return dietCost.toFixed(2) + " PLN";
};

const getPrepayLabel = (advance: number | null) => {
  if (!advance) {
    return `-----`;
  }

  return advance.toFixed(2) + " PLN";
};

const getPrepayTitle = (advance: number | null) => {
  if (!advance) {
    return undefined;
  }

  return advance.toFixed(2) + " PLN";
};

const getLumpSumCostLabel = (lumpSumCost: number | null) => {
  if (!lumpSumCost) {
    return `-----`;
  }

  return lumpSumCost.toFixed(2) + " PLN";
};

const getLumpSumCostTitle = (lumpSumCost: number | null) => {
  if (!lumpSumCost) {
    return undefined;
  }

  return lumpSumCost.toFixed(2) + " PLN";
};

const getOneDayDelegationLabel = (oneDayDelegation: number | null) => {
  if (!oneDayDelegation) {
    return `-----`;
  }

  return oneDayDelegation.toFixed(2) + " PLN";
};

const getOneDayDelegationTitle = (oneDayDelegation: number | null) => {
  if (!oneDayDelegation) {
    return undefined;
  }

  return oneDayDelegation.toFixed(2) + " PLN";
};

const getStartedDayDietCostLabel = (startedDayDietCost: number | null) => {
  if (!startedDayDietCost) {
    return `-----`;
  }

  return startedDayDietCost.toFixed(2) + " PLN";
};

const getStartedDayDietCostTitle = (startedDayDietCost: number | null) => {
  if (!startedDayDietCost) {
    return undefined;
  }

  return startedDayDietCost.toFixed(2) + " PLN";
};

const getWholeDayDietCostLabel = (wholeDayDietCostLabel: number | null) => {
  if (!wholeDayDietCostLabel) {
    return `-----`;
  }

  return wholeDayDietCostLabel.toFixed(2) + " PLN";
};

const getWholeDayDietCostTitle = (wholeDayDietCostLabel: number | null) => {
  if (!wholeDayDietCostLabel) {
    return undefined;
  }

  return wholeDayDietCostLabel.toFixed(2) + " PLN";
};

const getAccommodationCostLabel = (accommodationCost: number | null) => {
  if (!accommodationCost) {
    return `-----`;
  }

  return accommodationCost.toFixed(2) + " PLN";
};

const getAccommodationCostTitle = (accommodationCost: number | null) => {
  if (!accommodationCost) {
    return undefined;
  }

  return accommodationCost.toFixed(2) + " PLN";
};

const getAccommodationBillsLabel = (accommodationBills: number | null) => {
  if (!accommodationBills) {
    return `-----`;
  }

  return accommodationBills.toFixed(2) + " PLN";
};

const getAccommodationBillsTitle = (accommodationBills: number | null) => {
  if (!accommodationBills) {
    return undefined;
  }

  return accommodationBills.toFixed(2) + " PLN";
};

const getOtherExpensesLabel = (otherExpenses: number | null) => {
  if (!otherExpenses) {
    return `-----`;
  }

  return otherExpenses.toFixed(2) + " PLN";
};

const getOtherExpensesTitle = (otherExpenses: number | null) => {
  if (!otherExpenses) {
    return undefined;
  }

  return otherExpenses.toFixed(2) + " PLN";
};

const delegationAddSummaryHelper = {
  getStartDateLabel,
  getStartDateTitle,
  getCargoCompanyLabel,
  getCargoCompanyTitle,
  getWorkerLabel,
  getWorkerTitle,
  getEndDateLabel,
  getEndDateTitle,
  getDelegationCostLabel,
  getDelegationCostTitle,
  getDietCostLabel,
  getDietCostTitle,
  getPrepayLabel,
  getPrepayTitle,
  getLumpSumCostLabel,
  getLumpSumCostTitle,
  getOneDayDelegationLabel,
  getOneDayDelegationTitle,
  getStartedDayDietCostLabel,
  getStartedDayDietCostTitle,
  getWholeDayDietCostLabel,
  getWholeDayDietCostTitle,
  getAccommodationCostLabel,
  getAccommodationCostTitle,
  getAccommodationBillsLabel,
  getAccommodationBillsTitle,
  getOtherExpensesLabel,
  getOtherExpensesTitle,
};

export default delegationAddSummaryHelper;
