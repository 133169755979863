import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import DelegationListingActionData from "../common/types/delegation-listing-action-data";

const getDataLabel = (data: DelegationListingActionData[]): string => {
  const translations =
    delegationTranslationsHelper.getDelegationListingTranslations().download;

  return translations.joinedDelegationTemplateLabel.replace(
    "#{internalDelegationIds}",
    data.length === 0
      ? translations.delegationNotSelectedLabel
      : data.map((source) => source.humanId).join(", ")
  );
};

const delegationListingActionsModalHelper = {
  getDataLabel,
};

export default delegationListingActionsModalHelper;
