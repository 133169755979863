import AppLanguage from "../../../languages/types/app-language";
import AppHeaderLanguageSelectOption from "./types/app-header-language-select-option";

const createSelectOption = (
  languageOption: AppLanguage
): AppHeaderLanguageSelectOption => {
  return {
    label: languageOption,
    value: languageOption,
  };
};

const createSelectOptions = (
  languageOptions: AppLanguage[]
): AppHeaderLanguageSelectOption[] => {
  return languageOptions.map(createSelectOption);
};

const appHeaderLanguageFactory = {
  createSelectOptions,
};

export default appHeaderLanguageFactory;
