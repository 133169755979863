import OrderFinishedListingFilter, {
  OrderFinishedListingCargoCompanyExternalOrderIdFilter,
  OrderFinishedListingCargoCompanyInternalOrderIdFilter,
  OrderFinishedListingClientFilter,
  OrderFinishedListingDispatchFilter,
  OrderFinishedListingDriverFilter,
  OrderFinishedListingPassengerFilter,
  OrderFinishedListingPublicStatusFilter,
  OrderFinishedListingRouteAddressFilter,
  OrderFinishedListingRouteDestinationAddressFilter,
  OrderFinishedListingRouteIntermediateAddressFilter,
  OrderFinishedListingRoutePickupAddressFilter,
  OrderFinishedListingExecutionStatusFilter,
  OrderFinishedListingTaxiFilter,
  OrderFinishedListingUrgentOnlyFilter,
  OrderFinishedListingOrderStartDateFilter,
} from "./types/order-finished-listing-filter";
import OrderFinishedListingFilterType from "./types/order-finished-listing-filter-type";
import OrderFinishedListingPublicStatus from "./types/order-finished-listing-public-status";
import OrderFinishedListingSortKey from "./types/order-finished-listing-sort-key";
import OrderFinishedListingExecutionStatus from "./types/order-finished-listing-execution-status";
import CargoOrderFinishedListReportLoadParams, {
  CargoOrderFinishedListReportLoadParamsExecutionStatus,
  CargoOrderFinishedListReportLoadParamsPublicStatus,
  CargoOrderFinishedListReportLoadParamsSortKey,
} from "../../../../common/services/cargo-order/finished-list/report/cargo-order-finished-list-report-load-params";

const createOrder = (
  sortKey: OrderFinishedListingSortKey | null
): CargoOrderFinishedListReportLoadParamsSortKey | undefined => {
  const definition: Record<
    OrderFinishedListingSortKey,
    CargoOrderFinishedListReportLoadParamsSortKey
  > = {
    [OrderFinishedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC]:
      CargoOrderFinishedListReportLoadParamsSortKey.INTERNAL_ORDER_ID_ASC,
    [OrderFinishedListingSortKey.CARGO_COMPANY_INTERNAL_ID_DESC]:
      CargoOrderFinishedListReportLoadParamsSortKey.INTERNAL_ORDER_ID_DESC,
    [OrderFinishedListingSortKey.START_TIME_ASC]:
      CargoOrderFinishedListReportLoadParamsSortKey.ORDER_START_DATE_ASC,
    [OrderFinishedListingSortKey.START_TIME_DESC]:
      CargoOrderFinishedListReportLoadParamsSortKey.ORDER_START_DATE_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const createPublicStatus = (
  status: OrderFinishedListingPublicStatus | undefined
): CargoOrderFinishedListReportLoadParamsPublicStatus | undefined => {
  switch (status) {
    case OrderFinishedListingPublicStatus.PRIVATE:
      return CargoOrderFinishedListReportLoadParamsPublicStatus.PRIVATE;
    case OrderFinishedListingPublicStatus.PUBLISHED:
      return CargoOrderFinishedListReportLoadParamsPublicStatus.PUBLISHED;
    case OrderFinishedListingPublicStatus.PUBLIC:
      return CargoOrderFinishedListReportLoadParamsPublicStatus.PUBLIC;
    default:
      return undefined;
  }
};

const createExecutionStatus = (
  status: OrderFinishedListingExecutionStatus | undefined
): CargoOrderFinishedListReportLoadParamsExecutionStatus | undefined => {
  switch (status) {
    case OrderFinishedListingExecutionStatus.CANCELLED:
      return CargoOrderFinishedListReportLoadParamsExecutionStatus.CANCELLED;
    case OrderFinishedListingExecutionStatus.FINISHED:
      return CargoOrderFinishedListReportLoadParamsExecutionStatus.FINISHED;

    default:
      return undefined;
  }
};

const create = (
  filters: OrderFinishedListingFilter[],
  sortKey: OrderFinishedListingSortKey | null
): CargoOrderFinishedListReportLoadParams => {
  const publicStatusFilter = filters.find(
    (filter) => filter.type === OrderFinishedListingFilterType.PUBLIC_STATUS
  )?.value as OrderFinishedListingPublicStatusFilter["value"] | undefined;

  const executionStatusFilter = filters.find(
    (filter) => filter.type === OrderFinishedListingFilterType.EXECUTION_STATUS
  )?.value as OrderFinishedListingExecutionStatusFilter["value"] | undefined;

  const urgentOnlyFilter = filters.find(
    (filter) => filter.type === OrderFinishedListingFilterType.URGENT_ONLY
  )?.value as OrderFinishedListingUrgentOnlyFilter["value"] | undefined;

  const orderStartDateFilterValue = filters.find(
    (filter) => filter.type === OrderFinishedListingFilterType.ORDER_START_DATE
  )?.value as OrderFinishedListingOrderStartDateFilter["value"] | undefined;

  return {
    sortKey: createOrder(sortKey),
    client: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.CLIENT
    )?.value as OrderFinishedListingClientFilter["value"] | undefined,
    internalOrderId: filters.find(
      (filter) =>
        filter.type ===
        OrderFinishedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID
    )?.value as
      | OrderFinishedListingCargoCompanyInternalOrderIdFilter["value"]
      | undefined,
    externalOrderId: filters.find(
      (filter) =>
        filter.type ===
        OrderFinishedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID
    )?.value as
      | OrderFinishedListingCargoCompanyExternalOrderIdFilter["value"]
      | undefined,
    taxi: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.TAXI
    )?.value as OrderFinishedListingTaxiFilter["value"] | undefined,
    dispatch: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.DISPATCH
    )?.value as OrderFinishedListingDispatchFilter["value"] | undefined,
    pickupAddress: filters.find(
      (filter) =>
        filter.type === OrderFinishedListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | OrderFinishedListingRoutePickupAddressFilter["value"]
      | undefined,
    intermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        OrderFinishedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | OrderFinishedListingRouteIntermediateAddressFilter["value"]
      | undefined,
    destinationAddress: filters.find(
      (filter) =>
        filter.type === OrderFinishedListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | OrderFinishedListingRouteDestinationAddressFilter["value"]
      | undefined,
    address: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.ROUTE_ADDRESS
    )?.value as OrderFinishedListingRouteAddressFilter["value"] | undefined,
    passenger: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.PASSENGER
    )?.value as OrderFinishedListingPassengerFilter["value"] | undefined,
    driver: filters.find(
      (filter) => filter.type === OrderFinishedListingFilterType.DRIVER
    )?.value as OrderFinishedListingDriverFilter["value"] | undefined,
    publicStatus: createPublicStatus(publicStatusFilter),
    executionStatus: createExecutionStatus(executionStatusFilter),
    isUrgent: urgentOnlyFilter,
    orderStartDateFrom: orderStartDateFilterValue?.from ?? undefined,
    orderStartDateTo: orderStartDateFilterValue?.to ?? undefined,
  };
};

const orderFinishedListingReportLoadParamsFactory = {
  create,
};

export default orderFinishedListingReportLoadParamsFactory;
