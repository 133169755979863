import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import BillingModel from "../../../../common/types/billing-model";
import TaxiTransferedOrderSettlementListingItemBillingStatus from "../types/taxi-transfered-order-settlement-listing-item-billing-status";

const getBillingStatusOptions = () => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .status;

  const options: {
    text: string;
    status: TaxiTransferedOrderSettlementListingItemBillingStatus;
  }[] = [
    {
      text: translations.CREATED,
      status: TaxiTransferedOrderSettlementListingItemBillingStatus.CREATED,
    },
    {
      text: translations.ACCEPTED,
      status: TaxiTransferedOrderSettlementListingItemBillingStatus.ACCEPTED,
    },
    {
      text: translations.REJECTED,
      status: TaxiTransferedOrderSettlementListingItemBillingStatus.REJECTED,
    },
    {
      text: translations.REOPENED,
      status: TaxiTransferedOrderSettlementListingItemBillingStatus.REOPENED,
    },
    {
      text: translations.REOPEN_REQUEST,
      status:
        TaxiTransferedOrderSettlementListingItemBillingStatus.REOPEN_REQUEST,
    },
  ];

  return options;
};

const getBillingStatusText = (
  status: TaxiTransferedOrderSettlementListingItemBillingStatus
): string => {
  const options = getBillingStatusOptions();

  return options.find((option) => option.status === status)?.text ?? "";
};

const getBillingModelOptions = () => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .model;

  const options: {
    text: string;
    billingModel: BillingModel;
  }[] = [
    {
      text: translations.AB,
      billingModel: BillingModel.AB,
    },
    {
      text: translations.ABA,
      billingModel: BillingModel.ABA,
    },
    {
      text: translations.OTHER,
      billingModel: BillingModel.OTHER,
    },
    {
      text: translations.SABS,
      billingModel: BillingModel.SABS,
    },
  ];

  return options;
};

const getBillingModelText = (type: BillingModel): string => {
  const options = getBillingModelOptions();

  return options.find((option) => option.billingModel === type)?.text ?? "";
};

const taxiTransferedOrderSettlementListingHelper = {
  getBillingStatusOptions,
  getBillingStatusText,
  getBillingModelOptions,
  getBillingModelText,
};

export default taxiTransferedOrderSettlementListingHelper;
