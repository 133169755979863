import { ContainerProps, GroupBase, components } from "react-select";
import SelectOption from "../option/select-option";
import { FC } from "react";

type SelectContainerProps = ContainerProps<
  SelectOption,
  boolean,
  GroupBase<SelectOption>
> & {
  idForTesting: string | undefined;
};

const SelectContainerComponent: FC<SelectContainerProps> = (props) => {
  const { idForTesting, ...propsRest } = props;

  return (
    <components.SelectContainer
      {...propsRest}
      innerProps={Object.assign({}, props.innerProps, {
        "data-test-id": idForTesting,
      })}
    />
  );
};

export default SelectContainerComponent;
