import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import driverEditUserDataFormValidationService from "../../common/user/driver-edit-user-data-form-validation.service";
import DriverEditByRailyTaxiCorporationSelectOption from "../common/types/driver-edit-by-raily-taxi-corporation-select-option";

const validateTaxiCorporation = (
  taxiCorporation: DriverEditByRailyTaxiCorporationSelectOption | null
): FormValidationResult => {
  const validationSchema = Joi.object().required();

  return formValidationService.validate(taxiCorporation, validationSchema);
};

const driverEditByRailyUserDataFormValidationService = {
  validateFirstName: driverEditUserDataFormValidationService.validateFirstName,
  validateLastName: driverEditUserDataFormValidationService.validateLastName,
  validateMobile: driverEditUserDataFormValidationService.validateMobile,
  validateAlternativeMobile:
    driverEditUserDataFormValidationService.validateAlternativeMobile,
  validateBirthDate: driverEditUserDataFormValidationService.validateBirthDate,
  validateBirthPlace:
    driverEditUserDataFormValidationService.validateBirthPlace,
  validatePersonalIdNumber:
    driverEditUserDataFormValidationService.validatePersonalIdNumber,
  validateCitizenship:
    driverEditUserDataFormValidationService.validateCitizenship,
  validateLanguages: driverEditUserDataFormValidationService.validateLanguages,
  validateYearsOfExperience:
    driverEditUserDataFormValidationService.validateYearsOfExperience,
  validateFormOfEmployment:
    driverEditUserDataFormValidationService.validateFormOfEmployment,
  validateFleetPartner:
    driverEditUserDataFormValidationService.validateFleetPartner,
  validateAddresses: driverEditUserDataFormValidationService.validateAddresses,
  validateTaxiCorporation,
};

export default driverEditByRailyUserDataFormValidationService;
