import { useEffect, useState } from "react";
import { TaxiTranferedOrderSettlementStatusLegendOption } from "../../types/taxi-transfered-order-settlement-status-legend-option";
import useTransferredOrderStatusCount from "../../../../../../../common/services/billings/order/transferred-order-status-count/use-transferred-order-status-count.hook";
import TransferredOrderStatusCount from "../../../../../../../common/services/billings/order/transferred-order-status-count/transferred-order-status-count.model";
import useAbort from "../../../../../../../common/hooks/use-abort";
import StatusLegendStatuses from "../../../../../../../common/components/status-legend/types/status-legend-statuses";
import TaxiTransferedOrderSettlementListingItemBillingStatus from "../../types/taxi-transfered-order-settlement-listing-item-billing-status";

export const useTaxiTransferedOrderSettlementStatuses = () => {
  const [statusOptions, setStatusOptions] = useState<
    TaxiTranferedOrderSettlementStatusLegendOption[]
  >([]);
  const { data, load } = useTransferredOrderStatusCount();
  const abort = useAbort();

  const taxiTransferredOrderStatusesData = async () => {
    load(abort.signal);
  };

  useEffect(() => {
    if (!data) {
      taxiTransferredOrderStatusesData();
    } else {
      setStatusOptions(getBillingStatusOptions(data));
    }
  }, [data]);

  return {
    statusOptions,
  };
};

const getBillingStatusOptions = (
  responseData: TransferredOrderStatusCount
): TaxiTranferedOrderSettlementStatusLegendOption[] => {
  const options: TaxiTranferedOrderSettlementStatusLegendOption[] = [
    {
      status: TaxiTransferedOrderSettlementListingItemBillingStatus.CREATED,
      totalCount: responseData.created,
    },
    {
      status: TaxiTransferedOrderSettlementListingItemBillingStatus.ACCEPTED,
      totalCount: responseData.accepted,
    },
    {
      status: TaxiTransferedOrderSettlementListingItemBillingStatus.REJECTED,
      totalCount: responseData.rejected,
    },
    {
      status: TaxiTransferedOrderSettlementListingItemBillingStatus.REOPENED,
      totalCount: responseData.reopened,
    },
    {
      status:
        TaxiTransferedOrderSettlementListingItemBillingStatus.REOPEN_REQUEST,
      totalCount: responseData.reopenRequest,
    },
  ];

  return options;
};
