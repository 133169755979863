import dateService from "../../../../../common/utils/date/date.service";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return ``;
  }

  return dateService.formatDateTime(date);
};

const getCandidatureActivityText = (isCandidatureActive: boolean): string => {
  const translations = userTranslationsHelper.getDriverListingTranslations();

  return isCandidatureActive
    ? translations.activeCandidatureLabel
    : translations.inactiveCandidatureLabel;
};

const driverListingHelper = {
  getDateLabel,
  getCandidatureActivityText,
};

export default driverListingHelper;
