import DataLoadError, {
  DataLoadDefaultErrors,
} from "../../../utils/data-load-error/data-load-error";

export enum TransportingOrderDeleteForwardingErrorType {
  ABANDONED_ORDER = "ABANDONED_ORDER",
  DRIVER_ASSIGNED = "DRIVER_ASSIGNED ",
}

export type TransportingOrderDeleteForwardingAbandonedOrderError =
  DataLoadError<TransportingOrderDeleteForwardingErrorType.ABANDONED_ORDER, {}>;

export type TransportingOrderDeleteForwardingDriverAssignedError =
  DataLoadError<TransportingOrderDeleteForwardingErrorType.DRIVER_ASSIGNED, {}>;

type TransportingOrderDeleteForwardingError =
  | TransportingOrderDeleteForwardingAbandonedOrderError
  | TransportingOrderDeleteForwardingDriverAssignedError
  | DataLoadDefaultErrors;

export default TransportingOrderDeleteForwardingError;
