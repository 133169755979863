import railyApiService from "../raily-api.service";
import cargoTaxiContractAddRequestFactory from "./add/cargo-taxi-contract-add-request.factory";
import CargoTaxiContractAddRequest, {
  CargoTaxiContractAddRequestBody,
} from "./add/cargo-taxi-contract-add.request";
import CargoTaxiContractAddResponse from "./add/cargo-taxi-contract-add.response";
import CargoTaxiContractDetailsRequest from "./details/cargo-taxi-contract-details.request";
import CargoTaxiContractDetailsResponse from "./details/cargo-taxi-contract-details.response";
import cargoTaxiContractUpdateRequestFactory from "./update/cargo-taxi-contract-update-request.factory";
import CargoTaxiContractUpdateRequest, {
  CargoTaxiContractUpdateRequestBody,
} from "./update/cargo-taxi-contract-update.request";
import CargoTaxiContractUpdateResponse from "./update/cargo-taxi-contract-update.response";

const cargoTaxiContractApiService = () => {
  const add = (
    request: CargoTaxiContractAddRequest,
    abortSignal: AbortSignal
  ): Promise<CargoTaxiContractAddResponse> => {
    const url = `/contracts/cargo-taxi`;

    const body: CargoTaxiContractAddRequestBody =
      cargoTaxiContractAddRequestFactory.createBody(request);

    return railyApiService().post<CargoTaxiContractAddResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const update = (
    request: CargoTaxiContractUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<CargoTaxiContractUpdateResponse> => {
    const url = `/contracts/cargo-taxi/${request.contractId}`;

    const body: CargoTaxiContractUpdateRequestBody =
      cargoTaxiContractUpdateRequestFactory.createBody(request);

    return railyApiService().put<CargoTaxiContractUpdateResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const getDetails = (
    request: CargoTaxiContractDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<CargoTaxiContractDetailsResponse> => {
    const url = `/contracts/cargo-taxi/${request.contractId}`;

    return railyApiService().get<CargoTaxiContractDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return {
    add,
    update,
    getDetails,
  };
};

export default cargoTaxiContractApiService;
