import phoneNumberInputHelper from "../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import DispatcherEditForm from "../../../../../common/services/dispatcher/edit-form/dispatcher-edit-form";
import DispatcherEditFormData from "./types/dispatcher-edit-form-data";

const create = (editFormData: DispatcherEditForm): DispatcherEditFormData => {
  return {
    alternativePhoneNumber:
      editFormData.alternativePhoneNumber ??
      phoneNumberInputHelper.getEmptyValue(),
    dispatchUuid: editFormData.dispatch.uuid,
    email: editFormData.email,
    firstName: editFormData.firstName,
    lastName: editFormData.lastName,
    phoneNumber: editFormData.phoneNumber,
    username: editFormData.username,
  };
};

const dispatcherEditFormDataFactory = {
  create,
};

export default dispatcherEditFormDataFactory;
