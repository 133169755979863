enum BadgeType {
  PRIMARY = "PRIMARY",
  BRAND = "BRAND",
  DANGER = "DANGER",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export default BadgeType;
