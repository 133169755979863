import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import { UserDealerListingRouteQueryParams } from "../../../common/routes/types/user-dealer-listing-route-params";
import DealerListingFilter from "../common/types/dealer-listing-filter";
import DealerListingSortKey from "../common/types/dealer-listing-sort-key";
import dealerListingFiltersService from "./dealer-listing-filter.service";

const useDealerListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<UserDealerListingRouteQueryParams>();

  const getFilters = (): DealerListingFilter[] => {
    return dealerListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return dealerListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return dealerListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): DealerListingSortKey | undefined => {
    return dealerListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: DealerListingFilter[],
    sortKey: DealerListingSortKey | null,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams = dealerListingFiltersService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useDealerListingFiltersDao;
