import MileageDictionaryDetails from "../../../../../common/services/mileage-dictionary/details/mileage-dictionary-details";
import MileageDictionaryUpdateFormData from "./types/mileage-dictionary-update-form-data";

const createFormData = (
  details: MileageDictionaryDetails
): MileageDictionaryUpdateFormData => {
  return {
    active: details.active,
    name: details.name,
  };
};

const mileageDictionaryFormFactory = {
  createFormData,
};

export default mileageDictionaryFormFactory;
