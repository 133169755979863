enum CargoCompanyListingFilterType {
  TAX_NUMBER = "TAX_NUMBER",
  COMPANY_ID = "COMPANY_ID",
  NATIONAL_COURT_REGISTER = "NATIONAL_COURT_REGISTER",
  COMPANY_NAME = "COMPANY_NAME",
  COMPANY_DISPLAY_NAME = "COMPANY_DISPLAY_NAME",
  ADDRESS = "ADDRESS",
  NOTES = "NOTES",
}

export default CargoCompanyListingFilterType;
