import planningRoutesUrls from "./planning-routes-urls";
import PlanningDriverRouteParams from "./types/planning-driver-route-params";
import PlanningOrderRouteParams from "./types/planning-order-route-params";

const getOverviewRoute = () => {
  return planningRoutesUrls.overview;
};

const getDriverRoute = (params: PlanningDriverRouteParams) => {
  return planningRoutesUrls.driver.replace(":driverUuid", params.driverUuid);
};

const getOrderRoute = (params: PlanningOrderRouteParams) => {
  return planningRoutesUrls.order.replace(":orderUuid", params.orderUuid);
};

const planningRoutesHelper = {
  getOverviewRoute,
  getDriverRoute,
  getOrderRoute,
};

export default planningRoutesHelper;
