import TaxiOfficerEditByRailyFormData from "./types/taxi-officer-edit-by-raily-form-data";
import TaxiOfficerDetails from "../../../../../../common/services/taxi-officer/details/taxi-officer-details";

const create = (data: TaxiOfficerDetails): TaxiOfficerEditByRailyFormData => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: {
      dialingCode: data.phoneNumber?.dialingCode ?? "",
      number: data.phoneNumber?.number ?? "",
    },
    alternativePhoneNumber: {
      dialingCode: data.alternativePhoneNumber?.dialingCode ?? "",
      number: data.alternativePhoneNumber?.number ?? "",
    },
    taxiCorporationUuid: data.taxiCorporationUuid,
  };
};

const taxiOfficerEditByRailyFormDataFactory = {
  create,
};

export default taxiOfficerEditByRailyFormDataFactory;
