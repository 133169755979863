import { FC, useMemo } from "react";
import BillingFormData from "../types/billing-form.data";
import BonusType from "../types/bonus-type";
import PenaltyType from "../types/penalty-type";
import additionalOptionsFormHelper from "./additional-options-form.helper";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import NumericInputComponent from "../../../../../common/components/form/input/numeric-input/numeric-input.component";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import BillingRouteDetailsFormDataValidationResult from "../../../common/route-details/types/billings-route-details-form-data-validation-result";

type AdditionalOptionsProps = {
  billingData: BillingFormData | undefined;
  onPenaltyChanged: (penaltyValue: number) => void;
  onPenaltyTypeChanged: (penaltyType: PenaltyType) => void;
  onBonusChanged: (bonusValue: number) => void;
  onBonusTypeChanged: (bonusType: BonusType) => void;
  onBonusBlur: () => void;
  onPenaltyBlur: () => void;
  formValidationResults: BillingRouteDetailsFormDataValidationResult;
};

const AdditionalOptionsComponent: FC<AdditionalOptionsProps> = (props) => {
  const penaltyTypeSelectOptions: SelectOption<PenaltyType>[] = useMemo(() => {
    return additionalOptionsFormHelper.getPenaltyTypeOptions();
  }, []);

  const bonusTypeSelectOptions: SelectOption<BonusType>[] = useMemo(() => {
    return additionalOptionsFormHelper.getBonusTypeOptions();
  }, []);

  const onBonusChanged = (bonusValue: number | null) => {
    const value = bonusValue ?? 0;
    props.onBonusChanged(value);
  };

  const onBonusTypeChanged = (bonusType: BonusType) => {
    props.onBonusTypeChanged(bonusType);
  };

  const onPenaltyChanged = (penaltyValue: number | null) => {
    const value = penaltyValue ?? 0;
    props.onPenaltyChanged(value);
  };

  const onPenaltyTypeChanged = (penaltyType: PenaltyType) => {
    props.onPenaltyTypeChanged(penaltyType);
  };

  const translations =
    billingsTranslationsHelper.getRailyTaxiAddBillingsTranslations();

  return (
    <>
      <CardComponent
        classNames={{ root: "h-100", content: "route_details_additional" }}
      >
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.additionalOptions.penaltyLabel}
              classNames={{ root: `mt-0` }}
            >
              <NumericInputComponent
                placeholder={translations.additionalOptions.penaltyPlaceholder}
                value={props.billingData?.penalty ?? 0}
                onChange={onPenaltyChanged}
                decimalPrecision={2}
                onBlur={props.onPenaltyBlur}
                hasError={
                  !!props.formValidationResults?.penaltyValue?.errorMessage
                }
              />
              {!!props.formValidationResults?.penaltyValue?.errorMessage && (
                <span className={"route_details_error_message"}>
                  {props.formValidationResults?.penaltyValue.errorMessage}
                </span>
              )}
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.additionalOptions.penaltyReasonLabel}
              classNames={{ root: `mt-0` }}
            >
              <SingleSelectComponent
                placeholder={
                  translations.additionalOptions.penaltyReasonPlaceholder
                }
                value={
                  penaltyTypeSelectOptions.find(
                    (x) => x.value === props.billingData?.penaltyType
                  ) ?? null
                }
                onChange={(value) => onPenaltyTypeChanged(value?.value)}
                options={penaltyTypeSelectOptions}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.additionalOptions.bonusLabel}
              classNames={{ root: `mt-0` }}
            >
              <NumericInputComponent
                placeholder={translations.additionalOptions.bonusPlaceholder}
                value={props.billingData?.bonus ?? 0}
                onChange={onBonusChanged}
                decimalPrecision={2}
                onBlur={props.onBonusBlur}
              />
              {!!props.formValidationResults?.discountValue.errorMessage && (
                <span className={"route_details_error_message"}>
                  {props.formValidationResults?.discountValue.errorMessage}
                </span>
              )}
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.additionalOptions.bonusReasonLabel}
              classNames={{ root: `mt-0` }}
            >
              <SingleSelectComponent
                placeholder={
                  translations.additionalOptions.bonusReasonPlaceholder
                }
                value={
                  bonusTypeSelectOptions.find(
                    (x) => x.value === props.billingData?.bonusType
                  ) ?? null
                }
                onChange={(value) => onBonusTypeChanged(value?.value)}
                options={bonusTypeSelectOptions}
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
    </>
  );
};
export default AdditionalOptionsComponent;
