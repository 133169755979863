import {
  OrderDetailsChangeTaxiTaxiContractsResponseData,
  OrderDetailsChangeTaxiTaxiContractsResponseTaxiContract,
} from "./api/order-details-change-taxi-taxi-contracts.response";
import OrderDetailsChangeTaxiTaxiCorporationSelectOption from "./types/order-details-change-taxi-taxi-corporation-select-option";

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: OrderDetailsChangeTaxiTaxiContractsResponseTaxiContract["taxi_corporation"]
): OrderDetailsChangeTaxiTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      name: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationsSelectOptions = (
  taxiContractsResponseData: OrderDetailsChangeTaxiTaxiContractsResponseData
): OrderDetailsChangeTaxiTaxiCorporationSelectOption[] => {
  return taxiContractsResponseData.taxi_contracts.map((taxiContract) =>
    createTaxiCorporationSelectOption(taxiContract.taxi_corporation)
  );
};

const orderDetailsChangeTaxiFactory = {
  createTaxiCorporationsSelectOptions,
};

export default orderDetailsChangeTaxiFactory;
