import CargoCompanyListResponse, {
  CargoCompanyListResponseItemContractMaintenancePolicy,
  CargoCompanyListResponseItem,
} from "../../../../api/raily/cargo-company/cargo-company/list/cargo-company-list.response";
import CargoCompanyList, {
  CargoCompanyContractMaintenancePolicy,
  CargoCompanyListItem,
} from "./cargo-company-list";

const createContractMaintenancePolicy = (
  responseItemContractMaintenancePolicy: CargoCompanyListResponseItemContractMaintenancePolicy
): CargoCompanyContractMaintenancePolicy => {
  switch (responseItemContractMaintenancePolicy) {
    case CargoCompanyListResponseItemContractMaintenancePolicy.AUCTION:
      return CargoCompanyContractMaintenancePolicy.AUCTION;
    case CargoCompanyListResponseItemContractMaintenancePolicy.BASIC:
      return CargoCompanyContractMaintenancePolicy.BASIC;
  }
};

const createDataItem = (
  responseItem: CargoCompanyListResponseItem
): CargoCompanyListItem => {
  return {
    uuid: responseItem.id,
    address: responseItem.headquartersAddressString,
    companyId: responseItem.regon,
    nationalCourtRegister: responseItem.krsNo,
    contractMaintenancePolicy: createContractMaintenancePolicy(
      responseItem.contractMaintenancePolicy
    ),
    createdBy: responseItem.createdBy,
    creationDate: responseItem.createdAt,
    displayName: responseItem.displayName,
    modificationDate: responseItem.modifiedAt,
    modifiedBy: responseItem.modifiedBy,
    name: responseItem.companyName,
    notes: responseItem.notes,
    taxNumber: responseItem.taxId,
  };
};

const createData = (
  responseData: CargoCompanyListResponseItem[]
): CargoCompanyListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: CargoCompanyListResponse): CargoCompanyList => {
  return {
    data: createData(response.data),
  };
};

const cargoCompanyListFactory = {
  create,
};

export default cargoCompanyListFactory;
