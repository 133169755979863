import ContractValidCollectionRequest from "../../../api/raily/contracts/valid-collection/contract-valid-collection.request";
import ContractValidCollectionLoadParams from "./contract-valid-collection-load-params";

const create = (
  params: ContractValidCollectionLoadParams
): ContractValidCollectionRequest => {
  return {
    cargoCompanyId: params.cargoCompanyUuid,
  };
};

const contractValidCollectionRequestFactory = {
  create,
};

export default contractValidCollectionRequestFactory;
