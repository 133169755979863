import { FC } from "react";
import { OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent } from "../../../common/types/order-details-handling-log-entry-event";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import orderDetailsHistoryEntryAssignmentOrderExistingPlanContentHelper from "./order-details-history-entry-assignment-order-existing-plan-content.helper";

type OrderDetailsHistoryEntryAssignmentOrderNewPlanContentProps = {
  event: OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent;
};

const OrderDetailsHistoryEntryAssignmentOrderNewPlanContentComponent: FC<
  OrderDetailsHistoryEntryAssignmentOrderNewPlanContentProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .assignmentOrderExistingPlan;

  const routeLabel =
    orderDetailsHistoryEntryAssignmentOrderExistingPlanContentHelper.getRouteLabel(
      props.event.planEntryUuid
    );

  const taxiCorporationLabel =
    orderDetailsHistoryEntryAssignmentOrderExistingPlanContentHelper.getTaxiCorporationName(
      props.event.taxiCorporationName
    );

  const driverName =
    orderDetailsHistoryEntryAssignmentOrderExistingPlanContentHelper.getDriverName(
      props.event.driverName
    );

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={routeLabel}
      >
        {routeLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={taxiCorporationLabel}
      >
        {taxiCorporationLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={driverName}
      >
        {driverName}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryAssignmentOrderNewPlanContentComponent;
