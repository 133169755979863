import { FC, ReactNode, useEffect, useMemo } from "react";
import useAbort from "../../../../common/hooks/use-abort";
import { useAppContext } from "../../../../context/app.context";
import useTaxiFleetPartnerListingUserPermissions from "./common/user-permissions/use-taxi-fleet-partner-listing-user-permissions";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import { useParams } from "react-router";
import TaxiFleetPartnerListingRouteParams from "../../common/routes/types/taxi-fleet-partner-listing-route-params";
import TaxiFleetPartnerListingItem from "./common/types/taxi-fleet-partner-listing-item";
import taxiFleetPartnerListingItemFactory from "./common/taxi-fleet-partner-listing-item.factory";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import TaxiFleetPartnerListingTableComponent from "./table/taxi-fleet-partner-listing-table.component";
import taxiFleetPartnerListingHelper from "./common/taxi-fleet-partner-listing.helper";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import useClientSidePagination from "../../../../common/hooks/use-clientside-pagination";
import useTaxiCorporationDetails from "../../../../common/services/taxi-corporation/details/use-taxi-corporation-details";
import TaxiCorporationDetailsLoadParams from "../../../../common/services/taxi-corporation/details/taxi-corporation-details-load-params";
import useTaxiFleetPartnerList from "../../../../common/services/taxi-fleet-partner/list/use-taxi-fleet-partner-list";
import TaxiFleetPartnerListLoadParams from "../../../../common/services/taxi-fleet-partner/list/taxi-fleet-partner-list-load-params";

type TaxiFleetPartnerListingProps = {};

const TaxiFleetPartnerListingComponent: FC<
  TaxiFleetPartnerListingProps
> = () => {
  const taxiDetailsAbort = useAbort();
  const listingItemsAbort = useAbort();

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { taxiCorporationUuid } =
    useParams<TaxiFleetPartnerListingRouteParams>();

  const userPermissions = useTaxiFleetPartnerListingUserPermissions();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.taxiFleetPartnerListing);

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    const breadcrumbs = taxiBreadcrumbsHelper.getFleetPartnerListingBreadcrumbs(
      {
        taxiCorporationUuid,
      }
    );

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const taxiDetails = useTaxiCorporationDetails();

  const fleetPartnerList = useTaxiFleetPartnerList();

  const loadTaxiDetails = async () => {
    if (!taxiCorporationUuid) {
      return;
    }

    const params: TaxiCorporationDetailsLoadParams = { taxiCorporationUuid };

    taxiDetails.load(params, taxiDetailsAbort.signal);
  };

  const loadListingItems = async () => {
    if (!taxiCorporationUuid) {
      return;
    }

    const params: TaxiFleetPartnerListLoadParams = { taxiCorporationUuid };

    fleetPartnerList.load(params, listingItemsAbort.signal);
  };

  const taxiCorporationName = useMemo(() => {
    return taxiDetails.data?.name;
  }, [taxiDetails.data]);

  const listingItems: TaxiFleetPartnerListingItem[] = useMemo(() => {
    return taxiFleetPartnerListingItemFactory.createListingItems(
      fleetPartnerList.data
    );
  }, [fleetPartnerList.data]);

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: listingItems,
      totalResults: listingItems.length,
    });

  const translations =
    taxiTranslationsHelper.getTaxiFleetPartnerListingTranslations();

  useEffect(() => {
    loadTaxiDetails();
  }, [taxiCorporationUuid]);

  useEffect(() => {
    loadListingItems();
  }, [taxiCorporationUuid]);

  const TaxiFleetPartnerAddLinkButton = (
    <LinkButtonComponent
      type="primary"
      to={taxiRoutesHelper.getFleetPartnerAddRoute({
        taxiCorporationUuid: taxiCorporationUuid!,
      })}
      title={translations.header.addFleetPartnerLinkButtonTitle}
      idForTesting="taxi-fleet-partner-listing-add"
    >
      {translations.header.addFleetPartnerLinkButtonLabel}
    </LinkButtonComponent>
  );

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasAccessToAddTaxiFleetPartner
        ? TaxiFleetPartnerAddLinkButton
        : undefined,
    ];
  };

  const headingActions = createHeadingActions();

  return (
    <>
      <HeadingComponent
        title={taxiFleetPartnerListingHelper.getHeadingLabel(
          taxiCorporationName
        )}
        actions={headingActions}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TaxiFleetPartnerListingTableComponent
          listingItems={pageData}
          isError={fleetPartnerList.isError}
          isLoading={fleetPartnerList.isLoading}
          taxiCorporationUuid={taxiCorporationUuid!}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={totalResults}
        />
      </CardComponent>
    </>
  );
};

export default TaxiFleetPartnerListingComponent;
