import { RouteFinishedListItem } from "../../../../../common/services/route/finished-list/route-finished-list";
import RouteFinishedListingItem from "../types/route-finished-listing-item";

const createListingItem = (
  listItem: RouteFinishedListItem
): RouteFinishedListingItem => {
  return {
    uuid: listItem.uuid,
    cargoOrderHumanIds: listItem.cargoOrderHumanIds,
    driverName: listItem.driverName,
    passengers: listItem.passengers,
    routeAddresses: listItem.routeAddresses,
    humanId: listItem.humanId,
    startDate: listItem.startTime,
    taxiCorporationName: listItem.taxiCorporationName,
  };
};

const createListingItems = (
  listItems: RouteFinishedListItem[]
): RouteFinishedListingItem[] => {
  return listItems.map(createListingItem);
};

const routeFinishedListingItemFactory = {
  createListingItems,
};

export default routeFinishedListingItemFactory;
