import Joi from "joi";
import DriverContractAddByRailyFormData from "./common/types/driver-contract-add-by-raily-form-data";
import driverContractAddFormValidationService from "../common/driver-contract-add-form-validation.service";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";

const validateTaxiAssociation = (
  taxiAssociation: DriverContractAddByRailyFormData["taxiAssociation"]
) => {
  const validationSchema = Joi.object().required();

  return formValidationService.validate(taxiAssociation, validationSchema);
};

const driverContractAddByRailyFormValidationService = {
  validateTaxiAssociation,
  validateBillingModel:
    driverContractAddFormValidationService.validateBillingModel,
  validateDistanceRate:
    driverContractAddFormValidationService.validateDistanceRate,
  validateHaltingTimeGracePeriodMinutes:
    driverContractAddFormValidationService.validateHaltingTimeGracePeriodMinutes,
  validateHaltingTimeRate:
    driverContractAddFormValidationService.validateHaltingTimeRate,
  validatePeriodOfValidity:
    driverContractAddFormValidationService.validatePeriodOfValidity,
  validateIsActive: driverContractAddFormValidationService.validateIsActive,
  validateNotes: driverContractAddFormValidationService.validateNotes,
};

export default driverContractAddByRailyFormValidationService;
