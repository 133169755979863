import SignUpDealerFormData from "./types/sign-up-dealer-form-data";
import SignUpDealerRequest from "./api/sign-up-dealer.request";
import SignUpDealerTokenPayload from "../../common/types/sign-up-dealer-token-payload";
import SignUpDealerDisabledFormFields from "./types/sign-up-dealer-disabled-form-fields";

const createSignUpRequest = (
  formData: SignUpDealerFormData,
  taxiCorporationId: string
): SignUpDealerRequest => ({
  first_name: formData.firstName.trim(),
  last_name: formData.lastName.trim(),
  mobile_primary: formData.mobile.number!,
  mobile_primary_prefix: formData.mobile.dialingCode!,
  mobile_secondary: formData.alternativeMobile.number,
  mobile_secondary_prefix: formData.alternativeMobile.dialingCode,
  email: formData.email.trim(),
  username: formData.username.trim(),
  password: formData.password,
  dealer: {
    taxi_corporation_id: taxiCorporationId,
  },
});

const createFormData = (
  tokenPayload: SignUpDealerTokenPayload
): SignUpDealerFormData => {
  return {
    email: tokenPayload.user_data.email ?? "",
    password: "",
    passwordRepeat: "",
    username: tokenPayload.user_data.username ?? "",
    firstName: tokenPayload.user_data.first_name ?? "",
    lastName: tokenPayload.user_data.last_name ?? "",
    mobile: {
      dialingCode: tokenPayload.user_data.mobile_primary_prefix ?? "",
      number: tokenPayload.user_data.mobile_primary ?? "",
    },
    alternativeMobile: {
      dialingCode: tokenPayload.user_data.mobile_secondary_prefix ?? "",
      number: tokenPayload.user_data.mobile_secondary ?? "",
    },
  };
};

const createDisabledFormFieldsConfig = (
  tokenPayload: SignUpDealerTokenPayload
): SignUpDealerDisabledFormFields => {
  return {
    email: !!tokenPayload.user_data.email,
    firstName: !!tokenPayload.user_data.first_name,
    lastName: !!tokenPayload.user_data.last_name,
    username: !!tokenPayload.user_data.username,
    mobile:
      !!tokenPayload.user_data.mobile_primary_prefix &&
      !!tokenPayload.user_data.mobile_primary,
    alternativeMobile:
      !!tokenPayload.user_data.mobile_secondary_prefix &&
      !!tokenPayload.user_data.mobile_secondary,
  };
};

const signUpDealerFactory = {
  createFormData,
  createDisabledFormFieldsConfig,
  createSignUpRequest,
};

export default signUpDealerFactory;
