import HttpResponse from "../../../../../utils/http/http.response";

export enum CargoCompanyListResponseItemContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type CargoCompanyListResponseItem = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: CargoCompanyListResponseItemContractMaintenancePolicy;
  notes: string | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
};

type CargoCompanyListResponse = HttpResponse<CargoCompanyListResponseItem[]>;

export default CargoCompanyListResponse;
