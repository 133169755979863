import railyApiService from "../raily-api.service";
import mileageSettingsAddRequestFactory from "./add/mileage-settings-add-request.factory";
import MileageSettingsAddRequest, {
  MileageSettingsAddRequestBody,
} from "./add/mileage-settings-add.request";
import MileageSettingsAddResponse from "./add/mileage-settings-add.response";
import mileageSettingsDetailsRequestFactory from "./details/mileage-settings-details-request.factory";
import MileageSettingsDetailsRequest, {
  MileageSettingsDetailsRequestQueryParams,
} from "./details/mileage-settings-details.request";
import MileageSettingsDetailsResponse from "./details/mileage-settings-details.response";
import mileageSettingsupdateRequestFactory from "./update/mileage-settings-update-request.factory";
import MileageSettingsUpdateRequest, {
  MileageSettingsUpdateRequestBody,
} from "./update/mileage-settings-update.request";
import MileageSettingsUpdateResponse from "./update/mileage-settings-update.response";

const mileageSettingsApiService = () => {
  const add = async (
    request: MileageSettingsAddRequest,
    abortSignal: AbortSignal
  ): Promise<MileageSettingsAddResponse> => {
    const url = "/mileage/config";

    const body: MileageSettingsAddRequestBody =
      mileageSettingsAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const getDetails = async (
    request: MileageSettingsDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<MileageSettingsDetailsResponse> => {
    const url = `/mileage/config/${request.cargoCompanyUuid}`;

    const queryParams: MileageSettingsDetailsRequestQueryParams =
      mileageSettingsDetailsRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  const update = async (
    request: MileageSettingsUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<MileageSettingsUpdateResponse> => {
    const url = `/mileage/config/${request.cargoCompanyUuid}`;

    const body: MileageSettingsUpdateRequestBody =
      mileageSettingsupdateRequestFactory.createbody(request);

    return railyApiService().put({
      url,
      abortSignal,
      body,
    });
  };

  return {
    add,
    getDetails,
    update,
  };
};

export default mileageSettingsApiService;
