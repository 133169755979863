import orderJoinHelper from "../../order-join.helper";
import {
  OrderJoinOrderDetailsResponseData,
  OrderJoinPreviewResponseDriver,
  OrderJoinOrderDetailsResponseTransportingOrderCreatePlanEntry,
  OrderJoinSourceType,
  OrderJoinExternalCargoItemSourceData,
} from "../api/order-join-order-details.response";
import OrderJoinOrderDetailsPassenger from "../types/order-join-order-details-passenger";
import OrderJoinOrderDetails from "../types/order-join-order-details";
import orderJoinOrderDetailsWaypointFactory from "./order-join-order-details-waypoint.factory";
import { OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint } from "../api/order-join-order-details-solved-rides.response";
import { SearchRoadRoutingResponseRouteLeg } from "../../../../../common/utils/search-road-route/search-road-routing.response";
import OrderJoinOrderDetailsDriver, {
  OrderJoinOrderDetailsDriverRole,
} from "../types/order-join-order-details-driver";

const createExternalPassengersList = (
  responseData: OrderJoinOrderDetailsResponseData
): OrderJoinOrderDetailsPassenger[] => {
  const externalPassengersList: OrderJoinOrderDetailsPassenger[] = [];

  const externalSubjects =
    responseData.cargo_order.cargo_items_source_list.filter(
      (sourceItem) =>
        sourceItem.cargo_item_source.source_type ===
        OrderJoinSourceType.EXTERNAL
    );

  if (externalSubjects.length) {
    externalSubjects.forEach((subject) => {
      const subjectId = subject.cargo_subject_id;
      const subjectData =
        subject.cargo_item_source as OrderJoinExternalCargoItemSourceData;

      const externalPassenger: OrderJoinOrderDetailsPassenger = {
        uuid: subjectId,
        name: `${subjectData.first_name} ${subjectData.last_name}`,
      };

      externalPassengersList.push(externalPassenger);
    });
  }

  return externalPassengersList;
};

const createInternalPassengersList = (
  responseData: OrderJoinOrderDetailsResponseData
): OrderJoinOrderDetailsPassenger[] => {
  return responseData.passengers.map((passenger) => ({
    uuid: passenger.id,
    name: `${passenger.first_name} ${passenger.last_name}`,
  }));
};

const createPassengers = (
  responseData: OrderJoinOrderDetailsResponseData
): OrderJoinOrderDetailsPassenger[] => {
  const externalPassengersList = createExternalPassengersList(responseData);
  const internalPassengersList = createInternalPassengersList(responseData);

  return [...internalPassengersList, ...externalPassengersList];
};

const createAssignedDriver = (
  responseDriver: OrderJoinPreviewResponseDriver
): OrderJoinOrderDetailsDriver => {
  return {
    uuid: responseDriver.id,
    firstName: responseDriver.first_name,
    lastName: responseDriver.last_name,
    displayName: `${responseDriver.first_name} ${responseDriver.last_name}`,
    phoneNumber: `${responseDriver.mobile_primary_prefix} ${responseDriver.mobile_primary}`,
    role: OrderJoinOrderDetailsDriverRole.ASSIGNED,
  };
};

const createCandidateDriver = (
  responseCanidateDriver: OrderJoinOrderDetailsResponseTransportingOrderCreatePlanEntry["driver"]
): OrderJoinOrderDetailsDriver => {
  return {
    uuid: responseCanidateDriver.id,
    firstName: responseCanidateDriver.first_name,
    lastName: responseCanidateDriver.last_name,
    displayName: `${responseCanidateDriver.first_name} ${responseCanidateDriver.last_name}`,
    phoneNumber: `${responseCanidateDriver.mobile_primary_prefix} ${responseCanidateDriver.mobile_primary}`,
    role: OrderJoinOrderDetailsDriverRole.CANDIDATE,
  };
};

const createDrivers = (
  responseDrivers: OrderJoinPreviewResponseDriver[],
  responseCandidateDrivers: OrderJoinOrderDetailsResponseTransportingOrderCreatePlanEntry["driver"][]
) => {
  const assignedDrivers = responseDrivers.map(createAssignedDriver);
  const candidateDrivers = responseCandidateDrivers.map(createCandidateDriver);

  const drivers: OrderJoinOrderDetailsDriver[] = [
    ...assignedDrivers,
    ...candidateDrivers,
  ];

  return drivers;
};

const createOrderDetails = (
  data: OrderJoinOrderDetailsResponseData,
  responseCargoOrderSolvedRides:
    | OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint[]
    | null,
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[]
): OrderJoinOrderDetails => {
  const isOrderSolved = orderJoinHelper.isOrderSolved(
    data.cargo_order.transporting_orders ?? []
  );

  const responseCandidateDrivers = data.cargo_order.transporting_orders
    .map((transportingOrder) =>
      transportingOrder.create_plan_entry_entries.map((p) => p.driver)
    )
    .flat();

  const result = {
    uuid: data.cargo_order.id,
    routeWaypoints: isOrderSolved
      ? orderJoinOrderDetailsWaypointFactory.createWaypointsForSolvedRoute(
          data.cargo_order.transporting_orders,
          responseRouteLegs
        )
      : orderJoinOrderDetailsWaypointFactory.createWaypointsForOrderedRoute(
          data.cargo_order.ride,
          responseRouteLegs,
          responseCargoOrderSolvedRides ?? []
        ),
    passengers: createPassengers(data),
    drivers: createDrivers(data.drivers, responseCandidateDrivers),
    orderId: data.cargo_order.human_id,
    isOrderSolved: isOrderSolved,
    nodeExclusion: {
      excludeApproaching: data.node_exclusion.exclude_approaching,
      excludeInside: data.node_exclusion.exclude_inside,
      excludeReturning: data.node_exclusion.exclude_returning,
    },
  };

  return result;
};

const orderJoinOrderDetailsFactory = {
  createOrderDetails,
};

export default orderJoinOrderDetailsFactory;
