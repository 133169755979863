import MileageDownloadRequest, {
  MileageDownloadRequestTimezone,
} from "../../../api/raily/mileage/download/mileage-download.request";
import MileageDownloadParams, {
  MileageDownloadParamsTimezone,
} from "./mileage-download-params";

const createTimezone = (
  timezone: MileageDownloadParamsTimezone | undefined
): MileageDownloadRequestTimezone | undefined => {
  switch (timezone) {
    case MileageDownloadParamsTimezone.EUROPE_WARSAW:
      return MileageDownloadRequestTimezone.EUROPE_WARSAW;
    case MileageDownloadParamsTimezone.UTC:
      return MileageDownloadRequestTimezone.UTC;
    default:
      return undefined;
  }
};

const create = (params: MileageDownloadParams): MileageDownloadRequest => {
  return {
    mileageUuids: params.mileageUuids,
    separator: params.separator,
    timezone: createTimezone(params.timezone),
  };
};

const mileageDownloadRequestFactory = {
  create,
};

export default mileageDownloadRequestFactory;
