import { faAdd } from "@fortawesome/free-solid-svg-icons";
import TableButtonComponent from "../../../../common/components/table/button/table-button.component";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import DelegationListingTableColumn from "../common/types/delegation-listing-table-column";

const getColumns = (
  isActionButtonVisible: boolean,
  areAllRowsSelected: boolean,
  onActionSelectAllButtonClick: () => void
): DelegationListingTableColumn[] => {
  const translations =
    delegationTranslationsHelper.getDelegationListingTranslations().table
      .headers;

  const tableColumns: DelegationListingTableColumn[] = [
    {
      header: "",
      title: "",
      accessor: "status",
      colSpan: 2,
    },
    {
      header: translations.delegationNumberLabel,
      title: translations.delegationNumberTitle,
      accessor: "delegationNumber",
      colSpan: 5,
    },
    {
      header: translations.typeLabel,
      title: translations.typeTitle,
      accessor: "type",
      colSpan: 3,
    },
    {
      header: translations.cargoCompanyNameLabel,
      title: translations.cargoCompanyNameTitle,
      accessor: "cargoCompanyName",
      colSpan: 5,
    },
    {
      header: translations.workerLabel,
      title: translations.workerTitle,
      accessor: "workerName",
      colSpan: 5,
    },
    {
      header: translations.startDateLabel,
      title: translations.startDateTitle,
      accessor: "startDate",
      colSpan: 5,
    },
    {
      header: translations.endDateLabel,
      title: translations.endDateTitle,
      accessor: "endDate",
      colSpan: 5,
    },
    {
      header: translations.creationDateLabel,
      title: translations.creationDateTitle,
      accessor: "creationDate",
      colSpan: 7,
    },
    {
      header: translations.firstCsvDateLabel,
      title: translations.firstCsvDateTitle,
      accessor: "firstCsvDownload",
      colSpan: 7,
    },
    {
      header: translations.firstPdfDateLabel,
      title: translations.firstPdfDateTitle,
      accessor: "firstPdfDownload",
      colSpan: 7,
    },
    {
      header: translations.costLabel,
      title: translations.costTitle,
      accessor: "totalCost",
      colSpan: 4,
    },
    {
      header: translations.totalDistanceLabel,
      title: translations.totalDistanceTitle,
      accessor: "totalDistance",
      colSpan: 4,
    },
    {
      header: "",
      title: "",
      accessor: "delete",
      colSpan: 3,
    },
    {
      header: isActionButtonVisible && (
        <TableButtonComponent
          icon={faAdd}
          onClick={onActionSelectAllButtonClick}
          idForTesting="delegation-listing-table-column-action-button"
          type={areAllRowsSelected ? "success" : "brand"}
        />
      ),
      title: "",
      accessor: "action",
      colSpan: 3,
    },
  ];

  return tableColumns;
};

const getTotalCostLabel = (totalCost: number) => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getTotalCostTitle = (totalCost: number) => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const delegationListingTableHelper = {
  getColumns,
  getTotalCostLabel,
  getTotalCostTitle,
};

export default delegationListingTableHelper;
