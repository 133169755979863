import cargoOrderSettlementListingRouteQueryParamsService from "../../common/cargo-order-settlement-listing-route-query-params.service";
import CargoOrderSettlementListingFilter from "../../common/types/cargo-order-settlement-listing-filter";
import CargoOrderSettlementListingSortKey from "../../common/types/cargo-order-settlement-listing-sort-key";
import CargoOrderSettlementListingByRailyRouteQueryParams from "./types/cargo-order-settlement-listing-by-raily-route-query-params";
import CargoOrderSettlementListingByRailyCargoCompany from "./types/cargo-order-settlement-listing-by-raily-cargo-company";
import Joi from "joi";

const createRouteQueryParams = (
  cargoCompanyUuid:
    | CargoOrderSettlementListingByRailyCargoCompany["uuid"]
    | null,
  filters: CargoOrderSettlementListingFilter[],
  sortKey: CargoOrderSettlementListingSortKey | null,
  page: number,
  pageSize: number
): CargoOrderSettlementListingByRailyRouteQueryParams => {
  const commonParams =
    cargoOrderSettlementListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return {
    ...commonParams,
    cargoCompany: cargoCompanyUuid ?? undefined,
  };
};

const getCargoCompanyUuid = (
  routeQueryParams: CargoOrderSettlementListingByRailyRouteQueryParams
): string | undefined => {
  if (!validateCargoCompanyUuid(routeQueryParams.cargoCompany)) {
    return undefined;
  }

  return routeQueryParams.cargoCompany;
};

const validateCargoCompanyUuid = (
  routeQueryCargoCompanyParam: CargoOrderSettlementListingByRailyRouteQueryParams["cargoCompany"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryCargoCompanyParam).error?.message;
};

const cargoOrderSettlementListingByRailyRouteQueryParamsService = {
  getFilters: cargoOrderSettlementListingRouteQueryParamsService.getFilters,
  getSortKey: cargoOrderSettlementListingRouteQueryParamsService.getSortKey,
  getPage: cargoOrderSettlementListingRouteQueryParamsService.getPage,
  getPageSize: cargoOrderSettlementListingRouteQueryParamsService.getPageSize,
  getCargoCompanyUuid,
  createRouteQueryParams,
};

export default cargoOrderSettlementListingByRailyRouteQueryParamsService;
