import { FC } from "react";
import useTaxiOfficerListingUserPermissions from "../common/user-permissions/use-taxi-officer-listing-user-permission";
import TaxiOfficerListingItem from "../common/types/taxi-officer-listing-item";
import TaxiOfficerListingTableColumn from "../common/types/taxi-officer-listing-table-column";
import taxiOfficerListingTableHelper from "./taxi-officer-listing-table.helper";
import UserStatus from "../../../../../common/types/user-status";
import TableComponent from "../../../../../common/components/table/table.component";
import TableDeleteButtonComponent from "../../../../../common/components/table/button/delete/table-delete-button.component";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import TaxiOfficerListingTableRow from "../common/types/taxi-officer-listing-table-row";
import UserDeleteUserData from "../../../common/delete/common/types/user-delete-user-data";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import userRoutesHelper from "../../../common/routes/user-routes.helper";

type TaxiOfficerListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: TaxiOfficerListingItem[];
  onSelectUserToDelete: (userData: UserDeleteUserData) => void;
};

const TaxiOfficerListingTableComponent: FC<TaxiOfficerListingTableProps> = (
  props
) => {
  const userPermissions = useTaxiOfficerListingUserPermissions();

  const translations =
    userTranslationsHelper.getTaxiOfficerListingTranslations();

  const columns: TaxiOfficerListingTableColumn[] =
    taxiOfficerListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: TaxiOfficerListingItem
  ): TaxiOfficerListingTableRow => {
    const isDeleteButtonVisible =
      userPermissions.hasAccessToDeleteUser &&
      listingItem.status !== UserStatus.DELETED;
    const activityStatus = taxiOfficerListingTableHelper.getStatusText(
      listingItem.status
    );

    const isEditButtonVisible =
      userPermissions.hasAccessToEditUser &&
      listingItem.status !== UserStatus.DELETED;

    return {
      id: listingItem.uuid,
      value: {
        firstName: (
          <div title={listingItem.firstName}>{listingItem.firstName}</div>
        ),
        lastName: (
          <div title={listingItem.lastName}>{listingItem.lastName}</div>
        ),
        userName: (
          <div title={listingItem.username}>{listingItem.username}</div>
        ),
        email: <div title={listingItem.email}>{listingItem.email}</div>,
        phoneNumbers: (
          <div>
            <div>
              {listingItem.phoneNumbers.map((phoneNumber, index) => (
                <div key={index} title={phoneNumber}>
                  {phoneNumber}
                </div>
              ))}
            </div>
          </div>
        ),
        status: <div title={activityStatus}>{activityStatus}</div>,
        actions: (
          <div className="d-flex">
            {isEditButtonVisible && (
              <TableLinkButtonComponent
                icon={faEdit}
                to={userRoutesHelper.getTaxiOfficerEditRoute({
                  taxiOfficerUuid: listingItem.uuid,
                })}
                title={translations.table.taxiOfficerEditButtonTitle}
                idForTesting={`taxi-officer-listing-table-item-${listingItem.uuid}-edit-button`}
              />
            )}
            {isDeleteButtonVisible && (
              <TableDeleteButtonComponent
                onClick={() =>
                  props.onSelectUserToDelete({
                    uuid: listingItem.uuid,
                    firstName: listingItem.firstName,
                    lastName: listingItem.lastName,
                  })
                }
                title={translations.table.taxiOfficerDeleteButtonTitle}
                idForTesting={`taxi-officer-listing-table-item-${listingItem.uuid}-delete-button`}
              />
            )}
          </div>
        ),
      },
    };
  };

  const rows: TaxiOfficerListingTableRow[] = props.listingItems.map(
    (listingItem) => createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiOfficerListingTableComponent;
