import BillingModel from "../../../../types/billing-model";
import BillingModelSelectOption from "../../../../types/billing-model-select-option";

const getSelectOptions = (): BillingModelSelectOption[] => {
  return [
    {
      label: BillingModel.AB,
      value: BillingModel.AB,
    },
    {
      label: BillingModel.ABA,
      value: BillingModel.ABA,
    },
    {
      label: BillingModel.OTHER,
      value: BillingModel.OTHER,
    },
    {
      label: BillingModel.SABS,
      value: BillingModel.SABS,
    },
  ];
};

const billingModelSelectHelper = {
  getSelectOptions,
};

export default billingModelSelectHelper;
