import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiUnsettledOrderListingSortKey from "./types/taxi-unsettled-order-listing-sort-key";
import TaxiUnsettledOrderListingSortSelectOption from "./types/taxi-unsettled-order-listing-sort-select-option";

const getSelectOptions = (): TaxiUnsettledOrderListingSortSelectOption[] => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledOrderListingTranslations()
      .sort;

  return [
    {
      label: translations.startDateAscOptionLabel,
      value: TaxiUnsettledOrderListingSortKey.START_DATE_ASC,
    },
    {
      label: translations.startDateDescOptionLabel,
      value: TaxiUnsettledOrderListingSortKey.START_DATE_DESC,
    },
    {
      label: translations.internalOrderIdAscOptionLabel,
      value: TaxiUnsettledOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      label: translations.internalOrderIdDescOptionLabel,
      value: TaxiUnsettledOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
  ];
};

const taxiUnsettledOrderListingSortHelper = {
  getSelectOptions,
};

export default taxiUnsettledOrderListingSortHelper;
