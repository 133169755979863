import { FC } from "react";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import routeDetailsErrorHelper from "./route-details-error.helper";
import ButtonComponent from "../../../../../../common/components/button/button.component";

type RouteDetailsErrorProps = {
  isFetching: boolean;
  routeHumanId: number;
  onTryAgainButtonClick: () => void;
};

const RouteDetailsErrorComponent: FC<RouteDetailsErrorProps> = (props) => {
  const translations = routeTranslationsHelper.getDetailsTranslations();

  return (
    <div className="route_details_error">
      <div className="route_details_error__heading">
        {translations.fetchingError.headingLabel}
      </div>
      <div className="route_details_error__description">
        {routeDetailsErrorHelper.getFetchingErrorDescriptionLabel(
          props.routeHumanId
        )}
      </div>
      <ButtonComponent
        onClick={props.onTryAgainButtonClick}
        isLoading={props.isFetching}
        type="primary"
        classNames={{
          root: "route_details_error__retry_button",
        }}
      >
        {translations.fetchingError.retryButtonLabel}
      </ButtonComponent>
    </div>
  );
};

export default RouteDetailsErrorComponent;
