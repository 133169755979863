import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import ButtonComponent, {
  ButtonProps,
} from "../../../../../common/components/button/button.component";

type MileageDetailsToolButtonProps = Pick<
  ButtonProps,
  "onClick" | "title" | "isDisabled" | "type" | "isLoading"
> & {
  icon: IconProp;
};

const MileageDetailsToolButtonComponent: FC<MileageDetailsToolButtonProps> = (
  props
) => {
  return (
    <ButtonComponent
      onClick={props.onClick}
      title={props.title}
      classNames={{
        root: classNames(
          "mileage_details_tools_button",
          props.isDisabled && "disabled"
        ),
      }}
      type={props.type}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
    >
      <FontAwesomeIcon
        icon={props.icon}
        className="mileage_details_tools_button__icon"
      />
    </ButtonComponent>
  );
};

MileageDetailsToolButtonComponent.defaultProps = {
  type: "primary",
};

export default MileageDetailsToolButtonComponent;
