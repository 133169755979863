import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsCargoTaxiContributionDiscount, {
  BillingsCargoTaxiContributionDiscountType,
} from "./types/billings-cargo-taxi-contributions-discount";

const getDiscountNewContribution =
  (): BillingsCargoTaxiContributionDiscount => {
    return {
      amount: 0,
      comment: "",
      type: null,
      percentage: 0,
    };
  };

const getContributionTypeTranslation = (
  contributionType: BillingsCargoTaxiContributionDiscountType | null
) => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiContributionsTranslations().discount
      .type;

  switch (contributionType) {
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
      return translations.beingLate;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
      return translations.incompatibleCar;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
      return translations.optimization;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return translations.other;
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return translations.percentage;
  }
};

const billingsCargoTaxiContributionsDiscountHelper = {
  getDiscountNewContribution,
  getContributionTypeTranslation,
};

export default billingsCargoTaxiContributionsDiscountHelper;
