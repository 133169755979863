import { FC } from "react";
import TaxiCargoContractListingFilter from "../../common/types/taxi-cargo-contract-listing-filter";
import taxiCargoContractListingFiltersBadgeListHelper from "./taxi-cargo-contract-listing-filters-badge-list.helper";
import ListingFilterBadgeListComponent from "../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type TaxiCargoContractListingFiltersBadgeListProps = {
  filters: TaxiCargoContractListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const TaxiCargoContractListingFiltersBadgeListComponent: FC<
  TaxiCargoContractListingFiltersBadgeListProps
> = (props) => {
  const badges = taxiCargoContractListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default TaxiCargoContractListingFiltersBadgeListComponent;
