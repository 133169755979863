export enum BillingsTaxiTaxiContributionPenaltyType {
  PENALTY_WRONG_ROUTE = "PENALTY_WRONG_ROUTE",
  PENALTY_BEING_LATE = "PENALTY_BEING_LATE",
  PENALTY_INCOMPATIBLE_CAR = "PENALTY_INCOMPATIBLE_CAR",
  PENALTY_OTHER = "PENALTY_OTHER",
}

type BillingsTaxiTaxiContributionPenalty = {
  type: BillingsTaxiTaxiContributionPenaltyType | null;
  comment: string;
  amount: number | null;
};

export default BillingsTaxiTaxiContributionPenalty;
