import TaxiFleetPartnerUpdateParams from "../../../../../common/services/taxi-fleet-partner/update/taxi-fleet-partner-update-params";
import TaxiFleetPartnerEditFormData from "./types/taxi-fleet-partner-edit-form-data";

const create = (
  formData: TaxiFleetPartnerEditFormData,
  fleetPartnerUuid: string,
  taxiCorporationUuid: string
): TaxiFleetPartnerUpdateParams => {
  return {
    fleetPartnerUuid,
    name: formData.name,
    taxiCorporationUuid,
  };
};

const taxiFleetPartnerUpdateParamsFactory = {
  create,
};

export default taxiFleetPartnerUpdateParamsFactory;
