import { FC } from "react";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import RailyOfficerListingFilter from "../common/types/raily-officer-listing-filter";
import RailyOfficerListingSortKey from "../common/types/raily-officer-listing-sort-key";
import railyOfficerListingFilterHelper from "./raily-officer-listing-filters.helper";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";

type RailyOfficerListingFilterProps = {
  filters: RailyOfficerListingFilter[];
  onFiltersChange: (filters: RailyOfficerListingFilter[]) => void;
  sortKey: RailyOfficerListingSortKey;
  onSortKeyChange: (sortKey: RailyOfficerListingSortKey) => void;
};

const RailyOfficerListingFilterComponent: FC<RailyOfficerListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<RailyOfficerListingFilter> =
    railyOfficerListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    railyOfficerListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as RailyOfficerListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as RailyOfficerListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="raily-officer-listing-filter"
      idForTestingSort="raily-officer-listing-sort"
    />
  );
};

export default RailyOfficerListingFilterComponent;
