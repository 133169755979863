import appConfig from "../../../../../app.config";
import authService from "../../../../auth/common/auth.service";
import AppHeaderUserChangePasswordRequest from "./app-header-user-change-password.request";

const changePassword = async (request: AppHeaderUserChangePasswordRequest) => {
  const path = "/users/me/password/change";
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const appHeaderUserChangePasswordApiService = {
  changePassword,
};

export default appHeaderUserChangePasswordApiService;
