import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
const useRouteQueryParams = <T extends object>(): [T, (value: T) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = () => {
    const urlSearchParams = Object.fromEntries(searchParams);

    const paramsKeys = Object.keys(urlSearchParams);

    const newParams: { [key: string]: string | string[] } = {};

    paramsKeys.forEach((key) => {
      const values = searchParams.getAll(key);

      if (values.length > 1) {
        newParams[key] = values;
        return;
      }
      newParams[key] = values[0];
    });

    return newParams;
  };

  const params = getParams();

  const removeUndefinedProperties = (params: T) => {
    Object.keys(params).forEach(
      (key) =>
        params[key as keyof T] === undefined && delete params[key as keyof T]
    );
  };

  const setParams = (params: T) => {
    removeUndefinedProperties(params);

    setSearchParams(params as URLSearchParamsInit, {
      replace: true,
    });
  };

  return [params as T, setParams];
};

export default useRouteQueryParams;
