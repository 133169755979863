export enum MileageAddressListRequestOrder {
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  MODIFIED_AT_DESC = "MODIFIED_AT_DESC",
  MODIFIED_AT_ASC = "MODIFIED_AT_ASC",
  STREET_ASC = "STREET_ASC",
  STREET_DESC = "STREET_DESC",
  TOWN_ASC = "TOWN_ASC",
  TOWN_DESC = "TOWN_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
}

export type MileageAddressListQueryParams = {
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
  order: MileageAddressListRequestOrder | undefined;
  searchQuery: string | undefined;
};

type MileageAddressListRequest = {
  creationStart?: Date;
  creationEnd?: Date;
  order?: MileageAddressListRequestOrder;
  searchQuery?: string;
};

export default MileageAddressListRequest;
