import Joi from "joi";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import CargoTaxiContractAddFormData from "./common/types/cargo-taxi-contract-add-form-data";

const validateBillingModel = (
  value: CargoTaxiContractAddFormData["billingModel"]
): FormValidationResult => {
  const validationSchema = Joi.string().required();

  return formValidationService.validate(value, validationSchema);
};

const validateDiscountPercentLimit = (
  value: CargoTaxiContractAddFormData["discountPercentLimit"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).precision(2).required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRate = (
  value: CargoTaxiContractAddFormData["distanceRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .greater(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateFreeStoppingPeriodMinutes = (
  value: CargoTaxiContractAddFormData["freeStoppingPeriodMinutes"]
): FormValidationResult => {
  const validationSchema = Joi.number().integer().min(0).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsOrderPublishingAllowed = (
  value: CargoTaxiContractAddFormData["isOrderPublishingAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsTollRoadsDuringOrderAllowed = (
  value: CargoTaxiContractAddFormData["isTollRoadsDuringOrderAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsTollRoadsWhileArrivalAllowed = (
  value: CargoTaxiContractAddFormData["isTollRoadsWhileArrivalAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsTollRoadsWhileReturningAllowed = (
  value: CargoTaxiContractAddFormData["isTollRoadsWhileReturningAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validatePeriodOfValidity = (
  value: CargoTaxiContractAddFormData["periodOfValidity"]
): FormValidationResult => {
  const validationSchema = Joi.object<
    CargoTaxiContractAddFormData["periodOfValidity"]
  >()
    .keys({
      from: Joi.date().min("1970-01-01").required(),
      to: Joi.date().required(),
    })
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateStoppingRate = (
  value: CargoTaxiContractAddFormData["stoppingRate"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).precision(2).required();

  return formValidationService.validate(value, validationSchema);
};

const validateTaxiCorporation = (
  value: CargoTaxiContractAddFormData["taxiCorporationUuid"]
): FormValidationResult => {
  const validationSchema = Joi.string().required();

  return formValidationService.validate(value, validationSchema);
};

const validateActivity = (
  value: CargoTaxiContractAddFormData["isActive"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const cargoTaxiContractAddFormValidationService = {
  validateBillingModel,
  validateDiscountPercentLimit,
  validateDistanceRate,
  validateFreeStoppingPeriodMinutes,
  validateIsOrderPublishingAllowed,
  validateIsTollRoadsDuringOrderAllowed,
  validateIsTollRoadsWhileArrivalAllowed,
  validateIsTollRoadsWhileReturningAllowed,
  validatePeriodOfValidity,
  validateStoppingRate,
  validateTaxiCorporation,
  validateActivity,
};

export default cargoTaxiContractAddFormValidationService;
