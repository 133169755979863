import driverEditUserDataFormValidationService from "../../common/user/driver-edit-user-data-form-validation.service";

const driverEditByRailyUserDataFormValidationService = {
  validateFirstName: driverEditUserDataFormValidationService.validateFirstName,
  validateLastName: driverEditUserDataFormValidationService.validateLastName,
  validateMobile: driverEditUserDataFormValidationService.validateMobile,
  validateAlternativeMobile:
    driverEditUserDataFormValidationService.validateAlternativeMobile,
  validateBirthDate: driverEditUserDataFormValidationService.validateBirthDate,
  validateBirthPlace:
    driverEditUserDataFormValidationService.validateBirthPlace,
  validatePersonalIdNumber:
    driverEditUserDataFormValidationService.validatePersonalIdNumber,
  validateCitizenship:
    driverEditUserDataFormValidationService.validateCitizenship,
  validateLanguages: driverEditUserDataFormValidationService.validateLanguages,
  validateYearsOfExperience:
    driverEditUserDataFormValidationService.validateYearsOfExperience,
  validateFormOfEmployment:
    driverEditUserDataFormValidationService.validateFormOfEmployment,
  validateFleetPartner:
    driverEditUserDataFormValidationService.validateFleetPartner,
  validateAddresses: driverEditUserDataFormValidationService.validateAddresses,
};

export default driverEditByRailyUserDataFormValidationService;
