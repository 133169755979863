import { FC, useState } from "react";
import ButtonComponent from "../../../../../../../common/components/button/button.component";
import ModalComponent, {
  ModalProps,
} from "../../../../../../../common/components/modal/modal.component";
import DriverDetailsDriverPlanEntry, {
  DriverDetailsDriverPlanEntryWaypoint,
} from "../../common/types/driver-details-plan-entry";
import driverDetailsPlanDeleteCheckoutApiService from "./common/api/driver-details-plan-delete-checkout-api.service";
import DriverDetailsPlanDeleteCheckoutResponse from "./common/api/driver-details-plan-delete-checkout.response";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import notificationService from "../../../../../../../common/utils/notification/notification.service";

type DriverDetailsPlanDeleteCheckoutProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  onDeleteCheckoutSuccess: () => void;
  driverUuid: string;
  planEntry: DriverDetailsDriverPlanEntry | undefined;
  waypoint: DriverDetailsDriverPlanEntryWaypoint | undefined;
  checkoutEventUuid: string;
};

const DriverDetailsPlanDeleteCheckoutComponent: FC<
  DriverDetailsPlanDeleteCheckoutProps
> = (props) => {
  const translations =
    userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations()
      .deleteCheckout;

  const [isCheckoutDeleteFetching, setIsCheckoutDeleteFetching] =
    useState(false);

  const onDeleteCheckoutSuccess = () => {
    notificationService.success(translations.successNotificationText);
    props.onDeleteCheckoutSuccess();
  };

  const onDeleteCheckoutFailure = () => {
    notificationService.error(translations.failureNotificationText);
  };

  const handleDeleteCheckoutResponse = (
    response: DriverDetailsPlanDeleteCheckoutResponse
  ) => {
    if (response.status === 200) {
      onDeleteCheckoutSuccess();
      return;
    }

    onDeleteCheckoutFailure();
  };

  const deleteCheckout = () => {
    setIsCheckoutDeleteFetching(true);

    driverDetailsPlanDeleteCheckoutApiService
      .deleteCheckout(
        props.driverUuid,
        props.planEntry?.uuid!,
        props.checkoutEventUuid
      )
      .then(handleDeleteCheckoutResponse)
      .catch(onDeleteCheckoutFailure)
      .finally(() => setIsCheckoutDeleteFetching(false));
  };

  const onSubmitButtonClick = () => {
    deleteCheckout();
  };

  if (!props.waypoint || !props.planEntry) {
    return null;
  }

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="danger"
          isLoading={isCheckoutDeleteFetching}
          title={translations.submitButtonTitle}
        >
          {translations.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={props.onClose}
          type="brand"
          title={translations.rejectButtonTitle}
        >
          {translations.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <div>
        <div>
          {translations.addressTemplateText.replace(
            "#{addressDisplayName}",
            props.waypoint.address.displayName
          )}
        </div>
        <div>
          {translations.contentMessageTemplateText.replace(
            "#{planEntryId}",
            String(props.planEntry.id)
          )}
        </div>
      </div>
    </ModalComponent>
  );
};

export default DriverDetailsPlanDeleteCheckoutComponent;
