import DriverListResponse, {
  DriverListResponseItemDriverCitizenship,
  DriverListResponseItemDriverLanguage,
  DriverListResponseItemDriverCarOwnership,
  DriverListResponseItemDriver,
  DriverListResponseItemAddressType,
  DriverListResponseItemAddress,
  DriverListResponseItemTaxiAssociationFleetPartner,
  DriverListResponseItemTaxiDriverAssociationTaxiCorporation,
  DriverListResponseItemTaxiDriverAssociation,
  DriverListResponseItem,
} from "../../../api/raily/driver/list/driver-list.response";
import UserCitizenship from "../../../types/user-citizenship";
import AddressType from "../../../types/address-type";
import UserLanguage from "../../../types/user-language";
import UserVehicleOwnership from "../../../types/user-vehicle-ownership-form";
import DriverList, {
  DriverListItem,
  DriverListItemVehicle,
  DriverListItemTaxiAssociationFleetPartner,
  DriverListItemTaxiAssociation,
  DriverListItemTaxiAssociationTaxiCorporation,
  DriverListItemAddress,
} from "./driver-list";

const createCitizenship = (
  responseDriverCitizenship: DriverListResponseItemDriverCitizenship
): UserCitizenship => {
  switch (responseDriverCitizenship) {
    case DriverListResponseItemDriverCitizenship.BY:
      return UserCitizenship.BY;
    case DriverListResponseItemDriverCitizenship.CZ:
      return UserCitizenship.CZ;
    case DriverListResponseItemDriverCitizenship.DE:
      return UserCitizenship.DE;
    case DriverListResponseItemDriverCitizenship.LT:
      return UserCitizenship.LT;
    case DriverListResponseItemDriverCitizenship.PL:
      return UserCitizenship.PL;
    case DriverListResponseItemDriverCitizenship.RU:
      return UserCitizenship.RU;
    case DriverListResponseItemDriverCitizenship.SK:
      return UserCitizenship.SK;
    case DriverListResponseItemDriverCitizenship.UA:
      return UserCitizenship.UA;
  }
};

const createDriverLanguage = (
  responseDriverLanguage: DriverListResponseItemDriverLanguage
): UserLanguage => {
  switch (responseDriverLanguage) {
    case DriverListResponseItemDriverLanguage.BY:
      return UserLanguage.BY;
    case DriverListResponseItemDriverLanguage.CZ:
      return UserLanguage.CZ;
    case DriverListResponseItemDriverLanguage.DE:
      return UserLanguage.DE;
    case DriverListResponseItemDriverLanguage.EN:
      return UserLanguage.EN;
    case DriverListResponseItemDriverLanguage.ES:
      return UserLanguage.ES;
    case DriverListResponseItemDriverLanguage.FR:
      return UserLanguage.FR;
    case DriverListResponseItemDriverLanguage.LT:
      return UserLanguage.LT;
    case DriverListResponseItemDriverLanguage.PL:
      return UserLanguage.PL;
    case DriverListResponseItemDriverLanguage.RU:
      return UserLanguage.RU;
    case DriverListResponseItemDriverLanguage.SK:
      return UserLanguage.SK;
    case DriverListResponseItemDriverLanguage.UA:
      return UserLanguage.UA;
  }
};

const createLanguages = (
  responseDriverLanguages: DriverListResponseItemDriverLanguage[]
): UserLanguage[] => {
  return responseDriverLanguages.map(createDriverLanguage);
};

const createDriverCarOwnership = (
  responseDriverCarOwnership: DriverListResponseItemDriverCarOwnership
): UserVehicleOwnership => {
  switch (responseDriverCarOwnership) {
    case DriverListResponseItemDriverCarOwnership.BUSINESS:
      return UserVehicleOwnership.BUSINESS;
    case DriverListResponseItemDriverCarOwnership.OWNER:
      return UserVehicleOwnership.OWNER;
  }
};

const createVehicle = (
  responseDriver: DriverListResponseItemDriver
): DriverListItemVehicle => {
  return {
    make: responseDriver.carMake,
    model: responseDriver.carModel,
    numberOfSeats: responseDriver.carSeatsNo,
    ownership: createDriverCarOwnership(responseDriver.carOwnership),
    productionYear: responseDriver.carProdYear,
    registrationNumber: responseDriver.carRegNo,
  };
};

const createDriverAddressType = (
  responseDriverAddressType: DriverListResponseItemAddressType
): AddressType => {
  switch (responseDriverAddressType) {
    case DriverListResponseItemAddressType.MAILING:
      return AddressType.MAILING;
    case DriverListResponseItemAddressType.STARTING:
      return AddressType.STARTING;
  }
};

const createDriverAddress = (
  responseDriverAddress: DriverListResponseItemAddress
): DriverListItemAddress => {
  return {
    apartmentNumber: responseDriverAddress.apartment,
    country: responseDriverAddress.country,
    createdBy: responseDriverAddress.createdBy,
    creationDate: responseDriverAddress.createdAt,
    description: responseDriverAddress.description,
    displayName: responseDriverAddress.displayName,
    houseNumber: responseDriverAddress.houseNo,
    latitude: responseDriverAddress.lat,
    longitude: responseDriverAddress.lon,
    modificationDate: responseDriverAddress.modifiedAt,
    modifiedBy: responseDriverAddress.modifiedBy,
    street: responseDriverAddress.street,
    town: responseDriverAddress.town,
    type: createDriverAddressType(responseDriverAddress.type),
    uuid: responseDriverAddress.id,
    zipCode: responseDriverAddress.zipCode,
  };
};

const createAddresses = (
  responseDriverAddresses: DriverListResponseItemAddress[]
): DriverListItemAddress[] => {
  return responseDriverAddresses.map(createDriverAddress);
};

const createDriverTaxiAssocationFleetPartner = (
  responseDriverTaxiAssociationFleetPartner: DriverListResponseItemTaxiAssociationFleetPartner | null
): DriverListItemTaxiAssociationFleetPartner | null => {
  if (!responseDriverTaxiAssociationFleetPartner) {
    return null;
  }

  return {
    name: responseDriverTaxiAssociationFleetPartner.name,
    taxiCorporationUuid:
      responseDriverTaxiAssociationFleetPartner.taxiCorporationId,
    uuid: responseDriverTaxiAssociationFleetPartner.id,
  };
};
const createDriverTaxiAssocationTaxiCorporation = (
  responseDriverTaxiAssociationTaxiCorporation: DriverListResponseItemTaxiDriverAssociationTaxiCorporation
): DriverListItemTaxiAssociationTaxiCorporation => {
  return {
    companyId: responseDriverTaxiAssociationTaxiCorporation.regon,
    companyName: responseDriverTaxiAssociationTaxiCorporation.companyName,
    createdBy: responseDriverTaxiAssociationTaxiCorporation.createdBy,
    creationDate: responseDriverTaxiAssociationTaxiCorporation.createdAt,
    displayName: responseDriverTaxiAssociationTaxiCorporation.displayName,
    modificationDate: responseDriverTaxiAssociationTaxiCorporation.modifiedAt,
    modifiedBy: responseDriverTaxiAssociationTaxiCorporation.modifiedBy,
    nationalCourtRegisterNumber:
      responseDriverTaxiAssociationTaxiCorporation.krsNo,
    taxId: responseDriverTaxiAssociationTaxiCorporation.taxId,
  };
};

const createDriverTaxiAssociation = (
  responseDriverTaxiAssociation: DriverListResponseItemTaxiDriverAssociation
): DriverListItemTaxiAssociation => {
  return {
    apartmentNumber: responseDriverTaxiAssociation.apartment,
    companyId: responseDriverTaxiAssociation.regon,
    companyName: responseDriverTaxiAssociation.companyName,
    country: responseDriverTaxiAssociation.country,
    createdBy: responseDriverTaxiAssociation.createdBy,
    creationDate: responseDriverTaxiAssociation.createdAt,
    description: responseDriverTaxiAssociation.description,
    zipCode: responseDriverTaxiAssociation.zipCode,
    fleetPartnerUuid: responseDriverTaxiAssociation.fleetPartnerId,
    houseNumber: responseDriverTaxiAssociation.houseNo,
    modificationDate: responseDriverTaxiAssociation.modifiedAt,
    modifiedBy: responseDriverTaxiAssociation.modifiedBy,
    nationalCourtRegisterNumber: responseDriverTaxiAssociation.krsNo,
    street: responseDriverTaxiAssociation.street,
    taxId: responseDriverTaxiAssociation.taxId,
    town: responseDriverTaxiAssociation.town,
    uuid: responseDriverTaxiAssociation.id,
    fleetPartner: createDriverTaxiAssocationFleetPartner(
      responseDriverTaxiAssociation.fleetPartner
    ),
    taxiCorporation: createDriverTaxiAssocationTaxiCorporation(
      responseDriverTaxiAssociation.taxiCorporation
    ),
  };
};

const createTaxiAssociations = (
  responseDriverTaxiAssociations: DriverListResponseItemTaxiDriverAssociation[]
): DriverListItemTaxiAssociation[] => {
  return responseDriverTaxiAssociations.map(createDriverTaxiAssociation);
};

const createAlternativePhoneNumber = (
  responseDataItem: DriverListResponseItem
): DriverListItem["alternativePhoneNumber"] => {
  if (
    !responseDataItem.mobileSecondary ||
    !responseDataItem.mobileSecondaryPrefix
  ) {
    return null;
  }

  return {
    dialingCode: responseDataItem.mobileSecondaryPrefix,
    number: responseDataItem.mobileSecondary,
  };
};

const createDataItem = (
  responseDataItem: DriverListResponseItem
): DriverListItem => {
  return {
    creationDate: responseDataItem.createdAt,
    modificationDate: responseDataItem.modifiedAt,
    createdByName: responseDataItem.createdByName,
    modifiedByName: responseDataItem.modifiedByName,
    email: responseDataItem.email,
    firstName: responseDataItem.firstName,
    isCandidaturePending: responseDataItem.pendingCandidature,
    lastName: responseDataItem.lastName,
    phoneNumber: {
      dialingCode: responseDataItem.mobilePrimaryPrefix,
      number: responseDataItem.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(responseDataItem),
    removalDate: responseDataItem.removedAt,
    addresses: createAddresses(responseDataItem.driver.addresses),
    birthDate: responseDataItem.driver.birthDate,
    birthPlace: responseDataItem.driver.birthPlace,
    vehicle: createVehicle(responseDataItem.driver),
    citizenship: createCitizenship(responseDataItem.driver.citizenship),
    yearsOfExperience: responseDataItem.driver.experience,
    idNumber: responseDataItem.driver.idNumber,
    isPlanApproved: responseDataItem.driver.planApproved,
    languages: createLanguages(responseDataItem.driver.languages),
    mobileModel: responseDataItem.driver.mobileModel,
    taxiAssociations: createTaxiAssociations(
      responseDataItem.driver.taxiDriverAssociations
    ),
    username: responseDataItem.username,
    uuid: responseDataItem.id,
  };
};

const createData = (
  responseData: DriverListResponseItem[]
): DriverListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: DriverListResponse): DriverList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const driverListFactory = {
  create,
};

export default driverListFactory;
