export enum TaxiTaxiContractListRequestOrder {
  PRIORITY_ASC = "PRIORITY_ASC",
  PRIORITY_DESC = "PRIORITY_DESC",
  DISPLAY_NAME_ASC = "DISPLAY_NAME_ASC",
  DISPLAY_NAME_DESC = "DISPLAY_NAME_DESC",
  PRODUCER_DISPLAY_NAME_ASC = "PRODUCER_DISPLAY_NAME_ASC",
  PRODUCER_DISPLAY_NAME_DESC = "PRODUCER_DISPLAY_NAME_DESC",
  CONSUMER_DISPLAY_NAME_ASC = "CONSUMER_DISPLAY_NAME_ASC",
  CONSUMER_DISPLAY_NAME_DESC = "CONSUMER_DISPLAY_NAME_DESC",
  HALTING_TIME_RATE_ASC = "HALTING_TIME_RATE_ASC",
  HALTING_TIME_RATE_DESC = "HALTING_TIME_RATE_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  HALTING_TIME_AFTER_MINUTES_ASC = "HALTING_TIME_AFTER_MINUTES_ASC",
  HALTING_TIME_AFTER_MINUTES_DESC = "HALTING_TIME_AFTER_MINUTES_DESC",
}

export enum TaxiTaxiContractListRequestBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type TaxiContractListRequestQueryParams = {
  displayName: string | undefined;
  producerDisplayName: string | undefined;
  consumerDisplayName: string | undefined;
  engagedTaxiId: string | undefined;
  model: TaxiTaxiContractListRequestBillingModel | undefined;
  resultsOrdering: TaxiTaxiContractListRequestOrder | undefined;
  offset: number | undefined;
  limit: number | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
  producerTaxiId: string | undefined;
  startValidSince: Date | undefined;
  endValidTo: Date | undefined;
  startDistanceRate: number | undefined;
  endDistanceRate: number | undefined;
  startHaltingTimeAfterMinutes: number | undefined;
  endHaltingTimeAfterMinutes: number | undefined;
  startHaltingTimeRate: number | undefined;
  endHaltingTimeRate: number | undefined;
  isPartner: boolean | undefined;
  isValid: boolean | undefined;
  priority: number | undefined;
  disabled: boolean | undefined;
};

type TaxiTaxiContractListRequest = {
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
  displayName?: string;
  producerDisplayName?: string;
  consumerDisplayName?: string;
  engagedTaxiId?: string;
  producerTaxiId?: string;
  startValidSince?: Date;
  endValidTo?: Date;
  startDistanceRate?: number;
  endDistanceRate?: number;
  startHaltingTimeAfterMinutes?: number;
  endHaltingTimeAfterMinutes?: number;
  startHaltingTimeRate?: number;
  endHaltingTimeRate?: number;
  isPartner?: boolean;
  isValid?: boolean;
  disabled?: boolean;
  priority?: number;
  model?: TaxiTaxiContractListRequestBillingModel;
  resultsOrdering?: TaxiTaxiContractListRequestOrder;
};

export default TaxiTaxiContractListRequest;
