import plannerDriverPlanListRequestFactory from "./list/planner-driver-plan-list-request.factory";
import {
  PlannerDriverPlanListRequestBody,
  PlannerDriverPlanListRequestQueryParams,
} from "./list/planner-driver-plan-list.request";
import railyApiService from "../raily-api.service";
import PlannerDriverPlanListResponse from "./list/planner-driver-plan-list.response";
import PlannerDriverPlanListRequest from "./list/planner-driver-plan-list.request";
import PlannerDriverPlanOrderRequest from "./order/planning-driver-plan-order.request";
import PlannerDriverPlanOrderResponse from "./order/planning-driver-plan-order.response";

const plannerApiService = () => {
  const getPlanList = (
    request: PlannerDriverPlanListRequest,
    abortSignal: AbortSignal
  ): Promise<PlannerDriverPlanListResponse> => {
    const url = `/planner/dse/query`;

    const body: PlannerDriverPlanListRequestBody =
      plannerDriverPlanListRequestFactory.createBody(request);

    const queryParams: PlannerDriverPlanListRequestQueryParams =
      plannerDriverPlanListRequestFactory.createQueryParams(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
      queryParams,
    });
  };

  const getPlanOrder = (
    request: PlannerDriverPlanOrderRequest,
    abortSignal: AbortSignal
  ): Promise<PlannerDriverPlanOrderResponse> => {
    const url = `/planner/dse/cargo-order/${request.orderId}`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  return { getPlanList, getPlanOrder };
};

export default plannerApiService;
