import DelegationListingActionData from "./types/delegation-listing-action-data";
import DelegationListingItem from "./types/delegation-listing-item";

const create = (item: DelegationListingItem): DelegationListingActionData => {
  return {
    delegationUuid: item.uuid,
    humanId: item.humanId,
  };
};

const delegationListingActionDataFactory = {
  create,
};

export default delegationListingActionDataFactory;
