import {
  faFileCircleCheck,
  faFileWaveform,
} from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import routeRoutesHelper from "../routes/route-routes.helper";

const getActiveListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faFileWaveform,
      linkTo: routeRoutesHelper.getActiveRoutesRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .routeActiveListing,
    },
  ];
};

const getFinishedListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faFileCircleCheck,
      linkTo: routeRoutesHelper.getFinishedRoutesRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .routeFinishedListing,
    },
  ];
};

const routeBreadcrumbsHelper = {
  getActiveListingBreadcrumbs,
  getFinishedListingBreadcrumbs,
};

export default routeBreadcrumbsHelper;
