import DriverPlanCandidatureAddByOrderIdRequest, {
  DriverPlanCandidatureAddByOrderIdRequestBody,
} from "./driver-plan-candidature-add-by-order-id.request";

const createBody = (
  request: DriverPlanCandidatureAddByOrderIdRequest
): DriverPlanCandidatureAddByOrderIdRequestBody => {
  return {
    acceptImmediately: request.acceptImmediately,
    driverId: request.driverId,
    orderId: request.orderId,
    planEntryId: request.planEntryId,
    taxiCorporationId: request.taxiCorporationId,
  };
};

const driverPlanCandidatureAddByOrderIdRequestFactory = {
  createBody,
};

export default driverPlanCandidatureAddByOrderIdRequestFactory;
