import UserFormOfEmployment from "../../../../../common/types/user-form-of-employment";
import DriverAddRequest, {
  DriverAddRequestAddress,
  DriverAddRequestTaxiDriverAssociation,
  DriverAddRequestTaxiDriverAssociationForB2B,
  DriverAddRequestTaxiDriverAssociationForEmployment,
} from "./api/driver-add.request";
import DriverAddAccountFormData from "./types/driver-add-account-form-data";
import DriverAddAddress from "./types/driver-add-address";
import DriverAddCompanyFormData from "./types/driver-add-company-form-data";
import DriverAddVehicleFormData from "./types/driver-add-vehicle-form-data";
import dateService from "../../../../../common/utils/date/date.service";
import DriverAddUserFormData from "./types/driver-add-user-form-data";

const createDriverAddRequestAddress = (
  userDataAddress: DriverAddAddress
): DriverAddRequestAddress => {
  const requestAddress: DriverAddRequestAddress = {
    apartment: userDataAddress.apartmentNumber.trim(),
    country: userDataAddress.country.trim(),
    description: userDataAddress.description?.trim() || null,
    house_no: userDataAddress.houseNumber.trim(),
    lat: userDataAddress.latitude,
    lon: userDataAddress.longitude,
    street: userDataAddress.street.trim(),
    town: userDataAddress.town.trim(),
    type: userDataAddress.type,
    zip_code: userDataAddress.zipCode.trim(),
  };

  return requestAddress;
};

const createDriverAddRequestAddresses = (
  userDataAddresses: DriverAddAddress[]
): DriverAddRequestAddress[] => {
  return userDataAddresses.map(createDriverAddRequestAddress);
};

const createDriverAddRequestTaxiDriverAssociationForEmployment = (
  userData: DriverAddUserFormData,
  taxiCorporationUuid: string
): DriverAddRequestTaxiDriverAssociationForEmployment => {
  return {
    contract_type: userData.formOfEmployment?.value!,
    taxi_corporation_id: taxiCorporationUuid,
    display_name: `${userData.firstName.trim()} ${userData.lastName.trim()}`,
    fleet_partner_id: userData.fleetPartner?.value.uuid ?? null,
  };
};

const createDriverAddRequestTaxiDriverAssociationForB2B = (
  userData: DriverAddUserFormData,
  companyData: DriverAddCompanyFormData,
  taxiCorporationUuid: string
): DriverAddRequestTaxiDriverAssociationForB2B => {
  return {
    apartment: companyData.address.apartmentNumber.trim(),
    company_name: companyData.name.trim(),
    contract_type: userData.formOfEmployment?.value!,
    fleet_partner_id: userData.fleetPartner?.value.uuid ?? null,
    country: companyData.address.country.trim(),
    description: companyData.address.description.trim(),
    house_no: companyData.address.houseNumber.trim(),
    regon: companyData.companyId.trim() || null,
    tax_id: companyData.taxNumber.trim(),
    zip_code: companyData.address.zipCode.trim(),
    town: companyData.address.town.trim(),
    krs_no: companyData.nationalCourtRegisterNumber.trim() || null,
    street: companyData.address.street.trim(),
    taxi_corporation_id: taxiCorporationUuid,
    display_name: `${userData.firstName.trim()} ${userData.lastName.trim()}`,
  };
};

const createDriverAddRequestTaxiDriverAssociation = (
  userData: DriverAddUserFormData,
  companyData: DriverAddCompanyFormData,
  taxiCorporationUuid: string
): DriverAddRequestTaxiDriverAssociation => {
  if (userData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
    return createDriverAddRequestTaxiDriverAssociationForB2B(
      userData,
      companyData,
      taxiCorporationUuid
    );
  }

  return createDriverAddRequestTaxiDriverAssociationForEmployment(
    userData,
    taxiCorporationUuid
  );
};

const createAddDriverRequest = (
  accountFormData: DriverAddAccountFormData,
  userFormData: DriverAddUserFormData,
  companyFormData: DriverAddCompanyFormData,
  vehicleFormData: DriverAddVehicleFormData,
  taxiCorporationUuid: string
): DriverAddRequest => {
  return {
    email: accountFormData.email.trim(),
    username: accountFormData.username.trim(),
    password: accountFormData.password,
    first_name: userFormData.firstName.trim(),
    last_name: userFormData.lastName.trim(),
    mobile_primary_prefix: userFormData.mobile.dialingCode!,
    mobile_primary: userFormData.mobile.number!.trim(),
    mobile_secondary_prefix: userFormData.alternativeMobile.dialingCode || null,
    mobile_secondary: userFormData.alternativeMobile.number?.trim() || null,
    driver: {
      birth_date: dateService.format(userFormData.birthDate!, "yyyy-mm-dd"),
      birth_place: userFormData.birthPlace.trim(),
      car_make: vehicleFormData.make.trim(),
      car_model: vehicleFormData.model.trim(),
      car_ownership: vehicleFormData.ownership?.value!,
      car_prod_year: vehicleFormData.productionYear!,
      car_reg_no: vehicleFormData.registrationNumber.trim(),
      car_seats_no: vehicleFormData.numberOfSeats!,
      citizenship: userFormData.citizenship?.value!,
      experience: userFormData.yearsOfExperience!,
      id_number: userFormData.personalIdNumber.trim(),
      languages: userFormData.languages.map((lang) => lang.value),
      taxi_driver_association: createDriverAddRequestTaxiDriverAssociation(
        userFormData,
        companyFormData,
        taxiCorporationUuid
      ),
      addresses: createDriverAddRequestAddresses(userFormData.addresses),
    },
  };
};

const driverAddFactory = {
  createAddDriverRequest,
};

export default driverAddFactory;
