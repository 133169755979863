import TaxiAddRequest from "../api/taxi-add-request";
import TaxiAddFormData from "../../../common/types/taxi-add-form-data";

const createAddTaxiRequest = (data: TaxiAddFormData) => {
  const request: TaxiAddRequest = {
    company_name: data.companyName,
    krs_no: data.nationalCourtRegister || null,
    contract_maintenance_policy: data.contractMaintenancePolicy!,
    display_name: data.displayName,
    headquarters_address_string: data.address,
    notes: data.notes,
    regon: data.companyId || null,
    tax_id: data.taxId,
  };

  return request;
};

const taxiAddFactory = {
  createAddTaxiRequest,
};

export default taxiAddFactory;
