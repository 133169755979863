import { SearchAddressResponseItem } from "../../utils/search-address/search-address.response";
import MapAddressSelectorAddressSelected from "./types/map-address-selector-address-selected";

const createSearchAddressOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): MapAddressSelectorAddressSelected => {
  return {
    name: searchAddressResponseItem.display_name,
    country: searchAddressResponseItem.address.country,
    countryCode: searchAddressResponseItem.address.country_code,
    town:
      searchAddressResponseItem.address.city ??
      searchAddressResponseItem.address.town ??
      searchAddressResponseItem.address.village,
    street: searchAddressResponseItem.address.road,
    houseNumber: searchAddressResponseItem.address.house_number,
    zipCode: searchAddressResponseItem.address.postcode,
    latitude: Number(searchAddressResponseItem.lat),
    longitude: Number(searchAddressResponseItem.lon),
  };
};

const mapAddressSelectorFactory = {
  createSearchAddressOption,
};

export default mapAddressSelectorFactory;
