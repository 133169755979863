import { RouteActiveListingRouteQueryParams } from "../../common/routes/types/route-active-listing-route-params";
import RouteActiveListingFilter, {
  RouteActiveListingCargoOrderHumanIdFilter,
  RouteActiveListingDriverFilter,
  RouteActiveListingPassengerFilter,
  RouteActiveListingRouteAddressFilter,
  RouteActiveListingHumanIdFilter,
  RouteActiveListingTaxiCorporationFilter,
} from "../common/types/route-active-listing-filter";
import RouteActiveListingFilterType from "../common/types/route-active-listing-filter-type";
import RouteActiveListingSortKey from "../common/types/route-active-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: RouteActiveListingRouteQueryParams
): RouteActiveListingFilter[] => {
  const filters: RouteActiveListingFilter[] = [];

  if (routeQueryFilterParams.cargoOrderHumanId) {
    const cargoOrderHumanIdFilter: RouteActiveListingCargoOrderHumanIdFilter = {
      type: RouteActiveListingFilterType.CARGO_ORDER_HUMAN_ID,
      value: Number(routeQueryFilterParams.cargoOrderHumanId),
    };

    filters.push(cargoOrderHumanIdFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: RouteActiveListingDriverFilter = {
      type: RouteActiveListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: RouteActiveListingPassengerFilter = {
      type: RouteActiveListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: RouteActiveListingRouteAddressFilter = {
      type: RouteActiveListingFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.humanId) {
    const humanIdFilter: RouteActiveListingHumanIdFilter = {
      type: RouteActiveListingFilterType.HUMAN_ID,
      value: Number(routeQueryFilterParams.humanId),
    };

    filters.push(humanIdFilter);
  }

  if (routeQueryFilterParams.taxiCorporation) {
    const taxiCorporationFilter: RouteActiveListingTaxiCorporationFilter = {
      type: RouteActiveListingFilterType.TAXI_CORPORATION,
      value: routeQueryFilterParams.taxiCorporation,
    };

    filters.push(taxiCorporationFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: RouteActiveListingRouteQueryParams
): RouteActiveListingSortKey | undefined => {
  return routeQueryParams.sort as RouteActiveListingSortKey | undefined;
};

const createRouteQueryParams = (
  filters: RouteActiveListingFilter[],
  sortKey: RouteActiveListingSortKey | null,
  page: number,
  pageSize: number
): RouteActiveListingRouteQueryParams => {
  const cargoOrderHumanIdFilterValue = filters.find(
    (filter) =>
      filter.type === RouteActiveListingFilterType.CARGO_ORDER_HUMAN_ID
  )?.value as RouteActiveListingCargoOrderHumanIdFilter["value"] | undefined;
  const humanIdFilterValue = filters.find(
    (filter) => filter.type === RouteActiveListingFilterType.HUMAN_ID
  )?.value as RouteActiveListingHumanIdFilter["value"] | undefined;

  return {
    page: String(page),
    pageSize: String(pageSize),
    sort: sortKey ?? undefined,
    cargoOrderHumanId: cargoOrderHumanIdFilterValue
      ? String(cargoOrderHumanIdFilterValue)
      : undefined,
    driver: filters.find(
      (filter) => filter.type === RouteActiveListingFilterType.DRIVER
    )?.value as RouteActiveListingDriverFilter["value"] | undefined,
    passenger: filters.find(
      (filter) => filter.type === RouteActiveListingFilterType.PASSENGER
    )?.value as RouteActiveListingPassengerFilter["value"] | undefined,
    routeAddress: filters.find(
      (filter) => filter.type === RouteActiveListingFilterType.ROUTE_ADDRESS
    )?.value as RouteActiveListingRouteAddressFilter["value"] | undefined,
    humanId: humanIdFilterValue ? String(humanIdFilterValue) : undefined,
    taxiCorporation: filters.find(
      (filter) => filter.type === RouteActiveListingFilterType.TAXI_CORPORATION
    )?.value as RouteActiveListingTaxiCorporationFilter["value"] | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: RouteActiveListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: RouteActiveListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const routeActiveListingFilterService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default routeActiveListingFilterService;
