import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import { RouteDetailsPassenger } from "../../../../../../common/services/route/details/route-details";
import classNames from "classnames";
import routeDetailsHelper from "../../route-details.helper";
import RouteDetailsRouteWaypoint from "../../types/route-details-route-waypoint";

type RouteDetailsPassengersProps = {
  passengers: RouteDetailsPassenger[];
  selectedPassenger: RouteDetailsPassenger | null;
  onPassengerRowClick: (selectedPassenger: RouteDetailsPassenger) => void;
  waypoints: RouteDetailsRouteWaypoint[];
};

const RouteDetailsPassengersComponent: FC<RouteDetailsPassengersProps> = (
  props
) => {
  const translations =
    routeTranslationsHelper.getDetailsTranslations().passengers;

  return (
    <div className="route_details_passengers">
      <div>{translations.headingLabel}</div>
      <ul className="route_details_passengers_list">
        {props.passengers.map((passenger) => {
          const isSelected = props.selectedPassenger?.uuid === passenger.uuid;

          return (
            <li
              className={classNames(
                "route_details_passengers_item",
                isSelected && "selected"
              )}
              key={`passenger-${passenger.uuid}`}
              onClick={() => props.onPassengerRowClick(passenger)}
              title={routeDetailsHelper.getPassengerTitle(
                passenger,
                props.waypoints
              )}
            >
              <FontAwesomeIcon
                icon={faUser}
                className="route_details_passengers_item__icon"
              />
              <div className="route_details_passengers_item__name">
                {routeDetailsHelper.getPassengerDisplayName(passenger)}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RouteDetailsPassengersComponent;
