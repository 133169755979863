import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import BillingsTaxiDriverContributionsContractCorrectionTableColumn from "../types/billings-taxi-driver-contributions-contract-correction-table-column";

const getRequiredColumnHeading = (heading: string) => (
  <>
    {heading} <span className="header_asterisk">*</span>
  </>
);

const getColumns =
  (): BillingsTaxiDriverContributionsContractCorrectionTableColumn[] => {
    const translations =
      billingsTranslationsHelper.getTaxiDriverContributionsTranslations().bonus
        .table.headers;

    const tableColumns: BillingsTaxiDriverContributionsContractCorrectionTableColumn[] =
      [
        {
          header: translations.typeLabel,
          title: translations.typeTitle,
          accessor: "type",
          colSpan: 10,
        },
        {
          header: getRequiredColumnHeading(translations.distanceLabel),
          title: translations.distanceTitle,
          accessor: "distance",
          colSpan: 10,
        },
        {
          header: getRequiredColumnHeading(translations.rateLabel),
          title: translations.rateTitle,
          accessor: "rate",
          colSpan: 10,
        },
        {
          header: getRequiredColumnHeading(translations.amountLabel),
          title: translations.amountTitle,
          accessor: "amount",
          colSpan: 10,
        },
        {
          header: "",
          title: "",
          accessor: "actions",
          colSpan: 5,
        },
      ];

    return tableColumns;
  };

const BillingsTaxiDriverContributionsContractCorrectionTableHelper = {
  getColumns,
};

export default BillingsTaxiDriverContributionsContractCorrectionTableHelper;
