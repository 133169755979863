import { FormValidationDefinition } from "../../../../common/components/form/use-form";
import cargoTranslationsHelper from "../../../../languages/cargo-translations.helper";
import cargoAddressFormValidationService from "./cargo-address-form-validation.service";
import CargoAddressType from "./types/cargo-address-type";
import CargoAddressTypeSelectOption from "./types/cargo-address-type-select-option";
import CargoAddressFormData from "./types/cargo-address-form-data";

const getAddressTypeSelectOptions = (): CargoAddressTypeSelectOption[] => {
  const translations =
    cargoTranslationsHelper.getAddressAddTranslations().address.addressType;
  return [
    {
      label: translations.HOTEL,
      value: CargoAddressType.HOTEL,
    },
    {
      label: translations.MEETING_POINT,
      value: CargoAddressType.MEETING_POINT,
    },
    {
      label: translations.STATION,
      value: CargoAddressType.STATION,
    },
  ];
};

const getEmptyFormData = (): CargoAddressFormData => {
  return {
    addressType: null,
    street: "",
    houseNumber: "",
    apartmentNumber: "",
    zipCode: "",
    country: "",
    town: "",
    description: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<CargoAddressFormData> => {
    return {
      addressType: (values) =>
        cargoAddressFormValidationService.validateAddressType(
          values.addressType
        ),
      street: (values) =>
        cargoAddressFormValidationService.validateStreet(values.street),
      houseNumber: (values) =>
        cargoAddressFormValidationService.validateHouseNumber(
          values.houseNumber
        ),
      apartmentNumber: (values) =>
        cargoAddressFormValidationService.validateApartmentNumber(
          values.apartmentNumber
        ),
      town: (values) =>
        cargoAddressFormValidationService.validateTown(values.town),
      country: (values) =>
        cargoAddressFormValidationService.validateCountry(values.country),
      zipCode: (values) =>
        cargoAddressFormValidationService.validateZipCode(values.zipCode),
      description: (values) =>
        cargoAddressFormValidationService.validateDescription(
          values.description
        ),
    };
  };

const cargoAddressFormHelper = {
  getAddressTypeSelectOptions,
  getEmptyFormData,
  getValidationDefinition,
};

export default cargoAddressFormHelper;
