import { FC } from "react";
import OrderFinishedListingFilter from "../common/types/order-finished-listing-filter";
import OrderFinishedListingSortKey from "../common/types/order-finished-listing-sort-key";
import ListingFilterDefinition from "../../../../common/components/listing/filter/types/listing-filter-definition";
import orderFinishedListingFilterHelper from "./order-finished-listing-filters.helper";
import ListingSortDefinition from "../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../common/components/listing/filter/listing-filter.component";

type OrderFinishedListingFilterProps = {
  filters: OrderFinishedListingFilter[];
  onFiltersChange: (filters: OrderFinishedListingFilter[]) => void;
  sortKey: OrderFinishedListingSortKey;
  onSortKeyChange: (sortKey: OrderFinishedListingSortKey) => void;
};

const OrderFinishedListingFilterComponent: FC<
  OrderFinishedListingFilterProps
> = (props) => {
  const filterDefinition: ListingFilterDefinition<OrderFinishedListingFilter> =
    orderFinishedListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    orderFinishedListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as OrderFinishedListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as OrderFinishedListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="order-finished-listing-filter"
      idForTestingSort="order-finished-listing-sort"
    />
  );
};

export default OrderFinishedListingFilterComponent;
