import { FC } from "react";
import ListingFilterDefinition from "../../../../common/components/listing/filter/types/listing-filter-definition";
import mileageListingFilterHelper from "./mileage-listing-filters.helper";
import ListingSortDefinition from "../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../common/components/listing/filter/listing-filter.component";
import MileageListingFilter from "../common/types/mileage-listing-filter";
import MileageListingSortKey from "../common/types/mileage-listing-sort-key";

type MileageListingFilterProps = {
  filters: MileageListingFilter[];
  onFiltersChange: (filters: MileageListingFilter[]) => void;
  sortKey: MileageListingSortKey;
  onSortKeyChange: (sortKey: MileageListingSortKey) => void;
};

const MileageListingFilterComponent: FC<MileageListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<MileageListingFilter> =
    mileageListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    mileageListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as MileageListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as MileageListingSortKey)
      }
      sortDefinition={sortDefinition}
    />
  );
};

export default MileageListingFilterComponent;
