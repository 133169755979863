import Joi from "joi";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";

const validateName = (name: string): FormValidationResult => {
  const validationSchema = Joi.string().min(3).max(200).required();

  return formValidationService.validate(name, validationSchema);
};

const validateDisplayName = (displayName: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(40).required();

  return formValidationService.validate(displayName, validationSchema);
};

const validateAddress = (address: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(500).required();

  return formValidationService.validate(address, validationSchema);
};

const cargoDispatchFormValidationService = {
  validateName,
  validateDisplayName,
  validateAddress,
};

export default cargoDispatchFormValidationService;
