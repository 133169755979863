import railyApiService from "../raily-api.service";
import TaxiDetailsRequest from "./details/taxi-details.request";
import TaxiDetailsResponse from "./details/taxi-details.response";
import taxiCorporationListRequestFactory from "./list/taxi-corporation-list-request.factory";
import TaxiCorporationListRequest, {
  TaxiCorporationListRequestQueryParams,
} from "./list/taxi-corporation-list.request";
import TaxiCorporationListResponse from "./list/taxi-corporation-list.response";

const taxiCorporationApiService = () => {
  const getDetails = async (
    request: TaxiDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiDetailsResponse> => {
    const url = `/taxi-corporations/${request.taxiCorporationUuid}`;

    return railyApiService().get<TaxiDetailsResponse>({ url, abortSignal });
  };

  const getList = (
    request: TaxiCorporationListRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiCorporationListResponse> => {
    const url = `/taxi-corporations`;

    const queryParams: TaxiCorporationListRequestQueryParams =
      taxiCorporationListRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  return { getDetails, getList };
};

export default taxiCorporationApiService;
