import paginationService from "../../../../../../common/utils/pagination/pagination.service";
import TaxiTransferedOrderSettlementListingRequest, {
  TaxiTransferedOrderSettlementListingRequestOrder,
} from "../api/taxi-transfered-order-settlement-listing.request";
import TaxiTransferedOrderSettlementListingFilter, {
  TaxiTransferedOrderSettlementListingConsumerTaxiFilter,
  TaxiTransferedOrderSettlementListingInternalOrderIdFilter,
  TaxiTransferedOrderSettlementListingOrderStartDateFilter,
  TaxiTransferedOrderSettlementListingProducerTaxiFilter,
  TaxiTransferedOrderSettlementListingStatusFilter,
} from "../types/taxi-transfered-order-settlement-listing-filter";
import TaxiTransferedOrderSettlementListingFilterType from "../types/taxi-transfered-order-settlement-listing-filter-type";
import TaxiTransferedOrderSettlementListingSortKey from "../types/taxi-transfered-order-settlement-listing-sort-key";

const createRequestOrder = (
  sortKey: TaxiTransferedOrderSettlementListingSortKey | null
): TaxiTransferedOrderSettlementListingRequest["order"] => {
  const options: {
    sortKey: TaxiTransferedOrderSettlementListingSortKey;
    requestOrder: TaxiTransferedOrderSettlementListingRequestOrder;
  }[] = [
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.DATE_AT_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.ORDER_START_DATE_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.DATE_AT_DESC,
      sortKey:
        TaxiTransferedOrderSettlementListingSortKey.ORDER_START_DATE_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.HUMAN_ID_ASC,
      sortKey:
        TaxiTransferedOrderSettlementListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.HUMAN_ID_DESC,
      sortKey:
        TaxiTransferedOrderSettlementListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.DISTANCE_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.DISTANCE_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.DISTANCE_RATE_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.DISTANCE_RATE_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.HALTING_AMOUNT_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.STOPOVER_COST_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.HALTING_AMOUNT_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.STOPOVER_COST_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.TOTAL_COST_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.PRODUCER_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.PRODUCER_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.PRODUCER_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.PRODUCER_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.CONSUMER_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.CONSUMER_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.CONSUMER_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.CONSUMER_DESC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.BILLING_MODEL_ASC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.BILLING_MODEL_ASC,
    },
    {
      requestOrder:
        TaxiTransferedOrderSettlementListingRequestOrder.BILLING_MODEL_DESC,
      sortKey: TaxiTransferedOrderSettlementListingSortKey.BILLING_MODEL_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  taxiCorporationId: string | undefined,
  page: number,
  pageSize: number,
  filters: TaxiTransferedOrderSettlementListingFilter[],
  sortKey: TaxiTransferedOrderSettlementListingSortKey | null
): TaxiTransferedOrderSettlementListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    limit: pageSize,
    offset,
    order: createRequestOrder(sortKey),
    taxi_corporation_id: taxiCorporationId,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | TaxiTransferedOrderSettlementListingInternalOrderIdFilter["value"]
      | undefined,
    producer: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.PRODUCER_TAXI
    )?.value as
      | TaxiTransferedOrderSettlementListingProducerTaxiFilter["value"]
      | undefined,
    consumer: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.CONSUMER_TAXI
    )?.value as
      | TaxiTransferedOrderSettlementListingConsumerTaxiFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiTransferedOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiTransferedOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    status: filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.BILLING_STATUS
    )?.value as
      | TaxiTransferedOrderSettlementListingStatusFilter["value"]
      | undefined,
  };
};

const taxiTransferedOrderSettlementListingRequestFactory = {
  createRequest,
};

export default taxiTransferedOrderSettlementListingRequestFactory;
