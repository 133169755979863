import BillingsOrderTaxiDriverResponse, {
  BillingsOrderTaxiDriverResponseItem,
} from "../../../../api/raily/billings/order/taxi-driver/billings-order-taxi-driver.response";
import BillingModel from "../../../../types/billing-model";
import BillingsOrderTaxiDriver, {
  BillingsOrderTaxiDriverItem,
} from "./billings-order-taxi-driver";

const createDataItem = (
  data: BillingsOrderTaxiDriverResponseItem
): BillingsOrderTaxiDriverItem => {
  return {
    billingUuid: data.id,
    contractDetails: {
      companyName:
        data.contract.taxiDriverAssociation.taxiCorporation.displayName,
      distanceRate: data.contract.distanceRate,
      model: data.contract.model as BillingModel,
    },
    baseAmountValue: data.baseAmountValue,
    amountForChargeHaltings: data.amountForChargeHaltings,
    amountForChargeHighways: data.amountForChargeHighways,
    distance: data.distance,
    amountForDistance: data.amountForDistance,
    internalOrderId: data.planEntry.humanId,
    allContributionsAmount: data.allContributionsAmount,
    isDraft: data.draft,
    date: data.date,
    sumOfBonuses: data.sumOfBonuses,
    sumOfPenalties: data.sumOfPenalties,
  };
};

const createData = (
  responseData: BillingsOrderTaxiDriverResponseItem[]
): BillingsOrderTaxiDriverItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: BillingsOrderTaxiDriverResponse
): BillingsOrderTaxiDriver => {
  return {
    data: createData(response.data),
  };
};

const billingsOrderTaxiDriverFactory = {
  create,
};

export default billingsOrderTaxiDriverFactory;
