import classNames from "classnames";
import { FC, ReactNode } from "react";
import ComponentClassnames from "../../types/component-classnames";
import NoticeBoxType from "./notice-box-type";
import noticeBoxHelper from "./notice-box.helper";

type NoticeBoxProps = {
  children: ReactNode;
  classNames?: ComponentClassnames & {
    content?: string;
  };
  type?: NoticeBoxType;
};

const NoticeBoxComponent: FC<NoticeBoxProps> = (props) => {
  const rootClassnameByType = noticeBoxHelper.getRootClassnameByType(
    props.type!
  );

  const rootClassnames = classNames(
    "notice_box",
    props.classNames?.root,
    rootClassnameByType
  );
  const contentClassnames = classNames(
    "notice_box_content",
    props.classNames?.content
  );

  return (
    <div className={rootClassnames}>
      <div className={contentClassnames}>{props.children}</div>
    </div>
  );
};

NoticeBoxComponent.defaultProps = {
  type: NoticeBoxType.PRIMARY,
};

export default NoticeBoxComponent;
