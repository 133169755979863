import { round } from "lodash";

const getTimeDeltaLabel = (timeDeltaSeconds: number): string => {
  const days = Math.floor(timeDeltaSeconds / 86400);
  const hours = Math.floor((timeDeltaSeconds % 86400) / 3600);
  const minutes = Math.floor((timeDeltaSeconds % 3600) / 60);

  const daysLabel = `${days}D `;
  const hoursLabel = `${hours}H `;
  const minutesLabel = `${minutes}M`;

  return `${days !== 0 ? daysLabel : ""}${
    hours !== 0 ? hoursLabel : ""
  }${minutesLabel}`;
};

const getTimeDelta = (startDate: Date, endDate?: Date): number => {
  const startTime = startDate.getTime();
  const endTime = endDate ? endDate.getTime() : Date.now();

  return Math.floor((startTime - endTime) / 1000);
};

const getDistanceLabel = (distance: number) => {
  return `${round(distance, 2)} km`;
};

const orderOptimizerListingHelper = {
  getTimeDelta,
  getTimeDeltaLabel,
  getDistanceLabel,
};

export default orderOptimizerListingHelper;
