import appConfig from "../../../../app.config";
import authService from "../../../auth/common/auth.service";
import UserCheckExistsResponse from "./user-check-exists.response";
import UserDeleteResponse from "./user-delete.response";

const fetchIsUserExist = async (
  username: string
): Promise<UserCheckExistsResponse> => {
  const path = `/users/exists/${username}`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const deleteUser = async (userUuid: string): Promise<UserDeleteResponse> => {
  const path = `/users/${userUuid}`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const userApiService = {
  fetchIsUserExist,
  deleteUser,
};

export default userApiService;
