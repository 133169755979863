import useAsyncData from "../../../../hooks/use-async-data";
import billingService from "../../billings.service";
import BillingsOrderAddresses from "./billings-order-addresses";
import BillingsOrderAddressesLoadParams from "./billings-order-addresses-load-params";

const useBillingsOrderAddresses = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<BillingsOrderAddresses>([], false);

  const load = async (
    params: BillingsOrderAddressesLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const billingsOrderAddresses = await billingService.getOrderAddresses(
        params,
        signal
      );

      setData(billingsOrderAddresses);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useBillingsOrderAddresses;
