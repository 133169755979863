enum OrderAbandonedListingSortKey {
  CARGO_COMPANY_INTERNAL_ID_ASC = "CARGO_COMPANY_INTERNAL_ID_ASC",
  CARGO_COMPANY_INTERNAL_ID_DESC = "CARGO_COMPANY_INTERNAL_ID_DESC",
  MODIFIED_ASC = "MODIFIED_ASC",
  MODIFIED_DESC = "MODIFIED_DESC",
  CREATED_ASC = "CREATED_ASC",
  CREATED_DESC = "CREATED_DESC",
}

export default OrderAbandonedListingSortKey;
