import HttpResponse from "../../../../../../../common/utils/http/http.response";

export enum DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryType {
  RESTRICTED = "RESTRICTED",
  FULL = "FULL",
}

export type DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryRideNode =
  {
    display_name: string;
    lat: number;
    lon: number;
    time: string | null;
    halting_time: number | null;
    meta: {
      estimated_time: string;
      id: string;
    };
  };

export type DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryStartNode =
  DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryRideNode;

export type DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryEndNode =
  DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryRideNode;

export type DriverDetailsRoutePlansAfterCandidatureApprovalResponseRestrictedPlanEntry =
  {
    id: string;
    human_id: number;
    view_type: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryType.RESTRICTED;
    start_node: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryStartNode;
    end_node: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryEndNode;
  };

export type DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntrySolvedOrder =
  {
    ride: {
      seq: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryRideNode[];
    };
    transporting_order: {
      cargo_order: {
        id: string;
        human_id: number;
      };
    };
    cargo_company: {
      id: string;
      display_name: string;
    };
  };

export type DriverDetailsRoutePlansAfterCandidatureApprovalResponseFullPlanEntry =
  {
    id: string;
    human_id: number | null;
    view_type: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryType.FULL;
    start_node: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryStartNode;
    end_node: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryEndNode;
    solved_orders: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntrySolvedOrder[];
  };

export type DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry =
  | DriverDetailsRoutePlansAfterCandidatureApprovalResponseRestrictedPlanEntry
  | DriverDetailsRoutePlansAfterCandidatureApprovalResponseFullPlanEntry;

type DriverDetailsRoutePlansAfterCandidatureApprovalResponseData = {
  entries: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry[];
};

type DriverDetailsRoutePlansAfterCandidatureApprovalResponse =
  HttpResponse<DriverDetailsRoutePlansAfterCandidatureApprovalResponseData>;

export default DriverDetailsRoutePlansAfterCandidatureApprovalResponse;
