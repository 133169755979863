import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryRemoveCargoEnterChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";

type OrderDetailsHistoryEntryChangeRideContentRemoveCargoEnterProps = {
  event: OrderDetailsHistoryEntryRemoveCargoEnterChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentRemoveCargoEnterComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentRemoveCargoEnterProps
> = () => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .removeCargoEnter;

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentRemoveCargoEnterComponent;
