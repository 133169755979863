import { FC, Fragment } from "react";
import OrderOptimizerListingTableRowRouteOrderComponent from "./order/order-optimizer-listing-table-row-route-order.component";
import OrderOptimizerListingTableRowRouteEmptyRideComponent from "./empty-rides/order-optimizer-listing-table-row-route-empty-rides.component";
import OrderOptimizerListingItem from "../../../common/types/order-optimizer-listing-item";

type OrderOptimizerTableRowRouteProps = {
  listingItem: OrderOptimizerListingItem;
  selectedHuhmanId: number | undefined;
};

const OrderOptimizerListingTableRowRouteComponent: FC<
  OrderOptimizerTableRowRouteProps
> = (props) => {
  return (
    <div className="order_optimizer_table_row_route">
      {props.listingItem.orders.map((order, index) => (
        <Fragment
          key={`optimization-${props.listingItem.uuid}-fregment-${index}`}
        >
          {index > 0 && (
            <>
              <OrderOptimizerListingTableRowRouteEmptyRideComponent
                key={`optimization-${props.listingItem.uuid}-empty-ride-${index}`}
                emptyRide={props.listingItem.emptyRides[index - 1]}
              />
              <div
                key={`optimization-${props.listingItem.uuid}-dot-left-${index}`}
                className="order_optimizer_table_row_route__dot left"
              />
            </>
          )}
          <OrderOptimizerListingTableRowRouteOrderComponent
            key={`optimization-${props.listingItem.uuid}-order-${order.humanId}`}
            order={order}
            isSelected={order.humanId === props.selectedHuhmanId}
          />
          {index < props.listingItem.orders.length - 1 && (
            <div
              key={`optimization-${props.listingItem.uuid}-dot-right-${index}`}
              className="order_optimizer_table_row_route__dot right"
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default OrderOptimizerListingTableRowRouteComponent;
