import RailyOfficerListResponse, {
  RailyOfficerListResponseItem,
} from "../../../api/raily/raily-officer/list/raily-officer-list.response";
import RailyOfficerList, { RailyOfficerListItem } from "./raily-officer-list";

const createAlternativePhoneNumber = (
  responseDataItem: RailyOfficerListResponseItem
): RailyOfficerListItem["alternativePhoneNumber"] => {
  if (
    !responseDataItem.mobileSecondary ||
    !responseDataItem.mobileSecondaryPrefix
  ) {
    return null;
  }

  return {
    dialingCode: responseDataItem.mobileSecondaryPrefix,
    number: responseDataItem.mobileSecondary,
  };
};

const createDataItem = (
  responseDataItem: RailyOfficerListResponseItem
): RailyOfficerListItem => {
  return {
    creationDate: responseDataItem.createdAt,
    email: responseDataItem.email,
    firstName: responseDataItem.firstName,
    lastName: responseDataItem.lastName,
    phoneNumber: {
      dialingCode: responseDataItem.mobilePrimaryPrefix,
      number: responseDataItem.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(responseDataItem),
    removalDate: responseDataItem.removedAt,
    username: responseDataItem.username,
    uuid: responseDataItem.id,
  };
};

const createData = (
  responseData: RailyOfficerListResponseItem[]
): RailyOfficerListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: RailyOfficerListResponse): RailyOfficerList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const railyOfficerListFactory = {
  create,
};

export default railyOfficerListFactory;
