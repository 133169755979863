import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import CargoOfficerAddComponent from "../../cargo-officer/add/cargo-officer-add.component";
import DealerAddComponent from "../../dealer/add/dealer-add.component";
import DispatcherListingComponent from "../../dispatcher/listing/dispatcher-listing.component";
import DriverAddComponent from "../../driver/add/driver-add.component";
import userRoutesUrls from "./user-routes-urls";
import DispatcherAddComponent from "../../dispatcher/add/dispatcher-add.component";
import PassengerAddComponent from "../../passenger/add/passenger-add.component";
import PassengerListingComponent from "../../passenger/listing/passenger-listing.component";
import RailyOfficerAddComponent from "../../raily-officer/add/raily-officer-add.component";
import TaxiOfficerListingComponent from "../../taxi-officer/listing/taxi-officer-listing.component";
import TaxiOfficerAddComponent from "../../taxi-officer/add/taxi-officer-add.component";
import DriverListingComponent from "../../driver/listing/driver-listing.component";
import OperatorAddComponent from "../../operator/add/operator-add.component";
import DriverContractListingComponent from "../../driver/contract/listing/driver-contract-listing.component";
import DriverContractAddComponent from "../../driver/contract/add/driver-contract-add.component";
import PassengerEditComponent from "../../passenger/edit/passenger-edit.component";
import DriverEditComponent from "../../driver/edit/driver-edit.component";
import DriverPlanComponent from "../../driver/plan/driver-plan.component";
import OperatorListingComponent from "../../operator/listing/operator-listing.component";
import CargoOfficerListingComponent from "../../cargo-officer/listing/cargo-officer-listing.component";
import RailyOfficerListingComponent from "../../raily-officer/listing/raily-officer-listing.component";
import DealerListingComponent from "../../dealer/listing/dealer-listing.component";
import DispatcherEditComponent from "../../dispatcher/edit/dispatcher-edit.component";
import DriverContractEditComponent from "../../driver/contract/edit/driver-contract-edit.component";
import TaxiOfficerEditComponent from "../../taxi-officer/edit/taxi-officer-edit.component";

const userRoutesDefinition: RouteItemDefinition[] = [
  {
    path: userRoutesUrls.cargoOfficerAdd,
    component: <CargoOfficerAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.cargoOfficerListing,
    component: <CargoOfficerListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.dealerAdd,
    component: <DealerAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.dispatcherListing,
    component: <DispatcherListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.passengerAdd,
    component: <PassengerAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: userRoutesUrls.passengerListing,
    component: <PassengerListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: userRoutesUrls.passengerEdit,
    component: <PassengerEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: userRoutesUrls.driverAdd,
    component: <DriverAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.OPERATOR,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.driverEdit,
    component: <DriverEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.driverPlan,
    component: <DriverPlanComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
      UserRole.DEALER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: userRoutesUrls.railyOfficerAdd,
    component: <RailyOfficerAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.dispatcherAdd,
    component: <DispatcherAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.dispatcherEdit,
    component: <DispatcherEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.taxiOfficerListing,
    component: <TaxiOfficerListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.dealerListing,
    component: <DealerListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.taxiOfficerAdd,
    component: <TaxiOfficerAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.taxiOfficerEdit,
    component: <TaxiOfficerEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.driverListing,
    component: <DriverListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.OPERATOR,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.railyOfficerListing,
    component: <RailyOfficerListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.OPERATOR,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.driverContractListing,
    component: <DriverContractListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.OPERATOR,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: userRoutesUrls.driverContractAdd,
    component: <DriverContractAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: userRoutesUrls.driverContractEdit,
    component: <DriverContractEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: userRoutesUrls.operatorAdd,
    component: <OperatorAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: userRoutesUrls.operatorListing,
    component: <OperatorListingComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
];

export default userRoutesDefinition;
