import PlannerDriverPlanListRequest from "../../api/raily/planner/list/planner-driver-plan-list.request";
import PlannerDriverPlanOrderRequest from "../../api/raily/planner/order/planning-driver-plan-order.request";
import plannerApiService from "../../api/raily/planner/planner-driver-plan-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import PlannerDriverPlanList from "./list/planner-driver-plan-list";
import PlannerDriverPlanListError from "./list/planner-driver-plan-list-error";
import PlannerDriverPlanListLoadParams from "./list/planner-driver-plan-list-load-params";
import plannerRequestFactory from "./list/planner-driver-plan-list-request.factory";
import plannerDriverPlanListFactory from "./list/planner-driver-plan-list.factory";
import PlannerDriverPlanOrder from "./order/planner-driver-plan-order";
import PlannerDriverPlanOrderError from "./order/planner-driver-plan-order-error";
import PlannerDriverPlanOrderLoadParams from "./order/planner-driver-plan-order-load-params";
import plannerDriverPlanOrderRequestFactory from "./order/planner-driver-plan-order-request.factory";
import plannerDriverPlanOrderFactory from "./order/planner-driver-plan-order.factory";

const handlePlanListError = (
  error: HttpError | PlannerDriverPlanListError
): PlannerDriverPlanListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getData = async (
  params: PlannerDriverPlanListLoadParams,
  abortSignal: AbortSignal
): Promise<PlannerDriverPlanList> => {
  const requestParams: PlannerDriverPlanListRequest =
    plannerRequestFactory.create(params);

  try {
    const response = await plannerApiService().getPlanList(
      requestParams,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return plannerDriverPlanListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | PlannerDriverPlanListError;

    throw handlePlanListError(error);
  }
};

const handleOrderError = (
  error: HttpError | PlannerDriverPlanOrderError
): PlannerDriverPlanOrderError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getOrder = async (
  params: PlannerDriverPlanOrderLoadParams,
  abortSignal: AbortSignal
): Promise<PlannerDriverPlanOrder> => {
  const request: PlannerDriverPlanOrderRequest =
    plannerDriverPlanOrderRequestFactory.create(params);

  try {
    const response = await plannerApiService().getPlanOrder(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return plannerDriverPlanOrderFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | PlannerDriverPlanOrderError;

    throw handleOrderError(error);
  }
};

const plannerDriverPlanService = {
  getData,
  getOrder,
};

export default plannerDriverPlanService;
