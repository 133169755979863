import classNames from "classnames";
import { FC } from "react";
import { SteppedLineTo } from "react-lineto";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import orderRouteEditSummaryRouteHelper from "./order-route-edit-summary-route.helper";
import OrderRouteEditSummaryRouteWaypoint from "./types/order-route-edit-summary-route-waypoint";

type OrderRouteEditSummaryRouteProps = {
  waypoints: OrderRouteEditSummaryRouteWaypoint[];
  selectedPassengerUuid: string | null;
};

const OrderRouteEditSummaryRouteComponent: FC<
  OrderRouteEditSummaryRouteProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getEditTranslations().summary.routes;

  const lineConnections =
    orderRouteEditSummaryRouteHelper.getDefinitionOfLineConnectionsBetweenWaypoints(
      props.waypoints
    );

  return (
    <div className="order_edit_summary_route">
      <div className="order_edit_summary_route_waypoint_list">
        <div className="order_edit_summary_route_waypoint_list__header">
          <div
            className="order_edit_summary_route_waypoint_list__heading place"
            title={translations.placeHeaderTitle}
          >
            {translations.placeHeaderLabel}
          </div>
          <div
            className="order_edit_summary_route_waypoint_list__heading ordered_date"
            title={translations.orderedDateHeaderTitle}
          >
            {translations.orderedDateHeaderLabel}
          </div>
          <div
            className="order_edit_summary_route_waypoint_list__heading estimated_date"
            title={translations.estimatedDateHeaderTitle}
          >
            {translations.estimatedDateHeaderLabel}
          </div>
          <div
            className="order_edit_summary_route_waypoint_list__heading halting_time"
            title={translations.haltingTimeHeaderTitle}
          >
            {translations.haltingTimeHeaderLabel}
          </div>
          <div
            className="order_edit_summary_route_waypoint_list__heading distance"
            title={translations.distanceHeaderTitle}
          >
            {translations.distanceHeaderLabel}
          </div>
        </div>
        {props.waypoints.map((waypoint, index) => {
          const isHighlightedAsPassengerOnboardPlace =
            props.selectedPassengerUuid &&
            waypoint.onboardingPassengers.find(
              (onboardingPassenger) =>
                onboardingPassenger.uuid === props.selectedPassengerUuid
            );

          const isHighlightedAsPassengerOutboardPlace =
            props.selectedPassengerUuid &&
            waypoint.outboardingPassengers.find(
              (outboardingPassenger) =>
                outboardingPassenger.uuid === props.selectedPassengerUuid
            );

          const isFaded =
            props.selectedPassengerUuid &&
            !isHighlightedAsPassengerOnboardPlace &&
            !isHighlightedAsPassengerOutboardPlace;

          return (
            <div
              className={classNames(
                "order_edit_summary_route_waypoint_list__row",
                isHighlightedAsPassengerOnboardPlace && "onboard_highlight",
                isHighlightedAsPassengerOutboardPlace && "outboard_highlight",
                isFaded && "faded"
              )}
              key={`order-route-edit-summary-waypoint-index-${index}`}
            >
              <div
                className="order_edit_summary_route_waypoint_list__row_cell place"
                title={orderRouteEditSummaryRouteHelper.getPlaceTitle(
                  waypoint.place
                )}
              >
                <div className="index">{waypoint.stageNo}</div>
                <div
                  className={classNames(
                    "dot",
                    orderRouteEditSummaryRouteHelper.generateWaypointDotClassname(
                      waypoint
                    )
                  )}
                ></div>
                <div className="place_name">
                  {orderRouteEditSummaryRouteHelper.getPlaceLabel(
                    waypoint.place
                  )}
                </div>
              </div>
              <div
                title={orderRouteEditSummaryRouteHelper.getOrderedDateTitle(
                  waypoint.orderedDate
                )}
                className="order_edit_summary_route_waypoint_list__row_cell ordered_date"
              >
                {orderRouteEditSummaryRouteHelper.getOrderedDateLabel(
                  waypoint.orderedDate
                )}
              </div>
              <div
                title={orderRouteEditSummaryRouteHelper.getEstimatedDateTitle(
                  waypoint.estimatedDate
                )}
                className="order_edit_summary_route_waypoint_list__row_cell estimated_date"
              >
                {orderRouteEditSummaryRouteHelper.getEstimatedDateLabel(
                  waypoint.estimatedDate
                )}
              </div>
              <div className="order_edit_summary_route_waypoint_list__row_cell halting_time">
                {orderRouteEditSummaryRouteHelper.getHaltingTime(
                  waypoint.haltingTime
                )}
              </div>
              <div className="order_edit_summary_route_waypoint_list__row_cell distance">
                {orderRouteEditSummaryRouteHelper.getDistanceLabel(
                  waypoint.distance
                )}
              </div>
            </div>
          );
        })}
      </div>
      {lineConnections.map((item, index) => {
        return (
          <SteppedLineTo
            key={`waypoint-list-connection-line-index-${index}-type-${item.lineType}`}
            from={item.itemFromClassname}
            to={item.itemToClassname}
            delay={50}
            borderStyle={item.lineType}
            borderWidth={1}
            borderColor={"#000"}
            within={`order_edit_summary_route`}
            className="order_edit_summary__connection_line"
          />
        );
      })}
    </div>
  );
};

export default OrderRouteEditSummaryRouteComponent;
