import { faEnvelope, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import CardEditButtonComponent from "../../../../../../common/components/card/button/add/card-edit-button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../../common/context/sign-up-driver.context";
import SignUpDriverAddressType from "../../common/types/sign-up-driver-address-type";
import SignUpDriverFormStep from "../../common/types/sign-up-driver-form-step";
import signUpDriverSummaryHelper from "../sign-up-driver-summary.helper";

type SignUpDriverSummaryUserProps = {};

const SignUpDriverSummaryUserComponent: FC<
  SignUpDriverSummaryUserProps
> = () => {
  const userDataSummaryDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().summary.userData;

  const { userData, setCurrentFormStep } = useSignUpDriverContext();

  const onEditButtonClick = () => {
    setCurrentFormStep(SignUpDriverFormStep.userData);
  };

  return (
    <CardComponent
      header={{
        title: userDataSummaryDataFormTranslations.title,
        actions: [
          <CardEditButtonComponent
            onClick={onEditButtonClick}
            title={userDataSummaryDataFormTranslations.editButtonText}
          />,
        ],
      }}
    >
      <Row>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.firstNameLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.firstName}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.lastNameLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.lastName}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.mobileLabel}
            classNames={{ root: "mt-0" }}
          >
            {signUpDriverSummaryHelper.getMobileString(userData.mobile)}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.alternativeMobileLabel}
            classNames={{ root: "mt-0" }}
          >
            {signUpDriverSummaryHelper.getMobileString(
              userData.alternativeMobile
            )}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.birthDateLabel}
            classNames={{ root: "mt-0" }}
          >
            {signUpDriverSummaryHelper.getDateString(userData.birthDate!)}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.birthPlaceLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.birthPlace}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.personalIdNumberLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.personalIdNumber}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.citizenshipLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.citizenship!.label}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.languagesLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.languages.map((lang) => lang.label).join(", ")}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.experienceLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.yearsOfExperience}
          </FormFieldComponent>
        </Column>
        <Column md={6} lg={3}>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.formOfEmploymentLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.formOfEmployment!.label}
          </FormFieldComponent>
        </Column>
        <Column>
          <FormFieldComponent
            label={userDataSummaryDataFormTranslations.addressesLabel}
            classNames={{ root: "mt-0" }}
          >
            {userData.addresses.map((address) => {
              const MailingAddressIcon = (
                <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
              );
              const HouseAddressIcon = (
                <FontAwesomeIcon className="mr-2" icon={faHouse} />
              );

              const Icon =
                address.type === SignUpDriverAddressType.MAILING
                  ? MailingAddressIcon
                  : address.type === SignUpDriverAddressType.STARTING
                  ? HouseAddressIcon
                  : undefined;

              return (
                <div>
                  {Icon}
                  {signUpDriverSummaryHelper.getAddressString(address)}
                </div>
              );
            })}
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default SignUpDriverSummaryUserComponent;
