import { FC } from "react";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import TaxiTaxiContractListingFilter from "../common/types/taxi-taxi-contract-listing-filter";
import TaxiTaxiContractListingSortKey from "../common/types/taxi-taxi-contract-listing-sort-key";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import taxiTaxiContractListingFilterHelper from "./taxi-taxi-contract-listing-filters.helper";

type TaxiTaxiContractListingFilterProps = {
  filters: TaxiTaxiContractListingFilter[];
  onFiltersChange: (filters: TaxiTaxiContractListingFilter[]) => void;
  sortKey: TaxiTaxiContractListingSortKey;
  onSortKeyChange: (sortKey: TaxiTaxiContractListingSortKey) => void;
};

const TaxiTaxiContractListingFilterComponent: FC<
  TaxiTaxiContractListingFilterProps
> = (props) => {
  const filterDefinition: ListingFilterDefinition<TaxiTaxiContractListingFilter> =
    taxiTaxiContractListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    taxiTaxiContractListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(
          appliedFilters as TaxiTaxiContractListingFilter[]
        );
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as TaxiTaxiContractListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="taxi-taxi-contract-listing-filter"
      idForTestingSort="taxi-taxi-contract-listing-sort"
    />
  );
};

export default TaxiTaxiContractListingFilterComponent;
