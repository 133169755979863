import CargoCompanyDetails from "../../../../../../common/services/cargo-company/cargo-company/details/cargo-company-details";
import { CargoCompanyListItem } from "../../../../../../common/services/cargo-company/cargo-company/list/cargo-company-list";
import MileageDictionaryListingCompany from "../types/mileage-dictionary-listing-company";
import MileageDictionaryListingCompanySelectOption from "../types/mileage-dictionary-listing-company-select-options";

const createCompanyItem = (
  item: CargoCompanyListItem
): MileageDictionaryListingCompany => {
  return {
    displayName: item.displayName,
    uuid: item.uuid,
  };
};

const createCompanySelectOption = (
  company: MileageDictionaryListingCompany
): MileageDictionaryListingCompanySelectOption => {
  return {
    label: company.displayName,
    value: company,
  };
};

const createCompanySelectOptions = (
  data: CargoCompanyListItem[]
): MileageDictionaryListingCompanySelectOption[] => {
  const companies: MileageDictionaryListingCompany[] =
    data.map(createCompanyItem);
  const selectOptions: MileageDictionaryListingCompanySelectOption[] =
    companies.map(createCompanySelectOption);

  return selectOptions;
};

const createCompany = (
  details: CargoCompanyDetails
): MileageDictionaryListingCompany => {
  return {
    displayName: details.displayName,
    uuid: details.uuid,
  };
};

const mileageDictionaryListingCompanyFactory = {
  createCompanySelectOptions,
  createCompanySelectOption,
  createCompany,
};

export default mileageDictionaryListingCompanyFactory;
