import CargoOrderDetails, {
  CargoOrderDetailsItemCargoOrderCargoSourceExternal,
  CargoOrderDetailsItemCargoOrderCargoSourceType,
  CargoOrderDetailsItemCargoOrderRideMetaCargoSubject,
  CargoOrderDetailsItemCargoOrderRideSeq,
  CargoOrderDetailsItemCargoOrderRideSeqNode,
  CargoOrderDetailsItemCargoOrderRideSeqNodeSet,
  CargoOrderDetailsItemPassengerItem,
  CargoOrderDetailsItemPassengerItemPassengerExternal,
} from "../../../../../../common/services/cargo-order/details/cargo-order-details";
import OrderRouteEditRequestBody, {
  OrderRouteEditRequestExternalCargoItemSource,
  OrderRouteEditRequestInternalCargoItemSource,
  OrderRouteEditSeqGroupNodeRequest,
  OrderRouteEditSeqNodeRequest,
} from "../../../../route-edit/common/api/order-route-edit.request";

const matchPassengerFromList = (
  passengers: CargoOrderDetailsItemPassengerItem[],
  subject: CargoOrderDetailsItemCargoOrderRideMetaCargoSubject
): CargoOrderDetailsItemPassengerItem => {
  return passengers.find((passenger) => passenger.uuid === subject.uuid)!;
};

const createSeqNode = (
  node: CargoOrderDetailsItemCargoOrderRideSeqNode,
  passengers: CargoOrderDetailsItemPassengerItem[]
) => {
  const seqNode: OrderRouteEditSeqNodeRequest = {
    display_name: node.displayName,
    halting_time: node.haltingTime,
    lat: node.lat,
    lon: node.lon,
    time: null,
    meta: {
      id: node.meta.uuid,
      cargo_enter: {
        subjects: node.meta.cargoEnter.subjects.map((subject) => {
          return {
            id: matchPassengerFromList(passengers, subject).passenger.uuid,
          };
        }),
      },
      cargo_exit: {
        subjects: node.meta.cargoExit.subjects.map((subject) => {
          return {
            id: matchPassengerFromList(passengers, subject).passenger.uuid,
          };
        }),
      },
    },
  };

  return seqNode;
};

const createSeq = (
  seq: CargoOrderDetailsItemCargoOrderRideSeq,
  passengers: CargoOrderDetailsItemPassengerItem[],
  plannedStartTime: Date
): (OrderRouteEditSeqNodeRequest | OrderRouteEditSeqGroupNodeRequest)[] => {
  const patchSequence: (
    | OrderRouteEditSeqNodeRequest
    | OrderRouteEditSeqGroupNodeRequest
  )[] = [];

  let firstNode = true;

  seq.forEach((seqNode) => {
    const nodeSet = seqNode as CargoOrderDetailsItemCargoOrderRideSeqNodeSet;
    if (nodeSet.seq) {
      const seqNodeGroup: OrderRouteEditSeqGroupNodeRequest = {
        seq: nodeSet.seq.map((node) => createSeqNode(node, passengers)),
        meta: {
          id: nodeSet.meta.uuid,
        },
        sequence_order: "ANY",
      };

      patchSequence.push(seqNodeGroup);
    } else {
      const node = seqNode as CargoOrderDetailsItemCargoOrderRideSeqNode;

      const patchNode: OrderRouteEditSeqNodeRequest = createSeqNode(
        node,
        passengers
      );

      if (firstNode) {
        firstNode = false;
        patchNode.time = plannedStartTime;
      }

      patchSequence.push(patchNode);
    }
  });

  return patchSequence;
};

const createRequest = (
  details: CargoOrderDetails,
  plannedStartTime: Date
): OrderRouteEditRequestBody => {
  const rv_2: Record<
    string,
    | OrderRouteEditRequestInternalCargoItemSource
    | OrderRouteEditRequestExternalCargoItemSource
  > = {};

  const internalPassengers: CargoOrderDetailsItemPassengerItem[] = [];

  details.passengers.forEach((passenger) => {
    const externalId =
      details.cargoOrder.cargoItemsSourceList.find((item) => {
        item.cargoSubjectUuid == passenger.uuid;
      })?.cargoItemSource.externalId ?? "";

    const internalPassenger: CargoOrderDetailsItemPassengerItem = {
      uuid: passenger.uuid,
      passenger: {
        type: CargoOrderDetailsItemCargoOrderCargoSourceType.INTERNAL,
        addresses: passenger.passenger.addresses,
        externalId: externalId,
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        uuid: passenger.uuid,
      },
    };

    internalPassengers.push(internalPassenger);

    const internal: OrderRouteEditRequestInternalCargoItemSource = {
      source_type: "INTERNAL",
      external_id: externalId,
      passenger_id: internalPassenger.passenger.uuid,
    };

    rv_2[internalPassenger.passenger.uuid] = internal;
  });

  const externalPassengers: CargoOrderDetailsItemPassengerItem[] = [];

  const externalSubjects = details.cargoOrder.cargoItemsSourceList.filter(
    (subject) =>
      subject.cargoItemSource.sourceType ===
      CargoOrderDetailsItemCargoOrderCargoSourceType.EXTERNAL
  );

  if (externalSubjects.length) {
    externalSubjects.forEach((subject) => {
      const subjectId = subject.cargoSubjectUuid;
      const subjectData =
        subject.cargoItemSource as CargoOrderDetailsItemCargoOrderCargoSourceExternal;

      const externalPassenger: CargoOrderDetailsItemPassengerItem = {
        uuid: subjectId,
        passenger: {
          type: CargoOrderDetailsItemCargoOrderCargoSourceType.EXTERNAL,
          dispatchName: subjectData.dispatchName ?? "",
          externalId: subjectData.externalId ?? "",
          firstName: subjectData.firstName,
          lastName: subjectData.lastName,
          mobile: subjectData.phoneNo,
          uuid: subjectId,
        },
      };

      externalPassengers.push(externalPassenger);

      const listItemPassenger =
        externalPassenger.passenger as CargoOrderDetailsItemPassengerItemPassengerExternal;

      const external: OrderRouteEditRequestExternalCargoItemSource = {
        source_type: "EXTERNAL",
        external_id: listItemPassenger.externalId || null,
        dispatch_name: listItemPassenger.dispatchName || null,
        first_name: listItemPassenger.firstName,
        last_name: listItemPassenger.lastName,
        phone_no: listItemPassenger.mobile,
      };

      rv_2[subjectId] = external;
    });
  }

  const passengers: CargoOrderDetailsItemPassengerItem[] = [
    ...internalPassengers,
    ...externalPassengers,
  ];

  return {
    force_allow_toll: details.forceAllowToll,
    rv_1: {
      meta: {
        id: details.cargoOrder.ride.meta.uuid,
      },
      sequence_order: "STRICT",
      seq: createSeq(details.cargoOrder.ride.seq, passengers, plannedStartTime),
    },
    rv_2,
  };
};

const orderDetailsManageDriverAssignmentCollisionEditRequestFactory = {
  createRequest,
};

export default orderDetailsManageDriverAssignmentCollisionEditRequestFactory;
