import { FC } from "react";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { IsPublicOrder } from "../../../../../common/utils/order/is-public-order";
import useOpen from "../../../../../common/hooks/use-open";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsEditForwardingModal from "../../modals/edit-forwarding/order-details-edit-forwarding-modal.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsForwardingEditComponentProps = {
  tools: OrderDetailsToolsTranslations;
  details: OrderDetailsCargoOrder | null;
  hasAccess: boolean;
};

const OrderDetialsForwardingEditComponent: FC<
  OrderDetialsForwardingEditComponentProps
> = ({ tools, details, hasAccess }) => {
  if (!hasAccess) return null;

  const { isOpen, open, close } = useOpen();
  const {
    editForwardingButtonTitle,
    editForwardingButtonDisabledTitle,
    editNonActiveForwardingButtonDisabledTitle,
  } = tools;
  const { isFinished, isCancelled, transportingOrderUuid } = details!;

  const isForwardingEditButtonVisible =
    !isFinished && !isCancelled && hasAccess;

  if (!isForwardingEditButtonVisible) return null;

  const asPublicOrder = IsPublicOrder(details!);
  const actionButtonText = asPublicOrder
    ? editForwardingButtonDisabledTitle
    : editForwardingButtonTitle;

  return (
    <>
      <OrderDetailsToolButtonComponent
        onClick={open}
        icon={faPenToSquare}
        title={actionButtonText}
        isDisabled={asPublicOrder}
      />
      {!!isOpen && (
        <OrderDetailsEditForwardingModal
          isOpen={isOpen}
          onClose={close}
          transportingOrderId={transportingOrderUuid}
          sumbitDisabledText={editNonActiveForwardingButtonDisabledTitle}
        />
      )}
    </>
  );
};

export default OrderDetialsForwardingEditComponent;
