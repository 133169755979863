import DriverPlanCandidatureAddRequest, {
  DriverPlanCandidatureAddRequestBody,
} from "./driver-plan-candidature-add.request";

const createBody = (
  request: DriverPlanCandidatureAddRequest
): DriverPlanCandidatureAddRequestBody => {
  return {
    candidateEntries: request.candidatureEntries,
    driverId: request.driverId,
    acceptImmediately: request.acceptImmediately,
  };
};

const driverPlanCandidatureAddRequestFactory = {
  createBody,
};

export default driverPlanCandidatureAddRequestFactory;
