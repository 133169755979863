const mileageRoutesUrls = {
  listing: `/mileage/listing`,
  add: `/mileage/add`,
  update: `/mileage/:mileageUuid/update`,
  contractAdd: `/mileage/contract/add`,
  contractListing: `/mileage/contract/listing`,
  contractUpdate: `/mileage/contract/:contractUuid/update`,
  settings: `/mileage/settings`,
  dictionary: `/mileage/dictionary/:cargoCompanyUuid`,
  dictionaryRecordUpdate: `/mileage/dictionary/:cargoCompanyUuid/update/:recordUuid`,
  dictionaryRecordAdd: `/mileage/dictionary/:cargoCompanyUuid/add`,
};

export default mileageRoutesUrls;
