import { SearchRoadRoutingResponseRouteLeg } from "../../../../../../../common/utils/search-road-route/search-road-routing.response";
import { OrderAddSolvedRidesResponseRideSolvedWaypoint } from "../../../api/order-add-solved-rides.response";
import orderAddHelper from "../../../helper/order-add.helper";
import OrderAddPassenger from "../../../types/order-add-passenger";
import OrderAddRouteItem, {
  OrderAddRouteWaypoint,
  OrderAddRouteWaypointGroup,
  OrderAddRouteWaypointGroupWaypoint,
} from "../../../types/order-add-route-waypoint";
import OrderAddSummaryRouteWaypoint from "../route/types/order-add-summary-route-waypoint";

const createSummaryRouteWaypointForWaypoint = (
  orderAddRouteItem: OrderAddRouteWaypoint,
  index: number,
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[],
  solvedWaypoint: OrderAddSolvedRidesResponseRideSolvedWaypoint | undefined
): OrderAddSummaryRouteWaypoint => {
  const routeFragementDetails = responseRouteLegs.find((responseRouteLeg) => {
    return (
      responseRouteLeg.end.latitude === orderAddRouteItem.address?.latitude &&
      responseRouteLeg.end.longitude === orderAddRouteItem.address.longitude
    );
  });

  const estimatedDate = solvedWaypoint?.meta.estimated_time
    ? new Date(solvedWaypoint.meta.estimated_time)
    : null;

  const distance = responseRouteLegs.length
    ? routeFragementDetails?.distance ?? 0
    : null;

  const onboardingPassengers: OrderAddPassenger[] =
    orderAddRouteItem.onboardingPassengerListItems.map(
      (listItem) => listItem.passenger
    );
  const outboardingPassengers: OrderAddPassenger[] =
    orderAddRouteItem.outboardingPassengerListItems.map(
      (listItem) => listItem.passenger
    );

  return {
    stageNo: index + 1,
    uuid: orderAddRouteItem.uuid,
    onboardingPassengers,
    outboardingPassengers,
    orderedDate: orderAddRouteItem.date,
    estimatedDate,
    distance,
    haltingTime: orderAddRouteItem.haltingTimeMinutes
      ? orderAddRouteItem.haltingTimeMinutes * 60
      : 0,
    place: orderAddRouteItem.address
      ? {
          displayName: orderAddRouteItem.address.displayName,
          latitude: orderAddRouteItem.address.latitude,
          longitude: orderAddRouteItem.address.longitude,
        }
      : null,
  };
};

const createSummaryRouteWaypointForWaypointGroupWaypoint = (
  orderAddRouteItemGroupWaypoint: OrderAddRouteWaypointGroupWaypoint,
  index: number
): OrderAddSummaryRouteWaypoint => {
  const orderedDate: OrderAddSummaryRouteWaypoint["orderedDate"] = null;
  const estimatedDate: OrderAddSummaryRouteWaypoint["estimatedDate"] = null;
  const distance: OrderAddSummaryRouteWaypoint["distance"] = null;

  const onboardingPassengers: OrderAddPassenger[] =
    orderAddRouteItemGroupWaypoint.onboardingPassengerListItems.map(
      (listItem) => listItem.passenger
    );
  const outboardingPassengers: OrderAddPassenger[] =
    orderAddRouteItemGroupWaypoint.onboardingPassengerListItems.map(
      (listItem) => listItem.passenger
    );

  return {
    stageNo: index + 1,
    uuid: orderAddRouteItemGroupWaypoint.uuid,
    onboardingPassengers,
    outboardingPassengers,
    orderedDate,
    estimatedDate,
    distance,
    haltingTime: orderAddRouteItemGroupWaypoint.haltingTimeMinutes
      ? orderAddRouteItemGroupWaypoint.haltingTimeMinutes * 60
      : 0,
    place: orderAddRouteItemGroupWaypoint.address
      ? {
          displayName: orderAddRouteItemGroupWaypoint.address.displayName,
          latitude: orderAddRouteItemGroupWaypoint.address.latitude,
          longitude: orderAddRouteItemGroupWaypoint.address.longitude,
        }
      : null,
  };
};

const createSummaryRouteWaypointsForWaypointGroup = (
  orderAddRouteItemGroup: OrderAddRouteWaypointGroup,
  index: number
): OrderAddSummaryRouteWaypoint[] => {
  return orderAddRouteItemGroup.waypoints.map((waypoint) => {
    return createSummaryRouteWaypointForWaypointGroupWaypoint(waypoint, index);
  });
};

const createSummaryRouteWaypoint = (
  orderAddRouteItem: OrderAddRouteItem,
  index: number,
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[],
  solvedWaypoint: OrderAddSolvedRidesResponseRideSolvedWaypoint | undefined
): OrderAddSummaryRouteWaypoint[] => {
  const isWaypoint =
    orderAddHelper.checkIsRouteItemAWaypoint(orderAddRouteItem);

  if (isWaypoint) {
    const summaryWaypoint = createSummaryRouteWaypointForWaypoint(
      orderAddRouteItem,
      index,
      responseRouteLegs,
      solvedWaypoint
    );

    return [summaryWaypoint];
  }

  return createSummaryRouteWaypointsForWaypointGroup(orderAddRouteItem, index);
};

const createSummaryRouteWaypoints = (
  orderAddRouteWaypoints: OrderAddRouteItem[],
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[],
  solvedWaypoints: OrderAddSolvedRidesResponseRideSolvedWaypoint[]
): OrderAddSummaryRouteWaypoint[] => {
  let summaryWaypoints: OrderAddSummaryRouteWaypoint[] = [];

  orderAddRouteWaypoints.forEach((waypoint, index) => {
    const solvedWaypoint = solvedWaypoints.find(
      (solvedWaypoint) => solvedWaypoint.meta.id === waypoint.uuid
    );

    const newSummaryWaypoints = createSummaryRouteWaypoint(
      waypoint,
      index,
      responseRouteLegs,
      solvedWaypoint
    );

    summaryWaypoints.push(...newSummaryWaypoints);
  });

  return summaryWaypoints;
};

const orderAddSummaryFactory = {
  createSummaryRouteWaypoints,
};

export default orderAddSummaryFactory;
