import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import MileageContractUpdateFormData from "./types/mileage-contract-update-form-data";

const validateDistanceRateCompanyVehicle = (
  value: MileageContractUpdateFormData["distanceRateCompanyVehicle"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRateCarGt900cm3 = (
  value: MileageContractUpdateFormData["distanceRateCarGt900cm3"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRateCarLe900cm3 = (
  value: MileageContractUpdateFormData["distanceRateCarLe900cm3"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRateMotorcycle = (
  value: MileageContractUpdateFormData["distanceRateMotorcycle"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRateMoped = (
  value: MileageContractUpdateFormData["distanceRateMoped"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietFullRate = (
  value: MileageContractUpdateFormData["dietFullRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietFullAfterHours = (
  value: MileageContractUpdateFormData["dietFullAfterHours"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(24).required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietHalfRate = (
  value: MileageContractUpdateFormData["dietHalfRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateDietHalfAfterHours = (
  value: MileageContractUpdateFormData["dietHalfAfterHours"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(24).required();

  return formValidationService.validate(value, validationSchema);
};

const validatePeriodOfValidity = (
  value: MileageContractUpdateFormData["periodOfValidity"]
): FormValidationResult => {
  const validationSchema = Joi.object<
    MileageContractUpdateFormData["periodOfValidity"]
  >()
    .keys({
      from: Joi.date().min("1970-01-01").required(),
      to: Joi.date().required(),
    })
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsActive = (
  value: MileageContractUpdateFormData["isActive"]
): FormValidationResult => {
  const validationSchema = Joi.bool().required();

  return formValidationService.validate(value, validationSchema);
};

const mileageContractUpdateFormValidationService = {
  validateDistanceRateCompanyVehicle,
  validateDistanceRateCarGt900cm3,
  validateDistanceRateCarLe900cm3,
  validateDistanceRateMotorcycle,
  validateDistanceRateMoped,
  validateDietFullRate,
  validateDietFullAfterHours,
  validateDietHalfRate,
  validateDietHalfAfterHours,
  validatePeriodOfValidity,
  validateIsActive,
};

export default mileageContractUpdateFormValidationService;
