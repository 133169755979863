import { FC, useEffect } from "react";
import orderAddRoutesHelper from "./order-add-routes.helper";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import orderAddRoutesFactory from "./order-add-routes.factory";
import OrderAddRouteWaypointComponent from "./waypoint/order-add-route-waypoint.component";
import OrderAddRouteGroupWaypointComponent from "./waypoint/order-add-route-group-waypoint.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import orderAddHelper from "../../helper/order-add.helper";
import OrderAddCargoCompany from "../../types/order-add-cargo-company";
import OrderAddPassengerListItem from "../../types/order-add-passenger-list-item";
import OrderAddRouteItem, {
  OrderAddRouteWaypoint,
  OrderAddRouteWaypointGroup,
  OrderAddRouteWaypointGroupWaypoint,
} from "../../types/order-add-route-waypoint";

type OrderAddRoutesProps = {
  passengerList: OrderAddPassengerListItem[];
  routes: OrderAddRouteItem[];
  onRoutesChange: (routes: OrderAddRouteItem[]) => void;
  contractorUuid: OrderAddCargoCompany["uuid"] | null;
};

const OrderAddRoutesComponent: FC<OrderAddRoutesProps> = (props) => {
  const translations = orderTranslationsHelper.getAddTranslations().routes;

  const onAddRouteButtonClick = () => {
    const newEmptyRoute = orderAddRoutesHelper.getNewEmptyRoute();

    const newRoutes: OrderAddRouteItem[] = [...props.routes, newEmptyRoute];

    props.onRoutesChange(newRoutes);
  };

  const updateWaypoint = (
    waypointUuid: OrderAddRouteWaypoint["uuid"],
    updatedWaypoint: OrderAddRouteWaypoint
  ) => {
    let newRoutes: OrderAddRouteItem[] = [...props.routes];

    const routeToUpdate = props.routes.find(
      (route) => route.uuid === waypointUuid
    );

    if (!routeToUpdate) {
      return;
    }

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);

    newRoutes[indexOfRouteToUpdate] = updatedWaypoint;

    props.onRoutesChange(newRoutes);
  };

  const updateWaypointGroupWaypoint = (
    routeUuid: OrderAddRouteWaypointGroup["uuid"],
    waypointUuid: OrderAddRouteWaypointGroupWaypoint["uuid"],
    updatedWaypoint: OrderAddRouteWaypointGroupWaypoint
  ) => {
    let newRoutes: OrderAddRouteItem[] = [...props.routes];

    const routeToUpdate = props.routes.find(
      (route) => route.uuid === routeUuid
    );

    if (!routeToUpdate) {
      return;
    }

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);

    let routeItemToUpdate: OrderAddRouteWaypointGroup = newRoutes[
      indexOfRouteToUpdate
    ] as OrderAddRouteWaypointGroup;

    const waypointToUpdate = routeItemToUpdate.waypoints.find(
      (waypoint) => waypoint.uuid === waypointUuid
    );

    if (!waypointToUpdate) {
      return;
    }

    const indexOfWaypointToUpdate =
      routeItemToUpdate.waypoints.indexOf(waypointToUpdate);

    newRoutes[indexOfRouteToUpdate] = {
      ...routeItemToUpdate,
      waypoints: [
        ...routeItemToUpdate.waypoints.slice(0, indexOfWaypointToUpdate),
        updatedWaypoint,
        ...routeItemToUpdate.waypoints.slice(indexOfWaypointToUpdate + 1),
      ],
    };

    props.onRoutesChange(newRoutes);
  };

  const deleteRouteItem = (routeUuid: OrderAddRouteItem["uuid"]) => {
    const newRoutes: OrderAddRouteItem[] = props.routes.filter(
      (route) => route.uuid !== routeUuid
    );

    props.onRoutesChange(newRoutes);
  };

  const deleteWaypoint = (routeUuid: OrderAddRouteWaypoint["uuid"]) => {
    deleteRouteItem(routeUuid);
  };

  const deleteGroupWaypoint = (
    routeUuid: OrderAddRouteWaypointGroup["uuid"],
    waypointUuid: OrderAddRouteWaypointGroupWaypoint["uuid"]
  ) => {
    let newRoutes: OrderAddRouteItem[] = [...props.routes];

    const routeToUpdate = props.routes.find(
      (route) => route.uuid === routeUuid
    );

    if (
      !routeToUpdate ||
      orderAddHelper.checkIsRouteItemAWaypoint(routeToUpdate)
    ) {
      return;
    }

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);

    if (routeToUpdate.waypoints.length >= 2) {
      const waypointToStay = routeToUpdate.waypoints.find(
        (waypoint) => waypoint.uuid !== waypointUuid
      );

      if (!waypointToStay) {
        return;
      }

      const newWaypointFromGroup =
        orderAddRoutesFactory.createWaypointFromGroupWaypoint(waypointToStay);

      const newRoutes: OrderAddRouteItem[] = [
        ...props.routes.slice(0, indexOfRouteToUpdate),
        newWaypointFromGroup,

        ...props.routes.slice(indexOfRouteToUpdate + 1),
      ];

      props.onRoutesChange(newRoutes);

      return;
    }

    let routeItemToRemove: OrderAddRouteWaypointGroup = newRoutes[
      indexOfRouteToUpdate
    ] as OrderAddRouteWaypointGroup;

    const waypointToRemove = routeItemToRemove.waypoints.find(
      (waypoint) => waypoint.uuid === waypointUuid
    );

    if (!waypointToRemove) {
      return;
    }

    const indexOfWaypointToRemove =
      routeItemToRemove.waypoints.indexOf(waypointToRemove);

    newRoutes[indexOfRouteToUpdate] = {
      ...routeItemToRemove,
      waypoints: [
        ...routeItemToRemove.waypoints.slice(0, indexOfWaypointToRemove),
        ...routeItemToRemove.waypoints.slice(indexOfWaypointToRemove + 1),
      ],
    };

    props.onRoutesChange(newRoutes);
  };

  const getReorderedRouteWaypoints = (
    startIndex: number,
    endIndex: number
  ): OrderAddRouteItem[] => {
    const result = [...props.routes];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragRouteListItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedPassengerList = getReorderedRouteWaypoints(
      result.source.index,
      result.destination.index
    );

    props.onRoutesChange(reorderedPassengerList);
  };

  const onJoinWaypointIntoGroupButtonClick = (
    waypointUuid: OrderAddRouteWaypoint["uuid"]
  ) => {
    const routeItemInRoutes = props.routes.find(
      (routeItem) => routeItem.uuid === waypointUuid
    ) as OrderAddRouteWaypoint | undefined;

    if (!routeItemInRoutes) {
      return;
    }

    const indexOfRouteItemInRoutes = props.routes.indexOf(routeItemInRoutes);

    const previousRouteItem = props.routes[indexOfRouteItemInRoutes - 1];

    const isPreviousRouteItemAWaypoint =
      orderAddHelper.checkIsRouteItemAWaypoint(previousRouteItem);

    if (isPreviousRouteItemAWaypoint) {
      const newGroup: OrderAddRouteWaypointGroup = {
        uuid: uuidService.generate(),
        waypoints: [previousRouteItem, routeItemInRoutes],
      };

      const newRoutes = [
        ...props.routes.slice(0, indexOfRouteItemInRoutes - 1),
        newGroup,
        ...props.routes.slice(indexOfRouteItemInRoutes + 1),
      ];

      props.onRoutesChange(newRoutes);
    }

    const newGroup: OrderAddRouteWaypointGroup = {
      uuid: uuidService.generate(),
      waypoints: [
        ...(previousRouteItem as OrderAddRouteWaypointGroup).waypoints,
        routeItemInRoutes,
      ],
    };

    const newRoutes = [
      ...props.routes.slice(0, indexOfRouteItemInRoutes - 1),
      newGroup,
      ...props.routes.slice(indexOfRouteItemInRoutes + 1),
    ];

    props.onRoutesChange(newRoutes);
  };

  const onSeparateWaypointIntoGroupButtonClick = (
    waypointGroupUuid: OrderAddRouteWaypointGroup["uuid"],
    groupWaypoint: OrderAddRouteWaypointGroupWaypoint
  ) => {
    const routeItemInRoutes = props.routes.find(
      (routeItem) => routeItem.uuid === waypointGroupUuid
    ) as OrderAddRouteWaypointGroup | undefined;

    if (!routeItemInRoutes) {
      return;
    }

    const indexOfRouteItemInRoutes = props.routes.indexOf(routeItemInRoutes);

    const waypointsAfterFilter = routeItemInRoutes.waypoints.filter(
      (waypoint) => waypoint.uuid !== groupWaypoint.uuid
    );

    if (waypointsAfterFilter.length >= 2) {
      const modifiedGroup: OrderAddRouteWaypointGroup = {
        uuid: routeItemInRoutes.uuid,
        waypoints: routeItemInRoutes.waypoints.filter(
          (waypoint) => waypoint.uuid !== groupWaypoint.uuid
        ),
      };

      const newWaypoint =
        orderAddRoutesFactory.createWaypointFromGroupWaypoint(groupWaypoint);

      const newRoutes: OrderAddRouteItem[] = [
        ...props.routes.slice(0, indexOfRouteItemInRoutes),
        modifiedGroup,
        newWaypoint,
        ...props.routes.slice(indexOfRouteItemInRoutes + 1),
      ];

      props.onRoutesChange(newRoutes);

      return;
    }

    if (waypointsAfterFilter.length === 1) {
      const newWaypointFromGroup =
        orderAddRoutesFactory.createWaypointFromGroupWaypoint(
          waypointsAfterFilter[0]
        );

      const newWaypoint =
        orderAddRoutesFactory.createWaypointFromGroupWaypoint(groupWaypoint);

      const newRoutes: OrderAddRouteItem[] = [
        ...props.routes.slice(0, indexOfRouteItemInRoutes),
        newWaypointFromGroup,
        newWaypoint,
        ...props.routes.slice(indexOfRouteItemInRoutes + 1),
      ];

      props.onRoutesChange(newRoutes);

      return;
    }

    const newWaypoint =
      orderAddRoutesFactory.createWaypointFromGroupWaypoint(groupWaypoint);

    const newRoutes: OrderAddRouteItem[] = [
      ...props.routes.slice(0, indexOfRouteItemInRoutes),
      newWaypoint,
      ...props.routes.slice(indexOfRouteItemInRoutes + 1),
    ];

    props.onRoutesChange(newRoutes);
  };

  useEffect(() => {
    const newRoutes: OrderAddRouteItem[] =
      orderAddRoutesHelper.getUpdatedRouteItemsOnPassengerListChange(
        props.routes,
        props.passengerList
      );

    props.onRoutesChange(newRoutes);
  }, [JSON.stringify(props.passengerList)]);

  const isDraggingEnabled = props.routes.length > 1;

  const isAddRouteButtonDisabled = !props.contractorUuid;

  return (
    <FormFieldComponent label={translations.headingText} isRequired>
      <DragDropContext onDragEnd={onDragRouteListItemEnd}>
        <Droppable droppableId="order_add_passenger_list_drop_area">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div className="order_add_route_list">
                {props.routes.map((route, routeIndex) => {
                  const isWaypoint =
                    orderAddHelper.checkIsRouteItemAWaypoint(route);

                  const waypointNumber = routeIndex + 1;

                  const onboardingPassengerCandidateList: OrderAddPassengerListItem[] =
                    orderAddRoutesHelper.getOnboardingPassengerCandidateList(
                      props.passengerList,
                      route,
                      props.routes
                    );

                  const outboardingPassengerCandidateList: OrderAddPassengerListItem[] =
                    orderAddRoutesHelper.getOutboardingPassengerCandidateList(
                      props.passengerList,
                      route,
                      props.routes
                    );

                  if (isWaypoint) {
                    const isJoinWaypointIntoGroupButtonVisible =
                      !!props.routes[routeIndex - 1];

                    return (
                      <Draggable
                        key={route.uuid}
                        draggableId={route.uuid}
                        index={routeIndex}
                        isDragDisabled={!isDraggingEnabled}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <OrderAddRouteWaypointComponent
                              number={waypointNumber}
                              dragHandleProps={provided.dragHandleProps}
                              waypoint={route}
                              updateWaypoint={(updatedWaypoint) =>
                                updateWaypoint(route.uuid, updatedWaypoint)
                              }
                              deleteWaypoint={() => deleteWaypoint(route.uuid)}
                              isJoinWaypointIntoGroupButtonVisible={
                                isJoinWaypointIntoGroupButtonVisible
                              }
                              onboardingPassengerCandidateList={
                                onboardingPassengerCandidateList
                              }
                              outboardingPassengerCandidateList={
                                outboardingPassengerCandidateList
                              }
                              onJoinWaypointIntoGroupButtonClick={() =>
                                onJoinWaypointIntoGroupButtonClick(route.uuid)
                              }
                              passengerList={props.passengerList}
                              selectedCargoCompanyUuid={props.contractorUuid}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  }

                  return (
                    <div>
                      {route.waypoints.map((waypoint) => {
                        return (
                          <OrderAddRouteGroupWaypointComponent
                            number={waypointNumber}
                            waypoint={waypoint}
                            updateWaypoint={(updatedWaypoint) =>
                              updateWaypointGroupWaypoint(
                                route.uuid,
                                waypoint.uuid,
                                updatedWaypoint
                              )
                            }
                            deleteWaypoint={() =>
                              deleteGroupWaypoint(route.uuid, waypoint.uuid)
                            }
                            onboardingPassengerCandidateList={
                              onboardingPassengerCandidateList
                            }
                            outboardingPassengerCandidateList={
                              outboardingPassengerCandidateList
                            }
                            onSeparateWaypointIntoGroupButtonClick={() =>
                              onSeparateWaypointIntoGroupButtonClick(
                                route.uuid,
                                waypoint
                              )
                            }
                            passengerList={props.passengerList}
                            selectedCargoCompanyUuid={props.contractorUuid}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ButtonComponent
        onClick={onAddRouteButtonClick}
        type="primary"
        title={translations.addNewButtonTitle}
        isDisabled={isAddRouteButtonDisabled}
        idForTesting={"route-add-new-button"}
      >
        {translations.addNewButtonText}
      </ButtonComponent>
    </FormFieldComponent>
  );
};

export default OrderAddRoutesComponent;
