import appConfig from "../../../../../app.config";
import searchRoadRoutingService from "../../../../../common/utils/search-road-route/search-road-routing.service";
import authService from "../../../../auth/common/auth.service";
import BillingRouteRequest from "./billing-route.request";
import BillingRouteResponse from "./billing-route.response";
import BillingDataResponse from "./billing-data.response";
import BillingRecalculateRequest from "./billing-recalculate.request";
import BillingSaveRequest from "./billing-save.request";
import BillingSaveResponse from "./billing-save.response";
import BillingGpsResponse from "./billing-gps-data.response";
import urlService from "../../../../../common/utils/url/url.service";
import BillingAddressesRequest from "./billing-addresses.request";
import BillingAddressesResponse from "./billing-addresses.response";

const fetchBillingDraft = async (
  orderUuid: string
): Promise<BillingDataResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/orders/${orderUuid}/cargo-raily/draft`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const recalculateBilling = async (
  orderUuid: string,
  requestBody: BillingRecalculateRequest
): Promise<BillingDataResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/orders/${orderUuid}/cargo-raily/recalculate`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const addBilling = async (
  orderUuid: string,
  requestBody: BillingSaveRequest
): Promise<BillingSaveResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/orders/${orderUuid}/cargo-raily`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchRoute = (
  request: BillingRouteRequest
): Promise<BillingRouteResponse> => {
  return searchRoadRoutingService.search(request);
};

const fetchAddresses = (
  orderUuid: string,
  request: BillingAddressesRequest
): Promise<BillingAddressesResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/orders/${orderUuid}/addresses`;

  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchGpsData = (orderUuid: string): Promise<BillingGpsResponse> => {
  const path = `${appConfig.baseApiUrl}/gps-gateway/records/cargo-order/${orderUuid}/gps-time-nodes`;

  const url = urlService.buildWithoutEmptyParams(path);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const billingCargoRailyAddApiService = {
  fetchBillingDraft,
  fetchRoute,
  recalculateBilling,
  addBilling,
  fetchAddresses,
  fetchGpsData,
};

export default billingCargoRailyAddApiService;
