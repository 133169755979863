import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";

const validateName = (value: string | undefined) => {
  const validationSchema = Joi.string().min(1).max(255).required();

  return formValidationService.validate(value, validationSchema);
};

const validateActive = (value: boolean) => {
  const validationSchema = Joi.boolean().required();

  return formValidationService.validate(value, validationSchema);
};

const mileageDictionaryUpdateFormValildationService = {
  validateName,
  validateActive,
};

export default mileageDictionaryUpdateFormValildationService;
