import CargoOfficerListRequest, {
  CargoOfficerListRequestQueryParams,
} from "./cargo-officer-list.request";

const createQueryParams = (
  data: CargoOfficerListRequest
): CargoOfficerListRequestQueryParams => {
  return {
    cargoCompany: data.cargoCompany,
    cargoCompanyId: data.cargoCompanyId,
    order: data.order,
    firstName: data.firstName,
    lastName: data.lastName,
    username: data.username,
    email: data.email,
    statusCriteria: data.statusCriteria,
    offset: data.offset,
    limit: data.limit,
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
  };
};

const cargoOfficerListRequestFactory = {
  createQueryParams,
};

export default cargoOfficerListRequestFactory;
