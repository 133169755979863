import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import { useAppContext } from "../../../../../context/app.context";
import mileageContractsListingTableHelper from "./mileage-contract-listing-table.helper";
import dateService from "../../../../../common/utils/date/date.service";
import MileageContractListingItem from "../common/types/mileage-contract-listing-item";
import MileageContractListingTableColumn from "../common/types/mileage-contract-listing-table-column";
import MileageContractListingTableRow from "../common/types/mileage-contract-listing-table-row";
import mileageContractsListingHelper from "./mileage-contract-listing-table.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import useMileageContractListingUserPermissions from "../common/user-permissions/use-mileage-contract-listing-user-permissions";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";
import mileageRoutesHelper from "../../../common/routes/mileage-routes.helper";

type MileageContractsListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: MileageContractListingItem[];
  expandedRowsMileageUuids: string[];
  onRowClick: (mileageUuid: string) => void;
};

const MileageContractsListingTableComponent: FC<
  MileageContractsListingTableProps
> = (props) => {
  const userPermissions = useMileageContractListingUserPermissions();

  const { selectedAppLanguage } = useAppContext();
  const translations =
    mileageTranslationsHelper.getMileageContractListingTranslations();

  const columns = useMemo(
    (): MileageContractListingTableColumn[] =>
      mileageContractsListingTableHelper.getTableColumns(),
    [selectedAppLanguage]
  );

  const createTableRow = (
    listingItem: MileageContractListingItem
  ): MileageContractListingTableRow => {
    const validSinceTitle = dateService.checkIsValidDate(listingItem.validSince)
      ? dateService.format(listingItem.validSince, "dd.mm.yyyy")
      : "---";
    const validSinceLabel = validSinceTitle;

    const validToTitle = dateService.checkIsValidDate(listingItem.validTo)
      ? dateService.format(listingItem.validTo!, "dd.mm.yyyy")
      : "---";
    const validToLabel = validToTitle;

    return {
      id: listingItem.uuid,
      value: {
        companyName: (
          <div title={listingItem.companyName}>{listingItem.companyName}</div>
        ),
        validSince: <div title={validSinceTitle}>{validSinceLabel}</div>,
        validTo: <div title={validToTitle}>{validToLabel}</div>,
        distanceRateCompanyVehicle: (
          <div
            title={mileageContractsListingHelper.getDistanceRateCompanyVehicleTitle(
              listingItem.distanceRateCompanyVehicle
            )}
          >
            {mileageContractsListingHelper.getDistanceRateCompanyVehicleTitle(
              listingItem.distanceRateCompanyVehicle
            )}
          </div>
        ),
        distanceRateCarGt900cm3: (
          <div
            title={mileageContractsListingHelper.getDistanceRateCarGt900cm3Title(
              listingItem.distanceRateCarGt900cm3
            )}
          >
            {mileageContractsListingHelper.getDistanceRateCarGt900cm3Label(
              listingItem.distanceRateCarGt900cm3
            )}
          </div>
        ),
        distanceRateCarLe900cm3: (
          <div
            title={mileageContractsListingHelper.getDistanceRateCarLe900cm3Title(
              listingItem.distanceRateCarLe900cm3
            )}
          >
            {mileageContractsListingHelper.getDistanceRateCarLe900cm3Label(
              listingItem.distanceRateCarLe900cm3
            )}
          </div>
        ),
        distanceRateMotorcycle: (
          <div
            title={mileageContractsListingHelper.getDistanceRateMotorcycleTitle(
              listingItem.distanceRateMotorcycle
            )}
          >
            {mileageContractsListingHelper.getDistanceRateMotorcycleLabel(
              listingItem.distanceRateMotorcycle
            )}
          </div>
        ),
        distanceRateMoped: (
          <div
            title={mileageContractsListingHelper.getDistanceRateMopedTitle(
              listingItem.distanceRateMoped
            )}
          >
            {mileageContractsListingHelper.getDistanceRateMopedLabel(
              listingItem.distanceRateMoped
            )}
          </div>
        ),
        dietFullRate: (
          <div
            title={mileageContractsListingHelper.getDietFullRateTitle(
              listingItem.dietFullRate
            )}
          >
            {mileageContractsListingHelper.getDietFullRateLabel(
              listingItem.dietFullRate
            )}
          </div>
        ),
        dietFullAfterHours: (
          <div
            title={mileageContractsListingHelper.getDietFullAfterHoursTitle(
              listingItem.dietFullAfterHours
            )}
          >
            {mileageContractsListingHelper.getDietFullAfterHoursLabel(
              listingItem.dietFullAfterHours
            )}
          </div>
        ),
        dietHalfRate: (
          <div
            title={mileageContractsListingHelper.getDietHalfRateTitle(
              listingItem.dietHalfRate
            )}
          >
            {mileageContractsListingHelper.getDietHalfRateLabel(
              listingItem.dietHalfRate
            )}
          </div>
        ),
        dietHalfAfterHours: (
          <div
            title={mileageContractsListingHelper.getDietHalfAfterHoursTitle(
              listingItem.dietHalfAfterHours
            )}
          >
            {mileageContractsListingHelper.getDietHalfAfterHoursLabel(
              listingItem.dietHalfAfterHours
            )}
          </div>
        ),
        activityStatus: (
          <div
            title={mileageContractsListingHelper.getActivityStatusLabel(
              listingItem.disabled
            )}
          >
            {listingItem.disabled ? (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            )}
          </div>
        ),
        actions: userPermissions.hasAccessToEditMileageContracts && (
          <div className="d-flex">
            <TableLinkButtonComponent
              icon={faEdit}
              to={mileageRoutesHelper.getContractUpdateRoute({
                contractUuid: listingItem.uuid,
              })}
              title={translations.table.editButtonTitle}
              idForTesting={`mileage-contracts-listing-item-${listingItem.uuid}-edit-button`}
            />
          </div>
        ),
      },
    };
  };

  const rows: MileageContractListingTableRow[] = useMemo(() => {
    return props.listingItems.map((list) => createTableRow(list));
  }, [JSON.stringify(props.listingItems), props.expandedRowsMileageUuids]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default MileageContractsListingTableComponent;
