export enum MileageDownloadParamsTimezone {
  EUROPE_WARSAW = "Europe/Warsaw",
  UTC = "UTC",
}

export type MileageDownloadParamsSeparatorTypes = ";" | ",";

type MileageDownloadParams = {
  mileageUuids: string[];
  separator?: MileageDownloadParamsSeparatorTypes;
  timezone?: MileageDownloadParamsTimezone;
};

export default MileageDownloadParams;
