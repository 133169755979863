import DealerAddParams from "../../../../../../common/services/dealer/add/dealer-add-params";
import User from "../../../../../../common/types/user";
import DealerAddByTaxiFormData from "./types/dealer-add-by-taxi-form-data";

const create = (
  formData: DealerAddByTaxiFormData,
  user: User
): DealerAddParams => {
  const taxiCorporationUuid = user.aspects.taxiOfficer?.taxiCorporationUuid!;

  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    username: formData.username,
    password: formData.password,
    phoneNumber: formData.phoneNumber,
    alternativePhoneNumber: formData.alternativePhoneNumber,
    taxiCorporationUuid,
  };
};

const dealerAddByTaxiParamsFactory = {
  create,
};

export default dealerAddByTaxiParamsFactory;
