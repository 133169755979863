import { CargoCompanyDispatcherListItem } from "../../../../../common/services/cargo-company/dispatcher/list/cargo-company-dispatcher-list";
import CargoCompanyPassengerList, {
  CargoCompanyPassengerListItem,
  CargoCompanyPassengerListItemAddress,
} from "../../../../../common/services/cargo-company/passenger/list/cargo-company-passenger-list";
import delegationTranslationsHelper from "../../../../../languages/delegation-translations.helper";
import {
  DelegationAddWorkerListItem,
  DelegationAddWorkerListItemAddress,
} from "../types/delegation-add-worker-list";

const createPassengerAddress = (
  address: CargoCompanyPassengerListItemAddress
): DelegationAddWorkerListItemAddress => {
  return {
    displayName: address.displayName,
    lat: address.lat,
    lon: address.lon,
    apartmentNumber: address.apartmentNumber,
    houseNumber: address.houseNumber,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createPassengerListItem = (
  item: CargoCompanyPassengerListItem
): DelegationAddWorkerListItem => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().workers;

  return {
    addresses: item.addresses.map(createPassengerAddress),
    displayName: item.displayName,
    subText: translations.passengerWorkerLabel,
    uuid: item.uuid,
  };
};

const createPassengerList = (
  response: CargoCompanyPassengerList
): DelegationAddWorkerListItem[] => {
  return response.map(createPassengerListItem);
};

const createDispatcherListItem = (
  item: CargoCompanyDispatcherListItem
): DelegationAddWorkerListItem => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().workers;

  return {
    addresses: [],
    displayName: item.displayName,
    subText: translations.dispatcherWorkerLabel,
    uuid: item.uuid,
  };
};

const createDispatcherList = (
  response: CargoCompanyDispatcherListItem[]
): DelegationAddWorkerListItem[] => {
  return response.map(createDispatcherListItem);
};

const combineWorkerLists = (
  list1: DelegationAddWorkerListItem[],
  list2: DelegationAddWorkerListItem[]
): DelegationAddWorkerListItem[] => {
  return [...list1, ...list2].sort((item1, item2) => {
    if (item1.displayName < item2.displayName) return -1;
    if (item1.displayName > item2.displayName) return 1;
    return 0;
  });
};

const DelegationAddWorkerListFactory = {
  createPassengerList,
  createDispatcherList,
  combineWorkerLists,
};

export default DelegationAddWorkerListFactory;
