export enum TaxiCorporationListLoadParamsOrder {
  COMPANY_NAME_ASC = "COMPANY_NAME_ASC",
  COMPANY_NAME_DESC = "COMPANY_NAME_DESC",
  DISPLAY_NAME_ASC = "DISPLAY_NAME_ASC",
  DISPLAY_NAME_DESC = "DISPLAY_NAME_DESC",
}

type TaxiCorporationListLoadParams = {
  taxNumber?: string;
  companyId?: string;
  nationalCourtRegister?: string;
  page?: number;
  pageSize?: number;
  name?: string;
  displayName?: string;
  address?: string;
  notes?: string;
  order?: TaxiCorporationListLoadParamsOrder;
  creationDateFrom?: Date;
  creationDateTo?: Date;
};

export default TaxiCorporationListLoadParams;
