import appConfig from "../app.config";
import appLanguageDao from "./app-language.dao";
import AppLanguage from "./types/app-language";

const getLanguageOptions = (): AppLanguage[] => {
  return Object.values(AppLanguage);
};

const getSelectedLanguage = (): AppLanguage => {
  const selectedLanguage = appLanguageDao.getLanguage();

  if (!selectedLanguage) {
    return appConfig.defaultAppLanguage;
  }

  return selectedLanguage;
};

const appLanguageHelper = {
  getLanguageOptions,
  getSelectedLanguage,
};

export default appLanguageHelper;
