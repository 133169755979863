import formValidationService from "../../../../common/utils/validation/form-validation.service";
import AppHeaderUserChangePasswordFormValidationResults from "./types/app-header-user-change-password-data-validation.results";
import AppHeaderUserChangePasswordFormData from "./types/app-header-user-change-password-form-data";

const getDefaultFormData = (): AppHeaderUserChangePasswordFormData => {
  return {
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  };
};

const getDefaultFormValidationResults =
  (): AppHeaderUserChangePasswordFormValidationResults => {
    return {
      currentPassword: formValidationService.defaultValidationResult,
      newPassword: formValidationService.defaultValidationResult,
      repeatNewPassword: formValidationService.defaultValidationResult,
    };
  };

const appHeaderUserChangePasswordFormHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default appHeaderUserChangePasswordFormHelper;
