import CargoCompanyAddressListResponse, {
  CargoComapnyAddressListResponseItemAddressType,
  CargoCompanyAddressListResponseItem,
} from "../../../../api/raily/cargo-company/address/list/cargo-company-address-list.response";
import CargoCompanyAddressList, {
  CargoCompanyAddressListItem,
  CargoCompanyAddressListItemAddressType,
} from "./cargo-company-address-list";

const createType = (
  type: CargoComapnyAddressListResponseItemAddressType
): CargoCompanyAddressListItemAddressType => {
  switch (type) {
    case CargoComapnyAddressListResponseItemAddressType.HOTEL:
      return CargoCompanyAddressListItemAddressType.HOTEL;
    case CargoComapnyAddressListResponseItemAddressType.MEETING_POINT:
      return CargoCompanyAddressListItemAddressType.MEETING_POINT;
    case CargoComapnyAddressListResponseItemAddressType.STATION:
      return CargoCompanyAddressListItemAddressType.STATION;
  }
};

const createDataItem = (
  responseItem: CargoCompanyAddressListResponseItem
): CargoCompanyAddressListItem => {
  return {
    createdBy: responseItem.createdBy,
    creationDate: responseItem.createdAt,
    displayName: responseItem.displayName,
    lat: responseItem.lat,
    lon: responseItem.lon,
    type: createType(responseItem.type),
    uuid: responseItem.id,
    apartment: responseItem.apartment,
    country: responseItem.country,
    description: responseItem.description,
    houseNumber: responseItem.houseNo,
    modificationDate: responseItem.modifiedAt,
    modifiedBy: responseItem.modifiedBy,
    street: responseItem.street,
    town: responseItem.town,
    zipCode: responseItem.zipCode,
  };
};

const createData = (
  responseData: CargoCompanyAddressListResponseItem[]
): CargoCompanyAddressListItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: CargoCompanyAddressListResponse
): CargoCompanyAddressList => {
  return createData(response.data);
};

const cargoCompanyAddressListFactory = {
  create,
};

export default cargoCompanyAddressListFactory;
