import { CargoTaxiContractListingResponseDataItem } from "../api/types/cargo-taxi-contract-listing.response";
import CargoTaxiContractListingItem from "../types/cargo-taxi-contract-listing-item";

const createListingItem = (
  responseDataItem: CargoTaxiContractListingResponseDataItem
): CargoTaxiContractListingItem => {
  return {
    uuid: responseDataItem.id,
    discountPercentLimit: responseDataItem.discount_limit,
    distanceRate: responseDataItem.distance_rate,
    freeStoppingPeriodMinutes: responseDataItem.halting_time_after_minutes,
    stoppingRate: responseDataItem.halting_time_rate,
    isTollRoadsDuringOrderAllowed: responseDataItem.allow_charge_during_ride,
    isTollRoadsWhileApproachingAllowed:
      responseDataItem.allow_charge_while_approaching,
    isTollRoadsWhileReturningAllowed:
      responseDataItem.allow_charge_while_returning,
    isActive: responseDataItem.valid,
    billingModel: responseDataItem.model,
    isOrderPublishingAllowed: responseDataItem.publishing_allowed,
    taxiCorporation: responseDataItem.taxi_corporation.company_name,
    validFrom: new Date(responseDataItem.valid_since),
    validTo: new Date(responseDataItem.valid_to),
  };
};

const createListingItems = (
  responseDataItems: CargoTaxiContractListingResponseDataItem[]
): CargoTaxiContractListingItem[] => {
  const result = responseDataItems.map(createListingItem);
  return result;
};

const cargoTaxiContractListingFactory = { createListingItems };

export default cargoTaxiContractListingFactory;
