import { FC } from "react";
import { ReactComponent as PassengerMarkIcon } from "../../../../../../assets/icons/passenger-mark-icon.svg";

type OrderAddRoutesPassengerIconProps = {
  number: number;
};

const OrderAddRoutesPassengerIconComponent: FC<
  OrderAddRoutesPassengerIconProps
> = (props) => {
  return (
    <div className="order_add_passenger_icon">
      <PassengerMarkIcon />
      <span className="order_add_passenger_icon__content">{props.number}</span>
    </div>
  );
};

export default OrderAddRoutesPassengerIconComponent;
