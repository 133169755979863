import MileageSettingsDetailsRequest, {
  MileageSettingsDetailsRequestQueryParams,
} from "./mileage-settings-details.request";

const createQueryParams = (
  request: MileageSettingsDetailsRequest
): MileageSettingsDetailsRequestQueryParams => {
  return {
    active: request.active,
  };
};

const mileageSettingsDetailsRequestFactory = {
  createQueryParams,
};

export default mileageSettingsDetailsRequestFactory;
