import MessengerChannel from "../../../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../../../common/components/messenger/types/messenger-channel-availability";
import UserRole from "../../../../../../common/types/user-role";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";

const messengerTranslations =
  userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations()
    .messenger;

const getMessengerPostsHeadingText = (solvedOrderId: number | undefined) => {
  if (solvedOrderId) {
    return messengerTranslations.headingTemplateText.replace(
      "#{routeId}",
      String(solvedOrderId)
    );
  }
};

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.DRIVER]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
  };
};

const DriverDetailsActiveRoutePlansHelper = {
  getMessengerPostsHeadingText,
  getMessengerChannelAvailability,
};

export default DriverDetailsActiveRoutePlansHelper;
