import {
  PlannerDriverPlanListItemTargerPlanEntryData,
  PlannerDriverPlanListItemUser,
} from "../../../../../common/services/planner/list/planner-driver-plan-list";
import {
  PlanningOrderSelectedItemData,
  PlanningOrderSelectedItemDataDriver,
  PlanningOrderSelectedItemDataPlanEntry,
} from "../../common/types/planning-order-selected-data";

const createSelectedItemDataPlanEntry = (
  planEntry: PlannerDriverPlanListItemTargerPlanEntryData
): PlanningOrderSelectedItemDataPlanEntry => {
  return {
    uuid: planEntry.uuid,
    id: planEntry.planEntryUuid,
  };
};

const createSelectedItemDataDriver = (
  user: PlannerDriverPlanListItemUser
): PlanningOrderSelectedItemDataDriver => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    uuid: user.uuid,
  };
};

const createSelectedItemData = (
  id: number,
  user: PlannerDriverPlanListItemUser,
  planEntry: PlannerDriverPlanListItemTargerPlanEntryData,
  taxiDriverAssociationUuid: string | null
): PlanningOrderSelectedItemData => {
  return {
    id: id,
    driver: createSelectedItemDataDriver(user),
    planEntry: createSelectedItemDataPlanEntry(planEntry),
    taxiName: user.taxiName,
    taxiDriverAssociationUuid: taxiDriverAssociationUuid!,
  };
};

const planningOrderDriverPlanListingFactory = {
  createSelectedItemData,
};

export default planningOrderDriverPlanListingFactory;
