import DelegationAddRequest, {
  DelegationAddRequestAddressSequenceNode,
  DelegationAddRequestAddressSequenceNodeGroup,
  DelegationAddRequestBody,
  DelegationAddRequestQueryParams,
  DelegationAddRequestVehicleType,
} from "./delegation-add.request";

const createAddressGroup = (
  addresses: DelegationAddRequestAddressSequenceNode[],
  vehicleTypes: DelegationAddRequestVehicleType
): DelegationAddRequestAddressSequenceNodeGroup => {
  return {
    nodes: addresses,
    vehicleType: vehicleTypes,
  };
};

const createAddressGroups = (
  addresses: DelegationAddRequestAddressSequenceNode[][],
  vehicleTypes: DelegationAddRequestVehicleType[]
): DelegationAddRequestAddressSequenceNodeGroup[] => {
  return addresses.map((addressGroup, index) =>
    createAddressGroup(addressGroup, vehicleTypes[index])
  );
};

const createQueryParams = (
  request: DelegationAddRequest
): DelegationAddRequestQueryParams => {
  return {
    timezone: request.timezone,
  };
};

const createRequestBody = (
  request: DelegationAddRequest
): DelegationAddRequestBody => {
  return {
    dateFrom: request.dateFrom,
    dateTo: request.dateTo,
    mileagesCost: request.mileagesCost,
    totalDistance: request.totalDistance,
    cargoCompanyId: request.workerCompanyUuid,
    workerId: request.workerUuid,
    addressSequence: {
      seq: createAddressGroups(
        request.addressSequence,
        request.groupVehicleTypes
      ),
    },
    osrmDistance: request.osrmDistance,
    accommodationBillsCost: request.accommodationBillsCost,
    accommodationCost: request.accommodationCost,
    prepay: request.prepay,
    startedDayDietCost: request.startedDayDietCost,
    wholeDayDietCost: request.wholeDayDietCost,
    localTravelCost: request.localTravelCost,
    oneDayDietCost: request.oneDayDietCost,
    otherExpenses: request.otherExpenses,
  };
};

const delegationAddRequestFactory = {
  createRequestBody,
  createQueryParams,
};

export default delegationAddRequestFactory;
