import MileageAddressListRequest, {
  MileageAddressListRequestOrder,
} from "../../../api/raily/mileage-address/list/mileage-address-list.request";
import MileageAddressListLoadParams, {
  MileageAddressListLoadParamsOrder,
} from "./mileage-address-list-load-params";

const createOrder = (
  order: MileageAddressListLoadParamsOrder | undefined
): MileageAddressListRequestOrder | undefined => {
  switch (order) {
    case MileageAddressListLoadParamsOrder.CREATED_AT_ASC:
      return MileageAddressListRequestOrder.CREATED_AT_ASC;
    case MileageAddressListLoadParamsOrder.CREATED_AT_DESC:
      return MileageAddressListRequestOrder.CREATED_AT_DESC;
    case MileageAddressListLoadParamsOrder.DESCRIPTION_ASC:
      return MileageAddressListRequestOrder.DESCRIPTION_ASC;
    case MileageAddressListLoadParamsOrder.DESCRIPTION_DESC:
      return MileageAddressListRequestOrder.DESCRIPTION_DESC;
    case MileageAddressListLoadParamsOrder.MODIFIED_AT_ASC:
      return MileageAddressListRequestOrder.MODIFIED_AT_ASC;
    case MileageAddressListLoadParamsOrder.MODIFIED_AT_DESC:
      return MileageAddressListRequestOrder.MODIFIED_AT_DESC;
    case MileageAddressListLoadParamsOrder.STREET_ASC:
      return MileageAddressListRequestOrder.STREET_ASC;
    case MileageAddressListLoadParamsOrder.STREET_DESC:
      return MileageAddressListRequestOrder.STREET_DESC;
    case MileageAddressListLoadParamsOrder.TOWN_ASC:
      return MileageAddressListRequestOrder.TOWN_ASC;
    case MileageAddressListLoadParamsOrder.TOWN_DESC:
      return MileageAddressListRequestOrder.TOWN_DESC;
    default:
      return undefined;
  }
};

const create = (
  loadParams: MileageAddressListLoadParams
): MileageAddressListRequest => {
  return {
    creationEnd: loadParams.creationEnd,
    creationStart: loadParams.creationStart,
    order: createOrder(loadParams.order),
    searchQuery: loadParams.searchQuery,
  };
};

const mileageAddressListRequestFactory = {
  create,
};

export default mileageAddressListRequestFactory;
