export enum BillingsCargoTaxiContributionDiscountType {
  DISCOUNT_OPTIMIZATION = "DISCOUNT_OPTIMIZATION",
  DISCOUNT_BEING_LATE = "DISCOUNT_BEING_LATE",
  DISCOUNT_INCOMPATIBLE_CAR = "DISCOUNT_INCOMPATIBLE_CAR",
  DISCOUNT_OTHER = "DISCOUNT_OTHER",
  CONTRACT_CORRECTION_PERCENTAGE = "CONTRACT_CORRECTION_PERCENTAGE",
}

export type BillingsCargoTaxiContributionContractCorretionPercentage = {
  type: BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE | null;
  percentage: number | null;
  amount: number | null;
};

export type BillingsCargoTaxiContributionDiscountOptimization = {
  type: BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION | null;
  comment: string;
  amount: number | null;
};

export type BillingsCargoTaxiContributionDiscountBeingLate = {
  type: BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE | null;
  comment: string;
  amount: number | null;
};

export type BillingsCargoTaxiContributionDiscountIncompatibleCar = {
  type: BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR | null;
  comment: string;
  amount: number | null;
};

export type BillingsCargoTaxiContributionDiscountOther = {
  type: BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER | null;
  comment: string;
  amount: number | null;
};

type BillingsCargoTaxiContributionDiscount =
  | BillingsCargoTaxiContributionContractCorretionPercentage
  | BillingsCargoTaxiContributionDiscountOptimization
  | BillingsCargoTaxiContributionDiscountBeingLate
  | BillingsCargoTaxiContributionDiscountIncompatibleCar
  | BillingsCargoTaxiContributionDiscountOther;

export default BillingsCargoTaxiContributionDiscount;
