import formValidationService from "../../../../../../../../../common/utils/validation/form-validation.service";
import { OrderRouteEditInternalPassenger } from "../../../../../types/order-route-edit-passenger";
import OrderRouteEditInternalPassengerEditFormData from "./types/order-route-edit-internal-passenger-edit-form-data";
import OrderRouteEditInternalPassengerEditFormValidationResults from "./types/order-route-edit-internal-passenger-edit-form-validation-results";

const getDefaultFormData = (
  passenger: OrderRouteEditInternalPassenger
): OrderRouteEditInternalPassengerEditFormData => {
  return {
    externalId: passenger.externalId,
  };
};

const getDefaultFormValidationResults =
  (): OrderRouteEditInternalPassengerEditFormValidationResults => {
    return {
      externalId: formValidationService.defaultValidationResult,
    };
  };

const orderRouteEditInternalPassengerEditHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default orderRouteEditInternalPassengerEditHelper;
