enum OrderActiveListingFilterType {
  INTERNAL_ORDER_ID = "INTERNAL_ORDER_ID",
  EXTERNAL_ORDER_ID = "EXTERNAL_ORDER_ID",
  CLIENT = "CLIENT",
  EXCLUDE_CLIENT = "EXCLUDE_CLIENT",
  DISPATCH = "DISPATCH",
  EXCLUDE_DISPATCH = "EXCLUDE_DISPATCH",
  DRIVER = "DRIVER",
  MOBILE_APP_USER = "MOBILE_APP_USER",
  PASSENGER = "PASSENGER",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  ROUTE_DESTINATION_ADDRESS = "ROUTE_DESTINATION_ADDRESS",
  ROUTE_INTERMEDIATE_ADDRESS = "ROUTE_INTERMEDIATE_ADDRESS",
  ROUTE_PICKUP_ADDRESS = "ROUTE_PICKUP_ADDRESS",
  EXECUTION_STATUS = "EXECUTION_STATUS",
  TAXI_CORPORATION = "TAXI_CORPORATION",
  EXCLUDE_TAXI_CORPORATION = "EXCLUDE_TAXI_CORPORATION",
  URGENT_ONLY = "URGENT_ONLY",
}

export default OrderActiveListingFilterType;
