import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type PlanningOrderDriverPlanListingSelectedFailureNotificationProps = {
  text: string;
};

const PlanningOrderDriverPlanListingSelectedFailureNotificationComponent: FC<
  PlanningOrderDriverPlanListingSelectedFailureNotificationProps
> = (props) => {
  return (
    <div className="planning-order-driver-plan-listing-selected__notification">
      <FontAwesomeIcon
        icon={faXmarkCircle}
        className="planning-order-driver-plan-listing-selected__notification-failure"
        size="4x"
      />
      <p>{props.text}</p>
    </div>
  );
};

export default PlanningOrderDriverPlanListingSelectedFailureNotificationComponent;
