import UserRole from "../../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DriverContractListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView"
  | "hasAccessToAddContract";

export type DriverContractListingUserPermissionsDefinition =
  UserPermissionsDefinition<DriverContractListingUserPermissionOption>;

export type DriverContractListingUserPermissions =
  UserPermissions<DriverContractListingUserPermissionOption>;

const driverContractListingUserPermissionDefinition: DriverContractListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER, UserRole.DEALER],
    hasAccessToRailyView: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
    ],
    hasAccessToAddContract: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
      UserRole.OPERATOR,
    ],
  };

export default driverContractListingUserPermissionDefinition;
