import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import AppAsideMinifiedNavigationItem from "./common/types/app-aside-minified-navigation-item";

type AppAsideMinifiedNavigationListItemProps = {
  item: AppAsideMinifiedNavigationItem;
};

const AppAsideMinifiedNavigationListItemComponent: FC<
  AppAsideMinifiedNavigationListItemProps
> = (props) => {
  return (
    <li className="app_aside_navigation_list_item">
      <div
        className={classNames(
          "app_aside_navigation_list_item__content",
          props.item.isActive && "active"
        )}
      >
        <div className="app_aside_navigation_list_item__content_data">
          <div className="app_aside_navigation_list_item__icon_wrapper">
            <FontAwesomeIcon
              icon={props.item.icon}
              className="app_aside_navigation_list_item__icon"
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default AppAsideMinifiedNavigationListItemComponent;
