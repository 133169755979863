import HttpResponse from "../../../utils/http/http.response";
import railyApiService from "../raily-api.service";
import OperatorAddRequest, {
  OperatorAddRequestBody,
} from "./add/operator-add-request";
import operatorAddRequestFactory from "./add/operator-add-request.factory";
import operatorListRequestFactory from "./list/operator-list-request.factory";
import OperatorListRequest, {
  OperatorListRequestQueryParams,
} from "./list/operator-list.request";
import OperatorListResponse from "./list/operator-list.response";

const operatorApiService = () => {
  const add = (
    request: OperatorAddRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/operators`;

    const body: OperatorAddRequestBody =
      operatorAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const getList = async (
    request: OperatorListRequest,
    abortSignal: AbortSignal
  ): Promise<OperatorListResponse> => {
    const url = `/users/all/operators`;

    const queryParams: OperatorListRequestQueryParams =
      operatorListRequestFactory.createQueryParams(request);

    return railyApiService().get<OperatorListResponse>({
      url,
      queryParams,
      abortSignal,
    });
  };

  return { add, getList };
};

export default operatorApiService;
