import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import cargoOrderSettlementListingUserPermissionDefinition, {
  CargoOrderSettlementListingUserPermissions,
} from "./cargo-order-settlement-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): CargoOrderSettlementListingUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    cargoOrderSettlementListingUserPermissionDefinition
  );
};

const cargoOrderSettlementListingUserPermissionsHelper = {
  getPermissions,
};

export default cargoOrderSettlementListingUserPermissionsHelper;
