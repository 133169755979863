import Joi from "joi";
import DriverContractListingByTaxiRouteQueryParams from "./types/driver-contract-listing-by-taxi-route-query-params";
import DriverContractListingByTaxiRouteQueryFilterParams from "./types/driver-contract-listing-by-taxi-route-query-filter-params";
import DriverContractListingByTaxiFilter, {
  DriverContractListingByTaxiBillingModelFilter,
} from "./types/driver-contract-listing-by-taxi-filter";
import DriverContractListingByTaxiFilterType from "./types/driver-contract-listing-by-taxi-filter-type";
import DriverContractListingBillingModel from "../../common/types/driver-contract-listing-billing-model";
import DriverContractListingByTaxiSortKey from "./types/driver-contract-listing-by-taxi-sort-key";

const getFilters = (
  routeQueryFilterParams: DriverContractListingByTaxiRouteQueryFilterParams
): DriverContractListingByTaxiFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: DriverContractListingByTaxiFilter[] = [];

  if (routeQueryFilterParams.billingModel) {
    const billingModelFilter: DriverContractListingByTaxiBillingModelFilter = {
      type: DriverContractListingByTaxiFilterType.BILLING_MODEL,
      value:
        routeQueryFilterParams.billingModel as DriverContractListingByTaxiBillingModelFilter["value"],
    };

    filters.push(billingModelFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: DriverContractListingByTaxiRouteQueryParams
): DriverContractListingByTaxiSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const getPage = (
  routeQueryParams: DriverContractListingByTaxiRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryParams.page)) {
    return undefined;
  }

  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: DriverContractListingByTaxiRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryParams.pageSize)) {
    return undefined;
  }
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: DriverContractListingByTaxiFilter[],
  sortKey: DriverContractListingByTaxiSortKey | null,
  page: number,
  pageSize: number
): DriverContractListingByTaxiRouteQueryParams => {
  const routeQueryParams: DriverContractListingByTaxiRouteQueryParams = {
    billingModel: filters.find(
      (filter) =>
        filter.type === DriverContractListingByTaxiFilterType.BILLING_MODEL
    )?.value,
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const validateFilters = (
  routeQueryFilterParams: DriverContractListingByTaxiRouteQueryFilterParams
) => {
  const filterParams: DriverContractListingByTaxiRouteQueryFilterParams = {
    billingModel: routeQueryFilterParams.billingModel,
  };

  const validationSchema =
    Joi.object<DriverContractListingByTaxiRouteQueryFilterParams>({
      billingModel: Joi.valid(
        ...Object.values(DriverContractListingBillingModel)
      ),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: DriverContractListingByTaxiRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(DriverContractListingByTaxiSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: DriverContractListingByTaxiRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: DriverContractListingByTaxiRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const driverContractListingByTaxiRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default driverContractListingByTaxiRouteQueryParamsService;
