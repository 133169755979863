import { FC, useEffect, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import SelectOption from "../../../../common/components/form/select/common/option/select-option";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderAbandonedListingFilterSelectOption from "../common/types/order-abandoned-listing-filter-select-option";
import OrderAbandonedListingFilter from "../common/types/order-abandoned-listing-filter";
import orderAbandonedListingFiltersHelper from "./order-abandoned-listing-filters.helper";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";

type OrderAbandonedListingFiltersProps = {
  filters: OrderAbandonedListingFilter[];
  onAddNewFilter: (filter: OrderAbandonedListingFilter) => void;
};

const OrderAbandonedListingFiltersComponent: FC<
  OrderAbandonedListingFiltersProps
> = (props) => {
  const filterTranslations =
    orderTranslationsHelper.getAbandonedListingTranslations().filters;

  const { selectedAppLanguage } = useAppContext();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchSelectOptions, setSearchSelectOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    const allFilterOptions =
      orderAbandonedListingFiltersHelper.getSelectOptionsByQuery(
        searchInputValue
      );

    const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
      return !props.filters.find(
        (item) =>
          JSON.stringify(item) === JSON.stringify(option.value) ||
          item.type === option.value.type
      );
    });

    setSearchSelectOptions(filterOptionsWithoutSelected);
  }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (filter: OrderAbandonedListingFilterSelectOption) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <div className="order_abandoned_listing_filters">
      <SingleSelectComponent
        onChange={(option) => onFilterSelect(option!)}
        options={searchSelectOptions}
        value={null}
        classNames={{ root: "order_abandoned_listing_filters__select" }}
        placeholder={filterTranslations.search.inputPlaceholder}
        inputValue={searchInputValue}
        onInputChange={setSearchInputValue}
        isSearchable
        filterFunction={() => true}
        menuPlacement="bottom"
        maxMenuHeight={400}
        noOptionsMessage={() => {
          return filterTranslations.search.searchTipMessage;
        }}
        idForTesting="order-abandonded-listing-filter"
      />
    </div>
  );
};

export default OrderAbandonedListingFiltersComponent;
