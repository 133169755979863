import appTranslationsHelper from "./app-translations.helper";
import TaxiUnsettledOrderListingTranslations from "./types/settlement/taxi-unsettled-order-listing.translations";
import CargoOrderSettlementsListingTranslations from "./types/settlement/cargo-order-settlements-listing.translations";
import TaxiRouteSettlementsListingTranslations from "./types/settlement/taxi-route-settlements-listing.translations";
import TaxiOrderSettlementsListingTranslations from "./types/settlement/taxi-order-settlements-listing.translations";
import TaxiUnsettledRouteListingTranslations from "./types/settlement/taxi-unsettled-route-listing.translations";
import TaxiUnsettledTransferedOrderListingTranslations from "./types/settlement/taxi-unsettled-transfered-order-listing.translations";
import TaxiUnsettledPrivateOrderListingTranslations from "./types/settlement/taxi-unsettled-private-order-listing.translations";
import TaxiTransferedOrderSettlementsListingTranslations from "./types/settlement/taxi-transfered-order-settlements-listing.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `settlement`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getTaxiUnsettledOrderListingTranslations =
  (): TaxiUnsettledOrderListingTranslations => {
    const translations = getTranslations<TaxiUnsettledOrderListingTranslations>(
      `taxi-unsettled-order-listing.translations.json`
    );

    return translations;
  };

const getTaxiUnsettledPrivateOrderListingTranslations =
  (): TaxiUnsettledPrivateOrderListingTranslations => {
    const translations =
      getTranslations<TaxiUnsettledPrivateOrderListingTranslations>(
        `taxi-unsettled-private-order-listing.translations.json`
      );

    return translations;
  };

const getTaxiUnsettledRouteListingTranslations =
  (): TaxiUnsettledRouteListingTranslations => {
    const translations = getTranslations<TaxiUnsettledRouteListingTranslations>(
      `taxi-unsettled-route-listing.translations.json`
    );

    return translations;
  };

const getTaxiRouteSettlementListingTranslations =
  (): TaxiRouteSettlementsListingTranslations => {
    return getTranslations<TaxiRouteSettlementsListingTranslations>(
      `taxi-route-settlements-listing.translations.json`
    );
  };

const getTaxiOrderSettlementListingTranslations =
  (): TaxiOrderSettlementsListingTranslations => {
    return getTranslations<TaxiOrderSettlementsListingTranslations>(
      `taxi-order-settlements-listing.translations.json`
    );
  };

const getCargoOrderSettlementListingTranslations =
  (): CargoOrderSettlementsListingTranslations => {
    return getTranslations<CargoOrderSettlementsListingTranslations>(
      `cargo-order-settlements-listing.translations.json`
    );
  };

const getTaxiUnsettledTransferedOrderListingTranslations =
  (): TaxiUnsettledTransferedOrderListingTranslations => {
    return getTranslations<TaxiUnsettledTransferedOrderListingTranslations>(
      `taxi-unsettled-transfered-order-listing.translations.json`
    );
  };

const getTaxiTransferedOrderSettlementListingTranslations =
  (): TaxiTransferedOrderSettlementsListingTranslations => {
    return getTranslations<TaxiTransferedOrderSettlementsListingTranslations>(
      `taxi-transfered-order-settlements-listing.translations.json`
    );
  };

const settlementTranslationsHelper = {
  getTaxiUnsettledOrderListingTranslations,
  getTaxiUnsettledPrivateOrderListingTranslations,
  getTaxiUnsettledRouteListingTranslations,
  getCargoOrderSettlementListingTranslations,
  getTaxiOrderSettlementListingTranslations,
  getTaxiRouteSettlementListingTranslations,
  getTaxiUnsettledTransferedOrderListingTranslations,
  getTaxiTransferedOrderSettlementListingTranslations,
};

export default settlementTranslationsHelper;
