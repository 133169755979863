import {
  faAdd,
  faBuilding,
  faEdit,
  faIcons,
} from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import mileageRoutesHelper from "../routes/mileage-routes.helper";
import MileageDictionaryAddRecordBreadcrumbParams from "./types/mileage-dictionary-record-add-breadcrumbs-params";
import MileageDictionaryEditRecordBreadcrumbParams from "./types/mileage-dictionary-record-edit-breadcrumbs-params";
import MileageContractUpdateBreadcrumbsParams from "./types/mileage-contract-update-breadcrumbs-params";

const getMileageListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().mileageListing,
    },
  ];
};

const getMileageAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().mileageAdd,
    },
  ];
};

const getMileageContractListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getContractListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageContractListing,
    },
  ];
};

const getMileageContractAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getContractListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageContractListing,
    },
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getContractAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageContractAdd,
    },
  ];
};

const getMileageContractUpdateBreadcrumbs = (
  params: MileageContractUpdateBreadcrumbsParams
): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getContractListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageContractListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.companyName,
    },
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getContractUpdateRoute({
        contractUuid: params.contractUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageContractUpdate,
    },
  ];
};

const getMileageSettingsBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getSettingsRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().mileageSettings,
    },
  ];
};

const getMileageDictionaryBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: undefined,
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDictionary,
    },
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getDictionaryRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDictionaryListing,
    },
  ];
};

const getMileageDictionaryAddRecordBreadcrumbs = (
  params: MileageDictionaryAddRecordBreadcrumbParams
): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: undefined,
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDictionary,
    },
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getDictionaryRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDictionaryListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faAdd,
      linkTo: mileageRoutesHelper.getDictionaryRecordAddRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDictionaryAddRecord,
    },
  ];
};

const getMileageDictionaryUpdateRecordBreadcrumbs = (
  params: MileageDictionaryEditRecordBreadcrumbParams
): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: undefined,
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDictionary,
    },
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getDictionaryRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDictionaryListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faEdit,
      linkTo: mileageRoutesHelper.getDictionaryRecordUpdateRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
        recordUuid: params.dictionaryRecordUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .mileageDictionaryUpdateRecord,
    },
  ];
};

const getMileageUpdateBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: mileageRoutesHelper.getListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().mileageListing,
    },
    {
      icon: faIcons,
      linkTo: undefined,
      text: appTranslationsHelper.getBreadcrumbsTranslations().mileageEdit,
    },
  ];
};

const mileageBreadcrumbsHelper = {
  getMileageListingBreadcrumbs,
  getMileageContractListingBreadcrumbs,
  getMileageContractAddBreadcrumbs,
  getMileageContractUpdateBreadcrumbs,
  getMileageAddBreadcrumbs,
  getMileageSettingsBreadcrumbs,
  getMileageDictionaryBreadcrumbs,
  getMileageDictionaryAddRecordBreadcrumbs,
  getMileageDictionaryUpdateRecordBreadcrumbs,
  getMileageUpdateBreadcrumbs,
};

export default mileageBreadcrumbsHelper;
