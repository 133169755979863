import OrderDetailsResponse from "./order-details.response";
import OrderDetailsRouteRequest from "./order-details-route.request";
import OrderDetailsRouteResponse from "./order-details-route.response";
import OrderDetailsRidesNodeExlusionsResponse from "./order-details-rides-node-exclusions.response";
import OrderDetailsSolvedRidesRequest from "./order-details-solved-rides.request";
import OrderDetailsSolvedRidesResponse from "./order-details-solved-rides.response";
import OrderDetailsCancelOrderRequestResponse from "./order-details-cancel-order-request.response";
import OrderDetailsCancelOrderResponse from "./order-details-cancel-order.response";
import OrderDetailsCompletedRouteResponse from "./order-details-completed-route.response";
import appConfig from "../../../../../app.config";
import authService from "../../../../auth/common/auth.service";
import searchRoadRoutingService from "../../../../../common/utils/search-road-route/search-road-routing.service";

const fetchRoute = (
  request: OrderDetailsRouteRequest
): Promise<OrderDetailsRouteResponse> => {
  return searchRoadRoutingService.search(request);
};

const fetchRidesNodeExclusions = (
  orderUuid: string
): Promise<OrderDetailsRidesNodeExlusionsResponse> => {
  const path = `/orders/cargo-orders/${orderUuid}/node-exclusion`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchDetails = (orderUuid: string): Promise<OrderDetailsResponse> => {
  const path = `/orders/cargo-orders/${orderUuid}/complex`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchSolvedRides = (
  request: OrderDetailsSolvedRidesRequest
): Promise<OrderDetailsSolvedRidesResponse> => {
  const path = `/rides/solved`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(request),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchCancelRequest = (
  orderUuid: string
): Promise<OrderDetailsCancelOrderRequestResponse> => {
  const path = `/orders/${orderUuid}/cancel-request`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchCancel = (
  orderUuid: string
): Promise<OrderDetailsCancelOrderResponse> => {
  const path = `/orders/${orderUuid}/cancel-order`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchCompletedRoute = (
  orderUuid: string
): Promise<OrderDetailsCompletedRouteResponse> => {
  const path = `/gps-gateway/records/cargo-order/${orderUuid}/gps-time-nodes`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const orderDetailsApiService = {
  fetchRoute,
  fetchDetails,
  fetchRidesNodeExclusions,
  fetchSolvedRides,
  fetchCancelRequest,
  fetchCancel,
  fetchCompletedRoute,
};

export default orderDetailsApiService;
