import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";

const validateStreet = (street: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).required();

  return formValidationService.validate(street, validationSchema);
};

const validateHouseNumber = (houseNumber: string): FormValidationResult => {
  const validationSchema = Joi.string().max(10).trim().required();

  return formValidationService.validate(houseNumber, validationSchema);
};

const validateApartmentNumber = (
  apartmentNumber: string
): FormValidationResult => {
  const validationSchema = Joi.string().allow("").max(10).trim();

  return formValidationService.validate(apartmentNumber, validationSchema);
};

const validateTown = (town: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).trim().required();

  return formValidationService.validate(town, validationSchema);
};

const validateZipCode = (zipCode: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(10).trim().required();

  return formValidationService.validate(zipCode, validationSchema);
};

const delegationAddressAddModalValidationService = {
  validateStreet,
  validateHouseNumber,
  validateApartmentNumber,
  validateTown,
  validateZipCode,
};

export default delegationAddressAddModalValidationService;
