import MileageRoadRouteLoadParams, {
  MileageRoadRouteWaypoints,
} from "../../../../../../common/services/mileage/road-route/mileage-road-route-load-params";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import MileageUpdateAddress from "../../types/mileage-update-address";
import MileageUpdateSummaryRouteWaypoint from "./route/types/mileage-update-summary-route-waypoint";

const createWaypoint = (
  address: MileageUpdateAddress,
  addressIndex: number
): MileageUpdateSummaryRouteWaypoint => {
  return {
    distance: null,
    time: null,
    markerPosition: addressIndex + 1,
    place: {
      displayName: address.displayName,
      latitude: address.latitude,
      longitude: address.longitude,
    },
    uuid: address.uuid ?? uuidService.generate(),
  };
};

const createWaypoints = (
  addresses: MileageUpdateAddress[]
): MileageUpdateSummaryRouteWaypoint[] => {
  return addresses.map(createWaypoint);
};

const createSearchRoutingCoordinate = (
  waypoint: MileageUpdateSummaryRouteWaypoint
): MileageRoadRouteWaypoints => {
  return {
    lat: waypoint.place?.latitude!,
    lon: waypoint.place?.longitude!,
  };
};

const createSearchRoutingRequest = (
  waypoints: MileageUpdateSummaryRouteWaypoint[]
): MileageRoadRouteLoadParams => {
  const request: MileageRoadRouteLoadParams = {
    waypoints: waypoints.map(createSearchRoutingCoordinate),
  };

  return request;
};

const mileageUpdateSummaryFactory = {
  createWaypoints,
  createSearchRoutingRequest,
};

export default mileageUpdateSummaryFactory;
