import MileageContractAddRequest from "../../../api/raily/mileage-contract/add/mileage-contract-add.request";
import MileageContractAddParams from "./mileage-contract-add-params";

const create = (
  params: MileageContractAddParams
): MileageContractAddRequest => {
  return {
    companyUuid: params.companyUuid,
    dietFullAfterHours: params.dietFullAfterHours,
    dietFullRate: params.dietFullRate,
    dietHalfAfterHours: params.dietHalfAfterHours,
    dietHalfRate: params.dietHalfRate,
    disabled: params.disabled,
    distanceRateCarGt900cm3: params.distanceRateCarGt900cm3,
    distanceRateCarLe900cm3: params.distanceRateCarLe900cm3,
    distanceRateCompanyVehicle: params.distanceRateCompanyVehicle,
    distanceRateMoped: params.distanceRateMoped,
    distanceRateMotorcycle: params.distanceRateMotorcycle,
    validSince: params.validSince,
    validTo: params.validTo,
  };
};

const mileageContractsAddRequestFactory = {
  create,
};

export default mileageContractsAddRequestFactory;
