import taxiUnsettledRouteListingRouteQueryParamsService from "../../common/taxi-unsettled-route-listing-route-query-params.service";
import TaxiUnsettledRouteListingFilter from "../../common/types/taxi-unsettled-route-listing-filter";
import TaxiUnsettledRouteListingSortKey from "../../common/types/taxi-unsettled-route-listing-sort-key";
import TaxiUnsettledRouteListingByRailyRouteQueryParams from "./types/taxi-unsettled-route-listing-by-raily-route-query-params";
import TaxiUnsettledRouteListingByRailyTaxiCoporation from "./types/taxi-unsettled-route-listing-by-raily-taxi-corporation-company";
import Joi from "joi";

const createRouteQueryParams = (
  taxiCorporationUuid:
    | TaxiUnsettledRouteListingByRailyTaxiCoporation["uuid"]
    | null,
  filters: TaxiUnsettledRouteListingFilter[],
  sortKey: TaxiUnsettledRouteListingSortKey | null,
  page: number,
  pageSize: number
): TaxiUnsettledRouteListingByRailyRouteQueryParams => {
  const commonParams =
    taxiUnsettledRouteListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return {
    ...commonParams,
    taxiCompanyUuid: taxiCorporationUuid ?? undefined,
  };
};

const getTaxiCorporationUuid = (
  routeQueryParams: TaxiUnsettledRouteListingByRailyRouteQueryParams
): string | undefined => {
  if (!validateTaxiCorporationUuid(routeQueryParams.taxiCompanyUuid)) {
    return undefined;
  }

  return routeQueryParams.taxiCompanyUuid;
};

const validateTaxiCorporationUuid = (
  routeQueryTaxiCorporationParam: TaxiUnsettledRouteListingByRailyRouteQueryParams["taxiCompanyUuid"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryTaxiCorporationParam).error
    ?.message;
};

const taxiUnsettledRouteListingByRailyRouteQueryParamsService = {
  getFilters: taxiUnsettledRouteListingRouteQueryParamsService.getFilters,
  getSortKey: taxiUnsettledRouteListingRouteQueryParamsService.getSortKey,
  getPage: taxiUnsettledRouteListingRouteQueryParamsService.getPage,
  getPageSize: taxiUnsettledRouteListingRouteQueryParamsService.getPageSize,
  getTaxiCorporationUuid,
  createRouteQueryParams,
};

export default taxiUnsettledRouteListingByRailyRouteQueryParamsService;
