import Notiflix from "notiflix";

const notificationService = () => {
  Notiflix.Notify.init({
    zindex: 999999,
    timeout: 3000,
    pauseOnHover: true,
    clickToClose: true,
  });

  const success = (message: string) => {
    return Notiflix.Notify.success(message, {
      className: "notification_success",
    });
  };

  const warning = (message: string) => {
    return Notiflix.Notify.warning(message, {
      className: "notification_warning",
    });
  };

  const error = (message: string) => {
    return Notiflix.Notify.failure(message, {
      className: "notification_error",
    });
  };

  const info = (message: string) => {
    return Notiflix.Notify.info(message, {
      className: "notification_info",
    });
  };

  return {
    success,
    warning,
    error,
    info,
  };
};

export default notificationService();
