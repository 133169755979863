import TaxiFleetPartnerDetailsRequest from "../../../api/raily/taxi-fleet-partner/details/taxi-fleet-partner-details.request";
import TaxiFleetPartnerDetailsLoadParams from "./taxi-fleet-partner-details-load-params";

const create = (
  params: TaxiFleetPartnerDetailsLoadParams
): TaxiFleetPartnerDetailsRequest => {
  return {
    fleetPartnerId: params.fleetPartnerUuid,
  };
};

const taxiFleetPartnerDetailsRequestFactory = {
  create,
};

export default taxiFleetPartnerDetailsRequestFactory;
