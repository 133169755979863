export enum TaxiCorporationListRequestOrder {
  COMPANY_NAME_ASC = "COMPANY_NAME_ASC",
  COMPANY_NAME_DESC = "COMPANY_NAME_DESC",
  DISPLAY_NAME_ASC = "DISPLAY_NAME_ASC",
  DISPLAY_NAME_DESC = "DISPLAY_NAME_DESC",
}

export type TaxiCorporationListRequestQueryParams = {
  taxId: string | undefined;
  regon: string | undefined;
  order: TaxiCorporationListRequestOrder | undefined;
  krsNo: string | undefined;
  companyName: string | undefined;
  displayName: string | undefined;
  headquartersAddressString: string | undefined;
  notes: string | undefined;
  offset: number | undefined;
  limit: number | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
};

type TaxiCorporationListRequest = {
  taxId?: string;
  regon?: string;
  order?: TaxiCorporationListRequestOrder;
  krsNo?: string;
  companyName?: string;
  displayName?: string;
  headquartersAddressString: string | undefined;
  notes?: string;
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
};

export default TaxiCorporationListRequest;
