import HttpResponse from "../../../utils/http/http.response";
import railyApiService from "../raily-api.service";
import taxiOfficerAddRequestFactory from "./add/taxi-officer-add-request.factory";
import TaxiOfficerAddRequest, {
  TaxiOfficerAddRequestBody,
} from "./add/taxi-officer-add.request";
import taxiOfficerListRequestFactory from "./list/taxi-officer-list-request.factory";
import TaxiOfficerListRequest, {
  TaxiOfficerListRequestQueryParams,
} from "./list/taxi-officer-list.request";
import TaxiOfficerListResponse from "./list/taxi-officer-list.response";
import {
  TaxiOfficerEditRequest,
  TaxiOfficerEditRequestBody,
} from "./edit/taxi-officer-edit.request";
import taxiOfficerEditRequestFactory from "./edit/taxi-officer-edit-request.factory";
import TaxiOfficerDetailsRequest from "./details/taxi-officer-details.request";
import TaxiOfficerDetailsResponse from "./details/taxi-officer-details.response";

const taxiOfficerApiService = () => {
  const add = (
    request: TaxiOfficerAddRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/taxi-officers`;

    const body: TaxiOfficerAddRequestBody =
      taxiOfficerAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const edit = (
    taxiOfficerUuid: string,
    request: TaxiOfficerEditRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/taxi-officers/${taxiOfficerUuid}`;

    const body: TaxiOfficerEditRequestBody =
      taxiOfficerEditRequestFactory.createBody(request);

    return railyApiService().put({
      url,
      abortSignal,
      body,
    });
  };

  const getList = async (
    request: TaxiOfficerListRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiOfficerListResponse> => {
    const url = `/users/all/taxi-officers`;

    const queryParams: TaxiOfficerListRequestQueryParams =
      taxiOfficerListRequestFactory.createQueryParams(request);

    return railyApiService().get<TaxiOfficerListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getDetails = async (
    request: TaxiOfficerDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiOfficerDetailsResponse> => {
    const url = `taxi-officers/${request.taxiOfficerUuid}`;

    return railyApiService().get<TaxiOfficerDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return { add, edit, getList, getDetails };
};

export default taxiOfficerApiService;
