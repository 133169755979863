import billingsApiService from "../../api/raily/billings/billings-api.service";
import BillingsOrderForwardCargoTaxiRequest from "../../api/raily/billings/order-forward/cargo-taxi/billings-order-forward-cargo-taxi.request";
import BillingsOrderForwardTaxiDriverRequest from "../../api/raily/billings/order-forward/taxi-driver/billings-order-forward-taxi-driver.request";
import BillingsOrderForwardTaxiTaxiRequest from "../../api/raily/billings/order-forward/taxi-taxi/billings-order-forward-taxi-taxi.request";
import BillingsOrderAddressesRequest from "../../api/raily/billings/order/addresses/billings-order-addresses.request";
import BillingsOrderTaxiDriverRequest from "../../api/raily/billings/order/taxi-driver/billings-order-taxi-driver.request";
import BillingsOrderTaxiTaxiRequest from "../../api/raily/billings/order/taxi-taxi/billings-order-taxi-taxi.request";
import BillingsPlanEntryCargoTaxiRequest from "../../api/raily/billings/plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi.request";
import BillingsPlanEntryTaxiTaxiRequest from "../../api/raily/billings/plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import BillingsOrderForwardCargoTaxi from "./order-forward/cargo-taxi/billings-order-forward-cargo-taxi";
import BillingsOrderForwardCargoTaxiError from "./order-forward/cargo-taxi/billings-order-forward-cargo-taxi-error";
import BillingsOrderForwardCargoTaxiLoadParams from "./order-forward/cargo-taxi/billings-order-forward-cargo-taxi-load-params";
import billingsOrderForwardCargoTaxiRequestFactory from "./order-forward/cargo-taxi/billings-order-forward-cargo-taxi-request.factory";
import billingsOrderForwardCargoTaxiFactory from "./order-forward/cargo-taxi/billings-order-forward-cargo-taxi.factory";
import BillingsOrderForwardTaxiDriver from "./order-forward/taxi-driver/billings-order-forward-taxi-driver";
import BillingsOrderForwardTaxiDriverError from "./order-forward/taxi-driver/billings-order-forward-taxi-driver-error";
import BillingsOrderForwardTaxiDriverLoadParams from "./order-forward/taxi-driver/billings-order-forward-taxi-driver-load-params";
import billingsOrderForwardTaxiDriverRequestFactory from "./order-forward/taxi-driver/billings-order-forward-taxi-driver-request.factory";
import billingsOrderForwardTaxiDriverFactory from "./order-forward/taxi-driver/billings-order-forward-taxi-driver.factory";
import BillingsOrderForwardTaxiTaxi from "./order-forward/taxi-taxi/billings-order-forward-taxi-taxi";
import BillingsOrderForwardTaxiTaxiError from "./order-forward/taxi-taxi/billings-order-forward-taxi-taxi-error";
import BillingsOrderForwardTaxiTaxiLoadParams from "./order-forward/taxi-taxi/billings-order-forward-taxi-taxi-load-params";
import billingsOrderForwardTaxiTaxiRequestFactory from "./order-forward/taxi-taxi/billings-order-forward-taxi-taxi-request.factory";
import billingsOrderForwardTaxiTaxiFactory from "./order-forward/taxi-taxi/billings-order-forward-taxi-taxi.factory";
import BillingsOrderAddressesError from "./order/addresses/billings-order-addresses-error";
import BillingsOrderAddressesLoadParams from "./order/addresses/billings-order-addresses-load-params";
import billingsOrderAddressesRequestFactory from "./order/addresses/billings-order-addresses-request-factory";
import billingsOrderAddressesFactory from "./order/addresses/billings-order-addresses.factory";
import BillingsOrderTaxiDriver from "./order/taxi-driver/billings-order-taxi-driver";
import BillingsOrderTaxiDriverError from "./order/taxi-driver/billings-order-taxi-driver-error";
import BillingsOrderTaxiDriverLoadParams from "./order/taxi-driver/billings-order-taxi-driver-load-params";
import billingsOrderTaxiDriverRequestFactory from "./order/taxi-driver/billings-order-taxi-driver-request.factory";
import billingsOrderTaxiDriverFactory from "./order/taxi-driver/billings-order-taxi-driver.factory";
import BillingsOrderTaxiTaxi from "./order/taxi-taxi/billings-order-taxi-taxi";
import BillingsOrderTaxiTaxiError from "./order/taxi-taxi/billings-order-taxi-taxi-error";
import BillingsOrderTaxiTaxiLoadParams from "./order/taxi-taxi/billings-order-taxi-taxi-load-params";
import billingsOrderTaxiTaxiRequestFactory from "./order/taxi-taxi/billings-order-taxi-taxi-request.factory";
import billingsOrderTaxiTaxiFactory from "./order/taxi-taxi/billings-order-taxi-taxi.factory";
import TransferredOrderStatusCountError from "./order/transferred-order-status-count/transferred-order-status-count-error.model";
import transferredOrderStatusCountFactory from "./order/transferred-order-status-count/transferred-order-status-count.factory";
import TransferredOrderStatusCount from "./order/transferred-order-status-count/transferred-order-status-count.model";
import BillingsPlanEntryCargoTaxi from "./plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi";
import BillingsPlanEntryCargoTaxiError from "./plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi-error";
import BillingsPlanEntryCargoTaxiLoadParams from "./plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi-load-params";
import billingsPlanEntryCargoTaxiRequestFactory from "./plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi-request.factory";
import billingsPlanEntryCargoTaxiFactory from "./plan-entry/cargo-taxi/billings-plan-entry-cargo-taxi.factory";
import BillingsPlanEntryTaxiTaxi from "./plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi";
import BillingsPlanEntryTaxiTaxiError from "./plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi-error";
import BillingsPlanEntryTaxiTaxiLoadParams from "./plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi-load-params";
import billingsPlanEntryTaxiTaxiRequestFactory from "./plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi-request.factory";
import billingsPlanEntryTaxiTaxiFactory from "./plan-entry/taxi-taxi/billings-plan-entry-taxi-taxi.factory";

const handleOrderForwardCargoTaxiError = (
  error: HttpError | BillingsOrderForwardCargoTaxiError
): BillingsOrderForwardCargoTaxiError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getOrderForwardCargoTaxi = async (
  params: BillingsOrderForwardCargoTaxiLoadParams,
  abortSignal: AbortSignal
): Promise<BillingsOrderForwardCargoTaxi> => {
  const request: BillingsOrderForwardCargoTaxiRequest =
    billingsOrderForwardCargoTaxiRequestFactory.create(params);

  try {
    const response = await billingsApiService().getOrderForwardCargoTaxi(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoTaxi = billingsOrderForwardCargoTaxiFactory.create(response);

    return cargoTaxi;
  } catch (_error) {
    const error = _error as HttpError | BillingsOrderForwardCargoTaxiError;

    throw handleOrderForwardCargoTaxiError(error);
  }
};

const handleOrderForwardTaxiDriverError = (
  error: HttpError | BillingsOrderForwardTaxiDriverError
): BillingsOrderForwardTaxiDriverError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getOrderForwardTaxiDriver = async (
  params: BillingsOrderForwardTaxiDriverLoadParams,
  abortSignal: AbortSignal
): Promise<BillingsOrderForwardTaxiDriver> => {
  const request: BillingsOrderForwardTaxiDriverRequest =
    billingsOrderForwardTaxiDriverRequestFactory.create(params);

  try {
    const response = await billingsApiService().getOrderForwardTaxiDriver(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiDriver = billingsOrderForwardTaxiDriverFactory.create(response);

    return taxiDriver;
  } catch (_error) {
    const error = _error as HttpError | BillingsOrderForwardTaxiDriverError;

    throw handleOrderForwardTaxiDriverError(error);
  }
};

const handleOrderForwardTaxiTaxiError = (
  error: HttpError | BillingsOrderForwardTaxiTaxiError
): BillingsOrderForwardTaxiTaxiError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getOrderForwardTaxiTaxi = async (
  params: BillingsOrderForwardTaxiTaxiLoadParams,
  abortSignal: AbortSignal
): Promise<BillingsOrderForwardTaxiTaxi> => {
  const request: BillingsOrderForwardTaxiTaxiRequest =
    billingsOrderForwardTaxiTaxiRequestFactory.create(params);

  try {
    const response = await billingsApiService().getOrderForwardTaxiTaxi(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiTaxi = billingsOrderForwardTaxiTaxiFactory.create(response);

    return taxiTaxi;
  } catch (_error) {
    const error = _error as HttpError | BillingsOrderForwardTaxiTaxiError;

    throw handleOrderForwardTaxiTaxiError(error);
  }
};

const handlePlanEntryCargoTaxiError = (
  error: HttpError | BillingsPlanEntryCargoTaxiError
): BillingsPlanEntryCargoTaxiError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getPlanEntryCargoTaxi = async (
  params: BillingsPlanEntryCargoTaxiLoadParams,
  abortSignal: AbortSignal
): Promise<BillingsPlanEntryCargoTaxi> => {
  const request: BillingsPlanEntryCargoTaxiRequest =
    billingsPlanEntryCargoTaxiRequestFactory.create(params);

  try {
    const response = await billingsApiService().getPlanEntryCargoTaxi(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoTaxi = billingsPlanEntryCargoTaxiFactory.create(response);

    return cargoTaxi;
  } catch (_error) {
    const error = _error as HttpError | BillingsPlanEntryCargoTaxiError;

    throw handlePlanEntryCargoTaxiError(error);
  }
};

const handlePlanEntryTaxiTaxiError = (
  error: HttpError | BillingsPlanEntryTaxiTaxiError
): BillingsPlanEntryTaxiTaxiError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getPlanEntryTaxiTaxi = async (
  params: BillingsPlanEntryTaxiTaxiLoadParams,
  abortSignal: AbortSignal
): Promise<BillingsPlanEntryTaxiTaxi> => {
  const request: BillingsPlanEntryTaxiTaxiRequest =
    billingsPlanEntryTaxiTaxiRequestFactory.create(params);

  try {
    const response = await billingsApiService().getPlanEntryTaxiTaxi(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiTaxi = billingsPlanEntryTaxiTaxiFactory.create(response);

    return taxiTaxi;
  } catch (_error) {
    const error = _error as HttpError | BillingsPlanEntryTaxiTaxiError;

    throw handlePlanEntryTaxiTaxiError(error);
  }
};

const handleOrderTaxiDriverError = (
  error: HttpError | BillingsOrderTaxiDriverError
): BillingsOrderTaxiDriverError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getOrderTaxiDriver = async (
  params: BillingsOrderTaxiDriverLoadParams,
  abortSignal: AbortSignal
): Promise<BillingsOrderTaxiDriver> => {
  const request: BillingsOrderTaxiDriverRequest =
    billingsOrderTaxiDriverRequestFactory.create(params);

  try {
    const response = await billingsApiService().getOrderTaxiDriver(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiDriver = billingsOrderTaxiDriverFactory.create(response);

    return taxiDriver;
  } catch (_error) {
    const error = _error as HttpError | BillingsOrderTaxiDriverError;

    throw handleOrderTaxiDriverError(error);
  }
};

const handleOrderTaxiTaxiError = (
  error: HttpError | BillingsOrderTaxiTaxiError
): BillingsOrderTaxiTaxiError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getOrderTaxiTaxi = async (
  params: BillingsOrderTaxiTaxiLoadParams,
  abortSignal: AbortSignal
): Promise<BillingsOrderTaxiTaxi> => {
  const request: BillingsOrderTaxiTaxiRequest =
    billingsOrderTaxiTaxiRequestFactory.create(params);

  try {
    const response = await billingsApiService().getOrderTaxiTaxi(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiTaxi = billingsOrderTaxiTaxiFactory.create(response);

    return taxiTaxi;
  } catch (_error) {
    const error = _error as HttpError | BillingsOrderTaxiTaxiError;

    throw handleOrderTaxiTaxiError(error);
  }
};

const handleTransferredOrderStatusCountError = (
  error: HttpError | TransferredOrderStatusCountError
): BillingsOrderTaxiTaxiError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getTransferredOrderStatusCount = async (
  abortSignal: AbortSignal
): Promise<TransferredOrderStatusCount> => {
  try {
    const response = await billingsApiService().getTransferredOrderStatusCount(
      abortSignal
    );
    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return transferredOrderStatusCountFactory.create(response.data);
  } catch (_error) {
    const error = _error as HttpError | TransferredOrderStatusCountError;

    throw handleTransferredOrderStatusCountError(error);
  }
};

const handleOrderAddressesError = (
  error: HttpError | BillingsOrderAddressesError
): BillingsOrderAddressesError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getOrderAddresses = async (
  params: BillingsOrderAddressesLoadParams,
  abortSignal: AbortSignal
) => {
  const request: BillingsOrderAddressesRequest =
    billingsOrderAddressesRequestFactory.create(params);

  try {
    const response = await billingsApiService().getOrderAddresses(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const addresses = billingsOrderAddressesFactory.create(response);

    return addresses;
  } catch (_error) {
    const error = _error as HttpError | BillingsOrderAddressesError;

    throw handleOrderAddressesError(error);
  }
};

const billingService = {
  getOrderForwardCargoTaxi,
  getOrderForwardTaxiDriver,
  getOrderForwardTaxiTaxi,
  getPlanEntryCargoTaxi,
  getPlanEntryTaxiTaxi,
  getOrderTaxiDriver,
  getOrderTaxiTaxi,
  getTransferredOrderStatusCount,
  getOrderAddresses,
};

export default billingService;
