import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useMemo, useState } from "react";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import ButtonComponent from "../button/button.component";
import PaginationSelectOption from "./pagination-select-option";
import paginationHelper from "./pagination.helper";
import SingleSelectComponent from "../form/select/single-select/single-select.component";

export type PaginationProps = {
  page: number;
  pageSize: number;
  totalResults: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  pageSizeOptions?: number[];
};

const PaginationComponent: FC<PaginationProps> = (props) => {
  const minimumPageItemIndex = (props.page - 1) * props.pageSize + 1;
  const maximumPageItemIndex =
    (props.page - 1) * props.pageSize + props.pageSize > props.totalResults
      ? props.totalResults
      : (props.page - 1) * props.pageSize + props.pageSize;

  const translations =
    appTranslationsHelper.getComponentTranslations().pagination;

  const [selectedPageSizeSelectOption, setSelectedPageSizeSelectOption] =
    useState<PaginationSelectOption>(() =>
      paginationHelper.getSelectedOption(props.pageSize)
    );

  const pageSizeSelectOptions: PaginationSelectOption[] = useMemo(
    () => paginationHelper.createSelectOptions(props.pageSizeOptions),
    []
  );

  const onPrevPageButtonClick = () => {
    const newPage = props.page - 1;

    if (newPage < 1) {
      return;
    }

    props.onPageChange(newPage);
  };

  const onNextPageButtonClick = () => {
    const newPage = props.page + 1;

    const maxPage = Math.ceil(props.totalResults / props.pageSize);
    if (newPage > maxPage) {
      return;
    }

    props.onPageChange(newPage);
  };

  if (!props.totalResults) {
    return null;
  }

  const showingResultsLabel = translations.showingResultsLabel
    .replace("#{from}", String(minimumPageItemIndex))
    .replace("#{to}", String(maximumPageItemIndex))
    .replace("#{totalResults}", String(props.totalResults));

  return (
    <div className="pagination">
      <div className="pagination__details">
        {translations.numberOfRows}
        <SingleSelectComponent
          classNames={{ root: "pagination__select" }}
          value={selectedPageSizeSelectOption}
          onChange={(option) => {
            setSelectedPageSizeSelectOption(option!);
            props.onPageSizeChange(option?.value);
          }}
          options={pageSizeSelectOptions}
        />
      </div>
      <div className="pagination__page_select_details">
        <ButtonComponent onClick={onPrevPageButtonClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </ButtonComponent>
        <div>{showingResultsLabel}</div>
        <ButtonComponent onClick={onNextPageButtonClick}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ButtonComponent>
      </div>
    </div>
  );
};

export default PaginationComponent;
