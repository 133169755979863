import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DealerListingUserPermissionDefinitionOption =
  | "hasAccessToDeleteUser"
  | "hasVisibleAddDealerButton";

export type DealerListingUserPermissionsDefinition =
  UserPermissionsDefinition<DealerListingUserPermissionDefinitionOption>;

export type DealerListingUserPermissions =
  UserPermissions<DealerListingUserPermissionDefinitionOption>;

const dealerListingUserPermissionDefinition: DealerListingUserPermissionsDefinition =
  {
    hasAccessToDeleteUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasVisibleAddDealerButton: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  };

export default dealerListingUserPermissionDefinition;
