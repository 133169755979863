import SearchRoadRoutingRequest, {
  SearchRoadRoutingRequestWaypointCoordinate,
} from "../../../utils/search-road-route/search-road-routing.request";
import MileageRoadRouteLoadParams, {
  MileageRoadRouteWaypoints,
} from "./mileage-road-route-load-params";

const changeWaypointType = (
  type: MileageRoadRouteWaypoints
): SearchRoadRoutingRequestWaypointCoordinate => {
  return {
    latitude: type.lat,
    longitude: type.lon,
  };
};

const create = (
  loadParams: MileageRoadRouteLoadParams
): SearchRoadRoutingRequest => {
  return {
    waypointCoordinates: loadParams.waypoints.map(changeWaypointType),
  };
};

const mileageRoadRouteRequestFactory = {
  create,
};

export default mileageRoadRouteRequestFactory;
