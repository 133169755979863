import HttpResponse from "../../../../../utils/http/http.response";

export enum CargoComapnyAddressListResponseItemAddressType {
  HOTEL = "HOTEL",
  STATION = "STATION",
  MEETING_POINT = "MEETING_POINT",
}

export type CargoCompanyAddressListResponseItem = {
  id: string;
  createdAt: Date;
  createdBy: string;
  lat: number;
  lon: number;
  displayName: string;
  type: CargoComapnyAddressListResponseItemAddressType;
  modifiedAt?: Date;
  modifiedBy?: string;
  country?: string;
  town?: string;
  zipCode?: string;
  street?: string;
  houseNo?: string;
  apartment?: string;
  description?: string;
};

type CargoCompanyAddressListResponse = HttpResponse<
  CargoCompanyAddressListResponseItem[]
>;

export default CargoCompanyAddressListResponse;
