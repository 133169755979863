import DriverContractListingByTaxiListingItem from "../types/driver-contract-listing-by-taxi-listing-item";
import DriverContractListingByTaxiSortKey from "../types/driver-contract-listing-by-taxi-sort-key";

const sortListingItemsByBillingModelAsc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemA.billingModel.localeCompare(itemB.billingModel)
  );
};

const sortListingItemsByBillingModelDesc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemB.billingModel.localeCompare(itemA.billingModel)
  );
};

const sortListingItemsByDistanceRateAsc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.distanceRate - itemB.distanceRate
  );
};

const sortListingItemsByDistanceRateDesc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.distanceRate - itemA.distanceRate
  );
};

const sortListingItemsByHaltingTimeGracePeriodAsc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemA.freeStoppingPeriodMinutes - itemB.freeStoppingPeriodMinutes
  );
};

const sortListingItemsByHaltingTimeGracePeriodDesc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemB.freeStoppingPeriodMinutes - itemA.freeStoppingPeriodMinutes
  );
};

const sortListingItemsByHaltingTimeRateAsc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.stoppingRate - itemB.stoppingRate
  );
};

const sortListingItemsByHaltingTimeRateDesc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.stoppingRate - itemA.stoppingRate
  );
};

const sortListingItemsByPeriodOfValidityFromAsc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemB.periodOfValidity.from.getTime() -
      itemA.periodOfValidity.from.getTime()
  );
};

const sortListingItemsByPeriodOfValidityFromDesc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemA.periodOfValidity.from.getTime() -
      itemB.periodOfValidity.from.getTime()
  );
};

const sortListingItemsByPeriodOfValidityToAsc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemB.periodOfValidity.to.getTime() - itemA.periodOfValidity.to.getTime()
  );
};

const sortListingItemsByPeriodOfValidityToDesc = (
  listingItems: DriverContractListingByTaxiListingItem[]
): DriverContractListingByTaxiListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      itemA.periodOfValidity.to.getTime() - itemB.periodOfValidity.to.getTime()
  );
};

const sortListingItems = (
  listingItems: DriverContractListingByTaxiListingItem[],
  sortKey: DriverContractListingByTaxiSortKey | null
): DriverContractListingByTaxiListingItem[] => {
  switch (sortKey) {
    case DriverContractListingByTaxiSortKey.BILLING_MODEL_ASC:
      return sortListingItemsByBillingModelAsc(listingItems);
    case DriverContractListingByTaxiSortKey.BILLING_MODEL_DESC:
      return sortListingItemsByBillingModelDesc(listingItems);
    case DriverContractListingByTaxiSortKey.DISTANCE_RATE_ASC:
      return sortListingItemsByDistanceRateAsc(listingItems);
    case DriverContractListingByTaxiSortKey.DISTANCE_RATE_DESC:
      return sortListingItemsByDistanceRateDesc(listingItems);
    case DriverContractListingByTaxiSortKey.FREE_STOPPING_PERIOD_ASC:
      return sortListingItemsByHaltingTimeGracePeriodAsc(listingItems);
    case DriverContractListingByTaxiSortKey.FREE_STOPPING_PERIOD_DESC:
      return sortListingItemsByHaltingTimeGracePeriodDesc(listingItems);
    case DriverContractListingByTaxiSortKey.STOPPING_RATE_ASC:
      return sortListingItemsByHaltingTimeRateAsc(listingItems);
    case DriverContractListingByTaxiSortKey.STOPPING_RATE_DESC:
      return sortListingItemsByHaltingTimeRateDesc(listingItems);
    case DriverContractListingByTaxiSortKey.PERIOD_OF_VALIDITY_FROM_ASC:
      return sortListingItemsByPeriodOfValidityFromAsc(listingItems);
    case DriverContractListingByTaxiSortKey.PERIOD_OF_VALIDITY_FROM_DESC:
      return sortListingItemsByPeriodOfValidityFromDesc(listingItems);
    case DriverContractListingByTaxiSortKey.PERIOD_OF_VALIDITY_TO_ASC:
      return sortListingItemsByPeriodOfValidityToAsc(listingItems);
    case DriverContractListingByTaxiSortKey.PERIOD_OF_VALIDITY_TO_DESC:
      return sortListingItemsByPeriodOfValidityToDesc(listingItems);
    default:
      return listingItems;
  }
};

const driverContractListingByTaxiSortService = {
  sortListingItems,
};

export default driverContractListingByTaxiSortService;
