import formValidationService from "../../../../common/utils/validation/form-validation.service";
import BillingAddressAddFormValidationResults from "./types/billing-address-add-form-validation-results";

const getDefaultValidationResults =
  (): BillingAddressAddFormValidationResults => {
    return {
      addressType: formValidationService.defaultValidationResult,
      street: formValidationService.defaultValidationResult,
      houseNumber: formValidationService.defaultValidationResult,
      apartmentNumber: formValidationService.defaultValidationResult,
      zipCode: formValidationService.defaultValidationResult,
      country: formValidationService.defaultValidationResult,
      town: formValidationService.defaultValidationResult,
      description: formValidationService.defaultValidationResult,
      map: formValidationService.defaultValidationResult,
    };
  };

const billingAddressAddFormHelper = {
  getDefaultValidationResults,
};

export default billingAddressAddFormHelper;
