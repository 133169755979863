import useAsyncData from "../../../hooks/use-async-data";
import mileageService from "../mileage.service";
import MileageList from "./mileage-list";
import MileageListLoadParams from "./mileage-list-load-params";

const useMileageList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<MileageList>({ data: [], totalCount: 0 });

  const load = async (
    params: MileageListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const mileageList: MileageList = await mileageService.getList(
        params,
        signal
      );

      setData(mileageList);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useMileageList;
