import Joi from "joi";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import TaxiTaxiContractAddFormData from "./common/types/taxi-taxi-contract-add-form-data";
import taxiTaxiContractValidationService from "../common/taxi-taxi-contract-validation.service";

const validateBillingModel = (
  value: TaxiTaxiContractAddFormData["billingModel"]
): FormValidationResult => {
  const validationSchema = Joi.string().required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRate = (
  value: TaxiTaxiContractAddFormData["distanceRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .greater(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateFreeStoppingPeriodMinutes = (
  value: TaxiTaxiContractAddFormData["freeStoppingPeriodMinutes"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(60).required();

  return formValidationService.validate(value, validationSchema);
};

const validatePeriodOfValidity = (
  value: TaxiTaxiContractAddFormData["periodOfValidity"]
): FormValidationResult => {
  const validationSchema = Joi.object<
    TaxiTaxiContractAddFormData["periodOfValidity"]
  >()
    .keys({
      from: Joi.date().min("1970-01-01").required(),
      to: Joi.date().required(),
    })
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateStoppingRate = (
  value: TaxiTaxiContractAddFormData["stoppingRate"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).required();

  return formValidationService.validate(value, validationSchema);
};

const validateTargetTaxiCorporation = (
  value: TaxiTaxiContractAddFormData["targetTaxiCorporationUuid"]
): FormValidationResult => {
  const validationSchema = Joi.string().required();

  return formValidationService.validate(value, validationSchema);
};

const taxiTaxiContractAddFormValidationService = {
  validateBillingModel,
  validateDistanceRate,
  validateFreeStoppingPeriodMinutes,
  validatePeriodOfValidity,
  validateStoppingRate,
  validateTargetTaxiCorporation,
  validateActivity: taxiTaxiContractValidationService.validateActivity,
  validateDisplayName: taxiTaxiContractValidationService.validateDisplayName,
  validatePriority: taxiTaxiContractValidationService.validatePriority,
};

export default taxiTaxiContractAddFormValidationService;
