import { round } from "lodash";
import dateService from "../../../../../../../../common/utils/date/date.service";
import uuidService from "../../../../../../../../common/utils/uuid/uuid.service";
import MileageAddSummaryRouteConnection from "../types/mileage-add-summary-route-connection";
import MileageAddSummaryRouteConnectionLineType from "../types/mileage-add-summary-route-connection-line-type";
import MileageAddSummaryRouteWaypoint from "../types/mileage-add-summary-route-waypoint";

const getPlaceLabel = (place: MileageAddSummaryRouteWaypoint["place"]) => {
  if (place === null) {
    return `----`;
  }

  return place.displayName;
};

const getPlaceTitle = (place: MileageAddSummaryRouteWaypoint["place"]) => {
  if (place === null) {
    return undefined;
  }

  return place.displayName;
};

const getDistanceLabel = (
  distance: MileageAddSummaryRouteWaypoint["distance"]
): string => {
  if (distance === null) {
    return `0 KM`;
  }

  const distanceAsKm = round(distance / 1000, 0);

  return `${distanceAsKm} KM`;
};

const getTimeLabel = (time: MileageAddSummaryRouteWaypoint["time"]) => {
  if (time === null || !dateService.checkIsValidDate(time)) {
    return `---`;
  }

  return dateService.formatDateTime(time);
};

const generateWaypointDotClassname = (
  waypoint: MileageAddSummaryRouteWaypoint
) => {
  return `dot-uuid-${waypoint.uuid ?? uuidService.generate()}`;
};

const getDefinitionOfLineConnectionsBetweenWaypoints = (
  waypoints: MileageAddSummaryRouteWaypoint[]
): MileageAddSummaryRouteConnection[] => {
  let connectionLineDefinitions: MileageAddSummaryRouteConnection[] = [];

  waypoints.forEach((currentWaypoint, index) => {
    const nextWaypoint = waypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const lineType = MileageAddSummaryRouteConnectionLineType.SOLID;

    const newConnectionLineDefinition: MileageAddSummaryRouteConnection = {
      itemFromClassname: generateWaypointDotClassname(currentWaypoint),
      itemToClassname: generateWaypointDotClassname(nextWaypoint),
      lineType,
    };

    connectionLineDefinitions = [
      ...connectionLineDefinitions,
      newConnectionLineDefinition,
    ];
  });

  return connectionLineDefinitions;
};

const mileageAddSummaryRouteHelper = {
  getPlaceLabel,
  getPlaceTitle,
  getDistanceLabel,
  getTimeLabel,
  getDefinitionOfLineConnectionsBetweenWaypoints,
  generateWaypointDotClassname,
};

export default mileageAddSummaryRouteHelper;
