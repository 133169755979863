import RouteFinishedListLoadParams, {
  RouteFinishedListLoadParamsOrder,
} from "../../../../../common/services/route/finished-list/route-finished-list-load-params";
import RouteFinishedListingFilter, {
  RouteFinishedListingCargoOrderHumanIdFilter,
  RouteFinishedListingDriverFilter,
  RouteFinishedListingHumanIdFilter,
} from "../types/route-finished-listing-filter";
import RouteFinishedListingFilterType from "../types/route-finished-listing-filter-type";
import RouteFinishedListingSortKey from "../types/route-finished-listing-sort-key";

const createOrder = (
  sortKey: RouteFinishedListingSortKey | null
): RouteFinishedListLoadParamsOrder | undefined => {
  const definition: Record<
    RouteFinishedListingSortKey,
    RouteFinishedListLoadParamsOrder
  > = {
    [RouteFinishedListingSortKey.DRIVER_NAME_ASC]:
      RouteFinishedListLoadParamsOrder.DRIVER_ASC,
    [RouteFinishedListingSortKey.DRIVER_NAME_DESC]:
      RouteFinishedListLoadParamsOrder.DRIVER_DESC,
    [RouteFinishedListingSortKey.HUMAN_ID_ASC]:
      RouteFinishedListLoadParamsOrder.HUMAN_ID_ASC,
    [RouteFinishedListingSortKey.HUMAN_ID_DESC]:
      RouteFinishedListLoadParamsOrder.HUMAN_ID_DESC,
    [RouteFinishedListingSortKey.TAXI_CORPORATION_ASC]:
      RouteFinishedListLoadParamsOrder.TAXI_CORPORATION_ASC,
    [RouteFinishedListingSortKey.TAXI_CORPORATION_DESC]:
      RouteFinishedListLoadParamsOrder.TAXI_CORPORATION_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: RouteFinishedListingFilter[],
  sortKey: RouteFinishedListingSortKey | null
): RouteFinishedListLoadParams => {
  return {
    page,
    pageSize,
    order: createOrder(sortKey),
    driverName: filters.find(
      (filter) => filter.type === RouteFinishedListingFilterType.DRIVER
    )?.value as RouteFinishedListingDriverFilter["value"] | undefined,
    involvedCargoLabels: filters.find(
      (filter) => filter.type === RouteFinishedListingFilterType.PASSENGER
    )?.value as RouteFinishedListingDriverFilter["value"] | undefined,
    planEntryNodes: filters.find(
      (filter) => filter.type === RouteFinishedListingFilterType.ROUTE_ADDRESS
    )?.value as RouteFinishedListingDriverFilter["value"] | undefined,
    humanId: filters.find(
      (filter) => filter.type === RouteFinishedListingFilterType.HUMAN_ID
    )?.value as RouteFinishedListingHumanIdFilter["value"] | undefined,
    cargoOrderHumanId: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingFilterType.CARGO_ORDER_HUMAN_ID
    )?.value as
      | RouteFinishedListingCargoOrderHumanIdFilter["value"]
      | undefined,
    taxiName: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingFilterType.TAXI_CORPORATION
    )?.value as RouteFinishedListingDriverFilter["value"] | undefined,
  };
};

const RouteFinishedListingLoadParamsFactory = {
  create,
};

export default RouteFinishedListingLoadParamsFactory;
