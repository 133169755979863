import OperatorList from "./list/operator-list";
import OperatorListError from "./list/operator-list-error";
import OperatorListLoadParams from "./list/operator-list-load-params";
import operatorListFactory from "./list/operator-list.factory";
import operatorListRequestFactory from "./list/operator-list-request.factory";
import OperatorListRequest from "../../api/raily/operator/list/operator-list.request";
import operatorApiService from "../../api/raily/operator/operator-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import OperatorAddParams from "./add/operator-add-params";
import OperatorAddRequest from "../../api/raily/operator/add/operator-add-request";
import operatorAddRequestFactory from "./add/operator-add-request.factory";
import OperatorAddError from "./add/operator-add-error";

const handleAddError = (
  error: HttpError | OperatorAddError
): OperatorAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: OperatorAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: OperatorAddRequest = operatorAddRequestFactory.create(params);

  try {
    const response = await operatorApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | OperatorAddError;

    throw handleAddError(error);
  }
};

const handleListError = (
  error: HttpError | OperatorListError
): OperatorListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: OperatorListLoadParams,
  abortSignal: AbortSignal
): Promise<OperatorList> => {
  const request: OperatorListRequest =
    operatorListRequestFactory.create(params);

  try {
    const response = await operatorApiService().getList(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return operatorListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | OperatorListError;

    throw handleListError(error);
  }
};

const operatorService = {
  add,
  getList,
};

export default operatorService;
