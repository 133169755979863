import CargoCompanyDispatchListResponse, {
  CargoCompanyDispatchListResponseItem,
} from "../../../../api/raily/cargo-company/dispatch/list/cargo-company-dispatch-list.response";
import CargoCompanyDispatchList, {
  CargoCompanyDispatchListItem,
} from "./cargo-company-dispatch-list";

const createDataItem = (
  responseDataItem: CargoCompanyDispatchListResponseItem
): CargoCompanyDispatchListItem => {
  return {
    uuid: responseDataItem.id,
    address: responseDataItem.addressString,
    creationDate: responseDataItem.createdAt,
    displayName: responseDataItem.displayName,
    name: responseDataItem.dispatchName,
  };
};

const createData = (
  responseData: CargoCompanyDispatchListResponseItem[]
): CargoCompanyDispatchListItem[] => responseData.map(createDataItem);

const create = (
  response: CargoCompanyDispatchListResponse
): CargoCompanyDispatchList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const cargoCompanyDispatchListFactory = { create };

export default cargoCompanyDispatchListFactory;
