import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import ButtonComponent from "../../../../common/components/button/button.component";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import TaxiOfficerAddByRailyComponent from "./by-raily/taxi-officer-add-by-raily.component";
import useTaxiOfficerAddUserPermissions from "./common/user-permissions/use-taxi-officer-add-user-permissions";
import TaxiOfficerAddViewMode from "./common/taxi-officer-add-view-mode";
import TaxiOfficerAddByTaxiComponent from "./by-taxi/taxi-officer-add-by-taxi.component";

type TaxiOfficerAddProps = {};

const TaxiOfficerAddComponent: FC<TaxiOfficerAddProps> = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userTaxiOfficerAdd);

  const userPermissions = useTaxiOfficerAddUserPermissions();
  const translations = userTranslationsHelper.getTaxiOfficerAddTranslations();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getTaxiOfficerAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<TaxiOfficerAddViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(TaxiOfficerAddViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(TaxiOfficerAddViewMode.TAXI)}
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: TaxiOfficerAddViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: TaxiOfficerAddViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToTaxiButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: TaxiOfficerAddViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: TaxiOfficerAddViewMode.RAILY,
        component: (
          <TaxiOfficerAddByRailyComponent
            changeViewButtons={getViewChangeButtons(
              TaxiOfficerAddViewMode.RAILY
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: TaxiOfficerAddViewMode.TAXI,
        component: (
          <TaxiOfficerAddByTaxiComponent
            changeViewButtons={getViewChangeButtons(
              TaxiOfficerAddViewMode.TAXI
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default TaxiOfficerAddComponent;
