import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import driverEditUserPermissionDefinition, {
  DriverEditUserPermissions,
} from "./driver-edit-user-permission";

const getPermissions = (userRoles: UserRole[]): DriverEditUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    driverEditUserPermissionDefinition
  );
};

const driverEditUserPermissionsHelper = {
  getPermissions,
};

export default driverEditUserPermissionsHelper;
