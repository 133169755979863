export enum CargoOrderFinishedListReportPublicStatus {
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
  PUBLIC = "PUBLIC",
}

type CargoOrderFinishedListReport = {
  data: Blob;
  filename: string;
};

export default CargoOrderFinishedListReport;
