import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import UserDeleteUserData from "./user-delete-user-data";

const getUserFullName = (user: UserDeleteUserData) => {
  return `${user.firstName} ${user.lastName}`;
};

const getContentText = (user: UserDeleteUserData): string => {
  const translations = userTranslationsHelper.getUserDeleteTranslations();

  const fullName = getUserFullName(user);

  return translations.contentMessageTemplateLabel.replace(
    "#{userFullName}",
    fullName
  );
};

const getSuccessNotificationLabel = (user: UserDeleteUserData): string => {
  const translations = userTranslationsHelper.getUserDeleteTranslations();

  const fullName = getUserFullName(user);

  return translations.successNotificationTemplateLabel.replace(
    "#{userFullName}",
    fullName
  );
};

const getFailureNotificationLabel = (user: UserDeleteUserData): string => {
  const translations = userTranslationsHelper.getUserDeleteTranslations();

  const fullName = getUserFullName(user);

  return translations.failureNotificationTemplateLabel.replace(
    "#{userFullName}",
    fullName
  );
};

const getDriverHasCandidatureFailureNotificationLabel = (
  user: UserDeleteUserData
): string => {
  const translations = userTranslationsHelper.getUserDeleteTranslations();

  const fullName = getUserFullName(user);

  return translations.driverHasCandidatureFailureNotificationLabel.replace(
    "#{userFullName}",
    fullName
  );
};

const getDriverHasActivePlanFailureNotificationLabel = (
  user: UserDeleteUserData
): string => {
  const translations = userTranslationsHelper.getUserDeleteTranslations();

  const fullName = getUserFullName(user);

  return translations.driverHasAtivePlanFailureNotificationLabel.replace(
    "#{userFullName}",
    fullName
  );
};

const userDeleteHelper = {
  getContentText,
  getSuccessNotificationLabel,
  getFailureNotificationLabel,
  getDriverHasCandidatureFailureNotificationLabel,
  getDriverHasActivePlanFailureNotificationLabel,
};

export default userDeleteHelper;
