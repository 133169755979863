import paginationService from "../../../../../../common/utils/pagination/pagination.service";
import TaxiUnsettledTransferedOrderListingRequest, {
  TaxiUnsettledTransferedOrderListingRequestOrder,
} from "../api/taxi-unsettled-transfered-order-listing-request";
import TaxiUnsettledTransferedOrderListingFilter, {
  TaxiUnsettledTransferedOrderListingProducerTaxiFilter,
  TaxiUnsettledTransferedOrderListingDriverFilter,
  TaxiUnsettledTransferedOrderListingExternalOrderIdFilter,
  TaxiUnsettledTransferedOrderListingInternalOrderIdFilter,
  TaxiUnsettledTransferedOrderListingPassengerFilter,
  TaxiUnsettledTransferedOrderListingRouteAddressFilter,
  TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter,
  TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter,
  TaxiUnsettledTransferedOrderListingStartDateFilter,
  TaxiUnsettledTransferedOrderListingConsumerTaxiFilter,
} from "../types/taxi-unsettled-transfered-order-listing-filter";
import TaxiUnsettledTransferedOrderListingFilterType from "../types/taxi-unsettled-transfered-order-listing-filter-type";
import TaxiUnsettledTransferedOrderListingSortKey from "../types/taxi-unsettled-transfered-order-listing-sort-key";

const createRequestOrder = (
  sortKey: TaxiUnsettledTransferedOrderListingSortKey | null
): TaxiUnsettledTransferedOrderListingRequest["order"] => {
  const options: {
    sortKey: TaxiUnsettledTransferedOrderListingSortKey;
    requestOrder: TaxiUnsettledTransferedOrderListingRequestOrder;
  }[] = [
    {
      requestOrder:
        TaxiUnsettledTransferedOrderListingRequestOrder.CONSUMER_ASC,
      sortKey: TaxiUnsettledTransferedOrderListingSortKey.CONSUMER_ASC,
    },
    {
      requestOrder:
        TaxiUnsettledTransferedOrderListingRequestOrder.CONSUMER_DESC,
      sortKey: TaxiUnsettledTransferedOrderListingSortKey.CONSUMER_DESC,
    },
    {
      requestOrder:
        TaxiUnsettledTransferedOrderListingRequestOrder.PRODUCER_ASC,
      sortKey: TaxiUnsettledTransferedOrderListingSortKey.PRODUCER_ASC,
    },
    {
      requestOrder:
        TaxiUnsettledTransferedOrderListingRequestOrder.PRODUCER_DESC,
      sortKey: TaxiUnsettledTransferedOrderListingSortKey.PRODUCER_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: TaxiUnsettledTransferedOrderListingFilter[],
  sortKey: TaxiUnsettledTransferedOrderListingSortKey | null
): TaxiUnsettledTransferedOrderListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    order: createRequestOrder(sortKey),
    address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledTransferedOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | TaxiUnsettledTransferedOrderListingRouteAddressFilter["value"]
      | undefined,
    limit: pageSize,
    producer: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledTransferedOrderListingFilterType.PRODUCER_TAXI
    )?.value as
      | TaxiUnsettledTransferedOrderListingProducerTaxiFilter["value"]
      | undefined,
    consumer: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledTransferedOrderListingFilterType.CONSUMER_TAXI
    )?.value as
      | TaxiUnsettledTransferedOrderListingConsumerTaxiFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledTransferedOrderListingFilterType.DRIVER
    )?.value as
      | TaxiUnsettledTransferedOrderListingDriverFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledTransferedOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledTransferedOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledTransferedOrderListingExternalOrderIdFilter["value"]
      | undefined,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledTransferedOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledTransferedOrderListingInternalOrderIdFilter["value"]
      | undefined,
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledTransferedOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    offset,
    passenger: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledTransferedOrderListingFilterType.PASSENGER
    )?.value as
      | TaxiUnsettledTransferedOrderListingPassengerFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledTransferedOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.START_DATE
      )?.value as
        | TaxiUnsettledTransferedOrderListingStartDateFilter["value"]
        | undefined
    )?.from?.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.START_DATE
      )?.value as
        | TaxiUnsettledTransferedOrderListingStartDateFilter["value"]
        | undefined
    )?.to?.toJSON(),
  };
};

const taxiUnsettledTransferedOrderListingRequestFactory = {
  createRequest,
};

export default taxiUnsettledTransferedOrderListingRequestFactory;
