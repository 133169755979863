import MapConfig from "./types/map-config";
import MapMarker from "./types/map-marker";

const getDefaultConfig = (): MapConfig => {
  const config: MapConfig = {
    defaultCenterLocation: {
      lat: 52.06,
      lng: 19.25,
    },
    defaultZoom: 5,
    defaultRouteColor: "#df4f5d",
  };

  return config;
};

const getMarkersWithoutDuplicates = (
  markers: MapMarker[] = []
): MapMarker[] => {
  return markers.filter(
    (item, index) =>
      markers.findIndex(
        (marker) =>
          marker.coordinate.latitude === item.coordinate.latitude &&
          marker.coordinate.longitude === item.coordinate.longitude
      ) === index
  );
};

const mapHelper = {
  getDefaultConfig,
  getMarkersWithoutDuplicates,
};

export default mapHelper;
