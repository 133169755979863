import { FC, ReactNode, RefObject, useRef } from "react";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import Select, {
  ActionMeta,
  GroupBase,
  MenuPlacement,
  MenuPosition,
  MultiValue,
  SelectInstance,
  SingleValue,
} from "react-select";
import SelectOption from "../common/option/select-option";
import SelectOptionComponent from "../common/option/select-option.component";
import selectHelper from "../common/select.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import ComponentClassnames from "../../../../types/component-classnames";

export type MultiSelectComponentProps = {
  classNames?: ComponentClassnames;
  options: SelectOption[];
  value: SelectOption[];
  placeholder?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  autoFocus?: boolean;
  defaultValue?: SelectOption[];
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  menuPortalTarget?: HTMLElement | null | undefined;
  menuPlacement?: MenuPlacement;
  inputValue?: string;
  idForTesting?: string;
  maxMenuHeight?: number;
  menuPosition?: MenuPosition | undefined;
  onChange: (value: SelectOption[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onInputChange?: (value: string) => void;
  noOptionsMessage?: (inputValue: string) => string;
  filterFunction?: (
    option: FilterOptionOption<SelectOption<any>>,
    inputValue: string
  ) => boolean;
};

const MultiSelectComponent: FC<MultiSelectComponentProps> = (props) => {
  const isPositionFixed = props.menuPosition === "fixed";
  const selectComponentTranslations =
    appTranslationsHelper.getComponentTranslations().select;
  const selectClasses = selectHelper.getSelectClasses(
    props.classNames,
    props.hasError
  );

  const onChange = (
    newValue: MultiValue<SelectOption<any>> | SingleValue<SelectOption<any>>,
    _actionMeta: ActionMeta<SelectOption<any>>
  ) => {
    const isValueExists = !(newValue === undefined || newValue === null);
    const value = isValueExists ? (newValue as SelectOption[]) : [];

    if ((newValue as SelectOption)?.onClick) {
      (newValue as SelectOption).onClick!();
      selectRef.current?.menuListRef?.blur();
    }
    props.onChange(value);
  };

  const selectRef: RefObject<
    SelectInstance<SelectOption, boolean, GroupBase<SelectOption>>
  > = useRef(null);

  return (
    <Select
      isMulti
      ref={selectRef}
      value={props.value}
      defaultValue={props.defaultValue}
      options={props.options}
      backspaceRemovesValue
      autoFocus={props.autoFocus}
      classNamePrefix="select"
      className={selectClasses}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      menuPlacement={props.menuPlacement ?? "auto"}
      menuPosition={props.menuPosition || "absolute"}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      maxMenuHeight={props.maxMenuHeight}
      menuPortalTarget={props.menuPortalTarget || document.body}
      isClearable={props.isClearable}
      isLoading={props.isLoading}
      inputId={props.idForTesting}
      loadingMessage={() => selectComponentTranslations.loadingMessage}
      inputValue={props.inputValue}
      components={{
        Option: (optionProps) => (
          <SelectOptionComponent {...optionProps} selectRef={selectRef} />
        ),
      }}
      closeMenuOnScroll={() => !!isPositionFixed}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onChange={onChange}
      onInputChange={props.onInputChange}
      filterOption={props.filterFunction ?? selectHelper.filterByDefault}
      noOptionsMessage={(input) => {
        return selectHelper.getNoOptionsMessage(
          input.inputValue,
          props.noOptionsMessage!
        );
      }}
      styles={{
        menuPortal: (base) => ({
          ...base,
        }),
      }}
    />
  );
};

export default MultiSelectComponent;
