import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import driverAddUserPermissionDefinition, {
  DriverAddUserPermissions,
} from "./driver-add-user-permission";

const getPermissions = (userRoles: UserRole[]): DriverAddUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    driverAddUserPermissionDefinition
  );

  return permissions;
};

const driverAddUserPermissionsHelper = {
  getPermissions,
};

export default driverAddUserPermissionsHelper;
