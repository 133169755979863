import CargoOfficerAddParams from "../../../../../../common/services/cargo-officer/add/cargo-officer-add-params";
import User from "../../../../../../common/types/user";
import CargoOfficerAddByCargoFormData from "./types/cargo-officer-add-by-cargo-form-data";

const create = (
  formData: CargoOfficerAddByCargoFormData,
  user: User
): CargoOfficerAddParams => {
  const cargoCompanyUuid = user.aspects.cargoOfficer?.cargoCompanyUuid!;

  return {
    alternativePhoneNumber: formData.alternativePhoneNumber,
    cargoCompanyUuid,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    password: formData.password,
    phoneNumber: formData.phoneNumber,
    username: formData.username,
  };
};

const cargoOfficerAddByCargoParamsFactory = {
  create,
};

export default cargoOfficerAddByCargoParamsFactory;
