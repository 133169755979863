import { TaxiCargoContractListingResponseDataItem } from "../api/types/taxi-cargo-contract-listing-response";
import TaxiCargoContractListingItem from "../types/taxi-cargo-contract-listing-item";

const createListingItem = (
  responseDataItem: TaxiCargoContractListingResponseDataItem
): TaxiCargoContractListingItem => {
  return {
    uuid: responseDataItem.id,
    billingModel: responseDataItem.model,
    cargoCompany: responseDataItem.cargo_company.company_name,
    discountPercentLimit: responseDataItem.discount_limit,
    distanceRate: responseDataItem.distance_rate,
    freeStoppingPeriodMinutes: responseDataItem.halting_time_after_minutes,
    isActive: !responseDataItem.disabled,
    isTollRoadsDuringOrderAllowed: responseDataItem.allow_charge_during_ride,
    isTollRoadsWhileApproachingAllowed:
      responseDataItem.allow_charge_while_approaching,
    isTollRoadsWhileReturningAllowed:
      responseDataItem.allow_charge_while_returning,
    isOrderPublishingAllowed: responseDataItem.publishing_allowed,
    stoppingRate: responseDataItem.halting_time_rate,
    validFrom: new Date(responseDataItem.valid_since),
    validTo: new Date(responseDataItem.valid_to),
  };
};

const createListingItems = (
  responseDataItems: TaxiCargoContractListingResponseDataItem[]
): TaxiCargoContractListingItem[] => {
  const results = responseDataItems.map(createListingItem);

  return results;
};

const taxiCargoContractListingFactory = { createListingItems };

export default taxiCargoContractListingFactory;
