import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type CargoOfficerAddUserPermissionOption =
  | "hasAccessToCargoView"
  | "hasAccessToRailyView";

export type CargoOfficerAddUserPermissionsDefinition =
  UserPermissionsDefinition<CargoOfficerAddUserPermissionOption>;

export type CargoOfficerAddUserPermissions =
  UserPermissions<CargoOfficerAddUserPermissionOption>;

const cargoOfficerAddUserPermissionDefinition: CargoOfficerAddUserPermissionsDefinition =
  {
    hasAccessToCargoView: [UserRole.CARGO_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default cargoOfficerAddUserPermissionDefinition;
