import { FC } from "react";
import cargoOrderSettlementListingFiltersBadgeListHelper from "./cargo-order-settlement-listing-filters-badge-list.helper";
import CargoOrderSettlementListingFilter from "../../types/cargo-order-settlement-listing-filter";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type CargoOrderSettlementListingFiltersListProps = {
  filters: CargoOrderSettlementListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const CargoOrderSettlementListingFiltersBadgeListComponent: FC<
  CargoOrderSettlementListingFiltersListProps
> = (props) => {
  const badges = cargoOrderSettlementListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default CargoOrderSettlementListingFiltersBadgeListComponent;
