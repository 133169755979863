import UserRole from "../../../../../common/types/user-role";
import UserPermissions from "../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../common/utils/user/permissions/user-permissions-definition";

export type OrderAddUserPermissionOption =
  | "hasAccessToCargoView"
  | "hasAccessToRailyView";

export type OrderAddUserPermissionsDefinition =
  UserPermissionsDefinition<OrderAddUserPermissionOption>;

export type OrderAddUserPermissions =
  UserPermissions<OrderAddUserPermissionOption>;

const orderAddUserPermissionDefinition: OrderAddUserPermissionsDefinition = {
  hasAccessToCargoView: [UserRole.DISPATCHER],
  hasAccessToRailyView: [UserRole.ADMIN, UserRole.OPERATOR],
};

export default orderAddUserPermissionDefinition;
