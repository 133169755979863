import TaxiTaxiContractAddRequest, {
  TaxiTaxiContractAddRequestBody,
} from "./taxi-taxi-contract-add.request";

const createBody = (
  request: TaxiTaxiContractAddRequest
): TaxiTaxiContractAddRequestBody => {
  return {
    consumerTaxiId: request.consumerTaxiId,
    disabled: request.disabled,
    displayName: request.displayName,
    distanceRate: request.distanceRate,
    haltingTimeAfterMinutes: request.haltingTimeAfterMinutes,
    haltingTimeRate: request.haltingTimeRate,
    isPartner: request.isPartner,
    model: request.model,
    priority: request.priority,
    producerTaxiId: request.producerTaxiId,
    validSince: request.validSince,
    validTo: request.validTo,
  };
};

const taxiTaxiContractAddRequestFactory = {
  createBody,
};

export default taxiTaxiContractAddRequestFactory;
