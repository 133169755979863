import orderListingTableRouteHelper from "./order-listing-table-route.helper";

const createRouteString = (addresses: (string | string[])[]): string => {
  const addressesWithFlattenedGroups = addresses.map((address) => {
    const isAddressGroup =
      orderListingTableRouteHelper.checkIsAddressGroup(address);

    if (!isAddressGroup) {
      return address;
    }

    const connectedAddresses = address.join(" | ");
    return `[ ${connectedAddresses} ]`;
  });

  return addressesWithFlattenedGroups.join(" > ");
};

const orderListingTableRouteFactory = {
  createRouteString,
};

export default orderListingTableRouteFactory;
