import { OrderOptimizerListItem } from "../../../../common/services/order-optimizer/list/order-optimizer-list";
import uuidService from "../../../../common/utils/uuid/uuid.service";
import OrderOptimizerListingItem from "./types/order-optimizer-listing-item";

const createListingItem = (
  listItem: OrderOptimizerListItem
): OrderOptimizerListingItem => {
  return {
    uuid: uuidService.generate(),
    orders: listItem.orders,
    emptyRides: listItem.emptyRides,
    relativeScore: listItem.relativeScore,
    score: listItem.score,
  };
};

const createListingItems = (
  listItems: OrderOptimizerListItem[]
): OrderOptimizerListingItem[] => {
  return listItems.map(createListingItem);
};

const orderOptimizerListingItemFactory = {
  createListingItems,
};

export default orderOptimizerListingItemFactory;
