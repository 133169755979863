export enum TaxiUnsettledOrderListingByRailyRequestOrder {
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
}

export enum TaxiUnsettledOrderListingByRailyRequestLackingBillingType {
  PUB_CARGO_2_RAILY = "PUB_CARGO_2_RAILY",
  PUB_RAILY_2_TAXI = "PUB_RAILY_2_TAXI",
  PRIV_TAXI_2_RAILY = "PRIV_TAXI_2_RAILY",
}

type TaxiUnsettledOrderListingByRailyRequest = {
  lacking_billing_type?: TaxiUnsettledOrderListingByRailyRequestLackingBillingType[];
  order?: TaxiUnsettledOrderListingByRailyRequestOrder;
  limit: number;
  offset?: number;
  start_time_since?: string;
  start_time_to?: string;
  human_id?: number;
  address?: string;
  start_address?: string;
  mid_address?: string;
  end_address?: string;
  passenger?: string;
  dispatch?: string;
  client?: string;
  external_id?: string;
  taxi?: string;
  driver?: string;
  is_cancelled?: boolean;
};

export default TaxiUnsettledOrderListingByRailyRequest;
