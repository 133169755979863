import CargoCompanyDispatchDetailsResponse from "../../../../api/raily/cargo-company/dispatch/details/cargo-company-dispatch-details.response";
import CargoCompanyDispatchDetails from "./cargo-company-dispatch-details";

const create = (
  response: CargoCompanyDispatchDetailsResponse
): CargoCompanyDispatchDetails => {
  return {
    uuid: response.data.id,
    address: response.data.addressString,
    creationDate: response.data.createdAt,
    displayName: response.data.displayName,
    name: response.data.dispatchName,
  };
};

const cargoCompanyDispatchDetailsFactory = { create };

export default cargoCompanyDispatchDetailsFactory;
