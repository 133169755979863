import { FC, useState } from "react";
import PassengerAddressAddComponent from "./add/passenger-address-add.component";
import passengerAddressHelper from "./passenger-address.helper";
import PassengerAddress from "../../types/passenger-address";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import TableRow from "../../../../../../common/components/table/types/table-row";
import TableDeleteButtonComponent from "../../../../../../common/components/table/button/delete/table-delete-button.component";
import Row from "../../../../../../common/components/grid/row";
import Column from "../../../../../../common/components/grid/column";
import TableComponent from "../../../../../../common/components/table/table.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import MapComponent from "../../../../../../common/components/map/map.component";

type PassengerFormDataAddressComponentProps = {
  addresses: PassengerAddress[];
  onAddressAdd: (address: PassengerAddress) => void;
  onAddressRemove: (index: number) => void;
};

const PassengerFormDataAddressComponent: FC<
  PassengerFormDataAddressComponentProps
> = (props) => {
  const translations =
    userTranslationsHelper.getPassengerAddressTranslations().form;

  const [isAddNewAddressModalOpen, setIsAddNewAddressModalOpen] =
    useState(false);

  const mapMarkers: MapMarker[] = props.addresses.map(
    (address: PassengerAddress) => {
      return {
        coordinate: {
          latitude: address.latitude,
          longitude: address.longitude,
        },
        title: passengerAddressHelper.getAddressLabel(address),
        tooltip: passengerAddressHelper.getAddressLabel(address),
      };
    }
  );

  const openAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(true);
  };

  const closeAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(false);
  };

  const addNewAddress = (address: PassengerAddress) => {
    props.onAddressAdd(address);
  };

  const removeAddress = (addressIndex: number) => {
    props.onAddressRemove(addressIndex);
  };

  const tableRows: TableRow[] = props.addresses.map(
    (address: PassengerAddress, index: number) => {
      return {
        id: String(index),
        value: {
          address: (
            <div className="passenger_address_table_row address">
              <div className="text_ellipsis">
                {passengerAddressHelper.getAddressLabel(address)}
              </div>
            </div>
          ),
          actions: (
            <TableDeleteButtonComponent
              onClick={() => {
                removeAddress(index);
              }}
            />
          ),
        },
      };
    }
  );

  return (
    <div className="passenger_address">
      <Row>
        <Column xl={4}>
          <TableComponent
            columns={[
              {
                accessor: "address",
                header: translations.addressTableHeader.addressName,
                title: translations.addressTableHeader.addressName,
                colSpan: 4,
              },
              {
                accessor: "actions",
                header: "Actions",
                title: "",
              },
            ]}
            rows={tableRows}
          />
          <div className="passenger_address__add_button_wrapper">
            <ButtonComponent
              onClick={openAddNewAddressModal}
              title={translations.addNewAddressButtonTitle}
              type="primary"
              idForTesting="user-data-open-add-new-address-modal-button"
            >
              {translations.addNewAddressButtonText}
            </ButtonComponent>
          </div>
        </Column>
        <Column xl={8}>
          <div className="passenger_address__map_wrapper">
            <MapComponent markers={mapMarkers} autoFit autoFitOnUpdate />
          </div>
        </Column>
      </Row>
      <PassengerAddressAddComponent
        isModalOpen={isAddNewAddressModalOpen}
        onCloseClick={closeAddNewAddressModal}
        onAddressAddSuccess={addNewAddress}
      />
    </div>
  );
};

export default PassengerFormDataAddressComponent;
