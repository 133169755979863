import CargoCompanyAddParams from "../../../../../../common/services/cargo-company/cargo-company/add/cargo-company-add-params";
import CargoCompanyAddFormData from "./types/cargo-company-add-form-data";

const create = (formData: CargoCompanyAddFormData): CargoCompanyAddParams => {
  return {
    name: formData.name,
    displayName: formData.displayName,
    taxNumber: formData.taxNumber,
    nationalCourtRegister: formData.nationalCourtRegister,
    companyId: formData.companyId,
    address: formData.address,
    contractMaintenancePolicy: formData.contractMaintenancePolicy!,
    notes: formData.notes,
  };
};

const cargoCompanyAddParamsFactory = {
  create,
};

export default cargoCompanyAddParamsFactory;
