import HttpResponse from "../../../../../utils/http/http.response";

export enum CargoOrderDetailsHandlingLogType {
  FORWARD = "FORWARD",
  FORWARD_CANCEL = "FORWARD_CANCEL",
  ACCEPTED_CPE_CANDIDATURE = "ACCEPTED_CPE_CANDIDATURE",
  ACCEPTED_CSO_CANDIDATURE = "ACCEPTED_CSO_CANDIDATURE",
  ACCEPTED_DSO_CANDIDATURE = "ACCEPTED_DSO_CANDIDATURE",
}

export type CargoOrderDetailsHandlingLogDSOCandidatureAcceptedResponseItem = {
  driver: { id: string; displayName: string };
  taxiCorporation: { id: string; displayName: string };
  createdAt: Date;
  createdBy: Date;
  logType: CargoOrderDetailsHandlingLogType.ACCEPTED_DSO_CANDIDATURE;
  orderId: string;
  author: { firstName: string; lastName: string };
  planEntryId: string;
  planEntryHumanId: number;
  involvedOrderIds: string[];
  involvedOrderHumansIds: number[];
};

export type CargoOrderDetailsHandlingLogCSOCandidatureAcceptedResponseItem = {
  driver: { id: string; displayName: string };
  taxiCorporation: { id: string; displayName: string };
  createdAt: Date;
  createdBy: Date;
  logType: CargoOrderDetailsHandlingLogType.ACCEPTED_CSO_CANDIDATURE;
  orderId: string;
  author: { firstName: string; lastName: string };
  planEntryId: string;
  planEntryHumanId: number;
  involvedOrderIds: string[];
  involvedOrderHumansIds: number[];
};

export type CargoOrderDetailsHandlingLogCPECandidatureAcceptedResponseItem = {
  driver: { id: string; displayName: string };
  taxiCorporation: { id: string; displayName: string };
  createdAt: Date;
  createdBy: Date;
  logType: CargoOrderDetailsHandlingLogType.ACCEPTED_CPE_CANDIDATURE;
  orderId: string;
  author: { firstName: string; lastName: string };
};

export type CargoOrderDetailsHandlingLogResponseItem = {
  createdAt: Date;
  createdBy: Date;
  logType: CargoOrderDetailsHandlingLogType.FORWARD;
  orderId: string;
  author: { firstName: string; lastName: string };
  producer: { id: string; displayName: string };
  consumer: { id: string; displayName: string };
  contract: { id: string; displayName: string };
};

export type CargoOrderDetailsHandlingLogCancelResponseItem = {
  createdAt: Date;
  createdBy: Date;
  logType: CargoOrderDetailsHandlingLogType.FORWARD_CANCEL;
  orderId: string;
  author: { firstName: string; lastName: string };
  producer: { id: string; displayName: string };
  consumer: { id: string; displayName: string };
};

export type CargoOrderDetailsHandlingLogResponseData =
  | CargoOrderDetailsHandlingLogResponseItem
  | CargoOrderDetailsHandlingLogCancelResponseItem
  | CargoOrderDetailsHandlingLogCPECandidatureAcceptedResponseItem
  | CargoOrderDetailsHandlingLogCSOCandidatureAcceptedResponseItem
  | CargoOrderDetailsHandlingLogDSOCandidatureAcceptedResponseItem;

type CargoOrderDetailsHandlingLogResponse = HttpResponse<
  CargoOrderDetailsHandlingLogResponseData[]
>;

export default CargoOrderDetailsHandlingLogResponse;
