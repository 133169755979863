import classNames from "classnames";
import { FC } from "react";
import TaxiUnsettledOrderListingItemStatus from "../../types/taxi-unsettled-order-listing-item-status";

type TaxiUnsettledOrderListingTableStatusProps = {
  status: TaxiUnsettledOrderListingItemStatus;
};

const TaxiUnsettledOrderListingTableStatusComponent: FC<
  TaxiUnsettledOrderListingTableStatusProps
> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case TaxiUnsettledOrderListingItemStatus.CANCELLED:
        return "cancelled";
      case TaxiUnsettledOrderListingItemStatus.FINISHED:
        return "finished";
    }
  };

  const statusClassname = getClassnameByStatus();

  const containerClassNames = classNames(
    "taxi_unsettled_order_listing_table_status",
    statusClassname
  );

  return <div className={containerClassNames}></div>;
};

export default TaxiUnsettledOrderListingTableStatusComponent;
