import CargoOrderDetailsHandlingLogRequest, {
  CargoOrderDetailsHandlingLogRequestQueryParams,
} from "./cargo-order-details-handling-log.request";

const createQueryParams = (
  data: CargoOrderDetailsHandlingLogRequest
): CargoOrderDetailsHandlingLogRequestQueryParams => {
  return {
    creationStart: data.creationStart || null,
    creationEnd: data.creationEnd || null,
  };
};

const cargoOrderDetailsHandlingLogRequestFactory = {
  createQueryParams,
};

export default cargoOrderDetailsHandlingLogRequestFactory;
