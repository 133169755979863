import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import CargoAddressListingItem from "../types/cargo-address-listing-item";
import CargoAddressListingSortKey from "../types/cargo-address-listing-sort-key";
import CargoAddressListingSortSelectOption from "../types/cargo-address-listing-sort-select-option";

//Move to higher order helper?
//---
function sortListingItemsDescByStringKey<T, K extends keyof T>(
  listingItems: T[],
  key: K
): T[] {
  return listingItems.sort((itemA, itemB) => 
    (itemB[key] as string).localeCompare(itemA[key] as string)
  );
}

function sortListingItemsAscByStringKey<T, K extends keyof T>(
  listingItems: T[],
  key: K
): T[] {
  return listingItems.sort((itemA, itemB) => 
    (itemA[key] as string).localeCompare(itemB[key] as string)
  );
}
//---

const sortListingItems = (
  listingItems: CargoAddressListingItem[],
  sortKey: CargoAddressListingSortKey | null
): CargoAddressListingItem[] => {
  switch (sortKey) {
    case CargoAddressListingSortKey.ADDRESS_DESCRIPTION_ASC:
      return sortListingItemsAscByStringKey(listingItems, "description")
    case CargoAddressListingSortKey.ADDRESS_DESCRIPTION_DESC:
      return sortListingItemsDescByStringKey(listingItems, "description")
    case CargoAddressListingSortKey.ADDRESS_COUNTRY_ASC:
      return sortListingItemsAscByStringKey(listingItems, "country")
    case CargoAddressListingSortKey.ADDRESS_COUNTRY_DESC:
      return sortListingItemsDescByStringKey(listingItems, "country")
    case CargoAddressListingSortKey.ADDRESS_TOWN_ASC:
      return sortListingItemsAscByStringKey(listingItems, "town")
    case CargoAddressListingSortKey.ADDRESS_TOWN_DESC:
      return sortListingItemsDescByStringKey(listingItems, "town")
    case CargoAddressListingSortKey.ADDRESS_STREET_ASC:
      return sortListingItemsAscByStringKey(listingItems, "street")
    case CargoAddressListingSortKey.ADDRESS_STREET_DESC:
      return sortListingItemsDescByStringKey(listingItems, "street")

    default:
      return listingItems;
  }
};

const getSelectOptions = (): CargoAddressListingSortSelectOption[] => {
  const translations =
    cargoTranslationsHelper.getAddressListingTranslations().sort;

  return [
    {
      label: translations.descriptionAscOptionLabel,
      value: CargoAddressListingSortKey.ADDRESS_DESCRIPTION_ASC,
    },
    {
      label: translations.descriptionDescOptionLabel,
      value: CargoAddressListingSortKey.ADDRESS_DESCRIPTION_DESC,
    },
    {
      label: translations.countryAscOptionLabel,
      value: CargoAddressListingSortKey.ADDRESS_COUNTRY_ASC,
    },
    {
      label: translations.countryDescOptionLabel,
      value: CargoAddressListingSortKey.ADDRESS_COUNTRY_DESC,
    },
    {
      label: translations.townAscOptionLabel,
      value: CargoAddressListingSortKey.ADDRESS_TOWN_ASC,
    },
    {
      label: translations.townDescOptionLabel,
      value: CargoAddressListingSortKey.ADDRESS_TOWN_DESC,
    },
    {
      label: translations.streetAscOptionLabel,
      value: CargoAddressListingSortKey.ADDRESS_STREET_ASC,
    },
    {
      label: translations.streetDescOptionLabel,
      value: CargoAddressListingSortKey.ADDRESS_STREET_DESC,
    },
  ];
};

const getDefaultSelectOption = (): CargoAddressListingSortSelectOption => {
  const translations =
    cargoTranslationsHelper.getAddressListingTranslations().sort;

  return {
    label: translations.descriptionAscOptionLabel,
    value: CargoAddressListingSortKey.ADDRESS_DESCRIPTION_ASC,
  };
};

const cargoAddressListingSortHelper = {
  sortListingItems,
  getSelectOptions,
  getDefaultSelectOption,
};

export default cargoAddressListingSortHelper;
