export enum CargoOrderFinishedListReportRequestOrder {
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
}

export enum CargoOrderFinishedListReportRequestPublicStatus {
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
  PUBLIC = "PUBLIC",
}

export type CargoOrderFinishedListReportRequestQueryParams = {
  isCancelled: boolean | undefined;
  isApproved: boolean | undefined;
  client: string[] | undefined;
  dispatch: string[] | undefined;
  driver: string[] | undefined;
  externalId: string[] | undefined;
  address: string[] | undefined;
  startAddress: string[] | undefined;
  midAddress: string[] | undefined;
  endAddress: string[] | undefined;
  taxi: string[] | undefined;
  passenger: string[] | undefined;
  publicStatus: CargoOrderFinishedListReportRequestPublicStatus | undefined;
  order: CargoOrderFinishedListReportRequestOrder | undefined;
  startTimeSince: Date | undefined;
  startTimeTo: Date | undefined;
  humanId: number[] | undefined;
};

export type CargoOrderFinishedListReportRequest = {
  internalOrderId?: number[];
  isCancelled?: boolean;
  isApproved?: boolean;
  startTimeSince?: Date;
  startTimeTo?: Date;
  client?: string[];
  dispatch?: string[];
  driver?: string[];
  externalId?: string[];
  address?: string[];
  startAddress?: string[];
  midAddress?: string[];
  endAddress?: string[];
  taxi?: string[];
  passenger?: string[];
  publicStatus?: CargoOrderFinishedListReportRequestPublicStatus;
  order?: CargoOrderFinishedListReportRequestOrder;
};

export default CargoOrderFinishedListReportRequest;
