const checkIsAddressGroup = (
  address: string | string[]
): address is string[] => {
  if (!Array.isArray(address)) {
    return false;
  }

  return true;
};

const orderListingTableRouteHelper = {
  checkIsAddressGroup,
};

export default orderListingTableRouteHelper;
