import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverDetailsActiveRoutePlanOrderDeleteTableColumn from "./types/driver-details-active-route-plans-order-delete-table-column";

const getColumns = (): DriverDetailsActiveRoutePlanOrderDeleteTableColumn[] => {
  const translations =
    userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations()
      .deleteOrder.table.headers;

  return [
    {
      accessor: "cargoOrderInternalId",
      header: translations.cargoOrderInternalOrderIdLabel,
      title: translations.cargoOrderInternalOrderIdTitle,
      colSpan: 4,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 1,
    },
  ];
};

const driverDetailsActiveRoutePlanDeleteOrderListingHelper = {
  getColumns,
};

export default driverDetailsActiveRoutePlanDeleteOrderListingHelper;
