import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import OrderDetailsChangeTaxiFormData from "./types/order-details-change-taxi-form-data";

const validateTaxiCorporation = (
  taxiCorporation: OrderDetailsChangeTaxiFormData["taxiCorporation"]
) => {
  const validationSchema = Joi.any().not(null).required();

  return formValidationService.validate(taxiCorporation, validationSchema);
};

const orderDetailsChangeTaxiFormValidationService = {
  validateTaxiCorporation,
};

export default orderDetailsChangeTaxiFormValidationService;
