import formValidationService from "../../../../../../../../../common/utils/validation/form-validation.service";
import { OrderRouteEditExternalPassenger } from "../../../../../types/order-route-edit-passenger";
import OrderRouteEditExternalPassengerEditFormData from "./types/order-route-edit-external-passenger-edit-form-data";
import OrderRouteEditExternalPassengerEditFormValidationResults from "./types/order-route-edit-external-passenger-edit-form-validation-results";

const getDefaultFormData = (
  passenger: OrderRouteEditExternalPassenger
): OrderRouteEditExternalPassengerEditFormData => {
  return {
    externalId: passenger.externalId,
    dispatchName: passenger.dispatchName,
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    mobile: passenger.mobile,
  };
};

const getDefaultFormValidationResults =
  (): OrderRouteEditExternalPassengerEditFormValidationResults => {
    return {
      externalId: formValidationService.defaultValidationResult,
      dispatchName: formValidationService.defaultValidationResult,
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
    };
  };

const orderRouteEditExternalPassengerEditHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default orderRouteEditExternalPassengerEditHelper;
