import Joi from "joi";
import TaxiUnsettledRouteListingRouteQueryFilterParams from "./types/taxi-unsettled-route-listing-route-query-filter-params";
import TaxiUnsettledRouteListingRouteQueryParams from "./types/taxi-unsettled-route-listing-route-query-params";
import TaxiUnsettledRouteListingSortKey from "./types/taxi-unsettled-route-listing-sort-key";
import TaxiUnsettledRouteListingFilter, {
  TaxiUnsettledRouteListingRouteStartDateFilter,
  TaxiUnsettledRouteListingDriverFilter,
  TaxiUnsettledRouteListingRouteIdFilter,
  TaxiUnsettledRouteListingRouteAddressFilter,
  TaxiUnsettledRouteListingRouteDestinationAddressFilter,
  TaxiUnsettledRouteListingRouteIntermediateAddressFilter,
  TaxiUnsettledRouteListingRoutePickupAddressFilter,
  TaxiUnsettledRouteListingOrderInternalIdFilter,
  TaxiUnsettledRouteListingClientFilter,
} from "./types/taxi-unsettled-route-listing-filter";
import TaxiUnsettledRouteListingFilterType from "./types/taxi-unsettled-route-listing-filter-type";

const getFilters = (
  routeQueryFilterParams: TaxiUnsettledRouteListingRouteQueryFilterParams
): TaxiUnsettledRouteListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: TaxiUnsettledRouteListingFilter[] = [];

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const orderStartDateFilter: TaxiUnsettledRouteListingRouteStartDateFilter =
      {
        type: TaxiUnsettledRouteListingFilterType.ORDER_START_DATE,
        value: {
          from: new Date(routeQueryFilterParams.startDateFrom),
          to: new Date(routeQueryFilterParams.startDateTo),
        },
      };

    filters.push(orderStartDateFilter);
  }

  if (routeQueryFilterParams.client) {
    const clientFilter: TaxiUnsettledRouteListingClientFilter = {
      type: TaxiUnsettledRouteListingFilterType.CLIENT,
      value: routeQueryFilterParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: TaxiUnsettledRouteListingDriverFilter = {
      type: TaxiUnsettledRouteListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.orderInternalId) {
    const orderInternalIdFilter: TaxiUnsettledRouteListingOrderInternalIdFilter =
      {
        type: TaxiUnsettledRouteListingFilterType.ORDER_INTERNAL_ID,
        value: Number(routeQueryFilterParams.orderInternalId),
      };

    filters.push(orderInternalIdFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: TaxiUnsettledRouteListingRouteAddressFilter = {
      type: TaxiUnsettledRouteListingFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationFilter: TaxiUnsettledRouteListingRouteDestinationAddressFilter =
      {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationFilter);
  }

  if (routeQueryFilterParams.routeId) {
    const routeIdFilter: TaxiUnsettledRouteListingRouteIdFilter = {
      type: TaxiUnsettledRouteListingFilterType.ROUTE_ID,
      value: Number(routeQueryFilterParams.routeId),
    };
    filters.push(routeIdFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: TaxiUnsettledRouteListingRouteIntermediateAddressFilter =
      {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: TaxiUnsettledRouteListingRoutePickupAddressFilter =
      {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: TaxiUnsettledRouteListingRouteQueryParams
): TaxiUnsettledRouteListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: TaxiUnsettledRouteListingFilter[],
  sortKey: TaxiUnsettledRouteListingSortKey | null,
  page: number,
  pageSize: number
): TaxiUnsettledRouteListingRouteQueryParams => {
  const orderInternalIdFilterValue = filters.find(
    (filter) =>
      filter.type === TaxiUnsettledRouteListingFilterType.ORDER_INTERNAL_ID
  )?.value as
    | TaxiUnsettledRouteListingOrderInternalIdFilter["value"]
    | undefined;

  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiUnsettledRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiUnsettledRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    routeId: (
      filters.find(
        (filter) => filter.type === TaxiUnsettledRouteListingFilterType.ROUTE_ID
      )?.value as TaxiUnsettledRouteListingRouteIdFilter["value"] | undefined
    )?.toString(),
    driver: filters.find(
      (filter) => filter.type === TaxiUnsettledRouteListingFilterType.DRIVER
    )?.value as TaxiUnsettledRouteListingDriverFilter["value"] | undefined,
    client: filters.find(
      (filter) => filter.type === TaxiUnsettledRouteListingFilterType.CLIENT
    )?.value as TaxiUnsettledRouteListingDriverFilter["value"] | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.ROUTE_ADDRESS
    )?.value as
      | TaxiUnsettledRouteListingRouteAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | TaxiUnsettledRouteListingRouteDestinationAddressFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | TaxiUnsettledRouteListingRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | TaxiUnsettledRouteListingRoutePickupAddressFilter["value"]
      | undefined,
    orderInternalId: orderInternalIdFilterValue
      ? String(orderInternalIdFilterValue)
      : undefined,
  };
};

const getPage = (
  routeQueryFilterParams: TaxiUnsettledRouteListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: TaxiUnsettledRouteListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: TaxiUnsettledRouteListingRouteQueryFilterParams
) => {
  const filterParams: TaxiUnsettledRouteListingRouteQueryFilterParams = {
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
    routeId: routeQueryFilterParams.routeId,
    driver: routeQueryFilterParams.driver,
    client: routeQueryFilterParams.client,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    orderInternalId: routeQueryFilterParams.orderInternalId,
  };

  const validationSchema =
    Joi.object<TaxiUnsettledRouteListingRouteQueryFilterParams>({
      startDateFrom: Joi.string(),
      startDateTo: Joi.string(),
      routeId: Joi.number(),
      driver: Joi.string(),
      client: Joi.string(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      orderInternalId: Joi.number(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: TaxiUnsettledRouteListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(TaxiUnsettledRouteListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: TaxiUnsettledRouteListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: TaxiUnsettledRouteListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const taxiUnsettledRouteListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiUnsettledRouteListingRouteQueryParamsService;
