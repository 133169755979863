import useAsyncData from "../../../hooks/use-async-data";
import cargoOrderService from "../cargo-order.service";
import CargoOrderDetailsHandlingLog from "./cargo-order-details-handling-log";
import CargoOrderDetailsHandlingLogLoadParams from "./cargo-order-details-handling-log-load-params";

const useCargoOrderDetailsHandlingLog = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<CargoOrderDetailsHandlingLog>({
      data: [],
    });

  const load = async (
    params: CargoOrderDetailsHandlingLogLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoOrderDetailsHandlingLogData =
        await cargoOrderService.getHandlingLog(params, signal);

      setData(cargoOrderDetailsHandlingLogData);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useCargoOrderDetailsHandlingLog;
