import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import TaxiUnsettledTransferedOrderListingItem from "../types/taxi-unsettled-transfered-order-listing-item";
import TaxiUnsettledTransferedOrderListingTableColumn from "../types/taxi-unsettled-transfered-order-listing-table-column";
import TaxiUnsettledTransferedOrderListingTableRow from "../types/taxi-unsettled-transfered-order-listing-table-row";
import taxiUnsettledTransferedOrderListingTableHelper from "./taxi-unsettled-transfered-order-listing-table.helper";

type TaxiUnsettledTransferedOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: TaxiUnsettledTransferedOrderListingItem[];
};

const TaxiUnsettledTransferedOrderListingTableComponent: FC<
  TaxiUnsettledTransferedOrderListingTableProps
> = (props) => {
  const columns: TaxiUnsettledTransferedOrderListingTableColumn[] =
    taxiUnsettledTransferedOrderListingTableHelper.getColumns();

  const rows: TaxiUnsettledTransferedOrderListingTableRow[] =
    taxiUnsettledTransferedOrderListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiUnsettledTransferedOrderListingTableComponent;
