import TaxiCorporationDetails from "../../../../../common/services/taxi-corporation/details/taxi-corporation-details";
import TaxiTaxiContractAddParams from "../../../../../common/services/taxi-taxi-contract/add/taxi-taxi-contract-add-params";
import TaxiTaxiContractAddFormData from "./types/taxi-taxi-contract-add-form-data";

const create = (
  formData: TaxiTaxiContractAddFormData,
  sourceTaxiCorporation: TaxiCorporationDetails
): TaxiTaxiContractAddParams => {
  return {
    billingModel: formData.billingModel!,
    consumerTaxiUuid: formData.targetTaxiCorporationUuid,
    distanceRate: formData.distanceRate!,
    freeStoppingPeriodMinutes: formData.freeStoppingPeriodMinutes!,
    isActive: formData.isActive!,
    periodOfValidation: formData.periodOfValidity!,
    producerTaxiUuid: sourceTaxiCorporation.uuid,
    stoppingRate: formData.stoppingRate!,
    displayName: formData.displayName,
    priority: formData.priority ?? undefined,
  };
};

const taxiTaxiContractAddParamsFactory = {
  create,
};

export default taxiTaxiContractAddParamsFactory;
