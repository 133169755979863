import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiUnsettledRouteListingSortKey from "./types/taxi-unsettled-route-listing-sort-key";
import TaxiUnsettledRouteListingSortSelectOption from "./types/taxi-unsettled-route-listing-sort-select-option";

const getSelectOptions = (): TaxiUnsettledRouteListingSortSelectOption[] => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledRouteListingTranslations();
  return [
    {
      label: translations.sort.orderStartDateAscOptionLabel,
      value: TaxiUnsettledRouteListingSortKey.START_TIME_ASC,
    },
    {
      label: translations.sort.orderStartDateDescOptionLabel,
      value: TaxiUnsettledRouteListingSortKey.START_TIME_DESC,
    },
    {
      label: translations.sort.routeIdAscOptionLabel,
      value: TaxiUnsettledRouteListingSortKey.ROUTE_ID_ASC,
    },
    {
      label: translations.sort.routeIdDescOptionLabel,
      value: TaxiUnsettledRouteListingSortKey.ROUTE_ID_DESC,
    },
    {
      label: translations.sort.driverAscOptionLabel,
      value: TaxiUnsettledRouteListingSortKey.DRIVER_ASC,
    },
    {
      label: translations.sort.driverDescOptionLabel,
      value: TaxiUnsettledRouteListingSortKey.DRIVER_DESC,
    },
  ];
};

const taxiUnsettledRouteListingSortHelper = {
  getSelectOptions,
};

export default taxiUnsettledRouteListingSortHelper;
