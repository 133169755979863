import appConfig from "../../../../../app.config";
import authService from "../../../../auth/common/auth.service";
import OrderAddCargoCompaniesResponse from "./order-add-cargo-companies.response";
import OrderAddDispatchersResponse from "./order-add-dispatchers.response";
import OrderAddPassengersResponse from "./order-add-passengers.response";
import OrderAddTaxiContractsResponse from "./order-add-taxi-contracts.response";
import searchRoadRoutingService from "../../../../../common/utils/search-road-route/search-road-routing.service";
import OrderAddRouteRequest from "./order-add-route.request";
import OrderAddRouteResponse from "./order-add-route.response";
import OrderAddRequestBody, { OrderAddRequestQuery } from "./order-add.request";
import urlService from "../../../../../common/utils/url/url.service";
import OrderAddSolvedRidesRequest from "./order-add-solved-rides.request";
import OrderAddSolvedRidesResponse from "./order-add-solved-rides.response";
import OrderAddResponse, {
  OrderAddNotAcceptableResponseError,
  OrderAddNotAcceptableResponseErrorType,
  OrderAddUnprocessableEntityErrorType,
  OrderAddUnprocessableEntityTimeError,
} from "./order-add.response";
import OrderAddPassengersRequest from "./order-add-passengers.request";
import OrderAddCargoAddressesRequest from "./order-add-cargo-addresses-request";
import OrderAddCargoAddressesResponse from "./order-add-cargo-addresses.response";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";

const fetchCargoCompanies = (): Promise<OrderAddCargoCompaniesResponse> => {
  const path = `/cargo-companies`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchDispatchers = (
  companyUuid: string
): Promise<OrderAddDispatchersResponse> => {
  const path = `/cargo-companies/${companyUuid}/dispatchers`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchPassengers = (
  contractorCompanyUuid: string,
  request: OrderAddPassengersRequest
): Promise<OrderAddPassengersResponse> => {
  const path = `/cargo-companies/${contractorCompanyUuid}/passengers`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchCargoAddresses = (
  companyUuid: string,
  request: OrderAddCargoAddressesRequest
): Promise<OrderAddCargoAddressesResponse> => {
  const path = `/cargo-companies/${companyUuid}/addresses`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const finalUrl = urlService.buildWithoutEmptyParams(url, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(finalUrl, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchTaxiContracts = (
  dispatcherId: string
): Promise<OrderAddTaxiContractsResponse> => {
  const path = `/contracts/dispatchers/${dispatcherId}/valid-collection`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchRoute = (
  request: OrderAddRouteRequest
): Promise<OrderAddRouteResponse> => {
  return searchRoadRoutingService.search(request);
};

const addOrder = (
  requestBody: OrderAddRequestBody,
  requestQuery: OrderAddRequestQuery
): Promise<OrderAddResponse> => {
  const path = `/orders`;

  const url = urlService.buildWithoutEmptyParams(
    `${appConfig.baseApiUrl}${path}`,
    requestQuery
  );

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(requestBody),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchSolvedRides = (
  request: OrderAddSolvedRidesRequest
): Promise<OrderAddSolvedRidesResponse> => {
  const path = `/rides/solved`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    body: JSON.stringify(request),
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const resolveUnprocessableEntityError = (
  error: OrderAddUnprocessableEntityTimeError
): string => {
  const translations = orderTranslationsHelper.getAddTranslations().validation;
  const [item] = error.detail;

  switch (item.error_code) {
    case OrderAddUnprocessableEntityErrorType.TOO_LONG_RIDE:
      return translations.errors.unprocessableEntity.givenTimeError;
    default:
      return translations.defaultError;
  }
};

const resolveApiErrorLabel = (
  error: OrderAddNotAcceptableResponseError
): string => {
  const translations =
    orderTranslationsHelper.getAddTranslations().validation.errors.api;

  switch (error.error_type) {
    case OrderAddNotAcceptableResponseErrorType.PASSENGERS_DO_OVERLAP:
      return translations.passengerDoOverlapError;
    case OrderAddNotAcceptableResponseErrorType.INCONSISTENT_RIDE_TIMING:
      return translations.inconsistentRideTimingError;
    case OrderAddNotAcceptableResponseErrorType.TIME_NOT_AVAILABLE:
      return translations.timingNotAvailableError;
    case OrderAddNotAcceptableResponseErrorType.NO_VALID_CARGO_TAXI_CONTRACT:
      return translations.noValidCargoTaxiContractError;
    case OrderAddNotAcceptableResponseErrorType.TIMING_NOT_POSSIBLE:
      return translations.timingNotPossibleError;
  }
};

const orderAddApiService = {
  fetchCargoCompanies,
  fetchDispatchers,
  fetchPassengers,
  fetchCargoAddresses,
  fetchTaxiContracts,
  fetchRoute,
  addOrder,
  fetchSolvedRides,
  resolveApiErrorLabel,
  resolveUnprocessableEntityError,
};

export default orderAddApiService;
