import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../../common/components/button/button.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import DriverAddRequest from "../common/api/driver-add.request";
import DriverAddResponse from "../common/api/driver-add.response";
import DriverAddAccountDataComponent from "../common/account/driver-add-account-data.component";
import DriverAddCompanyDataComponent from "../common/company/driver-add-company-data.component";
import DriverAddVehicleDataComponent from "../common/vehicle/driver-add-vehicle-data.component";
import driverAddAccountDataFormValidationService from "../common/account/driver-add-account-data-form-validation.service";
import driverAddCompanyDataFormValidationService from "../common/company/driver-add-company-data-form-validation.service";
import driverAddByRailyUserDataFormValidationService from "./user-data/driver-add-by-raily-user-data-form-validation.service";
import driverAddVehicleDataFormValidationService from "../common/vehicle/driver-add-vehicle-data-form-validation.service";
import driverAddFormHelper from "../common/driver-add-form.helper";
import driverAddFactory from "../common/driver-add.factory";
import DriverAddAccountFormData from "../common/types/driver-add-account-form-data";
import DriverAddAccountFormValidationResults from "../common/types/driver-add-account-form-validation-results";
import DriverAddCompanyFormData from "../common/types/driver-add-company-form-data";
import DriverAddCompanyFormValidationResults from "../common/types/driver-add-company-form-validation-results";
import DriverAddVehicleFormData from "../common/types/driver-add-vehicle-form-data";
import DriverAddVehicleFormValidationResults from "../common/types/driver-add-vehicle-form-validation-results";
import DriverAddViewBasicProps from "../common/types/driver-add-view-basic-props";
import DriverAddByRailyUserDataComponent from "./user-data/driver-add-by-raily-user-data.component";
import DriverAddByRailyUserFormData from "./common/types/driver-add-by-raily-user-form-data";
import DriverAddByRailyUserFormValidationResults from "./common/types/driver-add-by-raily-user-form-validation-results";
import driverAddByRailyFormHelper from "./common/driver-add-by-raily-form.helper";
import driverAddByRailyApiService from "./common/api/driver-add-by-raily-api.service";
import UserFormOfEmployment from "../../../../../common/types/user-form-of-employment";
import userApiService from "../../../common/api/user-api.service";
import UserCheckExistsResponse from "../../../common/api/user-check-exists.response";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import { useAppContext } from "../../../../../context/app.context";

type DriverAddByRailyProps = DriverAddViewBasicProps;

const DriverAddByRailyComponent: FC<DriverAddByRailyProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const translations = userTranslationsHelper.getDriverAddTranslations();

  const navigate = useNavigate();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [accountFormData, setAccountFormData] =
    useState<DriverAddAccountFormData>(() =>
      driverAddFormHelper.getDefaultAccountFormData()
    );

  const [accountFormValidationResults, setAccountFormValidationResults] =
    useState<DriverAddAccountFormValidationResults>(() =>
      driverAddFormHelper.getDefaultAccountFormValidationResults()
    );

  const [userFormData, setUserFormData] =
    useState<DriverAddByRailyUserFormData>(() =>
      driverAddByRailyFormHelper.getDefaultUserFormData()
    );

  const [userFormValidationResults, setUserFormValidationResults] =
    useState<DriverAddByRailyUserFormValidationResults>(() =>
      driverAddByRailyFormHelper.getDefaultUserFormValidationResults()
    );

  const [companyFormData, setCompanyFormData] =
    useState<DriverAddCompanyFormData>(() =>
      driverAddFormHelper.getDefaultCompanyFormData()
    );

  const [companyFormValidationResults, setCompanyFormValidationResults] =
    useState<DriverAddCompanyFormValidationResults>(() =>
      driverAddFormHelper.getDefaultCompanyFormValidationResults()
    );
  const [vehicleFormData, setVehicleFormData] =
    useState<DriverAddVehicleFormData>(() =>
      driverAddFormHelper.getDefaultVehicleFormData()
    );

  const [vehicleFormValidationResults, setVehicleFormValidationResults] =
    useState<DriverAddVehicleFormValidationResults>(() =>
      driverAddFormHelper.getDefaultVehicleFormValidationResults()
    );

  const [isCheckUserExistsFetching, setIsCheckUserExistsFetching] =
    useState(false);

  // temporary solution - remove after refactor
  const revalidateAccountField = async (field: string) => {
    switch (field) {
      case "email":
        return validateAccountDataEmail();
      case "username":
        return await validateUsername();
      case "password":
        return validateAccountDataPassword();
      case "passwordRepeat":
        return validateAccountDataPasswordRepeat();
    }
  };

  const revalidateUserField = (field: string) => {
    switch (field) {
      case "firstName":
        return validateUserDataFirstName();
      case "lastName":
        return validateUserDataLastName();
      case "mobile":
        return validateUserDataMobile();
      case "alternativeMobile":
        return validateUserDataAlternativeMobile();
      case "birthDate":
        return validateUserDataBirthDate();
      case "birthPlace":
        return validateUserDataBirthPlace();
      case "personalIdNumber":
        return validateUserDataPersonalIdNumber();
      case "citizenship":
        return validateUserDataCitizenship();
      case "languages":
        return validateUserDataLanguages();
      case "yearsOfExperience":
        return validateUserDataYearsOfExperience();
      case "formOfEmployment":
        return validateUserDataFormOfEmployment();
      case "taxiCorporation":
        return validateUserDataTaxiCorporation();
      case "fleetPartner":
        return validateUserDataFleetPartner();
      case "addresses":
        return validateUserDataAddresses();
    }
  };

  const revalidateVehicleField = (field: string) => {
    switch (field) {
      case "make":
        return validateVehicleDataMake();
      case "model":
        return validateVehicleDataModel();
      case "productionYear":
        return validateVehicleDataProductionYear();
      case "numberOfSeats":
        return validateVehicleDataNumberOfSeats();
      case "registrationNumber":
        return validateVehicleDataRegistrationNumber();
      case "ownership":
        return validateVehicleDataOwnership();
    }
  };

  const revalidateCompanyField = (field: string) => {
    switch (field) {
      case "name":
        return validateCompanyDataCompanyName();
      case "companyId":
        return validateCompanyDataCompanyId();
      case "taxNumber":
        return validateCompanyDataTaxNumber();
      case "nationalCourtRegisterNumber":
        return validateCompanyDataNationalCourtRegisterNumber();
      case "addressCountry":
        return validateCompanyDataAddressCountry();
      case "addressZipCode":
        return validateCompanyDataAddressZipCode();
      case "addressTown":
        return validateCompanyDataAddressTown();
      case "addressStreet":
        return validateCompanyDataAddressStreet();
      case "addressHouseNumber":
        return validateCompanyDataAddressHouseNumber();
      case "addressApartmentNumber":
        return validateCompanyDataAddressApartmentNumber();
      case "addressDescription":
        return validateCompanyDataAddressDescription();
      case "addressMap":
        return validateVehicleDataOwnership();
    }
  };

  useEffect(() => {
    const accountRevalidationFields = Object.keys(
      accountFormValidationResults
    ).filter(
      (formKey) =>
        accountFormValidationResults[
          formKey as keyof DriverAddAccountFormValidationResults
        ].isValid === false
    );
    const userRevalidationFields = Object.keys(
      userFormValidationResults
    ).filter(
      (formKey) =>
        userFormValidationResults[
          formKey as keyof DriverAddByRailyUserFormValidationResults
        ].isValid === false
    );
    const vehicleRevalidationFields = Object.keys(
      vehicleFormValidationResults
    ).filter(
      (formKey) =>
        vehicleFormValidationResults[
          formKey as keyof DriverAddVehicleFormValidationResults
        ].isValid === false
    );

    accountRevalidationFields.forEach(
      async (field) => await revalidateAccountField(field)
    );

    userRevalidationFields.forEach((field) => revalidateUserField(field));

    vehicleRevalidationFields.forEach((field) => revalidateVehicleField(field));

    if (userFormData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
      const companyRevalidationFields = Object.keys(
        companyFormValidationResults
      ).filter(
        (formKey) =>
          companyFormValidationResults[
            formKey as keyof DriverAddCompanyFormValidationResults
          ].isValid === false
      );

      companyRevalidationFields.forEach((field) =>
        revalidateCompanyField(field)
      );
    }
  }, [selectedAppLanguage]);

  const setUserExists = () => {
    const validationResult: FormValidationResult = {
      isValid: false,
      errorMessage:
        userTranslationsHelper.getUserAddTranslations()
          .userExistsValidationMessage,
    };

    setAccountFormValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));
  };

  const handleUserExistsFetchResponseSuccess = (
    response: UserCheckExistsResponse
  ) => {
    const isUserExists = response.data;
    if (isUserExists) {
      setUserExists();
    }
  };

  const handleUserExistsFetchResponse = (response: UserCheckExistsResponse) => {
    if (response.status === 200) {
      handleUserExistsFetchResponseSuccess(response);
      return;
    }
  };

  const checkUserExists = (username: string) => {
    setIsCheckUserExistsFetching(true);
    userApiService
      .fetchIsUserExist(username)
      .then(handleUserExistsFetchResponse)
      .finally(() => setIsCheckUserExistsFetching(false));
  };

  const validateUsername = async () => {
    const validationResult =
      await driverAddAccountDataFormValidationService.validateUsername(
        accountFormData.username
      );

    setAccountFormValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAndCheckUsernameExists = () => {
    if (!validateUsername()) {
      return;
    }

    checkUserExists(accountFormData.username);
  };

  const validateAccountDataEmail = () => {
    const validationResult =
      driverAddAccountDataFormValidationService.validateEmail(
        accountFormData.email
      );

    setAccountFormValidationResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAccountDataPassword = () => {
    const validationResult =
      driverAddAccountDataFormValidationService.validatePassword(
        accountFormData.password
      );

    setAccountFormValidationResults((curr) => ({
      ...curr,
      password: validationResult,
    }));

    if (!!accountFormData.passwordRepeat) {
      validateAccountDataPasswordRepeat();
    }

    return validationResult.isValid;
  };

  const validateAccountDataPasswordRepeat = () => {
    const validationResult =
      driverAddAccountDataFormValidationService.validatePasswordRepeat(
        accountFormData.password,
        accountFormData.passwordRepeat
      );

    setAccountFormValidationResults((curr) => ({
      ...curr,
      passwordRepeat: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsAccountFormValid = async () => {
    const isUsernameValid = await validateUsername();
    const isEmailValid = validateAccountDataEmail();
    const isPasswordValid = validateAccountDataPassword();
    const isPasswordRepeatValid = validateAccountDataPasswordRepeat();

    const isFormValid =
      isUsernameValid &&
      isEmailValid &&
      isPasswordValid &&
      isPasswordRepeatValid;

    return isFormValid;
  };

  const validateUserDataFirstName = () => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateFirstName(
        userFormData.firstName
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataLastName = () => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateLastName(
        userFormData.lastName
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataMobile = () => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateMobile(
        userFormData.mobile
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataAlternativeMobile = () => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateAlternativeMobile(
        userFormData.alternativeMobile
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataBirthDate = (
    value: DriverAddByRailyUserFormData["birthDate"] = userFormData.birthDate
  ) => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateBirthDate(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      birthDate: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataBirthPlace = () => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateBirthPlace(
        userFormData.birthPlace
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      birthPlace: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataPersonalIdNumber = () => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validatePersonalIdNumber(
        userFormData.personalIdNumber
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      personalIdNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataCitizenship = (
    value: DriverAddByRailyUserFormData["citizenship"] = userFormData.citizenship
  ) => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateCitizenship(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      citizenship: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataLanguages = (
    value: DriverAddByRailyUserFormData["languages"] = userFormData.languages
  ) => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateLanguages(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      languages: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataYearsOfExperience = () => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateYearsOfExperience(
        userFormData.yearsOfExperience
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      yearsOfExperience: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataFormOfEmployment = (
    value: DriverAddByRailyUserFormData["formOfEmployment"] = userFormData.formOfEmployment
  ) => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateFormOfEmployment(
        value
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      formOfEmployment: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataTaxiCorporation = (
    value: DriverAddByRailyUserFormData["taxiCorporation"] = userFormData.taxiCorporation
  ) => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateTaxiCorporation(
        value
      );

    setUserFormValidationResults((curr) => ({
      ...curr,
      taxiCorporation: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataFleetPartner = (
    value: DriverAddByRailyUserFormData["fleetPartner"] = userFormData.fleetPartner
  ) => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateFleetPartner(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      fleetPartner: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUserDataAddresses = (
    value: DriverAddByRailyUserFormData["addresses"] = userFormData.addresses
  ) => {
    const validationResult =
      driverAddByRailyUserDataFormValidationService.validateAddresses(value);

    setUserFormValidationResults((curr) => ({
      ...curr,
      addresses: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsUserFormValid = () => {
    const isFirstNameValid = validateUserDataFirstName();
    const isLastNameValid = validateUserDataLastName();
    const isMobileValid = validateUserDataMobile();
    const isAlternativeMobileValid = validateUserDataAlternativeMobile();
    const isBirthDateValid = validateUserDataBirthDate();
    const isBirthPlaceValid = validateUserDataBirthPlace();
    const isPersonalIdNumberValid = validateUserDataPersonalIdNumber();
    const isCitizenshipValid = validateUserDataCitizenship();
    const isLanguagesValid = validateUserDataLanguages();
    const isYearsOfExperienceValid = validateUserDataYearsOfExperience();
    const isFormOfEmploymentValid = validateUserDataFormOfEmployment();
    const isTaxiCorporationValid = validateUserDataTaxiCorporation();
    const isFleetPartnerValid = validateUserDataFleetPartner();
    const isAddressesValid = validateUserDataAddresses();

    const isFormValid =
      isFirstNameValid &&
      isLastNameValid &&
      isMobileValid &&
      isAlternativeMobileValid &&
      isBirthDateValid &&
      isBirthPlaceValid &&
      isPersonalIdNumberValid &&
      isCitizenshipValid &&
      isLanguagesValid &&
      isYearsOfExperienceValid &&
      isFormOfEmploymentValid &&
      isTaxiCorporationValid &&
      isFleetPartnerValid &&
      isAddressesValid;

    return isFormValid;
  };

  const validateCompanyDataCompanyName = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateName(
        companyFormData.name
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      name: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataCompanyId = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateCompanyId(
        companyFormData.companyId
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      companyId: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataTaxNumber = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateTaxNumber(
        companyFormData.taxNumber
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      taxNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataNationalCourtRegisterNumber = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateNationalCourtRegister(
        companyFormData.nationalCourtRegisterNumber
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      nationalCourtRegisterNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressApartmentNumber = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateAddressApartmentNumber(
        companyFormData.address.apartmentNumber
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressApartmentNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressCountry = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateAddressCountry(
        companyFormData.address.country
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressCountry: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressDescription = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateAddressDescription(
        companyFormData.address.description
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressDescription: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressHouseNumber = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateAddressHouseNumber(
        companyFormData.address.houseNumber
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressHouseNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressStreet = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateAddressStreet(
        companyFormData.address.street
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressStreet: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressTown = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateAddressTown(
        companyFormData.address.town
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressTown: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDataAddressZipCode = () => {
    const validationResult =
      driverAddCompanyDataFormValidationService.validateAddressZipCode(
        companyFormData.address.zipCode
      );

    setCompanyFormValidationResults((curr) => ({
      ...curr,
      addressZipCode: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsCompanyDataAddressFormValid = () => {
    const isApartmentNumberValid = validateCompanyDataAddressApartmentNumber();
    const isCountryValid = validateCompanyDataAddressCountry();
    const isDescriptionValid = validateCompanyDataAddressDescription();
    const isHouseNumberNumberValid = validateCompanyDataAddressHouseNumber();
    const isStreetValid = validateCompanyDataAddressStreet();
    const isTownValid = validateCompanyDataAddressTown();
    const isZipCodeValid = validateCompanyDataAddressZipCode();

    return (
      isApartmentNumberValid &&
      isCountryValid &&
      isDescriptionValid &&
      isHouseNumberNumberValid &&
      isStreetValid &&
      isTownValid &&
      isZipCodeValid
    );
  };

  const checkIsCompanyDataFormValid = () => {
    const isCompanyNameValid = validateCompanyDataCompanyName();
    const isCompanyIdValid = validateCompanyDataCompanyId();
    const isTaxNumberValid = validateCompanyDataTaxNumber();
    const isNationalCourtRegisterNumberValid =
      validateCompanyDataNationalCourtRegisterNumber();

    const isAddressValid = checkIsCompanyDataAddressFormValid();

    const isFormValid =
      isCompanyNameValid &&
      isCompanyIdValid &&
      isTaxNumberValid &&
      isNationalCourtRegisterNumberValid &&
      isAddressValid;

    return isFormValid;
  };

  const validateVehicleDataMake = () => {
    const validationResult =
      driverAddVehicleDataFormValidationService.validateMake(
        vehicleFormData.make
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      make: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataModel = () => {
    const validationResult =
      driverAddVehicleDataFormValidationService.validateModel(
        vehicleFormData.model
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      model: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataNumberOfSeats = () => {
    const validationResult =
      driverAddVehicleDataFormValidationService.validateNumberOfSeats(
        vehicleFormData.numberOfSeats
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      numberOfSeats: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataOwnership = (
    value: DriverAddVehicleFormData["ownership"] = vehicleFormData.ownership
  ) => {
    const validationResult =
      driverAddVehicleDataFormValidationService.validateOwnership(value);

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      ownership: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataProductionYear = () => {
    const validationResult =
      driverAddVehicleDataFormValidationService.validateProductionYear(
        vehicleFormData.productionYear
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      productionYear: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateVehicleDataRegistrationNumber = () => {
    const validationResult =
      driverAddVehicleDataFormValidationService.validateRegistrationNumber(
        vehicleFormData.registrationNumber
      );

    setVehicleFormValidationResults((curr) => ({
      ...curr,
      registrationNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsVehicleDataFormValid = () => {
    const isMakeValid = validateVehicleDataMake();
    const isModelValid = validateVehicleDataModel();
    const isNumberOfSeatsValid = validateVehicleDataNumberOfSeats();
    const isOwnershipValid = validateVehicleDataOwnership();
    const isProductionYearValid = validateVehicleDataProductionYear();
    const isRegistrationNumberValid = validateVehicleDataRegistrationNumber();

    return (
      isMakeValid &&
      isModelValid &&
      isNumberOfSeatsValid &&
      isOwnershipValid &&
      isProductionYearValid &&
      isRegistrationNumberValid
    );
  };

  const checkIsFormValidForEmployment = async () => {
    const isAccountFormValid = await checkIsAccountFormValid();
    const isUserFormValid = checkIsUserFormValid();

    const isVehicleFormValid = checkIsVehicleDataFormValid();

    const isFormValid =
      isAccountFormValid && isUserFormValid && isVehicleFormValid;

    return isFormValid;
  };

  const checkIsFormValidForB2B = async () => {
    const isAccountFormValid = await checkIsAccountFormValid();
    const isUserFormValid = checkIsUserFormValid();
    const isCompanyFormValid = checkIsCompanyDataFormValid();
    const isVehicleFormValid = checkIsVehicleDataFormValid();

    const isFormValid =
      isAccountFormValid &&
      isUserFormValid &&
      isCompanyFormValid &&
      isVehicleFormValid;

    return isFormValid;
  };

  const checkIsFormValid = () => {
    if (userFormData.formOfEmployment?.value === UserFormOfEmployment.B2B) {
      return checkIsFormValidForB2B();
    }

    return checkIsFormValidForEmployment();
  };

  const navigateToListing = () => {
    const dealerListingRoute = userRoutesHelper.getDriverListingRoute();
    navigate(dealerListingRoute);
  };

  const handleDriverAddResponse = (response: DriverAddResponse) => {
    if (response.status === 201) {
      notificationService.success(translations.successAddNotificationText);
      navigateToListing();
      return;
    }
    notificationService.error(translations.failureAddNotificationText);
  };

  const submitForm = async () => {
    const isFormValid = await checkIsFormValid();

    if (!isFormValid) return;

    setIsFormSubmitting(true);

    const taxiCorporationUuid: string =
      userFormData.taxiCorporation?.value.uuid!;

    const request: DriverAddRequest = driverAddFactory.createAddDriverRequest(
      accountFormData,
      userFormData,
      companyFormData,
      vehicleFormData,
      taxiCorporationUuid
    );

    driverAddByRailyApiService
      .addDriver(request)
      .then(handleDriverAddResponse)
      .finally(() => setIsFormSubmitting(false));
  };

  const isCargoDataSectionVisible =
    userFormData.formOfEmployment?.value === UserFormOfEmployment.B2B;

  return (
    <div className="user_driver_add">
      <HeadingComponent
        title={translations.header.headingText}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column withPaddings>
          <DriverAddAccountDataComponent
            formData={accountFormData}
            formValidationResults={accountFormValidationResults}
            onFormDataChange={setAccountFormData}
            validateEmail={validateAccountDataEmail}
            validateUsername={validateAndCheckUsernameExists}
            validatePassword={validateAccountDataPassword}
            validatePasswordRepeat={validateAccountDataPasswordRepeat}
            isUsernameFetching={isCheckUserExistsFetching}
          />
        </Column>
      </Row>
      <Row>
        <Column withPaddings>
          <DriverAddByRailyUserDataComponent
            formData={userFormData}
            formValidationResults={userFormValidationResults}
            onFormDataChange={setUserFormData}
            validateFirstName={validateUserDataFirstName}
            validateLastName={validateUserDataLastName}
            validateMobile={validateUserDataMobile}
            validateAlternativeMobile={validateUserDataAlternativeMobile}
            validateBirthDate={validateUserDataBirthDate}
            validateBirthPlace={validateUserDataBirthPlace}
            validatePersonalIdNumber={validateUserDataPersonalIdNumber}
            validateCitizenship={validateUserDataCitizenship}
            validateLanguages={validateUserDataLanguages}
            validateYearsOfExperience={validateUserDataYearsOfExperience}
            validateFormOfEmployment={validateUserDataFormOfEmployment}
            validateTaxiCorporation={validateUserDataTaxiCorporation}
            validateFleetPartner={validateUserDataFleetPartner}
            validateAddresses={validateUserDataAddresses}
          />
        </Column>
      </Row>
      {isCargoDataSectionVisible && (
        <Row>
          <Column withPaddings>
            <DriverAddCompanyDataComponent
              formData={companyFormData}
              formValidationResults={companyFormValidationResults}
              onFormDataChange={setCompanyFormData}
              validateCompanyName={validateCompanyDataCompanyName}
              validateCompanyId={validateCompanyDataCompanyId}
              validateTaxNumber={validateCompanyDataTaxNumber}
              validateNationalCourtRegisterNumber={
                validateCompanyDataNationalCourtRegisterNumber
              }
              validateAddressApartmentNumber={
                validateCompanyDataAddressApartmentNumber
              }
              validateAddressCountry={validateCompanyDataAddressCountry}
              validateAddressDescription={validateCompanyDataAddressDescription}
              validateAddressHouseNumber={validateCompanyDataAddressHouseNumber}
              validateAddressTown={validateCompanyDataAddressTown}
              validateAddressStreet={validateCompanyDataAddressStreet}
              validateAddressZipCode={validateCompanyDataAddressZipCode}
            />
          </Column>
        </Row>
      )}
      <Row>
        <Column withPaddings>
          <DriverAddVehicleDataComponent
            formData={vehicleFormData}
            formValidationResults={vehicleFormValidationResults}
            onFormDataChange={setVehicleFormData}
            validateMake={validateVehicleDataMake}
            validateModel={validateVehicleDataModel}
            validateNumberOfSeats={validateVehicleDataNumberOfSeats}
            validateOwnership={validateVehicleDataOwnership}
            validateProductionYear={validateVehicleDataProductionYear}
            validateRegistrationNumber={validateVehicleDataRegistrationNumber}
          />
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isLoading={isFormSubmitting}
        classNames={{ root: "mt-2" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};

export default DriverAddByRailyComponent;
