import { FC } from "react";
import taxiRouteSettlementListingTableHelper from "./taxi-route-settlement-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import TaxiRouteSettlementListingItem from "../types/taxi-route-settlement-listing-item";
import TaxiRouteSettlementListingTableColumn from "../types/taxi-route-settlement-listing-table-column";
import TaxiRouteSettlementListingTableRow from "../types/taxi-route-settlement-listing-table-row";

type TaxiRouteSettlementListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: TaxiRouteSettlementListingItem[];
};

const TaxiRouteSettlementListingTableComponent: FC<
  TaxiRouteSettlementListingTableProps
> = (props) => {
  const columns: TaxiRouteSettlementListingTableColumn[] =
    taxiRouteSettlementListingTableHelper.getColumns();

  const rows: TaxiRouteSettlementListingTableRow[] =
    taxiRouteSettlementListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiRouteSettlementListingTableComponent;
