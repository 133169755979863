import UserDeleteUserData from "../../../modules/user/common/delete/common/types/user-delete-user-data";
import {
  UserDeleteNotAcceptableResponse,
  UserDeleteNotAcceptableResponseErrorType,
} from "../../api/raily/user/delete/user-delete.response";
import userApiService from "../../api/raily/user/user-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import CheckUserExistsError from "./check-user-exists/check-user-exists-error";
import CheckUserExistsParams from "./check-user-exists/check-user-exists-params";
import CheckUserExistsResult from "./check-user-exists/check-user-exists-result";
import UserDeleteError from "./delete/user-delete-error";
import userDeleteErrorFactory from "./delete/user-delete-error.factory";
import UserDeleteParams from "./delete/user-delete-params";
import UserRestoreError from "./restore/user-restore-error";
import UserRestoreParams from "./restore/user-restore-params";

const handleCheckUserExistsError = (
  error: HttpError | CheckUserExistsError
): CheckUserExistsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const checkUserExists = async (
  params: CheckUserExistsParams,
  abortSignal: AbortSignal
): Promise<CheckUserExistsResult> => {
  try {
    const response = await userApiService().checkUserExists(
      { username: params.username },
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return {
      isExists: response.data,
    };
  } catch (_error) {
    const error = _error as HttpError | CheckUserExistsError;

    throw handleCheckUserExistsError(error);
  }
};

const handleUserDeleteError = (
  error: HttpError | UserDeleteError
): UserDeleteError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createUserDeleteErrorsNotAcceptableResponse = (
  response: UserDeleteNotAcceptableResponse,
  user: UserDeleteUserData
): UserDeleteError => {
  switch (response.data.errorType) {
    case UserDeleteNotAcceptableResponseErrorType.DRIVER_HAS_CANDIDATURE:
      return userDeleteErrorFactory.createCandidatureCollisionError(user);
    case UserDeleteNotAcceptableResponseErrorType.DRIVER_HAS_ACTIVE_PLAN:
      return userDeleteErrorFactory.createActivePlanCollisionError(user);
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const deleteUser = async (
  params: UserDeleteParams,
  abortSignal: AbortSignal,
  user: UserDeleteUserData
): Promise<void> => {
  try {
    const response = await userApiService().delete(
      { userUuid: params.userUuid },
      abortSignal
    );

    if (response.status === 406) {
      throw createUserDeleteErrorsNotAcceptableResponse(
        response as UserDeleteNotAcceptableResponse,
        user
      );
    }

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | UserDeleteError;

    throw handleUserDeleteError(error);
  }
};

const handleUserRestoreError = (
  error: HttpError | UserRestoreError
): UserRestoreError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const restore = async (
  params: UserRestoreParams,
  abortSignal: AbortSignal
): Promise<void> => {
  try {
    const response = await userApiService().restore(
      { userUuid: params.userUuid },
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | UserRestoreError;

    throw handleUserRestoreError(error);
  }
};

const userService = {
  checkUserExists,
  delete: deleteUser,
  restore,
};

export default userService;
