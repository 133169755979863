import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import OrderDetailsHistoryChangeRideEventType from "../../../common/types/order-details-history-change-ride-event-type";
import { OrderDetailsHistoryEntryChangeRideEvent } from "../../../common/types/order-details-history-entry-event";
import OrderDetailsHistoryEntryChangeRideContentAddCargoEnterComponent from "./add-cargo-enter/order-details-history-entry-change-ride-content-add-cargo-enter.component";
import OrderDetailsHistoryEntryChangeRideContentAddCargoExitComponent from "./add-cargo-exit/order-details-history-entry-change-ride-content-add-cargo-exit.component";
import OrderDetailsHistoryEntryChangeRideContentChangeHaltingTimeComponent from "./change-halting-time/order-details-history-entry-change-ride-content-change-halting-time.component";
import OrderDetailsHistoryEntryChangeRideContentChangePositionComponent from "./change-position/order-details-history-entry-change-ride-content-change-position.component";
import OrderDetailsHistoryEntryChangeRideContentChangeTimeComponent from "./change-time/order-details-history-entry-change-ride-content-change-time.component";
import OrderDetailsHistoryEntryChangeRideContentCreateInnerRideComponent from "./create-inner-ride/order-details-history-entry-change-ride-content-create-inner-ride.component";
import OrderDetailsHistoryEntryChangeRideContentDeleteNodeComponent from "./delete-node/order-details-history-entry-change-ride-content-delete-node.component";
import OrderDetailsHistoryEntryChangeRideContentDoNothingComponent from "./do-nothing/order-details-history-entry-change-ride-content-do-nothing.component";
import OrderDetailsHistoryEntryChangeRideContentInsertNodeAfterComponent from "./insert-node-after/order-details-history-entry-change-ride-content-insert-node-after.component";
import OrderDetailsHistoryEntryChangeRideContentInsertNodeBeforeComponent from "./insert-node-before/order-details-history-entry-change-ride-content-insert-node-before.component";
import OrderDetailsHistoryEntryChangeRideContentRemoveCargoEnterComponent from "./remove-cargo-enter/order-details-history-entry-change-ride-content-remove-cargo-enter.component";
import OrderDetailsHistoryEntryChangeRideContentRemoveCargoExitComponent from "./remove-cargo-exit/order-details-history-entry-change-ride-content-remove-cargo-enter.component";

type OrderDetailsHistoryEntryChangeRideContentProps = {
  event: OrderDetailsHistoryEntryChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry;

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingText}
      </div>
      <ul>
        {props.event.events.map((event, index) => {
          switch (event.type) {
            case OrderDetailsHistoryChangeRideEventType.ADD_CARGO_ENTER:
              return (
                <OrderDetailsHistoryEntryChangeRideContentAddCargoEnterComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.ADD_CARGO_EXIT:
              return (
                <OrderDetailsHistoryEntryChangeRideContentAddCargoExitComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.CHANGE_HALTING_TIME:
              return (
                <OrderDetailsHistoryEntryChangeRideContentChangeHaltingTimeComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.CHANGE_POSITION:
              return (
                <OrderDetailsHistoryEntryChangeRideContentChangePositionComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.CHANGE_TIME:
              return (
                <OrderDetailsHistoryEntryChangeRideContentChangeTimeComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.CREATE_INNER_RIDE:
              return (
                <OrderDetailsHistoryEntryChangeRideContentCreateInnerRideComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.DELETE_NODE:
              return (
                <OrderDetailsHistoryEntryChangeRideContentDeleteNodeComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.DO_NOTHING:
              return (
                <OrderDetailsHistoryEntryChangeRideContentDoNothingComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.INSERT_NODE_AFTER:
              return (
                <OrderDetailsHistoryEntryChangeRideContentInsertNodeAfterComponent
                  event={event}
                  key={index}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.INSERT_NODE_BEFORE:
              return (
                <OrderDetailsHistoryEntryChangeRideContentInsertNodeBeforeComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_ENTER:
              return (
                <OrderDetailsHistoryEntryChangeRideContentRemoveCargoEnterComponent
                  key={index}
                  event={event}
                />
              );
            case OrderDetailsHistoryChangeRideEventType.REMOVE_CARGO_EXIT:
              return (
                <OrderDetailsHistoryEntryChangeRideContentRemoveCargoExitComponent
                  key={index}
                  event={event}
                />
              );
            default:
              return null;
          }
        })}
      </ul>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentComponent;
