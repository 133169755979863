import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryInsertNodeBeforeChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";
import orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper from "./order-details-history-entry-change-ride-content-insert-node-before.helper";

type OrderDetailsHistoryEntryChangeRideContentInsertNodeBeforeProps = {
  event: OrderDetailsHistoryEntryInsertNodeBeforeChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentInsertNodeBeforeComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentInsertNodeBeforeProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .insertNodeBefore;

  const addressLabel =
    orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper.getAddressLabel(
      props.event.details.placeAddressDisplayName
    );

  const addressTitle =
    orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper.getAddressTitle(
      props.event.details.placeAddressDisplayName
    );

  const plannedDateLabel =
    orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper.getPlannedDateLabel(
      props.event.details.date
    );

  const plannedDateTitle =
    orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper.getPlannedDateTitle(
      props.event.details.date
    );

  const haltingTimeLabel =
    orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper.getHaltingTimeLabel(
      props.event.details.haltingTime
    );

  const haltingTimeTitle =
    orderDetailsHistoryEntryChangeRideContentInsertNodeBeforeHelper.getHaltingTimeTitle(
      props.event.details.haltingTime
    );

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={addressTitle}
      >
        {addressLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={plannedDateTitle}
      >
        {plannedDateLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={haltingTimeTitle}
      >
        {haltingTimeLabel}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentInsertNodeBeforeComponent;
