import { DriverEditByRailyTaxiCorporationsResponseDataItem } from "../api/driver-edit-by-raily-taxi-corporations.response";
import DriverEditByRailyTaxiCorporation from "../types/driver-edit-by-raily-taxi-corporation";
import DriverEditByRailyTaxiCorporationSelectOption from "../types/driver-edit-by-raily-taxi-corporation-select-option";
import DriverEditUserFormData from "../../../common/types/driver-edit-user-form-data";
import InitFormDataItem, {
  DriverEditInitFormAddress,
  DriverEditInitFormAssociation,
  DriverEditInitFormFleetPartner,
} from "../../../common/types/driver-edit-init-form-data-item";
import PhoneNumber from "../../../../../../../common/types/phone-number";
import UserCitizenshipSelectOption from "../../../../../../../common/types/user-citizenship-select-option";
import userHelper from "../../../../../../../common/utils/user/user.helper";
import UserFormOfEmploymentSelectOption from "../../../../../../../common/types/user-form-of-employment-select-option";
import DriverEditUserDataFleetPartnerSelectOption from "../../../common/types/driver-edit-user-data-fleet-partner-select-option";
import DriverEditAddress from "../../../common/types/driver-edit-address";
import DriverEditAddressType from "../../../common/types/driver-edit-address-type";
import DriverEditAccountFormData from "../../../common/types/driver-edit-account-form-data";
import DriverEditCompanyFormData from "../../../common/types/driver-edit-company-form-data";
import DriverEditCompanyFormAddress from "../../../common/types/driver-edit-company-form-address";
import DriverEditVehicleFormData from "../../../common/types/driver-edit-vehicle-form-data";
import UserVehicleOwnershipSelectOption from "../../../../../../../common/types/user-vehicle-ownership-form-select-option";
import UserLanguageSelectOption from "../../../../../../../common/types/user-language-select-option";
import UserLanguage from "../../../../../../../common/types/user-language";
import appTranslationsHelper from "../../../../../../../languages/app-translations.helper";
import AddressType from "../../../../../../../common/types/address-type";

const createTaxiCorporation = (
  responseCargoCompany: DriverEditByRailyTaxiCorporationsResponseDataItem
): DriverEditByRailyTaxiCorporation => {
  return {
    uuid: responseCargoCompany.id,
    displayName: responseCargoCompany.display_name,
    name: responseCargoCompany.company_name,
  };
};

const createTaxiCorporationSelectOption = (
  taxiCorporation: DriverEditByRailyTaxiCorporation
): DriverEditByRailyTaxiCorporationSelectOption => {
  return {
    label: taxiCorporation.displayName,
    value: taxiCorporation,
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: DriverEditByRailyTaxiCorporationsResponseDataItem[]
): DriverEditByRailyTaxiCorporationSelectOption[] => {
  const taxiCorporations = responseTaxiCorporations.map(createTaxiCorporation);

  return taxiCorporations.map(createTaxiCorporationSelectOption);
};

const createFleetPartnerSelectOption = (
  fleetPartnerDataItem: DriverEditInitFormFleetPartner
): DriverEditUserDataFleetPartnerSelectOption => {
  return {
    value: {
      name: fleetPartnerDataItem.name,
      uuid: fleetPartnerDataItem.id,
    },
    label: fleetPartnerDataItem.name,
  };
};

const createFleetPartnerSelectOptions = (
  responseFleetPartners: DriverEditInitFormFleetPartner[]
): DriverEditUserDataFleetPartnerSelectOption[] => {
  return responseFleetPartners.map(createFleetPartnerSelectOption);
};

const createDriverEditMobile = (
  prefix: string,
  number: string
): PhoneNumber => {
  return {
    dialingCode: prefix ?? undefined,
    number: number ?? undefined,
  };
};

const createCitizenshipSelectOption = (
  citizenshipDataItem: string
): UserCitizenshipSelectOption | undefined => {
  return userHelper.getCitizenshipSelectOptions().find((citizenship) => {
    return citizenship.value === citizenshipDataItem;
  });
};

const createFormOfEmploymentSelectOption = (
  formOfEmploymentDataItem: string
): UserFormOfEmploymentSelectOption | undefined => {
  return userHelper
    .getFormOfEmploymentSelectOptions()
    .find((formOfEmployment) => {
      return formOfEmployment.value === formOfEmploymentDataItem;
    });
};

const createAddressType = (addressTypeDataItem: string): AddressType => {
  switch (addressTypeDataItem) {
    case "MAILING":
      return AddressType.MAILING;
    case "STARTING":
      return AddressType.STARTING;
    default:
      return AddressType.STARTING;
  }
};

const createAddresses = (
  addressesDataItem: DriverEditInitFormAddress[]
): DriverEditAddress[] => {
  return addressesDataItem.map((address) => {
    return {
      country: address.country,
      zipCode: address.zip_code,
      town: address.town,
      street: address.street,
      houseNumber: address.house_no,
      apartmentNumber: address.apartment,
      latitude: address.lat,
      longitude: address.lon,
      description: address.description,
      type: createAddressType(address.type),
    };
  });
};

const createLanguageSelectOption = (
  languageDataItem: string
): UserLanguageSelectOption => {
  const translations =
    appTranslationsHelper.getUserCommonTranslations().languages;

  switch (languageDataItem) {
    case "PL":
      return {
        value: UserLanguage.PL,
        label: translations.PL,
      };
    case "EN":
      return {
        value: UserLanguage.EN,
        label: translations.EN,
      };
    case "DE":
      return {
        value: UserLanguage.DE,
        label: translations.DE,
      };
    case "FR":
      return {
        value: UserLanguage.FR,
        label: translations.FR,
      };
    case "ES":
      return {
        value: UserLanguage.ES,
        label: translations.ES,
      };
    case "SK":
      return {
        value: UserLanguage.SK,
        label: translations.SK,
      };
    case "CZ":
      return {
        value: UserLanguage.CZ,
        label: translations.CZ,
      };
    case "UA":
      return {
        value: UserLanguage.UA,
        label: translations.UA,
      };
    case "RU":
      return {
        value: UserLanguage.RU,
        label: translations.RU,
      };
    case "BY":
      return {
        value: UserLanguage.BY,
        label: translations.BY,
      };
    case "LT":
      return {
        value: UserLanguage.LT,
        label: translations.LT,
      };
    default:
      return {
        value: UserLanguage.PL,
        label: translations.PL,
      };
  }
};

const createLanguageSelectOptions = (
  languageDataItems: string[]
): UserLanguageSelectOption[] => {
  return languageDataItems.map(createLanguageSelectOption);
};

const createFleetPartner = (
  fleetPartnerDataItem: DriverEditInitFormFleetPartner
): DriverEditUserDataFleetPartnerSelectOption | null => {
  if (!fleetPartnerDataItem) {
    return null;
  }

  return {
    value: {
      uuid: fleetPartnerDataItem.id,
      name: fleetPartnerDataItem.name,
    },
    label: fleetPartnerDataItem.name,
  };
};

const createFormData = (
  driverEditInitFormDataResponse: InitFormDataItem
): DriverEditUserFormData => {
  const userFormData = driverEditInitFormDataResponse.user;
  const driverFormData = driverEditInitFormDataResponse.driver;
  const associationFormData = driverEditInitFormDataResponse.associations[0];
  const addressesFormData = driverEditInitFormDataResponse.addresses;
  return {
    firstName: userFormData.first_name,
    lastName: userFormData.last_name,
    mobile: createDriverEditMobile(
      userFormData.mobile_primary_prefix,
      userFormData.mobile_primary
    ),
    alternativeMobile: createDriverEditMobile(
      userFormData.mobile_secondary_prefix,
      userFormData.mobile_secondary
    ),
    birthDate: new Date(driverFormData.birth_date),
    birthPlace: driverFormData.birth_place,
    personalIdNumber: driverFormData.id_number,
    citizenship:
      createCitizenshipSelectOption(driverFormData.citizenship) ?? null,
    languages: createLanguageSelectOptions(driverFormData.languages),
    yearsOfExperience: driverFormData.experience,
    formOfEmployment:
      createFormOfEmploymentSelectOption(associationFormData.contract_type) ??
      null,
    fleetPartner: createFleetPartner(associationFormData.fleet_partner),
    addresses: createAddresses(addressesFormData),
  };
};

const createAccountFormData = (
  driverEditInitFormDataResponse: InitFormDataItem
): DriverEditAccountFormData => {
  const userDataItem = driverEditInitFormDataResponse.user;
  return {
    username: userDataItem.username,
    email: userDataItem.email,
  };
};

const createCompanyAddress = (
  associationDataItem: DriverEditInitFormAssociation
): DriverEditCompanyFormAddress => {
  return {
    country: associationDataItem.country ?? "",
    zipCode: associationDataItem.zip_code ?? "",
    town: associationDataItem.town ?? "",
    street: associationDataItem.street ?? "",
    houseNumber: associationDataItem.house_no ?? "",
    apartmentNumber: associationDataItem.apartment ?? "",
    description: associationDataItem.description ?? "",
  };
};

const createCompanyFormData = (
  driverEditInitFormDataResponse: InitFormDataItem
): DriverEditCompanyFormData => {
  const associationDataItem = driverEditInitFormDataResponse.associations[0];
  return {
    name: associationDataItem.company_name ?? "",
    companyId: associationDataItem.regon ?? "",
    taxNumber: associationDataItem.tax_id ?? "",
    nationalCourtRegisterNumber: associationDataItem.krs_no ?? "",
    address: createCompanyAddress(associationDataItem),
  };
};

const createCarOwnerShipSelectOption = (
  ownershipDataItem: string
): UserVehicleOwnershipSelectOption | undefined => {
  return userHelper.getVehicleOwnershipSelectOptions().find((ownership) => {
    return ownership.value === ownershipDataItem;
  });
};

const createVehicleFormData = (
  driverEditInitFormDataResponse: InitFormDataItem["driver"]
): DriverEditVehicleFormData => {
  return {
    make: driverEditInitFormDataResponse.car_make,
    model: driverEditInitFormDataResponse.car_model,
    productionYear: driverEditInitFormDataResponse.car_prod_year,
    numberOfSeats: driverEditInitFormDataResponse.car_seats_no,
    registrationNumber: driverEditInitFormDataResponse.car_reg_no,
    ownership:
      createCarOwnerShipSelectOption(
        driverEditInitFormDataResponse.car_ownership
      ) ?? null,
  };
};

const driverEditByRailyFactory = {
  createFormData,
  createAccountFormData,
  createCompanyFormData,
  createVehicleFormData,
  createTaxiCorporationSelectOptions,
  createFleetPartnerSelectOptions,
};

export default driverEditByRailyFactory;
