import CargoOrderFinishedListResponse, {
  CargoOrderFinishedListResponseDataItem,
  CargoOrderFinishedListResponseItemPublicStatus,
} from "../../../api/raily/cargo-order/finished-list/cargo-order-finished-list.response";
import CargoOrderFinishedList, {
  CargoOrderFinishedListItem,
  CargoOrderFinishedListItemPublicStatus,
} from "./cargo-order-finished-list";

const createPublicStatus = (
  responsePublicStatus: CargoOrderFinishedListResponseItemPublicStatus
): CargoOrderFinishedListItemPublicStatus => {
  switch (responsePublicStatus) {
    case CargoOrderFinishedListResponseItemPublicStatus.PRIVATE:
      return CargoOrderFinishedListItemPublicStatus.PRIVATE;
    case CargoOrderFinishedListResponseItemPublicStatus.PUBLIC:
      return CargoOrderFinishedListItemPublicStatus.PUBLIC;
    case CargoOrderFinishedListResponseItemPublicStatus.PUBLISHED:
      return CargoOrderFinishedListItemPublicStatus.PUBLISHED;
  }
};

const createDataItem = (
  responseItem: CargoOrderFinishedListResponseDataItem
): CargoOrderFinishedListItem => {
  return {
    uuid: responseItem.id,
    client: responseItem.client.id
      ? {
          id: responseItem.client.id,
          name: responseItem.client.name,
        }
      : null,
    consumer: responseItem.consumer?.id
      ? {
          id: responseItem.consumer.id,
          name: responseItem.consumer.name,
        }
      : null,
    dispatch: responseItem.dispatch,
    drivers: responseItem.drivers,
    externalOrderId: responseItem.externalId,
    internalOrderId: responseItem.humanId,
    isCancelled: responseItem.isCancelled,
    isMobileAppUser: responseItem.push,
    passengers: responseItem.passengers,
    producer: responseItem.producer.id
      ? {
          id: responseItem.producer.id,
          name: responseItem.producer.name,
        }
      : null,
    publicStatus: createPublicStatus(responseItem.publicStatus),
    routeAddresses: responseItem.rideAddresses,
    estimatedStartDate: responseItem.startTime,
    taxiCorporations: responseItem.taxiCorporations,
    attentions: {
      newSharedPost: responseItem.attentions?.newSharedPost,
      newPlanEntryPost: responseItem.attentions?.newPlanEntryPost,
      cargoOrderEdit: responseItem.attentions?.cargoOrderEdit,
      cargoOrderCancel: responseItem.attentions?.cargoOrderCancel,
    },
    slkStatus: responseItem.slkStatus ?? null,
  };
};

const createData = (
  responseData: CargoOrderFinishedListResponseDataItem[]
): CargoOrderFinishedListItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: CargoOrderFinishedListResponse
): CargoOrderFinishedList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const cargoOrderFinishedListFactory = {
  create,
};

export default cargoOrderFinishedListFactory;
