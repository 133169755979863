import {
  OrderDetailsResponseTransportingOrder,
  OrderDetailsResponseTransportingOrderSolve,
} from "./api/order-details.response";
import OrderDetailsRouteWaypoint from "./types/order-details-route-waypoint";

const createOrderDetailsWaypointForSolvedOrderRide = (
  responseRideWaypoint: OrderDetailsResponseTransportingOrderSolve,
  stageNo: number,
  markerPosition: number,
  completionDate: Date | null
): OrderDetailsRouteWaypoint => {
  return {
    haltingTime: responseRideWaypoint.requested_halting_time,
    onboardingPassengersUuids: responseRideWaypoint.cargo_enter,
    outboardingPassengersUuids: responseRideWaypoint.cargo_exit,
    orderedDate: responseRideWaypoint.requested_time
      ? new Date(responseRideWaypoint.requested_time)
      : null,
    place: {
      displayName: responseRideWaypoint.display_name,
      latitude: responseRideWaypoint.lat,
      longitude: responseRideWaypoint.lon,
    },
    stageNo,
    markerPosition,
    completionDate,
    distance: responseRideWaypoint.distance,
    estimatedDate: new Date(responseRideWaypoint.estimated_time),
  };
};

const createOrderDetailsWaypointsFromSolvedOrders = (
  responseTransportingOrders: OrderDetailsResponseTransportingOrder[],
  responseSolvedOrders: OrderDetailsResponseTransportingOrderSolve[]
): OrderDetailsRouteWaypoint[] => {
  let waypoints: OrderDetailsRouteWaypoint[] = [];

  responseSolvedOrders.forEach((sequenceItem, index) => {
    const completionDateString = responseTransportingOrders
      .find((transportingOrder) =>
        transportingOrder?.driver_solve?.find(
          (item) => item.node_id === sequenceItem.node_id
        )
      )
      ?.driver_solve?.find((item) => item.node_id === sequenceItem.node_id)
      ?.checkout_event?.achieved_at;

    const completionDate = completionDateString
      ? new Date(completionDateString)
      : null;

    const isRouteResolved = responseTransportingOrders.every(
      (order) =>
        order.solve.every((item) => !Array.isArray(item)) || !!order.driver
    );

    let stageNo = 0;

    responseTransportingOrders.forEach((transportingOrder) =>
      transportingOrder.solve.forEach((item, index) => {
        if (Array.isArray(item)) {
          const containsOrder = item.some(
            (order) => order.node_id === sequenceItem.node_id
          );

          if (containsOrder) {
            stageNo = index + 1;
          }
        }

        if (
          (item as OrderDetailsResponseTransportingOrderSolve).node_id ===
          sequenceItem.node_id
        ) {
          stageNo = index + 1;
        }
      })
    );

    const markerPosition = isRouteResolved ? index + 1 : stageNo;

    const newWaypoint = createOrderDetailsWaypointForSolvedOrderRide(
      sequenceItem,
      stageNo,
      markerPosition,
      completionDate
    );

    waypoints = [...waypoints, newWaypoint];
  });

  return waypoints;
};

const createWaypoints = (
  responseTransportingOrders: OrderDetailsResponseTransportingOrder[]
): OrderDetailsRouteWaypoint[] => {
  const solvedOrdersFromAllTransportingOrders = responseTransportingOrders
    .map((item) => item.solve.flat())
    .flat();

  const waypoints = createOrderDetailsWaypointsFromSolvedOrders(
    responseTransportingOrders,
    solvedOrdersFromAllTransportingOrders
  );

  return waypoints;
};

const orderDetailsWaypointFactory = {
  createWaypoints,
};

export default orderDetailsWaypointFactory;
