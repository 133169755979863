import HttpResponse from "../../../../../common/utils/http/http.response";

export enum OrderJoinErrorType {
  ORDER_ASSIGNED_TO_DRIVER = "ORDER_ASSIGNED_TO_DRIVER",
  CHANGE_RIDE_RELATED = "CHANGE_RIDE_RELATED",
}

export enum OrderJoinChangeTargetRideErrorType {
  NODE_ALREADY_CHECKED_OUT = "NODE_ALREADY_CHECKED_OUT",
  RESULT_INCONSISTENT_TIMING = "RESULT_INCONSISTENT_TIMING",
  RESULT_TIMING_NOT_POSSIBLE = "RESULT_TIMING_NOT_POSSIBLE",
  RESULT_CARGO_PROGRESSION_ERROR = "RESULT_CARGO_PROGRESSION_ERROR",
  RESULT_CARGO_PROGRESSION_MISSING_ENTER_SUBJECT = "RESULT_CARGO_PROGRESSION_MISSING_ENTER_SUBJECT",
  NO_VALID_CARGO_TAXI_CONTRACT = "NO_VALID_CARGO_TAXI_CONTRACT",
  NO_VALID_CARGO_RAILY_CONTRACT = "NO_VALID_CARGO_RAILY_CONTRACT",
  NO_RELEVANT_DISPATCH_FOUND = "NO_RELEVANT_DISPATCH_FOUND",
  PASSENGERS_DO_OVERLAP = "PASSENGERS_DO_OVERLAP",
}

export type OrderJoinChangeTargetRideError = {
  error_type: OrderJoinChangeTargetRideErrorType;
};

export type OrderJoinNotAcceptableOrderAssignedToDriverError = {
  error_type: OrderJoinErrorType.ORDER_ASSIGNED_TO_DRIVER;
};

export type OrderJoinNotAcceptableChangeRideRelatedError = {
  error_type: OrderJoinErrorType.CHANGE_RIDE_RELATED;
  change_target_ride_error: OrderJoinChangeTargetRideError;
};

export type OrderJoinNotAcceptableError =
  | OrderJoinNotAcceptableOrderAssignedToDriverError
  | OrderJoinNotAcceptableChangeRideRelatedError;

export type OrderJoinNotAcceptableResponse =
  HttpResponse<OrderJoinNotAcceptableError>;

export type OrderJoinSuccessResponse = HttpResponse;

type OrderJoinResponse =
  | OrderJoinSuccessResponse
  | OrderJoinNotAcceptableResponse;

export default OrderJoinResponse;
