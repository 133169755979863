import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import MapComponent from "../../../../../../common/components/map/map.component";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import TableDeleteButtonComponent from "../../../../../../common/components/table/button/delete/table-delete-button.component";
import TableComponent from "../../../../../../common/components/table/table.component";
import TableRow from "../../../../../../common/components/table/types/table-row";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../../common/context/sign-up-driver.context";
import SignUpDriverAddressType from "../../common/types/sign-up-driver-address-type";
import SignUpDriverUserAddressAddComponent from "./add/sign-up-driver-user-address-add.component";
import signUpDriverUserAddressHelper from "./sign-up-driver-user-address.helper";

type SignUpDriverUserAddressProps = {};

const SignUpDriverUserAddressComponent: FC<
  SignUpDriverUserAddressProps
> = () => {
  const userDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().userDataForm;

  const {
    userData: { addresses },
    removeUserDataAddress,
  } = useSignUpDriverContext();

  const [isAddNewAddressModalOpen, setIsAddNewAddressModalOpen] =
    useState(false);

  const mapMarkers: MapMarker[] = addresses.map((address) => {
    return {
      coordinate: {
        latitude: address.latitude,
        longitude: address.longitude,
      },
      title: signUpDriverUserAddressHelper.getAddressLabel(address),
      tooltip: signUpDriverUserAddressHelper.getAddressLabel(address),
    };
  });

  const openAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(true);
  };

  const closeAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(false);
  };

  const addressTypeIconDefinitions: {
    type: SignUpDriverAddressType;
    icon: IconProp;
  }[] = [
    {
      icon: faEnvelope,
      type: SignUpDriverAddressType.MAILING,
    },
    {
      icon: faHouse,
      type: SignUpDriverAddressType.STARTING,
    },
  ];

  const tableRows: TableRow[] = addresses.map((address, index) => {
    const icon = addressTypeIconDefinitions.find(
      (definition) => definition.type === address.type
    )?.icon;

    return {
      id: String(index),
      value: {
        address: (
          <div className="sign_up_user_address_table_row address">
            {icon && <FontAwesomeIcon icon={icon} className="icon" />}
            <div className="text_ellipsis">
              {signUpDriverUserAddressHelper.getAddressLabel(address)}
            </div>
          </div>
        ),
        actions: (
          <TableDeleteButtonComponent
            onClick={() => removeUserDataAddress(index)}
          />
        ),
      },
    };
  });

  return (
    <div className="sign_up_user_address">
      <Row>
        <Column xl={4}>
          <TableComponent
            columns={[
              {
                accessor: "address",
                header: userDataFormTranslations.addressTableHeader.addressName,
                title: userDataFormTranslations.addressTableHeader.addressName,
                colSpan: 4,
              },
              {
                accessor: "actions",
                header: "Actions",
                title: "",
              },
            ]}
            rows={tableRows}
          />
          <div className="sign_up_user_address__add_button_wrapper">
            <ButtonComponent
              onClick={openAddNewAddressModal}
              title={userDataFormTranslations.addNewAddressButtonTitle}
              type="primary"
              idForTesting="user-data-open-add-new-address-modal-button"
            >
              {userDataFormTranslations.addNewAddressButtonText}
            </ButtonComponent>
          </div>
        </Column>
        <Column xl={8}>
          <div className="sign_up_user_address__map_wrapper">
            <MapComponent markers={mapMarkers} autoFit autoFitOnUpdate />
          </div>
        </Column>
      </Row>
      <SignUpDriverUserAddressAddComponent
        isModalOpen={isAddNewAddressModalOpen}
        onCloseClick={closeAddNewAddressModal}
      />
    </div>
  );
};

export default SignUpDriverUserAddressComponent;
