import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DelegationAddRouteItem from "../../types/delegation-add-route-item";
import DelegationAddAddress from "../../types/delegation-add-address";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import delegationTranslationsHelper from "../../../../../../languages/delegation-translations.helper";
import useMileageAddressList from "../../../../../../common/services/mileage-address/list/use-mileage-address-list";
import useAbort from "../../../../../../common/hooks/use-abort";
import useOpen from "../../../../../../common/hooks/use-open";
import { useAppContext } from "../../../../../../context/app.context";
import { debounce } from "lodash";
import MileageAddressListLoadParams from "../../../../../../common/services/mileage-address/list/mileage-address-list-load-params";
import DelegationAddAddressSelectOption from "../../types/delegation-add-address-select-option";
import SelectOption from "../../../../../../common/components/form/select/common/option/select-option";
import delegationAddAddressFactory from "../../factory/delegation-add-address.factory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import DelegationAddressAddModalAddress from "../../../../common/routes/address-add-modal/types/delegation-address-add-modal-address";
import DelegationAddressAddModalComponent from "../../../../common/routes/address-add-modal/delegation-address-add-modal.component";

type DelegationAddRouteItemProps = {
  routeIndex: number;
  routeItem: DelegationAddRouteItem;
  updateRouteItem: (updatedRouteItem: DelegationAddRouteItem) => void;
  deleteRouteItem: () => void;
  additionalAddresses: DelegationAddAddress[];
  cargoCompanyAddresses: DelegationAddAddress[];
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onBlur?: () => void;
  idForTesting?: string;
};

const DelegationAddRouteItemComponent: FC<DelegationAddRouteItemProps> = (
  props
) => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().routes;

  const mileageAddressList = useMileageAddressList();
  const mileageAddressListAbort = useAbort();

  const [addressSearchQuery, setAddressSearchQuery] = useState("");

  const { selectedAppLanguage } = useAppContext();
  const { isOpen, open, close } = useOpen();

  const loadAddressesDebounced = useCallback(
    debounce((addressSearchQuery: string) => {
      const loadParams: MileageAddressListLoadParams = {
        searchQuery: addressSearchQuery,
      };

      mileageAddressList.load(loadParams, mileageAddressListAbort.signal);
    }, 500),
    []
  );

  useEffect(() => {
    if (!addressSearchQuery) {
      return;
    }

    loadAddressesDebounced(addressSearchQuery);

    return mileageAddressListAbort.revoke;
  }, [addressSearchQuery]);

  const addressSelectOptions = useMemo<
    DelegationAddAddressSelectOption[]
  >(() => {
    if (!addressSearchQuery) {
      const additionalAddress: SelectOption = {
        label: translations.addressAddLabel,
        value: null,
        onClick: open,
      };

      return [additionalAddress];
    }

    const cargoCompanyAddressListOptions: DelegationAddAddressSelectOption[] =
      props.cargoCompanyAddresses
        .filter((address) =>
          address.displayName
            .toLowerCase()
            .includes(addressSearchQuery.toLowerCase())
        )
        .map(delegationAddAddressFactory.createSelectOption);

    const passengerOptions = props.additionalAddresses
      .filter((address: DelegationAddAddress) => {
        return (
          !address.subText ||
          address.subText
            .toLocaleLowerCase()
            .includes(addressSearchQuery.toLocaleLowerCase())
        );
      })
      .map(delegationAddAddressFactory.createSelectOption);

    const mileageAddressListOptions = mileageAddressList.data.map(
      delegationAddAddressFactory.createSelectOptions
    );

    return [
      ...passengerOptions,
      ...cargoCompanyAddressListOptions,
      ...mileageAddressListOptions,
    ];
  }, [
    props.additionalAddresses,
    props.cargoCompanyAddresses,
    mileageAddressList.data,
    mileageAddressList.isLoading,
    addressSearchQuery,
    selectedAppLanguage,
  ]);

  const addressSelectOption = props.routeItem.address
    ? delegationAddAddressFactory.createSelectOption(props.routeItem.address)
    : null;

  const isAddressListLoading =
    !!addressSearchQuery && mileageAddressList.isLoading;

  const onAddressChange = (address: DelegationAddAddress | null) => {
    const addressCopy: DelegationAddAddress | null = address
      ? { ...address }
      : null;

    const item: DelegationAddRouteItem = {
      uuid: props.routeItem.uuid,
      address: addressCopy,
    };

    props.updateRouteItem(item);
  };

  const onAddressAdd = (address: DelegationAddressAddModalAddress) => {
    const addAddress: DelegationAddAddress = {
      displayName: `${address.zipCode}, ${address.town}, ${address.street} ${address.houseNumber}`,
      latitude: address.latitude,
      longitude: address.longitude,
      apartmentNumber: address.apartmentNumber,
      houseNumber: address.houseNumber,
      street: address.street,
      town: address.town,
      zipCode: address.zipCode,
    };

    const item: DelegationAddRouteItem = {
      uuid: props.routeItem.uuid,
      address: addAddress,
    };

    props.updateRouteItem(item);
  };

  return (
    <div className="delegation_add_route_list_item">
      <div className="delegation_add_route_list_item__top_row">
        <button
          className="delegation_add_route_list_item__drag_button"
          title={translations.dragButtonTitle}
          {...props.dragHandleProps}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </button>
        <div className="delegation_add_route_list_item__index">
          {props.routeIndex + 1}
        </div>
        <SingleSelectComponent
          onChange={(value) => onAddressChange(value?.value)}
          options={addressSelectOptions}
          defaultValue={addressSelectOption}
          value={addressSelectOption}
          placeholder={translations.selectPlaceholder}
          isLoading={isAddressListLoading}
          isDisabled={mileageAddressList.isError}
          maxMenuHeight={500}
          isSearchable
          inputValue={addressSearchQuery}
          onInputChange={setAddressSearchQuery}
          isClearable
          onBlur={props.onBlur}
          noOptionsMessage={(inputValue) => {
            if (inputValue) {
              return translations.searchNoOptionsMessage;
            }
            return translations.searchTipMessage;
          }}
          idForTesting={props.idForTesting}
          classNames={{ root: "delegation_add_route_list_item__address" }}
        />
        <ButtonComponent
          onClick={props.deleteRouteItem}
          classNames={{
            root: "delegation_add_route_list_item__action_button",
          }}
          idForTesting={`delegation-add-route-item-${props.routeItem.uuid}-delete-button`}
        >
          <FontAwesomeIcon icon={faTrash} />
        </ButtonComponent>
      </div>
      <DelegationAddressAddModalComponent
        isOpen={isOpen}
        onClose={close}
        onAddNewAddress={onAddressAdd}
      />
    </div>
  );
};

export default DelegationAddRouteItemComponent;
