import { FC, useState, useEffect } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../common/components/modal/modal.component";
import orderDetailsChangeExternalIdHelper from "./common/order-details-change-cargo-external-id.helper";
import FormComponent from "../../../../common/components/form/form.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderDetailsChangeCargoExternalIdFormValidationResults from "./common/types/order-details-change-cargo-external-id-data-validation.results";
import orderDetailsChangeCargoExternalIdHelper from "./common/order-details-change-cargo-external-id.helper";
import OrderDetailsChangeCargoExternalIdFormData from "./common/types/order-details-change-cargo-external-id-form-data";
import orderDetailsChangeCargoExternalIdFactory from "./common/factory/order-details-change-cargo-external-id.factory";
import orderDetailsChangeCargoExternalIdApiService from "./common/api/order-details-change-cargo-external-id-api.service";
import HttpResponse from "../../../../common/utils/http/http.response";
import notificationService from "../../../../common/utils/notification/notification.service";
import orderDetailsChangeCargoExternalIdValidationService from "./common/order-details-change-cargo-external-id-form-validation.service";

type OrderDetailsChangeCargoExternalIdModalProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  defaultValue: string;
  orderUuid: string;
  onSuccess: () => void;
};

const OrderDetailsChangeCargoExternalIdModal: FC<
  OrderDetailsChangeCargoExternalIdModalProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().changeCargoExternalId;

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [formData, setFormData] =
    useState<OrderDetailsChangeCargoExternalIdFormData>({
      cargoExternalId: props.defaultValue,
    });

  const [formValidationResults, setFormValidationResults] =
    useState<OrderDetailsChangeCargoExternalIdFormValidationResults>(
      orderDetailsChangeExternalIdHelper.getDefaultFormValidationResults()
    );

  useEffect(() => {
    if (formData.cargoExternalId !== props.defaultValue) {
      setFormData({ cargoExternalId: props.defaultValue });
    }
  }, [props.defaultValue]);

  const setCargoExternalId = (
    value: OrderDetailsChangeCargoExternalIdFormData["cargoExternalId"]
  ) => setFormData({ cargoExternalId: value });

  const validateCargoExternalId = () => {
    const validationResult =
      orderDetailsChangeCargoExternalIdValidationService.validateCargoExternalId(
        formData.cargoExternalId
      );

    setFormValidationResults((curr) => ({
      ...curr,
      cargoExternalId: validationResult,
    }));

    return validationResult.isValid;
  };

  const handleSubmitResponse = (response: HttpResponse) => {
    if (response.status === 201) {
      notificationService.success(
        translations.successUpdateCargoExternalIdNotificationText
      );
      props.onClose();
      props.onSuccess();

      return;
    }

    notificationService.error(
      translations.failureUpdateCargoExternalIdNotificationText
    );
  };

  const submitForm = () => {
    const isFormValid = validateCargoExternalId();

    if (!isFormValid) {
      return;
    }

    setIsFormSubmitting(true);

    const request =
      orderDetailsChangeCargoExternalIdFactory.createChangeCargoExternalIdRequest(
        formData
      );

    orderDetailsChangeCargoExternalIdApiService
      .updateCargoExternalId(props.orderUuid, request)
      .then(handleSubmitResponse)
      .finally(() => setIsFormSubmitting(false));
  };

  const onModalClose = () => {
    props.onClose();
    setFormValidationResults(
      orderDetailsChangeCargoExternalIdHelper.getDefaultFormValidationResults()
    );
  };

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={onModalClose}
      actions={[
        <ButtonComponent
          onClick={submitForm}
          isDisabled={isFormSubmitting}
          type="primary"
          idForTesting="order-details-change-cargo-external-id-submit-button"
        >
          {translations.form.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          onClick={onModalClose}
          isDisabled={isFormSubmitting}
          type="brand"
          idForTesting="order-details-change-cargo-external-id-reject-button"
        >
          {translations.form.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <FormComponent onSubmit={submitForm}>
        <FormFieldComponent
          label={translations.form.cargoExternalIdLabel}
          isRequired
          errorMessage={formValidationResults.cargoExternalId.errorMessage}
        >
          <InputComponent
            placeholder={translations.form.cargoExternalIdPlaceholder}
            value={formData.cargoExternalId}
            onChange={setCargoExternalId}
            onBlur={validateCargoExternalId}
            hasError={!!formValidationResults.cargoExternalId.errorMessage}
            idForTesting="cargo-external-id"
          />
        </FormFieldComponent>
      </FormComponent>
    </ModalComponent>
  );
};

export default OrderDetailsChangeCargoExternalIdModal;
