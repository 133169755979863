import AppHeaderUserChangePasswordRequest from "../api/app-header-user-change-password.request";
import AppHeaderUserChangePasswordFormData from "../types/app-header-user-change-password-form-data";

const createChangePasswordRequest = (
  formData: AppHeaderUserChangePasswordFormData
): AppHeaderUserChangePasswordRequest => {
  const request: AppHeaderUserChangePasswordRequest = {
    old_password: formData.currentPassword,
    new_password: formData.newPassword,
  };

  return request;
};

const appHeaderUserChangePasswordFactory = {
  createChangePasswordRequest,
};

export default appHeaderUserChangePasswordFactory;
