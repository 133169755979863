import { FC, ReactNode } from "react";
import CardComponent from "../../../../../common/components/card/card.component";
import MapComponent from "../../../../../common/components/map/map.component";
import MapRoute from "../../../../../common/components/map/types/map-route";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import NoticeBoxComponent from "../../../../../common/components/notice-box/notice-box.component";
import NoticeBoxType from "../../../../../common/components/notice-box/notice-box-type";
import OrderJoinOrderDetailsRouteComponent from "../../order-details/order-join-order-details-route.component";
import OrderJoinOrderDetails from "../types/order-join-order-details";
import OrderJoinOrderDetailsDriversComponent from "../../drivers/order-join-order-details-drivers.component";
import OrderJoinOrderDetailsPassengersComponent from "../../order-details/order-join-order-details.component";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";

type OrderJoinOrderDetailsProps = {
  headingText: string;
  mapRoutes: MapRoute[];
  mapMarkers: MapMarker[];
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  details: OrderJoinOrderDetails | null;
  selector?: ReactNode;
};

const OrderJoinOrderDetailsComponent: FC<OrderJoinOrderDetailsProps> = (
  props
) => {
  const translations = orderTranslationsHelper.getOrderJoinTranslations();

  const isContentVisible = !props.isLoading && !props.isError && props.details;

  const Error = (
    <NoticeBoxComponent
      type={NoticeBoxType.DANGER}
      classNames={{ root: "mt-3" }}
    >
      {props.errorMessage}
    </NoticeBoxComponent>
  );

  const orderIdLabel = translations.orderIdTemplateText.replace(
    "#{cargoInternalOrderId}",
    String(props.details?.orderId)
  );

  const Content = props.details && (
    <>
      <div className="mt-2">{orderIdLabel}</div>
      <div className="order_join_drivers">
        <div>
          <OrderJoinOrderDetailsDriversComponent
            drivers={props.details.drivers}
          />
        </div>
      </div>
      <div>
        <OrderJoinOrderDetailsPassengersComponent
          passengers={props.details.passengers}
        />
        <OrderJoinOrderDetailsRouteComponent
          waypoints={props.details.routeWaypoints}
        />
      </div>
    </>
  );

  return (
    <CardComponent
      header={{ title: props.headingText }}
      classNames={{ root: "order_join_details h-100" }}
      isLoading={props.isLoading}
    >
      <div className="order_join_details__map_wrapper">
        <MapComponent
          markers={props.mapMarkers}
          routes={props.mapRoutes}
          autoFit
          autoFitOnUpdate
        />
      </div>
      {props.selector && <div>{props.selector}</div>}
      {isContentVisible && Content}
      {props.isError && Error}
    </CardComponent>
  );
};

export default OrderJoinOrderDetailsComponent;
