import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import dateService from "../../../../../../common/utils/date/date.service";
import TaxiTransferedOrderSettlementListingTableColumn from "../types/taxi-transfered-order-settlement-listing-table-column";
import TaxiTransferedOrderSettlementListingItem from "../types/taxi-transfered-order-settlement-listing-item";
import TaxiTransferedOrderSettlementListingTableRow from "../types/taxi-transfered-order-settlement-listing-table-row";
import taxiTransferedOrderSettlementListingHelper from "../helper/taxi-transfered-order-settlement-listing.helper";
import TaxiTransferedOrderSettlementListingTableStatusComponent from "./status/taxi-transfered-order-settlement-listing-table-status.component";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import { faCircleInfo, faEdit, faPen } from "@fortawesome/free-solid-svg-icons";
import billingRoutesHelper from "../../../../../billings/common/routes/billing-routes.helper";

const getColumns = (): TaxiTransferedOrderSettlementListingTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .table.headers;

  return [
    {
      accessor: "billingStatus",
      header: "",
      title: "",
      colSpan: 2,
    },
    {
      accessor: "orderStartDate",
      header: translations.orderStartDateLabel,
      title: translations.orderStartDateTitle,
      colSpan: 10,
    },
    {
      accessor: "orderTransferDate",
      header: translations.orderTransferDateLabel,
      title: translations.orderTransferDateTitle,
      colSpan: 10,
    },
    {
      accessor: "internalOrderId",
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      colSpan: 5,
    },
    {
      accessor: "producerTaxi",
      header: translations.producerTaxiLabel,
      title: translations.producerTaxiTitle,
      colSpan: 10,
    },
    {
      accessor: "consumerTaxi",
      header: translations.consumerTaxiLabel,
      title: translations.consumerTaxiTitle,
      colSpan: 10,
    },
    {
      accessor: "contractName",
      header: translations.contractNameLabel,
      title: translations.contractNameTitle,
      colSpan: 10,
    },
    {
      accessor: "billingModel",
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      colSpan: 6,
    },
    {
      accessor: "distance",
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      colSpan: 10,
    },
    {
      accessor: "baseDistanceRate",
      header: translations.baseDistanceRateLabel,
      title: translations.baseDistanceRateTitle,
      colSpan: 10,
    },
    {
      accessor: "amountForDistance",
      header: translations.amountForDistanceLabel,
      title: translations.amountForDistanceTitle,
      colSpan: 10,
    },
    {
      accessor: "stopCost",
      header: translations.stopoverCostLabel,
      title: translations.stopoverCostTitle,
      colSpan: 10,
    },
    {
      accessor: "tollRoadsCost",
      header: translations.tollRoadsCostLabel,
      title: translations.tollRoadsCostTitle,
      colSpan: 10,
    },
    {
      accessor: "sumOfBonuses",
      header: translations.sumOfBonusesLabel,
      title: translations.sumOfBonusesTitle,
      colSpan: 5,
    },
    {
      accessor: "sumOfPenalties",
      header: translations.sumOfPenaltiesLabel,
      title: translations.sumOfPenaltiesTitle,
      colSpan: 5,
    },
    {
      accessor: "totalCost",
      header: translations.totalCostLabel,
      title: translations.totalCostTitle,
      colSpan: 9,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 9,
    },
  ];
};

const getInternalOrderIdLabel = (internalOrderId: number): string => {
  return String(internalOrderId);
};

const getInternalOrderIdTitle = (internalOrderId: number): string => {
  return String(internalOrderId);
};

const getDistanceLabel = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceTitle = (distance: number): string => {
  return `${distance} km`;
};

const getBaseDistanceRateLabel = (baseDistanceRate: number): string => {
  return `${String(baseDistanceRate.toFixed(2))} PLN/km`;
};

const getBaseDistanceRateTitle = (baseDistanceRate: number): string => {
  return `${String(baseDistanceRate.toFixed(2))} PLN/km`;
};

const getStopoverCostLabel = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getStopoverCostTitle = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getTollRoadsCostLabel = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getTollRoadsCostTitle = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getAmountForDistanceLabel = (amountForDistance: number): string => {
  return `${String(amountForDistance.toFixed(2))} PLN`;
};

const getAmountForDistanceTitle = (amountForDistance: number): string => {
  return `${String(amountForDistance.toFixed(2))} PLN`;
};

const getTotalCostLabel = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getTotalCostTitle = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getSumOfBonusesLabel = (
  sumOfBonuses: TaxiTransferedOrderSettlementListingItem["sumOfBonuses"]
): string => {
  const template =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .table.sumOfBonusesLabelTemplateText;

  return template.replace("#{cost}", String(sumOfBonuses));
};

const getSumOfBonusesTitle = (
  sumOfBonuses: TaxiTransferedOrderSettlementListingItem["sumOfBonuses"]
): string => {
  const template =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .table.sumOfBonusesTitleTemplateText;

  return template.replace("#{cost}", String(sumOfBonuses));
};

const getSumOfPenaltiesLabel = (
  sumOfPenalties: TaxiTransferedOrderSettlementListingItem["sumOfPenalties"]
): string => {
  const template =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .table.sumOfPenaltiesLabelTemplateText;

  return template.replace("#{cost}", String(sumOfPenalties));
};

const getSumOfPenaltiesTitle = (
  sumOfPenalties: TaxiTransferedOrderSettlementListingItem["sumOfPenalties"]
): string => {
  const template =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .table.sumOfPenaltiesTitleTemplateText;

  return template.replace("#{cost}", String(sumOfPenalties));
};

const getRow = (
  listingItem: TaxiTransferedOrderSettlementListingItem
): TaxiTransferedOrderSettlementListingTableRow => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .table;

  const startDateLabel = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const transferDateLabel = dateService.format(
    listingItem.orderTransferDate,
    "dd.mm.yyyy"
  );

  const transferDateTitle = dateService.format(
    listingItem.orderTransferDate,
    "dd.mm.yyyy"
  );

  const internalOrderIdLabel = getInternalOrderIdLabel(
    listingItem.internalOrderId
  );
  const internalOrderIdTitle = getInternalOrderIdTitle(
    listingItem.internalOrderId
  );

  const distanceLabel = getDistanceLabel(listingItem.distance);
  const distanceTitle = getDistanceTitle(listingItem.distance);

  const baseDistanceRateLabel = getBaseDistanceRateLabel(listingItem.baseDistanceRate);
  const baseDistanceRateTitle = getBaseDistanceRateTitle(listingItem.baseDistanceRate);

  const stopoverCostLabel = getStopoverCostLabel(listingItem.stopoverCost);
  const stopoverCostTitle = getStopoverCostTitle(listingItem.stopoverCost);

  const tollRoadsCostLabel = getTollRoadsCostLabel(listingItem.tollRoadsCost);
  const tollRoadsCostTitle = getTollRoadsCostTitle(listingItem.tollRoadsCost);

  const amountForDistanceLabel = getAmountForDistanceLabel(listingItem.amountForDistance);
  const amountForDistanceTitle = getAmountForDistanceTitle(listingItem.amountForDistance);

  const totalCostLabel = getTotalCostLabel(listingItem.totalCost);
  const totalCostTitle = getTotalCostTitle(listingItem.totalCost);

  const sumOfBonusesLabel = getSumOfBonusesLabel(listingItem.sumOfBonuses);
  const sumOfBonusesTitle = getSumOfBonusesTitle(listingItem.sumOfBonuses);

  const sumOfPenaltiesLabel = getSumOfPenaltiesLabel(
    listingItem.sumOfPenalties
  );
  const sumOfPenaltiesTitle = getSumOfPenaltiesTitle(
    listingItem.sumOfPenalties
  );

  const billingModelLabel =
    taxiTransferedOrderSettlementListingHelper.getBillingModelText(
      listingItem.billingModel
    );
  const billingModelTitle =
    taxiTransferedOrderSettlementListingHelper.getBillingModelText(
      listingItem.billingModel
    );

  const isEditButtonVisible = listingItem.canBeModified;

  return {
    id: listingItem.uuid,
    value: {
      billingStatus: (
        <TaxiTransferedOrderSettlementListingTableStatusComponent
          status={listingItem.status}
        />
      ),
      orderStartDate: <div title={startDateLabel}>{startDateTitle}</div>,
      orderTransferDate: (
        <div title={transferDateLabel}>{transferDateTitle}</div>
      ),
      internalOrderId: (
        <div title={internalOrderIdLabel}>{internalOrderIdTitle}</div>
      ),
      producerTaxi: (
        <div title={listingItem.producerTaxi}>{listingItem.producerTaxi}</div>
      ),
      consumerTaxi: (
        <div title={listingItem.consumerTaxi}>{listingItem.consumerTaxi}</div>
      ),
      contractName: (
        <div title={listingItem.contractName}>{listingItem.contractName}</div>
      ),
      billingModel: <div title={billingModelTitle}>{billingModelLabel}</div>,
      sumOfBonuses: <div title={sumOfBonusesTitle}>{sumOfBonusesLabel}</div>,
      sumOfPenalties: (
        <div title={sumOfPenaltiesTitle}>{sumOfPenaltiesLabel}</div>
      ),
      distance: <div title={distanceTitle}>{distanceLabel}</div>,
      baseDistanceRate: <div title={baseDistanceRateTitle}>{baseDistanceRateLabel}</div>,
      amountForDistance: <div title={amountForDistanceTitle}>{amountForDistanceLabel}</div>,
      stopCost: <div title={stopoverCostTitle}>{stopoverCostLabel}</div>,
      tollRoadsCost: <div title={tollRoadsCostTitle}>{tollRoadsCostLabel}</div>,
      totalCost: <div title={totalCostTitle}>{totalCostLabel}</div>,
      actions: (
        <div className="d-flex">
          {isEditButtonVisible && (
            <TableLinkButtonComponent
              icon={faEdit}
              to={billingRoutesHelper.getTaxiWithTaxiEditRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.taxiWithTaxiSettlementEditButtonTitle}
              idForTesting={`taxi-transfered-order-settlement-listing-table-item-${listingItem.uuid}-edit-button`}
            />
          )}
          <TableLinkButtonComponent
            icon={faCircleInfo}
            to={billingRoutesHelper.getTaxiWithTaxiDetailsRoute({
              billingUuid: listingItem.uuid,
            })}
            title={translations.taxiWithTaxiSettlementViewButtonTitle}
            idForTesting={`taxi-transfered-order-settlement-listing-table-item-${listingItem.uuid}-details-button`}
          />
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: TaxiTransferedOrderSettlementListingItem[]
): TaxiTransferedOrderSettlementListingTableRow[] => {
  return listingItems.map((listingItem) => getRow(listingItem));
};

const taxiTransferedOrderSettlementListingTableHelper = {
  getColumns,
  getRows,
};

export default taxiTransferedOrderSettlementListingTableHelper;
