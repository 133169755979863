import BillingsOrderForwardCargoTaxiRequest from "../../../../api/raily/billings/order-forward/cargo-taxi/billings-order-forward-cargo-taxi.request";
import BillingsOrderForwardCargoTaxiLoadParams from "./billings-order-forward-cargo-taxi-load-params";

const create = (
  params: BillingsOrderForwardCargoTaxiLoadParams
): BillingsOrderForwardCargoTaxiRequest => {
  return {
    forwardingId: params.forwardingUuid,
  };
};

const billingsOrderForwardCargoTaxiRequestFactory = {
  create,
};

export default billingsOrderForwardCargoTaxiRequestFactory;
