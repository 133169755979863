import MileageSettingsDetails, {
  MileageSettingsDetailsMileageNumberCreationModel,
} from "../../../../../common/services/mileage-settings/details/mileage-settings-details";
import mileageAddFormHelper from "./mileage-add-form.helper";
import MileageAddFormSettings, {
  MileageAddFormSettingsMileageNumberCreationModel,
} from "./types/mileage-add-form-settings";

export const createModel = (
  model: MileageSettingsDetailsMileageNumberCreationModel
): MileageAddFormSettingsMileageNumberCreationModel => {
  switch (model) {
    case MileageSettingsDetailsMileageNumberCreationModel.AUTO:
      return MileageAddFormSettingsMileageNumberCreationModel.AUTO;
    case MileageSettingsDetailsMileageNumberCreationModel.DICT:
      return MileageAddFormSettingsMileageNumberCreationModel.DICT;
    case MileageSettingsDetailsMileageNumberCreationModel.MANUAL:
      return MileageAddFormSettingsMileageNumberCreationModel.MANUAL;
  }
};

const createSettings = (
  settings: MileageSettingsDetails | null
): MileageAddFormSettings => {
  if (!settings) {
    return mileageAddFormHelper.getDefaultSettings();
  }

  return {
    isCardNumberRequired: settings.isCardNumberRequired,
    isMileageNumberRequired: settings.isMileageNumberRequired,
    mileageNumberCreationModel: createModel(
      settings.mileageNumberCreationModel
    ),
  };
};

const mileageAddFormFactory = {
  createSettings,
};

export default mileageAddFormFactory;
