import { FC, useEffect, useMemo, useState } from "react";
import {
  DropResult,
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import delegationTranslationsHelper from "../../../../../../languages/delegation-translations.helper";
import DelegationAddAddress from "../../types/delegation-add-address";
import DelegationAddRouteGroup from "../../types/delegation-add-route-group";
import delegationAddRoutesHelper from "./delegation-add-routes.helper";
import DelegationAddRoutesGroupComponent from "./delegation-add-routes-group.component";
import useCargoCompanyAddressList from "../../../../../../common/services/cargo-company/address/list/use-cargo-company-address-list";
import useAbort from "../../../../../../common/hooks/use-abort";
import CargoCompanyAddressListLoadParams from "../../../../../../common/services/cargo-company/address/list/cargo-company-address-list-load-params";
import delegationAddAddressFactory from "../../factory/delegation-add-address.factory";
import delegationAddHelper from "../../factory/delegation-add-helper";
import Row from "../../../../../../common/components/grid/row";
import delegationAddRouteFactory from "../../factory/delegation-add-route.factory";
import CargoCompanyMileageContractDetails from "../../../../../../common/services/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details";
import DelegationAddRouteItem from "../../types/delegation-add-route-item";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";

type DelegationAddRoutesProps = {
  cargoCompanyUuid: string | undefined;
  groups: DelegationAddRouteGroup[];
  onGroupsChange: (addresses: DelegationAddRouteGroup[]) => void;
  additionalAddresses: DelegationAddAddress[];
  isAddButtonDisabled: boolean;
  mileageContract: CargoCompanyMileageContractDetails | null;
  onBlur?: () => void;
  idForTesting?: string;
};

const DelegationAddRoutesComponent: FC<DelegationAddRoutesProps> = (props) => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().rides;

  const cargoCompanyAddressList = useCargoCompanyAddressList();
  const cargoCompanyAddressListAbort = useAbort();

  const [returnedGroupItemUuid, setReturnedGroupItemUuid] = useState<
    string | null
  >(null);

  const loadCargoCompanyAddresses = (cargoCompanyUuid: string) => {
    const loadParams: CargoCompanyAddressListLoadParams = {
      companyUuid: cargoCompanyUuid,
    };

    cargoCompanyAddressList.load(
      loadParams,
      cargoCompanyAddressListAbort.signal
    );
  };

  useEffect(() => {
    if (!props.cargoCompanyUuid) {
      return;
    }

    loadCargoCompanyAddresses(props.cargoCompanyUuid);

    return cargoCompanyAddressListAbort.revoke;
  }, [props.cargoCompanyUuid]);

  const cargoCompanyAddresses = useMemo(
    () =>
      cargoCompanyAddressList.data.map(
        delegationAddAddressFactory.createAddressFromCargoAddress
      ),
    [cargoCompanyAddressList.data]
  );

  const onAddReturnedGroupButtonClick = () => {
    if (props.groups[0].routes.length < 2) {
      return;
    }

    const reverseGroupRoutes: DelegationAddRouteItem[] = props.groups[0].routes
      .map((route) => {
        const addressCopy: DelegationAddAddress | null = route.address
          ? { ...route.address }
          : null;

        const newRoute: DelegationAddRouteItem = {
          uuid: uuidService.generate(),
          address: addressCopy,
        };

        return newRoute;
      })
      .reverse();

    const routeAddressess =
      delegationAddAddressFactory.createAddressesFromRouteItems(
        reverseGroupRoutes
      );

    const reversedWaypoints = delegationAddRouteFactory.createWaypoints(
      routeAddressess,
      props.groups.length
    );

    const newStartDate = props.groups[0].endDate
      ? new Date(props.groups[0].endDate)
      : new Date();

    const returnedGroupItem: DelegationAddRouteGroup = {
      ...delegationAddRoutesHelper.getNewEmptyGroup(),
      mapWaypoints: reversedWaypoints,
      routes: reverseGroupRoutes,
      startDate: newStartDate,
      vehicleType: props.groups[0].vehicleType,
    };

    const newGroup: DelegationAddRouteGroup[] = [
      ...props.groups,
      returnedGroupItem,
    ];

    props.onGroupsChange(newGroup);
    setReturnedGroupItemUuid(returnedGroupItem.uuid);
  };

  const onAddGroupButtonClick = () => {
    const newEmptyGroup = delegationAddRoutesHelper.getNewEmptyGroup();

    const newGroup: DelegationAddRouteGroup[] = [
      ...props.groups,
      newEmptyGroup,
    ];

    props.onGroupsChange(newGroup);
  };

  const updateRouteItem = (updatedRouteItem: DelegationAddRouteGroup) => {
    const routeToUpdate = props.groups.find(
      (route) => route.uuid === updatedRouteItem.uuid
    );

    if (!routeToUpdate) {
      return;
    }

    const newGroup: DelegationAddRouteGroup[] = [...props.groups];

    const indexOfRouteToUpdate = newGroup.indexOf(routeToUpdate);
    newGroup[indexOfRouteToUpdate] = updatedRouteItem;

    props.onGroupsChange(newGroup);
  };

  const deleteGroup = (routeItemUuid: DelegationAddRouteGroup["uuid"]) => {
    const newGroup: DelegationAddRouteGroup[] = props.groups.filter(
      (route) => route.uuid !== routeItemUuid
    );

    if (routeItemUuid === returnedGroupItemUuid) {
      setReturnedGroupItemUuid(null);
    }

    props.onGroupsChange(newGroup);
  };

  const onDragRouteGroupEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedGroups = delegationAddRoutesHelper.getReorderedRouteGroups(
      result.source.index,
      result.destination.index,
      props.groups
    );

    const min = Math.min(result.source.index, result.destination.index);
    const max = Math.max(result.source.index, result.destination.index);

    const updatedRoutes = reorderedGroups.map((group, index) => {
      if (index >= min && index <= max) {
        return { ...group, startDate: null };
      }
      return group;
    });

    props.onGroupsChange(updatedRoutes);
  };

  const isDraggingEnabled = props.groups.length > 1;

  return (
    <>
      <DragDropContext onDragEnd={onDragRouteGroupEnd}>
        <Droppable droppableId="delegation_add_routes_group_drop_area">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div>
                {props.groups.map((group, groupIndex, groups) => {
                  let minDate: Date | null;
                  let maxDate: Date | null;

                  if (groupIndex > 0) {
                    minDate = groups[groupIndex - 1].endDate;
                  }

                  if (groupIndex < groups.length - 1) {
                    const tempDate = groups[groupIndex + 1].startDate;

                    if (tempDate && group.duration) {
                      maxDate = delegationAddHelper.calculateDate(
                        tempDate,
                        -group.duration
                      );
                    } else {
                      maxDate = tempDate;
                    }
                  }

                  return (
                    <Draggable
                      key={group.uuid}
                      draggableId={group.uuid}
                      index={groupIndex}
                      isDragDisabled={!isDraggingEnabled}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <DelegationAddRoutesGroupComponent
                            group={group}
                            groupIndex={groupIndex}
                            onGroupChange={updateRouteItem}
                            deleteGroup={() => deleteGroup(group.uuid)}
                            additionalAddresses={props.additionalAddresses}
                            cargoCompanyAddresses={cargoCompanyAddresses}
                            dragHandleProps={provided.dragHandleProps}
                            mileageContract={props.mileageContract}
                            onBlur={props.onBlur}
                            idForTesting={props.idForTesting}
                            dateMax={maxDate}
                            dateMin={minDate}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Row>
        <div className="delegation_add_group_list_item__return_button">
          <ButtonComponent
            onClick={onAddGroupButtonClick}
            type="primary"
            title={translations.addNewButtonTitle}
            isDisabled={props.isAddButtonDisabled}
            idForTesting={"delegation-add-routes-group-new-button"}
          >
            {translations.addNewButtonLabel}
          </ButtonComponent>
        </div>
        <div className="delegation_add_group_list_item__return_button">
          <ButtonComponent
            onClick={onAddReturnedGroupButtonClick}
            type="primary"
            title={translations.addReturnGroupButtonTitle}
            isDisabled={
              props.groups.length < 1 ||
              props.groups[0].routes[0].address === null ||
              props.groups[0].routes[1].address === null ||
              !!returnedGroupItemUuid
            }
            idForTesting={"delegation-add-routes-return-address-button"}
          >
            {translations.addReturnGroupButtonLabel}
          </ButtonComponent>
        </div>
      </Row>
    </>
  );
};

export default DelegationAddRoutesComponent;
