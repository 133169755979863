import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import { useAppContext } from "../../../../../context/app.context";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import PassengerFormDataAddressComponent from "../../common/components/address/passenger-address.component";
import PassengerAddResponse from "../common/api/passenger-add-response";
import passengerAddFactory from "../common/passenger-add.factory";
import PassengerAddress from "../../common/types/passenger-address";
import PassengerDispatchSelectOption from "../../common/types/passenger-dispatch-select-option";
import PassengerAddViewBasicProps from "../common/types/passenger-add-view-basic-props";
import passengerAddByCargoApiService from "./common/api/passenger-add-by-cargo-api.service";
import passengerAddByCargoFormHelper from "./common/passenger-add-by-cargo-form.helper";
import passengerAddByCargoFactory from "./common/passenger-add-by-cargo.factory";
import PassengerAddByCargoFormData from "./common/types/passenger-add-by-cargo-form-data";
import PassengerAddByCargoFormValidationResults from "./common/types/passenger-add-by-cargo-form-validation.results";
import passengerAddByCargoFormValidationService from "./common/passenger-add-by-cargo-form-validation.service";
import UserCheckExistsResponse from "../../../common/api/user-check-exists.response";
import userApiService from "../../../common/api/user-api.service";
import MultiSelectComponent from "../../../../../common/components/form/select/multi-select/multi-select.component";
import useCargoCompanyDispatchList from "../../../../../common/services/cargo-company/dispatch/list/use-cargo-company-dispatch-list";
import useAbort from "../../../../../common/hooks/use-abort";
import CargoCompanyDispatchList from "../../../../../common/services/cargo-company/dispatch/list/cargo-company-dispatch-list";

type PassengerAddByCargoProps = PassengerAddViewBasicProps;

const PassengerAddByCargoComponent: FC<PassengerAddByCargoProps> = (props) => {
  const { selectedAppLanguage, user } = useAppContext();
  const navigate = useNavigate();

  const translations = userTranslationsHelper.getPassengerAddTranslations();

  const [isDispatchesFetching, setIsDispatchesFetching] = useState(false);

  const [dispatchesSelectOptions, setDispatchesSelectOptions] = useState<
    PassengerDispatchSelectOption[]
  >([]);

  const [formData, setFormData] = useState<PassengerAddByCargoFormData>(() => {
    return passengerAddByCargoFormHelper.getDefaultFormData();
  });

  const [formValidationResults, setValidationResults] =
    useState<PassengerAddByCargoFormValidationResults>(() => {
      return passengerAddByCargoFormHelper.getFormValidationResults();
    });

  const [isCheckUserExistsFetching, setIsCheckUserExistsFetching] =
    useState(false);

  const cargoCompanyDispatchList = useCargoCompanyDispatchList();
  const cargoCompanyDispatchListAbort = useAbort();

  const setUserExists = () => {
    const validationResult: PassengerAddByCargoFormValidationResults["username"] =
      {
        isValid: false,
        errorMessage:
          userTranslationsHelper.getUserAddTranslations()
            .userExistsValidationMessage,
      };

    setValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));
  };

  const handleUserExistsFetchResponseSuccess = (
    response: UserCheckExistsResponse
  ) => {
    const isUserExists = response.data;
    if (isUserExists) {
      setUserExists();
    }
  };

  const handleUserExistsFetchResponse = (response: UserCheckExistsResponse) => {
    if (response.status === 200) {
      handleUserExistsFetchResponseSuccess(response);
      return;
    }
  };

  const checkUserExists = (username: string) => {
    setIsCheckUserExistsFetching(true);
    userApiService
      .fetchIsUserExist(username)
      .then(handleUserExistsFetchResponse)
      .finally(() => setIsCheckUserExistsFetching(false));
  };

  const validateAndCheckUsernameExists = () => {
    if (!validateUsername()) {
      return;
    }

    checkUserExists(formData.username);
  };

  // temporary solution - remove after refactor
  useEffect(() => {
    const revalidationFields = Object.keys(formValidationResults).filter(
      (formKey) =>
        formValidationResults[
          formKey as keyof PassengerAddByCargoFormValidationResults
        ].isValid === false
    );

    revalidationFields.forEach((field) => {
      switch (field) {
        case "firstName":
          return validateFirstName();
        case "lastName":
          return validateLastName();
        case "username":
          return validateUsername();
        case "email":
          return validateEmail();
        case "mobile":
          return validateMobile();
        case "alternativeMobile":
          return validateAlternativeMobile();
        case "password":
          return validatePassword();
        case "passwordRepeat":
          return validatePasswordRepeat();
        case "addresses":
          return validateAddresses();
        case "dispatches":
          return validateDispatches();
      }
    });
  }, [selectedAppLanguage]);

  useEffect(() => {
    const cargoCompanyUuid =
      user?.aspects.cargoOfficer?.cargoCompanyUuid ||
      user?.aspects.dispatcher?.cargoCompanyUuid!;

    cargoCompanyDispatchList.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyDispatchListAbort.signal
    );

    return () => {
      cargoCompanyDispatchListAbort.revoke();
    };
  }, []);

  useEffect(() => {
    if (!cargoCompanyDispatchList.data) return;

    onDispatchesFetchSuccess(cargoCompanyDispatchList.data);
  }, [cargoCompanyDispatchList.data]);

  const onDispatchesFetchSuccess = (response: CargoCompanyDispatchList) => {
    const dispatchSelectOptions =
      passengerAddFactory.createDispatchSelectOptions(response.data);
    setDispatchesSelectOptions(dispatchSelectOptions);
  };

  const validateFirstName = () => {
    const validationResult =
      passengerAddByCargoFormValidationService.validateFirstName(
        formData.firstName
      );

    setValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateLastName = () => {
    const validationResult =
      passengerAddByCargoFormValidationService.validateLastName(
        formData.lastName
      );

    setValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateMobile = () => {
    const validationResult =
      passengerAddByCargoFormValidationService.validateMobile(formData.mobile);

    setValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult;
  };

  const validateAlternativeMobile = () => {
    const validationResult =
      passengerAddByCargoFormValidationService.validateAlternativeMobile(
        formData.alternativeMobile
      );

    setValidationResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult;
  };

  const validateEmail = () => {
    const validationResult =
      passengerAddByCargoFormValidationService.validateEmail(formData.email);

    setValidationResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUsername = async () => {
    const validationResult =
      await passengerAddByCargoFormValidationService.validateUsername(
        formData.username
      );

    setValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));

    return validationResult.isValid;
  };

  const validatePassword = () => {
    const validationResult =
      passengerAddByCargoFormValidationService.validatePassword(
        formData.password
      );

    setValidationResults((curr) => ({
      ...curr,
      password: validationResult,
    }));

    if (!!formData.passwordRepeat) {
      validatePasswordRepeat();
    }

    return validationResult.isValid;
  };

  const validatePasswordRepeat = () => {
    const validationResult =
      passengerAddByCargoFormValidationService.validatePasswordRepeat(
        formData.password,
        formData.passwordRepeat
      );

    setValidationResults((curr) => ({
      ...curr,
      passwordRepeat: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDispatches = (
    value: PassengerAddByCargoFormData["dispatches"] = formData.dispatches
  ) => {
    const validationResult =
      passengerAddByCargoFormValidationService.validateDispatches(value);

    setValidationResults((curr) => ({
      ...curr,
      dispatches: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAddresses = (
    value: PassengerAddByCargoFormData["addresses"] = formData.addresses
  ) => {
    const validationResult =
      passengerAddByCargoFormValidationService.validateAddresses(value);

    setValidationResults((curr) => ({
      ...curr,
      addresses: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsFormValid = () => {
    const isDispatchIdsValid = validateDispatches();
    const isEmailValid = validateEmail();
    const isUsernameValid = validateUsername();
    const isPasswordValid = validatePassword();
    const isPasswordRepeatValid = validatePasswordRepeat();
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const isMobileValid = validateMobile();
    const isMobileAlternativeValid = validateAlternativeMobile();
    const isAddressesValid = validateAddresses();

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isMobileValid &&
      isMobileAlternativeValid &&
      isPasswordValid &&
      isPasswordRepeatValid &&
      isDispatchIdsValid &&
      isAddressesValid &&
      isUsernameValid
    );
  };

  const submitForm = async () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid) return;

    const request =
      passengerAddByCargoFactory.createAddPassengerRequest(formData);

    passengerAddByCargoApiService
      .addPassenger(request)
      .then(handleSubmitResponse);
  };

  const navigateToListing = () => {
    const passengerListingRoute = userRoutesHelper.getPassengerListingRoute();
    navigate(passengerListingRoute);
  };

  const handleSubmitResponse = (response: PassengerAddResponse) => {
    if (response.status === 201) {
      notificationService.success(translations.successAddNotificationText);
      navigateToListing();
      return;
    }
    notificationService.error(translations.failureAddNotificationText);
  };

  const setFirstName = (
    firstName: PassengerAddByCargoFormData["firstName"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      firstName,
    }));
  };

  const setLastName = (lastName: PassengerAddByCargoFormData["lastName"]) => {
    setFormData((curr) => ({
      ...curr,
      lastName,
    }));
  };

  const setUsername = (username: PassengerAddByCargoFormData["username"]) => {
    setFormData((curr) => ({
      ...curr,
      username,
    }));
  };

  const setEmail = (email: PassengerAddByCargoFormData["email"]) => {
    setFormData((curr) => ({ ...curr, email }));
  };

  const setPassword = (password: PassengerAddByCargoFormData["password"]) => {
    setFormData((curr) => ({
      ...curr,
      password,
    }));
  };

  const setPasswordRepeat = (
    passwordRepeat: PassengerAddByCargoFormData["passwordRepeat"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      passwordRepeat,
    }));
  };

  const setMobile = (mobile: PassengerAddByCargoFormData["mobile"]) => {
    setFormData((curr) => ({
      ...curr,
      mobile,
    }));
  };
  const setAlternativeMobile = (
    alternativeMobile: PassengerAddByCargoFormData["alternativeMobile"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      alternativeMobile,
    }));
  };

  const addNewAddress = (newAddress: PassengerAddress) => {
    const newAddresses: PassengerAddByCargoFormData["addresses"] = [
      ...formData.addresses,
      newAddress,
    ];

    setFormData((curr) => ({
      ...curr,
      addresses: newAddresses,
    }));

    validateAddresses(newAddresses);
  };

  const removeAddress = (index: number) => {
    const filteredAddresses: PassengerAddress[] = formData.addresses.filter(
      (_address, addressIndex: number) => {
        return index !== addressIndex;
      }
    );

    setFormData((curr) => ({
      ...curr,
      addresses: filteredAddresses,
    }));

    validateAddresses(filteredAddresses);
  };

  const setDispatches = (value: PassengerAddByCargoFormData["dispatches"]) => {
    setFormData((curr) => ({
      ...curr,
      dispatches: value,
    }));
  };

  const onDispatchesChange = (
    value: PassengerAddByCargoFormData["dispatches"]
  ) => {
    setDispatches(value);
    validateDispatches(value);
  };

  return (
    <div className="user_passenger_add">
      <HeadingComponent
        title={translations.header.headingText}
        actions={props.changeViewButtons}
      />
      <CardComponent>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.dispatchesLabel}
              isRequired
              classNames={{ root: "mt-0" }}
              errorMessage={formValidationResults.dispatches.errorMessage}
            >
              <MultiSelectComponent
                isLoading={isDispatchesFetching}
                placeholder={translations.form.dispatchesPlaceholder}
                onChange={(option) => onDispatchesChange(option)}
                options={dispatchesSelectOptions}
                value={formData.dispatches}
                hasError={!!formValidationResults.dispatches.errorMessage}
                idForTesting={`dispatches-select`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.emailLabel}
              isRequired
              errorMessage={formValidationResults.email.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.emailPlaceholder}
                value={formData.email}
                onChange={setEmail}
                onBlur={validateEmail}
                hasError={!!formValidationResults.email.errorMessage}
                idForTesting={`email-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.usernameLabel}
              isRequired
              errorMessage={formValidationResults.username.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.usernamePlaceholder}
                value={formData.username}
                onChange={setUsername}
                onBlur={validateAndCheckUsernameExists}
                hasError={!!formValidationResults.username.errorMessage}
                idForTesting={`username-input`}
                isDisabled={isCheckUserExistsFetching}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.firstNameLabel}
              isRequired
              errorMessage={formValidationResults.firstName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.firstNamePlaceholder}
                value={formData.firstName}
                onChange={setFirstName}
                onBlur={validateFirstName}
                hasError={!!formValidationResults.firstName.errorMessage}
                idForTesting={`first-name-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.lastNameLabel}
              isRequired
              errorMessage={formValidationResults.lastName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.lastNamePlaceholder}
                value={formData.lastName}
                onChange={setLastName}
                onBlur={validateLastName}
                hasError={!!formValidationResults.lastName.errorMessage}
                idForTesting={`last-name-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.mobileLabel}
              isRequired
              errorMessage={formValidationResults.mobile.errorMessage}
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.mobilePlaceholder}
                phoneNumber={formData.mobile}
                onChange={setMobile}
                onBlur={validateMobile}
                hasError={!!formValidationResults.mobile.errorMessage}
                idForTesting={`mobile`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.alternativeMobileLabel}
              errorMessage={
                formValidationResults.alternativeMobile.errorMessage
              }
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.alternativeMobilePlaceholder}
                phoneNumber={formData.alternativeMobile}
                onChange={setAlternativeMobile}
                onBlur={validateAlternativeMobile}
                hasError={
                  !!formValidationResults.alternativeMobile.errorMessage
                }
                idForTesting={`alternative-mobile`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.passwordLabel}
              isRequired
              errorMessage={formValidationResults.password.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.passwordPlaceholder}
                value={formData.password}
                onChange={setPassword}
                onBlur={validatePassword}
                type={"password"}
                hasError={!!formValidationResults.password.errorMessage}
                idForTesting={`password-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.passwordRepeatLabel}
              isRequired
              errorMessage={formValidationResults.passwordRepeat.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.passwordRepeatPlaceholder}
                value={formData.passwordRepeat}
                onChange={setPasswordRepeat}
                onBlur={validatePasswordRepeat}
                type={"password"}
                hasError={!!formValidationResults.passwordRepeat.errorMessage}
                idForTesting={`password-repeat-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column>
            <FormFieldComponent
              label={translations.form.addressLabel}
              isRequired
              errorMessage={formValidationResults.addresses.errorMessage}
            >
              <PassengerFormDataAddressComponent
                onAddressAdd={addNewAddress}
                onAddressRemove={removeAddress}
                addresses={formData.addresses}
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        title={translations.form.submitButtonTitle}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};
export default PassengerAddByCargoComponent;
