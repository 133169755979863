import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import MileageListingFilter from "../common/types/mileage-listing-filter";
import MileageListingRouteQueryParams from "../common/types/mileage-listing-filter-route-params";
import MileageListingSortKey from "../common/types/mileage-listing-sort-key";
import mileageListingFiltersService from "./mileage-listing-filters.service";

const useMileageListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<MileageListingRouteQueryParams>();

  const getFilters = (): MileageListingFilter[] => {
    return mileageListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return mileageListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return mileageListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): MileageListingSortKey | undefined => {
    return mileageListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: MileageListingFilter[],
    sortKey: MileageListingSortKey,
    page: number,
    pageSize: number
  ) => {
    const routeQueryParams =
      mileageListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getPage,
    getPageSize,
    getFilters,
    saveFilters,
    getSortKey,
  };
};

export default useMileageListingFiltersDao;
