import userTranslationsHelper from "../../../../../../languages/user-translations.helper";

const getHeadingText = (driverName: string) => {
  const translations =
    userTranslationsHelper.getDriverContractListingTranslations();

  if (!driverName) {
    return translations.header.headingLabel;
  }

  return translations.header.headingTextWithName.replace(
    "#{driverName}",
    driverName
  );
};

const driverContractListingHelper = {
  getHeadingText,
};

export default driverContractListingHelper;
