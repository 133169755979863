import classNames from "classnames";
import { FC } from "react";
import TaxiTransferedOrderSettlementListingItemBillingStatus from "../../types/taxi-transfered-order-settlement-listing-item-billing-status";

type TaxiTransferedOrderSettlementTableStatusProps = {
  status: TaxiTransferedOrderSettlementListingItemBillingStatus | undefined;
};

const TaxiTransferedOrderSettlementListingTableStatusComponent: FC<
  TaxiTransferedOrderSettlementTableStatusProps
> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case TaxiTransferedOrderSettlementListingItemBillingStatus.CREATED:
        return "created";
      case TaxiTransferedOrderSettlementListingItemBillingStatus.ACCEPTED:
        return "accepted";
      case TaxiTransferedOrderSettlementListingItemBillingStatus.REJECTED:
        return "rejected";
      case TaxiTransferedOrderSettlementListingItemBillingStatus.REOPENED:
        return "reopened";
      case TaxiTransferedOrderSettlementListingItemBillingStatus.REOPEN_REQUEST:
        return "reopen_request_sent";
      default:
        return "";
    }
  };

  const statusClassname = getClassnameByStatus();

  const containerClassNames = classNames(
    "taxi_transfered_order_settlements_listing_table_status",
    statusClassname
  );

  return <div className={containerClassNames}></div>;
};

export default TaxiTransferedOrderSettlementListingTableStatusComponent;
