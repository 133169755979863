import { AnySchema, Schema } from "joi";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import FormValidationResult from "./types/form-validation-result";

const getValidationMessageTemplates = () => {
  return appTranslationsHelper.getFormValidationMessageTemplates()
    .joiErrorTemplates;
};

const getValidationCustomMessages = () => {
  const validationMessages =
    appTranslationsHelper.getFormValidationMessageTemplates();

  const { joiErrorTemplates, ...rest } = validationMessages;

  return rest;
};

const defaultValidationResult: FormValidationResult = {
  isValid: true,
  errorMessage: "",
};

const ok = (): FormValidationResult => {
  return {
    isValid: true,
    errorMessage: "",
  };
};

const bad = (errorMessage: string): FormValidationResult => {
  return {
    isValid: false,
    errorMessage,
  };
};

const createSchema = (joiSchema: AnySchema): Schema => {
  const messageTemplates = getValidationMessageTemplates();

  return joiSchema.messages(messageTemplates);
};

const validate = (
  candidate: any,
  validationSchema: AnySchema
): FormValidationResult => {
  const validationError =
    createSchema(validationSchema).validate(candidate).error?.message;

  if (validationError) {
    return bad(validationError);
  }
  return ok();
};

const formValidationService = {
  validate,
  getValidationCustomMessages,
  defaultValidationResult,
  bad,
  ok,
};

export default formValidationService;
