import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-sort-definition";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import TaxiListingFilter, {
  TaxiListingAddressFilter,
  TaxiListingCompanyIdFilter,
  TaxiListingDisplayNameFilter,
  TaxiListingNameFilter,
  TaxiListingNationalCourtRegisterFilter,
  TaxiListingNotesFilter,
  TaxiListingTaxNumberFilter,
} from "../common/types/taxi-listing-filter";
import TaxiListingFilterType from "../common/types/taxi-listing-filter-type";
import TaxiListingSortKey from "../common/types/taxi-listing-sort-key";

const taxiListingFilterHelper = () => {
  const translations = taxiTranslationsHelper.getTaxiListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.companyDisplayNameAscLabel,
        value: TaxiListingSortKey.TAXI_COMPANY_DISPLAY_NAME_ASC,
      },
      {
        label: translations.sort.companyDisplayNameDescLabel,
        value: TaxiListingSortKey.TAXI_COMPANY_DISPLAY_NAME_DESC,
      },
      {
        label: translations.sort.companyNameAscLabel,
        value: TaxiListingSortKey.TAXI_COMPANY_NAME_ASC,
      },
      {
        label: translations.sort.companyNameDescLabel,
        value: TaxiListingSortKey.TAXI_COMPANY_NAME_DESC,
      },
    ];
  };

  const getSelectDataForName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.companyNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForDisplayName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.displayNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForCompanyId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.companyIdTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.addressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForNationalCourtRegister = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.nationalCourtRegisterTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForNotes = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.notesTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForTaxNumber = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.taxNumberTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: TaxiListingFilterType,
    query: string
  ) => {
    const options: Record<
      TaxiListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [TaxiListingFilterType.NAME]: getSelectDataForName,
      [TaxiListingFilterType.DISPLAY_NAME]: getSelectDataForDisplayName,
      [TaxiListingFilterType.COMPANY_ID]: getSelectDataForCompanyId,
      [TaxiListingFilterType.ADDRESS]: getSelectDataForAddress,
      [TaxiListingFilterType.NATIONAL_COURT_REGISTER]:
        getSelectDataForNationalCourtRegister,
      [TaxiListingFilterType.NOTES]: getSelectDataForNotes,
      [TaxiListingFilterType.TAX_NUMBER]: getSelectDataForTaxNumber,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeForName = (
    filterValue: TaxiListingNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.companyNameLabel,
      translations.filters.companyNameTitle
    );
  };

  const getBadgeForAddress = (
    filterValue: TaxiListingNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.addressLabel,
      translations.filters.addressTitle
    );
  };

  const getBadgeForCompanyId = (
    filterValue: TaxiListingNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.companyIdLabel,
      translations.filters.companyIdTitle
    );
  };

  const getBadgeForNationalCourtRegister = (
    filterValue: TaxiListingNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.nationalCourtRegisterLabel,
      translations.filters.nationalCourtRegisterTitle
    );
  };

  const getBadgeForDisplayName = (
    filterValue: TaxiListingNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.companyDisplayNameLabel,
      translations.filters.companyDisplayNameTitle
    );
  };

  const getBadgeForNotes = (
    filterValue: TaxiListingNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.notesLabel,
      translations.filters.notesTitle
    );
  };

  const getBadgeForTaxNumber = (
    filterValue: TaxiListingNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.taxNumberLabel,
      translations.filters.taxNumberTitle
    );
  };

  const getBadgeDefinitionByType = (
    filterType: TaxiListingFilterType,
    filterValue: TaxiListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      TaxiListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [TaxiListingFilterType.NAME]: () =>
        getBadgeForName(filterValue as TaxiListingNameFilter["value"]),
      [TaxiListingFilterType.DISPLAY_NAME]: () =>
        getBadgeForDisplayName(
          filterValue as TaxiListingDisplayNameFilter["value"]
        ),
      [TaxiListingFilterType.ADDRESS]: () =>
        getBadgeForAddress(filterValue as TaxiListingAddressFilter["value"]),
      [TaxiListingFilterType.COMPANY_ID]: () =>
        getBadgeForCompanyId(
          filterValue as TaxiListingCompanyIdFilter["value"]
        ),
      [TaxiListingFilterType.NATIONAL_COURT_REGISTER]: () =>
        getBadgeForNationalCourtRegister(
          filterValue as TaxiListingNationalCourtRegisterFilter["value"]
        ),
      [TaxiListingFilterType.NOTES]: () =>
        getBadgeForNotes(filterValue as TaxiListingNotesFilter["value"]),
      [TaxiListingFilterType.TAX_NUMBER]: () =>
        getBadgeForTaxNumber(
          filterValue as TaxiListingTaxNumberFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: TaxiListingFilterType
  ): ListingFilterDefinitionOption<TaxiListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<TaxiListingFilter>[] => {
      return Object.values(TaxiListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<TaxiListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default taxiListingFilterHelper;
