import {
  TransportingOrderDeleteForwardingNotAcceptableResponse,
  TransportingOrderDeleteForwardingNotAcceptableResponseErrorType,
} from "../../api/raily/transporting-order/delete-forwarding/transporting-order-delete-forwarding.response";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import TransportingOrderDeleteForwardingRequest from "../../api/raily/transporting-order/delete-forwarding/transporting-order-delete-forwarding.request";
import transportingOrderApiService from "../../api/raily/transporting-order/transporting-order-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import TransportingOrderDeleteForwardingError from "./delete-forwarding/transporting-order-delete-forwarding-error";
import TransportingOrderDeleteFowardingParams from "./delete-forwarding/transporting-order-delete-forwarding-params";
import transportingOrderDeleteForwardingRequestFactory from "./delete-forwarding/transporting-order-delete-forwarding-request.factory";
import transportingOrderDeleteForwardingErrorFactory from "./delete-forwarding/transporting-order-delete-forwarding-error.factory";

const handleDeleteForwardingError = (
  error: HttpError | TransportingOrderDeleteForwardingError
) => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createErrorsFromNotAcceptableResponse = (
  response: TransportingOrderDeleteForwardingNotAcceptableResponse
): TransportingOrderDeleteForwardingError => {
  switch (response.data.errorType) {
    case TransportingOrderDeleteForwardingNotAcceptableResponseErrorType.ABANDONED_ORDER:
      return transportingOrderDeleteForwardingErrorFactory.createAbandonedOrderError();
    case TransportingOrderDeleteForwardingNotAcceptableResponseErrorType.DRIVER_ASSIGNED:
      return transportingOrderDeleteForwardingErrorFactory.createDriverAssignedError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const deleteForwarding = async (
  params: TransportingOrderDeleteFowardingParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TransportingOrderDeleteForwardingRequest =
    transportingOrderDeleteForwardingRequestFactory.create(params);

  try {
    const response = await transportingOrderApiService().deleteForwarding(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createErrorsFromNotAcceptableResponse(
        response as TransportingOrderDeleteForwardingNotAcceptableResponse
      );
    }

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TransportingOrderDeleteForwardingError;

    throw handleDeleteForwardingError(error);
  }
};

const transportingOrderService = {
  deleteForwarding,
};

export default transportingOrderService;
