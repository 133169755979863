import TaxiDetailsResponse, {
  TaxiDetailsResponseCargoContractMaintenancePolicy,
  TaxiDetailsResponseItemFleetPartner,
  TaxiDetailsResponseItem,
} from "../../../api/raily/taxi-corporation/details/taxi-details.response";
import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import TaxiCorporationDetails, {
  TaxiDetailsFleetPartner,
} from "./taxi-corporation-details";

const createContractMaintenancePolicy = (
  contractMaintenancePolicy: TaxiDetailsResponseCargoContractMaintenancePolicy
): ContractMaintenancePolicy => {
  switch (contractMaintenancePolicy) {
    case TaxiDetailsResponseCargoContractMaintenancePolicy.AUCTION:
      return ContractMaintenancePolicy.AUCTION;
    case TaxiDetailsResponseCargoContractMaintenancePolicy.BASIC:
      return ContractMaintenancePolicy.BASIC;
  }
};

const createFleetPartner = (
  fleetPartner: TaxiDetailsResponseItemFleetPartner
): TaxiDetailsFleetPartner => {
  return {
    modificationDate: fleetPartner.modifiedAt,
    modifiedBy: fleetPartner.modifiedBy,
    creationDate: fleetPartner.createdAt,
    createdBy: fleetPartner.createdBy,
    uuid: fleetPartner.id,
    taxiCorporationUuid: fleetPartner.taxiCorporationId,
    name: fleetPartner.name,
  };
};

const createFleetPartners = (
  fleetPartners: TaxiDetailsResponseItemFleetPartner[]
) => {
  return fleetPartners.map(createFleetPartner);
};

const createDataItem = (
  responseDataItem: TaxiDetailsResponseItem
): TaxiCorporationDetails => {
  return {
    taxNumber: responseDataItem.taxId,
    companyId: responseDataItem.regon,
    nationalCourtRegister: responseDataItem.krsNo,
    name: responseDataItem.companyName,
    displayName: responseDataItem.displayName,
    address: responseDataItem.headquartersAddressString,
    contractMaintenancePolicy: createContractMaintenancePolicy(
      responseDataItem.contractMaintenancePolicy
    ),
    notes: responseDataItem.notes,
    uuid: responseDataItem.id,
    modificationDate: responseDataItem.modifiedAt,
    modifiedBy: responseDataItem.modifiedBy,
    creationDate: responseDataItem.createdAt,
    createdBy: responseDataItem.createdBy,
    fleetPartners: createFleetPartners(responseDataItem.fleetPartners),
  };
};

const create = (response: TaxiDetailsResponse): TaxiCorporationDetails => {
  return createDataItem(response.data);
};

const taxiCorporationDetailsFactory = {
  create,
};

export default taxiCorporationDetailsFactory;
