import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import {
  faAdd,
  faAddressBook,
  faBuilding,
  faEdit,
  faIcons,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import cargoRoutesHelper from "../routes/cargo-routes.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import CargoAddressListingBreadcrumbsParams from "./types/cargo-address-listing-breadcrumbs-params";
import CargoTaxiContractBreadcrumbsParams from "./types/cargo-taxi-contract-breadcrumbs-params";
import CargoAddressEditBreadcrumbsParams from "./types/cargo-address-edit-breadcrumbs-params";
import CargoDispatchBreadcrumbsParams from "./types/cargo-dispatch-breadcrumbs-params";
import CargoDispatchEditBreadcrumbsParams from "./types/cargo-dispatch-edit-breadcrumbs-params";

const getCompanyAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: cargoRoutesHelper.getCompanyAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().cargoCompanyAdd,
    },
  ];
};

const getCompanyListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
  ];
};

const getAddressAddBreadcrumbs = (
  params: CargoAddressListingBreadcrumbsParams
): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faIcons,
      linkTo: cargoRoutesHelper.getAddressAddRoute(params),
      text: appTranslationsHelper.getBreadcrumbsTranslations().cargoAddressAdd,
    },
  ];
};

const getAddressEditBreadcrumbs = (
  params: CargoAddressEditBreadcrumbsParams
): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faAddressBook,
      linkTo: cargoRoutesHelper.getAddressListingRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoAddressListing,
    },
    {
      icon: faEdit,
      linkTo: cargoRoutesHelper.getAddressEditRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
        addressUuid: params.addressUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations().cargoAddressEdit,
    },
  ];
};

const getAddressListingBreadcrumbs = (
  params: CargoAddressListingBreadcrumbsParams
): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faAddressBook,
      linkTo: cargoRoutesHelper.getAddressListingRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoAddressListing,
    },
  ];
};

const getTaxiContractAddBreadcrumbs = (
  params: CargoTaxiContractBreadcrumbsParams
) => {
  return [
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faAdd,
      linkTo: cargoRoutesHelper.getTaxiContractAddRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoTaxiContractAdd,
    },
  ];
};

const getTaxiContractListingBreadcrumbs = (
  params: CargoTaxiContractBreadcrumbsParams
) => {
  return [
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getTaxiContractListingRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoTaxiContractListing,
    },
  ];
};

const getDispatchListingBreadcrumbs = (
  params: CargoDispatchBreadcrumbsParams
): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getDispatchListingRoute({
        cargoCompanyUuid: params.cargoCompanyUuid!,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoDispatchListing,
    },
  ];
};

const getDispatchAddBreadcrumbs = (
  params: CargoDispatchBreadcrumbsParams
): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faAdd,
      linkTo: cargoRoutesHelper.getDispatchAddRoute({
        cargoCompanyUuid: params.cargoCompanyUuid!,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations().cargoDispatchAdd,
    },
  ];
};

const getDispatchUpdateBreadcrumbs = (
  params: CargoDispatchEditBreadcrumbsParams
): Breadcrumb[] => {
  return [
    {
      icon: faList,
      linkTo: cargoRoutesHelper.getCompanyListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoCompanyListing,
    },
    {
      icon: faBuilding,
      linkTo: undefined,
      text: params.cargoCompanyName,
    },
    {
      icon: faIcons,
      linkTo: cargoRoutesHelper.getDispatchListingRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .cargoDispatchListing,
    },
    {
      icon: faEdit,
      linkTo: cargoRoutesHelper.getDispatchEditRoute({
        cargoCompanyUuid: params.cargoCompanyUuid,
        dispatchUuid: params.dispatchUuid,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .cargoDispatchEdit.replace("#{dispatchName}", params.dispatchName),
    },
  ];
};

const cargoBreadcrumbsHelper = {
  getCompanyAddBreadcrumbs,
  getCompanyListingBreadcrumbs,
  getAddressAddBreadcrumbs,
  getAddressEditBreadcrumbs,
  getAddressListingBreadcrumbs,
  getTaxiContractAddBreadcrumbs,
  getTaxiContractListingBreadcrumbs,
  getDispatchListingBreadcrumbs,
  getDispatchAddBreadcrumbs,
  getDispatchUpdateBreadcrumbs,
};

export default cargoBreadcrumbsHelper;
