import debounce from "lodash/debounce";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import cargoBreadcrumbsHelper from "../../common/breadcrumbs/cargo-breadcrumbs.helper";
import cargoTranslationsHelper from "../../../../languages/cargo-translations.helper";
import CargoAddressEditAddressSelectOption from "./common/types/cargo-address-edit-address-select-option";
import CargoAddressEditRouteParams from "../../common/routes/types/cargo-address-edit-route-params";
import cargoAddressEditApiService from "./common/api/cargo-address-edit-api.service";
import cargoAddressEditFactory from "./common/cargo-address-edit.factory";
import SearchAddressResponse from "../../../../common/utils/search-address/search-address.response";
import searchAddressApiService from "../../../../common/utils/search-address/search-address.service";
import ButtonComponent from "../../../../common/components/button/button.component";
import notificationService from "../../../../common/utils/notification/notification.service";
import cargoRoutesHelper from "../../common/routes/cargo-routes.helper";
import CargoAddressEditResponse, {
  CargoAddressEditResponseItem,
} from "./common/api/types/cargo-address-edit.response";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import CargoAddressEditUpdateRequest from "./common/api/types/cargo-address-edit-update.request";
import CargoAddressTypeSelectOption from "../common/types/cargo-address-type-select-option";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import MapAddressSelectorComponent from "../../../../common/components/map-address-selector/map-address-selector-component";
import CargoAddressEditFormAddressSelected from "./common/types/cargo-address-edit-form-address-selected";
import CargoAddressEditFormCoordinates from "./common/types/cargo-address-edit-form-coordinates";
import useForm from "../../../../common/components/form/use-form";
import CargoAddressFormData from "../common/types/cargo-address-form-data";
import cargoAddressFormHelper from "../common/cargo-address-form.helper";

import useAbort from "../../../../common/hooks/use-abort";
import useCargoCompanyDetails from "../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";

type CargoAddressEditProps = {};

const CargoAddressEditComponent: FC<CargoAddressEditProps> = () => {
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const { cargoCompanyUuid, addressUuid } =
    useParams<CargoAddressEditRouteParams>();

  const translations = cargoTranslationsHelper.getAddressEditTranslations();

  const form = useForm<CargoAddressFormData>({
    emptyValues: cargoAddressFormHelper.getEmptyFormData(),
    validationDefinition: cargoAddressFormHelper.getValidationDefinition(),
  });

  const navigate = useNavigate();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.cargoAddressEdit);

  const [isAddressFetching, setIsAddressFetching] = useState(false);

  const [addressCoordinates, setAddressCoordinates] =
    useState<CargoAddressEditFormCoordinates | null>(null);

  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [isAddressSelectOptionsFetching, setIsAddressSelectOptionsFetching] =
    useState(false);

  const [addressSelectOptions, setAddressSelectOptions] = useState<
    CargoAddressEditAddressSelectOption[]
  >([]);
  const [selectedAddressOption, setSelectedAddressOption] =
    useState<CargoAddressEditAddressSelectOption | null>(null);

  const addressTypeSelectOptions: CargoAddressTypeSelectOption[] = useMemo(
    () => cargoAddressFormHelper.getAddressTypeSelectOptions(),
    [selectedAppLanguage]
  );

  const [cargoCompanyName, setCargoCompanyName] = useState("");

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => cargoCompanyAbort.revoke();
  }, [cargoCompanyUuid]);

  useEffect(() => {
    if (!cargoCompany.data) return;

    setCargoCompanyName(cargoCompany.data.displayName);
  }, [cargoCompany.data]);

  useEffect(() => {
    if (!addressSearchQuery) {
      return;
    }

    debounceFetchAddressSelectOptions(addressSearchQuery);
  }, [addressSearchQuery]);

  useEffect(() => {
    if (!addressUuid || !cargoCompanyUuid) return;

    const breadcrumbs = cargoBreadcrumbsHelper.getAddressEditBreadcrumbs({
      addressUuid,
      cargoCompanyUuid,
      cargoCompanyName,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, addressUuid, cargoCompanyUuid, cargoCompanyName]);

  useEffect(() => {
    fetchCargoAddress();
  }, [addressUuid, cargoCompanyUuid]);

  useEffect(() => {
    if (!form.values.addressType) return;

    const addressTypeValue =
      cargoAddressEditFactory.recreateAddressTypeSelectOption(
        form.values.addressType.value!
      );

    form.setValue("addressType", addressTypeValue);
  }, [selectedAppLanguage]);

  useEffect(() => {
    const streetValue = selectedAddressOption?.value.street ?? "";
    const houseNumberValue = selectedAddressOption?.value.houseNumber ?? "";
    const zipCodeValue = selectedAddressOption?.value.zipCode ?? "";
    const countryValue = selectedAddressOption?.value.country ?? "";
    const townValue = selectedAddressOption?.value.town ?? "";
    const coordinate = selectedAddressOption
      ? {
          latitude: selectedAddressOption.value.latitude,
          longitude: selectedAddressOption.value.longitude,
        }
      : null;

    form.setValues({
      addressType: null,
      street: streetValue,
      houseNumber: houseNumberValue,
      apartmentNumber: "",
      zipCode: zipCodeValue,
      country: countryValue,
      town: townValue,
      description: "",
    });
    setAddressCoordinates(coordinate);

    if (!selectedAddressOption) clearTypeValue();
  }, [selectedAddressOption]);

  const clearTypeValue = () => {
    form.setValue("addressType", null);
  };

  const fetchResolvedAddress = (
    addressSearchQuery: string
  ): Promise<SearchAddressResponse> => {
    return searchAddressApiService.searchByQuery(addressSearchQuery);
  };

  const fetchAddressSelectOptions = (addressSearchQuery: string) => {
    setIsAddressSelectOptionsFetching(true);

    fetchResolvedAddress(addressSearchQuery)
      .then((response) => {
        const selectOptions =
          cargoAddressEditFactory.createAddressSelectOptions(response);

        setAddressSelectOptions(selectOptions);
      })
      .finally(() => {
        setIsAddressSelectOptionsFetching(false);
      });
  };

  const debounceFetchAddressSelectOptions = useCallback(
    debounce((addressSearchQuery: string) => {
      fetchAddressSelectOptions(addressSearchQuery);
    }, 300),
    []
  );

  const fetchCargoAddress = () => {
    if (!cargoCompanyUuid || !addressUuid) {
      return;
    }

    setIsAddressFetching(true);

    cargoAddressEditApiService
      .fetchInitData(cargoCompanyUuid, addressUuid)
      .then(handleCargoAddressResponse)
      .finally(() => setIsAddressFetching(false));
  };

  const handleCargoAddressResponse = (response: CargoAddressEditResponse) => {
    if (response.status === 200) {
      onCargoAddressFetchSuccess(response.data);
      return;
    }
  };

  const onCargoAddressFetchSuccess = (
    responseData: CargoAddressEditResponseItem
  ) => {
    const formData = cargoAddressEditFactory.createFormData(responseData);

    form.setValues(formData);
    setAddressCoordinates({
      latitude: formData.latitude,
      longitude: formData.longitude,
    });
  };

  const acceptAddressCandidature = (
    value: CargoAddressEditFormAddressSelected
  ) => {
    if (!value) return;

    const streetValue = value.street ?? "";
    const houseNumberValue = value.houseNumber ?? "";
    const zipCodeValue = value.zipCode ?? "";
    const countryValue = value.country ?? "";
    const townValue = value.town ?? "";

    const addressValue = {
      type: null,
      street: streetValue,
      houseNumber: houseNumberValue,
      apartmentNumber: "",
      zipCode: zipCodeValue,
      country: countryValue,
      town: townValue,
      latitude: value.latitude,
      longitude: value.longitude,
    };

    const address: CargoAddressEditAddressSelectOption | null = {
      label: value.name,
      value: addressValue,
    };

    setSelectedAddressOption(address);

    setAddressCoordinates({
      latitude: value.latitude,
      longitude: value.longitude,
    });

    form.setValues({
      addressType: null,
      street: streetValue,
      houseNumber: houseNumberValue,
      apartmentNumber: "",
      zipCode: zipCodeValue,
      country: countryValue,
      town: townValue,
      description: "",
    });
  };

  const navigateToListing = () => {
    const cargoAddressListingRoute = cargoRoutesHelper.getAddressListingRoute({
      cargoCompanyUuid: cargoCompanyUuid!,
    });

    navigate(cargoAddressListingRoute);
  };

  const onUpdateAddressSuccess = () => {
    notificationService.success(translations.successEditNotificationText);
    navigateToListing();
  };

  const onUpdateAddressFailure = () => {
    notificationService.error(translations.failureEditNotificationText);
  };

  const handleUpdateAddressResponse = (response: CargoAddressEditResponse) => {
    if (response.status === 200) {
      onUpdateAddressSuccess();
      return;
    }

    onUpdateAddressFailure();
  };

  const updateAddress = () => {
    const request: CargoAddressEditUpdateRequest =
      cargoAddressEditFactory.createEditCargoAddressUpdateRequest(
        form.values,
        addressCoordinates!
      );

    cargoAddressEditApiService
      .update(cargoCompanyUuid!, addressUuid!, request)
      .then(handleUpdateAddressResponse);
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid || !cargoCompanyUuid || !addressUuid) return;

    updateAddress();
  };

  return (
    <div className="cargo_address_edit">
      <HeadingComponent title={translations.header.headingText} />
      <CardComponent isLoading={isAddressFetching}>
        <Row>
          <Column xl={6}>
            <small>{translations.address.searchAddressTip}</small>
            <FormFieldComponent label={translations.address.searchAddressLabel}>
              <SingleSelectComponent
                placeholder={
                  translations.address.searchAddressSelectPlaceholder
                }
                options={addressSelectOptions}
                value={selectedAddressOption}
                isLoading={isAddressSelectOptionsFetching}
                inputValue={addressSearchQuery}
                onInputChange={setAddressSearchQuery}
                isClearable
                onChange={(option) => setSelectedAddressOption(option)}
                isSearchable
                menuPlacement="bottom"
                filterFunction={() => true}
                idForTesting="cargo-address-edit-address-search-select"
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.address.addressTypeLabel}
              isRequired
              errorMessage={form.validationResults.addressType.errorMessage}
            >
              <SingleSelectComponent
                placeholder={translations.address.addressTypeSelectPlaceholder}
                options={addressTypeSelectOptions}
                value={form.values.addressType}
                onChange={(value) => form.setValue("addressType", value)}
                menuPlacement="bottom"
                hasError={!!form.validationResults.addressType.errorMessage}
                idForTesting="cargo-address-edit-address-type-select"
              />
            </FormFieldComponent>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.address.streetLabel}
                  isRequired
                  errorMessage={form.validationResults.street.errorMessage}
                >
                  <InputComponent
                    value={form.values.street}
                    onChange={(value) => form.setValue("street", value)}
                    placeholder={translations.address.streetInputPlaceholder}
                    hasError={!!form.validationResults.street.errorMessage}
                    onBlur={() => form.validate("street")}
                    idForTesting="cargo-address-edit-street-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={3}>
                <FormFieldComponent
                  label={translations.address.houseNumberLabel}
                  isRequired
                  errorMessage={form.validationResults.houseNumber.errorMessage}
                >
                  <InputComponent
                    value={form.values.houseNumber}
                    onChange={(value) => form.setValue("houseNumber", value)}
                    placeholder={
                      translations.address.houseNumberInputPlaceholder
                    }
                    hasError={!!form.validationResults.houseNumber.errorMessage}
                    onBlur={() => form.validate("houseNumber")}
                    idForTesting="cargo-address-edit-house-number-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={3}>
                <FormFieldComponent
                  label={translations.address.apartmentNumberLabel}
                  errorMessage={
                    form.validationResults.apartmentNumber.errorMessage
                  }
                >
                  <InputComponent
                    value={form.values.apartmentNumber}
                    onChange={(value) =>
                      form.setValue("apartmentNumber", value)
                    }
                    placeholder={
                      translations.address.apartmentNumberInputPlaceholder
                    }
                    hasError={
                      !!form.validationResults.apartmentNumber.errorMessage
                    }
                    onBlur={() => form.validate("apartmentNumber")}
                    idForTesting="cargo-address-edit-apartment-number-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.address.townLabel}
                  classNames={{ root: "mt-0" }}
                  isRequired
                  errorMessage={form.validationResults.town.errorMessage}
                >
                  <InputComponent
                    value={form.values.town}
                    onChange={(value) => form.setValue("town", value)}
                    placeholder={translations.address.townInputPlaceholder}
                    hasError={!!form.validationResults.town.errorMessage}
                    onBlur={() => form.validate("town")}
                    idForTesting="cargo-address-edit-town-input"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.address.zipCodeLabel}
                  classNames={{ root: "mt-0" }}
                  isRequired
                  errorMessage={form.validationResults.zipCode.errorMessage}
                >
                  <InputComponent
                    value={form.values.zipCode}
                    onChange={(value) => form.setValue("zipCode", value)}
                    placeholder={translations.address.zipCodeInputPlaceholder}
                    hasError={!!form.validationResults.zipCode.errorMessage}
                    onBlur={() => form.validate("zipCode")}
                    idForTesting="cargo-address-edit-zip-code-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <FormFieldComponent
              label={translations.address.countryLabel}
              classNames={{ root: "mt-0" }}
              isRequired
              errorMessage={form.validationResults.country.errorMessage}
            >
              <InputComponent
                value={form.values.country}
                onChange={(value) => form.setValue("country", value)}
                placeholder={translations.address.countryInputPlaceholder}
                hasError={!!form.validationResults.country.errorMessage}
                onBlur={() => form.validate("country")}
                idForTesting="cargo-address-edit-country-input"
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.address.descriptionLabel}
              classNames={{ root: "mt-0" }}
              errorMessage={form.validationResults.description.errorMessage}
              isRequired
            >
              <InputComponent
                value={form.values.description}
                onChange={(value) => form.setValue("description", value)}
                placeholder={translations.address.descriptionInputPlaceholder}
                hasError={!!form.validationResults.description.errorMessage}
                onBlur={() => form.validate("description")}
                idForTesting="cargo-address-edit-description-input"
              />
            </FormFieldComponent>
          </Column>
          <Column xl={6}>
            <FormFieldComponent
              label={translations.address.mapLabel}
              classNames={{
                root: "mt-0",
              }}
            >
              <div className="cargo_address_edit_map_wrapper">
                <MapAddressSelectorComponent
                  onAddressChange={acceptAddressCandidature}
                  address={addressCoordinates!}
                />
              </div>
            </FormFieldComponent>
          </Column>
        </Row>
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          idForTesting="cargo-address-edit-submit-button"
          classNames={{ root: "mt-2" }}
          title={translations.address.submitButtonTitle}
        >
          {translations.address.submitButtonText}
        </ButtonComponent>
      </CardComponent>
    </div>
  );
};

export default CargoAddressEditComponent;
