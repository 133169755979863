import NoticeBoxType from "./notice-box-type";

const getRootClassnameByType = (type: NoticeBoxType): string => {
  switch (type) {
    case NoticeBoxType.BRAND:
      return "brand";
    case NoticeBoxType.DANGER:
      return "danger";
    case NoticeBoxType.INFO:
      return "info";
    case NoticeBoxType.PRIMARY:
      return "primary";
    case NoticeBoxType.SUCCESS:
      return "success";
    case NoticeBoxType.WARNING:
      return "warning";
  }
};

const noticeBoxHelper = { getRootClassnameByType };

export default noticeBoxHelper;
