import {
  DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry,
  DriverDetailsRoutePlansAfterCandidatureApprovalResponseRestrictedPlanEntry,
  DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryType,
  DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryStartNode,
  DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryEndNode,
  DriverDetailsRoutePlansAfterCandidatureApprovalResponseFullPlanEntry,
  DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntrySolvedOrder,
} from "../../common/api/driver-details-route-plans-after-candidature-approval.response";
import DriverDetailsCandidatureManagementPlanEntry, {
  DriverDetailsCandidatureManagementPlanEntryWaypoint,
} from "./types/driver-details-candidature-management-plan-entry";

const checkIsRestrictedResponsePlanEntry = (
  responsePlanEntry: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry
): responsePlanEntry is DriverDetailsRoutePlansAfterCandidatureApprovalResponseRestrictedPlanEntry => {
  return (
    responsePlanEntry.view_type ===
    DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryType.RESTRICTED
  );
};

const createWaypointForStartNode = (
  responseNode: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryStartNode
): DriverDetailsCandidatureManagementPlanEntryWaypoint => {
  return {
    uuid: responseNode.meta.id,
    addressDisplayName: responseNode.display_name,
    clientName: null,
    estimatedDate: new Date(responseNode.meta.estimated_time),
    orderNo: null,
    plannedDate: responseNode.time ? new Date(responseNode.time) : null,
    plannedHaltingTime: responseNode.halting_time ?? null,
  };
};

const createWaypointForEndNode = (
  responseNode: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntryEndNode
): DriverDetailsCandidatureManagementPlanEntryWaypoint => {
  return {
    uuid: responseNode.meta.id,
    addressDisplayName: responseNode.display_name,
    clientName: null,
    estimatedDate: new Date(responseNode.meta.estimated_time),
    orderNo: null,
    plannedDate: responseNode.time ? new Date(responseNode.time) : null,
    plannedHaltingTime: responseNode.halting_time ?? null,
  };
};

const createWaypointsForSolvedOrder = (
  responseSolvedOrder: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntrySolvedOrder
): DriverDetailsCandidatureManagementPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsCandidatureManagementPlanEntryWaypoint[] = [];

  responseSolvedOrder.ride.seq.forEach((seqItem) => {
    const waypoint: DriverDetailsCandidatureManagementPlanEntryWaypoint = {
      uuid: seqItem.meta.id,
      clientName: responseSolvedOrder.cargo_company.display_name,
      orderNo: responseSolvedOrder.transporting_order.cargo_order.human_id,
      addressDisplayName: seqItem.display_name,
      estimatedDate: new Date(seqItem.meta.estimated_time),
      plannedHaltingTime: seqItem.halting_time,
      plannedDate: seqItem.time ? new Date(seqItem.time) : null,
    };

    waypoints.push(waypoint);
  });

  return waypoints;
};

const createWaypointsForSolvedOrders = (
  responseSolvedOrders: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntrySolvedOrder[]
): DriverDetailsCandidatureManagementPlanEntryWaypoint[] => {
  return responseSolvedOrders
    .map((responseSolvedOrder) =>
      createWaypointsForSolvedOrder(responseSolvedOrder)
    )
    .flat();
};

const createPlanEntryWaypointsForRestrictedPlanEntry = (
  responseRestrictedPlanEntry: DriverDetailsRoutePlansAfterCandidatureApprovalResponseRestrictedPlanEntry
): DriverDetailsCandidatureManagementPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsCandidatureManagementPlanEntryWaypoint[] = [];

  const startWaypoint = createWaypointForStartNode(
    responseRestrictedPlanEntry.start_node
  );

  waypoints.push(startWaypoint);

  const endWaypoint = createWaypointForEndNode(
    responseRestrictedPlanEntry.end_node
  );

  waypoints.push(endWaypoint);

  return waypoints;
};

const createPlanEntryWaypointsForFullPlanEntry = (
  responseFullPlanEntry: DriverDetailsRoutePlansAfterCandidatureApprovalResponseFullPlanEntry
): DriverDetailsCandidatureManagementPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsCandidatureManagementPlanEntryWaypoint[] = [];

  const startWaypoint = createWaypointForStartNode(
    responseFullPlanEntry.start_node
  );

  waypoints.push(startWaypoint);

  const orderWaypoints = createWaypointsForSolvedOrders(
    responseFullPlanEntry.solved_orders
  );

  waypoints.push(...orderWaypoints);

  const endWaypoint = createWaypointForEndNode(responseFullPlanEntry.end_node);

  waypoints.push(endWaypoint);

  return waypoints;
};

const createPlanEntryWaypoints = (
  responsePlanEntry: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry
): DriverDetailsCandidatureManagementPlanEntryWaypoint[] => {
  const isResponsePlanEntryRestricted =
    checkIsRestrictedResponsePlanEntry(responsePlanEntry);

  if (isResponsePlanEntryRestricted) {
    return createPlanEntryWaypointsForRestrictedPlanEntry(responsePlanEntry);
  }

  return createPlanEntryWaypointsForFullPlanEntry(responsePlanEntry);
};

const createPlanEntry = (
  responsePlanEntry: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry
): DriverDetailsCandidatureManagementPlanEntry => {
  const waypoints = createPlanEntryWaypoints(responsePlanEntry);

  return {
    uuid: responsePlanEntry.id,
    id: responsePlanEntry.human_id,
    waypoints,
  };
};

const createPlanEntries = (
  responsePlanEntries: DriverDetailsRoutePlansAfterCandidatureApprovalResponsePlanEntry[]
): DriverDetailsCandidatureManagementPlanEntry[] => {
  return responsePlanEntries.map(createPlanEntry);
};

const driverDetailsCandidatureManagementPlanEntriesAfterCandidatureApproveFactory =
  {
    createPlanEntries,
  };

export default driverDetailsCandidatureManagementPlanEntriesAfterCandidatureApproveFactory;
