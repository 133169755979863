import { useEffect, useState } from "react";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import orderDetailsForwardingApiService from "../api/forwarding/order-details-forwarding.service";
import orderDetailsForwardingAvailableConsumerFactory from "./types/order-details-forwarding-available-consumer.factory";
import OrderDetailsForwardingAvailableConsumerSelectOption from "./types/order-details-forwarding-available-consumer-select-option";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import OrderDetailsAvailableConsumerResponseData from "../api/forwarding/order-details-available-consumer.response";

type OrderDetailsSelectAvailableConsumerComponentProps = {
  forwardingId?: string | undefined;
  selectedTaxiCorporationValue: SelectOption | null;
  transportingOrderId: string | null;
  onChange: (
    newValue: OrderDetailsForwardingAvailableConsumerSelectOption
  ) => void;
};

const OrderDetailsSelectAvailableConsumerComponent = (
  props: OrderDetailsSelectAvailableConsumerComponentProps
) => {
  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<OrderDetailsForwardingAvailableConsumerSelectOption[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const translations =
    userTranslationsHelper.getTaxiOfficerListingTranslations().select;

  const handleTaxiAvailableResponse = (
    response: OrderDetailsAvailableConsumerResponseData
  ) => {
    if (response.status !== 200) return;

    const taxiAvailableConsumersSelectOptions =
      orderDetailsForwardingAvailableConsumerFactory.createSelectOptions(
        response.data
      );

    setTaxiCorporationSelectOptions(taxiAvailableConsumersSelectOptions);
  };

  useEffect(() => {
    setIsFetching(true);

    orderDetailsForwardingApiService
      .fetchTaxiAvailableConsumers(props.transportingOrderId!)
      .then(handleTaxiAvailableResponse)
      .finally(() => setIsFetching(false));
  }, []);

  return (
    <SingleSelectComponent
      placeholder={translations.selectTaxiCorporationPlaceholder}
      value={props.selectedTaxiCorporationValue!}
      onChange={(value) => props.onChange(value!)}
      options={taxiCorporationSelectOptions}
      isLoading={isFetching}
      isSearchable
      menuPlacement="bottom"
      menuPosition="fixed"
    />
  );
};

export default OrderDetailsSelectAvailableConsumerComponent;
