import HttpResponse from "../../../../utils/http/http.response";

export enum DelegationListResponseItemState {
  NEW = "NEW",
  DOWNLOADED = "DOWNLOADED",
  DELETED = "DELETED",
}

export enum DelegationListResponseItemDelegationType {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
}

export type DelegationListResponseItem = {
  id: string;
  createdAt: Date;
  createdBy: string;
  cargoCompanyName: string;
  downloadedCsvAt?: Date | null;
  downloadedCsvBy?: string | null;
  downloadedPdfAt?: Date | null;
  downloadedPdfBy?: string | null;
  state: DelegationListResponseItemState;
  delegationNumber: string;
  type: DelegationListResponseItemDelegationType;
  workerName: string;
  dateFrom: Date;
  dateTo: Date;
  creationDate: Date;
  totalCost: number;
  totalDistance: number;
  humanId: number;
};

type DelegationListResponse = HttpResponse<{
  data: DelegationListResponseItem[];
  totalCount: number;
}>;

export default DelegationListResponse;
