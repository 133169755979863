import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import delegationListingTableHelper from "./delegation-listing-table.helper";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import DelegationListingTableRow from "../common/types/delegation-listing-table-row";
import dateService from "../../../../common/utils/date/date.service";
import DelegationListingTableStatusComponent from "../common/table/status/delegation-listing-table-status.component";
import TableDeleteButtonComponent from "../../../../common/components/table/button/delete/table-delete-button.component";
import DelegationDeleteDelegationData from "../../common/delete/common/types/delegation-delete-delegation-data";
import DelegationListingTableColumn from "../common/types/delegation-listing-table-column";
import DelegationListingItem from "../common/types/delegation-listing-item";
import DelegationListingDelegationStatus from "../common/types/delegation-listing-delegation-status";
import DelegationListingActionData from "../common/types/delegation-listing-action-data";
import { useAppContext } from "../../../../context/app.context";
import TableButtonComponent from "../../../../common/components/table/button/table-button.component";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import DelegationDetailsComponent from "../../details/delegation-details.component";
import { round } from "lodash";

type DelegationListingTableProps = Pick<TableProps, "isError" | "isLoading"> & {
  expandedRowsDelegationUuids: string[];
  onRowClick: (delegationUuid: DelegationListingItem["uuid"]) => void;
  isError: boolean;
  isLoading: boolean;
  listingItems: DelegationListingItem[];
  nonDeletedListingItems: DelegationListingItem[];
  onSelectDelegationToDelete: (
    delegationData: DelegationDeleteDelegationData
  ) => void;
  isDownloadPDFEnabled: boolean;
  isDownloadCSVEnabled: boolean;
  actionDataList: DelegationListingActionData[];
  onActionButtonClick: (listingItem: DelegationListingItem) => void;
  onActionSelectAllButtonClick: () => void;
};

const DelegationListingTableComponent: FC<DelegationListingTableProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const isHeaderActionButtonVisible =
    props.isDownloadCSVEnabled || props.isDownloadPDFEnabled;
  const areAllRowsSelected =
    props.actionDataList.length === props.nonDeletedListingItems.length;

  const columns = useMemo(
    (): DelegationListingTableColumn[] =>
      delegationListingTableHelper.getColumns(
        isHeaderActionButtonVisible,
        areAllRowsSelected,
        props.onActionSelectAllButtonClick
      ),
    [
      selectedAppLanguage,
      props.listingItems,
      props.isDownloadCSVEnabled,
      props.isDownloadPDFEnabled,
      props.actionDataList,
    ]
  );

  const createTableRow = (
    listingItem: DelegationListingItem
  ): DelegationListingTableRow => {
    const delegationData: DelegationDeleteDelegationData = {
      delegationNumber: listingItem.delegationNumber,
      delegationUuid: listingItem.uuid,
    };

    const translations =
      delegationTranslationsHelper.getDelegationListingTranslations().table
        .headers;

    const startDateTitle = dateService.checkIsValidDate(listingItem.dateFrom)
      ? dateService.format(listingItem.dateFrom, "dd.mm.yyyy")
      : "---";
    const startDateLabel = startDateTitle;

    const endDateTitle = dateService.checkIsValidDate(listingItem.dateTo)
      ? dateService.format(listingItem.dateTo, "dd.mm.yyyy")
      : "---";
    const endDateLabel = endDateTitle;

    const creationDateTitle = dateService.checkIsValidDate(
      listingItem.creationDate
    )
      ? dateService.format(listingItem.creationDate, "dd.mm.yyyy")
      : "---";
    const creationDateLabel = creationDateTitle;

    const firstCsvDateTitle: string = dateService.checkIsValidDate(
      listingItem.downloadedCsvAt
    )
      ? dateService.format(listingItem.downloadedCsvAt!, "dd.mm.yyyy")
      : "---";
    const firstCsvDateLabel: string = firstCsvDateTitle;

    const firstPdfDateTitle: string = dateService.checkIsValidDate(
      listingItem.downloadedPdfAt
    )
      ? dateService.format(listingItem.downloadedPdfAt!, "dd.mm.yyyy")
      : "---";
    const firstPdfDateLabel: string = firstPdfDateTitle;

    const distanceLabel = `${round(listingItem.totalDistance)} KM`;

    const deleteComponent = (
      <div>
        <TableDeleteButtonComponent
          onClick={() => props.onSelectDelegationToDelete(delegationData)}
          title={translations.delegationDeleteButtonLabel}
          idForTesting={`delete-delegation-button`}
        />
      </div>
    );

    const isItemSelected = !!props.actionDataList.find(
      (source) => source.delegationUuid === listingItem.uuid
    );

    const isActionButtonVisible =
      isHeaderActionButtonVisible &&
      listingItem.status !== DelegationListingDelegationStatus.DELETED;

    const isHiddenComponentVisible =
      listingItem.status !== DelegationListingDelegationStatus.DELETED &&
      props.expandedRowsDelegationUuids.includes(listingItem.uuid);

    return {
      id: listingItem.uuid,
      onClick: () => props.onRowClick(listingItem.uuid),
      value: {
        delegationNumber: (
          <div title={String(listingItem.delegationNumber)}>
            {listingItem.delegationNumber}
          </div>
        ),
        type: <div title={listingItem.type}>{listingItem.type}</div>,
        startDate: <div title={startDateTitle}>{startDateLabel}</div>,
        endDate: <div title={endDateTitle}>{endDateLabel}</div>,
        creationDate: <div title={creationDateTitle}>{creationDateLabel}</div>,
        firstPdfDownload: (
          <div title={firstPdfDateTitle}>{firstPdfDateLabel}</div>
        ),
        firstCsvDownload: (
          <div title={firstCsvDateTitle}>{firstCsvDateLabel}</div>
        ),
        status: (
          <DelegationListingTableStatusComponent status={listingItem.status} />
        ),
        totalCost: (
          <div
            title={delegationListingTableHelper.getTotalCostTitle(
              listingItem.totalCost
            )}
          >
            {delegationListingTableHelper.getTotalCostLabel(
              listingItem.totalCost
            )}
          </div>
        ),
        totalDistance: <div title={distanceLabel}>{distanceLabel}</div>,
        cargoCompanyName: (
          <div title={listingItem.cargoCompanyName}>
            {listingItem.cargoCompanyName}
          </div>
        ),
        workerName: (
          <div title={listingItem.workerName}>{listingItem.workerName}</div>
        ),
        action: isActionButtonVisible && (
          <TableButtonComponent
            icon={faCrosshairs}
            type={isItemSelected ? "success" : "brand"}
            onClick={() => props.onActionButtonClick(listingItem)}
          />
        ),
        delete:
          listingItem.status !== DelegationListingDelegationStatus.DELETED
            ? deleteComponent
            : null,
      },
      hiddenComponent: (
        <DelegationDetailsComponent
          row={listingItem}
          isVisible={isHiddenComponentVisible}
        />
      ),
      isHiddenComponentVisible,
    };
  };

  const rows: DelegationListingTableRow[] = useMemo(() => {
    return props.listingItems.map((list) => createTableRow(list));
  }, [
    JSON.stringify(props.listingItems),
    props.expandedRowsDelegationUuids,
    props.isDownloadCSVEnabled,
    props.isDownloadPDFEnabled,
    props.actionDataList,
  ]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default DelegationListingTableComponent;
