import { FC } from "react";
import { ReactComponent as Logo } from "../../../assets/images/raily_logo_full.svg";
import classNames from "classnames";

type AppLogoHorizontalProps = {
  className?: string;
};

const AppLogoHorizontalComponent: FC<AppLogoHorizontalProps> = (props) => {
  return <Logo className={classNames("app-logo", props.className)} />;
};

export default AppLogoHorizontalComponent;
