import Joi from "joi";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import userFormValidationService from "../../../../shared/user/user.validation.service";

const validatePassword = (password: string): FormValidationResult => {
  const validationSchema = Joi.string().min(8).max(50).required();

  return formValidationService.validate(password, validationSchema);
};

const signInFormValidationService = {
  validatePassword: validatePassword,
  validateUsername: userFormValidationService.validateUsername,
};

export default signInFormValidationService;
