import railyApiService from "../raily-api.service";
import ContractValidCollectionRequest from "./valid-collection/contract-valid-collection.request";
import ContractValidCollectionResponse from "./valid-collection/contract-valid-collection.response";

const contractApiService = () => {
  const getValidCollection = async (
    request: ContractValidCollectionRequest,
    abortSignal: AbortSignal
  ): Promise<ContractValidCollectionResponse> => {
    const url = `/contracts/cargo-companies/${request.cargoCompanyId}/valid-collection`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  return {
    getValidCollection,
  };
};

export default contractApiService;
