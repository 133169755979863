import DealerListRequest, {
  DealerListRequestStatusCriteria,
  DealerListRequestOrder,
} from "../../../api/raily/dealer/list/dealer-list.request";
import UserStatus from "../../../types/user-status";
import paginationService from "../../../utils/pagination/pagination.service";
import DealerListLoadParams, {
  DealerListLoadParamsOrder,
} from "./dealer-list-load-params";

const createStatusCriteria = (
  status: UserStatus | undefined
): DealerListRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return DealerListRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return DealerListRequestStatusCriteria.DELETED;
    default:
      return DealerListRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: DealerListLoadParamsOrder | undefined
): DealerListRequestOrder | undefined => {
  switch (status) {
    case DealerListLoadParamsOrder.FIRST_NAME_ASC:
      return DealerListRequestOrder.FIRST_NAME_ASC;
    case DealerListLoadParamsOrder.FIRST_NAME_DESC:
      return DealerListRequestOrder.FIRST_NAME_DESC;
    case DealerListLoadParamsOrder.LAST_NAME_ASC:
      return DealerListRequestOrder.LAST_NAME_ASC;
    case DealerListLoadParamsOrder.LAST_NAME_DESC:
      return DealerListRequestOrder.LAST_NAME_DESC;
    case DealerListLoadParamsOrder.USERNAME_ASC:
      return DealerListRequestOrder.USERNAME_ASC;
    case DealerListLoadParamsOrder.USERNAME_DESC:
      return DealerListRequestOrder.USERNAME_DESC;
    default:
      return undefined;
  }
};

const create = (params: DealerListLoadParams): DealerListRequest => {
  return {
    taxiCorporation: params.taxiCorporation,
    taxiCorporationId: params.taxiCorporationUuid,
    firstName: params.firstName,
    lastName: params.lastName,
    username: params.username,
    email: params.email,
    statusCriteria: createStatusCriteria(params.status),
    creationStart: params.creationDateFrom,
    creationEnd: params.creationDateTo,
    order: createOrder(params.order),
    limit: params.pageSize,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
  };
};

const dealerListRequestFactory = { create };

export default dealerListRequestFactory;
