import { FC, useState } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import orderDetailsForwardingApiService from "../api/forwarding/order-details-forwarding.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsForwardingResponse from "./types/order-details-forwarding.response";
import useOrderDetialsForwarding from "../use-order-details-forwarding";
import OrderDetailsTaxiContractSelectOption from "./types/order-details-taxi-contract-select-option";
import useOrderDetialsForwardingForm from "../hooks/use-order-details-forwarding-form";
import OrderDetailsForwardingFormComponent from "./order-details-forwarding-form.component";

type OrderDetailsForwardingModalComponentProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  details: OrderDetailsCargoOrder | null;
};

const OrderDetailsForwardingModalComponent: FC<
  OrderDetailsForwardingModalComponentProps
> = ({ isOpen, details, onClose }) => {
  const [contractId, setContractId] = useState("");
  const [selectedTaxiCorporationId, setSelectedTaxiCorporationId] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    formValidationResults,
    setTaxiCorporation,
    setTaxiContract,
    checkIsFormValid,
  } = useOrderDetialsForwardingForm({});

  const { transportingOrderUuid: transportingOrderId, taxiCorporationUuid } =
    details!;

  const { consumerTaxiId, forwardingId, setForwardingId } =
    useOrderDetialsForwarding({
      transportingOrderId,
    });

  const translations =
    orderTranslationsHelper.getDetailsTranslations().forwarding;

  const {
    form,
    headingText,
    successForwardingNotificationText,
    failureForwardingNotificationText,
  } = translations;
  const {
    submitButtonTitle,
    submitButtonText,
    submitDisabledButtonTitle,
    rejectButtonTitle,
    rejectButtonText,
  } = form;

  const sumbitText = !!forwardingId
    ? submitDisabledButtonTitle
    : submitButtonTitle;

  const handleTaxiContractChange = (
    option: OrderDetailsTaxiContractSelectOption | null
  ) => {
    setContractId(option?.value?.id!);
    setTaxiContract(option);
  };

  const getForwardings = () => {
    orderDetailsForwardingApiService
      .fetchForwarding(transportingOrderId!)
      .then(handleForwardingResponse);
  };

  const handleForwardingResponse = (
    response: OrderDetailsForwardingResponse
  ) => {
    setForwardingId(response?.data[0]?.id);
  };

  const handleForwardingSumbit = (response: any) => {
    if (response?.status === 201) {
      notificationService.success(successForwardingNotificationText);
      onClose();
      return;
    }
    notificationService.error(failureForwardingNotificationText);
  };

  const handleOnSubmit = () => {
    const isFormValid = checkIsFormValid();
    if (!isFormValid) return;

    setIsLoading(true);
    orderDetailsForwardingApiService
      .fetchSendForwarding(transportingOrderId, {
        contract_id: contractId,
      })
      .then(handleForwardingSumbit)
      .finally(() => setIsLoading(false));
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      classNames={{
        content: `modal_content`,
      }}
      header={{
        title: headingText,
      }}
      actions={[
        <ButtonComponent
          onClick={handleOnSubmit}
          type="primary"
          title={sumbitText}
          isLoading={isLoading}
        >
          {submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={onClose}
          title={rejectButtonTitle}
        >
          {rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <OrderDetailsForwardingFormComponent
        producerTaxiId={forwardingId ? consumerTaxiId : taxiCorporationUuid}
        transportingOrderId={transportingOrderId}
        forwardingId={forwardingId}
        formValidationResults={formValidationResults}
        selectedTaxiCorporationId={selectedTaxiCorporationId}
        setSelectedTaxiCorporationId={setSelectedTaxiCorporationId}
        getForwardings={getForwardings}
        handleTaxiContractChange={handleTaxiContractChange}
        setTaxiCorporation={setTaxiCorporation}
      />
    </ModalComponent>
  );
};

export default OrderDetailsForwardingModalComponent;
