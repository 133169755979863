import Joi from "joi";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";

const validateActivity = (value: boolean | null): FormValidationResult => {
  const validationSchema = Joi.boolean().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateDisplayName = (value: string | null): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(40).required();

  return formValidationService.validate(value, validationSchema);
};

const validatePriority = (value: number | null): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(100).required();

  return formValidationService.validate(value, validationSchema);
};

const taxiTaxiContractValidationService = {
  validateActivity,
  validateDisplayName,
  validatePriority,
};

export default taxiTaxiContractValidationService;
