import DelegationAddFromMileagesRequest, {
  DelegationAddFromMileagesRequestBody,
  DelegationAddFromMileagesRequestParams,
} from "./delegation-add-from-mileages.request";

const createQueryParams = (
  request: DelegationAddFromMileagesRequest
): DelegationAddFromMileagesRequestParams => {
  return {
    timezone: request.timezone,
  };
};

const createBody = (
  request: DelegationAddFromMileagesRequest
): DelegationAddFromMileagesRequestBody => {
  return {
    cargoCompanyId: request.cargoCompanyUuid,
    mileageIds: request.mileageUuids,
    workerId: request.workerUuid,
  };
};

const delegationAddFromMileagesRequestFactory = {
  createBody,
  createQueryParams,
};

export default delegationAddFromMileagesRequestFactory;
