import HttpResponse from "../../../utils/http/http.response";
import railyApiService from "../raily-api.service";
import cargoOfficerAddRequestFactory from "./add/cargo-officer-add-request.factory";
import CargoOfficerAddRequest, {
  CargoOfficerAddRequestBody,
} from "./add/cargo-officer-add.request";
import cargoOfficerListRequestFactory from "./list/cargo-officer-list-request.factory";
import CargoOfficerListRequest, {
  CargoOfficerListRequestQueryParams,
} from "./list/cargo-officer-list.request";
import CargoOfficerListResponse from "./list/cargo-officer-list.response";

const cargoOfficerApiService = () => {
  const add = (
    request: CargoOfficerAddRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/cargo-officers`;

    const body: CargoOfficerAddRequestBody =
      cargoOfficerAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const getList = async (
    request: CargoOfficerListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoOfficerListResponse> => {
    const url = `/users/all/cargo-officers`;

    const queryParams: CargoOfficerListRequestQueryParams =
      cargoOfficerListRequestFactory.createQueryParams(request);

    return railyApiService().get<CargoOfficerListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    add,
    getList,
  };
};

export default cargoOfficerApiService;
