import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderFinishedListingExecutionStatus from "./types/order-finished-listing-execution-status";
import OrderFinishedListingPublicStatus from "./types/order-finished-listing-public-status";

const getStatusOptions = () => [
  {
    status: OrderFinishedListingExecutionStatus.FINISHED,
  },
  {
    status: OrderFinishedListingExecutionStatus.CANCELLED,
  },
];

const getExecutionStatusText = (
  status: OrderFinishedListingExecutionStatus
): string => {
  const translations =
    orderTranslationsHelper.getFinishedListingTranslations().statuses;

  const options: {
    text: string;
    status: OrderFinishedListingExecutionStatus;
  }[] = [
    {
      text: translations.cancelled,
      status: OrderFinishedListingExecutionStatus.CANCELLED,
    },
    {
      text: translations.finished,
      status: OrderFinishedListingExecutionStatus.FINISHED,
    },
  ];

  return options.find((option) => option.status === status)?.text ?? "";
};

const getPublicStatusText = (
  status: OrderFinishedListingPublicStatus
): string => {
  const translations =
    orderTranslationsHelper.getFinishedListingTranslations().publicStatuses;

  const options: {
    text: string;
    status: OrderFinishedListingPublicStatus;
  }[] = [
    {
      text: translations.private,
      status: OrderFinishedListingPublicStatus.PRIVATE,
    },
    {
      text: translations.public,
      status: OrderFinishedListingPublicStatus.PUBLIC,
    },
    {
      text: translations.published,
      status: OrderFinishedListingPublicStatus.PUBLISHED,
    },
  ];

  return options.find((option) => option.status === status)?.text ?? "";
};

const getStatusBoxClassname = (
  status: OrderFinishedListingExecutionStatus
): string => {
  const options = [
    {
      className: "finished",
      status: OrderFinishedListingExecutionStatus.FINISHED,
    },
    {
      className: "cancelled",
      status: OrderFinishedListingExecutionStatus.CANCELLED,
    },
  ];

  return options.find((option) => option.status === status)?.className ?? "";
};

const orderFinishedListingHelper = {
  getStatusOptions,
  getExecutionStatusText,
  getStatusBoxClassname,
  getPublicStatusText,
};

export default orderFinishedListingHelper;
