import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import TaxiFleetPartnerAddFormData from "./types/taxi-fleet-partner-add-form-data";
import TaxiFleetPartnerAddValidationResults from "./types/taxi-fleet-partner-add-form-validation.results";

const getDefaultFormData = (): TaxiFleetPartnerAddFormData => {
  return {
    name: "",
  };
};

const getDefaultFormValidationResults =
  (): TaxiFleetPartnerAddValidationResults => {
    return {
      name: formValidationService.defaultValidationResult,
    };
  };

const taxiFleetPartnerAddFormHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default taxiFleetPartnerAddFormHelper;
