export enum PassengerListRequestOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

export enum PassengerListRequestStatusCriteria {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  ALL = "ALL",
}

export type PassengerListRequestQueryParams = {
  cargoCompany: string | undefined;
  cargoCompanyId: string | undefined;
  dispatch: string | undefined;
  dispatchId: string | undefined;
  order: PassengerListRequestOrder | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  username: string | undefined;
  email: string | undefined;
  statusCriteria: PassengerListRequestStatusCriteria | undefined;
  offset: number | undefined;
  limit: number | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
  address: string | undefined;
};

type PassengerListRequest = {
  cargoCompany?: string;
  cargoCompanyId?: string;
  dispatch?: string;
  dispatchId?: string;
  order?: PassengerListRequestOrder;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  statusCriteria?: PassengerListRequestStatusCriteria;
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
  address?: string;
};

export default PassengerListRequest;
