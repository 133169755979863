import {
  OrderDetailsHistoryResponseDataRideSeqItem,
  OrderDetailsHistoryResponseItemInitializeCommandRideWaypoint,
} from "./api/order-details-history.response";

const checkIsResponseRideSeqItemAWaypoint = (
  responseRideSeqItem: OrderDetailsHistoryResponseDataRideSeqItem
): responseRideSeqItem is OrderDetailsHistoryResponseItemInitializeCommandRideWaypoint => {
  if (
    (
      responseRideSeqItem as OrderDetailsHistoryResponseItemInitializeCommandRideWaypoint
    ).display_name
  ) {
    return true;
  }

  return false;
};

const orderDetailsHistoryOrderRouteHelper = {
  checkIsResponseRideSeqItemAWaypoint,
};

export default orderDetailsHistoryOrderRouteHelper;
