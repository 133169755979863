import BillingsOrderForwardTaxiTaxi, {
  BillingsOrderForwardTaxiTaxiItem,
} from "./billings-order-forward-taxi-taxi";
import BillingModel from "../../../../types/billing-model";
import BillingStatus from "../../../../types/billing-status";
import BillingsOrderForwardTaxiTaxiResponse, {
  BillingsOrderForwardTaxiTaxiResponseItem,
} from "../../../../api/raily/billings/order-forward/taxi-taxi/billings-order-forward-taxi-taxi.response";

const createDataItem = (
  data: BillingsOrderForwardTaxiTaxiResponseItem
): BillingsOrderForwardTaxiTaxiItem => {
  return {
    billingUuid: data.id,
    forwardingUuid: data.orderForwardingAction.id,
    contractDetails: {
      companyName: data.orderForwardingAction.contract.consumerTaxi.displayName,

      distanceRate: data.orderForwardingAction.contract.distanceRate,
      model: data.orderForwardingAction.contract.model as BillingModel,
    },
    baseAmountValue: data.baseAmountValue,
    amountForChargeHaltings: data.amountForChargeHaltings,
    amountForChargeHighways: data.amountForChargeHighways,
    distance: data.distance,
    amountForDistance: data.amountForDistance,
    internalOrderId:
      data.orderForwardingAction.transportingOrder.cargoOrder.humanId,
    allContributionsAmount: data.allContributionsAmount,
    isDraft: data.draft,
    date: data.date,
    sumOfBonuses: data.sumOfBonuses,
    sumOfPenalties: data.sumOfPenalties,
    status: data.status as BillingStatus,
    readOnly: data.readOnly,
  };
};

const createData = (
  responseData: BillingsOrderForwardTaxiTaxiResponseItem[]
): BillingsOrderForwardTaxiTaxiItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: BillingsOrderForwardTaxiTaxiResponse
): BillingsOrderForwardTaxiTaxi => {
  return {
    data: createData(response.data),
  };
};

const billingsOrderForwardTaxiTaxiFactory = {
  create,
};

export default billingsOrderForwardTaxiTaxiFactory;
