import { FC, useMemo, useRef } from "react";
import { PlannerDriverPlanListItem } from "../../../../../../common/services/planner/list/planner-driver-plan-list";
import planningOrderDriverListingChartDataFactory from "./planning-order-driver-plan-listing-item-chart.factory";
import { PlannerDriverPlanOrderItem } from "../../../../../../common/services/planner/order/planner-driver-plan-order";
import { PlanningOrderSelectedItemData } from "../../../common/types/planning-order-selected-data";
import PlanningOrderDriverPlanListingItemDetailsComponent from "./planning-order-driver-plan-listing-item-details.component";
import Row from "../../../../../../common/components/grid/row";
import Column from "../../../../../../common/components/grid/column";
import ChartComponent from "../../../../../../common/components/chart/chart.component";
import ChartData from "../../../../../../common/components/chart/types/chart-data";
import PlanningOrderDriverPlanListingItemChart, {
  PlanXAxisValue,
} from "../../common/planner-order-driver-listing-item-chart";
import planningTranslationsHelper from "../../../../../../languages/planning-translations.helper";

type PlanningOrderDriverPlanListingItemProps = {
  item: PlannerDriverPlanListItem;
  order: PlannerDriverPlanOrderItem;
  onClick: (item: PlanningOrderSelectedItemData) => void;
};

const PlanningOrderDriverPlanListingItemComponent: FC<
  PlanningOrderDriverPlanListingItemProps
> = (props) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing.chart;

  const chartData: PlanningOrderDriverPlanListingItemChart =
    planningOrderDriverListingChartDataFactory.createChartItemData(
      props.item,
      props.order
    );

  const data: ChartData =
    planningOrderDriverListingChartDataFactory.createChartItems(chartData);

  return (
    <div className="planning_order_driver_plan_item" key={props.item.user.uuid}>
      <Row>
        <Column lg={4} xl={3}>
          <PlanningOrderDriverPlanListingItemDetailsComponent
            user={props.item.user}
            planEntries={props.item.targetPlanEntryData}
            taxiDriverAssociationUuid={props.item.taxiDriverAssociationUuid}
            onClick={props.onClick}
            id={props.item.id!}
          />
        </Column>
        <Column lg={8} xl={9}>
          <div ref={chartRef} className="planning_order_driver_chart">
            <ChartComponent
              data={data}
              parentRef={chartRef}
              barHeight={15}
              domainPadding={27}
              tickLablesFontSize={11}
              barLabelsAngle={-50}
              referenceGroupValue={PlanXAxisValue.FIRST_ROW}
              topLabelFontSize={11}
              topLabelGroupName={translations.routeLabel}
              barLabelsLeftPadding={-10}
              barLabelsTopPadding={10}
            />
          </div>
        </Column>
      </Row>
    </div>
  );
};

export default PlanningOrderDriverPlanListingItemComponent;
