import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import ButtonComponent from "../../../../common/components/button/button.component";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import useTaxiOfficerEditUserPermissions from "./common/user-permissions/use-taxi-officer-edit-user-permissions";
import TaxiOfficerEditViewMode from "./common/taxi-officer-edit-view-mode";
import TaxiOfficerEditByRailyComponent from "./by-raily/taxi-officer-edit-by-raily.component";
import TaxiOfficerEditByTaxiComponent from "./by-taxi/taxi-officer-edit-by-taxi.component";
import { useParams } from "react-router-dom";
import UserTaxiOfficerEditRouteParams from "../../common/routes/types/user-taxi-officer-edit-route-params";

type TaxiOfficerEditProps = {};

const TaxiOfficerEditComponent: FC<TaxiOfficerEditProps> = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { taxiOfficerUuid } = useParams<UserTaxiOfficerEditRouteParams>();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userTaxiOfficerEdit);

  const userPermissions = useTaxiOfficerEditUserPermissions();
  const translations = userTranslationsHelper.getTaxiOfficerEditTranslations();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getTaxiOfficerEditBreadcrumbs({
      taxiOfficerUuid: taxiOfficerUuid!,
    });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<TaxiOfficerEditViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(TaxiOfficerEditViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(TaxiOfficerEditViewMode.TAXI)}
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: TaxiOfficerEditViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: TaxiOfficerEditViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToTaxiButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: TaxiOfficerEditViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: TaxiOfficerEditViewMode.RAILY,
        component: (
          <TaxiOfficerEditByRailyComponent
            changeViewButtons={getViewChangeButtons(
              TaxiOfficerEditViewMode.RAILY
            )}
            taxiOfficerUuid={taxiOfficerUuid!}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: TaxiOfficerEditViewMode.TAXI,
        component: (
          <TaxiOfficerEditByTaxiComponent
            changeViewButtons={getViewChangeButtons(
              TaxiOfficerEditViewMode.TAXI
            )}
            taxiOfficerUuid={taxiOfficerUuid!}
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default TaxiOfficerEditComponent;
