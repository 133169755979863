import { SearchRoadRoutingResponseRouteLeg } from "../../../../../../../common/utils/search-road-route/search-road-routing.response";
import { OrderRouteEditSolvedRidesResponseRideSolvedWaypoint } from "../../../api/order-route-edit-solved-rides.response";
import orderRouteEditHelper from "../../../helper/order-route-edit.helper";
import OrderRouteEditPassenger from "../../../types/order-route-edit-passenger";
import OrderRouteEditRouteItem, {
  OrderRouteEditRouteWaypoint,
  OrderRouteEditRouteWaypointGroup,
  OrderRouteEditRouteWaypointGroupWaypoint,
} from "../../../types/order-route-edit-route-waypoint";
import OrderRouteEditSummaryRouteWaypoint from "../route/types/order-route-edit-summary-route-waypoint";

const createSummaryRouteWaypointForWaypoint = (
  orderRouteEditRouteItem: OrderRouteEditRouteWaypoint,
  index: number,
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[],
  solvedWaypoint:
    | OrderRouteEditSolvedRidesResponseRideSolvedWaypoint
    | undefined
): OrderRouteEditSummaryRouteWaypoint => {
  const routeFragementDetails = responseRouteLegs.find((responseRouteLeg) => {
    return (
      responseRouteLeg.end.latitude ===
        orderRouteEditRouteItem.address?.latitude &&
      responseRouteLeg.end.longitude ===
        orderRouteEditRouteItem.address.longitude
    );
  });

  const estimatedDate = solvedWaypoint?.meta.estimated_time
    ? new Date(solvedWaypoint.meta.estimated_time)
    : null;

  const distance = responseRouteLegs.length
    ? routeFragementDetails?.distance ?? 0
    : null;

  const onboardingPassengers: OrderRouteEditPassenger[] =
    orderRouteEditRouteItem.onboardingPassengerListItems.map(
      (listItem) => listItem.passenger
    );
  const outboardingPassengers: OrderRouteEditPassenger[] =
    orderRouteEditRouteItem.outboardingPassengerListItems.map(
      (listItem) => listItem.passenger
    );

  return {
    stageNo: index + 1,
    uuid: orderRouteEditRouteItem.uuid,
    onboardingPassengers,
    outboardingPassengers,
    orderedDate: orderRouteEditRouteItem.date,
    estimatedDate,
    distance,
    haltingTime: orderRouteEditRouteItem.haltingTimeMinutes
      ? orderRouteEditRouteItem.haltingTimeMinutes * 60
      : 0,
    place: orderRouteEditRouteItem.address
      ? {
          displayName: orderRouteEditRouteItem.address.displayName,
          latitude: orderRouteEditRouteItem.address.latitude,
          longitude: orderRouteEditRouteItem.address.longitude,
        }
      : null,
  };
};

const createSummaryRouteWaypointForWaypointGroupWaypoint = (
  orderRouteEditRouteItemGroupWaypoint: OrderRouteEditRouteWaypointGroupWaypoint,
  index: number
): OrderRouteEditSummaryRouteWaypoint => {
  const orderedDate: OrderRouteEditSummaryRouteWaypoint["orderedDate"] = null;
  const estimatedDate: OrderRouteEditSummaryRouteWaypoint["estimatedDate"] =
    null;
  const distance: OrderRouteEditSummaryRouteWaypoint["distance"] = null;

  const onboardingPassengers: OrderRouteEditPassenger[] =
    orderRouteEditRouteItemGroupWaypoint.onboardingPassengerListItems.map(
      (listItem) => listItem.passenger
    );
  const outboardingPassengers: OrderRouteEditPassenger[] =
    orderRouteEditRouteItemGroupWaypoint.onboardingPassengerListItems.map(
      (listItem) => listItem.passenger
    );

  return {
    stageNo: index + 1,
    uuid: orderRouteEditRouteItemGroupWaypoint.uuid,
    onboardingPassengers,
    outboardingPassengers,
    orderedDate,
    estimatedDate,
    distance,
    haltingTime: orderRouteEditRouteItemGroupWaypoint.haltingTimeMinutes
      ? orderRouteEditRouteItemGroupWaypoint.haltingTimeMinutes * 60
      : 0,
    place: orderRouteEditRouteItemGroupWaypoint.address
      ? {
          displayName: orderRouteEditRouteItemGroupWaypoint.address.displayName,
          latitude: orderRouteEditRouteItemGroupWaypoint.address.latitude,
          longitude: orderRouteEditRouteItemGroupWaypoint.address.longitude,
        }
      : null,
  };
};

const createSummaryRouteWaypointsForWaypointGroup = (
  orderRouteEditRouteItemGroup: OrderRouteEditRouteWaypointGroup,
  index: number
): OrderRouteEditSummaryRouteWaypoint[] => {
  return orderRouteEditRouteItemGroup.waypoints.map((waypoint) => {
    return createSummaryRouteWaypointForWaypointGroupWaypoint(waypoint, index);
  });
};

const createSummaryRouteWaypoint = (
  orderRouteEditRouteItem: OrderRouteEditRouteItem,
  index: number,
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[],
  solvedWaypoint:
    | OrderRouteEditSolvedRidesResponseRideSolvedWaypoint
    | undefined
): OrderRouteEditSummaryRouteWaypoint[] => {
  const isWaypoint = orderRouteEditHelper.checkIsRouteItemAWaypoint(
    orderRouteEditRouteItem
  );

  if (isWaypoint) {
    const summaryWaypoint = createSummaryRouteWaypointForWaypoint(
      orderRouteEditRouteItem,
      index,
      responseRouteLegs,
      solvedWaypoint
    );

    return [summaryWaypoint];
  }

  return createSummaryRouteWaypointsForWaypointGroup(
    orderRouteEditRouteItem,
    index
  );
};

const createSummaryRouteWaypoints = (
  orderRouteEditRouteWaypoints: OrderRouteEditRouteItem[],
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[],
  solvedWaypoints: OrderRouteEditSolvedRidesResponseRideSolvedWaypoint[]
): OrderRouteEditSummaryRouteWaypoint[] => {
  let summaryWaypoints: OrderRouteEditSummaryRouteWaypoint[] = [];

  orderRouteEditRouteWaypoints.forEach((waypoint, index) => {
    const solvedWaypoint = solvedWaypoints.find(
      (solvedWaypoint) => solvedWaypoint.meta.id === waypoint.uuid
    );

    const newSummaryWaypoints = createSummaryRouteWaypoint(
      waypoint,
      index,
      responseRouteLegs,
      solvedWaypoint
    );

    summaryWaypoints.push(...newSummaryWaypoints);
  });

  return summaryWaypoints;
};

const orderRouteEditSummaryFactory = {
  createSummaryRouteWaypoints,
};

export default orderRouteEditSummaryFactory;
