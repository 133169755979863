import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import userFormValidationService from "../../../../../shared/user/user.validation.service";

const validateDispatch = (dispatchUuid: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).required();

  return formValidationService.validate(dispatchUuid, validationSchema);
};

const dispatcherEditFormValidationService = {
  validateEmail: userFormValidationService.validateEmail,
  validateUsername: userFormValidationService.validateUsername,
  validateFirstName: userFormValidationService.validateFirstName,
  validateLastName: userFormValidationService.validateLastName,
  validateMobile: userFormValidationService.validateMobile,
  validateAlternativeMobile:
    userFormValidationService.validateAlternativeMobile,
  validateDispatch,
};

export default dispatcherEditFormValidationService;
