import classNames from "classnames";
import { FC } from "react";
import { SteppedLineTo } from "react-lineto";
import googleMapsRouteService from "../../../../../../common/utils/google-maps-route/google-maps-route.service";
import { Link } from "react-router-dom";
import RouteDetailsRouteWaypoint from "../../types/route-details-route-waypoint";
import { RouteDetailsPassenger } from "../../../../../../common/services/route/details/route-details";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import routeDetailsRouteHelper from "./route-details-route.helper";

type RouteDetailsRouteProps = {
  waypoints: RouteDetailsRouteWaypoint[];
  routeUuid: string;
  selectedPassenger: RouteDetailsPassenger | null;
};

const RouteDetailsRouteComponent: FC<RouteDetailsRouteProps> = (props) => {
  const translations = routeTranslationsHelper.getDetailsTranslations().routes;

  const lineConnections =
    routeDetailsRouteHelper.getDefinitionOfLineConnectionsBetweenWaypointsInList(
      props.waypoints,
      props.routeUuid
    );

  return (
    <div className="route_details_routes">
      <div>{translations.headingLabel}</div>
      <ul className="route_details_routes_list">
        <li className="route_details_route">
          <div className="route_details_route__place">
            {translations.placeHeaderLabel}
          </div>
          <div
            className="route_details_route__ordered_date"
            title={translations.orderedDateHeaderTitle}
          >
            {translations.orderedDateHeaderLabel}
          </div>
          <div
            className="route_details_route__estimated_date"
            title={translations.estimatedDateHeaderTitle}
          >
            {translations.estimatedDateHeaderLabel}
          </div>
          <div
            className="route_details_route__halting_date"
            title={translations.haltingDateHeaderTitle}
          >
            {translations.haltingDateHeaderLabel}
          </div>
          <div
            className="route_details_route__distance"
            title={translations.distanceHeaderTitle}
          >
            {translations.distanceHeaderLabel}
          </div>
          <div
            className="route_details_route__completion_date"
            title={translations.completionDateTitle}
          >
            {translations.completionDateLabel}
          </div>
        </li>
        {props.waypoints.map((waypoint, index) => {
          const isHighlightedAsPassengerOnboardPlace =
            props.selectedPassenger &&
            waypoint.onboardingPassengersUuids.includes(
              props.selectedPassenger?.uuid
            );
          const isHighlightedAsPassengerOutboardPlace =
            props.selectedPassenger &&
            waypoint.outboardingPassengersUuids.includes(
              props.selectedPassenger?.uuid
            );
          const isFaded =
            props.selectedPassenger &&
            !isHighlightedAsPassengerOnboardPlace &&
            !isHighlightedAsPassengerOutboardPlace;

          const waypointGoogleMapsRouteUrl =
            !!props.waypoints.length &&
            index > 0 &&
            props.waypoints.length > index
              ? googleMapsRouteService.createGoogleMapsRouteUrl(
                  props.waypoints
                    .slice(index - 1, index + 1)
                    .map((waypoint) => ({
                      latitude: waypoint.place.latitude,
                      longitude: waypoint.place.longitude,
                    }))
                )
              : "";

          return (
            <li
              key={`waypoint-${waypoint.place.latitude}${waypoint.place.longitude}-index-${index}`}
              className={classNames(
                "route_details_route",
                `route-uuid-${props.routeUuid}`,
                isHighlightedAsPassengerOnboardPlace && "onboard_highlight",
                isHighlightedAsPassengerOutboardPlace && "outboard_highlight",
                isFaded && "faded"
              )}
            >
              <div
                className="route_details_route__place"
                title={waypoint.place.displayName}
              >
                <div className="route_details_route__place__index">
                  {waypoint.markerPosition}
                </div>
                <div
                  className={classNames(
                    "dot",
                    routeDetailsRouteHelper.generateWaypointDotClassname(
                      waypoint,
                      props.routeUuid
                    )
                  )}
                ></div>
                <div className="route_details_route__place__name">
                  {!!waypointGoogleMapsRouteUrl ? (
                    <Link
                      className="route_details_route__place__name__link"
                      to={waypointGoogleMapsRouteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {waypoint.place.displayName}
                    </Link>
                  ) : (
                    waypoint.place.displayName
                  )}
                </div>
              </div>
              <div
                className="route_details_route__ordered_date"
                title={routeDetailsRouteHelper.getOrderedDateTitle(
                  waypoint.orderedDate
                )}
              >
                {routeDetailsRouteHelper.getOrderedDateLabel(
                  waypoint.orderedDate
                )}
              </div>
              <div
                className="route_details_route__estimated_date"
                title={routeDetailsRouteHelper.getEstimatedDateTitle(
                  waypoint.estimatedDate
                )}
              >
                {routeDetailsRouteHelper.getEstimatedDateLabel(
                  waypoint.estimatedDate
                )}
              </div>
              <div className="route_details_route__halting_date">
                {routeDetailsRouteHelper.getHaltingTimeLabel(
                  waypoint.haltingTime
                )}
              </div>
              <div className="route_details_route__distance">
                {routeDetailsRouteHelper.getDistanceLabel(waypoint.distance)}
              </div>
              <div
                className="completion_date"
                title={routeDetailsRouteHelper.getCompletionDateTitle(
                  waypoint.completionDate
                )}
              >
                {routeDetailsRouteHelper.getCompletionDateLabel(
                  waypoint.completionDate
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {lineConnections.map((item, index) => {
        return (
          <SteppedLineTo
            key={`waypoint-list-connection-line-index-${index}`}
            from={item.itemFromClassname}
            to={item.itemToClassname}
            delay={50}
            borderStyle={item.lineType}
            borderWidth={1}
            borderColor={"#000"}
            within={`route_details_route route-uuid-${props.routeUuid}`}
            className="route_details_route__connection_line"
          />
        );
      })}
    </div>
  );
};

export default RouteDetailsRouteComponent;
