import { faFilePen, faIcons } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import orderRoutesHelper from "../routes/order-routes.helper";
import OrderBreadcrumbsRouteEditParams from "./types/order-breadcrumbs-route-edit-params";
import OrderBreadcrumbsOrderJoinParams from "./types/order-breadcrumbs-order-join-params";

const getActiveListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: orderRoutesHelper.getListingOfActiveOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .orderActiveListing,
    },
  ];
};

const getAddBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: orderRoutesHelper.getAddRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations().orderAdd,
    },
  ];
};

const getEditBreadcrumbs = (
  params: OrderBreadcrumbsRouteEditParams
): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: orderRoutesHelper.getListingOfActiveOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .orderActiveListing,
    },
    {
      icon: faIcons,
      linkTo: `${orderRoutesHelper.getListingOfActiveOrdersRoute({
        defaultSelectedOrderUuid: params.orderUuid,
      })}`,
      text: `${params.cargoInternalOrderId}`,
    },
    {
      icon: faFilePen,
      linkTo: orderRoutesHelper.getEditRoute({
        orderId: params.orderUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations().orderRouteEdit,
    },
  ];
};

const getFinishedListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: orderRoutesHelper.getListingOfFinishedOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .orderFinishedListing,
    },
  ];
};

const getAbandonedListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: orderRoutesHelper.getListingOfAbandonedOrdersRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .orderAbandonedListing,
    },
  ];
};

const getOrderJoinBreadcrumbs = (
  params: OrderBreadcrumbsOrderJoinParams
): Breadcrumb[] => {
  return [
    {
      icon: faFilePen,
      linkTo: orderRoutesHelper.getJoinRoute({
        sourceOrders: params.sourceOrders,
        targetOrderUuid: params.targetOrderUuid,
      }),
      text: appTranslationsHelper.getBreadcrumbsTranslations().orderJoin,
    },
  ];
};

const orderBreadcrumbsHelper = {
  getActiveListingBreadcrumbs,
  getAddBreadcrumbs,
  getFinishedListingBreadcrumbs,
  getAbandonedListingBreadcrumbs,
  getEditBreadcrumbs,
  getOrderJoinBreadcrumbs,
};

export default orderBreadcrumbsHelper;
