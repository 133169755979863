import appConfig from "../../../../../../../app.config";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import authService from "../../../../../../auth/common/auth.service";
import DriverDetailsAcceptCandidatureResponse from "./driver-details-accept-candidature.response";
import {
  DriverDetailsAcceptPlanCandidatureNotAcceptableResponseError,
  DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType,
} from "./driver-details-accept-plan-candidature-error.response";
import DriverDetailsCandidatureResponse from "./driver-details-candidature.response";
import DriverDetailsDeleteCandidatureResponse from "./driver-details-delete-candidature.response";
import DriverDetailsRoutePlansAfterCandidatureApprovalResponse from "./driver-details-route-plans-after-candidature-approval.response";

const fetchActiveCandidature = async (
  driverUuid: string
): Promise<DriverDetailsCandidatureResponse> => {
  const path = `/drivers/${driverUuid}/candidature`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchDriverRoutePlansAfterCandidatureApproval = async (
  driverUuid: string
): Promise<DriverDetailsRoutePlansAfterCandidatureApprovalResponse> => {
  const path = `/drivers/${driverUuid}/potential-plan`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const acceptPlanCandidature = async (
  driverUuid: string
): Promise<DriverDetailsAcceptCandidatureResponse> => {
  const path = `/drivers/${driverUuid}/plan`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const deletePlanCandidature = async (
  driverUuid: string
): Promise<DriverDetailsDeleteCandidatureResponse> => {
  const path = `/drivers/${driverUuid}/candidature`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const acceptPlanCandidatureResolveApiErrorLabel = (
  error: DriverDetailsAcceptPlanCandidatureNotAcceptableResponseError
): string => {
  const translations =
    userTranslationsHelper.getDriverDetailsCandidatureTranslations().management;
  {
    switch (error.error_type) {
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION:
        return translations.apiErrors.taxiDriverAssocationNotExistsErrorLabel;
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND:
        return translations.apiErrors.orderNotExistsErrorLabel;
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS:
        return translations.apiErrors.orderInProgressErrorLabel;
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT:
        return translations.apiErrors.taxiDriverContractNotValidErrorLabel;
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT:
        return translations.apiErrors.taxiRailyContractNotValidErrorLabel;
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION:
        return translations.apiErrors
          .candidateFitsWithPlanEntryCollisionErrorLabel;
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME:
        return translations.apiErrors.candidateFitsWithNotEnoughTimeErrorLabel;
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_COLLISION:
        return translations.apiErrors.planEntryCandidateCollisionErrorLabel;
      case DriverDetailsAcceptPlanCandidatureNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR:
        return translations.apiErrors.planEntryCandidateStartTimeExpiredError;
      default:
        return translations.failureCandidatureAcceptanceNotificationText;
    }
  }
};

const driverDetailsCandidatureApiService = {
  fetchActiveCandidature,
  fetchDriverRoutePlansAfterCandidatureApproval,
  acceptPlanCandidature,
  deletePlanCandidature,
  acceptPlanCandidatureResolveApiErrorLabel,
};

export default driverDetailsCandidatureApiService;
