import PlannerDriverPlanOrderResponse, {
  PlannerDriverPlanOrderResponseItem,
  PlannerDriverPlanOrderResponseItemNodeData,
} from "../../../api/raily/planner/order/planning-driver-plan-order.response";
import PlannerDriverPlanOrder, {
  PlannerDriverPlanOrderItem,
  PlannerDriverPlanOrderItemOrderPoint,
} from "./planner-driver-plan-order";

const createDataItemOrderWaypoint = (
  data: PlannerDriverPlanOrderResponseItemNodeData
): PlannerDriverPlanOrderResponseItemNodeData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createDataItemOrderWaypoints = (
  item: PlannerDriverPlanOrderResponseItemNodeData[]
): PlannerDriverPlanOrderItemOrderPoint[] => {
  return item.map(createDataItemOrderWaypoint);
};

const createDataItem = (
  item: PlannerDriverPlanOrderResponseItem
): PlannerDriverPlanOrderItem => {
  return {
    orderUuid: item.humanId,
    orders: createDataItemOrderWaypoints(item.waypoints),
  };
};

const create = (
  item: PlannerDriverPlanOrderResponse
): PlannerDriverPlanOrder => {
  return {
    data: createDataItem(item.data),
  };
};

const plannerDriverPlanOrderFactory = {
  create,
};

export default plannerDriverPlanOrderFactory;
