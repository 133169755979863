enum DealerListingFilterType {
  TAXI_CORPORATION = "TAXI_CORPORATION",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  USERNAME = "USERNAME",
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  ACTIVITY_STATUS = "ACTIVITY_STATUS",
}

export default DealerListingFilterType;
