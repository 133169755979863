import { FC } from "react";
import CargoOrderSettlementListingBillingsOrderData from "../types/cargo-order-settlement-listing-billings-accept-data";
import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import cargoOrderSettlementListingBillingsAcceptHelper from "./cargo-order-settlement-listing-billings-accept.helper";
import ButtonComponent from "../../../../../../common/components/button/button.component";

type CargoOrderSettlementListingBillingsAcceptProps = {
  billingsAcceptData: CargoOrderSettlementListingBillingsOrderData[];
  onSubmit: () => void;
  onCancel: () => void;
};

const CargoOrderSettlementListingBillingsAcceptComponent: FC<
  CargoOrderSettlementListingBillingsAcceptProps
> = (props) => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .billingsAccept;

  const selectedBillingsLabel =
    cargoOrderSettlementListingBillingsAcceptHelper.getBillingLabel(
      props.billingsAcceptData
    );

  const isSubmitButtonEnabled = !!props.billingsAcceptData.length;

  return (
    <div className="cargo_order_settlement_listing_billings_accept">
      <div className="cargo_order_settlement_listing_billings_accept__heading">
        {translations.headingLabel}
      </div>
      <div className="cargo_order_settlement_listing_billings_accept__content">
        <div>
          <div>{selectedBillingsLabel}</div>
        </div>
        <div className="d-flex mt-2">
          <ButtonComponent
            type="success"
            onClick={props.onSubmit}
            isDisabled={!isSubmitButtonEnabled}
            title={translations.submitButtonLabel}
          >
            {translations.submitButtonLabel}
          </ButtonComponent>
          <ButtonComponent
            type="brand"
            onClick={props.onCancel}
            classNames={{ root: "ml-1" }}
            title={translations.cancelButtonLabel}
          >
            {translations.cancelButtonLabel}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default CargoOrderSettlementListingBillingsAcceptComponent;
