import { UserCargoOfficerListingRouteQueryParams } from "../../../common/routes/types/user-cargo-officer-listing-route-params";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import cargoOfficerListingFilterService from "./cargo-officer-listing-filter.service";
import CargoOfficerListingSortKey from "../common/types/cargo-officer-listing-sort-key";
import CargoOfficerListingFilter from "../common/types/cargo-officer-listing-filter";

const useCargoOfficerListingFilterDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<UserCargoOfficerListingRouteQueryParams>();

  const getFilters = (): CargoOfficerListingFilter[] => {
    return cargoOfficerListingFilterService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return cargoOfficerListingFilterService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return cargoOfficerListingFilterService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): CargoOfficerListingSortKey | undefined => {
    return cargoOfficerListingFilterService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: CargoOfficerListingFilter[],
    sortKey: CargoOfficerListingSortKey | null,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      cargoOfficerListingFilterService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useCargoOfficerListingFilterDao;
