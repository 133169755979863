import PassengerListRequest from "../../api/raily/passenger/list/passenger-list.request";
import passengerApiService from "../../api/raily/passenger/passenger-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import PassengerList from "./list/passenger-list";
import PassengerListError from "./list/passenger-list-error";
import PassengerListLoadParams from "./list/passenger-list-load-params";
import passengerListRequestFactory from "./list/passenger-list-request.factory";
import passengerListFactory from "./list/passenger-list.factory";

const handlePassengerListError = (
  error: HttpError | PassengerListError
): PassengerListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: PassengerListLoadParams,
  abortSignal: AbortSignal
): Promise<PassengerList> => {
  const request: PassengerListRequest =
    passengerListRequestFactory.create(params);

  try {
    const response = await passengerApiService().getList(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const passengerList: PassengerList = passengerListFactory.create(response);

    return passengerList;
  } catch (_error) {
    const error = _error as HttpError | PassengerListError;

    throw handlePassengerListError(error);
  }
};

const passengerService = {
  getList,
};

export default passengerService;
