import { FC } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import useQueryParams from "../../../../../common/hooks/use-query-params";
import UserFormOfEmployment from "../../../../../common/types/user-form-of-employment";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import signUpDriverApiService from "../common/api/sign-up-driver-api.service";
import SignUpDriverRequest from "../common/api/sign-up-driver.request";
import { useSignUpDriverContext } from "../common/context/sign-up-driver.context";
import signUpDriverFormFactory from "../common/sign-up-driver-form.factory";
import SignUpDriverSummaryAccountComponent from "./account/sign-up-driver-summary-account.component";
import SignUpDriverSummaryCompanyComponent from "./company/sign-up-driver-summary-company.component";
import SignUpDriverSummaryUserComponent from "./user/sign-up-driver-summary-user.component";
import SignUpDriverSummaryVehicleComponent from "./vehicle/sign-up-driver-summary-vehicle.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import { useNavigate } from "react-router-dom";
import authRoutesHelper from "../../../common/routes/auth-routes.helper";
import SignUpDriverResponse from "../common/api/sign-up-driver.response";

type SignUpDriverSummaryProps = {};

const SignUpDriverSummaryComponent: FC<SignUpDriverSummaryProps> = () => {
  const { accountData, userData, companyData, vehicleData, taxiCorporationId } =
    useSignUpDriverContext();

  const driverSignUpTranslations =
    appTranslationsHelper.getDriverSignUpTranslations();

  const summaryDataFormTranslations = driverSignUpTranslations.summary;

  const shouldDisplayCompanySection =
    userData.formOfEmployment?.value === UserFormOfEmployment.B2B;

  const { token } = useQueryParams<{ token: string | undefined }>();
  const navigate = useNavigate();

  const navigateToSignInRoute = (username: string) => {
    const signInRoute = authRoutesHelper.getSignInRoute();

    navigate(signInRoute, {
      state: {
        username,
      },
    });
  };

  const onSuccessSignUp = (username: string) => {
    notificationService.success(
      driverSignUpTranslations.successSignUpNotificationText
    );

    navigateToSignInRoute(username);
  };

  const handleSubmitResponse = (response: SignUpDriverResponse) => {
    if (response.status === 201) {
      onSuccessSignUp(response.data.username);
    }
  };

  const onSubmitButtonClick = () => {
    if (!token) {
      console.error(`No token available`);
      return;
    }

    const request: SignUpDriverRequest =
      signUpDriverFormFactory.createSignUpRequest({
        accountData,
        companyData,
        userData,
        vehicleData,
        taxiCorporationId,
      });

    signUpDriverApiService.signUp(request, token).then(handleSubmitResponse);
  };

  return (
    <>
      <h1 className="sign_up_driver__step_title">
        {summaryDataFormTranslations.title}
      </h1>
      <Row>
        <Column withPaddings>
          <SignUpDriverSummaryAccountComponent />
        </Column>
        <Column withPaddings>
          <SignUpDriverSummaryUserComponent />
        </Column>
        {shouldDisplayCompanySection && (
          <Column withPaddings>
            <SignUpDriverSummaryCompanyComponent />
          </Column>
        )}
        <Column withPaddings>
          <SignUpDriverSummaryVehicleComponent />
        </Column>
      </Row>
      <div className="sign_up_driver__next_button_wrapper">
        <ButtonComponent onClick={onSubmitButtonClick} type="primary">
          {summaryDataFormTranslations.submitButtonText}
        </ButtonComponent>
      </div>
    </>
  );
};

export default SignUpDriverSummaryComponent;
