import DriverPlanCandidatureAddRequest, {
  DriverPlanCandidatureAddRequestCandidatureEntryType,
} from "../../../api/raily/driver-plan-candidature/add/driver-plan-candidature-add.request";
import DriverPlanDeleteOrderParams from "./driver-plan-delete-order-params";

const create = (
  params: DriverPlanDeleteOrderParams
): DriverPlanCandidatureAddRequest => {
  return {
    driverId: params.driverUuid,
    acceptImmediately: true,
    candidatureEntries: [
      {
        candidateEntryType:
          DriverPlanCandidatureAddRequestCandidatureEntryType.DELETE_SOLVED_ORDER,
        deleteTargetId: params.solvedOrderUuid,
      },
    ],
  };
};

const driverPlanDeleteOrderRequestFactory = {
  create,
};

export default driverPlanDeleteOrderRequestFactory;
