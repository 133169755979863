import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DriverDetailsUserPermissionOption =
  | "hasAccessToManageCandidature"
  | "hasAccessToAcceptCandidature"
  | "hasAccessToDeleteCandidature"
  | "hasAccessToAddCheckoutEvent"
  | "hasAccessToEditCheckoutEvent"
  | "hasAccessToDeleteCheckoutEvent";

export type DriverDetailsUserPermissionsDefinition =
  UserPermissionsDefinition<DriverDetailsUserPermissionOption>;

export type DriverDetailsUserPermissions =
  UserPermissions<DriverDetailsUserPermissionOption>;

const driverDetailsUserPermissionDefinition: DriverDetailsUserPermissionsDefinition =
  {
    hasAccessToManageCandidature: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    hasAccessToAcceptCandidature: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    hasAccessToDeleteCandidature: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    hasAccessToAddCheckoutEvent: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    hasAccessToEditCheckoutEvent: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    hasAccessToDeleteCheckoutEvent: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
  };

export default driverDetailsUserPermissionDefinition;
