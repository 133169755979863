import DateRange from "../../../../../common/types/date-range";
import paginationService from "../../../../../common/utils/pagination/pagination.service";
import { OrderAbandonedListingRequest } from "../api/order-abandoned-listing.request";
import orderAbandonedListingHelper from "../helper/order-abandoned-listing.helper";
import OrderAbandonedListingFilter, {
  OrderAbandonedListingCargoCompanyExternalOrderIdFilter,
  OrderAbandonedListingCargoCompanyInternalOrderIdFilter,
  OrderAbandonedListingClientFilter,
  OrderAbandonedListingDispatchFilter,
  OrderAbandonedListingPassengerFilter,
  OrderAbandonedListingRouteAddressFilter,
  OrderAbandonedListingRouteDestinationAddressFilter,
  OrderAbandonedListingRouteIntermediateAddressFilter,
  OrderAbandonedListingRoutePickupAddressFilter,
} from "../types/order-abandoned-listing-filter";
import OrderAbandonedListingFilterType from "../types/order-abandoned-listing-filter-type";
import OrderAbandonedListingSortKey from "../types/order-abandoned-listing-sort-key";

const createFetchListingRequest = (
  filters: OrderAbandonedListingFilter[],
  sortKey: OrderAbandonedListingSortKey | null,
  startOrderDateRange: DateRange | null,
  pageSize: number,
  page: number
): OrderAbandonedListingRequest => {
  const orderValue = orderAbandonedListingHelper.getRequestOrderValue(sortKey);
  const startOrderDateRequest = getStartOrderDateRequest(
    startOrderDateRange?.from,
    startOrderDateRange?.to
  );

  const offset = paginationService.calculateOffset(page, pageSize);

  const result: OrderAbandonedListingRequest = {
    order: orderValue,
    limit: pageSize,
    offset: offset,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        OrderAbandonedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID
    )?.value as
      | OrderAbandonedListingCargoCompanyInternalOrderIdFilter["value"][]
      | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type ===
        OrderAbandonedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID
    )?.value as
      | OrderAbandonedListingCargoCompanyExternalOrderIdFilter["value"][]
      | undefined,
    client: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.CLIENT
    )?.value as OrderAbandonedListingClientFilter["value"][] | undefined,
    dispatch: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.DISPATCH
    )?.value as OrderAbandonedListingDispatchFilter["value"][] | undefined,
    address: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.ROUTE_ADDRESS
    )?.value as OrderAbandonedListingRouteAddressFilter["value"][] | undefined,
    passenger: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.PASSENGER
    )?.value as OrderAbandonedListingPassengerFilter["value"][] | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type === OrderAbandonedListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | OrderAbandonedListingRoutePickupAddressFilter["value"][]
      | undefined,
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        OrderAbandonedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | OrderAbandonedListingRouteIntermediateAddressFilter["value"][]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        OrderAbandonedListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | OrderAbandonedListingRouteDestinationAddressFilter["value"][]
      | undefined,
    start_time_since: startOrderDateRequest?.start_time_since,
    start_time_to: startOrderDateRequest?.start_time_to,
  };

  return result;
};

const getStartOrderDateRequest = (
  fromDate: Date | null | undefined,
  toDate: Date | null | undefined
) => {
  if (!fromDate || !toDate) return;

  return {
    start_time_since: fromDate.toJSON(),
    start_time_to: toDate.toJSON(),
  };
};

const orderAbandonedListingRequestFactory = {
  createFetchListingRequest,
};

export default orderAbandonedListingRequestFactory;
