import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";
import UserRole from "../../../../../../common/types/user-role";

export type TaxiOfficerEditUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type TaxiOfficerEditUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiOfficerEditUserPermissionOption>;

const taxiOfficerEditUserPermissionsDefinition: TaxiOfficerEditUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default taxiOfficerEditUserPermissionsDefinition;
