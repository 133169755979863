import { OrderDetailsAvailableConsumerResponse } from "../../api/forwarding/order-details-available-consumer.response";
import OrderDetailsForwardingAvailableConsumer from "./order-details-forwarding-available-consumer";
import OrderDetailsForwardingAvailableConsumerSelectOption from "./order-details-forwarding-available-consumer-select-option";

const createAvailableConsumer = (
  responseTaxiAvailableConsumers: OrderDetailsAvailableConsumerResponse
): OrderDetailsForwardingAvailableConsumer => {
  return {
    id: responseTaxiAvailableConsumers.id,
    name: responseTaxiAvailableConsumers.name,
  };
};

const createSelectOption = (
  taxiAvailableConsumers: OrderDetailsForwardingAvailableConsumer
): OrderDetailsForwardingAvailableConsumerSelectOption => {
  return {
    label: taxiAvailableConsumers.name,
    value: taxiAvailableConsumers,
  };
};

const createSelectOptions = (
  responseTaxiAvailableConsumers: OrderDetailsAvailableConsumerResponse[]
): OrderDetailsForwardingAvailableConsumerSelectOption[] => {
  const taxiCorporations = responseTaxiAvailableConsumers.map(
    createAvailableConsumer
  );

  return taxiCorporations.map(createSelectOption);
};

const orderDetailsForwardingAvailableConsumerFactory = {
  createSelectOptions,
};

export default orderDetailsForwardingAvailableConsumerFactory;
