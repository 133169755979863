import { CargoOrderActiveListItem } from "../../../../common/services/cargo-order/active-list/cargo-order-active-list";
import OrderActiveListingSortKey from "./types/order-active-listing-sort-key";

const sortByInternalOrderIdAsc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.internalOrderId - itemB.internalOrderId
  );
};

const sortByInternalOrderIdDesc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.internalOrderId - itemA.internalOrderId
  );
};

const sortByAppUserAsc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      Number(itemB.isMobileAppUser) - Number(itemA.isMobileAppUser)
  );
};

const sortByAppUserDesc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort(
    (itemA, itemB) =>
      Number(itemA.isMobileAppUser) - Number(itemB.isMobileAppUser)
  );
};

const sortByExecutionStatusAsc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemA.executionStatus.localeCompare(itemB.executionStatus)
  );
};

const sortByExecutionStatusDesc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemB.executionStatus.localeCompare(itemA.executionStatus)
  );
};

const sortByTimeToStartAsc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort((itemA, itemB) => {
    const itemATimeLeft =
      new Date().getTime() - itemA.estimatedStartDate.getTime();
    const itemBTimeLeft =
      new Date().getTime() - itemB.estimatedStartDate.getTime();

    if (itemATimeLeft < itemBTimeLeft) {
      return 1;
    }

    if (itemATimeLeft > itemBTimeLeft) {
      return -1;
    }

    return 0;
  });
};

const sortByTimeToStartDesc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort((itemA, itemB) => {
    const itemATimeLeft =
      new Date().getTime() - itemA.estimatedStartDate.getTime();
    const itemBTimeLeft =
      new Date().getTime() - itemB.estimatedStartDate.getTime();

    if (itemATimeLeft < itemBTimeLeft) {
      return -1;
    }

    if (itemATimeLeft > itemBTimeLeft) {
      return 1;
    }

    return 0;
  });
};

const sortByUrgentAsc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort((itemA, itemB) => {
    const isItemAUrgent = Object.values(itemA.attentions).includes(true);
    const isItemBUrgent = Object.values(itemB.attentions).includes(true);

    return isItemAUrgent && !isItemBUrgent
      ? -1
      : !isItemAUrgent && isItemBUrgent
      ? 1
      : 0;
  });
};

const sortByUrgentDesc = (
  listingItems: CargoOrderActiveListItem[]
): CargoOrderActiveListItem[] => {
  return listingItems.sort(
    (itemA, itemB) => Number(itemA.attentions) - Number(itemB.attentions)
  );
};

const sortListingItems = (
  listingItems: CargoOrderActiveListItem[],
  sortKey: OrderActiveListingSortKey | null
): CargoOrderActiveListItem[] => {
  switch (sortKey) {
    case OrderActiveListingSortKey.INTERNAL_ORDER_ID_ASC:
      return sortByInternalOrderIdAsc(listingItems);
    case OrderActiveListingSortKey.INTERNAL_ORDER_ID_DESC:
      return sortByInternalOrderIdDesc(listingItems);
    case OrderActiveListingSortKey.IS_MOBILE_APP_USER_ASC:
      return sortByAppUserAsc(listingItems);
    case OrderActiveListingSortKey.IS_MOBILE_APP_USER_DESC:
      return sortByAppUserDesc(listingItems);
    case OrderActiveListingSortKey.EXECUTION_STATUS_ASC:
      return sortByExecutionStatusAsc(listingItems);
    case OrderActiveListingSortKey.EXECUTION_STATUS_DESC:
      return sortByExecutionStatusDesc(listingItems);
    case OrderActiveListingSortKey.TIME_TO_START_ASC:
      return sortByTimeToStartAsc(listingItems);
    case OrderActiveListingSortKey.TIME_TO_START_DESC:
      return sortByTimeToStartDesc(listingItems);
    case OrderActiveListingSortKey.URGENT_ASC:
      return sortByUrgentAsc(listingItems);
    case OrderActiveListingSortKey.URGENT_DESC:
      return sortByUrgentDesc(listingItems);
    default:
      return listingItems;
  }
};

const orderActiveListingSortingService = {
  sortListingItems,
};

export default orderActiveListingSortingService;
