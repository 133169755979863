enum PenaltyType {
  OPTIMAZATION_REFUSED = "OPTIMIZATION_REFUSED",
  BEING_LATE = "BEING_LATE",
  INCOMPATIBILE_CAR = "INCOMPATIBLE_CAR",
  HIGHWAY_NOT_ALLOWED = "HIGHWAY_NOT_ALLOWED",
  SUBOPTIMAL_RIDE = "SUBOPTIMAL_RIDE",
  GUIDELINE_IGNORED = "GUIDELINE_IGNORED",
  OTHER = "OTHER",
}

export default PenaltyType;
