import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import DelegationAddComponent from "../../add/delegation-add-component";
import DelegationListingComponent from "../../listing/delegation-listing.component";
import delegationRoutesUrls from "./delegation-routes-urls";

const delegationRoutesDefinition: RouteItemDefinition[] = [
  {
    path: delegationRoutesUrls.listing,
    component: <DelegationListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  },
  {
    path: delegationRoutesUrls.add,
    component: <DelegationAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  },
];

export default delegationRoutesDefinition;
