import formValidationService from "../../../../../../../common/utils/validation/form-validation.service";
import DriverContractAddByTaxiFormData from "./types/driver-contract-add-by-taxi-form-data";
import DriverContractAddByTaxiFormValidationResults from "./types/driver-contract-add-by-taxi-form-validation-results";

const getDefaultFormValidationResults =
  (): DriverContractAddByTaxiFormValidationResults => {
    return {
      periodOfValidity: formValidationService.defaultValidationResult,
      billingModel: formValidationService.defaultValidationResult,
      distanceRate: formValidationService.defaultValidationResult,
      haltingTimeRate: formValidationService.defaultValidationResult,
      haltingTimeGracePeriod: formValidationService.defaultValidationResult,
      isActive: formValidationService.defaultValidationResult,
      notes: formValidationService.defaultValidationResult,
    };
  };

const getDefaultFormData = (): DriverContractAddByTaxiFormData => {
  return {
    periodOfValidity: null,
    billingModel: null,
    distanceRate: null,
    haltingTimeRate: null,
    haltingTimeGracePeriodMinutes: null,
    isActive: null,
    notes: null,
  };
};

const driverContractAddByTaxiFormHelper = {
  getDefaultFormData,
  getDefaultFormValidationResults,
};

export default driverContractAddByTaxiFormHelper;
