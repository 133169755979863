import ButtonComponent from "../../../../../common/components/button/button.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import useQueryParams from "../../../../../common/hooks/use-query-params";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import signUpPassengerApiService from "../common/api/sign-up-passenger-api.service";
import SignUpPassengerRequest from "../common/api/sign-up-passenger.request";
import { useSignUpPassengerContext } from "../common/context/sign-up-passenger.context";
import SignUpPassengerSummaryAccountComponent from "./account/sign-up-passenger-summary-account.component";
import SignUpPassengerSummaryUserComponent from "./user/sign-up-passenger-summary-user.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import { useNavigate } from "react-router-dom";
import authRoutesHelper from "../../../common/routes/auth-routes.helper";
import SignUpPassengerResponse from "../common/api/sign-up-passenger.response";
import { FC } from "react";
import signUpPassengerFactory from "../common/sign-up-passenger.factory";

const SignUpPassengerSummaryComponent: FC = () => {
  const { accountData, userData, dispatchId } = useSignUpPassengerContext();
  const passengerSignUpTranslations =
    appTranslationsHelper.getPassengerSignUpTranslations();

  const summaryDataFormTranslations = passengerSignUpTranslations.summary;

  const { token } = useQueryParams<{ token: string | undefined }>();
  const navigate = useNavigate();

  const onSuccessSignUp = (username: string) => {
    notificationService.success(
      passengerSignUpTranslations.successSignUpNotificationText
    );

    navigate(authRoutesHelper.getSignInRoute(), {
      state: {
        username,
      },
    });
  };

  const handleSubmitResponse = (response: SignUpPassengerResponse) => {
    if (response.status === 201) {
      onSuccessSignUp(response.data.username);
    }
  };

  const onSubmitButtonClick = () => {
    if (!token) {
      console.error(`No token available`);
      return;
    }

    const request: SignUpPassengerRequest =
      signUpPassengerFactory.createSignUpRequest({
        accountData,
        userData,
        dispatchId,
      });

    signUpPassengerApiService.signUp(request, token).then(handleSubmitResponse);
  };

  return (
    <>
      <h1 className="sign_up_passenger__step_title">
        {summaryDataFormTranslations.title}
      </h1>
      <Row>
        <Column withPaddings>
          <SignUpPassengerSummaryAccountComponent />
        </Column>
        <Column withPaddings>
          <SignUpPassengerSummaryUserComponent />
        </Column>
      </Row>
      <div className="sign_up_passenger__next_button_wrapper">
        <ButtonComponent onClick={onSubmitButtonClick} type="primary">
          {summaryDataFormTranslations.submitButtonText}
        </ButtonComponent>
      </div>
    </>
  );
};

export default SignUpPassengerSummaryComponent;
