import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import orderAbandonedListingHelper from "../../common/helper/order-abandoned-listing.helper";
import OrderAbandonedListingFilterType from "../../common/types/order-abandoned-listing-filter-type";
import OrderAbandonedListingFilter from "../../common/types/order-abandoned-listing-filter";

const getBadgeFilterNameTranslationByType = (
  filterType: OrderAbandonedListingFilterType
): string => {
  const filterTranslations =
    orderTranslationsHelper.getAbandonedListingTranslations().filters;

  const options: {
    translation: string;
    type: OrderAbandonedListingFilterType;
  }[] = [
    {
      translation: filterTranslations.clientFilterName,
      type: OrderAbandonedListingFilterType.CLIENT,
    },
    {
      translation: filterTranslations.dispatchFilterName,
      type: OrderAbandonedListingFilterType.DISPATCH,
    },

    {
      translation: filterTranslations.cargoCompanyInternalOrderIdFilterName,
      type: OrderAbandonedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID,
    },
    {
      translation: filterTranslations.cargoCompanyExternalOrderIdFilterName,
      type: OrderAbandonedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID,
    },
    {
      translation: filterTranslations.routeAddressFilterName,
      type: OrderAbandonedListingFilterType.ROUTE_ADDRESS,
    },
    {
      translation: filterTranslations.routeDestinationAddressFilterName,
      type: OrderAbandonedListingFilterType.ROUTE_DESTINATION_ADDRESS,
    },
    {
      translation: filterTranslations.routeIntermediateAddressFilterName,
      type: OrderAbandonedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
    },
    {
      translation: filterTranslations.routePickupAddressFilterName,
      type: OrderAbandonedListingFilterType.ROUTE_PICKUP_ADDRESS,
    },

    {
      translation: filterTranslations.passengerFilterName,
      type: OrderAbandonedListingFilterType.PASSENGER,
    },
  ];

  return (
    options.find((option) => option.type === filterType)?.translation ?? ""
  );
};

const getBadgeName = (filter: OrderAbandonedListingFilter): string => {
  const filterName = getBadgeFilterNameTranslationByType(filter.type);

  return `${filterName}: ${filter.value}`;
};

const getBadgeFilterTitleTranslationByType = (
  filterType: OrderAbandonedListingFilterType
): string => {
  const filterTranslations =
    orderTranslationsHelper.getAbandonedListingTranslations().filters;

  const options: {
    translation: string;
    type: OrderAbandonedListingFilterType;
  }[] = [
    {
      translation: filterTranslations.clientFilterTitle,
      type: OrderAbandonedListingFilterType.CLIENT,
    },

    {
      translation: filterTranslations.dispatchFilterTitle,
      type: OrderAbandonedListingFilterType.DISPATCH,
    },
    {
      translation: filterTranslations.cargoCompanyInternalOrderIdFilterTitle,
      type: OrderAbandonedListingFilterType.CARGO_COMPANY_INTERNAL_ORDER_ID,
    },
    {
      translation: filterTranslations.routeAddressFilterTitle,
      type: OrderAbandonedListingFilterType.ROUTE_ADDRESS,
    },
    {
      translation: filterTranslations.routeDestinationAddressFilterTitle,
      type: OrderAbandonedListingFilterType.ROUTE_DESTINATION_ADDRESS,
    },
    {
      translation: filterTranslations.routeIntermediateAddressFilterTitle,
      type: OrderAbandonedListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
    },
    {
      translation: filterTranslations.routePickupAddressFilterTitle,
      type: OrderAbandonedListingFilterType.ROUTE_PICKUP_ADDRESS,
    },
    {
      translation: filterTranslations.cargoCompanyExternalOrderIdFilterTitle,
      type: OrderAbandonedListingFilterType.CARGO_COMPANY_EXTERNAL_ORDER_ID,
    },
    {
      translation: filterTranslations.passengerFilterTitle,
      type: OrderAbandonedListingFilterType.PASSENGER,
    },
  ];

  return (
    options.find((option) => option.type === filterType)?.translation ?? ""
  );
};

const getBadgeTitle = (filter: OrderAbandonedListingFilter): string => {
  const filterTitle = getBadgeFilterTitleTranslationByType(filter.type);

  return `${filterTitle}: ${filter.value}`;
};

const OrderAbandonedListingFiltersBadgeHelper = {
  getBadgeName,
  getBadgeTitle,
};

export default OrderAbandonedListingFiltersBadgeHelper;
