import HttpResponse from "../../../../utils/http/http.response";

export enum TaxiDetailsResponseCargoContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type TaxiDetailsResponseItemFleetPartner = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  taxiCorporationId: string;
  name: string;
};

export type TaxiDetailsResponseItem = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: TaxiDetailsResponseCargoContractMaintenancePolicy;
  notes: string | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  fleetPartners: TaxiDetailsResponseItemFleetPartner[];
};

type TaxiDetailsResponse = HttpResponse<TaxiDetailsResponseItem>;

export default TaxiDetailsResponse;
