import durationService from "../../../../../../../../common/utils/duration/duration.service";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
    .changeHaltingTime;

const getNewHaltingTimeLabel = (haltingTime: number) => {
  const formattedHaltingTime = durationService.format(haltingTime);

  return translations.newHaltingTimeTemplateText.replace(
    "#{haltingTime}",
    formattedHaltingTime
  );
};

const getNewHaltingTimeTitle = (haltingTime: number) => {
  const formattedHaltingTime = durationService.format(haltingTime);

  return translations.newHaltingTimeTemplateText.replace(
    "#{haltingTime}",
    formattedHaltingTime
  );
};

const orderDetailsHistoryEntryChangeRideContentChangeHaltingTimeHelper = {
  getNewHaltingTimeLabel,
  getNewHaltingTimeTitle,
};

export default orderDetailsHistoryEntryChangeRideContentChangeHaltingTimeHelper;
