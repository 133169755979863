import railyApiService from "../../raily-api.service";
import cargoCompanyMileageContractRequestFactory from "./details/cargo-company-mileage-contract-details-request.factory";
import CargoCompanyMileageContractDetailsRequest from "./details/cargo-company-mileage-contract-details.request";
import CargoCompanyMileageContractDetailsResponse from "./details/cargo-company-mileage-contract-details.response";

const cargoCompanyMileageContractApiService = () => {
  const getDetails = async (
    request: CargoCompanyMileageContractDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyMileageContractDetailsResponse> => {
    const url = `/contracts/mileage/cargo-company/${request.cargoCompanyUuid}`;

    const queryParams =
      cargoCompanyMileageContractRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    getDetails,
  };
};

export default cargoCompanyMileageContractApiService;
