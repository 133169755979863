import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryExternalIdUpdateEvent } from "../../../common/types/order-details-history-entry-event";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations().externalIdUpdateEntry;

const getCargoExternalOrderIdLabel = (
  cargoExternalOrderId: OrderDetailsHistoryEntryExternalIdUpdateEvent["newCargoExternalOrderId"]
) => {
  return translations.newCargoExternalOrderIdTemplateText.replace(
    "#{cargoExternalOrderId}",
    cargoExternalOrderId ?? "-----"
  );
};

const getCargoExternalOrderIdTitle = (
  cargoExternalOrderId: OrderDetailsHistoryEntryExternalIdUpdateEvent["newCargoExternalOrderId"]
) => {
  return translations.newCargoExternalOrderIdTemplateText.replace(
    "#{cargoExternalOrderId}",
    cargoExternalOrderId ?? "-----"
  );
};

const orderDetailsHistoryEntryExternalIdUpdateContentHelper = {
  getCargoExternalOrderIdLabel,
  getCargoExternalOrderIdTitle,
};

export default orderDetailsHistoryEntryExternalIdUpdateContentHelper;
