import htmlReactParser from "html-react-parser";

const parse = (stringifiedHtml: string) => {
  return htmlReactParser(stringifiedHtml);
};

const htmlParser = {
  parse,
};

export default htmlParser;
