import TaxiTaxiContractListRequest, {
  TaxiContractListRequestQueryParams,
} from "./taxi-taxi-contract-list.request";

const createQueryParams = (
  data: TaxiTaxiContractListRequest
): TaxiContractListRequestQueryParams => {
  return {
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
    limit: data.limit,
    offset: data.offset,
    displayName: data.displayName,
    producerDisplayName: data.producerDisplayName,
    consumerDisplayName: data.consumerDisplayName,
    model: data.model,
    resultsOrdering: data.resultsOrdering,
    producerTaxiId: data.producerTaxiId,
    startValidSince: data.startValidSince,
    endValidTo: data.endValidTo,
    startDistanceRate: data.startDistanceRate,
    endDistanceRate: data.endDistanceRate,
    startHaltingTimeAfterMinutes: data.startHaltingTimeAfterMinutes,
    endHaltingTimeAfterMinutes: data.endHaltingTimeAfterMinutes,
    startHaltingTimeRate: data.startHaltingTimeRate,
    endHaltingTimeRate: data.endHaltingTimeRate,
    isPartner: data.isPartner,
    isValid: data.isValid,
    priority: data.priority,
    disabled: data.disabled,
    engagedTaxiId: data.engagedTaxiId,
  };
};

const taxiTaxiContractListRequestFactory = {
  createQueryParams,
};

export default taxiTaxiContractListRequestFactory;
