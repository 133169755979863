import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import TaxiTaxiContractResponse from "../../common/select/types/taxi-taxi-contracts.response";
import OrderDetailsForwardingResponse from "../../forwarding/types/order-details-forwarding.response";
import OrderDetailsSendForwardingResponse from "../../forwarding/types/order-details-send-forwarding.response";
import OrderDetailsAvailableConsumerResponseData from "./order-details-available-consumer.response";

const fetchTaxiAvailableConsumers = (
  transportingOrderUuid: string
): Promise<OrderDetailsAvailableConsumerResponseData> => {
  const path = `/orders/transporting-orders/${transportingOrderUuid}/available-consumers`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchTaxiContract = (
  selectedTaxiCorporationId: string,
  producerId: string
): Promise<TaxiTaxiContractResponse> => {
  const path = `/contracts/taxi-forwarding?producer_taxi_id=${producerId}&&consumer_taxi_id=${selectedTaxiCorporationId}&is_valid=true`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchSendForwarding = async (
  orderId: string | undefined,
  requestBody: { contract_id: string | undefined }
): Promise<OrderDetailsSendForwardingResponse> => {
  const path = `${appConfig.baseApiUrl}/orders/transporting-orders/${orderId}/forwards`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchForwarding = (
  transportingOrderId: string
): Promise<OrderDetailsForwardingResponse> => {
  const path = `${appConfig.baseApiUrl}/orders/transporting-orders/${transportingOrderId}/forwards`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(path, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchEditForwarding = async (
  forwardingId: string,
  requestBody: { contract_id: string | undefined }
): Promise<OrderDetailsSendForwardingResponse> => {
  const path = `${appConfig.baseApiUrl}/order-forwardings/${forwardingId}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "PUT",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const orderDetailsForwardingApiService = {
  fetchTaxiAvailableConsumers,
  fetchTaxiContract,
  fetchSendForwarding,
  fetchForwarding,
  fetchEditForwarding,
};

export default orderDetailsForwardingApiService;
