import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import CargoOfficerAddBasicProps from "../common/cargo-officer-add-basic-props";
import cargoOfficerAddByRailyFormHelper from "./common/cargo-officer-add-by-raily-form.helper";
import cargoOfficerAddByRailyParamsFactory from "./common/cargo-officer-add-by-raily-params.factory";
import CargoOfficerAddByRailyFormData from "./common/types/cargo-officer-add-by-raily-form-data";
import useAbort from "../../../../../common/hooks/use-abort";
import useForm from "../../../../../common/components/form/use-form";
import CargoOfficerAddParams from "../../../../../common/services/cargo-officer/add/cargo-officer-add-params";
import cargoOfficerService from "../../../../../common/services/cargo-officer/cargo-officer.service";
import CargoCompanySingleSelectComponent from "../../../../../common/components/form/select/cargo-company/cargo-company-single-select.component";
import useCargoCompanyList from "../../../../../common/services/cargo-company/cargo-company/list/use-cargo-company-list";

type CargoOfficerAddByRailyProps = CargoOfficerAddBasicProps;

const CargoOfficerAddByRailyComponent: FC<CargoOfficerAddByRailyProps> = (
  props
) => {
  const translations = userTranslationsHelper.getCargoOfficerAddTranslations();

  const cargoOfficerAddAbort = useAbort();
  const [isCargoOfficerAddPending, setIsCargoOfficerAddPending] =
    useState(false);

  const cargoCompanyListAbort = useAbort();
  const cargoCompanyList = useCargoCompanyList();

  const navigate = useNavigate();

  const form = useForm<CargoOfficerAddByRailyFormData>({
    emptyValues: cargoOfficerAddByRailyFormHelper.getDefaultFormData(),
    validationDefinition:
      cargoOfficerAddByRailyFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    cargoCompanyList.load({}, cargoCompanyListAbort.signal);
  }, []);

  const navigateToListing = () => {
    const cargoOfficerListingRoute =
      userRoutesHelper.getCargoOfficerListingRoute();

    navigate(cargoOfficerListingRoute);
  };

  const onCargoOfficerAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigateToListing();
  };

  const onCargoOfficerAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const addCargoOfficer = async () => {
    setIsCargoOfficerAddPending(true);

    const params: CargoOfficerAddParams =
      cargoOfficerAddByRailyParamsFactory.create(form.values);

    try {
      await cargoOfficerService.add(params, cargoOfficerAddAbort.signal);

      onCargoOfficerAddSuccess();
    } catch {
      onCargoOfficerAddFailure();
    } finally {
      setIsCargoOfficerAddPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid: boolean = await form.validateAll();

    if (!isFormValid) return;

    addCargoOfficer();
  };

  const isCargoCompanySelectDisabled = cargoCompanyList.isError;

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.cargoCompanyLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.cargoCompanyUuid.errorMessage
                  }
                >
                  <CargoCompanySingleSelectComponent
                    idForTesting="cargo-officer-add-by-raily-cargo-company"
                    onChange={(value) => {
                      form.setValue("cargoCompanyUuid", value?.uuid ?? "");
                    }}
                    onBlur={() => form.validate("cargoCompanyUuid")}
                    placeholder={translations.form.cargoCompanyPlaceholder}
                    selectedCargoCompanyUuid={form.values.cargoCompanyUuid}
                    cargoCompanyList={cargoCompanyList.data.data}
                    isError={
                      !!form.validationResults.cargoCompanyUuid.errorMessage
                    }
                    isLoading={cargoCompanyList.isLoading}
                    isDisabled={isCargoCompanySelectDisabled}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.emailLabel}
                  isRequired
                  errorMessage={form.validationResults.email.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.emailPlaceholder}
                    value={form.values.email}
                    onChange={(value) => form.setValue("email", value)}
                    onBlur={() => form.validate("email")}
                    hasError={!!form.validationResults.email.errorMessage}
                    idForTesting="cargo-officer-add-by-raily-email"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.usernameLabel}
                  isRequired
                  errorMessage={form.validationResults.username.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.usernamePlaceholder}
                    value={form.values.username}
                    onChange={(value) => form.setValue("username", value)}
                    onBlur={() => form.validate("username")}
                    hasError={!!form.validationResults.username.errorMessage}
                    idForTesting="cargo-officer-add-by-raily-username"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.firstNameLabel}
                  isRequired
                  errorMessage={form.validationResults.firstName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.firstNamePlaceholder}
                    value={form.values.firstName}
                    onChange={(value) => form.setValue("firstName", value)}
                    onBlur={() => form.validate("firstName")}
                    hasError={!!form.validationResults.firstName.errorMessage}
                    idForTesting="cargo-officer-add-by-raily-first-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.lastNameLabel}
                  isRequired
                  errorMessage={form.validationResults.lastName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.lastNamePlaceholder}
                    value={form.values.lastName}
                    onChange={(value) => form.setValue("lastName", value)}
                    onBlur={() => form.validate("lastName")}
                    hasError={!!form.validationResults.lastName.errorMessage}
                    idForTesting="cargo-officer-add-by-raily-last-name"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.phoneNumberLabel}
                  isRequired
                  errorMessage={form.validationResults.phoneNumber.errorMessage}
                >
                  <PhoneNumberInputComponent
                    placeholder={translations.form.phoneNumberPlaceholder}
                    phoneNumber={form.values.phoneNumber}
                    onChange={(value) => {
                      form.setValue("phoneNumber", value);
                    }}
                    onBlur={() => form.validate("phoneNumber")}
                    hasError={!!form.validationResults.phoneNumber.errorMessage}
                    idForTesting="cargo-officer-add-by-raily-phone-number"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.alternativePhoneNumberLabel}
                  errorMessage={
                    form.validationResults.alternativePhoneNumber.errorMessage
                  }
                >
                  <PhoneNumberInputComponent
                    placeholder={
                      translations.form.alternativePhoneNumberPlaceholder
                    }
                    phoneNumber={form.values.alternativePhoneNumber}
                    onChange={(value) => {
                      form.setValue("alternativePhoneNumber", value);
                    }}
                    onBlur={() => form.validate("alternativePhoneNumber")}
                    hasError={
                      !!form.validationResults.alternativePhoneNumber
                        .errorMessage
                    }
                    idForTesting="cargo-officer-add-by-raily-alternative-phone-number"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.passwordLabel}
                  isRequired
                  errorMessage={form.validationResults.password.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.passwordPlaceholder}
                    value={form.values.password}
                    type="password"
                    onChange={(value) => form.setValue("password", value)}
                    onBlur={() => form.validate("password")}
                    hasError={!!form.validationResults.password.errorMessage}
                    idForTesting="cargo-officer-add-by-raily-password"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.passwordRepeatLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.passwordRepeat.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={translations.form.passwordRepeatPlaceholder}
                    value={form.values.passwordRepeat}
                    type="password"
                    onChange={(value) => form.setValue("passwordRepeat", value)}
                    onBlur={() => form.validate("passwordRepeat")}
                    hasError={
                      !!form.validationResults.passwordRepeat.errorMessage
                    }
                    idForTesting="cargo-officer-add-by-raily-password-repeat"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isDisabled={isCargoOfficerAddPending}
        classNames={{ root: "mt-4" }}
        idForTesting="cargo-officer-add-by-raily-submit-button"
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default CargoOfficerAddByRailyComponent;
