import { FC } from "react";
import DispatcherListingFilter from "../common/types/dispatcher-listing-filter";
import DispatcherListingSortKey from "../common/types/dispatcher-listing-sort-key";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import dispatcherListingFilterHelper from "./dispatcher-listing-filter.helper";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";

type DispatcherListingFilterProps = {
  filters: DispatcherListingFilter[];
  onFiltersChange: (filters: DispatcherListingFilter[]) => void;
  sortKey: DispatcherListingSortKey;
  onSortKeyChange: (sortKey: DispatcherListingSortKey) => void;
};

const DispatcherListingFilterComponent: FC<DispatcherListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<DispatcherListingFilter> =
    dispatcherListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    dispatcherListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as DispatcherListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as DispatcherListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="dispatcher-listing-filter"
      idForTestingSort="dispatcher-listing-sort"
    />
  );
};

export default DispatcherListingFilterComponent;
