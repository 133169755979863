import { FC } from "react";
import CargoCompanyListingItem from "../common/types/cargo-company-listing-item";
import useCargoCompanyListingUserPermissions from "../common/user-permissions/use-cargo-company-listing-user-permissions";
import CargoCompanyListingTableColumn from "../common/types/cargo-company-listing-table-column";
import cargoCompanyListingTableHelper from "./cargo-company-listing-table.helper";
import CargoCompanyListingTableRow from "../common/types/cargo-company-listing-table-row";
import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import TableComponent from "../../../../../../common/components/table/table.component";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import {
  faAddressBook,
  faContactCard,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";
import cargoRoutesHelper from "../../../../common/routes/cargo-routes.helper";

type CargoCompanyListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: CargoCompanyListingItem[];
};

const CargoCompanyListingTableComponent: FC<CargoCompanyListingTableProps> = (
  props
) => {
  const userPermissions = useCargoCompanyListingUserPermissions();

  const columns: CargoCompanyListingTableColumn[] =
    cargoCompanyListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: CargoCompanyListingItem
  ): CargoCompanyListingTableRow => {
    const translations =
      cargoTranslationsHelper.getCompanyListingTranslations().table;

    return {
      id: listingItem.uuid,
      idForTesting: listingItem.uuid,
      value: {
        taxNumber: (
          <div title={listingItem.taxNumber}>{listingItem.taxNumber}</div>
        ),
        companyId: (
          <div title={listingItem.companyId}>{listingItem.companyId}</div>
        ),
        nationalCourtRegister: (
          <div title={listingItem.nationalCourtRegister}>
            {listingItem.nationalCourtRegister}
          </div>
        ),
        companyName: (
          <div title={listingItem.companyName}>{listingItem.companyName}</div>
        ),
        companyDisplayName: (
          <div title={listingItem.displayName}>{listingItem.displayName}</div>
        ),
        address: <div title={listingItem.address}>{listingItem.address}</div>,
        notes: <div title={listingItem.notes}>{listingItem.notes}</div>,
        actions: (
          <div className="d-flex" onClick={(event) => event.stopPropagation()}>
            {userPermissions.hasAccessToAddressListing && (
              <TableLinkButtonComponent
                icon={faAddressBook}
                to={cargoRoutesHelper.getAddressListingRoute({
                  cargoCompanyUuid: listingItem.uuid,
                })}
                title={translations.addressListingLinkButtonTitle}
                idForTesting="cargo-company-listing-table-address-button"
              />
            )}
            {userPermissions.hasAccessToTaxiContractListing && (
              <TableLinkButtonComponent
                icon={faContactCard}
                to={cargoRoutesHelper.getTaxiContractListingRoute({
                  cargoCompanyUuid: listingItem.uuid,
                })}
                title={translations.taxiContractListingLinkButtonTitle}
                idForTesting="cargo-company-listing-table-taxi-contract-button"
              />
            )}
            {userPermissions.hasAccessToDispatchListing && (
              <TableLinkButtonComponent
                icon={faIdCard}
                to={cargoRoutesHelper.getDispatchListingRoute({
                  cargoCompanyUuid: listingItem.uuid,
                })}
                title={translations.dispatchListingLinkButtonTitle}
                idForTesting={"taxi-dispatch-listing-button"}
              />
            )}
          </div>
        ),
      },
    };
  };

  const rows: CargoCompanyListingTableRow[] = props.listingItems.map(
    (listingItem) => createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default CargoCompanyListingTableComponent;
