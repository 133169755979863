import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useAbort from "../../../../common/hooks/use-abort";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import useDealerListingUserPermissions from "./common/user-permissions/use-dealer-listing-user-permissions";
import dealerListingLoadParamsFactory from "./common/dealer-listing-load-params.factory";
import DealerListingItem from "./common/types/dealer-listing-item";
import dealerListingItemFactory from "./common/dealer-listing-item.factory";
import useDealerListingFiltersDao from "./filter/use-dealer-listing-filters.dao";
import DealerListingSortKey from "./common/types/dealer-listing-sort-key";
import DealerListingFilter from "./common/types/dealer-listing-filter";
import usePagination from "../../../../common/hooks/use-pagination";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import DealerListingFilterComponent from "./filter/dealer-listing-filter.component";
import CardComponent from "../../../../common/components/card/card.component";
import DealerListingTableComponent from "./table/dealer-listing-table.component";
import ListingPaginationComponent from "../../../../common/components/listing/pagination/listing-pagination.component";
import UserDeleteUserData from "../../common/delete/common/types/user-delete-user-data";
import UserDeleteComponent from "../../common/delete/user-delete.component";
import useDealerList from "../../../../common/services/dealer/list/use-dealer-list";
import DealerListLoadParams from "../../../../common/services/dealer/list/dealer-list-load-params";

type DealerListingProps = {};

const DealerListingComponent: FC<DealerListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();

  const listingItemsAbort = useAbort();

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const userPermissions = useDealerListingUserPermissions();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userDealerListing);

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDealerListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const dealerList = useDealerList();

  const loadListingItems = async () => {
    const params: DealerListLoadParams = dealerListingLoadParamsFactory.create(
      page,
      pageSize,
      filters,
      sortKey
    );

    dealerList.load(params, listingItemsAbort.signal);
  };

  const listingItems: DealerListingItem[] = useMemo(() => {
    return dealerListingItemFactory.createListingItems(dealerList.data.data);
  }, [dealerList.data.data]);

  const filtersDao = useDealerListingFiltersDao();

  const [sortKey, setSortKey] = useState<DealerListingSortKey>(
    () => filtersDao.getSortKey() ?? DealerListingSortKey.FIRST_NAME_ASC
  );

  const [filters, setFilters] = useState<DealerListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: dealerList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const translations = userTranslationsHelper.getDealerListingTranslations();

  const [userSelectedToDelete, setUserSelectedToDelete] =
    useState<UserDeleteUserData | null>(null);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const openDeleteUserModal = () => {
    setIsDeleteUserModalOpen(true);
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onSelectUserToDelete = (userData: UserDeleteUserData) => {
    setUserSelectedToDelete(userData);
    openDeleteUserModal();
  };

  const onDeleteUserSuccess = () => {
    closeDeleteUserModal();
    loadListingItems();
  };

  const DealerAddLinkButton = (
    <LinkButtonComponent
      type="primary"
      to={userRoutesHelper.getDealerAddRoute()}
      title={translations.header.addNewDealerLinkTitle}
      idForTesting="dealer-listing-add-button"
    >
      {translations.header.addNewDealerLinkLabel}
    </LinkButtonComponent>
  );

  const createHeadingActions = (): ReactNode[] => {
    return [
      userPermissions.hasVisibleAddDealerButton
        ? DealerAddLinkButton
        : undefined,
    ];
  };

  const headingActions = createHeadingActions();

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <DealerListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <DealerListingTableComponent
          listingItems={listingItems}
          isError={dealerList.isError}
          isLoading={dealerList.isLoading}
          onSelectUserToDelete={onSelectUserToDelete}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={dealerList.data.totalCount}
        />
      </CardComponent>
      <UserDeleteComponent
        isOpen={isDeleteUserModalOpen}
        onClose={closeDeleteUserModal}
        userData={userSelectedToDelete}
        onSuccess={onDeleteUserSuccess}
      />
    </>
  );
};

export default DealerListingComponent;
