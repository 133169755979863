import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryOperationalApprovalEvent } from "../../../common/types/order-details-history-entry-event";

type OrderDetailsHistoryEntryOperationalApprovalContentProps = {
  event: OrderDetailsHistoryEntryOperationalApprovalEvent;
};

const OrderDetailsHistoryEntryOperationalApprovalContentComponent: FC<
  OrderDetailsHistoryEntryOperationalApprovalContentProps
> = () => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .operationalApprovalEntry;

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingText}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryOperationalApprovalContentComponent;
