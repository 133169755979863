import DealerListRequest, {
  DealerListRequestQueryParams,
} from "./dealer-list.request";

const createQueryParams = (
  data: DealerListRequest
): DealerListRequestQueryParams => {
  return {
    taxiCorporation: data.taxiCorporation,
    taxiCorporationId: data.taxiCorporationId,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    username: data.username,
    statusCriteria: data.statusCriteria,
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
    order: data.order,
    limit: data.limit,
    offset: data.offset,
  };
};

const dealerListRequestFactory = {
  createQueryParams,
};

export default dealerListRequestFactory;
