import { FC } from "react";
import OrderOptimizerListingTableRowSummaryComponent from "./summary/order-optimizer-listing-table-row-summary.component";
import OrderOptimizerListingTableRowRouteComponent from "./route/order-optimizer-listing-table-row-route.component";
import OrderOptimizerListingItem from "../../common/types/order-optimizer-listing-item";

type OrderOptimizerListingTableRowProps = {
  listingItem: OrderOptimizerListingItem;
  selectedHumanId: number | undefined;
};

const OrderOptimizerListingTableRowComponent: FC<
  OrderOptimizerListingTableRowProps
> = (props) => {
  return (
    <div className="order_optimizer_table_row">
      <OrderOptimizerListingTableRowSummaryComponent
        listingItem={props.listingItem}
      />
      <OrderOptimizerListingTableRowRouteComponent
        listingItem={props.listingItem}
        selectedHuhmanId={props.selectedHumanId}
      />
    </div>
  );
};

export default OrderOptimizerListingTableRowComponent;
