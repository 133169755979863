import { FC, useMemo } from "react";
import cargoCompanySelectOptionFactory from "./cargo-company-select-option.factory";
import CargoCompanySelectOption from "./cargo-company-select-option";
import { useAppContext } from "../../../../../context/app.context";
import SingleSelectComponent from "../single-select/single-select.component";
import CargoCompanySelectValue from "./cargo-company-select-value";

type CargoCompanySingleSelectProps = {
  placeholder: string;
  selectedCargoCompanyUuid: string;
  cargoCompanyList: CargoCompanySelectValue[];
  onChange: (value: CargoCompanySelectValue | null) => void;
  onBlur: () => void;
  isError: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  idForTesting?: string;
};

const CargoCompanySingleSelectComponent: FC<CargoCompanySingleSelectProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();
  const selectOptions: CargoCompanySelectOption[] = useMemo(
    () => cargoCompanySelectOptionFactory.createOptions(props.cargoCompanyList),
    [props.cargoCompanyList, selectedAppLanguage]
  );

  const selectedOption: CargoCompanySelectOption | null = useMemo(() => {
    if (!props.selectedCargoCompanyUuid) {
      return null;
    }

    return (
      selectOptions.find(
        (option) => option.value.uuid === props.selectedCargoCompanyUuid
      ) ?? null
    );
  }, [props.selectedCargoCompanyUuid, selectOptions]);

  return (
    <SingleSelectComponent
      placeholder={props.placeholder}
      value={selectedOption}
      onChange={(value) => props.onChange(value?.value ?? null)}
      onBlur={props.onBlur}
      options={selectOptions}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      hasError={props.isError}
      idForTesting={props.idForTesting}
      isSearchable
    />
  );
};

export default CargoCompanySingleSelectComponent;
