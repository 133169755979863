enum TaxiTaxiContractListingSortKey {
  PRIORITY_ASC = "PRIORITY_ASC",
  PRIORITY_DESC = "PRIORITY_DESC",
  DISPLAY_NAME_ASC = "DISPLAY_NAME_ASC",
  DISPLAY_NAME_DESC = "DISPLAY_NAME_DESC",
  PRODUCER_DISPLAY_NAME_ASC = "PRODUCER_DISPLAY_NAME_ASC",
  PRODUCER_DISPLAY_NAME_DESC = "PRODUCER_DISPLAY_NAME_DESC",
  CONSUMER_DISPLAY_NAME_ASC = "CONSUMER_DISPLAY_NAME_ASC",
  CONSUMER_DISPLAY_NAME_DESC = "CONSUMER_DISPLAY_NAME_DESC",
  HALTING_TIME_RATE_ASC = "HALTING_TIME_RATE_ASC",
  HALTING_TIME_RATE_DESC = "HALTING_TIME_RATE_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  HALTING_TIME_AFTER_MINUTES_ASC = "HALTING_TIME_AFTER_MINUTES_ASC",
  HALTING_TIME_AFTER_MINUTES_DESC = "HALTING_TIME_AFTER_MINUTES_DESC",
}

export default TaxiTaxiContractListingSortKey;
