import OrderRouteEditSummaryRouteConnection from "./types/order-route-edit-summary-route-connection";
import OrderRouteEditSummaryRouteConnectionLineType from "./types/order-route-edit-summary-route-connection-line-type";
import OrderRouteEditSummaryRouteWaypoint from "./types/order-route-edit-summary-route-waypoint";

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return `----`;
  }

  return date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getDateTitle = (date: Date | null): string => {
  if (!date) {
    return ``;
  }

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

const getPlaceLabel = (place: OrderRouteEditSummaryRouteWaypoint["place"]) => {
  if (place === null) {
    return `----`;
  }

  return place.displayName;
};

const getPlaceTitle = (place: OrderRouteEditSummaryRouteWaypoint["place"]) => {
  if (place === null) {
    return undefined;
  }

  return place.displayName;
};

const getOrderedDateLabel = (
  orderedDate: OrderRouteEditSummaryRouteWaypoint["orderedDate"]
): string => {
  return getDateLabel(orderedDate);
};

const getOrderedDateTitle = (
  orderedDate: OrderRouteEditSummaryRouteWaypoint["orderedDate"]
): string => {
  return getDateTitle(orderedDate);
};

const getEstimatedDateLabel = (
  estimatedDate: OrderRouteEditSummaryRouteWaypoint["estimatedDate"]
): string => {
  return getDateLabel(estimatedDate);
};

const getEstimatedDateTitle = (
  estimatedDate: OrderRouteEditSummaryRouteWaypoint["estimatedDate"]
): string => {
  return getDateTitle(estimatedDate);
};

const getDistanceLabel = (
  distance: OrderRouteEditSummaryRouteWaypoint["distance"]
): string => {
  if (distance === null) {
    return `----`;
  }

  const distanceAsKm = distance / 1000;

  return `${distanceAsKm.toFixed(1)} KM`;
};

const getHaltingTime = (
  haltingTime: OrderRouteEditSummaryRouteWaypoint["haltingTime"]
) => {
  const formatNumber = (number: number): string => {
    return `${number}`.padStart(2, "0");
  };

  const haltingTimeMinutes = formatNumber(Math.floor((haltingTime / 60) % 60));
  const haltingTimeHours = formatNumber(Math.floor(haltingTime / 60 / 60));

  const haltingTimeString = `${haltingTimeHours}:${haltingTimeMinutes}`;

  return haltingTimeString;
};

const generateWaypointDotClassname = (
  waypoint: OrderRouteEditSummaryRouteWaypoint
) => {
  return `dot-uuid-${waypoint.uuid}-stageNo-${waypoint.stageNo}`;
};

const getDefinitionOfLineConnectionsBetweenWaypoints = (
  waypoints: OrderRouteEditSummaryRouteWaypoint[]
): OrderRouteEditSummaryRouteConnection[] => {
  let connectionLineDefinitions: OrderRouteEditSummaryRouteConnection[] = [];

  waypoints.forEach((currentWaypoint, index) => {
    const nextWaypoint = waypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const areBothWaypointsFromTheSameStage =
      currentWaypoint.stageNo === nextWaypoint.stageNo;

    const lineType = areBothWaypointsFromTheSameStage
      ? OrderRouteEditSummaryRouteConnectionLineType.DOTTED
      : OrderRouteEditSummaryRouteConnectionLineType.SOLID;

    const newConnectionLineDefinition: OrderRouteEditSummaryRouteConnection = {
      itemFromClassname: generateWaypointDotClassname(currentWaypoint),
      itemToClassname: generateWaypointDotClassname(nextWaypoint),
      lineType,
    };

    connectionLineDefinitions = [
      ...connectionLineDefinitions,
      newConnectionLineDefinition,
    ];
  });

  return connectionLineDefinitions;
};

const orderRouteEditSummaryRouteHelper = {
  getPlaceLabel,
  getPlaceTitle,
  getOrderedDateLabel,
  getOrderedDateTitle,
  getEstimatedDateLabel,
  getEstimatedDateTitle,
  getHaltingTime,
  getDistanceLabel,
  getDefinitionOfLineConnectionsBetweenWaypoints,
  generateWaypointDotClassname,
};

export default orderRouteEditSummaryRouteHelper;
