import DispatcherEditFormRequest from "../../../api/raily/dispatcher/edit-form/dispatcher-edit-form.request";
import DispatcherEditFormLoadParams from "./dispatcher-edit-form-load-params";

const create = (
  params: DispatcherEditFormLoadParams
): DispatcherEditFormRequest => {
  return {
    dispatcherId: params.dispatcherUuid,
  };
};

const dispatcherEditFormRequestFactory = {
  create,
};

export default dispatcherEditFormRequestFactory;
