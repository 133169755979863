import useAsyncData from "../../../../hooks/use-async-data";
import cargoCompanyDispatcherService from "../cargo-company-dispatcher.service";
import CargoCompanyDispatcherList from "./cargo-company-dispatcher-list";
import CargoCompanyDispatcherListLoadParams from "./cargo-company-dispatcher-list-load-params";

const useCargoCompanyDispatcherList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<CargoCompanyDispatcherList>([]);

  const load = async (
    params: CargoCompanyDispatcherListLoadParams,
    abortSignal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await cargoCompanyDispatcherService.getDispatcherList(
        params,
        abortSignal
      );

      setData(data);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    isError,
    load,
  };
};

export default useCargoCompanyDispatcherList;
