import HttpResponse from "../../../../../common/utils/http/http.response";
import BillingStatus from "../../../types/billing-status";
import BillingNode from "./types/billing-node";
import Contract from "./types/contract";
import CargoOrder from "./types/transporting-order";

export enum BillingDataResponseContributionType {
  BASE_AMOUNT = "BASE_AMOUNT",
  CHARGE_HALTINGS = "CHARGE_HALTING",
  CHARGE_HIGHWAYS = "CHARGE_HIGHWAY",
  DISCOUNT_OPTIMIZATION = "DISCOUNT_OPTIMIZATION",
  DISCOUNT_BEING_LATE = "DISCOUNT_BEING_LATE",
  DISCOUNT_INCOMPATIBLE_CAR = "DISCOUNT_INCOMPATIBLE_CAR",
  DISCOUNT_OTHER = "DISCOUNT_OTHER",
  CONTRACT_CORRECTION_PERCENTAGE = "CONTRACT_CORRECTION_PERCENTAGE",
}

export type BillingDataResponseContributionContractCorrectionPercentage = {
  type: BillingDataResponseContributionType.CONTRACT_CORRECTION_PERCENTAGE;
  percentage: number;
  amount: number;
};

export type BillingDataResponseContributionDiscountOptimization = {
  type: BillingDataResponseContributionType.DISCOUNT_OPTIMIZATION;
  comment: string | null;
  amount: number;
};

export type BillingDataResponseContributionDiscountBeingLate = {
  type: BillingDataResponseContributionType.DISCOUNT_BEING_LATE;
  comment: string | null;
  amount: number;
};

export type BillingDataResponseContributionDiscountIncompatibleCar = {
  type: BillingDataResponseContributionType.DISCOUNT_INCOMPATIBLE_CAR;
  comment: string | null;
  amount: number;
};

export type BillingDataResponseContributionDiscountOther = {
  type: BillingDataResponseContributionType.DISCOUNT_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingDataResponseContribution =
  | BillingDataResponseContributionContractCorrectionPercentage
  | BillingDataResponseContributionDiscountOptimization
  | BillingDataResponseContributionDiscountBeingLate
  | BillingDataResponseContributionDiscountIncompatibleCar
  | BillingDataResponseContributionDiscountOther;

export type BillingData = {
  contract: Contract;
  billing_nodes: BillingNode[];
  billing_contributions: BillingDataResponseContribution[];
  sum_of_discounts: number;
  all_contributions_amount: number;
  base_amount_value: number;
  amount_for_charge_haltings: number;
  amount_for_charge_highways: number;
  distance: number;
  amount_for_distance: number;
  cargo_order: CargoOrder;
  plan_entry_id: string;
  date: Date;
  status: BillingStatus;
  passengers: string[];
  contract_correction_percentage: number;
};

export type BillingDataResponse = HttpResponse<BillingData>;

export default BillingDataResponse;
