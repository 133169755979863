import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DispatcherAddUserPermissionOption =
  | "hasAccessToCargoView"
  | "hasAccessToRailyView";

export type DispatcherAddUserPermissionsDefinition =
  UserPermissionsDefinition<DispatcherAddUserPermissionOption>;

export type DispatcherAddUserPermissions =
  UserPermissions<DispatcherAddUserPermissionOption>;

const dispatcherAddUserPermissionDefinition: DispatcherAddUserPermissionsDefinition =
  {
    hasAccessToCargoView: [UserRole.CARGO_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default dispatcherAddUserPermissionDefinition;
