import { FC, useEffect, useRef, useState } from "react";
import ModalComponent from "../../../modal/modal.component";
import ButtonComponent from "../../../button/button.component";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import NumericRange from "../../../../types/numeric-range";
import classNames from "classnames";
import FormFieldComponent from "../../../form/field/form-field.component";
import NumericInputComponent from "../../../form/input/numeric-input/numeric-input.component";
import useForm from "../../../form/use-form";
import NumericRangeFormData from "./common/types/numeric-range-form-data";
import numericRangeFormHelper from "./common/numeric-range-form.helper";

type ListingNumericRangeFilterProps = {
  onApply: (numericRange: NumericRange) => void;
  isOpen: boolean;
  onClose: () => void;
};

const ListingNumericRangeFilterComponent: FC<ListingNumericRangeFilterProps> = (
  props
) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [selectedNumericRange, setSelectedNumericRange] =
    useState<NumericRange | null>(null);

  const form = useForm<NumericRangeFormData>({
    emptyValues: numericRangeFormHelper.getDefaultFormData(),
    validationDefinition: numericRangeFormHelper.getValidationDefinition(),
  });

  const translations =
    appTranslationsHelper.getComponentTranslations().listing.filter
      .numericRange;

  useEffect(() => {
    if (props.isOpen) {
      modalRef.current?.focus();
      return;
    }

    setSelectedNumericRange(null);
  }, [props.isOpen]);

  const onAcceptButtonClick = async () => {
    if (!selectedNumericRange) return;

    const isFormValid: boolean = await form.validateAll();
    if (!isFormValid) return;

    props.onApply(selectedNumericRange);

    setSelectedNumericRange({
      from: 0,
      to: 1,
    });
    form.setValue("from", 0);
    form.setValue("to", 1);
  };

  const onRejectButtonClick = () => {
    props.onClose();
    setSelectedNumericRange(null);
  };

  const ApplyButton = (
    <ButtonComponent
      type="primary"
      onClick={onAcceptButtonClick}
      title={translations.applyButtonTitle}
      isDisabled={!selectedNumericRange}
    >
      {translations.applyButtonLabel}
    </ButtonComponent>
  );

  const RejectButton = (
    <ButtonComponent
      type="danger"
      onClick={onRejectButtonClick}
      title={translations.rejectButtonTitle}
    >
      {translations.rejectButtonLabel}
    </ButtonComponent>
  );

  const onMinChange = (number: number | null) => {
    if (number === null) {
      return;
    }

    form.setValue("from", number);

    setSelectedNumericRange({
      from: number,
      to: selectedNumericRange?.to ?? number + 1,
    });
  };

  const onMaxChange = (number: number | null) => {
    if (number === null) {
      return;
    }

    form.setValue("to", number);

    setSelectedNumericRange({
      from: selectedNumericRange?.from ?? 0,
      to: number,
    });
  };

  useEffect(() => {
    form.validateAll();
  }, [form.values]);

  return (
    <div ref={modalRef} tabIndex={-1}>
      <ModalComponent
        header={{
          title: translations.headingLabel,
        }}
        classNames={{
          root: "listing_numeric_range_filter",
          content: "listing_numeric_range_filter_content",
        }}
        isOpen={props.isOpen}
        onClose={props.onClose}
        actions={[ApplyButton, RejectButton]}
      >
        <div className={classNames("numeric_range_picker")}>
          <FormFieldComponent
            label={translations.minValueLabel}
            classNames={{ root: `mt-0` }}
            isRequired
            errorMessage={form.validationResults.from.errorMessage}
          >
            <NumericInputComponent
              value={form.values.from}
              onChange={onMinChange}
              hasError={!!form.validationResults.from.errorMessage}
            />
          </FormFieldComponent>

          <FormFieldComponent
            label={translations.maxValueLabel}
            classNames={{ root: `mt-0` }}
            errorMessage={form.validationResults.to.errorMessage}
            isRequired
          >
            <NumericInputComponent
              value={form.values.to}
              onChange={onMaxChange}
              hasError={!!form.validationResults.to.errorMessage}
            />
          </FormFieldComponent>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ListingNumericRangeFilterComponent;
