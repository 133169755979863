import UserStatus from "../../../types/user-status";

export enum TaxiOfficerListLoadParamsOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
  EMAIL_ADDRESS_ASC = "EMAIL_ADDRESS_ASC",
  EMAIL_ADDRESS_DESC = "EMAIL_ADDRESS_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
}

type TaxiOfficerListLoadParams = {
  taxiCorporation?: string;
  taxiCorporationId?: string;
  order?: TaxiOfficerListLoadParamsOrder;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  status?: UserStatus;
  creationStart?: Date;
  creationEnd?: Date;
  page: number;
  pageSize: number;
};

export default TaxiOfficerListLoadParams;
