export enum CargoCompanyListRequestOrder {
  COMPANY_NAME_ASC = "COMPANY_NAME_ASC",
  COMPANY_NAME_DESC = "COMPANY_NAME_DESC",
  DISPLAY_NAME_ASC = "DISPLAY_NAME_ASC",
  DISPLAY_NAME_DESC = "DISPLAY_NAME_DESC",
}

export type CargoCompanyListRequestQueryParams = {
  taxId: string | undefined;
  regon: string | undefined;
  krsNo: string | undefined;
  companyName: string | undefined;
  companyNameCaseSensitive: boolean | undefined;
  displayName: string | undefined;
  displayNameCaseSensitive: boolean | undefined;
  headquartersAddressString: string | undefined;
  headquartersAddressStringCaseSensitive: boolean | undefined;
  contractMaintenancePolicy: string | undefined;
  notes: string | undefined;
  notesCaseSensitive: string | undefined;
  order: CargoCompanyListRequestOrder | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
};

type CargoCompanyListRequest = {
  taxId?: string;
  regon?: string;
  krsNo?: string;
  companyName?: string;
  companyNameCaseSensitive?: boolean;
  displayName?: string;
  displayNameCaseSensitive?: boolean;
  headquartersAddressString?: string;
  headquartersAddressStringCaseSensitive?: boolean;
  contractMaintenancePolicy?: string;
  notes?: string;
  notesCaseSensitive?: string;
  order?: CargoCompanyListRequestOrder;
  creationStart?: Date;
  creationEnd?: Date;
};

export default CargoCompanyListRequest;
