import CargoOfficerListRequest, {
  CargoOfficerListRequestStatusCriteria,
  CargoOfficerListRequestOrder,
} from "../../../api/raily/cargo-officer/list/cargo-officer-list.request";
import UserStatus from "../../../types/user-status";
import paginationService from "../../../utils/pagination/pagination.service";
import CargoOfficerListLoadParams, {
  CargoOfficerListLoadParamsOrder,
} from "./cargo-officer-list-load-params";

const createStatusCriteria = (
  status: UserStatus | undefined
): CargoOfficerListRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return CargoOfficerListRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return CargoOfficerListRequestStatusCriteria.DELETED;
    default:
      return CargoOfficerListRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: CargoOfficerListLoadParamsOrder | undefined
): CargoOfficerListRequestOrder | undefined => {
  switch (status) {
    case CargoOfficerListLoadParamsOrder.FIRST_NAME_ASC:
      return CargoOfficerListRequestOrder.FIRST_NAME_ASC;
    case CargoOfficerListLoadParamsOrder.FIRST_NAME_DESC:
      return CargoOfficerListRequestOrder.FIRST_NAME_DESC;
    case CargoOfficerListLoadParamsOrder.LAST_NAME_ASC:
      return CargoOfficerListRequestOrder.LAST_NAME_ASC;
    case CargoOfficerListLoadParamsOrder.LAST_NAME_DESC:
      return CargoOfficerListRequestOrder.LAST_NAME_DESC;
    case CargoOfficerListLoadParamsOrder.USERNAME_ASC:
      return CargoOfficerListRequestOrder.USERNAME_ASC;
    case CargoOfficerListLoadParamsOrder.USERNAME_DESC:
      return CargoOfficerListRequestOrder.USERNAME_DESC;
    default:
      return undefined;
  }
};

const create = (
  params: CargoOfficerListLoadParams
): CargoOfficerListRequest => {
  return {
    cargoCompany: params.cargoCompany,
    cargoCompanyId: params.cargoCompanyId,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    statusCriteria: createStatusCriteria(params.status),
    username: params.username,
    creationEnd: params.creationEnd,
    creationStart: params.creationStart,
    limit: params.pageSize,
  };
};

const cargoOfficerListRequestFactory = { create };

export default cargoOfficerListRequestFactory;
