import { useState } from "react";

type PaginationParams = {
  defaultPageSize?: number;
  defaultPage?: number;
  totalResults: number;
};

const usePagination = (params: PaginationParams) => {
  const [page, setPage] = useState(params.defaultPage ?? 1);
  const [pageSize, setPageSize] = useState(params.defaultPageSize ?? 100);

  const totalPages = params?.totalResults
    ? Math.ceil(params.totalResults / pageSize)
    : 0;

  const changePageSize = (pageSize: number) => {
    setPage(1);
    setPageSize(pageSize);
  };

  return {
    page,
    pageSize,
    setPage,
    setPageSize: changePageSize,
    totalPages,
  };
};

export default usePagination;
