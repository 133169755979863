import { FC } from "react";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import DriverListingFilter from "../common/types/driver-listing-filter";
import DriverListingSortKey from "../common/types/driver-listing-sort-key";
import driverListingFilterHelper from "./driver-listing-filter.helper";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";

type DriverListingFilterProps = {
  filters: DriverListingFilter[];
  onFiltersChange: (filters: DriverListingFilter[]) => void;
  sortKey: DriverListingSortKey;
  onSortKeyChange: (sortKey: DriverListingSortKey) => void;
};

const DriverListingFilterComponent: FC<DriverListingFilterProps> = (props) => {
  const filterDefinition: ListingFilterDefinition<DriverListingFilter> =
    driverListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    driverListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as DriverListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as DriverListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="driver-listing-filter"
      idForTestingSort="driver-listing-sort"
    />
  );
};

export default DriverListingFilterComponent;
