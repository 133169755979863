import classNames from "classnames";
import { FC } from "react";
import TaxiUnsettledPrivateOrderListingItemStatus from "../../common/types/taxi-unsettled-private-order-listing-item-status";

type TaxiUnsettledPrivateOrderListingTableStatusProps = {
  status: TaxiUnsettledPrivateOrderListingItemStatus;
};

const TaxiUnsettledPrivateOrderListingTableStatusComponent: FC<
  TaxiUnsettledPrivateOrderListingTableStatusProps
> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case TaxiUnsettledPrivateOrderListingItemStatus.CANCELLED:
        return "cancelled";
      case TaxiUnsettledPrivateOrderListingItemStatus.FINISHED:
        return "finished";
    }
  };

  const statusClassname = getClassnameByStatus();

  const containerClassNames = classNames(
    "taxi_unsettled_private_order_listing_table_status",
    statusClassname
  );

  return <div className={containerClassNames}></div>;
};

export default TaxiUnsettledPrivateOrderListingTableStatusComponent;
