import { FC, useEffect, useState } from "react";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import TaxiTaxiContractEditFormData from "./common/types/taxi-taxi-contract-edit-form-data";
import taxiTaxiContractEditFormHelper from "./common/taxi-taxi-contract-edit-form.helper";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import notificationService from "../../../../common/utils/notification/notification.service";
import BooleanSelectComponent from "../../../../common/components/form/select/boolean/boolean-select.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import TaxiTaxiContractEditRouteParams from "../../common/routes/types/taxi-taxi-contract-edit-route-params";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import { useNavigate, useParams } from "react-router-dom";
import ErrorComponent from "../../../../common/components/error/error.component";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import useForm from "../../../../common/components/form/use-form";
import useTaxiTaxiContractDetails from "../../../../common/services/taxi-taxi-contract/details/use-taxi-taxi-contract-details";
import useAbort from "../../../../common/hooks/use-abort";
import taxiTaxiContractService from "../../../../common/services/taxi-taxi-contract/taxi-taxi-contract.service";
import useTaxiCorporationDetails from "../../../../common/services/taxi-corporation/details/use-taxi-corporation-details";

type TaxiTaxiContractEditProps = {};

const TaxiTaxiContractEditComponent: FC<TaxiTaxiContractEditProps> = () => {
  const navigate = useNavigate();

  const { taxiCorporationUuid, contractUuid } =
    useParams<TaxiTaxiContractEditRouteParams>();
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.taxiTaxiContractEdit);

  const taxiDetails = useTaxiCorporationDetails();
  const taxiDetailsAbort = useAbort();

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    taxiDetails.load(
      {
        taxiCorporationUuid,
      },
      taxiDetailsAbort.signal
    );
  }, [taxiCorporationUuid]);

  useEffect(() => {
    const breadcrumbs = taxiBreadcrumbsHelper.getTaxiContractEditBreadcrumbs({
      taxiCorporationUuid: taxiCorporationUuid!,
      contractUuid: contractUuid!,
      taxiCorporationDisplayName: taxiDetails.data?.displayName,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, taxiDetails.data]);

  const translations = taxiTranslationsHelper.getTaxiContractEditTranslations();

  const form = useForm<TaxiTaxiContractEditFormData>({
    emptyValues: taxiTaxiContractEditFormHelper.getEmptyFormData(),
    validationDefinition:
      taxiTaxiContractEditFormHelper.getValidationDefinition(),
  });

  const [isContractUpdatePending, setIsContractUpdatePending] = useState(false);

  const taxiTaxiContractDetailsAbort = useAbort();
  const taxiTaxiContractDetails = useTaxiTaxiContractDetails();

  const taxiTaxiContractUpdateAbort = useAbort();

  useEffect(() => {
    if (!contractUuid) {
      return;
    }

    taxiTaxiContractDetails.load(
      { contractUuid },
      taxiTaxiContractDetailsAbort.signal
    );
  }, [contractUuid]);

  useEffect(() => {
    if (!taxiTaxiContractDetails.data) {
      return;
    }

    form.setValues({
      displayName: taxiTaxiContractDetails.data.displayName ?? "",
      isActive: taxiTaxiContractDetails.data.isActive,
      priority: taxiTaxiContractDetails.data.priority,
    });
  }, [taxiTaxiContractDetails.data]);

  const navigateToContractListing = () => {
    const contractListingRoute = taxiRoutesHelper.getTaxiContractListingRoute({
      taxiCorporationUuid: taxiCorporationUuid!,
    });

    navigate(contractListingRoute);
  };

  const onContractUpdateSuccess = () => {
    notificationService.success(translations.successEditNotificationLabel);
    navigateToContractListing();
  };

  const onContractUpdateFailure = () => {
    notificationService.error(translations.failureEditNotificationLabel);
  };

  const updateContract = async () => {
    setIsContractUpdatePending(true);

    try {
      await taxiTaxiContractService.update(
        {
          contractUuid: contractUuid!,
          displayName: form.values.displayName,
          isActive: form.values.isActive!,
          priority: form.values.priority!,
        },
        taxiTaxiContractUpdateAbort.signal
      );

      onContractUpdateSuccess();
    } catch {
      onContractUpdateFailure();
    } finally {
      setIsContractUpdatePending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    updateContract();
  };

  const Content = (
    <Row>
      <Column xl={8}>
        <CardComponent isLoading={taxiTaxiContractDetails.isLoading}>
          <Row>
            <Column lg={6}>
              <FormFieldComponent
                label={translations.form.sourceTaxiCorporationLabel}
              >
                {taxiTaxiContractDetails.data?.producerTaxi.name}
              </FormFieldComponent>
            </Column>
            <Column lg={6}>
              <FormFieldComponent
                label={translations.form.targetTaxiCorporationLabel}
              >
                {taxiTaxiContractDetails.data?.consumerTaxi.name}
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column lg={6}>
              <FormFieldComponent
                label={translations.form.periodOfValidityLabel}
              >
                {taxiTaxiContractEditFormHelper.getPeriodOfValidityLabel(
                  taxiTaxiContractDetails.data?.periodOfValidity
                )}
              </FormFieldComponent>
            </Column>
            <Column lg={6}>
              <FormFieldComponent
                label={translations.form.contractDisplayNameLabel}
                isRequired
                errorMessage={form.validationResults.displayName.errorMessage}
              >
                <InputComponent
                  placeholder={translations.form.contractDisplayNamePlaceholder}
                  value={form.values.displayName}
                  onChange={(value) => form.setValue("displayName", value)}
                  onBlur={() => form.validate("displayName")}
                  hasError={!!form.validationResults.displayName.errorMessage}
                  idForTesting="taxi-taxi-contract-edit-display-name"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column lg={6}>
              <FormFieldComponent label={translations.form.distanceRateLabel}>
                {translations.form.distanceRateContentLabel.replace(
                  "#{distanceRate}",
                  taxiTaxiContractDetails.data?.distanceRate.toString() ?? ""
                )}
              </FormFieldComponent>
            </Column>
            <Column lg={6}>
              <FormFieldComponent label={translations.form.billingModelLabel}>
                {taxiTaxiContractDetails.data?.billingModel}
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column lg={6}>
              <FormFieldComponent
                label={translations.form.freeStoppingPeriodLabel}
              >
                {translations.form.freeStoppingPeriodContentLabel.replace(
                  "#{freeStoppingPeriod}",
                  taxiTaxiContractDetails.data?.freeStoppingPeriodMinutes.toString() ??
                    ""
                )}
              </FormFieldComponent>
            </Column>
            <Column lg={6}>
              <FormFieldComponent label={translations.form.stoppingRateLabel}>
                {translations.form.stoppingRateContentLabel.replace(
                  "#{stoppingRate}",
                  taxiTaxiContractDetails.data?.stoppingRate.toString() ?? ""
                )}
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column lg={6}>
              <FormFieldComponent
                label={translations.form.isActiveLabel}
                isRequired
                errorMessage={form.validationResults.isActive.errorMessage}
              >
                <BooleanSelectComponent
                  placeholder={translations.form.isActivePlaceholder}
                  value={form.values.isActive}
                  onChange={(value) => form.setValue("isActive", value)}
                  onBlur={() => form.validate("isActive")}
                  isError={!!form.validationResults.isActive.errorMessage}
                  idForTesting="taxi-taxi-contract-edit-is-active"
                />
              </FormFieldComponent>
            </Column>
            <Column lg={6}>
              <FormFieldComponent
                label={translations.form.priorityLabel}
                isRequired
                errorMessage={form.validationResults.priority.errorMessage}
              >
                <NumericInputComponent
                  placeholder={translations.form.priorityPlaceholder}
                  value={form.values.priority}
                  hasError={!!form.validationResults.priority.errorMessage}
                  isIntegerOnly
                  onChange={(value) => form.setValue("priority", value)}
                  onBlur={() => form.validate("priority")}
                  idForTesting="taxi-taxi-contract-edit-priority"
                />
              </FormFieldComponent>
            </Column>
          </Row>
        </CardComponent>
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          isLoading={isContractUpdatePending}
          idForTesting="taxi-taxi-contract-edit-submit-button"
          classNames={{ root: "mt-4" }}
          title={translations.form.submitButtonTitle}
        >
          {translations.form.submitButtonLabel}
        </ButtonComponent>
      </Column>
    </Row>
  );

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel.replace(
          "#{contractDisplayName}",
          taxiTaxiContractDetails.data?.displayName ?? ""
        )}
      />
      {taxiTaxiContractDetails.isError ? <ErrorComponent /> : Content}
    </>
  );
};

export default TaxiTaxiContractEditComponent;
