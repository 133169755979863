export enum MileageDetailsStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DELEGATION_CREATED = "DELEGATION_CREATED",
}

export enum MileageDetailsVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type MileageDetailsAddressSequenceNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
};

export type MileageDetailsAddressSequence = {
  seq: MileageDetailsAddressSequenceNode[];
};

type MileageDetails = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  workerId: string;
  workerCompanyId: string;
  mileageDate: Date;
  cardNumber?: string | null;
  commissionNumber?: string | null;
  mileageNumber?: string | null;
  notes?: string | null;
  status: MileageDetailsStatus | undefined;
  vehicleType: MileageDetailsVehicleType | undefined;
  distance: number;
  mileageCost: number;
  delegationId?: string | null;
  hasDelegation: boolean;
  humanId: number;
  workerName: string;
  workerPhone: string;
  addressSeq: MileageDetailsAddressSequence;
  startAddressLabel: string;
  midAddressesLabels: string[];
  endAddressLabel: string;
  osrmDistance?: number;
  acceptedDistance?: number;
};

export default MileageDetails;
