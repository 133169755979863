import TaxiOfficerListRequest, {
  TaxiOfficerListRequestStatusCriteria,
  TaxiOfficerListRequestOrder,
} from "../../../api/raily/taxi-officer/list/taxi-officer-list.request";
import UserStatus from "../../../types/user-status";
import paginationService from "../../../utils/pagination/pagination.service";
import TaxiOfficerListLoadParams, {
  TaxiOfficerListLoadParamsOrder,
} from "./taxi-officer-list-load-params";

const createStatusCriteria = (
  status: UserStatus | undefined
): TaxiOfficerListRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return TaxiOfficerListRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return TaxiOfficerListRequestStatusCriteria.DELETED;
    default:
      return TaxiOfficerListRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: TaxiOfficerListLoadParamsOrder | undefined
): TaxiOfficerListRequestOrder | undefined => {
  switch (status) {
    case TaxiOfficerListLoadParamsOrder.FIRST_NAME_ASC:
      return TaxiOfficerListRequestOrder.FIRST_NAME_ASC;
    case TaxiOfficerListLoadParamsOrder.FIRST_NAME_DESC:
      return TaxiOfficerListRequestOrder.FIRST_NAME_DESC;
    case TaxiOfficerListLoadParamsOrder.LAST_NAME_ASC:
      return TaxiOfficerListRequestOrder.LAST_NAME_ASC;
    case TaxiOfficerListLoadParamsOrder.LAST_NAME_DESC:
      return TaxiOfficerListRequestOrder.LAST_NAME_DESC;
    case TaxiOfficerListLoadParamsOrder.USERNAME_ASC:
      return TaxiOfficerListRequestOrder.USERNAME_ASC;
    case TaxiOfficerListLoadParamsOrder.USERNAME_DESC:
      return TaxiOfficerListRequestOrder.USERNAME_DESC;
    case TaxiOfficerListLoadParamsOrder.STATUS_ASC:
      return TaxiOfficerListRequestOrder.STATUS_ASC;
    case TaxiOfficerListLoadParamsOrder.STATUS_DESC:
      return TaxiOfficerListRequestOrder.STATUS_DESC;
    default:
      return undefined;
  }
};

const create = (params: TaxiOfficerListLoadParams): TaxiOfficerListRequest => {
  return {
    taxiCorporation: params.taxiCorporation,
    taxiCorporationId: params.taxiCorporationId,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    statusCriteria: createStatusCriteria(params.status),
    username: params.username,
    creationEnd: params.creationEnd,
    creationStart: params.creationStart,
    limit: params.pageSize,
  };
};

const taxiOfficerListRequestFactory = { create };

export default taxiOfficerListRequestFactory;
