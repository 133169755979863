import OrderCancelRequestRequest from "../../../api/raily/order/order-cancel-request/order-cancel-request.request";
import OrderCancelRequestParams from "./order-cancel-request-params";

const create = (
  params: OrderCancelRequestParams
): OrderCancelRequestRequest => {
  return {
    orderId: params.orderUuid,
  };
};

const orderCancelRequestRequestFactory = {
  create,
};

export default orderCancelRequestRequestFactory;
