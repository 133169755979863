import PlannerDriverPlanListLoadParams, {
  PlannerDriverPlanListLoadParamsPlanType,
  PlannerDriverPlanListLoadParamsSorter,
  PlannerDriverPlanListLoadParamsSorterType,
} from "../../../../../common/services/planner/list/planner-driver-plan-list-load-params";
import PlanningOrderDriverPlanListingFilter, {
  PlanningOrderDriverPlanListingAddressFilter,
  PlanningOrderDriverPlanListingCargoOrderFilter,
  PlanningOrderDriverPlanListingDriverNameFilter,
  PlanningOrderDriverPlanListingResultFilter,
  PlanningOrderDriverPlanListingResultFilterValue,
} from "./planning-order-driver-plan-listing-filter";
import PlanningOrderDriverPlanListingFilterType from "./planning-order-driver-plan-listing-filter-type";
import PlanningOrderDriverPlanListingSortKey from "./planning-order-driver-plan-listing-sort-key";

const createSort = (
  sort: PlanningOrderDriverPlanListingSortKey
): PlannerDriverPlanListLoadParamsSorter => {
  switch (sort) {
    case PlanningOrderDriverPlanListingSortKey.DRIVER_NAME_ASC:
      return {
        sorterType: PlannerDriverPlanListLoadParamsSorterType.DRIVER_NAME_ASC,
      };
    case PlanningOrderDriverPlanListingSortKey.DRIVER_NAME_DESC:
      return {
        sorterType: PlannerDriverPlanListLoadParamsSorterType.DRIVER_NAME_DESC,
      };
    case PlanningOrderDriverPlanListingSortKey.ROUTE_END_DATE_ASC:
      return {
        sorterType: PlannerDriverPlanListLoadParamsSorterType.END_DATE_ASC,
      };
    case PlanningOrderDriverPlanListingSortKey.ROUTE_END_DATE_DESC:
      return {
        sorterType: PlannerDriverPlanListLoadParamsSorterType.END_DATE_DESC,
      };
    case PlanningOrderDriverPlanListingSortKey.ROUTE_START_DATE_ASC:
      return {
        sorterType: PlannerDriverPlanListLoadParamsSorterType.START_DATE_ASC,
      };
    case PlanningOrderDriverPlanListingSortKey.ROUTE_START_DATE_DESC:
      return {
        sorterType: PlannerDriverPlanListLoadParamsSorterType.START_DATE_DESC,
      };
  }
};

const createPlanType = (
  resultFilterValue: PlanningOrderDriverPlanListingResultFilterValue | undefined
): PlannerDriverPlanListLoadParamsPlanType[] => {
  switch (resultFilterValue) {
    case PlanningOrderDriverPlanListingResultFilterValue.EXISTING_PLAN:
      return [PlannerDriverPlanListLoadParamsPlanType.EXISTING_PLAN];
    case PlanningOrderDriverPlanListingResultFilterValue.NEW_PLAN:
      return [PlannerDriverPlanListLoadParamsPlanType.NEW_PLAN];
    default:
      return [
        PlannerDriverPlanListLoadParamsPlanType.EXISTING_PLAN,
        PlannerDriverPlanListLoadParamsPlanType.NEW_PLAN,
      ];
  }
};

const create = (
  filters: PlanningOrderDriverPlanListingFilter[],
  sortKey: PlanningOrderDriverPlanListingSortKey,
  page: number,
  pageSize: number,
  orderUuid: string
): PlannerDriverPlanListLoadParams => {
  const addressFilterValue = filters.find(
    (filter) => filter.type === PlanningOrderDriverPlanListingFilterType.ADDRESS
  )?.value as PlanningOrderDriverPlanListingAddressFilter["value"] | undefined;

  const driverNameFilterValue = filters.find(
    (filter) =>
      filter.type === PlanningOrderDriverPlanListingFilterType.DRIVER_NAME
  )?.value as
    | PlanningOrderDriverPlanListingDriverNameFilter["value"]
    | undefined;

  const cargoOrderFilterValue = filters.find(
    (filter) =>
      filter.type === PlanningOrderDriverPlanListingFilterType.CARGO_ORDER
  )?.value as
    | PlanningOrderDriverPlanListingCargoOrderFilter["value"]
    | undefined;

  const resultFilterValue = filters.find(
    (filter) => filter.type === PlanningOrderDriverPlanListingFilterType.RESULT
  )?.value as PlanningOrderDriverPlanListingResultFilter["value"] | undefined;

  return {
    address: addressFilterValue
      ? {
          query: addressFilterValue.address,
          dateFrom: addressFilterValue.startDate,
          dateTo: addressFilterValue.endDate,
        }
      : undefined,
    orderUuid,
    page,
    pageSize,
    driverName: driverNameFilterValue,
    orderQuery: cargoOrderFilterValue,
    planType: createPlanType(resultFilterValue),
    sort: createSort(sortKey),
  };
};

const planningOrderDriverPlanListingDriverPlanListLoadParamsFactory = {
  create,
};

export default planningOrderDriverPlanListingDriverPlanListLoadParamsFactory;
