import classNames from "classnames";
import { FC } from "react";
import { SteppedLineTo } from "react-lineto";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageDetailsPassenger from "../common/types/mileage-details-passenger";
import MileageDetailsRouteWaypoint from "../common/types/mileage-details-route-waypoint";
import MileageDetailsRouteHelper from "./mileage-details-route.helper";

type MileageDetailsRouteProps = {
  waypoints: MileageDetailsRouteWaypoint[];
  mileageUuid: string;
  selectedPassenger: MileageDetailsPassenger | null;
};

const MileageDetailsRouteComponent: FC<MileageDetailsRouteProps> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageDetailsTranslations().routes;

  const lineConnections =
    MileageDetailsRouteHelper.getDefinitionOfLineConnectionsBetweenWaypointsInList(
      props.waypoints,
      props.mileageUuid
    );

  return (
    <div className="mileage_details_routes">
      <div>{translations.headingText}</div>
      <ul className="mileage_details_routes_list">
        <li className="mileage_details_route">
          <div className="place">{translations.placeHeaderLabel}</div>
          <div className="distance" title={translations.distanceHeaderTitle}>
            {translations.distanceHeaderLabel}
          </div>
          <div
            className="completion_date"
            title={translations.completionDateTitle}
          >
            {translations.completionDateLabel}
          </div>
        </li>
        {props.waypoints.map((waypoint, index) => {
          return (
            <li
              key={`waypoint-${waypoint.place.latitude}${waypoint.place.longitude}-index-${index}`}
              className={classNames(
                "mileage_details_route",
                `mileage-uuid-${props.mileageUuid}`
              )}
            >
              <div className="place" title={waypoint.place.displayName}>
                <div className="index">{waypoint.stageNo}</div>
                <div
                  className={classNames(
                    "dot",
                    MileageDetailsRouteHelper.generateWaypointDotClassname(
                      waypoint,
                      props.mileageUuid
                    )
                  )}
                ></div>
                <div className="place_name">{waypoint.place.displayName}</div>
              </div>
              <div className="distance">
                {MileageDetailsRouteHelper.getDistanceLabel(waypoint.distance)}
              </div>
              <div
                className="completion_date"
                title={MileageDetailsRouteHelper.getCompletionDateTitle(
                  waypoint.completionDate
                )}
              >
                {MileageDetailsRouteHelper.getCompletionDateLabel(
                  waypoint.completionDate
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {lineConnections.map((item, index) => {
        return (
          <SteppedLineTo
            key={`waypoint-list-connection-line-index-${index}`}
            from={item.itemFromClassname}
            to={item.itemToClassname}
            delay={50}
            borderStyle={item.lineType}
            borderWidth={1}
            borderColor={"#000"}
            within={`mileage_details_route mileage-uuid-${props.mileageUuid}`}
            className="mileage_details_route__connection_line"
          />
        );
      })}
    </div>
  );
};

export default MileageDetailsRouteComponent;
