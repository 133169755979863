import DataLoadError, {
  DataLoadDefaultErrors,
} from "../../../utils/data-load-error/data-load-error";

export enum OrderCancelErrorType {
  ORDER_CANCELLED = "ORDER_CANCELLED",
  PASSENGER_ON_BOARD = "PASSENGER_ON_BOARD",
}

export type OrderCancelOrderCancelledError = DataLoadError<
  OrderCancelErrorType.ORDER_CANCELLED,
  {}
>;

export type OrderCancelPassengerOnboardError = DataLoadError<
  OrderCancelErrorType.PASSENGER_ON_BOARD,
  {}
>;

type OrderCancelError =
  | DataLoadDefaultErrors
  | OrderCancelOrderCancelledError
  | OrderCancelPassengerOnboardError;

export default OrderCancelError;
