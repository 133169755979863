import BillingsOrderAddressesRequest, {
  BillingsOrderAddressesRequestQueryParams,
} from "./billings-order-addresses.request";
const createQueryParams = (
  data: BillingsOrderAddressesRequest
): BillingsOrderAddressesRequestQueryParams => {
  return {
    searchQuery: data.searchQuery,
  };
};

const billingsOrderAddressesRequestFactory = {
  createQueryParams,
};

export default billingsOrderAddressesRequestFactory;
