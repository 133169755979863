import { FC } from "react";
import Row from "../../../../../../../common/components/grid/row";
import SteeringWheelIcon from "../../../../../common/icons/steering-wheel";
import dateService from "../../../../../../../common/utils/date/date.service";
import ArrowRightIcon from "../../../../../common/icons/arrow-right";
import { OrderOptimizerListingItemOrder } from "../../../../common/types/order-optimizer-listing-item";
import orderOptimizerListingHelper from "../../../../common/order-optimizer-listing.helper";
import classNames from "classnames";

type OrderOptimizerTableRowRouteOrderProps = {
  order: OrderOptimizerListingItemOrder;
  isSelected: boolean;
};

const OrderOptimizerListingTableRowRouteOrderComponent: FC<
  OrderOptimizerTableRowRouteOrderProps
> = (props) => {
  return (
    <div
      className={classNames(
        "order_optimizer_table_row_route__order",
        props.isSelected ? "selected" : ""
      )}
    >
      <Row>
        <div className="order_optimizer_table_row_route__order__left">
          ID: {props.order.humanId}
        </div>
        <div className="order_optimizer_table_row_route__order__right">
          {orderOptimizerListingHelper.getDistanceLabel(
            props.order.forwardDistance
          )}
        </div>
      </Row>
      <Row>
        <div className="order_optimizer_table_row_route__order__wheel_icon">
          <SteeringWheelIcon />
        </div>
        <div className="order_optimizer_table_row_route__order__plan_entry">
          {props.order.businessData.planEntryHumanId}
        </div>
      </Row>
      <div className="order_optimizer_table_row_route__order__bottom">
        <div className="order_optimizer_table_row_route__order__bottom__company_name">
          {props.order.businessData.cargoCompany}
        </div>
        <div className="order_optimizer_table_row_route__order__bottom__company_name">
          {props.order.businessData.taxiCompany}
        </div>
        <div className="order_optimizer_table_row_route__order_point">
          <div className="order_optimizer_table_row_route__order_point__start">
            <div className="order_optimizer_table_row_route__order_point__start__name">
              {props.order.businessData.startPointDisplayName}
            </div>
            <div>{dateService.formatDateTime(props.order.startTime)}</div>
          </div>
          <ArrowRightIcon className="order_optimizer_table_row_route__order_point__arrow" />
          <div className="order_optimizer_table_row_route__order_point__end">
            <div className="order_optimizer_table_row_route__order_point__end__name">
              {props.order.businessData.endPointDisplayName}
            </div>
            <div>{dateService.formatDateTime(props.order.endTime)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOptimizerListingTableRowRouteOrderComponent;
