import MileageAddRequest from "../../api/raily/mileage/add/mileage-add.request";
import MileageDetailsRequest from "../../api/raily/mileage/details/mileage-details.request";
import MileageDetailsResponse from "../../api/raily/mileage/details/mileage-details.response";
import MileageDownloadRequest from "../../api/raily/mileage/download/mileage-download.request";
import MileageListRequest from "../../api/raily/mileage/list/mileage-list.request";
import MileageListResponse from "../../api/raily/mileage/list/mileage-list.response";
import mileageApiService from "../../api/raily/mileage/mileage-api.service";
import MileageUpdateRequest from "../../api/raily/mileage/update/mileage-update-request";
import {
  MileageUpdateNotAcceptableResponse,
  MileageUpdateNotAcceptableResponseErrorType,
} from "../../api/raily/mileage/update/mileage-update-response";
import { DataLoadDefaultErrors } from "../../utils/data-load-error/data-load-error";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import fileDownloadService from "../../utils/file-download/file-download.service";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import SearchRoadRoutingRequest from "../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../utils/search-road-route/search-road-routing.response";
import MileageAddError from "./add/mileage-add-error";
import MileageAddParams from "./add/mileage-add-params";
import mileageAddRequestfactory from "./add/mileage-add-request.factory";
import MileageDetails from "./details/mileage-details";
import MileageDetailsLoadParams from "./details/mileage-details-load-params";
import mileageDetailsRequestFactory from "./details/mileage-details-request.factory";
import mileageDetailsFactory from "./details/mileage-details.factory";
import MileageDownloadError from "./download/mileage-download-error";
import MileageDownloadParams from "./download/mileage-download-params";
import mileageDownloadRequestFactory from "./download/mileage-download-request.factory";
import MileageList from "./list/mileage-list";
import MileageListLoadParams from "./list/mileage-list-load-params";
import mileageListRequestFactory from "./list/mileage-list-request.factory";
import mileageListFactory from "./list/mileage-list.factory";
import MileageRoadRouteItem from "./road-route/mileage-road-route-item";
import MileageRoadRouteLoadParams from "./road-route/mileage-road-route-load-params";
import mileageRoadRouteRequestFactory from "./road-route/mileage-road-route-request.factory";
import mileageRoadRouteFactory from "./road-route/mileage-road-route.factory";
import MileageUpdateError from "./update/mileage-update-error";
import mileageUpdateErrorFactory from "./update/mileage-update-error.factory";
import MileageUpdateParams from "./update/mileage-update-params";
import mileageUpdateRequestfactory from "./update/mileage-update-request.factory";

const handleListError = (error: HttpError): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleAddError = (
  error: HttpError | MileageAddError
): MileageAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDownloadError = (
  error: HttpError | MileageDownloadError
): MileageDownloadError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};
const handleUpdateError = (
  error: HttpError | MileageUpdateError
): MileageUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleRoadRouteError = (error: HttpError): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDetailsError = (error: HttpError): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: MileageListLoadParams,
  abortSignal: AbortSignal
): Promise<MileageList> => {
  const request: MileageListRequest = mileageListRequestFactory.create(params);

  try {
    const response: MileageListResponse = await mileageApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const mileageList: MileageList = mileageListFactory.create(response);

    return mileageList;
  } catch (_error) {
    const error = _error as HttpError;

    throw handleListError(error);
  }
};

const add = async (
  params: MileageAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: MileageAddRequest = mileageAddRequestfactory.create(params);

  try {
    const response = await mileageApiService().add(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | MileageAddError;

    throw handleAddError(error);
  }
};

const download = async (params: MileageDownloadParams): Promise<void> => {
  const request: MileageDownloadRequest =
    mileageDownloadRequestFactory.create(params);

  try {
    const response = await mileageApiService().download(request);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    fileDownloadService.downloadFromBlob(
      response.data.data,
      response.data.filename
    );
  } catch (_error) {
    const error = _error as HttpError | MileageDownloadError;

    throw handleDownloadError(error);
  }
};

const getRoute = async (
  params: MileageRoadRouteLoadParams
): Promise<MileageRoadRouteItem> => {
  const request: SearchRoadRoutingRequest =
    mileageRoadRouteRequestFactory.create(params);

  try {
    const response: SearchRoadRoutingResponse =
      await mileageApiService().getRoute(request);

    const RoadRouteItems: MileageRoadRouteItem =
      mileageRoadRouteFactory.create(response);

    return RoadRouteItems;
  } catch (_error) {
    const error = _error as HttpError;

    throw handleRoadRouteError(error);
  }
};

const createMileageUpdateErrorsNotAcceptableResponse = (
  response: MileageUpdateNotAcceptableResponse
): MileageUpdateError => {
  switch (response.data.errorType) {
    case MileageUpdateNotAcceptableResponseErrorType.MILEAGE_HAS_DELEGATION:
      return mileageUpdateErrorFactory.createMileageHasDelegationError();
    case MileageUpdateNotAcceptableResponseErrorType.NO_VALID_MILEAGE_CONTRACT_FOUND:
      return mileageUpdateErrorFactory.createNoValidMileageContractFoundError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const update = async (
  params: MileageUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: MileageUpdateRequest =
    mileageUpdateRequestfactory.create(params);

  try {
    const response = await mileageApiService().updateMileage(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createMileageUpdateErrorsNotAcceptableResponse(
        response as unknown as MileageUpdateNotAcceptableResponse
      );
    }

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | MileageUpdateError;

    throw handleUpdateError(error);
  }
};

const getDetails = async (
  params: MileageDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<MileageDetails> => {
  const request: MileageDetailsRequest =
    mileageDetailsRequestFactory.create(params);

  try {
    const response: MileageDetailsResponse =
      await mileageApiService().getDetails(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const mileageDetails: MileageDetails =
      mileageDetailsFactory.create(response);

    return mileageDetails;
  } catch (_error) {
    const error = _error as HttpError;

    throw handleDetailsError(error);
  }
};

const mileageService = {
  getList,
  add,
  download,
  getRoute,
  getDetails,
  update,
};

export default mileageService;
