import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import CargoOrderSettlementListingComponent from "../../cargo/order-settlement-listing/cargo-order-settlement-listing.component";
import TaxiOrderSettlementListingComponent from "../../taxi/order-settlement-listing/taxi-order-settlement-listing.component";
import TaxiRouteSettlementListingComponent from "../../taxi/route-settlement-listing/taxi-route-settlement-listing.component";
import TaxiTransferedOrderSettlementListingComponent from "../../taxi/transfered-order-settlement-listing/taxi-transfered-order-settlement-listing.component";
import TaxiUnsettledOrderListingComponent from "../../taxi/unsettled-order-listing/taxi-unsettled-order-listing.component";
import TaxiUnsettledPrivateOrderListingComponent from "../../taxi/unsettled-private-order-listing/taxi-unsettled-private-order-listing.component";
import TaxiUnsettledRouteListingComponent from "../../taxi/unsettled-route-listing/taxi-unsettled-route-listing.component";
import TaxiUnsettledTransferedOrderListingComponent from "../../taxi/unsettled-transfered-order-listing/taxi-unsettled-transfered-order-listing.component";
import settlementRoutesUrls from "./settlement-routes-urls";

const settlementsRoutesDefinition: RouteItemDefinition[] = [
  {
    path: settlementRoutesUrls.taxiListingOfUnsettledOrders,
    component: <TaxiUnsettledOrderListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: settlementRoutesUrls.cargoListingOfOrderSettlements,
    component: <CargoOrderSettlementListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  },
  {
    path: settlementRoutesUrls.taxiListingOfSettledRoutes,
    component: <TaxiRouteSettlementListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: settlementRoutesUrls.taxiListingOfUnsettledRoutes,
    component: <TaxiUnsettledRouteListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: settlementRoutesUrls.taxiListingOfUnsettledPrivateOrders,
    component: <TaxiUnsettledPrivateOrderListingComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: settlementRoutesUrls.taxiListingOfSettledOrders,
    component: <TaxiOrderSettlementListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: settlementRoutesUrls.taxiListingOfUnsettledTransferedOrders,
    component: <TaxiUnsettledTransferedOrderListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: settlementRoutesUrls.taxiListingOfSettledTransferedOrders,
    component: <TaxiTransferedOrderSettlementListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
];

export default settlementsRoutesDefinition;
