import { FC, useState } from "react";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import PlanningOrderDriverPlanListingFilter, {
  PlanningOrderDriverPlanListingAddressFilter,
} from "../common/planning-order-driver-plan-listing-filter";
import PlanningOrderDriverPlanListingSortKey from "../common/planning-order-driver-plan-listing-sort-key";
import planningOrderDriverPlanListingFilterHelper from "./planning-order-driver-plan-listing-filter.helper";
import PlanningOrderDriverPlanListingFilterType from "../common/planning-order-driver-plan-listing-filter-type";
import PlanningOrderDriverPlanListingAddressFilterComponent from "./address/planning-order-driver-plan-listing-address-filter.component";

type PlanningOrderDriverPlanListingFilterProps = {
  filters: PlanningOrderDriverPlanListingFilter[];
  onFiltersChange: (filters: PlanningOrderDriverPlanListingFilter[]) => void;
  sortKey: PlanningOrderDriverPlanListingSortKey;
  onSortKeyChange: (sortKey: PlanningOrderDriverPlanListingSortKey) => void;
};

const PlanningOrderDriverPlanListingFilterComponent: FC<
  PlanningOrderDriverPlanListingFilterProps
> = (props) => {
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);

  const openAddressForm = () => {
    setIsAddressFormOpen(true);
  };

  const closeAddressForm = () => {
    setIsAddressFormOpen(false);
  };

  const filterDefinition: ListingFilterDefinition<PlanningOrderDriverPlanListingFilter> =
    planningOrderDriverPlanListingFilterHelper().getFilterDefinition(
      openAddressForm
    );

  const sortDefinition: ListingSortDefinition =
    planningOrderDriverPlanListingFilterHelper().getSortDefinition();

  const addAddressFilter = (
    filterValue: PlanningOrderDriverPlanListingAddressFilter["value"]
  ) => {
    const addressFilter: PlanningOrderDriverPlanListingAddressFilter = {
      type: PlanningOrderDriverPlanListingFilterType.ADDRESS,
      value: filterValue,
    };

    const newFilters: PlanningOrderDriverPlanListingFilter[] = [
      ...props.filters,
      addressFilter,
    ];

    props.onFiltersChange(newFilters);
    closeAddressForm();
  };

  return (
    <>
      <ListingFilterComponent
        appliedFilters={props.filters}
        filterDefinition={filterDefinition}
        onFiltersChange={(appliedFilters) => {
          props.onFiltersChange(
            appliedFilters as PlanningOrderDriverPlanListingFilter[]
          );
        }}
        appliedSortKey={props.sortKey}
        onSortKeyChange={(value) =>
          props.onSortKeyChange(value as PlanningOrderDriverPlanListingSortKey)
        }
        sortDefinition={sortDefinition}
      />
      <PlanningOrderDriverPlanListingAddressFilterComponent
        isOpen={isAddressFormOpen}
        onClose={closeAddressForm}
        onApply={addAddressFilter}
      />
    </>
  );
};

export default PlanningOrderDriverPlanListingFilterComponent;
