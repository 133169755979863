import { FC, Fragment, ReactNode } from "react";

type HeadingProps = {
  title: string;

  actions?: ReactNode[];
};

const HeadingComponent: FC<HeadingProps> = (props) => {
  return (
    <div className="heading">
      <div>
        <h1 className="heading_title">{props.title}</h1>
      </div>
      <div className="heading_actions">
        {props.actions?.map((item, index) => (
          <Fragment key={index}>{item}</Fragment>
        ))}
      </div>
    </div>
  );
};

export default HeadingComponent;
