import UserStatus from "../../../../../common/types/user-status";
import { UserDealerListingRouteQueryParams } from "../../../common/routes/types/user-dealer-listing-route-params";
import DealerListingFilter, {
  DealerListingActivityStatusFilter,
  DealerListingEmailAddressFilter,
  DealerListingFirstNameFilter,
  DealerListingLastNameFilter,
  DealerListingTaxiCorporationFilter,
  DealerListingUserNameFilter,
} from "../common/types/dealer-listing-filter";
import DealerListingFilterType from "../common/types/dealer-listing-filter-type";
import DealerListingSortKey from "../common/types/dealer-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: UserDealerListingRouteQueryParams
): DealerListingFilter[] => {
  const filters: DealerListingFilter[] = [];

  if (routeQueryFilterParams.firstName) {
    const firstNameFilter: DealerListingFirstNameFilter = {
      type: DealerListingFilterType.FIRST_NAME,
      value: routeQueryFilterParams.firstName,
    };

    filters.push(firstNameFilter);
  }

  if (routeQueryFilterParams.lastName) {
    const lastNameFilter: DealerListingLastNameFilter = {
      type: DealerListingFilterType.LAST_NAME,
      value: routeQueryFilterParams.lastName,
    };

    filters.push(lastNameFilter);
  }

  if (routeQueryFilterParams.username) {
    const usernameFilter: DealerListingUserNameFilter = {
      type: DealerListingFilterType.USERNAME,
      value: routeQueryFilterParams.username,
    };

    filters.push(usernameFilter);
  }

  if (routeQueryFilterParams.email) {
    const emailFilter: DealerListingEmailAddressFilter = {
      type: DealerListingFilterType.EMAIL_ADDRESS,
      value: routeQueryFilterParams.email,
    };

    filters.push(emailFilter);
  }

  if (routeQueryFilterParams.status) {
    const activityFilter: DealerListingActivityStatusFilter = {
      type: DealerListingFilterType.ACTIVITY_STATUS,
      value:
        routeQueryFilterParams.status === "DELETED"
          ? UserStatus.DELETED
          : UserStatus.ACTIVE,
    };

    filters.push(activityFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: UserDealerListingRouteQueryParams
): DealerListingSortKey | undefined => {
  return routeQueryParams.sort as DealerListingSortKey;
};

const getPage = (
  routeQueryParams: UserDealerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: UserDealerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: DealerListingFilter[],
  sortKey: DealerListingSortKey | null,
  page: number,
  pageSize: number
): UserDealerListingRouteQueryParams => {
  const userActivityFilterValue = filters.find(
    (filter) => filter.type === DealerListingFilterType.ACTIVITY_STATUS
  )?.value as DealerListingActivityStatusFilter["value"] | undefined;

  const routeQueryParams: UserDealerListingRouteQueryParams = {
    taxiCorporation: filters.find(
      (filter) => filter.type === DealerListingFilterType.TAXI_CORPORATION
    )?.value as DealerListingTaxiCorporationFilter["value"],
    firstName: filters.find(
      (filter) => filter.type === DealerListingFilterType.FIRST_NAME
    )?.value as DealerListingFirstNameFilter["value"],
    lastName: filters.find(
      (filter) => filter.type === DealerListingFilterType.LAST_NAME
    )?.value as DealerListingLastNameFilter["value"],
    username: filters.find(
      (filter) => filter.type === DealerListingFilterType.USERNAME
    )?.value as DealerListingUserNameFilter["value"],
    email: filters.find(
      (filter) => filter.type === DealerListingFilterType.EMAIL_ADDRESS
    )?.value as DealerListingEmailAddressFilter["value"],
    status:
      userActivityFilterValue === undefined
        ? undefined
        : String(userActivityFilterValue),
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const dealerListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default dealerListingFiltersService;
