enum TaxiUnsettledRouteListingSortKey {
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  ROUTE_ID_ASC = "ROUTE_ID_ASC",
  ROUTE_ID_DESC = "ROUTE_ID_DESC",
  DRIVER_ASC = "DRIVER_ASC",
  DRIVER_DESC = "DRIVER_DESC",
}

export default TaxiUnsettledRouteListingSortKey;
