import userFormValidationService from "../../../../../../shared/user/user.validation.service";

const driverAddAccountDataFormValidationService = {
  validateEmail: userFormValidationService.validateEmail,
  validateUsername: userFormValidationService.validateUsername,
  validatePassword: userFormValidationService.validatePassword,
  validatePasswordRepeat: userFormValidationService.validatePasswordRepeat,
};

export default driverAddAccountDataFormValidationService;
