export enum MileageSettingsUpdateParamsMileageNumberCreationModel {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
  DICT = "DICT",
}

type MileageSettingsUpdateParams = {
  cargoCompanyUuid: string;
  isCardNumberRequired?: boolean;
  isMileageNumberRequired?: boolean;
  mileageNumberCreationModel?: MileageSettingsUpdateParamsMileageNumberCreationModel;
};

export default MileageSettingsUpdateParams;
