import UserRole from "../../../../../common/types/user-role";
import userPermissionsService from "../../../../../common/utils/user/permissions/user-permissions.service";
import delegationAddUserPermissionsDefinition, {
  DelegationAddUserPermissions,
} from "./delegation-add-user-permissions";

const getPermissions = (
  userRoles: UserRole[]
): DelegationAddUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    delegationAddUserPermissionsDefinition
  );

  return permissions;
};

const delegationAddUserPermissionsHelper = {
  getPermissions,
};

export default delegationAddUserPermissionsHelper;
