enum MessengerChannel {
  BILLING = "BILLING",
  CARGO = "CARGO",
  RAILY = "RAILY",
  SHARED = "SHARED",
  TAXI = "TAXI",
  DRIVER = "DRIVER",
  MILEAGE_PASSENGER = "MILEAGE_PASSENGER",
  MILEAGE_NOTES = "MILEAGE_NOTES",
  DELEGATION_NOTES = "DELEGATION_NOTES",
}

export default MessengerChannel;
