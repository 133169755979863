import BillingsOrderForwardTaxiDriverRequest from "../../../../api/raily/billings/order-forward/taxi-driver/billings-order-forward-taxi-driver.request";
import BillingsOrderForwardTaxiDriverLoadParams from "./billings-order-forward-taxi-driver-load-params";

const create = (
  params: BillingsOrderForwardTaxiDriverLoadParams
): BillingsOrderForwardTaxiDriverRequest => {
  return {
    forwardingId: params.forwardingUuid,
  };
};

const billingsOrderForwardTaxiDriverRequestFactory = {
  create,
};

export default billingsOrderForwardTaxiDriverRequestFactory;
