import { FC } from "react";
import cargoOfficerListingTableHelper from "./cargo-officer-listing-table.helper";
import UserStatus from "../../../../../common/types/user-status";
import TableComponent from "../../../../../common/components/table/table.component";
import TableDeleteButtonComponent from "../../../../../common/components/table/button/delete/table-delete-button.component";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import UserDeleteUserData from "../../../common/delete/common/types/user-delete-user-data";
import useCargoOfficerListingUserPermissions from "../common/user-permissions/use-cargo-officer-listing-user-permission";
import CargoOfficerListingTableColumn from "../common/types/cargo-officer-listing-table-column";
import CargoOfficerListingTableRow from "../common/types/cargo-officer-listing-table-row";
import CargoOfficerListingItem from "../common/types/cargo-officer-listing-item";

type CargoOfficerListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: CargoOfficerListingItem[];
  onSelectUserToDelete: (userData: UserDeleteUserData) => void;
};

const CargoOfficerListingTableComponent: FC<CargoOfficerListingTableProps> = (
  props
) => {
  const userPermissions = useCargoOfficerListingUserPermissions();

  const translations =
    userTranslationsHelper.getCargoOfficerListingTranslations();

  const columns: CargoOfficerListingTableColumn[] =
    cargoOfficerListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: CargoOfficerListingItem
  ): CargoOfficerListingTableRow => {
    const isDeleteButtonVisible =
      userPermissions.hasAccessToDeleteUser &&
      listingItem.status !== UserStatus.DELETED;
    const activityStatus = cargoOfficerListingTableHelper.getStatusText(
      listingItem.status
    );

    return {
      id: listingItem.uuid,
      value: {
        firstName: (
          <div title={listingItem.firstName}>{listingItem.firstName}</div>
        ),
        lastName: (
          <div title={listingItem.lastName}>{listingItem.lastName}</div>
        ),
        username: (
          <div title={listingItem.username}>{listingItem.username}</div>
        ),
        email: <div title={listingItem.email}>{listingItem.email}</div>,
        phoneNumbers: (
          <div>
            <div>
              {listingItem.phoneNumbers.map((phoneNumber, index) => (
                <div key={index} title={phoneNumber}>
                  {phoneNumber}
                </div>
              ))}
            </div>
          </div>
        ),
        cargoCompany: (
          <div title={listingItem.cargoCompanyName}>
            {listingItem.cargoCompanyName}
          </div>
        ),
        status: <div title={activityStatus}>{activityStatus}</div>,
        actions: (
          <div className="d-flex">
            {isDeleteButtonVisible && (
              <TableDeleteButtonComponent
                onClick={() =>
                  props.onSelectUserToDelete({
                    uuid: listingItem.uuid,
                    firstName: listingItem.firstName,
                    lastName: listingItem.lastName,
                  })
                }
                title={translations.table.cargoOfficerDeleteButtonTitle}
                idForTesting={`cargo-officer-listing-table-item-${listingItem.uuid}-delete-button`}
              />
            )}
          </div>
        ),
      },
    };
  };

  const rows: CargoOfficerListingTableRow[] = props.listingItems.map(
    (listingItem) => createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default CargoOfficerListingTableComponent;
