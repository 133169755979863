import CargoCompanyListLoadParams from "../../../../../../common/services/cargo-company/cargo-company/list/cargo-company-list-load-params";

const createCargoCompanyLoadParams = (
  displayNameQuery: string | undefined
): CargoCompanyListLoadParams => {
  return {
    companyDisplayName: !!displayNameQuery ? displayNameQuery : undefined,
  };
};

const mileageDictionarylistingCompanyLoadParamsFactory = {
  createCargoCompanyLoadParams,
};

export default mileageDictionarylistingCompanyLoadParamsFactory;
