import HttpResponse from "../../../../utils/http/http.response";

export enum CargoOrderDetailsComplexResponseItemStatus {
  CANDIDATURE = "CANDIDATURE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  PLANNED = "PLANNED",
  FINISHED = "FINISHED",
}

export enum CargoOrderDetailsComplexResponseItemPublicStatus {
  PUBLIC = "PUBLIC",
  PUBLISHED = "PUBLISHED",
  PRIVATE = "PRIVATE",
}

export enum CargoOrderDetailsComplexResponseItemTransportingOrderDriverCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum CargoOrderDetailsComplexResponseItemTransportingOrderDriverCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export enum CargoOrderDetailsComplexResponseItemTransportingOrderDriverLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum CargoOrderDetailsComplexResponseItemPassengerType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

type CargoOrderDetailsComplexResponseItemNodeExclusions = {
  nodeIds: string[];
  excludeApproaching: boolean;
  excludeReturning: boolean;
  excludeInside: boolean;
};

export type CargoOrderDetailsComplexResponseItemDispatcher = {
  dispatchId: string;
  dispatch: CargoOrderDetailsComplexResponseItemDispatch | null;
};

export type CargoOrderDetailsComplexResponseItemDispatch = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  cargoCompany: CargoOrderDetailsComplexResponseItemDispatchCargoCompany;
};

export type CargoOrderDetailsComplexResponseItemDispatchCargoCompany = {
  id: string;
  displayName: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
};

export type CargoOrderDetailsComplexResponseItemTransportingOrder = {
  id: string;
  driver: CargoOrderDetailsComplexResponseItemTransportingOrderDriver | null;
  passengers: CargoOrderDetailsComplexResponseItemTransportingOrderPassenger[];
  solve: CargoOrderDetailsComplexResponseItemDriverSolveWaypoint[];
  driverSolve: CargoOrderDetailsComplexResponseItemDriverSolveWaypoint[] | null;
  createSolvedOrderEntries: CargoOrderDetailsComplexResponseItemSolvedOrderEntries[];
  createPlanEntryEntries: CargoOrderDetailsComplexResponseItemPlanEntryEntries[];
};

export type CargoOrderDetailsComplexResponseItemTransportingOrderDriver = {
  removedBy: string | null;
  removedAt: Date | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
  driver: CargoOrderDetailsComplexResponseItemTransportingOrderDriverDetails;
};

type CargoOrderDetailsComplexResponseItemTransportingOrderDriverDetails = {
  birthDate: Date;
  birthPlace: string;
  idNumber: string;
  citizenship: CargoOrderDetailsComplexResponseItemTransportingOrderDriverCitizenship[];
  languages: CargoOrderDetailsComplexResponseItemTransportingOrderDriverLanguage[];
  experience: number;
  carOwnership: CargoOrderDetailsComplexResponseItemTransportingOrderDriverCarOwnership[];
  carMake: string | null;
  carModel: string | null;
  carRegNo: string | null;
  carProdYear: number | null;
  carSeatsNo: number | null;
  mobileModel: string | null;
  planApproved: boolean;
  startingAddress: CargoOrderDetailsComplexResponseItemTransportingOrderDriverAddress;
  mailingAddress: CargoOrderDetailsComplexResponseItemTransportingOrderDriverMailingAddress;
};

type CargoOrderDetailsComplexResponseItemTransportingOrderDriverAddress = {
  lat: number;
  lon: number;
  country: string | null;
  town: string | null;
  zipCode: string | null;
  street: string | null;
  houseNo: string | null;
  apartment: string | null;
  description: string | null;
  displayName: string;
};

type CargoOrderDetailsComplexResponseItemTransportingOrderDriverMailingAddress =
  {
    lat: number;
    lon: number;
    country: string | null;
    town: string | null;
    zipCode: string | null;
    street: string | null;
    houseNo: string | null;
    apartment: string | null;
    description: string | null;
    displayName: string;
  };

export type CargoOrderDetailsComplexResponseItemTransportingOrderPassenger =
  | CargoOrderDetailsComplexResponseItemTransportingOrderInternalPassenger
  | CargoOrderDetailsComplexResponseItemTransportingOrderExternalPassenger;

export type CargoOrderDetailsComplexResponseItemTransportingOrderInternalPassenger =
  {
    itemType: CargoOrderDetailsComplexResponseItemPassengerType.INTERNAL;
    firstName: string;
    lastName: string;
    dispatch: string | null;
    phonePrefix: string;
    phoneNo: string;
    cargoItemId: string;
    passengerId: string;
  };

export type CargoOrderDetailsComplexResponseItemTransportingOrderExternalPassenger =
  {
    itemType: CargoOrderDetailsComplexResponseItemPassengerType.EXTERNAL;
    firstName: string;
    lastName: string;
    dispatch: string | null;
    phonePrefix: string;
    phoneNo: string;
    cargoItemId: string;
  };

export type CargoOrderDetailsComplexResopnseItemSolve =
  | CargoOrderDetailsComplexResponseItemSolveWaypoint
  | CargoOrderDetailsComplexResponseItemSolveWaypoint[];

export type CargoOrderDetailsComplexResponseItemSolveWaypoint = {
  nodeId: string;
  lat: number;
  lon: number;
  requestedTime: Date | null;
  requestedHaltingTime: number;
  displayName: string;
  cargoEnter: string[];
  cargoExit: string[];
  estimatedTime: Date;
  checkoutEvent: CargoOrderDetailsComplexResponseItemSolveCheckoutEvent | null;
  distance: number;
};

export type CargoOrderDetailsComplexResponseItemDriverSolveWaypoint = {
  nodeId: string;
  lat: number;
  lon: number;
  requestedTime: Date | null;
  requestedHaltingTime: number;
  displayName: string;
  cargoEnter: string[];
  cargoExit: string[];
  estimatedTime: Date;
  checkoutEvent: CargoOrderDetailsComplexResponseItemSolveCheckoutEvent | null;
  distance: number;
};

type CargoOrderDetailsComplexResponseItemSolveCheckoutEvent = {
  achievedAt: Date;
  haltingTime: number | null;
};

type CargoOrderDetailsComplexResponseItemSolvedOrderEntries = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  driver: CargoOrderDetailsComplexResponseItemSolvedOrderEntriesDriver;
  candidatureId: string;
};

type CargoOrderDetailsComplexResponseItemPlanEntryEntries = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  driver: CargoOrderDetailsComplexResponseItemSolvedOrderEntriesDriver;
  candidatureId: string;
};

export type CargoOrderDetailsComplexResponseItemSolvedOrderEntriesDriver = {
  removedAt: string | null;
  removedBy: string | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
};

export type CargoOrderDetailsComplexResponseItem = {
  id: string;
  isPublished: boolean;
  targetTaxiId: string | null;
  isFinished: boolean;
  externalId: string | null;
  humanId: number;
  isCancelled: boolean;
  isCancelRequested: boolean;
  approvingDispatcherId: string | null;
  approvingOperatorId: string | null;
  approvingDealerId: string | null;
  orderType: CargoOrderDetailsComplexResponseItemPublicStatus;
  status: CargoOrderDetailsComplexResponseItemStatus;
  nodeExclusions: CargoOrderDetailsComplexResponseItemNodeExclusions;
  dispatcher: CargoOrderDetailsComplexResponseItemDispatcher;
  transportingOrders: CargoOrderDetailsComplexResponseItemTransportingOrder[];
};

type CargoOrderDetailsComplexResponse =
  HttpResponse<CargoOrderDetailsComplexResponseItem>;

export default CargoOrderDetailsComplexResponse;
