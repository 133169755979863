import useAsyncData from "../../../../hooks/use-async-data";
import billingService from "../../billings.service";
import BillingsOrderTaxiTaxi from "./billings-order-taxi-taxi";
import BillingsOrderTaxiTaxiLoadParams from "./billings-order-taxi-taxi-load-params";

const useBillingsOrderTaxiTaxi = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<BillingsOrderTaxiTaxi>({
      data: [],
    });

  const load = async (
    params: BillingsOrderTaxiTaxiLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const taxiTaxi = await billingService.getOrderTaxiTaxi(params, signal);

      setData(taxiTaxi);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useBillingsOrderTaxiTaxi;
