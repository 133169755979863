import { SearchRoadRoutingResponseRouteLeg } from "../../../../../common/utils/search-road-route/search-road-routing.response";
import orderJoinHelper from "../../order-join.helper";
import { OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint } from "../api/order-join-order-details-solved-rides.response";
import {
  OrderJoinOrderDetailsResponseDataCargoOrderRide,
  OrderJoinPreviewResponseRideWaypoint,
  OrderJoinOrderDetailsResponseSolvedOrderRideSeqItem,
  OrderJoinOrderDetailsResponseTransportingOrder,
} from "../api/order-join-order-details.response";
import OrderJoinOrderDetailsRouteWaypoint from "../types/order-join-order-details-route-waypoint";

const createOrderDetailsWaypointForSolvedOrderRide = (
  responseRideWaypoint: OrderJoinOrderDetailsResponseSolvedOrderRideSeqItem,
  stageNo: number,
  distance: number | null,
  completionDate: Date | null
): OrderJoinOrderDetailsRouteWaypoint => {
  return {
    haltingTime: responseRideWaypoint.halting_time,
    onboardingPassengersUuids:
      responseRideWaypoint.meta.cargo_enter.subjects.map(
        (subject) => subject.id
      ),
    outboardingPassengersUuids:
      responseRideWaypoint.meta.cargo_exit.subjects.map(
        (subject) => subject.id
      ),
    orderedDate: responseRideWaypoint.time
      ? new Date(responseRideWaypoint.time)
      : null,
    place: {
      displayName: responseRideWaypoint.display_name,
      latitude: responseRideWaypoint.lat,
      longitude: responseRideWaypoint.lon,
    },
    stageNo,
    completionDate,
    distance,
    estimatedDate: new Date(responseRideWaypoint.meta.estimated_time),
  };
};

const createOrderDetailsWaypointsFromSolvedOrders = (
  responseSolvedOrderRideSeqItems: OrderJoinOrderDetailsResponseSolvedOrderRideSeqItem[],
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[]
): OrderJoinOrderDetailsRouteWaypoint[] => {
  let waypoints: OrderJoinOrderDetailsRouteWaypoint[] = [];

  let stageNo = 1;

  responseSolvedOrderRideSeqItems.forEach((sequenceItem) => {
    const routeFragementDetails = responseRouteLegs.find((responseRouteLeg) => {
      return (
        responseRouteLeg.end.latitude === sequenceItem.lat &&
        responseRouteLeg.end.longitude === sequenceItem.lon
      );
    });

    const distance = responseRouteLegs.length
      ? routeFragementDetails?.distance ?? 0
      : null;

    const completionDate = null;

    const newWaypoint = createOrderDetailsWaypointForSolvedOrderRide(
      sequenceItem,
      stageNo,
      distance,
      completionDate
    );

    waypoints = [...waypoints, newWaypoint];

    stageNo++;
  });

  return waypoints;
};

const createOrderWaypointFromCargoOrderRide = (
  responseRideWaypoint: OrderJoinPreviewResponseRideWaypoint,
  stageNo: number,
  distance: number | null,
  completionDate: Date | null,
  estimatedDate: Date | null
): OrderJoinOrderDetailsRouteWaypoint => {
  return {
    haltingTime: responseRideWaypoint.halting_time,
    onboardingPassengersUuids:
      responseRideWaypoint.meta.cargo_enter.subjects.map(
        (subject) => subject.id
      ),
    outboardingPassengersUuids:
      responseRideWaypoint.meta.cargo_exit.subjects.map(
        (subject) => subject.id
      ),
    orderedDate: responseRideWaypoint.time
      ? new Date(responseRideWaypoint.time)
      : null,
    place: {
      displayName: responseRideWaypoint.display_name,
      latitude: responseRideWaypoint.lat,
      longitude: responseRideWaypoint.lon,
    },
    stageNo,
    completionDate,
    distance,
    estimatedDate,
  };
};

const createOrderWaypointsFromCargoOrderRide = (
  responseCargoOrderRide: OrderJoinOrderDetailsResponseDataCargoOrderRide,
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[],
  responseCargoOrderSolvedRides: OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint[],
  isGroup?: boolean
): OrderJoinOrderDetailsRouteWaypoint[] => {
  let waypoints: OrderJoinOrderDetailsRouteWaypoint[] = [];

  let stageNo = 1;

  responseCargoOrderRide.seq.forEach((sequenceItem) => {
    const isRideWaypoint =
      orderJoinHelper.isResponseRideSeqItemAWaypoint(sequenceItem);

    if (isRideWaypoint) {
      const routeFragementDetails = responseRouteLegs.find(
        (responseRouteLeg) => {
          return (
            responseRouteLeg.end.latitude === sequenceItem.lat &&
            responseRouteLeg.end.longitude === sequenceItem.lon
          );
        }
      );

      const distance = responseRouteLegs.length
        ? routeFragementDetails?.distance ?? 0
        : null;

      const completionDate = null;

      const foundCargoOrderSolvedRide = responseCargoOrderSolvedRides.find(
        (ride) => ride.meta.id === sequenceItem.meta.id
      );

      const estimatedDate =
        !isGroup && foundCargoOrderSolvedRide?.meta.estimated_time
          ? new Date(foundCargoOrderSolvedRide.meta.estimated_time)
          : null;

      const newWaypoint = createOrderWaypointFromCargoOrderRide(
        sequenceItem,
        stageNo,
        distance,
        completionDate,
        estimatedDate
      );

      waypoints = [...waypoints, newWaypoint];

      stageNo++;

      return;
    }

    const newWaypoints = createOrderWaypointsFromCargoOrderRide(
      sequenceItem,
      responseRouteLegs,
      responseCargoOrderSolvedRides,
      true
    );

    newWaypoints.forEach((waypoint) => (waypoint.stageNo = stageNo));

    waypoints = [...waypoints, ...newWaypoints];

    stageNo++;
  });

  return waypoints;
};

const createWaypointsForOrderedRoute = (
  responseCargoOrderRide: OrderJoinOrderDetailsResponseDataCargoOrderRide,
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[],
  responseCargoOrderSolvedRides: OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint[]
): OrderJoinOrderDetailsRouteWaypoint[] => {
  return createOrderWaypointsFromCargoOrderRide(
    responseCargoOrderRide,
    responseRouteLegs,
    responseCargoOrderSolvedRides
  );
};

const createWaypointsForSolvedRoute = (
  responseTransportingOrders: OrderJoinOrderDetailsResponseTransportingOrder[],
  responseRouteLegs: SearchRoadRoutingResponseRouteLeg[]
): OrderJoinOrderDetailsRouteWaypoint[] => {
  const solvedOrderRidesFromAllTransportingOrders = responseTransportingOrders
    .map((item) => item.solved_order!)
    .map((item) => item.ride.seq)
    .flat();

  const waypoints = createOrderDetailsWaypointsFromSolvedOrders(
    solvedOrderRidesFromAllTransportingOrders,
    responseRouteLegs
  );

  return waypoints;
};

const orderJoinOrderDetailsWaypointFactory = {
  createWaypointsForOrderedRoute,
  createWaypointsForSolvedRoute,
};

export default orderJoinOrderDetailsWaypointFactory;
