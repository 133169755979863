import CargoCompanyMileageContractDetails from "../../../../../../common/services/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import DelegationAddRouteGroup from "../../types/delegation-add-route-group";
import DelegationAddRouteItem from "../../types/delegation-add-route-item";
import DelegationAddVehicleType from "../../types/delegation-add-vehicle-type";

const getNewEmptyGroup = (): DelegationAddRouteGroup => {
  return {
    uuid: uuidService.generate(),
    routes: [getNewEmptyRoute(), getNewEmptyRoute()],
    startDate: null,
    endDate: null,
    distance: null,
    duration: null,
    mapWaypoints: [],
    mapRoute: null,
    vehicleType: DelegationAddVehicleType.CAR_GT_900CM3,
    groupCost: 0,
  };
};

const getNewEmptyRoute = (): DelegationAddRouteItem => {
  return {
    uuid: uuidService.generate(),
    address: null,
  };
};

const getReorderedRouteGroups = (
  startIndex: number,
  endIndex: number,
  groups: DelegationAddRouteGroup[]
): DelegationAddRouteGroup[] => {
  const result = [...groups];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getDistanceRateForVehicleType = (
  type: DelegationAddVehicleType,
  contract: CargoCompanyMileageContractDetails | null
) => {
  if (!contract) return 0;

  switch (type) {
    case DelegationAddVehicleType.COMPANY_OWNED:
      return contract.distanceRateCompanyVehicle;
    case DelegationAddVehicleType.CAR_GT_900CM3:
      return contract.distanceRateCarGt900cm3;
    case DelegationAddVehicleType.CAR_LE_900CM3:
      return contract.distanceRateCarLe900cm3;
    case DelegationAddVehicleType.MOTORCYCLE:
      return contract.distanceRateMotorcycle;
    case DelegationAddVehicleType.MOPED:
      return contract.distanceRateMoped;
  }
};

const getReorderedRouteItems = (
  startIndex: number,
  endIndex: number,
  group: DelegationAddRouteGroup
): DelegationAddRouteItem[] => {
  const result = [...group.routes];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const delegationAddRoutesHelper = {
  getNewEmptyRoute,
  getNewEmptyGroup,
  getReorderedRouteGroups,
  getDistanceRateForVehicleType,
  getReorderedRouteItems,
};

export default delegationAddRoutesHelper;
