import MapCoordinate from "../../../../../common/components/map/types/map-coordinate";
import MapRoute from "../../../../../common/components/map/types/map-route";
import DelegationRoadRouteItem, {
  DelegationRoadRouteCoordinate,
} from "../../../../../common/services/delegation/road-route/delegation-road-route-item";

const createMapRouteCoordinate = (
  coordinate: DelegationRoadRouteCoordinate
): MapCoordinate => {
  return {
    latitude: coordinate[1],
    longitude: coordinate[0],
  };
};

const createMapRoute = (item: DelegationRoadRouteItem): MapRoute => {
  return {
    waypoints: item.coordinates.map(createMapRouteCoordinate),
  };
};

const createMapRoutes = (data: DelegationRoadRouteItem[]): MapRoute[] => {
  return data.map(createMapRoute);
};

const delegationDetailsRouteFactory = {
  createMapRoutes,
};

export default delegationDetailsRouteFactory;
