import MileageRoadRouteLoadParams, {
  MileageRoadRouteWaypoints,
} from "../../../../../../common/services/mileage/road-route/mileage-road-route-load-params";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import MileageAddAddress from "../../types/mileage-add-address";
import MileageAddSummaryRouteWaypoint from "./route/types/mileage-add-summary-route-waypoint";

const createWaypoint = (
  address: MileageAddAddress,
  addressIndex: number
): MileageAddSummaryRouteWaypoint => {
  return {
    distance: null,
    markerPosition: addressIndex + 1,
    time: null,
    place: {
      displayName: address.displayName,
      latitude: address.latitude,
      longitude: address.longitude,
    },
    uuid: uuidService.generate(),
  };
};

const createWaypoints = (
  addresses: MileageAddAddress[]
): MileageAddSummaryRouteWaypoint[] => {
  return addresses.map(createWaypoint);
};

const createSearchRoutingCoordinate = (
  waypoint: MileageAddSummaryRouteWaypoint
): MileageRoadRouteWaypoints => {
  return {
    lat: waypoint.place?.latitude!,
    lon: waypoint.place?.longitude!,
  };
};

const createSearchRoutingRequest = (
  waypoints: MileageAddSummaryRouteWaypoint[]
): MileageRoadRouteLoadParams => {
  const request: MileageRoadRouteLoadParams = {
    waypoints: waypoints.map(createSearchRoutingCoordinate),
  };

  return request;
};

const mileageAddSummaryFactory = {
  createWaypoints,
  createSearchRoutingRequest,
};

export default mileageAddSummaryFactory;
