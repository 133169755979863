import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import settlementTranslationsHelper from "../../../../../../../languages/settlement-translations.helper";
import TaxiUnsettledRouteListingFilter, {
  TaxiUnsettledRouteListingClientFilter,
  TaxiUnsettledRouteListingDriverFilter,
  TaxiUnsettledRouteListingFleetPartnerFilter,
  TaxiUnsettledRouteListingOrderInternalIdFilter,
  TaxiUnsettledRouteListingRouteAddressFilter,
  TaxiUnsettledRouteListingRouteDestinationAddressFilter,
  TaxiUnsettledRouteListingRouteIdFilter,
  TaxiUnsettledRouteListingRouteIntermediateAddressFilter,
  TaxiUnsettledRouteListingRoutePickupAddressFilter,
  TaxiUnsettledRouteListingRouteStartDateFilter,
} from "../../types/taxi-unsettled-route-listing-filter";
import TaxiUnsettledRouteListingFilterType from "../../types/taxi-unsettled-route-listing-filter-type";
import dateService from "../../../../../../../common/utils/date/date.service";

const getRouteStartDateBadgeOption = (
  filter: TaxiUnsettledRouteListingRouteStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: TaxiUnsettledRouteListingRouteStartDateFilter;
} => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledRouteListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.startDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.startDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: TaxiUnsettledRouteListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getBadgeOptions = (filter: TaxiUnsettledRouteListingFilter) => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledRouteListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: TaxiUnsettledRouteListingFilter;
  }[] = [
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingDriverFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.DRIVER,
        value: filter.value as TaxiUnsettledRouteListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.fleetPartnerFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingFleetPartnerFilter["value"]
        ),
        title: translations.fleetPartnerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingFleetPartnerFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.FLEET_PARTNER,
        value:
          filter.value as TaxiUnsettledRouteListingFleetPartnerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.clientFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingClientFilter["value"]
        ),
        title: translations.clientFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingClientFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.CLIENT,
        value: filter.value as TaxiUnsettledRouteListingClientFilter["value"],
      },
    },
    {
      badge: {
        text: translations.orderInternalIdFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledRouteListingOrderInternalIdFilter["value"]
          )
        ),
        title: translations.orderInternalIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledRouteListingOrderInternalIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.ORDER_INTERNAL_ID,
        value:
          filter.value as TaxiUnsettledRouteListingOrderInternalIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIdFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledRouteListingRouteIdFilter["value"]
          )
        ),
        title: translations.routeIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledRouteListingRouteIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_ID,
        value: filter.value as TaxiUnsettledRouteListingRouteIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as TaxiUnsettledRouteListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as TaxiUnsettledRouteListingRoutePickupAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeDestinationAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingRouteDestinationAddressFilter["value"]
        ),
        title: translations.routeDestinationAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingRouteDestinationAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as TaxiUnsettledRouteListingRouteDestinationAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingRouteAddressFilter["value"]
        ),
        title: translations.routeAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledRouteListingRouteAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as TaxiUnsettledRouteListingRouteAddressFilter["value"],
      },
    },
    getRouteStartDateBadgeOption(
      filter as TaxiUnsettledRouteListingRouteStartDateFilter
    ),
  ];

  return options;
};

const getBadge = (filter: TaxiUnsettledRouteListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: TaxiUnsettledRouteListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const taxiUnsettledRouteListingFiltersBadgeListHelper = {
  getBadges,
};

export default taxiUnsettledRouteListingFiltersBadgeListHelper;
