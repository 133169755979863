import DelegationAddRequest, {
  DelegationAddRequestAddressSequenceNode,
  DelegationAddRequestTimezone,
  DelegationAddRequestVehicleType,
} from "../../../api/raily/delegation/add/delegation-add.request";
import DelegationAddParams, {
  DelegationAddParamsAddress,
  DelegationAddParamsTimezone,
  DelegationAddParamsVehicleType,
} from "./delegation-add-params";

const createAddress = (
  params: DelegationAddParamsAddress
): DelegationAddRequestAddressSequenceNode => {
  return {
    displayName: params.displayName,
    lat: params.latitude,
    lon: params.longitude,
    apartmentNumber: params.apartmentNumber,
    houseNumber: params.houseNumber,
    street: params.street,
    time: params.time,
    town: params.town,
    zipCode: params.zipCode,
    distance: params.distance,
  };
};

const createAddressGroup = (
  params: DelegationAddParamsAddress[]
): DelegationAddRequestAddressSequenceNode[] => {
  return params.map(createAddress);
};

const createAddressSequence = (
  params: DelegationAddParamsAddress[][]
): DelegationAddRequestAddressSequenceNode[][] => {
  return params.map(createAddressGroup);
};

const createVehicleType = (
  type: DelegationAddParamsVehicleType
): DelegationAddRequestVehicleType => {
  switch (type) {
    case DelegationAddParamsVehicleType.CAR_GT_900CM3:
      return DelegationAddRequestVehicleType.CAR_GT_900CM3;
    case DelegationAddParamsVehicleType.CAR_LE_900CM3:
      return DelegationAddRequestVehicleType.CAR_LE_900CM3;
    case DelegationAddParamsVehicleType.COMPANY_OWNED:
      return DelegationAddRequestVehicleType.COMPANY_OWNED;
    case DelegationAddParamsVehicleType.MOPED:
      return DelegationAddRequestVehicleType.MOPED;
    case DelegationAddParamsVehicleType.MOTORCYCLE:
      return DelegationAddRequestVehicleType.MOTORCYCLE;
  }
};

const createVehicleTypes = (
  types: DelegationAddParamsVehicleType[]
): DelegationAddRequestVehicleType[] => {
  return types.map(createVehicleType);
};

const createTimezone = (
  timezone: DelegationAddParamsTimezone | undefined
): DelegationAddRequestTimezone | undefined => {
  switch (timezone) {
    case DelegationAddParamsTimezone.EUROPE_WARSAW:
      return DelegationAddRequestTimezone.EUROPE_WARSAW;
    case DelegationAddParamsTimezone.UTC:
      return DelegationAddRequestTimezone.UTC;
    default:
      return DelegationAddRequestTimezone.EUROPE_WARSAW;
  }
};

const create = (params: DelegationAddParams): DelegationAddRequest => {
  return {
    workerCompanyUuid: params.workerCompanyUuid,
    workerUuid: params.workerUuid,
    dateFrom: params.dateFrom,
    dateTo: params.dateTo,
    mileagesCost: params.mileagesCost,
    totalDistance: params.totalDistance,
    addressSequence: createAddressSequence(params.addressSequence),
    groupVehicleTypes: createVehicleTypes(params.groupVehicleTypes),
    osrmDistance: params.osrmDistance,
    accommodationBillsCost: params.accommodationBillsCost,
    accommodationCost: params.accommodationCost,
    prepay: params.prepay,
    startedDayDietCost: params.startedDayDietCost,
    wholeDayDietCost: params.wholeDayDietCost,
    localTravelCost: params.localTravelCost,
    oneDayDietCost: params.oneDayDietCost,
    otherExpenses: params.otherExpenses,
    timezone: createTimezone(params.timezone),
  };
};

const delegationAddRequestFactory = {
  create,
};

export default delegationAddRequestFactory;
