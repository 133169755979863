import TaxiTaxiContractListingFilter, {
  TaxiTaxiContractListingActivityStatusFilter,
  TaxiTaxiContractListingBillingModelFilter,
  TaxiTaxiContractListingConsumerDisplayNameFilter,
  TaxiTaxiContractListingDisplayNameFilter,
  TaxiTaxiContractListingDistanceRateFilter,
  TaxiTaxiContractListingPeriodOfValidationFilter,
  TaxiTaxiContractListingPriorityFilter,
  TaxiTaxiContractListingProducerDisplayNameFilter,
} from "../common/types/taxi-taxi-contract-listing-filter";
import TaxiTaxiContractListingFilterType from "../common/types/taxi-taxi-contract-listing-filter-type";
import TaxiTaxiContractListingSortKey from "../common/types/taxi-taxi-contract-listing-sort-key";
import dateService from "../../../../../common/utils/date/date.service";
import DateRange from "../../../../../common/types/date-range";
import NumericRange from "../../../../../common/types/numeric-range";
import BillingModel from "../../../../../common/types/billing-model";
import { TaxiTaxiContractListingRouteQueryParams } from "../../../common/routes/types/taxi-taxi-contract-listing-route-params";

const getFilters = (
  routeQueryFilterParams: TaxiTaxiContractListingRouteQueryParams
): TaxiTaxiContractListingFilter[] => {
  const filters: TaxiTaxiContractListingFilter[] = [];

  if (routeQueryFilterParams.displayName) {
    const displayNameFilter: TaxiTaxiContractListingDisplayNameFilter = {
      type: TaxiTaxiContractListingFilterType.DISPLAY_NAME,
      value: routeQueryFilterParams.displayName,
    };
    filters.push(displayNameFilter);
  }

  if (routeQueryFilterParams.producerDisplayName) {
    const producerDisplayNameFilter: TaxiTaxiContractListingProducerDisplayNameFilter =
      {
        type: TaxiTaxiContractListingFilterType.PRODUCER_DISPLAY_NAME,
        value: routeQueryFilterParams.producerDisplayName,
      };
    filters.push(producerDisplayNameFilter);
  }

  if (routeQueryFilterParams.consumerDisplayName) {
    const consumerDisplayNameFilter: TaxiTaxiContractListingConsumerDisplayNameFilter =
      {
        type: TaxiTaxiContractListingFilterType.CONSUMER_DISPLAY_NAME,
        value: routeQueryFilterParams.consumerDisplayName,
      };
    filters.push(consumerDisplayNameFilter);
  }

  if (routeQueryFilterParams.billingModel) {
    const createBillingModel = (
      routeQueryFilterParamsModel: string
    ): BillingModel => {
      switch (routeQueryFilterParamsModel) {
        case "AB":
          return BillingModel.AB;
        case "ABA":
          return BillingModel.ABA;
        case "SABS":
          return BillingModel.SABS;
        case "OTHER":
          return BillingModel.OTHER;
      }

      return BillingModel.OTHER;
    };

    const billingModelFilter: TaxiTaxiContractListingBillingModelFilter = {
      type: TaxiTaxiContractListingFilterType.BILLING_MODEL,
      value: createBillingModel(routeQueryFilterParams.billingModel),
    };

    filters.push(billingModelFilter);
  }

  if (
    routeQueryFilterParams.startValidSince &&
    routeQueryFilterParams.endValidTo
  ) {
    const periodOfValidationFrom = dateService.createDate(
      routeQueryFilterParams.startValidSince
    );
    const periodOfValidationTo = dateService.createDate(
      routeQueryFilterParams.endValidTo
    );

    const periodOfValidationFilter: TaxiTaxiContractListingPeriodOfValidationFilter =
      {
        type: TaxiTaxiContractListingFilterType.PERIOD_OF_VALIDATION,
        value: {
          from: periodOfValidationFrom,
          to: periodOfValidationTo,
        },
      };
    filters.push(periodOfValidationFilter);
  }

  if (
    routeQueryFilterParams.startDistanceRate &&
    routeQueryFilterParams.endDistanceRate
  ) {
    const distanceRateRange: TaxiTaxiContractListingDistanceRateFilter = {
      type: TaxiTaxiContractListingFilterType.DISTANCE_RATE_RANGE,
      value: {
        from: Number(routeQueryFilterParams.startDistanceRate),
        to: Number(routeQueryFilterParams.endDistanceRate),
      },
    };
    filters.push(distanceRateRange);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: TaxiTaxiContractListingRouteQueryParams
): TaxiTaxiContractListingSortKey | undefined => {
  return routeQueryParams.sort as TaxiTaxiContractListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: TaxiTaxiContractListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: TaxiTaxiContractListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: TaxiTaxiContractListingFilter[],
  sortKey: TaxiTaxiContractListingSortKey | null,
  page: number,
  pageSize: number,
  taxiCorporationUuid: string
): TaxiTaxiContractListingRouteQueryParams => {
  const activityStatus = filters.find(
    (filter) => filter.type === TaxiTaxiContractListingFilterType.ACTIVE_STATUS
  )?.value as TaxiTaxiContractListingActivityStatusFilter["value"];

  const periodOfValidation: DateRange = filters.find(
    (filter) =>
      filter.type === TaxiTaxiContractListingFilterType.PERIOD_OF_VALIDATION
  )?.value as TaxiTaxiContractListingPeriodOfValidationFilter["value"];

  const distanceRateRange: NumericRange = filters.find(
    (filter) =>
      filter.type === TaxiTaxiContractListingFilterType.DISTANCE_RATE_RANGE
  )?.value as TaxiTaxiContractListingDistanceRateFilter["value"];

  const billingModelFilterValue: BillingModel = filters.find(
    (filter) => filter.type === TaxiTaxiContractListingFilterType.BILLING_MODEL
  )?.value as TaxiTaxiContractListingBillingModelFilter["value"];

  const priorityValue: number = filters.find(
    (filter) => filter.type === TaxiTaxiContractListingFilterType.PRIORITY
  )?.value as TaxiTaxiContractListingPriorityFilter["value"];

  const routeQueryParams: TaxiTaxiContractListingRouteQueryParams = {
    priority: priorityValue?.toString(),
    displayName: filters.find(
      (filter) => filter.type === TaxiTaxiContractListingFilterType.DISPLAY_NAME
    )?.value as TaxiTaxiContractListingDisplayNameFilter["value"],
    producerDisplayName: filters.find(
      (filter) =>
        filter.type === TaxiTaxiContractListingFilterType.PRODUCER_DISPLAY_NAME
    )?.value as TaxiTaxiContractListingProducerDisplayNameFilter["value"],
    consumerDisplayName: filters.find(
      (filter) =>
        filter.type === TaxiTaxiContractListingFilterType.CONSUMER_DISPLAY_NAME
    )?.value as TaxiTaxiContractListingConsumerDisplayNameFilter["value"],
    billingModel: billingModelFilterValue,
    startValidSince: periodOfValidation?.from.toJSON(),
    endValidTo: periodOfValidation?.to.toJSON(),
    startDistanceRate: distanceRateRange?.from.toString(),
    endDistanceRate: distanceRateRange?.to.toString(),
    disabled: activityStatus === undefined ? undefined : String(activityStatus),
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
    producerTaxiId: taxiCorporationUuid,
  };

  return routeQueryParams;
};

const taxiTaxiContractListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiTaxiContractListingFiltersService;
