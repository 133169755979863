import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../../common/components/listing/filter/types/listing-sort-definition";
import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import CargoCompanyListingFilter, {
  CargoCompanyListingAddressFilter,
  CargoCompanyListingCompanyIdFilter,
  CargoCompanyListingCompanyNameFilter,
  CargoCompanyListingCompanyDisplayNameFilter,
  CargoCompanyListingNationalCourtRegisterFilter,
  CargoCompanyListingNotesFilter,
  CargoCompanyListingTaxNumberFilter,
} from "../common/types/cargo-company-listing-filter";
import CargoCompanyListingFilterType from "../common/types/cargo-company-listing-filter-type";
import CargoCompanyListingSortKey from "../common/types/cargo-company-listing-sort-key";

const cargoCompanyListingFilterHelper = () => {
  const translations = cargoTranslationsHelper.getCompanyListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForTaxNumber = (
    filterValue: CargoCompanyListingTaxNumberFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.taxNumberLabel,
      translations.filters.taxNumberTitle
    );
  };

  const getBadgeForCompanyId = (
    filterValue: CargoCompanyListingCompanyIdFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.companyIdLabel,
      translations.filters.companyIdTitle
    );
  };

  const getBadgeForNationalCourtRegister = (
    filterValue: CargoCompanyListingNationalCourtRegisterFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.nationalCourtRegisterLabel,
      translations.filters.nationalCourtRegisterTitle
    );
  };

  const getBadgeForCompanyName = (
    filterValue: CargoCompanyListingCompanyNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.companyNameLabel,
      translations.filters.companyNameTitle
    );
  };

  const getBadgeForDisplayName = (
    filterValue: CargoCompanyListingCompanyDisplayNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.companyDisplayNameLabel,
      translations.filters.companyDisplayNameTitle
    );
  };

  const getBadgeForAddress = (
    filterValue: CargoCompanyListingAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.addressLabel,
      translations.filters.addressTitle
    );
  };

  const getBadgeForNotes = (
    filterValue: CargoCompanyListingNotesFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.notesLabel,
      translations.filters.notesTitle
    );
  };

  const getSelectDataForTaxNumber = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.taxNumberTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForCompanyId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.companyIdTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForNationalCourtRegister = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.nationalCourtRegisterTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForCompanyName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.companyNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForDisplayName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.companyDisplayNameTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.addressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForNotes = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.notesTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: CargoCompanyListingFilterType,
    query: string
  ) => {
    const options: Record<
      CargoCompanyListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [CargoCompanyListingFilterType.TAX_NUMBER]: getSelectDataForTaxNumber,
      [CargoCompanyListingFilterType.COMPANY_ID]: getSelectDataForCompanyId,
      [CargoCompanyListingFilterType.NATIONAL_COURT_REGISTER]:
        getSelectDataForNationalCourtRegister,
      [CargoCompanyListingFilterType.COMPANY_NAME]: getSelectDataForCompanyName,
      [CargoCompanyListingFilterType.COMPANY_DISPLAY_NAME]:
        getSelectDataForDisplayName,
      [CargoCompanyListingFilterType.ADDRESS]: getSelectDataForAddress,
      [CargoCompanyListingFilterType.NOTES]: getSelectDataForNotes,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeDefinitionByType = (
    filterType: CargoCompanyListingFilterType,
    filterValue: CargoCompanyListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      CargoCompanyListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [CargoCompanyListingFilterType.TAX_NUMBER]: () =>
        getBadgeForTaxNumber(
          filterValue as CargoCompanyListingTaxNumberFilter["value"]
        ),
      [CargoCompanyListingFilterType.COMPANY_ID]: () =>
        getBadgeForCompanyId(
          filterValue as CargoCompanyListingCompanyIdFilter["value"]
        ),
      [CargoCompanyListingFilterType.NATIONAL_COURT_REGISTER]: () =>
        getBadgeForNationalCourtRegister(
          filterValue as CargoCompanyListingNationalCourtRegisterFilter["value"]
        ),
      [CargoCompanyListingFilterType.COMPANY_NAME]: () =>
        getBadgeForCompanyName(
          filterValue as CargoCompanyListingCompanyNameFilter["value"]
        ),
      [CargoCompanyListingFilterType.COMPANY_DISPLAY_NAME]: () =>
        getBadgeForDisplayName(
          filterValue as CargoCompanyListingCompanyDisplayNameFilter["value"]
        ),
      [CargoCompanyListingFilterType.ADDRESS]: () =>
        getBadgeForAddress(
          filterValue as CargoCompanyListingAddressFilter["value"]
        ),
      [CargoCompanyListingFilterType.NOTES]: () =>
        getBadgeForNotes(
          filterValue as CargoCompanyListingNotesFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinition =
    (): ListingFilterDefinition<CargoCompanyListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getFilterDefinitionByType = (
    filterType: CargoCompanyListingFilterType
  ): ListingFilterDefinitionOption<CargoCompanyListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<CargoCompanyListingFilter>[] => {
      return Object.values(CargoCompanyListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.companyNameAscLabel,
        value: CargoCompanyListingSortKey.COMPANY_NAME_ASC,
      },
      {
        label: translations.sort.companyNameDescLabel,
        value: CargoCompanyListingSortKey.COMPANY_NAME_DESC,
      },
      {
        label: translations.sort.companyDisplayNameAscLabel,
        value: CargoCompanyListingSortKey.COMPANY_DISPLAY_NAME_ASC,
      },
      {
        label: translations.sort.companyDisplayNameDescLabel,
        value: CargoCompanyListingSortKey.COMPANY_DISPLAY_NAME_DESC,
      },
    ];
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default cargoCompanyListingFilterHelper;
