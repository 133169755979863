import CargoCompanyDispatchDetailsRequest from "../../../../api/raily/cargo-company/dispatch/details/cargo-company-dispatch-details.request";
import CargoCompanyDispatchDetailsLoadParams from "./cargo-company-dispatch-details-load-params";

const create = (
  params: CargoCompanyDispatchDetailsLoadParams
): CargoCompanyDispatchDetailsRequest => {
  return {
    cargoCompanyId: params.cargoCompanyUuid,
    dispatchId: params.dispatchUuid,
  };
};

const cargoCompanyDispatchDetailsRequestFactory = {
  create,
};

export default cargoCompanyDispatchDetailsRequestFactory;
