import TaxiFleetPartnerAddRequest from "../../api/raily/taxi-fleet-partner/add/taxi-fleet-partner-add.request";
import TaxiFleetPartnerDetailsRequest from "../../api/raily/taxi-fleet-partner/details/taxi-fleet-partner-details.request";
import taxiFleetPartnerApiService from "../../api/raily/taxi-fleet-partner/taxi-fleet-partner-api.service";
import TaxiFleetPartnerUpdateRequest from "../../api/raily/taxi-fleet-partner/update/taxi-fleet-partner-update.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import TaxiFleetPartnerAddError from "./add/taxi-fleet-partner-add-error";
import TaxiFleetPartnerAddParams from "./add/taxi-fleet-partner-add-params";
import taxiFleetPartnerAddRequestFactory from "./add/taxi-fleet-partner-add-request.factory";
import TaxiFleetPartnerDetails from "./details/taxi-fleet-partner-details";
import TaxiFleetPartnerDetailsError from "./details/taxi-fleet-partner-details-error";
import TaxiFleetPartnerDetailsLoadParams from "./details/taxi-fleet-partner-details-load-params";
import taxiFleetPartnerDetailsRequestFactory from "./details/taxi-fleet-partner-details-request.factory";
import taxiFleetPartnerDetailsFactory from "./details/taxi-fleet-partner-details.factory";
import TaxiFleetPartnerList from "./list/taxi-fleet-partner-list";
import TaxiFleetPartnerListError from "./list/taxi-fleet-partner-list-error";
import TaxiFleetPartnerListLoadParams from "./list/taxi-fleet-partner-list-load-params";
import taxiFleetPartnerListFactory from "./list/taxi-fleet-partner-list.factory";
import TaxiFleetPartnerUpdateError from "./update/taxi-fleet-partner-update-error";
import TaxiFleetPartnerUpdateParams from "./update/taxi-fleet-partner-update-params";
import taxiFleetPartnerUpdateRequestFactory from "./update/taxi-fleet-partner-update-request.factory";

const handleAddError = (
  error: HttpError | TaxiFleetPartnerAddError
): TaxiFleetPartnerAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: TaxiFleetPartnerAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TaxiFleetPartnerAddRequest =
    taxiFleetPartnerAddRequestFactory.create(params);

  try {
    const response = await taxiFleetPartnerApiService().add(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TaxiFleetPartnerAddError;

    throw handleAddError(error);
  }
};

const handleListError = (
  error: HttpError | TaxiFleetPartnerListError
): TaxiFleetPartnerListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: TaxiFleetPartnerListLoadParams,
  abortSignal: AbortSignal
): Promise<TaxiFleetPartnerList> => {
  try {
    const response = await taxiFleetPartnerApiService().getList(
      params,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiFleetPartnerList = taxiFleetPartnerListFactory.create(response);
    return taxiFleetPartnerList;
  } catch (_error) {
    const error = _error as HttpError | TaxiFleetPartnerListError;

    throw handleListError(error);
  }
};

const handleUpdateError = (
  error: HttpError | TaxiFleetPartnerUpdateError
): TaxiFleetPartnerUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const update = async (
  params: TaxiFleetPartnerUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: TaxiFleetPartnerUpdateRequest =
    taxiFleetPartnerUpdateRequestFactory.create(params);

  try {
    const response = await taxiFleetPartnerApiService().update(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | TaxiFleetPartnerUpdateError;

    throw handleUpdateError(error);
  }
};

const handleDetailsError = (
  error: HttpError | TaxiFleetPartnerDetailsError
): TaxiFleetPartnerDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: TaxiFleetPartnerDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<TaxiFleetPartnerDetails> => {
  const request: TaxiFleetPartnerDetailsRequest =
    taxiFleetPartnerDetailsRequestFactory.create(params);

  try {
    const response = await taxiFleetPartnerApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const taxiFleetPartnerDetails =
      taxiFleetPartnerDetailsFactory.create(response);
    return taxiFleetPartnerDetails;
  } catch (_error) {
    const error = _error as HttpError | TaxiFleetPartnerDetailsError;

    throw handleDetailsError(error);
  }
};

const taxiFleetPartnerService = {
  add,
  getList,
  update,
  getDetails,
};

export default taxiFleetPartnerService;
