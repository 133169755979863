import PassengerAddress from "../../../common/types/passenger-address";
import PassengerDispatchSelectOption from "../../../common/types/passenger-dispatch-select-option";
import PassengerEditFormData from "../types/passenger-edit-form-data";
import PassengerEditUpdateRequest, {
  PassengerEditUpdateRequestAddress,
} from "../api/types/passenger-edit-update.request";
import {
  PassengerEditInitResponseData,
  PassengerEditInitResponseDataAddress,
  PassengerEditInitResponseDataDispatch,
} from "../api/types/passenger-edit-init-data.response";

const createPassengerAddress = (
  passengerRequestAddress: PassengerEditInitResponseDataAddress
): PassengerAddress => {
  return {
    latitude: passengerRequestAddress.lat,
    longitude: passengerRequestAddress.lon,
    country: passengerRequestAddress.country,
    town: passengerRequestAddress.town,
    zipCode: passengerRequestAddress.zip_code,
    street: passengerRequestAddress.street,
    houseNumber: passengerRequestAddress.house_no,
    apartmentNumber: passengerRequestAddress.apartment,
    description: passengerRequestAddress.description,
  };
};

const createEditPassengerRequestAddress = (
  formDataAddress: PassengerAddress
): PassengerEditUpdateRequestAddress => {
  return {
    lat: formDataAddress.latitude,
    lon: formDataAddress.longitude,
    country: formDataAddress.country?.trim() || null,
    town: formDataAddress.town?.trim() || null,
    street: formDataAddress.street?.trim() || null,
    zip_code: formDataAddress.zipCode?.trim() || null,
    house_no: formDataAddress.houseNumber?.trim() || null,
    apartment: formDataAddress.apartmentNumber?.trim() || null,
    description: formDataAddress.description?.trim() || null,
  };
};

const createEditPassengerRequestAddresses = (
  formDataAddresses: PassengerAddress[]
): PassengerEditUpdateRequestAddress[] => {
  return formDataAddresses.map((address: PassengerAddress) => {
    return createEditPassengerRequestAddress(address);
  });
};

const createEditPassengerRequestDispatchIds = (
  formDataDispatches: PassengerDispatchSelectOption[]
): string[] => {
  return formDataDispatches.map((dispatch) => {
    return dispatch.value.uuid;
  });
};

const createEditPassengerRequest = (formData: PassengerEditFormData) => {
  const addresses = createEditPassengerRequestAddresses(formData.addresses);
  const dispatchIds = createEditPassengerRequestDispatchIds(
    formData.dispatches
  );

  const request: PassengerEditUpdateRequest = {
    email: formData.email?.trim() || null,
    first_name: formData.firstName?.trim() || null,
    last_name: formData.lastName?.trim() || null,
    mobile_primary: formData.mobile.number?.trim() || null,
    mobile_primary_prefix: formData.mobile.dialingCode || null,
    mobile_secondary: formData.alternativeMobile.number?.trim() || null,
    mobile_secondary_prefix: formData.alternativeMobile.dialingCode || null,
    passenger: {
      addresses: addresses,
      dispatch_ids: dispatchIds,
    },
  };

  return request;
};

const createDispatchSelectOption = (
  responseDispatch: PassengerEditInitResponseDataDispatch
): PassengerDispatchSelectOption => {
  return {
    label: responseDispatch.display_name,
    value: {
      uuid: responseDispatch.id,
      displayName: responseDispatch.display_name,
      addressString: responseDispatch.address_string,
    },
  };
};

const createDispatchSelectOptions = (
  responseDispatches: PassengerEditInitResponseDataDispatch[]
): PassengerDispatchSelectOption[] => {
  return responseDispatches.map(createDispatchSelectOption);
};

const createFormData = (
  responseData: PassengerEditInitResponseData
): PassengerEditFormData => {
  return {
    email: responseData.email,
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    mobile: {
      dialingCode: responseData.mobile_primary_prefix,
      number: responseData.mobile_primary,
    },
    alternativeMobile: {
      dialingCode: responseData.mobile_secondary_prefix,
      number: responseData.mobile_secondary,
    },
    addresses: responseData.passenger.addresses.map((responseDataAddress) =>
      createPassengerAddress(responseDataAddress)
    ),
    dispatches: responseData.passenger.dispatches.map((dispatch) =>
      createDispatchSelectOption(dispatch)
    ),
  };
};

const passengerEditFactory = {
  createEditPassengerRequest,
  createFormData,
  createDispatchSelectOptions,
};

export default passengerEditFactory;
