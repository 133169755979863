import {
  CargoOrderDetailsComplexResponseItem,
  CargoOrderDetailsComplexResponseItemPublicStatus,
  CargoOrderDetailsComplexResponseItemStatus,
  CargoOrderDetailsComplexResponseItemTransportingOrder,
  CargoOrderDetailsComplexResponseItemTransportingOrderDriver,
  CargoOrderDetailsComplexResponseItemTransportingOrderPassenger,
  CargoOrderDetailsComplexResponseItemSolveWaypoint,
  CargoOrderDetailsComplexResponseItemPassengerType,
  CargoOrderDetailsComplexResponseItemTransportingOrderInternalPassenger,
  CargoOrderDetailsComplexResponseItemTransportingOrderExternalPassenger,
  CargoOrderDetailsComplexResopnseItemSolve,
} from "../../../api/raily/cargo-order/details-complex/cargo-order-details-complex.response";
import CargoOrderDetailsComplex, {
  CargoOrderDetailsComplexExecutionStatus,
  CargoOrderDetailsComplexPublicStatus,
  CargoOrderDetailsComplexTransportingOrder,
  CargoOrderDetailsComplexTransportingOrderDriver,
  CargoOrderDetailsComplexTransportingOrderPassengerType,
  CargoOrderDetailsComplexTransportingOrderPassenger,
  CargoOrderDetailsComplexTransportingOrderWaypointItem,
  CargoOrderDetailsComplexTransportingOrderInternalPassenger,
  CargoOrderDetailsComplexTransportingOrderExternalPassenger,
  CargoOrderDetailsComplexTransportingOrderWaypoint,
} from "./cargo-order-details-complex";
CargoOrderDetailsComplexResponseItemPublicStatus;

const createPublicStatus = (
  responsePublicStatus: CargoOrderDetailsComplexResponseItemPublicStatus
): CargoOrderDetailsComplexPublicStatus => {
  switch (responsePublicStatus) {
    case CargoOrderDetailsComplexResponseItemPublicStatus.PRIVATE:
      return CargoOrderDetailsComplexPublicStatus.PRIVATE;
    case CargoOrderDetailsComplexResponseItemPublicStatus.PUBLIC:
      return CargoOrderDetailsComplexPublicStatus.PUBLIC;
    case CargoOrderDetailsComplexResponseItemPublicStatus.PUBLISHED:
      return CargoOrderDetailsComplexPublicStatus.PUBLISHED;
  }
};

const createExecutionStatus = (
  responseStatus: CargoOrderDetailsComplexResponseItemStatus
): CargoOrderDetailsComplexExecutionStatus => {
  switch (responseStatus) {
    case CargoOrderDetailsComplexResponseItemStatus.CANDIDATURE:
      return CargoOrderDetailsComplexExecutionStatus.CANDIDATURE;
    case CargoOrderDetailsComplexResponseItemStatus.IN_PROGRESS:
      return CargoOrderDetailsComplexExecutionStatus.IN_PROGRESS;
    case CargoOrderDetailsComplexResponseItemStatus.PENDING:
      return CargoOrderDetailsComplexExecutionStatus.PENDING;
    case CargoOrderDetailsComplexResponseItemStatus.PLANNED:
      return CargoOrderDetailsComplexExecutionStatus.PLANNED;
    case CargoOrderDetailsComplexResponseItemStatus.FINISHED:
      return CargoOrderDetailsComplexExecutionStatus.FINISHED;
  }
};

const createAlternativePhoneNumber = (
  responseItem: CargoOrderDetailsComplexResponseItemTransportingOrderDriver
): CargoOrderDetailsComplexTransportingOrderDriver["alternativePhoneNumber"] => {
  if (!responseItem.mobileSecondary || !responseItem.mobileSecondaryPrefix)
    return null;

  return {
    dialingCode: responseItem.mobileSecondaryPrefix,
    number: responseItem.mobileSecondary,
  };
};

const createTransportingOrderDriver = (
  driverItem: CargoOrderDetailsComplexResponseItemTransportingOrderDriver
): CargoOrderDetailsComplexTransportingOrderDriver => {
  return {
    removalDate: driverItem.removedAt,
    uuid: driverItem.id,
    modificationDate: driverItem.modifiedAt,
    modifiedBy: driverItem.modifiedBy,
    creationDate: driverItem.createdAt,
    createdBy: driverItem.createdBy,
    firstName: driverItem.firstName,
    lastName: driverItem.lastName,
    username: driverItem.username,
    phoneNumber: {
      dialingCode: driverItem.mobilePrimaryPrefix,
      number: driverItem.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(driverItem),
    email: driverItem.email,
  };
};

const createTransportingOrderInternalPassenger = (
  passengerItem: CargoOrderDetailsComplexResponseItemTransportingOrderInternalPassenger
): CargoOrderDetailsComplexTransportingOrderInternalPassenger => {
  return {
    type: CargoOrderDetailsComplexTransportingOrderPassengerType.INTERNAL,
    firstName: passengerItem.firstName,
    lastName: passengerItem.lastName,
    phoneNumber: {
      dialingCode: passengerItem.phonePrefix,
      number: passengerItem.phoneNo,
    },
    uuid: passengerItem.passengerId,
  };
};

const createTransportingOrderExternalPassenger = (
  passengerItem: CargoOrderDetailsComplexResponseItemTransportingOrderExternalPassenger
): CargoOrderDetailsComplexTransportingOrderExternalPassenger => {
  return {
    type: CargoOrderDetailsComplexTransportingOrderPassengerType.EXTERNAL,
    firstName: passengerItem.firstName,
    lastName: passengerItem.lastName,
    phoneNumber: {
      dialingCode: passengerItem.phonePrefix,
      number: passengerItem.phoneNo,
    },
  };
};

const createTransportingOrderPassenger = (
  passengerItem: CargoOrderDetailsComplexResponseItemTransportingOrderPassenger
): CargoOrderDetailsComplexTransportingOrderPassenger => {
  if (
    passengerItem.itemType ===
    CargoOrderDetailsComplexResponseItemPassengerType.INTERNAL
  )
    return createTransportingOrderInternalPassenger(passengerItem);

  return createTransportingOrderExternalPassenger(passengerItem);
};

const createTransportingOrderPassengers = (
  passengerItem: CargoOrderDetailsComplexResponseItemTransportingOrderPassenger[]
): CargoOrderDetailsComplexTransportingOrderPassenger[] =>
  passengerItem.map(createTransportingOrderPassenger);

const createTransportingOrderWaypoint = (
  waypointItem: CargoOrderDetailsComplexResponseItemSolveWaypoint
): CargoOrderDetailsComplexTransportingOrderWaypointItem => {
  return {
    uuid: waypointItem.nodeId,
    latitude: waypointItem.lat,
    longitude: waypointItem.lon,
    orderedDate: waypointItem.requestedTime,
    orderedStopoverTime: waypointItem.requestedHaltingTime,
    displayName: waypointItem.displayName,
    boardingPassengersUuids: waypointItem.cargoEnter,
    disembarkingPassengerUuids: waypointItem.cargoExit,
    estimatedDate: waypointItem.estimatedTime,
    arrivalDate: waypointItem.checkoutEvent?.achievedAt!,
    stopoverTime: waypointItem.checkoutEvent?.haltingTime!,
    distance: waypointItem.distance,
  };
};

const checkIsSingleWaypoint = (
  item: CargoOrderDetailsComplexResopnseItemSolve
): item is CargoOrderDetailsComplexResponseItemSolveWaypoint =>
  !!(item as CargoOrderDetailsComplexResponseItemSolveWaypoint).nodeId;

const createTransportingOrderWaypoints = (
  transportingOrderItemSolve: CargoOrderDetailsComplexResopnseItemSolve[]
): CargoOrderDetailsComplexTransportingOrderWaypoint[] =>
  transportingOrderItemSolve.map((itemSolve) => {
    const isSingleWaypoint = checkIsSingleWaypoint(itemSolve);
    if (isSingleWaypoint) return createTransportingOrderWaypoint(itemSolve);

    return itemSolve.map(createTransportingOrderWaypoint);
  });

const createTransportingOrderSolvedWaypoints = (
  transportingOrderItemSolve: CargoOrderDetailsComplexResponseItemSolveWaypoint[]
): CargoOrderDetailsComplexTransportingOrderWaypointItem[] => {
  return transportingOrderItemSolve.map(createTransportingOrderWaypoint);
};

const createTransportingOrder = (
  transportingOrder: CargoOrderDetailsComplexResponseItemTransportingOrder
): CargoOrderDetailsComplexTransportingOrder => {
  return {
    uuid: transportingOrder.id,
    driver: transportingOrder.driver
      ? createTransportingOrderDriver(transportingOrder.driver)
      : null,
    passengers: createTransportingOrderPassengers(transportingOrder.passengers),
    waypoints: createTransportingOrderWaypoints(transportingOrder.solve),
    solvedWaypoints: transportingOrder.driverSolve
      ? createTransportingOrderSolvedWaypoints(transportingOrder.driverSolve)
      : [],
  };
};

const createTransportingOrderDetails = (
  transportingOrder: CargoOrderDetailsComplexResponseItemTransportingOrder[]
): CargoOrderDetailsComplexTransportingOrder[] =>
  transportingOrder.map(createTransportingOrder);

const create = (
  responseItem: CargoOrderDetailsComplexResponseItem
): CargoOrderDetailsComplex => {
  return {
    uuid: responseItem.id,
    targetTaxiUuid: responseItem.targetTaxiId,
    externalOrderId: responseItem.externalId,
    internalOrderId: responseItem.humanId,
    isCancelled: responseItem.isCancelled,
    isCancelRequested: responseItem.isCancelRequested,
    publicStatus: createPublicStatus(responseItem.orderType),
    executionStatus: createExecutionStatus(responseItem.status),
    tollRoadsPermissions: {
      isOnApproachAllowed: responseItem.nodeExclusions.excludeApproaching,
      isOnReturningAllowed: responseItem.nodeExclusions.excludeReturning,
      isDuringRideAllowed: responseItem.nodeExclusions.excludeInside,
    },
    dispatcherUuid: responseItem.dispatcher.dispatchId,
    dispatchUuid: responseItem.dispatcher.dispatch?.id!,
    transportingOrders: createTransportingOrderDetails(
      responseItem.transportingOrders
    ),
  };
};

const cargoOrderDetailsComplexFactory = { create };

export default cargoOrderDetailsComplexFactory;
