import SignUpCargoOfficerTokenPayload from "../../common/types/sign-up-cargo-officer-token-payload";
import SignUpCargoOfficerFormData from "./types/sign-up-cargo-officer-form-data";
import SignUpCargoOfficerRequest from "./api/sign-up-cargo-officer.request";
import SignUpCargoOfficerDisabledFormFields from "./types/sign-up-cargo-officer-disabled-form-fields";

const createSignUpRequest = (
  formData: SignUpCargoOfficerFormData,
  cargoCompanyId: string
): SignUpCargoOfficerRequest => ({
  first_name: formData.firstName.trim(),
  last_name: formData.lastName.trim(),
  mobile_primary: formData.mobile.number!,
  mobile_primary_prefix: formData.mobile.dialingCode!,
  mobile_secondary: formData.alternativeMobile.number,
  mobile_secondary_prefix: formData.alternativeMobile.dialingCode,
  email: formData.email.trim(),
  username: formData.username.trim(),
  password: formData.password,
  cargo_officer: {
    cargo_company_id: cargoCompanyId,
  },
});

const createFormData = (
  tokenPayload: SignUpCargoOfficerTokenPayload
): SignUpCargoOfficerFormData => {
  return {
    email: tokenPayload.user_data.email ?? "",
    password: "",
    passwordRepeat: "",
    username: tokenPayload.user_data.username ?? "",
    firstName: tokenPayload.user_data.first_name ?? "",
    lastName: tokenPayload.user_data.last_name ?? "",
    mobile: {
      dialingCode: tokenPayload.user_data.mobile_primary_prefix ?? "",
      number: tokenPayload.user_data.mobile_primary ?? "",
    },
    alternativeMobile: {
      dialingCode: tokenPayload.user_data.mobile_secondary_prefix ?? "",
      number: tokenPayload.user_data.mobile_secondary ?? "",
    },
  };
};

const createDisabledFormFieldsConfig = (
  tokenPayload: SignUpCargoOfficerTokenPayload
): SignUpCargoOfficerDisabledFormFields => {
  return {
    email: !!tokenPayload.user_data.email,
    firstName: !!tokenPayload.user_data.first_name,
    lastName: !!tokenPayload.user_data.last_name,
    mobile:
      !!tokenPayload.user_data.mobile_primary &&
      !!tokenPayload.user_data.mobile_primary_prefix,
    alternativeMobile:
      !!tokenPayload.user_data.mobile_secondary &&
      !!tokenPayload.user_data.mobile_secondary_prefix,
    username: !!tokenPayload.user_data.username,
  };
};

const signUpCargoOfficerFactory = {
  createSignUpRequest,
  createFormData,
  createDisabledFormFieldsConfig,
};

export default signUpCargoOfficerFactory;
