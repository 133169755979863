import useAsyncData from "../../../hooks/use-async-data";
import cargoOrderService from "../cargo-order.service";
import CargoOrderFinishedList from "./cargo-order-finished-list";
import CargoOrderFinishedListLoadParams from "./cargo-order-finished-list-load-params";

const useCargoOrderFinishedList = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<CargoOrderFinishedList>({
      data: [],
      totalCount: 0,
    });

  const load = async (
    params: CargoOrderFinishedListLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoOrderFinishedList = await cargoOrderService.getFinishedList(
        params,
        signal
      );

      setData(cargoOrderFinishedList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useCargoOrderFinishedList;
