import cargoTranslationsHelper from "../../../../../../languages/cargo-translations.helper";
import CargoTaxiContractListingSortKey from "../types/cargo-taxi-contract-listing-sort-key";
import CargoTaxiContractListingSortSelectOption from "../types/cargo-taxi-contract-listing-sort-select-option";

const getSelectOptions = (): CargoTaxiContractListingSortSelectOption[] => {
  const translations =
    cargoTranslationsHelper.getTaxiContractListingTranslations().sort;

  return [
    {
      label: translations.billingModelAscOptionLabel,
      value: CargoTaxiContractListingSortKey.BILLING_MODEL_ASC,
    },
    {
      label: translations.billingModelDescOptionLabel,
      value: CargoTaxiContractListingSortKey.BILLING_MODEL_DESC,
    },
    {
      label: translations.distanceRateAscOptionLabel,
      value: CargoTaxiContractListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.distanceRateDescOptionLabel,
      value: CargoTaxiContractListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      label: translations.discountPercentLimitAscOptionLabel,
      value: CargoTaxiContractListingSortKey.DISCOUNT_PERCENT_LIMIT_ASC,
    },
    {
      label: translations.discountPercentLimitDescOptionLabel,
      value: CargoTaxiContractListingSortKey.DISCOUNT_PERCENT_LIMIT_DESC,
    },
    {
      label: translations.freeStoppingPeriodAscOptionLabel,
      value: CargoTaxiContractListingSortKey.FREE_STOPPING_PERIOD_ASC,
    },
    {
      label: translations.freeStoppingPeriodDescOptionLabel,
      value: CargoTaxiContractListingSortKey.FREE_STOPPING_PERIOD_DESC,
    },
    {
      label: translations.stopRateAscOptionLabel,
      value: CargoTaxiContractListingSortKey.STOPPING_RATE_ASC,
    },
    {
      label: translations.stopRateDescOptionLabel,
      value: CargoTaxiContractListingSortKey.STOPPING_RATE_DESC,
    },
    {
      label: translations.periodOfValidityFromAscOptionLabel,
      value: CargoTaxiContractListingSortKey.PERIOD_OF_VALIDITY_FROM_ASC,
    },
    {
      label: translations.periodOfValidityFromDescOptionLabel,
      value: CargoTaxiContractListingSortKey.PERIOD_OF_VALIDITY_FROM_DESC,
    },
    {
      label: translations.periodOfValidityToAscOptionLabel,
      value: CargoTaxiContractListingSortKey.PERIOD_OF_VALIDITY_TO_ASC,
    },
    {
      label: translations.periodOfValidityToDescOptionLabel,
      value: CargoTaxiContractListingSortKey.PERIOD_OF_VALIDITY_TO_DESC,
    },
    {
      label: translations.taxiCorporationAscOptionLabel,
      value: CargoTaxiContractListingSortKey.TAXI_CORPORATION_ASC,
    },
    {
      label: translations.taxiCorporationDescOptionLabel,
      value: CargoTaxiContractListingSortKey.TAXI_CORPORATION_DESC,
    },
  ];
};

const cargoTaxiContractListingSortHelper = { getSelectOptions };

export default cargoTaxiContractListingSortHelper;
