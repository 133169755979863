import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import taxiOrderSettlementListingUserPermissionDefinition, {
  TaxiOrderSettlementListingUserPermissions,
} from "./taxi-order-settlement-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): TaxiOrderSettlementListingUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    taxiOrderSettlementListingUserPermissionDefinition
  );
};

const taxiOrderSettlementListingUserPermissionsHelper = {
  getPermissions,
};

export default taxiOrderSettlementListingUserPermissionsHelper;
