import { FC, useEffect, useRef } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import FormComponent from "../../../../../common/components/form/form.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import formValidationHelper from "../../../../../common/utils/validation/form-validation.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import { useSignUpDriverContext } from "../common/context/sign-up-driver.context";

type SignUpDriverAccountProps = {};

const SignUpDriverAccountComponent: FC<SignUpDriverAccountProps> = () => {
  const accountDataFormTranslations =
    appTranslationsHelper.getDriverSignUpTranslations().accountDataForm;

  const {
    accountData,
    setAccountDataEmail,
    setAccountDataUsername,
    setAccountDataPassword,
    setAccountDataRepeatPassword,
    accountDataValidationResults,
    validateAccountDataEmail,
    validateAccountDataUsername,
    validateAccountDataPassword,
    validateAccountDataPasswordRepeat,
    submitAccountStep,
    isAccountDataEmailDisabled,
    isAccountDataUsernameDisabled,
  } = useSignUpDriverContext();

  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailInputRef.current && !accountData.email) {
      emailInputRef.current.focus();
    }
  }, [emailInputRef.current]);

  const isSubmitButtonEnabled = formValidationHelper.checkThatFormHasErrors(
    accountDataValidationResults
  );

  return (
    <div className="sign_up_driver__account">
      <div className="sign_up_driver__account_content">
        <h1 className="sign_up_driver__step_title">
          {accountDataFormTranslations.title}
        </h1>
        <CardComponent>
          <FormComponent onSubmit={submitAccountStep}>
            <FormFieldComponent
              label={accountDataFormTranslations.emailLabel}
              isRequired
              classNames={{ root: "mt-0" }}
              errorMessage={accountDataValidationResults.email.errorMessage}
            >
              <InputComponent
                placeholder={accountDataFormTranslations.emailPlaceholder}
                value={accountData.email}
                onChange={setAccountDataEmail}
                onBlur={validateAccountDataEmail}
                hasError={!!accountDataValidationResults.email.errorMessage}
                inputRef={emailInputRef}
                isDisabled={isAccountDataEmailDisabled}
                idForTesting={`account-data-email-input`}
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={accountDataFormTranslations.usernameLabel}
              isRequired
              classNames={{ root: "mt-0" }}
              errorMessage={accountDataValidationResults.username.errorMessage}
            >
              <InputComponent
                placeholder={accountDataFormTranslations.usernamePlaceholder}
                value={accountData.username}
                onChange={setAccountDataUsername}
                onBlur={validateAccountDataUsername}
                hasError={!!accountDataValidationResults.username.errorMessage}
                isDisabled={isAccountDataUsernameDisabled}
                idForTesting={`account-data-username-input`}
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={accountDataFormTranslations.passwordLabel}
              isRequired
              errorMessage={accountDataValidationResults.password.errorMessage}
            >
              <InputComponent
                placeholder={accountDataFormTranslations.passwordPlaceholder}
                value={accountData.password}
                onChange={setAccountDataPassword}
                onBlur={validateAccountDataPassword}
                hasError={!!accountDataValidationResults.password.errorMessage}
                type="password"
                idForTesting={`account-data-password-input`}
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={accountDataFormTranslations.passwordRepeatLabel}
              isRequired
              errorMessage={
                accountDataValidationResults.repeatPassword.errorMessage
              }
            >
              <InputComponent
                placeholder={
                  accountDataFormTranslations.passwordRepeatPlaceholder
                }
                value={accountData.repeatPassword}
                onChange={setAccountDataRepeatPassword}
                onBlur={validateAccountDataPasswordRepeat}
                hasError={
                  !!accountDataValidationResults.repeatPassword.errorMessage
                }
                type="password"
                idForTesting={`account-data-password-repeat-input`}
              />
            </FormFieldComponent>
          </FormComponent>
        </CardComponent>
        <div className="sign_up_driver__next_button_wrapper">
          <ButtonComponent
            onClick={submitAccountStep}
            type="primary"
            isDisabled={!isSubmitButtonEnabled}
            idForTesting={`account-data-submit-button`}
          >
            {accountDataFormTranslations.submitButtonText}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default SignUpDriverAccountComponent;
