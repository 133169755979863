import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryInsertNodeAfterChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";
import orderDetailsHistoryEntryChangeRideContentInsertNodeAfterHelper from "./order-details-history-entry-change-ride-content-insert-node-after.helper";

type OrderDetailsHistoryEntryChangeRideContentInsertNodeAfterProps = {
  event: OrderDetailsHistoryEntryInsertNodeAfterChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeRideContentInsertNodeAfterComponent: FC<
  OrderDetailsHistoryEntryChangeRideContentInsertNodeAfterProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .insertNodeAfter;

  const addressLabel =
    orderDetailsHistoryEntryChangeRideContentInsertNodeAfterHelper.getAddressLabel(
      props.event.details.placeAddressDisplayName
    );

  const addressTitle =
    orderDetailsHistoryEntryChangeRideContentInsertNodeAfterHelper.getAddressTitle(
      props.event.details.placeAddressDisplayName
    );

  const plannedDateLabel =
    orderDetailsHistoryEntryChangeRideContentInsertNodeAfterHelper.getPlannedDateLabel(
      props.event.details.date
    );

  const plannedDateTitle =
    orderDetailsHistoryEntryChangeRideContentInsertNodeAfterHelper.getPlannedDateTitle(
      props.event.details.date
    );

  const haltingTimeLabel =
    orderDetailsHistoryEntryChangeRideContentInsertNodeAfterHelper.getHaltingTimeLabel(
      props.event.details.haltingTime
    );

  const haltingTimeTitle =
    orderDetailsHistoryEntryChangeRideContentInsertNodeAfterHelper.getHaltingTimeTitle(
      props.event.details.haltingTime
    );

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={addressTitle}
      >
        {addressLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={plannedDateTitle}
      >
        {plannedDateLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={haltingTimeTitle}
      >
        {haltingTimeLabel}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeRideContentInsertNodeAfterComponent;
