import { useState } from "react";
import OrderDetailsForwardingFormData from "../common/validation/types/order-details-forwarding-form-data";
import orderDetailsForwardingFormValidationService from "../common/validation/order-details-forwarding-form-validation.service";
import OrderDetailsForwardingFormValidationResults from "../common/validation/types/order-details-forwarding-form-validation-results";
import orderDetailsForwardingFormHelper from "../common/validation/order-details-forwarding-form.helper";

type UseOrderDetialsForwardingFormProps = {
  onlyContract?: boolean;
};

const UseOrderDetialsForwardingForm = (
  params: UseOrderDetialsForwardingFormProps
) => {
  const [formValidationResults, setFormValidationResults] =
    useState<OrderDetailsForwardingFormValidationResults>(() =>
      orderDetailsForwardingFormHelper.getDefaultFormValidationResults()
    );
  const [formData, setFormData] = useState<OrderDetailsForwardingFormData>(() =>
    orderDetailsForwardingFormHelper.getDefaultFormData()
  );

  const { onlyContract } = params;

  const setTaxiCorporation = (
    value: OrderDetailsForwardingFormData["taxiCorporation"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      taxiCorporation: value,
    }));
  };

  const validateTaxiCorporation = (
    taxiCorporation: OrderDetailsForwardingFormData["taxiCorporation"] = formData.taxiCorporation
  ) => {
    const validationResult =
      orderDetailsForwardingFormValidationService.validateTaxiCorporation(
        taxiCorporation
      );

    setFormValidationResults((curr) => ({
      ...curr,
      taxiCorporation: validationResult,
    }));

    return validationResult.isValid;
  };

  const setTaxiContract = (
    value: OrderDetailsForwardingFormData["taxiContract"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      taxiContract: value,
    }));
  };

  const validateTaxiContract = (
    taxiContract: OrderDetailsForwardingFormData["taxiContract"] = formData.taxiContract
  ) => {
    const validationResult =
      orderDetailsForwardingFormValidationService.validateTaxiContract(
        taxiContract
      );

    setFormValidationResults((curr) => ({
      ...curr,
      taxiContract: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsFormValid = () => {
    const isTaxiCorporationValid = validateTaxiCorporation();
    const isTaxiContractValid = validateTaxiContract();

    return onlyContract
      ? isTaxiContractValid
      : isTaxiCorporationValid && isTaxiContractValid;
  };

  return {
    formValidationResults,
    setTaxiCorporation,
    setTaxiContract,
    checkIsFormValid,
  };
};

export default UseOrderDetialsForwardingForm;
