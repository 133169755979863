import mileageSettingsApiService from "../../api/raily/mileage-settings/mileage-settings-api.service";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import MileageSettingsAddError from "./add/mileage-settings-add-error";
import MileageSettingsAddParams from "./add/mileage-settings-add-params";
import mileageSettingsAddRequestfactory from "./add/mileage-settings-add-request.factory";
import MileageSettingsDetails from "./details/mileage-settings-details";
import MileageSettingsDetailsError from "./details/mileage-settings-details-error";
import MileageSettingsDetailsLoadParams from "./details/mileage-settings-details-load-params";
import mileageSettingsDetailsRequestFactory from "./details/mileage-settings-details-request.factory";
import mileageSettingsDetailsFactory from "./details/mileage-settings-details.factory";
import MileageSettingsUpdateError from "./update/mileage-settings-update-error";
import MileageSettingsUpdateParams from "./update/mileage-settings-update-params";
import mileageSettingsUpdateRequestFactory from "./update/mileage-settings-update-request.factory";

const handleAddError = (
  error: HttpError | MileageSettingsAddError
): MileageSettingsAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleDetailsError = (
  error: HttpError | MileageSettingsDetailsError
): MileageSettingsDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleUpdateError = (
  error: HttpError | MileageSettingsUpdateError
): MileageSettingsUpdateError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const add = async (
  params: MileageSettingsAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request = mileageSettingsAddRequestfactory.create(params);

  try {
    const response = await mileageSettingsApiService().add(
      request,
      abortSignal
    );

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | MileageSettingsAddError;

    throw handleAddError(error);
  }
};

const details = async (
  params: MileageSettingsDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<MileageSettingsDetails | null> => {
  const request = mileageSettingsDetailsRequestFactory.create(params);

  try {
    const response = await mileageSettingsApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status === 404) {
      return null;
    }

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const details = mileageSettingsDetailsFactory.create(response);
    return details;
  } catch (_error) {
    const error = _error as HttpError | MileageSettingsDetailsError;

    throw handleDetailsError(error);
  }
};

const update = async (
  params: MileageSettingsUpdateParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request = mileageSettingsUpdateRequestFactory.create(params);

  try {
    const response = await mileageSettingsApiService().update(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | MileageSettingsUpdateError;

    throw handleUpdateError(error);
  }
};

const mileageSettingsService = {
  add,
  details,
  update,
};

export default mileageSettingsService;
