import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";

const validateHaltingTime = (
  haltingTimeValue: number | number
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(720).required();

  return formValidationService.validate(haltingTimeValue, validationSchema);
};

const validateDistance = (
  distance: number | undefined
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(999).required();

  return formValidationService.validate(distance, validationSchema);
};

const validateHighwayCost = (
  highwayCost: number | undefined
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(9999).required();

  return formValidationService.validate(highwayCost, validationSchema);
};

const validatePenalty = (
  penaltyValue: number | number,
  maxValue: number
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .max(maxValue)
    .precision(2)
    .required();

  return formValidationService.validate(penaltyValue, validationSchema);
};

const validateBonus = (penaltyValue: number | number): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .max(99999)
    .precision(2)
    .required();

  return formValidationService.validate(penaltyValue, validationSchema);
};

const validateDiscount = (
  discountValue: number,
  maxValue: number
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(maxValue).required();

  return formValidationService.validate(discountValue, validationSchema);
};

const billingRouteDetailsFormDataValidationService = {
  validateHaltingTime,
  validateDistance,
  validateHighwayCost,
  validateBonus,
  validatePenalty,
  validateDiscount,
};

export default billingRouteDetailsFormDataValidationService;
