import { TransferredOrderStatusCountResponseData } from "../../../../api/raily/billings/order/transferred-order-status-count/transferred-order-status-count.response";
import TransferredOrderStatusCount from "./transferred-order-status-count.model";

const transferredOrderStatusCountFactory = {
  create: (
    data: TransferredOrderStatusCountResponseData
  ): TransferredOrderStatusCount => ({
    accepted: data.accepted,
    created: data.created,
    rejected: data.rejected,
    reopened: data.reopened,
    reopenRequest: data.reopenRequest,
  }),
};

export default transferredOrderStatusCountFactory;
