enum SignUpTokenUserRole {
  CARGO_OFFICER = "CARGO_OFFICER",
  DEALER = "DEALER",
  DISPATCHER = "DISPATCHER",
  DRIVER = "DRIVER",
  PASSENGER = "PASSENGER",
  RAILY_OFFICER = "RAILY_OFFICER",
  TAXI_OFFICER = "TAXI_OFFICER",
  OPERATOR = "OPERATOR",
}

export default SignUpTokenUserRole;
