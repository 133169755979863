export enum RouteFinishedListLoadParamsOrder {
  DRIVER_ASC = "DRIVER_ASC",
  DRIVER_DESC = "DRIVER_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  TAXI_CORPORATION_ASC = "TAXI_CORPORATION_ASC",
  TAXI_CORPORATION_DESC = "TAXI_CORPORATION_DESC",
}

type RouteFinishedListLoadParams = {
  order?: RouteFinishedListLoadParamsOrder;
  taxiCorporationUuid?: string;
  driverUuid?: string;
  driverName?: string;
  taxiName?: string;
  planEntryNodes?: string;
  startingNodeLabel?: string;
  endingNodeLabel?: string;
  startTimeSince?: Date;
  startTimeTo?: Date;
  endTimeSince?: Date;
  endTimeTo?: Date;
  humanId?: number;
  involvedCargoLabels?: string;
  isBilled?: boolean;
  cargoOrderUuid?: string;
  cargoOrderHumanId?: number;
  creationStart?: Date;
  creationEnd?: Date;
  page: number;
  pageSize: number;
};

export default RouteFinishedListLoadParams;
