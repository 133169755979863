import isEqual from "lodash/isEqual";
import TaxiUnsettledRouteListingFilterSelectOption from "./types/taxi-unsettled-route-listing-filter-select-option";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiUnsettledRouteListingFilterType from "./types/taxi-unsettled-route-listing-filter-type";
import TaxiUnsettledRouteListingFilter from "./types/taxi-unsettled-route-listing-filter";

const getSearchQueryOptions = (
  query: string
): TaxiUnsettledRouteListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiUnsettledRouteListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByRouteIdLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDriverLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByClientLabel = (query: string) => {
    return searchFilterTranslations.searchByClientTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByOrderInternalIdLabel = (query: string) => {
    return searchFilterTranslations.searchByOrderInternalIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByFleetPartnerLabel = (query: string) => {
    return searchFilterTranslations.searchByFleetPartnerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions: TaxiUnsettledRouteListingFilterSelectOption[] = [
    {
      label: getSearchByDriverLabel(query),
      value: {
        type: TaxiUnsettledRouteListingFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByFleetPartnerLabel(query),
      value: {
        type: TaxiUnsettledRouteListingFilterType.FLEET_PARTNER,
        value: query,
      },
    },
    {
      label: getSearchByClientLabel(query),
      value: {
        type: TaxiUnsettledRouteListingFilterType.CLIENT,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressLabel(query),
      value: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressLabel(query),
      value: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressLabel(query),
      value: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressLabel(query),
      value: {
        type: TaxiUnsettledRouteListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
  ];

  const options: TaxiUnsettledRouteListingFilterSelectOption[] = [
    ...stringOptions,
  ] as TaxiUnsettledRouteListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const numericFilterOptions: TaxiUnsettledRouteListingFilterSelectOption[] =
      [
        {
          label: getSearchByRouteIdLabel(query),
          value: {
            type: TaxiUnsettledRouteListingFilterType.ROUTE_ID,
            value: Number(query),
          },
        },
        {
          label: getSearchByOrderInternalIdLabel(query),
          value: {
            type: TaxiUnsettledRouteListingFilterType.ORDER_INTERNAL_ID,
            value: Number(query),
          },
        },
      ];

    options.push(...numericFilterOptions);
  }

  return options;
};

const getSelectOptionsByQuery = (
  query: string
): TaxiUnsettledRouteListingFilterSelectOption[] => {
  const queryOptions = getSearchQueryOptions(query);

  return [...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: TaxiUnsettledRouteListingFilter[]
): TaxiUnsettledRouteListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  return allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });
};

const taxiUnsettledRouteListingFilterHelper = {
  getSelectOptions,
};

export default taxiUnsettledRouteListingFilterHelper;
