import userFormValidationService from "../../../../shared/user/user.validation.service";

const authValidationService = {
  validateUsername: userFormValidationService.validateUsername,
  validatePassword: userFormValidationService.validatePassword,
  validatePasswordRepeat: userFormValidationService.validatePasswordRepeat,
  validateFirstName: userFormValidationService.validateFirstName,
  validateLastName: userFormValidationService.validateLastName,
  validateMobile: userFormValidationService.validateMobile,
  validateAlternativeMobile:
    userFormValidationService.validateAlternativeMobile,
  validateEmail: userFormValidationService.validateEmail,
};

export default authValidationService;
