import UserRole from "../../../../../common/types/user-role";
import UserPermissions from "../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../common/utils/user/permissions/user-permissions-definition";

export type DelegationAddUserPermissionOption =
  | "hasAccessToCargoView"
  | "hasAccessToRailyView";

export type DelegationAddUserPermissionsDefinition =
  UserPermissionsDefinition<DelegationAddUserPermissionOption>;

export type DelegationAddUserPermissions =
  UserPermissions<DelegationAddUserPermissionOption>;

const delegationAddUserPermissionsDefinition: DelegationAddUserPermissionsDefinition =
  {
    hasAccessToCargoView: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default delegationAddUserPermissionsDefinition;
