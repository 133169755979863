import userHelper from "../../../../../common/utils/user/user.helper";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import DriverAddAccountFormData from "./types/driver-add-account-form-data";
import DriverAddAccountFormValidationResults from "./types/driver-add-account-form-validation-results";
import DriverAddCompanyFormAddress from "./types/driver-add-company-form-address";
import DriverAddCompanyFormData from "./types/driver-add-company-form-data";
import DriverAddCompanyFormValidationResults from "./types/driver-add-company-form-validation-results";
import DriverAddVehicleFormData from "./types/driver-add-vehicle-form-data";
import DriverAddVehicleFormValidationResults from "./types/driver-add-vehicle-form-validation-results";

const getDefaultAccountFormData = (): DriverAddAccountFormData => {
  return {
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
  };
};

const getDefaultAccountFormValidationResults =
  (): DriverAddAccountFormValidationResults => {
    return {
      email: formValidationService.defaultValidationResult,
      username: formValidationService.defaultValidationResult,
      password: formValidationService.defaultValidationResult,
      passwordRepeat: formValidationService.defaultValidationResult,
    };
  };

const getDefaultCompanyFormAddress = (): DriverAddCompanyFormAddress => {
  return {
    apartmentNumber: "",
    country: "",
    description: "",
    houseNumber: "",
    street: "",
    town: "",
    zipCode: "",
  };
};

const getDefaultCompanyFormData = (): DriverAddCompanyFormData => {
  const address = getDefaultCompanyFormAddress();

  return {
    name: "",
    companyId: "",
    taxNumber: "",
    nationalCourtRegisterNumber: "",
    address,
  };
};

const getDefaultCompanyFormValidationResults =
  (): DriverAddCompanyFormValidationResults => {
    return {
      name: formValidationService.defaultValidationResult,
      companyId: formValidationService.defaultValidationResult,
      taxNumber: formValidationService.defaultValidationResult,
      nationalCourtRegisterNumber:
        formValidationService.defaultValidationResult,
      addressCountry: formValidationService.defaultValidationResult,
      addressZipCode: formValidationService.defaultValidationResult,
      addressTown: formValidationService.defaultValidationResult,
      addressStreet: formValidationService.defaultValidationResult,
      addressHouseNumber: formValidationService.defaultValidationResult,
      addressApartmentNumber: formValidationService.defaultValidationResult,
      addressDescription: formValidationService.defaultValidationResult,
      addressMap: formValidationService.defaultValidationResult,
    };
  };

const getDefaultVehicleFormData = (): DriverAddVehicleFormData => {
  return {
    make: "",
    model: "",
    numberOfSeats: null,
    ownership: null,
    productionYear: null,
    registrationNumber: "",
  };
};

const getDefaultVehicleFormValidationResults =
  (): DriverAddVehicleFormValidationResults => {
    return {
      make: formValidationService.defaultValidationResult,
      model: formValidationService.defaultValidationResult,
      numberOfSeats: formValidationService.defaultValidationResult,
      ownership: formValidationService.defaultValidationResult,
      productionYear: formValidationService.defaultValidationResult,
      registrationNumber: formValidationService.defaultValidationResult,
    };
  };

const driverAddFormHelper = {
  getCitizenshipSelectOptions: userHelper.getCitizenshipSelectOptions,
  getFormOfEmploymentSelectOptions: userHelper.getFormOfEmploymentSelectOptions,
  getLanguageSelectOptions: userHelper.getLanguageSelectOptions,
  getVehicleOwnershipSelectOptions: userHelper.getVehicleOwnershipSelectOptions,
  getDefaultAccountFormData,
  getDefaultAccountFormValidationResults,
  getDefaultCompanyFormData,
  getDefaultCompanyFormValidationResults,
  getDefaultVehicleFormData,
  getDefaultVehicleFormValidationResults,
};

export default driverAddFormHelper;
