import { UserTaxiOfficerListingRouteQueryParams } from "../../../common/routes/types/user-taxi-officer-listing-route-params";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import TaxiOfficerListingFilter from "../common/types/taxi-officer-listing-filter";
import taxiOfficerListingFiltersService from "./taxi-officer-listing-filter.service";
import TaxiOfficerListingSortKey from "../common/types/taxi-officer-listing-sort-key";

const useTaxiOfficerListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<UserTaxiOfficerListingRouteQueryParams>();

  const getFilters = (): TaxiOfficerListingFilter[] => {
    return taxiOfficerListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return taxiOfficerListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return taxiOfficerListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): TaxiOfficerListingSortKey | undefined => {
    return taxiOfficerListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: TaxiOfficerListingFilter[],
    sortKey: TaxiOfficerListingSortKey | null,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      taxiOfficerListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useTaxiOfficerListingFiltersDao;
