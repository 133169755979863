import { FC, useEffect, useState } from "react";
import orderAddSummaryHelper from "./common/order-add-summary.helper";
import OrderAddSummaryRouteComponent from "./route/order-add-summary-route.component";
import orderAddSummaryFactory from "./common/order-add-summary.factory";
import orderAddSummaryMapFactory from "./route/order-add-summary-map-markers.factory";
import OrderAddSuccessModalComponent from "../add-success-modal/order-add-success-modal.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import MapComponent from "../../../../../../common/components/map/map.component";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../../common/components/map/types/map-route";
import SearchRoadRoutingRequest from "../../../../../../common/utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse, {
  SearchRoadRoutingResponseRouteLeg,
} from "../../../../../../common/utils/search-road-route/search-road-routing.response";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import OrderAddSolvedRidesResponse, {
  OrderAddSolvedRidesResponseDataCargoOrderSolvedRide,
  OrderAddSolvedRidesResponseRideSolvedWaypoint,
} from "../../api/order-add-solved-rides.response";
import OrderAddPassengerListItem from "../../types/order-add-passenger-list-item";
import OrderAddSolvedRidesRequest from "../../api/order-add-solved-rides.request";
import orderAddFactory from "../../factory/order-add.factory";
import orderAddApiService from "../../api/order-add-api.service";
import OrderAddResponse, {
  OrderAddNotAcceptableResponseError,
  OrderAddResponseSuccessResponse,
  OrderAddUnprocessableEntityTimeError,
} from "../../api/order-add.response";
import OrderAddRequestBody, {
  OrderAddRequestQuery,
} from "../../api/order-add.request";
import OrderAddTaxiCorporation from "../../types/order-add-taxi-corporation";
import OrderAddRouteItem from "../../types/order-add-route-waypoint";
import orderAddValidationService from "../../validation/order-add-validation.service";
import OrderAddDispatcher from "../../types/order-add-dispatcher";
import OrderAddPassenger from "../../types/order-add-passenger";

type OrderAddSummaryProps = {
  passengerList: OrderAddPassengerListItem[];
  selectedTaxiCorporation: OrderAddTaxiCorporation | null;
  routes: OrderAddRouteItem[];
  orderNumber: string;
  selectedDispatcherUuid: OrderAddDispatcher["uuid"] | null;
  selectedPassengerUuid: OrderAddPassenger["uuid"] | null;
  restoreForm: () => void;
  validateForm: () => boolean;
  setValidationMessage: (value: string[]) => void;
};

const OrderAddSummaryComponent: FC<OrderAddSummaryProps> = (props) => {
  const translations = orderTranslationsHelper.getAddTranslations().summary;

  const [isAddOrderFetching, setIsAddOrderFetching] = useState(false);

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const [isOrderAddSuccessModalOpen, setIsOrderAddSuccessModalOpen] =
    useState(false);

  const [addedOrderUuid, setAddedOrderUuid] = useState("");

  const [responseRouteLegs, setResponseRouteLegs] = useState<
    SearchRoadRoutingResponseRouteLeg[]
  >([]);

  const [solvedWaypoints, setSolvedWaypoints] = useState<
    OrderAddSolvedRidesResponseRideSolvedWaypoint[]
  >([]);

  const selectedPassengers = props.passengerList.map(
    (listItem) => listItem.passenger
  );

  const onSolvedRidesFetchSuccess = (
    responseCargoOrderRide: OrderAddSolvedRidesResponseDataCargoOrderSolvedRide
  ) => {
    setSolvedWaypoints(responseCargoOrderRide.seq);
  };

  const handleSolvedRidesResponse = (response: OrderAddSolvedRidesResponse) => {
    if (response.status === 200) {
      onSolvedRidesFetchSuccess(response.data);
    }
  };

  const fetchSolvedRides = () => {
    const request: OrderAddSolvedRidesRequest =
      orderAddFactory.createSolvedRidesRequest(
        props.selectedTaxiCorporation!,
        props.routes
      );

    orderAddApiService
      .fetchSolvedRides(request)
      .then(handleSolvedRidesResponse);
  };

  const waypoints = orderAddSummaryFactory.createSummaryRouteWaypoints(
    props.routes,
    responseRouteLegs,
    solvedWaypoints
  );

  const validateRoutesForSolvedOrderFetch = () => {
    const validationErrors =
      orderAddValidationService.validateRoutesForSolvedOrderFetch(
        props.passengerList,
        props.routes
      );

    return !validationErrors.length;
  };

  useEffect(() => {
    if (
      !props.selectedTaxiCorporation ||
      !props.routes.length ||
      !validateRoutesForSolvedOrderFetch()
    ) {
      setSolvedWaypoints([]);
      return;
    }

    fetchSolvedRides();
  }, [JSON.stringify(props.routes), props.selectedTaxiCorporation]);

  const mapMarkers: MapMarker[] =
    orderAddSummaryMapFactory.createMapMarkers(waypoints);

  const fetchRoute = () => {
    const waypointsCouldBeConnected =
      orderAddSummaryHelper.getWaypointGroupsCouldBeConnected(waypoints);

    const coordinatesOfWaypointsCouldBeConnected =
      orderAddSummaryHelper.getCoordinatesOfWaypointsCouldBeConnected(
        waypointsCouldBeConnected
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    coordinatesOfWaypointsCouldBeConnected.forEach((coordinates) => {
      const request: SearchRoadRoutingRequest = {
        waypointCoordinates: coordinates,
        excludeHighway:
          !props.selectedTaxiCorporation?.contractPermitsTollRoads,
      };

      const fetch = orderAddApiService.fetchRoute(request);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response) => {
        const mapRoute = response.routes[0]
          ? orderAddSummaryMapFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRoutes: MapRoute[] = mapRouteWaypointGroups.map(
        (routeWaypointGroup) => ({
          waypoints: routeWaypointGroup,
        })
      );

      setMapRoutes(newMapRoutes);

      const responseRouteLegs = responses
        .map((response) => response.routes.map((route) => route.legs).flat())
        .flat();

      setResponseRouteLegs(responseRouteLegs);
    });
  };

  useEffect(() => {
    if (!waypoints.length) {
      return;
    }

    fetchRoute();
  }, [JSON.stringify(waypoints)]);

  const onOrderAddSuccess = (orderUuid: string) => {
    setIsOrderAddSuccessModalOpen(true);
    setAddedOrderUuid(orderUuid);
  };

  const handleOrderAddRequest = (response: OrderAddResponse) => {
    if (response.status === 201) {
      const successResponse = response as OrderAddResponseSuccessResponse;
      onOrderAddSuccess(successResponse.data);
      return;
    }

    if (response.status === 406) {
      onApiError(response.data as OrderAddNotAcceptableResponseError);
    }

    if (response.status === 422) {
      onUnprocessableEntityError(
        response.data as OrderAddUnprocessableEntityTimeError
      );
    }
  };

  const onApiError = (error: OrderAddNotAcceptableResponseError) => {
    const resolvedApiError = orderAddApiService.resolveApiErrorLabel(error);

    props.setValidationMessage([resolvedApiError]);
  };

  const onUnprocessableEntityError = (
    error: OrderAddUnprocessableEntityTimeError
  ) => {
    const resolvedUnprocessableEntityError =
      orderAddApiService.resolveUnprocessableEntityError(error);

    props.setValidationMessage([resolvedUnprocessableEntityError]);
  };

  const onAddOrderSuccessNextOrderButtonClick = () => {
    props.restoreForm();
    setIsOrderAddSuccessModalOpen(false);
    setMapRoutes([]);
  };

  const onAddOrderSuccessCloseButtonClick = () => {
    props.restoreForm();
    setIsOrderAddSuccessModalOpen(false);
    setMapRoutes([]);
  };

  const onSubmitButtonClick = () => {
    const isFormValid = props.validateForm();

    if (!isFormValid) {
      return;
    }

    const request: OrderAddRequestBody = orderAddFactory.createAddRequest(
      props.orderNumber,
      props.selectedTaxiCorporation!,
      props.routes
    );

    const query: OrderAddRequestQuery = {
      assume_dispatcher_id: props.selectedDispatcherUuid!,
    };

    setIsAddOrderFetching(true);

    orderAddApiService
      .addOrder(request, query)
      .then(handleOrderAddRequest)
      .finally(() => {
        setIsAddOrderFetching(false);
      });
  };

  return (
    <>
      <CardComponent
        header={{ title: translations.headingText }}
        classNames={{ root: "order_add_summary" }}
      >
        <div style={{ height: 300 }}>
          <MapComponent
            markers={mapMarkers}
            autoFit
            autoFitOnUpdate
            routes={mapRoutes}
          />
        </div>
        <FormFieldComponent label={translations.taxiLabel}>
          <span data-test-id="summary-taxi">
            {orderAddSummaryHelper.getTaxiCorporationLabel(
              props.selectedTaxiCorporation
            )}
          </span>
        </FormFieldComponent>
        <FormFieldComponent label={translations.passengerListLabel}>
          <span data-test-id="summary-passengers">
            {orderAddSummaryHelper.getPassengersListLabel(selectedPassengers)}
          </span>
        </FormFieldComponent>
        <FormFieldComponent label={translations.routeLabel}>
          <OrderAddSummaryRouteComponent
            waypoints={waypoints}
            selectedPassengerUuid={props.selectedPassengerUuid}
          />
        </FormFieldComponent>
        <Row>
          <Column>
            <FormFieldComponent
              label={translations.orderNumberLabel}
              classNames={{
                content: "order_add_summary__order_number_form_field_content",
              }}
            >
              {orderAddSummaryHelper.getOrderNumberLabel(props.orderNumber)}
            </FormFieldComponent>
          </Column>
        </Row>
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          classNames={{ root: "w-100" }}
          isLoading={isAddOrderFetching}
          idForTesting="order-add-submit-button"
        >
          {translations.submitButtonText}
        </ButtonComponent>
      </CardComponent>
      {isOrderAddSuccessModalOpen && (
        <OrderAddSuccessModalComponent
          isOpen={isOrderAddSuccessModalOpen}
          addedOrderUuid={addedOrderUuid}
          onCloseClick={onAddOrderSuccessCloseButtonClick}
          onAddNextOrderButtonClick={onAddOrderSuccessNextOrderButtonClick}
        />
      )}
    </>
  );
};

export default OrderAddSummaryComponent;
