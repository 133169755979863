import Joi from "joi";
import SelectOption from "../../../../../../../common/components/form/select/common/option/select-option";
import formValidationService from "../../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import SignUpDriverAddressType from "../../../common/types/sign-up-driver-address-type";

const validateAddressType = (
  addressTypeSelectOption: SelectOption<SignUpDriverAddressType> | null
): FormValidationResult => {
  const validValues = Object.values(SignUpDriverAddressType);

  const validationSchema = Joi.string()
    .valid(...validValues)
    .required();

  const addressType = addressTypeSelectOption?.value;

  return formValidationService.validate(addressType, validationSchema);
};

const validateStreet = (street: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).required();

  return formValidationService.validate(street, validationSchema);
};

const validateHouseNumber = (houseNumber: string): FormValidationResult => {
  const validationSchema = Joi.string().max(10).required();

  return formValidationService.validate(houseNumber, validationSchema);
};

const validateApartmentNumber = (
  apartmentNumber: string
): FormValidationResult => {
  const validationSchema = Joi.string().allow("").max(10).required();

  return formValidationService.validate(apartmentNumber, validationSchema);
};

const validateTown = (town: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).required();

  return formValidationService.validate(town, validationSchema);
};

const validateCountry = (country: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).required();

  return formValidationService.validate(country, validationSchema);
};

const validateZipCode = (zipCode: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(10).required();

  return formValidationService.validate(zipCode, validationSchema);
};

const validateDescription = (description: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").max(100).required();

  return formValidationService.validate(description, validationSchema);
};

const signUpDriverUserAddressFormValidationService = {
  validateAddressType,
  validateStreet,
  validateHouseNumber,
  validateApartmentNumber,
  validateTown,
  validateCountry,
  validateZipCode,
  validateDescription,
};

export default signUpDriverUserAddressFormValidationService;
