import { FC, useEffect, useState } from "react";
import driverDetailsCandidatureApiService from "./common/api/driver-details-candidature-api.service";
import DriverDetailsCandidatureResponse from "./common/api/driver-details-candidature.response";
import ButtonComponent from "../../../../../common/components/button/button.component";
import DriverDetailsCandidatureManagementComponent from "./management/driver-details-candidature-management.component";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import notificationService from "../../../../../common/utils/notification/notification.service";
import driverDetailsUserPermissionsHelper from "../common/user-permissions/driver-details-user-permission.helper";
import { useAppContext } from "../../../../../context/app.context";
import DriverDetailsDriverPlanEntry from "../active-route-plans/common/types/driver-details-plan-entry";
import ContentWrapper from "../../../../../common/components/content-wrapper/content-wrapper.component";

type DriverDetailsCandidatureProps = {
  driverUuid: string;
  isVisible: boolean;
  onAcceptCandidatureSuccess: () => void;
  currentPlanEntries: DriverDetailsDriverPlanEntry[];
  asCardComponent?: boolean;
};

const DriverDetailsCandidatureComponent: FC<DriverDetailsCandidatureProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverDetailsCandidatureTranslations();

  const { user } = useAppContext();

  const userPermissions = driverDetailsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const [isActiveCandidature, setIsActiveCandidature] = useState(false);
  const [isActiveCandidatureFetching, setIsActiveCandidatureFetching] =
    useState(false);

  const [
    isCandidatureManagementModalOpen,
    setIsCandidatureManagementModalOpen,
  ] = useState(false);

  const onFetchCandidatureSuccess = () => {
    setIsActiveCandidature(true);
  };

  const onFetchCandidatureFailure = () => {
    notificationService.error(
      translations.failureCandidatureFetchNotificationText
    );
  };

  const handleCandidatureResponse = (
    response: DriverDetailsCandidatureResponse
  ) => {
    if (response.status === 200) {
      onFetchCandidatureSuccess();
      return;
    }

    if (response.status === 404) {
      setIsActiveCandidature(false);
      return;
    }

    onFetchCandidatureFailure();
  };

  const fetchCandidature = () => {
    setIsActiveCandidatureFetching(true);

    driverDetailsCandidatureApiService
      .fetchActiveCandidature(props.driverUuid)
      .then(handleCandidatureResponse)
      .catch(onFetchCandidatureFailure)
      .finally(() => setIsActiveCandidatureFetching(false));
  };

  useEffect(() => {
    if (!props.isVisible || !userPermissions.hasAccessToManageCandidature) {
      return;
    }

    fetchCandidature();
  }, [props.isVisible]);

  const openCandidatureManagementModal = () => {
    setIsCandidatureManagementModalOpen(true);
  };

  const closeCandidatureManagementModal = () => {
    setIsCandidatureManagementModalOpen(false);
  };

  const onManageCandidatureButtonClick = () => {
    openCandidatureManagementModal();
  };

  const onAcceptCandidatureSuccess = () => {
    fetchCandidature();
    closeCandidatureManagementModal();
    props.onAcceptCandidatureSuccess();
  };

  const onDeleteCandidatureSuccess = () => {
    fetchCandidature();
    closeCandidatureManagementModal();
  };

  if (!isActiveCandidature) {
    return null;
  }

  return (
    <ContentWrapper
      asCardComponent={props.asCardComponent}
      classNames={{ root: "mb-2" }}
    >
      <div className="mb-5">
        <div className="mb-1">
          {translations.activeCandidatureNotificationText}
        </div>
        <ButtonComponent
          onClick={onManageCandidatureButtonClick}
          type="primary"
          title={translations.manageCandidatureButtonTitle}
        >
          {translations.manageCandidatureButtonText}
        </ButtonComponent>
        <DriverDetailsCandidatureManagementComponent
          isOpen={isCandidatureManagementModalOpen}
          onClose={closeCandidatureManagementModal}
          driverUuid={props.driverUuid}
          onAcceptCandidatureSuccess={onAcceptCandidatureSuccess}
          onDeleteCandidatureSuccess={onDeleteCandidatureSuccess}
          currentPlanEntries={props.currentPlanEntries}
        />
      </div>
      <DriverDetailsCandidatureManagementComponent
        isOpen={isCandidatureManagementModalOpen}
        onClose={closeCandidatureManagementModal}
        driverUuid={props.driverUuid}
        onAcceptCandidatureSuccess={onAcceptCandidatureSuccess}
        onDeleteCandidatureSuccess={onDeleteCandidatureSuccess}
        currentPlanEntries={props.currentPlanEntries}
      />
    </ContentWrapper>
  );
};

export default DriverDetailsCandidatureComponent;
