import mapMarkerIconFactory from "../../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../common/components/map/types/map-route";
import { SearchRoadRoutingResponseRouteGeometryCoordinate } from "../../../../../common/utils/search-road-route/search-road-routing.response";
import MileageDetailsAddress from "../types/mileage-details-address";

const createMapMarker = (adres: MileageDetailsAddress, addressNum: number) => {
  const signature = `${addressNum + 1}`;

  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: signature,
  });

  const newMapMarker: MapMarker = {
    coordinate: {
      latitude: adres.latitude,
      longitude: adres.longitude,
    },
    icon: markerIcon,
    title: adres.displayName,
  };

  return newMapMarker;
};

const createMapMarkers = (
  addressSequence: MileageDetailsAddress[]
): MapMarker[] => {
  return addressSequence.map(createMapMarker);
};

const createMapRoute = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute => {
  return {
    waypoints: routesResponseGeometryCoordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
  };
};

const mileageDetailsRouteFactory = {
  createMapMarkers,
  createMapRoute,
};

export default mileageDetailsRouteFactory;
