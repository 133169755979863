import DelegationDownloadRequest, {
  DelegationDownloadRequestFileFormat,
  DelegationDownloadRequestTimezone,
} from "../../../api/raily/delegation/download/delegation-download.request";
import DelegationDownloadParams, {
  DelegationDownloadParamsFileFormat,
  DelegationDownloadParamsTimezone,
} from "./delegation-download-params";

const createFileFormat = (
  format: DelegationDownloadParamsFileFormat
): DelegationDownloadRequestFileFormat => {
  switch (format) {
    case DelegationDownloadParamsFileFormat.CSV:
      return DelegationDownloadRequestFileFormat.CSV;
    case DelegationDownloadParamsFileFormat.PDF:
      return DelegationDownloadRequestFileFormat.PDF;
  }
};

const createTimezone = (
  timezone: DelegationDownloadParamsTimezone | undefined
): DelegationDownloadRequestTimezone | undefined => {
  switch (timezone) {
    case DelegationDownloadParamsTimezone.EUROPE_WARSAW:
      return DelegationDownloadRequestTimezone.EUROPE_WARSAW;
    case DelegationDownloadParamsTimezone.UTC:
      return DelegationDownloadRequestTimezone.UTC;
    default:
      return undefined;
  }
};

const create = (
  params: DelegationDownloadParams
): DelegationDownloadRequest => {
  return {
    fileFormat: createFileFormat(params.fileFormat),
    delegationUuids: params.delegationUuids,
    separator: params.separator,
    timezone: createTimezone(params.timezone),
  };
};

const delegationDownloadRequestFactory = {
  create,
};

export default delegationDownloadRequestFactory;
