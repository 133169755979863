import railyApiService from "../raily-api.service";
import taxiDriverContractAddRequestFactory from "./add/taxi-driver-contract-add-request.factory";
import TaxiDriverContractAddRequest, {
  TaxiDriverContractAddRequestBody,
} from "./add/taxi-driver-contract-add.request";
import TaxiDriverContractAddResponse from "./add/taxi-driver-contract-add.response";
import TaxiDriverContractDetailsRequest from "./details/taxi-driver-contract-details.request";
import TaxiDriverContractDetailsResponse from "./details/taxi-driver-contract-details.response";
import taxiDriverContractUpdateRequestFactory from "./update/taxi-driver-contract-update-request.factory";
import TaxiDriverContractUpdateRequest, {
  TaxiDriverContractUpdateRequestBody,
} from "./update/taxi-driver-contract-update.request";
import TaxiDriverContractUpdateResponse from "./update/taxi-driver-contract-update.response";

const taxiDriverContractApiService = () => {
  const add = (
    request: TaxiDriverContractAddRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiDriverContractAddResponse> => {
    const url = `/contracts/taxi-driver`;

    const body: TaxiDriverContractAddRequestBody =
      taxiDriverContractAddRequestFactory.createBody(request);

    return railyApiService().post<TaxiDriverContractAddResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const update = (
    request: TaxiDriverContractUpdateRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiDriverContractUpdateResponse> => {
    const url = `/contracts/taxi-driver/${request.contractId}`;

    const body: TaxiDriverContractUpdateRequestBody =
      taxiDriverContractUpdateRequestFactory.createBody(request);

    return railyApiService().put<TaxiDriverContractUpdateResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const getDetails = (
    request: TaxiDriverContractDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<TaxiDriverContractDetailsResponse> => {
    const url = `/contracts/taxi-driver/${request.contractId}`;

    return railyApiService().get<TaxiDriverContractDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return {
    add,
    update,
    getDetails,
  };
};

export default taxiDriverContractApiService;
