import CargoOfficerListingFilter, {
  CargoOfficerListingEmailFilter,
  CargoOfficerListingFirstNameFilter,
  CargoOfficerListingLastNameFilter,
  CargoOfficerListingActivityStatusFilter,
  CargoOfficerListingUsernameFilter,
  CargoOfficerListingCargoCompanyFilter,
} from "./types/cargo-officer-listing-filter";
import CargoOfficerListingFilterType from "./types/cargo-officer-listing-filter-type";
import CargoOfficerListingSortKey from "./types/cargo-officer-listing-sort-key";
import CargoOfficerListLoadParams, {
  CargoOfficerListLoadParamsOrder,
} from "../../../../../common/services/cargo-officer/list/cargo-officer-list-load-params";

const createOrder = (
  sortKey: CargoOfficerListingSortKey | null
): CargoOfficerListLoadParamsOrder | undefined => {
  const definition: Record<
    CargoOfficerListingSortKey,
    CargoOfficerListLoadParamsOrder
  > = {
    [CargoOfficerListingSortKey.FIRST_NAME_ASC]:
      CargoOfficerListLoadParamsOrder.FIRST_NAME_ASC,
    [CargoOfficerListingSortKey.FIRST_NAME_DESC]:
      CargoOfficerListLoadParamsOrder.FIRST_NAME_DESC,
    [CargoOfficerListingSortKey.LAST_NAME_ASC]:
      CargoOfficerListLoadParamsOrder.LAST_NAME_ASC,
    [CargoOfficerListingSortKey.LAST_NAME_DESC]:
      CargoOfficerListLoadParamsOrder.LAST_NAME_DESC,
    [CargoOfficerListingSortKey.USERNAME_ASC]:
      CargoOfficerListLoadParamsOrder.USERNAME_ASC,
    [CargoOfficerListingSortKey.USERNAME_DESC]:
      CargoOfficerListLoadParamsOrder.USERNAME_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: CargoOfficerListingFilter[],
  sortKey: CargoOfficerListingSortKey | null
): CargoOfficerListLoadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === CargoOfficerListingFilterType.ACTIVITY_STATUS
  )?.value as CargoOfficerListingActivityStatusFilter["value"] | undefined;

  return {
    page,
    pageSize,
    firstName: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.FIRST_NAME
    )?.value as CargoOfficerListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.LAST_NAME
    )?.value as CargoOfficerListingLastNameFilter["value"] | undefined,
    email: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.EMAIL
    )?.value as CargoOfficerListingEmailFilter["value"] | undefined,
    username: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.USERNAME
    )?.value as CargoOfficerListingUsernameFilter["value"] | undefined,
    cargoCompany: filters.find(
      (filter) => filter.type === CargoOfficerListingFilterType.CARGO_COMPANY
    )?.value as CargoOfficerListingCargoCompanyFilter["value"] | undefined,
    status: statusFilter,
    order: createOrder(sortKey),
  };
};

const cargoOfficerListingLoadParamsFactory = {
  create,
};

export default cargoOfficerListingLoadParamsFactory;
