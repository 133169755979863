import CargoCompanyListRequest, {
  CargoCompanyListRequestOrder,
} from "../../../../api/raily/cargo-company/cargo-company/list/cargo-company-list.request";
import CargoCompanyListLoadParams, {
  CargoCompanyListLoadParamsOrder,
} from "./cargo-company-list-load-params";

const createOrder = (
  status: CargoCompanyListLoadParamsOrder | undefined
): CargoCompanyListRequestOrder | undefined => {
  switch (status) {
    case CargoCompanyListLoadParamsOrder.COMPANY_NAME_ASC:
      return CargoCompanyListRequestOrder.COMPANY_NAME_ASC;
    case CargoCompanyListLoadParamsOrder.COMPANY_NAME_DESC:
      return CargoCompanyListRequestOrder.COMPANY_NAME_DESC;
    case CargoCompanyListLoadParamsOrder.COMPANY_DISPLAY_NAME_ASC:
      return CargoCompanyListRequestOrder.DISPLAY_NAME_ASC;
    case CargoCompanyListLoadParamsOrder.COMPANY_DISPLAY_NAME_DESC:
      return CargoCompanyListRequestOrder.DISPLAY_NAME_DESC;
    default:
      return undefined;
  }
};

const create = (
  params: CargoCompanyListLoadParams
): CargoCompanyListRequest => {
  return {
    companyName: params.companyName,
    creationEnd: params.creationEnd,
    creationStart: params.creationStart,
    displayName: params.companyDisplayName,
    krsNo: params.nationalCourtRegister,
    notes: params.notes,
    order: createOrder(params.order),
    regon: params.companyId,
    taxId: params.taxNumber,
    headquartersAddressString: params.address,
  };
};

const cargoCompanyListRequestFactory = {
  create,
};

export default cargoCompanyListRequestFactory;
