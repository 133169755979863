import SignUpDispatcherFormData from "./types/sign-up-dispatcher-form-data";
import SignUpDispatcherRequest from "./api/sign-up-dispatcher.request";
import SignUpDispatcherTokenPayload from "../../common/types/sign-up-dispatcher-token-payload";
import SignUpDispatcherDisabledFormFields from "./types/sign-up-dispatcher-disabled-form-fields";

const createSignUpRequest = (
  formData: SignUpDispatcherFormData,
  dispatcherId: string
): SignUpDispatcherRequest => ({
  first_name: formData.firstName.trim(),
  last_name: formData.lastName.trim(),
  mobile_primary: formData.mobile.number!,
  mobile_primary_prefix: formData.mobile.dialingCode!,
  mobile_secondary: formData.alternativeMobile.number,
  mobile_secondary_prefix: formData.alternativeMobile.dialingCode,
  email: formData.email.trim(),
  username: formData.username.trim(),
  password: formData.password,
  dispatcher: {
    dispatch_id: dispatcherId,
  },
});

const createFormData = (
  tokenPayload: SignUpDispatcherTokenPayload
): SignUpDispatcherFormData => {
  return {
    email: tokenPayload.user_data.email ?? "",
    password: "",
    passwordRepeat: "",
    username: tokenPayload.user_data.username ?? "",
    alternativeMobile: {
      dialingCode: tokenPayload.user_data.mobile_secondary_prefix ?? "",
      number: tokenPayload.user_data.mobile_secondary ?? "",
    },
    mobile: {
      dialingCode: tokenPayload.user_data.mobile_primary_prefix ?? "",
      number: tokenPayload.user_data.mobile_primary ?? "",
    },
    firstName: tokenPayload.user_data.first_name ?? "",
    lastName: tokenPayload.user_data.last_name ?? "",
  };
};

const createDisabledFormFields = (
  tokenPayload: SignUpDispatcherTokenPayload
): SignUpDispatcherDisabledFormFields => {
  return {
    email: !!tokenPayload.user_data.email,
    firstName: !!tokenPayload.user_data.first_name,
    lastName: !!tokenPayload.user_data.last_name,
    username: !!tokenPayload.user_data.username,
    mobile:
      !!tokenPayload.user_data.mobile_primary_prefix &&
      !!tokenPayload.user_data.mobile_primary,
    alternativeMobile:
      !!tokenPayload.user_data.mobile_secondary_prefix &&
      !!tokenPayload.user_data.mobile_secondary,
  };
};

const signUpDispatcherFactory = {
  createFormData,
  createDisabledFormFields,
  createSignUpRequest,
};

export default signUpDispatcherFactory;
