import useAsyncData from "../../../hooks/use-async-data";
import delegationService from "../delegation.service";
import DelegationDetails from "./delegation-details";
import DelegationDetailsLoadParams from "./delegation-details-load-params";

const useDelegationDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<DelegationDetails | null>(null);

  const load = async (
    params: DelegationDetailsLoadParams,
    abortSignal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await delegationService.getDetails(params, abortSignal);

      setData(data);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useDelegationDetails;
