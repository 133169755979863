import dateService from "../../../../utils/date/date.service";
import DateRange from "../../../../types/date-range";

const getDateInputFormattedDate = (date: Date): string => {
  return dateService.formatDate(date);
};

const getDateRangeInputValue = (date: DateRange | null): string => {
  if (!date) {
    return "";
  }

  const fromDateLabel = getDateInputFormattedDate(date.from);
  const toDateLabel = getDateInputFormattedDate(date.to);

  return `${fromDateLabel} - ${toDateLabel}`;
};

const dateRangeInputHelper = {
  getDateRangeInputValue,
};

export default dateRangeInputHelper;
