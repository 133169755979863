import DelegationListRequest, {
  DelegationListRequestDelegationType,
  DelegationListResultsOrder,
} from "../../../api/raily/delegation/list/delegation-list.request";
import paginationService from "../../../utils/pagination/pagination.service";
import DelegationListLoadParams, {
  DelegationListLoadParamsDelegationType,
  DelegationListLoadParamsSortKey,
} from "./delegation-list-load-params";

const createOrder = (
  order: DelegationListLoadParamsSortKey | undefined
): DelegationListResultsOrder | undefined => {
  switch (order) {
    case DelegationListLoadParamsSortKey.START_DATE_ASC:
      return DelegationListResultsOrder.START_DATE_ASC;
    case DelegationListLoadParamsSortKey.START_DATE_DESC:
      return DelegationListResultsOrder.START_DATE_DESC;
    case DelegationListLoadParamsSortKey.COST_ASC:
      return DelegationListResultsOrder.COST_ASC;
    case DelegationListLoadParamsSortKey.COST_DESC:
      return DelegationListResultsOrder.COST_DESC;
    case DelegationListLoadParamsSortKey.TOTAL_DISTANCE_ASC:
      return DelegationListResultsOrder.TOTAL_DISTANCE_ASC;
    case DelegationListLoadParamsSortKey.TOTAL_DISTANCE_DESC:
      return DelegationListResultsOrder.TOTAL_DISTANCE_DESC;
    case DelegationListLoadParamsSortKey.CREATION_DATE_ASC:
      return DelegationListResultsOrder.CREATED_DATE_ASC;
    case DelegationListLoadParamsSortKey.CREATION_DATE_DESC:
      return DelegationListResultsOrder.CREATED_DATE_DESC;
    case DelegationListLoadParamsSortKey.DELEGATION_NUMBER_ASC:
      return DelegationListResultsOrder.DELEGATION_NUMBER_ASC;
    case DelegationListLoadParamsSortKey.DELEGATION_NUMBER_DESC:
      return DelegationListResultsOrder.DELEGATION_NUMBER_DESC;
    case DelegationListLoadParamsSortKey.WORKER_NAME_ASC:
      return DelegationListResultsOrder.WORKER_NAME_ASC;
    case DelegationListLoadParamsSortKey.WORKER_NAME_DESC:
      return DelegationListResultsOrder.WORKER_NAME_DESC;
    case DelegationListLoadParamsSortKey.END_DATE_ASC:
      return DelegationListResultsOrder.END_DATE_ASC;
    case DelegationListLoadParamsSortKey.END_DATE_DESC:
      return DelegationListResultsOrder.END_DATE_DESC;
    case DelegationListLoadParamsSortKey.FIRST_CSV_DOWNLOAD_ASC:
      return DelegationListResultsOrder.FIRST_CSV_DOWNLOAD_ASC;
    case DelegationListLoadParamsSortKey.FIRST_CSV_DOWNLOAD_DESC:
      return DelegationListResultsOrder.FIRST_CSV_DOWNLOAD_DESC;
    case DelegationListLoadParamsSortKey.FIRST_PDF_DOWNLOAD_ASC:
      return DelegationListResultsOrder.FIRST_PDF_DOWNLOAD_ASC;
    case DelegationListLoadParamsSortKey.FIRST_PDF_DOWNLOAD_DESC:
      return DelegationListResultsOrder.FIRST_PDF_DOWNLOAD_DESC;
    case DelegationListLoadParamsSortKey.CARGO_COMPANY_NAME_ASC:
      return DelegationListResultsOrder.CARGO_COMPANY_NAME_ASC;
    case DelegationListLoadParamsSortKey.CARGO_COMPANY_NAME_DESC:
      return DelegationListResultsOrder.CARGO_COMPANY_NAME_DESC;
    default:
      return undefined;
  }
};

const createType = (
  type: DelegationListLoadParamsDelegationType | undefined
): DelegationListRequestDelegationType | undefined => {
  switch (type) {
    case DelegationListLoadParamsDelegationType.AUTO:
      return DelegationListRequestDelegationType.AUTO;
    case DelegationListLoadParamsDelegationType.MANUAL:
      return DelegationListRequestDelegationType.MANUAL;
    default:
      return undefined;
  }
};

const create = (params: DelegationListLoadParams): DelegationListRequest => {
  return {
    limit: params.pageSize,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.sortKey),
    cargoCompanyName: params.cargoCompanyName,
    startDateSince: params.startDateSince,
    startDateTo: params.startDateTo,
    endDateSince: params.endDateSince,
    endDateTo: params.endDateTo,
    creationDateSince: params.creationDateSince,
    creationDateTo: params.creationDateTo,
    firstCsvDownloadDateSince: params.firstCsvDownloadDateSince,
    firstCsvDownloadDateTo: params.firstCsvDownloadDateTo,
    firstPdfDownloadDateSince: params.firstPdfDownloadDateSince,
    firstPdfDownloadDateTo: params.firstPdfDownloadDateTo,
    workerName: params.workerName,
    delegationNumber: params.delegationNumber,
    type: createType(params.type),
    companyUuid: params.cargoCompanyUuid,
    costMax: params.costMax,
    costMin: params.costMin,
    totalDistanceMax: params.totalDistanceMax,
    totalDistanceMin: params.totalDitanceMin,
  };
};

const delegationListRequestFactory = {
  create,
};

export default delegationListRequestFactory;
