import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import CargoAddressListingFilterSelectOption from "../common/types/cargo-address-listing-filter-select-options";
import CargoAddressListingFilterType from "../common/types/cargo-address-listing-filter-type";

const getSearchByDescriptionQueryLabel = (query: string) => {
  const searchFilterTranslations =
    cargoTranslationsHelper.getAddressListingTranslations().filters.search;

  return searchFilterTranslations.searchByDescriptionTemplateText.replace(
    `#{query}`,
    query
  );
};

const getSearchByTownQueryLabel = (query: string) => {
  const searchFilterTranslations =
    cargoTranslationsHelper.getAddressListingTranslations().filters.search;

  return searchFilterTranslations.searchByTownTemplateText.replace(
    `#{query}`,
    query
  );
};

const getSearchByStreetQueryLabel = (query: string) => {
  const searchFilterTranslations =
    cargoTranslationsHelper.getAddressListingTranslations().filters.search;

  return searchFilterTranslations.searchByStreetTemplateText.replace(
    `#{query}`,
    query
  );
};

const getSearchByZipCodeQueryLabel = (query: string) => {
  const searchFilterTranslations =
    cargoTranslationsHelper.getAddressListingTranslations().filters.search;

  return searchFilterTranslations.searchByZipCodeTemplateText.replace(
    `#{query}`,
    query
  );
};

const getSearchByCountryQueryLabel = (query: string) => {
  const searchFilterTranslations =
    cargoTranslationsHelper.getAddressListingTranslations().filters.search;

  return searchFilterTranslations.searchByCountryTemplateText.replace(
    `#{query}`,
    query
  );
};

const getSearchQueryOptions = (
  query: string
): CargoAddressListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  return [
    {
      label: getSearchByDescriptionQueryLabel(query),
      value: {
        type: CargoAddressListingFilterType.DESCRIPTION,
        value: query,
      },
    },
    {
      label: getSearchByCountryQueryLabel(query),
      value: {
        type: CargoAddressListingFilterType.COUNTRY,
        value: query,
      },
    },
    {
      label: getSearchByStreetQueryLabel(query),
      value: {
        type: CargoAddressListingFilterType.STREET,
        value: query,
      },
    },
    {
      label: getSearchByTownQueryLabel(query),
      value: {
        type: CargoAddressListingFilterType.TOWN,
        value: query,
      },
    },
    {
      label: getSearchByZipCodeQueryLabel(query),
      value: {
        type: CargoAddressListingFilterType.ZIP_CODE,
        value: query,
      },
    },
  ];
};

const getSelectOptionsByQuery = (
  query: string
): CargoAddressListingFilterSelectOption[] => {
  const standardOptions = getSearchQueryOptions(query);

  return [...standardOptions];
};

const cargoAddressListingFiltersHelper = {
  getSelectOptionsByQuery,
};

export default cargoAddressListingFiltersHelper;
