import Joi from "joi";
import TaxiUnsettledTransferedOrderListingFilter, {
  TaxiUnsettledTransferedOrderListingProducerTaxiFilter,
  TaxiUnsettledTransferedOrderListingConsumerTaxiFilter,
  TaxiUnsettledTransferedOrderListingDriverFilter,
  TaxiUnsettledTransferedOrderListingExternalOrderIdFilter,
  TaxiUnsettledTransferedOrderListingInternalOrderIdFilter,
  TaxiUnsettledTransferedOrderListingPassengerFilter,
  TaxiUnsettledTransferedOrderListingRouteAddressFilter,
  TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter,
  TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter,
  TaxiUnsettledTransferedOrderListingStartDateFilter,
} from "./types/taxi-unsettled-transfered-order-listing-filter";
import TaxiUnsettledTransferedOrderListingFilterType from "./types/taxi-unsettled-transfered-order-listing-filter-type";
import TaxiUnsettledTransferedOrderListingRouteQueryFilterParams from "./types/taxi-unsettled-transfered-order-listing-route-query-filter-params";
import TaxiUnsettledTransferedOrderListingRouteQueryParams from "./types/taxi-unsettled-transfered-order-listing-route-query-params";
import TaxiUnsettledTransferedOrderListingSortKey from "./types/taxi-unsettled-transfered-order-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: TaxiUnsettledTransferedOrderListingRouteQueryFilterParams
): TaxiUnsettledTransferedOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: TaxiUnsettledTransferedOrderListingFilter[] = [];

  if (routeQueryFilterParams.producerTaxi) {
    const producerTaxiFilter: TaxiUnsettledTransferedOrderListingProducerTaxiFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.PRODUCER_TAXI,
        value: routeQueryFilterParams.producerTaxi,
      };

    filters.push(producerTaxiFilter);
  }

  if (routeQueryFilterParams.consumerTaxi) {
    const consumerTaxiFilter: TaxiUnsettledTransferedOrderListingConsumerTaxiFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.CONSUMER_TAXI,
        value: routeQueryFilterParams.consumerTaxi,
      };

    filters.push(consumerTaxiFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: TaxiUnsettledTransferedOrderListingDriverFilter = {
      type: TaxiUnsettledTransferedOrderListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalOrderIdFilter: TaxiUnsettledTransferedOrderListingExternalOrderIdFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: routeQueryFilterParams.externalOrderId,
      };

    filters.push(externalOrderIdFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: TaxiUnsettledTransferedOrderListingInternalOrderIdFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.INTERNAL_ORDER_ID,
        value: routeQueryFilterParams.internalOrderId,
      };

    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: TaxiUnsettledTransferedOrderListingPassengerFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.PASSENGER,
        value: routeQueryFilterParams.passenger,
      };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: TaxiUnsettledTransferedOrderListingRouteAddressFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_ADDRESS,
        value: routeQueryFilterParams.routeAddress,
      };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationFilter: TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: TaxiUnsettledTransferedOrderListingStartDateFilter =
      {
        type: TaxiUnsettledTransferedOrderListingFilterType.START_DATE,
        value: {
          from: new Date(routeQueryFilterParams.startDateFrom),
          to: new Date(routeQueryFilterParams.startDateTo),
        },
      };

    filters.push(startDateFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: TaxiUnsettledTransferedOrderListingRouteQueryParams
): TaxiUnsettledTransferedOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: TaxiUnsettledTransferedOrderListingFilter[],
  sortKey: TaxiUnsettledTransferedOrderListingSortKey | null,
  page: number,
  pageSize: number
): TaxiUnsettledTransferedOrderListingRouteQueryParams => {
  const routeQueryParams: TaxiUnsettledTransferedOrderListingRouteQueryParams =
    {
      producerTaxi: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.PRODUCER_TAXI
      )?.value as
        | TaxiUnsettledTransferedOrderListingProducerTaxiFilter["value"]
        | undefined,
      consumerTaxi: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.CONSUMER_TAXI
      )?.value as
        | TaxiUnsettledTransferedOrderListingConsumerTaxiFilter["value"]
        | undefined,
      driver: filters.find(
        (filter) =>
          filter.type === TaxiUnsettledTransferedOrderListingFilterType.DRIVER
      )?.value as
        | TaxiUnsettledTransferedOrderListingDriverFilter["value"]
        | undefined,
      externalOrderId: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.EXTERNAL_ORDER_ID
      )?.value as
        | TaxiUnsettledTransferedOrderListingExternalOrderIdFilter["value"]
        | undefined,
      internalOrderId: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.INTERNAL_ORDER_ID
      )?.value as
        | TaxiUnsettledTransferedOrderListingInternalOrderIdFilter["value"]
        | undefined,
      passenger: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.PASSENGER
      )?.value as
        | TaxiUnsettledTransferedOrderListingPassengerFilter["value"]
        | undefined,
      routeAddress: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.ROUTE_ADDRESS
      )?.value as
        | TaxiUnsettledTransferedOrderListingRouteAddressFilter["value"]
        | undefined,
      routeDestinationAddress: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
      )?.value as
        | TaxiUnsettledTransferedOrderListingRouteDestinationAddressFilter["value"]
        | undefined,
      routeIntermediateAddress: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
      )?.value as
        | TaxiUnsettledTransferedOrderListingRouteIntermediateAddressFilter["value"]
        | undefined,
      routePickupAddress: filters.find(
        (filter) =>
          filter.type ===
          TaxiUnsettledTransferedOrderListingFilterType.ROUTE_PICKUP_ADDRESS
      )?.value as
        | TaxiUnsettledTransferedOrderListingRoutePickupAddressFilter["value"]
        | undefined,
      startDateFrom: (
        filters.find(
          (filter) =>
            filter.type ===
            TaxiUnsettledTransferedOrderListingFilterType.START_DATE
        )?.value as
          | TaxiUnsettledTransferedOrderListingStartDateFilter["value"]
          | undefined
      )?.from?.toJSON(),
      startDateTo: (
        filters.find(
          (filter) =>
            filter.type ===
            TaxiUnsettledTransferedOrderListingFilterType.START_DATE
        )?.value as
          | TaxiUnsettledTransferedOrderListingStartDateFilter["value"]
          | undefined
      )?.to?.toJSON(),
      sort: sortKey ?? undefined,
      page,
      pageSize,
    };

  return routeQueryParams;
};

const getPage = (
  routeQueryFilterParams: TaxiUnsettledTransferedOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: TaxiUnsettledTransferedOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: TaxiUnsettledTransferedOrderListingRouteQueryFilterParams
) => {
  const filterParams: TaxiUnsettledTransferedOrderListingRouteQueryFilterParams =
    {
      producerTaxi: routeQueryFilterParams.producerTaxi,
      consumerTaxi: routeQueryFilterParams.consumerTaxi,
      driver: routeQueryFilterParams.driver,
      externalOrderId: routeQueryFilterParams.externalOrderId,
      internalOrderId: routeQueryFilterParams.internalOrderId,
      passenger: routeQueryFilterParams.passenger,
      routeAddress: routeQueryFilterParams.routeAddress,
      routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
      routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
      routePickupAddress: routeQueryFilterParams.routePickupAddress,
      startDateFrom: routeQueryFilterParams.startDateFrom,
      startDateTo: routeQueryFilterParams.startDateTo,
    };

  const validationSchema =
    Joi.object<TaxiUnsettledTransferedOrderListingRouteQueryFilterParams>({
      producerTaxi: Joi.string(),
      consumerTaxi: Joi.string(),
      driver: Joi.string(),
      externalOrderId: Joi.string(),
      internalOrderId: Joi.string(),
      passenger: Joi.string(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      startDateFrom: Joi.date(),
      startDateTo: Joi.date(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: TaxiUnsettledTransferedOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(TaxiUnsettledTransferedOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: TaxiUnsettledTransferedOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: TaxiUnsettledTransferedOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const taxiUnsettledTransferedOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiUnsettledTransferedOrderListingRouteQueryParamsService;
