import { FC, useState, useEffect, useMemo } from "react";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import cargoTranslationsHelper from "../../../../languages/cargo-translations.helper";
import { useAppContext } from "../../../../context/app.context";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import TableComponent from "../../../../common/components/table/table.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import CargoTaxiContractListingItem from "./common/types/cargo-taxi-contract-listing-item";
import CargoTaxiContractListingTableRow from "./common/types/cargo-taxi-contract-listing-table-row";
import cargoTaxiContractListingHelper from "./common/cargo-taxi-contract-listing.helper";
import useClientSidePagination from "../../../../common/hooks/use-clientside-pagination";
import { useParams } from "react-router-dom";
import CargoTaxiContractListingRouteParams from "../../common/routes/types/cargo-taxi-contract-listing-route-params";
import cargoTaxiContractListingApiService from "./common/api/cargo-taxi-contract-listing-api.service";
import CargoTaxiContractListingResponse from "./common/api/types/cargo-taxi-contract-listing.response";
import cargoTaxiContractListingFactory from "./common/factory/cargo-taxi-contract-listing.factory";
import cargoBreadcrumbsHelper from "../../common/breadcrumbs/cargo-breadcrumbs.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import dateService from "../../../../common/utils/date/date.service";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import CargoTaxiContractListingSortKey from "./common/types/cargo-taxi-contract-listing-sort-key";
import CargoTaxiContractListingFilter from "./common/types/cargo-taxi-contract-listing-filter";
import cargoTaxiContractListingRouteQueryParamsService from "./common/cargo-taxi-contract-listing-route-query-params.service";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import CargoTaxiContractListingRouteQueryParams from "./common/types/cargo-taxi-contract-listing-route-query-params";
import cargoTaxiContractListingSortService from "./common/sort/cargo-taxi-contract-listing-sort.service";
import CargoTaxiContractListingSortSelectOption from "./common/types/cargo-taxi-contract-listing-sort-select-option";
import cargoTaxiContractListingSortHelper from "./common/sort/cargo-taxi-contract-listing-sort.helper";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import CargoTaxiContractListingFiltersSelectComponent from "./filter/select/cargo-taxi-contract-listing-filters-select.component";
import cargoTaxiContractListingFilterService from "./common/filter/cargo-taxi-contract-listing-filter.service";
import CargoTaxiContractListingFiltersBadgeListComponent from "./filter/list/cargo-taxi-contract-listing-filters-badge-list.component";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import cargoRoutesHelper from "../../common/routes/cargo-routes.helper";
import useAbort from "../../../../common/hooks/use-abort";
import useCargoCompanyDetails from "../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";

type CargoTaxiContractListingProps = {};

const CargoTaxiContractListingComponent: FC<
  CargoTaxiContractListingProps
> = () => {
  const isComponentMounted = useIsComponentMounted();

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<CargoTaxiContractListingRouteQueryParams>();

  const { cargoCompanyUuid } = useParams<CargoTaxiContractListingRouteParams>();

  const translations =
    cargoTranslationsHelper.getTaxiContractListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.cargoTaxiContractListing);

  const [cargoCompanyName, setCargoCompanyName] = useState("");

  const [filters, setFilters] = useState<CargoTaxiContractListingFilter[]>(() =>
    cargoTaxiContractListingRouteQueryParamsService.getFilters(routeQueryParams)
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<CargoTaxiContractListingSortKey | null>(
      () =>
        cargoTaxiContractListingRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);

  const [listingItems, setListingItems] = useState<
    CargoTaxiContractListingItem[]
  >([]);
  const [filteredListingItems, setFilteredListingItems] = useState<
    CargoTaxiContractListingItem[]
  >([]);

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => cargoCompanyAbort.revoke();
  }, [cargoCompanyUuid]);

  useEffect(() => {
    if (!cargoCompany.data) return;

    setCargoCompanyName(cargoCompany.data.displayName);
  }, [cargoCompany.data]);

  const createTableRow = (
    listingItem: CargoTaxiContractListingItem
  ): CargoTaxiContractListingTableRow => {
    const onTableRowClick = () => {};

    return {
      id: listingItem.uuid,
      onClick: onTableRowClick,
      value: {
        taxiCorporation: (
          <div title={listingItem.taxiCorporation}>
            {listingItem.taxiCorporation}
          </div>
        ),
        validSince: (
          <div title={dateService.formatDate(listingItem.validFrom)}>
            {dateService.formatDate(listingItem.validFrom)}
          </div>
        ),
        validTo: (
          <div title={dateService.formatDate(listingItem.validTo)}>
            {dateService.formatDate(listingItem.validTo)}
          </div>
        ),
        billingModel: (
          <div title={listingItem.billingModel}>{listingItem.billingModel}</div>
        ),
        isTollRoadsWhileApproachingAllowed: (
          <div
            title={cargoTaxiContractListingHelper.getActivityStatusText(
              listingItem.isTollRoadsWhileApproachingAllowed
            )}
          >
            {listingItem.isTollRoadsWhileApproachingAllowed ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        isTollRoadsWhileReturningAllowed: (
          <div
            title={cargoTaxiContractListingHelper.getActivityStatusText(
              listingItem.isTollRoadsWhileReturningAllowed
            )}
          >
            {listingItem.isTollRoadsWhileReturningAllowed ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        isTollRoadsDuringOrderAllowed: (
          <div
            title={cargoTaxiContractListingHelper.getActivityStatusText(
              listingItem.isTollRoadsDuringOrderAllowed
            )}
          >
            {listingItem.isTollRoadsDuringOrderAllowed ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        distanceRate: (
          <div
            title={cargoTaxiContractListingHelper.getDistanceRateTitle(
              listingItem.distanceRate
            )}
          >
            {cargoTaxiContractListingHelper.getDistanceRateLabel(
              listingItem.distanceRate
            )}
          </div>
        ),
        discountPercentLimit: (
          <div
            title={cargoTaxiContractListingHelper.getDiscountPercentLimitTitle(
              listingItem.discountPercentLimit
            )}
          >
            {cargoTaxiContractListingHelper.getDiscountPercentLimitLabel(
              listingItem.discountPercentLimit
            )}
          </div>
        ),
        stoppingRate: (
          <div
            title={cargoTaxiContractListingHelper.getStoppingRateTitle(
              listingItem.stoppingRate
            )}
          >
            {cargoTaxiContractListingHelper.getStoppingRateLabel(
              listingItem.stoppingRate
            )}
          </div>
        ),
        freeStoppingPeriodMinutes: (
          <div title={listingItem.freeStoppingPeriodMinutes.toString()}>
            {listingItem.freeStoppingPeriodMinutes}
          </div>
        ),
        isOrderPublishingAllowed: (
          <div
            title={cargoTaxiContractListingHelper.getActivityStatusText(
              listingItem.isOrderPublishingAllowed
            )}
          >
            {listingItem.isOrderPublishingAllowed ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        activityStatus: (
          <div
            title={cargoTaxiContractListingHelper.getActivityStatusText(
              listingItem.isActive
            )}
          >
            {listingItem.isActive ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
      },
    };
  };

  const tableColumns = cargoTaxiContractListingHelper.getTableColumns();

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: filteredListingItems,
      totalResults: filteredListingItems.length,
      defaultPageSize: 100,
    });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      cargoTaxiContractListingRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  useEffect(() => {
    fetchListing();
  }, [cargoCompanyUuid]);

  const fetchListing = () => {
    if (!cargoCompanyUuid) {
      return;
    }

    setIsListingFetching(true);
    setIsListingFetchingError(false);

    cargoTaxiContractListingApiService
      .fetchListing(cargoCompanyUuid)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  const handleListingResponse = (
    response: CargoTaxiContractListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const onListingFetchSuccess = (
    response: CargoTaxiContractListingResponse
  ) => {
    const listingItems = cargoTaxiContractListingFactory.createListingItems(
      response.data
    );

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const filteredListingItems =
      cargoTaxiContractListingFilterService.filterListingItems(
        listingItems,
        filters
      );

    const sortedListingItems =
      cargoTaxiContractListingSortService.sortListingItems(
        filteredListingItems,
        selectedSortKey
      );

    setFilteredListingItems(sortedListingItems);
  }, [selectedSortKey, JSON.stringify(filters), JSON.stringify(listingItems)]);

  useEffect(() => {
    if (!cargoCompanyUuid) {
      return;
    }

    const breadcrumbs =
      cargoBreadcrumbsHelper.getTaxiContractListingBreadcrumbs({
        cargoCompanyUuid,
        cargoCompanyName,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoCompanyUuid, cargoCompanyName]);

  const addNewFilter = (newFilter: CargoTaxiContractListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filterItem) => {
      return filters.indexOf(filterItem) !== index;
    });

    setFilters(newFilters);
  };

  const deleteAllFilters = () => {
    setFilters([]);
  };

  const sortSelectOptions: CargoTaxiContractListingSortSelectOption[] = useMemo(
    () => cargoTaxiContractListingSortHelper.getSelectOptions(),
    [selectedAppLanguage]
  );

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const tableRows: CargoTaxiContractListingTableRow[] = useMemo(() => {
    return pageData.map((listingItem) => createTableRow(listingItem));
  }, [pageData]);

  const AddNewContractLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={cargoRoutesHelper.getTaxiContractAddRoute({
          cargoCompanyUuid: cargoCompanyUuid!,
        })}
        title={translations.header.addContractLinkButtonTitle}
        idForTesting="cargo-taxi-contract-listing-add"
      >
        {translations.header.addContractLinkButtonText}
      </LinkButtonComponent>
    ),
    [translations]
  );

  return (
    <div className="cargo_taxi_contract_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[AddNewContractLinkButton]}
      />
      <div className="driver_contract_listing_tools">
        <CargoTaxiContractListingFiltersSelectComponent
          filters={filters}
          onAddNewFilter={addNewFilter}
        />
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="cargo-taxi-contract-listing-sort"
        />
      </div>
      <CargoTaxiContractListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <TableComponent
          columns={tableColumns}
          rows={tableRows}
          isLoading={isListingFetching}
          isError={isListingFetchingError}
        />
        <div className="cargo_taxi_contract_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={setPageSize}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default CargoTaxiContractListingComponent;
