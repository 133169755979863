import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import CargoOrderSettlementListingSortKey from "./types/cargo-order-settlement-listing-sort-key";
import CargoOrderSettlementListingSortSelectOption from "./types/cargo-order-settlement-listing-sort-select-option";

const getSelectOptions = (): CargoOrderSettlementListingSortSelectOption[] => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .sort;

  return [
    {
      label: translations.orderStartDateAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.ORDER_START_DATE_ASC,
    },
    {
      label: translations.orderStartDateDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.ORDER_START_DATE_DESC,
    },
    {
      label: translations.distanceAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      label: translations.distanceDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      label: translations.tollRoadsCostAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      label: translations.tollRoadsCostDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      label: translations.internalOrderIdAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      label: translations.internalOrderIdDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      label: translations.taxiCorporationAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.TAXI_CORPORATION_ASC,
    },
    {
      label: translations.taxiCorporationDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.TAXI_CORPORATION_DESC,
    },
    {
      label: translations.externalOrderIdAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.EXTERNAL_ORDER_ID_ASC,
    },
    {
      label: translations.externalOrderIdDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.EXTERNAL_ORDER_ID_DESC,
    },
    {
      label: translations.dispatchAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISPATCH_ASC,
    },
    {
      label: translations.dispatchDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISPATCH_DESC,
    },
    {
      label: translations.baseDistanceRateAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.baseDistanceRateDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      label: translations.amountForDistanceAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISTANCE_COST_ASC,
    },
    {
      label: translations.amountForDistanceDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISTANCE_COST_DESC,
    },
    {
      label: translations.stopoverTimeAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.STOPOVER_TIME_ASC,
    },
    {
      label: translations.stopoverTimeDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.STOPOVER_TIME_DESC,
    },
    {
      label: translations.stopoverCostAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.STOPOVER_COST_ASC,
    },
    {
      label: translations.stopoverCostDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.STOPOVER_COST_DESC,
    },
    {
      label: translations.discountAmountAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISCOUNT_AMOUNT_ASC,
    },
    {
      label: translations.discountAmountDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISCOUNT_AMOUNT_DESC,
    },
    {
      label: translations.sumOfDiscountsAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISCOUNT_SAVINGS_ASC,
    },
    {
      label: translations.sumOfDiscountsDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.DISCOUNT_SAVINGS_DESC,
    },
    {
      label: translations.totalCostAscOptionLabel,
      value: CargoOrderSettlementListingSortKey.TOTAL_COST_ASC,
    },
    {
      label: translations.totalCostDescOptionLabel,
      value: CargoOrderSettlementListingSortKey.TOTAL_COST_DESC,
    },
  ];
};

const cargoOrderSettlementListingSortHelper = {
  getSelectOptions,
};

export default cargoOrderSettlementListingSortHelper;
