import taxiRouteSettlementListingRouteQueryParamsService from "../../common/taxi-route-settlement-listing-route-query-params.service";
import TaxiRouteSettlementListingFilter from "../../common/types/taxi-route-settlement-listing-filter";
import TaxiRouteSettlementListingSortKey from "../../common/types/taxi-route-settlement-listing-sort-key";
import TaxiRouteSettlementListingByRailyRouteQueryParams from "./types/taxi-route-settlement-listing-by-raily-route-query-params";
import TaxiRouteSettlementListingByRailyTaxiCorporation from "./types/taxi-route-settlement-listing-by-raily-taxi-corporation-company";
import Joi from "joi";

const createRouteQueryParams = (
  taxiCorporationUuid:
    | TaxiRouteSettlementListingByRailyTaxiCorporation["uuid"]
    | null,
  filters: TaxiRouteSettlementListingFilter[],
  sortKey: TaxiRouteSettlementListingSortKey | null,
  page: number,
  pageSize: number
): TaxiRouteSettlementListingByRailyRouteQueryParams => {
  const commonParams =
    taxiRouteSettlementListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return {
    ...commonParams,
    taxiCorporation: taxiCorporationUuid ?? undefined,
  };
};

const getTaxiCorporationUuid = (
  routeQueryParams: TaxiRouteSettlementListingByRailyRouteQueryParams
): string | undefined => {
  if (!validateTaxiCorporationUuid(routeQueryParams.taxiCorporation)) {
    return undefined;
  }

  return routeQueryParams.taxiCorporation;
};

const validateTaxiCorporationUuid = (
  routeQueryTaxiCorporationParam: TaxiRouteSettlementListingByRailyRouteQueryParams["taxiCorporation"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryTaxiCorporationParam).error
    ?.message;
};

const taxiRouteSettlementListingByRailyRouteQueryParamsService = {
  getFilters: taxiRouteSettlementListingRouteQueryParamsService.getFilters,
  getSortKey: taxiRouteSettlementListingRouteQueryParamsService.getSortKey,
  getPage: taxiRouteSettlementListingRouteQueryParamsService.getPage,
  getPageSize: taxiRouteSettlementListingRouteQueryParamsService.getPageSize,
  getTaxiCorporationUuid,
  createRouteQueryParams,
};

export default taxiRouteSettlementListingByRailyRouteQueryParamsService;
