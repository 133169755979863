export enum TaxiUnsettledRouteListingRequestOrder {
  START_TIME_DESC = "START_TIME_DESC",
  START_TIME_ASC = "START_TIME_ASC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  DRIVER_ASC = "DRIVER_ASC",
  DRIVER_DESC = "DRIVER_DESC",
}

type TaxiUnsettledRouteListingRequest = {
  limit: number;
  offset: number;
  order: TaxiUnsettledRouteListingRequestOrder | undefined;
  human_id: string | undefined;
  start_time_since: string | undefined;
  start_time_to: string | undefined;
  driver: string | undefined;
  client: string | undefined;
  external_id: string | undefined;
  address: string | undefined;
  end_address: string | undefined;
  mid_address: string | undefined;
  start_address: string | undefined;
  cargo_order_human_id: number | undefined;
  fleet_partner: string | undefined;
};

export default TaxiUnsettledRouteListingRequest;
