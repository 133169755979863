import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import RelatedRailyTaxiBillingsTableColumn from "./types/related-raily-taxi-billings-table-column";

const getTableColumns = (): RelatedRailyTaxiBillingsTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getTaxiRailyAddBillingsTranslations()
      .relatedBillings.raily_taxi.table;

  const tableColumns: RelatedRailyTaxiBillingsTableColumn[] = [
    {
      header: translations.headers.dateLabel,
      title: translations.headers.dateTitle,
      accessor: "date",
      colSpan: 5,
    },
    {
      header: translations.headers.internalOrderIdLabel,
      title: translations.headers.internalOrderIdTitle,
      accessor: "internalOrderId",
      colSpan: 5,
    },
    {
      header: translations.headers.companyNameLabel,
      title: translations.headers.companyNameTitle,
      accessor: "companyName",
      colSpan: 10,
    },
    {
      header: translations.headers.modelLabel,
      title: translations.headers.modelTitle,
      accessor: "model",
      colSpan: 5,
    },
    {
      header: translations.headers.rateLabel,
      title: translations.headers.rateTitle,
      accessor: "rate",
      colSpan: 5,
    },
    {
      header: translations.headers.distanceLabel,
      title: translations.headers.distanceTitle,
      accessor: "distance",
      colSpan: 5,
    },
    {
      header: translations.headers.routeDistanceCostLabel,
      title: translations.headers.routeDistanceCostTitle,
      accessor: "routeDistanceCost",
      colSpan: 5,
    },
    {
      header: translations.headers.bonusLabel,
      title: translations.headers.bonusTitle,
      accessor: "bonus",
      colSpan: 5,
    },
    {
      header: translations.headers.penaltyLabel,
      title: translations.headers.penaltyTitle,
      accessor: "penalty",
      colSpan: 5,
    },
    {
      header: translations.headers.haltingCostLabel,
      title: translations.headers.haltingCostTitle,
      accessor: "haltingCost",
      colSpan: 5,
    },
    {
      header: translations.headers.highwayCostLabel,
      title: translations.headers.highwayCostTitle,
      accessor: "highwayCost",
      colSpan: 5,
    },
    {
      header: translations.headers.totalLabel,
      title: translations.headers.totalTitle,
      accessor: "total",
      colSpan: 5,
    },
    {
      header: translations.headers.actions,
      title: translations.headers.actions,
      accessor: "actions",
      colSpan: 5,
    },
  ];

  return tableColumns;
};

const relatedOrdersBillingsHelper = {
  getTableColumns,
};

export default relatedOrdersBillingsHelper;
