import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";

const validateName = (name: string): FormValidationResult => {
  const validationSchema = Joi.string().trim().min(1).max(100).required();

  return formValidationService.validate(name, validationSchema);
};

const validateCompanyId = (companyId: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").trim().min(2).max(14);

  return formValidationService.validate(companyId, validationSchema);
};

const validateTaxNumber = (taxNumber: string): FormValidationResult => {
  const validationSchema = Joi.string().trim().min(2).max(10).required();

  return formValidationService.validate(taxNumber, validationSchema);
};

const validateNationalCourtRegister = (
  nationalCourtRegister: string
): FormValidationResult => {
  const validationSchema = Joi.string().trim().allow("").min(2).max(10);

  return formValidationService.validate(
    nationalCourtRegister,
    validationSchema
  );
};

const validateAddressStreet = (street: string): FormValidationResult => {
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(street, validationSchema);
};

const validateAddressHouseNumber = (
  houseNumber: string
): FormValidationResult => {
  const validationSchema = Joi.string().trim().min(1).max(10).required();

  return formValidationService.validate(houseNumber, validationSchema);
};

const validateAddressApartmentNumber = (
  apartmentNumber: string
): FormValidationResult => {
  const validationSchema = Joi.string().trim().allow("").max(10).required();

  return formValidationService.validate(apartmentNumber, validationSchema);
};

const validateAddressTown = (town: string): FormValidationResult => {
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(town, validationSchema);
};

const validateAddressCountry = (country: string): FormValidationResult => {
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(country, validationSchema);
};

const validateAddressZipCode = (zipCode: string): FormValidationResult => {
  const pattern = new RegExp(`^[0-9\\-]*$`);
  const validationSchema = Joi.string()
    .trim()
    .min(2)
    .max(10)
    .pattern(pattern)
    .required();

  return formValidationService.validate(zipCode, validationSchema);
};

const validateAddressDescription = (
  description: string
): FormValidationResult => {
  const validationSchema = Joi.string()
    .trim()
    .allow("")
    .min(1)
    .max(100)
    .required();

  return formValidationService.validate(description, validationSchema);
};

const driverEditCompanyDataFormValidationService = {
  validateName,
  validateCompanyId,
  validateTaxNumber,
  validateNationalCourtRegister,
  validateAddressStreet,
  validateAddressHouseNumber,
  validateAddressApartmentNumber,
  validateAddressTown,
  validateAddressCountry,
  validateAddressZipCode,
  validateAddressDescription,
};

export default driverEditCompanyDataFormValidationService;
