export enum CargoCompanyContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type CargoCompanyListItem = {
  uuid: string;
  taxNumber: string;
  companyId: string | null;
  nationalCourtRegister: string | null;
  name: string;
  displayName: string;
  address: string;
  contractMaintenancePolicy: CargoCompanyContractMaintenancePolicy;
  notes: string | null;
  modificationDate: Date | null;
  modifiedBy: string | null;
  creationDate: Date;
  createdBy: string;
};

type CargoCompanyList = {
  data: CargoCompanyListItem[];
};

export default CargoCompanyList;
