export enum TaxiDriverContractAddRequestBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type TaxiDriverContractAddRequestBody = {
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: TaxiDriverContractAddRequestBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  taxiDriverAssociationId: string;
};

type TaxiDriverContractAddRequest = {
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: TaxiDriverContractAddRequestBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  taxiDriverAssociationId: string;
};

export default TaxiDriverContractAddRequest;
