import CargoAddressType from "../../../common/types/cargo-address-type";
import {
  CargoAddressListingResponseItem,
  CargoAddressListingResponseItemType,
} from "../api/cargo-address-listing.response";
import CargoAddressListingItem from "../types/cargo-address-listing-item";

const createListingItemType = (
  responseDataItemType: CargoAddressListingResponseItemType
): CargoAddressType => {
  switch (responseDataItemType) {
    case CargoAddressListingResponseItemType.HOTEL:
      return CargoAddressType.HOTEL;
    case CargoAddressListingResponseItemType.MEETING_POINT:
      return CargoAddressType.MEETING_POINT;
    case CargoAddressListingResponseItemType.STATION:
      return CargoAddressType.STATION;
  }
};

const createListingItem = (
  responseDataItem: CargoAddressListingResponseItem
): CargoAddressListingItem => {
  return {
    uuid: responseDataItem.id,
    description: responseDataItem.description ?? "",
    country: responseDataItem.country ?? "",
    town: responseDataItem.town ?? "",
    zipCode: responseDataItem.zip_code ?? "",
    street: responseDataItem.street ?? "",
    houseApartmentNumber: responseDataItem.apartment
      ? `${responseDataItem.house_no}/${responseDataItem.apartment}`
      : responseDataItem.house_no
      ? `${responseDataItem.house_no}`
      : "",
    geoCoordinates: `${responseDataItem.lat};${responseDataItem.lon}`,
    type: createListingItemType(responseDataItem.type),
  };
};

const createListingItems = (
  responseDataItems: CargoAddressListingResponseItem[]
): CargoAddressListingItem[] => {
  const result = responseDataItems.map(createListingItem);
  return result;
};

const cargoAddressListingFactory = {
  createListingItems,
};

export default cargoAddressListingFactory;
