import { FC } from "react";
import TableComponent from "../../../../../common/components/table/table.component";
import DriverListingTableColumn from "../common/types/driver-listing-table-column";
import DriverListingTableRow from "../common/types/driver-listing-table-row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faFileSignature,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import userHelper from "../../../../../common/utils/user/user.helper";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import UserStatus from "../../../../../common/types/user-status";
import TableDeleteButtonComponent from "../../../../../common/components/table/button/delete/table-delete-button.component";
import driverListingHelper from "../common/driver-listing.helper";
import DriverListingItem from "../common/types/driver-listing-item";
import driverListingTableHelper from "./driver-listing-table.helper";
import DriverDetailsActiveRoutePlansComponent from "../../details/active-route-plans/driver-details-active-route-plans.component";
import useDriverListingUserPermissions from "../common/user-permissions/use-driver-listing-user-permissions";
import UserDeleteUserData from "../../../common/delete/common/types/user-delete-user-data";
import TableRestoreButtonComponent from "../../../../../common/components/table/button/restore/table-restore-button.component";
import UserRestoreUserData from "../../../common/restore/types/user-restore-user-data";

type DriverListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: DriverListingItem[];
  onSelectedDriverUuidsChange: (expandedRowsUuids: string[]) => void;
  selectedDriverUuids: string[];
  onSelectUserToDelete: (userData: UserDeleteUserData) => void;
  onSelectUserToRestore: (userData: UserRestoreUserData) => void;
};

const DriverListingTableComponent: FC<DriverListingTableProps> = (props) => {
  const userPermissions = useDriverListingUserPermissions();

  const columns: DriverListingTableColumn[] =
    driverListingTableHelper.getColumns();

  const expandRow = (uuid: DriverListingItem["uuid"]) => {
    props.onSelectedDriverUuidsChange([...props.selectedDriverUuids, uuid]);
  };

  const collapseRow = (uuid: DriverListingItem["uuid"]) => {
    const filteredExpandedRows = props.selectedDriverUuids.filter(
      (expandedRowUuid) => expandedRowUuid !== uuid
    );

    props.onSelectedDriverUuidsChange(filteredExpandedRows);
  };

  const createTableRow = (
    listingItem: DriverListingItem
  ): DriverListingTableRow => {
    const isRowExpanded = props.selectedDriverUuids.includes(listingItem.uuid);

    const userData = {
      uuid: listingItem.uuid,
      firstName: listingItem.firstName,
      lastName: listingItem.lastName,
    };

    const onRowClick = () => {
      if (!userPermissions.hasAccessToDetails) {
        return;
      }

      if (isRowExpanded) {
        collapseRow(listingItem.uuid);
        return;
      }

      expandRow(listingItem.uuid);
    };

    const translations =
      userTranslationsHelper.getDriverListingTranslations().table;

    const activityStatus = driverListingTableHelper.getStatusText(
      listingItem.status
    );

    const isDeleted = listingItem.status === UserStatus.DELETED;

    const isContractListingButtonVisible =
      !isDeleted && userPermissions.hasAccessToTaxiContractListing;

    const isEditButtonVisible =
      !isDeleted && userPermissions.hasAccessToEditDriver;

    const isDeleteButtonVisible =
      !isDeleted && userPermissions.hasAccessToDeleteUser;

    const isRestoreButtonVisibile =
      isDeleted && userPermissions.hasAccessToRestoreUser;

    return {
      id: listingItem.uuid,
      idForTesting: listingItem.uuid,
      onClick: onRowClick,
      value: {
        firstName: (
          <div title={listingItem.firstName}>{listingItem.firstName}</div>
        ),
        lastName: (
          <div title={listingItem.lastName}>{listingItem.lastName}</div>
        ),
        taxiCorporations: listingItem.taxiCorporations.map(
          (taxiCorporation, index) => (
            <div key={index} title={taxiCorporation}>
              {taxiCorporation}
            </div>
          )
        ),
        startingAddress: (
          <div title={listingItem.startingAddress}>
            {listingItem.startingAddress}
          </div>
        ),
        phoneNumbers: (
          <div>
            {listingItem.phoneNumbers.map((phoneNumber, index) => (
              <div key={index} title={phoneNumber}>
                {phoneNumber}
              </div>
            ))}
          </div>
        ),
        vehicle: (
          <div>
            <div>{`${listingItem.vehicle.make} ${listingItem.vehicle.model}`}</div>
            <div>{`${translations.vehicleAgeTemplateLabel.replace(
              "#{vehicleAge}",
              String(listingItem.vehicle.age)
            )} - ${
              listingItem.vehicle.ownership
                ? userHelper.getVehicleOwnershipText(
                    listingItem.vehicle.ownership
                  )
                : ""
            }`}</div>
          </div>
        ),
        fleetPartners: (
          <div>
            {listingItem.fleetPartners.map((fleetPartner, index) => (
              <div key={index} title={fleetPartner}>
                {fleetPartner}
              </div>
            ))}
          </div>
        ),
        created: (
          <div
            title={`${driverListingHelper.getDateLabel(
              listingItem.creationDate
            )} ${listingItem.createdByName ?? ""}`}
          >
            {`${driverListingHelper.getDateLabel(listingItem.creationDate)} ${
              listingItem.createdByName ?? ""
            }`}
          </div>
        ),
        modified: (
          <div
            title={`${driverListingHelper.getDateLabel(
              listingItem.modificationDate
            )} ${listingItem.modifiedByName ?? ""}`}
          >
            {`${driverListingHelper.getDateLabel(
              listingItem.modificationDate
            )} ${listingItem.modifiedByName ?? ""}`}
          </div>
        ),
        activeCandidature: (
          <div
            title={driverListingHelper.getCandidatureActivityText(
              listingItem.isCandidatureActive
            )}
          >
            {listingItem.isCandidatureActive ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        status: <div title={activityStatus}>{activityStatus}</div>,
        actions: (
          <div className="d-flex" onClick={(event) => event.stopPropagation()}>
            {isContractListingButtonVisible && (
              <TableLinkButtonComponent
                title={translations.contractListingButtonTitle}
                icon={faFileSignature}
                to={userRoutesHelper.getDriverContractListingRoute({
                  driverUuid: listingItem.uuid,
                })}
                idForTesting={`driver-listing-table-item-${listingItem.uuid}-contract-button`}
              />
            )}
            {isEditButtonVisible && (
              <TableLinkButtonComponent
                icon={faEdit}
                to={userRoutesHelper.getDriverEditRoute({
                  driverUuid: listingItem.uuid,
                })}
                title={translations.driverEditButtonTitle}
                idForTesting={`driver-listing-table-item-${listingItem.uuid}-edit-button`}
              />
            )}
            {isDeleteButtonVisible && (
              <TableDeleteButtonComponent
                onClick={() => props.onSelectUserToDelete(userData)}
                title={translations.driverDeleteButtonTitle}
                idForTesting={`driver-listing-table-item-${listingItem.uuid}-delete-button`}
              />
            )}
            {isRestoreButtonVisibile && (
              <TableRestoreButtonComponent
                onClick={() => props.onSelectUserToRestore(userData)}
                title={translations.driverRestoreButtonTitle}
                idForTesting={`driver-listing-table-item-${listingItem.uuid}-resotre-button`}
              />
            )}
          </div>
        ),
      },
      hiddenComponent: userPermissions.hasAccessToDetails && (
        <DriverDetailsActiveRoutePlansComponent
          driverUuid={listingItem.uuid}
          isVisible={isRowExpanded}
        />
      ),
      isHiddenComponentVisible: isRowExpanded,
    };
  };

  const rows: DriverListingTableRow[] = props.listingItems.map((listingItem) =>
    createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default DriverListingTableComponent;