export enum MileageListStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DELEGATION_CREATED = "DELEGATION_CREATED",
}

export enum MileageListVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export type MileageListAddressSequenceNode = {
  lat: number;
  lon: number;
  displayName: string;
  zipCode?: string;
  town?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  time?: Date;
};

export type MileageListAddressSequence = {
  seq: MileageListAddressSequenceNode[];
};

export type MileageListItem = {
  uuid: string;
  createdAt: Date;
  createdBy: string;
  modificationDate?: Date | null;
  modifiedBy?: string | null;
  workerUuid: string;
  workerCompanyUuid: string;
  mileageDate: Date;
  cardNumber?: string | null;
  commissionNumber?: string | null;
  cargoCompanyName: string;
  mileageNumber?: string | null;
  notes?: string | null;
  status: MileageListStatus;
  vehicleType: MileageListVehicleType;
  distance: number;
  mileageCost: number;
  delegationUuid?: string | null;
  hasDelegation: boolean;
  humanId: number;
  workerName: string;
  workerPhone: string;
  addressSeq: MileageListAddressSequence;
  startAddressLabel: string;
  midAddressesLabels: string[];
  endAddressLabel: string;
  osrmDistance: number;
  acceptedDistance?: number;
};

type MileageList = {
  data: MileageListItem[];
  totalCount: number;
};

export default MileageList;
