import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import { MileageUpdateAddressRouteItem } from "./mileage-update-address-routes.types";

const getNewEmptyRoute = (): MileageUpdateAddressRouteItem => {
  return {
    uuid: uuidService.generate(),
    address: null,
  };
};

const mileageUpdateAddressRoutesHelper = {
  getNewEmptyRoute,
};

export default mileageUpdateAddressRoutesHelper;
