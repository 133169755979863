import useAsyncData from "../../../../hooks/use-async-data";
import cargoCompanyAddressService from "../cargo-company-address.service";
import CargoCompanyAddressList from "./cargo-company-address-list";
import CargoCompanyAddressListLoadParams from "./cargo-company-address-list-load-params";

const useCargoCompanyAddressList = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<CargoCompanyAddressList>([]);

  const load = async (
    params: CargoCompanyAddressListLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoCompanyAddressList = await cargoCompanyAddressService.getList(
        params,
        signal
      );

      setData(cargoCompanyAddressList);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useCargoCompanyAddressList;
