import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import MileageAddBasicProps from "../common/types/mileage-add-basic-props";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import { useAppContext } from "../../../../context/app.context";
import mileageBreadcrumbsHelper from "../../common/breadcrumbs/mileage-breadcrumbs.helper";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import MileageAddWorkerComponent from "../common/components/worker/mileage-add-worker-select.component";
import DateTimeInputComponent from "../../../../common/components/form/input/date-time/date-time-input.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import MileageAddAddress from "../common/types/mileage-add-address";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import { MileageAddRouteItem } from "../common/components/address/mileage-add-routes.types";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageAddRoutesComponent from "../common/components/address/mileage-add-routes.component";
import useForm from "../../../../common/components/form/use-form";
import mileageAddFormHelper from "../common/form/mileage-add-form.helper";
import useAbort from "../../../../common/hooks/use-abort";
import MileageAddSummaryComponent from "../common/components/summary/mileage-add-summary.component";
import useCargoCompanyDetails from "../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";
import CargoCompanyDetailsParams from "../../../../common/services/cargo-company/cargo-company/details/cargo-company-details-params";
import MileageAddWorker from "../common/types/mileage-add-worker";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import mileageAddAddressFactory from "../common/factory/mileage-add-address.factory";
import MileageSettingsDetailsLoadParams from "../../../../common/services/mileage-settings/details/mileage-settings-details-load-params";
import useMileageSettingsDetails from "../../../../common/services/mileage-settings/details/use-mileage-settings-details";
import MileageAddMileageNumberDictionaryInputComponent from "../common/components/mileage-number-dictionary-input/mileage-add-mileage-number-dictionary-input.component";
import { MileageAddFormSettingsMileageNumberCreationModel } from "../common/form/types/mileage-add-form-settings";
import mileageAddFormFactory from "../common/form/mileage-add-form.factory";
import MileageAddVehicleTypeSelectComponent from "../common/components/vehicle-type-select/mileage-add-vehicle-type-select.component";
import { round } from "lodash";
import useCargoCompanyMileageContractDetails from "../../../../common/services/cargo-company/mileage-contract/details/use-cargo-company-mileage-contract-details";
import CargoCompanyMileageContractDetailsLoadParams from "../../../../common/services/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details-load-params";

type CargoMileageAddProps = MileageAddBasicProps;

const CargoMileageAddComponent: FC<CargoMileageAddProps> = (props) => {
  const { user, setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const translations = mileageTranslationsHelper.getMileageAddTranslations();

  useEffect(() => {
    const breadcrumbs = mileageBreadcrumbsHelper.getMileageAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageAdd);

  const [worker, setWorker] = useState<MileageAddWorker | null>(null);
  const [companyName, setCompanyName] = useState<string>("");
  const [routesChanged, setRoutesChanged] = useState(false);

  const cargoCompanyDetails = useCargoCompanyDetails();
  const cargoCompanyDetailsAbort = useAbort();

  const mileageSettingsDetails = useMileageSettingsDetails();
  const mileageSettingsDetailsAbort = useAbort();

  const cargoCompanyMileageContractDetails =
    useCargoCompanyMileageContractDetails();
  const cargoCompanyMileageContractDetailsAbort = useAbort();

  const isComponentMounted = useIsComponentMounted();

  const form = useForm({
    emptyValues: mileageAddFormHelper.getDefaultFormData(),
    validationDefinition: mileageAddFormHelper.getValidationDefinition(),
  });

  const loadMileageSettingsDetails = (cargoCompanyUuid: string) => {
    const params: MileageSettingsDetailsLoadParams = {
      cargoCompanyUuid: cargoCompanyUuid,
    };

    mileageSettingsDetails.load(params, mileageSettingsDetailsAbort.signal);
  };

  const loadCargoMileageContract = (
    cargoCompanyUuid: string,
    validityDate?: Date
  ) => {
    const loadParams: CargoCompanyMileageContractDetailsLoadParams = {
      cargoCompanyUuid: cargoCompanyUuid,
      validAt: validityDate,
    };

    cargoCompanyMileageContractDetails.load(
      loadParams,
      cargoCompanyMileageContractDetailsAbort.signal
    );
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const cargoCompanyUuid =
      user?.aspects.cargoOfficer?.cargoCompanyUuid ??
      user?.aspects.dispatcher?.cargoCompanyUuid;

    if (!cargoCompanyUuid) {
      return;
    }

    const params: CargoCompanyDetailsParams = {
      cargoCompanyUuid: cargoCompanyUuid,
    };

    cargoCompanyDetails.load(params, cargoCompanyDetailsAbort.signal);

    return () => cargoCompanyDetailsAbort.revoke();
  }, [isComponentMounted]);

  useEffect(() => {
    if (!form.values.companyUuid) {
      return;
    }

    loadMileageSettingsDetails(form.values.companyUuid);

    return mileageSettingsDetailsAbort.revoke;
  }, [form.values.companyUuid]);

  useEffect(() => {
    if (!form.values.companyUuid) return;

    loadCargoMileageContract(
      form.values.companyUuid,
      form.values.mileageDate ?? undefined
    );
  }, [form.values.companyUuid, form.values.mileageDate]);

  useEffect(() => {
    form.setValue(
      "settings",
      mileageAddFormFactory.createSettings(mileageSettingsDetails.data)
    );
  }, [mileageSettingsDetails.data]);

  useEffect(() => {
    if (!cargoCompanyDetails.data) {
      return;
    }

    form.setValue("companyUuid", cargoCompanyDetails.data.uuid);
    setCompanyName(cargoCompanyDetails.data.displayName);
  }, [cargoCompanyDetails.data]);

  const [mileageAddRoutes, setMileageAddRoutes] = useState<
    MileageAddRouteItem[]
  >([]);

  const restoreForm = () => {
    setWorker(null);
    setCompanyName("");
    form.restore();
  };

  const onRoutesChange = (routeItems: MileageAddRouteItem[]) => {
    setMileageAddRoutes(routeItems);

    const seq: MileageAddAddress[] =
      mileageAddAddressFactory.createFromRouteItems(routeItems);

    form.setValue("addressSequence", seq);

    setRoutesChanged(true);
  };

  const onWorkerChange = (worker: MileageAddWorker | null) => {
    setWorker(worker);
    setMileageAddRoutes([]);

    form.setValue("workerUuid", worker?.uuid ?? "");
    form.setValue("addressSequence", []);
  };

  const passengerAddresses = useMemo(() => {
    return mileageAddAddressFactory.createPassengerAddresses(worker);
  }, [worker, selectedAppLanguage]);

  useEffect(() => {
    form.validate("addressSequence");
  }, [mileageAddRoutes]);

  const manualInputComponent = (
    <InputComponent
      value={form.values.mileageNumber}
      onChange={(value) => {
        form.setValue("mileageNumber", value);
      }}
      onBlur={() => {
        form.validate("mileageNumber");
      }}
      idForTesting="raily-mileage-add-mileage-number-manual"
      placeholder={translations.mileageNumber.selectPlaceholder}
      hasError={!!form.validationResults.mileageNumber.errorMessage}
    />
  );

  const autoInputComponent = (
    <InputComponent
      value={undefined}
      idForTesting="raily-mileage-add-mileage-number-auto"
      placeholder={translations.mileageNumber.autoInputPlaceholder}
      isDisabled
    />
  );

  const dictionaryInputComponent = (
    <MileageAddMileageNumberDictionaryInputComponent
      cargoCompanyUuid={form.values.companyUuid}
      value={form.values.mileageNumber}
      onChange={(value) => {
        form.setValue("mileageNumber", value);
      }}
      onBlur={() => {
        form.validate("mileageNumber");
      }}
      idForTesting="raily-mileage-add-mileage-number-manual"
      placeholder={translations.mileageNumber.dictionaryInputPlaceholder}
      hasError={!!form.validationResults.mileageNumber.errorMessage}
      isClearable={!form.values.settings.isMileageNumberRequired}
    />
  );

  const mileageNumberInputComponent = useMemo((): ReactElement => {
    switch (form.values.settings?.mileageNumberCreationModel) {
      case MileageAddFormSettingsMileageNumberCreationModel.AUTO:
        return autoInputComponent;
      case MileageAddFormSettingsMileageNumberCreationModel.DICT:
        return dictionaryInputComponent;
      default:
        return manualInputComponent;
    }
  }, [
    form.values.settings,
    form.values.mileageNumber,
    form.validationResults.mileageNumber,
    selectedAppLanguage,
    manualInputComponent,
    autoInputComponent,
    dictionaryInputComponent,
  ]);

  const onRouteFetched = (value: number) => {
    if (routesChanged) {
      form.setValue("distance", value);
      form.setValue("osrmDistance", value);
    }
  };

  return (
    <>
      <div className="mileage_add">
        <HeadingComponent
          title={translations.header.headingLabel}
          actions={props.changeViewButtons}
        />
        <Row>
          <Column xl={7}>
            <CardComponent>
              <Row>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.workers.headingLabel}
                    isRequired
                    errorMessage={
                      form.validationResults.workerUuid.errorMessage
                    }
                  >
                    <MileageAddWorkerComponent
                      worker={worker}
                      onWorkerChange={onWorkerChange}
                      onBlur={() => {
                        form.validate("workerUuid");
                      }}
                      idForTesting="cargo-mileage-add-worker"
                      workerCompanyUuid={form.values.companyUuid}
                      hasError={
                        !!form.validationResults.workerUuid.errorMessage &&
                        form.values.companyUuid !== null
                      }
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.date.headingLabel}
                    isRequired
                    errorMessage={
                      form.validationResults.mileageDate.errorMessage
                    }
                  >
                    <DateTimeInputComponent
                      date={form.values.mileageDate}
                      onChange={(value) => {
                        form.setValue("mileageDate", value);
                      }}
                      onBlur={() => {
                        form.validate("mileageDate");
                      }}
                      idForTesting="cargo-mileage-add-mileage-date"
                      placeholder={translations.date.selectPlaceholder}
                      hasError={
                        !!form.validationResults.mileageDate.errorMessage
                      }
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={12}>
                  <FormFieldComponent
                    label={translations.routes.headingLabel}
                    isRequired
                    errorMessage={
                      form.validationResults.addressSequence.errorMessage
                    }
                  >
                    <MileageAddRoutesComponent
                      cargoCompanyUuid={form.values.companyUuid}
                      isAddButtonDisabled={
                        !form.values.companyUuid || !form.values.workerUuid
                      }
                      additionalAddresses={passengerAddresses}
                      routes={mileageAddRoutes}
                      onRoutesChange={onRoutesChange}
                      idForTesting="cargo-mileage-add-routes"
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column>
                  <FormFieldComponent
                    label={translations.vehicleType.headingLabel}
                    errorMessage={
                      form.validationResults.vehicleType.errorMessage
                    }
                  >
                    <MileageAddVehicleTypeSelectComponent
                      vehicleType={form.values.vehicleType}
                      onVehicleTypeChange={(type) => {
                        form.setValue("vehicleType", type);
                      }}
                      onBlur={() => {
                        form.validate("vehicleType");
                      }}
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.cardNumber.headingLabel}
                    isRequired={
                      !form.values.settings ||
                      form.values.settings.isCardNumberRequired
                    }
                    errorMessage={
                      form.validationResults.cardNumber.errorMessage
                    }
                  >
                    <InputComponent
                      value={form.values.cardNumber}
                      onChange={(value) => {
                        form.setValue("cardNumber", value);
                      }}
                      onBlur={() => {
                        form.validate("cardNumber");
                      }}
                      idForTesting="cargo-mileage-add-card-number"
                      placeholder={translations.cardNumber.selectPlaceholder}
                      hasError={
                        !!form.validationResults.cardNumber.errorMessage
                      }
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.mileageNumber.headingLabel}
                    isRequired={
                      !form.values.settings ||
                      form.values.settings.isMileageNumberRequired
                    }
                    errorMessage={
                      form.validationResults.mileageNumber.errorMessage
                    }
                  >
                    {mileageNumberInputComponent}
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.distance.headingLabel}
                    isRequired
                    errorMessage={form.validationResults.distance.errorMessage}
                  >
                    <NumericInputComponent
                      value={round(form.values.distance)}
                      onChange={(value) => {
                        form.setValue("distance", value);
                      }}
                      onBlur={() => {
                        form.validate("distance");
                      }}
                      idForTesting="cargo-mileage-add-distance"
                      isIntegerOnly
                      placeholder={translations.distance.selectPlaceholder}
                      hasError={!!form.validationResults.distance.errorMessage}
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.commissionNumber.headingLabel}
                    errorMessage={
                      form.validationResults.commissionNumber.errorMessage
                    }
                  >
                    <InputComponent
                      value={form.values.commissionNumber}
                      onChange={(value) => {
                        form.setValue("commissionNumber", value);
                      }}
                      onBlur={() => {
                        form.validate("commissionNumber");
                      }}
                      idForTesting="cargo-mileage-add-commission-number"
                      placeholder={
                        translations.commissionNumber.selectPlaceholder
                      }
                      hasError={
                        !!form.validationResults.commissionNumber.errorMessage
                      }
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={12}>
                  <FormFieldComponent
                    label={translations.notes.headingLabel}
                    errorMessage={form.validationResults.notes.errorMessage}
                  >
                    <InputComponent
                      value={form.values.notes}
                      onChange={(value) => {
                        form.setValue("notes", value);
                      }}
                      onBlur={() => {
                        form.validate("notes");
                      }}
                      idForTesting="cargo-mileage-add-notes"
                      placeholder={translations.notes.selectPlaceholder}
                      hasError={!!form.validationResults.notes.errorMessage}
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
            </CardComponent>
          </Column>
          <Column xl={5}>
            <MileageAddSummaryComponent
              workerName={worker?.displayName}
              companyName={companyName}
              formData={form.values}
              validateForm={form.validateAll}
              restoreForm={restoreForm}
              onRouteFetched={onRouteFetched}
              mileageContract={cargoCompanyMileageContractDetails.data}
            />
          </Column>
        </Row>
      </div>
    </>
  );
};

export default CargoMileageAddComponent;
