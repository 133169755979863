import durationService from "../../../../../../common/utils/duration/duration.service";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteDetailsWaypointRouteConnection from "../../types/route-details-route-connection";
import RouteDetailsWaypointRouteConnectionLineType from "../../types/route-details-route-connection-line-type";
import RouteDetailsRouteWaypoint from "../../types/route-details-route-waypoint";

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return `----`;
  }

  return date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getDateTitle = (date: Date | null): string => {
  if (!date) {
    return ``;
  }

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

const getOrderedDateLabel = (orderedDate: Date | null): string => {
  return getDateLabel(orderedDate);
};

const getOrderedDateTitle = (orderedDate: Date | null): string => {
  return getDateTitle(orderedDate);
};

const getEstimatedDateLabel = (estimatedDate: Date | null): string => {
  return getDateLabel(estimatedDate);
};

const getEstimatedDateTitle = (estimatedDate: Date | null): string => {
  return getDateTitle(estimatedDate);
};

const getCompletionDateLabel = (completionDate: Date | null): string => {
  return getDateLabel(completionDate);
};

const getCompletionDateTitle = (completionDate: Date | null): string => {
  return getDateTitle(completionDate);
};

const getHaltingTimeLabel = (haltingTime: number): string => {
  const translations = routeTranslationsHelper.getDetailsTranslations();

  const formattedHaltingTime = durationService.format(haltingTime);

  return translations.routes.haltingDateTemplateLabel.replace(
    "#{duration}",
    formattedHaltingTime
  );
};

const getDistanceLabel = (distance: number | null): string => {
  if (distance === null) {
    return `----`;
  }

  const distanceAsKm = distance / 1000;

  return `${distanceAsKm.toFixed(1)} KM`;
};

const generateWaypointDotClassname = (
  waypoint: RouteDetailsRouteWaypoint,
  orderUuid: string
) => {
  return `dot-order-${orderUuid}-coordinates-${waypoint.place.latitude};${waypoint.place.longitude}-position-${waypoint.markerPosition}`;
};

const getDefinitionOfLineConnectionsBetweenWaypointsInList = (
  waypoints: RouteDetailsRouteWaypoint[],
  orderUuid: string
): RouteDetailsWaypointRouteConnection[] => {
  let connectionLineDefinitions: RouteDetailsWaypointRouteConnection[] = [];

  waypoints.forEach((currentWaypoint, index) => {
    const nextWaypoint = waypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const lineType = RouteDetailsWaypointRouteConnectionLineType.SOLID;

    const newConnectionLineDefinition: RouteDetailsWaypointRouteConnection = {
      itemFromClassname: generateWaypointDotClassname(
        currentWaypoint,
        orderUuid
      ),
      itemToClassname: generateWaypointDotClassname(nextWaypoint, orderUuid),
      lineType,
    };

    connectionLineDefinitions = [
      ...connectionLineDefinitions,
      newConnectionLineDefinition,
    ];
  });

  return connectionLineDefinitions;
};

const routeDetailsRouteHelper = {
  getOrderedDateLabel,
  getOrderedDateTitle,
  getEstimatedDateLabel,
  getEstimatedDateTitle,
  getCompletionDateLabel,
  getCompletionDateTitle,
  getHaltingTimeLabel,
  getDistanceLabel,
  getDefinitionOfLineConnectionsBetweenWaypointsInList,
  generateWaypointDotClassname,
};

export default routeDetailsRouteHelper;
