import PassengerAddRequest from "../../common/api/passenger-add-request";
import passengerAddFactory from "../../common/passenger-add.factory";
import { PassengerAddByRailyCargoCompaniesResponseDataItem } from "./api/passenger-add-by-raily-cargo-companies-response";
import PassengerAddByRailyCargoCompanySelectOption from "./types/passenger-add-by-raily-cargo-company-select-option";
import PassengerAddByRailyFormData from "./types/passenger-add-by-raily-form-data";

const createAddPassengerRequest = (
  formData: PassengerAddByRailyFormData
): PassengerAddRequest => {
  const addresses = passengerAddFactory.createAddPassengerRequestAddresses(
    formData.addresses
  );
  const dispatchIds = passengerAddFactory.createAddPassengerRequestDispatchIds(
    formData.dispatches!
  );

  const request: PassengerAddRequest = {
    first_name: formData.firstName.trim(),
    last_name: formData.lastName.trim(),
    mobile_primary_prefix: formData.mobile.dialingCode || undefined,
    mobile_primary: formData.mobile.number || undefined,
    mobile_secondary_prefix: formData.alternativeMobile.dialingCode || null,
    mobile_secondary: formData.alternativeMobile.number || null,
    email: formData.email.trim(),
    username: formData.username.trim(),
    password: formData.password,
    passenger: {
      addresses: addresses,
      dispatch_ids: dispatchIds,
    },
  };

  return request;
};

const createCargoCompanySelectOption = (
  responseCargoCompany: PassengerAddByRailyCargoCompaniesResponseDataItem
): PassengerAddByRailyCargoCompanySelectOption => {
  return {
    label: responseCargoCompany.display_name,
    value: {
      uuid: responseCargoCompany.id,
      displayName: responseCargoCompany.display_name,
    },
  };
};

const createCargoCompanySelectOptions = (
  responseCargoCompanies: PassengerAddByRailyCargoCompaniesResponseDataItem[]
): PassengerAddByRailyCargoCompanySelectOption[] => {
  return responseCargoCompanies.map(createCargoCompanySelectOption);
};

const passengerAddByRailyFactory = {
  createAddPassengerRequest,
  createCargoCompanySelectOptions,
  createDispatchSelectOptions: passengerAddFactory.createDispatchSelectOptions,
};

export default passengerAddByRailyFactory;
