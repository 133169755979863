import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import DriverEditUpdateRequest from "./driver-edit-update.request";
import DriverEditUpdateResponse from "./driver-edit-update.response";
import DriverEditInitFormDataRequest from "./driver-edit-init-form-data.request";
import DriverEditInitFormDataResponse from "./driver-edit-init-form-data.response";

const fetchFormInitData = async (
  request: DriverEditInitFormDataRequest
): Promise<DriverEditInitFormDataResponse> => {
  const path = `/users/${request.id}/driver/edit-form`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const updateDriver = async (
  request: DriverEditUpdateRequest,
  driverUuid: string
): Promise<DriverEditUpdateResponse> => {
  const path = `/users/${driverUuid}/driver`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const driverEditApiService = {
  fetchFormInitData,
  updateDriver,
};

export default driverEditApiService;
