import CargoCompanyDispatcherListRequest from "../../../../api/raily/cargo-company/dispatcher/list/cargo-company-dispatcher-list.request";
import CargoCompanyDispatcherListLoadParams from "./cargo-company-dispatcher-list-load-params";

const createDispatcherListRequest = (
  params: CargoCompanyDispatcherListLoadParams
): CargoCompanyDispatcherListRequest => {
  return {
    companyUuid: params.companyUuid,
  };
};

const cargoCompanyDispatcherListRequestFactory = {
  createDispatcherListRequest,
};

export default cargoCompanyDispatcherListRequestFactory;
