import TaxiRouteSettlementListingFilterType from "./types/taxi-route-settlement-listing-filter-type";
import TaxiRouteSettlementListingSortKey from "./types/taxi-route-settlement-listing-sort-key";
import TaxiRouteSettlementListingFilter, {
  TaxiRouteSettlementListingBillingModelFilter,
  TaxiRouteSettlementListingCargoOrderInternalIdFilter,
  TaxiRouteSettlementListingDriverFilter,
  TaxiRouteSettlementListingFleetPartnerFilter,
  TaxiRouteSettlementListingRouteIdFilter,
  TaxiRouteSettlementListingRouteStartDateFilter,
} from "./types/taxi-route-settlement-listing-filter";
import TaxiRouteSettlementListingReportRequest, {
  TaxiRouteSettlementListingReportRequestOrder,
} from "./api/taxi-route-settlement-listing-report.request";

const createRequestOrder = (
  sortKey: TaxiRouteSettlementListingSortKey | null
): TaxiRouteSettlementListingReportRequest["order"] => {
  const options: {
    sortKey: TaxiRouteSettlementListingSortKey;
    requestOrder: TaxiRouteSettlementListingReportRequestOrder;
  }[] = [
    {
      requestOrder: TaxiRouteSettlementListingReportRequestOrder.DISTANCE_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingReportRequestOrder.DISTANCE_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingReportRequestOrder.HUMAN_ID_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.ROUTE_ID_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingReportRequestOrder.HUMAN_ID_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.ROUTE_ID_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingReportRequestOrder.DRIVER_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.DRIVER_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingReportRequestOrder.DRIVER_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.DRIVER_DESC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.BILLING_MODEL_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.BILLING_MODEL_ASC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.BILLING_MODEL_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.BILLING_MODEL_DESC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.DISTANCE_RATE_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.DISTANCE_RATE_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder:
        TaxiRouteSettlementListingReportRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.TOTAL_COST_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  filters: TaxiRouteSettlementListingFilter[],
  sortKey: TaxiRouteSettlementListingSortKey | null
): TaxiRouteSettlementListingReportRequest => {
  return {
    order: createRequestOrder(sortKey),
    human_id: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ROUTE_ID
      )?.value as TaxiRouteSettlementListingRouteIdFilter["value"] | undefined
    )?.toString(),
    cargo_order_human_id: filters.find(
      (filter) =>
        filter.type ===
        TaxiRouteSettlementListingFilterType.CARGO_ORDER_INTERNAL_ID
    )?.value as
      | TaxiRouteSettlementListingCargoOrderInternalIdFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) => filter.type === TaxiRouteSettlementListingFilterType.DRIVER
    )?.value as TaxiRouteSettlementListingDriverFilter["value"] | undefined,
    date_since: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiRouteSettlementListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    date_to: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiRouteSettlementListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    billing_model: filters.find(
      (filter) =>
        filter.type === TaxiRouteSettlementListingFilterType.BILLING_MODEL
    )?.value as
      | TaxiRouteSettlementListingBillingModelFilter["value"]
      | undefined,
    fleet_partner: filters.find(
      (filter) =>
        filter.type === TaxiRouteSettlementListingFilterType.FLEET_PARTNER
    )?.value as
      | TaxiRouteSettlementListingFleetPartnerFilter["value"]
      | undefined,
  };
};

const taxiRouteSettlementListingReportRequestFactory = {
  createRequest,
};

export default taxiRouteSettlementListingReportRequestFactory;
