import useAsyncData from "../../../hooks/use-async-data";
import orderOptimizerService from "../order-optimizer.service";
import OrderOptimizerList from "./order-optimizer-list";
import OrderOptimizerListError from "./order-optimizer-list-error";
import OrderOptimizerListLoadParams from "./order-optimizer-list-load-paramts";

const useOrderOptimizerList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<OrderOptimizerList>({ data: [], totalCount: 0 }, false);

  const load = async (
    params: OrderOptimizerListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const orderOptimizerList = await orderOptimizerService.getList(
        params,
        signal
      );

      setData(orderOptimizerList);
    } catch (_error) {
      const error = _error as OrderOptimizerListError;

      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useOrderOptimizerList;
