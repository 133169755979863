import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";

const getFetchingErrorDescriptionLabel = (routeHumanId: number) => {
  const translations = routeTranslationsHelper.getDetailsTranslations();

  return translations.fetchingError.description.replace(
    "#{id}",
    String(routeHumanId)
  );
};

const routeDetailsErrorHelper = {
  getFetchingErrorDescriptionLabel,
};

export default routeDetailsErrorHelper;
