import { FC, useEffect, useState } from "react";
import FormComponent from "../../../common/components/form/form.component";
import InputComponent from "../../../common/components/form/input/input.component";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import FormFieldComponent from "../../../common/components/form/field/form-field.component";
import ButtonComponent from "../../../common/components/button/button.component";
import signInFormValidationService from "./common/sign-in-form-validation.service";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import { useLocation } from "react-router-dom";
import SignInRouteState from "../common/routes/types/sign-in-route-state";
import { useAppContext } from "../../../context/app.context";
import useAbort from "../../../common/hooks/use-abort";
import SignInFormData from "./common/sign-in-form-data";
import SignInFormValidationResults from "./common/sign-in-form-validation-results";
import userFactory from "../../../common/utils/user/user.factory";
import notificationService from "../../../common/utils/notification/notification.service";
import { DataLoadErrorType } from "../../../common/utils/data-load-error/data-load-error";
import signInFormHelper from "./common/sign-in-form.helper";
import SignInError from "../../../common/services/auth/sign-in/sign-in-error";
import SignInParams from "../../../common/services/auth/sign-in/sign-in-params";
import auth from "../../../common/services/auth/auth.service";
import authService from "../common/auth.service";
import AppLogoHorizontalComponent from "../../../common/components/app-logo/app-logo-horizontal.component";
import AppHeaderLanguageComponent from "../../app-header/language/app-header-language.component";
import LinkButtonComponent from "../../../common/components/button/link/link-button.component";

type SignInProps = {};

const SignInComponent: FC<SignInProps> = () => {
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.signIn);

  const translations = appTranslationsHelper.getSignInTranslations();

  const location = useLocation();

  const { setIsUserLoggedIn, setUser } = useAppContext();

  const signInAbort = useAbort();

  useEffect(() => {
    const locationState = location?.state as SignInRouteState | null;

    if (!locationState?.username) {
      return;
    }

    setUsername(locationState.username);
  }, [location.state]);

  const [isSigningIn, setIsSigningIn] = useState(false);

  const [formData, setFormData] = useState<SignInFormData>(() =>
    signInFormHelper.getDefaultFormData()
  );

  const [formValidationResults, setFormValidationResults] =
    useState<SignInFormValidationResults>(() =>
      signInFormHelper.getDefaultFormValidationResults()
    );

  const validateUsername = async (
    value: SignInFormData["username"] = formData.username
  ) => {
    const validationResult = await signInFormValidationService.validateUsername(
      value
    );

    setFormValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));

    return validationResult.isValid;
  };

  const validatePassword = (
    value: SignInFormData["password"] = formData.password
  ) => {
    const validationResult =
      signInFormValidationService.validatePassword(value);

    setFormValidationResults((curr) => ({
      ...curr,
      password: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateForm = async () => {
    const isUsernameValid = await validateUsername();
    const isPasswordValid = validatePassword();

    return isUsernameValid && isPasswordValid;
  };

  const onSignInSuccess = (accessToken: string) => {
    authService.setAccessCredentials(accessToken);
    setIsUserLoggedIn(true);

    const accessTokenDecodedContent =
      authService.getAccessTokenDecodedContent(accessToken)!;

    const user = userFactory.createUser(accessTokenDecodedContent.profile);

    setUser(user);

    notificationService.success(translations.successNotificationLabel);
  };

  const onSignInFailure = (error: SignInError) => {
    if (error.type === DataLoadErrorType.UNAUTHORIZED) {
      notificationService.error(
        translations.failureUnauthorizedNotificationLabel
      );
      return;
    }

    notificationService.error(translations.failureNotificationLabel);
  };

  const signIn = async () => {
    setIsSigningIn(true);

    const params: SignInParams = {
      password: formData.password,
      username: formData.username,
    };

    try {
      const result = await auth.signIn(params, signInAbort.signal);

      onSignInSuccess(result.accessToken);
    } catch (error) {
      onSignInFailure(error as SignInError);
    } finally {
      setIsSigningIn(false);
    }
  };

  const submitForm = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    signIn();
  };

  const setUsername = (value: SignInFormData["username"]) => {
    setFormData((curr) => ({
      ...curr,
      username: value,
    }));
  };

  const setPassword = (value: SignInFormData["password"]) => {
    setFormData((curr) => ({
      ...curr,
      password: value,
    }));
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="auth">
      <div className="auth__language">
        <AppHeaderLanguageComponent />
      </div>
      <div className="auth__content">
        <AppLogoHorizontalComponent className="auth__logo" />
        <h1 className="auth__title">{translations.headingLabel}</h1>
        <FormComponent onSubmit={submitForm}>
          <FormFieldComponent
            label={translations.form.usernameLabel}
            isRequired
            errorMessage={formValidationResults.username.errorMessage}
          >
            <InputComponent
              placeholder={translations.form.usernamePlaceholder}
              value={formData.username}
              onChange={setUsername}
              onBlur={validateUsername}
              hasError={!!formValidationResults.username.errorMessage}
              idForTesting={"username-input"}
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={translations.form.passwordLabel}
            isRequired
            errorMessage={formValidationResults.password.errorMessage}
          >
            <InputComponent
              placeholder={translations.form.passwordPlaceholder}
              value={formData.password}
              onChange={setPassword}
              onBlur={validatePassword}
              type="password"
              hasError={!!formValidationResults.password.errorMessage}
              idForTesting={"password-input"}
            />
          </FormFieldComponent>
          <ButtonComponent
            onClick={submitForm}
            type="primary"
            classNames={{ root: "auth__submit_button" }}
            isLoading={isSigningIn}
            idForTesting="submit-button"
            title={translations.form.submitButtonTitle}
          >
            {translations.form.submitButtonLabel}
          </ButtonComponent>
        </FormComponent>
      </div>
      <div className="auth__copyright">
        Copyright &copy; {currentYear}
        <LinkButtonComponent to={"https://www.sptech.pl"}>
          SP Tech Solutions
        </LinkButtonComponent>
      </div>
    </div>
  );
};

export default SignInComponent;
