import OrderDetailsChangeCargoExternalIdRequest from "../api/order-details-change-cargo-external-id.request";
import OrderDetailsChangeCargoExternalIdFormData from "../types/order-details-change-cargo-external-id-form-data";

const createChangeCargoExternalIdRequest = (
  formData: OrderDetailsChangeCargoExternalIdFormData
): OrderDetailsChangeCargoExternalIdRequest => {
  const request: OrderDetailsChangeCargoExternalIdRequest = {
    new_external_id: formData.cargoExternalId || null,
  };

  return request;
};

const orderDetailsChangeCargoExternalIdFactory = {
  createChangeCargoExternalIdRequest,
};

export default orderDetailsChangeCargoExternalIdFactory;
