import { FC } from "react";
import BillingsHistoryComponent from "./elements/billings-history.component";

export type BillingsToolsProps = {
  shouldShowOrderHistory: boolean;
  onOrderHistoryButtonClick: () => void;
};

const BillingsToolsComponent: FC<BillingsToolsProps> = (props) => {
  return (
    <div className="billings_tools">
      <BillingsHistoryComponent
        isActive={props.shouldShowOrderHistory}
        onSuccess={props.onOrderHistoryButtonClick}
      />
    </div>
  );
};

export default BillingsToolsComponent;
