import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import OrderAbandonedListingSortKey from "../types/order-abandoned-listing-sort-key";
import OrderAbandonedListingSortSelectOption from "../types/order-abandoned-listing-sort-select-option";

const getSelectOptions = (): OrderAbandonedListingSortSelectOption[] => {
  const translations =
    orderTranslationsHelper.getAbandonedListingTranslations().sort;

  return [
    {
      label: translations.cargoCompanyInternalOrderIdAscOptionLabel,
      value: OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC,
    },
    {
      label: translations.cargoCompanyInternalOrderIdDescOptionLabel,
      value: OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_DESC,
    },
  ];
};

const getDefaultSelectOption = (): OrderAbandonedListingSortSelectOption => {
  const translations =
    orderTranslationsHelper.getAbandonedListingTranslations().sort;

  return {
    label: translations.cargoCompanyInternalOrderIdAscOptionLabel,
    value: OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC,
  };
};

const orderAbandonedListingSortHelper = {
  getSelectOptions,
  getDefaultSelectOption,
};

export default orderAbandonedListingSortHelper;
