import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import ContractMaintenancePolicy from "../../../../../../common/types/contract-maintenance-policy";

const validateName = (companyName: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(40).trim().required();

  return formValidationService.validate(companyName, validationSchema);
};

const validateDisplayName = (displayName: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(40).trim().required();

  return formValidationService.validate(displayName, validationSchema);
};

const validateTaxNumber = (taxId: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(10).trim().required();

  return formValidationService.validate(taxId, validationSchema);
};

const validateCompanyId = (companyId: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").min(1).max(14).required();

  return formValidationService.validate(companyId, validationSchema);
};

const validateNationalCourtRegister = (
  nationalCourtRegister: string
): FormValidationResult => {
  const validationSchema = Joi.string().allow("").min(1).max(10).required();

  return formValidationService.validate(
    nationalCourtRegister,
    validationSchema
  );
};

const validateContractMaintenancePolicy = (
  contractMaintenancePolicy: ContractMaintenancePolicy
): FormValidationResult => {
  const validationSchema = Joi.any().not(null).required();

  return formValidationService.validate(
    contractMaintenancePolicy,
    validationSchema
  );
};

const validateAddress = (address: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(100).trim().required();

  return formValidationService.validate(address, validationSchema);
};

const validateNotes = (notes: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").max(255);

  return formValidationService.validate(notes, validationSchema);
};

const cargoCompanyAddFormValidationService = {
  validateNationalCourtRegister,
  validateCompanyId,
  validateTaxNumber,
  validateAddress,
  validateName,
  validateDisplayName,
  validateContractMaintenancePolicy,
  validateNotes,
};

export default cargoCompanyAddFormValidationService;
