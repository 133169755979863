import TaxiOrderSettlementListingByRailyTaxiCorporationSelectOption from "./types/taxi-order-settlement-listing-by-raily-taxi-corporation-select-option";
import { TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem } from "./api/taxi-order-settlement-listing-by-raily-taxi-corporations.response";
import taxiOrderSettlementListingFactory from "../../common/taxi-order-settlement-listing.factory";

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem
): TaxiOrderSettlementListingByRailyTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      displayName: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem[]
): TaxiOrderSettlementListingByRailyTaxiCorporationSelectOption[] => {
  return responseTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const taxiOrderSettlementListingByRailyFactory = {
  createTaxiCorporationSelectOptions,
  createListingItems: taxiOrderSettlementListingFactory.createListingItems,
  createStatsSummary: taxiOrderSettlementListingFactory.createStatsSummary,
};

export default taxiOrderSettlementListingByRailyFactory;
