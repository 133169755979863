import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import cargoDispatchFormValidationService from "./cargo-dispatch-validation.service";
import CargoDisapatchFormData from "./types/cargo-dispatch-form-data";

const getEmptyFormData = (): CargoDisapatchFormData => {
  return {
    name: "",
    displayName: "",
    address: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<CargoDisapatchFormData> => {
    return {
      name: (values) =>
        cargoDispatchFormValidationService.validateName(values.name),
      displayName: (values) =>
        cargoDispatchFormValidationService.validateDisplayName(
          values.displayName
        ),
      address: (values) =>
        cargoDispatchFormValidationService.validateAddress(values.address),
    };
  };

const cargoDispatchFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
};

export default cargoDispatchFormHelper;
