import appTranslationsHelper from "./app-translations.helper";
import PlanningDriverTranslations from "./types/planning/planning-driver.translations";
import PlanningOrderTranslations from "./types/planning/planning-order.translations";
import PlanningOverviewTranslations from "./types/planning/planning-overview.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `planning`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getOrderTranslations = (): PlanningOrderTranslations => {
  const translations = getTranslations<PlanningOrderTranslations>(
    `planning-order.translations.json`
  );

  return translations;
};

const getDriverTranslations = (): PlanningDriverTranslations => {
  const translations = getTranslations<PlanningDriverTranslations>(
    `planning-driver.translations.json`
  );

  return translations;
};

const getOverviewTranslations = (): PlanningOverviewTranslations => {
  const translations = getTranslations<PlanningOverviewTranslations>(
    `planning-overview.translations.json`
  );

  return translations;
};

const planningTranslationsHelper = {
  getOrderTranslations,
  getDriverTranslations,
  getOverviewTranslations,
};

export default planningTranslationsHelper;
