import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import userFormValidationService from "../../../../../../shared/user/user.validation.service";

const validateTaxiCorporation = (
  taxiCorporationUuid: string
): FormValidationResult => {
  const validationSchema = Joi.string().min(1).required();

  return formValidationService.validate(taxiCorporationUuid, validationSchema);
};

const dealerAddByRailyFormValidationService = {
  validateFirstName: userFormValidationService.validateFirstName,
  validateLastName: userFormValidationService.validateLastName,
  validateEmail: userFormValidationService.validateEmail,
  validateUsername: userFormValidationService.validateUsername,
  validatePassword: userFormValidationService.validatePassword,
  validatePasswordRepeat: userFormValidationService.validatePasswordRepeat,
  validatePhoneNumber: userFormValidationService.validateMobile,
  validateAlternativePhoneNumber:
    userFormValidationService.validateAlternativeMobile,
  validateTaxiCorporation,
};

export default dealerAddByRailyFormValidationService;
