import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryShuffleMergeEvent } from "../../../common/types/order-details-history-entry-event";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations().shuffleMergeEntry;

const getSourceOrdersLabel = (
  sourceInternalOrderIds: OrderDetailsHistoryEntryShuffleMergeEvent["sourceInternalOrderIds"]
) => {
  return translations.sourceOrderTemplateText.replace(
    "#{orderInternalIds}",
    sourceInternalOrderIds.join(", ")
  );
};

const getTargetOrderLabel = (
  targetInternalOrderId: OrderDetailsHistoryEntryShuffleMergeEvent["targetInternalOrderId"]
) => {
  return translations.targetOrderTemplateText.replace(
    "#{orderInternalId}",
    targetInternalOrderId
  );
};

const orderDetailsHistoryEntryShuffleMergeContentHelper = {
  getSourceOrdersLabel,
  getTargetOrderLabel,
};

export default orderDetailsHistoryEntryShuffleMergeContentHelper;
