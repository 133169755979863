import DriverPlanCandidaturePreviewRequest, {
  DriverPlanCandidaturePreviewRequestQueryParams,
} from "./driver-plan-candidature-preview.request";

const createQueryParams = (
  data: DriverPlanCandidaturePreviewRequest
): DriverPlanCandidaturePreviewRequestQueryParams => {
  return {
    taxiDriverAssociationId: data.taxiDriverAssociationId || undefined,
    planEntryId: data.planEntryId || undefined,
  };
};

const driverPlanCandidaturePreviewRequestFactory = {
  createQueryParams,
};

export default driverPlanCandidaturePreviewRequestFactory;
