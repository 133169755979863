import TaxiTaxiContractUpdateRequest, {
  TaxiTaxiContractUpdateRequestBody,
} from "./taxi-taxi-contract-update.request";

const createBody = (
  request: TaxiTaxiContractUpdateRequest
): TaxiTaxiContractUpdateRequestBody => {
  return {
    disabled: request.disabled,
    displayName: request.displayName,
    priority: request.priority,
  };
};

const taxiTaxiContractUpdateRequestFactory = {
  createBody,
};

export default taxiTaxiContractUpdateRequestFactory;
