import useAsyncData from "../../../hooks/use-async-data";
import cargoOfficerService from "../cargo-officer.service";
import CargoOfficerList from "./cargo-officer-list";
import CargoOfficerListError from "./cargo-officer-list-error";
import CargoOfficerListLoadParams from "./cargo-officer-list-load-params";

const useCargoOfficerList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<CargoOfficerList>({ data: [], totalCount: 0 });

  const load = async (
    params: CargoOfficerListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoOfficerList = await cargoOfficerService.list(params, signal);

      setData(cargoOfficerList);
      setIsLoading(false);
    } catch (_error) {
      const error = _error as CargoOfficerListError;

      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useCargoOfficerList;
