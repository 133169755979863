import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import orderAddHelper from "../../helper/order-add.helper";
import { OrderAddCargoAddressType } from "../../types/order-add-cargo-address";
import OrderAddPassengerListItem from "../../types/order-add-passenger-list-item";
import OrderAddRouteItem, {
  OrderAddRouteWaypoint,
  OrderAddRouteWaypointGroup,
  OrderAddRouteWaypointGroupWaypoint,
} from "../../types/order-add-route-waypoint";

const getNewEmptyRoute = (): OrderAddRouteItem => {
  const newRoute: OrderAddRouteItem = {
    uuid: uuidService.generate(),
    address: null,
    date: null,
    haltingTimeMinutes: null,
    onboardingPassengerListItems: [],
    outboardingPassengerListItems: [],
  };

  return newRoute;
};

const getListOfPassengersInCar = (
  passengerList: OrderAddPassengerListItem[],
  routeItem: OrderAddRouteItem,
  allRoutes: OrderAddRouteItem[]
): OrderAddPassengerListItem[] => {
  const indexOfCurrentRoute = allRoutes.indexOf(routeItem);

  let passengersInCar: OrderAddPassengerListItem[] = [];

  const routeItemsToCheck = allRoutes.slice(0, indexOfCurrentRoute);

  routeItemsToCheck.forEach((routeItem) => {
    const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      passengersInCar.push(...routeItem.onboardingPassengerListItems);

      passengersInCar = passengersInCar.filter(
        (passengerInCar) =>
          !routeItem.outboardingPassengerListItems.find(
            (outboardingPassenger) =>
              outboardingPassenger.uuid === passengerInCar.uuid
          )
      );

      return;
    }

    const waypointGroupOnboardingPassengerUuidList = routeItem.waypoints
      .map((waypoint) => waypoint.onboardingPassengerListItems)
      .flat();

    passengersInCar.push(...waypointGroupOnboardingPassengerUuidList);

    passengersInCar = passengersInCar.filter(
      (uuidOfPassengersInCar) =>
        !routeItem.waypoints
          .map((waypoint) => waypoint.outboardingPassengerListItems)
          .flat()
          .find(
            (outboardingPassengerUuid) =>
              outboardingPassengerUuid === uuidOfPassengersInCar
          )
    );
  });

  return passengerList.filter((passengerListItem) =>
    passengersInCar.find(
      (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
    )
  );
};

const getAmountOfPassengersInCar = (
  passengerList: OrderAddPassengerListItem[],
  route: OrderAddRouteItem,
  allRoutes: OrderAddRouteItem[]
): number => {
  const passengersInCar = getListOfPassengersInCar(
    passengerList,
    route,
    allRoutes
  );

  return passengersInCar.length;
};

const getOnboardingPassengerCandidateListForWaypoint = (
  passengerList: OrderAddPassengerListItem[],
  passengersInCarBeforeCurrentWaypoint: OrderAddPassengerListItem[],
  waypoint: OrderAddRouteWaypoint
): OrderAddPassengerListItem[] => {
  return passengerList.filter(
    (passengerListItem) =>
      !passengersInCarBeforeCurrentWaypoint.find(
        (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
      ) &&
      !waypoint.onboardingPassengerListItems.find(
        (onboardingPassengerListItem) =>
          onboardingPassengerListItem.uuid === passengerListItem.uuid
      )
  );
};

const getOnboardingPassengerCandidateListForWaypointGroup = (
  passengerList: OrderAddPassengerListItem[],
  passengersInCarBeforeCurrentWaypoint: OrderAddPassengerListItem[],
  waypoint: OrderAddRouteWaypointGroup
): OrderAddPassengerListItem[] => {
  return passengerList.filter((passengerListItem) => {
    return (
      !passengersInCarBeforeCurrentWaypoint.find(
        (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
      ) &&
      !waypoint.waypoints.some((waypoint) => {
        return waypoint.onboardingPassengerListItems.find(
          (onboardingPassengerListItem) =>
            onboardingPassengerListItem.uuid === passengerListItem.uuid
        );
      })
    );
  });
};

const getOnboardingPassengerCandidateList = (
  passengerList: OrderAddPassengerListItem[],
  currentRouteItem: OrderAddRouteItem,
  allRoutes: OrderAddRouteItem[]
): OrderAddPassengerListItem[] => {
  const passengersInCarBeforeCurrentRoute = getListOfPassengersInCar(
    passengerList,
    currentRouteItem,
    allRoutes
  );

  const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(currentRouteItem);

  if (isWaypoint) {
    return getOnboardingPassengerCandidateListForWaypoint(
      passengerList,
      passengersInCarBeforeCurrentRoute,
      currentRouteItem
    );
  }

  return getOnboardingPassengerCandidateListForWaypointGroup(
    passengerList,
    passengersInCarBeforeCurrentRoute,
    currentRouteItem
  );
};

const getOutboardingPassengerCandidateListForWaypoint = (
  passengerList: OrderAddPassengerListItem[],
  passengersInCarBeforeCurrentWaypoint: OrderAddPassengerListItem[],
  waypoint: OrderAddRouteWaypoint
) => {
  return passengerList.filter(
    (passengerListItem) =>
      passengersInCarBeforeCurrentWaypoint.find(
        (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
      ) &&
      !waypoint.outboardingPassengerListItems.find(
        (outboardingPassengerListItem) =>
          outboardingPassengerListItem.uuid === passengerListItem.uuid
      )
  );
};

const getOutboardingPassengerCandidateListForWaypointGroup = (
  passengerList: OrderAddPassengerListItem[],
  passengersInCarBeforeCurrentWaypoint: OrderAddPassengerListItem[],
  waypointGroup: OrderAddRouteWaypointGroup
) => {
  return passengerList.filter(
    (passengerListItem) =>
      passengersInCarBeforeCurrentWaypoint.find(
        (passengerInCar) => passengerInCar.uuid === passengerListItem.uuid
      ) &&
      !waypointGroup.waypoints.some(
        (waypoint) =>
          !!waypoint.outboardingPassengerListItems.find(
            (outboardingPassengerListItem) =>
              outboardingPassengerListItem.uuid === passengerListItem.uuid
          )
      )
  );
};

const getOutboardingPassengerCandidateList = (
  passengerList: OrderAddPassengerListItem[],
  currentRouteItem: OrderAddRouteItem,
  allRoutes: OrderAddRouteItem[]
): OrderAddPassengerListItem[] => {
  const passengersInCarBeforeCurrentRoute = getListOfPassengersInCar(
    passengerList,
    currentRouteItem,
    allRoutes
  );

  const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(currentRouteItem);

  if (isWaypoint) {
    return getOutboardingPassengerCandidateListForWaypoint(
      passengerList,
      passengersInCarBeforeCurrentRoute,
      currentRouteItem
    );
  }

  return getOutboardingPassengerCandidateListForWaypointGroup(
    passengerList,
    passengersInCarBeforeCurrentRoute,
    currentRouteItem
  );
};

const getIndexOfPassenger = (
  passengerList: OrderAddPassengerListItem[],
  passengerListItemUuid: OrderAddPassengerListItem["uuid"]
): number | undefined => {
  const passengerListItem = passengerList.find(
    (listItem) => listItem.uuid === passengerListItemUuid
  );

  if (!passengerListItem) {
    return undefined;
  }

  const indexOfPassengerListItem = passengerList.indexOf(passengerListItem);

  return indexOfPassengerListItem;
};

const getUpdatedRouteItemsOnPassengerListChange = (
  routes: OrderAddRouteItem[],
  passengerList: OrderAddPassengerListItem[]
) => {
  const newRoutes: OrderAddRouteItem[] = [];

  routes.forEach((route) => {
    const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(route);

    if (isWaypoint) {
      const newOnboardingPassengerListItems: OrderAddRouteWaypoint["onboardingPassengerListItems"] =
        [];
      const newOutboardingPassengerListItems: OrderAddRouteWaypoint["outboardingPassengerListItems"] =
        [];

      route.onboardingPassengerListItems.forEach(
        (onboardingPassengerListItem) => {
          const passengerOnList = passengerList.find(
            (passengerListItem) =>
              passengerListItem.uuid === onboardingPassengerListItem.uuid
          );

          if (!passengerOnList) {
            return;
          }

          newOnboardingPassengerListItems.push(passengerOnList);
        }
      );

      route.outboardingPassengerListItems.forEach(
        (outboardingPassengerListItem) => {
          const passengerOnList = passengerList.find(
            (passengerListItem) =>
              passengerListItem.uuid === outboardingPassengerListItem.uuid
          );

          if (!passengerOnList) {
            return;
          }

          newOutboardingPassengerListItems.push(passengerOnList);
        }
      );

      const newRoute: OrderAddRouteWaypoint = {
        ...route,
        onboardingPassengerListItems: newOnboardingPassengerListItems,
        outboardingPassengerListItems: newOutboardingPassengerListItems,
      };

      newRoutes.push(newRoute);

      return;
    }

    const newRoute: OrderAddRouteWaypointGroup = {
      ...route,
      waypoints: [],
    };

    route.waypoints.forEach((waypoint) => {
      const newOnboardingPassengerListItems: OrderAddRouteWaypoint["onboardingPassengerListItems"] =
        [];
      const newOutboardingPassengerListItems: OrderAddRouteWaypoint["outboardingPassengerListItems"] =
        [];

      waypoint.onboardingPassengerListItems.forEach(
        (onboardingPassengerListItem) => {
          const passengerOnList = passengerList.find(
            (passengerListItem) =>
              passengerListItem.uuid === onboardingPassengerListItem.uuid
          );

          if (!passengerOnList) {
            return;
          }
          newOnboardingPassengerListItems.push(passengerOnList);
        }
      );

      waypoint.outboardingPassengerListItems.forEach(
        (outboardingPassengerListItem) => {
          const passengerOnList = passengerList.find(
            (passengerListItem) =>
              passengerListItem.uuid === outboardingPassengerListItem.uuid
          );

          if (!passengerOnList) {
            return;
          }
          newOutboardingPassengerListItems.push(passengerOnList);
        }
      );

      const newWaypoint: OrderAddRouteWaypointGroupWaypoint = {
        ...waypoint,
        onboardingPassengerListItems: newOnboardingPassengerListItems,
        outboardingPassengerListItems: newOutboardingPassengerListItems,
      };

      newRoute.waypoints.push(newWaypoint);
    });
    newRoutes.push(newRoute);
  });

  return newRoutes;
};

const getRouteAddressSelectOptionSubTextForCargoAddress = (
  addressType: OrderAddCargoAddressType
): string => {
  const translations =
    orderTranslationsHelper.getAddTranslations().routes.waypoint
      .cargoAddressType;

  switch (addressType) {
    case OrderAddCargoAddressType.HOTEL:
      return translations.HOTEL;
    case OrderAddCargoAddressType.MEETING_POINT:
      return translations.MEETING_POINT;
    case OrderAddCargoAddressType.STATION:
      return translations.STATION;
    default:
      return ``;
  }
};

const orderAddRoutesHelper = {
  getNewEmptyRoute,
  getOnboardingPassengerCandidateList,
  getOutboardingPassengerCandidateList,
  getIndexOfPassenger,
  getListOfPassengersInCar,
  getAmountOfPassengersInCar,
  getUpdatedRouteItemsOnPassengerListChange,
  getRouteAddressSelectOptionSubTextForCargoAddress,
};

export default orderAddRoutesHelper;
