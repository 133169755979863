import HttpResponse from "../../../../utils/http/http.response";

export enum PassengerListCargoContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type PassengerListResponseItemCargoCompany = {
  id: string;
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: PassengerListCargoContractMaintenancePolicy;
  notes: string | null;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
};

export type PassengerListResponseItemDispatch = {
  id: string;
  dispatchName: string;
  displayName: string;
  addressString: string;
  cargoCompany: PassengerListResponseItemCargoCompany;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
};

export type PassengerListResponseItemAddress = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  lat: number;
  lon: number;
  country: string | null;
  town: string | null;
  zipCode: string | null;
  street: string | null;
  houseNo: string | null;
  apartment: string | null;
  description: string | null;
  displayName: string;
};

export type PassengerListResponseItemPassenger = {
  addresses: PassengerListResponseItemAddress[];
  dispatches: PassengerListResponseItemDispatch[];
};

export type PassengerListResponseItem = {
  removedBy: string | null;
  removedAt: Date | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
  passenger: PassengerListResponseItemPassenger;
};

type PassengerListResponse = HttpResponse<{
  data: PassengerListResponseItem[];
  totalCount: number;
}>;

export default PassengerListResponse;
