import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import OrderDetailsChangesApprovalResponse from "./order-details-changes-approval.response";

const fetchApproveChanges = (
  orderUuid: string
): Promise<OrderDetailsChangesApprovalResponse> => {
  const path = `/orders/${orderUuid}/attention-approval`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const orderDetailsChangesApprovalApiService = {
  fetchApproveChanges,
};

export default orderDetailsChangesApprovalApiService;
