import UserRole from "../../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../../common/utils/user/permissions/user-permissions-definition";

export type MileageDictionaryListingUserPermissionsDefinitionOption =
  | "hasAccessToAdminView"
  | "hasAccessToCargoView";

export type MileageDictionaryListingUserPermissionsDefinition =
  UserPermissionsDefinition<MileageDictionaryListingUserPermissionsDefinitionOption>;

export type MileageDictionaryListingUserPermissions =
  UserPermissions<MileageDictionaryListingUserPermissionsDefinitionOption>;

const mileageDictionaryListingUserPermissionsDefinition: MileageDictionaryListingUserPermissionsDefinition =
  {
    hasAccessToAdminView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToCargoView: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  };

export default mileageDictionaryListingUserPermissionsDefinition;
