import { FC, useEffect, useState } from "react";
import CardComponent from "../../../common/components/card/card.component";
import FormFieldComponent from "../../../common/components/form/field/form-field.component";
import DateTimeInputComponent from "../../../common/components/form/input/date-time/date-time-input.component";
import NumericInputComponent from "../../../common/components/form/input/numeric-input/numeric-input.component";
import useForm from "../../../common/components/form/use-form";
import Column from "../../../common/components/grid/column";
import Row from "../../../common/components/grid/row";
import HeadingComponent from "../../../common/components/heading/heading.component";
import useAbort from "../../../common/hooks/use-abort";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import CargoCompanyDetailsParams from "../../../common/services/cargo-company/cargo-company/details/cargo-company-details-params";
import useCargoCompanyDetails from "../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import mileageTranslationsHelper from "../../../languages/mileage-translations.helper";
import mileageBreadcrumbsHelper from "../common/breadcrumbs/mileage-breadcrumbs.helper";
import MileageUpdateSummaryComponent from "./common/components/summary/mileage-update-summary.component";
import mileageUpdateAddressFactory from "./common/factory/mileage-update-address.factory";
import mileageUpdateFormHelper from "./common/form/mileage-update-form.helper";
import MileageUpdateAddress from "./common/types/mileage-update-address";
import useMileageDetails from "../../../common/services/mileage/details/use-mileage-details";
import MileageDetailsLoadParams from "../../../common/services/mileage/details/mileage-details-load-params";
import { useParams } from "react-router-dom";
import MileageUpdateAddressRouteParams from "../common/routes/types/mileage-update-route-params";
import mileageUpdateFormFactory from "./common/form/mileage-update-form.factory";
import MileageUpdateVehicleTypeSelectComponent from "./common/components/vehicle-type-select/mileage-update-vehicle-type-select.component";
import { MileageUpdateAddressRouteItem } from "./common/components/address/mileage-update-address-routes.types";
import MileageUpdateAddressRoutesComponent from "./common/components/address/mileage-update-address-routes.component";
import { floor, round } from "lodash";

const MileageUpdateComponent: FC = () => {
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const { mileageUuid } = useParams<MileageUpdateAddressRouteParams>();

  const translations = mileageTranslationsHelper.getMileageUpdateTranslations();

  useEffect(() => {
    const breadcrumbs = mileageBreadcrumbsHelper.getMileageUpdateBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageUpdate);

  const [routes, setRoutes] = useState<MileageUpdateAddressRouteItem[]>([]);
  const [routesChanged, setRoutesChanged] = useState(false);

  const mileageDetails = useMileageDetails();
  const mileageDetailsAbort = useAbort();

  const cargoCompanyDetails = useCargoCompanyDetails();
  const cargoCompanyDetailsAbort = useAbort();

  const form = useForm({
    emptyValues: mileageUpdateFormHelper.getDefaultFormData(),
    validationDefinition: mileageUpdateFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    if (!mileageUuid) {
      return;
    }

    const params: MileageDetailsLoadParams = {
      mileageUuid: mileageUuid,
    };

    mileageDetails.load(params, mileageDetailsAbort.signal);

    return () => mileageDetailsAbort.revoke();
  }, []);

  useEffect(() => {
    if (!mileageDetails.data) {
      return;
    }

    form.setValues(mileageUpdateFormFactory.create(mileageDetails.data));

    setRoutes(
      mileageUpdateAddressFactory.createRouteItemsFromDeatils(
        mileageDetails.data
      )
    );
  }, [mileageDetails.data]);

  useEffect(() => {
    if (!mileageDetails.data) {
      return;
    }

    const params: CargoCompanyDetailsParams = {
      cargoCompanyUuid: mileageDetails.data.workerCompanyId,
    };

    cargoCompanyDetails.load(params, cargoCompanyDetailsAbort.signal);

    return () => cargoCompanyDetailsAbort.revoke();
  }, [mileageDetails.data]);

  const onRoutesChange = async (
    routeItems: MileageUpdateAddressRouteItem[]
  ) => {
    setRoutes(routeItems);

    const seq: MileageUpdateAddress[] =
      mileageUpdateAddressFactory.createAddressesFromRouteItems(routeItems);

    form.setValue("addressSequence", seq);

    setRoutesChanged(true);
  };

  const onRouteFetched = (totalDistance: number) => {
    if (routesChanged) {
      form.setValue("distance", totalDistance);
      form.setValue("osrmDistance", totalDistance);
    }
  };

  useEffect(() => {
    form.validate("addressSequence");
  }, [routes]);

  return (
    <div className="mileage_update">
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column xl={7}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent label={translations.workers.headingLabel}>
                  {mileageDetails.data?.workerName}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.date.headingLabel}
                  isRequired
                  errorMessage={form.validationResults.mileageDate.errorMessage}
                >
                  <DateTimeInputComponent
                    date={form.values.mileageDate}
                    onChange={(value) => {
                      form.setValue("mileageDate", value);
                    }}
                    onBlur={async () => await form.validate("mileageDate")}
                    idForTesting="mileage-update-mileage-date"
                    placeholder={translations.date.selectPlaceholder}
                    hasError={!!form.validationResults.mileageDate.errorMessage}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={12}>
                <FormFieldComponent
                  label={translations.routes.headingLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.addressSequence.errorMessage
                  }
                >
                  <MileageUpdateAddressRoutesComponent
                    cargoCompanyUuid={cargoCompanyDetails.data?.uuid}
                    isAddButtonDisabled={!mileageDetails.data}
                    additionalAddresses={[]}
                    routes={routes}
                    onRoutesChange={onRoutesChange}
                    idForTesting="mileage-update-mileage-routes"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={12}>
                <FormFieldComponent
                  label={translations.vehicleType.headingLabel}
                  isRequired
                  errorMessage={form.validationResults.vehicleType.errorMessage}
                >
                  <MileageUpdateVehicleTypeSelectComponent
                    vehicleType={form.values.vehicleType}
                    onVehicleTypeChange={(type) => {
                      form.setValue("vehicleType", type);
                    }}
                    onBlur={() => {
                      form.validate("vehicleType");
                    }}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.cardNumber.headingLabel}
                >
                  {mileageDetails.data?.cardNumber}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.mileageNumber.headingLabel}
                >
                  {mileageDetails.data?.mileageNumber}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.distance.headingLabel}
                  isRequired
                  errorMessage={form.validationResults.distance.errorMessage}
                >
                  <NumericInputComponent
                    value={round(form.values.distance ?? 0)}
                    onChange={(value) => {
                      form.setValue("distance", value);
                    }}
                    onBlur={async () => await form.validate("distance")}
                    idForTesting="mileage-update-mileage-distance"
                    placeholder={translations.distance.selectPlaceholder}
                    hasError={!!form.validationResults.distance.errorMessage}
                    isIntegerOnly
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.commissionNumber.headingLabel}
                >
                  {!mileageDetails.data?.commissionNumber
                    ? "---"
                    : mileageDetails.data.commissionNumber}
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
        </Column>
        <Column xl={5}>
          <MileageUpdateSummaryComponent
            details={mileageDetails.data}
            companyName={cargoCompanyDetails.data?.displayName ?? ""}
            formData={form.values}
            validateForm={form.validateAll}
            onRouteFetched={onRouteFetched}
          />
        </Column>
      </Row>
    </div>
  );
};

export default MileageUpdateComponent;
