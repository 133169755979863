import { OrderJoinRouteParams } from "./types/order-join-route-params";

export type OrderJoinOrderData = {
  uuid: string;
  internalOrderId: number;
};

const build = (
  targetOrderUuid: string,
  sourceOrdersData: OrderJoinOrderData[]
): OrderJoinRouteParams => {
  return {
    targetOrderUuid,
    sourceOrders: sourceOrdersData.map(
      (orderData) => `${orderData.uuid},${orderData.internalOrderId}`
    ),
  };
};

const parse = (
  params: OrderJoinRouteParams
): {
  targetOrderUuid: string;
  sourceOrdersData: OrderJoinOrderData[];
} => {
  const createOrderData = (
    sourceOrderRouteParam: string
  ): OrderJoinOrderData => {
    const splittedSourceOrderParams = sourceOrderRouteParam.split(",");

    return {
      uuid: splittedSourceOrderParams[0],
      internalOrderId: Number(splittedSourceOrderParams[1]),
    };
  };

  const parseSourceOrdersData = (): OrderJoinOrderData[] => {
    if (Array.isArray(params.sourceOrders)) {
      return params.sourceOrders.map(createOrderData);
    }

    return [createOrderData(params.sourceOrders)];
  };

  return {
    targetOrderUuid: params.targetOrderUuid,
    sourceOrdersData: parseSourceOrdersData(),
  };
};

const orderJoinRouteParamsService = {
  build,
  parse,
};

export default orderJoinRouteParamsService;
