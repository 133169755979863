import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import TaxiTransferedOrderSettlementListingFilter from "../../types/taxi-transfered-order-settlement-listing-filter";
import TaxiTransferedOrderSettlementListingFilterSelectOption from "../../types/taxi-transfered-order-settlement-listing-filter-select-option";
import taxiTransferedOrderSettlementListingFilterHelper from "../../helper/taxi-transfered-order-settlement-listing-filter.helper";

type TaxiTransferedOrderSettlementListingFiltersSelectProps = {
  filters: TaxiTransferedOrderSettlementListingFilter[];
  onAddNewFilter: (filter: TaxiTransferedOrderSettlementListingFilter) => void;
};

const TaxiTransferedOrderSettlementListingFiltersSelectComponent: FC<
  TaxiTransferedOrderSettlementListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: TaxiTransferedOrderSettlementListingFilterSelectOption[] =
    useMemo(() => {
      return taxiTransferedOrderSettlementListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: TaxiTransferedOrderSettlementListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(
          option as TaxiTransferedOrderSettlementListingFilterSelectOption
        )
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="taxi-transfered-order-settlement-listing-filter"
    />
  );
};

export default TaxiTransferedOrderSettlementListingFiltersSelectComponent;
