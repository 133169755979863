import useAsyncData from "../../../hooks/use-async-data";
import delegationService from "../delegation.service";
import DelegationRoadRouteItem from "./delegation-road-route-item";
import DelegationRoadRouteLoadParams from "./delegation-road-route-load-params";

const useDelegationRoadRoute = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<DelegationRoadRouteItem | null>(null);

  const load = async (params: DelegationRoadRouteLoadParams): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const response = await delegationService.getRoute(params);

      setData(response);
      setIsLoading(false);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useDelegationRoadRoute;
