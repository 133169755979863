import RouteRoadRouteLoadParams from "../../../../common/services/route/road-route/route-road-route-load-params";
import RouteDetailsRouteWaypointGroup from "./types/route-details-route-waypoint-group";

const create = (
  group: RouteDetailsRouteWaypointGroup
): RouteRoadRouteLoadParams => {
  return {
    waypoints: group.waypoints.map((waypoint) => ({
      lat: waypoint.place.latitude,
      lon: waypoint.place.longitude,
    })),
    excludeHighway: group.excludeHighway,
  };
};

const routeRoadRouteLoadParamsFactory = {
  create,
};

export default routeRoadRouteLoadParamsFactory;
