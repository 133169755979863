import TaxiCorporationListRequest, {
  TaxiCorporationListRequestQueryParams,
} from "./taxi-corporation-list.request";

const createQueryParams = (
  data: TaxiCorporationListRequest
): TaxiCorporationListRequestQueryParams => {
  return {
    companyName: data.companyName,
    displayName: data.displayName,
    headquartersAddressString: data.headquartersAddressString,
    krsNo: data.krsNo,
    notes: data.notes,
    regon: data.regon,
    taxId: data.taxId,
    order: data.order,
    offset: data.offset,
    limit: data.limit,
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
  };
};

const taxiCorporationListRequestFactory = {
  createQueryParams,
};

export default taxiCorporationListRequestFactory;
