import CargoOrderActiveListRequest from "../../../api/raily/cargo-order/active-list/cargo-order-active-list.request";
import CargoOrderActiveListLoadParams from "./cargo-order-active-list-load-params";

const create = (
  params: CargoOrderActiveListLoadParams
): CargoOrderActiveListRequest => {
  return {
    creationEnd: params.creationDateTo,
    creationStart: params.creationDateFrom,
  };
};

const cargoOrderActiveListRequestFactory = {
  create,
};

export default cargoOrderActiveListRequestFactory;
