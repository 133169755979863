import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import delegationAddressAddModalValidationService from "./delegation-address-add-modal-validation.service";
import DelegationAddressAddModalData from "./types/delegation-address-add-modal-data";

const getDefaultFormData = (): DelegationAddressAddModalData => {
  return {
    foundAddress: null,
    street: "",
    houseNumber: "",
    apartmentNumber: "",
    zipCode: "",
    town: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<DelegationAddressAddModalData> => {
    return {
      foundAddress: () => formValidationService.defaultValidationResult,
      street: (values) =>
        delegationAddressAddModalValidationService.validateStreet(
          values.street
        ),
      houseNumber: (values) =>
        delegationAddressAddModalValidationService.validateHouseNumber(
          values.houseNumber
        ),
      apartmentNumber: (values) =>
        delegationAddressAddModalValidationService.validateApartmentNumber(
          values.apartmentNumber
        ),
      zipCode: (values) =>
        delegationAddressAddModalValidationService.validateZipCode(
          values.zipCode
        ),
      town: (values) =>
        delegationAddressAddModalValidationService.validateTown(values.town),
    };
  };

const delegationAddressAddModalHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default delegationAddressAddModalHelper;
