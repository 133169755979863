import { FC, useMemo } from "react";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import cargoAddressListingSortHelper from "../common/helper/cargo-address-listing-sort.helper";
import CargoAddressListingSortKey from "../common/types/cargo-address-listing-sort-key";
import CargoAddressListingSortSelectOption from "../common/types/cargo-address-listing-sort-select-option";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import { useAppContext } from "../../../../../context/app.context";

type CargoAddressListingSortProps = {
  sortKey: CargoAddressListingSortKey | null;
  onSortKeyChange: (key: CargoAddressListingSortKey | null) => void;
};

const CargoAddressListingSortComponent: FC<CargoAddressListingSortProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const translations =
    cargoTranslationsHelper.getAddressListingTranslations().sort;

  const sortSelectOptions: CargoAddressListingSortSelectOption[] = useMemo(
    () => cargoAddressListingSortHelper.getSelectOptions(),
    [selectedAppLanguage]
  );

  const selectedOption =
    sortSelectOptions.find((item) => item.value === props.sortKey) ?? null;

  return (
    <div className="cargo_address_listing_sort">
      <SingleSelectComponent
        onChange={(option) => props.onSortKeyChange(option?.value!)}
        options={sortSelectOptions}
        value={selectedOption}
        classNames={{ root: "cargo_address_listing_sort__select" }}
        placeholder={translations.searchInputPlaceholder}
        isSearchable
        isClearable
        menuPlacement="bottom"
        maxMenuHeight={400}
        idForTesting="cargo-address-listing-sort"
      />
    </div>
  );
};

export default CargoAddressListingSortComponent;
