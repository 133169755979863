import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import appAsideMinifiedNavigationFactory from "./common/app-aside-minified-navigation.factory";
import appAsideNavigationHelper from "../common/app-aside-navigation.helper";
import AppAsideMinifiedNavigationListItemComponent from "./app-aside-minified-navigation-list-item.component";
import { useAppContext } from "../../../../context/app.context";

type AppAsideMinifiedNavigationProps = {};

const AppAsideMinifiedNavigationComponent: FC<
  AppAsideMinifiedNavigationProps
> = () => {
  const { user } = useAppContext();
  const { pathname } = useLocation();

  const navigationDefinition = useMemo(() => {
    return appAsideNavigationHelper.getNavigationDefinitionForUserRoles(
      user!.roles
    );
  }, [user?.roles]);

  const navigationItems = useMemo(
    () =>
      appAsideMinifiedNavigationFactory.createNavigationItems(
        navigationDefinition,
        pathname
      ),
    [navigationDefinition]
  );

  return (
    <nav className="app_aside_navigation">
      <div className="app_aside_navigation_list">
        {navigationItems.map((item) => {
          return (
            <AppAsideMinifiedNavigationListItemComponent
              key={`minified-navigation-list-item-key-${item.key}`}
              item={item}
            />
          );
        })}
      </div>
    </nav>
  );
};

export default AppAsideMinifiedNavigationComponent;
