import PassengerAddRequest from "../../common/api/passenger-add-request";
import passengerAddFactory from "../../common/passenger-add.factory";
import PassengerAddByCargoFormData from "./types/passenger-add-by-cargo-form-data";

const createAddPassengerRequest = (
  formData: PassengerAddByCargoFormData
): PassengerAddRequest => {
  const addresses = passengerAddFactory.createAddPassengerRequestAddresses(
    formData.addresses
  );
  const dispatchIds = passengerAddFactory.createAddPassengerRequestDispatchIds(
    formData.dispatches!
  );

  const request: PassengerAddRequest = {
    first_name: formData.firstName.trim(),
    last_name: formData.lastName.trim(),
    mobile_primary_prefix: formData.mobile.dialingCode || undefined,
    mobile_primary: formData.mobile.number || undefined,
    mobile_secondary_prefix: formData.alternativeMobile.dialingCode || null,
    mobile_secondary: formData.alternativeMobile.number || null,
    email: formData.email.trim(),
    username: formData.username.trim(),
    password: formData.password,
    passenger: {
      addresses: addresses,
      dispatch_ids: dispatchIds,
    },
  };

  return request;
};

const passengerAddByCargoFactory = {
  createAddPassengerRequest,
  createDispatchSelectOptions: passengerAddFactory.createDispatchSelectOptions,
};

export default passengerAddByCargoFactory;
