import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import FormComponent from "../../../../common/components/form/form.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import SignUpDealerFormData from "./common/types/sign-up-dealer-form-data";
import { FC, useEffect, useState } from "react";
import signUpDealerDataValidationService from "./common/types/sign-up-dealer-data-validation.results";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import signUpDealerValidationService from "./common/sign-up-dealer-validation.service";
import PhoneNumberInputComponent from "../../../../common/components/form/input/phone-number/phone-number-input.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import Column from "../../../../common/components/grid/column";
import useQueryParams from "../../../../common/hooks/use-query-params";
import signUpTokenService from "../common/sign-up-token.service";
import SignUpDealerResponse from "./common/api/sign-up-dealer.response";
import notificationService from "../../../../common/utils/notification/notification.service";
import { useNavigate } from "react-router-dom";
import authRoutesHelper from "../../common/routes/auth-routes.helper";
import SignUpDealerDisabledFormFields from "./common/types/sign-up-dealer-disabled-form-fields";
import signUpDealerFactory from "./common/sign-up-dealer.factory";
import SignUpDealerTokenPayload from "../common/types/sign-up-dealer-token-payload";
import signUpDealerApiService from "./common/api/sign-up-dealer-api.service";
import phoneNumberInputHelper from "../../../../common/components/form/input/phone-number/phone-number-input.helper";
import Row from "../../../../common/components/grid/row";
import AppLogoHorizontalComponent from "../../../../common/components/app-logo/app-logo-horizontal.component";

const SignUpDealerComponent: FC = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [disabledFormFields, setDisabledFormFields] =
    useState<SignUpDealerDisabledFormFields>({
      email: false,
      username: false,
      firstName: false,
      lastName: false,
      mobile: false,
      alternativeMobile: false,
    });

  const [formData, setFormData] = useState<SignUpDealerFormData>({
    email: "",
    username: "",
    password: "",
    passwordRepeat: "",
    mobile: phoneNumberInputHelper.getEmptyValue(),
    alternativeMobile: phoneNumberInputHelper.getEmptyValue(),
    firstName: "",
    lastName: "",
  });

  const [taxiCorporationId, setTaxiCorporationId] = useState("");

  const { token } = useQueryParams<{ token: string | undefined }>();

  const navigate = useNavigate();

  const [formValidationResults, setFormValidationResults] =
    useState<signUpDealerDataValidationService>({
      email: formValidationService.defaultValidationResult,
      username: formValidationService.defaultValidationResult,
      firstName: formValidationService.defaultValidationResult,
      lastName: formValidationService.defaultValidationResult,
      password: formValidationService.defaultValidationResult,
      passwordRepeat: formValidationService.defaultValidationResult,
      mobile: formValidationService.defaultValidationResult,
      alternativeMobile: formValidationService.defaultValidationResult,
    });

  const translations = appTranslationsHelper.getDealerSignUpTranslations();

  const setEmail = (value: SignUpDealerFormData["email"]) =>
    setFormData((curr) => ({ ...curr, email: value }));

  const setPassword = (value: SignUpDealerFormData["password"]) =>
    setFormData((curr) => ({ ...curr, password: value }));

  const setPasswordRepeat = (value: SignUpDealerFormData["passwordRepeat"]) =>
    setFormData((curr) => ({ ...curr, passwordRepeat: value }));

  const setUsername = (value: SignUpDealerFormData["username"]) =>
    setFormData((curr) => ({ ...curr, username: value }));

  const setFirstName = (value: SignUpDealerFormData["firstName"]) =>
    setFormData((curr) => ({ ...curr, firstName: value }));

  const setLastName = (value: SignUpDealerFormData["lastName"]) =>
    setFormData((curr) => ({ ...curr, lastName: value }));

  const setMobile = (value: SignUpDealerFormData["mobile"]) =>
    setFormData((curr) => ({ ...curr, mobile: value }));

  const setAlternativeMobile = (
    value: SignUpDealerFormData["alternativeMobile"]
  ) => setFormData((curr) => ({ ...curr, alternativeMobile: value }));

  const validateEmail = () => {
    const validationResult = signUpDealerValidationService.validateEmail(
      formData.email
    );

    setFormValidationResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const validatePassword = () => {
    const validationResult = signUpDealerValidationService.validatePassword(
      formData.password
    );

    setFormValidationResults((curr) => ({
      ...curr,
      password: validationResult,
    }));

    if (!!formData.passwordRepeat) {
      validatePasswordRepeat();
    }

    return validationResult.isValid;
  };

  const validatePasswordRepeat = () => {
    const validationResult =
      signUpDealerValidationService.validatePasswordRepeat(
        formData.password,
        formData.passwordRepeat
      );

    setFormValidationResults((curr) => ({
      ...curr,
      passwordRepeat: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUsername = async () => {
    const validationResult =
      await signUpDealerValidationService.validateUsername(formData.username);

    setFormValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateFirstName = () => {
    const validationResult = signUpDealerValidationService.validateFirstName(
      formData.firstName
    );

    setFormValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateLastName = () => {
    const validationResult = signUpDealerValidationService.validateLastName(
      formData.lastName
    );

    setFormValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateMobile = () => {
    const validationResult = signUpDealerValidationService.validateMobile(
      formData.mobile
    );

    setFormValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAlternativeMobile = () => {
    const validationResult =
      signUpDealerValidationService.validateAlternativeMobile(
        formData.alternativeMobile
      );

    setFormValidationResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult.isValid;
  };

  const onTokenDecodeSuccess = (tokenPayload: SignUpDealerTokenPayload) => {
    const defaultFormData = signUpDealerFactory.createFormData(tokenPayload);
    const defaultFormDataDisabledFields =
      signUpDealerFactory.createDisabledFormFieldsConfig(tokenPayload);

    setFormData(defaultFormData);
    setDisabledFormFields(defaultFormDataDisabledFields);
    setTaxiCorporationId(tokenPayload.taxi_corporation_id);
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    try {
      const tokenPayload = signUpTokenService.decode(token);

      if (!signUpTokenService.checkIsDealer(tokenPayload)) {
        console.error(`Invalid token type. It is not dealer token.`);
        return;
      }

      onTokenDecodeSuccess(tokenPayload);
    } catch (e) {
      console.error(`Invalid token`);
    }
  }, [token]);

  const checkIsFormValid = async () => {
    const isEmailValid = validateEmail();
    const isUsernameValid = await validateUsername();
    const isPasswordValid = validatePassword();
    const isPasswordRepeatValid = validatePasswordRepeat();
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const isMobileValid = validateMobile();
    const IsAlternativeMobileValid = validateAlternativeMobile();

    const isFormValid =
      isEmailValid &&
      isUsernameValid &&
      isPasswordValid &&
      isPasswordRepeatValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isMobileValid &&
      IsAlternativeMobileValid;

    return isFormValid;
  };

  const navigateToSignInRoute = (username: string) => {
    const signInRoute = authRoutesHelper.getSignInRoute();

    navigate(signInRoute, {
      state: {
        username,
      },
    });
  };

  const onSuccessSignUp = (username: string) => {
    notificationService.success(translations.successSignUpNotificationText);

    navigateToSignInRoute(username);
  };

  const handleSubmitResponse = (response: SignUpDealerResponse) => {
    if (response.status === 201) {
      onSuccessSignUp(response.data.username);
    }
  };

  const submitForm = async () => {
    if (!token) {
      console.error(`No token available`);
      return;
    }

    const isFormValid = checkIsFormValid();

    if (!isFormValid) {
      return;
    }

    setIsFormSubmitting(true);

    const request = signUpDealerFactory.createSignUpRequest(
      formData,
      taxiCorporationId
    );

    signUpDealerApiService
      .signUpDealer(request, token)
      .then(handleSubmitResponse);
  };

  return (
    <div className="sign_up_dealer">
      <div className="sign_up_dealer__content">
        <div className="sign_up_dealer__heading">
          <AppLogoHorizontalComponent className="sign_up_dealer__logo" />
          <h1 className="sign_up_dealer__title">{translations.headerTitle}</h1>
        </div>
        <div className="sign_up_dealer__form">
          <Row>
            <Column withPaddings>
              <CardComponent>
                <FormComponent onSubmit={submitForm}>
                  <Row>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.emailLabel}
                        isRequired
                        errorMessage={formValidationResults.email.errorMessage}
                      >
                        <InputComponent
                          placeholder={translations.form.emailPlaceholder}
                          value={formData.email}
                          isDisabled={disabledFormFields.email}
                          onChange={setEmail}
                          onBlur={validateEmail}
                          hasError={!!formValidationResults.email.errorMessage}
                          idForTesting="email-input"
                        />
                      </FormFieldComponent>
                    </Column>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.usernameLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.username.errorMessage
                        }
                      >
                        <InputComponent
                          placeholder={translations.form.usernamePlaceholder}
                          isDisabled={disabledFormFields.username}
                          value={formData.username}
                          onChange={setUsername}
                          onBlur={validateUsername}
                          hasError={
                            !!formValidationResults.username.errorMessage
                          }
                          idForTesting="username-input"
                        />
                      </FormFieldComponent>
                    </Column>
                  </Row>
                  <Row>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.firstNameLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.firstName.errorMessage
                        }
                      >
                        <InputComponent
                          placeholder={translations.form.firstNamePlaceholder}
                          value={formData.firstName}
                          isDisabled={disabledFormFields.firstName}
                          onChange={setFirstName}
                          onBlur={validateFirstName}
                          hasError={
                            !!formValidationResults.firstName.errorMessage
                          }
                          idForTesting="first-name-input"
                        />
                      </FormFieldComponent>
                    </Column>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.lastNameLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.lastName.errorMessage
                        }
                      >
                        <InputComponent
                          placeholder={translations.form.lastNamePlaceholder}
                          isDisabled={disabledFormFields.lastName}
                          value={formData.lastName}
                          onChange={setLastName}
                          onBlur={validateLastName}
                          hasError={
                            !!formValidationResults.lastName.errorMessage
                          }
                          idForTesting="last-name-input"
                        />
                      </FormFieldComponent>
                    </Column>
                  </Row>
                  <Row>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.mobileLabel}
                        isRequired
                        errorMessage={formValidationResults.mobile.errorMessage}
                      >
                        <PhoneNumberInputComponent
                          placeholder={translations.form.mobilePlaceholder}
                          phoneNumber={formData.mobile}
                          isDisabled={disabledFormFields.mobile}
                          onChange={setMobile}
                          onBlur={validateMobile}
                          hasError={!!formValidationResults.mobile.errorMessage}
                          idForTesting="mobile"
                        />
                      </FormFieldComponent>
                    </Column>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.alternativeMobileLabel}
                        errorMessage={
                          formValidationResults.alternativeMobile.errorMessage
                        }
                      >
                        <PhoneNumberInputComponent
                          placeholder={
                            translations.form.alternativeMobilePlaceholder
                          }
                          isDisabled={disabledFormFields.alternativeMobile}
                          phoneNumber={formData.alternativeMobile}
                          onChange={setAlternativeMobile}
                          onBlur={validateAlternativeMobile}
                          hasError={
                            !!formValidationResults.alternativeMobile
                              .errorMessage
                          }
                          idForTesting="alternative-mobile"
                        />
                      </FormFieldComponent>
                    </Column>
                  </Row>
                  <Row>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.passwordLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.password.errorMessage
                        }
                      >
                        <InputComponent
                          type="password"
                          placeholder={translations.form.passwordPlaceholder}
                          value={formData.password}
                          onChange={setPassword}
                          onBlur={validatePassword}
                          hasError={
                            !!formValidationResults.password.errorMessage
                          }
                          idForTesting="password-input"
                        />
                      </FormFieldComponent>
                    </Column>
                    <Column md={6}>
                      <FormFieldComponent
                        label={translations.form.passwordRepeatLabel}
                        isRequired
                        errorMessage={
                          formValidationResults.passwordRepeat.errorMessage
                        }
                      >
                        <InputComponent
                          type="password"
                          placeholder={
                            translations.form.passwordRepeatPlaceholder
                          }
                          value={formData.passwordRepeat}
                          onChange={setPasswordRepeat}
                          onBlur={validatePasswordRepeat}
                          hasError={
                            !!formValidationResults.passwordRepeat.errorMessage
                          }
                          idForTesting="password-repeat-input"
                        />
                      </FormFieldComponent>
                    </Column>
                  </Row>
                </FormComponent>
              </CardComponent>
              <div className="sign_up_cargo_officer__submit_button_wrapper">
                <ButtonComponent
                  onClick={submitForm}
                  type="primary"
                  isDisabled={isFormSubmitting}
                  idForTesting="submit-button"
                >
                  {translations.formSubmitButton}
                </ButtonComponent>
              </div>
            </Column>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SignUpDealerComponent;
