enum TaxiTransferedOrderSettlementListingFilterType {
  ORDER_START_DATE = "ORDER_START_DATE",
  INTERNAL_ORDER_ID = "INTERNAL_ORDER_ID",
  TAXI_CORPORATION_ID = "TAXI_CORPORATION_ID",
  CONSUMER_TAXI = "CONSUMER_TAXI",
  PRODUCER_TAXI = "PRODUCER_TAXI",
  BILLING_STATUS = "BILLING_STATUS",
}

export default TaxiTransferedOrderSettlementListingFilterType;
