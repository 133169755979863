import DispatcherUpdateRequest, {
  DispatcherUpdateRequestBody,
} from "./dispatcher-update.request";

const createBody = (
  data: DispatcherUpdateRequest
): DispatcherUpdateRequestBody => {
  return {
    dispatchId: data.dispatchId,
    user: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobilePrimary: data.mobilePrimary,
      mobilePrimaryPrefix: data.mobilePrimaryPrefix,
      mobileSecondary: data.mobileSecondary,
      mobileSecondaryPrefix: data.mobileSecondaryPrefix,
    },
  };
};

const dispatcherUpdateRequestFactory = {
  createBody,
};

export default dispatcherUpdateRequestFactory;
