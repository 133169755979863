import TaxiDriverContractAddRequest, {
  TaxiDriverContractAddRequestBody,
} from "./taxi-driver-contract-add.request";

const createBody = (
  request: TaxiDriverContractAddRequest
): TaxiDriverContractAddRequestBody => {
  return {
    disabled: request.disabled,
    distanceRate: request.distanceRate,
    haltingTimeAfterMinutes: request.haltingTimeAfterMinutes,
    haltingTimeRate: request.haltingTimeRate,
    model: request.model,
    taxiDriverAssociationId: request.taxiDriverAssociationId,
    validSince: request.validSince,
    validTo: request.validTo,
  };
};

const taxiDriverContractAddRequestFactory = {
  createBody,
};

export default taxiDriverContractAddRequestFactory;
