import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import OrderAbandonedListingFilter from "../../common/types/order-abandoned-listing-filter";
import orderAbandonedListingFiltersBadgeHelper from "./order-abandoned-listing-filters-badge.helper";

type OrderAbandonedListingFiltersBadgeProps = {
  filter: OrderAbandonedListingFilter;
  onDeleteButtonClick: () => void;
};

const OrderAbandonedListingFiltersBadgeComponent: FC<
  OrderAbandonedListingFiltersBadgeProps
> = (props) => {
  const badgeName = orderAbandonedListingFiltersBadgeHelper.getBadgeName(
    props.filter
  );
  const badgeTitle = orderAbandonedListingFiltersBadgeHelper.getBadgeTitle(
    props.filter
  );

  return (
    <div className="order_abandoned_listing_filters_badge" title={badgeTitle}>
      <div className="order_abandoned_listing_filters_badge__text">
        {badgeName}
      </div>
      <ButtonComponent
        onClick={props.onDeleteButtonClick}
        classNames={{
          root: `order_abandoned_listing_filters_badge__button`,
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </ButtonComponent>
    </div>
  );
};

export default OrderAbandonedListingFiltersBadgeComponent;
