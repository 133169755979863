import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import DateRange from "../../../../../common/types/date-range";
import dateService from "../../../../../common/utils/date/date.service";
import taxiTaxiContractEditFormValidationService from "../taxi-taxi-contract-edit-form-validation.service";
import TaxiTaxiContractEditFormData from "./types/taxi-taxi-contract-edit-form-data";

const getEmptyFormData = (): TaxiTaxiContractEditFormData => {
  return {
    displayName: "",
    isActive: null,
    priority: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<TaxiTaxiContractEditFormData> => {
    return {
      displayName: (values) =>
        taxiTaxiContractEditFormValidationService.validateDisplayName(
          values.displayName
        ),
      isActive: (values) =>
        taxiTaxiContractEditFormValidationService.validateActivity(
          values.isActive
        ),
      priority: (values) =>
        taxiTaxiContractEditFormValidationService.validatePriority(
          values.priority
        ),
    };
  };

const getPeriodOfValidityLabel = (
  periodOfValidity: DateRange | undefined
): string => {
  if (!periodOfValidity) {
    return "";
  }

  return `${dateService.formatDate(
    periodOfValidity.from
  )} - ${dateService.formatDate(periodOfValidity.to)}`;
};

const taxiTaxiContractEditFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
  getPeriodOfValidityLabel,
};

export default taxiTaxiContractEditFormHelper;
