import PassengerListLoadParams, {
  PassengerListLoadParamsOrder,
} from "../../../../../common/services/passenger/list/passenger-list-load-params";
import PassengerListingFilter, {
  PassengerListingAddressFilter,
  PassengerListingCargoCompanyFilter,
  PassengerListingDispatchFilter,
  PassengerListingEmailFilter,
  PassengerListingFirstNameFilter,
  PassengerListingLastNameFilter,
  PassengerListingStatusFilter,
  PassengerListingUsernameFilter,
} from "./types/passenger-listing-filter";
import PassengerListingFilterType from "./types/passenger-listing-filter-type";
import PassengerListingSortKey from "./types/passenger-listing-sort-key";

const createOrder = (
  sortKey: PassengerListingSortKey | null
): PassengerListLoadParamsOrder | undefined => {
  const definition: Record<
    PassengerListingSortKey,
    PassengerListLoadParamsOrder
  > = {
    [PassengerListingSortKey.FIRST_NAME_ASC]:
      PassengerListLoadParamsOrder.FIRST_NAME_ASC,
    [PassengerListingSortKey.FIRST_NAME_DESC]:
      PassengerListLoadParamsOrder.FIRST_NAME_DESC,
    [PassengerListingSortKey.LAST_NAME_ASC]:
      PassengerListLoadParamsOrder.LAST_NAME_ASC,
    [PassengerListingSortKey.LAST_NAME_DESC]:
      PassengerListLoadParamsOrder.LAST_NAME_DESC,
    [PassengerListingSortKey.USERNAME_ASC]:
      PassengerListLoadParamsOrder.USERNAME_ASC,
    [PassengerListingSortKey.USERNAME_DESC]:
      PassengerListLoadParamsOrder.USERNAME_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: PassengerListingFilter[],
  sortKey: PassengerListingSortKey | null
): PassengerListLoadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === PassengerListingFilterType.ACTIVITY_STATUS
  )?.value as PassengerListingStatusFilter["value"] | undefined;

  return {
    page,
    pageSize,
    address: filters.find(
      (filter) => filter.type === PassengerListingFilterType.ADDRESS
    )?.value as PassengerListingAddressFilter["value"] | undefined,
    firstName: filters.find(
      (filter) => filter.type === PassengerListingFilterType.FIRST_NAME
    )?.value as PassengerListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === PassengerListingFilterType.LAST_NAME
    )?.value as PassengerListingLastNameFilter["value"] | undefined,
    email: filters.find(
      (filter) => filter.type === PassengerListingFilterType.EMAIL
    )?.value as PassengerListingEmailFilter["value"] | undefined,
    username: filters.find(
      (filter) => filter.type === PassengerListingFilterType.USERNAME
    )?.value as PassengerListingUsernameFilter["value"] | undefined,
    status: statusFilter,
    cargoCompany: filters.find(
      (filter) => filter.type === PassengerListingFilterType.CARGO_COMPANY
    )?.value as PassengerListingCargoCompanyFilter["value"] | undefined,
    dispatch: filters.find(
      (filter) => filter.type === PassengerListingFilterType.DISPATCH
    )?.value as PassengerListingDispatchFilter["value"] | undefined,
    order: createOrder(sortKey),
  };
};

const passengerListingLoadParamsFactory = {
  create,
};

export default passengerListingLoadParamsFactory;
