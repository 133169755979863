import { FC } from "react";
import orderDetailsHistoryEntryForwardCancelContentHelper from "./order-details-history-entry-forward-cancel-content.helper";
import { OrderDetailsTHandlingLogEntryForwardCancelEvent } from "../../../common/types/order-details-handling-log-entry-event";

type OrderDetailsHistoryEntryForwardCancelContentProps = {
  event: OrderDetailsTHandlingLogEntryForwardCancelEvent;
};

const OrderDetailsHistoryEntryForwardCancelContentComponent: FC<
  OrderDetailsHistoryEntryForwardCancelContentProps
> = (props) => {
  const headingText =
    orderDetailsHistoryEntryForwardCancelContentHelper.getHeadingText(
      props.event.producerDisplayName,
      props.event.consumerDisplayName
    );

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {headingText}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryForwardCancelContentComponent;
