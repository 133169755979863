import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserDriverContractEditRouteParams from "../../../common/routes/types/user-driver-contract-edit-route-params";
import useTaxiDriverContractDetails from "../../../../../common/services/taxi-driver-contract/details/use-taxi-driver-contract-details";
import useAbort from "../../../../../common/hooks/use-abort";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import driverContractEditFormHelper from "./common/driver-contract-edit-form.helper";
import BooleanSelectComponent from "../../../../../common/components/form/select/boolean/boolean-select.component";
import useForm from "../../../../../common/components/form/use-form";
import DriverContractEditFormData from "./common/types/driver-contract-edit-form-data";
import ButtonComponent from "../../../../../common/components/button/button.component";
import taxiDriverContractService from "../../../../../common/services/taxi-driver-contract/taxi-driver-contract.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import { useAppContext } from "../../../../../context/app.context";
import userBreadcrumbsHelper from "../../../common/breadcrumbs/user-breadcrumbs.helper";
import useDriverDetails from "../../../../../common/services/driver/details/use-driver-details";

type DriverContractEditProps = {};

const DriverContractEditComponent: FC<DriverContractEditProps> = () => {
  useAppContext();
  const navigate = useNavigate();

  const { driverUuid, contractUuid } =
    useParams<UserDriverContractEditRouteParams>();

  const [isContractUpdatePending, setIsContractUpdatePending] = useState(false);
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const contractUpdateAbort = useAbort();

  const contractDetailsAbort = useAbort();
  const contractDetails = useTaxiDriverContractDetails();

  const driverDetails = useDriverDetails();
  const driverDetailsAbort = useAbort();

  const driverName = useMemo(() => {
    if (!driverDetails.data) {
      return "";
    }

    return `${driverDetails.data.firstName} ${driverDetails.data.lastName}`;
  }, [driverDetails.data]);

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDriverContractEditBreadcrumbs({
      driverUuid: driverUuid!,
      contractUuid: contractUuid!,
      driverName: driverName,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, contractUuid, driverName]);

  useEffect(() => {
    driverDetails.load({ driverUuid: driverUuid! }, driverDetailsAbort.signal);
  }, [driverUuid]);

  const translations =
    userTranslationsHelper.getDriverContractEditTranslations();

  const form = useForm<DriverContractEditFormData>({
    emptyValues: driverContractEditFormHelper.getEmptyFormData(),
    validationDefinition:
      driverContractEditFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    if (!contractUuid) {
      return;
    }

    contractDetails.load(
      {
        contractUuid,
      },
      contractDetailsAbort.signal
    );
  }, [contractUuid]);

  useEffect(() => {
    if (!contractDetails.data) {
      return;
    }

    form.setValues({
      isActive: contractDetails.data.isActive,
    });
  }, [contractDetails.data]);

  const navigateToContractListing = () => {
    const contractListingRoute = userRoutesHelper.getDriverContractListingRoute(
      {
        driverUuid: driverUuid!,
      }
    );

    navigate(contractListingRoute);
  };

  const onContractUpdateSuccess = () => {
    notificationService.success(translations.successUpdateNotificationLabel);
    navigateToContractListing();
  };

  const onContractUpdateFailure = () => {
    notificationService.error(translations.failureUpdateNotificationLabel);
  };

  const updateContract = async () => {
    setIsContractUpdatePending(true);

    try {
      await taxiDriverContractService.update(
        {
          isActive: form.values.isActive!,
          contractUuid: contractUuid!,
        },
        contractUpdateAbort.signal
      );

      onContractUpdateSuccess();
    } catch {
      onContractUpdateFailure();
    } finally {
      setIsContractUpdatePending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    updateContract();
  };

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.periodOfValidityLabel}
                >
                  {driverContractEditFormHelper.getPeriodOfValidityLabel(
                    contractDetails.data?.periodOfValidity
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent label={translations.form.billingModelLabel}>
                  {contractDetails.data?.billingModel}
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent label={translations.form.distanceRateLabel}>
                  {translations.form.distanceRateContentLabel.replace(
                    "#{distanceRate}",
                    contractDetails.data?.distanceRate.toString() ?? ""
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent label={translations.form.stoppingRateLabel}>
                  {translations.form.stoppingRateContentLabel.replace(
                    "#{stoppingRate}",
                    contractDetails.data?.stoppingRate.toString() ?? ""
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.freeStoppingPeriodLabel}
                >
                  {translations.form.freeStoppingPeriodContentLabel.replace(
                    "#{freeStoppingPeriod}",
                    contractDetails.data?.freeStoppingPeriodMinutes.toString() ??
                      ""
                  )}
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.isActiveLabel}
                  isRequired
                  errorMessage={form.validationResults.isActive.errorMessage}
                >
                  <BooleanSelectComponent
                    value={form.values.isActive}
                    placeholder={translations.form.isActivePlaceholder}
                    onChange={(value) => form.setValue("isActive", value)}
                    onBlur={() => form.validate("isActive")}
                    isError={!!form.validationResults.isActive.errorMessage}
                  />
                </FormFieldComponent>
              </Column>
              {!!contractDetails.data?.notes && (
                <Column lg={6}>
                  <FormFieldComponent
                    label={translations.form.notesLabel}
                    classNames={{ content: "driver_contract_edit_notes" }}
                  >
                    {contractDetails.data?.notes}
                  </FormFieldComponent>
                </Column>
              )}
            </Row>
          </CardComponent>
        </Column>
      </Row>
      <ButtonComponent
        onClick={onSubmitButtonClick}
        type="primary"
        isLoading={isContractUpdatePending}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default DriverContractEditComponent;
