import { FC } from "react";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import DealerListingFilter from "../common/types/dealer-listing-filter";
import DealerListingSortKey from "../common/types/dealer-listing-sort-key";
import dealerListingFilterHelper from "./dealer-listing-filter.helper";

type DealerListingFilterProps = {
  filters: DealerListingFilter[];
  onFiltersChange: (filters: DealerListingFilter[]) => void;
  sortKey: DealerListingSortKey;
  onSortKeyChange: (sortKey: DealerListingSortKey) => void;
};

const DealerListingFilterComponent: FC<DealerListingFilterProps> = (props) => {
  const filterDefinition: ListingFilterDefinition<DealerListingFilter> =
    dealerListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    dealerListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as DealerListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as DealerListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="dealer-listing-filter"
      idForTestingSort="dealer-listing-sort"
    />
  );
};

export default DealerListingFilterComponent;
