const orderRoutesUrls = {
  add: `/order/add`,
  routeEdit: `/order/:orderId/route-edit`,
  listingOfActiveOrders: `/order/listing/active`,
  listingOfAbandonedOrders: "/order/listing/abandoned",
  listingOfFinishedOrders: `/order/listing/finished`,
  join: `/order/join`,
};

export default orderRoutesUrls;
