import { FC, ReactNode, useEffect, useMemo } from "react";
import CardComponent from "../../../../../common/components/card/card.component";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import ListingPaginationComponent from "../../../../../common/components/listing/pagination/listing-pagination.component";
import useAbort from "../../../../../common/hooks/use-abort";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import usePagination from "../../../../../common/hooks/use-pagination";
import MileageDictionaryListLoadParams from "../../../../../common/services/mileage-dictionary/list/mileage-dictionary-list-load-params";
import useMileageDictionaryList from "../../../../../common/services/mileage-dictionary/list/use-mileage-dictionary-list";
import { useAppContext } from "../../../../../context/app.context";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import mileageTranslationsHelper from "../../../../../languages/mileage-translations.helper";
import mileageBreadcrumbsHelper from "../../../common/breadcrumbs/mileage-breadcrumbs.helper";
import mileageDictionaryListingItemFactory from "../common/factory/mileage-dictionary-listing-item.factory";
import mileageDictionaryListingLoadParamsFactory from "../common/factory/mileage-dictionary-listing-load-params.factory";
import MileageDictionaryListingTableComponent from "../table/mileage-dictionary-listing-table.component";
import MileageDictionaryListingItem from "../common/types/mileage-dictionary-listing-item";
import MileageDictionaryListingBasicProps from "../common/types/mileage-dictionary-listing-basic-props";
import useMileageDictionaryFiltersDao from "../use-mileage-dictionary-listing.dao";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";
import mileageRoutesHelper from "../../../common/routes/mileage-routes.helper";
import { useNavigate, useParams } from "react-router-dom";
import MileageDictionaryListingRouteParams from "../../../common/routes/types/mileage-dictionary-listing.route.params";

type MileageDictionaryCargoListingProps = MileageDictionaryListingBasicProps;

const MileageDictionaryCargoListingComponent: FC<
  MileageDictionaryCargoListingProps
> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageDictionaryTranslations();
  const isComponentMounted = useIsComponentMounted();
  const { user, selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { cargoCompanyUuid } = useParams<MileageDictionaryListingRouteParams>();
  const navigate = useNavigate();

  const userCargoCompanyUuid = useMemo(() => {
    const userCargoUuid =
      user?.aspects.cargoOfficer?.cargoCompanyUuid ??
      user?.aspects.dispatcher?.cargoCompanyUuid;

    if (userCargoUuid) {
      navigate(
        mileageRoutesHelper.getDictionaryRoute({
          cargoCompanyUuid: userCargoUuid,
        }),
        {
          replace: true,
        }
      );
    } else {
      return cargoCompanyUuid === "listing" ? undefined : cargoCompanyUuid;
    }

    return userCargoUuid;
  }, [user]);

  useEffect(() => {
    const breadcrumbs =
      mileageBreadcrumbsHelper.getMileageDictionaryBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageDictionaryListing);

  const mileageDictionaryList = useMileageDictionaryList();
  const mileageDictionaryListAbort = useAbort();

  const filtersDao = useMileageDictionaryFiltersDao();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: mileageDictionaryList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const listingItems: MileageDictionaryListingItem[] = useMemo(() => {
    return mileageDictionaryListingItemFactory.create(
      mileageDictionaryList.data.data
    );
  }, [mileageDictionaryList.data.data]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    filtersDao.saveParams(page, pageSize);
  }, [page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    if (!userCargoCompanyUuid) {
      return;
    }

    const params: MileageDictionaryListLoadParams =
      mileageDictionaryListingLoadParamsFactory.create(
        page,
        pageSize,
        userCargoCompanyUuid
      );

    mileageDictionaryList.load(params, mileageDictionaryListAbort.signal);

    return () => mileageDictionaryListAbort.revoke();
  }, [page, pageSize]);

  const AddNewDictionaryRecordLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        title={translations.header.addNewDictionaryRecordLinkTitle}
        to={mileageRoutesHelper.getDictionaryRecordAddRoute({
          cargoCompanyUuid: userCargoCompanyUuid!,
        })}
        idForTesting="cargo-mileage-dictionary-listing-add-button"
      >
        {translations.header.addNewDictionaryRecordLinkLabel}
      </LinkButtonComponent>
    ),
    [translations, userCargoCompanyUuid]
  );

  const createHeadingActions = (): ReactNode[] => {
    if (!!userCargoCompanyUuid) {
      return [AddNewDictionaryRecordLinkButton, ...props.changeViewButtons];
    }

    return [...props.changeViewButtons];
  };

  const headingActions = createHeadingActions();

  const isListingLoading = useMemo(() => {
    return !!userCargoCompanyUuid && mileageDictionaryList.isLoading;
  }, [user, mileageDictionaryList.isLoading]);

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <MileageDictionaryListingTableComponent
          cargoCompanyUuid={userCargoCompanyUuid}
          listingItems={listingItems}
          isError={mileageDictionaryList.isError}
          isLoading={isListingLoading}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={mileageDictionaryList.data.totalCount}
        />
      </CardComponent>
    </>
  );
};

export default MileageDictionaryCargoListingComponent;
