import { FC } from "react";
import orderTranslationsHelper from "../../../../../../../../languages/order-translations.helper";
import { OrderDetailsHistoryEntryChangeHaltingTimeChangeRideEvent } from "../../../../common/types/order-details-history-change-ride-event";
import orderDetailsHistoryEntryChangeRideContentChangeHaltingTimeHelper from "./order-details-history-entry-change-ride-content-change-halting-time.helper";

type OrderDetailsHistoryEntryChangeHaltingTimeProps = {
  event: OrderDetailsHistoryEntryChangeHaltingTimeChangeRideEvent;
};

const OrderDetailsHistoryEntryChangeHaltingTimeComponent: FC<
  OrderDetailsHistoryEntryChangeHaltingTimeProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations().changeRideEntry
      .changeHaltingTime;

  const newHaltingTimeLabel =
    orderDetailsHistoryEntryChangeRideContentChangeHaltingTimeHelper.getNewHaltingTimeLabel(
      props.event.details.newHaltingTime
    );
  const newHaltingTimeTitle =
    orderDetailsHistoryEntryChangeRideContentChangeHaltingTimeHelper.getNewHaltingTimeTitle(
      props.event.details.newHaltingTime
    );

  return (
    <div className="order_details_history_entry_list_item_content__section">
      <div className="order_details_history_entry_list_item_content__label">
        {translations.headingText}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={newHaltingTimeTitle}
      >
        {newHaltingTimeLabel}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryChangeHaltingTimeComponent;
