import classNames from "classnames";
import { FC } from "react";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderDetailsRouteHelper from "./order-join-order-details-route.helper";
import OrderJoinOrderDetailsRouteWaypoint from "../common/types/order-join-order-details-route-waypoint";

type OrderDetailsRouteProps = {
  waypoints: OrderJoinOrderDetailsRouteWaypoint[];
};

const OrderJoinOrderDetailsRouteComponent: FC<OrderDetailsRouteProps> = (
  props
) => {
  const translations =
    orderTranslationsHelper.getOrderJoinTranslations().routes;

  return (
    <div className="order_join_routes">
      <div>{translations.headingText}</div>
      <ul className="order_join_routes_list">
        <li className="order_join_route">
          <div className="place">{translations.placeHeaderLabel}</div>
          <div
            className="ordered_date"
            title={translations.orderedDateHeaderTitle}
          >
            {translations.orderedDateHeaderLabel}
          </div>
          <div
            className="estimated_date"
            title={translations.estimatedDateHeaderTitle}
          >
            {translations.estimatedDateHeaderLabel}
          </div>
          <div
            className="halting_date"
            title={translations.haltingDateHeaderTitle}
          >
            {translations.haltingDateHeaderLabel}
          </div>
          <div className="distance" title={translations.distanceHeaderTitle}>
            {translations.distanceHeaderLabel}
          </div>
        </li>
        {props.waypoints.map((waypoint, index) => {
          return (
            <li
              key={`waypoint-${waypoint.place.latitude}${waypoint.place.longitude}-index-${index}`}
              className={"order_join_route"}
            >
              <div className="place" title={waypoint.place.displayName}>
                <div className="index">{waypoint.stageNo}</div>
                <div className="dot"></div>
                <div className="place_name">{waypoint.place.displayName}</div>
              </div>
              <div
                className="ordered_date"
                title={orderDetailsRouteHelper.getOrderedDateTitle(
                  waypoint.orderedDate
                )}
              >
                {orderDetailsRouteHelper.getOrderedDateLabel(
                  waypoint.orderedDate
                )}
              </div>
              <div
                className="estimated_date"
                title={orderDetailsRouteHelper.getEstimatedDateTitle(
                  waypoint.estimatedDate
                )}
              >
                {orderDetailsRouteHelper.getEstimatedDateLabel(
                  waypoint.estimatedDate
                )}
              </div>
              <div className="halting_date">
                {orderDetailsRouteHelper.getHaltingTimeLabel(
                  waypoint.haltingTime
                )}
              </div>
              <div className="distance">
                {orderDetailsRouteHelper.getDistanceLabel(waypoint.distance)}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OrderJoinOrderDetailsRouteComponent;
