import orderTranslationsHelper from "../../../../../languages/order-translations.helper";

type OrderDetailsCopyToClipboardLabelBuilder = {
  withAuthor: (
    authorDisplayName: string
  ) => OrderDetailsCopyToClipboardLabelBuilder;
  withOrderId: (orderId: number) => OrderDetailsCopyToClipboardLabelBuilder;
  withRouteWaypoints: (
    waypointLabels: string[]
  ) => OrderDetailsCopyToClipboardLabelBuilder;
  withRouteId: (routeId: number) => OrderDetailsCopyToClipboardLabelBuilder;
  build: () => string;
};

const orderDetailsCopyToClipboardLabelBuilder =
  (): OrderDetailsCopyToClipboardLabelBuilder => {
    const translations = orderTranslationsHelper.getDetailsTranslations();

    let _authorDisplayName: string = "";
    let _orderId: number | null = null;
    let _waypointLabels: string[] = [];
    let _routeId: number | null = null;

    const withAuthor: OrderDetailsCopyToClipboardLabelBuilder["withAuthor"] = (
      authorDisplayName
    ) => {
      _authorDisplayName = authorDisplayName;

      return builders;
    };

    const withOrderId: OrderDetailsCopyToClipboardLabelBuilder["withOrderId"] =
      (orderId) => {
        _orderId = orderId;

        return builders;
      };

    const withRouteId: OrderDetailsCopyToClipboardLabelBuilder["withRouteId"] =
      (routeId) => {
        _routeId = routeId;

        return builders;
      };

    const withRouteWaypoints: OrderDetailsCopyToClipboardLabelBuilder["withRouteWaypoints"] =
      (waypointsLabels) => {
        _waypointLabels = waypointsLabels;

        return builders;
      };

    const build = () => {
      let labelTemplate = ``;

      if (_authorDisplayName) {
        labelTemplate += `${translations.copyToClipboard.introductionLabel}\r`;
      }

      if (_orderId) {
        labelTemplate += `${translations.copyToClipboard.orderIdLabel}\r`;
      }

      if (_routeId) {
        labelTemplate += `${translations.copyToClipboard.routeIdLabel}\r`;
      }

      if (_waypointLabels.length) {
        labelTemplate += `${translations.copyToClipboard.waypointsLabel}`;
      }

      return labelTemplate
        .replace(`#{authorDisplayName}`, _authorDisplayName)
        .replace(`#{orderId}`, `${_orderId}`)
        .replace(`#{routeId}`, `${_routeId}`)
        .replace(`#{waypointsLabels}`, _waypointLabels.join("\r"));
    };

    const builders: OrderDetailsCopyToClipboardLabelBuilder = {
      withAuthor,
      withOrderId,
      withRouteWaypoints,
      withRouteId,
      build,
    };
    return builders;
  };

export default orderDetailsCopyToClipboardLabelBuilder;
