import { FC } from "react";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import SingleSelectComponent, {
  SingleSelectProps,
} from "../../../../form/select/single-select/single-select.component";

type ListingFilterSelectProps = Pick<
  SingleSelectProps,
  "value" | "onChange" | "options" | "idForTesting"
>;

const ListingSortSelectComponent: FC<ListingFilterSelectProps> = (props) => {
  const translations =
    appTranslationsHelper.getComponentTranslations().listing.sort.select;

  return (
    <SingleSelectComponent
      onChange={(option) => props.onChange(option)}
      options={props.options}
      classNames={{ root: "listing_sort_select" }}
      value={props.value}
      placeholder={translations.inputPlaceholder}
      isSearchable
      menuPlacement="bottom"
      maxMenuHeight={400}
      idForTesting={props.idForTesting}
    />
  );
};

export default ListingSortSelectComponent;
