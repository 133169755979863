import { FC } from "react";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";
import useOpen from "../../../../../common/hooks/use-open";
import OrderDetailsChangeCargoExternalIdModal from "../../change-cargo-external-id/order-details-change-cargo-external-id.component";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetailsEditCargoExternalComponentProps = {
  details: OrderDetailsCargoOrder | null;
  tools: OrderDetailsToolsTranslations;
  hasAccess: boolean;
  onSuccess: () => void;
};

const OrderDetailsEditCargoExternalComponent: FC<
  OrderDetailsEditCargoExternalComponentProps
> = ({ details, tools, hasAccess, onSuccess }) => {
  if (!hasAccess) return null;

  const { isOpen, open, close } = useOpen();
  const { cargoCompanyExternalOrderId, cargoOrderId } = details!;
  const { editCargoExternalIdButtonTitle } = tools;

  return (
    <>
      <div className="order_details">
        <OrderDetailsToolButtonComponent
          onClick={open}
          icon={faFilePen}
          title={editCargoExternalIdButtonTitle}
        />
      </div>
      {!!isOpen && (
        <OrderDetailsChangeCargoExternalIdModal
          defaultValue={cargoCompanyExternalOrderId!}
          orderUuid={cargoOrderId}
          onSuccess={onSuccess}
          isOpen={isOpen}
          onClose={close}
        />
      )}
    </>
  );
};

export default OrderDetailsEditCargoExternalComponent;
