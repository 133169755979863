import {
  DelegationDetailsAddressNode,
  DelegationDetailsMileageData,
} from "../../../../../common/services/delegation/details/delegation-details";
import DelegationRoadRouteLoadParams, {
  DelegationRoadRouteWaypoint,
} from "../../../../../common/services/delegation/road-route/delegation-road-route-load-params";
import DelegationDetailsRouteWaypoint from "../types/delegation-details-route-waypoint";

const createWaypoint = (
  address: DelegationDetailsAddressNode,
  humanId: number
): DelegationDetailsRouteWaypoint => {
  return {
    place: {
      displayName: address.displayName,
      lat: address.lat,
      lon: address.lon,
    },
    stageNo: `${humanId}`,
    time: address.time,
  };
};

const createWaypointGroup = (
  addressGroup: DelegationDetailsAddressNode[],
  humanId: number
): DelegationDetailsRouteWaypoint[] => {
  return addressGroup.map((address) => createWaypoint(address, humanId));
};

const createWaypoints = (
  addressSequence: DelegationDetailsAddressNode[][],
  mileageData: DelegationDetailsMileageData[]
): DelegationDetailsRouteWaypoint[][] => {
  const hasMileageData = mileageData.length > 0;

  return addressSequence.map((group, groupIndex) =>
    createWaypointGroup(
      group,
      hasMileageData ? mileageData[groupIndex].humanId : groupIndex + 1
    )
  );
};

const createSearchRoutingCoordinate = (
  waypoint: DelegationDetailsRouteWaypoint
): DelegationRoadRouteWaypoint => {
  return {
    lat: waypoint.place.lat,
    lon: waypoint.place.lon,
  };
};

const createSearchRoutingRequest = (
  waypoints: DelegationDetailsRouteWaypoint[]
): DelegationRoadRouteLoadParams => {
  const request: DelegationRoadRouteLoadParams = {
    waypoints: waypoints.map(createSearchRoutingCoordinate),
  };

  return request;
};

const createSearchRoutingRequests = (
  waypoints: DelegationDetailsRouteWaypoint[][]
): DelegationRoadRouteLoadParams[] => {
  const filteredWaypoints = waypoints.filter((group) => group.length > 1);

  return filteredWaypoints.map(createSearchRoutingRequest);
};

const delegationDetailsWaypointFactory = {
  createWaypoints,
  createSearchRoutingRequests,
};

export default delegationDetailsWaypointFactory;
