import StatusLegendStatuses from "../../../../../common/components/status-legend/types/status-legend-statuses";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiUnsettledPrivateOrderListingItemStatus from "./types/taxi-unsettled-private-order-listing-item-status";

const getStatusOptions = () => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledPrivateOrderListingTranslations()
      .status;

  const options: {
    text: string;
    status: TaxiUnsettledPrivateOrderListingItemStatus;
  }[] = [
    {
      text: translations.CANCELLED,
      status: TaxiUnsettledPrivateOrderListingItemStatus.CANCELLED,
    },
    {
      text: translations.FINISHED,
      status: TaxiUnsettledPrivateOrderListingItemStatus.FINISHED,
    },
  ];

  return options;
};

const getStatusText = (
  status: TaxiUnsettledPrivateOrderListingItemStatus
): string => {
  const options = getStatusOptions();

  return options.find((option) => option.status === status)?.text ?? "";
};

const getStatusLegendOptions = () => {
  const options: {
    status: StatusLegendStatuses;
  }[] = [
    {
      status: StatusLegendStatuses.CANCELLED,
    },
    {
      status: StatusLegendStatuses.FINISHED,
    },
  ];

  return options;
};

const taxiUnsettledPrivateOrderListingHelper = {
  getStatusOptions,
  getStatusText,
  getStatusLegendOptions,
};

export default taxiUnsettledPrivateOrderListingHelper;
