import SearchRoadRoutingRequest, {
  SearchRoadRoutingRequestWaypointCoordinate,
} from "../../../utils/search-road-route/search-road-routing.request";
import RouteRoadRouteLoadParams, {
  RouteRoadRouteWaypoint,
} from "./route-road-route-load-params";

const createWaypointCoordinate = (
  type: RouteRoadRouteWaypoint
): SearchRoadRoutingRequestWaypointCoordinate => {
  return {
    latitude: type.lat,
    longitude: type.lon,
  };
};

const create = (
  loadParams: RouteRoadRouteLoadParams
): SearchRoadRoutingRequest => {
  return {
    waypointCoordinates: loadParams.waypoints.map(createWaypointCoordinate),
    excludeHighway: loadParams.excludeHighway,
  };
};

const routeRoadRouteRequestFactory = {
  create,
};

export default routeRoadRouteRequestFactory;
