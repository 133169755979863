import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DriverAddUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type DriverAddUserPermissionsDefinition =
  UserPermissionsDefinition<DriverAddUserPermissionOption>;

export type DriverAddUserPermissions =
  UserPermissions<DriverAddUserPermissionOption>;

const driverAddUserPermissionDefinition: DriverAddUserPermissionsDefinition = {
  hasAccessToTaxiView: [UserRole.TAXI_OFFICER, UserRole.DEALER],
  hasAccessToRailyView: [
    UserRole.ADMIN,
    UserRole.RAILY_OFFICER,
    UserRole.OPERATOR,
  ],
};

export default driverAddUserPermissionDefinition;
