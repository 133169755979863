import OperatorListingFilter, {
  OperatorListingFirstNameFilter,
  OperatorListingStatusFilter,
  OperatorListingLastNameFilter,
  OperatorListingUsernameFilter,
  OperatorListingEmailFilter,
} from "../common/types/operator-listing-filter";
import OperatorListingFilterType from "../common/types/operator-listing-filter-type";
import OperatorListingSortKey from "../common/types/operator-listing-sort-key";
import { UserOperatorListingRouteQueryParams } from "../../../common/routes/types/user-operator-listing-route-params";
import UserStatus from "../../../../../common/types/user-status";

const getFilters = (
  routeQueryFilterParams: UserOperatorListingRouteQueryParams
): OperatorListingFilter[] => {
  const filters: OperatorListingFilter[] = [];

  if (routeQueryFilterParams.firstName) {
    const firstNameFilter: OperatorListingFirstNameFilter = {
      type: OperatorListingFilterType.FIRST_NAME,
      value: routeQueryFilterParams.firstName,
    };

    filters.push(firstNameFilter);
  }

  if (routeQueryFilterParams.lastName) {
    const lastNameFilter: OperatorListingLastNameFilter = {
      type: OperatorListingFilterType.LAST_NAME,
      value: routeQueryFilterParams.lastName,
    };

    filters.push(lastNameFilter);
  }

  if (routeQueryFilterParams.username) {
    const usernameFilter: OperatorListingUsernameFilter = {
      type: OperatorListingFilterType.USERNAME,
      value: routeQueryFilterParams.username,
    };

    filters.push(usernameFilter);
  }

  if (routeQueryFilterParams.email) {
    const usernameFilter: OperatorListingEmailFilter = {
      type: OperatorListingFilterType.EMAIL,
      value: routeQueryFilterParams.email,
    };

    filters.push(usernameFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: OperatorListingStatusFilter = {
      type: OperatorListingFilterType.STATUS,
      value:
        routeQueryFilterParams.status === "DELETED"
          ? UserStatus.DELETED
          : UserStatus.ACTIVE,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: UserOperatorListingRouteQueryParams
): OperatorListingSortKey | undefined => {
  return routeQueryParams.sort as OperatorListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: UserOperatorListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: UserOperatorListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: OperatorListingFilter[],
  sortKey: OperatorListingSortKey | null,
  page: number,
  pageSize: number
): UserOperatorListingRouteQueryParams => {
  const userActivityFilterValue = filters.find(
    (filter) => filter.type === OperatorListingFilterType.STATUS
  )?.value as OperatorListingStatusFilter["value"] | undefined;

  const routeQueryParams: UserOperatorListingRouteQueryParams = {
    firstName: filters.find(
      (filter) => filter.type === OperatorListingFilterType.FIRST_NAME
    )?.value as OperatorListingFirstNameFilter["value"],
    lastName: filters.find(
      (filter) => filter.type === OperatorListingFilterType.LAST_NAME
    )?.value as OperatorListingLastNameFilter["value"],
    username: filters.find(
      (filter) => filter.type === OperatorListingFilterType.USERNAME
    )?.value as OperatorListingUsernameFilter["value"],
    email: filters.find(
      (filter) => filter.type === OperatorListingFilterType.EMAIL
    )?.value as OperatorListingEmailFilter["value"],
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
    status:
      userActivityFilterValue === undefined
        ? undefined
        : String(userActivityFilterValue),
  };

  return routeQueryParams;
};

const operatorListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default operatorListingFiltersService;
