import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import driverAddUserDataFormValidationService from "../../common/user/driver-add-user-data-form-validation.service";
import DriverAddByRailyTaxiCorporationSelectOption from "../common/types/driver-add-by-raily-taxi-corporation-select-option";

const validateTaxiCorporation = (
  taxiCorporation: DriverAddByRailyTaxiCorporationSelectOption | null
): FormValidationResult => {
  const validationSchema = Joi.object().required();

  return formValidationService.validate(taxiCorporation, validationSchema);
};

const driverAddByRailyUserDataFormValidationService = {
  validateFirstName: driverAddUserDataFormValidationService.validateFirstName,
  validateLastName: driverAddUserDataFormValidationService.validateLastName,
  validateMobile: driverAddUserDataFormValidationService.validateMobile,
  validateAlternativeMobile:
    driverAddUserDataFormValidationService.validateAlternativeMobile,
  validateBirthDate: driverAddUserDataFormValidationService.validateBirthDate,
  validateBirthPlace: driverAddUserDataFormValidationService.validateBirthPlace,
  validatePersonalIdNumber:
    driverAddUserDataFormValidationService.validatePersonalIdNumber,
  validateCitizenship:
    driverAddUserDataFormValidationService.validateCitizenship,
  validateLanguages: driverAddUserDataFormValidationService.validateLanguages,
  validateYearsOfExperience:
    driverAddUserDataFormValidationService.validateYearsOfExperience,
  validateFormOfEmployment:
    driverAddUserDataFormValidationService.validateFormOfEmployment,
  validateFleetPartner:
    driverAddUserDataFormValidationService.validateFleetPartner,
  validateAddresses: driverAddUserDataFormValidationService.validateAddresses,
  validateTaxiCorporation,
};

export default driverAddByRailyUserDataFormValidationService;
