import { CargoCompanyDetailsResponseItem } from "../../../../api/raily/cargo-company/cargo-company/details/cargo-company-details.response";
import CargoCompanyDetails from "./cargo-company-details";

const create = (
  response: CargoCompanyDetailsResponseItem
): CargoCompanyDetails => {
  return {
    uuid: response.id,
    name: response.companyName,
    displayName: response.displayName,
  };
};

const cargoCompanyDetailsFactory = { create };

export default cargoCompanyDetailsFactory;
