import TaxiFleetPartnerListResponse, {
  TaxiFleetPartnerListResponseItem,
} from "../../../api/raily/taxi-fleet-partner/list/taxi-fleet-partner-list.response";
import TaxiFleetPartnerList, {
  TaxiFleetPartnerListItem,
} from "./taxi-fleet-partner-list";

const createDataItem = (
  responseDataItem: TaxiFleetPartnerListResponseItem
): TaxiFleetPartnerListItem => {
  return {
    modificationDate: responseDataItem.modifiedAt,
    modifiedBy: responseDataItem.modifiedBy,
    creationDate: responseDataItem.createdAt,
    uuid: responseDataItem.id,
    taxiCorporationUuid: responseDataItem.taxiCorporationId,
    name: responseDataItem.name,
  };
};

const createData = (
  responseData: TaxiFleetPartnerListResponseItem[]
): TaxiFleetPartnerListItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: TaxiFleetPartnerListResponse
): TaxiFleetPartnerList => {
  return createData(response.data);
};

const taxiFleetPartnerListFactory = {
  create,
};

export default taxiFleetPartnerListFactory;
