import OrderRouteEditPassenger from "../../types/order-route-edit-passenger";

const getPassengerTitle = (passenger: OrderRouteEditPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const getPassengerLabel = (passenger: OrderRouteEditPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const orderRouteEditPassengerHelper = {
  getPassengerTitle,
  getPassengerLabel,
};

export default orderRouteEditPassengerHelper;
