import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import TaxiTransferedOrderSettlementListingItem from "../types/taxi-transfered-order-settlement-listing-item";
import TaxiTransferedOrderSettlementListingTableColumn from "../types/taxi-transfered-order-settlement-listing-table-column";
import TaxiTransferedOrderSettlementListingTableRow from "../types/taxi-transfered-order-settlement-listing-table-row";
import taxiTransferedOrderSettlementListingTableHelper from "./taxi-transfered-order-settlement-listing-table.helper";

type TaxiTransferedOrderSettlementListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: TaxiTransferedOrderSettlementListingItem[];
};

const TaxiTransferedOrderSettlementListingTableComponent: FC<
  TaxiTransferedOrderSettlementListingTableProps
> = (props) => {
  const columns: TaxiTransferedOrderSettlementListingTableColumn[] =
    taxiTransferedOrderSettlementListingTableHelper.getColumns();

  const rows: TaxiTransferedOrderSettlementListingTableRow[] =
    taxiTransferedOrderSettlementListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiTransferedOrderSettlementListingTableComponent;
