import PhoneNumber from "../../types/phone-number";

const getLabel = (phoneNumber: PhoneNumber): string => {
  return `${phoneNumber.dialingCode} ${phoneNumber.number}`;
};

const phoneNumberService = {
  getLabel,
};

export default phoneNumberService;
