import UserStatus from "../../../../../common/types/user-status";
import { UserRailyOfficerListingRouteQueryParams } from "../../../common/routes/types/user-raily-officer-listing-route-params";
import RailyOfficerListingFilter, {
  RailyOfficerListingEmailFilter,
  RailyOfficerListingFirstNameFilter,
  RailyOfficerListingLastNameFilter,
  RailyOfficerListingActivityStatusFilter,
  RailyOfficerListingUsernameFilter,
} from "../common/types/raily-officer-listing-filter";
import RailyOfficerListingFilterType from "../common/types/raily-officer-listing-filter-type";
import RailyOfficerListingSortKey from "../common/types/raily-officer-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: UserRailyOfficerListingRouteQueryParams
): RailyOfficerListingFilter[] => {
  const filters: RailyOfficerListingFilter[] = [];

  if (routeQueryFilterParams.firstName) {
    const firstNameFilter: RailyOfficerListingFirstNameFilter = {
      type: RailyOfficerListingFilterType.FIRST_NAME,
      value: routeQueryFilterParams.firstName,
    };

    filters.push(firstNameFilter);
  }

  if (routeQueryFilterParams.lastName) {
    const lastNameFilter: RailyOfficerListingLastNameFilter = {
      type: RailyOfficerListingFilterType.LAST_NAME,
      value: routeQueryFilterParams.lastName,
    };

    filters.push(lastNameFilter);
  }

  if (routeQueryFilterParams.username) {
    const usernameFilter: RailyOfficerListingUsernameFilter = {
      type: RailyOfficerListingFilterType.USERNAME,
      value: routeQueryFilterParams.username,
    };

    filters.push(usernameFilter);
  }

  if (routeQueryFilterParams.email) {
    const usernameFilter: RailyOfficerListingEmailFilter = {
      type: RailyOfficerListingFilterType.EMAIL,
      value: routeQueryFilterParams.email,
    };

    filters.push(usernameFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: RailyOfficerListingActivityStatusFilter = {
      type: RailyOfficerListingFilterType.ACTIVITY_STATUS,
      value:
        routeQueryFilterParams.status === "DELETED"
          ? UserStatus.DELETED
          : UserStatus.ACTIVE,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: UserRailyOfficerListingRouteQueryParams
): RailyOfficerListingSortKey | undefined => {
  return routeQueryParams.sort as RailyOfficerListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: UserRailyOfficerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: UserRailyOfficerListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: RailyOfficerListingFilter[],
  sortKey: RailyOfficerListingSortKey | null,
  page: number,
  pageSize: number
): UserRailyOfficerListingRouteQueryParams => {
  const userActivityFilterValue = filters.find(
    (filter) => filter.type === RailyOfficerListingFilterType.ACTIVITY_STATUS
  )?.value as RailyOfficerListingActivityStatusFilter["value"] | undefined;

  const routeQueryParams: UserRailyOfficerListingRouteQueryParams = {
    firstName: filters.find(
      (filter) => filter.type === RailyOfficerListingFilterType.FIRST_NAME
    )?.value as RailyOfficerListingFirstNameFilter["value"],
    lastName: filters.find(
      (filter) => filter.type === RailyOfficerListingFilterType.LAST_NAME
    )?.value as RailyOfficerListingLastNameFilter["value"],
    username: filters.find(
      (filter) => filter.type === RailyOfficerListingFilterType.USERNAME
    )?.value as RailyOfficerListingUsernameFilter["value"],
    email: filters.find(
      (filter) => filter.type === RailyOfficerListingFilterType.EMAIL
    )?.value as RailyOfficerListingEmailFilter["value"],
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
    status:
      userActivityFilterValue === undefined
        ? undefined
        : String(userActivityFilterValue),
  };

  return routeQueryParams;
};

const railyOfficerListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default railyOfficerListingFiltersService;
