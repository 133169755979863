import { OrderDetailsTHandlingLogAssignmentOrderCancelEvent } from "../../../common/types/order-details-handling-log-entry-event";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";

const translations =
  orderTranslationsHelper.getDetailsHistoryTranslations().assignmentOrderCancel;

const getRouteLabel = (
  planEntryUuid: OrderDetailsTHandlingLogAssignmentOrderCancelEvent["planEntryUuid"]
) => {
  return translations.routeLabel.replace(
    "#{planEntryUuid}",
    planEntryUuid.toString() ?? "-----"
  );
};

const orderDetailsHistoryEntryAssignmentOrderCancelContentHelper = {
  getRouteLabel,
};

export default orderDetailsHistoryEntryAssignmentOrderCancelContentHelper;
