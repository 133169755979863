import railyApiService from "../../raily-api.service";
import cargoCompanyAddressListRequestFactory from "./list/cargo-company-address-list-request.factory";
import CargoCompanyAddressListRequest from "./list/cargo-company-address-list.request";
import CargoCompanyAddressListResponse from "./list/cargo-company-address-list.response";

const cargoCompanyAddressApiService = () => {
  const list = async (
    request: CargoCompanyAddressListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoCompanyAddressListResponse> => {
    const url = `/cargo-companies/${request.companyUuid}/addresses`;

    const queryParams =
      cargoCompanyAddressListRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    list,
  };
};

export default cargoCompanyAddressApiService;
