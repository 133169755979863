import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import CargoAddressListingResponse from "./cargo-address-listing.response";

const fetchListing = async (
  cargoCompanyUuid: string
): Promise<CargoAddressListingResponse> => {
  const path = `${appConfig.baseApiUrl}/cargo-companies/${cargoCompanyUuid}/addresses`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(path, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const cargoAddressListingApiService = {
  fetchListing,
};

export default cargoAddressListingApiService;
