import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import useDealerAddUserPermissions from "./common/user-permissions/use-dealer-add-user-permissions";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import DealerAddViewMode from "./common/dealer-add-view-mode";
import ButtonComponent from "../../../../common/components/button/button.component";
import DealerAddByRailyComponent from "./by-raily/dealer-add-by-raily.component";
import DealerAddByTaxiComponent from "./by-taxi/dealer-add-by-taxi.component";

type DealerAddProps = {};

const DealerAddComponent: FC<DealerAddProps> = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userDealerAdd);

  const userPermissions = useDealerAddUserPermissions();

  const translations = userTranslationsHelper.getDealerAddTranslations();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDealerAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<DealerAddViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DealerAddViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DealerAddViewMode.TAXI)}
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: DealerAddViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: DealerAddViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToRailyButton, ChangeViewToTaxiButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: DealerAddViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: DealerAddViewMode.RAILY,
        component: (
          <DealerAddByRailyComponent
            changeViewButtons={getViewChangeButtons(DealerAddViewMode.RAILY)}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: DealerAddViewMode.TAXI,
        component: (
          <DealerAddByTaxiComponent
            changeViewButtons={getViewChangeButtons(DealerAddViewMode.TAXI)}
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default DealerAddComponent;
