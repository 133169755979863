export enum PlannerDriverPlanListLoadParamsPlanType {
  EXISTING_PLAN = "EXISTING_PLAN",
  NEW_PLAN = "NEW_PLAN",
}

export enum PlannerDriverPlanListLoadParamsSorterType {
  START_DATE_ASC = "START_DATE_ASC",
  START_DATE_DESC = "START_DATE_DESC",
  END_DATE_ASC = "END_DATE_ASC",
  END_DATE_DESC = "END_DATE_DESC",
  DISTANCE_TO_ADDRESS_ASC = "DISTANCE_TO_ADDRESS_ASC",
  DISTANCE_TO_ADDRESS_DESC = "DISTANCE_TO_ADDRESS_DESC",
  DISTANCE_TO_POINT_ASC = "DISTANCE_TO_POINT_ASC",
  DISTANCE_TO_POINT_DESC = "DISTANCE_TO_POINT_DESC",
  DRIVER_NAME_ASC = "DRIVER_NAME_ASC",
  DRIVER_NAME_DESC = "DRIVER_NAME_DESC",
}

export type PlannerDriverPlanListLoadParamsSorter =
  | PlannerDriverPlanListLoadParamsDriverNameAscSorter
  | PlannerDriverPlanListLoadParamsDriverNameDescSorter
  | PlannerDriverPlanListLoadParamsStartDateAscSorter
  | PlannerDriverPlanListLoadParamsStartDateDescSorter
  | PlannerDriverPlanListLoadParamsEndDateAscSorter
  | PlannerDriverPlanListLoadParamsEndDateDescSorter
  | PlannerDriverPlanListLoadParamsDistanceToAddressAscSorter
  | PlannerDriverPlanListLoadParamsDistanceToAddressDescSorter
  | PlannerDriverPlanListLoadParamsDistanceToPointAscSorter
  | PlannerDriverPlanListLoadParamsDistanceToPointDescSorter;

export type PlannerDriverPlanListLoadParamsDriverNameAscSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.DRIVER_NAME_ASC;
};
export type PlannerDriverPlanListLoadParamsDriverNameDescSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.DRIVER_NAME_DESC;
};

export type PlannerDriverPlanListLoadParamsStartDateAscSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.START_DATE_ASC;
};
export type PlannerDriverPlanListLoadParamsStartDateDescSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.START_DATE_DESC;
};

export type PlannerDriverPlanListLoadParamsEndDateAscSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.END_DATE_ASC;
};
export type PlannerDriverPlanListLoadParamsEndDateDescSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.END_DATE_DESC;
};

export type PlannerDriverPlanListLoadParamsDistanceToAddressAscSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.DISTANCE_TO_ADDRESS_ASC;
  relativeTo: string;
  dateFrom?: Date;
  dateTo?: Date;
};

export type PlannerDriverPlanListLoadParamsDistanceToAddressDescSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.DISTANCE_TO_ADDRESS_DESC;
  relativeTo: string;
  dateFrom?: Date;
  dateTo?: Date;
};

export type PlannerDriverPlanListLoadParamsDistanceToPointAscSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.DISTANCE_TO_POINT_ASC;
  dateFrom?: Date;
  dateTo?: Date;
  latitude: number;
  longitude: number;
};

export type PlannerDriverPlanListLoadParamsDistanceToPointDescSorter = {
  sorterType: PlannerDriverPlanListLoadParamsSorterType.DISTANCE_TO_POINT_DESC;
  dateFrom?: Date;
  dateTo?: Date;
  latitude: number;
  longitude: number;
};

export type PlannerDriverPlanListLoadParamsAddress = {
  query: string;
  dateFrom?: Date;
  dateTo?: Date;
};

type PlannerDriverPlanListLoadParams = {
  orderUuid?: string;
  taxiCorporationUuid?: string;
  page?: number;
  pageSize?: number;
  planType?: PlannerDriverPlanListLoadParamsPlanType[];
  orderQuery?: string;
  driverName?: string;
  address?: PlannerDriverPlanListLoadParamsAddress;
  sort?: PlannerDriverPlanListLoadParamsSorter;
};

export default PlannerDriverPlanListLoadParams;
