import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiUnsettledTransferedOrderListingSortKey from "../types/taxi-unsettled-transfered-order-listing-sort-key";
import TaxiUnsettledTransferedOrderListingSortSelectOption from "../types/taxi-unsettled-transfered-order-listing-sort-select-option";

const getSelectOptions =
  (): TaxiUnsettledTransferedOrderListingSortSelectOption[] => {
    const translations =
      settlementTranslationsHelper.getTaxiUnsettledTransferedOrderListingTranslations()
        .sort;

    return [
      {
        label: translations.consumerAscOptionLabel,
        value: TaxiUnsettledTransferedOrderListingSortKey.CONSUMER_ASC,
      },
      {
        label: translations.consumerDescOptionLabel,
        value: TaxiUnsettledTransferedOrderListingSortKey.CONSUMER_DESC,
      },
      {
        label: translations.producerAscOptionLabel,
        value: TaxiUnsettledTransferedOrderListingSortKey.PRODUCER_ASC,
      },
      {
        label: translations.producerDescOptionLabel,
        value: TaxiUnsettledTransferedOrderListingSortKey.PRODUCER_DESC,
      },
    ];
  };

const taxiUnsettledTransferedOrderListingSortHelper = {
  getSelectOptions,
};

export default taxiUnsettledTransferedOrderListingSortHelper;
