import DriverEditAddress from "../../../types/driver-edit-address";

const getAddressLabel = (address: DriverEditAddress) => {
  return `${address.street} ${address.houseNumber}${
    address.apartmentNumber && `/${address.apartmentNumber}`
  } ${address.town}, ${address.zipCode}`;
};

const driverEditUserDataAddressHelper = {
  getAddressLabel,
};

export default driverEditUserDataAddressHelper;
