import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DriverEditUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type DriverEditUserPermissionsDefinition =
  UserPermissionsDefinition<DriverEditUserPermissionOption>;

export type DriverEditUserPermissions =
  UserPermissions<DriverEditUserPermissionOption>;

const driverEditUserPermissionDefinition: DriverEditUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default driverEditUserPermissionDefinition;
