import {
  DriverPlanCandidaturePreviewCandidateEntryType,
  DriverPlanCandidaturePreviewResponseItem,
  DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntry,
  DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryEndData,
  DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPointsNodeData,
  DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryStartData,
  DriverPlanCandidaturePreviewResponseItemCandidatingRequest,
  DriverPlanCandidaturePreviewResponseItemCreatePlanEntryCandidating,
  DriverPlanCandidaturePreviewResponseItemCreateSolvedOrderCandidating,
  DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntry,
  DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryEndData,
  DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPointsNodeData,
  DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryStartData,
  DriverPlanCandidaturePreviewResponseItemPlanEntry,
  DriverPlanCandidaturePreviewResponseItemPlanEntryEndData,
  DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPointsNodeData,
  DriverPlanCandidaturePreviewResponseItemPlanEntryStartData,
} from "../../../api/raily/driver-plan-candidature/preview/driver-plan-candidature-preview.response";
import DriverPlanCandidaturePreview, {
  DriverPlanCandidaturePreviewCandidatingPlanEntry,
  DriverPlanCandidaturePreviewCandidatingPlanEntryEndData,
  DriverPlanCandidaturePreviewCandidatingPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewCandidatingPlanEntryStartData,
  DriverPlanCandidaturePreviewCandidatingPlanEntryWaypoint,
  DriverPlanCandidaturePreviewDraftCandidatingPlanEntry,
  DriverPlanCandidaturePreviewDraftCandidatingPlanEntryEndData,
  DriverPlanCandidaturePreviewDraftCandidatingPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewDraftCandidatingPlanEntryStartData,
  DriverPlanCandidaturePreviewDraftCandidatingPlanEntryWaypoint,
  DriverPlanCandidaturePreviewPlanEntry,
  DriverPlanCandidaturePreviewPlanEntryOrderPoint,
  DriverPlanCandidaturePreviewPlanEntryOrderWaypoint,
  DriverPlanCandidaturePreviewPlanEntryStartData,
  DriverPlanCandidaturePreviewCandidatureEntriesRequestData,
} from "./driver-plan-candidature-preview";

const createDraftCandidatureEntryStartData = (
  data: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryStartData
): DriverPlanCandidaturePreviewDraftCandidatingPlanEntryStartData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createDraftCandidatureEntryEndData = (
  data: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryEndData
): DriverPlanCandidaturePreviewDraftCandidatingPlanEntryEndData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createDraftCandidatureEntryWaypoint = (
  data: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPointsNodeData
): DriverPlanCandidaturePreviewDraftCandidatingPlanEntryWaypoint => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createDraftCandidatureEntryWaypoints = (
  data: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPointsNodeData[]
): DriverPlanCandidaturePreviewDraftCandidatingPlanEntryWaypoint[] => {
  return data.map(createDraftCandidatureEntryWaypoint);
};

const createDraftCandidatureEntryOrder = (
  data: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPoint
): DriverPlanCandidaturePreviewDraftCandidatingPlanEntryOrderPoint => {
  return {
    planEntryUuid: data.humanId,
    waypoints: createDraftCandidatureEntryWaypoints(data.waypoints),
  };
};

const createDraftCandidatureEntryOrders = (
  data: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntryOrderPoint[]
): DriverPlanCandidaturePreviewDraftCandidatingPlanEntryOrderPoint[] => {
  return data.map(createDraftCandidatureEntryOrder);
};

const createDraftCandidatureEntry = (
  data: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntry
): DriverPlanCandidaturePreviewDraftCandidatingPlanEntry => {
  return {
    uuid: data.id,
    candidatureUuid: data.humanId,
    start: createDraftCandidatureEntryStartData(data.start),
    end: createDraftCandidatureEntryEndData(data.end),
    orders: createDraftCandidatureEntryOrders(data.orders),
  };
};

const createDraftPlanEntries = (
  data: DriverPlanCandidaturePreviewResponseItemDraftCandidatingPlanEntry[]
): DriverPlanCandidaturePreviewDraftCandidatingPlanEntry[] => {
  return data.map(createDraftCandidatureEntry);
};

const createCandidatureEntryStartData = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryStartData
): DriverPlanCandidaturePreviewCandidatingPlanEntryStartData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createCandidatureEntryEndData = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryEndData
): DriverPlanCandidaturePreviewCandidatingPlanEntryEndData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createCandidatureEntryWaypoint = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPointsNodeData
): DriverPlanCandidaturePreviewCandidatingPlanEntryWaypoint => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createCandidatureEntryWaypoints = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPointsNodeData[]
): DriverPlanCandidaturePreviewCandidatingPlanEntryWaypoint[] => {
  return data.map(createCandidatureEntryWaypoint);
};

const createCandidatureEntryOrder = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPoint
): DriverPlanCandidaturePreviewCandidatingPlanEntryOrderPoint => {
  return {
    planEntryUuid: data.humanId,
    waypoints: createCandidatureEntryWaypoints(data.waypoints),
  };
};

const createCandidatureEntryOrders = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntryOrderPoint[]
): DriverPlanCandidaturePreviewCandidatingPlanEntryOrderPoint[] => {
  return data.map(createCandidatureEntryOrder);
};

const createCandidatureEntry = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntry
): DriverPlanCandidaturePreviewCandidatingPlanEntry => {
  return {
    uuid: data.id,
    candidatureUuid: data.humanId,
    start: createCandidatureEntryStartData(data.start),
    end: createCandidatureEntryEndData(data.end),
    orders: createCandidatureEntryOrders(data.orders),
  };
};

const createCandidatureEntries = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingPlanEntry[]
): DriverPlanCandidaturePreviewCandidatingPlanEntry[] => {
  return data.map(createCandidatureEntry);
};

const createPlanEntriesStartData = (
  data: DriverPlanCandidaturePreviewResponseItemPlanEntryStartData
): DriverPlanCandidaturePreviewPlanEntryStartData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createPlanEntriesEndData = (
  data: DriverPlanCandidaturePreviewResponseItemPlanEntryEndData
): DriverPlanCandidaturePreviewResponseItemPlanEntryEndData => {
  return {
    address: data.address,
    date: data.date,
  };
};

const createWaypoint = (
  data: DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPointsNodeData
): DriverPlanCandidaturePreviewPlanEntryOrderWaypoint => {
  return { address: data.address, date: data.date };
};

const createWaypoints = (
  data: DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPointsNodeData[]
): DriverPlanCandidaturePreviewPlanEntryOrderWaypoint[] => {
  return data.map(createWaypoint);
};

const createPlanEntriesOrder = (
  data: DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPoint
): DriverPlanCandidaturePreviewPlanEntryOrderPoint => {
  return {
    orderId: data.humanId,
    waypoints: createWaypoints(data.waypoints),
  };
};

const createPlanEntriesOrders = (
  data: DriverPlanCandidaturePreviewResponseItemPlanEntryOrderPoint[]
): DriverPlanCandidaturePreviewPlanEntryOrderPoint[] => {
  return data.map(createPlanEntriesOrder);
};

const createPlanEntry = (
  data: DriverPlanCandidaturePreviewResponseItemPlanEntry
): DriverPlanCandidaturePreviewPlanEntry => {
  return {
    id: data.id,
    planEntryId: data.humanId,
    start: createPlanEntriesStartData(data.start),
    end: createPlanEntriesEndData(data.end),
    orders: createPlanEntriesOrders(data.orders),
  };
};

const createPlanEntries = (
  data: DriverPlanCandidaturePreviewResponseItemPlanEntry[]
): DriverPlanCandidaturePreviewPlanEntry[] => {
  return data.map(createPlanEntry);
};

const createPlanEntryCandidating = (
  data: DriverPlanCandidaturePreviewResponseItemCreatePlanEntryCandidating
): DriverPlanCandidaturePreviewCandidatureEntriesRequestData => {
  return {
    token: data.candidatingSolve,
    taxiCorporationUuid: data.taxiCorporationId,
    givenPlanEntryUuid: data.givenPlanEntryId,
    transportingOrderUuid: data.transportingOrderId,
  };
};

const createSolvedOrderCandidating = (
  data: DriverPlanCandidaturePreviewResponseItemCreateSolvedOrderCandidating
): DriverPlanCandidaturePreviewCandidatureEntriesRequestData => {
  return {
    token: data.candidatingSolve,
    transportingOrderUuid: data.transportingOrderId,
  };
};

const createCandidatureEntriesRequestData = (
  data: DriverPlanCandidaturePreviewResponseItemCandidatingRequest
): DriverPlanCandidaturePreviewCandidatureEntriesRequestData => {
  switch (data.candidateEntryType) {
    case DriverPlanCandidaturePreviewCandidateEntryType.CREATE_PLAN_ENTRY:
      return createPlanEntryCandidating(
        data as DriverPlanCandidaturePreviewResponseItemCreatePlanEntryCandidating
      );
    case DriverPlanCandidaturePreviewCandidateEntryType.CREATE_SOLVED_ORDER:
      return createSolvedOrderCandidating(
        data as DriverPlanCandidaturePreviewResponseItemCreateSolvedOrderCandidating
      );
  }
};

const create = (
  item: DriverPlanCandidaturePreviewResponseItem
): DriverPlanCandidaturePreview => {
  return {
    planEntries: createPlanEntries(item.planEntries),
    candidatureEntries: createCandidatureEntries(item.candidatureEntries),
    draftCandidatureEntries: createDraftPlanEntries(
      item.draftCandidatureEntries
    ),
    candidatureEntriesRequestData: createCandidatureEntriesRequestData(
      item.candidatingRequest
    ),
  };
};

const driverPlanCandidaturePreviewFactory = { create };

export default driverPlanCandidaturePreviewFactory;
