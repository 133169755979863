import TransportingOrderDeleteForwardingRequest from "../../../api/raily/transporting-order/delete-forwarding/transporting-order-delete-forwarding.request";
import TransportingOrderDeleteFowardingParams from "./transporting-order-delete-forwarding-params";

const create = (
  params: TransportingOrderDeleteFowardingParams
): TransportingOrderDeleteForwardingRequest => {
  return {
    orderId: params.orderUuid,
    forwardActionId: params.forwardingUuid,
  };
};

const transportingOrderDeleteForwardingRequestFactory = {
  create,
};

export default transportingOrderDeleteForwardingRequestFactory;
