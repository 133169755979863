import { FC } from "react";
import classNames from "classnames";
import statusLegendHelper from "./status-legend.helper";

type StatusLegendItemProps = {
  status: string;
  totalCount?: number | undefined;
};

const StatusLegendItemComponent: FC<StatusLegendItemProps> = (props) => {
  const statusLabel = statusLegendHelper.getStatusLegendLabel(props.status);
  const statusClassName = statusLegendHelper.getStatusLegendClassName(
    props.status
  );

  return (
    <div className="status_legend_item">
      <span
        className={classNames("status_legend_item_square", statusClassName)}
      ></span>
      <span>{statusLabel}</span>
      {!!props.totalCount && (
        <span className="status_legend_item_count">({props.totalCount})</span>
      )}
    </div>
  );
};

export default StatusLegendItemComponent;
